import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';

import FormErrors from '../../../Library/FormErrors';

import DeleteModal from '../../../Library/Modal/DeleteModal/DeleteModal';
import { validateTotalLearningObjectiveRevus } from '../../../../helpers/validation/validateEncounter';

import ItemFormHeader from '../ItemFormHeader';
import LearningObjectives from './LearningObjectives/LearningObjectives';
import LinkedEncounterFields from './EncounterFormFields/LinkedEncounterFields';
import NonLinkedEncounterFields from './EncounterFormFields/NonLinkedEncounterFields';
import EncounterTitleField from './EncounterFormFields/EncounterTitleField';

const ItemFormBody = styled.div`
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 200px;
  margin-bottom: 200px;
`;

const EncounterForm = ({
  existing,
  encounterUuid,
  isLinkedEncounter,
  thisEncounterType,
  linkedAssessmentUuid,
  partUuid,
  status,
  encounterTypeUuid,
  endDate,
  leadPercent,
  location,
  maxRevus,
  notes,
  scheduleType,
  startDate,
  title,
  facultyLead,
  faculty_lead,
  encounter_faculty_assigned,
  objectives,
  restrictEdit,
  term,
  encounterNumber,
  optionsFaculty,
  optionsEncounterTypes,
  hasErrors,
  openModal,
  fieldInFocus,
  hasUnsavedChanges,
  onDiscardChanges,
  handleEncounterChange,
  handleDateChange,
  handleUserChange,
  handleEncounterSaveDraft,
  handleEncounterFinish,
  handleObjectiveAdd,
  handleObjectiveChange,
  handleEncounterDeleteOpen,
  handleEncounterDeleteClose,
  handleEncounterApprove,
  handleObjectiveDelete,
  handleObjectiveMove,
  handleObjectiveMicro,
  handleObjectiveMicroRemove,
  handleFieldInFocusValidation,
  handleParticpatingFaculty
}) => {
  const isSingleType = scheduleType === 'Single';

  const failedLearningObjectiveRevusValidation = isSingleType
    ? validateTotalLearningObjectiveRevus(objectives, maxRevus)
    : false;

  const isObjectives = objectives && objectives.length > 0;

  return (
    <div>
      <Paper>
        <ItemFormHeader
          restrictEdit={restrictEdit}
          itemType="Encounter"
          isExistingItem={existing}
          itemNumber={encounterNumber}
          item={{ status, uuid: encounterUuid }}
          handleSaveDraft={handleEncounterSaveDraft}
          handleFinish={handleEncounterFinish}
          handleApprove={handleEncounterApprove}
          handleDeleteOpen={handleEncounterDeleteOpen}
          hasUnsavedChanges={hasUnsavedChanges}
          onDiscardChanges={onDiscardChanges}
        />
        <ItemFormBody>
          <FormErrors hasErrors={hasErrors} />
          {!isObjectives && !isLinkedEncounter && (
            <FormErrors
              hasErrors={hasErrors}
              message="Must create at least one objective"
            />
          )}
          {failedLearningObjectiveRevusValidation && !isLinkedEncounter && (
            <FormErrors
              hasErrors={hasErrors}
              message="For single day encounters, sum of revus learning objectives
                should be equal the calculated max revus."
            />
          )}
          <Grid container spacing={4}>
            <EncounterTitleField
              hasErrors={hasErrors}
              encounterTypeUuid={encounterTypeUuid}
              optionsEncounterTypes={optionsEncounterTypes}
              handleEncounterChange={handleEncounterChange}
              restrictEdit={restrictEdit}
              title={title}
              fieldInFocus={fieldInFocus}
              handleFieldInFocusValidation={handleFieldInFocusValidation}
            />
            {isLinkedEncounter ? (
              <LinkedEncounterFields
                linkedEncounterType={thisEncounterType.label}
                linkedAssessmentUuid={linkedAssessmentUuid}
                handleEncounterChange={handleEncounterChange}
                partUuid={partUuid}
                hasErrors={hasErrors}
                restrictEdit={restrictEdit}
              />
            ) : (
              <NonLinkedEncounterFields
                location={location}
                restrictEdit={restrictEdit}
                scheduleType={scheduleType}
                hasErrors={hasErrors}
                startDate={startDate}
                encounter_faculty_assigned={encounter_faculty_assigned}
                handleDateChange={handleDateChange}
                fieldInFocus={fieldInFocus}
                term={term}
                endDate={endDate}
                optionsFaculty={optionsFaculty}
                facultyLead={facultyLead}
                faculty_lead={faculty_lead}
                leadPercent={leadPercent}
                encounterUuid={encounterUuid}
                handleEncounterChange={handleEncounterChange}
                handleFieldInFocusValidation={handleFieldInFocusValidation}
                handleParticpatingFaculty={handleParticpatingFaculty}
                handleUserChange={handleUserChange}
                notes={notes}
              />
            )}
          </Grid>
          {!isLinkedEncounter && (
            <LearningObjectives
              isObjectives={isObjectives}
              restrictEdit={restrictEdit}
              hasErrors={hasErrors}
              objectives={objectives}
              fieldInFocus={fieldInFocus}
              isSingleType={isSingleType}
              startDate={startDate}
              endDate={endDate}
              handleObjectiveAdd={handleObjectiveAdd}
              handleEncounterChange={handleEncounterChange}
              handleObjectiveChange={handleObjectiveChange}
              handleObjectiveDelete={handleObjectiveDelete}
              handleObjectiveMove={handleObjectiveMove}
              handleFieldInFocusValidation={handleFieldInFocusValidation}
              handleObjectiveMicro={handleObjectiveMicro}
              handleObjectiveMicroRemove={handleObjectiveMicroRemove}
            />
          )}
        </ItemFormBody>
      </Paper>
      <DeleteModal
        modalOpen={openModal}
        handleModalClose={() => handleEncounterDeleteClose(false)}
        handleDelete={() => handleEncounterDeleteClose(true)}
        type="Encounter"
      />
    </div>
  );
};

EncounterForm.propTypes = {
  encounterUuid: PropTypes.string,
  status: PropTypes.string,
  encounterTypeUuid: PropTypes.string,
  leadPercent: PropTypes.string,
  location: PropTypes.string,
  maxRevus: PropTypes.string,
  notes: PropTypes.string,
  scheduleType: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  title: PropTypes.string,
  facultyLead: PropTypes.string,
  faculty_lead: PropTypes.object,
  encounter_faculty_assigned: PropTypes.array,
  objectives: PropTypes.array,
  restrictEdit: PropTypes.bool,
  term: PropTypes.object,
  optionsEncounterTypes: PropTypes.arrayOf(PropTypes.object),
  encounterNumber: PropTypes.number,
  existing: PropTypes.bool,
  optionsFaculty: PropTypes.arrayOf(PropTypes.object),
  fieldInFocus: PropTypes.string,
  hasErrors: PropTypes.bool,
  thisEncounterType: PropTypes.object,
  linkedAssessmentUuid: PropTypes.string,
  partUuid: PropTypes.string,
  isLinkedEncounter: PropTypes.bool,
  openModal: PropTypes.bool,
  handleDateChange: PropTypes.func,
  hasUnsavedChanges: PropTypes.bool,
  onDiscardChanges: PropTypes.func,
  handleEncounterApprove: PropTypes.func,
  handleEncounterChange: PropTypes.func,
  handleEncounterDeleteClose: PropTypes.func,
  handleEncounterDeleteOpen: PropTypes.func,
  handleEncounterFinish: PropTypes.func,
  handleEncounterSaveDraft: PropTypes.func,
  handleFieldInFocusValidation: PropTypes.func,
  handleObjectiveAdd: PropTypes.func,
  handleObjectiveChange: PropTypes.func,
  handleObjectiveDelete: PropTypes.func,
  handleObjectiveMicro: PropTypes.func,
  handleObjectiveMicroRemove: PropTypes.func,
  handleObjectiveMove: PropTypes.func,
  handleParticpatingFaculty: PropTypes.func,
  handleUserChange: PropTypes.func
};

EncounterForm.defaultProps = {
  encounterUuid: '',
  status: '',
  encounterTypeUuid: null,
  endDate: '',
  leadPercent: '',
  location: '',
  maxRevus: '0.0',
  notes: '',
  scheduleType: '',
  startDate: '',
  title: '',
  facultyLead: '',
  faculty_lead: null,
  encounter_faculty_assigned: [],
  objectives: [],
  restrictEdit: true,
  term: {},
  optionsEncounterTypes: [],
  encounterNumber: 0,
  existing: false,
  optionsFaculty: [],
  fieldInFocus: '',
  hasErrors: false,
  thisEncounterType: {},
  linkedAssessmentUuid: '',
  partUuid: '',
  isLinkedEncounter: false,
  openModal: false,
  hasUnsavedChanges: false,
  handleDateChange: undefined,
  onDiscardChanges: undefined,
  handleEncounterApprove: undefined,
  handleEncounterChange: undefined,
  handleEncounterDeleteClose: undefined,
  handleEncounterDeleteOpen: undefined,
  handleEncounterFinish: undefined,
  handleEncounterSaveDraft: undefined,
  handleFieldInFocusValidation: undefined,
  handleObjectiveAdd: undefined,
  handleObjectiveChange: undefined,
  handleObjectiveDelete: undefined,
  handleObjectiveMicro: undefined,
  handleObjectiveMicroRemove: undefined,
  handleObjectiveMove: undefined,
  handleParticpatingFaculty: undefined,
  handleUserChange: undefined
};

export default EncounterForm;
