import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Body1,
  ButtonPrimary,
  ButtonOutline
} from '@xcomp/xcomp-design-library';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Modal from '../../../Library/Modal/Modal';
import {
  allFieldsAreValid,
  validateIsUuid
} from '../../../../helpers/validation/validateGeneric';

import { TextFieldWithError } from '../../../Library/FormComponents';
import { doPostCloneExistingPart } from '../../../../redux/actions/partActions';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 900px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const CloseButton = styled(ButtonOutline)`
  && {
    margin-left: 5px;
  }
`;

const ProceedButton = styled(ButtonPrimary)`
  && {
    margin-left: 12px;
  }
`;

const ClonePartModal = ({ partUuid, isModalOpen, handleModalClose }) => {
  const dispatch = useDispatch();

  const [partUuidToClone, setPartUuidToClone] = useState('');
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const validatePartUuid = text => validateIsUuid(text);

  const handleChange = event => {
    setPartUuidToClone(event.target.value);
  };

  const handleSubmit = () => {
    const partUuidError = validatePartUuid(partUuidToClone);

    if (allFieldsAreValid({ partUuidError })) {
      dispatch(
        doPostCloneExistingPart({
          partUuid,
          partUuidExist: partUuidToClone
        })
      );

      handleModalClose();
    } else {
      setShowValidationErrors(true);
    }
  };

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <ModalBody>
        <Title>Clone Existing Part</Title>

        <Body1>
          Find the ID for the part you would like to clone and enter it in
          below. This will duplicate all assessments & encounters from the
          existing part.
        </Body1>

        <TextFieldWithError
          autoFocus
          id="partUuidToClone"
          name="part id to clone"
          value={partUuidToClone}
          onChange={handleChange}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Part ID"
          placeholder=""
          variant="outlined"
          paddingRight="0"
          handleValidate={validatePartUuid}
          hasErrors={showValidationErrors}
        />
        <Actions>
          <div>
            <CloseButton onClick={handleModalClose}>Cancel</CloseButton>
            <ProceedButton
              onClick={() => {
                handleSubmit(false);
              }}
            >
              Proceed
            </ProceedButton>
          </div>
        </Actions>
      </ModalBody>
    </Modal>
  );
};

ClonePartModal.propTypes = {
  partUuid: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func
};

ClonePartModal.defaultProps = {
  isModalOpen: false,
  handleModalClose: undefined
};

export default ClonePartModal;
