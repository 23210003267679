import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import DOMpurify from 'dompurify';
import PropTypes from 'prop-types';

import { useDescriptionEditorState } from '../../../helpers/hooks/useDescriptionEditorState';

const DescriptionDisplay = ({ description }) => {
  const { convertToHtml } = useDescriptionEditorState();

  return (
    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
      {DOMpurify.sanitize(convertToHtml(description))}
    </ReactMarkdown>
  );
};

DescriptionDisplay.propTypes = {
  description: PropTypes.string
};

DescriptionDisplay.defaultProps = {
  description: ''
};

export default DescriptionDisplay;
