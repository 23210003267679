import _ from 'lodash';
import { descriptionMaxLength } from '../constants';

const validateRequired = textField => {
  if (!textField || textField === '' || textField === 0) {
    return {
      invalid: true,
      message: 'Required'
    };
  }
  return { invalid: false };
};

const validateCourseTitle = textField => {
  const requiredError = validateRequired(textField);
  if (requiredError.invalid) {
    return requiredError;
  }

  if (_.size(textField) > 100) {
    return {
      invalid: true,
      message: 'Course title must be less than 100 characters'
    };
  }
  return { invalid: false };
};

const validateSectionNumber = (value, sectionUuid, sections) => {
  const number = Number(value);
  let sectionDuplicate = false;
  if (!number) {
    return {
      invalid: true,
      message: 'Required'
    };
  }

  if (number < 1 || number > 99) {
    return {
      invalid: true,
      message: 'Must be between 1 and 99'
    };
  }
  sections.forEach(section => {
    if (section.sectionNumber === number && section.uuid !== sectionUuid) {
      sectionDuplicate = true;
    }
  });
  if (sectionDuplicate) {
    return {
      invalid: true,
      message: 'A section with this number already exists'
    };
  }

  return { invalid: false };
};

const validateCourseSections = sectionsArr => {
  let emptyDirectors = false;
  let sectionNumberError = false;
  let descriptionError = false;

  if (!sectionsArr || sectionsArr.length === 0) {
    return {
      invalid: true,
      message: 'You must create at least one section'
    };
  }

  sectionsArr.forEach(section => {
    if (
      !section.section_directors_assigned ||
      section.section_directors_assigned.length < 1
    )
      emptyDirectors = true;

    if (
      validateSectionNumber(section.sectionNumber, section.uuid, sectionsArr)
        .invalid
    )
      sectionNumberError = true;

    const descriptionLength =
      section?.description?.match(/\b\w+\b/gm)?.length || 0;

    if (
      !descriptionLength ||
      descriptionLength < 10 ||
      descriptionLength > descriptionMaxLength
    )
      descriptionError = true;
  });

  if (emptyDirectors) {
    return {
      invalid: true,
      message: 'Must enter at least one section director'
    };
  }
  if (sectionNumberError) {
    return {
      invalid: true,
      message: 'Section Number'
    };
  }

  if (descriptionError) {
    return {
      invalid: true,
      message: 'Section description must be between 10 and 2000 words'
    };
  }

  return { invalid: false };
};

const validateCourseParts = sectionsArr => {
  let emptyDirectors = false;
  let emptyTitle = false;
  let returnValue = { invalid: false };
  let noParts = false;
  let descriptionError = false;

  if (!sectionsArr || sectionsArr.length === 0) {
    return {
      invalid: true,
      message: 'You must create at least one part per section'
    };
  }

  sectionsArr.forEach(section => {
    if (!section.parts || section.parts.length < 1) {
      noParts = true;
    }
  });

  if (noParts) {
    return {
      invalid: true,
      message: 'You must create at least one part per section'
    };
  }

  sectionsArr.forEach(section => {
    section.parts.forEach(part => {
      if (!part.title || part.title === '') {
        emptyTitle = true;
      }
      if (_.size(part.title) > 100) {
        returnValue = {
          invalid: true,
          message: 'Part title must be less than 100 characters'
        };
      }
      if (
        !part.part_directors_assigned ||
        part.part_directors_assigned.length < 1
      ) {
        emptyDirectors = true;
      }

      const descriptionLength =
        part?.description?.match(/\b\w+\b/gm)?.length || 0;

      if (
        !descriptionLength ||
        descriptionLength < 10 ||
        descriptionLength > descriptionMaxLength
      )
        descriptionError = true;
    });
  });

  if (emptyDirectors) {
    returnValue = {
      invalid: true,
      message: 'All parts must have at least one director'
    };
  }

  if (emptyTitle) {
    returnValue = {
      invalid: true,
      message: 'All parts must have a part title'
    };
  }

  if (descriptionError) {
    return {
      invalid: true,
      message: 'Section description must be between 10 and 2000 words'
    };
  }

  return returnValue;
};

const validatePartTitle = title => {
  if (!title || title === '') {
    return {
      invalid: true,
      message: 'All parts must have a part title'
    };
  }
  if (_.size(title) > 100) {
    return {
      invalid: true,
      message: 'Part title must be less than 100 characters'
    };
  }
  return { invalid: false };
};

const validateCoursePartsExist = sectionsArr => {
  let noParts = false;

  if (!sectionsArr || sectionsArr.length === 0) {
    return {
      invalid: true,
      message: 'You must create at least one part per section'
    };
  }

  sectionsArr.forEach(section => {
    if (!section.parts || section.parts.length < 1) {
      noParts = true;
    }
  });

  if (noParts) {
    return {
      invalid: true,
      message: 'You must create at least one part per section'
    };
  }

  return { invalid: false };
};

export {
  validateCourseTitle,
  validateCourseSections,
  validateSectionNumber,
  validateCourseParts,
  validatePartTitle,
  validateCoursePartsExist
};
