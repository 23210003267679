import fetch from '../helpers/fetch';

const getCourse = courseUuid =>
  fetch(`/api/course/${courseUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCoursesByFilters = (courseMasterUuid, academicYearUuid, termUuid) =>
  fetch(
    `/api/courses/find-by-filters?courseMasterUuid=${courseMasterUuid}&academicYearUuid=${academicYearUuid}&termUuid=${termUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCoursesByDeptAy = (departmentUuid, academicYearUuid) =>
  fetch(
    `/api/courses/find-by-dept-ay?departmentUuid=${departmentUuid}&academicYearUuid=${academicYearUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postCourse = course =>
  fetch('/api/course/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(course)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putCourse = course =>
  fetch(`/api/course/${course.uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(course)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putCourseLock = course =>
  fetch('/api/course/lock', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(course)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteCourse = courseUuid =>
  fetch(`/api/course/${courseUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

const getCourseByStudent = userUuid =>
  fetch(`/api/courses-by-student/${userUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getCourse,
  getCoursesByFilters,
  getCoursesByDeptAy,
  getCourseByStudent,
  postCourse,
  putCourse,
  putCourseLock,
  deleteCourse
};
