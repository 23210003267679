import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { doGetApplicantsByProgram } from '../../../../redux/actions/userCohortActions';
import { selectApplicantRoles } from '../../../../redux/selectors/userCohortsSelectors';
import { doPostCloneTranscript } from '../../../../redux/actions/applicantTranscriptActions';

import TranscriptClone from './TranscriptClone';

export default function TranscriptCloneContainer({
  openModal,
  userUuid,
  programUuid,
  cohortUuid,
  handleCloneClose
}) {
  const dispatch = useDispatch();

  const applicantRoles = useSelector(state => {
    return selectApplicantRoles(state, userUuid, cohortUuid, programUuid);
  });

  const { createCloneTranscript, createCloneTranscriptResult } = useSelector(
    state => state.crudState
  );

  useEffect(() => {
    if (userUuid && programUuid) {
      dispatch(doGetApplicantsByProgram(userUuid, programUuid));
    }
  }, [dispatch, userUuid, programUuid]);

  useEffect(() => {
    if (createCloneTranscriptResult === 'success') {
      handleCloneClose();
      dispatch({ type: 'POST_CLONE_TRANSCRIPT_RESET' });
    }
    if (createCloneTranscriptResult === 'fail') {
      dispatch({ type: 'POST_CLONE_TRANSCRIPT_RESET' });
    }
  });

  const handleCloneTranscript = (fromCohortUuid, fromUserUuid) => {
    const toCohortUuid = cohortUuid;
    const toUserUuid = userUuid;

    dispatch(
      doPostCloneTranscript(
        fromCohortUuid,
        fromUserUuid,
        toCohortUuid,
        toUserUuid
      )
    );
  };

  return (
    <TranscriptClone
      loading={createCloneTranscript}
      applicantRoles={applicantRoles}
      openModal={openModal}
      handleCloneClose={handleCloneClose}
      handleCloneTranscript={handleCloneTranscript}
    />
  );
}

TranscriptCloneContainer.propTypes = {
  openModal: PropTypes.bool,
  userUuid: PropTypes.string,
  programUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  handleCloneClose: PropTypes.func
};

TranscriptCloneContainer.defaultProps = {
  openModal: false,
  userUuid: undefined,
  programUuid: undefined,
  cohortUuid: undefined,
  handleCloneClose: undefined
};
