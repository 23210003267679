import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import moment from 'moment';
// import _ from 'lodash';
import { Caption, Body1 } from '@xcomp/xcomp-design-library';
import PartList from '../Part/PartList';
import AccordionRowContainer from '../../Library/AccordionRow/AccordionRowContainer';

const SectionDetails = styled.div`
  padding-left: 74px;
  padding-right: 45px;
  padding-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

// const PartsPublished = styled(Caption)`
//   margin: 0 0 24px;
//   width: 100%;
// `;

const SectionAccordion = styled(
  ({ withBottomBorder, withTopBorder, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  width: 100%;

  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label div {
    font-size: 16px;
    color: ${props => props.theme.fontColors.darker};
  }

  .section_dates {
    padding-left: 16px;
    flex-grow: 2;

    span {
      font-size: 16px;
      color: ${props => props.theme.fontColors.dark};
    }
  }

  .section_icons {
    svg {
      color: ${props => props.theme.colors.grey[600]};
      &:hover {
        color: ${props => props.theme.colors.grey[900]};
      }
    }
  }

  .accordion_row_label {
    min-width: 200px;
  }
`;

const SectionDetailRow = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

const RowLeft = styled.div``;
const RowLabel = styled(Caption)`
  margin: 0;
`;

const RowValue = styled(Body1)`
  margin: 0;
  font-size: 14px;
  color: ${props => props.theme.fontColors.darker};
`;

const PartsOffset = styled.div`
  width: 100%;
  padding-left: 74px;
`;

const Section = ({
  courseUuid,
  sectionUuid,
  parts,
  section,
  directors,
  sectionNumber
}) => {
  const sectionIndex = `Section ${sectionNumber.toString().padStart(2, '0')}`;

  const sectionName =
    directors.length > 0
      ? `${sectionIndex} - ${directors[0].lastName}, ${directors[0].firstName}`
      : sectionIndex;

  // function getPartsPublished() {
  //   let complete = 0;
  //   let outstanding = 0;

  //   if (parts?.length > 0) {
  //     outstanding += parts.length;
  //     _.each(parts, part => {
  //       if (part.status === 'Commited') {
  //         complete += 1;
  //       }
  //     });
  //   }

  //   return `${complete}/${outstanding} part(s) published`;
  // }

  const termStart = section?.course?.term?.startDate;
  const termEnd = section?.course?.term?.endDate;

  const startDateSafe = moment(termStart);
  const endDateSafe = moment(termEnd);

  const startYear = startDateSafe.format('YYYY');
  const startDayOfMonth = startDateSafe.format('D');
  const startMonth = startDateSafe.format('MMMM');

  const endYear = endDateSafe.format('YYYY');
  const endDayOfMonth = endDateSafe.format('D');
  const endMonth = endDateSafe.format('MMMM');

  const termStartDisplay = `${startMonth} ${startDayOfMonth}, ${startYear}`;
  const termEndDisplay = `${endMonth} ${endDayOfMonth}, ${endYear}`;

  const termDatesDisplay = `${termStartDisplay} - ${termEndDisplay}`;

  return (
    <SectionAccordion
      withTopBorder={false}
      withBottomBorder={false}
      expandWidth="42px"
      height="60px"
      label={sectionName}
      columnOne={termDatesDisplay}
      columnOneClassName="section_dates"
      columnTwoClassName="section_icons"
      rowOffset="32px"
    >
      <SectionDetails>
        {/* <PartsPublished>{getPartsPublished()}</PartsPublished> */}
        <SectionDetailRow>
          <RowLeft>
            <RowLabel>Status</RowLabel>
            <RowValue>Open (fake)</RowValue>
          </RowLeft>
        </SectionDetailRow>
      </SectionDetails>
      <PartsOffset>
        <PartList
          parts={parts}
          termDatesDisplay={termDatesDisplay}
          sectionNumber={sectionNumber}
          sectionUuid={sectionUuid}
          courseUuid={courseUuid}
        />
      </PartsOffset>
    </SectionAccordion>
  );
};

Section.propTypes = {
  sectionUuid: PropTypes.string,
  directors: PropTypes.array,
  parts: PropTypes.array,
  section: PropTypes.object,
  courseUuid: PropTypes.string,
  sectionNumber: PropTypes.number
};
Section.defaultProps = {
  sectionUuid: undefined,
  directors: [],
  parts: [],
  section: PropTypes.object,
  sectionNumber: 0,
  courseUuid: ''
};

export default Section;
