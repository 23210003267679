import React, { useEffect, useReducer, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';

import {
  doSearchMicrocompetencyTopics,
  doGetCompetencyCollection,
  doClearCreated
} from '../../../../redux/actions/competencyCollectionActions';

import { doPutCompetencyAddMicros } from '../../../../redux/actions/competencyActions';

import { useUnsavedChanges } from '../../../Library/Modal/UnsavedChangesModal/useUnsavedChanges';
import { competencySelector } from '../../../../redux/selectors/competenciesSelector';
import { competencyCollectionSelector } from '../../../../redux/selectors/competencyCollectionsSelectors';
import ManageCompetencyMicros from './ManageCompetencyMicros';
import {
  initialMicroSearchState,
  microSearchReducer,
  sethaveFetchedCompetencyCollection,
  setShowResults
} from './microSearchState';

const ManageCompetencyMicrosContainer = () => {
  const { competencyCollectionUuid, competencyUuid } = useParams();
  const dispatch = useDispatch();
  // eslint-disable-next-line prefer-const
  let history = useHistory();

  const searchResultTopics = useSelector(
    state => state.searchState.microTopicSearchResults
  );

  const existingCompetencyCollection = useSelector(
    state => competencyCollectionSelector(state, competencyCollectionUuid),
    _.isEqual
  );

  const competency = useSelector(
    state => competencySelector(state, competencyUuid),
    shallowEqual
  );

  const successfullyUpdatedCompetency = useSelector(
    state => state.formSuccessState.successfullyUpdatedCompetency
  );

  const [state, searchDispatch] = useReducer(
    microSearchReducer,
    initialMicroSearchState
  );

  const {
    view,
    editTopicUuid,
    haveFetchedCompetencyCollection,
    showResults,
    searchType,
    searchForCompetencyUuid,
    addedMicros,
    micros,
    subtopics,
    topics,
    editTopicMicros
  } = state;

  const setExistingState = useCallback(
    existingCollection => {
      const thisCompetency = existingCollection.competencies.find(
        comp => comp.uuid === competencyUuid
      );

      searchDispatch({
        type: 'setExistingMicros',
        payload: {
          existingMicros: thisCompetency.microcompetencies,
          competencyUuid
        }
      });
    },
    [competencyUuid, searchDispatch]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!haveFetchedCompetencyCollection && !existingCompetencyCollection) {
      dispatch(doGetCompetencyCollection(competencyCollectionUuid));
      searchDispatch(sethaveFetchedCompetencyCollection());
    }
  }, [
    dispatch,
    existingCompetencyCollection,
    haveFetchedCompetencyCollection,
    competencyCollectionUuid
  ]);

  useEffect(() => {
    if (
      competencyUuid &&
      existingCompetencyCollection &&
      competencyUuid !== searchForCompetencyUuid
    ) {
      setExistingState(existingCompetencyCollection);
    }
  }, [
    setExistingState,
    searchForCompetencyUuid,
    competencyUuid,
    competencyCollectionUuid,
    existingCompetencyCollection
  ]);

  const clearForm = useCallback(() => {
    searchDispatch({
      type: 'clearForm'
    });
    history.push(
      `/program-management/competency-collection/${competencyCollectionUuid}/edit`
    );
  }, [competencyCollectionUuid, history]);

  useEffect(() => {
    const onClearCreated = () => dispatch(doClearCreated());

    if (successfullyUpdatedCompetency === true) {
      onClearCreated();
      clearForm();
    }
  }, [successfullyUpdatedCompetency, dispatch, clearForm]);

  const [
    isUnsavedChangesModalOpen,
    unsavedChanges,
    handleModalOpen,
    handleModalClose,
    setUnsavedChange,
    removeUnsavedChange,
    handleProceed
  ] = useUnsavedChanges(clearForm);

  const onPutUpdate = (uuid, micros) =>
    dispatch(doPutCompetencyAddMicros(uuid, micros));

  const buildValidPayload = addedMicrosObject => {
    const micros = [];

    Object.keys(addedMicrosObject).forEach(topicUuid => {
      const microsToAdd = Object.keys(addedMicrosObject[topicUuid].micros).map(
        microUuid => microUuid
      );

      micros.push(microsToAdd);
    });

    const flattenedMicros = _.flatten(micros);
    return flattenedMicros;
  };

  const handleUpdateSubmission = () => {
    const micros = buildValidPayload(addedMicros);
    onPutUpdate(competencyUuid, micros);
  };

  const onSearchClick = (searchType, searchQuery) => {
    if (searchQuery && searchQuery.length > 0) {
      dispatch(doSearchMicrocompetencyTopics(searchQuery, searchType));
    }
    searchDispatch(setShowResults(searchQuery && searchQuery.length > 0));
    searchDispatch({
      type: 'setSearchType',
      payload: {
        searchType
      }
    });
  };

  return (
    <ManageCompetencyMicros
      view={view}
      editTopicUuid={editTopicUuid}
      addedMicros={addedMicros}
      micros={micros}
      subtopics={subtopics}
      topics={topics}
      isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
      unsavedChanges={unsavedChanges}
      editTopicMicros={editTopicMicros}
      searchDispatch={searchDispatch}
      searchType={searchType}
      competency={competency}
      competencyUuid={competencyUuid}
      searchResultTopics={searchResultTopics}
      handleModalClose={handleModalClose}
      setUnsavedChange={setUnsavedChange}
      removeUnsavedChange={removeUnsavedChange}
      handleProceed={handleProceed}
      showResults={showResults}
      onSearchClick={onSearchClick}
      onSearchClose={handleModalOpen}
      onSubmit={handleUpdateSubmission}
    />
  );
};

export default ManageCompetencyMicrosContainer;
