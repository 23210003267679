import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { ButtonPrimary } from '@xcomp/xcomp-design-library';

import { ContainerResize, Panel } from '../../../Library/Layout';
import Header from '../../../Library/Header';
import FormSelect from '../../../Library/FormSelect';
import CohortSyllabusInfo from './CohortSyllabusInfo';
import SyllabusItemList from './SyllabusItemList';
import AssessmentFormContainer from './AssessmentForm/AssessmentFormContainer';
import NoItemsMessage from '../../../Library/NoItemsMessage';

export const HeaderSub = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.fontColors.dark};
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 2px;
`;

export const HeaderMain = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
`;

export const CourseNumber = styled.div``;
export const Coursetitle = styled.div`
  padding-left: 0.25rem;
`;

export const PartTitle = styled.div`
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
`;

export const SectionToggle = styled.span`
  font-size: 1.375rem;
  color: ${props => props.theme.fontColors.dark};
  font-weight: 300;
`;

export const SectionId = styled.div`
  padding-right: 0.25rem;
`;

export const PartId = styled.div`
  padding-left: 0.25rem;
`;

export const SyllabusStatus = styled.div`
  font-size: 12px;
  opacity: 0.8;
  background: ${props => props.theme.colors.primary};
  border-radius: 25px;
  color: ${props => props.theme.colors.white};
  padding: 4px 6px;
  margin-left: 10px;
  text-transform: none;
  align-items: center;
`;

export const TempSpan = styled.span`
  padding: 5px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
`;

const ButtonPrimarySC = styled(ButtonPrimary)`
  && {
    margin-left: 16px;
    min-width: 180px;
  }
`;

const Actions = styled.div`
  background: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-top: 80px;
  padding-bottom: 10px;
  padding-left: 70px;
  padding-right: 70px;
  height: 100%;
`;

const Layout = styled.div`
  && {
    width: 100%;
    height: 100%;
    padding-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const List = styled.div`
  width: 30%;
  height: 85%;
  position: fixed;
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Forms = styled.div`
  height: 85%;
  margin-left: 30%;
  overflow-y: scroll;
  position: fixed;
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
`;

const NoForm = styled.div`
  height: 85%;
  width: 70%;
  margin-left: 30%;
  overflow-y: scroll;
  position: fixed;
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
`;

const PanelSC = styled(Panel)`
  height: calc(100vh - 214px);
  display: flex;
  align-items: center;
`;

const SyllabusHeader = styled.div`
  background: ${props => props.theme.colors.white};
  padding: 1rem 2rem;
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  height: 12%;

  .MuiFormControl-root {
    margin-bottom: 0;
  }
`;

const SyllabusBody = styled.div`
  background: ${props => props.theme.colors.white};
  height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 200px;
`;

const EmptyItemsMessage = styled.div`
  padding: 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  p {
    font-size: 0.875rem;
    padding: 7rem 3rem;
    color: ${props => props.theme.fontColors.dark};
    line-height: 1.6;
  }
`;

const SyllabusSchedule = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const CohortAssessmentSyllabus = ({
  parentCohort,
  assessmentCollection,
  parentCohortUuid,
  cohortAssessments,
  assessmentFormUuid,
  setAssessmentFormUuid,
  isNewFormOpen,
  setIsNewFormOpen,
  setClearForm
}) => {
  const openNewForm = () => setIsNewFormOpen(true);

  return (
    <ContainerResize>
      <Header
        title="Cohort Assessment Collection"
        subtitle={`${parentCohort?.name} - ${assessmentCollection.title}`}
        backButtonLocation={`/program-management/cohort/${parentCohortUuid}/requirements`}
      />
      <Actions>
        <CohortSyllabusInfo
          parentCohort={parentCohort}
          assessmentCollection={assessmentCollection}
        />
      </Actions>

      <Layout>
        <List>
          <SyllabusSchedule>
            <SyllabusHeader>
              <FormSelect
                value="none"
                options={[
                  { label: '', value: 'none' },
                  { label: 'In Progress', value: 'in_progress' },
                  { label: 'Complete', value: 'complete' },
                  { label: 'Approved', value: 'approved' }
                ]}
                handleChange={undefined}
                name="status"
                label="Filter By Status"
              />
              <ButtonPrimarySC onClick={openNewForm}>
                New Assessment
              </ButtonPrimarySC>
            </SyllabusHeader>

            <SyllabusBody>
              {cohortAssessments && cohortAssessments.length > 0 ? (
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <SyllabusItemList
                      syllabusItems={cohortAssessments}
                      assessmentFormUuid={assessmentFormUuid}
                      changeAssessment={setAssessmentFormUuid}
                    />
                  </Grid>
                </Grid>
              ) : (
                <EmptyItemsMessage>
                  <p>
                    Please add an assessment to begin creating your syllabus.
                  </p>
                </EmptyItemsMessage>
              )}
            </SyllabusBody>
          </SyllabusSchedule>
        </List>
        {isNewFormOpen || assessmentFormUuid ? (
          <Forms>
            {isNewFormOpen ? (
              <AssessmentFormContainer
                facultyOptions={[]}
                restrictEdit={false}
                parentCohort={parentCohort}
                parentCollection={assessmentCollection}
                setAssessmentFormUuid={setAssessmentFormUuid}
                setClearForm={setClearForm}
                isNew
              />
            ) : (
              <AssessmentFormContainer
                existingAssessmentUuid={assessmentFormUuid}
                facultyOptions={[]}
                restrictEdit={false}
                parentCohort={parentCohort}
                parentCollection={assessmentCollection}
                setClearForm={setClearForm}
              />
            )}
          </Forms>
        ) : (
          <NoForm>
            <PanelSC>
              <NoItemsMessage message="Select or Add a Cohort Assessment" />
            </PanelSC>
          </NoForm>
        )}
      </Layout>
    </ContainerResize>
  );
};

CohortAssessmentSyllabus.propTypes = {
  parentCohort: PropTypes.object,
  parentCohortUuid: PropTypes.string.isRequired,
  cohortAssessments: PropTypes.array.isRequired,
  assessmentCollection: PropTypes.object,
  assessmentFormUuid: PropTypes.string,
  setAssessmentFormUuid: PropTypes.func,
  isNewFormOpen: PropTypes.bool,
  setIsNewFormOpen: PropTypes.func,
  setClearForm: PropTypes.func
};
CohortAssessmentSyllabus.defaultProps = {
  assessmentCollection: { title: '' },
  parentCohort: { name: '' },
  assessmentFormUuid: '',
  setAssessmentFormUuid: undefined,
  isNewFormOpen: false,
  setIsNewFormOpen: undefined,
  setClearForm: undefined
};

export default CohortAssessmentSyllabus;
