import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import {
  EditIcon,
  DocumentIcon,
  InstituteIdIcon
} from '@xcomp/xcomp-design-library/dist/icons';
import { Link } from 'react-router-dom';

import { rolesMap, cohortRoleStatusMap } from '../../../helpers/constants';
import ThirdPartyId from './ThirdParty/ThirdPartyId';

import { useCohortApplicantsDispatch } from './cohortApplicants.context';

const Edit = styled.div`
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
`;

const ThirdBox = styled.div`
  display: flex;
  align-items: center;
`;

const TranscriptBox = styled.div`
  display: flex;
  align-items: center;
`;

const StatusBox = styled.div`
  letter-spacing: 0.4px;
`;

const RoleBox = styled.div``;

const SelectAllBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Row = styled.div`
  font-size: 14px;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
`;

function CohortApplicantRow({ cohortUser, handleThirdPartyOpen, isAdmin }) {
  const cohortApplicantsDispatch = useCohortApplicantsDispatch();

  const handleSingleSelect = event => {
    if (event.target.checked) {
      cohortApplicantsDispatch({
        type: 'select-single',
        cohortUserUuid: cohortUser.uuid
      });
    } else {
      cohortApplicantsDispatch({
        type: 'deselect-single',
        cohortUserUuid: cohortUser.uuid
      });
    }
  };

  return (
    <Row>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item sm={2} xs={2}>
          {cohortUser?.user?.institutionId}
        </Grid>
        <Grid item sm={isAdmin ? 2 : 3} xs={isAdmin ? 2 : 3}>
          {cohortUser?.formattedName}
        </Grid>
        {isAdmin &&
          <Grid item sm={1} xs={1}>
            <Edit>
              <Link
                to={`/program-management/cohort/${cohortUser?.cohortUuid}/user/${cohortUser?.userUuid}/edit`}
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Link>
            </Edit>
          </Grid>
        }
        <Grid item sm={1} xs={1}>
          <StatusBox>{cohortRoleStatusMap[cohortUser?.status]}</StatusBox>
        </Grid>
        <Grid item sm={1} xs={1}>
          <RoleBox>{rolesMap[cohortUser?.role]}</RoleBox>
        </Grid>
        <Grid item sm={isAdmin ? 2 : 3} xs={isAdmin ? 2 : 3}>
          <ThirdBox>
            {isAdmin 
              ?
                <IconButton onClick={() => handleThirdPartyOpen(cohortUser?.userUuid)}>
                  <InstituteIdIcon />
                </IconButton>
              :
                <InstituteIdIcon style={{marginRight: 5}} />
            }
            <ThirdPartyId userUuid={cohortUser?.userUuid} />
          </ThirdBox>
        </Grid>
        <Grid item sm={1} xs={1}>
          <TranscriptBox>
            <Link
              to={`/program-management/cohort/applicant-transcript?cohortUuid=${cohortUser?.cohortUuid}&userUuid=${cohortUser?.userUuid}`}
            >
              <IconButton>
                <DocumentIcon />
              </IconButton>
            </Link>
          </TranscriptBox>
        </Grid>
        {isAdmin &&
          <Grid item sm={1} xs={1}>
            <SelectAllBox>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cohortUser?.selected}
                    onChange={event => handleSingleSelect(event)}
                    color="primary"
                  />
                }
              />
            </SelectAllBox>
          </Grid>
        }
      </Grid>
    </Row>
  );
}

CohortApplicantRow.propTypes = {
  cohortUser: PropTypes.object,
  handleThirdPartyOpen: PropTypes.func,
  isAdmin: PropTypes.bool,
};
CohortApplicantRow.defaultProps = {
  cohortUser: {},
  handleThirdPartyOpen: undefined,
  isAdmin: false,
};

export default CohortApplicantRow;
