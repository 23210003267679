import fetch from '../helpers/fetch';

const getAssessment = assessmentUuid =>
  fetch(`/api/assessment/${assessmentUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getAssessmentsByPart = partUuid =>
  fetch(`/api/assessments/find-by-part?partUuid=${partUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getAssessmentAssociations = () =>
  fetch(`/api/assessment-associations`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getAssessmentTechniques = () =>
  fetch(`/api/assessment-techniques`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getAssessmentTypes = () =>
  fetch(`/api/assessment-types`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postAssessment = assessment =>
  fetch('/api/assessment/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(assessment)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putAssessmentDraft = assessment =>
  fetch(`/api/assessment/${assessment.uuid}/draft`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(assessment)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putAssessmentStatusScore = assessment =>
  fetch(`/api/assessment/${assessment.uuid}/assessment-status`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      ...assessment
    })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putAssessmentStatusCommit = assessment =>
  fetch(`/api/assessment/${assessment.uuid}/commitment-status`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      statusCommit: assessment.statusCommit,
      forceGrading: assessment.forceGrading,
      justificationMessage: assessment.justificationMessage
    })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putAssessmentFinal = assessment =>
  fetch(`/api/assessment/${assessment.uuid}/final`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(assessment)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchAssessmentApprove = assessmentUuid =>
  fetch(`/api/assessment/${assessmentUuid}/approval`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteAssessment = assessmentUuid =>
  fetch(`/api/assessment/${assessmentUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putDelayedStudentAssessmentGrade = ({
  assessmentUuid,
  studentUuid,
  resultCardUuid
}) =>
  fetch(
    `/api/assessment/${assessmentUuid}/student/${studentUuid}/grade-delayed-student`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        resultCardUuid
      })
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putDelayedStudentAssessmentCommitStatus = ({
  assessmentUuid,
  studentUuid,
  delayedStatusCommit,
  justificationMessage
}) =>
  fetch(
    `/api/assessment/${assessmentUuid}/student/${studentUuid}/commitment-status`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        delayedStatusCommit,
        justificationMessage
      })
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteDelayedStudentAssessmentGrade = ({
  assessmentUuid,
  studentUuid,
  resultCardUuid
}) =>
  fetch(
    `/api/assessment/${assessmentUuid}/student/${studentUuid}/delayed-student-grade`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        resultCardUuid
      })
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  deleteAssessment,
  getAssessment,
  getAssessmentAssociations,
  getAssessmentsByPart,
  getAssessmentTechniques,
  getAssessmentTypes,
  patchAssessmentApprove,
  postAssessment,
  putAssessmentDraft,
  putAssessmentFinal,
  putAssessmentStatusCommit,
  putAssessmentStatusScore,
  putDelayedStudentAssessmentGrade,
  putDelayedStudentAssessmentCommitStatus,
  deleteDelayedStudentAssessmentGrade
};
