import React from 'react';
import PropTypes from 'prop-types';
import SectionPerformanceTableContainer from './SectionPerformanceTable/SectionPerformanceTableContainer';
import SectionPerformanceTranscript from './SectionPerformanceTranscript/SectionPerformanceTranscript';

const SectionPerformanceReport = ({
  activeSectionPerformanceReport,
  courseCredits,
  delayedOrWithdrawnSectionPerformanceReport,
  isLoadingReport,
  parts,
  reportView,
  termPeriod,
  courseNumber,
  courseTitle,
  sectionNumber,
  sectionPerformanceReport
}) => {
  if (reportView === 'transcript')
    return (
      <SectionPerformanceTranscript
        courseCredits={courseCredits}
        courseNumber={courseNumber}
        courseTitle={courseTitle}
        isLoadingReport={isLoadingReport}
        parts={parts}
        sectionNumber={sectionNumber}
        sectionPerformanceReport={sectionPerformanceReport}
        termPeriod={termPeriod}
      />
    );
  return (
    <SectionPerformanceTableContainer
      activeSectionPerformanceReport={activeSectionPerformanceReport}
      delayedOrWithdrawnSectionPerformanceReport={
        delayedOrWithdrawnSectionPerformanceReport
      }
      isLoadingReport={isLoadingReport}
      parts={parts}
      sectionPerformanceReport={sectionPerformanceReport}
    />
  );
};

SectionPerformanceReport.propTypes = {
  activeSectionPerformanceReport: PropTypes.array,
  delayedOrWithdrawnSectionPerformanceReport: PropTypes.array,
  isLoadingReport: PropTypes.bool,
  parts: PropTypes.array,
  reportView: PropTypes.string,
  courseCredits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  termPeriod: PropTypes.string,
  courseNumber: PropTypes.string,
  courseTitle: PropTypes.string,
  sectionNumber: PropTypes.string,
  sectionPerformanceReport: PropTypes.array
};

SectionPerformanceReport.defaultProps = {
  activeSectionPerformanceReport: [],
  delayedOrWithdrawnSectionPerformanceReport: [],
  isLoadingReport: false,
  parts: [],
  reportView: '',
  courseCredits: '',
  termPeriod: '',
  courseNumber: '',
  courseTitle: '',
  sectionNumber: '',
  sectionPerformanceReport: []
};

export default SectionPerformanceReport;
