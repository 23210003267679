import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import CourseEdit from './CourseEdit';

import {
  doSetSelectedInitialEditorState,
  doSetSelectedDepartmentCourseEdit,
  doSetSelectedCourseMasterCourseEdit,
  doSetSelectedAcademicYearCourseEdit,
  doSetSelectedTermCourseEdit
} from '../../../../redux/actions/courseEditActions';
import { doGetCoursesMasterByDepartment } from '../../../../redux/actions/courseMasterActions';
import {
  doGetCourse,
  doDeleteCourse,
  doPutCourse,
  doPutCourseLock,
  doSetIsLoading
} from '../../../../redux/actions/courseActions';

import { doGetDepartments } from '../../../../redux/actions/departmentActions';
import { doGetAcademicYearsBySchool } from '../../../../redux/actions/academicYearActions';
import { doGetTerms } from '../../../../redux/actions/termActions';
import { doGetUsersFaculty } from '../../../../redux/actions/userDepartmentActions';
import { courseEditCourseSelector } from '../../../../redux/selectors/coursesSelectors';
import { departmentsSelector } from '../../../../redux/selectors/departmentsSelectors';
import { academicYearsSelectorBySchool } from '../../../../redux/selectors/academicYearsSelectors';
import { termsAcademicYearSelectorCourseEdit } from '../../../../redux/selectors/termsSelectors';
import { coursesMasterSelectorByDepartmentCourseEdit } from '../../../../redux/selectors/coursesMasterSelectors';
import { usersSelectorByDepartmentFaculty } from '../../../../redux/selectors/usersSelectors';

import { doSetDepartmentFilterForCourse } from '../../../../redux/actions/filterActions';

import {
  validateCourseSections,
  validateCourseParts
} from '../../../../helpers/validation/validateCourse';

import { validateSelectField } from '../../../../helpers/validation/validateGeneric';

import {
  generateSectionNumber,
  generatePartNumber
} from '../../../../helpers/generators';

import { reorderArray } from '../../../../helpers/utilities';

import DeleteModalRedirect from '../../../Library/Modal/DeleteModal/DeleteModalRedirect';
import { AllDepartmentFacultyOption } from '../PartForm/PartForm';

class CourseEditContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      departmentUuid: '',
      courseMasterUuid: '',
      academicYearUuid: '',
      termUuid: '',
      sections: [
        {
          uuid: uuidv4().toString(),
          courseId: '',
          sectionNumber: 0,
          section_directors_assigned: [],
          parts: [
            {
              uuid: uuidv4().toString(),
              title: '',
              part_directors_assigned: [],
              part_faculty_assigned: []
            }
          ]
        }
      ],
      hasErrors: false,
      sectionsToDelete: [],
      partsToDelete: [],
      modalOpen: false,
      selectedCourseDelete: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSectionDirectorChange =
      this.handleSectionDirectorChange.bind(this);
    this.handleSectionInputChange = this.handleSectionInputChange.bind(this);
    this.handleAddSection = this.handleAddSection.bind(this);
    this.handleSectionDelete = this.handleSectionDelete.bind(this);
    this.handlePartInputChange = this.handlePartInputChange.bind(this);
    this.handlePartNumberChange = this.handlePartNumberChange.bind(this);
    this.handlePartDirectorChange = this.handlePartDirectorChange.bind(this);
    this.handleCourseSubmission = this.handleCourseSubmission.bind(this);
    this.hasValidationErrors = this.hasValidationErrors.bind(this);
    this.setFormState = this.setFormState.bind(this);
    this.handleDeleteModalOpen = this.handleDeleteModalOpen.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleLockCourse = this.handleLockCourse.bind(this);
    this.handleUnlockCourse = this.handleUnlockCourse.bind(this);
  }

  componentDidMount() {
    this.props.onGetDepartments();
    this.props.onGetAcademicYearsBySchool(this.props.selectedSchoolUuid);

    this.props.onGetCourse(this.props.match.params.courseUuid);
  }

  componentDidUpdate(prevProps, prevState) {
    const { course } = this.props;

    if (prevProps.course !== course) {
      this.setFormState(course);
      this.props.onSetSelectedInitialEditorState(course);
    }

    if (prevState.departmentUuid !== this.state.departmentUuid) {
      this.props.onGetCourseMasterByDepartment(this.state.departmentUuid);
      this.props.onSetDepartmentFilterForCourse(this.state.departmentUuid);
      this.props.onGetUsersFaculty(this.state.departmentUuid);
    }

    if (prevState.academicYearUuid !== this.state.academicYearUuid) {
      this.props.onGetTerms(this.state.academicYearUuid);
    }
  }

  setFormState = course => {
    const sortedSections = [...course.sections];

    sortedSections.sort(
      (sectionA, sectionB) => sectionA.sectionNumber - sectionB.sectionNumber
    );

    const sections = sortedSections.map(s => ({
      ...s,
      parts: s.parts.map(p => ({
        ...p, 
        part_faculty_assigned: p.allDepartmentFaculty ? [{uuid: AllDepartmentFacultyOption.value}] : p.part_faculty_assigned
      }))
    }))

    this.setState({
      ...course,
      departmentUuid: course.course_master.department.uuid,
      courseMasterUuid: course.course_master.uuid,
      academicYearUuid: course.academic_year.uuid,
      termUuid: course.term.uuid,
      sections,
      sectionsToDelete: [],
      partsToDelete: []
    });
  };

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({ [name]: value });

    if (name === 'departmentUuid') {
      this.props.onSetSelectedDepartment(value);
      this.setState({
        courseMasterUuid: ''
      });
    }

    if (name === 'courseMasterUuid') {
      this.props.onSetSelectedCourseMaster(value);
    }

    if (name === 'academicYearUuid') {
      this.props.onSetSelectedAcademicYear(value);
      this.setState({
        termUuid: ''
      });
    }

    if (name === 'termUuid') {
      this.props.onSetSelectedTerm(value);
    }
  };

  handleSectionDirectorChange = (directorsArr, id) => {
    const newDirectors = directorsArr.map(director => {
      const thisDirector =
        typeof director === 'string' ? director : director.value;
      return { uuid: thisDirector };
    });

    this.setState(prevState => {
      const newState = _.cloneDeep(prevState.sections);
      const location = _.findIndex(newState, obj => obj.uuid === id);
      newState[location].section_directors_assigned = newDirectors;
      return { sections: newState };
    });
  };

  handleSectionInputChange = (event, id) => {
    let newValue = event.target.value;
    const fieldToChange = event.target.name;

    if (fieldToChange === 'sectionNumber') {
      newValue = Number(newValue);
    }

    this.setState(prevState => {
      const newState = _.cloneDeep(prevState.sections);
      const location = _.findIndex(newState, obj => obj.uuid === id);
      newState[location][fieldToChange] =
        fieldToChange === 'courseId' ? newValue.toUpperCase() : newValue;
      return { sections: newState };
    });
  };

  handleAddSection = () => {
    this.setState(prevState => {
      const newSections = prevState.sections.concat({
        uuid: uuidv4().toString(),
        courseId: '',
        sectionNumber: generateSectionNumber(prevState.sections),
        section_directors_assigned: [],
        isNewSection: true,
        parts: [
          {
            uuid: uuidv4().toString(),
            title: '',
            partNumber: generatePartNumber(),
            part_directors_assigned: [],
            part_faculty_assigned: []
          }
        ]
      });
      return {
        sections: newSections
      };
    });
  };

  handleSectionDelete = id => {
    this.setState(prevState => {
      const newState = _.cloneDeep(prevState.sections);
      const location = _.findIndex(newState, obj => obj.uuid === id);
      if (location !== -1) {
        newState.splice(location, 1);
      }
      return {
        sections: newState,
        sectionsToDelete: prevState.sectionsToDelete.concat(id)
      };
    });
  };

  handlePartInputChange = (event, sectionId, partId) => {
    const newValue = event.target.value;
    const fieldToChange = event.target.name;

    this.setState(prevState => {
      const newSectionState = _.cloneDeep(prevState.sections);
      const sectionLocation = _.findIndex(
        newSectionState,
        obj => obj.uuid === sectionId
      );
      const partLocation = _.findIndex(
        newSectionState[sectionLocation].parts,
        obj => obj.uuid === partId
      );

      newSectionState[sectionLocation].parts[partLocation][fieldToChange] =
        newValue;
      return { sections: newSectionState };
    });
  };

  handlePartNumberChange = (partId, sectionId, moveUp) => {
    if (!partId || !sectionId) {
      return null;
    }

    this.setState(prevState => {
      const newSectionState = _.cloneDeep(prevState.sections);
      const sectionLocation = _.findIndex(
        newSectionState,
        obj => obj.uuid === sectionId
      );
      const partLocation = _.findIndex(
        newSectionState[sectionLocation].parts,
        obj => obj.uuid === partId
      );
      const thisSection = newSectionState[sectionLocation];
      const thisPart = thisSection.parts[partLocation];

      if (moveUp && thisPart.partNumber === 1) {
        return null;
      } else if (!moveUp && thisPart.partNumber === thisSection.parts.length) {
        return null;
      }

      if (moveUp) {
        thisSection.parts[partLocation - 1].partNumber = thisPart.partNumber;
        thisPart.partNumber -= 1;
      } else {
        thisSection.parts[partLocation + 1].partNumber = thisPart.partNumber;
        thisPart.partNumber += 1;
      }

      thisSection.parts = thisSection.parts.sort(
        (a, b) => a.partNumber - b.partNumber
      );

      return { sections: newSectionState };
    });
  };

  handlePartDirectorChange = (
    selectedDirectors,
    sectionUuid,
    partUuid,
    fieldName
  ) => {
    const filteredDirectors = selectedDirectors.includes(AllDepartmentFacultyOption.value) ? selectedDirectors.filter(sd => sd === AllDepartmentFacultyOption.value) : selectedDirectors;
    const newDirectors = filteredDirectors.map(director => {
      const addDirector =
        typeof director === 'string' ? director : director.value;
      return { uuid: addDirector };
    });

    this.setState(prevState => {
      const newSectionState = _.cloneDeep(prevState.sections);
      const sectionLocation = _.findIndex(
        newSectionState,
        obj => obj.uuid === sectionUuid
      );
      const partLocation = _.findIndex(
        newSectionState[sectionLocation].parts,
        obj => obj.uuid === partUuid
      );
      newSectionState[sectionLocation].parts[partLocation][fieldName] =
        newDirectors;
      return { sections: newSectionState };
    });
  };

  handleAddPart = sectionId => {
    this.setState(prevState => {
      const newSections = _.cloneDeep(prevState.sections);
      const location = _.findIndex(newSections, obj => obj.uuid === sectionId);
      newSections[location].parts = newSections[location].parts.concat({
        uuid: uuidv4().toString(),
        title: '',
        partNumber: generatePartNumber(newSections[location].parts),
        part_directors_assigned: [],
        part_faculty_assigned: []
      });
      return {
        sections: newSections
      };
    });
  };

  handlePartDelete = (sectionId, partId) => {
    this.setState(prevState => {
      const newSections = _.cloneDeep(prevState.sections);
      const sectionLocation = _.findIndex(
        newSections,
        obj => obj.uuid === sectionId
      );
      const thisSection = newSections[sectionLocation];
      const partLocation = _.findIndex(
        thisSection.parts,
        obj => obj.uuid === partId
      );
      const thisPart = thisSection.parts[partLocation];

      if (partLocation !== -1) {
        const deletedPartNumber = thisPart.partNumber;
        thisSection.parts.splice(partLocation, 1);

        thisSection.parts = reorderArray(
          thisSection.parts,
          deletedPartNumber,
          'partNumber'
        );
      }
      return {
        sections: newSections,
        partsToDelete: prevState.partsToDelete.concat(partId)
      };
    });
  };

  handleCourseSubmission = () => {
    const { hasErrors, ...course } = this.state;
    const { descriptions } = this.props;

    const updatedCourse = {
      ...course,
      sections: course?.sections?.map((s, i) => ({
        ...s,
        description:
          descriptions?.sections[s.uuid] || course?.sections?.[i]?.description,
        parts: s?.parts?.map((p, i) => ({
          ...p,
          description:
            descriptions?.parts?.[p.uuid] || s?.parts?.[i]?.description
        }))
      }))
    };

    const hasValidationErrors = this.hasValidationErrors();

    this.setState({
      hasErrors: hasValidationErrors
    });

    if (!hasValidationErrors) {
      this.props.onSetIsLoading(true);
      this.props.onPutCourse(updatedCourse);
    }
  };

  hasValidationErrors = () => {
    let validationErrors = false;

    const { courseMasterUuid, academicYearUuid, termUuid, sections } =
      this.state;

    const { descriptions } = this.props;

    const updatedSections = sections.map((s, i) => ({
      ...s,
      description: descriptions.sections[s.uuid] || sections?.[i]?.description,
      parts: s.parts.map((p, i) => ({
        ...p,
        description: descriptions?.parts?.[p.uuid] || s?.parts?.[i]?.description
      }))
    }));

    const academicYearError = validateSelectField(academicYearUuid);
    const termError = validateSelectField(termUuid);
    const courseMasterError = validateSelectField(courseMasterUuid);
    const sectionsError = validateCourseSections(updatedSections);
    const partError = validateCourseParts(updatedSections);

    if (academicYearError.invalid) {
      validationErrors = true;
    }
    if (termError.invalid) {
      validationErrors = true;
    }

    if (courseMasterError.invalid) {
      validationErrors = true;
    }

    if (sectionsError.invalid) {
      validationErrors = true;
    }
    if (partError.invalid) {
      validationErrors = true;
    }

    return validationErrors;
  };

  handleDeleteModalOpen = (event, courseUuid) => {
    this.setState({
      modalOpen: true,
      selectedCourseDelete: courseUuid
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      modalOpen: false,
      selectedCourseDelete: ''
    });
  };

  handleDelete = () => {
    this.props.onDeleteCourse(this.state.uuid);

    this.setState({
      modalOpen: false,
      selectedCourseDelete: ''
    });
  };

  handleLockCourse = () => {
    this.props.onPutCourseLock({
      courseUuid: this.state.uuid,
      locked: true
    });
  };

  handleUnlockCourse = () => {
    this.props.onPutCourseLock({
      courseUuid: this.state.uuid,
      locked: false
    });
  };

  render() {
    const { hasErrors, modalOpen, selectedCourseDelete, locked, ...course } =
      this.state;

    const {
      departments,
      coursesMaster,
      academicYears,
      terms,
      facultyUsers,
      selectedSchool
    } = this.props;

    const courseMaster = _.find(
      coursesMaster,
      courseMaster => courseMaster.uuid === this.state.courseMasterUuid
    );

    const department = _.find(
      departments,
      department => department.uuid === this.state.departmentUuid
    );

    return (
      <div>
        <CourseEdit
          {...course}
          courseLock={locked}
          hasErrors={hasErrors}
          departments={departments}
          coursesMaster={coursesMaster}
          academicYears={academicYears}
          terms={terms}
          facultyUsers={facultyUsers}
          selectedCourseMaster={courseMaster}
          selectedDepartment={department}
          selectedSchool={selectedSchool}
          handleChange={event => this.handleChange(event)}
          handleSectionDirectorChange={(event, id) =>
            this.handleSectionDirectorChange(event, id)
          }
          handleSectionInputChange={(event, id) =>
            this.handleSectionInputChange(event, id)
          }
          handleAddSection={() => this.handleAddSection()}
          onPutCourse={() => this.handleCourseSubmission()}
          handleSectionDelete={id => this.handleSectionDelete(id)}
          handlePartInputChange={(event, sectionId, partId) =>
            this.handlePartInputChange(event, sectionId, partId)
          }
          handlePartNumberChange={(partId, sectionId, moveUp) =>
            this.handlePartNumberChange(partId, sectionId, moveUp)
          }
          handlePartDirectorChange={(event, sectionId, partId, fieldName) =>
            this.handlePartDirectorChange(event, sectionId, partId, fieldName)
          }
          handleAddPart={sectionId => this.handleAddPart(sectionId)}
          handlePartDelete={(sectionId, partId) =>
            this.handlePartDelete(sectionId, partId)
          }
          handleDeleteModalOpen={this.handleDeleteModalOpen}
          handleUnlockCourse={this.handleUnlockCourse}
          handleLockCourse={this.handleLockCourse}
        />
        <DeleteModalRedirect
          modalOpen={modalOpen}
          type="course"
          link="/syllabus-management/all"
          handleModalClose={() => this.handleDeleteModalClose()}
          handleDelete={() => this.handleDelete()}
        />
      </div>
    );
  }
}

CourseEditContainer.propTypes = {
  course: PropTypes.object,
  descriptions: PropTypes.object,
  departments: PropTypes.arrayOf(PropTypes.object),
  coursesMaster: PropTypes.arrayOf(PropTypes.object),
  terms: PropTypes.arrayOf(PropTypes.object),
  academicYears: PropTypes.arrayOf(PropTypes.object),
  facultyUsers: PropTypes.arrayOf(PropTypes.object),
  onPostUpdateCoure: PropTypes.func,
  successfullyCreated: PropTypes.string,
  match: PropTypes.object,
  selectedSchoolUuid: PropTypes.string,
  selectedSchool: PropTypes.string,
  onGetDepartments: PropTypes.func,
  onGetCourse: PropTypes.func,
  onSetSelectedCourseId: PropTypes.func,
  onGetAcademicYearsBySchool: PropTypes.func,
  onGetTerms: PropTypes.func,
  onPutCourse: PropTypes.func,
  onGetUsersFaculty: PropTypes.func,
  onGetCourseMasterByDepartment: PropTypes.func,
  onSetSelectedInitialEditorState: PropTypes.func,
  onSetSelectedDepartment: PropTypes.func,
  onSetSelectedCourseMaster: PropTypes.func,
  onSetSelectedAcademicYear: PropTypes.func,
  onSetSelectedTerm: PropTypes.func,
  onDeleteCourse: PropTypes.func,
  onPutCourseLock: PropTypes.func,
  onSetDepartmentFilterForCourse: PropTypes.func,
  onSetIsLoading: PropTypes.func
};

CourseEditContainer.defaultProps = {
  course: {},
  descriptions: {},
  departments: [],
  coursesMaster: [],
  academicYears: [],
  terms: [],
  facultyUsers: [],
  onPostUpdateCoure: undefined,
  successfullyCreated: '',
  match: {},
  selectedSchoolUuid: '',
  selectedSchool: '',
  onGetDepartments: undefined,
  onGetCourse: undefined,
  onSetSelectedCourseId: undefined,
  onGetAcademicYearsBySchool: undefined,
  onGetTerms: undefined,
  onPutCourse: undefined,
  onGetUsersFaculty: undefined,
  onGetCourseMasterByDepartment: undefined,
  onSetSelectedInitialEditorState: undefined,
  onSetSelectedDepartment: undefined,
  onSetSelectedCourseMaster: undefined,
  onSetSelectedAcademicYear: undefined,
  onSetSelectedTerm: undefined,
  onDeleteCourse: undefined,
  onPutCourseLock: undefined,
  onSetDepartmentFilterForCourse: undefined,
  onSetIsLoading: undefined
};

const mapStateToProps = (state, props) => {
  const { notificationState, userState, courseEditState } = state;

  return {
    course: courseEditCourseSelector(state, props),
    descriptions: state.descriptionState,
    departments: departmentsSelector(state),
    coursesMaster: coursesMasterSelectorByDepartmentCourseEdit(state),
    academicYears: academicYearsSelectorBySchool(state),
    terms: termsAcademicYearSelectorCourseEdit(state),
    facultyUsers: usersSelectorByDepartmentFaculty(state),
    selectedDepartmentUuid: courseEditState.selectedDepartmentUuid,
    selectedCourseMasterUuid: courseEditState.selectedCourseMasterUuid,
    selectedAcademicYearUuid: courseEditState.selectedAcademicYearUuid,
    selectedTermUuid: courseEditState.selectedTermUuid,
    selectedSchoolUuid: userState.selectedSchoolUuid,
    selectedSchool: userState.selectedSchool,
    notifications: notificationState,
    successfullyCreated: notificationState.message
  };
};

const mapDispatchToProps = dispatch => ({
  onPutCourse: course => dispatch(doPutCourse(course)),
  onGetCourse: courseUuid => dispatch(doGetCourse(courseUuid)),
  onGetCourseMasterByDepartment: departmentUuid =>
    dispatch(doGetCoursesMasterByDepartment(departmentUuid)),
  onGetDepartments: () => dispatch(doGetDepartments()),
  onGetAcademicYearsBySchool: schoolUuid =>
    dispatch(doGetAcademicYearsBySchool(schoolUuid)),
  onGetTerms: academicYearUuid => dispatch(doGetTerms(academicYearUuid)),
  onGetUsersFaculty: departmentUuid =>
    dispatch(doGetUsersFaculty(departmentUuid)),
  onSetSelectedInitialEditorState: selections =>
    dispatch(doSetSelectedInitialEditorState(selections)),
  onSetSelectedDepartment: departmentUuid =>
    dispatch(doSetSelectedDepartmentCourseEdit(departmentUuid)),
  onSetSelectedCourseMaster: courseMasterUuid =>
    dispatch(doSetSelectedCourseMasterCourseEdit(courseMasterUuid)),
  onSetSelectedAcademicYear: academicYearUuid =>
    dispatch(doSetSelectedAcademicYearCourseEdit(academicYearUuid)),
  onSetSelectedTerm: termUuid =>
    dispatch(doSetSelectedTermCourseEdit(termUuid)),
  onDeleteCourse: courseUuid => dispatch(doDeleteCourse(courseUuid)),
  onPutCourseLock: course => dispatch(doPutCourseLock(course)),
  onSetDepartmentFilterForCourse: departmentUuid =>
    dispatch(doSetDepartmentFilterForCourse(departmentUuid)),
  onSetIsLoading: isLoading => dispatch(doSetIsLoading(isLoading))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEditContainer);
