import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from '@material-ui/core';

const FormControlSC = styled(FormControl)`
  && {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;

const FormHelperTextSC = styled(FormHelperText)`
  position: absolute;
  top: 60px;
`;

export default function FormSelectError({
  className,
  hasErrors,
  name,
  value,
  label,
  handleChange,
  handleValidation,
  options,
  id,
  displayEmpty,
  ...rest
}) {
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const fieldIsInvalid = handleValidation
    ? handleValidation(value)
    : { invalid: false };

  const selected =
    _.findIndex(options, o => {
      return o.value === value;
    }) === -1
      ? ''
      : value;

  return (
    <FormControlSC
      variant="outlined"
      error={hasErrors && fieldIsInvalid.invalid}
      className={className}
      {...rest}
    >
      <InputLabel shrink={true} ref={inputLabel}>
        {label}
      </InputLabel>
      <Select
        name={name}
        value={selected}
        displayEmpty={displayEmpty}
        onChange={event => handleChange(event)}
        input={
          <OutlinedInput name={name} labelWidth={labelWidth} notched={true} />
        }
        {...rest}
      >
        {options &&
          options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
      {hasErrors && fieldIsInvalid.invalid && (
        <FormHelperTextSC>{fieldIsInvalid.message}</FormHelperTextSC>
      )}
    </FormControlSC>
  );
}

FormSelectError.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  handleValidation: PropTypes.func,
  displayEmpty: PropTypes.bool,
  hasErrors: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object)
};

FormSelectError.defaultProps = {
  className: '',
  id: false,
  name: '',
  value: '',
  label: '',
  handleChange: undefined,
  handleValidation: undefined,
  displayEmpty: false,
  hasErrors: false,
  options: []
};
