import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

const Layout = styled.div`
  margin-top: 30px;
  font-size: 12px;
  width: 100%;
  background: lightgrey;
  border-radius: 5px;
  padding: 10px;
  overflow: auto;
`;

export default function UploadSuccess({ show, summary, uploadDate }) {
  return (
    show &&
    summary && (
      <Layout>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Grid container spacing={2}>
              <Grid container justifyContent="flex-end" item sm={1}>
                Date:
              </Grid>
              <Grid item sm={11}>
                {uploadDate}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid container spacing={2}>
              <Grid container justifyContent="flex-end" item sm={1}>
                Inserted:
              </Grid>
              <Grid item sm={11}>
                {summary?.inserted}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    )
  );
}

UploadSuccess.propTypes = {
  show: PropTypes.bool,
  summary: PropTypes.object,
  uploadDate: PropTypes.string
};

UploadSuccess.defaultProps = {
  show: false,
  summary: {},
  uploadDate: ''
};
