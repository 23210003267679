import { colors } from "@xcomp/xcomp-design-library";

export const getGestures = (dispatch, action, modalId) => {
  return {
    onWheel: state => {
      const modalEl = document.getElementById(modalId);
      if (state.ctrlKey && typeof state.direction[1] && modalEl) {
        const zoom = window.getComputedStyle(modalEl).getPropertyValue('zoom');
        const newZoom = parseFloat(zoom, 10) + -state.direction[1] / 100;

        modalEl.style.zoom = newZoom > 1.2 || newZoom < 0.8 ? zoom : newZoom;
      }
    },
    onWheelEnd: () => {
      const newZoom = parseFloat(
        window
          .getComputedStyle(document.getElementById(modalId))
          .getPropertyValue('zoom'),
        10
      );
      dispatch(action(newZoom));
    },
    onPinch: state => {
      const modalEl = document.getElementById(modalId);
      if (state.ctrlKey && typeof state.direction[1] && modalEl) {
        const zoom = window.getComputedStyle(modalEl).getPropertyValue('zoom');
        const newZoom = parseFloat(zoom, 10) + -state.direction[1] / 100;

        modalEl.style.zoom = newZoom > 1.2 || newZoom < 0.8 ? zoom : newZoom;
      }
    },
    onPinchEnd: () => {
      const newZoom = parseFloat(
        window
          .getComputedStyle(document.getElementById(modalId))
          .getPropertyValue('zoom'),
        10
      );
      dispatch(action(newZoom));
    }
  };
};

export const clickOnFilter = index => {
  document
    .getElementById('modal-header-actions')
    ?.getElementsByClassName('MuiInputBase-root MuiOutlinedInput-root')
    [index]?.click();
};

export const DataCellValueTypes = {
  date: 'date',
  attempt: 'attempt',
  score: 'score'
};

export const DidacticDataCellValueTypes = {
  date: 'date',
  grade: 'grade',
  score: 'score'
};

export const headerValueTypes = {
  procedures: 'Procedures',
  rvus: 'RVUs',
  score: 'Score',
  units: 'Units',
  epas: 'EPAs',
  cfs: 'CFs'
};

export const ASSESSMENT_GRID_MODAL_ID = 'assessment-modal-content';

export const selfAssessmentCriteria = {
  points_agreement: 'Agreement',
  points_equivocal: 'Equivocal',
  points_perfect_agreement: 'Perfect Agreement',
  points_slight_overgrade: 'Slight Overgrade',
  points_overgrade: 'Overgrade',
  points_significant_overgrade: 'Significant Overgrade',
  points_slight_undergrade: 'Slight Undergrade',
  points_undergrade: 'Undergrade',
  points_significant_undergrade: 'Significant Undergrade',
};

export const getCharacterizationColor = (c) => {
  if (c.characterization === selfAssessmentCriteria.points_significant_overgrade ||
    c.characterization === selfAssessmentCriteria.points_significant_undergrade
  ) {
    // eslint-disable-next-line
    return colors.red[200];
  }
  if (c.characterization === selfAssessmentCriteria.points_equivocal
  ) {
    // eslint-disable-next-line
    return colors.red[100];
  }
  if (c.characterization === selfAssessmentCriteria.points_perfect_agreement) {
    // eslint-disable-next-line
    return colors.green[300];
  }
  if (c.characterization === selfAssessmentCriteria.points_agreement) {
    // eslint-disable-next-line
    return colors.green[100];
  }

  return colors.grey[200];
}