import fetch from '../helpers/fetch';

const getUser = userUuid =>
  fetch(`/api/user/${userUuid}`, {
    method: 'GET',
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getUsersSearch = (field, input) =>
  fetch(`/api/users/search?field=${field}&input=${input}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getUsersSearchRoles = (field, input) =>
  fetch(`/api/users/search-roles?field=${field}&input=${input}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getUsersSearchInstitutionId = institutionId =>
  fetch(`/api/users/search-by-institution-id?institutionId=${institutionId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postUser = user =>
  fetch('/api/user/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(user)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putUser = (user, institutionUuid) =>
  fetch(`/api/user/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ user, institutionUuid })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putUserRoles = (userUuid, roles) =>
  fetch(`/api/user/update-roles`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ userUuid, roles })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putUserProfile = user =>
  fetch(`/api/user/${user.uuid}/profile/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(user)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteUser = (userUuid, institutionUuid) =>
  fetch(`/api/user/${userUuid}/institution/${institutionUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postUploadUsers = (uploadDate, users, institutionUuid) =>
  fetch('/api/users/upload', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ uploadDate, users, institutionUuid })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postUsersCheck = users => {
  return fetch(`/api/users-check`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ users })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

const postUserRole = (roleType, role) => {
  return fetch(`/api/user/create-role`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ roleType, role })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

const postUserAppId = (roleField, userUuid, email) => {
  return fetch(`/api/app-id/sign-up`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ roleField, userUuid, email })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

const deleteAppId = (roleField, userUuid, appId) =>
  fetch(
    `/api/app-id/delete?roleField=${roleField}&userUuid=${userUuid}&appId=${appId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  deleteAppId,
  deleteUser,
  getUser,
  getUsersSearch,
  getUsersSearchInstitutionId,
  getUsersSearchRoles,
  postUploadUsers,
  postUser,
  postUserAppId,
  postUserRole,
  postUsersCheck,
  putUser,
  putUserProfile,
  putUserRoles
};
