import { Model, attr } from 'redux-orm';
import {
  GET_DEGREE_LEVELS_SUCCESS,
  GET_TRANSCRIPT_SUCCESS
} from '../actions-type';

class DegreeLevel extends Model {
  static reducer(action, DegreeLevel, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_DEGREE_LEVELS_SUCCESS: {
        const degreeLevels = [...payload.degreeLevels];
        degreeLevels.forEach(degreeLevel => DegreeLevel.upsert(degreeLevel));
        break;
      }

      case GET_TRANSCRIPT_SUCCESS: {
        const sourceDegreeLevels = [...payload.sourceDegreeLevels];
        sourceDegreeLevels.forEach(degreeLevel => {
          DegreeLevel.upsert(degreeLevel);
        });
        break;
      }
      default:
        return session;
    }
  }
}

DegreeLevel.fields = {
  uuid: attr(),
  degreeLevel: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

DegreeLevel.modelName = 'DegreeLevel';
DegreeLevel.options = {
  idAttribute: 'uuid'
};

export default DegreeLevel;
