import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import ByInstitutionTable from './ByInstitutionTable';
import InstitutionCreditsBySubject from '../../TranscriptCalculations/InstitutionCreditsBySubject';
import InstitutionGradeBySubject from '../../TranscriptCalculations/InstitutionGradeBySubject';
import InstitutionAverageScoreBySubject from '../../TranscriptCalculations/InstitutionAverageScoreBySubject';

const Accordion = styled(({ ...rest }) => (
  <AccordionRowContainer {...rest} />
))``;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.grey[500]};
  font-size: 14px;
`;

const Calculations = styled.div`
  display: flex;
  font-size: 12px;
`;

const Spacing = styled.div`
  width: 120px;
`;

const Stub = styled.div`
  width: 66px;
`;

export default function ByInstitutionSubject({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  subject,
  source,
  classificationUuid,
  sourceInstitutionUuid
}) {
  return (
    <>
      <Accordion
        rowOffset="132px"
        expandWidth="66px"
        height="70px"
        label={
          <Group>
            <Label>{`${subject}`}</Label>
          </Group>
        }
        columnOneClassName="column_one"
        columnOne={
          <>
            <Calculations>
              <Spacing>&nbsp;</Spacing>
              <Spacing>
                <InstitutionCreditsBySubject
                  programUuid={programUuid}
                  userUuid={userUuid}
                  cohortUuid={cohortUuid}
                  source={source}
                  sourceInstitutionUuid={sourceInstitutionUuid}
                  classificationUuid={classificationUuid}
                />
              </Spacing>
              <Spacing>
                <InstitutionGradeBySubject
                  programUuid={programUuid}
                  userUuid={userUuid}
                  cohortUuid={cohortUuid}
                  source={source}
                  sourceInstitutionUuid={sourceInstitutionUuid}
                  classificationUuid={classificationUuid}
                />
              </Spacing>
              <Spacing>
                <InstitutionAverageScoreBySubject
                  programUuid={programUuid}
                  userUuid={userUuid}
                  cohortUuid={cohortUuid}
                  source={source}
                  sourceInstitutionUuid={sourceInstitutionUuid}
                  classificationUuid={classificationUuid}
                />
              </Spacing>
              <Stub>&nbsp;</Stub>
            </Calculations>
          </>
        }
      >
        <ByInstitutionTable
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
          classificationUuid={classificationUuid}
          sourceInstitutionUuid={sourceInstitutionUuid}
        />
      </Accordion>
    </>
  );
}

ByInstitutionSubject.propTypes = {
  subject: PropTypes.string,
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  classificationUuid: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string
};

ByInstitutionSubject.defaultProps = {
  subject: undefined,
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  classificationUuid: undefined,
  sourceInstitutionUuid: undefined
};
