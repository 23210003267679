import { Model, attr, fk } from 'redux-orm';
import {
  GET_PART_GROUPS_SUCCESS,
  POST_GROUP_SUCCESS,
  DELETE_GROUP_SUCCESS,
  PUT_GROUP_SUCCESS,
  PUT_GROUP_RANKINGS_SUCCESS,
  PUT_STUDENT_GROUP_RANKINGS_SUCCESS,
  RESET_PART_GROUP_SUMMARY
} from '../actions-type';

class Group extends Model {
  static reducer(action, Group, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_PART_GROUPS_SUCCESS: {
        const { groups } = payload;
        groups.forEach(group => Group.upsert(group));
        break;
      }
      case POST_GROUP_SUCCESS: {
        const { createdGroup } = payload;
        Group.upsert(createdGroup);
        break;
      }
      case DELETE_GROUP_SUCCESS: {
        const { groupUuid } = payload;
        Group.withId(groupUuid).delete();
        break;
      }
      case PUT_GROUP_SUCCESS: {
        const { group } = payload;
        Group.upsert(group);
        break;
      }
      case PUT_STUDENT_GROUP_RANKINGS_SUCCESS:
      case PUT_GROUP_RANKINGS_SUCCESS: {
        const { groups } = payload;
        (groups || payload.groups || []).forEach(group => Group.upsert(group));
        break;
      }
      case RESET_PART_GROUP_SUMMARY: {
        Group.all().delete();
        break;
      }
      default:
        return session;
    }
  }
}

Group.fields = {
  uuid: attr(),
  groupId: attr(),
  groupNumber: attr(),
  partUuid: fk({
    to: 'Part',
    as: 'part',
    relatedName: 'groups'
  }),
  groupRank: attr(),
  groupParticipation: attr(),
  pointsPerStudent: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

Group.modelName = 'Group';

Group.options = {
  idAttribute: 'uuid'
};

export default Group;
