import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { createFilter } from 'react-search-input';
import DepartmentManagement from './DepartmentManagement';

import { doGetUserDepartments } from '../../redux/actions/userDepartmentActions';
import { doGetSchoolDepartments } from '../../redux/actions/departmentActions';
import { departmentsBySchoolStateSelector } from '../../redux/selectors/departmentsSelectors';

export default function DepartmentManagementContainer() {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const departments = useSelector(
    state => departmentsBySchoolStateSelector(state),
    _.isEqual
  );
  const selectedSchoolUuid = useSelector(
    state => state.userState.selectedSchoolUuid
  );
  const KEYS_TO_FILTERS = ['name'];
  const searchFilter = departments.filter(
    createFilter(searchTerm, KEYS_TO_FILTERS)
  );

  useEffect(() => {
    if (departments) {
      dispatch(doGetUserDepartments(departments));
    }
  }, [dispatch, departments]);

  useEffect(() => {
    if (selectedSchoolUuid) {
      dispatch(doGetSchoolDepartments(selectedSchoolUuid));
    }
  }, [dispatch, selectedSchoolUuid]);

  const searchUpdated = event => {
    setSearchTerm(event.target.value);
  };

  return (
    <DepartmentManagement
      departments={searchFilter}
      searchTerm={searchTerm}
      searchUpdated={searchUpdated}
    />
  );
}
