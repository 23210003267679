import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from 'styled-components';
import { doGetPartFacultyAssessmentSummary } from '../../../../../../redux/actions/partReportsActions';
import PartFacultyReportAssessmentDetailContainer from './PartFacultyReportAssessmentDetailContainer';

const ProgressBar = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.fontColors.dark};
`;
export const AssessmentHeaderContainer = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  height: 48px;
  width: 100%;
  display: flex;
  padding-right: ${props => props.paddingRight};
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: flex-start;
`;

const AssessmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 90px;
  width: calc(100% - 90px);
`;

const AssessmentHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  font-size: 14px;
  font-weight: 450;
  overflow: hidden;
`;

const AssessmentHeaderData = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  font-size: 14px;
  font-weight: 450;
  min-width: 456px;
`;

const AssessmentHeaderSub = styled(({ marginRight, ...rest }) => (
  <div {...rest} />
))`
  min-width: 100px;
  margin-right: ${props => props.marginRight};
  color: ${props => props.theme.fontColors.darker};
  text-align: right;
  }
`;

const PartFacultyReportAssessmentListContainer = ({
  assessments,
  assessmentTechniques,
  assessmentTypes,
  facultyUuid,
  isSummaryPanel,
  partFacultyReportAssessmentData,
  partUuid
}) => {
  const dispatch = useDispatch();
  const {
    partFacultyAssessmentSummaryData,
    partFacultyAssessmentSummaryLoading
  } = useSelector(state => state.partFacultyReportState);
  useEffect(() => {
    if (partUuid && facultyUuid && !isSummaryPanel) {
      dispatch(
        doGetPartFacultyAssessmentSummary(
          partUuid,
          facultyUuid,
          assessmentTechniques,
          assessmentTypes
        )
      );
    }
  }, [
    partUuid,
    facultyUuid,
    assessmentTechniques,
    assessmentTypes,
    dispatch,
    isSummaryPanel
  ]);

  return partFacultyAssessmentSummaryLoading.includes(facultyUuid) ? (
    <ProgressBar>
      <ProgressBar>Loading...</ProgressBar>
      <LinearProgress />
    </ProgressBar>
  ) : (
    <>
      <AssessmentHeaderContainer paddingRight="44px">
        <AssessmentHeader>
          <AssessmentHeaderInfo>Assessment</AssessmentHeaderInfo>
          <AssessmentHeaderData>
            <AssessmentHeaderSub marginRight="16px">
              Participation (%)
            </AssessmentHeaderSub>
            <AssessmentHeaderSub marginRight="16px">
              Relative (%)
            </AssessmentHeaderSub>
            <AssessmentHeaderSub marginRight="16px">
              Relative Score
            </AssessmentHeaderSub>
            <AssessmentHeaderSub marginRight="8px">
              Potential Score
            </AssessmentHeaderSub>
          </AssessmentHeaderData>
        </AssessmentHeader>
      </AssessmentHeaderContainer>
      {assessments.map(assessment => (
        <PartFacultyReportAssessmentDetailContainer
          key={assessment.uuid}
          assessment={assessment}
          assessmentTechniques={assessmentTechniques}
          assessmentTypes={assessmentTypes}
          facultyUuid={facultyUuid}
          assessmentCumulativePotentialScore={_.get(
            partFacultyReportAssessmentData,
            `${assessment.uuid}.totalPotentialScore`,
            '0.00'
          )}
          assessmentCumulativeRelativeScore={_.get(
            partFacultyReportAssessmentData,
            `${assessment.uuid}.totalRelativeScore`,
            '0.00'
          )}
          partFacultyReportAssessmentData={partFacultyReportAssessmentData}
          partFacultyTotalPotentialScore={
            isSummaryPanel
              ? _.get(
                  partFacultyReportAssessmentData,
                  `${assessment.uuid}.totalPotentialScore`,
                  '0.00'
                )
              : _.get(
                  partFacultyAssessmentSummaryData,
                  `${facultyUuid}.${assessment.uuid}.totalPotentialScore`,
                  '0.00'
                )
          }
          partFacultyTotalRelativeScore={
            isSummaryPanel
              ? _.get(
                  partFacultyReportAssessmentData,
                  `${assessment.uuid}.totalRelativeScore`,
                  '0.00'
                )
              : _.get(
                  partFacultyAssessmentSummaryData,
                  `${facultyUuid}.${assessment.uuid}.totalRelativeScore`,
                  '0.00'
                )
          }
          partUuid={partUuid}
        />
      ))}
    </>
  );
};

PartFacultyReportAssessmentListContainer.propTypes = {
  assessments: PropTypes.array,
  assessmentTechniques: PropTypes.array,
  assessmentTypes: PropTypes.array,
  facultyUuid: PropTypes.string,
  isSummaryPanel: PropTypes.bool,
  partFacultyReportAssessmentData: PropTypes.object,
  partUuid: PropTypes.string
};
PartFacultyReportAssessmentListContainer.defaultProps = {
  assessments: [],
  assessmentTechniques: [],
  assessmentTypes: [],
  facultyUuid: '',
  isSummaryPanel: false,
  partFacultyReportAssessmentData: {},
  partUuid: ''
};

export default PartFacultyReportAssessmentListContainer;
