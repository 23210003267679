import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { ButtonPrimary, ButtonOutline } from '@xcomp/xcomp-design-library';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import IconButton from '@material-ui/core/IconButton';
import { LinearProgress } from '@material-ui/core';
import { rolesMap } from '../../../helpers/constants';

import { selectAdminRoles } from '../../../redux/selectors/userRolesSelectors';
import DeleteModal from '../../Library/Modal/DeleteModal/DeleteModal';
import { doDeleteAppId } from '../../../redux/actions/userActions';

const Attributes = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  border-left: 1px solid
    ${props => (props.left ? props.theme.colors.grey[300] : 'none')};
  border-right: 1px solid
    ${props => (props.right ? props.theme.colors.grey[300] : 'none')};
  border-top: 1px solid
    ${props => (props.top ? props.theme.colors.grey[300] : 'none')};
  border-bottom: 1px solid
    ${props => (props.bottom ? props.theme.colors.grey[300] : 'none')};
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
`;

const ButtonPlacement = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const Layout = styled.div`
  border-bottom: 1px solid
    ${props => (props.bottom ? props.theme.colors.grey[300] : 'none')};
`;

const Status = styled.span`
  text-transform: capitalize;
`;

export default function UserTabAppIdAdminRoles({
  userUuid,
  appIdAdmin,
  handleAdminAppIdOpen
}) {
  const roles = useSelector(state => selectAdminRoles(state, userUuid));
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const dispatch = useDispatch();

  const deleteAppId = useSelector(state => state.crudState.deleteAppId);
  const deletedUserUuid = useSelector(state => state.crudState.deletedUserUuid);

  const handleDeleteModalOpen = () => {
    setModalDeleteOpen(true);
  };

  const handleDeleteModalClose = () => {
    setModalDeleteOpen(false);
  };

  const handleDelete = () => {
    dispatch(doDeleteAppId('appIdAdmin', userUuid, appIdAdmin));
    setModalDeleteOpen(false);
  };

  return (
    <>
      {roles?.length > 0 && (
        <Layout>
          {deleteAppId && deletedUserUuid === userUuid && <LinearProgress />}
          <Grid container>
            <Grid item xs={8} sm={8}>
              {roles?.length > 0 &&
                roles?.map(role => (
                  <Grid container key={role.uuid}>
                    <Grid item xs={4} sm={4}>
                      <Attributes>
                        <Status>{role.status}</Status>
                      </Attributes>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <Attributes>{rolesMap[role.role]}</Attributes>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <Attributes>{role.email}</Attributes>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            <Grid container alignItems="flex-start" item xs={3} sm={3}>
              {!appIdAdmin ? (
                <ButtonPlacement>
                  <ButtonOutline onClick={() => handleAdminAppIdOpen(userUuid)}>
                    Create Admin AppID
                  </ButtonOutline>
                </ButtonPlacement>
              ) : (
                <ButtonPlacement>
                  <ButtonPrimary disabled>Activated</ButtonPrimary>
                </ButtonPlacement>
              )}
            </Grid>
            <Grid container alignItems="flex-start" item xs={1} sm={1}>
              {appIdAdmin && !deleteAppId && (
                <ButtonPlacement>
                  <IconButton onClick={handleDeleteModalOpen}>
                    <DeleteIcon />
                  </IconButton>
                </ButtonPlacement>
              )}
            </Grid>
          </Grid>
          <DeleteModal
            type="Admin App ID"
            modalOpen={modalDeleteOpen}
            handleModalClose={handleDeleteModalClose}
            handleDelete={handleDelete}
          />
        </Layout>
      )}
    </>
  );
}

UserTabAppIdAdminRoles.propTypes = {
  userUuid: PropTypes.string,
  appIdAdmin: PropTypes.string,
  handleAdminAppIdOpen: PropTypes.func
};
UserTabAppIdAdminRoles.defaultProps = {
  userUuid: undefined,
  appIdAdmin: undefined,
  handleAdminAppIdOpen: undefined
};
