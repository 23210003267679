import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body1 } from '@xcomp/xcomp-design-library';
import { useSelector } from 'react-redux';

import { useContainerDimensions } from '../../../../helpers/hooks/layout.hooks';
import { Panel } from '../../../Library/Layout';
import ReportTableHeaderBar from './CollectionReportTable/ReportTableHeaderBar';
import { StickyTitleTd, TableDataText } from './CollectionReportSC';
import ReportTableHead from './CollectionReportTable/ReportTableHead';
import CollectionDetailsRow from './CollectionReportTable/CollectionDetailsRow';
import PartGradeResult from './CollectionReportTable/PartGradeResult';

const Table = styled.table`
  background: ${props => props.theme.colors.white};
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
`;

const MissingGradesMessage = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TableBody = styled.tbody`
  color: ${props => props.theme.colors.grey[900]};
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 24px;
  tr:last-child td {
    border-bottom: none;
  }
`;

const ScrollableDiv = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: auto;
`;

const PartAssessmentCollectionReportTable = ({
  assessmentCollections,
  failThreshold,
  isCollectionExpanded,
  numAssessments,
  numCollectionAssessments,
  numPointsOnly,
  numPointsOnlyCollections,
  partGradeResults,
  partUuid,
  students
}) => {
  const [useFillerColumn, setUseFillerColumn] = useState(false);
  const [fillerColumnWidth, setFillerColumnWidth] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const tableRef = useRef(null);
  const { width } = useContainerDimensions(tableRef);

  const drawerOpen = useSelector(state => state.navigationState.drawerOpen);

  useEffect(() => {
    const occupiedWidth = isExpanded
      ? 475 +
        (assessmentCollections.length +
          numCollectionAssessments +
          numPointsOnlyCollections) *
          75
      : 475 + assessmentCollections.length * 75;

    if (width > occupiedWidth) {
      setUseFillerColumn(true);
      setFillerColumnWidth(width - occupiedWidth);
    } else {
      setUseFillerColumn(false);
      setFillerColumnWidth(0);
    }
  }, [
    width,
    numPointsOnlyCollections,
    numAssessments,
    numPointsOnly,
    assessmentCollections.length,
    isCollectionExpanded,
    isExpanded,
    drawerOpen,
    numCollectionAssessments
  ]);

  return (
    <Panel>
      <ReportTableHeaderBar
        headerText="Part Performance Report by Assessment Collection"
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
      <ScrollableDiv>
        <Table ref={tableRef}>
          <ReportTableHead
            assessmentCollections={assessmentCollections}
            isExpanded={isExpanded}
            useFillerColumn={useFillerColumn}
            fillerColumnWidth={fillerColumnWidth}
          />
          <TableBody>
            <CollectionDetailsRow
              assessmentCollections={assessmentCollections}
              isExpanded={isExpanded}
              useFillerColumn={useFillerColumn}
              fillerColumnWidth={fillerColumnWidth}
            />
            {partGradeResults?.length > 0 &&
              partGradeResults.map((gradeResult, idx) => (
                <PartGradeResult
                  key={gradeResult.uuid}
                  gradeResult={gradeResult}
                  students={students}
                  idx={idx}
                  partUuid={partUuid}
                  assessmentCollections={assessmentCollections}
                  failThreshold={failThreshold}
                  isExpanded={isExpanded}
                  useFillerColumn={useFillerColumn}
                  fillerColumnWidth={fillerColumnWidth}
                />
              ))}
          </TableBody>
        </Table>
      </ScrollableDiv>
      {partGradeResults?.length === 0 && (
        <MissingGradesMessage>
          <Body1>This part has not yet been graded.</Body1>
        </MissingGradesMessage>
      )}
    </Panel>
  );
};

PartAssessmentCollectionReportTable.propTypes = {
  assessmentCollections: PropTypes.array,
  failThreshold: PropTypes.string,
  isCollectionExpanded: PropTypes.bool,
  numAssessments: PropTypes.number,
  numCollectionAssessments: PropTypes.number,
  numPointsOnly: PropTypes.number,
  numPointsOnlyCollections: PropTypes.number,
  partGradeResults: PropTypes.arrayOf(PropTypes.object),
  partUuid: PropTypes.string,
  students: PropTypes.object
};

PartAssessmentCollectionReportTable.defaultProps = {
  assessmentCollections: [],
  failThreshold: '',
  isCollectionExpanded: false,
  numAssessments: 0,
  numCollectionAssessments: 0,
  numPointsOnly: 0,
  numPointsOnlyCollections: 0,
  partGradeResults: [],
  partUuid: '',
  students: {}
};

export default PartAssessmentCollectionReportTable;
export { StickyTitleTd, TableDataText };
