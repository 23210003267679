import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { doGetCohort } from '../../../redux/actions/cohortActions';
import { cohortSelectorByMatchParams } from '../../../redux/selectors/cohortsSelectors';
import {
  doPostCohortCompetencyGrid,
  doSetIsCompetencyReportLoading
} from '../../../redux/actions/competencyGridActions';
import CohortCompetencyGrid from './CohortCompetencyGrid';

const CohortCompetencyGridContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { cohortUuid } = useParams();

  const isLoading = useSelector(
    state => state.cohortCompetencyReportState.isLoading
  );

  const cohort =
    useSelector(
      state => cohortSelectorByMatchParams(state, cohortUuid),
      _.isEqual
    ) || {};

  const startDate = cohort?.startDate;
  const endDate = cohort?.endDate;

  useEffect(() => {
    dispatch(doGetCohort(cohortUuid));
  }, [dispatch, cohortUuid]);

  const handleBackAction = useCallback(() => {
    history.push('/reports');
  }, [history]);

  const setIsLoading = isLoading => {
    dispatch(doSetIsCompetencyReportLoading(isLoading));
  };

  const handleGenerateCompetencyReport = (
    competencyCollectionUuid,
    assessmentAssociationUuids,
    assessmentTechniqueUuids,
    assessmentTypeUuids,
    selectedStartDate,
    selectedEndDate
  ) => {
    setIsLoading(true);
    const competencyCollectionUuidParams =
      competencyCollectionUuid === 'all' ? '' : competencyCollectionUuid;

    const selectedStartDatePayload =
      selectedStartDate !== '' ? selectedStartDate : startDate;
    const selectedEndDatePayload =
      selectedEndDate !== '' ? selectedEndDate : endDate;

    dispatch(
      doPostCohortCompetencyGrid({
        cohortUuid,
        competencyCollectionUuid: competencyCollectionUuidParams,
        assessmentAssociationUuids,
        assessmentTechniqueUuids,
        assessmentTypeUuids,
        startDate: selectedStartDatePayload,
        endDate: selectedEndDatePayload
      })
    );
  };

  return (
    <CohortCompetencyGrid
      cohort={cohort}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      cohortStartDate={startDate}
      cohortEndDate={endDate}
      onBackAction={handleBackAction}
      onGenerateCompetencyReport={handleGenerateCompetencyReport}
    />
  );
};

export default CohortCompetencyGridContainer;
