import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import StepScore from './StepScore';
import DeleteModal from '../../Modal/DeleteModal/DeleteModal';
import { scoreSelector } from '../../../../redux/selectors/scoreSelectors';
import { doDeleteScore } from '../../../../redux/actions/scoreActions';

export default function StepScoreContainer({
  assessmentUuid,
  criticalFailure,
  scoreUuid,
  handleScoreEditModalOpen,
  isOpportunity,
}) {
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const score = useSelector(
    state => scoreSelector(state, scoreUuid),
    _.isEqual
  );

  const handleScoreDeleteModalOpen = () => {
    setOpenDeleteModal(true);
  };

  const handleScoreDeleteModalClose = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setOpenDeleteModal(false);
    dispatch(doDeleteScore(scoreUuid, score.resultCardUuid, assessmentUuid));
  };

  return (
    <>
      <StepScore
        uuid={score.uuid}
        date={score.date}
        rubricItem={score.rubricItem}
        stepSubItem={score.stepSubItem}
        stepSubItemDescription={score.stepSubItemDescription}
        isOpportunity={isOpportunity}
        topicUuid={score.topicUuid}
        relValue={score.relValue}
        potValue={score.potValue}
        sequenceOrder={score.sequenceOrder}
        rubricOrder={score.rubricOrder}
        procedureCodeOrder={score.procedureCodeOrder}
        procedureCodeDescriptor={score.procedureCodeDescriptor}
        criticalFailure={criticalFailure}
        handleScoreDeleteModalOpen={handleScoreDeleteModalOpen}
        handleScoreEditModalOpen={handleScoreEditModalOpen}
      />
      <DeleteModal
        modalOpen={openDeleteModal}
        handleDelete={handleDelete}
        handleModalClose={handleScoreDeleteModalClose}
      />
    </>
  );
}

StepScoreContainer.propTypes = {
  assessmentUuid: PropTypes.string,
  criticalFailure: PropTypes.bool,
  scoreUuid: PropTypes.string,
  handleScoreEditModalOpen: PropTypes.func,
  isOpportunity: PropTypes.bool,
};

StepScoreContainer.defaultProps = {
  isOpportunity: false,
  assessmentUuid: '',
  criticalFailure: false,
  scoreUuid: '',
  handleScoreEditModalOpen: undefined
};
