import {
  CLEAR_PART_TOPIC_ASSESSMENT_SUMMARY,
  CLEAR_PART_TOPIC_REPORT,
  GET_PART_TOPIC_ASSESSMENT_SUMMARY_ERROR,
  GET_PART_TOPIC_ASSESSMENT_SUMMARY_SUCCESS,
  GET_PART_TOPIC_ASSESSMENT_SUMMARY,
  GET_PART_TOPIC_REPORT_ERROR,
  GET_PART_TOPIC_REPORT_SUCCESS,
  GET_PART_TOPIC_REPORT,
  GET_PART_TOPICS_SUCCESS
} from '../actions-type';

export const INITIAL_STATE = {
  topics: [],
  partTopicAssessmentSummaryData: {},
  partTopicReportSummaryData: {},
  partTopicReportTopicData: {},
  partTopicAssessmentSummaryLoading: [],
  isLoading: false,
  records: 0
};

const partTopicReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PART_TOPICS_SUCCESS: {
      const { topics } = action.payload;
      return { ...state, topics };
    }

    case GET_PART_TOPIC_ASSESSMENT_SUMMARY: {
      const { topicUuid } = action.payload;
      return {
        ...state,
        partTopicAssessmentSummaryData: {
          ...state.partTopicAssessmentSummaryData,
          [topicUuid]: {}
        },
        partTopicAssessmentSummaryLoading: [
          ...state.partTopicAssessmentSummaryLoading,
          topicUuid
        ]
      };
    }
    case CLEAR_PART_TOPIC_ASSESSMENT_SUMMARY:
    case GET_PART_TOPIC_ASSESSMENT_SUMMARY_ERROR: {
      return {
        ...state,
        partTopicAssessmentSummaryData:
          INITIAL_STATE.partTopicAssessmentSummaryData,
        partTopicAssessmentSummaryLoading:
          INITIAL_STATE.partTopicAssessmentSummaryLoading
      };
    }
    case GET_PART_TOPIC_ASSESSMENT_SUMMARY_SUCCESS: {
      const { topicUuid, partTopicAssessmentSummaryData } = action.payload;
      return {
        ...state,
        partTopicAssessmentSummaryData: {
          ...state.partTopicAssessmentSummaryData,
          [topicUuid]: partTopicAssessmentSummaryData
        },
        partTopicAssessmentSummaryLoading:
          state.partTopicAssessmentSummaryLoading.filter(
            loadingFacultyUuid => loadingFacultyUuid !== topicUuid
          )
      };
    }
    case GET_PART_TOPIC_REPORT: {
      return { ...INITIAL_STATE, topics: state.topics, isLoading: true };
    }
    case CLEAR_PART_TOPIC_REPORT:
    case GET_PART_TOPIC_REPORT_ERROR: {
      return INITIAL_STATE;
    }
    case GET_PART_TOPIC_REPORT_SUCCESS: {
      const {
        partTopicReportAssessmentData,
        partTopicReportTopicData,
        partTopicReportSummaryData,
        records
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        partTopicReportAssessmentData,
        partTopicReportTopicData,
        partTopicReportSummaryData,
        records
      };
    }
    default:
      return state;
  }
};

export default partTopicReportReducer;
