export const GET_EDUCATION_LEVEL = 'GET_EDUCATION_LEVEL';
export const GET_EDUCATION_LEVEL_SUCCESS = 'GET_EDUCATION_LEVEL_SUCCESS';
export const GET_EDUCATION_LEVEL_ERROR = 'GET_EDUCATION_LEVEL_ERROR';
export const GET_EDUCATION_LEVELS_BY_SCHOOL = 'GET_EDUCATION_LEVELS_BY_SCHOOL';
export const GET_EDUCATION_LEVELS_BY_SCHOOL_SUCCESS =
  'GET_EDUCATION_LEVELS_BY_SCHOOL_SUCCESS';
export const GET_EDUCATION_LEVELS_BY_SCHOOL_ERROR =
  'GET_EDUCATION_LEVELS_BY_SCHOOL_ERROR';
export const POST_EDUCATION_LEVEL = 'POST_EDUCATION_LEVEL';
export const POST_EDUCATION_LEVEL_SUCCESS = 'POST_EDUCATION_LEVEL_SUCCESS';
export const POST_EDUCATION_LEVEL_ERROR = 'POST_EDUCATION_LEVEL_ERROR';
export const PUT_EDUCATION_LEVEL = 'PUT_EDUCATION_LEVEL';
export const PUT_EDUCATION_LEVEL_SUCCESS = 'PUT_EDUCATION_LEVEL_SUCCESS';
export const PUT_EDUCATION_LEVEL_ERROR = 'PUT_EDUCATION_LEVEL_ERROR';
export const DELETE_EDUCATION_LEVEL = 'DELETE_EDUCATION_LEVEL';
export const DELETE_EDUCATION_LEVEL_SUCCESS = 'DELETE_EDUCATION_LEVEL_SUCCESS';
export const DELETE_EDUCATION_LEVEL_ERROR = 'DELETE_EDUCATION_LEVEL_ERROR';
