import {
  POST_COURSE_COLLECTION_SUCCESS,
  POST_COMPETENCY_COLLECTION_SUCCESS,
  POST_COMPETENCY_SUCCESS,
  PUT_COMPETENCY_MICROS_SUCCESS,
  CLEAR_CREATED,
  PUT_ASSESSMENT_GRADING_TECHNIQUE_SUCCESS,
  PUT_PART_GRADE_DATA_SOURCE_SUCCESS,
  PUT_PART_GRADE_COLLECTIONS_SUCCESS,
  PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_SUCCESS,
  PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_SUCCESS,
  PUT_SECTION_DATASOURCE_SUCCESS,
  PUT_SECTION_PART_WEIGHTS_SUCCESS,
  POST_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS,
  PUT_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS,
  POST_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  PUT_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  POST_COHORT_ASSESSMENT_COLLECTION_SUCCESS,
  PUT_COHORT_ASSESSMENT_COLLECTION_SUCCESS,
  PUT_COHORT_ASSESSMENT_SUCCESS,
  POST_COHORT_ASSESSMENT_SUCCESS,
  DELETE_COHORT_ASSESSMENT_SUCCESS,
  PATCH_COHORT_ASSESSMENT_STATUS_SUCCESS,
  POST_CLONE_EXISTING_PART_SUCCESS
} from '../actions-type';

const INITIAL_STATE = {
  successfullyCreatedCourseCollection: false,
  successfullyCreatedCompetencyCollection: false,
  successfullyCreatedCompetency: false,
  successfullyUpdatedCompetency: false,
  successfullyUpdatedGradingTechnique: false,
  successfullyUpdatedPartDataSource: false,
  successfullyUpdatedSectionDataSource: false,
  successfullyUpdatedPartGradeCollections: false,
  successfullyUpdatedSectionPartWeights: false,
  successfullyUpdatedProgramAssessments: false,
  successfullyDeletedCohortAssessment: false,
  successfullyClonedPartUuid: null
};

const formSuccessReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_COURSE_COLLECTION_SUCCESS: {
      return {
        ...state,
        successfullyCreatedCourseCollection: true
      };
    }
    case POST_COMPETENCY_COLLECTION_SUCCESS: {
      return {
        ...state,
        successfullyCreatedCompetencyCollection: true
      };
    }
    case POST_CLONE_EXISTING_PART_SUCCESS: {
      return {
        ...state,
        successfullyClonedPartUuid: action.payload.partUuid
      };
    }
    case POST_COMPETENCY_SUCCESS: {
      return {
        ...state,
        successfullyCreatedCompetency: true
      };
    }
    case PUT_COMPETENCY_MICROS_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedCompetency: true
      };
    }
    case PUT_ASSESSMENT_GRADING_TECHNIQUE_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedGradingTechnique: true
      };
    }
    case PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedGradingTechnique: true
      };
    }
    case PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedGradingTechnique: true
      };
    }
    case PUT_PART_GRADE_DATA_SOURCE_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedPartDataSource: true
      };
    }
    case PUT_SECTION_DATASOURCE_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedSectionDataSource: true
      };
    }
    case PUT_PART_GRADE_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedPartGradeCollections: true
      };
    }
    case PUT_SECTION_PART_WEIGHTS_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedSectionPartWeights: true
      };
    }
    case POST_COHORT_ASSESSMENT_COLLECTION_SUCCESS:
    case PUT_COHORT_ASSESSMENT_COLLECTION_SUCCESS:
    case PUT_COHORT_ASSESSMENT_SUCCESS:
    case PATCH_COHORT_ASSESSMENT_STATUS_SUCCESS:
    case POST_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS:
    case PUT_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS:
    case POST_PROGRAM_ASSESSMENT_MASTER_SUCCESS:
    case PUT_PROGRAM_ASSESSMENT_MASTER_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedProgramAssessments: true
      };
    }
    case POST_COHORT_ASSESSMENT_SUCCESS: {
      const { uuid } = action.payload.cohort_assessment;

      return {
        ...state,
        successfullyUpdatedProgramAssessments: uuid
      };
    }
    case DELETE_COHORT_ASSESSMENT_SUCCESS: {
      const { uuid } = action.payload.deletedCohortAssessment;
      return {
        ...state,
        successfullyDeletedCohortAssessment: uuid
      };
    }
    case CLEAR_CREATED: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

export default formSuccessReducer;

export { INITIAL_STATE };
