import {
  GET_ASSESSMENT_BLOCKS,
  GET_ASSESSMENT_BLOCKS_SUCCESS,
  GET_ASSESSMENT_BLOCKS_ERROR,
  GET_ASSESSMENT_BLOCK_RESULTS,
  GET_ASSESSMENT_BLOCK_RESULTS_SUCCESS,
  GET_ASSESSMENT_BLOCK_RESULTS_ERROR
} from '../actions-type';

const doGetAssessmentBlocks = assessmentUuid => ({
  type: GET_ASSESSMENT_BLOCKS,
  payload: {
    assessmentUuid
  }
});

const doGetAssessmentBlocksSuccess = result => ({
  type: GET_ASSESSMENT_BLOCKS_SUCCESS,
  payload: result
});

const doGetAssessmentBlocksError = error => ({
  type: GET_ASSESSMENT_BLOCKS_ERROR,
  payload: error
});

const doGetAssessmentBlockResults = assessmentUuid => ({
  type: GET_ASSESSMENT_BLOCK_RESULTS,
  payload: {
    assessmentUuid
  }
});

const doGetAssessmentBlockResultsSuccess = result => ({
  type: GET_ASSESSMENT_BLOCK_RESULTS_SUCCESS,
  payload: result
});

const doGetAssessmentBlockResultsError = error => ({
  type: GET_ASSESSMENT_BLOCK_RESULTS_ERROR,
  payload: error
});

export {
  doGetAssessmentBlocks,
  doGetAssessmentBlocksSuccess,
  doGetAssessmentBlocksError,
  doGetAssessmentBlockResults,
  doGetAssessmentBlockResultsSuccess,
  doGetAssessmentBlockResultsError
};
