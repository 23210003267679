import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ButtonOutline, Body1 } from '@xcomp/xcomp-design-library';
import { PanelLabel } from '../../../Library/DashboardSC';

import AssessmentCollectionRowContainer from './AssessmentCollectionRowContainer';
import { sortByProperty } from '../../../../helpers/utilities';

const Layout = styled.div`
  && {
    width: 100%;
    padding-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const ButtonRow = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

const CohortAssessmentCollectionsList = ({
  parentCohortUuid,
  assessmentCollections
}) => (
  <Layout>
    <ButtonRow>
      <Link
        to={`/program-management/cohort/${parentCohortUuid}/assessment-collection/add`}
      >
        <ButtonOutline>Add Collection</ButtonOutline>
      </Link>
    </ButtonRow>
    {assessmentCollections && assessmentCollections.length > 0 ? (
      <>
        <PanelLabel marginLeft="66px">Assessment Collections</PanelLabel>
        {assessmentCollections
          .sort((a, b) => sortByProperty(a, b, 'title'))
          .map((collection, index) => (
            <AssessmentCollectionRowContainer
              key={collection.uuid}
              assessmentCollection={collection}
              withTopBorder={index === 0}
            />
          ))}
      </>
    ) : (
      <Body1>
        There are currently no assessment collections for this cohort.
      </Body1>
    )}
  </Layout>
);

CohortAssessmentCollectionsList.propTypes = {
  parentCohortUuid: PropTypes.string.isRequired,
  assessmentCollections: PropTypes.array.isRequired
};

export default CohortAssessmentCollectionsList;
