import { v4 as uuidv4 } from 'uuid';

const subjectExamInitialState = {
  milestones: [],
  conversionsRemove: []
};

const subjectExamReducer = (state, action) => {
  switch (action.type) {
    case 'populate': {
      const milestones = [...action.payload];

      return {
        ...state,
        milestones
      };
    }

    case 'add_exam': {
      const { classificationUuid } = {
        ...action.payload
      };

      const newState = state.milestones.map(subject => {
        if (subject.uuid === classificationUuid) {
          return {
            ...subject,
            exam_milestone: {
              uuid: uuidv4(),
              type: 'new',
              examId: '',
              examDescription: '',
              examName: '',
              credits: '0.0',
              sourceInstitutionUuid: '',
              conversion_milestones: []
            }
          };
        } else {
          return subject;
        }
      });

      return {
        ...state,
        milestones: newState
      };
    }

    case 'update': {
      const { classificationUuid, examMilestoneUuid, modify } = {
        ...action.payload
      };

      const newState = state.milestones.map(subject => {
        if (
          subject.uuid === classificationUuid &&
          subject.exam_milestone.uuid === examMilestoneUuid
        ) {
          const merge = { ...subject.exam_milestone, ...modify };

          return {
            ...subject,
            exam_milestone: merge
          };
        } else {
          return subject;
        }
      });

      return {
        ...state,
        milestones: newState
      };
    }

    case 'update_source_institutionUuid': {
      const { classificationUuid, examMilestoneUuid, sourceInstitutionUuid } = {
        ...action.payload
      };

      const newState = state.milestones.map(subject => {
        if (
          subject.uuid === classificationUuid &&
          subject.exam_milestone.uuid === examMilestoneUuid
        ) {
          const merge = { ...subject.exam_milestone, sourceInstitutionUuid };

          return {
            ...subject,
            exam_milestone: merge
          };
        } else {
          return subject;
        }
      });

      return {
        ...state,
        milestones: newState
      };
    }

    case 'add_conversion': {
      const { classificationUuid, examMilestoneUuid } = {
        ...action.payload
      };

      const newState = state.milestones.map(subject => {
        if (
          subject.uuid === classificationUuid &&
          subject.exam_milestone.uuid === examMilestoneUuid
        ) {
          const conversion_milestones = [
            ...subject.exam_milestone.conversion_milestones,
            {
              uuid: uuidv4(),
              type: 'new',
              scaledScore: '',
              percentage: ''
            }
          ];

          return {
            ...subject,
            exam_milestone: { ...subject.exam_milestone, conversion_milestones }
          };
        } else {
          return subject;
        }
      });

      return {
        ...state,
        milestones: newState
      };
    }

    case 'update_conversion': {
      const {
        classificationUuid,
        examMilestoneUuid,
        conversionMilestoneUuid,
        modify
      } = {
        ...action.payload
      };

      const newState = state.milestones.map(subject => {
        if (
          subject.uuid === classificationUuid &&
          subject.exam_milestone.uuid === examMilestoneUuid
        ) {
          if (
            subject.exam_milestone.conversion_milestones &&
            subject.exam_milestone.conversion_milestones.length > 0
          ) {
            const conversion_milestones = subject.exam_milestone.conversion_milestones.map(
              conversion => {
                if (conversion.uuid === conversionMilestoneUuid) {
                  const merge = { ...conversion, ...modify };
                  return merge;
                } else {
                  return conversion;
                }
              }
            );

            return {
              ...subject,
              exam_milestone: {
                ...subject.exam_milestone,
                conversion_milestones
              }
            };
          } else {
            return {
              ...subject,
              exam_milestone: {
                ...subject.exam_milestone,
                conversion_milestones: []
              }
            };
          }
        } else {
          return subject;
        }
      });

      return {
        ...state,
        milestones: newState
      };
    }

    case 'remove_conversion': {
      const {
        classificationUuid,
        examMilestoneUuid,
        conversionMilestoneUuid
      } = {
        ...action.payload
      };

      const remove = [];

      const newState = state.milestones.map(subject => {
        if (
          subject?.uuid === classificationUuid &&
          subject.exam_milestone?.uuid === examMilestoneUuid
        ) {
          if (subject.exam_milestone.conversion_milestones?.length > 0) {
            const keep = [];

            subject.exam_milestone.conversion_milestones.forEach(conversion => {
              if (
                conversion?.uuid === conversionMilestoneUuid &&
                conversion?.type !== 'new'
              ) {
                remove.push(conversion);
              } else if (conversion?.uuid !== conversionMilestoneUuid) {
                keep.push(conversion);
              }
            });

            return {
              ...subject,
              exam_milestone: {
                ...subject.exam_milestone,
                conversion_milestones: keep
              }
            };
          } else {
            return {
              ...subject
            };
          }
        } else {
          return {
            ...subject
          };
        }
      });

      return {
        ...state,
        milestones: newState,
        conversionsRemove: [...state.conversionsRemove, ...remove]
      };
    }

    default:
      throw new Error();
  }
};

export { subjectExamInitialState, subjectExamReducer };
