import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import { assessmentTypeSelectors } from '../../../../redux/selectors/assesssmentTypeSelectors';
import CompetencyRelativeByType from './CompetencyRelativeByType';
import CompetencyPotentialByType from './CompetencyPotentialByType';
import CompetencyScoreByType from './CompetencyScoreByType';
import CompetencyGradeByType from './CompetencyGradeByType';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .column_one {
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }

  .column_one > span {
    width: 100%;
  }
`;

const Label = styled.div`
  font-size: 16px;
  color: ${props => props.theme.fontColors.darker};
`;

const Calculations = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Record = styled.div`
  width: ${props => props.width};
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
`;
const GroupLabel = styled.div`
  padding: 10px;
`;
const GroupInput = styled.div`
  padding: 10px;
`;

export default function CompetencyByAssessmentType({ competencyUuid }) {
  const assessmentTypes = useSelector(
    state => assessmentTypeSelectors(state),
    _.isEqual
  );

  return (
    <>
      {assessmentTypes?.map(assessmentType => (
        <Accordion
          key={assessmentType.uuid}
          withBottomBorder={true}
          leaf
          height="80px"
          rowOffset="120px"
          label={<Label>{assessmentType?.name}</Label>}
          columnOneClassName="column_one"
          columnOne={
            <Calculations>
              <Record width="25%">
                <Group>
                  <GroupLabel />
                  <GroupInput>
                    <CompetencyRelativeByType
                      competencyUuid={competencyUuid}
                      assessmentTypeUuid={assessmentType.uuid}
                    />
                  </GroupInput>
                </Group>
              </Record>
              <Record width="25%">
                <Group>
                  <GroupLabel />
                  <GroupInput>
                    <CompetencyPotentialByType
                      competencyUuid={competencyUuid}
                      assessmentTypeUuid={assessmentType.uuid}
                    />
                  </GroupInput>
                </Group>
              </Record>
              <Record width="25%">
                <Group>
                  <GroupLabel />
                  <GroupInput>
                    <CompetencyScoreByType
                      competencyUuid={competencyUuid}
                      assessmentTypeUuid={assessmentType.uuid}
                    />
                  </GroupInput>
                </Group>
              </Record>
              <Record width="25%">
                <Group>
                  <GroupLabel />
                  <GroupInput>
                    <CompetencyGradeByType
                      competencyUuid={competencyUuid}
                      assessmentTypeUuid={assessmentType.uuid}
                    />
                  </GroupInput>
                </Group>
              </Record>
            </Calculations>
          }
        />
      ))}
    </>
  );
}

CompetencyByAssessmentType.propTypes = {
  competencyUuid: PropTypes.string
};

CompetencyByAssessmentType.defaultProps = {
  competencyUuid: undefined
};
