import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Panel } from '../../Library/DashboardSC';
import FilterDropDown from '../PartGradeSummary/FilterDropDown';
import OpportunityReportPanelContainer from './OpportunityReportPanelContainer';

const PanelSC = styled(Panel)`
  margin-top: 24px;
  min-width: 900px;
`;

const ProgressBar = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.fontColors.dark};
`;

const NoDataContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.fontColors.dark};
`;

const AssessmentHeaderContainer = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  padding-right: ${props => props.paddingRight};
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: flex-start;
  border-bottom: ${props => `solid 1px  ${props.theme.colors.grey[300]}`};
  font-size: 15px;
  font-weight: 450;
`;

const AssessmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 66px;
  width: calc(100% - 66px);
`;

const AssessmentHeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  overflow: hidden;
`;

const AssessmentHeaderData = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  min-width: 350px;
`;

const AssessmentHeaderSub = styled.div`
  min-width: ${props => props.minWidth};
  margin-right: ${props => props.marginRight};
  color: ${props => props.theme.fontColors.darker};
  text-align: right;
`;

const OpportunityReport = ({
  assessments,
  assessmentTypes,
  hasAppliedFilters,
  isLoading,
  onApplyPartOpportunityReportFilters,
  partOpportunityReportAssessmentStatisticData,
  partOpportunityReportStudentSummaryData,
  partOpportunityReportSummaryStatisticData,
  reportIsEmpty,
  studentUsers
}) => {
  return (
    <>
      <FilterDropDown
        options={assessmentTypes}
        callback={onApplyPartOpportunityReportFilters}
        title='Opportunity Report Filters'
      />
      {isLoading ? (
        <PanelSC>
          <ProgressBar>Loading</ProgressBar>
          <LinearProgress />
        </PanelSC>
      ) : (
        <PanelSC>
          {!reportIsEmpty && (
            <AssessmentHeaderContainer paddingRight="44px">
              <AssessmentHeader>
                <AssessmentHeaderInfo>
                  Student (Last name, First name)
                </AssessmentHeaderInfo>
                <AssessmentHeaderData>
                  <AssessmentHeaderSub marginRight="16px" minWidth="140px">
                    Total Opportunities
                  </AssessmentHeaderSub>
                  <AssessmentHeaderSub marginRight="16px" minWidth="100px">
                    Total Relative
                  </AssessmentHeaderSub>
                  <AssessmentHeaderSub marginRight="8px" minWidth="120px">
                    Points per Opp.
                  </AssessmentHeaderSub>
                </AssessmentHeaderData>
              </AssessmentHeader>
            </AssessmentHeaderContainer>
          )}
          {hasAppliedFilters && reportIsEmpty && (
            <NoDataContainer>
              No data for the selected filters. Change filter selection and
              re-apply.
            </NoDataContainer>
          )}
          {!hasAppliedFilters && reportIsEmpty && (
            <NoDataContainer>
              Apply the filter selection to view the report.
            </NoDataContainer>
          )}
          {!reportIsEmpty &&
            studentUsers.map(studentUser => (
              <OpportunityReportPanelContainer
                key={studentUser.uuid}
                assessments={assessments}
                partOpportunityReportAssessmentStatisticData={
                  partOpportunityReportAssessmentStatisticData
                }
                partOpportunityReportStudentSummaryData={
                  partOpportunityReportStudentSummaryData[studentUser.uuid]
                }
                partOpportunityReportSummaryStatisticData={
                  partOpportunityReportSummaryStatisticData
                }
                studentUser={studentUser}
              />
            ))}
        </PanelSC>
      )}
    </>
  );
};

OpportunityReport.propTypes = {
  assessments: PropTypes.array,
  assessmentTypes: PropTypes.array,
  hasAppliedFilters: PropTypes.bool,
  isLoading: PropTypes.bool,
  onApplyPartOpportunityReportFilters: PropTypes.func,
  partOpportunityReportAssessmentStatisticData: PropTypes.object,
  partOpportunityReportStudentSummaryData: PropTypes.object,
  partOpportunityReportSummaryStatisticData: PropTypes.object,
  reportIsEmpty: PropTypes.bool,
  studentUsers: PropTypes.array
};

OpportunityReport.defaultProps = {
  assessments: [],
  assessmentTypes: [],
  hasAppliedFilters: false,
  isLoading: false,
  onApplyPartOpportunityReportFilters: undefined,
  partOpportunityReportAssessmentStatisticData: {},
  partOpportunityReportStudentSummaryData: {},
  partOpportunityReportSummaryStatisticData: {},
  reportIsEmpty: true,
  studentUsers: []
};

export default OpportunityReport;
