import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import momentTz from 'moment-timezone';
import PropTypes from 'prop-types';

import {
  Grid,
  Paper,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup
} from '@material-ui/core';

import {
  DocumentIcon,
  DateIcon,
  UsersIcon
} from '@xcomp/xcomp-design-library/dist/icons';

import {
  validateSyllabusSelectUserSingle,
  validateSyllabusSelectMultiUserField
} from '../../../../helpers/validation/validateSyllabus';

import { validateSelectField } from '../../../../helpers/validation/validateGeneric';

import {
  validateAssessmentTitle,
  validateAssessmentStartDate,
  validateAssessmentEndDate
} from '../../../../helpers/validation/validateAssessment';

import withValidation from '../../../Library/FieldWithError/FieldWithError';
import FormErrors from '../../../Library/FormErrors';
import SelectUserFieldContainer from '../../../Library/SelectUserField/SelectUserFieldContainer';
import DateTime from '../../../Library/DateTime';
import ItemFormHeader from '../ItemFormHeader';
import DeleteModal from '../../../Library/Modal/DeleteModal/DeleteModal';
import SyllabusSubHeader from '../SyllabusSubHeader';
import FormSelectError from '../../../Library/FormSelectError';
import FormField from '../../../Library/FormField';

import { convertNullToString } from '../../../../helpers/utilities';
import { optionsScoreType } from '../../../../helpers/options';

const CheckboxContainer = styled.div`
  color: ${props => props.theme.fontColors.dark};
`;

const FormHelperTextSC = styled(FormHelperText)`
  && {
    margin-top: 0.5rem;
    margin-bottom: 1.25rem;
  }
`;

const AssessmentFormBody = styled.div`
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 200px;
  margin-bottom: 200px;
`;

const DatetimeSC = styled(DateTime)`
  height: 100%;
`;

const TimespanOptions = [
  {
    value: 'ONE TIME',
    label: 'One Time'
  },
  {
    value: 'WEEKLY',
    label: 'Weekly'
  },
  {
    value: 'OPEN ENDED',
    label: 'Open Ended'
  }
];

const PaperSC = styled(Paper)`
  && {
    border-radius: 0;
  }
`;

const DatetimePickerWithError = withValidation(DatetimeSC);
const SelectUserFieldWithError = withValidation(SelectUserFieldContainer);

const AssessmentForm = ({
  assessmentAssociationUuid,
  assessmentManager,
  assessmentTechniqueUuid,
  assessmentTypeUuid,
  assessmentUuid,
  endDate,
  location,
  legacyScores,
  responsibleFaculty,
  scoreType,
  startDate,
  status,
  timespan,
  title,
  existing,
  restrictEdit,
  term,
  openModal,
  fieldInFocus,
  hasErrors,
  assessmentIndex,
  hasUnsavedChanges,
  optionsFaculty,
  optionsAssessmentTypes,
  optionsAssessmentTechniques,
  optionsAssessmentAssociations,
  handleAssessmentChange,
  handleUserFieldChange,
  handleDateChange,
  handleAssessmentSaveDraft,
  handleAssessmentFinish,
  handleDeleteOpen,
  handleDeleteClose,
  handleApproveAssessment,
  handleFieldInFocusValidation,
  handleLegacyScores,
  onDiscardChanges
}) => {
  const appromixateTimeZone = moment.tz.guess();

  const nonMomentStartDate = moment.isMoment(startDate)
    ? startDate.format()
    : startDate;

  const nonMomentEndDate = moment.isMoment(endDate)
    ? endDate.format()
    : endDate;

  const startDateTimeZone = startDate
    ? momentTz(startDate).tz(appromixateTimeZone).toDate()
    : null;

  const endDateTimeZone = endDate
    ? momentTz(endDate).tz(appromixateTimeZone).toDate()
    : null;

  return (
    <div>
      <PaperSC>
        <ItemFormHeader
          restrictEdit={restrictEdit}
          itemType="Assessment"
          isExistingItem={existing}
          itemNumber={assessmentIndex}
          item={{ uuid: assessmentUuid, status }}
          handleSaveDraft={handleAssessmentSaveDraft}
          handleFinish={handleAssessmentFinish}
          handleApprove={handleApproveAssessment}
          handleDeleteOpen={handleDeleteOpen}
          hasUnsavedChanges={hasUnsavedChanges}
          onDiscardChanges={onDiscardChanges}
        />
        <AssessmentFormBody>
          <FormErrors hasErrors={hasErrors} />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SyllabusSubHeader first>
                <DocumentIcon />
                Assessment Information
              </SyllabusSubHeader>
            </Grid>

            <Grid item xs={12}>
              <FormSelectError
                name="assessmentTypeUuid"
                label="Assessment Type*"
                value={convertNullToString(assessmentTypeUuid)}
                handleValidation={validateSelectField}
                hasErrors={hasErrors}
                handleChange={handleAssessmentChange}
                options={optionsAssessmentTypes}
                disabled={restrictEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                name="title"
                label="Assessment Title*"
                value={title}
                onBlur={handleAssessmentChange}
                inputProps={{ maxLength: 100 }}
                handleValidate={validateAssessmentTitle}
                hasErrors={hasErrors || fieldInFocus === 'title'}
                onFocus={handleFieldInFocusValidation}
                disabled={restrictEdit}
              />
              <FormHelperTextSC>
                Character Count: {title.length}/100
              </FormHelperTextSC>
            </Grid>
            <Grid item xs={12}>
              <SyllabusSubHeader>
                <DateIcon />
                Time and Location
              </SyllabusSubHeader>
            </Grid>
            <Grid item xs={12}>
              <FormField
                name="location"
                label="Assessment Location"
                value={convertNullToString(location)}
                onBlur={handleAssessmentChange}
                disabled={restrictEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelectError
                name="timespan"
                label="Timespan"
                value={convertNullToString(timespan)}
                handleChange={handleAssessmentChange}
                options={TimespanOptions}
                disabled={restrictEdit}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <DatetimePickerWithError
                value={startDateTimeZone}
                name="startDate"
                handleChange={handleDateChange}
                label="Start Time*"
                hasErrors={hasErrors || fieldInFocus === 'startDate'}
                handleValidate={() =>
                  validateAssessmentStartDate(nonMomentStartDate, term)
                }
                onFocus={() => {
                  handleFieldInFocusValidation({
                    target: {
                      name: 'startDate'
                    }
                  });
                }}
                disabled={restrictEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <DatetimePickerWithError
                value={endDateTimeZone}
                name="endDate"
                handleChange={handleDateChange}
                label="End Time*"
                hasErrors={hasErrors || fieldInFocus === 'endDate'}
                handleValidate={() =>
                  validateAssessmentEndDate(nonMomentEndDate, term)
                }
                onFocus={() => {
                  handleFieldInFocusValidation({
                    target: {
                      name: 'endDate'
                    }
                  });
                }}
                disabled={restrictEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelectError
                name="assessmentAssociationUuid"
                label="Assessment Association*"
                value={convertNullToString(assessmentAssociationUuid)}
                handleValidation={validateSelectField}
                hasErrors={hasErrors}
                handleChange={handleAssessmentChange}
                options={optionsAssessmentAssociations}
                disabled={restrictEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelectError
                name="assessmentTechniqueUuid"
                label="Assessment Technique*"
                value={convertNullToString(assessmentTechniqueUuid)}
                handleValidation={validateSelectField}
                hasErrors={hasErrors}
                handleChange={handleAssessmentChange}
                options={optionsAssessmentTechniques}
                disabled={restrictEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <SyllabusSubHeader>
                <DocumentIcon />
                Grade Summary
              </SyllabusSubHeader>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormGroup>
                  <CheckboxContainer>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="special_collection"
                          onChange={handleLegacyScores}
                          checked={legacyScores}
                          color="primary"
                        />
                      }
                      label="Upload as legacy scores"
                    />
                  </CheckboxContainer>
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormSelectError
                name="scoreType"
                label="Score Type*"
                value={convertNullToString(scoreType)}
                handleValidation={validateSelectField}
                hasErrors={hasErrors}
                handleChange={handleAssessmentChange}
                options={optionsScoreType}
                disabled={restrictEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <SyllabusSubHeader>
                <UsersIcon />
                Responsible Personnel
              </SyllabusSubHeader>
            </Grid>
            <Grid item xs={9}>
              <SelectUserFieldWithError
                name="responsibleFaculty"
                handleValidate={validateSyllabusSelectUserSingle}
                hasErrors={hasErrors || fieldInFocus === 'responsibleFaculty'}
                userSelect
                fieldName="responsibleFaculty"
                label="Responsible Faculty*"
                handleChange={handleUserFieldChange}
                usersArray={optionsFaculty}
                placeholder="Select"
                values={responsibleFaculty}
                onFocus={() => {
                  handleFieldInFocusValidation({
                    target: {
                      name: 'responsibleFaculty'
                    }
                  });
                }}
                singleValue
                isDisabled={restrictEdit}
              />
            </Grid>

            <Grid item xs={9}>
              <SelectUserFieldWithError
                name="assessmentManager"
                handleValidate={validateSyllabusSelectMultiUserField}
                hasErrors={hasErrors || fieldInFocus === 'assessmentManager'}
                userSelect
                fieldName="assessmentManager"
                label="Assessment Manager*"
                handleChange={handleUserFieldChange}
                usersArray={optionsFaculty}
                placeholder="Select a user"
                values={assessmentManager}
                onFocus={() => {
                  handleFieldInFocusValidation({
                    target: {
                      name: 'assessmentManager'
                    }
                  });
                }}
                singleValue
                isDisabled={restrictEdit}
              />
            </Grid>
          </Grid>
        </AssessmentFormBody>
      </PaperSC>
      <DeleteModal
        modalOpen={openModal}
        type="Assessment"
        handleModalClose={() => handleDeleteClose(false)}
        handleDelete={() => handleDeleteClose(true)}
      />
    </div>
  );
};

AssessmentForm.propTypes = {
  hasUnsavedChanges: PropTypes.bool,
  assessmentAssociationUuid: PropTypes.string,
  assessmentManager: PropTypes.string,
  assessmentTechniqueUuid: PropTypes.string,
  assessmentTypeUuid: PropTypes.string,
  assessmentUuid: PropTypes.string,
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  location: PropTypes.string,
  legacyScores: PropTypes.bool,
  responsibleFaculty: PropTypes.string,
  scoreType: PropTypes.string,
  status: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  timespan: PropTypes.string,
  title: PropTypes.string,
  existing: PropTypes.bool,
  restrictEdit: PropTypes.bool,
  hasErrors: PropTypes.bool,
  openModal: PropTypes.bool,
  optionsFaculty: PropTypes.array,
  optionsAssessmentTypes: PropTypes.arrayOf(PropTypes.object),
  optionsAssessmentTechniques: PropTypes.arrayOf(PropTypes.object),
  optionsAssessmentAssociations: PropTypes.arrayOf(PropTypes.object),
  assessmentIndex: PropTypes.number,
  term: PropTypes.object,
  fieldInFocus: PropTypes.string,
  handleApproveAssessment: PropTypes.func,
  handleAssessmentChange: PropTypes.func,
  handleAssessmentFinish: PropTypes.func,
  handleAssessmentSaveDraft: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleDeleteClose: PropTypes.func,
  handleDeleteOpen: PropTypes.func,
  handleFieldInFocusValidation: PropTypes.func,
  handleUserFieldChange: PropTypes.func,
  handleLegacyScores: PropTypes.func,
  onDiscardChanges: PropTypes.func
};

AssessmentForm.defaultProps = {
  hasUnsavedChanges: false,
  assessmentAssociationUuid: '',
  assessmentManager: '',
  assessmentTechniqueUuid: '',
  assessmentTypeUuid: '',
  assessmentUuid: '',
  endDate: null,
  location: '',
  legacyScores: false,
  responsibleFaculty: '',
  scoreType: '',
  status: '',
  startDate: null,
  timespan: '',
  title: '',
  existing: false,
  hasErrors: false,
  openModal: false,
  restrictEdit: true,
  optionsFaculty: [],
  optionsAssessmentTypes: [],
  optionsAssessmentTechniques: [],
  optionsAssessmentAssociations: [],
  assessmentIndex: 0,
  term: {},
  fieldInFocus: '',
  handleApproveAssessment: undefined,
  handleAssessmentChange: undefined,
  handleAssessmentFinish: undefined,
  handleAssessmentSaveDraft: undefined,
  handleDateChange: undefined,
  handleDeleteClose: undefined,
  handleDeleteOpen: undefined,
  handleFieldInFocusValidation: undefined,
  handleUserFieldChange: undefined,
  handleLegacyScores: undefined,
  onDiscardChanges: undefined
};

export default AssessmentForm;
