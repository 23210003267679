import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { competencyScoreSelector } from '../../../../redux/selectors/competencyScoreSelectors';

export default function CompetencyPotential({ competencyUuid }) {
  const score = useSelector(
    state => competencyScoreSelector(state, competencyUuid),
    _.isEqual
  );

  return <>{score?.potTotal || '- -'}</>;
}

CompetencyPotential.propTypes = {
  competencyUuid: PropTypes.string
};

CompetencyPotential.defaultProps = {
  competencyUuid: undefined
};
