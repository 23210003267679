import { TOGGLE_DRAWER } from '../actions-type';

export const INITIAL_STATE = {
  drawerOpen: false
};

const navigationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_DRAWER: {
      return {
        drawerOpen: !state.drawerOpen
      };
    }
    default:
      return state;
  }
};

export default navigationReducer;
