import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CollectionCourseSearch from './CollectionCourseSearch';
import { doSearchCollectionCourses } from '../../../../redux/actions/courseCollectionActions';

class CollectionCourseSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      showResults: false
    };
  }

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.onSearchClick();
    }
  };

  handleChange = event => {
    this.setState({
      searchTerm: event.target.value
    });
  };

  onSearchClick = () => {
    const { searchTerm } = this.state;
    if (searchTerm && searchTerm.length > 0) {
      this.props.onSearch(searchTerm);
    }
    this.setState({
      showResults: searchTerm && searchTerm.length > 0
    });
  };

  onResultSelect = program => {
    const { handleCollectionCourseAdd } = this.props;
    handleCollectionCourseAdd(program);
    this.setState({
      searchTerm: '',
      showResults: false
    });
  };

  render() {
    const {
      searchResults,
      isLoading,
      label,
      selectedCourses,
      showValidationErrors,
      placeholder,
      fieldName,
      onClose,
      children
    } = this.props;
    return (
      <CollectionCourseSearch
        selectedCourses={selectedCourses}
        onSearchClick={this.onSearchClick}
        searchResults={searchResults}
        isLoading={isLoading}
        searchTerm={this.state.searchTerm}
        showResults={this.state.showResults}
        handleChange={this.handleChange}
        onResultSelect={this.onResultSelect}
        handleKeyDown={this.handleKeyDown}
        fieldName={fieldName}
        label={label}
        placeholder={placeholder}
        onClose={onClose}
        showValidationErrors={showValidationErrors}
      >
        {children && children}
      </CollectionCourseSearch>
    );
  }
}

CollectionCourseSearchContainer.propTypes = {
  selectedCourses: PropTypes.object.isRequired,
  searchResults: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onSearch: PropTypes.func,
  handleCollectionCourseAdd: PropTypes.func
};

CollectionCourseSearchContainer.defaultProps = {
  searchResults: null,
  isLoading: false,
  showValidationErrors: false,
  fieldName: 'searchTerm',
  label: '',
  placeholder: '',
  children: null,
  onClose: undefined,
  onSearch: undefined,
  handleCollectionCourseAdd: undefined
};

const mapStateToProps = state => ({
  searchResults: state.searchState.collectionCourseSearchResults,
  isLoading: state.searchState.isLoading
});

const mapDispatchToProps = dispatch => ({
  onSearch: searchTerm => dispatch(doSearchCollectionCourses(searchTerm))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionCourseSearchContainer);
export { CollectionCourseSearchContainer };
