import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MoreIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonIcon } from '@xcomp/xcomp-design-library';

export default function DegreeLevelActions({
  degreeLevelIndexUuid,
  handleDegreeEditOpen,
  handleDegreeDeleteOpen
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonIcon
        aria-owns={anchorEl ? 'degree-level-index-menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
        noHoverBg
      >
        <MoreIcon />
      </ButtonIcon>
      <Menu
        id="degree-level-index-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleDegreeEditOpen(degreeLevelIndexUuid);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleDegreeDeleteOpen(degreeLevelIndexUuid);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
}

DegreeLevelActions.propTypes = {
  degreeLevelIndexUuid: PropTypes.string,
  handleDegreeEditOpen: PropTypes.func,
  handleDegreeDeleteOpen: PropTypes.func
};

DegreeLevelActions.defaultProps = {
  degreeLevelIndexUuid: undefined,
  handleDegreeEditOpen: undefined,
  handleDegreeDeleteOpen: undefined
};
