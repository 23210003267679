import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { useParams } from 'react-router-dom';

import {
  doGetAssessmentsByPart,
  doGetAssessmentType
} from '../../../../redux/actions/assessmentActions';
import {
  assessmentsByTypesSelector,
  opportunityAssessmentTypesSelector
} from '../../../../redux/selectors/assessmentSelectors';
import {
  doClearPartOpportunityReport,
  doGetPartOpportunityReport
} from '../../../../redux/actions/partReportsActions';
import { sortByDateProperty } from '../../../../helpers/utilities';
import OpportunityReport from '../../OpportunityReport/OpportunityReport';
import { useSortReportStudents } from '../../../../helpers/hooks/useSortStudents';

const PartOpportunityReportContainer = ({ partUuid }) => {
  const dispatch = useDispatch();
  const { sectionUuid } = useParams();

  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);

  const {
    isLoading,
    partOpportunityReportAssessmentStatisticData,
    partOpportunityReportStudentSummaryData,
    partOpportunityReportSummaryStatisticData,
    records
  } = useSelector(state => state.partOpportunityReportState);

  const assessmentTypes = useSelector(
    state => opportunityAssessmentTypesSelector(state),
    isEqual
  );

  const sortedStudents = useSortReportStudents({
    sectionUuid,
    isPartReport: true
  });

  const assessments = useSelector(
    state =>
      assessmentsByTypesSelector(
        state,
        partUuid,
        assessmentTypes.map(assessmentType => assessmentType.uuid)
      ),
    isEqual
  );

  // clear report on unmount
  useEffect(() => {
    return () => dispatch(doClearPartOpportunityReport());
  }, [dispatch]);

  useEffect(() => {
    if (partUuid) {
      dispatch(doGetAssessmentsByPart(partUuid));
      dispatch(doGetAssessmentType());
    }
  }, [dispatch, partUuid]);

  const handleApplyPartOpportunityReportFilters = assessmentTypes => {
    setHasAppliedFilters(true);
    dispatch(doGetPartOpportunityReport(partUuid, assessmentTypes));
  };

  return (
    <OpportunityReport
      assessments={[...assessments].sort((a, b) =>
        sortByDateProperty(a, b, 'startDate')
      )}
      assessmentTypes={assessmentTypes}
      hasAppliedFilters={hasAppliedFilters}
      isLoading={isLoading}
      onApplyPartOpportunityReportFilters={
        handleApplyPartOpportunityReportFilters
      }
      partOpportunityReportAssessmentStatisticData={
        partOpportunityReportAssessmentStatisticData
      }
      partOpportunityReportStudentSummaryData={
        partOpportunityReportStudentSummaryData
      }
      partOpportunityReportSummaryStatisticData={
        partOpportunityReportSummaryStatisticData
      }
      partUuid={partUuid}
      reportIsEmpty={records === 0}
      studentUsers={sortedStudents}
    />
  );
};

PartOpportunityReportContainer.propTypes = {
  partUuid: PropTypes.string
};

PartOpportunityReportContainer.defaultProps = { partUuid: '' };

export default PartOpportunityReportContainer;
