import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  CheckIcon,
  RemoveIcon,
  WarningIcon
} from '@xcomp/xcomp-design-library/dist/icons';
import { Body1 } from '@xcomp/xcomp-design-library';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import StepBlockResultPanel from './StepBlockResultPanel';
import { sortByProperty } from '../../../../helpers/utilities';

const StepPanelAccordion = styled(
  ({
    withBottomBorder,
    withTopBorder,
    criticalFailure,
    blockStatus,
    ...rest
  }) => <AccordionRowContainer {...rest} />
)`
  margin-left: 1rem;
  width: calc(100% - 1rem);

  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    white-space: nowrap;
  }

  .accordion_row_label div {
    font-size: 16px;
    letter-spacing: 0.15;
    color: ${props => props.theme.fontColors.darker};
  }

  .line {
    left: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }

  .block_status_label {
    .MuiChip-root {
      background-color: ${props =>
        (props.blockStatus === 'pass' && props.theme.colors.status.success) ||
        props.theme.colors.status.error};
      color: ${props => props.theme.colors.white};
      min-width: 10rem;
      font-size: 1rem;
      margin-right: 1rem;
    }
    .MuiSvgIcon-root {
      color: ${props =>
        (props.$noScores && props.theme.colors.status.warning) ||
        (props.blockStatus === 'pass' && props.theme.colors.status.success) ||
        (props.blockStatus === 'pending' &&
          props.theme.colors.status.success) ||
        props.theme.colors.status.error};
      margin-right: 1rem;
      font-size: 2rem;
    }
  }
`;

const NoScoresMessage = styled(Body1)`
  margin-left: 66px;
  margin-top: 0;
`;

export default function StepBlockPanel({
  assessmentBlock,
  assessmentType,
  assessmentUuid,
  blockScoresByAttempt,
  handleScoreAddModalOpen,
  handleScoreEditModalOpen,
  handleDeleteStudentScoresModalOpen,
  partUuid,
  scoreType,
  studentBlockResultsByBlock,
  studentUuid,
  isAdmin,
  isMutating
}) {
  const latestStatus = studentBlockResultsByBlock.sort((a, b) =>
    sortByProperty(a, b, 'blockAttempt')
  )[studentBlockResultsByBlock.length - 1]?.status;

  const labelText = `BLOCK ${assessmentBlock.blockNumber} - ${assessmentBlock.title}`;
  let statusIcon = null;

  if (studentBlockResultsByBlock.length === 0) {
    statusIcon = <WarningIcon />;
  } else if (latestStatus === 'pass' || latestStatus === 'pending') {
    statusIcon = <CheckIcon />;
  } else {
    statusIcon = <RemoveIcon />;
  }

  return (
    <StepPanelAccordion
      height="50px"
      label={labelText}
      loading={isMutating}
      actions={isAdmin
        ? 
          [
            {
              label: 'Delete Block Scores',
              onClick: () => handleDeleteStudentScoresModalOpen(`&assessmentBlockUuid=${assessmentBlock.uuid}`),
              shouldOpenOnClick: false,
              icon: 'delete'
            }
          ]
        :
          false
      }
      columnOne={scoreType === 'Opportunity' ? null : <div />}
      columnOneClassName="line"
      columnTwo={scoreType === 'Opportunity' ? null : statusIcon}
      columnTwoClassName="block_status_label"
      blockStatus={latestStatus}
      $noScores={studentBlockResultsByBlock.length === 0}
    >
      {studentBlockResultsByBlock.length === 0 ? (
        <NoScoresMessage>
          No scores have been reported for this block. Make sure everyone
          finished grading.
        </NoScoresMessage>
      ) : (
        studentBlockResultsByBlock.map(assessmentBlockResult => (
          <StepBlockResultPanel
            assessmentBlockResult={assessmentBlockResult}
            assessmentBlockUuid={assessmentBlock.uuid}
            assessmentType={assessmentType}
            assessmentUuid={assessmentUuid}
            handleScoreAddModalOpen={handleScoreAddModalOpen}
            handleScoreEditModalOpen={handleScoreEditModalOpen}
            handleDeleteStudentScoresModalOpen={handleDeleteStudentScoresModalOpen}
            key={assessmentBlockResult.uuid}
            partUuid={partUuid}
            scores={blockScoresByAttempt[assessmentBlockResult.blockAttempt]}
            scoreType={scoreType}
            studentUuid={studentUuid}
            isAdmin={isAdmin}
            isMutating={isMutating}
          />
        ))
      )}
    </StepPanelAccordion>
  );
}

StepBlockPanel.propTypes = {
  assessmentBlock: PropTypes.object,
  assessmentType: PropTypes.string,
  assessmentUuid: PropTypes.string,
  blockScoresByAttempt: PropTypes.object,
  handleScoreAddModalOpen: PropTypes.func,
  handleScoreEditModalOpen: PropTypes.func,
  handleDeleteStudentScoresModalOpen: PropTypes.func,
  partUuid: PropTypes.string,
  scoreType: PropTypes.string,
  studentBlockResultsByBlock: PropTypes.array,
  studentUuid: PropTypes.string,
  isAdmin: PropTypes.bool,
  isMutating: PropTypes.bool,
};

StepBlockPanel.defaultProps = {
  assessmentBlock: {},
  assessmentType: '',
  assessmentUuid: '',
  blockScoresByAttempt: {},
  handleScoreAddModalOpen: undefined,
  handleScoreEditModalOpen: undefined,
  handleDeleteStudentScoresModalOpen: undefined,
  partUuid: '',
  scoreType: '',
  studentBlockResultsByBlock: [],
  studentUuid: '',
  isAdmin: false,
  isMutating: false,
};
