import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Revus from './Revus';
import { doPatchSchoolRevus } from '../../../../redux/actions/schoolActions';
import { schoolSelectorBySelectedSchoolUuid } from '../../../../redux/selectors/schoolSelectors';
import { validateInputNumber } from '../../../../helpers/validation/validateGeneric';

class RevusContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      revus: 0,
      minutes: 0,
      hours: 0,
      errors: false
    };

    this.handleRevusChange = this.handleRevusChange.bind(this);
    this.handleMinutesChange = this.handleMinutesChange.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this._setFormState = this._setFormState.bind(this);
    this._validate = this._validate.bind(this);
  }

  componentDidMount() {
    if (this.props.school) {
      this._setFormState();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.school !== prevProps.school) {
      this._setFormState();
    }
  }

  handleRevusChange(event) {
    const { value } = event.target;
    this.setState({ revus: Number(value) });
  }

  handleMinutesChange(event) {
    const { value } = event.target;

    const number = Number(value);
    const hours = number / 60;

    this.setState(state => ({
      ...state,
      minutes: number,
      hours
    }));
  }

  handleSave() {
    const { school, onPatchSchoolRevus } = this.props;
    const revus = { ...this.state, schoolUuid: school.uuid };

    const hasErrors = this._validate();

    if (hasErrors) {
      this.setState({
        errors: true
      });
    } else {
      onPatchSchoolRevus(revus);
    }
  }

  _setFormState() {
    this.setState({
      revus: Number(this.props.school.revus),
      minutes: Number(this.props.school.minutes),
      hours: Number(this.props.school.hours)
    });
  }

  _validate() {
    const { revus, minutes } = this.state;

    const revusError = validateInputNumber(revus);
    const minutesError = validateInputNumber(minutes);

    let validationError = false;

    if (revusError.invalid) {
      validationError = true;
    }

    if (minutesError.invalid) {
      validationError = true;
    }

    return validationError;
  }

  render() {
    const { revus, minutes, hours, errors } = this.state;

    return (
      <Revus
        revus={revus}
        minutes={minutes}
        hours={hours}
        errors={errors}
        handleRevusChange={this.handleRevusChange}
        handleMinutesChange={this.handleMinutesChange}
        handleSave={this.handleSave}
      />
    );
  }
}

RevusContainer.propTypes = {
  school: PropTypes.object,
  onPatchSchoolRevus: PropTypes.func
};
RevusContainer.defaultProps = {
  school: { revus: 0, minutes: 0, hours: 0 },
  onPatchSchoolRevus: undefined
};

const mapStateToProps = state => {
  return {
    school: schoolSelectorBySelectedSchoolUuid(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onPatchSchoolRevus: revus => dispatch(doPatchSchoolRevus(revus))
});

export default connect(mapStateToProps, mapDispatchToProps)(RevusContainer);
