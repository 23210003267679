import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CohortUploadApplicants from './CohortUploadApplicants';
import {
  doUploadApplicants,
  doUploadApplicantsReset
} from '../../../../redux/actions/userCohortActions';

export default function CohortUploadApplicantsContainer({
  cohortUuid,
  openModal,
  handleCloseApplicantUpload
}) {
  const dispatch = useDispatch();
  const [applicants, setApplicants] = useState(undefined);
  const [openMapping, setOpenMapping] = useState(false);
  const uploadState = useSelector(
    state => state.uploadApplicantsState,
    _.isEqual
  );

  useEffect(() => {
    if (!openModal) {
      setApplicants(undefined);
      setOpenMapping(false);
      dispatch(doUploadApplicantsReset());
    }
  }, [dispatch, openModal]);

  const handleApplicants = applicants => {
    setApplicants(applicants);
  };

  const handleUpload = () => {
    dispatch(doUploadApplicants(cohortUuid, applicants));
  };

  const handleDisplayMapping = () => {
    setOpenMapping(openMapping => !openMapping);
  };

  return (
    <CohortUploadApplicants
      applicants={applicants}
      uploadState={uploadState}
      openModal={openModal}
      openMapping={openMapping}
      handleApplicants={handleApplicants}
      handleCloseApplicantUpload={handleCloseApplicantUpload}
      handleDisplayMapping={handleDisplayMapping}
      handleUpload={handleUpload}
    />
  );
}

CohortUploadApplicantsContainer.propTypes = {
  cohortUuid: PropTypes.string,
  openModal: PropTypes.bool,
  handleCloseApplicantUpload: PropTypes.func
};
CohortUploadApplicantsContainer.defaultProps = {
  cohortUuid: '',
  openModal: false,
  handleCloseApplicantUpload: undefined
};
