import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LetterGradeShowMenu from './LetterGradeShowMenu';
import { ElevateLow } from '../../../../Library/Layout';

const Layout = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 20px;
  margin-bottom: 25px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LetterGradeShow = ({
  letterGrade,
  handleEditMode,
  handleDeleteModalOpen
}) => {
  return (
    <ElevateLow>
      <Layout>
        <Actions>
          <LetterGradeShowMenu
            letterGradeUuid={letterGrade.uuid}
            handleEditMode={handleEditMode}
            handleDeleteModalOpen={handleDeleteModalOpen}
          />
        </Actions>
        <Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={4}>
              <TextField
                name="grade"
                label="Grade"
                value={letterGrade.grade}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="rangelow"
                label="Range Low"
                value={letterGrade.rangeLow}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="rangehigh"
                label="Range High"
                value={letterGrade.rangeHigh}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={4}>
              <TextField
                name="conversion"
                label="Conversion"
                value={letterGrade.conversion}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="creditmultiplier"
                label="Credit Multiplier"
                value={letterGrade.creditMultiplier}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    </ElevateLow>
  );
};

LetterGradeShow.propTypes = {
  letterGrade: PropTypes.object,
  handleEditMode: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func
};

LetterGradeShow.defaultProps = {
  letterGrade: {},
  handleEditMode: undefined,
  handleDeleteModalOpen: undefined
};

export default LetterGradeShow;
