import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_SECTION_UNDO_CALCULATED_GRADE_SUCCESS,
  GET_COURSES_SUCCESS,
  GET_COURSE_BY_STUDENT_SUCCESS,
  GET_COURSE_SUCCESS,
  GET_SCORES_SUCCESS,
  GET_SECTION_COMMITMENT_LOG_SUCCESS,
  GET_SECTION_NESTED_BY_COURSE_SUCCESS,
  GET_SECTION_SUCCESS,
  GET_SYLLABUS_COURSE_SUCCESS,
  GET_SYLLABUS_SUCCESS,
  PATCH_SECTION_CALCULATE_GRADE,
  PATCH_SECTION_CALCULATE_GRADE_ERROR,
  PATCH_SECTION_CALCULATE_GRADE_SUCCESS,
  POST_COURSE_SUCCESS,
  PUT_COURSE_SUCCESS,
  PUT_SECTION_DATASOURCE_SUCCESS,
  PUT_SECTION_PART_WEIGHTS_SUCCESS,
  PUT_SECTION_STATUS_COMMIT_SUCCESS
} from '../actions-type';

class Section extends Model {
  static reducer(action, Section, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_COURSES_SUCCESS: {
        const courses = [...payload.courses];
        courses.forEach(course =>
          course.sections.forEach(section => Section.upsert(section))
        );
        break;
      }
      case GET_SECTION_SUCCESS: {
        const { section } = payload;
        Section.upsert(section);
        break;
      }
      case GET_SECTION_COMMITMENT_LOG_SUCCESS: {
        const { section } = payload;

        Section.upsert(section);
        break;
      }
      case PUT_SECTION_DATASOURCE_SUCCESS: {
        const { section } = payload;

        Section.upsert(section);
        break;
      }
      case PUT_SECTION_PART_WEIGHTS_SUCCESS: {
        const { section } = payload;

        Section.upsert(section);
        break;
      }
      case PATCH_SECTION_CALCULATE_GRADE: {
        const { sectionUuid } = payload;

        Section.withId(sectionUuid).update({
          statusGrade: 'GRADING_IN_PROGRESS'
        });
        break;
      }
      case PATCH_SECTION_CALCULATE_GRADE_SUCCESS: {
        const { section } = payload;

        Section.upsert(section);
        break;
      }
      case PATCH_SECTION_CALCULATE_GRADE_ERROR: {
        const { sectionUuid } = payload;

        if (Section.withId(sectionUuid))
          Section.withId(sectionUuid).update({
            statusGrade: 'NOT_GRADED'
          });
        break;
      }
      case PUT_SECTION_STATUS_COMMIT_SUCCESS: {
        const { section } = payload;

        Section.upsert(section);
        break;
      }
      case DELETE_SECTION_UNDO_CALCULATED_GRADE_SUCCESS: {
        const { section } = payload;

        Section.upsert(section);
        break;
      }
      case POST_COURSE_SUCCESS: {
        const course = { ...payload.course };
        course.sections.forEach(section => Section.upsert(section));
        break;
      }
      case GET_COURSE_SUCCESS: {
        const course = { ...payload.course };
        course.sections.forEach(section => Section.upsert(section));
        break;
      }
      case PUT_COURSE_SUCCESS: {
        const course = { ...payload.course };
        course.sections.forEach(section => Section.upsert(section));
        break;
      }
      case GET_SYLLABUS_SUCCESS: {
        const section = { ...payload.section };
        Section.upsert(section);
        break;
      }
      case GET_SECTION_NESTED_BY_COURSE_SUCCESS: {
        const sections = [...payload.sections];
        return sections.forEach(section => Section.upsert(section));
      }
      case GET_SCORES_SUCCESS: {
        const section = { ...payload.section };
        Section.upsert(section);
        break;
      }
      case GET_COURSE_BY_STUDENT_SUCCESS: {
        const courses = [...payload.courses];

        if (courses?.length > 0) {
          courses.forEach(course => {
            const { sections } = course;

            if (sections?.length > 0) {
              sections.forEach(section => {
                Section.upsert(section);
              });
            }
          });
        }

        break;
      }
      case GET_SYLLABUS_COURSE_SUCCESS: {
        const course = { ...payload.course };
        const { sections } = course;

        if (sections?.length > 0) {
          sections.forEach(section => {
            const copy = { ...section };

            delete copy.parts;

            Section.upsert(copy);
          });
        }

        break;
      }
      default:
        return session;
    }
  }
}

Section.fields = {
  uuid: attr(),
  courseId: attr(),
  sectionNumber: attr(),
  directors: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  courseUuid: fk('Course', 'sections')
};

Section.modelName = 'Section';

Section.options = {
  idAttribute: 'uuid'
};

export default Section;
