import _ from 'lodash';
import {
  POST_CHECK_USERS,
  POST_CHECK_USERS_SUCCESS,
  POST_CHECK_USERS_ERROR,
  POST_CHECK_USERS_RESET
} from '../actions-type';

export const INITIAL_STATE = {
  usersCheck: false,
  usersCheckStatus: 'Inactive',
  usersCheckMatch: undefined,
  error: undefined
};

const checkUsersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_CHECK_USERS: {
      return {
        ...state,
        usersCheck: true,
        usersCheckStatus: 'In Progress',
        error: undefined
      };
    }
    case POST_CHECK_USERS_SUCCESS: {
      return {
        ...state,
        usersCheck: false,
        usersCheckStatus: 'Success',
        usersCheckMatch: action.payload.similar_users
      };
    }
    case POST_CHECK_USERS_ERROR: {
      return {
        ...state,
        usersCheck: false,
        usersCheckStatus: 'Failed',
        error: _.get(action, 'payload.error')
      };
    }
    case POST_CHECK_USERS_RESET: {
      return {
        usersCheck: false,
        usersCheckStatus: 'Inactive',
        error: undefined,
        usersCheckMatch: undefined
      };
    }
    default:
      return state;
  }
};

export default checkUsersReducer;
