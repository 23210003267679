import { Model, attr, fk } from 'redux-orm';
import _ from 'lodash';
import {
  GET_USER_SUCCESS,
  GET_USER_ACTIVE_SUCCESS,
  GET_USERS_SCHOOL_SUCCESS,
  GET_USERS_SCHOOLS_SUCCESS,
  GET_USERS_BY_SEARCH_ROLES_SUCCESS,
  GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS,
  POST_USER_SUCCESS,
  PUT_USER_ROLES_SUCCESS,
  DELETE_USER_SUCCESS,
  POST_USER_ROLE_SUCCESS
} from '../actions-type';

class UserSchool extends Model {
  static reducer(action, UserSchool, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_USER_ACTIVE_SUCCESS: {
        if (payload?.roles?.school_admin?.length > 0) {
          const userSchools = [...payload.roles.school_admin];
          userSchools.forEach(userSchool => {
            const add = _.omit(userSchool, ['school']);
            UserSchool.upsert(add);
          });
        }
        break;
      }
      case GET_USER_SUCCESS: {
        const userSchools = [...payload.userSchools];
        userSchools.forEach(userSchool => UserSchool.upsert(userSchool));
        break;
      }
      case GET_USERS_SCHOOL_SUCCESS: {
        const userSchools = [...payload.userSchools];
        userSchools.forEach(userSchool => UserSchool.upsert(userSchool));
        break;
      }
      case GET_USERS_SCHOOLS_SUCCESS: {
        const userSchools = [...payload.userSchools];
        userSchools.forEach(userSchool => UserSchool.upsert(userSchool));
        break;
      }
      case GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS: {
        const usersSchools = [...payload.usersSchools];
        usersSchools.forEach(userSchool => UserSchool.upsert(userSchool));
        break;
      }
      case POST_USER_SUCCESS: {
        const userSchools = [...payload.userSchools];
        userSchools.forEach(userSchool => UserSchool.create(userSchool));
        break;
      }
      case PUT_USER_ROLES_SUCCESS: {
        if (payload?.schoolAdminRemove?.length > 0) {
          const schoolAdminRemove = [...payload.schoolAdminRemove];
          schoolAdminRemove.forEach(uuid => UserSchool.withId(uuid).delete());
        }

        const userSchools = [...payload.userSchools];
        if (userSchools?.length > 0) {
          userSchools.forEach(userSchool => UserSchool.upsert(userSchool));
        }

        break;
      }
      case DELETE_USER_SUCCESS: {
        UserSchool.all()
          .filter(userSchool => userSchool.userUuid === payload.userUuid)
          .delete();
        break;
      }
      case POST_USER_ROLE_SUCCESS: {
        if (payload.roleType === 'school_role') {
          UserSchool.create(payload.role);
        }

        break;
      }
      case GET_USERS_BY_SEARCH_ROLES_SUCCESS: {
        if (payload?.userSchools?.length > 0) {
          const userSchools = [...payload.userSchools];
          userSchools.forEach(userSchool => UserSchool.upsert(userSchool));
        }
        break;
      }
      default:
        return session;
    }
  }
}

UserSchool.fields = {
  uuid: attr(),
  role: attr(),
  email: attr(),
  phone: attr(),
  status: attr(),
  startDate: attr(),
  endDate: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  userUuid: fk({
    to: 'User',
    as: 'user',
    relatedName: 'user_schools'
  }),
  schoolUuid: fk({
    to: 'School',
    as: 'school',
    relatedName: 'user_schools'
  })
};

UserSchool.modelName = 'UserSchool';

UserSchool.options = {
  idAttribute: 'uuid'
};

export default UserSchool;
