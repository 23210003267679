import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getSyllabus,
  getSyllabusPart,
  getSyllabusCourse
} from '../../api/syllabusApi';
import {
  doGetSyllabusSuccess,
  doGetSyllabusError,
  doGetSyllabusCourseSuccess,
  doGetSyllabusCourseError,
  doGetSyllabusPartSuccess,
  doGetSyllabusPartError
} from '../actions/syllabusActions';

import {
  GET_SYLLABUS,
  GET_SYLLABUS_PART,
  GET_SYLLABUS_COURSE
} from '../actions-type';

function* handleFetchSyllabus(action) {
  const { courseUuid, sectionUuid, partUuid } = action.payload;

  try {
    const result = yield call(getSyllabus, courseUuid, sectionUuid, partUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetSyllabusSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSyllabusError(error));
  }
}

function* handleGetSyllabusCourse(action) {
  const { courseUuid } = action.payload;

  try {
    const result = yield call(getSyllabusCourse, courseUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetSyllabusCourseSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSyllabusCourseError(error));
  }
}

function* handleGetSyllabusPart(action) {
  const { partUuid } = action.payload;

  try {
    const result = yield call(getSyllabusPart, partUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetSyllabusPartSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSyllabusPartError(error));
  }
}

const syllabus = [
  takeEvery(GET_SYLLABUS, handleFetchSyllabus),
  takeEvery(GET_SYLLABUS_COURSE, handleGetSyllabusCourse),
  takeEvery(GET_SYLLABUS_PART, handleGetSyllabusPart)
];

export { syllabus };
