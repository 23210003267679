import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const departmentsSelector = createSelector(
  state => state,
  redux => {
    const departmentWithSchool = ormCreateSelector(
      orm.Department,
      orm.Department.school,
      (departments, schools) => {
        const addSchool = departments.map((department, index) => {
          return { ...department, schoolInfo: schools[index] };
        });

        return addSchool;
      }
    );

    const departmentsAll = departmentWithSchool(redux);

    return departmentsAll;
  }
);

export const departmentsBySchoolStateSelector = createSelector(
  state => state,
  state => state.userState.selectedSchoolUuid,
  (redux, selectedSchoolUuid) => {
    const departmentsSelectorORM = ormCreateSelector(orm.Department);

    const departments = departmentsSelectorORM(redux).filter(
      department => department.schoolUuid === selectedSchoolUuid
    );

    const departmentsSorted = _.sortBy(departments, ['name']);

    return departmentsSorted;
  }
);

export const departmentsBySchoolPropSelector = createSelector(
  state => state,
  (_, schoolUuid) => schoolUuid,
  (redux, schoolUuid) => {
    const departmentsSelectorORM = ormCreateSelector(orm.Department);

    const departments = departmentsSelectorORM(redux).filter(
      department => department.schoolUuid === schoolUuid
    );

    return departments;
  }
);

export const departmentSelector = createSelector(
  state => state,
  (_, departmentUuid) => departmentUuid,
  (redux, departmentUuid) => {
    const departmentsSelectorORM = ormCreateSelector(orm.Department);
    const department = departmentsSelectorORM(redux, departmentUuid) || {};

    return department;
  }
);

export const selectDepartments = ormCreateSelector(orm.Department);

export const selectDepartmentSchoolUuid = ormCreateSelector(
  orm.Department,
  department => department.schoolUuid
);

export const selectDepartmentUuid = ormCreateSelector(
  orm.Department,
  department => department.uuid
);

export const selectDepartmentsBySchool = createSelector(
  state => state,
  (_, schoolUuid) => schoolUuid,
  (redux, schoolUuid) => {
    const departmentsSelectorORM = ormCreateSelector(orm.Department);

    const departments = departmentsSelectorORM(redux).filter(
      department => department.schoolUuid === schoolUuid
    );

    return departments;
  }
);
