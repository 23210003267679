import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2 } from '@xcomp/xcomp-design-library';
import SectionPerformanceTableHeader from './PerformanceTable/SectionPerformanceTableHeader';
import {
  Table,
  TableBody,
  ScrollableDiv,
  NoDataContainer
} from './PerformanceTable/SharedSC';
import { Panel, PanelHeader } from '../../../../Library/Layout';
import DelayedStudentPerformanceTableRow from './PerformanceTable/DelayedStudentPerformanceTableRow';

const PanelSC = styled(Panel)`
  margin-top: 2.5rem;
`;

const PanelHeaderSC = styled(PanelHeader)`
  && {
    display: flex;
    height: auto;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
    .MuiFormControlLabel-root span {
      font-size: 18px;
    }
  }
`;

const DelayedStudentPerformanceTable = ({
  delayedStudentReports,
  isLoadingReport,
  parts
}) => {
  const [useFillerColumn, setUseFillerColumn] = useState(false);
  const [fillerColumnWidth, setFillerColumnWidth] = useState(0);
  const [width, setWidth] = useState(0);

  const tableRef = useRef(null);

  useLayoutEffect(() => {
    const cur = tableRef?.current?.clientWidth ?? 0;
    setWidth(cur);
  }, []);

  useEffect(() => {
    const occupiedWidth = 475 + parts.length * 75;
    if (width > occupiedWidth) {
      setUseFillerColumn(true);
      setFillerColumnWidth(width - occupiedWidth);
    } else {
      setUseFillerColumn(false);
      setFillerColumnWidth(0);
    }
  }, [width, parts.length, isLoadingReport]);

  return (
    <PanelSC>
      <PanelHeaderSC paddingLeft="24px">
        <Heading2>Delayed Students</Heading2>
      </PanelHeaderSC>
      <ScrollableDiv>
        <Table ref={tableRef}>
          <TableBody>
            <SectionPerformanceTableHeader
              parts={parts}
              useFillerColumn={useFillerColumn}
              fillerColumnWidth={fillerColumnWidth}
              isDelayedPanel
            />
            {delayedStudentReports.length === 0 && (
              <NoDataContainer>
                No delayed students found in this section
              </NoDataContainer>
            )}
            {delayedStudentReports.map((studentReportInfo, idx) => (
              <DelayedStudentPerformanceTableRow
                studentReportInfo={studentReportInfo}
                parts={parts}
                useFillerColumn={useFillerColumn}
                fillerColumnWidth={fillerColumnWidth}
                idx={idx}
              />
            ))}
          </TableBody>
        </Table>
      </ScrollableDiv>
    </PanelSC>
  );
};

DelayedStudentPerformanceTable.propTypes = {
  delayedStudentReports: PropTypes.array,
  isLoadingReport: PropTypes.bool,
  parts: PropTypes.array
};

DelayedStudentPerformanceTable.defaultProps = {
  delayedStudentReports: [],
  isLoadingReport: false,
  parts: []
};

export default DelayedStudentPerformanceTable;
