import {
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR
} from '../actions-type';

const doLogoutUser = () => ({
  type: LOGOUT_USER
});
const doLogoutUserSuccess = result => ({
  type: LOGOUT_USER_SUCCESS,
  payload: result
});
const doLogoutUserError = error => ({
  type: LOGOUT_USER_ERROR,
  payload: error
});

export { doLogoutUser, doLogoutUserSuccess, doLogoutUserError };
