import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import NewProgramAssessmentCollection from './NewProgramAssessmentCollection';
import {
  validateStringLength,
  allFieldsAreValid
} from '../../../../helpers/validation/validateGeneric';
import { doPostProgramAssessmentCollection } from '../../../../redux/actions/programActions';

const NewProgramAssessmentCollectionContainer = ({
  programUuid,
  clearNewForm
}) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const onPostCreate = collection =>
    dispatch(doPostProgramAssessmentCollection(collection));

  const setValidationErrors = ({ title }) => {
    const nameError = validateStringLength(title, 100, true);

    const newValidationErrors = {
      nameError
    };

    return newValidationErrors;
  };

  const onSubmit = () => {
    const newValidationErrors = setValidationErrors({
      title
    });

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        title,
        programUuid
      };

      onPostCreate(payload);
      setShowValidationErrors(false);
    } else {
      setShowValidationErrors(true);
    }
  };

  const handleTitleChange = event => {
    setTitle(event.target.value);
  };

  return (
    <NewProgramAssessmentCollection
      title={title}
      clearNewForm={clearNewForm}
      handleTitleChange={handleTitleChange}
      onSubmit={onSubmit}
      showValidationErrors={showValidationErrors}
    />
  );
};

NewProgramAssessmentCollectionContainer.propTypes = {
  programUuid: PropTypes.string.isRequired,
  clearNewForm: PropTypes.func
};

NewProgramAssessmentCollectionContainer.defaultProps = {
  clearNewForm: undefined
};

export default NewProgramAssessmentCollectionContainer;
