import React from 'react';
import styled from 'styled-components';
import { tintColor } from '@xcomp/xcomp-design-library/dist/helpers';
import { Tooltip } from '@material-ui/core';

export const TableRow = styled.tr`
  display: flex;
  justify-content: flex-start;
  inline-size: min-content;
  td:last-child {
    border-right: none;
  }
`;

export const InfoTd = styled(({ greyOne, greyTwo, ...rest }) => (
  <td {...rest} />
))`
  min-width: 250px;
  max-width: 250px;
  min-height: 53px;
  z-index: 10;
  position: sticky;
  left: 225px;
  background-color: ${props =>
    (props.greyTwo && props.theme.colors.grey[200]) ||
    (props.greyOne && props.theme.colors.grey[100]) ||
    'white'};
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
`;

export const FillerTd = styled(
  ({ borderTop, borderBottom, greyOne, greyTwo, ...rest }) => <td {...rest} />
)`
  min-width: ${props => `${props.width}px`};
  max-width: ${props => `${props.width}px`};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-top: ${props =>
    props.borderTop ? `1px solid ${props.theme.colors.grey[300]}` : 'none'};
  border-bottom: ${props =>
    props.borderBottom ? `1px solid ${props.theme.colors.grey[300]}` : 'none'};
  background-color: ${props =>
    (props.greyOne && props.theme.colors.grey[100]) ||
    (props.greyTwo && props.theme.colors.grey[200]) ||
    'white'};
`;

export const Table = styled.table`
  background: ${props => props.theme.colors.white};
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
`;

export const TableBody = styled.tbody`
  color: ${props => props.theme.colors.grey[900]};
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 24px;
  tr:first-child td {
    border-top: solid 1px ${props => props.theme.colors.grey[300]};
  }

  tr:last-child td {
    border-bottom: none;
  }
`;

export const ScrollableDiv = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: auto;
`;

export const RowInfoTitle = styled(({ infoTitle, ...rest }) => (
  <Tooltip {...rest} />
))`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 53px;
  padding: 16px;
  color: ${props => props.theme.fontColors.darker};
  font-size: ${props => (props.infoTitle ? '15px' : '14px')};
  font-weight: ${props => (props.infoTitle ? '450' : '400')};
  line-height: 20px;
  text-align: center;
  .MuiSvgIcon-root {
    margin-right: 16px;
  }
  .MuiChip-root {
    margin-right: 16px;
    background-color: ${props => props.theme.colors.blue[100]};
  }
`;

export const StickyDataTd = styled(
  ({
    greyOne,
    greyTwo,
    redOne,
    redTwo,
    redThree,
    blueThree,
    left,
    ...rest
  }) => <td {...rest} />
)`
  min-width: 75px;
  max-width: 75px;
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  background-color: ${props =>
    (props.greyOne && props.theme.colors.grey[100]) ||
    (props.greyTwo && props.theme.colors.grey[200]) ||
    (props.redOne && tintColor(props.theme.colors.red[100], 0.5)) ||
    (props.redTwo && tintColor(props.theme.colors.red[100], 0.4)) ||
    (props.redThree && tintColor(props.theme.colors.red[100], 0.7)) ||
    (props.blueOne && tintColor(props.theme.colors.blue[500], 0.8)) ||
    (props.blueTwo && tintColor(props.theme.colors.blue[100], 0.5)) ||
    (props.blueThree && tintColor(props.theme.colors.blue[100], 0.7)) ||
    'white'};
  position: sticky;
  left: ${props => props.left};
`;

export const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const NoDataContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: ${props => props.theme.colors.grey[300]};
  font-size: 14px;
  font-weight: 450;
  color: ${props => props.theme.fontColors.darker};
`;
