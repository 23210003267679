import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ButtonInline } from '@xcomp/xcomp-design-library';

import {
  Column,
  CancelButton,
  SubmitButton,
  FormTop,
  FormBottom,
  FormSectionHeaderSubtitle,
  FormSectionHeader
} from '../../Library/FormComponents';
import CollectionCourseSearchContainer from './CollectionCourseSearch/CollectionCourseSearchContainer';
import CourseCollectionFormDetails from './FormSections/CourseCollectionFormDetails';
import CourseCollectionCoursesForm from './FormSections/CourseCollectionCoursesForm';
import { setCollectionCourses } from './courseCollectionState';

const FormLayout = styled.div`
  padding: 1.5rem 1.25rem 1rem 1.25rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const CourseCollectionForm = ({
  courseCollection,
  buttonText,
  handleNameChange,
  handleCreditsRequiredChange,
  handleAllCreditsRequiredChange,
  handleSpecialCollectionChange,
  formDispatch,
  onSubmit,
  showValidationErrors,
  onFormClose,
  isEditForm
}) => {
  const [showSearchForm, setShowSearchForm] = useState(false);
  const numberOfCourses = Object.keys(courseCollection.courses).length;
  const openSearchForm = () => setShowSearchForm(true);
  const closeSearchForm = () => setShowSearchForm(false);

  useEffect(() => {
    if (!isEditForm && courseCollection.courses && numberOfCourses < 1) {
      setShowSearchForm(true);
    }
  }, [isEditForm, courseCollection.courses, numberOfCourses]);

  const handleCollectionCourseAdd = courseToAdd => {
    formDispatch(setCollectionCourses(courseToAdd));
    setShowSearchForm(false);
  };

  return (
    <FormLayout>
      <FormTop>
        <CourseCollectionFormDetails
          name={courseCollection.name}
          credits_required={courseCollection.credits_required}
          special_collection={courseCollection.special_collection}
          all_credits_required={courseCollection.all_credits_required}
          showValidationErrors={showValidationErrors}
          handleNameChange={handleNameChange}
          handleCreditsRequiredChange={handleCreditsRequiredChange}
          handleAllCreditsRequiredChange={handleAllCreditsRequiredChange}
          handleSpecialCollectionChange={handleSpecialCollectionChange}
        />
        <Column size="100%">
          <FormSectionHeader offset="0">
            <FormSectionHeaderSubtitle>Courses</FormSectionHeaderSubtitle>
          </FormSectionHeader>
        </Column>
        <Column size="100%">
          {numberOfCourses > 0 && (
            <CourseCollectionCoursesForm
              selectedCourses={courseCollection.courses}
              formDispatch={formDispatch}
            />
          )}
          {showSearchForm ? (
            <CollectionCourseSearchContainer
              label="Search Courses"
              fieldName="courseCollectionSearchTerm"
              selectedCourses={courseCollection.courses}
              placeholder="Search"
              handleCollectionCourseAdd={handleCollectionCourseAdd}
              showValidationErrors={showValidationErrors}
              onClose={closeSearchForm}
            />
          ) : (
            <ButtonInline onClick={openSearchForm} noHoverBg>
              Add Course
            </ButtonInline>
          )}
        </Column>
      </FormTop>
      <FormBottom>
        <CancelButton onClick={onFormClose} noHoverBg>
          Cancel
        </CancelButton>
        <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
      </FormBottom>
    </FormLayout>
  );
};

CourseCollectionForm.propTypes = {
  courseCollection: PropTypes.object,
  buttonText: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  isEditForm: PropTypes.bool,
  handleNameChange: PropTypes.func,
  handleCreditsRequiredChange: PropTypes.func,
  handleAllCreditsRequiredChange: PropTypes.func,
  handleSpecialCollectionChange: PropTypes.func,
  onSubmit: PropTypes.func,
  formDispatch: PropTypes.func,
  onFormClose: PropTypes.func
};
CourseCollectionForm.defaultProps = {
  courseCollection: {},
  buttonText: 'Create',
  showValidationErrors: false,
  isEditForm: false,
  handleNameChange: undefined,
  handleCreditsRequiredChange: undefined,
  handleAllCreditsRequiredChange: undefined,
  handleSpecialCollectionChange: undefined,
  onSubmit: undefined,
  formDispatch: undefined,
  onFormClose: undefined
};

export default CourseCollectionForm;
