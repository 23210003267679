import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import ScoreAddAttempt from './ScoreAddAttempt';

import { partFacultySelector } from '../../../../redux/selectors/partsSelectors';
import {
  validateDecimalStringField,
  validateInputNumber,
  validateInputString,
  validateSelectDate,
  validateSelectField
} from '../../../../helpers/validation/validateGeneric';

import { userSelector } from '../../../../redux/selectors/usersSelectors';

import {
  doPostScore,
  doPostScoreReset
} from '../../../../redux/actions/scoreActions';
import fetchWithAuthorization from '../../../../helpers/fetch';

export default function ScoreAddAttemptAttemptContainer({
  assessmentUuid,
  partUuid,
  latestAttempt,
  studentUuid,
  openModal,
  handleScoreAddAttemptModalClose
}) {
  const dispatch = useDispatch();
  const student = useSelector(
    state => userSelector(state, studentUuid),
    shallowEqual
  );
  const createScore = useSelector(state => state.crudState.createScore);
  const createSuccess = useSelector(
    state => state.crudState.createScoreSuccess
  );

  const [attempt, setAttempt] = useState(0);
  const [date, setDate] = useState('');
  const [tieBackID, setTieBackID] = useState('');
  const [rubricItem, setRubricItem] = useState('');
  const [stepSubItem, setStepSubItem] = useState('');
  const [stepSubItemDescription, setStepSubItemDescription] = useState('');
  const [microcompetencyCode, setMicrocompetencyCode] = useState('');
  const [microcompetencyTitle, setMicrocompetencyTitle] =
    useState('Title Unknown');
  const [relValue, setRelValue] = useState('');
  const [potValue, setPotValue] = useState('');
  const [grader1Uuid, setGrader1Uuid] = useState(null);
  const [grader2Uuid, setGrader2Uuid] = useState(null);
  const [grader3Uuid, setGrader3Uuid] = useState(null);
  const [grader1ID, setGrader1ID] = useState(null);
  const [grader2ID, setGrader2ID] = useState(null);
  const [grader3ID, setGrader3ID] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [microcompetencies, setMicrocompetencies] = useState([]);
  const [microSelected, setMicroSelected] = useState({});
  const [displayMicros, setDisplayMicros] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const facultyOptions = useSelector(state =>
    partFacultySelector(state, partUuid)
  ).map(faculty => ({
    label: `${faculty.user.firstName} ${faculty.user.lastName}`,
    value: faculty.userUuid,
    institutionId: faculty.user.institutionId
  }));

  useEffect(() => {
    setAttempt(latestAttempt);
  }, [latestAttempt]);

  useEffect(() => {
    if (createSuccess) {
      handleScoreAddAttemptModalClose();
      dispatch(doPostScoreReset());
    }
  }, [dispatch, createSuccess, handleScoreAddAttemptModalClose]);

  useEffect(() => {
    if (openModal === false) {
      setDate('');
      setTieBackID('');
      setRubricItem('');
      setStepSubItemDescription('');
      setStepSubItem('');
      setMicrocompetencyCode('');
      setMicrocompetencyTitle('Title Unknown');
      setRelValue('');
      setPotValue('');
      setGrader1Uuid(null);
      setGrader2Uuid(null);
      setGrader3Uuid(null);
      setGrader1ID(null);
      setGrader2ID(null);
      setGrader3ID(null);
      setFetching(false);
      setMicrocompetencies([]);
      setMicroSelected({});
      setDisplayMicros(false);
      setHasErrors(false);
    }
  }, [openModal]);

  useEffect(() => {
    async function handleMicroDetails() {
      const results = await fetchWithAuthorization(
        `/api/microcompetency-code/${microcompetencyCode}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        }
      )
        .then(res => res)
        .catch(error => {
          throw error;
        });

      if (results.status === 200) {
        const converted = await results.json();

        if (converted.microcompetency !== null) {
          setMicrocompetencyTitle(converted.microcompetency.title);
        } else {
          setMicrocompetencyTitle('Title Unknown');
        }
      } else {
        setMicrocompetencyTitle('Title Unknown');
      }
    }

    if (microcompetencyCode && microcompetencyCode !== '') {
      handleMicroDetails();
    }
  }, [microcompetencyCode]);

  const handleAttempt = event => {
    const { value } = event.target;
    setAttempt(value);
  };

  const handleTieBack = event => {
    const { value } = event.target;
    setTieBackID(value);
  };

  const handleDate = (indentifier, field, date) => {
    if (date === null) {
      setDate('');
    } else {
      const convert = moment(date).format();

      setDate(convert);
    }
  };

  const handleStepSubItem = event => {
    const { value } = event.target;
    setStepSubItem(value);
  };

  const handleStepSubItemDescription = event => {
    const { value } = event.target;
    setStepSubItemDescription(value);
  };

  const handleRubricItem = event => {
    const { value } = event.target;
    setRubricItem(value);
  };

  const handleRelValue = event => {
    const { value } = event.target;
    setRelValue(value);
  };

  const handlePotValue = event => {
    const { value } = event.target;
    setPotValue(value);
  };

  const handleGrader1Change = event => {
    const { value } = event.target;
    const grader = facultyOptions.filter(faculty => faculty.value === value);
    const institutionId = _.get(grader, '0.institutionId');

    setGrader1ID(institutionId);
    setGrader1Uuid(value);
  };

  const handleGrader2Change = event => {
    const { value } = event.target;
    const grader = facultyOptions.filter(faculty => faculty.value === value);
    const institutionId = _.get(grader, '0.institutionId');

    setGrader2ID(institutionId);
    setGrader2Uuid(value);
  };

  const handleGrader3Change = event => {
    const { value } = event.target;
    const grader = facultyOptions.filter(faculty => faculty.value === value);
    const institutionId = _.get(grader, '0.institutionId');

    setGrader3ID(institutionId);
    setGrader3Uuid(value);
  };

  const handleRemoveMicroCode = () => {
    setMicrocompetencyCode('');
  };

  const handleSearch = async (field, input) => {
    setDisplayMicros(true);
    if (field === 'microcompetencyCode') {
      setFetching(true);
      const results = await fetchWithAuthorization(
        `/api/microcompetency-code/${input}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        }
      )
        .then(res => res)
        .catch(error => {
          throw error;
        });

      if (results.status === 200) {
        const converted = await results.json();

        if (converted.microcompetency !== null) {
          const results = [
            {
              uuid: converted.microcompetency.uuid,
              code: converted.code,
              title: converted.microcompetency.title
            }
          ];

          setMicrocompetencies(results);
          setFetching(false);
        } else {
          setMicrocompetencies([]);
          setFetching(false);
        }
      } else {
        setMicrocompetencies([]);
        setFetching(false);
      }
    }
  };

  const handleMicroSelected = micro => {
    setMicroSelected(micro);
  };

  const handleClearSearch = () => {
    setDisplayMicros(false);
    setMicrocompetencies([]);
  };

  const handleEstablishedMicro = () => {
    setDisplayMicros(false);
    setMicrocompetencyCode(microSelected.code);
    setMicrocompetencies([]);
  };

  const _validate = () => {
    let containErrors = false;

    const tieBackIDError = validateInputString(tieBackID);
    const dateError = validateSelectDate(date);

    const rubricItemError = validateInputString(rubricItem);
    const relValueError = validateDecimalStringField(relValue);
    const potValueError = validateDecimalStringField(potValue);
    const attemptError = validateInputNumber(attempt);
    const microcompetencyCodeError = validateInputString(microcompetencyCode);
    const grader1UuidError = validateSelectField(grader1Uuid);

    if (
      tieBackIDError.invalid ||
      dateError.invalid ||
      rubricItemError.invalid ||
      relValueError.invalid ||
      potValueError.invalid ||
      attemptError.invalid ||
      microcompetencyCodeError.invalid ||
      grader1UuidError.invalid
    ) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleCreateScore = () => {
    const rejectCreation = _validate();

    if (rejectCreation) {
      setHasErrors(true);
    } else {
      setHasErrors(false);

      const request = {
        assessmentUuid,
        studentUuid,
        studentID: student.institutionId,
        attempt,
        stepSubItem,
        stepSubItemDescription,
        tieBackID,
        date,
        rubricItem,
        relValue,
        potValue,
        microcompetencyCode,
        grader1Uuid,
        grader2Uuid,
        grader3Uuid,
        grader1ID,
        grader2ID,
        grader3ID
      };

      dispatch(doPostScore(request));
    }
  };

  return (
    <ScoreAddAttempt
      student={student}
      attempt={attempt}
      date={date}
      tieBackID={tieBackID}
      rubricItem={rubricItem}
      stepSubItemDescription={stepSubItemDescription}
      microcompetencyCode={microcompetencyCode}
      microcompetencies={microcompetencies}
      relValue={relValue}
      potValue={potValue}
      studentUuid={studentUuid}
      grader1Uuid={grader1Uuid}
      grader2Uuid={grader2Uuid}
      grader3Uuid={grader3Uuid}
      microSelected={microSelected}
      displayMicros={displayMicros}
      microcompetencyTitle={microcompetencyTitle}
      facultyOptions={facultyOptions}
      fetching={fetching}
      openModal={openModal}
      hasErrors={hasErrors}
      createScore={createScore}
      handleScoreAddAttemptModalClose={handleScoreAddAttemptModalClose}
      handleAttempt={handleAttempt}
      handleTieBack={handleTieBack}
      handleDate={handleDate}
      handleStepSubItem={handleStepSubItem}
      handleStepSubItemDescription={handleStepSubItemDescription}
      handleRubricItem={handleRubricItem}
      handleRelValue={handleRelValue}
      handlePotValue={handlePotValue}
      handleGrader1Change={handleGrader1Change}
      handleGrader2Change={handleGrader2Change}
      handleGrader3Change={handleGrader3Change}
      handleRemoveMicroCode={handleRemoveMicroCode}
      handleSearch={handleSearch}
      handleMicroSelected={handleMicroSelected}
      handleClearSearch={handleClearSearch}
      handleEstablishedMicro={handleEstablishedMicro}
      handleCreateScore={handleCreateScore}
    />
  );
}

ScoreAddAttemptAttemptContainer.propTypes = {
  assessmentUuid: PropTypes.string,
  latestAttempt: PropTypes.number,
  studentUuid: PropTypes.string,
  partUuid: PropTypes.string,
  openModal: PropTypes.bool,
  handleScoreAddAttemptModalClose: PropTypes.func
};

ScoreAddAttemptAttemptContainer.defaultProps = {
  assessmentUuid: '',
  latestAttempt: 0,
  studentUuid: '',
  partUuid: '',
  openModal: false,
  handleScoreAddAttemptModalClose: undefined
};
