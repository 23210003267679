const validateSyllabusSelectUserSingle = inputValue => {
  if (!inputValue || inputValue === '') {
    return {
      invalid: true,
      message: 'Please select a user'
    };
  }

  return false;
};

const validateSyllabusSelectProgramAssessmentMaster = inputValue => {
  if (!inputValue || inputValue === '') {
    return {
      invalid: true,
      message: 'Please select a program assessment'
    };
  }

  return false;
};

const validateSyllabusSelectMultiUserField = inputValue => {
  if (!inputValue || inputValue.length < 1) {
    return {
      invalid: true,
      message: 'Select required'
    };
  }

  return false;
};

export {
  validateSyllabusSelectUserSingle,
  validateSyllabusSelectProgramAssessmentMaster,
  validateSyllabusSelectMultiUserField
};
