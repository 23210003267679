export const GET_SCHOOLS = 'GET_SCHOOLS';
export const GET_SCHOOLS_SUCCESS = 'GET_SCHOOLS_SUCCESS';
export const GET_SCHOOLS_ERROR = 'GET_SCHOOLS_ERROR';

export const POST_SCHOOL = 'POST_SCHOOL';
export const POST_SCHOOL_SUCCESS = 'POST_SCHOOL_SUCCESS';
export const POST_SCHOOL_ERROR = 'POST_SCHOOL_ERROR';

export const GET_SCHOOL = 'GET_SCHOOL';
export const GET_SCHOOL_SUCCESS = 'GET_SCHOOL_SUCCESS';
export const GET_SCHOOL_ERROR = 'GET_SCHOOL_ERROR';

export const PUT_SCHOOL = 'PUT_SCHOOL';
export const PUT_SCHOOL_SUCCESS = 'PUT_SCHOOL_SUCCESS';
export const PUT_SCHOOL_ERROR = 'PUT_SCHOOL_ERROR';

export const DELETE_SCHOOL = 'DELETE_SCHOOL';
export const DELETE_SCHOOL_SUCCESS = 'DELETE_SCHOOL_SUCCESS';
export const DELETE_SCHOOL_ERROR = 'DELETE_SCHOOL_ERROR';

export const PATCH_SCHOOL_REVUS = 'PATCH_SCHOOL_REVUS';
export const PATCH_SCHOOL_REVUS_SUCCESS = 'PATCH_SCHOOL_REVUS_SUCCESS';
export const PATCH_SCHOOL_REVUS_ERROR = 'PATCH_SCHOOL_REVUS_ERROR';

export const PATCH_SCHOOL_DENOMINATORS = 'PATCH_SCHOOL_DENOMINATORS';
export const PATCH_SCHOOL_DENOMINATORS_SUCCESS = 'PATCH_SCHOOL_DENOMINATORS_SUCCESS';
export const PATCH_SCHOOL_DENOMINATORS_ERROR = 'PATCH_SCHOOL_DENOMINATORS_ERROR';
