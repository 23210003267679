import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { isNumeric } from 'validator';
import { useParams } from 'react-router-dom';

import GradeCollectionsForm from './GradeCollectionsForm';
import {
  partGradeCollectionsSelector,
  partGradeSettingsSelector
} from '../../../../redux/selectors/partGradeSettingsSelectors';
import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';

import { doClearCreated } from '../../../../redux/actions/courseCollectionActions';
import {
  gradeCollectionsReducer,
  initialGradeCollectionState
} from './gradeCollectionsState';
import {
  allFieldsAreValid,
  validateStringLength
} from '../../../../helpers/validation/validateGeneric';
import { doPutPartGradeCollections } from '../../../../redux/actions/partGradeSettingsActions';

const GradeCollectionsFormContainer = ({
  partGradeSettingsUuid,
  unsavedChanges,
  setUnsavedChange,
  removeUnsavedChange
}) => {
  const dispatch = useDispatch();
  const { partUuid } = useParams();

  const [state, gradeCollectionsDispatch] = useReducer(
    gradeCollectionsReducer,
    initialGradeCollectionState
  );

  const partGradeSettings = useSelector(
    state => partGradeSettingsSelector(state, partUuid),
    _.isEqual
  );

  const existingGradeCollections = useSelector(
    state => partGradeCollectionsSelector(state, partGradeSettingsUuid),
    _.isEqual
  );

  const successfullyUpdatedPartGradeCollections = useSelector(
    state => state.formSuccessState.successfullyUpdatedPartGradeCollections
  );

  const onGeneralErrorNotification = errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage));

  const setExistingState = (gradeCollections, distributeWeightEqually) => {
    gradeCollectionsDispatch({
      type: 'setExistingCollections',
      payload: {
        gradeCollections,
        distributeWeightEqually
      }
    });
  };

  const {
    gradeCollections,
    collectionsToDelete,
    showValidationErrors,
    hasSetCollections,
    distributeWeightEqually
  } = state;

  useEffect(() => {
    if (existingGradeCollections && !hasSetCollections && partGradeSettings) {
      setExistingState(
        existingGradeCollections,
        partGradeSettings.distributeWeightEqually
      );
    }
  }, [
    existingGradeCollections,
    hasSetCollections,
    successfullyUpdatedPartGradeCollections,
    partGradeSettings
  ]);

  useEffect(() => {
    if (successfullyUpdatedPartGradeCollections) {
      dispatch(doClearCreated());
      setExistingState(
        existingGradeCollections,
        partGradeSettings.distributeWeightEqually
      );
      removeUnsavedChange('gradeCollections');
    }
  }, [
    dispatch,
    removeUnsavedChange,
    successfullyUpdatedPartGradeCollections,
    existingGradeCollections,
    partGradeSettings
  ]);

  const handleDistributeWeightEquallyChange = (e, distributeWeightEqually) => {
    setUnsavedChange('gradeCollections');
    gradeCollectionsDispatch({
      type: 'setDistributeWeightEqually',
      payload: {
        distributeWeightEqually
      }
    });
  };

  const onCollectionTitleChange = (event, uuid) => {
    gradeCollectionsDispatch({
      type: 'setGradeCollectionTitle',
      payload: {
        uuid,
        title: event.target.value
      }
    });
    setUnsavedChange('gradeCollections');
  };

  const onCollectionWeightChange = (event, uuid) => {
    gradeCollectionsDispatch({
      type: 'setGradeCollectionWeight',
      payload: {
        uuid,
        weight: event.target.value
      }
    });
    setUnsavedChange('gradeCollections');
  };

  const onAddCollection = () => {
    gradeCollectionsDispatch({
      type: 'addGradeCollection'
    });
    setUnsavedChange('gradeCollections');
  };

  const onRemoveCollection = uuid => {
    gradeCollectionsDispatch({
      type: 'removeGradeCollection',
      payload: {
        uuid
      }
    });
    setUnsavedChange('gradeCollections');
  };

  const setValidationErrors = (gradeCollections, distributeWeightEqually) => {
    const gradeCollectionKeys = gradeCollections
      ? Object.keys(gradeCollections)
      : false;

    let noCollectionsError = false;
    let nameError = false;
    let weightError = false;

    if (gradeCollectionKeys && gradeCollectionKeys.length > 0) {
      gradeCollectionKeys.forEach(collectionUuid => {
        const thisCollection = gradeCollections[collectionUuid];
        const thisCollectionWeight = Number(thisCollection.weight);
        const isNameError = validateStringLength(
          thisCollection.title,
          100,
          true
        );
        if (
          thisCollection.title.toLowerCase() === 'untitled' ||
          isNameError.invalid
        ) {
          nameError = { invalid: true };
        }

        if (
          !distributeWeightEqually &&
          (!thisCollectionWeight ||
            !isNumeric(thisCollection.weight.toString()) ||
            thisCollectionWeight > 100 ||
            thisCollectionWeight < 1)
        ) {
          weightError = { invalid: true };
        }
      });
    } else {
      noCollectionsError = { invalid: true };
      onGeneralErrorNotification('There must be at least one grade collection');
    }

    const newValidationErrors = {
      noCollectionsError,
      nameError,
      weightError
    };

    return newValidationErrors;
  };

  const onPutUpdate = (
    partGradeSettingsUuid,
    grade_collections,
    collectionsToDelete,
    distributeWeightEqually
  ) =>
    dispatch(
      doPutPartGradeCollections(
        partGradeSettingsUuid,
        grade_collections,
        collectionsToDelete,
        distributeWeightEqually
      )
    );

  const handleUpdateSubmission = () => {
    const newValidationErrors = setValidationErrors(
      gradeCollections,
      distributeWeightEqually
    );

    if (allFieldsAreValid(newValidationErrors)) {
      const grade_collections = [];
      Object.keys(gradeCollections).forEach(collectionUuid => {
        const thisCollection = gradeCollections[collectionUuid];
        const collectionObj = thisCollection.isNew
          ? {
              title: thisCollection.title,
              weight: Number(thisCollection.weight)
            }
          : {
              uuid: thisCollection.uuid,
              title: thisCollection.title,
              weight: Number(thisCollection.weight)
            };
        grade_collections.push(collectionObj);
      });

      onPutUpdate(
        partGradeSettingsUuid,
        grade_collections,
        collectionsToDelete,
        distributeWeightEqually
      );

      gradeCollectionsDispatch({
        type: 'setValidationErrors',
        payload: {
          showValidationErrors: false
        }
      });
    } else {
      gradeCollectionsDispatch({
        type: 'setValidationErrors',
        payload: {
          showValidationErrors: newValidationErrors
        }
      });
    }
  };

  const handleDiscardChanges = () => {
    setExistingState(
      existingGradeCollections,
      partGradeSettings.distributeWeightEqually
    );
    removeUnsavedChange('gradeCollections');
  };

  return (
    <GradeCollectionsForm
      partGradeSettingsUuid={partGradeSettingsUuid}
      distributeWeightEqually={distributeWeightEqually}
      gradeCollections={gradeCollections}
      showValidationErrors={showValidationErrors}
      onCollectionTitleChange={onCollectionTitleChange}
      onCollectionWeightChange={onCollectionWeightChange}
      onAddCollection={onAddCollection}
      onRemoveCollection={onRemoveCollection}
      handleCollectionsSubmit={handleUpdateSubmission}
      handleDiscardChanges={handleDiscardChanges}
      handleDistributeWeightEquallyChange={handleDistributeWeightEquallyChange}
      unsavedChanges={unsavedChanges}
    />
  );
};

GradeCollectionsFormContainer.propTypes = {
  partGradeSettingsUuid: PropTypes.string,
  unsavedChanges: PropTypes.object,
  setUnsavedChange: PropTypes.func,
  removeUnsavedChange: PropTypes.func
};

GradeCollectionsFormContainer.defaultProps = {
  partGradeSettingsUuid: '',
  unsavedChanges: {},
  setUnsavedChange: undefined,
  removeUnsavedChange: undefined
};

export default GradeCollectionsFormContainer;
