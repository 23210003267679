import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Decimal from 'decimal.js-light';
import { getDroppedAssessmentUuids } from '../../../../../helpers/utilities';
import { PointsTd, TableDataText } from '../CollectionReportSC';
import StudentAssessmentGradeData from './StudentAssessmentGradeData';
import {
  collectionGradeResultSelector,
  resultCardsByCollectionSelector
} from '../../../../../redux/selectors/gradeResultSelectors';

const StudentCollectionGradeData = ({
  assessmentCollection,
  evenStudent,
  failThreshold,
  isExpanded,
  studentUuid
}) => {
  const collectionGradeResult = useSelector(state =>
    collectionGradeResultSelector(state, assessmentCollection.uuid, studentUuid)
  );
  const pointsOnlyCollection = assessmentCollection.scoreType !== 'SCORE';

  const resultCards = useSelector(state =>
    resultCardsByCollectionSelector(
      state,
      assessmentCollection.uuid,
      studentUuid
    )
  );

  const droppedAssessmentUuids = getDroppedAssessmentUuids(
    assessmentCollection,
    resultCards,
    pointsOnlyCollection
  );

  const collectionCumulativeScore = pointsOnlyCollection
    ? resultCards
        .reduce((curTotal, resultCard) => {
          return curTotal.plus(resultCard?.assessmentRelativeScore || 0);
        }, new Decimal(0))

        .toDecimalPlaces(2)
        .toString()
    : null;

  return (
    <>
      <PointsTd
        key={assessmentCollection.uuid}
        blueOne={evenStudent}
        blueTwo={!evenStudent}
      >
        <TableDataText>{collectionGradeResult?.calculatedGrade}</TableDataText>
      </PointsTd>
      <PointsTd
        blueOne={evenStudent}
        blueTwo={!evenStudent}
        displayNone={!isExpanded || !pointsOnlyCollection}
      >
        <TableDataText>{collectionCumulativeScore}</TableDataText>
      </PointsTd>
      {assessmentCollection.collection_assessments.length > 0 &&
        assessmentCollection.collection_assessments.map(
          collectionAssessment => {
            return (
              <StudentAssessmentGradeData
                key={collectionAssessment.assessmentUuid}
                collectionAssessment={collectionAssessment}
                isExpanded={isExpanded}
                studentUuid={studentUuid}
                evenStudent={evenStudent}
                dropAssessment={droppedAssessmentUuids.has(
                  collectionAssessment.assessmentUuid
                )}
                failThreshold={failThreshold}
                pointsOnlyCollection={Boolean(pointsOnlyCollection)}
              />
            );
          }
        )}
    </>
  );
};

StudentCollectionGradeData.propTypes = {
  assessmentCollection: PropTypes.object,
  evenStudent: PropTypes.bool.isRequired,
  failThreshold: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  studentUuid: PropTypes.string.isRequired
};

StudentCollectionGradeData.defaultProps = {
  assessmentCollection: { collection_assessments: [] },
  failThreshold: ''
};

export default StudentCollectionGradeData;
