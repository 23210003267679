import { Model, attr, fk } from 'redux-orm';
import {
  GET_COURSES_SUCCESS,
  GET_COURSE_BY_STUDENT_SUCCESS,
  GET_COURSE_SUCCESS,
  GET_GRADE_COLLECTION_SUCCESS,
  GET_PART_GRADE_SETTINGS_SUCCESS,
  GET_PART_SUCCESS,
  GET_SCORES_SUCCESS,
  GET_SECTION_NESTED_BY_COURSE_SUCCESS,
  GET_SECTION_SUCCESS,
  GET_SYLLABUS_COURSE_SUCCESS,
  GET_SYLLABUS_PART_SUCCESS,
  GET_SYLLABUS_SUCCESS,
  PATCH_PART_CALCULATE_GRADE_ERROR,
  PATCH_PART_CALCULATE_GRADE_SUCCESS,
  PATCH_PART_LOCKED_SUCCESS,
  PATCH_PART_STATUS_SUCCESS,
  PATCH_PART_UNDO_CALCULATED_GRADE_SUCCESS,
  POST_ASSESSMENT_SUCCESS,
  POST_COURSE_SUCCESS,
  PUT_ASSESSMENT_DRAFT_SUCCESS,
  PUT_ASSESSMENT_FINAL_SUCCESS,
  PUT_COURSE_SUCCESS,
  PUT_PART_GRADE_COLLECTIONS_SUCCESS,
  PUT_PART_GRADE_DATA_SOURCE_SUCCESS,
  PUT_PART_STATUS_COMMIT_SUCCESS,
  PUT_SECTION_PART_WEIGHTS_SUCCESS
} from '../actions-type';

class Part extends Model {
  static reducer(action, Part, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_PART_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case GET_SECTION_SUCCESS: {
        const { parts } = payload.section;

        parts.forEach(part => Part.upsert(part));
        break;
      }
      case PUT_SECTION_PART_WEIGHTS_SUCCESS: {
        const { parts } = payload.section;
        parts.forEach(part => Part.upsert(part));
        break;
      }
      case GET_PART_GRADE_SETTINGS_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case GET_GRADE_COLLECTION_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case GET_COURSES_SUCCESS: {
        const courses = [...payload.courses];
        courses.forEach(course =>
          course.sections.forEach(section =>
            section.parts.forEach(part => Part.upsert(part))
          )
        );
        break;
      }
      case POST_COURSE_SUCCESS: {
        const course = { ...payload.course };
        course.sections.forEach(section =>
          section.parts.forEach(part => Part.upsert(part))
        );
        break;
      }
      case GET_COURSE_SUCCESS: {
        const course = { ...payload.course };
        course.sections.forEach(section =>
          section.parts.forEach(part => Part.upsert(part))
        );
        break;
      }
      case PUT_COURSE_SUCCESS: {
        const course = { ...payload.course };
        course.sections.forEach(section => {
          const partsToDelete = Part.all().filter(
            part => part.sectionUuid === section.uuid
          );

          partsToDelete.delete();
          section.parts.forEach(part => Part.upsert(part));
        });
        break;
      }
      case GET_SYLLABUS_SUCCESS: {
        const parts = [...payload.parts];
        parts.forEach(part => Part.upsert(part));
        break;
      }
      case GET_SECTION_NESTED_BY_COURSE_SUCCESS: {
        const parts = [...payload.parts];
        parts.forEach(part => Part.upsert(part));

        break;
      }
      case PATCH_PART_STATUS_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case POST_ASSESSMENT_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case PUT_ASSESSMENT_DRAFT_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case PUT_ASSESSMENT_FINAL_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case GET_SCORES_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case PATCH_PART_LOCKED_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case PATCH_PART_CALCULATE_GRADE_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case PATCH_PART_CALCULATE_GRADE_ERROR: {
        const { partUuid } = payload;
        Part.withId(partUuid).update({ statusGrade: 'NOT_GRADED' });
        break;
      }
      case PATCH_PART_UNDO_CALCULATED_GRADE_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case PUT_PART_GRADE_DATA_SOURCE_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case PUT_PART_GRADE_COLLECTIONS_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case PUT_PART_STATUS_COMMIT_SUCCESS: {
        const { part } = payload;
        Part.upsert(part);
        break;
      }
      case GET_COURSE_BY_STUDENT_SUCCESS: {
        const courses = [...payload.courses];

        if (courses?.length > 0) {
          courses.forEach(course => {
            const { sections } = course;

            if (sections?.length > 0) {
              sections.forEach(section => {
                const { parts } = section;

                if (parts?.length > 0) {
                  parts.forEach(part => {
                    Part.upsert(part);
                  });
                }
              });
            }
          });
        }

        break;
      }
      case GET_SYLLABUS_COURSE_SUCCESS: {
        const course = { ...payload.course };
        const { sections } = course;

        if (sections?.length > 0) {
          sections.forEach(section => {
            const { parts } = section;

            if (parts?.length > 0) {
              parts.forEach(part => {
                Part.upsert(part);
              });
            }
          });
        }

        break;
      }
      case GET_SYLLABUS_PART_SUCCESS: {
        const part = { ...payload.part };

        Part.upsert(part);

        break;
      }
      default:
        return session;
    }
  }
}

Part.fields = {
  uuid: attr(),
  title: attr(),
  status: attr(),
  locked: attr(),
  directors: attr(),
  faculty: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  sectionUuid: fk('Section', 'parts')
};

Part.modelName = 'Part';

Part.options = {
  idAttribute: 'uuid'
};

export default Part;
