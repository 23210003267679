import React from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import {
  Body1,
  ButtonInline,
  ButtonPrimary,
  ButtonIcon,
  Subtitle2
} from '@xcomp/xcomp-design-library';
import withValidation from './FieldWithError/FieldWithError';

const ModalHeader = styled.div`
  width: 100%;
  height: 66px;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Column = styled.div`
  width: ${props => props.size};
  font-size: inherit;
  padding-right: ${props => props.paddingRight || 'unset'};
  padding-left: ${props => props.paddingLeft || 'unset'};
  color: ${props => props.theme.colors.grey[600] || 'inherit'};
  
  &.italic {
    font-style: italic;
  }
`;

const ElevatedForm = styled.div`
  padding: 1.5rem 1rem 1rem 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  box-shadow: ${props => props.theme.boxShadows.high};
  border-left: 6px solid ${props => props.theme.colors.primary};
  border-radius: 3px;
  z-index: 1000;
`;

const SubmitButton = styled(ButtonInline)`
  && {
    margin-left: 1rem;
  }
`;

const CancelButton = styled(ButtonInline)`
  && {
    color: ${props => props.theme.colors.grey[600]};
  }
`;

const TextFieldSC = styled(({ paddingRight, ...rest }) => (
  <TextField {...rest} />
))`
  && {
    width: 100%;
    margin: auto;
    padding-right: ${props =>
      props.paddingRight ? props.paddingRight : 'initial'};

    input {
      font-weight: 400;
    }
    & > div:before {
      border-bottom: 0;
    }
  }
`;

const TextFieldWithError = withValidation(TextFieldSC);

const FormTop = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding-top: 6px;

  margin-bottom: 1.5rem;
  &.has_code_field {
    margin-bottom: 0;
  }
  &.edit {
    padding-right: 2rem;
  }
`;

const FormBottom = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const SubmitButtonSC = styled(ButtonPrimary)`
  && {
    margin-top: 1rem;
  }
`;

const ButtonIconSC = styled(ButtonIcon)`
  && {
    margin-left: 0.375rem;
    font-size: 1.25rem;
    line-height: 0.7;
    &.delete {
      svg {
        font-size: 1.125rem;
      }
    }
  }
`;

const IconsContainer = styled.div`
  && {
    position: absolute;
    right: 0.5rem;
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0.5rem 0;
  }
`;

const FormSectionHeaderSubtitle = styled(Subtitle2)`
  && {
    color: ${props => props.theme.fontColors.darker};
    position: relative;
    display: inline-block;
    z-index: 100;
    background: ${props => props.theme.colors.white};
    padding-right: 0.5rem;
  }
`;

const FormSectionHeader = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }
`;

const InfoText = styled(
  ({
    justifyContent,
    alignItems,
    padding,
    margin,
    error,
    fontWeight,
    whiteSpace,
    ...rest
  }) => <Body1 {...rest} />
)`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : '0')};
  margin: ${props => (props.margin ? props.margin : '0')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '500')};
  color: ${props =>
    props.error ? props.theme.colors.status.error : props.theme.fontColors.darker};
  display: flex;
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  white-space: ${props => (props.whiteSpace ? props.whiteSpace : 'nowrap')};
  svg {
    color: ${props => (props.error ? props.theme.colors.status.error : 'inherit')};
    font-size: 22px;
    margin-right: 8px;
  }
`;

export {
  ButtonIconSC,
  CancelButton,
  Column,
  ElevatedForm,
  FormBottom,
  FormSectionHeader,
  FormSectionHeaderSubtitle,
  FormTop,
  IconsContainer,
  InfoText,
  ModalHeader,
  SubmitButton,
  SubmitButtonSC,
  TextFieldSC,
  TextFieldWithError
};
