import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { selectGradeResultPart } from '../../../../redux/selectors/calculatedGradeResultSelectors';

export default function GradeResultPartScore({ userUuid, partUuid }) {
  const result = useSelector(
    state => selectGradeResultPart(state, userUuid, partUuid),
    _.isEqual
  );

  const relativeScore = _.get(result, 'relativeScore');

  return <>{relativeScore || '- -'}</>;
}

GradeResultPartScore.propTypes = {
  userUuid: PropTypes.string,
  partUuid: PropTypes.string
};

GradeResultPartScore.defaultProps = {
  userUuid: undefined,
  partUuid: undefined
};
