import fetch from '../helpers/fetch';

const getAcademicYear = academicYearUuid =>
  fetch(`/api/academic-year/${academicYearUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getAcademicYearsBySchool = schoolUuid =>
  fetch(`/api/academic-years/find-by-school?schoolUuid=${schoolUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postAcademicYear = academicYear =>
  fetch(`/api/academic-year/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(academicYear)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putAcademicYear = academicYear =>
  fetch(`/api/academic-year/${academicYear.uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(academicYear)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteAcademicYear = academicYearUuid =>
  fetch(`/api/academic-year/${academicYearUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  getAcademicYear,
  getAcademicYearsBySchool,
  postAcademicYear,
  putAcademicYear,
  deleteAcademicYear
};
