import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

export const AssessmentHeaderContainer = styled('div')`
  height: 48px;
  width: 100%;
  display: flex;
  padding-right: ${props => props.paddingRight};
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: flex-start;
`;

const AssessmentHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-left: 80px;
  width: calc(100% - 80px);
`;

const AssessmentHeaderInfo = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
`;

const TitleTooltip = styled(Tooltip)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AssessmentHeaderData = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  font-size: 14px;
  font-weight: 500;
  min-width: 400px;
`;

const AssessmentHeaderSub = styled('div')`
  min-width: ${props => props.width};
  margin-right: ${props => props.marginRight};
  color: ${props => props.theme.fontColors.darker};
  text-align: right;
`;

const PartOpportunityReportOpportunityDetail = ({
  opportunityNumber,
  opportunityRelativeScore,
  resultCardUuid
}) => {
  return (
    <AssessmentHeaderContainer paddingRight="44px">
      <AssessmentHeader>
        <AssessmentHeaderInfo>
          <TitleTooltip
            title={`Opportunity ${opportunityNumber} — ${resultCardUuid}`}
            placement="top-start"
          >
            <div>{`Opportunity ${opportunityNumber} — ${resultCardUuid}`}</div>
          </TitleTooltip>
        </AssessmentHeaderInfo>
        <AssessmentHeaderData>
          <AssessmentHeaderSub marginRight="16px" width="140px" />
          <AssessmentHeaderSub marginRight="16px" width="100px">
            {opportunityRelativeScore}
          </AssessmentHeaderSub>
          <AssessmentHeaderSub marginRight="8px" width="120px" />
        </AssessmentHeaderData>
      </AssessmentHeader>
    </AssessmentHeaderContainer>
  );
};

PartOpportunityReportOpportunityDetail.propTypes = {
  opportunityNumber: PropTypes.number,
  opportunityRelativeScore: PropTypes.string,
  resultCardUuid: PropTypes.string
};

PartOpportunityReportOpportunityDetail.defaultProps = {
  opportunityNumber: undefined,
  opportunityRelativeScore: '',
  resultCardUuid: ''
};

export default PartOpportunityReportOpportunityDetail;
