import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

const TextFieldSC = styled(TextField)`
  width: 100%;
`;

export default function FormField({
  dataTestId,
  name,
  label,
  value,
  onBlur,
  onChange,
  handleValidate,
  hasErrors,
  ...rest
}) {
  const [text, setText] = useState('');

  const fieldIsInvalid = handleValidate
    ? handleValidate(text)
    : { invalid: false };

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleChange = event => {
    setText(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <TextFieldSC
      data-testid={dataTestId}
      name={name}
      label={label}
      value={text}
      variant="outlined"
      onChange={handleChange}
      onBlur={onBlur}
      error={hasErrors && fieldIsInvalid.invalid}
      helperText={hasErrors && fieldIsInvalid.message}
      margin="normal"
      InputLabelProps={{ shrink: true }}
      {...rest}
    />
  );
}

FormField.propTypes = {
  dataTestId: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleValidate: PropTypes.func,
  hasErrors: PropTypes.bool
};

FormField.defaultProps = {
  dataTestId: '',
  label: '',
  name: '',
  onBlur: undefined,
  onChange: undefined,
  value: '',
  handleValidate: undefined,
  hasErrors: false
};
