import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const assessmentRankSelector = createSelector(
  state => state,
  (_, assessmentUuid, studentUuid) => ({
    assessmentUuid,
    studentUuid
  }),
  (redux, data) => {
    const { assessmentUuid, studentUuid } = data;

    const assessmentRankORM = ormCreateSelector(orm.AssessmentRank);

    const assessmentRank = assessmentRankORM(redux).find(
      ar =>
        ar.assessmentUuid === assessmentUuid && ar.studentUuid === studentUuid
    );

    return assessmentRank;
  }
);
