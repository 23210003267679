import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

const TextFieldSC = styled(TextField)`
  && {
    width: 100%;
  }
`;

const SearchIconSC = styled(SearchIcon)`
  color: rgba(0, 0, 0, 0.23);
`;

const Search = ({ ...rest }) => (
  <TextFieldSC
    {...rest}
    variant="outlined"
    label="Search Filter"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton>
            <SearchIconSC />
          </IconButton>
        </InputAdornment>
      )
    }}
  />
);

Search.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

Search.defaultProps = {
  children: <div />,
  className: ''
};

export default Search;
