import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox
} from '@material-ui/core';

import {
  Column,
  TextFieldWithError,
  CancelButton,
  SubmitButton
} from '../../../../Library/FormComponents';
import { validateStringLength } from '../../../../../helpers/validation/validateGeneric';

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const CheckboxContainer = styled.div`
  color: ${props => props.theme.fontColors.dark};
  margin-bottom: 0.5rem;
`;

const ButtonsColumn = styled(Column)`
  && {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
`;

const CompetencyCollectionFormDetails = ({
  uuid,
  name,
  is_default_collection,
  buttonText,
  showValidationErrors,
  unsavedChanges,
  handleNameChange,
  handleDefaultCollectionChange,
  onSubmit,
  onFormClose,
  isNewForm
}) => {
  const validateNameField = value => validateStringLength(value, 100, true);
  const changeDefaultCollection = () =>
    handleDefaultCollectionChange(!is_default_collection);
  const formHasChanges = isNewForm ? true : Boolean(unsavedChanges[uuid]);
  return (
    <Layout>
      <Column size="100%">
        <TextFieldWithError
          autoFocus
          id="competencyCollectionName"
          name="name"
          value={name}
          onChange={handleNameChange}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Collection Name"
          placeholder=""
          variant="outlined"
          handleValidate={validateNameField}
          hasErrors={showValidationErrors}
        />
        <FormControl component="fieldset">
          <FormGroup>
            <CheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    id="is_default_collection"
                    onChange={changeDefaultCollection}
                    checked={is_default_collection}
                    color="primary"
                  />
                }
                label="Set as Default Collection"
              />
            </CheckboxContainer>
          </FormGroup>
        </FormControl>
      </Column>
      <ButtonsColumn size="100%">
        <CancelButton onClick={onFormClose} noHoverBg>
          Cancel
        </CancelButton>
        <SubmitButton onClick={onSubmit} disabled={!formHasChanges}>
          {buttonText}
        </SubmitButton>
      </ButtonsColumn>
    </Layout>
  );
};

CompetencyCollectionFormDetails.propTypes = {
  name: PropTypes.string,
  uuid: PropTypes.string,
  is_default_collection: PropTypes.bool,
  isNewForm: PropTypes.bool,
  buttonText: PropTypes.string,
  unsavedChanges: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  onSubmit: PropTypes.func,
  onFormClose: PropTypes.func,
  handleNameChange: PropTypes.func,
  handleDefaultCollectionChange: PropTypes.func
};
CompetencyCollectionFormDetails.defaultProps = {
  name: '',
  uuid: '',
  is_default_collection: false,
  isNewForm: false,
  buttonText: 'Create',
  unsavedChanges: {},
  showValidationErrors: false,
  onSubmit: undefined,
  onFormClose: undefined,
  handleNameChange: undefined,
  handleDefaultCollectionChange: undefined
};

export default CompetencyCollectionFormDetails;
