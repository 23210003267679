import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import SchoolIcon from '@material-ui/icons/School';
import LinkIcon from '@material-ui/icons/Link';
import ShareIcon from '@material-ui/icons/Share';

import { Panel } from '../../../../Library/DashboardSC';
import PageIllustration from '../../../../Library/PageIllustration';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';

const PanelSC = styled(Panel)`
  margin-top: 24px;
  width: 100% !important;
  max-width: unset !important;
`;

const ProgressBar = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.fontColors.dark};
`;

const NoDataContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.fontColors.dark};
`;

const AssessmentHeaderContainer = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  height: 48px;
  width: 100%;
  display: flex;
  padding-right: ${props => props.paddingRight};
  padding-left: ${props => props.$paddingLeft};
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: flex-start;
  border-bottom: ${props => `solid 1px  ${props.theme.colors.grey[300]}`};
  font-size: 15px;
  font-weight: ${props => props.$isLeaf ? 400 : 450};
`;

const AssessmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  ${props => !props.$noMargin && 'margin-left: 66px;'};
  ${props => props.$noMargin ? 'width: 100%;' : 'width: calc(100% - 66px);'};
`;

const AssessmentHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  overflow: hidden;
  align-items: center;
`;

const AssessmentHeaderData = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
`;

const AssessmentHeaderSub = styled(({ marginRight, ...rest }) => (
  <div {...rest} />
))`
  min-width: 100px;
  margin-right: ${props => props.marginRight};
  color: ${props => props.theme.fontColors.darker};
  text-align: right;
`;

const TitleTooltip = styled(({ ...rest }) => <Tooltip {...rest} />)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${props => props.$remainigWidth && 'width: calc(100% - 80px);'}
  ${props => props.$isEncounterType && `
    color: ${props.theme.colors.primary};
  `}
`;

const Stars = styled.div`
  color: ${props => props.theme.colors.orange[500]};
  display: flex;
  align-items: center;
  width: 80px;
`;

const PartFacultyReportPanelAccordion = styled(
  ({ withBottomBorder, withTopBorder, criticalFailure, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label,
  .column_two_label,
  .column_three_label {
    flex-grow: 0;
    width: 130px;
    text-align: right;
    min-width: 100px !important;
  }

  .accordion_row_label div {
    font-size: 16px;
    letter-spacing: 0.15;
    color: ${props => props.theme.fontColors.darker};
  }

  .column_two_label,
  .column_three_label {
    margin-right: 8px;
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props =>
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.theme.fontColors.darker};
    }
  }

  .column_one_label {
    margin-right: 30px;
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props =>
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.theme.fontColors.darker};
    }
  }

  .hover-effect {
    padding-right: 44px;
  }
  width: 100%;
`;

const getTypeIcon = (type) => {
  if (type === 'Didactic') {
    return <SchoolIcon />;
  } else if (type === 'Shared') {
    return <ShareIcon />;
  }
  return <LinkIcon />;
}

const InstructionReport = ({
  error, 
  data, 
  isMutating
}) => {
  return (
    <>
      {isMutating ? (
        <PanelSC>
          <ProgressBar>Loading</ProgressBar>
          <LinearProgress />
        </PanelSC>
      ) : (
        <PanelSC>
          {!data && !error && (
            <NoDataContainer>
              Apply the filter selection to view the report.
            </NoDataContainer>
          )}
          {data && data.encounterRecords.length === 0 && (
            <NoDataContainer>
              No data for the selected filters. Change filter selection and
              re-apply.
            </NoDataContainer>
          )}
          {error &&           
            <PageIllustration 
              infoText="An error occured while trying to fetch the data. Please try again."
            />
          }
          {data && data.encounterRecords.length > 0 && (
            <>
              <AssessmentHeaderContainer paddingRight="44px">
                <AssessmentHeader>
                  <AssessmentHeaderInfo>
                    Faculty (Last Name, First Name)
                  </AssessmentHeaderInfo>
                  <AssessmentHeaderData>
                    <AssessmentHeaderSub marginRight="16px">
                      Participation (%)
                    </AssessmentHeaderSub>
                    <AssessmentHeaderSub marginRight="16px">
                      Total Relative (RVUs)
                    </AssessmentHeaderSub>
                    <AssessmentHeaderSub marginRight="8px">
                      Total Potential (h)
                    </AssessmentHeaderSub>
                  </AssessmentHeaderData>
                </AssessmentHeader>
              </AssessmentHeaderContainer>
              {data.encounterRecords.map((f) => 
                <PartFacultyReportPanelAccordion
                  key={f.fullName}
                  withBottomBorder
                  expandWidth="66px"
                  height="50px"
                  label={f.fullName}
                  columnOne={f.total.percentage}
                  columnTwo={f.total.relativeRVUs.toFixed(2)}
                  columnThree={f.total.potentialRVUs.toFixed(2)}
                  columnOneClassName="column_one_label"
                  columnTwoClassName="column_two_label"
                  columnThreeClassName="column_three_label"
                >
                  <>
                    <AssessmentHeaderContainer paddingRight="44px" $paddingLeft="30px">
                      <AssessmentHeader>
                        <AssessmentHeaderInfo>Encounter</AssessmentHeaderInfo>
                        <AssessmentHeaderData>
                          <AssessmentHeaderSub marginRight="16px">
                            Participation (%)
                          </AssessmentHeaderSub>
                          <AssessmentHeaderSub marginRight="16px">
                            Total Relative (RVUs)
                          </AssessmentHeaderSub>
                          <AssessmentHeaderSub marginRight="8px">
                            Total Potential (h)
                          </AssessmentHeaderSub>
                        </AssessmentHeaderData>
                      </AssessmentHeader>
                    </AssessmentHeaderContainer>
                    {f.encounters.map(e => (
                      <AssessmentHeaderContainer key={`${f.fullName}-${e.encounterLabel}`} paddingRight="44px" $paddingLeft="16px" $isLeaf>
                        <AssessmentHeader $noMargin>
                          <AssessmentHeaderInfo>                           
                            <Stars>
                              {e.isLeadFaculty
                                ? 
                                  <TitleTooltip title='Faculty Lead' placement="top-start">
                                    <StarIcon />
                                  </TitleTooltip>
                                :
                                  <div style={{width: 24}} />
                              }
                              {e.isParticipatingFaculty 
                                ? 
                                  <TitleTooltip title='Participating Faculty' placement="top-start">
                                    <StarBorderIcon />
                                  </TitleTooltip>
                                :
                                  <div style={{width: 24}} />
                              }
                              <TitleTooltip title={e.type} placement="top-start" $isEncounterType={true}>
                                {getTypeIcon(e.type)}
                              </TitleTooltip>

                            </Stars>
                            <TitleTooltip $remainigWidth title={e.encounterLabel} placement="top-start">
                              <div>
                                {e.encounterLabel}
                              </div>
                            </TitleTooltip>
                          </AssessmentHeaderInfo>
                          <AssessmentHeaderData>
                            <AssessmentHeaderSub marginRight="60px">
                              {e.percentage}
                            </AssessmentHeaderSub>
                            <AssessmentHeaderSub marginRight="38px">
                              {e.relativeRVUs.toFixed(2)}
                            </AssessmentHeaderSub>
                            <AssessmentHeaderSub marginRight="8px">
                              {e.potentialRVUs.toFixed(2)}
                            </AssessmentHeaderSub>
                          </AssessmentHeaderData>
                        </AssessmentHeader>
                      </AssessmentHeaderContainer>
                    ))}
                  </>
                </PartFacultyReportPanelAccordion>
                )
              }
            </>
          )}
        </PanelSC>
      )}
    </>
  );
};

InstructionReport.propTypes = {
  error: PropTypes.object, 
  data: PropTypes.object, 
  isMutating: PropTypes.bool,
};

InstructionReport.defaultProps = {
  error: undefined, 
  data: undefined, 
  isMutating: false
};

export default InstructionReport;
