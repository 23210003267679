import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import SyllabusHeader from './SyllabusHeader/SyllabusHeader';
import SyllabusSchedule from './SyllabusSchedule/SyllabusSchedule';
import EncounterFormContainer from './SyllabusForms/EncounterForm/EncounterFormContainer';
import AssessmentFormContainer from './SyllabusForms/AssessmentForm/AssessmentFormContainer';
import ItemWelcome from './SyllabusForms/ItemWelcome';
import { ContainerResize } from '../Library/Layout';
import SyllabusModalApprove from './SyllabusModalApprove';
import SyllabusModalCommit from './SyllabusModalCommit';
import { syllabusTypes } from '../../helpers/constants';

const Layout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const List = styled.div`
  width: 30%;
  height: 85%;
  position: fixed;
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Forms = styled.div`
  height: 85%;
  margin-left: 30%;
  overflow-y: scroll;
  position: fixed;
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
`;

export const getUpdateForm = (
  exist,
  partUuid,
  dat,
  partFacultyAssigned,
  restrictEdit
) => {
  switch (exist.type) {
    case syllabusTypes.encounter:
      return (
        <EncounterFormContainer
          existingItem={exist}
          restrictEdit={restrictEdit}
          partUuid={partUuid}
          dat={dat}
          facultyOptions={partFacultyAssigned}
        />
      );
    case syllabusTypes.assessment:
      return (
        <AssessmentFormContainer
          existingItem={exist}
          restrictEdit={restrictEdit}
          partUuid={partUuid}
          dat={dat}
          facultyOptions={partFacultyAssigned}
        />
      );
    default:
      return <ItemWelcome />;
  }
};

export const getNewForm = (
  partUuid,
  itemType,
  dat,
  partFacultyAssigned,
  restrictEdit
) => {
  switch (itemType) {
    case syllabusTypes.encounter:
      return (
        <EncounterFormContainer
          partUuid={partUuid}
          dat={dat}
          restrictEdit={restrictEdit}
          facultyOptions={partFacultyAssigned}
        />
      );
    case syllabusTypes.assessment:
      return (
        <AssessmentFormContainer
          partUuid={partUuid}
          dat={dat}
          facultyOptions={partFacultyAssigned}
          restrictEdit={restrictEdit}
        />
      );
    default:
      return <ItemWelcome />;
  }
};

export const itemFormSection = (
  itemId,
  syllabusItems,
  syllabusItemNew,
  partUuid,
  itemType,
  dat,
  partFacultyAssigned,
  restrictEdit
) => {
  const exist = _.find(syllabusItems, item => item.uuid === itemId);

  if (exist) {
    return getUpdateForm(
      exist,
      partUuid,
      dat,
      partFacultyAssigned,
      restrictEdit
    );
  } else if (syllabusItemNew) {
    return getNewForm(
      partUuid,
      itemType,
      dat,
      partFacultyAssigned,
      restrictEdit
    );
  } else {
    return <div />;
  }
};

const SyllabusEditor = ({
  restrictEdit,
  course,
  section,
  part,
  parts,
  dat,
  partNumber,
  sectionNumber,
  syllabusItems,
  itemId,
  modalApproveOpen,
  modalCommitOpen,
  changeItemId,
  syllabusItemNew,
  syllabusItemNewType,
  addSyllabusItem,
  filterStatus,
  filterType,
  handleModalSubmitSyllabusOpen,
  handleModalApproveSyllabusOpen,
  handleModalSubmitSyllabusClose,
  handleModalApproveSyllabusClose,
  handleSubmitForApproval,
  handleSubmitForCommit,
  handleFilterType,
  handleFilterStatus
}) =>
  part &&
  course &&
  section && (
    <ContainerResize>
      <SyllabusHeader
        restrictEdit={restrictEdit}
        course={course}
        part={part}
        parts={parts}
        section={section}
        sectionNumber={sectionNumber}
        partNumber={partNumber}
        syllabusItems={syllabusItems}
        handleModalSubmitSyllabusOpen={handleModalSubmitSyllabusOpen}
        handleModalApproveSyllabusOpen={handleModalApproveSyllabusOpen}
        handleSubmitForApproval={handleSubmitForApproval}
        handleSubmitForCommit={handleSubmitForApproval}
        handleFilterType={handleFilterType}
        handleFilterStatus={handleFilterStatus}
      />

      <Layout>
        <List>
          <SyllabusSchedule
            restrictEdit={restrictEdit}
            syllabusItems={syllabusItems}
            addSyllabusItem={addSyllabusItem}
            itemId={itemId}
            changeItemId={changeItemId}
            filterStatus={filterStatus}
            filterType={filterType}
            handleFilterType={handleFilterType}
            handleFilterStatus={handleFilterStatus}
          />
        </List>
        <Forms>
          {itemFormSection(
            itemId,
            syllabusItems,
            syllabusItemNew,
            part.uuid,
            syllabusItemNewType,
            dat,
            part.part_faculty_assigned,
            restrictEdit
          )}
        </Forms>
      </Layout>

      <SyllabusModalApprove
        modalApproveOpen={modalApproveOpen}
        handleModalSubmitSyllabusClose={handleModalSubmitSyllabusClose}
        handleSubmitForApproval={handleSubmitForApproval}
      />
      <SyllabusModalCommit
        modalCommitOpen={modalCommitOpen}
        handleModalApproveSyllabusClose={handleModalApproveSyllabusClose}
        handleSubmitForCommit={handleSubmitForCommit}
      />
    </ContainerResize>
  );

SyllabusEditor.propTypes = {
  restrictEdit: PropTypes.bool,
  dat: PropTypes.object,
  part: PropTypes.object,
  parts: PropTypes.array,
  section: PropTypes.object,
  course: PropTypes.object,
  partNumber: PropTypes.string,
  sectionNumber: PropTypes.string,
  syllabusItems: PropTypes.arrayOf(PropTypes.object),
  itemId: PropTypes.string,
  syllabusItemNew: PropTypes.bool,
  syllabusItemNewType: PropTypes.oneOf(['assessment', 'encounter', '']),
  modalApproveOpen: PropTypes.bool,
  modalCommitOpen: PropTypes.bool,
  filterStatus: PropTypes.string,
  filterType: PropTypes.string,
  addSyllabusItem: PropTypes.func,
  changeItemId: PropTypes.func,
  handleModalSubmitSyllabusOpen: PropTypes.func,
  handleModalSubmitSyllabusClose: PropTypes.func,
  handleModalApproveSyllabusOpen: PropTypes.func,
  handleModalApproveSyllabusClose: PropTypes.func,
  handleSubmitForApproval: PropTypes.func,
  handleSubmitForCommit: PropTypes.func,
  handleFilterType: PropTypes.func,
  handleFilterStatus: PropTypes.func
};

SyllabusEditor.defaultProps = {
  restrictEdit: true,
  dat: {},
  part: {},
  parts: [],
  section: {},
  course: {},
  partNumber: '1',
  sectionNumber: '1',
  syllabusItems: [],
  itemId: '',
  syllabusItemNew: false,
  syllabusItemNewType: '',
  modalApproveOpen: false,
  modalCommitOpen: false,
  filterStatus: 'all',
  filterType: 'all',
  addSyllabusItem: undefined,
  changeItemId: undefined,
  handleModalSubmitSyllabusOpen: undefined,
  handleModalSubmitSyllabusClose: undefined,
  handleModalApproveSyllabusOpen: undefined,
  handleModalApproveSyllabusClose: undefined,
  handleSubmitForApproval: undefined,
  handleSubmitForCommit: undefined,
  handleFilterType: undefined,
  handleFilterStatus: undefined
};

export default SyllabusEditor;
