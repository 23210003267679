import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Chip,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  IconButton
} from '@material-ui/core';
import { RemoveIcon } from '@xcomp/xcomp-design-library/dist/icons';

const FormControlSC = styled(FormControl)`
  && {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;
const MenuItemSC = styled(({ isSelected, ...rest }) => <MenuItem {...rest} />)`
  && {
    display: flex;
    justify-content: space-between;
    background-color: ${props =>
      props.isSelected ? 'rgba(0, 0, 0, 0.08)' : 'none'};
  }
`;

const SelectedSC = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ChipSC = styled(Chip)`
  && {
    margin-top: 8px;
    margin-left: 8px;
    color: ${props => props.theme.fontColors.darker};
    background-color: ${props => props.theme.colors.blue[100]};
  }
`;

const RemoveIconSC = styled(RemoveIcon)`
  && {
    color: ${props => props.theme.fontColors.dark};
  }
`;

export default function SelectOutline({
  label,
  name,
  value,
  options,
  handleChange,
  onSelectAll,
  onDeselect,
  ...rest
}) {
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const optionsByValue = _.keyBy(options, 'value');

  return (
    <FormControlSC fullWidth={true} variant="outlined" {...rest}>
      <InputLabel shrink={true} ref={inputLabel} htmlFor={`${name}-helper`}>
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        multiple
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name={name}
            id={`${name}-helper`}
            notched={true}
          />
        }
        renderValue={selected => (
          <SelectedSC>
            {selected.map(value => (
              <ChipSC
                key={value}
                label={optionsByValue[value]?.label}
                onDelete={() => {}}
                deleteIcon={
                  <IconButton
                    value={value}
                    onMouseDown={event => {
                      event.stopPropagation();
                      onDeselect(value);
                    }}
                  >
                    <RemoveIconSC />
                  </IconButton>
                }
                color="primary"
              />
            ))}
          </SelectedSC>
        )}
        MenuProps={{
          variant: 'menu',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
        {...rest}
      >
        <MenuItemSC
          key="all"
          value="all"
          isSelected={options.length === value.length}
        >
          <div>All</div>
          <Checkbox
            checked={options.length === value.length}
            color="primary"
            onChange={onSelectAll}
          />
        </MenuItemSC>
        {options &&
          options.map(option => (
            <MenuItemSC key={option.value} value={option.value}>
              {option.label}
              <Checkbox
                checked={
                  value.indexOf(option.value) > -1 ||
                  options.length === value.length
                }
                color="primary"
              />
            </MenuItemSC>
          ))}
      </Select>
    </FormControlSC>
  );
}

SelectOutline.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  onDeselect: PropTypes.func,
  onSelectAll: PropTypes.func
};

SelectOutline.defaultProps = {
  label: '',
  name: '',
  value: [],
  options: [],
  handleChange: undefined,
  onDeselect: undefined,
  onSelectAll: undefined
};
