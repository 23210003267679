import { call, put, takeEvery } from 'redux-saga/effects';
import {
  deleteAssessment,
  deleteDelayedStudentAssessmentGrade,
  getAssessment,
  getAssessmentAssociations,
  getAssessmentsByPart,
  getAssessmentTechniques,
  getAssessmentTypes,
  patchAssessmentApprove,
  postAssessment,
  putAssessmentDraft,
  putAssessmentFinal,
  putAssessmentStatusCommit,
  putAssessmentStatusScore,
  putDelayedStudentAssessmentCommitStatus,
  putDelayedStudentAssessmentGrade
} from '../../api/assessmentApi';

import {
  doDeleteAssessmentDelayedStudentGradeError,
  doDeleteAssessmentDelayedStudentGradeSuccess,
  doDeleteAssessmentError,
  doDeleteAssessmentSuccess,
  doGetAssessmentAssociationsError,
  doGetAssessmentAssociationsSuccess,
  doGetAssessmentError,
  doGetAssessmentsByPartError,
  doGetAssessmentsByPartSuccess,
  doGetAssessmentSuccess,
  doGetAssessmentTechniquesError,
  doGetAssessmentTechniquesSuccess,
  doGetAssessmentTypeError,
  doGetAssessmentTypeSuccess,
  doPatchAssessmentApproveError,
  doPatchAssessmentApproveSuccess,
  doPostAssessmentError,
  doPostAssessmentSuccess,
  doPutAssessmentDelayedStudentGradeError,
  doPutAssessmentDelayedStudentGradeSuccess,
  doPutAssessmentDelayedStudentStatusCommitError,
  doPutAssessmentDelayedStudentStatusCommitSuccess,
  doPutAssessmentDraftError,
  doPutAssessmentDraftSuccess,
  doPutAssessmentFinalError,
  doPutAssessmentFinalSuccess,
  doPutAssessmentStatusCommitError,
  doPutAssessmentStatusCommitSuccess,
  doPutAssessmentStatusScoreError,
  doPutAssessmentStatusScoreSuccess
} from '../actions/assessmentActions';

import {
  DELETE_ASSESSMENT,
  GET_ASSESSMENT_ASSOCIATIONS,
  GET_ASSESSMENT_TECHNIQUES,
  GET_ASSESSMENT_TYPE,
  GET_ASSESSMENT,
  GET_ASSESSMENTS_BY_PART,
  PATCH_ASSESSMENT_APPROVE,
  POST_ASSESSMENT,
  PUT_ASSESSMENT_DRAFT,
  PUT_ASSESSMENT_FINAL,
  PUT_ASSESSMENT_STATUS_COMMIT,
  PUT_ASSESSMENT_STATUS_SCORE,
  PUT_ASSESSMENT_GRADE_DELAYED_STUDENT,
  PUT_ASSESSMENT_DELAYED_STATUS_COMMIT,
  DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT
} from '../actions-type';

function* handleGetAssessment(action) {
  const { assessmentUuid } = action.payload;
  try {
    const result = yield call(getAssessment, assessmentUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetAssessmentSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetAssessmentError(error));
  }
}

function* handleGetAssessmentsByPart(action) {
  const { partUuid } = action.payload;
  try {
    const result = yield call(getAssessmentsByPart, partUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetAssessmentsByPartSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetAssessmentsByPartError(error));
  }
}

function* handleGetAssessmentTypes() {
  try {
    const result = yield call(getAssessmentTypes);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetAssessmentTypeSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetAssessmentTypeError(error));
  }
}

function* handleGetAssessmentAssociations() {
  try {
    const result = yield call(getAssessmentAssociations);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetAssessmentAssociationsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetAssessmentAssociationsError(error));
  }
}

function* handleGetAssessmentTechniques() {
  try {
    const result = yield call(getAssessmentTechniques);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetAssessmentTechniquesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetAssessmentTechniquesError(error));
  }
}

function* handlePostCreateAssessment(action) {
  const { assessment } = action.payload;
  try {
    const result = yield call(postAssessment, assessment);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostAssessmentSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostAssessmentError(error));
  }
}

function* handlePutDraftAssessment(action) {
  const { assessment } = action.payload;
  try {
    const result = yield call(putAssessmentDraft, assessment);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutAssessmentDraftSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutAssessmentDraftError(error));
  }
}

function* handlePutFinishAssessment(action) {
  const { assessment } = action.payload;
  try {
    const result = yield call(putAssessmentFinal, assessment);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutAssessmentFinalSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutAssessmentFinalError(error));
  }
}

function* handlePutAssessmentStatusScore(action) {
  const { assessment } = action.payload;
  try {
    const result = yield call(putAssessmentStatusScore, assessment);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutAssessmentStatusScoreSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutAssessmentStatusScoreError(error));
  }
}

function* handlePutAssessmentStatusCommit(action) {
  const { assessment } = action.payload;
  try {
    const result = yield call(putAssessmentStatusCommit, assessment);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutAssessmentStatusCommitSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutAssessmentStatusCommitError(error));
  }
}

function* handlePutAssessmentDelayedStudentGrade(action) {
  const { assessmentUuid, studentUuid, resultCardUuid } = action.payload;
  try {
    const result = yield call(putDelayedStudentAssessmentGrade, {
      assessmentUuid,
      studentUuid,
      resultCardUuid
    });
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPutAssessmentDelayedStudentGradeSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutAssessmentDelayedStudentGradeError(error));
  }
}

function* handlePutAssessmentDelayedStudentGradeStatusCommit(action) {
  const {
    assessmentUuid,
    studentUuid,
    delayedStatusCommit,
    justificationMessage
  } = action.payload;

  try {
    const result = yield call(putDelayedStudentAssessmentCommitStatus, {
      assessmentUuid,
      studentUuid,
      delayedStatusCommit,
      justificationMessage
    });
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutAssessmentDelayedStudentStatusCommitSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutAssessmentDelayedStudentStatusCommitError(error));
  }
}

function* handleDeleteAssessmentDelayedStudentGrade(action) {
  const { assessmentUuid, studentUuid, resultCardUuid } = action.payload;
  try {
    const result = yield call(deleteDelayedStudentAssessmentGrade, {
      assessmentUuid,
      studentUuid,
      resultCardUuid
    });
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteAssessmentDelayedStudentGradeSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteAssessmentDelayedStudentGradeError(error));
  }
}

function* handlePatchApproveAssessment(action) {
  try {
    const result = yield call(patchAssessmentApprove, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchAssessmentApproveSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPatchAssessmentApproveError(error));
  }
}

function* handlePostDeleteAssessment(action) {
  const { assessmentUuid } = action.payload;
  try {
    const result = yield call(deleteAssessment, assessmentUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteAssessmentSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteAssessmentError(error));
  }
}

const assessment = [
  takeEvery(DELETE_ASSESSMENT, handlePostDeleteAssessment),
  takeEvery(GET_ASSESSMENT_ASSOCIATIONS, handleGetAssessmentAssociations),
  takeEvery(GET_ASSESSMENT_TECHNIQUES, handleGetAssessmentTechniques),
  takeEvery(GET_ASSESSMENT_TYPE, handleGetAssessmentTypes),
  takeEvery(GET_ASSESSMENT, handleGetAssessment),
  takeEvery(GET_ASSESSMENTS_BY_PART, handleGetAssessmentsByPart),
  takeEvery(PATCH_ASSESSMENT_APPROVE, handlePatchApproveAssessment),
  takeEvery(POST_ASSESSMENT, handlePostCreateAssessment),
  takeEvery(PUT_ASSESSMENT_DRAFT, handlePutDraftAssessment),
  takeEvery(PUT_ASSESSMENT_FINAL, handlePutFinishAssessment),
  takeEvery(PUT_ASSESSMENT_STATUS_COMMIT, handlePutAssessmentStatusCommit),
  takeEvery(
    PUT_ASSESSMENT_GRADE_DELAYED_STUDENT,
    handlePutAssessmentDelayedStudentGrade
  ),
  takeEvery(
    PUT_ASSESSMENT_DELAYED_STATUS_COMMIT,
    handlePutAssessmentDelayedStudentGradeStatusCommit
  ),
  takeEvery(
    DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT,
    handleDeleteAssessmentDelayedStudentGrade
  ),
  takeEvery(PUT_ASSESSMENT_STATUS_SCORE, handlePutAssessmentStatusScore)
];

export { assessment };
