import React, { useContext, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import styled, { ThemeContext } from 'styled-components';
import { useAtom } from 'jotai';
import NotFoundIllustration from '@xcomp/xcomp-design-library/dist/illustrations/custom/NotFoundIllustration';
import { useParams } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';

import { ASSESSMENT_GRID_MODAL_ID } from '../../../Reports/reportUtils';
import AssessmentTable from '../../../Reports/CohortAssessmentGrid/Table/AssessmentTable';
import Filters, {
  filtersAtom,
  filtersDefaultValues,
  reportDataAtom
} from './Filters';
import fetch from '../../../../helpers/fetch';
import { useComputeAttemptReportSummaryRows } from '../../../../helpers/hooks/useComputeAttemptReportSummaryRows';

// const filtersLoadingAtom = atom(false);

export async function fetcher(url, { arg }) {
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(arg)
  }).then(res => res.json());
}

const ScrollableContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
  background: ${props => props.theme.colors.grey[200]};
`;
//
const NotFoundWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;

  & > p {
    font-size: 22px;
    font-weight: 500;
  }
`;

const PartAttemptReport = () => {
  const { partUuid, sectionUuid } = useParams();
  const themeContext = useContext(ThemeContext);
  const [filters, setFilters] = useAtom(filtersAtom);
  const [reportData, setReportData] = useAtom(reportDataAtom);

  const { students, summaryRows } = useComputeAttemptReportSummaryRows();

  const { trigger, isMutating } = useSWRMutation(
    `/api/part-attempt-report/${partUuid}/${sectionUuid}/attempt-report`,
    fetcher
  );

  useEffect(() => {
    setFilters(filtersDefaultValues);
    setReportData(null);
  }, [setFilters, setReportData]);

  if (!reportData || !reportData?.assessmentBlocks?.length) {
    return (
      <>
        <Filters trigger={trigger} />

        <NotFoundWrapper>
          {isMutating ? (
            <CircularProgress />
          ) : (
            <>
              <NotFoundIllustration
                dotsColor={themeContext.colors.primaryShades[450]}
                bgColor={themeContext.colors.primaryShades[200]}
                outlineColor={themeContext.colors.primary}
              />
              <p>
                There were no students and/or assessments found for this cohort.
                Please use the filters.
              </p>
            </>
          )}
        </NotFoundWrapper>
      </>
    );
  }

  return (
    <>
      <Filters trigger={trigger} />
      {isMutating ? (
        <NotFoundWrapper>
          <CircularProgress />
        </NotFoundWrapper>
      ) : (
        <div style={{ height: 'calc(100% - 65px)' }}>
          <div
            id={ASSESSMENT_GRID_MODAL_ID}
            style={{ paddingLeft: 15, paddingTop: 15, zoom: 1, height: '100%' }}
          >
            <ScrollableContainer>
              <AssessmentTable
                noOfBlocks={
                  filters.filteredAssessments.includes('all')
                    ? filters.filteredAssessments.length - 1
                    : filters.filteredAssessments.length
                }
                assessments={reportData?.assessmentBlocks}
                students={students.s}
                isAnonymized={filters.isAnonymized}
                dataCellValueType={filters.dataCellValueType}
                isExpanded
                summaryRows={summaryRows}
                filteredStudentUuids={filters.filteredStudents}
                filteredAssessmentUuids={filters.filteredAssessments}
                totalFirstAttemptPassRate={summaryRows.firstTimePassRate}
                showDaysOfCompletion={false}
              />
            </ScrollableContainer>
          </div>
        </div>
      )}
    </>
  );
};

export default PartAttemptReport;
