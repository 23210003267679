import {
  GET_COHORT,
  GET_COHORT_SUCCESS,
  GET_COHORT_ERROR,
  GET_COHORTS,
  GET_COHORTS_SUCCESS,
  GET_COHORTS_ERROR,
  GET_PROGRAM_COHORTS,
  GET_PROGRAM_COHORTS_SUCCESS,
  GET_PROGRAM_COHORTS_ERROR,
  POST_COHORT,
  POST_COHORT_SUCCESS,
  POST_COHORT_ERROR,
  PUT_COHORT,
  PUT_COHORT_SUCCESS,
  PUT_COHORT_ERROR,
  DELETE_COHORT,
  DELETE_COHORT_SUCCESS,
  DELETE_COHORT_ERROR
} from '../actions-type';

const doGetCohort = cohortUuid => ({
  type: GET_COHORT,
  payload: {
    cohortUuid
  }
});

const doGetCohortSuccess = result => ({
  type: GET_COHORT_SUCCESS,
  payload: result
});

const doGetCohortError = error => ({
  type: GET_COHORT_ERROR,
  payload: error
});

const doGetCohorts = () => ({
  type: GET_COHORTS
});

const doGetCohortsSuccess = result => ({
  type: GET_COHORTS_SUCCESS,
  payload: result
});

const doGetCohortsError = error => ({
  type: GET_COHORTS_ERROR,
  payload: error
});

const doGetProgramCohorts = programUuid => ({
  type: GET_PROGRAM_COHORTS,
  payload: {
    programUuid
  }
});

const doGetProgramCohortsSuccess = results => ({
  type: GET_PROGRAM_COHORTS_SUCCESS,
  payload: results
});

const doGetProgramCohortsError = error => ({
  type: GET_PROGRAM_COHORTS_ERROR,
  payload: error
});

const doPostCohort = cohort => ({
  type: POST_COHORT,
  payload: {
    cohort
  }
});

const doPostCohortSuccess = result => ({
  type: POST_COHORT_SUCCESS,
  payload: result
});

const doPostCohortError = error => ({
  type: POST_COHORT_ERROR,
  payload: error
});

const doPutCohort = cohort => ({
  type: PUT_COHORT,
  payload: {
    cohort
  }
});

const doPutCohortSuccess = result => ({
  type: PUT_COHORT_SUCCESS,
  payload: result
});

const doPutCohortError = error => ({
  type: PUT_COHORT_ERROR,
  payload: error
});

const doDeleteCohort = cohortUuid => ({
  type: DELETE_COHORT,
  payload: {
    cohortUuid
  }
});

const doDeleteCohortSuccess = result => ({
  type: DELETE_COHORT_SUCCESS,
  payload: result
});

const doDeleteCohortError = error => ({
  type: DELETE_COHORT_ERROR,
  payload: error
});

export {
  doGetCohort,
  doGetCohortSuccess,
  doGetCohortError,
  doGetCohorts,
  doGetCohortsSuccess,
  doGetCohortsError,
  doGetProgramCohorts,
  doGetProgramCohortsSuccess,
  doGetProgramCohortsError,
  doPostCohort,
  doPostCohortSuccess,
  doPostCohortError,
  doPutCohort,
  doPutCohortSuccess,
  doPutCohortError,
  doDeleteCohort,
  doDeleteCohortSuccess,
  doDeleteCohortError
};
