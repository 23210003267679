import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import {
  ContainerResize,
  ContainerPage,
  ContainerActions,
  ElevateLow
} from '../Library/Layout';
import Search from '../Library/Search';
import Header from '../Library/Header';
import ProgramActions from './ProgramActions';

const SearchRow = styled.div`    
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid
    rgba(224, 224, 224, 1);
`;

const SearchBox = styled.div`
  width: 400px;
`;

class ProgramManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: 25
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { programs, searchUpdated } = this.props;
    const { rowsPerPage, page } = this.state;

    return (
      <ContainerResize>
        <Header
          title="Program Management"
          subtitle="Manage, add and remove programs"
          backOn={false}
        />
        <ContainerActions>
          <Link to="/school-management/programs/add">
            <ButtonPrimary>Add Program</ButtonPrimary>
          </Link>
        </ContainerActions>
        <ContainerPage paddingTop="40px">
          <ElevateLow>
            <SearchRow>
              <SearchBox>
                <Search onChange={searchUpdated} />
              </SearchBox>
            </SearchRow>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Program Abbreviation</TableCell>
                  <TableCell>Program Name</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {programs
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(program => (
                    <Fragment key={program.uuid}>
                      <TableRow>
                        <TableCell>{program.programAbbreviation}</TableCell>
                        <TableCell>{program.programName}</TableCell>
                        <TableCell>
                          <ProgramActions programUuid={program.uuid} />
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={programs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page'
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page'
              }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </ElevateLow>
        </ContainerPage>
      </ContainerResize>
    );
  }
}

ProgramManagement.propTypes = {
  programs: PropTypes.array,
  searchUpdated: PropTypes.func
};

ProgramManagement.defaultProps = {
  programs: [],
  searchUpdated: undefined
};

export default ProgramManagement;
