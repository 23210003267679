import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import { competencyScoreSelector } from '../../../../redux/selectors/competencyScoreSelectors';
import { selectLetterGradesBySchool } from '../../../../redux/selectors/letterGradeSelectors';

export default function CompetencyGrade({ competencyUuid }) {
  const [grade, setGrade] = useState('- - ');
  const schoolUuid = useSelector(state => state.userState.selectedSchoolUuid);

  const letterGrades = useSelector(
    state => selectLetterGradesBySchool(state, schoolUuid),
    _.isEqual
  );
  const score = useSelector(
    state => competencyScoreSelector(state, competencyUuid),
    _.isEqual
  );

  useEffect(() => {
    if (score?.relTotal && score?.potTotal) {
      const rel = new Decimal(score?.relTotal);
      const pot = new Decimal(score?.potTotal);

      const percentage = rel.div(pot).times(100);

      const mapGrades = _(letterGrades)
        .map(record => ({
          ...record,
          rangeLow: Number(record.rangeLow),
          rangeHigh: Number(record.rangeHigh)
        }))
        .value();

      const findGrade = _.find(mapGrades, schoolGrade => {
        if (percentage) {
          const low = new Decimal(schoolGrade.rangeLow);
          const high = new Decimal(schoolGrade.rangeHigh);

          const conditionOne = percentage.greaterThanOrEqualTo(low);
          const conditionTwo = percentage.lessThanOrEqualTo(high);

          return conditionOne && conditionTwo;
        } else {
          return false;
        }
      });

      setGrade(findGrade?.grade);
    }
  }, [letterGrades, score?.potTotal, score?.relTotal]);

  return <>{grade}</>;
}

CompetencyGrade.propTypes = {
  competencyUuid: PropTypes.string
};

CompetencyGrade.defaultProps = {
  competencyUuid: undefined
};
