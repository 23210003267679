import fetch from '../helpers/fetch';

const getCompetencyCollections = programUuid =>
  fetch(`/api/program/${programUuid}/competency-collections`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCompetencyCollection = competencyCollectionUuid =>
  fetch(`/api/competency-collection/${competencyCollectionUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCompetencyCollectionTopicSearch = searchTerm =>
  fetch(`/api/topics/topic-search/${searchTerm}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCompetencyCollectionSubtopicSearch = searchTerm =>
  fetch(`/api/subtopics/topic-search/${searchTerm}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCompetencyCollectionAndScores = (programUuid, userUuid) =>
  fetch(
    `/api/competency-collections-scores?programUuid=${programUuid}&userUuid=${userUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCompetencyCollectionMicroSearch = searchTerm =>
  fetch(`/api/microcompetencies/topic-search/${searchTerm}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postCompetencyCollection = newCompetencyCollection =>
  fetch(`/api/competency-collection/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(newCompetencyCollection)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putCompetencyCollection = ({ uuid, ...updates }) =>
  fetch(`/api/competency-collection/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(updates)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteCompetencyCollection = uuid =>
  fetch(`/api/competency-collection/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  deleteCompetencyCollection,
  getCompetencyCollection,
  getCompetencyCollectionAndScores,
  getCompetencyCollectionMicroSearch,
  getCompetencyCollectionSubtopicSearch,
  getCompetencyCollectionTopicSearch,
  getCompetencyCollections,
  postCompetencyCollection,
  putCompetencyCollection
};
