import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { partSelectorByUuid } from '../../../redux/selectors/partsSelectors';
import Part from './Part';
import { useQuery } from '../../../helpers/hooks/router.hooks';

export default function PartContainer({
  courseUuid,
  partIndex,
  partUuid,
  sectionNumber,
  sectionUuid,
  termDatesDisplay
}) {
  const query = useQuery();
  const userUuid = query.get('userUuid');

  const part = useSelector(
    state => partSelectorByUuid(state, partUuid),
    shallowEqual
  );

  return (
    <Part
      userUuid={userUuid}
      part={part}
      partIndex={partIndex}
      termDatesDisplay={termDatesDisplay}
      courseUuid={courseUuid}
      sectionUuid={sectionUuid}
      sectionNumber={sectionNumber}
    />
  );
}

PartContainer.propTypes = {
  sectionUuid: PropTypes.string,
  partUuid: PropTypes.string,
  courseUuid: PropTypes.string,
  termDatesDisplay: PropTypes.string,
  partIndex: PropTypes.number,
  sectionNumber: PropTypes.number
};

PartContainer.defaultProps = {
  sectionUuid: undefined,
  courseUuid: undefined,
  partUuid: undefined,
  termDatesDisplay: '',
  partIndex: 0,
  sectionNumber: 0
};
