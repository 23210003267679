import {
  GET_SECTION_PERFORMANCE_REPORT,
  GET_SECTION_PERFORMANCE_REPORT_ERROR,
  GET_SECTION_PERFORMANCE_REPORT_SUCCESS,
  GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION,
  GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION_ERROR,
  GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION_SUCCESS,
  PATCH_SECTION_GRADE_DELAYED_STUDENT_SUCCESS,
  DELETE_SECTION_GRADE_DELAYED_STUDENT_SUCCESS,
  DELETE_SECTION_LEGACY_GRADES_SUCCESS
} from '../actions-type';

export const INITIAL_STATE = {
  sectionPerformanceReport: [],
  loadingSectionPerformanceReport: false,
  loadingPartGradeResults: false
};

const sectionPerformanceReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SECTION_PERFORMANCE_REPORT_SUCCESS: {
      const { sectionPerformanceReport } = action.payload;
      return {
        ...state,
        sectionPerformanceReport,
        loadingSectionPerformanceReport: false
      };
    }
    case DELETE_SECTION_LEGACY_GRADES_SUCCESS: {
      const { sectionPerformanceReport } = action.payload;
      return {
        ...state,
        sectionPerformanceReport,
        loadingSectionPerformanceReport: false
      };
    }
    case PATCH_SECTION_GRADE_DELAYED_STUDENT_SUCCESS: {
      const { sectionPerformanceReport } = action.payload;
      return {
        ...state,
        sectionPerformanceReport,
        loadingSectionPerformanceReport: false
      };
    }
    case DELETE_SECTION_GRADE_DELAYED_STUDENT_SUCCESS: {
      const { sectionPerformanceReport } = action.payload;
      return {
        ...state,
        sectionPerformanceReport,
        loadingSectionPerformanceReport: false
      };
    }
    case GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION_SUCCESS: {
      return { ...state, loadingPartGradeResults: false };
    }
    case GET_SECTION_PERFORMANCE_REPORT: {
      return { ...state, loadingSectionPerformanceReport: true };
    }
    case GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION: {
      return { ...state, loadingPartGradeResults: true };
    }
    case GET_SECTION_PERFORMANCE_REPORT_ERROR: {
      return { ...state, loadingSectionPerformanceReport: false };
    }
    case GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION_ERROR: {
      return { ...state, loadingPartGradeResults: false };
    }
    default:
      return state;
  }
};

export default sectionPerformanceReportReducer;
