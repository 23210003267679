import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  CancelButton,
  SubmitButton,
  FormTop,
  FormBottom
} from '../../Library/FormComponents';
import CohortAssessmentCollectionFormDetails from './FormSections/CohortAssessmentCollectionFormDetails';

const FormLayout = styled.div`
  padding: 1.5rem 1.25rem 1rem 1.25rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const CohortAssessmentCollectionForm = ({
  assessmentCollection,
  buttonText,
  handleTitleChange,
  onSubmit,
  showValidationErrors,
  onFormClose
}) => {
  return (
    <FormLayout>
      <FormTop>
        <CohortAssessmentCollectionFormDetails
          title={assessmentCollection.title}
          showValidationErrors={showValidationErrors}
          handleTitleChange={handleTitleChange}
        />
      </FormTop>
      <FormBottom>
        <CancelButton onClick={onFormClose} noHoverBg>
          Cancel
        </CancelButton>
        <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
      </FormBottom>
    </FormLayout>
  );
};

CohortAssessmentCollectionForm.propTypes = {
  assessmentCollection: PropTypes.object,
  buttonText: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleTitleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onFormClose: PropTypes.func
};
CohortAssessmentCollectionForm.defaultProps = {
  assessmentCollection: {},
  buttonText: 'Create',
  showValidationErrors: false,
  handleTitleChange: undefined,
  onSubmit: undefined,
  onFormClose: undefined
};

export default CohortAssessmentCollectionForm;
