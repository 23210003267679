import { Model, fk, attr } from 'redux-orm';
import {
  DELETE_COURSE_SUCCESS,
  GET_COURSES_BY_DEPARTMENT_AY_SUCCESS,
  GET_COURSES_SUCCESS,
  GET_COURSE_BY_STUDENT_SUCCESS,
  GET_COURSE_SUCCESS,
  GET_SYLLABUS_COURSE_SUCCESS,
  GET_SYLLABUS_SUCCESS,
  POST_COURSE_SUCCESS,
  PUT_COURSE_LOCK_SUCCESS,
  PUT_COURSE_SUCCESS
} from '../actions-type';

class Course extends Model {
  static reducer(action, Course, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_COURSES_SUCCESS: {
        const courses = [...payload.courses];
        courses.forEach(course => Course.upsert(course));
        break;
      }
      case POST_COURSE_SUCCESS: {
        const course = { ...payload.course };
        Course.upsert(course);
        break;
      }
      case GET_COURSES_BY_DEPARTMENT_AY_SUCCESS: {
        const courses = [...payload.courses];
        courses.forEach(course => Course.upsert(course));
        break;
      }
      case GET_COURSE_SUCCESS: {
        const course = { ...payload.course };
        Course.upsert(course);
        break;
      }
      case PUT_COURSE_SUCCESS: {
        const course = { ...payload.course };
        Course.upsert(course);
        break;
      }
      case GET_SYLLABUS_SUCCESS: {
        const course = { ...payload.course };
        Course.upsert(course);
        break;
      }
      case PUT_COURSE_LOCK_SUCCESS: {
        const course = { ...payload.course };
        Course.withId(course.uuid).update({ locked: course.locked });
        break;
      }
      case DELETE_COURSE_SUCCESS: {
        const course = { ...payload };
        Course.withId(course.courseUuid).delete();
        break;
      }
      case GET_COURSE_BY_STUDENT_SUCCESS: {
        const courses = [...payload.courses];

        if (courses?.length > 0) {
          courses.forEach(course => {
            const {
              uuid,
              status,
              locked,
              createdAt,
              updatedAt,
              academicYearUuid,
              courseMasterUuid,
              termUuid
            } = course;

            Course.upsert({
              uuid,
              status,
              locked,
              createdAt,
              updatedAt,
              academicYearUuid,
              courseMasterUuid,
              termUuid
            });
          });
        }

        break;
      }
      case GET_SYLLABUS_COURSE_SUCCESS: {
        const course = { ...payload.course };

        const {
          uuid,
          status,
          locked,
          createdAt,
          updatedAt,
          academicYearUuid,
          courseMasterUuid,
          termUuid
        } = course;

        Course.upsert({
          uuid,
          status,
          locked,
          createdAt,
          updatedAt,
          academicYearUuid,
          courseMasterUuid,
          termUuid
        });

        break;
      }
      default:
        return session;
    }
  }
}

Course.fields = {
  uuid: attr(),
  status: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  academicYearUuid: fk('AcademicYear', 'courses'),
  termUuid: fk('Term', 'courses')
};

Course.modelName = 'Course';
Course.options = {
  idAttribute: 'uuid'
};

export default Course;
