import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  validateInputString,
  validateSelectDate
} from '../../../helpers/validation/validateGeneric';

import {
  doGetCohort,
  doPutCohort,
  doDeleteCohort
} from '../../../redux/actions/cohortActions';

import { cohortSelectorByMatchParams } from '../../../redux/selectors/cohortsSelectors';

import CohortEdit from './CohortEdit';
import DeleteModal from '../../Library/Modal/DeleteModal/DeleteModal';

export default function CohortEditContainer({ match }) {
  const history = useHistory();
  const [uuid, setUuid] = useState('');
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [hasErrors, setHasErrors] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const { cohortUuid } = match.params;
  const cohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    shallowEqual
  );

  const programUuid = useSelector(state => state.userState.selectedProgramUuid);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(doGetCohort(cohortUuid));
  }, [dispatch, cohortUuid]);

  useEffect(() => {
    if (cohort) {
      setUuid(cohort.uuid);
      setName(cohort.name);
      setStartDate(cohort.startDate);
      setEndDate(cohort.endDate);
    }
  }, [cohort]);

  const handleNameChange = event => {
    const { value } = event.target;
    setName(value);
  };

  const handleStartDateChange = (indentifier, field, date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (indentifier, field, date) => {
    setEndDate(date);
  };

  const _validate = () => {
    let containErrors = false;
    const cohortNameError = validateInputString(name);
    const cohortStartDateError = validateSelectDate(startDate);
    const cohortEndDateError = validateSelectDate(endDate);

    if (
      cohortNameError.invalid ||
      cohortStartDateError.invalid ||
      cohortEndDateError.invalid
    ) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleUpdateCohort = () => {
    const rejectCreation = _validate();

    if (rejectCreation) {
      setHasErrors(true);
    } else {
      const request = {
        uuid,
        name,
        startDate,
        endDate,
        programUuid
      };

      dispatch(doPutCohort(request));
      history.push('/program-management/cohort');
    }
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };

  const handleDelete = () => {
    dispatch(doDeleteCohort(uuid));
    setDeleteModal(false);
    history.push('/program-management/cohort');
  };

  return (
    <>
      <CohortEdit
        name={name}
        startDate={startDate}
        endDate={endDate}
        hasErrors={hasErrors}
        handleNameChange={handleNameChange}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleUpdateCohort={handleUpdateCohort}
        handleDeleteModalOpen={handleDeleteModalOpen}
      />
      <DeleteModal
        type="cohort"
        modalOpen={deleteModal}
        handleDeleteModalClose={handleDeleteModalClose}
        handleDelete={handleDelete}
      />
    </>
  );
}

CohortEditContainer.propTypes = {
  match: PropTypes.object
};

CohortEditContainer.defaultProps = {
  match: undefined
};
