import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import {
  ButtonInline,
  ButtonPrimary,
  Heading2,
  ButtonDelete
} from '@xcomp/xcomp-design-library';

import {
  ContainerResize,
  ContainerPage,
  ContainerContent,
  ElevateLow
} from '../../Library/Layout';
import {
  validateInputString,
  validateSelectDate
} from '../../../helpers/validation/validateGeneric';

import Header from '../../Library/Header';
import FormField from '../../Library/FormField';
import Date from '../../Library/Date';

const DeleteRow = styled.div`
  margin-top: 2rem;
  margin-bottom: 6rem;
`;

const Cancel = styled(ButtonInline)`
  && {
    margin-right: 10px;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
`;

export default function CohortEdit({
  name,
  startDate,
  endDate,
  hasErrors,
  handleNameChange,
  handleStartDateChange,
  handleEndDateChange,
  handleUpdateCohort,
  handleDeleteModalOpen
}) {
  return (
    <ContainerResize>
      <Header title="Cohort Management" subtitle="Add cohort" />
      <ContainerPage>
        <ElevateLow>
          <ContainerContent>
            <Heading2>Cohort Details</Heading2>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                <FormField
                  name="name"
                  label="Cohort Name*"
                  value={name}
                  onBlur={handleNameChange}
                  handleValidate={validateInputString}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Date
                  dataTestId="cohort-add-start-date"
                  name="startDate"
                  label="Start Date*"
                  value={startDate}
                  indentifier={null}
                  handleChange={handleStartDateChange}
                  handleValidate={validateSelectDate}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Date
                  dataTestId="cohort-add-end-date"
                  name="endDate"
                  label="End Date*"
                  value={endDate}
                  indentifier={null}
                  handleChange={handleEndDateChange}
                  handleValidate={validateSelectDate}
                  hasErrors={hasErrors}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Actions>
                <Link to="/program-management/cohort">
                  <Cancel>CANCEL</Cancel>
                </Link>
                <ButtonPrimary
                  data-testid="cohort-add-save"
                  onClick={handleUpdateCohort}
                >
                  SAVE
                </ButtonPrimary>
              </Actions>
            </Grid>
          </ContainerContent>
        </ElevateLow>
        <DeleteRow>
          <ButtonDelete onClick={() => handleDeleteModalOpen()}>
            Delete Cohort
          </ButtonDelete>
        </DeleteRow>
      </ContainerPage>
    </ContainerResize>
  );
}

CohortEdit.propTypes = {
  name: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  hasErrors: PropTypes.bool,
  handleNameChange: PropTypes.func,
  handleStartDateChange: PropTypes.func,
  handleEndDateChange: PropTypes.func,
  handleUpdateCohort: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func
};

CohortEdit.defaultProps = {
  name: '',
  startDate: '',
  endDate: '',
  hasErrors: false,
  handleNameChange: undefined,
  handleStartDateChange: undefined,
  handleEndDateChange: undefined,
  handleUpdateCohort: undefined,
  handleDeleteModalOpen: undefined
};
