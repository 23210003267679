import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import LockOutlined from '@material-ui/icons/LockOutlined';

import ActionButton from '../../../Library/AccordionRow/ActionButton';
import StatusCommitModalContainer from '../../../Library/Modal/StatusCommitModal/StatusCommitModalContainer';
import {
  BannerIdColumn,
  NameColumn,
  LetterGradeColumn,
  CalculatedGradeColumn,
  Subtitle1SC,
  getNameColumnWidth
} from './SharedSC';
import { getGradeOutput } from './GradeSummaryRow';
import {
  doDeleteAssessmentDelayedStudentGrade,
  doPutAssessmentDelayedStudentGrade,
  doPutAssessmentDelayedStudentStatusCommit,
  doClearUpdatedAssessment
} from '../../../../redux/actions/assessmentActions';

export const ResultCardRow = styled.div`
  padding: 12px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  position: relative;
`;

const StyledPublishedIcon = styled(LockOutlined)`
  && {
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    height: 100%;
    odisplay: flex;
    align-item: center;
    font-size: 1.25rem;
    color: ${props => props.theme.fontColors.dark};
  }
`;

const ActionsColumn = styled(({ isPublished, ...rest }) => <div {...rest} />)`
  && {
    margin-left: auto;
    width: 100px;
    height: 100%;
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    right: 0;
  }
`;

const RowButtons = styled.div`
  display: flex;
  wrap: nowrap;
  justify-content: flex-end;
  padding-right: 0.5rem;
`;

const getDelayedStudentActions = (
  dispatch,
  assessmentUuid,
  studentUuid,
  resultCardUuid,
  calculatedGrade,
  delayedStatusCommit,
  justificationMessage,
  handleCommitModalOpen
) => {
  const isGraded = Boolean(calculatedGrade);
  const isCommitted = delayedStatusCommit === 'COMMITTED';

  const gradeDelayedStudent = (assessmentUuid, studentUuid, resultCardUuid) =>
    dispatch(
      doPutAssessmentDelayedStudentGrade({
        assessmentUuid,
        studentUuid,
        resultCardUuid
      })
    );

  const ungradeDelayedStudent = (assessmentUuid, studentUuid, resultCardUuid) =>
    dispatch(
      doDeleteAssessmentDelayedStudentGrade({
        assessmentUuid,
        studentUuid,
        resultCardUuid
      })
    );

  const commitDelayedStudentGrade = (assessmentUuid, studentUuid) =>
    dispatch(
      doPutAssessmentDelayedStudentStatusCommit({
        assessmentUuid,
        studentUuid,
        delayedStatusCommit: 'COMMITTED',
        justificationMessage: null
      })
    );

  const uncommitDelayedStudentGrade = (
    assessmentUuid,
    studentUuid,
    justificationMessage
  ) =>
    dispatch(
      doPutAssessmentDelayedStudentStatusCommit({
        assessmentUuid,
        studentUuid,
        delayedStatusCommit: 'UNCOMMITTED',
        justificationMessage
      })
    );

  let delayedStudentActions = [];

  if (!isGraded) {
    delayedStudentActions = [
      {
        label: 'Grade This Student',
        onClick: () =>
          gradeDelayedStudent(assessmentUuid, studentUuid, resultCardUuid),
        shouldOpenOnClick: false,
        icon: 'assessment'
      }
    ];
  } else if (isGraded && !isCommitted) {
    delayedStudentActions = [
      {
        label: 'Ungrade This Student',
        onClick: () =>
          ungradeDelayedStudent(assessmentUuid, studentUuid, resultCardUuid),
        shouldOpenOnClick: false,
        icon: 'delete'
      },
      {
        label: 'Commit This Student',
        onClick: () => handleCommitModalOpen(),
        shouldOpenOnClick: false,
        icon: 'locked'
      }
    ];
  } else {
    delayedStudentActions = [
      {
        label: 'Uncommit This Student',
        onClick: () => handleCommitModalOpen(),
        shouldOpenOnClick: false,
        icon: 'unlock'
      }
    ];
  }

  return {
    delayedStudentActions,
    gradeDelayedStudent,
    ungradeDelayedStudent,
    commitDelayedStudentGrade,
    uncommitDelayedStudentGrade
  };
};

const DelayedGradeSummaryRow = ({
  resultCard,
  hideStudentNames,
  isPointsOnly
}) => {
  const dispatch = useDispatch();

  const {
    calculatedGrade,
    delayedStatusCommit,
    assessmentUuid,
    studentUuid,
    uuid: resultCardUuid
  } = resultCard;

  const thisGrade = getGradeOutput(resultCard, isPointsOnly);
  const [justificationMessage, setJustificationMessage] = useState('');
  const [isCommitModalOpen, setCommitModalOpen] = useState(false);

  const criticalFailure = thisGrade === '0.00';
  const isCommitted = delayedStatusCommit === 'COMMITTED';

  const { successfullyCommitted, successfullyUncommitted } = useSelector(
    state => state.assessmentStatusState,
    shallowEqual
  );

  const handleCommitModalOpen = () => {
    setCommitModalOpen(true);
  };

  const handleCommitModalClose = () => {
    setCommitModalOpen(false);
  };

  const handleJustificationChange = event => {
    setJustificationMessage(event.target.value);
  };

  const {
    delayedStudentActions,
    commitDelayedStudentGrade,
    uncommitDelayedStudentGrade
  } = getDelayedStudentActions(
    dispatch,
    assessmentUuid,
    studentUuid,
    resultCardUuid,
    calculatedGrade,
    delayedStatusCommit,
    justificationMessage,
    handleCommitModalOpen
  );

  const handleStatusCommitSubmit = () => {
    handleCommitModalClose();
    if (delayedStatusCommit === 'UNCOMMITTED') {
      commitDelayedStudentGrade(assessmentUuid, studentUuid);
    } else {
      uncommitDelayedStudentGrade(
        assessmentUuid,
        studentUuid,
        justificationMessage
      );
    }
  };

  useEffect(() => {
    if (successfullyCommitted || successfullyUncommitted) {
      dispatch(doClearUpdatedAssessment());
      setJustificationMessage('');
    }
  }, [dispatch, successfullyCommitted, successfullyUncommitted]);

  const nameColumnWidth = getNameColumnWidth(isPointsOnly, false);

  const currentStatusCommitType =
    delayedStatusCommit === 'COMMITTED' ? 'Uncommit' : 'Commit';

  const modalTitle = `${currentStatusCommitType} Delayed Student Grade`;

  const messageOverrides = {
    title: modalTitle,
    successCommitted: 'Successfully committed delayed student grade',
    successUncommitted: 'Successfully uncommitted delayed student grade',
    confirmCommitment:
      'Committing this delayed student grade is permanent and cannot be undone without school admin access priveleges and a justification message. Are you sure you want to proceed?',
    confirmUncommitment:
      'Uncommitting this delayed student grade will remove any previously calculated grade. This must be justified by a school admin. Are you sure you want to proceed?'
  };

  return (
    <ResultCardRow
      key={isPointsOnly ? resultCard.studentUuid : resultCard.uuid}
    >
      <StatusCommitModalContainer
        statusCommit={delayedStatusCommit}
        messages={messageOverrides}
        isModalOpen={isCommitModalOpen}
        handleModalClose={handleCommitModalClose}
        handleModalSuccess={undefined}
        handleSubmit={handleStatusCommitSubmit}
        justificationMessage={justificationMessage}
        handleJustificationChange={handleJustificationChange}
      />
      <BannerIdColumn>
        <Subtitle1SC criticalFailure={criticalFailure}>
          {resultCard.institutionId}
        </Subtitle1SC>
      </BannerIdColumn>
      <NameColumn columnWidth={nameColumnWidth}>
        <Subtitle1SC criticalFailure={criticalFailure}>
          {!hideStudentNames
            ? `${resultCard.lastName}, ${resultCard.firstName}`
            : `---`}
        </Subtitle1SC>
      </NameColumn>
      {isPointsOnly ? (
        <CalculatedGradeColumn>
          <Subtitle1SC criticalFailure={criticalFailure}>
            {thisGrade}
          </Subtitle1SC>
        </CalculatedGradeColumn>
      ) : (
        <>
          <LetterGradeColumn>
            <Subtitle1SC criticalFailure={criticalFailure}>
              {resultCard.letterGrade}
            </Subtitle1SC>
          </LetterGradeColumn>
          <CalculatedGradeColumn>
            <Subtitle1SC criticalFailure={criticalFailure}>
              {thisGrade === '--' ? thisGrade : `${thisGrade}%`}
            </Subtitle1SC>
          </CalculatedGradeColumn>
        </>
      )}

      {delayedStudentActions && (
        <ActionsColumn>
          <RowButtons>
            <ActionButton actions={delayedStudentActions} isRowOpen={false} />
          </RowButtons>
        </ActionsColumn>
      )}
      {isCommitted && <StyledPublishedIcon />}
    </ResultCardRow>
  );
};

DelayedGradeSummaryRow.propTypes = {
  hideStudentNames: PropTypes.bool,
  isPointsOnly: PropTypes.bool,
  resultCard: PropTypes.object
};

DelayedGradeSummaryRow.defaultProps = {
  hideStudentNames: false,
  isPointsOnly: false,
  resultCard: {}
};

export default DelayedGradeSummaryRow;
