export const GET_USERS_COHORT = 'GET_USERS_COHORT';
export const GET_USERS_COHORT_SUCCESS = 'GET_USERS_COHORT_SUCCESS';
export const GET_USERS_COHORT_ERROR = 'GET_USERS_COHORT_ERROR';

export const POST_USER_COHORT = 'POST_USER_COHORT';
export const POST_USER_COHORT_SUCCESS = 'POST_USER_COHORT_SUCCESS';
export const POST_USER_COHORT_ERROR = 'POST_USER_COHORT_ERROR';

export const GET_USER_COHORT_BY_USER_AND_COHORT =
  'GET_USER_COHORT_BY_USER_AND_COHORT';
export const GET_USER_COHORT_BY_USER_AND_COHORT_SUCCESS =
  'GET_USER_COHORT_BY_USER_AND_COHORT_SUCCESS';
export const GET_USER_COHORT_BY_USER_AND_COHORT_ERROR =
  'GET_USER_COHORT_BY_USER_AND_COHORT_ERROR';

export const POST_USER_COHORTS = 'POST_USER_COHORTS';
export const POST_USER_COHORTS_SUCCESS = 'POST_USER_COHORTS_SUCCESS';
export const POST_USER_COHORTS_ERROR = 'POST_USER_COHORTS_ERROR';
export const POST_USER_COHORT_RESET = 'POST_USER_COHORT_RESET';

export const PATCH_USER_COHORT_STATUS = 'PATCH_USER_COHORT_STATUS';
export const PATCH_USER_COHORT_STATUS_SUCCESS =
  'PATCH_USER_COHORT_STATUS_SUCCESS';
export const PATCH_USER_COHORT_STATUS_ERROR = 'PATCH_USER_COHORT_STATUS_ERROR';

export const DELETE_USER_COHORTS = 'DELETE_USER_COHORTS';
export const DELETE_USER_COHORTS_SUCCESS = 'DELETE_USER_COHORTS_SUCCESS';
export const DELETE_USER_COHORTS_ERROR = 'DELETE_USER_COHORTS_ERROR';

export const POST_STUDENT_CONVERSION = 'POST_STUDENT_CONVERSION';
export const POST_STUDENT_CONVERSION_SUCCESS =
  'POST_STUDENT_CONVERSION_SUCCESS';
export const POST_STUDENT_CONVERSION_ERROR = 'POST_STUDENT_CONVERSION_ERROR';

export const PATCH_STUDENT_STATUS = 'PATCH_STUDENT_STATUS';
export const PATCH_STUDENT_STATUS_SUCCESS = 'PATCH_STUDENT_STATUS_SUCCESS';
export const PATCH_STUDENT_STATUS_ERROR = 'PATCH_STUDENT_STATUS_ERROR';
export const DELETE_COHORT_STUDENTS = 'DELETE_COHORT_STUDENTS';
export const DELETE_COHORT_STUDENTS_SUCCESS = 'DELETE_COHORT_STUDENTS_SUCCESS';
export const DELETE_COHORT_STUDENTS_ERROR = 'DELETE_COHORT_STUDENTS_ERROR';

export const GET_COHORT_APPLICANTS_BY_STATUS =
  'GET_COHORT_APPLICANTS_BY_STATUS';
export const GET_COHORT_APPLICANTS_BY_STATUS_SUCCESS =
  'GET_COHORT_APPLICANTS_BY_STATUS_SUCCESS';
export const GET_COHORT_APPLICANTS_BY_STATUS_ERROR =
  'GET_COHORT_APPLICANTS_BY_STATUS_ERROR';

export const GET_SCHOOL_STUDENTS_BY_STATUS = 'GET_SCHOOL_STUDENTS_BY_STATUS';
export const GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS =
  'GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS';
export const GET_SCHOOL_STUDENTS_BY_STATUS_ERROR =
  'GET_SCHOOL_STUDENTS_BY_STATUS_ERROR';

export const DELETE_USER_COHORT = 'DELETE_USER_COHORT';
export const DELETE_USER_COHORT_SUCCESS = 'DELETE_USER_COHORT_SUCCESS';
export const DELETE_USER_COHORT_ERROR = 'DELETE_USER_COHORT_ERROR';

export const GET_APPLICANTS_BY_PROGRAM = 'GET_APPLICANTS_BY_PROGRAM';
export const GET_APPLICANTS_BY_PROGRAM_SUCCESS =
  'GET_APPLICANTS_BY_PROGRAM_SUCCESS';
export const GET_APPLICANTS_BY_PROGRAM_ERROR =
  'GET_APPLICANTS_BY_PROGRAM_ERROR';
