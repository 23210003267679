import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getCourse,
  getCoursesByFilters,
  getCoursesByDeptAy,
  getCourseByStudent,
  putCourse,
  postCourse,
  deleteCourse,
  putCourseLock
} from '../../api/courseApi';
import {
  doDeleteCourseError,
  doDeleteCourseSuccess,
  doGetCourseError,
  doGetCourseSuccess,
  doGetCoursesByDeptAyError,
  doGetCoursesByDeptAySuccess,
  doGetCoursesByStudentError,
  doGetCoursesByStudentSuccess,
  doGetCoursesError,
  doGetCoursesSuccess,
  doPostCourseError,
  doPostCourseSuccess,
  doPutCourseError,
  doPutCourseLockError,
  doPutCourseLockSuccess,
  doPutCourseSuccess,
  doSetIsLoading
} from '../actions/courseActions';

import {
  DELETE_COURSE,
  GET_COURSE,
  GET_COURSES,
  GET_COURSES_BY_DEPARTMENT_AY,
  GET_COURSE_BY_STUDENT,
  POST_COURSE,
  PUT_COURSE,
  PUT_COURSE_LOCK
} from '../actions-type';

function* handleGetCourse(action) {
  const { courseUuid } = action.payload;

  try {
    const result = yield call(getCourse, courseUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCourseSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCourseError(error));
  }
}

// TODO this also appears not to be used
function* handleGetCourses(action) {
  const { courseMasterUuid, academicYearUuid, termUuid } = action.payload;

  try {
    const result = yield call(
      getCoursesByFilters,
      courseMasterUuid,
      academicYearUuid,
      termUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCoursesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCoursesError(error));
  }
}

function* handleGetCoursesByDeptAy(action) {
  const { departmentUuid, academicYearUuid } = action.payload;

  try {
    const result = yield call(
      getCoursesByDeptAy,
      departmentUuid,
      academicYearUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCoursesByDeptAySuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCoursesByDeptAyError(error));
  }
}

function* handlePostCourse(action) {
  try {
    const result = yield call(postCourse, action.payload.course);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostCourseSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostCourseError(error));
  }
}

function* handlePutCourse(action) {
  const { course } = action.payload;

  try {
    const result = yield call(putCourse, course);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutCourseSuccess(payload));
      yield put(doSetIsLoading(false));
    } else {
      const payload = yield result.json();
      yield put(doSetIsLoading(false));
      throw payload;
    }
  } catch (error) {
    yield put(doPutCourseError(error));
    yield put(doSetIsLoading(false));
  }
}

function* handleDeleteCourse(action) {
  const { courseUuid } = action.payload;

  try {
    const result = yield call(deleteCourse, courseUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteCourseSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteCourseError(error));
  }
}

function* handlePutCourseLock(action) {
  try {
    const result = yield call(putCourseLock, action.payload.course);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutCourseLockSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutCourseLockError(error));
  }
}

function* handleGetCourseByStudent(action) {
  const { userUuid } = action.payload;

  try {
    const result = yield call(getCourseByStudent, userUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCoursesByStudentSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCoursesByStudentError(error));
  }
}

const course = [
  takeEvery(GET_COURSES, handleGetCourses),
  takeEvery(GET_COURSES_BY_DEPARTMENT_AY, handleGetCoursesByDeptAy),
  takeEvery(GET_COURSE, handleGetCourse),
  takeEvery(POST_COURSE, handlePostCourse),
  takeEvery(PUT_COURSE, handlePutCourse),
  takeEvery(DELETE_COURSE, handleDeleteCourse),
  takeEvery(PUT_COURSE_LOCK, handlePutCourseLock),
  takeEvery(GET_COURSE_BY_STUDENT, handleGetCourseByStudent)
];

export { course };
