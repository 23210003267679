import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { doGetCourseCollections } from '../../../../redux/actions/courseCollectionActions';
import { courseCollectionsSelector } from '../../../../redux/selectors/courseCollectionsSelectors';

import CohortCourseCollectionsList from './CohortCourseCollectionsList';

const CohortCourseCollectionsListContainer = () => {
  const dispatch = useDispatch();
  const { cohortUuid } = useParams();

  const courseCollections = useSelector(
    state => courseCollectionsSelector(state, cohortUuid),
    _.isEqual
  );

  useEffect(() => {
    dispatch(doGetCourseCollections(cohortUuid));
  }, [cohortUuid, dispatch]);

  return (
    <CohortCourseCollectionsList
      courseCollections={courseCollections}
      parentCohortUuid={cohortUuid}
    />
  );
};

export default CohortCourseCollectionsListContainer;
