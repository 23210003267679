import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';
import { Body1 } from '@xcomp/xcomp-design-library';
import { ReportIcon } from '@xcomp/xcomp-design-library/dist/icons';
import moment from 'moment';
import AccessTime from '@material-ui/icons/AccessTime';
import PersonOutline from '@material-ui/icons/PersonOutline';
import Overline from '../../../Library/Overline';

const PartPercentage = styled(Body1)`
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    font-size: 18px;
    color: ${props => props.theme.colors.grey[600]};
    margin-right: 8px;
  }
`;

const Layout = styled.div`
  position: relative;
  border-bottom: 2px solid ${props => props.theme.colors.grey[300]};
  border-left: 8px solid ${props => props.theme.colors.primary};
  padding: 1rem 0;
  > div {
    padding: 0 1rem;
  }

  &.selected {
    position: relative;
    z-index: 10;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: ${props => props.theme.colors.primary};
      opacity: 0.08;
    }
  }

  &:hover {
    cursor: pointer;
    background: rgba(155, 155, 155, 0.1);
  }
`;

const ItemDate = styled.div`
  font-size: 0.75rem;
  color: ${props => props.theme.fontColors.dark};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  &.no_dates {
    align-items: center;
  }
`;

const DateBlock = styled.div`
  width: 100%;
  text-align: center;
  .endDate {
    color: ${props => props.theme.fontColors.dark};
  }
`;

const DayDisplay = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  width: 100%;
`;

const MonthDisplay = styled.div`
  width: 100%;
  font-size: 0.625rem;
`;

const OverlineSC = styled(Overline)`
  margin: 0;
  line-height: 1.6;
  font-size: 0.75rem;
  font-weight: 400;
`;

const ItemTitle = styled.h4`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1rem;
  color: ${props => props.theme.fontColors.darker};
  margin: 0.25rem 0;
  font-weight: 400;
  width: 100%;
`;

const ItemSubtitle = styled.h5`
  font-size: 0.75rem;
  color: ${props => props.theme.fontColors.dark};
  margin: 0.25rem 0 0.25rem 0;
  font-weight: 400;
  width: 100%;

  svg {
    font-size: 1rem;
    vertical-align: bottom;
    margin-right: 0.5rem;
  }
`;

const LinkedItem = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

const Body1SC = styled(Body1)`
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
`;

const AssessmentCard = ({
  assessment,
  selectedItem,
  itemNumber,
  selectSyllabusItem
}) => {
  const isLinked = !!assessment.linked_encounter;
  const selected = selectedItem === assessment.uuid;
  const itemStartDate = assessment.startDate;
  const itemEndDate = assessment.endDate;
  const startDateMoment = moment(itemStartDate);
  const endDateMoment = moment(itemEndDate);
  const duration = endDateMoment.diff(startDateMoment, 'days');
  const multiDay = duration > 1;
  const itemTitle = isLinked ? `– ${assessment.linked_encounter.title}` : '';

  const responsibleFaculty = useMemo(
    () =>
      assessment.responsible_faculty
        ? `${assessment.responsible_faculty.firstName} ${assessment.responsible_faculty.lastName}`
        : 'Not assigned',
    [assessment.responsible_faculty]
  );

  const collectionGradePercentage = useMemo(
    () =>
      assessment?.collection_assessment?.weight
        ? `${assessment?.collection_assessment?.weight}% of ${
            assessment?.collection_assessment?.grade_collection?.title ||
            'Collection'
          } Grade`
        : 'N/A',
    [
      assessment?.collection_assessment?.grade_collection?.title,
      assessment?.collection_assessment?.weight
    ]
  );

  return (
    <Layout
      className={selected ? 'selected' : ''}
      onClick={() =>
        selectSyllabusItem(assessment.uuid, 'assessment', itemNumber)
      }
      itemType={assessment.type}
    >
      <Grid container spacing={4}>
        <Grid item sm={1} xs={1}>
          {!itemStartDate ? (
            <ItemDate className="no_dates">–</ItemDate>
          ) : (
            <ItemDate>
              <DateBlock>
                <DayDisplay>{startDateMoment.format('DD')}</DayDisplay>
                <MonthDisplay>
                  {startDateMoment.format('MMM').toUpperCase()}
                </MonthDisplay>
                {multiDay && (
                  <div className="endDate">
                    <span>–</span>
                    <DayDisplay>{endDateMoment.format('DD')}</DayDisplay>
                    <MonthDisplay>
                      {endDateMoment.format('MMM').toUpperCase()}
                    </MonthDisplay>
                  </div>
                )}
              </DateBlock>
            </ItemDate>
          )}
        </Grid>
        <Grid item sm={11} xs={11}>
          <PartPercentage>
            <ReportIcon />
            {collectionGradePercentage}
          </PartPercentage>
          <OverlineSC>
            {assessment.type} {(itemNumber + 1).toString().padStart(2, 0)}
            <span>
              &nbsp;
              {itemTitle}
            </span>
          </OverlineSC>

          <ItemTitle>{assessment.title}</ItemTitle>
          <ItemSubtitle>
            <AccessTime />
            {startDateMoment.format() !== 'Invalid date' && (
              <span>
                {startDateMoment.format('dddd h:mm a')}{' '}
                {assessment.scheduleType !== 'Multi' &&
                  ` – ${endDateMoment.format('h:mm a')}`}
              </span>
            )}
          </ItemSubtitle>

          <ItemSubtitle>
            <PersonOutline />
            {responsibleFaculty}
          </ItemSubtitle>
          {isLinked && (
            <LinkedItem>
              <OverlineSC>Linked Encounter</OverlineSC>
              <Body1SC>
                {get(assessment, 'linked_encounter.title', '---')}
              </Body1SC>
            </LinkedItem>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

AssessmentCard.propTypes = {
  assessment: PropTypes.object,
  selectedItem: PropTypes.string,
  itemNumber: PropTypes.number,
  selectSyllabusItem: PropTypes.func
};
AssessmentCard.defaultProps = {
  assessment: {},
  selectedItem: '',
  itemNumber: 1,
  selectSyllabusItem: undefined
};

export default AssessmentCard;
