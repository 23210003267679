import React, { useCallback, useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { TableContainer } from '@material-ui/core';

import { SelfAssessmentAccordion } from '../../../Reports/SelfAssessmentReportComponents';
import Remaining from './Remaining';
import Completed from './Completed';
import ColumnOne from './ColumnOne';
import DidacticAssessments from '../DidacticReport/DidacticAssessments';
import DidacticColumnOne from '../DidacticReport/DidacticColumnOne';

const Layout = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
`;

const NoAssessmentsContainer = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 16px;
  font-style: italic;
  padding: 50px 0;
`;

const PartContainer = styled.div`
  width: 100%;
`;
const PartInnerContainer = styled.div`
  padding: ${props => props.$isDidactic ? '20px 0' : '50px 0'};
`;

const AttemptsReport = ({ data, error, isLoading, isDidactic }) => {
  const themeContext = useContext(ThemeContext);
  const [rowOpen, setRowOpen] = useState([]);

  const handleRowOpen = useCallback(id => {
    setRowOpen(prev => {
      if (prev.includes(id)) {
        return prev.filter(row => row !== id);
      }
      return [...prev, id];
    });
  }, []);

  const handleRowClose = useCallback(id => {
    setRowOpen(prev => {
      return prev.filter(row => row !== id);
    });
  }, []);

  if (isLoading) {
    return (
      <Layout>
        <LoadingContainer>
          <LinearProgress />
        </LoadingContainer>
      </Layout>
    );
  }

  if (!data || !data.report) {
    return (
      <Layout>
        <LoadingContainer>
          <NoAssessmentsContainer>
            There are no assessments for the used filters
          </NoAssessmentsContainer>
        </LoadingContainer>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <LoadingContainer>
          <NoAssessmentsContainer>
            There was an error loading the data.
          </NoAssessmentsContainer>
        </LoadingContainer>
      </Layout>
    );
  }

  return (
    <TableContainer>
      {data.report.length === 0 && (
        <NoAssessmentsContainer>
          There are no assessments for the used filters
        </NoAssessmentsContainer>
      )}
      {data.report.map(academicYear => (
        <SelfAssessmentAccordion
          key={academicYear.uuid}
          expandWidth="66px"
          height="40px"
          isStatisticStudent={false}
          label={<div>{academicYear.name}</div>}
          $level="academicYear"
          $noPadding
          onRowOpen={() => handleRowOpen(academicYear.uuid)}
          onRowClose={() => handleRowClose(academicYear.uuid)}
          $background={
            rowOpen.includes(academicYear.uuid) && themeContext.colors.grey[400]
          }
          columnOne={isDidactic ? <DidacticColumnOne data={academicYear} /> : <ColumnOne data={academicYear} />}
        >
          {academicYear.terms.map((term, index) => (
            <SelfAssessmentAccordion
              key={term.uuid}
              expandWidth="66px"
              height="40px"
              isStatisticStudent={false}
              label={<div>{term.name}</div>}
              $level="part"
              $even={index % 2 === 0}
              backgroundSelected={themeContext.colors.grey[300]}
              $background={themeContext.colors.grey[300]}
              columnOne={isDidactic ? <DidacticColumnOne data={term} /> : <ColumnOne data={term} />}
            >
              {term.courses.map((course, index) => (
                <SelfAssessmentAccordion
                  key={course.uuid}
                  expandWidth="66px"
                  height="40px"
                  isStatisticStudent={false}
                  label={<div>{course.name}</div>}
                  $level="part"
                  $even={index % 2 === 0}
                  backgroundSelected={themeContext.colors.grey[200]}
                  $background={themeContext.colors.grey[200]}
                  columnOne={isDidactic ? <DidacticColumnOne data={course} /> : <ColumnOne data={course} />}
                >
                  {course.parts.map((part, index) => (
                    <SelfAssessmentAccordion
                      key={course.uuid}
                      expandWidth="66px"
                      height="40px"
                      isStatisticStudent={false}
                      label={<div>{part.name}</div>}
                      $level="part"
                      $even={index % 2 === 0}
                      backgroundSelected={themeContext.colors.grey[200]}
                      $background={themeContext.colors.grey[200]}
                      columnOne={isDidactic ? <DidacticColumnOne data={part} /> : <ColumnOne data={part} />}
                    >
                      <PartContainer>
                        {part.noAssessments ? (
                          <NoAssessmentsContainer>
                            There are no assessments for this part
                          </NoAssessmentsContainer>
                        ) : (
                          <PartInnerContainer $isDidactic={isDidactic}>
                            {isDidactic
                              ?
                                <DidacticAssessments assessments={part.didacticAssessments} />
                              :
                                <>
                                  {part.remainingAssessments.length > 0 && (
                                    <Remaining
                                      assessments={part.remainingAssessments}
                                      completionPercentage={part.remainingPercentage}
                                    />
                                  )}

                                  {part.completedAssessments.length > 0 && (
                                    <Completed
                                      assessments={part.completedAssessments}
                                      completionPercentage={part.completedPercentage}
                                    />
                                  )}
                                </>
                            }

                          </PartInnerContainer>
                        )}
                      </PartContainer>
                    </SelfAssessmentAccordion>
                  ))}
                </SelfAssessmentAccordion>
              ))}
            </SelfAssessmentAccordion>
          ))}
        </SelfAssessmentAccordion>
      ))}
    </TableContainer>
  );
};

AttemptsReport.propTypes = {
  data: PropTypes.object,
  error: PropTypes.object,
  isLoading: PropTypes.bool,
  isDidactic: PropTypes.bool,
};

AttemptsReport.defaultProps = {
  data: {},
  error: null,
  isLoading: false,
  isDidactic: false,
};

export default AttemptsReport;
