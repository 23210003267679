import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import StatusCommitModal from './StatusCommitModal';
import { doStatusCommitModalReset } from '../../../../../../../redux/actions/assessmentActions';
import { doClearCreated } from '../../../../../../../redux/actions/generalActions';

const StatusCommitModalContainer = ({
  isModalOpen,
  justificationMessage,
  handleModalClose,
  handleModalSuccess,
  handleSubmit,
  handleJustificationChange,
  statusCommit
}) => {
  const dispatch = useDispatch();

  const { successfullyCommitted } = useSelector(
    state => state.gradingStatusState,
    shallowEqual
  );

  useEffect(() => {
    if (!isModalOpen) {
      dispatch(doStatusCommitModalReset());
    }
  }, [dispatch, isModalOpen]);

  useEffect(() => {
    if (isModalOpen && successfullyCommitted) {
      handleModalClose();
      dispatch(doClearCreated());
    }
  }, [dispatch, isModalOpen, successfullyCommitted, handleModalClose]);

  return (
    <StatusCommitModal
      statusCommit={statusCommit}
      justificationMessage={justificationMessage}
      isModalOpen={isModalOpen}
      successfullyCommitted={successfullyCommitted}
      handleModalClose={handleModalClose}
      handleModalSuccess={handleModalSuccess}
      handleJustificationChange={handleJustificationChange}
      handleSubmit={handleSubmit}
    />
  );
};

StatusCommitModalContainer.propTypes = {
  statusCommit: PropTypes.oneOf(['COMMITTED', 'UNCOMMITTED']),
  isModalOpen: PropTypes.bool,
  justificationMessage: PropTypes.string,
  handleModalClose: PropTypes.func,
  handleJustificationChange: PropTypes.func,
  handleModalSuccess: PropTypes.func,
  handleSubmit: PropTypes.func
};
StatusCommitModalContainer.defaultProps = {
  statusCommit: false,
  justificationMessage: '',
  isModalOpen: false,
  handleModalClose: undefined,
  handleJustificationChange: undefined,
  handleModalSuccess: undefined,
  handleSubmit: undefined
};

export default StatusCommitModalContainer;
