import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2 } from '@xcomp/xcomp-design-library';
import { Panel, PanelHeader } from '../../Library/Layout';

const SummaryContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 32px;
`;

const PanelSC = styled(Panel)`
  margin-top: 48px;
`;

const PanelHeaderSC = styled(PanelHeader)`
  padding-left: 32px;
`;

const DataContainer = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 30px;
`;

const DataLabel = styled.div`
  text-align: start;
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  font-weight: 300;
  padding-bottom: 8px;
`;

const DataValue = styled.div`
  text-align: start;
  color: ${props => props.theme.fontColors.darker};
  font-size: 18px;
  font-weight: 400;
`;

const CohortApplicantSummaryData = ({
  reportSummary,
  reportSummaryMapping
}) => {
  return (
    <PanelSC>
      <PanelHeaderSC>
        <Heading2>Summary Data</Heading2>
      </PanelHeaderSC>
      <SummaryContainer>
        {reportSummaryMapping.map(mapping => (
          <DataContainer key={mapping.value}>
            <DataLabel>{mapping.label}</DataLabel>
            <DataValue>{reportSummary[mapping.value] ?? '--'}</DataValue>
          </DataContainer>
        ))}
      </SummaryContainer>
    </PanelSC>
  );
};

CohortApplicantSummaryData.propTypes = {
  reportSummary: PropTypes.object,
  reportSummaryMapping: PropTypes.array
};

CohortApplicantSummaryData.defaultProps = {
  reportSummary: {},
  reportSummaryMapping: []
};

export default CohortApplicantSummaryData;
