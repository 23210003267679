import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { doGetDepartment } from '../../../redux/actions/departmentActions';
import { doGetUserDepartment } from '../../../redux/actions/userDepartmentActions';
import { userRoleSelectorByDepartment } from '../../../redux/selectors/userRolesSelectors';
import { departmentSelector } from '../../../redux/selectors/departmentsSelectors';

import DepartmentRole from './DepartmentRole';

class DepartmentRoleContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterStatus: {
        active: false,
        complete: false
      },
      userRoles: []
    };

    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this._loadRoles = this._loadRoles.bind(this);
    this._filterUserByStatus = this._filterUserByStatus.bind(this);
  }

  componentDidMount() {
    const {
      onGetDepartment,
      onGetUsersDepartment,
      match,
      userRoles
    } = this.props;
    const { departmentUuid } = match.params;

    onGetDepartment(departmentUuid);
    onGetUsersDepartment(departmentUuid);

    if (userRoles.length > 0) {
      this._loadRoles();
    }
  }

  componentDidUpdate(prevProps) {
    const { userRoles } = this.props;

    if (userRoles !== prevProps.userRoles) {
      this._loadRoles();
    }
  }

  handleFilterSelection(selection) {
    if (selection === 'active') {
      this.setState(prevState => {
        const toggleSelection = !prevState.filterStatus.active;

        return {
          ...prevState,
          filterStatus: {
            ...prevState.filterStatus,
            active: toggleSelection
          }
        };
      });
    }

    if (selection === 'complete') {
      this.setState(prevState => {
        const toggleSelection = !prevState.filterStatus.complete;

        return {
          ...prevState,
          filterStatus: {
            ...prevState.filterStatus,
            complete: toggleSelection
          }
        };
      });
    }
  }

  _filterUserByStatus() {
    const { userRoles, filterStatus } = this.state;

    const { active, complete } = filterStatus;

    const selectedUser = [];
    if (filterStatus.active) {
      const activeSelected = _.filter(
        userRoles,
        user => user.status === 'active'
      );

      selectedUser.push(activeSelected);
    }

    if (filterStatus.complete) {
      const completeSelected = _.filter(
        userRoles,
        user => user.status === 'complete'
      );

      selectedUser.push(completeSelected);
    }

    if (!active && !complete) {
      selectedUser.push(userRoles);
    }

    return _.flatten(selectedUser);
  }

  _loadRoles() {
    this.setState(prevState => {
      const { userRoles } = this.props;

      return {
        ...prevState,
        userRoles
      };
    });
  }

  render() {
    const { department } = this.props;
    const { filterStatus } = this.state;

    const filterStudents = this._filterUserByStatus();

    return (
      <DepartmentRole
        department={department}
        userRoles={filterStudents}
        filterStatus={filterStatus}
        handleFilterSelection={this.handleFilterSelection}
      />
    );
  }
}

DepartmentRoleContainer.propTypes = {
  match: PropTypes.object,
  onGetDepartment: PropTypes.func,
  onGetUsersDepartment: PropTypes.func,
  userRoles: PropTypes.array,
  department: PropTypes.object
};

DepartmentRoleContainer.defaultProps = {
  match: {},
  onGetDepartment: undefined,
  onGetUsersDepartment: undefined,
  userRoles: [],
  department: {}
};

const mapStateToProps = (state, props) => {
  return {
    userRoles: userRoleSelectorByDepartment(state, props),
    department: departmentSelector(state, props.match.params.departmentUuid)
  };
};

const mapDispatchToProps = dispatch => ({
  onGetDepartment: departmentUuid => dispatch(doGetDepartment(departmentUuid)),
  onGetUsersDepartment: departmentUuid =>
    dispatch(doGetUserDepartment(departmentUuid))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentRoleContainer);
