import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import Grid from '@material-ui/core/Grid';
import {
  ContainerResize,
  ContainerPage,
  ContainerActions,
  ElevateLow
} from '../Library/Layout';
import Search from '../Library/Search';
import Header from '../Library/Header';
import SchoolActions from './SchoolActions';

const SearchRow = styled.div`
  padding: 20px;
`;

export default function SchoolManagement({ schools, handleSearch }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <ContainerResize>
      <Header
        title="School Management"
        subtitle="Manage, add and remove schools"
        backOn={false}
      />
      <ContainerActions>
        <Link to="/institution-management/school/add">
          <ButtonPrimary>Add School</ButtonPrimary>
        </Link>
      </ContainerActions>
      <ContainerPage paddingTop="50px">
        <ElevateLow>
          <Grid container direction="row">
            <Grid item xs={4}>
              <SearchRow>
                <Search onChange={handleSearch} />
              </SearchRow>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>School</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {schools
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(school => (
                  <Fragment key={school.uuid}>
                    <TableRow>
                      <TableCell>{school.schoolName}</TableCell>
                      <TableCell>
                        <SchoolActions schoolUuid={school.uuid} />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={schools.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
}

SchoolManagement.propTypes = {
  schools: PropTypes.array,
  handleSearch: PropTypes.func
};

SchoolManagement.defaultProps = {
  schools: [],
  handleSearch: undefined
};
