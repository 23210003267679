import React, { Suspense } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import useSWR from 'swr';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import AttemptsReport from '../../CohortManagement/CohortStudentTranscripts/AttemptsReport/AttemptsReport';

import { fetcher } from '../../../helpers/utilities';

const QualitativeReportContainer = ({
  selectedAssessmentType,
  selectedAssessmentTechnique,
  student,
  isDidactic
}) => {
  const { cohortUuid } = useParams();

  const urlParams = new URLSearchParams({
    assessmentType: selectedAssessmentType,
    assessmentTechnique: selectedAssessmentTechnique,
    userUuid: student.uuid,
    cohortUuid,
    isDidactic
  });

  const { error, data, isLoading } = useSWR(
    `/api/student-attempts-report?${urlParams.toString()}`,
    fetcher,
    {
      throwOnError: false,
      revalidateOnFocus: false
    }
  );

  return (
    <Suspense fallback={<LinearProgress />}>
      <AttemptsReport data={data} error={error} isLoading={isLoading} isDidactic={isDidactic} />
    </Suspense>
  );
};

QualitativeReportContainer.displayName = 'QualitativeReportContainer';
QualitativeReportContainer.propTypes = {
  selectedAssessmentType: PropTypes.array,
  selectedAssessmentTechnique: PropTypes.array,
  student: PropTypes.object.isRequired,
  isDidactic: PropTypes.bool,
};

QualitativeReportContainer.defaultProps = {
  selectedAssessmentType: [],
  selectedAssessmentTechnique: [],
  isDidactic: false,
};

export default QualitativeReportContainer;
