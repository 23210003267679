import { Model, attr } from 'redux-orm';
import { GET_TRANSCRIPT_SUCCESS } from '../actions-type';

class ApplicantCognitiveRank extends Model {
  static reducer(action, ApplicantCognitiveRank, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_TRANSCRIPT_SUCCESS: {
        const applicantCognitiveRank = [...payload.applicantCognitiveRank];
        applicantCognitiveRank.forEach(rank =>
          ApplicantCognitiveRank.upsert(rank)
        );
        break;
      }

      default:
        return session;
    }
  }
}

ApplicantCognitiveRank.fields = {
  uuid: attr(),
  cohortRankRelative: attr(),
  cohortRankPotential: attr(),
  cumulativeCredits: attr(),
  averageCreditScore: attr(),
  letterGrade: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  applicantUuid: attr(),
  cohortUuid: attr(),
  applicantCognitiveReportTypeUuid: attr(),
  classificationUuid: attr()
};

ApplicantCognitiveRank.modelName = 'ApplicantCognitiveRank';
ApplicantCognitiveRank.options = {
  idAttribute: 'uuid'
};

export default ApplicantCognitiveRank;
