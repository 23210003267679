import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body1, Caption, Heading3 } from '@xcomp/xcomp-design-library';
import Overline from '../../Library/Overline';
import LinkOutline from '../../Library/LinkOutline';

import {
  gradeCalculationDataSourceMap,
  partStatusCommitMap,
  partStatusGradeMap
} from '../../../helpers/constants';
import PartFaculty from './PartFaculty';

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  border-left: 6px solid ${props => props.theme.colors.grey[500]};
  background-color: ${props => props.theme.colors.grey[100]};
  margin-bottom: 72px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const PartLeft = styled.div`
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  padding: 24px;
`;

const PartRight = styled.div`
  display: flex;
  position: relative;
  width: 50%;
  flex-wrap: wrap;
  padding: 24px;
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
`;

const DetailsSection = styled.div`
  width: 100%;
`;

const TermDates = styled(Caption)`
  margin: 0;
`;

const PartLabel = styled(Caption)`
  margin-top: 24px;
  margin-bottom: 0;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }
`;

const PartValue = styled(Body1)`
  width: 100%;
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
`;

const StaffDisplay = styled(Body1)`
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
`;

const Status = styled(Overline)`
  margin: 0;
  line-height: 1.6;
  font-size: 0.75rem;
  font-weight: 400;
`;

const PartFieldDisplay = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
`;

const PartActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: auto;

  button {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Title = styled(Heading3)`
  margin-top: 24px;
  margin-bottom: 12px;
`;

const getPartStatusString = status =>
  status === 'Committed' ? 'Approved' : status;

export default function Part({
  courseUuid,
  userUuid,
  part,
  partIndex,
  termDatesDisplay
}) {
  const {
    part_directors_assigned,
    part_grade_settings,
    status,
    title,
    uuid,
    weight
  } = part;

  const listOfDirectors = part_directors_assigned
    ? part_directors_assigned.map(
        director => `${director.firstName} ${director.lastName}`
      )
    : [];

  const pathSyllabus = `/program-management/cohort/syllabus-part?userUuid=${userUuid}&courseUuid=${courseUuid}&partUuid=${uuid}`;
  const partNumber = `Part ${partIndex.toString().padStart(2, '0')}`;

  const partGradeDataSource = part_grade_settings?.dataSource
    ? gradeCalculationDataSourceMap[part_grade_settings.dataSource]
    : 'Not Set';

  const partTitle = weight ? `${title} - ${weight}%` : title;
  return (
    <Layout>
      <PartLeft>
        <DetailsSection>
          <Status>
            {partNumber} - {getPartStatusString(status)}
          </Status>
          <TermDates>{termDatesDisplay}</TermDates>
          <Title>{partTitle}</Title>
          <PartLabel>Directors</PartLabel>
          <StaffDisplay>
            {listOfDirectors.length > 0
              ? listOfDirectors
              : 'No assigned Director(s)'}
          </StaffDisplay>
          <PartLabel>Faculty</PartLabel>
          <PartFaculty partUuid={part?.uuid} allDepartmentFaculty={part.allDepartmentFaculty} />
        </DetailsSection>
      </PartLeft>

      <PartRight>
        <PartFieldDisplay>
          <PartLabel>Status</PartLabel>
          <PartValue>
            {partStatusGradeMap[part.statusGrade]} /{' '}
            {partStatusCommitMap[part.statusCommit]}
          </PartValue>
          <PartLabel>Grade Calculation</PartLabel>
          <PartValue>{partGradeDataSource}</PartValue>
        </PartFieldDisplay>
        <PartActions>
          <LinkOutline to={pathSyllabus}>View Syllabus</LinkOutline>
        </PartActions>
      </PartRight>
    </Layout>
  );
}

Part.propTypes = {
  courseUuid: PropTypes.string,
  userUuid: PropTypes.string,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  termDatesDisplay: PropTypes.string
};

Part.defaultProps = {
  courseUuid: undefined,
  userUuid: undefined,
  part: { part_directors_assigned: [], part_faculty_assigned: [] },
  partIndex: 1,
  termDatesDisplay: ''
};
