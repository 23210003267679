import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';

export const AssessmentHeaderContainer = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  padding-right: ${props => props.paddingRight};
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: flex-start;
`;

const AssessmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 90px;
  width: calc(100% - 90px);
`;

const AssessmentHeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
`;

const TitleTooltip = styled(Tooltip)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AssessmentHeaderData = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  font-size: 14px;
  font-weight: 500;
  min-width: 400px;
`;

const ProgressBar = styled.div`
  width: calc(100% - 90px);
  margin-left: 90px;
`;

const AssessmentHeaderSub = styled.div`
  min-width: ${props => props.width};
  margin-right: ${props => props.marginRight};
  color: ${props => props.theme.fontColors.darker};
  text-align: right;
`;

const OpportunityReportStatisticBlockDetail = ({
  isLoading,
  panelLabel,
  studentAssessmentBlockRelativeScore,
  studentAssessmentBlockOpportunityCount
}) => {
  return isLoading ? (
    <ProgressBar>
      <LinearProgress />
    </ProgressBar>
  ) : (
    <AssessmentHeaderContainer paddingRight="44px">
      <AssessmentHeader>
        <AssessmentHeaderInfo>
          <TitleTooltip title={panelLabel} placement="top-start">
            <div>{panelLabel}</div>
          </TitleTooltip>
        </AssessmentHeaderInfo>
        <AssessmentHeaderData>
          <AssessmentHeaderSub marginRight="16px" width="140px">
            {studentAssessmentBlockOpportunityCount}
          </AssessmentHeaderSub>
          <AssessmentHeaderSub marginRight="16px" width="100px">
            {studentAssessmentBlockRelativeScore}
          </AssessmentHeaderSub>
          <AssessmentHeaderSub marginRight="8px" width="120px" />
        </AssessmentHeaderData>
      </AssessmentHeader>
    </AssessmentHeaderContainer>
  );
};

OpportunityReportStatisticBlockDetail.propTypes = {
  isLoading: PropTypes.bool,
  panelLabel: PropTypes.string,
  studentAssessmentBlockOpportunityCount: PropTypes.number,
  studentAssessmentBlockRelativeScore: PropTypes.string
};

OpportunityReportStatisticBlockDetail.defaultProps = {
  isLoading: false,
  panelLabel: '',
  studentAssessmentBlockOpportunityCount: 0,
  studentAssessmentBlockRelativeScore: ''
};

export default OpportunityReportStatisticBlockDetail;
