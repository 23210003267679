import React, { useReducer, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { assessmentSelector } from '../../../../redux/selectors/assessmentSelectors';
import {
  allFieldsAreValid,
  validateDecimalStringField
} from '../../../../helpers/validation/validateGeneric';
import { doGetAssessment } from '../../../../redux/actions/assessmentActions';
import { doPutAssessmentGradingTechnique } from '../../../../redux/actions/gradingTechniqueActions';
import { doClearCreated } from '../../../../redux/actions/courseCollectionActions';
import { useUnsavedChanges } from '../../../Library/Modal/UnsavedChangesModal/useUnsavedChanges';
import GradeCalculationSettings from './GradeCalculationSettings';
import {
  gradingTechniqueReducer,
  initialGradingTechniqueState
} from '../../../Library/GradingTechniqueForm/gradingTechniqueState';

export default function GradeCalculationSettingsContainer({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { assessmentUuid } = match.params;

  const [state, gradingTechniqueDispatch] = useReducer(
    gradingTechniqueReducer,
    initialGradingTechniqueState
  );

  const assessment = useSelector(
    state => assessmentSelector(state, assessmentUuid),
    _.isEqual
  );

  const successfullyUpdatedGradingTechnique = useSelector(
    state => state.formSuccessState.successfullyUpdatedGradingTechnique
  );

  const currentGradingTechnique = _.get(assessment, 'grading_technique', {});
  const section = _.get(assessment, 'part.section', null);
  const course = _.get(section, 'course', null);
  const courseNumber = _.get(course, 'course_master.courseNumber', '');
  const courseTitle = _.get(course, 'course_master.title', '');
  const sectionNumber = _.get(section, 'sectionNumber', '')
    .toString()
    .padStart(2, 0);
  const partNumber = _.get(assessment, 'part.partNumber', '')
    .toString()
    .padStart(2, 0);
  const assessmentType = _.get(assessment, 'assessment_type.name');
  const partUuid = _.get(assessment, 'part.uuid', '');
  const courseUuid = _.get(course, 'uuid', '');
  const sectionUuid = _.get(section, 'uuid', '');

  const clearForm = useCallback(() => {
    gradingTechniqueDispatch({
      type: 'clearForm'
    });
    history.push(
      `/syllabus-management/view/part/${partUuid}&courseUuid=${courseUuid}&sectionUuid=${sectionUuid}&partNumber=${partNumber}&sectionNumber=${sectionNumber}`
    );
  }, [partUuid, sectionUuid, partNumber, sectionNumber, courseUuid, history]);

  const [
    isUnsavedChangesModalOpen,
    unsavedChanges,
    handleModalOpen,
    handleModalClose,
    setUnsavedChange,
    removeUnsavedChange,
    handleProceed
  ] = useUnsavedChanges(clearForm);

  useEffect(() => {
    dispatch(doGetAssessment(assessmentUuid));
  }, [dispatch, assessmentUuid]);

  useEffect(() => {
    if (successfullyUpdatedGradingTechnique) {
      dispatch(doClearCreated());
      removeUnsavedChange('gradingTechnique');
    }
  }, [dispatch, removeUnsavedChange, successfullyUpdatedGradingTechnique]);

  const setExistingState = ({
    uuid,
    gradingTechnique,
    maximumRevusInput,
    maximumMethodCalculated,
    calculatedHighScore,
    lowScoreInput,
    lowScoreCalculated,
    calculatedLowScore,
    curvedHighGrade,
    curvedFailingGrade,
    scaledHighScore,
    scaledLowScore
  }) => {
    gradingTechniqueDispatch({
      type: 'setExistingGradingTechnique',
      payload: {
        uuid,
        gradingTechnique,
        maximumRevusInput,
        maximumMethodCalculated,
        calculatedHighScore,
        lowScoreInput,
        lowScoreCalculated,
        calculatedLowScore,
        curvedHighGrade,
        curvedFailingGrade,
        scaledHighScore,
        scaledLowScore
      }
    });
  };

  useEffect(() => {
    if (currentGradingTechnique.uuid) {
      setExistingState({
        uuid: currentGradingTechnique.uuid,
        gradingTechnique: currentGradingTechnique.gradingTechnique,
        maximumRevusInput: currentGradingTechnique.maximumRevus,
        maximumMethodCalculated:
          currentGradingTechnique.maximumMethodCalculated,
        lowScoreInput: currentGradingTechnique.lowScore,
        lowScoreCalculated: currentGradingTechnique.lowScoreCalculated,
        calculatedLowScore: currentGradingTechnique.calculatedLowScore,
        calculatedHighScore: currentGradingTechnique.calculatedHighScore,
        curvedHighGrade: currentGradingTechnique.curvedHighGrade,
        curvedFailingGrade: currentGradingTechnique.curvedFailingGrade,
        scaledHighScore: currentGradingTechnique.scaledHighScore,
        scaledLowScore: currentGradingTechnique.scaledLowScore
      });
    }
  }, [
    currentGradingTechnique.uuid,
    currentGradingTechnique.gradingTechnique,
    currentGradingTechnique.maximumRevus,
    currentGradingTechnique.maximumMethodCalculated,
    currentGradingTechnique.lowScore,
    currentGradingTechnique.lowScoreCalculated,
    currentGradingTechnique.curvedHighGrade,
    currentGradingTechnique.curvedFailingGrade,
    currentGradingTechnique.scaledHighScore,
    currentGradingTechnique.scaledLowScore,
    currentGradingTechnique.calculatedLowScore,
    currentGradingTechnique.calculatedHighScore
  ]);

  const {
    uuid,
    gradingTechnique,
    maximumRevusInput,
    maximumMethodCalculated,
    calculatedHighScore,
    lowScoreInput,
    calculatedLowScore,
    lowScoreCalculated,
    curvedHighGrade,
    curvedFailingGrade,
    scaledHighScore,
    scaledLowScore,
    showValidationErrors
  } = state;

  const setValidationErrors = ({
    curvedFailingGrade,
    gradingTechnique,
    maximumMethodCalculated,
    maximumRevusInput,
    lowScoreInput,
    lowScoreCalculated,
    scaledHighScore,
    scaledLowScore
  }) => {
    const errorObject = {};
    switch (gradingTechnique) {
      case 'points': {
        errorObject.pointOnlyError = { invalid: false };
        break;
      }
      case 'dividedCohortHigh':
      case 'dividedAssessmentPoints': {
        errorObject.maximumRevusError = maximumMethodCalculated
          ? { invalid: false }
          : validateDecimalStringField(maximumRevusInput);
        break;
      }
      case 'curved': {
        errorObject.curvedFailingGradeError =
          validateDecimalStringField(curvedFailingGrade);
        break;
      }
      case 'scaled': {
        errorObject.scaledHighScoreError =
          validateDecimalStringField(scaledHighScore);
        errorObject.lowScoreError = lowScoreCalculated
          ? { invalid: false }
          : validateDecimalStringField(lowScoreInput);
        errorObject.scaledLowScoreError =
          validateDecimalStringField(scaledLowScore);
        break;
      }

      default:
        break;
    }
    return errorObject;
  };

  const buildGradingTechniquePayload = (
    uuid,
    gradingTechnique,
    maximumRevus,
    maximumMethodCalculated,
    lowScore,
    lowScoreCalculated,
    curvedFailingGrade,
    scaledHighScore,
    scaledLowScore
  ) => {
    let payload = {};
    switch (gradingTechnique) {
      case 'points': {
        payload = {
          ...initialGradingTechniqueState,
          uuid,
          gradingTechnique
        };
        break;
      }
      case 'dividedCohortHigh':
      case 'dividedAssessmentPoints': {
        payload = {
          ...initialGradingTechniqueState,
          uuid,
          gradingTechnique,
          maximumRevus: maximumMethodCalculated ? null : maximumRevus,
          maximumMethodCalculated
        };
        break;
      }
      case 'curved': {
        payload = {
          ...initialGradingTechniqueState,
          uuid,
          curvedHighGrade: '100',
          curvedFailingGrade,
          gradingTechnique,
          maximumMethodCalculated: true,
          lowScoreCalculated: true
        };
        break;
      }
      case 'scaled': {
        payload = {
          ...initialGradingTechniqueState,
          uuid,
          gradingTechnique,
          maximumRevus: maximumMethodCalculated ? null : maximumRevus,
          maximumMethodCalculated,
          lowScore: lowScoreCalculated ? null : lowScore,
          lowScoreCalculated,
          scaledHighScore,
          scaledLowScore
        };
        break;
      }
      default:
        break;
    }
    return payload;
  };

  // handlers
  const handleChangeRadio = event => {
    const selectedOption = event.target.value;
    setUnsavedChange('gradingTechnique');
    switch (selectedOption) {
      case 'points': {
        gradingTechniqueDispatch({
          type: 'setPointsOnly',
          payload: {
            selectedRadio: selectedOption
          }
        });
        break;
      }
      case 'dividedAssessmentPoints': {
        gradingTechniqueDispatch({
          type: 'setDividedAssessmentPoints',
          payload: {
            selectedRadio: selectedOption
          }
        });
        break;
      }
      case 'dividedCohortHigh': {
        gradingTechniqueDispatch({
          type: 'setDividedCohortHigh',
          payload: {
            selectedRadio: selectedOption
          }
        });
        break;
      }
      case 'curved': {
        gradingTechniqueDispatch({
          type: 'setCurved',
          payload: {
            selectedRadio: selectedOption
          }
        });
        break;
      }
      case 'scaled': {
        gradingTechniqueDispatch({
          type: 'setScaled',
          payload: {
            selectedRadio: selectedOption
          }
        });
        break;
      }
      default:
        console.log('error: ', selectedOption);
    }
  };

  const handleGradingTechniqueChange = event => {
    event.stopPropagation();
    const { name, value, checked } = event.target;
    setUnsavedChange('gradingTechnique');
    switch (name) {
      case 'maximumRevusInput':
        gradingTechniqueDispatch({
          type: 'setMaximumRevusInput',
          payload: {
            maximumRevusInput: value
          }
        });
        break;
      case 'maximumMethodCalculated':
        gradingTechniqueDispatch({
          type: 'setMaximumMethodCalculated',
          payload: {
            maximumMethodCalculated: checked
          }
        });
        break;
      case 'lowScoreInput':
        gradingTechniqueDispatch({
          type: 'setLowScoreInput',
          payload: {
            lowScoreInput: value
          }
        });
        break;
      case 'lowScoreCalculated':
        gradingTechniqueDispatch({
          type: 'setLowScoreCalculated',
          payload: {
            lowScoreCalculated: checked
          }
        });
        break;
      case 'curvedHighGrade':
        gradingTechniqueDispatch({
          type: 'setCurvedHighGrade',
          payload: {
            curvedHighGrade: value
          }
        });
        break;
      case 'curvedFailingGrade':
        gradingTechniqueDispatch({
          type: 'setCurvedFailingGrade',
          payload: {
            curvedFailingGrade: value
          }
        });
        break;
      case 'scaledHighScore':
        gradingTechniqueDispatch({
          type: 'setScaledHighScore',
          payload: {
            scaledHighScore: value
          }
        });
        break;
      case 'scaledLowScore':
        gradingTechniqueDispatch({
          type: 'setScaledLowScore',
          payload: {
            scaledLowScore: value
          }
        });
        break;
      default:
        break;
    }
  };

  const handleSubmitGradingTechnique = () => {
    const newValidationErrors = setValidationErrors({
      curvedFailingGrade,
      gradingTechnique,
      maximumRevusInput,
      maximumMethodCalculated,
      lowScoreInput,
      lowScoreCalculated,
      scaledHighScore,
      scaledLowScore
    });

    if (allFieldsAreValid(newValidationErrors)) {
      const gradingTechniquePayload = buildGradingTechniquePayload(
        uuid,
        gradingTechnique,
        maximumRevusInput,
        maximumMethodCalculated,
        lowScoreInput,
        lowScoreCalculated,
        curvedFailingGrade,
        scaledHighScore,
        scaledLowScore
      );
      gradingTechniqueDispatch({
        type: 'setShowValidationErrors',
        payload: { showValidationErrors: false }
      });
      dispatch(
        doPutAssessmentGradingTechnique(
          { ...gradingTechniquePayload },
          assessmentUuid
        )
      );
    } else {
      gradingTechniqueDispatch({
        type: 'setShowValidationErrors',
        payload: { showValidationErrors: true }
      });
    }
  };
  const { selectedRadio } = state;

  return (
    <GradeCalculationSettings
      selectedRadio={selectedRadio}
      assessmentTitle={assessment.title}
      curvedHighGrade={curvedHighGrade}
      curvedFailingGrade={curvedFailingGrade}
      courseNumber={courseNumber}
      courseTitle={courseTitle}
      sectionNumber={sectionNumber}
      partNumber={partNumber}
      assessmentType={assessmentType}
      handleChangeRadio={handleChangeRadio}
      handleSubmitGradingTechnique={handleSubmitGradingTechnique}
      handleGradingTechniqueChange={handleGradingTechniqueChange}
      lowScoreInput={lowScoreInput}
      lowScoreCalculated={lowScoreCalculated}
      calculatedLowScore={calculatedLowScore}
      maximumRevusInput={maximumRevusInput}
      maximumMethodCalculated={maximumMethodCalculated}
      calculatedHighScore={calculatedHighScore}
      showValidationErrors={showValidationErrors}
      scaledHighScore={scaledHighScore}
      scaledLowScore={scaledLowScore}
      isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
      unsavedChanges={unsavedChanges}
      handleModalOpen={handleModalOpen}
      handleModalClose={handleModalClose}
      handleProceed={handleProceed}
    />
  );
}

GradeCalculationSettingsContainer.propTypes = {
  match: PropTypes.object
};

GradeCalculationSettingsContainer.defaultProps = {
  match: {}
};
