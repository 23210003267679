import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  getTotalNumberOfUngradedParts,
  getTotalNumberOfGradedParts,
  getTotalNumberOfUncommittedParts,
  getTotalNumberOfCommittedParts,
  getTotalNumberOfParts
} from '../../../../../helpers/sections';
import Overline from '../../../../Library/Overline';

const SyllabusStatusSection = styled.div`
  width: 100%;
  margin: 32px 0 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
`;

const StatusColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const StatusTypeHeader = styled(Overline)`
  margin: 0 0 12px;
  line-height: 1.6;
  font-size: 0.75rem;
  font-weight: 400;
`;

const StatusChip = styled(({ isComplete, ...rest }) => <Overline {...rest} />)`
  display: flex;
  width: 175px;
  flex-wrap: nowrap;
  background: ${props =>
    props.isComplete ? props.theme.colors.status.warning : props.theme.colors.grey[400]};
  color: ${props => props.theme.fontColors.darker};
  padding: 3px 0;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 16px;
  aling-items: center;
  justify-content: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemStatusSummary = ({ section, className }) => {
  const totalNumberOfUngradedParts = getTotalNumberOfUngradedParts(section);

  const totalNumberOfGradedParts = getTotalNumberOfGradedParts(section);
  const totalNumberOfUncommittedParts = getTotalNumberOfUncommittedParts(
    section
  );

  const totalNumberOfCommittedParts = getTotalNumberOfCommittedParts(section);

  const totalNumberOfParts = getTotalNumberOfParts(section);

  return (
    <SyllabusStatusSection className={className}>
      <StatusColumn>
        <StatusTypeHeader>Parts - Grading</StatusTypeHeader>
        <StatusChip
          isComplete={
            totalNumberOfUngradedParts !== 0 &&
            totalNumberOfUngradedParts === totalNumberOfParts
          }
        >
          {`${totalNumberOfUngradedParts} / ${totalNumberOfParts} Not Graded`}
        </StatusChip>
        <StatusChip
          isComplete={
            totalNumberOfGradedParts !== 0 &&
            totalNumberOfGradedParts === totalNumberOfParts
          }
        >
          {`${totalNumberOfGradedParts} / ${totalNumberOfParts} Graded`}
        </StatusChip>
      </StatusColumn>

      <StatusColumn>
        <StatusTypeHeader>Parts - Commitment</StatusTypeHeader>
        <StatusChip
          isComplete={
            totalNumberOfUncommittedParts !== 0 &&
            totalNumberOfUncommittedParts === totalNumberOfParts
          }
        >
          {`${totalNumberOfUncommittedParts} / ${totalNumberOfParts} Not Committed`}
        </StatusChip>
        <StatusChip
          isComplete={
            totalNumberOfCommittedParts !== 0 &&
            totalNumberOfCommittedParts === totalNumberOfParts
          }
        >
          {`${totalNumberOfCommittedParts} / ${totalNumberOfParts} Committed`}
        </StatusChip>
      </StatusColumn>
    </SyllabusStatusSection>
  );
};

ItemStatusSummary.propTypes = {
  section: PropTypes.object,
  className: PropTypes.string
};

ItemStatusSummary.defaultProps = {
  section: {},
  className: ''
};

export default ItemStatusSummary;
