import { Model, attr, fk } from 'redux-orm';
import {
  GET_PART_USER_GROUPS_SUCCESS,
  POST_USER_GROUPS_SUCCESS,
  PUT_GROUP_RANKINGS_SUCCESS,
  DELETE_GROUP_SUCCESS,
  DELETE_USER_GROUP_SUCCESS,
  PUT_STUDENT_GROUP_RANKINGS_SUCCESS,
  GET_STUDENT_PART_USER_GROUPS_SUCCESS,
  RESET_PART_GROUP_SUMMARY
} from '../actions-type';

class UserGroup extends Model {
  static reducer(action, UserGroup, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_STUDENT_PART_USER_GROUPS_SUCCESS:
      case GET_PART_USER_GROUPS_SUCCESS: {
        const { userGroups } = payload;
        userGroups.forEach(userGroup => UserGroup.upsert(userGroup));
        break;
      }
      case POST_USER_GROUPS_SUCCESS: {
        const { userGroups } = payload;
        userGroups.forEach(userGroup => UserGroup.upsert(userGroup));
        break;
      }
      case PUT_STUDENT_GROUP_RANKINGS_SUCCESS:
      case PUT_GROUP_RANKINGS_SUCCESS: {
        const { userGroups } = payload;
        userGroups.forEach(userGroup => UserGroup.upsert(userGroup));
        break;
      }
      case DELETE_GROUP_SUCCESS: {
        const userGroupsToDelete = UserGroup.all().filter(
          userGroup => userGroup.groupUuid === null
        );
        userGroupsToDelete.delete();
        break;
      }
      case DELETE_USER_GROUP_SUCCESS: {
        const { userUuid, groupUuid } = payload;
        const userGroupsToDelete = UserGroup.all().filter(
          userGroup =>
            userGroup.groupUuid === groupUuid && userGroup.userUuid === userUuid
        );
        userGroupsToDelete.delete();
        break;
      }
      case RESET_PART_GROUP_SUMMARY: {
        UserGroup.all().delete();
        break;
      }
      default:
        return session;
    }
  }
}

UserGroup.fields = {
  uuid: attr(),
  userUuid: fk({
    to: 'User',
    as: 'user',
    relatedName: 'user_groups'
  }),
  groupUuid: fk({
    to: 'Group',
    as: 'group',
    relatedName: 'user_groups'
  }),
  createdAt: attr(),
  updatedAt: attr()
};

UserGroup.modelName = 'UserGroup';

UserGroup.options = {
  idAttribute: 'uuid'
};

export default UserGroup;
