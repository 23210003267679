import {
  GET_COURSE_MASTER_BY_DEPARTMENT,
  GET_COURSE_MASTER_BY_DEPARTMENT_SUCCESS,
  GET_COURSE_MASTER_BY_DEPARTMENT_ERROR,
  GET_COURSE_MASTER,
  GET_COURSE_MASTER_SUCCESS,
  GET_COURSE_MASTER_ERROR,
  POST_COURSE_MASTER,
  POST_COURSE_MASTER_SUCCESS,
  POST_COURSE_MASTER_ERROR,
  PUT_COURSE_MASTER,
  PUT_COURSE_MASTER_SUCCESS,
  PUT_COURSE_MASTER_ERROR,
  DELETE_COURSE_MASTER,
  DELETE_COURSE_MASTER_SUCCESS,
  DELETE_COURSE_MASTER_ERROR
} from '../actions-type';

const doGetCoursesMasterByDepartment = departmentUuid => ({
  type: GET_COURSE_MASTER_BY_DEPARTMENT,
  payload: {
    departmentUuid
  }
});

const doGetCoursesMasterByDepartmentSuccess = result => ({
  type: GET_COURSE_MASTER_BY_DEPARTMENT_SUCCESS,
  payload: result
});

const doGetCoursesMasterByDepartmentError = error => ({
  type: GET_COURSE_MASTER_BY_DEPARTMENT_ERROR,
  payload: error
});

const doGetCourseMaster = courseMasterUuid => ({
  type: GET_COURSE_MASTER,
  payload: {
    courseMasterUuid
  }
});

const doGetCourseMasterSuccess = result => ({
  type: GET_COURSE_MASTER_SUCCESS,
  payload: result
});

const doGetCourseMasterError = error => ({
  type: GET_COURSE_MASTER_ERROR,
  payload: error
});

const doPostCourseMaster = courseMaster => ({
  type: POST_COURSE_MASTER,
  payload: {
    courseMaster
  }
});

const doPostCourseMasterSuccess = result => ({
  type: POST_COURSE_MASTER_SUCCESS,
  payload: result
});

const doPostCourseMasterError = error => ({
  type: POST_COURSE_MASTER_ERROR,
  payload: error
});

const doPutCourseMaster = courseMaster => ({
  type: PUT_COURSE_MASTER,
  payload: {
    courseMaster
  }
});

const doPutCourseMasterSuccess = result => ({
  type: PUT_COURSE_MASTER_SUCCESS,
  payload: result
});

const doPutCourseMasterError = error => ({
  type: PUT_COURSE_MASTER_ERROR,
  payload: error
});

const doDeleteCourseMaster = courseMasterUuid => ({
  type: DELETE_COURSE_MASTER,
  payload: {
    courseMasterUuid
  }
});

const doDeleteCourseMasterSuccess = result => ({
  type: DELETE_COURSE_MASTER_SUCCESS,
  payload: result
});

const doDeleteCourseMasterError = error => ({
  type: DELETE_COURSE_MASTER_ERROR,
  payload: error
});

export {
  doGetCoursesMasterByDepartment,
  doGetCoursesMasterByDepartmentSuccess,
  doGetCoursesMasterByDepartmentError,
  doGetCourseMaster,
  doGetCourseMasterSuccess,
  doGetCourseMasterError,
  doPostCourseMaster,
  doPostCourseMasterSuccess,
  doPostCourseMasterError,
  doPutCourseMaster,
  doPutCourseMasterSuccess,
  doPutCourseMasterError,
  doDeleteCourseMaster,
  doDeleteCourseMasterSuccess,
  doDeleteCourseMasterError
};
