import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useGesture } from '@use-gesture/react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundIllustration from '@xcomp/xcomp-design-library/dist/illustrations/custom/NotFoundIllustration';
import { CircularProgress, Dialog } from '@material-ui/core';
import styled, { ThemeContext } from 'styled-components';
import CohortAssessmentGridModalHeader from './CohortAssessmentGridModalHeader';
import {
  ASSESSMENT_GRID_MODAL_ID,
  DataCellValueTypes,
  getGestures
} from '../reportUtils';
import AssessmentTable from './Table/AssessmentTable';
import { doSetAssessmentGridZoom } from '../../../redux/actions/assessmentGridActions';

const ScrollableContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
  background: ${props => props.theme.colors.grey[200]};
`;

const NotFoundWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;

  & > p {
    font-size: 22px;
    font-weight: 500;
  }
`;

const CohortAssessmentGridModal = ({
  startDate,
  endDate,
  cohortUuid,
  selectStartDate,
  selectEndDate,
  cohortStartDate,
  cohortEndDate,
  students,
  summaryRows,
  totalFirstAttemptPassRate
}) => {
  const [showTotals, setShowTotals] = useState(true);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isAnonymized, setIsAnonymized] = useState(true);
  const [dataCellValueType, setDataCellValueType] = useState(
    DataCellValueTypes.date
  );
  const [reportType, setReportType] = useState('pass');
  const themeContext = useContext(ThemeContext);

  const assessmentCollections = useSelector(
    state => state.cohortAssessmentReportState.assessmentCollections
  );
  const assessmentBlocks = useSelector(
    state => state.cohortAssessmentReportState.assessmentBlocks
  );
  const isLoading = useSelector(
    state => state.cohortAssessmentReportState.isLoading
  );

  const dispatch = useDispatch();

  const bind = useGesture(
    getGestures(dispatch, doSetAssessmentGridZoom, ASSESSMENT_GRID_MODAL_ID),
    {
      pinch: {
        eventOptions: {
          passive: false
        }
      }
    }
  );


  return (
    <Dialog tabIndex="0" open={true} fullScreen onClose={() => {}}>
      <CohortAssessmentGridModalHeader
        isExpanded={isExpanded}
        toggleExpanded={setIsExpanded}
        filteredStudents={filteredStudents}
        setFilteredStudents={setFilteredStudents}
        students={students}
        isAnonymized={isAnonymized}
        assessments={isExpanded ? assessmentBlocks : assessmentCollections}
        filteredAssessments={filteredAssessments}
        setFilteredAssessments={setFilteredAssessments}
        setIsAnonymized={setIsAnonymized}
        showTotals={showTotals}
        setShowTotals={setShowTotals}
        dataCellValueType={dataCellValueType}
        setDataCellValueType={setDataCellValueType}
        reportType={reportType}
        setReportType={setReportType}
        startDate={startDate}
        endDate={endDate}
        selectStartDate={selectStartDate}
        selectEndDate={selectEndDate}
        cohortStartDate={cohortStartDate}
        cohortEndDate={cohortEndDate}
        toggleValues={Object.values(DataCellValueTypes)}
        downloadURL={`/api/cohort/${cohortUuid}/assessment-grid-download?reportType=${reportType}&isAnonymized=${isAnonymized}&isExpanded=${isExpanded}&cohortStartDate=${cohortStartDate}&startDate=${startDate}&endDate=${endDate}&filteredStudents=${filteredStudents.filter(s => s.uuid).map(s => s.uuid)}&filteredAssessments=${filteredAssessments.filter(a => a.uuid).map(a => a.uuid)}`}
      />

      {students.length === 0 ||
      (assessmentCollections.length === 0 && assessmentBlocks.length === 0) ? (
        <NotFoundWrapper>
          {!isLoading ? (
            <>
              <NotFoundIllustration
                dotsColor={themeContext.colors.primaryShades[450]}
                bgColor={themeContext.colors.primaryShades[200]}
                outlineColor={themeContext.colors.primary}
              />
              <p>
                There were no students and/or assessments found for this cohort.
                Please refresh or try again later.
              </p>
            </>
          ) : (
            <CircularProgress size="100px" />
          )}
        </NotFoundWrapper>
      ) : (
        <div style={{ height: 'calc(100% - 65px)' }}>
          <div
            id={ASSESSMENT_GRID_MODAL_ID}
            style={{ paddingLeft: 15, paddingTop: 15, zoom: 1, height: '100%' }}
            {...bind()}
          >
            <ScrollableContainer>
              <AssessmentTable
                noOfBlocks={assessmentBlocks.length}
                assessments={
                  isExpanded ? assessmentBlocks : assessmentCollections
                }
                students={students}
                isAnonymized={isAnonymized}
                dataCellValueType={dataCellValueType}
                reportType={reportType}
                cohortStartDate={cohortStartDate}
                isComputedDataStatic={true}
                isExpanded={isExpanded}
                summaryRows={summaryRows}
                filteredStudentUuids={filteredStudents.map(fs => fs.uuid)}
                filteredAssessmentUuids={filteredAssessments.map(fa => fa.uuid)}
                totalFirstAttemptPassRate={totalFirstAttemptPassRate}
              />
            </ScrollableContainer>
          </div>
        </div>
      )}
    </Dialog>
  );
};

CohortAssessmentGridModal.propTypes = {
  cohortUuid: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  selectStartDate: PropTypes.func.isRequired,
  selectEndDate: PropTypes.func.isRequired,
  cohortStartDate: PropTypes.string,
  cohortEndDate: PropTypes.string,
  students: PropTypes.array,
  summaryRows: PropTypes.object,
  totalFirstAttemptPassRate: PropTypes.number.isRequired
};

CohortAssessmentGridModal.defaultProps = {
  cohortUuid: null,
  cohortStartDate: '',
  cohortEndDate: '',
  students: [],
  summaryRows: {}
};

export default CohortAssessmentGridModal;
