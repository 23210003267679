import React from 'react';
import styled, { css } from 'styled-components';
import { TableDataText } from '../CollectionReportSC';

const ColorScaleLegend = styled.div`
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid ${props => props.theme.colors.grey[300]};
  border-radius: 4px;
  height: 100%;
`;

const ColorScaleItem = styled(({ bgColor, ...rest }) => <div {...rest} />)`
  width: 60px;
  height: 100%;

  ${props =>
    props.bgColor &&
    props.bgColor === 'a' &&
    css`
      background-color: ${props => props.theme.colors.primaryShades[450]};
    `};

  ${props =>
    props.bgColor &&
    props.bgColor === 'b' &&
    css`
      background-color: ${props => props.theme.colors.status.success};
    `};

  ${props =>
    props.bgColor &&
    props.bgColor === 'c' &&
    css`
      background-color: ${props => props.theme.colors.green[400]};
    `};

  ${props =>
    props.bgColor &&
    props.bgColor === 'd' &&
    css`
      background-color: ${props => props.theme.colors.green[200]};
    `};

  ${props =>
    props.bgColor &&
    props.bgColor === 'e' &&
    css`
      background-color: ${props => props.theme.colors.yellow[600]};
    `};
`;

const StdDevLegend = () => {
  return (
    <ColorScaleLegend>
      <ColorScaleItem bgColor="a">
        <TableDataText fontColor="a">-5, -4</TableDataText>
      </ColorScaleItem>
      <ColorScaleItem bgColor="b">
        <TableDataText fontColor="b">-3, -2</TableDataText>
      </ColorScaleItem>
      <ColorScaleItem bgColor="c">
        <TableDataText fontColor="c">-1, 0, 1</TableDataText>
      </ColorScaleItem>
      <ColorScaleItem bgColor="d">
        <TableDataText fontColor="d">2, 3</TableDataText>
      </ColorScaleItem>
      <ColorScaleItem bgColor="e">
        <TableDataText fontColor="e">4, 5</TableDataText>
      </ColorScaleItem>
    </ColorScaleLegend>
  );
};

StdDevLegend.propTypes = {};

StdDevLegend.defaultProps = {};

export default StdDevLegend;
