import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const encountersSelector = createSelector(
  state => state,
  (_, partUuid) => partUuid,
  (redux, partUuid) => {
    const encounterSelectorORM = ormCreateSelector(orm.Encounter);
    const encounters = encounterSelectorORM(redux).filter(
      encounter => encounter.partUuid === partUuid
    );

    return encounters;
  }
);

export const encounterSelector = createSelector(
  state => state,
  (_, encounterUuid) => encounterUuid,
  (redux, encounterUuid) => {
    const encounterSelectorORM = ormCreateSelector(orm.Encounter);
    const encounter = encounterSelectorORM(redux, encounterUuid) || {};
    return encounter;
  }
);

export const selectEncounter = ormCreateSelector(orm.Encounter);
