import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';

import { ButtonOutline, ButtonDelete } from '@xcomp/xcomp-design-library';
import {
  ItemLayout,
  ItemLeft,
  ItemRight,
  ItemType,
  ItemDetails,
  ItemDetail,
  DetailsList,
  DetailsListItem,
  Title,
  Faculty,
  Body1Dark,
  LinkedItem,
  OverlineSC
} from '../../Library/SyllabusComponents';
import { assessmentSelector } from '../../../redux/selectors/assessmentSelectors';

import ItemDatesBlock from '../ItemDatesBlock';
import LearningObjectivesList from './LearningObjectivesList';
import ItemDetailViewModal from '../ItemDetailViewModal';
import { doPostCalculateEncounterObjectives } from '../../../redux/actions/encounterActions';
import ObjectiveInvalidScoresModal from '../ObjectiveInvalidScoresModal';

const CalcButtonLayout = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: auto;

  button {
    width: 100%;
  }
`;

const calculateLOButton = (onClick, disabled) => {
  return (
    <CalcButtonLayout>
      {disabled ? (
        <Tooltip
          title="Close and commit this assessment to calculate learning objectives."
          placement="bottom"
        >
          <div>
            <ButtonOutline onClick={onClick} disabled>
              Calculate Learning Objectives
            </ButtonOutline>
          </div>
        </Tooltip>
      ) : (
        <ButtonOutline onClick={onClick}>
          Calculate Learning Objectives
        </ButtonOutline>
      )}
    </CalcButtonLayout>
  );
};

export default function SyllabusLinkedEncounter({ encounter, itemNumber }) {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState('');

  const handleLearningObjectivesModalOpen = () =>
    setIsModalOpen('learningObjectives');
  const handleModalClose = () => setIsModalOpen('');

  const linkedAssessment = useSelector(
    state => assessmentSelector(state, encounter.linkedAssessmentUuid),
    _.isEqual
  );

  const objectiveInvalidRows = useSelector(
    state => state.fetchErrorState.objectiveInvalidRows
  );

  const startDate = linkedAssessment.startDate
    ? moment(linkedAssessment.startDate)
    : null;
  const endDate = linkedAssessment.endDate
    ? moment(linkedAssessment.endDate)
    : null;

  const faculty = `${_.get(
    linkedAssessment,
    'responsible_faculty.firstName',
    ''
  )} ${_.get(linkedAssessment, 'responsible_faculty.lastName', '')}`;

  const onCalculateLearningObjectives = () =>
    dispatch(doPostCalculateEncounterObjectives(encounter.uuid));

  const hasCalculatedLOs =
    encounter.objectives && encounter.objectives.length > 0;

  const isCommitted = linkedAssessment.statusCommit === 'Commit';

  return (
    <ItemLayout>
      <ItemLeft borderColor={themeContext.colors.secondary}>
        <ItemType>
          Encounter {itemNumber.toString().padStart(2, 0)} ●{' '}
          {_.get(encounter, 'encounter_type.name', '')}
        </ItemType>

        <Title>{encounter.title}</Title>

        <ItemDatesBlock startDate={startDate} endDate={endDate} />

        <ItemDetails>
          <DetailsList>
            <DetailsListItem>
              <Faculty />
              <ItemDetail>{faculty}</ItemDetail>
            </DetailsListItem>
          </DetailsList>
          <LinkedItem>
            <OverlineSC>Linked Assessment</OverlineSC>
            <Body1Dark>{_.get(linkedAssessment, 'title', '---')}</Body1Dark>
          </LinkedItem>
        </ItemDetails>
      </ItemLeft>

      <ItemRight>
        <LearningObjectivesList
          objectives={encounter.objectives}
          noObjectivesMessage={
            isCommitted
              ? 'The assessment linked to this encounter has been committed. Click the button below to generate learning objectives for this encounter.'
              : 'Linked assessment has not yet been committed. Calculated learning objectives will be shown here after the assessment is committed.'
          }
          onViewMore={
            hasCalculatedLOs ? handleLearningObjectivesModalOpen : undefined
          }
          maxHeight={hasCalculatedLOs ? '285px' : '170px'}
          hasLabel
          isLinked
        />
        {hasCalculatedLOs
          ? undefined
          : calculateLOButton(onCalculateLearningObjectives, !isCommitted)}
        {objectiveInvalidRows && objectiveInvalidRows.encounterUuid === encounter.uuid &&
          <ButtonDelete onClick={() => setOpenErrorModal(true)}>
            See Invalid Scores
          </ButtonDelete>
        }
        <ItemDetailViewModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          objectives={encounter.objectives}
        />
        {openErrorModal &&
          <ObjectiveInvalidScoresModal
            handleModalClose={() => setOpenErrorModal(false)}
            errors={objectiveInvalidRows}
          />
        }
      </ItemRight>
    </ItemLayout>
  );
}

SyllabusLinkedEncounter.propTypes = {
  encounter: PropTypes.object,
  itemNumber: PropTypes.number.isRequired
};

SyllabusLinkedEncounter.defaultProps = {
  encounter: {}
};
