export const GET_PART_GROUPS = 'GET_PART_GROUPS';
export const GET_PART_GROUPS_SUCCESS = 'GET_PART_GROUPS_SUCCESS';
export const GET_PART_GROUPS_ERROR = 'GET_PART_GROUPS_ERROR';

export const POST_GROUP = 'POST_GROUP';
export const POST_GROUP_SUCCESS = 'POST_GROUP_SUCCESS';
export const POST_GROUP_ERROR = 'POST_GROUP_ERROR';

export const PUT_GROUP = 'PUT_GROUP';
export const PUT_GROUP_SUCCESS = 'PUT_GROUP_SUCCESS';
export const PUT_GROUP_ERROR = 'PUT_GROUP_ERROR';

export const PUT_GROUP_RANKINGS = 'PUT_GROUP_RANKINGS';
export const PUT_GROUP_RANKINGS_SUCCESS = 'PUT_GROUP_RANKINGS_SUCCESS';
export const PUT_GROUP_RANKINGS_ERROR = 'PUT_GROUP_RANKINGS_ERROR';

export const PUT_STUDENT_GROUP_RANKINGS = 'PUT_STUDENT_GROUP_RANKINGS';
export const PUT_STUDENT_GROUP_RANKINGS_SUCCESS =
  'PUT_STUDENT_GROUP_RANKINGS_SUCCESS';
export const PUT_STUDENT_GROUP_RANKINGS_ERROR =
  'PUT_STUDENT_GROUP_RANKINGS_ERROR';

export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';
