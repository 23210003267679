import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Button } from '@material-ui/core';
import { Heading2 } from '@xcomp/xcomp-design-library';
import { Panel, PanelHeader } from '../../../Library/Layout';
import FormSelectError from '../../../Library/FormSelectError';
import { validateSelectField } from '../../../../helpers/validation/validateGeneric';

const FilterContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
`;

const FilterBody = styled(Grid)`
  padding-left: 30px;
`;

const PanelHeaderSC = styled(PanelHeader)`
  padding-left: 32px;
`;

const ButtonSC = styled(Button)`
  && {
    margin-bottom: 16px;
  }
`;

const CohortApplicantPhysicalSkillsFilters = ({
  onApplyReportType,
  onSelectTranscriptExam,
  onSelectTranscriptSkill,
  onSelectTranscriptSource,
  transcriptExam,
  transcriptExamDisabled,
  transcriptExamOptions,
  transcriptReportTypeError,
  transcriptSkill,
  transcriptSkillDisabled,
  transcriptSkillOptions,
  transcriptSource,
  transcriptSourceOptions
}) => {
  return (
    <Panel>
      <PanelHeaderSC>
        <Heading2>Physical Skills Filters</Heading2>
      </PanelHeaderSC>
      <FilterContainer>
        <FilterBody container spacing={2}>
          <Grid item sm={4}>
            <FormSelectError
              label="Transcript Source"
              name="transcriptSourceSelect"
              options={transcriptSourceOptions}
              value={transcriptSource}
              handleChange={onSelectTranscriptSource}
              hasErrors={transcriptReportTypeError}
              handleValidation={validateSelectField}
            />
          </Grid>
          <Grid item sm={4}>
            <FormSelectError
              label="Skill"
              name="filterBySelect"
              value={transcriptSkill}
              options={transcriptSkillOptions}
              handleChange={onSelectTranscriptSkill}
              disabled={transcriptSkillDisabled}
              handleValidation={validateSelectField}
            />
          </Grid>
          <Grid item sm={4}>
            <FormSelectError
              label="Exam"
              name="filterBySelect"
              options={transcriptExamOptions}
              value={transcriptExam}
              handleChange={onSelectTranscriptExam}
              disabled={transcriptExamDisabled}
              handleValidation={validateSelectField}
            />
          </Grid>
        </FilterBody>
        <Grid container justifyContent="flex-end" alignItems="center">
          <ButtonSC
            color="primary"
            variant="contained"
            onClick={onApplyReportType}
          >
            Apply
          </ButtonSC>
        </Grid>
      </FilterContainer>
    </Panel>
  );
};

CohortApplicantPhysicalSkillsFilters.propTypes = {
  transcriptSkill: PropTypes.string,
  transcriptSkillDisabled: PropTypes.bool,
  transcriptSkillOptions: PropTypes.array,
  transcriptSource: PropTypes.string,
  transcriptSourceOptions: PropTypes.array,
  onApplyReportType: PropTypes.func,
  onSelectTranscriptSkill: PropTypes.func,
  onSelectTranscriptSource: PropTypes.func,
  transcriptExam: PropTypes.string,
  transcriptExamOptions: PropTypes.array,
  transcriptExamDisabled: PropTypes.bool,
  transcriptReportTypeError: PropTypes.bool,
  onSelectTranscriptExam: PropTypes.func
};
CohortApplicantPhysicalSkillsFilters.defaultProps = {
  transcriptSkill: '',
  transcriptSkillDisabled: true,
  transcriptSkillOptions: [],
  transcriptSource: '',
  transcriptSourceOptions: [],
  onApplyReportType: undefined,
  onSelectTranscriptSkill: undefined,
  onSelectTranscriptSource: undefined,
  transcriptExam: '',
  transcriptExamOptions: [],
  transcriptExamDisabled: true,
  transcriptReportTypeError: false,
  onSelectTranscriptExam: undefined
};
export default CohortApplicantPhysicalSkillsFilters;
