import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Body1 } from '@xcomp/xcomp-design-library';

import CompetencyCollectionRowContainer from './CompetencyCollectionRowContainer';

const Layout = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`;

const CompetencyCollectionsList = ({ competencyCollections }) => (
  <Layout>
    {competencyCollections && competencyCollections.length > 0 ? (
      <>
        {competencyCollections.map(collection => (
          <CompetencyCollectionRowContainer
            key={collection.uuid}
            competencyCollection={collection}
          />
        ))}
      </>
    ) : (
      <Body1>
        There are currently no competency collections in this program.
      </Body1>
    )}
  </Layout>
);

CompetencyCollectionsList.propTypes = {
  competencyCollections: PropTypes.array.isRequired
};

export default CompetencyCollectionsList;
