import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DocumentIcon,
  ReportIcon
} from '@xcomp/xcomp-design-library/dist/icons';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import FormSelectError from '../../Library/FormSelectError';

import {
  Column,
  FormSectionHeaderSubtitle,
  TextFieldWithError,
  CancelButton,
  SubmitButton
} from '../../Library/FormComponents';
import {
  validateStringLength,
  validateSelectField
} from '../../../helpers/validation/validateGeneric';
import { optionsScoreType } from '../../../helpers/options';
import { convertNullToString } from '../../../helpers/utilities';
import {
  handleChangeRadio,
  handleGradingTechniqueChange
} from '../../Library/GradingTechniqueForm/gradingTechniqueState';
import GradingTechniqueForm from '../../Library/GradingTechniqueForm/GradingTechniqueForm';

const FormSectionHeader = styled.div`
  && {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
      margin-right: 8px;
    }
  }
`;
const FormSectionPadding = styled.div`
  padding: 24px;
`;
const GradingTechniqueFormSectionPadding = styled.div`
  padding: 0 24px 24px 24px;
`;
const FormSectionHeaderSubtitleSC = styled(FormSectionHeaderSubtitle)``;

const ButtonsColumn = styled(Column)`
  && {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
`;

const Layout = styled.div`
  && {
    margin: 12px 0;
  }
`;

const FormSelectErrorSC = styled(({ paddingRight, ...rest }) => (
  <FormSelectError {...rest} />
))`
  && {
    margin-bottom: 24px;
    padding-right: ${props =>
      props.paddingRight ? props.paddingRight : '0px'};
  }
`;

const TextFieldWithErrorSC = styled(TextFieldWithError)`
  && {
    margin-top: 0;
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ProgramAssessmentMasterForm = ({
  programAssessment,
  showValidationErrors,
  programAssessmentDispatch,
  optionsAssessmentTypes,
  optionsAssessmentAssociations,
  onSubmit,
  onCancel,
  buttonText,
  unsavedChanges,
  setUnsavedChange,
  gradingTechnique,
  gradingTechniqueDispatch
}) => {
  const { title, scoreType, assessmentTypeUuid, assessmentAssociationUuid } =
    programAssessment;

  const someUuid = uuidv4();

  const validateTitle = () => validateStringLength(title, 100, true);

  const {
    curvedFailingGrade,
    lowScoreInput,
    lowScoreCalculated,
    calculatedLowScore,
    maximumRevusInput,
    calculatedHighScore,
    scaledHighScore,
    scaledLowScore,
    selectedRadio
  } = gradingTechnique;

  const setUnsavedProgramAssessmentChange = () =>
    setUnsavedChange('programAssessment');

  const handleChangeRadioFunc = event =>
    handleChangeRadio(
      event,
      gradingTechniqueDispatch,
      setUnsavedProgramAssessmentChange
    );

  const handleGradingTechniqueChangeFunc = event =>
    handleGradingTechniqueChange(
      event,
      gradingTechniqueDispatch,
      setUnsavedProgramAssessmentChange
    );

  const handleTitleChange = event => {
    setUnsavedChange('programAssessment');
    programAssessmentDispatch({
      type: 'setTitle',
      payload: {
        title: event.target.value
      }
    });
  };

  const handleAssessmentTypeChange = event => {
    setUnsavedChange('programAssessment');
    programAssessmentDispatch({
      type: 'setAssessmentType',
      payload: {
        assessmentTypeUuid: event.target.value
      }
    });
  };

  const handleAssessmentAssociationChange = event => {
    setUnsavedChange('programAssessment');
    programAssessmentDispatch({
      type: 'setAssessmentAssociation',
      payload: {
        assessmentAssociationUuid: event.target.value
      }
    });
  };

  const handleScoreTypeChange = event => {
    setUnsavedChange('programAssessment');
    programAssessmentDispatch({
      type: 'setScoreType',
      payload: {
        scoreType: event.target.value
      }
    });
  };

  useEffect(() => {
    const fakeEvent = {
      target: {
        value: 'dividedAssessmentPoints'
      }
    };
    if (gradingTechnique.gradingTechnique !== 'dividedAssessmentPoints')
      handleChangeRadio(fakeEvent, gradingTechniqueDispatch, undefined);
  }, [gradingTechniqueDispatch, gradingTechnique]);

  const formHasChanges = Boolean(unsavedChanges.programAssessment);

  return (
    <Layout>
      <Column size="100%">
        <FormSectionHeader>
          <DocumentIcon />
          <FormSectionHeaderSubtitleSC>
            Assessment Details
          </FormSectionHeaderSubtitleSC>
        </FormSectionHeader>
        <FormSectionPadding>
          <Column size="100%">
            <TextFieldWithErrorSC
              id={`programAssessmentTitle-${someUuid}`}
              name="assessment_title"
              value={title}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              handleValidate={validateTitle}
              hasErrors={showValidationErrors}
              onChange={handleTitleChange}
              label="Assessment Title*"
              placeholder=""
              variant="outlined"
            />
          </Column>
          <Column size="100%">
            <FormSelectErrorSC
              name="assessmentTypeUuid"
              label="Assessment Type*"
              value={convertNullToString(assessmentTypeUuid)}
              handleValidation={validateSelectField}
              hasErrors={showValidationErrors}
              handleChange={handleAssessmentTypeChange}
              options={optionsAssessmentTypes}
            />
          </Column>
          <FlexRow>
            <Column size="50%">
              <FormSelectErrorSC
                name="scoreType"
                label="Score Type*"
                value={convertNullToString(scoreType)}
                handleValidation={validateSelectField}
                hasErrors={showValidationErrors}
                handleChange={handleScoreTypeChange}
                options={optionsScoreType}
                paddingRight="24px"
              />
            </Column>
            <Column size="50%">
              <FormSelectErrorSC
                name="assessmentAssociationUuid"
                label="Assessment Association*"
                value={convertNullToString(assessmentAssociationUuid)}
                handleValidation={validateSelectField}
                hasErrors={showValidationErrors}
                handleChange={handleAssessmentAssociationChange}
                options={optionsAssessmentAssociations}
              />
            </Column>
          </FlexRow>
        </FormSectionPadding>
        <FormSectionHeader>
          <ReportIcon />
          <FormSectionHeaderSubtitleSC>
            Grading Technique
          </FormSectionHeaderSubtitleSC>
        </FormSectionHeader>
        <GradingTechniqueFormSectionPadding>
          <GradingTechniqueForm
            allowPointsOnly
            isProgramAssessment
            curvedFailingGrade={curvedFailingGrade}
            handleChangeRadio={handleChangeRadioFunc}
            handleGradingTechniqueChange={handleGradingTechniqueChangeFunc}
            lowScoreInput={lowScoreInput}
            lowScoreCalculated={Boolean(lowScoreCalculated)}
            calculatedLowScore={calculatedLowScore}
            maximumMethodCalculated={false}
            maximumRevusInput={maximumRevusInput}
            calculatedHighScore={calculatedHighScore}
            scaledHighScore={scaledHighScore}
            scaledLowScore={scaledLowScore}
            selectedRadio={selectedRadio}
            showValidationErrors={showValidationErrors}
          />
          <ButtonsColumn size="100%">
            <CancelButton onClick={onCancel} noHoverBg>
              Cancel
            </CancelButton>
            <SubmitButton onClick={onSubmit} disabled={!formHasChanges}>
              {buttonText}
            </SubmitButton>
          </ButtonsColumn>
        </GradingTechniqueFormSectionPadding>
      </Column>
    </Layout>
  );
};

ProgramAssessmentMasterForm.propTypes = {
  programAssessment: PropTypes.object.isRequired,
  showValidationErrors: PropTypes.bool,
  programAssessmentDispatch: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  setUnsavedChange: PropTypes.func,
  gradingTechniqueDispatch: PropTypes.func,
  buttonText: PropTypes.string,
  unsavedChanges: PropTypes.object,
  gradingTechnique: PropTypes.object,
  optionsAssessmentTypes: PropTypes.arrayOf(PropTypes.object),
  optionsAssessmentAssociations: PropTypes.arrayOf(PropTypes.object)
};

ProgramAssessmentMasterForm.defaultProps = {
  showValidationErrors: false,
  programAssessmentDispatch: undefined,
  onSubmit: undefined,
  onCancel: undefined,
  setUnsavedChange: undefined,
  gradingTechniqueDispatch: undefined,
  buttonText: 'Submit',
  unsavedChanges: {},
  gradingTechnique: {},
  optionsAssessmentTypes: [],
  optionsAssessmentAssociations: []
};

export default ProgramAssessmentMasterForm;
