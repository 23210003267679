import {
  GET_ACADEMIC_YEAR,
  GET_ACADEMIC_YEAR_SUCCESS,
  GET_ACADEMIC_YEAR_ERROR,
  GET_ACADEMIC_YEARS_BY_SCHOOL,
  GET_ACADEMIC_YEARS_BY_SCHOOL_SUCCESS,
  GET_ACADEMIC_YEARS_BY_SCHOOL_ERROR,
  POST_ACADEMIC_YEAR,
  POST_ACADEMIC_YEAR_SUCCESS,
  POST_ACADEMIC_YEAR_ERROR,
  PUT_ACADEMIC_YEAR,
  PUT_ACADEMIC_YEAR_SUCCESS,
  PUT_ACADEMIC_YEAR_ERROR,
  DELETE_ACADEMIC_YEAR,
  DELETE_ACADEMIC_YEAR_SUCCESS,
  DELETE_ACADEMIC_YEAR_ERROR
} from '../actions-type';

const doGetAcademicYear = academicYearUuid => ({
  type: GET_ACADEMIC_YEAR,
  payload: {
    academicYearUuid
  }
});

const doGetAcademicYearSuccess = result => ({
  type: GET_ACADEMIC_YEAR_SUCCESS,
  payload: result
});

const doGetAcademicYearError = error => ({
  type: GET_ACADEMIC_YEAR_ERROR,
  payload: error
});

const doGetAcademicYearsBySchool = schoolUuid => ({
  type: GET_ACADEMIC_YEARS_BY_SCHOOL,
  payload: {
    schoolUuid
  }
});

const doGetAcademicYearsBySchoolSuccess = result => ({
  type: GET_ACADEMIC_YEARS_BY_SCHOOL_SUCCESS,
  payload: result
});

const doGetAcademicYearsBySchoolError = error => ({
  type: GET_ACADEMIC_YEARS_BY_SCHOOL_ERROR,
  payload: error
});

const doPostAcademicYear = academicYear => ({
  type: POST_ACADEMIC_YEAR,
  payload: {
    academicYear
  }
});

const doPostAcademicYearSuccess = response => ({
  type: POST_ACADEMIC_YEAR_SUCCESS,
  payload: response
});

const doPostAcademicYearError = error => ({
  type: POST_ACADEMIC_YEAR_ERROR,
  payload: error
});

const doPutAcademicYear = academicYear => ({
  type: PUT_ACADEMIC_YEAR,
  payload: {
    academicYear
  }
});

const doPutAcademicYearSuccess = response => ({
  type: PUT_ACADEMIC_YEAR_SUCCESS,
  payload: response
});

const doPutAcademicYearError = error => ({
  type: PUT_ACADEMIC_YEAR_ERROR,
  payload: error
});

const doDeleteAcademicYear = academicYearUuid => ({
  type: DELETE_ACADEMIC_YEAR,
  payload: {
    academicYearUuid
  }
});

const doDeleteAcademicYearSuccess = academicYearUuid => ({
  type: DELETE_ACADEMIC_YEAR_SUCCESS,
  payload: academicYearUuid
});

const doDeleteAcademicYearError = error => ({
  type: DELETE_ACADEMIC_YEAR_ERROR,
  payload: error
});

export {
  doGetAcademicYear,
  doGetAcademicYearSuccess,
  doGetAcademicYearError,
  doGetAcademicYearsBySchool,
  doGetAcademicYearsBySchoolSuccess,
  doGetAcademicYearsBySchoolError,
  doPostAcademicYear,
  doPostAcademicYearSuccess,
  doPostAcademicYearError,
  doPutAcademicYear,
  doPutAcademicYearSuccess,
  doPutAcademicYearError,
  doDeleteAcademicYear,
  doDeleteAcademicYearSuccess,
  doDeleteAcademicYearError
};
