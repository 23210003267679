import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { isNumeric } from 'validator';

import PartWeightsForm from './PartWeightsForm';
import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';

import { doClearCreated } from '../../../../redux/actions/courseCollectionActions';
import {
  partWeightsFormReducer,
  initialPartWeightsFormState
} from './partWeightsFormState';
import { allFieldsAreValid } from '../../../../helpers/validation/validateGeneric';
import { sectionSelector } from '../../../../redux/selectors/sectionsSelectors';
import { partsSelectorBySectionUuid } from '../../../../redux/selectors/partsSelectors';
import { doPutSectionPartWeights } from '../../../../redux/actions/sectionActions';

const PartWeightsFormContainer = ({
  sectionUuid,
  unsavedChanges,
  setUnsavedChange,
  removeUnsavedChange
}) => {
  const dispatch = useDispatch();

  const [state, partWeightsDispatch] = useReducer(
    partWeightsFormReducer,
    initialPartWeightsFormState
  );

  const section = useSelector(
    state => sectionSelector(state, sectionUuid),
    _.isEqual
  );

  const existingParts = useSelector(
    state => partsSelectorBySectionUuid(state, sectionUuid),
    _.isEqual
  );

  // TODO: do I need to delete this?
  const successfullyUpdatedSectionPartWeights = useSelector(
    state => state.formSuccessState.successfullyUpdatedSectionPartWeights
  );

  const onGeneralErrorNotification = errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage));

  const setExistingState = (parts, distributeWeightEqually) => {
    partWeightsDispatch({
      type: 'setExisting',
      payload: {
        parts,
        distributeWeightEqually
      }
    });
  };

  const {
    parts,
    showValidationErrors,
    hasSetParts,
    distributeWeightEqually
  } = state;

  useEffect(() => {
    if (existingParts && !hasSetParts && section) {
      setExistingState(existingParts, section.distributeWeightEqually);
    }
  }, [
    existingParts,
    hasSetParts,
    successfullyUpdatedSectionPartWeights,
    section
  ]);

  useEffect(() => {
    if (successfullyUpdatedSectionPartWeights) {
      dispatch(doClearCreated());
      setExistingState(existingParts, section.distributeWeightEqually);
      removeUnsavedChange('parts');
    }
  }, [
    dispatch,
    removeUnsavedChange,
    successfullyUpdatedSectionPartWeights,
    existingParts,
    section
  ]);

  const handleDistributeWeightEquallyChange = (e, distributeWeightEqually) => {
    setUnsavedChange('parts');
    partWeightsDispatch({
      type: 'setDistributeWeightEqually',
      payload: {
        distributeWeightEqually
      }
    });
  };

  const onPartWeightChange = (event, uuid) => {
    partWeightsDispatch({
      type: 'setPartWeight',
      payload: {
        uuid,
        weight: event.target.value
      }
    });
    setUnsavedChange('parts');
  };

  const setValidationErrors = (parts, distributeWeightEqually) => {
    const partKeys = parts ? Object.keys(parts) : false;

    let noPartsError = false;
    let weightError = false;

    if (partKeys && partKeys.length > 0) {
      partKeys.forEach(partUuid => {
        const thisPart = parts[partUuid];
        const thisPartWeight = Number(thisPart.weight);

        if (
          !distributeWeightEqually &&
          (!thisPartWeight ||
            !isNumeric(thisPart.weight.toString()) ||
            thisPartWeight > 100 ||
            thisPartWeight < 1)
        ) {
          weightError = { invalid: true };
        }
      });
    } else {
      noPartsError = { invalid: true };
      onGeneralErrorNotification(
        'You must create at least one part before you can add section grade settings.'
      );
    }

    const newValidationErrors = {
      noPartsError,
      weightError
    };

    return newValidationErrors;
  };

  const onPutUpdate = (sectionUuid, partWeights, distributeWeightEqually) =>
    dispatch(
      doPutSectionPartWeights(sectionUuid, partWeights, distributeWeightEqually)
    );

  const handleUpdateSubmission = () => {
    const newValidationErrors = setValidationErrors(
      parts,
      distributeWeightEqually
    );

    if (allFieldsAreValid(newValidationErrors)) {
      const partWeights = [];
      Object.keys(parts).forEach(partUuid => {
        const thisPart = parts[partUuid];
        const partObj = {
          uuid: thisPart.uuid,
          weight: Number(thisPart.weight)
        };
        partWeights.push(partObj);
      });

      onPutUpdate(sectionUuid, partWeights, distributeWeightEqually);

      partWeightsDispatch({
        type: 'setValidationErrors',
        payload: {
          showValidationErrors: false
        }
      });
    } else {
      partWeightsDispatch({
        type: 'setValidationErrors',
        payload: {
          showValidationErrors: newValidationErrors
        }
      });
    }
  };

  const handleDiscardChanges = () => {
    setExistingState(existingParts, section.distributeWeightEqually);
    removeUnsavedChange('parts');
  };

  return (
    <PartWeightsForm
      sectionUuid={sectionUuid}
      distributeWeightEqually={distributeWeightEqually}
      parts={parts}
      onPartWeightChange={onPartWeightChange}
      showValidationErrors={showValidationErrors}
      handleSubmitPartWeights={handleUpdateSubmission}
      handleDiscardChanges={handleDiscardChanges}
      handleDistributeWeightEquallyChange={handleDistributeWeightEquallyChange}
      unsavedChanges={unsavedChanges}
    />
  );
};

PartWeightsFormContainer.propTypes = {
  sectionUuid: PropTypes.string,
  unsavedChanges: PropTypes.object,
  setUnsavedChange: PropTypes.func,
  removeUnsavedChange: PropTypes.func
};

PartWeightsFormContainer.defaultProps = {
  sectionUuid: '',
  unsavedChanges: {},
  setUnsavedChange: undefined,
  removeUnsavedChange: undefined
};

export default PartWeightsFormContainer;
