import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import { competencyScoreSelector } from '../../../../redux/selectors/competencyScoreSelectors';

export default function CompetencyScoreByType({
  competencyUuid,
  assessmentTypeUuid
}) {
  const [percentageScore, setPercentageScore] = useState('- -');

  const score = useSelector(
    state => competencyScoreSelector(state, competencyUuid),
    _.isEqual
  );

  useEffect(() => {
    if (
      score?.[assessmentTypeUuid]?.relTotal &&
      score?.[assessmentTypeUuid]?.potTotal
    ) {
      const rel = new Decimal(score?.[assessmentTypeUuid]?.relTotal);
      const pot = new Decimal(score?.[assessmentTypeUuid]?.potTotal);

      const percentage = rel.div(pot).times(100).toFixed(2);
      setPercentageScore(percentage);
    }
  }, [assessmentTypeUuid, score]);

  return <>{percentageScore}</>;
}

CompetencyScoreByType.propTypes = {
  competencyUuid: PropTypes.string,
  assessmentTypeUuid: PropTypes.string
};

CompetencyScoreByType.defaultProps = {
  competencyUuid: undefined,
  assessmentTypeUuid: undefined
};
