import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { doGetUser } from '../../../redux/actions/userActions';
import { userSelector } from '../../../redux/selectors/usersSelectors';
import { doGetInstitutionIdTypes } from '../../../redux/actions/sourceActions';
import { selectInstitutionIdType } from '../../../redux/selectors/sourceSelectors';

import {
  formatLastName,
  formatFirstNameMiddleName
} from '../../../helpers/format/user.format';

const SubHeader = styled.div`
  font-size: 24px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding: 20px;
`;

const DetailData = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Label = styled.div`
  height: 16px;
  color: ${props => props.theme.colors.grey[600]};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const Value = styled.div`
  height: 20px;
  width: 228px;
  color: ${props => props.theme.colors.grey[900]};
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
`;

export default function CohortUserDetails({ userUuid }) {
  const dispatch = useDispatch();

  const user = useSelector(
    state => userSelector(state, userUuid),
    shallowEqual
  );

  const institutionIdTypes = useSelector(state =>
    selectInstitutionIdType(state)
  );

  const type = _.find(
    institutionIdTypes,
    institutionIdType => institutionIdType.uuid === user.institutionIdTypeUuid
  );

  const name = _.get(type, ['name'], '');

  useEffect(() => {
    dispatch(doGetUser(userUuid));
    dispatch(doGetInstitutionIdTypes());
  }, [dispatch, userUuid]);

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
    >
      <Grid item sm={12} xs={12}>
        <SubHeader>User Details</SubHeader>
      </Grid>

      <Grid item sm={3} xs={12}>
        <DetailData>
          <Label>Institution Type</Label>
          <Value>{name}</Value>
        </DetailData>
      </Grid>
      <Grid item sm={3} xs={12}>
        <DetailData>
          <Label>Institution ID</Label>
          <Value>{user.institutionId}</Value>
        </DetailData>
      </Grid>
      <Grid item sm={3} xs={12}>
        <DetailData>
          <Label>First Name</Label>
          <Value>
            {formatFirstNameMiddleName(user?.firstName, user?.middleName)}
          </Value>
        </DetailData>
      </Grid>
      <Grid item sm={3} xs={12}>
        <DetailData>
          <Label>Last Name</Label>
          <Value>
            {`${formatLastName(
              user?.lastName,
              user?.marriedLastName,
              user?.suffix
            )}
            `}
          </Value>
        </DetailData>
      </Grid>
      <Grid item sm={3} xs={12}>
        <DetailData>
          <Label>Date of Birth</Label>
          <Value>{user.birthDate}</Value>
        </DetailData>
      </Grid>
      <Grid item sm={3} xs={12}>
        <DetailData>
          <Label>Gender</Label>
          <Value>{user.gender}</Value>
        </DetailData>
      </Grid>
      <Grid item sm={3} xs={12}>
        <DetailData>
          <Label>Ethnicity</Label>
          <Value>{user.ethnicity}</Value>
        </DetailData>
      </Grid>
    </Grid>
  );
}

CohortUserDetails.propTypes = {
  userUuid: PropTypes.string
};

CohortUserDetails.defaultProps = {
  userUuid: ''
};
