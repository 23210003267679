import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';
import ProgramAssessmentsManagement from './ProgramAssessmentsManagement';
import {
  programAssessmentCollectionsSelector,
  programSelector
} from '../../redux/selectors/programSelectors';
import { doGetProgramAssessments } from '../../redux/actions/programActions';
import { doClearCreated } from '../../redux/actions/competencyCollectionActions';

export default function ProgramAssessmentsManagementContainer() {
  const dispatch = useDispatch();

  const programUuid = useSelector(state => state.userState.selectedProgramUuid);
  const successfullyUpdated = useSelector(
    state => state.formSuccessState.successfullyUpdatedProgramAssessments
  );

  const [isNewFormOpen, setNewFormOpen] = useState(false);

  const program = useSelector(
    state => programSelector(state, programUuid),
    _.isEqual
  );

  const programAssessmentCollections = useSelector(
    state => programAssessmentCollectionsSelector(state, programUuid),
    _.isEqual
  );

  useEffect(() => {
    dispatch(doGetProgramAssessments(programUuid));
  }, [dispatch, programUuid]);

  useEffect(() => {
    const onClearCreated = () => dispatch(doClearCreated());

    if (successfullyUpdated === true) {
      onClearCreated();
      setNewFormOpen(false);
    }
  }, [dispatch, successfullyUpdated]);

  return (
    <ProgramAssessmentsManagement
      programUuid={programUuid}
      program={program}
      programAssessmentCollections={programAssessmentCollections || []}
      isNewFormOpen={isNewFormOpen}
      setNewFormOpen={setNewFormOpen}
    />
  );
}
