/* eslint prefer-destructuring: 0 */ 
import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  competencyCollectionsSelector
} from '../../../redux/selectors/competencyCollectionsSelectors';
import {
  activeStudentRosterSelectorByCohortUuid,
  delayedStudentRosterSelectorByCohortUuid
} from '../../../redux/selectors/userCohortsSelectors';
import { doGetUserCohorts } from '../../../redux/actions/userCohortActions';
import CompetencyGridSummaryData from './CompetencyGridSummaryData';
import {
  generateScoreTotalMap,
  sortStudentsByRank
} from '../../../helpers/competencyGrid';
import { doClearCompetencyGridReport, doSetIsResultLoaded, doSetShowTableModal } from '../../../redux/actions/competencyGridActions';

const CompetencyGridSummaryDataContainer = ({
  setIsLoading,
}) => {
  const dispatch = useDispatch();
  const { cohortUuid } = useParams();

  const programUuid = useSelector(state => state.userState.selectedProgramUuid);

  const competencyCollections = useSelector(
    state => competencyCollectionsSelector(state, programUuid),
    _.isEqual
  );

  const students = useSelector(state =>
    activeStudentRosterSelectorByCohortUuid(state, cohortUuid), _.isEqual
  );

  const delayedStudents = useSelector(state =>
    delayedStudentRosterSelectorByCohortUuid(state, cohortUuid), _.isEqual
  );

  const cohortCompetencyReportState = useSelector(state => state.cohortCompetencyReportState);

  const activeCompetencyGridMap = cohortCompetencyReportState.activeCompetencyGridMap;
  const delayedCompetencyGridMap = cohortCompetencyReportState.delayedCompetencyGridMap;
  const isLoading = cohortCompetencyReportState.isLoading;
  const hasActiveScores = cohortCompetencyReportState.hasActiveScores;
  const hasFetchedReport = cohortCompetencyReportState.hasFetchedReport;
  const competencyDeviationsMap = cohortCompetencyReportState.competencyDeviationsMap;
  const selectedCompetencyCollectionUuid = cohortCompetencyReportState.selectedCompetencyCollectionUuid;
  const currentReportCohortUuid = cohortCompetencyReportState.currentReportCohortUuid;

  const hasNoScores = !isLoading && hasFetchedReport && !hasActiveScores;

  const filteredCollections =
    selectedCompetencyCollectionUuid &&
    selectedCompetencyCollectionUuid !== 'all'
      ? competencyCollections.filter(
          collection => collection.uuid === selectedCompetencyCollectionUuid
        )
      : competencyCollections;

  const tableRef = useRef(null);

  const activeParticipationMaps = useMemo(() => {
    return generateScoreTotalMap(
      activeCompetencyGridMap,
      students,
      filteredCollections
    );
  }, [activeCompetencyGridMap, students, filteredCollections]);

  const studentRankMap = activeParticipationMaps.studentRankMap;

  const delayedParticipationMaps = useMemo(() => {
    return generateScoreTotalMap(
      delayedCompetencyGridMap,
      delayedStudents,
      filteredCollections
    );
  }, [delayedCompetencyGridMap, delayedStudents, filteredCollections]);

  useEffect(() => {
    dispatch(doGetUserCohorts(cohortUuid));
  }, [dispatch, cohortUuid]);

  useEffect(() => {
    if (currentReportCohortUuid !== cohortUuid) {
      dispatch(doSetIsResultLoaded(false));
      dispatch(doSetShowTableModal(false));
      dispatch(doClearCompetencyGridReport());
    }
  }, [dispatch, cohortUuid, currentReportCohortUuid]);

  useEffect(() => {
    if (hasNoScores) {
      dispatch(doSetIsResultLoaded(true));
      dispatch(doSetShowTableModal(true));
    }

    if (
      isLoading &&
      hasFetchedReport &&
      studentRankMap &&
      Object.keys(studentRankMap).length > 0
    ) {
      dispatch(doSetIsResultLoaded(true));
      dispatch(doSetShowTableModal(true));
      setIsLoading(false);
    }

    if (
      isLoading &&
      hasFetchedReport &&
      studentRankMap &&
      Object.keys(studentRankMap).length === 0 &&
      students?.length === 0
    ) {
      dispatch(doSetIsResultLoaded(true));
      dispatch(doSetShowTableModal(true));
      setIsLoading(false);
    }
  }, [
    hasFetchedReport,
    hasNoScores,
    isLoading,
    setIsLoading,
    studentRankMap,
    students?.length,
    dispatch
  ]);

  useEffect(
    () => () => {
      // UNMOUNT
      dispatch(doClearCompetencyGridReport());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const studentsDependency = JSON.stringify(students) || '{}';

  const sortedStudents = useMemo(() => {
    const studentsData = JSON.parse(studentsDependency);
    return sortStudentsByRank(studentsData, studentRankMap);
  }, [studentsDependency, studentRankMap]);

  return !isLoading && (
    <CompetencyGridSummaryData
      tableRef={tableRef}
      competencyCollections={filteredCollections}
      activeCompetencyGridMap={activeCompetencyGridMap}
      delayedCompetencyGridMap={delayedCompetencyGridMap}
      activeParticipationMaps={activeParticipationMaps}
      delayedParticipationMaps={delayedParticipationMaps}
      competencyDeviationsMap={competencyDeviationsMap}
      hasNoScores={hasNoScores}
      students={sortedStudents}
      delayedStudents={delayedStudents}
    />
  );
};

CompetencyGridSummaryDataContainer.propTypes = {
  setIsLoading: PropTypes.func,
};
CompetencyGridSummaryDataContainer.defaultProps = {
  setIsLoading: undefined,
};

export default CompetencyGridSummaryDataContainer;
