import fetch from '../helpers/fetch';

const getSectionsNestedByCourse = courseUuid =>
  fetch(`/api/sections/course/${courseUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getSection = sectionUuid =>
  fetch(`/api/section/${sectionUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getSectionCommitmentLog = sectionUuid =>
  fetch(`/api/section/${sectionUuid}/commitment-log`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putSectionDataSource = ({ sectionUuid, dataSource }) =>
  fetch(`/api/section/${sectionUuid}/datasource`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ dataSource })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putSectionPartWeigths = ({
  sectionUuid,
  partWeights,
  distributeWeightEqually
}) =>
  fetch(`/api/section/${sectionUuid}/part-weights`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ part_weights: partWeights, distributeWeightEqually })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchSectionCalculateGrade = sectionUuid =>
  fetch(`/api/section/${sectionUuid}/calculate-grade`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchSectionUndoGradeCalculation = sectionUuid =>
  fetch(`/api/section/${sectionUuid}/delete-calculated-grades`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putSectionStatusCommit = ({ uuid, ...rest }) =>
  fetch(`/api/section/${uuid}/commitment-status`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(rest)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchDelayedStudentSectionGrade = ({ sectionUuid, studentUuid }) =>
  fetch(
    `/api/section/${sectionUuid}/student/${studentUuid}/grade-delayed-student`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putDelayedStudentSectionCommitStatus = ({
  sectionUuid,
  studentUuid,
  delayedStatusCommit,
  justificationMessage
}) =>
  fetch(
    `/api/section/${sectionUuid}/student/${studentUuid}/commitment-status`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        delayedStatusCommit,
        justificationMessage
      })
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteDelayedStudentSectionGrade = ({ sectionUuid, studentUuid }) =>
  fetch(
    `/api/section/${sectionUuid}/student/${studentUuid}/delayed-student-grade`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getSectionsNestedByCourse,
  getSection,
  getSectionCommitmentLog,
  putSectionDataSource,
  putSectionPartWeigths,
  patchSectionCalculateGrade,
  patchSectionUndoGradeCalculation,
  putSectionStatusCommit,
  patchDelayedStudentSectionGrade,
  putDelayedStudentSectionCommitStatus,
  deleteDelayedStudentSectionGrade
};
