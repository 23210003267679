import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, FormGroup} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

const Rule = ({label, data, handleChange, name}) => (
  <FormGroup row style={{gap: '20px'}}>
    <FormControlLabel
      style={{minWidth: '150px'}}
      control={
        <Checkbox
          checked={data.checked}
          name={`${name}Checked`}
          onChange={handleChange}
          color="primary"
        />
      }
      label={label}
    />
    <TextField
      id="standard-basic"
      label="Threshold"
      margin="dense"
      variant="outlined"
      value={data.threshold || ''}
      disabled={!data.checked}
      name={`${name}Threshold`}
      onChange={handleChange}
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={data.rank}
          name={`${name}Rank`}
          disabled={!data.checked}
          onChange={handleChange}
          color="primary"
        />
      }
      label="Rank"
    />
    {name === 'score' && (
      <FormControlLabel
        control={
          <Checkbox
            checked={data.grade}
            disabled={!data.checked}
            color="primary"
            name={`${name}Grade`}
            onChange={handleChange}
          />
        }
        label="Grade"
      />
    )}
  </FormGroup>
);

Rule.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default Rule;