import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  doPostUserDepartments,
  doResetAssignState
} from '../../../../redux/actions/userDepartmentActions';
import UserAssign from './UserAssign';

export default function UserAssignContainer({
  openUserAssignModal,
  handleUserAssignClose
}) {
  const dispatch = useDispatch();
  const [userRoles, setUserRoles] = useState(undefined);
  const [openMapping, setOpenMapping] = useState(false);

  const uploadState = useSelector(
    state => state.uploadDepartmentRolesState,
    shallowEqual
  );

  useEffect(() => {
    if (!openUserAssignModal) {
      setUserRoles(undefined);
      setOpenMapping(false);
      dispatch(doResetAssignState());
    }
  }, [dispatch, openUserAssignModal]);

  const handleAssign = roles => {
    setUserRoles(roles);
  };

  const handleImport = () => {
    dispatch(doPostUserDepartments(userRoles));
  };

  const handleDisplayMapping = () => {
    setOpenMapping(openMapping => !openMapping);
  };

  return (
    <UserAssign
      uploadState={uploadState}
      userRoles={userRoles}
      uploadingDepartmentRoles={uploadState.uploadingDepartmentRoles}
      departmentRolesCreated={uploadState.departmentRolesCreated}
      openUserAssignModal={openUserAssignModal}
      openMapping={openMapping}
      handleUserAssignClose={handleUserAssignClose}
      handleDisplayMapping={handleDisplayMapping}
      handleAssign={handleAssign}
      handleImport={handleImport}
    />
  );
}

UserAssignContainer.propTypes = {
  openUserAssignModal: PropTypes.bool,
  handleUserAssignClose: PropTypes.func
};

UserAssignContainer.defaultProps = {
  openUserAssignModal: false,
  handleUserAssignClose: undefined
};
