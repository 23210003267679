import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  doPutApplicantCognitiveReport,
  doGetApplicantCognitiveReportTypes,
  doClearApplicantCognitiveReport
} from '../../../../redux/actions/applicantReportActions';
import { doGetSubjects } from '../../../../redux/actions/classificationActions';
import { selectSubjects } from '../../../../redux/selectors/classificationSelectors';
import { applicantCognitiveReportTypesSelector } from '../../../../redux/selectors/applicantReportTypesSelectors';
import {
  getApplicantCognitiveReportFilters,
  sortByNestedProperty,
  sortByDecimalProperty
} from '../../../../helpers/utilities';
import {
  applicantCognitiveReportTranscriptSourceOptions,
  applicantReportTranscriptScopeOptions,
  applicantReportRankByOptions
} from '../../../../helpers/constants';
import CohortApplicantCognitiveReport from './CohortApplicantCognitiveReport';

const CohortApplicantCognitiveReportContainer = ({
  cohortUuid,
  programUuid
}) => {
  const dispatch = useDispatch();
  const [shouldAnonymizeReport, setShouldAnonymizeReport] = useState(false);
  const [filterSelection, setFilterSelection] = useState({
    property: 'user.lastName',
    decimalProperty: false,
    reversed: false
  });
  const [transcriptSource, setTranscriptSource] = useState('');
  const [transcriptScope, setTranscriptScope] = useState('');
  const [transcriptScopeDisabled, setTranscriptScopeDisabled] = useState(true);
  const [transcriptScopeOptions, setTranscriptScopeOptions] = useState(
    applicantReportTranscriptScopeOptions
  );
  const [transcriptRankBy, setTranscriptRankBy] = useState('');
  const [transcriptRankByDisabled, setTranscriptRankByDisabled] = useState(
    true
  );

  const [reportSummaryFilters, setReportSummaryFilters] = useState([]);
  const [transcriptReportTypeError, setTranscriptReportTypeError] = useState(
    false
  );

  const cognitiveReportState = useSelector(
    state => state.applicantCognitiveReportState
  );

  const applicantCognitiveReportTypes = useSelector(
    applicantCognitiveReportTypesSelector,
    _.isEqual
  );

  const cognitiveSubjects = useSelector(
    state => selectSubjects(state, programUuid),
    _.isEqual
  );

  const isLoadingCognitiveReport = cognitiveReportState?.loadingCognitiveReport;

  const cognitiveReportMap = _.keyBy(applicantCognitiveReportTypes, 'name');

  const handleSelectTranscriptSource = event => {
    const { value } = event.target;
    dispatch(doClearApplicantCognitiveReport());
    setTranscriptSource(value);
    setTranscriptReportTypeError(false);
    setReportSummaryFilters([]);
    setTranscriptScope('Cumulative');
    setTranscriptScopeDisabled(false);
  };

  const handleSelectTranscriptScope = event => {
    const { value } = event.target;
    dispatch(doClearApplicantCognitiveReport());
    setTranscriptScope(value);
    setReportSummaryFilters([]);
  };

  const handleSelectTranscriptRankBy = event => {
    const { value } = event.target;
    dispatch(doClearApplicantCognitiveReport());
    setTranscriptRankBy(value);
    setReportSummaryFilters([]);
  };

  const getSelectedReportType = (
    transcriptSource,
    transcriptScope,
    transcriptRankBy
  ) => {
    if (transcriptSource === 'Cumulative' && transcriptScope === 'Cumulative') {
      return cognitiveReportMap['Cognitive Cumulative'];
    }
    if (transcriptSource === 'Cumulative' && transcriptScope !== 'Cumulative') {
      return cognitiveReportMap['Cognitive Cumulative Subject'];
    }
    if (
      transcriptSource === 'Program' &&
      transcriptScope === 'Cumulative' &&
      transcriptRankBy === 'score'
    ) {
      return cognitiveReportMap['Program Cumulative Score'];
    }
    if (
      transcriptSource === 'Program' &&
      transcriptScope === 'Cumulative' &&
      transcriptRankBy === 'credit'
    ) {
      return cognitiveReportMap['Program Cumulative Credit'];
    }
    if (transcriptSource === 'Program' && transcriptScope !== 'Cumulative') {
      return cognitiveReportMap['Program Subject'];
    }
    if (transcriptSource === 'Milestone' && transcriptScope === 'Cumulative') {
      return cognitiveReportMap['Milestone Cumulative'];
    }
    if (transcriptSource === 'Milestone' && transcriptScope !== 'Cumulative') {
      return cognitiveReportMap['Milestone Subject'];
    }
  };

  const handleApplyReportType = () => {
    const selectedReportType = getSelectedReportType(
      transcriptSource,
      transcriptScope,
      transcriptRankBy
    );
    const classificationUuid =
      transcriptScope === 'Cumulative' ? null : transcriptScope;
    if (transcriptSource === '') {
      setTranscriptReportTypeError(true);
    } else {
      dispatch(doClearApplicantCognitiveReport());
      dispatch(
        doPutApplicantCognitiveReport(
          cohortUuid,
          selectedReportType.uuid,
          classificationUuid,
          transcriptRankBy
        )
      );
      const filterOptions = getApplicantCognitiveReportFilters();
      setReportSummaryFilters(filterOptions);
    }
  };

  const handleApplyFilterSelection = (
    selected,
    isDecimalProperty,
    isReversed
  ) => {
    setFilterSelection({
      property: selected,
      decimalProperty: isDecimalProperty,
      reversed: isReversed
    });
  };

  const handleToggleShouldAnonymizeReport = event => {
    const { checked } = event.target;
    setShouldAnonymizeReport(checked);
  };

  useEffect(() => {
    dispatch(doGetApplicantCognitiveReportTypes());
  }, [dispatch]);

  useEffect(() => {
    if (programUuid) dispatch(doGetSubjects(programUuid));
  }, [dispatch, programUuid]);

  useEffect(() => {
    if (cognitiveSubjects)
      setTranscriptScopeOptions(
        applicantReportTranscriptScopeOptions.concat(
          cognitiveSubjects.map(cognitiveSubject => ({
            value: cognitiveSubject.uuid,
            label: cognitiveSubject.scope
          }))
        )
      );
  }, [cognitiveSubjects]);

  useEffect(() => {
    if (transcriptSource === 'Program' && transcriptScope === 'Cumulative') {
      setTranscriptRankByDisabled(false);
    } else {
      setTranscriptRankByDisabled(true);
    }
    setTranscriptRankBy('score');
  }, [transcriptSource, transcriptScope]);

  const filteredReportData = filterSelection.decimalProperty
    ? cognitiveReportState?.cognitiveReport.sort((a, b) =>
        filterSelection.reversed
          ? sortByDecimalProperty(b, a, filterSelection.property)
          : sortByDecimalProperty(a, b, filterSelection.property)
      )
    : cognitiveReportState?.cognitiveReport.sort((a, b) =>
        filterSelection.reversed
          ? sortByNestedProperty(b, a, filterSelection.property)
          : sortByNestedProperty(a, b, filterSelection.property)
      );

  return (
    <CohortApplicantCognitiveReport
      isLoadingCognitiveReport={isLoadingCognitiveReport}
      onApplyFilterSelection={handleApplyFilterSelection}
      onApplyReportType={handleApplyReportType}
      onSelectTranscriptRankBy={handleSelectTranscriptRankBy}
      onSelectTranscriptScope={handleSelectTranscriptScope}
      onSelectTranscriptSource={handleSelectTranscriptSource}
      onToggleShouldAnonymizeReport={handleToggleShouldAnonymizeReport}
      reportData={filteredReportData}
      reportHeaders={cognitiveReportState?.reportHeaders}
      reportSummary={cognitiveReportState?.reportSummary}
      reportSummaryFilters={reportSummaryFilters}
      reportSummaryHeaders={cognitiveReportState?.reportSummaryHeaders}
      shouldAnonymizeReport={shouldAnonymizeReport}
      transcriptRankBy={transcriptRankBy}
      transcriptRankByDisabled={transcriptRankByDisabled}
      transcriptRankByOptions={applicantReportRankByOptions}
      transcriptReportTypeError={transcriptReportTypeError}
      transcriptScope={transcriptScope}
      transcriptScopeDisabled={transcriptScopeDisabled}
      transcriptScopeOptions={transcriptScopeOptions}
      transcriptSource={transcriptSource}
      transcriptSourceOptions={applicantCognitiveReportTranscriptSourceOptions}
    />
  );
};

CohortApplicantCognitiveReportContainer.propTypes = {
  cohortUuid: PropTypes.string.isRequired,
  programUuid: PropTypes.string.isRequired
};

CohortApplicantCognitiveReportContainer.defaultProps = {};

export default CohortApplicantCognitiveReportContainer;
