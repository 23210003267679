import { Model, attr } from 'redux-orm';
import {
  GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES_SUCCESS,
  GET_TRANSCRIPT_SUCCESS
} from '../actions-type';

class ApplicantNoncognitiveReportType extends Model {
  static reducer(action, ApplicantNoncognitiveReportType, session) {
    const { type, payload } = action;
    switch (type) {
      case GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES_SUCCESS: {
        const applicantNoncognitiveReportTypes = [
          ...payload.applicantNoncognitiveReportTypes
        ];
        applicantNoncognitiveReportTypes.forEach(type =>
          ApplicantNoncognitiveReportType.upsert(type)
        );
        break;
      }

      case GET_TRANSCRIPT_SUCCESS: {
        const applicantNoncognitiveReportType = [
          ...payload.applicantNoncognitiveReportType
        ];
        applicantNoncognitiveReportType.forEach(type =>
          ApplicantNoncognitiveReportType.upsert(type)
        );
        break;
      }
      default:
        return session;
    }
  }
}

ApplicantNoncognitiveReportType.fields = {
  uuid: attr(),
  name: attr(),
  subType: attr(),
  order: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

ApplicantNoncognitiveReportType.modelName = 'ApplicantNoncognitiveReportType';

ApplicantNoncognitiveReportType.options = {
  idAttribute: 'uuid'
};

export default ApplicantNoncognitiveReportType;
