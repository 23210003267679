import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Subtitle2, ButtonIcon } from '@xcomp/xcomp-design-library';
import { ExpandIcon, DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  doSetClosedAccordion,
  doSetOpenAccordion
} from '../../redux/actions/openAccordionsActions';

const FormSectionHeader = styled(({ ...rest }) => <div {...rest} />)`
  margin-bottom: 1.5rem;
  width: 100%;
  height: 40px;
  position: relative;

  .grow {
    transition: all 0.2s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 19px;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }

  &:hover {
    cursor: default;

    .section_subtitle,
    svg {
      color: ${props => props.theme.colors.primary};
    }
    &:after {
      background-color: ${props => props.theme.colors.grey[300]};
    }
  }
`;

const FormSectionChildren = styled(({ padding, ...rest }) => <div {...rest} />)`
  width: 100%;
  padding: ${props => props.padding};
  display: flex;
  flex-wrap: wrap;
`;

const FormSectionHeaderLayout = styled.div`
  position: relative;
  display: inline-block;
  z-index: 100;
  background: ${props => props.theme.colors.white};
  padding-right: 0.5rem;
  height: 100%;
`;

const FlexBox = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const FormSectionHeaderSubtitle = styled(Subtitle2)`
  && {
    display: flex;
    align-items: center;
    padding-left: 5px;
    color: ${props => props.theme.fontColors.darker};
  }
`;

const ExpandIconSC = styled(({ isOpen, ...rest }) => <ExpandIcon {...rest} />)`
  transform: ${props => (props.isOpen ? 'rotate(270deg)' : 'rotate(180deg)')};
`;

const ButtonIconContainer = styled.div`
  position: absolute;
  top: 7px;
  right: 0;
  width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.white};
  z-index: 25;
`;

const ButtonIconSC = styled(ButtonIcon)`
  && {
  }
`;

const FormSectionShowHide = ({
  headerText,
  padding,
  children,
  startOpen,
  className,
  onDelete,
  accordionKey,
  loading
}) => {
  const dispatch = useDispatch();
  const [isSectionOpen, setIsSectionOpen] = useState(true);

  const toggle = () => {
    if (isSectionOpen && accordionKey) {
      // close accordion
      dispatch(doSetClosedAccordion(accordionKey));
    } else if (accordionKey) {
      // open accordion
      dispatch(doSetOpenAccordion(accordionKey));
    }
    setIsSectionOpen(!isSectionOpen);
  };

  useEffect(() => {
    setIsSectionOpen(startOpen);
  }, [startOpen]);

  return (
    <>
      <FormSectionHeader className={className}>
        <FormSectionHeaderLayout onClick={toggle}>
          <FlexBox>
            {/* <ButtonIcon onClick={toggle} noHoverBg> */}
            <ExpandIconSC isOpen={isSectionOpen} />
            {/* </ButtonIcon> */}
            <FormSectionHeaderSubtitle className="section_subtitle grow">
              {headerText}
            </FormSectionHeaderSubtitle>
          </FlexBox>
        </FormSectionHeaderLayout>
        {onDelete && typeof onDelete === 'function' && (
          <ButtonIconContainer>
            <ButtonIconSC onClick={onDelete} noHoverBg>
              {
                loading
                  ? <CircularProgress size={20} />
                  : <DeleteIcon />
              }
            </ButtonIconSC>
          </ButtonIconContainer>
        )}
      </FormSectionHeader>
      {isSectionOpen && (
        <FormSectionChildren padding={padding}>{children}</FormSectionChildren>
      )}
    </>
  );
};

FormSectionShowHide.propTypes = {
  headerText: PropTypes.string.isRequired,
  padding: PropTypes.string,
  accordionKey: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  startOpen: PropTypes.bool,
  onDelete: PropTypes.func,
  loading: PropTypes.bool
};

FormSectionShowHide.defaultProps = {
  padding: '0',
  className: '',
  accordionKey: '',
  startOpen: true,
  onDelete: undefined,
  loading: false
};
export default FormSectionShowHide;
