import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DegreeLevelIndex from './DegreeLevelIndex';

import {
  doDeleteDegreeLevelIndex,
  doGetDegreeLevelIndices
} from '../../../../redux/actions/degreeLevelIndexActions';
import { selectDegreeLevelIndices } from '../../../../redux/selectors/degreeLevelIndexSelectors';

export default function DegreeLevelIndexContainer() {
  const dispatch = useDispatch();
  const programUuid = useSelector(state => state.userState.selectedProgramUuid);
  const [openDegree, setOpenDegree] = useState(false);
  const [openDegreeEdit, setOpenDegreeEdit] = useState(false);
  const [openDegreeDelete, setOpenDegreeDelete] = useState(false);
  const [selectEdit, setSelectEdit] = useState(undefined);
  const [selectDelete, setSelectDelete] = useState(undefined);

  const degreeLevelIndices = useSelector(state =>
    selectDegreeLevelIndices(state, programUuid)
  );

  useEffect(() => {
    if (programUuid) {
      dispatch(doGetDegreeLevelIndices(programUuid));
    }
  }, [dispatch, programUuid]);

  const handleDegreeOpen = () => {
    setOpenDegree(true);
  };

  const handleDegreeClose = () => {
    setOpenDegree(false);
  };

  const handleDegreeEditOpen = uuid => {
    setSelectEdit(uuid);
    setOpenDegreeEdit(true);
  };

  const handleDegreeEditClose = () => {
    setOpenDegreeEdit(false);
    setSelectEdit(undefined);
  };

  const handleDegreeDeleteOpen = uuid => {
    setSelectDelete(uuid);
    setOpenDegreeDelete(true);
  };

  const handleDegreeDeleteClose = () => {
    setOpenDegreeDelete(false);
    setSelectDelete(undefined);
  };

  const handleDeleteDegreeLevelIndex = () => {
    dispatch(doDeleteDegreeLevelIndex(selectDelete));
    setOpenDegreeDelete(false);
    setSelectDelete(undefined);
  };

  return (
    <DegreeLevelIndex
      openDegreeDelete={openDegreeDelete}
      openDegreeEdit={openDegreeEdit}
      selectEdit={selectEdit}
      selectDelete={selectDelete}
      degreeLevelIndices={degreeLevelIndices}
      programUuid={programUuid}
      openDegree={openDegree}
      handleDegreeOpen={handleDegreeOpen}
      handleDegreeClose={handleDegreeClose}
      handleDegreeEditOpen={handleDegreeEditOpen}
      handleDegreeEditClose={handleDegreeEditClose}
      handleDegreeDeleteOpen={handleDegreeDeleteOpen}
      handleDegreeDeleteClose={handleDegreeDeleteClose}
      handleDeleteDegreeLevelIndex={handleDeleteDegreeLevelIndex}
    />
  );
}

DegreeLevelIndexContainer.propTypes = {};

DegreeLevelIndexContainer.defaultProps = {};
