import _ from 'lodash';
import {
  PUT_ASSESSMENT_STATUS_COMMIT,
  PUT_ASSESSMENT_STATUS_COMMIT_SUCCESS,
  PUT_ASSESSMENT_STATUS_COMMIT_ERROR,
  RESET_STATUS_COMMIT_MODAL,
  CLEAR_UPDATED_ASSESSMENT,
  PUT_PART_STATUS_COMMIT_SUCCESS,
  PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_SUCCESS,
  PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_SUCCESS
} from '../actions-type';

export const INITIAL_STATE = {
  isLoading: false,
  invalid: false,
  successfullyCommitted: false,
  successfullyUncommitted: false,
  missingScoresError: false,
  errorMessage: ''
};

const assessmentStatusReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PUT_ASSESSMENT_STATUS_COMMIT: {
      return {
        ...state,
        isLoading: true,
        invalid: false
      };
    }
    case PUT_ASSESSMENT_STATUS_COMMIT_SUCCESS: {
      const { statusCommit } = action.payload.assessment;
      return {
        ...state,
        isLoading: false,
        invalid: false,
        successfullyCommitted: statusCommit === 'Commit',
        successfullyUncommitted: statusCommit === 'Uncommitted'
      };
    }
    case PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_SUCCESS: {
      const { delayedStatusCommit } = action.payload.result_card;
      return {
        ...state,
        isLoading: false,
        invalid: false,
        successfullyCommitted: delayedStatusCommit === 'COMMITTED',
        successfullyUncommitted: delayedStatusCommit === 'UNCOMMITTED'
      };
    }
    case PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_SUCCESS: {
      const { cohortAssessmentStatusCommit } = action.payload.result_card;
      return {
        ...state,
        isLoading: false,
        invalid: false,
        successfullyCommitted: cohortAssessmentStatusCommit === 'COMMITTED',
        successfullyUncommitted: cohortAssessmentStatusCommit === 'UNCOMMITTED'
      };
    }
    case PUT_PART_STATUS_COMMIT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        invalid: false,
        successfullyCommitted: true
      };
    }
    case PUT_ASSESSMENT_STATUS_COMMIT_ERROR: {
      const errorMessage = _.get(action, 'payload.error', '');
      const missingScoresError = errorMessage.toLowerCase().includes('scores');
      return {
        ...state,
        isLoading: false,
        invalid: true,
        missingScoresError,
        errorMessage: _.get(action, 'payload.error', '')
      };
    }

    case RESET_STATUS_COMMIT_MODAL: {
      return INITIAL_STATE;
    }

    case CLEAR_UPDATED_ASSESSMENT: {
      return {
        ...state,
        successfullyCommitted: false,
        successfullyUncommitted: false
      };
    }
    default:
      return state;
  }
};

export default assessmentStatusReducer;
