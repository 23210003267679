import React from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js-light';
import { useSelector } from 'react-redux';
import { PointsTd, TableDataText } from '../CollectionReportSC';
import { assessmentGradeResultSelector } from '../../../../../redux/selectors/gradeResultSelectors';

const getDisplayedScore = assessmentGradeResult => {
  if (!assessmentGradeResult) {
    return '--';
  }

  const { gradeModification, calculatedGrade, assessmentRelativeScore } =
    assessmentGradeResult;

  if (!calculatedGrade && gradeModification) {
    return '--';
  }

  return calculatedGrade || assessmentRelativeScore;
};

const getIsFailingGrade = (
  pointsOnlyCollection,
  displayedScore,
  failThreshold
) => {
  return (
    !pointsOnlyCollection &&
    displayedScore !== undefined &&
    displayedScore !== '--' &&
    new Decimal(displayedScore).lte(failThreshold)
  );
};

const StudentAssessmentGradeData = ({
  collectionAssessment,
  dropAssessment,
  evenStudent,
  failThreshold,
  isExpanded,
  pointsOnlyCollection,
  studentUuid
}) => {
  const assessmentGradeResult = useSelector(state =>
    assessmentGradeResultSelector(
      state,
      collectionAssessment.assessmentUuid,
      studentUuid
    )
  );

  const displayedScore = getDisplayedScore(assessmentGradeResult);

  const isFailingGrade = getIsFailingGrade(
    pointsOnlyCollection,
    displayedScore,
    failThreshold
  );

  return (
    <PointsTd
      key={collectionAssessment.uuid}
      displayNone={!isExpanded}
      greyOne={!evenStudent}
    >
      <TableDataText
        secondaryFontColor={!isFailingGrade}
        warningFontColor={isFailingGrade}
        strikeThrough={dropAssessment}
      >
        {displayedScore || '--'}
      </TableDataText>
    </PointsTd>
  );
};

StudentAssessmentGradeData.propTypes = {
  collectionAssessment: PropTypes.object,
  dropAssessment: PropTypes.bool,
  evenStudent: PropTypes.bool.isRequired,
  failThreshold: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  pointsOnlyCollection: PropTypes.bool.isRequired,
  studentUuid: PropTypes.string.isRequired
};

StudentAssessmentGradeData.defaultProps = {
  collectionAssessment: { uuid: '' },
  dropAssessment: false,
  failThreshold: ''
};

export default StudentAssessmentGradeData;
