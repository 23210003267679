/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import Decimal from 'decimal.js-light';
import { cloneDeep, groupBy, isEqual, orderBy } from 'lodash';
import { cohortSelectorByMatchParams } from '../../../redux/selectors/cohortsSelectors';
import { doGetCohort } from '../../../redux/actions/cohortActions';
import { doGetCohortAssessmentGrid } from '../../../redux/actions/assessmentGridActions';
import { cohortRolesStatus } from '../../../helpers/constants';
import { diffInDays } from '../../../helpers/date-fns.utils';
import {
  formatFullName
} from '../../../helpers/format/user.format';
import { useParams } from 'react-router-dom';
import CohortAssessmentGridModal from './CohortAssessmentGridModal';

const processData = (
  students,
  scores,
  startDate,
  endDate,
  mappedBlocksToCollection
) => {
  const mappedScores = groupBy(scores, 'studentUuid');
  const summaryRows = {};
  const activeStudents = [];
  const inactiveStudents = [];
  let totalFirstAttemptPassRate = 0;

  students.map(s => {
    const latestAssBlockResults = {};
    const collectionProgress = {};
    const copiedStudent = cloneDeep(s);

    copiedStudent['studentFullName'] =
      s.user?.firstName && s.user?.lastName ? formatFullName(s.user) : 'N/A';

    // there can be multiple assessment block results for the same block and user
    // so we only take the latest one (the one with the highest attempt number for this block and user)
    copiedStudent.user.assessment_block_results.map(abr => {
      if (
        !latestAssBlockResults[abr.assessmentBlockUuid] ||
        latestAssBlockResults[abr.assessmentBlockUuid].blockAttempt <
          abr.blockAttempt
      ) {
        let blockScoreRel = new Decimal(0);
        let blockScorePot = new Decimal(0);
        let date = null;

        // assessment grid should only report the attempts that were passed
        // so we need to filter the student scores by the assessment block uuid and
        // attempt (we need to calculate only the score for the passed attempt)
        const scoresByAssessmentBlockUuid = groupBy(
          mappedScores[s.userUuid],
          'assessmentBlockUuid'
        );

        const scoresByAssessmentBlockUuidAndAttempt = groupBy(
          scoresByAssessmentBlockUuid[abr.assessmentBlockUuid],
          'attempt'
        );

        const isAttemptPassed = scoresByAssessmentBlockUuidAndAttempt[
          abr.blockAttempt
        ]?.every(s => new Decimal(s.relValue).gt(0) || s.rubricItem === 'Self-Assessment');

        if (!scoresByAssessmentBlockUuidAndAttempt[abr.blockAttempt]) return;

        // we calculate the score for the block (should be between 0 and 100)
        // and we take the date when it was last graded
        scoresByAssessmentBlockUuidAndAttempt[abr.blockAttempt].map(bs => {
          if (bs.assessmentBlockUuid === abr.assessmentBlockUuid) {
            blockScoreRel = blockScoreRel.add(new Decimal(bs.relValue));
            blockScorePot = blockScorePot.add(new Decimal(bs.potValue));
            if (bs.attempt === abr.blockAttempt) {
              date = bs.date;
            }
          }
        });

        const blockScoreDecimal = blockScoreRel.div(blockScorePot).times(100);
        const blockScore = blockScoreDecimal.toFixed(2).toString();

        if ((date && startDate < date && date < endDate)) {
          latestAssBlockResults[abr.assessmentBlockUuid] = {
            ...latestAssBlockResults[abr.assessmentBlockUuid],
            blockAttempt: abr.blockAttempt,
            failReport: {
              isPassed: isAttemptPassed,
              passDate: date,
              blockScore: isAttemptPassed ? blockScore : 0,
              attempt: abr.blockAttempt
            }
          }
        }

        if (isAttemptPassed) {
          latestAssBlockResults[abr.assessmentBlockUuid] = {
            ...latestAssBlockResults[abr.assessmentBlockUuid],
            ...abr,
            blockScore,
            passDate: date,
          }
        }
      }
    });

    let latestPassDate = null;
    let passedInFirstAttempt = 0;
    const copyLatestAssBlockResults = cloneDeep(latestAssBlockResults);

    Object.keys(latestAssBlockResults).map(key => {
      const passDate = latestAssBlockResults[key].passDate;

      // we store the date for the latest completed assessment
      // we will need it if the student has completed all the assessments
      // so we can display the total days of "competence"
      if (latestPassDate === null || passDate > latestPassDate) {
        latestPassDate = passDate;
      }

      // we add all the assessments that were passed in the first attempt
      if (latestAssBlockResults[key].blockAttempt === 1) {
        passedInFirstAttempt += 1;
      }

      // we calculate the earliest and latest assessment completion between all the ACTIVE students
      // basically, which student completed first the assessment vs which completed last
      // and the difference in days between these 2
      if (
        copiedStudent.status === cohortRolesStatus.competent ||
        copiedStudent.status === cohortRolesStatus.active ||
        copiedStudent.status === cohortRolesStatus.complete
      ) {
        if (!summaryRows[key]) {
          summaryRows[key] = {
            latest: passDate,
            earliest: passDate,
            span: 0
          };
        }

        if (summaryRows[key].latest < passDate) {
          summaryRows[key].latest = passDate;
        }

        if (summaryRows[key].earliest > passDate) {
          summaryRows[key].earliest = passDate;
        }

        if (summaryRows[key].earliest && summaryRows[key].latest) {
          summaryRows[key].span = diffInDays(
            summaryRows[key].latest,
            summaryRows[key].earliest
          );
        }
      }

      // we filter out the results that are not in the requested interval
      if (startDate > passDate || passDate > endDate) {
        delete copyLatestAssBlockResults[key].blockAttempt;
        delete copyLatestAssBlockResults[key].passDate;
        delete copyLatestAssBlockResults[key].blockScore;
      } else {
        // we calculate the number of completed assessments within a collection
        if (!collectionProgress[mappedBlocksToCollection[key]]) {
          collectionProgress[mappedBlocksToCollection[key]] = 0;
        }

        collectionProgress[mappedBlocksToCollection[key]] += 1;
      }
    });

    copiedStudent.user.assessment_block_results = copyLatestAssBlockResults;
    copiedStudent.user['numberOfPassedAss'] = Object.keys(
      latestAssBlockResults
    ).length;
    copiedStudent.user['firstTimePassRate'] =
      copiedStudent.user['numberOfPassedAss'] === 0 ||
      passedInFirstAttempt === 0
        ? 0.0
        : (passedInFirstAttempt / copiedStudent.user['numberOfPassedAss']) *
          100;
    copiedStudent.user['latestPassDate'] = latestPassDate;
    copiedStudent.user['collectionProgress'] = collectionProgress;

    if (
      copiedStudent.status === cohortRolesStatus.competent ||
      copiedStudent.status === cohortRolesStatus.active ||
      copiedStudent.status === cohortRolesStatus.complete
    ) {
      activeStudents.push(copiedStudent);
      totalFirstAttemptPassRate += copiedStudent.user['firstTimePassRate'];
    } else {
      copiedStudent['inactive'] = true;
      inactiveStudents.push(copiedStudent);
    }
  });

  // we sort the active students and put the inactive students at the end of the array
  const sortedActiveStudents = orderBy(
    activeStudents,
    ['user.numberOfPassedAss'],
    ['desc']
  );
  sortedActiveStudents.push(...inactiveStudents);

  return {
    students: sortedActiveStudents,
    summaryRows,
    totalFirstAttemptPassRate: totalFirstAttemptPassRate / activeStudents.length
  };
};

const CohortAssessmentGridContainer = () => {
  const dispatch = useDispatch();
  const { cohortUuid } = useParams();
  const [startDate, selectStartDate] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );
  const [endDate, selectEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const students = useSelector(
    state => state.cohortAssessmentReportState.students
  );
  const scores = useSelector(state => state.cohortAssessmentReportState.scores);
  const mappedBlocksToCollection = useSelector(
    state => state.cohortAssessmentReportState.mappedBlocksToCollection
  );

  useEffect(() => {
    if (cohortUuid) {
      dispatch(doGetCohort(cohortUuid));
      dispatch(doGetCohortAssessmentGrid(cohortUuid));
    }
  }, []);

  const cohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    isEqual
  );

  useEffect(() => {
    if (cohort) {
      selectStartDate(cohort.startDate);
      selectEndDate(cohort.endDate);
    }
  }, [cohort]);

  const processedData = useMemo(() => {
    return processData(
      students,
      scores,
      startDate,
      endDate,
      mappedBlocksToCollection
    );
  }, [students, scores, startDate, endDate, mappedBlocksToCollection]);

  return (
    <CohortAssessmentGridModal
      startDate={startDate}
      endDate={endDate}
      cohortUuid={cohortUuid}
      cohortStartDate={cohort?.startDate}
      cohortEndDate={cohort?.endDate}
      selectStartDate={selectStartDate}
      selectEndDate={selectEndDate}
      students={processedData.students}
      summaryRows={processedData.summaryRows}
      totalFirstAttemptPassRate={processedData.totalFirstAttemptPassRate}
    />
  );
};

export default CohortAssessmentGridContainer;
