import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import FacultyAlternativeScoreViewStudentPanel from './FacultyAlternativeScoreViewStudentPanel';

const FacultyAlternativeScoreViewStudentPanelContainer = ({
  assessmentScores,
  faculty,
  scoreType,
  studentFullName,
  studentTotalPotentialScore,
  studentTotalRelativeScore,
  studentUuid
}) => {
  const studentScores = useMemo(
    () => assessmentScores.filter(score => score.studentUuid === studentUuid),
    [assessmentScores, studentUuid]
  );
  const facultyUuids = useMemo(
    () => faculty.map(facultyUser => facultyUser.userUuid),
    [faculty]
  );
  const studentScoresByFaculty = useMemo(
    () =>
      _.reduce(
        studentScores,
        (acc, score) => {
          const { grader1Uuid, grader2Uuid, grader3Uuid } = score;
          const cur = {};
          if (grader1Uuid && grader1Uuid.length > 1) {
            if (!acc[grader1Uuid]) {
              acc[grader1Uuid] = [];
            }
            const grader1Scores = acc[grader1Uuid];
            grader1Scores.push(score);
            cur[grader1Uuid] = grader1Scores;
          }
          if (grader2Uuid && grader2Uuid.length > 1) {
            if (!acc[grader2Uuid]) {
              acc[grader2Uuid] = [];
            }
            const grader2Scores = acc[grader2Uuid];
            grader2Scores.push(score);
            cur[grader2Uuid] = grader2Scores;
          }
          if (grader3Uuid && grader3Uuid.length > 1) {
            if (!acc[grader3Uuid]) {
              acc[grader3Uuid] = [];
            }
            const grader3Scores = acc[grader3Uuid];
            grader3Scores.push(score);
            cur[grader3Uuid] = grader3Scores;
          }
          return { ...acc, ...cur };
        },
        facultyUuids.reduce(
          (obj, facultyUuid) => ({
            ...obj,
            [facultyUuid]: []
          }),
          {}
        )
      ),
    [facultyUuids, studentScores]
  );

  const shouldReturn = new Decimal(studentTotalPotentialScore).greaterThan(0);
  const decimalStudentTotalPotentialScore = new Decimal(
    studentTotalPotentialScore
  ).toFixed(2);
  const decimalStudentTotalRelativeScore = new Decimal(
    studentTotalRelativeScore
  ).toFixed(2);

  return shouldReturn ? (
    <FacultyAlternativeScoreViewStudentPanel
      faculty={faculty}
      scoreType={scoreType}
      studentFullName={studentFullName}
      studentTotalPotentialScore={decimalStudentTotalPotentialScore}
      studentTotalRelativeScore={decimalStudentTotalRelativeScore}
      studentScoresByFaculty={studentScoresByFaculty}
    />
  ) : null;
};

FacultyAlternativeScoreViewStudentPanelContainer.propTypes = {
  assessmentScores: PropTypes.array,
  faculty: PropTypes.array,
  scoreType: PropTypes.string,
  studentFullName: PropTypes.string,
  studentTotalPotentialScore: PropTypes.number,
  studentTotalRelativeScore: PropTypes.number,
  studentUuid: PropTypes.string
};
FacultyAlternativeScoreViewStudentPanelContainer.defaultProps = {
  assessmentScores: [],
  faculty: [],
  scoreType: '',
  studentFullName: '',
  studentTotalPotentialScore: 0,
  studentTotalRelativeScore: 0,
  studentUuid: ''
};

export default FacultyAlternativeScoreViewStudentPanelContainer;
