import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';
import { ButtonPrimary, ButtonInline } from '@xcomp/xcomp-design-library';
import { ContainerActions } from '../../Library/Layout';
import FormErrors from '../../Library/FormErrors';
import UserTabCheckUploadContainer from './UserTabCheckUpload/UserTabCheckUploadContainer';
import UploadErrorsNew from '../../Library/UploadErrorsNew';

import { visibleToInstitutionAdmin } from '../../../helpers/authorization';

import UserTabSummary from './UserTabCheckSummary';

const Clear = styled(ButtonInline)`
  && {
    margin-right: 40px;
  }
`;

const ErrorLayout = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 4.5rem;
  padding-right: 4.5rem;
`;

const ErrorInfo = styled.div`
  margin-top: 20px;
`;

const InstitutionAdminActions = visibleToInstitutionAdmin(props => (
  <div>
    <Clear onClick={() => props.handleClearResults()}>Clear Results</Clear>
    <ButtonPrimary onClick={() => props.handleCheckOpen()}>Check</ButtonPrimary>
  </div>
));

export default function UserTabCheck({
  status,
  openCheckUpload,
  handleCheckOpen,
  handleCheckClose,
  handleClearResults
}) {
  return (
    <>
      <ContainerActions paddingTop="20px">
        <InstitutionAdminActions
          handleClearResults={handleClearResults}
          handleCheckOpen={handleCheckOpen}
        />
      </ContainerActions>
      {status.usersCheck && <LinearProgress />}
      {status.usersCheckStatus === 'Failed' && (
        <>
          <ErrorLayout>
            <FormErrors
              hasErrors
              message={`Error ${status?.error?.message || ''}`}
            />
            <ErrorInfo>
              <UploadErrorsNew
                error={status.error}
                show={status.usersCheckStatus === 'Failed'}
              />
            </ErrorInfo>
          </ErrorLayout>
        </>
      )}

      <UserTabSummary status={status} />
      <UserTabCheckUploadContainer
        openCheckUpload={openCheckUpload}
        handleCheckClose={handleCheckClose}
      />
    </>
  );
}

UserTabCheck.propTypes = {
  status: PropTypes.object,
  openCheckUpload: PropTypes.bool,
  handleCheckOpen: PropTypes.func,
  handleCheckClose: PropTypes.func,
  handleClearResults: PropTypes.func
};

UserTabCheck.defaultProps = {
  status: undefined,
  openCheckUpload: false,
  handleCheckOpen: undefined,
  handleCheckClose: undefined,
  handleClearResults: undefined
};
