import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { FormHelperText, FormLabel } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerBox = styled(({ disabled, ...rest }) => <div {...rest} />)`
  && {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    font-family: ${props => props.theme.fonts.primary};
    & > div {
      width: 100%;
    }

    label.Mui-focused {
      color: ${props => props.theme.fontColors.dark};
    }

    .react-datepicker__input-container,
    .react-datepicker-wrapper {
      width: 100%;
    }
    .react-datepicker__time-container {
      width: 90px;
    }
    .react-datepicker__navigation--next--with-time:not(
        .react-datepicker__navigation--next--with-today-button
      ) {
      right: 100px;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box.react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box,
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box {
      width: 100%;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list {
      padding-right: 0;
      overflow-y: scroll;
    }

    input {
      width: 100%;
      font-size: 1rem;
      padding: 17px 0px 17px 10px;
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
      border-color: rgba(0, 0, 0, 0.23);

      &:hover:not([disabled]) {
        border: 1px solid ${props => props.theme.colors.grey[300]};
        outline: 0;
      }

      &:active,
      &:focus {
        border: ${props =>
          props.disabled
            ? `1px solid ${props => props.theme.colors.grey[300]}`
            : `2px solid ${props => props.theme.colors.primary}`};
        outline: 0;
      }
    }

    input:disabled {
      color: ${props => props.theme.colors.grey[300]};
    }

    ul {
      padding: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    .react-datepicker__time-list {
      padding-right: 0;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item {
      width: 100%;
      border-right: 0;
      overflow-y: hidden;
      overflow-x: hidden;
      padding: 8px 7px;
      padding-right: 27px; /* Increase/decrease this value for cross-browser compatibility */
      box-sizing: border-box; /* So the width will be 100% + 17px */
    }

    .react-datepicker__month-container {
      border-right: 1px solid ${props => props.theme.colors.grey[400]};
    }

    .react-datepicker-popper {
      z-index: 130;
    }

    .react-datepicker__time-container {
      border-left: 0;
    }

    &.error {
      label {
        color: ${props => props.theme.colors.status.error};
        padding-left: 5px;
      }
      input {
        border: 1px solid ${props => props.theme.colors.status.error};
        margin-bottom: 0;
        &:active,
        &:hover {
          border: 1px solid ${props => props.theme.colors.status.error};
        }

        &:focus {
          border: 2px solid ${props => props.theme.colors.status.error};
        }
      }
    }

    ${props =>
      props.disabled
        ? css`
            label,
            label.Mui-focused {
              color: ${props => props.theme.colors.grey[300]};
            }
            &:hover,
            &:active,
            &:focus {
              label {
                color: ${props => props.theme.colors.grey[300]};
              }
            }

            input {
              border-color: ${props => props.theme.colors.grey[300]};

              &:hover:not([disabled]) {
                border: 1px solid ${props => props.theme.colors.grey[300]};
                outline: 0;
              }

              &:active,
              &:focus {
                border: 1px solid ${props => props.theme.colors.grey[300]};
                outline: 0;
              }
            }
          `
        : ''}
  }
`;

const FormLabelSC = styled(FormLabel)`
  && {
    position: relative;
    top: 9px;
    left: 12px;
    z-index: 1;
    background: ${props => props.theme.colors.white};
    font-size: 16px;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    padding-left: 4px;
    padding-right: 4px;
  }
`;

const FormHelperTextSC = styled(FormHelperText)`
  && {
    padding-left: 12px;
  }
`;

const CustomInput = styled.input`
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.87);
  }
`;

const Date = ({
  name,
  label,
  value,
  dataTestId,
  indentifier,
  className,
  hasErrors,
  handleChange,
  handleValidate,
  disabled,
  ...rest
}) => {
  const appromixateTimeZone = moment.tz.guess();

  const error = hasErrors
    ? handleValidate(value)
    : { invalid: false, message: '' };

  const dateTimeZone = value
    ? momentTz(value).tz(appromixateTimeZone).toDate()
    : null;

  return (
    <DatePickerBox
      disabled={disabled}
      className={`${className} ${error.invalid ? 'error' : ''}`}
    >
      {label && <FormLabelSC>{label}</FormLabelSC>}
      <DatePicker
        data-testid={dataTestId}
        className="datetime-picker"
        selected={dateTimeZone}
        onChange={date => handleChange(indentifier, name, date)}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dateFormat="MMMM d, yyyy"
        customInput={<CustomInput data-testid={dataTestId} />}
        disabled={disabled}
        {...rest}
      />
      {error.invalid && (
        <FormHelperTextSC error={error.invalid}>
          {error.message}
        </FormHelperTextSC>
      )}
    </DatePickerBox>
  );
};

Date.propTypes = {
  dataTestId: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object,
    PropTypes.string
  ]),
  name: PropTypes.string,
  indentifier: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  handleValidate: PropTypes.func
};

Date.defaultProps = {
  dataTestId: '',
  value: null,
  name: '',
  indentifier: '',
  className: '',
  label: '',
  disabled: false,
  hasErrors: false,
  handleChange: undefined,
  handleValidate: undefined
};

export default Date;
