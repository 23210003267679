import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';
import { assessmentTypesMap } from '../../helpers/constants';

export const assessmentTypesSelector = createSelector(
  state => state,
  redux => {
    const assessmentTypesSelectorORM = ormCreateSelector(orm.AssessmentType);
    return assessmentTypesSelectorORM(redux);
  }
);

export const reportAssessmentTypesSelector = createSelector(
  state => state,
  (_, isDidactic) => isDidactic,
  (redux, isDidactic) => {
    const assessmentTypesSelectorORM = ormCreateSelector(orm.AssessmentType);
    const all = assessmentTypesSelectorORM(redux);
    return all.filter(
      at => isDidactic 
            ? (at.name === assessmentTypesMap.basicDidactic || at.name === assessmentTypesMap.appliedDidactic)
            : (at.name === assessmentTypesMap.qualitativeSkills || at.name === assessmentTypesMap.documentProject)
    );
  }
);

export const assessmentAssociationsSelector = createSelector(
  state => state,
  redux => {
    const assessmentAssociationsSelectorORM = ormCreateSelector(
      orm.AssessmentAssociation
    );
    return assessmentAssociationsSelectorORM(redux);
  }
);

export const assessmentTechniquesSelector = createSelector(
  state => state,
  redux => {
    const assessmentTechniquesSelectorORM = ormCreateSelector(
      orm.AssessmentTechnique
    );
    return assessmentTechniquesSelectorORM(redux);
  }
);

export const qualitativeAssessmentTechniquesSelector = createSelector(
  state => state,
  redux => {
    const assessmentTechniquesSelectorORM = ormCreateSelector(
      orm.AssessmentTechnique
    );
    const all = assessmentTechniquesSelectorORM(redux);
    return all.map(at => ({
      uuid: at.uuid,
      name: at.technique
    }));
  }
);
