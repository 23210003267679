import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Body1, Body2, Caption } from '@xcomp/xcomp-design-library';

import DeleteModal from '../../../Library/Modal/DeleteModal/DeleteModal';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import { Column } from '../../../Library/FormComponents';
import { sortByProperty } from '../../../../helpers/utilities';

const AssessmentCollectionAccordion = styled(
  ({ withBottomBorder, withTopBorder, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  width: 100%;

  .align_right {
    margin-left: auto;

    span {
      font-size: 12px;
      letter-spacing: 0.4;
      color: ${props => props.theme.fontColors.dark};
    }
  }
`;

const CollectionAssessmentsLayout = styled.div`
  padding-left: 132px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 24px;
  p {
    margin: 0;
  }
`;

const AssessmentCollectionRow = ({
  assessmentCollection,
  isDeleteModalOpen,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete,
  withTopBorder
}) => {
  // eslint-disable-next-line prefer-const
  let history = useHistory();

  const { cohortUuid } = useParams();

  const navigateEditOnClick = () => {
    const historyString = `/program-management/cohort/${cohortUuid}/assessment-collection/${assessmentCollection.uuid}/edit`;
    history.push(historyString);
  };

  const navigateSyllabusOnClick = () => {
    const historyString = `/program-management/cohort/${cohortUuid}/assessment-collection/${assessmentCollection.uuid}/syllabus`;
    history.push(historyString);
  };

  const navigateSyllabusManagementOnClick = () => {
    const historyString = `/program-management/cohort/${cohortUuid}/assessment-collection/${assessmentCollection.uuid}/syllabus-management`;
    history.push(historyString);
  };

  const assessmentCollectionActions = [
    {
      label: 'Edit Collection',
      onClick: navigateEditOnClick,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Edit Syllabus',
      onClick: navigateSyllabusOnClick,
      shouldOpenOnClick: false,
      icon: 'assessment'
    },
    {
      label: 'Manage Syllabus',
      onClick: navigateSyllabusManagementOnClick,
      shouldOpenOnClick: false,
      icon: 'gear'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  return (
    <>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={handleDeleteModalClose}
        handleDelete={handleDelete}
        type="Assessment Collection"
      />
      <AssessmentCollectionAccordion
        withTopBorder={withTopBorder}
        withBottomBorder
        expandWidth="66px"
        height="38px"
        columnOne={`${assessmentCollection.title}`}
        columnTwo={`Assessments - ${assessmentCollection?.cohort_assessments?.length}`}
        columnTwoClassName="align_right"
        actions={assessmentCollectionActions}
      >
        <CollectionAssessmentsLayout>
          {assessmentCollection &&
          assessmentCollection.cohort_assessments &&
          assessmentCollection.cohort_assessments.length > 0 ? (
            <>
              <Column size="100%">
                <Caption>Assessment</Caption>
              </Column>
              {assessmentCollection.cohort_assessments
                .sort((a, b) => sortByProperty(a, b, 'title'))
                .map(assessment => (
                  <React.Fragment key={assessment.uuid}>
                    <Column size="100%">
                      <Body2>{assessment.title}</Body2>
                    </Column>
                  </React.Fragment>
                ))}
            </>
          ) : (
            <Body1>There are no assessments in this collection</Body1>
          )}
        </CollectionAssessmentsLayout>
      </AssessmentCollectionAccordion>
    </>
  );
};

AssessmentCollectionRow.propTypes = {
  assessmentCollection: PropTypes.object.isRequired,
  withTopBorder: PropTypes.bool,
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func
};

AssessmentCollectionRow.defaultProps = {
  withTopBorder: false,
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined
};

export default AssessmentCollectionRow;
