import {
  GET_PART,
  GET_PART_SUCCESS,
  GET_PART_ERROR,
  GET_PART_FACULTY,
  GET_PART_FACULTY_SUCCESS,
  GET_PART_FACULTY_ERROR,
  PATCH_PART_STATUS,
  PATCH_PART_STATUS_SUCCESS,
  PATCH_PART_STATUS_ERROR,
  PATCH_PART_LOCKED,
  PATCH_PART_LOCKED_SUCCESS,
  PATCH_PART_LOCKED_ERROR,
  PATCH_PART_CALCULATE_GRADE,
  PATCH_PART_CALCULATE_GRADE_SUCCESS,
  PATCH_PART_CALCULATE_GRADE_ERROR,
  PATCH_PART_UNDO_CALCULATED_GRADE,
  PATCH_PART_UNDO_CALCULATED_GRADE_SUCCESS,
  PATCH_PART_UNDO_CALCULATED_GRADE_ERROR,
  PUT_PART_GRADE_DELAYED_STUDENT,
  PUT_PART_GRADE_DELAYED_STUDENT_SUCCESS,
  PUT_PART_GRADE_DELAYED_STUDENT_ERROR,
  DELETE_PART_GRADE_DELAYED_STUDENT,
  DELETE_PART_GRADE_DELAYED_STUDENT_SUCCESS,
  DELETE_PART_GRADE_DELAYED_STUDENT_ERROR,
  PUT_PART_DELAYED_STATUS_COMMIT,
  PUT_PART_DELAYED_STATUS_COMMIT_SUCCESS,
  PUT_PART_DELAYED_STATUS_COMMIT_ERROR,
  PUT_PART_STATUS_COMMIT,
  PUT_PART_STATUS_COMMIT_SUCCESS,
  PUT_PART_STATUS_COMMIT_ERROR,
  COMPLETE_STATUS_CHECK_ERROR,
  APPROVE_STATUS_CHECK_ERROR,
  POST_CLONE_EXISTING_PART,
  POST_CLONE_EXISTING_PART_SUCCESS,
  POST_CLONE_EXISTING_PART_ERROR
} from '../actions-type';

const doGetPart = partUuid => ({
  type: GET_PART,
  payload: {
    partUuid
  }
});

const doGetPartSuccess = result => ({
  type: GET_PART_SUCCESS,
  payload: result
});

const doGetPartError = error => ({
  type: GET_PART_ERROR,
  payload: error
});

const doGetPartFaculty = partUuid => ({
  type: GET_PART_FACULTY,
  payload: {
    partUuid
  }
});

const doGetPartFacultySuccess = result => ({
  type: GET_PART_FACULTY_SUCCESS,
  payload: result
});

const doGetPartFacultyError = error => ({
  type: GET_PART_FACULTY_ERROR,
  payload: error
});

const doPatchPartStatus = part => ({
  type: PATCH_PART_STATUS,
  payload: {
    part
  }
});

const doPatchPartStatusSuccess = result => ({
  type: PATCH_PART_STATUS_SUCCESS,
  payload: result
});

const doPatchPartStatusError = error => ({
  type: PATCH_PART_STATUS_ERROR,
  payload: error
});

const doCompleteStatusCheck = () => ({
  type: COMPLETE_STATUS_CHECK_ERROR
});

const doApproveStatusCheck = () => ({
  type: APPROVE_STATUS_CHECK_ERROR
});

const doPatchPartLock = part => ({
  type: PATCH_PART_LOCKED,
  payload: {
    part
  }
});

const doPatchPartLockSuccess = result => ({
  type: PATCH_PART_LOCKED_SUCCESS,
  payload: result
});

const doPatchPartLockError = error => ({
  type: PATCH_PART_LOCKED_ERROR,
  payload: error
});

const doPatchPartCalculateGrade = partUuid => ({
  type: PATCH_PART_CALCULATE_GRADE,
  payload: {
    partUuid
  }
});

const doPatchPartCalculateGradeSuccess = result => ({
  type: PATCH_PART_CALCULATE_GRADE_SUCCESS,
  payload: result
});

const doPatchPartCalculateGradeError = (error, partUuid) => ({
  type: PATCH_PART_CALCULATE_GRADE_ERROR,
  payload: { error: { message: error }, partUuid }
});

const doPatchPartUndoCalculatedGrade = partUuid => ({
  type: PATCH_PART_UNDO_CALCULATED_GRADE,
  payload: {
    partUuid
  }
});

const doPatchPartUndoCalculatedGradeSuccess = result => ({
  type: PATCH_PART_UNDO_CALCULATED_GRADE_SUCCESS,
  payload: result
});

const doPatchPartUndoCalculatedGradeError = error => ({
  type: PATCH_PART_UNDO_CALCULATED_GRADE_ERROR,
  payload: { error: { message: error } }
});

const doPutPartStatusCommit = part => ({
  type: PUT_PART_STATUS_COMMIT,
  payload: {
    part
  }
});

const doPutPartDelayedStudentGrade = ({ partUuid, studentUuid }) => ({
  type: PUT_PART_GRADE_DELAYED_STUDENT,
  payload: {
    partUuid,
    studentUuid
  }
});

const doPutPartDelayedStudentGradeSuccess = result => ({
  type: PUT_PART_GRADE_DELAYED_STUDENT_SUCCESS,
  payload: result
});

const doPutPartDelayedStudentGradeError = error => ({
  type: PUT_PART_GRADE_DELAYED_STUDENT_ERROR,
  payload: error
});

const doPostCloneExistingPart = ({ partUuid, partUuidExist }) => ({
  type: POST_CLONE_EXISTING_PART,
  payload: {
    partUuid,
    partUuidExist
  }
});

const doPostCloneExistingPartSuccess = result => ({
  type: POST_CLONE_EXISTING_PART_SUCCESS,
  payload: result
});

const doPostCloneExistingPartError = error => ({
  type: POST_CLONE_EXISTING_PART_ERROR,
  payload: error
});

const doDeletePartDelayedStudentGrade = ({ partUuid, studentUuid }) => ({
  type: DELETE_PART_GRADE_DELAYED_STUDENT,
  payload: {
    partUuid,
    studentUuid
  }
});

const doDeletePartDelayedStudentGradeSuccess = result => ({
  type: DELETE_PART_GRADE_DELAYED_STUDENT_SUCCESS,
  payload: result
});

const doDeletePartDelayedStudentGradeError = error => ({
  type: DELETE_PART_GRADE_DELAYED_STUDENT_ERROR,
  payload: error
});

const doPutPartDelayedStudentStatusCommit = ({
  partUuid,
  studentUuid,
  delayedStatusCommit,
  justificationMessage
}) => ({
  type: PUT_PART_DELAYED_STATUS_COMMIT,
  payload: {
    partUuid,
    studentUuid,
    delayedStatusCommit,
    justificationMessage
  }
});

const doPutPartDelayedStudentStatusCommitSuccess = result => ({
  type: PUT_PART_DELAYED_STATUS_COMMIT_SUCCESS,
  payload: result
});

const doPutPartDelayedStudentStatusCommitError = error => ({
  type: PUT_PART_DELAYED_STATUS_COMMIT_ERROR,
  payload: error
});

const doPutPartStatusCommitSuccess = result => ({
  type: PUT_PART_STATUS_COMMIT_SUCCESS,
  payload: result
});

const doPutPartStatusCommitError = error => ({
  type: PUT_PART_STATUS_COMMIT_ERROR,
  payload: error
});

export {
  doCompleteStatusCheck,
  doApproveStatusCheck,
  doGetPart,
  doGetPartSuccess,
  doGetPartError,
  doGetPartFaculty,
  doGetPartFacultySuccess,
  doGetPartFacultyError,
  doPatchPartLock,
  doPatchPartLockSuccess,
  doPatchPartLockError,
  doPatchPartStatus,
  doPatchPartStatusSuccess,
  doPatchPartStatusError,
  doPatchPartCalculateGrade,
  doPatchPartCalculateGradeSuccess,
  doPatchPartCalculateGradeError,
  doPatchPartUndoCalculatedGrade,
  doPatchPartUndoCalculatedGradeSuccess,
  doPatchPartUndoCalculatedGradeError,
  doPutPartStatusCommit,
  doPutPartStatusCommitSuccess,
  doPutPartStatusCommitError,
  doPutPartDelayedStudentGrade,
  doPutPartDelayedStudentGradeSuccess,
  doPutPartDelayedStudentGradeError,
  doDeletePartDelayedStudentGrade,
  doDeletePartDelayedStudentGradeSuccess,
  doDeletePartDelayedStudentGradeError,
  doPutPartDelayedStudentStatusCommit,
  doPutPartDelayedStudentStatusCommitSuccess,
  doPutPartDelayedStudentStatusCommitError,
  doPostCloneExistingPart,
  doPostCloneExistingPartSuccess,
  doPostCloneExistingPartError
};
