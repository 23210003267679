import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import InstitutionRigor from './InstitutionRigor';
import { doGetProgramCohorts } from '../../../../redux/actions/cohortActions';
import { selectCohortsByProgramDesc } from '../../../../redux/selectors/cohortsSelectors';
import { doPostInstitutionRigor } from '../../../../redux/actions/institutionRigorActions';
import { doGetRigorHistoricalReport } from '../../../../redux/actions/rigorHistoricalReportActions';
import { selectRigorHistoricalReport } from '../../../../redux/selectors/rigorHistoricalReporteSelectors';

export default function InstitutionRigorContainer() {
  const [displayName, setDisplayName] = useState(false);
  const [invalidRange, setInvalidRange] = useState(false);
  const dispatch = useDispatch();
  const [cohortStartSelected, setCohortStartSelected] = useState(undefined);
  const [cohortEndSelected, setCohortEndSelected] = useState(undefined);
  const programUuid = useSelector(state => state.userState.selectedProgramUuid);
  const cohorts = useSelector(state =>
    selectCohortsByProgramDesc(state, programUuid)
  );

  const optionsCohorts = cohorts?.map(cohort => ({
    value: cohort.uuid,
    label: cohort.name
  }));

  const { createInstitutionRigor } = useSelector(state => state.crudState);

  const historicalSelection = useSelector(state =>
    selectRigorHistoricalReport(state, programUuid)
  );

  const cohortStartUuid = historicalSelection?.cohortStartUuid;
  const cohortEndUuid = historicalSelection?.cohortEndUuid;

  useEffect(() => {
    if (cohortStartUuid && cohortEndUuid) {
      setCohortStartSelected(cohortStartUuid);
      setCohortEndSelected(cohortEndUuid);
    }
  }, [cohortStartUuid, cohortEndUuid]);

  useEffect(() => {
    if (programUuid) {
      dispatch(doGetProgramCohorts(programUuid));
      dispatch(doGetRigorHistoricalReport(programUuid));
    }
  }, [dispatch, programUuid]);

  const handleCohortStart = event => {
    const { value } = event.target;
    setCohortStartSelected(value);
  };

  const handleCohortEnd = event => {
    const { value } = event.target;
    setCohortEndSelected(value);
  };

  const handleRigorIndex = () => {
    const cohortStart = _.findIndex(cohorts, { uuid: cohortStartSelected });
    const cohortEnd = _.findIndex(cohorts, { uuid: cohortEndSelected });

    if (cohortEnd < cohortStart) {
      setInvalidRange(true);
    } else if (cohortStart === cohortEnd) {
      dispatch(
        doPostInstitutionRigor(
          [cohorts[cohortStart]],
          programUuid,
          cohorts[cohortStart].uuid,
          cohorts[cohortEnd].uuid
        )
      );
      setInvalidRange(false);
    } else if (cohortStart < cohortEnd) {
      const range = cohorts.slice(cohortStart, cohortEnd + 1);
      dispatch(
        doPostInstitutionRigor(
          range,
          programUuid,
          cohorts[cohortStart].uuid,
          cohorts[cohortEnd].uuid
        )
      );
      setInvalidRange(false);
    }
  };

  const handleDisplayName = () => {
    setDisplayName(!displayName);
  };

  return (
    <InstitutionRigor
      displayName={displayName}
      programUuid={programUuid}
      loading={createInstitutionRigor}
      invalidRange={invalidRange}
      cohortStartSelected={cohortStartSelected}
      cohortEndSelected={cohortEndSelected}
      optionsCohorts={optionsCohorts}
      handleCohortStart={handleCohortStart}
      handleCohortEnd={handleCohortEnd}
      handleRigorIndex={handleRigorIndex}
      handleDisplayName={handleDisplayName}
    />
  );
}

InstitutionRigorContainer.propTypes = {};

InstitutionRigorContainer.defaultProps = {};
