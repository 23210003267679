import { useMemo } from 'react';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { studentRosterSelectorBySectionUuid } from '../../redux/selectors/sectionsSelectors';
import { sortByDecimalPropertiesAndFullName } from '../utilities';
import { studentRosterSelectorByCohortUuid } from '../../redux/selectors/userCohortsSelectors';

export const useSortReportStudents = ({
  sectionUuid,
  cohortUuid,
  isPartReport,
  isCourseReport,
  isSectionReport
}) => {
  let performanceReport = useSelector(
    state => state.sectionPerformanceOpportunityReport,
    isEqual
  )?.sectionPerformanceReport;

  if (isPartReport) {
    performanceReport = useSelector(
      state => state.partOpportunityReportState,
      isEqual
    );
  }

  if (isCourseReport) {
    performanceReport = useSelector(
      state => state.cohortOpportunityReportState,
      isEqual
    )?.coursePerformanceReport;
  }

  let students = useMemo(() => [], []);

  if (isPartReport || isSectionReport)
    students = useSelector(state =>
      studentRosterSelectorBySectionUuid(state, sectionUuid)
    );

  if (isCourseReport) {
    students = useSelector(state =>
      studentRosterSelectorByCohortUuid(state, cohortUuid)
    );
  }

  const studentUsers = useMemo(
    () => [
      { uuid: 'student_high', firstName: 'aaaaa', lastName: 'aaaaa' },
      { uuid: 'student_average', firstName: 'aaaa', lastName: 'aaaa' },
      ...students.map(student => student.user)
    ],
    [students]
  );

  const sortedStudents = useMemo(() => {
    return performanceReport?.records > 0
      ? [...studentUsers].sort((a, b) => {
          const studentUuidA = a.uuid;
          const studentUuidB = b.uuid;
          return sortByDecimalPropertiesAndFullName(
            {
              totalOpportunityCount: !isCourseReport
                ? performanceReport?.partOpportunityReportStudentSummaryData?.[
                    studentUuidB
                  ]?.totalOpportunityCount
                : performanceReport?.[studentUuidB]?.totalOpportunityCount,
              totalRelativeScore: !isCourseReport
                ? performanceReport?.partOpportunityReportStudentSummaryData?.[
                    studentUuidB
                  ]?.totalRelativeScore
                : performanceReport?.[studentUuidB]?.totalRelativeScore,
              firstName: b.firstName,
              lastName: b.lastName
            },
            {
              totalOpportunityCount: !isCourseReport
                ? performanceReport?.partOpportunityReportStudentSummaryData?.[
                    studentUuidA
                  ]?.totalOpportunityCount
                : performanceReport?.[studentUuidA]?.totalOpportunityCount,
              totalRelativeScore: !isCourseReport
                ? performanceReport?.partOpportunityReportStudentSummaryData?.[
                    studentUuidA
                  ]?.totalRelativeScore
                : performanceReport?.[studentUuidA]?.totalRelativeScore,
              firstName: a.firstName,
              lastName: a.lastName
            },
            'totalOpportunityCount',
            'totalRelativeScore',
            'firstName',
            'lastName',
            true
          );
        })
      : [];
  }, [isCourseReport, performanceReport, studentUsers]);

  return sortedStudents;
};
