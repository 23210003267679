import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { doGetUsersBySearchRoles } from '../../../redux/actions/userActions';

import { usersSelectorBySearchRole } from '../../../redux/selectors/usersSelectors';

import UserTabAppId from './UserTabAppId';

export default function UserTabAppIdContainer() {
  const dispatch = useDispatch();
  const [openAdminAppId, setOpenAdminAppId] = useState(false);
  const [adminAppIdUser, setAdminAppIdUser] = useState(undefined);
  const [openStudentAppId, setOpenStudentAppId] = useState(false);
  const [studentAppIdUser, setStudentAppIdUser] = useState(undefined);

  const usersSearched = useSelector(state => usersSelectorBySearchRole(state));

  const searchUserRoleBy = useSelector(
    state => state.searchState.searchUserRoleBy
  );

  const searchUserRoleInput = useSelector(
    state => state.searchState.searchUserRoleInput
  );

  const searching = useSelector(
    state => state.crudState.readUsersBySearchRoles
  );

  const handleSearch = (field, input) => {
    dispatch(doGetUsersBySearchRoles(field, input));
  };

  const handleAdminAppIdClose = () => {
    setOpenAdminAppId(false);
  };
  const handleAdminAppIdOpen = userUuid => {
    setOpenAdminAppId(true);
    setAdminAppIdUser(userUuid);
  };

  const handleStudentAppIdClose = () => {
    setOpenStudentAppId(false);
  };
  const handleStudentAppIdOpen = userUuid => {
    setOpenStudentAppId(true);
    setStudentAppIdUser(userUuid);
  };

  return (
    <UserTabAppId
      openAdminAppId={openAdminAppId}
      adminAppIdUser={adminAppIdUser}
      openStudentAppId={openStudentAppId}
      studentAppIdUser={studentAppIdUser}
      searchUserRoleBy={searchUserRoleBy}
      searchUserRoleInput={searchUserRoleInput}
      searching={searching}
      usersSearched={usersSearched}
      handleSearch={(field, input) => handleSearch(field, input)}
      handleAdminAppIdClose={handleAdminAppIdClose}
      handleAdminAppIdOpen={handleAdminAppIdOpen}
      handleStudentAppIdClose={handleStudentAppIdClose}
      handleStudentAppIdOpen={handleStudentAppIdOpen}
    />
  );
}

UserTabAppIdContainer.propTypes = {};
UserTabAppIdContainer.defaultProps = {};
