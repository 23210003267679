import React, { useCallback } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useSelector } from 'react-redux';
import { Dialog, DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { CancelButton, SubmitButton } from '../../../../Library/FormComponents';
import QuantitativeSkillsCollectionForm from '../CollectionForm/QuantitativeSkillsCollectionForm';
import {
  errorAtom,
  fetchCollectionsAtom,
  newCollectionAtom,
  newCollectionCreatingAtom,
  newCollectionDataAtom,
  newCollectionModalAtom,
  newCollectionRawAtom
} from '../../state/collectionState';
import { validateStringLength } from '../../../../../helpers/validation/validateGeneric';
import FormErrors from '../../../../Library/FormErrors';

const NewCollectionModal = () => {
  const programUuid = useSelector(state => state.userState.selectedProgramUuid);

  const [open, setIsOpen] = useAtom(newCollectionModalAtom);
  // const [name, setName] = useAtom(newCollectionNameAtom);
  // const [isDefault, setIsDefault] = useAtom(newCollectionIsDefaultAtom);

  const collection = useAtomValue(newCollectionRawAtom);
  const setCollection = useSetAtom(newCollectionDataAtom);

  const creating = useAtomValue(newCollectionCreatingAtom);
  const createCollection = useSetAtom(newCollectionAtom);
  const getCollections = useSetAtom(fetchCollectionsAtom);
  const error = useAtomValue(errorAtom);

  const handleCreate = useCallback(async () => {
    if (!programUuid) return;

    await createCollection(programUuid);
    await getCollections(programUuid);
  }, [createCollection, programUuid, getCollections]);

  const validateField = useCallback(
    name => validateStringLength(name, 100, true),
    []
  );

  const handleClose = useCallback(() => {
    setIsOpen(!open);
  }, [setIsOpen, open]);

  const handleChange = useCallback(
    e => {
      setCollection({
        [e.target.name]:
          e.target.name === 'is_default_collection'
            ? e.target.checked
            : e.target.value
      });
    },
    [setCollection]
  );

  // const handleNameChange = useCallback(
  //   e => {
  //     setName(e.target.value);
  //   },
  //   [setName]
  // );
  //
  // const handleIsDefaultChange = useCallback(
  //   e => {
  //     setIsDefault(e.target.checked);
  //   },
  //   [setIsDefault]
  // );
  console.log(collection);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Quantitative Skill Collection
      </DialogTitle>
      <DialogContent>
        <QuantitativeSkillsCollectionForm
          collection={collection}
          handleChange={handleChange}
        />
        <FormErrors hasErrors={error?.length} message={error} />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleClose} noHoverBg>
          Cancel
        </CancelButton>
        <SubmitButton
          type="submit"
          onClick={handleCreate}
          disabled={validateField(collection.name).invalid || creating}
        >
          {creating ? 'Creating...' : 'Create'}
        </SubmitButton>
      </DialogActions>
      ;
    </Dialog>
  );
};

export default NewCollectionModal;
