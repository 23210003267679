import { Model, attr, fk, oneToOne } from 'redux-orm';
import {
  DELETE_ASSESSMENT_SUCCESS,
  DELETE_ENCOUNTER_SUCCESS,
  GET_SYLLABUS_PART_SUCCESS,
  GET_SYLLABUS_SUCCESS,
  PATCH_ENCOUNTER_APPROVE_SUCCESS,
  POST_CALCULATE_ENCOUNTER_OBJECTIVES_SUCCESS,
  POST_ENCOUNTER_SUCCESS,
  PUT_ENCOUNTER_DRAFT_SUCCESS,
  PUT_ENCOUNTER_FINAL_SUCCESS
} from '../actions-type';

class Encounter extends Model {
  static reducer(action, Encounter, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_SYLLABUS_SUCCESS: {
        const encounters = [...payload.encounters];
        return encounters.map(encounter => Encounter.upsert(encounter));
      }
      case POST_ENCOUNTER_SUCCESS: {
        const encounter = { ...payload.encounter };
        Encounter.upsert(encounter);
        break;
      }
      case POST_CALCULATE_ENCOUNTER_OBJECTIVES_SUCCESS: {
        const { updatedEncounter } = payload;

        Encounter.upsert(updatedEncounter);
        break;
      }
      case PUT_ENCOUNTER_DRAFT_SUCCESS: {
        const encounter = { ...payload.encounter };
        Encounter.upsert(encounter);
        break;
      }
      case PUT_ENCOUNTER_FINAL_SUCCESS: {
        const encounter = { ...payload.encounter };
        Encounter.upsert(encounter);
        break;
      }
      case DELETE_ENCOUNTER_SUCCESS: {
        const { encounterUuid } = payload;
        Encounter.withId(encounterUuid).delete();
        break;
      }
      case DELETE_ASSESSMENT_SUCCESS: {
        const { assessmentUuid } = payload;

        const linkedEncounter = Encounter.get({
          linkedAssessmentUuid: assessmentUuid
        });

        if (linkedEncounter) {
          linkedEncounter.update({
            linkedAssessmentUuid: null,
            linked_assessment: null,
            status: 'In Progress'
          });
        }

        break;
      }
      case PATCH_ENCOUNTER_APPROVE_SUCCESS: {
        const { encounter } = payload;
        Encounter.upsert(encounter);
        break;
      }
      case GET_SYLLABUS_PART_SUCCESS: {
        const encounters = [...payload.encounters];

        if (encounters?.length > 0) {
          encounters.forEach(encounter => {
            Encounter.upsert(encounter);
          });
        }

        return encounters.map(encounter => Encounter.upsert(encounter));
      }
      default:
        return session;
    }
  }
}

Encounter.fields = {
  uuid: attr(),
  type: attr(),
  title: attr(),
  status: attr(),
  startDate: attr(),
  endDate: attr(),
  location: attr(),
  facultyLead: attr(),
  leadPercent: attr(),
  maxRevus: attr(),
  scheduleType: attr(),
  notes: attr(),
  objectives: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  encounterTypeUuid: fk('EncounterType', 'encounters'),
  linkedAssessmentUuid: oneToOne({
    to: 'Assessment',
    as: 'linked_assessment',
    relatedName: 'linked_encounter'
  }),
  partUuid: fk('Part', 'encounters')
};

Encounter.modelName = 'Encounter';

Encounter.options = {
  idAttribute: 'uuid'
};

export default Encounter;
