import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectCognitiveCreditsBySource } from '../../../../redux/selectors/transcriptCognitiveSelectors';

export default function CognitiveTotalCreditsBySource({
  programUuid,
  userUuid,
  cohortUuid,
  category,
  source
}) {
  const overallCognitiveCreditsBySource = useSelector(state =>
    selectCognitiveCreditsBySource(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source
    )
  );

  const credits = overallCognitiveCreditsBySource || 'N/A';

  return <>{credits}</>;
}

CognitiveTotalCreditsBySource.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string
};

CognitiveTotalCreditsBySource.defaultProps = {
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined
};
