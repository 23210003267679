import {
  CLEAR_PART_OPPORTUNITY_REPORT,
  GET_PART_OPPORTUNITY_REPORT_ERROR,
  GET_PART_OPPORTUNITY_REPORT_SUCCESS,
  GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL,
  GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL_ERROR,
  GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL_SUCCESS,
  GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL,
  GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL_ERROR,
  GET_PART_OPPORTUNITY_REPORT,
  GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL_SUCCESS
} from '../actions-type';

export const INITIAL_STATE = {
  partOpportunityReportAssessmentStatisticData: {},
  partOpportunityReportStudentSummaryData: {},
  partOpportunityReportSummaryStatisticData: {},
  partOpportunityReportBlockDetailLoading: [],
  partOpportunityReportStatisticBlockDetailLoading: [],
  partOpportunityReportBlockDetailData: {},
  partOpportunityReportStatisticBlockDetailData: {},
  isLoading: false,
  records: 0
};

const partOpportunityReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PART_OPPORTUNITY_REPORT: {
      return { ...INITIAL_STATE, isLoading: true };
    }
    case CLEAR_PART_OPPORTUNITY_REPORT:
    case GET_PART_OPPORTUNITY_REPORT_ERROR: {
      return INITIAL_STATE;
    }
    case GET_PART_OPPORTUNITY_REPORT_SUCCESS: {
      const {
        partOpportunityReportAssessmentStatisticData,
        partOpportunityReportStudentSummaryData,
        partOpportunityReportSummaryStatisticData,
        records
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        partOpportunityReportAssessmentStatisticData,

        partOpportunityReportStudentSummaryData: {
          ...partOpportunityReportStudentSummaryData,
          student_high: {
            totalOpportunityCount:
              partOpportunityReportSummaryStatisticData.highOpportunityCount,
            totalRelativeScore:
              partOpportunityReportSummaryStatisticData.highRelativeScore
          },
          student_average: {
            totalOpportunityCount:
              partOpportunityReportSummaryStatisticData.averageOpportunityCount,
            totalRelativeScore:
              partOpportunityReportSummaryStatisticData.averageRelativeScore
          }
        },
        partOpportunityReportSummaryStatisticData,
        records
      };
    }
    case GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL: {
      const { assessmentUuid, studentUuid } = action.payload;
      return {
        ...state,
        partOpportunityReportBlockDetailLoading: [
          ...state.partOpportunityReportBlockDetailLoading,
          `${studentUuid}:${assessmentUuid}`
        ]
      };
    }
    case GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL_ERROR: {
      return {
        ...state,
        partOpportunityReportBlockDetailLoading:
          INITIAL_STATE.partOpportunityReportBlockDetailLoading,
        partOpportunityReportBlockDetailData:
          INITIAL_STATE.partOpportunityReportBlockDetailData
      };
    }
    case GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL_SUCCESS: {
      const {
        assessmentUuid,
        studentUuid,
        partOpportunityReportStudentBlockDetailData
      } = action.payload;
      return {
        ...state,
        partOpportunityReportBlockDetailLoading:
          state.partOpportunityReportBlockDetailLoading.filter(
            loadingStudentAssessment =>
              loadingStudentAssessment !== `${studentUuid}:${assessmentUuid}`
          ),
        partOpportunityReportBlockDetailData: {
          ...state.partOpportunityReportBlockDetailData,
          [`${studentUuid}:${assessmentUuid}`]:
            partOpportunityReportStudentBlockDetailData
        }
      };
    }
    case GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL: {
      const { assessmentUuid, statisticType } = action.payload;
      return {
        ...state,
        partOpportunityReportStatisticBlockDetailLoading: [
          ...state.partOpportunityReportStatisticBlockDetailLoading,
          `${statisticType}:${assessmentUuid}`
        ]
      };
    }
    case GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL_ERROR: {
      return {
        ...state,
        partOpportunityReportStatisticBlockDetailLoading:
          INITIAL_STATE.partOpportunityReportStatisticBlockDetailLoading,
        partOpportunityReportBlockDetailData:
          INITIAL_STATE.partOpportunityReportBlockDetailData
      };
    }
    case GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL_SUCCESS: {
      const {
        assessmentUuid,
        partOpportunityReportStatisticBlockDetailData,
        statisticType
      } = action.payload;
      return {
        ...state,
        partOpportunityReportStatisticBlockDetailLoading:
          state.partOpportunityReportStatisticBlockDetailLoading.filter(
            loadingStatisticType =>
              loadingStatisticType !== `${statisticType}:${assessmentUuid}`
          ),
        partOpportunityReportStatisticBlockDetailData: {
          ...state.partOpportunityReportStatisticBlockDetailData,
          [statisticType]: {
            ...state.partOpportunityReportStatisticBlockDetailData[
              statisticType
            ],
            ...partOpportunityReportStatisticBlockDetailData
          }
        }
      };
    }
    default:
      return state;
  }
};

export default partOpportunityReportReducer;
