import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import { EditIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { Link } from 'react-router-dom';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';

import {
  ContainerResize,
  ContainerActions,
  ContainerPage,
  ElevateLow
} from '../Library/Layout';
import Header from '../Library/Header';
import FormSelect from '../Library/FormSelect';

const DepartmentSelection = styled.div`
  padding: 1rem;
  width: 300px;
`;

export default function CourseManagement({
  departmentUuid,
  optionsDepartment,
  filteredCoursesMaster,
  selectFilter
}) {
  return (
    <ContainerResize>
      <Header
        title="Course Management"
        subtitle="Manage parent course, add and delete"
        backOn={false}
      />
      <ContainerActions>
        <Link to="/course-management/coursemaster/new">
          <ButtonPrimary>Add Course</ButtonPrimary>
        </Link>
      </ContainerActions>
      <ContainerPage paddingTop="40px">
        <ElevateLow>
          <DepartmentSelection>
            <FormSelect
              value={departmentUuid}
              options={optionsDepartment}
              handleChange={selectFilter}
              name="department"
              label="Department"
            />
          </DepartmentSelection>
        </ElevateLow>
      </ContainerPage>
      <ContainerPage paddingTop="40px">
        {departmentUuid && departmentUuid !== '' && (
          <ElevateLow>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel>Course Number</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Title</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel>Edit</TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCoursesMaster.map(courseMaster => (
                  <TableRow key={courseMaster.uuid}>
                    <TableCell>{courseMaster.courseNumber}</TableCell>
                    <TableCell>{courseMaster.title}</TableCell>
                    <TableCell>
                      <Link
                        to={`/course-management/coursemaster/edit/${courseMaster.uuid}`}
                      >
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ElevateLow>
        )}
      </ContainerPage>
    </ContainerResize>
  );
}

CourseManagement.propTypes = {
  filteredCoursesMaster: PropTypes.array,
  departmentUuid: PropTypes.string,
  optionsDepartment: PropTypes.array,
  selectFilter: PropTypes.func
};

CourseManagement.defaultProps = {
  filteredCoursesMaster: [],
  departmentUuid: '',
  optionsDepartment: [],
  selectFilter: undefined
};
