import {
  DELETE_SCORE_ERROR,
  DELETE_SCORE_SUCCESS,
  DELETE_SCORE,
  DELETE_SCORES_ERROR,
  DELETE_SCORES_SUCCESS,
  DELETE_SCORES,
  DELETE_STUDENT_SCORES_ERROR,
  DELETE_STUDENT_SCORES_SUCCESS,
  GET_SCORES_ERROR,
  GET_SCORES_SUCCESS,
  GET_SCORES,
  GET_UPLOAD_SCORES_STATUS_ERROR,
  GET_UPLOAD_SCORES_STATUS_SUCCESS,
  GET_UPLOAD_SCORES_STATUS,
  POST_SCORE_ERROR,
  POST_SCORE_RESET,
  POST_SCORE_SUCCESS,
  POST_SCORE,
  POST_UPLOAD_SCORES_ERROR,
  POST_UPLOAD_SCORES_SUCCESS,
  POST_UPLOAD_SCORES,
  PUT_SCORE_ERROR,
  PUT_SCORE_SUCCESS,
  PUT_SCORE,
  RESET_UPLOAD_SCORES,
  PUT_ASSESSMENT_BLOCK_RESULT_STATUS_SUCCESS,
  PUT_ASSESSMENT_BLOCK_RESULT_STATUS_ERROR
} from '../actions-type';

const doGetScores = (assessmentUuid, sectionUuid, partUuid) => ({
  type: GET_SCORES,
  payload: {
    assessmentUuid,
    sectionUuid,
    partUuid
  }
});

const doGetScoresSuccess = result => ({
  type: GET_SCORES_SUCCESS,
  payload: result
});

const doGetScoresError = error => ({
  type: GET_SCORES_ERROR,
  payload: error
});

const doUploadScores = (
  assessmentUuid,
  records,
  uploadDate,
  isCohortAssessment = false,
  isDelayedStudent = false
) => ({
  type: POST_UPLOAD_SCORES,
  payload: {
    assessmentUuid,
    records,
    uploadDate,
    isCohortAssessment,
    isDelayedStudent
  }
});

const doUploadScoresSuccess = result => ({
  type: POST_UPLOAD_SCORES_SUCCESS,
  payload: result
});

const doUploadScoresError = error => ({
  type: POST_UPLOAD_SCORES_ERROR,
  payload: error
});

const doUploadScoresReset = () => ({
  type: RESET_UPLOAD_SCORES
});

const doGetUploadScoresStatus = assessmentUuid => ({
  type: GET_UPLOAD_SCORES_STATUS,
  payload: {
    assessmentUuid
  }
});

const doGetUploadScoresStatusSuccess = result => ({
  type: GET_UPLOAD_SCORES_STATUS_SUCCESS,
  payload: result
});

const doGetUploadScoresStatusError = error => ({
  type: GET_UPLOAD_SCORES_STATUS_ERROR,
  payload: error
});

const doPostScore = score => ({
  type: POST_SCORE,
  payload: {
    score
  }
});

const doPostScoreSuccess = result => ({
  type: POST_SCORE_SUCCESS,
  payload: result
});

const doPostScoreError = error => ({
  type: POST_SCORE_ERROR,
  payload: error
});

const doPostScoreReset = () => ({
  type: POST_SCORE_RESET
});

const doPutScore = score => ({
  type: PUT_SCORE,
  payload: {
    score
  }
});

const doPutScoreSuccess = result => ({
  type: PUT_SCORE_SUCCESS,
  payload: result
});

const doPutScoreError = error => ({
  type: PUT_SCORE_ERROR,
  payload: error
});

const doDeleteScore = (scoreUuid, resultCardUuid, assessmentUuid) => ({
  type: DELETE_SCORE,
  payload: {
    assessmentUuid,
    scoreUuid,
    resultCardUuid
  }
});

const doDeleteScoreSuccess = result => ({
  type: DELETE_SCORE_SUCCESS,
  payload: result
});

const doDeleteScoreError = error => ({
  type: DELETE_SCORE_ERROR,
  payload: error
});

const doPutAssessmentBlockResultStatusSuccess = result => ({
  type: PUT_ASSESSMENT_BLOCK_RESULT_STATUS_SUCCESS,
  payload: result
});

const doPutAssessmentBlockResultStatusError = result => ({
  type: PUT_ASSESSMENT_BLOCK_RESULT_STATUS_ERROR,
  payload: result
});

const doDeleteStudentScoresSuccess = result => ({
  type: DELETE_STUDENT_SCORES_SUCCESS,
  payload: result
});

const doDeleteStudentScoresError = error => ({
  type: DELETE_STUDENT_SCORES_ERROR,
  payload: error
});

const doDeleteScores = assessmentUuid => ({
  type: DELETE_SCORES,
  payload: {
    assessmentUuid
  }
});

const doDeleteScoresSuccess = result => ({
  type: DELETE_SCORES_SUCCESS,
  payload: result
});

const doDeleteScoresError = error => ({
  type: DELETE_SCORES_ERROR,
  payload: error
});

export {
  doDeleteScore,
  doDeleteScoreError,
  doDeleteScores,
  doDeleteScoresError,
  doDeleteScoresSuccess,
  doDeleteScoreSuccess,
  doDeleteStudentScoresError,
  doDeleteStudentScoresSuccess,
  doPutAssessmentBlockResultStatusSuccess,
  doPutAssessmentBlockResultStatusError,
  doGetScores,
  doGetScoresError,
  doGetScoresSuccess,
  doGetUploadScoresStatus,
  doGetUploadScoresStatusError,
  doGetUploadScoresStatusSuccess,
  doPostScore,
  doPostScoreError,
  doPostScoreReset,
  doPostScoreSuccess,
  doPutScore,
  doPutScoreError,
  doPutScoreSuccess,
  doUploadScores,
  doUploadScoresError,
  doUploadScoresReset,
  doUploadScoresSuccess
};
