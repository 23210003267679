import {
  GET_DEPARTMENT,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_ERROR,
  GET_DEPARTMENTS,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_ERROR,
  GET_SCHOOL_DEPARTMENTS,
  GET_SCHOOL_DEPARTMENTS_SUCCESS,
  GET_SCHOOL_DEPARTMENTS_ERROR,
  POST_DEPARTMENT,
  POST_DEPARTMENT_SUCCESS,
  POST_DEPARTMENT_ERROR,
  PUT_DEPARTMENT,
  PUT_DEPARTMENT_SUCCESS,
  PUT_DEPARTMENT_ERROR,
  DELETE_DEPARTMENT,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_ERROR
} from '../actions-type';

const doGetDepartment = departmentUuid => ({
  type: GET_DEPARTMENT,
  payload: {
    departmentUuid
  }
});

const doGetDepartmentSuccess = result => ({
  type: GET_DEPARTMENT_SUCCESS,
  payload: result
});

const doGetDepartmentError = error => ({
  type: GET_DEPARTMENT_ERROR,
  payload: error
});

const doGetDepartments = () => ({
  type: GET_DEPARTMENTS
});

const doGetDepartmentsSuccess = result => ({
  type: GET_DEPARTMENTS_SUCCESS,
  payload: result
});

const doGetDepartmentsError = error => ({
  type: GET_DEPARTMENTS_ERROR,
  payload: error
});

const doGetSchoolDepartments = schoolUuid => ({
  type: GET_SCHOOL_DEPARTMENTS,
  payload: {
    schoolUuid
  }
});

const doGetSchoolDepartmentsSuccess = result => ({
  type: GET_SCHOOL_DEPARTMENTS_SUCCESS,
  payload: result
});

const doGetSchoolDepartmentsError = error => ({
  type: GET_SCHOOL_DEPARTMENTS_ERROR,
  payload: error
});

const doPostDepartment = department => ({
  type: POST_DEPARTMENT,
  payload: {
    department
  }
});

const doPostDepartmentSuccess = result => ({
  type: POST_DEPARTMENT_SUCCESS,
  payload: result
});

const doPostDepartmentError = error => ({
  type: POST_DEPARTMENT_ERROR,
  payload: error
});

const doPutDepartment = department => ({
  type: PUT_DEPARTMENT,
  payload: {
    department
  }
});

const doPutDepartmentSuccess = result => ({
  type: PUT_DEPARTMENT_SUCCESS,
  payload: result
});

const doPutDepartmentError = error => ({
  type: PUT_DEPARTMENT_ERROR,
  payload: error
});

const doDeleteDepartment = departmentUuid => ({
  type: DELETE_DEPARTMENT,
  payload: {
    departmentUuid
  }
});

const doDeleteDepartmentSuccess = result => ({
  type: DELETE_DEPARTMENT_SUCCESS,
  payload: result
});

const doDeleteDepartmentError = error => ({
  type: DELETE_DEPARTMENT_ERROR,
  payload: error
});

export {
  doGetDepartment,
  doGetDepartmentSuccess,
  doGetDepartmentError,
  doGetDepartments,
  doGetDepartmentsSuccess,
  doGetDepartmentsError,
  doGetSchoolDepartments,
  doGetSchoolDepartmentsSuccess,
  doGetSchoolDepartmentsError,
  doPostDepartment,
  doPostDepartmentSuccess,
  doPostDepartmentError,
  doPutDepartment,
  doPutDepartmentSuccess,
  doPutDepartmentError,
  doDeleteDepartment,
  doDeleteDepartmentSuccess,
  doDeleteDepartmentError
};
