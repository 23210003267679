import { Model, attr } from 'redux-orm';

import {
  GET_ASSESSMENT_GRADES_SUCCESS,
  GET_COHORT_ASSESSMENT_SCORES_SUCCESS,
  GET_SCORES_SUCCESS,
  POST_SCORE_SUCCESS,
  PUT_SCORE_SUCCESS
} from '../actions-type';

class Topic extends Model {
  static reducer(action, Topic, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_COHORT_ASSESSMENT_SCORES_SUCCESS:
      case GET_SCORES_SUCCESS: {
        const topics = [...payload.topicsORM];
        topics.forEach(topic => Topic.upsert(topic));
        break;
      }
      case POST_SCORE_SUCCESS: {
        const { microcompetency } = payload;
        Topic.upsert(microcompetency.subtopic.topic);
        break;
      }
      case PUT_SCORE_SUCCESS: {
        const { microcompetency } = payload;
        Topic.upsert(microcompetency.subtopic.topic);
        break;
      }
      case GET_ASSESSMENT_GRADES_SUCCESS: {
        const topics = [...payload.topicsORM];
        topics.forEach(topic => Topic.upsert(topic));
        break;
      }
      default:
        return session;
    }
  }
}

Topic.fields = {
  uuid: attr(),
  title: attr(),
  level_one_code: attr(),
  level_two_code: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

Topic.modelName = 'Topic';

Topic.options = {
  idAttribute: 'uuid'
};

export default Topic;
