import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const groupsSelectorByPartUuid = createSelector(
  state => state,
  (_, partUuid) => partUuid,
  (redux, partUuid) => {
    const groupsSelectorByPartUuidORM = ormCreateSelector(orm.Group);
    const groups = groupsSelectorByPartUuidORM(redux).filter(
      group => group.partUuid === partUuid
    );
    return groups;
  }
);

export const groupedStudentsSelectorByPartUuid = createSelector(
  state => state,
  (_, partUuid) => partUuid,
  (redux, partUuid) => {
    const groupedStudentsSelectorByPartUuidORM = ormCreateSelector(
      [orm],
      session => {
        const groups = session.Group.all()
          .filter(group => group.partUuid === partUuid)
          .toRefArray()
          .map(group => group.uuid);

        const studentsByGroup = {};

        session.UserGroup.all()
          .filter(userGroup => groups.includes(userGroup.groupUuid))
          .toRefArray()
          .forEach(userGroup => {
            studentsByGroup[userGroup.userUuid] = session.Group.withId(
              userGroup.groupUuid
            );
          });
        return studentsByGroup;
      }
    );
    const groups = groupedStudentsSelectorByPartUuidORM(redux);
    return groups;
  }
);
