import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

export const useSelectedCells = ({
  showTotals,
  isExpanded,
  isDelayed,
  showScores
}) => {
  const [selectedCells, setSelectedCells] = useState({});

  useEffect(() => {
    setSelectedCells({});
  }, [showTotals, isExpanded, isDelayed]);

  const selectCell = e => {
    const cell = e.target.closest('td');

    if (cell && isExpanded && !showScores) {
      const startRowIndex = isDelayed || !showTotals ? 0 : 6;
      const startCellIndex = showTotals ? 4 : 1;
      const row = cell.parentElement;
      if (cell.cellIndex > startCellIndex && row.rowIndex > startRowIndex) {
        if (
          selectedCells[row.rowIndex] &&
          selectedCells[row.rowIndex][cell.cellIndex]
        ) {
          const copiedCells = { ...selectedCells };
          delete copiedCells[row.rowIndex][cell.cellIndex];

          if (isEmpty(copiedCells[row.rowIndex])) {
            delete copiedCells[row.rowIndex];
          }
          setSelectedCells(copiedCells);
        } else {
          setSelectedCells({
            ...selectedCells,
            [row.rowIndex]: {
              ...selectedCells[row.rowIndex],
              [cell.cellIndex]: true
            }
          });
        }
      }
    }
  };

  return {
    selectedCells: [
      ...new Set(
        Object.values(selectedCells)
          .map(Object.keys)
          .reduce((acc, curr) => acc.concat(curr), [])
      )
    ],
    selectCell
  };
};
