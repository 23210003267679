import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectRigorByInstitution } from '../../../../../redux/selectors/rigorIndexSelectors';

export default function InstitutionRigor({
  programUuid,
  sourceInstitutionUuid
}) {
  const rigorIndex = useSelector(state =>
    selectRigorByInstitution(state, programUuid, sourceInstitutionUuid)
  );

  return <>{rigorIndex && rigorIndex}</>;
}

InstitutionRigor.propTypes = {
  programUuid: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string
};

InstitutionRigor.defaultProps = {
  programUuid: undefined,
  sourceInstitutionUuid: undefined
};
