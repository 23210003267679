import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LabelIcon } from '@xcomp/xcomp-design-library/dist/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import { studentStatus, studentStatusMap } from '../../../helpers/constants';

const Instruction = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

export default function CohortStudentStatusMenu({ handleSelectedStatus }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuSelection = status => {
    handleSelectedStatus(status);
  };

  return (
    <>
      <LabelIcon
        aria-controls="student-status-menu"
        aria-haspopup="true"
        onClick={handleClick}
      />
      <Menu
        id="student-status-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Instruction>Change status roles to: </Instruction>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(studentStatus.active);
          }}
        >
          {studentStatusMap.active}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(studentStatus.inactive);
          }}
        >
          {studentStatusMap.inactive}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(studentStatus.delayed);
          }}
        >
          {studentStatusMap.delayed}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(studentStatus.competent);
          }}
        >
          {studentStatusMap.competent}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(studentStatus.withdrawn);
          }}
        >
          {studentStatusMap.withdrawn}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(studentStatus.transferred);
          }}
        >
          {studentStatusMap.transferred}
        </MenuItem>
      </Menu>
    </>
  );
}

CohortStudentStatusMenu.propTypes = {
  handleSelectedStatus: PropTypes.func
};
CohortStudentStatusMenu.defaultProps = {
  handleSelectedStatus: undefined
};
