import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { selectOverallGPA } from '../../../../redux/selectors/gpaSelectors';

export default function OverallGPA({ userUuid }) {
  const gpa = useSelector(state => selectOverallGPA(state, userUuid));

  return <>{gpa}</>;
}

OverallGPA.propTypes = {
  userUuid: PropTypes.string
};

OverallGPA.defaultProps = {
  userUuid: undefined
};
