import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { doGetProgram } from '../../../redux/actions/programActions';
import { doGetUserProgramsByProgram } from '../../../redux/actions/userProgramActions';
import { userRoleSelectorByProgram } from '../../../redux/selectors/userRolesSelectors';
import { programSelector } from '../../../redux/selectors/programSelectors';

import ProgramRole from './ProgramRole';

class ProgramRoleContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterStatus: {
        active: false,
        complete: false
      },
      userRoles: []
    };

    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this._loadRoles = this._loadRoles.bind(this);
    this._filterUserByStatus = this._filterUserByStatus.bind(this);
  }

  componentDidMount() {
    const { onGetProgram, onGetUsersProgram, match, userRoles } = this.props;
    const { programUuid } = match.params;

    onGetProgram(programUuid);
    onGetUsersProgram(programUuid);

    if (userRoles.length > 0) {
      this._loadRoles();
    }
  }

  componentDidUpdate(prevProps) {
    const { userRoles } = this.props;

    if (userRoles !== prevProps.userRoles) {
      this._loadRoles();
    }
  }

  handleFilterSelection(selection) {
    if (selection === 'active') {
      this.setState(prevState => {
        const toggleSelection = !prevState.filterStatus.active;

        return {
          ...prevState,
          filterStatus: {
            ...prevState.filterStatus,
            active: toggleSelection
          }
        };
      });
    }

    if (selection === 'complete') {
      this.setState(prevState => {
        const toggleSelection = !prevState.filterStatus.complete;

        return {
          ...prevState,
          filterStatus: {
            ...prevState.filterStatus,
            complete: toggleSelection
          }
        };
      });
    }
  }

  _loadRoles() {
    this.setState(prevState => {
      const { userRoles } = this.props;

      return {
        ...prevState,
        userRoles
      };
    });
  }

  _filterUserByStatus() {
    const { userRoles, filterStatus } = this.state;

    const { active, complete } = filterStatus;

    const selectedUser = [];
    if (filterStatus.active) {
      const activeSelected = _.filter(
        userRoles,
        user => user.status === 'active'
      );

      selectedUser.push(activeSelected);
    }

    if (filterStatus.complete) {
      const completeSelected = _.filter(
        userRoles,
        user => user.status === 'complete'
      );

      selectedUser.push(completeSelected);
    }

    if (!active && !complete) {
      selectedUser.push(userRoles);
    }

    return _.flatten(selectedUser);
  }

  render() {
    const { program } = this.props;
    const { filterStatus } = this.state;

    const filterStudents = this._filterUserByStatus();

    return (
      <ProgramRole
        program={program}
        userRoles={filterStudents}
        filterStatus={filterStatus}
        handleFilterSelection={this.handleFilterSelection}
      />
    );
  }
}

ProgramRoleContainer.propTypes = {
  match: PropTypes.object,
  onGetProgram: PropTypes.func,
  onGetUsersProgram: PropTypes.func,
  userRoles: PropTypes.array,
  program: PropTypes.object
};

ProgramRoleContainer.defaultProps = {
  match: {},
  onGetProgram: undefined,
  onGetUsersProgram: undefined,
  userRoles: [],
  program: {}
};

const mapStateToProps = (state, props) => {
  return {
    userRoles: userRoleSelectorByProgram(state, props),
    program: programSelector(state, props.match.params.programUuid)
  };
};

const mapDispatchToProps = dispatch => ({
  onGetProgram: programUuid => dispatch(doGetProgram(programUuid)),
  onGetUsersProgram: programUuid =>
    dispatch(doGetUserProgramsByProgram(programUuid))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramRoleContainer);
