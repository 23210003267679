import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CSVReader from 'react-csv-reader';
import Chip from '@material-ui/core/Chip';
import { CircularProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonPrimary, ButtonInline } from '@xcomp/xcomp-design-library';
import Modal from '../../Modal/Modal';
import UploadMapping from '../../UploadMapping';
import UploadErrorsNew from '../../UploadErrorsNew';
import UploadSuccess from '../../UploadSuccess';
import { headersScores } from '../../../../helpers/headers_upload/headers_scores';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 900px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  margin-bottom: 20px;
`;

const FileUpload = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Note = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 12px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Import = styled(ButtonPrimary)`
  && {
    margin-left: 5px;
  }
`;

const FileSelectorSC = styled.div`
  width: 100%;
  margin-right: 16px;
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
`;

const LoaderMessage = styled.div`
  padding-left: 10px;
`;

const ChipSC = styled(Chip)`
  && {
    background: ${props => {
      switch (props.status) {
        case 'ready': {
          return props.theme.colors.grey[500];
        }
        case 'loading': {
          return props.theme.colors.status.warning;
        }
        case 'success': {
          return props.theme.colors.status.success;
        }
        case 'failed': {
          return props.theme.colors.status.error;
        }
        default:
          return props.theme.colors.grey[500];
      }
    }};
    color: ${props => props.theme.colors.white};
  }

  margin-left: 20px;
`;

const ButtonDanger = styled(ButtonPrimary)`
  && {
    margin-left: 20px;
    background: ${props => props.theme.colors.status.error};
    &:hover {
      filter: brightness(140%);
      background-color: ${props => props.theme.colors.status.error};
    }
  }
`;

const ButtonSummary = styled(ButtonPrimary)`
  && {
    margin-left: 20px;
    background: ${props => props.theme.colors.status.success};
    &:hover {
      filter: brightness(140%);
      background-color: ${props => props.theme.colors.status.success};
    }
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ClearResults = styled(ButtonInline)`
  && {
    margin-left: 20px;
  }
`;

export default function ScoresUpload({
  scores,
  status,
  uploadDate,
  error,
  summary,
  openSummary,
  openModal,
  openError,
  openMapping,
  handleUploadClose,
  handleScores,
  handleUpload,
  handleDisplayMapping,
  handleDisplaySummary,
  handleDisplayErrors,
  handleClearResults
}) {
  function statusMap() {
    switch (status) {
      case 'ready': {
        return 'Ready for upload';
      }
      case 'loading': {
        return (
          <Loader>
            <CircularProgress color="primary" size={20} />
            <LoaderMessage>Upload in progress</LoaderMessage>
          </Loader>
        );
      }
      case 'success': {
        return 'Recent upload complete';
      }
      case 'failed': {
        return 'Recent upload failed';
      }
      default:
        return 'Ready for upload';
    }
  }

  return (
    <Modal open={openModal} onClose={handleUploadClose}>
      <ModalBody>
        <ModalHeader>
          <Title>
            Upload Scores (CSV)
            <ChipSC status={status} label={statusMap()} />
          </Title>
          <CloseModal>
            <IconButton onClick={handleUploadClose}>
              <CloseIcon />
            </IconButton>
          </CloseModal>
        </ModalHeader>
        <FileUpload>
          <FileSelectorSC>
            <CSVReader
              onFileLoaded={handleScores}
              parserOptions={{ header: true }}
              inputId="scores-upload"
              inputStyle={{
                width: '100%',
                color: 'black',
                background: 'lightgrey',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                borderRadius: '5px'
              }}
            />
          </FileSelectorSC>
          <Import disabled={status === 'loading'} onClick={handleUpload}>
            Import
          </Import>
        </FileUpload>
        <Note>
          {scores
            ? 'If changes are made to an existing selected file, close this modal and re-open then re-select the file.'
            : 'Select a file to import Scores from.'}
        </Note>

        <Actions>
          <div>
            <ButtonPrimary onClick={handleDisplayMapping}>
              {openMapping ? `Hide Rules` : `Rules`}
            </ButtonPrimary>
            {status === 'success' && (
              <ButtonSummary onClick={handleDisplaySummary}>
                {openSummary ? `Hide Results` : `Results`}
              </ButtonSummary>
            )}
            {status === 'failed' && (
              <ButtonDanger onClick={handleDisplayErrors}>Errors</ButtonDanger>
            )}
            {(status === 'failed' || status === 'success') && (
              <ClearResults onClick={handleClearResults}>
                Clear Results
              </ClearResults>
            )}
          </div>
        </Actions>

        <UploadMapping display={openMapping} fields={headersScores} />

        <UploadErrorsNew
          show={status === 'failed' && openError}
          error={error}
          uploadDate={uploadDate}
        />
        <UploadSuccess
          show={status === 'success' && openSummary}
          summary={summary}
          uploadDate={uploadDate}
        />
      </ModalBody>
    </Modal>
  );
}

ScoresUpload.propTypes = {
  summary: PropTypes.object,
  error: PropTypes.object,
  status: PropTypes.string,
  uploadDate: PropTypes.string,
  openSummary: PropTypes.bool,
  scores: PropTypes.array,
  openModal: PropTypes.bool,
  openError: PropTypes.bool,
  openMapping: PropTypes.bool,
  handleUploadClose: PropTypes.func,
  handleScores: PropTypes.func,
  handleUpload: PropTypes.func,
  handleDisplayMapping: PropTypes.func,
  handleDisplaySummary: PropTypes.func,
  handleDisplayErrors: PropTypes.func,
  handleClearResults: PropTypes.func
};

ScoresUpload.defaultProps = {
  summary: undefined,
  error: undefined,
  scores: undefined,
  uploadDate: '',
  status: 'Ready for upload',
  openModal: false,
  openError: false,
  openMapping: false,
  openSummary: false,
  handleUploadClose: undefined,
  handleScores: undefined,
  handleUpload: undefined,
  handleDisplayMapping: undefined,
  handleDisplaySummary: undefined,
  handleDisplayErrors: undefined,
  handleClearResults: undefined
};
