import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import SyllabusInfo from './SyllabusInfo';

import Header from '../../Library/Header';

import {
  visibleToSchoolRoles,
  visibleToSchoolAdmin
} from '../../../helpers/authorization';

export const HeaderSub = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.fontColors.dark};
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 2px;
`;

const MenuItemSC = styled(MenuItem)`
  && {
    font-size: 0.8rem;
  }
`;

export const HeaderMain = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
`;

export const CourseNumber = styled.div``;
export const Coursetitle = styled.div`
  padding-left: 0.25rem;
`;

export const PartTitle = styled.div`
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
`;

export const SectionToggle = styled.span`
  font-size: 1.375rem;
  color: ${props => props.theme.fontColors.dark};
  font-weight: 300;
`;

export const SectionId = styled.div`
  padding-right: 0.25rem;
`;

export const PartId = styled.div`
  padding-left: 0.25rem;
`;

export const SyllabusStatus = styled.div`
  font-size: 12px;
  opacity: 0.8;
  background: ${props => props.theme.colors.primary};
  border-radius: 25px;
  color: ${props => props.theme.colors.white};
  padding: 4px 6px;
  margin-left: 10px;
  text-transform: none;
  align-items: center;
`;

export const TempSpan = styled.span`
  padding: 5px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
`;

const AssessmentSwithButton = styled(ButtonPrimary)`
  && {
    margin: 0.325rem;
  }
`;

const ButtonPrimarySC = styled(ButtonPrimary)`
  && {
    margin: 0.325rem;
  }
`;

const SubmitSyllabusButton = visibleToSchoolRoles(props => (
  <ButtonPrimarySC onClick={() => props.handleModalSubmitSyllabusOpen()}>
    Submit Syllabus
  </ButtonPrimarySC>
));

const ApproveSyllabusButton = visibleToSchoolAdmin(props => (
  <ButtonPrimarySC onClick={() => props.handleModalApproveSyllabusOpen()}>
    Approve Syllabus
  </ButtonPrimarySC>
));

const Actions = styled.div`
  background: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-top: 85px;
  padding-bottom: 10px;
  padding-left: 70px;
  padding-right: 70px;
  height: 100%;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 12px 0;

  button {
  }
`;

export default function SyllabiHeader({
  restrictEdit,
  course,
  part,
  parts,
  section,
  sectionNumber,
  partNumber,
  syllabusItems,
  handleModalSubmitSyllabusOpen,
  handleModalApproveSyllabusOpen
}) {
  const [anchorEl, setAnchhorEl] = useState(null);

  const handleClick = event => {
    setAnchhorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchhorEl(null);
  };

  const allComplete = _.every(syllabusItems, { status: 'Complete' });
  const allApproved = _.every(syllabusItems, { status: 'Approved' });

  const sectionParts = parts
    .map(part => {
      return {
        sectionUuid: section.uuid,
        sectionNumber: section.sectionNumber,
        partUuid: part.uuid,
        partNumber: part.partNumber,
        partTitle: part.title,
        path: `/syllabus-management/editor/part/${part.uuid}&courseUuid=${course.uuid}&sectionUuid=${section.uuid}&partNumber=${part.partNumber}&sectionNumber=${section.sectionNumber}`
      };
    })
    .sort((a, b) => a.partNumber - b.partNumber);

  return (
    <>
      <Header
        title="Syllabus"
        subtitle={`${course.course_master.courseNumber} - ${course.course_master.title}`}
      />
      <Actions>
        <SyllabusInfo
          course={course}
          section={section}
          partStatus={part.status}
        />
        <ActionButtons>
          <SectionToggle>
            <AssessmentSwithButton
              aria-owns={anchorEl ? 'part-menu' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              {`Section ${sectionNumber} – Part ${partNumber} – ${part.title}`}
              <ExpandMore />
            </AssessmentSwithButton>

            <Menu
              id="part-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {sectionParts.map(part => (
                <Link key={part.partUuid} to={part.path}>
                  <MenuItemSC onClick={handleClose}>
                    {`Section ${part.sectionNumber} – Part ${part.partNumber} – ${part.partTitle}`}
                  </MenuItemSC>
                </Link>
              ))}
            </Menu>
          </SectionToggle>

          {!restrictEdit &&
            syllabusItems.length > 0 &&
            allComplete &&
            part.status === 'In Progress' && (
              <SubmitSyllabusButton
                handleModalSubmitSyllabusOpen={handleModalSubmitSyllabusOpen}
              />
            )}
          {!restrictEdit &&
            syllabusItems.length > 0 &&
            allApproved &&
            part.status === 'Ready for Review' && (
              <ApproveSyllabusButton
                allApproved={allApproved}
                handleModalApproveSyllabusOpen={handleModalApproveSyllabusOpen}
              />
            )}
        </ActionButtons>
      </Actions>
    </>
  );
}

SyllabiHeader.propTypes = {
  restrictEdit: PropTypes.bool,
  course: PropTypes.object,
  part: PropTypes.object,
  parts: PropTypes.array,
  section: PropTypes.object,
  sectionNumber: PropTypes.string,
  partNumber: PropTypes.string,
  syllabusItems: PropTypes.array,
  handleModalSubmitSyllabusOpen: PropTypes.func,
  handleModalApproveSyllabusOpen: PropTypes.func
};

SyllabiHeader.defaultProps = {
  restrictEdit: true,
  course: {},
  part: {},
  parts: [],
  section: {},
  sectionNumber: '1',
  partNumber: '1',
  syllabusItems: [],
  handleModalSubmitSyllabusOpen: undefined,
  handleModalApproveSyllabusOpen: undefined
};
