const credentialInitial = {
  credential: undefined,
  year: undefined,
  degreeLevelUuid: undefined,
  institutionUuid: undefined
};

const credentialReducer = (state, action) => {
  switch (action.type) {
    case 'exist': {
      const {
        uuid,
        credential,
        year,
        degreeLevelUuid,
        institutionUuid,
        programCodeUuid
      } = { ...action.credential };

      return {
        ...state,
        uuid,
        year: new Date(year, 0),
        credential,
        institutionUuid,
        degreeLevelUuid,
        programCodeUuid
      };
    }
    case 'reset': {
      return {
        ...credentialInitial
      };
    }
    case 'year': {
      const { date } = { ...action };

      return {
        ...state,
        year: date
      };
    }
    case 'credential': {
      const { value } = { ...action };

      return {
        ...state,
        credential: value
      };
    }
    case 'institutionUuid': {
      const { institutionCode, institutionUuid } = { ...action };

      return {
        ...state,
        institutionUuid,
        institutionCode
      };
    }
    case 'programCodeUuid': {
      const { programCodeUuid } = { ...action };

      return {
        ...state,
        programCodeUuid
      };
    }
    case 'degreeLevelUuid': {
      const { value } = { ...action };

      return {
        ...state,
        degreeLevelUuid: value
      };
    }
    default:
      return {
        ...state
      };
  }
};

export { credentialReducer, credentialInitial };
