import fetch from '../helpers/fetch';

const getUsersSchool = schoolUuid =>
  fetch(`/api/user-school/school/${schoolUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getUsersSchools = () =>
  fetch(`/api/user-schools/all`, {
    method: 'GET',
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getUsersSchoolAdmin = () =>
  fetch(`/api/user-schools/school-admin`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getUsersSchool, getUsersSchools, getUsersSchoolAdmin };
