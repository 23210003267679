import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import FormField from '../../../../Library/FormField';
import { validateLoverbTextFieldAdd } from '../../../../../helpers/validation/validateEducationLevelLoverb';

const Center = styled.div`
  margin-top: 30px;
  margin-left: 10px;
`;

export default function LoverbAdd({
  indentifier,
  objectiveVerb,
  loverbs,
  loverbsRedux,
  hasErrors,
  handleLoverbChange,
  handleRemoveLoverb
}) {
  return (
    <>
      <Grid item xs={11}>
        <FormField
          name="objectiveVerb"
          label="Objective Verb*"
          value={objectiveVerb}
          onBlur={event => handleLoverbChange(indentifier, event)}
          handleValidate={textField =>
            validateLoverbTextFieldAdd(textField, loverbs, loverbsRedux)
          }
          hasErrors={hasErrors}
        />
      </Grid>
      <Grid item xs={1}>
        <Center>
          <DeleteIcon onClick={() => handleRemoveLoverb(indentifier)} />
        </Center>
      </Grid>
    </>
  );
}

LoverbAdd.propTypes = {
  indentifier: PropTypes.string,
  objectiveVerb: PropTypes.string,
  loverbs: PropTypes.array,
  loverbsRedux: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleLoverbChange: PropTypes.func,
  handleRemoveLoverb: PropTypes.func
};
LoverbAdd.defaultProps = {
  indentifier: '',
  objectiveVerb: '',
  loverbs: [],
  loverbsRedux: [],
  hasErrors: false,
  handleLoverbChange: undefined,
  handleRemoveLoverb: undefined
};
