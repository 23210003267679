import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const assessmentBlockResultsSelector = createSelector(
  state => state,
  (_, assessmentBlockUuid, studentUuid) => ({
    assessmentBlockUuid,
    studentUuid
  }),
  (redux, data) => {
    const { assessmentBlockUuid, studentUuid } = data;

    const assessmentBlockResultSelectorORM = ormCreateSelector(
      orm.AssessmentBlockResult
    );
    const assessmentBlockResults = assessmentBlockResultSelectorORM(
      redux
    ).filter(
      assessmentBlockResult =>
        assessmentBlockResult.assessmentBlockUuid === assessmentBlockUuid &&
        assessmentBlockResult.studentUuid === studentUuid
    );
    return assessmentBlockResults;
  }
);
