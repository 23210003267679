import {
  GET_TERMS,
  GET_TERMS_SUCCESS,
  GET_TERMS_ERROR,
  GET_TERMS_BY_ACADEMIC_YEARS,
  GET_TERMS_BY_ACADEMIC_YEARS_SUCCESS,
  GET_TERMS_BY_ACADEMIC_YEARS_ERROR
} from '../actions-type';

const doGetTerms = academicYearUuid => ({
  type: GET_TERMS,
  payload: {
    academicYearUuid
  }
});

const doGetTermsSuccess = result => ({
  type: GET_TERMS_SUCCESS,
  payload: result
});

const doGetTermsError = error => ({
  type: GET_TERMS_ERROR,
  payload: error
});

const doGetTermsByAcademicYears = academicYears => ({
  type: GET_TERMS_BY_ACADEMIC_YEARS,
  payload: {
    academicYears
  }
});

const doGetTermsByAcademicYearsSuccess = result => ({
  type: GET_TERMS_BY_ACADEMIC_YEARS_SUCCESS,
  payload: result
});

const doGetTermsByAcademicYearsError = error => ({
  type: GET_TERMS_BY_ACADEMIC_YEARS_ERROR,
  payload: error
});

export {
  doGetTerms,
  doGetTermsSuccess,
  doGetTermsError,
  doGetTermsByAcademicYears,
  doGetTermsByAcademicYearsSuccess,
  doGetTermsByAcademicYearsError
};
