import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

const PartDataRow = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  width: calc(100% - 32px);
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  font-size: 14px;
`;

const PartData = styled(({ width, ...rest }) => <div {...rest} />)`
  display: flex;
  width: ${props => props.width};
  padding-right: 16px;
`;

const PartDataTitle = styled(({ ...rest }) => <Tooltip {...rest} />)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SectionPerformanceStudentPartData = ({
  calculatedGrade,
  letterGrade,
  partNumber,
  partTitle,
  partWeight
}) => {
  return (
    <PartDataRow>
      <PartData width="45%" />
      <PartData width="10%">
        <PartDataTitle title={`Part ${partNumber}`} placement="top">
          <div>{`Part ${partNumber}`}</div>
        </PartDataTitle>
      </PartData>
      <PartData width="20%">
        <PartDataTitle title={partTitle} placement="top">
          <div>{partTitle}</div>
        </PartDataTitle>
      </PartData>
      <PartData width="5%">
        <PartDataTitle title={partWeight} placement="top">
          <div>{partWeight}</div>
        </PartDataTitle>
      </PartData>
      <PartData width="5%">
        <PartDataTitle title={calculatedGrade ?? 'N/A'} placement="top">
          <div>{calculatedGrade}</div>
        </PartDataTitle>
      </PartData>
      <PartData width="5%">
        <PartDataTitle title={letterGrade ?? 'N/A'} placement="top">
          <div>{letterGrade}</div>
        </PartDataTitle>
      </PartData>
      <PartData width="5%" />
      <PartData width="5%" />
    </PartDataRow>
  );
};

SectionPerformanceStudentPartData.propTypes = {
  calculatedGrade: PropTypes.string,
  letterGrade: PropTypes.string,
  partNumber: PropTypes.string,
  partTitle: PropTypes.string,
  partWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

SectionPerformanceStudentPartData.defaultProps = {
  calculatedGrade: undefined,
  letterGrade: '',
  partNumber: '',
  partTitle: '',
  partWeight: ''
};

export default SectionPerformanceStudentPartData;
