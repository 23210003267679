import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Decimal from 'decimal.js-light';
import { useContainerDimensions } from '../../../../../helpers/hooks/layout.hooks';

const Table = styled.table`
  background: ${props => props.theme.colors.white};
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
`;

const TableHead = styled.thead`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: ${props => props.theme.colors.grey[600]};
  tr:first-child td {
    border-right: none;
  }
`;

const TableRow = styled.tr`
  display: flex;
  justify-content: flex-start;
  inline-size: min-content;
  td:last-child {
    border-right: none;
  }
`;

const TableBody = styled.tbody`
  color: ${props => props.theme.colors.grey[900]};
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 24px;
  tr:last-child td {
    border-bottom: none;
  }
`;

const ScrollableDiv = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: auto;
`;

const RotatedTd = styled.td`
  min-width: 75px;
  max-width: 75px;
  z-index: 5;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-top: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
`;

const StickyTitleTd = styled(({ left, greyOne, ...rest }) => <td {...rest} />)`
  min-width: 625px;
  max-width: 625px;
  z-index: 10;
  position: sticky;
  left: ${props => props.left};
  background-color: ${props => (props.greyOne && props.theme.colors.grey[100]) || 'white'};
  border-top: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
  border-right: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
`;

const AssessmentTitle = styled.div`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${props => props.theme.colors.grey[600]};
  padding: 16px 0px;
  white-space: nowrap;
`;

const RowInfoTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 53px;
  padding-left: 32px;
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${props => props.theme.colors.grey[600]};
`;

const PointsTd = styled(({ greyOne, ...rest }) => <td {...rest} />)`
  min-width: 75px;
  max-width: 75px;
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  background-color: ${props => (props.greyOne && props.theme.colors.grey[100]) || 'white'};
`;

const FillerTd = styled(({ borderTop, greyOne, greyTwo, ...rest }) => (
  <td {...rest} />
))`
  min-width: ${props => `${props.width}px`};
  max-width: ${props => `${props.width}px`};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-top: ${props =>
    props.borderTop ? `1px solid ${props.theme.colors.grey[300]}` : 'none'};
  background-color: ${props =>
    (props.greyOne && props.theme.colors.grey[100]) ||
    (props.greyTwo && props.theme.colors.grey[200]) ||
    'white'};
`;

const TableDataText = styled(({ redChip, ...rest }) => <div {...rest} />)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${props => props.theme.colors.grey[900]};
  .MuiChip-root {
    background-color: ${props =>
      props.redChip ? `${props.theme.colors.red[200]} !important` : props.theme.colors.blue[100]};
  }
`;

const PartGroupReportSummaryTable = ({
  assessments,
  assessmentSummaryData
}) => {
  const [useFillerColumn, setUseFillerColumn] = useState(false);
  const [fillerColumnWidth, setFillerColumnWidth] = useState(0);

  const tableRef = useRef(null);
  const { width } = useContainerDimensions(tableRef);

  useEffect(() => {
    const occupiedWidth = 625 + assessments.length * 75;
    if (width > occupiedWidth) {
      setUseFillerColumn(true);
      setFillerColumnWidth(width - occupiedWidth);
    } else {
      setUseFillerColumn(false);
      setFillerColumnWidth(0);
    }
  }, [width, assessments.length]);

  return (
    <ScrollableDiv>
      <Table ref={tableRef}>
        <TableHead>
          <TableRow>
            <StickyTitleTd left="0px" />
            {assessments &&
              assessments.map(assessment => (
                <RotatedTd key={assessment.uuid}>
                  <AssessmentTitle>{assessment.title}</AssessmentTitle>
                </RotatedTd>
              ))}
            {useFillerColumn && (
              <FillerTd width={fillerColumnWidth} borderTop />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StickyTitleTd left="0px">
              <RowInfoTitle>High</RowInfoTitle>
            </StickyTitleTd>
            {assessments.map(assessment => (
              <PointsTd key={assessment.uuid}>
                <TableDataText>
                  {assessmentSummaryData[assessment.uuid]?.highPoints
                    ? new Decimal(
                        assessmentSummaryData[assessment.uuid].highPoints
                      )
                        .toDecimalPlaces(2)
                        .toString()
                    : '---'}
                </TableDataText>
              </PointsTd>
            ))}
            {useFillerColumn && (
              <FillerTd width={fillerColumnWidth} borderTop />
            )}
          </TableRow>
          <TableRow>
            <StickyTitleTd left="0px" greyOne>
              <RowInfoTitle>Average</RowInfoTitle>
            </StickyTitleTd>
            {assessments.map(assessment => (
              <PointsTd key={assessment.uuid} greyOne>
                <TableDataText>
                  {assessmentSummaryData[assessment.uuid]?.averagePoints
                    ? new Decimal(
                        assessmentSummaryData[assessment.uuid].averagePoints
                      )
                        .toDecimalPlaces(2)
                        .toString()
                    : '---'}
                </TableDataText>
              </PointsTd>
            ))}
            {useFillerColumn && (
              <FillerTd width={fillerColumnWidth} borderTop greyOne />
            )}
          </TableRow>
          <TableRow>
            <StickyTitleTd left="0px">
              <RowInfoTitle>Low</RowInfoTitle>
            </StickyTitleTd>
            {assessments.map(assessment => (
              <PointsTd key={assessment.uuid}>
                <TableDataText>
                  {assessmentSummaryData[assessment.uuid]?.lowPoints
                    ? new Decimal(
                        assessmentSummaryData[assessment.uuid].lowPoints
                      )
                        .toDecimalPlaces(2)
                        .toString()
                    : '---'}
                </TableDataText>
              </PointsTd>
            ))}
            {useFillerColumn && (
              <FillerTd width={fillerColumnWidth} borderTop />
            )}
          </TableRow>
        </TableBody>
      </Table>
    </ScrollableDiv>
  );
};

PartGroupReportSummaryTable.propTypes = {
  assessments: PropTypes.array,
  assessmentSummaryData: PropTypes.object
};

PartGroupReportSummaryTable.defaultProps = {
  assessments: [],
  assessmentSummaryData: {}
};

export default PartGroupReportSummaryTable;
