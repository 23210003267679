import fetch from '../helpers/fetch';

const getUserDepartment = departmentUuid =>
  fetch(`/api/user-departments/department/${departmentUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getUserDepartments = departments =>
  fetch(`/api/user-departments/departments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(departments)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getUserDepartmentsFaculty = departmentUuid =>
  fetch(`/api/user-departments/faculty?departmentUuid=${departmentUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postUserDepartmentsAssign = roles =>
  fetch('/api/user-departments/assign', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(roles)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getUserDepartment,
  getUserDepartments,
  getUserDepartmentsFaculty,
  postUserDepartmentsAssign
};
