import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { selectLetterGrade } from '../../../../../redux/selectors/letterGradeSelectors';

export default function LetterGrade({ letterGradeUuid }) {
  const { grade } = useSelector(
    state => selectLetterGrade(state, letterGradeUuid),
    shallowEqual
  );

  return grade;
}

LetterGrade.propTypes = { letterGradeUuid: PropTypes.string };
LetterGrade.defaultProps = { letterGradeUuid: undefined };
