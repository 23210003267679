import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isUUID } from 'validator';
import _ from 'lodash';
import { assessmentSelector } from '../../../../redux/selectors/assessmentSelectors';
import { doGetAssessment } from '../../../../redux/actions/assessmentActions';
import { doGetGradeSummary } from '../../../../redux/actions/gradeSummaryActions';
import GradeSummary from './GradeSummary';
import {
  sortByProperty,
  sortByDecimalPropertyAndFullName
} from '../../../../helpers/utilities';
import { gradingTechniques } from '../../../../helpers/constants';
import { studentRosterSelectorBySectionUuid } from '../../../../redux/selectors/sectionsSelectors';
import {
  mapStudentScoresByStudentSectionStatus,
  createStudentMap
} from '../../../../helpers/sections';
import { doGetStudentRoster } from '../../../../redux/actions/userSectionActions';
import { resultCardsByAssessmentUuidSelector } from '../../../../redux/selectors/resultCardSelectors';

export default function GradeSummaryContainer({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { assessmentUuid } = match.params;

  const [hideStudentNames, setHideStudentNames] = useState(false);
  const [sortBy, setSortBy] = useState('highLow');

  const assessment = useSelector(
    state => assessmentSelector(state, assessmentUuid),
    _.isEqual
  );

  const gradeSummaryState = useSelector(
    state => state.gradeSummaryState,
    _.isEqual
  );

  const section = _.get(assessment, 'part.section', null);
  const course = _.get(section, 'course', null);
  const courseNumber = _.get(course, 'course_master.courseNumber', '');
  const courseTitle = _.get(course, 'course_master.title', '');
  const sectionNumber = _.get(section, 'sectionNumber', '')
    .toString()
    .padStart(2, 0);
  const partNumber = _.get(assessment, 'part.partNumber', '')
    .toString()
    .padStart(2, 0);
  const assessmentType = _.get(assessment, 'assessment_type.name');
  const partUuid = _.get(assessment, 'part.uuid', '');
  const courseUuid = _.get(course, 'uuid', '');
  const sectionUuid = _.get(section, 'uuid', '');
  const students = useSelector(state =>
    studentRosterSelectorBySectionUuid(state, sectionUuid)
  );
  const studentsMap = students?.length > 0 ? createStudentMap(students) : {};

  const handleBackAction = useCallback(() => {
    history.push(
      `/syllabus-management/view/part/${partUuid}&courseUuid=${courseUuid}&sectionUuid=${sectionUuid}&partNumber=${partNumber}&sectionNumber=${sectionNumber}`
    );
  }, [partUuid, sectionUuid, partNumber, sectionNumber, courseUuid, history]);

  useEffect(() => {
    dispatch(doGetAssessment(assessmentUuid));
  }, [dispatch, assessmentUuid]);

  useEffect(() => {
    if (assessmentUuid && isUUID(assessmentUuid))
      dispatch(doGetGradeSummary(assessmentUuid));
  }, [dispatch, assessmentUuid]);

  useEffect(() => {
    if (sectionUuid) dispatch(doGetStudentRoster(sectionUuid));
  }, [dispatch, sectionUuid]);

  const toggleHideStudentNames = () => setHideStudentNames(!hideStudentNames);
  const { ...gradeSummary } = gradeSummaryState;

  const resultCards = useSelector(
    state => resultCardsByAssessmentUuidSelector(state, assessmentUuid),
    _.isEqual
  );

  const isPointsOnly =
    gradeSummary.gradingTechnique === gradingTechniques.points;

  const sortFunction = (a, b) => {
    if (sortBy === 'lastName') return sortByProperty(a, b, 'lastName');

    if (isPointsOnly) {
      return sortBy === 'highLow'
        ? sortByDecimalPropertyAndFullName(
            b,
            a,
            'assessmentRelativeScore',
            'firstName',
            'lastName',
            true
          )
        : sortByDecimalPropertyAndFullName(
            a,
            b,
            'assessmentRelativeScore',
            'firstName',
            'lastName'
          );
    }
    return sortBy === 'highLow'
      ? sortByDecimalPropertyAndFullName(
          b,
          a,
          'calculatedGrade',
          'firstName',
          'lastName',
          true
        )
      : sortByDecimalPropertyAndFullName(
          a,
          b,
          'calculatedGrade',
          'firstName',
          'lastName'
        );
  };

  const {
    delayedStudentScores: delayedResultCards,
    activeStudentScores: activeResultCards,
    withdrewStudentScores: withdrewResultCards
  } = mapStudentScoresByStudentSectionStatus(resultCards, studentsMap);

  const activeAndWithdrewResultCards = [
    ...activeResultCards,
    ...withdrewResultCards
  ];

  return (
    <GradeSummary
      assessmentTitle={assessment.title}
      gradeSummary={gradeSummary}
      hideStudentNames={hideStudentNames}
      isPointsOnly={isPointsOnly}
      sortFunction={sortFunction}
      toggleHideStudentNames={toggleHideStudentNames}
      setSortBy={setSortBy}
      resultCards={activeAndWithdrewResultCards}
      delayedResultCards={delayedResultCards}
      courseNumber={courseNumber}
      courseTitle={courseTitle}
      sectionNumber={sectionNumber}
      partNumber={partNumber}
      assessmentType={assessmentType}
      handleBackAction={handleBackAction}
    />
  );
}

GradeSummaryContainer.propTypes = {
  match: PropTypes.object
};

GradeSummaryContainer.defaultProps = {
  match: {}
};
