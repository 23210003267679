import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { roles } from '../../helpers/constants';

import { orm } from '../models';

export const selectUser = ormCreateSelector(orm.User);

export const userActiveSelector = createSelector(
  state => state,
  state => state.userState.activeUserUuid,
  (redux, activeUserUuid) => {
    const users = ormCreateSelector(orm.User);
    const activeUser = users(redux, activeUserUuid) || {};

    return activeUser;
  }
);

export const userSelector = createSelector(
  state => state,
  (_, userUuid) => userUuid,
  (redux, userUuid) => {
    const usersSelectorORM = ormCreateSelector(orm.User);
    const user = usersSelectorORM(redux, userUuid) || {};

    return user;
  }
);

export const usersSelector = createSelector(
  state => state,
  redux => {
    const usersSelectorORM = ormCreateSelector(orm.User);
    const users = usersSelectorORM(redux);

    return users;
  }
);

export const usersSelectorBySearch = createSelector(
  state => state,
  redux => {
    const usersSelectorORM = ormCreateSelector(orm.User);
    const users = usersSelectorORM(redux);

    if (redux?.searchState?.searchUserBy) {
      const options = {
        threshold: 0.0,
        keys: [redux.searchState.searchUserBy]
      };

      const fuse = new Fuse(users, options);

      const findme = fuse.search(redux?.searchState?.searchUserInput);

      return findme;
    }
  }
);

export const usersSelectorBySearchRole = createSelector(
  state => state,
  redux => {
    const usersSelectorORM = ormCreateSelector(orm.User);
    const users = usersSelectorORM(redux);

    if (redux?.searchState?.searchUserRoleBy) {
      const options = {
        threshold: 0.0,
        keys: [redux.searchState.searchUserRoleBy]
      };

      const fuse = new Fuse(users, options);

      const findme = fuse.search(redux?.searchState?.searchUserRoleInput);

      return findme;
    }
  }
);

export const userSelectorByInstitutionId = createSelector(
  state => state,
  (_, institutionId) => institutionId,
  (redux, institutionId) => {
    const userSelectorORM = ormCreateSelector(orm.User);
    const user = userSelectorORM(redux).filter(
      user => user.institutionId === institutionId
    )[0];

    return user;
  }
);

export const usersSelectorByDepartmentFaculty = createSelector(
  state => state,
  state => state.filterCourseState.departmentUuid,
  (redux, departmentUuid) => {
    const usersSelectorORM = ormCreateSelector([orm], session => {
      const userDepartments = session.UserDepartment.all()
        .filter(
          userDepartment =>
            userDepartment.departmentUuid === departmentUuid &&
            userDepartment.role === roles.departmentFaculty
        )
        .toRefArray();

      const facultyUsers = userDepartments.map(userDepartment => {
        const user = session.User.withId(userDepartment.userUuid);
        return user ? user.ref : [];
      });

      const unique = _.uniqBy(facultyUsers, 'institutionId');

      return unique;
    });

    const users = usersSelectorORM(redux);
    return users;
  }
);

export const userWithRolesSelector = createSelector(
  state => state,
  (_, userUuid) => userUuid,
  (redux, userUuid) => {
    const rolesSelector = ormCreateSelector([orm], session => {
      const getUser = session.User.withId(userUuid);
      const user = getUser ? { ...getUser.ref } : undefined;

      const userSchools = session.UserSchool.all()
        .filter(userSchool => userSchool.userUuid === userUuid)
        .toRefArray();

      const userDepartments = session.UserDepartment.all()
        .filter(userDepartment => userDepartment.userUuid === userUuid)
        .toRefArray();

      const userPrograms = session.UserProgram.all()
        .filter(userProgram => userProgram.userUuid === userUuid)
        .toRefArray();

      const userDepartmentsWithSchool = userDepartments.map(userDepartment => {
        const getDepartment = session.Department.withId(
          userDepartment.departmentUuid
        );

        const department = getDepartment ? { ...getDepartment.ref } : {};

        return {
          ...userDepartment,
          schoolUuid: department.schoolUuid
        };
      });

      const userProgramsWithSchool = userPrograms.map(userProgram => {
        const getProgram = session.Program.withId(userProgram.programUuid);
        const program = getProgram ? { ...getProgram.ref } : {};

        return { ...userProgram, schoolUuid: program.schoolUuid };
      });

      const userInstitutions = session.UserInstitution.all()
        .filter(userInstitution => userInstitution.userUuid === userUuid)
        .toRefArray();

      const userRoles = [
        ...userProgramsWithSchool,
        ...userDepartmentsWithSchool,
        ...userSchools,
        ...userInstitutions
      ];

      return user ? { ...user, userRoles } : undefined;
    });

    const roles = rolesSelector(redux);
    return roles;
  }
);

const roleSelectors = (redux, userUuid) => {
  const getInstitutionAdminRoles = ormCreateSelector(orm.UserInstitution);
  const getSchoolAdminRoles = ormCreateSelector(orm.UserSchool);
  const getDepartmentAdminRoles = ormCreateSelector(orm.UserDepartment);
  const getProgramAdminRoles = ormCreateSelector(orm.UserProgram);
  const getDepartment = ormCreateSelector(orm.Department);
  const getProgram = ormCreateSelector(orm.Program);
  const getUserCohort = ormCreateSelector(orm.UserCohort);

  const filterRole = (user, role) =>
    user.userUuid === userUuid && user.role === role;

  const mapRole = (role, mapFromDepartment = false) => {
    const schoolUuid = mapFromDepartment
      ? getDepartment(redux, role.departmentUuid)
      : getProgram(redux, role.programUuid) ?? {};

    if (!schoolUuid) return role;

    const payload = {
      ...role,
      schoolUuid: schoolUuid.schoolUuid
    };

    return payload;
  };

  return {
    getInstitutionAdminRoles,
    getSchoolAdminRoles,
    getDepartmentAdminRoles,
    getProgramAdminRoles,
    getUserCohort,
    filterRole,
    mapRole
  };
};

export const selectUserRoles = createSelector(
  state => state,
  (_, userUuid) => userUuid,
  (redux, userUuid) => {
    const {
      getDepartmentAdminRoles,
      getInstitutionAdminRoles,
      getProgramAdminRoles,
      getSchoolAdminRoles,
      getUserCohort,
      filterRole,
      mapRole
    } = roleSelectors(redux, userUuid);

    const student =
      getUserCohort(redux).filter(user => filterRole(user, roles.student)) ||
      [];

    const institutionAdmin =
      getInstitutionAdminRoles(redux).filter(user =>
        filterRole(user, roles.institutionAdmin)
      ) || [];

    const schoolAdmin =
      getSchoolAdminRoles(redux).filter(user =>
        filterRole(user, roles.schoolAdmin)
      ) || [];

    const departmentFaculty =
      getDepartmentAdminRoles(redux)
        .filter(user => filterRole(user, roles.departmentFaculty))
        .map(role => mapRole(role, true)) || [];

    const departmentStaff =
      getDepartmentAdminRoles(redux)
        .filter(user => filterRole(user, roles.departmentStaff))
        .map(role => mapRole(role, true)) || [];

    const programAdmin =
      getProgramAdminRoles(redux)
        .filter(user => filterRole(user, roles.programAdmin))
        .map(mapRole) || [];

    const programStaff =
      getProgramAdminRoles(redux)
        .filter(user => filterRole(user, roles.programStaff))
        .map(mapRole) || [];

    const programFaculty =
      getProgramAdminRoles(redux)
        .filter(user => filterRole(user, roles.programFaculty))
        .map(mapRole) || [];

    return {
      institutionAdmin,
      schoolAdmin,
      departmentFaculty,
      departmentStaff,
      programAdmin,
      programStaff,
      programFaculty,
      student
    };
  }
);

export const selectAdminEmails = createSelector(
  state => state,
  (_, userUuid) => userUuid,
  (redux, userUuid) => {
    const {
      filterRole,
      getDepartmentAdminRoles,
      getInstitutionAdminRoles,
      getProgramAdminRoles,
      getSchoolAdminRoles,
      mapRole
    } = roleSelectors(redux, userUuid);

    const institutionAdmin =
      getInstitutionAdminRoles(redux).filter(user =>
        filterRole(user, roles.institutionAdmin)
      ) || [];

    const schoolAdmin =
      getSchoolAdminRoles(redux).filter(user =>
        filterRole(user, roles.schoolAdmin)
      ) || [];

    const departmentFaculty =
      getDepartmentAdminRoles(redux)
        .filter(user => filterRole(user, roles.departmentFaculty))
        .map(role => mapRole(role, true)) || [];

    const departmentStaff =
      getDepartmentAdminRoles(redux)
        .filter(user => filterRole(user, roles.departmentStaff))
        .map(role => mapRole(role, true)) || [];

    const programAdmin =
      getProgramAdminRoles(redux)
        .filter(user => filterRole(user, roles.programAdmin))
        .map(mapRole) || [];

    const programStaff =
      getProgramAdminRoles(redux)
        .filter(user => filterRole(user, roles.programStaff))
        .map(mapRole) || [];

    const programFaculty =
      getProgramAdminRoles(redux)
        .filter(user => filterRole(user, roles.programFaculty))
        .map(mapRole) || [];

    const userRoles = [
      ...institutionAdmin,
      ...schoolAdmin,
      ...departmentFaculty,
      ...departmentStaff,
      ...programAdmin,
      ...programStaff,
      ...programFaculty
    ];

    const emails = userRoles
      .map(record => record.email)
      .filter(email => !_.isNil(email));

    return emails;
  }
);

export const selectStudentEmails = createSelector(
  state => state,
  (_, userUuid) => userUuid,
  (redux, userUuid) => {
    const getCohortStudentRoles = ormCreateSelector(orm.UserCohort);
    const getCohort = ormCreateSelector(orm.Cohort);

    const cohortStudents =
      getCohortStudentRoles(redux)
        .filter(user => user.userUuid === userUuid && user.role === 'student')
        .map(role => {
          const cohort = getCohort(redux, role.cohortUuid);

          const payload = {
            ...role,
            programUuid: cohort.programUuid
          };

          return payload;
        }) || [];

    const emails = cohortStudents
      .map(record => record.email)
      .filter(email => !_.isNil(email));

    return emails;
  }
);
