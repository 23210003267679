import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useAtom, useSetAtom} from 'jotai';
import {useSelector} from 'react-redux';

import DeleteModal from '../../../../../Library/Modal/DeleteModal/DeleteModal';
import ProcedureCodeGroupAccordion
  from './ProcedureCodeGroupAccordion/ProcedureCodeGroupAccordion';
import {
  deleteCollectionAtom,
  deleteCollectionModalAtom, fetchCollectionsAtom,
} from '../../../state/collectionState';

export const CustomHeading = ({name}) => {
  return (
    <>
      Are you sure you want to delete
      <span
        style={{
          fontStyle: 'italic',
          fontWeight: 'bold',
        }}
      >
        &nbsp;&apos;{name}&apos;&nbsp;
      </span>
      collection?
    </>
  );
};

CustomHeading.propTypes = {
  name: PropTypes.string.isRequired,
};

const QuantitativeCollectionRow = ({
  quantitativeSkillCollection,
}) => {
  const programUuid = useSelector(state => state.userState.selectedProgramUuid);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useAtom(
    deleteCollectionModalAtom,
  );
  const deleteCollection = useSetAtom(deleteCollectionAtom);
  const getCollections = useSetAtom(fetchCollectionsAtom);

  const handleDelete = useCallback(async () => {
    await deleteCollection(quantitativeSkillCollection.uuid);
    await getCollections(programUuid);
  }, [
    deleteCollection,
    quantitativeSkillCollection.uuid,
    getCollections,
    programUuid,
  ]);

  const handleModalClose = useCallback(() => {
    setIsDeleteModalOpen({[quantitativeSkillCollection.uuid]: false});
  }, [setIsDeleteModalOpen, quantitativeSkillCollection.uuid]);

  return (
    <>
      <DeleteModal
        customHeading={
          <CustomHeading
            name={quantitativeSkillCollection.name}
          />
        }
        warningMessage="This action cannot be undone."
        modalOpen={isDeleteModalOpen[quantitativeSkillCollection.uuid]}
        handleModalClose={handleModalClose}
        handleDelete={handleDelete}
      />
      <ProcedureCodeGroupAccordion
        quantitativeSkillCollection={quantitativeSkillCollection}
      />
    </>
  );
};

QuantitativeCollectionRow.propTypes = {
  quantitativeSkillCollection: PropTypes.object.isRequired,
};

export default QuantitativeCollectionRow;
