export const formatLastName = (lastName, marriedLastName, suffix) => {
  let formatLastName = '';

  if (lastName && marriedLastName && suffix) {
    formatLastName = `(${lastName}) ${marriedLastName} ${suffix}`;
  } else if (lastName && marriedLastName && !suffix) {
    formatLastName = `(${lastName}) ${marriedLastName}`;
  } else if (lastName && !marriedLastName && suffix) {
    formatLastName = `${lastName} ${suffix}`;
  } else if (lastName) {
    formatLastName = `${lastName}`;
  }

  return formatLastName;
};

export const formatFirstNameMiddleName = (firstName, middleName) => {
  let name = '';

  if (middleName && firstName) {
    name = `${firstName} ${middleName}`;
  } else if (firstName && !middleName) {
    name = `${firstName}`;
  }
  return name;
};


export const formatFullName = (user) => {
  const lastName = formatLastName(
    user?.lastName,
    user?.marriedLastName,
    user?.suffix
  );
  const firstName = formatFirstNameMiddleName(
    user?.firstName,
    user?.middleName
  );

  return lastName && firstName ? `${lastName}, ${firstName}` : '';
};