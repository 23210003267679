import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import RoleCohort from './RoleCohort';
import { doPostUserRole } from '../../../redux/actions/userActions';

import {
  validateInputString,
  validateSelectDate
} from '../../../helpers/validation/validateGeneric';

export default function RoleCohortContainer({
  userUuid,
  cohortUuid,
  openRoleCohort,
  handleCohortRoleClose
}) {
  const dispatch = useDispatch();

  const [status, setStatus] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [role, setRole] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [hasErrors, setHasErrors] = useState(false);

  const createRole = useSelector(state => state.crudState.createRole);

  const createRoleResult = useSelector(
    state => state.crudState.createRoleResult
  );

  useEffect(() => {
    if (!openRoleCohort) {
      setStatus(undefined);
      setStartDate(undefined);
      setEndDate(undefined);
      setRole(undefined);
      setEmail(undefined);
      setPhone(undefined);
      setHasErrors(false);
    }
  }, [openRoleCohort]);

  useEffect(() => {
    if (createRoleResult === 'success') {
      handleCohortRoleClose();
      dispatch({ type: 'POST_USER_ROLE_RESET' });
    }
  }, [dispatch, createRoleResult, handleCohortRoleClose]);

  const handleRole = event => {
    const { name, value } = event.target;
    switch (name) {
      case 'status': {
        setStatus(value);
        break;
      }
      case 'role': {
        setRole(value);
        break;
      }
      case 'email': {
        setEmail(value);
        break;
      }
      case 'phone': {
        setPhone(value);
        break;
      }
      default:
        break;
    }
  };

  const handleRoleDate = (indentifier, field, date) => {
    if (field === 'startDate') {
      setStartDate(date);
    }

    if (field === 'endDate') {
      setEndDate(date);
    }
  };

  const validation = () => {
    let containErrors = false;
    const statusError = validateInputString(status);
    const startDateError = validateSelectDate(startDate);
    const roleError = validateInputString(role);
    const emailError = validateInputString(email);
    const phoneError = validateInputString(phone);

    if (
      statusError.invalid ||
      startDateError.invalid ||
      roleError.invalid ||
      emailError.invalid ||
      phoneError.invalid
    ) {
      containErrors = true;
    }
    return containErrors;
  };

  const handleRoleSubmit = () => {
    const reject = validation();
    if (reject) {
      setHasErrors(true);
    } else {
      const payload = {
        userUuid,
        cohortUuid,
        status,
        startDate,
        endDate,
        role,
        email,
        phone
      };

      dispatch(doPostUserRole('cohort_role', payload));
    }
  };

  return (
    <RoleCohort
      submit={createRole}
      result={createRoleResult}
      status={status}
      startDate={startDate}
      endDate={endDate}
      role={role}
      email={email}
      phone={phone}
      cohortUuid={cohortUuid}
      hasErrors={hasErrors}
      handleRole={handleRole}
      handleRoleDate={handleRoleDate}
      openRoleCohort={openRoleCohort}
      handleCohortRoleClose={handleCohortRoleClose}
      handleRoleSubmit={handleRoleSubmit}
    />
  );
}

RoleCohortContainer.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  openRoleCohort: PropTypes.bool,
  handleCohortRoleClose: PropTypes.func
};

RoleCohortContainer.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  openRoleCohort: false,
  handleCohortRoleClose: undefined
};
