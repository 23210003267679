import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function useWsSubscriber(url = undefined, key = undefined) {
  const [result, setResult] = useState(undefined);
  const [send, setSend] = useState(() => () => undefined);
  const [readyState, setReadyState] = useState(false);

  /*
    Please note: If a socket is establsed on page refresh then it must reconnect because
    loading an active user establishes the session on the serverside.
  */
  const loadingUser = useSelector(state => state.userState.fetching);

  useEffect(() => {
    if (url && key && !loadingUser) {
      const { hostname, port, protocol } = window.location;
      const setProtocol = protocol === 'https:' ? 'wss' : 'ws';

      const ws = new WebSocket(
        `${setProtocol}://${hostname}:${port}/ws/${url}`
      );

      ws.onopen = () => {
        // console.log(`----- ws connection -----`);
        // console.log('status: ', 'open');
        // console.log('ready state: ', ws.readyState);
        // console.log('\n');

        setReadyState(true);

        /* EMIT MESSAGE TO WEBSOCKET */
        setSend(() => {
          return data => {
            try {
              // console.log(`----- ws send message -----`);
              // console.log('message: ', data);
              // console.log('\n');
              const dataStringify = JSON.stringify(data);
              ws.send(dataStringify);
              return true;
            } catch (err) {
              return false;
            }
          };
        });

        /* RECIEVE MESSAGES FROM WEBSOCKET */
        ws.onmessage = event => {
          const msg = event.data;
          const { redisKey, redisData } = JSON.parse(msg);

          // console.log(`----- ws message -----`);
          // console.log('redisKey', redisKey);
          // console.log('redisData', redisData);
          // console.log('\n');

          if (redisKey === key) {
            setResult({ ...redisData });
          }
        };
      };

      ws.onclose = () => {
        // console.log(`----- ws connection -----`);
        // console.log('status: ', 'closed');
        // console.log('ready state: ', ws.readyState);
        // console.log('\n');
      };

      return () => {
        setReadyState(false);
        ws.close();
      };
    }
  }, [url, key, loadingUser]);

  return { send, result, readyState };
}

export { useWsSubscriber };
