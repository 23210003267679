import fetch from '../helpers/fetch';

const getProgram = programUuid =>
  fetch(`/api/program/${programUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getProgramAssessment = (programUuid, uuid) =>
  fetch(`/api/program/${programUuid}/program-assessment/${uuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getProgramAssessments = programUuid =>
  fetch(`/api/program/${programUuid}/program-assessments`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getPrograms = () =>
  fetch('/api/programs', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getProgramsBySchool = schoolUuid =>
  fetch(`/api/programs/find-by-school?schoolUuid=${schoolUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postProgram = program =>
  fetch('/api/program/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(program)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putProgram = program =>
  fetch(`/api/program/${program.uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(program)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postProgramAssessmentCollection = ({ title, programUuid }) =>
  fetch(`/api/program/${programUuid}/program-assessment-collection`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ title })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putProgramAssessmentCollection = ({ uuid, title, programUuid }) =>
  fetch(`/api/program/${programUuid}/program-assessment-collection/${uuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ title })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postProgramAssessmentMaster = ({ programUuid, ...body }) =>
  fetch(`/api/program/${programUuid}/program-assessment-master`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putProgramAssessmentMaster = ({ uuid, programUuid, ...body }) =>
  fetch(`/api/program/${programUuid}/program-assessment-master/${uuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteProgram = programUuid =>
  fetch(`/api/program/${programUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

const deleteProgramAssessmentCollection = ({ programUuid, uuid }) =>
  fetch(`/api/program/${programUuid}/program-assessment-collection/${uuid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

const deleteProgramAssessmentMaster = ({ programUuid, uuid }) =>
  fetch(`/api/program/${programUuid}/program-assessment-master/${uuid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  getProgram,
  getProgramAssessment,
  getProgramAssessments,
  getPrograms,
  getProgramsBySchool,
  postProgram,
  putProgram,
  postProgramAssessmentCollection,
  putProgramAssessmentCollection,
  postProgramAssessmentMaster,
  putProgramAssessmentMaster,
  deleteProgram,
  deleteProgramAssessmentCollection,
  deleteProgramAssessmentMaster
};
