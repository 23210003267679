import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProgramAssessmentCollectionForm from '../ProgramAssessmentCollectionForm';
import { RowSummary } from '../../../Library/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditProgramAssessmentCollection = ({
  title,
  showValidationErrors,
  handleTitleChange,
  onSubmit,
  toggleEditView
}) => {
  const closeEditView = () => toggleEditView(false);
  return (
    <Layout>
      <RowSummary>
        <ProgramAssessmentCollectionForm
          title={title}
          formSubtitle="Edit Collection"
          label="Collection Title"
          buttonText="Update"
          handleChange={handleTitleChange}
          onSubmit={onSubmit}
          showValidationErrors={showValidationErrors}
          closeForm={closeEditView}
        />
      </RowSummary>
    </Layout>
  );
};

EditProgramAssessmentCollection.propTypes = {
  title: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleTitleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditProgramAssessmentCollection.defaultProps = {
  title: '',
  showValidationErrors: false,
  handleTitleChange: undefined,
  onSubmit: undefined,
  toggleEditView: undefined
};

export default EditProgramAssessmentCollection;
