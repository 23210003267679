import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SelectedMicrosPill from '../../ManageCompetencyMicros/SelectedMicrosPill';

const Layout = styled.div``;

const SelectedMicrosDisplay = ({ microcompetencies }) => {
  const microsObj = {};
  if (microcompetencies) {
    microcompetencies.forEach(micro => {
      const topicKey = `${micro.subtopic.topic.uuid}`;

      if (microsObj[topicKey]) {
        microsObj[topicKey].micros[micro.uuid] = micro;
      } else {
        microsObj[topicKey] = {};
        microsObj[topicKey].topic = micro.subtopic.topic;
        microsObj[topicKey].micros = {};
        microsObj[topicKey].micros[micro.uuid] = micro;
      }
    });
  }
  return (
    <Layout>
      {Object.entries(microsObj).map(([topicUuid, { topic, micros }]) => (
        <SelectedMicrosPill key={topicUuid} topic={topic} microsMap={micros} />
      ))}
    </Layout>
  );
};

SelectedMicrosDisplay.propTypes = {
  microcompetencies: PropTypes.arrayOf(PropTypes.object)
};

SelectedMicrosDisplay.defaultProps = {
  microcompetencies: null
};

export default SelectedMicrosDisplay;
