import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import UserTabUpload from './UserTabUpload';
import { doGetInstitutionIdTypes } from '../../../redux/actions/sourceActions';

export default function UserTabUploadContainer() {
  const dispatch = useDispatch();
  const [openUpload, setOpenUpload] = useState(false);
  const [openUserAssignModal, setOpenUserAssignModal] = useState(false);

  useEffect(() => {
    dispatch(doGetInstitutionIdTypes());
  }, [dispatch]);

  function handleUserUploadOpen() {
    setOpenUpload(true);
  }

  function handleUserAssignOpen() {
    setOpenUserAssignModal(true);
  }

  function handleUserUploadClose() {
    setOpenUpload(false);
  }

  function handleUserAssignClose() {
    setOpenUserAssignModal(false);
  }

  return (
    <UserTabUpload
      openUpload={openUpload}
      openUserAssignModal={openUserAssignModal}
      handleUserUploadOpen={() => handleUserUploadOpen()}
      handleUserAssignOpen={() => handleUserAssignOpen()}
      handleUserUploadClose={() => handleUserUploadClose()}
      handleUserAssignClose={() => handleUserAssignClose()}
    />
  );
}
