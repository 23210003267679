import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2, ButtonOutline } from '@xcomp/xcomp-design-library';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import { useParams } from 'react-router-dom';

import StatusBar from './StatusBar';
import ActionButton from '../../../../Library/AccordionRow/ActionButton';

// import {
//   visibleToSchoolRoles,
//   visibleToSchoolAdmin
// } from '../../../../../helpers/authorization';

import { roles } from '../../../../../helpers/constants';
import { doPatchCohortAssessmentStatus } from '../../../../../redux/actions/cohortAssessmentActions';

export const DeleteButtonSC = styled(ButtonOutline)`
  && {
    color: ${props => props.theme.colors.status.error};
    transition: background 0.4s ease-in, color 0.4s ease-in, border 0.4s ease-in;
    margin-left: 24px;
    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.status.error};
      border-color: ${props => props.theme.colors.status.error};
    }

    svg {
      font-size: 20px;
    }
  }
`;

const Layout = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  height: 70px;
`;

const Title = styled(Heading2)`
  margin: 0;
`;

const ActionButtonSC = styled(ActionButton)`
  margin-left: 16px;
`;

const TitleAndStatus = styled.div`
  display: flex;
`;

const ViewOnlyMode = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
`;

const ViewOnlyLabel = styled.div`
  padding-left: 5px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
`;

const LayoutLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const LayoutRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SubmitButton = styled(ButtonOutline)``;

export default function ItemFormHeader({
  isExistingItem,
  itemNumber,
  item,
  handleSaveDraft,
  // handleFinish,
  // handleApprove,
  handleDeleteOpen,
  itemType,
  restrictEdit,
  isNew
}) {
  const dispatch = useDispatch();
  const { cohortUuid } = useParams();

  const activeUser = useSelector(state => state.userState, shallowEqual);
  const title = isExistingItem ? (
    <Title>
      {itemType} {itemNumber.toString().padStart(2, 0)}
    </Title>
  ) : (
    <Title>New {itemType}</Title>
  );

  const onPatchStatus = status =>
    dispatch(doPatchCohortAssessmentStatus(item.uuid, cohortUuid, status));

  const onSaveInProgress = () => onPatchStatus('In Progress');
  const onComplete = () => onPatchStatus('Complete');
  const onApprove = () => onPatchStatus('Approved');

  const showAdminActions = activeUser.selectedRole === roles.programAdmin;

  const inProgressAction = {
    label: 'In Progress',
    onClick: onSaveInProgress,
    shouldOpenOnClick: false,
    icon: 'unlock'
  };

  const completeAction = {
    label: 'Complete',
    onClick: onComplete,
    shouldOpenOnClick: false,
    icon: 'assessment'
  };

  const approveAction = {
    label: 'Approve',
    onClick: onApprove,
    shouldOpenOnClick: false,
    icon: 'locked'
  };

  const deleteAction = {
    label: 'Delete',
    onClick: handleDeleteOpen,
    shouldOpenOnClick: false,
    icon: 'delete'
  };

  let assessmentActions;

  switch (item.status) {
    case 'Approved': {
      assessmentActions = [inProgressAction, completeAction];
      break;
    }
    case 'Complete': {
      assessmentActions = [inProgressAction, approveAction, deleteAction];
      break;
    }
    case 'In Progress':
    default: {
      assessmentActions = [completeAction, approveAction, deleteAction];
    }
  }

  return (
    <Layout>
      <LayoutLeft>
        <TitleAndStatus>
          {title}
          {item.uuid && <StatusBar status={item.status} />}
        </TitleAndStatus>
      </LayoutLeft>
      <LayoutRight>
        {restrictEdit ? (
          <ViewOnlyMode>
            <RemoveRedEye />
            <ViewOnlyLabel>view only mode</ViewOnlyLabel>
          </ViewOnlyMode>
        ) : (
          <>
            <SubmitButton onClick={handleSaveDraft}>
              {isNew ? 'Create Assessment' : 'Save Assessment'}
            </SubmitButton>
            {isExistingItem && showAdminActions && (
              <ActionButtonSC
                actions={assessmentActions}
                isRowOpen={false}
                toggleOpenRow={undefined}
              />
            )}
          </>
        )}
      </LayoutRight>
    </Layout>
  );
}

ItemFormHeader.propTypes = {
  restrictEdit: PropTypes.bool,
  isNew: PropTypes.bool,
  isExistingItem: PropTypes.bool,
  itemNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  itemType: PropTypes.string,
  item: PropTypes.object,
  handleSaveDraft: PropTypes.func,
  // handleFinish: PropTypes.func,
  // handleApprove: PropTypes.func,
  handleDeleteOpen: PropTypes.func
};

ItemFormHeader.defaultProps = {
  restrictEdit: true,
  isNew: false,
  isExistingItem: false,
  itemNumber: 1,
  itemType: PropTypes.string,
  item: {},
  handleSaveDraft: undefined,
  // handleFinish: undefined,
  // handleApprove: undefined,
  handleDeleteOpen: undefined
};
