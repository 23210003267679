import React, { useReducer, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  doGetPartGradeSettings,
  doPutPartGradeDataSource
} from '../../../redux/actions/partGradeSettingsActions';
import { doClearCreated } from '../../../redux/actions/courseCollectionActions';
import { useUnsavedChanges } from '../../Library/Modal/UnsavedChangesModal/useUnsavedChanges';
import PartGradeSettings from './PartGradeSettings';
import {
  partGradeSettingsReducer,
  initialPartGradeSettingsState
} from './partGradeSettingsState';
import { partSelectorByUuid } from '../../../redux/selectors/partsSelectors';
import { partGradeSettingsSelector } from '../../../redux/selectors/partGradeSettingsSelectors';

export default function PartGradeSettingsContainer({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { partUuid } = match.params;

  const [state, partGradeSettingsDispatch] = useReducer(
    partGradeSettingsReducer,
    initialPartGradeSettingsState
  );

  const part = useSelector(
    state => partSelectorByUuid(state, partUuid),
    _.isEqual
  );

  const partGradeSettings = useSelector(
    state => partGradeSettingsSelector(state, partUuid),
    _.isEqual
  );

  const successfullyUpdatedPartDataSource = useSelector(
    state => state.formSuccessState.successfullyUpdatedPartDataSource
  );

  const section = _.get(part, 'section', null);
  const course = _.get(section, 'course', null);
  const courseNumber = _.get(course, 'course_master.courseNumber', '');
  const courseTitle = _.get(course, 'course_master.title', '');
  const sectionNumber = _.get(section, 'sectionNumber', '')
    .toString()
    .padStart(2, 0);
  const partNumber = _.get(part, 'partNumber', '').toString().padStart(2, 0);

  const clearForm = useCallback(() => {
    partGradeSettingsDispatch({
      type: 'clearForm'
    });
    history.push(`/syllabus-management/all`);
  }, [history]);

  const [
    isUnsavedChangesModalOpen,
    unsavedChanges,
    handleModalOpen,
    handleModalClose,
    setUnsavedChange,
    removeUnsavedChange,
    handleProceed
  ] = useUnsavedChanges(clearForm);

  useEffect(() => {
    dispatch(doGetPartGradeSettings(partUuid));
  }, [dispatch, partUuid]);

  useEffect(() => {
    if (successfullyUpdatedPartDataSource) {
      dispatch(doClearCreated());
      removeUnsavedChange('dataSource');
    }
  }, [dispatch, removeUnsavedChange, successfullyUpdatedPartDataSource]);

  const setExistingState = ({ uuid, dataSource }) => {
    partGradeSettingsDispatch({
      type: 'setExistingSettings',
      payload: {
        uuid,
        dataSource
      }
    });
  };

  const existingPartUuid = partGradeSettings?.partUuid;
  const existingDataSource = partGradeSettings?.dataSource;
  const existingUuid = partGradeSettings?.uuid || null;

  useEffect(() => {
    if (existingPartUuid) {
      setExistingState({
        uuid: existingUuid,
        dataSource: existingDataSource
      });
    }
  }, [existingPartUuid, existingDataSource, existingUuid]);

  const { uuid, dataSource, showValidationErrors } = state;

  const handleSubmitPartDataSource = () => {
    const dataSourcePayload = {
      partUuid,
      dataSource
    };

    dispatch(doPutPartGradeDataSource(dataSourcePayload));
  };

  const handleDataSourceChange = event => {
    partGradeSettingsDispatch({
      type: 'setDataSource',
      payload: {
        dataSource: event.target.value
      }
    });
    setUnsavedChange('dataSource');
  };

  return (
    <PartGradeSettings
      savedDataSource={partGradeSettings?.dataSource}
      dataSource={dataSource}
      partGradeSettingsUuid={uuid}
      courseNumber={courseNumber}
      courseTitle={courseTitle}
      sectionNumber={sectionNumber}
      partNumber={partNumber}
      partTitle={part?.title}
      handleDataSourceChange={handleDataSourceChange}
      handleSubmitPartDataSource={handleSubmitPartDataSource}
      showValidationErrors={showValidationErrors}
      isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
      unsavedChanges={unsavedChanges}
      setUnsavedChange={setUnsavedChange}
      removeUnsavedChange={removeUnsavedChange}
      handleModalOpen={handleModalOpen}
      handleModalClose={handleModalClose}
      handleProceed={handleProceed}
    />
  );
}

PartGradeSettingsContainer.propTypes = {
  match: PropTypes.object
};

PartGradeSettingsContainer.defaultProps = {
  match: {}
};
