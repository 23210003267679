import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Caption } from '@xcomp/xcomp-design-library';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import FacultyStudentScore from '../FacultyStudentScore';
import { ScoreRow } from '../../ScoresSC';
import { sortScores } from '../../../../../helpers/utilities';

const FacultyStudentScoreAttemptPanelAccordion = styled(
  ({ withBottomBorder, withTopBorder, criticalFailure, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}
  .accordion_row_label {
    flex-grow: 2;
  }
  .column_one_label,
  .column_two_label {
    flex-grow: 0;
    width: 100px;
    text-align: right;
  }
  .accordion_row_label div {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.15;
    color: ${props => props.theme.fontColors.darker};
  }
  .column_one_label {
    margin-right: 16px;
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props =>
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.theme.fontColors.darker};
    }
  }
  .column_two_label {
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props => props.theme.fontColors.darker};
    }
  }
  .hover-effect {
    padding-right: 44px;
  }
`;

const ScoreColumnHeader = styled(({ width, ...rest }) => <Caption {...rest} />)`
  width: ${props => props.width || 'initial'};
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
`;

const FacultyAlternativeScoreViewAttemptPanel = ({
  attemptOrOpportunityLabel,
  studentAttemptScores,
  totalRelativeByAttempt,
  totalPotentialByAttempt,
  isOpportunity,
}) => {
  return (
    <FacultyStudentScoreAttemptPanelAccordion
      expandWidth="66px"
      height="50px"
      label={attemptOrOpportunityLabel}
      columnOne={totalRelativeByAttempt}
      columnTwo={totalPotentialByAttempt}
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
      rowOffset="60px"
    >
      <ScoreRow marginLeft="100px">
        <ScoreColumnHeader width="10%">Date</ScoreColumnHeader>
        <ScoreColumnHeader width="10%">Rubric Item</ScoreColumnHeader>
        {!isOpportunity &&
          <>
            <ScoreColumnHeader width="5%">Sequence</ScoreColumnHeader>
            <ScoreColumnHeader width="5%">Rubric</ScoreColumnHeader>
          </>
        }
        <ScoreColumnHeader width="10%">Step/Item</ScoreColumnHeader>
        <ScoreColumnHeader width="15%">Description</ScoreColumnHeader>
        <ScoreColumnHeader width={isOpportunity ? "36%" : "26%"}>Topic</ScoreColumnHeader>
        <ScoreColumnHeader width="7%">Rel.</ScoreColumnHeader>
        <ScoreColumnHeader width="12%">Pot.</ScoreColumnHeader>
      </ScoreRow>
      {studentAttemptScores.length > 0 &&
        studentAttemptScores.sort(sortScores).map(studentScore => (
          <FacultyStudentScore
            key={studentScore.uuid}
            isOpportunity={isOpportunity}
            date={studentScore.date}
            potValue={studentScore.potValue}
            relValue={studentScore.relValue}
            sequenceOrder={studentScore.sequenceOrder}
            rubricOrder={studentScore.rubricOrder}
            procedureCodeOrder={studentScore.procedureCodeOrder}
            procedureCodeDescriptor={studentScore.procedureCodeDescriptor}
            rubricItem={studentScore.rubricItem}
            stepSubItem={studentScore.stepSubItem}
            stepSubItemDescription={studentScore.stepSubItemDescription}
            topicUuid={studentScore.topicUuid}
            uuid={studentScore.uuid}
          />
        ))}
    </FacultyStudentScoreAttemptPanelAccordion>
  );
};

FacultyAlternativeScoreViewAttemptPanel.propTypes = {
  attemptOrOpportunityLabel: PropTypes.string,
  studentAttemptScores: PropTypes.array,
  totalRelativeByAttempt: PropTypes.string,
  totalPotentialByAttempt: PropTypes.string,
  isOpportunity: PropTypes.bool,
};
FacultyAlternativeScoreViewAttemptPanel.defaultProps = {
  isOpportunity: false,
  attemptOrOpportunityLabel: '1',
  studentAttemptScores: [],
  totalRelativeByAttempt: '',
  totalPotentialByAttempt: ''
};

export default FacultyAlternativeScoreViewAttemptPanel;
