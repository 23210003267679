import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import SyllabusAdd from './SyllabusAdd';
import SyllabusItemList from './SyllabusItemList';
import FormSelect from '../../Library/FormSelect';

import {
  optionsSyllabus,
  optionsSyllabusStatus
} from '../../../helpers/options';

const SyllabusHeader = styled.div`
  background: ${props => props.theme.colors.white};
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  height: 20%;
`;

const SyllabusBody = styled.div`
  background: ${props => props.theme.colors.white};
  height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 200px;
`;

const EmptyItemsMessage = styled.div`
  padding: 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  p {
    font-size: 0.875rem;
    padding: 7rem 3rem;
    color: ${props => props.theme.fontColors.dark};
    line-height: 1.6;
  }
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const SyllabusSchedule = ({
  restrictEdit,
  syllabusItems,
  addSyllabusItem,
  itemId,
  changeItemId,
  filterStatus,
  filterType,
  handleFilterType,
  handleFilterStatus
}) => (
  <Layout>
    <SyllabusHeader>
      <Grid container alignItems="center" spacing={2}>
        <Grid item sm={6} xs={12}>
          <FormSelect
            value={filterType}
            options={optionsSyllabus}
            handleChange={handleFilterType}
            name="status"
            label="Filter By Type"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormSelect
            value={filterStatus}
            options={optionsSyllabusStatus}
            handleChange={handleFilterStatus}
            name="status"
            label="Filter By Status"
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          {!restrictEdit && <SyllabusAdd addSyllabusItem={addSyllabusItem} />}
        </Grid>
      </Grid>
    </SyllabusHeader>

    <SyllabusBody>
      {syllabusItems && syllabusItems.length > 0 ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SyllabusItemList
              syllabusItems={syllabusItems}
              itemId={itemId}
              changeItemId={changeItemId}
            />
          </Grid>
        </Grid>
      ) : (
        <EmptyItemsMessage>
          <p>
            Please add an encounter or assessment to begin creating your
            syllabus.
          </p>
        </EmptyItemsMessage>
      )}
    </SyllabusBody>
  </Layout>
);

SyllabusSchedule.propTypes = {
  restrictEdit: PropTypes.bool,
  syllabusItems: PropTypes.arrayOf(PropTypes.object),
  itemId: PropTypes.string,
  filterStatus: PropTypes.string,
  filterType: PropTypes.string,
  addSyllabusItem: PropTypes.func,
  changeItemId: PropTypes.func,
  handleFilterType: PropTypes.func,
  handleFilterStatus: PropTypes.func
};

SyllabusSchedule.defaultProps = {
  restrictEdit: true,
  syllabusItems: [],
  itemId: '',
  filterStatus: 'all',
  filterType: 'all',
  addSyllabusItem: undefined,
  changeItemId: undefined,
  handleFilterType: undefined,
  handleFilterStatus: undefined
};

export default SyllabusSchedule;
export { Paper, SyllabusHeader, SyllabusBody, EmptyItemsMessage };
