import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS,
  DELETE_PART_GRADE_DELAYED_STUDENT_SUCCESS,
  DELETE_SCORES_SUCCESS,
  GET_ASSESSMENT_GRADES_SUCCESS,
  GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS,
  GET_CURRENT_RESULT_CARDS_SUCCESS,
  GET_GRADE_SUMMARY_SUCCESS,
  PATCH_PART_UNDO_CALCULATED_GRADE_SUCCESS,
  PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_SUCCESS,
  PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS,
  PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_SUCCESS,
  PUT_PART_GRADE_DELAYED_STUDENT_SUCCESS
} from '../actions-type';

class ResultCard extends Model {
  static reducer(action, ResultCard, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_CURRENT_RESULT_CARDS_SUCCESS: {
        const { assessmentResultCards } = payload.gradeSummary;
        if (assessmentResultCards && assessmentResultCards.length > 0) {
          assessmentResultCards.map(resultCard => {
            return ResultCard.upsert(resultCard);
          });
        }
        break;
      }
      case PUT_PART_GRADE_DELAYED_STUDENT_SUCCESS: {
        const { assessmentResultCards } = payload.gradeSummary;
        if (assessmentResultCards && assessmentResultCards.length > 0) {
          assessmentResultCards.map(resultCard => {
            return ResultCard.upsert(resultCard);
          });
        }
        break;
      }
      case DELETE_PART_GRADE_DELAYED_STUDENT_SUCCESS: {
        const { assessmentResultCards } = payload.gradeSummary;
        if (assessmentResultCards && assessmentResultCards.length > 0) {
          assessmentResultCards.map(resultCard => {
            return ResultCard.upsert(resultCard);
          });
        }
        break;
      }
      case PATCH_PART_UNDO_CALCULATED_GRADE_SUCCESS: {
        ResultCard.all().delete();

        break;
      }
      case GET_GRADE_SUMMARY_SUCCESS: {
        const { resultCards } = action.payload.gradeSummary;

        if (resultCards && resultCards.length > 0) {
          resultCards.map(resultCard => {
            return ResultCard.upsert(resultCard);
          });
        }
        break;
      }
      case PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS: {
        const { result_cards } = action.payload;

        if (result_cards && result_cards.length > 0) {
          result_cards.map(resultCard => {
            return ResultCard.upsert(resultCard);
          });
        }
        break;
      }
      case PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_SUCCESS: {
        const { result_card } = action.payload;

        if (result_card) return ResultCard.upsert(result_card);
        break;
      }
      case PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_SUCCESS: {
        const { result_card } = action.payload;

        if (result_card) return ResultCard.upsert(result_card);
        break;
      }
      case DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS: {
        const { result_card } = action.payload;

        if (result_card) return ResultCard.upsert(result_card);
        break;
      }
      case DELETE_SCORES_SUCCESS: {
        ResultCard.all().delete();
        break;
      }
      case GET_ASSESSMENT_GRADES_SUCCESS: {
        const { gradeSummary } = payload;
        gradeSummary.resultCards.forEach(resultCard => {
          ResultCard.upsert(resultCard);
        });
        break;
      }
      case GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS: {
        const { resultCards } = payload;
        resultCards.forEach(resultCard => {
          const {
            uuid,
            attempt,
            calculatedGrade,
            isCurrentGrade,
            assessmentRelativeScore,
            assessmentPotentialScore,
            createdAt,
            updatedAt,
            assessmentUuid,
            studentUuid
          } = resultCard;

          ResultCard.upsert({
            uuid,
            assessmentPotentialScore,
            assessmentRelativeScore,
            assessmentUuid,
            attempt,
            calculatedGrade,
            createdAt,
            isCurrentGrade,
            studentUuid,
            updatedAt
          });
        });
        break;
      }

      default:
        return session;
    }
  }
}

ResultCard.fields = {
  uuid: attr(),
  assessmentPotentialScore: attr(),
  assessmentRelativeScore: attr(),
  assessmentUuid: fk('Assessment', 'result_cards'),
  attempt: attr(),
  calculatedGrade: attr(),
  collection_assessments: attr(),
  createdAt: attr(),
  delayedStatusCommit: attr(),
  gradeModification: attr(),
  isCurrentGrade: attr(),
  letterGrade: attr(),
  studentUuid: fk('User', 'result_cards'),
  updatedAt: attr()
};

ResultCard.modelName = 'ResultCard';

ResultCard.options = {
  idAttribute: 'uuid'
};

export default ResultCard;
