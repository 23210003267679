const initialPartStatusState = {
  uuid: '',
  statusCommit: 'UNCOMMITTED',
  status: 'In Progress'
};

const partStatusReducer = (state, action) => {
  switch (action.type) {
    case 'setExistingPart': {
      const { uuid, statusCommit, status } = action.payload;

      return {
        ...state,
        uuid,
        statusCommit,
        status
      };
    }
    case 'setStatusCommitted': {
      return { ...state, statusCommit: 'COMMITTED' };
    }
    case 'setStatusUncommitted':
      return { ...state, statusCommit: 'UNCOMMITTED' };
    case 'clearForm':
      return initialPartStatusState;
    default: {
      throw new Error();
    }
  }
};

export { initialPartStatusState, partStatusReducer };
