import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';

import { opportunityAssessmentTypesSelector } from '../../../redux/selectors/assessmentSelectors';

import { doGetAssessmentType } from '../../../redux/actions/assessmentActions';
import { doGetUserCohorts } from '../../../redux/actions/userCohortActions';

import { doGetCohortOpportunityReport } from '../../../redux/actions/cohortOpportunitiesReportActions';
import { useSortReportStudents } from '../../../helpers/hooks/useSortStudents';

import { Panel } from '../../Library/DashboardSC';

import StudentAccordionRow from './StudentAccordionRow';
import FilterDropDown from '../../SyllabusManagement/PartGradeSummary/FilterDropDown';

const ProgressBar = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.fontColors.dark};
`;

const PanelSC = styled(Panel)`
  margin-top: 24px;
  min-width: 900px;
`;

const AssessmentHeaderContainer = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  padding-right: ${props => props.paddingRight};
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  border-bottom: ${props => `solid 1px  ${props.theme.colors.grey[300]}`};
  font-size: 15px;
  font-weight: 450;
`;

const AssessmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 66px;
  width: calc(100% - 66px);
`;

const AssessmentHeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  overflow: hidden;
`;

const AssessmentHeaderData = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  min-width: 350px;
`;

const AssessmentHeaderSub = styled.div`
  min-width: ${props => props.minWidth};
  margin-right: ${props => props.marginRight};
  color: ${props => props.theme.fontColors.darker};
  text-align: right;
`;

const NoDataContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.fontColors.dark};
`;

const OpportunityReportPanel = () => {
  const { cohortUuid } = useParams();
  const dispatch = useDispatch();
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);

  const assessmentTypes = useSelector(
    state => opportunityAssessmentTypesSelector(state),
    isEqual
  );

  const { coursePerformanceReport, isLoading } = useSelector(
    state => state.cohortOpportunityReportState
  );

  const sortedStudents = useSortReportStudents({
    cohortUuid,
    isCourseReport: true
  });

  useEffect(() => {
    if (cohortUuid) {
      dispatch(doGetUserCohorts(cohortUuid));
    }
    dispatch(doGetAssessmentType());
  }, [dispatch, cohortUuid]);

  const emptyReport = useMemo(
    () => coursePerformanceReport && coursePerformanceReport?.records === 0,
    [coursePerformanceReport]
  );

  const handleApplyPartOpportunityReportFilters = assessmentTypes => {
    setHasAppliedFilters(true);
    dispatch(doGetCohortOpportunityReport({ cohortUuid, assessmentTypes }));
  };

  return (
    <div>
      <FilterDropDown
        options={assessmentTypes}
        callback={handleApplyPartOpportunityReportFilters}
        title='Opportunity Report Filters'
      />
      {isLoading ? (
        <PanelSC>
          <ProgressBar>Loading</ProgressBar>
          <LinearProgress />
        </PanelSC>
      ) : (
        <PanelSC>
          {!emptyReport && (
            <AssessmentHeaderContainer paddingRight="44px">
              <AssessmentHeader>
                <AssessmentHeaderInfo>
                  Student (Last name, First name)
                </AssessmentHeaderInfo>
                <AssessmentHeaderData>
                  <AssessmentHeaderSub marginRight="16px" minWidth="140px">
                    Total Opportunities
                  </AssessmentHeaderSub>
                  <AssessmentHeaderSub marginRight="16px" minWidth="100px">
                    Total Relative
                  </AssessmentHeaderSub>
                  <AssessmentHeaderSub marginRight="8px" minWidth="120px">
                    Points per Opp.
                  </AssessmentHeaderSub>
                </AssessmentHeaderData>
              </AssessmentHeader>
            </AssessmentHeaderContainer>
          )}
          {hasAppliedFilters && emptyReport && (
            <NoDataContainer>
              No data for the selected filters. Change filter selection and
              re-apply.
            </NoDataContainer>
          )}
          {!hasAppliedFilters && emptyReport && (
            <NoDataContainer>
              Apply the filter selection to view the report.
            </NoDataContainer>
          )}
          {!emptyReport &&
            sortedStudents.map(student => (
              <StudentAccordionRow key={student.uuid} student={student} />
            ))}
        </PanelSC>
      )}
    </div>
  );
};

export default OpportunityReportPanel;
