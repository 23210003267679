import { Model, attr } from 'redux-orm';
import { GET_TRANSCRIPT_SUCCESS } from '../actions-type';

class ApplicantPhysicalSkillsRank extends Model {
  static reducer(action, ApplicantPhysicalSkillsRank, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_TRANSCRIPT_SUCCESS: {
        const applicantPhysicalSkillsRank = [
          ...payload.applicantPhysicalSkillsRank
        ];
        applicantPhysicalSkillsRank.forEach(rank =>
          ApplicantPhysicalSkillsRank.upsert(rank)
        );
        break;
      }

      default:
        return session;
    }
  }
}

ApplicantPhysicalSkillsRank.fields = {
  uuid: attr(),
  cohortRankRelative: attr(),
  cohortRankPotential: attr(),
  cumulativeCredits: attr(),
  averageCreditScore: attr(),
  letterGrade: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  applicantUuid: attr(),
  cohortUuid: attr(),
  applicantPhysicalSkillsReportTypeUuid: attr(),
  classificationUuid: attr(),
  examAptitudeUuid: attr(),
  examMilestoneUuid: attr()
};

ApplicantPhysicalSkillsRank.modelName = 'ApplicantPhysicalSkillsRank';
ApplicantPhysicalSkillsRank.options = {
  idAttribute: 'uuid'
};

export default ApplicantPhysicalSkillsRank;
