import React, { useReducer, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import SectionGrading from './SectionGrading';
import { sectionSelector } from '../../../../../../redux/selectors/sectionsSelectors';
import { useDelete } from '../../../../../Library/Modal/DeleteModal/useDelete';

import {
  doPatchSectionCalculateGrade,
  doPatchSectionUndoCalculatedGrade,
  doPutSectionStatusCommit
} from '../../../../../../redux/actions/sectionActions';
import {
  sectionGradeStatusReducer,
  initialSectionGradeStatusState
} from './sectionGradingState';

export default function SectionGradingContainer({ sectionUuid }) {
  const dispatch = useDispatch();

  const [state, sectionGradeDispatch] = useReducer(
    sectionGradeStatusReducer,
    initialSectionGradeStatusState
  );

  const existingSection = useSelector(
    state => sectionSelector(state, sectionUuid),
    shallowEqual
  );

  const setExistingState = ({ uuid, statusCommit, statusGrade }) => {
    sectionGradeDispatch({
      type: 'setExistingSection',
      payload: {
        uuid,
        statusCommit,
        statusGrade
      }
    });
  };

  useEffect(() => {
    if (existingSection?.uuid)
      setExistingState({
        uuid: existingSection.uuid,
        statusCommit: existingSection.statusCommit,
        statusGrade: existingSection.statusGrade
      });
  }, [existingSection]);

  const { statusCommit, statusGrade, justificationMessage } = state;

  const handleCommit = () => {
    sectionGradeDispatch({
      type: 'setStatusCommitted'
    });
  };

  const handleUncommit = () => {
    sectionGradeDispatch({ type: 'setStatusUncommitted' });
  };

  const handleJustificationChange = event => {
    sectionGradeDispatch({
      type: 'setJustificationMessage',
      payload: {
        justificationMessage: event.target.value
      }
    });
  };

  const handleResetCommitment = () => {
    if (existingSection?.uuid)
      setExistingState({
        uuid: existingSection.uuid,
        statusCommit: existingSection.statusCommit,
        statusGrade: existingSection.statusGrade
      });
  };

  const handleStatusCommitSubmit = () => {
    const payload = {
      uuid: sectionUuid,
      statusCommit,
      justificationMessage:
        statusCommit === 'COMMITTED' ? null : justificationMessage
    };

    dispatch(doPutSectionStatusCommit(payload));
  };

  const handleStatusGradeSubmit = () => {
    dispatch(doPatchSectionCalculateGrade(sectionUuid));
  };

  const onDelete = () =>
    dispatch(doPatchSectionUndoCalculatedGrade(sectionUuid));

  const [
    isDeleteModalOpen,
    handleDeleteClick,
    handleDeleteModalClose,
    handleDelete
  ] = useDelete(onDelete);

  const onDeleteClick = e => {
    e.stopPropagation();
    handleDeleteClick();
  };

  return (
    <SectionGrading
      sectionUuid={sectionUuid}
      statusCommit={statusCommit}
      justificationMessage={justificationMessage}
      statusGrade={statusGrade}
      handleCommit={handleCommit}
      isDeleteModalOpen={isDeleteModalOpen}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDelete={handleDelete}
      onDeleteClick={onDeleteClick}
      handleUncommit={handleUncommit}
      handleGrading={handleCommit}
      handleJustificationChange={handleJustificationChange}
      handleGradingCancel={handleUncommit}
      handleStatusCommitSubmit={handleStatusCommitSubmit}
      handleStatusGradeSubmit={handleStatusGradeSubmit}
      handleResetCommitment={handleResetCommitment}
    />
  );
}

SectionGradingContainer.propTypes = {
  sectionUuid: PropTypes.string
};

SectionGradingContainer.defaultProps = {
  sectionUuid: null
};
