import React from 'react';
import styled from 'styled-components';
import { LockIcon, WarningIcon } from '@xcomp/xcomp-design-library/dist/icons';
import LockOpenIcon from '@xcomp/xcomp-design-library/dist/icons/actions/LockOpenIcon';
import PropTypes from 'prop-types';

const Layout = styled.div`
  background-color: ${props => props.theme.colors.primaryShades[50]};
  border-radius: 16px;
  padding: 4px 16px 4px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

const StatusPillUnlockIcon = styled(LockOpenIcon)`
  && {
    font-size: 16px;
    margin: 0;
    margin-right: 8px;
  }
`;

const StatusPillLockIcon = styled(LockIcon)`
  && {
    font-size: 18px;
    margin: 0;
    margin-right: 8px;
  }
`;

const WarningIconSC = styled(WarningIcon)`
  && {
    font-size: 18px;
    margin: 0;
    margin-right: 8px;
  }
`;

const PillLayout = styled.div`
  background-color: ${props => props.theme.colors.primaryShades[50]};
  border-radius: 16px;
  padding: 4px 16px 4px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
`;

const StatusCommitPill = ({ statusCommit }) => {
  switch (statusCommit) {
    case 'COMMITTED': {
      return (
        <PillLayout>
          <StatusPillLockIcon />
          Committed
        </PillLayout>
      );
    }
    case 'UNCOMMITTED': {
      return (
        <PillLayout>
          <StatusPillUnlockIcon />
          Not Committed
        </PillLayout>
      );
    }
    default: {
      return (
        <PillLayout>
          <StatusPillUnlockIcon />
          Not Committed
        </PillLayout>
      );
    }
  }
};

StatusCommitPill.propTypes = {
  statusCommit: PropTypes.string
};

StatusCommitPill.defaultProps = {
  statusCommit: undefined
};

const StatusGradePill = ({ statusGrade }) => {
  switch (statusGrade) {
    case 'GRADED': {
      return (
        <PillLayout>
          <StatusPillLockIcon />
          Graded
        </PillLayout>
      );
    }
    case 'GRADING_IN_PROGRESS': {
      return (
        <PillLayout>
          <StatusPillUnlockIcon />
          Grading In Progress
        </PillLayout>
      );
    }
    case 'NOT_GRADED': {
      return (
        <PillLayout>
          <StatusPillUnlockIcon />
          Not Graded
        </PillLayout>
      );
    }
    default: {
      return (
        <PillLayout>
          <StatusPillUnlockIcon />
          Not Graded
        </PillLayout>
      );
    }
  }
};

StatusGradePill.propTypes = {
  statusGrade: PropTypes.string
};

StatusGradePill.defaultProps = {
  statusGrade: undefined
};

const PartStatusPill = ({ status }) => {
  switch (status) {
    case 'In Progress': {
      return (
        <PillLayout>
          <StatusPillUnlockIcon />
          In Progress
        </PillLayout>
      );
    }
    case 'Committed': {
      return (
        <PillLayout>
          <StatusPillLockIcon />
          Approved and Locked
        </PillLayout>
      );
    }
    case 'Published': {
      return (
        <PillLayout>
          <StatusPillLockIcon />
          Published
        </PillLayout>
      );
    }
    default: {
      return (
        <PillLayout>
          <StatusPillUnlockIcon />
          In Progress
        </PillLayout>
      );
    }
  }
};

PartStatusPill.propTypes = {
  status: PropTypes.string
};

PartStatusPill.defaultProps = {
  status: undefined
};

const StatusPill = ({ status }) => {
  switch (status) {
    case 'In Progress': {
      return (
        <Layout>
          <StatusPillUnlockIcon />
          In Progress
        </Layout>
      );
    }
    case 'Committed': {
      return (
        <Layout>
          <StatusPillLockIcon />
          Approved and Locked
        </Layout>
      );
    }
    case 'Published': {
      return (
        <Layout>
          <StatusPillLockIcon />
          Published
        </Layout>
      );
    }
    case 'errors': {
      return (
        <Layout>
          <WarningIconSC />
          Errors
        </Layout>
      );
    }
    case 'graded':
    case 'GRADED': {
      return (
        <Layout>
          <StatusPillLockIcon />
          Graded
        </Layout>
      );
    }
    case 'grading':
    case 'GRADING_IN_PROGRESS': {
      return (
        <Layout>
          <StatusPillLockIcon />
          Grading In Progress
        </Layout>
      );
    }
    case 'complete': {
      return (
        <Layout>
          <StatusPillLockIcon />
          Graded
        </Layout>
      );
    }
    case 'not_graded':
    case 'NOT_GRADED': {
      return (
        <Layout>
          <StatusPillUnlockIcon />
          Not Graded
        </Layout>
      );
    }
    default: {
      return (
        <Layout>
          <StatusPillUnlockIcon />
          In Progress
        </Layout>
      );
    }
  }
};

StatusPill.propTypes = {
  status: PropTypes.string
};

StatusPill.defaultProps = {
  status: undefined
};

export { StatusPill, StatusCommitPill, StatusGradePill, PartStatusPill };
