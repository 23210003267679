import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import ByScopeCognitiveSourceContainer from './ByScopeCognitiveTranscriptsContainer';
import SubjectCreditsProgram from '../../TranscriptCalculations/SubjectCreditsProgram';
import SubjectCreditsMilestone from '../../TranscriptCalculations/SubjectCreditsMilestone';
import SubjectAvgScore from '../../TranscriptCalculations/SubjectAvgScore';
import SubjectGrade from '../../TranscriptCalculations/SubjectGrade';
import RankSubject from '../../TranscriptCalculations/RankSubject';

const Accordion = styled(({ ...rest }) => (
  <AccordionRowContainer {...rest} />
))``;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.grey[500]};
  font-size: 14px;
`;

const Calculations = styled.div`
  display: flex;
  font-size: 12px;
`;

const Spacing = styled.div`
  width: 120px;
`;

const Stub = styled.div`
  width: 66px;
`;

export default function ByScopeCognitiveSubject({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  subject,
  classificationUuid
}) {
  return (
    <>
      <Accordion
        expandWidth="66px"
        height="70px"
        label={
          <Group>
            <Label>{`${subject}`}</Label>
          </Group>
        }
        columnOneClassName="column_one"
        columnOne={
          <Calculations>
            <Spacing>
              <RankSubject
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                classificationUuid={classificationUuid}
              />
            </Spacing>
            <Spacing>
              <SubjectCreditsMilestone
                programUuid={programUuid}
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                category={category}
                classificationUuid={classificationUuid}
              />
              <SubjectCreditsProgram
                programUuid={programUuid}
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                category={category}
                classificationUuid={classificationUuid}
              />
            </Spacing>
            <Spacing>
              <SubjectGrade
                programUuid={programUuid}
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                category={category}
                classificationUuid={classificationUuid}
              />
            </Spacing>
            <Spacing>
              <SubjectAvgScore
                programUuid={programUuid}
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                category={category}
                classificationUuid={classificationUuid}
              />
            </Spacing>
            <Stub>&nbsp;</Stub>
          </Calculations>
        }
      >
        <ByScopeCognitiveSourceContainer
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          classificationUuid={classificationUuid}
        />
      </Accordion>
    </>
  );
}

ByScopeCognitiveSubject.propTypes = {
  subject: PropTypes.string,
  category: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  classificationUuid: PropTypes.string
};

ByScopeCognitiveSubject.defaultProps = {
  subject: undefined,
  category: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  classificationUuid: undefined
};
