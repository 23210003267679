import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import moment from 'moment';
import _ from 'lodash';
import { orm } from '../models';
import { roles, rolesMap } from '../../helpers/constants';
import {
  formatLastName,
  formatFirstNameMiddleName
} from '../../helpers/format/user.format';

export const userRoleSelectorBySchool = createSelector(
  state => state,
  (_, schoolUuid) => schoolUuid,
  (redux, schoolUuid) => {
    const userSchoolsSelectorORM = ormCreateSelector(
      orm.UserSchool,
      orm.UserSchool.user,
      (userschools, user) => {
        const addUser = userschools.map((userSchool, index) => {
          let endDate;
          if (userSchool.endDate === null && userSchool.status === 'active') {
            endDate = 'Present';
          } else if (userSchool.endDate !== null) {
            endDate = moment(userSchool.endDate).format('LL');
          } else {
            endDate = '';
          }

          let startDate;

          if (userSchool.startDate === null) {
            startDate = '';
          } else {
            startDate = moment(userSchool.startDate).format('LL');
          }

          const role = rolesMap[userSchool.role];

          return {
            ...userSchool,
            startDate,
            endDate,
            role,
            user: {
              ...user[index]
            }
          };
        });

        return addUser;
      }
    );

    const userSchools = userSchoolsSelectorORM(redux).filter(
      userSchool =>
        userSchool.schoolUuid === schoolUuid &&
        userSchool.role === 'School Admin'
    );

    return userSchools;
  }
);

export const userRoleSelectorByDepartment = createSelector(
  state => state,
  (_, props) => props.match.params.departmentUuid,
  (redux, departmentUuid) => {
    const userDepartmentsSelectorORM = ormCreateSelector([orm], session => {
      const userDepartmentRole = session.UserDepartment.all()
        .filter(
          userDepartment =>
            (userDepartment.departmentUuid === departmentUuid &&
              userDepartment.role === roles.departmentFaculty) ||
            (userDepartment.departmentUuid === departmentUuid &&
              roles.departmentStaff)
        )
        .toModelArray()
        .map(userDepartment => {
          const { ref } = userDepartment;

          const user = session.User.withId(ref.userUuid);
          const userReference = user ? user.ref : undefined;

          if (user) {
            const userFullName = {
              fullName: `${userReference.firstName} ${userReference.lastName}`
            };

            let endDate;
            if (ref.endDate === null && ref.status === 'Active') {
              endDate = 'Present';
            } else if (ref.endDate !== null) {
              endDate = moment(ref.endDate).format('LL');
            } else {
              endDate = '';
            }

            let startDate;
            if (ref.startDate === null) {
              startDate = '';
            } else {
              startDate = moment(ref.startDate).format('LL');
            }

            const role = rolesMap[ref.role];

            return {
              ...ref,
              startDate,
              endDate,
              role,
              user: {
                ...userFullName,
                institutionId: userReference.institutionId
              }
            };
          } else {
            return {};
          }
        });
      return userDepartmentRole;
    });

    const userDepartments = userDepartmentsSelectorORM(redux);
    return userDepartments;
  }
);

export const userRoleSelectorByProgram = createSelector(
  state => state,
  (state, props) => props.match.params.programUuid,
  (redux, programUuid) => {
    const userProgramsSelectorORM = ormCreateSelector([orm], session => {
      const userProgramRole = session.UserProgram.all()
        .filter(
          userProgram =>
            userProgram.programUuid === programUuid &&
            userProgram.role === roles.programAdmin
        )
        .toModelArray()
        .map(userProgram => {
          const { ref } = userProgram;

          const user = session.User.withId(ref.userUuid);
          const userReference = user ? user.ref : undefined;

          if (user) {
            const userFullName = {
              fullName: `${userReference.firstName} ${userReference.lastName}`
            };

            let endDate;
            if (ref.endDate === null && ref.status === 'Active') {
              endDate = 'Present';
            } else if (ref.endDate !== null) {
              endDate = moment(ref.endDate).format('LL');
            } else {
              endDate = '';
            }

            let startDate;
            if (ref.startDate === null) {
              startDate = '';
            } else {
              startDate = moment(ref.startDate).format('LL');
            }

            const role = rolesMap[ref.role];

            return {
              ...ref,
              startDate,
              endDate,
              role,
              user: {
                ...userFullName,
                institutionId: userReference.institutionId
              }
            };
          } else {
            return {};
          }
        });

      return userProgramRole;
    });

    return userProgramsSelectorORM(redux);
  }
);

export const userApplicantSelectorByCohort = createSelector(
  state => state,
  (_, cohortUuid) => cohortUuid,
  (redux, cohortUuid) => {
    const userCohortsSelectorORM = ormCreateSelector(
      orm.UserCohort,
      orm.UserCohort.user,
      (usercohorts, user) => {
        const addUser = usercohorts.map((userCohort, index) => {
          let endDate;
          if (userCohort.endDate === null && userCohort.status === 'Active') {
            endDate = 'Present';
          } else if (userCohort.endDate !== null) {
            endDate = moment(userCohort.endDate).format('LL');
          } else {
            endDate = '';
          }

          let startDate;
          if (userCohort.startDate === null) {
            startDate = '';
          } else {
            startDate = moment(userCohort.startDate).format('LL');
          }

          const updated = {
            ...userCohort,
            startDate,
            endDate,

            user: {
              ...user[index]
            }
          };

          return updated;
        });

        return addUser;
      }
    );

    const userCohorts = userCohortsSelectorORM(redux).filter(
      userCohort =>
        userCohort.cohortUuid === cohortUuid &&
        userCohort.role === roles.applicant
    );

    const formatName = userCohorts.map(uc => {
      const formattedName = `${formatLastName(
        uc?.user?.lastName,
        uc?.user?.marriedLastName,
        uc?.user?.suffix
      )},
      ${formatFirstNameMiddleName(uc?.user?.firstName, uc?.user?.middleName)}`;

      return { ...uc, formattedName };
    });

    const sorted = _.sortBy(formatName, [
      'user.lastName',
      'user.suffix',
      'user.firstName'
    ]);

    return sorted;
  }
);

export const userStudentSelectorByCohort = createSelector(
  state => state,
  (state, cohortUuid) => cohortUuid,
  (redux, cohortUuid) => {
    const userCohorsSelectorORM = ormCreateSelector([orm], session => {
      const userCohortRole = session.UserCohort.all()
        .filter(
          userCohort =>
            userCohort.cohortUuid === cohortUuid &&
            userCohort.role === roles.student
        )
        .toModelArray()
        .map(userCohort => {
          const { ref } = userCohort;

          const user = session.User.withId(ref.userUuid);
          const userReference = user ? user.ref : undefined;

          if (user) {
            let endDate;
            if (ref.endDate === null && ref.status === 'Active') {
              endDate = 'Present';
            } else if (ref.endDate !== null) {
              endDate = moment(ref.endDate).format('LL');
            } else {
              endDate = '';
            }

            let startDate;
            if (ref.startDate === null) {
              startDate = '';
            } else {
              startDate = moment(ref.startDate).format('LL');
            }

            return {
              ...ref,
              startDate,
              endDate,
              firstName: userReference.firstName,
              lastName: userReference.lastName,
              marriedLastName: userReference.marriedLastName,
              middleName: userReference.middleName,
              suffix: userReference.suffix,
              institutionId: userReference.institutionId
            };
          } else {
            return {};
          }
        });

      const userCohortRolesSorted = _.sortBy(userCohortRole, [
        'lastName',
        'suffix',
        'firstName'
      ]);

      return userCohortRolesSorted;
    });

    const userCohorts = userCohorsSelectorORM(redux);

    return userCohorts;
  }
);

export const selectAdminRoles = createSelector(
  state => state,
  (_, userUuid) => userUuid,
  (redux, userUuid) => {
    const userInstitution = ormCreateSelector(orm.UserInstitution);

    const userInstitutions = userInstitution(redux).filter(
      tc => tc.userUuid === userUuid && tc.status === 'active'
    );

    const userSchool = ormCreateSelector(orm.UserSchool);

    const userSchools = userSchool(redux).filter(
      tc => tc.userUuid === userUuid && tc.status === 'active'
    );

    const userDepartment = ormCreateSelector(orm.UserDepartment);

    const userDepartments = userDepartment(redux).filter(
      tc => tc.userUuid === userUuid && tc.status === 'active'
    );

    const userProgram = ormCreateSelector(orm.UserProgram);

    const userPrograms = userProgram(redux).filter(
      tc => tc.userUuid === userUuid && tc.status === 'active'
    );

    return [
      ...userInstitutions,
      ...userSchools,
      ...userDepartments,
      ...userPrograms
    ];
  }
);

export const selectStudentRoles = createSelector(
  state => state,
  (_, userUuid) => userUuid,
  (redux, userUuid) => {
    const userCohort = ormCreateSelector(orm.UserCohort);

    const userCohorts = userCohort(redux).filter(
      tc =>
        tc.userUuid === userUuid &&
        tc.status === 'active' &&
        tc.role === 'student'
    );

    return userCohorts;
  }
);
