import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonInline, Heading3 } from '@xcomp/xcomp-design-library';
import SimpleModal from '../SimpleModal';

const ModalBody = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;

  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const DeleteModal = ({
  modalOpen,
  type,
  className,
  handleModalClose,
  handleDelete,
  warningMessage,
  customHeading,
  verb
}) => (
  <div className={className}>
    <SimpleModal open={modalOpen} onClose={handleModalClose}>
      <ModalBody>
        <div>
          <Heading3>
            {customHeading ??
              `Are you sure you want to delete this ${type.toLowerCase()}?`}
          </Heading3>
          <em>{warningMessage ?? 'This cannot be reversed.'}</em>
        </div>
        <Actions>
          <ButtonInline onClick={() => handleModalClose()}>Cancel</ButtonInline>
          <ButtonInline onClick={() => handleDelete()}>
            {verb ?? 'Delete'} {type}
          </ButtonInline>
        </Actions>
      </ModalBody>
    </SimpleModal>
  </div>
);

DeleteModal.propTypes = {
  modalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  customHeading: PropTypes.string || PropTypes.element,
  warningMessage: PropTypes.string,
  verb: PropTypes.string
};

DeleteModal.defaultProps = {
  modalOpen: false,
  handleModalClose: undefined,
  handleDelete: undefined,
  type: '',
  className: '',
  customHeading: null,
  warningMessage: '',
  verb: null
};

export default DeleteModal;
