import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import StatusGradingModal from './StatusGradingModal';

import { doStatusCommitModalReset } from '../../../../../../../redux/actions/assessmentActions';

const StatusGradingModalContainer = ({
  statusGrade,
  isModalOpen,
  handleModalClose,
  handleModalSuccess,
  handleSubmit
}) => {
  const dispatch = useDispatch();

  const sucessState = useSelector(state => state.gradingStatusState);

  useEffect(() => {
    if (!isModalOpen) {
      dispatch(doStatusCommitModalReset());
    }
  }, [dispatch, isModalOpen]);

  return (
    <StatusGradingModal
      statusGrade={statusGrade}
      sucessState={sucessState}
      isModalOpen={isModalOpen}
      handleModalClose={handleModalClose}
      handleModalSuccess={handleModalSuccess}
      handleSubmit={handleSubmit}
    />
  );
};

StatusGradingModalContainer.propTypes = {
  statusGrade: PropTypes.string,
  isModalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleModalSuccess: PropTypes.func,
  handleSubmit: PropTypes.func
};
StatusGradingModalContainer.defaultProps = {
  statusGrade: 'NOT_GRADED',
  isModalOpen: false,
  handleModalClose: undefined,
  handleModalSuccess: undefined,
  handleSubmit: undefined
};

export default StatusGradingModalContainer;
