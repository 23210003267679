import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectCognitiveMilestoneGradeByAttempt } from '../../../../redux/selectors/transcriptCognitiveSelectors';

export default function CognitiveMilestoneAttemptGrade({
  programUuid,
  userUuid,
  cohortUuid,
  category,
  source,
  attempt
}) {
  const milestoneAvgGradeByAttempt = useSelector(state =>
    selectCognitiveMilestoneGradeByAttempt(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      attempt
    )
  );

  const grade = milestoneAvgGradeByAttempt || 'N/A';

  return <>{grade}</>;
}

CognitiveMilestoneAttemptGrade.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  attempt: PropTypes.number
};

CognitiveMilestoneAttemptGrade.defaultProps = {
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  attempt: 0
};
