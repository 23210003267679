import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { optionUserStatus, optionsRolesAll } from '../../../../helpers/options';
import FormField from '../../../Library/FormField';
import FormSelectError from '../../../Library/FormSelectError';
import Date from '../../../Library/Date';

import {
  validateInputString,
  validateSelectDate,
  validateSelectField
} from '../../../../helpers/validation/validateGeneric';

import { selectProgramsBySchool } from '../../../../redux/selectors/programSelectors';

export default function UserProgramAdmin({
  uuid,
  status,
  startDate,
  endDate,
  role,
  email,
  phone,
  programUuid,
  schoolUuid,
  optionsSchool,
  hasErrors,
  handleRole,
  handleRoleDate,
  handleRoleRemove
}) {
  const programs = useSelector(state =>
    selectProgramsBySchool(state, schoolUuid)
  );

  const optionsProgram = _.map(programs, program => ({
    label: program.programName,
    value: program.uuid
  }));

  return (
    <>
      <Grid item sm={12} xs={12}>
        <Grid container direction="row" justifyContent="flex-end">
          <IconButton onClick={() => handleRoleRemove(uuid, 'program_admin')}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item sm={3} xs={12}>
        <FormSelectError
          name="role"
          label="Role"
          value={role}
          options={optionsRolesAll}
          hasErrors={hasErrors}
          disabled={true}
        />
      </Grid>
      <Grid item sm={3} xs={12}>
        <FormSelectError
          name="status"
          label="Status*"
          value={status}
          handleChange={event => handleRole(event, uuid, 'program_admin')}
          handleValidation={validateInputString}
          options={optionUserStatus}
          hasErrors={hasErrors}
        />
      </Grid>
      <Grid item sm={3} xs={12}>
        <Date
          name="startDate"
          label="Start Date*"
          value={startDate}
          indentifier={uuid}
          hasErrors={hasErrors}
          handleValidate={validateSelectDate}
          handleChange={(indentifier, field, date) =>
            handleRoleDate(indentifier, field, date, 'program_admin')
          }
        />
      </Grid>
      <Grid item sm={3} xs={12}>
        <Date
          name="endDate"
          label="End Date"
          value={endDate}
          indentifier={uuid}
          handleChange={(indentifier, field, date) =>
            handleRoleDate(indentifier, field, date, 'program_admin')
          }
          placeholderText={status === 'active' ? 'Present' : null}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <FormSelectError
          name="schoolUuid"
          label="School"
          value={schoolUuid}
          handleChange={event => {
            handleRole(event, uuid, 'program_admin');

            const clearDepartment = {
              target: { name: 'programUuid', value: undefined }
            };
            handleRole(clearDepartment, uuid, 'program_admin');
          }}
          handleValidation={validateSelectField}
          options={optionsSchool}
          hasErrors={hasErrors}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <FormSelectError
          name="programUuid"
          label="Program*"
          value={programUuid}
          handleChange={event => handleRole(event, uuid, 'program_admin')}
          handleValidation={validateSelectField}
          options={optionsProgram}
          hasErrors={hasErrors}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormField
          name="email"
          label="Email*"
          value={email}
          onBlur={event => handleRole(event, uuid, 'program_admin')}
          handleValidate={validateInputString}
          hasErrors={hasErrors}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormField
          name="phone"
          label="Phone*"
          value={phone}
          onBlur={event => handleRole(event, uuid, 'program_admin')}
          margin="normal"
          handleValidate={validateInputString}
          hasErrors={hasErrors}
        />
      </Grid>
    </>
  );
}

UserProgramAdmin.propTypes = {
  uuid: PropTypes.string,
  status: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.object,
    PropTypes.string
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.object,
    PropTypes.string
  ]),
  schoolUuid: PropTypes.string,
  optionsSchool: PropTypes.array,
  role: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  programUuid: PropTypes.string,
  hasErrors: PropTypes.bool,
  handleRole: PropTypes.func,
  handleRoleDate: PropTypes.func,
  handleRoleRemove: PropTypes.func
};

UserProgramAdmin.defaultProps = {
  uuid: undefined,
  status: undefined,
  startDate: null,
  endDate: null,
  role: undefined,
  email: undefined,
  phone: undefined,
  programUuid: undefined,
  hasErrors: undefined,
  handleRole: undefined,
  handleRoleDate: undefined,
  handleRoleRemove: undefined,
  schoolUuid: undefined,
  optionsSchool: []
};
