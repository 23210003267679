import React from 'react';
import PropTypes from 'prop-types';
import SchoolDefinitions from './SchoolDefinitions';

export default function SchoolDefinitionsContainer({ match, history }) {
  const { path } = match;
  const { pathname } = history.location;

  return <SchoolDefinitions path={path} pathname={pathname} />;
}

SchoolDefinitionsContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

SchoolDefinitionsContainer.defaultProps = {
  history: {},
  match: {}
};
