import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import { ElevateLow } from '../../../../Library/Layout';

const Layout = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 20px;
  margin-bottom: 25px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const GridSC = styled(Grid)`
  margin-top: 20px;
`;

const Cancel = styled(ButtonInline)`
  && {
    margin-right: 10px;
    color: ${props => props.theme.fontColors.dark};
  }
`;

const LetterGradeEdit = ({
  uuid,
  grade,
  rangeLow,
  rangeHigh,
  conversion,
  creditMultiplier,
  handleShowMode,
  handleChange,
  handleSave
}) => {
  return (
    <ElevateLow>
      <Layout>
        <GridSC>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={4}>
              <TextField
                name="grade"
                label="Grade"
                variant="outlined"
                value={grade}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="rangeLow"
                label="Range Low"
                variant="outlined"
                value={rangeLow}
                onChange={handleChange}
                type="number"
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="rangeHigh"
                label="Range High"
                variant="outlined"
                onChange={handleChange}
                value={rangeHigh}
                type="number"
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={4}>
              <TextField
                name="conversion"
                label="Conversion"
                variant="outlined"
                value={conversion}
                onChange={handleChange}
                type="number"
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="creditMultiplier"
                label="Credit Multiplier"
                variant="outlined"
                value={creditMultiplier}
                onChange={handleChange}
                type="number"
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
          </Grid>
        </GridSC>
        <Actions>
          <Cancel onClick={() => handleShowMode(uuid)}>CANCEL</Cancel>
          <ButtonInline onClick={handleSave}>SAVE</ButtonInline>
        </Actions>
      </Layout>
    </ElevateLow>
  );
};

LetterGradeEdit.propTypes = {
  uuid: PropTypes.string,
  grade: PropTypes.string,
  rangeLow: PropTypes.string,
  rangeHigh: PropTypes.string,
  conversion: PropTypes.string,
  creditMultiplier: PropTypes.string,
  handleShowMode: PropTypes.func,
  handleChange: PropTypes.func,
  handleSave: PropTypes.func
};
LetterGradeEdit.defaultProps = {
  uuid: '',
  grade: '',
  rangeLow: '0.0',
  rangeHigh: '0.0',
  conversion: '0.0',
  creditMultiplier: '0.0',
  handleShowMode: undefined,
  handleChange: undefined,
  handleSave: undefined
};

export default LetterGradeEdit;
