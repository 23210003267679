import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Paper, TableContainer } from '@material-ui/core';

import { fetcher } from '../../../../helpers/utilities';
import QuantitativeSkillsTable from './components/QuantitativeSkillsTable';

const Layout = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
`;

const QuantitativeSkills = ({
  programUuid,
  userUuid,
  cohortUuid,
  viewDefault
}) => {
  const urlParams = new URLSearchParams({
    programUuid,
    userUuid,
    cohortUuid,
    fetchDefaultCollections: viewDefault
    // Add as many parameters as needed
  });

  const { data, error, isLoading } = useSWR(
    `/api/cohort/quantitative-skills-transcript/student-transcript?${urlParams.toString()}`,
    fetcher,
    {
      revalidateOnFocus: false
    }
  );

  if (isLoading) {
    return (
      <Layout>
        <LoadingContainer>
          <LinearProgress />
        </LoadingContainer>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout style={{ flexDirection: 'column' }}>
        <LoadingContainer>
          <p>There was an error loading the data.</p>
        </LoadingContainer>
      </Layout>
    );
  }

  return (
    <TableContainer component={Paper}>
      <QuantitativeSkillsTable
        data={data.scores}
        totals={data.allCollections}
      />
    </TableContainer>
  );
};

QuantitativeSkills.propTypes = {
  programUuid: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  viewDefault: PropTypes.bool
};

QuantitativeSkills.defaultProps = {
  programUuid: '',
  userUuid: '',
  cohortUuid: '',
  viewDefault: false
};

export default QuantitativeSkills;
