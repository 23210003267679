import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import { orm } from '../models';
import { userSectionStatus } from '../../helpers/constants';
import { selectTermCredits } from './creditsSelectors';
import { selectCollectionCredits } from './courseCollectionsSelectors';

export const selectCollectionGPA = createSelector(
  state => state,
  (_, userUuid, courseCollectionUuid) => ({
    userUuid,
    courseCollectionUuid
  }),
  (redux, paramaters) => {
    const { userUuid, courseCollectionUuid } = paramaters;

    const getCourseMaster = ormCreateSelector(orm.CourseMaster);
    const getCalculatedGradeResult = ormCreateSelector(
      orm.CalculatedGradeResult
    );
    const getLetterGrades = ormCreateSelector(orm.LetterGrade);
    const getCourseCollectionCourse = ormCreateSelector(
      orm.CourseCollectionCourse
    );
    const getUserSection = ormCreateSelector(orm.UserSection);
    const getSection = ormCreateSelector(orm.Section);
    const getCourse = ormCreateSelector(orm.Course);
    const getDepartment = ormCreateSelector(orm.Department);
    const getSchool = ormCreateSelector(orm.School);

    if (userUuid) {
      const courseCollectionCourse = getCourseCollectionCourse(redux).filter(
        cp => cp.courseCollectionUuid === courseCollectionUuid
      );

      const courseMastersUuids = courseCollectionCourse.map(
        ccc => ccc.courseMasterUuid
      );

      const userSections = getUserSection(redux).filter(
        us =>
          us.userUuid === userUuid &&
          (us.status === userSectionStatus.pass ||
            us.status === userSectionStatus.fail)
      );

      const userSectionsResults = userSections.map(userSection => {
        const section = getSection(redux, userSection.sectionUuid);
        const course = getCourse(redux, section.courseUuid);
        const courseMaster = getCourseMaster(redux, course.courseMasterUuid);
        const department = getDepartment(redux, courseMaster.departmentUuid);
        const school = getSchool(redux, department.schoolUuid);
        const calculatedGradeResult = getCalculatedGradeResult(redux).find(
          gradeResult =>
            gradeResult.studentUuid === userUuid &&
            gradeResult.sectionUuid === section.uuid &&
            gradeResult.partUuid === null
        ) || { calculatedGrade: '0.0' };

        return {
          school,
          userSection,
          course,
          section,
          courseMaster,
          calculatedGradeResult
        };
      });

      const gradePointResults = _(userSectionsResults)
        .filter(record =>
          _.includes(courseMastersUuids, record.courseMaster.uuid)
        )
        .map(record => {
          const letterGrades = getLetterGrades(redux).filter(
            letterGrade => letterGrade?.schoolUuid === record?.school?.uuid
          );

          const mapGrades = _(letterGrades)
            .map(record => ({
              ...record,
              rangeLow: Number(record.rangeLow),
              rangeHigh: Number(record.rangeHigh)
            }))
            .value();

          const findGrade = _.find(mapGrades, schoolGrade => {
            const calculatedGrade = _.get(record, [
              'calculatedGradeResult',
              'calculatedGrade'
            ]);

            if (calculatedGrade) {
              const low = new Decimal(schoolGrade.rangeLow);
              const high = new Decimal(schoolGrade.rangeHigh);
              const calculatedGradeDecimal = new Decimal(calculatedGrade);
              const conditionOne =
                calculatedGradeDecimal.greaterThanOrEqualTo(low);
              const conditionTwo =
                calculatedGradeDecimal.lessThanOrEqualTo(high);

              return conditionOne && conditionTwo;
            } else {
              return false;
            }
          });

          const creditMultiplier = new Decimal(findGrade?.creditMultiplier);
          const credits = new Decimal(record?.courseMaster.hours);

          const gradePoint = creditMultiplier
            .times(credits)
            .toFixed(2)
            .toString();

          return { credits, gradePoint };
        })
        .value();

      const gradePointTotal = _.reduce(
        gradePointResults,
        (total, gradePointResult) => {
          const gradePointDecimal = new Decimal(gradePointResult?.gradePoint);
          return total.add(gradePointDecimal);
        },
        new Decimal(0)
      );

      const creditsTotal = selectCollectionCredits(
        redux,
        userUuid,
        courseCollectionUuid
      );

      const gpa = gradePointTotal
        .div(new Decimal(creditsTotal))
        .toFixed(2)
        .toString();

      return gpa;
    }
  }
);

export const selectOverallGPA = createSelector(
  state => state,
  (_, userUuid) => ({
    userUuid
  }),
  (redux, paramaters) => {
    const { userUuid } = paramaters;

    const getCourseMaster = ormCreateSelector(orm.CourseMaster);
    const getCalculatedGradeResult = ormCreateSelector(
      orm.CalculatedGradeResult
    );
    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const getUserSection = ormCreateSelector(orm.UserSection);
    const getSection = ormCreateSelector(orm.Section);
    const getCourse = ormCreateSelector(orm.Course);
    const getDepartment = ormCreateSelector(orm.Department);
    const getSchool = ormCreateSelector(orm.School);

    if (userUuid) {
      const userSections = getUserSection(redux).filter(
        us =>
          us.userUuid === userUuid &&
          (us.status === userSectionStatus.pass ||
            us.status === userSectionStatus.pass_with_remediation ||
            us.status === userSectionStatus.fail ||
            us.status === userSectionStatus.fail_with_remediation)
      );

      const userSectionsResults = userSections.map(userSection => {
        const section = getSection(redux, userSection.sectionUuid);
        const course = getCourse(redux, section.courseUuid);

        const courseMaster = getCourseMaster(redux, course.courseMasterUuid);
        const department = getDepartment(redux, courseMaster.departmentUuid);
        const school = getSchool(redux, department.schoolUuid);
        const calculatedGradeResult = getCalculatedGradeResult(redux).find(
          gradeResult =>
            gradeResult.studentUuid === userUuid &&
            gradeResult.sectionUuid === section.uuid &&
            gradeResult.partUuid === null
        ) || { calculatedGrade: '0.0' };

        return {
          school,
          courseMaster,
          userSection,
          calculatedGradeResult
        };
      });

      const gradePointResults = _(userSectionsResults)
        .map(record => {
          const letterGrades = getLetterGrades(redux).filter(
            letterGrade => letterGrade?.schoolUuid === record?.school?.uuid
          );

          const mapGrades = _(letterGrades)
            .map(record => ({
              ...record,
              rangeLow: Number(record.rangeLow),
              rangeHigh: Number(record.rangeHigh)
            }))
            .value();

          const findGrade = _.find(mapGrades, schoolGrade => {
            const calculatedGrade = _.get(record, [
              'calculatedGradeResult',
              'calculatedGrade'
            ]);

            if (calculatedGrade) {
              const low = new Decimal(schoolGrade.rangeLow);
              const high = new Decimal(schoolGrade.rangeHigh);
              const calculatedGradeDecimal = new Decimal(calculatedGrade);
              const conditionOne =
                calculatedGradeDecimal.greaterThanOrEqualTo(low);
              const conditionTwo =
                calculatedGradeDecimal.lessThanOrEqualTo(high);

              return conditionOne && conditionTwo;
            } else {
              return false;
            }
          });

          const creditMultiplier = new Decimal(findGrade?.creditMultiplier || '0');
          const credits = new Decimal(record?.courseMaster.hours);
          const gradePoint = creditMultiplier
            .times(credits)
            .toFixed(2)
            .toString();

          return { credit: record?.courseMaster?.hours, gradePoint };
        })
        .value();

      const creditsTotal = _.reduce(
        gradePointResults,
        (total, record) => {
          const creditDecimal = new Decimal(record?.credit);
          return total.add(creditDecimal);
        },
        new Decimal(0)
      );

      const gradePointTotal = _.reduce(
        gradePointResults,
        (total, gradePointResult) => {
          const gradePointDecimal = new Decimal(gradePointResult?.gradePoint);
          return total.add(gradePointDecimal);
        },
        new Decimal(0)
      );

      const gpa = gradePointTotal.div(creditsTotal).toFixed(2).toString();

      return gpa;
    }
  }
);

export const selectTermGPA = createSelector(
  state => state,
  (_, userUuid, termUuid, terms) => ({
    userUuid,
    termUuid,
    terms
  }),
  (redux, paramaters) => {
    const { userUuid, termUuid, terms } = paramaters;

    const getCourseMaster = ormCreateSelector(orm.CourseMaster);
    const getCalculatedGradeResult = ormCreateSelector(
      orm.CalculatedGradeResult
    );
    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const getUserSection = ormCreateSelector(orm.UserSection);
    const getSection = ormCreateSelector(orm.Section);
    const getCourse = ormCreateSelector(orm.Course);
    const getTerm = ormCreateSelector(orm.Term);
    const getDepartment = ormCreateSelector(orm.Department);
    const getSchool = ormCreateSelector(orm.School);

    if (userUuid) {
      const userSections = getUserSection(redux).filter(
        us =>
          us.userUuid === userUuid &&
          (us.status === userSectionStatus.pass ||
            us.status === userSectionStatus.fail)
      );

      const userSectionsResults = userSections.map(userSection => {
        const section = getSection(redux, userSection.sectionUuid);
        const course = getCourse(redux, section.courseUuid);
        const courseMaster = getCourseMaster(redux, course.courseMasterUuid);
        const department = getDepartment(redux, courseMaster.departmentUuid);
        const school = getSchool(redux, department.schoolUuid);
        const term = getTerm(redux, course.termUuid);
        const calculatedGradeResult = getCalculatedGradeResult(redux).find(
          gradeResult =>
            gradeResult.studentUuid === userUuid &&
            gradeResult.sectionUuid === section.uuid &&
            gradeResult.partUuid === null
        ) || { calculatedGrade: '0.0' };

        return {
          term,
          school,
          courseMaster,
          calculatedGradeResult
        };
      });

      const gradePointResultsAtTerm = _(userSectionsResults)
        .filter(record => record.term.uuid === termUuid)
        .map(record => {
          const letterGrades = getLetterGrades(redux).filter(
            letterGrade => letterGrade.schoolUuid === record.school.uuid
          );

          const mapGrades = _(letterGrades)
            .map(record => ({
              ...record,
              rangeLow: Number(record.rangeLow),
              rangeHigh: Number(record.rangeHigh)
            }))
            .value();

          const findGrade = _.find(mapGrades, schoolGrade => {
            const calculatedGrade = _.get(record, [
              'calculatedGradeResult',
              'calculatedGrade'
            ]);

            if (calculatedGrade) {
              const low = new Decimal(schoolGrade.rangeLow);
              const high = new Decimal(schoolGrade.rangeHigh);
              const calculatedGradeDecimal = new Decimal(calculatedGrade);
              const conditionOne =
                calculatedGradeDecimal.greaterThanOrEqualTo(low);
              const conditionTwo =
                calculatedGradeDecimal.lessThanOrEqualTo(high);

              return conditionOne && conditionTwo;
            } else {
              return false;
            }
          });

          const creditMultiplier = new Decimal(findGrade?.creditMultiplier);
          const credits = new Decimal(record?.courseMaster.hours);
          const gradePoint = creditMultiplier
            .times(credits)
            .toFixed(2)
            .toString();

          return { credit: record?.courseMaster?.hours, gradePoint };
        })
        .value();

      const gradePointTotalAtTerm = _.reduce(
        gradePointResultsAtTerm,
        (total, gradePointResult) => {
          const gradePointDecimal = new Decimal(gradePointResult?.gradePoint);
          return total.add(gradePointDecimal);
        },
        new Decimal(0)
      );

      const gradePointResultsToTerm = _(userSectionsResults)
        .filter(record => _.includes(terms, record.term.uuid))
        .map(record => {
          const letterGrades = getLetterGrades(redux).filter(
            letterGrade => letterGrade.schoolUuid === record.school.uuid
          );

          const mapGrades = _(letterGrades)
            .map(record => ({
              ...record,
              rangeLow: Number(record.rangeLow),
              rangeHigh: Number(record.rangeHigh)
            }))
            .value();

          const findGrade = _.find(mapGrades, schoolGrade => {
            const calculatedGrade = _.get(record, [
              'calculatedGradeResult',
              'calculatedGrade'
            ]);

            if (calculatedGrade) {
              const low = new Decimal(schoolGrade.rangeLow);
              const high = new Decimal(schoolGrade.rangeHigh);
              const calculatedGradeDecimal = new Decimal(calculatedGrade);
              const conditionOne =
                calculatedGradeDecimal.greaterThanOrEqualTo(low);
              const conditionTwo =
                calculatedGradeDecimal.lessThanOrEqualTo(high);

              return conditionOne && conditionTwo;
            } else {
              return false;
            }
          });

          const creditMultiplier = new Decimal(findGrade?.creditMultiplier);
          const credits = new Decimal(record?.courseMaster.hours);
          const gradePoint = creditMultiplier
            .times(credits)
            .toFixed(2)
            .toString();

          return { credit: record?.courseMaster?.hours, gradePoint };
        })
        .value();

      const gradePointTotalToTerm = _.reduce(
        gradePointResultsToTerm,
        (total, gradePointResult) => {
          const gradePointDecimal = new Decimal(gradePointResult?.gradePoint);
          return total.add(gradePointDecimal);
        },
        new Decimal(0)
      );

      const { credits_total_at_term, credits_total_to_term } =
        selectTermCredits(redux, userUuid, termUuid, terms);

      const gpa_at_term = gradePointTotalAtTerm
        .div(new Decimal(credits_total_at_term))
        .toFixed(2)
        .toString();

      const gpa_to_term = gradePointTotalToTerm
        .div(new Decimal(credits_total_to_term))
        .toFixed(2)
        .toString();

      return { gpa_at_term, gpa_to_term };
    }
  }
);
