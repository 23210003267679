const initialAssessmentStatusState = {
  uuid: '',
  statusCommit: 'Uncommit',
  statusScore: 'Close',
  justificationMessage: null,
  assessmentOpenDate: '',
  assessmentCloseDate: '',
  datePickerOpen: '',
  datePickerClose: '',
  openSpecificDate: false,
  closeSpecificDate: false,
  shouldOpenAssessmentStart: false,
  shouldCloseAssessmentEnd: false,
  shouldCloseAtCompletion: false,
  selectedRadioOpen: '',
  selectedRadioClose: '',
  showValidationErrors: false
};

const assessmentStatusReducer = (state, action) => {
  switch (action.type) {
    case 'setExistingAssessment': {
      const {
        uuid,
        statusCommit,
        statusScore,
        assessmentOpenDate,
        assessmentCloseDate,
        shouldOpenAssessmentStart,
        shouldCloseAssessmentEnd,
        shouldCloseAtCompletion
      } = action.payload;

      let options = {};

      // set open rules
      if (assessmentOpenDate === null) {
        options.selectedRadioOpen = shouldOpenAssessmentStart
          ? 'openAssessment'
          : 'closeIndefinite';
      } else {
        options.selectedRadioOpen = 'openSetDate';
        options.assessmentOpenDate = assessmentOpenDate;
        options.datePickerOpen = assessmentOpenDate;
        options.openSpecificDate = true;
      }

      // set close rules
      if (assessmentCloseDate === null) {
        if (!shouldCloseAssessmentEnd && !shouldCloseAtCompletion) {
          options.selectedRadioClose = 'openIndefinite';
        } else {
          options.selectedRadioClose = shouldCloseAssessmentEnd
            ? 'closeAssessment'
            : 'closeSubmission';
        }
      } else {
        options.selectedRadioClose = 'closeSetDate';
        options.assessmentCloseDate = assessmentCloseDate;
        options.datePickerClose = assessmentCloseDate;
        options.closeSpecificDate = true;
      }

      if (options.selectedRadioOpen === 'closeIndefinite') {
        options = {
          selectedRadioOpen: 'closeIndefinite',
          datePickerOpen: null,
          datePickerClose: assessmentCloseDate,
          openSpecificDate: false,
          closeSpecificDate: true,
          assessmentCloseDate,
          assessmentOpenDate: null,
          selectedRadioClose: 'closeSetDate'
        };
      }

      return {
        ...state,
        ...options,
        uuid,
        statusCommit,
        statusScore
      };
    }
    case 'setStatusCommitted': {
      return { ...state, statusCommit: 'Commit' };
    }
    case 'setStatusUncommitted':
      return { ...state, statusCommit: 'Uncommitted' };
    case 'setOpenDate':
      return {
        ...state,
        openSpecificDate: false,
        assessmentOpenDate: action.payload.assessmentOpenDate,
        datePickerOpen: '',
        selectedRadioOpen: action.payload.selectedRadioOpen
      };
    case 'setSpecificOpenDate':
      return {
        ...state,
        openSpecificDate: true,
        assessmentOpenDate: null,
        shouldOpenAssessmentStart: false,
        selectedRadioOpen: action.payload.selectedRadioOpen
      };
    case 'setCloseIndefinite':
      return {
        ...state,
        assessmentOpenDate: null,
        assessmentCloseDate: action.payload.assessmentCloseDate,
        datePickerOpen: '',
        datePickerClose: '',
        openSpecificDate: false,
        closeSpecificDate: false,
        shouldOpenAssessmentStart: false,
        shouldCloseAssessmentEnd: false,
        shouldCloseAtCompletion: false,
        selectedRadioOpen: action.payload.selectedRadioOpen,
        selectedRadioClose: ''
      };
    case 'setOpenIndefinite':
      return {
        ...state,
        assessmentOpenDate: action.payload.assessmentOpenDate,
        assessmentCloseDate: null,
        closeSpecificDate: false,
        datePickerClose: '',
        shouldCloseAssessmentEnd: false,
        shouldCloseAtCompletion: false,
        selectedRadioClose: action.payload.selectedRadioClose
      };
    case 'setOpenAssessmentStart':
      return {
        ...state,
        shouldOpenAssessmentStart: true,
        openSpecificDate: false,
        assessmentOpenDate: null,
        datePickerOpen: '',
        selectedRadioOpen: action.payload.selectedRadioOpen
      };
    case 'setCloseAssessmentEnd':
      return {
        ...state,
        shouldCloseAssessmentEnd: true,
        closeSpecificDate: false,
        assessmentCloseDate: null,
        shouldCloseAtCompletion: false,
        datePickerClose: '',
        selectedRadioClose: action.payload.selectedRadioClose
      };
    case 'setCloseSubmission':
      return {
        ...state,
        closeSpecificDate: false,
        assessmentCloseDate: null,
        shouldCloseAtCompletion: true,
        shouldCloseAssessmentEnd: false,
        datePickerClose: '',
        selectedRadioClose: action.payload.selectedRadioClose
      };
    case 'setDatePickerOpen':
      return {
        ...state,
        datePickerOpen: action.payload.date
      };
    case 'setDatePickerClose':
      return {
        ...state,
        datePickerClose: action.payload.date
      };
    case 'setSpecificCloseDate':
      return {
        ...state,
        closeSpecificDate: true,
        assessmentCloseDate: null,
        shouldCloseAssessmentEnd: false,
        shouldCloseAtCompletion: false,
        selectedRadioClose: action.payload.selectedRadioClose
      };
    case 'setJustificationMessage': {
      return {
        ...state,
        justificationMessage: action.payload.justificationMessage
      };
    }
    case 'resetJustification': {
      return {
        ...state,
        justificationMessage: ''
      };
    }
    // case 'setShowValidationErrors':
    //   return {
    //     ...state,
    //     showValidationErrors: action.payload.showValidationErrors
    //   };
    case 'clearForm':
      return initialAssessmentStatusState;
    default: {
      throw new Error();
    }
  }
};

// const setShowValidationErrors = showValidationErrors => ({
//   type: 'setShowValidationErrors',
//   payload: { showValidationErrors }
// });

export { initialAssessmentStatusState, assessmentStatusReducer };
