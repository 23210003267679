import fetch from '../helpers/fetch';

const getCourseMaster = courseMasterUuid =>
  fetch(`/api/course-master/${courseMasterUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCourseMasterByDepartment = departmentUuid =>
  fetch(
    `/api/course-masters/find-by-department?departmentUuid=${departmentUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postCourseMaster = course =>
  fetch('/api/course-master/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(course)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putCourseMaster = course =>
  fetch(`/api/course-master/${course.uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(course)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteCourseMaster = courseMasterUuid =>
  fetch(`/api/course-master/${courseMasterUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  getCourseMaster,
  getCourseMasterByDepartment,
  postCourseMaster,
  putCourseMaster,
  deleteCourseMaster
};
