import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import {
  cohortApplicantsInitialState,
  cohortApplicantsReducer
} from './cohortApplicants.state';

const CohortApplicantsStateContext = createContext(undefined);

const CohortApplicantsDispatchContext = createContext(undefined);

export const CohortApplicantsProvider = props => {
  const [cohortApplicantsState, cohortApplicantsDispatch] = useReducer(
    cohortApplicantsReducer,
    cohortApplicantsInitialState
  );

  return (
    <CohortApplicantsStateContext.Provider value={cohortApplicantsState}>
      <CohortApplicantsDispatchContext.Provider
        value={cohortApplicantsDispatch}
      >
        {props.children}
      </CohortApplicantsDispatchContext.Provider>
    </CohortApplicantsStateContext.Provider>
  );
};

CohortApplicantsProvider.propTypes = {
  children: PropTypes.node
};

CohortApplicantsProvider.defaultProps = {
  children: null
};

export const useCohortApplicantsState = () => {
  const state = useContext(CohortApplicantsStateContext);

  return state;
};

export const useCohortApplicantsDispatch = () => {
  const dispatch = useContext(CohortApplicantsDispatchContext);

  return dispatch;
};
