import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { classificationsSelectorByCategory } from '../../../../../redux/selectors/classificationSelectors';
import ByInstitutionSubject from './ByInstitutionSubject';

export default function ByInstitutionSubjectContainer({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  sourceInstitutionUuid
}) {
  const subjects = useSelector(state =>
    classificationsSelectorByCategory(state, programUuid, category)
  )?.map(classification => classification);

  return (
    <>
      {subjects.map(subject => (
        <ByInstitutionSubject
          key={subject.uuid}
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
          subject={subject.scope}
          classificationUuid={subject.uuid}
          sourceInstitutionUuid={sourceInstitutionUuid}
        />
      ))}
    </>
  );
}

ByInstitutionSubjectContainer.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string
};
ByInstitutionSubjectContainer.defaultProps = {
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  sourceInstitutionUuid: undefined
};
