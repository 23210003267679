import fetch from '../helpers/fetch';

const putInstitution = (institution, picture) => {
  const json = JSON.stringify(institution);
  const blob = new Blob([json], {
    type: 'application/json'
  });

  const formData = new FormData();

  if (picture) {
    formData.append('photo', picture);
  }

  formData.append('institution', blob);

  return fetch(`/api/institution/${institution.uuid}/update`, {
    method: 'PUT',
    credentials: 'include',
    body: formData
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export { putInstitution };
