import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ButtonInline } from '@xcomp/xcomp-design-library';
import Modal from '../../../../Library/Modal/Modal';

const ModalBodySC = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;
  h3 {
    margin-bottom: 0.5rem;
  }
`;

const ModalButtonsSC = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const EducationLevelDeleteModal = ({
  modalOpen,
  handleDeleteModalClose,
  handleDelete
}) => (
  <div>
    <Modal open={modalOpen} onClose={handleDeleteModalClose}>
      <ModalBodySC>
        <div>
          <h3>Are you sure you want to delete?</h3>
          <em>This cannot be reversed.</em>
        </div>
        <ModalButtonsSC>
          <ButtonInline onClick={() => handleDeleteModalClose()}>
            Cancel
          </ButtonInline>
          <Link to="/school-management/definitions/educationlevels">
            <ButtonInline onClick={() => handleDelete()}>Delete</ButtonInline>
          </Link>
        </ModalButtonsSC>
      </ModalBodySC>
    </Modal>
  </div>
);

EducationLevelDeleteModal.propTypes = {
  modalOpen: PropTypes.bool,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func
};

EducationLevelDeleteModal.defaultProps = {
  modalOpen: false,
  handleDeleteModalClose: undefined,
  handleDelete: undefined
};

export default EducationLevelDeleteModal;
