import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Scope from '../DataCells/Scope';
import { decimalTenth } from '../../../../../helpers/utilities';

const Spacing = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

const Layout = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 132px;
  align-items: center;
`;

const Record = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => (props.width ? props.width : '120px')};
`;

export default function CognitiveMilestoneTermRowRead({ transcript }) {
  return (
    <Layout>
      <Record>
        <Spacing>{transcript?.attempt}</Spacing>
      </Record>
      <Record>
        <Spacing>{transcript?.date}</Spacing>
      </Record>
      <Record>
        <Spacing>{transcript?.itemCode}</Spacing>
      </Record>
      <Record width="300px">
        <Spacing>{transcript?.itemName}</Spacing>
      </Record>
      <Record>
        <Spacing>
          <Scope classificationUuid={transcript?.classificationUuid} />
        </Spacing>
      </Record>
      <Record>
        <Spacing>{decimalTenth(transcript?.credits)}</Spacing>
      </Record>
      <Record>
        <Spacing>{transcript?.grade}</Spacing>
      </Record>
      <Record>
        <Spacing>{transcript?.score}</Spacing>
      </Record>
      <Record width="66px">&nbsp;</Record>
    </Layout>
  );
}

CognitiveMilestoneTermRowRead.propTypes = {
  transcript: PropTypes.object
};
CognitiveMilestoneTermRowRead.defaultProps = {
  transcript: {}
};
