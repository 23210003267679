import React from 'react';

import PropTypes from 'prop-types';

import ByInstitutionSource from './ByInstitutionSource';

export default function ByInstitutionSourceContainer({
  userUuid,
  cohortUuid,
  programUuid,
  category
}) {
  const sources = ['Program', 'Milestone'];

  return (
    <>
      {sources.map(source => (
        <ByInstitutionSource
          key={source}
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
        />
      ))}
    </>
  );
}

ByInstitutionSourceContainer.propTypes = {
  category: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string
};
ByInstitutionSourceContainer.defaultProps = {
  category: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined
};
