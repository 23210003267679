import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { selectTranscriptCognitive } from '../../../../../redux/selectors/transcriptCognitiveSelectors';

import ByDefaultCognitive from './ByDefaultCognitive';

export default function ByDefaultCognitiveContainer({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  isAdmin
}) {
  const transcripts = useSelector(
    state =>
      selectTranscriptCognitive(
        state,
        programUuid,
        userUuid,
        cohortUuid,
        category,
        source
      ),
    _.isEqual
  );

  return (
    <ByDefaultCognitive
      userUuid={userUuid}
      cohortUuid={cohortUuid}
      programUuid={programUuid}
      transcripts={transcripts}
      category={category}
      source={source}
      isAdmin={isAdmin}
    />
  );
}

ByDefaultCognitiveContainer.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  isAdmin: PropTypes.bool,
};
ByDefaultCognitiveContainer.defaultProps = {
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  isAdmin: false,
};
