import React from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js-light';
import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../../../helpers/format/user.format';
import PartFacultyReportPanel from './PartFacultyReportPanel';

const PartFacultyReportPanelContainer = ({
  assessments,
  assessmentTechniques,
  assessmentTypes,
  cumulativePotentialScore,
  isSummaryPanel,
  partFacultyReportAssessmentData,
  partFacultyTotalPotentialScore,
  partFacultyTotalRelativeScore,
  partFacultyUser,
  partUuid
}) => {
  const decimalpartFacultyTotalPotentialScore = new Decimal(
    partFacultyTotalPotentialScore
  );
  const decimalpartFacultyTotalRelativeScore = new Decimal(
    partFacultyTotalRelativeScore
  );
  const decimalCumulativeTotalPotentialScore = new Decimal(
    cumulativePotentialScore
  );
  const facultyParticipationPercentage =
    decimalCumulativeTotalPotentialScore.isZero()
      ? '0.00'
      : decimalpartFacultyTotalPotentialScore
          .dividedBy(decimalCumulativeTotalPotentialScore)
          .times(100)
          .toFixed(2);
  const facultyRelativePercentage =
    decimalCumulativeTotalPotentialScore.isZero()
      ? '0.00'
      : decimalpartFacultyTotalRelativeScore
          .dividedBy(decimalpartFacultyTotalPotentialScore)
          .times(100)
          .toFixed(2);

  const partFacultyLabel =
    partFacultyUser === undefined
      ? 'All Faculty'
      : `${formatLastName(
          partFacultyUser.lastName,
          partFacultyUser.marriedLastName,
          partFacultyUser.suffix
        )}, ${formatFirstNameMiddleName(
          partFacultyUser.firstName,
          partFacultyUser.middleName
        )}`;
  return decimalpartFacultyTotalPotentialScore.greaterThan(0) ? (
    <PartFacultyReportPanel
      assessments={assessments}
      assessmentTechniques={assessmentTechniques}
      assessmentTypes={assessmentTypes}
      facultyUuid={partFacultyUser?.uuid}
      isSummaryPanel={isSummaryPanel}
      partFacultyReportAssessmentData={partFacultyReportAssessmentData}
      partFacultyLabel={partFacultyLabel}
      partFacultyParticipationPercentage={facultyParticipationPercentage}
      partFacultyRelativePercentage={facultyRelativePercentage}
      partFacultyTotalPotentialScore={partFacultyTotalPotentialScore}
      partFacultyTotalRelativeScore={partFacultyTotalRelativeScore}
      partUuid={partUuid}
    />
  ) : null;
};

PartFacultyReportPanelContainer.propTypes = {
  assessments: PropTypes.array,
  assessmentTechniques: PropTypes.array,
  assessmentTypes: PropTypes.array,
  isSummaryPanel: PropTypes.bool,
  cumulativePotentialScore: PropTypes.string,
  partFacultyReportAssessmentData: PropTypes.object,
  partFacultyTotalPotentialScore: PropTypes.string,
  partFacultyTotalRelativeScore: PropTypes.string,
  partFacultyUser: PropTypes.object,
  partUuid: PropTypes.string
};
PartFacultyReportPanelContainer.defaultProps = {
  assessments: [],
  assessmentTechniques: [],
  assessmentTypes: [],
  isSummaryPanel: false,
  cumulativePotentialScore: '0.00',
  partFacultyReportAssessmentData: {},
  partFacultyTotalPotentialScore: '0.00',
  partFacultyTotalRelativeScore: '0.00',
  partFacultyUser: undefined,
  partUuid: ''
};

export default PartFacultyReportPanelContainer;
