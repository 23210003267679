import {
  GET_DEGREE_LEVELS,
  GET_DEGREE_LEVELS_SUCCESS,
  GET_DEGREE_LEVELS_ERROR,
  GET_PROGRAM_CIPS,
  GET_PROGRAM_CIPS_SUCCESS,
  GET_PROGRAM_CIPS_ERROR,
  GET_PROGRAM_DISCIPLINES,
  GET_PROGRAM_DISCIPLINES_SUCCESS,
  GET_PROGRAM_DISCIPLINES_ERROR,
  GET_PROGRAM_CODES,
  GET_PROGRAM_CODES_SUCCESS,
  GET_PROGRAM_CODES_ERROR,
  GET_ACCREDITATION_AGENCIES,
  GET_ACCREDITATION_AGENCIES_SUCCESS,
  GET_ACCREDITATION_AGENCIES_ERROR,
  GET_INSTITUTION_ID_TYPES,
  GET_INSTITUTION_ID_TYPES_SUCCESS,
  GET_INSTITUTION_ID_TYPES_ERROR
} from '../actions-type';

const doGetDegreeLevels = () => ({
  type: GET_DEGREE_LEVELS
});

const doGetDegreeLevelsSuccess = result => ({
  type: GET_DEGREE_LEVELS_SUCCESS,
  payload: result
});

const doGetDegreeLevelsError = error => ({
  type: GET_DEGREE_LEVELS_ERROR,
  payload: error
});

const doGetProgramCips = () => ({
  type: GET_PROGRAM_CIPS
});

const doGetProgramCipsSuccess = result => ({
  type: GET_PROGRAM_CIPS_SUCCESS,
  payload: result
});

const doGetProgramCipsError = error => ({
  type: GET_PROGRAM_CIPS_ERROR,
  payload: error
});

const doGetProgramDisciplines = cipUuid => ({
  type: GET_PROGRAM_DISCIPLINES,
  payload: { cipUuid }
});

const doGetProgramDisciplinesSuccess = result => ({
  type: GET_PROGRAM_DISCIPLINES_SUCCESS,
  payload: result
});

const doGetProgramDisciplinesError = error => ({
  type: GET_PROGRAM_DISCIPLINES_ERROR,
  payload: error
});

const doGetProgramCodes = () => ({
  type: GET_PROGRAM_CODES
});

const doGetProgramCodesSuccess = result => ({
  type: GET_PROGRAM_CODES_SUCCESS,
  payload: result
});

const doGetProgramCodesError = error => ({
  type: GET_PROGRAM_CODES_ERROR,
  payload: error
});

const doGetAccreditationAgencies = () => ({
  type: GET_ACCREDITATION_AGENCIES
});

const doGetAccreditationAgenciesSuccess = result => ({
  type: GET_ACCREDITATION_AGENCIES_SUCCESS,
  payload: result
});

const doGetAccreditationAgenciesError = error => ({
  type: GET_ACCREDITATION_AGENCIES_ERROR,
  payload: error
});

const doGetInstitutionIdTypes = () => ({
  type: GET_INSTITUTION_ID_TYPES
});

const doGetInstitutionIdTypesSuccess = result => ({
  type: GET_INSTITUTION_ID_TYPES_SUCCESS,
  payload: result
});

const doGetInstitutionIdTypesError = error => ({
  type: GET_INSTITUTION_ID_TYPES_ERROR,
  payload: error
});

export {
  doGetDegreeLevels,
  doGetDegreeLevelsSuccess,
  doGetDegreeLevelsError,
  doGetProgramCips,
  doGetProgramCipsSuccess,
  doGetProgramCipsError,
  doGetProgramDisciplines,
  doGetProgramDisciplinesSuccess,
  doGetProgramDisciplinesError,
  doGetProgramCodes,
  doGetProgramCodesSuccess,
  doGetProgramCodesError,
  doGetAccreditationAgencies,
  doGetAccreditationAgenciesSuccess,
  doGetAccreditationAgenciesError,
  doGetInstitutionIdTypes,
  doGetInstitutionIdTypesSuccess,
  doGetInstitutionIdTypesError
};
