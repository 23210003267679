import React, { useEffect, useState } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { Button, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { schoolSelectorBySelectedSchoolUuid } from '../../../../redux/selectors/schoolSelectors';
import { ContainerContent, ContainerPage, ElevateLow } from '../../../Library/Layout';
import { doPatchSchoolDenominators } from '../../../../redux/actions/schoolActions';

const RevusRow = styled.div`
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: space-between;
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const validateNumber = (value, setter) => {
  if (Number.isNaN(value)) {
    setter('Value must be a number');
  } else if (value < 0.1) {
    setter('Value cannot be lower than 0.1');
  } else if (value > 100) {
    setter('Value cannot be greater than 100.0');
  } else if (value.toString().split('.')[1]?.length > 1 ) {
    setter('Value must have a single decimal');
  } else {
    setter(null);
  }
}

const LinkedDenominators = () => {
  const [linkedSeminarDenominator, setLinkedSeminarDenominator] = useState(1);
  const [linkedSeminarDenominatorError, setLinkedSeminarDenominatorError] = useState(null);
  const [linkedPracExpDenominator, setLinkedPracExpDenominator] = useState(1);
  const [linkedPracExpDenominatorError, setLinkedPracExpDenominatorError] = useState(null);
  const dispatch = useDispatch();

  const school = useSelector(
    state => schoolSelectorBySelectedSchoolUuid(state),
    isEqual
  );

  const { loading } = useSelector(
    state => state.authorizeState,
    shallowEqual
  );

  useEffect(() => {
    if (school) {
      setLinkedSeminarDenominator(parseFloat(school.linkedSeminarDenominator));
      setLinkedPracExpDenominator(parseFloat(school.linkedPracExpDenominator));
    }
  }, [school]);

  const handleSave = () => {
    if (!linkedSeminarDenominatorError && !linkedPracExpDenominatorError) {
      dispatch(doPatchSchoolDenominators({linkedSeminarDenominator, linkedPracExpDenominator, schoolUuid: school.uuid}));
    }
  }

  return (
    <ContainerPage paddingTop="60px">
      <ElevateLow>
        <ContainerContent>
          {loading === 'yes'
            ? 
              <CircularProgress />
            :
              <>
                <RevusRow>
                  <TextField
                    required
                    name="Linked Seminar Denominator"
                    label="Linked Seminar Denominator"
                    value={linkedSeminarDenominator}
                    onChange={(e) => {
                      const value = e.target.valueAsNumber;
                      validateNumber(value, setLinkedSeminarDenominatorError);
                      if (!Number.isNaN(value)) {
                        setLinkedSeminarDenominator(value);
                      } else {
                        setLinkedSeminarDenominator('');
                      }
                    }}
                    error={!!linkedSeminarDenominatorError}
                    helperText={linkedSeminarDenominatorError}
                    margin="normal"
                    type="number"
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0.1, max: 100, step: 0.1 } }}
                    style={{width: '100%'}}
                    lang="en-US"
                  />
                </RevusRow>
                <RevusRow>
                  <TextField
                    required
                    name="Linked Practical Experience Denominator"
                    label="Linked Practical Experience Denominator"
                    value={linkedPracExpDenominator}
                    onChange={(e) => {
                      const value = e.target.valueAsNumber;
                      validateNumber(value, setLinkedPracExpDenominatorError);
                      if (!Number.isNaN(value)) {
                        setLinkedPracExpDenominator(value);
                      } else {
                        setLinkedPracExpDenominator('');
                      }
                    }}
                    error={!!linkedPracExpDenominatorError}
                    helperText={linkedPracExpDenominatorError}
                    margin="normal"
                    type="number"
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0.1, max: 100, step: 0.1 } }}
                    style={{width: '100%'}}
                    lang="en-US"
                  />
                </RevusRow>
                <ButtonRow>
                  <Button
                    onClick={handleSave}
                    variant='contained'
                    color='primary'
                    disabled={!!(linkedSeminarDenominatorError || linkedPracExpDenominatorError)}
                  >
                    Save
                  </Button>
                </ButtonRow>
              </>
          }
        </ContainerContent>
      </ElevateLow>
    </ContainerPage>
  );
};

LinkedDenominators.propTypes = {
};

LinkedDenominators.defaultProps = {
};

export default LinkedDenominators;