import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Chip from '@material-ui/core/Chip';
import ScoresData from './ScoresData';
import { assessmentBlockResultsSelector } from '../../../../redux/selectors/assessmentBlockResultSelectors';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';

const assessmentBlockResultStatusMap = {
  pass: 'Passed',
  fail: 'Failed',
  disqualified: 'Disqualified',
  critical: 'Critical Failure',
  pending: 'Pending'
};

const StepPanelAccordion = styled(
  ({
    withBottomBorder,
    withTopBorder,
    criticalFailure,
    blockResultStatus,
    ...rest
  }) => <AccordionRowContainer {...rest} />
)`
  margin-left: 1rem;
  width: calc(100% - 1rem);

  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    white-space: nowrap;
  }

  .accordion_row_label div {
    font-size: 14px !important;
    font-weight: 500;
    color: ${props => props.theme.fontColors.darker};
  }

  .line {
    left: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }

  .block_result_status {
    .MuiChip-root {
      background-color: ${props =>
        (props.blockResultStatus === 'pass' && props.theme.colors.status.success) ||
        (props.blockResultStatus === 'disqualified' && props.theme.colors.status.warning) ||
        (props.blockResultStatus === 'pending' && props.theme.colors.status.warning) ||
        props.theme.colors.status.error};
      color: ${props => props.theme.colors.white};
      min-width: 10rem;
      font-size: 1rem;
      margin-right: 1rem;
    }
  }
`;

const Indent = styled.div`
  padding-left: 20px;
`;

export default function ScoresBlockResults({
  assessmentBlockUuid,
  studentUuid,
  scoreType
}) {
  const assessmentBlockResults = useSelector(
    state =>
      assessmentBlockResultsSelector(state, assessmentBlockUuid, studentUuid),
    _.isEqual
  );

  const isOpportunity = scoreType === 'Opportunity';

  return (
    <>
      {assessmentBlockResults
        ?.sort((a, b) => a.blockAttempt - b.blockAttempt)
        .map(assessmentBlockResult => (
          <StepPanelAccordion
            key={assessmentBlockResult?.uuid}
            label={`ATTEMPT ${assessmentBlockResult?.blockAttempt} - ${assessmentBlockResult?.uuid}`}
            columnOne={isOpportunity ? null : <div />}
            columnOneClassName="line"
            columnTwoClassName="block_result_status"
            columnTwo={
              isOpportunity ? null : (
                <Chip
                  label={
                    assessmentBlockResultStatusMap[assessmentBlockResult.status]
                  }
                />
              )
            }
            blockResultStatus={assessmentBlockResult.status}
          >
            <Indent>
              <ScoresData
                isOpportunity={isOpportunity}
                assessmentBlockUuid={assessmentBlockUuid}
                attempt={assessmentBlockResult?.blockAttempt}
                studentUuid={studentUuid}
              />
            </Indent>
          </StepPanelAccordion>
        ))}
    </>
  );
}

ScoresBlockResults.propTypes = {
  assessmentBlockUuid: PropTypes.string,
  studentUuid: PropTypes.string,
  scoreType: PropTypes.string
};

ScoresBlockResults.defaultProps = {
  assessmentBlockUuid: undefined,
  studentUuid: undefined,
  scoreType: undefined
};
