import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid, InputBase, Popper } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LockIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { selectProgramCodeSet } from '../../redux/selectors/sourceSelectors';
import { ElevateMedium } from '../Library/Layout';
import fetchWithAuthorization from '../../helpers/fetch';

const Layout = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  position: relative;
`;

const SearchRow = styled.div`
  border: 1px solid ${props => props.theme.colors.grey[400]};
  border-radius: 4px;
  &:hover {
    border-color: ${props => (props.lock ? 'none' : props.theme.colors.black)};
  }
  border-color: ${props =>
    props.error
      ? props.theme.colors.status.error
      : props.theme.colors.grey[400]};
`;

const SearchLabel = styled.div`
  color: ${props => props.theme.fontColors.dark};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 14px;
  height: 50px;
  padding-left: 20px;
  color: ${props =>
    props.error
      ? props.theme.colors.status.error
      : props.theme.fontColors.dark};
`;

const SearchInput = styled(({ ...rest }) => <InputBase {...rest} />)`
  && {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    border-right: solid 1px ${props => props.theme.colors.grey[400]};
    border-left: solid 1px ${props => props.theme.colors.grey[400]};
  }
`;

const SearchIcon = styled(({ ...rest }) => <Search {...rest} />)`
  && {
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.grey[400]};
  }
`;

const LoadIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${props => props.theme.colors.grey[400]};
`;

const CloseIcon = styled(({ ...rest }) => <Close {...rest} />)`
  && {
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.grey[400]};
  }
`;

const IconButton = styled.div`
  height: 35px;
  &:hover {
    border-color: ${props => (props.lock ? 'none' : props.theme.colors.black)};
  }
`;

const SearchResults = styled.div`
  width: 100%;
  background: red;
  margin-top: 10px;
  background: ${props => props.theme.colors.grey[100]};
  border-radius: 5px;
  overflow-y: ${props => {
    return props.size > 4 ? 'scroll' : 'none';
  }};
  height: ${props => {
    return props.size > 4 ? '200px' : '100%';
  }};
`;

const Item = styled.div`
  padding: 15px;
  font-size: 14px;
  &:hover {
    background: ${props => props.theme.colors.grey[100]};
  }
`;

const NoResults = styled.div`
  padding: 15px;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background: ${props => props.theme.colors.grey[100]};
`;

const SkeletonLayout = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const Required = styled.div`
  position: absolute;
  font-size: 12px;
  padding-left: 16px;
  color: ${props => props.theme.colors.status.error};
  padding-top: 8px;
`;

export default function ProgramCodeSearch({
  programCodeUuid,
  hasErrors,
  handleProgramCodeUuid,
  lock,
  ...rest
}) {
  const [searchText, setSearchText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [resultsDisplay, setResultsDisplay] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selection, setSelection] = useState(undefined);

  const dispatch = useDispatch();
  const searchInputRef = useRef(null);

  const existing = useSelector(
    state => selectProgramCodeSet(state, programCodeUuid),
    _.isEqual
  );

  useEffect(() => {
    if (programCodeUuid && existing) {
      setSelection(programCodeUuid);

      setSearchText(
        `${existing.programCip.code}.${existing.programDiscipline.code}${existing.programCode.code} - ${existing.programCode.title}`
      );
    } else {
      setSearchText('');
      setSelection(undefined);
    }
  }, [programCodeUuid, existing]);

  const searchInstitutions = async () => {
    setResultsDisplay(true);
    setResultsLoading(true);

    const response = await fetchWithAuthorization(
      `/api/search-program-codes?title=${searchText}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      }
    )
      .then(res => res)
      .catch(error => {
        throw error;
      });

    if (response.status === 200) {
      const result = await response.json();

      if (result.programCodes.length > 0) {
        dispatch({
          type: 'GET_SOURCE_PROGRAM_CODES_BY_TITLE_SUCCESS',
          payload: result
        });

        setResults(result.programCodes);
        setResultsLoading(false);
      } else {
        setResults([]);
        setResultsLoading(false);
      }
    } else {
      setResults([]);
      setResultsLoading(false);
      dispatch({
        type: 'GET_SOURCE_PROGRAM_CODES_BY_TITLE_SUCCESS_ERROR'
      });
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && searchText.length >= 1) {
      searchInstitutions();
    }

    if (event.keyCode === 8) {
      setResultsDisplay(false);
    }
  };

  const handleClickSearch = () => {
    if (searchText.length >= 1) {
      searchInstitutions();
    }
  };

  const handleSearchText = event => {
    setAnchorEl(event.currentTarget);
    setSearchText(event.target.value);
  };

  const handleClickAway = () => {
    setResultsDisplay(false);
  };

  const handleSelection = programCode => {
    setSelection(programCode.uuid);
    setResultsDisplay(false);
    handleProgramCodeUuid(programCode.uuid);
  };

  const handleSelectionClear = () => {
    handleProgramCodeUuid(undefined);
  };

  const iconState = () => {
    if (lock) {
      return (
        <LoadIcon>
          <LockIcon />
        </LoadIcon>
      );
    } else if (selection) {
      return <CloseIcon onClick={handleSelectionClear} {...rest} />;
    } else if (resultsLoading) {
      return (
        <LoadIcon>
          <CircularProgress size="25px" thickness={5} />
        </LoadIcon>
      );
    } else {
      return <SearchIcon onClick={handleClickSearch} {...rest} />;
    }
  };

  const searchListResults = () => {
    if (resultsLoading) {
      return (
        <SearchResults>
          <SkeletonLayout>
            <Skeleton width="100%" height={60} />
            <Skeleton width="100%" height={60} />
            <Skeleton width="100%" height={60} />
            <Skeleton width="100%" height={60} />
          </SkeletonLayout>
        </SearchResults>
      );
    } else if (!resultsLoading && results.length > 0) {
      return (
        <SearchResults size={results.length}>
          {results.map(result => (
            <Item onClick={() => handleSelection(result)} key={result.uuid}>
              {result.title}
            </Item>
          ))}
        </SearchResults>
      );
    } else {
      return <NoResults>No results found....</NoResults>;
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Layout>
        <SearchRow lock={lock} error={!selection && hasErrors}>
          <Grid container>
            <Grid item sm={3} xs={3}>
              <SearchLabel error={!selection && hasErrors}>
                Program Code
              </SearchLabel>
            </Grid>
            <Grid item sm={8} xs={8}>
              <SearchInput
                inputRef={searchInputRef}
                disabled={Boolean(selection)}
                onChange={event => {
                  handleSearchText(event);
                }}
                onKeyDown={handleKeyDown}
                value={searchText}
                {...rest}
              />

              {!selection && hasErrors && (
                <Required>Field is required</Required>
              )}
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              item
              sm={1}
              xs={1}
            >
              <IconButton>{iconState()}</IconButton>
            </Grid>
          </Grid>
        </SearchRow>

        <Popper
          style={{
            width: anchorEl ? anchorEl.clientWidth : null,
            zIndex: 2000
          }}
          open={resultsDisplay}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          <ElevateMedium>{searchListResults()}</ElevateMedium>
        </Popper>
      </Layout>
    </ClickAwayListener>
  );
}

ProgramCodeSearch.propTypes = {
  programCodeUuid: PropTypes.string,
  lock: PropTypes.bool,
  hasErrors: PropTypes.bool,
  handleProgramCodeUuid: PropTypes.func
};

ProgramCodeSearch.defaultProps = {
  programCodeUuid: undefined,
  lock: false,
  hasErrors: false,
  handleProgramCodeUuid: undefined
};
