import { Model, attr } from 'redux-orm';
import {
  GET_ASSESSMENT_TECHNIQUES_SUCCESS,
  GET_PROGRAM_ASSESSMENTS_SUCCESS,
  GET_PROGRAM_ASSESSMENT_SUCCESS,
  GET_SYLLABUS_PART_SUCCESS,
  GET_SYLLABUS_SUCCESS,
  GET_COHORT_ASSESSMENTS_SUCCESS,
  GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS
} from '../actions-type';

class AssessmentTechnique extends Model {
  static reducer(action, AssessmentTechnique, session) {
    const { type, payload } = action;
    switch (type) {
      case GET_SYLLABUS_SUCCESS: {
        const assessmentTechniques = [...payload.assessmentTechniques];
        assessmentTechniques.forEach(aTechnique =>
          AssessmentTechnique.upsert(aTechnique)
        );
        break;
      }
      case GET_ASSESSMENT_TECHNIQUES_SUCCESS: {
        const { assessmentTechniques } = payload;
        assessmentTechniques.forEach(assessmentTechnique =>
          AssessmentTechnique.upsert(assessmentTechnique)
        );
        break;
      }
      case GET_PROGRAM_ASSESSMENT_SUCCESS:
      case GET_PROGRAM_ASSESSMENTS_SUCCESS: {
        const { assessmentTechniques } = payload;
        assessmentTechniques.forEach(assessmentTechnique =>
          AssessmentTechnique.upsert(assessmentTechnique)
        );
        break;
      }
      case GET_SYLLABUS_PART_SUCCESS: {
        const assessmentTechniques = [...payload.assessmentTechniques];
        assessmentTechniques.forEach(aTechnique =>
          AssessmentTechnique.upsert(aTechnique)
        );
        break;
      }
      case GET_COHORT_ASSESSMENTS_SUCCESS: {
        const assessmentTechniques = [...payload.assessmentTechniques];
        assessmentTechniques.forEach(aTechnique =>
          AssessmentTechnique.upsert(aTechnique)
        );
        break;
      }
      case GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS: {
        const assessmentTechniques = [...payload.assessmentTechniques];
        assessmentTechniques.forEach(aTechnique =>
          AssessmentTechnique.upsert(aTechnique)
        );
        break;
      }
      default:
        return session;
    }
  }
}

AssessmentTechnique.fields = {
  uuid: attr(),
  technique: attr(),
  order: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

AssessmentTechnique.modelName = 'AssessmentTechnique';

AssessmentTechnique.options = {
  idAttribute: 'uuid'
};

export default AssessmentTechnique;
