import React from 'react';
import styled from 'styled-components';
import { Body2 } from '@xcomp/xcomp-design-library';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

const ContainerPage = styled(
  ({ paddingTop, marginLeft, marginRight, ...rest }) => <div {...rest} />
)`
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '70px')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '70px')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '140px')};
`;

const ContainerContent = styled.div`
  padding: 2rem;
`;

const ContainerContentLight = styled.div`
  padding: 1rem;
`;

const ContainerActions = styled(({ paddingTop, ...rest }) => <div {...rest} />)`
  display: flex;
  justify-content: flex-end;
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '100px')};
  padding-bottom: 20px;
  padding-left: 70px;
  padding-right: 70px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const ContainerTabs = styled(({ paddingTop, ...rest }) => <div {...rest} />)`
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '75px')};
`;

const ElevateLow = styled.div`
  border-radius: 4px;

  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background: ${props => props.theme.colors.white};
`;

const ElevateMedium = styled.div`
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  background: ${props => props.theme.colors.white};
`;

const ElevateHigh = styled.div`
  border-radius: 4px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  background: ${props => props.theme.colors.white};
`;

const ElevateOverLay = styled.div`
  border-radius: 4px;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  background: ${props => props.theme.colors.white};
`;

const Panel = styled.div`
  background: ${props => props.theme.colors.white};
  max-width: 1500px;
  display: block;
  margin: 0 auto;
  padding: 0;
  box-shadow: ${props => props.theme.boxShadows.low};
  border: 1px solid rgba(155, 155, 155, 0.1);
  border-radius: 3px;
`;

const PanelHeader = styled(({ paddingLeft, paddingRight, ...rest }) => (
  <div {...rest} />
))`
  height: 48px;
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '66px')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '66px')};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const PanelLabel = styled(({ marginLeft, ...rest }) => <Body2 {...rest} />)`
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)};
`;

const Page = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  padding: 0 1.5rem;
`;

const RowSummary = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 0.9375rem;
  font-weight: 300;
  color: ${props => props.theme.fontColors.dark};

  h2 {
    font-size: inherit;
    font-weight: 400;
    color: ${props => props.theme.fontColors.darker};
    margin: 0;
    padding: 0;
  }

  svg {
    vertical-align: middle;
  }
`;

const Main = styled.div`
  min-height: calc(100vh - 64px);
  margin-top: 64px;
  margin-left: ${props => (props.open ? '300px' : '60px')};
  transition-duration: 0.2s;
  padding-bottom: 3rem;
`;

export default function ContainerResize({ children }) {
  const drawerOpen = useSelector(state => state.navigationState.drawerOpen);

  return <Main open={drawerOpen}>{children}</Main>;
}

ContainerResize.propTypes = {
  children: PropTypes.node
};

ContainerResize.defaultProps = {
  children: <div />
};

export {
  ContainerResize,
  ContainerPage,
  ContainerContent,
  ContainerContentLight,
  ContainerTabs,
  ContainerActions,
  ElevateLow,
  ElevateMedium,
  ElevateHigh,
  ElevateOverLay,
  Panel,
  PanelHeader,
  PanelLabel,
  Page,
  Wrapper,
  RowSummary
};
