import {
  GET_SECTION_NESTED_BY_COURSE,
  GET_SECTION_NESTED_BY_COURSE_SUCCESS,
  GET_SECTION_NESTED_BY_COURSE_ERROR
} from '../actions-type';

const INITIAL_STATE = {};

const fetchSectionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SECTION_NESTED_BY_COURSE: {
      const courseUuid = action.payload;

      return {
        ...state,
        [`${courseUuid}`]: true
      };
    }
    case GET_SECTION_NESTED_BY_COURSE_SUCCESS: {
      const { courseUuid } = action.payload;

      return {
        ...state,
        [`${courseUuid}`]: false
      };
    }
    case GET_SECTION_NESTED_BY_COURSE_ERROR: {
      const { courseUuid } = action.payload;

      return {
        ...state,
        [`${courseUuid}`]: false
      };
    }
    default:
      return state;
  }
};

export default fetchSectionsReducer;
