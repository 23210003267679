import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../helpers/format/user.format';
import AccordionRowContainer from '../../Library/AccordionRow/AccordionRowContainer';
import CourseAccordionRow from './CourseAccordionRow';

const OpportunityReportPanelAccordion = styled(AccordionRowContainer)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label {
    width: 140px;
  }
  .column_two_label {
    width: 100px;
  }
  .column_three_label {
    width: 120px;
  }
  .column_one_label,
  .column_two_label,
  .column_three_label {
    flex-grow: 0;
    text-align: right;
  }

  .accordion_row_label div {
    color: ${props =>
      props.isStatisticStudent
        ? props.theme.colors.status.success
        : props.theme.fontColors.darker};
  }

  .column_one_label,
  .column_two_label {
    margin-right: 16px;
    span {
      color: ${props =>
        props.isStatisticStudent
          ? props.theme.colors.status.success
          : props.theme.fontColors.darker};
    }
  }

  .column_three_label {
    span {
      color: ${props =>
        props.isStatisticStudent
          ? props.theme.colors.status.success
          : props.theme.fontColors.darker};
    }
  }

  .hover-effect {
    padding-right: 44px;
  }
  font-size: 20px;
  width: 100%;
`;

const StudentRow = ({ student }) => {
  const [panelLabel, setPanelLabel] = useState('');
  const [isStatisticStudent, setIsStatisticStudent] = useState(false);

  const { coursePerformanceReport, courses } = useSelector(
    state => state.cohortOpportunityReportState
  );

  useEffect(() => {
    if (student.uuid === 'student_high') {
      setPanelLabel('Student High (Capacity)');

      setIsStatisticStudent(true);
    } else if (student.uuid === 'student_average') {
      setPanelLabel('Student Average (Capacity)');
      setIsStatisticStudent(true);
    } else {
      const formattedFirstName = formatFirstNameMiddleName(
        student.firstName,
        student.middleName
      );
      const formattedLastName = formatLastName(
        student.lastName,
        student.marriedLastName,
        student.suffix
      );
      setPanelLabel(`${formattedLastName}, ${formattedFirstName}`);
    }
  }, [
    student.firstName,
    student.lastName,
    student.marriedLastName,
    student.middleName,
    student.suffix,
    student.uuid
  ]);

  const performanceData = useMemo(
    () => coursePerformanceReport?.[student.uuid],
    [coursePerformanceReport, student?.uuid]
  );

  return (
    <OpportunityReportPanelAccordion
      key={student.uuid}
      columnOne={performanceData?.totalOpportunityCount}
      columnTwo={performanceData?.totalRelativeScore}
      columnThree={performanceData?.totalPointsPerOpportunity}
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
      columnThreeClassName="column_three_label"
      expandWidth="66px"
      height="50px"
      isStatisticStudent={isStatisticStudent}
      label={panelLabel}
      withBottomBorder
    >
      {courses.map(c => (
        <CourseAccordionRow
          key={c.uuid}
          student={student}
          course={c}
          performanceData={performanceData}
        />
      ))}
    </OpportunityReportPanelAccordion>
  );
};

StudentRow.defaultProps = {
  student: {}
};
StudentRow.propTypes = { student: PropTypes.object };

export default StudentRow;
