import React, { useMemo, forwardRef } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';

import Header from '../../Library/Header';
import { ContainerResize, ContainerPage } from '../../Library/Layout';
import StepListContainer from './Step/StepListContainer';
import FacultyListContainer from './Faculty/FacultyListContainer';
import TopicListContainer from './Topic/TopicListContainer';
import { ButtonDelete, ButtonDownload, ButtonUpload } from '../../Syllabus/Scores/ScoresUpload/ScoresUpload';

const Actions = styled.div`
  background: ${props => props.theme.colors.white};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 77px;
`;

const TabsSC = styled(Tabs)`
  && {
    border-top: 1px solid ${props => props.theme.colors.grey[300]};
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
    background: ${props => props.theme.colors.white};
  }
`;

const TabSC = styled(Tab)`
  && {
    max-width: 100%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    line-height: 16px;
    width: 100%;
  }
`;

const LinkSC = styled(Link)`
  width: 100%;
`;

export default function Scores({
  assessmentBlocks,
  assessmentType,
  assessmentTypeUuid,
  assessmentUuid,
  programUuid,
  programFaculty,
  students,
  cohortAssessmentCollectionUuid,
  cohortUuid,
  cohortTitle,
  cohortAssessmentCollectionTitle,
  handleDeleteScoresOpen,
  handleUploadScoresOpen,
  handleDownloadScoresOpen,
  pathname,
  scoreType,
  assessmentScores,
  title
}) {
  const basePath = `/cohort-syllabus-management/view/scores/cohort/${cohortUuid}/cohort-assessment-collection/${cohortAssessmentCollectionUuid}/cohort-assessment/${assessmentUuid}/type/${assessmentTypeUuid}`;
  let location = 0;

  if (
    pathname ===
    `/cohort-syllabus-management/view/scores/cohort/${cohortUuid}/cohort-assessment-collection/${cohortAssessmentCollectionUuid}/cohort-assessment/${assessmentUuid}/type/${assessmentTypeUuid}/steps`
  ) {
    location = 0;
  } else if (
    pathname ===
    `/cohort-syllabus-management/view/scores/cohort/${cohortUuid}/cohort-assessment-collection/${cohortAssessmentCollectionUuid}/cohort-assessment/${assessmentUuid}/type/${assessmentTypeUuid}/faculty`
  ) {
    location = 1;
  } else if (
    pathname ===
    `/cohort-syllabus-management/view/scores/cohort/${cohortUuid}/cohort-assessment-collection/${cohortAssessmentCollectionUuid}/cohort-assessment/${assessmentUuid}/type/${assessmentTypeUuid}/topics`
  ) {
    location = 2;
  }

  const renderLink = useMemo(
    () =>
      forwardRef((props, ref) => (
        // eslint-disable-next-line react/prop-types
        <LinkSC to={props.topath} {...props} ref={ref} />
      )),
    []
  );

  const loadingDeleteScores = useSelector(state => state.crudState.loadingDeleteScores);

  return (
    <ContainerResize>
      <Header
        title="Scores"
        subtitle={`Cohort ${cohortTitle} • ${cohortAssessmentCollectionTitle} • ${title}`}
        backButtonLocation={`/program-management/cohort/${cohortUuid}/assessment-collection/${cohortAssessmentCollectionUuid}/syllabus-management`}
      />
      <Actions>
        <div style={{padding: 10}}>
          <ButtonDownload onClick={handleDownloadScoresOpen}>
            <GetAppIcon />
            Download
          </ButtonDownload>
          <ButtonUpload onClick={handleUploadScoresOpen}>
            <PublishIcon />
            Upload
          </ButtonUpload>
          <ButtonDelete onClick={handleDeleteScoresOpen} disabled={loadingDeleteScores}>
            {loadingDeleteScores ? <CircularProgress style={{color: 'white', marginRight: 5}} size='20px' /> : <DeleteIcon /> }            
            Delete
          </ButtonDelete>
        </div>
      </Actions>
      <TabsSC
        value={location}
        textColor="primary"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <TabSC
          component={renderLink}
          topath={`${basePath}/steps`}
          label="step/item"
        />
        <TabSC
          component={renderLink}
          topath={`${basePath}/faculty`}
          label="faculty"
        />
        <TabSC
          component={renderLink}
          topath={`${basePath}/topics`}
          label="topic"
        />
      </TabsSC>
      <ContainerPage paddingTop="70px">
        <Switch>
          <Route
            path={`${basePath}/steps`}
            component={props => {
              return (
                <StepListContainer
                  {...props}
                  programUuid={programUuid}
                  cohortUuid={cohortUuid}
                  scoreType={scoreType}
                  assessmentUuid={assessmentUuid}
                  assessmentType={assessmentType}
                  assessmentBlocks={assessmentBlocks}
                />
              );
            }}
          />
          <Route
            path={`${basePath}/faculty`}
            component={props => {
              return (
                <FacultyListContainer
                  {...props}
                  cohortUuid={cohortUuid}
                  scoreType={scoreType}
                  assessmentUuid={assessmentUuid}
                  faculty={programFaculty}
                  assessmentScores={assessmentScores}
                  students={students}
                />
              );
            }}
          />
          <Route
            path={`${basePath}/topics`}
            component={props => {
              return (
                <TopicListContainer
                  {...props}
                  cohortUuid={cohortUuid}
                  scoreType={scoreType}
                  assessmentUuid={assessmentUuid}
                  assessmentType={assessmentType}
                  faculty={programFaculty}
                  assessmentScores={assessmentScores}
                  students={students}
                />
              );
            }}
          />
        </Switch>
      </ContainerPage>
    </ContainerResize>
  );
}

Scores.propTypes = {
  assessmentBlocks: PropTypes.array,
  programFaculty: PropTypes.array,
  assessmentScores: PropTypes.array,
  students: PropTypes.array,
  assessmentType: PropTypes.string,
  assessmentTypeUuid: PropTypes.string,
  assessmentUuid: PropTypes.string,
  programUuid: PropTypes.string,
  cohortAssessmentCollectionUuid: PropTypes.string,
  handleDownloadScoresOpen: PropTypes.func.isRequired,
  cohortUuid: PropTypes.string,
  cohortTitle: PropTypes.string,
  cohortAssessmentCollectionTitle: PropTypes.string,
  handleDeleteScoresOpen: PropTypes.func,
  handleUploadScoresOpen: PropTypes.func,
  pathname: PropTypes.string,
  scoreType: PropTypes.string,
  title: PropTypes.string
};
Scores.defaultProps = {
  assessmentBlocks: [],
  programFaculty: [],
  assessmentScores: [],
  students: [],
  assessmentType: '',
  assessmentTypeUuid: '',
  programUuid: '',
  assessmentUuid: '',
  cohortAssessmentCollectionUuid: '',
  cohortUuid: '',
  cohortTitle: '',
  cohortAssessmentCollectionTitle: '',
  handleDeleteScoresOpen: undefined,
  handleUploadScoresOpen: undefined,
  pathname: '',
  scoreType: '',
  title: ''
};
