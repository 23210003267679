import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonIcon, ButtonOutline } from '@xcomp/xcomp-design-library';
import SearchIcon from '@material-ui/icons/Search';
import {
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader
} from '../../Library/Layout';
import Header from '../../Library/Header';
import { TextFieldSC } from '../../Library/FormComponents';
import NoItemsMessage from '../../Library/NoItemsMessage';
import CommitmentLog from './CommitmentLog';

const HeaderSpacer = styled.div`
  width: 100%;
  height: 36px;
`;

const SearchBarWrapper = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  max-width: 500px;
`;

const PanelHeaderSC = styled(PanelHeader)`
  && {
    height: 100px;
    width: 100%;
    justify-content: space-between;
  }
`;

const SearchButton = styled(({ error, ...rest }) => <ButtonIcon {...rest} />)`
  && {
    position: absolute;
    right: 24px;
    top: ${props => (props.error ? '-20px' : '0')};
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 300;

    &:hover,
    &:focus,
    &:active {
      background: 0;
    }
  }
`;

const AssessmentCommitmentLog = ({
  assessment,
  cohortAsessmentCollection,
  cohort,
  cohortAssessmentUuid,
  cohortUuid,
  commitmentLogs,
  handleQueryChange,
  toggleSortReverse
}) => {
  const noCollectionsMessage =
    'There are currently no commitment logs for this cohort assessment.';

  const assessmentTitle = assessment?.title;
  const collectionTitle = cohortAsessmentCollection?.title;

  const { cohortAssessmentCollectionUuid } = assessment;

  const headerSubtitle = cohort
    ? `${cohort?.name} ● ${collectionTitle} ● ${assessmentTitle}`
    : `${collectionTitle} ● ${assessmentTitle}`;

  return (
    <ContainerResize>
      <Header
        title="Commitment Log"
        subtitle={headerSubtitle}
        backButtonLocation={`/program-management/cohort/${cohortUuid}/cohort-assessment-collection/${cohortAssessmentCollectionUuid}/assessment/${cohortAssessmentUuid}/grade-summary`}
      />
      <HeaderSpacer />;
      <ContainerPage paddingTop="48px">
        <Panel>
          <PanelHeaderSC paddingLeft="24px" paddingRight="24px">
            <SearchBarWrapper>
              <SearchButton noHoverBg onClick={undefined}>
                <SearchIcon />
              </SearchButton>
              <TextFieldSC
                autoFocus
                id="program-assessment-search"
                name="program-assessment-search"
                label="Search"
                placeholder="Student Name, Faculty Name, Date, etc."
                value={undefined}
                onChange={handleQueryChange}
                margin="normal"
                variant="outlined"
                paddingRight="1rem"
              />
            </SearchBarWrapper>
            <ButtonOutline onClick={toggleSortReverse}>
              Reverse Sort
            </ButtonOutline>
          </PanelHeaderSC>
          {commitmentLogs?.length > 0 ? (
            <>
              {commitmentLogs?.length > 0 &&
                commitmentLogs.map(log => (
                  <CommitmentLog key={log.uuid} logUuid={log.uuid} />
                ))}
            </>
          ) : (
            <Panel>
              <NoItemsMessage message={noCollectionsMessage} />
            </Panel>
          )}{' '}
        </Panel>
      </ContainerPage>
    </ContainerResize>
  );
};

AssessmentCommitmentLog.propTypes = {
  assessment: PropTypes.object,
  cohort: PropTypes.object,
  cohortAssessmentUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  cohortAsessmentCollection: PropTypes.object,
  handleQueryChange: PropTypes.func,
  toggleSortReverse: PropTypes.func,
  commitmentLogs: PropTypes.arrayOf(PropTypes.object)
};

AssessmentCommitmentLog.defaultProps = {
  assessment: {},
  cohort: {},
  cohortAssessmentUuid: '',
  cohortUuid: '',
  cohortAsessmentCollection: {},
  handleQueryChange: undefined,
  toggleSortReverse: undefined,
  commitmentLogs: []
};

export default AssessmentCommitmentLog;
