import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getCohort,
  getCohorts,
  getCohortsByProgram,
  postCohort,
  putCohort,
  deleteCohort
} from '../../api/cohortApi';

import {
  doGetCohortSuccess,
  doGetCohortError,
  doGetCohortsSuccess,
  doGetCohortsError,
  doGetProgramCohortsSuccess,
  doGetProgramCohortsError,
  doPostCohortSuccess,
  doPostCohortError,
  doPutCohortSuccess,
  doPutCohortError,
  doDeleteCohortSuccess,
  doDeleteCohortError
} from '../actions/cohortActions';

import {
  GET_COHORT,
  GET_COHORTS,
  GET_PROGRAM_COHORTS,
  POST_COHORT,
  PUT_COHORT,
  DELETE_COHORT
} from '../actions-type';

function* handleGetCohort(action) {
  const { cohortUuid } = action.payload;

  try {
    const result = yield call(getCohort, cohortUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCohortSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCohortError(error));
  }
}

function* handleGetCohorts() {
  try {
    const result = yield call(getCohorts);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCohortsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCohortsError(error));
  }
}

function* handleGetProgramCohorts(action) {
  const { programUuid } = action.payload;

  try {
    const result = yield call(getCohortsByProgram, programUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetProgramCohortsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetProgramCohortsError(error));
  }
}

function* handlePostCohort(action) {
  try {
    const result = yield call(postCohort, action.payload.cohort);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostCohortSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostCohortError(error));
  }
}

function* handlePutCohort(action) {
  try {
    const result = yield call(putCohort, action.payload.cohort);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutCohortSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutCohortError(error));
  }
}

function* handleDeleteCohort(action) {
  const { cohortUuid } = action.payload;

  try {
    const result = yield call(deleteCohort, cohortUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteCohortSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteCohortError(error));
  }
}

const cohort = [
  takeEvery(GET_COHORT, handleGetCohort),
  takeEvery(GET_COHORTS, handleGetCohorts),
  takeEvery(GET_PROGRAM_COHORTS, handleGetProgramCohorts),
  takeEvery(POST_COHORT, handlePostCohort),
  takeEvery(PUT_COHORT, handlePutCohort),
  takeEvery(DELETE_COHORT, handleDeleteCohort)
];

export { cohort };
