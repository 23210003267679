import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { SearchIllustration } from '@xcomp/xcomp-design-library';
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchMulti from '../../Library/SearchMulti';
import UserTabAppIdSearch from './UserTabAppIdSearch';

import {
  ContainerPage,
  ContainerContent,
  ElevateLow
} from '../../Library/Layout';
import AdminAppIdContainer from './ModalAppId/AdminAppIdContainer';
import StudentAppIdContainer from './ModalAppId/StudentAppIdContainer';

const SearchOrFilter = styled.div``;

const SearchOrFilterResults = styled.div`
  margin-top: 30px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40vh;
  align-items: center;
  font-size: 16px;
`;
const InvisibleLineBreak = styled.div`
  height: 4px;
`;

const LoadingBar = styled.div`
  padding-top: 5px;
`;

export default function UserTabAppId({
  adminAppIdUser,
  openAdminAppId,
  openStudentAppId,
  searchUserRoleBy,
  searchUserRoleInput,
  searching,
  studentAppIdUser,
  usersSearched,
  handleAdminAppIdClose,
  handleAdminAppIdOpen,
  handleSearch,
  handleStudentAppIdClose,
  handleStudentAppIdOpen
}) {
  const themeContext = useContext(ThemeContext);

  return (
    <ContainerPage paddingTop="40px">
      <SearchOrFilter>
        <ElevateLow>
          <ContainerContent>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <SearchMulti
                  searchBy={searchUserRoleBy}
                  searchInput={searchUserRoleInput}
                  placeholder="Search By"
                  options={[
                    { label: 'First Name', field: 'firstName' },
                    { label: 'Last Name', field: 'lastName' },
                    { label: 'Institution ID', field: 'institutionId' }
                  ]}
                  handleSearch={handleSearch}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <LoadingBar>
                  {searching ? <LinearProgress /> : <InvisibleLineBreak />}
                </LoadingBar>
              </Grid>
            </Grid>
          </ContainerContent>
        </ElevateLow>
      </SearchOrFilter>

      <SearchOrFilterResults>
        <ElevateLow>
          <UserTabAppIdSearch
            usersSearched={usersSearched}
            handleAdminAppIdOpen={handleAdminAppIdOpen}
            handleStudentAppIdOpen={handleStudentAppIdOpen}
          />
          {!(usersSearched.length > 0) && (
            <Center>
              <div>
                <SearchIllustration
                  dotsColor={themeContext.colors.primaryShades[450]}
                  bgColor={themeContext.colors.primaryShades[200]}
                  outlineColor={themeContext.colors.primary}
                />
              </div>
              <div>Search for users with active roles.</div>
            </Center>
          )}
        </ElevateLow>
      </SearchOrFilterResults>
      <AdminAppIdContainer
        adminAppIdUser={adminAppIdUser}
        openAdminAppId={openAdminAppId}
        handleAdminAppIdClose={handleAdminAppIdClose}
      />
      <StudentAppIdContainer
        studentAppIdUser={studentAppIdUser}
        openStudentAppId={openStudentAppId}
        handleStudentAppIdClose={handleStudentAppIdClose}
      />
    </ContainerPage>
  );
}

UserTabAppId.propTypes = {
  adminAppIdUser: PropTypes.string,
  openAdminAppId: PropTypes.bool,
  studentAppIdUser: PropTypes.string,
  openStudentAppId: PropTypes.bool,
  searchUserRoleBy: PropTypes.string,
  searchUserRoleInput: PropTypes.string,
  usersSearched: PropTypes.array,
  searching: PropTypes.bool,
  handleSearch: PropTypes.func,
  handleAdminAppIdClose: PropTypes.func,
  handleAdminAppIdOpen: PropTypes.func,
  handleStudentAppIdClose: PropTypes.func,
  handleStudentAppIdOpen: PropTypes.func
};
UserTabAppId.defaultProps = {
  adminAppIdUser: undefined,
  openAdminAppId: false,
  studentAppIdUser: undefined,
  openStudentAppId: false,
  searchUserRoleBy: undefined,
  searchUserRoleInput: undefined,
  searching: false,
  usersSearched: [],
  handleSearch: undefined,
  handleAdminAppIdClose: undefined,
  handleAdminAppIdOpen: undefined,
  handleStudentAppIdClose: undefined,
  handleStudentAppIdOpen: undefined
};
