import { parse, format, differenceInDays } from 'date-fns';

/* 
    Please review base format in order to use these utilties
*/

const formatMMddyyyy = date => {
  const formatted = date
    ? format(parse(date, 'yyyy-MM-dd', new Date()), 'MMMM dd, yyyy')
    : '';

  return formatted;
};

const formatMMMddyyyy = date => {
  const formatted = date
    ? format(parse(date, 'yyyy-MM-dd', new Date()), 'MMM dd, yyyy')
    : '';

  return formatted;
};

const formatMMddyy = date => {
  const formatted = date
    ? format(parse(date, 'yyyy-MM-dd', new Date()), 'MM/dd/yy')
    : '';

  return formatted;
};

const formatMMMMyyyy = date => {
  const formatted = date
    ? format(parse(date, 'yyyy-MM-dd', new Date()), 'MMMM yyyy')
    : '';

  return formatted;
};

const formatYYYY = date => {
  const formatted = date
    ? format(parse(date, 'yyyy-MM-dd', new Date()), 'yyyy')
    : '';

  return formatted;
};

const diffInDays = (minuendDate, subtrahendDate) => {
  if (!subtrahendDate || !minuendDate) {
    return '--';
  }

  return differenceInDays(
    parse(minuendDate, 'yyyy-MM-dd', new Date()),
    parse(subtrahendDate, 'yyyy-MM-dd', new Date())
  );
}

export { formatMMddyyyy, formatMMMddyyyy, formatYYYY, formatMMddyy, formatMMMMyyyy, diffInDays };
