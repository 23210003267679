import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import ComputerIcon from '@material-ui/icons/Computer';

import { convertNullToString } from '../../../../../helpers/utilities';
import SyllabusSubHeader from '../../SyllabusSubHeader';

import FormField from '../../../../Library/FormField';

const EncounterOtherFields = ({
  notes,
  handleEncounterChange,
  restrictEdit
}) => (
  <>
    <Grid item xs={12}>
      <SyllabusSubHeader>
        <ComputerIcon />
        Other
      </SyllabusSubHeader>
    </Grid>
    <Grid item xs={12}>
      <FormField
        name="notes"
        label="Notes"
        value={convertNullToString(notes)}
        onBlur={handleEncounterChange}
        multiline
        minRows="4"
        maxRows="8"
        disabled={restrictEdit}
      />
    </Grid>
  </>
);

EncounterOtherFields.propTypes = {
  notes: PropTypes.string,
  restrictEdit: PropTypes.bool,
  handleEncounterChange: PropTypes.func
};

EncounterOtherFields.defaultProps = {
  notes: '',
  restrictEdit: true,
  handleEncounterChange: undefined
};

export default EncounterOtherFields;
