import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getDepartment,
  getDepartments,
  getDepartmentsBySchool,
  postDepartment,
  putDepartment,
  deleteDepartment
} from '../../api/departmentApi';

import {
  doGetDepartmentSuccess,
  doGetDepartmentError,
  doGetDepartmentsSuccess,
  doGetDepartmentsError,
  doGetSchoolDepartmentsSuccess,
  doGetSchoolDepartmentsError,
  doPostDepartmentSuccess,
  doPostDepartmentError,
  doPutDepartmentSuccess,
  doPutDepartmentError,
  doDeleteDepartmentSuccess,
  doDeleteDepartmentError
} from '../actions/departmentActions';

import {
  GET_DEPARTMENT,
  GET_DEPARTMENTS,
  GET_SCHOOL_DEPARTMENTS,
  POST_DEPARTMENT,
  PUT_DEPARTMENT,
  DELETE_DEPARTMENT
} from '../actions-type';

function* handleGetDepartment(action) {
  const { departmentUuid } = action.payload;

  try {
    const result = yield call(getDepartment, departmentUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetDepartmentSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetDepartmentError(error));
  }
}

function* handleGetDepartments() {
  try {
    const result = yield call(getDepartments);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetDepartmentsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetDepartmentsError(error));
  }
}

function* handleGetSchoolDepartments(action) {
  const { schoolUuid } = action.payload;

  try {
    const result = yield call(getDepartmentsBySchool, schoolUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetSchoolDepartmentsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSchoolDepartmentsError(error));
  }
}

function* handlePostDepartment(action) {
  try {
    const result = yield call(postDepartment, action.payload.department);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostDepartmentSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostDepartmentError(error));
  }
}

function* handlePutDepartment(action) {
  try {
    const result = yield call(putDepartment, action.payload.department);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutDepartmentSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutDepartmentError(error));
  }
}

function* handleDeleteDepartment(action) {
  const { departmentUuid } = action.payload;

  try {
    const result = yield call(deleteDepartment, departmentUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteDepartmentSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteDepartmentError(error));
  }
}

const department = [
  takeEvery(GET_DEPARTMENT, handleGetDepartment),
  takeEvery(GET_DEPARTMENTS, handleGetDepartments),
  takeEvery(GET_SCHOOL_DEPARTMENTS, handleGetSchoolDepartments),
  takeEvery(POST_DEPARTMENT, handlePostDepartment),
  takeEvery(PUT_DEPARTMENT, handlePutDepartment),
  takeEvery(DELETE_DEPARTMENT, handleDeleteDepartment)
];

export { department };
