import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Subtitle2 } from '@xcomp/xcomp-design-library';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';

const AssessmentRow = styled.div`
  padding-left: 132px;
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
`;

const Input = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${props => props.width};
  ${props => props.autoWidth && `width: calc((100% - 20% - 230px)/2);`}
  padding-right: 10px;
`;

export const StatusLine = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }
`;

export const StatusLabel = styled(Subtitle2)`
  color: ${props => props.theme.fontColors.darker};
  position: relative;
  display: inline-block;
  z-index: 100;
  background: ${props => props.theme.colors.white};
  padding-right: 0.5rem;
  font-weight: 600;
`;

const ChipSC = styled(Chip)`
  && {
    background: ${props => props.theme.colors.status.error};
    color: ${props => props.theme.colors.white};
    text-transform: capitalize;
  }
`;

export default function RemainingAssessment({ record }) {
  return (
    <>
      <AssessmentRow key={record?.assessment?.uuid}>
        <Input autoWidth>
          <Tooltip arrow title={`${record?.assessment?.title}`} placement="top">
            <span>{record?.assessment?.title}</span>
          </Tooltip>
        </Input>

        <Input width="50px">--</Input>
        <Input autoWidth>--</Input>
        <Input width="10%">
          <ChipSC size="small" label="Remaining" />
        </Input>
        <Input width="10%">--</Input>
        <Input width="60px">--</Input>
        <Input width="70px">--</Input>
        <Input width="50px">--</Input>
      </AssessmentRow>
    </>
  );
}

RemainingAssessment.propTypes = {
  record: PropTypes.object
};

RemainingAssessment.defaultProps = {
  record: {}
};
