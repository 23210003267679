import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_ACADEMIC_YEAR_SUCCESS,
  GET_ACADEMIC_YEARS_BY_SCHOOL_SUCCESS,
  GET_ACADEMIC_YEAR_SUCCESS,
  GET_COURSES_BY_DEPARTMENT_AY_SUCCESS,
  GET_COURSE_BY_STUDENT_SUCCESS,
  GET_SYLLABUS_COURSE_SUCCESS,
  GET_TERMS_BY_ACADEMIC_YEARS_SUCCESS,
  GET_TERMS_SUCCESS,
  POST_ACADEMIC_YEAR_SUCCESS,
  POST_COURSE_SUCCESS,
  PUT_ACADEMIC_YEAR_SUCCESS
} from '../actions-type';

class Term extends Model {
  static reducer(action, Term, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_TERMS_SUCCESS: {
        const allTerms = [...payload.terms];
        allTerms.forEach(term => Term.upsert(term));
        break;
      }
      case GET_TERMS_BY_ACADEMIC_YEARS_SUCCESS: {
        const allTerms = [...payload.terms];
        allTerms.forEach(term => Term.upsert(term));
        break;
      }
      case GET_ACADEMIC_YEARS_BY_SCHOOL_SUCCESS: {
        const years = [...payload.academicYears];
        years.forEach(year => year.terms.forEach(term => Term.upsert(term)));
        break;
      }
      case GET_ACADEMIC_YEAR_SUCCESS: {
        const { terms } = payload.academicYear;
        terms.forEach(term => Term.upsert(term));
        break;
      }
      case POST_ACADEMIC_YEAR_SUCCESS: {
        const { terms } = payload;
        terms.forEach(term => Term.upsert(term));
        break;
      }
      case PUT_ACADEMIC_YEAR_SUCCESS: {
        const deleteTerms = [...payload.termsRemoval];
        deleteTerms.forEach(term => Term.withId(term.uuid).delete());

        const { terms } = { ...payload.academicYear };
        terms.forEach(term => Term.upsert(term));
        break;
      }
      case DELETE_ACADEMIC_YEAR_SUCCESS: {
        const { academicYearUuid } = payload;

        Term.all()
          .filter(term => term.academicYearUuid === academicYearUuid)
          .delete();

        break;
      }
      case GET_COURSES_BY_DEPARTMENT_AY_SUCCESS: {
        const courses = [...payload.courses];
        courses.forEach(course => {
          Term.upsert(course.term);
        });
        break;
      }
      case POST_COURSE_SUCCESS: {
        const course = { ...payload.course };
        Term.upsert(course.term);
        break;
      }
      case GET_COURSE_BY_STUDENT_SUCCESS: {
        const courses = [...payload.courses];

        if (courses?.length > 0) {
          courses.forEach(course => {
            const { term } = course;
            Term.upsert(term);
          });
        }
        break;
      }
      case GET_SYLLABUS_COURSE_SUCCESS: {
        const course = { ...payload.course };
        const { term } = course;
        Term.upsert(term);
        break;
      }
      default:
        return session;
    }
  }
}

Term.fields = {
  uuid: attr(),
  period: attr(),
  startDate: attr(),
  endDate: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  academicYearUuid: fk({
    to: 'AcademicYear',
    as: 'academic year',
    relatedName: 'terms'
  })
};

Term.modelName = 'Term';
Term.options = {
  idAttribute: 'uuid'
};

export default Term;
