import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';
import SectionPerformanceTable from './SectionPerformanceTable';
import DelayedStudentPerformanceTable from './DelayedStudentPerformanceTable';
import { mapSectionGradeResultsByStudentSectionStatus } from '../../../../../helpers/sections';
import { LoadingContainer } from './PerformanceTable/SharedSC';

const SectionPerformanceTableContainer = ({
  sectionPerformanceReport,
  isLoadingReport,
  parts
}) => {
  const { delayed, active } = mapSectionGradeResultsByStudentSectionStatus(
    sectionPerformanceReport
  );

  return isLoadingReport ? (
    <LoadingContainer>
      <CircularProgress size={100} />
    </LoadingContainer>
  ) : (
    <>
      <SectionPerformanceTable
        sectionPerformanceReport={active}
        isLoadingReport={isLoadingReport}
        parts={parts}
      />
      <DelayedStudentPerformanceTable
        delayedStudentReports={delayed}
        isLoadingReport={isLoadingReport}
        parts={parts}
      />
    </>
  );
};

SectionPerformanceTableContainer.propTypes = {
  sectionPerformanceReport: PropTypes.array,
  isLoadingReport: PropTypes.bool,
  parts: PropTypes.array
};

SectionPerformanceTableContainer.defaultProps = {
  sectionPerformanceReport: [],
  isLoadingReport: false,
  parts: []
};

export default SectionPerformanceTableContainer;
