import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SectionStatusSettings from './SectionStatusSettings';
import { sectionSelector } from '../../../../../redux/selectors/sectionsSelectors';
import { doGetSection } from '../../../../../redux/actions/sectionActions';

export default function SectionStatusSettingsContainer({ match }) {
  const dispatch = useDispatch();
  const { sectionUuid } = match.params;

  const section = useSelector(
    state => sectionSelector(state, sectionUuid),
    _.isEqual
  );

  const course = _.get(section, 'course', null);

  useEffect(() => {
    dispatch(doGetSection(sectionUuid));
  }, [dispatch, sectionUuid]);

  const courseNumber = _.get(course, 'course_master.courseNumber', '');

  const courseTitle = _.get(course, 'course_master.title', '');

  const sectionNumber = _.get(section, 'sectionNumber', '')
    .toString()
    .padStart(2, 0);

  const { statusCommit, statusGrade } = section || {};

  return (
    <SectionStatusSettings
      section={section}
      courseNumber={courseNumber}
      courseTitle={courseTitle}
      sectionNumber={sectionNumber}
      statusCommit={statusCommit}
      statusGrade={statusGrade}
    />
  );
}

SectionStatusSettingsContainer.propTypes = {
  match: PropTypes.object
};

SectionStatusSettingsContainer.defaultProps = {
  match: {}
};
