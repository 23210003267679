import { call, put, takeEvery } from 'redux-saga/effects';
import { logoutUser } from '../../api/logoutApi';
import {
  doLogoutUserSuccess,
  doLogoutUserError
} from '../actions/logoutActions';

import { LOGOUT_USER } from '../actions-type';

function* handleLogoutUser() {
  try {
    const result = yield call(logoutUser);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doLogoutUserSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doLogoutUserError(error));
  }
}

const logout = [takeEvery(LOGOUT_USER, handleLogoutUser)];

export { logout };
