import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const schoolSelector = createSelector(
  state => state,
  (_, schoolUuid) => schoolUuid,
  (redux, schoolUuid) => {
    const schools = ormCreateSelector(orm.School);
    const school = schools(redux, schoolUuid) || {};

    return school;
  }
);

export const schoolsSelector = createSelector(
  state => state,
  redux => {
    const schools = ormCreateSelector(orm.School);
    const schoolsAll = schools(redux);

    return schoolsAll;
  }
);

export const schoolSelectorBySelectedSchoolUuid = createSelector(
  state => state,
  state => state.userState.selectedSchoolUuid,
  (redux, selectedSchoolUuid) => {
    const schools = ormCreateSelector(orm.School);
    const school = schools(redux, selectedSchoolUuid);

    return school;
  }
);

export const selectSchools = ormCreateSelector(orm.School);

export const schoolSelectorByInstitutionUuid = createSelector(
  state => state,
  state => state.userState.institutionUuid,
  (redux, institutionUuid) => {
    const schools = ormCreateSelector(orm.School);
    const school = schools(redux).filter(
      school => school.institutionUuid === institutionUuid
    );

    return school;
  }
);
