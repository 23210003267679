import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFilter } from 'react-search-input';
import ProgramManagement from './ProgramManagement';

import { doGetUserProgramsByProgramsByPrograms } from '../../redux/actions/userProgramActions';
import { doGetSchoolPrograms } from '../../redux/actions/programActions';
import { programsBySchoolStateSelector } from '../../redux/selectors/programSelectors';

const ProgramManagementContainer = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [schoolProgramFetched, setSchoolProgramFetched] = useState(false);
  const [userProgramsFetched, setUserProgramsFetched] = useState(false);
  const programs = useSelector(state => programsBySchoolStateSelector(state));
  const selectedSchoolUuid = useSelector(
    state => state.userState.selectedSchoolUuid
  );

  const searchUpdated = useCallback(event => {
    setSearchTerm(event.target.value);
  }, []);

  const KEYS_TO_FILTERS = useMemo(
    () => ['name', 'programName', 'programAbbreviation'],
    []
  );
  const searchFilter = useMemo(
    () => programs.filter(createFilter(searchTerm, KEYS_TO_FILTERS)),
    [KEYS_TO_FILTERS, programs, searchTerm]
  );

  useEffect(() => {
    if (selectedSchoolUuid && !schoolProgramFetched) {
      dispatch(doGetSchoolPrograms(selectedSchoolUuid));
      setSchoolProgramFetched(true);
    }
    if (programs.length > 0 && !userProgramsFetched) {
      dispatch(doGetUserProgramsByProgramsByPrograms(programs));
      setUserProgramsFetched(true);
    }
  }, [
    selectedSchoolUuid,
    schoolProgramFetched,
    programs,
    userProgramsFetched,
    dispatch
  ]);

  return (
    <ProgramManagement
      programs={searchFilter}
      searchTerm={searchTerm}
      searchUpdated={searchUpdated}
    />
  );
};

export default ProgramManagementContainer;
