import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SyllabusPart from './SyllabusPart';
import { useQuery } from '../../helpers/hooks/router.hooks';
import { doGetSyllabusPart } from '../../redux/actions/syllabusActions';
import { selectPart } from '../../redux/selectors/partsSelectors';

export default function SyllabusPartContainer() {
  const dispatch = useDispatch();
  const query = useQuery();
  const partUuid = query.get('partUuid');
  const userUuid = query.get('userUuid');
  const part = useSelector(state => selectPart(state, partUuid));

  useEffect(() => {
    if (partUuid) {
      dispatch(doGetSyllabusPart(partUuid));
    }
  }, [dispatch, partUuid]);

  return (
    <SyllabusPart
      userUuid={userUuid}
      partUuid={partUuid}
      partTitle={part?.title}
      partNumber={part?.partNumber}
    />
  );
}

SyllabusPartContainer.propTypes = {};

SyllabusPartContainer.defaultProps = {};
