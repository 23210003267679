import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { ButtonInline, Heading2 } from '@xcomp/xcomp-design-library';
import _ from 'lodash';

import { convertNullToString } from '../../../helpers/utilities';
import {
  ContainerResize,
  ContainerPage,
  ContainerContent,
  ElevateLow
} from '../../Library/Layout';

import {
  validateInputString,
  validateSelectField
} from '../../../helpers/validation/validateGeneric';

import Header from '../../Library/Header';
import FormField from '../../Library/FormField';
import FormSelectError from '../../Library/FormSelectError';
import ProgramCip from '../ProgramDegreeCode/ProgramCip';
import ProgramDiscipline from '../ProgramDegreeCode/ProgramDiscipline';
import ProgramCode from '../ProgramDegreeCode/ProgramCode';
import ProgramDegreeCode from '../ProgramDegreeCode/ProgramDegreeCode';
import ProgramDescription from '../ProgramDegreeCode/ProgramDescription';

const ButtonArea = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

const LinkSC = styled(Link)`
  padding-right: 10px;
`;

const Cancel = styled(ButtonInline)`
  && {
    margin-right: 10px;
    color: ${props => props.theme.fontColors.dark};
  }
`;

const ProgramAdd = ({
  programName,
  programAbbreviation,
  degreeAbbreviation,
  degreeName,
  degreeDesignation,
  programCip,
  programDiscipline,
  programCode,
  institutionAA,
  programAA,
  degreeDesignations,
  institutionAAs,
  programAAs,
  handleChange,
  handleCreateProgram,
  hasErrors
}) => {
  const degreeDesignationOptions = _.map(
    degreeDesignations,
    degreeDesignation => ({
      value: degreeDesignation.uuid,
      label: degreeDesignation.degreeLevel
    })
  );

  const institutionAAsOptions = _.map(institutionAAs, institutionAA => ({
    value: institutionAA.uuid,
    label: institutionAA.name
  }));

  const programAAsOptions = _.map(programAAs, programAA => ({
    value: programAA.uuid,
    label: programAA.name
  }));

  return (
    <ContainerResize>
      <Header title="Program Management" subtitle="Add program" />

      <ContainerPage>
        <ElevateLow>
          <ContainerContent>
            <Heading2>Program Details</Heading2>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormField
                  name="programName"
                  label="Program Name*"
                  value={programName}
                  onBlur={handleChange}
                  handleValidate={validateInputString}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={6}>
                <FormField
                  name="programAbbreviation"
                  label="Program Abbreviation*"
                  value={programAbbreviation}
                  onBlur={handleChange}
                  handleValidate={validateInputString}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={6}>
                <FormField
                  name="degreeAbbreviation"
                  label="Degree Abbreviation*"
                  value={degreeAbbreviation}
                  onBlur={handleChange}
                  handleValidate={validateInputString}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={6}>
                <FormField
                  name="degreeName"
                  label="Degree Name*"
                  value={degreeName}
                  onBlur={handleChange}
                  handleValidate={validateInputString}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={4}>
                <FormSelectError
                  handleValidation={validateSelectField}
                  hasErrors={hasErrors}
                  name="degreeDesignation"
                  label="Degree Designation*"
                  value={convertNullToString(degreeDesignation)}
                  handleChange={handleChange}
                  options={degreeDesignationOptions}
                />
              </Grid>
              <Grid item xs={2}>
                <ProgramCip
                  programCip={programCip}
                  hasErrors={hasErrors}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <ProgramDiscipline
                  programCip={programCip}
                  programDiscipline={programDiscipline}
                  hasErrors={hasErrors}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <ProgramCode
                  programDiscipline={programDiscipline}
                  programCode={programCode}
                  hasErrors={hasErrors}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <ProgramDegreeCode
                  programCip={programCip}
                  programDiscipline={programDiscipline}
                  programCode={programCode}
                />
              </Grid>
              <Grid item xs={12}>
                <ProgramDescription programCode={programCode} />
              </Grid>
              <Grid item xs={6}>
                <FormSelectError
                  name="institutionAA"
                  label="Institution Accrediting Agency*"
                  handleValidation={validateSelectField}
                  hasErrors={hasErrors}
                  value={convertNullToString(institutionAA)}
                  handleChange={handleChange}
                  options={institutionAAsOptions}
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelectError
                  name="report"
                  label="Report Disabled"
                  readOnly={true}
                  value=""
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelectError
                  handleValidation={validateSelectField}
                  hasErrors={hasErrors}
                  name="programAA"
                  label="Program Accrediting Agency*"
                  value={convertNullToString(programAA)}
                  handleChange={handleChange}
                  options={programAAsOptions}
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelectError
                  name="report"
                  label="Report Disabled"
                  readOnly={true}
                  value=""
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonArea>
                <LinkSC to="/school-management/programs">
                  <Cancel>CANCEL</Cancel>
                </LinkSC>
                <ButtonInline
                  onClick={() => {
                    handleCreateProgram();
                  }}
                >
                  SAVE
                </ButtonInline>
              </ButtonArea>
            </Grid>
          </ContainerContent>
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
};

ProgramAdd.propTypes = {
  programName: PropTypes.string,
  programAbbreviation: PropTypes.string,
  degreeAbbreviation: PropTypes.string,
  degreeName: PropTypes.string,
  programCip: PropTypes.string,
  programDiscipline: PropTypes.string,
  programCode: PropTypes.string,
  degreeDesignation: PropTypes.string,
  institutionAA: PropTypes.string,
  programAA: PropTypes.string,
  degreeDesignations: PropTypes.array,
  institutionAAs: PropTypes.array,
  programAAs: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  handleCreateProgram: PropTypes.func
};
ProgramAdd.defaultProps = {
  programName: '',
  programAbbreviation: '',
  degreeAbbreviation: '',
  degreeName: '',
  programCip: '',
  programDiscipline: '',
  programCode: '',
  degreeDesignation: '',
  institutionAA: '',
  programAA: '',
  degreeDesignations: [],
  institutionAAs: [],
  programAAs: [],
  hasErrors: false,
  handleChange: undefined,
  handleCreateProgram: undefined
};

export default ProgramAdd;
