import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import FormControl from '@material-ui/core/FormControl';
import { Checkbox, FormGroup, TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Thresholds from '../Thresholds';

const CheckboxContainer = styled.div`
  color: ${props => props.theme.fontColors.dark};
  margin-bottom: 0.5rem;
`;

const QuantitativeSkillsCollectionForm = ({
  collection,
  nameLabel,
  isDefaultLabel,
  handleChange
}) => {
  return (
    <>
      <TextField
        id="outlined-error"
        label={nameLabel}
        name="name"
        variant="outlined"
        value={collection.name}
        error={collection.name === '' || collection.name.length > 100}
        helperText={
          collection.name === '' || collection.name.length > 100
            ? 'Name is required and must be less than 100 characters'
            : ''
        }
        onChange={handleChange}
        autoFocus
        fullWidth
      />

      <FormControl
        component="div"
        style={{ width: '100%', marginBottom: '20px' }}
      >
        <FormGroup>
          <CheckboxContainer>
            <FormControlLabel
              control={
                <Checkbox
                  name="is_default_collection"
                  onChange={handleChange}
                  checked={collection.is_default_collection}
                  color="primary"
                />
              }
              label={isDefaultLabel}
            />
          </CheckboxContainer>
        </FormGroup>
        <Thresholds handleChange={handleChange} thresholds={collection} />
      </FormControl>
    </>
  );
};

QuantitativeSkillsCollectionForm.propTypes = {
  collection: PropTypes.object,
  nameLabel: PropTypes.string,
  isDefaultLabel: PropTypes.string,
  handleChange: PropTypes.func
};

QuantitativeSkillsCollectionForm.defaultProps = {
  collection: {},
  nameLabel: 'Quantitative Skills Collection Name',
  isDefaultLabel: 'Set as Default Collection',
  handleChange: () => {}
};

export default QuantitativeSkillsCollectionForm;
