import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import NotFoundIllustration from '@xcomp/xcomp-design-library/dist/illustrations/custom/NotFoundIllustration';

const NotFoundWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;

  & > p {
    font-size: 22px;
    font-weight: 500;
  }
`;

const NoStudentsFound = ({ isLoading }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <NotFoundWrapper>
      {!isLoading ? (
        <>
          <NotFoundIllustration
            dotsColor={themeContext.colors.primaryShades[450]}
            bgColor={themeContext.colors.primaryShades[200]}
            outlineColor={themeContext.colors.primary}
          />
          <p>
            There were no students and/or assessments found for this cohort.
            Please refresh or try again later.
          </p>
        </>
      ) : (
        <CircularProgress size="100px" />
      )}
    </NotFoundWrapper>
  );
};

NoStudentsFound.propTypes = {
  isLoading: PropTypes.bool.isRequired
};
export default NoStudentsFound;
