import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import CohortRoles from './CohortRoles';
import { useQuery } from '../../../helpers/hooks/router.hooks';
import { doGetUserCohorts } from '../../../redux/actions/userCohortActions';
import { doGetInstitutionIdTypes } from '../../../redux/actions/sourceActions';

export default function CohortRolesContainer({ match, history }) {
  const dispatch = useDispatch();
  const { path } = match;
  const { pathname } = history.location;

  const query = useQuery();
  const cohortUuid = query.get('cohortUuid');
  const programUuid = query.get('programUuid');

  useEffect(() => {
    if (cohortUuid) {
      dispatch(doGetInstitutionIdTypes());
      dispatch(doGetUserCohorts(cohortUuid));
    }
  }, [dispatch, cohortUuid]);

  return (
    <CohortRoles
      path={path}
      pathname={pathname}
      cohortUuid={cohortUuid}
      programUuid={programUuid}
    />
  );
}

CohortRolesContainer.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
};

CohortRolesContainer.defaultProps = {
  match: {},
  history: {}
};
