import {
  GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT,
  GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT_ERROR,
  GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT_SUCCESS,
  GET_SECTION_PERFORMANCE_REPORT,
  GET_SECTION_PERFORMANCE_REPORT_ERROR,
  GET_SECTION_PERFORMANCE_REPORT_SUCCESS
} from '../actions-type/sectionPerformanceReportActionTypes';

const doGetSectionPerformanceReport = sectionUuid => ({
  type: GET_SECTION_PERFORMANCE_REPORT,
  payload: { sectionUuid }
});

const doGetSectionPerformanceReportError = error => ({
  type: GET_SECTION_PERFORMANCE_REPORT_ERROR,
  payload: error
});

const doGetSectionPerformanceReportSuccess = result => ({
  type: GET_SECTION_PERFORMANCE_REPORT_SUCCESS,
  payload: result
});

const doGetSectionPerformanceOpportunityReport = ({
  sectionUuid,
  assessmentTypes
}) => ({
  type: GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT,
  payload: { sectionUuid, assessmentTypes }
});

const doGetSectionPerformanceOpportunityReportError = error => ({
  type: GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT_ERROR,
  payload: error
});

const doGetSectionPerformanceOpportunityReportSuccess = result => ({
  type: GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT_SUCCESS,
  payload: result
});

export {
  doGetSectionPerformanceReport,
  doGetSectionPerformanceReportError,
  doGetSectionPerformanceReportSuccess,
  doGetSectionPerformanceOpportunityReport,
  doGetSectionPerformanceOpportunityReportError,
  doGetSectionPerformanceOpportunityReportSuccess
};
