import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { doPostCompetency } from '../../../../redux/actions/competencyActions';
import { doClearCreated } from '../../../../redux/actions/competencyCollectionActions';
import {
  allFieldsAreValid,
  validateStringLength
} from '../../../../helpers/validation/validateGeneric';
import { validateCompetencyDescription } from '../../../../helpers/validation/validateCompetencyCollection';
import CompetencyDetailsForm from '../Form/Sections/CompetencyDetailsForm';
import {
  initialCompetencyState,
  competencyReducer,
  setShowValidationErrors
} from './competencyState';

const NewCompetencyContainer = ({
  competencyCollection,
  competencyNumber,
  onFormClose,
  unsavedChanges,
  setUnsavedChange
}) => {
  const dispatch = useDispatch();

  const [state, newDispatch] = useReducer(
    competencyReducer,
    initialCompetencyState
  );

  const successfullyCreatedCompetency = useSelector(
    state => state.formSuccessState.successfullyCreatedCompetency
  );

  const { name, description, showValidationErrors } = state;

  const onPostCreate = newCompetency =>
    dispatch(doPostCompetency(newCompetency));

  useEffect(() => {
    newDispatch({
      type: 'clearForm'
    });
  }, []);

  useEffect(() => {
    const onClearCreated = () => dispatch(doClearCreated());

    if (successfullyCreatedCompetency === true) {
      onClearCreated();
      newDispatch({
        type: 'clearForm'
      });
      onFormClose();
    }
  }, [onFormClose, successfullyCreatedCompetency, dispatch, newDispatch]);

  const setValidationErrors = newCompetency => {
    const nameError = validateStringLength(newCompetency.name, 100, true);
    const descriptionError = validateCompetencyDescription(
      newCompetency.description,
      100,
      50000,
      true
    );

    const newValidationErrors = {
      nameError,
      descriptionError
    };

    return newValidationErrors;
  };

  const buildPayload = (name, description, competencyCollectionUuid) => {
    return {
      name,
      description,
      competencyCollectionUuid
    };
  };

  const handleCreateSubmission = () => {
    const newValidationErrors = setValidationErrors({
      name,
      description
    });

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = buildPayload(
        name,
        description,
        competencyCollection.uuid
      );

      onPostCreate(payload);
      newDispatch(setShowValidationErrors(false));
    } else {
      newDispatch(setShowValidationErrors(true));
    }
  };

  return (
    <CompetencyDetailsForm
      uuid="newCompetency"
      name={name}
      description={description}
      unsavedChanges={unsavedChanges}
      index={competencyNumber}
      formDispatch={newDispatch}
      onFormClose={onFormClose}
      setUnsavedChange={setUnsavedChange}
      onSubmit={handleCreateSubmission}
      buttonText="Create Competency"
      showValidationErrors={showValidationErrors}
    />
  );
};

export default NewCompetencyContainer;

NewCompetencyContainer.propTypes = {
  competencyCollection: PropTypes.object,
  competencyNumber: PropTypes.number,
  unsavedChanges: PropTypes.object,
  setUnsavedChange: PropTypes.func,
  onFormClose: PropTypes.func
};

NewCompetencyContainer.defaultProps = {
  competencyCollection: { uuid: '' },
  competencyNumber: 1,
  unsavedChanges: {},
  setUnsavedChange: undefined,
  onFormClose: undefined
};
