import React, { useEffect, useReducer, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import CredentialEdit from './CredentialEdit';
import { credentialReducer, credentialInitial } from './credentialState';
import { degreeLevelsSelector } from '../../../../../redux/selectors/degreeLevelsSelectors';
import {
  validateInputString,
  validateSelectDate,
  validateSelectField
} from '../../../../../helpers/validation/validateGeneric';

import {
  doPutCredential,
  doDeleteCredential
} from '../../../../../redux/actions/credentialActions';
import DeleteModal from '../../../../Library/Modal/DeleteModal/DeleteModal';
import { selectCredential } from '../../../../../redux/selectors/credentialSelectors';

const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const Layout = styled.div`
  margin-bottom: 60px;
`;

export default function CredentialEditContainer({
  credentialEditing,
  credentialUuid,
  handleCredentialEditing
}) {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [credentialState, credentialDispatch] = useReducer(
    credentialReducer,
    credentialInitial
  );

  const degreeLevelOptions = useSelector(state =>
    degreeLevelsSelector(state)
  ).map(level => ({ label: level.degreeLevel, value: level.uuid }));

  const {
    uuid,
    globalUserUuid,
    year,
    credential,
    degreeLevelUuid,
    institutionUuid,
    programCodeUuid
  } = credentialState;

  const updateCredential = useSelector(
    state => state.crudState.updateCredential,
    _.isEqual
  );

  const updateCredentialResult = useSelector(
    state => state.crudState.updateCredentialResult,
    _.isEqual
  );

  const credentialExist = useSelector(
    state => selectCredential(state, credentialUuid),
    _.isEqual
  );

  useEffect(() => {
    if (credentialExist) {
      credentialDispatch({
        type: 'exist',
        credential: credentialExist
      });
    }
  }, [credentialExist]);

  useEffect(() => {
    if (updateCredentialResult === 'success') {
      setEditMode(false);
      handleCredentialEditing(false);
      dispatch({ type: 'PUT_CREDENTIAL_RESET' });
    }
  }, [dispatch, updateCredentialResult, handleCredentialEditing]);

  const handleChangeDate = (_, field, date) => {
    credentialDispatch({ type: field, date });
  };

  const handleChange = event => {
    const { name, value } = event.target;
    credentialDispatch({ type: name, value });
  };

  const handleSourceInstitutionUuid = institution => {
    const institutionUuid = institution?.uuid;
    const institutionCode = institution?.institutionCode;

    credentialDispatch({
      type: 'institutionUuid',
      institutionUuid,
      institutionCode
    });
  };

  const handleProgramCodeUuid = programCodeUuid => {
    credentialDispatch({ type: 'programCodeUuid', programCodeUuid });
  };

  const _validate = () => {
    let containErrors = false;

    const yearError = validateSelectDate(year);
    const degreeLevelUuidError = validateSelectField(degreeLevelUuid);
    const credentialError = validateInputString(credential);
    const institutionUuidError = validateInputString(institutionUuid);

    const programCodeUuidError = validateInputString(programCodeUuid);

    if (
      yearError.invalid ||
      degreeLevelUuidError.invalid ||
      credentialError.invalid ||
      institutionUuidError.invalid ||
      programCodeUuidError.invalid
    ) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleSaveCredential = () => {
    const check = _validate();

    if (check) {
      setHasErrors(true);
    } else {
      const payload = {
        uuid,
        globalUserUuid,
        year: moment(year).format('YYYY'),
        credential,
        degreeLevelUuid,
        institutionUuid,
        programCodeUuid
      };
      dispatch(doPutCredential(payload));
    }
  };

  const handleEdit = () => {
    setEditMode(true);
    handleCredentialEditing(true);
  };

  const handleEditCancel = () => {
    setEditMode(false);
    handleCredentialEditing(false);
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };

  const handleDeletion = () => {
    dispatch(doDeleteCredential(credentialUuid));
    setDeleteModal(false);
  };

  return (
    <Layout>
      <CredentialEdit
        credentialEditing={credentialEditing}
        editMode={editMode}
        submit={updateCredential}
        result={updateCredentialResult}
        degreeLevelUuid={degreeLevelUuid}
        year={year}
        uuid={uuid}
        credential={credential}
        institutionUuid={institutionUuid}
        programCodeUuid={programCodeUuid}
        degreeLevelOptions={degreeLevelOptions}
        hasErrors={hasErrors}
        handleChange={handleChange}
        handleChangeDate={handleChangeDate}
        handleSourceInstitutionUuid={handleSourceInstitutionUuid}
        handleProgramCodeUuid={handleProgramCodeUuid}
        handleClose={handleEditCancel}
        handleSave={handleSaveCredential}
        handleDeletion={handleDeletion}
        handleDeleteModalOpen={handleDeleteModalOpen}
      />
      {!editMode && (
        <ButtonArea>
          <ButtonPrimary disabled={credentialEditing} onClick={handleEdit}>
            Edit
          </ButtonPrimary>
        </ButtonArea>
      )}
      <DeleteModal
        modalOpen={deleteModal}
        handleModalClose={handleDeleteModalClose}
        handleDelete={handleDeletion}
        type="Credential"
      />
    </Layout>
  );
}

CredentialEditContainer.propTypes = {
  credentialEditing: PropTypes.bool,
  credentialUuid: PropTypes.string,

  handleCredentialEditing: PropTypes.func
};

CredentialEditContainer.defaultProps = {
  credentialEditing: false,
  credentialUuid: undefined,

  handleCredentialEditing: undefined
};
