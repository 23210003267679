import {
  FILTER_COURSE_MASTER_DEPARTMENT_UUID,
  SET_CURRENT_ROLE
} from '../actions-type';

const INITIAL_STATE = {
  departmentUuid: ''
};

const filterCourseMasterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_ROLE: {
      return {
        ...state,
        departmentUuid: ''
      };
    }
    case FILTER_COURSE_MASTER_DEPARTMENT_UUID: {
      return {
        ...state,
        departmentUuid: action.payload.departmentUuid
      };
    }
    default:
      return state;
  }
};

export default filterCourseMasterReducer;

export { INITIAL_STATE };
