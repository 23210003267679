import React, { useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import CourseManagement from './CourseManagement';

import { doSetDepartmentFilterForCourseMaster } from '../../redux/actions/filterActions';
import { doGetSchoolDepartments } from '../../redux/actions/departmentActions';
import { doGetCoursesMasterByDepartment } from '../../redux/actions/courseMasterActions';
import { departmentsBySchoolStateSelector } from '../../redux/selectors/departmentsSelectors';
import { coursesMasterSelectorByDepartmentInCourseMasterFilter } from '../../redux/selectors/coursesMasterSelectors';

export default function CourseManagementContainer() {
  const dispatch = useDispatch();
  const filteredCoursesMaster = useSelector(
    state => coursesMasterSelectorByDepartmentInCourseMasterFilter(state),
    shallowEqual
  );

  const departmentUuid = useSelector(
    state => state.filterCourseMasterState.departmentUuid
  );

  const optionsDepartment = useSelector(
    state => departmentsBySchoolStateSelector(state),
    _.isEqual
  ).map(department => ({
    value: department.uuid,
    label: department.name
  }));

  const selectedSchoolUuid = useSelector(
    state => state.userState.selectedSchoolUuid
  );

  useEffect(() => {
    dispatch(doGetSchoolDepartments(selectedSchoolUuid));
  }, [dispatch, selectedSchoolUuid]);

  useEffect(() => {
    if (departmentUuid && departmentUuid !== '') {
      dispatch(doGetCoursesMasterByDepartment(departmentUuid));
    }
  }, [dispatch, departmentUuid]);

  function selectFilter(event) {
    const filterType = event.target.name;
    const filterValue = event.target.value;

    if (filterType === 'department') {
      dispatch(doSetDepartmentFilterForCourseMaster(filterValue));
      dispatch(doGetCoursesMasterByDepartment(filterValue));
    }
  }

  return (
    <CourseManagement
      departmentUuid={departmentUuid}
      optionsDepartment={optionsDepartment}
      filteredCoursesMaster={filteredCoursesMaster}
      selectFilter={event => selectFilter(event)}
    />
  );
}
