import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Caption,
  ButtonIcon,
  lightenDarkenColor
} from '@xcomp/xcomp-design-library';
import RemoveIcon from '@xcomp/xcomp-design-library/dist/icons/actions/RemoveIcon';

const getTopicCodeString = topic => {
  return topic
    ? `${topic.discipline.cipcode.code}.${topic.discipline.code}.${topic.level_one_code}${topic.level_two_code}`
    : '';
};

const PillLayout = styled(({ isClickable, ...rest }) => <div {...rest} />)`
  background: ${props => props.theme.colors.primary};
  padding: 4px 16px;
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${props => props.theme.boxShadows.low};
  margin-right: 1rem;
  margin-bottom: 0.5rem;

  ${props =>
    props.isClickable &&
    css`
      &:hover,
      &:focus,
      &:active {
        cursor: pointer;
        background: ${props =>
          lightenDarkenColor(props.theme.colors.primary, -50)};
      }
    `}
`;

const CaptionSC = styled(Caption)`
  && {
    color: ${props => props.theme.colors.white};
    padding: 0;
    margin: 0;
  }
`;

const RemoveTopicIcon = styled(ButtonIcon)`
  && {
    margin-left: 8px;

    svg {
      fill: ${props => props.theme.colors.white};
      width: 17px;
    }

    &:hover,
    &:focus,
    &:active {
      svg {
        fill: ${props => props.theme.colors.white};
      }
    }
  }
`;

const SelectedMicrosPill = ({ topic, microsMap, onDelete, onClick }) => {
  const handleDelete = e => {
    e.stopPropagation();
    onDelete();
  };
  return (
    <PillLayout onClick={onClick} isClickable={Boolean(onClick)}>
      <CaptionSC>
        {Object.keys(microsMap).length} selected - Topic&nbsp;
        {getTopicCodeString(topic)} {topic.title}
      </CaptionSC>
      {onDelete && (
        <RemoveTopicIcon onClick={handleDelete} noHoverBg>
          <RemoveIcon />
        </RemoveTopicIcon>
      )}
    </PillLayout>
  );
};

SelectedMicrosPill.propTypes = {
  topic: PropTypes.object,
  microsMap: PropTypes.object,
  onDelete: PropTypes.func,
  onClick: PropTypes.func
};

SelectedMicrosPill.defaultProps = {
  topic: {},
  microsMap: {},
  onDelete: undefined,
  onClick: undefined
};

export default SelectedMicrosPill;
