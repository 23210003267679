export const GET_TRANSCRIPT_ERROR = 'GET_TRANSCRIPT_ERROR';
export const GET_TRANSCRIPT_SUCCESS = 'GET_TRANSCRIPT_SUCCESS';
export const GET_TRANSCRIPT = 'GET_TRANSCRIPT';

export const POST_TRANSCRIPT_UPLOAD = 'POST_TRANSCRIPT_UPLOAD';
export const POST_TRANSCRIPT_UPLOAD_SUCCESS = 'POST_TRANSCRIPT_UPLOAD_SUCCESS';
export const POST_TRANSCRIPT_UPLOAD_ERROR = 'POST_TRANSCRIPT_UPLOAD_ERROR';

export const POST_TRANSCRIPT_ERROR = 'POST_TRANSCRIPT_ERROR';
export const POST_TRANSCRIPT_SUCCESS = 'POST_TRANSCRIPT_SUCCESS';
export const POST_TRANSCRIPT = 'POST_TRANSCRIPT';
export const POST_TRANSCRIPT_RESET = 'POST_TRANSCRIPT_RESET';

export const PUT_TRANSCRIPT = 'PUT_TRANSCRIPT';
export const PUT_TRANSCRIPT_SUCCESS = 'PUT_TRANSCRIPT_SUCCESS';
export const PUT_TRANSCRIPT_ERROR = 'PUT_TRANSCRIPT_ERROR';
export const PUT_TRANSCRIPT_RESET = 'PUT_TRANSCRIPT_RESET';

export const DELETE_TRANSCRIPT = 'DELETE_TRANSCRIPT';
export const DELETE_TRANSCRIPT_SUCCESS = 'DELETE_TRANSCRIPT_SUCCESS';
export const DELETE_TRANSCRIPT_ERROR = 'DELETE_TRANSCRIPT_ERROR';

export const DELETE_TRANSCRIPTS = 'DELETE_TRANSCRIPTS';
export const DELETE_TRANSCRIPTS_SUCCESS = 'DELETE_TRANSCRIPTS_SUCCESS';
export const DELETE_TRANSCRIPTS_ERROR = 'DELETE_TRANSCRIPTS_ERROR';

export const POST_CLONE_TRANSCRIPT = 'POST_CLONE_TRANSCRIPT';
export const POST_CLONE_TRANSCRIPT_SUCCESS = 'POST_CLONE_TRANSCRIPT_SUCCESS';
export const POST_CLONE_TRANSCRIPT_ERROR = 'POST_CLONE_TRANSCRIPT_ERROR';
export const POST_CLONE_TRANSCRIPT_RESET = 'POST_CLONE_TRANSCRIPT_RESET';
