import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Button } from '@material-ui/core';
import { Heading2 } from '@xcomp/xcomp-design-library';
import { Panel, PanelHeader } from '../../../Library/Layout';
import FormSelectError from '../../../Library/FormSelectError';
import { validateSelectField } from '../../../../helpers/validation/validateGeneric';

const FilterContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
`;

const FilterBody = styled(Grid)`
  padding-left: 30px;
`;

const PanelHeaderSC = styled(PanelHeader)`
  padding-left: 32px;
`;

const ButtonSC = styled(Button)`
  && {
    margin-bottom: 16px;
  }
`;

const CohortApplicantCognitiveFilters = ({
  transcriptScope,
  transcriptScopeDisabled,
  transcriptScopeOptions,
  transcriptSource,
  transcriptSourceOptions,
  transcriptRankBy,
  transcriptRankByOptions,
  transcriptRankByDisabled,
  transcriptReportTypeError,
  onSelectTranscriptRankBy,
  onApplyReportType,
  onSelectTranscriptScope,
  onSelectTranscriptSource
}) => {
  return (
    <Panel>
      <PanelHeaderSC>
        <Heading2>Cognitive Filters</Heading2>
      </PanelHeaderSC>
      <FilterContainer>
        <FilterBody container spacing={2}>
          <Grid item sm={4}>
            <FormSelectError
              label="Transcript Source"
              name="transcriptSourceSelect"
              options={transcriptSourceOptions}
              value={transcriptSource}
              handleChange={onSelectTranscriptSource}
              hasErrors={transcriptReportTypeError}
              handleValidation={validateSelectField}
            />
          </Grid>
          <Grid item sm={4}>
            <FormSelectError
              label="Subject"
              name="filterBySelect"
              value={transcriptScope}
              options={transcriptScopeOptions}
              handleChange={onSelectTranscriptScope}
              disabled={transcriptScopeDisabled}
              handleValidation={validateSelectField}
            />
          </Grid>
          <Grid item sm={4}>
            <FormSelectError
              label="View By"
              name="filterBySelect"
              options={transcriptRankByOptions}
              value={transcriptRankBy}
              handleChange={onSelectTranscriptRankBy}
              disabled={transcriptRankByDisabled}
              handleValidation={validateSelectField}
            />
          </Grid>
        </FilterBody>
        <Grid container justifyContent="flex-end" alignItems="center">
          <ButtonSC
            color="primary"
            variant="contained"
            onClick={onApplyReportType}
          >
            Apply
          </ButtonSC>
        </Grid>
      </FilterContainer>
    </Panel>
  );
};

CohortApplicantCognitiveFilters.propTypes = {
  transcriptScope: PropTypes.string,
  transcriptScopeDisabled: PropTypes.bool,
  transcriptScopeOptions: PropTypes.array,
  transcriptSource: PropTypes.string,
  transcriptSourceOptions: PropTypes.array,
  onApplyReportType: PropTypes.func,
  onSelectTranscriptScope: PropTypes.func,
  onSelectTranscriptSource: PropTypes.func,
  transcriptRankBy: PropTypes.string,
  transcriptRankByOptions: PropTypes.array,
  transcriptRankByDisabled: PropTypes.bool,
  transcriptReportTypeError: PropTypes.bool,
  onSelectTranscriptRankBy: PropTypes.func
};
CohortApplicantCognitiveFilters.defaultProps = {
  transcriptScope: '',
  transcriptScopeDisabled: true,
  transcriptScopeOptions: [],
  transcriptSource: '',
  transcriptSourceOptions: [],
  onApplyReportType: undefined,
  onSelectTranscriptScope: undefined,
  onSelectTranscriptSource: undefined,
  transcriptRankBy: '',
  transcriptRankByOptions: [],
  transcriptRankByDisabled: true,
  transcriptReportTypeError: false,
  onSelectTranscriptRankBy: undefined
};
export default CohortApplicantCognitiveFilters;
