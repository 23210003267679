import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { selectConversionAptitude } from '../../../../../redux/selectors/conversionAptitudeSelectors';

export default function ExamScore({ conversionAptitudeUuid }) {
  const { scaledScore } = useSelector(
    state => selectConversionAptitude(state, conversionAptitudeUuid),
    shallowEqual
  );

  return scaledScore;
}

ExamScore.propTypes = { conversionAptitudeUuid: PropTypes.string };
ExamScore.defaultProps = { conversionAptitudeUuid: undefined };
