import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import { orm } from '../models';

export const selectTranscriptAptitudeUuid = createSelector(
  state => state,
  (_, category, uuid) => ({
    category,
    uuid
  }),
  (redux, paramaters) => {
    const { category, uuid } = paramaters;

    switch (category) {
      case 'Non-Cognitive': {
        const getTranscript = ormCreateSelector(orm.TranscriptNonCognitive);
        return getTranscript(redux, uuid);
      }
      case 'Physical-Skills': {
        const getTranscript = ormCreateSelector(orm.TranscriptPhysicalSkills);
        return getTranscript(redux, uuid);
      }
      case 'Communication': {
        const getTranscript = ormCreateSelector(orm.TranscriptCommunication);
        return getTranscript(redux, uuid);
      }
      default: {
        return undefined;
      }
    }
  }
);

export const selectOverallAptitudeAvgScore = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    let getTranscript;
    switch (category) {
      case 'Non-Cognitive': {
        getTranscript = ormCreateSelector(orm.TranscriptNonCognitive);
        break;
      }
      case 'Physical-Skills': {
        getTranscript = ormCreateSelector(orm.TranscriptPhysicalSkills);
        break;
      }
      case 'Communication': {
        getTranscript = ormCreateSelector(orm.TranscriptCommunication);
        break;
      }
      default:
        break;
    }

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category
    );

    const exam = ormCreateSelector(orm.ExamAptitude);

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalWeight = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const { weight } = exam(redux, transcript.examAptitudeUuid);
          const weightDecimal = new Decimal(weight);

          return total.add(weightDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const { weight } = exam(redux, transcript.examAptitudeUuid);
          const weightDecimal = new Decimal(weight);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = weightDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalWeight)
        .toDecimalPlaces(2)
        .toString();

      return totalPercentage;
    } else {
      return undefined;
    }
  }
);

export const selectOverallAptitudeWeight = createSelector(
  state => state,
  (_, programUuid, category) => ({
    programUuid,
    category
  }),
  (redux, paramaters) => {
    const { programUuid, category } = paramaters;

    const getClassifications = ormCreateSelector(orm.Classification);

    const getExamAptitudes = ormCreateSelector(
      orm.Classification.exam_aptitudes
    );

    const classifications = getClassifications(redux).filter(
      classification =>
        classification.programUuid === programUuid &&
        classification.category === category
    );

    const examsDefined = [];

    if (classifications?.length > 0) {
      classifications.forEach(classification => {
        const exam_aptitudes = getExamAptitudes(redux, classification.uuid);

        if (exam_aptitudes?.length > 0) {
          examsDefined.push(exam_aptitudes);
        }
      });
    }

    if (examsDefined?.length > 0) {
      const totalWeight = _.reduce(
        _.flatten(examsDefined),
        (total, examAptitdue) => {
          const { weight } = examAptitdue;
          const weightDecimal = new Decimal(weight);
          return total.add(weightDecimal);
        },
        new Decimal(0)
      );

      return totalWeight.toDecimalPlaces(2).toString();
    } else {
      return undefined;
    }
  }
);

export const selectOverallAptitudeGrade = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    let getTranscript;
    switch (category) {
      case 'Non-Cognitive': {
        getTranscript = ormCreateSelector(orm.TranscriptNonCognitive);
        break;
      }
      case 'Physical-Skills': {
        getTranscript = ormCreateSelector(orm.TranscriptPhysicalSkills);
        break;
      }
      case 'Communication': {
        getTranscript = ormCreateSelector(orm.TranscriptCommunication);
        break;
      }
      default:
        break;
    }

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category
    );

    const exam = ormCreateSelector(orm.ExamAptitude);

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalWeight = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const { weight } = exam(redux, transcript.examAptitudeUuid);
          const weightDecimal = new Decimal(weight);

          return total.add(weightDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const { weight } = exam(redux, transcript.examAptitudeUuid);
          const weightDecimal = new Decimal(weight);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = weightDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore.div(totalWeight);

      const findGrade = _.find(letterGrades, schoolGrade => {
        const low = new Decimal(schoolGrade.rangeLow);
        const high = new Decimal(schoolGrade.rangeHigh);

        const conditionOne = totalPercentage.greaterThanOrEqualTo(low);
        const conditionTwo = totalPercentage.lessThanOrEqualTo(high);

        return conditionOne && conditionTwo;
      });

      return findGrade?.grade;
    } else {
      return undefined;
    }
  }
);

export const selectAptitudeCompletion = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category } = paramaters;

    const getClassifications = ormCreateSelector(orm.Classification);

    const getExamAptitudes = ormCreateSelector(
      orm.Classification.exam_aptitudes
    );

    const classifications = getClassifications(redux).filter(
      classification =>
        classification.programUuid === programUuid &&
        classification.category === category
    );

    const examsDefined = [];

    if (classifications?.length > 0) {
      classifications.forEach(classification => {
        const exam_aptitudes = getExamAptitudes(redux, classification.uuid);

        if (exam_aptitudes?.length > 0) {
          examsDefined.push(exam_aptitudes);
        }
      });
    }

    const exams_defined = _.flatten(examsDefined);

    let getTranscript;
    switch (category) {
      case 'Non-Cognitive': {
        getTranscript = ormCreateSelector(orm.TranscriptNonCognitive);
        break;
      }
      case 'Physical-Skills': {
        getTranscript = ormCreateSelector(orm.TranscriptPhysicalSkills);
        break;
      }
      case 'Communication': {
        getTranscript = ormCreateSelector(orm.TranscriptCommunication);
        break;
      }
      default:
        break;
    }
    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category
    );

    if (transcriptRecords?.length > 0) {
      const ids = _(transcriptRecords)
        .map(transcript => transcript.examAptitudeUuid)
        .uniq()
        .value();

      if (ids?.length === exams_defined?.length) {
        return 'Complete';
      } else if (ids?.length === 0 && exams_defined?.length > 0) {
        return 'Empty';
      } else {
        return 'Incomplete';
      }
    } else {
      return 'Incomplete';
    }
  }
);

export const selectAptitudeGradeBySourceSkill = createSelector(
  state => state,
  (
    _,
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    classificationUuid
  ) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    classificationUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      classificationUuid
    } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    let getTranscript;
    switch (category) {
      case 'Non-Cognitive': {
        getTranscript = ormCreateSelector(orm.TranscriptNonCognitive);
        break;
      }
      case 'Physical-Skills': {
        getTranscript = ormCreateSelector(orm.TranscriptPhysicalSkills);
        break;
      }
      case 'Communication': {
        getTranscript = ormCreateSelector(orm.TranscriptCommunication);
        break;
      }
      default:
        break;
    }

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source &&
        tc.classificationUuid === classificationUuid
    );

    const exam = ormCreateSelector(orm.ExamAptitude);

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalWeight = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const { weight } = exam(redux, transcript.examAptitudeUuid);
          const weightDecimal = new Decimal(weight);

          return total.add(weightDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const { weight } = exam(redux, transcript.examAptitudeUuid);
          const weightDecimal = new Decimal(weight);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = weightDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore.div(totalWeight);

      const findGrade = _.find(letterGrades, schoolGrade => {
        const low = new Decimal(schoolGrade.rangeLow);
        const high = new Decimal(schoolGrade.rangeHigh);

        const conditionOne = totalPercentage.greaterThanOrEqualTo(low);
        const conditionTwo = totalPercentage.lessThanOrEqualTo(high);

        return conditionOne && conditionTwo;
      });

      return findGrade?.grade;
    } else {
      return undefined;
    }
  }
);

export const selectAptitudeScoreBySourceSkill = createSelector(
  state => state,
  (
    _,
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    classificationUuid
  ) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    classificationUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      classificationUuid
    } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    let getTranscript;
    switch (category) {
      case 'Non-Cognitive': {
        getTranscript = ormCreateSelector(orm.TranscriptNonCognitive);
        break;
      }
      case 'Physical-Skills': {
        getTranscript = ormCreateSelector(orm.TranscriptPhysicalSkills);
        break;
      }
      case 'Communication': {
        getTranscript = ormCreateSelector(orm.TranscriptCommunication);
        break;
      }
      default:
        break;
    }

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.source === source &&
        tc.classificationUuid === classificationUuid
    );

    const exam = ormCreateSelector(orm.ExamAptitude);

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalWeight = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const { weight } = exam(redux, transcript.examAptitudeUuid);
          const weightDecimal = new Decimal(weight);

          return total.add(weightDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const { weight } = exam(redux, transcript.examAptitudeUuid);
          const weightDecimal = new Decimal(weight);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = weightDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalWeight)
        .toDecimalPlaces(2)
        .toString();

      return totalPercentage;
    } else {
      return undefined;
    }
  }
);

export const selectAptitudeWeightBySourceSkill = createSelector(
  state => state,
  (_, programUuid, category, source, classificationUuid) => ({
    programUuid,
    category,
    source,
    classificationUuid
  }),
  (redux, paramaters) => {
    const { programUuid, category, source, classificationUuid } = paramaters;

    const getClassifications = ormCreateSelector(orm.Classification);

    const getExamAptitudes = ormCreateSelector(
      orm.Classification.exam_aptitudes
    );

    const classifications = getClassifications(redux).filter(
      classification =>
        classification.programUuid === programUuid &&
        classification.category === category &&
        classification.source === source &&
        classification.uuid === classificationUuid
    );

    const examsDefined = [];

    if (classifications?.length > 0) {
      classifications.forEach(classification => {
        const exam_aptitudes = getExamAptitudes(redux, classification.uuid);

        if (exam_aptitudes?.length > 0) {
          examsDefined.push(exam_aptitudes);
        }
      });
    }

    if (examsDefined?.length > 0) {
      const totalWeight = _.reduce(
        _.flatten(examsDefined),
        (total, examAptitdue) => {
          const { weight } = examAptitdue;
          const weightDecimal = new Decimal(weight);
          return total.add(weightDecimal);
        },
        new Decimal(0)
      );

      return totalWeight.toDecimalPlaces(2).toString();
    } else {
      return undefined;
    }
  }
);

export const selectAptitudeWeightBySource = createSelector(
  state => state,
  (_, programUuid, category, source) => ({
    programUuid,
    category,
    source
  }),
  (redux, paramaters) => {
    const { programUuid, category, source } = paramaters;

    const getClassifications = ormCreateSelector(orm.Classification);

    const getExamAptitudes = ormCreateSelector(
      orm.Classification.exam_aptitudes
    );

    const classifications = getClassifications(redux).filter(
      classification =>
        classification.programUuid === programUuid &&
        classification.category === category &&
        classification.source === source
    );

    const examsDefined = [];

    if (classifications?.length > 0) {
      classifications.forEach(classification => {
        const exam_aptitudes = getExamAptitudes(redux, classification.uuid);

        if (exam_aptitudes?.length > 0) {
          examsDefined.push(exam_aptitudes);
        }
      });
    }

    if (examsDefined?.length > 0) {
      const totalWeight = _.reduce(
        _.flatten(examsDefined),
        (total, examAptitdue) => {
          const { weight } = examAptitdue;
          const weightDecimal = new Decimal(weight);
          return total.add(weightDecimal);
        },
        new Decimal(0)
      );

      return totalWeight.toDecimalPlaces(2).toString();
    } else {
      return undefined;
    }
  }
);

export const selectAptitudeGradeBySource = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category, source) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category, source } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    let getTranscript;
    switch (category) {
      case 'Non-Cognitive': {
        getTranscript = ormCreateSelector(orm.TranscriptNonCognitive);
        break;
      }
      case 'Physical-Skills': {
        getTranscript = ormCreateSelector(orm.TranscriptPhysicalSkills);
        break;
      }
      case 'Communication': {
        getTranscript = ormCreateSelector(orm.TranscriptCommunication);
        break;
      }
      default:
        break;
    }

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source
    );

    const exam = ormCreateSelector(orm.ExamAptitude);

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalWeight = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const { weight } = exam(redux, transcript.examAptitudeUuid);
          const weightDecimal = new Decimal(weight);

          return total.add(weightDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const { weight } = exam(redux, transcript.examAptitudeUuid);
          const weightDecimal = new Decimal(weight);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = weightDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore.div(totalWeight);

      const findGrade = _.find(letterGrades, schoolGrade => {
        const low = new Decimal(schoolGrade.rangeLow);
        const high = new Decimal(schoolGrade.rangeHigh);

        const conditionOne = totalPercentage.greaterThanOrEqualTo(low);
        const conditionTwo = totalPercentage.lessThanOrEqualTo(high);

        return conditionOne && conditionTwo;
      });

      return findGrade?.grade;
    } else {
      return undefined;
    }
  }
);

export const selectAptitudeScoreBySource = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category, source) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category, source } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    let getTranscript;
    switch (category) {
      case 'Non-Cognitive': {
        getTranscript = ormCreateSelector(orm.TranscriptNonCognitive);
        break;
      }
      case 'Physical-Skills': {
        getTranscript = ormCreateSelector(orm.TranscriptPhysicalSkills);
        break;
      }
      case 'Communication': {
        getTranscript = ormCreateSelector(orm.TranscriptCommunication);
        break;
      }
      default:
        break;
    }

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.source === source
    );

    const exam = ormCreateSelector(orm.ExamAptitude);

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalWeight = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const { weight } = exam(redux, transcript.examAptitudeUuid);
          const weightDecimal = new Decimal(weight);

          return total.add(weightDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const { weight } = exam(redux, transcript.examAptitudeUuid);
          const weightDecimal = new Decimal(weight);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = weightDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalWeight)
        .toDecimalPlaces(2)
        .toString();

      return totalPercentage;
    } else {
      return undefined;
    }
  }
);
