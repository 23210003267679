import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { ButtonOutline } from '@xcomp/xcomp-design-library';
import { syllabusTypes } from '../../../helpers/constants';

const Layout = styled.div`
  margin-right: 20px;
`;

function SyllabusAdd({ addSyllabusItem }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Layout>
      <ButtonOutline
        aria-owns={anchorEl ? 'schedule-item-menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
      >
        Add <ArrowDropDown />
      </ButtonOutline>
      <Menu
        id="schedule-item-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            addSyllabusItem(syllabusTypes.encounter);
          }}
        >
          Encounter
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            addSyllabusItem(syllabusTypes.assessment);
          }}
        >
          Assessment
        </MenuItem>
      </Menu>
    </Layout>
  );
}

SyllabusAdd.propTypes = {
  addSyllabusItem: PropTypes.func
};

SyllabusAdd.defaultProps = {
  addSyllabusItem: undefined
};

export default SyllabusAdd;
