import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getPartFacultyAssessmentSummary,
  getPartFacultyReport,
  getPartOpportunityReport,
  getPartOpportunityReportBlockDetail,
  getPartOpportunityReportStatisticBlockDetail,
  getPartTopicAssessmentSummary,
  getPartTopicReport
} from '../../api/partReportsApi';

import {
  doGetPartFacultyAssessmentSummaryError,
  doGetPartFacultyAssessmentSummarySuccess,
  doGetPartFacultyReportError,
  doGetPartFacultyReportSuccess,
  doGetPartOpportunityReportError,
  doGetPartOpportunityReportSuccess,
  doGetPartOpportunityReportBlockDetailError,
  doGetPartOpportunityReportBlockDetailSuccess,
  doGetPartOpportunityReportStatisticBlockDetailSuccess,
  doGetPartOpportunityReportStatisticBlockDetailError,
  doGetPartTopicAssessmentSummaryError,
  doGetPartTopicAssessmentSummarySuccess,
  doGetPartTopicReportError,
  doGetPartTopicReportSuccess
} from '../actions/partReportsActions';

import {
  GET_PART_FACULTY_ASSESSMENT_SUMMARY,
  GET_PART_FACULTY_REPORT,
  GET_PART_OPPORTUNITY_REPORT,
  GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL,
  GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL,
  GET_PART_TOPIC_ASSESSMENT_SUMMARY,
  GET_PART_TOPIC_REPORT
} from '../actions-type';

function* handleGetPartFacultyAssessmentSummary(action) {
  const { partUuid, facultyUuid, assessmentTechniques, assessmentTypes } =
    action.payload;
  try {
    const result = yield call(
      getPartFacultyAssessmentSummary,
      partUuid,
      facultyUuid,
      assessmentTechniques,
      assessmentTypes
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartFacultyAssessmentSummarySuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetPartFacultyAssessmentSummaryError(error));
  }
}

function* handleGetPartFacultyReport(action) {
  const { partUuid, reportType, filters } = action.payload;
  try {
    const result = yield call(
      getPartFacultyReport,
      partUuid,
      reportType,
      filters
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartFacultyReportSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetPartFacultyReportError(error));
  }
}

function* handleGetPartTopicAssessmentSummary(action) {
  const { partUuid, topicUuid } = action.payload;
  try {
    const result = yield call(
      getPartTopicAssessmentSummary,
      partUuid,
      topicUuid
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartTopicAssessmentSummarySuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetPartTopicAssessmentSummaryError(error));
  }
}

function* handleGetPartTopicReport(action) {
  const { partUuid, topics } = action.payload;
  try {
    const result = yield call(getPartTopicReport, partUuid, topics);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartTopicReportSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetPartTopicReportError(error));
  }
}

function* handleGetPartOpportunityReport(action) {
  const { partUuid, assessmentTypes } = action.payload;
  try {
    const result = yield call(
      getPartOpportunityReport,
      partUuid,
      assessmentTypes
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartOpportunityReportSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetPartOpportunityReportError(error));
  }
}

function* handleGetPartOpportunityBlockDetailReport(action) {
  const { assessmentUuid, studentUuid } = action.payload;
  try {
    const result = yield call(
      getPartOpportunityReportBlockDetail,
      assessmentUuid,
      studentUuid
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartOpportunityReportBlockDetailSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetPartOpportunityReportBlockDetailError(error));
  }
}

function* handleGetPartOpportunityStatisticBlockDetailReport(action) {
  const { assessmentUuid, statisticType } = action.payload;
  try {
    const result = yield call(
      getPartOpportunityReportStatisticBlockDetail,
      assessmentUuid,
      statisticType
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartOpportunityReportStatisticBlockDetailSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetPartOpportunityReportStatisticBlockDetailError(error));
  }
}

const partReports = [
  takeEvery(
    GET_PART_FACULTY_ASSESSMENT_SUMMARY,
    handleGetPartFacultyAssessmentSummary
  ),
  takeEvery(GET_PART_FACULTY_REPORT, handleGetPartFacultyReport),
  takeEvery(GET_PART_OPPORTUNITY_REPORT, handleGetPartOpportunityReport),
  takeEvery(
    GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL,
    handleGetPartOpportunityBlockDetailReport
  ),
  takeEvery(
    GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL,
    handleGetPartOpportunityStatisticBlockDetailReport
  ),
  takeEvery(
    GET_PART_TOPIC_ASSESSMENT_SUMMARY,
    handleGetPartTopicAssessmentSummary
  ),
  takeEvery(GET_PART_TOPIC_REPORT, handleGetPartTopicReport)
];

export { partReports };
