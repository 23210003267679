import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useContainerDimensions } from '../../../../../helpers/hooks/layout.hooks';
import {
  filterResultCardsByGroup,
  groupResultCardsByStudent,
  groupPointsPerStudentByAssessment,
  sortByDecimalProperty
} from '../../../../../helpers/utilities';
import PartGroupReportTable from './PartGroupReportTable';

const PartGroupReportTableContainer = ({
  assessments,
  groupMembers,
  partGroupSummaryState
}) => {
  const [useFillerColumn, setUseFillerColumn] = useState(false);
  const [fillerColumnWidth, setFillerColumnWidth] = useState(0);

  const tableRef = useRef(null);
  const { width } = useContainerDimensions(tableRef);

  const filteredResultCards = filterResultCardsByGroup(
    partGroupSummaryState.resultCardsByAssessment,
    groupMembers
  );

  const {
    pointsPerStudentByAssessment,
    groupAssessmentsTotal,
    totalPointsByStudent
  } = groupPointsPerStudentByAssessment(filteredResultCards);

  const sortedGroupMembers = groupMembers.sort((a, b) =>
    sortByDecimalProperty(a, b, 'studentRank')
  );

  useEffect(() => {
    const occupiedWidth = 5 * 75 + assessments.length * 75 + 250;
    if (width > occupiedWidth) {
      setUseFillerColumn(true);
      setFillerColumnWidth(width - occupiedWidth);
    } else {
      setUseFillerColumn(false);
      setFillerColumnWidth(0);
    }
  }, [width, assessments.length]);

  return (
    <PartGroupReportTable
      assessments={assessments}
      groupMembers={sortedGroupMembers}
      resultCardsByAssessment={groupResultCardsByStudent(filteredResultCards)}
      studentPartRanks={_.keyBy(
        partGroupSummaryState.studentPartRanks,
        'studentUuid'
      )}
      pointsPerStudentByAssessment={pointsPerStudentByAssessment}
      groupAssessmentsTotal={groupAssessmentsTotal}
      tableRef={tableRef}
      totalPointsByStudent={totalPointsByStudent}
      useFillerColumn={useFillerColumn}
      fillerColumnWidth={fillerColumnWidth}
    />
  );
};

PartGroupReportTableContainer.propTypes = {
  assessments: PropTypes.array,
  groupMembers: PropTypes.array,
  partGroupSummaryState: PropTypes.object
};

PartGroupReportTableContainer.defaultProps = {
  assessments: [],
  groupMembers: [],
  partGroupSummaryState: {}
};

export default PartGroupReportTableContainer;
