import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { ButtonIcon } from '@xcomp/xcomp-design-library';
import DeletePartButtonContainer from './DeletePartButton/DeletePartButtonContainer';

const PartActions = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  top: 2rem;
  right: 0.5rem;
`;

const IconBar = styled.div`
  width: 100%;
  max-width: 100px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 10;
  padding: 1rem 0 1rem 1.25rem;

  svg {
    font-size: 1.5rem;
    margin-left: 0.25rem;
    color: ${props => props.theme.fontColors.medium};

    &.delete {
      font-size: 1.125rem;
    }
  }
`;

const PartActionButtons = ({
  handlePartDelete,
  handlePartNumberChange,
  sectionId,
  part
}) => (
  <PartActions>
    <IconBar>
      <ButtonIcon
        onClick={() => handlePartNumberChange(part.uuid, sectionId, true)}
        noHoverBg
      >
        <ExpandLessIcon />
      </ButtonIcon>
      <ButtonIcon
        onClick={() => handlePartNumberChange(part.uuid, sectionId, false)}
        noHoverBg
      >
        <ExpandMoreIcon />
      </ButtonIcon>

      <DeletePartButtonContainer
        handlePartDelete={() => handlePartDelete(sectionId, part.uuid)}
      />
    </IconBar>
  </PartActions>
);

PartActionButtons.propTypes = {
  handlePartDelete: PropTypes.func,
  handlePartNumberChange: PropTypes.func,
  sectionId: PropTypes.string,
  part: PropTypes.object
};

PartActionButtons.defaultProps = {
  handlePartDelete: undefined,
  handlePartNumberChange: undefined,
  sectionId: '',
  part: {}
};

export default PartActionButtons;
