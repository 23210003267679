import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { Subtitle2 } from '@xcomp/xcomp-design-library';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useAssessmentCompletion } from '../../../../helpers/hooks/useGetCohortStudentAssessments';
import CompletedPercentage from "./Assessments/CompetedPercentage";

export const StatusLine = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }
`;

export const StatusLabel = styled(Subtitle2)`
  color: ${props => props.theme.fontColors.darker};
  position: relative;
  display: inline-block;
  z-index: 100;
  background: ${props => props.theme.colors.white};
  padding-right: 0.5rem;
  font-weight: 600;
`;

const ChipSC = styled(Chip)`
  && {
    margin-right: 5px;
    background: ${props => {
      switch (props.status) {
        case 'remaining': {
          return props.theme.colors.status.error;
        }
        case 'pending': {
          return props.theme.colors.status.warning;
        }
        case 'complete': {
          return props.theme.colors.status.success;
        }
        default:
          return props.theme.colors.grey[500];
      }
    }};
    color: ${props => props.theme.colors.white};
  }

  .MuiChip-avatar {
    background: ${props => props.theme.colors.white};
    font-weight: 800;
  }
`;

export default function CollectionCompletion({ cohortAssessmentCollectionUuid, completionStatus }) {

  const {
    completedAmount,
    remainingAmount,
    completedAssessments,
    pendingAssessments,
    remainingAssessments,
  } = useAssessmentCompletion({ ...completionStatus, cohortAssessmentCollectionUuid })

  return (
    <>
      <CompletedPercentage
        completed={completedAssessments}
        remaining={remainingAssessments}
      />
      <ChipSC
        status="complete"
        variant="outlined"
        size="small"
        avatar={<Avatar>{completedAmount}</Avatar>}
        label="Complete"
      />
      <ChipSC
        status="pending"
        variant="outlined"
        size="small"
        avatar={<Avatar>{pendingAssessments.length}</Avatar>}
        label="Pending"
      />
      <ChipSC
        status="remaining"
        variant="outlined"
        avatar={<Avatar>{remainingAmount}</Avatar>}
        size="small"
        label="Remaining"
      />
    </>
  );

}

CollectionCompletion.propTypes = {
  cohortAssessmentCollectionUuid: PropTypes.string,
  completionStatus: PropTypes.shape({
    completed: PropTypes.array,
    pending: PropTypes.array,
    remaining: PropTypes.array
  })

};

CollectionCompletion.defaultProps = {
  cohortAssessmentCollectionUuid: undefined,
  completionStatus: {
    completed: [],
    pending: [],
    remaining: []
  }
};
