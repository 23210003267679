import { call, put, takeEvery } from 'redux-saga/effects';
import {
  postUploadPartLegacyGrades,
  deletePartLegacyGrades,
  postUploadSectionLegacyGrades,
  deleteSectionLegacyGrades
} from '../../api/legacyGradingApi';

import {
  DELETE_PART_LEGACY_GRADES,
  DELETE_SECTION_LEGACY_GRADES,
  POST_UPLOAD_PART_LEGACY_GRADES,
  POST_UPLOAD_SECTION_LEGACY_GRADES
} from '../actions-type';
import {
  doDeletePartLegacyGradesError,
  doDeletePartLegacyGradesSuccess,
  doDeleteSectionLegacyGradesError,
  doDeleteSectionLegacyGradesSuccess,
  doUploadPartLegacyGradesError,
  doUploadPartLegacyGradesSuccess,
  doUploadSectionLegacyGradesError,
  doUploadSectionLegacyGradesSuccess
} from '../actions/legacyGradingActions';

function* handleUploadPartLegacyGrades(action) {
  try {
    const { partUuid, records, uploadDate } = action.payload;

    const result = yield call(
      postUploadPartLegacyGrades,
      partUuid,
      records,
      uploadDate
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doUploadPartLegacyGradesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doUploadPartLegacyGradesError(error));
  }
}

function* handleDeletePartLegacyGrades(action) {
  const { partUuid } = action.payload;

  try {
    const result = yield call(deletePartLegacyGrades, partUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeletePartLegacyGradesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeletePartLegacyGradesError(error));
  }
}

function* handleUploadSectionLegacyGrades(action) {
  try {
    const { sectionUuid, records, uploadDate } = action.payload;

    const result = yield call(
      postUploadSectionLegacyGrades,
      sectionUuid,
      records,
      uploadDate
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doUploadSectionLegacyGradesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doUploadSectionLegacyGradesError(error));
  }
}

function* handleDeleteSectionLegacyGrades(action) {
  const { sectionUuid } = action.payload;

  try {
    const result = yield call(deleteSectionLegacyGrades, sectionUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteSectionLegacyGradesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteSectionLegacyGradesError(error));
  }
}

// function* handleDeleteStudentPartLegacyGrades(action) {
//   const { studentUuid, partUuid } = action.payload;

//   try {
//     const result = yield call(deletePartLegacyGrades, studentUuid, partUuid);

//     if (result.status === 200) {
//       const payload = yield result.json();
//       yield put(doDeletePartLegacyGradesSuccess(payload));
//     } else {
//       const payload = yield result.json();
//       throw payload;
//     }
//   } catch (error) {
//     yield put(doDeletePartLegacyGradesError(error));
//   }
// }

const legacyGrading = [
  takeEvery(POST_UPLOAD_PART_LEGACY_GRADES, handleUploadPartLegacyGrades),
  takeEvery(DELETE_PART_LEGACY_GRADES, handleDeletePartLegacyGrades),
  takeEvery(POST_UPLOAD_SECTION_LEGACY_GRADES, handleUploadSectionLegacyGrades),
  takeEvery(DELETE_SECTION_LEGACY_GRADES, handleDeleteSectionLegacyGrades)
];

export { legacyGrading };
