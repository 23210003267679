import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import Header from '../../../Library/Header';
import CourseForm from '../CourseForm';
import CourseSection from '../CourseSection/CourseSection';
import FormErrors from '../../../Library/FormErrors';
import {
  ContainerResize,
  ContainerPage,
  ContainerActions
} from '../../../Library/Layout';

import { validateCoursePartsExist } from '../../../../helpers/validation/validateCourse';

const ErrorMessage = styled.div`
  font-size: 0.875rem;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.status.error};
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 0.75rem 1.125rem;
  display: inline-block;
  span {
    display: block;
  }
`;

const CourseAdd = ({ hasErrors, ...props }) => {
  const partErrors = validateCoursePartsExist(props.sections);
  const sectionErrors = !props.sections || props.sections.length < 1;
  const sectionPartErrors = Boolean(partErrors || sectionErrors);

  return (
    <ContainerResize>
      <Header title="Course Sections" subtitle="Create course and sections" />
      <ContainerActions>
        <ButtonPrimary onClick={props.handleCourseSubmission}>
          SAVE
        </ButtonPrimary>
      </ContainerActions>
      <ContainerPage>
        <FormErrors hasErrors={hasErrors} />
        {hasErrors && sectionPartErrors && (
          <ErrorMessage {...props}>
            {sectionErrors && <span>You must create at least one section</span>}
            {partErrors.invalid && <span>{partErrors.message}</span>}
          </ErrorMessage>
        )}
        <CourseForm hasErrors={hasErrors} {...props} />
        <CourseSection hasErrors={hasErrors} {...props} />
      </ContainerPage>
    </ContainerResize>
  );
};

CourseAdd.propTypes = {
  departments: PropTypes.arrayOf(PropTypes.object),
  terms: PropTypes.arrayOf(PropTypes.object),
  academicYears: PropTypes.arrayOf(PropTypes.object),
  facultyUsers: PropTypes.arrayOf(PropTypes.object),
  handleCourseSubmission: PropTypes.func,
  hasErrors: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.object)
};

CourseAdd.defaultProps = {
  departments: [],
  academicYears: [],
  terms: [],
  facultyUsers: [],
  handleCourseSubmission: undefined,
  hasErrors: false,
  sections: []
};

export default CourseAdd;
