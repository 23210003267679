export const GET_SCHOOL_PROGRAMS = 'GET_SCHOOL_PROGRAMS';
export const GET_SCHOOL_PROGRAMS_SUCCESS = 'GET_SCHOOL_PROGRAMS_SUCCESS';
export const GET_SCHOOL_PROGRAMS_ERROR = 'GET_SCHOOL_PROGRAMS_ERROR';
export const GET_PROGRAM = 'GET_PROGRAM';
export const GET_PROGRAM_SUCCESS = 'GET_PROGRAM_SUCCESS';
export const GET_PROGRAM_ERROR = 'GET_PROGRAM_ERROR';
export const GET_PROGRAM_ASSESSMENTS = 'GET_PROGRAM_ASSESSMENTS';
export const GET_PROGRAM_ASSESSMENTS_SUCCESS =
  'GET_PROGRAM_ASSESSMENTS_SUCCESS';
export const GET_PROGRAM_ASSESSMENTS_ERROR = 'GET_PROGRAM_ASSESSMENTS_ERROR';
export const GET_PROGRAM_ASSESSMENT = 'GET_PROGRAM_ASSESSMENT';
export const GET_PROGRAM_ASSESSMENT_SUCCESS = 'GET_PROGRAM_ASSESSMENT_SUCCESS';
export const GET_PROGRAM_ASSESSMENT_ERROR = 'GET_PROGRAM_ASSESSMENT_ERROR';
export const GET_PROGRAMS = 'GET_PROGRAMS';
export const GET_PROGRAMS_SUCCESS = 'GET_PROGRAMS_SUCCESS';
export const GET_PROGRAMS_ERROR = 'GET_PROGRAMS_ERROR';
export const POST_PROGRAM = 'POST_PROGRAM';
export const POST_PROGRAM_SUCCESS = 'POST_PROGRAM_SUCCESS';
export const POST_PROGRAM_ERROR = 'POST_PROGRAM_ERROR';
export const PUT_PROGRAM = 'PUT_PROGRAM';
export const PUT_PROGRAM_SUCCESS = 'PUT_PROGRAM_SUCCESS';
export const PUT_PROGRAM_ERROR = 'PUT_PROGRAM_ERROR';

export const POST_PROGRAM_ASSESSMENT_COLLECTION =
  'POST_PROGRAM_ASSESSMENT_COLLECTION';
export const POST_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS =
  'POST_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS';
export const POST_PROGRAM_ASSESSMENT_COLLECTION_ERROR =
  'POST_PROGRAM_ASSESSMENT_COLLECTION_ERROR';

export const PUT_PROGRAM_ASSESSMENT_COLLECTION =
  'PUT_PROGRAM_ASSESSMENT_COLLECTION';
export const PUT_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS =
  'PUT_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS';
export const PUT_PROGRAM_ASSESSMENT_COLLECTION_ERROR =
  'PUT_PROGRAM_ASSESSMENT_COLLECTION_ERROR';

export const POST_PROGRAM_ASSESSMENT_MASTER = 'POST_PROGRAM_ASSESSMENT_MASTER';
export const POST_PROGRAM_ASSESSMENT_MASTER_SUCCESS =
  'POST_PROGRAM_ASSESSMENT_MASTER_SUCCESS';
export const POST_PROGRAM_ASSESSMENT_MASTER_ERROR =
  'POST_PROGRAM_ASSESSMENT_MASTER_ERROR';

export const PUT_PROGRAM_ASSESSMENT_MASTER = 'PUT_PROGRAM_ASSESSMENT_MASTER';
export const PUT_PROGRAM_ASSESSMENT_MASTER_SUCCESS =
  'PUT_PROGRAM_ASSESSMENT_MASTER_SUCCESS';
export const PUT_PROGRAM_ASSESSMENT_MASTER_ERROR =
  'PUT_PROGRAM_ASSESSMENT_MASTER_ERROR';

export const DELETE_PROGRAM = 'DELETE_PROGRAM';
export const DELETE_PROGRAM_SUCCESS = 'DELETE_PROGRAM_SUCCESS';
export const DELETE_PROGRAM_ERROR = 'DELETE_PROGRAM_ERROR';

export const DELETE_PROGRAM_ASSESSMENT_COLLECTION =
  'DELETE_PROGRAM_ASSESSMENT_COLLECTION';
export const DELETE_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS =
  'DELETE_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS';
export const DELETE_PROGRAM_ASSESSMENT_COLLECTION_ERROR =
  'DELETE_PROGRAM_ASSESSMENT_COLLECTION_ERROR';

export const DELETE_PROGRAM_ASSESSMENT_MASTER =
  'DELETE_PROGRAM_ASSESSMENT_MASTER';
export const DELETE_PROGRAM_ASSESSMENT_MASTER_SUCCESS =
  'DELETE_PROGRAM_ASSESSMENT_MASTER_SUCCESS';
export const DELETE_PROGRAM_ASSESSMENT_MASTER_ERROR =
  'DELETE_PROGRAM_ASSESSMENT_MASTER_ERROR';
