import {
  DELETE_TRANSCRIPT_ERROR,
  DELETE_TRANSCRIPT_SUCCESS,
  DELETE_TRANSCRIPT,
  DELETE_TRANSCRIPTS_ERROR,
  DELETE_TRANSCRIPTS_SUCCESS,
  DELETE_TRANSCRIPTS,
  GET_TRANSCRIPT_ERROR,
  GET_TRANSCRIPT_SUCCESS,
  GET_TRANSCRIPT,
  PUT_TRANSCRIPT,
  PUT_TRANSCRIPT_SUCCESS,
  PUT_TRANSCRIPT_ERROR,
  POST_TRANSCRIPT_UPLOAD_ERROR,
  POST_TRANSCRIPT_UPLOAD_SUCCESS,
  POST_TRANSCRIPT_UPLOAD,
  POST_TRANSCRIPT_ERROR,
  POST_TRANSCRIPT_SUCCESS,
  POST_TRANSCRIPT,
  POST_CLONE_TRANSCRIPT,
  POST_CLONE_TRANSCRIPT_SUCCESS,
  POST_CLONE_TRANSCRIPT_ERROR
} from '../actions-type';

const doGetTranscript = (programUuid, cohortUuid, userUuid) => ({
  type: GET_TRANSCRIPT,
  payload: { programUuid, cohortUuid, userUuid }
});

const doGetTranscriptSuccess = result => ({
  type: GET_TRANSCRIPT_SUCCESS,
  payload: result
});

const doGetTranscriptError = error => ({
  type: GET_TRANSCRIPT_ERROR,
  payload: error
});

const doPostTranscriptUpload = (
  category,
  source,
  programUuid,
  cohortUuid,
  userUuid,
  records,
  uploadDate
) => ({
  type: POST_TRANSCRIPT_UPLOAD,
  payload: {
    category,
    source,
    programUuid,
    cohortUuid,
    userUuid,
    records,
    uploadDate
  }
});

const doPostTranscriptUploadSuccess = result => ({
  type: POST_TRANSCRIPT_UPLOAD_SUCCESS,
  payload: result
});

const doPostTranscriptUploadError = error => ({
  type: POST_TRANSCRIPT_UPLOAD_ERROR,
  payload: error
});

const doPostTranscript = payload => ({
  type: POST_TRANSCRIPT,
  payload
});

const doPostTranscriptSuccess = result => ({
  type: POST_TRANSCRIPT_SUCCESS,
  payload: result
});

const doPostTranscriptError = error => ({
  type: POST_TRANSCRIPT_ERROR,
  payload: error
});

const doPutTranscript = payload => ({
  type: PUT_TRANSCRIPT,
  payload
});

const doPutTranscriptSuccess = result => ({
  type: PUT_TRANSCRIPT_SUCCESS,
  payload: result
});

const doPutTranscriptError = error => ({
  type: PUT_TRANSCRIPT_ERROR,
  payload: error
});

const doDeleteTranscript = (category, uuid) => ({
  type: DELETE_TRANSCRIPT,
  payload: {
    category,
    uuid
  }
});

const doDeleteTranscriptSuccess = result => ({
  type: DELETE_TRANSCRIPT_SUCCESS,
  payload: result
});

const doDeleteTranscriptError = error => ({
  type: DELETE_TRANSCRIPT_ERROR,
  payload: error
});

const doDeleteTranscripts = (
  category,
  source,
  userUuid,
  cohortUuid,
  programUuid
) => ({
  type: DELETE_TRANSCRIPTS,
  payload: {
    category,
    source,
    userUuid,
    cohortUuid,
    programUuid
  }
});

const doDeleteTranscriptsSuccess = result => ({
  type: DELETE_TRANSCRIPTS_SUCCESS,
  payload: result
});

const doDeleteTranscriptsError = error => ({
  type: DELETE_TRANSCRIPTS_ERROR,
  payload: error
});

const doPostCloneTranscript = (
  fromCohortUuid,
  fromUserUuid,
  toCohortUuid,
  toUserUuid
) => ({
  type: POST_CLONE_TRANSCRIPT,
  payload: { fromCohortUuid, fromUserUuid, toCohortUuid, toUserUuid }
});

const doPostCloneTranscriptSuccess = result => ({
  type: POST_CLONE_TRANSCRIPT_SUCCESS,
  payload: result
});

const doPostCloneTranscriptError = error => ({
  type: POST_CLONE_TRANSCRIPT_ERROR,
  payload: error
});

export {
  doGetTranscript,
  doGetTranscriptError,
  doGetTranscriptSuccess,
  doPutTranscript,
  doPutTranscriptSuccess,
  doPutTranscriptError,
  doPostTranscriptUpload,
  doPostTranscriptUploadSuccess,
  doPostTranscriptUploadError,
  doPostTranscript,
  doPostTranscriptSuccess,
  doPostTranscriptError,
  doDeleteTranscript,
  doDeleteTranscriptSuccess,
  doDeleteTranscriptError,
  doDeleteTranscripts,
  doDeleteTranscriptsSuccess,
  doDeleteTranscriptsError,
  doPostCloneTranscript,
  doPostCloneTranscriptSuccess,
  doPostCloneTranscriptError
};
