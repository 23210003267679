import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import {
  Caption,
  Body1,
  Heading3,
  CaptionSmall
} from '@xcomp/xcomp-design-library';
import { TimeIcon } from '@xcomp/xcomp-design-library/dist/icons';

export const Dates = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
`;

export const DateBlock = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const ThroughBlock = styled(Caption)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  margin-right: auto;
`;

export const DateStyle = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

export const TimeStyle = styled(Body1)`
  display: flex;
  align-items: center;
  margin: 0;
  color: ${props => props.theme.fontColors.darker};

  svg {
    margin-right: 8px;
  }
`;

export const Weekday = styled(Caption)`
  text-transform: uppercase;
  margin: 0;
`;

export const DayOfMonth = styled(Heading3)`
  margin: 0;
`;

export const Month = styled(CaptionSmall)`
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
`;

export const Time = styled(TimeIcon)`
  && {
    font-size: 16px;
  }
`;

const ItemDatesBlock = ({ startDate, endDate }) => {
  const isStartNull = !startDate;

  const startDateSafe = startDate || moment(startDate);
  const endDateSafe = endDate || moment(startDate);

  const startDayOfWeek = startDateSafe.format('ddd');
  const startDayOfMonth = startDateSafe.format('D');
  const startMonth = startDateSafe.format('MMM');

  const endDayOfWeek = endDateSafe.format('ddd');
  const endDayOfMonth = endDateSafe.format('D');
  const endMonth = endDateSafe.format('MMM');

  const startTime = startDateSafe.format('hh:mm a');
  const endTime = endDateSafe.format('hh:mm a');

  const isMultiDay =
    startDateSafe.format('MMM DD') !== endDateSafe.format('MMM DD');

  return (
    <Dates>
      {isMultiDay ? (
        <>
          <DateBlock>
            <DateStyle>
              <Weekday>{startDayOfWeek}</Weekday>
              <DayOfMonth>{startDayOfMonth}</DayOfMonth>
              <Month>{startMonth}</Month>
            </DateStyle>
            <TimeStyle>
              <Time />
              {startTime}
            </TimeStyle>
          </DateBlock>
          <ThroughBlock>through</ThroughBlock>
          <DateBlock>
            <DateStyle>
              <Weekday>{endDayOfWeek}</Weekday>
              <DayOfMonth>{endDayOfMonth}</DayOfMonth>
              <Month>{endMonth}</Month>
            </DateStyle>
            <TimeStyle>
              <Time />
              {endTime}
            </TimeStyle>
          </DateBlock>
        </>
      ) : (
        <>
          <DateBlock>
            {!isStartNull ? (
              <>
                <DateStyle>
                  <Weekday>{startDayOfWeek}</Weekday>
                  <DayOfMonth>{startDayOfMonth}</DayOfMonth>
                  <Month>{startMonth}</Month>
                </DateStyle>
                <TimeStyle>
                  <Time />
                  {startTime} - {endTime}
                </TimeStyle>
              </>
            ) : (
              <TimeStyle>
                <Time />
                --
              </TimeStyle>
            )}
          </DateBlock>
        </>
      )}
    </Dates>
  );
};

ItemDatesBlock.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object
};
ItemDatesBlock.defaultProps = {
  endDate: null
};

export default ItemDatesBlock;
