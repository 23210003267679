import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectTermAverageScore } from '../../../../redux/selectors/transcriptCognitiveSelectors';

const Group = styled.div`
  display: flex;
  font-size: 12px;
  width: 120px;
`;

const Label = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Value = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 5px;
`;

export default function TermAvgScore({
  programUuid,
  userUuid,
  cohortUuid,
  source,
  termYear
}) {
  const avgScore = useSelector(state =>
    selectTermAverageScore(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      source,
      termYear
    )
  );

  return (
    <Group>
      <Label>{avgScore && 'Score:'}</Label>
      <Value>{avgScore && avgScore}</Value>
    </Group>
  );
}

TermAvgScore.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  source: PropTypes.string,
  termYear: PropTypes.string
};

TermAvgScore.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  source: undefined,
  termYear: undefined
};
