import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import { selectCompetenciesByCollection } from '../../../../redux/selectors/competencyCollectionsSelectors';
import CompetencyRelative from './CompetencyRelative';
import CompetencyPotential from './CompetencyPotential';
import CompetencyScore from './CompetencyScore';
import CompetencyGrade from './CompetencyGrade';
import CompetencyByAssessmentType from './CompetencyByAssessmentType';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .column_one {
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }

  .column_one > span {
    width: 100%;
  }
`;

const Label = styled.div`
  font-size: 16px;
  color: ${props => props.theme.fontColors.darker};
`;

const None = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const Calculations = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Record = styled.div`
  width: ${props => props.width};
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
`;
const GroupLabel = styled.div`
  padding: 10px;
`;
const GroupInput = styled.div`
  padding: 10px;
`;

export default function Competencies({ competencyCollectionUuid }) {
  const competencies = useSelector(
    state => selectCompetenciesByCollection(state, competencyCollectionUuid),
    _.isEqual
  );

  return (
    <>
      {competencies?.length > 0 ? (
        competencies?.map(competency => (
          <Accordion
            key={competency.uuid}
            withBottomBorder={true}
            height="80px"
            rowOffset="66px"
            label={<Label>{competency?.name}</Label>}
            columnOneClassName="column_one"
            columnOne={
              <Calculations>
                <Record width="25%">
                  <Group>
                    <GroupLabel>Rel</GroupLabel>
                    <GroupInput>
                      <CompetencyRelative competencyUuid={competency?.uuid} />
                    </GroupInput>
                  </Group>
                </Record>
                <Record width="25%">
                  <Group>
                    <GroupLabel>Pot</GroupLabel>
                    <GroupInput>
                      <CompetencyPotential competencyUuid={competency?.uuid} />
                    </GroupInput>
                  </Group>
                </Record>
                <Record width="25%">
                  <Group>
                    <GroupLabel>Score</GroupLabel>
                    <GroupInput>
                      <CompetencyScore competencyUuid={competency?.uuid} />
                    </GroupInput>
                  </Group>
                </Record>
                <Record width="25%">
                  <Group>
                    <GroupLabel>Grade</GroupLabel>
                    <GroupInput>
                      <CompetencyGrade competencyUuid={competency?.uuid} />
                    </GroupInput>
                  </Group>
                </Record>
              </Calculations>
            }
          >
            <CompetencyByAssessmentType competencyUuid={competency?.uuid} />
          </Accordion>
        ))
      ) : (
        <None>No competencies have been assigned to this collection.</None>
      )}
    </>
  );
}

Competencies.propTypes = {
  competencyCollectionUuid: PropTypes.string
};

Competencies.defaultProps = {
  competencyCollectionUuid: undefined
};
