const validateCreditsRequired = inputValue => {
  if (!inputValue || inputValue === '' || Number(inputValue) === 0) {
    return {
      invalid: true,
      message: 'Required'
    };
  }

  const regex = /^[0-9]{1,3}([.]?[0-9]{1})?$/i;

  const regexMatch = inputValue.match(regex);
  const failsRegex = !regexMatch || regexMatch.length < 1;

  if (failsRegex) {
    return {
      invalid: true,
      message: 'Must be a number up to 1 decimal place'
    };
  }

  // const maxCredits =
  //   allCourses && allCourses.length > 0
  //     ? allCourses.reduce((accumulator, course) => accumulator + course.hours)
  //     : 0;

  // if (Number(inputValue) < maxCredits) {
  //   return {
  //     invalid: true,
  //     message: 'Must be a number up to 1 decimal place'
  //   };
  // }

  return { invalid: false };
};

export { validateCreditsRequired };
