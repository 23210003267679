import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SyllabusCourse from './SyllabusCourse';
import { useQuery } from '../../helpers/hooks/router.hooks';
import { doGetSyllabusCourse } from '../../redux/actions/syllabusActions';
import { selectCourse } from '../../redux/selectors/coursesSelectors';
import { selectCourseMaster } from '../../redux/selectors/coursesMasterSelectors';

export default function SyllabusCourseContainer() {
  const dispatch = useDispatch();
  const query = useQuery();
  const courseUuid = query.get('courseUuid');
  const userUuid = query.get('userUuid');
  const course = useSelector(state => selectCourse(state, courseUuid));
  const courseMaster = useSelector(state => {
    if (course?.courseMasterUuid) {
      return selectCourseMaster(state, course.courseMasterUuid);
    } else {
      return undefined;
    }
  });

  useEffect(() => {
    if (courseUuid) {
      dispatch(doGetSyllabusCourse(courseUuid));
    }
  }, [dispatch, courseUuid]);

  return (
    <SyllabusCourse
      userUuid={userUuid}
      course={course}
      courseMaster={courseMaster}
      title={courseMaster?.title}
    />
  );
}

SyllabusCourseContainer.propTypes = {};

SyllabusCourseContainer.defaultProps = {};
