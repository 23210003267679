const validateTermsPeriod = terms => {
  const noBlankFields = terms.filter(textField => textField.period === '');

  if (noBlankFields.length > 0) {
    return {
      invalid: true
    };
  }

  return {
    invalid: false
  };
};

const validateTermDates = terms => {
  const noBlankDates = terms.filter(
    dateField => dateField.startDate === '' || dateField.endDate === ''
  );

  if (noBlankDates.length > 0) {
    return {
      invalid: true
    };
  }

  return {
    invalid: false
  };
};

export { validateTermsPeriod, validateTermDates };
