import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { selectGradeResult } from '../../../../redux/selectors/calculatedGradeResultSelectors';

export default function GradeResultScore({ userUuid, sectionUuid }) {
  const result = useSelector(state =>
    selectGradeResult(state, userUuid, sectionUuid)
  );

  const relativeScore = _.get(result, 'relativeScore');

  return <>{relativeScore || 'N/A'}</>;
}

GradeResultScore.propTypes = {
  userUuid: PropTypes.string,
  sectionUuid: PropTypes.string
};

GradeResultScore.defaultProps = {
  userUuid: undefined,
  sectionUuid: undefined
};
