import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../../../helpers/format/user.format';
import FacultyDefaultScoreViewStudentPanel from './FacultyDefaultScoreViewStudentPanel';

const FacultyDefaultScoreViewStudentPanelContainer = ({
  facultyScores,
  scoreType,
  studentUser
}) => {
  const studentScores = useMemo(
    () =>
      facultyScores.filter(
        facultyScore => facultyScore.studentUuid === studentUser.uuid
      ),
    [facultyScores, studentUser.uuid]
  );
  const {
    studentTotalRelative,
    studentTotalPotential,
    attemptOrOpportunityCount
  } = useMemo(
    () =>
      _.reduce(
        studentScores,
        (acc, studentScore) => {
          const relativeValue = new Decimal(studentScore.relValue);
          const potentialValue = new Decimal(studentScore.potValue);
          const currentAttemptOrOpportunity = Number(studentScore.attempt);
          const res = Math.max(
            currentAttemptOrOpportunity,
            acc.attemptOrOpportunityCount
          );
          return {
            studentTotalRelative: relativeValue.plus(acc.studentTotalRelative),
            studentTotalPotential: potentialValue.plus(
              acc.studentTotalPotential
            ),
            attemptOrOpportunityCount: res
          };
        },
        {
          studentTotalRelative: new Decimal(0),
          studentTotalPotential: new Decimal(0),
          attemptOrOpportunityCount: 0
        }
      ),
    [studentScores]
  );
  const studentFirstName = formatFirstNameMiddleName(
    studentUser.firstName,
    studentUser.middleName
  );
  const studentLastName = formatLastName(
    studentUser.lastName,
    studentUser.marriedLastName,
    studentUser.suffix
  );
  return studentTotalPotential.greaterThan(0) ? (
    <FacultyDefaultScoreViewStudentPanel
      attemptOrOpportunityCount={attemptOrOpportunityCount}
      scoreType={scoreType}
      studentScores={studentScores}
      studentTotalRelative={studentTotalRelative.toFixed(2)}
      studentTotalPotential={studentTotalPotential.toFixed(2)}
      userFullName={`${studentLastName}, ${studentFirstName}`}
    />
  ) : null;
};

FacultyDefaultScoreViewStudentPanelContainer.propTypes = {
  facultyScores: PropTypes.array,
  scoreType: PropTypes.string,
  studentUser: PropTypes.object
};
FacultyDefaultScoreViewStudentPanelContainer.defaultProps = {
  facultyScores: [],
  scoreType: '',
  studentUser: {}
};

export default FacultyDefaultScoreViewStudentPanelContainer;
