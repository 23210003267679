import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CohortApplicantAssign from './CohortApplicantAssign';
import { doGetUsersBySearchInstitutionId } from '../../../../redux/actions/userActions';
import {
  doPostUserCohort,
  doPostUserCohortsReset
} from '../../../../redux/actions/userCohortActions';
import { userSelectorByInstitutionId } from '../../../../redux/selectors/usersSelectors';

export default function CohortApplicantAssignContainer({
  cohortUuid,
  openAssignApplicant,
  handleAssignApplicantClose
}) {
  const [searchInput, setSearchInput] = useState('');
  const [selectedUser, setSelectedUser] = useState(false);
  const [selectedUserUuid, setSelectedUserUuid] = useState(undefined);
  const [selectedUserEmail, setSelectedUserEmail] = useState(undefined);
  const [searchMessage, setSearchMessage] = useState(
    'Use the search input to find applicant by institution Id.'
  );
  const dispatch = useDispatch();

  const searchUser = useSelector(
    state => userSelectorByInstitutionId(state, searchInput),
    _.isEqual
  );

  const searching = useSelector(
    state => state.crudState.readUsersByInstitutionId
  );

  const createUserCohort = useSelector(
    state => state.crudState.createUserCohort
  );

  const createUserCohortSuccess = useSelector(
    state => state.crudState.createUserCohortSuccess
  );

  useEffect(() => {
    if (!openAssignApplicant) {
      setSearchInput('');
      setSelectedUser(false);
      setSelectedUserUuid(undefined);
      setSelectedUserEmail(undefined);
      setSearchMessage(
        'Use the search input to find applicant by institution Id.'
      );
      dispatch(doPostUserCohortsReset());
    }
  }, [dispatch, openAssignApplicant]);

  useEffect(() => {
    if (createUserCohortSuccess) {
      handleAssignApplicantClose();
    }
  }, [handleAssignApplicantClose, createUserCohortSuccess]);

  const handleSearchChange = event => {
    const { value } = event.target;
    setSearchInput(value);
  };

  const handleSearch = () => {
    dispatch(doGetUsersBySearchInstitutionId(searchInput));
    setSearchMessage('User not found.');
  };

  const handleUserSelection = (uuid, email) => {
    setSelectedUser(!selectedUser);
    setSelectedUserUuid(uuid);
    setSelectedUserEmail(email)
  };

  const handleAddApplicant = () => {
    if (selectedUser) {
      const payload = {
        role: 'applicant',
        cohortUuid,
        userUuid: selectedUserUuid,
        status: 'active',
        startDate: new Date(),
        email: selectedUserEmail || 'temp@email.com',
        phone: '(000) 000-0000'
      };

      dispatch(doPostUserCohort(payload));
    }
  };

  return (
    <CohortApplicantAssign
      openAssignApplicant={openAssignApplicant}
      searchInput={searchInput}
      searchUser={searchUser}
      selectedUser={selectedUser}
      searching={searching}
      searchMessage={searchMessage}
      createUserCohort={createUserCohort}
      handleAssignApplicantClose={handleAssignApplicantClose}
      handleSearchChange={handleSearchChange}
      handleSearch={handleSearch}
      handleUserSelection={handleUserSelection}
      handleAddApplicant={handleAddApplicant}
    />
  );
}

CohortApplicantAssignContainer.propTypes = {
  cohortUuid: PropTypes.string,
  openAssignApplicant: PropTypes.bool,
  handleAssignApplicantClose: PropTypes.func
};
CohortApplicantAssignContainer.defaultProps = {
  cohortUuid: '',
  openAssignApplicant: false,
  handleAssignApplicantClose: undefined
};
