import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectSourceRank } from '../../../../redux/selectors/applicantRankSelectors';

export default function RankSource({ userUuid, cohortUuid, category, source }) {
  const categoryRank = useSelector(state =>
    selectSourceRank(state, userUuid, cohortUuid, category, source)
  );

  const rank =
    categoryRank?.cohortRankRelative && categoryRank?.cohortRankPotential
      ? `${categoryRank?.cohortRankRelative} of ${categoryRank?.cohortRankPotential}`
      : 'N/A';

  return <>{rank}</>;
}

RankSource.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  category: PropTypes.string,
  source: PropTypes.string
};

RankSource.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  category: undefined,
  source: undefined
};
