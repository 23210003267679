import {
  GET_PART_GROUP_SUMMARY_SUCCESS,
  GET_STUDENT_PART_GROUP_SUMMARY_SUCCESS,
  RESET_PART_GROUP_SUMMARY
} from '../actions-type';

export const INITIAL_STATE = {
  resultCardsByAssessment: {}
};

const partGroupSummaryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_STUDENT_PART_GROUP_SUMMARY_SUCCESS:
    case GET_PART_GROUP_SUMMARY_SUCCESS: {
      const {
        assessmentSummaryData,
        resultCardsByAssessment,
        studentPartRanks,
        groupLowPerStudent,
        groupHighPerStudent,
        groupAvgPerStudent
      } = action.payload;
      return {
        ...state,
        assessmentSummaryData,
        resultCardsByAssessment,
        studentPartRanks,
        groupLowPerStudent,
        groupHighPerStudent,
        groupAvgPerStudent
      };
    }
    case RESET_PART_GROUP_SUMMARY: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default partGroupSummaryReducer;
