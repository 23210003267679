import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Decimal from 'decimal.js-light';
import { isEmpty } from 'lodash';
import { useAtomValue } from 'jotai';

import { letterGradesAtom } from './EditCollection/state/editState';

const Thresholds = ({ thresholds, handleChange }) => {
  const letterGrades = useAtomValue(letterGradesAtom);

  const renderLetterGrade = useCallback(
    scoreThreshold => {
      console.log(scoreThreshold);
      if (Number.isNaN(+scoreThreshold) || isEmpty(scoreThreshold))
        return <InputAdornment position="end">(F)</InputAdornment>;

      const threshold = new Decimal(scoreThreshold);
      const grade = letterGrades.find(
        grade =>
          new Decimal(grade.rangeLow).lessThanOrEqualTo(threshold) &&
          new Decimal(grade.rangeHigh).greaterThanOrEqualTo(threshold)
      );
      console.log(grade);
      return (
        <InputAdornment position="end">({grade?.grade || 'F'})</InputAdornment>
      );
    },
    [letterGrades]
  );

  return (
    <FormGroup
      style={{
        display: 'flex',
        gap: '24px',
        flexWrap: 'nowrap',
        flexDirection: 'row'
      }}
    >
      <TextField
        name="procedure_threshold"
        value={thresholds.procedure_threshold}
        onChange={handleChange}
        margin="normal"
        label="Procedure Threshold"
        variant="outlined"
        fullWidth
      />
      <TextField
        name="rvus_threshold"
        value={thresholds.rvus_threshold}
        onChange={handleChange}
        margin="normal"
        label="RVUs Threshold"
        variant="outlined"
        fullWidth
      />
      <TextField
        name="score_threshold"
        value={thresholds.score_threshold}
        onChange={handleChange}
        margin="normal"
        label="Score Threshold"
        variant="outlined"
        InputProps={{
          endAdornment: renderLetterGrade(thresholds.score_threshold)
        }}
        error={
          Number.isNaN(+thresholds.score_threshold) ||
          Number(thresholds.score_threshold) > 100
        }
        helperText={
          Number.isNaN(+thresholds.score_threshold) ||
          Number(thresholds.score_threshold) > 100
            ? 'Score must be a number between 0 and 100'
            : ''
        }
        fullWidth
      />
      <TextField
        name="units_threshold"
        value={thresholds.units_threshold}
        onChange={handleChange}
        margin="normal"
        label="Units Threshold"
        variant="outlined"
        fullWidth
      />
      <TextField
        name="epas_threshold"
        value={thresholds.epas_threshold}
        onChange={handleChange}
        margin="normal"
        label="EPAs Threshold"
        variant="outlined"
        fullWidth
      />
    </FormGroup>
  );
};

Thresholds.propTypes = {
  thresholds: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default Thresholds;
