import {
  GET_APTITUDES_BY_CATEGORY_ERROR,
  GET_APTITUDES_BY_CATEGORY_SUCCESS,
  GET_APTITUDES_BY_CATEGORY,
  GET_CLASSIFICATIONS_ERROR,
  GET_CLASSIFICATIONS_SUCCESS,
  GET_CLASSIFICATIONS,
  GET_SUBJECTS_ERROR,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS,
  POST_APTITUDES_ERROR,
  POST_APTITUDES_SUCCESS,
  POST_APTITUDES,
  POST_MILESTONES_ERROR,
  POST_MILESTONES_SUCCESS,
  POST_MILESTONES,
  POST_SUBJECTS_ERROR,
  POST_SUBJECTS_SUCCESS,
  POST_SUBJECTS
} from '../actions-type';

const doGetClassifications = programUuid => ({
  type: GET_CLASSIFICATIONS,
  payload: programUuid
});

const doGetClassificationsSuccess = result => ({
  type: GET_CLASSIFICATIONS_SUCCESS,
  payload: result
});

const doGetClassificationsError = error => ({
  type: GET_CLASSIFICATIONS_ERROR,
  payload: error
});

const doGetAptitudes = (programUuid, category) => ({
  type: GET_APTITUDES_BY_CATEGORY,
  payload: { programUuid, category }
});

const doGetAptitudesSuccess = result => ({
  type: GET_APTITUDES_BY_CATEGORY_SUCCESS,
  payload: result
});

const doGetAptitudesError = error => ({
  type: GET_APTITUDES_BY_CATEGORY_ERROR,
  payload: error
});

const doGetSubjects = programUuid => ({
  type: GET_SUBJECTS,
  payload: programUuid
});

const doGetSubjectsSuccess = result => ({
  type: GET_SUBJECTS_SUCCESS,
  payload: result
});

const doGetSubjectsError = error => ({
  type: GET_SUBJECTS_ERROR,
  payload: error
});

const doPostSubjects = (programUuid, subjects, subjectsRemove) => ({
  type: POST_SUBJECTS,
  payload: { programUuid, subjects, subjectsRemove }
});

const doPostSubjectsSuccess = result => ({
  type: POST_SUBJECTS_SUCCESS,
  payload: result
});

const doPostSubjectsError = error => ({
  type: POST_SUBJECTS_ERROR,
  payload: error
});

const doPostMilestoneExams = (milestones, conversionsRemove) => ({
  type: POST_MILESTONES,
  payload: { milestones, conversionsRemove }
});

const doPostMilestoneExamsSuccess = result => ({
  type: POST_MILESTONES_SUCCESS,
  payload: result
});

const doPostMilestoneExamsError = error => ({
  type: POST_MILESTONES_ERROR,
  payload: error
});

const doPostAptitudes = (
  programUuid,
  classifciations,
  classificationsRemove,
  examAptitudesRemove,
  conversionAptitudesRemove
) => ({
  type: POST_APTITUDES,
  payload: {
    programUuid,
    classifciations,
    classificationsRemove,
    examAptitudesRemove,
    conversionAptitudesRemove
  }
});

const doPostAptitudesSuccess = result => ({
  type: POST_APTITUDES_SUCCESS,
  payload: result
});

const doPostAptitudesError = error => ({
  type: POST_APTITUDES_ERROR,
  payload: error
});

export {
  doGetAptitudes,
  doGetAptitudesError,
  doGetAptitudesSuccess,
  doGetClassifications,
  doGetClassificationsError,
  doGetClassificationsSuccess,
  doGetSubjects,
  doGetSubjectsError,
  doGetSubjectsSuccess,
  doPostAptitudes,
  doPostAptitudesError,
  doPostAptitudesSuccess,
  doPostMilestoneExams,
  doPostMilestoneExamsError,
  doPostMilestoneExamsSuccess,
  doPostSubjects,
  doPostSubjectsError,
  doPostSubjectsSuccess
};
