import { attr, fk, Model } from 'redux-orm';
import {
  DELETE_COURSE_MASTER_SUCCESS,
  GET_COURSE_BY_STUDENT_SUCCESS,
  GET_COURSE_COLLECTIONS_SUCCESS,
  GET_COURSE_MASTER_BY_DEPARTMENT_SUCCESS,
  GET_COURSE_MASTER_SUCCESS,
  GET_COURSES_BY_DEPARTMENT_AY_SUCCESS,
  GET_SECTION_COMMITMENT_LOG_SUCCESS,
  GET_SYLLABUS_COURSE_SUCCESS,
  POST_COURSE_MASTER_SUCCESS,
  POST_COURSE_SUCCESS,
  PUT_COURSE_MASTER_SUCCESS
} from '../actions-type';

class CourseMaster extends Model {
  static reducer(action, CourseMaster, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_COURSE_MASTER_SUCCESS: {
        const course = { ...payload.courseMaster };
        CourseMaster.upsert(course);
        break;
      }
      case GET_COURSE_MASTER_BY_DEPARTMENT_SUCCESS: {
        const courseMasters = [...payload.courseMasters];
        courseMasters.map(courseMaster => CourseMaster.upsert(courseMaster));
        break;
      }
      case POST_COURSE_MASTER_SUCCESS: {
        const courseMaster = { ...payload.courseMaster };
        CourseMaster.upsert(courseMaster);
        break;
      }
      case PUT_COURSE_MASTER_SUCCESS: {
        const course = { ...payload.courseMaster };
        CourseMaster.upsert(course);
        break;
      }
      case DELETE_COURSE_MASTER_SUCCESS: {
        const course = { ...payload };
        CourseMaster.withId(course.courseMasterUuid).delete();
        break;
      }
      case GET_COURSES_BY_DEPARTMENT_AY_SUCCESS: {
        const courses = [...payload.courses];
        courses.forEach(course =>
          CourseMaster.upsert({
            ...course.course_master,
            termUuid: course.termUuid
          })
        );
        break;
      }
      case POST_COURSE_SUCCESS: {
        const course = { ...payload.course };
        CourseMaster.upsert(course.course_master);
        break;
      }
      case GET_SECTION_COMMITMENT_LOG_SUCCESS: {
        const { course } = payload.section;
        CourseMaster.upsert(course.course_master);
        break;
      }
      case GET_COURSE_BY_STUDENT_SUCCESS: {
        const courses = [...payload.courses];
        courses.forEach(course => {
          const { course_master } = course;

          CourseMaster.upsert(course_master);
        });
        break;
      }
      case GET_COURSE_COLLECTIONS_SUCCESS: {
        const courseCollections = [...payload.courseCollections];

        courseCollections.forEach(courseCollection => {
          const { courses } = courseCollection;

          if (courses?.length > 0) {
            courses.forEach(cm => {
              CourseMaster.upsert(cm);
            });
          }
        });

        break;
      }
      case GET_SYLLABUS_COURSE_SUCCESS: {
        const course = { ...payload.course };
        const { course_master } = course;
        CourseMaster.upsert(course_master);
        break;
      }
      default:
        return session;
    }
  }
}

CourseMaster.fields = {
  uuid: attr(),
  title: attr(),
  description: attr(),
  hours: attr(),
  courseNumber: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  departmentUuid: fk('Department', 'courseMasters')
};

CourseMaster.modelName = 'CourseMaster';
CourseMaster.options = {
  idAttribute: 'uuid'
};

export default CourseMaster;
