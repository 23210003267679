import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import Modal from '../Library/Modal/Modal';

export const ModalBody = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function SyllabusModalApprove({
  modalApproveOpen,
  handleModalSubmitSyllabusClose,
  handleSubmitForApproval
}) {
  return (
    <Modal open={modalApproveOpen} onClose={handleModalSubmitSyllabusClose}>
      <ModalBody>
        <p>
          Are you sure you are finished creating your syllabus and want to
          submit to the administrator for approval?
        </p>
        <ModalButtons>
          <ButtonInline onClick={() => handleModalSubmitSyllabusClose()}>
            Stay
          </ButtonInline>
          <ButtonInline
            onClick={() => {
              handleModalSubmitSyllabusClose();
              handleSubmitForApproval();
            }}
          >
            Submit
          </ButtonInline>
        </ModalButtons>
      </ModalBody>
    </Modal>
  );
}

SyllabusModalApprove.propTypes = {
  modalApproveOpen: PropTypes.bool,
  handleModalSubmitSyllabusClose: PropTypes.func,
  handleSubmitForApproval: PropTypes.func
};

SyllabusModalApprove.defaultProps = {
  modalApproveOpen: false,
  handleModalSubmitSyllabusClose: undefined,
  handleSubmitForApproval: undefined
};
