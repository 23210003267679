import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Header from '../Library/Header';
import {
  ContainerResize,
  ContainerPage,
  ElevateLow,
  ContainerContent
} from '../Library/Layout';
import SectionsContainer from './Sections/SectionsContainer';

const Info = styled.div`
  margin-bottom: 30px;
`;

export default function SyllabusCourse({ course, courseMaster }) {
  return (
    <ContainerResize>
      <Header
        title={`${courseMaster?.courseNumber} - ${courseMaster?.title}`}
        subtitle="Syllabus Course"
      />
      <ContainerPage>
        <ElevateLow>
          <Info>
            <ContainerContent>
              Description: {courseMaster?.description}
            </ContainerContent>
          </Info>
        </ElevateLow>
        <SectionsContainer courseUuid={course?.uuid} />
      </ContainerPage>
    </ContainerResize>
  );
}

SyllabusCourse.propTypes = {
  course: PropTypes.object,
  courseMaster: PropTypes.object
};

SyllabusCourse.defaultProps = {
  course: undefined,
  courseMaster: undefined
};
