import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import { PanelHeader } from '../../../../Library/DashboardSC';
import FacultyDefaultScoreViewFacultyPanelContainer from './FacultyDefaultScoreViewFacultyPanelContainer';

const FacultyPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 24px;
  width: calc(100% - 24px);
`;

const FacultyPanelHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  font-weight: 450;
`;

const FacultyPanelHeaderSub = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  padding-right: ${props => props.paddingRight};
  color: ${props => props.theme.fontColors.dark};
  text-align: right;
  }
`;

const FacultyDefaultScoreViewList = ({
  cumulativeTotalPotential,
  facultyScoreDataMap,
  partFacultyUsers,
  scoreType,
  students
}) => {
  return (
    <>
      <PanelHeader paddingLeft="0px">
        <FacultyPanelHeader>
          <FacultyPanelHeaderInfo>
            Faculty (Last name, First name)
          </FacultyPanelHeaderInfo>
          <FacultyPanelHeaderInfo>
            <FacultyPanelHeaderSub paddingRight="24px">
              Participation (%)
            </FacultyPanelHeaderSub>
            <FacultyPanelHeaderSub paddingRight="24px">
              Relative (%)
            </FacultyPanelHeaderSub>
            <FacultyPanelHeaderSub paddingRight="24px">
              Total Relative
            </FacultyPanelHeaderSub>
            <FacultyPanelHeaderSub paddingRight="44px">
              Total Potential
            </FacultyPanelHeaderSub>
          </FacultyPanelHeaderInfo>
        </FacultyPanelHeader>
      </PanelHeader>
      {partFacultyUsers.map(facultyUser => {
        return (
          <LazyLoad key={facultyUser.user?.uuid}>
            <FacultyDefaultScoreViewFacultyPanelContainer
              facultyScores={
                facultyScoreDataMap[facultyUser.user?.uuid]?.facultyScores
              }
              facultyUser={facultyUser.user}
              facultyTotalPotentialScore={
                facultyScoreDataMap[facultyUser.user?.uuid]
                  ?.facultyTotalPotentialScore
              }
              facultyTotalRelativeScore={
                facultyScoreDataMap[facultyUser.user?.uuid]
                  ?.facultyTotalRelativeScore
              }
              cumulativeTotalPotential={cumulativeTotalPotential}
              scoreType={scoreType}
              students={students}
            />
          </LazyLoad>
        );
      })}
    </>
  );
};

FacultyDefaultScoreViewList.propTypes = {
  cumulativeTotalPotential: PropTypes.string,
  facultyScoreDataMap: PropTypes.object,
  partFacultyUsers: PropTypes.array,
  scoreType: PropTypes.string,
  students: PropTypes.array
};

FacultyDefaultScoreViewList.defaultProps = {
  cumulativeTotalPotential: '',
  facultyScoreDataMap: {},
  partFacultyUsers: [],
  scoreType: '',
  students: []
};

export default FacultyDefaultScoreViewList;
