import React from 'react';
import styled, { css } from 'styled-components';
import { Body1, Subtitle2, Caption } from '@xcomp/xcomp-design-library';
import Divider from '@material-ui/core/Divider';

export const Feature = styled(({ selected, isLevel2, open, ...rest }) => (
  <div {...rest} />
))`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding-left: ${props => (props.isLevel2 && props.open ? '49px' : '13px')};
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  background: ${props =>
    props.selected ? props.theme.colors.grey[100] : 'none'};
  svg {
    fill: ${props => props.theme.fontColors.dark};
  }

  ${props =>
    props.selected &&
    css`
        border-left: 3px solid ${props => props.theme.colors.primary}
          background-color: ${props => props.theme.colors.grey[100]};

          svg {
            fill: ${props => props.theme.colors.primary}
          }
          p {
            font-weight: bold;
            color: ${props => props.theme.colors.primary}
          }
      `}

  &:hover,
    &:focus,
    &:active {
    background: ${props => props.theme.colors.grey[100]};
    border-left: ${props => `3px solid ${props.theme.colors.primary}`};

    svg {
      fill: ${props => props.theme.colors.primary};
    }
    p {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

export const FeatureIcon = styled.div`
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: justify;
`;

export const FeatureLabel = styled(({ drawerOpen, ...rest }) => (
  <Body1 {...rest} />
))`
  margin-left: 18px;
  white-space: nowrap;
  width: 100%;
  color: ${props => props.theme.fontColors.dark};
  opacity: ${props => (props.drawerOpen ? '1' : '0')};
`;

export const ToggleNavSection = styled.div`
  color: ${props => props.theme.fontColors.darker};
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-weight: 800;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    background: ${props => props.theme.colors.grey[100]};
  }
`;

export const ToggleNavSectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`;

export const SchoolName = styled(Body1)`
  white-space: nowrap;
  color: ${props => props.theme.fontColors.darker};
  margin: 0;
  line-height: 1.4;
  &:hover {
    color: ${props => props.theme.fontColors.darker};
  }
`;

export const ToggleNavSectionCaption = styled(Caption)`
  font-size: 12px;
  color: ${props => props.theme.fontColors.darker};
  margin: 0;
  line-height: 1.4;
`;

export const Role = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  justify-content: ${props => (props.open ? 'flex-start' : 'center')};
  padding-left: ${props => (props.open ? '16px' : '0px')};
`;

export const RoleLabel = styled(Subtitle2)`
  white-space: nowrap;
  color: ${props => props.theme.fontColors.dark};
`;

export const RoleIcon = styled(Subtitle2)`
  && {
    font-size: 12px;
    color: ${props => props.theme.fontColors.dark};
  }
`;

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75px;
  align-items: ${props => (props.open ? 'flex-start' : 'center')};
  color: rgba(0, 0, 0, 0.6);
  justify-content: ${props => (props.open ? 'center' : 'center')};
  padding-left: ${props => (props.open ? '16px' : '0px')};
`;

export const DrawerHeaderTitle = styled.div`
  white-space: nowrap;
  font-size: 14px;
  text-transform: uppercase;
  color: ${props => props.theme.fontColors.dark};
`;

export const DrawerHeaderSubTitle = styled.div`
  white-space: nowrap;
  font-size: 12px;
  color: ${props => props.theme.fontColors.dark};
`;

export const NavigationDividerLayout = styled.div`
  && {
    margin-top: 6px;
    margin-bottom: 0;
  }
`;

export const NavigationDivider = () => (
  <NavigationDividerLayout>
    <Divider />
  </NavigationDividerLayout>
);
