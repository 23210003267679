import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import {
  ContainerResize,
  ContainerPage,
  ContainerContent,
  ElevateLow
} from '../../Library/Layout';

import { validateInputString } from '../../../helpers/validation/validateGeneric';

import Header from '../../Library/Header';
import FormField from '../../Library/FormField';

// const Label = styled.div`
//   color: rgba(0, 0, 0, 0.6);
//   font-size: 12px;
//   letter-spacing: 0.21px;
//   line-height: 20px;
// `;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const ColorBox = styled.div`
  width: 60px;
  height: 30px;
  background-color: ${props => props.color};
  border-radius: 5px;
  border: 3px solid ${props => props.theme.colors.black};
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
`;

const SchoolHeader = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
`;

const SchoolAdd = ({
  handleChange,
  handleCreateSchool,
  // imageUpload,
  abbreviation,
  schoolName,
  hasErrors
}) => {
  return (
    <ContainerResize>
      <Header title="School Management" subtitle="Add school" />

      <ContainerPage>
        <ElevateLow>
          <ContainerContent>
            <SchoolHeader>School Details</SchoolHeader>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <FormField
                  id="abbreviation"
                  name="abbreviation"
                  label="Abbreviation*"
                  value={abbreviation}
                  onBlur={handleChange}
                  handleValidate={validateInputString}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={9}>
                <FormField
                  id="schoolName"
                  name="schoolName"
                  label="School Name*"
                  value={schoolName}
                  onBlur={handleChange}
                  handleValidate={validateInputString}
                  hasErrors={hasErrors}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Label>Add School Logo</Label>
                <form
                  method="post"
                  encType="multipart/form-data"
                  action="/imageUpload"
                >
                  <input
                    type="file"
                    id="single"
                    name="photo"
                    onChange={event => imageUpload(event)}
                  />
                </form>
              </Grid> */}
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <ButtonInline onClick={() => handleCreateSchool()}>
                    Save
                  </ButtonInline>
                </Grid>
              </Grid>
            </Grid>
          </ContainerContent>
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
};
SchoolAdd.propTypes = {
  hasErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  handleCreateSchool: PropTypes.func,
  abbreviation: PropTypes.string,
  schoolName: PropTypes.string
  // imageUpload: PropTypes.func
};
SchoolAdd.defaultProps = {
  hasErrors: false,
  handleChange: undefined,
  handleCreateSchool: undefined,
  abbreviation: '',
  schoolName: ''
  // imageUpload: undefined
};

export default SchoolAdd;
export { ColorBox, Cover };
