import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  doGetApplicantPhysicalSkillsReportTypes,
  doClearApplicantPhysicalSkillsReport,
  doPutApplicantPhysicalSkillsReport
} from '../../../../redux/actions/applicantReportActions';
import { doGetClassifications } from '../../../../redux/actions/classificationActions';
import { selectExamMilestones } from '../../../../redux/selectors/examMilestoneSelectors';
import { selectExamAptitude } from '../../../../redux/selectors/examAptitudeSelectors';
import { classificationsSelectorByCategorySource } from '../../../../redux/selectors/classificationSelectors';
import { applicantPhysicalSkillsReportTypesSelector } from '../../../../redux/selectors/applicantReportTypesSelectors';
import {
  getApplicantCognitiveReportFilters,
  sortByNestedProperty,
  sortByDecimalProperty
} from '../../../../helpers/utilities';
import {
  applicantReportTranscriptSourceOptions,
  applicantReportTranscriptScopeOptions
} from '../../../../helpers/constants';
import CohortApplicantCognitiveReport from './CohortApplicantPhysicalSkillsReport';

const CohortApplicantPhysicalSkillsReportContainer = ({
  cohortUuid,
  programUuid
}) => {
  const dispatch = useDispatch();
  const [shouldAnonymizeReport, setShouldAnonymizeReport] = useState(false);
  const [filterSelection, setFilterSelection] = useState({
    property: 'user.lastName',
    decimalProperty: false,
    reversed: false
  });
  const [transcriptSource, setTranscriptSource] = useState('');
  const [transcriptSkill, setTranscriptSkill] = useState('');
  const [transcriptSkillDisabled, setTranscriptSkillDisabled] = useState(true);
  const [transcriptSkillOptions, setTranscriptSkillOptions] = useState(
    applicantReportTranscriptScopeOptions
  );
  const [transcriptExam, setTranscriptExam] = useState('');
  const [transcriptExamDisabled, setTranscriptExamDisabled] = useState(true);
  const [transcriptExamOptions, setTranscriptExamOptions] = useState(
    applicantReportTranscriptScopeOptions
  );

  const [reportSummaryFilters, setReportSummaryFilters] = useState([]);
  const [transcriptReportTypeError, setTranscriptReportTypeError] = useState(
    false
  );

  const physicalSkillsReportState = useSelector(
    state => state.applicantPhysicalSkillsReportState
  );

  const applicantPhysicalSkillsReportTypes = useSelector(
    applicantPhysicalSkillsReportTypesSelector,
    _.isEqual
  );

  const physicalSkillsProgramSkills = useSelector(
    state =>
      classificationsSelectorByCategorySource(
        state,
        programUuid,
        'Physical-Skills',
        'Program'
      ),
    _.isEqual
  );

  const physicalSkillsMilestoneSkills = useSelector(
    state =>
      classificationsSelectorByCategorySource(
        state,
        programUuid,
        'Physical-Skills',
        'Milestone'
      ),
    _.isEqual
  );

  const examMilestones = useSelector(selectExamMilestones);

  const examAptitudes = useSelector(selectExamAptitude);

  const isLoadingPhysicalSkillsReport =
    physicalSkillsReportState?.loadingPhysicalSkillsReport;

  const physicalSkillsReportMap = _.keyBy(
    applicantPhysicalSkillsReportTypes,
    'name'
  );

  const getTranscriptExamOptions = classificationUuid => {
    const exams =
      transcriptSource === 'Program' ? examAptitudes : examMilestones;
    const val = exams.reduce((options, exam) => {
      if (exam.classificationUuid === classificationUuid) {
        options.push({
          value: exam.uuid,
          label: exam.examName
        });
      }
      return options;
    }, []);
    return val;
  };

  const handleSelectTranscriptSource = event => {
    const { value } = event.target;
    dispatch(doClearApplicantPhysicalSkillsReport());
    setTranscriptSource(value);
    setTranscriptReportTypeError(false);
    if (value === 'Cumulative') {
      setTranscriptSkill('');
      setTranscriptSkillDisabled(true);
    }
    if (value === 'Program') {
      setTranscriptSkill('Cumulative');
      setTranscriptSkillDisabled(false);
    }
    if (value === 'Milestone') {
      setTranscriptSkill('Cumulative');
      setTranscriptSkillDisabled(false);
    }
  };

  const handleSelectTranscriptSkill = event => {
    const { value } = event.target;
    dispatch(doClearApplicantPhysicalSkillsReport());
    if (
      (transcriptSource === 'Program' &&
        physicalSkillsProgramSkills.some(subject => subject.uuid === value)) ||
      (transcriptSource === 'Milestone' &&
        physicalSkillsMilestoneSkills.some(subject => subject.uuid === value))
    ) {
      const examOptions = getTranscriptExamOptions(value);
      setTranscriptExamOptions(
        applicantReportTranscriptScopeOptions.concat(examOptions)
      );
      setTranscriptExam('Cumulative');
    }
    setTranscriptSkill(value);
    setReportSummaryFilters([]);
  };

  const handleSelectTranscriptExam = event => {
    const { value } = event.target;
    dispatch(doClearApplicantPhysicalSkillsReport());
    setTranscriptExam(value);
    setReportSummaryFilters([]);
  };

  const getSelectedReportType = (
    transcriptSource,
    transcriptSkill,
    transcriptExam
  ) => {
    if (transcriptSource === 'Cumulative') {
      return physicalSkillsReportMap['Physical-Skills Cumulative'];
    }
    if (
      transcriptSource === 'Program' &&
      transcriptSkill !== 'Cumulative' &&
      transcriptExam === 'Cumulative'
    ) {
      return physicalSkillsReportMap['Program Skill'];
    }
    if (
      transcriptSource === 'Program' &&
      transcriptSkill !== 'Cumulative' &&
      transcriptExam !== 'Cumulative'
    ) {
      return physicalSkillsReportMap['Program Skill Exam'];
    }
    if (transcriptSource === 'Program' && transcriptSkill === 'Cumulative') {
      return physicalSkillsReportMap['Program Cumulative'];
    }
    if (
      transcriptSource === 'Milestone' &&
      transcriptSkill !== 'Cumulative' &&
      transcriptExam === 'Cumulative'
    ) {
      return physicalSkillsReportMap['Milestone Skill'];
    }
    if (
      transcriptSource === 'Milestone' &&
      transcriptSkill !== 'Cumulative' &&
      transcriptExam !== 'Cumulative'
    ) {
      return physicalSkillsReportMap['Milestone Skill Exam'];
    }
    if (transcriptSource === 'Milestone' && transcriptSkill === 'Cumulative') {
      return physicalSkillsReportMap['Milestone Cumulative'];
    }
  };

  const handleApplyReportType = () => {
    if (transcriptSource === '') {
      setTranscriptReportTypeError(true);
    } else {
      const selectedReportType = getSelectedReportType(
        transcriptSource,
        transcriptSkill,
        transcriptExam
      );
      const classificationUuid =
        transcriptSource === 'Cumulative' || transcriptSkill === 'Cumulative'
          ? null
          : transcriptSkill;
      const examMilestoneUuid =
        transcriptSource === 'Cumulative' ||
        transcriptSource === 'Program' ||
        transcriptSkill === 'Cumulative' ||
        transcriptExam === 'Cumulative'
          ? null
          : transcriptExam;
      const examAptitudeUuid =
        transcriptSource === 'Cumulative' ||
        transcriptSource === 'Milestone' ||
        transcriptSkill === 'Cumulative' ||
        transcriptExam === 'Cumulative'
          ? null
          : transcriptExam;
      dispatch(doClearApplicantPhysicalSkillsReport());
      dispatch(
        doPutApplicantPhysicalSkillsReport(
          cohortUuid,
          selectedReportType.uuid,
          classificationUuid,
          examMilestoneUuid,
          examAptitudeUuid
        )
      );
    }

    const filterOptions = getApplicantCognitiveReportFilters();
    setReportSummaryFilters(filterOptions);
  };

  const handleApplyFilterSelection = (
    selected,
    isDecimalProperty,
    isReversed
  ) => {
    setFilterSelection({
      property: selected,
      decimalProperty: isDecimalProperty,
      reversed: isReversed
    });
  };

  const handleToggleShouldAnonymizeReport = event => {
    const { checked } = event.target;
    setShouldAnonymizeReport(checked);
  };

  useEffect(() => {
    dispatch(doGetApplicantPhysicalSkillsReportTypes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(doClearApplicantPhysicalSkillsReport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (programUuid) dispatch(doGetClassifications(programUuid));
  }, [dispatch, programUuid]);

  useEffect(() => {
    if (transcriptSource === 'Milestone' && physicalSkillsMilestoneSkills) {
      setTranscriptSkillOptions(
        applicantReportTranscriptScopeOptions.concat(
          physicalSkillsMilestoneSkills.map(physicalSkillsMilestoneSkill => ({
            value: physicalSkillsMilestoneSkill.uuid,
            label: physicalSkillsMilestoneSkill.scope
          }))
        )
      );
    }
    if (transcriptSource === 'Program' && physicalSkillsProgramSkills) {
      setTranscriptSkillOptions(
        applicantReportTranscriptScopeOptions.concat(
          physicalSkillsProgramSkills.map(physicalSkillsProgramSkill => ({
            value: physicalSkillsProgramSkill.uuid,
            label: physicalSkillsProgramSkill.scope
          }))
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptSource]);

  useEffect(() => {
    if (
      (transcriptSource === 'Milestone' || transcriptSource === 'Program') &&
      transcriptSkill !== 'Cumulative'
    ) {
      setTranscriptExamDisabled(false);
    } else {
      setTranscriptExamDisabled(true);
      setTranscriptExam('');
    }
  }, [transcriptSource, transcriptSkill]);

  const filteredReportData = filterSelection.decimalProperty
    ? physicalSkillsReportState?.physicalSkillsReport.sort((a, b) =>
        filterSelection.reversed
          ? sortByDecimalProperty(b, a, filterSelection.property)
          : sortByDecimalProperty(a, b, filterSelection.property)
      )
    : physicalSkillsReportState?.physicalSkillsReport.sort((a, b) =>
        filterSelection.reversed
          ? sortByNestedProperty(b, a, filterSelection.property)
          : sortByNestedProperty(a, b, filterSelection.property)
      );
  return (
    <CohortApplicantCognitiveReport
      isLoadingPhysicalSkillsReport={isLoadingPhysicalSkillsReport}
      onApplyFilterSelection={handleApplyFilterSelection}
      onApplyReportType={handleApplyReportType}
      onSelectTranscriptExam={handleSelectTranscriptExam}
      onSelectTranscriptSource={handleSelectTranscriptSource}
      onSelectTranscriptSkill={handleSelectTranscriptSkill}
      onToggleShouldAnonymizeReport={handleToggleShouldAnonymizeReport}
      reportData={filteredReportData}
      reportHeaders={physicalSkillsReportState?.reportHeaders}
      reportSummary={physicalSkillsReportState?.reportSummary}
      reportSummaryFilters={reportSummaryFilters}
      reportSummaryHeaders={physicalSkillsReportState?.reportSummaryHeaders}
      shouldAnonymizeReport={shouldAnonymizeReport}
      transcriptReportTypeError={transcriptReportTypeError}
      transcriptExam={transcriptExam}
      transcriptExamDisabled={transcriptExamDisabled}
      transcriptExamOptions={transcriptExamOptions}
      transcriptSkill={transcriptSkill}
      transcriptSkillDisabled={transcriptSkillDisabled}
      transcriptSkillOptions={transcriptSkillOptions}
      transcriptSource={transcriptSource}
      transcriptSourceOptions={applicantReportTranscriptSourceOptions}
    />
  );
};

CohortApplicantPhysicalSkillsReportContainer.propTypes = {
  cohortUuid: PropTypes.string.isRequired,
  programUuid: PropTypes.string.isRequired
};

CohortApplicantPhysicalSkillsReportContainer.defaultProps = {};

export default CohortApplicantPhysicalSkillsReportContainer;
