import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import StudentPartGradeData from '../GradeData/StudentPartGradeData';
import StudentCollectionGradeData from '../GradeData/StudentCollectionGradeData';
import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../../../helpers/format/user.format';

import { TableRow, StickyInfoTd, FillerTd, StudentInfoText } from './SharedSC';

const PartGradeResult = ({
  gradeResult,
  students,
  idx,
  partUuid,
  assessmentCollections,
  failThreshold,
  isExpanded,
  useFillerColumn,
  fillerColumnWidth
}) => {
  const student = students[gradeResult?.studentUuid] || {};
  const evenStudent = idx % 2 === 0;
  const studentFormattedLastName = formatLastName(
    student.user?.lastName,
    student.user?.marriedLastName,
    student.user?.suffix
  );
  const studentFormattedFirstMiddleName = formatFirstNameMiddleName(
    student.user?.firstName,
    student.user?.middleName
  );
  const studentFullName =
    student.user?.firstName && student.user?.lastName
      ? `${studentFormattedLastName}, ${studentFormattedFirstMiddleName}`
      : 'N/A';

  const studentRank = gradeResult.classRank;

  return (
    <TableRow key={student.uuid}>
      <StudentPartGradeData
        evenStudent={evenStudent}
        partUuid={partUuid}
        studentUuid={student.userUuid}
        studentRank={studentRank}
      />
      <StickyInfoTd greyOne={!evenStudent} left="225px" borderRightStrong>
        <Tooltip title={studentFullName}>
          <StudentInfoText>{studentFullName}</StudentInfoText>
        </Tooltip>
      </StickyInfoTd>
      {assessmentCollections.map(assessmentCollection => {
        return (
          <StudentCollectionGradeData
            key={assessmentCollection.uuid}
            assessmentCollection={assessmentCollection}
            evenStudent={evenStudent}
            failThreshold={failThreshold}
            isExpanded={isExpanded}
            studentUuid={student.userUuid}
          />
        );
      })}
      {useFillerColumn && (
        <FillerTd width={fillerColumnWidth} borderTop greyOne={!evenStudent} />
      )}
    </TableRow>
  );
};

PartGradeResult.propTypes = {
  assessmentCollections: PropTypes.array,
  failThreshold: PropTypes.string,
  partUuid: PropTypes.string,
  students: PropTypes.object,
  gradeResult: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool,
  useFillerColumn: PropTypes.bool,
  fillerColumnWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

PartGradeResult.defaultProps = {
  assessmentCollections: [],
  failThreshold: '',
  isExpanded: false,
  partUuid: '',
  students: {},
  useFillerColumn: false
};

export default PartGradeResult;
