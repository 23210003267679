import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import FacultyAlternativeScoreViewFacultyPanelContainer from './FacultyAlternativeScoreViewFacultyPanelContainer';

const StudentPanelAccordion = styled(
  ({ withBottomBorder, withTopBorder, criticalFailure, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label,
  .column_two_label {
    flex-grow: 0;
    width: 100px;
    text-align: right;
  }

  .accordion_row_label div {
    font-size: 16px;
    letter-spacing: 0.15;
    color: ${props => props.theme.fontColors.darker};
  }

  .column_one_label {
    margin-right: 16px;
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props =>
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.theme.fontColors.darker};
    }
  }

  .column_two_label {
    span {
      letter-spacing: 0.15;
      color: ${props => props.theme.fontColors.darker};
    }
  }
  .hover-effect {
    padding-right: 44px;
  }
`;

const FacultyPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 48px;
  width: calc(100% - 48px);
`;

const FacultyPanelHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  font-weight: 450;
`;

const FacultyPanelHeaderSub = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  padding-right: ${props => props.paddingRight};
  color: ${props => props.theme.fontColors.dark};
  text-align: right;
  }
`;

const FacultyAlternaticeScoreViewStudentPanel = ({
  faculty,
  scoreType,
  studentFullName,
  studentScoresByFaculty,
  studentTotalPotentialScore,
  studentTotalRelativeScore
}) => {
  return (
    <StudentPanelAccordion
      withBottomBorder
      expandWidth="66px"
      height="50px"
      label={`${studentFullName}`}
      columnOne={studentTotalRelativeScore}
      columnTwo={studentTotalPotentialScore}
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
    >
      <FacultyPanelHeader>
        <FacultyPanelHeaderInfo>
          Faculty (Last name, First name)
        </FacultyPanelHeaderInfo>
        <FacultyPanelHeaderInfo>
          <FacultyPanelHeaderSub paddingRight="24px">
            Total Relative
          </FacultyPanelHeaderSub>
          <FacultyPanelHeaderSub paddingRight="44px">
            Total Potential
          </FacultyPanelHeaderSub>
        </FacultyPanelHeaderInfo>
      </FacultyPanelHeader>
      {faculty.map(facultyOption => (
        <FacultyAlternativeScoreViewFacultyPanelContainer
          key={facultyOption.user?.uuid}
          facultyUser={facultyOption}
          scoreType={scoreType}
          studentFacultyScores={
            studentScoresByFaculty[facultyOption.user?.uuid]
          }
        />
      ))}
    </StudentPanelAccordion>
  );
};

FacultyAlternaticeScoreViewStudentPanel.propTypes = {
  faculty: PropTypes.array,
  scoreType: PropTypes.string,
  studentFullName: PropTypes.string,
  studentScoresByFaculty: PropTypes.object,
  studentTotalPotentialScore: PropTypes.string,
  studentTotalRelativeScore: PropTypes.string
};
FacultyAlternaticeScoreViewStudentPanel.defaultProps = {
  faculty: [],
  scoreType: '',
  studentFullName: '',
  studentScoresByFaculty: {},
  studentTotalPotentialScore: '',
  studentTotalRelativeScore: ''
};

export default FacultyAlternaticeScoreViewStudentPanel;
