import { call, put, takeEvery } from 'redux-saga/effects';
import { getHierarchy } from '../../api/hierarchyApi';

import {
  doGetHierarchySuccess,
  doGetHierarchyError
} from '../actions/hierarchyActions';

import { GET_HIERARCHY } from '../actions-type';

function* handleGetHierachy() {
  try {
    const result = yield call(getHierarchy);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetHierarchySuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetHierarchyError(error));
  }
}

const hierarchy = [takeEvery(GET_HIERARCHY, handleGetHierachy)];

export { hierarchy };
