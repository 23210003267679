import { call, put, takeEvery } from 'redux-saga/effects';
import { putInstitution } from '../../api/institutionApi';

import {
  doPutInstitutionSuccess,
  doPutInstitutionError
} from '../actions/institutionActions';

import { PUT_INSTITUTION } from '../actions-type';

function* handlePutInstitution(action) {
  try {
    const result = yield call(
      putInstitution,
      action.payload.institution,
      action.payload.picture
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutInstitutionSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutInstitutionError(error));
  }
}

const institution = [takeEvery(PUT_INSTITUTION, handlePutInstitution)];

export { institution };
