import React from 'react';
import PropTypes from 'prop-types';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import { ContainerPage, ContainerActions } from '../../../Library/Layout';

import LetterGradeShowContainer from './LetterGradeShow/LetterGradeShowContainer';
import LetterGradeAddContainer from './LetterGradeAdd/LetterGradeAddContainer';
import LetterGradeEditContainer from './LetterGradeEdit/LetterGradeEditContainer';

const LetterGrades = ({
  letterGrades,
  letterGradeAdd,
  selectedSchoolUuid,
  handleAddMode,
  handleAddClose,
  handleEditMode,
  handleShowMode,
  handleDeleteModalOpen
}) => {
  return (
    <div>
      <ContainerActions paddingTop="20px">
        <ButtonPrimary onClick={() => handleAddMode()}>Add Grade</ButtonPrimary>
      </ContainerActions>
      <ContainerPage paddingTop="60px">
        {letterGradeAdd && (
          <LetterGradeAddContainer
            handleAddClose={handleAddClose}
            selectedSchoolUuid={selectedSchoolUuid}
          />
        )}
        {letterGrades
          .sort((a, b) => b.rangeHigh - a.rangeHigh)
          .map(letterGrade => {
            if (letterGrade.mode === 'show') {
              return (
                <LetterGradeShowContainer
                  key={letterGrade.uuid}
                  letterGrade={letterGrade}
                  handleEditMode={handleEditMode}
                  handleDeleteModalOpen={handleDeleteModalOpen}
                />
              );
            } else if (letterGrade.mode === 'edit') {
              return (
                <LetterGradeEditContainer
                  key={letterGrade.uuid}
                  letterGrade={letterGrade}
                  handleShowMode={handleShowMode}
                />
              );
            } else {
              return <div />;
            }
          })}
      </ContainerPage>
    </div>
  );
};

LetterGrades.propTypes = {
  letterGrades: PropTypes.array,
  letterGradeAdd: PropTypes.bool,
  selectedSchoolUuid: PropTypes.string,
  handleAddClose: PropTypes.func,
  handleAddMode: PropTypes.func,
  handleEditMode: PropTypes.func,
  handleShowMode: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func
};
LetterGrades.defaultProps = {
  letterGrades: [],
  letterGradeAdd: false,
  selectedSchoolUuid: '',
  handleAddClose: undefined,
  handleAddMode: undefined,
  handleEditMode: undefined,
  handleShowMode: undefined,
  handleDeleteModalOpen: undefined
};

export default LetterGrades;
