import {
  CLEAR_COMPETENCY_GRID_REPORT,
  POST_COHORT_COMPETENCY_GRID,
  POST_COHORT_COMPETENCY_GRID_ERROR,
  POST_COHORT_COMPETENCY_GRID_SUCCESS,
  SET_IS_COMPETENCY_REPORT_LOADING,
  SET_IS_RESULT_LOADED,
  SET_SHOW_TABLE_MODAL,
  SET_TABLE_ZOOM
} from '../actions-type';

export const INITIAL_STATE = {
  currentReportCohortUuid: '',
  selectedCompetencyCollectionUuid: 'all',
  activeCompetencyGridMap: null,
  delayedCompetencyGridMap: null,
  competencyDeviationsMap: null,
  hasFetchedReport: false,
  hasActiveScores: false,
  hasDelayedScores: false,
  isLoading: false,
  zoom: 1,
  showTableModal: true,
  isResultLoaded: false,
};

const cohortCompetencyReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_COHORT_COMPETENCY_GRID_SUCCESS: {
      const {
        competencyScoresActiveComplete,
        competencyScoresDelayed,
        competencyDeviations
      } = action.payload;

      const isActiveScoresNull =
        Object.keys(competencyScoresActiveComplete).length === 0;
      const isDelayedScoresNull =
        Object.keys(competencyScoresDelayed).length === 0;

      const isLoading = isActiveScoresNull ? false : state.isLoading;

      return {
        ...state,
        isLoading,
        competencyDeviationsMap: competencyDeviations,
        activeCompetencyGridMap: !isActiveScoresNull
          ? competencyScoresActiveComplete
          : null,
        delayedCompetencyGridMap: !isDelayedScoresNull
          ? competencyScoresDelayed
          : null,
        hasActiveScores: !isActiveScoresNull,
        hasDelayedScores: !isDelayedScoresNull,
        hasFetchedReport: true
      };
    }
    case POST_COHORT_COMPETENCY_GRID_ERROR: {
      return {
        ...state,
        isLoading: false,
        hasActiveScores: false,
        hasDelayedScores: false,
        hasFetchedReport: true
      };
    }
    case POST_COHORT_COMPETENCY_GRID: {
      const { cohortUuid, competencyCollectionUuid } = action.payload;

      return {
        ...INITIAL_STATE,
        currentReportCohortUuid: cohortUuid,
        selectedCompetencyCollectionUuid: competencyCollectionUuid,
        isLoading: true,
        hasFetchedReport: false
      };
    }
    case SET_IS_COMPETENCY_REPORT_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    }
    case CLEAR_COMPETENCY_GRID_REPORT: {
      return {
        ...INITIAL_STATE
      };
    }

    case SET_TABLE_ZOOM: {
      if (state.zoom !== action.payload.zoom) {
        return {
          ...state,
          zoom: action.payload.zoom,
        };
      }
      return state;
    }
    case SET_IS_RESULT_LOADED: {
      return {
        ...state,
        isResultLoaded: action.payload.isResultLoaded,
      };
    }
    case SET_SHOW_TABLE_MODAL: {
      return {
        ...state,
        showTableModal: action.payload.showTableModal,
      };
    }
    default:
      return state;
  }
};

export default cohortCompetencyReportReducer;
