import fetch from '../helpers/fetch';

const getStudentRoster = sectionUuid =>
  fetch(`/api/section/${sectionUuid}/students`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postUserSection = userSection =>
  fetch(`/api/user-section/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(userSection)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchUsersSectionStatus = users =>
  fetch(`/api/users-section/status`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(users)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postUploadStudentsSection = payload =>
  fetch(`/api/users-section/upload/students/section`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(payload)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteUsersSection = students =>
  fetch(`/api/users-section/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(students)
  })
    .then(res => res)
    .catch(error => error);

export {
  getStudentRoster,
  postUserSection,
  patchUsersSectionStatus,
  postUploadStudentsSection,
  deleteUsersSection
};
