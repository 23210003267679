import Decimal from "decimal.js-light";
import { useEffect, useState } from "react";

export const useCompletionPercentage = (completed, remaining, remainingPercentage)=>{
	const [percentage, setPercentage] = useState('0');

	useEffect(() => {
		let completedAmount
		let remainingAmount
		if(remainingPercentage) {
			completedAmount = remaining?.reduce((a, c) => c?.blocks?.length > 0 ? a + c?.blocks?.length : a + 1, 0)
			remainingAmount = completed?.reduce((a, c) => c?.assessmentBlocks?.length > 0 ? a + c?.assessmentBlocks?.length : a + 1, 0)
		} else {
			completedAmount = completed?.reduce((a, c) => a + c?.assessmentBlocks?.length, 0)
			remainingAmount = remaining?.reduce((a, c) => a + c?.blocks?.length, 0)
		}

		const completionRate = new Decimal(completedAmount);
		const remainingRate = new Decimal(remainingAmount);

		const rate = completionRate
			.dividedBy(completionRate.add(remainingRate))
			.times(100)
			.toFixed(2);
		setPercentage(rate);
	}, [completed, remaining, remainingPercentage]);

	return percentage;
}