import {
  CLEAR_CREATED,
  GET_COMPETENCY_COLLECTIONS,
  GET_COMPETENCY_COLLECTIONS_SUCCESS,
  GET_COMPETENCY_COLLECTIONS_ERROR,
  SEARCH_MICROCOMPETENCY_TOPICS,
  SEARCH_MICROCOMPETENCY_TOPICS_SUCCESS,
  SEARCH_MICROCOMPETENCY_TOPICS_ERROR,
  POST_COMPETENCY_COLLECTION,
  POST_COMPETENCY_COLLECTION_SUCCESS,
  POST_COMPETENCY_COLLECTION_ERROR,
  PUT_COMPETENCY_COLLECTION,
  PUT_COMPETENCY_COLLECTION_SUCCESS,
  PUT_COMPETENCY_COLLECTION_ERROR,
  DELETE_COMPETENCY_COLLECTION,
  DELETE_COMPETENCY_COLLECTION_SUCCESS,
  DELETE_COMPETENCY_COLLECTION_ERROR,
  GET_COMPETENCY_COLLECTION,
  GET_COMPETENCY_COLLECTION_SUCCESS,
  GET_COMPETENCY_COLLECTION_ERROR,
  GET_COMPETENCY_COLLECTION_AND_SCORES,
  GET_COMPETENCY_COLLECTION_AND_SCORES_ERROR,
  GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS
} from '../actions-type';

const doClearCreated = () => ({ type: CLEAR_CREATED });

const doGetCompetencyCollection = competencyCollectionUuid => ({
  type: GET_COMPETENCY_COLLECTION,
  payload: {
    competencyCollectionUuid
  }
});

const doGetCompetencyCollectionSuccess = data => ({
  type: GET_COMPETENCY_COLLECTION_SUCCESS,
  payload: {
    competencyCollection: data.competencyCollection
  }
});

const doGetCompetencyCollectionError = error => ({
  type: GET_COMPETENCY_COLLECTION_ERROR,
  payload: error
});

const doGetCompetencyCollections = programUuid => ({
  type: GET_COMPETENCY_COLLECTIONS,
  payload: {
    programUuid
  }
});

const doGetCompetencyCollectionsSuccess = data => ({
  type: GET_COMPETENCY_COLLECTIONS_SUCCESS,
  payload: {
    competencyCollections: data.competencyCollections
  }
});

const doGetCompetencyCollectionsError = error => ({
  type: GET_COMPETENCY_COLLECTIONS_ERROR,
  payload: error
});

const doSearchMicrocompetencyTopics = (searchTerm, searchType) => ({
  type: SEARCH_MICROCOMPETENCY_TOPICS,
  payload: {
    searchTerm,
    searchType
  }
});

const doSearchMicrocompetencyTopicsSuccess = data => ({
  type: SEARCH_MICROCOMPETENCY_TOPICS_SUCCESS,
  payload: {
    topics: data.topics
  }
});

const doSearchMicrocompetencyTopicsError = error => ({
  type: SEARCH_MICROCOMPETENCY_TOPICS_ERROR,
  payload: error
});

const doPostCompetencyCollection = newCompetencyCollection => ({
  type: POST_COMPETENCY_COLLECTION,
  payload: {
    newCompetencyCollection
  }
});

const doPostCompetencyCollectionSuccess = data => ({
  type: POST_COMPETENCY_COLLECTION_SUCCESS,
  payload: {
    createdCompetencyCollection: data.createdCompetencyCollection
  }
});

const doPostCompetencyCollectionError = error => ({
  type: POST_COMPETENCY_COLLECTION_ERROR,
  payload: error
});

const doPutCompetencyCollection = competencyCollectionUpdates => ({
  type: PUT_COMPETENCY_COLLECTION,
  payload: {
    competencyCollectionUpdates
  }
});

const doPutCompetencyCollectionSuccess = data => ({
  type: PUT_COMPETENCY_COLLECTION_SUCCESS,
  payload: {
    updatedCompetencyCollection: data.updatedCompetencyCollection
  }
});

const doPutCompetencyCollectionError = error => ({
  type: PUT_COMPETENCY_COLLECTION_ERROR,
  payload: error
});

const doDeleteCompetencyCollection = uuid => ({
  type: DELETE_COMPETENCY_COLLECTION,
  payload: {
    uuid
  }
});

const doDeleteCompetencyCollectionSuccess = data => ({
  type: DELETE_COMPETENCY_COLLECTION_SUCCESS,
  payload: {
    deletedCompetencyCollection: data.deletedCompetencyCollection
  }
});

const doDeleteCompetencyCollectionError = error => ({
  type: DELETE_COMPETENCY_COLLECTION_ERROR,
  payload: error
});

const doGetCompetencyCollectionsAndScores = (programUuid, userUuid) => ({
  type: GET_COMPETENCY_COLLECTION_AND_SCORES,
  payload: {
    programUuid,
    userUuid
  }
});

const doGetCompetencyCollectionsAndScoresSuccess = payload => ({
  type: GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS,
  payload
});

const doGetCompetencyCollectionsAndScoresError = error => ({
  type: GET_COMPETENCY_COLLECTION_AND_SCORES_ERROR,
  payload: error
});

export {
  doClearCreated,
  doGetCompetencyCollection,
  doGetCompetencyCollectionSuccess,
  doGetCompetencyCollectionError,
  doGetCompetencyCollections,
  doGetCompetencyCollectionsSuccess,
  doGetCompetencyCollectionsError,
  doSearchMicrocompetencyTopics,
  doSearchMicrocompetencyTopicsSuccess,
  doSearchMicrocompetencyTopicsError,
  doPostCompetencyCollection,
  doPostCompetencyCollectionSuccess,
  doPostCompetencyCollectionError,
  doPutCompetencyCollection,
  doPutCompetencyCollectionSuccess,
  doPutCompetencyCollectionError,
  doDeleteCompetencyCollection,
  doDeleteCompetencyCollectionSuccess,
  doDeleteCompetencyCollectionError,
  doGetCompetencyCollectionsAndScores,
  doGetCompetencyCollectionsAndScoresError,
  doGetCompetencyCollectionsAndScoresSuccess
};
