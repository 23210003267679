import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ButtonOutline,
  Caption,
  Heading2,
  ButtonInline
} from '@xcomp/xcomp-design-library';

import { Panel } from '../../../Library/Layout';
import CheckboxAccordionRow from '../../../Library/AccordionRow/CheckboxAccordion/CheckboxAccordionRowContainer';

export const PanelHeader = styled(({ paddingLeft, paddingRight, ...rest }) => (
  <div {...rest} />
))`
  height: 100px;
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: space-between;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '66px')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '66px')};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const TopicNumber = styled.span`
  display: inline-block;
`;
const TopicTitle = styled.span`
  display: inline-block;
  color: ${props => props.theme.fontColors.dark};
`;

const TopicCount = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-right: 8px;
`;

const CaptionSC = styled(Caption)`
  display: inline;
  margin: 0;
  padding: 0;
  color: ${props => props.theme.colors.white};
`;

const ButtonInlineSC = styled(ButtonInline)`
  && {
    margin-right: 1rem;
  }
`;

const TopicHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const isMicroChecked = (micros, microUuid) => {
  return Boolean(micros[microUuid]);
};

const EditTopicMicros = ({
  addedMicros,
  topicUuid,
  competencyUuid,
  unsavedChanges,
  setUnsavedChange,
  removeUnsavedChange,
  dispatch,
  editTopicMicros
}) => {
  const { topic, micros } = addedMicros[topicUuid];
  const topicCodeString = `${topic.discipline.cipcode.code}.${topic.discipline.code}.${topic.level_one_code}${topic.level_two_code}`;
  const topicCount = Object.keys(editTopicMicros).length;
  const cancelTopicManagment = () => {
    removeUnsavedChange(topicUuid);
    dispatch({ type: 'cancelTopicEdit' });
  };

  const saveTopicChanges = () => {
    removeUnsavedChange(topicUuid);
    setUnsavedChange(competencyUuid);
    dispatch({ type: 'addUpdatdTopicToSelected' });
  };

  const selectMicrocompetencyDispatch = micro => {
    setUnsavedChange(topicUuid);
    dispatch({
      type: 'reselectMicrocompetencyInTopic',
      payload: {
        microcompetency: micro
      }
    });
  };

  const deselectMicrocompetencyDispatch = microUuid => {
    setUnsavedChange(topicUuid);
    dispatch({
      type: 'deselectMicrocompetencyInTopic',
      payload: {
        microcompetencyUuid: microUuid
      }
    });
  };

  const formHasChanges = Boolean(unsavedChanges[topicUuid]);

  return (
    <Panel>
      <PanelHeader paddingLeft="24px" paddingRight="24px">
        <TopicHeading>
          <TopicCount>
            <CaptionSC>{topicCount}</CaptionSC>
          </TopicCount>
          <Heading2>
            Topic
            <TopicNumber>&nbsp;{topicCodeString}&nbsp;&nbsp;</TopicNumber>
            <TopicTitle>{topic.title}</TopicTitle>
          </Heading2>
        </TopicHeading>
        <div>
          <ButtonInlineSC onClick={cancelTopicManagment}>Cancel</ButtonInlineSC>
          <ButtonOutline onClick={saveTopicChanges} disabled={!formHasChanges}>
            Update Topic
          </ButtonOutline>
        </div>
      </PanelHeader>
      <PanelPadding>
        <CheckboxAccordionRow
          key={topic.uuid}
          actions={false}
          label="Topic"
          expandWidth="24px"
          columnOne={topicCodeString}
          columnTwo={`${topic.title}`}
          hideCheckBox={true}
          isTopLevel
        >
          {micros &&
            Object.keys(micros).length > 0 &&
            Object.keys(micros).map(microUuid => {
              const micro = micros[microUuid];
              const microCodeString = `${topicCodeString}.${micro.subtopic.level_one_code}${micro.subtopic.level_two_code}.${micro.level_one_code}${micro.level_two_code}`;
              return (
                <CheckboxAccordionRow
                  key={micro.uuid}
                  actions={false}
                  label="Microcompetency"
                  columnOne={microCodeString}
                  columnTwo={micro.title}
                  setCheckedFromProps={isMicroChecked(
                    editTopicMicros,
                    micro.uuid
                  )}
                  onCheck={() => selectMicrocompetencyDispatch(micro)}
                  onUncheck={() => deselectMicrocompetencyDispatch(micro.uuid)}
                  rowOffset="48px"
                  leaf
                />
              );
            })}
        </CheckboxAccordionRow>
      </PanelPadding>
    </Panel>
  );
};

EditTopicMicros.propTypes = {
  addedMicros: PropTypes.object,
  editTopicMicros: PropTypes.object,
  topicUuid: PropTypes.string,
  competencyUuid: PropTypes.string,
  unsavedChanges: PropTypes.object,
  setUnsavedChange: PropTypes.func,
  removeUnsavedChange: PropTypes.func,
  dispatch: PropTypes.func
};
EditTopicMicros.defaultProps = {
  addedMicros: {},
  editTopicMicros: {},
  topicUuid: '',
  competencyUuid: '',
  unsavedChanges: {},
  setUnsavedChange: undefined,
  removeUnsavedChange: undefined,
  dispatch: undefined
};

export default EditTopicMicros;
