import fetch from '../helpers/fetch';

const getUserProgramsByProgram = programUuid =>
  fetch(`/api/user-programs/program/${programUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getUserProgramsByPrograms = programs =>
  fetch(`/api/user-programs/programs`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(programs)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getUserProgramsByProgram, getUserProgramsByPrograms };
