import { useEffect, useState, useCallback } from 'react';

const useContainerDimensions = ref => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const getDimensions = useCallback(() => {
    return ref.current
      ? {
          width: ref.current.clientWidth,
          height: ref.current.clientHeight
        }
      : { width: 0, height: 0 };
  }, [ref]);

  const clientWidth = ref?.current?.clientWidth;
  const clientHeight = ref?.current?.clientHeight;
  useEffect(() => {
    setDimensions(getDimensions);
    window.addEventListener('resize', () => setDimensions(getDimensions));
    return () => {
      window.removeEventListener('resize', () => setDimensions(getDimensions));
    };
  }, [clientWidth, clientHeight, getDimensions]);

  return dimensions;
};

export { useContainerDimensions };
