import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { selectMilestone } from '../../../../../../redux/selectors/classificationSelectors';

import MilestoneConversion from './MilestoneConversion';
import AccordionRowContainer from '../../../../../Library/AccordionRow/AccordionRowContainer';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .accordion_row_label {
    flex-grow: 2;
  }

  && {
    .accordion_row_label div {
      letter-spacing: 0.15;
    }
  }

  width: 100%;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div``;

const Input = styled.div`
  margin-left: 60px;
  color: ${props => props.theme.fontColors.darker};
`;

export default function MilestoneTest({ classificationUuid }) {
  const milestone = useSelector(
    state => selectMilestone(state, classificationUuid),
    shallowEqual
  );

  return (
    <>
      {!_.isNil(milestone) && (
        <Accordion
          rowOffset="198px"
          label={
            <Group>
              <Label>Milestone Test</Label>
              <Input>{milestone.examName}</Input>
            </Group>
          }
        >
          <MilestoneConversion examMilestoneUuid={milestone.uuid} />
        </Accordion>
      )}
    </>
  );
}

MilestoneTest.propTypes = {
  classificationUuid: PropTypes.string
};

MilestoneTest.defaultProps = {
  classificationUuid: undefined
};
