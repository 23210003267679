import React from 'react';
import styled from 'styled-components';

export const TableRow = styled.tr`
  display: flex;
  justify-content: flex-start;
  inline-size: min-content;

  td:last-child {
    border-right: none;
  }
`;

export const StickyInfoTd = styled(
  ({ left, greyOne, borderRightStrong, ...rest }) => <td {...rest} />
)`
  min-width: 250px;
  max-width: 250px;
  z-index: 10;
  position: sticky;
  padding: 0 24px;
  left: ${props => props.left};
  background-color: ${props => (props.greyOne && props.theme.colors.grey[100]) || 'white'};
  border-top: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
  border-right: ${props =>
    props.borderRightStrong
      ? `solid 2px ${props.theme.fontColors.darker}`
      : `solid 1px ${props.theme.colors.grey[300]}`};
`;

export const FillerTd = styled(
  ({ borderTop, borderBottom, greyOne, greyTwo, ...rest }) => <td {...rest} />
)`
  min-width: ${props => `${props.width}px`};
  max-width: ${props => `${props.width}px`};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-top: ${props =>
    props.borderTop ? `1px solid ${props.theme.colors.grey[300]}` : 'none'};
  border-bottom: ${props =>
    props.borderBottom ? `1px solid ${props.theme.colors.grey[300]}` : 'none'};
  background-color: ${props =>
    (props.greyOne && props.theme.colors.grey[100]) ||
    (props.greyTwo && props.theme.colors.grey[200]) ||
    'white'};
`;

export const StudentInfoText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 53px;
  color: ${props => props.theme.fontColors.darker};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
