import {
  GET_LETTER_GRADES_BY_SCHOOL,
  GET_LETTER_GRADES_BY_SCHOOL_SUCCESS,
  GET_LETTER_GRADES_BY_SCHOOL_ERROR,
  POST_LETTER_GRADE,
  POST_LETTER_GRADE_SUCCESS,
  POST_LETTER_GRADE_ERROR,
  PUT_LETTER_GRADE,
  PUT_LETTER_GRADE_SUCCESS,
  PUT_LETTER_GRADE_ERROR,
  DELETE_LETTER_GRADE,
  DELETE_LETTER_GRADE_SUCCESS,
  DELETE_LETTER_GRADE_ERROR
} from '../actions-type';

const doGetLetterGradesBySchool = schoolUuid => ({
  type: GET_LETTER_GRADES_BY_SCHOOL,
  payload: {
    schoolUuid
  }
});

const doGetLetterGradesBySchoolSuccess = result => ({
  type: GET_LETTER_GRADES_BY_SCHOOL_SUCCESS,
  payload: result
});

const doGetLetterGradesBySchoolError = error => ({
  type: GET_LETTER_GRADES_BY_SCHOOL_ERROR,
  payload: error
});

const doPostLetterGrade = letterGrade => ({
  type: POST_LETTER_GRADE,
  payload: { letterGrade }
});

const doPostLetterGradeSuccess = result => ({
  type: POST_LETTER_GRADE_SUCCESS,
  payload: result
});

const doPostLetterGradeError = error => ({
  type: POST_LETTER_GRADE_ERROR,
  payload: error
});

const doPutLetterGrade = letterGrade => ({
  type: PUT_LETTER_GRADE,
  payload: { letterGrade }
});

const doPutLetterGradeSuccess = result => ({
  type: PUT_LETTER_GRADE_SUCCESS,
  payload: result
});

const doPutLetterGradeError = error => ({
  type: PUT_LETTER_GRADE_ERROR,
  payload: error
});

const doDeleteLetterGrade = letterGradeUuid => ({
  type: DELETE_LETTER_GRADE,
  payload: {
    letterGradeUuid
  }
});

const doDeleteLetterGradeSuccess = result => ({
  type: DELETE_LETTER_GRADE_SUCCESS,
  payload: result
});

const doDeleteLetterGradeError = error => ({
  type: DELETE_LETTER_GRADE_ERROR,
  payload: error
});

export {
  doGetLetterGradesBySchool,
  doGetLetterGradesBySchoolSuccess,
  doGetLetterGradesBySchoolError,
  doPostLetterGrade,
  doPostLetterGradeSuccess,
  doPostLetterGradeError,
  doPutLetterGrade,
  doPutLetterGradeSuccess,
  doPutLetterGradeError,
  doDeleteLetterGrade,
  doDeleteLetterGradeSuccess,
  doDeleteLetterGradeError
};
