import fetch from '../helpers/fetch';

const getLoverbsBySchool = schoolUuid =>
  fetch(`/api/loverbs/find-by-school?schoolUuid=${schoolUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getLoverbsByEducationLevels = educationLevels =>
  fetch(`/api/loverbs/education-levels`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(educationLevels)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getLoverbsBySchool, getLoverbsByEducationLevels };
