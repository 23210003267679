import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import FacultyDefaultScoreViewStudentPanelContainer from './FacultyDefaultScoreViewStudentPanelContainer';

const FacultyPanelAccordion = styled(
  ({ withBottomBorder, withTopBorder, criticalFailure, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label,
  .column_two_label,
  .column_three_label,
  .column_four_label {
    flex-grow: 0;
    width: 100px;
    text-align: right;
  }

  .accordion_row_label div {
    font-size: 16px;
    letter-spacing: 0.15;
    color: ${props => props.theme.fontColors.darker};
  }

  .column_one_label,
  .column_two_label,
  .column_three_label {
    margin-right: 16px;
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props =>
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.theme.fontColors.darker};
    }
  }

  .column_four_label {
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props => props.theme.fontColors.darker};
    }
  }

  .hover-effect {
    padding-right: 44px;
  }
  width: 100%;
`;

const StudentPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 48px;
  width: calc(100% - 48px);
`;

const StudentPanelHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  font-weight: 450;
`;

const StudentPanelHeaderSub = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  padding-right: ${props => props.paddingRight};
  color: ${props => props.theme.fontColors.dark};
  text-align: right;
  }
`;

export default function FacultyPanel({
  facultyFullName,
  facultyParticipationPercentage,
  facultyRelativePercentage,
  facultyScores,
  facultyTotalRelative,
  facultyTotalPotential,
  scoreType,
  students
}) {
  return (
    <FacultyPanelAccordion
      withBottomBorder
      expandWidth="66px"
      height="50px"
      label={facultyFullName}
      columnOne={`${facultyParticipationPercentage}%`}
      columnTwo={`${facultyRelativePercentage}%`}
      columnThree={facultyTotalRelative}
      columnFour={facultyTotalPotential}
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
      columnThreeClassName="column_three_label"
      columnFourClassName="column_four_label"
    >
      <StudentPanelHeader>
        <StudentPanelHeaderInfo>
          Student (Last Name, First Name)
        </StudentPanelHeaderInfo>
        <StudentPanelHeaderInfo>
          <StudentPanelHeaderSub paddingRight="24px">
            Total Relative
          </StudentPanelHeaderSub>
          <StudentPanelHeaderSub paddingRight="44px">
            Total Potential
          </StudentPanelHeaderSub>
        </StudentPanelHeaderInfo>
      </StudentPanelHeader>
      {students.map(student => {
        const someUuid = uuidv4();
        return (
          <FacultyDefaultScoreViewStudentPanelContainer
            key={someUuid}
            facultyScores={facultyScores}
            scoreType={scoreType}
            studentUser={student.user}
          />
        );
      })}
    </FacultyPanelAccordion>
  );
}

FacultyPanel.propTypes = {
  facultyFullName: PropTypes.string,
  facultyParticipationPercentage: PropTypes.string,
  facultyRelativePercentage: PropTypes.string,
  facultyScores: PropTypes.array,
  facultyTotalRelative: PropTypes.string,
  facultyTotalPotential: PropTypes.string,
  scoreType: PropTypes.string,
  students: PropTypes.array
};

FacultyPanel.defaultProps = {
  facultyFullName: '',
  facultyParticipationPercentage: '',
  facultyRelativePercentage: '',
  facultyScores: [],
  facultyTotalRelative: '',
  facultyTotalPotential: '',
  scoreType: '',
  students: []
};
