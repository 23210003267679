import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { selectSourceInstitution } from '../../../../../redux/selectors/sourceSelectors';

export default function InstitutionName({ sourceInstitutionUuid }) {
  const { name } = useSelector(
    state => selectSourceInstitution(state, sourceInstitutionUuid),
    shallowEqual
  );

  return name;
}

InstitutionName.propTypes = { classificationUuid: PropTypes.string };
InstitutionName.defaultProps = { classificationUuid: undefined };
