import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import {
  doGetAssessmentTechniques,
  doGetAssessmentAssociations,
  doGetAssessmentType
} from '../../../../redux/actions/assessmentActions';
import { doGetCompetencyCollections } from '../../../../redux/actions/competencyCollectionActions';
import {
  assessmentAssociationsSelector,
  assessmentTechniquesSelector,
  assessmentTypesSelector
} from '../../../../redux/selectors/assessmentSelectors';
import { competencyCollectionsSelector } from '../../../../redux/selectors/competencyCollectionsSelectors';
import CompetencyGridFilter from './CompetencyGridFilter';
import { cohortSelectorByMatchParams } from '../../../../redux/selectors/cohortsSelectors';

const CompetencyGridFilterContainer = ({
  onGenerateCompetencyReport,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const { cohortUuid } = useParams();

  const assessmentAssociations = useSelector(
    assessmentAssociationsSelector,
    _.isEqual
  );
  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  const assessmentTechniques = useSelector(
    assessmentTechniquesSelector,
    _.isEqual
  );
  const assessmentTypes = useSelector(assessmentTypesSelector, _.isEqual);
  const cohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    _.isEqual
  );

  const competencyCollections = useSelector(
    state => competencyCollectionsSelector(state, selectedProgramUuid),
    _.isEqual
  );
  const defaultCompetencyCollection = competencyCollections.find(
    cc => cc.is_default_collection
  );

  const [selectedAssessmentAssociations, setSelectedAssessmentAssociations] =
    useState([]);
  const [
    hasSetDefaultAssessmentAssociation,
    setHasSetDefaultAssessmentAssociation
  ] = useState(false);

  const [selectedAssessmentTechniques, setSelectedAssessmentTechniques] =
    useState([]);
  const [
    hasSetDefaultAssessmentTechnique,
    setHasSetDefaultAssessmentTechnique
  ] = useState(false);

  const [selectedAssessmentTypes, setSelectedAssessmentTypes] = useState([]);
  const [hasSetDefaultAssessmentType, setHasSetDefaultAssessmentType] =
    useState(false);

  const [selectedCompetencyCollection, setSelectedCompetencyCollection] =
    useState('all');
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');

  const [
    hasSetDefaultCompetencyCollection,
    setHasSetDefaultCompetencyCollection
  ] = useState(false);

  useEffect(() => {
    dispatch(doGetAssessmentAssociations());
    dispatch(doGetAssessmentTechniques());
    dispatch(doGetAssessmentType());
    dispatch(doGetCompetencyCollections(selectedProgramUuid));
  }, [dispatch, selectedProgramUuid]);

  useEffect(() => {
    if (cohortUuid && cohort?.startDate && cohort?.endDate) {
      const cleanredUpStartDate = cohort.startDate
        .replace(/-/g, '/')
        .replace(/T.+/, '');
      const cleanredUpEndDate = cohort.endDate
        .replace(/-/g, '/')
        .replace(/T.+/, '');
      setSelectedStartDate(new Date(cleanredUpStartDate));
      setSelectedEndDate(new Date(cleanredUpEndDate));
    }
  }, [cohort, cohortUuid]);

  useEffect(() => {
    if (
      assessmentAssociations.length > 0 &&
      !hasSetDefaultAssessmentAssociation
    ) {
      setSelectedAssessmentAssociations(
        assessmentAssociations.map(at => at.uuid)
      );
      setHasSetDefaultAssessmentAssociation(true);
    }
  }, [assessmentAssociations, hasSetDefaultAssessmentAssociation]);

  useEffect(() => {
    if (assessmentTechniques.length > 0 && !hasSetDefaultAssessmentTechnique) {
      setSelectedAssessmentTechniques(assessmentTechniques.map(at => at.uuid));
      setHasSetDefaultAssessmentTechnique(true);
    }
  }, [assessmentTechniques, hasSetDefaultAssessmentTechnique]);

  useEffect(() => {
    if (assessmentTypes.length > 0 && !hasSetDefaultAssessmentType) {
      setSelectedAssessmentTypes(assessmentTypes.map(at => at.uuid));
      setHasSetDefaultAssessmentType(true);
    }
  }, [assessmentTypes, hasSetDefaultAssessmentType]);

  useEffect(() => {
    if (
      competencyCollections.length > 0 &&
      defaultCompetencyCollection &&
      !hasSetDefaultCompetencyCollection
    ) {
      setSelectedCompetencyCollection(defaultCompetencyCollection.uuid);
      setHasSetDefaultCompetencyCollection(true);
    }
  }, [
    defaultCompetencyCollection,
    competencyCollections,
    hasSetDefaultCompetencyCollection
  ]);

  const handleSelectAssessmentAssociation = event => {
    const { value } = event.target;
    setSelectedAssessmentAssociations(value);
  };

  const handleSelectAllAssessmentAssociations = event => {
    const { checked } = event.target;
    if (checked) {
      setSelectedAssessmentAssociations(
        assessmentAssociations.map(aa => aa.uuid)
      );
    } else {
      setSelectedAssessmentAssociations([]);
    }
  };

  const handleDeselectAssessmentAssociation = value => {
    setSelectedAssessmentAssociations(
      selectedAssessmentAssociations.filter(aaUuid => aaUuid !== value)
    );
  };

  const handleSelectAssessmentTechnique = event => {
    const { value } = event.target;
    setSelectedAssessmentTechniques(value);
  };

  const handleSelectAllAssessmentTechniques = event => {
    const { checked } = event.target;
    if (checked) {
      setSelectedAssessmentTechniques(assessmentTechniques.map(at => at.uuid));
    } else {
      setSelectedAssessmentTechniques([]);
    }
  };

  const handleDeselectAssessmentTechnique = value => {
    setSelectedAssessmentTechniques(
      selectedAssessmentTechniques.filter(atUuid => atUuid !== value)
    );
  };

  const handleSelectAssessmentType = event => {
    const { value } = event.target;
    setSelectedAssessmentTypes(value);
  };

  const handleSelectAllAssessmentTypes = event => {
    const { checked } = event.target;
    if (checked) {
      setSelectedAssessmentTypes(assessmentTypes.map(at => at.uuid));
    } else {
      setSelectedAssessmentTypes([]);
    }
  };

  const handleDeselectAssessmentType = value => {
    setSelectedAssessmentTypes(
      selectedAssessmentTypes.filter(atUuid => atUuid !== value)
    );
  };

  const handleSelectCompetencyCollection = event => {
    const { value } = event.target;
    setSelectedCompetencyCollection(value);
  };

  const handleDatePickerChange = (indentifier, fieldName, date) => {
    if (fieldName === 'startDate') {
      setSelectedStartDate(date);
    } else {
      setSelectedEndDate(date);
    }
  };

  const handleResetAllFilters = () => {
    setSelectedAssessmentAssociations(
      assessmentAssociations.map(aa => aa.uuid)
    );
    setSelectedAssessmentTechniques(assessmentTechniques.map(at => at.uuid));
    setSelectedAssessmentTypes(assessmentTypes.map(at => at.uuid));
    setSelectedCompetencyCollection(defaultCompetencyCollection?.uuid);
  };

  const handleApplyCompetencyGridFilters = () => {
    const startDate =
      selectedStartDate !== ''
        ? format(new Date(selectedStartDate), 'yyyy-MM-dd')
        : '';
    const endDate =
      selectedEndDate !== ''
        ? format(new Date(selectedEndDate), 'yyyy-MM-dd')
        : '';

    onGenerateCompetencyReport(
      selectedCompetencyCollection,
      selectedAssessmentAssociations,
      selectedAssessmentTechniques,
      selectedAssessmentTypes,
      startDate,
      endDate
    );
  };

  const assessmentAssociationsOptions = assessmentAssociations.map(
    assessmentAssociation => {
      return {
        value: assessmentAssociation.uuid,
        label: assessmentAssociation.association
      };
    }
  );

  const assessmentTechniquesOptions = assessmentTechniques.map(
    assessmentTechnique => {
      return {
        value: assessmentTechnique.uuid,
        label: assessmentTechnique.technique
      };
    }
  );

  const assessmentTypesOptions = assessmentTypes.map(assessmentType => {
    return {
      value: assessmentType.uuid,
      label: assessmentType.name
    };
  });

  const competencyCollectionOptions = competencyCollections.map(
    competencyCollection => {
      return {
        value: competencyCollection.uuid,
        label: competencyCollection.name
      };
    }
  );

  const allCollectionsOption = {
    value: 'all',
    label: 'All Collections'
  };

  const competencyCollectionOptionsComplete = [
    allCollectionsOption,
    ...competencyCollectionOptions
  ];

  return (
    <CompetencyGridFilter
      assessmentAssociationsOptions={assessmentAssociationsOptions}
      assessmentTechniquesOptions={assessmentTechniquesOptions}
      assessmentTypesOptions={assessmentTypesOptions}
      selectedStartDate={selectedStartDate}
      selectedEndDate={selectedEndDate}
      competencyCollectionOptions={competencyCollectionOptionsComplete}
      onApplyCompetencyGridFilters={handleApplyCompetencyGridFilters}
      onResetAllFilters={handleResetAllFilters}
      onDeselectAssessmentAssociation={handleDeselectAssessmentAssociation}
      onDeselectAssessmentTechnique={handleDeselectAssessmentTechnique}
      onDeselectAssessmentType={handleDeselectAssessmentType}
      onSelectAllAssessmentAssociations={handleSelectAllAssessmentAssociations}
      onSelectAllAssessmentTechniques={handleSelectAllAssessmentTechniques}
      onSelectAllAssessmentTypes={handleSelectAllAssessmentTypes}
      onSelectAssessmentAssociation={handleSelectAssessmentAssociation}
      onSelectAssessmentTechnique={handleSelectAssessmentTechnique}
      onSelectAssessmentType={handleSelectAssessmentType}
      onSelectCompetencyCollection={handleSelectCompetencyCollection}
      selectedAssessmentAssociations={selectedAssessmentAssociations}
      selectedAssessmentTechniques={selectedAssessmentTechniques}
      selectedAssessmentTypes={selectedAssessmentTypes}
      selectedCompetencyCollection={selectedCompetencyCollection}
      handleDatePickerChange={handleDatePickerChange}
      isLoading={isLoading}
    />
  );
};

CompetencyGridFilterContainer.propTypes = {
  onGenerateCompetencyReport: PropTypes.func,
  isLoading: PropTypes.bool,
};

CompetencyGridFilterContainer.defaultProps = {
  onGenerateCompetencyReport: undefined,
  isLoading: false,
};

export default CompetencyGridFilterContainer;
