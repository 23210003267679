import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import Header from '../Library/Header';
import { ContainerResize, ContainerTabs } from '../Library/Layout';
import UserTabViewContainer from './UserTabView/UserTabViewContainer';

import {
  visibleToSchoolAdmin,
  visibleToProgramAdmin
} from '../../helpers/authorization';

const SchoolAdminHeader = visibleToSchoolAdmin(() => (
  <Header
    backOn={false}
    title="User Assignment"
    subtitle="Manage and assign roles"
  />
));

const ProgramAdminHeader = visibleToProgramAdmin(() => (
  <Header
    backOn={false}
    title="User Assignment"
    subtitle="Manage and assign program roles"
  />
));

export default function UserManagement({ path }) {
  return (
    <ContainerResize>
      <SchoolAdminHeader />
      <ProgramAdminHeader />
      <ContainerTabs>
        <Switch>
          <Route
            path={`${path}/tab/view`}
            component={props => {
              return <UserTabViewContainer {...props} />;
            }}
          />
        </Switch>
      </ContainerTabs>
    </ContainerResize>
  );
}

UserManagement.propTypes = {
  path: PropTypes.string,
  pathname: PropTypes.string
};
UserManagement.defaultProps = {
  path: '',
  pathname: ''
};
