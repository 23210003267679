export const GET_COMPETENCY_COLLECTIONS = 'GET_COMPETENCY_COLLECTIONS';
export const GET_COMPETENCY_COLLECTIONS_SUCCESS =
  'GET_COMPETENCY_COLLECTIONS_SUCCESS';
export const GET_COMPETENCY_COLLECTIONS_ERROR =
  'GET_COMPETENCY_COLLECTIONS_ERROR';

export const GET_COMPETENCY_COLLECTION = 'GET_COMPETENCY_COLLECTION';
export const GET_COMPETENCY_COLLECTION_SUCCESS =
  'GET_COMPETENCY_COLLECTION_SUCCESS';
export const GET_COMPETENCY_COLLECTION_ERROR =
  'GET_COMPETENCY_COLLECTION_ERROR';

export const SEARCH_MICROCOMPETENCY_TOPICS = 'SEARCH_MICROCOMPETENCY_TOPICS';
export const SEARCH_MICROCOMPETENCY_TOPICS_SUCCESS =
  'SEARCH_MICROCOMPETENCY_TOPICS_SUCCESS';
export const SEARCH_MICROCOMPETENCY_TOPICS_ERROR =
  'SEARCH_MICROCOMPETENCY_TOPICS_ERROR';

export const POST_COMPETENCY_COLLECTION = 'POST_COMPETENCY_COLLECTION';
export const POST_COMPETENCY_COLLECTION_SUCCESS =
  'POST_COMPETENCY_COLLECTION_SUCCESS';
export const POST_COMPETENCY_COLLECTION_ERROR =
  'POST_COMPETENCY_COLLECTION_ERROR';

export const PUT_COMPETENCY_COLLECTION = 'PUT_COMPETENCY_COLLECTION';
export const PUT_COMPETENCY_COLLECTION_SUCCESS =
  'PUT_COMPETENCY_COLLECTION_SUCCESS';
export const PUT_COMPETENCY_COLLECTION_ERROR =
  'PUT_COMPETENCY_COLLECTION_ERROR';

export const DELETE_COMPETENCY_COLLECTION = 'DELETE_COMPETENCY_COLLECTION';
export const DELETE_COMPETENCY_COLLECTION_SUCCESS =
  'DELETE_COMPETENCY_COLLECTION_SUCCESS';
export const DELETE_COMPETENCY_COLLECTION_ERROR =
  'DELETE_COMPETENCY_COLLECTION_ERROR';

export const GET_COMPETENCY_COLLECTION_AND_SCORES =
  'GET_COMPETENCY_COLLECTION_AND_SCORES';
export const GET_COMPETENCY_COLLECTION_AND_SCORES_ERROR =
  'GET_COMPETENCY_COLLECTION_AND_SCORES_ERROR';
export const GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS =
  'GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS';

export const RESET_COMPETENCY_SCORES = 'RESET_COMPETENCY_SCORES';
