import moment from 'moment';
import { termFinder } from '../../../../../helpers/utilities';

const cognitiveMilestoneInitial = {
  attempt: 0,
  programUuid: undefined,
  cohortUuid: undefined,
  userUuid: undefined,
  classificationUuid: undefined,
  applicationDate: undefined,
  termType: undefined,
  term: undefined,
  year: undefined,
  date: undefined,
  itemCode: undefined,
  itemName: undefined,
  credits: undefined,
  grade: undefined,
  score: undefined,
  sourceDegreeLevelUuid: undefined,
  sourceInstitutionUuid: undefined,
  examMilestoneUuid: undefined,
  conversionMilestoneUuid: undefined
};

const cognitiveMilestoneReducer = (state, action) => {
  switch (action.type) {
    case 'exist': {
      const {
        attempt,
        applicationDate,
        date,
        classificationUuid,
        examMilestoneUuid,
        conversionMilestoneUuid,
        sourceInstitutionUuid,
        sourceDegreeLevelUuid
      } = { ...action.transcript };

      const appDate = moment(applicationDate, 'YYYY-MM-DD');
      const dateConvert = moment(date, 'YYYY-MM-DD');
      const dateYear = new Date(date);
      const term = termFinder(date);

      return {
        ...state,
        attempt,
        applicationDate: appDate,
        date: dateConvert,
        term,
        year: dateYear,
        classificationUuid,
        sourceInstitutionUuid,
        sourceDegreeLevelUuid,
        examMilestoneUuid,
        conversionMilestoneUuid
      };
    }
    case 'reset': {
      return {
        ...cognitiveMilestoneInitial
      };
    }
    case 'applicationDate': {
      const { date } = { ...action };

      return {
        ...state,
        applicationDate: date
      };
    }
    case 'date': {
      const { date } = { ...action };

      const term = termFinder(date);

      return {
        ...state,
        date,
        term,
        year: date
      };
    }
    case 'attempt': {
      const { value } = { ...action };

      return {
        ...state,
        attempt: value
      };
    }
    case 'classificationUuid': {
      const { value } = { ...action };

      return {
        ...state,
        classificationUuid: value
      };
    }
    case 'sourceInstitutionUuid': {
      const { institutionCode, sourceInstitutionUuid } = { ...action };

      return {
        ...state,
        sourceInstitutionUuid,
        institutionCode
      };
    }
    case 'sourceDegreeLevelUuid': {
      const { value } = { ...action };

      return {
        ...state,
        sourceDegreeLevelUuid: value
      };
    }
    case 'credits': {
      const { value } = { ...action };

      return {
        ...state,
        credits: value
      };
    }
    case 'examMilestoneUuid': {
      const { value } = { ...action };

      return {
        ...state,
        examMilestoneUuid: value
      };
    }
    case 'conversionMilestoneUuid': {
      const { value } = { ...action };

      return {
        ...state,
        conversionMilestoneUuid: value,
        grade: value
      };
    }
    default:
      return {
        ...state
      };
  }
};

export { cognitiveMilestoneReducer, cognitiveMilestoneInitial };
