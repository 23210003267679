import {
  SET_SELECTED_INITIAL_EDITOR_STATE,
  SET_SELECTED_DEPARTMENT_UUID_COURSE_EDIT,
  SET_SELECTED_COURSE_MASTER_UUID_COURSE_EDIT,
  SET_SELECTED_ACADEMIC_YEAR_UUID_COURSE_EDIT,
  SET_SELECTED_TERM_UUID_COURSE_EDIT
} from '../actions-type';

const doSetSelectedInitialEditorState = selections => ({
  type: SET_SELECTED_INITIAL_EDITOR_STATE,
  payload: {
    selections
  }
});

const doSetSelectedDepartmentCourseEdit = departmentUuid => ({
  type: SET_SELECTED_DEPARTMENT_UUID_COURSE_EDIT,
  payload: {
    departmentUuid
  }
});

const doSetSelectedCourseMasterCourseEdit = courseMasterUuid => ({
  type: SET_SELECTED_COURSE_MASTER_UUID_COURSE_EDIT,
  payload: {
    courseMasterUuid
  }
});

const doSetSelectedAcademicYearCourseEdit = academicYearUuid => ({
  type: SET_SELECTED_ACADEMIC_YEAR_UUID_COURSE_EDIT,
  payload: {
    academicYearUuid
  }
});

const doSetSelectedTermCourseEdit = termUuid => ({
  type: SET_SELECTED_TERM_UUID_COURSE_EDIT,
  payload: {
    termUuid
  }
});

export {
  doSetSelectedInitialEditorState,
  doSetSelectedDepartmentCourseEdit,
  doSetSelectedCourseMasterCourseEdit,
  doSetSelectedAcademicYearCourseEdit,
  doSetSelectedTermCourseEdit
};
