import React from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Link } from 'react-router-dom'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import ChevronRight from '@material-ui/icons/ChevronRight'
import PropTypes from 'prop-types'

const FeatureItem = ({
  feature,
  drawerOpen,
  open,
  handleFeatureClick,
  selectedFeature,
}) => {
  return (
    <ListItem
      {...(!feature.subFeatures
        ? { component: Link, to: feature.url }
        : { button: true })}
      button
      style={{
        ...(drawerOpen && !feature.subFeatures && { paddingLeft: '32px' }),
      }}
      selected={selectedFeature === feature.featureId}
      onClick={() => handleFeatureClick(feature)}
    >
      <ListItemIcon
        style={{
          ...(!drawerOpen && { minWidth: 'unset' }),
        }}
      >
        {!drawerOpen && open[feature.featureId] ? (
          <KeyboardArrowDown />
        ) : (
          feature.icon
        )}
      </ListItemIcon>
      {drawerOpen && (
        <ListItemText
          primaryTypographyProps={{
            color: 'textSecondary',
          }}
          primary={feature.featureName}
        />
      )}
      {drawerOpen && feature.subFeatures && (
        <>
          {open[feature.featureId] ? <KeyboardArrowDown /> : <ChevronRight />}
        </>
      )}
    </ListItem>
  )
}

FeatureItem.propTypes = {
  feature: PropTypes.object.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  open: PropTypes.object.isRequired,
  handleFeatureClick: PropTypes.func.isRequired,
  selectedFeature: PropTypes.string,
}

FeatureItem.defaultProps = {
  selectedFeature: null,
}

export default FeatureItem