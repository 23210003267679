import { Model, attr, fk } from 'redux-orm';

import {
  GET_PART_FACULTY_SUCCESS,
  GET_SCORES_SUCCESS,
  GET_SYLLABUS_COURSE_SUCCESS,
  GET_SYLLABUS_PART_SUCCESS
} from '../actions-type';

class PartFaculty extends Model {
  static reducer(action, PartFaculty, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_PART_FACULTY_SUCCESS: {
        const partFaculty = [...payload.partFaculty];
        partFaculty.forEach(faculty => PartFaculty.upsert(faculty));
        break;
      }
      case GET_SCORES_SUCCESS: {
        const partFaculty = [...payload.partFaculty];
        partFaculty.forEach(faculty => PartFaculty.upsert(faculty));
        break;
      }
      case GET_SYLLABUS_COURSE_SUCCESS: {
        const course = { ...payload.course };
        const { sections } = course;

        if (sections?.length > 0) {
          sections.forEach(section => {
            const { parts } = section;

            if (parts?.length > 0) {
              parts.forEach(part => {
                const { part_faculty_assigned } = part;

                if (part_faculty_assigned?.length > 0) {
                  part_faculty_assigned.forEach(faculty => {
                    const { part_faculty } = faculty;

                    PartFaculty.upsert(part_faculty);
                  });
                }
              });
            }
          });
        }

        break;
      }
      case GET_SYLLABUS_PART_SUCCESS: {
        const part = { ...payload.part };

        const { part_faculty_assigned } = part;

        if (part_faculty_assigned?.length > 0) {
          part_faculty_assigned.forEach(director => {
            const { part_faculty } = director;

            PartFaculty.upsert(part_faculty);
          });
        }

        break;
      }

      default:
        return session;
    }
  }
}

PartFaculty.fields = {
  uuid: attr(),
  userUuid: fk({
    to: 'User',
    as: 'user',
    relatedName: 'part_faculty'
  }),
  partUuid: fk({
    to: 'Part',
    as: 'part',
    relatedName: 'part_faculty'
  })
};

PartFaculty.options = {
  idAttribute: 'uuid'
};

PartFaculty.modelName = 'PartFaculty';

export default PartFaculty;
