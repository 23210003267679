import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ButtonInline, Heading3 } from '@xcomp/xcomp-design-library';
import SimpleModal from '../SimpleModal';

export const assessmentBlockResultStatusMap = {
  pass: 'Passed',
  fail: 'Failed',
  disqualified: 'Disqualified',
  critical: 'Critical Failure',
  pending: 'Pending'
};

const ModalBody = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;

  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const MenuItemSC = styled(MenuItem)`
  border-radius: 3px !important;
  color: white !important;
  margin: 5px !important;
  font-weight: 600 !important;
  color: ${props => 
    (props.$status === 'pass' && props.theme.colors.status.success)
    || (props.$status === 'pending' && props.theme.colors.status.warning)
    || (props.$status === 'fail' && props.theme.colors.status.error)
    || (props.$status === 'critical' && props.theme.colors.status.error)
    || (props.$status === 'disqualified' && props.theme.colors.status.error)
  } !important;
`

const SelectedValue = styled.div`
  border-radius: 3px !important;
  color: white !important;
  font-weight: 600 !important;
  color: ${props => 
    (props.$status === 'pass' && props.theme.colors.status.success)
    || (props.$status === 'pending' && props.theme.colors.status.warning)
    || (props.$status === 'fail' && props.theme.colors.status.error)
    || (props.$status === 'critical' && props.theme.colors.status.error)
    || (props.$status === 'disqualified' && props.theme.colors.status.error)
  } !important;
`

const ChangeAttemptStatusModal = ({
  modalOpen,
  className,
  handleModalClose,
  handleUpdate,
  currentStatus,
  isLoading
}) => {
  const [status, setStatus ] = useState(currentStatus);

  return (
    <div className={className}>
      <SimpleModal open={modalOpen} onClose={handleModalClose}>
        <ModalBody>
          <div>
            <Heading3 style={{marginBottom: 20}}>
              Update attempt status
            </Heading3>
            <FormControl variant="outlined" style={{width: 300}}>
              <InputLabel id="attempt-status">Status</InputLabel>
              <Select
                labelId="attempt-status"
                id="attempt-status"
                value={status}
                label='Status'
                onChange={(e) => setStatus(e.target.value)}
                style={{width: '100%'}}
                renderValue={(v) => <SelectedValue $status={v}>{assessmentBlockResultStatusMap[v]}</SelectedValue>}
              >
                <MenuItemSC value='pass' $status='pass'>Passed</MenuItemSC>
                <MenuItemSC value='pending' $status='pending'>Pending</MenuItemSC>
                <MenuItemSC value='fail' $status='fail'>Failed</MenuItemSC>
                <MenuItemSC value='critical' $status='critical'>Critical Failure</MenuItemSC>
                <MenuItemSC value='disqualified' $status='disqualified'>Disqualified</MenuItemSC>
              </Select>
            </FormControl>
          </div>
          <Actions>
            <ButtonInline onClick={() => handleModalClose()}>Cancel</ButtonInline>
            <ButtonInline disabled={isLoading || status === currentStatus} onClick={() => handleUpdate(status)}>Update</ButtonInline>
          </Actions>
        </ModalBody>
      </SimpleModal>
    </div>
  )
};

ChangeAttemptStatusModal.propTypes = {
  modalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleUpdate: PropTypes.func,
  className: PropTypes.string,
  currentStatus: PropTypes.string.isRequired,
  isLoading: PropTypes.bool
};

ChangeAttemptStatusModal.defaultProps = {
  modalOpen: false,
  handleModalClose: undefined,
  handleUpdate: undefined,
  className: '',
  isLoading: false,
};

export default ChangeAttemptStatusModal;
