import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getPartUserGroups,
  postUserGroups,
  deleteUserGroup,
  getPartStudentUserGroups
} from '../../api/userGroupApi';

import {
  doGetPartUserGroupsSuccess,
  doGetPartUserGroupsError,
  doPostUserGroupsSuccess,
  doPostUserGroupsError,
  doDeleteUserGroupSuccess,
  doDeleteUserGroupError,
  doGetStudentPartUserGroupsSuccess,
  doGetStudentPartUserGroupsError
} from '../actions/userGroupActions';

import {
  GET_PART_USER_GROUPS,
  GET_STUDENT_PART_USER_GROUPS,
  POST_USER_GROUPS,
  DELETE_USER_GROUP
} from '../actions-type';

function* handleGetPartUserGroups(action) {
  const { partUuid } = action.payload;
  try {
    const result = yield call(getPartUserGroups, partUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartUserGroupsSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetPartUserGroupsError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doGetPartUserGroupsError());
  }
}

function* handleGetStudentPartUserGroups(action) {
  const { partUuid, studentUuid } = action.payload;
  try {
    const result = yield call(getPartStudentUserGroups, partUuid, studentUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetStudentPartUserGroupsSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetStudentPartUserGroupsError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doGetStudentPartUserGroupsError(error));
  }
}

function* handlePostUserGroups(action) {
  const { userGroups } = action.payload;
  try {
    const result = yield call(postUserGroups, userGroups);
    if (result.status === 201) {
      const payload = yield result.json();
      yield put(doPostUserGroupsSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPostUserGroupsError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPostUserGroupsError());
  }
}

function* handleDeleteUserGroup(action) {
  const { userUuid, groupUuid } = action.payload;
  try {
    const result = yield call(deleteUserGroup, userUuid, groupUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteUserGroupSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doDeleteUserGroupError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doDeleteUserGroupError());
  }
}

const userGroup = [
  takeEvery(GET_PART_USER_GROUPS, handleGetPartUserGroups),
  takeEvery(GET_STUDENT_PART_USER_GROUPS, handleGetStudentPartUserGroups),
  takeEvery(POST_USER_GROUPS, handlePostUserGroups),
  takeEvery(DELETE_USER_GROUP, handleDeleteUserGroup)
];

export { userGroup };
