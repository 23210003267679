import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { Caption, Body1, ButtonOutline } from '@xcomp/xcomp-design-library';
import { UsersIcon, GearIcon } from '@xcomp/xcomp-design-library/dist/icons';
import PartList from '../Part/PartList';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import LinkOutline from '../../../Library/LinkOutline';
import {
  gradeCalculationDataSourceMap,
  partStatusCommitMap,
  partStatusGradeMap
} from '../../../../helpers/constants';
import { visibleToSchoolAdmin } from '../../../../helpers/authorization';

const SectionRowButtons = styled.div`
  margin-right: 37px;

  a {
    margin-left: 16px;
  }
`;

const SectionDetails = styled.div`
  padding-left: 74px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

// const PartsPublished = styled(Caption)`
//   margin: 0 0 24px;
//   width: 100%;
// `;

const SectionAccordion = styled(
  ({ withBottomBorder, withTopBorder, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  width: 100%;

  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label div {
    font-size: 16px;
    color: ${props => props.theme.fontColors.darker};
  }

  .section_dates {
    padding-left: 16px;
    flex-grow: 2;

    span {
      font-size: 16px;
      color: ${props => props.theme.fontColors.dark};
    }
  }

  .section_icons {
    svg {
      color: ${props => props.theme.colors.grey[600]};
      &:hover {
        color: ${props => props.theme.colors.grey[900]};
      }
    }
  }

  .accordion_row_label {
    min-width: 200px;
  }
`;

const PartsOffset = styled.div`
  width: 100%;
  padding-left: 74px;
`;

const DiabledButtonRow = styled.div`
  margin-bottom: 16px;
`;

const StatusButton = styled.div`
  a svg {
    color: ${props => props.theme.colors.grey[600]};

    &:hover {
      color: ${props => props.theme.colors.grey[900]};
    }
  }
`;

const SectionLabel = styled(Caption)`
  margin-top: 24px;
  margin-bottom: 0;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }
`;

const SectionValue = styled(Body1)`
  width: 100%;
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
`;

const SectionLeft = styled.div`
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  padding: 24px;
`;

const SectionRight = styled.div`
  display: flex;
  position: relative;
  width: 50%;
  flex-wrap: wrap;
  padding: 24px;
`;

const SectionFieldDisplay = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
`;

const SectionActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: auto;
  padding-left: 4px;

  button {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ActionButton = styled(ButtonOutline)`
  && {
    border: 1px solid ${props => props.theme.colors.primary};
    transition: background 0.4s ease-in, color 0.4s ease-in;
    width: 100%;
    &:hover {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};
    }
  }
`;

const SchoolAdminOnlyCommitmentLogLink = visibleToSchoolAdmin(
  ({ sectionUuid }) => (
    <LinkOutline
      to={`/syllabus-management/section/${sectionUuid}/commitment-log`}
    >
      Commitment Logs
    </LinkOutline>
  )
);

const SchoolAdminEditSection = visibleToSchoolAdmin(({ sectionUuid }) => (
  <Tooltip title="Edit Section Status" placement="top">
    <Link to={`/syllabus-management/section/${sectionUuid}/status-settings`}>
      <GearIcon />
    </Link>
  </Tooltip>
));

const Section = ({
  courseUuid,
  sectionUuid,
  parts,
  section,
  directors,
  sectionNumber,
  onRowOpen,
  onRowClose,
  onFetchSection,
  openSectionAccordions
}) => {
  const sectionIndex = `Section ${sectionNumber.toString().padStart(2, '0')}`;

  const sectionName =
    directors.length > 0
      ? `${sectionIndex} - ${directors[0].lastName}, ${directors[0].firstName}`
      : sectionIndex;

  const pathGradeCalculation = `/syllabus-management/section/${sectionUuid}/section-grade-settings&courseUuid=${courseUuid}&sectionNumber=${sectionNumber}`;

  const columnTwo = (
    <SectionRowButtons>
      <StatusButton>
        <Tooltip title="Student Roster" placement="top">
          <Link
            to={`/syllabus-management/studentroster/${sectionUuid}/${sectionNumber}`}
          >
            <UsersIcon />
          </Link>
        </Tooltip>
        <SchoolAdminEditSection sectionUuid={sectionUuid} />
      </StatusButton>
    </SectionRowButtons>
  );

  // function getPartsPublished() {
  //   let complete = 0;
  //   let outstanding = 0;

  //   if (parts?.length > 0) {
  //     outstanding += parts.length;
  //     _.each(parts, part => {
  //       if (part.status === 'Published') {
  //         complete += 1;
  //       }
  //     });
  //   }

  //   return `${complete}/${outstanding} part(s) published`;
  // }

  const termStart = section?.course?.term?.startDate;
  const termEnd = section?.course?.term?.endDate;

  const startDateSafe = moment(termStart);
  const endDateSafe = moment(termEnd);

  const startYear = startDateSafe.format('YYYY');
  const startDayOfMonth = startDateSafe.format('D');
  const startMonth = startDateSafe.format('MMMM');

  const endYear = endDateSafe.format('YYYY');
  const endDayOfMonth = endDateSafe.format('D');
  const endMonth = endDateSafe.format('MMMM');

  const termStartDisplay = `${startMonth} ${startDayOfMonth}, ${startYear}`;
  const termEndDisplay = `${endMonth} ${endDayOfMonth}, ${endYear}`;

  const termDatesDisplay = `${termStartDisplay} - ${termEndDisplay}`;

  const sectionGradeDataSource = section?.dataSource
    ? gradeCalculationDataSourceMap[section.dataSource]
    : 'Not Set';

  const sectionCompletionPercentage =
    section.completionPercentage && section?.dataSource !== 'LEGACY'
      ? `${section.completionPercentage}%`
      : 'N/A';

  return (
    <SectionAccordion
      withTopBorder={false}
      withBottomBorder={false}
      expandWidth="42px"
      height="38px"
      label={sectionName}
      columnOne={termDatesDisplay}
      columnOneClassName="section_dates"
      columnTwo={columnTwo}
      columnTwoClassName="section_icons"
      rowOffset="32px"
      onRowOpen={onRowOpen}
      onRowClose={onRowClose}
      shouldStartOpen={Boolean(openSectionAccordions[section.uuid])}
    >
      <SectionDetails>
        {/* <PartsPublished>{getPartsPublished()}</PartsPublished> */}
        <SectionLeft>
          <SectionFieldDisplay>
            <SectionLabel>Status</SectionLabel>
            <SectionValue>
              {partStatusGradeMap[section.statusGrade]} /{' '}
              {partStatusCommitMap[section.statusCommit]}
            </SectionValue>
            <SectionLabel>Grade Calculation</SectionLabel>
            <SectionValue>{sectionGradeDataSource}</SectionValue>
            <SectionLabel>Completion %</SectionLabel>
            <SectionValue>{sectionCompletionPercentage}</SectionValue>
          </SectionFieldDisplay>
        </SectionLeft>
        <SectionRight>
          <SectionActions>
            <LinkOutline to={pathGradeCalculation}>
              Grade Calculation
            </LinkOutline>

            <ActionButton>Curriculum Summary</ActionButton>
            {section.statusGrade === 'GRADED' ||
            section.dataSource === 'LEGACY' ? (
              <LinkOutline
                to={`/syllabus-management/section/${sectionUuid}/section-grade-summary/default`}
              >
                Performance Summary
              </LinkOutline>
            ) : (
              <Tooltip title="Section must be graded to access Performance Summary">
                <DiabledButtonRow>
                  <ActionButton disabled>Performance Summary</ActionButton>
                </DiabledButtonRow>
              </Tooltip>
            )}

            <SchoolAdminOnlyCommitmentLogLink sectionUuid={sectionUuid} />
          </SectionActions>
        </SectionRight>
        {/* <SectionDetailRow>
          <RowLeft>
            <RowLabel>Status</RowLabel>
            <RowValue>
              {partStatusGradeMap[section.statusGrade]} /{' '}
              {partStatusCommitMap[section.statusCommit]}
            </RowValue>
          </RowLeft>
          <LinkOutline to={pathGradeCalculation}>Grade Calculation</LinkOutline>
        </SectionDetailRow>
        <SectionDetailRow>
          <RowLeft>
            <RowLabel>Grade Calculation</RowLabel>
            <RowValue>{sectionGradeDataSource}</RowValue>
          </RowLeft>
          <ActionButton onClick={undefined}>Curriculum Summary</ActionButton>
        </SectionDetailRow>
        <SectionDetailRow>
          <RowLeft>
            <RowLabel>Completion %</RowLabel>
            <RowValue>{sectionCompletionPercentage}</RowValue>
          </RowLeft>
          <ActionButton onClick={undefined}>Grade Summary</ActionButton>
        </SectionDetailRow> */}
      </SectionDetails>
      <PartsOffset>
        <PartList
          parts={parts}
          termDatesDisplay={termDatesDisplay}
          sectionNumber={sectionNumber}
          sectionUuid={sectionUuid}
          courseUuid={courseUuid}
          onFetchSection={onFetchSection}
        />
      </PartsOffset>
    </SectionAccordion>
  );
};

Section.propTypes = {
  sectionUuid: PropTypes.string,
  directors: PropTypes.array,
  parts: PropTypes.array,
  section: PropTypes.object,
  openSectionAccordions: PropTypes.object,
  courseUuid: PropTypes.string,
  sectionNumber: PropTypes.number,
  onRowOpen: PropTypes.func,
  onFetchSection: PropTypes.func,
  onRowClose: PropTypes.func
};
Section.defaultProps = {
  sectionUuid: '',
  directors: [],
  parts: [],
  section: {},
  openSectionAccordions: {},
  sectionNumber: 0,
  courseUuid: '',
  onRowOpen: undefined,
  onFetchSection: undefined,
  onRowClose: undefined
};

export default Section;
