import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Decimal from 'decimal.js-light';

import {
  cognitiveProgramReducer,
  cognitiveProgramInitial
} from './cognitiveProgramState';

import CognitiveProgram from './CognitiveProgram';
import { userSelector } from '../../../../../redux/selectors/usersSelectors';
import { classificationsSelector } from '../../../../../redux/selectors/classificationSelectors';
import { degreeLevelsSelector } from '../../../../../redux/selectors/degreeLevelsSelectors';
import { selectLetterGradesBySchool } from '../../../../../redux/selectors/letterGradeSelectors';
import { programSelector } from '../../../../../redux/selectors/programSelectors';
import {
  validateSelectDate,
  validateSelectField,
  validateInputString
} from '../../../../../helpers/validation/validateGeneric';
import { doPostTranscript } from '../../../../../redux/actions/applicantTranscriptActions';
import { termHash } from '../../../../../helpers/constants';

export default function CognitiveProgramAddContainer({
  formType,
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  modalOpen,
  handleModalClose
}) {
  const dispatch = useDispatch();
  const [itemAge, setItemAge] = useState('0.0');

  const [hasErrors, setHasErrors] = useState(false);
  const [transcriptState, transcriptDispatch] = useReducer(
    cognitiveProgramReducer,
    cognitiveProgramInitial
  );

  const {
    date,
    applicationDate,
    grade,
    termType,
    term,
    year,
    itemCode,
    itemName,
    institutionCode,
    sourceDegreeLevelUuid,
    classificationUuid,
    sourceInstitutionUuid,
    credits,
    score
  } = transcriptState;

  const user = useSelector(state => userSelector(state, userUuid));
  const program = useSelector(state => programSelector(state, programUuid));

  const letterGrades = useSelector(state =>
    selectLetterGradesBySchool(state, program.schoolUuid)
  );

  const letterGradeOptions = letterGrades.map(level => ({
    label: level.grade,
    value: level.grade
  }));

  const letterGradesHash = _.keyBy(letterGrades, 'grade');

  const degreeLevelOptions = useSelector(state =>
    degreeLevelsSelector(state)
  ).map(level => ({ label: level.degreeLevel, value: level.uuid }));

  const classificationOptions = useSelector(state =>
    classificationsSelector(state, programUuid, category, source)
  ).map(classification => ({
    label: classification.scope,
    value: classification.uuid
  }));

  const { createTranscript, createTranscriptSuccess } = useSelector(
    state => state.crudState
  );

  useEffect(() => {
    if (!modalOpen) {
      setHasErrors(false);
      transcriptDispatch({
        type: 'reset'
      });
    }
  }, [dispatch, modalOpen]);

  useEffect(() => {
    if (applicationDate && date) {
      const app = moment.utc(applicationDate);
      const historical = moment.utc(date);
      const days = moment.duration(app.diff(historical)).asDays();
      const itemage = days / 365;
      const format = new Decimal(itemage).toFixed(2).toString();
      setItemAge(format);
    }
  }, [date, applicationDate]);

  useEffect(() => {
    function internalDispatch() {
      const ddmm = _.get(termHash, [`${termType}`, `${term}`]);

      if (ddmm && year) {
        const formatYear = moment(year).format('YYYY');
        const newDate = moment(`${formatYear}-${ddmm}`, 'YYYY-MM-DD');
        transcriptDispatch({ type: 'date', date: newDate });
      } else if (!ddmm && year) {
        transcriptDispatch({ type: 'date', date: undefined });
      }
    }

    internalDispatch();
  }, [termType, term, year]);

  useEffect(() => {
    if (createTranscriptSuccess) {
      handleModalClose();
    }
  }, [dispatch, createTranscriptSuccess, handleModalClose]);

  const handleChangeDate = (_, field, date) => {
    transcriptDispatch({ type: field, date });
  };

  const handleChange = event => {
    const { name, value } = event.target;

    transcriptDispatch({ type: name, value });
  };

  const handleGradeScore = event => {
    const { name, value } = event.target;

    const grade = value;
    const score = letterGradesHash[value].conversion;

    transcriptDispatch({ type: name, grade, score });
  };

  const handleSourceInstitutionUuid = institution => {
    const sourceInstitutionUuid = institution?.uuid;
    const institutionCode = institution?.institutionCode;

    transcriptDispatch({
      type: 'sourceInstitutionUuid',
      sourceInstitutionUuid,
      institutionCode
    });
  };

  const _validate = () => {
    let containErrors = false;

    const applicationDateError = validateSelectDate(applicationDate);
    const termTypeError = validateSelectField(termType);
    const termError = validateSelectField(term);
    const dateError = validateSelectDate(date);
    const yearError = validateSelectDate(year);
    const itemCodeError = validateInputString(itemCode);
    const itemNameError = validateInputString(itemName);
    const sourceDegreeLevelUuidError = validateSelectField(
      sourceDegreeLevelUuid
    );
    const classificationUuidError = validateSelectField(classificationUuid);
    const sourceInstitutionUuidError = validateInputString(
      sourceInstitutionUuid
    );
    const creditsError = validateInputString(credits);
    const gradeError = validateSelectField(grade);

    if (
      applicationDateError.invalid ||
      termTypeError.invalid ||
      termError.invalid ||
      dateError.invalid ||
      yearError.invalid ||
      itemCodeError.invalid ||
      itemNameError.invalid ||
      sourceDegreeLevelUuidError.invalid ||
      classificationUuidError.invalid ||
      sourceInstitutionUuidError.invalid ||
      creditsError.invalid ||
      gradeError.invalid
    ) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleRequest = () => {
    const settings = {
      userUuid,
      cohortUuid,
      programUuid,
      category,
      source
    };

    const record = {
      applicationDate: moment(applicationDate).format('YYYY-MM-DD'),
      classificationUuid,
      credits,
      date: moment(date).format('YYYY-MM-DD'),
      grade,
      itemCode,
      itemName,
      score,
      institutionCode,
      sourceDegreeLevelUuid,
      sourceInstitutionUuid,
      term,
      termType,
      year: moment(year).format('YYYY')
    };

    const check = _validate();

    if (check) {
      setHasErrors(true);
    } else {
      dispatch(doPostTranscript({ ...settings, record }));
    }
  };

  return (
    <CognitiveProgram
      formType={formType}
      loading={createTranscript}
      user={user}
      category={category}
      source={source}
      applicationDate={applicationDate}
      termType={termType}
      term={term}
      date={date}
      year={year}
      itemAge={itemAge}
      itemCode={itemCode}
      itemName={itemName}
      sourceDegreeLevelUuid={sourceDegreeLevelUuid}
      classificationUuid={classificationUuid}
      sourceInstitutionUuid={sourceInstitutionUuid}
      credits={credits}
      grade={grade}
      score={score}
      modalOpen={modalOpen}
      degreeLevelOptions={degreeLevelOptions}
      classificationOptions={classificationOptions}
      letterGradeOptions={letterGradeOptions}
      hasErrors={hasErrors}
      handleModalClose={handleModalClose}
      handleChangeDate={handleChangeDate}
      handleChange={handleChange}
      handleGradeScore={handleGradeScore}
      handleRequest={handleRequest}
      handleSourceInstitutionUuid={handleSourceInstitutionUuid}
    />
  );
}

CognitiveProgramAddContainer.propTypes = {
  formType: PropTypes.string,
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  modalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func
};

CognitiveProgramAddContainer.defaultProps = {
  formType: '',
  category: 'Cognitive',
  source: 'Program',
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  modalOpen: false,
  handleModalClose: undefined
};
