import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectOverallCognitiveCredits } from '../../../../redux/selectors/transcriptCognitiveSelectors';

export default function CognitiveTotalCredits({
  programUuid,
  userUuid,
  cohortUuid,
  category
}) {
  const overallCognitiveCredits = useSelector(state =>
    selectOverallCognitiveCredits(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      category
    )
  );

  const credits = overallCognitiveCredits || 'N/A';

  return <>{credits}</>;
}

CognitiveTotalCredits.propTypes = {
  category: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string
};

CognitiveTotalCredits.defaultProps = {
  category: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined
};
