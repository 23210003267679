import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { selectExamAptitude } from '../../../../../redux/selectors/examAptitudeSelectors';

export default function ExamWeight({ examAptitudeUuid }) {
  const { weight } = useSelector(
    state => selectExamAptitude(state, examAptitudeUuid),
    shallowEqual
  );

  return weight;
}

ExamWeight.propTypes = { classificationUuid: PropTypes.string };
ExamWeight.defaultProps = { classificationUuid: undefined };
