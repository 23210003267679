import React from 'react';
import PropTypes from 'prop-types';
import SectionContainer from '../Section/SectionContainer';

export default function Sections({ sections }) {
  return (
    <>
      {sections?.map(section => (
        <SectionContainer key={section?.uuid} sectionUuid={section?.uuid} />
      ))}
    </>
  );
}

Sections.propTypes = {
  sections: PropTypes.array
};

Sections.defaultProps = {
  sections: undefined
};
