import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Grid, IconButton } from '@material-ui/core';
import { ButtonPrimary, ButtonOutline } from '@xcomp/xcomp-design-library';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';

import {
  ContainerResize,
  ContainerPage,
  ContainerActions,
  ElevateLow
} from '../../../../../Library/Layout';

import Header from '../../../../../Library/Header';
import FormField from '../../../../../Library/FormField';

import { weightPercentage } from '../../../../../../helpers/utilities';
import { validateInputString } from '../../../../../../helpers/validation/validateGeneric';

const TitleArea = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding: 20px;
  margin-bottom: 30px;
`;

const Title = styled.div`
  font-size: 18px;
`;

const Weight = styled.div`
  color: ${props => (props.totalWeight === '100.00' ? 'green' : 'red')};
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
`;

const DeleteIconSC = styled(DeleteIcon)`
  display: flex;
  align-self: center;
`;

const IconArea = styled.div``;

const PageArea = styled.div`
  padding-left: 40px;
`;

export default function EditorSubject({
  subjects,
  hasErrors,
  handleAddSubject,
  handleUpdateScope,
  handleUpdateWeight,
  handleDestroySubject,
  handleSave
}) {
  const [totalWeight, setTotalWeight] = useState('0.00');

  useEffect(() => {
    const total = weightPercentage(subjects);
    setTotalWeight(total);
  }, [subjects]);

  return (
    <ContainerResize>
      <Header title="Cognitive Program" subtitle="Edit Transcript Source" />
      <ContainerActions>
        <ButtonPrimary onClick={handleSave}>Save</ButtonPrimary>
      </ContainerActions>
      <ContainerPage paddingTop="50px">
        <ElevateLow>
          <TitleArea>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={9}>
                <Title>Subjects</Title>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Weight totalWeight={totalWeight}>{totalWeight}%</Weight>
              </Grid>
            </Grid>
          </TitleArea>
          <PageArea>
            {subjects.map(subject => (
              <Grid container spacing={4} direction="row" key={subject.uuid}>
                <Grid item xs={12} sm={8}>
                  <FormField
                    name="scope"
                    label="Subject*"
                    value={subject.scope}
                    onChange={event => handleUpdateScope(event, subject.uuid)}
                    handleValidate={validateInputString}
                    hasErrors={hasErrors}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormField
                    name="weight"
                    label="Weight*"
                    type="number"
                    value={subject.weight}
                    onChange={event => handleUpdateWeight(event, subject.uuid)}
                    handleValidate={validateInputString}
                    hasErrors={hasErrors}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={1}
                  direction="column"
                  justifyContent="center"
                >
                  <IconArea>
                    <IconButton onClick={() => handleDestroySubject(subject)}>
                      <DeleteIconSC />
                    </IconButton>
                  </IconArea>
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                <ButtonOutline onClick={handleAddSubject}>
                  Add Subject
                </ButtonOutline>
              </Grid>
            </Grid>
          </PageArea>
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
}

EditorSubject.propTypes = {
  subjects: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleAddSubject: PropTypes.func,
  handleUpdateScope: PropTypes.func,
  handleUpdateWeight: PropTypes.func,
  handleDestroySubject: PropTypes.func,
  handleSave: PropTypes.func
};

EditorSubject.defaultProps = {
  subjects: [],
  hasErrors: false,
  handleAddSubject: undefined,
  handleUpdateScope: undefined,
  handleUpdateWeight: undefined,
  handleDestroySubject: undefined,
  handleSave: undefined
};
