import {
  CLEAR_UPDATED_ASSESSMENT,
  DELETE_ASSESSMENT_ERROR,
  DELETE_ASSESSMENT_SUCCESS,
  DELETE_ASSESSMENT,
  GET_ASSESSMENT_ASSOCIATIONS_ERROR,
  GET_ASSESSMENT_ASSOCIATIONS_SUCCESS,
  GET_ASSESSMENT_ASSOCIATIONS,
  GET_ASSESSMENT_ERROR,
  GET_ASSESSMENT_SUCCESS,
  GET_ASSESSMENT_TECHNIQUES_ERROR,
  GET_ASSESSMENT_TECHNIQUES_SUCCESS,
  GET_ASSESSMENT_TECHNIQUES,
  GET_ASSESSMENT_TYPE_ERROR,
  GET_ASSESSMENT_TYPE_SUCCESS,
  GET_ASSESSMENT_TYPE,
  GET_ASSESSMENT,
  GET_ASSESSMENTS_BY_PART_ERROR,
  GET_ASSESSMENTS_BY_PART_SUCCESS,
  GET_ASSESSMENTS_BY_PART,
  PATCH_ASSESSMENT_APPROVE_ERROR,
  PATCH_ASSESSMENT_APPROVE_SUCCESS,
  PATCH_ASSESSMENT_APPROVE,
  POST_ASSESSMENT_ERROR,
  POST_ASSESSMENT_SUCCESS,
  POST_ASSESSMENT,
  PUT_ASSESSMENT_DRAFT_ERROR,
  PUT_ASSESSMENT_DRAFT_SUCCESS,
  PUT_ASSESSMENT_DRAFT,
  PUT_ASSESSMENT_FINAL_ERROR,
  PUT_ASSESSMENT_FINAL_SUCCESS,
  PUT_ASSESSMENT_FINAL,
  PUT_ASSESSMENT_STATUS_COMMIT_ERROR,
  PUT_ASSESSMENT_STATUS_COMMIT_SUCCESS,
  PUT_ASSESSMENT_STATUS_COMMIT,
  PUT_ASSESSMENT_STATUS_SCORE_ERROR,
  PUT_ASSESSMENT_STATUS_SCORE_SUCCESS,
  PUT_ASSESSMENT_STATUS_SCORE,
  RESET_STATUS_COMMIT_MODAL,
  PUT_ASSESSMENT_GRADE_DELAYED_STUDENT,
  PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS,
  PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_ERROR,
  DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT,
  DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS,
  DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_ERROR,
  PUT_ASSESSMENT_DELAYED_STATUS_COMMIT,
  PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_SUCCESS,
  PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_ERROR
} from '../actions-type';

const doClearUpdatedAssessment = () => ({
  type: CLEAR_UPDATED_ASSESSMENT
});

const doGetAssessment = assessmentUuid => ({
  type: GET_ASSESSMENT,
  payload: {
    assessmentUuid
  }
});

const doGetAssessmentSuccess = result => ({
  type: GET_ASSESSMENT_SUCCESS,
  payload: result
});

const doGetAssessmentError = error => ({
  type: GET_ASSESSMENT_ERROR,
  payload: error
});

const doGetAssessmentsByPart = partUuid => ({
  type: GET_ASSESSMENTS_BY_PART,
  payload: {
    partUuid
  }
});

const doGetAssessmentsByPartSuccess = result => ({
  type: GET_ASSESSMENTS_BY_PART_SUCCESS,
  payload: result
});

const doGetAssessmentsByPartError = error => ({
  type: GET_ASSESSMENTS_BY_PART_ERROR,
  payload: error
});

const doGetAssessmentType = () => ({
  type: GET_ASSESSMENT_TYPE
});

const doGetAssessmentTypeSuccess = result => ({
  type: GET_ASSESSMENT_TYPE_SUCCESS,
  payload: result
});

const doGetAssessmentTypeError = error => ({
  type: GET_ASSESSMENT_TYPE_ERROR,
  payload: error
});

const doGetAssessmentAssociations = () => ({
  type: GET_ASSESSMENT_ASSOCIATIONS
});

const doGetAssessmentAssociationsSuccess = result => ({
  type: GET_ASSESSMENT_ASSOCIATIONS_SUCCESS,
  payload: result
});

const doGetAssessmentAssociationsError = error => ({
  type: GET_ASSESSMENT_ASSOCIATIONS_ERROR,
  payload: error
});

const doGetAssessmentTechniques = () => ({
  type: GET_ASSESSMENT_TECHNIQUES
});

const doGetAssessmentTechniquesSuccess = result => ({
  type: GET_ASSESSMENT_TECHNIQUES_SUCCESS,
  payload: result
});

const doGetAssessmentTechniquesError = error => ({
  type: GET_ASSESSMENT_TECHNIQUES_ERROR,
  payload: error
});

const doPostAssessment = assessment => ({
  type: POST_ASSESSMENT,
  payload: {
    assessment
  }
});

const doPostAssessmentSuccess = result => ({
  type: POST_ASSESSMENT_SUCCESS,
  payload: result
});

const doPostAssessmentError = error => ({
  type: POST_ASSESSMENT_ERROR,
  payload: error
});

const doPutAssessmentDraft = assessment => ({
  type: PUT_ASSESSMENT_DRAFT,
  payload: {
    assessment
  }
});

const doPutAssessmentDraftSuccess = result => ({
  type: PUT_ASSESSMENT_DRAFT_SUCCESS,
  payload: result
});

const doPutAssessmentDraftError = error => ({
  type: PUT_ASSESSMENT_DRAFT_ERROR,
  payload: error
});

const doPutAssessmentFinal = assessment => ({
  type: PUT_ASSESSMENT_FINAL,
  payload: {
    assessment
  }
});

const doPutAssessmentFinalSuccess = result => ({
  type: PUT_ASSESSMENT_FINAL_SUCCESS,
  payload: result
});

const doPutAssessmentFinalError = error => ({
  type: PUT_ASSESSMENT_FINAL_ERROR,
  payload: error
});

const doPutAssessmentStatusScore = assessment => ({
  type: PUT_ASSESSMENT_STATUS_SCORE,
  payload: {
    assessment
  }
});

const doPutAssessmentStatusScoreSuccess = result => ({
  type: PUT_ASSESSMENT_STATUS_SCORE_SUCCESS,
  payload: result
});

const doPutAssessmentStatusScoreError = error => ({
  type: PUT_ASSESSMENT_STATUS_SCORE_ERROR,
  payload: error
});

const doPutAssessmentStatusCommit = assessment => ({
  type: PUT_ASSESSMENT_STATUS_COMMIT,
  payload: {
    assessment
  }
});

const doPutAssessmentStatusCommitSuccess = result => ({
  type: PUT_ASSESSMENT_STATUS_COMMIT_SUCCESS,
  payload: result
});

const doPutAssessmentStatusCommitError = error => ({
  type: PUT_ASSESSMENT_STATUS_COMMIT_ERROR,
  payload: error
});

const doPutAssessmentDelayedStudentGrade = ({
  assessmentUuid,
  studentUuid,
  resultCardUuid
}) => ({
  type: PUT_ASSESSMENT_GRADE_DELAYED_STUDENT,
  payload: {
    assessmentUuid,
    studentUuid,
    resultCardUuid
  }
});

const doPutAssessmentDelayedStudentGradeSuccess = result => ({
  type: PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS,
  payload: result
});

const doPutAssessmentDelayedStudentGradeError = error => ({
  type: PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_ERROR,
  payload: error
});

const doDeleteAssessmentDelayedStudentGrade = ({
  assessmentUuid,
  studentUuid,
  resultCardUuid
}) => ({
  type: DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT,
  payload: {
    assessmentUuid,
    studentUuid,
    resultCardUuid
  }
});

const doDeleteAssessmentDelayedStudentGradeSuccess = result => ({
  type: DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS,
  payload: result
});

const doDeleteAssessmentDelayedStudentGradeError = error => ({
  type: DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_ERROR,
  payload: error
});

const doPutAssessmentDelayedStudentStatusCommit = ({
  assessmentUuid,
  studentUuid,
  delayedStatusCommit,
  justificationMessage
}) => ({
  type: PUT_ASSESSMENT_DELAYED_STATUS_COMMIT,
  payload: {
    assessmentUuid,
    studentUuid,
    delayedStatusCommit,
    justificationMessage
  }
});

const doPutAssessmentDelayedStudentStatusCommitSuccess = result => ({
  type: PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_SUCCESS,
  payload: result
});

const doPutAssessmentDelayedStudentStatusCommitError = error => ({
  type: PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_ERROR,
  payload: error
});

const doStatusCommitModalReset = () => ({
  type: RESET_STATUS_COMMIT_MODAL
});

const doPatchAssessmentApprove = assessmentUuid => ({
  type: PATCH_ASSESSMENT_APPROVE,
  payload: assessmentUuid
});

const doPatchAssessmentApproveSuccess = result => ({
  type: PATCH_ASSESSMENT_APPROVE_SUCCESS,
  payload: result
});

const doPatchAssessmentApproveError = error => ({
  type: PATCH_ASSESSMENT_APPROVE_ERROR,
  payload: error
});

const doDeleteAssessment = assessmentUuid => ({
  type: DELETE_ASSESSMENT,
  payload: {
    assessmentUuid
  }
});

const doDeleteAssessmentSuccess = assessmentUuid => ({
  type: DELETE_ASSESSMENT_SUCCESS,
  payload: assessmentUuid
});

const doDeleteAssessmentError = error => ({
  type: DELETE_ASSESSMENT_ERROR,
  payload: error
});

export {
  doClearUpdatedAssessment,
  doDeleteAssessment,
  doDeleteAssessmentError,
  doDeleteAssessmentSuccess,
  doGetAssessment,
  doGetAssessmentAssociations,
  doGetAssessmentAssociationsError,
  doGetAssessmentAssociationsSuccess,
  doGetAssessmentError,
  doGetAssessmentsByPart,
  doGetAssessmentsByPartError,
  doGetAssessmentsByPartSuccess,
  doGetAssessmentSuccess,
  doGetAssessmentTechniques,
  doGetAssessmentTechniquesError,
  doGetAssessmentTechniquesSuccess,
  doGetAssessmentType,
  doGetAssessmentTypeError,
  doGetAssessmentTypeSuccess,
  doPatchAssessmentApprove,
  doPatchAssessmentApproveError,
  doPatchAssessmentApproveSuccess,
  doPostAssessment,
  doPostAssessmentError,
  doPostAssessmentSuccess,
  doPutAssessmentDraft,
  doPutAssessmentDraftError,
  doPutAssessmentDraftSuccess,
  doPutAssessmentFinal,
  doPutAssessmentFinalError,
  doPutAssessmentFinalSuccess,
  doPutAssessmentStatusCommit,
  doPutAssessmentStatusCommitError,
  doPutAssessmentStatusCommitSuccess,
  doPutAssessmentStatusScore,
  doPutAssessmentStatusScoreError,
  doPutAssessmentStatusScoreSuccess,
  doStatusCommitModalReset,
  doPutAssessmentDelayedStudentGrade,
  doPutAssessmentDelayedStudentGradeSuccess,
  doPutAssessmentDelayedStudentGradeError,
  doDeleteAssessmentDelayedStudentGrade,
  doDeleteAssessmentDelayedStudentGradeSuccess,
  doDeleteAssessmentDelayedStudentGradeError,
  doPutAssessmentDelayedStudentStatusCommit,
  doPutAssessmentDelayedStudentStatusCommitSuccess,
  doPutAssessmentDelayedStudentStatusCommitError
};
