import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox
} from '@material-ui/core';

import { Column, TextFieldWithError } from '../../../Library/FormComponents';
import { validateStringLength } from '../../../../helpers/validation/validateGeneric';
import { validateCreditsRequired } from '../../../../helpers/validation/validateCourseCollection';

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

const CheckboxContainer = styled.div`
  color: ${props => props.theme.fontColors.dark};
`;

const CourseCollectionFormDetails = ({
  name,
  credits_required,
  special_collection,
  all_credits_required,
  showValidationErrors,
  handleNameChange,
  handleCreditsRequiredChange,
  handleAllCreditsRequiredChange,
  handleSpecialCollectionChange
}) => {
  const validateNameField = value => validateStringLength(value, 100, true);
  const validateCreditsRequiredField = value => validateCreditsRequired(value);
  const changeAllCreditsRequired = () =>
    handleAllCreditsRequiredChange(!all_credits_required);
  const changeSpecialCollection = () =>
    handleSpecialCollectionChange(!special_collection);
  return (
    <Layout>
      <Column size="75%">
        <TextFieldWithError
          autoFocus
          id="courseCollectionName"
          name="name"
          value={name}
          onChange={handleNameChange}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Collection Name"
          placeholder=""
          variant="outlined"
          paddingRight="1rem"
          handleValidate={validateNameField}
          hasErrors={showValidationErrors}
        />
      </Column>
      <Column size="25%">
        <TextFieldWithError
          autoFocus
          id="courseCollectionCreditsRequired"
          name="credits_required"
          value={credits_required || ''}
          onChange={handleCreditsRequiredChange}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Credits Required"
          placeholder=""
          variant="outlined"
          disabled={all_credits_required}
          handleValidate={validateCreditsRequiredField}
          hasErrors={all_credits_required ? false : showValidationErrors}
        />
      </Column>
      <Column size="75%">
        <FormControl component="fieldset">
          <FormGroup>
            <CheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    id="special_collection"
                    onChange={changeSpecialCollection}
                    checked={special_collection}
                    color="primary"
                  />
                }
                label="This is a Special Collection"
              />
            </CheckboxContainer>
          </FormGroup>
        </FormControl>
      </Column>
      <Column size="25%">
        <FormControl component="fieldset">
          <FormGroup>
            <CheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    id="all_credits_required"
                    color="primary"
                    checked={all_credits_required}
                    onChange={changeAllCreditsRequired}
                  />
                }
                label="All Credits Required"
              />
            </CheckboxContainer>
          </FormGroup>
        </FormControl>
      </Column>
    </Layout>
  );
};

CourseCollectionFormDetails.propTypes = {
  name: PropTypes.string,
  credits_required: PropTypes.string,
  special_collection: PropTypes.bool,
  all_credits_required: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  handleNameChange: PropTypes.func,
  handleCreditsRequiredChange: PropTypes.func,
  handleAllCreditsRequiredChange: PropTypes.func,
  handleSpecialCollectionChange: PropTypes.func
};
CourseCollectionFormDetails.defaultProps = {
  name: '',
  credits_required: '',
  special_collection: false,
  all_credits_required: false,
  showValidationErrors: false,
  handleNameChange: undefined,
  handleCreditsRequiredChange: undefined,
  handleAllCreditsRequiredChange: undefined,
  handleSpecialCollectionChange: undefined
};

export default CourseCollectionFormDetails;
