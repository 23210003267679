import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonPin from '@material-ui/icons/PersonPin';
import {
  ButtonPrimary,
  ButtonOutline,
  Body1,
  Heading3
} from '@xcomp/xcomp-design-library';

import Modal from '../../../../../../Library/Modal/Modal';
import { StatusPill } from '../../../../../../Library/StatusPill';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 900px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
  margin-bottom: 20px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
`;

const FileUpload = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Note = styled(Body1)`
  margin-top: 10px;
`;

const StatusHeading = styled(Heading3)`
  width: 100%;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const CloseButton = styled(ButtonOutline)`
  && {
    margin-right: 5px;
  }
`;

const ProceedButton = styled(ButtonPrimary)`
  && {
    margin-right: 5px;
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Created = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.white};
  border-radius: 5px;
  padding: 15px;
  background: ${props => props.theme.colors.status.success};
`;

const getModalView = (
  statusGrade,
  isLoading,
  successfullyGraded,
  handleModalClose,
  handleModalSuccess,
  handleForceSubmit,
  handleSubmit
) => {
  if (successfullyGraded) {
    return (
      <>
        <Created>
          <PersonPin />
          Successfully graded this Part and its collections.
        </Created>

        <Actions>
          <div>
            <CloseButton onClick={handleModalSuccess}>Close</CloseButton>
          </div>
        </Actions>
      </>
    );
  }

  if (isLoading)
    return (
      <>
        <FileUpload>
          <StatusHeading>Grading In Progress</StatusHeading>
        </FileUpload>
        <Loading>
          <CircularProgress size="100px" />
        </Loading>
        {/* <div>
          <Body1>
            Grading taking too long? Click <i>Reset Grading</i> below to reset
            the grading status and try again.
          </Body1>
          <ButtonOutline onClick={onDeleteClick}>
            <RemoveIconSC />
            Reset Grading
          </ButtonOutline>
        </div> */}
      </>
    );

  return (
    <>
      <FileUpload>
        <Note>
          Clicking <strong>Proceed</strong> below will run grade calculations
          for this section. Are you sure you want to proceed?
        </Note>
      </FileUpload>
      <Actions>
        <div>
          <CloseButton onClick={handleModalClose}>Cancel</CloseButton>
          <ProceedButton
            onClick={() => {
              handleSubmit(false);
            }}
          >
            Proceed
          </ProceedButton>
        </div>
      </Actions>
    </>
  );
};

export default function StatusGradingModal({
  statusGrade,
  sucessState,
  isModalOpen,
  handleModalClose,
  handleModalSuccess,
  handleSubmit
}) {
  const { isLoading, successfullyGraded } = sucessState || {
    isLoading: false,
    invalid: false,
    successfullyGraded: false
  };

  const handleForceSubmit = () => {
    handleSubmit(true);
  };

  const modalView = getModalView(
    statusGrade,
    isLoading,
    successfullyGraded,
    handleModalClose,
    handleModalSuccess,
    handleForceSubmit,
    handleSubmit
  );

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <ModalBody>
        <ModalHeader>
          <Title>Grade Part</Title>
          <StatusPill status={statusGrade} />
        </ModalHeader>

        {modalView}
      </ModalBody>
    </Modal>
  );
}

StatusGradingModal.propTypes = {
  isModalOpen: PropTypes.bool,
  statusGrade: PropTypes.string,
  sucessState: PropTypes.object,
  handleModalClose: PropTypes.func,
  handleModalSuccess: PropTypes.func,
  handleSubmit: PropTypes.func
};

StatusGradingModal.defaultProps = {
  isModalOpen: false,
  statusGrade: 'NOT_GRADED',
  sucessState: {},
  handleModalClose: undefined,
  handleModalSuccess: undefined,
  handleSubmit: undefined
};
