import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MoreIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonIcon } from '@xcomp/xcomp-design-library';

export default function DepartmentActions({ className, departmentUuid }) {
  const [anchorEl, SetAnchorEl] = useState(null);

  const handleClick = event => {
    event.stopPropagation();
    SetAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    SetAnchorEl(null);
  };

  return (
    <div className={className}>
      <ButtonIcon
        aria-owns={anchorEl ? 'simple-menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
        noHoverBg
      >
        <MoreIcon />
      </ButtonIcon>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to={`/department-management/edit/${departmentUuid}`}>
          <MenuItem onClick={handleClose}>Edit</MenuItem>
        </Link>
        <Link to={`/department-management/role/${departmentUuid}`}>
          <MenuItem onClick={handleClose}>Faculty & Staff</MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

DepartmentActions.propTypes = {
  className: PropTypes.string,
  departmentUuid: PropTypes.string
};

DepartmentActions.defaultProps = {
  className: '',
  departmentUuid: ''
};
