import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { MoreIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonIcon } from '@xcomp/xcomp-design-library';

const Layout = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
`;

export default function SchoolActions({ className, schoolUuid }) {
  const [anchorEl, SetAnchorEl] = useState(null);

  const handleClick = event => {
    event.stopPropagation();
    SetAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    SetAnchorEl(null);
  };

  return (
    <Layout className={className}>
      <ButtonIcon
        aria-owns={anchorEl ? 'simple-menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
        noHoverBg
      >
        <MoreIcon />
      </ButtonIcon>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to={`/institution-management/school/edit/${schoolUuid}`}>
          <MenuItem>Edit</MenuItem>
        </Link>
        <Link to={`/institution-management/school/role/${schoolUuid}`}>
          <MenuItem onClick={handleClose}>School Admins</MenuItem>
        </Link>
      </Menu>
    </Layout>
  );
}

SchoolActions.propTypes = {
  className: PropTypes.string,
  schoolUuid: PropTypes.string
};

SchoolActions.defaultProps = {
  className: '',
  schoolUuid: ''
};
