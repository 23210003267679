import {
  PUT_APPLICANT_DEMOGRAPHIC_REPORT,
  PUT_APPLICANT_DEMOGRAPHIC_REPORT_SUCCESS,
  PUT_APPLICANT_DEMOGRAPHIC_REPORT_ERROR,
  CLEAR_APPLICANT_DEMOGRAPHIC_REPORT
} from '../actions-type';

export const INITIAL_STATE = {
  demographicReport: [],
  loadingDemographicReport: false,
  reportHeaders: [],
  reportSummary: {},
  reportType: ''
};

const applicantDemographicReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PUT_APPLICANT_DEMOGRAPHIC_REPORT_SUCCESS: {
      const {
        demographicReport,
        reportHeaders,
        reportSummary,
        reportType
      } = action.payload;
      return {
        ...state,
        demographicReport,
        reportHeaders,
        reportSummary,
        reportType,
        loadingDemographicReport: false
      };
    }
    case PUT_APPLICANT_DEMOGRAPHIC_REPORT:
      return {
        ...state,
        loadingDemographicReport: true
      };
    case PUT_APPLICANT_DEMOGRAPHIC_REPORT_ERROR:
      return {
        ...state,
        loadingDemographicReport: false
      };
    case CLEAR_APPLICANT_DEMOGRAPHIC_REPORT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default applicantDemographicReportReducer;
