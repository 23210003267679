import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Body2, Caption } from '@xcomp/xcomp-design-library';
import { Box, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

const CompetencyDetails = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
  //border: 1px solid ${props => props.theme.colors.border};
`;

const ProcedureCodeRow = ({ procedureCode, index }) => {
  return (
    <CompetencyDetails elevation={3}>
      <Body2>{procedureCode.procedureCode.code}</Body2>
      <Caption>Procedure Code {index + 1}</Caption>
      <Divider />
      <Box>
        <Body2>{procedureCode.procedureCode.description}</Body2>
      </Box>
    </CompetencyDetails>
  );
};

ProcedureCodeRow.propTypes = {
  procedureCode: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default ProcedureCodeRow;
