import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { selectUserSection } from '../../../../redux/selectors/userSectionsSelectors';

export default function StudentStatus({ userUuid, sectionUuid }) {
  const status = useSelector(
    state => selectUserSection(state, userUuid, sectionUuid),
    _.isEqual
  );

  return <>{status}</>;
}

StudentStatus.propTypes = {
  userUuid: PropTypes.string,
  sectionUuid: PropTypes.string
};

StudentStatus.defaultProps = {
  userUuid: undefined,
  sectionUuid: undefined
};
