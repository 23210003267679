import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useParams,
  useLocation,
  useRouteMatch,
  useHistory
} from 'react-router-dom';
import _ from 'lodash';
import { doGetPart } from '../../../redux/actions/partActions';
import { doGetStudentRoster } from '../../../redux/actions/userSectionActions';
import { partSelectorByUuid } from '../../../redux/selectors/partsSelectors';
import { studentRosterSelectorBySectionUuid } from '../../../redux/selectors/sectionsSelectors';

import PartGradeSummary from './PartGradeSummary';

const PartGradeSummaryContainer = () => {
  const { partUuid, sectionUuid } = useParams();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState(0);
  const [basePath, setBasePath] = useState(
    _(path).replace(':partUuid', partUuid).replace(':sectionUuid', sectionUuid)
  );

  const part = useSelector(state => partSelectorByUuid(state, partUuid)) || {};

  const section = _.get(part, 'section', null);
  const course = _.get(section, 'course', null);
  const courseNumber = _.get(course, 'course_master.courseNumber', '');
  const courseTitle = _.get(course, 'course_master.title', '');
  const paddedSectionNumber = _.get(section, 'sectionNumber', '')
    .toString()
    .padStart(2, 0);
  const paddedPartNumber = _.get(part, 'partNumber', '')
    .toString()
    .padStart(2, 0);

  const students = useSelector(state =>
    studentRosterSelectorBySectionUuid(state, sectionUuid)
  );

  useEffect(() => {
    const tabMap = {
      'assessment-collection': 0,
      'assessment-type': 1,
      faculty: 2,
      topic: 3,
      group: 4,
      attempt: 5,
      didactic: 6,
      opportunity: 7,
      'self-assessment': 8,
    };

    const baseRoute = `/syllabus-management/part/${partUuid}/section/${sectionUuid}/grade-summary/`;
    const pathSuffix = pathname.replace(baseRoute, '');

    setCurrentTab(tabMap[pathSuffix] || 0);
  }, [pathname, partUuid, sectionUuid]);

  useEffect(() => {
    setBasePath(
      _(basePath)
        .replace(':partUuid', partUuid)
        .replace(':sectionUuid', sectionUuid)
    );
  }, [basePath, partUuid, sectionUuid]);

  useEffect(() => {
    dispatch(doGetPart(partUuid));
  }, [dispatch, partUuid]);

  useEffect(() => {
    if (sectionUuid) {
      dispatch(doGetStudentRoster(sectionUuid));
    }
  }, [dispatch, sectionUuid]);

  const handleBackAction = useCallback(
    () => history.push('/syllabus-management/all'),
    [history]
  );

  return (
    <PartGradeSummary
      basePath={basePath}
      courseNumber={courseNumber}
      courseTitle={courseTitle}
      currentTab={currentTab}
      onBackAction={handleBackAction}
      part={part}
      partNumber={paddedPartNumber}
      partTitle={part?.title}
      partUuid={partUuid}
      sectionUuid={sectionUuid}
      path={path}
      sectionNumber={paddedSectionNumber}
      students={students}
    />
  );
};

export default PartGradeSummaryContainer;
