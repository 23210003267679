import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { decimalTenth } from '../../../../../helpers/utilities';
import DegreeLevel from '../DataCells/DegreeLevel';

const Spacing = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Layout = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Record = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => (props.width ? props.width : '120px')};
`;

export default function ByInstitutionTableRecords({ transcript }) {
  return (
    <Layout>
      <Record />
      <Record>
        <Spacing>{transcript?.term}</Spacing>
      </Record>

      <Record>
        <Spacing>{transcript?.year}</Spacing>
      </Record>
      <Record width="300px">
        <DegreeLevel
          sourceDegreeLevelUuid={transcript?.sourceDegreeLevelUuid}
        />
      </Record>
      <Record>
        <Spacing>{transcript?.itemCode}</Spacing>
      </Record>
      <Record width="300px">
        <Spacing>{transcript?.itemName}</Spacing>
      </Record>
      <Record>
        <Spacing>{decimalTenth(transcript?.credits)}</Spacing>
      </Record>
      <Record>
        <Spacing>{transcript?.grade}</Spacing>
      </Record>
      <Record>
        <Spacing>{transcript?.score}</Spacing>
      </Record>
      <Record width="66px">&nbsp;</Record>
    </Layout>
  );
}

ByInstitutionTableRecords.propTypes = {
  transcript: PropTypes.object
};
ByInstitutionTableRecords.defaultProps = {
  transcript: {}
};
