import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SectionPerformanceTableHeader from './PerformanceTable/SectionPerformanceTableHeader';
import {
  Table,
  TableBody,
  ScrollableDiv,
  NoDataContainer
} from './PerformanceTable/SharedSC';
import StudentPerformanceTableRow from './PerformanceTable/StudentPerformanceTableRow';
import { Panel } from '../../../../Library/Layout';

const SectionPerformanceTable = ({
  sectionPerformanceReport,
  isLoadingReport,
  parts
}) => {
  const [useFillerColumn, setUseFillerColumn] = useState(false);
  const [fillerColumnWidth, setFillerColumnWidth] = useState(0);
  const [width, setWidth] = useState(0);

  const tableRef = useRef(null);

  useLayoutEffect(() => {
    const cur = tableRef?.current?.clientWidth ?? 0;
    setWidth(cur);
  }, []);

  useEffect(() => {
    const occupiedWidth = 475 + parts.length * 75;
    if (width > occupiedWidth) {
      setUseFillerColumn(true);
      setFillerColumnWidth(width - occupiedWidth);
    } else {
      setUseFillerColumn(false);
      setFillerColumnWidth(0);
    }
  }, [width, parts.length, isLoadingReport]);

  return (
    <Panel>
      <ScrollableDiv>
        <Table ref={tableRef}>
          <TableBody>
            <SectionPerformanceTableHeader
              parts={parts}
              useFillerColumn={useFillerColumn}
              fillerColumnWidth={fillerColumnWidth}
            />
            {sectionPerformanceReport.length === 0 && (
              <NoDataContainer>No Section Performance Data</NoDataContainer>
            )}
            {sectionPerformanceReport.map((studentReportInfo, idx) => (
              <StudentPerformanceTableRow
                key={studentReportInfo.uuid}
                studentReportInfo={studentReportInfo}
                parts={parts}
                useFillerColumn={useFillerColumn}
                fillerColumnWidth={fillerColumnWidth}
                idx={idx}
              />
            ))}
          </TableBody>
        </Table>
      </ScrollableDiv>
    </Panel>
  );
};

SectionPerformanceTable.propTypes = {
  sectionPerformanceReport: PropTypes.array,
  isLoadingReport: PropTypes.bool,
  parts: PropTypes.array
};

SectionPerformanceTable.defaultProps = {
  sectionPerformanceReport: [],
  isLoadingReport: false,
  parts: []
};

export default SectionPerformanceTable;
