import moment from 'moment';

const cognitiveProgramInitial = {
  programUuid: undefined,
  cohortUuid: undefined,
  userUuid: undefined,
  classificationUuid: undefined,
  applicationDate: undefined,
  termType: undefined,
  term: undefined,
  year: undefined,
  date: undefined,
  itemCode: undefined,
  itemName: undefined,
  credits: undefined,
  grade: undefined,
  score: undefined,
  sourceDegreeLevelUuid: undefined,
  sourceInstitutionUuid: undefined
};

const cognitiveProgramReducer = (state, action) => {
  switch (action.type) {
    case 'exist': {
      const {
        applicationDate,
        termType,
        term,
        year,
        itemCode,
        itemName,
        classificationUuid,
        sourceInstitutionUuid,
        sourceDegreeLevelUuid,
        credits,
        grade,
        score,
        date
      } = { ...action.transcript };

      const appDate = moment(applicationDate, 'YYYY-MM-DD').toDate();
      const tranDate = moment(date, 'YYYY-MM-DD').toDate();

      return {
        ...state,
        applicationDate: appDate,
        date: tranDate,
        year: new Date(year),
        termType,
        term,
        itemCode,
        itemName,
        classificationUuid,
        sourceInstitutionUuid,
        sourceDegreeLevelUuid,
        credits,
        grade,
        score
      };
    }
    case 'reset': {
      return {
        ...cognitiveProgramInitial
      };
    }
    case 'applicationDate': {
      const { date } = { ...action };

      return {
        ...state,
        applicationDate: date
      };
    }
    case 'termType': {
      const { value } = { ...action };

      return {
        ...state,
        termType: value,
        term: undefined
      };
    }
    case 'term': {
      const { value } = { ...action };

      return {
        ...state,
        term: value
      };
    }
    case 'year': {
      const { date } = { ...action };

      return {
        ...state,
        year: date
      };
    }
    case 'date': {
      const { date } = { ...action };

      return {
        ...state,
        date
      };
    }
    case 'itemCode': {
      const { value } = { ...action };

      return {
        ...state,
        itemCode: value
      };
    }
    case 'itemName': {
      const { value } = { ...action };

      return {
        ...state,
        itemName: value
      };
    }
    case 'classificationUuid': {
      const { value } = { ...action };

      return {
        ...state,
        classificationUuid: value
      };
    }
    case 'sourceInstitutionUuid': {
      const { institutionCode, sourceInstitutionUuid } = { ...action };

      return {
        ...state,
        sourceInstitutionUuid,
        institutionCode
      };
    }
    case 'sourceDegreeLevelUuid': {
      const { value } = { ...action };

      return {
        ...state,
        sourceDegreeLevelUuid: value
      };
    }
    case 'credits': {
      const { value } = { ...action };

      return {
        ...state,
        credits: value
      };
    }
    case 'grade': {
      const { score, grade } = { ...action };

      return {
        ...state,
        grade,
        score
      };
    }
    default:
      return {
        ...state
      };
  }
};

export { cognitiveProgramReducer, cognitiveProgramInitial };
