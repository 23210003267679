import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2 } from '@xcomp/xcomp-design-library';

import {
  ContainerPage,
  Panel,
  PanelHeader,
  ContainerResize
} from '../../../Library/Layout';
import Header from '../../../Library/Header';
import UnsavedChangesModal from '../../../Library/Modal/UnsavedChangesModal/UnsavedChangesModal';
import CompetencyCollectionForm from '../Form/CompetencyCollectionForm';

const HeaderSpacer = styled.div`
  width: 100%;
  height: 36px;
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const EditCompetencyCollection = ({
  competencyCollection,
  unsavedChanges,
  isUnsavedChangesModalOpen,
  handleNameChange,
  handleDefaultCollectionChange,
  onSubmit,
  clearForm,
  handleModalClose,
  handleProceed,
  setUnsavedChange,
  removeUnsavedChange,
  formDispatch,
  showValidationErrors
}) => {
  return (
    <ContainerResize>
      <UnsavedChangesModal
        modalOpen={isUnsavedChangesModalOpen}
        message="The form has unsaved changes. Click cancel to go back and update the form or click Discard Changes to return to the competency management page."
        proceedButtonText="Discard Changes"
        handleProceed={handleProceed}
        handleModalClose={handleModalClose}
      />
      <Header
        title="Edit Collection"
        subtitle="Competency Managment"
        backButtonLocation="/program-management/competency"
        backButtonOverride={clearForm}
      />
      <ContainerPage>
        <HeaderSpacer />
        <Panel>
          <PanelHeader paddingLeft="24px">
            <Heading2>Competency Collection</Heading2>
          </PanelHeader>
          <PanelPadding>
            <CompetencyCollectionForm
              competencyCollection={competencyCollection}
              buttonText="Update"
              handleNameChange={handleNameChange}
              handleDefaultCollectionChange={handleDefaultCollectionChange}
              formDispatch={formDispatch}
              onSubmit={onSubmit}
              onFormClose={clearForm}
              unsavedChanges={unsavedChanges}
              showValidationErrors={showValidationErrors}
              setUnsavedChange={setUnsavedChange}
              removeUnsavedChange={removeUnsavedChange}
              withCompetencies
            />
          </PanelPadding>
        </Panel>
      </ContainerPage>
    </ContainerResize>
  );
};

EditCompetencyCollection.propTypes = {
  unsavedChanges: PropTypes.object,
  competencyCollection: PropTypes.object.isRequired,
  handleNameChange: PropTypes.func,
  handleDefaultCollectionChange: PropTypes.func,
  onSubmit: PropTypes.func,
  formDispatch: PropTypes.func,
  clearForm: PropTypes.func,
  setUnsavedChange: PropTypes.func,
  removeUnsavedChange: PropTypes.func,
  handleModalClose: PropTypes.func,
  handleProceed: PropTypes.func,
  isUnsavedChangesModalOpen: PropTypes.bool,
  showValidationErrors: PropTypes.bool
};

EditCompetencyCollection.defaultProps = {
  unsavedChanges: {},
  handleNameChange: undefined,
  handleDefaultCollectionChange: undefined,
  onSubmit: undefined,
  formDispatch: undefined,
  clearForm: undefined,
  setUnsavedChange: undefined,
  removeUnsavedChange: undefined,
  handleModalClose: undefined,
  handleProceed: undefined,
  isUnsavedChangesModalOpen: false,
  showValidationErrors: false
};

export default EditCompetencyCollection;
