import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import Modal from '../Library/Modal/Modal';

export const ModalBody = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function SyllabusModalCommit({
  modalCommitOpen,
  handleModalApproveSyllabusClose,
  handleSubmitForCommit
}) {
  return (
    <Modal open={modalCommitOpen} onClose={handleModalApproveSyllabusClose}>
      <ModalBody>
        <p>
          Are you sure you are finished approving and want to publish the
          syllabus?
        </p>
        <ModalButtons>
          <ButtonInline onClick={() => handleModalApproveSyllabusClose()}>
            Stay
          </ButtonInline>
          <ButtonInline
            onClick={() => {
              handleModalApproveSyllabusClose();
              handleSubmitForCommit();
            }}
          >
            Submit
          </ButtonInline>
        </ModalButtons>
      </ModalBody>
    </Modal>
  );
}

SyllabusModalCommit.propTypes = {
  modalCommitOpen: PropTypes.bool,
  handleModalApproveSyllabusClose: PropTypes.func,
  handleSubmitForCommit: PropTypes.func
};

SyllabusModalCommit.defaultProps = {
  modalCommitOpen: false,
  handleModalApproveSyllabusClose: undefined,
  handleSubmitForCommit: undefined
};
