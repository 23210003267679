import React, { useState } from 'react';
import useSWRMutation from 'swr/mutation';
import styled from 'styled-components';

import ContainerResize from '../Library/Layout';
import Header from '../Library/Header';
import Filters from './Filters';
import Logs from './Logs';
import { fetcher } from '../../helpers/utilities';
import LogModal from './LogModal';
import InfoModal from './InfoModal';

const ContainerPage = styled.div`
  background: ${props => props.theme.colors.white};
  width: 100%;
  padding-top: 70px;
`

export default function InstitutionLogs() {
  const [openModal, setOpenModal] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const { trigger, error, data, isMutating } = useSWRMutation(
    `/api/logs`,
    fetcher,
    {
      throwOnError: false,
      revalidateOnFocus: false,
    }
  );

  return (
    <ContainerResize style={{background: 'white'}}>
      <Header
        backOn={false}
        title="User Action Logs"
      />
      <ContainerPage>
        <Filters onSubmit={trigger} onOpenInfo={setOpenInfo} />
        <Logs error={error} data={data} isMutating={isMutating} setOpenModal={setOpenModal} />
        {openModal && <LogModal logUuid={openModal} handleModalClose={() => setOpenModal(null)} openModal={openModal} />}
        {openInfo && <InfoModal logUuid={openInfo} handleModalClose={() => setOpenInfo(false)} openModal={openInfo} />}
      </ContainerPage>
    </ContainerResize>
  );
}
