import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import moment from 'moment';
import { orm } from '../models';
import { rolesMap, userStatusMap } from '../../helpers/constants';

export const userProgramsSelector = createSelector(
  state => state,
  (_, userUuid) => userUuid,
  (redux, userUuid) => {
    const userProgramsSelectorORM = ormCreateSelector([orm], session => {
      const userPrograms = session.UserProgram.all()
        .filter(userProgram => userProgram.userUuid === userUuid)
        .toRefArray();

      const userProgramsWithProgram = userPrograms.map(userProgram => {
        const program = session.Program.withId(userProgram.programUuid);

        const formatStartDate = moment(userProgram.startDate).isValid()
          ? moment(userProgram.startDate).format('ll')
          : '';

        const formatEndDate = moment(userProgram.endDate).isValid()
          ? moment(userProgram.endDate).format('ll')
          : '';

        const userProgramAdjust = {
          ...userProgram,
          role: rolesMap[userProgram.role],
          status: userStatusMap[userProgram.status],
          startDate: formatStartDate,
          endDate: formatEndDate,
          schoolUuid: program.schoolUuid
        };

        const school = session.School.withId(program.schoolUuid);
        const schoolInfo = school ? school.ref : {};

        const programInfo = program ? program.ref : {};
        const merge = _.merge(
          userProgramAdjust,
          { programInfo },
          { schoolInfo }
        );

        return merge;
      });

      return userProgramsWithProgram;
    });

    const userPrograms = userProgramsSelectorORM(redux);
    return userPrograms;
  }
);

export const programFacultyUsersSelector = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const userProgramsSelectorORM = ormCreateSelector(orm.UserProgram);
    const usersSelectorORM = ormCreateSelector(orm.User);
    const programsSelectorORM = ormCreateSelector(orm.Program);

    const userPrograms = userProgramsSelectorORM(redux).filter(
      userProgram =>
        userProgram.programUuid === programUuid &&
        userProgram.status === 'active'
    );

    const program = programsSelectorORM(redux, programUuid);

    const userProgramsWithProgram = userPrograms.map(userProgram => {
      const user = usersSelectorORM(redux, userProgram.userUuid);

      const formatStartDate = moment(userProgram.startDate).isValid()
        ? moment(userProgram.startDate).format('ll')
        : '';

      const formatEndDate = moment(userProgram.endDate).isValid()
        ? moment(userProgram.endDate).format('ll')
        : '';

      const {
        firstName,
        lastName,
        middleName,
        marriedLastName,
        institutionId,
        uuid
      } = user;

      const { email } = userProgram;

      const userProfile = {
        institutionId,
        uuid,
        firstName,
        lastName,
        middleName,
        marriedLastName
      };

      const userProgramAdjust = {
        ...userProfile,
        ...userProgram,
        email,
        role: rolesMap[userProgram.role],
        status: userStatusMap[userProgram.status],
        startDate: formatStartDate,
        endDate: formatEndDate,
        schoolUuid: program.schoolUuid
      };

      return userProgramAdjust;
    });

    return userProgramsWithProgram;
  }
);

export const programFacultySelector = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const userProgramsSelectorORM = ormCreateSelector(orm.UserProgram);
    const usersSelectorORM = ormCreateSelector(orm.User);

    const userPrograms = userProgramsSelectorORM(redux).filter(
      userProgram =>
        userProgram.programUuid === programUuid &&
        userProgram.status === 'active'
    );

    const faculty = userPrograms.map(userProgram => {
      const user = usersSelectorORM(redux, userProgram.userUuid);

      const formatStartDate = moment(userProgram.startDate).isValid()
        ? moment(userProgram.startDate).format('ll')
        : '';

      const formatEndDate = moment(userProgram.endDate).isValid()
        ? moment(userProgram.endDate).format('ll')
        : '';

      const {
        firstName,
        lastName,
        middleName,
        marriedLastName,
        institutionId,
        uuid
      } = user;

      const { email } = userProgram;

      const userProfile = {
        institutionId,
        uuid,
        firstName,
        lastName,
        middleName,
        marriedLastName
      };

      const userProgramAdjust = {
        user,
        ...userProfile,
        ...userProgram,
        email,
        role: rolesMap[userProgram.role],
        status: userStatusMap[userProgram.status],
        startDate: formatStartDate,
        endDate: formatEndDate
      };

      return userProgramAdjust;
    });

    return faculty;
  }
);
