import {
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_ERROR,
  GET_COURSES,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERROR,
  GET_COURSES_BY_DEPARTMENT_AY,
  GET_COURSES_BY_DEPARTMENT_AY_SUCCESS,
  GET_COURSES_BY_DEPARTMENT_AY_ERROR,
  POST_COURSE,
  POST_COURSE_SUCCESS,
  POST_COURSE_ERROR,
  PUT_COURSE,
  PUT_COURSE_SUCCESS,
  PUT_COURSE_ERROR,
  DELETE_COURSE,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_ERROR,
  PUT_COURSE_LOCK,
  PUT_COURSE_LOCK_SUCCESS,
  PUT_COURSE_LOCK_ERROR,
  GET_COURSE_BY_STUDENT,
  GET_COURSE_BY_STUDENT_SUCCESS,
  GET_COURSE_BY_STUDENT_ERROR,
  SET_IS_LOADING_COURSE_EDIT
} from '../actions-type';

const doGetCourse = courseUuid => ({
  type: GET_COURSE,
  payload: {
    courseUuid
  }
});

const doGetCourseSuccess = result => {
  return {
    type: GET_COURSE_SUCCESS,
    payload: result
  };
};

const doGetCourseError = error => ({
  type: GET_COURSE_ERROR,
  payload: error
});

const doGetCoursesByDeptAy = (departmentUuid, academicYearUuid) => ({
  type: GET_COURSES_BY_DEPARTMENT_AY,
  payload: {
    departmentUuid,
    academicYearUuid
  }
});

const doGetCoursesByDeptAySuccess = result => ({
  type: GET_COURSES_BY_DEPARTMENT_AY_SUCCESS,
  payload: result
});

const doGetCoursesByDeptAyError = error => ({
  type: GET_COURSES_BY_DEPARTMENT_AY_ERROR,
  payload: error
});

const doGetCourses = (courseMasterUuid, academicYearUuid, termUuid) => ({
  type: GET_COURSES,
  payload: {
    courseMasterUuid,
    academicYearUuid,
    termUuid
  }
});

const doGetCoursesSuccess = result => ({
  type: GET_COURSES_SUCCESS,
  payload: result
});

const doGetCoursesError = error => ({
  type: GET_COURSES_ERROR,
  payload: error
});

const doPostCourse = course => ({
  type: POST_COURSE,
  payload: {
    course
  }
});

const doPostCourseSuccess = result => ({
  type: POST_COURSE_SUCCESS,
  payload: result
});

const doPostCourseError = error => ({
  type: POST_COURSE_ERROR,
  payload: error
});

const doPutCourse = course => ({
  type: PUT_COURSE,
  payload: {
    course
  }
});

const doPutCourseSuccess = result => ({
  type: PUT_COURSE_SUCCESS,
  payload: result
});

const doPutCourseError = error => ({
  type: PUT_COURSE_ERROR,
  payload: error
});

const doDeleteCourse = courseUuid => ({
  type: DELETE_COURSE,
  payload: {
    courseUuid
  }
});

const doDeleteCourseSuccess = courseUuid => ({
  type: DELETE_COURSE_SUCCESS,
  payload: courseUuid
});

const doDeleteCourseError = error => ({
  type: DELETE_COURSE_ERROR,
  payload: error
});

const doPutCourseLock = course => ({
  type: PUT_COURSE_LOCK,
  payload: {
    course
  }
});

const doPutCourseLockSuccess = result => ({
  type: PUT_COURSE_LOCK_SUCCESS,
  payload: result
});

const doPutCourseLockError = error => ({
  type: PUT_COURSE_LOCK_ERROR,
  payload: error
});

const doGetCoursesByStudent = userUuid => ({
  type: GET_COURSE_BY_STUDENT,
  payload: {
    userUuid
  }
});

const doGetCoursesByStudentSuccess = result => ({
  type: GET_COURSE_BY_STUDENT_SUCCESS,
  payload: result
});

const doGetCoursesByStudentError = error => ({
  type: GET_COURSE_BY_STUDENT_ERROR,
  payload: error
});

const doSetIsLoading = isLoading => ({
  type: SET_IS_LOADING_COURSE_EDIT,
  payload: {
    isLoading
  }
});

export {
  doGetCourse,
  doGetCourseSuccess,
  doGetCourseError,
  doGetCourses,
  doGetCoursesSuccess,
  doGetCoursesError,
  doGetCoursesByDeptAy,
  doGetCoursesByDeptAySuccess,
  doGetCoursesByDeptAyError,
  doPostCourse,
  doPostCourseSuccess,
  doPostCourseError,
  doPutCourse,
  doPutCourseSuccess,
  doPutCourseError,
  doDeleteCourse,
  doDeleteCourseSuccess,
  doDeleteCourseError,
  doPutCourseLock,
  doPutCourseLockSuccess,
  doPutCourseLockError,
  doGetCoursesByStudent,
  doGetCoursesByStudentSuccess,
  doGetCoursesByStudentError,
  doSetIsLoading
};
