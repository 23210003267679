import fetch from '../helpers/fetch';

const logoutUser = () =>
  fetch(`/api/logout`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { logoutUser };
