import { call, put, takeEvery } from 'redux-saga/effects';
import { postInstitutionRigor } from '../../api/institutionRigorApi';

import {
  doPostInstitutionRigorSuccess,
  doPostInstitutionRigorError
} from '../actions/institutionRigorActions';

import { POST_INSTITUTION_RIGOR } from '../actions-type';

function* handlePostInstitutionRigor(action) {
  try {
    const {
      cohorts,
      programUuid,
      cohortStartUuid,
      cohortEndUuid
    } = action.payload;

    const result = yield call(
      postInstitutionRigor,
      cohorts,
      programUuid,
      cohortStartUuid,
      cohortEndUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put({ type: 'CLEAR_RIGOR_REPORT', programUuid });
      yield put(doPostInstitutionRigorSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostInstitutionRigorError(error));
  }
}

const institutionRigor = [
  takeEvery(POST_INSTITUTION_RIGOR, handlePostInstitutionRigor)
];

export { institutionRigor };
