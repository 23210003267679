import React from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js-light';
import PartFacultyReportPanel from './PartTopicReportPanel';

const PartFacultyReportPanelContainer = ({
  assessments,
  assessmentTechniques,
  assessmentTypes,
  cumulativePotentialScore,
  isSummaryPanel,
  partTopicReportAssessmentData,
  partTopicTotalPotentialScore,
  partTopicTotalRelativeScore,
  partUuid,
  topic
}) => {
  const decimalPartTopicTotalPotentialScore = new Decimal(
    partTopicTotalPotentialScore
  );
  const decimalPartTopicTotalRelativeScore = new Decimal(
    partTopicTotalRelativeScore
  );
  const decimalCumulativeTotalPotentialScore = new Decimal(
    cumulativePotentialScore
  );
  const topicParticipationPercentage =
    decimalCumulativeTotalPotentialScore.isZero()
      ? '0.00'
      : decimalPartTopicTotalPotentialScore
          .dividedBy(decimalCumulativeTotalPotentialScore)
          .times(100)
          .toFixed(2);
  const topicRelativePercentage = decimalCumulativeTotalPotentialScore.isZero()
    ? '0.00'
    : decimalPartTopicTotalRelativeScore
        .dividedBy(decimalPartTopicTotalPotentialScore)
        .times(100)
        .toFixed(2);

  const topicLabel = topic === undefined ? 'All Topics' : topic.title;
  return decimalPartTopicTotalPotentialScore.greaterThan(0) ? (
    <PartFacultyReportPanel
      assessments={assessments}
      assessmentTechniques={assessmentTechniques}
      assessmentTypes={assessmentTypes}
      topicUuid={topic?.uuid}
      isSummaryPanel={isSummaryPanel}
      partTopicReportAssessmentData={partTopicReportAssessmentData}
      topicLabel={topicLabel}
      partTopicParticipationPercentage={topicParticipationPercentage}
      partTopicRelativePercentage={topicRelativePercentage}
      partTopicTotalPotentialScore={partTopicTotalPotentialScore}
      partTopicTotalRelativeScore={partTopicTotalRelativeScore}
      partUuid={partUuid}
    />
  ) : null;
};

PartFacultyReportPanelContainer.propTypes = {
  assessments: PropTypes.array,
  assessmentTechniques: PropTypes.array,
  assessmentTypes: PropTypes.array,
  isSummaryPanel: PropTypes.bool,
  cumulativePotentialScore: PropTypes.string,
  partTopicReportAssessmentData: PropTypes.object,
  partTopicTotalPotentialScore: PropTypes.string,
  partTopicTotalRelativeScore: PropTypes.string,
  partUuid: PropTypes.string,
  topic: PropTypes.object
};
PartFacultyReportPanelContainer.defaultProps = {
  assessments: [],
  assessmentTechniques: [],
  assessmentTypes: [],
  isSummaryPanel: false,
  cumulativePotentialScore: '0.00',
  partTopicReportAssessmentData: {},
  partTopicTotalPotentialScore: '0.00',
  partTopicTotalRelativeScore: '0.00',
  partUuid: '',
  topic: undefined
};

export default PartFacultyReportPanelContainer;
