import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { ButtonIcon } from '@xcomp/xcomp-design-library';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';

import {
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import {
  Column,
  TextFieldWithError,
  FormSectionHeaderSubtitle,
  FormSectionHeader
} from '../../../../Library/FormComponents';
import {
  validateDecimalStringField,
  validateSelectField
} from '../../../../../helpers/validation/validateGeneric';
import FormSelect from '../../../../Library/FormSelect';
import FormSelectError from '../../../../Library/FormSelectError';

const FormSectionHeaderSC = styled(FormSectionHeader)`
  position: relative;
`;

const CollectionAssessment = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 48px;
`;

const ColumnPaddingRight = styled(Column)`
  padding-right: 24px;
`;

const PartSelect = styled(FormSelect)`
  && {
    margin-top: 0;
    background-color: ${props => props.theme.colors.white};
    width: 100%;
  }
`;

const AssessmentSelect = styled(FormSelectError)`
  && {
    margin-top: 0;
    background-color: ${props => props.theme.colors.white};
    width: 100%;
  }
`;

const CheckboxContainer = styled.div`
  color: ${props => props.theme.fontColors.dark};
  margin-bottom: 0.5rem;
`;

const ButtonIconContainer = styled.div`
  position: absolute;
  top: -3px;
  right: 0;
  width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.white};
  z-index: 25;
`;

const CollectionAssessmentForm = ({
  index,
  uuid,
  partUuid,
  assessmentUuid,
  weight,
  required,
  distributeWeightEqually,
  partOptions,
  assessmentOptions,
  formDispatch,
  handlePartChange,
  setUnsavedChange,
  showValidationErrors,
  usedAssessmentUuids,
  isPartFixed
}) => {
  const handleWeightChange = e => {
    formDispatch({
      type: 'setAssessmentWeight',
      payload: {
        weight: e.target.value,
        collectionAssessmentUuid: uuid
      }
    });
    setUnsavedChange('collectionAssessments');
  };

  const handleRequiredChange = (e, required) => {
    formDispatch({
      type: 'setAssessmentRequired',
      payload: {
        required,
        collectionAssessmentUuid: uuid
      }
    });
    setUnsavedChange('collectionAssessments');
  };

  const handleAssessmentChange = event => {
    const assessmentUuid = event.target.value;
    formDispatch({
      type: 'setAssessmentUuid',
      payload: {
        assessmentUuid,
        collectionAssessmentUuid: uuid
      }
    });
    setUnsavedChange('collectionAssessments');
  };

  const handleDeleteClick = () => {
    formDispatch({
      type: 'deleteCollectionAssessment',
      payload: {
        collectionAssessmentUuid: uuid
      }
    });
    setUnsavedChange('collectionAssessments');
  };

  const validateWeightField = weight => validateDecimalStringField(weight);

  const thisAssessmentOptions = assessmentOptions.filter(
    assessment =>
      !usedAssessmentUuids[assessment.value] ||
      assessment.value === assessmentUuid
  );

  const assessmentNumber = index + 1;

  return (
    <>
      <Column size="100%">
        <FormSectionHeaderSC offset="0">
          <FormSectionHeaderSubtitle>
            Assessment {assessmentNumber}
          </FormSectionHeaderSubtitle>
          <ButtonIconContainer>
            <ButtonIcon onClick={handleDeleteClick} noHoverBg>
              <DeleteIcon />
            </ButtonIcon>
          </ButtonIconContainer>
        </FormSectionHeaderSC>
      </Column>
      <CollectionAssessment key={uuid}>
        <ColumnPaddingRight size="25%">
          {isPartFixed ? (
            <Tooltip
              title="Assessment must belong to this part"
              placement="bottom-start"
            >
              <div>
                <PartSelect
                  value={partUuid || ''}
                  options={partOptions}
                  handleChange={handlePartChange}
                  name={`collectionAssessmentPart-${assessmentNumber}`}
                  label="Part*"
                  disabled
                />
              </div>
            </Tooltip>
          ) : (
            <PartSelect
              value={partUuid || ''}
              options={partOptions}
              handleChange={handlePartChange}
              name={`collectionAssessmentPart-${assessmentNumber}`}
              label="Part*"
            />
          )}
        </ColumnPaddingRight>
        <ColumnPaddingRight size="60%">
          <AssessmentSelect
            id={`assessmentUuid-${uuid}`}
            name="assessmentUuid"
            value={assessmentUuid || ''}
            hasErrors={showValidationErrors}
            label="Assessment*"
            handleChange={handleAssessmentChange}
            handleValidation={validateSelectField}
            options={thisAssessmentOptions}
          />
        </ColumnPaddingRight>
        <Column size="15%">
          <TextFieldWithError
            id={`collectionWeight-${assessmentNumber}`}
            name="weight"
            value={weight}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            label="Weight*"
            variant="outlined"
            onChange={handleWeightChange}
            handleValidate={
              distributeWeightEqually
                ? () => ({
                    invalid: false
                  })
                : validateWeightField
            }
            hasErrors={showValidationErrors}
            disabled={Boolean(distributeWeightEqually)}
          />
        </Column>
        <Column size="100%">
          <FormControl component="fieldset">
            <FormGroup>
              <CheckboxContainer>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={`assessmentRequired-${uuid}`}
                      onChange={handleRequiredChange}
                      checked={required}
                      color="primary"
                    />
                  }
                  label="Required"
                />
              </CheckboxContainer>
            </FormGroup>
          </FormControl>
        </Column>
      </CollectionAssessment>
    </>
  );
};

CollectionAssessmentForm.propTypes = {
  index: PropTypes.number,
  uuid: PropTypes.string.isRequired,
  partUuid: PropTypes.string,
  assessmentUuid: PropTypes.string,
  weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  required: PropTypes.bool,
  distributeWeightEqually: PropTypes.bool,
  partOptions: PropTypes.arrayOf(PropTypes.object),
  assessmentOptions: PropTypes.arrayOf(PropTypes.object),
  usedAssessmentUuids: PropTypes.object,
  isPartFixed: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  formDispatch: PropTypes.func,
  handlePartChange: PropTypes.func,
  setUnsavedChange: PropTypes.func
};

CollectionAssessmentForm.defaultProps = {
  index: 0,
  partUuid: '',
  assessmentUuid: '',
  weight: 100,
  required: false,
  distributeWeightEqually: false,
  partOptions: [{ label: 'Select One', value: '' }],
  assessmentOptions: [{ label: 'Select One', value: '' }],
  usedAssessmentUuids: {},
  isPartFixed: false,
  showValidationErrors: false,
  formDispatch: undefined,
  handlePartChange: undefined,
  setUnsavedChange: undefined
};

export default CollectionAssessmentForm;
