import fetch from '../helpers/fetch';

const getClassifications = programUuid =>
  fetch(`/api/program-transcript/classifications/${programUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getAptitudes = (programUuid, category) =>
  fetch(
    `/api/program-transcript/classifications/${programUuid}/category/${category}/aptitudes`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getSubjects = programUuid =>
  fetch(`/api/program-transcript/subjects/${programUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postSubjects = (programUuid, subjects, subjectsRemove) =>
  fetch(`/api/program-transcript/subjects/${programUuid}/save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ subjects, subjectsRemove })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postMilestones = (milestones, conversionsRemove) =>
  fetch(`/api/program-transcript/milestones/save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ milestones, conversionsRemove })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postAptitudes = (
  programUuid,
  classifications,
  classificationsRemove,
  examAptitudesRemove,
  conversionAptitudesRemove
) =>
  fetch(`/api/program-transcript/exams-aptitude/${programUuid}/save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      classifications,
      classificationsRemove,
      examAptitudesRemove,
      conversionAptitudesRemove
    })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getAptitudes,
  getClassifications,
  getSubjects,
  postAptitudes,
  postMilestones,
  postSubjects
};
