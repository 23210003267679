import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { programDegreeCodeSelector } from '../../../redux/selectors/degreeCodeSelector';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 14px;
`;

const Value = styled.div`
  padding-top: 15px;
`;

export default function ProgramDegreeCode({
  programCip,
  programDiscipline,
  programCode
}) {
  const programDegreeCode = useSelector(
    state =>
      programDegreeCodeSelector(
        state,
        programCip,
        programDiscipline,
        programCode
      ),
    _.isEqual
  );

  return (
    <Layout>
      <Label>Degree Code</Label>
      <Value>{programDegreeCode}</Value>
    </Layout>
  );
}

ProgramDegreeCode.propTypes = {
  programCip: PropTypes.string,
  programDiscipline: PropTypes.string,
  programCode: PropTypes.string
};
ProgramDegreeCode.defaultProps = {
  programCip: '',
  programDiscipline: '',
  programCode: ''
};
