import React from 'react';
import PropTypes from 'prop-types';
import { PointsTd, TableDataText } from '../CollectionReportSC';

const getDisplayedScore = (competencyGridMap, studentUuid, competencyUuid) => {
  const key = `${studentUuid}:${competencyUuid}`;
  if (!competencyGridMap || !competencyGridMap[key]) {
    return '--';
  }

  const { totalScore } = competencyGridMap[key];

  return totalScore?.slice(0, -1) || '--';
};

const getStdDeviationRange = stdDeviationRange => {
  let range = 'c';

  if (stdDeviationRange < -3) {
    range = 'a';
  } else if (stdDeviationRange >= -3 && stdDeviationRange < -1) {
    range = 'b';
  } else if (stdDeviationRange >= -1 && stdDeviationRange < 2) {
    range = 'c';
  } else if (stdDeviationRange >= 2 && stdDeviationRange < 4) {
    range = 'd';
  } else if (stdDeviationRange >= 4) {
    range = 'e';
  }

  return range;
};

const StudentAssessmentGradeData = ({
  competency,
  competencyGridMap,
  dropAssessment,
  evenStudent,
  isExpanded,
  studentUuid,
  showScores,
}) => {
  const displayedScore = getDisplayedScore(
    competencyGridMap,
    studentUuid,
    competency.uuid
  );

  const thisCompetencyObj =
    competencyGridMap[`${studentUuid}:${competency.uuid}`];

  const thisStdDecviationRange = getStdDeviationRange(
    thisCompetencyObj?.standard_deviation_range
  );

  const bgColor =
    displayedScore && displayedScore !== '--'
      ? thisStdDecviationRange
      : undefined;

  return (
    <PointsTd
      key={competency.uuid}
      greyOne={!evenStudent}
      bgColor={bgColor}
      isExpanded={isExpanded}
      isCompact={!showScores}
    >
      <TableDataText strikeThrough={dropAssessment} fontColor={bgColor} isCompact={!showScores}>
        {displayedScore || '--'}
      </TableDataText>
    </PointsTd>
  );
};

StudentAssessmentGradeData.propTypes = {
  competency: PropTypes.object,
  competencyGridMap: PropTypes.object,
  dropAssessment: PropTypes.bool,
  evenStudent: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  studentUuid: PropTypes.string.isRequired,
  showScores: PropTypes.bool.isRequired
};

StudentAssessmentGradeData.defaultProps = {
  competency: { uuid: '' },
  competencyGridMap: {},
  dropAssessment: false
};

export default StudentAssessmentGradeData;
