import React, { useEffect, useState } from 'react';
import Decimal from 'decimal.js-light';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';
import _ from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { selectCourseCollection } from '../../../../redux/selectors/courseCollectionsSelectors';

const ChipSC = styled(Chip)`
  && {
    margin-left: 10px;
  }
`;

export default function CompletionRate({
  collectionUuid,
  completed,
  enrolled,
  remaining
}) {
  const [percentage, setPercentage] = useState('0');

  const courseCollection = useSelector(
    state => selectCourseCollection(state, collectionUuid),
    shallowEqual
  );

  useEffect(() => {
    if (
      courseCollection?.all_credits_required &&
      completed &&
      enrolled &&
      remaining
    ) {
      const completed_total = _.reduce(
        completed,
        (total, record) => {
          const creditDecimal = new Decimal(record?.courseMaster.hours);
          return total.add(creditDecimal);
        },
        new Decimal(0)
      );

      const enrolled_total = _.reduce(
        enrolled,
        (total, record) => {
          const creditDecimal = new Decimal(record?.courseMaster.hours);
          return total.add(creditDecimal);
        },
        new Decimal(0)
      );

      const remaining_total = _.reduce(
        remaining,
        (total, record) => {
          const creditDecimal = new Decimal(record?.hours);
          return total.add(creditDecimal);
        },
        new Decimal(0)
      );

      const outstanding = enrolled_total
        .add(remaining_total)
        .add(completed_total);

      if (courseCollection?.all_credits_required) {
        const rate = completed_total
          .div(outstanding)
          .times(100)
          .toFixed(2)
          .toString();

        setPercentage(rate);
      } else {
        const credits_required = courseCollection?.credits_required;

        const creditsRequiredDecimal = new Decimal(credits_required);

        const rate = completed_total
          .div(creditsRequiredDecimal)
          .times(100)
          .toFixed(2)
          .toString();

        setPercentage(rate);
      }
    }
  }, [
    courseCollection.all_credits_required,
    courseCollection?.credits_required,
    completed,
    enrolled,
    remaining
  ]);

  return (
    <>
      <ChipSC color="primary" size="small" label={`${percentage}%`} />
    </>
  );
}

CompletionRate.propTypes = {
  collectionUuid: PropTypes.string,
  completed: PropTypes.array,
  enrolled: PropTypes.array,
  remaining: PropTypes.array
};

CompletionRate.defaultProps = {
  collectionUuid: undefined,
  completed: undefined,
  enrolled: undefined,
  remaining: undefined
};
