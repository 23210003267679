import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const selectThirdPartyId = createSelector(
  state => state,
  (_, userUuid, programUuid) => ({ userUuid, programUuid }),
  (redux, parameters) => {
    const { userUuid, programUuid } = parameters;

    const getThirdPartyId = ormCreateSelector(orm.ThirdPartyId);
    const thirdPartyIds = getThirdPartyId(redux);

    const thirdParty = _.find(thirdPartyIds, { programUuid, userUuid });

    return thirdParty;
  }
);
