import { Model, attr, fk } from 'redux-orm';
import _ from 'lodash';
import {
  GET_ASSESSMENT_GRADES_SUCCESS,
  GET_COHORT_ASSESSMENT_SCORES_SUCCESS,
  GET_SCORES_SUCCESS,
  POST_SCORE_SUCCESS,
  PUT_SCORE_SUCCESS
} from '../actions-type';

class Subtopic extends Model {
  static reducer(action, Subtopic, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_COHORT_ASSESSMENT_SCORES_SUCCESS:
      case GET_SCORES_SUCCESS: {
        const subtopics = [...payload.subtopicsORM];
        subtopics.forEach(sub => Subtopic.upsert(sub));
        break;
      }
      case POST_SCORE_SUCCESS: {
        const { microcompetency } = payload;
        const add = _.omit(microcompetency.subtopic, ['topic']);
        Subtopic.upsert(add);
        break;
      }
      case PUT_SCORE_SUCCESS: {
        const { microcompetency } = payload;
        const add = _.omit(microcompetency.subtopic, ['topic']);
        Subtopic.upsert(add);
        break;
      }
      case GET_ASSESSMENT_GRADES_SUCCESS: {
        const subtopics = [...payload.subtopicsORM];
        subtopics.forEach(sub => Subtopic.upsert(sub));
        break;
      }
      default:
        return session;
    }
  }
}

Subtopic.fields = {
  uuid: attr(),
  title: attr(),
  code: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  topicUuid: fk('Topic', 'subtopics')
};

Subtopic.modelName = 'Subtopic';

Subtopic.options = {
  idAttribute: 'uuid'
};

export default Subtopic;
