import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectSubjectGrade } from '../../../../redux/selectors/transcriptCognitiveSelectors';

export default function SubjectGrade({
  programUuid,
  userUuid,
  cohortUuid,
  classificationUuid
}) {
  const cognitiveGrade = useSelector(state =>
    selectSubjectGrade(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      classificationUuid
    )
  );

  const grade = cognitiveGrade || 'N/A';

  return <>{grade}</>;
}

SubjectGrade.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  classificationUuid: PropTypes.string
};

SubjectGrade.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  classificationUuid: undefined
};
