import { call, put, takeEvery } from 'redux-saga/effects';
import { getRigorHistoricalReport } from '../../api/rigorHistoricalReportApi';

import {
  doGetRigorHistoricalReportSuccess,
  doGetRigorHistoricalReportError
} from '../actions/rigorHistoricalReportActions';

import { GET_RIGOR_HISTORICAL_REPORT } from '../actions-type';

function* handleGetRigorHistoricalReport(actions) {
  const { programUuid } = actions.payload;

  try {
    const result = yield call(getRigorHistoricalReport, programUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetRigorHistoricalReportSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetRigorHistoricalReportError(error));
  }
}

const rigorHistoricalReport = [
  takeEvery(GET_RIGOR_HISTORICAL_REPORT, handleGetRigorHistoricalReport)
];

export { rigorHistoricalReport };
