import fetch from '../helpers/fetch';

const getScores = (assessmentUuid, sectionUuid, partUuid) =>
  fetch(
    `/api/scores?sectionUuid=${sectionUuid}&partUuid=${partUuid}&assessmentUuid=${assessmentUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getUploadScoresStatus = assessmentUuid =>
  fetch(`/api/scores/upload/${assessmentUuid}/status`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postUploadScores = (
  assessmentUuid,
  records,
  uploadDate,
  isCohortAssessment,
  isDelayedStudent
) =>
  fetch(`/api/scores/upload`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      assessmentUuid,
      records,
      uploadDate,
      isCohortAssessment,
      isDelayedStudentScores: isDelayedStudent
    })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putScore = score =>
  fetch(`/api/score/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(score)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postScore = score =>
  fetch(`/api/score/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(score)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteScore = (scoreUuid, resultCardUuid, assessmentUuid) =>
  fetch(`/api/score/${scoreUuid}/delete?resultCardUuid=${resultCardUuid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ assessmentUuid })
  })
    .then(res => res)
    .catch(error => error);

const deleteScores = assessmentUuid =>
  fetch(`/api/scores/delete/all?assessmentUuid=${assessmentUuid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  deleteScore,
  deleteScores,
  getScores,
  getUploadScoresStatus,
  postScore,
  postUploadScores,
  putScore
};
