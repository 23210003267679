import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectAptitudeExamRank } from '../../../../redux/selectors/applicantRankSelectors';

export default function RankAptitudeExam({
  userUuid,
  cohortUuid,
  category,
  source,
  classificationUuid
}) {
  const categoryRank = useSelector(state =>
    selectAptitudeExamRank(
      state,
      userUuid,
      cohortUuid,
      category,
      source,
      classificationUuid
    )
  );

  const rank =
    categoryRank?.cohortRankRelative && categoryRank?.cohortRankPotential
      ? `${categoryRank?.cohortRankRelative} of ${categoryRank?.cohortRankPotential}`
      : 'N/A';

  return <>{rank}</>;
}

RankAptitudeExam.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  category: PropTypes.string,
  source: PropTypes.string,
  classificationUuid: PropTypes.string
};

RankAptitudeExam.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  category: undefined,
  source: undefined,
  classificationUuid: undefined
};
