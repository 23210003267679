import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const institutionAAsSelector = createSelector(
  state => state,
  redux => {
    const accreditationAgenciesSelectorORM = ormCreateSelector(
      orm.AccreditationAgency
    );
    const accreditationAgencies = accreditationAgenciesSelectorORM(redux);

    const accreditationAgenciesSorted = _.sortBy(accreditationAgencies, [
      'degreeLevel'
    ]);

    return accreditationAgenciesSorted;
  }
);

export const programAAsSelector = createSelector(
  state => state,
  redux => {
    const accreditationAgenciesSelectorORM = ormCreateSelector(
      orm.AccreditationAgency
    );
    const accreditationAgencies = accreditationAgenciesSelectorORM(
      redux
    ).filter(agency => agency.agencyType === 'PROGRAM');

    const accreditationAgenciesSorted = _.sortBy(accreditationAgencies, [
      'name'
    ]);

    return accreditationAgenciesSorted;
  }
);
