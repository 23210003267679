import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import FormSelectError from '../../Library/FormSelectError';
import { validateCourseAliasCourseMaster } from '../../../helpers/validation/validateCourseMaster';
import { validateSelectField } from '../../../helpers/validation/validateGeneric';
import { doGetCoursesMasterByDepartment } from '../../../redux/actions/courseMasterActions';
import { courseMastersByDepartmentSelector } from '../../../redux/selectors/coursesMasterSelectors';
import { formatOptions } from '../../../helpers/utilities';

const DeleteIconSC = styled(DeleteIcon)`
  && {
    margin-top: 16px
    margin-bottom: 8px;
  }
`;

export default function CourseMasterAddAlias({
  alias,
  courseAlias,
  departmentOptions,
  hasErrors,
  handleCourseAliasChange,
  handleCourseAliasUuidChange,
  handleRemoveCourseAlias
}) {
  const dispatch = useDispatch();

  const courseMasters = useSelector(
    state =>
      courseMastersByDepartmentSelector(state, courseAlias.departmentUuid),
    _.isEqual
  );

  const courseMastersOptions = formatOptions(courseMasters, 'title');

  useEffect(() => {
    if (courseAlias.departmentUuid && courseAlias.departmentUuid !== '') {
      dispatch(doGetCoursesMasterByDepartment(courseAlias.departmentUuid));
    }
  }, [dispatch, courseAlias.departmentUuid]);

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={4}
    >
      <Grid item xs={4}>
        <FormSelectError
          name="courseAliasDepartmentUuid"
          label="Department"
          value={courseAlias.departmentUuid}
          handleChange={event =>
            handleCourseAliasChange(courseAlias.indentifier, event)
          }
          handleValidation={validateSelectField}
          hasErrors={hasErrors}
          options={departmentOptions}
        />
      </Grid>
      <Grid item xs={4}>
        <FormSelectError
          name="courseMasterAliasUuid"
          label="Course Alias*"
          value={courseAlias.courseMasterAliasUuid}
          handleChange={event =>
            handleCourseAliasUuidChange(courseAlias.indentifier, event)
          }
          handleValidation={selected =>
            validateCourseAliasCourseMaster(selected, alias)
          }
          hasErrors={hasErrors}
          options={courseMastersOptions}
        />
      </Grid>
      <Grid item xs={1}>
        <DeleteIconSC
          onClick={() => handleRemoveCourseAlias(courseAlias.indentifier)}
        />
      </Grid>
    </Grid>
  );
}

CourseMasterAddAlias.propTypes = {
  alias: PropTypes.array,
  courseAlias: PropTypes.object,
  departmentOptions: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleCourseAliasChange: PropTypes.func,
  handleCourseAliasUuidChange: PropTypes.func,
  handleRemoveCourseAlias: PropTypes.func
};

CourseMasterAddAlias.defaultProps = {
  alias: [],
  courseAlias: {},
  departmentOptions: [],
  hasErrors: false,
  handleCourseAliasUuidChange: undefined,
  handleCourseAliasChange: undefined,
  handleRemoveCourseAlias: undefined
};
