import React from 'react';
// import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styled from 'styled-components';
// import { selectAssesmentBlocksCount } from '../../../redux/selectors/assessmentSelectors';

const Input = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default function AssesmentBlocks({ blockNumber }) {
  // const numberOfBlock = useSelector(state =>
  //   selectAssesmentBlocksCount(state, assessmentUuid)
  // );

  return (
    <Input>
      {blockNumber}
      {/* of {`${numberOfBlock || 0}`} */}
    </Input>
  );
}

AssesmentBlocks.propTypes = {
  // assessmentUuid: PropTypes.string,
  blockNumber: PropTypes.number
};

AssesmentBlocks.defaultProps = {
  // assessmentUuid: undefined,
  blockNumber: 0
};
