import { Model, attr, fk } from 'redux-orm';

import {
  GET_LOVERBS_SUCCESS,
  GET_EDUCATION_LEVEL_SUCCESS,
  GET_LOVERBS_BY_EDUCATION_LEVELS_SUCCESS,
  POST_EDUCATION_LEVEL_SUCCESS,
  PUT_EDUCATION_LEVEL_SUCCESS,
  DELETE_EDUCATION_LEVEL_SUCCESS
} from '../actions-type';

class Loverb extends Model {
  static reducer(action, Loverb, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_LOVERBS_SUCCESS: {
        const { educationLevels } = payload;

        educationLevels.forEach(educationLevel => {
          educationLevel.loverbs.forEach(loverb => {
            Loverb.upsert(loverb);
          });
        });

        break;
      }
      case GET_LOVERBS_BY_EDUCATION_LEVELS_SUCCESS: {
        const allLoverbs = [...payload.loverbs];
        allLoverbs.forEach(loverb => Loverb.upsert(loverb));
        break;
      }
      case GET_EDUCATION_LEVEL_SUCCESS: {
        const { loverbs } = payload.educationLevel;
        loverbs.forEach(loverb => Loverb.upsert(loverb));
        break;
      }
      case POST_EDUCATION_LEVEL_SUCCESS: {
        const allLoverbs = [...payload.loverbs];
        allLoverbs.forEach(loverb => Loverb.upsert(loverb));
        break;
      }

      case PUT_EDUCATION_LEVEL_SUCCESS: {
        const deleteLoverbs = [...payload.loverbsRemoval];
        const allLoverbs = [...payload.educationLevel.loverbs];

        deleteLoverbs.forEach(loverb => Loverb.withId(loverb.uuid).delete());
        allLoverbs.forEach(loverb => Loverb.upsert(loverb));
        break;
      }
      case DELETE_EDUCATION_LEVEL_SUCCESS: {
        const { educationLevelUuid } = payload;

        Loverb.all()
          .filter(loverb => loverb.educationLevelUuid === educationLevelUuid)
          .delete();

        break;
      }
      default:
        return session;
    }
  }
}

Loverb.fields = {
  uuid: attr(),
  objectiveVerb: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  educationLevelUuid: fk('EducationLevel', 'loverbs')
};

Loverb.modelName = 'Loverb';
Loverb.options = {
  idAttribute: 'uuid'
};

export default Loverb;
