import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getAptitudes,
  getClassifications,
  getSubjects,
  postAptitudes,
  postMilestones,
  postSubjects
} from '../../api/classificationApi';

import {
  doGetClassificationsSuccess,
  doGetClassificationsError,
  doGetSubjectsSuccess,
  doGetSubjectsError,
  doPostSubjectsSuccess,
  doPostSubjectsError,
  doPostMilestoneExamsSuccess,
  doPostMilestoneExamsError,
  doGetAptitudesSuccess,
  doGetAptitudesError,
  doPostAptitudesSuccess,
  doPostAptitudesError
} from '../actions/classificationActions';

import {
  GET_CLASSIFICATIONS,
  GET_SUBJECTS,
  POST_SUBJECTS,
  POST_MILESTONES,
  GET_APTITUDES_BY_CATEGORY,
  POST_APTITUDES
} from '../actions-type';

function* handleGetClassifications(action) {
  const programUuid = action.payload;
  try {
    const result = yield call(getClassifications, programUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetClassificationsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetClassificationsError(error));
  }
}

function* handleGetAptitudes(action) {
  const { programUuid, category } = action.payload;
  try {
    const result = yield call(getAptitudes, programUuid, category);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetAptitudesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetAptitudesError(error));
  }
}

function* handleGetSubjects(action) {
  const programUuid = action.payload;
  try {
    const result = yield call(getSubjects, programUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetSubjectsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSubjectsError(error));
  }
}

function* handlePostSubjects(action) {
  const { programUuid, subjects, subjectsRemove } = action.payload;

  try {
    const result = yield call(
      postSubjects,
      programUuid,
      subjects,
      subjectsRemove
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostSubjectsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostSubjectsError(error));
  }
}

function* handlePostMilestones(action) {
  const { milestones, conversionsRemove } = action.payload;

  try {
    const result = yield call(postMilestones, milestones, conversionsRemove);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostMilestoneExamsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostMilestoneExamsError(error));
  }
}

function* handlePostAptitudes(action) {
  const {
    programUuid,
    classifciations,
    classificationsRemove,
    examAptitudesRemove,
    conversionAptitudesRemove
  } = action.payload;

  try {
    const result = yield call(
      postAptitudes,
      programUuid,
      classifciations,
      classificationsRemove,
      examAptitudesRemove,
      conversionAptitudesRemove
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostAptitudesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostAptitudesError(error));
  }
}

const classification = [
  takeEvery(GET_CLASSIFICATIONS, handleGetClassifications),
  takeEvery(GET_SUBJECTS, handleGetSubjects),
  takeEvery(POST_SUBJECTS, handlePostSubjects),
  takeEvery(POST_MILESTONES, handlePostMilestones),
  takeEvery(GET_APTITUDES_BY_CATEGORY, handleGetAptitudes),
  takeEvery(POST_APTITUDES, handlePostAptitudes)
];

export { classification };
