import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import useSWRMutation from 'swr/mutation';

import Header from '../../Library/Header';
import { formatYYYY } from '../../../helpers/date-fns.utils';
import ContainerResize, { ContainerPage } from '../../Library/Layout';
import { cohortSelectorByMatchParams } from '../../../redux/selectors/cohortsSelectors';
import {
  doGetAssessmentTechniques,
  doGetAssessmentType
} from '../../../redux/actions/assessmentActions';
import { doGetCohort } from '../../../redux/actions/cohortActions';
import {
  qualitativeAssessmentTechniquesSelector,
  reportAssessmentTypesSelector
} from '../../../redux/selectors/assessmentFieldSelectors';
import Filters from './components/Filters';
import { doGetUserCohorts } from '../../../redux/actions/userCohortActions';
import { cohortStudentsSelector } from '../../../redux/selectors/userCohortsSelectors';
import {
  ErrorWrapper,
  NoDataContainer,
  ProgressBar,
  SelfAssessmentAccordion
} from '../SelfAssessmentReportComponents';
import { formatFullName } from '../../../helpers/format/user.format';
import QualitativeReportContainer from './QualitativeReportContainer';
import ColumnOne from '../../CohortManagement/CohortStudentTranscripts/AttemptsReport/ColumnOne';
import AccordionRowContainer from '../../Library/AccordionRow/AccordionRowContainer';
import { Panel } from '../../Library/DashboardSC';
import { fetcher } from '../../../helpers/utilities';
import PageIllustration from '../../Library/PageIllustration';
import { roles, studentStatus } from '../../../helpers/constants';
import DidacticColumnOne from '../../CohortManagement/CohortStudentTranscripts/DidacticReport/DidacticColumnOne';

const Accordion = styled(AccordionRowContainer)`
  .accordion_row_label {
    display: flex;
    flex: ${({ isTablet }) => (!isTablet ? 1 : 'unset')};
  }

  & > div:hover > .hover-effect {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  }

  & > div > .hover-effect {
    background: ${props => props.theme.colors.primary};
  }

  & > div > .hover-effect > div > div > div {
    color: ${props => props.theme.colors.white};
  }

  .hover-effect > div:nth-child(2) {
    flex-direction: ${({ isTablet }) => (isTablet ? 'column' : 'row')};
    justify-content: ${({ isTablet }) =>
      isTablet ? 'space-around' : 'flex-start'};
    align-items: ${({ isTablet }) => (isTablet ? 'flex-start' : 'center')};
  }

  .column_one {
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }

  .column_one > span {
    width: 100%;
  }
`;

const PanelSC = styled(Panel)`
  margin-top: 50px;
`;

const CohortQualitativeSkillsAndDocumentsContainer = ({ isDidactic }) => {
  const [isAnonymized, setIsAnonymized] = useState(true);
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);

  const [selectedAssessmentType, setSelectedAssessmentType] = useState([]);
  const [selectedAssessmentTechnique, setSelectedAssessmentTechnique] =
    useState([]);

  const { cohortUuid } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const cohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    isEqual
  );

  const assessmentTypes = useSelector(
    state => reportAssessmentTypesSelector(state, isDidactic),
    isEqual
  );

  const assessmentTechniques = useSelector(
    state => qualitativeAssessmentTechniquesSelector(state),
    isEqual
  );

  const students = useSelector(
    state => cohortStudentsSelector(state, cohortUuid),
    isEqual
  );

  const types = useMemo(
    () =>
      !selectedAssessmentType || selectedAssessmentType.includes('all')
        ? assessmentTypes.map(at => at.uuid)
        : selectedAssessmentType,
    [selectedAssessmentType, assessmentTypes]
  );

  const techniques = useMemo(
    () =>
      !selectedAssessmentTechnique ||
      selectedAssessmentTechnique.includes('all')
        ? assessmentTechniques.map(at => at.uuid)
        : selectedAssessmentTechnique,
    [selectedAssessmentTechnique, assessmentTechniques]
  );

  const urlParams = new URLSearchParams({
    assessmentTechnique: techniques,
    assessmentType: types,
    students: students.map(student => student.uuid),
    cohortUuid,
    isDidactic,
  });

  const shouldFetch =
    types.length > 0 && techniques.length > 0 && students.length > 0;

  const { trigger, data, isMutating, error } = useSWRMutation(
    shouldFetch
      ? `/api/cohort/${cohortUuid}/cohort-qualitative-skills-and-document/students-summary?${urlParams.toString()}`
      : null,
    fetcher,
    { throwOnError: false }
  );

  useEffect(() => {
    if (assessmentTypes.length > 0)
      setSelectedAssessmentType(assessmentTypes.map(at => at.uuid));

    if (assessmentTechniques.length > 0)
      setSelectedAssessmentTechnique(assessmentTechniques.map(at => at.uuid));
  }, [assessmentTechniques, assessmentTypes]);

  useEffect(() => {
    if (cohortUuid) {
      dispatch(doGetAssessmentType());
      dispatch(doGetAssessmentTechniques());
      dispatch(doGetCohort(cohortUuid));
      dispatch(
        doGetUserCohorts(cohortUuid, studentStatus.active, roles.student)
      );
    }
  }, [dispatch, cohortUuid]);

  const handleBackAction = useCallback(
    () => history.push(`/reports`),
    [history]
  );

  const handleApplyFilters = useCallback(() => {
    setHasAppliedFilters(true);
    trigger();
  }, [trigger]);

  const sortedStudents = students.sort(
    (a, b) => data?.report[a.uuid]?.rank - data?.report[b.uuid]?.rank
  );

  return (
    <ContainerResize>
      <Header
        title={isDidactic ? "Cohort Reports - Basic and Applied Didactic" : "Cohort Reports - Qualitative Skills and Documents"}
        subtitle={
          cohort &&
          `cohort ${formatYYYY(cohort.startDate)} - ${formatYYYY(
            cohort.endDate
          )}`
        }
        backButtonOverride={handleBackAction}
      />
      <ContainerPage paddingTop="100px">
        <Filters
          assessmentTypes={assessmentTypes.map(at => ({
            value: at.uuid,
            label: at.name
          }))}
          assessmentTechniques={assessmentTechniques.map(at => ({
            value: at.uuid,
            label: at.name
          }))}
          selectedAssessmentType={selectedAssessmentType}
          selectedAssessmentTechnique={selectedAssessmentTechnique}
          setSelectedAssessmentType={setSelectedAssessmentType}
          setSelectedAssessmentTechnique={setSelectedAssessmentTechnique}
          fetchReportData={handleApplyFilters}
          isDidactic={isDidactic}
        />

        {
          // eslint-disable-next-line no-nested-ternary
          isMutating ? (
            <PanelSC>
              <ProgressBar>Loading</ProgressBar>
              <LinearProgress />
            </PanelSC>
          ) : error ? (
            <ErrorWrapper>
              <PageIllustration
                illustrationType="notFound"
                infoText="An error occured when trying to retrieve data."
              />
            </ErrorWrapper>
          ) : (
            <PanelSC>
              {hasAppliedFilters && !data?.report && (
                <NoDataContainer>
                  No data for the selected filters. Change filter selection and
                  re-apply.
                </NoDataContainer>
              )}
              {!hasAppliedFilters && !data && (
                <NoDataContainer>
                  Apply the filter selection to view the report.
                </NoDataContainer>
              )}
              {data && data.report && (
                <Accordion
                  withBottomBorder={true}
                  expandWidth="66px"
                  height="90px"
                  columnTwo={isDidactic ? <DidacticColumnOne data={data} displayHeader whiteText /> : <ColumnOne data={data} displayHeader whiteText />}
                  label={
                    <div>
                      <Switch
                        size="small"
                        checked={isAnonymized}
                        onClick={() => setIsAnonymized(!isAnonymized)}
                      />{' '}
                      Student{' '}
                      {isAnonymized
                        ? '(Institution ID)'
                        : '(Last name, First name)'}
                    </div>
                  }
                  leaf
                />
              )}

              {data &&
                data.report &&
                sortedStudents.map(student => (
                  <SelfAssessmentAccordion
                    key={student.uuid}
                    expandWidth="66px"
                    height="40px"
                    isStatisticStudent={false}
                    label={
                      <div>
                        {isAnonymized
                          ? student.institutionId
                          : formatFullName(student)}
                      </div>
                    }
                    $level="student"
                    // eslint-disable-next-line
                    columnOne={isMutating 
                      ?  <CircularProgress />
                      : isDidactic 
                        ? <DidacticColumnOne data={data?.report[student.uuid]} />
                        : <ColumnOne data={data?.report[student.uuid]} />
                    }
                  >
                    <QualitativeReportContainer
                      student={student}
                      selectedAssessmentTechnique={techniques}
                      selectedAssessmentType={types}
                      isDidactic={isDidactic}
                    />
                  </SelfAssessmentAccordion>
                ))}
            </PanelSC>
          )
        }
      </ContainerPage>
    </ContainerResize>
  );
};

CohortQualitativeSkillsAndDocumentsContainer.propTypes = {
  isDidactic: PropTypes.bool,
};

CohortQualitativeSkillsAndDocumentsContainer.defaultProps = {
  isDidactic: false,
};

export default CohortQualitativeSkillsAndDocumentsContainer;
