import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import CognitiveSubjectRowHeader from '../DataRows/CognitiveSubjectRowHeader';
import CognitiveSubjectRowRead from '../DataRows/CognitiveSubjectRowRead';

export default function ByScopeCognitiveTranscripts({ transcripts }) {
  return (
    <>
      {transcripts?.length > 0 && <CognitiveSubjectRowHeader />}
      {transcripts?.length > 0 &&
        _.sortBy(transcripts, ['date', 'itemCode']).map(transcript => (
          <CognitiveSubjectRowRead
            key={transcript?.uuid}
            transcript={transcript}
          />
        ))}
    </>
  );
}

ByScopeCognitiveTranscripts.propTypes = {
  transcripts: PropTypes.array
};

ByScopeCognitiveTranscripts.defaultProps = {
  transcripts: []
};
