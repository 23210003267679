import React, {useCallback} from 'react';
import {useAtomValue, useSetAtom} from 'jotai';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  searchProcedureCodeAtom,
  searchProcedureCodeFetchingAtom,
} from '../state/editState';

const AutocompleteInput = ({params}) => {
  const setSearchTerm = useSetAtom(searchProcedureCodeAtom);
  const loading = useAtomValue(searchProcedureCodeFetchingAtom);

  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, [setSearchTerm]);

  return (
    <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
      <TextField
        {...params}
        autoFocus
        label="Search by code or description"
        margin="normal"
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          type: 'search',
          startAdornment: loading ? (
            <CircularProgress
              size="24px"
            />
          ) : (
            <div
              style={{width: '24px'}}
            />
          ),
        }}
        onChange={handleSearch}
      />
    </div>
  );
};

AutocompleteInput.propTypes = {
  params: PropTypes.object.isRequired,
};

export default AutocompleteInput;