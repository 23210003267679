import React from 'react';
import PropTypes from 'prop-types';
import { LabelIcon } from '@xcomp/xcomp-design-library/dist/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import {
  applicantStatus,
  applicantStatusMap
} from '../../../helpers/constants';

const Instruction = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

export default function CohortApplicantStatusMenu({ handleSelectedStatus }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuSelection = status => {
    handleSelectedStatus(status);
  };

  return (
    <>
      <LabelIcon
        aria-controls="applicant-status-menu"
        aria-haspopup="true"
        onClick={handleClick}
      />
      <Menu
        id="applicant-status-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Instruction>Change status roles to: </Instruction>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(applicantStatus.active);
          }}
        >
          {applicantStatusMap.active}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(applicantStatus.complete);
          }}
        >
          {applicantStatusMap.complete}
        </MenuItem>
      </Menu>
    </>
  );
}

CohortApplicantStatusMenu.propTypes = {
  handleSelectedStatus: PropTypes.func
};
CohortApplicantStatusMenu.defaultProps = {
  handleSelectedStatus: undefined
};
