import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const selectDegreeLevelIndex = ormCreateSelector(orm.DegreeLevelIndex);

export const selectDegreeLevelIndices = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const getDegreeLevelIndex = ormCreateSelector(orm.DegreeLevelIndex);
    const getDegreeLevel = ormCreateSelector(orm.DegreeLevel);

    if (programUuid) {
      const degreeLevelIndices = getDegreeLevelIndex(redux)
        .filter(dl => dl.programUuid === programUuid)
        .map(dl => {
          const degreeLevel = getDegreeLevel(redux, dl.sourceDegreeLevelUuid);

          return {
            ...dl,
            degreeLevel
          };
        });

      const degreeLevelsSorted = _.sortBy(degreeLevelIndices, [
        'degreeLevel.degreeLevel'
      ]);

      return degreeLevelsSorted;
    } else {
      return [];
    }
  }
);
