import { Model, attr, fk } from 'redux-orm';
import _ from 'lodash';
import {
  GET_USER_ACTIVE_SUCCESS,
  GET_USER_SUCCESS,
  GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS,
  POST_USER_ROLE_SUCCESS,
  POST_USER_SUCCESS,
  PUT_USER_ROLES_SUCCESS,
  GET_USERS_BY_SEARCH_ROLES_SUCCESS
} from '../actions-type';

class UserInstitution extends Model {
  static reducer(action, UserInstitution, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_USER_ACTIVE_SUCCESS: {
        if (payload?.roles?.institution_admin?.length > 0) {
          const userInstitutions = [...payload.roles.institution_admin];
          userInstitutions.forEach(userInstitution => {
            const add = _.omit(userInstitution, ['institution']);
            UserInstitution.upsert(add);
          });
        }
        break;
      }
      case GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS: {
        const usersInsitution = [...payload.usersInstitution];
        usersInsitution.forEach(userInstitution =>
          UserInstitution.upsert(userInstitution)
        );
        break;
      }
      case POST_USER_SUCCESS: {
        const userInstitutions = [...payload.userInstitutions];
        userInstitutions.forEach(userInstitution =>
          UserInstitution.create(userInstitution)
        );
        break;
      }
      case PUT_USER_ROLES_SUCCESS: {
        if (payload?.institutionAdminRemove?.length > 0) {
          const institutionAdminRemove = [...payload.institutionAdminRemove];
          institutionAdminRemove.forEach(uuid =>
            UserInstitution.withId(uuid).delete()
          );
        }

        const userInstitutions = [...payload.userInstitutions];

        if (userInstitutions?.length > 0) {
          userInstitutions.forEach(userInstitution =>
            UserInstitution.upsert(userInstitution)
          );
        }

        break;
      }
      case GET_USER_SUCCESS: {
        const userInstitutions = [...payload.userInstitutions];
        userInstitutions.forEach(userInstitution =>
          UserInstitution.upsert(userInstitution)
        );
        break;
      }
      case POST_USER_ROLE_SUCCESS: {
        if (payload.roleType === 'institution_role') {
          UserInstitution.create(payload.role);
        }

        break;
      }

      case GET_USERS_BY_SEARCH_ROLES_SUCCESS: {
        if (payload?.userInstitutions?.length > 0) {
          const userInstitutions = [...payload.userInstitutions];
          userInstitutions.forEach(userInstitution =>
            UserInstitution.upsert(userInstitution)
          );
        }
        break;
      }
      default:
        return session;
    }
  }
}

UserInstitution.fields = {
  uuid: attr(),
  role: attr(),
  email: attr(),
  phone: attr(),
  status: attr(),
  startDate: attr(),
  endDate: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  userUuid: fk({
    to: 'User',
    as: 'user',
    relatedName: 'user_institutions'
  }),
  institutionUuid: fk({
    to: 'Institution',
    as: 'institution',
    relatedName: 'user_institutions'
  })
};

UserInstitution.modelName = 'UserInstitution';

UserInstitution.options = {
  idAttribute: 'uuid'
};

export default UserInstitution;
