import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';

import { orm } from '../models';

export const competencyScoreSelector = createSelector(
  state => state,
  (_, competencyUuid) => competencyUuid,
  (redux, competencyUuid) => {
    const competencyScoreORM = ormCreateSelector(orm.CompetencyScore);
    const competencyScore = competencyScoreORM(redux, competencyUuid);

    return competencyScore;
  }
);
