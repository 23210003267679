import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Decimal from 'decimal.js-light';

import { aptitudeReducer, aptitudeInitial } from './aptitudeState';
import Aptitude from './Aptitude';
import { userSelector } from '../../../../../redux/selectors/usersSelectors';
import {
  classificationsSelector,
  selectAptitudes
} from '../../../../../redux/selectors/classificationSelectors';
import { degreeLevelsSelector } from '../../../../../redux/selectors/degreeLevelsSelectors';
import { selectLetterGradesBySchool } from '../../../../../redux/selectors/letterGradeSelectors';
import { programSelector } from '../../../../../redux/selectors/programSelectors';
import {
  validateSelectDate,
  validateSelectField,
  validateInputString,
  validateInputNumber
} from '../../../../../helpers/validation/validateGeneric';
import { doPostTranscript } from '../../../../../redux/actions/applicantTranscriptActions';
import {
  selectAptitudeConversions,
  selectExamAptitude
} from '../../../../../redux/selectors/examAptitudeSelectors';
import { selectConversionAptitude } from '../../../../../redux/selectors/conversionAptitudeSelectors';
import { alphaNumSorted } from '../../../../../helpers/utilities';

export default function AptitudeAddContainer({
  formType,
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  modalOpen,
  handleModalClose
}) {
  const dispatch = useDispatch();
  const [itemAge, setItemAge] = useState('0.0');

  const [hasErrors, setHasErrors] = useState(false);
  const [transcriptState, transcriptDispatch] = useReducer(
    aptitudeReducer,
    aptitudeInitial
  );

  const {
    attempt,
    date,
    applicationDate,
    termType,
    term,
    year,
    institutionCode,
    sourceDegreeLevelUuid,
    classificationUuid,
    sourceInstitutionUuid,
    examAptitudeUuid,
    conversionAptitudeUuid
  } = transcriptState;

  const user = useSelector(state => userSelector(state, userUuid));
  const program = useSelector(state => programSelector(state, programUuid));

  const letterGrades = useSelector(state =>
    selectLetterGradesBySchool(state, program.schoolUuid)
  );

  const letterGradeOptions = letterGrades.map(level => ({
    label: level.grade,
    value: level.uuid
  }));

  const mapGrades = _(letterGrades)
    .map(record => ({
      ...record,
      rangeLow: Number(record.rangeLow),
      rangeHigh: Number(record.rangeHigh)
    }))
    .value();

  const degreeLevelOptions = useSelector(state =>
    degreeLevelsSelector(state)
  ).map(level => ({ label: level.degreeLevel, value: level.uuid }));

  const classificationOptions = useSelector(state =>
    classificationsSelector(state, programUuid, category, source)
  ).map(classification => ({
    label: classification.scope,
    value: classification.uuid
  }));

  const { createTranscript, createTranscriptSuccess } = useSelector(
    state => state.crudState
  );

  const examAptitudes = useSelector(state => {
    if (classificationUuid) {
      return selectAptitudes(state, classificationUuid);
    }
  }, _.isEqual);

  const examAptitudeOptions = examAptitudes?.map(exam => ({
    label: exam.examId,
    value: exam.uuid
  }));

  const examAptitude = useSelector(state => {
    if (examAptitudeUuid) {
      return selectExamAptitude(state, examAptitudeUuid);
    }
  }, _.isEqual);

  const conversions = useSelector(state => {
    if (examAptitudeUuid) {
      return selectAptitudeConversions(state, examAptitudeUuid);
    }
  }, _.isEqual);

  const conversionOptions =
    conversions?.length > 0
      ? alphaNumSorted(conversions, 'scaledScore', 'percentage').map(exam => ({
          label: exam.scaledScore,
          value: exam.uuid
        }))
      : [];

  const conversion = useSelector(
    state => selectConversionAptitude(state, conversionAptitudeUuid),
    _.isEqual
  );

  const findGrade = _.find(mapGrades, schoolGrade => {
    if (conversion?.percentage) {
      const low = new Decimal(schoolGrade.rangeLow);
      const high = new Decimal(schoolGrade.rangeHigh);

      const conversionPercentage = new Decimal(conversion.percentage);

      const conditionOne = conversionPercentage.greaterThanOrEqualTo(low);
      const conditionTwo = conversionPercentage.lessThanOrEqualTo(high);

      return conditionOne && conditionTwo;
    } else {
      return false;
    }
  });

  useEffect(() => {
    if (!modalOpen) {
      setHasErrors(false);
      transcriptDispatch({
        type: 'reset'
      });
    }
  }, [dispatch, modalOpen]);

  useEffect(() => {
    if (applicationDate && date) {
      const app = moment.utc(applicationDate);
      const historical = moment.utc(date);
      const days = moment.duration(app.diff(historical)).asDays();
      const itemage = days / 365;
      const format = new Decimal(itemage).toFixed(2).toString();
      setItemAge(format);
    }
  }, [date, applicationDate]);

  useEffect(() => {
    if (createTranscriptSuccess) {
      handleModalClose();
    }
  }, [createTranscriptSuccess, handleModalClose]);

  const handleChangeDate = (_, field, date) => {
    transcriptDispatch({ type: field, date });
  };

  const handleChange = event => {
    const { name, value } = event.target;

    transcriptDispatch({ type: name, value });
  };

  const handleSourceInstitutionUuid = institution => {
    const sourceInstitutionUuid = institution?.uuid;
    const institutionCode = institution?.institutionCode;

    transcriptDispatch({
      type: 'sourceInstitutionUuid',
      sourceInstitutionUuid,
      institutionCode
    });
  };

  const _validate = () => {
    let containErrors = false;

    const attemptError = validateInputNumber(attempt);
    const applicationDateError = validateSelectDate(applicationDate);
    const dateError = validateSelectDate(date);

    const sourceDegreeLevelUuidError = validateSelectField(
      sourceDegreeLevelUuid
    );
    const classificationUuidError = validateSelectField(classificationUuid);
    const sourceInstitutionUuidError = validateInputString(
      sourceInstitutionUuid
    );
    const examAptitudeUuidError = validateSelectField(examAptitudeUuid);
    const conversionAptitudeUuidError = validateSelectField(
      conversionAptitudeUuid
    );

    if (
      attemptError.invalid ||
      applicationDateError.invalid ||
      dateError.invalid ||
      sourceDegreeLevelUuidError.invalid ||
      classificationUuidError.invalid ||
      sourceInstitutionUuidError.invalid ||
      examAptitudeUuidError.invalid ||
      conversionAptitudeUuidError.invalid
    ) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleRequest = () => {
    const settings = {
      userUuid,
      cohortUuid,
      programUuid,
      category,
      source
    };

    const record = {
      attempt,
      applicationDate: moment(applicationDate).format('YYYY-MM-DD'),
      classificationUuid,
      weight: examAptitude?.weight,
      date: moment(date).format('YYYY-MM-DD'),
      year: moment(year).format('YYYY'),
      term,
      termType: 'Semester',
      institutionCode,
      sourceDegreeLevelUuid,
      sourceInstitutionUuid,
      examAptitudeUuid: examAptitude?.uuid,
      conversionAptitudeUuid,
      letterGradeUuid: findGrade?.uuid,
      grade: conversion.scaledScore,
      score: conversion?.percentage
    };

    const check = _validate();

    if (check) {
      setHasErrors(true);
    } else {
      dispatch(doPostTranscript({ ...settings, record }));
    }
  };

  return (
    <Aptitude
      attempt={Number(attempt)}
      formType={formType}
      loading={createTranscript}
      user={user}
      category={category}
      source={source}
      applicationDate={applicationDate}
      termType={termType}
      term={term}
      date={date}
      year={year}
      itemAge={itemAge}
      examAptitudeUuid={examAptitudeUuid}
      examName={examAptitude?.examName}
      sourceDegreeLevelUuid={sourceDegreeLevelUuid}
      classificationUuid={classificationUuid}
      sourceInstitutionUuid={sourceInstitutionUuid}
      conversionAptitudeUuid={conversionAptitudeUuid}
      letterGradeUuid={findGrade?.uuid}
      weight={examAptitude?.weight}
      percentage={conversion?.percentage}
      grade={conversion.scaledScore}
      score={conversion.percentage}
      modalOpen={modalOpen}
      degreeLevelOptions={degreeLevelOptions}
      classificationOptions={classificationOptions}
      examAptitudeOptions={examAptitudeOptions}
      conversionOptions={conversionOptions}
      letterGradeOptions={letterGradeOptions}
      hasErrors={hasErrors}
      handleModalClose={handleModalClose}
      handleChangeDate={handleChangeDate}
      handleChange={handleChange}
      handleRequest={handleRequest}
      handleSourceInstitutionUuid={handleSourceInstitutionUuid}
    />
  );
}

AptitudeAddContainer.propTypes = {
  formType: PropTypes.string,
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  modalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func
};

AptitudeAddContainer.defaultProps = {
  formType: 'Add',
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  modalOpen: false,
  handleModalClose: undefined
};
