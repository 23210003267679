import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import { tintColor } from '@xcomp/xcomp-design-library/dist/helpers';
import { format } from 'date-fns';
import { DataCellValueTypes, DidacticDataCellValueTypes } from '../../reportUtils';
import {
  diffInDays,
  formatMMMddyyyy
} from '../../../../helpers/date-fns.utils';

const DataCell = styled.td`
  height: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  ${props =>
    props.trimName && {
      'text-overflow': 'ellipsis',
      overflow: 'hidden',
      'white-space': 'nowrap',
      padding: '0 10px'
    }}
  background-color: ${props =>
    (props.$background && props.theme.colors[props.$background][200]) ||
    (props.redOne && tintColor(props.theme.colors.red[100], 0.5)) ||
    (props.red && props.theme.colors.red[200]) ||
    (props.green && props.theme.colors.green[200]) ||
    (props.yellow && props.theme.colors.yellow[500]) ||
    props.theme.colors.white};
`;

const AssessmentStudentRow = ({
  student,
  index,
  isAnonymized,
  isExpanded,
  assessments,
  dataCellValueType,
  reportType,
  cohortStartDate,
  isComputedDataStatic,
  noOfBlocks,
  isDidactic,
  showDaysOfCompletion
}) => {
  const nameColumnDisplay = isAnonymized
    ? student.user.institutionId
    : student.studentFullName;

  const minuendDate =
    student.user.numberOfPassedAss === noOfBlocks
      ? student.user.latestPassDate
      : format(new Date(), 'yyyy-MM-dd');

  const passedAssessments = useMemo(() => {
    if (isComputedDataStatic) {
      return student.user.numberOfPassedAss;
    }
    const assessmentUuids = assessments.map(b => b.uuid);

    return Object.entries(student.user.assessment_block_results)
      .map(([, v]) => v)
      .filter(a => assessmentUuids.includes(a.assessmentBlockUuid)).length;
  }, [assessments, student.user.assessment_block_results, isComputedDataStatic, student.user.numberOfPassedAss]);

  return (
    <tr>
      <DataCell redOne>{index + 1}</DataCell>

      <Tooltip arrow title={nameColumnDisplay} placement="bottom">
        <DataCell trimName>{nameColumnDisplay}</DataCell>
      </Tooltip>

      {showDaysOfCompletion && !isDidactic && (
        <DataCell redOne>
          {!student.inactive && diffInDays(minuendDate, cohortStartDate)}
        </DataCell>
      )}
      <DataCell redOne>
        {!student.inactive && passedAssessments ? passedAssessments : '--'}
      </DataCell>
      <DataCell redOne>
        {!student.inactive && passedAssessments / noOfBlocks
          ? `${((passedAssessments / noOfBlocks) * 100).toFixed(2)}%`
          : '--'}
      </DataCell>
      {isDidactic
        ?
          <DataCell redOne>
            {student.user.cumulativeScore}
          </DataCell>
        :
          <DataCell redOne>
            {!student.inactive && passedAssessments
              ? `${student.user.firstTimePassRate.toFixed(2)}%`
              : '--'}
          </DataCell>
      }

      {assessments.map(a => {
        if (isExpanded) {
          const foundBlock = student.user.assessment_block_results[a.uuid];

          let displayValue = 'N/A';
          let isRed = false;
          let background;

          if (reportType === 'fail' && foundBlock && foundBlock.failReport) {
            if (foundBlock.failReport.isPassed && foundBlock.failReport.attempt === 1) {
              background = 'green';
            } else if (foundBlock.failReport.isPassed && foundBlock.failReport.attempt > 1) {
              background = 'yellow';
            } else {
              background = 'red';
            }
          }

          const hasData = (reportType === 'pass' && foundBlock?.passDate) || (reportType === 'fail' && foundBlock?.failReport);

          if (hasData) {
            switch (dataCellValueType) {
              case DataCellValueTypes.date:
                displayValue = formatMMMddyyyy(reportType === 'fail' ? foundBlock.failReport.passDate : foundBlock.passDate);
                break;
              case DataCellValueTypes.score:
                displayValue = reportType === 'fail' ? foundBlock.failReport.blockScore : foundBlock.blockScore;
                isRed = foundBlock.blockScore === '0/0';
                break;
              case DataCellValueTypes.attempt:
                displayValue = reportType === 'fail' ? foundBlock.failReport.attempt : foundBlock.blockAttempt;
                if (foundBlock.blockAttempt > 1) {
                  isRed = true;
                }
                break;
              case DidacticDataCellValueTypes.grade:
                displayValue = `${foundBlock.grade}%`;
                isRed = foundBlock.grade === '0.00'
                break;
              default:
                displayValue = '';
                break;
            }
          }
          
          return (
            <DataCell
              key={`student ${a.uuid}`}
              green={!!hasData}
              red={isRed}
              $background={background}
            >
              {displayValue}
            </DataCell>
          );
        } else {
          let displayValue;
          let green = false;
          let red = false;
          let yellow = false;

          if (a.noOfAssBlocks === 0) {
            displayValue = 'N/A';
          } else if (student.user.collectionProgress[a.uuid] === undefined) {
            displayValue = '0.00%';
            red = true;
          } else {
            displayValue = `${(
              (student.user.collectionProgress[a.uuid] / a.noOfAssBlocks) *
              100
            ).toFixed(2)}%`;
            green = displayValue === '100.00%';
            yellow = displayValue !== '100.00%';
          }

          return (
            <DataCell
              key={`student ${a.uuid}`}
              green={green}
              red={red}
              yellow={yellow}
            >
              {displayValue}
            </DataCell>
          );
        }
      })}
    </tr>
  );
};

AssessmentStudentRow.propTypes = {
  noOfBlocks: PropTypes.number.isRequired,
  student: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isAnonymized: PropTypes.bool.isRequired,
  assessments: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  dataCellValueType: PropTypes.string.isRequired,
  reportType: PropTypes.string,
  isComputedDataStatic: PropTypes.bool,
  cohortStartDate: PropTypes.string,
  showDaysOfCompletion: PropTypes.bool,
  isDidactic: PropTypes.bool,
};
AssessmentStudentRow.defaultProps = {
  cohortStartDate: null,
  showDaysOfCompletion: true,
  reportType: 'pass',
  isComputedDataStatic: false,
  isDidactic: false,
};

export default AssessmentStudentRow;
