import { Model, attr, fk } from 'redux-orm';
import {
  GET_COMPETENCY_COLLECTION_SUCCESS,
  GET_COMPETENCY_COLLECTIONS_SUCCESS,
  POST_COMPETENCY_SUCCESS,
  DELETE_COMPETENCY_SUCCESS,
  DELETE_COMPETENCY_COLLECTION_SUCCESS,
  GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS
} from '../actions-type';
import { sortByProperty } from '../../helpers/utilities';

class Competency extends Model {
  static reducer(action, Competency, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_COMPETENCY_COLLECTION_SUCCESS: {
        const { competencyCollection } = payload;
        return competencyCollection.competencies
          .sort((a, b) => sortByProperty(a, b, 'name'))
          .map((competency, index) => {
            return Competency.upsert({ ...competency, order: index });
          });
      }
      case GET_COMPETENCY_COLLECTIONS_SUCCESS: {
        const competencyCollections = [...payload.competencyCollections];

        return competencyCollections.map(collection => {
          return collection.competencies
            .sort((a, b) => sortByProperty(a, b, 'name'))
            .map((competency, index) => {
              return Competency.upsert({ ...competency, order: index });
            });
        });
      }
      case POST_COMPETENCY_SUCCESS: {
        const { createdCompetency } = payload;

        return Competency.upsert({
          ...createdCompetency
        });
      }
      case DELETE_COMPETENCY_SUCCESS: {
        const { deletedCompetency } = payload;
        return Competency.withId(deletedCompetency.uuid).delete();
      }
      case DELETE_COMPETENCY_COLLECTION_SUCCESS: {
        const { deletedCompetencyCollection } = payload;
        const competencisToDelete = Competency.all().filter(
          comp =>
            comp.competencyCollectionUuid === deletedCompetencyCollection.uuid
        );

        return competencisToDelete.delete();
      }
      case GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS: {
        const { competencyCollection } = payload;

        if (competencyCollection?.length > 0) {
          competencyCollection.forEach(collection => {
            const { competencies } = collection;
            if (competencies?.length > 0) {
              competencies.forEach(competency => {
                Competency.upsert(competency);
              });
            }
          });
        }
        break;
      }

      default:
        return session;
    }
  }
}

Competency.fields = {
  uuid: attr(),
  name: attr(),
  description: attr(),
  order: attr(),
  competencyCollectionUuid: fk('CompetencyCollection', 'competencies'),
  createdAt: attr(),
  updatedAt: attr()
};

Competency.modelName = 'Competency';
Competency.options = {
  idAttribute: 'uuid'
};

export default Competency;
