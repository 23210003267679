import { takeEvery, call, put } from 'redux-saga/effects';
import {
  getPartGradeSettings,
  getGradeCollection,
  putPartDataSource,
  putPartGradeCollections,
  putCollectionAssessments
} from '../../api/partGradeSettingsApi';

import {
  doGetPartGradeSettingsError,
  doGetPartGradeSettingsSuccess,
  doGetGradeCollectionError,
  doGetGradeCollectionSuccess,
  doPutPartGradeDataSourceSuccess,
  doPutPartGradeDataSourceError,
  doPutPartGradeCollectionsSuccess,
  doPutPartGradeCollectionsError,
  doPutCollectionAssessmentsError,
  doPutCollectionAssessmentsSuccess
} from '../actions/partGradeSettingsActions';

import {
  GET_PART_GRADE_SETTINGS,
  GET_GRADE_COLLECTION,
  PUT_PART_GRADE_DATA_SOURCE,
  PUT_PART_GRADE_COLLECTIONS,
  PUT_COLLECTION_ASSESSMENTS
} from '../actions-type';

function* handleGetPartGradeSettings(action) {
  try {
    const result = yield call(getPartGradeSettings, action.payload.partUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartGradeSettingsSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetPartGradeSettingsError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doGetPartGradeSettingsError());
  }
}

function* handleGetGradeCollection(action) {
  try {
    const result = yield call(getGradeCollection, action.payload);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetGradeCollectionSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetGradeCollectionError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doGetGradeCollectionError());
  }
}

function* handlePutPartDataSource(action) {
  try {
    const result = yield call(putPartDataSource, action.payload);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutPartGradeDataSourceSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPutPartGradeDataSourceError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPutPartGradeDataSourceError());
  }
}

function* handlePutPartGradeCollections(action) {
  try {
    const result = yield call(putPartGradeCollections, action.payload);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutPartGradeCollectionsSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPutPartGradeCollectionsError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPutPartGradeCollectionsError());
  }
}

function* handlePutCollectionAssessments(action) {
  try {
    const result = yield call(putCollectionAssessments, action.payload);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutCollectionAssessmentsSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPutCollectionAssessmentsError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPutCollectionAssessmentsError());
  }
}

const partGradeSettingsSaga = [
  takeEvery(GET_PART_GRADE_SETTINGS, handleGetPartGradeSettings),
  takeEvery(GET_GRADE_COLLECTION, handleGetGradeCollection),
  takeEvery(PUT_PART_GRADE_DATA_SOURCE, handlePutPartDataSource),
  takeEvery(PUT_PART_GRADE_COLLECTIONS, handlePutPartGradeCollections),
  takeEvery(PUT_COLLECTION_ASSESSMENTS, handlePutCollectionAssessments)
];

export { partGradeSettingsSaga };
