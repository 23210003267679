import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AddIllustration, ButtonPrimary } from '@xcomp/xcomp-design-library';
import styled, { ThemeContext } from 'styled-components';
import UserUploadContainer from './UserUpload/UserUploadContainer';
import UserAssignContainer from './UserAssign/UserAssignContainer';
import {
  ContainerActions,
  ContainerContent,
  ElevateLow
} from '../../Library/Layout';

import {
  visibleToInstitutionAdmin,
  visibleToSchoolAdmin
} from '../../../helpers/authorization';

const InstitutionAdminActions = visibleToInstitutionAdmin(props => (
  <ButtonPrimary onClick={() => props.handleUserUploadOpen()}>
    Upload Users
  </ButtonPrimary>
));

const SchoolAdminActions = visibleToSchoolAdmin(props => (
  <ButtonPrimary onClick={() => props.handleUserAssignOpen()}>
    Mass Assign
  </ButtonPrimary>
));

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40vh;
  align-items: center;
  font-size: 16px;
`;

const UserTabUpload = ({
  openUpload,
  openUserAssignModal,
  handleUserUploadOpen,
  handleUserUploadClose,
  handleUserAssignClose,
  handleGoToAddUser,
  handleUserAssignOpen
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <ContainerActions paddingTop="20px">
        <InstitutionAdminActions
          handleGoToAddUser={handleGoToAddUser}
          handleUserUploadOpen={handleUserUploadOpen}
        />
        <SchoolAdminActions handleUserAssignOpen={handleUserAssignOpen} />
      </ContainerActions>
      <ContainerContent>
        <ElevateLow>
          <Center>
            <div>
              <AddIllustration
                dotsColor={themeContext.colors.primaryShades[450]}
                bgColor={themeContext.colors.primaryShades[200]}
                outlineColor={themeContext.colors.primary}
              />
            </div>
            <div>Upload users to global system and institution.</div>
          </Center>
        </ElevateLow>
      </ContainerContent>
      <UserUploadContainer
        openUpload={openUpload}
        handleUserUploadClose={handleUserUploadClose}
      />
      <UserAssignContainer
        openUserAssignModal={openUserAssignModal}
        handleUserAssignClose={handleUserAssignClose}
      />
    </>
  );
};

UserTabUpload.propTypes = {
  openUpload: PropTypes.bool,
  openUserAssignModal: PropTypes.bool,
  handleUserUploadOpen: PropTypes.func,
  handleUserUploadClose: PropTypes.func,
  handleUserAssignClose: PropTypes.func,
  handleGoToAddUser: PropTypes.func,
  handleUserAssignOpen: PropTypes.func
};

UserTabUpload.defaultProps = {
  openUpload: false,
  openUserAssignModal: false,
  handleUserUploadOpen: undefined,
  handleUserUploadClose: undefined,
  handleUserAssignClose: undefined,
  handleGoToAddUser: undefined,
  handleUserAssignOpen: undefined
};

export default UserTabUpload;
