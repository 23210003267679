// import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useGetCohortStudentAssessments } from '../../../../helpers/hooks/useGetCohortStudentAssessments';
import CohortAssessmentCollection from './CohortAssessmentCollection';

export default function CohortAssessmentCollectionContainer({ userUuid }) {
  const { collections, completionStatus } = useGetCohortStudentAssessments();

  return (
    <>
      <CohortAssessmentCollection
        collections={collections}
        completionStatus={completionStatus}
        userUuid={userUuid}
      />
    </>
  );
}

CohortAssessmentCollectionContainer.propTypes = {
  userUuid: PropTypes.string
};

CohortAssessmentCollectionContainer.defaultProps = {
  userUuid: undefined
};
