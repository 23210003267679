import {
  GET_DEGREE_LEVEL_INDICES,
  GET_DEGREE_LEVEL_INDICES_SUCCESS,
  GET_DEGREE_LEVEL_INDICES_ERROR,
  PUT_DEGREE_LEVEL_INDEX,
  PUT_DEGREE_LEVEL_INDEX_SUCCESS,
  PUT_DEGREE_LEVEL_INDEX_ERROR,
  POST_DEGREE_LEVEL_INDEX,
  POST_DEGREE_LEVEL_INDEX_SUCCESS,
  POST_DEGREE_LEVEL_INDEX_ERROR,
  DELETE_DEGREE_LEVEL_INDEX,
  DELETE_DEGREE_LEVEL_INDEX_SUCCESS,
  DELETE_DEGREE_LEVEL_INDEX_ERROR
} from '../actions-type';

const doGetDegreeLevelIndices = programUuid => ({
  type: GET_DEGREE_LEVEL_INDICES,
  payload: { programUuid }
});

const doGetDegreeLevelIndicesSuccess = result => ({
  type: GET_DEGREE_LEVEL_INDICES_SUCCESS,
  payload: result
});

const doGetDegreeLevelIndicesError = error => ({
  type: GET_DEGREE_LEVEL_INDICES_ERROR,
  payload: error
});

const doPostDegreeLevelIndex = (
  programUuid,
  sourceDegreeLevelUuid,
  percentage
) => ({
  type: POST_DEGREE_LEVEL_INDEX,
  payload: { programUuid, sourceDegreeLevelUuid, percentage }
});

const doPostDegreeLevelIndexSuccess = result => ({
  type: POST_DEGREE_LEVEL_INDEX_SUCCESS,
  payload: result
});

const doPostDegreeLevelIndexError = error => ({
  type: POST_DEGREE_LEVEL_INDEX_ERROR,
  payload: error
});

const doPutDegreeLevelIndex = (uuid, sourceDegreeLevelUuid, percentage) => ({
  type: PUT_DEGREE_LEVEL_INDEX,
  payload: { uuid, sourceDegreeLevelUuid, percentage }
});

const doPutDegreeLevelIndexSuccess = result => ({
  type: PUT_DEGREE_LEVEL_INDEX_SUCCESS,
  payload: result
});

const doPutDegreeLevelIndexError = error => ({
  type: PUT_DEGREE_LEVEL_INDEX_ERROR,
  payload: error
});

const doDeleteDegreeLevelIndex = uuid => ({
  type: DELETE_DEGREE_LEVEL_INDEX,
  payload: { uuid }
});

const doDeleteDegreeLevelIndexSuccess = result => ({
  type: DELETE_DEGREE_LEVEL_INDEX_SUCCESS,
  payload: result
});

const doDeleteDegreeLevelIndexError = error => ({
  type: DELETE_DEGREE_LEVEL_INDEX_ERROR,
  payload: error
});

export {
  doGetDegreeLevelIndices,
  doGetDegreeLevelIndicesSuccess,
  doGetDegreeLevelIndicesError,
  doPutDegreeLevelIndex,
  doPutDegreeLevelIndexSuccess,
  doPutDegreeLevelIndexError,
  doPostDegreeLevelIndex,
  doPostDegreeLevelIndexSuccess,
  doPostDegreeLevelIndexError,
  doDeleteDegreeLevelIndex,
  doDeleteDegreeLevelIndexSuccess,
  doDeleteDegreeLevelIndexError
};
