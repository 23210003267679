import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import {
  formatLastName,
  formatFirstNameMiddleName
} from '../../../../../helpers/format/user.format';
import FacultyAlternativeScoreViewFacultyPanel from './FacultyAlternativeScoreViewFacultyPanel';

const FacultyAlternativeScoreViewFacultyPanelContainer = ({
  facultyUser,
  scoreType,
  studentFacultyScores
}) => {
  const studentScoresByAttempt = useMemo(
    () => _.groupBy(studentFacultyScores, 'attempt'),
    [studentFacultyScores]
  );
  const { facultyTotalPotentialScore, facultyTotalRelativeScore } = useMemo(
    () =>
      _.reduce(
        studentFacultyScores,
        (acc, score) => {
          const currentPotential = new Decimal(score.potValue);
          const currentRelative = new Decimal(score.relValue);
          return {
            facultyTotalPotentialScore: currentPotential.plus(
              acc.facultyTotalPotentialScore
            ),
            facultyTotalRelativeScore: currentRelative.plus(
              acc.facultyTotalRelativeScore
            )
          };
        },
        {
          facultyTotalPotentialScore: new Decimal(0),
          facultyTotalRelativeScore: new Decimal(0)
        }
      ),
    [studentFacultyScores]
  );
  const facultyFirstName = formatFirstNameMiddleName(
    facultyUser.user?.firstName,
    facultyUser.user?.middleName
  );
  const facultyLastName = formatLastName(
    facultyUser.user?.lastName,
    facultyUser.user?.marriedLastName,
    facultyUser.user?.suffix
  );

  return new Decimal(facultyTotalPotentialScore).greaterThan(0) ? (
    <FacultyAlternativeScoreViewFacultyPanel
      facultyFullName={`${facultyLastName}, ${facultyFirstName}`}
      facultyTotalPotentialScore={new Decimal(
        facultyTotalPotentialScore
      ).toFixed(2)}
      facultyTotalRelativeScore={new Decimal(facultyTotalRelativeScore).toFixed(
        2
      )}
      scoreType={scoreType}
      studentScoresByAttempt={studentScoresByAttempt}
    />
  ) : null;
};

FacultyAlternativeScoreViewFacultyPanelContainer.propTypes = {
  facultyUser: PropTypes.object,
  scoreType: PropTypes.string,
  studentFacultyScores: PropTypes.array
};
FacultyAlternativeScoreViewFacultyPanelContainer.defaultProps = {
  facultyUser: {},
  scoreType: '',
  studentFacultyScores: []
};

export default FacultyAlternativeScoreViewFacultyPanelContainer;
