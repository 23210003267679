import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormSelectError from '../../../../Library/FormSelectError';
import { validateSelectField } from '../../../../../helpers/validation/validateGeneric';

const ScoreTypeSelect = styled(FormSelectError)`
  && {
    margin-top: 16px;
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    max-width: 450px;
    margin-bottom: 0;
  }
`;

const Layout = styled.div`
  width: 100%;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-start;
`;

const ScoreTypeForm = ({ handleChange, scoreType, showValidationErrors }) => {
  const scoreTypeOptions = [
    {
      value: 'SCORE',
      label: 'Score'
    },
    {
      value: 'POINTS_ATTEMPT',
      label: 'Points (Attempt)'
    },
    {
      value: 'POINTS_OPPORTUNITY',
      label: 'Points (Opportunity)'
    }
  ];

  return (
    <Layout>
      <ScoreTypeSelect
        id="scoreType"
        value={scoreType}
        options={scoreTypeOptions}
        handleChange={handleChange}
        name="scoreType"
        label="Collection Score Type"
        handleValidation={validateSelectField}
        hasErrors={showValidationErrors}
      />
    </Layout>
  );
};

ScoreTypeForm.propTypes = {
  scoreType: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func
};

ScoreTypeForm.defaultProps = {
  scoreType: 'SCORE',
  showValidationErrors: false,
  handleChange: undefined
};

export default ScoreTypeForm;
