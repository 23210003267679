import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  validateInputString,
  validateSelectDate
} from '../../../helpers/validation/validateGeneric';

import { doPostCohort } from '../../../redux/actions/cohortActions';

import CohortAdd from './CohortAdd';

export default function CohortAddContainer() {
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [hasErrors, setHasErrors] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const programUuid = useSelector(state => state.userState.selectedProgramUuid);
  const dispatch = useDispatch();

  const handleNameChange = event => {
    const { value } = event.target;
    setName(value);
  };

  const handleStartDateChange = (indentifier, field, date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (indentifier, field, date) => {
    setEndDate(date);
  };

  const _validate = () => {
    let containErrors = false;
    const cohortNameError = validateInputString(name);
    const cohortStartDateError = validateSelectDate(startDate);
    const cohortEndDateError = validateSelectDate(endDate);

    if (
      cohortNameError.invalid ||
      cohortStartDateError.invalid ||
      cohortEndDateError.invalid
    ) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleCreateCohort = () => {
    const rejectCreation = _validate();

    if (rejectCreation) {
      setHasErrors(true);
    } else {
      const request = {
        name,
        startDate,
        endDate,
        programUuid
      };

      dispatch(doPostCohort(request));
      setRedirect(true);
    }
  };

  return redirect ? (
    <Redirect to="/program-management/cohort" />
  ) : (
    <CohortAdd
      name={name}
      startDate={startDate}
      endDate={endDate}
      hasErrors={hasErrors}
      handleNameChange={handleNameChange}
      handleStartDateChange={handleStartDateChange}
      handleEndDateChange={handleEndDateChange}
      handleCreateCohort={handleCreateCohort}
    />
  );
}
