import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DegreeLevelIndexAdd from './DegreeLevelIndexAdd';
import { degreeLevelsSelector } from '../../../../../redux/selectors/degreeLevelsSelectors';
import {
  validateSelectField,
  validateInputString
} from '../../../../../helpers/validation/validateGeneric';
import { doGetDegreeLevels } from '../../../../../redux/actions/sourceActions';
import { doPostDegreeLevelIndex } from '../../../../../redux/actions/degreeLevelIndexActions';

export default function DegreeLevelIndexAddContainer({
  programUuid,
  openDegree,
  handleDegreeClose
}) {
  const dispatch = useDispatch();
  const [hasErrors, setHasErrors] = useState(false);
  const [sourceDegreeLevelUuid, setSourceDegreeLevelUuid] = useState(undefined);
  const [percentage, setPercentage] = useState(undefined);

  const degreeLevelOptions = useSelector(state =>
    degreeLevelsSelector(state)
  ).map(level => ({ label: level.degreeLevel, value: level.uuid }));

  useEffect(() => {
    dispatch(doGetDegreeLevels());
  }, [dispatch]);

  useEffect(() => {
    if (!openDegree) {
      setHasErrors(false);
      setSourceDegreeLevelUuid(undefined);
      setPercentage(undefined);
    }
  }, [openDegree]);

  const _validate = () => {
    let containErrors = false;

    const sourceDegreeLevelUuidError = validateSelectField(
      sourceDegreeLevelUuid
    );

    const percentageError = validateInputString(percentage);

    if (sourceDegreeLevelUuidError.invalid || percentageError.invalid) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleDegreeLevel = event => {
    const { value } = event.target;
    setSourceDegreeLevelUuid(value);
  };

  const handlePercentage = event => {
    const { value } = event.target;
    setPercentage(value);
  };

  const handleAddDegreeLevelIndex = () => {
    const check = _validate();

    if (check) {
      setHasErrors(true);
    } else {
      dispatch(
        doPostDegreeLevelIndex(programUuid, sourceDegreeLevelUuid, percentage)
      );
      handleDegreeClose();
    }
  };

  return (
    <DegreeLevelIndexAdd
      sourceDegreeLevelUuid={sourceDegreeLevelUuid}
      percentage={percentage}
      hasErrors={hasErrors}
      openDegree={openDegree}
      degreeLevelOptions={degreeLevelOptions}
      handleDegreeClose={handleDegreeClose}
      handleAddDegreeLevelIndex={handleAddDegreeLevelIndex}
      handleDegreeLevel={handleDegreeLevel}
      handlePercentage={handlePercentage}
    />
  );
}

DegreeLevelIndexAddContainer.propTypes = {
  programUuid: PropTypes.string,
  openDegree: PropTypes.bool,
  handleDegreeClose: PropTypes.func
};

DegreeLevelIndexAddContainer.defaultProps = {
  programUuid: undefined,
  openDegree: false,
  handleDegreeClose: undefined
};
