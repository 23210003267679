import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { selectCollectionCredits } from '../../../../redux/selectors/courseCollectionsSelectors';

export default function CollectionCredits({ userUuid, courseCollectionUuid }) {
  const credits = useSelector(
    state => selectCollectionCredits(state, userUuid, courseCollectionUuid),
    _.isEqual
  );

  return <>{credits}</>;
}

CollectionCredits.propTypes = {
  userUuid: PropTypes.string,
  courseCollectionUuid: PropTypes.string
};

CollectionCredits.defaultProps = {
  userUuid: undefined,
  courseCollectionUuid: undefined
};
