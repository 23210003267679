import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import CohortStudentActions from './CohortStudentActions';
import CohortStudent from './CohortStudent';
import CohortDeleteStudentConfirmation from './CohortDeleteStudentConfirmation';
import CohortStudentAssignContainer from './CohortStudentAssign/CohortStudentAssignContainer';

import {
  doPatchUserCohortStatus,
  doDeleteUserCohorts
} from '../../../redux/actions/userCohortActions';

import { userStudentSelectorByCohort } from '../../../redux/selectors/userRolesSelectors';
import { usePrevious } from '../../../helpers/hooks/state.hooks';
import { roles } from '../../../helpers/constants';

export default function CohortStudentContainer({ cohortUuid }) {
  const dispatch = useDispatch();
  const [cohortUsers, setCohortUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState({
    active: false,
    inactive: false,
    delayed: false,
    competent: false,
    withdrawn: false,
    transferred: false
  });
  const [openAssignStudent, setOpenAssignStudent] = useState(false);

  const cohortUsersORM = useSelector(
    state => userStudentSelectorByCohort(state, cohortUuid),
    _.isEqual
  );

  const selectedRole = useSelector(state => state.userState.selectedRole);
  const isAdmin = selectedRole === roles.programAdmin;

  const cohortUsersPrevious = usePrevious(cohortUsersORM);

  useEffect(() => {
    const equalityCheck = !_.isEqual(cohortUsersPrevious, cohortUsersORM);

    if (equalityCheck) {
      const setNonSelected = cohortUsersORM.map(cohortUser => ({
        ...cohortUser,
        selected: false
      }));

      setCohortUsers(setNonSelected);
    }
  }, [cohortUsersPrevious, cohortUsersORM]);

  const handleFilterStatus = selection => {
    setFilterStatus(filterStatus => ({
      ...filterStatus,
      [selection]: !filterStatus[selection]
    }));
  };

  const handleSelectAll = () => {
    const selectAll = cohortUsers.map(cohortUser => ({
      ...cohortUser,
      selected: true
    }));

    setSelectAll(true);
    setCohortUsers(selectAll);
  };

  const handleDeselectAll = () => {
    const deselectAll = cohortUsers.map(cohortUser => ({
      ...cohortUser,
      selected: false
    }));

    setSelectAll(false);
    setCohortUsers(deselectAll);
  };

  const filterCohortUsers = () => {
    const { active, inactive, delayed, competent, withdrawn, transferred } =
      filterStatus;

    const noFilters =
      !active &&
      !inactive &&
      !delayed &&
      !competent &&
      !withdrawn &&
      !transferred;

    if (noFilters) {
      return cohortUsers;
    } else {
      const filterStatus = cohortUsers.filter(cohortUser => {
        const { status } = cohortUser;

        return (
          (active && status === 'active') ||
          (inactive && status === 'inactive') ||
          (delayed && status === 'delayed') ||
          (competent && status === 'competent') ||
          (withdrawn && status === 'withdrawn') ||
          (transferred && status === 'transferred')
        );
      });

      return filterStatus;
    }
  };

  const handleSingleSelect = uuid => {
    const index = _.findIndex(
      cohortUsers,
      cohortUser => cohortUser.uuid === uuid
    );

    setCohortUsers(cohortUsers => {
      const copy = [...cohortUsers];

      copy[index] = {
        ...copy[index],
        selected: !copy[index].selected
      };

      return copy;
    });
  };

  const handleAssignStudentOpen = () => {
    setOpenAssignStudent(true);
  };

  const handleAssignStudentClose = () => {
    setOpenAssignStudent(false);
  };

  const countCohortUsersStatus = () => {
    const activeCount = cohortUsers.filter(
      cohortUser => cohortUser.status === 'active'
    ).length;

    const completeCount = cohortUsers.filter(
      cohortUser => cohortUser.status === 'complete'
    ).length;

    const inactiveCount = cohortUsers.filter(
      cohortUser => cohortUser.status === 'inactive'
    ).length;

    const delayedCount = cohortUsers.filter(
      cohortUser => cohortUser.status === 'delayed'
    ).length;

    const competentCount = cohortUsers.filter(
      cohortUser => cohortUser.status === 'competent'
    ).length;

    const withdrawnCount = cohortUsers.filter(
      cohortUser => cohortUser.status === 'withdrawn'
    ).length;

    const transferredCount = cohortUsers.filter(
      cohortUser => cohortUser.status === 'transferred'
    ).length;

    const count = {
      activeCount,
      completeCount,
      inactiveCount,
      delayedCount,
      competentCount,
      withdrawnCount,
      transferredCount
    };

    return count;
  };

  const handleSelectedStatus = status => {
    const selectedUsers = cohortUsers.filter(
      cohortUser => cohortUser.selected === true
    );

    const changeStatus = selectedUsers.map(user => ({
      uuid: user.uuid,
      status
    }));

    dispatch(doPatchUserCohortStatus(changeStatus));
    setSelectAll(false);
  };

  const handleInitiateDelete = () => {
    setDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setDeleteModal(false);
  };

  const handleDeleteStudents = () => {
    const selectedUsers = cohortUsers.filter(
      cohortUser => cohortUser.selected === true
    );

    if (selectedUsers.length > 0) {
      dispatch(doDeleteUserCohorts(selectedUsers));
    }

    setDeleteModal(false);
  };

  return (
    <>
      {isAdmin &&
        <CohortStudentActions
          handleSelectedStatus={handleSelectedStatus}
          handleAssignStudentOpen={handleAssignStudentOpen}
          handleInitiateDelete={handleInitiateDelete}
        />
      }
      <CohortStudent
        cohortUsers={filterCohortUsers()}
        cohortUsersStatusCount={countCohortUsersStatus()}
        filterStatus={filterStatus}
        selectAll={selectAll}
        handleFilterStatus={handleFilterStatus}
        handleSelectAll={handleSelectAll}
        handleDeselectAll={handleDeselectAll}
        handleSingleSelect={handleSingleSelect}
        isAdmin={isAdmin}
      />
      {isAdmin &&
        <>
          <CohortStudentAssignContainer
            cohortUuid={cohortUuid}
            openAssignStudent={openAssignStudent}
            handleAssignStudentClose={handleAssignStudentClose}
          />
          <CohortDeleteStudentConfirmation
            openModal={deleteModal}
            handleCancelDelete={handleCancelDelete}
            handleDeleteStudents={handleDeleteStudents}
          />
        </>
      }
    </>
  );
}

CohortStudentContainer.propTypes = {
  cohortUuid: PropTypes.string
};
CohortStudentContainer.defaultProps = {
  cohortUuid: undefined
};
