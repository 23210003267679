import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const selectPartFaculty = createSelector(
  state => state,
  (_, partUuid) => partUuid,
  (redux, partUuid) => {
    const getPartFaculty = ormCreateSelector(orm.PartFaculty);
    const getUser = ormCreateSelector(orm.User);

    const partFaculty = getPartFaculty(redux).filter(
      faculty => faculty.partUuid === partUuid
    );

    const addUser = partFaculty.map(pf => {
      const user = getUser(redux, pf.userUuid);

      return {
        ...user,
        part_faculty: {
          ...pf
        }
      };
    });

    return addUser;
  }
);
