import React, { useReducer, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  doPostCompetencyCollection,
  doClearCreated
} from '../../../../redux/actions/competencyCollectionActions';
import {
  allFieldsAreValid,
  validateStringLength
} from '../../../../helpers/validation/validateGeneric';
import NewCompetencyCollectionDashboard from './NewCompetencyCollectionDashboard';
import {
  initialCompetencyCollectionState,
  competencyCollectionReducer,
  setShowValidationErrors,
  setName,
  setDefaultCollection
} from '../competencyCollectionState';

const NewCompetencyCollectionDashboardContainer = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  const successfullyCreatedCompetencyCollection = useSelector(
    state => state.formSuccessState.successfullyCreatedCompetencyCollection
  );

  const [state, newDispatch] = useReducer(
    competencyCollectionReducer,
    initialCompetencyCollectionState
  );

  const {
    name,
    is_default_collection,
    showValidationErrors,
    competencies,
    isMicroSearchOpen,
    microSearchForCompetencyUuid
  } = state;

  const clearForm = useCallback(() => {
    history.push(`/program-management/competency`);
  }, [history]);

  const onPostCreate = newCompetencyCollection =>
    dispatch(doPostCompetencyCollection(newCompetencyCollection));

  useEffect(() => {
    window.scrollTo(0, 0);
    newDispatch({
      type: 'clearForm'
    });
  }, []);

  useEffect(() => {
    const onClearCreated = () => dispatch(doClearCreated());

    if (successfullyCreatedCompetencyCollection === true) {
      onClearCreated();
      clearForm();
    }
  }, [successfullyCreatedCompetencyCollection, dispatch, clearForm]);

  const setValidationErrors = newCompetencyCollection => {
    const nameError = validateStringLength(
      newCompetencyCollection.name,
      100,
      true
    );

    const newValidationErrors = {
      nameError
    };

    return newValidationErrors;
  };

  const buildPayload = (name, is_default_collection, programUuid) => {
    return {
      name,
      is_default_collection,
      programUuid
    };
  };

  const handleCreateSubmission = () => {
    const newValidationErrors = setValidationErrors({
      name
    });

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = buildPayload(
        name,
        is_default_collection,
        selectedProgramUuid
      );

      onPostCreate(payload);
      newDispatch(setShowValidationErrors(false));
    } else {
      newDispatch(setShowValidationErrors(true));
    }
  };

  const newCompetencyCollection = {
    name,
    is_default_collection,
    competencies
  };

  const handleNameChange = e => newDispatch(setName(e.target.value));

  const handleDefaultCollectionChange = value =>
    newDispatch(setDefaultCollection(value));

  return (
    <NewCompetencyCollectionDashboard
      newCompetencyCollection={newCompetencyCollection}
      formDispatch={newDispatch}
      handleNameChange={handleNameChange}
      handleDefaultCollectionChange={handleDefaultCollectionChange}
      onSubmit={handleCreateSubmission}
      clearForm={clearForm}
      showValidationErrors={showValidationErrors}
      isMicroSearchOpen={isMicroSearchOpen}
      microSearchForCompetencyUuid={microSearchForCompetencyUuid}
    />
  );
};

export default NewCompetencyCollectionDashboardContainer;
