export const GET_PART_GRADE_SETTINGS = 'GET_PART_GRADE_SETTINGS';
export const GET_PART_GRADE_SETTINGS_SUCCESS =
  'GET_PART_GRADE_SETTINGS_SUCCESS';
export const GET_PART_GRADE_SETTINGS_ERROR = 'GET_PART_GRADE_SETTINGS_ERROR';

export const GET_GRADE_COLLECTION = 'GET_GRADE_COLLECTION';
export const GET_GRADE_COLLECTION_SUCCESS = 'GET_GRADE_COLLECTION_SUCCESS';
export const GET_GRADE_COLLECTION_ERROR = 'GET_GRADE_COLLECTION_ERROR';

export const PUT_PART_GRADE_DATA_SOURCE = 'PUT_PART_GRADE_DATA_SOURCE';
export const PUT_PART_GRADE_DATA_SOURCE_SUCCESS =
  'PUT_PART_GRADE_DATA_SOURCE_SUCCESS';
export const PUT_PART_GRADE_DATA_SOURCE_ERROR =
  'PUT_PART_GRADE_DATA_SOURCE_ERROR';

export const PUT_PART_GRADE_COLLECTIONS = 'PUT_PART_GRADE_COLLECTIONS';
export const PUT_PART_GRADE_COLLECTIONS_SUCCESS =
  'PUT_PART_GRADE_COLLECTIONS_SUCCESS';
export const PUT_PART_GRADE_COLLECTIONS_ERROR =
  'PUT_PART_GRADE_COLLECTIONS_ERROR';

export const PUT_COLLECTION_ASSESSMENTS = 'PUT_COLLECTION_ASSESSMENTS';
export const PUT_COLLECTION_ASSESSMENTS_SUCCESS =
  'PUT_COLLECTION_ASSESSMENTS_SUCCESS';
export const PUT_COLLECTION_ASSESSMENTS_ERROR =
  'PUT_COLLECTION_ASSESSMENTS_ERROR';
