import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Business from '@material-ui/icons/Business';
import { Heading1 } from '@xcomp/xcomp-design-library/dist/components/Typography';
import { ContainerResize } from '../Library/Layout';

const DashboardLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 100%;
  min-height: calc(100vh - 128px);
`;

const BusinessLogoBorderSC = styled.div`
  display: inline-block;
  padding: 3rem;
  border-style: solid;
  border-width: 2px;
  border-color: ${props => props.theme.colors.primary};
  opacity: 0.5;
  border-radius: 10rem;
`;

const BusinessLogoSC = styled(Business)`
  && {
    color: ${props => props.theme.colors.primary};
    height: 160px;
    width: 160px;
  }
`;

const DashboardHeader = styled(Heading1)`
  font-size: 2rem;
  margin-bottom: 0.5rem;
`;

// const DashboardSubtitle = styled.div`
//   width: 300px;
//   display: inline-block;
//   text-align: center;
// `;

const Wrapper = styled.div`
  padding: 0 1.5rem;
  height: calc(100vh - 128px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FlexItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Dashboard = ({ firstName }) => (
  <ContainerResize>
    <DashboardLayout>
      <Wrapper>
        <FlexItem>
          <BusinessLogoBorderSC>
            <BusinessLogoSC />
          </BusinessLogoBorderSC>
        </FlexItem>
        <FlexItem>
          <DashboardHeader>
            Welcome, {firstName && `${firstName}`}
          </DashboardHeader>
        </FlexItem>
        <FlexItem>
          {/* <DashboardSubtitle>
            Select a school program or department to view its courses or users.
          </DashboardSubtitle> */}
        </FlexItem>
      </Wrapper>
    </DashboardLayout>
  </ContainerResize>
);

Dashboard.propTypes = {
  firstName: PropTypes.string
};
Dashboard.defaultProps = {
  firstName: ''
};

export default Dashboard;
