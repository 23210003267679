import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getAcademicYear,
  getAcademicYearsBySchool,
  postAcademicYear,
  putAcademicYear,
  deleteAcademicYear
} from '../../api/academicYearApi';
import {
  doGetAcademicYearSuccess,
  doGetAcademicYearError,
  doGetAcademicYearsBySchoolSuccess,
  doGetAcademicYearsBySchoolError,
  doPostAcademicYearSuccess,
  doPostAcademicYearError,
  doPutAcademicYearSuccess,
  doPutAcademicYearError,
  doDeleteAcademicYearSuccess,
  doDeleteAcademicYearError
} from '../actions/academicYearActions';

import {
  GET_ACADEMIC_YEAR,
  GET_ACADEMIC_YEARS_BY_SCHOOL,
  POST_ACADEMIC_YEAR,
  PUT_ACADEMIC_YEAR,
  DELETE_ACADEMIC_YEAR
} from '../actions-type';

function* handleGetAcademicYear(action) {
  try {
    const result = yield call(getAcademicYear, action.payload.academicYearUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetAcademicYearSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetAcademicYearError(error));
  }
}

function* handleGetAcademicYearsBySchool(action) {
  try {
    const result = yield call(
      getAcademicYearsBySchool,
      action.payload.schoolUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetAcademicYearsBySchoolSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetAcademicYearsBySchoolError(error));
  }
}

function* handlePostAcademicYear(action) {
  try {
    const result = yield call(postAcademicYear, action.payload.academicYear);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostAcademicYearSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostAcademicYearError(error));
  }
}

function* handlePutAcademicYear(action) {
  try {
    const result = yield call(putAcademicYear, action.payload.academicYear);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutAcademicYearSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutAcademicYearError(error));
  }
}

function* handleDeleteAcademicYear(action) {
  const { academicYearUuid } = action.payload;

  try {
    const result = yield call(deleteAcademicYear, academicYearUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteAcademicYearSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteAcademicYearError(error));
  }
}

const academicYear = [
  takeEvery(GET_ACADEMIC_YEAR, handleGetAcademicYear),
  takeEvery(GET_ACADEMIC_YEARS_BY_SCHOOL, handleGetAcademicYearsBySchool),
  takeEvery(POST_ACADEMIC_YEAR, handlePostAcademicYear),
  takeEvery(PUT_ACADEMIC_YEAR, handlePutAcademicYear),
  takeEvery(DELETE_ACADEMIC_YEAR, handleDeleteAcademicYear)
];

export { academicYear };
