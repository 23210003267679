import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  doGetSchool,
  doPutSchool,
  doDeleteSchool
} from '../../../redux/actions/schoolActions';

import { validateInputString } from '../../../helpers/validation/validateGeneric';

import SchoolEdit from './SchoolEdit';
import DeleteModal from '../../Library/Modal/DeleteModal/DeleteModal';

import { schoolSelector } from '../../../redux/selectors/schoolSelectors';

export default function SchoolEditContainer({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { schoolUuid } = match.params;

  const school = useSelector(
    state => schoolSelector(state, schoolUuid),
    shallowEqual
  );
  const institutionUuid = useSelector(state => state.userState.institutionUuid);

  const [abbreviation, setAbbreviation] = useState('');
  const [schoolName, setSchoolName] = useState('');

  const [hasErrors, setHasErrors] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    dispatch(doGetSchool(schoolUuid));
  }, [dispatch, schoolUuid]);

  useEffect(() => {
    if (school) {
      setAbbreviation(school.abbreviation);
      setSchoolName(school.schoolName);
    }
  }, [school]);

  const handleChange = event => {
    const { name, value } = event.target;
    switch (name) {
      case 'abbreviation': {
        setAbbreviation(value);
        break;
      }
      case 'schoolName': {
        setSchoolName(value);
        break;
      }
      default:
        break;
    }
  };

  const validate = () => {
    let validationErrors = false;

    const schoolNameError = validateInputString(schoolName);
    const abbreviationError = validateInputString(abbreviation);

    if (schoolNameError.invalid) {
      validationErrors = true;
    }

    if (abbreviationError.invalid) {
      validationErrors = true;
    }

    return validationErrors;
  };

  const handleUpdateSchool = () => {
    const hasError = validate();

    if (hasError) {
      setHasErrors(true);
    } else {
      const payload = {
        uuid: school.uuid,
        institutionUuid,
        abbreviation,
        schoolName
      };

      dispatch(doPutSchool(payload));
      history.push('/institution-management/schools');
    }
  };

  const handleDeleteModalOpen = () => {
    setModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setModalOpen(false);
  };

  const handleDelete = () => {
    dispatch(doDeleteSchool(school.uuid));
    setModalOpen(false);
    history.push('/institution-management/schools');
  };

  return (
    <div>
      <SchoolEdit
        handleChange={handleChange}
        abbreviation={abbreviation}
        schoolName={schoolName}
        hasErrors={hasErrors}
        handleUpdateSchool={handleUpdateSchool}
        handleDeleteModalOpen={handleDeleteModalOpen}
      />
      <DeleteModal
        type="School"
        modalOpen={modalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleDelete()}
      />
    </div>
  );
}

SchoolEditContainer.propTypes = {
  match: PropTypes.object
};

SchoolEditContainer.defaultProps = {
  match: {}
};
