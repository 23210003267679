import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_COHORT_SUCCESS,
  GET_APPLICANTS_BY_PROGRAM_SUCCESS,
  GET_COHORTS_SUCCESS,
  GET_COHORT_ASSESSMENTS_SUCCESS,
  GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS,
  GET_COHORT_ASSESSMENT_SCORES_SUCCESS,
  GET_COHORT_SUCCESS,
  GET_PROGRAM_COHORTS_SUCCESS,
  GET_RIGOR_HISTORICAL_REPORT_SUCCESS,
  GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS,
  GET_TRANSCRIPT_SUCCESS,
  GET_USERS_BY_SEARCH_ROLES_SUCCESS,
  GET_USER_SUCCESS,
  POST_COHORT_SUCCESS,
  POST_INSTITUTION_RIGOR_SUCCESS,
  PUT_COHORT_SUCCESS
} from '../actions-type';

class Cohort extends Model {
  static reducer(action, Cohort, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_COHORT_SUCCESS: {
        const cohort = { ...payload.cohort };
        Cohort.upsert(cohort);
        break;
      }
      case GET_COHORT_ASSESSMENTS_SUCCESS: {
        const { cohort_assessment_collections } = payload;

        cohort_assessment_collections.forEach(collection =>
          Cohort.upsert(collection.cohort)
        );

        break;
      }
      case GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS: {
        const { cohortAssessmentCollections } = payload;

        cohortAssessmentCollections.forEach(collection =>
          Cohort.upsert(collection.cohort)
        );

        break;
      }
      case GET_COHORTS_SUCCESS: {
        const cohorts = [...payload.cohorts];
        cohorts.forEach(cohort => Cohort.upsert(cohort));
        break;
      }
      case GET_COHORT_ASSESSMENT_SCORES_SUCCESS: {
        const cohort = { ...payload.cohort };
        Cohort.upsert(cohort);
        break;
      }
      case GET_PROGRAM_COHORTS_SUCCESS: {
        const cohorts = [...payload.cohorts];
        cohorts.forEach(cohort => Cohort.upsert(cohort));
        break;
      }
      case POST_COHORT_SUCCESS: {
        const cohort = { ...payload.cohort };
        Cohort.upsert(cohort);
        break;
      }
      case PUT_COHORT_SUCCESS: {
        const cohort = { ...payload.cohort };
        Cohort.upsert(cohort);
        break;
      }
      case DELETE_COHORT_SUCCESS: {
        const { cohortUuid } = { ...payload };
        Cohort.withId(cohortUuid).delete();
        break;
      }
      case GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS: {
        const programs = [...payload.school.programs];
        programs.forEach(program => {
          const cohorts = [...program.cohorts];
          cohorts.forEach(cohort => {
            const cohortCopy = { ...cohort };
            delete cohortCopy.user_cohorts;
            Cohort.upsert(cohort);
          });
        });
        break;
      }

      case GET_TRANSCRIPT_SUCCESS: {
        const cohort = { ...payload.cohort };
        Cohort.upsert(cohort);
        break;
      }
      case GET_USERS_BY_SEARCH_ROLES_SUCCESS: {
        if (payload?.cohorts?.length > 0) {
          const cohorts = [...payload.cohorts];
          cohorts.forEach(cohort => Cohort.upsert(cohort));
        }
        break;
      }

      case GET_APPLICANTS_BY_PROGRAM_SUCCESS: {
        const userCohorts = [...payload.userCohorts];

        if (userCohorts?.length > 0) {
          userCohorts.forEach(userCohort => {
            const { cohort } = userCohort;

            if (cohort) {
              const {
                uuid,
                name,
                startDate,
                endDate,
                createdAt,
                updatedAt,
                programUuid
              } = cohort;

              Cohort.upsert({
                uuid,
                name,
                startDate,
                endDate,
                createdAt,
                updatedAt,
                programUuid
              });
            }
          });
        }

        break;
      }
      case POST_INSTITUTION_RIGOR_SUCCESS: {
        if (payload?.cohorts?.length > 0) {
          const cohorts = [...payload.cohorts];
          cohorts.forEach(cohort => Cohort.upsert(cohort));
        }
        break;
      }

      case GET_RIGOR_HISTORICAL_REPORT_SUCCESS: {
        if (payload?.cohorts?.length > 0) {
          const cohorts = [...payload.cohorts];
          cohorts.forEach(cohort => Cohort.upsert(cohort));
        }
        break;
      }
      case GET_USER_SUCCESS: {
        const cohorts = [...payload.cohorts];
        cohorts.forEach(school => Cohort.upsert(school));
        break;
      }

      default:
        return session;
    }
  }
}

Cohort.fields = {
  uuid: attr(),
  name: attr(),
  startDate: attr(),
  endDate: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  programUuid: fk({
    to: 'Program',
    as: 'program',
    relatedName: 'cohorts'
  })
};

Cohort.modelName = 'Cohort';
Cohort.options = {
  idAttribute: 'uuid'
};

export default Cohort;
