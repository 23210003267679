import fetch from '../helpers/fetch';

export const getSectionPerformanceReport = (
  sectionUuid,
  reportType = 'default',
  assessmentTypes
) =>
  fetch(
    `/api/section/${sectionUuid}/performance-report?reportType=${reportType}&assessmentTypes=${JSON.stringify(
      assessmentTypes
    )}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });
