import {
  POST_COMPETENCY,
  POST_COMPETENCY_SUCCESS,
  POST_COMPETENCY_ERROR,
  PUT_COMPETENCY,
  PUT_COMPETENCY_SUCCESS,
  PUT_COMPETENCY_ERROR,
  PUT_COMPETENCY_MICROS,
  PUT_COMPETENCY_MICROS_SUCCESS,
  PUT_COMPETENCY_MICROS_ERROR,
  DELETE_COMPETENCY,
  DELETE_COMPETENCY_SUCCESS,
  DELETE_COMPETENCY_ERROR
} from '../actions-type';

const doPostCompetency = newCompetency => ({
  type: POST_COMPETENCY,
  payload: {
    newCompetency
  }
});

const doPostCompetencySuccess = data => ({
  type: POST_COMPETENCY_SUCCESS,
  payload: {
    createdCompetency: data.createdCompetency
  }
});

const doPostCompetencyError = error => ({
  type: POST_COMPETENCY_ERROR,
  payload: error
});

const doPutCompetency = competencyUpdates => ({
  type: PUT_COMPETENCY,
  payload: {
    competencyUpdates
  }
});

const doPutCompetencySuccess = data => ({
  type: PUT_COMPETENCY_SUCCESS,
  payload: {
    updatedCompetency: data.updatedCompetency
  }
});

const doPutCompetencyError = error => ({
  type: PUT_COMPETENCY_ERROR,
  payload: error
});

const doPutCompetencyAddMicros = (uuid, micros) => ({
  type: PUT_COMPETENCY_MICROS,
  payload: {
    uuid,
    micros
  }
});

const doPutCompetencyAddMicrosSuccess = data => ({
  type: PUT_COMPETENCY_MICROS_SUCCESS,
  payload: {
    updatedCompetency: data.updatedCompetency
  }
});

const doPutCompetencyAddMicrosError = error => ({
  type: PUT_COMPETENCY_MICROS_ERROR,
  payload: error
});

const doDeleteCompetency = uuid => ({
  type: DELETE_COMPETENCY,
  payload: {
    uuid
  }
});

const doDeleteCompetencySuccess = data => ({
  type: DELETE_COMPETENCY_SUCCESS,
  payload: {
    deletedCompetency: data.deletedCompetency
  }
});

const doDeleteCompetencyError = error => ({
  type: DELETE_COMPETENCY_ERROR,
  payload: error
});

export {
  doPostCompetency,
  doPostCompetencySuccess,
  doPostCompetencyError,
  doPutCompetency,
  doPutCompetencySuccess,
  doPutCompetencyError,
  doPutCompetencyAddMicros,
  doPutCompetencyAddMicrosSuccess,
  doPutCompetencyAddMicrosError,
  doDeleteCompetency,
  doDeleteCompetencySuccess,
  doDeleteCompetencyError
};
