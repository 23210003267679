const initialSectionGradeStatusState = {
  uuid: '',
  statusCommit: 'UNCOMMITTED',
  statusGrade: 'NOT_GRADED',
  justificationMessage: null,
  showValidationErrors: false
};

const sectionGradeStatusReducer = (state, action) => {
  switch (action.type) {
    case 'setExistingSection': {
      const { uuid, statusCommit, statusGrade } = action.payload;

      return {
        ...state,
        uuid,
        statusCommit,
        statusGrade
      };
    }
    case 'setStatusCommitted': {
      return { ...state, statusCommit: 'COMMITTED' };
    }
    case 'setStatusUncommitted':
      return { ...state, statusCommit: 'UNCOMMITTED' };
    case 'setJustificationMessage': {
      return {
        ...state,
        justificationMessage: action.payload.justificationMessage
      };
    }
    case 'clearForm':
      return initialSectionGradeStatusState;
    default: {
      throw new Error();
    }
  }
};

export { initialSectionGradeStatusState, sectionGradeStatusReducer };
