import React from 'react';
import PropTypes from 'prop-types';
import { ContainerResize, ContainerPage, ElevateLow } from '../Library/Layout';
import SyllabusFilter from './SyllabusFilter';
import SyllabusAssessmentContainer from './SyllabusAssessment/SyllabusAssessmentContainer';
import Header from '../Library/Header';

export default function CohortSyllabus({
  cohortUuid,
  programUuid,
  cohortAssessmentCollectionUuid,
  parentCohort,
  cohortTitle,
  cohortAssessmentCollectionTitle,
  cohortAssessments,
  filterSelection,
  sortSelection,
  handleFilterSelection,
  handleSortSelection
}) {
  let assessmentIndex = 0;

  return (
    <ContainerResize>
      <Header
        title="Cohort Assessments Syllabus"
        subtitle={`Cohort ${cohortTitle} ● ${cohortAssessmentCollectionTitle}`}
        backButtonLocation={`/program-management/cohort/${parentCohort?.uuid}/requirements`}
      />

      <ContainerPage>
        <ElevateLow>
          <SyllabusFilter
            filterSelection={filterSelection}
            sortSelection={sortSelection}
            handleFilterSelection={handleFilterSelection}
            handleSortSelection={handleSortSelection}
          />
          {cohortAssessments.map(item => {
            const itemNumber = assessmentIndex + 1;
            assessmentIndex += 1;

            return (
              <React.Fragment key={item.uuid}>
                <SyllabusAssessmentContainer
                  key={item.uuid}
                  itemNumber={itemNumber}
                  cohortUuid={cohortUuid}
                  programUuid={programUuid}
                  cohortAssessmentCollectionUuid={
                    cohortAssessmentCollectionUuid
                  }
                  cohortAssessmentUuid={item.uuid}
                />
              </React.Fragment>
            );
          })}
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
}

CohortSyllabus.propTypes = {
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  cohortAssessmentCollectionUuid: PropTypes.string,
  parentCohort: PropTypes.object,
  cohortTitle: PropTypes.string,
  cohortAssessmentCollectionTitle: PropTypes.string,
  cohortAssessments: PropTypes.array,
  filterSelection: PropTypes.string,
  sortSelection: PropTypes.string,
  handleFilterSelection: PropTypes.func,
  handleSortSelection: PropTypes.func
};

CohortSyllabus.defaultProps = {
  cohortUuid: '',
  programUuid: '',
  cohortAssessmentCollectionUuid: '',
  parentCohort: {},
  cohortTitle: '',
  cohortAssessmentCollectionTitle: '',
  cohortAssessments: [],
  filterSelection: '',
  sortSelection: '',
  handleFilterSelection: undefined,
  handleSortSelection: undefined
};
