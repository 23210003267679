import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Grid, TextField } from '@material-ui/core';
import { ButtonInline } from '@xcomp/xcomp-design-library';

import SelectUserFieldContainer from '../../../Library/SelectUserField/SelectUserFieldContainer';
import withValidation from '../../../Library/FieldWithError/FieldWithError';
import PartForm from '../PartForm/PartForm';
import { validateSectionNumber } from '../../../../helpers/validation/validateCourse';
import { validateSelectMultiUserField } from '../../../../helpers/validation/validateGeneric';
import { convertNullToArray } from '../../../../helpers/utilities';
import DebouncedTextField from '../../../Library/DebouncedTextField';
import DescriptionEditor from '../../../Library/DescriptionEditor/DescriptionEditor';

const FormSC = styled.form`
  width: 100%;
`;

const TextFieldSC = styled(DebouncedTextField)`
  && {
    width: 100%;
    padding-right: 2rem;
    margin-top: 0;
    margin-bottom: 1.5rem;

    .MuiInput-root-244 {
      height: 36px;
    }
  }
`;

const SectionNumberFieldSC = styled(TextField)`
  && {
    width: 100%;
    padding-right: 2rem;
    margin-top: 0;
    margin-bottom: 1.5rem;

    .MuiInput-root-244 {
      height: 36px;
    }
  }
`;

const AddPartButtonContainerSC = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SectionNumberFieldWithError = withValidation(SectionNumberFieldSC);
const SelectUserFieldWithError = withValidation(SelectUserFieldContainer);

const CourseSectionForm = props => {
  const {
    handleSectionInputChange,
    handlePartNumberChange,
    handleSectionDirectorChange,
    handlePartInputChange,
    handlePartDirectorChange,
    handlePartDelete,
    handleAddPart,
    hasErrors,
    courseId,
    uuid,
    sections,
    section,
    section_directors_assigned,
    parts,
    facultyUsers
  } = props;

  const faculty = facultyUsers.map(user => ({
    value: `${user.uuid}`,
    label: `${user.firstName} ${user.lastName}`
  }));

  const directors = section_directors_assigned.map(director => director.uuid);

  return (
    <FormSC style={{ width: '100%' }}>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <SectionNumberFieldWithError
            handleValidate={sectionNumber =>
              validateSectionNumber(sectionNumber, uuid, sections)
            }
            hasErrors={hasErrors}
            id="sectionNumber"
            label="Section Number*"
            value={Number(props.sectionNumber)}
            name="sectionNumber"
            onChange={event => handleSectionInputChange(event, uuid)}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              required: true,
              type: 'number'
            }}
          />
          <TextFieldSC
            id="courseId"
            label="Course ID"
            value={courseId}
            name="courseId"
            onChange={event => handleSectionInputChange(event, uuid)}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              required: true
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <SelectUserFieldWithError
            userSelect
            name="directors"
            handleValidate={validateSelectMultiUserField}
            hasErrors={hasErrors}
            fieldName="directors"
            label="Course section director(s)*"
            handleChange={event => handleSectionDirectorChange(event, uuid)}
            usersArray={faculty}
            values={convertNullToArray(directors)}
          />
          <Box height={24} />
          <DescriptionEditor
            editorstyle={{ height: '180px' }}
            descriptionFor="Section"
            section={section}
          />
        </Grid>
      </Grid>
      {parts
        .sort((a, b) => a.partNumber - b.partNumber)
        .map(part => (
          <PartForm
            key={part.uuid}
            sectionId={uuid}
            handlePartInputChange={handlePartInputChange}
            handlePartNumberChange={handlePartNumberChange}
            handlePartDirectorChange={handlePartDirectorChange}
            handlePartDelete={handlePartDelete}
            hasErrors={hasErrors}
            part={part}
            number={part.partNumber}
            usersArray={faculty}
          />
        ))}
      <AddPartButtonContainerSC>
        <ButtonInline onClick={() => handleAddPart(uuid)}>
          Add Part
        </ButtonInline>
      </AddPartButtonContainerSC>
    </FormSC>
  );
};

CourseSectionForm.propTypes = {
  handleSectionInputChange: PropTypes.func,
  handlePartNumberChange: PropTypes.func,
  handleSectionDirectorChange: PropTypes.func,
  handlePartInputChange: PropTypes.func,
  handlePartDirectorChange: PropTypes.func,
  handlePartDelete: PropTypes.func,
  handleAddPart: PropTypes.func,
  hasErrors: PropTypes.bool,
  courseId: PropTypes.string,
  uuid: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
  section: PropTypes.object,
  sectionNumber: PropTypes.number,
  section_directors_assigned: PropTypes.arrayOf(PropTypes.object),
  parts: PropTypes.arrayOf(PropTypes.object),
  facultyUsers: PropTypes.arrayOf(PropTypes.object)
};

CourseSectionForm.defaultProps = {
  handleSectionInputChange: undefined,
  handlePartNumberChange: undefined,
  handleSectionDirectorChange: undefined,
  handlePartDelete: undefined,
  handleAddPart: undefined,
  handlePartInputChange: undefined,
  handlePartDirectorChange: undefined,
  hasErrors: false,
  courseId: '',
  uuid: '',
  sections: [],
  section: {},
  sectionNumber: 1,
  section_directors_assigned: [],
  parts: [],
  facultyUsers: []
};

export default CourseSectionForm;
