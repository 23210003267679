import fetch from '../helpers/fetch';

const postUploadPartLegacyGrades = (partUuid, records, uploadDate) =>
  fetch(`/api/part/${partUuid}/legacy-grades/upload`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      partUuid,
      records,
      uploadDate
    })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deletePartLegacyGrades = partUuid =>
  fetch(`/api/part/${partUuid}/legacy-grades/delete/all`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

const postUploadSectionLegacyGrades = (sectionUuid, records, uploadDate) =>
  fetch(`/api/section/${sectionUuid}/legacy-grades/upload`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      sectionUuid,
      records,
      uploadDate
    })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteSectionLegacyGrades = sectionUuid =>
  fetch(`/api/section/${sectionUuid}/legacy-grades/delete/all`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  postUploadPartLegacyGrades,
  deletePartLegacyGrades,
  postUploadSectionLegacyGrades,
  deleteSectionLegacyGrades
};
