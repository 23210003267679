import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import _ from 'lodash';
import Scores from './Scores';
import ScoresUploadContainer from './ScoresUpload/ScoresUploadContainer';
import DeleteModal from '../../Library/Modal/DeleteModal/DeleteModal';
import { doDeleteScores } from '../../../redux/actions/scoreActions';
import { sortUsersByFullName } from '../../../helpers/utilities';

import {
  doGetAssessmentBlocks,
  doGetAssessmentBlockResults
} from '../../../redux/actions/assessmentBlockActions';
import { assessmentSelector } from '../../../redux/selectors/assessmentSelectors';
import { assessmentBlocksSelector } from '../../../redux/selectors/assessmentBlockSelectors';
import {
  cohortAssessmentScoresStepSelector,
  scoresByAssessment
} from '../../../redux/selectors/scoreSelectors';
import { cohortAssessmentCollectionSelector } from '../../../redux/selectors/cohortAssessmentSelectors';
import {
  doGetCohortAssessments,
  doGetCohortAssessmentScores
} from '../../../redux/actions/cohortAssessmentActions';
import { cohortSelector } from '../../../redux/selectors/cohortsSelectors';
import { doGetUserCohorts } from '../../../redux/actions/userCohortActions';
import { programFacultyUsersSelector } from '../../../redux/selectors/userProgramsSelectors';
import { studentRosterSelectorByCohortUuid } from '../../../redux/selectors/userCohortsSelectors';
import DownloadModal from '../../Library/Modal/DownloadModal';

const ScoresContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [openUploadScores, setOpenUploadScores] = useState(false);
  const [modalDelete, setDeleteModal] = useState(false);
  const [openDownloadScores, setOpenDownloadScores] = useState(false);

  const {
    cohortAssessmentUuid,
    cohortAssessmentCollectionUuid,
    cohortUuid,
    assessmentTypeUuid
  } = useParams();

  const { pathname } = history.location;

  const assessment = useSelector(
    state => assessmentSelector(state, cohortAssessmentUuid),
    _.isEqual
  );

  const assessmentCollection = useSelector(
    state =>
      cohortAssessmentCollectionSelector(state, cohortAssessmentCollectionUuid),
    _.isEqual
  );

  const parentCohort = useSelector(state => cohortSelector(state, cohortUuid));
  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  useEffect(() => {
    dispatch(doGetCohortAssessments(cohortUuid));
  }, [cohortUuid, dispatch]);

  const cohortTitle = _.get(parentCohort, 'name', '');
  const cohortAssessmentCollectionTitle = _.get(
    assessmentCollection,
    'title',
    ''
  );

  const assessmentScores = useSelector(
    state => scoresByAssessment(state, cohortAssessmentUuid),
    _.isEqual
  );

  const scores = useSelector(
    state =>
      cohortAssessmentScoresStepSelector(
        state,
        cohortAssessmentUuid,
        cohortUuid
      ),
    _.isEqual
  );

  const assessmentBlocks = useSelector(
    state => assessmentBlocksSelector(state, cohortAssessmentUuid),
    _.isEqual
  );

  const students = useSelector(state =>
    studentRosterSelectorByCohortUuid(state, cohortUuid)
  );

  const programFaculty = useSelector(
    state => programFacultyUsersSelector(state, selectedProgramUuid),
    _.isEqual
  );

  useEffect(() => {
    dispatch(doGetCohortAssessmentScores(cohortUuid, cohortAssessmentUuid));
    dispatch(doGetAssessmentBlockResults(cohortAssessmentUuid));
    dispatch(doGetAssessmentBlocks(cohortAssessmentUuid));
    dispatch(doGetUserCohorts(cohortUuid));
  }, [dispatch, cohortUuid, cohortAssessmentUuid]);

  const handleUploadScoresOpen = () => {
    setOpenUploadScores(true);
  };

  const handleUploadClose = () => {
    setOpenUploadScores(false);
  };

  const handleDeleteScoresOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteScoresClose = () => {
    setDeleteModal(false);
  };

  const handleDeleteScores = () => {
    setDeleteModal(false);
    dispatch(doDeleteScores(cohortAssessmentUuid));
  };

  const handleDownloadScoresOpen = () => {
    setOpenDownloadScores(true);
  };

  const handleDownloadScoresClose = () => {
    setOpenDownloadScores(false);
  };

  const sortedStudents = useMemo(
    () => [...students].sort((a, b) => sortUsersByFullName(a.user, b.user)),
    [students]
  );

  const sortedFaculty = useMemo(
    () =>
      [...programFaculty].sort((a, b) => sortUsersByFullName(a.user, b.user)),
    [programFaculty]
  );

  return (
    <div>
      <Scores
        assessmentBlocks={_.orderBy(assessmentBlocks, 'blockNumber')}
        assessmentType={assessment.assessmentType}
        assessmentTypeUuid={assessmentTypeUuid}
        assessmentUuid={cohortAssessmentUuid}
        cohortTitle={cohortTitle}
        cohortAssessmentCollectionUuid={cohortAssessmentCollectionUuid}
        programUuid={selectedProgramUuid}
        cohortUuid={cohortUuid}
        programFaculty={sortedFaculty}
        students={sortedStudents}
        cohortAssessmentCollectionTitle={cohortAssessmentCollectionTitle}
        handleDeleteScoresOpen={handleDeleteScoresOpen}
        handleUploadScoresOpen={handleUploadScoresOpen}
        handleDownloadScoresOpen={handleDownloadScoresOpen}
        pathname={pathname}
        scores={scores}
        assessmentScores={assessmentScores}
        scoreType={assessment.scoreType}
        title={assessment.title}
      />
      <ScoresUploadContainer
        openUploadScores={openUploadScores}
        assessmentUuid={cohortAssessmentUuid}
        handleUploadClose={handleUploadClose}
        cohortUuid={cohortUuid}
        cohortAssessmentUuid={cohortAssessmentUuid}
      />
      <DeleteModal
        modalOpen={modalDelete}
        type="scores"
        handleModalClose={handleDeleteScoresClose}
        handleDelete={handleDeleteScores}
      />
      {openDownloadScores &&
        <DownloadModal
          modalOpen={openDownloadScores}
          title="Download scores"
          description='Are you sure you want to download the scores under an Excel file format?'
          handleModalClose={handleDownloadScoresClose}
          downloadURL={`/api/scores/download-excel-scores?assessmentUuid=${cohortAssessmentUuid}&cohortUuid=${cohortUuid}`}
        />
      }
    </div>
  );
};

export default ScoresContainer;
