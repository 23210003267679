import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { doDeleteCohortAsssessmentCollection } from '../../../../redux/actions/cohortAssessmentActions';
import { useDelete } from '../../../Library/Modal/DeleteModal/useDelete';
import AssessmentCollectionRow from './AssessmentCollectionRow';

const AssessmentCollectionRowContainer = ({
  assessmentCollection,
  withTopBorder
}) => {
  const dispatch = useDispatch();

  const onDelete = () =>
    dispatch(
      doDeleteCohortAsssessmentCollection({
        uuid: assessmentCollection.uuid,
        cohortUuid: assessmentCollection.cohortUuid
      })
    );

  const [
    isDeleteModalOpen,
    handleDeleteClick,
    handleDeleteModalClose,
    handleDelete
  ] = useDelete(onDelete);

  return (
    <AssessmentCollectionRow
      assessmentCollection={assessmentCollection}
      isDeleteModalOpen={isDeleteModalOpen}
      handleDeleteClick={handleDeleteClick}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDelete={handleDelete}
      withTopBorder={withTopBorder}
    />
  );
};

AssessmentCollectionRowContainer.propTypes = {
  assessmentCollection: PropTypes.object.isRequired,
  withTopBorder: PropTypes.bool
};

AssessmentCollectionRowContainer.defaultProps = {
  withTopBorder: false
};

export default AssessmentCollectionRowContainer;
