import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import MenuItem from '@material-ui/core/MenuItem';

const FormControlSC = styled(FormControl)`
  && {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;

export default function FormSelect({
  label,
  name,
  value,
  options,
  handleChange,
  ...rest
}) {
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControlSC fullWidth={true} variant="outlined" {...rest}>
      <InputLabel shrink={true} ref={inputLabel} htmlFor={`${name}-helper`}>
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name={name}
            id={`${name}-helper`}
            notched={true}
          />
        }
        MenuProps={{ variant: 'menu' }}
        {...rest}
      >
        {options &&
          options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </FormControlSC>
  );
}

FormSelect.propTypes = {
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  handleChange: PropTypes.func
};

FormSelect.defaultProps = {
  label: '',
  multiple: false,
  name: '',
  value: '',
  options: [],
  handleChange: undefined
};
