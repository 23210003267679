import Decimal from 'decimal.js-light';
import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const selectTranscriptCognitiveUuid = ormCreateSelector(
  orm.TranscriptCognitive
);

export const selectTranscriptCognitive = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category, source) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category, source } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source
    );

    return transcriptRecords;
  }
);

export const selectTranscriptCognitiveByCategory = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category
    );

    return transcriptRecords;
  }
);

export const selectTranscriptCognitiveBySubject = createSelector(
  state => state,
  (
    _,
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    classificationUuid
  ) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    classificationUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      classificationUuid
    } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source &&
        tc.classificationUuid === classificationUuid
    );

    return transcriptRecords;
  }
);

export const selectTranscriptCognitiveBySkill = createSelector(
  state => state,
  (
    _,
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    classificationUuid
  ) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    classificationUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      classificationUuid
    } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source &&
        tc.classificationUuid === classificationUuid
    );

    return transcriptRecords;
  }
);

export const selectTranscriptCognitiveByScopeCategory = createSelector(
  state => state,
  (
    _,
    programUuid,
    userUuid,
    cohortUuid,
    category,

    classificationUuid
  ) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,

    classificationUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      category,

      classificationUuid
    } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.classificationUuid === classificationUuid
    );

    return transcriptRecords;
  }
);

export const selectTranscriptCognitiveInstitutions = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category, source) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category, source } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source
    );

    const institutions = _(transcriptRecords)
      .map(transcript => transcript.sourceInstitutionUuid)
      .uniq()
      .value();

    return institutions;
  }
);

export const selectTranscriptCognitiveByInstitutions = createSelector(
  state => state,
  (
    _,
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    classificationUuid,
    sourceInstitutionUuid
  ) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    classificationUuid,
    sourceInstitutionUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      classificationUuid,
      sourceInstitutionUuid
    } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.category === category &&
        tc.source === source &&
        tc.classificationUuid === classificationUuid &&
        tc.sourceInstitutionUuid === sourceInstitutionUuid
    );

    return transcriptRecords;
  }
);

export const selectCognitiveTotalCredits = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category, source) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category, source } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source
    );

    return  _.reduce(
      transcriptRecords,
      (total, transcript) => {
        const creditDecimal = new Decimal(transcript.credits);
        return total.add(creditDecimal);
      },

      new Decimal(0)
    ).val();

  }
);

export const selectSubjectCreditsProgram = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, classificationUuid) => ({
    programUuid,
    userUuid,
    cohortUuid,
    classificationUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      classificationUuid
    } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecordsProgram = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.source === 'Program' &&
        tc.classificationUuid === classificationUuid
    );

    let programCredits;

    if (transcriptRecordsProgram.length > 0) {
      programCredits = _.reduce(
        transcriptRecordsProgram,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      ).val();
    }

    return programCredits;
  }
);

export const selectSubjectCreditsMilestone = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, classificationUuid) => ({
    programUuid,
    userUuid,
    cohortUuid,
    classificationUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      classificationUuid
    } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecordsMilestone = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.source === 'Milestone' &&
        tc.classificationUuid === classificationUuid
    );

    let milestoneCredits;

    if (transcriptRecordsMilestone.length > 0) {
      milestoneCredits = _.reduce(
        transcriptRecordsMilestone,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      ).val();
    }

    return milestoneCredits;
  }
);

export const selectSubjectAverageScore = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, classificationUuid) => ({
    programUuid,
    userUuid,
    cohortUuid,
    classificationUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      classificationUuid
    } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.classificationUuid === classificationUuid
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalCredits)
        .toDecimalPlaces(2)
        .toString();

      return totalPercentage;
    } else {
      return undefined;
    }
  }
);

export const selectSubjectGrade = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, classificationUuid) => ({
    programUuid,
    userUuid,
    cohortUuid,
    classificationUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      classificationUuid
    } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.classificationUuid === classificationUuid
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore.div(totalCredits);

      const findGrade = _.find(letterGrades, schoolGrade => {
        const low = new Decimal(schoolGrade.rangeLow);
        const high = new Decimal(schoolGrade.rangeHigh);

        const conditionOne = totalPercentage.greaterThanOrEqualTo(low);
        const conditionTwo = totalPercentage.lessThanOrEqualTo(high);

        return conditionOne && conditionTwo;
      });
      return findGrade?.grade;
    } else {
      return undefined;
    }
  }
);

export const selectTranscriptCognitiveTerms = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category, source) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category, source } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source
    );

    return _(transcriptRecords)
      .sortBy([record => new Date(record.date)])
      .map(transcript => `${transcript.term}-${transcript.year}`)
      .uniq()
      .value();
  },
);

export const selectTranscriptCognitiveByTerm = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category, source, termYear) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    termYear
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      termYear
    } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source &&
        `${tc.term}-${tc.year}` === termYear
    );

    return transcriptRecords;
  }
);

export const selectTermCredits = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, source, termYear) => ({
    programUuid,
    userUuid,
    cohortUuid,
    source,
    termYear
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, source, termYear } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.source === source &&
        `${tc.term}-${tc.year}` === termYear
    );

    if (transcriptRecords.length > 0) {
      const total = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      ).val();

      return total;
    } else {
      return undefined;
    }
  }
);

export const selectTermAverageScore = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, source, termYear) => ({
    programUuid,
    userUuid,
    cohortUuid,
    source,
    termYear
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, source, termYear } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.source === source &&
        `${tc.term}-${tc.year}` === termYear
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalCredits)
        .toDecimalPlaces(2)
        .toString();

      return totalPercentage;
    } else {
      return undefined;
    }
  }
);

export const selectTermAverageScoreGrade = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, source, termYear) => ({
    programUuid,
    userUuid,
    cohortUuid,
    source,
    termYear
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, source, termYear } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.source === source &&
        `${tc.term}-${tc.year}` === termYear
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalCredits)
        .toDecimalPlaces(2);

      const findGrade = _.find(letterGrades, schoolGrade => {
        const low = new Decimal(schoolGrade.rangeLow);
        const high = new Decimal(schoolGrade.rangeHigh);

        const conditionOne = totalPercentage.greaterThanOrEqualTo(low);
        const conditionTwo = totalPercentage.lessThanOrEqualTo(high);

        return conditionOne && conditionTwo;
      });

      return findGrade?.grade;
    } else {
      return undefined;
    }
  }
);

export const selectTermCreditsCumulative = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, source, termYear) => ({
    programUuid,
    userUuid,
    cohortUuid,
    source,
    termYear
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, source, termYear } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const recordsByTermYear = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.source === source &&
        `${tc.term}-${tc.year}` === termYear
    );

    const recordsByTermYearSorted = _.sortBy(recordsByTermYear, [
      [
        record => {
          return new Date(record.date);
        }
      ]
    ]);

    const lastDate =
      recordsByTermYearSorted[recordsByTermYearSorted.length - 1].date;

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.source === source
    );

    const transcripts = transcriptRecords.filter(transcript => {
      const transcriptDate = moment(transcript.date);
      const lastTranscriptDate = moment(lastDate);
      return transcriptDate.isSameOrBefore(lastTranscriptDate);
    });

    if (transcripts.length > 0) {
      const total = _.reduce(
        transcripts,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      ).val();

      return total;
    } else {
      return undefined;
    }
  }
);

export const selectTermAvgScoreCumulative = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, source, termYear) => ({
    programUuid,
    userUuid,
    cohortUuid,
    source,
    termYear
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, source, termYear } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const recordsByTermYear = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.source === source &&
        `${tc.term}-${tc.year}` === termYear
    );

    const recordsByTermYearSorted = _.sortBy(recordsByTermYear, [
      [
        record => {
          return new Date(record.date);
        }
      ]
    ]);

    const lastDate =
      recordsByTermYearSorted[recordsByTermYearSorted.length - 1].date;

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.source === source
    );

    const transcripts = transcriptRecords.filter(transcript => {
      const transcriptDate = moment(transcript.date);
      const lastTranscriptDate = moment(lastDate);
      return transcriptDate.isSameOrBefore(lastTranscriptDate);
    });

    if (letterGrades?.length > 0 && transcripts?.length > 0) {
      const totalCredits = _.reduce(
        transcripts,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcripts,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalCredits)
        .toDecimalPlaces(2)
        .toString();

      return totalPercentage;
    } else {
      return undefined;
    }
  }
);

export const selectTermGradeCumulative = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, source, termYear) => ({
    programUuid,
    userUuid,
    cohortUuid,
    source,
    termYear
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, source, termYear } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const recordsByTermYear = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.source === source &&
        `${tc.term}-${tc.year}` === termYear
    );

    const recordsByTermYearSorted = _.sortBy(recordsByTermYear, [
      [
        record => {
          return new Date(record.date);
        }
      ]
    ]);

    const lastDate =
      recordsByTermYearSorted[recordsByTermYearSorted.length - 1].date;

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.source === source
    );

    const transcripts = transcriptRecords.filter(transcript => {
      const transcriptDate = moment(transcript.date);
      const lastTranscriptDate = moment(lastDate);
      return transcriptDate.isSameOrBefore(lastTranscriptDate);
    });

    if (letterGrades?.length > 0 && transcripts?.length > 0) {
      const totalCredits = _.reduce(
        transcripts,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcripts,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalCredits)
        .toDecimalPlaces(2);

      const findGrade = _.find(letterGrades, schoolGrade => {
        const low = new Decimal(schoolGrade.rangeLow);
        const high = new Decimal(schoolGrade.rangeHigh);

        const conditionOne = totalPercentage.greaterThanOrEqualTo(low);
        const conditionTwo = totalPercentage.lessThanOrEqualTo(high);

        return conditionOne && conditionTwo;
      });

      return findGrade?.grade;
    } else {
      return undefined;
    }
  }
);

export const selectOverallCognitiveCredits = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category
    );

    if (transcriptRecords.length > 0) {
      const total = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      ).val();

      return total;
    } else {
      return undefined;
    }
  }
);

export const selectOverallCognitiveGrade = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalCredits)
        .toDecimalPlaces(2);

      const findGrade = _.find(letterGrades, schoolGrade => {
        const low = new Decimal(schoolGrade.rangeLow);
        const high = new Decimal(schoolGrade.rangeHigh);

        const conditionOne = totalPercentage.greaterThanOrEqualTo(low);
        const conditionTwo = totalPercentage.lessThanOrEqualTo(high);

        return conditionOne && conditionTwo;
      });

      return findGrade?.grade;
    } else {
      return undefined;
    }
  }
);

export const selectOverallCognitiveAvgScore = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalCredits)
        .toDecimalPlaces(2)
        .toString();

      return totalPercentage;
    } else {
      return undefined;
    }
  }
);

export const selectInstitutionCredits = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, source, sourceInstitutionUuid) => ({
    programUuid,
    userUuid,
    cohortUuid,
    source,
    sourceInstitutionUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      source,
      sourceInstitutionUuid
    } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === 'Cognitive' &&
        tc.source === source &&
        tc.sourceInstitutionUuid === sourceInstitutionUuid
    );

    if (transcriptRecords?.length > 0) {
      const total = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      ).val();

      return total;
    } else {
      return undefined;
    }
  }
);

export const selectInstitutionAverageScore = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, source, sourceInstitutionUuid) => ({
    programUuid,
    userUuid,
    cohortUuid,
    source,
    sourceInstitutionUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      source,
      sourceInstitutionUuid
    } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === 'Cognitive' &&
        tc.source === source &&
        tc.sourceInstitutionUuid === sourceInstitutionUuid
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalCredits)
        .toDecimalPlaces(2)
        .toString();

      return totalPercentage;
    } else {
      return undefined;
    }
  }
);

export const selectInstitutionGrade = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, source, sourceInstitutionUuid) => ({
    programUuid,
    userUuid,
    cohortUuid,
    source,
    sourceInstitutionUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      source,
      sourceInstitutionUuid
    } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === 'Cognitive' &&
        tc.source === source &&
        tc.sourceInstitutionUuid === sourceInstitutionUuid
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore.div(totalCredits);

      const findGrade = _.find(letterGrades, schoolGrade => {
        const low = new Decimal(schoolGrade.rangeLow);
        const high = new Decimal(schoolGrade.rangeHigh);

        const conditionOne = totalPercentage.greaterThanOrEqualTo(low);
        const conditionTwo = totalPercentage.lessThanOrEqualTo(high);

        return conditionOne && conditionTwo;
      });

      return findGrade?.grade;
    } else {
      return undefined;
    }
  }
);

export const selectCognitiveCreditsBySource = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category, source) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category, source } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source
    );

    if (transcriptRecords.length > 0) {
      const total = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      ).val();

      return total;
    } else {
      return undefined;
    }
  }
);

export const selectCognitiveAvgScoreBySource = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category, source) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category, source } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalCredits)
        .toDecimalPlaces(2)
        .toString();

      return totalPercentage;
    } else {
      return undefined;
    }
  }
);

export const selectCognitiveGradeBySource = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category, source) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source
  }),
  (redux, paramaters) => {
    const { programUuid, userUuid, cohortUuid, category, source } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalCredits)
        .toDecimalPlaces(2);

      const findGrade = _.find(letterGrades, schoolGrade => {
        const low = new Decimal(schoolGrade.rangeLow);
        const high = new Decimal(schoolGrade.rangeHigh);

        const conditionOne = totalPercentage.greaterThanOrEqualTo(low);
        const conditionTwo = totalPercentage.lessThanOrEqualTo(high);

        return conditionOne && conditionTwo;
      });

      return findGrade?.grade;
    } else {
      return undefined;
    }
  }
);

export const selectInstitutionCreditsBySubject = createSelector(
  state => state,
  (
    _,
    programUuid,
    userUuid,
    cohortUuid,
    source,
    sourceInstitutionUuid,
    classificationUuid
  ) => ({
    programUuid,
    userUuid,
    cohortUuid,
    source,
    sourceInstitutionUuid,
    classificationUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      source,
      sourceInstitutionUuid,
      classificationUuid
    } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === 'Cognitive' &&
        tc.source === source &&
        tc.sourceInstitutionUuid === sourceInstitutionUuid &&
        tc.classificationUuid === classificationUuid
    );

    if (transcriptRecords?.length > 0) {
      const total = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      ).val();

      return total;
    } else {
      return undefined;
    }
  }
);

export const selectInstitutionGradeBySubject = createSelector(
  state => state,
  (
    _,
    programUuid,
    userUuid,
    cohortUuid,
    source,
    sourceInstitutionUuid,
    classificationUuid
  ) => ({
    programUuid,
    userUuid,
    cohortUuid,
    source,
    sourceInstitutionUuid,
    classificationUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      source,
      sourceInstitutionUuid,
      classificationUuid
    } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === 'Cognitive' &&
        tc.source === source &&
        tc.sourceInstitutionUuid === sourceInstitutionUuid &&
        tc.classificationUuid === classificationUuid
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore.div(totalCredits);

      const findGrade = _.find(letterGrades, schoolGrade => {
        const low = new Decimal(schoolGrade.rangeLow);
        const high = new Decimal(schoolGrade.rangeHigh);

        const conditionOne = totalPercentage.greaterThanOrEqualTo(low);
        const conditionTwo = totalPercentage.lessThanOrEqualTo(high);

        return conditionOne && conditionTwo;
      });

      return findGrade?.grade;
    } else {
      return undefined;
    }
  }
);

export const selectInstitutionAverageScoreBySubject = createSelector(
  state => state,
  (
    _,
    programUuid,
    userUuid,
    cohortUuid,
    source,
    sourceInstitutionUuid,
    classificationUuid
  ) => ({
    programUuid,
    userUuid,
    cohortUuid,
    source,
    sourceInstitutionUuid,
    classificationUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      source,
      sourceInstitutionUuid,
      classificationUuid
    } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === 'Cognitive' &&
        tc.source === source &&
        tc.sourceInstitutionUuid === sourceInstitutionUuid &&
        tc.classificationUuid === classificationUuid
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalCredits)
        .toDecimalPlaces(2)
        .toString();

      return totalPercentage;
    } else {
      return undefined;
    }
  }
);

export const selectCognitiveMilestoneAvgScoreByAttempt = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category, source, attempt) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    attempt
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      attempt
    } = paramaters;

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source &&
        tc.attempt === Number(attempt)
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalCredits)
        .toDecimalPlaces(2)
        .toString();

      return totalPercentage;
    } else {
      return undefined;
    }
  }
);

export const selectCognitiveMilestoneGradeByAttempt = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category, source, attempt) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    attempt
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      attempt
    } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const getProgram = ormCreateSelector(orm.Program);

    const program = getProgram(redux, programUuid);

    const schoolUuid = _.get(program, 'schoolUuid');

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source &&
        tc.attempt === Number(attempt)
    );

    if (letterGrades?.length > 0 && transcriptRecords?.length > 0) {
      const totalCredits = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      );

      const totalCreditScore = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          const scoreDecimal = new Decimal(transcript.score);
          const creditScoreMultiplied = creditDecimal.times(scoreDecimal);
          return total.add(creditScoreMultiplied);
        },

        new Decimal(0)
      );

      const totalPercentage = totalCreditScore
        .div(totalCredits)
        .toDecimalPlaces(2);

      const findGrade = _.find(letterGrades, schoolGrade => {
        const low = new Decimal(schoolGrade.rangeLow);
        const high = new Decimal(schoolGrade.rangeHigh);

        const conditionOne = totalPercentage.greaterThanOrEqualTo(low);
        const conditionTwo = totalPercentage.lessThanOrEqualTo(high);

        return conditionOne && conditionTwo;
      });

      return findGrade?.grade;
    } else {
      return undefined;
    }
  }
);

export const selectCognitiveMilestoneCreditsByAttempt = createSelector(
  state => state,
  (_, programUuid, userUuid, cohortUuid, category, source, attempt) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    attempt
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      attempt
    } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCognitive);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source &&
        tc.attempt === Number(attempt)
    );

    if (transcriptRecords.length > 0) {
      const total = _.reduce(
        transcriptRecords,
        (total, transcript) => {
          const creditDecimal = new Decimal(transcript.credits);
          return total.add(creditDecimal);
        },

        new Decimal(0)
      ).val();

      return total;
    } else {
      return undefined;
    }
  }
);
