import { v4 as uuidv4 } from 'uuid';

const initialGradeCollectionState = {
  gradeCollections: {},
  hasSetCollections: false,
  collectionsToDelete: [],
  showValidationErrors: false,
  distributeWeightEqually: false
};

const gradeCollectionsReducer = (state, action) => {
  switch (action.type) {
    case 'setExistingCollections': {
      const { gradeCollections, distributeWeightEqually } = action.payload;
      const newGradecollectionsObj = {};

      gradeCollections.forEach(collection => {
        newGradecollectionsObj[collection.uuid] = { ...collection };
      });
      return {
        ...state,
        hasSetCollections: true,
        distributeWeightEqually,
        gradeCollections: newGradecollectionsObj
      };
    }
    case 'setGradeCollectionTitle': {
      const { uuid, title } = action.payload;
      const { gradeCollections } = state;

      gradeCollections[uuid].title = title;
      return {
        ...state,
        gradeCollections
      };
    }
    case 'setDistributeWeightEqually': {
      const { distributeWeightEqually } = action.payload;

      return {
        ...state,
        distributeWeightEqually
      };
    }
    case 'setGradeCollectionWeight': {
      const { uuid, weight } = action.payload;
      const { gradeCollections } = state;

      const newGradeCollections = { ...gradeCollections };
      newGradeCollections[uuid].weight = weight;
      return {
        ...state,
        gradeCollections: newGradeCollections
      };
    }
    case 'addGradeCollection': {
      const { gradeCollections } = state;

      const newGradeCollections = { ...gradeCollections };
      const someUuid = uuidv4();
      newGradeCollections[someUuid] = {
        uuid: someUuid,
        isNew: true,
        title: 'Untitled',
        weight: 0
      };

      return {
        ...state,
        gradeCollections: newGradeCollections
      };
    }
    case 'removeGradeCollection': {
      const { uuid } = action.payload;
      const { gradeCollections, collectionsToDelete } = state;

      const newCollectionsToDelete = [...collectionsToDelete];
      if (!gradeCollections[uuid].isNew) {
        newCollectionsToDelete.push(uuid);
      }

      delete gradeCollections[uuid];

      return {
        ...state,
        gradeCollections,
        collectionsToDelete: newCollectionsToDelete
      };
    }
    case 'setValidationErrors': {
      const { showValidationErrors } = action.payload;
      return {
        ...state,
        showValidationErrors
      };
    }

    case 'clearForm':
      return initialGradeCollectionState;
    default: {
      throw new Error();
    }
  }
};

export { gradeCollectionsReducer, initialGradeCollectionState };
