import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Paper } from '@material-ui/core';
import { Heading2 } from '@xcomp/xcomp-design-library';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { atom, useAtom, useSetAtom } from 'jotai';
import GetAppIcon from '@material-ui/icons/GetApp';

import { DataCellValueTypes, DidacticDataCellValueTypes } from '../../../Reports/reportUtils';
import FilterMenuButton from './MenuButton';
import { PanelHeader } from '../../../Library/DashboardSC';
import fetch from '../../../../helpers/fetch';
import SwitchButton from './SwitchButton';
import DownloadModal from '../../../Library/Modal/DownloadModal';
import { ButtonDownload } from '../../../Syllabus/Scores/ScoresUpload/ScoresUpload';

const getDownloadURL = (partUuid, sectionUuid, filters, isSection, isDidactic) => { 
  const allAssessmentUuids = filters.assessments?.map(a => a.uuid).filter(a => a !== 'all');
  const allPartUuids = filters.parts?.map(a => a.uuid).filter(a => a !== 'all')
  const filteredAssessmentUuids = filters.filteredAssessments.filter(a => a !== 'all');
  const filteredPartUuids = filters.filteredParts.filter(a => a !== 'all');
  const assessmentBlockUuids = !filters.isExpanded && isSection ? allAssessmentUuids : filteredAssessmentUuids;
  const url = isSection
                ? `/api/section-attempt-report/${sectionUuid}/attempt-report-download`
                : `/api/part-attempt-report/${partUuid}/${sectionUuid}/attempt-report-download`;

  let partUuids = []; 
  if (isSection) {
    partUuids = filters.isExpanded ? allPartUuids : filteredPartUuids;
  }
  
  return `${url}?assessmentTechniqueUuids=${filters.filteredAssessmentTechniques}&assessmentTypeUuids=${filters.filteredAssessmentTypes}&studentUuids=${filters.filteredStudents.filter(a => a !== 'all')}&assessmentBlockUuids=${assessmentBlockUuids}&partUuids=${partUuids}&isAnonymized=${filters.isAnonymized}&isSection=${isSection}&isExpanded=${filters.isExpanded}&isDidactic=${isDidactic}`
}


const useStyles = makeStyles(() => ({
  paper: {
    padding: '20px',
    display: 'flex',
    gap: '15px',
    flexDirection: 'column'
  },
  panelHeader: {
    justifyContent: 'space-between',
    paddingBottom: '16px'
  },
  headerButtons: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  loadingBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  filtersBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '10px'
  },
  applyBox: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export const filtersDefaultValues = {
  assessmentTechniques: [],
  assessmentTypes: [],
  assessments: [],
  parts: [],
  students: [],
  isAnonymized: true,
  dataCellValueType: DataCellValueTypes.date,
  filteredStudents: [],
  filteredAssessments: [],
  filteredParts: [],
  filteredAssessmentTechniques: [],
  filteredAssessmentTypes: [],
  searchParams: '',
  selectedStartDate: '',
  selectedEndDate: '',
  fetched: false,
  isExpanded: true,
};

export const filtersAtom = atom(filtersDefaultValues);
export const reportDataAtom = atom(null);

const Filters = ({ trigger, isSection, isDidactic }) => {
  const classes = useStyles();
  const { partUuid, sectionUuid } = useParams();
  const [filters, setFilters] = useAtom(filtersAtom);
  const setReportData = useSetAtom(reportDataAtom);
  const [openDownloadScores, setOpenDownloadScores] = useState(false);

  const { data, isLoading } = useSWR(
    `/api/attempt-report-filters?partUuid=${partUuid}&sectionUuid=${sectionUuid}&sectionLevel=${isSection}&isDidactic=${isDidactic}`,
    url => fetch(url).then(res => res.json())
  );

  const handleDownloadScoresOpen = () => {
    setOpenDownloadScores(true);
  };

  const handleDownloadScoresClose = () => {
    setOpenDownloadScores(false);
  };

  const handleResetFilters = useCallback(() => {
    setFilters({
      ...filtersDefaultValues,
      dataCellValueType: isDidactic ? DidacticDataCellValueTypes.grade : DataCellValueTypes.date,
    });
  }, [setFilters, isDidactic]);

  const setDataCellValueType = useCallback(
    type => {
      setFilters({
        ...filters,
        dataCellValueType: type
      });
    },
    [filters, setFilters]
  );

  const setFilteredKeys = useCallback(
    (a, b) => uuid => {
      if (uuid.toLowerCase() === 'all') {
        setFilters({
          ...filters,
          [a]:
            filters[b].length === filters[a].length
              ? []
              : filters[b].map(a => a.uuid)
        });
        return;
      }

      const index = filters[a].indexOf(uuid);

      const newArray =
        index > -1 ? filters[a].filter(a => a !== uuid) : [...filters[a], uuid];

      setFilters({
        ...filters,
        [a]: newArray
      });
    },
    [filters, setFilters]
  );

  const setFilteredKeysByTypeAndTechnique = useCallback(
    (filteredKey, key) => uuid => {
      const index = filters[filteredKey].indexOf(uuid);

      const newArray =
        index > -1
          ? filters[filteredKey].filter(a => a !== uuid)
          : [...filters[filteredKey], uuid];

      // for now it's just one, but in the future we might need to add other filters and this logic will be needed
      const remainingFilters = [
        {
          filteredKey: 'filteredAssessmentTechniques',
          key: 'assessmentTechniqueUuid',
        },
        {
          filteredKey: 'filteredAssessmentTypes',
          key: 'assessmentTypeUuid',
        }
      ].filter(f => f.key !== key);

      const validPartUuids = new Set();
      const assessments = data?.assessments.filter(a => {
        const passesRemainingFilters = remainingFilters.map(rf => filters[rf.filteredKey].includes(a[rf.key])).every(Boolean);
        if ((newArray.includes(a[key]) && passesRemainingFilters) || a.uuid === 'all') {
          validPartUuids.add(a.partUuid || a.uuid);
          return true;
        }
        return false;
      });

      const parts = data?.parts.filter(p =>validPartUuids.has(p.uuid));

      setFilters({
        ...filters,
        [filteredKey]: newArray,
        assessments,
        parts,
        filteredAssessments: assessments.map(a => a.uuid),
        filteredParts: parts.map(p => p.uuid),
      });
    },
    [data?.assessments, data?.parts, filters, setFilters]
  );

  useEffect(() => {
    if (data && !filters.fetched) {
      const {
        assessmentTechniques,
        assessmentTypes,
        assessments,
        parts,
        students
      } = data || {};

      trigger({
        isDidactic,
        assessmentTechniqueUuids: assessmentTechniques?.map(a => a.uuid),
        assessmentTypeUuids: assessmentTypes?.map(a => a.uuid),
        studentUuids: students?.map(s => s.uuid)?.filter(a => a !== 'all'),
        assessmentBlockUuids: assessments
          ?.map(a => a.uuid)
          ?.filter(a => a !== 'all')
      }).then(reportData => setReportData(reportData));

      setFilters({
        ...filters,
        dataCellValueType: isDidactic ? DidacticDataCellValueTypes.grade : DataCellValueTypes.date,
        assessmentTechniques,
        assessmentTypes,
        assessments,
        parts,
        students,
        filteredAssessments: assessments?.map(a => a.uuid),
        filteredParts: parts?.map(a => a.uuid),
        filteredStudents: students?.map(s => s.uuid) || [],
        filteredAssessmentTechniques:
          assessmentTechniques?.map(a => a.uuid) || [],
        filteredAssessmentTypes: assessmentTypes?.map(a => a.uuid) || [],
        fetched: true
      });
    }
  }, [data, filters, setFilters, setReportData, trigger, isDidactic]);

  return (
    <Paper className={classes.paper}>
      <PanelHeader className={classes.panelHeader} paddingLeft="16px">
        <Heading2>Attempt Report Filters</Heading2>
        <Box className={classes.headerButtons} style={{}}>
          {isSection && (
            <SwitchButton
              isChecked={filters.isExpanded}
              label={
                filters.isExpanded
                  ? 'Collapse Collections'
                  : 'Expand Collections'
              }
              handleClick={() => {
                const isExpanded = !filters.isExpanded;
                setFilters({
                  ...filters,
                  isExpanded,
                  assessments: data.assessments,
                  parts: data.parts,
                  filteredParts: data.parts?.map(a => a.uuid),
                  filteredAssessments: data.assessments?.map(a => a.uuid),
                  filteredAssessmentTechniques: data.assessmentTechniques?.map(a => a.uuid) || [],
                  filteredAssessmentTypes: data.assessmentTypes?.map(a => a.uuid) || [],
                });
              }}
            />
          )}
          <SwitchButton
            isChecked={filters.isAnonymized}
            label={
              filters.isAnonymized
                ? 'Identify Students'
                : 'De-Identify Students'
            }
            handleClick={() =>
              setFilters({ ...filters, isAnonymized: !filters.isAnonymized })
            }
          />
        </Box>
      </PanelHeader>
      {isLoading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box className={classes.filtersBox}>
            {filters.isExpanded &&
              <FilterMenuButton
                onClick={setDataCellValueType}
                options={Object.values(isDidactic ? DidacticDataCellValueTypes : DataCellValueTypes)}
                buttonLabel={filters.dataCellValueType}
                selectedOption={filters.dataCellValueType}
                variant='contained'
              />
            }
            <FilterMenuButton
              onClick={setFilteredKeysByTypeAndTechnique(
                'filteredAssessmentTechniques', 'assessmentTechniqueUuid'
              )}
              options={filters.assessmentTechniques}
              buttonLabel={`Assessment Techniques (${filters.filteredAssessmentTechniques.length})`}
              selectedOptions={filters.filteredAssessmentTechniques}
              displayCheckbox
              variant='contained'
            />
            <FilterMenuButton
              onClick={setFilteredKeysByTypeAndTechnique(
                'filteredAssessmentTypes', 'assessmentTypeUuid'
              )}
              options={filters.assessmentTypes}
              buttonLabel={`Assessment Types (${filters.filteredAssessmentTypes.length})`}
              selectedOptions={filters.filteredAssessmentTypes}
              displayCheckbox
              variant='contained'
            />
          </Box>
          <Box className={classes.filtersBox}>
            <FilterMenuButton
              onClick={setFilteredKeys('filteredStudents', 'students')}
              options={filters.students}
              isAnonymized={filters.isAnonymized}
              buttonLabel={`Students (${
                filters.filteredStudents.includes('all')
                  ? filters.filteredStudents.length - 1
                  : filters.filteredStudents.length
              })`}
              selectedOptions={filters.filteredStudents}
              displaySearch
              displayCheckbox
              maxWidth="400px"
            />
            {filters.isExpanded 
              ?
                <FilterMenuButton
                  onClick={setFilteredKeys('filteredAssessments', 'assessments')}
                  options={filters.assessments}
                  buttonLabel={`Assessments (${
                    filters.filteredAssessments.includes('all')
                      ? filters.filteredAssessments.length - 1
                      : filters.filteredAssessments.length
                  })`}
                  selectedOptions={filters.filteredAssessments}
                  displaySearch
                  displayCheckbox
                  maxWidth="400px"
                />
              :
                <FilterMenuButton
                  onClick={setFilteredKeys('filteredParts', 'parts')}
                  options={filters.parts}
                  buttonLabel={`Parts (${
                    filters.filteredParts.includes('all')
                      ? filters.filteredParts.length - 1
                      : filters.filteredParts.length
                  })`}
                  selectedOptions={filters.filteredParts}
                  displaySearch
                  displayCheckbox
                  maxWidth="400px"
                />
            }
          </Box>
        </>
      )}
      <Box className={classes.applyBox}>
        <Button onClick={handleResetFilters}>Reset filters</Button>
        <ButtonDownload onClick={handleDownloadScoresOpen}>
          <GetAppIcon />
          Download
        </ButtonDownload>
      </Box>
      {openDownloadScores &&
        <DownloadModal
          modalOpen={openDownloadScores}
          title="Download report"
          description='Are you sure you want to download the report under an Excel file format?'
          handleModalClose={handleDownloadScoresClose}
          downloadURL={getDownloadURL(partUuid, sectionUuid, filters, isSection, isDidactic)}
        />
      }
    </Paper>
  );
};

Filters.propTypes = {
  trigger: PropTypes.func,
  isSection: PropTypes.bool,
  isDidactic: PropTypes.bool,
};

Filters.defaultProps = {
  trigger: () => {},
  isSection: false,
  isDidactic: false,
};

export default Filters;
