import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import moment from 'moment';
import _ from 'lodash';
import { orm } from '../models';

export const academicYearSelectorByMatchParams = createSelector(
  state => state,
  (_, academicYearUuid) => academicYearUuid,
  (redux, academicYearUuid) => {
    const academicYearSelectorORM = ormCreateSelector(
      orm.AcademicYear,
      orm.AcademicYear.terms,
      (academicYear, terms) => {
        if (academicYear && terms) {
          const sortTerms = _.orderBy(terms, ['period'], ['asc']);

          return {
            ...academicYear,
            terms: sortTerms
          };
        } else {
          return undefined;
        }
      }
    );

    const academicYear = academicYearSelectorORM(redux, academicYearUuid);

    return academicYear;
  }
);

export const academicYearsSelectorBySchool = createSelector(
  state => state,
  state => state.userState.selectedSchoolUuid,
  (redux, selectedSchoolUuid) => {
    const academicYearsSelectorORM = ormCreateSelector(orm.AcademicYear);

    const academicYears = academicYearsSelectorORM(redux).filter(
      ay => ay.schoolUuid === selectedSchoolUuid
    );

    const aysSorted = academicYears.sort(
      (a, b) => moment(b.startDate) - moment(a.startDate)
    );

    return aysSorted;
  }
);

export const selectAcademicYear = ormCreateSelector(orm.AcademicYear);
