import React from 'react';
import PropTypes from 'prop-types';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import Header from '../../Library/Header';
import {
  ContainerResize,
  ContainerPage,
  ContainerActions,
  ElevateLow
} from '../../Library/Layout';
import CohortUserDetails from './CohortUserDetails';
import CohortUserRoles from './CohortUserRoles';

export default function CohortEditRole({
  cohort,
  userUuid,
  cohortRoles,
  hasErrors,
  handleFormRoleChange,
  handleFormRoleDateChange,
  handleCohortRoleOpen,
  handleDeleteModalOpen,
  handleUpdateUserCohortRoles
}) {
  return (
    <ContainerResize>
      <Header title="Cohort Roles" subtitle="Manage user roles" />
      <ContainerActions>
        <ButtonPrimary onClick={handleUpdateUserCohortRoles}>
          Save
        </ButtonPrimary>
      </ContainerActions>
      <ContainerPage paddingTop="50px">
        <ElevateLow>
          <CohortUserDetails userUuid={userUuid} />
        </ElevateLow>
        <ElevateLow>
          <CohortUserRoles
            cohort={cohort}
            cohortRoles={cohortRoles}
            hasErrors={hasErrors}
            handleFormRoleChange={handleFormRoleChange}
            handleFormRoleDateChange={handleFormRoleDateChange}
            handleCohortRoleOpen={handleCohortRoleOpen}
            handleDeleteModalOpen={handleDeleteModalOpen}
          />
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
}

CohortEditRole.propTypes = {
  cohort: PropTypes.object,
  userUuid: PropTypes.string,
  cohortRoles: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleFormRoleChange: PropTypes.func,
  handleFormRoleDateChange: PropTypes.func,
  handleCohortRoleOpen: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func,
  handleUpdateUserCohortRoles: PropTypes.func
};

CohortEditRole.defaultProps = {
  cohort: {
    uuid: '',
    program: { uuid: '', name: '', school: { uuid: '', schoolName: '' } }
  },
  userUuid: '',
  cohortRoles: [],
  hasErrors: false,
  handleFormRoleChange: undefined,
  handleFormRoleDateChange: undefined,
  handleCohortRoleOpen: undefined,
  handleDeleteModalOpen: undefined,
  handleUpdateUserCohortRoles: undefined
};
