import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import Modal from '../../../../Library/Modal/Modal';

const Main = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;

  h3 {
    margin-bottom: 0.5rem;
  }
  em {
    display: block;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const LearningObjectiveDelete = ({ openModal, handleDeleteClose }) => (
  <div>
    <Modal
      aria-labelledby="learning-objective-delete-title"
      aria-describedby="learning-objective-delete-description"
      open={openModal}
      onClose={() => handleDeleteClose('cancel')}
    >
      <Main>
        <h3>Are you sure you want delete?</h3>
        <em>Save to apply changes.</em>
        <Actions>
          <ButtonInline onClick={() => handleDeleteClose('yes')}>
            Yes
          </ButtonInline>
          <ButtonInline onClick={() => handleDeleteClose('no')}>
            No
          </ButtonInline>
        </Actions>
      </Main>
    </Modal>
  </div>
);

LearningObjectiveDelete.propTypes = {
  openModal: PropTypes.bool,
  handleDeleteClose: PropTypes.func
};

LearningObjectiveDelete.defaultProps = {
  openModal: false,
  handleDeleteClose: undefined
};

export default LearningObjectiveDelete;
