import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import UserProfileCredentials from './UserProfileCredentials';
import { doGetDegreeLevels } from '../../../../redux/actions/sourceActions';
import { doGetCredentials } from '../../../../redux/actions/credentialActions';
import { userActiveSelector } from '../../../../redux/selectors/usersSelectors';
import { selectCredentials } from '../../../../redux/selectors/credentialSelectors';

export default function UserProfileCredentialsContainer() {
  const dispatch = useDispatch();

  const [addCredential, setAddCredential] = useState(false);
  const [credentialEditing, setCredentialEditing] = useState(false);

  const user = useSelector(state => userActiveSelector(state), _.isEqual);

  const { uuid } = user;

  const credentials = useSelector(state => {
    if (uuid) {
      return selectCredentials(state, uuid);
    } else {
      return [];
    }
  });

  useEffect(() => {
    dispatch(doGetDegreeLevels());
  }, [dispatch]);

  useEffect(() => {
    if (uuid) {
      dispatch(doGetCredentials(uuid));
    }
  }, [dispatch, uuid]);

  const handleOpenAdd = () => {
    setAddCredential(true);
  };

  const handleCloseAdd = () => {
    setAddCredential(false);
  };

  const handleCredentialEditing = uuid => {
    setCredentialEditing(uuid);
  };

  return (
    <UserProfileCredentials
      credentialEditing={credentialEditing}
      credentials={credentials}
      userUuid={user.uuid}
      addCredential={addCredential}
      handleOpenAdd={handleOpenAdd}
      handleCloseAdd={handleCloseAdd}
      handleCredentialEditing={handleCredentialEditing}
    />
  );
}

UserProfileCredentialsContainer.propTypes = {};

UserProfileCredentialsContainer.defaultProps = {};
