import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { topicSelector } from '../../../../redux/selectors/topicSelectors';

export default function TopicData({ topicUuid }) {
  const topic = useSelector(
    state => topicSelector(state, topicUuid),
    _.isEqual
  );

  return (
    <>
      <Tooltip arrow title={`${topic.title}`} placement="top">
        <span>{topic.title && topic.title !== '' ? topic.title : '- -'}</span>
      </Tooltip>
    </>
  );
}

TopicData.propTypes = {
  topicUuid: PropTypes.string
};

TopicData.defaultProps = {
  topicUuid: undefined
};
