import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectInstitutionCreditsBySubject } from '../../../../redux/selectors/transcriptCognitiveSelectors';

export default function InstitutionCreditsBySubject({
  programUuid,
  userUuid,
  cohortUuid,
  source,
  sourceInstitutionUuid,
  classificationUuid
}) {
  const cognitiveCredits = useSelector(state =>
    selectInstitutionCreditsBySubject(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      source,
      sourceInstitutionUuid,
      classificationUuid
    )
  );

  const credits = cognitiveCredits || 'N/A';

  return <>{credits}</>;
}

InstitutionCreditsBySubject.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  source: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string,
  classificationUuid: PropTypes.string
};

InstitutionCreditsBySubject.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  source: undefined,
  sourceInstitutionUuid: undefined,
  classificationUuid: undefined
};
