import React from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js-light';
import PartFacultyReportAssessmentDetail from './PartFacultyReportAssessmentDetail';

const PartFacultyReportAssessmentDetailContainer = ({
  assessment,
  assessmentCumulativePotentialScore,
  partFacultyTotalPotentialScore,
  partFacultyTotalRelativeScore
}) => {
  const assessmentRelativePercentage = new Decimal(
    partFacultyTotalPotentialScore
  ).greaterThan(0)
    ? new Decimal(partFacultyTotalRelativeScore)
        .dividedBy(partFacultyTotalPotentialScore)
        .times(100)
        .toFixed(2)
    : '0.00';
  const assessmentParticipationPercentage = new Decimal(
    partFacultyTotalPotentialScore
  ).greaterThan(0)
    ? new Decimal(partFacultyTotalPotentialScore)
        .dividedBy(assessmentCumulativePotentialScore)
        .times(100)
        .toFixed(2)
    : '0.00';

  const assessementLabel = `${assessment.title} ● ${assessment.assessment_type?.name} ● ${assessment.scoreType}`;

  return new Decimal(partFacultyTotalPotentialScore).greaterThan(0) ? (
    <PartFacultyReportAssessmentDetail
      assessmentLabel={assessementLabel}
      assessmentParticipationPercentage={assessmentParticipationPercentage}
      assessmentRelativePercentage={assessmentRelativePercentage}
      partFacultyTotalPotentialScore={partFacultyTotalPotentialScore}
      partFacultyTotalRelativeScore={partFacultyTotalRelativeScore}
    />
  ) : null;
};

PartFacultyReportAssessmentDetailContainer.propTypes = {
  assessment: PropTypes.object,
  assessmentCumulativePotentialScore: PropTypes.string,
  partFacultyTotalPotentialScore: PropTypes.string,
  partFacultyTotalRelativeScore: PropTypes.string
};
PartFacultyReportAssessmentDetailContainer.defaultProps = {
  assessment: {},
  assessmentCumulativePotentialScore: '0.00',
  partFacultyTotalPotentialScore: '',
  partFacultyTotalRelativeScore: ''
};

export default PartFacultyReportAssessmentDetailContainer;
