import React from 'react';
import PropTypes from 'prop-types';

import { ButtonOutline } from '@xcomp/xcomp-design-library';
import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import { optionsSyllabus } from '../../helpers/options';
import FormSelect from '../Library/FormSelect';
import { Label } from '../Library/SyllabusComponents';

const Layout = styled.div`
  padding-left: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const SortButton = styled(({ isSelected, ...rest }) => (
  <ButtonOutline {...rest} />
))`
  && {
    width: 100%;

    ${props =>
      props.isSelected &&
      css`
        svg {
          color: ${props => props.theme.colors.primary};
        }
      `}
  }
`;

const SortButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  height: 100%;

  .negativeBottomMargin {
    margin-bottom: -12px;
  }
`;

export default function SyllabusFilter({
  filterSelection,
  sortSelection,
  handleFilterSelection,
  handleSortSelection
}) {
  const onSortAscending = () => handleSortSelection('ascending');
  const onSortDescending = () => handleSortSelection('descending');

  return (
    <Layout>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <FormSelect
            // select={true}
            name="filterType"
            label="Filter By"
            value={filterSelection}
            handleChange={event => handleFilterSelection(event)}
            options={optionsSyllabus}
          />
        </Grid>
        <Grid item xs={3}>
          <SortButtonContainer>
            <Label className="negativeBottomMargin">Sort By Date</Label>
            {sortSelection === 'ascending' ? (
              <SortButton onClick={onSortDescending}>Old to New</SortButton>
            ) : (
              <SortButton onClick={onSortAscending}>New to Old</SortButton>
            )}
          </SortButtonContainer>
          {/* <Select
            select={true}
            name="sortDate"
            label="Sort By"
            value={sortSelection}
            handleChange={event => handleSortSelection(event)}
            options={optionsDateRange}
          /> */}
        </Grid>
      </Grid>
    </Layout>
  );
}

SyllabusFilter.propTypes = {
  filterSelection: PropTypes.string,
  sortSelection: PropTypes.string,
  handleFilterSelection: PropTypes.func,
  handleSortSelection: PropTypes.func
};

SyllabusFilter.defaultProps = {
  filterSelection: '',
  sortSelection: '',
  handleFilterSelection: undefined,
  handleSortSelection: undefined
};
