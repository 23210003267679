export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const GET_USER_ACTIVE = 'GET_USER_ACTIVE';
export const GET_USER_ACTIVE_SUCCESS = 'GET_USER_ACTIVE_SUCCESS';
export const GET_USER_ACTIVE_REJECT = 'GET_USER_ACTIVE_REJECT';
export const GET_USER_ACTIVE_ERROR = 'GET_USER_ACTIVE_ERROR';

export const POST_USER = 'POST_USER';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_ERROR = 'POST_USER_ERROR';

export const PUT_USER = 'PUT_USER';
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS';
export const PUT_USER_ERROR = 'PUT_USER_ERROR';

export const PUT_USER_PROFILE = 'PUT_USER_PROFILE';
export const PUT_USER_PROFILE_SUCCESS = 'PUT_USER_PROFILE_SUCCESS';
export const PUT_USER_PROFILE_ERROR = 'PUT_USER_PROFILE_ERROR';

export const GET_USERS_BY_SEARCH = 'GET_USERS_BY_SEARCH';
export const GET_USERS_BY_SEARCH_SUCCESS = 'GET_USERS_BY_SEARCH_SUCCESS';
export const GET_USERS_BY_SEARCH_ERROR = 'GET_USERS_BY_SEARCH_ERROR';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const SET_SELECTED_INSTITUTION_ROLE_FEATURE =
  'SET_SELECTED_INSTITUTION_ROLE_FEATURE';
export const SET_SELECTED_SCHOOL_ROLE_FEATURE =
  'SET_SELECTED_SCHOOL_ROLE_FEATURE';
export const SET_SELECTED_DEPARTMENT_ROLE_FEATURE =
  'SET_SELECTED_DEPARTMENT_ROLE_FEATURE';
export const SET_SELECTED_PROGRAM_ROLE_FEATURE =
  'SET_SELECTED_PROGRAM_ROLE_FEATURE';
export const SET_CURRENT_ROLE = 'SET_CURRENT_ROLE';
export const SET_SELECTED_HOME = 'SET_SELECTED_HOME';

export const GET_USERS_BY_SEARCH_INSTITUTION_ID =
  'GET_USERS_BY_SEARCH_INSTITUTION_ID';
export const GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS =
  'GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS';
export const GET_USERS_BY_SEARCH_INSTITUTION_ID_ERROR =
  'GET_USERS_BY_SEARCH_INSTITUTION_ID_ERROR';

export const POST_UPLOAD_USERS = 'POST_UPLOAD_USERS';
export const POST_UPLOAD_USERS_SUCCESS = 'POST_UPLOAD_USERS_SUCCESS';
export const POST_UPLOAD_USERS_ERROR = 'POST_UPLOAD_USERS_ERROR';

export const POST_CHECK_USERS = 'POST_CHECK_USERS';
export const POST_CHECK_USERS_SUCCESS = 'POST_CHECK_USERS_SUCCESS';
export const POST_CHECK_USERS_ERROR = 'POST_CHECK_USERS_ERROR';
export const POST_CHECK_USERS_RESET = 'POST_CHECK_USERS_RESET';

export const PUT_USER_ROLES_ERROR = 'PUT_USER_ROLES_ERROR';
export const PUT_USER_ROLES_SUCCESS = 'PUT_USER_ROLES_SUCCESS';
export const PUT_USER_ROLES = 'PUT_USER_ROLES';

export const POST_USER_ROLE_ERROR = 'POST_USER_ROLE_ERROR';
export const POST_USER_ROLE_SUCCESS = 'POST_USER_ROLE_SUCCESS';
export const POST_USER_ROLE = 'POST_USER_ROLE';
export const POST_USER_ROLE_RESET = 'POST_USER_ROLE_RESET';

export const GET_USERS_BY_SEARCH_ROLES = 'GET_USERS_BY_SEARCH_ROLES';
export const GET_USERS_BY_SEARCH_ROLES_SUCCESS =
  'GET_USERS_BY_SEARCH_ROLES_SUCCESS';
export const GET_USERS_BY_SEARCH_ROLES_ERROR =
  'GET_USERS_BY_SEARCH_ROLES_ERROR';

export const POST_USER_APP_ID = 'POST_USER_APP_ID';
export const POST_USER_APP_ID_SUCCESS = 'POST_USER_APP_ID_SUCCESS';
export const POST_USER_APP_ID_ERROR = 'POST_USER_APP_ID_ERROR';
export const POST_USER_APP_ID_RESET = 'POST_USER_APP_ID_RESET';

export const DELETE_APP_ID = 'DELETE_APP_ID';
export const DELETE_APP_ID_SUCCESS = 'DELETE_APP_ID_SUCCESS';
export const DELETE_APP_ID_ERROR = 'DELETE_APP_ID_ERROR';
export const DELETE_APP_ID_RESET = 'DELETE_APP_ID_RESET';
