import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import { selectGradeResultPart } from '../../../../redux/selectors/calculatedGradeResultSelectors';
import { selectLetterGradesBySchool } from '../../../../redux/selectors/letterGradeSelectors';

export default function GradeResultPartGrade({ userUuid, partUuid }) {
  const result = useSelector(state =>
    selectGradeResultPart(state, userUuid, partUuid)
  );

  const schoolUuid = useSelector(state => state.userState.selectedSchoolUuid);

  const letterGrades = useSelector(state =>
    selectLetterGradesBySchool(state, schoolUuid)
  );

  const mapGrades = _(letterGrades)
    .map(record => ({
      ...record,
      rangeLow: Number(record.rangeLow),
      rangeHigh: Number(record.rangeHigh)
    }))
    .value();

  const calculatedGrade = _.get(result, 'calculatedGrade');

  const findGrade = _.find(mapGrades, schoolGrade => {
    if (calculatedGrade) {
      const low = new Decimal(schoolGrade.rangeLow);
      const high = new Decimal(schoolGrade.rangeHigh);

      const conversionPercentage = new Decimal(calculatedGrade);

      const conditionOne = conversionPercentage.greaterThanOrEqualTo(low);
      const conditionTwo = conversionPercentage.lessThanOrEqualTo(high);

      return conditionOne && conditionTwo;
    } else {
      return false;
    }
  });

  return <>{findGrade?.grade || '- -'}</>;
}

GradeResultPartGrade.propTypes = {
  userUuid: PropTypes.string,
  partUuid: PropTypes.string
};

GradeResultPartGrade.defaultProps = {
  userUuid: undefined,
  partUuid: undefined
};
