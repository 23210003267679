import {
  CLEAR_CREATED,
  PATCH_PART_CALCULATE_GRADE,
  PATCH_SECTION_CALCULATE_GRADE,
  PATCH_SECTION_CALCULATE_GRADE_ERROR,
  PATCH_SECTION_CALCULATE_GRADE_SUCCESS,
  PUT_SECTION_STATUS_COMMIT_SUCCESS
} from '../actions-type';

export const INITIAL_STATE = {
  isLoading: false,
  invalid: false,
  successfullyCommitted: false,
  successfullyGraded: false,
  missingScoresError: false,
  errorMessage: ''
};

const gradingStatusReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PATCH_PART_CALCULATE_GRADE: {
      return {
        ...state,
        isLoading: true,
        invalid: false
      };
    }
    case PATCH_SECTION_CALCULATE_GRADE: {
      return {
        ...state,
        isLoading: true,
        invalid: false
      };
    }
    case PATCH_SECTION_CALCULATE_GRADE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        invalid: false,
        successfullyGraded: true
      };
    }
    case PUT_SECTION_STATUS_COMMIT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        invalid: false,
        successfullyCommitted: true
      };
    }
    case PATCH_SECTION_CALCULATE_GRADE_ERROR: {
      return {
        ...state,
        isLoading: false,
        invalid: true,
        successfullyGraded: false
      };
    }
    case CLEAR_CREATED: {
      return {
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
};

export default gradingStatusReducer;
