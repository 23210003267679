import React from 'react';
import PropTypes from 'prop-types';
import { Caption, Body1 } from '@xcomp/xcomp-design-library';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { commitmentLogSelector } from '../../../redux/selectors/commitmentLogSelectors';
import AccordionRowContainer from '../../Library/AccordionRow/AccordionRowContainer';

const CommitmentLogAccordion = styled(
  ({ withBottomBorder, withTopBorder, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  width: 100%;

  .commitment_message {
    padding: 0.875rem 0;
    span {
      color: ${props => props.theme.fontColors.dark};
    }
  }
`;

const Layout = styled.div`
  width: 100%;
`;

const LogDetails = styled.div`
  width: 100%;
  padding-left: 64px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-rigth: 1rem;
`;

const DetailRow = styled(({ minWidth, ...rest }) => <div {...rest} />)`
  width: 15%;
  min-width: ${props => props.minWidth || '250px'};
  padding: 0.875rem 1rem 1.125rem;

  span,
  p {
    margin: 0;
  }
`;

const formatCommitmentDate = date => {
  const thisDate = parseISO(date);
  const formatted = thisDate
    ? `${format(thisDate, 'MM/dd/yyyy')} at ${format(thisDate, 'ppp')}`
    : '';

  return formatted;
};

const getFullName = user => {
  if (user.marriedLastName) {
    return `${user.firstName} ${user.marriedLastName}`;
  }
  return `${user.firstName} ${user.lastName}`;
};

const CommitmentLog = ({ logUuid }) => {
  const commitmentLog = useSelector(
    state => commitmentLogSelector(state, logUuid),
    _.isEqual
  );

  return (
    <Layout>
      <CommitmentLogAccordion
        columnOne={commitmentLog?.summary}
        columnOneClassName="commitment_message"
        columnGutters="1.5rem"
        height="auto"
        withBottomBorder
      >
        <LogDetails>
          <DetailRow minWidth="100px">
            <Caption>Type:</Caption>
            <Body1>Assessment Student Grade</Body1>
          </DetailRow>
          <DetailRow>
            <Caption minWidth="100px">User:</Caption>
            <Body1>{getFullName(commitmentLog.user)}</Body1>
          </DetailRow>

          {commitmentLog.statusCommit === 'UNCOMMITTED' && (
            <DetailRow minWidth="350px">
              <Caption>Justification:</Caption>
              <Body1>{commitmentLog.justificationMessage}</Body1>
            </DetailRow>
          )}
        </LogDetails>
        <LogDetails>
          <DetailRow minWidth="350px">
            <Caption>Assessment:</Caption>
            <Body1>{commitmentLog.assessment.title}</Body1>
            <Body1>{commitmentLog.statusCommit}</Body1>
            <Body1>{formatCommitmentDate(commitmentLog.createdAt)}</Body1>
          </DetailRow>
        </LogDetails>
      </CommitmentLogAccordion>
    </Layout>
  );
};

CommitmentLog.propTypes = {
  logUuid: PropTypes.string.isRequired
};

export default CommitmentLog;
