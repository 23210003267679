import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UnsavedChangesModal from '../../../Library/Modal/UnsavedChangesModal/UnsavedChangesModal';
import { ContainerResize, ContainerPage } from '../../../Library/Layout';
import Header from '../../../Library/Header';
import EditCollectionAssessmentsForm from './Form/EditCollectionAssessmentsForm';
import EditCollectionGradeModifiersContainer from './Form/EditCollectionGradeModifiers/EditCollectionGradeModifiersContainer';
import { sortByProperty } from '../../../../helpers/utilities';

const EditCollectionGradeModifiersForm = styled(
  EditCollectionGradeModifiersContainer
)`
  && {
    margin-top: 64px;
  }
`;

export default function EditGradeCollection({
  gradeCollection,
  usedAssessmentUuids,
  partUuid,
  partTitle,
  courseNumber,
  courseTitle,
  handleModalClose,
  handleModalOpen,
  partAssessments,
  handleProceed,
  isUnsavedChangesModalOpen,
  handleSubmitAssessments,
  partNumber,
  sectionNumber,
  formDispatch,
  showValidationErrors,
  unsavedChanges,
  setUnsavedChange,
  removeUnsavedChange
}) {
  const {
    title,
    scoreType,
    distributeWeightEqually,
    collection_assessments
  } = gradeCollection;

  const headerSubtitle = `${courseNumber} ● ${courseTitle}`;

  const handleAddCollectionAssessment = () => {
    setUnsavedChange('collectionAssessments');
    formDispatch({
      type: 'addCollectionAssessment'
    });
  };

  const handleDistributeWeightEquallyChange = (e, distributeWeightEqually) => {
    setUnsavedChange('collectionAssessments');
    formDispatch({
      type: 'setDistributeWeightEqually',
      payload: {
        distributeWeightEqually
      }
    });
  };

  const partOptions = [{ label: `Part ${partNumber}`, value: partUuid }];

  const assessmentOptions = partAssessments
    .map(assessment => ({
      label: assessment.title,
      value: assessment.uuid,
      assessment
    }))
    .sort((a, b) => sortByProperty(a, b, 'label'));

  return (
    <ContainerResize>
      <UnsavedChangesModal
        modalOpen={isUnsavedChangesModalOpen}
        message="The form has unsaved changes. Click cancel to go back and update the form or click Discard Changes to return to the syllabus management page."
        proceedButtonText="Discard Changes"
        handleProceed={handleProceed}
        handleModalClose={handleModalClose}
      />
      <Header
        title={`Section ${sectionNumber} - Part ${partNumber}: ${partTitle}`}
        subtitle={headerSubtitle}
        backButtonOverride={handleModalOpen}
      />
      <ContainerPage>
        <EditCollectionAssessmentsForm
          gradeCollectionUuid={gradeCollection.uuid}
          title={title}
          scoreType={scoreType}
          distributeWeightEqually={distributeWeightEqually}
          collection_assessments={collection_assessments}
          partOptions={partOptions}
          assessmentOptions={assessmentOptions}
          handleAddCollectionAssessment={handleAddCollectionAssessment}
          handleDistributeWeightEquallyChange={
            handleDistributeWeightEquallyChange
          }
          partUuid={partUuid}
          usedAssessmentUuids={usedAssessmentUuids}
          formDispatch={formDispatch}
          setUnsavedChange={setUnsavedChange}
          showValidationErrors={showValidationErrors}
          handleSubmitAssessments={handleSubmitAssessments}
          unsavedChanges={unsavedChanges}
        />
      </ContainerPage>
      <ContainerPage>
        <EditCollectionGradeModifiersForm
          isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
          unsavedChanges={unsavedChanges}
          setUnsavedChange={setUnsavedChange}
          removeUnsavedChange={removeUnsavedChange}
        />
      </ContainerPage>
    </ContainerResize>
  );
}

EditGradeCollection.propTypes = {
  gradeCollection: PropTypes.object,
  partUuid: PropTypes.string,
  partTitle: PropTypes.string,
  courseNumber: PropTypes.string,
  courseTitle: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  formDispatch: PropTypes.func,
  handleModalClose: PropTypes.func,
  handleModalOpen: PropTypes.func,
  handleProceed: PropTypes.func,
  handleSubmitAssessments: PropTypes.func,
  isUnsavedChangesModalOpen: PropTypes.bool,
  partNumber: PropTypes.string,
  sectionNumber: PropTypes.string,
  unsavedChanges: PropTypes.object,
  usedAssessmentUuids: PropTypes.object,
  partAssessments: PropTypes.arrayOf(PropTypes.object),
  setUnsavedChange: PropTypes.func,
  removeUnsavedChange: PropTypes.func
};

EditGradeCollection.defaultProps = {
  gradeCollection: {},
  partUuid: '',
  partTitle: '',
  courseNumber: '',
  courseTitle: '',
  showValidationErrors: false,
  formDispatch: undefined,
  handleModalClose: undefined,
  handleModalOpen: undefined,
  handleProceed: undefined,
  handleSubmitAssessments: undefined,
  isUnsavedChangesModalOpen: false,
  partNumber: '',
  sectionNumber: '',
  unsavedChanges: {},
  usedAssessmentUuids: {},
  partAssessments: [],
  setUnsavedChange: undefined,
  removeUnsavedChange: undefined
};
