import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Body1 } from '@xcomp/xcomp-design-library';
import _ from 'lodash';
import useSWRMutation from 'swr/mutation';

import { scoresGroupedByBlock } from '../../../../redux/selectors/scoreSelectors';
import { assessmentBlockResultGroupedByBlockSelector } from '../../../../redux/selectors/assessmentBlockSelectors';

import DeleteModal from '../../../Library/Modal/DeleteModal/DeleteModal';
import StepBlockPanel from './StepBlockPanel';

import { isSchoolAdmin } from '../../../../helpers/authorization';
import {
  decimalTenth,
  determineStudentCriticalFailure,
  fetcher,
  totalByScoreType
} from '../../../../helpers/utilities';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import { doDeleteStudentScoresError, doDeleteStudentScoresSuccess } from '../../../../redux/actions/scoreActions';

// TODO: uncomment all the code in this file to re-allow adding attempt scores manually. This was
//   commented to remove this feature as the step form does not currently support
//   required block fields - JV 9/10/21

const StepPanelAccordion = styled(
  ({
    withBottomBorder,
    withTopBorder,
    criticalFailure,
    unfinished,
    ...rest
  }) => <AccordionRowContainer {...rest} />
)`
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label,
  .column_two_label {
    flex-grow: 0;
    width: 100px;

    &.unreported {
      content: '';
    }
  }

  .accordion_row_label div {
    font-size: 16px;
    color: ${props => props.theme.fontColors.darker};
  }

  .column_two_label {
    span {
      font-size: 16px;
      color: ${props => props.theme.fontColors.darker};
    }
  }

  .column_one_label {
    span {
      font-size: 16px;
      color: ${props =>
        // eslint-disable-next-line no-nested-ternary
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.unfinished
          ? props.theme.colors.status.warning
          : props.theme.fontColors.darker};
    }
  }

  .column_three_label {
    span {
      color: ${props =>
        // eslint-disable-next-line no-nested-ternary
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.unfinished
          ? props.theme.colors.status.warning
          : props.theme.fontColors.darker};
    }
  }

  width: 100%;
`;

const NoScoresMessage = styled(Body1)`
  margin-left: 66px;
  margin-top: 0;
`;

export default function StepPanel({
  assessmentBlocks,
  assessmentType,
  assessmentUuid,
  fullName,
  // handleScoreAddAttemptModalOpen,
  handleScoreAddModalOpen,
  handleScoreEditModalOpen,
  partUuid,
  scoreType,
  studentUuid,
  isWithdrewPanel,
  completedBlocks,
  totalPot
}) {
  const dispatch = useDispatch();
  const [openDeleteStudentScoresModal, setOpenDeleteStudentScoresModal] =
    useState(false);
  const [deleteParameters, setDeleteParameters] = useState('');

  // const scoresByResultCardAttempt = useSelector(
  //   state =>
  //     scoresGroupedByResultCardAttempt(state, assessmentUuid, studentUuid),
  //   _.isEqual
  // );

  const scoresByBlock = useSelector(
    state => scoresGroupedByBlock(state, assessmentUuid, studentUuid),
    _.isEqual
  );

  const userState = useSelector(state => state.userState);
  const studentBlockResultsByBlock = useSelector(
    state =>
      assessmentBlockResultGroupedByBlockSelector(
        state,
        studentUuid,
        assessmentBlocks.map(ab => ab.uuid)
        // Object.keys(scoresByBlock)
      ),
    _.isEqual
  );

  const { trigger, error, data, isMutating } = useSWRMutation(
    `/api/scores/delete/student?`,
    fetcher,
    {
      throwOnError: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() =>
    {
      if (data) {
        dispatch(doDeleteStudentScoresSuccess(data));
      }
    },
    // eslint-disable-next-line
    [data]
  )

  useEffect(() => 
    {
      if (error) {
        dispatch(doDeleteStudentScoresError({...error.info}));
      }
    },
    // eslint-disable-next-line
    [error]
  )

  const totalRel = totalByScoreType(scoreType, 'relValue', scoresByBlock);

  const handleDeleteStudentScoresModalOpen = (parameters) => {
    setOpenDeleteStudentScoresModal(true);
    setDeleteParameters(parameters);
  };

  const handleDeleteStudentScoresModalClose = () => {
    setOpenDeleteStudentScoresModal(false);
    setDeleteParameters('');
  };

  const handleDeleteStudentScores = () => {
    trigger({
      queryParameters: `studentUuid=${studentUuid}&assessmentUuid=${assessmentUuid}${deleteParameters}`, 
      options: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      }
    })
    handleDeleteStudentScoresModalClose();
  };

  const isAdmin = isSchoolAdmin({ userState });

  const stepPanelActions = isAdmin
    ? [
        // {
        //   label: 'Add Attempt Score',
        //   onClick: handleScoreAdd,
        //   shouldOpenOnClick: false,
        //   icon: 'add'
        // },
        {
          label: 'Delete Student Scores',
          onClick: () => handleDeleteStudentScoresModalOpen(''),
          shouldOpenOnClick: false,
          icon: 'delete'
        }
      ]
    : false;

  const getAccordionActions = isWithdrewPanel => {
    if (isWithdrewPanel) {
      return null;
    }

    return stepPanelActions;
  };

  const actions = getAccordionActions(isWithdrewPanel);

  const isUnreported = _.flatten(Object.values(scoresByBlock))?.length === 0;
  const isDidactic =
    assessmentType === 'Applied Didactic' ||
    assessmentType === 'Basic Didactic' ||
    scoreType === 'Opportunity';
  const criticalFailure =
    !isDidactic && determineStudentCriticalFailure(studentBlockResultsByBlock);

  const relVal = isUnreported ? '--' : decimalTenth(totalRel);

  let opportunityPotTotal = 0;

  if (scoreType === 'Opportunity' && !isUnreported) {
    Object.values(scoresByBlock).forEach(scores => {
      scores.forEach(s => {
        if (!Number.isNaN(Number(s.potValue))) {
          opportunityPotTotal += parseFloat(s.potValue);
        }
      })
    })
  }

  return (
    <>
      <StepPanelAccordion
        actions={actions}
        loading={isMutating}
        columnOne={criticalFailure ? '0' : relVal}
        columnOneClassName={
          isUnreported ? 'unreported column_one_label' : 'column_one_label'
        }
        columnTwo={isUnreported ? '--' : decimalTenth(scoreType === 'Opportunity' ? opportunityPotTotal : totalPot)}
        columnTwoClassName={
          isUnreported ? 'unreported column_two_label' : 'column_two_label'
        }
        columnThree={`${completedBlocks}/${assessmentBlocks.length}`}
        columnThreeClassName="column_three_label"
        criticalFailure={criticalFailure}
        unfinished={completedBlocks < assessmentBlocks.length}
        expandWidth="66px"
        height="50px"
        label={fullName}
        withBottomBorder
      >
        {isUnreported ? (
          <NoScoresMessage>
            No scores have been reported for this student.
          </NoScoresMessage>
        ) : (
          assessmentBlocks.map(assessmentBlock => (
            <StepBlockPanel
              assessmentBlock={assessmentBlock}
              assessmentType={assessmentType}
              assessmentUuid={assessmentUuid}
              blockScoresByAttempt={_.groupBy(
                scoresByBlock[assessmentBlock.uuid],
                'attempt'
              )}
              handleScoreAddModalOpen={handleScoreAddModalOpen}
              handleScoreEditModalOpen={handleScoreEditModalOpen}
              handleDeleteStudentScoresModalOpen={handleDeleteStudentScoresModalOpen}
              key={assessmentBlock.uuid}
              partUuid={partUuid}
              scoreType={scoreType}
              studentBlockResultsByBlock={
                studentBlockResultsByBlock[assessmentBlock.uuid]
              }
              studentUuid={studentUuid}
              isAdmin={isAdmin}
              isMutating={isMutating}
            />
          ))
        )}
      </StepPanelAccordion>
      {openDeleteStudentScoresModal &&
        <DeleteModal
          customHeading={
            // eslint-disable-next-line
            deleteParameters === ''
              ? 'Are you sure you want to delete the scores for this student?'
              : (deleteParameters.includes('opportunityNumber') || deleteParameters.includes('attemptNumber'))
                ? `Are you sure you want to delete the scores for this ${deleteParameters.includes('opportunityNumber') ? 'opportunity' : 'attempt'}, block and student?`
                : 'Are you sure you want to delete the scores for this block and student?'
          }
          handleDelete={handleDeleteStudentScores}
          handleModalClose={handleDeleteStudentScoresModalClose}
          modalOpen={openDeleteStudentScoresModal}
        />
      }
    </>
  );
}

StepPanel.propTypes = {
  assessmentBlocks: PropTypes.array,
  assessmentType: PropTypes.string,
  assessmentUuid: PropTypes.string,
  fullName: PropTypes.string,
  isWithdrewPanel: PropTypes.bool,
  // handleScoreAddAttemptModalOpen: PropTypes.func,
  handleScoreAddModalOpen: PropTypes.func,
  handleScoreEditModalOpen: PropTypes.func,
  partUuid: PropTypes.string,
  scoreType: PropTypes.string,
  studentUuid: PropTypes.string,
  completedBlocks: PropTypes.number,
  totalPot: PropTypes.number
};

StepPanel.defaultProps = {
  assessmentBlocks: [],
  assessmentType: '',
  assessmentUuid: '',
  fullName: '',
  isWithdrewPanel: false,
  // handleScoreAddAttemptModalOpen: undefined,
  handleScoreAddModalOpen: undefined,
  handleScoreEditModalOpen: undefined,
  partUuid: '',
  scoreType: '',
  studentUuid: '',
  completedBlocks: 0,
  totalPot: 0
};
