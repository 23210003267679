import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import ByInstitutionSubjectContainer from './ByInstitutionSubjectContainer';
import InstitutionName from '../DataCells/InstitutionName';
import InstitutionCredits from '../../TranscriptCalculations/InstitutionCredits';
import InstitutionAverageScore from '../../TranscriptCalculations/InstitutionAverageScore';
import InstitutionGrade from '../../TranscriptCalculations/InstitutionGrade';

const Accordion = styled(({ ...rest }) => (
  <AccordionRowContainer {...rest} />
))``;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.grey[500]};
  font-size: 14px;
`;

const Calculations = styled.div`
  display: flex;
  font-size: 12px;
`;

const Spacing = styled.div`
  width: 120px;
`;

const Stub = styled.div`
  width: 66px;
`;

export default function ByInstitutionCognitiveContainer({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  sourceInstitutionUuid
}) {
  return (
    <>
      <Accordion
        rowOffset="66px"
        expandWidth="66px"
        height="70px"
        label={
          <Group>
            <Label>
              <InstitutionName sourceInstitutionUuid={sourceInstitutionUuid} />
            </Label>
          </Group>
        }
        columnOneClassName="column_one"
        columnOne={
          <>
            <Calculations>
              <Spacing>&nbsp;</Spacing>
              <Spacing>
                <InstitutionCredits
                  programUuid={programUuid}
                  userUuid={userUuid}
                  cohortUuid={cohortUuid}
                  source={source}
                  sourceInstitutionUuid={sourceInstitutionUuid}
                />
              </Spacing>
              <Spacing>
                <InstitutionGrade
                  programUuid={programUuid}
                  userUuid={userUuid}
                  cohortUuid={cohortUuid}
                  source={source}
                  sourceInstitutionUuid={sourceInstitutionUuid}
                />
              </Spacing>
              <Spacing>
                <InstitutionAverageScore
                  programUuid={programUuid}
                  userUuid={userUuid}
                  cohortUuid={cohortUuid}
                  source={source}
                  sourceInstitutionUuid={sourceInstitutionUuid}
                />
              </Spacing>
              <Stub>&nbsp;</Stub>
            </Calculations>
          </>
        }
      >
        <ByInstitutionSubjectContainer
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
          sourceInstitutionUuid={sourceInstitutionUuid}
        />
      </Accordion>
    </>
  );
}

ByInstitutionCognitiveContainer.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string
};

ByInstitutionCognitiveContainer.defaultProps = {
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  sourceInstitutionUuid: undefined
};
