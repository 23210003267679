import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectSubjectCreditsMilestone } from '../../../../redux/selectors/transcriptCognitiveSelectors';

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export default function SubjectCredits({
  programUuid,
  userUuid,
  cohortUuid,
  classificationUuid
}) {
  const milestoneCredits = useSelector(state =>
    selectSubjectCreditsMilestone(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      classificationUuid
    )
  );

  const credits = `M - ${milestoneCredits}` || `M - N/A`;

  return <Layout>{credits}</Layout>;
}

SubjectCredits.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  classificationUuid: PropTypes.string
};

SubjectCredits.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  classificationUuid: undefined
};
