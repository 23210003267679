const fetchWithAuthorization = async (url, options = {}) => {
  const newOptions = {...options};

  if (process.env.REACT_APP_USE_IBM_CLOUD !== "true") {
    const cookies = document.cookie;
    const accessToken = cookies.split(';')
      .map(cookie => cookie.trim().split('='))
      .find(([name]) => name === 'token')?.[1] || '';

    if (!accessToken) {
      window.location.href = process.env.REACT_APP_LOGIN_URL || 'login';
      throw new Error('Authorization token missing');
    }

    newOptions.headers = options.headers || {}
    newOptions.headers.Authorization = `Bearer ${accessToken}`;
  }

  // eslint-disable-next-line no-return-await
  return await fetch(url, newOptions);
};

export default fetchWithAuthorization;