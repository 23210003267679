import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Chip, Tooltip } from '@material-ui/core';
import { ButtonIcon } from '@xcomp/xcomp-design-library';
import RemoveUserIcon from '@xcomp/xcomp-design-library/dist/icons/actions/RemoveUserIcon';
import { userSectionStatusMap } from '../../../../helpers/constants';

const Table = styled.table`
  background: ${props => props.theme.colors.white};
  border-collapse: collapse;
  width: 100%;
`;

const TableHead = styled.thead`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: ${props => props.theme.colors.grey[600]};
`;

const TableRow = styled.tr``;

const TableRowText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 53px;
  padding: 5px 10px 5px 20px;
`;

const TableBody = styled.tbody`
  color: ${props => props.theme.colors.grey[900]};
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 24px;
  &:last-child td {
    border-bottom: none;
  }
`;

const InstitutionId = styled.td`
  width: 15%;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding: 0px;
`;

const Name = styled.td`
  width: 25%;
  padding: 5px 10px 5px 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Status = styled.td`
  width: 35%;
  padding: 5px 10px 5px 20px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const GroupLeader = styled.td`
  width: 20%;
  text-align: end;
  padding-right: 1.5rem;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  && .MuiChip-root {
    font-size: 12px;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.primary};
  }
`;

const ButtonIconSC = styled(ButtonIcon)`
  && {
    svg {
      font-size: 18px;
    }
    &:hover,
    &:focus,
    &:active {
      background: 0;
    }
  }
`;

const GroupAction = styled.td`
  width: 5%;
  text-align: start;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const StatusBox = styled.div`
  color: ${props => props.theme.colors.grey[600]};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const GroupInfoTable = ({
  group,
  groupMembers,
  onOpenRemoveStudentModal,
  studentStatusMap
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <InstitutionId>
            <TableRowText>Institution ID</TableRowText>
          </InstitutionId>
          <Name>Last Name, First Name</Name>
          <Status>Status</Status>
          <GroupLeader />
          <GroupAction />
        </TableRow>
      </TableHead>
      <TableBody>
        {groupMembers.map(groupMember => (
          <TableRow key={groupMember.uuid}>
            <InstitutionId>
              <TableRowText>{groupMember.user.institutionId}</TableRowText>
            </InstitutionId>
            <Name>
              {`${groupMember.user.lastName}, ${groupMember.user.firstName}`}
            </Name>
            <Status>
              <StatusBox>
                {userSectionStatusMap[studentStatusMap[groupMember.user.uuid]]}
              </StatusBox>
            </Status>
            <GroupLeader>
              {group.groupLeaderUuid === groupMember.user.uuid && (
                <Chip label="GROUP LEADER" />
              )}
            </GroupLeader>
            <GroupAction>
              <Tooltip title="Remove from group" placement="top">
                <div>
                  <ButtonIconSC
                    noHoverBg
                    onClick={() =>
                      onOpenRemoveStudentModal(
                        groupMember.user.uuid,
                        group.uuid
                      )
                    }
                  >
                    <RemoveUserIcon />
                  </ButtonIconSC>
                </div>
              </Tooltip>
            </GroupAction>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

GroupInfoTable.propTypes = {
  group: PropTypes.object,
  groupMembers: PropTypes.array,
  onOpenRemoveStudentModal: PropTypes.func,
  studentStatusMap: PropTypes.object
};

GroupInfoTable.defaultProps = {
  group: {},
  groupMembers: [],
  onOpenRemoveStudentModal: undefined,
  studentStatusMap: {}
};

export default GroupInfoTable;
