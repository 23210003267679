import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const encounterTypesSelector = createSelector(
  state => state,
  redux => {
    const encounterTypesSelectorORM = ormCreateSelector(orm.EncounterType);
    const encounters = encounterTypesSelectorORM(redux);
    return encounters;
  }
);
