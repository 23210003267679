import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectCreditsBySubject } from '../../../../../redux/selectors/rigorIndexSelectors';

export default function SubjectCredits({
  programUuid,
  sourceInstitutionUuid,
  classificationUuid
}) {
  const credits = useSelector(state =>
    selectCreditsBySubject(
      state,
      programUuid,
      sourceInstitutionUuid,
      classificationUuid
    )
  );

  return <>{credits && credits}</>;
}

SubjectCredits.propTypes = {
  programUuid: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string,
  classificationUuid: PropTypes.string
};

SubjectCredits.defaultProps = {
  programUuid: undefined,
  sourceInstitutionUuid: undefined,
  classificationUuid: undefined
};
