import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import SyllabusItem from './SyllabusItem';

const Layout = styled.div`
  height: 100%;
`;

const SyllabusItemList = ({
  syllabusItems,
  assessmentFormUuid,
  changeAssessment
}) => {
  return (
    <Layout>
      {syllabusItems.map((item, index) => {
        const itemNumber = index + 1;
        const itemTypeName = _.get(item, 'assessment_type.name', '');

        return (
          <div key={item.uuid}>
            <SyllabusItem
              item={item}
              assessmentFormUuid={assessmentFormUuid}
              changeAssessment={changeAssessment}
              itemNumber={itemNumber}
              itemTypeName={itemTypeName}
            />
          </div>
        );
      })}
    </Layout>
  );
};

SyllabusItemList.propTypes = {
  syllabusItems: PropTypes.arrayOf(PropTypes.object),
  assessmentFormUuid: PropTypes.string,
  changeAssessment: PropTypes.func
};

SyllabusItemList.defaultProps = {
  syllabusItems: [],
  assessmentFormUuid: '',
  changeAssessment: undefined
};

export default SyllabusItemList;
