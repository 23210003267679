import {
  GET_USERS_BY_SEARCH,
  GET_USERS_BY_SEARCH_ROLES,
  SEARCH_COLLECTION_COURSES_SUCCESS,
  SEARCH_COLLECTION_COURSES_ERROR,
  SEARCH_MICROCOMPETENCY_TOPICS,
  SEARCH_MICROCOMPETENCY_TOPICS_SUCCESS,
  SEARCH_MICROCOMPETENCY_TOPICS_ERROR
} from '../actions-type';
import { sortByMicroLevels } from '../../helpers/utilities';

export const INITIAL_STATE = {
  searchUserBy: undefined,
  searchUserInput: undefined,
  searchUserRoleBy: undefined,
  searchUserRoleInput: undefined,
  collectionCourseSearchResults: null,
  microTopicSearchResults: null,
  isLoading: false
};

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERS_BY_SEARCH: {
      return {
        ...state,
        searchUserBy: action.payload.field,
        searchUserInput: action.payload.input
      };
    }
    case GET_USERS_BY_SEARCH_ROLES: {
      return {
        ...state,
        searchUserRoleBy: action.payload.field,
        searchUserRoleInput: action.payload.input
      };
    }

    case SEARCH_COLLECTION_COURSES_SUCCESS: {
      return {
        ...state,
        collectionCourseSearchResults: action.payload.courses.map(course => ({
          uuid: course.uuid,
          label: `${course.courseNumber} - ${course.title}`,
          object: course
        })),
        isLoading: false
      };
    }
    case SEARCH_COLLECTION_COURSES_ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }
    case SEARCH_MICROCOMPETENCY_TOPICS: {
      return {
        ...state,
        microTopicSearchResults: null,
        isLoading: true
      };
    }
    case SEARCH_MICROCOMPETENCY_TOPICS_SUCCESS: {
      return {
        ...state,
        microTopicSearchResults: action.payload.topics.sort((a, b) =>
          sortByMicroLevels(a, b)
        ),
        isLoading: false
      };
    }
    case SEARCH_MICROCOMPETENCY_TOPICS_ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
};

export default searchReducer;
