import {
  CLEAR_PART_FACULTY_ASSESSMENT_SUMMARY,
  GET_PART_FACULTY_ASSESSMENT_SUMMARY,
  GET_PART_FACULTY_ASSESSMENT_SUMMARY_ERROR,
  GET_PART_FACULTY_ASSESSMENT_SUMMARY_SUCCESS,
  CLEAR_PART_FACULTY_REPORT,
  GET_PART_FACULTY_REPORT,
  GET_PART_FACULTY_REPORT_ERROR,
  GET_PART_FACULTY_REPORT_SUCCESS
} from '../actions-type';

export const INITIAL_STATE = {
  partFacultyAssessmentSummaryData: {},
  partFacultyReportSummaryData: {},
  partFacultyReportFacultyData: {},
  partFacultyAssessmentSummaryLoading: [],
  isLoading: false,
  records: 0
};

const partFacultyReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PART_FACULTY_ASSESSMENT_SUMMARY: {
      const { facultyUuid } = action.payload;
      return {
        ...state,
        partFacultyAssessmentSummaryData: {
          ...state.partFacultyAssessmentSummaryData,
          [facultyUuid]: {}
        },
        partFacultyAssessmentSummaryLoading: [
          ...state.partFacultyAssessmentSummaryLoading,
          facultyUuid
        ]
      };
    }
    case CLEAR_PART_FACULTY_ASSESSMENT_SUMMARY:
    case GET_PART_FACULTY_ASSESSMENT_SUMMARY_ERROR: {
      return {
        ...state,
        partFacultyAssessmentSummaryData:
          INITIAL_STATE.partFacultyAssessmentSummaryData,
        partFacultyAssessmentSummaryLoading:
          INITIAL_STATE.partFacultyAssessmentSummaryLoading
      };
    }
    case GET_PART_FACULTY_ASSESSMENT_SUMMARY_SUCCESS: {
      const { facultyUuid, partFacultyAssessmentSummaryData } = action.payload;
      return {
        ...state,
        partFacultyAssessmentSummaryData: {
          ...state.partFacultyAssessmentSummaryData,
          [facultyUuid]: partFacultyAssessmentSummaryData
        },
        partFacultyAssessmentSummaryLoading:
          state.partFacultyAssessmentSummaryLoading.filter(
            loadingFacultyUuid => loadingFacultyUuid !== facultyUuid
          )
      };
    }
    case GET_PART_FACULTY_REPORT: {
      return { ...INITIAL_STATE, isLoading: true };
    }
    case CLEAR_PART_FACULTY_REPORT:
    case GET_PART_FACULTY_REPORT_ERROR: {
      return INITIAL_STATE;
    }
    case GET_PART_FACULTY_REPORT_SUCCESS: {
      const {
        partFacultyReportAssessmentData,
        partFacultyReportFacultyData,
        partFacultyReportSummaryData,
        encounterRecords,
        records
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        partFacultyReportAssessmentData,
        partFacultyReportFacultyData,
        partFacultyReportSummaryData,
        encounterRecords,
        records
      };
    }
    default:
      return state;
  }
};

export default partFacultyReportReducer;
