import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Body1, Caption } from '@xcomp/xcomp-design-library';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { rolesMap } from '../../helpers/constants'
import { useRoleHierarchy } from '../../helpers/hooks/useRoleHierarchy'

const LayoutSC = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const IconSC = styled(AccountCircle)`
  && {
    height: 100px;
    width: 100px;
    margin: 1rem;
    color: ${props => props.theme.colors.primary};
  }
`;

const ProfileSC = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
`;

const Name = styled.div`
  font-size: 1.25rem;
  text-align: center;
`;

const Email = styled(Caption)`
  margin: 0;
`;

const LinkProfileSC = styled(Link)`
  && {
    color: ${props => props.theme.colors.primary};
    font-size: 14px;
    letter-spacing: 1.25px;
    line-height: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const RolesSwitcher = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const RoleButtonSC = styled.button`
  width: 100%;
  background: 0;
  padding: 0.5rem 1.125rem;
  text-align: left;
  border: 0;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    background: ${props => props.theme.colors.grey[100]};
    p {
      color: ${props => props.theme.colors.primary};
    }

    border-left: 3px solid ${props => props.theme.colors.primary};
    outline: 0;
  }
`;

const RoleName = styled(Body1)`
  margin: 0;
`

const RoleButton = ({ role, onChangeRole }) => {
  const roleName = useRoleHierarchy(role)

  return (
    <RoleButtonSC type='button' onClick={onChangeRole} key={role.uuid}>
      <RoleName>{rolesMap[role.role]}</RoleName>
      <Email>{role.email}</Email>
      <div>{roleName}</div>
    </RoleButtonSC>
  )
}

const NavigationContainer = ({
  user,
  handleClose,
  currentRole,
  changeCurrentRole,
}) => {
  const path = `/user-management/user-profile/edit/details`
  const userRoles = useSelector(state => state.userState.userRoles)

  const roles = useMemo(
    () =>
      Object.entries(userRoles)
        .map(([, roles]) => roles.map(role => role))
        .flat(),
    [userRoles],
  )
  
  const onChangeRole = role => {
    changeCurrentRole(role)
    handleClose()
  }

  return (
    <LayoutSC>
      <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <IconSC />
      </div>
      <ProfileSC>
        <Name>{user && `${user.firstName} ${user.lastName}`}</Name>
        <Email>{currentRole && `${currentRole.email}`}</Email>
        <LinkProfileSC onClick={() => handleClose()} to={path}>
          PROFILE
        </LinkProfileSC>
      </ProfileSC>
      <RolesSwitcher>
        {roles.map(role => (
          <RoleButton
            onChangeRole={() => onChangeRole(role)}
            key={role.uuid}
            role={role}
          />
        ))}
      </RolesSwitcher>
    </LayoutSC>
  );
};

NavigationContainer.propTypes = {
  user: PropTypes.object,
  currentRole: PropTypes.object,
  handleClose: PropTypes.func,
  changeCurrentRole: PropTypes.func
};

NavigationContainer.defaultProps = {
  user: {},
  currentRole: {
    email: '',
  },
  handleClose: undefined,
  changeCurrentRole: undefined,
}

RoleButton.propTypes = {
  role: PropTypes.shape({
    uuid: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  onChangeRole: PropTypes.func.isRequired,
}

export default NavigationContainer
