import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import Select from 'react-select';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { sortBy } from 'lodash';

const selectUserFieldStyles = theme => ({
  root: {
    flexGrow: 1,
    height: 'auto'
  },
  input: {
    display: 'flex',
    height: 'auto'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  chip: {
    margin: '.5em 1em .5em 0'
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(2)
  }
});

const ChipSC = styled(Chip)`
  margin: 0.5rem 0.5rem 0.5rem 0;

  ${props => props.$isAllChoice && `
    background: ${props.theme.colors.green[300]} !important;
    color: ${props.theme.colors.black} !important;

    & svg {
      fill: ${props.theme.colors.green[700]} !important;;
    }
  `};
`;

const MenuItemSC = styled(MenuItem)`
  ${props => props.$isAllChoice && `
    background: ${props.theme.colors.green[200]} !important;
    color: ${props.theme.colors.black} !important;
  `};
`

const NoOptionsMessage = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

NoOptionsMessage.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.node
};

NoOptionsMessage.defaultProps = {
  selectProps: {},
  innerProps: {},
  children: <div />
};

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

inputComponent.propTypes = {
  inputRef: PropTypes.func
};

inputComponent.defaultProps = {
  inputRef: undefined
};

const Control = props => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: props.selectProps.classes.input,
        inputRef: props.innerRef,
        children: props.children,
        ...props.innerProps
      }
    }}
    {...props.selectProps.textFieldProps}
  />
);

Control.propTypes = {
  selectProps: PropTypes.object,
  inputProps: PropTypes.object,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
  children: PropTypes.node
};

Control.defaultProps = {
  selectProps: {},
  inputProps: {},
  innerProps: {},
  innerRef: undefined,
  children: <div />
};

const Option = props => (
  <MenuItemSC
    ref={props.innerRef}
    selected={props.isFocused}
    $isAllChoice={props.data?.value === 'all'}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItemSC>
);

Option.propTypes = {
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
  children: PropTypes.node,
  data: PropTypes.object,
};

Option.defaultProps = {
  isFocused: false,
  isSelected: false,
  innerProps: {},
  innerRef: undefined,
  children: <div />,
  data: undefined,
};

const Placeholder = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

Placeholder.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.func,
  children: PropTypes.node
};

Placeholder.defaultProps = {
  selectProps: {},
  innerProps: undefined,
  children: <div />
};

const ValueContainer = props => (
  <div className={props.selectProps.classes.valueContainer}>
    {props.children}
  </div>
);

ValueContainer.propTypes = {
  selectProps: PropTypes.object,
  children: PropTypes.node
};

ValueContainer.defaultProps = {
  selectProps: {},
  children: <div />
};

const MultiValue = props => (
  <ChipSC
    $isAllChoice={props.data?.value === 'all'}
    tabIndex={-1}
    label={props.children}
    onDelete={event => {
      props.removeProps.onClick();
      props.removeProps.onMouseDown(event);
    }}
  />
);

MultiValue.propTypes = {
  children: PropTypes.node,
  removeProps: PropTypes.object,
  data: PropTypes.object,
};

MultiValue.defaultProps = {
  data: undefined,
  children: <div />,
  removeProps: {}
};

const Menu = props => (
  <Paper
    square
    className={props.selectProps.classes.paper}
    {...props.innerProps}
  >
    {props.children}
  </Paper>
);

Menu.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.node
};

Menu.defaultProps = {
  selectProps: {},
  innerProps: {},
  children: <div />
};

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  MultiValue,
  ValueContainer,
  Menu
};

const SelectUserField = props => {
  const {
    classes,
    theme,
    usersArray,
    value,
    handleChange,
    label,
    placeholder,
    error,
    className,
    singleValue,
    allOption,
    children,
    ...rest
  } = props;

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary
    })
  };

  const sortedUsers = sortBy(usersArray, 'label');

  return (
    <div className={`${classes.root} ${className}`}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          id="selectUserField"
          textFieldProps={{
            label,
            error,
            InputLabelProps: {
              shrink: true
            }
          }}
          options={allOption ? [allOption, ...sortedUsers] : sortedUsers}
          components={components}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          isMulti={!singleValue}
          closeMenuOnSelect={singleValue}
          {...rest}
        />
      </NoSsr>
      {children && children}
    </div>
  );
};

SelectUserField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  usersArray: PropTypes.array,
  value: oneOfType([PropTypes.object, PropTypes.array]),
  handleChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: oneOfType([PropTypes.bool, PropTypes.object]),
  className: PropTypes.string,
  singleValue: PropTypes.bool,
  allOption: PropTypes.object,
  children: PropTypes.node
};

SelectUserField.defaultProps = {
  usersArray: [],
  value: '',
  handleChange: undefined,
  label: '',
  placeholder: '',
  error: false,
  className: '',
  singleValue: false,
  allOption: undefined,
  children: <div />
};

export default withStyles(selectUserFieldStyles, { withTheme: true })(
  SelectUserField
);
export {
  SelectUserField,
  NoOptionsMessage,
  inputComponent,
  Control,
  Option,
  Placeholder,
  ValueContainer,
  MultiValue,
  Menu,
  selectUserFieldStyles,
  ChipSC
};
