import fetch from '../helpers/fetch';

const postCohortCompetencyGridReport = ({
  cohortUuid,
  competencyCollectionUuid,
  assessmentAssociationUuids,
  assessmentTechniqueUuids,
  assessmentTypeUuids,
  startDate,
  endDate
}) =>
  fetch(`/api/cohort/${cohortUuid}/competency-grid`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      competencyCollectionUuid,
      assessmentAssociationUuids,
      assessmentTechniqueUuids,
      assessmentTypeUuids,
      dateRange: { startDate, endDate }
    })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { postCohortCompetencyGridReport };
