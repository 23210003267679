import { OPEN_ACCORDION, CLOSE_ACCORDION } from '../actions-type';

const INITIAL_STATE = {
  openAccordions: {}
};

const openAccordionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_ACCORDION: {
      const { key } = action.payload;
      const newOpenAccordions = { ...state.openAccordions };
      newOpenAccordions[key] = true;
      return {
        ...state,
        openAccordions: newOpenAccordions
      };
    }
    case CLOSE_ACCORDION: {
      const { key } = action.payload;
      const newOpenAccordions = { ...state.openAccordions };
      if (newOpenAccordions[key]) {
        delete newOpenAccordions[key];
      }
      return {
        ...state,
        openAccordions: newOpenAccordions
      };
    }

    default:
      return state;
  }
};

export default openAccordionsReducer;

export { INITIAL_STATE };
