import React from 'react';
import PropTypes from 'prop-types';
import { LabelIcon } from '@xcomp/xcomp-design-library/dist/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import {
  userSectionStatus,
  userSectionStatusMap
} from '../../../helpers/constants';

const Instruction = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

export default function StudentRosterStatusMenu({ handleSelectedStatus }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuSelection = status => {
    handleSelectedStatus(status);
  };

  return (
    <>
      <LabelIcon
        aria-controls="userSection-status-menu"
        aria-haspopup="true"
        onClick={handleClick}
      />
      <Menu
        id="userSection-status-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Instruction>Change status roles to: </Instruction>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(userSectionStatus.active);
          }}
        >
          {userSectionStatusMap.active}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(userSectionStatus.delayed);
          }}
        >
          {userSectionStatusMap.delayed}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(userSectionStatus.withdrew);
          }}
        >
          {userSectionStatusMap.withdrew}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(userSectionStatus.credit);
          }}
        >
          {userSectionStatusMap.credit}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(userSectionStatus.pass);
          }}
        >
          {userSectionStatusMap.pass}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(userSectionStatus.pass_with_remediation);
          }}
        >
          {userSectionStatusMap.pass_with_remediation}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(userSectionStatus.fail);
          }}
        >
          {userSectionStatusMap.fail}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleMenuSelection(userSectionStatus.fail_with_remediation);
          }}
        >
          {userSectionStatusMap.fail_with_remediation}
        </MenuItem>
      </Menu>
    </>
  );
}

StudentRosterStatusMenu.propTypes = {
  handleSelectedStatus: PropTypes.func
};
StudentRosterStatusMenu.defaultProps = {
  handleSelectedStatus: undefined
};
