import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { doGetCompetencyCollections } from '../../../../redux/actions/competencyCollectionActions';
import { competencyCollectionsSelector } from '../../../../redux/selectors/competencyCollectionsSelectors';

import CompetencyCollectionsList from './CompetencyCollectionsList';

const CompetencyCollectionsListContainer = () => {
  const dispatch = useDispatch();

  const programUuid = useSelector(state => state.userState.selectedProgramUuid);

  const competencyCollections = useSelector(
    state => competencyCollectionsSelector(state, programUuid),
    _.isEqual
  );

  useEffect(() => {
    dispatch(doGetCompetencyCollections(programUuid));
  }, [programUuid, dispatch]);

  return (
    <CompetencyCollectionsList competencyCollections={competencyCollections} />
  );
};

export default CompetencyCollectionsListContainer;
