import React from 'react';
import PropTypes from 'prop-types';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ButtonSC = styled(Button)`
  width: 40px !important;
  min-width: 40px !important;
  background: red;
`

const NumberOfItems = styled.div`
  width: 140px;
  display: flex;
  justify-content: space-between;
`

const PageButtons = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Pagination = ({
  numberOfPages,
  currentPage,
  onChangePage,
  itemsPerPage,
  numberOfItems,
  onChangeNumberOfItems,
  style,
}) => {

  const changeNumberOfItems = (value) => {
    onChangePage(1);
    onChangeNumberOfItems(value);
  }
  return (
    <Container style={style}>
      {itemsPerPage && onChangeNumberOfItems && 
        <NumberOfItems>
          <ButtonSC variant={itemsPerPage === 10 ? 'contained' : 'outlined'} color='primary' onClick={() => changeNumberOfItems(10)}>
            10
          </ButtonSC>
          <ButtonSC variant={itemsPerPage === 25 ? 'contained' : 'outlined'} color='primary' onClick={() => changeNumberOfItems(25)}>
            25
          </ButtonSC>
          <ButtonSC variant={itemsPerPage === 50 ? 'contained' : 'outlined'} color='primary' onClick={() => changeNumberOfItems(50)}>
            50
          </ButtonSC>
        </NumberOfItems>
      }

      <PageButtons>
        <div style={{width: 150, textAlign: 'right'}}>
          {`${(currentPage - 1) * itemsPerPage + 1}-${(currentPage * itemsPerPage) > numberOfItems ? numberOfItems : currentPage * itemsPerPage} of ${numberOfItems} items`}
        </div>
        <ButtonSC disabled={currentPage === 1} variant='outlined' color='primary' onClick={() => currentPage > 1 && onChangePage(1)}>
          <FirstPageIcon />
        </ButtonSC>
        <ButtonSC disabled={currentPage === 1} variant='outlined' color='primary' onClick={() => currentPage > 1 && onChangePage(currentPage - 1)}>
          <NavigateBeforeIcon />
        </ButtonSC>

        <ButtonSC variant='contained' color='primary'>{currentPage}</ButtonSC>

        <ButtonSC disabled={currentPage === numberOfPages} variant='outlined' color='primary' onClick={() => currentPage < numberOfPages && onChangePage(currentPage + 1)}>
          <NavigateNextIcon />
        </ButtonSC>
        <ButtonSC disabled={currentPage === numberOfPages} variant='outlined' color='primary' onClick={() => currentPage < numberOfPages && onChangePage(numberOfPages)}>
          <LastPageIcon />
        </ButtonSC>
      </PageButtons>
    </Container>
  );
}

Pagination.propTypes = {
  numberOfPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  numberOfItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number,
  onChangeNumberOfItems: PropTypes.func,
  style: PropTypes.object,
};

Pagination.defaultProps = {
  style: undefined,
  itemsPerPage: 10,
  onChangeNumberOfItems: undefined,
};

export default Pagination;
