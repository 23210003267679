import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getUser,
  getUsersSearch,
  getUsersSearchRoles,
  getUsersSearchInstitutionId,
  postUsersCheck,
  postUser,
  putUser,
  deleteUser,
  putUserProfile,
  postUploadUsers,
  putUserRoles,
  postUserRole,
  postUserAppId,
  deleteAppId
} from '../../api/userApi';

import { getUserActive } from '../../api/userLoadApi';

import {
  doDeleteUserError,
  doDeleteUserSuccess,
  doGetUserActiveError,
  doGetUserActiveReject,
  doGetUserActiveSuccess,
  doGetUserError,
  doGetUsersBySearchError,
  doGetUsersBySearchInstitutionIdError,
  doGetUsersBySearchInstitutionIdSuccess,
  doGetUsersBySearchRolesError,
  doGetUsersBySearchRolesSuccess,
  doGetUsersBySearchSuccess,
  doGetUserSuccess,
  doPostCheckUsersError,
  doPostCheckUsersSuccess,
  doPostUploadUsersError,
  doPostUploadUsersSuccess,
  doPostUserError,
  doPostUserRoleError,
  doPostUserRoleSuccess,
  doPostUserSuccess,
  doPutRolesError,
  doPutRolesSuccess,
  doPutUserError,
  doPutUserProfileError,
  doPutUserProfileSuccess,
  doPutUserSuccess,
  doPostGenerateAppIdSuccess,
  doPostGenerateAppIdError,
  doDeleteAppIdSuccess,
  doDeleteAppIdError
} from '../actions/userActions';

import {
  DELETE_USER,
  GET_USER_ACTIVE,
  GET_USER,
  GET_USERS_BY_SEARCH,
  GET_USERS_BY_SEARCH_ROLES,
  GET_USERS_BY_SEARCH_INSTITUTION_ID,
  POST_UPLOAD_USERS,
  POST_USER,
  PUT_USER_PROFILE,
  PUT_USER,
  PUT_USER_ROLES,
  POST_CHECK_USERS,
  POST_USER_ROLE,
  POST_USER_APP_ID,
  DELETE_APP_ID
} from '../actions-type';

function* handleGetUser(action) {
  const { userUuid } = action.payload;
  try {
    const result = yield call(getUser, userUuid);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetUserSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUserError(error));
  }
}

function* handleGetActiveUser() {
  try {
    const result = yield call(getUserActive);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetUserActiveSuccess(payload));
    } else if (result.status === 401) {
      const payload = yield result.json();
      yield put(doGetUserActiveReject(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUserActiveError(error));
  }
}

function* handlePostUser(action) {
  try {
    const result = yield call(postUser, action.payload.user);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostUserSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostUserError(error));
  }
}

function* handlePutUser(action) {
  const { user, institutionUuid } = action.payload;
  try {
    const result = yield call(putUser, user, institutionUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutUserSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutUserError(error));
  }
}

function* handlePutUserRoles(action) {
  const { userUuid, roles } = action.payload;
  try {
    const result = yield call(putUserRoles, userUuid, roles);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutRolesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutRolesError(error));
  }
}

function* handleDeleteUser(action) {
  const { userUuid, institutionUuid } = action.payload;

  try {
    const result = yield call(deleteUser, userUuid, institutionUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteUserSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteUserError(error));
  }
}

function* handlePostUpdateUserProfile(action) {
  try {
    const result = yield call(putUserProfile, action.payload);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutUserProfileSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutUserProfileError(error));
  }
}

function* handlePostUploadUsers(action) {
  try {
    const { users, uploadDate, institutionUuid } = action.payload;

    const result = yield call(
      postUploadUsers,
      uploadDate,
      users,
      institutionUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostUploadUsersSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostUploadUsersError(error));
  }
}

function* handleGetUsersBySearch(action) {
  const { field, input } = action.payload;
  try {
    const result = yield call(getUsersSearch, field, input);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetUsersBySearchSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUsersBySearchError(error));
  }
}

function* handleGetUsersBySearchRoles(action) {
  const { field, input } = action.payload;
  try {
    const result = yield call(getUsersSearchRoles, field, input);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetUsersBySearchRolesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUsersBySearchRolesError(error));
  }
}

function* handleGetUsersBySearchInstitutionId(action) {
  try {
    const result = yield call(
      getUsersSearchInstitutionId,
      action.payload.institutionId
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetUsersBySearchInstitutionIdSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUsersBySearchInstitutionIdError(error));
  }
}

function* handlePostCheckUsers(action) {
  const users = action.payload;

  try {
    const result = yield call(postUsersCheck, users);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostCheckUsersSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostCheckUsersError(error));
  }
}

function* handlePostUserRole(action) {
  const { role, roleType } = action.payload;

  try {
    const result = yield call(postUserRole, roleType, role);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostUserRoleSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostUserRoleError(error));
  }
}

function* handlePostUserAppId(action) {
  const { roleField, userUuid, email } = action.payload;

  try {
    const result = yield call(postUserAppId, roleField, userUuid, email);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostGenerateAppIdSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostGenerateAppIdError(error));
  }
}

function* handleDeleteAppId(action) {
  const { roleField, userUuid, appId } = action.payload;

  try {
    const result = yield call(deleteAppId, roleField, userUuid, appId);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteAppIdSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteAppIdError(error));
  }
}

const user = [
  takeEvery(GET_USER, handleGetUser),
  takeEvery(GET_USERS_BY_SEARCH, handleGetUsersBySearch),
  takeEvery(
    GET_USERS_BY_SEARCH_INSTITUTION_ID,
    handleGetUsersBySearchInstitutionId
  ),
  takeEvery(POST_USER, handlePostUser),
  takeEvery(PUT_USER, handlePutUser),
  takeEvery(DELETE_USER, handleDeleteUser),
  takeEvery(PUT_USER_PROFILE, handlePostUpdateUserProfile),
  takeEvery(GET_USER_ACTIVE, handleGetActiveUser),
  takeEvery(POST_UPLOAD_USERS, handlePostUploadUsers),
  takeEvery(POST_CHECK_USERS, handlePostCheckUsers),
  takeEvery(PUT_USER_ROLES, handlePutUserRoles),
  takeEvery(POST_USER_ROLE, handlePostUserRole),
  takeEvery(GET_USERS_BY_SEARCH_ROLES, handleGetUsersBySearchRoles),
  takeEvery(POST_USER_APP_ID, handlePostUserAppId),
  takeEvery(DELETE_APP_ID, handleDeleteAppId)
];

export { user };
