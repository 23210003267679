import { Model, attr, fk } from 'redux-orm';

import {
  GET_SYLLABUS_COURSE_SUCCESS,
  GET_SYLLABUS_PART_SUCCESS
} from '../actions-type';

class PartDirector extends Model {
  static reducer(action, PartDirector, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_SYLLABUS_COURSE_SUCCESS: {
        const course = { ...payload.course };
        const { sections } = course;

        if (sections?.length > 0) {
          sections.forEach(section => {
            const { parts } = section;

            if (parts?.length > 0) {
              parts.forEach(part => {
                const { part_directors_assigned } = part;

                if (part_directors_assigned?.length > 0) {
                  part_directors_assigned.forEach(director => {
                    const { part_director } = director;

                    PartDirector.upsert(part_director);
                  });
                }
              });
            }
          });
        }

        break;
      }
      case GET_SYLLABUS_PART_SUCCESS: {
        const part = { ...payload.part };

        const { part_directors_assigned } = part;

        if (part_directors_assigned?.length > 0) {
          part_directors_assigned.forEach(director => {
            const { part_director } = director;

            PartDirector.upsert(part_director);
          });
        }

        break;
      }
      default:
        return session;
    }
  }
}

PartDirector.fields = {
  uuid: attr(),
  userUuid: fk({
    to: 'User',
    as: 'user',
    relatedName: 'part_director'
  }),
  partUuid: fk({
    to: 'Part',
    as: 'part',
    relatedName: 'part_director'
  })
};

PartDirector.options = {
  idAttribute: 'uuid'
};

PartDirector.modelName = 'PartDirector';

export default PartDirector;
