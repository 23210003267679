import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import CheckboxAccordionRow from '../../../Library/AccordionRow/CheckboxAccordion/CheckboxAccordionRowContainer';
import { sortByMicroLevels } from '../../../../helpers/utilities';
import {
  selectMicrocompetency,
  deselectMicrocompetency,
  selectSubtopic,
  selectSubtopicMicro,
  deselectSubtopic,
  deselectSubtopicMicro,
  selectTopic,
  selectTopicMicro,
  deselectTopic,
  deselectTopicMicro
} from './microSearchState';

const isMicroChecked = (
  searchType,
  topics,
  subtopics,
  micro,
  subtopic,
  topic
) => {
  switch (searchType) {
    case 'subtopic':
      return Boolean(
        _.get(
          subtopics,
          `[${topic.uuid}].subtopics[${subtopic.uuid}].micros[${micro.uuid}]`,
          false
        )
      );
    case 'topic':
      return Boolean(
        _.get(topics, `[${topic.uuid}].micros[${micro.uuid}]`, false)
      );
    default:
      return false;
  }
};

const isSubtopicChecked = (subtopics, subtopic) => {
  return Boolean(
    _.get(
      subtopics,
      `[${subtopic.topicUuid}].subtopics[${subtopic.uuid}].micros`,
      false
    )
  );
};

const isTopicChecked = (topics, topic) => {
  return Boolean(_.get(topics, `[${topic.uuid}].micros`, false));
};

const MicroSearchResults = ({
  searchDispatch,
  searchResultTopics,
  searchType,
  selectedTopics,
  selectedSubtopics
}) => {
  const isLoading = useSelector(state => state.searchState.isLoading);

  const setTopicDispatch = topic => searchDispatch(selectTopic(topic));

  const deselectTopicDispatch = uuid => searchDispatch(deselectTopic(uuid));

  const setSubtopicDispatch = subtopic =>
    searchDispatch(selectSubtopic(subtopic));

  const deselectSubtopicDispatch = (uuid, topicUuid) =>
    searchDispatch(deselectSubtopic(uuid, topicUuid));

  const setMicrocompetencyDispatch = (searchType, micro, subtopic, topic) => {
    if (searchType === 'subtopic') {
      searchDispatch(selectSubtopicMicro(micro, subtopic));
    } else if (searchType === 'topic') {
      searchDispatch(selectTopicMicro(micro, topic));
    } else {
      searchDispatch(selectMicrocompetency(micro));
    }
  };

  const deselectMicrocompetencyDispatch = (
    searchType,
    uuid,
    subtopicUuid,
    topicUuid
  ) => {
    if (searchType === 'subtopic') {
      searchDispatch(deselectSubtopicMicro(uuid, subtopicUuid, topicUuid));
    } else if (searchType === 'topic') {
      searchDispatch(deselectTopicMicro(uuid, topicUuid));
    } else {
      searchDispatch(deselectMicrocompetency(uuid, topicUuid));
    }
  };

  return isLoading ? (
    <div>loading...</div>
  ) : (
    <div>
      {searchResultTopics && searchResultTopics.length > 0 ? (
        searchResultTopics.map(topic => {
          const topicCodeString = `${topic.discipline.cipcode.code}.${topic.discipline.code}.${topic.level_one_code}${topic.level_two_code}`;
          return (
            <CheckboxAccordionRow
              key={topic.uuid}
              actions={false}
              label="Topic"
              expandWidth="24px"
              columnOne={topicCodeString}
              columnTwo={`${topic.title}`}
              setCheckedFromProps={isTopicChecked(selectedTopics, topic)}
              onCheck={() => setTopicDispatch(topic)}
              onUncheck={() => deselectTopicDispatch(topic.uuid)}
              isTopLevel
              hideCheckBox={searchType !== 'topic'}
            >
              {topic.subtopics &&
                topic.subtopics.length > 0 &&
                topic.subtopics
                  .sort((a, b) => sortByMicroLevels(a, b))
                  .map(subtopic => {
                    const subtopicCodeString = `${topicCodeString}.${subtopic.level_one_code}${subtopic.level_two_code}`;
                    const nestedSubtopic = {
                      ...subtopic,
                      topic: {
                        ...topic
                      }
                    };
                    return (
                      <CheckboxAccordionRow
                        key={subtopic.uuid}
                        actions={false}
                        label="Subtopic"
                        expandWidth="24px"
                        columnOne={subtopicCodeString}
                        columnTwo={`${subtopic.title}`}
                        setCheckedFromProps={isSubtopicChecked(
                          selectedSubtopics,
                          subtopic
                        )}
                        onCheck={() => setSubtopicDispatch(nestedSubtopic)}
                        onUncheck={() =>
                          deselectSubtopicDispatch(subtopic.uuid, topic.uuid)
                        }
                        rowOffset="48px"
                        hideCheckBox={searchType !== 'subtopic'}
                      >
                        {subtopic.microcompetencies &&
                          subtopic.microcompetencies.length > 0 &&
                          subtopic.microcompetencies
                            .sort((a, b) => sortByMicroLevels(a, b))
                            .map(micro => {
                              const nestedMicro = {
                                ...micro,
                                subtopic: {
                                  ...subtopic,
                                  topic
                                }
                              };
                              return (
                                <CheckboxAccordionRow
                                  key={micro.uuid}
                                  actions={false}
                                  label="Microcompetency"
                                  columnOne={`${subtopicCodeString}.${micro.level_one_code}${micro.level_two_code}`}
                                  columnTwo={`${micro.title}`}
                                  setCheckedFromProps={isMicroChecked(
                                    searchType,
                                    selectedTopics,
                                    selectedSubtopics,
                                    micro,
                                    subtopic,
                                    topic
                                  )}
                                  onCheck={() =>
                                    setMicrocompetencyDispatch(
                                      searchType,
                                      nestedMicro,
                                      nestedSubtopic,
                                      topic
                                    )
                                  }
                                  onUncheck={() =>
                                    deselectMicrocompetencyDispatch(
                                      searchType,
                                      micro.uuid,
                                      subtopic.uuid,
                                      topic.uuid
                                    )
                                  }
                                  rowOffset="96px"
                                  leaf
                                />
                              );
                            })}
                      </CheckboxAccordionRow>
                    );
                  })}
            </CheckboxAccordionRow>
          );
        })
      ) : (
        <div>No Results</div>
      )}
    </div>
  );
};

MicroSearchResults.propTypes = {
  searchResultTopics: PropTypes.arrayOf(PropTypes.object),
  selectedTopics: PropTypes.object,
  selectedSubtopics: PropTypes.object,
  searchDispatch: PropTypes.func,
  searchType: PropTypes.oneOf(['microcompetency', 'subtopic', 'topic', ''])
};

MicroSearchResults.defaultProps = {
  searchResultTopics: [],
  selectedTopics: {},
  selectedSubtopics: {},
  searchDispatch: undefined,
  searchType: ''
};

export default MicroSearchResults;
