import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import CognitiveProgramTermRowHeader from '../DataRows/CognitiveProgramTermRowHeader';
import CognitiveProgramTermRowRead from '../DataRows/CognitiveProgramTermRowRead';
import CognitiveMilestoneTermRowHeader from '../DataRows/CognitiveMilestoneTermRowHeader';
import CognitiveMilestoneTermRowRead from '../DataRows/CognitiveMilestoneTermRowRead';
import TermCredits from '../../TranscriptCalculations/TermCredits';
import TermGrade from '../../TranscriptCalculations/TermGrade';
import TermAvgScore from '../../TranscriptCalculations/TermAvgScore';
import TermCreditsCumulative from '../../TranscriptCalculations/TermCreditsCumulative';
import TermAvgScoreCumulative from '../../TranscriptCalculations/TermAvgScoreCumulative';
import TermGradeCumulative from '../../TranscriptCalculations/TermGradeCumulative';
import { selectTranscriptCognitiveByTerm } from '../../../../../redux/selectors/transcriptCognitiveSelectors';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .accordion_row_label {
    flex-grow: 2;
  }

  && {
    .accordion_row_label div {
      font-size: 14px;
      letter-spacing: 0.15;
      color: ${props => props.theme.fontColors.darker};
    }
  }

  width: 100%;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.grey[500]};
  font-size: 14px;
`;

const AccordionArea = styled.div`
  width: 100%;
  scroll: auto;
  font-size: 12px;
`;

const Calculations = styled.div`
  display: flex;
  font-size: 12px;
`;

const Spacing = styled.div`
  width: 120px;
`;

const Cumulation = styled.div`
  display: flex;
  font-size: 12px;
  padding-top: 5px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Stub = styled.div`
  width: 66px;
`;

export default function ByTermCognitive({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  termYear
}) {
  const transcripts = useSelector(state =>
    selectTranscriptCognitiveByTerm(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      termYear
    )
  );

  return (
    <>
      <Accordion
        columnGutters="0px"
        rowOffset="66px"
        expandWidth="66px"
        height="70px"
        label={
          <Group>
            <Label>{`${termYear}`}</Label>
          </Group>
        }
        columnOne={
          <>
            <Calculations>
              <Spacing />
              <Spacing>
                <TermCredits
                  programUuid={programUuid}
                  userUuid={userUuid}
                  cohortUuid={cohortUuid}
                  source={source}
                  termYear={termYear}
                />
              </Spacing>
              <Spacing>
                <TermGrade
                  programUuid={programUuid}
                  userUuid={userUuid}
                  cohortUuid={cohortUuid}
                  source={source}
                  termYear={termYear}
                />
              </Spacing>
              <Spacing>
                <TermAvgScore
                  programUuid={programUuid}
                  userUuid={userUuid}
                  cohortUuid={cohortUuid}
                  source={source}
                  termYear={termYear}
                />
              </Spacing>
              <Stub>&nbsp;</Stub>
            </Calculations>
            {source === 'Program' && (
              <Cumulation>
                <Spacing>Cumulative Total</Spacing>
                <Spacing>
                  <TermCreditsCumulative
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    source={source}
                    termYear={termYear}
                  />
                </Spacing>
                <Spacing>
                  <TermGradeCumulative
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    source={source}
                    termYear={termYear}
                  />
                </Spacing>
                <Spacing>
                  <TermAvgScoreCumulative
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    source={source}
                    termYear={termYear}
                  />
                </Spacing>
                <Stub>&nbsp;</Stub>
              </Cumulation>
            )}
          </>
        }
      >
        <AccordionArea>
          {transcripts?.length > 0 && source === 'Program' && (
            <CognitiveProgramTermRowHeader />
          )}

          {transcripts?.length > 0 && source === 'Milestone' && (
            <CognitiveMilestoneTermRowHeader />
          )}

          {transcripts?.length > 0 &&
            source === 'Program' &&
            _.sortBy(transcripts, [
              'date',
              'itemCode'
            ]).map((transcript, index) => (
              <CognitiveProgramTermRowRead
                key={transcript?.uuid}
                index={index}
                transcript={transcript}
              />
            ))}

          {transcripts?.length > 0 &&
            source === 'Milestone' &&
            _.sortBy(transcripts, ['date', 'itemCode']).map(transcript => (
              <CognitiveMilestoneTermRowRead
                key={transcript?.uuid}
                transcript={transcript}
              />
            ))}
        </AccordionArea>
      </Accordion>
    </>
  );
}

ByTermCognitive.propTypes = {
  termYear: PropTypes.string,
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string
};

ByTermCognitive.defaultProps = {
  termYear: undefined,
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined
};
