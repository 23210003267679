import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectSubjectRank } from '../../../../redux/selectors/applicantRankSelectors';

export default function RankSubject({
  userUuid,
  cohortUuid,
  classificationUuid
}) {
  const scopeRank = useSelector(state =>
    selectSubjectRank(state, userUuid, cohortUuid, classificationUuid)
  );

  const rank =
    scopeRank?.cohortRankRelative && scopeRank?.cohortRankPotential
      ? `${scopeRank?.cohortRankRelative} of ${scopeRank?.cohortRankPotential}`
      : 'N/A';

  return <>{rank}</>;
}

RankSubject.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,

  classificationUuid: PropTypes.string
};

RankSubject.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  classificationUuid: undefined
};
