import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import { Column } from '../../../Library/FormComponents';
import CompetencyCollectionFormDetails from './Sections/CompetencyCollectionFormDetails';
import NewCompetencyContainer from '../Competency/NewCompetencyContainer';
import EditCompetencyContainer from '../Competency/EditCompetencyContainer';

const FormLayout = styled.div`
  padding: 1.5rem 1.25rem 1rem 1.25rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const CompetencyCollectionForm = ({
  competencyCollection,
  buttonText,
  handleNameChange,
  handleDefaultCollectionChange,
  onSubmit,
  unsavedChanges,
  showValidationErrors,
  onFormClose,
  setUnsavedChange,
  removeUnsavedChange,
  withCompetencies,
  isNewForm
}) => {
  // eslint-disable-next-line prefer-const
  let history = useHistory();

  const { competencies } = competencyCollection;

  const [newCompetencyOpen, setNewCompetencyOpen] = useState(false);

  const openCompetencyForm = () => {
    setNewCompetencyOpen(true);
  };

  const closeCompetencyForm = () => {
    removeUnsavedChange('newCompetency');
    setNewCompetencyOpen(false);
  };

  return (
    <FormLayout>
      <CompetencyCollectionFormDetails
        uuid={competencyCollection.uuid}
        name={competencyCollection.name}
        is_default_collection={competencyCollection.is_default_collection}
        unsavedChanges={unsavedChanges}
        showValidationErrors={showValidationErrors}
        handleNameChange={handleNameChange}
        handleDefaultCollectionChange={handleDefaultCollectionChange}
        onSubmit={onSubmit}
        onFormClose={onFormClose}
        buttonText={buttonText}
        isNewForm={isNewForm}
      />
      {withCompetencies && (
        <Column size="100%">
          <>
            {competencies &&
              competencies.length > 0 &&
              competencies.map((competency, index) => {
                const openMicroSearch = () =>
                  history.push(
                    `/program-management/competency-collection/${competencyCollection.uuid}/competency/${competency.uuid}/add-microcompetencies`
                  );

                return (
                  <EditCompetencyContainer
                    key={competency.uuid}
                    competencyUuid={competency.uuid}
                    competencyNumber={index}
                    openMicroSearch={openMicroSearch}
                    unsavedChanges={unsavedChanges}
                    setUnsavedChange={setUnsavedChange}
                    removeUnsavedChange={removeUnsavedChange}
                  />
                );
              })}
          </>
          <>
            {newCompetencyOpen ? (
              <NewCompetencyContainer
                competencyCollection={competencyCollection}
                onFormClose={closeCompetencyForm}
                competencyNumber={competencies.length}
                setUnsavedChange={setUnsavedChange}
                unsavedChanges={unsavedChanges}
              />
            ) : (
              <ButtonInline onClick={openCompetencyForm} noHoverBg>
                Add Competency
              </ButtonInline>
            )}
          </>
        </Column>
      )}
    </FormLayout>
  );
};

CompetencyCollectionForm.propTypes = {
  competencyCollection: PropTypes.object,
  buttonText: PropTypes.string,
  unsavedChanges: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  isNewForm: PropTypes.bool,
  withCompetencies: PropTypes.bool,
  handleNameChange: PropTypes.func,
  handleDefaultCollectionChange: PropTypes.func,
  onSubmit: PropTypes.func,
  setUnsavedChange: PropTypes.func,
  removeUnsavedChange: PropTypes.func,
  onFormClose: PropTypes.func
};
CompetencyCollectionForm.defaultProps = {
  competencyCollection: {},
  buttonText: 'Create',
  unsavedChanges: {},
  showValidationErrors: false,
  isNewForm: false,
  withCompetencies: false,
  handleNameChange: undefined,
  handleDefaultCollectionChange: undefined,
  onSubmit: undefined,
  setUnsavedChange: undefined,
  removeUnsavedChange: undefined,
  onFormClose: undefined
};

export default CompetencyCollectionForm;
