const headersLegacyGrades = [
  {
    field: 'studentID',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'grade',
    type: 'STRING',
    allowEmpty: false
  }
];

module.exports = {
  headersLegacyGrades
};
