import React from 'react';
import styled from 'styled-components';
import { ButtonIcon } from '@xcomp/xcomp-design-library';
import ArtTrack from '@material-ui/icons/ArtTrack';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const Label = styled.div`
  display: flex;
  width: 100%;
  font-size: 20px;
  color: ${props => props.theme.fontColors.darker};
`;

const ChipSC = styled(Chip)`
  margin-left: 10px;
`;
const Layout = styled.div`
  width: 100%;
  display: flex;
  //justify-content: flex-start;
`;

const AccordionLabel = ({ viewDefault, handleChange }) => {
  return (
    <Label>
      <Typography style={{ fontSize: '20px', textWrap: 'nowrap' }}>
        Quantitative Skills
      </Typography>
      <Layout>
        <ButtonIcon
          aria-controls="menu"
          aria-haspopup="true"
          noHoverBg
          onClick={e => {
            e.stopPropagation();
            handleChange();
          }}
        >
          <ChipSC
            icon={<ArtTrack />}
            size="small"
            label={
              viewDefault ? 'View All Collections' : 'View Default Collections'
            }
          />
        </ButtonIcon>
      </Layout>
    </Label>
  );
};

AccordionLabel.propTypes = {
  viewDefault: PropTypes.bool,
  handleChange: PropTypes.func
};

AccordionLabel.defaultProps = {
  viewDefault: false,
  handleChange: () => {}
};

export default AccordionLabel;
