import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getTermsByAcademicYear,
  getTermsByAcademicYears
} from '../../api/termApi';
import {
  doGetTermsSuccess,
  doGetTermsError,
  doGetTermsByAcademicYearsSuccess,
  doGetTermsByAcademicYearsError
} from '../actions/termActions';
import { GET_TERMS, GET_TERMS_BY_ACADEMIC_YEARS } from '../actions-type';

function* handleGetTerms(action) {
  const { academicYearUuid } = action.payload;
  try {
    const result = yield call(getTermsByAcademicYear, academicYearUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetTermsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetTermsError(error));
  }
}

function* handleGetTermsByAcademicYears(action) {
  try {
    const result = yield call(getTermsByAcademicYears, action.payload);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetTermsByAcademicYearsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetTermsByAcademicYearsError(error));
  }
}

const term = [
  takeEvery(GET_TERMS, handleGetTerms),
  takeEvery(GET_TERMS_BY_ACADEMIC_YEARS, handleGetTermsByAcademicYears)
];

export { term };
