import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Body1, Body2, Caption } from '@xcomp/xcomp-design-library';
import { sortByProperty } from '../../../../helpers/utilities';
import DeleteModal from '../../../Library/Modal/DeleteModal/DeleteModal';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import { Column } from '../../../Library/FormComponents';

const ProcedureCodeCategoryAccordion = styled(
  ({ withBottomBorder, withTopBorder, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  width: 100%;

  .align_right {
    margin-left: auto;

    span {
      font-size: 12px;
      letter-spacing: 0.4;
      color: ${props => props.theme.fontColors.dark};
    }
  }
`;

const CollectionLayout = styled.div`
  padding-left: 82px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  p {
    margin: 0;
  }
`;

const CompetencyDetails = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
`;

const CompetencyCollectionRow = ({
  competencyCollection,
  isDeleteModalOpen,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete
}) => {
  // eslint-disable-next-line prefer-const
  let history = useHistory();

  const navigateEditOnClick = () => {
    const historyString = `/program-management/competency-collection/${competencyCollection.uuid}/edit`;
    history.push(historyString);
  };

  const competenyCollectionActions = [
    {
      label: 'Edit',
      onClick: navigateEditOnClick,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  let competencyCollectionNameString;
  if (competencyCollection) {
    competencyCollectionNameString = competencyCollection.is_default_collection
      ? `Default: ${competencyCollection.name}`
      : competencyCollection.name;
  } else {
    competencyCollectionNameString = '';
  }

  return (
    <>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={handleDeleteModalClose}
        handleDelete={handleDelete}
        type="Competency Collection"
      />
      <ProcedureCodeCategoryAccordion
        columnGutters="1.5rem"
        isTopLevel
        withBottomBorder
        expandWidth="66px"
        columnOne={competencyCollectionNameString}
        actions={competenyCollectionActions}
      >
        <CollectionLayout>
          {competencyCollection &&
          competencyCollection.competencies &&
          competencyCollection.competencies.length > 0 ? (
            competencyCollection.competencies
              .sort((a, b) => sortByProperty(a, b, 'order'))
              .map((competency, index) => (
                <React.Fragment key={competency.uuid}>
                  <Column size="100%">
                    <CompetencyDetails>
                      <Caption>Competency {index + 1}</Caption>
                      <Body2>{competency.name}</Body2>
                      {/* TODO: add topic & microcompetencies count here */}
                    </CompetencyDetails>
                  </Column>
                </React.Fragment>
              ))
          ) : (
            <Body1>There are no Competencies assigned to this collection</Body1>
          )}
        </CollectionLayout>
      </ProcedureCodeCategoryAccordion>
    </>
  );
};

CompetencyCollectionRow.propTypes = {
  competencyCollection: PropTypes.object.isRequired,
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func
};

CompetencyCollectionRow.defaultProps = {
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined
};

export default CompetencyCollectionRow;
