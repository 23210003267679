import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { Dialog, DialogTitle, FormGroup } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import { useSelector } from 'react-redux';

import {
  CancelButton,
  SubmitButton,
  TextFieldWithError
} from '../../../../../Library/FormComponents';
import { validateStringLength } from '../../../../../../helpers/validation/validateGeneric';
import { validateCompetencyDescription } from '../../../../../../helpers/validation/validateCompetencyCollection';
import {
  createGroupAtom,
  creatingGroupAtom,
  getCollectionDetails,
  newGroupAtom
} from '../state/editState';
import Thresholds from '../../Thresholds';

const NewGroupModal = ({ open, onClose }) => {
  const { quantitativeSkillUuid } = useParams();
  const programUuid = useSelector(state => state.userState.selectedProgramUuid);

  const [state, setNewGroup] = useAtom(newGroupAtom);
  const creating = useAtomValue(creatingGroupAtom);
  const createGroup = useSetAtom(createGroupAtom);
  const getCollection = useSetAtom(getCollectionDetails);

  const handleChange = useCallback(
    e => {
      setNewGroup({
        ...state,
        [e.target.name]: e.target.value
      });
    },
    [setNewGroup, state]
  );

  const handleCreate = useCallback(async () => {
    await createGroup(quantitativeSkillUuid);
    onClose();
    await getCollection({ quantitativeSkillUuid, programUuid });
  }, [createGroup, quantitativeSkillUuid, onClose, getCollection, programUuid]);

  const validateNameField = useCallback(
    name => validateStringLength(name, 100, true),
    []
  );
  const validateDescriptionField = useCallback(
    name => validateCompetencyDescription(name, 100, 10000, true),
    []
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Procedure Code Group</DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControl style={{ display: 'flex', gap: '24px' }}>
            <TextFieldWithError
              autoFocus
              name="name"
              value={state.name}
              onChange={handleChange}
              margin="normal"
              label="Procedure Code Group Name"
              variant="outlined"
              handleValidate={() => validateNameField(state.name)}
              hasErrors={validateNameField(state.name).invalid}
            />
            <TextFieldWithError
              name="description"
              value={state.description}
              onChange={handleChange}
              margin="normal"
              label="Procedure Code Group Description"
              variant="outlined"
              multiline
              minRows="4"
              maxRows="16"
              handleValidate={() => validateDescriptionField(state.description)}
              hasErrors={validateDescriptionField(state.description).invalid}
            />
          </FormControl>
        </FormGroup>
        <Thresholds handleChange={handleChange} thresholds={state} />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} noHoverBg>
          Cancel
        </CancelButton>
        <SubmitButton
          onClick={handleCreate}
          disabled={
            creating ||
            validateDescriptionField(state.description).invalid ||
            validateNameField(state.name).invalid
          }
        >
          {creating ? 'Creating...' : 'Create'}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};

NewGroupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default NewGroupModal;
