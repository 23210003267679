import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ByDefaultAptitudeSkillContainer from './ByDefaultAptitudeSkillContainer';

const Layout = styled.div`
  width: 100%;
  font-size: 12px;
`;

export default function ByDefaultAptitude({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  isAdmin,
}) {
  return (
    <Layout>
      <ByDefaultAptitudeSkillContainer
        userUuid={userUuid}
        cohortUuid={cohortUuid}
        programUuid={programUuid}
        category={category}
        source={source}
        isAdmin={isAdmin}
      />
    </Layout>
  );
}

ByDefaultAptitude.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  isAdmin: PropTypes.bool,
};
ByDefaultAptitude.defaultProps = {
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  isAdmin: false,
};
