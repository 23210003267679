import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const userGroupsByPartUuid = createSelector(
  state => state,
  (_, partUuid) => partUuid,
  (redux, partUuid) => {
    const userGroupsByPartUuidORM = ormCreateSelector([orm], session => {
      const studentRoster = session.UserGroup.all()
        .filter(
          userGroup => userGroup.group && userGroup.group.partUuid === partUuid
        )
        .toRefArray();
      return studentRoster;
    });
    const groups = userGroupsByPartUuidORM(redux);
    return groups;
  }
);
