import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Heading3 } from '@xcomp/xcomp-design-library';
import SimpleModal from '../Library/Modal/SimpleModal';

const Header = styled.div`
  background: ${props => props.theme.colors.status.error};
  color: ${props => props.theme.colors.white};
  padding: 1rem 1.25rem;
  font-size: 24px;
`

const ModalBody = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;

  h3 {
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

const Card = styled.div`
  padding: 5px 10px;
  border-radius: 3px;
  background: ${props => props.theme.colors.grey[200]};
  margin-bottom: 10px;
`

const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid;
`

const Row = styled.div`
  display: flex;

  & :first-child {
    text-transform: capitalize;
    margin-right: 5px;
    font-weight: 600;
  }
`

const ObjectiveInvalidScoresModal = ({
  handleModalClose,
  errors,
}) => {
  return (
    <SimpleModal
      open={true}
      onClose={handleModalClose}
      maxWidth="md"
    >
      <>
        <Header>
          Invalid Scores
        </Header>
        <ModalBody>
          {errors.rowsWithoutGrader1.length > 0 &&
            <>
              <Heading3>Scores without <i><b>grader1</b></i> assigned</Heading3>
              <Divider />
              <div style={{marginTop: 10}}>
                {errors.rowsWithoutGrader1.map(r => 
                  <Card key={r.uuid}>
                    {Object.keys(r).filter(k => k !== 'uuid').map(k => 
                      <Row>
                        <div>{k}</div>
                        <div>{r[k]}</div>
                      </Row>
                    )}
                  </Card>
                )}
              </div>
            </>
          }
          {errors.rowsWithoutGrader1Uuid.length > 0 &&
            <>
              <Heading3>Scores without <i><b>grader1Uuid</b></i></Heading3>
              <Divider />
              <div style={{marginTop: 10}}>
                {errors.rowsWithoutGrader1Uuid.map(r => 
                  <Card key={r.uuid}>
                    {Object.keys(r).filter(k => k !== 'uuid').map(k => 
                      <Row>
                        <div>{k}:</div>
                        <div>{r[k]}</div>
                      </Row>
                    )}
                  </Card>
                )}
              </div>
            </>
          }
        </ModalBody>
      </>
    </SimpleModal>
  );
};

ObjectiveInvalidScoresModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
ObjectiveInvalidScoresModal.defaultProps = {

};

export default ObjectiveInvalidScoresModal;
