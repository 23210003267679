import { Model, attr, oneToOne } from 'redux-orm';

import {
  GET_CLASSIFICATIONS_SUCCESS,
  GET_SUBJECTS_SUCCESS,
  POST_MILESTONES_SUCCESS,
  GET_TRANSCRIPT_SUCCESS
} from '../actions-type';

class ExamMilestone extends Model {
  static reducer(action, ExamMilestone, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_CLASSIFICATIONS_SUCCESS: {
        const classifications = [...payload.classifications];

        classifications.forEach(classification => {
          if (classification.category === 'Cognitive') {
            if (classification.exam_milestone) {
              const copy = { ...classification.exam_milestone };
              delete copy.conversion_milestones;
              ExamMilestone.upsert(copy);
            }
          }
        });
        break;
      }

      case GET_SUBJECTS_SUCCESS: {
        const subjects = [...payload.subjects];
        subjects.forEach(classification => {
          if (classification.exam_milestone) {
            const copy = { ...classification.exam_milestone };
            delete copy.conversion_milestones;

            ExamMilestone.upsert(copy);
          }
        });
        break;
      }

      case POST_MILESTONES_SUCCESS: {
        const milestones = [...payload.milestones];
        milestones.forEach(milestone => {
          ExamMilestone.upsert(milestone);
        });
        break;
      }

      case GET_TRANSCRIPT_SUCCESS: {
        const classifications = [...payload.classifications];

        classifications.forEach(classification => {
          if (classification.category === 'Cognitive') {
            if (classification.exam_milestone) {
              const copy = { ...classification.exam_milestone };
              delete copy.conversion_milestones;
              ExamMilestone.upsert(copy);
            }
          }
        });
        break;
      }

      default:
        return session;
    }
  }
}

ExamMilestone.fields = {
  uuid: attr(),
  examId: attr(),
  examName: attr(),
  credits: attr(),
  sourceInstitutionUuid: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  classificationUuid: oneToOne({
    to: 'Classification',
    as: 'classification',
    relatedName: 'exam_milestone'
  })
};

ExamMilestone.modelName = 'ExamMilestone';

ExamMilestone.options = {
  idAttribute: 'uuid'
};

export default ExamMilestone;
