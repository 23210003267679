import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import Date from '../../../../Library/Date';
import FormField from '../../../../Library/FormField';

import {
  validateInputString,
  validateSelectDate
} from '../../../../../helpers/validation/validateGeneric';

const Center = styled.div`
  margin-top: 30px;
  margin-left: 10px;
`;

export default function TermAdd({
  indentifier,
  period,
  startDate,
  endDate,
  hasErrors,
  handleTermChange,
  handleTermDateChange,
  handleRemoveTerm
}) {
  return (
    <>
      <Grid item sm={5} xs={12}>
        <FormField
          name="period"
          label="Term Title*"
          value={period}
          onBlur={event => handleTermChange(indentifier, event)}
          handleValidate={validateInputString}
          hasErrors={hasErrors}
        />
      </Grid>
      <Grid item sm={3} xs={12}>
        <Date
          dataTestId="term-add-role-start-date"
          name="startDate"
          label="Start Date*"
          value={startDate}
          indentifier={indentifier}
          hasErrors={hasErrors}
          handleValidate={validateSelectDate}
          handleChange={handleTermDateChange}
        />
      </Grid>
      <Grid item sm={3} xs={12}>
        <Date
          dataTestId="term-add-role-end-date"
          name="endDate"
          label="End Date"
          value={endDate}
          indentifier={indentifier}
          hasErrors={hasErrors}
          handleValidate={validateSelectDate}
          handleChange={handleTermDateChange}
        />
      </Grid>
      <Grid item xs={1}>
        <Center>
          <DeleteIcon onClick={() => handleRemoveTerm(indentifier)} />
        </Center>
      </Grid>
    </>
  );
}

TermAdd.propTypes = {
  indentifier: PropTypes.string,
  period: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  hasErrors: PropTypes.bool,
  handleTermChange: PropTypes.func,
  handleTermDateChange: PropTypes.func,
  handleRemoveTerm: PropTypes.func
};

TermAdd.defaultProps = {
  indentifier: '',
  period: '',
  startDate: '',
  endDate: '',
  hasErrors: false,
  handleTermChange: undefined,
  handleTermDateChange: undefined,
  handleRemoveTerm: undefined
};
