import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { classificationsSelector } from '../../../../../../redux/selectors/classificationSelectors';
import MilestoneTest from './MilestoneTest';
import AccordionRowContainer from '../../../../../Library/AccordionRow/AccordionRowContainer';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .accordion_row_label {
    flex-grow: 2;
  }

  && {
    .accordion_row_label div {
      letter-spacing: 0.15;
    }
  }

  width: 100%;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div``;

const Input = styled.div`
  margin-left: 46px;
  color: ${props => props.theme.fontColors.darker};
`;

export default function MilestoneSubject({ programUuid }) {
  const classifications = useSelector(
    state =>
      classificationsSelector(state, programUuid, 'Cognitive', 'Program'),
    _.isEqual
  );

  return (
    <>
      {classifications.map(classification => (
        <Accordion
          rowOffset="132px"
          key={classification.uuid}
          label={
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <Group>
                  <Label>Subject</Label>
                  <Input>{classification.scope}</Input>
                </Group>
              </Grid>
            </Grid>
          }
        >
          <MilestoneTest classificationUuid={classification.uuid} />
        </Accordion>
      ))}
    </>
  );
}

MilestoneSubject.propTypes = {
  programUuid: PropTypes.string
};

MilestoneSubject.defaultProps = {
  programUuid: undefined
};
