import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { classificationsSelectorByCategorySource } from '../../../../../redux/selectors/classificationSelectors';
import ByDefaultAptitudeSkill from './ByDefaultAptitudeSkill';

export default function ByDefaultAptitudeSkillContainer({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  isAdmin,
}) {
  const skills = useSelector(state =>
    classificationsSelectorByCategorySource(
      state,
      programUuid,
      category,
      source
    )
  )?.map(classification => classification);

  return (
    <>
      {skills.map(skill => (
        <ByDefaultAptitudeSkill
          key={skill.uuid}
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
          skill={skill.scope}
          classificationUuid={skill.uuid}
          isAdmin={isAdmin}
        />
      ))}
    </>
  );
}

ByDefaultAptitudeSkillContainer.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  isAdmin: PropTypes.bool,
};
ByDefaultAptitudeSkillContainer.defaultProps = {
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  isAdmin: false,
};
