import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

const Layout = styled.div`
  width: 100%;
  margin-right: 30px;
`;

const Label = styled.div`
  font-size: 0.8rem;
  margin-bottom: 10px;
  color: ${props => props.theme.fontColors.dark};
`;

const TermRow = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 0.8rem;
`;

const Period = styled.div`
  width: 40%;
`;

const StartDate = styled.div`
  width: 30%;
`;

const EndDate = styled.div`
  width: 30%;
`;

export default function TermShow({ terms }) {
  return (
    <Layout>
      <Label>Terms</Label>
      {terms.map(term => (
        <TermRow key={term.uuid}>
          <Period>{term.period}</Period>
          <StartDate>{moment(term.startDate).format('ll')}</StartDate>
          <EndDate>{moment(term.endDate).format('ll')}</EndDate>
        </TermRow>
      ))}
    </Layout>
  );
}

TermShow.propTypes = {
  terms: PropTypes.array
};

TermShow.defaultProps = {
  terms: []
};
