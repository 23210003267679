import React from 'react';
import PropTypes from 'prop-types';
import LetterGradeShow from './LetterGradeShow';

export default function LetterGradeShowContainer({
  letterGrade,
  handleEditMode,
  handleDeleteModalOpen
}) {
  return (
    <LetterGradeShow
      letterGrade={letterGrade}
      handleEditMode={handleEditMode}
      handleDeleteModalOpen={handleDeleteModalOpen}
    />
  );
}

LetterGradeShowContainer.propTypes = {
  letterGrade: PropTypes.object,
  handleEditMode: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func
};
LetterGradeShowContainer.defaultProps = {
  letterGrade: {},
  handleEditMode: undefined,
  handleDeleteModalOpen: undefined
};
