import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEqual, parseISO } from 'date-fns';

import Syllabus from './Syllabus';
import {
  doGetSyllabus,
  doSetSyllabus
} from '../../redux/actions/syllabusActions';
import { encountersSelector } from '../../redux/selectors/encountersSelectors';
import { assessmentsSelector } from '../../redux/selectors/assessmentSelectors';
import { partSelector } from '../../redux/selectors/partsSelectors';
import { syllabusCourseSelector } from '../../redux/selectors/syllabusSelectors';
import { syllabusSectionSelector } from '../../redux/selectors/sectionsSelectors';

const isLinkedEncounter = encounter => {
  return encounter.encounter_type.name.toLowerCase().includes('linked');
};

const sortDates = (dateA, dateB) => {
  if (!dateA) {
    return 1;
  }

  if (!dateB) {
    return -1;
  }

  return moment(dateA) - moment(dateB);
};

const sortWithLinked = (itemA, itemB) => {
  let dateA = itemA.startDate;
  let dateB = itemB.startDate;
  const isItemAEncounter = itemA.type === 'encounter';
  const isItemBEncounter = itemB.type === 'encounter';

  if (isItemAEncounter) {
    dateA = isLinkedEncounter(itemA)
      ? _.get(itemA, 'linked_assessment.startDate', null)
      : itemA.startDate;
  }

  if (isItemBEncounter) {
    dateB = isLinkedEncounter(itemB)
      ? _.get(itemB, 'linked_assessment.startDate', null)
      : itemB.startDate;
  }

  if (isEqual(parseISO(dateA), parseISO(dateB))) {
    if (isItemAEncounter && !isItemBEncounter) {
      return -1;
    } else if (!isItemAEncounter && isItemBEncounter) {
      return 1;
    }
  }

  if (!dateA) {
    return 1;
  }

  if (!dateB) {
    return -1;
  }

  return moment(dateA) - moment(dateB);
};

const sortWithLinkedReverse = (itemA, itemB) => {
  let dateA = itemA.startDate;
  let dateB = itemB.startDate;

  const isItemAEncounter = itemA.type === 'encounter';
  const isItemBEncounter = itemB.type === 'encounter';

  if (isItemAEncounter) {
    dateA = isLinkedEncounter(itemA)
      ? _.get(itemA, 'linked_assessment.startDate', null)
      : itemA.startDate;
  }

  if (isItemBEncounter) {
    dateB = isLinkedEncounter(itemB)
      ? _.get(itemB, 'linked_assessment.startDate', null)
      : itemB.startDate;
  }

  if (isEqual(parseISO(dateA), parseISO(dateB))) {
    if (isItemAEncounter && !isItemBEncounter) {
      return -1;
    } else if (!isItemAEncounter && isItemBEncounter) {
      return 1;
    }
  }

  if (!dateA) {
    return 1;
  }

  if (!dateB) {
    return -1;
  }

  return moment(dateB) - moment(dateA);
};

const sortDatesReverse = (dateA, dateB) => {
  if (!dateA) {
    return 1;
  }

  if (!dateB) {
    return -1;
  }
  return moment(dateB) - moment(dateA);
};

export default function SyllabusContainer({ match }) {
  const dispatch = useDispatch();
  const [filterSelection, setFilterSelection] = useState('all');
  const [sortSelection, setSortSelection] = useState('ascending');
  const [haveFetchedSyllabus, setHaveFetchedSyllabus] = useState(false);

  const encounters = useSelector(
    state => encountersSelector(state, match.params.partUuid),
    _.isEqual
  );

  const assessments = useSelector(
    state => assessmentsSelector(state, match.params.partUuid),
    _.isEqual
  );

  const part = useSelector(state => partSelector(state), shallowEqual);

  const course = useSelector(
    state => syllabusCourseSelector(state),
    shallowEqual
  );

  const section = useSelector(
    state => syllabusSectionSelector(state),
    shallowEqual
  );

  const { courseUuid, partUuid, sectionUuid } = match.params;

  useEffect(() => {
    if (!haveFetchedSyllabus) {
      dispatch(doGetSyllabus(courseUuid, sectionUuid, partUuid));
      dispatch(doSetSyllabus(courseUuid, sectionUuid, partUuid));
      setHaveFetchedSyllabus(true);
    }
  }, [dispatch, courseUuid, partUuid, sectionUuid, haveFetchedSyllabus]);

  const handleFilterSelection = event => {
    const { value } = event.target;
    setFilterSelection(value);
  };

  const handleSortSelection = sortDirection => {
    setSortSelection(sortDirection);
  };

  const sortSyllabus = () => {
    switch (filterSelection) {
      case 'encounter': {
        if (sortSelection === 'descending') {
          return encounters.sort((a, b) => sortWithLinkedReverse(a, b));
        } else {
          return encounters.sort((a, b) => sortWithLinked(a, b));
        }
      }
      case 'assessment': {
        if (sortSelection === 'descending') {
          return assessments.sort((a, b) =>
            sortDatesReverse(a.startDate, b.startDate)
          );
        } else {
          return assessments.sort((a, b) =>
            sortDates(a.startDate, b.startDate)
          );
        }
      }
      default: {
        const items = [...assessments, ...encounters];

        if (sortSelection === 'descending') {
          return items.sort((a, b) => sortWithLinkedReverse(a, b));
        } else {
          return items.sort((a, b) => sortWithLinked(a, b));
        }
      }
    }
  };

  const courseNumber = _.get(course, 'course_master.courseNumber', '');
  const sectionNumber = _.get(section, 'sectionNumber', '')
    .toString()
    .padStart(2, 0);
  const partNumber = _.get(part, 'partNumber', '').toString().padStart(2, 0);
  const partTitle = _.get(part, 'title', '');

  return (
    <Syllabus
      sectionUuid={match.params.sectionUuid}
      partUuid={match.params.partUuid}
      courseNumber={courseNumber}
      sectionNumber={sectionNumber}
      partNumber={partNumber}
      partTitle={partTitle}
      filterSelection={filterSelection}
      sortSelection={sortSelection}
      handleFilterSelection={handleFilterSelection}
      handleSortSelection={handleSortSelection}
      syllabusItems={sortSyllabus()}
    />
  );
}

SyllabusContainer.propTypes = {
  match: PropTypes.object
};

SyllabusContainer.defaultProps = {
  match: {}
};
