import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import SimpleModal from '../../../../Library/Modal/SimpleModal';
import FormField from '../../../../Library/FormField';
import FormSelectError from '../../../../Library/FormSelectError';
import Date from '../../../../Library/Date';
import DateYear from '../../../../Library/DateYear';
import InstitutionCodeSearch from '../../../../LibraryExternal/InstitutionCodeSearch';
import { optionsTermTypes, optionsTerms } from '../../../../../helpers/options';
import {
  validateSelectDate,
  validateSelectField,
  validateInputNumber
} from '../../../../../helpers/validation/validateGeneric';

import { convertNullToString } from '../../../../../helpers/utilities';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 900px;
`;

const FormHeader = styled.div``;

const FormArea = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-top: 30px;
`;

const TitleArea = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
`;

const SubTitle = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InvisibleLineBreak = styled.div`
  height: 4px;
`;

const ActionsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px;
`;

const SaveButton = styled(ButtonPrimary)`
  && {
    margin-left: 20px;
  }
`;

const Aptitude = ({
  loading,
  formType,
  category,
  source,
  user,
  applicationDate,
  date,
  term,
  year,
  itemAge,
  attempt,
  examAptitudeUuid,
  examName,
  classificationUuid,
  conversionAptitudeUuid,
  sourceDegreeLevelUuid,
  sourceInstitutionUuid,
  letterGradeUuid,
  weight,
  modalOpen,
  score,
  degreeLevelOptions,
  classificationOptions,
  examAptitudeOptions,
  conversionOptions,
  letterGradeOptions,
  hasErrors,
  handleModalClose,
  handleChangeDate,
  handleChange,
  handleRequest,
  handleSourceInstitutionUuid
}) => {
  return (
    <SimpleModal open={modalOpen} onClose={handleModalClose} maxWidth="xl">
      <ModalBody>
        <FormHeader>
          <Grid item sm={12} xs={12}>
            <TitleArea>
              <Grid container direction="row" alignItems="center">
                <Grid item sm={11} xs={11}>
                  <Title>
                    {`${formType}`} Transcript for {`${category} ${source}`}
                  </Title>
                  <SubTitle>
                    {user?.firstName} {user?.lastName}
                  </SubTitle>
                </Grid>
                <Grid item sm={1} xs={1}>
                  <CloseModal>
                    <IconButton onClick={handleModalClose}>
                      <CloseIcon />
                    </IconButton>
                  </CloseModal>
                </Grid>
              </Grid>
            </TitleArea>
          </Grid>
          <Grid item sm={12} xs={12}>
            {loading ? <LinearProgress /> : <InvisibleLineBreak />}
          </Grid>
        </FormHeader>
        <FormArea>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <Date
                name="applicationDate"
                label="Application Date*"
                value={applicationDate}
                indentifier={null}
                hasErrors={hasErrors}
                handleChange={handleChangeDate}
                handleValidate={validateSelectDate}
              />
            </Grid>
            <Grid item sm={2} xs={2}>
              <Date
                name="date"
                label="Date"
                value={date}
                indentifier={null}
                handleChange={handleChangeDate}
                dateFormat="MM-dd-yyyy"
                hasErrors={hasErrors}
                handleValidate={validateSelectDate}
              />
            </Grid>
            <Grid item sm={3} xs={3}>
              <FormSelectError
                name="termType"
                value="Semester"
                label="Term Type*"
                options={optionsTermTypes}
                disabled={true}
              />
            </Grid>
            <Grid item sm={3} xs={3}>
              <FormSelectError
                name="term"
                value={convertNullToString(term)}
                label="Term*"
                handleChange={handleChange}
                options={optionsTerms.Semester}
                disabled={true}
              />
            </Grid>
            <Grid item sm={2} xs={2}>
              <DateYear
                name="year"
                label="Year"
                value={year}
                indentifier={null}
                disabled={true}
              />
            </Grid>

            <Grid item sm={2} xs={2}>
              <FormField
                name="itemAge"
                label="Item Age"
                value={itemAge}
                disabled={true}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormSelectError
                name="sourceDegreeLevelUuid"
                value={sourceDegreeLevelUuid}
                label="Education Level*"
                handleChange={handleChange}
                options={degreeLevelOptions}
                hasErrors={hasErrors}
                handleValidation={validateSelectField}
              />
            </Grid>
            <Grid item sm={3} xs={3}>
              <FormSelectError
                name="classificationUuid"
                value={classificationUuid}
                label="Skill*"
                handleChange={handleChange}
                options={classificationOptions}
                hasErrors={hasErrors}
                handleValidation={validateSelectField}
              />
            </Grid>
            <Grid item sm={3} xs={3}>
              <FormSelectError
                name="examAptitudeUuid"
                value={examAptitudeUuid}
                label="Exam*"
                handleChange={handleChange}
                options={examAptitudeOptions}
                hasErrors={hasErrors}
                handleValidation={validateSelectField}
              />
            </Grid>
            <Grid item sm={6} xs={6}>
              <FormField
                name="examName"
                label="Exam Name"
                value={examName}
                disabled={true}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <InstitutionCodeSearch
                hasErrors={hasErrors}
                sourceInstitutionUuid={sourceInstitutionUuid}
                handleSourceInstitutionUuid={handleSourceInstitutionUuid}
              />
            </Grid>

            <Grid item sm={4} xs={4}>
              <FormField
                name="attempt"
                label="Attempt"
                value={attempt}
                onBlur={handleChange}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                hasErrors={hasErrors}
                handleValidate={validateInputNumber}
              />
            </Grid>

            <Grid item sm={2} xs={2}>
              <FormField
                name="weight"
                label="Weight"
                value={weight}
                disabled={true}
              />
            </Grid>
            <Grid item sm={2} xs={2}>
              <FormSelectError
                name="conversionAptitudeUuid"
                value={conversionAptitudeUuid}
                label="Grade*"
                handleChange={handleChange}
                options={conversionOptions}
                hasErrors={hasErrors}
                handleValidation={validateSelectField}
                disabled={!classificationUuid}
              />
            </Grid>
            <Grid item sm={2} xs={2}>
              <FormField
                name="score"
                label="Score"
                value={score}
                disabled={true}
              />
            </Grid>

            <Grid item sm={2} xs={2}>
              <FormSelectError
                name="letterGradeUuid"
                value={letterGradeUuid}
                label="Letter Grade"
                options={letterGradeOptions}
                disabled={true}
              />
            </Grid>
          </Grid>
        </FormArea>

        <ActionsArea>
          <SaveButton onClick={handleRequest}>
            {loading ? 'Saving...' : 'Save'}
          </SaveButton>
        </ActionsArea>
      </ModalBody>
    </SimpleModal>
  );
};

Aptitude.propTypes = {
  formType: PropTypes.string,
  loading: PropTypes.bool,
  category: PropTypes.string,
  source: PropTypes.string,
  user: PropTypes.object,
  applicationDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  year: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  term: PropTypes.string,
  itemAge: PropTypes.string,
  examAptitudeUuid: PropTypes.string,
  examName: PropTypes.string,
  score: PropTypes.string,
  attempt: PropTypes.number,
  classificationUuid: PropTypes.string,
  conversionAptitudeUuid: PropTypes.string,
  sourceDegreeLevelUuid: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string,
  letterGradeUuid: PropTypes.string,
  weight: PropTypes.string,
  modalOpen: PropTypes.bool,
  degreeLevelOptions: PropTypes.array,
  classificationOptions: PropTypes.array,
  examAptitudeOptions: PropTypes.array,
  conversionOptions: PropTypes.array,
  letterGradeOptions: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleChangeDate: PropTypes.func,
  handleChange: PropTypes.func,
  handleRequest: PropTypes.func,
  handleSourceInstitutionUuid: PropTypes.func
};

Aptitude.defaultProps = {
  formType: '',
  loading: false,
  category: undefined,
  source: undefined,
  user: undefined,
  applicationDate: undefined,
  date: undefined,
  attempt: 0,
  term: undefined,
  year: undefined,
  itemAge: undefined,
  examAptitudeUuid: undefined,
  examName: undefined,
  score: undefined,
  classificationUuid: undefined,
  conversionAptitudeUuid: undefined,
  sourceDegreeLevelUuid: undefined,
  sourceInstitutionUuid: undefined,
  letterGradeUuid: undefined,
  weight: undefined,
  modalOpen: false,
  examAptitudeOptions: [],
  degreeLevelOptions: [],
  classificationOptions: [],
  conversionOptions: [],
  letterGradeOptions: [],
  hasErrors: false,
  handleModalClose: undefined,
  handleChangeDate: undefined,
  handleChange: undefined,
  handleRequest: undefined,
  handleSourceInstitutionUuid: undefined
};

export default Aptitude;
