import fetch from '../helpers/fetch';

const getPartGroups = partUuid =>
  fetch(`/api/part/${partUuid}/groups`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postGroup = (partUuid, groupId) =>
  fetch('/api/groups/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ partUuid, groupId })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteGroup = groupUuid =>
  fetch(`/api/groups/${groupUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putGroup = (groupUuid, groupId, groupLeaderUuid) =>
  fetch(`/api/group/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ groupUuid, groupId, groupLeaderUuid })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putGroupRankings = partUuid =>
  fetch(`/api/part/${partUuid}/groups/rank`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putGroupStudentRankings = (partUuid, studentUuid) =>
  fetch(`/api/part/${partUuid}/groups/rank/${studentUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getPartGroups,
  postGroup,
  deleteGroup,
  putGroup,
  putGroupRankings,
  putGroupStudentRankings
};
