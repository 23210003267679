import { Model, attr, fk } from 'redux-orm';
import _ from 'lodash';
import {
  DELETE_PROGRAM_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_USER_ACTIVE_SUCCESS,
  GET_USER_PROGRAMS_BY_PROGRAM_SUCCESS,
  GET_USER_PROGRAMS_BY_PROGRAMS_SUCCESS,
  GET_USER_SUCCESS,
  GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS,
  GET_USERS_BY_SEARCH_ROLES_SUCCESS,
  POST_USER_ROLE_SUCCESS,
  PUT_USER_ROLES_SUCCESS,
  GET_COHORT_ASSESSMENT_SCORES_SUCCESS
} from '../actions-type';

class UserProgram extends Model {
  static reducer(action, UserProgram, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_USER_ACTIVE_SUCCESS: {
        if (payload?.roles?.program_admin?.length > 0) {
          const userPrograms = [...payload.roles.program_admin];
          userPrograms.forEach(userProgram => {
            const add = _.omit(userProgram, ['program']);
            UserProgram.upsert(add);
          });
        }
        if (payload?.roles?.program_staff?.length > 0) {
          const userPrograms = [...payload.roles.program_staff];
          userPrograms.forEach(userProgram => {
            const add = _.omit(userProgram, ['program']);
            UserProgram.upsert(add);
          });
        }
        if (payload?.roles?.program_faculty?.length > 0) {
          const userPrograms = [...payload.roles.program_faculty];
          userPrograms.forEach(userProgram => {
            const add = _.omit(userProgram, ['program']);
            UserProgram.upsert(add);
          });
        }
        break;
      }
      case GET_COHORT_ASSESSMENT_SCORES_SUCCESS: {
        const programFaculty = [...payload.programFaculty];

        programFaculty.forEach(faculty => UserProgram.upsert(faculty));

        break;
      }
      case GET_USER_SUCCESS: {
        const userPrograms = [...payload.userPrograms];
        userPrograms.forEach(userProgram => UserProgram.upsert(userProgram));
        break;
      }
      case GET_USER_PROGRAMS_BY_PROGRAM_SUCCESS: {
        const userPrograms = [...payload.userPrograms];
        userPrograms.forEach(userProgram => UserProgram.upsert(userProgram));
        break;
      }
      case GET_USER_PROGRAMS_BY_PROGRAMS_SUCCESS: {
        const userPrograms = [...payload.userPrograms];
        userPrograms.forEach(userProgram => UserProgram.upsert(userProgram));
        break;
      }
      case GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS: {
        const usersPrograms = [...payload.usersPrograms];
        usersPrograms.forEach(userProgram => UserProgram.upsert(userProgram));
        break;
      }
      case PUT_USER_ROLES_SUCCESS: {
        if (payload?.programAdminRemove?.length > 0) {
          const programAdminRemove = [...payload.programAdminRemove];
          programAdminRemove.forEach(uuid => UserProgram.withId(uuid).delete());
        }

        if (payload?.programStaffRemove?.length > 0) {
          const programStaffRemove = [...payload.programStaffRemove];
          programStaffRemove.forEach(uuid => UserProgram.withId(uuid).delete());
        }

        if (payload?.programFacultyRemove?.length > 0) {
          const programFacultyRemove = [...payload.programFacultyRemove];
          programFacultyRemove.forEach(uuid =>
            UserProgram.withId(uuid).delete()
          );
        }

        const userPrograms = [...payload.userPrograms];
        if (userPrograms?.length > 0) {
          userPrograms.forEach(userProgram => UserProgram.upsert(userProgram));
        }

        break;
      }
      case DELETE_USER_SUCCESS: {
        UserProgram.all()
          .filter(userProgram => userProgram.userUuid === payload.userUuid)
          .delete();
        break;
      }

      case DELETE_PROGRAM_SUCCESS: {
        const { programUuid } = payload;

        UserProgram.all()
          .filter(userProgram => userProgram.programUuid === programUuid)
          .delete();

        break;
      }
      case POST_USER_ROLE_SUCCESS: {
        if (payload.roleType === 'program_role') {
          UserProgram.create(payload.role);
        }

        break;
      }
      case GET_USERS_BY_SEARCH_ROLES_SUCCESS: {
        if (payload?.userPrograms?.length > 0) {
          const userPrograms = [...payload.userPrograms];
          userPrograms.forEach(userProgram => UserProgram.upsert(userProgram));
        }
        break;
      }
      default:
        return session;
    }
  }
}

UserProgram.fields = {
  uuid: attr(),
  role: attr(),
  email: attr(),
  phone: attr(),
  status: attr(),
  startDate: attr(),
  endDate: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  userUuid: fk({
    to: 'User',
    as: 'user',
    relatedName: 'user_programs'
  }),
  programUuid: fk({
    to: 'Program',
    as: 'program',
    relatedName: 'user_programs'
  })
};

UserProgram.modelName = 'UserProgram';

UserProgram.options = {
  idAttribute: 'uuid'
};

export default UserProgram;
