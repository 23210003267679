import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  getTotalNumberOfInProgressEncounters,
  getTotalNumberOfCompleteEncounters,
  getTotalNumberOfApprovedEncounters,
  getTotalNumberOfInProgressAssessments,
  getTotalNumberOfCompleteAssessments,
  getTotalNumberOfApprovedAssessments,
  getTotalNumberofEncounters,
  getTotalNumberofAssessments
} from '../../../../../helpers/parts';
import Overline from '../../../../Library/Overline';
import {
  StatusChip,
  CompleteStatusChip
} from '../../../../Library/StatusChipsSC';

const SyllabusStatusSection = styled.div`
  width: 100%;
  margin: 32px 0 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
`;

const StatusColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const StatusTypeHeader = styled(Overline)`
  margin: 0 0 12px;
  line-height: 1.6;
  font-size: 0.75rem;
  font-weight: 400;
`;

const ItemStatusSummary = ({ part, className }) => {
  const totalNumberOfInProgressEncounters = getTotalNumberOfInProgressEncounters(
    part
  );
  const totalNumberOfCompleteEncounters = getTotalNumberOfCompleteEncounters(
    part
  );
  const totalNumberOfApprovedEncounters = getTotalNumberOfApprovedEncounters(
    part
  );
  const totalNumberOfInProgressAssessments = getTotalNumberOfInProgressAssessments(
    part
  );
  const totalNumberOfCompleteAssessments = getTotalNumberOfCompleteAssessments(
    part
  );
  const totalNumberOfApprovedAssessments = getTotalNumberOfApprovedAssessments(
    part
  );

  const totalNumberofEncounters = getTotalNumberofEncounters(part);
  const totalNumberofAssessments = getTotalNumberofAssessments(part);

  return (
    <SyllabusStatusSection className={className}>
      <StatusColumn>
        <StatusTypeHeader>Encounters</StatusTypeHeader>
        <StatusChip
          isComplete={
            totalNumberOfInProgressEncounters !== 0 &&
            totalNumberOfInProgressEncounters === totalNumberofEncounters
          }
        >
          {`${totalNumberOfInProgressEncounters} / ${totalNumberofEncounters} In Progress`}
        </StatusChip>
        <StatusChip
          isComplete={
            totalNumberOfCompleteEncounters !== 0 &&
            totalNumberOfCompleteEncounters === totalNumberofEncounters
          }
        >
          {`${totalNumberOfCompleteEncounters} / ${totalNumberofEncounters} Complete`}
        </StatusChip>
        <CompleteStatusChip
          isComplete={
            totalNumberOfApprovedEncounters !== 0 &&
            totalNumberOfApprovedEncounters === totalNumberofEncounters
          }
        >
          {`${totalNumberOfApprovedEncounters} / ${totalNumberofEncounters} Approved`}
        </CompleteStatusChip>
      </StatusColumn>

      <StatusColumn>
        <StatusTypeHeader>Assessments</StatusTypeHeader>
        <StatusChip
          isComplete={
            totalNumberOfInProgressAssessments !== 0 &&
            totalNumberOfInProgressAssessments === totalNumberofAssessments
          }
        >
          {`${totalNumberOfInProgressAssessments} / ${totalNumberofAssessments} In Progress`}
        </StatusChip>
        <StatusChip
          isComplete={
            totalNumberOfCompleteAssessments !== 0 &&
            totalNumberOfCompleteAssessments === totalNumberofAssessments
          }
        >
          {`${totalNumberOfCompleteAssessments} / ${totalNumberofAssessments} Complete`}
        </StatusChip>
        <CompleteStatusChip
          isComplete={
            totalNumberOfApprovedAssessments !== 0 &&
            totalNumberOfApprovedAssessments === totalNumberofAssessments
          }
        >
          {`${totalNumberOfApprovedAssessments} / ${totalNumberofAssessments} Approved`}
        </CompleteStatusChip>
      </StatusColumn>
    </SyllabusStatusSection>
  );
};

ItemStatusSummary.propTypes = {
  part: PropTypes.object,
  className: PropTypes.string
};

ItemStatusSummary.defaultProps = {
  part: {},
  className: ''
};

export default ItemStatusSummary;
