import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useDelete } from '../../../Library/Modal/DeleteModal/useDelete';
import DeleteModal from '../../../Library/Modal/DeleteModal/DeleteModal';
import {
  doPutCompetency,
  doDeleteCompetency
} from '../../../../redux/actions/competencyActions';
import { competencySelector } from '../../../../redux/selectors/competenciesSelector';
import {
  allFieldsAreValid,
  validateStringLength
} from '../../../../helpers/validation/validateGeneric';
import { validateCompetencyDescription } from '../../../../helpers/validation/validateCompetencyCollection';
import CompetencyDetailsForm from '../Form/Sections/CompetencyDetailsForm';
import {
  initialCompetencyState,
  competencyReducer,
  setShowValidationErrors
} from './competencyState';

const EditCompetencyContainer = ({
  competencyUuid,
  competencyNumber,
  openMicroSearch,
  unsavedChanges,
  setUnsavedChange,
  removeUnsavedChange
}) => {
  const dispatch = useDispatch();

  const [state, editDispatch] = useReducer(
    competencyReducer,
    initialCompetencyState
  );

  const existingCompetency = useSelector(state =>
    competencySelector(state, competencyUuid)
  );

  const setExistingState = existingCompetency => {
    editDispatch({
      type: 'setExistingCompetency',
      payload: { ...existingCompetency }
    });
  };

  const { uuid, name, description, showValidationErrors } = state;

  const onPutUpdate = competency => dispatch(doPutCompetency(competency));

  useEffect(() => {
    if (
      competencyUuid &&
      existingCompetency &&
      existingCompetency.uuid !== uuid
    ) {
      setExistingState(existingCompetency);
    }
  }, [competencyUuid, existingCompetency, uuid]);

  const setValidationErrors = newCompetency => {
    const nameError = validateStringLength(newCompetency.name, 100, true);
    const descriptionError = validateCompetencyDescription(
      newCompetency.description,
      100,
      50000,
      true
    );

    const newValidationErrors = {
      nameError,
      descriptionError
    };

    return newValidationErrors;
  };

  const handleSubmission = () => {
    const newValidationErrors = setValidationErrors({
      name,
      description
    });

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid,
        name,
        description
      };

      onPutUpdate(payload);
      removeUnsavedChange(uuid);
      editDispatch(setShowValidationErrors(false));
    } else {
      editDispatch(setShowValidationErrors(true));
    }
  };

  const onDelete = () => dispatch(doDeleteCompetency(uuid));

  const [
    isDeleteModalOpen,
    handleDeleteClick,
    handleDeleteModalClose,
    handleDelete
  ] = useDelete(onDelete);

  const onDeleteClick = e => {
    e.stopPropagation();
    handleDeleteClick();
  };

  return (
    <>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={handleDeleteModalClose}
        handleDelete={handleDelete}
        type="Competency"
      />
      <CompetencyDetailsForm
        uuid={uuid}
        name={name}
        description={description}
        microcompetencies={existingCompetency.microcompetencies}
        index={competencyNumber}
        formDispatch={editDispatch}
        onFormClose={onDeleteClick}
        onSubmit={handleSubmission}
        buttonText="Update Competency"
        unsavedChanges={unsavedChanges}
        setUnsavedChange={setUnsavedChange}
        showValidationErrors={showValidationErrors}
        openMicroSearch={openMicroSearch}
      />
    </>
  );
};

export default EditCompetencyContainer;

EditCompetencyContainer.propTypes = {
  competencyUuid: PropTypes.string,
  competencyNumber: PropTypes.number,
  unsavedChanges: PropTypes.object,
  openMicroSearch: PropTypes.func,
  setUnsavedChange: PropTypes.func,
  removeUnsavedChange: PropTypes.func
};

EditCompetencyContainer.defaultProps = {
  competencyUuid: '',
  competencyNumber: 1,
  unsavedChanges: {},
  openMicroSearch: undefined,
  setUnsavedChange: undefined,
  removeUnsavedChange: undefined
};
