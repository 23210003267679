import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserTabCheck from './UserTabCheck';

export default function UserTabCheckContainer() {
  const dispatch = useDispatch();
  const [openCheckUpload, setOpenCheckUpload] = useState(false);

  const [openMapping, setOpenMapping] = useState(false);
  const status = useSelector(state => state.checkUsersState);

  const handleDisplayMapping = () => {
    setOpenMapping(openMapping => !openMapping);
  };

  const handleCheckOpen = () => {
    setOpenCheckUpload(true);
  };

  const handleCheckClose = () => {
    setOpenCheckUpload(false);
  };

  const handleClearResults = () => {
    dispatch({ type: 'POST_CHECK_USERS_RESET' });
  };

  return (
    <UserTabCheck
      openCheckUpload={openCheckUpload}
      openMapping={openMapping}
      status={status}
      handleDisplayMapping={handleDisplayMapping}
      handleCheckOpen={handleCheckOpen}
      handleCheckClose={handleCheckClose}
      handleClearResults={handleClearResults}
    />
  );
}

UserTabCheckContainer.propTypes = {};
UserTabCheckContainer.defaultProps = {};
