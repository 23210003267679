export const GET_CREDENTIALS = 'GET_CREDENTIALS';
export const GET_CREDENTIALS_SUCCESS = 'GET_CREDENTIALS_SUCCESS';
export const GET_CREDENTIALS_ERROR = 'GET_CREDENTIALS_ERROR';
export const GET_CREDENTIALS_RESET = 'GET_CREDENTIALS_RESET';

export const POST_CREDENTIAL = 'POST_CREDENTIAL';
export const POST_CREDENTIAL_SUCCESS = 'POST_CREDENTIAL_SUCCESS';
export const POST_CREDENTIAL_ERROR = 'POST_CREDENTIAL_ERROR';
export const POST_CREDENTIAL_RESET = 'POST_CREDENTIAL_RESET';

export const PUT_CREDENTIAL = 'PUT_CREDENTIAL';
export const PUT_CREDENTIAL_SUCCESS = 'PUT_CREDENTIAL_SUCCESS';
export const PUT_CREDENTIAL_ERROR = 'PUT_CREDENTIAL_ERROR';
export const PUT_CREDENTIAL_RESET = 'PUT_CREDENTIAL_RESET';

export const DELETE_CREDENTIAL = 'DELETE_CREDENTIAL';
export const DELETE_CREDENTIAL_SUCCESS = 'DELETE_CREDENTIAL_SUCCESS';
export const DELETE_CREDENTIAL_ERROR = 'DELETE_CREDENTIAL_ERROR';
export const DELETE_CREDENTIAL_RESET = 'DELETE_CREDENTIAL_RESET';
