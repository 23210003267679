import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import UserManagementInstitutionAdmin from './UserManagementInstitutionAdmin';
import UserManagementSchoolProgramAdmin from './UserManagementSchoolAndProgramAdmin';

export default function UserManagementContainer({ match, history }) {
  const { path } = match;
  const { pathname } = history.location;
  const selectedRole = useSelector(state => state.userState.selectedRole);

  return (
    <>
      {selectedRole === 'institution_admin' && (
        <UserManagementInstitutionAdmin path={path} pathname={pathname} />
      )}
      {(selectedRole === 'school_admin' ||
        selectedRole === 'program_admin') && (
        <UserManagementSchoolProgramAdmin path={path} pathname={pathname} />
      )}
    </>
  );
}

UserManagementContainer.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
};

UserManagementContainer.defaultProps = {
  match: {},
  history: {}
};
