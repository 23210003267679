import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ScoreEdit from './ScoreEdit';

import { scoreSelector } from '../../../../redux/selectors/scoreSelectors';

import { partFacultySelector } from '../../../../redux/selectors/partsSelectors';
import {
  isNumberPositiveInteger,
  validateDecimalStringField,
  validateInputNumber,
  validateInputString,
  validateSelectDate,
  validateSelectField
} from '../../../../helpers/validation/validateGeneric';

import { doPutScore } from '../../../../redux/actions/scoreActions';
import fetchWithAuthorization from '../../../../helpers/fetch';

export default function ScoreEditContainer({
  assessmentUuid,
  scoreUuid,
  partUuid,
  openModal,
  handleScoreEditModalClose,
  isOpportunity,
}) {
  const dispatch = useDispatch();
  const score = useSelector(
    state => scoreSelector(state, scoreUuid),
    _.isEqual
  );

  const updateScore = useSelector(state => state.crudState.updateScore);

  const [attempt, setAttempt] = useState(0);
  const [resultCardUuid, setResultCardUuid] = useState('');
  const [date, setDate] = useState('');
  const [tieBackID, setTieBackID] = useState('');
  const [rubricItem, setRubricItem] = useState('');
  const [stepSubItem, setStepSubItem] = useState('');
  const [rubricOrder, setRubricOrder] = useState(null);
  const [procedureCodeOrder, setProcedureCodeOrder] = useState(null);
  const [procedureCodeDescriptor, setProcedureCodeDescriptor] = useState(null);
  const [sequenceOrder, setSequenceOrder] = useState(null);
  const [stepSubItemDescription, setStepSubItemDescription] = useState('');
  const [microcompetencyCode, setMicrocompetencyCode] = useState('');
  const [microcompetencyTitle, setMicrocompetencyTitle] =
    useState('Title Unknown');
  const [relValue, setRelValue] = useState('');
  const [potValue, setPotValue] = useState('');
  const [studentUuid, setStudentUuid] = useState('');
  const [grader1Uuid, setGrader1Uuid] = useState('');
  const [grader2Uuid, setGrader2Uuid] = useState('');
  const [grader3Uuid, setGrader3Uuid] = useState('');
  const [grader1ID, setGrader1ID] = useState(null);
  const [grader2ID, setGrader2ID] = useState(null);
  const [grader3ID, setGrader3ID] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [microcompetencies, setMicrocompetencies] = useState([]);
  const [microSelected, setMicroSelected] = useState({});
  const [displayMicros, setDisplayMicros] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [isSelfAssessment, setIsSelfAssessment] = useState(false);

  const facultyOptions = useSelector(
    state => partFacultySelector(state, partUuid),
    _.isEqual
  ).map(faculty => ({
    label: `${faculty.user.firstName} ${faculty.user.lastName}`,
    value: faculty.userUuid,
    institutionId: faculty.user.institutionId
  }));

  useEffect(() => {
    if (score) {
      setAttempt(score.attempt);
      setResultCardUuid(score.resultCardUuid);
      setDate(score.date);
      setTieBackID(score.tieBackID);
      setRubricItem(score.rubricItem);
      setRubricOrder(score.rubricOrder);
      setSequenceOrder(score.sequenceOrder);
      setProcedureCodeDescriptor(score.procedureCodeDescriptor);
      setProcedureCodeOrder(score.procedureCodeOrder);
      setStepSubItem(score.stepSubItem);
      setStepSubItemDescription(score.stepSubItemDescription);
      setMicrocompetencyCode(score.microcompetencyCode);
      setRelValue(score.relValue);
      setPotValue(score.potValue);
      setStudentUuid(score.studentUuid);
      setGrader1Uuid(score.grader1Uuid);
      setGrader2Uuid(score.grader2Uuid);
      setGrader3Uuid(score.grader3Uuid);
      setGrader1ID(score.grader1ID);
      setGrader2ID(score.grader2ID);
      setGrader3ID(score.grader3ID);
    }
  }, [score]);

  useEffect(() => {
    async function handleMicroDetails() {
      const results = await fetchWithAuthorization(
        `/api/microcompetency-code/${microcompetencyCode}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        }
      )
        .then(res => res)
        .catch(error => {
          throw error;
        });

      if (results.status === 200) {
        const converted = await results.json();

        if (converted.microcompetency !== null) {
          setMicrocompetencyTitle(converted.microcompetency.title);
        } else {
          setMicrocompetencyTitle('Title Unknown');
        }
      } else {
        setMicrocompetencyTitle('Title Unknown');
      }
    }

    if (microcompetencyCode && microcompetencyCode !== '') {
      handleMicroDetails();
    }
  }, [microcompetencyCode]);

  useEffect(() => {
    if (rubricItem.toLowerCase().includes('self-assessment')) {
      setIsSelfAssessment(true);
    } else {
      setIsSelfAssessment(false);
    }
  }, [rubricItem]);

  const handleTieBack = event => {
    const { value } = event.target;
    setTieBackID(value);
  };

  const handleDate = (indentifier, field, date) => {
    if (date === null) {
      setDate('');
    } else {
      const convert = moment(date).format();

      setDate(convert);
    }
  };

  const handleRubricOrder = event => {
    const order = Math.floor(Number(event.target.value));

    setRubricOrder(!order ? null : order);
  }

  const handleSequenceOrder = event => {
    const order = Math.floor(Number(event.target.value));

    setSequenceOrder(!order ? null : order);
  }

  const handleStepSubItem = event => {
    const { value } = event.target;
    setStepSubItem(value);
  };

  const handleStepSubItemDescription = event => {
    const { value } = event.target;
    setStepSubItemDescription(value);
  };

  const handleProcedureCodeOrder = event => {
    const order = Math.floor(Number(event.target.value));

    setProcedureCodeOrder(!order ? null : order);
  }

  const handleDescriptor = event => {
    const { value } = event.target;
    setProcedureCodeDescriptor(value);
  };

  const handleRubricItem = event => {
    const { value } = event.target;
    setRubricItem(value);
  };

  const handleAttempt = event => {
    const { value } = event.target;
    setAttempt(value);
  };

  const handleRelValue = event => {
    const { value } = event.target;
    setRelValue(value);
  };

  const handlePotValue = event => {
    const { value } = event.target;
    setPotValue(value);
  };

  const handleGrader1Change = event => {
    const { value } = event.target;
    const grader = facultyOptions.filter(faculty => faculty.value === value);
    const institutionId = _.get(grader, '0.institutionId');

    setGrader1ID(institutionId);
    setGrader1Uuid(value);
  };

  const handleGrader2Change = event => {
    const { value } = event.target;
    const grader = facultyOptions.filter(faculty => faculty.value === value);
    const institutionId = _.get(grader, '0.institutionId');

    setGrader2ID(institutionId);
    setGrader2Uuid(value);
  };

  const handleGrader3Change = event => {
    const { value } = event.target;
    const grader = facultyOptions.filter(faculty => faculty.value === value);
    const institutionId = _.get(grader, '0.institutionId');

    setGrader3ID(institutionId);
    setGrader3Uuid(value);
  };

  const handleRemoveMicroCode = () => {
    setMicrocompetencyCode('');
  };

  const handleSearch = async (field, input) => {
    setDisplayMicros(true);
    if (field === 'microcompetencyCode') {
      setFetching(true);
      const results = await fetchWithAuthorization(
        `/api/microcompetency-code/${input}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        }
      )
        .then(res => res)
        .catch(error => {
          throw error;
        });

      if (results.status === 200) {
        const converted = await results.json();

        if (converted.microcompetency !== null) {
          const results = [
            {
              uuid: converted.microcompetency.uuid,
              code: converted.code,
              title: converted.microcompetency.title
            }
          ];

          setMicrocompetencies(results);
          setFetching(false);
        } else {
          setMicrocompetencies([]);
          setFetching(false);
        }
      } else {
        setMicrocompetencies([]);
        setFetching(false);
      }
    }
  };

  const handleMicroSelected = micro => {
    setMicroSelected(micro);
  };

  const handleClearSearch = () => {
    setDisplayMicros(false);
    setMicrocompetencies([]);
  };

  const handleEstablishedMicro = () => {
    setDisplayMicros(false);
    setMicrocompetencyCode(microSelected.code);
    setMicrocompetencies([]);
  };

  const _validate = () => {
    let containErrors = false;

    const tieBackIDError = validateInputString(tieBackID);
    const dateError = validateSelectDate(date);

    const rubricItemError = validateInputString(rubricItem);
    const relValueError = validateDecimalStringField(
      relValue,
      isSelfAssessment
    );
    const sequenceError = !isOpportunity && isNumberPositiveInteger(sequenceOrder);
    const rubricError = !isOpportunity && isNumberPositiveInteger(rubricOrder);
    const procedureCodeOrderError = isOpportunity && isNumberPositiveInteger(procedureCodeOrder);
    const procedureCodeDescriptorError = isOpportunity && validateInputString(procedureCodeDescriptor);

    const potValueError = validateDecimalStringField(potValue);
    const attemptError = validateInputNumber(attempt);
    const microcompetencyCodeError = validateInputString(microcompetencyCode);
    const grader1UuidError = validateSelectField(grader1Uuid);

    if (
      tieBackIDError.invalid ||
      dateError.invalid ||
      rubricItemError.invalid ||
      sequenceError?.invalid ||
      rubricError?.invalid ||
      procedureCodeOrderError?.invalid ||
      procedureCodeDescriptorError?.invalid ||
      relValueError.invalid ||
      potValueError.invalid ||
      attemptError.invalid ||
      microcompetencyCodeError.invalid ||
      grader1UuidError.invalid
    ) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleUpdateScore = () => {
    const rejectCreation = _validate();

    if (rejectCreation) {
      setHasErrors(true);
    } else {
      setHasErrors(false);

      const request = {
        uuid: scoreUuid,
        assessmentUuid,
        stepSubItem,
        stepSubItemDescription,
        tieBackID,
        date,
        rubricItem,
        relValue,
        potValue,
        sequenceOrder,
        rubricOrder,
        microcompetencyCode,
        grader1Uuid,
        grader2Uuid,
        grader3Uuid,
        grader1ID,
        grader2ID,
        grader3ID,
        procedureCodeDescriptor,
        procedureCodeOrder
      };

      dispatch(doPutScore(request));
    }
  };

  return (
    <ScoreEdit
      attempt={attempt}
      isOpportunity={isOpportunity}
      resultCardUuid={resultCardUuid}
      date={date}
      tieBackID={tieBackID}
      rubricItem={rubricItem}
      stepSubItem={stepSubItem}
      stepSubItemDescription={stepSubItemDescription}
      microcompetencyCode={microcompetencyCode}
      microcompetencies={microcompetencies}
      relValue={relValue}
      potValue={potValue}
      sequenceOrder={sequenceOrder}
      rubricOrder={rubricOrder}
      procedureCodeDescriptor={procedureCodeDescriptor}
      procedureCodeOrder={procedureCodeOrder}
      studentUuid={studentUuid}
      grader1Uuid={grader1Uuid}
      grader2Uuid={grader2Uuid}
      grader3Uuid={grader3Uuid}
      microSelected={microSelected}
      displayMicros={displayMicros}
      microcompetencyTitle={microcompetencyTitle}
      facultyOptions={facultyOptions}
      fetching={fetching}
      openModal={openModal}
      hasErrors={hasErrors}
      updateScore={updateScore}
      handleScoreEditModalClose={handleScoreEditModalClose}
      handleTieBack={handleTieBack}
      handleDate={handleDate}
      handleStepSubItem={handleStepSubItem}
      handleStepSubItemDescription={handleStepSubItemDescription}
      handleRubricItem={handleRubricItem}
      handleAttempt={handleAttempt}
      handleSequenceOrder={handleSequenceOrder}
      handleRubricOrder={handleRubricOrder}
      handleRelValue={handleRelValue}
      handlePotValue={handlePotValue}
      handleGrader1Change={handleGrader1Change}
      handleGrader2Change={handleGrader2Change}
      handleGrader3Change={handleGrader3Change}
      handleRemoveMicroCode={handleRemoveMicroCode}
      handleSearch={handleSearch}
      handleMicroSelected={handleMicroSelected}
      handleClearSearch={handleClearSearch}
      handleEstablishedMicro={handleEstablishedMicro}
      handleUpdateScore={handleUpdateScore}
      handleDescriptor={handleDescriptor}
      handleProcedureCodeOrder={handleProcedureCodeOrder}
      isSelfAssessment={isSelfAssessment}
    />
  );
}

ScoreEditContainer.propTypes = {
  assessmentUuid: PropTypes.string,
  scoreUuid: PropTypes.string,
  partUuid: PropTypes.string,
  openModal: PropTypes.bool,
  handleScoreEditModalClose: PropTypes.func,
  isOpportunity: PropTypes.bool,
};

ScoreEditContainer.defaultProps = {
  assessmentUuid: '',
  isOpportunity: false,
  scoreUuid: '',
  partUuid: '',
  openModal: false,
  handleScoreEditModalClose: undefined
};
