import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import DragHandle from '@material-ui/icons/DragHandle';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import FormField from '../../../Library/FormField';

import {
  ContainerPage,
  ContainerContent,
  ElevateLow
} from '../../../Library/Layout';

import { validateInputNumber } from '../../../../helpers/validation/validateGeneric';

const TextFieldSC = styled(TextField)`
  && {
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
  }
`;

const RevusRow = styled.div`
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: space-between;
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Equal = styled(DragHandle)`
  margin-top: 31px;
  margin-left: 30px;
  margin-right: 30px;
`;

const Revus = ({
  revus,
  minutes,
  hours,
  handleRevusChange,
  handleMinutesChange,
  handleSave,
  errors
}) => {
  return (
    <ContainerPage paddingTop="60px">
      <ElevateLow>
        <ContainerContent>
          <RevusRow>
            <FormField
              required
              name="revus"
              label="Revus"
              value={revus}
              onBlur={handleRevusChange}
              type="number"
              hasErrors={errors}
              handleValidate={validateInputNumber}
              InputProps={{ inputProps: { min: 0 } }}
            />
            <Equal />
            <FormField
              required
              name="minutes"
              label="Minutes"
              value={minutes}
              onBlur={handleMinutesChange}
              type="number"
              hasErrors={errors}
              handleValidate={validateInputNumber}
              InputProps={{ inputProps: { min: 0 } }}
            />
            <Equal />
            <TextFieldSC
              name="hours"
              variant="outlined"
              label="Hours"
              value={hours}
              disabled={true}
            />
          </RevusRow>
          <ButtonRow>
            <ButtonInline onClick={handleSave}>Save</ButtonInline>
          </ButtonRow>
        </ContainerContent>
      </ElevateLow>
    </ContainerPage>
  );
};

Revus.propTypes = {
  revus: PropTypes.number,
  minutes: PropTypes.number,
  hours: PropTypes.number,
  errors: PropTypes.bool,
  handleRevusChange: PropTypes.func,
  handleMinutesChange: PropTypes.func,
  handleSave: PropTypes.func
};
Revus.defaultProps = {
  revus: 0,
  minutes: 0,
  hours: 0,
  errors: false,
  handleRevusChange: undefined,
  handleMinutesChange: undefined,
  handleSave: undefined
};

export default Revus;
