import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { createFilter } from 'react-search-input';
import SchoolManagement from './SchoolManagement';
import { doGetSchools } from '../../redux/actions/schoolActions';
import { schoolsSelector } from '../../redux/selectors/schoolSelectors';

export default function SchoolManagementContainer() {
  const [searchTerm, setSearchTerm] = useState('');
  const KEYS_TO_FILTERS = ['schoolName'];
  const dispatch = useDispatch();
  const schools = useSelector(state => schoolsSelector(state), _.isEqual);

  useEffect(() => {
    dispatch(doGetSchools());
  }, [dispatch]);

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const searchFilter = () => {
    return schools.filter(createFilter(searchTerm, KEYS_TO_FILTERS));
  };

  return (
    <SchoolManagement
      schools={searchFilter()}
      searchTerm={searchTerm}
      handleSearch={handleSearch}
    />
  );
}
