import { Model, attr, fk } from 'redux-orm';

import {
  GET_TRANSCRIPT_SUCCESS,
  GET_APTITUDES_BY_CATEGORY_SUCCESS,
  GET_CLASSIFICATIONS_SUCCESS,
  GET_SUBJECTS_SUCCESS,
  POST_APTITUDES_SUCCESS,
  POST_SUBJECTS_SUCCESS,
  POST_INSTITUTION_RIGOR_SUCCESS,
  GET_RIGOR_HISTORICAL_REPORT_SUCCESS
} from '../actions-type';

class Classification extends Model {
  static reducer(action, Classification, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_CLASSIFICATIONS_SUCCESS: {
        const classifications = [...payload.classifications];
        classifications.forEach(classification => {
          const copy = { ...classification };
          delete copy.exam_aptitudes;
          delete copy.exam_milestone;
          Classification.upsert(copy);
        });
        break;
      }
      case GET_APTITUDES_BY_CATEGORY_SUCCESS: {
        const classifications = [...payload.classifications];

        classifications.forEach(classification => {
          const copy = { ...classification };
          delete copy.exam_aptitudes;
          delete copy.exam_milestone;
          Classification.upsert(copy);
        });

        break;
      }
      case POST_APTITUDES_SUCCESS: {
        const classifications = [...payload.classifications];

        classifications.forEach(classification => {
          const copy = { ...classification };
          delete copy.exam_aptitudes;
          delete copy.exam_milestone;
          Classification.upsert(copy);
        });

        const classificationsRemove = [...payload.classificationsRemove];
        if (classificationsRemove?.length > 0) {
          classificationsRemove.forEach(classification => {
            Classification.withId(classification.uuid).delete();
          });
        }
        break;
      }
      case GET_SUBJECTS_SUCCESS: {
        const subjects = [...payload.subjects];
        subjects.forEach(classification => {
          const copy = { ...classification };
          delete copy.exam_milestone;
          Classification.upsert(copy);
        });
        break;
      }
      case POST_SUBJECTS_SUCCESS: {
        const subjects = [...payload.subjects];
        subjects.forEach(classification => {
          const copy = { ...classification };
          Classification.upsert(copy);
        });

        const subjectsRemove = [...payload.subjectsRemove];

        if (subjectsRemove?.length > 0) {
          subjectsRemove.forEach(subject => {
            Classification.withId(subject.uuid).delete();
          });
        }
        break;
      }
      case GET_TRANSCRIPT_SUCCESS: {
        const classifications = [...payload.classifications];
        classifications.forEach(classification => {
          Classification.upsert(classification);
        });
        break;
      }

      case POST_INSTITUTION_RIGOR_SUCCESS: {
        const classifications = [...payload.classifications];
        classifications.forEach(classification => {
          Classification.upsert(classification);
        });
        break;
      }

      case GET_RIGOR_HISTORICAL_REPORT_SUCCESS: {
        const classifications = [...payload.classifications];
        classifications.forEach(classification => {
          Classification.upsert(classification);
        });
        break;
      }

      default:
        return session;
    }
  }
}

Classification.fields = {
  uuid: attr(),
  category: attr(),
  source: attr(),
  weight: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  programUuid: fk('Program', 'classifications')
};

Classification.modelName = 'Classification';

Classification.options = {
  idAttribute: 'uuid'
};

export default Classification;
