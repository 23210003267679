import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { studentCalculatedPartGradeResultSelector } from '../../../../../redux/selectors/calculatedGradeResultSelectors';
import SectionPerformanceStudentPartData from './SectionPerformanceStudentPartData';

const SectionPerformanceStudentPartDataContainer = ({
  rowIndex,
  partUuid,
  studentUuid
}) => {
  const calculatedGradeResult = useSelector(state =>
    studentCalculatedPartGradeResultSelector(state, studentUuid, partUuid)
  );

  return (
    <SectionPerformanceStudentPartData
      calculatedGrade={calculatedGradeResult?.calculatedGrade}
      rowIndex={rowIndex}
    />
  );
};

SectionPerformanceStudentPartDataContainer.propTypes = {
  rowIndex: PropTypes.number,
  partUuid: PropTypes.string,
  studentUuid: PropTypes.string
};

SectionPerformanceStudentPartDataContainer.defaultProps = {
  rowIndex: undefined,
  partUuid: '',
  studentUuid: ''
};

export default SectionPerformanceStudentPartDataContainer;
