import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flattenDeep, isEqual } from 'lodash';
import { useParams } from 'react-router-dom';

import {
  assessmentsByTypesSelector,
  opportunityAssessmentTypesSelector
} from '../../../../redux/selectors/assessmentSelectors';
import { sortByDateProperty } from '../../../../helpers/utilities';
import { doClearPartOpportunityReport } from '../../../../redux/actions/partReportsActions';
import {
  doGetAssessmentsByPart,
  doGetAssessmentType
} from '../../../../redux/actions/assessmentActions';
import OpportunityReport from '../../OpportunityReport/OpportunityReport';
import { doGetSectionPerformanceOpportunityReport } from '../../../../redux/actions/sectionPerformanceReportActions';
import { partsSelectorBySectionUuid } from '../../../../redux/selectors/partsSelectors';
import { doGetStudentRoster } from '../../../../redux/actions/userSectionActions';
import { useSortReportStudents } from '../../../../helpers/hooks/useSortStudents';

const SectionPerformanceOpportunityReport = () => {
  const { sectionUuid } = useParams();

  const dispatch = useDispatch();

  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);

  const assessmentTypes = useSelector(
    state => opportunityAssessmentTypesSelector(state),
    isEqual
  );

  const sortedStudents = useSortReportStudents({
    sectionUuid,
    isSectionReport: true
  });

  const parts = useSelector(
    state => partsSelectorBySectionUuid(state, sectionUuid),
    isEqual
  );

  const { sectionPerformanceReport, isLoading } = useSelector(
    state => state.sectionPerformanceOpportunityReport
  );
  const assessments = useSelector(
    state =>
      parts.map(({ uuid }) =>
        assessmentsByTypesSelector(
          state,
          uuid,
          assessmentTypes.map(assessmentType => assessmentType.uuid)
        )
      ),
    isEqual
  );

  // clear report on unmount
  useEffect(() => {
    return () => dispatch(doClearPartOpportunityReport());
  }, [dispatch]);

  useEffect(() => {
    if (sectionUuid) {
      dispatch(doGetAssessmentType());
      parts.map(({ uuid }) => dispatch(doGetAssessmentsByPart(uuid)));
      dispatch(doGetStudentRoster(sectionUuid));
    }
  }, [dispatch, parts, sectionUuid]);

  const handleApplyPartOpportunityReportFilters = assessmentTypes => {
    setHasAppliedFilters(true);
    dispatch(doClearPartOpportunityReport());
    dispatch(
      doGetSectionPerformanceOpportunityReport({ sectionUuid, assessmentTypes })
    );
  };

  return (
    <OpportunityReport
      assessments={flattenDeep(assessments).sort((a, b) =>
        sortByDateProperty(a, b, 'startDate')
      )}
      assessmentTypes={assessmentTypes}
      hasAppliedFilters={hasAppliedFilters}
      isLoading={isLoading}
      onApplyPartOpportunityReportFilters={
        handleApplyPartOpportunityReportFilters
      }
      partOpportunityReportAssessmentStatisticData={
        sectionPerformanceReport?.partOpportunityReportAssessmentStatisticData
      }
      partOpportunityReportStudentSummaryData={
        sectionPerformanceReport?.partOpportunityReportStudentSummaryData
      }
      partOpportunityReportSummaryStatisticData={
        sectionPerformanceReport?.partOpportunityReportSummaryStatisticData
      }
      reportIsEmpty={!sectionPerformanceReport?.records}
      studentUsers={sortedStudents}
    />
  );
};

export default SectionPerformanceOpportunityReport;
