import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const degreeLevelsSelector = createSelector(
  state => state,
  redux => {
    const degreeLevelsSelectorORM = ormCreateSelector(orm.DegreeLevel);
    const degreeLevels = degreeLevelsSelectorORM(redux);

    const degreeLevelsSorted = _.sortBy(degreeLevels, ['degreeLevel']);

    return degreeLevelsSorted;
  }
);
