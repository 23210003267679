import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Decimal from 'decimal.js-light';
import { Heading3 } from '@xcomp/xcomp-design-library';
import { Panel } from '../../../../Library/Layout';
import PartGroupReportSummaryTable from './PartGroupReportSummaryTable';

const PanelSC = styled(Panel)`
  && {
    margin-bottom: 48px;
    min-width: 100%;
  }
`;

const GroupSummary = styled.div`
  display: flex;
  justify-content: space-between;
  h3 {
    padding-left: 24px;
  }
`;

const GroupStatWrap = styled.div`
  display: flex;
  align-items: center;
`;

const GroupStat = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 48px;
`;
const GroupStatLabel = styled.div`
  font-size: 13px;
  padding-bottom: 8px;
  color: ${props => props.theme.fontColors.dark};
`;
const GroupStateData = styled.div`
  font-size: 15px;
  color: ${props => props.theme.fontColors.darker};
`;

const PartGroupReportSummary = ({
  assessments,
  assessmentSummaryData,
  groupAvgPerStudent,
  groupHighPerStudent,
  groupLowPerStudent
}) => {
  return (
    <PanelSC>
      <GroupSummary>
        <Heading3>Group Performance Summary</Heading3>
        <GroupStatWrap>
          <GroupStat>
            <GroupStatLabel>High</GroupStatLabel>
            <GroupStateData>
              {groupHighPerStudent
                ? new Decimal(groupHighPerStudent).toDecimalPlaces(2).toString()
                : '---'}
            </GroupStateData>
          </GroupStat>
          <GroupStat>
            <GroupStatLabel>Average</GroupStatLabel>
            <GroupStateData>
              {groupAvgPerStudent
                ? new Decimal(groupAvgPerStudent).toDecimalPlaces(2).toString()
                : '---'}
            </GroupStateData>
          </GroupStat>
          <GroupStat>
            <GroupStatLabel>Low</GroupStatLabel>
            <GroupStateData>
              {groupLowPerStudent
                ? new Decimal(groupLowPerStudent).toDecimalPlaces(2).toString()
                : '---'}
            </GroupStateData>
          </GroupStat>
        </GroupStatWrap>
      </GroupSummary>
      <PartGroupReportSummaryTable
        assessments={assessments}
        assessmentSummaryData={assessmentSummaryData}
      />
    </PanelSC>
  );
};

PartGroupReportSummary.propTypes = {
  assessments: PropTypes.array,
  assessmentSummaryData: PropTypes.object,

  groupAvgPerStudent: PropTypes.string,
  groupHighPerStudent: PropTypes.string,
  groupLowPerStudent: PropTypes.string
};
PartGroupReportSummary.defaultProps = {
  assessments: [],
  assessmentSummaryData: {},

  groupAvgPerStudent: '',
  groupHighPerStudent: '',
  groupLowPerStudent: ''
};
export default PartGroupReportSummary;
