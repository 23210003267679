import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { createFilter } from 'react-search-input';
import { doGetProgramCohorts } from '../../redux/actions/cohortActions';
import { cohortsByProgramSelector } from '../../redux/selectors/cohortsSelectors';
import CohortManagement from './CohortManagement';

export default function CohortManagementContainer() {
  const [searchTerm, setSearchTerm] = useState('');
  const KEYS_TO_FILTERS = ['name'];
  const dispatch = useDispatch();

  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  const cohorts = useSelector(
    state => cohortsByProgramSelector(state),
    _.isEqual
  );

  const searchFilter = cohorts.filter(
    createFilter(searchTerm, KEYS_TO_FILTERS)
  );

  useEffect(() => {
    dispatch(doGetProgramCohorts(selectedProgramUuid));
  }, [dispatch, selectedProgramUuid]);

  const handleSearchTerm = event => {
    setSearchTerm(event.target.value);
  };

  return (
    <CohortManagement
      cohorts={searchFilter}
      handleSearchTerm={handleSearchTerm}
    />
  );
}
