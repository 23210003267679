import _ from 'lodash';
import {
  GET_UPLOAD_SCORES_STATUS_SUCCESS,
  GET_UPLOAD_SCORES_STATUS_ERROR,
  POST_UPLOAD_SCORES,
  POST_UPLOAD_SCORES_SUCCESS,
  POST_UPLOAD_SCORES_ERROR,
  RESET_UPLOAD_SCORES
} from '../actions-type';

export const INITIAL_STATE = {
  uploadingScores: false,
  invalid: false,
  records: 0,
  status: ''
};

const uploadScoreReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_UPLOAD_SCORES_STATUS_SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case GET_UPLOAD_SCORES_STATUS_ERROR: {
      return {
        ...state,
        ...action.payload,
        invalid: true,
        type: 'general'
      };
    }
    case POST_UPLOAD_SCORES: {
      return {
        ...state,
        uploadingScores: true,
        invalid: false
      };
    }
    case POST_UPLOAD_SCORES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        uploadingScores: false,
        invalid: false
      };
    }
    case POST_UPLOAD_SCORES_ERROR: {
      return {
        ...state,
        ...action.payload,
        uploadingScores: false,
        invalid: true,
        status: 'close',
        statusMessage: '',
        error_server: _.get(action, 'payload.error', '')
      };
    }
    case RESET_UPLOAD_SCORES: {
      return {
        uploadingScores: false,
        invalid: false,
        records: 0,
        status: ''
      };
    }
    default:
      return state;
  }
};

export default uploadScoreReducer;
