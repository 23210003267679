import React from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { CaptionSmall, ButtonMenu } from '@xcomp/xcomp-design-library';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import FormSelectError from '../../Library/FormSelectError';
import Date from '../../Library/Date';
import FormField from '../../Library/FormField';

import { convertNullToString } from '../../../helpers/utilities';

import {
  validateInputString,
  validateSelectDate
} from '../../../helpers/validation/validateGeneric';

import { roles } from '../../../helpers/constants';
import {
  optionApplicantStatus,
  optionStudentStatus,
  optionRolesForCohortManagement
} from '../../../helpers/options';

const Layout = styled.div`
  margin-top: 40px;
  padding-bottom: 80px;
`;

const LayoutInner = styled.div`
  margin-left: 40px;
  margin-right: 40px;
`;

const RoleBreak = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding: 2rem;
`;

const Title = styled.div`
  font-size: 24px;
  color: ${props => props.theme.colors.black};
`;

export default function CohortUserRoles({
  cohort,
  cohortRoles,
  hasErrors,
  handleFormRoleChange,
  handleFormRoleDateChange,
  handleCohortRoleOpen,
  handleDeleteModalOpen
}) {
  function statusOptionsByRole(role) {
    if (role === roles.applicant) {
      return optionApplicantStatus;
    }

    if (role === roles.student) {
      return optionStudentStatus;
    }
  }

  return (
    <Layout>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} sm={12}>
          <TitleArea>
            <Title>Roles</Title>
            <div>
              <ButtonMenu
                buttonText="Add Role"
                type="primary"
                options={[
                  {
                    label: 'Cohort Roles',
                    onClick: () => handleCohortRoleOpen()
                  }
                ]}
              />
            </div>
          </TitleArea>
        </Grid>
        {cohortRoles?.map((cohortRole, index) => (
          <Grid key={cohortRole.uuid} item xs={12} sm={12}>
            <LayoutInner>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <RoleBreak>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <CaptionSmall>ROLE {index + 1}</CaptionSmall>
                      <IconButton
                        onClick={() => handleDeleteModalOpen(cohortRole.uuid)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </RoleBreak>
                </Grid>
                <Grid container spacing={1} direction="row" item xs={12}>
                  <Grid item xs={12} sm={3}>
                    <FormSelectError
                      name="role"
                      label="Role*"
                      value={cohortRole.role}
                      handleChange={event =>
                        handleFormRoleChange(cohortRole.uuid, event)
                      }
                      options={optionRolesForCohortManagement}
                      handleValidation={validateInputString}
                      hasErrors={hasErrors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormSelectError
                      name="status"
                      label="Status*"
                      value={cohortRole.status}
                      handleChange={event =>
                        handleFormRoleChange(cohortRole.uuid, event)
                      }
                      options={statusOptionsByRole(cohortRole.role)}
                      handleValidation={validateInputString}
                      hasErrors={hasErrors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Date
                      dataTestId="cohort-role-start-date"
                      name="startDate"
                      label="Start Date*"
                      value={cohortRole.startDate}
                      indentifier={cohortRole.uuid}
                      hasErrors={hasErrors}
                      handleChange={handleFormRoleDateChange}
                      handleValidate={validateSelectDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Date
                      dataTestId="cohort-role-end-date"
                      name="endDate"
                      label="End Date"
                      value={cohortRole.endDate}
                      indentifier={cohortRole.uuid}
                      readOnly={cohortRole.status === 'active'}
                      placeholderText={
                        cohortRole.status === 'active' ? 'Present' : null
                      }
                      handleChange={handleFormRoleDateChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormSelectError
                      name="schoolUuid"
                      label="School/College"
                      value={cohort.program?.school?.uuid}
                      options={[
                        {
                          label: cohort.program?.school?.schoolName,
                          value: cohort.program?.school?.uuid
                        }
                      ]}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormSelectError
                      name="programUuid"
                      label="Program"
                      value={cohort.program?.uuid}
                      options={[
                        {
                          label: cohort.program?.programName,
                          value: cohort.program?.uuid
                        }
                      ]}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormSelectError
                      name="cohortUuid"
                      label="Cohort Name"
                      value={cohort.uuid}
                      options={[
                        {
                          label: cohort.name,
                          value: cohort.uuid
                        }
                      ]}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormSelectError
                      name="cohortUuid"
                      label="Cohort"
                      value={cohort.uuid}
                      options={[
                        {
                          label: `${moment(cohort.startDate).format(
                            'll'
                          )} - ${moment(cohort.endDate).format('ll')}`,
                          value: cohort.uuid
                        }
                      ]}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      name="email"
                      label="Email*"
                      value={convertNullToString(cohortRole.email)}
                      onChange={event =>
                        handleFormRoleChange(cohortRole.uuid, event)
                      }
                      handleValidate={validateInputString}
                      hasErrors={hasErrors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      name="phone"
                      label="Phone*"
                      value={convertNullToString(cohortRole.phone)}
                      onChange={event =>
                        handleFormRoleChange(cohortRole.uuid, event)
                      }
                      handleValidate={validateInputString}
                      hasErrors={hasErrors}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </LayoutInner>
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
}

CohortUserRoles.propTypes = {
  cohort: PropTypes.object,
  cohortRoles: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleFormRoleChange: PropTypes.func,
  handleFormRoleDateChange: PropTypes.func,
  handleCohortRoleOpen: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func
};

CohortUserRoles.defaultProps = {
  cohort: {
    uuid: '',
    program: { uuid: '', name: '', school: { uuid: '', schoolName: '' } }
  },
  cohortRoles: [],
  hasErrors: false,
  handleFormRoleChange: undefined,
  handleFormRoleDateChange: undefined,
  handleCohortRoleOpen: undefined,
  handleDeleteModalOpen: undefined
};
