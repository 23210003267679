import React from 'react';
import { Caption, Body1, Subtitle2 } from '@xcomp/xcomp-design-library';
import styled from 'styled-components';
import FormSelect from '../../Library/FormSelect';

export const ScoreColumnHeader = styled(({ width, ...rest }) => (
  <Caption {...rest} />
))`
  width: ${props => props.width || 'initial'};
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
`;

export const ScoreRow = styled(({ marginLeft, ...rest }) => <div {...rest} />)`
  width: ${props => `calc(100% - ${props.marginLeft || '30px'})`};
  margin-left: ${props => props.marginLeft || '30px'};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  padding: 8px 0;
`;

export const AccordionChild = styled(
  ({ offsetLeft, offsetRight, paddingBottom, ...rest }) => <div {...rest} />
)`
  margin-left: ${props => props.offsetLeft || '0'};
  margin-right: ${props => props.offsetRight || '0'};
  padding-bottom: ${props => props.paddingBottom || '0'};
  width: 100%;
`;

export const ScoreDetail = styled(({ criticalFailure, width, ...rest }) => (
  <Body1 {...rest} />
))`
  width: ${props => props.width || 'initial'};
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  color: ${props =>
    props.criticalFailure
      ? props.theme.colors.status.error
      : props.theme.fontColors.darker};
`;

export const TotalColumn = styled(
  ({ width, criticalFailure, withBorder, ...rest }) => <Body1 {...rest} />
)`
  width: ${props => props.width || 'initial'};
  padding: 0;
  margin: 0;
  color: ${props =>
    props.criticalFailure
      ? props.theme.colors.status.error
      : props.theme.fontColors.darker};
  border-top: ${props =>
    props.withBorder ? `1px solid ${props => props.theme.colors.black}` : '1px solid transparent'};
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const SpacingBox = styled(({ width, ...rest }) => <div {...rest} />)`
  width: ${props => props.width || 'initial'};
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  padding-right: 16px;
  min-width: 50px;
`;

export const HeaderSpacer = styled.div`
  width: 100%;
  height: 36px;
`;

export const ListSortContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 8px 21px;
`;

export const SelectSC = styled(FormSelect)`
  && {
    background-color: ${props => props.theme.colors.white};
    width: 500px;
  }
`;

export const RowLabelsLayout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding-right: 44px;

  p {
    flex-grow: 0;
    min-width: 100px;
    margin-right: 0.5rem;

    &:first-child {
      flex-grow: 2;
    }
  }
`;

export const AttemptHeader = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 95%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }
`;

export const AttemptLabel = styled(Subtitle2)`
  color: ${props => props.theme.fontColors.darker};
  position: relative;
  display: inline-block;
  z-index: 100;
  background: ${props => props.theme.colors.white};
  padding-right: 0.5rem;
  padding-left: 30px;
  font-weight: 600;
`;
