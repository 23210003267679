import { Model, attr } from 'redux-orm';
import {
  POST_COURSE_AGE_MOD_SUCCESS,
  GET_COURSE_AGE_MOD_SUCCESS
} from '../actions-type';

class CourseAgeMod extends Model {
  static reducer(action, CourseAgeMod, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_COURSE_AGE_MOD_SUCCESS: {
        const courseAgeMods = [...payload.courseAgeMods];
        courseAgeMods.forEach(courseAgeMod =>
          CourseAgeMod.upsert(courseAgeMod)
        );
        break;
      }

      case POST_COURSE_AGE_MOD_SUCCESS: {
        const courseAgeMods = [...payload.courseAgeMods];
        courseAgeMods.forEach(courseAgeMod =>
          CourseAgeMod.upsert(courseAgeMod)
        );
        break;
      }

      default:
        return session;
    }
  }
}

CourseAgeMod.fields = {
  uuid: attr(),
  programUuid: attr(),
  year: attr(),
  low: attr(),
  high: attr(),
  mod: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

CourseAgeMod.modelName = 'CourseAgeMod';
CourseAgeMod.options = {
  idAttribute: 'uuid'
};

export default CourseAgeMod;
