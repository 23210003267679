import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Body1 } from '@xcomp/xcomp-design-library';
import _ from 'lodash';
import useSWRMutation from 'swr/mutation';

import {
  // scoresGroupedByResultCardAttempt,
  scoresGroupedByBlock
} from '../../../../redux/selectors/scoreSelectors';
import { assessmentBlockResultGroupedByBlockSelector } from '../../../../redux/selectors/assessmentBlockSelectors';
import { doDeleteStudentScoresError, doDeleteStudentScoresSuccess } from '../../../../redux/actions/scoreActions';

import DeleteModal from '../../../Library/Modal/DeleteModal/DeleteModal';
import StepBlockPanel from '../../../Library/ScoresComponents/Step/StepBlockPanel';

import { isProgramAdmin } from '../../../../helpers/authorization';
import {
  totalByScoreType,
  decimalTenth,
  determineStudentCriticalFailure,
  fetcher
} from '../../../../helpers/utilities';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';

const StepPanelAccordion = styled(
  ({ withBottomBorder, withTopBorder, criticalFailure, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label,
  .column_two_label {
    flex-grow: 0;
    width: 100px;

    &.unreported {
      content: '';
    }
  }

  .accordion_row_label div {
    font-size: 16px;
    letter-spacing: 0.15;
    color: ${props => props.theme.fontColors.darker};
  }

  .column_two_label {
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props => props.theme.fontColors.darker};
    }
  }

  .column_one_label {
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props =>
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.theme.fontColors.darker};
    }
  }

  width: 100%;
`;

const NoScoresMessage = styled(Body1)`
  margin-left: 66px;
  margin-top: 0;
`;

export default function StepsPanel({
  assessmentBlocks,
  assessmentType,
  assessmentUuid,
  fullName,
  // handleScoreAddAttemptModalOpen,
  handleScoreAddModalOpen,
  handleScoreEditModalOpen,
  scoreType,
  studentUuid
}) {
  const dispatch = useDispatch();

  const [openDeleteStudentScoresModal, setOpenDeleteStudentScoresModal] =
    useState(false);
  const [deleteParameters, setDeleteParameters] = useState('');

  // const scoresByResultCardAttempt = useSelector(
  //   state =>
  //     scoresGroupedByResultCardAttempt(state, assessmentUuid, studentUuid),
  //   _.isEqual
  // );

  const scoresByBlock = useSelector(
    state => scoresGroupedByBlock(state, assessmentUuid, studentUuid),
    _.isEqual
  );

  const userState = useSelector(state => state.userState);
  const studentBlockResultsByBlock = useSelector(
    state => assessmentBlockResultGroupedByBlockSelector(state, studentUuid, Object.keys(scoresByBlock)),
    _.isEqual
  );

  // const lastestResultCard = _.findLastKey(scoresByResultCardAttempt);
  // const lastestAttemptCard = _.get(
  //   scoresByResultCardAttempt[lastestResultCard],
  //   '0.attempt',
  //   0
  // );
  // const availableAttempt = lastestAttemptCard + 1;

  const { trigger, error, data, isMutating } = useSWRMutation(
    `/api/scores/delete/student?`,
    fetcher,
    {
      throwOnError: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() =>
    {
      if (data) {
        dispatch(doDeleteStudentScoresSuccess(data));
      }
    },
    // eslint-disable-next-line
    [data]
  )

  useEffect(() => 
    {
      if (error) {
        dispatch(doDeleteStudentScoresError({...error.info}));
      }
    },
    // eslint-disable-next-line
    [error]
  )

  const totalRel = totalByScoreType(scoreType, 'relValue', scoresByBlock);

  const totalPot = totalByScoreType(scoreType, 'potValue', scoresByBlock);

  const handleDeleteStudentScoresModalOpen = (parameters) => {
    setOpenDeleteStudentScoresModal(true);
    setDeleteParameters(parameters);
  };

  const handleDeleteStudentScoresModalClose = () => {
    setOpenDeleteStudentScoresModal(false);
    setDeleteParameters('');
  };

  const handleDeleteStudentScores = () => {
    trigger({
      queryParameters: `studentUuid=${studentUuid}&assessmentUuid=${assessmentUuid}${deleteParameters}`, 
      options: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      }
    })
    handleDeleteStudentScoresModalClose();
  };

  // const handleScoreAdd = () =>
  //   handleScoreAddAttemptModalOpen(studentUuid, availableAttempt);

  const isAdmin = isProgramAdmin({ userState });

  const stepPanelActions = isAdmin
    ? [
        // {
        //   label: 'Add Attempt Score',
        //   onClick: handleScoreAdd,
        //   shouldOpenOnClick: false,
        //   icon: 'add'
        // },
        {
          label: 'Delete Student Scores',
          onClick: () => handleDeleteStudentScoresModalOpen(''),
          shouldOpenOnClick: false,
          icon: 'delete'
        }
      ]
    : false;

  const isUnreported = _.flatten(Object.values(scoresByBlock))?.length === 0;
  const isDidactic =
    assessmentType === 'Applied Didactic' ||
    assessmentType === 'Basic Didactic' ||
    scoreType === 'Opportunity';
  const criticalFailure =
    !isDidactic && determineStudentCriticalFailure(studentBlockResultsByBlock);

  const relVal = isUnreported ? '--' : decimalTenth(totalRel);

  return (
    <>
      <StepPanelAccordion
        actions={stepPanelActions}
        loading={isMutating}
        columnOne={criticalFailure ? '0' : relVal}
        columnOneClassName={
          isUnreported ? 'unreported column_one_label' : 'column_one_label'
        }
        columnTwo={isUnreported ? '--' : decimalTenth(totalPot)}
        columnTwoClassName={
          isUnreported ? 'unreported column_two_label' : 'column_two_label'
        }
        criticalFailure={criticalFailure}
        expandWidth="66px"
        height="50px"
        label={fullName}
        withBottomBorder
      >
        {isUnreported ? (
          <NoScoresMessage>
            No scores have been reported for this student.
          </NoScoresMessage>
        ) : (
          assessmentBlocks.map(assessmentBlock => (
            <StepBlockPanel
              assessmentBlock={assessmentBlock}
              assessmentType={assessmentType}
              assessmentUuid={assessmentUuid}
              blockScoresByAttempt={_.groupBy(
                scoresByBlock[assessmentBlock.uuid],
                'attempt'
              )}
              handleScoreAddModalOpen={handleScoreAddModalOpen}
              handleScoreEditModalOpen={handleScoreEditModalOpen}
              handleDeleteStudentScoresModalOpen={handleDeleteStudentScoresModalOpen}
              isSingleBlock={assessmentBlocks.length === 1}
              key={assessmentBlock.uuid}
              scoreType={scoreType}
              studentBlockResultsByBlock={
                studentBlockResultsByBlock[assessmentBlock.uuid]
              }
              studentUuid={studentUuid}
              isAdmin={isAdmin}
              isMutating={isMutating}
            />
          ))
        )}
      </StepPanelAccordion>
      {openDeleteStudentScoresModal &&
        <DeleteModal
          customHeading={
            // eslint-disable-next-line
            deleteParameters === ''
              ? 'Are you sure you want to delete the scores for this student?'
              : (deleteParameters.includes('opportunityNumber') || deleteParameters.includes('attemptNumber'))
                ? `Are you sure you want to delete the scores for this ${deleteParameters.includes('opportunityNumber') ? 'opportunity' : 'attempt'}, block and student?`
                : 'Are you sure you want to delete the scores for this block and student?'
          }
          handleDelete={handleDeleteStudentScores}
          handleModalClose={handleDeleteStudentScoresModalClose}
          modalOpen={openDeleteStudentScoresModal}
        />
      }
    </>
  );
}

StepsPanel.propTypes = {
  assessmentBlocks: PropTypes.array,
  assessmentType: PropTypes.string,
  assessmentUuid: PropTypes.string,
  fullName: PropTypes.string,
  handleScoreAddModalOpen: PropTypes.func,
  handleScoreEditModalOpen: PropTypes.func,
  scoreType: PropTypes.string,
  studentUuid: PropTypes.string
};

StepsPanel.defaultProps = {
  assessmentBlocks: [],
  assessmentType: '',
  assessmentUuid: '',
  fullName: '',
  handleScoreAddModalOpen: undefined,
  handleScoreEditModalOpen: undefined,
  scoreType: '',
  studentUuid: ''
};
