import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

import { WarningIcon } from '@xcomp/xcomp-design-library/dist/icons';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import { format } from 'date-fns';
import { formatMMddyyyy } from '../../../helpers/date-fns.utils';
import {
  ContainerPage,
  ElevateLow,
  ContainerContent
} from '../../Library/Layout';

const StatusBar = styled.div`
  display: flex;
  align-items: center;
  background: ${props => {
    switch (props.type) {
      case 'nomatch': {
        return props.theme.colors.primary;
      }
      case 'match': {
        return props.theme.colors.status.error;
      }
      default:
        return 'grey';
    }
  }};
  color: ${props => props.theme.colors.white};
  padding: 20px;
  border-radius: 4px;
  font-size: 0.9375rem;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Message = styled.div`
  margin-left: 10px;
`;

const StepStages = styled.div`
  margin-left: 10px;
`;

const Step = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const StepNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${props => props.theme.colors.blue[100]};
  width: 20px;
  height: 20px;
  margin-right: 10px;
  font-size: 12px;
`;
const StepMessage = styled.div``;

const Bold = styled.span``;

const UserSection = styled.div`
  width: 100%;
`;

const UserParent = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-top: 25px;
  padding-bottom: 25px;
`;

const UserChild = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-top: 20px;
  padding-bottom: 20px;
`;

const UserChildName = styled.div`
  padding-left: 40px;
  text-transform: capitalize;
`;

const UserName = styled.div`
  text-transform: capitalize;
`;

const InsitutionName = styled.div`
  text-transform: capitalize;
  padding-left: 40px;
  font-size: 12px;
  color: ${props => props.theme.colors.grey[600]};
  padding-top: 5px;
  padding-bottom: 5px;
`;

const UserInstitutionId = styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 19px;
  color: ${props => props.theme.colors.grey[600]};
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const Info = styled.div``;

const InfoGroup = styled.div`
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
`;

const InfoLabel = styled.div`
  text-transform: uppercase;
  line-height: 19px;
  color: ${props => props.theme.colors.grey[600]};
  padding-right: 5px;
`;
const InfoValue = styled.div`
  line-height: 19px;
  color: ${props => props.theme.colors.grey[600]};
`;

export default function UserTabCheck({ status, handleDownloadMatches }) {
  function matchInstititions(matches) {
    return _(matches)
      .groupBy('uuid')
      .map((institutions, uuid) => {
        return {
          uuid,
          firstName: institutions[0].firstName,
          lastName: institutions[0].lastName,
          middleName: institutions[0].middleName,
          birthDate: institutions[0].birthDate,
          suffix: institutions[0].suffix,
          gender: institutions[0].gender,
          ethnicity: institutions[0].ethnicity,
          marriedLastName: institutions[0].marriedLastName,
          institutions
        };
      })
      .value();
  }

  function csvFormat(users) {
    const flatten = [];

    for (let i = 0; i < users.length; i += 1) {
      for (let j = 0; j < users[i].matches.length; j += 1) {
        const match = {
          'RECORD-MATCH birthDate': users[i].matches[j].birthDate,
          'RECORD-MATCH email': users[i].matches[j].email,
          'RECORD-MATCH ethnicity': users[i].matches[j].ethnicity,
          'RECORD-MATCH firstName': users[i].matches[j].firstName,
          'RECORD-MATCH gender': users[i].matches[j].gender,
          'RECORD-MATCH globalUserUuid':
            users[i].matches[j].global_user_institutions.globalUserUuid,
          'RECORD-MATCH institutionId':
            users[i].matches[j].global_user_institutions.institutionId,
          'RECORD-MATCH institutionTIdypeUuid':
            users[i].matches[j].global_user_institutions.institutionIdTypeUuid,
          'RECORD-MATCH institutionName':
            users[i].matches[j].global_user_institutions.institution.name,
          'RECORD-MATCH lastName': users[i].matches[j].lastName,
          'RECORD-MATCH marriedName': users[i].matches[j].marriedName,
          'RECORD-MATCH middleName': users[i].matches[j].middleName,
          'RECORD-MATCH phone': users[i].matches[j].phone,
          'RECORD-MATCH suffix': users[i].matches[j].suffix
        };

        const payload = {
          ...users[i].record,
          ...match
        };

        flatten.push(payload);
      }
    }

    return flatten;
  }

  function statusBar() {
    if (status?.usersCheckMatch?.length === 0) {
      return (
        <>
          <StatusBar type="nomatch">
            <WarningIcon />
            <Message>0 similar users found.</Message>
          </StatusBar>
          <StepStages>
            <Step>
              <StepNumber>1</StepNumber>
              <StepMessage>
                Proceed to the <Bold>Upload Tab</Bold> to add users to the
                instiution.
              </StepMessage>
            </Step>
          </StepStages>
        </>
      );
    } else if (status?.usersCheckMatch?.length > 0) {
      return (
        <>
          <Grid container justifyContent="flex-end">
            <ButtonPrimary onClick={handleDownloadMatches}>
              <CSVLink
                filename={`${format(
                  new Date(),
                  'yyyy-MM-dd'
                )}-matching-user-results-.csv`}
                target="_blank"
                data={csvFormat(status?.usersCheckMatch)}
              >
                Download CSV
              </CSVLink>
            </ButtonPrimary>
          </Grid>
          <StatusBar type="match">
            <WarningIcon />
            <Message>Similar users found.</Message>
          </StatusBar>
          <StepStages>
            <Step>
              <StepNumber>1</StepNumber>
              <StepMessage>Contact your XCOMP administrator.</StepMessage>
            </Step>
            <Step>
              <StepNumber>2</StepNumber>
              <StepMessage>
                Resolve issues by adding global user IDs to upload file and
                reupload in the <Bold>Upload Tab</Bold>.
              </StepMessage>
            </Step>
          </StepStages>

          {status?.usersCheckMatch.map(user => (
            <UserSection key={user?.record?.institutionId}>
              <UserParent>
                <Grid container alignItems="center">
                  <Grid item xs={3} sm={3}>
                    <UserName>{`${user?.record?.lastName}, ${user?.record?.firstName}`}</UserName>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <UserInstitutionId>{`${user?.record?.institutionId}`}</UserInstitutionId>
                  </Grid>
                  <Grid container item xs={6} sm={6} justifyContent="flex-end">
                    <Chip
                      color="primary"
                      size="small"
                      label={`${user?.matches?.length} Similar User(s)`}
                    />
                  </Grid>
                </Grid>
              </UserParent>
              <UserChild>
                {matchInstititions(user?.matches).map(match => (
                  <Grid container key={match?.uuid}>
                    <Grid item xs={3} sm={3}>
                      <UserChildName>{`${match?.lastName}, ${match?.firstName}`}</UserChildName>
                    </Grid>
                    <Grid item xs={5} sm={5}>
                      <Info>
                        <InfoGroup>
                          <InfoLabel>GLOBAL USER ID:</InfoLabel>
                          <InfoValue>{match?.uuid}</InfoValue>
                        </InfoGroup>
                        <InfoGroup>
                          <InfoLabel>DOB:</InfoLabel>
                          <InfoValue>
                            {formatMMddyyyy(match?.birthDate)}
                          </InfoValue>
                        </InfoGroup>
                        <InfoGroup>
                          <InfoLabel>Suffix:</InfoLabel>
                          <InfoValue>{match?.suffix}</InfoValue>
                        </InfoGroup>
                        <InfoGroup>
                          <InfoLabel>Gender:</InfoLabel>
                          <InfoValue>{match?.gender}</InfoValue>
                        </InfoGroup>
                        <InfoGroup>
                          <InfoLabel>Ethnicity:</InfoLabel>
                          <InfoValue>{match?.ethnicity}</InfoValue>
                        </InfoGroup>
                      </Info>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={4}
                      sm={4}
                      justifyContent="flex-end"
                    >
                      <Chip
                        label={`${match?.institutions.length} Institutions(s)`}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {match?.institutions.map(institution => (
                        <Grid
                          container
                          key={
                            institution?.global_user_institutions?.institution
                              ?.uuid
                          }
                        >
                          <Grid item xs={3} sm={3}>
                            <InsitutionName>
                              {institution.global_user_institutions?.institution?.name.toLowerCase()}
                            </InsitutionName>
                          </Grid>
                          <Grid item xs={9} sm={9}>
                            <UserInstitutionId>
                              {`${institution.global_user_institutions?.institutionId}`}
                            </UserInstitutionId>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </UserChild>
            </UserSection>
          ))}
        </>
      );
    } else {
      return (
        <StepStages>
          <Step>
            <StepNumber>1</StepNumber>
            <StepMessage>
              Upload user to check if they exist in other institutions across
              the XCOMP Ecosystem.
            </StepMessage>
          </Step>
        </StepStages>
      );
    }
  }

  return (
    <>
      <ContainerPage paddingTop="0px">
        <ElevateLow>
          <ContainerContent>{statusBar()}</ContainerContent>
        </ElevateLow>
      </ContainerPage>
    </>
  );
}

UserTabCheck.propTypes = {
  status: PropTypes.object,
  handleDownloadMatches: PropTypes.func
};

UserTabCheck.defaultProps = {
  status: undefined,
  handleDownloadMatches: undefined
};
