import React from 'react';
import PropTypes from 'prop-types';
import EncounterContainer from './EncounterContainer';
import AssessmentContainer from './AssessmentContainer';

export default function SyllabusDetail({ selectedType }) {
  return (
    <div>
      {selectedType === 'encounter' && <EncounterContainer />}
      {selectedType === 'assessment' && <AssessmentContainer />}
    </div>
  );
}

SyllabusDetail.propTypes = {
  selectedType: PropTypes.string
};

SyllabusDetail.defaultProps = {
  selectedType: undefined
};
