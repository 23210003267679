import {
  GET_RIGOR_HISTORICAL_REPORT,
  GET_RIGOR_HISTORICAL_REPORT_SUCCESS,
  GET_RIGOR_HISTORICAL_REPORT_ERROR
} from '../actions-type';

const doGetRigorHistoricalReport = programUuid => ({
  type: GET_RIGOR_HISTORICAL_REPORT,
  payload: { programUuid }
});

const doGetRigorHistoricalReportSuccess = result => ({
  type: GET_RIGOR_HISTORICAL_REPORT_SUCCESS,
  payload: result
});

const doGetRigorHistoricalReportError = error => ({
  type: GET_RIGOR_HISTORICAL_REPORT_ERROR,
  payload: error
});

export {
  doGetRigorHistoricalReport,
  doGetRigorHistoricalReportSuccess,
  doGetRigorHistoricalReportError
};
