import {
  CLEAR_CREATED,
  GET_COURSE_COLLECTIONS,
  GET_COURSE_COLLECTIONS_SUCCESS,
  GET_COURSE_COLLECTIONS_ERROR,
  SEARCH_COLLECTION_COURSES,
  SEARCH_COLLECTION_COURSES_SUCCESS,
  SEARCH_COLLECTION_COURSES_ERROR,
  POST_COURSE_COLLECTION,
  POST_COURSE_COLLECTION_SUCCESS,
  POST_COURSE_COLLECTION_ERROR,
  PUT_COURSE_COLLECTION,
  PUT_COURSE_COLLECTION_SUCCESS,
  PUT_COURSE_COLLECTION_ERROR,
  DELETE_COURSE_COLLECTION,
  DELETE_COURSE_COLLECTION_SUCCESS,
  DELETE_COURSE_COLLECTION_ERROR
} from '../actions-type';

const doClearCreated = () => ({ type: CLEAR_CREATED });

const doGetCourseCollections = cohortUuid => ({
  type: GET_COURSE_COLLECTIONS,
  payload: {
    cohortUuid
  }
});

const doGetCourseCollectionsSuccess = data => ({
  type: GET_COURSE_COLLECTIONS_SUCCESS,
  payload: {
    courseCollections: data.courseCollections
  }
});

const doGetCourseCollectionsError = error => ({
  type: GET_COURSE_COLLECTIONS_ERROR,
  payload: error
});

const doSearchCollectionCourses = searchTerm => ({
  type: SEARCH_COLLECTION_COURSES,
  payload: {
    searchTerm
  }
});

const doSearchCollectionCoursesSuccess = data => ({
  type: SEARCH_COLLECTION_COURSES_SUCCESS,
  payload: {
    courses: data.courses
  }
});

const doSearchCollectionCoursesError = error => ({
  type: SEARCH_COLLECTION_COURSES_ERROR,
  payload: error
});

const doPostCourseCollection = newCourseCollection => ({
  type: POST_COURSE_COLLECTION,
  payload: {
    newCourseCollection
  }
});

const doPostCourseCollectionSuccess = data => ({
  type: POST_COURSE_COLLECTION_SUCCESS,
  payload: {
    createdCourseCollection: data.createdCourseCollection
  }
});

const doPostCourseCollectionError = error => ({
  type: POST_COURSE_COLLECTION_ERROR,
  payload: error
});

const doPutCourseCollection = courseCollectionUpdates => ({
  type: PUT_COURSE_COLLECTION,
  payload: {
    courseCollectionUpdates
  }
});

const doPutCourseCollectionSuccess = data => ({
  type: PUT_COURSE_COLLECTION_SUCCESS,
  payload: {
    updatedCourseCollection: data.updatedCourseCollection
  }
});

const doPutCourseCollectionError = error => ({
  type: PUT_COURSE_COLLECTION_ERROR,
  payload: error
});

const doDeleteCourseCollection = uuid => ({
  type: DELETE_COURSE_COLLECTION,
  payload: {
    uuid
  }
});

const doDeleteCourseCollectionSuccess = data => ({
  type: DELETE_COURSE_COLLECTION_SUCCESS,
  payload: {
    deletedCourseCollection: data.deletedCourseCollection
  }
});

const doDeleteCourseCollectionError = error => ({
  type: DELETE_COURSE_COLLECTION_ERROR,
  payload: error
});

export {
  doClearCreated,
  doGetCourseCollections,
  doGetCourseCollectionsSuccess,
  doGetCourseCollectionsError,
  doSearchCollectionCourses,
  doSearchCollectionCoursesSuccess,
  doSearchCollectionCoursesError,
  doPostCourseCollection,
  doPostCourseCollectionSuccess,
  doPostCourseCollectionError,
  doPutCourseCollection,
  doPutCourseCollectionSuccess,
  doPutCourseCollectionError,
  doDeleteCourseCollection,
  doDeleteCourseCollectionSuccess,
  doDeleteCourseCollectionError
};
