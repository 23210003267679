import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Header from '../../Library/Header';
import { ContainerResize, ContainerTabs } from '../../Library/Layout';
import CohortApplicantContainer from '../CohortApplicant/CohortApplicantContainer';
import CohortStudentContainer from '../CohortStudent/CohortStudentContainer';
import { CohortApplicantsProvider } from '../CohortApplicant/cohortApplicants.context';

const TabsSC = styled(Tabs)`
  && {
    border-top: 1px solid ${props => props.theme.colors.grey[300]};
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
    background: ${props => props.theme.colors.white};
  }
`;

const TabSC = styled(Tab)`
  && {
    max-width: 100%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    line-height: 16px;
    width: 100%;
  }
`;

const LinkSC = styled(Link)`
  width: 100%;
`;

export default function CohortRole({
  path,
  pathname,
  cohortUuid,
  programUuid
}) {
  let tabLocation = 0;

  const pathnameWithQuery = `${pathname}?programUuid=${programUuid}&cohortUuid=${cohortUuid}`;

  if (
    pathnameWithQuery ===
    `/program-management/cohort/roles/applicants?programUuid=${programUuid}&cohortUuid=${cohortUuid}`
  ) {
    tabLocation = 0;
  } else if (
    pathnameWithQuery ===
    `/program-management/cohort/roles/students?programUuid=${programUuid}&cohortUuid=${cohortUuid}`
  ) {
    tabLocation = 1;
  }

  const renderLink = useMemo(
    () =>
      forwardRef((props, ref) => (
        // eslint-disable-next-line react/prop-types
        <LinkSC to={props.topath} {...props} ref={ref} />
      )),
    []
  );

  return (
    <ContainerResize>
      <Header
        title="Cohort Roles"
        subtitle="Manage applicant and students"
        backButtonLocation="/program-management/cohort"
      />
      <ContainerTabs>
        <TabsSC
          value={tabLocation}
          textColor="primary"
          variant="fullWidth"
          indicatorColor="primary"
        >
          <TabSC
            component={renderLink}
            topath={`${path}/applicants?programUuid=${programUuid}&cohortUuid=${cohortUuid}`}
            label="Applicants"
          />
          <TabSC
            component={renderLink}
            topath={`${path}/students?programUuid=${programUuid}&cohortUuid=${cohortUuid}`}
            label="Students"
          />
        </TabsSC>
        <Switch>
          <Route
            path={`${path}/applicants`}
            // eslint-disable-next-line no-unused-vars
            component={props => {
              return (
                <CohortApplicantsProvider>
                  <CohortApplicantContainer
                    cohortUuid={cohortUuid}
                    programUuid={programUuid}
                    {...props}
                  />
                </CohortApplicantsProvider>
              );
            }}
          />
          <Route
            path={`${path}/students`}
            // eslint-disable-next-line no-unused-vars
            component={props => {
              return (
                <CohortStudentContainer
                  cohortUuid={cohortUuid}
                  programUuid={programUuid}
                  {...props}
                />
              );
            }}
          />
        </Switch>
      </ContainerTabs>
    </ContainerResize>
  );
}

CohortRole.propTypes = {
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  path: PropTypes.string,
  pathname: PropTypes.string
};

CohortRole.defaultProps = {
  cohortUuid: undefined,
  programUuid: undefined,
  path: '',
  pathname: ''
};
