import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { classificationsSelector } from '../../../../../../redux/selectors/classificationSelectors';
import AptitudeTest from './AptitudeTest';
import AccordionRowContainer from '../../../../../Library/AccordionRow/AccordionRowContainer';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .accordion_row_label {
    flex-grow: 2;
  }

  && {
    .accordion_row_label div {
      letter-spacing: 0.15;
    }
  }

  width: 100%;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.grey[500]};
`;

const Input = styled.div`
  margin-left: 60px;
  color: ${props => props.theme.fontColors.darker};
`;

export default function AptitudeSkill({ programUuid, category, source }) {
  const classifications = useSelector(
    state => classificationsSelector(state, programUuid, category, source),
    _.isEqual
  );

  return (
    <>
      {classifications.map(classification => (
        <Accordion
          rowOffset="132px"
          key={classification.uuid}
          label={
            <Group>
              <Label>Scope</Label>
              <Input>{classification.scope}</Input>
            </Group>
          }
        >
          <AptitudeTest classificationUuid={classification.uuid} />
        </Accordion>
      ))}
    </>
  );
}

AptitudeSkill.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  programUuid: PropTypes.string
};

AptitudeSkill.defaultProps = {
  category: undefined,
  source: undefined,
  programUuid: undefined
};
