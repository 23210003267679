import fetch from '../helpers/fetch';

const getCohortAssessments = cohortUuid =>
  fetch(`/api/cohort/${cohortUuid}/cohort-assessments`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCohortAssessmentsStudent = (cohortUuid, userUuid) =>
  fetch(
    `/api/cohort-assessments-student?cohortUuid=${cohortUuid}&userUuid=${userUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCohortAssessmentScores = ({ cohortUuid, cohortAssessmentUuid }) =>
  fetch(
    `/api/cohort/${cohortUuid}/cohort-assessment/${cohortAssessmentUuid}/scores`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCohortAssessmentCommitmentLog = ({ cohortUuid, assessmentUuid }) =>
  fetch(
    `/api/cohort/${cohortUuid}/cohort-assessment/${assessmentUuid}/commitment-log`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postCohortAssessmentCollection = ({ title, cohortUuid }) =>
  fetch(`/api/cohort/${cohortUuid}/cohort-assessment-collection`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ title })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putCohortAssessmentCollection = ({ uuid, title, cohortUuid }) =>
  fetch(`/api/cohort/${cohortUuid}/cohort-assessment-collection/${uuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ title })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putCohortAssessmentStudentCommitStatus = ({
  assessmentUuid,
  studentUuid,
  cohortUuid,
  cohortAssessmentStatusCommit,
  justificationMessage
}) =>
  fetch(
    `/api/cohort/${cohortUuid}/cohort-assessment/${assessmentUuid}/student/${studentUuid}/commitment-status`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        cohortAssessmentStatusCommit,
        justificationMessage
      })
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteCohortAssessmentCollection = ({ cohortUuid, uuid }) =>
  fetch(`/api/cohort/${cohortUuid}/cohort-assessment-collection/${uuid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

const postCohortAssessment = ({ cohortUuid, ...cohortAssessment }) =>
  fetch(`/api/cohort/${cohortUuid}/cohort-assessment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ ...cohortAssessment })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putCohortAssessment = ({ uuid, cohortUuid, ...cohortAssessment }) =>
  fetch(`/api/cohort/${cohortUuid}/cohort-assessment/${uuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ ...cohortAssessment })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchCohortAssessmentStatus = ({ uuid, cohortUuid, status }) =>
  fetch(`/api/cohort/${cohortUuid}/cohort-assessment/${uuid}/status`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ status })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteCohortAssessment = ({ cohortUuid, uuid }) =>
  fetch(`/api/cohort/${cohortUuid}/cohort-assessment/${uuid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  getCohortAssessments,
  getCohortAssessmentsStudent,
  getCohortAssessmentScores,
  getCohortAssessmentCommitmentLog,
  postCohortAssessmentCollection,
  putCohortAssessmentCollection,
  deleteCohortAssessmentCollection,
  postCohortAssessment,
  putCohortAssessment,
  putCohortAssessmentStudentCommitStatus,
  patchCohortAssessmentStatus,
  deleteCohortAssessment
};
