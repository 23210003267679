import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AcademicYearEdit from './AcademicYearEdit';
import DeleteModalRedirect from '../../../../Library/Modal/DeleteModal/DeleteModalRedirect';

import {
  validateTermsPeriod,
  validateTermDates
} from '../../../../../helpers/validation/validateAcademicYearTerm';

import { validateSelectDate } from '../../../../../helpers/validation/validateGeneric';
import { academicYearSelectorByMatchParams } from '../../../../../redux/selectors/academicYearsSelectors';

import {
  doGetAcademicYear,
  doPutAcademicYear,
  doDeleteAcademicYear
} from '../../../../../redux/actions/academicYearActions';

export default function AcademicYearEditContainer({ match }) {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [terms, setTerms] = useState([]);
  const [termsRemoval, setTermsRemoval] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const schoolUuid = useSelector(state => state.userState.selectedSchoolUuid);
  const { academicYearUuid } = match.params;

  const academicYear = useSelector(
    state => academicYearSelectorByMatchParams(state, academicYearUuid),
    _.isEqual
  );

  useEffect(() => {
    dispatch(doGetAcademicYear(academicYearUuid));
  }, [dispatch, academicYearUuid]);

  useEffect(() => {
    if (academicYear) {
      setStartDate(academicYear.startDate);
      setEndDate(academicYear.endDate);

      const termsIncludeIndentifier = academicYear.terms.map(term => ({
        ...term,
        indentifier: uuidv4().toString()
      }));

      setTerms(termsIncludeIndentifier);
    }
  }, [academicYear]);

  const handleDateChange = (indentifier, field, date) => {
    switch (field) {
      case 'startDate':
        setStartDate(date);
        break;
      case 'endDate':
        setEndDate(date);
        break;
      default:
        break;
    }
  };

  const handleAddTerm = () => {
    const addTerm = {
      indentifier: uuidv4().toString(),
      period: '',
      startDate: '',
      endDate: '',
      academicYearUuid
    };

    setTerms([...terms, addTerm]);
  };

  const handleTermChange = (indentifier, event) => {
    const { value } = event.target;

    const termsUpdated = terms.map(term => {
      if (term.indentifier === indentifier) {
        return { ...term, period: value };
      } else {
        return term;
      }
    });

    setTerms(termsUpdated);
  };

  const handleRemoveTerm = indentifier => {
    const filterUpdate = terms.filter(term => term.indentifier !== indentifier);
    setTerms(filterUpdate);

    const filterRemove = terms.filter(term => term.indentifier === indentifier);
    setTermsRemoval([...termsRemoval, ...filterRemove]);
  };

  const handleTermDateChange = (indentifier, field, date) => {
    let update;

    if (field === 'startDate') {
      update = {
        startDate: date
      };
    }

    if (field === 'endDate') {
      update = {
        endDate: date
      };
    }

    const termsUpdated = terms.map(term => {
      if (term.indentifier === indentifier) {
        return { ...term, ...update };
      } else {
        return term;
      }
    });

    setTerms(termsUpdated);
  };

  const validate = () => {
    const startDateError = validateSelectDate(startDate);
    const endDateError = validateSelectDate(endDate);
    const termsPeriodError = validateTermsPeriod(terms);
    const termsDateError = validateTermDates(terms);

    const validations = {
      startDateInvalid: startDateError,
      endDateInvalid: endDateError,
      termsPeriodInvalid: termsPeriodError,
      termsDateInvalid: termsDateError
    };

    const isValid = !_.some(validations, { invalid: true });

    return isValid;
  };

  const handleSubmit = () => {
    const check = validate();

    if (check) {
      setSubmit(true);
      setHasErrors(false);

      const payload = {
        uuid: academicYear.uuid,
        startDate,
        endDate,
        terms,
        termsRemoval,
        schoolUuid
      };

      dispatch(doPutAcademicYear(payload));
    } else {
      setSubmit(false);
      setHasErrors(true);
    }
  };

  const handleDeleteModalOpen = () => {
    setModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setModalOpen(false);
  };

  const handleDelete = () => {
    dispatch(doDeleteAcademicYear(academicYear.uuid));
    setModalOpen(false);
  };

  return (
    <div>
      <AcademicYearEdit
        startDate={startDate}
        endDate={endDate}
        terms={terms}
        academicYear={academicYear}
        hasErrors={hasErrors}
        submit={submit}
        handleDateChange={handleDateChange}
        handleAddTerm={handleAddTerm}
        handleTermChange={(indentifier, event) =>
          handleTermChange(indentifier, event)
        }
        handleTermDateChange={handleTermDateChange}
        handleRemoveTerm={handleRemoveTerm}
        handleSubmit={handleSubmit}
        handleDeleteModalOpen={handleDeleteModalOpen}
      />
      <DeleteModalRedirect
        modalOpen={modalOpen}
        type="academic year"
        link="/school-management/definitions/academicyearterm"
        handleModalClose={handleDeleteModalClose}
        handleDelete={handleDelete}
      />
    </div>
  );
}

AcademicYearEditContainer.propTypes = {
  match: PropTypes.object
};
AcademicYearEditContainer.defaultProps = {
  match: {}
};
