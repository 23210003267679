import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { parse, differenceInDays } from 'date-fns';
import Decimal from 'decimal.js-light';
import { doGetTranscript } from '../../../redux/actions/applicantTranscriptActions';
import { useQuery } from '../../../helpers/hooks/router.hooks';
import { userSelector } from '../../../redux/selectors/usersSelectors';
import { cohortSelector } from '../../../redux/selectors/cohortsSelectors';
import TranscriptApplicant from './TranscriptApplicant';
import { doGetDegreeLevels } from '../../../redux/actions/sourceActions';
import { programSelector } from '../../../redux/selectors/programSelectors';
import { doGetLetterGradesBySchool } from '../../../redux/actions/letterGradeActions';

export default function ApplicantTranscriptContainer() {
  const [ageOnApplication, setAgeOnApplication] = useState('');
  const [openClone, setOpenClone] = useState(false);
  const dispatch = useDispatch();
  const query = useQuery();
  const cohortUuid = query.get('cohortUuid');
  const userUuid = query.get('userUuid');
  const programUuid = useSelector(state => state.userState.selectedProgramUuid);
  const user = useSelector(state => userSelector(state, userUuid), _.isEqual);
  const cohort = useSelector(
    state => cohortSelector(state, cohortUuid),
    _.isEqual
  );
  const startDate = cohort?.startDate;
  const program = useSelector(
    state => programSelector(state, programUuid),
    _.isEqual
  );

  useEffect(() => {
    dispatch(doGetTranscript(programUuid, cohortUuid, userUuid));
  }, [dispatch, programUuid, cohortUuid, userUuid]);

  useEffect(() => {
    dispatch(doGetDegreeLevels());
  }, [dispatch]);

  useEffect(() => {
    if (program.schoolUuid) {
      dispatch(doGetLetterGradesBySchool(program.schoolUuid));
    }
  }, [dispatch, program.schoolUuid]);

  useEffect(() => {
    if (startDate && user.birthDate) {
      const cohortStartDate = parse(
        startDate,
        'yyyy-MM-dd',
        new Date(),
        'MMMM dd, yyyy'
      );

      const userBirthDate = parse(
        user.birthDate,
        'yyyy-MM-dd',
        new Date(),
        'MMMM dd, yyyy'
      );

      const days = 365;
      const age = differenceInDays(cohortStartDate, userBirthDate) / days;
      const format = new Decimal(age).toFixed(2).toString();
      setAgeOnApplication(format);
    }
  }, [startDate, user.birthDate]);

  const handleCloneOpen = () => {
    setOpenClone(true);
  };

  const handleCloneClose = () => {
    setOpenClone(false);
  };

  return (
    <TranscriptApplicant
      user={user}
      ageOnApplication={ageOnApplication}
      cohortUuid={cohortUuid}
      programUuid={programUuid}
      openClone={openClone}
      handleCloneOpen={handleCloneOpen}
      handleCloneClose={handleCloneClose}
    />
  );
}
