import { call, put, takeEvery } from 'redux-saga/effects';
import { getCurrentResultCards } from '../../api/resultCardApi';
import {
  doGetCurrentResultCardsSuccess,
  doGetCurrentResultCardsError
} from '../actions/resultCardActions';

import { GET_CURRENT_RESULT_CARDS } from '../actions-type';

function* handleGetCurrentResultCards(action) {
  try {
    const { partUuid } = action.payload;
    const result = yield call(getCurrentResultCards, partUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCurrentResultCardsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCurrentResultCardsError(error));
  }
}

const resultCards = [
  takeEvery(GET_CURRENT_RESULT_CARDS, handleGetCurrentResultCards)
];

export { resultCards };
