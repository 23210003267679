import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Switch, Route, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Header from '../../Library/Header';
import { ContainerResize, ContainerTabs } from '../../Library/Layout';
import UserEditContainer from './UserEdit/UserEditContainer';
import UserRolesContainer from './UserRoles/UserRolesContainer';
import { userSelector } from '../../../redux/selectors/usersSelectors';
import {
  formatLastName,
  formatFirstNameMiddleName
} from '../../../helpers/format/user.format';

const TabsSC = styled(Tabs)`
  && {
    border-top: 1px solid ${props => props.theme.colors.grey[300]};
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
    background: ${props => props.theme.colors.white};
  }
`;

const TabSC = styled(Tab)`
  && {
    max-width: 100%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    line-height: 16px;
    width: 100%;
  }
`;

const LinkSC = styled(Link)`
  width: 100%;
`;

export default function UserModify({ path, pathname, userUuid }) {
  const generatePath = _(path).replace(':userUuid', userUuid);
  const user = useSelector(state => userSelector(state, userUuid), _.isEqual);
  const name = `${formatLastName(
    user?.lastName,
    user?.marriedLastName,
    user?.suffix
  )} ${formatFirstNameMiddleName(user?.firstName, user?.middleName)}`;

  let location = 0;

  if (pathname === `/user-management/user-edit/${userUuid}/info`) {
    location = 0;
  } else if (pathname === `/user-management/user-edit/${userUuid}/roles`) {
    location = 1;
  }

  const renderLink = useMemo(
    () =>
      forwardRef((props, ref) => (
        // eslint-disable-next-line react/prop-types
        <LinkSC to={props.topath} {...props} ref={ref} />
      )),
    []
  );

  return (
    <ContainerResize>
      <Header
        title="Edit User and Roles"
        subtitle={`${name}`}
        backButtonLocation="/user-management/dashboard/tab/view"
      />
      <ContainerTabs>
        <TabsSC
          value={location}
          textColor="primary"
          variant="fullWidth"
          indicatorColor="primary"
        >
          <TabSC
            component={renderLink}
            topath={`${generatePath}/info`}
            label="Info"
          />
          <TabSC
            component={renderLink}
            topath={`${generatePath}/roles`}
            label="Roles"
          />
        </TabsSC>
        <Switch>
          <Route
            path={`${generatePath}/info`}
            // eslint-disable-next-line no-unused-vars
            component={props => {
              return <UserEditContainer userUuid={userUuid} {...props} />;
            }}
          />
          <Route
            path={`${generatePath}/roles`}
            // eslint-disable-next-line no-unused-vars
            component={props => {
              return <UserRolesContainer userUuid={userUuid} {...props} />;
            }}
          />
        </Switch>
      </ContainerTabs>
    </ContainerResize>
  );
}

UserModify.propTypes = {
  path: PropTypes.string,
  pathname: PropTypes.string,
  userUuid: PropTypes.string
};

UserModify.defaultProps = {
  path: '',
  pathname: '',
  userUuid: undefined
};
