import fetch from '../helpers/fetch';

const getTermsByAcademicYear = academicYearUuid =>
  fetch(
    `/api/terms/find-by-academic-year?academicYearUuid=${academicYearUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getTermsByAcademicYears = academicYears =>
  fetch(`/api/terms/academic-years`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(academicYears)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getTermsByAcademicYear, getTermsByAcademicYears };
