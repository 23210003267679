import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import ActionButton from '../../Library/AccordionRow/ActionButton';
import { doSetShowAssessmentGridModal } from '../../../redux/actions/assessmentGridActions';
import { doSetShowQuantitativeGridModal } from '../../../redux/actions/quantitativeSkillsGridActions';

const Cohort = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  padding: 16px 0 16px 86px;
  p:first-child {
    width: 33%;
    font-family:
      IBM Plex Sans,
      sans-serif;
    font-size: 14px;
    color: ${props => props.theme.fontColors.dark};
  }

  p:nth-child(2) {
    font-family:
      IBM Plex Sans,
      sans-serif;
    font-size: 16px;
    color: ${props => props.theme.fontColors.darker};
  }
  div {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    padding-right: 24px;
  }
  &:hover {
    background-color: ${props => props.theme.colors.grey[300]};
  }
`;

const CohortPanel = ({
  cohort,
  onNavigateApplicantReports,
  onNavigateCompetencyGrid,
  onNavigateAssessmentGrid,
  onNavigateOpportunityReport,
  onNavigateSelfAssessmentReport,
  onNavigateQuantitativeSkillsGrid,
  onNavigateQualitativeAndDocumentsReport,
  onNavigateDidacticReport
}) => {
  const dispatch = useDispatch();

  const courseSectionActions = [
    {
      label: 'Competency Report',
      onClick: () => onNavigateCompetencyGrid(cohort.uuid),
      shouldOpenOnClick: false
    },
    {
      label: 'Program Assessment Report',
      onClick: () => {
        onNavigateAssessmentGrid(cohort.uuid);
        dispatch(doSetShowAssessmentGridModal(true));
      },
      shouldOpenOnClick: false
    },
    {
      label: 'Quantitative Skills Report',
      onClick: () => {
        onNavigateQuantitativeSkillsGrid(cohort.uuid);
        dispatch(doSetShowQuantitativeGridModal(true));
      },
      shouldOpenOnClick: false
    },
    {
      label: 'Applicant Reports',
      onClick: () => onNavigateApplicantReports(cohort.uuid),
      shouldOpenOnClick: false
    },
    {
      label: 'Opportunity Reports',
      onClick: () => onNavigateOpportunityReport(cohort.uuid),
      shouldOpenOnClick: false
    },
    {
      label: 'Self Assessment Report',
      onClick: () => onNavigateSelfAssessmentReport(cohort.uuid),
      shouldOpenOnClick: false
    },
    {
      label: 'Qualitative Skills and Documents',
      onClick: () => onNavigateQualitativeAndDocumentsReport(cohort.uuid),
      shouldOpenOnClick: false
    },
    {
      label: 'Basic and Applied Didactic',
      onClick: () => onNavigateDidacticReport(cohort.uuid),
      shouldOpenOnClick: false
    }
  ];
  const startDate = moment(cohort.startDate).format('YYYY');
  const endDate = moment(cohort.endDate).format('YYYY');
  return (
    <Cohort>
      <p>Cohort</p>
      <p>{`${startDate} - ${endDate}`}</p>
      <ActionButton actions={courseSectionActions} isRowOpen={false} />
    </Cohort>
  );
};

CohortPanel.propTypes = {
  cohort: PropTypes.object,
  onNavigateApplicantReports: PropTypes.func,
  onNavigateAssessmentGrid: PropTypes.func.isRequired,
  onNavigateCompetencyGrid: PropTypes.func,
  onNavigateOpportunityReport: PropTypes.func,
  onNavigateQuantitativeSkillsGrid: PropTypes.func,
  onNavigateSelfAssessmentReport: PropTypes.func.isRequired,
  onNavigateQualitativeAndDocumentsReport: PropTypes.func.isRequired,
  onNavigateDidacticReport: PropTypes.func.isRequired,
};

CohortPanel.defaultProps = {
  cohort: {},
  onNavigateApplicantReports: undefined,
  onNavigateCompetencyGrid: undefined,
  onNavigateOpportunityReport: undefined,
  onNavigateQuantitativeSkillsGrid: undefined
};

export default CohortPanel;
