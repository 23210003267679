import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { doGetAcademicYearsBySchool } from '../../../../redux/actions/academicYearActions';
import { academicYearsSelectorBySchool } from '../../../../redux/selectors/academicYearsSelectors';
import AcademicYear from './AcademicYear';

export default function AcademicYearContainer() {
  const dispatch = useDispatch();

  const selectedSchoolUuid = useSelector(
    state => state.userState.selectedSchoolUuid
  );

  const academicYears = useSelector(
    state => academicYearsSelectorBySchool(state),
    _.isEqual
  );

  useEffect(() => {
    dispatch(doGetAcademicYearsBySchool(selectedSchoolUuid));
  }, [dispatch, selectedSchoolUuid]);

  return <AcademicYear academicYears={academicYears} />;
}
