import _ from 'lodash';
import { generateNewId } from '../../../../helpers/factory';
import { generateObjectiveNumber } from '../../../../helpers/generators';

const encounterFormInitialState = {
  encounterTypeUuid: null,
  endDate: '',
  leadPercent: '',
  location: '',
  maxRevus: '0.0',
  notes: '',
  scheduleType: '',
  startDate: '',
  status: 'In Progress',
  title: 'Untitled Encounter',
  facultyLead: null,
  faculty_lead: null,
  encounter_faculty_assigned: [],
  linkedAssessmentUuid: '',
  objectives: [],
  objectivesRemove: [],
  existing: false,
  hasUnsavedChanges: false
};

const encounterFormReducer = (state, action) => {
  switch (action.type) {
    case 'encounterNew': {
      return {
        ...state,
        encounterTypeUuid: null,
        endDate: '',
        leadPercent: '',
        location: '',
        maxRevus: '0.0',
        notes: '',
        scheduleType: '',
        startDate: '',
        status: 'In Progress',
        title: 'Untitled Encounter',
        facultyLead: null,
        encounter_faculty_assigned: [],
        objectives: [],
        objectivesRemove: [],
        existing: false,
        hasUnsavedChanges: false
      };
    }
    case 'encounterExist': {
      const {
        uuid,
        encounterTypeUuid,
        title,
        location,
        scheduleType,
        maxRevus,
        startDate,
        endDate,
        leadPercent,
        notes,
        status,
        facultyLead,
        faculty_lead,
        encounter_faculty_assigned,
        linkedAssessmentUuid,
        objectives
      } = action.encounter;

      return {
        ...state,
        uuid,
        encounterTypeUuid,
        title,
        location,
        scheduleType,
        maxRevus,
        startDate,
        endDate,
        leadPercent,
        notes,
        status,
        facultyLead,
        faculty_lead,
        encounter_faculty_assigned,
        linkedAssessmentUuid,
        objectives,
        existing: true,
        hasUnsavedChanges: false
      };
    }
    case 'title': {
      return {
        ...state,
        title: action.title,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }

    case 'encounterTypeUuid': {
      let isLinkedEncounter = false;
      const { encounterType, encounterTypeUuid } = action.payload;

      if (encounterType.toLowerCase().includes('linked')) {
        isLinkedEncounter = true;
      }
      return isLinkedEncounter
        ? {
            ...state,
            isLinkedEncounter,
            encounterTypeUuid,
            endDate: null,
            leadPercent: null,
            location: null,
            maxRevus: null,
            notes: null,
            scheduleType: null,
            startDate: null,
            facultyLead: null,
            faculty_lead: null,
            encounter_faculty_assigned: [],
            objectives: [],
            objectivesRemove: [],
            linkedAssessmentUuid: '',
            hasUnsavedChanges: true,
            status: 'In Progress'
          }
        : {
            ...state,
            isLinkedEncounter,
            encounterTypeUuid,
            linkedAssessmentUuid: null,
            hasUnsavedChanges: true,
            status: 'In Progress'
          };
    }

    case 'endDate': {
      return {
        ...state,
        endDate: action.endDate,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }
    case 'leadPercent': {
      return {
        ...state,
        leadPercent: action.leadPercent,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }
    case 'location': {
      return {
        ...state,
        location: action.location,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }
    case 'maxRevus': {
      return {
        ...state,
        maxRevus: action.maxRevus,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }
    case 'notes': {
      return {
        ...state,
        notes: action.notes,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }
    case 'scheduleType': {
      return {
        ...state,
        scheduleType: action.scheduleType,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }
    case 'startDate': {
      return {
        ...state,
        startDate: action.startDate,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }
    case 'status': {
      return {
        ...state,
        status: action.status
      };
    }
    case 'facultyLead': {
      return {
        ...state,
        facultyLead: action.facultyLead,
        faculty_lead: null,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }
    case 'encounterFacultyAssigned': {
      return {
        ...state,
        encounter_faculty_assigned: action.encounterFacultyAssigned,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }

    case 'objectiveAdd': {
      const uuid = generateNewId();

      const add = {
        uuid,
        objectiveNumber: generateObjectiveNumber(state.objectives),
        loverb: '',
        learningObjective: '',
        revus: 0.0,
        microcompetency: '',
        isNew: true
      };

      const objectives = [...state.objectives, add];

      return {
        ...state,
        objectives,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }

    case 'learningObjective': {
      const { objectiveUuid, learningObjective } = action;

      const objectives = state.objectives.map(objective => {
        if (objective.uuid === objectiveUuid) {
          return { ...objective, learningObjective };
        } else {
          return { ...objective };
        }
      });

      return {
        ...state,
        objectives,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }

    case 'revus': {
      const { objectiveUuid, revus } = action;

      const objectives = state.objectives.map(objective => {
        if (objective.uuid === objectiveUuid) {
          return { ...objective, revus };
        } else {
          return { ...objective };
        }
      });

      return {
        ...state,
        objectives,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }

    case 'microcompetency': {
      const { objectiveUuid, microcompetency } = action;

      const objectives = state.objectives.map(objective => {
        if (objective.uuid === objectiveUuid) {
          return {
            ...objective,
            microcompetency: microcompetency.code,
            microcompetencyUuid: microcompetency.uuid,
            microcompetencyTitle: microcompetency.title
          };
        } else {
          return { ...objective };
        }
      });

      return {
        ...state,
        objectives,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }

    case 'microcompetencyRemove': {
      const { objectiveUuid } = action;

      const objectives = state.objectives.map(objective => {
        if (objective.uuid === objectiveUuid) {
          return {
            ...objective,
            microcompetency: null,
            microcompetencyUuid: null,
            microcompetencyTitle: null
          };
        } else {
          return { ...objective };
        }
      });

      return {
        ...state,
        objectives,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }

    case 'loverbUuid': {
      const { objectiveUuid, loverbUuid } = action;

      const objectives = state.objectives.map(objective => {
        if (objective.uuid === objectiveUuid) {
          return { ...objective, loverbUuid };
        } else {
          return { ...objective };
        }
      });

      return {
        ...state,
        objectives,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }

    case 'objectiveUp': {
      const { objectiveUuid } = action;

      const objectives = _.cloneDeep(state.objectives);

      const indexCurrent = _.findIndex(
        state.objectives,
        objective => objective.uuid === objectiveUuid
      );

      if (indexCurrent !== 0) {
        objectives[indexCurrent].objectiveNumber -= 1;
        objectives[indexCurrent - 1].objectiveNumber += 1;
      }

      return {
        ...state,
        objectives,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }

    case 'objectiveDown': {
      const { objectiveUuid } = action;

      const objectives = _.cloneDeep(state.objectives);

      const indexCurrent = _.findIndex(
        state.objectives,
        objective => objective.uuid === objectiveUuid
      );

      const indexLast = objectives.length - 1;

      if (indexCurrent !== indexLast) {
        objectives[indexCurrent].objectiveNumber += 1;
        objectives[indexCurrent + 1].objectiveNumber -= 1;
      }

      return {
        ...state,
        objectives,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }

    case 'objectiveRemove': {
      const { objectiveUuid } = action;

      const objectives = state.objectives.filter(
        objective => objective.uuid !== objectiveUuid
      );

      const remove = state.objectives.filter(
        objective => objective.uuid === objectiveUuid
      );

      return {
        ...state,
        objectives,
        objectivesRemove: [...state.objectivesRemove, remove],
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }

    case 'linkedAssessmentChange': {
      const { linkedAssessmentUuid } = action.payload;

      return {
        ...state,
        linkedAssessmentUuid,
        hasUnsavedChanges: true,
        status: 'In Progress'
      };
    }

    default:
      throw new Error();
  }
};

export { encounterFormInitialState, encounterFormReducer };
