import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_DEPARTMENT_SUCCESS,
  GET_COURSE_BY_STUDENT_SUCCESS,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENT_SUCCESS,
  GET_HIERARCHY_SUCCESS,
  GET_SCHOOL_DEPARTMENTS_SUCCESS,
  GET_USERS_BY_SEARCH_ROLES_SUCCESS,
  GET_USER_ACTIVE_SUCCESS,
  GET_USER_SUCCESS,
  POST_DEPARTMENT_SUCCESS,
  PUT_DEPARTMENT_SUCCESS
} from '../actions-type';

class Department extends Model {
  static reducer(action, Department, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_USER_ACTIVE_SUCCESS: {
        if (payload?.roles?.department_faculty?.length > 0) {
          const userDepartments = [...payload.roles.department_faculty];
          userDepartments.forEach(userDepartment => {
            const { department } = userDepartment;
            Department.upsert(department);
          });
        }

        if (payload?.roles?.department_staff?.length > 0) {
          const userDepartments = [...payload.roles.department_staff];
          userDepartments.forEach(userDepartment => {
            const { department } = userDepartment;
            Department.upsert(department);
          });
        }

        break;
      }
      case GET_DEPARTMENT_SUCCESS: {
        const department = { ...payload.department };
        Department.upsert(department);
        break;
      }
      case GET_DEPARTMENTS_SUCCESS: {
        const departments = [...payload.departments];
        departments.forEach(department => Department.upsert(department));
        break;
      }
      case GET_SCHOOL_DEPARTMENTS_SUCCESS: {
        const departments = [...payload.departments];
        departments.forEach(department => Department.upsert(department));
        break;
      }
      case GET_USER_SUCCESS: {
        const departments = [...payload.departments];
        departments.forEach(department => Department.upsert(department));
        break;
      }
      case POST_DEPARTMENT_SUCCESS: {
        const department = { ...payload.department };
        Department.upsert(department);
        break;
      }
      case PUT_DEPARTMENT_SUCCESS: {
        const department = { ...payload.department };
        Department.upsert(department);
        break;
      }
      case DELETE_DEPARTMENT_SUCCESS: {
        const department = { ...payload };
        Department.withId(department.departmentUuid).delete();
        break;
      }
      case GET_HIERARCHY_SUCCESS: {
        const departments = [...payload.departments];
        departments.forEach(department => Department.upsert(department));
        break;
      }
      case GET_COURSE_BY_STUDENT_SUCCESS: {
        const courses = [...payload.courses];
        courses.forEach(course => {
          const { course_master } = course;
          const { department } = course_master;
          Department.upsert(department);
        });
        break;
      }
      case GET_USERS_BY_SEARCH_ROLES_SUCCESS: {
        if (payload?.departments?.length > 0) {
          const departments = [...payload.departments];
          departments.forEach(department => Department.upsert(department));
        }
        break;
      }
      default:
        return session;
    }
  }
}

Department.fields = {
  uuid: attr(),
  name: attr(),
  code: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  schoolUuid: fk({
    to: 'School',
    as: 'school',
    relatedName: 'departments'
  })
};

Department.modelName = 'Department';
Department.options = {
  idAttribute: 'uuid'
};

export default Department;
