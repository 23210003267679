import {
  GET_COHORT_ASSESSMENT_GRID,
  GET_COHORT_ASSESSMENT_GRID_ERROR,
  GET_COHORT_ASSESSMENT_GRID_SUCCESS,
  SET_ASSESSMENT_GRID_ZOOM,
  SET_SHOW_ASSESSMENT_GRID_MODAL
} from "../actions-type/assessmentGridActionTypes";

export const INITIAL_STATE = {
  assessmentCollections: [],
  assessmentBlocks: [],
  students: [],
  scores: [],
  mappedBlocksToCollection: {},
  isLoading: false,
  showTableModal: false,
  zoom: 1,
};

const cohortAssessmentReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COHORT_ASSESSMENT_GRID_SUCCESS: {
      return {
        ...state,
        assessmentCollections: action.payload.cohortAssessmentCollections,
        assessmentBlocks: action.payload.assessmentBlocks,
        students: action.payload.students,
        scores: action.payload.scores,
        mappedBlocksToCollection: action.payload.mappedBlocksToCollection,
        isLoading: false,
      }
    }
    case GET_COHORT_ASSESSMENT_GRID_ERROR: {
      return INITIAL_STATE;
    }
    case GET_COHORT_ASSESSMENT_GRID: {
      return {
        ...INITIAL_STATE,
        isLoading: true,
        showTableModal: state.showTableModal,
      };
    }
    case SET_ASSESSMENT_GRID_ZOOM: {
      if (state.zoom !== action.payload.zoom) {
        return {
          ...state,
          zoom: action.payload.zoom,
        };
      }
      return state;
    }
    case SET_SHOW_ASSESSMENT_GRID_MODAL: {
      return {
        ...state,
        showTableModal: action.payload.showTableModal,
      };
    }
    default:
      return state;
  }
};

export default cohortAssessmentReportReducer;
