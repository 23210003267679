import {
  SET_SELECTED_DEPARTMENT_UUID_COURSE_ADD,
  SET_SELECTED_COURSE_MASTER_UUID_COURSE_ADD,
  SET_SELECTED_ACADEMIC_YEAR_UUID_COURSE_ADD,
  SET_SELECTED_TERM_UUID_COURSE_ADD
} from '../actions-type';

const doSetSelectedDepartmentCourseAdd = departmentUuid => ({
  type: SET_SELECTED_DEPARTMENT_UUID_COURSE_ADD,
  payload: {
    departmentUuid
  }
});

const doSetSelectedCourseMasterCourseAdd = courseMasterUuid => ({
  type: SET_SELECTED_COURSE_MASTER_UUID_COURSE_ADD,
  payload: {
    courseMasterUuid
  }
});

const doSetSelectedAcademicYearCourseAdd = academicYearUuid => ({
  type: SET_SELECTED_ACADEMIC_YEAR_UUID_COURSE_ADD,
  payload: {
    academicYearUuid
  }
});

const doSetSelectedTermCourseAdd = termUuid => ({
  type: SET_SELECTED_TERM_UUID_COURSE_ADD,
  payload: {
    termUuid
  }
});

export {
  doSetSelectedDepartmentCourseAdd,
  doSetSelectedCourseMasterCourseAdd,
  doSetSelectedAcademicYearCourseAdd,
  doSetSelectedTermCourseAdd
};
