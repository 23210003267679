import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';

import Tooltip from '@material-ui/core/Tooltip';
import {
  LockIcon,
  RemoveIcon,
  CheckIcon
} from '@xcomp/xcomp-design-library/dist/icons';
import LockOpenIcon from '@xcomp/xcomp-design-library/dist/icons/actions/LockOpenIcon';
import {
  getTotalNumberOfApprovedEncounters,
  getTotalNumberOfApprovedAssessments,
  getTotalNumberofEncounters,
  getTotalNumberofAssessments
} from '../../../../../helpers/parts';
import { ButtonOutline } from '../../../../Library/ButtonSC';
import PartStatusModal from './PartStatusModal';

const ButtonRowLayout = styled(({ margin, ...rest }) => <div {...rest} />)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: ${props => props.margin || 'initial'};
  position: relative;
  z-index: 300;
`;

const PublishButton = styled(({ marginLeft, ...rest }) => (
  <ButtonOutline {...rest} />
))`
  && {
    margin-left: ${props => props.marginLeft || '24px'};
  }
`;

const LockIconSC = styled(LockIcon)`
  font-size: 16px;
  margin-right: 8px;
`;

const UnlockIcon = styled(LockOpenIcon)`
  font-size: 16px;
  margin-right: 8px;
`;

const RemoveIconSC = styled(RemoveIcon)`
  font-size: 16px;
  margin-right: 8px;
`;

const PublishIcon = styled(CheckIcon)`
  font-size: 16px;
  margin-right: 8px;
`;

const StatusActionButtons = ({
  part,
  handleStatusApprove,
  handleStatusUnapprove,
  handleStatusPublish,
  handleStatusUnpublish,
  isApproved,
  isPublished
}) => {
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const themeContext = useContext(ThemeContext);
  const handleStatusModalOpen = () => setIsStatusModalOpen(true);
  const handleStatusModalClose = () => setIsStatusModalOpen(false);

  const handlePublishModalOpen = () => setIsPublishModalOpen(true);
  const handlePublishModalClose = () => setIsPublishModalOpen(false);

  const totalNumberOfApprovedEncounters =
    getTotalNumberOfApprovedEncounters(part);
  const totalNumberOfApprovedAssessments =
    getTotalNumberOfApprovedAssessments(part);

  const totalNumberofEncounters = getTotalNumberofEncounters(part);
  const totalNumberofAssessments = getTotalNumberofAssessments(part);

  const isPartComplete =
    totalNumberOfApprovedEncounters === totalNumberofEncounters &&
    totalNumberOfApprovedAssessments === totalNumberofAssessments;

  const statusHeading = isApproved
    ? 'Unappaprove and Unlock Part Syllabus'
    : 'Approve and Lock Part Syllabus';

  const statusMessage = isApproved
    ? 'This syllabus has already been approved and has potentially been released to students. Are you sure you want to unlock this part syllabus?'
    : 'Are you sure you want to approve this part syllabus? Once approved, you will not be able to edit encounter and assessments without unapproving the part syllabus first.';

  const publishModalHeading = isPublished
    ? 'Unpublish and Unlock Part Syllabus'
    : 'Publish Part Syllabus';

  const publishModalMessage = isPublished
    ? 'This syllabus has already been published and has been released to students. Are you sure you want to unlock this part syllabus? Doing so will remove student access to syllabus until it is reapproved and republished.'
    : 'Are you sure you want to publish this part syllabus? Once published, students will have access to the syllabus and you will not be able to edit encounter and assessments without unapproving the part syllabus first.';

  const handleStatusSubmit = isApproved
    ? handleStatusUnapprove
    : handleStatusApprove;

  const handlePublishSubmit = isPublished
    ? handleStatusUnpublish
    : handleStatusPublish;

  const statusSubmitButtonText = isApproved
    ? 'Unlock Part'
    : 'Approve and Lock Part';

  const publishSubmitButtonText = isPublished
    ? 'Unpublish Part'
    : 'Publish Part';

  const statusSubmitButtonIcon = isApproved ? (
    <UnlockIcon color={themeContext.colors.primary} />
  ) : (
    <LockIconSC />
  );

  const disabledPublishButton = (
    <Tooltip
      title="This part must be approved and locked before it can be published."
      placement="top"
    >
      <div>
        <PublishButton onClick={undefined} disabled>
          <PublishIcon />
          Publish Part
        </PublishButton>
      </div>
    </Tooltip>
  );

  return (
    <>
      <ButtonRowLayout margin="0 0 24px">
        {!part || (!isPartComplete && !isApproved) ? (
          <>
            <Tooltip
              title="All encounters and assessments need to be approved before this part can be approved and locked."
              placement="top"
            >
              <div>
                <ButtonOutline onClick={handleStatusModalOpen} disabled>
                  <LockIconSC />
                  Approve and Lock Part
                </ButtonOutline>
              </div>
            </Tooltip>
            {disabledPublishButton}
          </>
        ) : (
          <>
            {!isPublished && (
              <ButtonOutline onClick={handleStatusModalOpen}>
                {statusSubmitButtonIcon}
                {statusSubmitButtonText}
              </ButtonOutline>
            )}
            {!isApproved && !isPublished && disabledPublishButton}
          </>
        )}
        {isApproved && (
          <PublishButton onClick={handlePublishModalOpen}>
            <PublishIcon />
            Publish Part
          </PublishButton>
        )}
        {isPublished && (
          <PublishButton onClick={handlePublishModalOpen} marginLeft="0">
            <RemoveIconSC />
            Unpublish Part
          </PublishButton>
        )}
      </ButtonRowLayout>
      <PartStatusModal
        isModalOpen={isStatusModalOpen}
        handleModalClose={handleStatusModalClose}
        handleSubmit={handleStatusSubmit}
        heading={statusHeading}
        message={statusMessage}
        buttonText={statusSubmitButtonText}
      />
      <PartStatusModal
        isModalOpen={isPublishModalOpen}
        handleModalClose={handlePublishModalClose}
        handleSubmit={handlePublishSubmit}
        heading={publishModalHeading}
        message={publishModalMessage}
        buttonText={publishSubmitButtonText}
      />
    </>
  );
};

StatusActionButtons.propTypes = {
  part: PropTypes.object,
  isApproved: PropTypes.bool,
  isPublished: PropTypes.bool,
  handleStatusApprove: PropTypes.func,
  handleStatusUnapprove: PropTypes.func,
  handleStatusPublish: PropTypes.func,
  handleStatusUnpublish: PropTypes.func
};
StatusActionButtons.defaultProps = {
  part: {},
  isApproved: false,
  isPublished: false,
  handleStatusApprove: undefined,
  handleStatusUnapprove: undefined,
  handleStatusPublish: undefined,
  handleStatusUnpublish: undefined
};

export default StatusActionButtons;
