import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import StatusCommitModal from './StatusCommitModal';
import { doStatusCommitModalReset } from '../../../../../redux/actions/assessmentActions';

const StatusCommitModalContainer = ({
  statusCommit,
  justificationMessage,
  handleJustificationChange,
  isModalOpen,
  handleModalClose,
  handleModalSuccess,
  handleSubmit
}) => {
  const dispatch = useDispatch();

  const {
    isLoading,
    successfullyCommitted,
    successfullyUncommitted,
    invalid,
    errorMessage,
    missingScoresError
  } = useSelector(state => state.assessmentStatusState, shallowEqual);

  useEffect(() => {
    if (!isModalOpen) {
      dispatch(doStatusCommitModalReset());
    }
  }, [dispatch, isModalOpen]);

  return (
    <StatusCommitModal
      statusCommit={statusCommit}
      isModalOpen={isModalOpen}
      justificationMessage={justificationMessage}
      isLoading={isLoading}
      invalid={invalid}
      missingScoresError={missingScoresError}
      errorMessage={errorMessage}
      successfullyCommitted={successfullyCommitted}
      successfullyUncommitted={successfullyUncommitted}
      handleJustificationChange={handleJustificationChange}
      handleModalClose={handleModalClose}
      handleModalSuccess={handleModalSuccess}
      handleSubmit={handleSubmit}
    />
  );
};

StatusCommitModalContainer.propTypes = {
  statusCommit: PropTypes.string,
  justificationMessage: PropTypes.string,
  isModalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleJustificationChange: PropTypes.func,
  handleModalSuccess: PropTypes.func,
  handleSubmit: PropTypes.func
};
StatusCommitModalContainer.defaultProps = {
  statusCommit: 'Uncommitted',
  justificationMessage: '',
  isModalOpen: false,
  handleModalClose: undefined,
  handleJustificationChange: undefined,
  handleModalSuccess: undefined,
  handleSubmit: undefined
};

export default StatusCommitModalContainer;
