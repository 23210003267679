import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { departmentsBySchoolStateSelector } from '../../../redux/selectors/departmentsSelectors';
import { doGetSchoolDepartments } from '../../../redux/actions/departmentActions';
import { doPostCourseMaster } from '../../../redux/actions/courseMasterActions';

import {
  validateCourseTitle,
  validateCourseHours,
  validateCourseDescription,
  validateCourseAliases,
  validateCourseAliasesCourseMaster
} from '../../../helpers/validation/validateCourseMaster';

import { validateSelectField } from '../../../helpers/validation/validateGeneric';

import CourseMasterAdd from './CourseMasterAdd';

export default function CourseMasterAddContainer() {
  const [departmentUuid, setDepartmentUuid] = useState('');
  const [courseNumber, setCourseNumber] = useState('');
  const [title, setTitle] = useState('');
  const [hours, setHours] = useState(0);
  const [hasErrors, setHasFormErrors] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [alias, setAlias] = useState([]);
  const [aliasRemoval, setAliasRemoval] = useState([]);

  const dispatch = useDispatch();

  const schoolDepartments = useSelector(
    state => departmentsBySchoolStateSelector(state),
    _.isEqual
  );
  const selectedSchoolUuid = useSelector(
    state => state.userState.selectedSchoolUuid
  );

  const { course: courseDescription } = useSelector(
    state => state.descriptionState
  );

  useEffect(() => {
    dispatch(doGetSchoolDepartments(selectedSchoolUuid));
  }, [dispatch, selectedSchoolUuid]);

  const handleChange = useCallback(event => {
    const { name, value } = event.target;

    switch (name) {
      case 'departmentUuid':
        setDepartmentUuid(value);
        break;
      case 'courseNumber':
        setCourseNumber(value);
        break;
      case 'title':
        setTitle(value);
        break;
      case 'hours':
        setHours(value);
        break;
      default:
        break;
    }
  }, []);

  const handleAddCourseAlias = useCallback(() => {
    const addAlias = {
      indentifier: uuidv4().toString(),
      courseMasterAliasUuid: '',
      departmentUuid: ''
    };

    setAlias([...alias, addAlias]);
  }, [alias]);

  const handleCourseAliasUuidChange = useCallback(
    (indentifier, event) => {
      const { value } = event.target;

      const newState = alias.map(a => {
        if (a.indentifier === indentifier) {
          return { ...a, courseMasterAliasUuid: value };
        } else {
          return a;
        }
      });

      setAlias(newState);
    },
    [alias]
  );

  const handleCourseAliasChange = useCallback(
    (indentifier, event) => {
      const { value } = event.target;

      const newState = alias.map(department => {
        if (department.indentifier === indentifier) {
          return { ...department, departmentUuid: value };
        } else {
          return department;
        }
      });

      setAlias(newState);
    },
    [alias]
  );

  const handleRemoveCourseAlias = useCallback(
    indentifier => {
      const aliasToDelete = _.find(
        alias,
        department => department.indentifier === indentifier && department.uuid
      );

      if (aliasToDelete) {
        setAliasRemoval([...aliasRemoval, aliasToDelete]);
      }

      const newState = _.filter(
        alias,
        record => record.indentifier !== indentifier
      );

      setAlias(newState);
    },
    [alias, aliasRemoval]
  );

  const validateForm = useCallback(() => {
    let containsErrors = false;

    const departmentError = validateSelectField(departmentUuid);
    const titleError = validateCourseTitle(title);
    const hoursError = validateCourseHours(hours);
    const descriptionError = validateCourseDescription(courseDescription);
    const aliasError = validateCourseAliases(alias);
    const courseMasterAliasUuidError = validateCourseAliasesCourseMaster(alias);

    if (
      departmentError.invalid ||
      titleError.invalid ||
      hoursError.invalid ||
      descriptionError.invalid ||
      aliasError.invalid ||
      courseMasterAliasUuidError.invalid
    ) {
      containsErrors = true;
    }

    return containsErrors;
  }, [
    alias,
    courseDescription,
    departmentUuid,
    hours,
    title
  ]);

  const handleCreateCourse = useCallback(() => {
    const hasValidationErrors = validateForm();

    if (hasValidationErrors) {
      setHasFormErrors(true);
    } else {
      const payload = {
        departmentUuid,
        courseNumber,
        title,
        hours,
        description: courseDescription,
        alias
      };

      setRedirect(true);
      dispatch(doPostCourseMaster(payload));
    }
  }, [
    alias,
    courseDescription,
    courseNumber,
    departmentUuid,
    dispatch,
    hours,
    title,
    validateForm
  ]);

  return (
    <CourseMasterAdd
      selectedDepartment={departmentUuid}
      courseNumber={courseNumber}
      title={title}
      hours={hours}
      alias={alias}
      redirect={redirect}
      schoolDepartments={schoolDepartments}
      hasErrors={hasErrors}
      handleChange={event => handleChange(event)}
      handleCreateCourse={() => handleCreateCourse()}
      handleAddCourseAlias={() => handleAddCourseAlias()}
      handleCourseAliasUuidChange={(indentifier, event) =>
        handleCourseAliasUuidChange(indentifier, event)
      }
      handleCourseAliasChange={(indentifier, event) =>
        handleCourseAliasChange(indentifier, event)
      }
      handleRemoveCourseAlias={indentifier =>
        handleRemoveCourseAlias(indentifier)
      }
    />
  );
}
