import _ from 'lodash';

const validateGradeCollectionTitle = title => {
  if (!title || title.trim() === '' || title === 0) {
    return {
      invalid: true,
      message: 'Required'
    };
  } else if (_.size(title) > 100) {
    return {
      invalid: true,
      message: 'Must be less than 100 characters'
    };
  } else if (title.toLowerCase() === 'untitled') {
    return {
      invalid: true,
      message: 'Required'
    };
  } else {
    return {
      invalid: false
    };
  }
};

const validateGradeCollectionWeight = weight => {
  const weightNumber = weight ? Number(weight) : false;
  if (!weightNumber || weightNumber === 0) {
    return {
      invalid: true,
      message: 'Required'
    };
  } else if (weightNumber < 1 || weightNumber > 100) {
    return {
      invalid: true,
      message: 'Must be between 1 and 100 percent'
    };
  } else {
    return {
      invalid: false
    };
  }
};

export { validateGradeCollectionTitle, validateGradeCollectionWeight };
