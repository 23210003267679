const initialCohortAssessmentState = {
  uuid: '',
  title: '',
  isNew: false,
  selectedAssessmentMasterUuid: '',
  status: 'In Progress',
  rubricCheck: true,
  responsibleFaculty: '',
  assessmentManager: '',
  haveFetchedProgramAssessmentMasters: false,
  haveFetchedCohortAssessments: false,
  showValidationErrors: false
};

const cohortAssessmentReducer = (state, action) => {
  switch (action.type) {
    case 'setExisting': {
      const {
        uuid,
        title,
        programAssessmentMasterUuid,
        status,
        rubricCheck,
        responsibleFaculty,
        assessmentManager
      } = action.payload;

      return {
        ...state,
        uuid,
        title,
        selectedAssessmentMasterUuid: programAssessmentMasterUuid,
        status,
        rubricCheck,
        responsibleFaculty,
        assessmentManager
      };
    }
    case 'setSelectedAssessmentMasterUuid': {
      const { programAssessmentMasterUuid } = action.payload;

      return {
        ...state,
        selectedAssessmentMasterUuid: programAssessmentMasterUuid
      };
    }
    case 'setTitle':
      return { ...state, title: action.payload.title };
    case 'setRubricCheck':
      return { ...state, rubricCheck: action.payload }
    case 'setResponsibleFaculty':
      return {
        ...state,
        responsibleFaculty: action.payload.responsibleFaculty
      };
    case 'setAssessmentManager':
      return { ...state, assessmentManager: action.payload.assessmentManager };
    case 'setShowValidationErrors':
      return {
        ...state,
        showValidationErrors: action.payload.showValidationErrors
      };
    case 'setHaveFetchedProgramAssessmentMasters':
      return {
        ...state,
        haveFetchedProgramAssessmentMasters: true
      };
    case 'setHaveFetchedCohortAssessments':
      return {
        ...state,
        haveFetchedCohortAssessments: true
      };
    case 'clearForm': {
      const newState = {
        ...initialCohortAssessmentState
      };

      return newState;
    }
    default:
      throw new Error();
  }
};

const setTitle = title => ({ type: 'setTitle', payload: { title } });

const setShowValidationErrors = showValidationErrors => ({
  type: 'setShowValidationErrors',
  payload: { showValidationErrors }
});

const setHaveFetchedCohorts = () => ({
  type: 'setHaveFetchedCohorts'
});

const setHaveFetchedCohortAssessments = () => ({
  type: 'setHaveFetchedCohortAssessments'
});

export {
  initialCohortAssessmentState,
  cohortAssessmentReducer,
  setTitle,
  setShowValidationErrors,
  setHaveFetchedCohorts,
  setHaveFetchedCohortAssessments
};
