import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '@xcomp/xcomp-design-library/dist/icons';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Modal from '../../../../Library/Modal/Modal';
import FormSelectError from '../../../../Library/FormSelectError';
import {
  validateSelectField,
  validateInputString
} from '../../../../../helpers/validation/validateGeneric';
import FormField from '../../../../Library/FormField';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 700px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function DegreeLevelIndexEdit({
  percentage,
  openDegreeEdit,
  sourceDegreeLevelUuid,
  handleDegreeLevel,
  handlePercentage,
  degreeLevelOptions,
  hasErrors,
  handleDegreeEditClose,
  handleEditDegreeLevelIndex
}) {
  return (
    <Modal open={openDegreeEdit} onClose={handleDegreeEditClose}>
      <ModalBody>
        <ModalHeader>
          <Title>Edit Degree Level Index</Title>
          <CloseModal>
            <IconButton onClick={handleDegreeEditClose}>
              <CloseIcon />
            </IconButton>
          </CloseModal>
        </ModalHeader>
        <Grid container spacing={4}>
          <Grid item sm={9} xs={12}>
            <FormSelectError
              name="sourceDegreeLevelUuid"
              value={sourceDegreeLevelUuid}
              label="Education Level*"
              handleChange={handleDegreeLevel}
              options={degreeLevelOptions}
              hasErrors={hasErrors}
              handleValidation={validateSelectField}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormField
              name="percentage"
              label="Percentage*"
              type="number"
              onChange={handlePercentage}
              hasErrors={hasErrors}
              value={percentage}
              handleValidate={validateInputString}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </Grid>
        </Grid>
        <Actions>
          <ButtonPrimary onClick={handleEditDegreeLevelIndex}>
            Save
          </ButtonPrimary>
        </Actions>
      </ModalBody>
    </Modal>
  );
}

DegreeLevelIndexEdit.propTypes = {
  percentage: PropTypes.string,
  openDegreeEdit: PropTypes.bool,
  hasErrors: PropTypes.bool,
  sourceDegreeLevelUuid: PropTypes.string,
  degreeLevelOptions: PropTypes.array,
  handleDegreeLevel: PropTypes.func,
  handleDegreeEditClose: PropTypes.func,
  handleEditDegreeLevelIndex: PropTypes.func,
  handlePercentage: PropTypes.func
};

DegreeLevelIndexEdit.defaultProps = {
  percentage: undefined,
  openDegreeEdit: false,
  degreeLevelOptions: [],
  sourceDegreeLevelUuid: undefined,
  handleDegreeLevel: undefined,
  hasErrors: false,
  handleDegreeEditClose: undefined,
  handleEditDegreeLevelIndex: undefined,
  handlePercentage: undefined
};
