import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { FormLabel, FormHelperText } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const WrapperSC = styled(({ disabled, locked, ...rest }) => <div {...rest} />)`
  && {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    font-family: ${props => props.theme.fonts.primary};
    & > div {
      width: 100%;
    }

    label.Mui-focused {
      color: ${props => props.theme.fontColors.dark};
    }

    ${'' /* &:hover,
    &:active,
    &:focus {
      label {
        color: rgba(0, 0, 0, 0.87);
      }
    } */}
    .react-datepicker__input-container,
    .react-datepicker-wrapper {
      width: 100%;
    }
    .react-datepicker__time-container {
      width: 90px;
    }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 100px;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box.react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box,
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box {
      width: 100%;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list {
      padding-right: 0;
      overflow-y: scroll;
    }

    input {
      width: 100%;
      font-size: 1rem;
      padding: 17px 0px 17px 10px;
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
      border-color: rgba(0, 0, 0, 0.23);

      &:hover:not([disabled]) {
        border: 1px solid ${props => props.theme.colors.grey[300]};
        outline: 0;
      }

      &:active,
      &:focus {
        border: ${props =>
          props.disabled
            ? `1px solid ${props => props.theme.colors.grey[300]}`
            : `2px solid ${props => props.theme.colors.primary}`};
        outline: 0;
      }
    }

    input:disabled {
      color: ${props => props.theme.colors.grey[300]};
    }

    ul {
      padding: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    .react-datepicker__time-list {
      padding-right: 0;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item {
      width: 100%;
      border-right: 0;
      overflow-y: hidden;
      overflow-x: hidden;
      padding: 8px 7px;
      padding-right: 27px; /* Increase/decrease this value for cross-browser compatibility */
      box-sizing: border-box; /* So the width will be 100% + 17px */
    }

    .react-datepicker__month-container {
      border-right: 1px solid ${props => props.theme.colors.grey[400]};
    }

    .react-datepicker-popper {
      z-index: 2;
    }

    .react-datepicker__time-container {
      border-left: 0;
    }

    &.error {
      label {
        color: ${props => props.theme.colors.status.error};
        padding-left: 5px;
      }
      input {
        border-bottom: 2px solid ${props => props.theme.colors.status.error};
        margin-bottom: 0;
        &:active,
        &:hover {
          border: 1px solid ${props => props.theme.colors.status.error};
        }

        &:focus {
          border: 2px solid ${props => props.theme.colors.status.error};
        }
      }
    }

    ${props =>
      props.disabled &&
      css`
        label,
        label.Mui-focused {
          color: ${props => props.theme.colors.grey[300]};
        }
        &:hover,
        &:active,
        &:focus {
          label {
            color: ${props => props.theme.colors.grey[300]};
          }
        }

        input {
          border-color: ${props => props.theme.colors.grey[300]};

          &:hover:not([disabled]) {
            border: 1px solid ${props => props.theme.colors.grey[300]};
            outline: 0;
          }

          &:active,
          &:focus {
            border: 1px solid ${props => props.theme.colors.grey[300]};
            outline: 0;
          }
        }
      `}

    ${props =>
      props.locked &&
      css`
        label,
        label.Mui-focused {
          color: ${props => props.theme.colors.grey[600]};
        }
        &:hover,
        &:active,
        &:focus {
          label {
            color: ${props => props.theme.colors.grey[600]};
          }
        }

        input {
          border-color: ${props => props.theme.colors.grey[600]};

          &:hover:not([disabled]) {
            border: 1px solid ${props => props.theme.colors.grey[600]};
            outline: 0;
          }

          &:active,
          &:focus {
            border: 1px solid ${props => props.theme.colors.grey[600]};
            outline: 0;
          }
        }

        input.datetime-picker {
          color: ${props => props.theme.fontColors.darker};
        }
      `}
  }
`;

const FormLabelSC = styled(FormLabel)`
  && {
    width: 100%;
    font-size: 16px;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
  }
`;

const CustomInput = styled.input`
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.87);
  }
`;

const DateTime = ({
  value,
  name,
  dataTestId,
  disabled,
  className,
  label,
  handleChange,
  error,
  helperText,
  locked,
  ...rest
}) => {
  const handleDateChange = date => {
    handleChange(name, date);
  };

  const appromixateTimeZone = moment.tz.guess();

  const dateTimeZone = value
    ? momentTz(value).tz(appromixateTimeZone).toDate()
    : null;

  return (
    <WrapperSC
      disabled={disabled}
      locked={locked}
      className={`${className} ${error && 'error'}`}
    >
      {label && <FormLabelSC>{label}</FormLabelSC>}
      <DatePicker
        data-testid={dataTestId}
        className="datetime-picker"
        selected={dateTimeZone}
        onChange={handleDateChange}
        showTimeSelect
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        timeFormat="hh:mm"
        timeIntervals={5}
        dateFormat="MMMM d, yyyy h:mm aa"
        timeCaption="Time"
        customInput={<CustomInput data-testid={dataTestId} />}
        disabled={disabled || locked}
        {...rest}
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </WrapperSC>
  );
};

DateTime.propTypes = {
  dataTestId: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  helperText: PropTypes.string
};

DateTime.defaultProps = {
  dataTestId: '',
  value: null,
  name: '',
  className: '',
  label: '',
  handleChange: undefined,
  disabled: false,
  locked: false,
  error: false,
  helperText: ''
};

export default DateTime;
