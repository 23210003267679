import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LetterGrades from './LetterGrades';
import DeleteModal from '../../../Library/Modal/DeleteModal/DeleteModal';
import { letterGradesBySchoolSelector } from '../../../../redux/selectors/letterGradeSelectors';

const {
  doGetLetterGradesBySchool,
  doDeleteLetterGrade
} = require('../../../../redux/actions/letterGradeActions');

class LetterGradesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      letterGrades: [],
      letterGradeAdd: false,
      modalOpen: false,
      letterGradeDelete: ''
    };

    this._loadLetterGrades = this._loadLetterGrades.bind(this);
    this.handleAddMode = this.handleAddMode.bind(this);
    this.handleAddClose = this.handleAddClose.bind(this);
    this.handleEditMode = this.handleEditMode.bind(this);
    this.handleShowMode = this.handleShowMode.bind(this);
    this.handleDeleteModalOpen = this.handleDeleteModalOpen.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    const {
      onGetLetterGradesBySchool,
      selectedSchoolUuid,
      letterGrades
    } = this.props;

    onGetLetterGradesBySchool(selectedSchoolUuid);

    if (letterGrades.length > 0) {
      this._loadLetterGrades(letterGrades);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.letterGrades !== prevProps.letterGrades) {
      this._loadLetterGrades(this.props.letterGrades);
    }
  }

  handleAddMode() {
    this.setState({ letterGradeAdd: true });
  }

  handleAddClose() {
    this.setState({ letterGradeAdd: false });
  }

  handleEditMode(letterGradeUuid) {
    this.setState(prevState => {
      const newState = prevState.letterGrades.map(letterGrade => {
        if (letterGrade.uuid === letterGradeUuid) {
          const changeEditMode = { ...letterGrade, mode: 'edit' };
          return changeEditMode;
        } else {
          return letterGrade;
        }
      });

      return {
        letterGrades: newState
      };
    });
  }

  handleShowMode(letterGradeUuid) {
    this.setState(prevState => {
      const newState = prevState.letterGrades.map(letterGrade => {
        if (letterGrade.uuid === letterGradeUuid) {
          const changeEditMode = { ...letterGrade, mode: 'show' };
          return changeEditMode;
        } else {
          return letterGrade;
        }
      });

      return {
        letterGrades: newState
      };
    });
  }

  handleDeleteModalOpen(selectedLetterGrade) {
    this.setState({
      modalOpen: true,
      letterGradeDelete: selectedLetterGrade
    });
  }

  handleDeleteModalClose() {
    this.setState({
      modalOpen: false
    });
  }

  handleDelete() {
    this.props.onDeleteLetterGrade(this.state.letterGradeDelete);

    this.setState({
      modalOpen: false
    });
  }

  _loadLetterGrades(letterGrades) {
    const letterGradesWithState = letterGrades.map(letterGrade => {
      const addStatus = { ...letterGrade, mode: 'show' };
      return addStatus;
    });

    this.setState({ letterGrades: letterGradesWithState });
  }

  render() {
    const { letterGrades, letterGradeAdd, modalOpen } = this.state;
    const { selectedSchoolUuid } = this.props;

    return (
      <div>
        <LetterGrades
          letterGrades={letterGrades}
          letterGradeAdd={letterGradeAdd}
          selectedSchoolUuid={selectedSchoolUuid}
          handleAddMode={this.handleAddMode}
          handleAddClose={this.handleAddClose}
          handleEditMode={this.handleEditMode}
          handleShowMode={this.handleShowMode}
          handleDeleteModalOpen={selectedLetterGrade =>
            this.handleDeleteModalOpen(selectedLetterGrade)
          }
        />
        <DeleteModal
          modalOpen={modalOpen}
          type="Letter Grade"
          handleDelete={() => this.handleDelete()}
          handleModalClose={() => this.handleDeleteModalClose()}
        />
      </div>
    );
  }
}

LetterGradesContainer.propTypes = {
  selectedSchoolUuid: PropTypes.string,
  letterGrades: PropTypes.array,
  onGetLetterGradesBySchool: PropTypes.func,
  onDeleteLetterGrade: PropTypes.func
};
LetterGradesContainer.defaultProps = {
  selectedSchoolUuid: '',
  letterGrades: [],
  onGetLetterGradesBySchool: undefined,
  onDeleteLetterGrade: undefined
};

const mapStateToProps = state => {
  return {
    selectedSchoolUuid: state.userState.selectedSchoolUuid,
    letterGrades: letterGradesBySchoolSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onGetLetterGradesBySchool: schoolUuid =>
    dispatch(doGetLetterGradesBySchool(schoolUuid)),
  onDeleteLetterGrade: letterGradeUuid =>
    dispatch(doDeleteLetterGrade(letterGradeUuid))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LetterGradesContainer);
