import { Model, attr } from 'redux-orm';
import { GET_ASSESSMENT_GRADES_SUCCESS } from '../actions-type';

class GradeSummary extends Model {
  static reducer(action, GradeSummary, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_ASSESSMENT_GRADES_SUCCESS: {
        const { gradeSummary } = payload;
        GradeSummary.upsert(gradeSummary);
        break;
      }
      default:
        return session;
    }
  }
}

GradeSummary.fields = {
  assessmentUuid: attr(),
  gradingTechnique: attr(),
  highScore: attr(),
  lowScore: attr(),
  averageScore: attr()
};

GradeSummary.modelName = 'GradeSummary';

GradeSummary.options = {
  idAttribute: 'assessmentUuid'
};

export default GradeSummary;
