import {
  CLEAR_APPLICANT_COGNITIVE_REPORT,
  PUT_APPLICANT_COGNITIVE_REPORT,
  PUT_APPLICANT_COGNITIVE_REPORT_ERROR,
  PUT_APPLICANT_COGNITIVE_REPORT_SUCCESS
} from '../actions-type';

export const INITIAL_STATE = {
  cognitiveReport: [],
  loadingCognitiveReport: false,
  reportHeaders: [],
  reportSummary: {},
  reportSummaryHeaders: [],
  reportType: ''
};

const applicantCognitiveReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PUT_APPLICANT_COGNITIVE_REPORT_SUCCESS: {
      const {
        cognitiveReport,
        reportHeaders,
        reportSummary,
        reportSummaryHeaders,
        reportType
      } = action.payload;
      return {
        ...state,
        cognitiveReport,
        loadingCognitiveReport: false,
        reportHeaders,
        reportSummary,
        reportSummaryHeaders,
        reportType
      };
    }
    case PUT_APPLICANT_COGNITIVE_REPORT:
      return { ...state, loadingCognitiveReport: true };
    case PUT_APPLICANT_COGNITIVE_REPORT_ERROR:
      return { ...state, loadingCognitiveReport: false };
    case CLEAR_APPLICANT_COGNITIVE_REPORT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default applicantCognitiveReportReducer;
