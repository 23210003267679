import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '@material-ui/core';
import { useGesture } from '@use-gesture/react';
import CompetencyGridSummaryDataPanel from './CompetencyGridSummaryDataPanel';
import GridModalHeader from './GridModalHeader';
import { doSetTableZoom } from '../../../redux/actions/competencyGridActions';

const DisabledCompetencyGridSummaryDataPanel = styled(
  CompetencyGridSummaryDataPanel
)`
  margin-top: 48px;
`;

const CompetencyGridSummaryData = ({
  tableRef,
  competencyCollections,
  activeCompetencyGridMap,
  delayedCompetencyGridMap,
  activeParticipationMaps,
  delayedParticipationMaps,
  competencyDeviationsMap,
  hasNoScores,
  students,
  delayedStudents
}) => {
  const [showCohort, setShowCohort] = useState(true);
  const [showScores, setShowScores] = useState(true);
  const [showTotals, setShowTotals] = useState(true);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [filteredCompetencies, setFilteredCompetencies] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldAnonymizeReport, setShouldAnonymizeReport] = useState(true);

  const showTableModal = useSelector(
    state => state.cohortCompetencyReportState.showTableModal
  );
  const isResultLoaded = useSelector(
    state => state.cohortCompetencyReportState.isResultLoaded
  );

  const dispatch = useDispatch();

  const bind = useGesture(
    {
      onWheel: state => {
        const modalEl = document.getElementById('competency-modal-content');
        if (state.ctrlKey && typeof state.direction[1] && modalEl) {
          const zoom = window
            .getComputedStyle(modalEl)
            .getPropertyValue('zoom');
          const newZoom = parseFloat(zoom, 10) + -state.direction[1] / 100;

          modalEl.style.zoom = newZoom > 1.2 || newZoom < 0.8 ? zoom : newZoom;
        }
      },
      onWheelEnd: () => {
        const newZoom = parseFloat(
          window
            .getComputedStyle(
              document.getElementById('competency-modal-content')
            )
            .getPropertyValue('zoom'),
          10
        );
        dispatch(doSetTableZoom(newZoom));
      },
      onPinch: state => {
        const modalEl = document.getElementById('competency-modal-content');
        if (state.ctrlKey && typeof state.direction[1] && modalEl) {
          const zoom = window
            .getComputedStyle(modalEl)
            .getPropertyValue('zoom');
          const newZoom = parseFloat(zoom, 10) + -state.direction[1] / 100;

          modalEl.style.zoom = newZoom > 1.2 || newZoom < 0.8 ? zoom : newZoom;
        }
      },
      onPinchEnd: () => {
        const newZoom = parseFloat(
          window
            .getComputedStyle(
              document.getElementById('competency-modal-content')
            )
            .getPropertyValue('zoom'),
          10
        );
        dispatch(doSetTableZoom(newZoom));
      }
    },
    {
      pinch: {
        eventOptions: {
          passive: false
        }
      }
    }
  );

  useEffect(() => {
    const preventDefault = e => {
      const event = e || window.event;
      if (event.preventDefault && event.ctrlKey) {
        event.preventDefault();
      }
    };
    document.addEventListener('wheel', preventDefault, {
      passive: false
    });
    return () => document.removeEventListener('wheel', preventDefault, false);
  }, []);

  const filteredStudentsObject = useMemo(() => {
    const filteredStudentUuids = filteredStudents.map(fs => fs.uuid);
    let isStudentFilterActive = false;

    const filteredStudentsForTable = (
      showCohort ? students : delayedStudents
    ).filter(s => {
      const isDisplayed = filteredStudentUuids.includes(s.userUuid);
      if (!isDisplayed && !isStudentFilterActive) {
        isStudentFilterActive = true;
      }
      return isDisplayed;
    });

    return {
      filteredStudentsForTable,
      isStudentFilterActive
    };
  }, [filteredStudents, students, delayedStudents, showCohort]);

  const filteredCompetenciesObject = useMemo(() => {
    const filteredCompetencyUuids = filteredCompetencies.map(fc => fc.uuid);
    let isCompetencyFilterActive = false;

    const filteredCompetenciesForTable = isExpanded
      ? competencyCollections.map(col => ({
          ...col,
          competencies: col.competencies.filter(com => {
            const isDisplayed = filteredCompetencyUuids.includes(com.uuid);
            if (!isDisplayed && !isCompetencyFilterActive) {
              isCompetencyFilterActive = true;
            }
            return isDisplayed;
          })
        }))
      : competencyCollections.filter(c => {
          const isDisplayed = filteredCompetencyUuids.includes(c.uuid);
          if (!isDisplayed && !isCompetencyFilterActive) {
            isCompetencyFilterActive = true;
          }
          return isDisplayed;
        });

    return {
      filteredCompetenciesForTable,
      isCompetencyFilterActive
    };
  }, [isExpanded, filteredCompetencies, competencyCollections]);

  return (
    isResultLoaded && (
      <Dialog tabIndex="0" open={showTableModal} fullScreen onClose={() => {}}>
        <GridModalHeader
          isExpanded={isExpanded}
          showCohort={showCohort}
          toggleExpanded={setIsExpanded}
          setShowCohort={setShowCohort}
          showScores={showScores}
          setShowScores={setShowScores}
          filteredStudents={filteredStudents}
          setFilteredStudents={setFilteredStudents}
          students={students}
          shouldAnonymizeReport={shouldAnonymizeReport}
          competencyCollections={competencyCollections}
          filteredCompetencies={filteredCompetencies}
          setFilteredCompetencies={setFilteredCompetencies}
          setShouldAnonymizeReport={setShouldAnonymizeReport}
          isCompetencyFilterActive={
            filteredCompetenciesObject.isCompetencyFilterActive
          }
          isStudentFilterActive={filteredStudentsObject.isStudentFilterActive}
          showTotals={showTotals}
          setShowTotals={setShowTotals}
        />

        <div
          id="competency-modal-content"
          style={{ padding: 15, zoom: 1 }}
          {...bind()}
        >
          {showCohort ? (
            <CompetencyGridSummaryDataPanel
              isExpanded={isExpanded}
              shouldAnonymizeReport={shouldAnonymizeReport}
              hasNoScores={hasNoScores}
              tableRef={tableRef}
              competencyCollections={
                filteredCompetenciesObject.filteredCompetenciesForTable
              }
              isCompetencyFilterActive={
                filteredCompetenciesObject.isCompetencyFilterActive
              }
              competencyGridMap={activeCompetencyGridMap}
              participationMaps={activeParticipationMaps}
              competencyDeviationsMap={competencyDeviationsMap}
              students={filteredStudentsObject.filteredStudentsForTable}
              showScores={showScores}
              filteredCompetencyUuids={filteredCompetencies.map(fc => fc.uuid)}
              showTotals={showTotals}
            />
          ) : (
            <DisabledCompetencyGridSummaryDataPanel
              isExpanded={isExpanded}
              shouldAnonymizeReport={shouldAnonymizeReport}
              headerText="Non Active Student Data"
              tableRef={tableRef}
              competencyCollections={
                filteredCompetenciesObject.filteredCompetenciesForTable
              }
              isCompetencyFilterActive={
                filteredCompetenciesObject.isCompetencyFilterActive
              }
              competencyGridMap={delayedCompetencyGridMap}
              participationMaps={delayedParticipationMaps}
              students={filteredStudentsObject.filteredStudentsForTable}
              isDelayed
              showScores={showScores}
              showTotals={showTotals}
            />
          )}
        </div>
      </Dialog>
    )
  );
};

CompetencyGridSummaryData.propTypes = {
  hasNoScores: PropTypes.bool,
  tableRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  competencyCollections: PropTypes.arrayOf(PropTypes.object),
  competencyDeviationsMap: PropTypes.object,
  activeCompetencyGridMap: PropTypes.object,
  delayedCompetencyGridMap: PropTypes.object,
  activeParticipationMaps: PropTypes.object,
  delayedParticipationMaps: PropTypes.object,
  shouldAnonymizeReport: PropTypes.bool,
  students: PropTypes.arrayOf(PropTypes.object),
  delayedStudents: PropTypes.arrayOf(PropTypes.object)
};

CompetencyGridSummaryData.defaultProps = {
  hasNoScores: false,
  competencyDeviationsMap: {},
  activeCompetencyGridMap: {},
  delayedCompetencyGridMap: {},
  activeParticipationMaps: {},
  delayedParticipationMaps: {},
  competencyCollections: [],
  shouldAnonymizeReport: true,
  students: [],
  delayedStudents: []
};

export default CompetencyGridSummaryData;
