const initialProgramAssessmentCollectionState = {
  uuid: '',
  title: '',
  showValidationErrors: false
};

const programAssessmentCollectionReducer = (state, action) => {
  switch (action.type) {
    case 'setExisting': {
      const { uuid, title } = action.payload;

      return {
        ...state,
        uuid,
        title
      };
    }
    case 'setTitle':
      return { ...state, title: action.payload.title };
    case 'setShowValidationErrors':
      return {
        ...state,
        showValidationErrors: action.payload.showValidationErrors
      };
    case 'clearForm':
      return initialProgramAssessmentCollectionState;
    default: {
      throw new Error();
    }
  }
};

const setShowValidationErrors = showValidationErrors => ({
  type: 'setShowValidationErrors',
  payload: { showValidationErrors }
});

export {
  initialProgramAssessmentCollectionState,
  programAssessmentCollectionReducer,
  setShowValidationErrors
};
