import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { selectCollectionGPA } from '../../../../redux/selectors/gpaSelectors';

export default function CollectionGPA({ userUuid, courseCollectionUuid }) {
  const gpa = useSelector(
    state => selectCollectionGPA(state, userUuid, courseCollectionUuid),
    _.isEqual
  );

  return <>{gpa}</>;
}

CollectionGPA.propTypes = {
  courseCollectionUuid: PropTypes.string,
  userUuid: PropTypes.string
};

CollectionGPA.defaultProps = {
  courseCollectionUuid: undefined,
  userUuid: undefined
};
