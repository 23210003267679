export const GET_LETTER_GRADES_BY_SCHOOL = 'GET_LETTER_GRADES_BY_SCHOOL';
export const GET_LETTER_GRADES_BY_SCHOOL_SUCCESS =
  'GET_LETTER_GRADES_BY_SCHOOL_SUCCESS';
export const GET_LETTER_GRADES_BY_SCHOOL_ERROR =
  'GET_LETTER_GRADES_BY_SCHOOL_ERROR';

export const POST_LETTER_GRADE = 'POST_LETTER_GRADE';
export const POST_LETTER_GRADE_SUCCESS = 'POST_LETTER_GRADE_SUCCESS';
export const POST_LETTER_GRADE_ERROR = 'POST_LETTER_GRADE_ERROR';

export const PUT_LETTER_GRADE = 'PUT_LETTER_GRADE';
export const PUT_LETTER_GRADE_SUCCESS = 'PUT_LETTER_GRADE_SUCCESS';
export const PUT_LETTER_GRADE_ERROR = 'PUT_LETTER_GRADE_ERROR';

export const DELETE_LETTER_GRADE = 'DELETE_LETTER_GRADE';
export const DELETE_LETTER_GRADE_SUCCESS = 'DELETE_LETTER_GRADE_SUCCESS';
export const DELETE_LETTER_GRADE_ERROR = 'DELETE_LETTER_GRADE_ERROR';
