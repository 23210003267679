import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import CohortAssessmentContainer from './CohortAssessmentContainer';
import CollectionCompletion from './CollectionCompletion';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .accordion_row_label {
    display: flex;
    flex: ${({ isTablet }) => (!isTablet ? 1 : 'unset')};
  }

  .hover-effect > div:nth-child(2) {
    flex-direction: ${({ isTablet }) => (isTablet ? 'column' : 'row')};
    justify-content: ${({ isTablet }) =>
      isTablet ? 'space-around' : 'flex-start'};
    align-items: ${({ isTablet }) => (isTablet ? 'flex-start' : 'center')};
  }

  .column_one {
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }
`;

const Label = styled.div`
  font-size: 16px;
  color: ${props => props.theme.fontColors.darker};
`;

export default function CoursesByCollection({
  collections,
  userUuid,
  completionStatus
}) {
  const isTablet = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <>
      {collections?.map(collection => (
        <Accordion
          key={collection.uuid}
          withBottomBorder={true}
          expandWidth="66px"
          isTablet={isTablet}
          expandcontent="66px"
          height="80px"
          label={<Label>{collection?.title}</Label>}
          columnOne={
            <CollectionCompletion
              userUuid={userUuid}
              cohortAssessmentCollectionUuid={collection?.uuid}
              completionStatus={completionStatus}
            />
          }
        >
          <CohortAssessmentContainer
            cohortAssessmentCollectionUuid={collection?.uuid}
          />
        </Accordion>
      ))}
    </>
  );
}

CoursesByCollection.propTypes = {
  collections: PropTypes.array,
  userUuid: PropTypes.string,

  completionStatus: PropTypes.shape({
    completed: PropTypes.array,
    pending: PropTypes.array,
    remaining: PropTypes.array
  })
};

CoursesByCollection.defaultProps = {
  collections: undefined,
  userUuid: undefined,

  completionStatus: {
    completed: [],
    pending: [],
    remaining: []
  }
};
