import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import TopicDefaultScoreViewStudentPanelContainer from './TopicDefaultScoreViewStudentPanelContainer';

const TopicPanelHeader = styled(({ headerOffset, ...rest }) => (
  <div {...rest} />
))`
  display: flex;
  justify-content: space-between;
  margin-left: ${props => `${props.headerOffset}px`};
  width: ${props => `calc(100% - ${props.headerOffset}px)`};
`;

const TopicPanelHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  font-weight: 450;
`;

const TopicPanelHeaderSub = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  padding-right: ${props => props.paddingRight};
  color: ${props => props.theme.fontColors.dark};
  text-align: right;
  }
`;

const TopicDefaultScoreViewList = ({
  currentOffset,
  scoreType,
  students,
  topicScores
}) => {
  const headerOffset = Math.floor(currentOffset + 48);
  return (
    <>
      <TopicPanelHeader headerOffset={headerOffset}>
        <TopicPanelHeaderInfo>
          Student (Last Name, First Name)
        </TopicPanelHeaderInfo>
        <TopicPanelHeaderInfo>
          <TopicPanelHeaderSub paddingRight="24px">
            Total Relative
          </TopicPanelHeaderSub>
          <TopicPanelHeaderSub paddingRight="44px">
            Total Potential
          </TopicPanelHeaderSub>
        </TopicPanelHeaderInfo>
      </TopicPanelHeader>
      {students.map(student => {
        const someUuid = uuidv4();
        return (
          <TopicDefaultScoreViewStudentPanelContainer
            key={someUuid}
            currentOffset={currentOffset}
            scoreType={scoreType}
            studentUser={student.user}
            topicScores={topicScores}
          />
        );
      })}
    </>
  );
};

TopicDefaultScoreViewList.propTypes = {
  currentOffset: PropTypes.number,
  scoreType: PropTypes.string,
  students: PropTypes.array,
  topicScores: PropTypes.array
};

TopicDefaultScoreViewList.defaultProps = {
  currentOffset: 0,
  scoreType: '',
  students: '',
  topicScores: []
};

export default TopicDefaultScoreViewList;
