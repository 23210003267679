import { call, put, takeEvery } from 'redux-saga/effects';

import {
  getAssessmentBlocks,
  getAssessmentBlockResults
} from '../../api/assessmentBlockApi';
import {
  doGetAssessmentBlocksSuccess,
  doGetAssessmentBlocksError,
  doGetAssessmentBlockResultsSuccess,
  doGetAssessmentBlockResultsError
} from '../actions/assessmentBlockActions';

import {
  GET_ASSESSMENT_BLOCKS,
  GET_ASSESSMENT_BLOCK_RESULTS
} from '../actions-type';

function* handleGetAssessmentBlocks(action) {
  const { assessmentUuid } = action.payload;
  try {
    const result = yield call(getAssessmentBlocks, assessmentUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetAssessmentBlocksSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetAssessmentBlocksError({
          error: {
            message: errorText.error
          }
        })
      );
    }
  } catch (error) {
    yield put(doGetAssessmentBlocksError());
  }
}

function* handleGetAssessmentBlockResults(action) {
  const { assessmentUuid } = action.payload;
  try {
    const result = yield call(getAssessmentBlockResults, assessmentUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetAssessmentBlockResultsSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetAssessmentBlockResultsError({
          error: {
            message: errorText.error
          }
        })
      );
    }
  } catch (error) {
    yield put(doGetAssessmentBlockResultsError());
  }
}

const assessmentBlock = [
  takeEvery(GET_ASSESSMENT_BLOCKS, handleGetAssessmentBlocks),
  takeEvery(GET_ASSESSMENT_BLOCK_RESULTS, handleGetAssessmentBlockResults)
];

export { assessmentBlock };
