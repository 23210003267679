import { call, put, takeEvery } from 'redux-saga/effects';
import {
  postSchool,
  getSchools,
  getSchool,
  putSchool,
  deleteSchool,
  patchSchoolRevus,
  patchSchoolDenominators
} from '../../api/schoolApi';
import {
  doGetSchoolSuccess,
  doGetSchoolError,
  doGetSchoolsSuccess,
  doGetSchoolsError,
  doPostSchoolSuccess,
  doPostSchoolError,
  doPutSchoolSuccess,
  doPutSchoolError,
  doDeleteSchoolSuccess,
  doDeleteSchoolError,
  doPatchSchoolRevusSuccess,
  doPatchSchoolRevusError,
  doPatchSchoolDenominatorsSuccess,
  doPatchSchoolDenominatorsError
} from '../actions/schoolActions';

import {
  GET_SCHOOL,
  POST_SCHOOL,
  PUT_SCHOOL,
  DELETE_SCHOOL,
  GET_SCHOOLS,
  PATCH_SCHOOL_REVUS,
  PATCH_SCHOOL_DENOMINATORS
} from '../actions-type';

function* handleGetSchool(action) {
  const { schoolUuid } = action.payload;

  try {
    const result = yield call(getSchool, schoolUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetSchoolSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSchoolError(error));
  }
}

function* handleGetSchools() {
  try {
    const result = yield call(getSchools);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetSchoolsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSchoolsError(error));
  }
}

function* handlePostSchool(action) {
  try {
    const result = yield call(postSchool, action.payload.school);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostSchoolSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostSchoolError(error));
  }
}

function* handlePutSchool(action) {
  try {
    const result = yield call(putSchool, action.payload.school);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutSchoolSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutSchoolError(error));
  }
}

function* handleDeleteSchool(action) {
  const { schoolUuid } = action.payload;

  try {
    const result = yield call(deleteSchool, schoolUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteSchoolSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteSchoolError(error));
  }
}

function* handlePatchSchoolRevus(action) {
  const { revus } = action.payload;

  try {
    const result = yield call(patchSchoolRevus, revus);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchSchoolRevusSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPatchSchoolRevusError(error));
  }
}

function* handlePatchSchoolDenominators(action) {
  const { denominators } = action.payload;

  try {
    const result = yield call(patchSchoolDenominators, denominators);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchSchoolDenominatorsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPatchSchoolDenominatorsError(error));
  }
}

const school = [
  takeEvery(GET_SCHOOL, handleGetSchool),
  takeEvery(POST_SCHOOL, handlePostSchool),
  takeEvery(PUT_SCHOOL, handlePutSchool),
  takeEvery(DELETE_SCHOOL, handleDeleteSchool),
  takeEvery(GET_SCHOOLS, handleGetSchools),
  takeEvery(PATCH_SCHOOL_REVUS, handlePatchSchoolRevus),
  takeEvery(PATCH_SCHOOL_DENOMINATORS, handlePatchSchoolDenominators)
];

export { school };
