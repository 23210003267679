import React from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js-light';
import PartTopicReportAssessmentDetail from './PartTopicReportAssessmentDetail';

const PartTopicReportAssessmentDetailContainer = ({
  assessment,
  assessmentCumulativePotentialScore,
  partTopicTotalPotentialScore,
  partTopicTotalRelativeScore
}) => {
  const assessmentRelativePercentage = new Decimal(
    partTopicTotalPotentialScore
  ).greaterThan(0)
    ? new Decimal(partTopicTotalRelativeScore)
        .dividedBy(partTopicTotalPotentialScore)
        .times(100)
        .toFixed(2)
    : '0.00';
  const assessmentParticipationPercentage = new Decimal(
    partTopicTotalPotentialScore
  ).greaterThan(0)
    ? new Decimal(partTopicTotalPotentialScore)
        .dividedBy(assessmentCumulativePotentialScore)
        .times(100)
        .toFixed(2)
    : '0.00';

  const assessementLabel = `${assessment.title} ● ${assessment.assessment_type?.name} ● ${assessment.scoreType}`;

  return new Decimal(partTopicTotalPotentialScore).greaterThan(0) ? (
    <PartTopicReportAssessmentDetail
      assessmentLabel={assessementLabel}
      assessmentParticipationPercentage={assessmentParticipationPercentage}
      assessmentRelativePercentage={assessmentRelativePercentage}
      partTopicTotalPotentialScore={partTopicTotalPotentialScore}
      partTopicTotalRelativeScore={partTopicTotalRelativeScore}
    />
  ) : null;
};

PartTopicReportAssessmentDetailContainer.propTypes = {
  assessment: PropTypes.object,
  assessmentCumulativePotentialScore: PropTypes.string,
  partTopicTotalPotentialScore: PropTypes.string,
  partTopicTotalRelativeScore: PropTypes.string
};
PartTopicReportAssessmentDetailContainer.defaultProps = {
  assessment: {},
  assessmentCumulativePotentialScore: '0.00',
  partTopicTotalPotentialScore: '',
  partTopicTotalRelativeScore: ''
};

export default PartTopicReportAssessmentDetailContainer;
