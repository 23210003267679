import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { orderBy } from 'lodash';

import ReportTableHead from './CollectionReportTable/ReportTableHead';
import CompetencyGridRow from './CollectionReportTable/CompetencyGridRow';
import StudentHighRow from './CollectionReportTable/StudentHighRow';
import StudentAvgRow from './CollectionReportTable/StudentAvgRow';
import StandardDeviationRow from './CollectionReportTable/StandardDeviationRow';
import { standardDeviation } from '../../../helpers/competencyGrid';
import { useSelectedCells } from '../../../helpers/hooks/useSelectedCell';

const Table = styled.table`
  background: ${props => props.theme.colors.grey[200]};
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  & tr {
    & > td:last-child {
      border-right: 1px solid ${props => props.theme.colors.grey[300]};
    }
  }
`;

const TableBody = styled.tbody`
  color: ${props => props.theme.colors.grey[900]};
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 24px;

  ${props =>
    props.isCompact &&
    Object.keys(props.selectedCells).reduce(
      (acc, curr) =>
        `${acc} & tr:nth-child(${curr}){
        ${Object.keys(props.selectedCells[curr]).reduce(
          (acc, curr) =>
            `${acc} & > td:nth-child(${parseInt(curr, 10) + 1}) {
            transform: scale(1.9);
            z-index: 20;
            border: 1px solid dodgerblue !important;
            width: 50px;
            height: 17px;
            margin-top: 5px;
      
            &>div {
              display: flex;
              font-size: 8px;
              cursor: crosshair;
              font-weight: bold;
            }
          }`,
          ''
        )}
        & > td:nth-child(2) {
          background: lavender !important;
        }
      }`,
      ''
    )}

  tr:last-child td {
    border-bottom: none;
  }
`;

const ScrollableDiv = styled(({ ...rest }) => <div {...rest} />)`
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const TableRowEmpty = styled.tr`
  border-top: 1px solid lightgray;
  background: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  padding: 30px;
  align-items: center;
  font-size: 18px;

  & > td {
    border-right: 0px !important;
  }
`;

const StudentRow = ({
  student,
  shouldAnonymizeReport,
  idx,
  competencyCollections,
  competencyGridMap,
  collectionStudentMap,
  studentRankMap,
  studentTotalParticipationMap,
  isExpanded,
  isDelayed,
  showScores,
  showTotals
}) => {
  const someUuid = uuidv4();

  return (
    <CompetencyGridRow
      key={someUuid}
      student={student}
      idx={idx}
      shouldAnonymizeReport={shouldAnonymizeReport}
      competencyCollections={competencyCollections}
      competencyGridMap={competencyGridMap}
      collectionStudentMap={collectionStudentMap}
      studentRankMap={studentRankMap}
      studentTotalParticipationMap={studentTotalParticipationMap}
      isExpanded={isExpanded}
      isDelayed={isDelayed}
      showScores={showScores}
      showTotals={showTotals}
    />
  );
};

StudentRow.propTypes = {
  student: PropTypes.object,
  idx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shouldAnonymizeReport: PropTypes.bool,
  competencyCollections: PropTypes.arrayOf(PropTypes.object),
  competencyGridMap: PropTypes.object,
  collectionStudentMap: PropTypes.object,
  studentRankMap: PropTypes.object,
  studentTotalParticipationMap: PropTypes.object,
  isExpanded: PropTypes.bool,
  isDelayed: PropTypes.bool,
  showScores: PropTypes.bool.isRequired,
  showTotals: PropTypes.bool.isRequired
};

StudentRow.defaultProps = {
  student: {},
  idx: '0',
  shouldAnonymizeReport: true,
  competencyCollections: [],
  competencyGridMap: {},
  collectionStudentMap: {},
  studentRankMap: {},
  studentTotalParticipationMap: {},
  isExpanded: false,
  isDelayed: false
};

const CompetencyGridSummaryDataPanel = ({
  isExpanded,
  shouldAnonymizeReport,
  isDelayed,
  hasNoScores,
  tableRef,
  competencyCollections,
  isCompetencyFilterActive,
  competencyGridMap,
  competencyDeviationsMap,
  participationMaps,
  students,
  showScores,
  filteredCompetencyUuids,
  showTotals
}) => {
  const hasStudents = students?.length > 0;

  const { selectedCells, selectCell } = useSelectedCells({
    showTotals,
    isExpanded,
    isDelayed,
    showScores
  });

  const {
    collectionStudentHighMap,
    competencyStudentHighMap,
    collectionStudentAvgMap,
    competencyStudentAvgMap,
    studentRankMap,
    studentTotalParticipationMap,
    collectionStudentMap
  } = participationMaps;

  const relativeCompScores = {};

  students.map((s, index) => {
    let relativeCumulative = 0;
    filteredCompetencyUuids.map(compId => {
      if (!compId || !competencyGridMap) {
        return;
      }
      if (!relativeCompScores[compId]) {
        relativeCompScores[compId] = {
          high: 0,
          average: 0,
          deviation: 0,
          count: 0,
          values: []
        };
      }
      const studentTotalScore = competencyGridMap[`${s.userUuid}:${compId}`]
        ? parseFloat(competencyGridMap[`${s.userUuid}:${compId}`].totalScore)
        : 0;
      relativeCumulative += studentTotalScore;
      if (studentTotalScore > 0) {
        relativeCompScores[compId].values.push(studentTotalScore);
      }
      relativeCompScores[compId].high =
        relativeCompScores[compId].high < studentTotalScore
          ? studentTotalScore
          : relativeCompScores[compId].high;
      relativeCompScores[compId].average += studentTotalScore;
      relativeCompScores[compId].count += studentTotalScore > 0 ? 1 : 0;

      if (index === students.length - 1) {
        relativeCompScores[compId].average /= relativeCompScores[compId].count;
        relativeCompScores[compId].deviation = standardDeviation(
          relativeCompScores[compId]
        );
        delete relativeCompScores[compId].count;
        delete relativeCompScores[compId].values;
      }
      return null;
    });

    // eslint-disable-next-line no-param-reassign
    s.relativeCumulative = relativeCumulative;
    return null;
  });

  const reorderedStudents =
    isCompetencyFilterActive && isExpanded
      ? orderBy(students, 'relativeCumulative', 'desc')
      : students;

  return (
    <ScrollableDiv>
      <Table ref={tableRef}>
        <ReportTableHead
          competencyCollections={competencyCollections}
          isExpanded={isExpanded}
          isDelayed={isDelayed}
          selectedColumns={selectedCells}
          showScores={showScores}
          showTotals={!isExpanded || showTotals}
        />
        {!hasNoScores && hasStudents ? (
          <TableBody
            onClick={selectCell}
            selectedCells={selectedCells}
            isCompact={!showScores}
          >
            {!isDelayed && (!isExpanded || showTotals) && (
              <>
                <StudentHighRow
                  competencyCollections={competencyCollections}
                  collectionStudentHighMap={collectionStudentHighMap}
                  competencyStudentHighMap={competencyStudentHighMap}
                  isExpanded={isExpanded}
                  showScores={showScores}
                  relativeCompScores={relativeCompScores}
                />
                <StudentAvgRow
                  competencyCollections={competencyCollections}
                  collectionStudentAvgMap={collectionStudentAvgMap}
                  competencyStudentAvgMap={competencyStudentAvgMap}
                  isExpanded={isExpanded}
                  showScores={showScores}
                  relativeCompScores={relativeCompScores}
                />
                <StandardDeviationRow
                  competencyCollections={competencyCollections}
                  competencyDeviationsMap={competencyDeviationsMap}
                  isExpanded={isExpanded}
                  showScores={showScores}
                  relativeCompScores={relativeCompScores}
                />
              </>
            )}
            {hasStudents &&
              reorderedStudents.map((student, idx) => (
                <StudentRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  student={student}
                  shouldAnonymizeReport={shouldAnonymizeReport}
                  idx={idx}
                  competencyCollections={competencyCollections}
                  competencyGridMap={competencyGridMap}
                  collectionStudentMap={collectionStudentMap}
                  studentRankMap={studentRankMap}
                  studentTotalParticipationMap={studentTotalParticipationMap}
                  isExpanded={isExpanded}
                  isDelayed={isDelayed}
                  showScores={showScores}
                  showTotals={!isExpanded || showTotals}
                />
              ))}
          </TableBody>
        ) : (
          <tbody>
            {!hasStudents && (
              <TableRowEmpty>
                <td>This report does not include any students.</td>
              </TableRowEmpty>
            )}
            {hasNoScores && (
              <TableRowEmpty>
                <td>The search criteria you selected returned no scores.</td>
              </TableRowEmpty>
            )}
          </tbody>
        )}
      </Table>
    </ScrollableDiv>
  );
};

CompetencyGridSummaryDataPanel.propTypes = {
  isExpanded: PropTypes.bool,
  isDelayed: PropTypes.bool,
  shouldAnonymizeReport: PropTypes.bool,
  hasNoScores: PropTypes.bool,
  showScores: PropTypes.bool,
  tableRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  competencyCollections: PropTypes.arrayOf(PropTypes.object),
  competencyGridMap: PropTypes.object,
  competencyDeviationsMap: PropTypes.object,
  participationMaps: PropTypes.object,
  students: PropTypes.arrayOf(PropTypes.object),
  filteredCompetencyUuids: PropTypes.array,
  showTotals: PropTypes.bool.isRequired,
  isCompetencyFilterActive: PropTypes.bool.isRequired
};

CompetencyGridSummaryDataPanel.defaultProps = {
  isExpanded: false,
  isDelayed: false,
  shouldAnonymizeReport: false,
  hasNoScores: false,
  showScores: true,
  competencyGridMap: {},
  competencyDeviationsMap: {},
  participationMaps: {},
  competencyCollections: [],
  students: [],
  filteredCompetencyUuids: []
};

export default CompetencyGridSummaryDataPanel;
