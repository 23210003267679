import React, { useMemo, useRef, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import { defaultRangeExtractor, useVirtualizer } from '@tanstack/react-virtual';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { isUndefined } from 'lodash';

const NewTable = ({
  quantitativeSkillsCollections,
  students,
  dataCellValueType,
  isAnonymized,
  isExpanded
}) => {
  const theme = useTheme();
  let longestAssessmentTitle = 0;
  quantitativeSkillsCollections.forEach(a => {
    if (a.name?.length > longestAssessmentTitle) {
      longestAssessmentTitle = a.name?.length;
    }
  });
  const columns = useMemo(() => {
    const cols = quantitativeSkillsCollections.map(skill => ({
      accessorKey: skill.uuid,
      accessorFn: row => {
        let cellData;
        if (skill?.type === 'collection') {
          cellData = row?.studentResults.find(sr => sr.uuid === skill.uuid);
        }
        if (skill?.type === 'group') {
          cellData = row.studentResults
            .map(sr => sr.procedureGroups)
            .flat()
            .find(pg => pg.uuid === skill.uuid);
        }

        return {
          value: cellData?.[dataCellValueType.toLowerCase()],
          thresholdMet: cellData?.thresholds?.[dataCellValueType.toLowerCase()]
        };
      },
      cell: ({ getValue }) => {
        const cellValue = getValue();
        const { value } = cellValue;
        const hasValue = value && !`${value}`.includes('N/A');

        return (
          <span
            style={{
              color: !hasValue ? '#ccc' : 'inherit'
            }}
          >
            {value}
          </span>
        );
      },

      header: ({ header }) => {
        let background = 'white';
        if (skill.type === 'collection') {
          background =
            'linear-gradient(-90deg, rgb(211, 234, 253) 0%, rgb(255, 255, 255) 75%)';
        }
        if (!isExpanded && skill.type === 'collection') {
          background = 'rgb(211, 234, 253)';
        }

        return (
          <TableCell
            style={{
              display: 'flex',
              width: header.getSize(),
              writingMode: 'vertical-lr',
              transform: 'rotate(180deg)',
              alignItems: 'flex-start',
              fontSize: '12px',
              border: '1px solid #e0e0e0',
              padding: '20px 5px',
              background
            }}
          >
            {skill.name}
          </TableCell>
        );
      },
      size: 65
    }));

    return [
      {
        accessorKey: 'rank',
        accessorFn: row => {
          return row.rank[dataCellValueType.toLowerCase()];
        },
        header: 'Quantitative Skills Rank',
        meta: {
          isSticky: true
        },
        size: 30
      },
      {
        accessorKey: 'name',
        accessorFn: row => {
          return isAnonymized ? row.user.institutionId : row.studentFullName;
        },
        meta: {
          isSticky: true
        },
        header: '',
        size: 240
      },
      {
        accessorKey: 'all-collections',
        accessorFn: row => {
          const value =
            row?.studentResults?.at(0)?.[dataCellValueType.toLowerCase()];

          return value;
        },
        meta: {
          isSticky: true
        },
        header: 'All Collections',
        size: 65
      },
      ...cols
    ];
  }, [
    dataCellValueType,
    quantitativeSkillsCollections,
    isAnonymized,
    isExpanded
  ]);

  const [, setColumnPinning] = useState({
    left: [],
    right: []
  });

  const table = useReactTable({
    data: students,
    columns,
    initialState: {
      columnPinning: {
        left: ['rank', 'name', 'all-collections'],
        right: []
      }
    },
    getCoreRowModel: getCoreRowModel(),
    onColumnPinningChange: setColumnPinning
  });

  const { rows } = table.getRowModel();

  const visibleColumns = table.getVisibleLeafColumns();

  // The virtualizer need to know the scrollable container element
  const tableContainerRef = useRef(null);
  // const testRef = useRef(null);

  // we are using a slightly different virtualization strategy for columns (compared to virtual rows) in order to support dynamic row heights
  const columnVirtualizer = useVirtualizer({
    count: visibleColumns.length,
    estimateSize: index => visibleColumns[index].getSize(), // estimate width of each column for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    horizontal: true,
    overscan: 20, // how many columns to render on each side off screen each way (adjust this for performance)
    rangeExtractor: range =>
      [...new Set([0, 1, 2, ...defaultRangeExtractor(range)])].sort(
        (a, b) => a - b
      )
  });

  // dynamic row height virtualization - alternatively you could use a simpler fixed row height strategy without the need for `measureElement`
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 40, // estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    // measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 20 // how many rows to render off screen each way (adjust this for performance)
  });

  const virtualColumns = columnVirtualizer.getVirtualItems();
  const virtualRows = rowVirtualizer.getVirtualItems();

  // different virtualization strategy for columns - instead of absolute and translateY, we add empty columns to the left and right
  let virtualPaddingLeft;
  let virtualPaddingRight;

  if (columnVirtualizer && virtualColumns?.length) {
    const start = virtualColumns[3]?.index > 3 ? 3 : 0;
    // console.log(virtualColumns[start].start);
    virtualPaddingLeft = virtualColumns[start]?.start ?? 0;
    virtualPaddingRight =
      columnVirtualizer.getTotalSize() -
      (virtualColumns[virtualColumns.length - 1]?.end ?? 0);
  }

  return (
    <Box
      className="container"
      ref={tableContainerRef}
      style={{
        overflow: 'auto', // our scrollable table container
        position: 'relative', // needed for sticky header
        height: 'calc(100vh - 100px)' // should be a fixed height
      }}
    >
      {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
      <Table style={{ display: 'grid' }} size="small">
        <TableHead
          style={{
            display: 'grid',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            height: 450,
            backgroundColor: 'white'
          }}
        >
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow
              key={headerGroup.id}
              style={{ display: 'flex', width: '100%' }}
            >
              {virtualPaddingLeft > 0 && (
                // fake empty column to the left for virtualization scroll padding
                <TableCell
                  style={{
                    display: 'flex',
                    paddingLeft: virtualPaddingLeft
                  }}
                />
              )}
              {virtualColumns.map(vc => {
                const header = headerGroup.headers[vc.index];

                let backgroundColor = 'white';

                if (
                  header.id.includes('rank') ||
                  header.id.includes('all-collections')
                ) {
                  backgroundColor = '#ffe6e9';
                }

                if (typeof header.column.columnDef.header === 'function') {
                  return flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  );
                }

                return (
                  <TableCell
                    key={header.id}
                    style={{
                      display: 'flex',
                      width: header.getSize(),
                      writingMode: 'vertical-lr',
                      transform: 'rotate(180deg)',
                      alignItems: 'flex-start',
                      fontSize: '12px',
                      border: '1px solid #e0e0e0',
                      padding: '20px 5px',
                      ...(header.column.columnDef.meta?.isSticky
                        ? {
                            position: 'sticky',
                            zIndex: 99,
                            left: vc.start,
                            backgroundColor: 'white'
                          }
                        : {}),
                      backgroundColor
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableCell>
                );
              })}
              {virtualPaddingRight > 0 && (
                // fake empty column to the right for virtualization scroll padding
                <TableCell
                  style={{
                    display: 'flex',
                    paddingRight: virtualPaddingRight
                  }}
                />
              )}
            </TableRow>
          ))}
        </TableHead>
        <TableBody
          style={{
            display: 'grid',
            height: `${rowVirtualizer.getTotalSize()}px`, // tells scrollbar how big the table is
            position: 'relative', // needed for absolute positioning of rows
            backgroundColor: 'white'
          }}
        >
          {virtualRows.map((virtualRow, index) => {
            const row = rows[virtualRow.index];

            const visibleCells = row.getVisibleCells();

            return (
              <TableRow
                data-index={virtualRow.index} // needed for dynamic row height measurement
                ref={node => rowVirtualizer.measureElement(node)} // measure dynamic row height
                key={row.id}
                style={{
                  display: 'flex',
                  position: 'absolute',
                  transform: `translateY(${virtualRow.start}px)`, // this should always be a `style` as it changes on scroll
                  width: '100%',
                  backgroundColor: index % 2 ? '#efeeee' : 'white'
                }}
              >
                {virtualPaddingLeft > 0 && (
                  // fake empty column to the left for virtualization scroll padding
                  <TableCell
                    style={{
                      display: 'flex',
                      paddingLeft: virtualPaddingLeft
                    }}
                  />
                )}
                {virtualColumns.map(vc => {
                  const cell = visibleCells[vc.index];
                  const cellValue = cell.getContext().getValue();

                  return (
                    <TableCell
                      key={cell.id}
                      style={{
                        display: 'flex',
                        width: cell.column.getSize(),

                        fontSize: '12px',
                        alignItems: 'flex-end',
                        flexDirection: 'column',
                        border: '1px solid #e0e0e0',
                        padding: '5px',
                        ...(!isUndefined(cellValue.thresholdMet) &&
                        cellValue.thresholdMet === true
                          ? {
                              backgroundColor: theme.palette.success.main,
                              color: 'white'
                            }
                          : {}),
                        ...(!isUndefined(cellValue.thresholdMet) &&
                        cellValue.thresholdMet === false
                          ? {
                              backgroundColor: theme.palette.error.main,
                              color: 'white'
                            }
                          : {}),

                        ...(cell.column.columnDef.meta?.isSticky
                          ? {
                              position: 'sticky',
                              zIndex: 99,
                              left: vc.start,
                              backgroundColor: 'white'
                            }
                          : {})
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  );
                })}
                {virtualPaddingRight > 0 && (
                  // fake empty column to the right for virtualization scroll padding
                  <TableCell
                    style={{
                      display: 'flex',
                      paddingRight: virtualPaddingRight
                    }}
                  />
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

NewTable.propTypes = {
  quantitativeSkillsCollections: PropTypes.array,
  students: PropTypes.array,
  dataCellValueType: PropTypes.string,
  isAnonymized: PropTypes.bool,
  isExpanded: PropTypes.bool
};

NewTable.defaultProps = {
  quantitativeSkillsCollections: [],
  students: [],
  dataCellValueType: 'string',
  isAnonymized: false,
  isExpanded: false
};

export default NewTable;
