import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Heading2,
  Subtitle1,
  Caption,
  ButtonMenu,
  ButtonPrimary
} from '@xcomp/xcomp-design-library';

import { Link } from 'react-router-dom';
import { CheckIcon, RubricIcon } from '@xcomp/xcomp-design-library/dist/icons';
import {
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox
} from '@material-ui/core';

import {
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader,
  ContainerActions
} from '../../../Library/Layout';
import Header from '../../../Library/Header';
import { gradingTechniquesMap } from '../../../../helpers/constants';
import GradeSummaryRow from './GradeSummaryRow';
import GradeSummaryRowHeader from './GradeSummaryRowHeader';

const formatSummaryScores = (value, isPointsOnly) => {
  if (Number.isNaN(Number(value)) || !value) return '--';
  return isPointsOnly ? value : `${value}%`;
};

const PanelHeaderSC = styled(PanelHeader)`
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 24px;
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const GradeSummaryPanelSC = styled(Panel)`
  margin-bottom: 40px;
`;

const SummaryPanelHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CheckIconSC = styled(CheckIcon)`
  color: ${props => props.theme.fontColors.dark};
  margin-right: 8px;
`;

const SummaryScores = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ScoreRightContain = styled.div`
  width: 40%;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const SummaryScoreColumn = styled.div`
  margin-right: 64px;

  &:last-child {
    margin-right: 0;
  }
`;

const ScoreLabel = styled(Caption)`
  margin-bottom: 0;
  margin: 0 0 4px;
`;

const RubricIconSC = styled(RubricIcon)`
  margin-left: 4px;
`;

const SummaryScore = styled(Heading2)`
  margin: 0;
`;

const GradesHeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const CheckboxContainer = styled.div`
  color: ${props => props.theme.fontColors.dark};
  margin-left: 115px;
`;

const ButtonMenuSC = styled(ButtonMenu)`
  margin-left: auto;
  button {
    width: 200px;

    span {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const GradeSummary = ({
  assessmentTitle,
  assessmentUuid,
  cohortUuid,
  cohort,
  assessmentType,
  gradeSummary,
  hideStudentNames,
  isPointsOnly,
  sortFunction,
  toggleHideStudentNames,
  setSortBy,
  handleBackAction,
  resultCards
}) => {
  const headerSubtitle = cohort
    ? `${cohort?.name} ● ${assessmentTitle} ● ${assessmentType}`
    : `${assessmentTitle} ● ${assessmentType}`;

  const sortByLastName = () => setSortBy('lastName');
  const sortHighToLow = () => setSortBy('highLow');
  const sortLowToHigh = () => setSortBy('lowHigh');

  const highScore = Number(gradeSummary.highScore).toFixed(2);
  const avgScore = Number(gradeSummary.averageScore).toFixed(2);
  const lowScore = Number(gradeSummary.lowScore).toFixed(2);

  return (
    <ContainerResize>
      <Header
        title={assessmentTitle}
        subtitle={headerSubtitle}
        backButtonOverride={handleBackAction}
      />
      <ContainerActions paddingTop="100px">
        <Link
          to={`/cohort-syllabus-management/cohort/${cohortUuid}/cohort-assessment/${assessmentUuid}/commitment-log`}
        >
          <ButtonPrimary>
            Commitment Log&nbsp;
            <RubricIconSC />
          </ButtonPrimary>
        </Link>
      </ContainerActions>
      <ContainerPage paddingTop="36px">
        <GradeSummaryPanelSC>
          <PanelHeaderSC paddingLeft="24px">
            <SummaryPanelHeader>
              <Heading2>Grade Summary</Heading2>
              <ScoreRightContain>
                <CheckIconSC />
                <Subtitle1>
                  {gradingTechniquesMap[gradeSummary.gradingTechnique]}
                </Subtitle1>
              </ScoreRightContain>
            </SummaryPanelHeader>
          </PanelHeaderSC>
          <PanelPadding>
            <SummaryScores>
              <ScoreRightContain>
                <SummaryScoreColumn>
                  <ScoreLabel>High</ScoreLabel>
                  <SummaryScore>
                    {formatSummaryScores(highScore, isPointsOnly)}
                  </SummaryScore>
                </SummaryScoreColumn>
                <SummaryScoreColumn>
                  <ScoreLabel>Average</ScoreLabel>
                  <SummaryScore>
                    {formatSummaryScores(avgScore, isPointsOnly)}
                  </SummaryScore>
                </SummaryScoreColumn>
                <SummaryScoreColumn>
                  <ScoreLabel>Low</ScoreLabel>
                  <SummaryScore>
                    {formatSummaryScores(lowScore, isPointsOnly)}
                  </SummaryScore>
                </SummaryScoreColumn>
              </ScoreRightContain>
            </SummaryScores>
          </PanelPadding>
        </GradeSummaryPanelSC>
        <Panel>
          <PanelHeaderSC paddingLeft="24px">
            <GradesHeadingWrapper>
              <Heading2>Student Grades</Heading2>
              <FormControl component="fieldset">
                <FormGroup>
                  <CheckboxContainer>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="hideStudents"
                          onChange={toggleHideStudentNames}
                          checked={hideStudentNames}
                          color="primary"
                        />
                      }
                      label="Hide Student Names"
                    />
                  </CheckboxContainer>
                </FormGroup>
              </FormControl>
              <ButtonMenuSC
                buttonText="Sort By..."
                type="outline"
                options={[
                  {
                    label: 'Last Name',
                    onClick: sortByLastName
                  },
                  {
                    label: 'High to Low Percentage',
                    onClick: sortHighToLow
                  },
                  {
                    label: 'Low to High Percentage',
                    onClick: sortLowToHigh
                  }
                ]}
              />
            </GradesHeadingWrapper>
          </PanelHeaderSC>
          {resultCards && resultCards.length > 0 ? (
            <>
              <GradeSummaryRowHeader isPointsOnly={isPointsOnly} />
              {resultCards.sort(sortFunction).map(resultCard => (
                <GradeSummaryRow
                  key={isPointsOnly ? resultCard.studentUuid : resultCard.uuid}
                  isPointsOnly={isPointsOnly}
                  resultCard={resultCard}
                  hideStudentNames={hideStudentNames}
                />
              ))}
            </>
          ) : (
            <PanelPadding>No Scores</PanelPadding>
          )}
        </Panel>
      </ContainerPage>
    </ContainerResize>
  );
};

GradeSummary.propTypes = {
  assessmentTitle: PropTypes.string,
  cohort: PropTypes.object,
  assessmentUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  assessmentType: PropTypes.string,
  gradeSummary: PropTypes.object,
  hideStudentNames: PropTypes.bool,
  isPointsOnly: PropTypes.bool,
  sortFunction: PropTypes.func,
  toggleHideStudentNames: PropTypes.func,
  setSortBy: PropTypes.func,
  handleBackAction: PropTypes.func,
  resultCards: PropTypes.arrayOf(PropTypes.object)
};

GradeSummary.defaultProps = {
  assessmentTitle: '',
  cohort: {},
  assessmentUuid: '',
  cohortUuid: '',
  assessmentType: '',
  gradeSummary: { highScore: '--', lowScore: '--', averageScore: '--' },
  hideStudentNames: false,
  isPointsOnly: false,
  sortFunction: undefined,
  toggleHideStudentNames: undefined,
  setSortBy: undefined,
  handleBackAction: undefined,
  resultCards: []
};

export default GradeSummary;
