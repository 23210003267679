import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectAptitudeWeightBySource } from '../../../../redux/selectors/transcriptAptitudeSelectors';

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export default function AptitudeWeightBySource({
  programUuid,
  category,
  source
}) {
  const sourceSkillWeight = useSelector(state =>
    selectAptitudeWeightBySource(state, programUuid, category, source)
  );

  const weight = sourceSkillWeight ? `${sourceSkillWeight}%` : 'N/A';

  return <Layout>{weight}</Layout>;
}

AptitudeWeightBySource.propTypes = {
  programUuid: PropTypes.string,
  category: PropTypes.string,
  source: PropTypes.string
};

AptitudeWeightBySource.defaultProps = {
  programUuid: undefined,
  category: undefined,
  source: undefined
};
