import { call, put, takeEvery } from 'redux-saga/effects';

import { getAssessmentGrades } from '../../api/assessmentGradesApi';
import {
  doGetAssessmentGradesSuccess,
  doGetAssessmentGradesError
} from '../actions/assessmentGradeActions';

import { GET_ASSESSMENT_GRADES } from '../actions-type';

function* handleGetAssessmentGrade(action) {
  const { assessmentUuid, studentUuid } = action.payload;
  try {
    const result = yield call(getAssessmentGrades, assessmentUuid, studentUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetAssessmentGradesSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetAssessmentGradesError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doGetAssessmentGradesError());
  }
}

const assessmentGrade = [
  takeEvery(GET_ASSESSMENT_GRADES, handleGetAssessmentGrade)
];

export { assessmentGrade };
