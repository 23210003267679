export const GET_PART = 'GET_PART';
export const GET_PART_SUCCESS = 'GET_PART_SUCCESS';
export const GET_PART_ERROR = 'GET_PART_ERROR';

export const GET_PART_FACULTY = 'GET_PART_FACULTY';
export const GET_PART_FACULTY_SUCCESS = 'GET_PART_FACULTY_SUCCESS';
export const GET_PART_FACULTY_ERROR = 'GET_PART_FACULTY_ERROR';

export const PATCH_PART_STATUS = 'PATCH_PART_STATUS';
export const PATCH_PART_STATUS_SUCCESS = 'PATCH_PART_STATUS_SUCCESS';
export const PATCH_PART_STATUS_ERROR = 'PATCH_PART_STATUS_ERROR';

export const PATCH_PART_LOCKED = 'PATCH_PART_LOCKED';
export const PATCH_PART_LOCKED_SUCCESS = 'PATCH_PART_LOCKED_SUCCESS';
export const PATCH_PART_LOCKED_ERROR = 'PATCH_PART_LOCKED_ERROR';

export const PATCH_PART_CALCULATE_GRADE = 'PATCH_PART_CALCULATE_GRADE';
export const PATCH_PART_CALCULATE_GRADE_SUCCESS =
  'PATCH_PART_CALCULATE_GRADE_SUCCESS';
export const PATCH_PART_CALCULATE_GRADE_ERROR =
  'PATCH_PART_CALCULATE_GRADE_ERROR';

export const PATCH_PART_UNDO_CALCULATED_GRADE =
  'PATCH_PART_UNDO_CALCULATED_GRADE';
export const PATCH_PART_UNDO_CALCULATED_GRADE_SUCCESS =
  'PATCH_PART_UNDO_CALCULATED_GRADE_SUCCESS';
export const PATCH_PART_UNDO_CALCULATED_GRADE_ERROR =
  'PATCH_PART_UNDO_CALCULATED_GRADE_ERROR';

export const PUT_PART_GRADE_DELAYED_STUDENT = 'PUT_PART_GRADE_DELAYED_STUDENT';
export const PUT_PART_GRADE_DELAYED_STUDENT_SUCCESS =
  'PUT_PART_GRADE_DELAYED_STUDENT_SUCCESS';
export const PUT_PART_GRADE_DELAYED_STUDENT_ERROR =
  'PUT_PART_GRADE_DELAYED_STUDENT_ERROR';

export const DELETE_PART_GRADE_DELAYED_STUDENT =
  'DELETE_PART_GRADE_DELAYED_STUDENT';
export const DELETE_PART_GRADE_DELAYED_STUDENT_SUCCESS =
  'DELETE_PART_GRADE_DELAYED_STUDENT_SUCCESS';
export const DELETE_PART_GRADE_DELAYED_STUDENT_ERROR =
  'DELETE_PART_GRADE_DELAYED_STUDENT_ERROR';

export const PUT_PART_DELAYED_STATUS_COMMIT = 'PUT_PART_DELAYED_STATUS_COMMIT';
export const PUT_PART_DELAYED_STATUS_COMMIT_SUCCESS =
  'PUT_PART_DELAYED_STATUS_COMMIT_SUCCESS';
export const PUT_PART_DELAYED_STATUS_COMMIT_ERROR =
  'PUT_PART_DELAYED_STATUS_COMMIT_ERROR';

export const PUT_PART_STATUS_COMMIT = 'PUT_PART_STATUS_COMMIT';
export const PUT_PART_STATUS_COMMIT_SUCCESS = 'PUT_PART_STATUS_COMMIT_SUCCESS';
export const PUT_PART_STATUS_COMMIT_ERROR = 'PUT_PART_STATUS_COMMIT_ERROR';

export const COMPLETE_STATUS_CHECK_ERROR = 'COMPLETE_STATUS_CHECK_ERROR';
export const APPROVE_STATUS_CHECK_ERROR = 'APPROVE_STATUS_CHECK_ERROR';

export const POST_CLONE_EXISTING_PART = 'POST_CLONE_EXISTING_PART';
export const POST_CLONE_EXISTING_PART_SUCCESS =
  'POST_CLONE_EXISTING_PART_SUCCESS';
export const POST_CLONE_EXISTING_PART_ERROR = 'POST_CLONE_EXISTING_PART_ERROR';
