import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { doGetAssessmentsByPart } from '../../../../redux/actions/assessmentActions';
import { doGetPartTopics } from '../../../../redux/actions/topicActions';
import { assessmentsSelector } from '../../../../redux/selectors/assessmentSelectors';
import {
  doClearPartTopicReport,
  doGetPartTopicReport
} from '../../../../redux/actions/partReportsActions';
import {
  sortByDateProperty,
  sortByDecimalAndAlphaProperty
} from '../../../../helpers/utilities';
import PartTopicReport from './PartTopicReport';

const PartTopicReportContainer = ({ partUuid }) => {
  const dispatch = useDispatch();

  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);

  const [sortedTopics, setSortedTopics] = useState([]);

  const {
    isLoading,
    partTopicReportAssessmentData,
    partTopicReportTopicData,
    partTopicReportSummaryData,
    records
  } = useSelector(state => state.partTopicReportState);

  const partTopics = useSelector(
    state => state.partTopicReportState.topics,
    _.isEqual
  );

  const assessments = useSelector(
    state => assessmentsSelector(state, partUuid),
    _.isEqual
  );

  // clear report on unmount
  useEffect(() => {
    return () => dispatch(doClearPartTopicReport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (partUuid !== '') {
      dispatch(doGetAssessmentsByPart(partUuid));
      dispatch(doGetPartTopics(partUuid));
    }
  }, [dispatch, partUuid]);

  useEffect(() => {
    if (records > 0) {
      setSortedTopics(
        [...partTopics].sort((a, b) =>
          sortByDecimalAndAlphaProperty(
            {
              title: b?.title,
              totalPotentialScore:
                partTopicReportTopicData[b.uuid]?.totalPotentialScore
            },
            {
              title: a?.title,
              totalPotentialScore:
                partTopicReportTopicData[a.uuid]?.totalPotentialScore
            },
            'totalPotentialScore',
            'title'
          )
        )
      );
    }
  }, [dispatch, partTopicReportTopicData, partTopics, records]);

  const handleApplyPartTopicReportFilters = topics => {
    setHasAppliedFilters(true);
    dispatch(doGetPartTopicReport(partUuid, topics));
  };

  return (
    <PartTopicReport
      assessments={[...assessments].sort((a, b) =>
        sortByDateProperty(a, b, 'startDate')
      )}
      cumulativePotentialScore={
        partTopicReportSummaryData.totalPotentialScore ?? '0.00'
      }
      cumulativeRelativeScore={
        partTopicReportSummaryData.totalRelativeScore ?? '0.00'
      }
      hasAppliedFilters={hasAppliedFilters}
      isLoading={isLoading}
      onApplyPartTopicReportFilters={handleApplyPartTopicReportFilters}
      partTopicReportAssessmentData={partTopicReportAssessmentData}
      partTopicReportTopicData={partTopicReportTopicData}
      partTopics={partTopics}
      partUuid={partUuid}
      reportIsEmpty={records === 0}
      sortedPartTopics={sortedTopics}
    />
  );
};

PartTopicReportContainer.propTypes = { partUuid: PropTypes.string };

PartTopicReportContainer.defaultProps = { partUuid: '' };

export default PartTopicReportContainer;
