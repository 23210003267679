import { Model, attr } from 'redux-orm';
import { GET_ACCREDITATION_AGENCIES_SUCCESS } from '../actions-type';

class AccreditationAgency extends Model {
  static reducer(action, AccreditationAgency, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_ACCREDITATION_AGENCIES_SUCCESS: {
        AccreditationAgency.all().delete();
        const agencies = [...payload.accreditationAgencies];
        agencies.forEach(agency => AccreditationAgency.upsert(agency));
        break;
      }
      default:
        return session;
    }
  }
}

AccreditationAgency.fields = {
  uuid: attr(),
  name: attr(),
  agencyType: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

AccreditationAgency.modelName = 'AccreditationAgency';
AccreditationAgency.options = {
  idAttribute: 'uuid'
};

export default AccreditationAgency;
