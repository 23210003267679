import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const selectCourseMods = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const getCourseAgeMod = ormCreateSelector(orm.CourseAgeMod);

    const mods = getCourseAgeMod(redux).filter(
      rigor => rigor.programUuid === programUuid
    );

    return mods;
  }
);
