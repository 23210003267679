import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import moment from 'moment';
import CohortSyllabus from './CohortSyllabusManagement';
import { doGetCohortAssessments } from '../../redux/actions/cohortAssessmentActions';
import { cohortAssessmentCollectionSelector } from '../../redux/selectors/cohortAssessmentSelectors';
import { cohortSelector } from '../../redux/selectors/cohortsSelectors';
import { doGetProgramAssessments } from '../../redux/actions/programActions';
import { doGetUserProgramsByProgram } from '../../redux/actions/userProgramActions';

const sortDates = (dateA, dateB) => {
  if (!dateA) {
    return 1;
  }

  if (!dateB) {
    return -1;
  }

  return moment(dateA) - moment(dateB);
};

const sortDatesReverse = (dateA, dateB) => {
  if (!dateA) {
    return 1;
  }

  if (!dateB) {
    return -1;
  }
  return moment(dateB) - moment(dateA);
};

const CohortSyllabusContainer = () => {
  const dispatch = useDispatch();
  const { cohortUuid, cohortAssessmentCollectionUuid } = useParams();

  const [filterSelection, setFilterSelection] = useState('all');
  const [sortSelection, setSortSelection] = useState('ascending');

  const assessmentCollection = useSelector(
    state =>
      cohortAssessmentCollectionSelector(state, cohortAssessmentCollectionUuid),
    _.isEqual
  );

  const parentCohort = useSelector(state => cohortSelector(state, cohortUuid));
  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );
  useEffect(() => {
    if (parentCohort?.programUuid) {
      dispatch(doGetProgramAssessments(parentCohort.programUuid));
    }
  }, [dispatch, parentCohort]);

  useEffect(() => {
    if (selectedProgramUuid && selectedProgramUuid !== '') {
      dispatch(doGetUserProgramsByProgram(selectedProgramUuid));
    }
  }, [dispatch, selectedProgramUuid]);

  useEffect(() => {
    dispatch(doGetCohortAssessments(cohortUuid));
  }, [cohortUuid, dispatch]);

  const { cohortAssessments } = assessmentCollection;

  const handleFilterSelection = event => {
    const { value } = event.target;
    setFilterSelection(value);
  };

  const handleSortSelection = sortDirection => {
    setSortSelection(sortDirection);
  };

  const sortSyllabus = () => {
    switch (filterSelection) {
      case 'assessments': {
        if (sortSelection === 'descending') {
          return cohortAssessments.sort((a, b) =>
            sortDatesReverse(a.startDate, b.startDate)
          );
        } else {
          return cohortAssessments.sort((a, b) =>
            sortDates(a.startDate, b.startDate)
          );
        }
      }
      default: {
        const items = [...cohortAssessments];

        if (sortSelection === 'descending') {
          return items.sort((a, b) => sortDatesReverse(a, b));
        } else {
          return items.sort((a, b) => sortDates(a, b));
        }
      }
    }
  };

  const cohortTitle = _.get(parentCohort, 'name', '');
  const cohortAssessmentCollectionTitle = _.get(
    assessmentCollection,
    'title',
    ''
  );

  return (
    <CohortSyllabus
      cohortUuid={cohortUuid}
      programUuid={selectedProgramUuid}
      cohortAssessmentCollectionUuid={cohortAssessmentCollectionUuid}
      cohortTitle={cohortTitle}
      cohortAssessments={cohortAssessments}
      parentCohort={parentCohort}
      cohortAssessmentCollectionTitle={cohortAssessmentCollectionTitle}
      filterSelection={filterSelection}
      sortSelection={sortSelection}
      handleFilterSelection={handleFilterSelection}
      handleSortSelection={handleSortSelection}
      syllabusItems={sortSyllabus()}
    />
  );
};

CohortSyllabusContainer.propTypes = {};

CohortSyllabusContainer.defaultProps = {};

export default CohortSyllabusContainer;
