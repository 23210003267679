import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { doGetCohortAssessments } from '../../../../redux/actions/cohortAssessmentActions';
import { cohortAssessmentCollectionsSelector } from '../../../../redux/selectors/cohortAssessmentSelectors';

import CohortAssessmentCollectionsList from './CohortAssessmentCollectionsList';

const CohortAssessmentCollectionsListContainer = () => {
  const dispatch = useDispatch();
  const { cohortUuid } = useParams();

  const assessmentCollections = useSelector(
    state => cohortAssessmentCollectionsSelector(state, cohortUuid),
    _.isEqual
  );

  useEffect(() => {
    dispatch(doGetCohortAssessments(cohortUuid));
  }, [cohortUuid, dispatch]);

  return (
    <CohortAssessmentCollectionsList
      assessmentCollections={assessmentCollections}
      parentCohortUuid={cohortUuid}
    />
  );
};

export default CohortAssessmentCollectionsListContainer;
