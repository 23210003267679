import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const assessmentBlocksSelector = createSelector(
  state => state,
  (_, assessmentUuid) => assessmentUuid,
  (redux, assessmentUuid) => {
    const assessmentBlockSelectorORM = ormCreateSelector(orm.AssessmentBlock);
    const assessmentBlocks = assessmentBlockSelectorORM(redux).filter(
      assessmentBlock => assessmentBlock.assessmentUuid === assessmentUuid
    );
    return assessmentBlocks;
  }
);

export const assessmentBlockResultGroupedByBlockSelector = createSelector(
  state => state,
  (_, studentUuid, blockUuids) => ({studentUuid, blockUuids}),
  (redux, parameters) => {
    const {
      studentUuid,
      blockUuids
    } = parameters;

    const assessmentBlockResultGroupedByBlockSelectorORM = ormCreateSelector(
      orm.AssessmentBlockResult
    );
    const assessmentBlockResults = assessmentBlockResultGroupedByBlockSelectorORM(
      redux
    ).filter(
      assessmentBlockResult => assessmentBlockResult.studentUuid === studentUuid && blockUuids.includes(assessmentBlockResult.assessmentBlockUuid)
    );
    return _.groupBy(assessmentBlockResults, 'assessmentBlockUuid');
  }
);

export const assessmentBlockResultsPerStudent = createSelector(
  state => state,
  (_, blockUuids) => blockUuids,
  (redux, blockUuids) => {

    const assessmentBlockResultGroupedByBlockSelectorORM = ormCreateSelector(
      orm.AssessmentBlockResult
    );
    const assessmentBlockResults = assessmentBlockResultGroupedByBlockSelectorORM(
      redux
    ).filter(
      assessmentBlockResult => blockUuids.includes(assessmentBlockResult.assessmentBlockUuid)
    );

    const assGroupedByStudent = _.groupBy(assessmentBlockResults, 'studentUuid');

    return _.mapValues(assGroupedByStudent, v => Object.keys(_.groupBy(v, 'assessmentBlockUuid')).length );
  }
);