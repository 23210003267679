import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InputBase from '@material-ui/core/InputBase';
import { ButtonInline, ButtonPrimary } from '@xcomp/xcomp-design-library';
import Search from '@material-ui/icons/Search';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Modal from '../../../Library/Modal/Modal';

const SearchUser = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const SearchGroup = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
  border-radius: 4px;
  height: 50px;
`;

const SearchLabel = styled.div`
  padding-right: 10px;
  color: ${props => props.theme.fontColors.darker};
`;

const SearchInput = styled(({ ...rest }) => <InputBase {...rest} />)`
  && {
    padding-left: 10px;
    border-left: 1px solid ${props => props.theme.colors.grey[300]};
    height: 50px;
  }
`;

const SearchIcon = styled(({ ...rest }) => <Search {...rest} />)`
  && {
    height: 50px;
    width: 50px;
    color: ${props => props.theme.colors.grey[300]};
    padding: 10px;
    border-radius: 0px 4px 4px 0px;
  }
`;

const SearchList = styled.div`
  height: 400px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const SearchListTitles = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
`;

const IntitutionId = styled.div`
  width: 50%;
  padding-left: 20px;
  padding-top: 15px;
  height: 50px;
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Name = styled.div`
  width: 50%;
  padding-top: 15px;
  padding-left: 20px;
  height: 50px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const SearchListUser = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  color: ${props => (props.highlight ? 'white' : 'black')};
  background ${props =>
    props.highlight ? props.theme.colors.grey[400] : 'none'};
`;

const Close = styled(ButtonInline)`
  && {
    margin-right: 30px;
  }
`;

const InvisibleLineBreak = styled.div`
  height: 4px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 900px;
`;

const Header = styled.div`
  padding: 30px;
  font-size: 1.5rem;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;

const SearchMessage = styled.div`
  height: 350px;
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
`;

export default function CohortApplicantAssign({
  openAssignApplicant,
  searchInput,
  searchUser,
  searching,
  searchMessage,
  selectedUser,
  createUserCohort,
  handleSearchChange,
  handleSearch,
  handleUserSelection,
  handleAssignApplicantClose,
  handleAddApplicant
}) {
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Modal open={openAssignApplicant} onClose={handleAssignApplicantClose}>
      <ModalBody>
        <Grid container>
          <Grid item sm={12} xs={12}>
            <Header>Assign Applicant</Header>
          </Grid>
          <Grid item sm={12} xs={12}>
            {searching ? <LinearProgress /> : <InvisibleLineBreak />}
            {createUserCohort ? <LinearProgress /> : <InvisibleLineBreak />}
          </Grid>
          <Grid item sm={12} xs={12}>
            <SearchUser>
              <SearchGroup>
                <SearchLabel>Institution ID</SearchLabel>
                <SearchInput
                  value={searchInput}
                  onChange={event => handleSearchChange(event)}
                  onKeyDown={handleKeyDown}
                />
                <SearchIcon onClick={handleSearch} />
              </SearchGroup>
            </SearchUser>
          </Grid>
          <Grid item sm={12} xs={12}>
            <SearchList>
              <SearchListTitles>
                <IntitutionId>Institution Id</IntitutionId>
                <Name>Name</Name>
              </SearchListTitles>
              {searching && (
                <SearchMessage>Searching for user . . .</SearchMessage>
              )}
              {!searching && !searchUser && (
                <SearchMessage>{searchMessage}</SearchMessage>
              )}
              {!searching && searchUser && (
                <SearchListUser
                  highlight={selectedUser}
                  onClick={() => handleUserSelection(searchUser.uuid, searchUser.email)}
                >
                  <IntitutionId>{searchUser.institutionId}</IntitutionId>
                  <Name>{`${searchUser.firstName} ${searchUser.lastName}`}</Name>
                </SearchListUser>
              )}
            </SearchList>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Actions>
              <Close onClick={handleAssignApplicantClose}>Close</Close>
              <ButtonPrimary
                disabled={!selectedUser}
                onClick={handleAddApplicant}
              >
                Add
              </ButtonPrimary>
            </Actions>
          </Grid>
        </Grid>
      </ModalBody>
    </Modal>
  );
}

CohortApplicantAssign.propTypes = {
  openAssignApplicant: PropTypes.bool,
  searchInput: PropTypes.string,
  searchUser: PropTypes.object,
  searching: PropTypes.bool,
  createUserCohort: PropTypes.bool,
  selectedUser: PropTypes.bool,
  searchMessage: PropTypes.string,
  handleAssignApplicantClose: PropTypes.func,
  handleSearchChange: PropTypes.func,
  handleSearch: PropTypes.func,
  handleUserSelection: PropTypes.func,
  handleAddApplicant: PropTypes.func
};
CohortApplicantAssign.defaultProps = {
  openAssignApplicant: false,
  searchInput: '',
  selectedUser: false,
  searching: false,
  createUserCohort: false,
  searchMessage: 'Use the search input to find applicant by institution Id.',
  searchUser: undefined,
  handleAssignApplicantClose: undefined,
  handleSearchChange: undefined,
  handleSearch: undefined,
  handleUserSelection: undefined,
  handleAddApplicant: undefined
};
