import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import TopicDefaultScoreViewAttemptPanel from './TopicDefaultScoreViewAttemptPanel';

const TopicDefaultScoreViewAttemptPanelContainer = ({
  attemptNumber,
  currentOffset,
  scoreType,
  studentScores
}) => {
  const {
    studentScoresByAttempt,
    totalRelativeByAttempt,
    totalPotentialByAttempt
  } = useMemo(
    () =>
      _.reduce(
        studentScores,
        (acc, studentScore) => {
          const currentAttempt = studentScore.attempt;
          let relativeValue = new Decimal(0);
          let potentialValue = new Decimal(0);
          const scores = acc.studentScoresByAttempt;
          if (currentAttempt === attemptNumber) {
            scores.push(studentScore);
            relativeValue = relativeValue.plus(studentScore.relValue);
            potentialValue = potentialValue.plus(studentScore.potValue);
          }
          return {
            studentScoresByAttempt: scores,
            totalRelativeByAttempt: relativeValue
              .plus(acc.totalRelativeByAttempt)
              .toFixed(2),
            totalPotentialByAttempt: potentialValue
              .plus(acc.totalPotentialByAttempt)
              .toFixed(2)
          };
        },
        {
          studentScoresByAttempt: [],
          totalRelativeByAttempt: '0.00',
          totalPotentialByAttempt: '0.00'
        }
      ),
    [attemptNumber, studentScores]
  );
  const resultCardUuid = _.get(studentScores, '[0].resultCardUuid', '');
  const attemptOrOpportunityLabel = `${scoreType.toUpperCase()} ${attemptNumber} - ${resultCardUuid}`;
  const isOpportunity = scoreType === 'Opportunity';

  return new Decimal(totalPotentialByAttempt).greaterThan(0) ? (
    <TopicDefaultScoreViewAttemptPanel
      isOpportunity={isOpportunity}
      attemptOrOpportunityLabel={attemptOrOpportunityLabel}
      currentOffset={currentOffset}
      studentScoresByAttempt={studentScoresByAttempt}
      totalRelativeByAttempt={totalRelativeByAttempt}
      totalPotentialByAttempt={totalPotentialByAttempt}
    />
  ) : null;
};

TopicDefaultScoreViewAttemptPanelContainer.propTypes = {
  attemptNumber: PropTypes.number,
  currentOffset: PropTypes.number,
  scoreType: PropTypes.string,
  studentScores: PropTypes.array
};
TopicDefaultScoreViewAttemptPanelContainer.defaultProps = {
  attemptNumber: 0,
  currentOffset: 0,
  scoreType: '',
  studentScores: []
};

export default TopicDefaultScoreViewAttemptPanelContainer;
