import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  doGetApplicantNoncognitiveReportTypes,
  doClearApplicantNoncognitiveReport,
  doPutApplicantNoncognitiveReport
} from '../../../../redux/actions/applicantReportActions';
import { doGetClassifications } from '../../../../redux/actions/classificationActions';
import { selectExamMilestones } from '../../../../redux/selectors/examMilestoneSelectors';
import { selectExamAptitude } from '../../../../redux/selectors/examAptitudeSelectors';
import { classificationsSelectorByCategorySource } from '../../../../redux/selectors/classificationSelectors';
import { applicantNoncognitiveReportTypesSelector } from '../../../../redux/selectors/applicantReportTypesSelectors';
import {
  getApplicantCognitiveReportFilters,
  sortByNestedProperty,
  sortByDecimalProperty
} from '../../../../helpers/utilities';
import {
  applicantReportTranscriptSourceOptions,
  applicantReportTranscriptScopeOptions
} from '../../../../helpers/constants';
import CohortApplicantCognitiveReport from './CohortApplicantNoncognitiveReport';

const CohortApplicantNoncognitiveReportContainer = ({
  cohortUuid,
  programUuid
}) => {
  const dispatch = useDispatch();
  const [shouldAnonymizeReport, setShouldAnonymizeReport] = useState(false);
  const [filterSelection, setFilterSelection] = useState({
    property: 'user.lastName',
    decimalProperty: false,
    reversed: false
  });
  const [transcriptSource, setTranscriptSource] = useState('');
  const [transcriptSkill, setTranscriptSkill] = useState('');
  const [transcriptSkillDisabled, setTranscriptSkillDisabled] = useState(true);
  const [transcriptSkillOptions, setTranscriptSkillOptions] = useState(
    applicantReportTranscriptScopeOptions
  );
  const [transcriptExam, setTranscriptExam] = useState('');
  const [transcriptExamDisabled, setTranscriptExamDisabled] = useState(true);
  const [transcriptExamOptions, setTranscriptExamOptions] = useState(
    applicantReportTranscriptScopeOptions
  );

  const [reportSummaryFilters, setReportSummaryFilters] = useState([]);
  const [transcriptReportTypeError, setTranscriptReportTypeError] = useState(
    false
  );

  const noncognitiveReportState = useSelector(
    state => state.applicantNoncognitiveReportState
  );

  const applicantNoncognitiveReportTypes = useSelector(
    applicantNoncognitiveReportTypesSelector,
    _.isEqual
  );

  const noncognitiveProgramSkills = useSelector(
    state =>
      classificationsSelectorByCategorySource(
        state,
        programUuid,
        'Non-Cognitive',
        'Program'
      ),
    _.isEqual
  );

  const noncognitiveMilestoneSkills = useSelector(
    state =>
      classificationsSelectorByCategorySource(
        state,
        programUuid,
        'Non-Cognitive',
        'Milestone'
      ),
    _.isEqual
  );

  const examMilestones = useSelector(selectExamMilestones);

  const examAptitudes = useSelector(selectExamAptitude);

  const isLoadingNoncognitiveReport =
    noncognitiveReportState?.loadingNoncognitiveReport;

  const noncognitiveReportMap = _.keyBy(
    applicantNoncognitiveReportTypes,
    'name'
  );

  const getTranscriptExamOptions = classificationUuid => {
    const exams =
      transcriptSource === 'Program' ? examAptitudes : examMilestones;
    const val = exams.reduce((options, exam) => {
      if (exam.classificationUuid === classificationUuid) {
        options.push({
          value: exam.uuid,
          label: exam.examName
        });
      }
      return options;
    }, []);
    return val;
  };

  const handleSelectTranscriptSource = event => {
    const { value } = event.target;
    dispatch(doClearApplicantNoncognitiveReport());
    setTranscriptSource(value);
    setTranscriptReportTypeError(false);
    if (value === 'Cumulative') {
      setTranscriptSkill('');
      setTranscriptSkillDisabled(true);
    }
    if (value === 'Program') {
      setTranscriptSkill('Cumulative');
      setTranscriptSkillDisabled(false);
    }
    if (value === 'Milestone') {
      setTranscriptSkill('Cumulative');
      setTranscriptSkillDisabled(false);
    }
  };

  const handleSelectTranscriptSkill = event => {
    const { value } = event.target;
    dispatch(doClearApplicantNoncognitiveReport());
    if (
      (transcriptSource === 'Program' &&
        noncognitiveProgramSkills.some(subject => subject.uuid === value)) ||
      (transcriptSource === 'Milestone' &&
        noncognitiveMilestoneSkills.some(subject => subject.uuid === value))
    ) {
      const examOptions = getTranscriptExamOptions(value);
      setTranscriptExamOptions(
        applicantReportTranscriptScopeOptions.concat(examOptions)
      );
      setTranscriptExam('Cumulative');
    }
    setTranscriptSkill(value);
    setReportSummaryFilters([]);
  };

  const handleSelectTranscriptExam = event => {
    const { value } = event.target;
    dispatch(doClearApplicantNoncognitiveReport());
    setTranscriptExam(value);
    setReportSummaryFilters([]);
  };

  const getSelectedReportType = (
    transcriptSource,
    transcriptSkill,
    transcriptExam
  ) => {
    if (transcriptSource === 'Cumulative') {
      return noncognitiveReportMap['Non-Cognitive Cumulative'];
    }
    if (
      transcriptSource === 'Program' &&
      transcriptSkill !== 'Cumulative' &&
      transcriptExam === 'Cumulative'
    ) {
      return noncognitiveReportMap['Program Skill'];
    }
    if (
      transcriptSource === 'Program' &&
      transcriptSkill !== 'Cumulative' &&
      transcriptExam !== 'Cumulative'
    ) {
      return noncognitiveReportMap['Program Skill Exam'];
    }
    if (transcriptSource === 'Program' && transcriptSkill === 'Cumulative') {
      return noncognitiveReportMap['Program Cumulative'];
    }
    if (
      transcriptSource === 'Milestone' &&
      transcriptSkill !== 'Cumulative' &&
      transcriptExam === 'Cumulative'
    ) {
      return noncognitiveReportMap['Milestone Skill'];
    }
    if (
      transcriptSource === 'Milestone' &&
      transcriptSkill !== 'Cumulative' &&
      transcriptExam !== 'Cumulative'
    ) {
      return noncognitiveReportMap['Milestone Skill Exam'];
    }
    if (transcriptSource === 'Milestone' && transcriptSkill === 'Cumulative') {
      return noncognitiveReportMap['Milestone Cumulative'];
    }
  };

  const handleApplyReportType = () => {
    if (transcriptSource === '') {
      setTranscriptReportTypeError(true);
    } else {
      const selectedReportType = getSelectedReportType(
        transcriptSource,
        transcriptSkill,
        transcriptExam
      );
      const classificationUuid =
        transcriptSource === 'Cumulative' || transcriptSkill === 'Cumulative'
          ? null
          : transcriptSkill;
      const examMilestoneUuid =
        transcriptSource === 'Cumulative' ||
        transcriptSource === 'Program' ||
        transcriptSkill === 'Cumulative' ||
        transcriptExam === 'Cumulative'
          ? null
          : transcriptExam;
      const examAptitudeUuid =
        transcriptSource === 'Cumulative' ||
        transcriptSource === 'Milestone' ||
        transcriptSkill === 'Cumulative' ||
        transcriptExam === 'Cumulative'
          ? null
          : transcriptExam;
      dispatch(doClearApplicantNoncognitiveReport());
      dispatch(
        doPutApplicantNoncognitiveReport(
          cohortUuid,
          selectedReportType.uuid,
          classificationUuid,
          examMilestoneUuid,
          examAptitudeUuid
        )
      );
    }

    const filterOptions = getApplicantCognitiveReportFilters();
    setReportSummaryFilters(filterOptions);
  };

  const handleApplyFilterSelection = (
    selected,
    isDecimalProperty,
    isReversed
  ) => {
    setFilterSelection({
      property: selected,
      decimalProperty: isDecimalProperty,
      reversed: isReversed
    });
  };

  const handleToggleShouldAnonymizeReport = event => {
    const { checked } = event.target;
    setShouldAnonymizeReport(checked);
  };

  useEffect(() => {
    dispatch(doGetApplicantNoncognitiveReportTypes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(doClearApplicantNoncognitiveReport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (programUuid) dispatch(doGetClassifications(programUuid));
  }, [dispatch, programUuid]);

  useEffect(() => {
    if (transcriptSource === 'Milestone' && noncognitiveMilestoneSkills) {
      setTranscriptSkillOptions(
        applicantReportTranscriptScopeOptions.concat(
          noncognitiveMilestoneSkills.map(noncognitiveMilestoneSkill => ({
            value: noncognitiveMilestoneSkill.uuid,
            label: noncognitiveMilestoneSkill.scope
          }))
        )
      );
    }
    if (transcriptSource === 'Program' && noncognitiveProgramSkills) {
      setTranscriptSkillOptions(
        applicantReportTranscriptScopeOptions.concat(
          noncognitiveProgramSkills.map(noncognitiveProgramSkill => ({
            value: noncognitiveProgramSkill.uuid,
            label: noncognitiveProgramSkill.scope
          }))
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptSource]);

  useEffect(() => {
    if (
      (transcriptSource === 'Milestone' || transcriptSource === 'Program') &&
      transcriptSkill !== 'Cumulative'
    ) {
      setTranscriptExamDisabled(false);
    } else {
      setTranscriptExamDisabled(true);
      setTranscriptExam('');
    }
  }, [transcriptSource, transcriptSkill]);

  const filteredReportData = filterSelection.decimalProperty
    ? noncognitiveReportState?.noncognitiveReport.sort((a, b) =>
        filterSelection.reversed
          ? sortByDecimalProperty(b, a, filterSelection.property)
          : sortByDecimalProperty(a, b, filterSelection.property)
      )
    : noncognitiveReportState?.noncognitiveReport.sort((a, b) =>
        filterSelection.reversed
          ? sortByNestedProperty(b, a, filterSelection.property)
          : sortByNestedProperty(a, b, filterSelection.property)
      );
  return (
    <CohortApplicantCognitiveReport
      isLoadingNoncognitiveReport={isLoadingNoncognitiveReport}
      onApplyFilterSelection={handleApplyFilterSelection}
      onApplyReportType={handleApplyReportType}
      onSelectTranscriptExam={handleSelectTranscriptExam}
      onSelectTranscriptSource={handleSelectTranscriptSource}
      onSelectTranscriptSkill={handleSelectTranscriptSkill}
      onToggleShouldAnonymizeReport={handleToggleShouldAnonymizeReport}
      reportData={filteredReportData}
      reportHeaders={noncognitiveReportState?.reportHeaders}
      reportSummary={noncognitiveReportState?.reportSummary}
      reportSummaryFilters={reportSummaryFilters}
      reportSummaryHeaders={noncognitiveReportState?.reportSummaryHeaders}
      shouldAnonymizeReport={shouldAnonymizeReport}
      transcriptReportTypeError={transcriptReportTypeError}
      transcriptExam={transcriptExam}
      transcriptExamDisabled={transcriptExamDisabled}
      transcriptExamOptions={transcriptExamOptions}
      transcriptSkill={transcriptSkill}
      transcriptSkillDisabled={transcriptSkillDisabled}
      transcriptSkillOptions={transcriptSkillOptions}
      transcriptSource={transcriptSource}
      transcriptSourceOptions={applicantReportTranscriptSourceOptions}
    />
  );
};

CohortApplicantNoncognitiveReportContainer.propTypes = {
  cohortUuid: PropTypes.string.isRequired,
  programUuid: PropTypes.string.isRequired
};

CohortApplicantNoncognitiveReportContainer.defaultProps = {};

export default CohortApplicantNoncognitiveReportContainer;
