import _ from 'lodash';

import {
  GET_USER_ACTIVE_SUCCESS,
  SET_SELECTED_INSTITUTION_ROLE_FEATURE,
  SET_SELECTED_SCHOOL_ROLE_FEATURE,
  SET_SELECTED_DEPARTMENT_ROLE_FEATURE,
  SET_SELECTED_PROGRAM_ROLE_FEATURE,
  SET_CURRENT_ROLE,
  SET_SELECTED_HOME,
  PUT_INSTITUTION_SUCCESS
} from '../actions-type';

const DEFAULT_PRIMARY_COLOR = '#00939f';
const DEFAULT_SECONDARY_COLOR = '#9F0C00';

export const INITIAL_STATE = {
  activeUserUuid: '',
  institutionUuid: '',
  selectedInstitutionUuid: '',
  selectedInstitution: '',
  selectedSchoolUuid: '',
  selectedSchool: '',
  selectedDepartmentUuid: '',
  selectedDepartment: '',
  selectedProgramUuid: '',
  selectedProgram: '',
  selectedRole: '',
  selectedFeature: '',
  selectedFeatureUuid: '',
  userRoles: {},
  currentRole: {},
  primary: DEFAULT_PRIMARY_COLOR,
  secondary: DEFAULT_SECONDARY_COLOR,
  institutionName: ''
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_ACTIVE_SUCCESS: {
      const { profile, roles, institution } = action.payload;
      const userRoles = {};

      if (roles?.institution_admin?.length > 0) {
        userRoles.institution = [];
        roles.institution_admin.forEach(
          ({ uuid, role, email, institutionUuid }) => {
            const newInstituitionAdminRole = {
              uuid,
              role,
              email,
              institutionUuid
            };
            userRoles.institution.push(newInstituitionAdminRole);
          }
        );
      }

      if (roles?.school_admin?.length > 0) {
        userRoles.school = [];
        roles.school_admin.forEach(({ uuid, role, email, schoolUuid }) => {
          const newSchoolAdminRole = {
            uuid,
            role,
            email,
            schoolUuid
          };
          userRoles.school.push(newSchoolAdminRole);
        });
      }

      if (roles?.department_staff?.length > 0) {
        if (!userRoles?.department) {
          userRoles.department = [];
        }

        roles.department_staff.forEach(
          ({ uuid, role, email, departmentUuid }) => {
            const newDepartmentRole = {
              uuid,
              role,
              email,
              departmentUuid
            };
            userRoles.department.push(newDepartmentRole);
          }
        );
      }

      if (roles?.department_faculty?.length > 0) {
        if (!userRoles?.department) {
          userRoles.department = [];
        }
        roles.department_faculty.forEach(
          ({ uuid, role, email, departmentUuid }) => {
            const newDepartmentRole = {
              uuid,
              role,
              email,
              departmentUuid
            };
            userRoles.department.push(newDepartmentRole);
          }
        );
      }

      if (roles?.program_admin?.length > 0) {
        if (!userRoles?.program) {
          userRoles.program = [];
        }
        roles.program_admin.forEach(({ uuid, role, email, programUuid }) => {
          const newProgramRole = {
            uuid,
            role,
            email,
            programUuid
          };
          userRoles.program.push(newProgramRole);
        });
      }

      if (roles?.program_staff?.length > 0) {
        if (!userRoles?.program) {
          userRoles.program = [];
        }
        roles.program_staff.forEach(({ uuid, role, email, programUuid }) => {
          const newProgramRole = {
            uuid,
            role,
            email,
            programUuid
          };
          userRoles.program.push(newProgramRole);
        });
      }

      if (roles?.program_faculty?.length > 0) {
        if (!userRoles?.program) {
          userRoles.program = [];
        }
        roles.program_faculty.forEach(({ uuid, role, email, programUuid }) => {
          const newProgramRole = {
            uuid,
            role,
            email,
            programUuid
          };
          userRoles.program.push(newProgramRole);
        });
      }

      return {
        ...state,
        userRoles,
        activeUserUuid: profile?.uuid,
        institutionUuid: _.get(institution, 'uuid', undefined),
        primary: _.get(institution, 'primaryColor', state.primary),
        secondary: _.get(institution, 'secondaryColor', state.secondary),
        institutionName: _.get(institution, 'institutionName', ''),
        institutionLogo: _.get(institution, 'logo', '')
      };
    }
    case PUT_INSTITUTION_SUCCESS: {
      const institution = { ...action.payload.institution };

      return {
        ...state,
        institutionLogo: institution.logo || state.institutionLogo,
      }
    }
    case SET_CURRENT_ROLE: {
      const { role } = action.payload;

      const selectedInstitutionUuid =
        role.institutionUuid || state.selectedInstitutionUuid;

      const selectedSchoolUuid = role.schoolUuid || state.selectedSchoolUuid;

      const selectedDepartmentUuid =
        role.departmentUuid || state.selectedDepartmentUuid;

      const selectedProgramUuid = role.programUuid || state.selectedProgramUuid;

      return {
        ...state,
        selectedRole: action.payload.role.role,
        currentRole: action.payload.role,
        selectedInstitutionUuid,
        selectedSchoolUuid,
        selectedDepartmentUuid,
        selectedProgramUuid
      };
    }
    case SET_SELECTED_INSTITUTION_ROLE_FEATURE: {
      const { institution, feature, featureId, role } = action.payload;

      const currentRole = state.userRoles.institution.find(
        role => role.institutionUuid === institution.uuid
      );

      return {
        ...state,
        selectedInstitutionUuid: institution.uuid,
        selectedInstitution: institution.institutionName,
        selectedSchoolUuid: '',
        selectedSchool: '',
        selectedDepartmentUuid: '',
        selectedDepartment: '',
        selectedProgramUuid: '',
        selectedProgram: '',
        selectedFeature: feature,
        selectedFeatureUuid: featureId,
        selectedRole: role,
        currentRole
      };
    }
    case SET_SELECTED_SCHOOL_ROLE_FEATURE: {
      const { school, feature, featureId, role } = action.payload;

      const currentRole = state.userRoles.school.find(
        role => role.schoolUuid === school.uuid
      );

      return {
        ...state,
        selectedInstitutionUuid: '',
        selectedInstitution: '',
        selectedSchoolUuid: school.uuid,
        selectedSchool: school.schoolName,
        selectedDepartmentUuid: '',
        selectedDepartment: '',
        selectedProgramUuid: '',
        selectedProgram: '',
        selectedFeature: feature,
        selectedFeatureUuid: featureId,
        selectedRole: role,
        currentRole
      };
    }
    case SET_SELECTED_DEPARTMENT_ROLE_FEATURE: {
      const { school, department, feature, featureId, role } = action.payload;

      const currentRole = state.userRoles.department.find(
        role => role.departmentUuid === department.uuid
      );

      return {
        ...state,
        selectedInstitutionUuid: '',
        selectedInstitution: '',
        selectedSchoolUuid: school.uuid,
        selectedSchool: school.schoolName,
        selectedDepartmentUuid: department.uuid,
        selectedDepartment: department.name,
        selectedProgramUuid: '',
        selectedProgram: '',
        selectedFeature: feature,
        selectedFeatureUuid: featureId,
        selectedRole: role,
        currentRole
      };
    }
    case SET_SELECTED_PROGRAM_ROLE_FEATURE: {
      const { school, program, feature, featureId, role } = action.payload;

      const currentRole = state.userRoles.program.find(
        r => r.programUuid === program.uuid && r.role === role
      );

      return {
        ...state,
        selectedInstitutionUuid: '',
        selectedInstitution: '',
        selectedSchoolUuid: school.uuid,
        selectedSchool: school.schoolName,
        selectedDepartmentUuid: '',
        selectedDepartment: '',
        selectedProgramUuid: program.uuid,
        selectedProgram: program.programName,
        selectedFeature: feature,
        selectedFeatureUuid: featureId,
        selectedRole: role,
        currentRole
      };
    }
    case SET_SELECTED_HOME: {
      return {
        ...state,
        selectedFeature: '',
        selectedFeatureUuid: ''
      };
    }
    default:
      return state;
  }
};

export default userReducer;
