import _ from 'lodash';
import { descriptionMaxLength } from '../constants';

const validateRequired = textField => {
  if (!textField || textField === '' || textField === 0) {
    return {
      invalid: true,
      message: 'Required'
    };
  }
  return false;
};

const validateCourseTitle = textField => {
  const requiredError = validateRequired(textField);
  if (requiredError) {
    return requiredError;
  }

  if (_.size(textField) > 100) {
    return {
      invalid: true,
      message: 'Course title must be less than 100 characters'
    };
  }
  return { invalid: false };
};

const validateCourseHours = textField => {
  if (!textField || textField === '' || Number(textField) === 0) {
    return {
      invalid: true,
      message: 'Must be at least 1 hour'
    };
  }

  const splitArrays = textField.toString().split('.');
  const afterDecimal = splitArrays[1];
  if (_.size(afterDecimal) > 2) {
    return {
      invalid: true,
      message: 'Precision cannot exceed 2 decimal places'
    };
  }

  return { invalid: false };
};

const validateCourseDescription = textField => {
  if (!textField || textField === '' || textField === 0) {
    return {
      invalid: true,
      message: 'Please enter a description'
    };
  }

  const contentLength = textField?.match(/\b\w+\b/gm)?.length || 0;

  if (contentLength < 10) {
    return {
      invalid: true,
      message: 'Must be at least 10 words long'
    };
  }
  if (contentLength > descriptionMaxLength) {
    return {
      invalid: true,
      message: `Must not exceed ${descriptionMaxLength} words`
    };
  }
  return { invalid: false };
};

const validateCourseAliases = alias => {
  const noBlankFields = alias.filter(alias => alias.departmentUuid === '');

  if (noBlankFields.length > 0) {
    return {
      invalid: true
    };
  }

  return {
    invalid: false
  };
};

const validateCourseAliasCourseMaster = (selected, alias) => {
  if (selected === '') {
    return {
      invalid: true,
      message: 'Select course'
    };
  }

  const duplicateCheck = alias.filter(
    a => a.courseMasterAliasUuid === selected
  );

  if (duplicateCheck.length > 1) {
    return {
      invalid: true,
      message: 'Duplicate Course'
    };
  }

  return {
    invalid: false
  };
};

const validateCourseAliasesCourseMaster = alias => {
  const noBlankFields = alias.filter(
    alias => alias.courseMasterAliasUuid === ''
  );

  if (noBlankFields.length > 0) {
    return {
      invalid: true
    };
  }

  const duplicateCheck =
    _.uniqBy(alias, 'courseMasterAliasUuid').length !== alias.length;

  if (duplicateCheck) {
    return {
      invalid: true,
      message: 'Contains duplicate alias'
    };
  }

  return {
    invalid: false
  };
};

export {
  validateCourseTitle,
  validateCourseHours,
  validateCourseDescription,
  validateCourseAliases,
  validateCourseAliasCourseMaster,
  validateCourseAliasesCourseMaster
};
