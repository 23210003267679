import React from 'react';
import PropTypes from 'prop-types';
import StudentAssessmentGradeData from './StudentAssessmentGradeData';
import { PointsTd, TableDataText } from '../CollectionReportSC';

const StudentCollectionGradeData = ({
  collection,
  evenStudent,
  competencyGridMap,
  collectionStudentMap,
  isExpanded,
  studentUuid,
  showScores,
}) => {
  const hasScoreData =
    collectionStudentMap && Object.keys(collectionStudentMap).length > 0;

  const thisCollectionScore = hasScoreData
    ? collectionStudentMap[collection.uuid][studentUuid].totalCollectionScore
    : null;

  const collectionScoreDisplay =
    thisCollectionScore && thisCollectionScore !== '0.00'
      ? thisCollectionScore
      : null;

  return (
    <React.Fragment key={collection.uuid}>
      {!isExpanded ?
        <PointsTd greyOne={!evenStudent} isExpanded={isExpanded}>
          <TableDataText>{collectionScoreDisplay || '--'}</TableDataText>
        </PointsTd>
        :
        collection.competencies.length > 0 &&
        collection.competencies.map(competency => {
          return (
            <StudentAssessmentGradeData
              key={competency.uuid}
              competency={competency}
              competencyGridMap={competencyGridMap}
              studentUuid={studentUuid}
              evenStudent={evenStudent}
              isExpanded={isExpanded}
              showScores={showScores}
            />
          );
        })
      }
    </React.Fragment>
  );
};

StudentCollectionGradeData.propTypes = {
  collection: PropTypes.object,
  competencyGridMap: PropTypes.object,
  collectionStudentMap: PropTypes.object,
  evenStudent: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  showScores: PropTypes.bool.isRequired,
  studentUuid: PropTypes.string.isRequired
};

StudentCollectionGradeData.defaultProps = {
  collection: { competencies: [] },
  competencyGridMap: {},
  collectionStudentMap: {}
};

export default StudentCollectionGradeData;
