import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import Competencies from './Competencies';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .accordion_row_label {
    width: 82.25%;
  }

  .column_one {
    width: 17.75%;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }

  .column_one > span {
    width: 100%;
  }
`;

const Label = styled.div`
  font-size: 16px;
  color: ${props => props.theme.fontColors.darker};
`;

const Loading = styled(LinearProgress)`
  && {
    width: 100%;
  }
`;

export default function CompetencyCollection({
  fetchingCompetencyCollectionsAndScores,
  collections
}) {
  return (
    <>
      {fetchingCompetencyCollectionsAndScores && <Loading />}
      {collections?.map(collection => (
        <Accordion
          key={collection.uuid}
          withBottomBorder={true}
          expandWidth="66px"
          expandcontent="66px"
          height="80px"
          label={<Label>{collection?.name}</Label>}
        >
          <Competencies competencyCollectionUuid={collection.uuid} />
        </Accordion>
      ))}
    </>
  );
}

CompetencyCollection.propTypes = {
  collections: PropTypes.array,
  fetchingCompetencyCollectionsAndScores: PropTypes.bool
};

CompetencyCollection.defaultProps = {
  collections: undefined,
  fetchingCompetencyCollectionsAndScores: false
};
