import fetch from '../helpers/fetch';

const putAssessmentGradingTechnique = ({
  assessmentUuid,
  ...gradingTechnique
}) =>
  fetch(
    `/api/assessment/${assessmentUuid}/grading-technique/${gradingTechnique.uuid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(gradingTechnique)
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putGradeCollectionGradingTechnique = ({
  gradeCollectionUuid,
  ...gradingTechnique
}) =>
  fetch(
    `/api/grade-collection/${gradeCollectionUuid}/grading-technique/${gradingTechnique.uuid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(gradingTechnique)
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putPartGradeSettingsGradingTechnique = ({
  partGradeSettingsUuid,
  ...gradingTechniquePayload
}) =>
  fetch(`/api/part-grade-settings/${partGradeSettingsUuid}/grading-technique`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(gradingTechniquePayload)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  putAssessmentGradingTechnique,
  putGradeCollectionGradingTechnique,
  putPartGradeSettingsGradingTechnique
};
