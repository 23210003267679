import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const CellExpand = ({row}) => {

  const handleExpand = row.getToggleExpandedHandler();

  if (!row.getCanExpand()) {
    return null;
  }

  if (row.getIsExpanded()) {
    return (
      <div>
        <KeyboardArrowDownIcon
          onClick={handleExpand}
          style={{cursor: 'pointer'}}
        />
      </div>
    );
  }

  return (
    <div>
      <KeyboardArrowRightIcon
        onClick={handleExpand}
        style={{cursor: 'pointer'}}
      />
    </div>
  );
};

CellExpand.propTypes = {
  row: PropTypes.object.isRequired,
};

export default CellExpand;