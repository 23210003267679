import React, { useReducer, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { partGradeCollectionSelector } from '../../../../../../redux/selectors/partGradeSettingsSelectors';
import {
  allFieldsAreValid,
  validateDecimalStringField,
  validateSelectField
} from '../../../../../../helpers/validation/validateGeneric';
import { doPutGradeCollectionGradingTechnique } from '../../../../../../redux/actions/gradingTechniqueActions';
import { doClearCreated } from '../../../../../../redux/actions/courseCollectionActions';
import EditGradeModifiers from './EditGradeModifiers';
import {
  gradingTechniqueReducer,
  initialGradingTechniqueState
} from '../../../../../Library/GradingTechniqueForm/gradingTechniqueState';
import { doGeneralErrorNotification } from '../../../../../../redux/actions/notificationActions';

export default function EditGradeModifiersContainer({
  isUnsavedChangesModalOpen,
  unsavedChanges,
  setUnsavedChange,
  removeUnsavedChange
}) {
  const dispatch = useDispatch();

  const { gradeCollectionUuid } = useParams();

  const [state, gradingTechniqueDispatch] = useReducer(
    gradingTechniqueReducer,
    initialGradingTechniqueState
  );

  const [low_grades_dropped, setLowGradesDropped] = useState(0);
  const [allowDroppedAssessments, setAllowDroppedAssessments] = useState(false);

  const existingGradeCollection = useSelector(
    state => partGradeCollectionSelector(state, gradeCollectionUuid),
    _.isEqual
  );
  const successfullyUpdatedGradingTechnique = useSelector(
    state => state.formSuccessState.successfullyUpdatedGradingTechnique
  );

  const currentGradingTechnique = _.get(
    existingGradeCollection,
    'grading_technique',
    {}
  );

  const currentAssessments = _.get(
    existingGradeCollection,
    'collection_assessments',
    []
  );

  const distributeWeightEqually = _.get(
    existingGradeCollection,
    'distributeWeightEqually',
    false
  );

  useEffect(() => {
    if (successfullyUpdatedGradingTechnique) {
      dispatch(doClearCreated());
      removeUnsavedChange('collectionGradeModifiers');
    }
  }, [dispatch, removeUnsavedChange, successfullyUpdatedGradingTechnique]);

  const onGeneralErrorNotification = errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage));

  const setExistingState = ({
    low_grades_dropped,
    uuid,
    gradingTechnique,
    maximumRevusInput,
    maximumMethodCalculated,
    calculatedHighScore,
    lowScoreInput,
    lowScoreCalculated,
    calculatedLowScore,
    curvedHighGrade,
    curvedFailingGrade,
    scaledHighScore,
    scaledLowScore
  }) => {
    setLowGradesDropped(low_grades_dropped);
    if (low_grades_dropped && Number(low_grades_dropped) > 0) {
      setAllowDroppedAssessments(true);
    }
    gradingTechniqueDispatch({
      type: 'setExistingGradingTechnique',
      payload: {
        uuid,
        gradingTechnique,
        maximumRevusInput,
        maximumMethodCalculated,
        calculatedHighScore,
        lowScoreInput,
        lowScoreCalculated,
        calculatedLowScore,
        curvedHighGrade,
        curvedFailingGrade,
        scaledHighScore,
        scaledLowScore
      }
    });
  };

  useEffect(() => {
    if (currentGradingTechnique.uuid) {
      setExistingState({
        low_grades_dropped: existingGradeCollection?.low_grades_dropped,
        uuid: currentGradingTechnique.uuid,
        gradingTechnique: currentGradingTechnique.gradingTechnique,
        maximumRevusInput: currentGradingTechnique.maximumRevus,
        maximumMethodCalculated:
          currentGradingTechnique.maximumMethodCalculated,
        lowScoreInput: currentGradingTechnique.lowScore,
        lowScoreCalculated: currentGradingTechnique.lowScoreCalculated,
        calculatedLowScore: currentGradingTechnique.calculatedLowScore,
        calculatedHighScore: currentGradingTechnique.calculatedHighScore,
        curvedHighGrade: currentGradingTechnique.curvedHighGrade,
        curvedFailingGrade: currentGradingTechnique.curvedFailingGrade,
        scaledHighScore: currentGradingTechnique.scaledHighScore,
        scaledLowScore: currentGradingTechnique.scaledLowScore
      });
    }
  }, [
    existingGradeCollection,
    currentGradingTechnique.uuid,
    currentGradingTechnique.gradingTechnique,
    currentGradingTechnique.maximumRevus,
    currentGradingTechnique.maximumMethodCalculated,
    currentGradingTechnique.lowScore,
    currentGradingTechnique.lowScoreCalculated,
    currentGradingTechnique.curvedHighGrade,
    currentGradingTechnique.curvedFailingGrade,
    currentGradingTechnique.scaledHighScore,
    currentGradingTechnique.scaledLowScore,
    currentGradingTechnique.calculatedLowScore,
    currentGradingTechnique.calculatedHighScore
  ]);

  const {
    uuid,
    gradingTechnique,
    maximumRevusInput,
    maximumMethodCalculated,
    calculatedHighScore,
    lowScoreInput,
    calculatedLowScore,
    lowScoreCalculated,
    curvedHighGrade,
    curvedFailingGrade,
    scaledHighScore,
    scaledLowScore,
    showValidationErrors,
    selectedRadio
  } = state;

  // handlers
  const handleChangeRadio = event => {
    const selectedOption = event.target.value;
    setUnsavedChange('collectionGradeModifiers');
    switch (selectedOption) {
      case 'dividedAssessmentPoints': {
        gradingTechniqueDispatch({
          type: 'setDividedAssessmentPoints',
          payload: {
            selectedRadio: selectedOption
          }
        });
        break;
      }
      case 'dividedCohortHigh': {
        gradingTechniqueDispatch({
          type: 'setDividedCohortHigh',
          payload: {
            selectedRadio: selectedOption
          }
        });
        break;
      }
      case 'curved': {
        gradingTechniqueDispatch({
          type: 'setCurved',
          payload: {
            selectedRadio: selectedOption
          }
        });
        break;
      }
      case 'scaled': {
        gradingTechniqueDispatch({
          type: 'setScaled',
          payload: {
            selectedRadio: selectedOption
          }
        });
        break;
      }
      default:
        console.log('error: ', selectedOption);
    }
  };

  const handleGradingTechniqueChange = event => {
    event.stopPropagation();
    const { name, value, checked } = event.target;
    setUnsavedChange('collectionGradeModifiers');
    switch (name) {
      case 'maximumRevusInput':
        gradingTechniqueDispatch({
          type: 'setMaximumRevusInput',
          payload: {
            maximumRevusInput: value
          }
        });
        break;
      case 'maximumMethodCalculated':
        gradingTechniqueDispatch({
          type: 'setMaximumMethodCalculated',
          payload: {
            maximumMethodCalculated: checked
          }
        });
        break;
      case 'lowScoreInput':
        gradingTechniqueDispatch({
          type: 'setLowScoreInput',
          payload: {
            lowScoreInput: value
          }
        });
        break;
      case 'lowScoreCalculated':
        gradingTechniqueDispatch({
          type: 'setLowScoreCalculated',
          payload: {
            lowScoreCalculated: checked
          }
        });
        break;
      case 'curvedHighGrade':
        gradingTechniqueDispatch({
          type: 'setCurvedHighGrade',
          payload: {
            curvedHighGrade: value
          }
        });
        break;
      case 'curvedFailingGrade':
        gradingTechniqueDispatch({
          type: 'setCurvedFailingGrade',
          payload: {
            curvedFailingGrade: value
          }
        });
        break;
      case 'scaledHighScore':
        gradingTechniqueDispatch({
          type: 'setScaledHighScore',
          payload: {
            scaledHighScore: value
          }
        });
        break;
      case 'scaledLowScore':
        gradingTechniqueDispatch({
          type: 'setScaledLowScore',
          payload: {
            scaledLowScore: value
          }
        });
        break;
      default:
        break;
    }
  };

  const handleLowGradesDroppedChange = e => {
    setLowGradesDropped(e.target.value);
    setUnsavedChange('collectionGradeModifiers');
  };

  const handleAllowDroppedAssessments = (e, allowDroppedAssessments) => {
    setAllowDroppedAssessments(allowDroppedAssessments);
    setLowGradesDropped('');
    setUnsavedChange('collectionGradeModifiers');
  };

  const setValidationErrors = ({
    curvedFailingGrade,
    gradingTechnique,
    maximumMethodCalculated,
    maximumRevusInput,
    lowScoreInput,
    lowScoreCalculated,
    scaledHighScore,
    scaledLowScore,
    low_grades_dropped,
    allowDroppedAssessments,
    unsavedChanges,
    currentAssessments
  }) => {
    const errorObject = {};

    if (unsavedChanges.collectionAssessments) {
      const errorMessage =
        'There are unsaved changes to the collection above. Apply those changes and try again.';
      onGeneralErrorNotification(errorMessage);
      errorObject.unsavedCollectionChanges = {
        invalid: true,
        message: errorMessage
      };
      return errorObject;
    }

    switch (gradingTechnique) {
      case 'points': {
        onGeneralErrorNotification(
          'cannot set collection grading technique to points only'
        );
        break;
      }
      case 'dividedCohortHigh':
      case 'dividedAssessmentPoints': {
        errorObject.maximumRevusError = maximumMethodCalculated
          ? { invalid: false }
          : validateDecimalStringField(maximumRevusInput);
        break;
      }
      case 'curved': {
        errorObject.curvedFailingGradeError =
          validateDecimalStringField(curvedFailingGrade);
        break;
      }
      case 'scaled': {
        errorObject.scaledHighScoreError =
          validateDecimalStringField(scaledHighScore);
        errorObject.lowScoreError = lowScoreCalculated
          ? { invalid: false }
          : validateDecimalStringField(lowScoreInput);
        errorObject.scaledLowScoreError =
          validateDecimalStringField(scaledLowScore);
        break;
      }
      default:
        break;
    }

    errorObject.lowGradesDroppedError = allowDroppedAssessments
      ? validateSelectField(String(low_grades_dropped))
      : { invalid: false };

    if (allowDroppedAssessments && currentAssessments?.length === 1) {
      errorObject.lowGradesDroppedError = {
        invalid: true,
        message:
          'You cannot allow dropped assessments in collections with only one assessment'
      };
      onGeneralErrorNotification(errorObject.lowGradesDroppedError.message);
    }
    if (allowDroppedAssessments && !distributeWeightEqually) {
      errorObject.lowGradesDroppedError = {
        invalid: true,
        message:
          'Distribute weights equally must be set for the collection to allow dropped assessments'
      };
      onGeneralErrorNotification(errorObject.lowGradesDroppedError.message);
    }

    return errorObject;
  };

  const buildGradingTechniquePayload = (
    uuid,
    gradingTechnique,
    maximumRevus,
    maximumMethodCalculated,
    lowScore,
    lowScoreCalculated,
    curvedFailingGrade,
    scaledHighScore,
    scaledLowScore,
    low_grades_dropped,
    allowDroppedAssessments
  ) => {
    let payload = {};
    switch (gradingTechnique) {
      case 'dividedCohortHigh':
      case 'dividedAssessmentPoints': {
        payload = {
          ...initialGradingTechniqueState,
          uuid,
          gradingTechnique,
          maximumRevus: maximumMethodCalculated ? null : maximumRevus,
          maximumMethodCalculated,
          low_grades_dropped: allowDroppedAssessments
            ? low_grades_dropped
            : null
        };
        break;
      }
      case 'curved': {
        payload = {
          ...initialGradingTechniqueState,
          uuid,
          curvedHighGrade: '100',
          curvedFailingGrade,
          gradingTechnique,
          maximumMethodCalculated: true,
          lowScoreCalculated: true,
          low_grades_dropped: allowDroppedAssessments
            ? low_grades_dropped
            : null
        };
        break;
      }
      case 'scaled': {
        payload = {
          ...initialGradingTechniqueState,
          uuid,
          gradingTechnique,
          maximumRevus: maximumMethodCalculated ? null : maximumRevus,
          maximumMethodCalculated,
          lowScore: lowScoreCalculated ? null : lowScore,
          lowScoreCalculated,
          scaledHighScore,
          scaledLowScore,
          low_grades_dropped: allowDroppedAssessments
            ? low_grades_dropped
            : null
        };
        break;
      }
      default:
        break;
    }
    return payload;
  };

  const handleSubmitGradingTechnique = () => {
    const newValidationErrors = setValidationErrors({
      curvedHighGrade,
      curvedFailingGrade,
      gradingTechnique,
      maximumRevusInput,
      maximumMethodCalculated,
      lowScoreInput,
      lowScoreCalculated,
      scaledHighScore,
      scaledLowScore,
      low_grades_dropped,
      allowDroppedAssessments,
      unsavedChanges,
      currentAssessments
    });

    if (allFieldsAreValid(newValidationErrors)) {
      const gradingTechniquePayload = buildGradingTechniquePayload(
        uuid,
        gradingTechnique,
        maximumRevusInput,
        maximumMethodCalculated,
        lowScoreInput,
        lowScoreCalculated,
        curvedFailingGrade,
        scaledHighScore,
        scaledLowScore,
        low_grades_dropped,
        allowDroppedAssessments
      );
      gradingTechniqueDispatch({
        type: 'setShowValidationErrors',
        payload: { showValidationErrors: false }
      });
      dispatch(
        doPutGradeCollectionGradingTechnique(
          { ...gradingTechniquePayload },
          gradeCollectionUuid
        )
      );
    } else {
      gradingTechniqueDispatch({
        type: 'setShowValidationErrors',
        payload: { showValidationErrors: true }
      });
    }
  };

  return (
    <EditGradeModifiers
      allowCohortHigh
      droppedOptions={currentAssessments}
      distributeWeightEqually={distributeWeightEqually}
      formTitle="Collection Grade Modification"
      allowDroppedLabel="Allow Dropped Assessments"
      allowDropped={allowDroppedAssessments}
      handleAllowDropped={handleAllowDroppedAssessments}
      calculatedHighScore={calculatedHighScore}
      calculatedLowScore={calculatedLowScore}
      curvedFailingGrade={curvedFailingGrade}
      curvedHighGrade={curvedHighGrade}
      handleChangeRadio={handleChangeRadio}
      handleGradingTechniqueChange={handleGradingTechniqueChange}
      handleSubmitGradingTechnique={handleSubmitGradingTechnique}
      handleLowGradesDroppedChange={handleLowGradesDroppedChange}
      isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
      low_grades_dropped={low_grades_dropped}
      lowScoreCalculated={lowScoreCalculated}
      lowScoreInput={lowScoreInput}
      maximumMethodCalculated={maximumMethodCalculated}
      maximumRevusInput={maximumRevusInput}
      scaledHighScore={scaledHighScore}
      scaledLowScore={scaledLowScore}
      selectedRadio={selectedRadio}
      showValidationErrors={showValidationErrors}
      unsavedParentChanges={unsavedChanges.collectionAssessments || false}
      unsavedGradeModifierChanges={
        unsavedChanges.collectionGradeModifiers || false
      }
      unsavedParentErrorMessage="Collection assessments have changed, apply changes above before
                setting low grade(s) dropped."
      distributeWeightErrorMessage="To drop assessments, you must select option to distributed
                weight equally among assessments, then apply changes above."
      labels={{
        dividedAssessmentPoints:
          'Divided by Grade Collection Total Possible Points',
        dividedCohortHigh: 'Divided by Cohort High Points',
        curved: 'Curved',
        scaled: 'Scaled'
      }}
    />
  );
}

EditGradeModifiersContainer.propTypes = {
  isUnsavedChangesModalOpen: PropTypes.bool,
  unsavedChanges: PropTypes.object,
  setUnsavedChange: PropTypes.func,
  removeUnsavedChange: PropTypes.func
};

EditGradeModifiersContainer.defaultProps = {
  isUnsavedChangesModalOpen: false,
  unsavedChanges: {},
  setUnsavedChange: undefined,
  removeUnsavedChange: undefined
};
