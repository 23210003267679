import React from 'react';
import PropTypes from 'prop-types';
import { ContainerPage } from '../../../Library/Layout';
import CohortApplicantCognitiveFilters from './CohortApplicantCognitiveReportFilters';
import CohortApplicantSummaryData from '../CohortApplicantSummaryData';
import CohortApplicantReportList from '../CohortApplicantReportList';

const CohortApplicantCognitiveReport = ({
  isLoadingCognitiveReport,
  onApplyFilterSelection,
  onApplyReportType,
  onSelectTranscriptRankBy,
  onSelectTranscriptScope,
  onSelectTranscriptSource,
  onToggleShouldAnonymizeReport,
  reportData,
  reportHeaders,
  reportSummary,
  reportSummaryFilters,
  reportSummaryHeaders,
  shouldAnonymizeReport,
  transcriptRankBy,
  transcriptRankByDisabled,
  transcriptRankByOptions,
  transcriptReportTypeError,
  transcriptScope,
  transcriptScopeDisabled,
  transcriptScopeOptions,
  transcriptSource,
  transcriptSourceOptions
}) => {
  return (
    <ContainerPage paddingTop="48px">
      <CohortApplicantCognitiveFilters
        onApplyReportType={onApplyReportType}
        onSelectTranscriptRankBy={onSelectTranscriptRankBy}
        onSelectTranscriptScope={onSelectTranscriptScope}
        onSelectTranscriptSource={onSelectTranscriptSource}
        transcriptRankBy={transcriptRankBy}
        transcriptRankByDisabled={transcriptRankByDisabled}
        transcriptRankByOptions={transcriptRankByOptions}
        transcriptReportTypeError={transcriptReportTypeError}
        transcriptScope={transcriptScope}
        transcriptScopeDisabled={transcriptScopeDisabled}
        transcriptScopeOptions={transcriptScopeOptions}
        transcriptSource={transcriptSource}
        transcriptSourceOptions={transcriptSourceOptions}
      />
      <CohortApplicantSummaryData
        reportSummary={reportSummary}
        reportSummaryMapping={reportSummaryHeaders}
      />
      <CohortApplicantReportList
        isLoading={isLoadingCognitiveReport}
        onApplyFilterSelection={onApplyFilterSelection}
        onToggleShouldAnonymizeReport={onToggleShouldAnonymizeReport}
        reportData={reportData}
        reportHeaders={reportHeaders}
        reportSummaryFilters={reportSummaryFilters}
        shouldAnonymizeReport={shouldAnonymizeReport}
        showRank={true}
      />
    </ContainerPage>
  );
};

CohortApplicantCognitiveReport.propTypes = {
  isLoadingCognitiveReport: PropTypes.bool,
  onApplyFilterSelection: PropTypes.func,
  onApplyReportType: PropTypes.func,
  onSelectTranscriptRankBy: PropTypes.func,
  onSelectTranscriptScope: PropTypes.func,
  onSelectTranscriptSource: PropTypes.func,
  onToggleShouldAnonymizeReport: PropTypes.func,
  reportData: PropTypes.array,
  reportHeaders: PropTypes.array,
  reportSummary: PropTypes.object,
  reportSummaryFilters: PropTypes.array,
  reportSummaryHeaders: PropTypes.array,
  shouldAnonymizeReport: PropTypes.bool,
  transcriptRankBy: PropTypes.string,
  transcriptRankByDisabled: PropTypes.bool,
  transcriptRankByOptions: PropTypes.array,
  transcriptReportTypeError: PropTypes.bool,
  transcriptScope: PropTypes.string,
  transcriptScopeDisabled: PropTypes.bool,
  transcriptScopeOptions: PropTypes.array,
  transcriptSource: PropTypes.string,
  transcriptSourceOptions: PropTypes.array
};

CohortApplicantCognitiveReport.defaultProps = {
  isLoadingCognitiveReport: false,
  onApplyFilterSelection: undefined,
  onApplyReportType: undefined,
  onSelectTranscriptRankBy: undefined,
  onSelectTranscriptScope: undefined,
  onSelectTranscriptSource: undefined,
  onToggleShouldAnonymizeReport: undefined,
  reportData: [],
  reportHeaders: [],
  reportSummary: {},
  reportSummaryFilters: [],
  reportSummaryHeaders: [],
  shouldAnonymizeReport: false,
  transcriptRankBy: '',
  transcriptRankByDisabled: true,
  transcriptRankByOptions: [],
  transcriptReportTypeError: false,
  transcriptScope: '',
  transcriptScopeDisabled: true,
  transcriptScopeOptions: [],
  transcriptSource: '',
  transcriptSourceOptions: []
};

export default CohortApplicantCognitiveReport;
