import _ from 'lodash';

export const getTotalNumberOfInProgressEncounters = part => {
  const encounters = _.get(part, 'encounters', []);

  const approved = encounters.filter(
    encounter => encounter.status === 'In Progress'
  );

  return approved.length;
};

export const getTotalNumberOfCompleteEncounters = part => {
  const encounters = _.get(part, 'encounters', []);

  const approved = encounters.filter(
    encounter => encounter.status === 'Complete'
  );

  return approved.length;
};

export const getTotalNumberOfApprovedEncounters = part => {
  const encounters = _.get(part, 'encounters', []);

  const approved = encounters.filter(
    encounter => encounter.status === 'Approved'
  );

  return approved.length;
};

export const getTotalNumberOfInProgressAssessments = part => {
  const assessments = _.get(part, 'assessments', []);

  const approved = assessments.filter(
    assessment => assessment.status === 'In Progress'
  );

  return approved.length;
};

export const getTotalNumberOfCompleteAssessments = part => {
  const assessments = _.get(part, 'assessments', []);

  const approved = assessments.filter(
    assessment => assessment.status === 'Complete'
  );

  return approved.length;
};

export const getTotalNumberOfApprovedAssessments = part => {
  const assessments = _.get(part, 'assessments', []);

  const approved = assessments.filter(
    assessment => assessment.status === 'Approved'
  );

  return approved.length;
};

export const getTotalNumberOfPendingEncounters = part => {
  const encounters = _.get(part, 'encounters', []);

  const approved = encounters.filter(
    encounter => encounter.status === 'Complete'
  );

  return approved.length;
};

export const getTotalNumberOfPendingAssessments = part => {
  const assessments = _.get(part, 'assessments', []);

  const approved = assessments.filter(
    assessment => assessment.status === 'Complete'
  );

  return approved.length;
};

export const getTotalNumberOfCommittedAssessments = part => {
  const assessments = _.get(part, 'assessments', []);

  const approved = assessments.filter(
    assessment => assessment.statusCommit === 'Commit'
  );

  return approved.length;
};

export const getTotalNumberOfUncommittedAssessments = part => {
  const assessments = _.get(part, 'assessments', []);

  const approved = assessments.filter(
    assessment => assessment.statusCommit === 'Uncommitted'
  );

  return approved.length;
};

export const getTotalNumberofEncounters = part => {
  const encounters = _.get(part, 'encounters', []);

  return encounters.length;
};

export const getTotalNumberofAssessments = part => {
  const assessments = _.get(part, 'assessments', []);

  return assessments.length;
};
