import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getDegreeLevelIndex,
  putDegreeLevelIndex,
  postDegreeLevelIndex,
  deleteDegreeLevelIndex
} from '../../api/degreeLevelIndexApi';

import {
  doPostDegreeLevelIndexSuccess,
  doPostDegreeLevelIndexError,
  doPutDegreeLevelIndexSuccess,
  doPutDegreeLevelIndexError,
  doDeleteDegreeLevelIndexSuccess,
  doDeleteDegreeLevelIndexError,
  doGetDegreeLevelIndicesSuccess,
  doGetDegreeLevelIndicesError
} from '../actions/degreeLevelIndexActions';

import {
  GET_DEGREE_LEVEL_INDICES,
  POST_DEGREE_LEVEL_INDEX,
  PUT_DEGREE_LEVEL_INDEX,
  DELETE_DEGREE_LEVEL_INDEX
} from '../actions-type';

function* handleGetDegreeLevelIndex(action) {
  try {
    const { programUuid } = action.payload;

    const result = yield call(getDegreeLevelIndex, programUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetDegreeLevelIndicesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetDegreeLevelIndicesError(error));
  }
}

function* handlePostDegreeLevelIndex(action) {
  try {
    const { programUuid, sourceDegreeLevelUuid, percentage } = action.payload;

    const result = yield call(
      postDegreeLevelIndex,
      programUuid,
      sourceDegreeLevelUuid,
      percentage
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostDegreeLevelIndexSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostDegreeLevelIndexError(error));
  }
}

function* handlePutDegreeLevelIndex(action) {
  try {
    const { uuid, sourceDegreeLevelUuid, percentage } = action.payload;

    const result = yield call(
      putDegreeLevelIndex,
      uuid,
      sourceDegreeLevelUuid,
      percentage
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutDegreeLevelIndexSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutDegreeLevelIndexError(error));
  }
}

function* handleDeleteDegreeLevelIndex(action) {
  try {
    const { uuid } = action.payload;

    const result = yield call(deleteDegreeLevelIndex, uuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteDegreeLevelIndexSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteDegreeLevelIndexError(error));
  }
}

const degreeLevelIndex = [
  takeEvery(GET_DEGREE_LEVEL_INDICES, handleGetDegreeLevelIndex),
  takeEvery(POST_DEGREE_LEVEL_INDEX, handlePostDegreeLevelIndex),
  takeEvery(PUT_DEGREE_LEVEL_INDEX, handlePutDegreeLevelIndex),
  takeEvery(DELETE_DEGREE_LEVEL_INDEX, handleDeleteDegreeLevelIndex)
];

export { degreeLevelIndex };
