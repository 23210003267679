import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';
import momentTz from 'moment-timezone';

import {
  DocumentIcon,
  DateIcon,
  UsersIcon,
  CloseIcon
} from '@xcomp/xcomp-design-library/dist/icons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import ButtonPrimary from '@xcomp/xcomp-design-library/dist/components/ButtonPrimary';
import ScoresView from './ScoresView/ScoresView';
import FormRead from '../../Library/FormRead';
import { ContainerContent, ElevateLow } from '../../Library/Layout';

import {
  selectAssessmentTechnique,
  selectAssessmentAssociation,
  selectGradeSummary
} from '../../../redux/selectors/assessmentSelectors';

import { assessmentRankSelector } from '../../../redux/selectors/assessmentRankSelectors';
import { resultCardByFieldsSelector } from '../../../redux/selectors/resultCardSelector';
import { partSelectorByUuid } from '../../../redux/selectors/partsSelectors';
import StudentPartGroupReportContainer from '../../SyllabusManagement/PartGradeSummary/PartGroupReport/StudentPartGroupReportContainer';
import { doResetPartGroupSummary } from '../../../redux/actions/partGroupSummaryActions';

const Layout = styled.div`
  padding: 3rem;
`;

const FormLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 19px;
  text-transform: uppercase;
  svg {
    vertical-align: top;
    margin-right: 0.5rem;
  }
`;

const FormInput = styled.div`
  padding-left: 30px;
`;

const FormHeader = styled.div`
  padding: 30px;

  border-bottom: 2px solid ${props => props.theme.colors.grey[300]};
`;

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
`;

const SubTitle = styled.div`
  font-size: 18px;
`;

// const Temp = styled.div`
//   border: 2px dotted red;
// `;

export default function Assessment({ userUuid, assessment, selectedOrder }) {
  const dispatch = useDispatch();
  const [openScores, setOpenScores] = useState(false);
  const [openPerformance, setOpenPerformance] = useState(false);

  const assessmentAssociation = useSelector(
    state =>
      selectAssessmentAssociation(state, assessment.assessmentAssociationUuid),
    _.isEqual
  );

  const assessmentTechnique = useSelector(
    state =>
      selectAssessmentTechnique(state, assessment.assessmentTechniqueUuid),
    _.isEqual
  );

  const gradeSummary = useSelector(
    state => selectGradeSummary(state, assessment.uuid),
    _.isEqual
  );

  const resultCard = useSelector(
    state => resultCardByFieldsSelector(state, assessment.uuid, userUuid),
    _.isEqual
  );

  const assessmentRank = useSelector(
    state => assessmentRankSelector(state, assessment?.uuid, userUuid),
    _.isEqual
  );

  const date = _.get(resultCard, 'createdAt', null);
  const appromixateTimeZone = moment.tz.guess();
  const completionDate = date
    ? momentTz(date).tz(appromixateTimeZone).format('YYYY-MM-DD')
    : '- -';

  const handleScoresOpen = () => {
    setOpenScores(true);
  };

  const handleScoresClose = () => {
    setOpenScores(false);
  };

  const handlePerfomanceOpen = useCallback(() => {
    setOpenPerformance(true);
  }, []);

  const handlePerfomanceClose = useCallback(() => {
    setOpenPerformance(false);
    dispatch(doResetPartGroupSummary());
  }, [dispatch]);

  const part = useSelector(
    state => partSelectorByUuid(state, assessment?.partUuid),
    shallowEqual
  );
  
  const showPercentSymbol = assessment?.grading_technique?.gradingTechnique?.toLowerCase().includes('points');

  return (
    <Layout>
      <ElevateLow>
        <FormHeader>
          <Title>
            Assessment {(selectedOrder + 1).toString().padStart(2, '0')}
          </Title>
          <SubTitle>{assessment?.title}</SubTitle>
        </FormHeader>
        <ContainerContent paddingTop="40px">
          <Grid container spacing={4}>
            <Grid item sm={12} xs={12}>
              <FormLabel>
                <DocumentIcon />
                Assessment Information
              </FormLabel>
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormInput>{assessment?.title}</FormInput>
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormLabel>
                <DateIcon />
                Time and Location
              </FormLabel>
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormInput>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={12}>
                    <FormRead
                      label="Start Date and Time"
                      value={moment(assessment.startDate).format('LL, h:mm a')}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormRead
                      label="End Date and Time"
                      value={moment(assessment.endDate).format('LL, h:mm a')}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormRead
                      label="Assessment Association"
                      value={assessmentAssociation?.association}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormRead
                      label="Assessment Technique"
                      value={assessmentTechnique?.technique}
                    />
                  </Grid>
                </Grid>
              </FormInput>
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormLabel>
                <UsersIcon />
                Responsible Personnel
              </FormLabel>
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormInput>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={12}>
                    <FormRead
                      label="Responsible Faculty"
                      value={`${assessment?.responsible_faculty?.firstName} ${assessment?.responsible_faculty?.lastName}`}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormRead
                      label="Assessment Manager"
                      value={`${assessment?.assessment_manager?.firstName} ${assessment?.assessment_manager?.lastName}`}
                    />
                  </Grid>
                </Grid>
              </FormInput>
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormLabel>
                <UsersIcon />
                Grading Overall
              </FormLabel>
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormInput>
                <Grid container spacing={4}>
                  <Grid item sm={3} xs={12}>
                    <FormRead
                      label={`High ${showPercentSymbol ? '%' : ''}`}
                      value={gradeSummary?.highScore || '- -'}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormRead
                      label={`Average ${showPercentSymbol ? '%' : ''}`}
                      value={gradeSummary?.averageScore || '- -'}
                    />
                  </Grid>

                  <Grid item sm={3} xs={12}>
                    <FormRead
                      label={`Low ${showPercentSymbol ? '%' : ''}`}
                      value={gradeSummary?.lowScore || '- -'}
                    />
                  </Grid>

                  <Grid item sm={3} xs={12}>
                    <ButtonPrimary onClick={handleScoresOpen}>
                      View Scores
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              </FormInput>
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormLabel>
                <UsersIcon />
                Grading Individual
              </FormLabel>
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormInput>
                <Grid container spacing={4}>
                  <Grid item sm={2} xs={12}>
                    <FormRead
                      label="Rank"
                      value={`${assessmentRank?.rank || '-'} of ${
                        assessmentRank?.rankCount || '-'
                      }`}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormRead
                      label="Date of Completion"
                      value={completionDate}
                    />
                  </Grid>

                  <Grid item sm={2} xs={12}>
                    <FormRead
                      label="Score"
                      value={resultCard?.assessmentPotentialScore || '- -'}
                    />
                  </Grid>

                  <Grid item sm={2} xs={12}>
                    <FormRead
                      label="Grade"
                      value={resultCard?.letterGrade || '- -'}
                    />
                  </Grid>

                  {/* <Grid item sm={2} xs={12}>
                    <Temp>
                      <FormRead label="Pass/Fail" value="- -" />
                    </Temp>
                  </Grid> */}
                </Grid>
              </FormInput>
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormLabel>
                <UsersIcon />
                Performance summary
              </FormLabel>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Tooltip
                placement="top"
                title={
                  !['Committed', 'Published'].includes(part?.status)
                    ? 'Part must be Locked to access Performance Summary'
                    : ''
                }
              >
                <div>
                  <ButtonPrimary
                    onClick={handlePerfomanceOpen}
                    disabled={
                      !['Committed', 'Published'].includes(part?.status)
                    }
                  >
                    Performance Summary
                  </ButtonPrimary>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </ContainerContent>
      </ElevateLow>
      <ScoresView
        assessmentUuid={assessment?.uuid}
        openScores={openScores}
        studentUuid={userUuid}
        handleScoresClose={handleScoresClose}
      />
      <Dialog
        fullScreen
        open={openPerformance}
        onClose={handlePerfomanceClose}
        // TransitionComponent={Transition}
      >
        <AppBar>
          <Toolbar style={{ justifyContent: 'space-between' }}>
            <Typography variant="h6">Group Performance Summary</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlePerfomanceClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <DialogContent style={{ marginTop: '80px' }}>
          <StudentPartGroupReportContainer
            partUuid={assessment?.partUuid}
            studentUuid={userUuid}
            isOpen={openPerformance}
          />
        </DialogContent>
      </Dialog>
    </Layout>
  );
}

Assessment.propTypes = {
  userUuid: PropTypes.string,
  assessment: PropTypes.object,
  selectedOrder: PropTypes.number
};

Assessment.defaultProps = {
  userUuid: undefined,
  assessment: undefined,
  selectedOrder: 0
};
