import { Model, attr, fk } from 'redux-orm';

import {
  GET_CLASSIFICATIONS_SUCCESS,
  GET_APTITUDES_BY_CATEGORY_SUCCESS,
  POST_APTITUDES_SUCCESS,
  GET_TRANSCRIPT_SUCCESS
} from '../actions-type';

class ConversionAptitude extends Model {
  static reducer(action, ConversionAptitude, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_CLASSIFICATIONS_SUCCESS: {
        const classifications = [...payload.classifications];
        classifications.forEach(classification => {
          if (classification.category !== 'Cognitive') {
            if (
              classification.exam_aptitudes &&
              classification.exam_aptitudes.length > 0
            ) {
              classification.exam_aptitudes.forEach(exam => {
                if (
                  exam.conversion_aptitudes &&
                  exam.conversion_aptitudes.length > 0
                ) {
                  exam.conversion_aptitudes.forEach(conversion => {
                    const copy = { ...conversion };
                    ConversionAptitude.upsert(copy);
                  });
                }
              });
            }
          }
        });
        break;
      }

      case GET_TRANSCRIPT_SUCCESS: {
        const classifications = [...payload.classifications];
        classifications.forEach(classification => {
          if (classification?.category !== 'Cognitive') {
            if (classification?.exam_aptitudes?.length > 0) {
              classification.exam_aptitudes.forEach(exam => {
                if (exam?.conversion_aptitudes?.length > 0) {
                  exam.conversion_aptitudes.forEach(conversion => {
                    const copy = { ...conversion };
                    ConversionAptitude.upsert(copy);
                  });
                }
              });
            }
          }
        });
        break;
      }

      case GET_APTITUDES_BY_CATEGORY_SUCCESS: {
        const classifications = [...payload.classifications];

        classifications.forEach(classification => {
          if (classification.exam_aptitudes?.length > 0) {
            classification.exam_aptitudes.forEach(exam => {
              if (exam.conversion_aptitudes?.length > 0) {
                exam.conversion_aptitudes.forEach(conversion => {
                  const copy = { ...conversion };
                  ConversionAptitude.upsert(copy);
                });
              }
            });
          }
        });

        break;
      }

      case POST_APTITUDES_SUCCESS: {
        const classifications = [...payload.classifications];
        const conversionAptitudesRemove = [
          ...payload.conversionAptitudesRemove
        ];

        classifications.forEach(classification => {
          if (classification.exam_aptitudes?.length > 0) {
            classification.exam_aptitudes.forEach(exam => {
              if (exam.conversion_aptitudes?.length > 0) {
                exam.conversion_aptitudes.forEach(conversion => {
                  const copy = { ...conversion };
                  ConversionAptitude.upsert(copy);
                });
              }
            });
          }
        });
        if (conversionAptitudesRemove?.length > 0) {
          conversionAptitudesRemove.forEach(conversion => {
            ConversionAptitude.withId(conversion.uuid).delete();
          });
        }
        break;
      }

      default:
        return session;
    }
  }
}

ConversionAptitude.fields = {
  uuid: attr(),
  scaledScore: attr(),
  percentage: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  examAptitudeUuid: fk('ExamAptitude', 'conversion_aptitudes')
};

ConversionAptitude.modelName = 'ConversionAptitude';

ConversionAptitude.options = {
  idAttribute: 'uuid'
};

export default ConversionAptitude;
