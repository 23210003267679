import {
  GET_GRADE_SUMMARY,
  GET_GRADE_SUMMARY_SUCCESS,
  GET_GRADE_SUMMARY_ERROR,
  GET_COHORT_GRADE_SUMMARY
} from '../actions-type';

const doGetGradeSummary = assessmentUuid => ({
  type: GET_GRADE_SUMMARY,
  payload: {
    assessmentUuid
  }
});

const doGetCohortGradeSummary = assessmentUuid => ({
  type: GET_COHORT_GRADE_SUMMARY,
  payload: {
    assessmentUuid
  }
});

const doGetGradeSummarySuccess = result => ({
  type: GET_GRADE_SUMMARY_SUCCESS,
  payload: result
});

const doGetGradeSummaryError = error => ({
  type: GET_GRADE_SUMMARY_ERROR,
  payload: error
});

export {
  doGetGradeSummary,
  doGetCohortGradeSummary,
  doGetGradeSummarySuccess,
  doGetGradeSummaryError
};
