import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { doGetCohortAssessmentCommitmentLog } from '../../../redux/actions/cohortAssessmentActions';

import AssessmentCommitmentLog from './AssessmentCommitmentLog';
import { commitmentLogsSelectorByCohortAssessmentUuid } from '../../../redux/selectors/commitmentLogSelectors';
import { sortByDateProperty } from '../../../helpers/utilities';
import { assessmentSelector } from '../../../redux/selectors/assessmentSelectors';
import { cohortAssessmentCollectionSelector } from '../../../redux/selectors/cohortAssessmentSelectors';
import { doGetCohort } from '../../../redux/actions/cohortActions';
import { cohortSelectorByMatchParams } from '../../../redux/selectors/cohortsSelectors';

export default function CohortAssessmentCommitmentLogContainer() {
  const dispatch = useDispatch();
  const { cohortAssessmentUuid, cohortUuid } = useParams();
  const [haveFetchedLogsFor, setHaveFetchedLogsFor] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortReverseChron, setSortReverseChron] = useState(false);

  const assessment = useSelector(
    state => assessmentSelector(state, cohortAssessmentUuid),
    _.isEqual
  );
  const cohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    shallowEqual
  );

  const cohortAsessmentCollection = useSelector(
    state =>
      cohortAssessmentCollectionSelector(
        state,
        assessment.cohortAssessmentCollectionUuid
      ),
    _.isEqual
  );

  const commitmentLogs = useSelector(
    state =>
      commitmentLogsSelectorByCohortAssessmentUuid(state, cohortAssessmentUuid),
    _.isEqual
  );

  const handleQueryChange = event => {
    setSearchQuery(event.target.value);
  };

  const toggleSortReverse = () => {
    setSortReverseChron(!sortReverseChron);
  };

  useEffect(() => {
    if (
      commitmentLogs.length === 0 &&
      haveFetchedLogsFor !== cohortAssessmentUuid
    ) {
      dispatch(
        doGetCohortAssessmentCommitmentLog(cohortAssessmentUuid, cohortUuid)
      );
      setHaveFetchedLogsFor(cohortAssessmentUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortAssessmentUuid, commitmentLogs, haveFetchedLogsFor]);

  useEffect(() => {
    dispatch(doGetCohort(cohortUuid));
  }, [dispatch, cohortUuid]);

  const filteredCommitmentLogs = searchQuery
    ? commitmentLogs.filter(log => {
        if (!searchQuery || searchQuery.trim() === '') {
          return true;
        }

        const doesSummaryMatchQuery = log.summary
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());

        const doesJustificationyMatchQuery = log.justificationMessage
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());

        if (doesSummaryMatchQuery || doesJustificationyMatchQuery) {
          return true;
        }
        return false;
      })
    : commitmentLogs;

  const sortedCommitmentLogs = filteredCommitmentLogs.sort((a, b) =>
    !sortReverseChron
      ? sortByDateProperty(a, b, 'createdAt')
      : sortByDateProperty(b, a, 'createdAt')
  );

  return (
    <AssessmentCommitmentLog
      assessment={assessment}
      cohortAsessmentCollection={cohortAsessmentCollection}
      cohort={cohort}
      cohortAssessmentUuid={cohortAssessmentUuid}
      cohortUuid={cohortUuid}
      handleQueryChange={handleQueryChange}
      toggleSortReverse={toggleSortReverse}
      commitmentLogs={sortedCommitmentLogs || []}
    />
  );
}
