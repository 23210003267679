import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const resultCardByFieldsSelector = createSelector(
  state => state,
  (_, assessmentUuid, studentUuid) => ({ assessmentUuid, studentUuid }),
  (redux, data) => {
    const { assessmentUuid, studentUuid } = data;

    const resultCardSelectorORM = ormCreateSelector(orm.ResultCard);
    const resultCardByFields = resultCardSelectorORM(redux).filter(
      resultCard =>
        resultCard.assessmentUuid === assessmentUuid &&
        resultCard.studentUuid === studentUuid
    );

    const resultCard = _.maxBy(resultCardByFields, 'attempt');

    return resultCard;
  }
);
