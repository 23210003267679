import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { doPostDepartment } from '../../../redux/actions/departmentActions';
import { validateDepartmentCode } from '../../../helpers/validation/validateDepartment';
import { validateInputString } from '../../../helpers/validation/validateGeneric';

import DepartmentAdd from './DepartmentAdd';

class DepartmentAddContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      code: '',
      redirect: false,
      hasErrors: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.hasValidationErrors = this.hasValidationErrors.bind(this);
    this.handleCreateDepartment = this.handleCreateDepartment.bind(this);
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleCreateDepartment() {
    const hasValidationErrors = this.hasValidationErrors();

    if (hasValidationErrors) {
      this.setState({
        hasErrors: true
      });
    } else {
      const { schoolUuid } = this.props;
      const { name, code } = this.state;

      const payload = {
        name,
        code,
        schoolUuid
      };

      this.props.onPostDepartment(payload);

      this.setState({
        redirect: true
      });
    }
  }

  hasValidationErrors() {
    let validationErrors = false;

    const { name, code } = this.state;

    const departmentNameError = validateInputString(name);
    const departmentCodeError = validateDepartmentCode(code);

    if (departmentNameError.invalid) {
      validationErrors = true;
    }

    if (departmentCodeError.invalid) {
      validationErrors = true;
    }

    return validationErrors;
  }

  render() {
    const { name, code, hasErrors, redirect } = this.state;

    return redirect ? (
      <Redirect to="/school-management/departments" />
    ) : (
      <DepartmentAdd
        name={name}
        code={code}
        hasErrors={hasErrors}
        handleChange={this.handleChange}
        handleCreateDepartment={this.handleCreateDepartment}
      />
    );
  }
}

DepartmentAddContainer.propTypes = {
  onPostDepartment: PropTypes.func,
  schoolUuid: PropTypes.string
};

DepartmentAddContainer.defaultProps = {
  onPostDepartment: undefined,
  schoolUuid: undefined
};

const mapStateToProps = state => {
  const { userState } = state;
  return {
    schoolUuid: userState.selectedSchoolUuid
  };
};

const mapDispatchToProps = dispatch => ({
  onPostDepartment: (payload, picture) =>
    dispatch(doPostDepartment(payload, picture))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentAddContainer);
