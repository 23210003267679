import _ from 'lodash';

const initialMicroSearchState = {
  view: 'search', // ['search', 'editTopic']
  editTopicUuid: '',
  searchForCompetencyUuid: '',
  showResults: false,
  searchType: 'microcompetency',
  haveFetchedCompetencyCollection: false,
  showValidationErrors: false,
  addedMicros: {
    // [topicUuid]: {
    //   topic: { topic object },
    //   micros: {
    //     [microUuid]: { micro object }
    //   }
    // }
  },
  topics: {
    // [topicUuid]: topicAdded->subtopic->micro
  },
  subtopics: {
    // [topicUuid]: {
    //   topic: { topic object },
    //   subtopics: {
    //     [subtopicUuid]: {
    //       subtopic: { subtopic object },
    //       micros: {
    //         [microUuid]: { micro object }
    //       }
    //     }
    //   }
    // }
  },
  micros: {
    // [topicUuid]: {
    //   topic: { topic object },
    //   micros: {
    //     [microUuid]: { micro object }
    //   }
    // }
  },
  editTopicMicros: {
    // [microUuid]: { micro object }
  }
};

const microSearchReducer = (state, action) => {
  switch (action.type) {
    case 'setEditTopic': {
      const { topicUuid } = action.payload;
      const { addedMicros } = state;
      const newMicrosObj = _.cloneDeep(addedMicros[topicUuid].micros);

      return {
        ...state,
        view: 'editTopic',
        editTopicUuid: topicUuid,
        editTopicMicros: newMicrosObj,
        micros: {},
        subtopics: {},
        topics: {}
      };
    }
    case 'cancelTopicEdit': {
      return {
        ...state,
        view: 'search',
        editTopicUuid: '',
        micros: {},
        subtopics: {},
        topics: {},
        editTopicMicros: {}
      };
    }
    case 'sethaveFetchedCompetencyCollection':
      return {
        ...state,
        haveFetchedCompetencyCollection: true
      };
    case 'setShowResults':
      return {
        ...state,
        showResults: action.payload.showResults
      };
    case 'setSearchType':
      return {
        ...state,
        searchType: action.payload.searchType,
        micros: {},
        subtopics: {},
        topics: {}
      };
    case 'setExistingMicros': {
      const { existingMicros, competencyUuid } = action.payload;

      const newAddedMicrosObj = {};

      if (existingMicros && existingMicros.length > 0) {
        existingMicros.forEach(micro => {
          const topicKey = `${micro.subtopic.topic.uuid}`;

          if (newAddedMicrosObj[topicKey]) {
            newAddedMicrosObj[topicKey].micros[micro.uuid] = micro;
          } else {
            newAddedMicrosObj[topicKey] = {};
            newAddedMicrosObj[topicKey].topic = micro.subtopic.topic;
            newAddedMicrosObj[topicKey].micros = {};
            newAddedMicrosObj[topicKey].micros[micro.uuid] = micro;
          }
        });
      }

      return {
        ...state,
        searchForCompetencyUuid: competencyUuid,
        addedMicros: newAddedMicrosObj
      };
    }
    case 'removeTopicAndMicros': {
      const { topicUuid } = action.payload;
      const addedMicrosObj = _.cloneDeep(state.addedMicros);

      if (addedMicrosObj[topicUuid]) delete addedMicrosObj[topicUuid];

      return {
        ...state,
        addedMicros: addedMicrosObj,
        view: 'search',
        editTopicUuid: '',
        micros: {},
        subtopics: {},
        topics: {},
        editTopicMicros: {}
      };
    }
    case 'reselectMicrocompetencyInTopic': {
      const { microcompetency } = action.payload;
      const newMicrosObj = _.cloneDeep(state.editTopicMicros);

      newMicrosObj[microcompetency.uuid] = microcompetency;

      return {
        ...state,
        editTopicMicros: newMicrosObj
      };
    }
    case 'deselectMicrocompetencyInTopic': {
      const { microcompetencyUuid } = action.payload;
      const newMicrosObj = _.cloneDeep(state.editTopicMicros);

      delete newMicrosObj[microcompetencyUuid];

      return {
        ...state,
        editTopicMicros: newMicrosObj
      };
    }
    case 'selectMicrocompetency': {
      const { microcompetency } = action.payload;
      const newMicrosObj = _.cloneDeep(state.micros);
      const topicKey = `${microcompetency.subtopic.topic.uuid}`;

      if (newMicrosObj[topicKey]) {
        newMicrosObj[topicKey].micros[microcompetency.uuid] = microcompetency;
      } else {
        newMicrosObj[topicKey] = {};
        newMicrosObj[topicKey].topic = microcompetency.subtopic.topic;
        newMicrosObj[topicKey].micros = {};
        newMicrosObj[topicKey].micros[microcompetency.uuid] = microcompetency;
      }

      return {
        ...state,
        micros: newMicrosObj
      };
    }
    case 'deselectMicrocompetency': {
      const { microcompetencyUuid, topicUuid } = action.payload;
      const newMicrosObj = _.cloneDeep(state.micros);

      if (Object.keys(newMicrosObj[topicUuid].micros).length < 2) {
        // last one
        delete newMicrosObj[topicUuid];
      } else {
        delete newMicrosObj[topicUuid].micros[microcompetencyUuid];
      }

      return {
        ...state,
        micros: newMicrosObj
      };
    }
    case 'selectSubtopic': {
      const { subtopic } = action.payload;

      const newSubtopicObj = _.cloneDeep(state.subtopics);
      const topicKey = `${subtopic.topicUuid}`;

      if (newSubtopicObj[topicKey]) {
        if (!newSubtopicObj[topicKey].subtopics[subtopic.uuid])
          newSubtopicObj[topicKey].subtopics[subtopic.uuid] = {};
        newSubtopicObj[topicKey].subtopics[subtopic.uuid].subtopic = subtopic;
        subtopic.microcompetencies.forEach(micro => {
          if (!newSubtopicObj[topicKey].subtopics[subtopic.uuid].micros)
            newSubtopicObj[topicKey].subtopics[subtopic.uuid].micros = {};
          newSubtopicObj[topicKey].subtopics[subtopic.uuid].micros[
            micro.uuid
          ] = micro;
        });
      } else {
        newSubtopicObj[topicKey] = {};
        newSubtopicObj[topicKey].topic = subtopic.topic;
        newSubtopicObj[topicKey].subtopics = {};
        newSubtopicObj[topicKey].subtopics[subtopic.uuid] = subtopic;
        subtopic.microcompetencies.forEach(micro => {
          if (!newSubtopicObj[topicKey].subtopics[subtopic.uuid].micros)
            newSubtopicObj[topicKey].subtopics[subtopic.uuid].micros = {};

          newSubtopicObj[topicKey].subtopics[subtopic.uuid].micros[
            micro.uuid
          ] = micro;
        });
      }

      return {
        ...state,
        subtopics: newSubtopicObj
      };
    }
    case 'selectSubtopicMicro': {
      const { subtopic, micro } = action.payload;

      const newSubtopicObj = _.cloneDeep(state.subtopics);
      const topicKey = `${subtopic.topicUuid}`;

      if (newSubtopicObj[topicKey]) {
        if (!newSubtopicObj[topicKey].subtopics[subtopic.uuid])
          newSubtopicObj[topicKey].subtopics[subtopic.uuid] = {};

        newSubtopicObj[topicKey].subtopics[subtopic.uuid].subtopic = subtopic;

        if (!newSubtopicObj[topicKey].subtopics[subtopic.uuid].micros)
          newSubtopicObj[topicKey].subtopics[subtopic.uuid].micros = {};

        newSubtopicObj[topicKey].subtopics[subtopic.uuid].micros[
          micro.uuid
        ] = micro;
      } else {
        newSubtopicObj[topicKey] = {};
        newSubtopicObj[topicKey].topic = subtopic.topic;
        newSubtopicObj[topicKey].subtopics = {};
        newSubtopicObj[topicKey].subtopics[subtopic.uuid] = subtopic;
        newSubtopicObj[topicKey].subtopics[subtopic.uuid].micros = {};
        newSubtopicObj[topicKey].subtopics[subtopic.uuid].micros[
          micro.uuid
        ] = micro;
      }

      return {
        ...state,
        subtopics: newSubtopicObj
      };
    }
    case 'deselectSubtopic': {
      const { subtopicUuid, topicUuid } = action.payload;
      const newSubtopicObj = _.cloneDeep(state.subtopics);

      if (Object.keys(newSubtopicObj[topicUuid].subtopics).length < 2) {
        // last one
        delete newSubtopicObj[topicUuid];
      } else {
        delete newSubtopicObj[topicUuid].subtopics[subtopicUuid];
      }

      return {
        ...state,
        subtopics: newSubtopicObj
      };
    }
    case 'deselectSubtopicMicro': {
      const { microUuid, subtopicUuid, topicUuid } = action.payload;
      const newSubtopicObj = _.cloneDeep(state.subtopics);

      if (Object.keys(newSubtopicObj[topicUuid].subtopics).length < 2) {
        // last subtopic in topic
        if (
          Object.keys(newSubtopicObj[topicUuid].subtopics[subtopicUuid].micros)
            .length < 2
        ) {
          // last micro in subtopic
          delete newSubtopicObj[topicUuid];
        } else {
          delete newSubtopicObj[topicUuid].subtopics[subtopicUuid].micros[
            microUuid
          ];
        }
      } else if (
        Object.keys(newSubtopicObj[topicUuid].subtopics[subtopicUuid].micros)
          .length < 2
      ) {
        // last micro in subtopic
        delete newSubtopicObj[topicUuid].subtopics[subtopicUuid];
      } else {
        delete newSubtopicObj[topicUuid].subtopics[subtopicUuid].micros[
          microUuid
        ];
      }

      return {
        ...state,
        subtopics: newSubtopicObj
      };
    }
    case 'selectTopic': {
      const { topic } = action.payload;

      const newTopicObj = _.cloneDeep(state.topics);
      const topicKey = `${topic.uuid}`;

      if (newTopicObj[topicKey]) {
        // shouldn't be possible but just in case
        if (!newTopicObj[topicKey].micros) newTopicObj[topicKey].micros = {};
        topic.subtopics.forEach(subtopic => {
          subtopic.microcompetencies.forEach(micro => {
            newTopicObj[topicKey].micros[micro.uuid] = micro;
          });
        });
      } else {
        newTopicObj[topicKey] = {};
        newTopicObj[topicKey].topic = topic;
        newTopicObj[topicKey].micros = {};

        topic.subtopics.forEach(subtopic => {
          subtopic.microcompetencies.forEach(micro => {
            newTopicObj[topicKey].micros[micro.uuid] = micro;
          });
        });
      }

      return {
        ...state,
        topics: newTopicObj
      };
    }
    case 'selectTopicMicro': {
      const { topic, micro } = action.payload;

      const newTopicObj = _.cloneDeep(state.topics);
      const topicKey = `${topic.uuid}`;

      if (newTopicObj[topicKey]) {
        if (!newTopicObj[topicKey].micros) newTopicObj[topicKey].micros = {};

        newTopicObj[topicKey].micros[micro.uuid] = micro;
      } else {
        newTopicObj[topicKey] = {};
        newTopicObj[topicKey].topic = topic;
        newTopicObj[topicKey].micros = {};
        newTopicObj[topicKey].micros[micro.uuid] = micro;
      }

      return {
        ...state,
        topics: newTopicObj
      };
    }
    case 'deselectTopic': {
      const { topicUuid } = action.payload;
      const newTopicObj = _.cloneDeep(state.topics);

      if (newTopicObj[topicUuid]) delete newTopicObj[topicUuid];

      return {
        ...state,
        topics: newTopicObj
      };
    }
    case 'deselectTopicMicro': {
      const { microUuid, topicUuid } = action.payload;
      const newTopicObj = _.cloneDeep(state.topics);

      if (Object.keys(newTopicObj[topicUuid].micros).length < 2) {
        // last micro in topic
        delete newTopicObj[topicUuid];
      } else {
        delete newTopicObj[topicUuid].micros[microUuid];
      }

      return {
        ...state,
        topics: newTopicObj
      };
    }
    case 'addMicrosToSelected': {
      const { addedMicros, micros } = state;
      const newAddedMicrosObj = _.cloneDeep(addedMicros);

      Object.keys(micros).forEach(topicUuid => {
        if (newAddedMicrosObj[topicUuid]) {
          newAddedMicrosObj[topicUuid] = _.merge(
            {},
            newAddedMicrosObj[topicUuid],
            micros[topicUuid]
          );
        } else {
          newAddedMicrosObj[topicUuid] = micros[topicUuid];
        }
      });

      return {
        ...state,
        addedMicros: newAddedMicrosObj,
        micros: {},
        subtopics: {},
        topics: {},
        showResults: false
      };
    }
    case 'addSubtopicMicrosToSelected': {
      const { addedMicros, subtopics } = state;
      const newAddedMicrosObj = _.cloneDeep(addedMicros);

      Object.keys(subtopics).forEach(topicUuid => {
        if (newAddedMicrosObj[topicUuid]) {
          if (!newAddedMicrosObj[topicUuid].micros)
            newAddedMicrosObj[topicUuid].micros = {}; // shouldn't be possible but just in case

          // loop through subtopics
          Object.keys(subtopics[topicUuid].subtopics).forEach(subtopicUuid => {
            newAddedMicrosObj[topicUuid].micros = _.merge(
              {},
              newAddedMicrosObj[topicUuid].micros,
              subtopics[topicUuid].subtopics[subtopicUuid].micros
            );
          });
        } else {
          newAddedMicrosObj[topicUuid] = {};
          newAddedMicrosObj[topicUuid].topic = subtopics[topicUuid].topic;
          newAddedMicrosObj[topicUuid].micros = {};
          Object.keys(subtopics[topicUuid].subtopics).forEach(subtopicUuid => {
            newAddedMicrosObj[topicUuid].micros = _.merge(
              {},
              newAddedMicrosObj[topicUuid].micros,
              subtopics[topicUuid].subtopics[subtopicUuid].micros
            );
          });
        }
      });

      return {
        ...state,
        addedMicros: newAddedMicrosObj,
        subtopics: {},
        micros: {},
        topics: {},
        showResults: false
      };
    }
    case 'addTopicMicrosToSelected': {
      const { addedMicros, topics } = state;
      const newAddedMicrosObj = _.cloneDeep(addedMicros);

      Object.keys(topics).forEach(topicUuid => {
        if (newAddedMicrosObj[topicUuid]) {
          newAddedMicrosObj[topicUuid] = _.merge(
            {},
            newAddedMicrosObj[topicUuid],
            topics[topicUuid]
          );
        } else {
          newAddedMicrosObj[topicUuid] = topics[topicUuid];
        }
      });

      return {
        ...state,
        addedMicros: newAddedMicrosObj,
        micros: {},
        subtopics: {},
        topics: {},
        showResults: false
      };
    }
    case 'addUpdatdTopicToSelected': {
      const { addedMicros, editTopicMicros, editTopicUuid } = state;
      const newAddedMicrosObj = _.cloneDeep(addedMicros);

      if (Object.keys(editTopicMicros).length > 0) {
        newAddedMicrosObj[editTopicUuid].micros = _.cloneDeep(editTopicMicros);
      } else {
        // delete topic
        delete newAddedMicrosObj[editTopicUuid];
      }

      return {
        ...state,
        view: 'search',
        editTopicUuid: '',
        addedMicros: newAddedMicrosObj,
        micros: {},
        subtopics: {},
        topics: {},
        editTopicMicros: {},
        showResults: false
      };
    }
    case 'clearForm':
      return initialMicroSearchState;
    default:
      throw new Error();
  }
};

const sethaveFetchedCompetencyCollection = () => ({
  type: 'sethaveFetchedCompetencyCollection'
});

const setShowResults = showResults => ({
  type: 'setShowResults',
  payload: {
    showResults
  }
});

const selectMicrocompetency = microcompetency => ({
  type: 'selectMicrocompetency',
  payload: {
    microcompetency
  }
});

const deselectMicrocompetency = (microcompetencyUuid, topicUuid) => ({
  type: 'deselectMicrocompetency',
  payload: {
    microcompetencyUuid,
    topicUuid
  }
});

const selectSubtopic = subtopic => ({
  type: 'selectSubtopic',
  payload: {
    subtopic
  }
});

const selectSubtopicMicro = (micro, subtopic) => ({
  type: 'selectSubtopicMicro',
  payload: {
    subtopic,
    micro
  }
});

const deselectSubtopic = (subtopicUuid, topicUuid) => ({
  type: 'deselectSubtopic',
  payload: {
    subtopicUuid,
    topicUuid
  }
});

const deselectSubtopicMicro = (microUuid, subtopicUuid, topicUuid) => ({
  type: 'deselectSubtopicMicro',
  payload: {
    microUuid,
    subtopicUuid,
    topicUuid
  }
});

const selectTopic = topic => ({
  type: 'selectTopic',
  payload: {
    topic
  }
});

const selectTopicMicro = (micro, topic) => ({
  type: 'selectTopicMicro',
  payload: {
    topic,
    micro
  }
});

const deselectTopic = topicUuid => ({
  type: 'deselectTopic',
  payload: {
    topicUuid
  }
});

const deselectTopicMicro = (microUuid, topicUuid) => ({
  type: 'deselectTopicMicro',
  payload: {
    microUuid,
    topicUuid
  }
});

export {
  initialMicroSearchState,
  microSearchReducer,
  sethaveFetchedCompetencyCollection,
  setShowResults,
  selectMicrocompetency,
  deselectMicrocompetency,
  selectSubtopic,
  selectSubtopicMicro,
  deselectSubtopic,
  deselectSubtopicMicro,
  selectTopic,
  selectTopicMicro,
  deselectTopic,
  deselectTopicMicro
};
