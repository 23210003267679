import fetch from '../helpers/fetch';

const getCurrentResultCards = partUuid =>
  fetch(`/api/grade-summary/part/${partUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getCurrentResultCards };
