import fetch from '../helpers/fetch';

const getDegreeLevels = () =>
  fetch(`/api/degree-levels`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getProgramCips = () =>
  fetch(`/api/program-cips`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getProgramDisciplines = cipUuid =>
  fetch(`/api/program-cipcode/${cipUuid}/program-disciplines`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getProgramCodes = () =>
  fetch(`/api/program-codes`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getAccreditationAgencies = () =>
  fetch(`/api/accreditation-agencies`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getInstitutionIdTypes = () =>
  fetch(`/api/institution-id-types`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getDegreeLevels,
  getProgramCips,
  getProgramDisciplines,
  getProgramCodes,
  getAccreditationAgencies,
  getInstitutionIdTypes
};
