import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import momentTz from 'moment-timezone';
import PropTypes from 'prop-types';
import { Chip, Grid } from '@material-ui/core';
import { Body1, ButtonInline, ButtonPrimary } from '@xcomp/xcomp-design-library';
import { DateIcon, UsersIcon, WarningIcon } from '@xcomp/xcomp-design-library/dist/icons';

import { validateSyllabusSelectUserSingle } from '../../../../../helpers/validation/validateSyllabus';
import { validateSelectField } from '../../../../../helpers/validation/validateGeneric';
import { optionScheduleType } from '../../../../../helpers/options';

import SelectUserFieldContainer from '../../../../Library/SelectUserField/SelectUserFieldContainer';
import withValidation from '../../../../Library/FieldWithError/FieldWithError';
import {
  validateEncounterStartDateField,
  validateEncounterEndDateField,
  validateEncounterLeadPercent,
  validateEncounterFacultyAssigned
} from '../../../../../helpers/validation/validateEncounter';
import { generateMaxRevus } from '../../../../../helpers/generators';
import { convertNullToString } from '../../../../../helpers/utilities';
import DateTime from '../../../../Library/DateTime';
import SyllabusSubHeader from '../../SyllabusSubHeader';
import FormSelectError from '../../../../Library/FormSelectError';
import FormField from '../../../../Library/FormField';
import EncounterOtherFields from './EncounterOtherFields';
import { formatFullName } from '../../../../../helpers/format/user.format';
import SimpleModal from '../../../../Library/Modal/SimpleModal';

const DatetimePickerSC = styled(DateTime)`
  height: 100%;
`;

const WarningMessage = styled.div`
  cursor: pointer;
  color: ${props => props.theme.colors.status.warning};
  display: flex;
  align-items: center;
  
  & > svg {
    margin-right: 5px;
  }

  &:hover {
    color: ${props => props.theme.colors.status.error};
  }
`

const StaffDisplay = styled(Body1)`
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
`;

const ModalHeader = styled.div`
  padding: 1rem 1.25rem;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  font-size: 24px;
`

const ModalBody = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;

  h3 {
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

const ModalActions = styled.div`
  padding: 1rem 1.25rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.5rem;
`;

const Description = styled.div`
  margin-bottom: 10px;
`;

const ChipSC = styled(Chip)`
  && {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
  }
`;


const SelectUserFieldWithError = withValidation(SelectUserFieldContainer);
const DatetimePickerWithError = withValidation(DatetimePickerSC);

const NonLinkedEncounterFields = ({
  location,
  restrictEdit,
  scheduleType,
  hasErrors,
  startDate,
  encounter_faculty_assigned,
  handleDateChange,
  fieldInFocus,
  term,
  endDate,
  optionsFaculty,
  facultyLead,
  faculty_lead,
  leadPercent,
  encounterUuid,
  notes,
  handleEncounterChange,
  handleFieldInFocusValidation,
  handleParticpatingFaculty,
  handleUserChange
}) => {
  const [invalidFacultiesModal, setInvalidFacultiesModal] = useState(false);

  const invalidFaculties = [];
  const participating = [];
  encounter_faculty_assigned.forEach(faculty => {
    if (!optionsFaculty.find(op => op.value === faculty.uuid)) {
      invalidFaculties.push(formatFullName(faculty));
    }

    participating.push(faculty.uuid);
  });

  const isSingleType = scheduleType === 'Single';

  const appromixateTimeZone = momentTz.tz.guess();

  const nonMomentStartDate = moment.isMoment(startDate)
    ? startDate.tz(appromixateTimeZone).format()
    : momentTz(startDate).tz(appromixateTimeZone).format();

  const nonMomentEndDate = moment.isMoment(endDate)
    ? endDate.tz(appromixateTimeZone).format()
    : momentTz(endDate).tz(appromixateTimeZone).format();

  const calculatedMaxRevus = isSingleType
    ? generateMaxRevus(nonMomentStartDate, nonMomentEndDate)
    : 0;

  return (
    <>
      <Grid item xs={12}>
        <SyllabusSubHeader>
          <DateIcon />
          Location and Time
        </SyllabusSubHeader>
      </Grid>
      <Grid item xs={12}>
        <FormField
          name="location"
          label="Encounter Location"
          value={convertNullToString(location)}
          onBlur={handleEncounterChange}
          disabled={restrictEdit}
        />
      </Grid>
      <Grid item xs={8}>
        <FormSelectError
          name="scheduleType"
          label="Schedule Type*"
          value={convertNullToString(scheduleType)}
          handleValidation={validateSelectField}
          hasErrors={hasErrors}
          handleChange={handleEncounterChange}
          options={optionScheduleType}
          disabled={restrictEdit}
        />
      </Grid>
      <Grid item xs={4}>
        {scheduleType === 'Single' && (
          <FormField
            name="maxRevus"
            label="Max Revus"
            value={calculatedMaxRevus}
            disabled={true}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <DatetimePickerWithError
          value={startDate}
          name="startDate"
          handleChange={handleDateChange}
          label="Start Time*"
          hasErrors={hasErrors || fieldInFocus === 'startDate'}
          handleValidate={() =>
            validateEncounterStartDateField(
              nonMomentStartDate,
              nonMomentEndDate,
              scheduleType,
              term
            )
          }
          onFocus={() => {
            handleFieldInFocusValidation({
              target: {
                name: 'startDate'
              }
            });
          }}
          disabled={restrictEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <DatetimePickerWithError
          value={endDate}
          name="endDate"
          handleChange={handleDateChange}
          label="End Time*"
          hasErrors={hasErrors || fieldInFocus === 'endDate'}
          handleValidate={() =>
            validateEncounterEndDateField(
              nonMomentEndDate,
              nonMomentStartDate,
              scheduleType,
              term
            )
          }
          onFocus={() => {
            handleFieldInFocusValidation({
              target: {
                name: 'endDate'
              }
            });
          }}
          disabled={restrictEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <SyllabusSubHeader>
          <UsersIcon />
          Designated Faculty
        </SyllabusSubHeader>
      </Grid>
      <Grid item xs={9}>
        <SelectUserFieldWithError
          name="facultyLead"
          handleValidate={validateSyllabusSelectUserSingle}
          hasErrors={hasErrors || fieldInFocus === 'facultyLead'}
          userSelect
          fieldName="facultyLead"
          label="Faculty Lead*"
          handleChange={handleUserChange}
          usersArray={optionsFaculty}
          placeholder="Select"
          values={facultyLead}
          singleValue
          onFocus={() => {
            handleFieldInFocusValidation({
              target: {
                name: 'facultyLead'
              }
            });
          }}
          isDisabled={restrictEdit}
        />
      </Grid>
      {faculty_lead && !optionsFaculty.find(op => op.value === facultyLead) &&
        <Grid item xs={12}>
          <WarningMessage>
            <WarningIcon />
            <div>Faculty lead <b>{formatFullName(faculty_lead)}</b> is not assigned to this part anymore. Please select another faculty.</div>
          </WarningMessage>
        </Grid>
      }
      <Grid item xs={3}>
        <FormField
          name="leadPercent"
          label="Lead Percent"
          value={convertNullToString(leadPercent)}
          onBlur={handleEncounterChange}
          handleValidate={validateEncounterLeadPercent}
          hasErrors={hasErrors || fieldInFocus === 'leadPercent'}
          onFocus={handleFieldInFocusValidation}
          disabled={restrictEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectUserFieldWithError
          userSelect
          name="participatingFaculty"
          handleValidate={value =>
            validateEncounterFacultyAssigned(value, leadPercent)
          }
          hasErrors={hasErrors || fieldInFocus === 'participatingFaculty'}
          fieldName="encounter_faculty_assigned"
          label="Participating Faculty"
          placeholder="Select a user"
          handleChange={event =>
            handleParticpatingFaculty(event, encounterUuid)
          }
          usersArray={optionsFaculty}
          values={participating}
          onFocus={() => {
            handleFieldInFocusValidation({
              target: {
                name: 'participatingFaculty'
              }
            });
          }}
          isDisabled={restrictEdit}
        />
      </Grid>
      {invalidFaculties.length > 0 &&
        <Grid item xs={12}>
          <WarningMessage onClick={() => setInvalidFacultiesModal(true)}>
            <WarningIcon />
            <div>Some participating faculties are not assigned to the part anymore.</div>
          </WarningMessage>
          {invalidFacultiesModal &&
            <SimpleModal
              open={invalidFacultiesModal}
              onClose={() => setInvalidFacultiesModal(false)}
              maxWidth="md"
            >
              <>
                <ModalHeader>Invalid Faculties</ModalHeader>
                <ModalBody>
                  <Description>
                    <div>
                      Below you can find all the faculties that are assigned to this Encounter, but not assigned to the Part anymore. These faculties are not visible in the dropdown and cannot be removed manually using the dropdown.
                    </div>
                    <div style={{marginTop: 10}}>
                      Do you want to clear the participating faculties? <b>Please note that you still have to save the changes.</b>
                    </div>
                  </Description>
                  <StaffDisplay>
                    {invalidFaculties.map(f => <ChipSC key={f} label={f} />)}
                  </StaffDisplay>
                </ModalBody>
                <ModalActions>
                  <ButtonInline style={{marginRight: 10}} onClick={() => setInvalidFacultiesModal(false)}>CANCEL</ButtonInline>
                  <ButtonPrimary 
                    color="secondary" 
                    onClick={() => {
                      handleParticpatingFaculty([]);
                      setInvalidFacultiesModal(false);
                    }}
                  >
                    Clear
                  </ButtonPrimary>
                </ModalActions>
              </>
            </SimpleModal>
          }
        </Grid>
      }
      <EncounterOtherFields
        notes={notes}
        handleEncounterChange={handleEncounterChange}
        restrictEdit={restrictEdit}
      />
    </>
  );
};

NonLinkedEncounterFields.propTypes = {
  encounterUuid: PropTypes.string,
  leadPercent: PropTypes.string,
  location: PropTypes.string,
  scheduleType: PropTypes.string,
  notes: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  facultyLead: PropTypes.string,
  faculty_lead: PropTypes.object,
  encounter_faculty_assigned: PropTypes.array,
  restrictEdit: PropTypes.bool,
  term: PropTypes.object,
  optionsFaculty: PropTypes.arrayOf(PropTypes.object),
  fieldInFocus: PropTypes.string,
  hasErrors: PropTypes.bool,
  handleDateChange: PropTypes.func,
  handleEncounterChange: PropTypes.func,
  handleFieldInFocusValidation: PropTypes.func,
  handleParticpatingFaculty: PropTypes.func,
  handleUserChange: PropTypes.func
};
NonLinkedEncounterFields.defaultProps = {
  encounterUuid: '',
  endDate: '',
  leadPercent: '',
  location: '',
  scheduleType: '',
  notes: '',
  startDate: '',
  facultyLead: '',
  faculty_lead: null,
  encounter_faculty_assigned: [],
  restrictEdit: true,
  term: {},
  optionsFaculty: [],
  fieldInFocus: '',
  hasErrors: false,
  handleDateChange: undefined,
  handleEncounterChange: undefined,
  handleFieldInFocusValidation: undefined,
  handleParticpatingFaculty: undefined,
  handleUserChange: undefined
};

export default NonLinkedEncounterFields;
