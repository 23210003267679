import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tab, Tabs } from '@material-ui/core';
import { formatYYYY } from '../../../helpers/date-fns.utils';
import { ContainerPage, ContainerResize } from '../../Library/Layout';
import Header from '../../Library/Header';
import OpportunityReportPanel from './OpportunityReportPanel';

const TabsSC = styled(Tabs)`
  background-color: ${props => props.theme.colors.white};
  padding-top: 77px;
`;

const OpportunityReports = ({
  basePath,
  cohort,
  currentTab,
  onBackAction,
  path
}) => {
  const headerSubtitle = `cohort ${formatYYYY(cohort.startDate)} - ${formatYYYY(
    cohort.endDate
  )}`;

  return (
    <ContainerResize>
      <Header
        title="Cohort Reports"
        subtitle={headerSubtitle}
        backButtonOverride={onBackAction}
      />
      <TabsSC
        value={currentTab}
        textColor="primary"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab
          component={Link}
          to={`${basePath}/opportunity`}
          label="opportunity"
        />
      </TabsSC>
      <ContainerPage paddingTop="50px">
        <Switch>
          <Route path={`${path}`} exact>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh'
              }}
            >
              Please select a report from the tabs above.
            </div>
          </Route>
          <Route path={`${path}/opportunity`}>
            <OpportunityReportPanel />
          </Route>
        </Switch>
      </ContainerPage>
    </ContainerResize>
  );
};

OpportunityReports.propTypes = {
  basePath: PropTypes.string,
  cohort: PropTypes.object,
  currentTab: PropTypes.number,
  onBackAction: PropTypes.func,
  path: PropTypes.string
};
OpportunityReports.defaultProps = {
  basePath: '',
  cohort: {},
  currentTab: 0,
  onBackAction: undefined,
  path: ''
};

export default OpportunityReports;
