import moment from 'moment';

const validateAssessmentTitle = textField => {
  if (
    !textField ||
    textField.trim() === '' ||
    textField.trim() === 'Untitled Assessment'
  ) {
    return {
      invalid: true,
      message: 'Assessment must have a title'
    };
  }

  if (textField.length > 100) {
    return {
      invalid: true,
      message: 'Assessment title cannot exceed 100 characters'
    };
  }

  return {
    invalid: false
  };
};

const validateAssessmentStartDate = (startDate, termRange) => {
  if (!startDate) {
    return {
      invalid: true,
      message: 'Please select a valid start date'
    };
  }

  if (startDate) {
    const startDateNoTime = moment(startDate, 'YYYY-MM-DD');

    const termStartDate = moment(termRange.startDate, 'YYYY-MM-DD');
    const termEndDate = moment(termRange.endDate, 'YYYY-MM-DD');

    const startDateBeforeTerm = startDateNoTime.isBefore(termStartDate, 'day');
    const startAfterBeforeTerm = startDateNoTime.isAfter(termEndDate, 'day');

    if (startDateBeforeTerm || startAfterBeforeTerm) {
      return {
        invalid: true,
        message: `Start date is out of term range  ${termStartDate.format(
          'MM-DD-YYYY'
        )} to ${termEndDate.format('MM-DD-YYYY')}`
      };
    }
  }

  return {
    invalid: false
  };
};

const validateAssessmentEndDate = (endDate, termRange) => {
  if (!endDate) {
    return {
      invalid: true,
      message: 'Please select a valid end date'
    };
  }

  if (endDate) {
    const endDateNoTime = moment(endDate, 'YYYY-MM-DD');

    const termStartDate = moment(termRange.startDate, 'YYYY-MM-DD');
    const termEndDate = moment(termRange.endDate, 'YYYY-MM-DD');

    const endDateBeforeTerm = endDateNoTime.isBefore(termStartDate, 'day');
    const endDateAfterTerm = endDateNoTime.isAfter(termEndDate, 'day');

    if (endDateBeforeTerm || endDateAfterTerm) {
      return {
        invalid: true,
        message: `End date is out of term range ${termStartDate.format(
          'MM-DD-YYYY'
        )} to ${termEndDate.format('MM-DD-YYYY')}`
      };
    }
  }

  return {
    invalid: false
  };
};

export {
  validateAssessmentTitle,
  validateAssessmentStartDate,
  validateAssessmentEndDate
};
