import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { doGetAssessmentsByPart } from '../../../../redux/actions/assessmentActions';
import { doGetPartFaculty } from '../../../../redux/actions/partActions';
import { assessmentsByTypesAndTechniquesSelector } from '../../../../redux/selectors/assessmentSelectors';
import {
  doClearPartFacultyReport,
  doGetPartFacultyReport
} from '../../../../redux/actions/partReportsActions';
import { partFacultySelector } from '../../../../redux/selectors/partsSelectors';
import PartFacultyReport from './PartFacultyReport';
import {
  sortByDateProperty,
  sortByDecimalPropertyAndFullName
} from '../../../../helpers/utilities';

const PartFacultyReportContainer = ({ partUuid }) => {
  const dispatch = useDispatch();

  const [selectedAssessmentTechniques, setSelectedAssessmentTechniques] =
    useState([]);
  const [selectedAssessmentTypes, setSelectedAssessmentTypes] = useState([]);
  const [sortedFacultyUsers, setSortedFacultyUsers] = useState([]);
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);
  const [reportType, setReportType] = useState('instruction');

  const {
    isLoading,
    partFacultyReportAssessmentData,
    partFacultyReportFacultyData,
    partFacultyReportSummaryData,
    records
  } = useSelector(state => state.partFacultyReportState);

  const partFacultyUsers = useSelector(
    state => partFacultySelector(state, partUuid),
    _.isEqual
  );

  const assessments = useSelector(
    state =>
      assessmentsByTypesAndTechniquesSelector(
        state,
        partUuid,
        selectedAssessmentTechniques,
        selectedAssessmentTypes
      ),
    _.isEqual
  );

  // clear report on unmount
  useEffect(() => {
    return () => dispatch(doClearPartFacultyReport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (partUuid !== '') {
      dispatch(doGetPartFaculty(partUuid));
      dispatch(doGetAssessmentsByPart(partUuid));
    }
  }, [dispatch, partUuid]);

  useEffect(() => {
    if (records > 0) {
      setSortedFacultyUsers(
        [...partFacultyUsers].sort((a, b) =>
          sortByDecimalPropertyAndFullName(
            {
              firstName: b.user?.firstName,
              lastName: b.user?.lastName,
              totalPotentialScore:
                partFacultyReportFacultyData[b.user?.uuid]?.totalPotentialScore
            },
            {
              firstName: a.user?.firstName,
              lastName: a.user?.lastName,
              totalPotentialScore:
                partFacultyReportFacultyData[a.user?.uuid]?.totalPotentialScore
            },
            'totalPotentialScore',
            'firstName',
            'lastName',
            1
          )
        )
      );
    }
  }, [dispatch, partFacultyReportFacultyData, partFacultyUsers, records]);

  const handleApplyPartFacultyReportFilters = (
    reportType,
    filters,
  ) => {
    setSelectedAssessmentTechniques(filters.filteredAssessmentTechniques);
    setSelectedAssessmentTypes(filters.filteredAssessmentTypes);
    setHasAppliedFilters(true);
    dispatch(
      doGetPartFacultyReport(partUuid, reportType, filters)
    );
  };
  return (
    <PartFacultyReport
      assessments={[...assessments].sort((a, b) =>
        sortByDateProperty(a, b, 'startDate')
      )}
      assessmentTechniques={selectedAssessmentTechniques}
      assessmentTypes={selectedAssessmentTypes}
      cumulativePotentialScore={
        partFacultyReportSummaryData.totalPotentialScore ?? '0.00'
      }
      cumulativeRelativeScore={
        partFacultyReportSummaryData.totalRelativeScore ?? '0.00'
      }
      hasAppliedFilters={hasAppliedFilters}
      isLoading={isLoading}
      onApplyPartFacultyReportFilters={handleApplyPartFacultyReportFilters}
      partFacultyReportAssessmentData={partFacultyReportAssessmentData}
      partFacultyReportFacultyData={partFacultyReportFacultyData}
      partFacultyUsers={sortedFacultyUsers}
      partUuid={partUuid}
      reportIsEmpty={records === 0}
      reportType={reportType}
      setReportType={setReportType}
    />
  );
};

PartFacultyReportContainer.propTypes = { partUuid: PropTypes.string };

PartFacultyReportContainer.defaultProps = { partUuid: '' };

export default PartFacultyReportContainer;
