import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Search from '@material-ui/icons/Search';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import { ButtonOutline } from '@xcomp/xcomp-design-library';

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
`;

const SearchChoice = styled(ButtonOutline)`
  && {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primary};
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: upcase;
    border-radius: 5px 0px 0px 5px;
    height: 100%;
    width: 250px;
    &:hover {
      background: ${props => props.theme.colors.primary};
    }
  }
`;

const SearchInput = styled(({ ...rest }) => <InputBase {...rest} />)`
  && {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    border-top: 1px solid ${props => props.theme.colors.grey[300]};
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  }
`;

const SearchIcon = styled(({ ...rest }) => <Search {...rest} />)`
  && {
    height: 100%;
    width: 50px;
    border-right: solid 1px ${props => props.theme.colors.grey[300]};
    border-top: solid 1px ${props => props.theme.colors.grey[300]};
    border-bottom: solid 1px ${props => props.theme.colors.grey[300]};
    color: ${props => props.theme.colors.grey[300]};
    padding: 10px;
    border-radius: 0px 5px 5px 0px;
  }
`;

export default function SearchMulti({
  placeholder,
  searchBy,
  searchInput,
  options,
  className,
  handleSearch,
  ...rest
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectLabel, setSelectLabel] = useState(placeholder);
  const [selectField, setSelectField] = useState('');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (searchBy && searchBy !== '') {
      setSelectField(searchBy);
    }

    if (searchBy && searchBy !== '') {
      const result = options.filter(option => option.field === searchBy);

      const { label } = result[0];

      setSelectLabel(label);
    }

    if (searchInput && searchInput !== '') {
      setSearchText(searchInput);
    }
  }, [options, searchInput, searchBy]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelection = option => {
    setSelectLabel(option.label);
    setSelectField(option.field);
  };

  const handleSearchText = event => {
    setSearchText(event.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSearch(selectField, searchText);
    }
  };

  return (
    <Layout className={className}>
      <SearchChoice
        aria-owns={anchorEl ? 'search-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {selectLabel}
        <ArrowDropDown />
      </SearchChoice>
      <Menu
        id="search-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem
            key={option.field}
            onClick={() => {
              handleClose();
              handleSelection(option);
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <SearchInput
        id="search-multi-input"
        onChange={event => handleSearchText(event)}
        onKeyDown={handleKeyDown}
        placeholder="Search"
        value={searchText}
        {...rest}
      />
      <SearchIcon
        id="search-submit-icon"
        onClick={() => handleSearch(selectField, searchText)}
        {...rest}
      />
    </Layout>
  );
}

SearchMulti.propTypes = {
  placeholder: PropTypes.string,
  searchBy: PropTypes.string,
  searchInput: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  handleSearch: PropTypes.func
};

SearchMulti.defaultProps = {
  placeholder: 'Select',
  searchBy: undefined,
  searchInput: undefined,
  options: [],
  className: '',
  handleSearch: undefined
};
