import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Button } from '@material-ui/core';
import { Heading2 } from '@xcomp/xcomp-design-library';
import { Panel, PanelHeader } from '../../../Library/Layout';
import FormSelectError from '../../../Library/FormSelectError';
import { validateSelectField } from '../../../../helpers/validation/validateGeneric';

const FilterContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
`;

const FilterBody = styled(Grid)`
  padding-left: 30px;
`;

const PanelHeaderSC = styled(PanelHeader)`
  padding-left: 32px;
`;

const ButtonSC = styled(Button)`
  && {
    margin-bottom: 16px;
  }
`;

const CohortApplicantDemographicFilters = ({
  reportTypeOptions,
  onApplyReportType,
  onSelectReportType,
  reportTypeSelectError,
  selectedReportType
}) => {
  return (
    <Panel>
      <PanelHeaderSC>
        <Heading2>Demographic Filters</Heading2>
      </PanelHeaderSC>
      <FilterContainer>
        <FilterBody container spacing={2}>
          <Grid item sm={4}>
            <FormSelectError
              label="Filter By"
              name="filterBySelect"
              options={reportTypeOptions}
              value={selectedReportType}
              handleChange={onSelectReportType}
              hasErrors={reportTypeSelectError}
              handleValidation={validateSelectField}
            />
          </Grid>
        </FilterBody>
        <Grid container justifyContent="flex-end" alignItems="center">
          <ButtonSC
            color="primary"
            variant="contained"
            onClick={onApplyReportType}
          >
            Apply
          </ButtonSC>
        </Grid>
      </FilterContainer>
    </Panel>
  );
};

CohortApplicantDemographicFilters.propTypes = {
  reportTypeOptions: PropTypes.array,
  onApplyReportType: PropTypes.func,
  onSelectReportType: PropTypes.func,
  selectedReportType: PropTypes.string,
  reportTypeSelectError: PropTypes.bool
};
CohortApplicantDemographicFilters.defaultProps = {
  reportTypeOptions: [],
  onApplyReportType: undefined,
  onSelectReportType: undefined,
  selectedReportType: '',
  reportTypeSelectError: false
};
export default CohortApplicantDemographicFilters;
