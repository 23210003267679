import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2 } from '@xcomp/xcomp-design-library';
import UnsavedChangesModal from '../../Library/Modal/UnsavedChangesModal/UnsavedChangesModal';
import {
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader
} from '../../Library/Layout';
import Header from '../../Library/Header';
import FormSelect from '../../Library/FormSelect';
import { optionsSectionGradeDataSource } from '../../../helpers/options';
import { ButtonOutline } from '../../Library/ButtonSC';
import PartWeightsFormContainer from './PartWeightsForm/PartWeightsFormContainer';

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const DataSource = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const SubmitButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const DataSourceSelect = styled(FormSelect)`
  && {
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    max-width: 300px;
  }
`;

const ButtonOutlineSC = styled(ButtonOutline)``;

export default function SectionGradeSettings({
  sectionTitle,
  courseNumber,
  courseTitle,
  dataSource,
  sectionUuid,
  handleDataSourceChange,
  handleModalClose,
  handleModalOpen,
  handleProceed,
  isUnsavedChangesModalOpen,
  handleSubmitDataSource,
  unsavedChanges,
  setUnsavedChange,
  removeUnsavedChange,
  savedDataSource
}) {
  const headerSubtitle = `${courseNumber} ● ${courseTitle}`;

  return (
    <ContainerResize>
      <UnsavedChangesModal
        modalOpen={isUnsavedChangesModalOpen}
        message="The form has unsaved changes. Click cancel to go back and update the form or click Discard Changes to return to the syllabus management page."
        proceedButtonText="Discard Changes"
        handleProceed={handleProceed}
        handleModalClose={handleModalClose}
      />
      <Header
        title={`Section ${sectionTitle}`}
        subtitle={headerSubtitle}
        backButtonOverride={handleModalOpen}
      />
      <ContainerPage>
        <Panel>
          <PanelHeader paddingLeft="24px">
            <Heading2>Grade Calculation</Heading2>
          </PanelHeader>
          <PanelPadding>
            <DataSource>
              <DataSourceSelect
                value={dataSource || ''}
                options={optionsSectionGradeDataSource}
                handleChange={handleDataSourceChange}
                name="dataSource"
                label="Data Source"
              />
            </DataSource>
            <SubmitButtonRow>
              <ButtonOutlineSC
                onClick={handleSubmitDataSource}
                disabled={!unsavedChanges.dataSource}
              >
                Apply
              </ButtonOutlineSC>
            </SubmitButtonRow>
          </PanelPadding>
        </Panel>
      </ContainerPage>
      {savedDataSource === 'PARTS' && sectionUuid && (
        <ContainerPage paddingTop="48px">
          <PartWeightsFormContainer
            sectionUuid={sectionUuid}
            unsavedChanges={unsavedChanges}
            setUnsavedChange={setUnsavedChange}
            removeUnsavedChange={removeUnsavedChange}
          />
        </ContainerPage>
      )}
    </ContainerResize>
  );
}

SectionGradeSettings.propTypes = {
  sectionTitle: PropTypes.string,
  dataSource: PropTypes.string,
  courseNumber: PropTypes.string,
  courseTitle: PropTypes.string,
  sectionUuid: PropTypes.string,
  handleDataSourceChange: PropTypes.func,
  handleModalClose: PropTypes.func,
  handleModalOpen: PropTypes.func,
  handleProceed: PropTypes.func,
  handleSubmitDataSource: PropTypes.func,
  isUnsavedChangesModalOpen: PropTypes.bool,
  unsavedChanges: PropTypes.object,
  setUnsavedChange: PropTypes.func,
  removeUnsavedChange: PropTypes.func,
  savedDataSource: PropTypes.string
};

SectionGradeSettings.defaultProps = {
  sectionTitle: '',
  dataSource: '',
  courseNumber: '',
  courseTitle: '',
  sectionUuid: '',
  handleDataSourceChange: undefined,
  handleModalClose: undefined,
  handleModalOpen: undefined,
  handleProceed: undefined,
  handleSubmitDataSource: undefined,
  isUnsavedChangesModalOpen: false,
  unsavedChanges: {},
  setUnsavedChange: undefined,
  removeUnsavedChange: undefined,
  savedDataSource: ''
};
