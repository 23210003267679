import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { EditIcon } from '@xcomp/xcomp-design-library/dist/icons';

import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import Classification from './Classification';
import ProgramSubjectWeight from './Cognitive/ProgramSubjectWeight';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .accordion_row_label {
    flex-grow: 2;
  }

  && {
    .accordion_row_label div {
      letter-spacing: 0.15;
    }
  }

  width: 100%;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;
const Label = styled.div`
  color: ${props => props.theme.colors.grey[500]};
`;
const Input = styled.div`
  color: ${props => props.theme.fontColors.darker};
  margin-left: 52px;
  font-size: 16px;
`;

export default function Source({ category, programUuid }) {
  const urlProgram = `/program-management/editor-subject/${programUuid}`;
  const urlMilestone = `/program-management/editor-subject-exam/${programUuid}`;

  return (
    <>
      <Accordion
        rowOffset="66px"
        label={
          <Group>
            <Label>Transcript Source</Label>
            <Input>{`${category}-Program`}</Input>
          </Group>
        }
        columnOne={
          category === 'Cognitive' ? (
            <ProgramSubjectWeight programUuid={programUuid} />
          ) : (
            ''
          )
        }
        columnTwo={
          category === 'Cognitive' ? (
            <Link to={urlProgram}>
              <EditIcon />
            </Link>
          ) : (
            ''
          )
        }
      >
        <Classification
          programUuid={programUuid}
          category={category}
          source="Program"
        />
      </Accordion>
      <Accordion
        rowOffset="66px"
        label={
          <Group>
            <Label>Transcript Source</Label>
            <Input>{`${category}-Milestone`}</Input>
          </Group>
        }
        columnTwo={
          category === 'Cognitive' ? (
            <Link to={urlMilestone}>
              <EditIcon />
            </Link>
          ) : (
            ''
          )
        }
      >
        <Classification
          programUuid={programUuid}
          category={category}
          source="Milestone"
        />
      </Accordion>
    </>
  );
}

Source.propTypes = {
  category: PropTypes.string,
  programUuid: PropTypes.string
};

Source.defaultProps = {
  category: undefined,
  programUuid: undefined
};
