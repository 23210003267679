import React, { useReducer, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _ from 'lodash';

import {
  validateInputString,
  validateSelectField
} from '../../../../helpers/validation/validateGeneric';
import { filterUniqueOptionsByLabel } from '../../../../helpers/utilities';
import {
  validateEncounterEndDateField,
  validateEncounterLeadPercent,
  validateEncounterFacultyAssigned,
  validateEncounterTitle,
  validateSingleLearningObjectiveRevus,
  validateTotalLearningObjectiveRevus,
  validateEncounterStartDateField
} from '../../../../helpers/validation/validateEncounter';

import { encountersSelector } from '../../../../redux/selectors/encountersSelectors';
import { encounterTypesSelector } from '../../../../redux/selectors/encounterTypesSelectors';

import {
  doPostEncounter,
  doPutEncounterDraft,
  doPutEncounterFinal,
  doPatchEncounterApprove,
  doDeleteEncounter
} from '../../../../redux/actions/encounterActions';
import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';

import { doGetLoverbs } from '../../../../redux/actions/loverbActions';
import { doClearUpdateEncounter } from '../../../../redux/actions/syllabusActions';

import EncounterForm from './EncounterForm';

import { generateMaxRevus } from '../../../../helpers/generators';

import {
  encounterFormReducer,
  encounterFormInitialState
} from './encounterFormState';

export default function EncounterFormContainer({
  partUuid,
  restrictEdit,
  dat,
  existingItem,
  facultyOptions
}) {
  const dispatch = useDispatch();
  const guessTimeZone = moment.tz.guess();
  const [state, encounterDispatch] = useReducer(
    encounterFormReducer,
    encounterFormInitialState
  );
  const [hasErrors, setHasErrors] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fieldInFocus, setFieldInFocus] = useState('');
  const term = _.get(dat, 'term');

  const optionsEncounterTypes = useSelector(
    state => encounterTypesSelector(state),
    _.isEqual
  )
    .map(encounterType => ({
      value: encounterType.uuid,
      label: encounterType.name,
      order: encounterType.order
    }))
    .sort((a, b) => a.order - b.order);

  const filteredEncounterTypeOptions = filterUniqueOptionsByLabel(
    optionsEncounterTypes
  );

  const optionsFaculty = facultyOptions.map(user => ({
    value: user.uuid,
    label: `${user.firstName} ${user.lastName}`
  }));

  const onGeneralErrorNotification = errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage));

  const encounters = useSelector(
    state => encountersSelector(state, state.syllabusState.selectedPartUuid),
    _.isEqual
  );

  const selectedSchoolUuid = useSelector(
    state => state.userState.selectedSchoolUuid
  );

  const { successfullyUpdatedEncounter } = useSelector(
    state => state.syllabusState
  );

  const setInitialState = useCallback(() => {
    if (existingItem) {
      encounterDispatch({
        type: 'encounterExist',
        encounter: existingItem
      });

      setFieldInFocus('');
    } else {
      encounterDispatch({
        type: 'encounterNew'
      });

      setFieldInFocus('');
    }
  }, [existingItem]);

  const {
    uuid,
    encounterTypeUuid,
    endDate,
    leadPercent,
    location,
    maxRevus,
    notes,
    scheduleType,
    startDate,
    status,
    title,
    facultyLead,
    faculty_lead,
    encounter_faculty_assigned,
    objectives,
    existing,
    linkedAssessmentUuid,
    hasUnsavedChanges
  } = state;

  useEffect(() => {
    setInitialState();
  }, [existingItem, setInitialState]);

  useEffect(() => {
    if (selectedSchoolUuid) {
      dispatch(doGetLoverbs(selectedSchoolUuid));
    }
  }, [dispatch, selectedSchoolUuid]);

  useEffect(() => {
    if (successfullyUpdatedEncounter) {
      dispatch(doClearUpdateEncounter());
    }
  }, [dispatch, successfullyUpdatedEncounter]);

  const thisEncounterType = filteredEncounterTypeOptions.find(
    encounterType => encounterType.value === encounterTypeUuid
  );

  const isLinkedEncounter = thisEncounterType
    ? thisEncounterType.label.toLowerCase().includes('linked')
    : false;

  const generateEncounterNumber = () => {
    if (existingItem && encounters) {
      const sort = encounters.sort(
        (a, b) => moment(a.startDate) - moment(b.startDate)
      );

      const index =
        _.findIndex(sort, item => item.uuid === existingItem.uuid) + 1;

      return index;
    }

    return 0;
  };

  const handleEncounterChange = event => {
    const { name, value } = event.target;

    switch (name) {
      case 'encounterTypeUuid': {
        const selectedEncounterType = filteredEncounterTypeOptions.find(
          encounterType => encounterType.value === value
        );

        encounterDispatch({
          type: 'encounterTypeUuid',
          payload: {
            encounterTypeUuid: value,
            encounterType: selectedEncounterType.label
          }
        });
        break;
      }
      case 'title': {
        encounterDispatch({
          type: 'title',
          title: value
        });
        break;
      }
      case 'location': {
        encounterDispatch({
          type: 'location',
          location: value
        });
        break;
      }
      case 'scheduleType': {
        encounterDispatch({
          type: 'scheduleType',
          scheduleType: value
        });
        break;
      }
      case 'maxRevus': {
        encounterDispatch({
          type: 'maxRevus',
          maxRevus: value
        });
        break;
      }
      case 'leadPercent': {
        encounterDispatch({
          type: 'leadPercent',
          leadPercent: value
        });
        break;
      }
      case 'notes': {
        encounterDispatch({
          type: 'notes',
          notes: value
        });
        break;
      }
      case 'linkedAssessment': {
        encounterDispatch({
          type: 'linkedAssessmentChange',
          payload: { linkedAssessmentUuid: value }
        });
        break;
      }
      default:
        break;
    }
  };

  const handleDateChange = (name, date) => {
    switch (name) {
      case 'startDate': {
        encounterDispatch({
          type: 'startDate',
          startDate: date
        });
        break;
      }
      case 'endDate': {
        encounterDispatch({
          type: 'endDate',
          endDate: date
        });
        break;
      }
      default:
        break;
    }
  };

  const handleUserChange = (values, name) => {
    switch (name) {
      case 'facultyLead': {
        encounterDispatch({
          type: 'facultyLead',
          facultyLead: values
        });
        break;
      }
      default:
        break;
    }
  };

  const handleFieldInFocusValidation = event => {
    const { name } = event.target;
    setFieldInFocus(name);
  };

  const handleParticpatingFaculty = selections => {
    const faculty = selections.map(ids => {
      return { uuid: ids, encounterUuid: uuid };
    });

    encounterDispatch({
      type: 'encounterFacultyAssigned',
      encounterFacultyAssigned: faculty
    });
  };

  const handleObjectiveAdd = () => {
    encounterDispatch({
      type: 'objectiveAdd'
    });
  };

  const handleObjectiveChange = (event, objectiveUuid) => {
    const { name, value } = event.target;

    switch (name) {
      case 'learningObjective': {
        encounterDispatch({
          type: 'learningObjective',
          objectiveUuid,
          learningObjective: value
        });
        break;
      }
      case 'revus': {
        encounterDispatch({
          type: 'revus',
          objectiveUuid,
          revus: value
        });
        break;
      }
      case 'loverb': {
        encounterDispatch({
          type: 'loverbUuid',
          objectiveUuid,
          loverbUuid: value
        });
        break;
      }

      default:
        break;
    }
  };

  const handleObjectiveMove = (objectiveUuid, direction) => {
    switch (direction) {
      case 'up': {
        encounterDispatch({
          type: 'objectiveUp',
          objectiveUuid
        });
        break;
      }
      case 'down': {
        encounterDispatch({
          type: 'objectiveDown',
          objectiveUuid
        });
        break;
      }
      default:
        break;
    }
  };

  const handleObjectiveMicro = (objectiveUuid, microcompetency) => {
    encounterDispatch({
      type: 'microcompetency',
      objectiveUuid,
      microcompetency
    });
  };

  const handleObjectiveMicroRemove = objectiveUuid => {
    encounterDispatch({
      type: 'microcompetencyRemove',
      objectiveUuid
    });
  };

  const handleObjectiveDelete = objectiveUuid => {
    encounterDispatch({
      type: 'objectiveRemove',
      objectiveUuid
    });
  };

  const buildEncounter = () => {
    const startDateTimeZone = startDate
      ? moment(startDate).tz(guessTimeZone)
      : null;

    const endDateTimeZone = endDate ? moment(endDate).tz(guessTimeZone) : null;

    const objectivesNew = objectives.map(objective => {
      const {
        objectiveNumber,
        loverb,
        learningObjective,
        revus,
        microcompetency,
        microcompetencyUuid,
        microcompetencyTitle,
        loverbUuid,
        isNew
      } = objective;

      if (isNew) {
        return {
          objectiveNumber,
          loverb,
          learningObjective,
          revus,
          microcompetency,
          microcompetencyUuid,
          microcompetencyTitle,
          loverbUuid,
          encounterUuid: uuid
        };
      } else {
        return objective;
      }
    });

    let calcMaxRevus = null;
    if (scheduleType === 'Single') {
      calcMaxRevus = generateMaxRevus(startDate, endDate);
    }

    return {
      ...state,
      partUuid,
      startDate: startDateTimeZone,
      endDate: endDateTimeZone,
      maxRevus: calcMaxRevus,
      objectives: objectivesNew
    };
  };

  const buildLinkedEncounter = () => {
    return {
      ...state,
      uuid,
      partUuid,
      encounterTypeUuid,
      status,
      title,
      linkedAssessmentUuid
    };
  };

  const handleEncounterSaveDraft = () => {
    const request = isLinkedEncounter
      ? buildLinkedEncounter()
      : buildEncounter();

    if (isLinkedEncounter && !linkedAssessmentUuid) {
      setHasErrors(true);
      setFieldInFocus('');
      onGeneralErrorNotification(
        'Linked encounter types MUST be linked to an Assessment to be saved. Please select or create an assessment to link first.'
      );
    } else if (existing) {
      dispatch(doPutEncounterDraft(request));
      setHasErrors(false);
      setFieldInFocus('');
    } else {
      dispatch(doPostEncounter({ ...request, partUuid }));
      setHasErrors(false);
      setFieldInFocus('');
    }
  };

  const validate = () => {
    const titleError = validateEncounterTitle(title);
    const encounterTypeError = validateSelectField(encounterTypeUuid);
    const scheduleTypeError = validateSelectField(scheduleType);
    const facultyLeadError = validateSelectField(facultyLead);
    const leadPercentError = validateEncounterLeadPercent(leadPercent);
    const encounterFacultyAssignedError = validateEncounterFacultyAssigned(
      encounter_faculty_assigned,
      leadPercent
    );

    const nonMomentStartDate = moment.isMoment(startDate)
      ? startDate.tz(guessTimeZone).format()
      : moment(startDate).tz(guessTimeZone).format();

    const nonMomentEndDate = moment.isMoment(endDate)
      ? endDate.tz(guessTimeZone).format()
      : moment(endDate).tz(guessTimeZone).format();

    const startDateError = validateEncounterStartDateField(
      nonMomentStartDate,
      nonMomentEndDate,
      scheduleType,
      term
    );
    const endDateError = validateEncounterEndDateField(
      nonMomentEndDate,
      nonMomentStartDate,
      scheduleType,
      term
    );

    const objectiveErrors = { invalid: false };

    const startDateTimeZone = startDate
      ? moment(startDate).tz(guessTimeZone)
      : null;

    const endDateTimeZone = endDate ? moment(endDate).tz(guessTimeZone) : null;

    const calculatedMaxRevus =
      scheduleType !== 'Multi'
        ? generateMaxRevus(startDateTimeZone, endDateTimeZone)
        : undefined;

    if (objectives && objectives.length > 0) {
      objectives.forEach(obj => {
        const loverbUuidError = validateSelectField(obj.loverbUuid);
        const learningObjectiveError = validateInputString(
          obj.learningObjective
        );
        const microcompetencyError = validateInputString(obj.microcompetency);
        const revusError = validateSingleLearningObjectiveRevus(obj.revus);

        if (
          loverbUuidError.invalid ||
          learningObjectiveError.invalid ||
          microcompetencyError.invalid ||
          revusError.invalid
        ) {
          objectiveErrors.invalid = true;
        }
      });

      const revusError = validateTotalLearningObjectiveRevus(
        objectives,
        calculatedMaxRevus
      );

      if (!objectiveErrors && scheduleType !== 'Multi' && revusError.invalid) {
        objectiveErrors.invalid = true;
      }
    } else {
      objectiveErrors.invalid = true;
    }

    return {
      title: titleError,
      encounterType: encounterTypeError,
      scheduleType: scheduleTypeError,
      startDate: startDateError,
      endDate: endDateError,
      facultyLead: facultyLeadError,
      leadPercent: leadPercentError,
      encounterFacultyAssigned: encounterFacultyAssignedError,
      objectives: objectiveErrors
    };
  };

  const validateLinkedEncounter = () => {
    const titleError = validateEncounterTitle(title);
    const encounterTypeError = validateSelectField(encounterTypeUuid);
    const linkedAssessmentError = validateSelectField(linkedAssessmentUuid);

    return {
      title: titleError,
      encounterType: encounterTypeError,
      linkedAssessment: linkedAssessmentError
    };
  };

  const passValidation = validationErrors => {
    const errorList = _.mapValues(validationErrors, value => value.invalid);
    const invalids = _.map(errorList, value => value);
    const check = _.filter(invalids, value => value === true);

    if (check.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleEncounterFinish = () => {
    const errors = isLinkedEncounter ? validateLinkedEncounter() : validate();

    const request = isLinkedEncounter
      ? buildLinkedEncounter()
      : buildEncounter();

    if (passValidation(errors)) {
      dispatch(doPutEncounterFinal(request));
      setHasErrors(false);
      setFieldInFocus('');
    } else {
      setHasErrors(true);
    }
  };

  const handleEncounterApprove = () => {
    if (status === 'Complete') {
      const errors = isLinkedEncounter ? validateLinkedEncounter() : validate();

      if (passValidation(errors)) {
        dispatch(doPatchEncounterApprove(uuid));
        setHasErrors(false);
        setFieldInFocus('');
      } else {
        setHasErrors(true);
      }
    } else {
      onGeneralErrorNotification(
        'This encounter is currently set to In Progress. You must first save is as Complete before this encounter can be Approved'
      );
    }
  };

  const handleEncounterDeleteOpen = () => {
    setOpenModal(true);
  };

  const handleEncounterDeleteClose = yes => {
    if (yes) {
      dispatch(doDeleteEncounter(uuid));
    }

    setOpenModal(false);
  };

  return (
    <EncounterForm
      encounterUuid={uuid}
      isLinkedEncounter={isLinkedEncounter}
      encounterTypeUuid={encounterTypeUuid}
      endDate={endDate}
      leadPercent={leadPercent}
      location={location}
      maxRevus={maxRevus}
      notes={notes}
      scheduleType={scheduleType}
      startDate={startDate}
      status={status}
      title={title}
      facultyLead={facultyLead}
      faculty_lead={faculty_lead}
      encounter_faculty_assigned={encounter_faculty_assigned}
      objectives={objectives}
      openModal={openModal}
      hasErrors={hasErrors}
      restrictEdit={restrictEdit}
      term={term}
      optionsFaculty={optionsFaculty}
      optionsEncounterTypes={filteredEncounterTypeOptions}
      fieldInFocus={fieldInFocus}
      existing={existing}
      partUuid={partUuid}
      linkedAssessmentUuid={linkedAssessmentUuid}
      thisEncounterType={thisEncounterType}
      hasUnsavedChanges={hasUnsavedChanges}
      onDiscardChanges={setInitialState}
      encounterNumber={generateEncounterNumber()}
      handleFieldInFocusValidation={handleFieldInFocusValidation}
      handleEncounterChange={handleEncounterChange}
      handleDateChange={handleDateChange}
      handleUserChange={handleUserChange}
      handleParticpatingFaculty={handleParticpatingFaculty}
      handleObjectiveAdd={handleObjectiveAdd}
      handleObjectiveChange={handleObjectiveChange}
      handleObjectiveMove={handleObjectiveMove}
      handleObjectiveDelete={handleObjectiveDelete}
      handleObjectiveMicro={handleObjectiveMicro}
      handleObjectiveMicroRemove={handleObjectiveMicroRemove}
      handleEncounterSaveDraft={handleEncounterSaveDraft}
      handleEncounterFinish={handleEncounterFinish}
      handleEncounterApprove={handleEncounterApprove}
      handleEncounterDeleteOpen={handleEncounterDeleteOpen}
      handleEncounterDeleteClose={handleEncounterDeleteClose}
    />
  );
}

EncounterFormContainer.propTypes = {
  restrictEdit: PropTypes.bool,
  existingItem: PropTypes.object,
  dat: PropTypes.object,
  facultyOptions: PropTypes.array,
  partUuid: PropTypes.string
};

EncounterFormContainer.defaultProps = {
  restrictEdit: false,
  existingItem: undefined,
  dat: {},
  facultyOptions: [],
  partUuid: ''
};
