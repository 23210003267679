import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import InstitutionLevel from './InstitutionLevel';
import { selectRigorInstitutions } from '../../../../../redux/selectors/rigorIndexSelectors';

export default function InstitutionLevelContainer({
  displayName,
  programUuid
}) {
  const rigorInstitutions = useSelector(state =>
    selectRigorInstitutions(state, programUuid)
  );

  return (
    <InstitutionLevel
      displayName={displayName}
      programUuid={programUuid}
      rigorInstitutions={rigorInstitutions}
    />
  );
}

InstitutionLevelContainer.propTypes = {
  displayName: PropTypes.bool,
  programUuid: PropTypes.string
};

InstitutionLevelContainer.defaultProps = {
  displayName: false,
  programUuid: undefined
};
