/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../reportUtils';
import { formatMMMddyyyy } from '../../../../helpers/date-fns.utils';
import { tintColor } from '@xcomp/xcomp-design-library/dist/helpers';

const HeaderCell = styled.th`
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  background-color: ${props =>
    (props.redOne && tintColor(props.theme.colors.red[100], 0.5)) ||
    (props.redTwo && tintColor(props.theme.colors.red[100], 0.4)) ||
    (props.blueOne && tintColor(props.theme.colors.blue[500], 0.8)) ||
    'white'};
`;

const AssessmentSummaryRow = ({
  summaryLabel,
  assessmentBlocks,
  summaryRows,
  propName,
  showDaysOfCompletion
}) => (
  <tr>
    <HeaderCell redOne />
    <HeaderCell>{summaryLabel}</HeaderCell>
    {showDaysOfCompletion && <HeaderCell redOne />}
    <HeaderCell redOne />
    <HeaderCell redOne />
    <HeaderCell redOne />

    {assessmentBlocks.map(ab => {
      return (
        <HeaderCell key={`summary + ${ab.uuid}`}>
          {summaryRows[ab.uuid] && summaryRows[ab.uuid][propName]
            ? propName === 'span'
              ? summaryRows[ab.uuid][propName]
              : formatMMMddyyyy(summaryRows[ab.uuid][propName])
            : '--'}
        </HeaderCell>
      );
    })}
  </tr>
);

AssessmentSummaryRow.propTypes = {
  summaryLabel: PropTypes.string.isRequired,
  assessmentBlocks: PropTypes.array,
  summaryRows: PropTypes.object.isRequired,
  propName: PropTypes.string.isRequired,
  showDaysOfCompletion: PropTypes.bool
};
AssessmentSummaryRow.defaultProps = {
  assessmentBlocks: [],
  showDaysOfCompletion: true
};

export default AssessmentSummaryRow;
