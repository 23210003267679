import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  MoreIcon,
  AddIcon,
  EditIcon,
  DeleteIcon,
  MoveIcon,
  PublishIcon
} from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonIcon, Body1 } from '@xcomp/xcomp-design-library';

const MenuSC = styled(Menu)`
  && {
    padding: 6px 0;
  }
`;

const ButtonIconSC = styled(ButtonIcon)`
  && {
    &:hover,
    &:focus,
    &:active {
      background: 0;
    }
  }
`;

const MenuItemSC = styled(MenuItem)`
  && {
    font-weight: 500;
    font-size: 0.875rem;
    opacity: 0.8;
    display: flex;
    padding: 8px 20px 8px 16px;
    background: none;

    &:hover {
      background: ${props => props.theme.colors.grey[100]};
    }
  }
`;

const Body1SC = styled(Body1)`
  && {
    margin: 0;
    color: ${props => props.theme.fontColors.darker};
  }
`;

const OptionIcon = styled.div`
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const getIcon = name => {
  if (name === 'add') {
    return <AddIcon />;
  } else if (name === 'edit') {
    return <EditIcon />;
  } else if (name === 'delete') {
    return <DeleteIcon />;
  } else if (name === 'publish') {
    return <PublishIcon />;
  } else if (name === 'move') {
    return <MoveIcon />;
  }
  return <AddIcon />;
};

const CohortApplicantReportActionButton = ({
  actions,
  onApplyFilterSelection
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleOptionOnClick = (event, property, decimalProperty, reversed) => {
    event.stopPropagation();
    onApplyFilterSelection(property, decimalProperty, reversed);
    setAnchorEl(null);
  };

  return (
    <div>
      <ButtonIconSC
        noHoverBg
        aria-owns={anchorEl ? 'action-menu-accordion' : null}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreIcon />
      </ButtonIconSC>
      <MenuSC
        id="action-menu-accordion"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={event => handleClose(event)}
      >
        {actions.map(option => (
          <MenuItemSC
            onClick={event =>
              handleOptionOnClick(
                event,
                option.property,
                option.decimalProperty,
                option.reversed
              )
            }
            key={option.label}
          >
            {option.icon && <OptionIcon>{getIcon(option.icon)}</OptionIcon>}
            <Body1SC>{option.label}</Body1SC>
          </MenuItemSC>
        ))}
      </MenuSC>
    </div>
  );
};

CohortApplicantReportActionButton.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool
  ]),
  onApplyFilterSelection: PropTypes.func
};
CohortApplicantReportActionButton.defaultProps = {
  actions: false,
  onApplyFilterSelection: undefined
};

export default CohortApplicantReportActionButton;
