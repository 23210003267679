import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useSWR from 'swr';
import { format } from 'date-fns';
import { IconButton } from '@material-ui/core';
import { CloseIcon } from '@xcomp/xcomp-design-library/dist/icons';

import CircularProgress from '@material-ui/core/CircularProgress';
import { fetcher } from '../../helpers/utilities';
import SimpleModal from '../Library/Modal/SimpleModal';
import { getColor } from './Logs';
import PageIllustration from '../Library/PageIllustration';

const Header = styled.div`
  background: ${({$methodLabel, theme}) => getColor($methodLabel, theme)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 10px 15px;
  font-size: 22px;
  font-weight: 500;
  color: ${props => props.theme.colors.white};
`

const ModalBody = styled.div`
  width: 100%;
  padding: 0 1.25rem 1rem 1.25rem;
`;

const SectionTitle = styled.div`
  font-size: 18px;
  border-bottom: 1px solid;
  margin-bottom: 5px;
  margin-top: 15px;
  font-weight: 500;
`

const TwoColumns = styled.div`
  display: flex;
  margin-bottom: 3px;

  & > div:first-child {
    width: 150px;
  }
`

const TwoRows = styled.div`
  background: ${props => props.theme.colors.grey[100]};
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 5px 10px;

  & > div:first-child {
    width: 150px;
    font-weight: 500;
  }
`

const Chip = styled.div`
  display: flex;
  align-items: center;
  border-radius: 3px;
  font-size: 14px;
  padding: 0px 10px;
  width: fit-content;
  color: ${props => props.theme.colors.white};
  background: ${({$methodLabel, theme}) => getColor($methodLabel, theme)};
`

const LogModal = ({logUuid, handleModalClose, openModal}) => {
  const { data, error, isLoading } = useSWR(
    `/api/logs/${logUuid}`,
    fetcher,
    {
      revalidateOnFocus: false
    }
  );

  return (
    <SimpleModal open={!!openModal} onClose={() => handleModalClose(null)} fullWidth={true} maxWidth='xl'>
      <div>
        <Header $methodLabel={data?.method}>
          <div>Log Details</div>
          <IconButton onClick={() => handleModalClose(null)} style={{color: 'white'}}>
            <CloseIcon />
          </IconButton>
        </Header>
        <ModalBody>
          <div>
            {isLoading && 
              <div style={{marginTop: 50, display: 'flex', justifyContent: 'center'}}>
                <CircularProgress size={80} />
              </div>
            }
            {error && 
              <div style={{marginTop: 50, display: 'flex', justifyContent: 'center'}}> 
                <PageIllustration
                  infoText="An error occured while trying to fetch the data. Please close the modal and open it again."
                />
              </div>
            }
            {data && !error && !isLoading &&
              <div>
                <SectionTitle>Author Details</SectionTitle>
                <TwoColumns>
                  <div>Full Name: </div>
                  <div>{data.authorFullName}</div>
                </TwoColumns>
                <TwoColumns>
                  <div>Institution ID: </div>
                  <div>{data.authorId}</div>
                </TwoColumns>              
                <TwoColumns>
                  <div>User UUID: </div>
                  <div>{data.authorUuid}</div>
                </TwoColumns>

                <SectionTitle>Action Details</SectionTitle>
                <TwoColumns>
                  <div>Method: </div>
                  <Chip $methodLabel={data.method}>{data.method}</Chip>
                </TwoColumns>
                <TwoColumns>
                  <div>Action: </div>
                  <Chip $methodLabel={data.action}>{data.action}</Chip>
                </TwoColumns>              
                <TwoColumns>
                  <div>Reason: </div>
                  <div>{data.reason ? data.reason : <i>No reason specified</i>}</div>
                </TwoColumns>
                <TwoColumns>
                  <div>Timestamp: </div>
                  <div>{format(data.createdAt, 'MMMM d, yyyy h:mm aa')}</div>
                </TwoColumns>

                <SectionTitle>Additional Details</SectionTitle>
                {Object.keys(data.detailsObject).map(k =>
                  <TwoRows key={k}>
                    <div>{k}: </div>
                    <div>{data.detailsObject[k]}</div>
                  </TwoRows>
                )}
              </div>
            }
          </div>
        </ModalBody>
      </div>
    </SimpleModal>
  );
}

LogModal.propTypes = {
  logUuid: PropTypes.string.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  openModal: PropTypes.string.isRequired
};

export default LogModal;
