import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import CoursesByCollectionTable from './CoursesByCollectionTable';
// import GradeResultCollectionScore from '../Calculations/GradeResultCollectionScore';
import CollectionCredits from '../Calculations/CollectionCredits';
import CollectionGPA from '../Calculations/CollectionGPA';

const Accordion = styled(({ ...rest }) => (
  <AccordionRowContainer {...rest} />
))``;

const Label = styled.div`
  font-size: 16px;
  color: ${props => props.theme.fontColors.darker};
`;

const Calculations = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Record = styled.div`
  width: ${props => props.width};
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
`;
const GroupLabel = styled.div`
  padding: 10px;
`;
const GroupInput = styled.div`
  padding: 10px;
`;

// const Temp = styled.div`
//   border: 1px dotted red;
// `;

export default function CoursesByCollection({ collections, userUuid }) {
  return (
    <>
      {collections?.map(collection => (
        <Accordion
          key={collection.uuid}
          expandWidth="66px"
          expandcontent="66px"
          height="80px"
          label={<Label>{collection?.name}</Label>}
          columnOneClassName="column_one"
          columnOne={
            <Calculations>
              <Record width="50%">
                <Group>
                  <GroupLabel>Credits</GroupLabel>
                  <GroupInput>
                    <CollectionCredits
                      userUuid={userUuid}
                      courseCollectionUuid={collection?.uuid}
                    />
                  </GroupInput>
                </Group>
              </Record>
              <Record width="50%">
                <Group>
                  <GroupLabel>GPA</GroupLabel>
                  <GroupInput>
                    <CollectionGPA
                      courseCollectionUuid={collection?.uuid}
                      userUuid={userUuid}
                    />
                  </GroupInput>
                </Group>
              </Record>
            </Calculations>
          }
        >
          <CoursesByCollectionTable
            collectionUuid={collection.uuid}
            userUuid={userUuid}
          />
        </Accordion>
      ))}
    </>
  );
}

CoursesByCollection.propTypes = {
  collections: PropTypes.array,
  userUuid: PropTypes.string
};

CoursesByCollection.defaultProps = {
  collections: undefined,
  userUuid: undefined
};
