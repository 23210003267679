import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Grid, IconButton, InputAdornment } from '@material-ui/core';
import { ButtonOutline } from '@xcomp/xcomp-design-library';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';

import FormField from '../../../../../Library/FormField';

import { validateInputString } from '../../../../../../helpers/validation/validateGeneric';

import {
  FormSectionHeader,
  FormSectionHeaderSubtitle
} from '../../../../../Library/FormComponents';
import EditorAptitudeConversion from './EditorAptitudeConversion';

const FormSectionHeaderSC = styled(FormSectionHeader)`
  && {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

const DeleteIconSC = styled(DeleteIcon)`
  display: flex;
  align-self: center;
`;

const IconArea = styled.div`
  position: absolute;
  top: -3px;
  right: 0;
  width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.white};
  z-index: 25;
`;

const Layout = styled.div`
  margin-left: 100px;
`;

export default function EditorExam({
  index,
  classificationUuid,
  exam,
  hasErrors,
  handleUpdateExam,
  handleRemoveExam,
  handleAddConversion,
  handleUpdateConversion,
  handleRemoveConversion
}) {
  return (
    <Layout>
      <Grid container spacing={4} direction="row">
        <Grid item sm={12} xs={12}>
          <FormSectionHeaderSC offset="0">
            <FormSectionHeaderSubtitle>
              Exam {index + 1}
            </FormSectionHeaderSubtitle>
            <IconArea>
              <IconButton
                onClick={() => handleRemoveExam(classificationUuid, exam?.uuid)}
              >
                <DeleteIconSC />
              </IconButton>
            </IconArea>
          </FormSectionHeaderSC>
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormField
            name="examId"
            label="Test Id *"
            value={exam?.examId}
            onBlur={event =>
              handleUpdateExam(event, classificationUuid, exam?.uuid)
            }
            handleValidate={validateInputString}
            hasErrors={hasErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            name="examName"
            label="Test Name *"
            value={exam?.examName}
            onBlur={event =>
              handleUpdateExam(event, classificationUuid, exam?.uuid)
            }
            handleValidate={validateInputString}
            hasErrors={hasErrors}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormField
            name="weight"
            label="Weight *"
            value={exam?.weight}
            onBlur={event =>
              handleUpdateExam(event, classificationUuid, exam?.uuid)
            }
            handleValidate={validateInputString}
            hasErrors={hasErrors}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormField
            name="examDescription"
            label="Description"
            value={exam?.examDescription || ''}
            onBlur={event =>
              handleUpdateExam(event, classificationUuid, exam?.uuid)
            }
            hasErrors={hasErrors}
            multiline
            minRows={5}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <EditorAptitudeConversion
            classificationUuid={classificationUuid}
            examAptitudeUuid={exam?.uuid}
            conversions={exam?.conversion_aptitudes}
            hasErrors={hasErrors}
            handleUpdateConversion={handleUpdateConversion}
            handleRemoveConversion={handleRemoveConversion}
          />
        </Grid>

        <Grid container justifyContent="flex-end" item xs={12} sm={12}>
          <ButtonOutline
            onClick={() => handleAddConversion(classificationUuid, exam?.uuid)}
          >
            Add Conversion
          </ButtonOutline>
        </Grid>
      </Grid>
    </Layout>
  );
}

EditorExam.propTypes = {
  index: PropTypes.number,
  classificationUuid: PropTypes.string,
  exam: PropTypes.object,
  hasErrors: PropTypes.bool,
  handleUpdateExam: PropTypes.func,
  handleRemoveExam: PropTypes.func,
  handleAddConversion: PropTypes.func,
  handleUpdateConversion: PropTypes.func,
  handleRemoveConversion: PropTypes.func
};

EditorExam.defaultProps = {
  index: 0,
  classificationUuid: undefined,
  exam: undefined,
  hasErrors: false,
  handleUpdateExam: undefined,
  handleRemoveExam: undefined,
  handleAddConversion: undefined,
  handleUpdateConversion: undefined,
  handleRemoveConversion: undefined
};
