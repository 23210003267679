import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';
import { userSectionStatus } from '../../helpers/constants';

export const courseEditCourseSelector = createSelector(
  state => state,
  (_, props) => props.match.params.courseUuid,
  (redux, courseUuid) => {
    const coursesSelectorORM = ormCreateSelector(orm.Course);
    const course = coursesSelectorORM(redux, courseUuid);
    return course;
  }
);

export const courseSelector = createSelector(
  state => state,
  (_, courseUuid) => courseUuid,
  (redux, courseUuid) => {
    const coursesSelectorORM = ormCreateSelector(orm.Course);
    const course = coursesSelectorORM(redux, courseUuid);
    return course;
  }
);

export const coursesByDeptAy = createSelector(
  state => state,
  state => state.filterCourseState,
  (redux, filterCourseState) => {
    const coursesMasterSelectorORM = ormCreateSelector(orm.CourseMaster);
    const coursesMaster = coursesMasterSelectorORM(redux).filter(
      courseMaster =>
        courseMaster.departmentUuid === filterCourseState.departmentUuid
    );
    const coursesSelectorORM = ormCreateSelector(orm.Course);
    const courses = coursesSelectorORM(redux);

    const coursesAll = coursesMaster.map(courseMaster => {
      return courses.filter(
        course =>
          course.courseMasterUuid === courseMaster.uuid &&
          course.academicYearUuid === filterCourseState.academicYearUuid
      );
    });

    const flattenCourses = _.flatten(coursesAll);

    let filterCourses = [...flattenCourses];

    if (filterCourseState.termUuid && filterCourseState.termUuid !== '') {
      filterCourses = filterCourses.filter(
        course => course.termUuid === filterCourseState.termUuid
      );
    }

    if (
      filterCourseState.courseMasterUuid &&
      filterCourseState.courseMasterUuid !== ''
    ) {
      filterCourses = filterCourses.filter(
        course => course.courseMasterUuid === filterCourseState.courseMasterUuid
      );
    }

    const sortCourses = _.sortBy(filterCourses, ['course_master.courseNumber']);

    return sortCourses;
  }
);

export const selectCoursesByTerm = createSelector(
  state => state,
  (_, termUuid, userUuid) => ({ termUuid, userUuid }),
  (redux, parameters) => {
    const { termUuid, userUuid } = parameters;

    const getUserSection = ormCreateSelector(orm.UserSection);
    const getSection = ormCreateSelector(orm.Section);
    const getCourse = ormCreateSelector(orm.Course);
    const getCourseMaster = ormCreateSelector(orm.CourseMaster);

    if (userUuid) {
      const userSections = getUserSection(redux).filter(
        us => us.userUuid === userUuid
      );

      const sections = userSections.map(us =>
        getSection(redux, us.sectionUuid)
      );

      const courses = sections.map(c => {
        const course = getCourse(redux, c.courseUuid);
        const courseMaster = getCourseMaster(redux, course.courseMasterUuid);
        return { ...course, courseMaster, section: c };
      });

      const result = _(courses)
        .filter(course => course.termUuid === termUuid)
        .uniq(['uuid'])
        .orderBy(['courseMaster.courseNumber'])
        .value();

      return result;
    } else {
      return undefined;
    }
  }
);

export const selectCoursesByCollectionRemaining = createSelector(
  state => state,
  (_, collectionUuid, userUuid) => ({ collectionUuid, userUuid }),
  (redux, parameters) => {
    const { collectionUuid, userUuid } = parameters;

    const getCourseCollectionCourse = ormCreateSelector(
      orm.CourseCollectionCourse
    );
    const getUserSection = ormCreateSelector(orm.UserSection);
    const getSection = ormCreateSelector(orm.Section);
    const getCourse = ormCreateSelector(orm.Course);
    const getCourseMaster = ormCreateSelector(orm.CourseMaster);

    if (userUuid) {
      const courseCollectionCourse = getCourseCollectionCourse(redux).filter(
        cp => cp.courseCollectionUuid === collectionUuid
      );

      const courseMastersUuids = courseCollectionCourse.map(
        ccc => ccc.courseMasterUuid
      );

      const userSections = getUserSection(redux).filter(
        us => us.userUuid === userUuid
      );

      const sections = userSections.map(us => {
        const section = getSection(redux, us.sectionUuid);
        return section;
      });

      const takenCourseMastersUuids = sections.map(c => {
        const course = getCourse(redux, c.courseUuid);
        return course.courseMasterUuid;
      });

      const remaining = _(courseMastersUuids)
        .filter(courseMasterUuid => {
          const notTaken = !_.includes(
            takenCourseMastersUuids,
            courseMasterUuid
          );

          return notTaken;
        })
        .map(courseMasterUuid => {
          const courseMaster = getCourseMaster(redux, courseMasterUuid);

          return {
            ...courseMaster,
            courseMaster,
            courseMasterUuid
          };
        })
        .value();

      return remaining;
    } else {
      return undefined;
    }
  }
);

export const selectCoursesByCollections = createSelector(
  state => state,
  (_, collectionUuid) => ({ collectionUuid }),
  (redux, parameters) => {
    const { collectionUuid } = parameters;

    const getCourseCollectionCourse = ormCreateSelector(
      orm.CourseCollectionCourse
    );

    const getCourseMaster = ormCreateSelector(orm.CourseMaster);

    if (collectionUuid) {
      const courseCollectionCourse = getCourseCollectionCourse(redux).filter(
        cp => cp.courseCollectionUuid === collectionUuid
      );

      const courseMasters = courseCollectionCourse.map(ccc => {
        const courseMaster = getCourseMaster(redux, ccc.courseMasterUuid);
        return courseMaster;
      });

      return courseMasters;
    } else {
      return undefined;
    }
  }
);

export const selectSectionPart = createSelector(
  state => state,
  (_, courseUuid) => courseUuid,
  (redux, courseUuid) => {
    const getSections = ormCreateSelector(orm.Section);
    const getSectionParts = ormCreateSelector(orm.Section.parts);

    if (courseUuid) {
      const sections = getSections(redux).filter(
        section => section.courseUuid === courseUuid
      );

      const result = sections.map(section => {
        const section_parts = getSectionParts(redux, section.uuid);

        const sorted = _.sortBy(section_parts, 'partNumber');

        const payload = {
          section,
          parts: sorted
        };

        return payload;
      });

      // const result = _(courses)
      //   .filter(course =>
      //     _.includes(courseMastersUuids, course.courseMasterUuid)
      //   )
      //   .uniq(['uuid'])
      //   .sortBy([
      //     record => {
      //       return new Date(record.startDate);
      //     }
      //   ])
      //   .value();

      return result;
      // return result;
    } else {
      return undefined;
    }
  }
);

export const selectCoursesByCollectionEnrolled = createSelector(
  state => state,
  (_, collectionUuid, userUuid) => ({ collectionUuid, userUuid }),
  (redux, parameters) => {
    const { collectionUuid, userUuid } = parameters;

    const getCourseCollectionCourse = ormCreateSelector(
      orm.CourseCollectionCourse
    );
    const getUserSection = ormCreateSelector(orm.UserSection);
    const getSection = ormCreateSelector(orm.Section);
    const getCourse = ormCreateSelector(orm.Course);
    const getCourseMaster = ormCreateSelector(orm.CourseMaster);

    if (userUuid) {
      const courseCollectionCourse = getCourseCollectionCourse(redux).filter(
        cp => cp.courseCollectionUuid === collectionUuid
      );

      const courseMastersUuids = courseCollectionCourse.map(
        ccc => ccc.courseMasterUuid
      );

      const userSections = getUserSection(redux).filter(
        us => us.userUuid === userUuid && us.status === userSectionStatus.active
      );

      const sections = userSections.map(us => {
        const section = getSection(redux, us.sectionUuid);
        return section;
      });

      const courses = sections.map(c => {
        const course = getCourse(redux, c.courseUuid);
        const courseMaster = getCourseMaster(redux, course.courseMasterUuid);
        return { ...course, course, courseMaster, section: c };
      });

      const result = _(courses)
        .filter(record =>
          _.includes(courseMastersUuids, record?.course?.courseMasterUuid)
        )
        .uniq(['uuid'])
        // .sortBy([record => new Date(record.startDate)])
        .orderBy(['courseMaster.courseNumber'])
        .value();

      return result;
    } else {
      return undefined;
    }
  }
);

export const selectCoursesByCollectionComplete = createSelector(
  state => state,
  (_, collectionUuid, userUuid) => ({ collectionUuid, userUuid }),
  (redux, parameters) => {
    const { collectionUuid, userUuid } = parameters;

    const getCourseCollectionCourse = ormCreateSelector(
      orm.CourseCollectionCourse
    );
    const getUserSection = ormCreateSelector(orm.UserSection);
    const getSection = ormCreateSelector(orm.Section);
    const getCourse = ormCreateSelector(orm.Course);
    const getCourseMaster = ormCreateSelector(orm.CourseMaster);
    const getTerm = ormCreateSelector(orm.Term);

    if (userUuid) {
      const courseCollectionCourse = getCourseCollectionCourse(redux).filter(
        cp => cp.courseCollectionUuid === collectionUuid
      );

      const courseMastersUuids = courseCollectionCourse.map(
        ccc => ccc.courseMasterUuid
      );

      const userSections = getUserSection(redux).filter(
        us =>
          us.userUuid === userUuid &&
          (us.status === userSectionStatus.delayed ||
            us.status === userSectionStatus.withdrew ||
            us.status === userSectionStatus.credit ||
            us.status === userSectionStatus.pass ||
            us.status === userSectionStatus.pass_with_remediation ||
            us.status === userSectionStatus.fail ||
            us.status === userSectionStatus.fail_with_remediation)
      );

      const sections = userSections.map(us => {
        const section = getSection(redux, us.sectionUuid);
        return section;
      });

      const courses = sections.map(c => {
        const course = getCourse(redux, c.courseUuid);
        const term = getTerm(redux, course.termUuid);
        const courseMaster = getCourseMaster(redux, course.courseMasterUuid);
        return { ...course, course, courseMaster, term, section: c };
      });

      const result = _(courses)
        .filter(record =>
          _.includes(courseMastersUuids, record.course.courseMasterUuid)
        )
        .uniq(['uuid'])
        // .sortBy([record => new Date(record.startDate)])
        .orderBy(['courseMaster.courseNumber', ['term.startDate']])
        .value();

      return result;
    } else {
      return undefined;
    }
  }
);

export const selectCourse = ormCreateSelector(orm.Course);
