import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Subtitle2 } from '@xcomp/xcomp-design-library/dist/components/Typography';
import {
  Column,
  ElevatedForm,
  CancelButton,
  SubmitButton,
  TextFieldWithError,
  FormTop,
  FormBottom
} from '../../Library/FormComponents';
import { validateStringLength } from '../../../helpers/validation/validateGeneric';

const FormTopSC = styled(FormTop)`
  margin-bottom: 1.5rem;
`;

const FormSubtitle = styled(Subtitle2)`
  margin-bottom: 24px;
  display: block;
`;

const ProgramAssessmentCollectionForm = ({
  title,
  label,
  formSubtitle,
  buttonText,
  handleChange,
  onSubmit,
  showValidationErrors,
  closeForm
}) => {
  const validateTitle = () => validateStringLength(title, 100, true);
  const someUuid = uuidv4();

  return (
    <ElevatedForm>
      <FormTopSC>
        {formSubtitle && (
          <Column size="100%">
            <FormSubtitle>{formSubtitle}</FormSubtitle>
          </Column>
        )}
        <Column size="100%">
          <TextFieldWithError
            id={`collectionTitle-${someUuid}`}
            name="collection_title"
            value={title}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            handleValidate={validateTitle}
            hasErrors={showValidationErrors}
            onChange={handleChange}
            label={label}
            placeholder=""
            variant="outlined"
          />
        </Column>
      </FormTopSC>
      <FormBottom>
        <CancelButton onClick={closeForm} noHoverBg>
          Cancel
        </CancelButton>
        <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
      </FormBottom>
    </ElevatedForm>
  );
};

ProgramAssessmentCollectionForm.propTypes = {
  title: PropTypes.string,
  formSubtitle: PropTypes.string,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  closeForm: PropTypes.func
};
ProgramAssessmentCollectionForm.defaultProps = {
  title: '',
  formSubtitle: null,
  label: 'New Collection Title',
  buttonText: 'Create',
  showValidationErrors: false,
  handleChange: undefined,
  onSubmit: undefined,
  closeForm: undefined
};

export default ProgramAssessmentCollectionForm;
