import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import FacultyAlternativeScoreViewAttemptPanel from './FacultyAlternativeScoreViewAttemptPanel';

const FacultyAlternativeScoreViewAttemptPanelContainer = ({
  attemptNumber,
  scoreType,
  studentAttemptScores
}) => {
  const { totalRelativeByAttempt, totalPotentialByAttempt } = useMemo(
    () =>
      _.reduce(
        studentAttemptScores,
        (acc, score) => {
          const currentRelative = new Decimal(score.relValue);
          const currentPotential = new Decimal(score.potValue);
          return {
            totalRelativeByAttempt: currentRelative.plus(
              acc.totalRelativeByAttempt
            ),
            totalPotentialByAttempt: currentPotential.plus(
              acc.totalPotentialByAttempt
            )
          };
        },
        {
          totalRelativeByAttempt: new Decimal(0),
          totalPotentialByAttempt: new Decimal(0)
        }
      ),
    [studentAttemptScores]
  );
  const resultCardUuid = _.get(studentAttemptScores, '[0].resultCardUuid', '');
  const attemptOrOpportunityLabel = `${scoreType.toUpperCase()} ${attemptNumber} - ${resultCardUuid}`;
  const isOpportunity = scoreType === 'Opportunity';

  return totalPotentialByAttempt.greaterThan(0) ? (
    <FacultyAlternativeScoreViewAttemptPanel
      isOpportunity={isOpportunity}
      attemptOrOpportunityLabel={attemptOrOpportunityLabel}
      studentAttemptScores={studentAttemptScores}
      totalRelativeByAttempt={totalRelativeByAttempt.toFixed(2)}
      totalPotentialByAttempt={totalPotentialByAttempt.toFixed(2)}
    />
  ) : null;
};

FacultyAlternativeScoreViewAttemptPanelContainer.propTypes = {
  attemptNumber: PropTypes.string,
  scoreType: PropTypes.string,
  studentAttemptScores: PropTypes.array
};
FacultyAlternativeScoreViewAttemptPanelContainer.defaultProps = {
  attemptNumber: '1',
  scoreType: '',
  studentAttemptScores: []
};

export default FacultyAlternativeScoreViewAttemptPanelContainer;
