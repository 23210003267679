import {
  GET_USERS_SCHOOL_ADMIN,
  GET_USERS_SCHOOL_ADMIN_SUCCESS,
  GET_USERS_SCHOOL_ADMIN_ERROR,
  GET_USERS_SCHOOL,
  GET_USERS_SCHOOL_SUCCESS,
  GET_USERS_SCHOOL_ERROR,
  GET_USERS_SCHOOLS,
  GET_USERS_SCHOOLS_SUCCESS,
  GET_USERS_SCHOOLS_ERROR
} from '../actions-type';

const doGetUsersSchoolAdmin = () => ({
  type: GET_USERS_SCHOOL_ADMIN
});

const doGetUsersSchoolAdminSuccess = result => ({
  type: GET_USERS_SCHOOL_ADMIN_SUCCESS,
  payload: result
});

const doGetUsersSchoolAdminError = error => ({
  type: GET_USERS_SCHOOL_ADMIN_ERROR,
  payload: error
});

const doGetUsersSchool = schoolUuid => ({
  type: GET_USERS_SCHOOL,
  payload: {
    schoolUuid
  }
});

const doGetUsersSchoolSuccess = result => ({
  type: GET_USERS_SCHOOL_SUCCESS,
  payload: result
});

const doGetUsersSchoolError = error => ({
  type: GET_USERS_SCHOOL_ERROR,
  payload: error
});

const doGetUsersSchools = () => ({
  type: GET_USERS_SCHOOLS
});

const doGetUsersSchoolsSuccess = result => ({
  type: GET_USERS_SCHOOLS_SUCCESS,
  payload: result
});

const doGetUsersSchoolsError = error => ({
  type: GET_USERS_SCHOOLS_ERROR,
  payload: error
});

export {
  doGetUsersSchoolAdmin,
  doGetUsersSchoolAdminSuccess,
  doGetUsersSchoolAdminError,
  doGetUsersSchool,
  doGetUsersSchoolSuccess,
  doGetUsersSchoolError,
  doGetUsersSchools,
  doGetUsersSchoolsSuccess,
  doGetUsersSchoolsError
};
