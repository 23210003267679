import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectOverallAptitudeGrade } from '../../../../redux/selectors/transcriptAptitudeSelectors';

export default function AptitudeGrade({
  programUuid,
  userUuid,
  cohortUuid,
  category
}) {
  const overallAptitudeGrade = useSelector(state =>
    selectOverallAptitudeGrade(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      category
    )
  );

  const grade = overallAptitudeGrade || 'N/A';

  return <>{grade}</>;
}

AptitudeGrade.propTypes = {
  category: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string
};

AptitudeGrade.defaultProps = {
  category: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined
};
