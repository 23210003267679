import React, { useReducer, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { doGetProgramCohorts } from '../../../../redux/actions/cohortActions';
import {
  doPutCohortAssessmentCollection,
  doGetCohortAssessments
} from '../../../../redux/actions/cohortAssessmentActions';
import { cohortSelectorByMatchParams } from '../../../../redux/selectors/cohortsSelectors';
import {
  allFieldsAreValid,
  validateStringLength
} from '../../../../helpers/validation/validateGeneric';
import EditCohortAssessmentCollectionDashboard from './EditCohortAssessmentCollectionDashboard';
import {
  initialCohortAssessmentCollectionState,
  cohortAssessmentCollectionReducer,
  setHaveFetchedCohorts,
  setHaveFetchedCohortAssessmentCollections,
  setShowValidationErrors,
  setTitle
} from '../cohortAssessmentCollectionState';
import { cohortAssessmentCollectionSelector } from '../../../../redux/selectors/cohortAssessmentSelectors';

const EditCohortAssessmentCollectionDashboardContainer = () => {
  // eslint-disable-next-line prefer-const
  let history = useHistory();
  const dispatch = useDispatch();
  const { cohortUuid, cohortAssessmentCollectionUuid } = useParams();

  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  const parentCohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    shallowEqual
  );

  const existingAssessmentCollection = useSelector(
    state =>
      cohortAssessmentCollectionSelector(state, cohortAssessmentCollectionUuid),
    shallowEqual
  );

  const [state, editDispatch] = useReducer(
    cohortAssessmentCollectionReducer,
    initialCohortAssessmentCollectionState
  );

  const setExistingState = existingCollection => {
    editDispatch({
      type: 'setExistingCohortAssessmentCollection',
      payload: { ...existingCollection }
    });
  };

  const {
    uuid,
    title,
    haveFetchedCohorts,
    haveFetchedCohortAssessmentCollections,
    showValidationErrors
  } = state;

  const clearForm = () => {
    editDispatch({
      type: 'clearForm'
    });
    history.push(`/program-management/cohort/${cohortUuid}/requirements`);
  };

  const onPutUpdate = assessmentCollection =>
    dispatch(doPutCohortAssessmentCollection(assessmentCollection));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!haveFetchedCohorts && !parentCohort) {
      dispatch(doGetProgramCohorts(selectedProgramUuid));
      editDispatch(setHaveFetchedCohorts());
    }
  }, [dispatch, selectedProgramUuid, haveFetchedCohorts, parentCohort]);

  useEffect(() => {
    if (
      !haveFetchedCohortAssessmentCollections &&
      !existingAssessmentCollection
    ) {
      dispatch(doGetCohortAssessments(cohortUuid));
      editDispatch(setHaveFetchedCohortAssessmentCollections());
    }
  }, [
    dispatch,
    existingAssessmentCollection,
    haveFetchedCohortAssessmentCollections,
    cohortUuid
  ]);

  useEffect(() => {
    if (
      cohortAssessmentCollectionUuid &&
      existingAssessmentCollection &&
      uuid !== existingAssessmentCollection.uuid
    ) {
      setExistingState(existingAssessmentCollection);
    }
  }, [cohortAssessmentCollectionUuid, existingAssessmentCollection, uuid]);

  const setValidationErrors = assessmentCollection => {
    const titleError = validateStringLength(
      assessmentCollection.title,
      100,
      true
    );

    const newValidationErrors = {
      titleError
    };

    return newValidationErrors;
  };

  const buildProcedureCodePayload = (uuid, title, cohortUuid) => {
    return {
      uuid,
      title,
      cohortUuid
    };
  };

  const handleUpdateSubmission = () => {
    const newValidationErrors = setValidationErrors({
      title
    });

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = buildProcedureCodePayload(uuid, title, cohortUuid);

      onPutUpdate(payload);
      editDispatch(setShowValidationErrors(false));
    } else {
      editDispatch(setShowValidationErrors(true));
    }
  };

  const assessmentCollection = {
    title,
    cohortUuid
  };

  const handleTitleChange = e => editDispatch(setTitle(e.target.value));

  return (
    <EditCohortAssessmentCollectionDashboard
      assessmentCollection={assessmentCollection}
      parentCohort={parentCohort}
      handleTitleChange={handleTitleChange}
      onSubmit={handleUpdateSubmission}
      clearForm={clearForm}
      showValidationErrors={showValidationErrors}
    />
  );
};

export default EditCohortAssessmentCollectionDashboardContainer;
