import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import EditorAptitude from './EditorAptitude';
import {
  doGetAptitudes,
  doPostAptitudes
} from '../../../../../../redux/actions/classificationActions';

import { selectAptitudesByCategory } from '../../../../../../redux/selectors/classificationSelectors';
import { usePrevious } from '../../../../../../helpers/hooks/state.hooks';
import { validateInputString } from '../../../../../../helpers/validation/validateGeneric';
import { weightPercentage } from '../../../../../../helpers/utilities';
import { aptitudeReducer, aptitudeInitialState } from './editorAptitudeState';

export default function EditorAptitudeContainer({ match }) {
  const { programUuid, category } = match.params;
  const dispatch = useDispatch();
  const [hasErrors, setHasErrors] = useState(false);

  const [aptitudeState, aptitudeDispatch] = useReducer(
    aptitudeReducer,
    aptitudeInitialState
  );

  const aptitudesRedux = useSelector(
    state => selectAptitudesByCategory(state, programUuid, category),
    _.isEqual
  );

  const aptitudesPrevious = usePrevious(aptitudesRedux);

  useEffect(() => {
    dispatch(doGetAptitudes(programUuid, category));
  }, [dispatch, programUuid, category]);

  useEffect(() => {
    const equalityCheck = !_.isEqual(aptitudesPrevious, aptitudesRedux);

    if (equalityCheck && !_.isNil(aptitudesRedux)) {
      aptitudeDispatch({ type: 'populate', payload: aptitudesRedux });
    }
  }, [aptitudeDispatch, aptitudesPrevious, aptitudesRedux]);

  const handleAddScope = () => {
    aptitudeDispatch({ type: 'classification_add', payload: { category } });
  };

  const handleUpdateSource = (event, classificationUuid) => {
    const { name, value } = event.target;

    aptitudeDispatch({
      type: 'classfication_update',
      payload: { classificationUuid, modify: { [name]: value } }
    });
  };

  const handleUpdateScope = (event, classificationUuid) => {
    const { name, value } = event.target;

    aptitudeDispatch({
      type: 'classfication_update',
      payload: { classificationUuid, modify: { [name]: value } }
    });
  };

  const handleRemoveClassification = classificationUuid => {
    aptitudeDispatch({
      type: 'classfication_remove',
      payload: { classificationUuid }
    });
  };

  const handleAddExam = classificationUuid => {
    aptitudeDispatch({ type: 'exam_add', payload: { classificationUuid } });
  };

  const handleUpdateExam = (
    event,
    classificationUuid,
    examAptitudeUuid,
    conversionAptitudeUuid
  ) => {
    const { name, value } = event.target;

    aptitudeDispatch({
      type: 'exam_update',
      payload: {
        classificationUuid,
        examAptitudeUuid,
        conversionAptitudeUuid,
        modify: { [name]: value }
      }
    });
  };

  const handleRemoveExam = (classificationUuid, examAptitudeUuid) => {
    aptitudeDispatch({
      type: 'exam_remove',
      payload: { classificationUuid, examAptitudeUuid }
    });
  };

  const handleAddConversion = (classificationUuid, examAptitudeUuid) => {
    aptitudeDispatch({
      type: 'conversion_add',
      payload: { classificationUuid, examAptitudeUuid }
    });
  };

  const handleUpdateConversion = (
    event,
    classificationUuid,
    examAptitudeUuid,
    conversionAptitudeUuid
  ) => {
    const { name, value } = event.target;

    aptitudeDispatch({
      type: 'conversion_update',
      payload: {
        classificationUuid,
        examAptitudeUuid,
        conversionAptitudeUuid,
        modify: { [name]: value }
      }
    });
  };

  const handleRemoveConversion = (
    classificationUuid,
    examAptitudeUuid,
    conversionAptitudeUuid
  ) => {
    aptitudeDispatch({
      type: 'conversion_remove',
      payload: {
        classificationUuid,
        examAptitudeUuid,
        conversionAptitudeUuid
      }
    });
  };

  const _removeUuid = object => {
    const copy = _.cloneDeep(object);

    if (object.type === 'new') {
      const change = _.omit(copy, ['type', 'uuid']);
      return change;
    } else {
      return object;
    }
  };

  const totalWeight = () => {
    const exams = _(aptitudeState.classifications)
      .filter(classification => classification.exam_aptitudes)
      .map(classification => classification.exam_aptitudes)
      .flatten()
      .value();

    const total = weightPercentage(exams);

    return total;
  };

  const _validate = () => {
    let containErrors = false;

    const classificationSources = aptitudeState.classifications.map(
      classification => validateInputString(classification.source).invalid
    );

    const classificationScopes = aptitudeState.classifications.map(
      classification => validateInputString(classification.scope).invalid
    );

    const aptitudeIds = _(aptitudeState.classifications)
      .map(classification => classification.exam_aptitudes)
      .flatten()
      .remove(undefined)
      .map(exam => {
        return validateInputString(exam.examId).invalid;
      })
      .value();

    const aptitudeExamNames = _(aptitudeState.classifications)
      .map(classification => classification.exam_aptitudes)
      .flatten()
      .remove(undefined)
      .map(exam => validateInputString(exam.examName).invalid)
      .value();

    const aptitudeExamWeight = _(aptitudeState.classifications)
      .map(classification => classification.exam_aptitudes)
      .flatten()
      .remove(undefined)
      .map(exam => validateInputString(exam.weight).invalid)
      .value();

    const conversionScaledScore = _(aptitudeState.classifications)
      .map(classification => classification.exam_aptitudes)
      .flatten()
      .remove(undefined)
      .map(exam => exam.conversion_aptitudes)
      .flatten()
      .remove(undefined)
      .map(conversion => validateInputString(conversion.scaledScore).invalid)
      .value();

    const conversionPercentage = _(aptitudeState.classifications)
      .map(classification => classification.exam_aptitudes)
      .flatten()
      .remove(undefined)
      .map(exam => exam.conversion_aptitudes)
      .flatten()
      .remove(undefined)
      .map(conversion => validateInputString(conversion.percentage).invalid)
      .value();

    const classificationScopesErrors = _.includes(classificationScopes, true);
    const classificationSourcesErrors = _.includes(classificationSources, true);
    const aptitudeIdsErrors = _.includes(aptitudeIds, true);
    const aptitudeExamNamesErrors = _.includes(aptitudeExamNames, true);
    const aptitudeExamWeightErrors = _.includes(aptitudeExamWeight, true);
    const conversionScaledScoreErrors = _.includes(conversionScaledScore, true);
    const conversionPercentageErrors = _.includes(conversionPercentage, true);

    if (
      classificationScopesErrors ||
      classificationSourcesErrors ||
      aptitudeIdsErrors ||
      aptitudeExamNamesErrors ||
      aptitudeExamWeightErrors ||
      conversionScaledScoreErrors ||
      conversionPercentageErrors
    ) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleSave = () => {
    const {
      classifications,
      classificationsRemove,
      examAptitudesRemove,
      conversionAptitudesRemove
    } = aptitudeState;

    const check = _validate();

    if (check) {
      setHasErrors(true);
    } else {
      const payload = classifications.map(classification => {
        const cleanClassification = _removeUuid(classification);

        const cleanExams = cleanClassification.exam_aptitudes?.map(exam => {
          const cleanExam = _removeUuid(exam);
          const cleanConversions = cleanExam.conversion_aptitudes?.map(
            conversion => _removeUuid(conversion)
          );

          return { ...cleanExam, conversion_aptitudes: cleanConversions };
        });

        return {
          ...cleanClassification,
          programUuid,
          exam_aptitudes: cleanExams
        };
      });

      dispatch(
        doPostAptitudes(
          programUuid,
          payload,
          classificationsRemove,
          examAptitudesRemove,
          conversionAptitudesRemove
        )
      );
    }
  };

  return (
    <EditorAptitude
      classifications={aptitudeState?.classifications}
      category={category}
      totalWeight={totalWeight()}
      hasErrors={hasErrors}
      handleAddScope={handleAddScope}
      handleUpdateSource={handleUpdateSource}
      handleUpdateScope={handleUpdateScope}
      handleRemoveClassification={handleRemoveClassification}
      handleAddExam={handleAddExam}
      handleUpdateExam={handleUpdateExam}
      handleRemoveExam={handleRemoveExam}
      handleAddConversion={handleAddConversion}
      handleUpdateConversion={handleUpdateConversion}
      handleRemoveConversion={handleRemoveConversion}
      handleSave={handleSave}
    />
  );
}

EditorAptitudeContainer.propTypes = {
  match: PropTypes.object
};

EditorAptitudeContainer.defaultProps = {
  match: {}
};
