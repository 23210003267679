import { Model, attr, fk } from 'redux-orm';
import _ from 'lodash';

import {
  DELETE_USERS_SECTION_SUCCESS,
  GET_COURSE_BY_STUDENT_SUCCESS,
  GET_SCORES_SUCCESS,
  GET_STUDENT_ROSTER_SUCCESS,
  GET_SYLLABUS_SUCCESS,
  PATCH_STUDENT_SECTION_STATUS_SUCCESS,
  POST_UPLOAD_STUDENTS_SECTION_SUCCESS,
  POST_USER_SECTION_SUCCESS
} from '../actions-type';

class UserSection extends Model {
  static reducer(action, UserSection, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_STUDENT_ROSTER_SUCCESS: {
        const userSections = [...payload.userSections];
        userSections.forEach(userSection => UserSection.upsert(userSection));
        break;
      }
      case POST_USER_SECTION_SUCCESS: {
        const userSection = { ...payload.userSection };
        UserSection.upsert(userSection);
        break;
      }
      case PATCH_STUDENT_SECTION_STATUS_SUCCESS: {
        const userSections = [...payload.userSections];
        userSections.forEach(userSection => UserSection.upsert(userSection));
        break;
      }
      case POST_UPLOAD_STUDENTS_SECTION_SUCCESS: {
        const userSections = [...payload.userSections];
        userSections.forEach(userSection => UserSection.upsert(userSection));
        break;
      }
      case DELETE_USERS_SECTION_SUCCESS: {
        const students = [...payload.students];
        students.forEach(student => UserSection.withId(student.uuid).delete());
        break;
      }
      case GET_SCORES_SUCCESS: {
        const userSections = [...payload.userSections];
        userSections.forEach(userSection => UserSection.upsert(userSection));
        break;
      }
      case GET_SYLLABUS_SUCCESS: {
        const userSections = [...payload.userSections];
        userSections.forEach(userSection => {
          const add = _.omit(userSection, ['user']);
          UserSection.upsert(add);
        });
        break;
      }
      case GET_COURSE_BY_STUDENT_SUCCESS: {
        const courses = [...payload.courses];
        if (courses?.length > 0) {
          courses.forEach(course => {
            const { sections } = course;

            if (sections?.length > 0) {
              sections.forEach(section => {
                const { user_sections } = section;

                if (user_sections?.length > 0) {
                  user_sections.forEach(userSection => {
                    const {
                      uuid,
                      status,
                      userUuid,
                      sectionUuid,
                      createdAt,
                      updatedAt
                    } = userSection;

                    UserSection.upsert({
                      uuid,
                      status,
                      userUuid,
                      sectionUuid,
                      createdAt,
                      updatedAt
                    });
                  });
                }
              });
            }
          });
        }

        break;
      }
      default:
        return session;
    }
  }
}

UserSection.fields = {
  uuid: attr(),
  status: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  userUuid: fk({
    to: 'User',
    as: 'user',
    relatedName: 'user_sections'
  }),
  sectionUuid: fk({
    to: 'Section',
    as: 'section',
    relatedName: 'user_sections'
  })
};

UserSection.modelName = 'UserSection';

UserSection.options = {
  idAttribute: 'uuid'
};

export default UserSection;
