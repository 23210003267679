import { call, put, takeEvery } from 'redux-saga/effects';

import { putThirdPartyId } from '../../api/thirdPartyApi';

import {
  doPutThirdPartyIdSuccess,
  doPutThirdPartyIdError
} from '../actions/thirdPartyActions';

import { PUT_THIRD_PARTY_ID } from '../actions-type';

function* handlePutThirdPartyId(action) {
  try {
    const { userUuid, programUuid, thirdPartyId } = action.payload;

    const result = yield call(
      putThirdPartyId,
      userUuid,
      programUuid,
      thirdPartyId
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutThirdPartyIdSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutThirdPartyIdError(error));
  }
}

const thirdPartyId = [takeEvery(PUT_THIRD_PARTY_ID, handlePutThirdPartyId)];

export { thirdPartyId };
