import React, { useMemo, forwardRef } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Header from '../Library/Header';
import { ContainerResize, ContainerTabs } from '../Library/Layout';
import UserTabViewContainer from './UserTabView/UserTabViewContainer';
import UserTabCheckContainer from './UserTabCheck/UserTabCheckContainer';
import UserTabUploadContainer from './UserTabUpload/UserTabUploadContainer';
import UserTabAppidContainer from './UserTabAppId/UserTabAppIdContainer';

import {
  visibleToInstitutionAdmin,
  visibleToSchoolAdmin,
  visibleToProgramAdmin
} from '../../helpers/authorization';

const TabsSC = styled(Tabs)`
  && {
    border-top: 1px solid ${props => props.theme.colors.grey[300]};
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
    background: ${props => props.theme.colors.white};
  }
`;

const TabSC = styled(Tab)`
  && {
    max-width: 100%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    line-height: 16px;
    width: 100%;
  }
`;

const LinkSC = styled(Link)`
  width: 100%;
`;

const InstitutionAdminHeader = visibleToInstitutionAdmin(() => (
  <Header
    backOn={false}
    title="User Management"
    subtitle="Manage, add and remove users"
  />
));

const SchoolAdminHeader = visibleToSchoolAdmin(() => (
  <Header
    backOn={false}
    title="User Assignment"
    subtitle="Manage and assign roles"
  />
));

const ProgramAdminHeader = visibleToProgramAdmin(() => (
  <Header
    backOn={false}
    title="User Assignment"
    subtitle="Manage and assign program roles"
  />
));

export default function UserManagementInstitutionAdmin({ path, pathname }) {
  let location = 0;

  if (pathname === `/user-management/dashboard/tab/check`) {
    location = 0;
  } else if (pathname === `/user-management/dashboard/tab/upload`) {
    location = 1;
  } else if (pathname === `/user-management/dashboard/tab/app-id`) {
    location = 2;
  } else if (pathname === `/user-management/dashboard/tab/view`) {
    location = 3;
  }

  const renderLink = useMemo(
    () =>
      forwardRef((props, ref) => (
        // eslint-disable-next-line react/prop-types
        <LinkSC to={props.topath} {...props} ref={ref} />
      )),
    []
  );

  return (
    <ContainerResize>
      <InstitutionAdminHeader />
      <SchoolAdminHeader />
      <ProgramAdminHeader />
      <ContainerTabs>
        <TabsSC
          value={location}
          textColor="primary"
          variant="fullWidth"
          indicatorColor="primary"
        >
          <TabSC
            component={renderLink}
            topath={`${path}/tab/check`}
            label="check"
          />
          <TabSC
            component={renderLink}
            topath={`${path}/tab/upload`}
            label="upload"
          />
          <TabSC
            component={renderLink}
            topath={`${path}/tab/app-id`}
            label="app id"
          />
          <TabSC
            component={renderLink}
            topath={`${path}/tab/view`}
            label="view"
          />
        </TabsSC>
        <Switch>
          <Route
            path={`${path}/tab/check`}
            // eslint-disable-next-line no-unused-vars
            component={props => {
              return <UserTabCheckContainer {...props} />;
            }}
          />
          <Route
            path={`${path}/tab/upload`}
            // eslint-disable-next-line no-unused-vars
            component={props => {
              return <UserTabUploadContainer {...props} />;
            }}
          />
          <Route
            path={`${path}/tab/app-id`}
            // eslint-disable-next-line no-unused-vars
            component={props => {
              return <UserTabAppidContainer {...props} />;
            }}
          />
          <Route
            path={`${path}/tab/view`}
            component={props => {
              return <UserTabViewContainer {...props} />;
            }}
          />
        </Switch>
      </ContainerTabs>
    </ContainerResize>
  );
}

UserManagementInstitutionAdmin.propTypes = {
  path: PropTypes.string,
  pathname: PropTypes.string
};
UserManagementInstitutionAdmin.defaultProps = {
  path: '',
  pathname: ''
};
