import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import CourseSectionPanels from './CourseSectionPanels';
import { visibleToSchoolAdmin } from '../../../../helpers/authorization';

const SchoolAdminOnlyButton = visibleToSchoolAdmin(props => (
  <ButtonPrimary onClick={props.handleAddSection}>Add Section</ButtonPrimary>
));

const Layout = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const CourseSection = props => (
  <Layout>
    <CourseSectionPanels {...props} />
    <SchoolAdminOnlyButton handleAddSection={props.handleAddSection} />
  </Layout>
);

CourseSection.propTypes = {
  handleAddSection: PropTypes.func
};

CourseSection.defaultProps = {
  handleAddSection: undefined
};

export default CourseSection;
