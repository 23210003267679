import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';
import { sortByPropertyAlphaNumeric } from '../../helpers/utilities';

export const cohortAssessmentCollectionsSelector = createSelector(
  state => state,
  (_, cohortUuid) => cohortUuid,
  (redux, cohortUuid) => {
    const cohortCollectionsSelectorORM = ormCreateSelector(
      orm.CohortAssessmentCollection
    );

    const cohortAssessmentCollections = cohortCollectionsSelectorORM(
      redux
    ).filter(collection => collection.cohortUuid === cohortUuid);

    const sorted = _.sortBy(cohortAssessmentCollections, 'title');

    return sorted;
  }
);

export const cohortAssessmentCollectionSelector = createSelector(
  state => state,
  (_, cohortAssessmentCollectionUuid) => cohortAssessmentCollectionUuid,
  (redux, cohortAssessmentCollectionUuid) => {
    const cohortAssessmentCollectionSelectorORM = ormCreateSelector(
      orm.CohortAssessmentCollection
    );

    const cohortAssessmentsSelectorORM = ormCreateSelector(orm.Assessment);

    const cohortAssessmentCollection = cohortAssessmentCollectionSelectorORM(
      redux,
      cohortAssessmentCollectionUuid
    );

    const cohortAssessments = cohortAssessmentsSelectorORM(redux)
      .filter(
        assessment =>
          assessment.cohortAssessmentCollectionUuid ===
          cohortAssessmentCollectionUuid
      )
      .sort((a, b) => sortByPropertyAlphaNumeric(a, b, 'title'));

    return { cohortAssessments, ...cohortAssessmentCollection };
  }
);
