import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { doGetPartOpportunityReportBlockDetail } from '../../../redux/actions/partReportsActions';
import OpportunityReportAssessmentBlockPanel from './OpportunityReportAssessmentBlockPanel';

const OpportunityReportAssessmentBlockPanelContainer = ({
  assessment,
  assessmentBlock,
  studentUser
}) => {
  const dispatch = useDispatch();
  const {
    partOpportunityReportBlockDetailLoading,
    partOpportunityReportBlockDetailData
  } = useSelector(state => state.partOpportunityReportState);
  useEffect(() => {
    if (assessment.uuid && studentUser.uuid) {
      dispatch(
        doGetPartOpportunityReportBlockDetail(assessment.uuid, studentUser.uuid)
      );
    }
  }, [dispatch, assessment.uuid, studentUser.uuid]);

  const assessementBlockLabel = `Block ${assessmentBlock.blockNumber} — ${assessmentBlock.title}`;
  return (
    <OpportunityReportAssessmentBlockPanel
      assessmentBlock={assessmentBlock}
      isLoading={partOpportunityReportBlockDetailLoading.includes(
        `${studentUser.uuid}:${assessment.uuid}`
      )}
      panelLabel={assessementBlockLabel}
      studentAssessmentBlockData={
        partOpportunityReportBlockDetailData[
          `${studentUser.uuid}:${assessment.uuid}`
        ]?.[assessmentBlock.uuid]
      }
      studentAssessmentBlockRelativeScore={
        partOpportunityReportBlockDetailData[
          `${studentUser.uuid}:${assessment.uuid}`
        ]?.[assessmentBlock.uuid]?.totalRelativeScore
      }
      studentAssessmentBlockOpportunityCount={
        partOpportunityReportBlockDetailData[
          `${studentUser.uuid}:${assessment.uuid}`
        ]?.[assessmentBlock.uuid]?.totalOpportunityCount
      }
    />
  );
};

OpportunityReportAssessmentBlockPanelContainer.propTypes = {
  assessment: PropTypes.object,
  assessmentBlock: PropTypes.object,
  studentUser: PropTypes.object
};
OpportunityReportAssessmentBlockPanelContainer.defaultProps = {
  assessment: {},
  assessmentBlock: PropTypes.object,
  studentUser: {}
};

export default OpportunityReportAssessmentBlockPanelContainer;
