import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import { ObjectivesIcon } from '@xcomp/xcomp-design-library/dist/icons';
import moment from 'moment';
import momentTz from 'moment-timezone';
import LearningObjective from './LearningObjective';
import SyllabusSubHeader from '../../SyllabusSubHeader';
import { generateMaxRevus } from '../../../../../helpers/generators';
import TotalRevus from './TotalRevus';

const LearningObjectives = ({
  isObjectives,
  restrictEdit,
  hasErrors,
  objectives,
  fieldInFocus,
  isSingleType,
  startDate,
  endDate,
  handleObjectiveAdd,
  handleEncounterChange,
  handleObjectiveChange,
  handleObjectiveDelete,
  handleObjectiveMove,
  handleObjectiveMicroRemove,
  handleObjectiveMicro,
  handleFieldInFocusValidation
}) => {
  const appromixateTimeZone = momentTz.tz.guess();

  const nonMomentStartDate = moment.isMoment(startDate)
    ? startDate.tz(appromixateTimeZone).format()
    : momentTz(startDate).tz(appromixateTimeZone).format();

  const nonMomentEndDate = moment.isMoment(endDate)
    ? endDate.tz(appromixateTimeZone).format()
    : momentTz(endDate).tz(appromixateTimeZone).format();

  const calculatedMaxRevus = isSingleType
    ? generateMaxRevus(nonMomentStartDate, nonMomentEndDate)
    : 0;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <SyllabusSubHeader>
          <ObjectivesIcon />
          Learning Objectives
        </SyllabusSubHeader>
      </Grid>
      <Grid item xs={12}>
        {isObjectives &&
          objectives
            .sort((a, b) => a.objectiveNumber - b.objectiveNumber)
            .map(objective => (
              <LearningObjective
                key={objective.uuid || objective.id}
                restrictEdit={restrictEdit}
                objective={objective}
                objectiveNumber={objective.objectiveNumber}
                fieldInFocus={fieldInFocus}
                handleEncounterChange={handleEncounterChange}
                handleObjectiveChange={handleObjectiveChange}
                handleObjectiveDelete={handleObjectiveDelete}
                hasErrors={hasErrors}
                handleObjectiveMove={handleObjectiveMove}
                handleFieldInFocusValidation={handleFieldInFocusValidation}
                handleObjectiveMicro={handleObjectiveMicro}
                handleObjectiveMicroRemove={handleObjectiveMicroRemove}
              />
            ))}
        <TotalRevus
          objectives={objectives}
          isSingleType={isSingleType}
          calculatedMaxRevus={calculatedMaxRevus}
        />
      </Grid>
      {!restrictEdit && (
        <Grid item xs={12}>
          <ButtonInline onClick={handleObjectiveAdd}>
            Add learning objective
          </ButtonInline>
        </Grid>
      )}
    </Grid>
  );
};

LearningObjectives.propTypes = {
  isObjectives: PropTypes.bool,
  restrictEdit: PropTypes.bool,
  hasErrors: PropTypes.bool,
  objectives: PropTypes.array,
  isSingleType: PropTypes.bool,
  fieldInFocus: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  handleEncounterChange: PropTypes.func,
  handleObjectiveAdd: PropTypes.func,
  handleObjectiveChange: PropTypes.func,
  handleObjectiveDelete: PropTypes.func,
  handleObjectiveMove: PropTypes.func,
  handleObjectiveMicroRemove: PropTypes.func,
  handleObjectiveMicro: PropTypes.func,
  handleFieldInFocusValidation: PropTypes.func
};

LearningObjectives.defaultProps = {
  isObjectives: false,
  restrictEdit: true,
  hasErrors: false,
  objectives: [],
  isSingleType: false,
  endDate: '',
  startDate: '',
  fieldInFocus: '',
  handleObjectiveAdd: undefined,
  handleEncounterChange: undefined,
  handleObjectiveChange: undefined,
  handleObjectiveDelete: undefined,
  handleObjectiveMove: undefined,
  handleObjectiveMicroRemove: undefined,
  handleObjectiveMicro: undefined,
  handleFieldInFocusValidation: undefined
};

export default LearningObjectives;
