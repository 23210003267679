import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectCognitiveMilestoneCreditsByAttempt } from '../../../../redux/selectors/transcriptCognitiveSelectors';

export default function CognitiveMilestoneAttemptCredits({
  programUuid,
  userUuid,
  cohortUuid,
  category,
  source,
  attempt
}) {
  const milestoneCreditsByAttempt = useSelector(state =>
    selectCognitiveMilestoneCreditsByAttempt(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      attempt
    )
  );

  const credits = milestoneCreditsByAttempt || 'N/A';

  return <>{credits}</>;
}

CognitiveMilestoneAttemptCredits.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  attempt: PropTypes.number
};

CognitiveMilestoneAttemptCredits.defaultProps = {
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  attempt: 0
};
