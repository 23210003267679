import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import moment from 'moment';
import { orm } from '../models';
import {
  cohortRolesStatus,
  cohortRoleStatusMap,
  roles,
  rolesMap
} from '../../helpers/constants';

export const userCohortSelector = createSelector(
  state => state,
  (_, userUuid, cohortUuid) => ({ cohortUuid, userUuid }),
  (redux, data) => {
    const userCohortsSelectorORM = ormCreateSelector(orm.UserCohort);
    return userCohortsSelectorORM(redux).filter(
      userCohort =>
        userCohort.userUuid === data.userUuid &&
        userCohort.cohortUuid === data.cohortUuid
    );
  }
);

export const cohortStudentsSelector = createSelector(
  state => state,
  (_, cohortUuid) => ({ cohortUuid }),
  (redux, data) => {
    const userCohortsSelectorORM = ormCreateSelector(orm.UserCohort);
    const userSelectorORM = ormCreateSelector(orm.User);
    const userUuids = userCohortsSelectorORM(redux)
      .filter(userCohort => userCohort.cohortUuid === data.cohortUuid)
      .map(userCohort => userCohort.userUuid);

    return userSelectorORM(redux).filter(user => userUuids.includes(user.uuid));
  }
);

export const applicantsByStatusSelector = createSelector(
  state => state,
  (_, cohortUuid, status) => ({ cohortUuid, status }),
  (redux, data) => {
    const userCohortsOrm = ormCreateSelector([orm], session =>
      session.UserCohort.all()
        .toModelArray()
        .filter(
          userCohort =>
            userCohort.cohortUuid === data.cohortUuid &&
            userCohort.role === 'applicant' &&
            userCohort.status === data.status
        )
        .map(userCohort => {
          const { ref } = userCohort;

          const user = session.User.withId(userCohort.userUuid);

          return {
            ...ref,
            ...(user?.ref ? { user: { ...user?.ref } } : {})
          };
        })
    );
    return userCohortsOrm(redux);
  }
);

export const userByStudentSchoolSelector = createSelector(
  state => state,
  (_, institutionId) => ({
    institutionId
  }),
  (redux, parameters) => {
    const { institutionId } = parameters;

    if (institutionId) {
      const getUser = ormCreateSelector(orm.User);
      const getUserCohort = ormCreateSelector(orm.UserCohort);
      const getCohort = ormCreateSelector(orm.Cohort);

      const user = _.find(
        getUser(redux),
        user => user.institutionId === institutionId
      );

      const userCohort = _.find(
        getUserCohort(redux),
        userCohort =>
          userCohort.userUuid === user?.uuid &&
          userCohort?.role === 'student' &&
          userCohort?.status === 'active'
      );

      const cohort = getCohort(redux, userCohort?.cohortUuid);

      if (user) {
        const payload = {
          ...userCohort,
          user,
          status: cohortRoleStatusMap[userCohort?.status],
          role: rolesMap[userCohort?.role],
          cohort: cohort?.name,
          userCohortUuid: userCohort?.uuid,
          cohortStartDate: moment(cohort?.startDate).format('LL'),
          cohortEndDate: moment(cohort?.endDate).format('LL')
        };

        return payload;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
);

export const cohortApplicantCountSelector = createSelector(
  state => state,
  (_, cohortUuid) => cohortUuid,
  (redux, cohortUuid) => {
    const userCohortsSelectorORM = ormCreateSelector(orm.UserCohort);
    const userCohorts = userCohortsSelectorORM(redux).filter(
      userCohort =>
        userCohort.cohortUuid === cohortUuid &&
        userCohort.role === roles.applicant
    );

    const count = userCohorts.length || 0;

    return count;
  }
);

export const cohortStudentCountSelector = createSelector(
  state => state,
  (_, cohortUuid) => cohortUuid,
  (redux, cohortUuid) => {
    const userCohortsSelectorORM = ormCreateSelector(orm.UserCohort);
    const userCohorts = userCohortsSelectorORM(redux).filter(
      userCohort =>
        userCohort.cohortUuid === cohortUuid &&
        userCohort.role === roles.student
    );

    const count = userCohorts.length || 0;

    return count;
  }
);

export const selectApplicantRoles = createSelector(
  state => state,
  (_, userUuid, cohortUuid, programUuid) => ({
    userUuid,
    cohortUuid,
    programUuid
  }),
  (redux, parameters) => {
    const { userUuid, cohortUuid, programUuid } = parameters;

    const getUserCohort = ormCreateSelector(orm.UserCohort);
    const getCohort = ormCreateSelector(orm.Cohort);
    const getProgram = ormCreateSelector(orm.Program);

    const roles = getUserCohort(redux).filter(
      userCohort => userCohort.userUuid === userUuid
    );

    if (roles?.length > 0) {
      const rolesInProgram = roles.filter(role => {
        const cohort = getCohort(redux, role.cohortUuid);

        if (cohort) {
          const program = getProgram(redux, cohort.programUuid);

          if (program.uuid === programUuid && cohort.uuid !== cohortUuid) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });

      return rolesInProgram;
    } else {
      return undefined;
    }
  }
);

export const studentRosterSelectorByCohortUuid = createSelector(
  state => state,
  (_, cohortUuid) => cohortUuid,
  (redux, cohortUuid) => {
    const userCohortsSelectorORM = ormCreateSelector(
      orm.UserCohort,
      orm.UserCohort.user,
      (userCohorts, user) =>
        userCohorts.map((userCohort, index) => ({
          ...userCohort,
          user: user[index]
        }))
    );

    return userCohortsSelectorORM(redux).filter(
      userCohort =>
        userCohort.cohortUuid === cohortUuid && userCohort.role === 'student'
    );
  }
);

export const activeStudentRosterSelectorByCohortUuid = createSelector(
  state => state,
  (_, cohortUuid) => cohortUuid,
  (redux, cohortUuid) => {
    const userCohortsSelectorORM = ormCreateSelector(
      orm.UserCohort,
      orm.UserCohort.user,
      (userCohorts, user) =>
        userCohorts.map((userCohort, index) => ({
          ...userCohort,
          user: user[index]
        }))
    );

    const { active, complete, competent } = cohortRolesStatus;
    const goodRoles = [active, complete, competent];

    return userCohortsSelectorORM(redux).filter(
      userCohort =>
        userCohort.cohortUuid === cohortUuid &&
        userCohort.role === 'student' &&
        goodRoles.includes(userCohort.status)
    );
  }
);

export const delayedStudentRosterSelectorByCohortUuid = createSelector(
  state => state,
  (_, cohortUuid) => cohortUuid,
  (redux, cohortUuid) => {
    const userCohortsSelectorORM = ormCreateSelector(
      orm.UserCohort,
      orm.UserCohort.user,
      (userCohorts, user) =>
        userCohorts.map((userCohort, index) => ({
          ...userCohort,
          user: user[index]
        }))
    );

    const { delayed, inactive, withdrawn, transferred } = cohortRolesStatus;
    const goodRoles = [delayed, inactive, withdrawn, transferred];

    return userCohortsSelectorORM(redux).filter(
      userCohort =>
        userCohort.cohortUuid === cohortUuid &&
        userCohort.role === 'student' &&
        goodRoles.includes(userCohort.status)
    );
  }
);
