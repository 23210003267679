import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Divider, Chip } from '@material-ui/core';

import { validatePartTitle } from '../../../../helpers/validation/validateCourse';
import { validateSelectMultiUserField } from '../../../../helpers/validation/validateGeneric';
import {
  convertNullToString,
  convertNullToArray
} from '../../../../helpers/utilities';
import SelectUserFieldContainer from '../../../Library/SelectUserField/SelectUserFieldContainer';
import PartActionButtons from './PartActionButtons';
import DebouncedTextField from '../../../Library/DebouncedTextField';
import withValidation from '../../../Library/FieldWithError/FieldWithError';
import DescriptionEditor from '../../../Library/DescriptionEditor/DescriptionEditor';
import { useDescriptionEditorState } from '../../../../helpers/hooks/useDescriptionEditorState';

const TextFieldSC = styled(DebouncedTextField)`
  && {
    width: 100%;
    padding-right: 2rem;
    margin-top: 0;

    .MuiInput-root-244 {
      height: 36px;
    }
  }
`;

const Layout = styled.div`
  && {
    padding-top: 2rem;

    .part_field {
      margin-bottom: 1.5rem;
    }
  }
`;

const Body = styled.div`
  padding-top: 3rem;
`;

export const UserFieldSC = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  .part_field {
    margin-bottom: 0;
  }
  .SelectUserField-root-285 {
    width: 100%;
    height: auto;
  }
`;

const TextFieldWithError = withValidation(TextFieldSC);
const SelectUserFieldWithError = withValidation(SelectUserFieldContainer);

const ChipSC = styled(Chip)`
  margin: 0.5rem 0.5rem 0.5rem 0;

  ${props => `
    background: ${props.theme.colors.green[300]} !important;
    color: ${props.theme.colors.black} !important;

    & svg {
      fill: ${props.theme.colors.green[700]} !important;;
    }
  `};
`;

export const AllDepartmentFacultyOption = {
  label: 'All Department Faculty',
  value: 'all'
};

export const AllDepartmentFacultyChip = ({onClick}) => <ChipSC onClick={onClick} label={AllDepartmentFacultyOption.label} />

AllDepartmentFacultyChip.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const PartForm = ({
  hasErrors,
  usersArray,
  part,
  number,
  sectionId,
  handlePartInputChange,
  handlePartNumberChange,
  handlePartDirectorChange,
  handlePartDelete
}) => {
  const directors = part.part_directors_assigned
    ? part.part_directors_assigned.map(director => director.uuid)
    : [];

  const faculties = part.part_faculty_assigned
    ? part.part_faculty_assigned.map(faculty => faculty.uuid)
    : [];

  const { editorState, onEditorStateChange } = useDescriptionEditorState(
    convertNullToString(part?.description)
  );

  return (
    <Layout>
      <Divider />
      <Body>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <div>Part {number.toString().padStart(2, '0')}</div>
          </Grid>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={11}>
                <TextFieldWithError
                  id="title"
                  className="part_field title"
                  label="Part title*"
                  name="title"
                  value={convertNullToString(part.title)}
                  onChange={event =>
                    handlePartInputChange(event, sectionId, part.uuid)
                  }
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  handleValidate={validatePartTitle}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={1}>
                <PartActionButtons
                  handlePartNumberChange={handlePartNumberChange}
                  handlePartDelete={handlePartDelete}
                  sectionId={sectionId}
                  part={part}
                />
              </Grid>
            </Grid>
            <UserFieldSC>
              <SelectUserFieldWithError
                userSelect
                name="directors"
                handleValidate={validateSelectMultiUserField}
                hasErrors={hasErrors}
                fieldName="part directors"
                label="Part director(s)*"
                handleChange={event =>
                  handlePartDirectorChange(
                    event,
                    sectionId,
                    part.uuid,
                    'part_directors_assigned'
                  )
                }
                usersArray={usersArray}
                values={convertNullToArray(directors)}
              />
            </UserFieldSC>
            <SelectUserFieldContainer
              className="part_field"
              fieldName="participating faculty"
              label="Participating faculty"
              handleChange={event =>
                handlePartDirectorChange(
                  event,
                  sectionId,
                  part.uuid,
                  'part_faculty_assigned'
                )
              }
              usersArray={usersArray}
              values={convertNullToArray(faculties)}
              allOption={AllDepartmentFacultyOption}
            />
            <DescriptionEditor
              onEditorStateChange={onEditorStateChange}
              editorState={editorState}
              editorstyle={{ height: '180px' }}
              descriptionFor="Part"
              part={part}
            />
          </Grid>
        </Grid>
      </Body>
    </Layout>
  );
};

PartForm.propTypes = {
  hasErrors: PropTypes.bool,
  usersArray: PropTypes.arrayOf(PropTypes.object),
  part: PropTypes.object,
  number: PropTypes.number,
  sectionId: PropTypes.string,
  handlePartInputChange: PropTypes.func,
  handlePartNumberChange: PropTypes.func,
  handlePartDirectorChange: PropTypes.func,
  handlePartDelete: PropTypes.func
};

PartForm.defaultProps = {
  hasErrors: false,
  usersArray: [],
  part: {},
  number: 1,
  sectionId: '',
  handlePartInputChange: undefined,
  handlePartNumberChange: undefined,
  handlePartDirectorChange: undefined,
  handlePartDelete: undefined
};

export default PartForm;
export { TextFieldSC, Layout, SelectUserFieldWithError };
