export const GET_COURSE_COLLECTIONS = 'GET_COURSE_COLLECTIONS';
export const GET_COURSE_COLLECTIONS_SUCCESS = 'GET_COURSE_COLLECTIONS_SUCCESS';
export const GET_COURSE_COLLECTIONS_ERROR = 'GET_COURSE_COLLECTIONS_ERROR';

export const SEARCH_COLLECTION_COURSES = 'SEARCH_COLLECTION_COURSES';
export const SEARCH_COLLECTION_COURSES_SUCCESS =
  'SEARCH_COLLECTION_COURSES_SUCCESS';
export const SEARCH_COLLECTION_COURSES_ERROR =
  'SEARCH_COLLECTION_COURSES_ERROR';

export const POST_COURSE_COLLECTION = 'POST_COURSE_COLLECTION';
export const POST_COURSE_COLLECTION_SUCCESS = 'POST_COURSE_COLLECTION_SUCCESS';
export const POST_COURSE_COLLECTION_ERROR = 'POST_COURSE_COLLECTION_ERROR';

export const PUT_COURSE_COLLECTION = 'PUT_COURSE_COLLECTION';
export const PUT_COURSE_COLLECTION_SUCCESS = 'PUT_COURSE_COLLECTION_SUCCESS';
export const PUT_COURSE_COLLECTION_ERROR = 'PUT_COURSE_COLLECTION_ERROR';

export const DELETE_COURSE_COLLECTION = 'DELETE_COURSE_COLLECTION';
export const DELETE_COURSE_COLLECTION_SUCCESS =
  'DELETE_COURSE_COLLECTION_SUCCESS';
export const DELETE_COURSE_COLLECTION_ERROR = 'DELETE_COURSE_COLLECTION_ERROR';
