import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {Body1} from '@xcomp/xcomp-design-library';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useAtom, useAtomValue} from 'jotai';

import QuantitativeCollectionRow from './components/QuantitativeCollectionRow';
import {fetchCollectionsAtom, fetchingAtom} from '../../state/collectionState';

const Layout = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

`;

const QuantitativeCollectionsList = () => {
  const programUuid = useSelector(state => state.userState.selectedProgramUuid);

  const [collections, getCollections] = useAtom(fetchCollectionsAtom);
  const loading = useAtomValue(fetchingAtom);

  useEffect(() => {
    if (!programUuid) return;

    getCollections(programUuid).then();
  }, [programUuid, getCollections]);

  if (loading) {
    return (
      <Layout style={{flexDirection: 'column'}}>
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      </Layout>
    );
  }

  if (!loading && !collections?.length) {
    return (
      <Layout>
        <Body1>
          There are currently no Quantitative Skills Collection in this program.
        </Body1>
      </Layout>
    );
  }

  return (
    <Layout>
      {collections.map(collection => (
        <QuantitativeCollectionRow
          key={collection.uuid}
          quantitativeSkillCollection={collection}
        />
      ))}
    </Layout>
  );
};

export default QuantitativeCollectionsList;
