import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import { orm } from '../models';
import { userSectionStatus } from '../../helpers/constants';

export const selectOverallCredits = createSelector(
  state => state,
  (_, userUuid) => ({
    userUuid
  }),
  (redux, paramaters) => {
    const { userUuid } = paramaters;

    const getCourseMaster = ormCreateSelector(orm.CourseMaster);

    const getUserSection = ormCreateSelector(orm.UserSection);
    const getSection = ormCreateSelector(orm.Section);
    const getCourse = ormCreateSelector(orm.Course);

    if (userUuid) {
      const userSections = getUserSection(redux).filter(
        us =>
          us.userUuid === userUuid &&
          (us.status === userSectionStatus.credit ||
            us.status === userSectionStatus.pass ||
            us.status === userSectionStatus.pass_with_remediation ||
            us.status === userSectionStatus.fail ||
            us.status === userSectionStatus.fail_with_remediation)
      );

      const userSectionsResults = userSections.map(userSection => {
        const section = getSection(redux, userSection.sectionUuid);
        const course = getCourse(redux, section.courseUuid);
        const courseMaster = getCourseMaster(redux, course.courseMasterUuid);

        return {
          courseMaster
        };
      });

      const gradePointResults = _(userSectionsResults)
        .map(record => ({ credit: record?.courseMaster?.hours }))
        .value();

      const creditsTotal = _.reduce(
        gradePointResults,
        (total, record) => {
          const creditDecimal = new Decimal(record?.credit);
          return total.add(creditDecimal);
        },
        new Decimal(0)
      );

      const credits_total = creditsTotal.toFixed(2).toString();

      return credits_total;
    }
  }
);

export const selectTermCredits = createSelector(
  state => state,
  (_, userUuid, termUuid, terms) => ({
    userUuid,
    termUuid,
    terms
  }),
  (redux, paramaters) => {
    const { userUuid, termUuid, terms } = paramaters;

    const getCourseMaster = ormCreateSelector(orm.CourseMaster);

    const getUserSection = ormCreateSelector(orm.UserSection);
    const getSection = ormCreateSelector(orm.Section);
    const getCourse = ormCreateSelector(orm.Course);
    const getTerm = ormCreateSelector(orm.Term);

    if (userUuid) {
      const userSections = getUserSection(redux).filter(
        us =>
          us.userUuid === userUuid &&
          (us.status === userSectionStatus.credit ||
            us.status === userSectionStatus.pass ||
            us.status === userSectionStatus.pass_with_remediation ||
            us.status === userSectionStatus.fail ||
            us.status === userSectionStatus.fail_with_remediation)
      );

      const userSectionsResults = userSections.map(userSection => {
        const section = getSection(redux, userSection.sectionUuid);
        const course = getCourse(redux, section.courseUuid);
        const term = getTerm(redux, course.termUuid);
        const courseMaster = getCourseMaster(redux, course.courseMasterUuid);

        return {
          term,
          courseMaster
        };
      });

      const creditsTakenForTerm = _(userSectionsResults)
        .filter(record => record.term.uuid === termUuid)
        .map(record => ({ credit: record?.courseMaster?.hours }))
        .value();

      const creditsTotal = _.reduce(
        creditsTakenForTerm,
        (total, record) => {
          const creditDecimal = new Decimal(record?.credit);
          return total.add(creditDecimal);
        },
        new Decimal(0)
      );

      const creditsTakenToTerm = _(userSectionsResults)
        .filter(record => _.includes(terms, record.term.uuid))
        .map(record => ({ credit: record?.courseMaster?.hours }))
        .value();

      const creditsTotalToTerm = _.reduce(
        creditsTakenToTerm,
        (total, record) => {
          const creditDecimal = new Decimal(record?.credit);
          return total.add(creditDecimal);
        },
        new Decimal(0)
      );

      const credits_total_at_term = creditsTotal.toFixed(2).toString();

      const credits_total_to_term = creditsTotalToTerm.toFixed(2).toString();

      return { credits_total_at_term, credits_total_to_term };
    }
  }
);
