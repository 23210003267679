import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ByScopeCognitiveSource from './ByScopeCognitiveTranscripts';

import { selectTranscriptCognitiveByScopeCategory } from '../../../../../redux/selectors/transcriptCognitiveSelectors';

export default function ByScopeCognitiveTranscriptsContainer({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  classificationUuid
}) {
  const transcripts = useSelector(state =>
    selectTranscriptCognitiveByScopeCategory(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      category,
      classificationUuid
    )
  );

  return (
    <>
      {transcripts?.length > 0 && (
        <ByScopeCognitiveSource
          transcripts={transcripts}
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          classificationUuid={classificationUuid}
        />
      )}
    </>
  );
}

ByScopeCognitiveTranscriptsContainer.propTypes = {
  category: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  classificationUuid: PropTypes.string
};
ByScopeCognitiveTranscriptsContainer.defaultProps = {
  category: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  classificationUuid: undefined
};
