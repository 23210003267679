import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import { optionsTopicScoreView } from '../../../../helpers/options';
import FormSelect from '../../../Library/FormSelect';
import { Panel, PanelHeader } from '../../../Library/DashboardSC';
import { HeaderSpacer } from '../../../Library/ScoresComponents/ScoresSC';
import TopicDefaultScoreViewTopicPanelContainer from '../../../Library/ScoresComponents/Topic/TopicDefaultScoreViewTopicPanelContainer';

const ListSortContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 8px 21px;
`;

const FormSelectSC = styled(FormSelect)`
  && {
    background-color: ${props => props.theme.colors.white};
    width: 500px;
  }
`;
const TopicPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 24px;
  width: calc(100% - 24px);
`;

const TopicPanelHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  font-weight: 450;
`;

const TopicPanelHeaderSub = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  padding-right: ${props => props.paddingRight};
  color: ${props => props.theme.fontColors.dark};
  text-align: right;
  }
`;

export default function TopicList({
  assessmentUuid,
  cumulativePotentialScore,
  onSelectTopicScoreView,
  faculty,
  partUuid,
  scoreType,
  students,
  topicScoreData,
  topicScoreViewSelection,
  topics
}) {
  return (
    <>
      <HeaderSpacer />
      <Panel maxWidth={1600}>
        <ListSortContainer>
          <FormSelectSC
            handleChange={onSelectTopicScoreView}
            label="View"
            name="sort"
            options={optionsTopicScoreView}
            value={topicScoreViewSelection}
          />
        </ListSortContainer>
        <PanelHeader paddingLeft="0px">
          <TopicPanelHeader>
            <TopicPanelHeaderInfo>
              Student (Last name, First name)
            </TopicPanelHeaderInfo>
            <TopicPanelHeaderInfo>
              <TopicPanelHeaderSub paddingRight="24px">
                Participation (%)
              </TopicPanelHeaderSub>
              <TopicPanelHeaderSub paddingRight="24px">
                Relative (%)
              </TopicPanelHeaderSub>
              <TopicPanelHeaderSub paddingRight="24px">
                Total Relative
              </TopicPanelHeaderSub>
              <TopicPanelHeaderSub paddingRight="44px">
                Total Potential
              </TopicPanelHeaderSub>
            </TopicPanelHeaderInfo>
          </TopicPanelHeader>
        </PanelHeader>
        {topics.map(topic => (
          <LazyLoad key={topic.uuid}>
            <TopicDefaultScoreViewTopicPanelContainer
              assessmentUuid={assessmentUuid}
              cumulativePotentialScore={cumulativePotentialScore}
              faculty={faculty}
              partUuid={partUuid}
              scoreType={scoreType}
              students={students}
              topicScores={topicScoreData[topic.uuid].topicScores}
              topicScoreViewSelection={topicScoreViewSelection}
              topicTotalPotential={topicScoreData[topic.uuid].totalPotential}
              topicTotalRelative={topicScoreData[topic.uuid].totalRelative}
              topic={topic}
            />
          </LazyLoad>
        ))}
      </Panel>
    </>
  );
}

TopicList.propTypes = {
  assessmentUuid: PropTypes.string,
  cumulativePotentialScore: PropTypes.string,
  topicScoreViewSelection: PropTypes.string,
  onSelectTopicScoreView: PropTypes.func,
  faculty: PropTypes.array,
  partUuid: PropTypes.string,
  topicScoreData: PropTypes.object,
  scoreType: PropTypes.string,
  students: PropTypes.array,
  topics: PropTypes.array
};

TopicList.defaultProps = {
  assessmentUuid: '',
  cumulativePotentialScore: '',
  topicScoreViewSelection: '',
  onSelectTopicScoreView: undefined,
  faculty: [],
  partUuid: '',
  topicScoreData: {},
  scoreType: '',
  students: [],
  topics: []
};
