import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { selectExamAptitude } from '../../../../../redux/selectors/examAptitudeSelectors';

export default function ExamName({ examAptitudeUuid }) {
  const { examName } = useSelector(
    state => selectExamAptitude(state, examAptitudeUuid),
    shallowEqual
  );

  return examName;
}

ExamName.propTypes = { examAptitudeUuid: PropTypes.string };
ExamName.defaultProps = { examAptitudeUuid: undefined };
