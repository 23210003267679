import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useContainerDimensions } from '../../../helpers/hooks/layout.hooks';
import AccordionRowContainer from '../../Library/AccordionRow/AccordionRowContainer';
import OpportunityReportAssessmentBlockPanelContainer from './OpportunityReportAssessmentBlockPanelContainer';
import OpportunityReportStatisticBlockDetailContainer from './OpportunityReportStatisticBlockDetailContainer';

const OpportunityReportAssessmentPanelAccordion = styled(props => (
  <AccordionRowContainer {...props} />
))`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  && .accordion_row_label {
    flex-grow: 2;
    max-width: ${props => props.labelMaxWidth};
    white-space: nowrap;
    div {
      color: ${props => props.theme.fontColors.darker};
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .column_one_label {
    min-width: 140px;
  }
  .column_two_label {
    min-width: 100px;
  }
  .column_three_label {
    min-width: 120px;
  }
  .column_one_label,
  .column_two_label,
  .column_three_label {
    flex-grow: 0;
    text-align: right;
  }

  && .column_one_label,
  && .column_two_label {
    margin-right: 16px;
    span {
      color: ${props => props.theme.fontColors.darker};
      font-size: 14px;
      font-weight: 500;
    }
  }

  && .column_three_label {
    span {
      color: ${props => props.theme.fontColors.darker};
      font-size: 14px;
      font-weight: 500;
    }
  }

  .hover-effect {
    padding-right: 44px;
  }
  padding-left: 32px;
  width: 100%;
`;

const OpportunityReportAssessmentPanel = ({
  assessment,
  assessmentBlocks,
  panelLabel,
  studentAssessmentOpportunityCount,
  studentAssessmentPointsPerOpportunity,
  studentAssessmentRelativeScore,
  studentUser
}) => {
  const panelRef = useRef(null);
  const { width } = useContainerDimensions(panelRef);

  return (
    <OpportunityReportAssessmentPanelAccordion
      columnOne={`${studentAssessmentOpportunityCount}`}
      columnTwo={`${studentAssessmentRelativeScore}`}
      columnThree={`${studentAssessmentPointsPerOpportunity}`}
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
      columnThreeClassName="column_three_label"
      expandWidth="66px"
      height="50px"
      label={panelLabel}
      // column one, two, three + expansion + padding
      labelMaxWidth={`${Number(width) - 520}px`}
      panelRef={panelRef}
      withBottomBorder
      withLabelTooltip
    >
      {assessmentBlocks.map(assessmentBlock =>
        studentUser.uuid === 'student_high' ||
        studentUser.uuid === 'student_average' ? (
          <OpportunityReportStatisticBlockDetailContainer
            key={assessmentBlock.uuid}
            assessment={assessment}
            assessmentBlock={assessmentBlock}
            studentUser={studentUser}
          />
        ) : (
          <OpportunityReportAssessmentBlockPanelContainer
            key={assessmentBlock.uuid}
            assessment={assessment}
            assessmentBlock={assessmentBlock}
            studentUser={studentUser}
          />
        )
      )}
    </OpportunityReportAssessmentPanelAccordion>
  );
};

OpportunityReportAssessmentPanel.propTypes = {
  assessment: PropTypes.object,
  assessmentBlocks: PropTypes.array,
  panelLabel: PropTypes.string,
  studentAssessmentOpportunityCount: PropTypes.number,
  studentAssessmentPointsPerOpportunity: PropTypes.string,
  studentAssessmentRelativeScore: PropTypes.string,
  studentUser: PropTypes.object
};

OpportunityReportAssessmentPanel.defaultProps = {
  assessment: {},
  assessmentBlocks: [],
  panelLabel: '',
  studentAssessmentOpportunityCount: 0,
  studentAssessmentPointsPerOpportunity: '',
  studentAssessmentRelativeScore: '',
  studentUser: {}
};

export default OpportunityReportAssessmentPanel;
