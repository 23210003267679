import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { ButtonIcon } from '@xcomp/xcomp-design-library';

const SnackbarSC = styled(Snackbar)`
  margin-top: 10px;
  && {
    div {
      background-color: ${props => props.bgcolor};
      flex-wrap: unset;
    }
  }
`;

const ButtonIconSC = styled(ButtonIcon)`
  && {
    svg {
      fill: ${props => props.theme.colors.white};
    }
    &:hover {
      svg {
        fill: ${props => props.theme.colors.white};
      }
    }
  }
`;

const MessageLayout = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const ClientMessage = styled.div``;

const ServerMessage = styled.div`
  font-size: 11px;
  margin-top: 10px;
  width: 300px;
`;

const Notification = props => {
  const { open, handleClose, notifications, notificationBg } = props;
  const duration = notifications?.type === 'Error' ? 10000 : 5000;

  return (
    <div>
      <SnackbarSC
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        bgcolor={notificationBg}
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
        message={
          <MessageLayout id="message-id">
            <ClientMessage data-testid="notification-message">
              {notifications?.message}
            </ClientMessage>
            {notifications?.type === 'Error' && notifications?.error_server && (
              <ServerMessage>{notifications.error_server}</ServerMessage>
            )}
          </MessageLayout>
        }
        transitionDuration={{
          exit: 0
        }}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        action={[
          <ButtonIconSC key="undo" onClick={handleClose} noHoverBg>
            <CloseIcon key="close" />
          </ButtonIconSC>
        ]}
      />
    </div>
  );
};

Notification.propTypes = {
  open: PropTypes.bool,
  notificationBg: PropTypes.string,
  handleClose: PropTypes.func,
  notifications: PropTypes.object
};

Notification.defaultProps = {
  open: false,
  handleClose: undefined,
  notifications: {},
  notificationBg: 'gray'
};

export default Notification;
