import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AcademicYearAdd from './AcademicYearAdd';

import {
  validateTermsPeriod,
  validateTermDates
} from '../../../../../helpers/validation/validateAcademicYearTerm';

import { validateSelectDate } from '../../../../../helpers/validation/validateGeneric';
import { doPostAcademicYear } from '../../../../../redux/actions/academicYearActions';

export default function AcademicYearAddContainer({ academicYear }) {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [terms, setTerms] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [submit, setSubmit] = useState(false);
  const schoolUuid = useSelector(state => state.userState.selectedSchoolUuid);

  const handleDateChange = (indentifier, field, date) => {
    switch (field) {
      case 'startDate':
        setStartDate(date);
        break;
      case 'endDate':
        setEndDate(date);
        break;
      default:
        break;
    }
  };

  const handleAddTerm = () => {
    const addTerm = {
      indentifier: uuidv4().toString(),
      period: '',
      startDate: '',
      endDate: ''
    };

    setTerms([...terms, addTerm]);
  };

  const handleTermChange = (indentifier, event) => {
    const { value } = event.target;

    const termsUpdated = terms.map(term => {
      if (term.indentifier === indentifier) {
        return { ...term, period: value };
      } else {
        return term;
      }
    });

    setTerms(termsUpdated);
  };

  const handleRemoveTerm = indentifier => {
    const termsUpdated = terms.filter(term => term.indentifier !== indentifier);
    setTerms(termsUpdated);
  };

  const handleTermDateChange = (indentifier, field, date) => {
    let update;

    if (field === 'startDate') {
      update = {
        startDate: date
      };
    }

    if (field === 'endDate') {
      update = {
        endDate: date
      };
    }

    const termsUpdated = terms.map(term => {
      if (term.indentifier === indentifier) {
        return { ...term, ...update };
      } else {
        return term;
      }
    });

    setTerms(termsUpdated);
  };

  const _validate = () => {
    const startDateError = validateSelectDate(startDate);
    const endDateError = validateSelectDate(endDate);
    const termsPeriodError = validateTermsPeriod(terms);
    const termsDateError = validateTermDates(terms);

    const validations = {
      startDateInvalid: startDateError,
      endDateInvalid: endDateError,
      termsPeriodInvalid: termsPeriodError,
      termsDateInvalid: termsDateError
    };

    const isValid = !_.some(validations, { invalid: true });

    return isValid;
  };

  const handleSubmit = () => {
    const check = _validate();

    if (check) {
      setSubmit(true);
      setHasErrors(false);

      const payload = {
        startDate,
        endDate,
        terms,
        schoolUuid
      };

      dispatch(doPostAcademicYear(payload));
    } else {
      setSubmit(false);
      setHasErrors(true);
    }
  };

  return (
    <AcademicYearAdd
      startDate={startDate}
      endDate={endDate}
      terms={terms}
      academicYear={academicYear}
      hasErrors={hasErrors}
      submit={submit}
      handleDateChange={handleDateChange}
      handleAddTerm={handleAddTerm}
      handleTermChange={(indentifier, event) =>
        handleTermChange(indentifier, event)
      }
      handleTermDateChange={handleTermDateChange}
      handleRemoveTerm={handleRemoveTerm}
      handleSubmit={handleSubmit}
    />
  );
}

AcademicYearAddContainer.propTypes = {
  academicYear: PropTypes.object
};
AcademicYearAddContainer.defaultProps = {
  academicYear: {}
};
