export const GET_DEGREE_LEVELS = 'GET_DEGREE_LEVELS';
export const GET_DEGREE_LEVELS_SUCCESS = 'GET_DEGREE_LEVELS_SUCCESS';
export const GET_DEGREE_LEVELS_ERROR = 'GET_DEGREE_LEVELS_ERROR';

export const GET_ACCREDITATION_AGENCIES = 'GET_ACCREDITATION_AGENCIES';
export const GET_ACCREDITATION_AGENCIES_SUCCESS =
  'GET_ACCREDITATION_AGENCIES_SUCCESS';
export const GET_ACCREDITATION_AGENCIES_ERROR =
  'GET_ACCREDITATION_AGENCIES_ERROR';

export const GET_PROGRAM_CIPS = 'GET_PROGRAM_CIPS';
export const GET_PROGRAM_CIPS_SUCCESS = 'GET_PROGRAM_CIPS_SUCCESS';
export const GET_PROGRAM_CIPS_ERROR = 'GET_PROGRAM_CIPS_ERROR';
export const GET_PROGRAM_DISCIPLINES = 'GET_PROGRAM_DISCIPLINES';
export const GET_PROGRAM_DISCIPLINES_SUCCESS =
  'GET_PROGRAM_DISCIPLINES_SUCCESS';
export const GET_PROGRAM_DISCIPLINES_ERROR = 'GET_PROGRAM_DISCIPLINES_ERROR';
export const GET_PROGRAM_CODES = 'GET_PROGRAM_CODES';
export const GET_PROGRAM_CODES_SUCCESS = 'GET_PROGRAM_CODES_SUCCESS';
export const GET_PROGRAM_CODES_ERROR = 'GET_PROGRAM_CODES_ERROR';

export const GET_SOURCE_INSTITUTIONS_BY_CODE_SUCCESS =
  'GET_SOURCE_INSTITUTIONS_BY_CODE_SUCCESS';

export const GET_SOURCE_INSTITUTIONS_BY_CODE_SUCCESS_ERROR =
  'GET_SOURCE_INSTITUTIONS_BY_CODE_SUCCESS_ERROR';

export const GET_INSTITUTION_ID_TYPES = 'GET_INSTITUTION_ID_TYPES';
export const GET_INSTITUTION_ID_TYPES_SUCCESS =
  'GET_INSTITUTION_ID_TYPES_SUCCESS';
export const GET_INSTITUTION_ID_TYPES_ERROR = 'GET_INSTITUTION_ID_TYPES_ERROR';

export const GET_SOURCE_PROGRAM_CODES_BY_TITLE_SUCCESS =
  'GET_SOURCE_PROGRAM_CODES_BY_TITLE_SUCCESS';
export const GET_SOURCE_PROGRAM_CODES_BY_TITLE_ERROR =
  'GET_SOURCE_PROGRAM_CODES_BY_TITLE_ERROR';
