import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import styled, { ThemeContext } from 'styled-components';
import useSWRMutation from 'swr/mutation';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotFoundIllustration from '@xcomp/xcomp-design-library/dist/illustrations/custom/NotFoundIllustration';

import Filters, {
  filtersAtom,
  filtersDefaultValues,
  reportDataAtom
} from '../../PartGradeSummary/PartAttemptReport/Filters';
import { ASSESSMENT_GRID_MODAL_ID } from '../../../Reports/reportUtils';
import AssessmentTable from '../../../Reports/CohortAssessmentGrid/Table/AssessmentTable';
import { fetcher } from '../../PartGradeSummary/PartAttemptReport/PartAttemptReport';
import { useComputeAttemptReportSummaryRows } from '../../../../helpers/hooks/useComputeAttemptReportSummaryRows';

const ScrollableContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
  background: ${props => props.theme.colors.grey[200]};
`;

const NotFoundWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;

  & > p {
    font-size: 22px;
    font-weight: 500;
  }
`;

const SectionDidacticReport = () => {
  const themeContext = useContext(ThemeContext);

  const { sectionUuid } = useParams();
  const [filters, setFilters] = useAtom(filtersAtom);
  const [reportData, setReportData] = useAtom(reportDataAtom);

  const { students, summaryRows } = useComputeAttemptReportSummaryRows(true);

  const { trigger, isMutating } = useSWRMutation(
    `/api/section-attempt-report/${sectionUuid}/attempt-report`,
    fetcher
  );

  useEffect(() => {
    setFilters(filtersDefaultValues);
    setReportData(null);
  }, [setFilters, setReportData]);

  if (!reportData || !reportData?.assessmentBlocks?.length) {
    return (
      <>
        <Filters trigger={trigger} isSection isDidactic />

        <NotFoundWrapper>
          {isMutating ? (
            <CircularProgress />
          ) : (
            <>
              <NotFoundIllustration
                dotsColor={themeContext.colors.primaryShades[450]}
                bgColor={themeContext.colors.primaryShades[200]}
                outlineColor={themeContext.colors.primary}
              />
              <p>
                There were no students and/or assessments found for this cohort.
                Please use the filters.
              </p>
            </>
          )}
        </NotFoundWrapper>
      </>
    );
  }

  return (
    <>
      <Filters trigger={trigger} isSection isDidactic />
      {isMutating ? (
        <NotFoundWrapper>
          <CircularProgress />
        </NotFoundWrapper>
      ) : (
        <div style={{ height: 'calc(100% - 65px)' }}>
          <div
            id={ASSESSMENT_GRID_MODAL_ID}
            style={{ paddingLeft: 15, paddingTop: 15, zoom: 1, height: '100%' }}
          >
            <ScrollableContainer>
              <AssessmentTable
                noOfBlocks={
                  (filters.isExpanded ? filters.filteredAssessments : filters.filteredParts).includes('all')
                    ? (filters.isExpanded ? filters.filteredAssessments : filters.filteredParts).length - 1
                    : (filters.isExpanded ? filters.filteredAssessments : filters.filteredParts).length
                }
                assessments={
                  filters?.isExpanded
                    ? reportData?.assessmentBlocks
                    : reportData?.parts
                }
                students={students.s}
                isDidactic
                isAnonymized={filters.isAnonymized}
                dataCellValueType={filters.dataCellValueType}
                isExpanded={filters.isExpanded}
                summaryRows={summaryRows}
                filteredStudentUuids={filters.filteredStudents}
                filteredAssessmentUuids={filters.isExpanded ? filters.filteredAssessments : filters.filteredParts}
                totalFirstAttemptPassRate={summaryRows.firstTimePassRate}
                showDaysOfCompletion={false}
              />
            </ScrollableContainer>
          </div>
        </div>
      )}
    </>
  );
};

export default SectionDidacticReport;
