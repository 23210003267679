import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import {
  Grid,
  Paper,
  FormHelperText,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
} from '@material-ui/core'

import {
  DocumentIcon,
  DateIcon,
  UsersIcon,
} from '@xcomp/xcomp-design-library/dist/icons';

import {
  validateSyllabusSelectUserSingle,
  validateSyllabusSelectMultiUserField,
} from '../../../../../helpers/validation/validateSyllabus'

import { validateAssessmentTitle } from '../../../../../helpers/validation/validateAssessment'

import withValidation from '../../../../Library/FieldWithError/FieldWithError'
import FormErrors from '../../../../Library/FormErrors'
import SelectUserFieldContainer from '../../../../Library/SelectUserField/SelectUserFieldContainer'
import DateTime from '../../../../Library/DateTime'
import ItemFormHeader from './ItemFormHeader'
import DeleteModal from '../../../../Library/Modal/DeleteModal/DeleteModal'
import SyllabusSubHeader from './SyllabusSubHeader'
import FormSelectError from '../../../../Library/FormSelectError'
import FormField from '../../../../Library/FormField'

import { convertNullToString } from '../../../../../helpers/utilities'
import { optionsScoreType } from '../../../../../helpers/options'
import ProgramAssessmentMastersSelectField from '../ProgramAssessmentMastersSelect/ProgramAssessmentMastersSelectField'
import GradingTechniqueForm from '../../../../Library/GradingTechniqueForm/GradingTechniqueForm'

const FormHelperTextSC = styled(FormHelperText)`
  && {
    margin-top: 0.5rem;
    margin-bottom: 1.25rem;
  }
`

const ProgramAssessmentMastersSelectFieldSC = styled(
  ProgramAssessmentMastersSelectField,
)`
  && {
    margin-top: 0;
    margin-bottom: 48px;
  }
`;

const SyllabusSubHeaderSC = styled(SyllabusSubHeader)`
  && {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
`;

const AssessmentFormBody = styled.div`
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 200px;
  margin-bottom: 200px;
`;

const Layout = styled.div`
  width: 100%;
`;

const DatetimeSC = styled(DateTime)`
  height: 100%;
`;

const TimespanOptions = [
  {
    value: 'ONE TIME',
    label: 'One Time'
  },
  {
    value: 'WEEKLY',
    label: 'Weekly'
  },
  {
    value: 'OPEN ENDED',
    label: 'Open Ended'
  }
];

const PaperSC = styled(Paper)`
  && {
    border-radius: 0;
  }
`;

const DatetimePickerWithError = withValidation(DatetimeSC);
const SelectUserFieldWithError = withValidation(SelectUserFieldContainer);
const CheckboxContainer = styled.div`
  color: ${props => props.theme.fontColors.dark};
`;
const AssessmentForm = ({
  assessmentDispatch,
  assessmentIndex,
  assessmentState,
  existing,
  fieldInFocus,
  hasErrors,
  isNew,
  optionsAssessmentAssociations,
  optionsAssessmentTechniques,
  optionsAssessmentTypes,
  optionsFaculty,
  parentCohort,
  programAssessmentMaster,
  restrictEdit,
  selectedAssessmentMasterUuid,
  handleAssessmentChange,
  handleUserFieldChange,
  handleAssessmentSubmit,
  handleFieldInFocusValidation,
  isDeleteModalOpen,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete,
  handleRubricCheck
}) => {
  const isAssessmentMasterSelected =
    selectedAssessmentMasterUuid && selectedAssessmentMasterUuid !== '';

  const {
    uuid,
    status,
    title,
    responsibleFaculty,
    assessmentManager,
    rubricCheck
  } = assessmentState;

  const {
    timespan,
    scoreType,
    assessmentTypeUuid,
    assessmentAssociationUuid,
    assessmentTechniqueUuid,
    grading_technique,
  } = programAssessmentMaster

  const startDate = new Date(parentCohort.startDate)
  const endDate = new Date(parentCohort.endDate)

  return (
    <Layout>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={handleDeleteModalClose}
        handleDelete={handleDelete}
        type='Cohort Assessment'
      />
      <PaperSC>
        <ItemFormHeader
          restrictEdit={restrictEdit}
          itemType="Cohort Assessment"
          isExistingItem={existing}
          itemNumber={assessmentIndex}
          item={{ uuid, status }}
          handleSaveDraft={handleAssessmentSubmit}
          handleDeleteOpen={handleDeleteClick}
          isNew={isNew}
        />
        <AssessmentFormBody>
          <FormErrors hasErrors={hasErrors} />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SyllabusSubHeaderSC first>
                <DocumentIcon />
                Program Assessment Master
              </SyllabusSubHeaderSC>

              <ProgramAssessmentMastersSelectFieldSC
                programAssessmentMaster={programAssessmentMaster}
                selectedAssessmentMasterUuid={selectedAssessmentMasterUuid}
                assessmentDispatch={assessmentDispatch}
                hasErrors={hasErrors}
                isNew={isNew}
              />
            </Grid>
            <>
              <Grid item xs={12}>
                <SyllabusSubHeaderSC>
                  <DocumentIcon />
                  Assessment Information
                </SyllabusSubHeaderSC>
                <Tooltip
                  title="Assessment type is set at the program assessment master level and cannot be changed for this cohort assessment"
                  placement="top-start"
                >
                  <div>
                    <FormSelectError
                      name="assessmentTypeUuid"
                      label="Assessment Type*"
                      value={convertNullToString(assessmentTypeUuid)}
                      handleChange={handleAssessmentChange}
                      options={optionsAssessmentTypes}
                      disabled
                    />
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <FormField
                  name="title"
                  label="Assessment Title*"
                  value={title}
                  onBlur={handleAssessmentChange}
                  inputProps={{ maxLength: 100 }}
                  handleValidate={validateAssessmentTitle}
                  hasErrors={hasErrors || fieldInFocus === 'title'}
                  onFocus={handleFieldInFocusValidation}
                  disabled={restrictEdit || !isAssessmentMasterSelected}
                />
                <FormHelperTextSC>
                  Character Count: {title.length}/100
                </FormHelperTextSC>
              </Grid>
              <Grid item xs={12}>
                <SyllabusSubHeaderSC>
                  <UsersIcon />
                  Responsible Personnel
                </SyllabusSubHeaderSC>
              </Grid>
              <Grid item xs={9}>
                <SelectUserFieldWithError
                  name="responsibleFaculty"
                  handleValidate={validateSyllabusSelectUserSingle}
                  hasErrors={hasErrors || fieldInFocus === 'responsibleFaculty'}
                  userSelect
                  fieldName="responsibleFaculty"
                  label="Responsible Faculty*"
                  handleChange={handleUserFieldChange}
                  usersArray={optionsFaculty}
                  placeholder="Select"
                  values={responsibleFaculty}
                  onFocus={() => {
                    handleFieldInFocusValidation({
                      target: {
                        name: 'responsibleFaculty'
                      }
                    });
                  }}
                  singleValue
                  isDisabled={restrictEdit}
                />
              </Grid>
              <Grid item xs={9}>
                <SelectUserFieldWithError
                  name="assessmentManager"
                  handleValidate={validateSyllabusSelectMultiUserField}
                  hasErrors={hasErrors || fieldInFocus === 'assessmentManager'}
                  userSelect
                  fieldName="assessmentManager"
                  label="Assessment Manager*"
                  handleChange={handleUserFieldChange}
                  usersArray={optionsFaculty}
                  placeholder="Select a user"
                  values={assessmentManager}
                  onFocus={() => {
                    handleFieldInFocusValidation({
                      target: {
                        name: 'assessmentManager'
                      }
                    });
                  }}
                  singleValue
                  isDisabled={restrictEdit}
                />
              </Grid>

              <Grid item xs={12}>
                <SyllabusSubHeaderSC>
                  <DateIcon />
                  Settings (View Only)
                </SyllabusSubHeaderSC>
              </Grid>

              <Grid item xs={6}>
                <FormSelectError
                  name="timespan"
                  label="Timespan"
                  value={convertNullToString(timespan)}
                  handleChange={undefined}
                  options={TimespanOptions}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <DatetimePickerWithError
                  value={startDate}
                  name="startDate"
                  label="Start Time*"
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <DatetimePickerWithError
                  value={endDate}
                  name="endDate"
                  label="End Time*"
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelectError
                  name="assessmentAssociationUuid"
                  label="Assessment Association"
                  value={convertNullToString(assessmentAssociationUuid)}
                  handleChange={undefined}
                  options={optionsAssessmentAssociations}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelectError
                  name="assessmentTechniqueUuid"
                  label="Assessment Technique*"
                  value={convertNullToString(assessmentTechniqueUuid)}
                  handleChange={undefined}
                  options={optionsAssessmentTechniques}
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <SyllabusSubHeaderSC>
                  <DocumentIcon />
                  Grade Summary (View Only)
                </SyllabusSubHeaderSC>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormGroup>
                      <CheckboxContainer>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id='rubric_check'
                              onChange={handleRubricCheck}
                              checked={rubricCheck}
                              disabled
                              color='primary'
                            />
                          }
                          label="Validate block title and step description when loading scores"
                        />
                      </CheckboxContainer>
                    </FormGroup>
                  </FormControl>
                </Grid>
                <FormSelectError
                  name="scoreType"
                  label="Score Type*"
                  value={convertNullToString(scoreType)}
                  handleChange={undefined}
                  options={optionsScoreType}
                  disabled
                />
              </Grid>
              {grading_technique && (
                <>
                  <Grid item xs={12}>
                    <SyllabusSubHeaderSC>
                      <DateIcon />
                      Grading Technique (View Only)
                    </SyllabusSubHeaderSC>

                    <Tooltip
                      title="Grading technique is set at the program assessment master level and cannot be changed for this cohort assessment"
                      placement="top-start"
                    >
                      <div>
                        <GradingTechniqueForm
                          allowPointsOnly
                          isProgramAssessment
                          isReadOnly
                          curvedFailingGrade={null}
                          handleChangeRadio={undefined}
                          handleGradingTechniqueChange={undefined}
                          lowScoreInput={null}
                          lowScoreCalculated={null}
                          calculatedLowScore={null}
                          maximumMethodCalculated={
                            grading_technique
                              ? grading_technique.maximumMethodCalculated
                              : null
                          }
                          maximumRevusInput={
                            grading_technique
                              ? grading_technique.maximumRevus
                              : null
                          }
                          calculatedHighScore={null}
                          scaledHighScore={null}
                          scaledLowScore={null}
                          selectedRadio={
                            grading_technique
                              ? grading_technique.gradingTechnique
                              : null
                          }
                          showValidationErrors={hasErrors}
                        />
                      </div>
                    </Tooltip>
                  </Grid>
                </>
              )}
            </>
          </Grid>
        </AssessmentFormBody>
      </PaperSC>
    </Layout>
  );
};

AssessmentForm.propTypes = {
  selectedAssessmentMasterUuid: PropTypes.string,
  programAssessmentMaster: PropTypes.object,
  assessmentState: PropTypes.object,
  parentCohort: PropTypes.object,
  assessmentDispatch: PropTypes.func,
  isNew: PropTypes.bool,
  gradingTechnique: PropTypes.object,
  handleAssessmentSubmit: PropTypes.func,
  assessmentAssociationUuid: PropTypes.string,
  assessmentManager: PropTypes.string,
  assessmentTechniqueUuid: PropTypes.string,
  assessmentTypeUuid: PropTypes.string,
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  responsibleFaculty: PropTypes.string,
  scoreType: PropTypes.string,
  status: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  timespan: PropTypes.string,
  title: PropTypes.string,
  existing: PropTypes.bool,
  restrictEdit: PropTypes.bool,
  hasErrors: PropTypes.bool,
  optionsFaculty: PropTypes.array,
  optionsAssessmentTypes: PropTypes.arrayOf(PropTypes.object),
  optionsAssessmentTechniques: PropTypes.arrayOf(PropTypes.object),
  optionsAssessmentAssociations: PropTypes.arrayOf(PropTypes.object),
  assessmentIndex: PropTypes.number,
  fieldInFocus: PropTypes.string,
  handleAssessmentChange: PropTypes.func,
  handleFieldInFocusValidation: PropTypes.func,
  handleUserFieldChange: PropTypes.func,
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleRubricCheck: PropTypes.func
};

AssessmentForm.defaultProps = {
  selectedAssessmentMasterUuid: '',
  programAssessmentMaster: {},
  assessmentState: {},
  parentCohort: {},
  assessmentDispatch: undefined,
  isNew: false,
  gradingTechnique: {},
  assessmentAssociationUuid: '',
  assessmentManager: '',
  assessmentTechniqueUuid: '',
  assessmentTypeUuid: '',
  endDate: null,
  responsibleFaculty: '',
  scoreType: '',
  status: '',
  startDate: null,
  timespan: '',
  title: '',
  existing: false,
  hasErrors: false,
  restrictEdit: true,
  optionsFaculty: [],
  optionsAssessmentTypes: [],
  optionsAssessmentTechniques: [],
  optionsAssessmentAssociations: [],
  assessmentIndex: 0,
  fieldInFocus: '',
  handleAssessmentSubmit: undefined,
  handleAssessmentChange: undefined,
  handleFieldInFocusValidation: undefined,
  handleUserFieldChange: undefined,
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined,
  handleRubricCheck: undefined
};

export default AssessmentForm;
