import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { doPostProgram } from '../../../redux/actions/programActions';
import {
  doGetDegreeLevels,
  doGetProgramCips,
  doGetProgramDisciplines,
  doGetProgramCodes,
  doGetAccreditationAgencies
} from '../../../redux/actions/sourceActions';
import { degreeLevelsSelector } from '../../../redux/selectors/degreeLevelsSelectors';
import {
  institutionAAsSelector,
  programAAsSelector
} from '../../../redux/selectors/accreditationAgenciesSelectors';

import { programCipsSelector } from '../../../redux/selectors/degreeCodeSelector';

import {
  validateInputString,
  validateSelectField
} from '../../../helpers/validation/validateGeneric';

import ProgramAdd from './ProgramAdd';

class ProgramAddContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programName: '',
      programAbbreviation: '',
      degreeAbbreviation: '',
      degreeName: '',
      programCip: '',
      programDiscipline: '',
      programCode: '',
      degreeDesignation: '',
      degreeDescription: '',
      institutionAA: '',
      programAA: '',
      redirect: false,
      hasErrors: false
    };

    this._clearProgramDisciplineAndCode = this._clearProgramDisciplineAndCode.bind(
      this
    );
    this._clearProgramCode = this._clearProgramCode.bind(this);
    this._validate = this._validate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreateProgram = this.handleCreateProgram.bind(this);
  }

  componentDidMount() {
    const {
      onGetDegreeLevels,
      onGetAccreditationAgencies,
      onGetProgramCips
    } = this.props;
    onGetDegreeLevels();
    onGetAccreditationAgencies();
    onGetProgramCips();
  }

  componentDidUpdate(prevProps, prevState) {
    const { onGetProgramDisciplines, onGetProgramCodes } = this.props;
    const { programCip, programDiscipline } = this.state;

    if (prevState.programCip !== programCip && programCip !== '') {
      onGetProgramDisciplines(programCip);
    }

    if (prevState.programCip !== programCip) {
      this._clearProgramDisciplineAndCode();
    }

    if (prevState.programDiscipline !== programDiscipline) {
      this._clearProgramCode();
    }

    if (
      prevState.programDiscipline !== programDiscipline &&
      programDiscipline !== ''
    ) {
      onGetProgramCodes(programDiscipline);
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleCreateProgram() {
    const _validate = this._validate();

    if (_validate) {
      this.setState({
        hasErrors: true
      });
    } else {
      const { schoolUuid } = this.props;
      const {
        programName,
        programAbbreviation,
        degreeAbbreviation,
        degreeName,
        programCip,
        programDiscipline,
        programCode,
        degreeDesignation,
        institutionAA,
        programAA
      } = this.state;

      const payload = {
        programName,
        programAbbreviation,
        degreeAbbreviation,
        degreeName,
        programCip,
        programDiscipline,
        programCode,
        degreeDesignation,
        institutionAA,
        programAA,
        schoolUuid
      };

      this.props.onPostProgram(payload);

      this.setState({
        redirect: true
      });
    }
  }

  _validate() {
    let validationErrors = false;

    const {
      programName,
      programAbbreviation,
      degreeAbbreviation,
      degreeName,
      programCip,
      programDiscipline,
      programCode,
      degreeDesignation,
      institutionAA,
      programAA
    } = this.state;

    const programNameError = validateInputString(programName);

    const programCipError = validateSelectField(programCip);
    const programDisciplineError = validateSelectField(programDiscipline);
    const programCodeError = validateSelectField(programCode);

    const programAbbreviationError = validateInputString(programAbbreviation);
    const degreeAbbreviationError = validateInputString(degreeAbbreviation);
    const degreeNameError = validateInputString(degreeName);

    const degreeDesignationError = validateSelectField(degreeDesignation);

    const institutionAAError = validateSelectField(institutionAA);
    const programAAError = validateSelectField(programAA);

    if (programNameError.invalid) {
      validationErrors = true;
    }

    if (programCipError.invalid) {
      validationErrors = true;
    }
    if (programDisciplineError.invalid) {
      validationErrors = true;
    }
    if (programCodeError.invalid) {
      validationErrors = true;
    }

    if (programAbbreviationError.invalid) {
      validationErrors = true;
    }
    if (degreeAbbreviationError.invalid) {
      validationErrors = true;
    }
    if (degreeNameError.invalid) {
      validationErrors = true;
    }

    if (degreeDesignationError.invalid) {
      validationErrors = true;
    }

    if (institutionAAError.invalid) {
      validationErrors = true;
    }
    if (programAAError.invalid) {
      validationErrors = true;
    }

    return validationErrors;
  }

  _clearProgramDisciplineAndCode() {
    this.setState(state => {
      return {
        ...state,
        programDiscipline: '',
        programCode: ''
      };
    });
  }

  _clearProgramCode() {
    this.setState(state => {
      return {
        ...state,
        programCode: ''
      };
    });
  }

  render() {
    const {
      degreeLevels,
      institutionAAs,
      programAAs,
      programCips
    } = this.props;

    const degreeCodesTemp = [{ uuid: 'fake', name: 'fake' }];

    const {
      programName,
      programAbbreviation,
      degreeAbbreviation,
      degreeName,
      programCip,
      programDiscipline,
      programCode,
      degreeDesignation,
      degreeDescription,
      institutionAA,
      programAA,
      hasErrors,
      redirect
    } = this.state;

    return redirect ? (
      <Redirect to="/school-management/programs" />
    ) : (
      <ProgramAdd
        programName={programName}
        programAbbreviation={programAbbreviation}
        degreeAbbreviation={degreeAbbreviation}
        degreeName={degreeName}
        programCip={programCip}
        programDiscipline={programDiscipline}
        programCode={programCode}
        degreeDesignation={degreeDesignation}
        degreeDescription={degreeDescription}
        institutionAA={institutionAA}
        programAA={programAA}
        degreeDesignations={degreeLevels}
        degreeCodes={degreeCodesTemp}
        institutionAAs={institutionAAs}
        programAAs={programAAs}
        programCips={programCips}
        hasErrors={hasErrors}
        handleChange={this.handleChange}
        handleCreateProgram={this.handleCreateProgram}
      />
    );
  }
}

ProgramAddContainer.propTypes = {
  programCips: PropTypes.array,
  degreeLevels: PropTypes.array,
  institutionAAs: PropTypes.array,
  programAAs: PropTypes.array,
  onPostProgram: PropTypes.func,
  schoolUuid: PropTypes.string,
  onGetDegreeLevels: PropTypes.func,
  onGetAccreditationAgencies: PropTypes.func,
  onGetProgramCips: PropTypes.func,
  onGetProgramDisciplines: PropTypes.func,
  onGetProgramCodes: PropTypes.func
};

ProgramAddContainer.defaultProps = {
  programCips: [],
  degreeLevels: [],
  institutionAAs: [],
  programAAs: [],
  onPostProgram: undefined,
  schoolUuid: undefined,
  onGetDegreeLevels: undefined,
  onGetAccreditationAgencies: undefined,
  onGetProgramCips: undefined,
  onGetProgramDisciplines: undefined,
  onGetProgramCodes: undefined
};

const mapStateToProps = state => {
  return {
    schoolUuid: state.userState.selectedSchoolUuid,
    degreeLevels: degreeLevelsSelector(state),
    institutionAAs: institutionAAsSelector(state),
    programAAs: programAAsSelector(state),
    programCips: programCipsSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onPostProgram: (payload, picture) =>
    dispatch(doPostProgram(payload, picture)),
  onGetDegreeLevels: () => dispatch(doGetDegreeLevels()),
  onGetProgramCips: () => dispatch(doGetProgramCips()),
  onGetProgramDisciplines: cipUuid =>
    dispatch(doGetProgramDisciplines(cipUuid)),
  onGetProgramCodes: disciplineUuid =>
    dispatch(doGetProgramCodes(disciplineUuid)),
  onGetAccreditationAgencies: () => dispatch(doGetAccreditationAgencies())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramAddContainer);
