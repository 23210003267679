import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonInline } from '@xcomp/xcomp-design-library';

import {
  Column,
  TextFieldWithError,
  FormSectionHeaderSubtitle,
  FormSectionHeader,
  SubmitButton
} from '../../../../Library/FormComponents';
import FormSectionShowHide from '../../../../Library/FormSectionShowHide';
import { validateStringLength } from '../../../../../helpers/validation/validateGeneric';
import { validateCompetencyDescription } from '../../../../../helpers/validation/validateCompetencyCollection';
import SelectedMicrosDisplay from './SelectedMicrosDisplay';

const CourseCollectionItem = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

const FormSectionShowHideSC = styled(FormSectionShowHide)`
  && {
    position: relative;
  }
`;

const DescriptionTextFieldWithError = styled(TextFieldWithError)`
  && {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`;

const ButtonInlineSC = styled(ButtonInline)`
  && {
    margin-left: 1rem;
  }
`;

const ButtonsColumn = styled(Column)`
  && {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
`;

const CompetencyDetailsForm = ({
  index,
  uuid,
  name,
  description,
  microcompetencies,
  unsavedChanges,
  formDispatch,
  openMicroSearch,
  onFormClose,
  onSubmit,
  setUnsavedChange,
  buttonText,
  showValidationErrors
}) => {
  const handleNameChange = name => {
    setUnsavedChange(uuid);
    formDispatch({
      type: 'setName',
      payload: {
        name
      }
    });
  };

  const handleDescriptionChange = description => {
    setUnsavedChange(uuid);
    formDispatch({
      type: 'setDescription',
      payload: {
        description
      }
    });
  };

  const validateNameField = name => validateStringLength(name, 100, true);
  const validateDescriptionField = description =>
    validateCompetencyDescription(description, 100, 50000, true);

  const formHasChanges = Boolean(unsavedChanges[uuid]);
  return (
    <FormSectionShowHideSC
      headerText={`Competency ${index + 1}`}
      padding="0 30px"
      startOpen={true}
      className="hello"
      onDelete={onFormClose}
    >
      <CourseCollectionItem key={uuid}>
        <Column size="100%">
          <TextFieldWithError
            id={`competencyTitle-${index + 1}`}
            name="name"
            value={name}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            label="Competency Name"
            variant="outlined"
            onChange={e => handleNameChange(e.target.value)}
            handleValidate={validateNameField}
            hasErrors={showValidationErrors}
          />
        </Column>
        <Column size="100%">
          <DescriptionTextFieldWithError
            id={`collectionDescription-${index + 1}`}
            name="description"
            value={description}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            label="Description"
            variant="outlined"
            multiline
            minRows="4"
            maxRows="16"
            placeholder=""
            onChange={e => handleDescriptionChange(e.target.value)}
            handleValidate={validateDescriptionField}
            hasErrors={showValidationErrors}
          />
        </Column>
        <ButtonsColumn size="100%">
          <SubmitButton onClick={onSubmit} disabled={!formHasChanges}>
            {buttonText}
          </SubmitButton>
        </ButtonsColumn>
        {openMicroSearch && (
          <Column size="100%">
            <FormSectionHeader offset="0">
              <FormSectionHeaderSubtitle>
                Microcompetencies
              </FormSectionHeaderSubtitle>
            </FormSectionHeader>
            <SelectedMicrosDisplay microcompetencies={microcompetencies} />
            <ButtonInlineSC onClick={() => openMicroSearch(uuid)} noHoverBg>
              {microcompetencies && microcompetencies.length > 0
                ? 'Manage Microcompetencies'
                : 'Add Microcompetencies'}
            </ButtonInlineSC>
          </Column>
        )}
      </CourseCollectionItem>
    </FormSectionShowHideSC>
  );
};

CompetencyDetailsForm.propTypes = {
  index: PropTypes.number,
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  microcompetencies: PropTypes.arrayOf(PropTypes.object),
  buttonText: PropTypes.string,
  unsavedChanges: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  formDispatch: PropTypes.func,
  onFormClose: PropTypes.func,
  onSubmit: PropTypes.func,
  openMicroSearch: PropTypes.func,
  setUnsavedChange: PropTypes.func
};

CompetencyDetailsForm.defaultProps = {
  index: 0,
  microcompetencies: [],
  buttonText: 'Update',
  unsavedChanges: {},
  showValidationErrors: false,
  formDispatch: undefined,
  onFormClose: undefined,
  onSubmit: undefined,
  openMicroSearch: undefined,
  setUnsavedChange: undefined
};

export default CompetencyDetailsForm;
