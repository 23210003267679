import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import { selectCommunicationTranscriptsBySkill } from '../../../../../redux/selectors/transcriptCommunicationSelectors';
import { selectPhysicalSkillsTranscriptsBySkill } from '../../../../../redux/selectors/transcriptPhysicalSkillsSelectors';
import { selectNonCognitiveTranscriptsBySkill } from '../../../../../redux/selectors/transcriptNonCognitiveSelectors';
import AptitudeRow from '../DataRows/AptitudeRow';
import AptitudeRowHeader from '../DataRows/AptitudeRowHeader';
import AptitudeGradeBySkill from '../../TranscriptCalculations/AptitudeGradeBySkill';
import AptitudeScoreBySkill from '../../TranscriptCalculations/AptitudeScoreBySkill';
import AptitudeWeightBySkill from '../../TranscriptCalculations/AptitudeWeightBySkill';
import RankAptitudeExam from '../../TranscriptCalculations/RankAptitudeExam';

const Accordion = styled(({ ...rest }) => (
  <AccordionRowContainer {...rest} />
))``;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.grey[500]};
  font-size: 14px;
`;

const NoRecords = styled.div`
  padding-left: 200px;
`;

const Calculations = styled.div`
  display: flex;
  font-size: 12px;
`;

const Spacing = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
`;

const Stub = styled.div`
  width: 66px;
`;

export default function ByDefaultAptitudeSkill({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  skill,
  classificationUuid,
  isAdmin,
}) {
  const transcripts = useSelector(state => {
    switch (category) {
      case 'Non-Cognitive': {
        return selectNonCognitiveTranscriptsBySkill(
          state,
          programUuid,
          userUuid,
          cohortUuid,
          category,
          source,
          classificationUuid
        );
      }

      case 'Physical-Skills': {
        return selectPhysicalSkillsTranscriptsBySkill(
          state,
          programUuid,
          userUuid,
          cohortUuid,
          category,
          source,
          classificationUuid
        );
      }

      case 'Communication': {
        return selectCommunicationTranscriptsBySkill(
          state,
          programUuid,
          userUuid,
          cohortUuid,
          category,
          source,
          classificationUuid
        );
      }

      default:
        break;
    }
  }, _.isEqual);

  return (
    <>
      <Accordion
        rowOffset="66px"
        expandWidth="66px"
        height="70px"
        label={
          <Group>
            <Label>{`${skill}`}</Label>
          </Group>
        }
        columnOneClassName="column_one"
        columnOne={
          <Calculations>
            <Spacing>
              <RankAptitudeExam
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                category={category}
                source={source}
                classificationUuid={classificationUuid}
              />
            </Spacing>
            <Spacing>
              <AptitudeWeightBySkill
                programUuid={programUuid}
                category={category}
                source={source}
                classificationUuid={classificationUuid}
              />
            </Spacing>
            <Spacing>
              <AptitudeGradeBySkill
                programUuid={programUuid}
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                category={category}
                source={source}
                classificationUuid={classificationUuid}
              />
            </Spacing>
            <Spacing>
              <AptitudeScoreBySkill
                programUuid={programUuid}
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                category={category}
                source={source}
                classificationUuid={classificationUuid}
              />
            </Spacing>
            <Stub>&nbsp;</Stub>
          </Calculations>
        }
      >
        {transcripts?.length > 0 && <AptitudeRowHeader />}
        {transcripts?.length > 0 ? (
          _.sortBy(transcripts, [
            'date',
            'scope',
            'itemCode'
          ]).map((transcript, index) => (
            <AptitudeRow
              key={transcript.uuid}
              index={index}
              transcript={transcript}
              userUuid={userUuid}
              cohortUuid={cohortUuid}
              programUuid={programUuid}
              category={category}
              source={source}
              isAdmin={isAdmin}
            />
          ))
        ) : (
          <NoRecords>N/A</NoRecords>
        )}
      </Accordion>
    </>
  );
}

ByDefaultAptitudeSkill.propTypes = {
  skill: PropTypes.string,
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  classificationUuid: PropTypes.string,
  isAdmin: PropTypes.bool,
};

ByDefaultAptitudeSkill.defaultProps = {
  skill: undefined,
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  classificationUuid: undefined,
  isAdmin: false,
};
