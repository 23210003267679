import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getDegreeLevels,
  getProgramCips,
  getProgramDisciplines,
  getProgramCodes,
  getAccreditationAgencies,
  getInstitutionIdTypes
} from '../../api/sourceApi';
import {
  doGetDegreeLevelsSuccess,
  doGetDegreeLevelsError,
  doGetAccreditationAgenciesSuccess,
  doGetAccreditationAgenciesError,
  doGetProgramCipsSuccess,
  doGetProgramCipsError,
  doGetProgramDisciplinesSuccess,
  doGetProgramDisciplinesError,
  doGetProgramCodesSuccess,
  doGetProgramCodesError,
  doGetInstitutionIdTypesSuccess,
  doGetInstitutionIdTypesError
} from '../actions/sourceActions';

import {
  GET_PROGRAM_CIPS,
  GET_PROGRAM_DISCIPLINES,
  GET_PROGRAM_CODES,
  GET_ACCREDITATION_AGENCIES,
  GET_DEGREE_LEVELS,
  GET_INSTITUTION_ID_TYPES
} from '../actions-type';

function* handleGetDegreeLevels() {
  try {
    const result = yield call(getDegreeLevels);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetDegreeLevelsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetDegreeLevelsError(error));
  }
}

function* handleProgramCips() {
  try {
    const result = yield call(getProgramCips);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetProgramCipsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetProgramCipsError(error));
  }
}

function* handleProgramDisciplines(action) {
  const { cipUuid } = action.payload;

  try {
    const result = yield call(getProgramDisciplines, cipUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetProgramDisciplinesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetProgramDisciplinesError(error));
  }
}

function* handleProgramCodes() {
  try {
    const result = yield call(getProgramCodes);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetProgramCodesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetProgramCodesError(error));
  }
}

function* handleAccreditationAgencies() {
  try {
    const result = yield call(getAccreditationAgencies);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetAccreditationAgenciesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetAccreditationAgenciesError(error));
  }
}

function* handleInstitutionIdTypes() {
  try {
    const result = yield call(getInstitutionIdTypes);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetInstitutionIdTypesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetInstitutionIdTypesError(error));
  }
}

const source = [
  takeEvery(GET_PROGRAM_CIPS, handleProgramCips),
  takeEvery(GET_PROGRAM_DISCIPLINES, handleProgramDisciplines),
  takeEvery(GET_PROGRAM_CODES, handleProgramCodes),
  takeEvery(GET_ACCREDITATION_AGENCIES, handleAccreditationAgencies),
  takeEvery(GET_DEGREE_LEVELS, handleGetDegreeLevels),
  takeEvery(GET_INSTITUTION_ID_TYPES, handleInstitutionIdTypes)
];

export { source };
