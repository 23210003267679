import fetch from '../helpers/fetch';

const getPartGroupSummary = partUuid =>
  fetch(`/api/part/${partUuid}/group-summary`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getStudentPartGroupSummary = (partUuid, studentUuid) =>
  fetch(`/api/part/${partUuid}/group-summary/${studentUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getPartGroupSummary, getStudentPartGroupSummary };
