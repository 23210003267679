import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Decimal from 'decimal.js-light';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Subtitle1 } from '@xcomp/xcomp-design-library';
import LockOutlined from '@material-ui/icons/LockOutlined';
import ActionButton from '../../../Library/AccordionRow/ActionButton';
import { doClearUpdatedAssessment } from '../../../../redux/actions/assessmentActions';
import StatusCommitModalContainer from '../../../Library/Modal/StatusCommitModal/StatusCommitModalContainer';
import { doPutCohortAssessmentStudentStatusCommit } from '../../../../redux/actions/cohortAssessmentActions';

const ResultCardRow = styled.div`
  padding: 12px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  position: relative;
`;

const BannerIdColumn = styled.div`
  width: 20%;
`;

const NameColumn = styled(({ isPointsOnly, ...rest }) => <div {...rest} />)`
  width: ${props => (props.isPointsOnly ? '70%' : '60%')};
`;

const LetterGradeColumn = styled.div`
  width: 10%;
`;

const CalculatedGradeColumn = styled.div`
  width: 10%;
`;

const ActionsColumn = styled(({ isPublished, ...rest }) => <div {...rest} />)`
  && {
    margin-left: auto;
    width: 100px;
    height: 100%;
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    right: 0;
  }
`;

const RowButtons = styled.div`
  display: flex;
  wrap: nowrap;
  justify-content: flex-end;
  padding-right: 0.5rem;
`;

const StyledPublishedIcon = styled(LockOutlined)`
  && {
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    height: 100%;
    odisplay: flex;
    align-item: center;
    font-size: 1.25rem;
    color: ${props => props.theme.fontColors.dark};
  }
`;

const Subtitle1SC = styled(({ criticalFailure, ...rest }) => (
  <Subtitle1 {...rest} />
))`
  color: ${props =>
    props.criticalFailure
      ? props.theme.colors.status.error
      : props.theme.fontColors.darker};
`;

const getDelayedStudentActions = (
  dispatch,
  assessmentUuid,
  studentUuid,
  cohortUuid,
  cohortAssessmentStatusCommit,
  justificationMessage,
  handleCommitModalOpen
) => {
  const isCommitted = cohortAssessmentStatusCommit === 'COMMITTED';

  const commitDelayedStudentGrade = (assessmentUuid, studentUuid) =>
    dispatch(
      doPutCohortAssessmentStudentStatusCommit({
        cohortUuid,
        assessmentUuid,
        studentUuid,
        cohortAssessmentStatusCommit: 'COMMITTED',
        justificationMessage: null
      })
    );

  const uncommitDelayedStudentGrade = (
    assessmentUuid,
    studentUuid,
    justificationMessage
  ) =>
    dispatch(
      doPutCohortAssessmentStudentStatusCommit({
        cohortUuid,
        assessmentUuid,
        studentUuid,
        cohortAssessmentStatusCommit: 'UNCOMMITTED',
        justificationMessage
      })
    );

  let delayedStudentActions = [];

  if (!isCommitted) {
    delayedStudentActions = [
      {
        label: 'Commit This Student',
        onClick: () => handleCommitModalOpen(),
        shouldOpenOnClick: false,
        icon: 'locked'
      }
    ];
  } else {
    delayedStudentActions = [
      {
        label: 'Uncommit This Student',
        onClick: () => handleCommitModalOpen(),
        shouldOpenOnClick: false,
        icon: 'unlock'
      }
    ];
  }

  return {
    delayedStudentActions,
    commitDelayedStudentGrade,
    uncommitDelayedStudentGrade
  };
};

const getCalculatedGradeString = calculatedGrade => {
  return calculatedGrade === '--'
    ? calculatedGrade
    : new Decimal(calculatedGrade).toFixed(2);
};

const GradeSummaryRow = ({ resultCard, hideStudentNames, isPointsOnly }) => {
  const dispatch = useDispatch();
  const { cohortUuid } = useParams();

  const {
    calculatedGrade,
    cohortAssessmentStatusCommit,
    assessmentRelativeScore,
    assessmentUuid,
    studentUuid
  } = resultCard;

  const thisGrade = isPointsOnly
    ? new Decimal(assessmentRelativeScore).toFixed(2)
    : getCalculatedGradeString(calculatedGrade);
  const criticalFailure = thisGrade === '0.00';

  const [justificationMessage, setJustificationMessage] = useState('');
  const [isCommitModalOpen, setCommitModalOpen] = useState(false);

  const isCommitted = cohortAssessmentStatusCommit === 'COMMITTED';

  const isGraded = Boolean(calculatedGrade && calculatedGrade !== '--');

  const { successfullyCommitted, successfullyUncommitted } = useSelector(
    state => state.assessmentStatusState,
    shallowEqual
  );

  const handleCommitModalOpen = () => {
    setCommitModalOpen(true);
  };

  const handleCommitModalClose = () => {
    setCommitModalOpen(false);
  };

  const handleJustificationChange = event => {
    setJustificationMessage(event.target.value);
  };

  const {
    delayedStudentActions,
    commitDelayedStudentGrade,
    uncommitDelayedStudentGrade
  } = getDelayedStudentActions(
    dispatch,
    assessmentUuid,
    studentUuid,
    cohortUuid,
    cohortAssessmentStatusCommit,
    justificationMessage,
    handleCommitModalOpen
  );
  const handleStatusCommitSubmit = () => {
    handleCommitModalClose();
    if (cohortAssessmentStatusCommit === 'UNCOMMITTED') {
      commitDelayedStudentGrade(assessmentUuid, studentUuid);
    } else {
      uncommitDelayedStudentGrade(
        assessmentUuid,
        studentUuid,
        justificationMessage
      );
    }
  };

  useEffect(() => {
    if (successfullyCommitted || successfullyUncommitted) {
      dispatch(doClearUpdatedAssessment());
      setJustificationMessage('');
    }
  }, [dispatch, successfullyCommitted, successfullyUncommitted]);

  const currentStatusCommitType =
    cohortAssessmentStatusCommit === 'COMMITTED' ? 'Uncommit' : 'Commit';

  const modalTitle = `${currentStatusCommitType} Student Grade`;

  const messageOverrides = {
    title: modalTitle,
    successCommitted: 'Successfully committed student grade',
    successUncommitted: 'Successfully uncommitted student grade',
    confirmCommitment:
      'Committing this student grade is permanent and cannot be undone without program admin priveleges and a justification message. Are you sure you want to proceed?',
    confirmUncommitment:
      'Uncommitting this student grade will allow changes to a previously calculated grade that was sent to the student transcript. This must be justified by a program admin. Are you sure you want to proceed?'
  };

  return (
    <ResultCardRow
      key={isPointsOnly ? resultCard.studentUuid : resultCard.uuid}
    >
      <StatusCommitModalContainer
        statusCommit={cohortAssessmentStatusCommit}
        messages={messageOverrides}
        isModalOpen={isCommitModalOpen}
        handleModalClose={handleCommitModalClose}
        handleModalSuccess={undefined}
        handleSubmit={handleStatusCommitSubmit}
        justificationMessage={justificationMessage}
        handleJustificationChange={handleJustificationChange}
      />
      <BannerIdColumn>
        <Subtitle1SC criticalFailure={criticalFailure}>
          {resultCard.institutionId}
        </Subtitle1SC>
      </BannerIdColumn>
      <NameColumn isPointsOnly={isPointsOnly}>
        <Subtitle1SC criticalFailure={criticalFailure}>
          {!hideStudentNames
            ? `${resultCard.lastName}, ${resultCard.firstName}`
            : `---`}
        </Subtitle1SC>
      </NameColumn>
      {isPointsOnly ? (
        <CalculatedGradeColumn>
          <Subtitle1SC criticalFailure={criticalFailure}>
            {thisGrade}
          </Subtitle1SC>
        </CalculatedGradeColumn>
      ) : (
        <>
          <LetterGradeColumn>
            <Subtitle1SC criticalFailure={criticalFailure}>
              {resultCard.letterGrade}
            </Subtitle1SC>
          </LetterGradeColumn>
          <CalculatedGradeColumn>
            <Subtitle1SC criticalFailure={criticalFailure}>
              {thisGrade}
              {thisGrade !== '--' && '%'}
            </Subtitle1SC>
          </CalculatedGradeColumn>
        </>
      )}

      {isGraded && (
        <ActionsColumn>
          <RowButtons>
            <ActionButton actions={delayedStudentActions} isRowOpen={false} />
          </RowButtons>
        </ActionsColumn>
      )}

      {isCommitted && <StyledPublishedIcon />}
    </ResultCardRow>
  );
};

GradeSummaryRow.propTypes = {
  hideStudentNames: PropTypes.bool,
  isPointsOnly: PropTypes.bool,
  resultCard: PropTypes.object
};

GradeSummaryRow.defaultProps = {
  hideStudentNames: false,
  isPointsOnly: false,
  resultCard: {}
};

export default GradeSummaryRow;
