import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Grid } from '@material-ui/core';

import { ButtonPrimary } from '@xcomp/xcomp-design-library';

import {
  ContainerResize,
  ContainerPage,
  ContainerActions,
  ElevateLow
} from '../../../../../Library/Layout';

import Header from '../../../../../Library/Header';
import EditorScope from './EditorScope';

const TitleArea = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding: 20px;
  margin-bottom: 30px;
`;

const Title = styled.div`
  font-size: 18px;
`;

const PageArea = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;

const ScopeAdd = styled.div`
  padding-top: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 100%;
`;

const Weight = styled.div`
  color: ${props => (props.totalWeight === '100.00' ? 'green' : 'red')};
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
`;

export default function EditorAptitude({
  category,
  classifications,
  totalWeight,
  hasErrors,
  handleAddScope,
  handleUpdateSource,
  handleUpdateScope,
  handleRemoveClassification,
  handleAddExam,
  handleUpdateExam,
  handleRemoveExam,
  handleAddConversion,
  handleUpdateConversion,
  handleRemoveConversion,
  handleSave
}) {
  return (
    <ContainerResize>
      <Header title={`${category}`} subtitle="Edit Application Component" />
      <ContainerActions>
        <ButtonPrimary onClick={handleSave}>Save</ButtonPrimary>
      </ContainerActions>
      <ContainerPage paddingTop="50px">
        <ElevateLow>
          <TitleArea>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={10}>
                <Title>Program/Milestone Aptitude Test</Title>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Weight totalWeight={totalWeight}>{totalWeight}%</Weight>
              </Grid>
            </Grid>
          </TitleArea>

          <PageArea>
            <Grid container spacing={4}>
              {classifications
                .sort((a, b) => a.scope - b.scope)
                .map((classification, index) => (
                  <EditorScope
                    index={index}
                    key={classification?.uuid}
                    classification={classification}
                    hasErrors={hasErrors}
                    handleUpdateSource={handleUpdateSource}
                    handleUpdateScope={handleUpdateScope}
                    handleRemoveClassification={handleRemoveClassification}
                    handleAddExam={handleAddExam}
                    handleUpdateExam={handleUpdateExam}
                    handleRemoveExam={handleRemoveExam}
                    handleAddConversion={handleAddConversion}
                    handleUpdateConversion={handleUpdateConversion}
                    handleRemoveConversion={handleRemoveConversion}
                  />
                ))}
              <ScopeAdd>
                <ButtonPrimary onClick={handleAddScope}>
                  Add Scope
                </ButtonPrimary>
              </ScopeAdd>
            </Grid>
          </PageArea>
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
}

EditorAptitude.propTypes = {
  category: PropTypes.string,
  classifications: PropTypes.array,
  totalWeight: PropTypes.string,
  hasErrors: PropTypes.bool,
  handleAddScope: PropTypes.func,
  handleUpdateSource: PropTypes.func,
  handleUpdateScope: PropTypes.func,
  handleRemoveClassification: PropTypes.func,
  handleAddExam: PropTypes.func,
  handleUpdateExam: PropTypes.func,
  handleRemoveExam: PropTypes.func,
  handleSave: PropTypes.func,
  handleAddConversion: PropTypes.func,
  handleUpdateConversion: PropTypes.func,
  handleRemoveConversion: PropTypes.func
};

EditorAptitude.defaultProps = {
  category: '',
  classifications: [],
  totalWeight: '0.00',
  hasErrors: false,
  handleAddScope: undefined,
  handleUpdateSource: undefined,
  handleUpdateScope: undefined,
  handleRemoveClassification: undefined,
  handleAddExam: undefined,
  handleUpdateExam: undefined,
  handleRemoveExam: undefined,
  handleSave: undefined,
  handleAddConversion: undefined,
  handleUpdateConversion: undefined,
  handleRemoveConversion: undefined
};
