export const GET_DEGREE_LEVEL_INDICES = 'GET_DEGREE_LEVEL_INDICES';
export const GET_DEGREE_LEVEL_INDICES_SUCCESS =
  'GET_DEGREE_LEVEL_INDICES_SUCCESS';
export const GET_DEGREE_LEVEL_INDICES_ERROR = 'GET_DEGREE_LEVEL_INDICES_ERROR';

export const PUT_DEGREE_LEVEL_INDEX = 'PUT_DEGREE_LEVEL_INDEX';
export const PUT_DEGREE_LEVEL_INDEX_SUCCESS = 'PUT_DEGREE_LEVEL_INDEX_SUCCESS';
export const PUT_DEGREE_LEVEL_INDEX_ERROR = 'PUT_DEGREE_LEVEL_INDEX_ERROR';

export const POST_DEGREE_LEVEL_INDEX = 'POST_DEGREE_LEVEL_INDEX';
export const POST_DEGREE_LEVEL_INDEX_SUCCESS =
  'POST_DEGREE_LEVEL_INDEX_SUCCESS';
export const POST_DEGREE_LEVEL_INDEX_ERROR = 'POST_DEGREE_LEVEL_INDEX_ERROR';

export const DELETE_DEGREE_LEVEL_INDEX = 'DELETE_DEGREE_LEVEL_INDEX';
export const DELETE_DEGREE_LEVEL_INDEX_SUCCESS =
  'DELETE_DEGREE_LEVEL_INDEX_SUCCESS';
export const DELETE_DEGREE_LEVEL_INDEX_ERROR =
  'DELETE_DEGREE_LEVEL_INDEX_ERROR';
