import {
  GET_SECTION_NESTED_BY_COURSE,
  GET_SECTION_NESTED_BY_COURSE_SUCCESS,
  GET_SECTION_NESTED_BY_COURSE_ERROR,
  GET_SECTION,
  GET_SECTION_SUCCESS,
  GET_SECTION_ERROR,
  PUT_SECTION_DATASOURCE,
  PUT_SECTION_DATASOURCE_SUCCESS,
  PUT_SECTION_DATASOURCE_ERROR,
  PUT_SECTION_PART_WEIGHTS,
  PUT_SECTION_PART_WEIGHTS_SUCCESS,
  PUT_SECTION_PART_WEIGHTS_ERROR,
  PATCH_SECTION_CALCULATE_GRADE,
  PATCH_SECTION_CALCULATE_GRADE_SUCCESS,
  PATCH_SECTION_CALCULATE_GRADE_ERROR,
  DELETE_SECTION_UNDO_CALCULATED_GRADE,
  DELETE_SECTION_UNDO_CALCULATED_GRADE_SUCCESS,
  DELETE_SECTION_UNDO_CALCULATED_GRADE_ERROR,
  PUT_SECTION_STATUS_COMMIT,
  PUT_SECTION_STATUS_COMMIT_SUCCESS,
  PUT_SECTION_STATUS_COMMIT_ERROR,
  GET_SECTION_COMMITMENT_LOG,
  GET_SECTION_COMMITMENT_LOG_SUCCESS,
  GET_SECTION_COMMITMENT_LOG_ERROR,
  PATCH_SECTION_GRADE_DELAYED_STUDENT,
  PATCH_SECTION_GRADE_DELAYED_STUDENT_SUCCESS,
  PATCH_SECTION_GRADE_DELAYED_STUDENT_ERROR,
  DELETE_SECTION_GRADE_DELAYED_STUDENT,
  DELETE_SECTION_GRADE_DELAYED_STUDENT_SUCCESS,
  DELETE_SECTION_GRADE_DELAYED_STUDENT_ERROR,
  PUT_SECTION_DELAYED_STATUS_COMMIT,
  PUT_SECTION_DELAYED_STATUS_COMMIT_SUCCESS,
  PUT_SECTION_DELAYED_STATUS_COMMIT_ERROR
} from '../actions-type';

const doGetSectionNestedByCourse = courseUuid => ({
  type: GET_SECTION_NESTED_BY_COURSE,
  payload: courseUuid
});

const doGetSectionNestedByCourseSuccess = sections => ({
  type: GET_SECTION_NESTED_BY_COURSE_SUCCESS,
  payload: sections
});

const doGetSectionNestedByCourseError = error => ({
  type: GET_SECTION_NESTED_BY_COURSE_ERROR,
  payload: error
});

const doGetSection = sectionUuid => ({
  type: GET_SECTION,
  payload: { sectionUuid }
});

const doGetSectionSuccess = result => ({
  type: GET_SECTION_SUCCESS,
  payload: result
});

const doGetSectionError = error => ({
  type: GET_SECTION_ERROR,
  payload: error
});

const doGetSectionCommitmentLog = sectionUuid => ({
  type: GET_SECTION_COMMITMENT_LOG,
  payload: { sectionUuid }
});

const doGetSectionCommitmentLogSuccess = result => ({
  type: GET_SECTION_COMMITMENT_LOG_SUCCESS,
  payload: result
});

const doGetSectionCommitmentLogError = error => ({
  type: GET_SECTION_COMMITMENT_LOG_ERROR,
  payload: error
});

const doPutSectionGradeDataSource = ({ sectionUuid, dataSource }) => ({
  type: PUT_SECTION_DATASOURCE,
  payload: {
    sectionUuid,
    dataSource
  }
});

const doPutSectionGradeDataSourceSuccess = result => ({
  type: PUT_SECTION_DATASOURCE_SUCCESS,
  payload: result
});

const doPutSectionGradeDataSourceError = error => ({
  type: PUT_SECTION_DATASOURCE_ERROR,
  payload: error
});

const doPutSectionPartWeights = (
  sectionUuid,
  partWeights,
  distributeWeightEqually
) => ({
  type: PUT_SECTION_PART_WEIGHTS,
  payload: {
    sectionUuid,
    partWeights,
    distributeWeightEqually
  }
});

const doPutSectionPartWeightsSuccess = result => ({
  type: PUT_SECTION_PART_WEIGHTS_SUCCESS,
  payload: result
});

const doPutSectionPartWeightsError = error => ({
  type: PUT_SECTION_PART_WEIGHTS_ERROR,
  payload: error
});

const doPatchSectionCalculateGrade = sectionUuid => ({
  type: PATCH_SECTION_CALCULATE_GRADE,
  payload: {
    sectionUuid
  }
});

const doPatchSectionCalculateGradeSuccess = result => ({
  type: PATCH_SECTION_CALCULATE_GRADE_SUCCESS,
  payload: result
});

const doPatchSectionCalculateGradeError = (error, sectionUuid) => ({
  type: PATCH_SECTION_CALCULATE_GRADE_ERROR,
  payload: { error: { message: error }, sectionUuid }
});

const doPatchSectionUndoCalculatedGrade = sectionUuid => ({
  type: DELETE_SECTION_UNDO_CALCULATED_GRADE,
  payload: {
    sectionUuid
  }
});

const doPatchSectionUndoCalculatedGradeSuccess = result => ({
  type: DELETE_SECTION_UNDO_CALCULATED_GRADE_SUCCESS,
  payload: result
});

const doPatchSectionUndoCalculatedGradeError = error => ({
  type: DELETE_SECTION_UNDO_CALCULATED_GRADE_ERROR,
  payload: { error: { message: error } }
});

const doPutSectionStatusCommit = section => ({
  type: PUT_SECTION_STATUS_COMMIT,
  payload: {
    section
  }
});

const doPutSectionStatusCommitSuccess = result => ({
  type: PUT_SECTION_STATUS_COMMIT_SUCCESS,
  payload: result
});

const doPutSectionStatusCommitError = error => ({
  type: PUT_SECTION_STATUS_COMMIT_ERROR,
  payload: { error: { message: error } }
});

const doPatchSectionDelayedStudentGrade = ({ sectionUuid, studentUuid }) => ({
  type: PATCH_SECTION_GRADE_DELAYED_STUDENT,
  payload: {
    sectionUuid,
    studentUuid
  }
});

const doPatchSectionDelayedStudentGradeSuccess = result => ({
  type: PATCH_SECTION_GRADE_DELAYED_STUDENT_SUCCESS,
  payload: result
});

const doPatchSectionDelayedStudentGradeError = error => ({
  type: PATCH_SECTION_GRADE_DELAYED_STUDENT_ERROR,
  payload: error
});

const doDeleteSectionDelayedStudentGrade = ({ sectionUuid, studentUuid }) => ({
  type: DELETE_SECTION_GRADE_DELAYED_STUDENT,
  payload: {
    sectionUuid,
    studentUuid
  }
});

const doDeleteSectionDelayedStudentGradeSuccess = result => ({
  type: DELETE_SECTION_GRADE_DELAYED_STUDENT_SUCCESS,
  payload: result
});

const doDeleteSectionDelayedStudentGradeError = error => ({
  type: DELETE_SECTION_GRADE_DELAYED_STUDENT_ERROR,
  payload: error
});

const doPutSectionDelayedStudentStatusCommit = ({
  sectionUuid,
  studentUuid,
  delayedStatusCommit,
  justificationMessage
}) => ({
  type: PUT_SECTION_DELAYED_STATUS_COMMIT,
  payload: {
    sectionUuid,
    studentUuid,
    delayedStatusCommit,
    justificationMessage
  }
});

const doPutSectionDelayedStudentStatusCommitSuccess = result => ({
  type: PUT_SECTION_DELAYED_STATUS_COMMIT_SUCCESS,
  payload: result
});

const doPutSectionDelayedStudentStatusCommitError = error => ({
  type: PUT_SECTION_DELAYED_STATUS_COMMIT_ERROR,
  payload: error
});

export {
  doGetSectionNestedByCourse,
  doGetSectionNestedByCourseSuccess,
  doGetSectionNestedByCourseError,
  doGetSection,
  doGetSectionSuccess,
  doGetSectionError,
  doPutSectionGradeDataSource,
  doPutSectionGradeDataSourceSuccess,
  doPutSectionGradeDataSourceError,
  doPutSectionPartWeights,
  doPutSectionPartWeightsSuccess,
  doPutSectionPartWeightsError,
  doPatchSectionCalculateGrade,
  doPatchSectionCalculateGradeSuccess,
  doPatchSectionCalculateGradeError,
  doPatchSectionUndoCalculatedGrade,
  doPatchSectionUndoCalculatedGradeSuccess,
  doPatchSectionUndoCalculatedGradeError,
  doPutSectionStatusCommit,
  doPutSectionStatusCommitSuccess,
  doPutSectionStatusCommitError,
  doGetSectionCommitmentLog,
  doGetSectionCommitmentLogSuccess,
  doGetSectionCommitmentLogError,
  doPatchSectionDelayedStudentGrade,
  doPatchSectionDelayedStudentGradeSuccess,
  doPatchSectionDelayedStudentGradeError,
  doDeleteSectionDelayedStudentGrade,
  doDeleteSectionDelayedStudentGradeSuccess,
  doDeleteSectionDelayedStudentGradeError,
  doPutSectionDelayedStudentStatusCommit,
  doPutSectionDelayedStudentStatusCommitSuccess,
  doPutSectionDelayedStudentStatusCommitError
};
