import {
  POST_ENCOUNTER,
  POST_ENCOUNTER_SUCCESS,
  POST_ENCOUNTER_ERROR,
  POST_CALCULATE_ENCOUNTER_OBJECTIVES,
  POST_CALCULATE_ENCOUNTER_OBJECTIVES_SUCCESS,
  POST_CALCULATE_ENCOUNTER_OBJECTIVES_ERROR,
  PUT_ENCOUNTER_DRAFT,
  PUT_ENCOUNTER_DRAFT_SUCCESS,
  PUT_ENCOUNTER_DRAFT_ERROR,
  PUT_ENCOUNTER_FINAL,
  PUT_ENCOUNTER_FINAL_SUCCESS,
  PUT_ENCOUNTER_FINAL_ERROR,
  DELETE_ENCOUNTER,
  DELETE_ENCOUNTER_SUCCESS,
  DELETE_ENCOUNTER_ERROR,
  PATCH_ENCOUNTER_APPROVE,
  PATCH_ENCOUNTER_APPROVE_SUCCESS,
  PATCH_ENCOUNTER_APPROVE_ERROR,
  POST_CALCULATE_ENCOUNTER_OBJECTIVES_INVALID_SCORES
} from '../actions-type';

const doPostEncounter = encounter => ({
  type: POST_ENCOUNTER,
  payload: {
    encounter
  }
});

const doPostEncounterSuccess = result => ({
  type: POST_ENCOUNTER_SUCCESS,
  payload: result
});

const doPostEncounterError = error => ({
  type: POST_ENCOUNTER_ERROR,
  payload: error
});

const doPostCalculateEncounterObjectives = encounterUuid => ({
  type: POST_CALCULATE_ENCOUNTER_OBJECTIVES,
  payload: {
    encounterUuid
  }
});

const doPostCalculateEncounterObjectivesSuccess = result => ({
  type: POST_CALCULATE_ENCOUNTER_OBJECTIVES_SUCCESS,
  payload: result
});

const doPostCalculateEncounterObjectivesError = error => ({
  type: POST_CALCULATE_ENCOUNTER_OBJECTIVES_ERROR,
  payload: error
});

const doPostCalculateEncounterObjectivesInvalidScores = errors => ({
  type: POST_CALCULATE_ENCOUNTER_OBJECTIVES_INVALID_SCORES,
  payload: errors
});


const doPutEncounterDraft = encounter => ({
  type: PUT_ENCOUNTER_DRAFT,
  payload: {
    encounter
  }
});

const doPutEncounterDraftSuccess = result => ({
  type: PUT_ENCOUNTER_DRAFT_SUCCESS,
  payload: result
});

const doPutEncounterDraftError = error => ({
  type: PUT_ENCOUNTER_DRAFT_ERROR,
  payload: error
});

const doPutEncounterFinal = encounter => ({
  type: PUT_ENCOUNTER_FINAL,
  payload: {
    encounter
  }
});

const doPutEncounterFinalSuccess = result => ({
  type: PUT_ENCOUNTER_FINAL_SUCCESS,
  payload: result
});

const doPutEncounterFinalError = error => ({
  type: PUT_ENCOUNTER_FINAL_ERROR,
  payload: error
});

const doPatchEncounterApprove = encounterUuid => ({
  type: PATCH_ENCOUNTER_APPROVE,
  payload: encounterUuid
});

const doPatchEncounterApproveSuccess = encounter => ({
  type: PATCH_ENCOUNTER_APPROVE_SUCCESS,
  payload: {
    encounter
  }
});

const doPatchEncounterApproveError = error => ({
  type: PATCH_ENCOUNTER_APPROVE_ERROR,
  payload: error
});

const doDeleteEncounter = encounterUuid => ({
  type: DELETE_ENCOUNTER,
  payload: {
    encounterUuid
  }
});

const doDeleteEncounterSuccess = encounterUuid => ({
  type: DELETE_ENCOUNTER_SUCCESS,
  payload: encounterUuid
});

const doDeleteEncounterError = error => ({
  type: DELETE_ENCOUNTER_ERROR,
  payload: error
});

export {
  doPostEncounter,
  doPostEncounterSuccess,
  doPostEncounterError,
  doPostCalculateEncounterObjectives,
  doPostCalculateEncounterObjectivesSuccess,
  doPostCalculateEncounterObjectivesError,
  doPostCalculateEncounterObjectivesInvalidScores,
  doPutEncounterDraft,
  doPutEncounterDraftSuccess,
  doPutEncounterDraftError,
  doPutEncounterFinal,
  doPutEncounterFinalSuccess,
  doPutEncounterFinalError,
  doPatchEncounterApprove,
  doPatchEncounterApproveSuccess,
  doPatchEncounterApproveError,
  doDeleteEncounter,
  doDeleteEncounterSuccess,
  doDeleteEncounterError
};
