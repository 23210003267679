import React, { useReducer, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import {
  doPutCompetencyCollection,
  doGetCompetencyCollection
} from '../../../../redux/actions/competencyCollectionActions';
import {
  allFieldsAreValid,
  validateStringLength
} from '../../../../helpers/validation/validateGeneric';
import EditCompetencyCollection from './EditCompetencyCollectionDashboard';
import {
  initialCompetencyCollectionState,
  competencyCollectionReducer,
  setHaveFetchedCompetencyCollections,
  setShowValidationErrors,
  setName,
  setDefaultCollection
} from '../competencyCollectionState';
import { competencyCollectionSelector } from '../../../../redux/selectors/competencyCollectionsSelectors';
import { useUnsavedChanges } from '../../../Library/Modal/UnsavedChangesModal/useUnsavedChanges';

const EditCompetencyCollectionContainer = () => {
  // eslint-disable-next-line prefer-const
  let history = useHistory();
  const dispatch = useDispatch();
  const { cohortUuid, competencyCollectionUuid } = useParams();

  const existingCompetencyCollection = useSelector(state =>
    competencyCollectionSelector(state, competencyCollectionUuid)
  );

  const [state, editDispatch] = useReducer(
    competencyCollectionReducer,
    initialCompetencyCollectionState
  );
  const setExistingState = existingCollection => {
    editDispatch({
      type: 'setExistingCompetencyCollection',
      payload: { ...existingCollection }
    });
  };

  const {
    uuid,
    name,
    is_default_collection,
    haveFetchedCompetencyCollection,
    showValidationErrors,
    isMicroSearchOpen,
    microSearchForCompetencyUuid
  } = state;

  const clearForm = useCallback(() => {
    editDispatch({
      type: 'clearForm'
    });
    history.push(`/program-management/competency`);
  }, [history]);

  const [
    isUnsavedChangesModalOpen,
    unsavedChanges,
    handleModalOpen,
    handleModalClose,
    setUnsavedChange,
    removeUnsavedChange,
    handleProceed
  ] = useUnsavedChanges(clearForm);

  const onPutUpdate = competencyCollection =>
    dispatch(doPutCompetencyCollection(competencyCollection));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!haveFetchedCompetencyCollection && competencyCollectionUuid) {
      dispatch(doGetCompetencyCollection(competencyCollectionUuid));
      editDispatch(setHaveFetchedCompetencyCollections());
    }
  }, [dispatch, haveFetchedCompetencyCollection, competencyCollectionUuid]);

  useEffect(() => {
    if (
      haveFetchedCompetencyCollection &&
      competencyCollectionUuid &&
      existingCompetencyCollection
    ) {
      setExistingState(existingCompetencyCollection);
    }
  }, [
    haveFetchedCompetencyCollection,
    competencyCollectionUuid,
    existingCompetencyCollection,
    uuid
  ]);

  const setValidationErrors = competencyCollection => {
    const nameError = validateStringLength(
      competencyCollection.name,
      100,
      true
    );

    const newValidationErrors = {
      nameError
    };

    return newValidationErrors;
  };

  const handleUpdateSubmission = () => {
    const newValidationErrors = setValidationErrors({
      name
    });

    if (allFieldsAreValid(newValidationErrors)) {
      onPutUpdate({
        uuid,
        name,
        is_default_collection
      });
      removeUnsavedChange(uuid);
      editDispatch(setShowValidationErrors(false));
    } else {
      editDispatch(setShowValidationErrors(true));
    }
  };

  const competencyCollection = {
    uuid,
    name,
    is_default_collection,
    cohortUuid,
    competencies: existingCompetencyCollection
      ? existingCompetencyCollection.competencies
      : []
  };

  const handleNameChange = e => {
    setUnsavedChange(uuid);
    editDispatch(setName(e.target.value));
  };

  const handleDefaultCollectionChange = value => {
    setUnsavedChange(uuid);
    editDispatch(setDefaultCollection(value));
  };

  return (
    <EditCompetencyCollection
      isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
      unsavedChanges={unsavedChanges}
      competencyCollection={competencyCollection}
      formDispatch={editDispatch}
      handleNameChange={handleNameChange}
      handleDefaultCollectionChange={handleDefaultCollectionChange}
      onSubmit={handleUpdateSubmission}
      clearForm={handleModalOpen}
      handleModalClose={handleModalClose}
      handleProceed={handleProceed}
      setUnsavedChange={setUnsavedChange}
      removeUnsavedChange={removeUnsavedChange}
      showValidationErrors={showValidationErrors}
      isMicroSearchOpen={isMicroSearchOpen}
      microSearchForCompetencyUuid={microSearchForCompetencyUuid}
    />
  );
};

export default EditCompetencyCollectionContainer;
