import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS,
  GET_PROGRAM_ASSESSMENTS_SUCCESS,
  GET_PROGRAM_ASSESSMENT_SUCCESS,
  POST_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS,
  PUT_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS
} from '../actions-type';

class ProgramAssessmentCollection extends Model {
  static reducer(action, ProgramAssessmentCollection, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_PROGRAM_ASSESSMENTS_SUCCESS: {
        const { program_assessment_collections } = payload;

        if (
          program_assessment_collections &&
          program_assessment_collections.length > 0
        ) {
          program_assessment_collections.map(collection => {
            return ProgramAssessmentCollection.upsert(collection);
          });
        }
        break;
      }
      case GET_PROGRAM_ASSESSMENT_SUCCESS: {
        const { program_assessment_collection } = payload.program_assessment;

        ProgramAssessmentCollection.upsert(program_assessment_collection);

        break;
      }
      case POST_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS:
      case PUT_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS: {
        const { program_assessment_collection } = payload;

        ProgramAssessmentCollection.upsert(program_assessment_collection);
        break;
      }
      case DELETE_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS: {
        const { deletedProgramAssessmentCollection } = payload;
        ProgramAssessmentCollection.withId(
          deletedProgramAssessmentCollection.uuid
        ).delete();
        break;
      }

      default:
        return session;
    }
  }
}

ProgramAssessmentCollection.fields = {
  uuid: attr(),
  title: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  programUuid: fk('Program', 'program_assessment_collections')
};

ProgramAssessmentCollection.modelName = 'ProgramAssessmentCollection';

ProgramAssessmentCollection.options = {
  idAttribute: 'uuid'
};

export default ProgramAssessmentCollection;
