import { SET_SHOW_QUANTITATIVE_GRID_MODAL } from '../actions-type/quantitativeGridActionTypes';

export const INITIAL_STATE = {
  isLoading: false,
  showTableModal: false,
  zoom: 1
};

const cohortQuantitativeSkillsReportReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case SET_SHOW_QUANTITATIVE_GRID_MODAL: {
      return {
        ...state,
        showTableModal: action.payload.showTableModal
      };
    }
    default:
      return state;
  }
};

export default cohortQuantitativeSkillsReportReducer;
