import React from 'react';
import PropTypes from 'prop-types';
import { ContainerPage } from '../../../Library/Layout';
import GroupPanel from '../../StudentPartRoster/StudentPartGroups/GroupPanel';
import PartGroupReportSummary from './PartGroupReportSummary/PartGroupReportSummary';

const PartGroupReport = ({
  assessments,
  assessmentSummaryData,
  groupAvgPerStudent,
  groupHighPerStudent,
  groupLowPerStudent,
  groups,
  partGroupSummaryState,
  partUuid,
  userGroups
}) => {
  return (
    <ContainerPage paddingTop="0px">
      <PartGroupReportSummary
        assessments={assessments}
        assessmentSummaryData={assessmentSummaryData}
        groupAvgPerStudent={groupAvgPerStudent}
        groupHighPerStudent={groupHighPerStudent}
        groupLowPerStudent={groupLowPerStudent}
      />
      {groups.map(group => (
        <GroupPanel
          assessments={assessments}
          partGroupSummaryState={partGroupSummaryState}
          key={group.uuid}
          group={group}
          groupMembers={userGroups.filter(
            userGroup => userGroup.groupUuid === group.uuid
          )}
          partUuid={partUuid}
          showGroupRankings={true}
        />
      ))}
    </ContainerPage>
  );
};
PartGroupReport.propTypes = {
  assessments: PropTypes.array,
  assessmentSummaryData: PropTypes.object,
  groupAvgPerStudent: PropTypes.string,
  groupHighPerStudent: PropTypes.string,
  groupLowPerStudent: PropTypes.string,
  groups: PropTypes.array,
  partGroupSummaryState: PropTypes.object,
  partUuid: PropTypes.string,
  userGroups: PropTypes.array
};

PartGroupReport.defaultProps = {
  assessments: [],
  assessmentSummaryData: {},
  groupAvgPerStudent: '',
  groupHighPerStudent: '',
  groupLowPerStudent: '',
  groups: [],
  partGroupSummaryState: {},
  partUuid: '',
  userGroups: []
};

export default PartGroupReport;
