import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const resultCardSelector = createSelector(
  state => state,
  (_, resultCardUuid) => resultCardUuid,
  (redux, resultCardUuid) => {
    const resultCardsSelectorORM = ormCreateSelector(orm.ResultCard);

    const resultCard = resultCardsSelectorORM(redux, resultCardUuid) || {};

    return resultCard;
  }
);

export const resultCardsByAssessmentUuidSelector = createSelector(
  state => state,
  (_, assessmentUuid) => assessmentUuid,
  (redux, assessmentUuid) => {
    const resultCardsSelectorORM = ormCreateSelector(orm.ResultCard);

    const resultCards = resultCardsSelectorORM(redux).filter(
      resultCard => resultCard.assessmentUuid === assessmentUuid
    );

    return resultCards;
  }
);
