import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { CloseIcon } from '@xcomp/xcomp-design-library/dist/icons';
import IconButton from '@material-ui/core/IconButton';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import SimpleModal from '../../../Library/Modal/SimpleModal';
import { assessmentBlocksSelector } from '../../../../redux/selectors/assessmentBlockSelectors';
import ScoresBlockResults from './ScoresBlockResults';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import { selectAssessment } from '../../../../redux/selectors/assessmentSelectors';

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 900px;
`;

const ModalHeader = styled.div``;

const ModalBody = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-top: 30px;
`;

const TitleArea = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function ScoresView({
  assessmentUuid,
  openScores,
  studentUuid,
  handleScoresClose
}) {
  const assessment = useSelector(
    state => selectAssessment(state, assessmentUuid),
    _.isEqual
  );

  const assessmentBlocks = useSelector(
    state => assessmentBlocksSelector(state, assessmentUuid),
    _.isEqual
  );

  return (
    <SimpleModal open={openScores} onClose={handleScoresClose} maxWidth="xl">
      <ModalContainer>
        <ModalHeader>
          <Grid item sm={12} xs={12}>
            <TitleArea>
              <Grid container direction="row" alignItems="center">
                <Grid item sm={11} xs={11}>
                  <Title>Scores</Title>
                </Grid>
                <Grid item sm={1} xs={1}>
                  <CloseModal>
                    <IconButton onClick={handleScoresClose}>
                      <CloseIcon />
                    </IconButton>
                  </CloseModal>
                </Grid>
              </Grid>
            </TitleArea>
          </Grid>
        </ModalHeader>
        <ModalBody>
          {assessmentBlocks
            ?.sort((a, b) => a.blockNumber - b.blockNumber)
            .map(assessmentBlock => (
              <AccordionRowContainer
                key={assessmentBlock?.uuid}
                label={`Block ${assessmentBlock?.blockNumber} - ${assessmentBlock?.title}`}
              >
                <ScoresBlockResults
                  assessmentBlockUuid={assessmentBlock?.uuid}
                  studentUuid={studentUuid}
                  scoreType={assessment?.scoreType}
                />
              </AccordionRowContainer>
            ))}
        </ModalBody>
      </ModalContainer>
    </SimpleModal>
  );
}

ScoresView.propTypes = {
  assessmentUuid: PropTypes.string,
  studentUuid: PropTypes.string,
  openScores: PropTypes.bool,
  handleScoresClose: PropTypes.func
};

ScoresView.defaultProps = {
  assessmentUuid: undefined,
  studentUuid: undefined,
  openScores: false,
  handleScoresClose: undefined
};
