import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DialogTitle, Dialog } from '@material-ui/core';

const StyledDialog = styled(Dialog)`
  font-size: 1rem;
  color: ${props => props.theme.fontColors.dark};
  line-height: 1.75rem;
`;

const ModalBody = styled.div``;

class SimpleModal extends Component {
  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  render() {
    const {
      className,
      onClose,
      children,
      headerText,
      maxWidth,
      selectedValue,
      ...other
    } = this.props;

    return (
      <StyledDialog
        onClose={this.handleClose}
        aria-labelledby="button-modal"
        className={className}
        maxWidth={maxWidth}
        {...other}
      >
        {headerText && (
          <DialogTitle id="button-modal">{headerText}</DialogTitle>
        )}
        <ModalBody>{children}</ModalBody>
      </StyledDialog>
    );
  }
}

SimpleModal.propTypes = {
  className: PropTypes.string,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  onClose: PropTypes.func,
  children: PropTypes.element,
  headerText: PropTypes.string,
  selectedValue: PropTypes.string
};

SimpleModal.defaultProps = {
  className: '',
  maxWidth: 'sm',
  onClose: undefined,
  children: <div />,
  headerText: '',
  selectedValue: ''
};

export default SimpleModal;

export { StyledDialog };
