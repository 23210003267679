import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { WarningIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { isArray } from 'lodash';
import { Box } from '@material-ui/core';

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.status.error};
  color: ${props => props.theme.colors.white};
  padding: 20px;
  border-radius: 4px;
  font-size: 0.9375rem;
  font-weight: 400;
`;

const WarningMessage = styled.div`
  margin-left: 10px;
`;

const FormErrors = ({ hasErrors, message }) => {
  if (hasErrors && isArray(message)) {
    return (
      <Box display="flex" gridGap="10px" flexDirection="column">
        {message.map(msg => (
          <ErrorMessage key={msg}>
            <WarningIcon />
            <WarningMessage>{msg}</WarningMessage>
          </ErrorMessage>
        ))}
      </Box>
    );
  }

  return hasErrors ? (
    <ErrorMessage>
      <WarningIcon />
      <WarningMessage>{message}</WarningMessage>
    </ErrorMessage>
  ) : null;
};

FormErrors.propTypes = {
  hasErrors: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

FormErrors.defaultProps = {
  hasErrors: false,
  message: 'Please fix the errors below and submit again.'
};

export default FormErrors;
