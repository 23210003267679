import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { doGetCohortAssessments } from '../../../../redux/actions/cohortAssessmentActions';
import { cohortAssessmentCollectionSelector } from '../../../../redux/selectors/cohortAssessmentSelectors';
import { cohortSelector } from '../../../../redux/selectors/cohortsSelectors';
import { doGetProgramAssessments } from '../../../../redux/actions/programActions';
import { doGetUserProgramsByProgram } from '../../../../redux/actions/userProgramActions';

import CohortAssessmentSyllabus from './CohortAssessmentSyllabus';

const CohortAssessmentSyllabusContainer = () => {
  const dispatch = useDispatch();
  const { cohortUuid, cohortAssessmentCollectionUuid } = useParams();

  const assessmentCollection = useSelector(
    state =>
      cohortAssessmentCollectionSelector(state, cohortAssessmentCollectionUuid),
    _.isEqual
  );

  const parentCohort = useSelector(state => cohortSelector(state, cohortUuid));
  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  const [assessmentFormUuid, setAssessmentFormUuid] = useState('');
  const [isNewFormOpen, setIsNewFormOpen] = useState(false);

  useEffect(() => {
    if (parentCohort?.programUuid) {
      dispatch(doGetProgramAssessments(parentCohort.programUuid));
    }
  }, [dispatch, parentCohort]);

  useEffect(() => {
    if (selectedProgramUuid && selectedProgramUuid !== '') {
      dispatch(doGetUserProgramsByProgram(selectedProgramUuid));
    }
  }, [dispatch, selectedProgramUuid]);

  useEffect(() => {
    dispatch(doGetCohortAssessments(cohortUuid));
  }, [cohortUuid, dispatch]);

  const { cohortAssessments } = assessmentCollection;

  const setEditFormUuid = uuid => {
    setAssessmentFormUuid(uuid);
    setIsNewFormOpen(false);
  };

  const setNewFormOpen = () => {
    setIsNewFormOpen(true);
    setAssessmentFormUuid('');
  };

  const setClearForm = () => {
    setIsNewFormOpen(false);
    setAssessmentFormUuid('');
  };
  return (
    <CohortAssessmentSyllabus
      assessmentCollection={assessmentCollection}
      cohortAssessments={cohortAssessments}
      parentCohort={parentCohort}
      parentCohortUuid={cohortUuid}
      assessmentFormUuid={assessmentFormUuid}
      setAssessmentFormUuid={setEditFormUuid}
      isNewFormOpen={isNewFormOpen}
      setIsNewFormOpen={setNewFormOpen}
      setClearForm={setClearForm}
    />
  );
};

export default CohortAssessmentSyllabusContainer;
