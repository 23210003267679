import React, { useContext } from 'react';
import { Subtitle2, AddIllustration } from '@xcomp/xcomp-design-library';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';

const Layout = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Message = styled(Subtitle2)`
  width: 100%;
  text-align: center;
`;

const NoItemsMessage = ({ message }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Layout>
      <AddIllustration
        dotsColor={themeContext.colors.primaryShades[450]}
        bgColor={themeContext.colors.primaryShades[200]}
        outlineColor={themeContext.colors.primary}
      />
      <Message>{message}</Message>
    </Layout>
  );
};

NoItemsMessage.propTypes = {
  message: PropTypes.string
};

NoItemsMessage.defaultProps = {
  message: ''
};
export default NoItemsMessage;
