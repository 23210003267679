import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Body1 } from '@xcomp/xcomp-design-library';
import _ from 'lodash';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import TopicDefaultScoreViewAttemptPanelContainer from './TopicDefaultScoreViewAttemptPanelContainer';

const TopicDefaultScoreViewStudentPanelAccordion = styled(
  ({ withBottomBorder, withTopBorder, criticalFailure, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label,
  .column_two_label,
  .column_three_label,
  .column_four_label {
    flex-grow: 0;
    width: 100px;
    text-align: right;
  }

  .accordion_row_label div {
    font-size: 16px;
    letter-spacing: 0.15;
    color: ${props => props.theme.fontColors.darker};
  }

  .column_one_label,
  .column_two_label,
  .column_three_label {
    margin-right: 16px;
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props =>
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.theme.fontColors.darker};
    }
  }

  .column_four_label {
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props => props.theme.fontColors.darker};
    }
  }

  .hover-effect {
    padding-right: 44px;
  }
  width: 100%;
`;

const StudentPanelHeader = styled(({ headerOffset, ...rest }) => (
  <div {...rest} />
))`
  display: flex;
  justify-content: space-between;
  margin-left: ${props => `${props.headerOffset}px`};
  width: ${props => `calc(100% - ${props.headerOffset}px)`};
`;

const StudentPanelHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  font-weight: 450;
`;

const StudentPanelHeaderSub = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  padding-right: ${props => props.paddingRight};
  color: ${props => props.theme.fontColors.dark};
  text-align: right;
  }
`;

const NoAttemptDataMessage = styled(({ offset, ...rest }) => (
  <Body1 {...rest} />
))`
  margin-left: ${props => `${props.offset}px`};
  margin-top: 0;
`;

const TopicDefaultScoreViewStudentPanel = ({
  attemptOrOpportunityCount,
  currentOffset,
  scoreType,
  studentFullName,
  studentTopicScores,
  studentTotalPotential,
  studentTotalRelative
}) => {
  const totalOffset = Math.floor(currentOffset + 28);
  const headerOffset = Math.floor(totalOffset + 48);
  return (
    <TopicDefaultScoreViewStudentPanelAccordion
      expandWidth="66px"
      height="50px"
      label={studentFullName}
      columnOne={studentTotalRelative}
      columnTwo={studentTotalPotential}
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
      rowOffset={`${totalOffset}px`}
    >
      <StudentPanelHeader headerOffset={headerOffset}>
        <StudentPanelHeaderInfo>Attempt / Opportunity</StudentPanelHeaderInfo>
        <StudentPanelHeaderInfo>
          <StudentPanelHeaderSub paddingRight="24px">
            Total Relative
          </StudentPanelHeaderSub>
          <StudentPanelHeaderSub paddingRight="44px">
            Total Potential
          </StudentPanelHeaderSub>
        </StudentPanelHeaderInfo>
      </StudentPanelHeader>
      {attemptOrOpportunityCount === 0 && (
        <NoAttemptDataMessage offset={headerOffset}>
          No attempt data to display
        </NoAttemptDataMessage>
      )}
      {_.times(attemptOrOpportunityCount, attemptNumber => (
        <TopicDefaultScoreViewAttemptPanelContainer
          key={attemptNumber}
          attemptNumber={attemptNumber + 1}
          currentOffset={totalOffset}
          scoreType={scoreType}
          studentScores={studentTopicScores}
        />
      ))}
    </TopicDefaultScoreViewStudentPanelAccordion>
  );
};

TopicDefaultScoreViewStudentPanel.propTypes = {
  attemptOrOpportunityCount: PropTypes.number,
  currentOffset: PropTypes.number,
  scoreType: PropTypes.string,
  studentFullName: PropTypes.string,
  studentTopicScores: PropTypes.array,
  studentTotalPotential: PropTypes.string,
  studentTotalRelative: PropTypes.string
};

TopicDefaultScoreViewStudentPanel.defaultProps = {
  attemptOrOpportunityCount: 0,
  currentOffset: 0,
  scoreType: '',
  studentFullName: '',
  studentTopicScores: [],
  studentTotalPotential: '',
  studentTotalRelative: ''
};

export default TopicDefaultScoreViewStudentPanel;
