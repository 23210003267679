import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BlockTitles = styled.div`
  padding-left: 20px;
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
`;

const BlockTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width};
  ${props => props.autoWidth && `width: calc(100% - 240px);`}
  font-weight: 600;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-bottom: 10px;
`;

const AssessmentRow = styled.div`
  width: 100%;
`;

const Input = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width};
  ${props => props.autoWidth && `width: calc(100% - 240px);`}
  padding-right: 15px;
  white-space: nowrap;
`;

const BlockRow = styled.div`
  padding-left: 20px;
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
  align-items: center;
`;

const DidacticAssessments = ({ assessments }) => {
  return (
    <>
      <BlockTitles>
        <BlockTitle autoWidth>Assessment</BlockTitle>
        <BlockTitle width="80px">Date</BlockTitle>
        <BlockTitle width="80px">Score</BlockTitle>
        <BlockTitle width="80px">Grade</BlockTitle>
      </BlockTitles>
      {assessments.map(assessment => (
        <AssessmentRow key={assessment?.uuid}>
          <BlockRow key={assessment.uuid}>
            <Input autoWidth>
              <Tooltip arrow title={`${assessment?.name}`} placement="top">
                <span>{assessment?.name}</span>
              </Tooltip>
            </Input>
            <Input width="80px">{assessment?.passDate}</Input>
            <Input width="80px">{assessment?.score}</Input>
            <Input width="80px">{assessment?.grade}%</Input>
          </BlockRow>
        </AssessmentRow>
      ))}
    </>
  );
};

DidacticAssessments.propTypes = {
  assessments: PropTypes.array,
};

DidacticAssessments.defaultProps = {
  assessments: [],
};
export default DidacticAssessments;
