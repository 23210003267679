import React from 'react';
import PropTypes from 'prop-types';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import styled from 'styled-components';
import { Grid, InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import FormField from '../../../../../Library/FormField';

import { validateInputString } from '../../../../../../helpers/validation/validateGeneric';

const AddButton = styled(ButtonPrimary)`
  && {
    margin-top: 30px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
`;

const IconArea = styled.div``;

export default function EditorMilestoneConversion({
  classificationUuid,
  examMilestoneUuid,
  conversions,
  hasErrors,
  handleAddConversion,
  handleUpdateConversion,
  handleRemoveConversion
}) {
  return (
    <>
      <Grid container>
        {conversions.length > 0 && (
          <Grid item xs={12} sm={12}>
            <Grid container spacing={4} justifyContent="flex-end">
              <Grid item xs={12} sm={7}>
                <Title>Conversions</Title>
              </Grid>
            </Grid>
          </Grid>
        )}
        {conversions.map(conversion => (
          <Grid item xs={12} sm={12} key={conversion.uuid}>
            <Grid container spacing={4} justifyContent="flex-end">
              <Grid item xs={12} sm={3}>
                <FormField
                  name="scaledScore"
                  label="Scaled Score*"
                  type="number"
                  onBlur={event =>
                    handleUpdateConversion(
                      event,
                      classificationUuid,
                      examMilestoneUuid,
                      conversion.uuid
                    )
                  }
                  hasErrors={hasErrors}
                  value={conversion.scaledScore}
                  handleValidate={validateInputString}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormField
                  name="percentage"
                  label="Percentage*"
                  type="number"
                  onBlur={event =>
                    handleUpdateConversion(
                      event,
                      classificationUuid,
                      examMilestoneUuid,
                      conversion.uuid
                    )
                  }
                  hasErrors={hasErrors}
                  value={conversion.percentage}
                  handleValidate={validateInputString}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={1}
                direction="column"
                justifyContent="center"
              >
                <IconArea>
                  <IconButton
                    onClick={() =>
                      handleRemoveConversion(
                        classificationUuid,
                        examMilestoneUuid,
                        conversion.uuid
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </IconArea>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container justifyContent="flex-end" item sm={12} xs={12}>
        <AddButton
          onClick={() =>
            handleAddConversion(classificationUuid, examMilestoneUuid)
          }
        >
          Add Conversion
        </AddButton>
      </Grid>
    </>
  );
}

EditorMilestoneConversion.propTypes = {
  classificationUuid: PropTypes.string,
  examMilestoneUuid: PropTypes.string,
  conversions: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleAddConversion: PropTypes.func,
  handleUpdateConversion: PropTypes.func,
  handleRemoveConversion: PropTypes.func
};

EditorMilestoneConversion.defaultProps = {
  classificationUuid: undefined,
  examMilestoneUuid: undefined,
  conversions: [],
  hasErrors: false,
  handleAddConversion: undefined,
  handleUpdateConversion: undefined,
  handleRemoveConversion: undefined
};
