import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import {
  ColumnLabel,
  BannerIdColumn,
  NameColumn,
  LetterGradeColumn,
  CalculatedGradeColumn
} from './SharedSC';

const DelayedGradeActionsColumn = styled.div`
  width: 10%;
  display: flex;
  justify-content: flex-end;
  margin-left: 0;
`;

const ResultCardRow = styled.div`
  padding: 12px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const getNameColumnWidth = (isPointsOnly, isDelayed) => {
  if (isPointsOnly && isDelayed) {
    return '60%';
  } else if (isDelayed) {
    return '50%';
  } else if (isPointsOnly) {
    return '70%';
  }

  return '60%';
};

const GradeSummaryRowHeader = ({ isPointsOnly, isDelayed }) => {
  const nameColumnWidth = getNameColumnWidth(isPointsOnly, isDelayed);
  return (
    <ResultCardRow>
      <BannerIdColumn>
        <ColumnLabel>Banner ID</ColumnLabel>
      </BannerIdColumn>
      <NameColumn columnWidth={nameColumnWidth}>
        <ColumnLabel>Student Name</ColumnLabel>
      </NameColumn>
      {!isPointsOnly ? (
        <>
          <LetterGradeColumn>
            <ColumnLabel>Grade</ColumnLabel>
          </LetterGradeColumn>
          <CalculatedGradeColumn>
            <ColumnLabel>Percentage</ColumnLabel>
          </CalculatedGradeColumn>
        </>
      ) : (
        <CalculatedGradeColumn>
          <ColumnLabel>Total Score</ColumnLabel>
        </CalculatedGradeColumn>
      )}
      {isDelayed && (
        <DelayedGradeActionsColumn>
          <ColumnLabel>Actions</ColumnLabel>
        </DelayedGradeActionsColumn>
      )}
    </ResultCardRow>
  );
};

GradeSummaryRowHeader.propTypes = {
  isPointsOnly: PropTypes.bool,
  isDelayed: PropTypes.bool
};

GradeSummaryRowHeader.defaultProps = {
  isPointsOnly: false,
  isDelayed: false
};

export default GradeSummaryRowHeader;
