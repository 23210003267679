import { Model, attr } from 'redux-orm';
import {
  GET_ASSESSMENT_ASSOCIATIONS_SUCCESS,
  GET_PROGRAM_ASSESSMENTS_SUCCESS,
  GET_PROGRAM_ASSESSMENT_SUCCESS,
  GET_SYLLABUS_PART_SUCCESS,
  GET_SYLLABUS_SUCCESS,
  GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS
} from '../actions-type';

class AssessmentAssociation extends Model {
  static reducer(action, AssessmentAssociation, session) {
    const { type, payload } = action;
    switch (type) {
      case GET_SYLLABUS_SUCCESS: {
        const assessmentAssociations = [...payload.assessmentAssociations];
        assessmentAssociations.forEach(aAssociation =>
          AssessmentAssociation.upsert(aAssociation)
        );
        break;
      }
      case GET_ASSESSMENT_ASSOCIATIONS_SUCCESS: {
        const { assessmentAssociations } = payload;
        assessmentAssociations.forEach(assessmentAssociation =>
          AssessmentAssociation.upsert(assessmentAssociation)
        );
        break;
      }
      case GET_PROGRAM_ASSESSMENT_SUCCESS:
      case GET_PROGRAM_ASSESSMENTS_SUCCESS: {
        const { assessmentAssociations } = payload;
        assessmentAssociations.forEach(assessmentAssociation =>
          AssessmentAssociation.upsert(assessmentAssociation)
        );
        break;
      }
      case GET_SYLLABUS_PART_SUCCESS: {
        const assessmentAssociations = [...payload.assessmentAssociations];
        assessmentAssociations.forEach(aAssociation =>
          AssessmentAssociation.upsert(aAssociation)
        );
        break;
      }
      case GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS: {
        const assessmentAssociations = [...payload.assessmentAssociations];
        assessmentAssociations.forEach(aAssociation =>
          AssessmentAssociation.upsert(aAssociation)
        );
        break;
      }
      default:
        return session;
    }
  }
}

AssessmentAssociation.fields = {
  uuid: attr(),
  association: attr(),
  order: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

AssessmentAssociation.modelName = 'AssessmentAssociation';

AssessmentAssociation.options = {
  idAttribute: 'uuid'
};

export default AssessmentAssociation;
