import React from 'react';
import PropTypes from 'prop-types';

import {
  StickyTitleTd,
  TableDataText,
  PointsTd,
  TableRow,
  StickyInfoTd,
  StudentInfoText
} from '../CollectionReportSC';

const StandardDeviationRow = ({
  competencyCollections,
  competencyDeviationsMap,
  isExpanded,
  showScores,
  relativeCompScores,
}) => {
  return (
    <>
      <TableRow borderBottom={!isExpanded}>
        <StickyTitleTd redTwo isRank left="0px" borderRight>
          <TableDataText />
        </StickyTitleTd>
        <StickyInfoTd greyOne left="25px" borderRightStrong>
          <StudentInfoText style={{ fontWeight: 500 }}>Standard Deviation (For all)</StudentInfoText>
        </StickyInfoTd>
        <StickyTitleTd redTwo left="275px" borderRight>
          <TableDataText disabledFontColor>n/a</TableDataText>
        </StickyTitleTd>
        {isExpanded &&
          <>
            <StickyTitleTd left="340px" isRank orangeTwo borderRight isRelativeRank>
              <TableDataText />
            </StickyTitleTd>
            <StickyTitleTd orangeTwo left="365px" borderRight>
              <TableDataText disabledFontColor>n/a</TableDataText>
            </StickyTitleTd>
          </>
        }
        {competencyCollections.map(collection => {
          return (
            <React.Fragment key={collection.uuid}>
              {!isExpanded ?
                <PointsTd blueTwo isExpanded={isExpanded}>
                  <TableDataText disabledFontColor>n/a</TableDataText>
                </PointsTd>
                :
                collection.competencies.length > 0 &&
                collection.competencies.map((competency, index) => {
                  const stdDeviation =
                    competencyDeviationsMap &&
                    competencyDeviationsMap[competency.uuid]?.standard_deviation;

                  const competencyStdDevDisplay =
                    stdDeviation || stdDeviation === '0.00' ? stdDeviation.slice(0, -1) : '--';

                  return (
                    <PointsTd
                      key={competency.uuid}
                      greyOne
                      isFirstCompetency={index === 0}
                      isExpanded={isExpanded}
                      isCompact={!showScores}
                    >
                      <TableDataText bold secondaryFontColor isCompact={!showScores}>
                        {competencyStdDevDisplay}
                      </TableDataText>
                    </PointsTd>
                  );
                })
              }
            </React.Fragment>
          );
        })}
      </TableRow>
      {isExpanded &&
        <TableRow borderBottom={true}>
          <StickyTitleTd redOne isRank left="0px" borderRight>
            <TableDataText />
          </StickyTitleTd>
          <StickyInfoTd left="25px" borderRightStrong>
            <StudentInfoText>Relative Standard Deviation</StudentInfoText>
          </StickyInfoTd>
          <StickyTitleTd redOne left="275px" borderRight>
            <TableDataText disabledFontColor>n/a</TableDataText>
          </StickyTitleTd>
          <StickyTitleTd left="340px" isRank orangeOne borderRight isRelativeRank>
            <TableDataText />
          </StickyTitleTd>
          <StickyTitleTd orangeOne left="365px" borderRight>
            <TableDataText disabledFontColor>n/a</TableDataText>
          </StickyTitleTd>
          {competencyCollections.map(collection => {
            return collection.competencies.length > 0 &&
              collection.competencies.map((competency, index) => {
                const stdDeviation =
                  relativeCompScores[competency.uuid]?.deviation?.toFixed(1);

                return (
                  <PointsTd
                    key={competency.uuid}
                    isFirstCompetency={index === 0}
                    isExpanded={isExpanded}
                    isCompact={!showScores}
                  >
                    <TableDataText secondaryFontColor isCompact={!showScores}>
                      {stdDeviation && stdDeviation > 0 ? stdDeviation : '--'}
                    </TableDataText>
                  </PointsTd>
                );
              })
          }
          )}
        </TableRow>
      }
    </>
  );
};

StandardDeviationRow.propTypes = {
  competencyCollections: PropTypes.array,
  competencyDeviationsMap: PropTypes.object,
  isExpanded: PropTypes.bool.isRequired,
  showScores: PropTypes.bool.isRequired,
  relativeCompScores: PropTypes.object,
};

StandardDeviationRow.defaultProps = {
  competencyCollections: [],
  competencyDeviationsMap: {},
  relativeCompScores: {},

};

export default StandardDeviationRow;
