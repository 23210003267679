import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  GET_PROGRAM_ASSESSMENTS_SUCCESS,
  GET_PROGRAM_ASSESSMENT_SUCCESS,
  POST_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  PUT_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS
} from '../actions-type';

class ProgramAssessmentMaster extends Model {
  static reducer(action, ProgramAssessmentMaster, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS: {
        const { cohortAssessmentCollections } = payload;

        cohortAssessmentCollections.forEach(collection =>
          collection.cohort_assessments.forEach(assessment => {
            const { program_assessment_master } = assessment;
            ProgramAssessmentMaster.upsert(program_assessment_master);
          })
        );

        break;
      }

      case GET_PROGRAM_ASSESSMENTS_SUCCESS: {
        const { program_assessment_collections } = payload;

        if (
          program_assessment_collections &&
          program_assessment_collections.length > 0
        ) {
          program_assessment_collections.map(collection => {
            const { program_assessment_masters } = collection;

            return (
              program_assessment_masters?.length > 0 &&
              program_assessment_masters.map(assessmentMaster => {
                return ProgramAssessmentMaster.upsert(assessmentMaster);
              })
            );
          });
        }
        break;
      }
      case GET_PROGRAM_ASSESSMENT_SUCCESS: {
        const { program_assessment } = payload;

        ProgramAssessmentMaster.upsert(program_assessment);

        break;
      }
      case POST_PROGRAM_ASSESSMENT_MASTER_SUCCESS:
      case PUT_PROGRAM_ASSESSMENT_MASTER_SUCCESS: {
        const { program_assessment_master } = payload;

        ProgramAssessmentMaster.upsert(program_assessment_master);
        break;
      }
      case DELETE_PROGRAM_ASSESSMENT_MASTER_SUCCESS: {
        const { deletedProgramAssessmentMaster } = payload;
        ProgramAssessmentMaster.withId(
          deletedProgramAssessmentMaster.uuid
        ).delete();
        break;
      }

      default:
        return session;
    }
  }
}

ProgramAssessmentMaster.fields = {
  uuid: attr(),
  title: attr(),
  timespan: attr(),
  scoreType: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  programAssessmentCollectionUuid: fk(
    'ProgramAssessmentCollection',
    'program_assessment_masters'
  ),
  assessmentTypeUuid: fk('AssessmentType', 'program_assessment_masters'),
  assessmentAssociationUuid: fk(
    'AssessmentAssociation',
    'program_assessment_masters'
  ),
  assessmentTechniqueUuid: fk(
    'AssessmentTechnique',
    'program_assessment_masters'
  )
};

ProgramAssessmentMaster.modelName = 'ProgramAssessmentMaster';

ProgramAssessmentMaster.options = {
  idAttribute: 'uuid'
};

export default ProgramAssessmentMaster;
