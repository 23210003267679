import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getCredentials,
  postCredential,
  putCredential,
  deleteCredential
} from '../../api/credentialApi';

import {
  doGetCredentialsSuccess,
  doGetCredentialsError,
  doPostCredentialSuccess,
  doPostCredentialError,
  doPutCredentialSuccess,
  doPutCredentialError,
  doDeleteCredentialSuccess,
  doDeleteCredentialError
} from '../actions/credentialActions';

import {
  GET_CREDENTIALS,
  POST_CREDENTIAL,
  PUT_CREDENTIAL,
  DELETE_CREDENTIAL
} from '../actions-type';

function* handleGetCredentials(action) {
  try {
    const { uuid } = action.payload;

    const result = yield call(getCredentials, uuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCredentialsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCredentialsError(error));
  }
}

function* handlePostCredential(action) {
  try {
    const result = yield call(postCredential, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostCredentialSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostCredentialError(error));
  }
}

function* handlePutCredential(action) {
  try {
    const result = yield call(putCredential, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutCredentialSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutCredentialError(error));
  }
}

function* handleDeleteCredential(action) {
  try {
    const { uuid } = action.payload;

    const result = yield call(deleteCredential, uuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteCredentialSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteCredentialError(error));
  }
}

const credential = [
  takeEvery(GET_CREDENTIALS, handleGetCredentials),
  takeEvery(POST_CREDENTIAL, handlePostCredential),
  takeEvery(PUT_CREDENTIAL, handlePutCredential),
  takeEvery(DELETE_CREDENTIAL, handleDeleteCredential)
];

export { credential };
