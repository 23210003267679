import React, { useReducer, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import PartGrading from './PartGrading';
import { partSelectorByUuid } from '../../../../../../redux/selectors/partsSelectors';
import { useDelete } from '../../../../../Library/Modal/DeleteModal/useDelete';
import {
  doPatchPartCalculateGrade,
  doPatchPartUndoCalculatedGrade,
  doPutPartStatusCommit
} from '../../../../../../redux/actions/partActions';
import {
  partGradeStatusReducer,
  initialPartGradeStatusState
} from './partGradingState';

export default function PartGradingContainer({ partUuid }) {
  const dispatch = useDispatch();

  const [state, partGradeDispatch] = useReducer(
    partGradeStatusReducer,
    initialPartGradeStatusState
  );

  const existingPart = useSelector(
    state => partSelectorByUuid(state, partUuid),
    shallowEqual
  );

  const setExistingState = ({ uuid, statusCommit, statusGrade }) => {
    partGradeDispatch({
      type: 'setExistingPart',
      payload: {
        uuid,
        statusCommit,
        statusGrade
      }
    });
  };

  useEffect(() => {
    if (existingPart?.uuid)
      setExistingState({
        uuid: existingPart.uuid,
        statusCommit: existingPart.statusCommit,
        statusGrade: existingPart.statusGrade
      });
  }, [existingPart]);

  const { statusCommit, statusGrade, justificationMessage } = state;

  const handleCommit = () => {
    partGradeDispatch({
      type: 'setStatusCommitted'
    });
  };

  const handleUncommit = () => {
    partGradeDispatch({ type: 'setStatusUncommitted' });
  };

  const handleJustificationChange = event => {
    partGradeDispatch({
      type: 'setJustificationMessage',
      payload: {
        justificationMessage: event.target.value
      }
    });
  };

  const handleResetCommitment = () => {
    if (existingPart?.uuid)
      setExistingState({
        uuid: existingPart.uuid,
        statusCommit: existingPart.statusCommit,
        statusGrade: existingPart.statusGrade
      });
  };

  const handleStatusCommitSubmit = () => {
    const payload = {
      uuid: partUuid,
      statusCommit,
      justificationMessage:
        statusCommit === 'COMMITTED' ? null : justificationMessage
    };

    dispatch(doPutPartStatusCommit(payload));
  };

  const handleStatusGradeSubmit = () => {
    dispatch(doPatchPartCalculateGrade(partUuid));
  };

  const onDelete = () => dispatch(doPatchPartUndoCalculatedGrade(partUuid));

  const [
    isDeleteModalOpen,
    handleDeleteClick,
    handleDeleteModalClose,
    handleDelete
  ] = useDelete(onDelete);

  const onDeleteClick = e => {
    e.stopPropagation();
    handleDeleteClick();
  };

  return (
    <PartGrading
      partUuid={partUuid}
      existingPart={existingPart}
      statusCommit={statusCommit}
      justificationMessage={justificationMessage}
      statusGrade={statusGrade}
      handleCommit={handleCommit}
      isDeleteModalOpen={isDeleteModalOpen}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDelete={handleDelete}
      onDeleteClick={onDeleteClick}
      handleUncommit={handleUncommit}
      handleJustificationChange={handleJustificationChange}
      handleGrading={handleCommit}
      handleGradingCancel={handleUncommit}
      handleStatusCommitSubmit={handleStatusCommitSubmit}
      handleStatusGradeSubmit={handleStatusGradeSubmit}
      handleResetCommitment={handleResetCommitment}
    />
  );
}

PartGradingContainer.propTypes = {
  partUuid: PropTypes.string
};

PartGradingContainer.defaultProps = {
  partUuid: null
};
