import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { AutoSizer, List } from 'react-virtualized';
import CohortApplicantRow from './CohortApplicantRow';
import { ContainerPage, ElevateLow } from '../../Library/Layout';
import CohortStatusFilter from './CohortStatusFilter';
import 'react-virtualized/styles.css';

const StatusCount = styled.div`
  background: ${props => props.theme.colors.white};
  padding: 20px;
`;

const TranscriptBox = styled.div`
  display: flex;
  align-items: center;
`;

const Status = styled.div``;

const SelectAllBox = styled.div`
  display: flex;
  align-items: center;
`;

const ChipSC = styled(Chip)`
  && {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
  }
`;

const Row = styled.div`
  font-size: 14px;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

export default function CohortApplicant({
  activeCount,
  cohortUsers,
  completeCount,
  filterStatus,
  handleSelect,
  handleFilterStatus,
  handleThirdPartyOpen,
  isAdmin
}) {
  const rowRenderer = ({
    index, // Index of row
    // isScrolling, // The List is currently being scrolled
    // isVisible, // This row is visible within the List (eg it is not an overscanned row)
    key, // Unique key within array of rendered rows
    // parent, // Reference to the parent List (instance)
    style // Style object to be applied to row (to position it);
    // This must be passed through to the rendered row element.
  }) => {
    const cohortUser = cohortUsers[index];

    return (
      <div key={key} style={style}>
        <CohortApplicantRow
          key={cohortUser.uuid}
          cohortUser={cohortUser}
          handleThirdPartyOpen={handleThirdPartyOpen}
          isAdmin={isAdmin}
        />
      </div>
    );
  };

  return (
    <ContainerPage paddingTop="60px">
      <StatusCount>
        <ChipSC label={`${activeCount} Active`} />
        <ChipSC label={`${completeCount} Complete`} />
      </StatusCount>
      <ElevateLow>
        <Row style={{paddingRight: isAdmin ? 0 : 15}}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item sm={2} xs={2}>
              Institution ID
            </Grid>
            <Grid item sm={isAdmin ? 2 : 3} xs={isAdmin ? 2 : 3}>
              Name
            </Grid>
            {isAdmin &&
              <Grid item sm={1} xs={1}>
                Edit
              </Grid>
            }
            <Grid item sm={1} xs={1}>
              <Status>
                <CohortStatusFilter
                  filterStatus={filterStatus}
                  handleFilterStatus={handleFilterStatus}
                />
              </Status>
            </Grid>
            <Grid item sm={1} xs={1}>
              Role
            </Grid>
            <Grid item sm={isAdmin ? 2 : 3} xs={isAdmin ? 2 : 3}>
              <TranscriptBox>Thirdparty ID</TranscriptBox>
            </Grid>
            <Grid item sm={1} xs={1}>
              <TranscriptBox>Transcript</TranscriptBox>
            </Grid>
            {isAdmin &&
              <Grid item sm={1} xs={1}>
                <SelectAllBox>
                  <Checkbox
                    onChange={event => handleSelect(event)}
                    color="primary"
                  />
                  Select
                </SelectAllBox>
              </Grid>
            }
          </Grid>
        </Row>
        <div className="list">
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                rowCount={cohortUsers.length}
                rowHeight={50}
                rowRenderer={rowRenderer}
                width={width}
              />
            )}
          </AutoSizer>
        </div>
      </ElevateLow>
    </ContainerPage>
  );
}

CohortApplicant.propTypes = {
  activeCount: PropTypes.number,
  cohortUsers: PropTypes.array,
  completeCount: PropTypes.number,
  filterStatus: PropTypes.object,
  handleSelect: PropTypes.func,
  handleFilterStatus: PropTypes.func,
  handleThirdPartyOpen: PropTypes.func,
  isAdmin: PropTypes.bool,
};
CohortApplicant.defaultProps = {
  activeCount: 0,
  cohortUsers: [],
  completeCount: 0,
  filterStatus: undefined,
  handleSelect: undefined,
  handleFilterStatus: undefined,
  handleThirdPartyOpen: undefined,
  isAdmin: false,
};
