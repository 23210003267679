import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  doGetStudentRoster,
  doPatchUserSectionStatus,
  doDeleteUsersSection
} from '../../../redux/actions/userSectionActions';
import { studentRosterSelector } from '../../../redux/selectors/sectionsSelectors';

import StudentRoster from './StudentRoster';
import StudentAssignContainer from './StudentAssign/StudentAssignContainer';
import StudentMassAssignContainer from './StudenMassAssign/StudentMassAssignContainer';
import StudentRosterDeleteConfirmation from './StudentRosterDeleteConfirmation';

class StudentRosterContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectAll: false,
      filterStatus: {
        active: false,
        delayed: false,
        withdrew: false,
        credit: false,
        pass: false,
        pass_with_remediation: false,
        fail: false,
        fail_with_remediation: false
      },
      students: [],
      openAssignStudent: false,
      openMassAssignStudent: false,
      openDeleteModal: false
    };

    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleDeselectAll = this.handleDeselectAll.bind(this);
    this.handleSelectStudent = this.handleSelectStudent.bind(this);
    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this.handleAssignStudentOpen = this.handleAssignStudentOpen.bind(this);
    this.handleAssignStudentClose = this.handleAssignStudentClose.bind(this);
    this.handleMassAssignStudentOpen = this.handleMassAssignStudentOpen.bind(
      this
    );
    this.handleMassAssignStudentClose = this.handleMassAssignStudentClose.bind(
      this
    );
    this.handleSelectedStatus = this.handleSelectedStatus.bind(this);
    this._loadStudents = this._loadStudents.bind(this);
    this._filterStudentsByStatus = this._filterStudentsByStatus.bind(this);
    this.handleInitiateDelete = this.handleInitiateDelete.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);
    this.handleDeleteStudents = this.handleDeleteStudents.bind(this);
  }

  componentDidMount() {
    const { match, onGetStudentRoster, students } = this.props;
    const { sectionUuid } = match.params;

    onGetStudentRoster(sectionUuid);

    if (students) {
      this._loadStudents();
    }
  }

  componentDidUpdate(prevProps) {
    const { students } = this.props;

    if (students !== prevProps.students) {
      this._loadStudents();
    }
  }

  handleFilterSelection(selection) {
    if (selection === 'active') {
      this.setState(prevState => {
        const toggleSelection = !prevState.filterStatus.active;

        return {
          ...prevState,
          filterStatus: {
            ...prevState.filterStatus,
            active: toggleSelection
          }
        };
      });
    }

    if (selection === 'delayed') {
      this.setState(prevState => {
        const toggleSelection = !prevState.filterStatus.delayed;

        return {
          ...prevState,
          filterStatus: {
            ...prevState.filterStatus,
            delayed: toggleSelection
          }
        };
      });
    }

    if (selection === 'withdrew') {
      this.setState(prevState => {
        const toggleSelection = !prevState.filterStatus.withdrew;

        return {
          ...prevState,
          filterStatus: {
            ...prevState.filterStatus,
            withdrew: toggleSelection
          }
        };
      });
    }

    if (selection === 'credit') {
      this.setState(prevState => {
        const toggleSelection = !prevState.filterStatus.credit;

        return {
          ...prevState,
          filterStatus: {
            ...prevState.filterStatus,
            credit: toggleSelection
          }
        };
      });
    }

    if (selection === 'pass') {
      this.setState(prevState => {
        const toggleSelection = !prevState.filterStatus.pass;

        return {
          ...prevState,
          filterStatus: {
            ...prevState.filterStatus,
            pass: toggleSelection
          }
        };
      });
    }

    if (selection === 'pass_with_remediation') {
      this.setState(prevState => {
        const toggleSelection = !prevState.filterStatus.pass_with_remediation;

        return {
          ...prevState,
          filterStatus: {
            ...prevState.filterStatus,
            pass_with_remediation: toggleSelection
          }
        };
      });
    }

    if (selection === 'fail') {
      this.setState(prevState => {
        const toggleSelection = !prevState.filterStatus.fail;

        return {
          ...prevState,
          filterStatus: {
            ...prevState.filterStatus,
            fail: toggleSelection
          }
        };
      });
    }

    if (selection === 'fail_with_remediation') {
      this.setState(prevState => {
        const toggleSelection = !prevState.filterStatus.fail_with_remediation;

        return {
          ...prevState,
          filterStatus: {
            ...prevState.filterStatus,
            fail_with_remediation: toggleSelection
          }
        };
      });
    }
  }

  handleSelectAll() {
    this.setState(prevState => {
      const { students } = this.state;

      const selectAllStudents = students.map(student => {
        return { ...student, selected: true };
      });

      return {
        ...prevState,
        selectAll: true,
        students: selectAllStudents
      };
    });
  }

  handleDeselectAll() {
    this.setState(prevState => {
      const { students } = this.state;

      const deselectAllStudents = students.map(student => {
        return { ...student, selected: false };
      });

      return {
        ...prevState,
        selectAll: false,
        students: deselectAllStudents
      };
    });
  }

  handleSelectStudent(uuid) {
    const { students } = this.state;

    const studentsSelected = students.map(student => {
      if (student.uuid === uuid) {
        const selection = !student.selected;

        return { ...student, selected: selection };
      } else {
        return student;
      }
    });

    this.setState({ students: studentsSelected });
  }

  handleAssignStudentOpen() {
    this.setState({ openAssignStudent: true });
  }

  handleAssignStudentClose() {
    this.setState({ openAssignStudent: false });
  }

  handleSelectedStatus(status) {
    const { students } = this.state;
    const { onPatchUserSectionStatus } = this.props;

    const selectedUsers = students.filter(student => student.selected === true);

    const changeStatus = selectedUsers.map(user => ({
      uuid: user.uuid,
      status
    }));

    onPatchUserSectionStatus(changeStatus);

    this.setState({ selectAll: false });
  }

  handleMassAssignStudentOpen() {
    this.setState({ openMassAssignStudent: true });
  }

  handleMassAssignStudentClose() {
    this.setState({ openMassAssignStudent: false });
  }

  handleInitiateDelete() {
    this.setState({ openDeleteModal: true });
  }

  handleCancelDelete() {
    this.setState({ openDeleteModal: false });
  }

  handleDeleteStudents() {
    const { students } = this.state;
    const { onDeleteUsersSection } = this.props;
    const selectedUsers = students.filter(student => student.selected === true);

    if (selectedUsers.length > 0) {
      onDeleteUsersSection(selectedUsers);
    }

    this.setState({ openDeleteModal: false });
    this.setState({ selectAll: false });
  }

  _loadStudents() {
    this.setState(prevState => {
      const { students } = this.props;

      const studentsSelector = students.map(student => {
        return { ...student, selected: false };
      });

      return {
        ...prevState,
        students: studentsSelector
      };
    });
  }

  _filterStudentsByStatus() {
    const { students, filterStatus } = this.state;

    const {
      active,
      delayed,
      withdrew,
      credit,
      pass,
      pass_with_remediation,
      fail,
      fail_with_remediation
    } = filterStatus;

    const selectedStudents = [];
    if (filterStatus.active) {
      const activeSelected = _.filter(
        students,
        student => student.status === 'active'
      );

      selectedStudents.push(activeSelected);
    }

    if (filterStatus.delayed) {
      const delayedSelected = _.filter(
        students,
        student => student.status === 'delayed'
      );

      selectedStudents.push(delayedSelected);
    }

    if (filterStatus.withdrew) {
      const withdrewSelected = _.filter(
        students,
        student => student.status === 'withdrew'
      );

      selectedStudents.push(withdrewSelected);
    }

    if (filterStatus.credit) {
      const creditSelected = _.filter(
        students,
        student => student.status === 'credit'
      );

      selectedStudents.push(creditSelected);
    }

    if (filterStatus.pass) {
      const passSelected = _.filter(
        students,
        student => student.status === 'pass'
      );

      selectedStudents.push(passSelected);
    }

    if (filterStatus.pass_with_remediation) {
      const pass_with_remediationSelected = _.filter(
        students,
        student => student.status === 'pass_with_remediation'
      );

      selectedStudents.push(pass_with_remediationSelected);
    }

    if (filterStatus.fail) {
      const failSelected = _.filter(
        students,
        student => student.status === 'fail'
      );

      selectedStudents.push(failSelected);
    }

    if (filterStatus.fail_with_remediation) {
      const fail_with_remediationSelected = _.filter(
        students,
        student => student.status === 'fail_with_remediation'
      );

      selectedStudents.push(fail_with_remediationSelected);
    }

    if (
      !active &&
      !delayed &&
      !withdrew &&
      !credit &&
      !pass &&
      !pass_with_remediation &&
      !fail &&
      !fail_with_remediation
    ) {
      selectedStudents.push(students);
    }

    return _.flatten(selectedStudents);
  }

  render() {
    const { schoolUuid } = this.props;
    const { sectionUuid, sectionNumber } = this.props.match.params;
    const {
      selectAll,
      filterStatus,
      openAssignStudent,
      openMassAssignStudent,
      openDeleteModal
    } = this.state;

    const filterStudents = this._filterStudentsByStatus();

    return (
      <div>
        <StudentRoster
          sectionUuid={sectionUuid}
          sectionNumber={sectionNumber}
          students={filterStudents}
          selectAll={selectAll}
          filterStatus={filterStatus}
          handleSelectAll={this.handleSelectAll}
          handleDeselectAll={this.handleDeselectAll}
          handleSelectStudent={this.handleSelectStudent}
          handleFilterSelection={this.handleFilterSelection}
          handleAssignStudentOpen={this.handleAssignStudentOpen}
          handleSelectedStatus={this.handleSelectedStatus}
          handleMassAssignStudentOpen={this.handleMassAssignStudentOpen}
          handleInitiateDelete={this.handleInitiateDelete}
        />
        <StudentAssignContainer
          schoolUuid={schoolUuid}
          sectionUuid={sectionUuid}
          openAssignStudent={openAssignStudent}
          handleAssignStudentClose={this.handleAssignStudentClose}
        />
        <StudentMassAssignContainer
          schoolUuid={schoolUuid}
          sectionUuid={sectionUuid}
          openMassAssignStudent={openMassAssignStudent}
          handleMassAssignStudentClose={this.handleMassAssignStudentClose}
        />
        <StudentRosterDeleteConfirmation
          openModal={openDeleteModal}
          handleCancelDelete={this.handleCancelDelete}
          handleDeleteStudents={this.handleDeleteStudents}
        />
      </div>
    );
  }
}

StudentRosterContainer.propTypes = {
  match: PropTypes.object,
  schoolUuid: PropTypes.string,
  students: PropTypes.array,
  sectionUuid: PropTypes.string,
  sectionNumber: PropTypes.string,
  onGetStudentRoster: PropTypes.func,
  onPatchUserSectionStatus: PropTypes.func,
  onDeleteUsersSection: PropTypes.func
};

StudentRosterContainer.defaultProps = {
  match: {},
  schoolUuid: '',
  students: [],
  sectionUuid: '',
  sectionNumber: '',
  onGetStudentRoster: undefined,
  onPatchUserSectionStatus: undefined,
  onDeleteUsersSection: undefined
};

const mapStateToProps = (state, props) => ({
  students: studentRosterSelector(state, props),
  schoolUuid: state.userState.selectedSchoolUuid
});

const mapDispatchToProps = dispatch => ({
  onGetStudentRoster: sectionUuid => dispatch(doGetStudentRoster(sectionUuid)),
  onPatchUserSectionStatus: changeStatus =>
    dispatch(doPatchUserSectionStatus(changeStatus)),
  onDeleteUsersSection: students => dispatch(doDeleteUsersSection(students))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentRosterContainer);

export { StudentRosterContainer };
