import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

export const AssessmentHeaderContainer = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  height: 48px;
  width: 100%;
  display: flex;
  padding-right: ${props => props.paddingRight};
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: flex-start;
`;

const AssessmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 90px;
  width: calc(100% - 90px);
`;

const AssessmentHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
`;

const TitleTooltip = styled(({ ...rest }) => <Tooltip {...rest} />)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AssessmentHeaderData = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  font-size: 14px;
  font-weight: 400;
  min-width: 456px;
`;

const AssessmentHeaderSub = styled(({ marginRight, ...rest }) => (
  <div {...rest} />
))`
  min-width: 100px;
  margin-right: ${props => props.marginRight};
  color: ${props => props.theme.fontColors.darker};
  text-align: right;
  }
`;

const PartTopicReportAssessmentDetail = ({
  assessmentLabel,
  assessmentRelativePercentage,
  assessmentParticipationPercentage,
  partTopicTotalPotentialScore,
  partTopicTotalRelativeScore
}) => {
  return (
    <AssessmentHeaderContainer paddingRight="44px">
      <AssessmentHeader>
        <AssessmentHeaderInfo>
          <TitleTooltip title={assessmentLabel} placement="top-start">
            <div>{assessmentLabel}</div>
          </TitleTooltip>
        </AssessmentHeaderInfo>
        <AssessmentHeaderData>
          <AssessmentHeaderSub marginRight="16px">
            {`${assessmentParticipationPercentage}%`}
          </AssessmentHeaderSub>
          <AssessmentHeaderSub marginRight="16px">
            {`${assessmentRelativePercentage}%`}
          </AssessmentHeaderSub>
          <AssessmentHeaderSub marginRight="16px">
            {partTopicTotalRelativeScore}
          </AssessmentHeaderSub>
          <AssessmentHeaderSub marginRight="8px">
            {partTopicTotalPotentialScore}
          </AssessmentHeaderSub>
        </AssessmentHeaderData>
      </AssessmentHeader>
    </AssessmentHeaderContainer>
  );
};

PartTopicReportAssessmentDetail.propTypes = {
  assessmentLabel: PropTypes.string,
  assessmentRelativePercentage: PropTypes.string,
  assessmentParticipationPercentage: PropTypes.string,
  partTopicTotalPotentialScore: PropTypes.string,
  partTopicTotalRelativeScore: PropTypes.string
};

PartTopicReportAssessmentDetail.defaultProps = {
  assessmentLabel: '',
  assessmentRelativePercentage: '',
  assessmentParticipationPercentage: '',
  partTopicTotalPotentialScore: '',
  partTopicTotalRelativeScore: ''
};

export default PartTopicReportAssessmentDetail;
