import { Model, attr, fk } from 'redux-orm';
import {
  GET_PROGRAM_CODES_SUCCESS,
  GET_SOURCE_PROGRAM_CODES_BY_TITLE_SUCCESS,
  GET_CREDENTIALS_SUCCESS
} from '../actions-type';

class ProgramCode extends Model {
  static reducer(action, ProgramCode, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_PROGRAM_CODES_SUCCESS: {
        const programCodes = [...payload.programCodes];
        programCodes.forEach(programCode => ProgramCode.upsert(programCode));
        break;
      }
      case GET_SOURCE_PROGRAM_CODES_BY_TITLE_SUCCESS: {
        const programCodes = [...payload.programCodes];
        programCodes.forEach(programCode => {
          const {
            uuid,
            title,
            code,
            description,
            published,
            disciplineUuid
          } = programCode;

          ProgramCode.upsert({
            uuid,
            title,
            code,
            description,
            published,
            disciplineUuid
          });
        });
        break;
      }

      case GET_CREDENTIALS_SUCCESS: {
        const programCodes = [...payload.programCodes];
        programCodes.forEach(programCode => ProgramCode.upsert(programCode));
        break;
      }

      default:
        return session;
    }
  }
}

ProgramCode.fields = {
  uuid: attr(),
  title: attr(),
  code: attr(),
  description: attr(),
  published: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  disciplineUuid: fk('ProgramDiscipline', 'program_codes')
};

ProgramCode.modelName = 'ProgramCode';
ProgramCode.options = {
  idAttribute: 'uuid'
};

export default ProgramCode;
