export const GET_SYLLABUS = 'GET_SYLLABUS';
export const GET_SYLLABUS_SUCCESS = 'GET_SYLLABUS_SUCCESS';
export const GET_SYLLABUS_ERROR = 'GET_SYLLABUS_ERROR';
export const SET_SELECTED_SYLLABUS = 'SET_SELECTED_SYLLABUS';
export const GET_SYLLABUS_COURSE = 'GET_SYLLABUS_COURSE';
export const GET_SYLLABUS_COURSE_SUCCESS = 'GET_SYLLABUS_COURSE_SUCCESS';
export const GET_SYLLABUS_COURSE_ERROR = 'GET_SYLLABUS_COURSE_ERROR';
export const GET_SYLLABUS_PART = 'GET_SYLLABUS_PART';
export const GET_SYLLABUS_PART_SUCCESS = 'GET_SYLLABUS_PART_SUCCESS';
export const GET_SYLLABUS_PART_ERROR = 'GET_SYLLABUS_PART_ERROR';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const SET_SELECTED_ITEM_RESET = 'SET_SELECTED_ITEM_RESET';
