import React, { Suspense, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import useSWR from 'swr';
import { CircularProgress } from '@material-ui/core';

import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import AttemptsReport from './AttemptsReport';
import AttemptsAccordionLabel from './AttemptsAccordionLabel';
import {
  qualitativeAssessmentTechniquesSelector,
  reportAssessmentTypesSelector
} from '../../../../redux/selectors/assessmentFieldSelectors';
import { doGetAssessmentTechniques } from '../../../../redux/actions/assessmentActions';
import { fetcher } from '../../../../helpers/utilities';
import ColumnOne from './ColumnOne';
import DidacticColumnOne from '../DidacticReport/DidacticColumnOne';

const Accordion = styled(AccordionRowContainer)`
  .accordion_row_label {
    display: flex;
    flex: ${({ isTablet }) => (!isTablet ? 1 : 'unset')};
  }

  & > div:hover > .hover-effect {
    background: ${props => props.theme.colors.grey[400]};
  }

  .hover-effect > div:nth-child(2) {
    flex-direction: ${({ isTablet }) => (isTablet ? 'column' : 'row')};
    justify-content: ${({ isTablet }) =>
      isTablet ? 'space-around' : 'flex-start'};
    align-items: ${({ isTablet }) => (isTablet ? 'flex-start' : 'center')};
  }

  .column_one {
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }

  .column_one > span {
    width: 100%;
  }
`;

const AttemptsAccordion = ({ userUuid, cohortUuid, isDidactic }) => {
  const dispatch = useDispatch();
  const [selectedAssessmentType, setSelectedAssessmentType] = useState(null);
  const [selectedAssessmentTechnique, setSelectedAssessmentTechnique] =
    useState(null);

  const assessmentTypes = useSelector(
    state => reportAssessmentTypesSelector(state, isDidactic),
    isEqual
  );

  const assessmentTechniques = useSelector(
    state => qualitativeAssessmentTechniquesSelector(state),
    isEqual
  );

  useEffect(() => {
    if (cohortUuid) {
      dispatch(doGetAssessmentTechniques());
    }
  }, [cohortUuid, dispatch]);

  const types = useMemo(
    () =>
      !selectedAssessmentType || selectedAssessmentType === 'all'
        ? assessmentTypes.map(at => at.uuid)
        : selectedAssessmentType,
    [selectedAssessmentType, assessmentTypes]
  );

  const techniques = useMemo(
    () =>
      !selectedAssessmentTechnique || selectedAssessmentTechnique === 'all'
        ? assessmentTechniques.map(at => at.uuid)
        : selectedAssessmentTechnique,
    [selectedAssessmentTechnique, assessmentTechniques]
  );

  const urlParams = new URLSearchParams({
    userUuid,
    cohortUuid,
    assessmentTechnique: techniques,
    assessmentType: types,
    isDidactic,
  });

  const { data, error, isLoading } = useSWR(
    assessmentTypes.length > 0 && assessmentTechniques.length > 0
      ? `/api/student-attempts-report?${urlParams.toString()}`
      : null,
    fetcher,
    {
      throwOnError: false
    }
  );

  return (
    <Accordion
      withBottomBorder={true}
      expandWidth="66px"
      height="90px"
      // eslint-disable-next-line
      columnOne={!data && isLoading 
        ? <CircularProgress />
        : isDidactic 
          ? <DidacticColumnOne data={data} displayHeader /> 
          : <ColumnOne data={data} displayHeader />
      }
      label={
        <AttemptsAccordionLabel
          isDidactic={isDidactic}
          assessmentTypes={
            assessmentTypes.length > 0
              ? [
                  { uuid: 'all', name: 'All assessment types' },
                  ...assessmentTypes
                ]
              : []
          }
          assessmentTechniques={
            assessmentTechniques.length > 0
              ? [
                  { uuid: 'all', name: 'All assessment techniques' },
                  ...assessmentTechniques
                ]
              : []
          }
          selectedAssessmentType={selectedAssessmentType}
          selectedAssessmentTechnique={selectedAssessmentTechnique}
          setSelectedAssessmentType={setSelectedAssessmentType}
          setSelectedAssessmentTechnique={setSelectedAssessmentTechnique}
        />
      }
    >
      <Suspense fallback={<LinearProgress />}>
        <AttemptsReport data={data} error={error} isLoading={isLoading} isDidactic={isDidactic} />
      </Suspense>
    </Accordion>
  );
};

AttemptsAccordion.propTypes = {
  userUuid: PropTypes.string.isRequired,
  cohortUuid: PropTypes.string.isRequired,
  isDidactic: PropTypes.bool,
};

AttemptsAccordion.defaultProps = {
  isDidactic: false,
};

export default AttemptsAccordion;
