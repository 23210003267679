import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonIcon } from '@xcomp/xcomp-design-library';
import { Tooltip } from '@material-ui/core';
import { BackArrowIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { useHistory } from 'react-router-dom';

const PageHeader = styled.div`
  padding-left: 70px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  background: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const HeaderMain = styled.div`
  position: relative;
  font-size: 2rem;
`;

const HeaderMainText = styled(({ noSubtitle, ...rest }) => <div {...rest} />)`
  position: relative;
  font-weight: 300;
  padding: ${props => (props.noSubtitle ? '16px 0px' : '0px')};
`;

const HeaderSub = styled.div``;

const HeaderSubText = styled.div`
  font-size: 14px;
  letter-spacing 2px;
  text-transform: uppercase;
  padding-left: 2px;
  font-weight: 300;
  color: ${props => props.theme.fontColors.dark};
`;

const Position = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: -3rem;
  top: 2px;
`;

const ArrowBackSC = styled(BackArrowIcon)`
  && {
    height: auto;
    width: 40px;
  }
`;

const Layout = styled.div`
  height: 70px;
  position: fixed;
  background: ${props => props.theme.colors.white};
  width: 100%;
  z-index: 1201;
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
`;

export default function Header({
  children,
  backButtonLocation,
  backButtonOverride,
  backOn,
  ...props
}) {
  const history = useHistory();
  return (
    <Layout {...props}>
      <PageHeader>
        <HeaderSub>
          <HeaderSubText>{props.subtitle}</HeaderSubText>
        </HeaderSub>
        <HeaderMain>
          {backOn && (
            <Tooltip title="Back" placement="bottom">
              <Position>
                {backButtonOverride ? (
                  <ButtonIcon onClick={backButtonOverride}>
                    <ArrowBackSC />
                  </ButtonIcon>
                ) : (
                  <ButtonIcon
                    onClick={() =>
                      backButtonLocation
                        ? history.push(backButtonLocation)
                        : history.goBack()
                    }
                  >
                    <ArrowBackSC />
                  </ButtonIcon>
                )}
              </Position>
            </Tooltip>
          )}
          <HeaderMainText>{props.title}</HeaderMainText>
        </HeaderMain>
        {children}
      </PageHeader>
    </Layout>
  );
}

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  link: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  tooltip: PropTypes.string,
  backOn: PropTypes.bool,
  backButtonOverride: PropTypes.func,
  backButtonLocation: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hideBackButton: PropTypes.bool
};
Header.defaultProps = {
  children: null,
  link: '/',
  title: '',
  subtitle: '',
  tooltip: '',
  backOn: true,
  backButtonOverride: undefined,
  backButtonLocation: false,
  hideBackButton: false
};
