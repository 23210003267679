import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { doGetUsersBySearch } from '../../../redux/actions/userActions';

import UserTabView from './UserTabView';

export default function UserTabViewContainer() {
  const [openUpload, setOpenUpload] = useState(false);
  const [openUserAssignModal, setOpenUserAssignModal] = useState(false);
  const [goToAddUser, setGoToAddUser] = useState(false);
  const searchUserBy = useSelector(state => state.searchState.searchUserBy);

  const searchUserInput = useSelector(
    state => state.searchState.searchUserInput
  );

  const searching = useSelector(state => state.crudState.readUsersBySearch);

  const dispatch = useDispatch();

  function handleUserUploadOpen() {
    setOpenUpload(true);
  }

  function handleUserAssignOpen() {
    setOpenUserAssignModal(true);
  }

  function handleUserUploadClose() {
    setOpenUpload(false);
  }

  function handleUserAssignClose() {
    setOpenUserAssignModal(false);
  }

  function handleSearch(field, input) {
    dispatch(doGetUsersBySearch(field, input));
  }

  function handleGoToAddUser() {
    setGoToAddUser(true);
  }

  return (
    <UserTabView
      openUpload={openUpload}
      openUserAssignModal={openUserAssignModal}
      searchUserBy={searchUserBy}
      searchUserInput={searchUserInput}
      searching={searching}
      goToAddUser={goToAddUser}
      handleGoToAddUser={() => handleGoToAddUser()}
      handleUserUploadOpen={() => handleUserUploadOpen()}
      handleUserAssignOpen={() => handleUserAssignOpen()}
      handleUserUploadClose={() => handleUserUploadClose()}
      handleUserAssignClose={() => handleUserAssignClose()}
      handleSearch={(field, input) => handleSearch(field, input)}
    />
  );
}
