const initialProgramAssessmentMasterState = {
  uuid: '',
  title: '',
  scoreType: 'Attempt',
  assessmentTypeUuid: null,
  assessmentAssociationUuid: null,
  optionsAssessmentTypes: [],
  optionsAssessmentAssociations: [],
  showValidationErrors: false
};

const programAssessmentMasterReducer = (state, action) => {
  switch (action.type) {
    case 'setExisting': {
      const {
        uuid,
        title,
        scoreType,
        assessmentTypeUuid,
        assessmentAssociationUuid
      } = action.payload;

      return {
        ...state,
        uuid,
        title,
        scoreType,
        assessmentTypeUuid,
        assessmentAssociationUuid
      };
    }
    case 'setTitle':
      return { ...state, title: action.payload.title };
    case 'setScoreType':
      return { ...state, scoreType: action.payload.scoreType };
    case 'setAssessmentAssociation':
      return {
        ...state,
        assessmentAssociationUuid: action.payload.assessmentAssociationUuid
      };
    case 'setAssessmentType':
      return {
        ...state,
        assessmentTypeUuid: action.payload.assessmentTypeUuid
      };
    case 'setAssessmentTypes':
      return {
        ...state,
        optionsAssessmentTypes: action.payload.optionsAssessmentTypes
      };
    case 'setAssessmentAssociations': {
      return {
        ...state,
        optionsAssessmentAssociations:
          action.payload.optionsAssessmentAssociations
      };
    }
    case 'setShowValidationErrors':
      return {
        ...state,
        showValidationErrors: action.payload.showValidationErrors
      };
    case 'clearForm':
      return initialProgramAssessmentMasterState;
    default: {
      throw new Error();
    }
  }
};

const setShowValidationErrors = showValidationErrors => ({
  type: 'setShowValidationErrors',
  payload: { showValidationErrors }
});

export {
  initialProgramAssessmentMasterState,
  programAssessmentMasterReducer,
  setShowValidationErrors
};
