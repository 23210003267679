import React, { useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tab, Tabs } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  CompetencyIcon,
  DeleteIcon
} from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonDelete, ButtonPrimary } from '@xcomp/xcomp-design-library';

import { ContainerPage, ContainerResize } from '../../Library/Layout';
import Header from '../../Library/Header';
import SectionPerformanceReportContainer from './SectionPerformanceReport/SectionPerformanceReportContainer';
import { useDelete } from '../../Library/Modal/DeleteModal/useDelete';
import DeleteModal from '../../Library/Modal/DeleteModal/DeleteModal';
import { doDeleteSectionLegacyGrades } from '../../../redux/actions/legacyGradingActions';
import SectionLegacyGradeUploadContainer from './SectionLegacyGradeUpload/SectionLegacyGradeUploadContainer';
import SectionPerformanceOpportunityReport from './SectionPerformanceReport/SectionPerformanceOpportunityReport';
import SectionSelfAssessmentReportContainer from './SectionSelfAssessmentReport/SectionSelfAssessmentReportContainer';
import SectionAttemptReport from './SectionAttemptReport/SectionAttemptReport';
import SectionDidacticReport from './SectionDidacticReport/SectionAttemptReport';

const ButtonDeleteSC = styled(ButtonDelete)`
  && {
    margin-right: 1rem;
  }
`;

const TabsSC = styled(Tabs)`
  background-color: ${props => props.theme.colors.white};
  padding-top: 77px;
`;

const ContainerPageSC = styled(ContainerPage)`
  padding-top: 48px;
`;

const ButtonRow = styled.div`
  margin: 0;
  display: flex;
  justify-content: flex-end;
`;

const getGradedStudents = sectionPerformanceReport => {
  if (!sectionPerformanceReport) {
    return 0;
  }

  let numberOfGradedStudents = 0;

  for (let i = 0; i < sectionPerformanceReport.length; i += 1) {
    const { calculatedGrade, letterGrade } = sectionPerformanceReport[i];
    if (calculatedGrade && letterGrade) {
      numberOfGradedStudents += 1;
    }
  }

  return numberOfGradedStudents;
};

const SectionPerformanceSummary = ({
  basePath,
  courseCredits,
  courseNumber,
  courseTitle,
  currentTab,
  onBackAction,
  parts,
  path,
  sectionNumber,
  sectionUuid,
  section,
  termPeriod
}) => {
  const dispatch = useDispatch();

  const [openUploadScores, setOpenUploadScores] = useState(false);

  const onDeleteLegacyGrades = () =>
    dispatch(doDeleteSectionLegacyGrades(sectionUuid));

  const [
    isDeleteModalOpen,
    handleDeleteClick,
    handleDeleteModalClose,
    handleDelete
  ] = useDelete(onDeleteLegacyGrades);

  const handleUploadScoresOpen = () => {
    setOpenUploadScores(true);
  };

  const handleUploadClose = () => {
    setOpenUploadScores(false);
  };

  const sectionPerformanceReportState = useSelector(
    state => state.sectionPerformanceReportState
  );

  const headerSubtitle = `${courseNumber} ● ${courseTitle}`;

  const numberGradedStudents = getGradedStudents(
    sectionPerformanceReportState?.sectionPerformanceReport
  );
  const showDeleteButton =
    section?.dataSource === 'LEGACY' && numberGradedStudents > 0;

  return (
    <ContainerResize>
      <Header
        title={`Section ${sectionNumber} - Performance Summary`}
        subtitle={headerSubtitle}
        backButtonOverride={onBackAction}
      />
      <TabsSC
        value={currentTab}
        textColor="primary"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab component={Link} to={`${basePath}/default`} label="default" />
        <Tab
          component={Link}
          to={`${basePath}/transcript`}
          label="transcript"
        />
        <Tab component={Link} to={`${basePath}/attempt`} label="Attempt" />
        <Tab component={Link} to={`${basePath}/didactic`} label="didactic" />
        <Tab
          component={Link}
          to={`${basePath}/opportunity`}
          label="Opportunity"
        />
        <Tab
          component={Link}
          to={`${basePath}/self-assessment`}
          label="self assessment"
        />
      </TabsSC>

      <SectionLegacyGradeUploadContainer
        openUploadScores={openUploadScores}
        handleUploadClose={handleUploadClose}
        sectionUuid={sectionUuid}
      />

      {section?.dataSource === 'LEGACY' && (
        <ContainerPage paddingTop="32px">
          <ButtonRow>
            {showDeleteButton && (
              <>
                <ButtonDeleteSC onClick={handleDeleteClick}>
                  Delete Grades&nbsp;
                  <DeleteIcon />
                </ButtonDeleteSC>
                <DeleteModal
                  modalOpen={isDeleteModalOpen}
                  handleModalClose={handleDeleteModalClose}
                  handleDelete={handleDelete}
                  type="section's legacy grades"
                />
              </>
            )}
            <ButtonPrimary onClick={handleUploadScoresOpen}>
              Upload Legacy Grades&nbsp;
              <CompetencyIcon />
            </ButtonPrimary>
          </ButtonRow>
        </ContainerPage>
      )}

      <ContainerPageSC>
        <Switch>
          <Route path={`${path}/default`}>
            <SectionPerformanceReportContainer
              courseCredits={courseCredits}
              parts={parts}
              reportView="default"
              sectionUuid={sectionUuid}
              termPeriod={termPeriod}
              courseNumber={courseNumber}
              courseTitle={courseTitle}
              sectionNumber={sectionNumber}
            />
          </Route>
          <Route path={`${path}/transcript`}>
            <SectionPerformanceReportContainer
              courseCredits={courseCredits}
              parts={parts}
              reportView="transcript"
              sectionUuid={sectionUuid}
              termPeriod={termPeriod}
              courseNumber={courseNumber}
              courseTitle={courseTitle}
              sectionNumber={sectionNumber}
            />
          </Route>
          <Route path={`${path}/attempt`}>
            <SectionAttemptReport sectionUuid={sectionUuid} />
          </Route>
          <Route path={`${path}/didactic`}>
            <SectionDidacticReport sectionUuid={sectionUuid} />
          </Route>
          <Route path={`${path}/opportunity`}>
            <SectionPerformanceOpportunityReport
              courseCredits={courseCredits}
              parts={parts}
              reportView="opportunity"
              sectionUuid={sectionUuid}
              termPeriod={termPeriod}
              courseNumber={courseNumber}
              courseTitle={courseTitle}
              sectionNumber={sectionNumber}
            />
          </Route>
          <Route path={`${path}/self-assessment`}>
            <SectionSelfAssessmentReportContainer sectionUuid={sectionUuid} />
          </Route>
        </Switch>
      </ContainerPageSC>
    </ContainerResize>
  );
};

SectionPerformanceSummary.propTypes = {
  courseCredits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  basePath: PropTypes.string,
  courseNumber: PropTypes.string,
  courseTitle: PropTypes.string,
  currentTab: PropTypes.number,
  onBackAction: PropTypes.func,
  section: PropTypes.object,
  sectionUuid: PropTypes.string,
  parts: PropTypes.array,
  path: PropTypes.string,
  sectionNumber: PropTypes.string,
  termPeriod: PropTypes.string
};
SectionPerformanceSummary.defaultProps = {
  courseCredits: '',
  basePath: '',
  courseNumber: '',
  courseTitle: '',
  section: {},
  currentTab: 0,
  onBackAction: undefined,
  sectionUuid: '',
  parts: [],
  path: '',
  sectionNumber: '',
  termPeriod: ''
};

export default SectionPerformanceSummary;
