import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const syllabusCourseSelector = createSelector(
  state => state,
  state => state.syllabusState.selectedCourseUuid,
  (redux, selectedCourseUuid) => {
    const couresSelectorORM = ormCreateSelector(orm.Course);
    const course = couresSelectorORM(redux, selectedCourseUuid);

    return course;
  }
);

export const selectSyllabusItems = createSelector(
  state => state,
  (_, partUuid) => partUuid,
  (redux, partUuid) => {
    const getEncounters = ormCreateSelector(orm.Encounter);
    const getAssessments = ormCreateSelector(orm.Assessment);

    const encounters = getEncounters(redux).filter(
      encounter => encounter.partUuid === partUuid
    );

    const assessments = getAssessments(redux).filter(
      assessment => assessment.partUuid === partUuid
    );

    const items = [];

    if (assessments?.length > 0) {
      items.push(...assessments);
    }

    if (encounters?.length > 0) {
      items.push(...encounters);
    }

    return items;
  }
);
