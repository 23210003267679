import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';

import { CloneIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import { formatMMddyyyy } from '../../../helpers/date-fns.utils';
import { formatLastName } from '../../../helpers/format/user.format';

import {
  ContainerResize,
  ContainerPage,
  ElevateLow,
  ContainerActions
} from '../../Library/Layout';

import Header from '../../Library/Header';
import FormRead from '../../Library/FormRead';
import TranscriptCategory from './TranscriptList/TranscriptCategory';
import TranscriptCloneContainer from './TranscriptClone/TranscriptCloneContainer';
import { roles } from '../../../helpers/constants';

const SectionHeader = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Page = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const IconSpacing = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;

const ButtonText = styled.div`
  padding-left: 10px;
`;

export default function TranscriptApplicant({
  user,
  ageOnApplication,
  cohortUuid,
  programUuid,
  openClone,
  handleCloneOpen,
  handleCloneClose
}) {
  const selectedRole = useSelector(state => state.userState.selectedRole);
  const isAdmin = selectedRole === roles.programAdmin;

  return (
    <ContainerResize>
      <Header title="Applicant Transcripts" subtitle={isAdmin ? "Add/Edit/Delete" : "Applicant Transcript"} />
      {isAdmin 
        ?
          <ContainerActions>
            <IconSpacing>
              <Tooltip
                title="Select cohort to copy application transcript"
                placement="top-start"
              >
                <ButtonPrimary onClick={handleCloneOpen}>
                  <CloneIcon />
                  <ButtonText>Copy Transcript</ButtonText>
                </ButtonPrimary>
              </Tooltip>
            </IconSpacing>
          </ContainerActions>
        :
          <div style={{paddingTop: 80}} />
      }
      <ContainerPage paddingTop="30px">
        <ElevateLow>
          <SectionHeader>Applicant Details</SectionHeader>
          <Page>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={3}>
                <FormRead
                  label="Institution ID"
                  value={`${user?.institutionId}`}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormRead
                  label="Last Name"
                  valueFontWeight="800"
                  value={formatLastName(
                    user?.lastName,
                    user?.marriedLastName,
                    user?.suffix
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormRead
                  label="First Name"
                  value={`${_.get(user, ['firstName'], '')}`}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormRead
                  label="Middle Name"
                  value={`${_.get(user, ['middleName'], '')}`}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormRead
                  label="Date of Birth"
                  value={formatMMddyyyy(user?.birthDate)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormRead label="Age at Application" value={ageOnApplication} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormRead
                  label="Gender"
                  value={`${_.get(user, ['gender'], 'Unreported')}`}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormRead
                  label="Ethnicity"
                  value={`${_.get(user, ['ethnicity'], 'Unreported')}`}
                />
              </Grid>
            </Grid>
          </Page>
        </ElevateLow>
      </ContainerPage>

      <TranscriptCategory
        userUuid={user?.uuid}
        cohortUuid={cohortUuid}
        programUuid={programUuid}
        isAdmin={isAdmin}
      />
      {isAdmin && 
        <TranscriptCloneContainer
          openModal={openClone}
          userUuid={user?.uuid}
          programUuid={programUuid}
          cohortUuid={cohortUuid}
          handleCloneClose={handleCloneClose}
        />
      }
    </ContainerResize>
  );
}

TranscriptApplicant.propTypes = {
  openClone: PropTypes.bool,
  user: PropTypes.object,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  ageOnApplication: PropTypes.string,
  handleCloneOpen: PropTypes.func,
  handleCloneClose: PropTypes.func
};

TranscriptApplicant.defaultProps = {
  openClone: false,
  user: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  ageOnApplication: '',
  handleCloneOpen: undefined,
  handleCloneClose: undefined
};
