import {
  GET_PROGRAM,
  GET_PROGRAM_SUCCESS,
  GET_PROGRAM_ERROR,
  GET_PROGRAM_ASSESSMENTS,
  GET_PROGRAM_ASSESSMENTS_SUCCESS,
  GET_PROGRAM_ASSESSMENTS_ERROR,
  GET_PROGRAM_ASSESSMENT,
  GET_PROGRAM_ASSESSMENT_SUCCESS,
  GET_PROGRAM_ASSESSMENT_ERROR,
  GET_PROGRAMS,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_ERROR,
  GET_SCHOOL_PROGRAMS,
  GET_SCHOOL_PROGRAMS_SUCCESS,
  GET_SCHOOL_PROGRAMS_ERROR,
  POST_PROGRAM,
  POST_PROGRAM_SUCCESS,
  POST_PROGRAM_ERROR,
  POST_PROGRAM_ASSESSMENT_COLLECTION,
  POST_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS,
  POST_PROGRAM_ASSESSMENT_COLLECTION_ERROR,
  PUT_PROGRAM,
  PUT_PROGRAM_SUCCESS,
  PUT_PROGRAM_ERROR,
  PUT_PROGRAM_ASSESSMENT_COLLECTION,
  PUT_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS,
  PUT_PROGRAM_ASSESSMENT_COLLECTION_ERROR,
  DELETE_PROGRAM,
  DELETE_PROGRAM_SUCCESS,
  DELETE_PROGRAM_ERROR,
  DELETE_PROGRAM_ASSESSMENT_COLLECTION,
  DELETE_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS,
  DELETE_PROGRAM_ASSESSMENT_COLLECTION_ERROR,
  DELETE_PROGRAM_ASSESSMENT_MASTER,
  DELETE_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  DELETE_PROGRAM_ASSESSMENT_MASTER_ERROR,
  POST_PROGRAM_ASSESSMENT_MASTER,
  POST_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  POST_PROGRAM_ASSESSMENT_MASTER_ERROR,
  PUT_PROGRAM_ASSESSMENT_MASTER,
  PUT_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  PUT_PROGRAM_ASSESSMENT_MASTER_ERROR
} from '../actions-type';

const doGetProgram = programUuid => ({
  type: GET_PROGRAM,
  payload: {
    programUuid
  }
});

const doGetProgramSuccess = result => ({
  type: GET_PROGRAM_SUCCESS,
  payload: result
});

const doGetProgramError = error => ({
  type: GET_PROGRAM_ERROR,
  payload: error
});

const doGetPrograms = () => ({
  type: GET_PROGRAMS
});

const doGetProgramsSuccess = result => ({
  type: GET_PROGRAMS_SUCCESS,
  payload: result
});

const doGetProgramsError = error => ({
  type: GET_PROGRAMS_ERROR,
  payload: error
});

const doGetProgramAssessment = (programUuid, uuid) => ({
  type: GET_PROGRAM_ASSESSMENT,
  payload: {
    programUuid,
    uuid
  }
});

const doGetProgramAssessmentSuccess = result => ({
  type: GET_PROGRAM_ASSESSMENT_SUCCESS,
  payload: result
});

const doGetProgramAssessmentError = error => ({
  type: GET_PROGRAM_ASSESSMENT_ERROR,
  payload: error
});

const doGetProgramAssessments = programUuid => ({
  type: GET_PROGRAM_ASSESSMENTS,
  payload: {
    programUuid
  }
});

const doGetProgramAssessmentsSuccess = result => ({
  type: GET_PROGRAM_ASSESSMENTS_SUCCESS,
  payload: result
});

const doGetProgramAssessmentsError = error => ({
  type: GET_PROGRAM_ASSESSMENTS_ERROR,
  payload: error
});

const doGetSchoolPrograms = schoolUuid => ({
  type: GET_SCHOOL_PROGRAMS,
  payload: {
    schoolUuid
  }
});

const doGetSchoolProgramsSuccess = result => ({
  type: GET_SCHOOL_PROGRAMS_SUCCESS,
  payload: result
});

const doGetSchoolProgramsError = error => ({
  type: GET_SCHOOL_PROGRAMS_ERROR,
  payload: error
});

const doPostProgram = program => ({
  type: POST_PROGRAM,
  payload: {
    program
  }
});

const doPostProgramSuccess = result => ({
  type: POST_PROGRAM_SUCCESS,
  payload: result
});

const doPostProgramError = error => ({
  type: POST_PROGRAM_ERROR,
  payload: error
});

const doPostProgramAssessmentCollection = ({ title, programUuid }) => ({
  type: POST_PROGRAM_ASSESSMENT_COLLECTION,
  payload: {
    title,
    programUuid
  }
});

const doPostProgramAssessmentCollectionSuccess = result => ({
  type: POST_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS,
  payload: result
});

const doPostProgramAssessmentCollectionError = error => ({
  type: POST_PROGRAM_ASSESSMENT_COLLECTION_ERROR,
  payload: error
});

const doPostProgramAssessmentMaster = programAssessmentMaster => ({
  type: POST_PROGRAM_ASSESSMENT_MASTER,
  payload: {
    ...programAssessmentMaster
  }
});

const doPostProgramAssessmentMasterSuccess = result => ({
  type: POST_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  payload: result
});

const doPostProgramAssessmentMasterError = error => ({
  type: POST_PROGRAM_ASSESSMENT_MASTER_ERROR,
  payload: error
});

const doPutProgram = program => ({
  type: PUT_PROGRAM,
  payload: {
    program
  }
});

const doPutProgramSuccess = result => ({
  type: PUT_PROGRAM_SUCCESS,
  payload: result
});

const doPutProgramError = error => ({
  type: PUT_PROGRAM_ERROR,
  payload: error
});

const doPutProgramAssessmentCollection = programAssessmentCollection => ({
  type: PUT_PROGRAM_ASSESSMENT_COLLECTION,
  payload: {
    ...programAssessmentCollection
  }
});

const doPutProgramAssessmentCollectionSuccess = result => ({
  type: PUT_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS,
  payload: result
});

const doPutProgramAssessmentCollectionError = error => ({
  type: PUT_PROGRAM_ASSESSMENT_COLLECTION_ERROR,
  payload: error
});

const doPutProgramAssessmentMaster = programAssessmentMaster => ({
  type: PUT_PROGRAM_ASSESSMENT_MASTER,
  payload: {
    ...programAssessmentMaster
  }
});

const doPutProgramAssessmentMasterSuccess = result => ({
  type: PUT_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  payload: result
});

const doPutProgramAssessmentMasterError = error => ({
  type: PUT_PROGRAM_ASSESSMENT_MASTER_ERROR,
  payload: error
});

const doDeleteProgram = programUuid => ({
  type: DELETE_PROGRAM,
  payload: {
    programUuid
  }
});

const doDeleteProgramSuccess = result => ({
  type: DELETE_PROGRAM_SUCCESS,
  payload: result
});

const doDeleteProgramError = error => ({
  type: DELETE_PROGRAM_ERROR,
  payload: error
});

const doDeleteProgramAsssessmentCollection = ({ programUuid, uuid }) => ({
  type: DELETE_PROGRAM_ASSESSMENT_COLLECTION,
  payload: {
    programUuid,
    uuid
  }
});

const doDeleteProgramAsssessmentCollectionSuccess = result => ({
  type: DELETE_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS,
  payload: result
});

const doDeleteProgramAsssessmentCollectionError = error => ({
  type: DELETE_PROGRAM_ASSESSMENT_COLLECTION_ERROR,
  payload: error
});

const doDeleteProgramAsssessmentMaster = ({ programUuid, uuid }) => ({
  type: DELETE_PROGRAM_ASSESSMENT_MASTER,
  payload: {
    programUuid,
    uuid
  }
});

const doDeleteProgramAsssessmentMasterSuccess = result => ({
  type: DELETE_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  payload: result
});

const doDeleteProgramAsssessmentMasterError = error => ({
  type: DELETE_PROGRAM_ASSESSMENT_MASTER_ERROR,
  payload: error
});

export {
  doGetProgram,
  doGetProgramSuccess,
  doGetProgramError,
  doGetProgramAssessment,
  doGetProgramAssessmentSuccess,
  doGetProgramAssessmentError,
  doGetProgramAssessments,
  doGetProgramAssessmentsSuccess,
  doGetProgramAssessmentsError,
  doGetPrograms,
  doGetProgramsSuccess,
  doGetProgramsError,
  doGetSchoolPrograms,
  doGetSchoolProgramsSuccess,
  doGetSchoolProgramsError,
  doPostProgram,
  doPostProgramSuccess,
  doPostProgramError,
  doPostProgramAssessmentCollection,
  doPostProgramAssessmentCollectionSuccess,
  doPostProgramAssessmentCollectionError,
  doPostProgramAssessmentMaster,
  doPostProgramAssessmentMasterSuccess,
  doPostProgramAssessmentMasterError,
  doPutProgram,
  doPutProgramSuccess,
  doPutProgramError,
  doPutProgramAssessmentCollection,
  doPutProgramAssessmentCollectionSuccess,
  doPutProgramAssessmentCollectionError,
  doPutProgramAssessmentMaster,
  doPutProgramAssessmentMasterSuccess,
  doPutProgramAssessmentMasterError,
  doDeleteProgram,
  doDeleteProgramSuccess,
  doDeleteProgramError,
  doDeleteProgramAsssessmentCollection,
  doDeleteProgramAsssessmentCollectionSuccess,
  doDeleteProgramAsssessmentCollectionError,
  doDeleteProgramAsssessmentMaster,
  doDeleteProgramAsssessmentMasterSuccess,
  doDeleteProgramAsssessmentMasterError
};
