import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { Caption, Body1 } from '@xcomp/xcomp-design-library';
import LinearProgress from '@material-ui/core/LinearProgress';
import SectionContainer from '../Section/SectionContainer';

import { doGetSectionNestedByCourse } from '../../../../redux/actions/sectionActions';
import { sectionsByCourseSelector } from '../../../../redux/selectors/sectionsSelectors';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import {
  doSetClosedCourseAccordion,
  doSetOpenCourseAccordion
} from '../../../../redux/actions/filterActions';
import DescriptionDisplay from '../../../Library/DescriptionEditor/DescriptionDisplay';

const DescriptionSection = styled.div`
  width: 100%;
  padding-left: 174px;
  margin-bottom: 24px;
  color: ${props => props.theme.fontColors.darker};
  font-weight: 400;
  width: 100%;
  font-size: 1rem;
`;

const CaptionLabel = styled(Caption)`
  margin-bottom: 0;
`;

const DescriptionText = styled(Body1)`
  margin-top: 0;
  color: ${props => props.theme.fontColors.darker};
  word-break: break-word;
`;

const ProgressBar = styled.div`
  width: 100%;
`;

const CourseSectionAccordion = styled(
  ({ withBottomBorder, withTopBorder, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  width: 100%;

  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .section_title span, .accordion_row_label div {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.fontColors.darker};
  }

  .section_title {
    flex-grow: 2;
  }
  .accordion_row_label {
    min-width: 100px;
  }

  .section_completion span {
    font-size: 12px;
    color: ${props => props.theme.fontColors.dark};
  }
`;

const CoursePanel = ({ course, title, courseNumber, description }) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line prefer-const
  let history = useHistory();

  const navigateEditOnClick = () => {
    const historyString = `/syllabus-management/course/edit/${course.uuid}`;
    history.push(historyString);
  };

  const sections = useSelector(
    state => sectionsByCourseSelector(state, course.uuid),
    _.isEqual
  );

  const sectionsLoading = useSelector(state =>
    _.get(state, `fetchSectionsState.${course.uuid}`, false)
  );

  const openCourseAccordions = useSelector(
    state => state.filterCourseState.openCourseAccordions
  );

  // function completionRate() {
  //   let complete = 0;
  //   let outstanding = 0;

  //   if (sections.length > 0) {
  //     _.each(sections, section => {
  //       if (section.parts) {
  //         outstanding += section.parts.length;
  //         _.each(section.parts, part => {
  //           if (part.status === 'Published') {
  //             complete += 1;
  //           }
  //         });
  //       }
  //     });
  //   }

  //   return `${complete}/${outstanding} part(s) published`;
  // }

  const courseSectionActions = [
    {
      label: 'Edit',
      onClick: navigateEditOnClick,
      shouldOpenOnClick: false,
      icon: 'edit'
    }
    // {
    //   label: 'Delete',
    //   onClick: handleDeleteClick,
    //   shouldOpenOnClick: false,
    //   icon: 'delete'
    // }
  ];

  const onFetchSection = () =>
    dispatch(doGetSectionNestedByCourse(course.uuid));

  const onRowOpen = () => {
    onFetchSection();
    dispatch(doSetOpenCourseAccordion(course.uuid));
  };

  const onRowClose = () => {
    dispatch(doSetClosedCourseAccordion(course.uuid));
  };

  const courseUuid = _.get(course, 'uuid');
  const shouldOpenSection = _.get(openCourseAccordions, courseUuid);

  return (
    <CourseSectionAccordion
      isTopLevel
      withBottomBorder
      expandWidth="66px"
      height="48px"
      label={courseNumber}
      columnOne={title}
      columnOneClassName="section_title"
      // columnTwo={completionRate()}
      // columnTwoClassName="section_completion"
      actions={courseSectionActions}
      isPublished={course.locked}
      onRowOpen={onRowOpen}
      onRowClose={onRowClose}
      shouldStartOpen={Boolean(shouldOpenSection)}
    >
      <DescriptionSection>
        <CaptionLabel>Course Description</CaptionLabel>
        <DescriptionText>
          <DescriptionDisplay description={description} />
        </DescriptionText>
      </DescriptionSection>
      {sectionsLoading && sections?.length < 1 && (
        <ProgressBar>
          <LinearProgress />
        </ProgressBar>
      )}
      {sections?.map(section => (
        <SectionContainer
          key={section?.uuid}
          sectionUuid={section?.uuid}
          courseUuid={section?.courseUuid}
          onFetchSection={onFetchSection}
        />
      ))}
    </CourseSectionAccordion>
  );
};

CoursePanel.propTypes = {
  title: PropTypes.string,
  courseNumber: PropTypes.string,
  description: PropTypes.string,
  course: PropTypes.object
};

CoursePanel.defaultProps = {
  title: '',
  courseNumber: '',
  description: '',
  course: {}
};

export default CoursePanel;
