import React from 'react';

import PropTypes from 'prop-types';

import ByTermCognitiveSource from './ByTermCognitiveSource';

export default function ByTermCognitiveSourceContainer({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  termYear
}) {
  const sources = ['Program', 'Milestone'];

  return (
    <>
      {sources.map(source => (
        <ByTermCognitiveSource
          key={source}
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
          termYear={termYear}
        />
      ))}
    </>
  );
}

ByTermCognitiveSourceContainer.propTypes = {
  category: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  termYear: PropTypes.string
};
ByTermCognitiveSourceContainer.defaultProps = {
  category: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  termYear: undefined
};
