import React from 'react';
import PropTypes from 'prop-types';
import AssessmentCardContainer from './AssessmentCard/AssessmentCardContainer';
import EncounterCardContainer from './EncounterCard/EncounterCardContainer';
import SyllabusFilter from './SyllabusFilter';

export default function SyllabusItems({
  items,
  selectedItem,
  selectSyllabusItem,
  filterType,
  handleFilterType
}) {
  let encounterIndex = 0;
  let assessmentIndex = 0;

  return (
    <>
      <SyllabusFilter
        filterType={filterType}
        handleFilterType={handleFilterType}
      />
      {items.map(item => {
        let itemNumber;

        if (item.type === 'encounter') {
          itemNumber = encounterIndex;
          encounterIndex += 1;
        } else if (item.type === 'assessment') {
          itemNumber = assessmentIndex;
          assessmentIndex += 1;
        }

        if (item.type === 'assessment') {
          return (
            <AssessmentCardContainer
              key={item.uuid}
              assessmentUuid={item.uuid}
              itemNumber={itemNumber}
              selectedItem={selectedItem}
              selectSyllabusItem={selectSyllabusItem}
            />
          );
        } else if (item.type === 'encounter') {
          return (
            <EncounterCardContainer
              key={item.uuid}
              encounterUuid={item.uuid}
              itemNumber={itemNumber}
              selectedItem={selectedItem}
              selectSyllabusItem={selectSyllabusItem}
            />
          );
        } else {
          return <></>;
        }
      })}
    </>
  );
}

SyllabusItems.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.string,
  selectSyllabusItem: PropTypes.func,
  filterType: PropTypes.string,
  handleFilterType: PropTypes.func
};

SyllabusItems.defaultProps = {
  items: [],
  selectedItem: undefined,
  selectSyllabusItem: undefined,
  filterType: 'all',
  handleFilterType: undefined
};
