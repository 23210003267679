import React from 'react';
import styled from 'styled-components';
import { Body2, Subtitle1 } from '@xcomp/xcomp-design-library';

export const ColumnLabel = styled(Body2)`
  margin: 0;
`;

export const BannerIdColumn = styled.div`
  width: 20%;
`;

export const NameColumn = styled(({ columnWidth, ...rest }) => (
  <div {...rest} />
))`
  width: ${props => props.columnWidth || '70%'};
`;

export const LetterGradeColumn = styled.div`
  width: 10%;
`;

export const CalculatedGradeColumn = styled.div`
  width: 10%;
`;

export const Subtitle1SC = styled(({ criticalFailure, ...rest }) => (
  <Subtitle1 {...rest} />
))`
  color: ${props =>
    props.criticalFailure
      ? props.theme.colors.status.error
      : props.theme.fontColors.darker};
`;

export const getNameColumnWidth = (isPointsOnly, isDelayed) => {
  if (isPointsOnly && isDelayed) {
    return '60%';
  } else if (isDelayed) {
    return '50%';
  } else if (isPointsOnly) {
    return '70%';
  }

  return '60%';
};
