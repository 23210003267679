import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';



export const educationLevelSelectorByMatchParams = createSelector(
  state => state,
  (_, props) => props.match.params.educationLevelUuid,
  (redux, educationLevelUuid) => {
    const educationLevelsSelectorORM = ormCreateSelector(
      orm.EducationLevel,
      orm.EducationLevel.loverbs,
      (educationLevel, loverbsCollecton) => {
        const loverbs = loverbsCollecton !== null ? loverbsCollecton : [];

        return {
          ...educationLevel,
          loverbs
        };
      }
    );

    const educationLevel = educationLevelsSelectorORM(
      redux,
      educationLevelUuid
    );

    return educationLevel;
  }
);

export const educationLevelsSelectorBySchool = createSelector(
  state => state,
  state => state.userState.selectedSchoolUuid,
  (redux, selectedSchoolUuid) => {
    const educationLevelSelectorORM = ormCreateSelector(orm.EducationLevel);
    const educationLevels = educationLevelSelectorORM(redux).filter(
      el => el.schoolUuid === selectedSchoolUuid
    );
    return educationLevels;
  }
);

export const educationLevelByVerbSelector = createSelector(
  state => state,
  (_, loverbUuid) => loverbUuid,
  (redux, loverbUuid) => {
    const loverbsSelectorORM = ormCreateSelector(orm.Loverb);
    const loverb = loverbsSelectorORM(redux, loverbUuid);
    const educationLevelUuid = _.get(loverb, 'educationLevelUuid');

    if (educationLevelUuid) {
      const educationLevelsSelectorORM = ormCreateSelector(orm.EducationLevel);
      const educationLevel = educationLevelsSelectorORM(
        redux,
        educationLevelUuid
      );

      return educationLevel;
    } else {
      return {};
    }
  }
);
