import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonPin from '@material-ui/icons/PersonPin';
import Cancel from '@material-ui/icons/Cancel';
import {
  ButtonPrimary,
  ButtonOutline,
  Body1
} from '@xcomp/xcomp-design-library';
import Modal from '../Modal';
import { TextFieldWithError } from '../../FormComponents';
import { validateCompetencyDescription } from '../../../../helpers/validation/validateCompetencyCollection';

const JustificationTextFieldWithError = styled(TextFieldWithError)`
  && {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 900px;
`;

const DangerText = styled.span`
  color: ${props => props.theme.colors.status.error};
  font-weight: 500;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  margin-bottom: 20px;
`;

const FileUpload = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Note = styled(Body1)`
  margin-top: 10px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const CloseButton = styled(ButtonOutline)`
  && {
    margin-right: 5px;
  }
`;

const ProceedButton = styled(ButtonPrimary)`
  && {
    margin-right: 5px;
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Created = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.white};
  border-radius: 5px;
  padding: 15px;
  background: ${props => props.theme.colors.status.success};
`;

const Warning = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.status.error};
  border-radius: 5px;
  color: ${props => props.theme.colors.white};
  display: flex;
  margin-top: 15px;
  padding: 15px;
`;

const CancelSC = styled(Cancel)`
  color: ${props => props.theme.colors.status.error};
`;

const Message = styled.div`
  color: ${props => props.theme.colors.white};
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  padding-left: 10px;
`;

const getModalView = (
  statusCommit,
  messages,
  justificationMessage,
  successfullyCommitted,
  successfullyUncommitted,
  invalid,
  missingScoresError,
  isLoading,
  handleModalClose,
  handleModalSuccess,
  errorMessage,
  handleForceSubmit,
  handleSubmit,
  handleJustificationChange
) => {
  const {
    successCommitted,
    successUncommitted,
    missingScores,
    confirmCommitment,
    confirmUncommitment
  } = messages;

  const thisAction = statusCommit === 'COMMITTED' ? 'Uncommitted' : 'Committed';

  if (successfullyCommitted) {
    const successMessage =
      successCommitted ||
      `Successfully ${thisAction} and graded assessment scores.`;
    return (
      <>
        <Created>
          <PersonPin />
          {successMessage}
        </Created>

        <Actions>
          <div>
            <CloseButton onClick={handleModalSuccess}>Close</CloseButton>
          </div>
        </Actions>
      </>
    );
  }

  if (successfullyUncommitted) {
    const successMessage =
      successUncommitted ||
      `Successfully ${thisAction} and ungraded assessment.`;
    return (
      <>
        <Created>
          <PersonPin />
          {successMessage}
        </Created>

        <Actions>
          <div>
            <CloseButton onClick={handleModalSuccess}>Close</CloseButton>
          </div>
        </Actions>
      </>
    );
  }

  if (statusCommit !== 'COMMITTED') {
    return invalid && missingScoresError ? (
      <>
        <Warning>
          <CancelSC />
          <Message>{errorMessage}</Message>
        </Warning>
        <FileUpload>
          <Note>
            {missingScores || (
              <>
                Complete assessment scores have not been entered for every
                student in the section. Either cancel this commitment and verify
                you have all the correct scores uploaded for this assessment or
                click <em>Force Commit</em> below to give a <strong>0</strong>{' '}
                for any unprovided scores?
              </>
            )}
          </Note>
        </FileUpload>

        <Actions>
          <div>
            <CloseButton onClick={handleModalClose}>Cancel</CloseButton>
            <ProceedButton onClick={handleForceSubmit}>
              Force Commit
            </ProceedButton>
          </div>
        </Actions>
      </>
    ) : (
      <>
        <FileUpload>
          <Note>
            {confirmCommitment || (
              <>
                Committing this assessment will run grade calculations on all
                current scores based on the grading technique selected for this
                assessment. Are you sure you want to proceed?
              </>
            )}
          </Note>
        </FileUpload>
        <Actions>
          <div>
            <CloseButton onClick={handleModalClose}>Cancel</CloseButton>
            <ProceedButton
              onClick={() => {
                handleSubmit(false);
              }}
            >
              Proceed
            </ProceedButton>
          </div>
        </Actions>
        {isLoading && (
          <Loading>
            <CircularProgress size="100px" />
          </Loading>
        )}
      </>
    );
  }

  const validateJustificationField = message =>
    validateCompetencyDescription(message, 10, 250, true);

  const justificationFieldInvalid = Boolean(
    validateJustificationField(justificationMessage).invalid
  );

  const uncommitMessage = confirmUncommitment || (
    <>
      Uncommitting this assessment will revoke all student grades. This must be
      justified by a school admin. Are you sure you want to proceed?
    </>
  );

  return (
    <>
      <FileUpload>
        <Note>
          <DangerText>DANGER:</DangerText>&nbsp;{uncommitMessage}
        </Note>
        <JustificationTextFieldWithError
          id="justification-message"
          name="justification-message"
          value={justificationMessage || ''}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Justification"
          variant="outlined"
          multiline
          minRows="6"
          maxRows="6"
          placeholder=""
          onChange={handleJustificationChange}
          handleValidate={validateJustificationField}
          hasErrors={true}
        />
      </FileUpload>
      <Actions>
        <div>
          <CloseButton onClick={handleModalClose}>Cancel</CloseButton>
          <ProceedButton
            disabled={justificationFieldInvalid}
            onClick={() => {
              handleSubmit();
            }}
          >
            Proceed
          </ProceedButton>
        </div>
      </Actions>
    </>
  );
};

export default function StatusCommitModal({
  statusCommit,
  messages,
  justificationMessage,
  isModalOpen,
  isLoading,
  invalid,
  missingScoresError,
  errorMessage,
  successfullyCommitted,
  successfullyUncommitted,
  handleModalClose,
  handleModalSuccess,
  handleSubmit,
  handleJustificationChange
}) {
  const handleForceSubmit = () => {
    handleSubmit(true);
  };

  const modalView = getModalView(
    statusCommit,
    messages,
    justificationMessage,
    successfullyCommitted,
    successfullyUncommitted,
    invalid,
    missingScoresError,
    isLoading,
    handleModalClose,
    handleModalSuccess,
    errorMessage,
    handleForceSubmit,
    handleSubmit,
    handleJustificationChange
  );

  const onClickAway = successfullyCommitted
    ? handleModalSuccess
    : handleModalClose;

  const thisAction = statusCommit === 'COMMITTED' ? 'Uncommit' : 'Commit';

  const { title } = messages;

  return (
    <Modal open={isModalOpen} onClose={onClickAway}>
      <ModalBody>
        <Title>{title || `${thisAction} Assessment and Grade`}</Title>
        {modalView}
      </ModalBody>
    </Modal>
  );
}

StatusCommitModal.propTypes = {
  isModalOpen: PropTypes.bool,
  messages: PropTypes.object,
  isLoading: PropTypes.bool,
  statusCommit: PropTypes.string,
  justificationMessage: PropTypes.string,
  invalid: PropTypes.bool,
  missingScoresError: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleJustificationChange: PropTypes.func,
  successfullyCommitted: PropTypes.bool,
  successfullyUncommitted: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleModalSuccess: PropTypes.func,
  handleSubmit: PropTypes.func
};

StatusCommitModal.defaultProps = {
  isModalOpen: false,
  messages: {},
  isLoading: false,
  invalid: false,
  statusCommit: 'Uncommitted',
  justificationMessage: '',
  missingScoresError: false,
  errorMessage: '',
  successfullyCommitted: false,
  successfullyUncommitted: false,
  handleJustificationChange: undefined,
  handleModalClose: undefined,
  handleModalSuccess: undefined,
  handleSubmit: undefined
};
