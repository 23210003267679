import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { selectDegreeLevel } from '../../../../../redux/selectors/sourceSelectors';

export default function DegreeLevel({ sourceDegreeLevelUuid }) {
  const { degreeLevel } = useSelector(
    state => selectDegreeLevel(state, sourceDegreeLevelUuid),
    shallowEqual
  );

  return degreeLevel;
}

DegreeLevel.propTypes = { sourceDegreeLevelUuid: PropTypes.string };
DegreeLevel.defaultProps = { sourceDegreeLevelUuid: undefined };
