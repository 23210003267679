import Decimal from 'decimal.js-light';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const selectRigorInstitutions = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const getRigorIndexReport = ormCreateSelector(orm.RigorIndexReport);
    const getSourceInstitution = ormCreateSelector(orm.SourceInstitution);

    const rigorIndex = getRigorIndexReport(redux).filter(
      rigor => rigor.programUuid === programUuid
    );

    if (rigorIndex?.length > 0) {
      const institutions = _(rigorIndex)
        .map(record => {
          return record.sourceInstitutionUuid;
        })
        .uniq()
        .map(sourceInstitutionUuid => {
          const institution = getSourceInstitution(
            redux,
            sourceInstitutionUuid
          );

          if (institution) {
            const institution_rigors = getRigorIndexReport(redux).filter(
              rigor => rigor.sourceInstitutionUuid === institution.uuid
            );

            const credits_total = _.reduce(
              institution_rigors,
              (total, rigor) => {
                const creditDecimal = new Decimal(rigor.program_credits);
                return total.add(creditDecimal);
              },

              new Decimal(0)
            ).toNumber();

            return { ...institution, credits_total };
          } else {
            return {
              uuid: sourceInstitutionUuid,
              name: 'Unknown Institution',
              sourceInstitutionUuid,
              credits_total: 0
            };
          }
        })
        .orderBy(['credits_total', 'name'], ['desc'])
        .value();

      return institutions;
    } else {
      return [];
    }
  }
);

export const selectRigorSubjects = createSelector(
  state => state,
  (_, programUuid, sourceInstitutionUuid) => ({
    programUuid,
    sourceInstitutionUuid
  }),
  (redux, parameters) => {
    const { programUuid, sourceInstitutionUuid } = parameters;

    const getRigorIndexReport = ormCreateSelector(orm.RigorIndexReport);
    const getClassification = ormCreateSelector(orm.Classification);

    const rigorIndex = getRigorIndexReport(redux).filter(
      rigor =>
        rigor.programUuid === programUuid &&
        rigor.sourceInstitutionUuid === sourceInstitutionUuid
    );

    const subjects = _(rigorIndex)
      .map(record => {
        return record.classificationUuid;
      })
      .uniq()
      .map(classificationUuid => {
        const classfication = getClassification(redux, classificationUuid);
        return classfication;
      })
      .sortBy(['scope'])
      .value();

    return subjects;
  }
);

export const selectRigorApplicants = createSelector(
  state => state,
  (_, programUuid, sourceInstitutionUuid, classificationUuid) => ({
    programUuid,
    sourceInstitutionUuid,
    classificationUuid
  }),
  (redux, parameters) => {
    const {
      programUuid,
      sourceInstitutionUuid,
      classificationUuid
    } = parameters;

    const getRigorIndexReport = ormCreateSelector(orm.RigorIndexReport);
    const getUser = ormCreateSelector(orm.User);

    const rigorIndex = getRigorIndexReport(redux).filter(
      rigor =>
        rigor.programUuid === programUuid &&
        rigor.sourceInstitutionUuid === sourceInstitutionUuid &&
        rigor.classificationUuid === classificationUuid
    );

    const applicants = _(rigorIndex)
      .map(record => {
        const user = getUser(redux, record.userUuid);
        return { ...record, user };
      })
      .sortBy(['user.lastName'])
      .value();

    return applicants;
  }
);

export const selectCreditsBySubject = createSelector(
  state => state,
  (_, programUuid, sourceInstitutionUuid, classificationUuid) => ({
    programUuid,
    sourceInstitutionUuid,
    classificationUuid
  }),
  (redux, parameters) => {
    const {
      programUuid,
      sourceInstitutionUuid,
      classificationUuid
    } = parameters;

    const getRigorIndexReport = ormCreateSelector(orm.RigorIndexReport);

    const rigorIndex = getRigorIndexReport(redux).filter(
      rigor =>
        rigor.programUuid === programUuid &&
        rigor.sourceInstitutionUuid === sourceInstitutionUuid &&
        rigor.classificationUuid === classificationUuid
    );

    const total = _.reduce(
      rigorIndex,
      (total, rigor) => {
        const creditDecimal = new Decimal(rigor.program_credits);
        return total.add(creditDecimal);
      },

      new Decimal(0)
    ).val();

    return total;
  }
);

/* PREVIOUS CALCULATIONS NO WEIGHT */

// export const selectRigorBySubject = createSelector(
//   state => state,
//   (_, programUuid, sourceInstitutionUuid, classificationUuid) => ({
//     programUuid,
//     sourceInstitutionUuid,
//     classificationUuid
//   }),
//   (redux, parameters) => {
//     const {
//       programUuid,
//       sourceInstitutionUuid,
//       classificationUuid
//     } = parameters;

//     const getRigorIndexReport = ormCreateSelector(orm.RigorIndexReport);

//     const rigorIndex = getRigorIndexReport(redux).filter(
//       rigor =>
//         rigor.programUuid === programUuid &&
//         rigor.sourceInstitutionUuid === sourceInstitutionUuid &&
//         rigor.classificationUuid === classificationUuid
//     );

//     const total_program_score_average = _.reduce(
//       rigorIndex,
//       (total, rigor) => {
//         const milestone_score_average = new Decimal(
//           rigor.milestone_score_average
//         );

//         if (milestone_score_average.isZero()) {
//           return total;
//         } else {
//           const progrm_score_average = new Decimal(rigor.program_score_average);
//           return total.add(progrm_score_average);
//         }
//       },

//       new Decimal(0)
//     );

//     const total_milestone_score_average = _.reduce(
//       rigorIndex,
//       (total, rigor) => {
//         const score_average = new Decimal(rigor.milestone_score_average);
//         return total.add(score_average);
//       },

//       new Decimal(0)
//     );

//     const rigor = total_milestone_score_average.div(
//       total_program_score_average
//     );

//     if (rigor.isZero()) {
//       return 'N/A';
//     } else {
//       const rigorValue = rigor.toFixed(2).toString();
//       return rigorValue;
//     }
//   }
// );

export const selectRigorBySubject = createSelector(
  state => state,
  (_, programUuid, sourceInstitutionUuid, classificationUuid) => ({
    programUuid,
    sourceInstitutionUuid,
    classificationUuid
  }),
  (redux, parameters) => {
    const {
      programUuid,
      sourceInstitutionUuid,
      classificationUuid
    } = parameters;

    const getRigorIndexReport = ormCreateSelector(orm.RigorIndexReport);

    const rigorIndex = getRigorIndexReport(redux).filter(
      rigor =>
        rigor.programUuid === programUuid &&
        rigor.sourceInstitutionUuid === sourceInstitutionUuid &&
        rigor.classificationUuid === classificationUuid
    );

    const total_program_credits = _.reduce(
      rigorIndex,
      (total, rigor) => {
        const creditDecimal = new Decimal(rigor.program_credits);
        return total.add(creditDecimal);
      },

      new Decimal(0)
    );

    const total_weighted_credits = _.reduce(
      rigorIndex,
      (total, rigor) => {
        const creditDecimal = new Decimal(rigor.weighted_credits);
        return total.add(creditDecimal);
      },

      new Decimal(0)
    );

    const rigor = total_weighted_credits.div(total_program_credits);

    if (rigor.isZero()) {
      return 'N/A';
    } else {
      const rigorValue = rigor.toFixed(2).toString();
      return rigorValue;
    }
  }
);

export const selectCreditsByInstitution = createSelector(
  state => state,
  (_, programUuid, sourceInstitutionUuid) => ({
    programUuid,
    sourceInstitutionUuid
  }),
  (redux, parameters) => {
    const { programUuid, sourceInstitutionUuid } = parameters;

    const getRigorIndexReport = ormCreateSelector(orm.RigorIndexReport);

    const rigorIndex = getRigorIndexReport(redux).filter(
      rigor =>
        rigor.programUuid === programUuid &&
        rigor.sourceInstitutionUuid === sourceInstitutionUuid
    );

    const total = _.reduce(
      rigorIndex,
      (total, rigor) => {
        const creditDecimal = new Decimal(rigor.program_credits);
        return total.add(creditDecimal);
      },

      new Decimal(0)
    ).val();

    return total;
  }
);

/* PREVIOUS CALCULATIONS NO WEIGHT */

// export const selectRigorByInstitution = createSelector(
//   state => state,
//   (_, programUuid, sourceInstitutionUuid) => ({
//     programUuid,
//     sourceInstitutionUuid
//   }),
//   (redux, parameters) => {
//     const { programUuid, sourceInstitutionUuid } = parameters;

//     const getRigorIndexReport = ormCreateSelector(orm.RigorIndexReport);

//     const rigorIndex = getRigorIndexReport(redux).filter(
//       rigor =>
//         rigor.programUuid === programUuid &&
//         rigor.sourceInstitutionUuid === sourceInstitutionUuid
//     );

//     const total_program_score_average = _.reduce(
//       rigorIndex,
//       (total, rigor) => {
//         const milestone_score_average = new Decimal(
//           rigor.milestone_score_average
//         );

//         if (milestone_score_average.isZero()) {
//           return total;
//         } else {
//           const progrm_score_average = new Decimal(rigor.program_score_average);
//           return total.add(progrm_score_average);
//         }
//       },

//       new Decimal(0)
//     );

//     const total_milestone_score_average = _.reduce(
//       rigorIndex,
//       (total, rigor) => {
//         const score_average = new Decimal(rigor.milestone_score_average);
//         return total.add(score_average);
//       },

//       new Decimal(0)
//     );

//     const rigor = total_milestone_score_average.div(
//       total_program_score_average
//     );

//     if (rigor.isZero()) {
//       return 'N/A';
//     } else {
//       const rigorValue = rigor.toFixed(2).toString();
//       return rigorValue;
//     }
//   }
// );

export const selectRigorByInstitution = createSelector(
  state => state,
  (_, programUuid, sourceInstitutionUuid) => ({
    programUuid,
    sourceInstitutionUuid
  }),
  (redux, parameters) => {
    const { programUuid, sourceInstitutionUuid } = parameters;

    const getRigorIndexReport = ormCreateSelector(orm.RigorIndexReport);

    const rigorIndex = getRigorIndexReport(redux).filter(
      rigor =>
        rigor.programUuid === programUuid &&
        rigor.sourceInstitutionUuid === sourceInstitutionUuid
    );

    const total_program_credits = _.reduce(
      rigorIndex,
      (total, rigor) => {
        const creditDecimal = new Decimal(rigor.program_credits);
        return total.add(creditDecimal);
      },

      new Decimal(0)
    );

    const total_weighted_credits = _.reduce(
      rigorIndex,
      (total, rigor) => {
        const creditDecimal = new Decimal(rigor.weighted_credits);
        return total.add(creditDecimal);
      },

      new Decimal(0)
    );

    const rigor = total_weighted_credits.div(total_program_credits);

    if (rigor.isZero()) {
      return 'N/A';
    } else {
      const rigorValue = rigor.toFixed(2).toString();
      return rigorValue;
    }
  }
);
