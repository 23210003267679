import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonInline, ButtonIcon } from '@xcomp/xcomp-design-library';
import Modal from '../../../../Library/Modal/Modal';

const ButtonIconSC = styled(ButtonIcon)`
  && {
    svg {
      font-size: 20px;
    }
  }
`;

const ModalBody = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DeletePartButton = ({
  modalOpen,
  handleModalClose,
  handleModalOpen,
  handlePartDelete,
  className,
  partUuid
}) => (
  <div className={className}>
    <ButtonIconSC
      key="close"
      aria-label="Close"
      color="inherit"
      size="small"
      onClick={handleModalOpen}
      noHoverBg
    >
      <DeleteIcon />
    </ButtonIconSC>
    <Modal open={modalOpen} onClose={handleModalClose}>
      <ModalBody>
        <div>
          <h3>Are you sure you want to delete this part?</h3>
          <em>
            Any deletions won&rsquo;t be saved unless you click
            <strong>Update</strong> above. Refresh the page to discard your
            changes.
          </em>
        </div>
        <Actions>
          <ButtonInline onClick={() => handleModalClose()}>Cancel</ButtonInline>
          <ButtonInline onClick={() => handlePartDelete(partUuid)}>
            Delete Part
          </ButtonInline>
        </Actions>
      </ModalBody>
    </Modal>
  </div>
);

DeletePartButton.propTypes = {
  modalOpen: PropTypes.bool,
  className: PropTypes.string,
  partUuid: PropTypes.string,
  handleModalClose: PropTypes.func,
  handleModalOpen: PropTypes.func,
  handlePartDelete: PropTypes.func
};

DeletePartButton.defaultProps = {
  modalOpen: false,
  className: '',
  partUuid: '',
  handleModalClose: undefined,
  handleModalOpen: undefined,
  handlePartDelete: undefined
};

export default DeletePartButton;
