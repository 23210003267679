import { useMediaQuery } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../helpers/format/user.format';
import {
  useAssessmentCompletion,
  useGetCohortStudentAssessments
} from '../../../helpers/hooks/useGetCohortStudentAssessments';
import { doGetUser } from '../../../redux/actions/userActions';
import { userSelector } from '../../../redux/selectors/usersSelectors';
import AccordionRowContainer from '../../Library/AccordionRow/AccordionRowContainer';
import Header from '../../Library/Header';
import ContainerResize, {
  ContainerContentLight,
  ContainerPage
} from '../../Library/Layout';
import OverallCredits from './Calculations/OverallCredits';
import OverallGPA from './Calculations/OverallGPA';
import CohortAssessmentCollectionContainer from './CohortAssessmentCollection/CohortAssessmentCollectionContainer';
import CompetencyCollectionContainer from './CompetencyCollection/CompetencyCollectionContainer';
import CoursesByCollectionContainer from './Courses/CoursesByCollectionContainer';
import CoursesByTermContainer from './Courses/CoursesByTermContainer';
import CoursesView from './Courses/CoursesView';
import { QuantitativeSkills } from './QuantitativeSkills';
import SelfAssessmentReport from './SelfAssessmentReport/SelfAssessmentReport';
import AccordionLabel from './QuantitativeSkills/AccordionLabel';
import AttemptsAccordion from './AttemptsReport/AttemptsAccordion';
import CompletionRate from './Data/CompletionPercentages';

const Accordion = styled(AccordionRowContainer)`
  .accordion_row_label {
    display: flex;
    flex: ${({ isTablet }) => (!isTablet ? 1 : 'unset')};
  }

  &:hover > div > .hover-effect {
    background: ${props => props.theme.colors.grey[400]};
  }

  .hover-effect > div:nth-child(2) {
    flex-direction: ${({ isTablet }) => (isTablet ? 'column' : 'row')};
    justify-content: ${({ isTablet }) =>
      isTablet ? 'space-around' : 'flex-start'};
    align-items: ${({ isTablet }) => (isTablet ? 'flex-start' : 'center')};
  }

  .column_one {
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }

  .column_one > span {
    width: 100%;
  }
`;

const Label = styled.div`
  display: flex;
  font-size: 20px;
  color: ${props => props.theme.fontColors.darker};
`;

const Calculations = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
`;
const GroupLabel = styled.div`
  padding: 10px;
`;
const GroupInput = styled.div`
  padding: 10px;
`;

const Record = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width};
`;

const Progress = styled(LinearProgress)`
  && {
    width: 100%;
  }
`;

export default function CohortStudentTranscript({
  programUuid,
  userUuid,
  cohortUuid
}) {
  const dispatch = useDispatch();
  const [view, setView] = useState('Academic Year & Term');
  const isTablet = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [viewDefault, setViewDefault] = useState(true);

  const { completionStatus, loading, fetchCohortAssessments } =
    useGetCohortStudentAssessments();

  const { completedAmount, remainingAmount, pendingAmount, percentage } =
    useAssessmentCompletion({ ...completionStatus });

  const user = useSelector(state => userSelector(state, userUuid), _.isEqual);

  const name = `${formatLastName(
    user?.lastName,
    user?.marriedLastName,
    user?.suffix
  )} ${formatFirstNameMiddleName(user?.firstName, user?.middleName)}`;

  const { fetchingCohortAssessments } = useSelector(
    state => state.crudState,
    _.isEqual
  );

  const handleSetView = choice => {
    setView(choice);
  };

  useEffect(() => {
    dispatch(doGetUser(userUuid));
    fetchCohortAssessments();
  }, [dispatch, fetchCohortAssessments, userUuid]);

  return (
    <ContainerResize>
      <Header title="Student Transcript" subtitle={name} />
      <ContainerPage paddingTop="80px">
        <ContainerContentLight>
          <Accordion
            withBottomBorder={true}
            expandWidth="66px"
            height="80px"
            label={
              <Label>
                Courses
                <CoursesView
                  view={view}
                  handleSetView={handleSetView}
                  userUuid={userUuid}
                  cohortUuid={cohortUuid}
                />
              </Label>
            }
            columnOne={
              <Calculations>
                <Record width="50%">
                  <Group>
                    <GroupLabel>Credits</GroupLabel>
                    <GroupInput>
                      <OverallCredits userUuid={userUuid} />
                    </GroupInput>
                  </Group>
                </Record>
                <Record width="50%">
                  <Group>
                    <GroupLabel>GPA</GroupLabel>
                    <GroupInput>
                      <OverallGPA userUuid={userUuid} />
                    </GroupInput>
                  </Group>
                </Record>
              </Calculations>
            }
            columnOneClassName="column_one"
            columnTwoClassName="column_two"
          >
            {view === 'Academic Year & Term' && (
              <CoursesByTermContainer
                cohortUuid={cohortUuid}
                userUuid={userUuid}
              />
            )}
            {view === 'Cohort Collection' && (
              <CoursesByCollectionContainer
                cohortUuid={cohortUuid}
                userUuid={userUuid}
              />
            )}
          </Accordion>
        </ContainerContentLight>
        <ContainerContentLight>
          <Accordion
            withBottomBorder={true}
            expandWidth="66px"
            height="90px"
            style={{ display: 'flex', flexDirection: 'column' }}
            columnOneClassName="test-this-shit"
            isTablet={isTablet}
            label={<Label>Program Assessment Collection</Label>}
            columnOne={
              <CompletionRate
                loading={loading}
                completionRate={{
                  complete: completedAmount,
                  pending: pendingAmount,
                  remaining: remainingAmount,
                  percentage
                }}
              />
            }
          >
            {fetchingCohortAssessments && <Progress />}

            <CohortAssessmentCollectionContainer
              cohortUuid={cohortUuid}
              userUuid={userUuid}
            />
          </Accordion>
        </ContainerContentLight>
        <ContainerContentLight>
          <AttemptsAccordion userUuid={userUuid} cohortUuid={cohortUuid} isDidactic />
        </ContainerContentLight>
        <ContainerContentLight>
          <AttemptsAccordion userUuid={userUuid} cohortUuid={cohortUuid} />
        </ContainerContentLight>
        <ContainerContentLight>
          <Accordion
            withBottomBorder={true}
            expandWidth="66px"
            height="90px"
            label={
              <AccordionLabel
                viewDefault={viewDefault}
                handleChange={() => setViewDefault(!viewDefault)}
              />
            }
          >
            <Suspense fallback={<LinearProgress />}>
              <QuantitativeSkills
                programUuid={programUuid}
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                viewDefault={viewDefault}
              />
            </Suspense>
          </Accordion>
        </ContainerContentLight>
        <ContainerContentLight>
          <SelfAssessmentReport userUuid={userUuid} cohortUuid={cohortUuid} />
        </ContainerContentLight>
        <ContainerContentLight>
          <Accordion
            withBottomBorder={true}
            expandWidth="66px"
            height="90px"
            label={<Label>Competencies</Label>}
          >
            <CompetencyCollectionContainer
              cohortUuid={cohortUuid}
              userUuid={userUuid}
              programUuid={programUuid}
            />
          </Accordion>
        </ContainerContentLight>
        {/*
         </ContainerContentLight>
         <ContainerContentLight>
         <Accordion
         withBottomBorder={true}
         expandWidth="66px"
         height="90px"
         label={<Label>Assessment Performance</Label>}
         >
         EMPTY
         </Accordion>
         </ContainerContentLight>
         <ContainerContentLight>
         <Accordion
         withBottomBorder={true}
         expandWidth="66px"
         height="90px"
         label={<Label>Self Assessment</Label>}
         >
         EMPTY
         </Accordion>
         </ContainerContentLight> */}
      </ContainerPage>
    </ContainerResize>
  );
}

CohortStudentTranscript.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string
};

CohortStudentTranscript.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined
};
