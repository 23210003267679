import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2 } from '@xcomp/xcomp-design-library';
import UnsavedChangesModal from '../../Library/Modal/UnsavedChangesModal/UnsavedChangesModal';
import {
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader
} from '../../Library/Layout';
import Header from '../../Library/Header';
import FormSelect from '../../Library/FormSelect';
import { optionsPartGradeDataSource } from '../../../helpers/options';
import { ButtonOutline } from '../../Library/ButtonSC';
import GradeCollectionsFormContainer from './GradeCollectionsForm/GradeCollectionsFormContainer';
import EditPartGradeModifiersContainer from './EditPartGradeModifiers/EditPartGradeModifiersContainer';

const EditPartGradeModifiersForm = styled(EditPartGradeModifiersContainer)`
  && {
    margin-top: 64px;
  }
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const DataSource = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const SubmitButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const DataSourceSelect = styled(FormSelect)`
  && {
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    max-width: 300px;
  }
`;

const ButtonOutlineSC = styled(ButtonOutline)``;

export default function PartGradeSettings({
  partTitle,
  courseNumber,
  courseTitle,
  dataSource,
  partGradeSettingsUuid,
  handleDataSourceChange,
  handleModalClose,
  handleModalOpen,
  handleProceed,
  isUnsavedChangesModalOpen,
  handleSubmitPartDataSource,
  partNumber,
  sectionNumber,
  unsavedChanges,
  setUnsavedChange,
  removeUnsavedChange,
  savedDataSource
}) {
  const headerSubtitle = `${courseNumber} ● ${courseTitle}`;

  return (
    <ContainerResize>
      <UnsavedChangesModal
        modalOpen={isUnsavedChangesModalOpen}
        message="The form has unsaved changes. Click cancel to go back and update the form or click Discard Changes to return to the syllabus management page."
        proceedButtonText="Discard Changes"
        handleProceed={handleProceed}
        handleModalClose={handleModalClose}
      />
      <Header
        title={`Section ${sectionNumber} - Part ${partNumber}: ${partTitle}`}
        subtitle={headerSubtitle}
        backButtonOverride={handleModalOpen}
      />
      <ContainerPage>
        <Panel>
          <PanelHeader paddingLeft="24px">
            <Heading2>Grade Calculation</Heading2>
          </PanelHeader>
          <PanelPadding>
            <DataSource>
              <DataSourceSelect
                value={dataSource || ''}
                options={optionsPartGradeDataSource}
                handleChange={handleDataSourceChange}
                name="dataSource"
                label="Data Source"
              />
            </DataSource>
            <SubmitButtonRow>
              <ButtonOutlineSC
                onClick={handleSubmitPartDataSource}
                disabled={!unsavedChanges.dataSource}
              >
                Apply
              </ButtonOutlineSC>
            </SubmitButtonRow>
          </PanelPadding>
        </Panel>
        {savedDataSource === 'ASSESSMENTS' && partGradeSettingsUuid && (
          <GradeCollectionsFormContainer
            partGradeSettingsUuid={partGradeSettingsUuid}
            unsavedChanges={unsavedChanges}
            setUnsavedChange={setUnsavedChange}
            removeUnsavedChange={removeUnsavedChange}
          />
        )}
      </ContainerPage>
      <ContainerPage>
        {savedDataSource === 'ASSESSMENTS' && partGradeSettingsUuid && (
          <EditPartGradeModifiersForm
            partGradeSettingsUuid={partGradeSettingsUuid}
            isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
            unsavedChanges={unsavedChanges}
            setUnsavedChange={setUnsavedChange}
            removeUnsavedChange={removeUnsavedChange}
          />
        )}
      </ContainerPage>
    </ContainerResize>
  );
}

PartGradeSettings.propTypes = {
  partTitle: PropTypes.string,
  dataSource: PropTypes.string,
  courseNumber: PropTypes.string,
  courseTitle: PropTypes.string,
  partGradeSettingsUuid: PropTypes.string,
  handleDataSourceChange: PropTypes.func,
  handleModalClose: PropTypes.func,
  handleModalOpen: PropTypes.func,
  handleProceed: PropTypes.func,
  handleSubmitPartDataSource: PropTypes.func,
  isUnsavedChangesModalOpen: PropTypes.bool,
  partNumber: PropTypes.string,
  sectionNumber: PropTypes.string,
  unsavedChanges: PropTypes.object,
  setUnsavedChange: PropTypes.func,
  removeUnsavedChange: PropTypes.func,
  savedDataSource: PropTypes.string
};

PartGradeSettings.defaultProps = {
  partTitle: '',
  dataSource: '',
  courseNumber: '',
  courseTitle: '',
  partGradeSettingsUuid: '',
  handleDataSourceChange: undefined,
  handleModalClose: undefined,
  handleModalOpen: undefined,
  handleProceed: undefined,
  handleSubmitPartDataSource: undefined,
  isUnsavedChangesModalOpen: false,
  partNumber: '',
  sectionNumber: '',
  unsavedChanges: {},
  setUnsavedChange: undefined,
  removeUnsavedChange: undefined,
  savedDataSource: ''
};
