import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MoreIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonIcon } from '@xcomp/xcomp-design-library';

const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default function RecordActions({
  uuid,
  handleDeleteModalOpen,
  handleEditModalOpen
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Layout>
      <ButtonIcon
        aria-controls={`${uuid}-menu`}
        aria-haspopup="true"
        onClick={handleClick}
        noHoverBg
      >
        <MoreIcon />
      </ButtonIcon>
      <Menu
        id={`${uuid}-menu`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleEditModalOpen();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleDeleteModalOpen();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Layout>
  );
}

RecordActions.propTypes = {
  uuid: PropTypes.string,
  handleDeleteModalOpen: PropTypes.func,
  handleEditModalOpen: PropTypes.func
};

RecordActions.defaultProps = {
  uuid: undefined,
  handleDeleteModalOpen: undefined,
  handleEditModalOpen: undefined
};
