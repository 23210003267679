import { Model, attr, fk } from 'redux-orm';
import {
  PUT_THIRD_PARTY_ID_SUCCESS,
  GET_USERS_COHORT_SUCCESS
} from '../actions-type';

class ThirdPartyId extends Model {
  static reducer(action, ThirdPartyId, session) {
    const { payload, type } = action;

    switch (type) {
      case PUT_THIRD_PARTY_ID_SUCCESS: {
        const thirdParty = { ...payload.thirdParty };

        ThirdPartyId.upsert(thirdParty);
        break;
      }

      case GET_USERS_COHORT_SUCCESS: {
        const thirdPartyIds = [...payload.thirdPartyIds];
        thirdPartyIds.forEach(id => ThirdPartyId.upsert(id));
        break;
      }

      default:
        return session;
    }
  }
}

ThirdPartyId.fields = {
  uuid: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  thirdPartyId: attr(),
  programUuid: fk({
    to: 'Program',
    as: 'program',
    relatedName: 'program'
  }),
  userUuid: fk({
    to: 'User',
    as: 'user',
    relatedName: 'user'
  })
};

ThirdPartyId.modelName = 'ThirdPartyId';
ThirdPartyId.options = {
  idAttribute: 'uuid'
};

export default ThirdPartyId;
