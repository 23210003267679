import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CredentialAdd from './CredentialAdd';
import { credentialReducer, credentialInitial } from './credentialState';
import { degreeLevelsSelector } from '../../../../../redux/selectors/degreeLevelsSelectors';
import {
  validateInputString,
  validateSelectDate,
  validateSelectField
} from '../../../../../helpers/validation/validateGeneric';

import { doPostCredential } from '../../../../../redux/actions/credentialActions';

export default function CredentialAddContainer({
  userUuid,
  addCredential,
  handleCloseAdd
}) {
  const dispatch = useDispatch();
  const [hasErrors, setHasErrors] = useState(false);
  const [credentialState, credentialDispatch] = useReducer(
    credentialReducer,
    credentialInitial
  );

  const degreeLevelOptions = useSelector(state =>
    degreeLevelsSelector(state)
  ).map(level => ({ label: level.degreeLevel, value: level.uuid }));

  const {
    year,
    credential,
    degreeLevelUuid,
    institutionUuid,
    programCodeUuid
  } = credentialState;

  const createCredential = useSelector(
    state => state.crudState.createCredential
  );

  const createCredentialResult = useSelector(
    state => state.crudState.createCredentialResult
  );

  useEffect(() => {
    if (!addCredential) {
      credentialDispatch({ type: 'reset' });
    }
  }, [addCredential]);

  useEffect(() => {
    if (createCredentialResult === 'success') {
      handleCloseAdd();
      dispatch({ type: 'POST_CREDENTIAL_RESET' });
    }
  }, [dispatch, createCredentialResult, handleCloseAdd]);

  const handleChangeDate = (_, field, date) => {
    credentialDispatch({ type: field, date });
  };

  const handleChange = event => {
    const { name, value } = event.target;
    credentialDispatch({ type: name, value });
  };

  const handleSourceInstitutionUuid = institution => {
    const institutionUuid = institution?.uuid;
    const institutionCode = institution?.institutionCode;

    credentialDispatch({
      type: 'institutionUuid',
      institutionUuid,
      institutionCode
    });
  };

  const handleProgramCodeUuid = programCodeUuid => {
    credentialDispatch({ type: 'programCodeUuid', programCodeUuid });
  };

  const _validate = () => {
    let containErrors = false;

    const yearError = validateSelectDate(year);
    const degreeLevelUuidError = validateSelectField(degreeLevelUuid);
    const credentialError = validateInputString(credential);
    const institutionUuidError = validateInputString(institutionUuid);
    const programCodeUuidError = validateInputString(programCodeUuid);

    if (
      yearError.invalid ||
      degreeLevelUuidError.invalid ||
      credentialError.invalid ||
      institutionUuidError.invalid ||
      programCodeUuidError.invalid
    ) {
      containErrors = true;
    }

    return containErrors;
  };
  const handleSaveCredential = () => {
    const check = _validate();

    if (check) {
      setHasErrors(true);
    } else {
      const payload = {
        globalUserUuid: userUuid,
        year: moment(year).format('YYYY'),
        credential,
        degreeLevelUuid,
        institutionUuid,
        programCodeUuid
      };
      dispatch(doPostCredential(payload));
    }
  };

  return (
    <CredentialAdd
      submit={createCredential}
      result={createCredentialResult}
      degreeLevelUuid={degreeLevelUuid}
      year={year}
      credential={credential}
      institutionUuid={institutionUuid}
      programCodeUuid={programCodeUuid}
      degreeLevelOptions={degreeLevelOptions}
      hasErrors={hasErrors}
      handleChange={handleChange}
      handleChangeDate={handleChangeDate}
      handleSourceInstitutionUuid={handleSourceInstitutionUuid}
      handleProgramCodeUuid={handleProgramCodeUuid}
      handleClose={handleCloseAdd}
      handleSave={handleSaveCredential}
    />
  );
}

CredentialAddContainer.propTypes = {
  userUuid: PropTypes.string,
  addCredential: PropTypes.bool,
  handleCloseAdd: PropTypes.func
};

CredentialAddContainer.defaultProps = {
  userUuid: undefined,
  addCredential: false,
  handleCloseAdd: undefined
};
