import _ from 'lodash';

import {
  APPROVE_STATUS_CHECK_ERROR,
  CLOSE_NOTIFICATION,
  COMPLETE_STATUS_CHECK_ERROR,
  DELETE_ACADEMIC_YEAR_ERROR,
  DELETE_ACADEMIC_YEAR_SUCCESS,
  DELETE_APP_ID_ERROR,
  DELETE_APP_ID_SUCCESS,
  DELETE_ASSESSMENT_ERROR,
  DELETE_ASSESSMENT_SUCCESS,
  DELETE_COHORT_ASSESSMENT_COLLECTION_ERROR,
  DELETE_COHORT_ASSESSMENT_COLLECTION_SUCCESS,
  DELETE_COHORT_ASSESSMENT_ERROR,
  DELETE_COHORT_ASSESSMENT_SUCCESS,
  DELETE_COHORT_ERROR,
  DELETE_COHORT_SUCCESS,
  DELETE_COMPETENCY_COLLECTION_ERROR,
  DELETE_COMPETENCY_COLLECTION_SUCCESS,
  DELETE_COMPETENCY_ERROR,
  DELETE_COMPETENCY_SUCCESS,
  DELETE_COURSE_COLLECTION_ERROR,
  DELETE_COURSE_COLLECTION_SUCCESS,
  DELETE_COURSE_ERROR,
  DELETE_COURSE_MASTER_ERROR,
  DELETE_COURSE_MASTER_SUCCESS,
  DELETE_COURSE_SUCCESS,
  DELETE_CREDENTIAL_ERROR,
  DELETE_CREDENTIAL_SUCCESS,
  DELETE_DEGREE_LEVEL_INDEX_ERROR,
  DELETE_DEGREE_LEVEL_INDEX_SUCCESS,
  DELETE_DEPARTMENT_ERROR,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_EDUCATION_LEVEL_ERROR,
  DELETE_EDUCATION_LEVEL_SUCCESS,
  DELETE_ENCOUNTER_ERROR,
  DELETE_ENCOUNTER_SUCCESS,
  DELETE_GROUP_ERROR,
  DELETE_GROUP_SUCCESS,
  DELETE_LETTER_GRADE_ERROR,
  DELETE_LETTER_GRADE_SUCCESS,
  DELETE_PROGRAM_ASSESSMENT_COLLECTION_ERROR,
  DELETE_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS,
  DELETE_PROGRAM_ASSESSMENT_MASTER_ERROR,
  DELETE_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  DELETE_PROGRAM_ERROR,
  DELETE_PROGRAM_SUCCESS,
  DELETE_SCHOOL_ERROR,
  DELETE_SCHOOL_SUCCESS,
  DELETE_SCORE_ERROR,
  DELETE_SCORE_SUCCESS,
  DELETE_SCORES_ERROR,
  DELETE_SCORES_SUCCESS,
  DELETE_SECTION_UNDO_CALCULATED_GRADE_ERROR,
  DELETE_SECTION_UNDO_CALCULATED_GRADE_SUCCESS,
  DELETE_STUDENT_SCORES_ERROR,
  DELETE_STUDENT_SCORES_SUCCESS,
  DELETE_TRANSCRIPT_ERROR,
  DELETE_TRANSCRIPT_SUCCESS,
  DELETE_TRANSCRIPTS_ERROR,
  DELETE_TRANSCRIPTS_SUCCESS,
  DELETE_USER_COHORT_ERROR,
  DELETE_USER_COHORT_SUCCESS,
  DELETE_USER_COHORTS_ERROR,
  DELETE_USER_COHORTS_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_GROUP_ERROR,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_SUCCESS,
  DELETE_USERS_SECTION_ERROR,
  DELETE_USERS_SECTION_SUCCESS,
  GENERAL_NOTIFICATION_ERROR,
  GET_ACADEMIC_YEAR_ERROR,
  GET_ACADEMIC_YEARS_BY_SCHOOL_ERROR,
  GET_ACCREDITATION_AGENCIES_ERROR,
  GET_APTITUDES_BY_CATEGORY_ERROR,
  GET_ASSESSMENT_ERROR,
  GET_CLASSIFICATIONS_ERROR,
  GET_COHORT_APPLICANTS_BY_STATUS_ERROR,
  GET_COHORT_ASSESSMENT_SCORES_ERROR,
  GET_COHORT_ASSESSMENTS_ERROR,
  GET_COHORT_ERROR,
  GET_COHORTS_ERROR,
  GET_COMPETENCY_COLLECTION_ERROR,
  GET_COMPETENCY_COLLECTIONS_ERROR,
  GET_COURSE_COLLECTIONS_ERROR,
  GET_COURSE_ERROR,
  GET_COURSE_MASTER_BY_DEPARTMENT_ERROR,
  GET_COURSE_MASTER_ERROR,
  GET_COURSES_BY_DEPARTMENT_AY_ERROR,
  GET_COURSES_ERROR,
  GET_DEGREE_LEVELS_ERROR,
  GET_DEPARTMENT_ERROR,
  GET_DEPARTMENTS_ERROR,
  GET_EDUCATION_LEVEL_ERROR,
  GET_EDUCATION_LEVELS_BY_SCHOOL_ERROR,
  GET_LETTER_GRADES_BY_SCHOOL_ERROR,
  GET_LOVERBS_BY_EDUCATION_LEVELS_ERROR,
  GET_LOVERBS_ERROR,
  GET_PART_FACULTY_ERROR,
  GET_PART_FACULTY_REPORT_ERROR,
  GET_PART_GRADE_SETTINGS_ERROR,
  GET_PROGRAM_ASSESSMENT_ERROR,
  GET_PROGRAM_ASSESSMENTS_ERROR,
  GET_PROGRAM_CIPS_ERROR,
  GET_PROGRAM_CODES_ERROR,
  GET_PROGRAM_COHORTS_ERROR,
  GET_PROGRAM_DISCIPLINES_ERROR,
  GET_PROGRAM_ERROR,
  GET_PROGRAMS_ERROR,
  GET_SCHOOL_DEPARTMENTS_ERROR,
  GET_SCHOOL_PROGRAMS_ERROR,
  GET_SCHOOL_STUDENTS_BY_STATUS_ERROR,
  GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS,
  GET_SCHOOLS_ERROR,
  GET_SCORES_ERROR,
  GET_SECTION_ERROR,
  GET_SECTION_NESTED_BY_COURSE_ERROR,
  GET_SECTION_PERFORMANCE_REPORT_ERROR,
  GET_SOURCE_INSTITUTIONS_BY_CODE_SUCCESS_ERROR,
  GET_STUDENT_ROSTER_ERROR,
  GET_SUBJECTS_ERROR,
  GET_SYLLABUS_ERROR,
  GET_TERMS_BY_ACADEMIC_YEARS_ERROR,
  GET_TERMS_ERROR,
  GET_TRANSCRIPT_ERROR,
  GET_USER_ACTIVE_ERROR,
  GET_USER_COHORT_BY_USER_AND_COHORT_ERROR,
  GET_USER_DEPARTMENT_ERROR,
  GET_USER_DEPARTMENTS_ERROR,
  GET_USER_DEPARTMENTS_FACULTY_ERROR,
  GET_USER_ERROR,
  GET_USER_PROGRAMS_BY_PROGRAM_ERROR,
  GET_USER_PROGRAMS_BY_PROGRAMS_ERROR,
  GET_USERS_BY_SEARCH_INSTITUTION_ID_ERROR,
  GET_USERS_COHORT_ERROR,
  GET_USERS_SCHOOL_ADMIN_ERROR,
  GET_USERS_SCHOOL_ERROR,
  GET_USERS_SCHOOLS_ERROR,
  OPEN_NOTIFICATION,
  PATCH_COHORT_ASSESSMENT_STATUS_ERROR,
  PATCH_COHORT_ASSESSMENT_STATUS_SUCCESS,
  PATCH_ENCOUNTER_APPROVE_ERROR,
  PATCH_PART_CALCULATE_GRADE_ERROR,
  PATCH_PART_LOCKED_ERROR,
  PATCH_PART_STATUS_ERROR,
  PATCH_PART_STATUS_SUCCESS,
  PATCH_PART_UNDO_CALCULATED_GRADE_ERROR,
  PATCH_PART_UNDO_CALCULATED_GRADE_SUCCESS,
  PATCH_SCHOOL_REVUS_ERROR,
  PATCH_SCHOOL_REVUS_SUCCESS,
  PATCH_SECTION_CALCULATE_GRADE_ERROR,
  PATCH_SECTION_CALCULATE_GRADE_SUCCESS,
  PATCH_STUDENT_SECTION_STATUS_ERROR,
  PATCH_STUDENT_SECTION_STATUS_SUCCESS,
  PATCH_USER_COHORT_STATUS_ERROR,
  PATCH_USER_COHORT_STATUS_SUCCESS,
  POST_ACADEMIC_YEAR_ERROR,
  POST_ACADEMIC_YEAR_SUCCESS,
  POST_APTITUDES_ERROR,
  POST_APTITUDES_SUCCESS,
  POST_ASSESSMENT_ERROR,
  POST_ASSESSMENT_SUCCESS,
  POST_CALCULATE_ENCOUNTER_OBJECTIVES_ERROR,
  POST_CALCULATE_ENCOUNTER_OBJECTIVES_SUCCESS,
  POST_CLONE_TRANSCRIPT_ERROR,
  POST_CLONE_TRANSCRIPT_SUCCESS,
  POST_COHORT_ASSESSMENT_COLLECTION_ERROR,
  POST_COHORT_ASSESSMENT_COLLECTION_SUCCESS,
  POST_COHORT_ASSESSMENT_ERROR,
  POST_COHORT_ASSESSMENT_SUCCESS,
  POST_COHORT_ERROR,
  POST_COHORT_SUCCESS,
  POST_COMPETENCY_COLLECTION_ERROR,
  POST_COMPETENCY_COLLECTION_SUCCESS,
  POST_COMPETENCY_ERROR,
  POST_COMPETENCY_SUCCESS,
  POST_COURSE_AGE_MOD_ERROR,
  POST_COURSE_AGE_MOD_SUCCESS,
  POST_COURSE_COLLECTION_ERROR,
  POST_COURSE_COLLECTION_SUCCESS,
  POST_COURSE_ERROR,
  POST_COURSE_MASTER_ERROR,
  POST_COURSE_MASTER_SUCCESS,
  POST_COURSE_SUCCESS,
  POST_CREDENTIAL_ERROR,
  POST_CREDENTIAL_SUCCESS,
  POST_DEGREE_LEVEL_INDEX_ERROR,
  POST_DEGREE_LEVEL_INDEX_SUCCESS,
  POST_DEPARTMENT_ERROR,
  POST_DEPARTMENT_SUCCESS,
  POST_EDUCATION_LEVEL_ERROR,
  POST_EDUCATION_LEVEL_SUCCESS,
  POST_ENCOUNTER_ERROR,
  POST_ENCOUNTER_SUCCESS,
  POST_GROUP_ERROR,
  POST_GROUP_SUCCESS,
  POST_INSTITUTION_RIGOR_ERROR,
  POST_INSTITUTION_RIGOR_SUCCESS,
  POST_LETTER_GRADE_ERROR,
  POST_LETTER_GRADE_SUCCESS,
  POST_MILESTONES_ERROR,
  POST_MILESTONES_SUCCESS,
  POST_PROGRAM_ASSESSMENT_COLLECTION_ERROR,
  POST_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS,
  POST_PROGRAM_ASSESSMENT_MASTER_ERROR,
  POST_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  POST_PROGRAM_ERROR,
  POST_PROGRAM_SUCCESS,
  POST_SCHOOL_ERROR,
  POST_SCHOOL_SUCCESS,
  POST_SCORE_ERROR,
  POST_SCORE_SUCCESS,
  POST_STUDENT_CONVERSION_ERROR,
  POST_STUDENT_CONVERSION_SUCCESS,
  POST_SUBJECTS_ERROR,
  POST_SUBJECTS_SUCCESS,
  POST_TRANSCRIPT_ERROR,
  POST_TRANSCRIPT_SUCCESS,
  POST_TRANSCRIPT_UPLOAD_ERROR,
  POST_USER_APP_ID_ERROR,
  POST_USER_APP_ID_SUCCESS,
  POST_USER_COHORT_ERROR,
  POST_USER_COHORT_SUCCESS,
  POST_USER_COHORTS_ERROR,
  POST_USER_COHORTS_SUCCESS,
  POST_USER_ERROR,
  POST_USER_GROUPS_ERROR,
  POST_USER_GROUPS_SUCCESS,
  POST_USER_ROLE_ERROR,
  POST_USER_ROLE_SUCCESS,
  POST_USER_SECTION_ERROR,
  POST_USER_SECTION_SUCCESS,
  POST_USER_SUCCESS,
  PUT_ACADEMIC_YEAR_ERROR,
  PUT_ACADEMIC_YEAR_SUCCESS,
  PUT_APPLICANT_COGNITIVE_REPORT_ERROR,
  PUT_APPLICANT_COGNITIVE_REPORT_SUCCESS,
  PUT_APPLICANT_COMMUNICATION_REPORT_ERROR,
  PUT_APPLICANT_COMMUNICATION_REPORT_SUCCESS,
  PUT_APPLICANT_DEMOGRAPHIC_REPORT_ERROR,
  PUT_APPLICANT_DEMOGRAPHIC_REPORT_SUCCESS,
  PUT_APPLICANT_NONCOGNITIVE_REPORT_ERROR,
  PUT_APPLICANT_NONCOGNITIVE_REPORT_SUCCESS,
  PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_ERROR,
  PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_SUCCESS,
  PUT_ASSESSMENT_DRAFT_ERROR,
  PUT_ASSESSMENT_DRAFT_SUCCESS,
  PUT_ASSESSMENT_FINAL_ERROR,
  PUT_ASSESSMENT_FINAL_SUCCESS,
  PUT_ASSESSMENT_GRADING_TECHNIQUE_ERROR,
  PUT_ASSESSMENT_GRADING_TECHNIQUE_SUCCESS,
  PUT_ASSESSMENT_STATUS_COMMIT_ERROR,
  PUT_ASSESSMENT_STATUS_COMMIT_SUCCESS,
  PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS,
  PUT_ASSESSMENT_STATUS_SCORE_ERROR,
  PUT_ASSESSMENT_STATUS_SCORE_SUCCESS,
  PUT_COHORT_ASSESSMENT_COLLECTION_ERROR,
  PUT_COHORT_ASSESSMENT_COLLECTION_SUCCESS,
  PUT_COHORT_ASSESSMENT_ERROR,
  PUT_COHORT_ASSESSMENT_SUCCESS,
  PUT_COHORT_ERROR,
  PUT_COHORT_SUCCESS,
  PUT_COLLECTION_ASSESSMENTS_ERROR,
  PUT_COLLECTION_ASSESSMENTS_SUCCESS,
  PUT_COMPETENCY_COLLECTION_ERROR,
  PUT_COMPETENCY_COLLECTION_SUCCESS,
  PUT_COMPETENCY_ERROR,
  PUT_COMPETENCY_MICROS_ERROR,
  PUT_COMPETENCY_MICROS_SUCCESS,
  PUT_COMPETENCY_SUCCESS,
  PUT_COURSE_COLLECTION_ERROR,
  PUT_COURSE_COLLECTION_SUCCESS,
  PUT_COURSE_ERROR,
  PUT_COURSE_MASTER_ERROR,
  PUT_COURSE_MASTER_SUCCESS,
  PUT_COURSE_SUCCESS,
  PUT_CREDENTIAL_ERROR,
  PUT_CREDENTIAL_SUCCESS,
  PUT_DEGREE_LEVEL_INDEX_ERROR,
  PUT_DEGREE_LEVEL_INDEX_SUCCESS,
  PUT_DEPARTMENT_ERROR,
  PUT_DEPARTMENT_SUCCESS,
  PUT_EDUCATION_LEVEL_ERROR,
  PUT_EDUCATION_LEVEL_SUCCESS,
  PUT_ENCOUNTER_DRAFT_ERROR,
  PUT_ENCOUNTER_DRAFT_SUCCESS,
  PUT_ENCOUNTER_FINAL_ERROR,
  PUT_ENCOUNTER_FINAL_SUCCESS,
  PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_ERROR,
  PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_SUCCESS,
  PUT_GROUP_ERROR,
  PUT_GROUP_SUCCESS,
  PUT_INSTITUTION_ERROR,
  PUT_INSTITUTION_SUCCESS,
  PUT_LETTER_GRADE_ERROR,
  PUT_LETTER_GRADE_SUCCESS,
  PUT_PART_GRADE_COLLECTIONS_ERROR,
  PUT_PART_GRADE_COLLECTIONS_SUCCESS,
  PUT_PART_GRADE_DATA_SOURCE_ERROR,
  PUT_PART_GRADE_DATA_SOURCE_SUCCESS,
  PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_ERROR,
  PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_SUCCESS,
  PUT_PART_STATUS_COMMIT_ERROR,
  PUT_PART_STATUS_COMMIT_SUCCESS,
  PUT_PROGRAM_ASSESSMENT_COLLECTION_ERROR,
  PUT_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS,
  PUT_PROGRAM_ASSESSMENT_MASTER_ERROR,
  PUT_PROGRAM_ASSESSMENT_MASTER_SUCCESS,
  PUT_PROGRAM_ERROR,
  PUT_PROGRAM_SUCCESS,
  PUT_SCHOOL_ERROR,
  PUT_SCHOOL_SUCCESS,
  PUT_SCORE_ERROR,
  PUT_SCORE_SUCCESS,
  PUT_SECTION_DATASOURCE_ERROR,
  PUT_SECTION_DATASOURCE_SUCCESS,
  PUT_SECTION_PART_WEIGHTS_ERROR,
  PUT_SECTION_PART_WEIGHTS_SUCCESS,
  PUT_SECTION_STATUS_COMMIT_ERROR,
  PUT_SECTION_STATUS_COMMIT_SUCCESS,
  PUT_THIRD_PARTY_ID_ERROR,
  PUT_THIRD_PARTY_ID_SUCCESS,
  PUT_TRANSCRIPT_ERROR,
  PUT_TRANSCRIPT_SUCCESS,
  PUT_USER_ERROR,
  PUT_USER_PROFILE_ERROR,
  PUT_USER_PROFILE_SUCCESS,
  PUT_USER_ROLES_ERROR,
  PUT_USER_ROLES_SUCCESS,
  PUT_USER_SUCCESS,
  GET_SECTION_COMMITMENT_LOG_ERROR,
  PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_ERROR,
  DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS,
  DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_ERROR,
  PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_SUCCESS,
  PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_ERROR,
  PUT_PART_GRADE_DELAYED_STUDENT_SUCCESS,
  PUT_PART_GRADE_DELAYED_STUDENT_ERROR,
  DELETE_PART_GRADE_DELAYED_STUDENT_SUCCESS,
  DELETE_PART_GRADE_DELAYED_STUDENT_ERROR,
  PUT_PART_DELAYED_STATUS_COMMIT_SUCCESS,
  PUT_PART_DELAYED_STATUS_COMMIT_ERROR,
  PATCH_SECTION_GRADE_DELAYED_STUDENT_SUCCESS,
  PATCH_SECTION_GRADE_DELAYED_STUDENT_ERROR,
  DELETE_SECTION_GRADE_DELAYED_STUDENT_SUCCESS,
  DELETE_SECTION_GRADE_DELAYED_STUDENT_ERROR,
  PUT_SECTION_DELAYED_STATUS_COMMIT_SUCCESS,
  PUT_SECTION_DELAYED_STATUS_COMMIT_ERROR,
  PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_SUCCESS,
  PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_ERROR,
  DELETE_PART_LEGACY_GRADES_SUCCESS,
  DELETE_PART_LEGACY_GRADES_ERROR,
  DELETE_SECTION_LEGACY_GRADES_SUCCESS,
  DELETE_SECTION_LEGACY_GRADES_ERROR,
  GENERAL_NOTIFICATION_SUCCESS,
  POST_CLONE_EXISTING_PART_SUCCESS,
  POST_CLONE_EXISTING_PART_ERROR,
  POST_COHORT_COMPETENCY_GRID_ERROR,
  GET_CURRENT_RESULT_CARDS_ERROR,
  PUT_GROUP_RANKINGS_ERROR,
  PUT_ASSESSMENT_BLOCK_RESULT_STATUS_SUCCESS,
  PUT_ASSESSMENT_BLOCK_RESULT_STATUS_ERROR,
  PATCH_SCHOOL_DENOMINATORS_SUCCESS,
  PATCH_SCHOOL_DENOMINATORS_ERROR
} from '../actions-type';
import { GET_COHORT_ASSESSMENT_GRID_ERROR } from '../actions-type/assessmentGridActionTypes';

const types = {
  error: 'Error',
  success: 'Success',
  warning: 'Warning'
};

const INITIAL_STATE = {
  message: '',
  type: '',
  open: false,
  link: false,
  linkPath: ''
};

const genericSuccess = message => `Successfully ${message}`;
const genericFailure = message => `Failed to ${message}`;
// const fetchSuccess = type => `Successfully fetched ${type}`;
const fetchError = type => `Error: unable to retrieve ${type}`;
const createSuccessMessage = type => `Successfully created ${type}`;
const createErrorMessage = type => `Failed to create ${type}`;
const updateSuccessMessage = type => `Successfully updated ${type}`;
const updateErrorMessage = type => `Failed to update ${type}`;
const deleteSuccessMessage = type => `Successfully deleted ${type}`;
const deleteErrorMessage = type => `Failed to delete ${type}`;
const removeSuccessMessage = type => `Successfully removed ${type}`;
const removeErrorMessage = type => `Failed to remove ${type}`;
const defaultErrorPrefix = error => `Error: ${error}`;
const addToGroupSuccess = type => `Successfully added user(s) to ${type}`;
const addToGroupError = type => `Unable to add user(s) to ${type}`;
const putReportError = type => `Unable to generate ${type} report`;
const putReportSuccess = type => `Successfully generated ${type} report`;

/* 
the server will return an error which is either generated by a js object or js error,
this function will extract detail as one will return the error in a message and the other 
as the message in the error 
*/

const errorServer = action => {
  if (typeof action.payload === 'string') {
    return action.payload;
  } else if (typeof action.payload?.error === 'string') {
    return action.payload.error;
  }

  const asObject = _.get(action, 'payload.error.message', 'Unknown Error');
  const asErrorInstance = _.get(action, 'payload.error', 'Unknown Error');
  return asObject || asErrorInstance;
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GENERAL_NOTIFICATION_ERROR: {
      return {
        ...state,
        type: types.error,
        message: action.payload.error,
        open: true
      };
    }
    case GENERAL_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: action.payload.message,
        open: true
      };
    }
    case OPEN_NOTIFICATION: {
      return {
        ...state,
        open: true
      };
    }
    case CLOSE_NOTIFICATION: {
      return {
        ...INITIAL_STATE
      };
    }
    case PUT_INSTITUTION_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('institution'),
        open: true
      };
    }
    case PUT_INSTITUTION_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('institution'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_COURSES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('courses'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_COURSE_MASTER_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('course parent'),
        open: true
      };
    }
    case POST_COURSE_MASTER_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('course parent'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_COURSE_MASTER_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('course parent'),
        open: true
      };
    }
    case PUT_COURSE_MASTER_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('course parent'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_COURSE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('course section'),
        open: true
      };
    }
    case POST_COURSE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('course section'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_ENCOUNTER_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('encounter'),
        open: true
      };
    }
    case POST_ENCOUNTER_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('encounter'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_ENCOUNTER_DRAFT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('encounter'),
        open: true
      };
    }
    case PUT_ENCOUNTER_DRAFT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('encounter'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_ENCOUNTER_FINAL_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('encounter'),
        open: true
      };
    }
    case PUT_ENCOUNTER_FINAL_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('encounter'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_GROUP_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('group'),
        open: true
      };
    }
    case POST_GROUP_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('group'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('assessment'),
        open: true
      };
    }
    case POST_ASSESSMENT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('assessment'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_ASSESSMENT_DRAFT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('assessment'),
        open: true
      };
    }
    case PUT_ASSESSMENT_DRAFT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('assessment'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_ASSESSMENT_FINAL_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('assessment'),
        open: true
      };
    }
    case PUT_ASSESSMENT_FINAL_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('assessment'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_ASSESSMENT_STATUS_SCORE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('assessment'),
        open: true
      };
    }
    case PUT_ASSESSMENT_STATUS_SCORE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('assessment'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_ASSESSMENT_STATUS_COMMIT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('assessment'),
        open: true
      };
    }

    case PUT_ASSESSMENT_STATUS_COMMIT_ERROR: {
      const errorMessage = _.get(action, 'payload.error', '');
      const missingScoresError = errorMessage.toLowerCase().includes('scores');

      return missingScoresError
        ? { ...state }
        : {
            ...state,
            type: types.error,
            message: updateErrorMessage('assessment'),
            error_server: errorMessage,
            open: true
          };
    }
    case PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('delayed student grade'),
        open: true
      };
    }
    case PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('delayed student grade'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('delayed student grade'),
        open: true
      };
    }
    case DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: deleteErrorMessage('delayed student grade'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: 'Successfully updated delayed student grade commitment status',
        open: true
      };
    }
    case PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: 'Failed to update delayed student grade commitment status',
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: 'Successfully updated student grade commitment status',
        open: true
      };
    }
    case PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: 'Failed to update student grade commitment status',
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_PART_STATUS_COMMIT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('part'),
        open: true
      };
    }
    case PUT_PART_STATUS_COMMIT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('part'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_CLONE_EXISTING_PART_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: 'Successfully cloned part',
        open: true
      };
    }
    case POST_CLONE_EXISTING_PART_ERROR: {
      return {
        ...state,
        type: types.error,
        message: 'Failed to clone part',
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_PART_LEGACY_GRADES_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('part legacy grades'),
        open: true
      };
    }
    case DELETE_PART_LEGACY_GRADES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: deleteErrorMessage('part legacy grades'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_SECTION_LEGACY_GRADES_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('section legacy grades'),
        open: true
      };
    }
    case DELETE_SECTION_LEGACY_GRADES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: deleteErrorMessage('section legacy grades'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_PART_GRADE_DELAYED_STUDENT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('delayed student grade'),
        open: true
      };
    }
    case PUT_PART_GRADE_DELAYED_STUDENT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('delayed student grade'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_PART_GRADE_DELAYED_STUDENT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('delayed student grade'),
        open: true
      };
    }
    case DELETE_PART_GRADE_DELAYED_STUDENT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: deleteErrorMessage('delayed student grade'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_PART_DELAYED_STATUS_COMMIT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: 'Successfully updated delayed student grade commitment status',
        open: true
      };
    }
    case PUT_PART_DELAYED_STATUS_COMMIT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: 'Failed to update delayed student grade commitment status',
        error_server: errorServer(action),
        open: true
      };
    }
    case PATCH_SECTION_GRADE_DELAYED_STUDENT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('delayed student grade'),
        open: true
      };
    }
    case PATCH_SECTION_GRADE_DELAYED_STUDENT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('delayed student grade'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_SECTION_GRADE_DELAYED_STUDENT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('delayed student grade'),
        open: true
      };
    }
    case DELETE_SECTION_GRADE_DELAYED_STUDENT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: deleteErrorMessage('delayed student grade'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_SECTION_DELAYED_STATUS_COMMIT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: 'Successfully updated delayed student grade commitment status',
        open: true
      };
    }
    case PUT_SECTION_DELAYED_STATUS_COMMIT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: 'Failed to update delayed student grade commitment status',
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_ASSESSMENT_GRADING_TECHNIQUE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('grading technique'),
        open: true
      };
    }
    case PUT_ASSESSMENT_GRADING_TECHNIQUE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('grading technique'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_APPLICANT_COGNITIVE_REPORT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: putReportError('Cognitive'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_APPLICANT_COGNITIVE_REPORT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: putReportSuccess('Cognitive'),
        open: true
      };
    }
    case PUT_APPLICANT_COMMUNICATION_REPORT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: putReportError('Communication'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_APPLICANT_COMMUNICATION_REPORT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: putReportSuccess('Communication'),
        open: true
      };
    }
    case PUT_APPLICANT_DEMOGRAPHIC_REPORT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: putReportError('Demographic'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_APPLICANT_DEMOGRAPHIC_REPORT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: putReportSuccess('Demographic'),
        open: true
      };
    }
    case PUT_APPLICANT_NONCOGNITIVE_REPORT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: putReportError('Non-Cognitive'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_APPLICANT_NONCOGNITIVE_REPORT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: putReportSuccess('Non-Cognitive'),
        open: true
      };
    }
    case PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: putReportError('Physical Skills'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: putReportSuccess('Physical Skills'),
        open: true
      };
    }
    case DELETE_ENCOUNTER_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('encounter'),
        open: true
      };
    }
    case DELETE_ENCOUNTER_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('encounter'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_ASSESSMENT_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('assessment'),
        open: true
      };
    }
    case DELETE_ASSESSMENT_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('assessment'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_COURSE_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('course'),
        open: true
      };
    }
    case PUT_COURSE_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('course'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PATCH_PART_STATUS_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('part status'),
        open: true
      };
    }
    case PATCH_PART_STATUS_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('part status'),
        error_server: errorServer(action),
        open: true
      };
    }
    case COMPLETE_STATUS_CHECK_ERROR: {
      return {
        type: types.error,
        message: genericFailure(
          'change status all items must be complete before they can be reviewed'
        ),
        error_server: errorServer(action),
        open: true
      };
    }
    case APPROVE_STATUS_CHECK_ERROR: {
      return {
        type: types.error,
        message: genericFailure(
          'approve all items must be approved before they can be committed'
        ),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_USER_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('user'),
        open: true
      };
    }
    case POST_USER_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('user'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_USER_GROUPS_SUCCESS: {
      return {
        type: types.success,
        message: addToGroupSuccess('group'),
        open: true
      };
    }
    case POST_USER_GROUPS_ERROR: {
      return {
        type: types.error,
        message: addToGroupError('group'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_USER_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('user'),
        open: true
      };
    }
    case PUT_USER_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('user'),
        error_server: errorServer(action),
        open: true
      };
    }

    case DELETE_USER_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('user'),
        open: true
      };
    }
    case DELETE_USER_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('user'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_USER_PROFILE_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('user Profile'),
        open: true
      };
    }
    case PUT_USER_PROFILE_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('user Profile'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_USER_DEPARTMENTS_FACULTY_ERROR: {
      return {
        type: types.error,
        message: fetchError('faculty'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_SYLLABUS_ERROR: {
      return {
        type: types.error,
        message: fetchError('syllabus'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_COURSE_MASTER_BY_DEPARTMENT_ERROR: {
      return {
        type: types.error,
        message: fetchError('courses by department'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_COURSE_MASTER_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('course parent'),
        open: true
      };
    }
    case DELETE_COURSE_MASTER_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('course parent'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_COURSE_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('course sections'),
        open: true
      };
    }
    case DELETE_COURSE_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('course sections'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_SCHOOL_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('school'),
        open: true
      };
    }
    case POST_SCHOOL_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('school'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_SCHOOL_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('school'),
        open: true
      };
    }
    case PUT_SCHOOL_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('school'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_SCHOOL_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('school'),
        open: true
      };
    }
    case DELETE_SCHOOL_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('school'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PATCH_SCHOOL_REVUS_SUCCESS: {
      return {
        type: types.success,
        message: genericSuccess('saved school revus'),
        open: true
      };
    }
    case PATCH_SCHOOL_REVUS_ERROR: {
      return {
        type: types.error,
        message: genericFailure('save school revus'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PATCH_SCHOOL_DENOMINATORS_SUCCESS: {
      return {
        type: types.success,
        message: genericSuccess('saved school denominators'),
        open: true
      };
    }
    case PATCH_SCHOOL_DENOMINATORS_ERROR: {
      return {
        type: types.error,
        message: genericFailure('save school denominators'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_LETTER_GRADE_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('letter grade'),
        open: true
      };
    }
    case POST_LETTER_GRADE_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('letter grade'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_LETTER_GRADE_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('letter grade'),
        open: true
      };
    }
    case PUT_LETTER_GRADE_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('letter grade'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_LETTER_GRADE_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('letter grade'),
        open: true
      };
    }
    case DELETE_LETTER_GRADE_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('letter grade'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_ACADEMIC_YEAR_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('academic year'),
        open: true
      };
    }
    case POST_ACADEMIC_YEAR_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('academic year'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_ACADEMIC_YEAR_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('academic year'),
        open: true
      };
    }
    case PUT_ACADEMIC_YEAR_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('academic year'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_ACADEMIC_YEAR_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('academic year'),
        open: true
      };
    }
    case DELETE_ACADEMIC_YEAR_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('academic year'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_EDUCATION_LEVEL_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('education level'),
        open: true
      };
    }
    case POST_EDUCATION_LEVEL_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('education level'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_EDUCATION_LEVEL_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('education level'),
        open: true
      };
    }
    case PUT_EDUCATION_LEVEL_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('education level'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_EDUCATION_LEVEL_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('education level'),
        open: true
      };
    }
    case DELETE_EDUCATION_LEVEL_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('education level'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_DEPARTMENT_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('department'),
        open: true
      };
    }
    case POST_DEPARTMENT_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('department'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_DEPARTMENT_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('department'),
        open: true
      };
    }
    case PUT_DEPARTMENT_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('department'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_GROUP_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('group'),
        open: true
      };
    }
    case PUT_GROUP_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('group'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_DEPARTMENT_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('department'),
        open: true
      };
    }
    case DELETE_DEPARTMENT_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('department'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_STUDENT_ROSTER_ERROR: {
      return {
        type: types.error,
        message: fetchError('student roster'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_DEGREE_LEVELS_ERROR: {
      return {
        type: types.error,
        message: fetchError('degree levels'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_ACCREDITATION_AGENCIES_ERROR: {
      return {
        type: types.error,
        message: fetchError('accediation agencies'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_PROGRAM_CIPS_ERROR: {
      return {
        type: types.error,
        message: fetchError('program CIPs'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_PROGRAM_DISCIPLINES_ERROR: {
      return {
        type: types.error,
        message: fetchError('program disciplines'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_PROGRAM_CODES_ERROR: {
      return {
        type: types.error,
        message: fetchError('program codes'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_PROGRAM_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('program'),
        open: true
      };
    }
    case POST_PROGRAM_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('program'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_COHORT_ASSESSMENT_COLLECTION_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('cohort assessment collection'),
        open: true
      };
    }
    case POST_COHORT_ASSESSMENT_COLLECTION_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('cohort assessment collection'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('program assessment collection'),
        open: true
      };
    }
    case POST_PROGRAM_ASSESSMENT_COLLECTION_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('program assessment collection'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_PROGRAM_ASSESSMENT_MASTER_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('program assessment master'),
        open: true
      };
    }
    case POST_PROGRAM_ASSESSMENT_MASTER_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('program assessment master'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_COHORT_COMPETENCY_GRID_ERROR: {
      return {
        ...state,
        type: types.error,
        message: 'Failed to fetch competency grid report',
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_COHORT_ASSESSMENT_GRID_ERROR: {
      return {
        ...state,
        type: types.error,
        message: 'Failed to fetch assessment grid report',
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_COHORT_ASSESSMENT_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('cohort assessment'),
        open: true
      };
    }
    case POST_COHORT_ASSESSMENT_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('cohort assessment'),
        error_server: _.get(action, 'payload.error'),
        open: true
      };
    }
    case PUT_SECTION_DATASOURCE_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('section data source'),
        open: true
      };
    }
    case PUT_SECTION_DATASOURCE_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('section data source'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_SECTION_PART_WEIGHTS_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('section part weights'),
        open: true
      };
    }
    case PUT_SECTION_PART_WEIGHTS_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('section part weights'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_PART_GRADE_DATA_SOURCE_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('part grade data source'),
        open: true
      };
    }
    case PUT_PART_GRADE_DATA_SOURCE_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('part grade data source'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_PART_GRADE_COLLECTIONS_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('part grade collections'),
        open: true
      };
    }
    case PUT_PART_GRADE_COLLECTIONS_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('part grade collections'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_COLLECTION_ASSESSMENTS_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('collection assessments'),
        open: true
      };
    }
    case PUT_COLLECTION_ASSESSMENTS_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('collection assessments'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('collection grade modifiers'),
        open: true
      };
    }
    case PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('collection grade modifiers'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('part grade modifiers'),
        open: true
      };
    }
    case PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('part grade modifiers'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_PROGRAM_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('program'),
        open: true
      };
    }
    case PUT_PROGRAM_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('program'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('program assessment collection'),
        open: true
      };
    }
    case PUT_PROGRAM_ASSESSMENT_COLLECTION_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('program assessment collection'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_COHORT_ASSESSMENT_COLLECTION_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('cohort assessment collection'),
        open: true
      };
    }
    case PUT_COHORT_ASSESSMENT_COLLECTION_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('cohort assessment collection'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_PROGRAM_ASSESSMENT_MASTER_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('program assessment master'),
        open: true
      };
    }
    case PUT_PROGRAM_ASSESSMENT_MASTER_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('program assessment master'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_COHORT_ASSESSMENT_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('cohort assessment'),
        open: true
      };
    }
    case PUT_COHORT_ASSESSMENT_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('cohort assessment'),
        error_server: _.get(action, 'payload.error'),
        open: true
      };
    }
    case PATCH_COHORT_ASSESSMENT_STATUS_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('cohort assessment'),
        open: true
      };
    }
    case PATCH_COHORT_ASSESSMENT_STATUS_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('cohort assessment'),
        error_server: _.get(action, 'payload.error'),
        open: true
      };
    }
    case DELETE_PROGRAM_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('program'),
        open: true
      };
    }
    case DELETE_PROGRAM_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('program'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_COHORT_ASSESSMENT_COLLECTION_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('cohort assessment collection'),
        open: true
      };
    }
    case DELETE_COHORT_ASSESSMENT_COLLECTION_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('cohort assessment collection'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_PROGRAM_ASSESSMENT_COLLECTION_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('program assessment collection'),
        open: true
      };
    }
    case DELETE_PROGRAM_ASSESSMENT_COLLECTION_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('program assessment collection'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_PROGRAM_ASSESSMENT_MASTER_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('program assessment master'),
        open: true
      };
    }
    case DELETE_PROGRAM_ASSESSMENT_MASTER_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('program assessment master'),
        error_server: _.get(action, 'payload.error'),
        open: true
      };
    }
    case DELETE_COHORT_ASSESSMENT_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('cohort assessment'),
        open: true
      };
    }
    case DELETE_COHORT_ASSESSMENT_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('cohort assessment'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_SCHOOL_DEPARTMENTS_ERROR: {
      return {
        type: types.error,
        message: fetchError('departments'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_PROGRAM_COHORTS_ERROR: {
      return {
        type: types.error,
        message: fetchError('Cohorts'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_COHORT_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('cohort'),
        open: true
      };
    }
    case POST_COHORT_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('cohort'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_COHORT_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('cohort'),
        open: true
      };
    }
    case PUT_COHORT_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('cohort'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_COHORT_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('cohort'),
        open: true
      };
    }
    case DELETE_COHORT_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('cohort'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_COURSE_COLLECTIONS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('course collections'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_CALCULATE_ENCOUNTER_OBJECTIVES_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : 'Failed to calculate learning objectives',
        open: true
      };
    }
    case POST_CALCULATE_ENCOUNTER_OBJECTIVES_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: 'Successfully calculated learning objectives',
        open: true
      };
    }
    case POST_COURSE_COLLECTION_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : createErrorMessage('course collection'),
        open: true
      };
    }
    case POST_COURSE_COLLECTION_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('course collection'),
        open: true
      };
    }
    case PUT_COURSE_COLLECTION_ERROR: {
      return {
        ...state,
        type: types.error,
        message: action.payload.error
          ? defaultErrorPrefix(action.payload.error)
          : updateErrorMessage('course collection'),
        open: true
      };
    }
    case PUT_COURSE_COLLECTION_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('course collection'),
        open: true
      };
    }
    case DELETE_COURSE_COLLECTION_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('course collection'),
        open: true
      };
    }
    case DELETE_COURSE_COLLECTION_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('course collection'),
        open: true
      };
    }
    case GET_COMPETENCY_COLLECTIONS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Competency Collections'),
        open: true
      };
    }
    case GET_COHORT_ASSESSMENTS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Cohort Assessments & Collections'),
        open: true
      };
    }
    case GET_COHORT_ASSESSMENT_SCORES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Cohort Assessment Scores'),
        open: true
      };
    }
    case GET_COMPETENCY_COLLECTION_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Competency Collection'),
        open: true
      };
    }
    case POST_COMPETENCY_COLLECTION_ERROR: {
      const errorText = _.get(action, 'payload.error', false);
      return {
        ...state,
        type: types.error,
        message: errorText
          ? `Error: ${errorText}`
          : createErrorMessage('competency collection'),
        open: true
      };
    }
    case POST_COMPETENCY_COLLECTION_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('competency collection'),
        open: true
      };
    }
    case POST_COMPETENCY_ERROR: {
      const errorText = _.get(action, 'payload.error', false);
      return {
        ...state,
        type: types.error,
        message: errorText
          ? `Error: ${errorText}`
          : createErrorMessage('competency'),
        open: true
      };
    }
    case POST_COMPETENCY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('competency'),
        open: true
      };
    }
    case PUT_COMPETENCY_COLLECTION_ERROR: {
      return {
        ...state,
        type: types.error,
        message: action.payload.error
          ? defaultErrorPrefix(action.payload.error)
          : updateErrorMessage('competency collection'),
        open: true
      };
    }
    case PUT_COMPETENCY_COLLECTION_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('competency collection'),
        open: true
      };
    }
    case DELETE_COMPETENCY_COLLECTION_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('competency collection'),
        open: true
      };
    }
    case DELETE_COMPETENCY_COLLECTION_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('competency collection'),
        open: true
      };
    }
    case PUT_COMPETENCY_ERROR: {
      return {
        ...state,
        type: types.error,
        message: action.payload.error
          ? defaultErrorPrefix(action.payload.error)
          : updateErrorMessage('competency'),
        open: true
      };
    }
    case PUT_COMPETENCY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('competency'),
        open: true
      };
    }
    case PUT_COMPETENCY_MICROS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: action.payload.error
          ? defaultErrorPrefix(action.payload.error)
          : updateErrorMessage('competency microcompetencies'),
        open: true
      };
    }
    case PUT_COMPETENCY_MICROS_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('competency microcompetencies'),
        open: true
      };
    }
    case DELETE_COMPETENCY_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('competency'),
        open: true
      };
    }
    case DELETE_COMPETENCY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('competency'),
        open: true
      };
    }
    case DELETE_GROUP_ERROR: {
      return {
        type: types.error,
        message: removeErrorMessage('user'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_GROUP_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('group'),
        open: true
      };
    }
    case GET_USERS_COHORT_ERROR: {
      return {
        type: types.error,
        message: fetchError('applicants and students'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_USERS_BY_SEARCH_INSTITUTION_ID_ERROR: {
      return {
        type: types.error,
        message: fetchError('users'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_USER_COHORT_SUCCESS: {
      return {
        type: types.success,
        message: genericSuccess('added applicant'),
        open: true
      };
    }
    case POST_USER_COHORT_ERROR: {
      return {
        type: types.error,
        message: genericFailure('add applicant'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_USER_COHORT_BY_USER_AND_COHORT_ERROR: {
      return {
        type: types.error,
        message: fetchError('user roles within cohort'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_SECTION_PERFORMANCE_REPORT_ERROR: {
      return {
        type: types.error,
        message: genericFailure('retrieve Section Performance Report'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_USER_COHORTS_SUCCESS: {
      return {
        type: types.success,
        message: genericSuccess('saved role changes'),
        open: true
      };
    }
    case POST_USER_COHORTS_ERROR: {
      return {
        type: types.error,
        message: genericFailure('saving role changes'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PATCH_USER_COHORT_STATUS_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('status of users'),
        open: true
      };
    }
    case PATCH_USER_COHORT_STATUS_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('status of users'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_USER_COHORTS_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('applicants'),
        open: true
      };
    }
    case DELETE_USER_COHORTS_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('applicants'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_USER_GROUP_SUCCESS: {
      return {
        type: types.success,
        message: removeSuccessMessage('user'),
        open: true
      };
    }
    case DELETE_USER_GROUP_ERROR: {
      return {
        type: types.error,
        message: removeErrorMessage('user'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_STUDENT_CONVERSION_SUCCESS: {
      return {
        type: types.success,
        message: `${action.payload.userCohorts.length / 2} students created.`,
        link: true,
        linkPath: `/program-management/cohort/students/${action.payload.userCohorts[0].cohortUuid}`,
        linkText: 'View Students',
        open: true
      };
    }
    case POST_STUDENT_CONVERSION_ERROR: {
      return {
        type: types.error,
        message: genericFailure('convert applicants to students'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_COHORT_APPLICANTS_BY_STATUS_ERROR: {
      return {
        type: types.error,
        message: 'Failed retrieveing active applicants for cohort.',
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_COURSES_BY_DEPARTMENT_AY_ERROR: {
      return {
        type: types.error,
        message: fetchError('courses for department and academic year'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS: {
      return {
        type: types.success,
        message: genericSuccess('found student'),

        open: true
      };
    }
    case GET_SCHOOL_STUDENTS_BY_STATUS_ERROR: {
      return {
        type: types.error,
        message: genericFailure('find student'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_USER_SECTION_SUCCESS: {
      return {
        type: types.success,
        message: genericSuccess('added student to section'),
        open: true
      };
    }
    case POST_USER_SECTION_ERROR: {
      return {
        type: types.error,
        message: genericFailure('add student to section'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PATCH_STUDENT_SECTION_STATUS_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('student status within section'),
        open: true
      };
    }
    case PATCH_STUDENT_SECTION_STATUS_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('student status within section'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_USERS_SECTION_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('students'),
        open: true
      };
    }
    case DELETE_USERS_SECTION_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('students'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PATCH_ENCOUNTER_APPROVE_ERROR: {
      return {
        type: types.error,
        message: genericFailure('change status to approved'),
        error_server: errorServer(action),
        open: true
      };
    }

    case GET_PART_FACULTY_ERROR: {
      return {
        type: types.error,
        message: fetchError('part faculty'),
        error_server: errorServer(action),
        open: true
      };
    }

    case GET_PART_GRADE_SETTINGS_ERROR: {
      return {
        type: types.error,
        message: fetchError('part grade settings'),
        error_server: errorServer(action),
        open: true
      };
    }

    case GET_PART_FACULTY_REPORT_ERROR: {
      return {
        type: types.error,
        message: fetchError('part faculty report'),
        error_server: errorServer(action),
        open: true
      };
    }

    case POST_SCORE_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('score'),
        open: true
      };
    }
    case POST_SCORE_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('score'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_SCORE_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('score'),
        open: true
      };
    }
    case PUT_SCORE_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('score'),
        error_server: errorServer(action),
        open: true
      };
    }

    case DELETE_SCORE_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('score'),
        open: true
      };
    }
    case DELETE_SCORE_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('score'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_STUDENT_SCORES_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('student scores'),
        open: true
      };
    }
    case PUT_ASSESSMENT_BLOCK_RESULT_STATUS_SUCCESS: {
      return {
        type: types.success,
        message: 'Status successfully updated!',
        open: true
      };
    }
    case PUT_ASSESSMENT_BLOCK_RESULT_STATUS_ERROR: {
      return {
        type: types.error,
        message: 'There was an error when trying to update the status',
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_SCORES_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('assessment scores'),
        open: true
      };
    }
    case DELETE_SCORES_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('assessment scores'),
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_STUDENT_SCORES_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('scores'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PATCH_PART_LOCKED_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('part locked status'),
        error_server: errorServer(action),
        open: true
      };
    }
    case PATCH_PART_CALCULATE_GRADE_ERROR: {
      return {
        type: types.error,
        message: 'Failed to start grading part',
        error_server: errorServer(action),
        open: true
      };
    }
    case PATCH_PART_UNDO_CALCULATED_GRADE_SUCCESS: {
      return {
        type: types.success,
        message: 'Successfully removed all calculated part grades',
        open: true
      };
    }
    case PATCH_PART_UNDO_CALCULATED_GRADE_ERROR: {
      return {
        type: types.error,
        message: 'Failed to undo calculated part grades',
        error_server: errorServer(action),
        open: true
      };
    }
    case PATCH_SECTION_CALCULATE_GRADE_SUCCESS: {
      return {
        type: types.success,
        message: 'Successfully calculated section grades',
        open: true
      };
    }
    case PATCH_SECTION_CALCULATE_GRADE_ERROR: {
      return {
        type: types.error,
        message: 'Failed to calculate section grades',
        error_server: errorServer(action),
        open: true
      };
    }
    case PUT_SECTION_STATUS_COMMIT_SUCCESS: {
      return {
        type: types.success,
        message: 'Successfully committed section',
        open: true
      };
    }
    case PUT_SECTION_STATUS_COMMIT_ERROR: {
      return {
        type: types.error,
        message: 'Failed to commit section',
        error_server: errorServer(action),
        open: true
      };
    }
    case DELETE_SECTION_UNDO_CALCULATED_GRADE_SUCCESS: {
      return {
        type: types.success,
        message: 'Successfully removed all calculated section grades',
        open: true
      };
    }
    case DELETE_SECTION_UNDO_CALCULATED_GRADE_ERROR: {
      return {
        type: types.error,
        message: 'Failed to undo calculated section grades',
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_ASSESSMENT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('assessment'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_SCHOOLS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('schools'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_ACADEMIC_YEAR_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('academic year'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_ACADEMIC_YEARS_BY_SCHOOL_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('academic years by school'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_COHORT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('cohort'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_COHORTS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('cohorts'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_COURSE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('course'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_COURSE_MASTER_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('course master'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_DEPARTMENT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('department'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_DEPARTMENTS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('departments'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_EDUCATION_LEVEL_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('education level'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_EDUCATION_LEVELS_BY_SCHOOL_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('education level by school'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_LETTER_GRADES_BY_SCHOOL_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('letter grades by school'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_LOVERBS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('loverbs'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_LOVERBS_BY_EDUCATION_LEVELS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('loverbs by education levels'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_SCHOOL_PROGRAMS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('school programs'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_PROGRAM_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('program'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_PROGRAM_ASSESSMENT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('program assessment'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_PROGRAM_ASSESSMENTS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('program assessments'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_PROGRAMS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('programs'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_SCORES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('scores'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_SECTION_NESTED_BY_COURSE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('section nested by course'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_SECTION_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('section'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_SECTION_COMMITMENT_LOG_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('section commitment log'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_TERMS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('terms'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_TERMS_BY_ACADEMIC_YEARS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('terms by academic years'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_USER_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('user'),
        error_server: errorServer(action),
        open: true
      };
    }

    case GET_USER_DEPARTMENT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('user department'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_USER_DEPARTMENTS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('user departments'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_USER_PROGRAMS_BY_PROGRAM_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('user programs by program'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_USER_PROGRAMS_BY_PROGRAMS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('user programs by programs'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_USERS_SCHOOL_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('user school'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_USERS_SCHOOLS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('user schools'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_USERS_SCHOOL_ADMIN_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('user school admin'),
        error_server: errorServer(action),
        open: true
      };
    }

    case GET_CLASSIFICATIONS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('classifications'),
        error_server: errorServer(action),
        open: true
      };
    }

    case GET_SUBJECTS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('subjects'),
        error_server: errorServer(action),
        open: true
      };
    }

    case POST_SUBJECTS_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('subjects'),
        open: true
      };
    }

    case POST_SUBJECTS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('subjects'),
        error_server: errorServer(action),
        open: true
      };
    }

    case POST_MILESTONES_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('milestones'),
        open: true
      };
    }

    case POST_MILESTONES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('milestones'),
        error_server: errorServer(action),
        open: true
      };
    }

    case GET_SOURCE_INSTITUTIONS_BY_CODE_SUCCESS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('institution by provided code'),
        error_server: errorServer(action),
        open: true
      };
    }

    case GET_APTITUDES_BY_CATEGORY_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('aptitudes by category'),
        error_server: errorServer(action),
        open: true
      };
    }

    case POST_APTITUDES_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('aptitudes'),
        open: true
      };
    }

    case POST_APTITUDES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('aptitudes'),
        error_server: errorServer(action),
        open: true
      };
    }

    case GET_TRANSCRIPT_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('applicant trancript'),
        error_server: errorServer(action),
        open: true
      };
    }

    case POST_TRANSCRIPT_UPLOAD_ERROR: {
      return {
        ...state,
        type: types.error,
        message: genericFailure('upload transcript'),
        error_server: errorServer(action),
        open: true
      };
    }

    case DELETE_TRANSCRIPT_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('transcript'),
        open: true
      };
    }

    case DELETE_TRANSCRIPT_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('transcript'),
        error_server: errorServer(action),
        open: true
      };
    }

    case DELETE_TRANSCRIPTS_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('transcripts'),
        open: true
      };
    }

    case DELETE_TRANSCRIPTS_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('transcripts'),
        error_server: errorServer(action),
        open: true
      };
    }

    case POST_TRANSCRIPT_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('applicant trancript'),
        open: true
      };
    }
    case POST_TRANSCRIPT_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('applicant trancript'),
        error_server: errorServer(action),
        open: true
      };
    }

    case PUT_TRANSCRIPT_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('applicant trancript'),
        open: true
      };
    }
    case PUT_TRANSCRIPT_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('applicant trancript'),
        error_server: errorServer(action),
        open: true
      };
    }
    case GET_USER_ACTIVE_ERROR: {
      return {
        type: types.error,
        message: _.get(
          action,
          'payload.error',
          genericFailure('load user and roles.')
        ),
        open: true
      };
    }
    case PUT_USER_ROLES_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('user roles'),
        open: true
      };
    }
    case PUT_USER_ROLES_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('user roles'),
        error_server: errorServer(action),
        open: true
      };
    }
    case POST_USER_ROLE_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('user role'),
        open: true
      };
    }
    case POST_USER_ROLE_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('user role'),
        error_server: errorServer(action),
        open: true
      };
    }

    case POST_USER_APP_ID_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('user app Id'),
        open: true
      };
    }
    case POST_USER_APP_ID_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('user app Id'),
        error_server: errorServer(action),
        open: true
      };
    }

    case DELETE_APP_ID_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('user app Id'),
        open: true
      };
    }
    case DELETE_APP_ID_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('user app Id'),
        error_server: errorServer(action),
        open: true
      };
    }

    case POST_CREDENTIAL_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('credential'),
        open: true
      };
    }
    case POST_CREDENTIAL_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('credential'),
        error_server: errorServer(action),
        open: true
      };
    }

    case PUT_CREDENTIAL_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('credential'),
        open: true
      };
    }
    case PUT_CREDENTIAL_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('credential'),
        error_server: errorServer(action),
        open: true
      };
    }

    case DELETE_CREDENTIAL_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('credential'),
        open: true
      };
    }

    case DELETE_CREDENTIAL_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('credential'),
        error_server: errorServer(action),
        open: true
      };
    }

    case DELETE_USER_COHORT_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('user cohort role'),
        open: true
      };
    }

    case DELETE_USER_COHORT_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('user cohort role'),
        error_server: errorServer(action),
        open: true
      };
    }

    case PUT_THIRD_PARTY_ID_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('third party Id'),
        open: true
      };
    }
    case PUT_THIRD_PARTY_ID_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('third party Id'),
        error_server: errorServer(action),
        open: true
      };
    }

    case POST_CLONE_TRANSCRIPT_SUCCESS: {
      return {
        type: types.success,
        message: genericSuccess('clone transcript'),
        open: true
      };
    }

    case POST_CLONE_TRANSCRIPT_ERROR: {
      return {
        type: types.error,
        message: genericFailure('clone transcript'),
        error_server: errorServer(action),
        open: true
      };
    }

    case POST_INSTITUTION_RIGOR_SUCCESS: {
      return {
        type: types.success,
        message: genericSuccess('generated rigor index'),
        open: true
      };
    }

    case POST_INSTITUTION_RIGOR_ERROR: {
      return {
        type: types.error,
        message: genericFailure('generated rigor index'),
        error_server: errorServer(action),
        open: true
      };
    }

    case POST_DEGREE_LEVEL_INDEX_SUCCESS: {
      return {
        type: types.success,
        message: createSuccessMessage('degree level index'),
        open: true
      };
    }

    case POST_DEGREE_LEVEL_INDEX_ERROR: {
      return {
        type: types.error,
        message: createErrorMessage('degree level index'),
        error_server: errorServer(action),
        open: true
      };
    }

    case PUT_DEGREE_LEVEL_INDEX_SUCCESS: {
      return {
        type: types.success,
        message: updateSuccessMessage('degree level index'),
        open: true
      };
    }

    case PUT_DEGREE_LEVEL_INDEX_ERROR: {
      return {
        type: types.error,
        message: updateErrorMessage('degree level index'),
        error_server: errorServer(action),
        open: true
      };
    }

    case DELETE_DEGREE_LEVEL_INDEX_SUCCESS: {
      return {
        type: types.success,
        message: deleteSuccessMessage('degree level index'),
        open: true
      };
    }

    case DELETE_DEGREE_LEVEL_INDEX_ERROR: {
      return {
        type: types.error,
        message: deleteErrorMessage('degree level index'),
        error_server: errorServer(action),
        open: true
      };
    }
   
    case GET_CURRENT_RESULT_CARDS_ERROR: {
      return {
        type: types.error,
        message: 'Failed to get part performance result cards',
        error_server: errorServer(action),
        open: true
      };
    }
   
    case PUT_GROUP_RANKINGS_ERROR: {
      return {
        type: types.error,
        message: 'Failed to get group rankings',
        error_server: errorServer(action),
        open: true
      };
    }

    case POST_COURSE_AGE_MOD_SUCCESS: {
      return {
        type: types.success,
        message: genericSuccess('saved course age modification'),
        open: true
      };
    }

    case POST_COURSE_AGE_MOD_ERROR: {
      return {
        type: types.error,
        message: genericFailure('save course age modification'),
        error_server: errorServer(action),
        open: true
      };
    }

    default:
      return state;
  }
};

export default notificationReducer;
export { types, INITIAL_STATE };
