import {
  GET_USER_DEPARTMENT,
  GET_USER_DEPARTMENT_SUCCESS,
  GET_USER_DEPARTMENT_ERROR,
  GET_USER_DEPARTMENTS,
  GET_USER_DEPARTMENTS_SUCCESS,
  GET_USER_DEPARTMENTS_ERROR,
  GET_USER_DEPARTMENTS_FACULTY,
  GET_USER_DEPARTMENTS_FACULTY_SUCCESS,
  GET_USER_DEPARTMENTS_FACULTY_ERROR,
  POST_USER_DEPARTMENTS_ASSIGN,
  POST_ASSIGN_FACULTY_STAFF_USERS_SUCCESS,
  POST_ASSIGN_FACULTY_STAFF_USERS_ERROR,
  POST_ASSIGN_FACULTY_STAFF_USERS_WARNING,
  RESET_ASSIGN_USERS
} from '../actions-type';

const doGetUserDepartment = departmentUuid => ({
  type: GET_USER_DEPARTMENT,
  payload: {
    departmentUuid
  }
});

const doGetUserDepartmentSuccess = result => ({
  type: GET_USER_DEPARTMENT_SUCCESS,
  payload: result
});

const doGetUserDepartmentError = error => ({
  type: GET_USER_DEPARTMENT_ERROR,
  payload: error
});

const doGetUserDepartments = departments => ({
  type: GET_USER_DEPARTMENTS,
  payload: departments
});

const doGetUserDepartmentsSuccess = result => ({
  type: GET_USER_DEPARTMENTS_SUCCESS,
  payload: result
});

const doGetUserDepartmentsError = error => ({
  type: GET_USER_DEPARTMENTS_ERROR,
  payload: error
});

const doGetUsersFaculty = departmentUuid => ({
  type: GET_USER_DEPARTMENTS_FACULTY,
  payload: { departmentUuid }
});

const doGetUsersFacultySuccess = payload => ({
  type: GET_USER_DEPARTMENTS_FACULTY_SUCCESS,
  payload
});

const doGetUsersFacultyError = error => ({
  type: GET_USER_DEPARTMENTS_FACULTY_ERROR,
  payload: error
});

const doPostUserDepartments = roles => ({
  type: POST_USER_DEPARTMENTS_ASSIGN,
  payload: roles
});

const doPostUserDepartmentsSuccess = results => ({
  type: POST_ASSIGN_FACULTY_STAFF_USERS_SUCCESS,
  payload: results
});

const doPostUserDepartmentsError = error => ({
  type: POST_ASSIGN_FACULTY_STAFF_USERS_ERROR,
  payload: error
});

const doPostUserDepartmentsWarning = warning => ({
  type: POST_ASSIGN_FACULTY_STAFF_USERS_WARNING,
  payload: { warning }
});

const doResetAssignState = () => ({
  type: RESET_ASSIGN_USERS
});

export {
  doGetUsersFaculty,
  doGetUsersFacultySuccess,
  doGetUsersFacultyError,
  doGetUserDepartment,
  doGetUserDepartmentSuccess,
  doGetUserDepartmentError,
  doGetUserDepartments,
  doGetUserDepartmentsSuccess,
  doGetUserDepartmentsError,
  doPostUserDepartments,
  doPostUserDepartmentsSuccess,
  doPostUserDepartmentsError,
  doPostUserDepartmentsWarning,
  doResetAssignState
};
