import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sortByDecimalAndAlphaProperty } from '../../../../helpers/utilities';
import { topicSelector } from '../../../../redux/selectors/topicSelectors';
import TopicList from './TopicList';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 60vh;
  align-items: center;
  font-size: 80px;
`;

export default function TopicListContainer({
  assessmentScores,
  assessmentUuid,
  assessmentType,
  faculty,
  partUuid,
  scoreType,
  students
}) {
  const [topicScoreViewSelection, setTopicScoreViewSelection] =
    useState('default');

  const loading = useSelector(state => state.crudState.readScores);

  const topicUuids = _.reduce(
    assessmentScores,
    (acc, score) => {
      if (!acc.includes(score.topicUuid)) return acc.concat([score.topicUuid]);
      return acc;
    },
    []
  );

  const topics = useSelector(
    state => topicSelector(state, topicUuids),
    _.isEqual
  );

  const defaultTopicScoreData = useMemo(
    () =>
      topicUuids.reduce(
        (obj, topicUuid) => ({
          ...obj,
          [topicUuid]: {
            totalRelative: '0.00',
            totalPotential: '0.00',
            topicScores: []
          }
        }),
        {}
      ),
    [topicUuids]
  );
  const { topicScoreData, cumulativePotentialScore } = _.reduce(
    assessmentScores,
    (acc, score) => {
      const { topicUuid } = score;
      const curTotalRelative = new Decimal(
        acc.topicScoreData[topicUuid].totalRelative
      );
      const curTotalPotential = new Decimal(
        acc.topicScoreData[topicUuid].totalPotential
      );
      const curCumulativePotential = new Decimal(acc.cumulativePotentialScore);
      acc.topicScoreData[topicUuid].totalRelative = curTotalRelative
        .plus(score.relValue)
        .toFixed(2);
      acc.topicScoreData[topicUuid].totalPotential = curTotalPotential
        .plus(score.potValue)
        .toFixed(2);
      acc.topicScoreData[topicUuid].topicScores = [
        ...acc.topicScoreData[topicUuid].topicScores,
        score
      ];
      acc.cumulativePotentialScore = curCumulativePotential
        .plus(score.potValue)
        .toFixed(2);
      return acc;
    },
    { topicScoreData: defaultTopicScoreData, cumulativePotentialScore: '0.00' }
  );

  const sortedTopics = useMemo(
    () =>
      topics.sort((a, b) =>
        sortByDecimalAndAlphaProperty(
          {
            totalPotential: topicScoreData[b.uuid].totalPotential,
            title: b.title
          },
          {
            totalPotential: topicScoreData[a.uuid].totalPotential,
            title: a.title
          },
          'totalPotential',
          'title'
        )
      ),
    [topics, topicScoreData]
  );

  const handleSelectTopicScoreView = event => {
    setTopicScoreViewSelection(event.target.value);
  };

  return loading ? (
    <LoadingContainer>
      <CircularProgress size={100} />
    </LoadingContainer>
  ) : (
    <TopicList
      assessmentType={assessmentType}
      assessmentUuid={assessmentUuid}
      cumulativePotentialScore={cumulativePotentialScore}
      onSelectTopicScoreView={handleSelectTopicScoreView}
      faculty={faculty}
      partUuid={partUuid}
      topicScoreData={topicScoreData}
      scoreType={scoreType}
      students={students}
      topicScoreViewSelection={topicScoreViewSelection}
      topics={sortedTopics}
    />
  );
}

TopicListContainer.propTypes = {
  assessmentScores: PropTypes.array,
  assessmentType: PropTypes.string,
  assessmentUuid: PropTypes.string,
  faculty: PropTypes.array,
  partUuid: PropTypes.string,
  scoreType: PropTypes.string,
  students: PropTypes.array
};

TopicListContainer.defaultProps = {
  assessmentScores: [],
  assessmentType: '',
  assessmentUuid: '',
  faculty: [],
  partUuid: '',
  scoreType: '',
  students: []
};
