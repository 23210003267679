import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SubjectLevel from './SubjectLevel';
import { selectRigorSubjects } from '../../../../../redux/selectors/rigorIndexSelectors';

export default function SubjectLevelContainer({
  displayName,
  programUuid,
  sourceInstitutionUuid
}) {
  const subjects = useSelector(state =>
    selectRigorSubjects(state, programUuid, sourceInstitutionUuid)
  );

  return (
    <SubjectLevel
      displayName={displayName}
      programUuid={programUuid}
      sourceInstitutionUuid={sourceInstitutionUuid}
      subjects={subjects}
    />
  );
}

SubjectLevelContainer.propTypes = {
  displayName: PropTypes.bool,
  programUuid: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string
};

SubjectLevelContainer.defaultProps = {
  displayName: false,
  programUuid: undefined,
  sourceInstitutionUuid: undefined
};
