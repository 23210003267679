import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContainerContent } from './Layout';

const Layout = styled.div`
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.grey[300]};
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Field = styled.div`
  font-size: 14px;
  display: flex;
`;

const Label = styled.div`
  font-weight: bold;
`;

const Value = styled.div`
  margin-left: 10px;
`;

const Rules = styled.div`
  margin-top: 20px;
  font-size: 14px;
  border: 1px solid ${props => props.theme.colors.grey[300]};
  padding: 10px;
`;

const Rule = styled.div``;

export default function UploadMapping({ display, fields }) {
  return (
    <>
      {display && (
        <>
          <ContainerContent>
            <Title>Restrictions</Title>
            <Rules>
              <Rule>CSV file cannot contain empty rows.</Rule>
            </Rules>

            <Title>Mapping</Title>

            {fields.map(field => (
              <Layout key={field.field}>
                {field?.field && (
                  <Field>
                    <Label>Header:</Label>
                    <Value>{field?.field}</Value>
                  </Field>
                )}

                {field?.type && (
                  <Field>
                    <Label>Type:</Label>
                    <Value>{field?.type}</Value>
                  </Field>
                )}

                {field?.allowEmpty && (
                  <Field>
                    <Label>Allow Empty:</Label>
                    <Value>{field?.allowEmpty.toString()}</Value>
                  </Field>
                )}

                {field?.default && (
                  <Field>
                    <Label>Default:</Label>
                    <Value>{field?.default}</Value>
                  </Field>
                )}

                {field?.accepted && (
                  <Field>
                    <Label>Accepted:</Label>
                    <Value>{field?.accepted}</Value>
                  </Field>
                )}

                {field?.acceptedMap && (
                  <Field>
                    <Label>Accepted Map:</Label>
                    <Value>
                      <div>
                        <pre>{JSON.stringify(field?.acceptedMap, null, 2)}</pre>
                      </div>
                    </Value>
                  </Field>
                )}

                {field?.format && (
                  <Field>
                    <Label>Format:</Label>
                    <Value>{field?.format}</Value>
                  </Field>
                )}

                {field?.maxSize && (
                  <Field>
                    <Label>Max:</Label>
                    <Value>{field?.maxSize}</Value>
                  </Field>
                )}

                {field?.note && (
                  <Field>
                    <Label>Note:</Label>
                    <Value>{field?.note}</Value>
                  </Field>
                )}
              </Layout>
            ))}
          </ContainerContent>
        </>
      )}
    </>
  );
}

UploadMapping.propTypes = {
  fields: PropTypes.array,
  display: PropTypes.bool
};

UploadMapping.defaultProps = {
  fields: [],
  display: false
};
