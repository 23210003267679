import { call, put, takeEvery } from 'redux-saga/effects';
import { getSectionPerformanceReport } from '../../api/sectionPerformanceReportApi';
import {
  doGetSectionPerformanceOpportunityReportError,
  doGetSectionPerformanceOpportunityReportSuccess,
  doGetSectionPerformanceReportError,
  doGetSectionPerformanceReportSuccess
} from '../actions/sectionPerformanceReportActions';

import {
  GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT,
  GET_SECTION_PERFORMANCE_REPORT
} from '../actions-type';

function* handleGetSectionPerformanceReport(action) {
  try {
    const result = yield call(
      getSectionPerformanceReport,
      action.payload.sectionUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetSectionPerformanceReportSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSectionPerformanceReportError(error));
  }
}

function* handleGetSectionPerformanceOpportunityReport(action) {
  try {
    const result = yield call(
      getSectionPerformanceReport,
      action.payload.sectionUuid,
      'opportunity',
      action.payload.assessmentTypes
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetSectionPerformanceOpportunityReportSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSectionPerformanceOpportunityReportError(error));
  }
}

const sectionPerformanceReport = [
  takeEvery(GET_SECTION_PERFORMANCE_REPORT, handleGetSectionPerformanceReport),
  takeEvery(
    GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT,
    handleGetSectionPerformanceOpportunityReport
  )
];

export { sectionPerformanceReport };
