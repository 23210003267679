import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Subtitle2 } from '@xcomp/xcomp-design-library';
import RemainingAssessment from './RemainingAssessment';
import RemainingBlocks from './RemainingBlocks';

export const StatusLine = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }
`;

export const StatusLabel = styled(Subtitle2)`
  color: ${props => props.theme.fontColors.darker};
  position: relative;
  display: inline-block;
  z-index: 100;
  background: ${props => props.theme.colors.white};
  padding-right: 0.5rem;
  font-weight: 600;
`;

const BlockTitles = styled.div`
  padding-left: 132px;
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
  margin-top: 2rem;
`;

const BlockTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width};
  ${props => props.autoWidth && `width: calc((100% - 20% - 230px)/2);`}
  font-weight: 600;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-bottom: 10px;
`;

export default function Remaining({ remaining }) {
  return (
    <>
      {remaining?.length > 0 && (
        <BlockTitles>
          <BlockTitle autoWidth>Assessment</BlockTitle>
          <BlockTitle width="50px">Block</BlockTitle>
          <BlockTitle autoWidth>Block Title</BlockTitle>
          <BlockTitle width="10%">Status</BlockTitle>
          <BlockTitle width="10%">Date</BlockTitle>
          <BlockTitle width="60px">Attempt</BlockTitle>
          <BlockTitle width="70px">Score</BlockTitle>
          <BlockTitle width="50px">Grade</BlockTitle>
        </BlockTitles>
      )}
      {remaining?.map(record => {
        if (record.blocks.length > 0) {
          return (
            <RemainingBlocks key={record.assessment?.uuid} record={record} />
          );
        } else {
          return (
            <RemainingAssessment
              key={record.assessment?.uuid}
              record={record}
            />
          );
        }
      })}
    </>
  );
}

Remaining.propTypes = {
  remaining: PropTypes.array
};

Remaining.defaultProps = {
  remaining: undefined
};
