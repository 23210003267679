import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import { cohortRoleStatusMap } from '../../../helpers/constants';

const Label = styled.div``;

const FilterSelect = styled.div`
  display: flex;

  align-items: center;
`;

const MenuItemSC = styled(MenuItem)`
  && {
    display: flex;
    justify-content: space-between;
  }
`;

const FormControlLabelSC = styled(FormControlLabel)`
  && {
    margin: 0;
  }
`;

const ButtonInlineSC = styled(ButtonInline)`
  width: 100%;
`;

class CohortStudentStatusFilter extends Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { className, filterStatus, handleFilterStatus } = this.props;

    return (
      <div className={className}>
        <FilterSelect
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          noHoverBg
        >
          <Label>Status</Label>
          <ArrowDropDown />
        </FilterSelect>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItemSC>
            <Label>{cohortRoleStatusMap.active}</Label>
            <FormControlLabelSC
              control={
                <Checkbox
                  checked={filterStatus.active}
                  onChange={() => handleFilterStatus('active')}
                  color="primary"
                />
              }
            />
          </MenuItemSC>
          <MenuItemSC>
            <Label>{cohortRoleStatusMap.inactive}</Label>
            <FormControlLabelSC
              control={
                <Checkbox
                  checked={filterStatus.inactive}
                  onChange={() => handleFilterStatus('inactive')}
                  color="primary"
                />
              }
            />
          </MenuItemSC>
          <MenuItemSC>
            <Label>{cohortRoleStatusMap.delayed}</Label>
            <FormControlLabelSC
              control={
                <Checkbox
                  checked={filterStatus.delayed}
                  onChange={() => handleFilterStatus('delayed')}
                  color="primary"
                />
              }
            />
          </MenuItemSC>
          <MenuItemSC>
            <Label>{cohortRoleStatusMap.competent}</Label>
            <FormControlLabelSC
              control={
                <Checkbox
                  checked={filterStatus.competent}
                  onChange={() => handleFilterStatus('competent')}
                  color="primary"
                />
              }
            />
          </MenuItemSC>
          <MenuItemSC>
            <Label>{cohortRoleStatusMap.withdrawn}</Label>
            <FormControlLabelSC
              control={
                <Checkbox
                  checked={filterStatus.withdrawn}
                  onChange={() => handleFilterStatus('withdrawn')}
                  color="primary"
                />
              }
            />
          </MenuItemSC>
          <MenuItemSC>
            <Label>{cohortRoleStatusMap.transferred}</Label>
            <FormControlLabelSC
              control={
                <Checkbox
                  checked={filterStatus.transferred}
                  onChange={() => handleFilterStatus('transferred')}
                  color="primary"
                />
              }
            />
          </MenuItemSC>
          <MenuItemSC>
            <ButtonInlineSC onClick={this.handleClose}>Close</ButtonInlineSC>
          </MenuItemSC>
        </Menu>
      </div>
    );
  }
}

CohortStudentStatusFilter.propTypes = {
  className: PropTypes.string,
  filterStatus: PropTypes.object,
  handleFilterStatus: PropTypes.func
};

CohortStudentStatusFilter.defaultProps = {
  className: '',
  filterStatus: {},
  handleFilterStatus: undefined
};

export default CohortStudentStatusFilter;
