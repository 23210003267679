export const GET_CLASSIFICATIONS = 'GET_CLASSIFICATIONS';
export const GET_CLASSIFICATIONS_SUCCESS = 'GET_CLASSIFICATIONS_SUCCESS';
export const GET_CLASSIFICATIONS_ERROR = 'GET_CLASSIFICATIONS_ERROR';

export const GET_SUBJECTS = 'GET_SUBJECTS';
export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS';
export const GET_SUBJECTS_ERROR = 'GET_SUBJECTS_ERROR';

export const POST_SUBJECTS = 'POST_SUBJECTS';
export const POST_SUBJECTS_SUCCESS = 'POST_SUBJECTS_SUCCESS';
export const POST_SUBJECTS_ERROR = 'POST_SUBJECTS_ERROR';

export const POST_MILESTONES = 'POST_MILESTONES';
export const POST_MILESTONES_SUCCESS = 'POST_MILESTONES_SUCCESS';
export const POST_MILESTONES_ERROR = 'POST_MILESTONES_ERROR';

export const GET_APTITUDES_BY_CATEGORY = 'GET_APTITUDES_BY_CATEGORY';
export const GET_APTITUDES_BY_CATEGORY_SUCCESS =
  'GET_APTITUDES_BY_CATEGORY_SUCCESS';
export const GET_APTITUDES_BY_CATEGORY_ERROR =
  'GET_APTITUDES_BY_CATEGORY_ERROR';

export const POST_APTITUDES = 'POST_APTITUDES';
export const POST_APTITUDES_SUCCESS = 'POST_APTITUDES_SUCCESS';
export const POST_APTITUDES_ERROR = 'POST_APTITUDES_ERROR';
