export const GET_SECTION_PERFORMANCE_REPORT = 'GET_SECTION_PERFORMANCE_REPORT';
export const GET_SECTION_PERFORMANCE_REPORT_ERROR =
  'GET_SECTION_PERFORMANCE_REPORT_ERROR';
export const GET_SECTION_PERFORMANCE_REPORT_SUCCESS =
  'GET_SECTION_PERFORMANCE_REPORT_SUCCESS';

export const GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT =
  'GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT';
export const GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT_ERROR =
  'GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT_ERROR';
export const GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT_SUCCESS =
  'GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT_SUCCESS';
