import React from 'react';
import PropTypes from 'prop-types';
import { ContainerPage } from '../../../Library/Layout';
import CohortApplicantCommunicationReportFilters from './CohortApplicantCommunicationReportFilters';
import CohortApplicantSummaryData from '../CohortApplicantSummaryData';
import CohortApplicantReportList from '../CohortApplicantReportList';

const CohortApplicantCommunicationReport = ({
  isLoadingCommunicationReport,
  onApplyFilterSelection,
  onApplyReportType,
  onSelectTranscriptExam,
  onSelectTranscriptSkill,
  onSelectTranscriptSource,
  onToggleShouldAnonymizeReport,
  reportData,
  reportHeaders,
  reportSummary,
  reportSummaryHeaders,
  shouldAnonymizeReport,
  transcriptExam,
  transcriptExamDisabled,
  transcriptExamOptions,
  transcriptReportTypeError,
  transcriptSkill,
  transcriptSkillDisabled,
  transcriptSkillOptions,
  transcriptSource,
  transcriptSourceOptions,
  reportSummaryFilters
}) => {
  return (
    <ContainerPage paddingTop="48px">
      <CohortApplicantCommunicationReportFilters
        onApplyReportType={onApplyReportType}
        onSelectTranscriptExam={onSelectTranscriptExam}
        onSelectTranscriptSkill={onSelectTranscriptSkill}
        onSelectTranscriptSource={onSelectTranscriptSource}
        transcriptExam={transcriptExam}
        transcriptExamDisabled={transcriptExamDisabled}
        transcriptExamOptions={transcriptExamOptions}
        transcriptReportTypeError={transcriptReportTypeError}
        transcriptSkill={transcriptSkill}
        transcriptSkillDisabled={transcriptSkillDisabled}
        transcriptSkillOptions={transcriptSkillOptions}
        transcriptSource={transcriptSource}
        transcriptSourceOptions={transcriptSourceOptions}
      />
      <CohortApplicantSummaryData
        reportSummary={reportSummary}
        reportSummaryMapping={reportSummaryHeaders}
      />
      <CohortApplicantReportList
        isLoading={isLoadingCommunicationReport}
        onApplyFilterSelection={onApplyFilterSelection}
        onToggleShouldAnonymizeReport={onToggleShouldAnonymizeReport}
        reportData={reportData}
        reportHeaders={reportHeaders}
        reportSummaryFilters={reportSummaryFilters}
        shouldAnonymizeReport={shouldAnonymizeReport}
        showRank={true}
      />
    </ContainerPage>
  );
};

CohortApplicantCommunicationReport.propTypes = {
  isLoadingCommunicationReport: PropTypes.bool,
  onApplyFilterSelection: PropTypes.func,
  onApplyReportType: PropTypes.func,
  onSelectTranscriptExam: PropTypes.func,
  onSelectTranscriptSkill: PropTypes.func,
  onSelectTranscriptSource: PropTypes.func,
  onToggleShouldAnonymizeReport: PropTypes.func,
  reportData: PropTypes.array,
  reportHeaders: PropTypes.array,
  reportSummary: PropTypes.object,
  reportSummaryFilters: PropTypes.array,
  reportSummaryHeaders: PropTypes.array,
  shouldAnonymizeReport: PropTypes.bool,
  transcriptExam: PropTypes.string,
  transcriptExamDisabled: PropTypes.bool,
  transcriptExamOptions: PropTypes.array,
  transcriptReportTypeError: PropTypes.bool,
  transcriptSkill: PropTypes.string,
  transcriptSkillDisabled: PropTypes.bool,
  transcriptSkillOptions: PropTypes.array,
  transcriptSource: PropTypes.string,
  transcriptSourceOptions: PropTypes.array
};

CohortApplicantCommunicationReport.defaultProps = {
  isLoadingCommunicationReport: false,
  onApplyFilterSelection: undefined,
  onApplyReportType: undefined,
  onSelectTranscriptExam: undefined,
  onSelectTranscriptSkill: undefined,
  onSelectTranscriptSource: undefined,
  onToggleShouldAnonymizeReport: undefined,
  reportData: [],
  reportHeaders: [],
  reportSummary: {},
  reportSummaryFilters: [],
  reportSummaryHeaders: [],
  shouldAnonymizeReport: false,
  transcriptExam: '',
  transcriptExamDisabled: true,
  transcriptExamOptions: [],
  transcriptReportTypeError: false,
  transcriptSkill: '',
  transcriptSkillDisabled: true,
  transcriptSkillOptions: [],
  transcriptSource: '',
  transcriptSourceOptions: []
};

export default CohortApplicantCommunicationReport;
