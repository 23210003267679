import {
  CLEAR_CREATED_ASSESSMENT,
  CLEAR_CREATED_ENCOUNTER,
  CLEAR_UPDATED_ASSESSMENT,
  CLEAR_UPDATED_ENCOUNTER,
  POST_ASSESSMENT_SUCCESS,
  POST_ENCOUNTER_SUCCESS,
  PUT_ASSESSMENT_DRAFT_SUCCESS,
  PUT_ASSESSMENT_FINAL_SUCCESS,
  PUT_ENCOUNTER_DRAFT_SUCCESS,
  PUT_ENCOUNTER_FINAL_SUCCESS,
  SET_SELECTED_ITEM,
  SET_SELECTED_ITEM_RESET,
  SET_SELECTED_SYLLABUS
} from '../actions-type';

export const INITIAL_STATE = {
  newAssessmentUuid: '',
  newEncounterUuid: '',
  selectedCourseUuid: '',
  selectedItemUuid: undefined,
  selectedOrder: '',
  selectedPartUuid: '',
  selectedSectionUuid: '',
  selectedType: undefined,
  successfullyCreatedEncounter: false,
  successfullyUpdatedEncounter: false
};

const syllabusReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_SYLLABUS: {
      return {
        ...state,
        selectedPartUuid: action.payload.partUuid,
        selectedSectionUuid: action.payload.sectionUuid,
        selectedCourseUuid: action.payload.courseUuid
      };
    }
    case POST_ENCOUNTER_SUCCESS: {
      return {
        ...state,
        successfullyCreatedEncounter: true,
        newEncounterUuid: action.payload.encounter.uuid
      };
    }
    case PUT_ENCOUNTER_DRAFT_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedEncounter: true
      };
    }
    case PUT_ENCOUNTER_FINAL_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedEncounter: true
      };
    }
    case CLEAR_CREATED_ENCOUNTER: {
      return {
        ...state,
        successfullyCreatedEncounter: false,
        newEncounterUuid: ''
      };
    }
    case CLEAR_UPDATED_ENCOUNTER: {
      return {
        ...state,
        successfullyUpdatedEncounter: false
      };
    }
    case POST_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        successfullyCreatedAssessment: true,
        newAssessmentUuid: action.payload.assessment.uuid
      };
    }
    case PUT_ASSESSMENT_DRAFT_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedAssessment: true
      };
    }
    case PUT_ASSESSMENT_FINAL_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedAssessment: true
      };
    }
    case CLEAR_CREATED_ASSESSMENT: {
      return {
        ...state,
        successfullyCreatedAssessment: false
      };
    }
    case CLEAR_UPDATED_ASSESSMENT: {
      return {
        ...state,
        successfullyUpdatedAssessment: false
      };
    }
    case SET_SELECTED_ITEM: {
      return {
        ...state,
        selectedItemUuid: action.payload.uuid,
        selectedType: action.payload.type,
        selectedOrder: action.payload.order
      };
    }

    case SET_SELECTED_ITEM_RESET: {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
};

export default syllabusReducer;
