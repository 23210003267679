export const GET_USER_DEPARTMENT = 'GET_USER_DEPARTMENT';
export const GET_USER_DEPARTMENT_SUCCESS = 'GET_USER_DEPARTMENT_SUCCESS';
export const GET_USER_DEPARTMENT_ERROR = 'GET_USER_DEPARTMENT_ERROR';

export const GET_USER_DEPARTMENTS = 'GET_USER_DEPARTMENTS';
export const GET_USER_DEPARTMENTS_SUCCESS = 'GET_USER_DEPARTMENTS_SUCCESS';
export const GET_USER_DEPARTMENTS_ERROR = 'GET_USER_DEPARTMENTS_ERROR';

export const GET_USER_DEPARTMENTS_FACULTY = 'GET_USER_DEPARTMENTS_FACULTY';
export const GET_USER_DEPARTMENTS_FACULTY_SUCCESS =
  'GET_USER_DEPARTMENTS_FACULTY_SUCCESS';
export const GET_USER_DEPARTMENTS_FACULTY_ERROR =
  'GET_USER_DEPARTMENTS_FACULTY_ERROR';

export const POST_USER_DEPARTMENTS_ASSIGN = 'POST_USER_DEPARTMENTS_ASSIGN';

export const POST_ASSIGN_FACULTY_STAFF_USERS_SUCCESS =
  'POST_ASSIGN_FACULTY_STAFF_USERS_SUCCESS';
export const POST_ASSIGN_FACULTY_STAFF_USERS_ERROR =
  'POST_ASSIGN_FACULTY_STAFF_USERS_ERROR';
export const POST_ASSIGN_FACULTY_STAFF_USERS_WARNING =
  'POST_ASSIGN_FACULTY_STAFF_USERS_WARNING';
export const RESET_ASSIGN_USERS = 'RESET_ASSIGN_USERS';
