import React from 'react';

import { Heading2, ButtonPrimary } from '@xcomp/xcomp-design-library';
import { Link } from 'react-router-dom';
import {
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader,
  ContainerActions
} from '../Library/Layout';
import Header from '../Library/Header';

import CompetencyCollectionsListContainer from './CompetencyCollections/CompetencyCollectionsList/CompetencyCollectionsListContainer';

const CompetencyRequirements = () => (
  <ContainerResize>
    <Header
      title="Competency Requirements"
      subtitle="Manage program competencies"
      backButtonLocation="/"
    />
    <ContainerActions paddingTop="100px">
      <Link to="/program-management/competency-collection/add">
        <ButtonPrimary>Add Collection</ButtonPrimary>
      </Link>
    </ContainerActions>
    <ContainerPage paddingTop="48px">
      <Panel>
        <PanelHeader paddingLeft="24px">
          <Heading2>Competency Collections</Heading2>
        </PanelHeader>

        <CompetencyCollectionsListContainer />
      </Panel>
    </ContainerPage>
  </ContainerResize>
);

export default CompetencyRequirements;
