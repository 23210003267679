import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CoursesByTerm from './CoursesByTerm';
import { selectTermsByUserCourses } from '../../../../redux/selectors/termsSelectors';

export default function CoursesByTermContainer({ userUuid }) {
  const terms = useSelector(
    state => selectTermsByUserCourses(state, userUuid),
    _.isEqual
  );

  return <CoursesByTerm terms={terms} userUuid={userUuid} />;
}

CoursesByTermContainer.propTypes = {
  userUuid: PropTypes.string
};

CoursesByTermContainer.defaultProps = {
  userUuid: undefined
};
