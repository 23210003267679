import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

import FacultyList from './FacultyList';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 60vh;
  align-items: center;
  font-size: 80px;
`;

export default function FacultyListContainer({
  assessmentScores,
  partFacultyUsers,
  scoreType,
  stepScoreSummary,
  students
}) {
  const [facultyScoreViewSelection, setFacultyScoreViewSelection] =
    useState('default');

  const loading = useSelector(state => state.crudState.readScores);

  const handleFacultyScoreViewSelection = event => {
    setFacultyScoreViewSelection(event.target.value);
  };

  return loading ? (
    <LoadingContainer>
      <CircularProgress size={100} />
    </LoadingContainer>
  ) : (
    <FacultyList
      assessmentScores={assessmentScores}
      facultyScoreViewSelection={facultyScoreViewSelection}
      onFacultyScoreViewSelection={handleFacultyScoreViewSelection}
      partFacultyUsers={partFacultyUsers}
      scoreType={scoreType}
      stepScoreSummary={stepScoreSummary}
      students={students}
    />
  );
}

FacultyListContainer.propTypes = {
  assessmentScores: PropTypes.array,
  partFacultyUsers: PropTypes.array,
  scoreType: PropTypes.string,
  stepScoreSummary: PropTypes.array,
  students: PropTypes.array
};

FacultyListContainer.defaultProps = {
  assessmentScores: [],
  partFacultyUsers: [],
  scoreType: '',
  stepScoreSummary: [],
  students: []
};
