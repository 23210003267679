import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const termsSelector = createSelector(
  state => state,
  redux => {
    const termsSelectorORM = ormCreateSelector(orm.Term);
    const terms = termsSelectorORM(redux);

    return terms;
  }
);

export const termsAcademicYearSelector = createSelector(
  state => state,
  state => state.filterCourseState.academicYearUuid,
  (redux, academicYearUuid) => {
    const termsSelectorORM = ormCreateSelector(orm.Term);
    const terms = termsSelectorORM(redux).filter(
      term => term.academicYearUuid === academicYearUuid
    );

    const termsSorted = _.sortBy(terms, ['period']);

    return termsSorted;
  }
);

export const termsAcademicYearSelectorCourseEdit = createSelector(
  state => state,
  state => state.courseEditState.selectedAcademicYearUuid,
  (redux, selectedAcademicYearUuid) => {
    const termsSelectorORM = ormCreateSelector(orm.Term);
    const terms = termsSelectorORM(redux).filter(
      term => term.academicYearUuid === selectedAcademicYearUuid
    );

    return terms;
  }
);

export const termsAcademicYearSelectorCourseAdd = createSelector(
  state => state,
  state => state.courseAddState.selectedAcademicYearUuid,
  (redux, selectedAcademicYearUuid) => {
    const termsSelectorORM = ormCreateSelector(orm.Term);
    const terms = termsSelectorORM(redux).filter(
      term => term.academicYearUuid === selectedAcademicYearUuid
    );

    const termsSorted = _.sortBy(terms, ['period']);

    return termsSorted;
  }
);

export const termsAcademicYearSelectorCourseNew = createSelector(
  state => state,
  state => state.courseEditState.selectedAcademicYearUuid,
  (redux, selectedAcademicYearUuid) => {
    const termsSelectorORM = ormCreateSelector(orm.Term);
    const terms = termsSelectorORM(redux).filter(
      term => term.academicYearUuid === selectedAcademicYearUuid
    );

    return terms;
  }
);

export const termsByAcademicYearSelector = createSelector(
  state => state,
  (_, academicYearUuid) => academicYearUuid,
  (redux, academicYearUuid) => {
    const termsSelectorORM = ormCreateSelector(orm.Term);
    const terms = termsSelectorORM(redux).filter(
      term => term.academicYearUuid === academicYearUuid
    );

    const ordered = _.orderBy(terms, ['period'], ['asc']);

    return ordered;
  }
);

export const selectTermsByUserCourses = createSelector(
  state => state,
  (_, userUuid) => userUuid,
  (redux, userUuid) => {
    const getUserSection = ormCreateSelector(orm.UserSection);
    const getSection = ormCreateSelector(orm.Section);
    const getCourse = ormCreateSelector(orm.Course);
    const getTerm = ormCreateSelector(orm.Term);

    if (userUuid) {
      const userSections = getUserSection(redux).filter(
        us => us.userUuid === userUuid
      );

      const sections = userSections.map(us => {
        const section = getSection(redux, us.sectionUuid);
        return section;
      });

      const courses = sections.map(c => {
        const course = getCourse(redux, c.courseUuid);
        return course;
      });

      const termsUuids = courses.filter(course => course.termUuid);

      const terms = termsUuids.map(t => {
        const term = getTerm(redux, t.termUuid);
        return term;
      });

      const result = _(terms).uniq(['uuid']).sortBy('startDate').value();

      return result;
    } else {
      return undefined;
    }
  }
);

export const selectTerm = ormCreateSelector(orm.Term);
