import _ from 'lodash';
import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

import { weightPercentage } from '../../helpers/utilities';

export const classificationScope = ormCreateSelector(orm.Classification);

export const classificationsSelectorByCategory = createSelector(
  state => state,
  (_, programUuid, category) => ({
    programUuid,
    category
  }),
  (redux, data) => {
    const { programUuid, category } = data;

    const classification = ormCreateSelector(orm.Classification);

    const classifications = classification(redux).filter(
      tc => tc.programUuid === programUuid && tc.category === category
    );

    return classifications;
  }
);

export const classificationsSelectorByCategorySource = createSelector(
  state => state,
  (_, programUuid, category, source) => ({
    programUuid,
    category,
    source
  }),
  (redux, data) => {
    const { programUuid, category, source } = data;

    const classification = ormCreateSelector(orm.Classification);

    const classifications = classification(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.category === category &&
        tc.source === source
    );

    return classifications;
  }
);

export const classificationsSelector = createSelector(
  state => state,
  (_, programUuid, category, source) => ({
    programUuid,
    category,
    source
  }),
  (redux, data) => {
    const { programUuid, category, source } = data;

    const classification = ormCreateSelector(orm.Classification);

    const classifications = classification(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.category === category &&
        tc.source === source
    );

    return classifications;
  }
);

export const selectMilestone = ormCreateSelector(
  orm.Classification.exam_milestone
);

export const selectMilestoneConversions = ormCreateSelector(
  orm.ExamMilestone.conversion_milestones
);

export const selectAptitudes = ormCreateSelector(
  orm.Classification.exam_aptitudes
);

export const selectAptitudeConversions = ormCreateSelector(
  orm.ExamAptitude.conversion_aptitudes
);

export const selectSubjects = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const getClassifications = ormCreateSelector(orm.Classification);

    const subjects = getClassifications(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.category === 'Cognitive' &&
        tc.source === 'Program'
    );

    return subjects;
  }
);

export const selectSubjectMilestones = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const getClassifications = ormCreateSelector(orm.Classification);

    const getMilestone = ormCreateSelector(orm.Classification.exam_milestone);
    const getMilestoneConversions = ormCreateSelector(
      orm.ExamMilestone.conversion_milestones
    );

    const subjects = getClassifications(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.category === 'Cognitive' &&
        tc.source === 'Program'
    );

    if (subjects && subjects.length > 0) {
      const list = subjects.map(subject => {
        const exam_milestone = getMilestone(redux, subject.uuid);

        if (exam_milestone) {
          const conversion_milestones = getMilestoneConversions(
            redux,
            exam_milestone.uuid
          );

          if (conversion_milestones && conversion_milestones.length > 0) {
            return {
              ...subject,
              exam_milestone: {
                ...exam_milestone,
                conversion_milestones
              }
            };
          } else {
            return {
              ...subject,
              exam_milestone: {
                ...exam_milestone,
                conversion_milestones: []
              }
            };
          }
        } else {
          return {
            ...subject
          };
        }
      });

      return list;
    } else {
      return [];
    }
  }
);

export const selectSubjectWeight = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const getClassifications = ormCreateSelector(orm.Classification);

    const subjects = getClassifications(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.category === 'Cognitive' &&
        tc.source === 'Program'
    );

    if (subjects && subjects.length > 0) {
      const weight = weightPercentage(subjects);
      return weight;
    } else {
      return '0.00';
    }
  }
);

export const selectAptitudeWeight = createSelector(
  state => state,
  (_, programUuid, category) => ({
    programUuid,
    category
  }),
  (redux, data) => {
    const { programUuid, category } = data;

    const getClassifications = ormCreateSelector(orm.Classification);
    const getAptitudes = ormCreateSelector(orm.Classification.exam_aptitudes);

    const classifications = getClassifications(redux)
      .filter(tc => tc.programUuid === programUuid && tc.category === category)
      .map(record => record.uuid);

    const aptitudes = _.flatten(getAptitudes(redux, classifications));

    if (aptitudes && aptitudes.length > 0) {
      const weight = weightPercentage(aptitudes);
      return weight;
    } else {
      return '0.00';
    }
  }
);

export const selectAptitudesByCategory = createSelector(
  state => state,
  (_, programUuid, category) => ({ programUuid, category }),
  (redux, data) => {
    const { programUuid, category } = data;

    const getClassifications = ormCreateSelector(orm.Classification);
    const getExamAptitudes = ormCreateSelector(
      orm.Classification.exam_aptitudes
    );

    const getCoversionAptitudes = ormCreateSelector(
      orm.ExamAptitude.conversion_aptitudes
    );

    let results = [];

    const classifications = getClassifications(redux).filter(
      classification =>
        classification.programUuid === programUuid &&
        classification.category === category
    );

    if (classifications?.length > 0) {
      results = classifications.map(classification => {
        const exam_aptitudes = getExamAptitudes(redux, classification.uuid);

        if (exam_aptitudes?.length > 0) {
          const replace = exam_aptitudes.map(exam => {
            const conversion_aptitudes = getCoversionAptitudes(
              redux,
              exam.uuid
            );

            if (conversion_aptitudes?.length > 0) {
              return {
                ...exam,
                conversion_aptitudes
              };
            } else {
              return exam;
            }
          });

          return {
            ...classification,
            exam_aptitudes: replace
          };
        } else {
          return classification;
        }
      });
    }

    return results;
  }
);
