import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import ButtonPrimary from '@xcomp/xcomp-design-library/dist/components/ButtonPrimary';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  ContainerPage,
  ElevateLow,
  ContainerContent
} from '../../../Library/Layout';

import FormSelect from '../../../Library/FormSelect';
import FormErrors from '../../../Library/FormErrors';
import InstitutionLevelContainer from './InstitutionLevel/InstitutionLevelContainer';
import DisplayName from './DisplayName/DisplayName';

const InvisibleLineBreak = styled.div`
  height: 4px;
`;

const Report = styled.div`
  margin-top: 40px;
`;

export default function InstitutionRigor({
  displayName,
  programUuid,
  loading,
  invalidRange,
  cohortStartSelected,
  cohortEndSelected,
  optionsCohorts,
  handleCohortStart,
  handleCohortEnd,
  handleRigorIndex,
  handleDisplayName
}) {
  return (
    <ContainerPage paddingTop="50px">
      <ElevateLow>
        <ContainerContent>
          <FormErrors
            hasErrors={invalidRange}
            message="The selected cohort range is invalid. The initial cohort must begin before the second cohort."
          />
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={12} xs={12}>
              {loading ? <LinearProgress /> : <InvisibleLineBreak />}
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormSelect
                value={cohortStartSelected}
                options={optionsCohorts}
                handleChange={handleCohortStart}
                name="cohortStartUuid"
                label="Initial Cohort"
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormSelect
                value={cohortEndSelected}
                options={optionsCohorts}
                handleChange={handleCohortEnd}
                name="cohortEndUuid"
                label="Last Cohort"
              />
            </Grid>
            <Grid container justifyContent="center" item sm={2} xs={12}>
              <ButtonPrimary
                disabled={!cohortStartSelected && !cohortEndSelected}
                onClick={handleRigorIndex}
              >
                Apply
              </ButtonPrimary>
            </Grid>
            <Grid item>
              <DisplayName
                handleDisplayName={handleDisplayName}
                item
                sm={2}
                xs={12}
              />
            </Grid>
          </Grid>
        </ContainerContent>
      </ElevateLow>

      <Report>
        <InstitutionLevelContainer
          programUuid={programUuid}
          displayName={displayName}
        />
      </Report>
    </ContainerPage>
  );
}

InstitutionRigor.propTypes = {
  displayName: PropTypes.bool,
  programUuid: PropTypes.string,
  loading: PropTypes.bool,
  invalidRange: PropTypes.bool,
  cohortStartSelected: PropTypes.string,
  cohortEndSelected: PropTypes.string,
  optionsCohorts: PropTypes.array,
  handleCohortStart: PropTypes.func,
  handleCohortEnd: PropTypes.func,
  handleRigorIndex: PropTypes.func,
  handleDisplayName: PropTypes.func
};

InstitutionRigor.defaultProps = {
  displayName: false,
  programUuid: undefined,
  loading: false,
  invalidRange: false,
  cohortStartSelected: undefined,
  cohortEndSelected: undefined,
  optionsCohorts: [],
  handleCohortStart: undefined,
  handleCohortEnd: undefined,
  handleRigorIndex: undefined,
  handleDisplayName: undefined
};
