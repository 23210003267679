import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ViewIcon } from '@xcomp/xcomp-design-library/dist/icons';

import { ButtonIcon } from '@xcomp/xcomp-design-library';
import {
  ScrollPanelLabel,
  ViewMore,
  Body1Dark
} from '../../Library/SyllabusComponents';
import { sortByProperty } from '../../../helpers/utilities';

const ListObjectivesLayout = styled(({ maxHeight, minWidth, ...rest }) => (
  <div {...rest} />
))`
  position: relative;
  border: 1px solid ${props => props.theme.colors.grey[300]};
  border-radius: 4px;
  padding-bottom: 5px;
  padding-top: 5px;
  height: ${props => props.maxHeight || '170px'};
  min-width: ${props => props.minWidth || '100px;'};
`;

const ListObjectivesInner = styled.div`
  padding: 16px 16px 32px 16px;
  height: 100%;
  overflow-y: scroll;
`;

const Objective = styled(Body1Dark)`
  margin-bottom: 24px;
  line-height: 1.6;
`;

const LOVerb = styled.span`
  text-transform: uppercase;
  font-weight: 500;
  color: ${props => props.theme.fontColors.darker};
  display: inline;
`;

const LearningObjectivesList = ({
  objectives,
  onViewMore,
  hasLabel,
  maxHeight,
  minWidth,
  noObjectivesMessage
}) => (
  <ListObjectivesLayout maxHeight={maxHeight} minWidth={minWidth}>
    {hasLabel && <ScrollPanelLabel>Learning Objectives</ScrollPanelLabel>}
    <ListObjectivesInner>
      {objectives && objectives.length > 0 ? (
        objectives
          .sort((a, b) => sortByProperty(a, b, 'objectiveNumber'))
          .map(({ loverb, ...objective }) => (
            <Objective key={objective.uuid}>
              {objective.objectiveNumber}.&nbsp;
              <LOVerb>{loverb.objectiveVerb}</LOVerb>&nbsp;
              {objective.learningObjective}
            </Objective>
          ))
      ) : (
        <Body1Dark>{noObjectivesMessage}</Body1Dark>
      )}
      {onViewMore && (
        <ViewMore>
          <ButtonIcon onClick={onViewMore} noHoverBg>
            <ViewIcon />
          </ButtonIcon>
        </ViewMore>
      )}
    </ListObjectivesInner>
  </ListObjectivesLayout>
);

LearningObjectivesList.propTypes = {
  objectives: PropTypes.arrayOf(PropTypes.object),
  onViewMore: PropTypes.func,
  noObjectivesMessage: PropTypes.string,
  hasLabel: PropTypes.bool,
  maxHeight: PropTypes.string,
  minWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

LearningObjectivesList.defaultProps = {
  objectives: [],
  onViewMore: undefined,
  noObjectivesMessage:
    'There are currently no learning objectives for this Encounter',
  hasLabel: true,
  maxHeight: '170px',
  minWidth: false
};

export default LearningObjectivesList;
