import _ from 'lodash';
import {
  POST_UPLOAD_PART_LEGACY_GRADES,
  POST_UPLOAD_PART_LEGACY_GRADES_SUCCESS,
  POST_UPLOAD_PART_LEGACY_GRADES_ERROR,
  RESET_UPLOAD_PART_LEGACY_GRADES,
  POST_UPLOAD_SECTION_LEGACY_GRADES,
  POST_UPLOAD_SECTION_LEGACY_GRADES_SUCCESS,
  POST_UPLOAD_SECTION_LEGACY_GRADES_ERROR,
  RESET_UPLOAD_SECTION_LEGACY_GRADES
} from '../actions-type';

export const INITIAL_STATE = {
  uploadingGrades: false,
  invalid: false,
  records: 0,
  status: ''
};

const uploadLegacyGradesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_UPLOAD_PART_LEGACY_GRADES: {
      return {
        ...state,
        uploadingGrades: true,
        invalid: false
      };
    }
    case POST_UPLOAD_PART_LEGACY_GRADES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        uploadingGrades: false,
        invalid: false
      };
    }
    case POST_UPLOAD_PART_LEGACY_GRADES_ERROR: {
      return {
        ...state,
        ...action.payload,
        uploadingGrades: false,
        invalid: true,
        status: 'close',
        statusMessage: '',
        error_server: _.get(action, 'payload.error', '')
      };
    }
    case POST_UPLOAD_SECTION_LEGACY_GRADES: {
      return {
        ...state,
        uploadingGrades: true,
        invalid: false
      };
    }
    case POST_UPLOAD_SECTION_LEGACY_GRADES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        uploadingGrades: false,
        invalid: false
      };
    }
    case POST_UPLOAD_SECTION_LEGACY_GRADES_ERROR: {
      return {
        ...state,
        ...action.payload,
        uploadingGrades: false,
        invalid: true,
        status: 'close',
        statusMessage: '',
        error_server: _.get(action, 'payload.error', '')
      };
    }
    case RESET_UPLOAD_SECTION_LEGACY_GRADES:
    case RESET_UPLOAD_PART_LEGACY_GRADES: {
      return {
        uploadingGrades: false,
        invalid: false,
        records: 0,
        status: ''
      };
    }
    default:
      return state;
  }
};

export default uploadLegacyGradesReducer;
