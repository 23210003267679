import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Heading2 } from '@xcomp/xcomp-design-library';
import {
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader
} from '../../../Library/Layout';
import Header from '../../../Library/Header';
import ProgramAssessmentMasterForm from '../ProgramAssessmentMasterForm';
import UnsavedChangesModal from '../../../Library/Modal/UnsavedChangesModal/UnsavedChangesModal';

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const NewProgramAssessmentMaster = ({
  newProgramAssessment,
  program,
  parentCollection,
  gradingTechnique,
  gradingTechniqueDispatch,
  optionsAssessmentTypes,
  optionsAssessmentAssociations,
  showValidationErrors,
  programAssessmentDispatch,
  onSubmit,
  onCancel,
  unsavedChanges,
  isUnsavedChangesModalOpen,
  handleModalClose,
  handleProceed,
  setUnsavedChange,
  removeUnsavedChange
}) => {
  return (
    <ContainerResize>
      <UnsavedChangesModal
        modalOpen={isUnsavedChangesModalOpen}
        message="The form has unsaved changes. Choose CANCEL and click CREATE to save this program assessment or click DISCARD CHANGES to return to the program assessment collections page."
        proceedButtonText="Discard Changes"
        handleProceed={handleProceed}
        handleModalClose={handleModalClose}
      />
      <Header
        title={`${program?.programName} - ${parentCollection?.title}`}
        subtitle="Create Program Assessment"
        backButtonOverride={onCancel}
      />
      <ContainerPage paddingTop="148px">
        <Panel>
          <PanelHeader paddingLeft="24px" paddingRight="24px">
            <Heading2>Create Program Assessment</Heading2>
          </PanelHeader>
          <PanelPadding>
            <ProgramAssessmentMasterForm
              programAssessment={newProgramAssessment}
              showValidationErrors={showValidationErrors}
              programAssessmentDispatch={programAssessmentDispatch}
              optionsAssessmentTypes={optionsAssessmentTypes}
              optionsAssessmentAssociations={optionsAssessmentAssociations}
              buttonText="Create"
              onSubmit={onSubmit}
              onCancel={onCancel}
              unsavedChanges={unsavedChanges}
              setUnsavedChange={setUnsavedChange}
              removeUnsavedChange={removeUnsavedChange}
              gradingTechnique={gradingTechnique}
              gradingTechniqueDispatch={gradingTechniqueDispatch}
            />
          </PanelPadding>
        </Panel>
      </ContainerPage>
    </ContainerResize>
  );
};

NewProgramAssessmentMaster.propTypes = {
  newProgramAssessment: PropTypes.object.isRequired,
  program: PropTypes.object,
  parentCollection: PropTypes.object,
  optionsAssessmentTypes: PropTypes.arrayOf(PropTypes.object),
  optionsAssessmentAssociations: PropTypes.arrayOf(PropTypes.object),
  gradingTechnique: PropTypes.object,
  gradingTechniqueDispatch: PropTypes.func,
  showValidationErrors: PropTypes.bool,
  programAssessmentDispatch: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  unsavedChanges: PropTypes.object,
  setUnsavedChange: PropTypes.func,
  removeUnsavedChange: PropTypes.func,
  handleModalClose: PropTypes.func,
  handleProceed: PropTypes.func,
  isUnsavedChangesModalOpen: PropTypes.bool
};

NewProgramAssessmentMaster.defaultProps = {
  program: {},
  parentCollection: {},
  gradingTechnique: {},
  gradingTechniqueDispatch: undefined,
  optionsAssessmentTypes: [],
  optionsAssessmentAssociations: [],
  showValidationErrors: false,
  programAssessmentDispatch: undefined,
  onSubmit: undefined,
  onCancel: undefined,
  unsavedChanges: {},
  setUnsavedChange: undefined,
  removeUnsavedChange: undefined,
  handleModalClose: undefined,
  handleProceed: undefined,
  isUnsavedChangesModalOpen: false
};

export default NewProgramAssessmentMaster;
