import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import IconButton from '@material-ui/core/IconButton';
import { rolesMap } from '../../../helpers/constants';
import DeleteModal from '../../Library/Modal/DeleteModal/DeleteModal';
import { doDeleteAppId } from '../../../redux/actions/userActions';

import { selectStudentRoles } from '../../../redux/selectors/userRolesSelectors';

const Attributes = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  border-left: 1px solid
    ${props => (props.left ? props.theme.colors.grey[300] : 'none')};
  border-right: 1px solid
    ${props => (props.right ? props.theme.colors.grey[300] : 'none')};
  border-top: 1px solid
    ${props => (props.top ? props.theme.colors.grey[300] : 'none')};
  border-bottom: 1px solid
    ${props => (props.bottom ? props.theme.colors.grey[300] : 'none')};
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
`;

const ButtonPlacement = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const Status = styled.span`
  text-transform: capitalize;
`;

export default function UserTabAppIdStudentRoles({
  userUuid,
  appIdStudent,
  handleStudentAppIdOpen
}) {
  const roles = useSelector(state => selectStudentRoles(state, userUuid));

  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const dispatch = useDispatch();

  const deleteAppId = useSelector(state => state.crudState.deleteAppId);

  const handleDeleteModalOpen = () => {
    setModalDeleteOpen(true);
  };

  const handleDeleteModalClose = () => {
    setModalDeleteOpen(false);
  };

  const handleDelete = () => {
    dispatch(doDeleteAppId('appIdStudent', userUuid, appIdStudent));
    setModalDeleteOpen(false);
  };

  return (
    <>
      {roles?.length > 0 && (
        <Grid container>
          <Grid item xs={8} sm={8}>
            {roles?.map(role => (
              <Grid container key={role.uuid}>
                <Grid item xs={4} sm={4}>
                  <Attributes>
                    <Status>{role.status}</Status>
                  </Attributes>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Attributes>{rolesMap[role.role]}</Attributes>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Attributes>{role.email}</Attributes>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container alignItems="flex-start" item xs={3} sm={3}>
            {!appIdStudent ? (
              <ButtonPlacement>
                <ButtonPrimary onClick={() => handleStudentAppIdOpen(userUuid)}>
                  Create Student AppID
                </ButtonPrimary>
              </ButtonPlacement>
            ) : (
              <ButtonPlacement>
                <ButtonPrimary disabled>Activated</ButtonPrimary>
              </ButtonPlacement>
            )}
          </Grid>
          <Grid container alignItems="flex-start" item xs={1} sm={1}>
            {appIdStudent && !deleteAppId && (
              <ButtonPlacement>
                <IconButton onClick={handleDeleteModalOpen}>
                  <DeleteIcon />
                </IconButton>
              </ButtonPlacement>
            )}
          </Grid>
          <DeleteModal
            type="Student App ID"
            modalOpen={modalDeleteOpen}
            handleModalClose={handleDeleteModalClose}
            handleDelete={handleDelete}
          />
        </Grid>
      )}
    </>
  );
}

UserTabAppIdStudentRoles.propTypes = {
  userUuid: PropTypes.string,
  appIdStudent: PropTypes.string,
  handleStudentAppIdOpen: PropTypes.func
};
UserTabAppIdStudentRoles.defaultProps = {
  userUuid: undefined,
  appIdStudent: undefined,
  handleStudentAppIdOpen: undefined
};
