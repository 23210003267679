import fetch from '../helpers/fetch';

const getGradeSummary = assessmentUuid =>
  fetch(`/api/grade-summary/assessment/${assessmentUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCohortGradeSummary = assessmentUuid =>
  fetch(`/api/grade-summary/cohort-assessment/${assessmentUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getGradeSummary, getCohortGradeSummary };
