import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';
import { Body1 } from '@xcomp/xcomp-design-library';
import moment from 'moment';
import AccessTime from '@material-ui/icons/AccessTime';
import PersonOutline from '@material-ui/icons/PersonOutline';
import Overline from '../../../Library/Overline';

const Layout = styled.div`
  position: relative;
  border-bottom: 2px solid ${props => props.theme.colors.grey[300]};
  border-left: 8px solid ${props => props.theme.colors.secondary};
  padding: 1rem 0;
  > div {
    padding: 0 1rem;
  }

  &.selected {
    position: relative;
    z-index: 10;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: ${props => props.theme.colors.primary};
      opacity: 0.08;
    }
  }

  &:hover {
    cursor: pointer;
    background: rgba(155, 155, 155, 0.1);
  }
`;

const ItemDate = styled.div`
  font-size: 0.75rem;
  color: ${props => props.theme.fontColors.dark};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  &.no_dates {
    align-items: center;
  }
`;

const DateBlock = styled.div`
  width: 100%;
  text-align: center;
  .endDate {
    color: ${props => props.theme.fontColors.dark};
  }
`;

const DayDisplay = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  width: 100%;
`;

const MonthDisplay = styled.div`
  width: 100%;
  font-size: 0.625rem;
`;

const OverlineSC = styled(Overline)`
  margin: 0;
  line-height: 1.6;
  font-size: 0.75rem;
  font-weight: 400;
`;

const ItemTitle = styled.h4`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  color: ${props => props.theme.fontColors.darker};
  margin: 0.25rem 0;
  font-weight: 400;
  width: 100%;
`;

const ItemSubtitle = styled.h5`
  font-size: 0.75rem;
  color: ${props => props.theme.fontColors.dark};
  margin: 0.25rem 0 0.25rem 0;
  font-weight: 400;
  width: 100%;

  svg {
    font-size: 1rem;
    vertical-align: bottom;
    margin-right: 0.5rem;
  }
`;

const LinkedItem = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

const Body1SC = styled(Body1)`
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
`;

const EncounterCard = ({
  encounter,
  selectedItem,
  itemNumber,
  selectSyllabusItem
}) => {
  const isLinked = useMemo(
    () => !!encounter?.linked_assessment,
    [encounter?.linked_assessment]
  );

  const selected = useMemo(
    () => selectedItem === encounter?.uuid,
    [encounter?.uuid, selectedItem]
  );

  const itemStartDate = useMemo(
    () =>
      moment(
        encounter?.linkedAssessmentUuid
          ? encounter?.linked_assessment?.startDate
          : encounter?.startDate
      ),
    [
      encounter?.linkedAssessmentUuid,
      encounter?.linked_assessment?.startDate,
      encounter?.startDate
    ]
  );
  const itemEndDate = useMemo(
    () =>
      moment(
        encounter?.linkedAssessmentUuid
          ? encounter?.linked_assessment?.endDate
          : encounter?.endDate
      ),
    [
      encounter?.endDate,
      encounter?.linkedAssessmentUuid,
      encounter?.linked_assessment?.endDate
    ]
  );

  const duration = itemEndDate.diff(itemStartDate, 'days');
  const multiDay = duration > 1;

  const facultyLead = encounter?.faculty_lead
    ? `${encounter?.faculty_lead?.firstName} ${encounter?.faculty_lead?.lastName}`
    : 'Not assigned';

  return (
    <Layout
      className={selected ? 'selected' : ''}
      onClick={() =>
        selectSyllabusItem(encounter.uuid, 'encounter', itemNumber)
      }
    >
      <Grid container spacing={4}>
        <Grid item sm={1} xs={1}>
          {!itemStartDate ? (
            <ItemDate className="no_dates">–</ItemDate>
          ) : (
            <ItemDate>
              <DateBlock>
                <DayDisplay>{itemStartDate.format('DD')}</DayDisplay>
                <MonthDisplay>
                  {itemStartDate.format('MMM').toUpperCase()}
                </MonthDisplay>
                {multiDay && (
                  <div className="endDate">
                    <span>–</span>
                    <DayDisplay>{itemEndDate.format('DD')}</DayDisplay>
                    <MonthDisplay>
                      {itemEndDate.format('MMM').toUpperCase()}
                    </MonthDisplay>
                  </div>
                )}
              </DateBlock>
            </ItemDate>
          )}
        </Grid>
        <Grid item sm={11} xs={11}>
          <OverlineSC>
            {encounter.type} {(itemNumber + 1).toString().padStart(2, 0)}
            <span>
              &nbsp;
              {isLinked ? `– ${encounter?.linked_assessment?.title}` : ''}
            </span>
          </OverlineSC>
          <ItemTitle>{encounter.title}</ItemTitle>
          <ItemSubtitle>
            <AccessTime />
            {itemStartDate.format() !== 'Invalid date' && (
              <span>
                {itemStartDate.format('dddd h:mm a')}
                {encounter.scheduleType !== 'Multi' &&
                  ` – ${itemEndDate.format('h:mm a')}`}
              </span>
            )}
          </ItemSubtitle>
          {!isLinked && (
            <ItemSubtitle>
              <PersonOutline />
              {facultyLead}
            </ItemSubtitle>
          )}
          {isLinked && (
            <LinkedItem>
              <OverlineSC>Linked Assessment</OverlineSC>
              <Body1SC>
                {get(encounter, 'linked_assessment.title', '---')}
              </Body1SC>
            </LinkedItem>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

EncounterCard.propTypes = {
  itemNumber: PropTypes.number,
  encounter: PropTypes.object,
  selectedItem: PropTypes.string,
  selectSyllabusItem: PropTypes.func
};
EncounterCard.defaultProps = {
  itemNumber: 0,
  encounter: {},
  selectedItem: '',
  selectSyllabusItem: undefined
};

export default EncounterCard;
