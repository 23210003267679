import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Grid,
  LinearProgress
} from '@material-ui/core';
import { Heading2 } from '@xcomp/xcomp-design-library';
import { ExpandIcon } from '@xcomp/xcomp-design-library/dist/icons';

import Date from '../../../Library/Date';
import { validateSelectDate } from '../../../../helpers/validation/validateGeneric';
import { Panel, PanelHeader } from '../../../Library/DashboardSC';
import FormSelect from '../../../Library/FormSelect';
import MultiSelectOutline from '../../../Library/MultiSelectOutline';
import { doSetShowTableModal } from '../../../../redux/actions/competencyGridActions';

const FilterContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
`;

const PanelHeaderSC = styled(PanelHeader)`
  &:hover {
    cursor: pointer;
  }
`;
const FilterBody = styled(Grid)``;

const ButtonSC = styled(Button)`
  && {
    margin-bottom: 16px;
  }
`;

const ExpandColumn = styled(({ width, ...rest }) => <div {...rest} />)`
  && {
    z-index: 500;
    position: relative;
    min-width: ${props => props.width};
    height: 100%;
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;
const ClosedIcon = styled(ExpandIcon)`
  transform: rotate(180deg);
`;

const OpenedIcon = styled(ExpandIcon)`
  transform: rotate(270deg);
`;

const DateTimeSC = styled(Date)`
  && {
    margin-top: 0.375rem;
  }
`;

const getIcon = isRowOpen => {
  if (!isRowOpen) {
    return <ClosedIcon />;
  }
  return <OpenedIcon />;
};

const CompetencyGridFilter = ({
  assessmentAssociationsOptions,
  assessmentTechniquesOptions,
  assessmentTypesOptions,
  competencyCollectionOptions,
  onApplyCompetencyGridFilters,
  onResetAllFilters,
  onDeselectAssessmentAssociation,
  onDeselectAssessmentTechnique,
  onDeselectAssessmentType,
  onSelectAllAssessmentAssociations,
  onSelectAllAssessmentTechniques,
  onSelectAllAssessmentTypes,
  onSelectAssessmentAssociation,
  onSelectAssessmentTechnique,
  onSelectAssessmentType,
  onSelectCompetencyCollection,
  selectedAssessmentAssociations,
  selectedAssessmentTechniques,
  selectedAssessmentTypes,
  selectedCompetencyCollection,
  isLoading,
  selectedStartDate,
  selectedEndDate,
  handleDatePickerChange,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const isResultLoaded = useSelector(state => state.cohortCompetencyReportState.isResultLoaded);

  const toggleFilter = () => setIsOpen(!isOpen);
  return (
    <Panel maxWidth="2400px">
      <PanelHeaderSC paddingLeft="16px" onClick={toggleFilter}>
        <ExpandColumn width="30px">{getIcon(isOpen)}</ExpandColumn>
        <Heading2>Competency Filters</Heading2>
      </PanelHeaderSC>
      {isOpen && (
        <FilterContainer>
          <FilterBody container spacing={2}>
            <Grid item sm={12}>
              <FormSelect
                label="Competency Collection"
                name="competencyCollectionSelect"
                options={competencyCollectionOptions}
                value={selectedCompetencyCollection}
                handleChange={onSelectCompetencyCollection}
              />
            </Grid>
            <Grid item sm={12}>
              <MultiSelectOutline
                label="Assessment Type"
                name="assessmentTypeSelect"
                options={assessmentTypesOptions}
                value={selectedAssessmentTypes}
                handleChange={onSelectAssessmentType}
                onSelectAll={onSelectAllAssessmentTypes}
                onDeselect={onDeselectAssessmentType}
                multiple
                size='small'
              />
            </Grid>
            <Grid item sm={12}>
              <MultiSelectOutline
                label="Assessment Technique"
                name="assessmentTechniqueSelect"
                options={assessmentTechniquesOptions}
                value={selectedAssessmentTechniques}
                handleChange={onSelectAssessmentTechnique}
                onSelectAll={onSelectAllAssessmentTechniques}
                onDeselect={onDeselectAssessmentTechnique}
                multiple
                size='small'
              />
            </Grid>
            <Grid item sm={12}>
              <MultiSelectOutline
                label="Assessment Association"
                name="assessmentAssociationSelect"
                options={assessmentAssociationsOptions}
                value={selectedAssessmentAssociations}
                handleChange={onSelectAssessmentAssociation}
                onSelectAll={onSelectAllAssessmentAssociations}
                onDeselect={onDeselectAssessmentAssociation}
                multiple
                size='small'
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <DateTimeSC
                dataTestId="assessment-start-date"
                name="startDate"
                label="Start Date*"
                value={selectedStartDate}
                indentifier="start_date"
                handleChange={handleDatePickerChange}
                hasErrors={false}
                handleValidate={validateSelectDate}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <DateTimeSC
                dataTestId="assessment-end-date"
                name="endDate"
                label="End Date*"
                value={selectedEndDate}
                indentifier="endDate"
                handleChange={handleDatePickerChange}
                hasErrors={false}
                handleValidate={validateSelectDate}
              />
            </Grid>
            <Grid container item justifyContent="space-between" sm={12}>
              <ButtonSC onClick={onResetAllFilters} color="primary">
                RESET FILTERS
              </ButtonSC>
              <div>
                {isResultLoaded &&
                  <ButtonSC style={{ marginRight: 10 }} onClick={() => dispatch(doSetShowTableModal(true))} color="primary">
                    OPEN MODAL
                  </ButtonSC>
                }
                <ButtonSC
                  color="primary"
                  variant="contained"
                  onClick={onApplyCompetencyGridFilters}
                  disabled={isLoading}
                >
                  APPLY
                </ButtonSC>
              </div>
            </Grid>
          </FilterBody>
        </FilterContainer>
      )}
      {isLoading && <LinearProgress />}
    </Panel>
  );
};

CompetencyGridFilter.propTypes = {
  assessmentAssociationsOptions: PropTypes.array,
  assessmentTechniquesOptions: PropTypes.array,
  assessmentTypesOptions: PropTypes.array,
  competencyCollectionOptions: PropTypes.array,
  onApplyCompetencyGridFilters: PropTypes.func,
  onResetAllFilters: PropTypes.func,
  onDeselectAssessmentAssociation: PropTypes.func,
  onDeselectAssessmentTechnique: PropTypes.func,
  onDeselectAssessmentType: PropTypes.func,
  onSelectAllAssessmentAssociations: PropTypes.func,
  onSelectAllAssessmentTechniques: PropTypes.func,
  onSelectAllAssessmentTypes: PropTypes.func,
  onSelectAssessmentAssociation: PropTypes.func,
  onSelectAssessmentTechnique: PropTypes.func,
  onSelectAssessmentType: PropTypes.func,
  onSelectCompetencyCollection: PropTypes.func,
  handleDatePickerChange: PropTypes.func,
  selectedAssessmentAssociations: PropTypes.array,
  selectedAssessmentTechniques: PropTypes.array,
  selectedAssessmentTypes: PropTypes.array,
  selectedCompetencyCollection: PropTypes.string,
  selectedStartDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.object
  ]),
  selectedEndDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.object
  ]),
  isLoading: PropTypes.bool,
};

CompetencyGridFilter.defaultProps = {
  assessmentAssociationsOptions: [],
  assessmentTechniquesOptions: [],
  assessmentTypesOptions: [],
  competencyCollectionOptions: [],
  onApplyCompetencyGridFilters: undefined,
  onResetAllFilters: undefined,
  onDeselectAssessmentAssociation: undefined,
  onDeselectAssessmentTechnique: undefined,
  onDeselectAssessmentType: undefined,
  onSelectAllAssessmentAssociations: undefined,
  onSelectAllAssessmentTechniques: undefined,
  onSelectAllAssessmentTypes: undefined,
  onSelectAssessmentAssociation: undefined,
  onSelectAssessmentTechnique: undefined,
  onSelectAssessmentType: undefined,
  onSelectCompetencyCollection: undefined,
  handleDatePickerChange: undefined,
  selectedAssessmentAssociations: [],
  selectedAssessmentTechniques: [],
  selectedAssessmentTypes: [],
  selectedCompetencyCollection: '',
  selectedStartDate: '',
  selectedEndDate: '',
  isLoading: false
};

export default CompetencyGridFilter;
