import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ButtonIcon } from '@xcomp/xcomp-design-library';
import { shallowEqual, useSelector } from 'react-redux';
import { DeleteIcon, EditIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { topicSelector } from '../../../../redux/selectors/topicSelectors';
import { visibleToSchoolAdmin } from '../../../../helpers/authorization';
import { roles } from '../../../../helpers/constants';
import { decimalTenth } from '../../../../helpers/utilities';
import { ScoreDetail, ScoreRow } from '../ScoresSC';

const fillEmptyRow = string => {
  return !string || string === '' ? '--' : string;
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  &:hover {
    background: ${props => props.theme.colors.grey[300]};
    svg {
      display: block;
    }
  }
`;

const ButtonIconSC = styled(ButtonIcon)`
  svg {
    font-size: 22px;
  }
`;

const IconBox = styled(({ width, ...rest }) => <div {...rest} />)`
  width: ${props => props.width || 'initial'};
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  padding-right: 16px;
  min-width: 50px;

  svg {
    margin-left: 8px;
  }
`;

const EditIconSC = styled(EditIcon)`
  && {
    display: none;
  }
`;

const DeleteIconSC = styled(DeleteIcon)`
  && {
    display: none;
  }
`;

const SchoolAdminOnlyDeleteScore = visibleToSchoolAdmin(props => (
  <ButtonIconSC onClick={props.handleDelete} size="small">
    <DeleteIconSC />
  </ButtonIconSC>
));

export default function StepScore({
  uuid,
  date,
  rubricItem,
  stepSubItem,
  sequenceOrder,
  rubricOrder,
  procedureCodeDescriptor,
  procedureCodeOrder,
  stepSubItemDescription,
  topicUuid,
  relValue,
  potValue,
  criticalFailure,
  handleScoreDeleteModalOpen,
  handleScoreEditModalOpen,
  isOpportunity
}) {
  const topic = useSelector(
    state => topicSelector(state, topicUuid),
    shallowEqual
  );

  const selectedRole = useSelector(state => state.userState.selectedRole);

  const handleDelete = event => {
    event.stopPropagation();
    handleScoreDeleteModalOpen();
  };

  const schoolAdminOnlyEditScore = () => {
    if (selectedRole === roles.schoolAdmin) {
      handleScoreEditModalOpen(uuid);
    }
  };

  return (
    <Layout key={uuid}>
      <ScoreRow>
        <ScoreDetail criticalFailure={criticalFailure} width="10%">
          {fillEmptyRow(moment(date).format('M/D/YYYY'))}
        </ScoreDetail>
        {isOpportunity && (
          <ScoreDetail
            criticalFailure={criticalFailure}
            width={isOpportunity ? '15%' : '5%'}
          >
            {fillEmptyRow(procedureCodeOrder)}
          </ScoreDetail>
        )}
        <ScoreDetail criticalFailure={criticalFailure} width="10%">
          {fillEmptyRow(rubricItem)}
        </ScoreDetail>
        <ScoreDetail
          criticalFailure={criticalFailure}
          width={isOpportunity ? '15%' : '5%'}
        >
          {isOpportunity
            ? fillEmptyRow(procedureCodeDescriptor)
            : fillEmptyRow(sequenceOrder)}
        </ScoreDetail>
        {!isOpportunity && (
          <ScoreDetail
            criticalFailure={criticalFailure}
            width={isOpportunity ? '15%' : '5%'}
          >
            {fillEmptyRow(rubricOrder)}
          </ScoreDetail>
        )}
        <ScoreDetail criticalFailure={criticalFailure} width="10%">
          {fillEmptyRow(stepSubItem)}
        </ScoreDetail>
        <ScoreDetail criticalFailure={criticalFailure} width="15%">
          {stepSubItemDescription && stepSubItemDescription !== ''
            ? stepSubItemDescription
            : 'no description'}
        </ScoreDetail>
        <ScoreDetail
          criticalFailure={criticalFailure}
          width={isOpportunity ? '36%' : '26%'}
        >
          {topic.title && topic.title !== '' ? topic.title : 'no topic'}
        </ScoreDetail>
        <ScoreDetail criticalFailure={criticalFailure} width="7%">
          {fillEmptyRow(decimalTenth(relValue))}
        </ScoreDetail>
        <ScoreDetail criticalFailure={criticalFailure} width="7%">
          {fillEmptyRow(decimalTenth(potValue))}
        </ScoreDetail>
        <IconBox width="5%">
          {handleScoreEditModalOpen !== undefined && (
            <ButtonIconSC onClick={schoolAdminOnlyEditScore}>
              <EditIconSC />
            </ButtonIconSC>
          )}
          {handleScoreDeleteModalOpen !== undefined && (
            <SchoolAdminOnlyDeleteScore handleDelete={handleDelete} />
          )}
        </IconBox>
      </ScoreRow>
    </Layout>
  );
}

StepScore.propTypes = {
  uuid: PropTypes.string,
  date: PropTypes.string,
  rubricItem: PropTypes.string,
  stepSubItem: PropTypes.string,
  stepSubItemDescription: PropTypes.string,
  topicUuid: PropTypes.string,
  relValue: PropTypes.string,
  potValue: PropTypes.string,
  criticalFailure: PropTypes.bool,
  handleScoreDeleteModalOpen: PropTypes.func,
  handleScoreEditModalOpen: PropTypes.func,
  sequenceOrder: PropTypes.number,
  rubricOrder: PropTypes.number,
  isOpportunity: PropTypes.bool,
  procedureCodeDescriptor: PropTypes.string,
  procedureCodeOrder: PropTypes.number
};

StepScore.defaultProps = {
  isOpportunity: false,
  uuid: '',
  date: '',
  rubricItem: '',
  stepSubItem: '',
  stepSubItemDescription: '',
  topicUuid: '',
  relValue: '',
  potValue: '',
  sequenceOrder: undefined,
  rubricOrder: undefined,
  procedureCodeDescriptor: '',
  procedureCodeOrder: undefined,
  criticalFailure: false,
  handleScoreDeleteModalOpen: undefined,
  handleScoreEditModalOpen: undefined
};
