import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CSVReader from 'react-csv-reader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import {
  ButtonPrimary,
  ButtonOutline,
  ButtonInline
} from '@xcomp/xcomp-design-library';
import Modal from '../../../Library/Modal/Modal';
import UploadMapping from '../../../Library/UploadMapping';
import UploadErrorsNew from '../../../Library/UploadErrorsNew';
import UploadSuccess from '../../../Library/UploadSuccess';
import { headersUserCheck } from '../../../../helpers/headers_upload/headers_users';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 900px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  margin-bottom: 20px;
`;

const FileUpload = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Note = styled.div`
  margin-top: 10px;
  font-size: 12px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Import = styled(ButtonPrimary)`
  && {
    margin-left: 5px;
  }
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
`;

const LoaderMessage = styled.div`
  padding-left: 10px;
`;

const ClearResults = styled(ButtonInline)`
  && {
    margin-left: 20px;
  }
`;

const ChipSC = styled(Chip)`
  && {
    background: ${props => {
      switch (props.status) {
        case 'ready': {
          return props.theme.colors.grey[500];
        }
        case 'loading': {
          return props.theme.colors.status.warning;
        }
        case 'success': {
          return props.theme.colors.status.success;
        }
        case 'failed': {
          return props.theme.colors.status.error;
        }
        default:
          return props.theme.colors.grey[500];
      }
    }};
    color: ${props => props.theme.colors.white};
  }

  margin-left: 20px;
`;

const ButtonDanger = styled(ButtonPrimary)`
  && {
    margin-left: 20px;
    background: ${props => props.theme.colors.status.error};
    &:hover {
      filter: brightness(140%);
      background-color: ${props => props.theme.colors.status.error};
    }
  }
`;

const ButtonSummary = styled(ButtonPrimary)`
  && {
    margin-left: 20px;
    background: ${props => props.theme.colors.status.success};
    &:hover {
      filter: brightness(140%);
      background-color: ${props => props.theme.colors.status.success};
    }
  }
`;

const FileSelector = styled.div`
  width: 100%;
  margin-right: 16px;
`;

const UserUpload = ({
  uploadDate,
  status,
  openUpload,
  openMapping,
  error,
  summary,
  openError,
  openSummary,
  institutionIdTypesNames,
  handleUserUploadClose,
  handleUsers,
  handleUpload,
  handleDisplayMapping,
  handleDisplaySummary,
  handleDisplayErrors,
  handleClearResults
}) => {
  const option = [
    {
      field: 'institutionType',
      type: 'STRING',
      allowEmpty: false,
      note: 'Optional for check required for upload',
      accepted: institutionIdTypesNames
    }
  ];

  const headersUsers = [...option, ...headersUserCheck];

  function statusMap() {
    switch (status) {
      case 'ready': {
        return 'Ready for upload';
      }
      case 'loading': {
        return (
          <Loader>
            <CircularProgress color="primary" size={20} />
            <LoaderMessage>Upload in progress</LoaderMessage>
          </Loader>
        );
      }
      case 'success': {
        return 'Recent upload complete';
      }
      case 'failed': {
        return 'Recent upload failed';
      }
      default:
        return 'Ready for upload';
    }
  }

  return (
    <Modal open={openUpload} onClose={handleUserUploadClose}>
      <ModalBody>
        <Title>
          Mass Upload Users (CSV)
          <ChipSC status={status} label={statusMap()} />
        </Title>
        <FileUpload>
          <FileSelector>
            <CSVReader
              onFileLoaded={handleUsers}
              parserOptions={{
                header: true,
                skipEmptyLines: 'greedy',
                transform: (value, column) => {
                  if (column === 'firstName') {
                    return value.replace(/[^a-zA-Z0-9_-]/g, '');
                  }

                  if (column === 'lastName') {
                    return value.replace(/[^a-zA-Z0-9_-]/g, '');
                  }

                  return value;
                }
              }}
              inputId="userload"
              inputStyle={{
                width: '100%',
                color: 'black',
                background: 'lightgrey',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                borderRadius: '5px'
              }}
            />
          </FileSelector>
          <Import disabled={status === 'loading'} onClick={handleUpload}>
            Import
          </Import>
        </FileUpload>
        <Note>
          If changes are made to an existing selected file, close this modal and
          re-open then re-select the file.
        </Note>

        <Actions>
          <div>
            <ButtonPrimary onClick={handleDisplayMapping}>
              {openMapping ? `Hide Rules` : `Rules`}
            </ButtonPrimary>
            {status === 'success' && (
              <ButtonSummary onClick={handleDisplaySummary}>
                {openSummary ? `Hide Results` : `Results`}
              </ButtonSummary>
            )}
            {status === 'failed' && (
              <ButtonDanger onClick={handleDisplayErrors}>Errors</ButtonDanger>
            )}

            {(status === 'failed' || status === 'success') && (
              <ClearResults onClick={handleClearResults}>
                Clear Results
              </ClearResults>
            )}
          </div>

          <ButtonOutline onClick={handleUserUploadClose}>Close</ButtonOutline>
        </Actions>

        <UploadMapping display={openMapping} fields={headersUsers} />

        <UploadErrorsNew
          show={status === 'failed' && openError}
          error={error}
          uploadDate={uploadDate}
        />
        <UploadSuccess
          show={status === 'success' && openSummary}
          summary={summary}
          uploadDate={uploadDate}
        />
      </ModalBody>
    </Modal>
  );
};

UserUpload.propTypes = {
  institutionIdTypesNames: PropTypes.string,
  summary: PropTypes.object,
  status: PropTypes.string,
  error: PropTypes.object,
  openUpload: PropTypes.bool,
  openMapping: PropTypes.bool,
  openError: PropTypes.bool,
  openSummary: PropTypes.bool,
  uploadDate: PropTypes.string,
  handleUserUploadClose: PropTypes.func,
  handleUsers: PropTypes.func,
  handleUpload: PropTypes.func,
  handleDisplayMapping: PropTypes.func,
  handleDisplayErrors: PropTypes.func,
  handleDisplaySummary: PropTypes.func,
  handleClearResults: PropTypes.func
};

UserUpload.defaultProps = {
  institutionIdTypesNames: '',
  summary: undefined,
  status: 'Ready for upload',
  error: undefined,
  openUpload: false,
  openMapping: false,
  openError: false,
  openSummary: false,
  uploadDate: '',
  handleUserUploadClose: undefined,
  handleUsers: undefined,
  handleUpload: undefined,
  handleDisplayMapping: undefined,
  handleDisplayErrors: undefined,
  handleDisplaySummary: undefined,
  handleClearResults: undefined
};

export default UserUpload;
