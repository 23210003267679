import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Cancel from '@material-ui/icons/Cancel';
import { ButtonInline, ButtonIcon } from '@xcomp/xcomp-design-library';

const Layout = styled.div`
  background: ${props => props.theme.colors.white};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14) 0 2px 1px -1px rgba(0, 0, 0, 0.12)
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 20px;
  margin-bottom: 25px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const CancelButton = styled(ButtonInline)`
  && {
    margin-right: 10px;
  }
`;

const LetterGradeAdd = ({
  grade,
  rangeLow,
  rangeHigh,
  conversion,
  creditMultiplier,
  handleAddClose,
  handleChange,
  handleSave
}) => {
  return (
    <Layout>
      <Actions>
        <ButtonIcon onClick={() => handleAddClose()}>
          <Cancel />
        </ButtonIcon>
      </Actions>
      <Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={4}>
            <TextField
              name="grade"
              label="Grade"
              variant="outlined"
              value={grade}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="rangeLow"
              label="Range Low"
              variant="outlined"
              value={rangeLow}
              onChange={handleChange}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="rangeHigh"
              variant="outlined"
              label="Range High"
              onChange={handleChange}
              value={rangeHigh}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={4}>
            <TextField
              name="conversion"
              label="Conversion"
              variant="outlined"
              value={conversion}
              onChange={handleChange}
              type="number"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="creditMultiplier"
              label="Credit Multiplier"
              variant="outlined"
              value={creditMultiplier}
              onChange={handleChange}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Grid>
        </Grid>
      </Grid>
      <ButtonActions>
        <CancelButton onClick={() => handleAddClose()}>CANCEL</CancelButton>
        <ButtonInline
          onClick={() => {
            handleSave();
            handleAddClose();
          }}
        >
          SAVE
        </ButtonInline>
      </ButtonActions>
    </Layout>
  );
};

LetterGradeAdd.propTypes = {
  grade: PropTypes.string,
  rangeLow: PropTypes.string,
  rangeHigh: PropTypes.string,
  conversion: PropTypes.string,
  creditMultiplier: PropTypes.string,
  handleAddClose: PropTypes.func,
  handleChange: PropTypes.func,
  handleSave: PropTypes.func
};
LetterGradeAdd.defaultProps = {
  grade: '',
  rangeLow: '0.0',
  rangeHigh: '0.0',
  conversion: '0.0',
  creditMultiplier: '0.0',
  handleAddClose: undefined,
  handleChange: undefined,
  handleSave: undefined
};

export default LetterGradeAdd;
