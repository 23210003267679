import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import _ from 'lodash';
import FormSelectError from '../../Library/FormSelectError';
import { convertNullToString } from '../../../helpers/utilities';
import { programDisciplinesSelector } from '../../../redux/selectors/degreeCodeSelector';
import { validateSelectField } from '../../../helpers/validation/validateGeneric';

export default function ProgramDiscipline({
  programCip,
  programDiscipline,
  handleChange,
  hasErrors
}) {
  const programDisciplines = useSelector(
    state => programDisciplinesSelector(state, programCip),
    _.isEqual
  );

  const programDisciplinesOptions = _.map(
    programDisciplines,
    programDiscipline => ({
      value: programDiscipline.uuid,
      label: `${programDiscipline.code} - ${programDiscipline.title}`
    })
  );

  return (
    <div>
      <FormSelectError
        name="programDiscipline"
        label="Discipline*"
        handleValidation={validateSelectField}
        hasErrors={hasErrors}
        value={convertNullToString(programDiscipline)}
        handleChange={handleChange}
        options={programDisciplinesOptions}
      />
    </div>
  );
}

ProgramDiscipline.propTypes = {
  programCip: PropTypes.string,
  programDiscipline: PropTypes.string,
  handleChange: PropTypes.func,
  hasErrors: PropTypes.bool
};
ProgramDiscipline.defaultProps = {
  programCip: '',
  programDiscipline: '',
  handleChange: undefined,
  hasErrors: false
};
