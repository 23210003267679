export const GET_PART_GROUP_SUMMARY = 'GET_PART_GROUP_SUMMARY';
export const GET_PART_GROUP_SUMMARY_SUCCESS = 'GET_PART_GROUP_SUMMARY_SUCCESS';
export const GET_PART_GROUP_SUMMARY_ERROR = 'GET_PART_GROUP_SUMMARY_ERROR';

export const GET_STUDENT_PART_GROUP_SUMMARY = 'GET_STUDENT_PART_GROUP_SUMMARY';
export const GET_STUDENT_PART_GROUP_SUMMARY_SUCCESS =
  'GET_STUDENT_PART_GROUP_SUMMARY_SUCCESS';
export const GET_STUDENT_PART_GROUP_SUMMARY_ERROR =
  'GET_STUDENT_PART_GROUP_SUMMARY_ERROR';

export const RESET_PART_GROUP_SUMMARY = 'RESET_PART_GROUP_SUMMARY';
