import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import FacultyStudentScoreAttemptPanel from './FacultyDefaultScoreViewAttemptPanel';

const FacultyDefaultScoreViewAttemptPanelContainer = ({
  attemptNumber,
  scoreType,
  studentScores
}) => {
  const {
    studentScoresByAttempt,
    totalRelativeByAttempt,
    totalPotentialByAttempt
  } = _.reduce(
    studentScores,
    (acc, studentScore) => {
      const currentAttempt = studentScore.attempt;
      let relativeValue = new Decimal(0);
      let potentialValue = new Decimal(0);
      const scores = acc.studentScoresByAttempt;
      if (currentAttempt === attemptNumber) {
        scores.push(studentScore);
        relativeValue = relativeValue.plus(studentScore.relValue);
        potentialValue = potentialValue.plus(studentScore.potValue);
      }
      return {
        studentScoresByAttempt: scores,
        totalRelativeByAttempt: relativeValue
          .plus(acc.totalRelativeByAttempt)
          .toFixed(2),
        totalPotentialByAttempt: potentialValue
          .plus(acc.totalPotentialByAttempt)
          .toFixed(2)
      };
    },
    {
      studentScoresByAttempt: [],
      totalRelativeByAttempt: '0.0',
      totalPotentialByAttempt: '0.0'
    }
  );
  const resultCardUuid = _.get(studentScores, '[0].resultCardUuid', '');
  const attemptOrOpportunityLabel = `${scoreType.toUpperCase()} ${attemptNumber} - ${resultCardUuid}`;
  const isOpportunity = scoreType === 'Opportunity';

  return new Decimal(totalPotentialByAttempt).greaterThan(0) ? (
    <FacultyStudentScoreAttemptPanel
      attemptNumber={attemptNumber}
      isOpportunity={isOpportunity}
      attemptOrOpportunityLabel={attemptOrOpportunityLabel}
      scoreType={scoreType}
      studentScores={studentScoresByAttempt}
      totalRelativeByAttempt={totalRelativeByAttempt}
      totalPotentialByAttempt={totalPotentialByAttempt}
    />
  ) : null;
};

FacultyDefaultScoreViewAttemptPanelContainer.propTypes = {
  attemptNumber: PropTypes.number,
  scoreType: PropTypes.string,
  studentScores: PropTypes.array
};
FacultyDefaultScoreViewAttemptPanelContainer.defaultProps = {
  attemptNumber: 0,
  scoreType: '',
  studentScores: []
};

export default FacultyDefaultScoreViewAttemptPanelContainer;
