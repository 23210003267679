import { call, put, takeEvery } from 'redux-saga/effects';
import {
  putAssessmentGradingTechnique,
  putGradeCollectionGradingTechnique,
  putPartGradeSettingsGradingTechnique
} from '../../api/gradingTechniqueApi';

import {
  doPutAssessmentGradingTechniqueSuccess,
  doPutAssessmentGradingTechniqueError,
  doPutGradeCollectionGradingTechniqueSuccess,
  doPutGradeCollectionGradingTechniqueError,
  doPutPartGradeSettingsGradingTechniqueSuccess,
  doPutPartGradeSettingsGradingTechniqueError
} from '../actions/gradingTechniqueActions';

import {
  PUT_ASSESSMENT_GRADING_TECHNIQUE,
  PUT_GRADE_COLLECTION_GRADING_TECHNIQUE,
  PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE
} from '../actions-type';

function* handlePutAssessmentGradingTechnique(action) {
  const { gradingTechnique, assessmentUuid } = action.payload;
  try {
    const result = yield call(putAssessmentGradingTechnique, {
      assessmentUuid,
      ...gradingTechnique
    });

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutAssessmentGradingTechniqueSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutAssessmentGradingTechniqueError(error));
  }
}

function* handlePutGradeCollectionGradingTechnique(action) {
  const { gradingTechnique, gradeCollectionUuid } = action.payload;
  try {
    const result = yield call(putGradeCollectionGradingTechnique, {
      gradeCollectionUuid,
      ...gradingTechnique
    });

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutGradeCollectionGradingTechniqueSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutGradeCollectionGradingTechniqueError(error));
  }
}

function* handlePutPartGradeSettingsGradingTechnique(action) {
  const { gradingTechnique, partGradeSettingsUuid } = action.payload;
  try {
    const result = yield call(putPartGradeSettingsGradingTechnique, {
      partGradeSettingsUuid,
      ...gradingTechnique
    });

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutPartGradeSettingsGradingTechniqueSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutPartGradeSettingsGradingTechniqueError(error));
  }
}

const gradingTechnique = [
  takeEvery(
    PUT_ASSESSMENT_GRADING_TECHNIQUE,
    handlePutAssessmentGradingTechnique
  ),
  takeEvery(
    PUT_GRADE_COLLECTION_GRADING_TECHNIQUE,
    handlePutGradeCollectionGradingTechnique
  ),
  takeEvery(
    PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE,
    handlePutPartGradeSettingsGradingTechnique
  )
];

export { gradingTechnique };
