import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../../../../helpers/format/user.format';
import { TableDataText } from '../../../../PartGradeSummary/PartAssessmentCollectionReport/CollectionReportSC';
import SectionPerformanceStudentPartDataContainer from '../SectionPerformanceStudentPartDataContainer';
import {
  TableRow,
  InfoTd,
  FillerTd,
  RowInfoTitle,
  StickyDataTd
} from './SharedSC';
import { studentCalculatedSectionGradeResultSelector } from '../../../../../../redux/selectors/calculatedGradeResultSelectors';
import {
  getCalculatedGradeDisplay,
  getLetterGradeDisplay,
  getClassRankDisplay
} from '../../../../../../helpers/format/performance_report.format';

const StudentPerformanceTableRow = ({
  studentReportInfo,
  parts,
  useFillerColumn,
  fillerColumnWidth,
  idx
}) => {
  const { sectionUuid } = useParams();
  const { studentUuid } = studentReportInfo;

  const calculatedGradeResult = useSelector(state =>
    studentCalculatedSectionGradeResultSelector(state, studentUuid, sectionUuid)
  );
  const { user } = studentReportInfo;
  const { calculatedGrade, gradeModification, letterGrade, classRank } =
    calculatedGradeResult;

  const applicantFirstName = formatFirstNameMiddleName(
    user?.firstName,
    user?.middleName
  );
  const applicantLastName = formatLastName(
    user?.lastName,
    user?.marriedLastName
  );
  const applicantFullName = `${applicantLastName}, ${applicantFirstName}`;
  const unranked = calculatedGrade === null;
  const isDelayed = gradeModification === 'IP';
  const isWithdrew = gradeModification === 'W';
  const isCredit = gradeModification === 'CR';
  const isInactiveStudent = isDelayed || isWithdrew;

  const calculatedGradeDisplay = getCalculatedGradeDisplay(
    calculatedGrade,
    isDelayed,
    isWithdrew,
    isCredit
  );

  const letterGradeDisplay = getLetterGradeDisplay(
    letterGrade,
    unranked,
    isInactiveStudent,
    isCredit
  );

  const classRankDisplay = getClassRankDisplay(classRank, unranked);

  return (
    <TableRow key={user?.uuid}>
      <StickyDataTd redOne={idx % 2 === 0} redTwo={idx % 2 !== 0} left="0px">
        <TableDataText>{calculatedGradeDisplay}</TableDataText>
      </StickyDataTd>
      <StickyDataTd redOne={idx % 2 === 0} redTwo={idx % 2 !== 0} left="75px">
        <TableDataText>{letterGradeDisplay}</TableDataText>
      </StickyDataTd>
      <StickyDataTd redOne={idx % 2 === 0} redTwo={idx % 2 !== 0} left="150px">
        <TableDataText>{classRankDisplay}</TableDataText>
      </StickyDataTd>
      <InfoTd greyOne={idx % 2 !== 0}>
        <RowInfoTitle title={`${applicantFullName}`} placement="top">
          <div>{`${applicantFullName}`}</div>
        </RowInfoTitle>
      </InfoTd>
      {parts.map(part => (
        <SectionPerformanceStudentPartDataContainer
          partUuid={part.uuid}
          rowIndex={idx}
          key={part.uuid}
          studentUuid={studentUuid}
        />
      ))}
      {useFillerColumn && (
        <FillerTd greyOne={idx % 2 !== 0} borderTop width={fillerColumnWidth} />
      )}
    </TableRow>
  );
};

StudentPerformanceTableRow.propTypes = {
  studentReportInfo: PropTypes.object.isRequired,
  parts: PropTypes.array,
  useFillerColumn: PropTypes.bool,
  fillerColumnWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  idx: PropTypes.number.isRequired
};

StudentPerformanceTableRow.defaultProps = {
  parts: [],
  useFillerColumn: false,
  fillerColumnWidth: 0
};

export default StudentPerformanceTableRow;
