import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SyllabusItems from './SyllabusItems';
import { selectSyllabusItems } from '../../../redux/selectors/syllabusSelectors';

export default function SyllabusItemsContainer({ partUuid }) {
  const dispatch = useDispatch();
  const items = useSelector(state => selectSyllabusItems(state, partUuid));
  const [filterType, setFilterType] = useState('all');

  useEffect(
    () => () => {
      dispatch({ type: 'SET_SELECTED_ITEM_RESET' });
    },
    [dispatch]
  );

  const selectSyllabusItem = (uuid, type, order) => {
    dispatch({ type: 'SET_SELECTED_ITEM', payload: { uuid, type, order } });
  };

  const handleFilterType = event => {
    const { value } = event.target;
    setFilterType(value);
  };

  const sortAndFilter = () => {
    const sorted = items.sort((a, b) => {
      const aStartDate = a.linkedAssessmentUuid
        ? a.linked_assessment.startDate
        : a.startDate;
      const bStartDate = b.linkedAssessmentUuid
        ? b.linked_assessment.startDate
        : b.startDate;

      const aSD = aStartDate ? new Date(aStartDate) : 0;
      const bSD = bStartDate ? new Date(bStartDate) : 0;
      if (!aStartDate) {
        return bSD - aSD;
      }
      if (aStartDate === bStartDate) {
        const aTitle = a.title.toLowerCase();
        const bTitle = a.title.toLowerCase();
        return aTitle < bTitle ? 1 : -1;
      }
      return bStartDate ? aSD - bSD : bSD - aSD;
    });

    switch (filterType) {
      case 'all': {
        return sorted;
      }
      case 'encounter': {
        return sorted.filter(item => item.type === 'encounter');
      }
      case 'assessment': {
        return sorted.filter(item => item.type === 'assessment');
      }
      default:
        return sorted;
    }
  };

  return (
    <SyllabusItems
      items={sortAndFilter()}
      selectSyllabusItem={selectSyllabusItem}
      filterType={filterType}
      handleFilterType={handleFilterType}
    />
  );
}

SyllabusItemsContainer.propTypes = { partUuid: PropTypes.string };

SyllabusItemsContainer.defaultProps = { partUuid: undefined };
