import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InstitutionName from '../DataCells/InstitutionName';
import { decimalTenth } from '../../../../../helpers/utilities';

const Spacing = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

const Layout = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 66px;
  align-items: center;
`;

const Record = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => (props.width ? props.width : '120px')};
`;

export default function CognitiveSubjectRowRead({ transcript }) {
  return (
    <Layout>
      <Record>
        <Spacing>{transcript?.source}</Spacing>
      </Record>
      <Record>
        <Spacing>{transcript?.date}</Spacing>
      </Record>
      <Record>
        <Spacing>{transcript?.itemCode}</Spacing>
      </Record>
      <Record width="300px">
        <Spacing>{transcript?.itemName}</Spacing>
      </Record>
      <Record width="300px">
        <Spacing>
          <InstitutionName
            sourceInstitutionUuid={transcript?.sourceInstitutionUuid}
          />
        </Spacing>
      </Record>
      <Record>
        <Spacing>{decimalTenth(transcript?.credits)}</Spacing>
      </Record>
      <Record>
        <Spacing>{transcript?.grade}</Spacing>
      </Record>
      <Record>
        <Spacing>{transcript?.score}</Spacing>
      </Record>
      <Record width="66px">&nbsp;</Record>
    </Layout>
  );
}

CognitiveSubjectRowRead.propTypes = {
  transcript: PropTypes.object
};
CognitiveSubjectRowRead.defaultProps = {
  transcript: {}
};
