import { call, put, takeEvery } from 'redux-saga/effects';

import {
  deleteUserCohort,
  deleteUserCohorts,
  getApplicantsByProgram,
  getCohortApplicantsByStatus,
  getSchoolStudentByStatus,
  getUserCohortByUserAndCohort,
  getUserCohorts,
  patchUserCohortsByStatus,
  postStudentConversion,
  postUploadApplicants,
  postUserCohort,
  postUserCohorts
} from '../../api/userCohortApi';

import {
  doDeleteUserCohortError,
  doDeleteUserCohortsError,
  doDeleteUserCohortsSuccess,
  doDeleteUserCohortSuccess,
  doGetApplicantsByProgramError,
  doGetApplicantsByProgramSuccess,
  doGetCohortApplicantsByStatusError,
  doGetCohortApplicantsByStatusSuccess,
  doGetSchoolStudentsByStatusError,
  doGetSchoolStudentsByStatusSuccess,
  doGetUserCohortbyUserAndCohortError,
  doGetUserCohortbyUserAndCohortSuccess,
  doGetUserCohortsError,
  doGetUserCohortsSuccess,
  doPatchUserCohortStatusError,
  doPatchUserCohortStatusSuccess,
  doPostStudentConversionError,
  doPostStudentConversionSuccess,
  doPostUserCohortError,
  doPostUserCohortsError,
  doPostUserCohortsSuccess,
  doPostUserCohortSuccess,
  doUploadApplicantsError,
  doUploadApplicantsSuccess,
  doUploadApplicantsWarning
} from '../actions/userCohortActions';

import {
  DELETE_USER_COHORT,
  DELETE_USER_COHORTS,
  GET_APPLICANTS_BY_PROGRAM,
  GET_COHORT_APPLICANTS_BY_STATUS,
  GET_SCHOOL_STUDENTS_BY_STATUS,
  GET_USER_COHORT_BY_USER_AND_COHORT,
  GET_USERS_COHORT,
  PATCH_USER_COHORT_STATUS,
  POST_STUDENT_CONVERSION,
  POST_UPLOAD_APPLICANTS,
  POST_USER_COHORT,
  POST_USER_COHORTS
} from '../actions-type';

function* handleGetUserCohortByUserAndCohort(action) {
  try {
    const result = yield call(getUserCohortByUserAndCohort, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetUserCohortbyUserAndCohortSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUserCohortbyUserAndCohortError(error));
  }
}

function* handlePostUserCohort(action) {
  try {
    const result = yield call(postUserCohort, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostUserCohortSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostUserCohortError(error));
  }
}

function* handleGetUserCohorts(action) {
  try {
    const result = yield call(
      getUserCohorts,
      action.payload.cohortUuid,
      action.payload.status,
      action.payload.role
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetUserCohortsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUserCohortsError(error));
  }
}

function* handlePostUserCohorts(action) {
  try {
    const result = yield call(postUserCohorts, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostUserCohortsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostUserCohortsError(error));
  }
}

function* handlePatchUserCohortsStatus(action) {
  try {
    const result = yield call(patchUserCohortsByStatus, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchUserCohortStatusSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPatchUserCohortStatusError(error));
  }
}

function* handleUploadApplicants(action) {
  try {
    const result = yield call(postUploadApplicants, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doUploadApplicantsSuccess(payload));
    } else if (result.status === 422) {
      const payload = yield result.json();
      yield put(doUploadApplicantsWarning(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doUploadApplicantsError(error));
  }
}

function* handleDeleteUserCohorts(action) {
  try {
    const result = yield call(deleteUserCohorts, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteUserCohortsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteUserCohortsError(error));
  }
}

function* handlePostStudentConversion(action) {
  try {
    const result = yield call(postStudentConversion, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostStudentConversionSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostStudentConversionError(error));
  }
}

function* handleGetCohortApplicantsByStatus(action) {
  try {
    const result = yield call(getCohortApplicantsByStatus, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCohortApplicantsByStatusSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCohortApplicantsByStatusError(error));
  }
}

function* handleGetSchoolStudentsByStatus(action) {
  try {
    const result = yield call(getSchoolStudentByStatus, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetSchoolStudentsByStatusSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSchoolStudentsByStatusError(error));
  }
}

function* handleDeleteUserCohort(action) {
  try {
    const { uuid, userUuid } = action.payload;
    const result = yield call(deleteUserCohort, uuid, userUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteUserCohortSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteUserCohortError(error));
  }
}

function* handleGetApplicantsByProgram(action) {
  try {
    const { userUuid, programUuid } = action.payload;
    const result = yield call(getApplicantsByProgram, userUuid, programUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetApplicantsByProgramSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetApplicantsByProgramError(error));
  }
}

const userCohort = [
  takeEvery(GET_USERS_COHORT, handleGetUserCohorts),
  takeEvery(POST_USER_COHORT, handlePostUserCohort),
  takeEvery(
    GET_USER_COHORT_BY_USER_AND_COHORT,
    handleGetUserCohortByUserAndCohort
  ),
  takeEvery(POST_USER_COHORTS, handlePostUserCohorts),
  takeEvery(PATCH_USER_COHORT_STATUS, handlePatchUserCohortsStatus),
  takeEvery(POST_UPLOAD_APPLICANTS, handleUploadApplicants),
  takeEvery(DELETE_USER_COHORTS, handleDeleteUserCohorts),
  takeEvery(POST_STUDENT_CONVERSION, handlePostStudentConversion),
  takeEvery(GET_COHORT_APPLICANTS_BY_STATUS, handleGetCohortApplicantsByStatus),
  takeEvery(GET_SCHOOL_STUDENTS_BY_STATUS, handleGetSchoolStudentsByStatus),
  takeEvery(DELETE_USER_COHORT, handleDeleteUserCohort),
  takeEvery(GET_APPLICANTS_BY_PROGRAM, handleGetApplicantsByProgram)
];

export { userCohort };
