import {
  GET_CURRENT_RESULT_CARDS,
  GET_CURRENT_RESULT_CARDS_SUCCESS,
  GET_CURRENT_RESULT_CARDS_ERROR
} from '../actions-type';

const doGetCurrentResultCards = partUuid => ({
  type: GET_CURRENT_RESULT_CARDS,
  payload: {
    partUuid
  }
});

const doGetCurrentResultCardsSuccess = resultCards => ({
  type: GET_CURRENT_RESULT_CARDS_SUCCESS,
  payload: resultCards
});

const doGetCurrentResultCardsError = error => ({
  type: GET_CURRENT_RESULT_CARDS_ERROR,
  payload: error
});

export {
  doGetCurrentResultCards,
  doGetCurrentResultCardsSuccess,
  doGetCurrentResultCardsError
};
