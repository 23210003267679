import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { selectOverallCredits } from '../../../../redux/selectors/creditsSelectors';

export default function OverallGPA({ userUuid }) {
  const credits = useSelector(state => selectOverallCredits(state, userUuid));

  return <>{credits}</>;
}

OverallGPA.propTypes = {
  userUuid: PropTypes.string
};

OverallGPA.defaultProps = {
  userUuid: undefined
};
