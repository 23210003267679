import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import styled, { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme, createTheme } from '@xcomp/xcomp-design-library';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  adminsAuthentication,
  institutionAdminAuthentication,
  programAuthentication,
  programAdminAuthentication,
  programAdminFacultyStaffAuthentication,
  schoolAdminAndDeptFacultyAndDeptStaffAuthentication,
  schoolAdminAuthentication
} from '../../helpers/authorization';

import { doGetUserActive } from '../../redux/actions/userActions';

import Callback from '../Callback';
import NavigationContainer from '../Navigation/NavigationContainer';
import InstitutionManagementContainer from '../InstitutionManagement/InstitutionManagementContainer';
import CourseAddContainer from '../SyllabusManagement/Course/CourseAdd/CourseAddContainer';
import CourseEditContainer from '../SyllabusManagement/Course/CourseEdit/CourseEditContainer';
import SectionPerformanceSummaryContainer from '../SyllabusManagement/SectionPerformanceSummary/SectionPerformanceSummaryContainer';
import SyllabusManagementContainer from '../SyllabusManagement/SyllabusManagementContainer';
import SyllabusEditorContainer from '../SyllabusEditor/SyllabusEditorContainer';
import SyllabusContainer from '../Syllabus/SyllabusContainer';
import SectionCommitmentLogContainer from '../SyllabusManagement/SectionCommitmentLog/SectionCommitmentLogContainer';
import CohortSyllabusManagementContainer from '../CohortSyllabusManagement/CohortSyllabusManagementContainer';
import SectionStatusSettingsContainer from '../SyllabusManagement/CourseList/Section/SectionStatusSettings/SectionStatusSettingsContainer';
import PartStatusSettingsContainer from '../SyllabusManagement/CourseList/Part/PartStatusSettings/PartStatusSettingsContainer';
import PartGradeSummaryContainer from '../SyllabusManagement/PartGradeSummary/PartGradeSummaryContainer';
import StatusSettingsContainer from '../Syllabus/SyllabusAssessment/StatusSettings/StatusSettingsContainer';
import ScoresContainer from '../Syllabus/Scores/ScoresContainer';
import StudentPartRosterContainer from '../SyllabusManagement/StudentPartRoster/StudentPartRosterContainer';
import StudentRosterContainer from '../SyllabusManagement/StudentRoster/StudentRosterContainer';
import UserManagementContainer from '../UserManagement/UserManagementContainer';
import UserProfileContainer from '../UserManagement/UserProfile/UserProfileContainer';
import UserModifyContainer from '../UserManagement/UserModify/UserModifyContainer';
import DashboardContainer from '../Dashboard/DashboardContainer';
import NotificationContainer from '../Notification/NotificationContainer';
import CourseManagementContainer from '../CourseManagement/CourseManagementContainer';
import SchoolManagementContainer from '../SchoolManagement/SchoolManagementContainer';
import SchoolAddContainer from '../SchoolManagement/SchoolAdd/SchoolAddContainer';
import SchoolEditContainer from '../SchoolManagement/SchoolEdit/SchoolEditContainer';
import SchoolRoleContainer from '../SchoolManagement/SchoolRole/SchoolRoleContainer';
import SchoolDefinitionsContainer from '../SchoolManagement/SchoolDefinitions/SchoolDefinitionsContainer';
import ProgramManagementContainer from '../ProgramManagement/ProgramManagementContainer';
import ProgramAddContainer from '../ProgramManagement/ProgramAdd/ProgramAddContainer';
import ProgramEditContainer from '../ProgramManagement/ProgramEdit/ProgramEditContainer';
import ProgramRoleContainer from '../ProgramManagement/ProgramRole/ProgramRoleContainer';
import ProgramDefinitionsContainer from '../ProgramManagement/ProgramDefinitions/ProgramDefinitionsContainer';
import EditorSubjectsContainer from '../ProgramManagement/ProgramDefinitions/ProgramTranscript/Editor/EditorSubject/EditorSubjectContainer';
import EditorSubjectExamContainer from '../ProgramManagement/ProgramDefinitions/ProgramTranscript/Editor/EditorSubjectExam/EditorSubjectExamContainer';
import EditorAptitudeContainer from '../ProgramManagement/ProgramDefinitions/ProgramTranscript/Editor/EditorAptitude/EditorAptitudeContainer';
import ProgramAssessmentsManagementContainer from '../ProgramAssessmentManagement/ProgramAssessmentsManagementContainer';
import DepartmentManagementContainer from '../DepartmentManagement/DepartmentManagementContainer';
import ReportsContainer from '../Reports/ReportsContainer';
import CohortCompetencyGridContainer from '../Reports/CohortCompetencyGrid/CohortCompetencyGridContainer';
import CohortApplicantReportsContainer from '../Reports/CohortApplicantReports/CohortApplicantReportsContainer';
import DepartmentAddContainer from '../DepartmentManagement/DepartmentAdd/DepartmentAddContainer';
import DepartmentEditContainer from '../DepartmentManagement/DepartmentEdit/DepartmentEditContainer';
import DepartmentRoleContainer from '../DepartmentManagement/DepartmentRole/DepartmentRoleContainer';
import CourseMasterAddContainer from '../CourseManagement/CourseMasterAdd/CourseMasterAddContainer';
import CourseMasterEditContainer from '../CourseManagement/CourseMasterEdit/CourseMasterEditContainer';
import AcademicYearAddContainer from '../SchoolManagement/SchoolDefinitions/AcademicYear/AcademicYearAdd/AcademicYearAddContainer';
import AcademicYearEditContainer from '../SchoolManagement/SchoolDefinitions/AcademicYear/AcademicYearEdit/AcademicYearEditContainer';
import EducationLevelAddContainer from '../SchoolManagement/SchoolDefinitions/EducationLevels/EducationLevelAdd/EducationLevelAddContainer';
import EducationLevelEditContainer from '../SchoolManagement/SchoolDefinitions/EducationLevels/EducationLevelEdit/EducationLevelEditContainer';
import CohortManagementContainer from '../CohortManagement/CohortManagementContainer';
import CohortRequirementsContainer from '../CohortRequirements/CohortRequirementsContainer';
import CompetencyRequirementsContainer from '../CompetencyManagement/CompetencyRequirementsContainer';
import NewCourseCollectionDashboardContainer from '../CohortRequirements/CourseCollections/NewCourseCollection/NewCourseCollectionDashboardContainer';
import EditCourseCollectionDashboardContainer from '../CohortRequirements/CourseCollections/EditCourseCollection/EditCourseCollectionDashboardContainer';
import NewCompetencyCollectionDashboardContainer from '../CompetencyManagement/CompetencyCollections/NewCompetencyCollection/NewCompetencyCollectionDashboardContainer';
import EditCompetencyCollectionDashboardContainer from '../CompetencyManagement/CompetencyCollections/EditCompetencyCollection/EditCompetencyCollectionDashboardContainer';
import ManageCompetencyMicrosContainer from '../CompetencyManagement/CompetencyCollections/ManageCompetencyMicros/ManageCompetencyMicrosContainer';
import CohortAddContainer from '../CohortManagement/CohortAdd/CohortAddContainer';
import CohortEditContainer from '../CohortManagement/CohortEdit/CohortEditContainer';
import SyllabusCourseContainer from '../SyllabusCourse/SyllabusCourseContainer';
import SyllabusPartContainer from '../SyllabusPart/SyllabusPartContainer';
import CohortEditRoleContainer from '../CohortManagement/CohortEditRole/CohortEditRoleContainer';
import CohortRoleContainer from '../CohortManagement/CohortRoles/CohortRolesContainer';
import TranscriptApplicantContainer from '../CohortManagement/CohortApplicantTranscripts/TranscriptApplicantContainer';
import TranscriptStudentContainer from '../CohortManagement/CohortStudentTranscripts/CohortStudentTranscriptContainer';
import GradeCalculationSettingsContainer from '../Syllabus/SyllabusAssessment/GradeCalculationSettings/GradeCalculationSettingsContainer';
import GradeSummaryContainer from '../Syllabus/SyllabusAssessment/GradeSummary/GradeSummaryContainer';
import PartGradeSettingsContainer from '../SyllabusManagement/PartGradeSettings/PartGradeSettingsContainer';
import EditGradeCollectionContainer from '../SyllabusManagement/PartGradeSettings/EditGradeCollection/EditGradeCollectionContainer';
import Unauthorized from '../Pages/Unauthorized';
import { ContainerResize } from '../Library/Layout';
import './App.css';
import SectionGradeSettingsContainer from '../SyllabusManagement/SectionGradeSettings/SectionGradeSettingsContainer';
import NewProgramAssessmentMasterContainer from '../ProgramAssessmentManagement/ProgramAssessmentMaster/NewProgramAssessmentMaster/NewProgramAssessmentMasterContainer';
import EditProgramAssessmentMasterContainer from '../ProgramAssessmentManagement/ProgramAssessmentMaster/EditProgramAssessmentMaster/EditProgramAssessmentMasterContainer';
import NewCohortAssessmentCollectionDashboardContainer from '../CohortRequirements/AssessmentCollections/NewCohortAssessmentCollection/NewCohortAssessmentCollectionDashboardContainer';
import EditCohortAssessmentCollectionDashboardContainer from '../CohortRequirements/AssessmentCollections/EditCohortAssessmentCollection/EditCohortAssessmentCollectionDashboardContainer';
import CohortAssessmentSyllabusContainer from '../CohortRequirements/AssessmentCollections/CohortAssessmentSyllabus/CohortAssessmentSyllabusContainer';
import CohortAssessmentGradeSummaryContainer from '../CohortSyllabusManagement/SyllabusAssessment/GradeSummary/CohortAssessmentGradeSummaryContainer';
import CohortAssessmentScoresContainer from '../CohortSyllabusManagement/CohortAssessmentScores/ScoresContainer';
import CohortAssessmentCommitmentLogContainer from '../CohortSyllabusManagement/AssessmentCommitmentLog/AssessmentCommitmentLogContainer';
import OpportunityReportsContainer from '../Reports/OpportunityReports/OpportunityReportsContainer';
import EditQuantitativeSkillsCollection from '../CompetencyManagement/QuantitativeManagement/components/EditCollection/EditQuantitativeSkillsCollection';
import CohortSelfAssessmentReportContainer from '../Reports/CohortSelfAssessmentReport/CohortSelfAssessmentReportContainer';
import CohortQualitativeSkillsAndDocumentsContainer from '../Reports/CohortQualitativeSkillsAndDocuments/CohortQualitativeSkillsAndDocumentsContainer';
import InstitutionLogs from '../InstitutionLogs/InstitutionLogs';

const BodySC = styled.div`
  margin: 0;
  background: ${props => props.theme.colors.grey[100]};
  min-height: calc(100vh - 64px);
`;

const RouterChild = styled.div`
  min-height: calc(100vh - 64px);
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  height: 80vh;
  align-items: center;
  font-size: 50px;
`;

const DNE = styled.div`
  display: flex;
  justify-content: center;
  height: 80vh;
  align-items: center;
  font-size: 50px;
`;

const NoMatch = () => (
  <ContainerResize>
    <DNE>Page does not exist.</DNE>
  </ContainerResize>
);

const routes = [
  {
    path: '/institution-management/edit',
    component: institutionAdminAuthentication(InstitutionManagementContainer)
  },
  {
    path: '/institution-management/school/add',
    component: institutionAdminAuthentication(SchoolAddContainer)
  },
  {
    path: '/institution-management/school/edit/:schoolUuid',
    component: institutionAdminAuthentication(SchoolEditContainer)
  },
  {
    path: '/institution-management/school/role/:schoolUuid',
    component: institutionAdminAuthentication(SchoolRoleContainer)
  },
  {
    path: '/institution-management/schools',
    component: institutionAdminAuthentication(SchoolManagementContainer)
  },
  {
    path: '/institution-logs',
    component: schoolAdminAuthentication(InstitutionLogs)
  },
  {
    path: '/school-management/programs/add',
    component: schoolAdminAuthentication(ProgramAddContainer)
  },
  {
    path: '/school-management/programs/edit/:programUuid',
    component: schoolAdminAuthentication(ProgramEditContainer)
  },
  {
    path: '/school-management/programs/role/:programUuid',
    component: schoolAdminAuthentication(ProgramRoleContainer)
  },
  {
    path: '/school-management/programs',
    component: schoolAdminAuthentication(ProgramManagementContainer)
  },
  {
    path: '/school-management/departments',
    component: schoolAdminAuthentication(DepartmentManagementContainer)
  },
  {
    path: '/school-management/definitions',
    component: schoolAdminAuthentication(SchoolDefinitionsContainer)
  },
  {
    path: '/school-management/academic-year-term/add',
    component: schoolAdminAuthentication(AcademicYearAddContainer)
  },
  {
    path: '/school-management/academic-year-term/edit/:academicYearUuid',
    component: schoolAdminAuthentication(AcademicYearEditContainer)
  },
  {
    path: '/school-management/education-level/add',
    component: schoolAdminAuthentication(EducationLevelAddContainer)
  },
  {
    path: '/school-management/education-level/edit/:educationLevelUuid',
    component: schoolAdminAuthentication(EducationLevelEditContainer)
  },
  {
    path: '/department-management/add',
    component: schoolAdminAuthentication(DepartmentAddContainer)
  },
  {
    path: '/department-management/edit/:departmentUuid',
    component: schoolAdminAuthentication(DepartmentEditContainer)
  },
  {
    path: '/department-management/role/:departmentUuid',
    component: schoolAdminAuthentication(DepartmentRoleContainer)
  },
  {
    path: '/program-management/cohort/:cohortUuid/user/:userUuid/edit',
    component: programAdminAuthentication(CohortEditRoleContainer)
  },
  {
    path: '/program-management/cohort/:cohortUuid/requirements',
    component: programAdminFacultyStaffAuthentication(
      CohortRequirementsContainer
    )
  },
  {
    path: '/program-management/competency',
    component: programAdminFacultyStaffAuthentication(
      CompetencyRequirementsContainer
    ),
    props: { isQuantitative: false }
  },
  {
    path: '/program-management/quantitative-skills',
    component: programAdminFacultyStaffAuthentication(
      CompetencyRequirementsContainer
    ),
    props: { isQuantitative: true }
  },
  {
    /* QUERY PARAMS [cohortUuid, userUuid] */
    path: '/program-management/cohort/applicant-transcript',
    component: programAuthentication(TranscriptApplicantContainer)
  },
  {
    /* QUERY PARAMS [cohortUuid, userUuid] */
    path: '/program-management/cohort/student-transcript',
    component: programAuthentication(TranscriptStudentContainer)
  },
  {
    /* QUERY PARAMS [userUuid, courseUuid, partUuid] */
    path: '/program-management/cohort/syllabus-part',
    component: SyllabusPartContainer
  },
  {
    /* QUERY PARAMS [userUuid, courseUuid, partUuid] */
    path: '/program-management/cohort/syllabus-course',
    component: SyllabusCourseContainer
  },
  {
    path: '/program-management/cohort/:cohortUuid/assessment-collection/add',
    component: programAdminAuthentication(
      NewCohortAssessmentCollectionDashboardContainer
    )
  },
  {
    path: '/program-management/cohort/:cohortUuid/assessment-collection/:cohortAssessmentCollectionUuid/edit',
    component: programAdminAuthentication(
      EditCohortAssessmentCollectionDashboardContainer
    )
  },
  {
    path: '/program-management/cohort/:cohortUuid/assessment-collection/:cohortAssessmentCollectionUuid/syllabus',
    component: programAdminFacultyStaffAuthentication(
      CohortAssessmentSyllabusContainer
    )
  },
  {
    path: '/program-management/cohort/:cohortUuid/cohort-assessment-collection/:cohortAssessmentCollectionUuid/assessment/:assessmentUuid/grade-summary',
    component: programAdminFacultyStaffAuthentication(
      CohortAssessmentGradeSummaryContainer
    )
  },
  {
    path: '/program-management/cohort/:cohortUuid/assessment-collection/:cohortAssessmentCollectionUuid/syllabus-management',
    component: programAdminAuthentication(CohortSyllabusManagementContainer)
  },
  {
    path: '/program-management/cohort/:cohortUuid/course-collection/add',
    component: programAdminAuthentication(NewCourseCollectionDashboardContainer)
  },
  {
    path: '/program-management/cohort/:cohortUuid/course-collection/:courseCollectionUuid/edit',
    component: programAdminAuthentication(
      EditCourseCollectionDashboardContainer
    )
  },
  {
    path: '/program-management/competency-collection/add',
    component: programAdminAuthentication(
      NewCompetencyCollectionDashboardContainer
    )
  },
  {
    path: '/program-management/competency-collection/:competencyCollectionUuid/edit',
    component: programAdminAuthentication(
      EditCompetencyCollectionDashboardContainer
    )
  },
  {
    path: '/program-management/competency-collection/:competencyCollectionUuid/competency/:competencyUuid/add-microcompetencies',
    component: programAdminAuthentication(ManageCompetencyMicrosContainer)
  },
  {
    path: '/program-management/quantitative-skill/:quantitativeSkillUuid/edit',
    component: programAdminAuthentication(EditQuantitativeSkillsCollection)
  },
  // {
  //   /* QUERY PARAMS [programUuid, cohortUuid] */
  //   path: '/program-management/cohort/applicants',
  //   component: programAdminAuthentication(CohortApplicantContainer)
  // },
  // {
  //   path: '/program-management/cohort/students/:cohortUuid',
  //   component: programAdminAuthentication(CohortStudentContainer)
  // },
  {
    path: '/program-management/cohort/roles',
    component: programAuthentication(CohortRoleContainer)
  },
  {
    path: '/program-management/cohort/edit/:cohortUuid',
    component: programAdminAuthentication(CohortEditContainer)
  },
  {
    path: '/program-management/cohort/add',
    component: programAdminAuthentication(CohortAddContainer)
  },
  {
    path: '/program-management/cohort',
    component: programAuthentication(CohortManagementContainer)
  },
  {
    path: '/program-management/definitions',
    component: programAdminAuthentication(ProgramDefinitionsContainer)
  },
  {
    path: '/program-management/editor-subject/:programUuid',
    component: programAdminAuthentication(EditorSubjectsContainer)
  },
  {
    path: '/program-management/editor-subject-exam/:programUuid',
    component: programAdminAuthentication(EditorSubjectExamContainer)
  },
  {
    path: '/program-management/editor-aptitude-exam/:programUuid/:category',
    component: programAdminAuthentication(EditorAptitudeContainer)
  },
  {
    path: '/program-management/program-assessments',
    component: programAdminAuthentication(ProgramAssessmentsManagementContainer)
  },
  {
    path: '/program-management/program-assessment-collection/:programAssessmentCollectionUuid/program-assessment/add',
    component: programAdminAuthentication(NewProgramAssessmentMasterContainer)
  },
  {
    path: '/program-management/program-assessment-collection/:programAssessmentCollectionUuid/program-assessment/:programAssessmentMasterUuid',
    component: programAdminAuthentication(EditProgramAssessmentMasterContainer)
  },
  {
    path: '/cohort-syllabus-management/view/scores/cohort/:cohortUuid/cohort-assessment-collection/:cohortAssessmentCollectionUuid/cohort-assessment/:cohortAssessmentUuid/type/:assessmentTypeUuid',
    component: programAdminAuthentication(CohortAssessmentScoresContainer)
  },
  {
    path: '/cohort-syllabus-management/cohort/:cohortUuid/cohort-assessment/:cohortAssessmentUuid/commitment-log',
    component: programAdminAuthentication(
      CohortAssessmentCommitmentLogContainer
    )
  },
  {
    path: '/user-management/user-profile',
    component: UserProfileContainer
  },
  {
    path: '/user-management/user-edit/:userUuid',
    component: adminsAuthentication(UserModifyContainer)
  },

  {
    path: '/user-management/dashboard',
    component: adminsAuthentication(UserManagementContainer)
  },
  {
    path: '/course-management/coursemaster/new',
    component: schoolAdminAuthentication(CourseMasterAddContainer)
  },
  {
    path: '/course-management/coursemaster/edit/:courseMasterUuid',
    component: schoolAdminAuthentication(CourseMasterEditContainer)
  },
  {
    path: '/course-management/all',
    component: schoolAdminAuthentication(CourseManagementContainer)
  },

  {
    path: '/syllabus-management/course/add',
    component: schoolAdminAuthentication(CourseAddContainer)
  },
  {
    path: '/syllabus-management/course/edit/:courseUuid',
    component: schoolAdminAuthentication(CourseEditContainer)
  },
  {
    path: '/syllabus-management/studentroster/:sectionUuid/:sectionNumber',
    component: schoolAdminAndDeptFacultyAndDeptStaffAuthentication(
      StudentRosterContainer
    )
  },
  {
    path: '/syllabus-management/section/:sectionUuid/:sectionNumber/part/:partUuid/:partNumber/student-roster',
    component: schoolAdminAndDeptFacultyAndDeptStaffAuthentication(
      StudentPartRosterContainer
    )
  },
  {
    path: '/syllabus-management/editor/part/:partUuid&courseUuid=:courseUuid&sectionUuid=:sectionUuid&partNumber=:partNumber&sectionNumber=:sectionNumber',
    component: schoolAdminAndDeptFacultyAndDeptStaffAuthentication(
      SyllabusEditorContainer
    )
  },
  {
    path: '/syllabus-management/view/part/:partUuid&courseUuid=:courseUuid&sectionUuid=:sectionUuid&partNumber=:partNumber&sectionNumber=:sectionNumber',
    component:
      schoolAdminAndDeptFacultyAndDeptStaffAuthentication(SyllabusContainer)
  },
  {
    path: '/syllabus-management/section/:sectionUuid/commitment-log',
    component: schoolAdminAuthentication(SectionCommitmentLogContainer)
  },
  {
    path: '/syllabus-management/part/:partUuid/section/:sectionUuid/grade-summary',
    component: schoolAdminAndDeptFacultyAndDeptStaffAuthentication(
      PartGradeSummaryContainer
    )
  },
  {
    path: '/syllabus-management/section/:sectionUuid/section-grade-summary',
    component: schoolAdminAndDeptFacultyAndDeptStaffAuthentication(
      SectionPerformanceSummaryContainer
    )
  },
  {
    path: '/syllabus-management/section/:sectionUuid/status-settings',
    component: schoolAdminAuthentication(SectionStatusSettingsContainer)
  },
  {
    path: '/syllabus-management/part/:partUuid/status-settings',
    component: schoolAdminAuthentication(PartStatusSettingsContainer)
  },
  {
    path: '/syllabus-management/assessment/status-settings/:assessmentUuid',
    component: schoolAdminAuthentication(StatusSettingsContainer)
  },
  {
    path: '/syllabus-management/assessment/grade-calculations/:assessmentUuid',
    component: schoolAdminAuthentication(GradeCalculationSettingsContainer)
  },
  {
    path: '/syllabus-management/assessment/grade-summary/:assessmentUuid',
    component: schoolAdminAndDeptFacultyAndDeptStaffAuthentication(
      GradeSummaryContainer
    )
  },
  {
    path: '/syllabus-management/part/:partUuid/part-grade-settings&courseUuid=:courseUuid&sectionUuid=:sectionUuid&partNumber=:partNumber&sectionNumber=:sectionNumber',
    component: schoolAdminAuthentication(PartGradeSettingsContainer)
  },
  {
    path: '/syllabus-management/section/:sectionUuid/section-grade-settings&courseUuid=:courseUuid&sectionNumber=:sectionNumber',
    component: schoolAdminAuthentication(SectionGradeSettingsContainer)
  },
  {
    path: '/syllabus-management/part/:partUuid/grade-collection/:gradeCollectionUuid/edit',
    component: schoolAdminAuthentication(EditGradeCollectionContainer)
  },
  {
    path: '/syllabus-management/view/scores/section/:sectionUuid/part/:partUuid/assessment/:assessmentUuid/type/:assessmentTypeUuid',
    component:
      schoolAdminAndDeptFacultyAndDeptStaffAuthentication(ScoresContainer)
  },
  {
    path: '/syllabus-management/all',
    component: schoolAdminAndDeptFacultyAndDeptStaffAuthentication(
      SyllabusManagementContainer
    )
  },
  {
    path: '/reports/cohort/:cohortUuid/competency-grid',
    component: programAuthentication(
      CohortCompetencyGridContainer
    )
  },
  {
    path: '/cohort/:cohortUuid/reports/applicant-reports',
    component: programAuthentication(
      CohortApplicantReportsContainer
    )
  },
  {
    path: '/cohort/:cohortUuid/reports/cohort-reports',
    component: programAuthentication(OpportunityReportsContainer)
  },
  {
    path: ['/reports/cohort/:cohortUuid/assessment-grid', '/reports'],
    component: programAuthentication(ReportsContainer)
  },
  {
    path: '/cohort/:cohortUuid/self-assessment',
    component: programAuthentication(
      CohortSelfAssessmentReportContainer
    )
  },
  {
    path: '/cohort/:cohortUuid/qualitative-skills-and-documents',
    component: programAuthentication(
      CohortQualitativeSkillsAndDocumentsContainer
    )
  },
  {
    path: '/cohort/:cohortUuid/basic-and-aplied-didactic',
    component: programAuthentication(
      () => <CohortQualitativeSkillsAndDocumentsContainer isDidactic />
    )
  }
];

export default function App() {
  const dispatch = useDispatch();
  const primaryColor = useSelector(state => state.userState.primary);
  const secondaryColor = useSelector(state => state.userState.secondary);

  // TODO: remove institutionLogo below. It is not passed down to NavigationContainer
  const institutionLogo = useSelector(state => state.userState.institutionLogo);

  const { authorized, loading } = useSelector(
    state => state.authorizeState,
    shallowEqual
  );

  useEffect(() => {
    dispatch(doGetUserActive());
  }, [dispatch]);

  useEffect(() => {
    if (
      loading === 'no' &&
      authorized === 'no' &&
      process.env.REACT_APP_USE_IBM_CLOUD === 'true'
    ) {
      window.location.assign(
        `${window.location.protocol}//${window.location.hostname}/appid_logout`
      );
    }
  });

  const theme = createTheme(primaryColor, secondaryColor);

  function pageSequences() {
    if (loading === 'yes') {
      return (
        <Center>
          <CircularProgress />
        </Center>
      );
    } else if (loading === 'no' && authorized === 'yes') {
      return (
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={createMuiTheme(theme)}>
            <BodySC>
              <Router>
                <LastLocationProvider>
                  <RouterChild>
                    <NavigationContainer institutionLogo={institutionLogo} />
                    <NotificationContainer />
                    <Switch>
                      <Route exact path="/" component={DashboardContainer} />
                      {routes.map(route => (
                        <Route
                          key={route.path}
                          path={route.path}
                          render={props => (
                            <route.component
                              key={route.path}
                              {...props}
                              {...route.props}
                            />
                          )}
                        />
                      ))}
                      <Route path="/callback" component={Callback} />
                      <Route component={NoMatch} />
                    </Switch>
                  </RouterChild>
                </LastLocationProvider>
              </Router>
            </BodySC>
          </MuiThemeProvider>
        </ThemeProvider>
      );
    } else if (loading === 'no' && authorized === 'no') {
      return (
        <Router>
          <Switch>
            <Route path="/callback" component={Callback} />
            <Route component={Unauthorized} />
          </Switch>
        </Router>
      );
    } else {
      return (
        <Router>
          <Switch>
            <Route path="/callback" component={Callback} />
          </Switch>
        </Router>
      );
    }
  }

  return <>{pageSequences()}</>;
}
