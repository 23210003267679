import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Grid } from '@material-ui/core';
import { Heading2 } from '@xcomp/xcomp-design-library';
import { Panel, PanelHeader } from '../../../Library/DashboardSC';
import MultiSelectOutline from '../../../Library/MultiSelectOutline';

const FilterContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
`;
const FilterBody = styled(Grid)``;

const ButtonSC = styled(Button)`
  && {
    margin-bottom: 16px;
  }
`;

const FilterDropDown = ({
  assessmentTypes,
  assessmentTechniques,
  selectedAssessmentType,
  selectedAssessmentTechnique,
  setSelectedAssessmentType,
  setSelectedAssessmentTechnique,
  fetchReportData,
  isDidactic
}) => {
  const handleSelectOptions = event => {
    const { value, name } = event.target;

    switch (name) {
      case 'assessmentType': {
        if (value.includes('all')) {
          if (assessmentTypes.every(at => value.includes(at.value))) {
            setSelectedAssessmentType([]);
            return;
          }
          setSelectedAssessmentType(assessmentTypes.map(at => at.value));
          return;
        }

        setSelectedAssessmentType(value);
        break;
      }
      case 'assessmentTechnique': {
        if (value.includes('all')) {
          if (assessmentTechniques.every(at => value.includes(at.value))) {
            setSelectedAssessmentTechnique([]);
            return;
          }
          setSelectedAssessmentTechnique(
            assessmentTechniques.map(at => at.value)
          );
          return;
        }

        setSelectedAssessmentTechnique(value);
        break;
      }
      default:
        break;
    }
  };

  const handleDeselectOptions = name => value => {
    if (name === 'assessmentType') {
      setSelectedAssessmentType(prevTopics =>
        prevTopics.filter(topicUuid => topicUuid !== value)
      );
    }

    if (name === 'assessmentTechnique') {
      setSelectedAssessmentTechnique(prevTopics =>
        prevTopics.filter(topicUuid => topicUuid !== value)
      );
    }
  };

  const handleResetAllFilters = () => {
    setSelectedAssessmentType(assessmentTypes.map(at => at.value));
    setSelectedAssessmentTechnique(assessmentTechniques.map(at => at.value));
  };

  return (
    <Panel>
      <PanelHeader paddingLeft="16px">
        <Heading2>{isDidactic ? "Basic and Applied Didactic Report Filters" : "Qualitative Skills and Documents Report Filters"}</Heading2>
      </PanelHeader>
      <FilterContainer>
        <FilterBody container spacing={2}>
          <Grid item sm={12} md={6}>
            <MultiSelectOutline
              label="Assessment Type"
              name="assessmentType"
              options={assessmentTypes}
              value={selectedAssessmentType}
              handleChange={handleSelectOptions}
              onDeselect={handleDeselectOptions('assessmentType')}
              multiple
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <MultiSelectOutline
              label="Assessment Technique"
              name="assessmentTechnique"
              options={assessmentTechniques}
              value={selectedAssessmentTechnique}
              handleChange={handleSelectOptions}
              onDeselect={handleDeselectOptions('assessmentTechnique')}
              multiple
            />
          </Grid>
          <Grid container item justifyContent="space-between" sm={12}>
            <ButtonSC onClick={handleResetAllFilters} color="primary">
              RESET FILTERS
            </ButtonSC>
            <ButtonSC
              color="primary"
              variant="contained"
              onClick={fetchReportData}
              disabled={
                selectedAssessmentTechnique.length === 0 ||
                selectedAssessmentType.length === 0
              }
            >
              APPLY
            </ButtonSC>
          </Grid>
        </FilterBody>
      </FilterContainer>
    </Panel>
  );
};
FilterDropDown.propTypes = {
  assessmentTypes: PropTypes.array,
  assessmentTechniques: PropTypes.array,
  selectedAssessmentType: PropTypes.array,
  selectedAssessmentTechnique: PropTypes.array,
  setSelectedAssessmentType: PropTypes.func,
  setSelectedAssessmentTechnique: PropTypes.func,
  fetchReportData: PropTypes.func,
  isDidactic: PropTypes.bool,
};

FilterDropDown.defaultProps = {
  assessmentTypes: [],
  assessmentTechniques: [],
  selectedAssessmentType: null,
  selectedAssessmentTechnique: null,
  setSelectedAssessmentType: () => {},
  setSelectedAssessmentTechnique: () => {},
  fetchReportData: () => {},
  isDidactic: false,
};

export default FilterDropDown;
