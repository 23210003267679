import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { doGetProgramCohorts } from '../../redux/actions/cohortActions';
import { cohortSelectorByMatchParams } from '../../redux/selectors/cohortsSelectors';
import CohortRequirements from './CohortRequirements';

export default function CohortRequirementsContainer() {
  const dispatch = useDispatch();
  const { cohortUuid } = useParams();
  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  const cohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    shallowEqual
  );

  const [haveFetchedCohorts, setHaveFetchedCohorts] = useState(false);

  useEffect(() => {
    if (!haveFetchedCohorts && !cohort) {
      dispatch(doGetProgramCohorts(selectedProgramUuid));
      setHaveFetchedCohorts(true);
    }
  }, [dispatch, selectedProgramUuid, cohort, haveFetchedCohorts]);

  return <CohortRequirements cohort={cohort && cohort} />;
}
