import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const programSelector = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const programsSelectorORM = ormCreateSelector(orm.Program);

    const program = programsSelectorORM(redux, programUuid) || {};

    return program;
  }
);

export const programAssessmentCollectionsSelector = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const programCollectionsSelectorORM = ormCreateSelector(
      orm.ProgramAssessmentCollection
    );

    const programAssessmentCollections = programCollectionsSelectorORM(
      redux
    ).filter(collection => collection.programUuid === programUuid);

    return programAssessmentCollections || [];
  }
);

export const programAssessmentMastersSelector = createSelector(
  state => state,
  (_, programAssessmentCollectionUuid) => programAssessmentCollectionUuid,
  (redux, programAssessmentCollectionUuid) => {
    const programAssessmentMastersSelectorORM = ormCreateSelector(
      orm.ProgramAssessmentMaster
    );
    const programAssessmentMasters = programAssessmentMastersSelectorORM(
      redux
    ).filter(
      assessmentMaster =>
        assessmentMaster.programAssessmentCollectionUuid ===
        programAssessmentCollectionUuid
    );

    return programAssessmentMasters;
  }
);

export const programAssessmentCollectionSelector = createSelector(
  state => state,
  (_, programAssessmentCollectionUuid) => programAssessmentCollectionUuid,
  (redux, programAssessmentCollectionUuid) => {
    const programAssessmentCollectionSelectorORM = ormCreateSelector(
      orm.ProgramAssessmentCollection
    );

    const programAssessmentMastersSelectorORM = ormCreateSelector(
      orm.ProgramAssessmentMaster
    );

    const programAssessmentCollections = programAssessmentCollectionSelectorORM(
      redux,
      programAssessmentCollectionUuid
    );

    const programAssessmentMasters = programAssessmentMastersSelectorORM(
      redux
    ).filter(
      assessmentMaster =>
        assessmentMaster.programAssessmentCollectionUuid ===
        programAssessmentCollectionUuid
    );

    return { programAssessmentMasters, ...programAssessmentCollections };
  }
);

export const programAssessmentMasterSelector = createSelector(
  state => state,
  (_, programAssessmentMasterUuid) => programAssessmentMasterUuid,
  (redux, programAssessmentMasterUuid) => {
    const programAssessmentMastersSelectorORM = ormCreateSelector(
      orm.ProgramAssessmentMaster
    );

    const programAssessmentMaster = programAssessmentMastersSelectorORM(
      redux,
      programAssessmentMasterUuid
    );

    return programAssessmentMaster || {};
  }
);

export const programsSelector = createSelector(
  state => state,
  redux => {
    const programsSelectorORM = ormCreateSelector(orm.Program);
    const programs = programsSelectorORM(redux);
    const programsSorted = _.sortBy(programs, ['programName']);

    return programsSorted;
  }
);

export const programsBySchoolStateSelector = createSelector(
  state => state,
  state => state.userState.selectedSchoolUuid,
  (redux, selectedSchoolUuid) => {
    const programsSelectorORM = ormCreateSelector(orm.Program);
    const programs = programsSelectorORM(redux).filter(
      program => program.schoolUuid === selectedSchoolUuid
    );

    const programsSorted = _.sortBy(programs, ['programName']);

    return programsSorted;
  }
);

export const programsBySchoolPropSelector = createSelector(
  state => state,
  (_, props) => props.schoolUuid,
  (redux, schoolUuid) => {
    const programsSelectorORM = ormCreateSelector(orm.Program);
    const programs = programsSelectorORM(redux).filter(
      program => program.schoolUuid === schoolUuid
    );

    return programs;
  }
);

export const programsBySchoolActiveSelector = createSelector(
  state => state,
  state => state.userState.selectedSchoolUuid,
  (redux, selectedSchoolUuid) => {
    const programsSelectorORM = ormCreateSelector(orm.Program);
    const programs = programsSelectorORM(redux).filter(
      program => program.schoolUuid === selectedSchoolUuid
    );
    return programs;
  }
);

export const programsBySchoolSelectedSelector = createSelector(
  state => state,
  (_, props) => props.schoolUuid,
  (redux, schoolUuid) => {
    const programsSelectorORM = ormCreateSelector(orm.Program);
    const programs = programsSelectorORM(redux).filter(
      program => program.schoolUuid === schoolUuid
    );
    return programs;
  }
);

export const selectPrograms = ormCreateSelector(orm.Program);

export const selectProgramsBySchool = createSelector(
  state => state,
  (_, schoolUuid) => schoolUuid,
  (redux, schoolUuid) => {
    const programsSelectorORM = ormCreateSelector(orm.Program);

    const programs = programsSelectorORM(redux).filter(
      program => program.schoolUuid === schoolUuid
    );

    return programs;
  }
);

export const selectedProgramSelector = createSelector(
  state => state,
  state => state.userState.selectedProgramUuid,
  (redux, selectedProgramUuid) => {
    const programsSelectorORM = ormCreateSelector(orm.Program);
    const program = programsSelectorORM(redux, selectedProgramUuid);
    return program;
  }
);
