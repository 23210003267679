import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const programCipsSelector = createSelector(
  state => state,
  redux => {
    const programCipsSelectorORM = ormCreateSelector(orm.ProgramCip);
    const programCips = programCipsSelectorORM(redux);

    const programCipsSorted = _.sortBy(programCips, ['code']);

    return programCipsSorted;
  }
);

export const programDisciplinesSelector = createSelector(
  state => state,
  (_, programCip) => programCip,
  (redux, programCip) => {
    const programDisciplinesSelectorORM = ormCreateSelector(
      orm.ProgramDiscipline
    );
    const programDisciplines = programDisciplinesSelectorORM(redux).filter(
      programDiscipline => programDiscipline.cipcodeUuid === programCip
    );
    const programDisciplinesSorted = _.sortBy(programDisciplines, ['code']);

    return programDisciplinesSorted;
  }
);

export const programCodesSelector = createSelector(
  state => state,
  (_, programDiscipline) => programDiscipline,
  (redux, programDiscipline) => {
    const programCodesSelectorORM = ormCreateSelector(orm.ProgramCode);
    const programCodes = programCodesSelectorORM(redux).filter(
      programCode => programCode.disciplineUuid === programDiscipline
    );
    const programCodesSorted = _.sortBy(programCodes, ['code']);

    return programCodesSorted;
  }
);

export const programDescriptionSelector = createSelector(
  state => state,
  (_, programCode) => programCode,
  (redux, programCode) => {
    const programCodesSelectorORM = ormCreateSelector(orm.ProgramCode);
    const programCodeObject = programCodesSelectorORM(redux, programCode);

    const programDescription = _.get(
      programCodeObject,
      'description',
      undefined
    );

    return programDescription;
  }
);

export const programDegreeCodeSelector = createSelector(
  state => state,
  (_, programCip, programDiscipline, programCode) => ({
    programCip,
    programDiscipline,
    programCode
  }),
  (redux, data) => {
    const { programCip, programDiscipline, programCode } = data;

    const programCipsSelectorORM = ormCreateSelector(orm.ProgramCip);
    const programCipInfo = programCipsSelectorORM(redux, programCip);

    const programDisciplinesSelectorORM = ormCreateSelector(
      orm.ProgramDiscipline
    );
    const programDisciplineInfo = programDisciplinesSelectorORM(
      redux,
      programDiscipline
    );

    const programCodesSelectorORM = ormCreateSelector(orm.ProgramCode);
    const programCodeInfo = programCodesSelectorORM(redux, programCode);

    const getProgramCipCode = _.get(programCipInfo, 'code', '');

    const getProgramDisciplineCode = _.get(
      programDisciplineInfo,
      'code',
      undefined
    );

    const programDisciplineCode = getProgramDisciplineCode
      ? `.${getProgramDisciplineCode}`
      : '';

    const getProgramCodeCode = _.get(programCodeInfo, 'code', '');

    return `${getProgramCipCode}${programDisciplineCode}${getProgramCodeCode}`;
  }
);
