import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { Body1, colors } from '@xcomp/xcomp-design-library';
import moment from 'moment';
import AccessTime from '@material-ui/icons/AccessTime';
import PersonOutline from '@material-ui/icons/PersonOutline';
import Lens from '@material-ui/icons/Lens';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Overline from '../../Library/Overline';
import { cutStringToLength } from '../../../helpers/utilities';

const getInvalidDateText = isLinkedEncounter =>
  isLinkedEncounter ? 'Please link to an assessment' : 'Please select a date';

const SyllabusItemLayout = styled.div.attrs(({ itemType }) => ({
  type: itemType
}))`
  position: relative;
  border-bottom: 2px solid ${props => props.theme.colors.grey[300]};
  border-left: 8px solid
    ${props =>
      props.type === 'assessment'
        ? props.theme.colors.primary
        : props.theme.colors.secondary};

  padding: 1rem 0;
  > div {
    padding: 0 1rem;
  }

  &.selected {
    position: relative;
    z-index: 10;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: ${props => props.theme.colors.primary};
      opacity: 0.08;
    }
  }

  &:hover {
    cursor: pointer;
    background: rgba(155, 155, 155, 0.1);
  }
`;

const ItemDate = styled.div`
  font-size: 0.75rem;
  color: ${props => props.theme.fontColors.dark};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  &.no_dates {
    align-items: center;
  }
`;

const DateBlock = styled.div`
  width: 100%;
  text-align: center;
  .endDate {
    color: ${props => props.theme.fontColors.dark};
  }
`;

const DayDisplay = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  width: 100%;
`;

const MonthDisplay = styled.div`
  width: 100%;
  font-size: 0.625rem;
`;

const OverlineSC = styled(Overline)`
  margin: 0;
  line-height: 1.6;
  font-size: 0.75rem;
  font-weight: 400;
`;

const ItemTitle = styled.h4`
  font-size: 1rem;
  color: ${props => props.theme.fontColors.darker};
  margin: 0.25rem 0;
  font-weight: 400;
  width: 100%;
`;

const ItemSubtitle = styled.h5`
  font-size: 0.75rem;
  color: ${props => props.theme.fontColors.dark};
  margin: 0.25rem 0 0.25rem 0;
  font-weight: 400;
  width: 100%;

  svg {
    font-size: 1rem;
    vertical-align: bottom;
    margin-right: 0.5rem;
  }
`;

const Status = styled.div`
  font-size: 0.75rem;
  color: ${props => props.theme.fontColors.dark};
  position: absolute;
  bottom: 6px;
  right: 10px;
  display: flex;
  align-item: center;
  justify-content: center;
  svg {
    width: 15px;
  }
`;

const Icon = styled.div`
  && {
    color: ${props => props.color};
    display: flex;
  }
`;

const LinkedItem = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

const Body1SC = styled(Body1)`
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
`;

const itemStatusIcon = status => {
  if (status.toLowerCase() === 'approved') {
    return (
      <Icon color={colors.status.success}>
        <CheckCircle />
      </Icon>
    );
  } else if (status.toLowerCase() === 'complete') {
    return (
      <Icon color={colors.status.warning}>
        <Lens />
      </Icon>
    );
  } else {
    return (
      <Icon>
        <RadioButtonUnchecked />
      </Icon>
    );
  }
};

const SyllabusItem = ({
  item,
  itemId,
  itemNumber,
  itemTypeName,
  changeItemId
}) => {
  const isLinkedEncounter = Boolean(
    itemTypeName.toLowerCase().includes('linked')
  );
  const isLinkedAsessment = Boolean(item.linked_encounter);
  const isLinked = isLinkedEncounter || isLinkedAsessment;

  const selected = itemId === item.uuid;

  const itemStartDate = item.linkedAssessmentUuid
    ? item.linked_assessment.startDate
    : item.startDate;
  const itemEndDate = item.linkedAssessmentUuid
    ? item.linked_assessment.endDate
    : item.endDate;

  const startDateMoment = moment(itemStartDate);
  const endDateMoment = moment(itemEndDate);

  const duration = endDateMoment.diff(startDateMoment, 'days');
  const multiDay = duration > 1;

  const itemTitle = itemTypeName ? `– ${itemTypeName}` : '';

  const responsibleFaculty = item.responsible_faculty
    ? `${item.responsible_faculty.firstName} ${item.responsible_faculty.lastName}`
    : 'Not assigned';

  const facultyLead = item.faculty_lead
    ? `${item.faculty_lead.firstName} ${item.faculty_lead.lastName}`
    : 'Not assigned';

  return (
    <SyllabusItemLayout
      className={selected ? 'selected' : ''}
      onClick={() => changeItemId(item.uuid)}
      itemType={item.type}
    >
      <Grid container spacing={4}>
        <Grid item sm={1} xs={1}>
          {!itemStartDate ? (
            <ItemDate className="no_dates">–</ItemDate>
          ) : (
            <ItemDate>
              <DateBlock>
                <DayDisplay>{startDateMoment.format('DD')}</DayDisplay>
                <MonthDisplay>
                  {startDateMoment.format('MMM').toUpperCase()}
                </MonthDisplay>
                {multiDay && (
                  <div className="endDate">
                    <span>–</span>
                    <DayDisplay>{endDateMoment.format('DD')}</DayDisplay>
                    <MonthDisplay>
                      {endDateMoment.format('MMM').toUpperCase()}
                    </MonthDisplay>
                  </div>
                )}
              </DateBlock>
            </ItemDate>
          )}
        </Grid>
        <Grid item sm={11} xs={11}>
          <Status>{itemStatusIcon(item.status)}</Status>
          <OverlineSC>
            {item.type} {(itemNumber + 1).toString().padStart(2, 0)}
            <span>
              &nbsp;
              {itemTitle}
            </span>
          </OverlineSC>
          <ItemTitle>
            {item.title ? cutStringToLength(item.title, 35) : ''}
          </ItemTitle>
          <ItemSubtitle>
            <AccessTime />
            {startDateMoment.format() !== 'Invalid date' ? (
              <span>
                {startDateMoment.format('dddd')}
                &nbsp;
                {moment(itemStartDate).format('h:mm a')}
                {item.scheduleType !== 'Multi' &&
                  ` – ${endDateMoment.format('h:mm a')}`}
              </span>
            ) : (
              getInvalidDateText(isLinkedEncounter)
            )}
          </ItemSubtitle>
          {!isLinkedEncounter && (
            <ItemSubtitle>
              <PersonOutline />
              {item.type === 'assessment' ? responsibleFaculty : facultyLead}
            </ItemSubtitle>
          )}
          {isLinked && (
            <LinkedItem>
              <OverlineSC>
                Linked&nbsp;{isLinkedEncounter ? 'Assessment' : 'Encounter'}
              </OverlineSC>
              <Body1SC>
                {isLinkedEncounter
                  ? _.get(item, 'linked_assessment.title', '---')
                  : _.get(item, 'linked_encounter.title', '---')}
              </Body1SC>
            </LinkedItem>
          )}
        </Grid>
      </Grid>
    </SyllabusItemLayout>
  );
};

SyllabusItem.propTypes = {
  item: PropTypes.object,
  itemId: PropTypes.string,
  itemNumber: PropTypes.number,
  itemTypeName: PropTypes.string,
  changeItemId: PropTypes.func
};
SyllabusItem.defaultProps = {
  item: {},
  itemId: '',
  itemNumber: 1,
  itemTypeName: '',
  changeItemId: undefined
};

export default SyllabusItem;
