import {
  GET_PART_GRADE_SETTINGS,
  GET_PART_GRADE_SETTINGS_SUCCESS,
  GET_PART_GRADE_SETTINGS_ERROR,
  GET_GRADE_COLLECTION,
  GET_GRADE_COLLECTION_SUCCESS,
  GET_GRADE_COLLECTION_ERROR,
  PUT_PART_GRADE_DATA_SOURCE,
  PUT_PART_GRADE_DATA_SOURCE_SUCCESS,
  PUT_PART_GRADE_DATA_SOURCE_ERROR,
  PUT_PART_GRADE_COLLECTIONS,
  PUT_PART_GRADE_COLLECTIONS_SUCCESS,
  PUT_PART_GRADE_COLLECTIONS_ERROR,
  PUT_COLLECTION_ASSESSMENTS,
  PUT_COLLECTION_ASSESSMENTS_SUCCESS,
  PUT_COLLECTION_ASSESSMENTS_ERROR
} from '../actions-type';

const doGetPartGradeSettings = partUuid => ({
  type: GET_PART_GRADE_SETTINGS,
  payload: {
    partUuid
  }
});

const doGetPartGradeSettingsSuccess = result => ({
  type: GET_PART_GRADE_SETTINGS_SUCCESS,
  payload: result
});

const doGetPartGradeSettingsError = error => ({
  type: GET_PART_GRADE_SETTINGS_ERROR,
  payload: error
});

const doGetGradeCollection = ({ partUuid, gradeCollectionUuid }) => ({
  type: GET_GRADE_COLLECTION,
  payload: {
    partUuid,
    gradeCollectionUuid
  }
});

const doGetGradeCollectionSuccess = result => ({
  type: GET_GRADE_COLLECTION_SUCCESS,
  payload: result
});

const doGetGradeCollectionError = error => ({
  type: GET_GRADE_COLLECTION_ERROR,
  payload: error
});

const doPutPartGradeDataSource = ({ partUuid, dataSource }) => ({
  type: PUT_PART_GRADE_DATA_SOURCE,
  payload: {
    partUuid,
    dataSource
  }
});

const doPutPartGradeDataSourceSuccess = result => ({
  type: PUT_PART_GRADE_DATA_SOURCE_SUCCESS,
  payload: result
});

const doPutPartGradeDataSourceError = error => ({
  type: PUT_PART_GRADE_DATA_SOURCE_ERROR,
  payload: error
});

const doPutPartGradeCollections = (
  partGradeSettingsUuid,
  grade_collections,
  collectionsToDelete,
  distributeWeightEqually
) => ({
  type: PUT_PART_GRADE_COLLECTIONS,
  payload: {
    partGradeSettingsUuid,
    grade_collections,
    collectionsToDelete,
    distributeWeightEqually
  }
});

const doPutPartGradeCollectionsSuccess = result => ({
  type: PUT_PART_GRADE_COLLECTIONS_SUCCESS,
  payload: result
});

const doPutPartGradeCollectionsError = error => ({
  type: PUT_PART_GRADE_COLLECTIONS_ERROR,
  payload: error
});

const doPutCollectionAssessments = ({
  partUuid,
  scoreType,
  gradeCollectionUuid,
  distributeWeightEqually,
  collection_assessments
}) => ({
  type: PUT_COLLECTION_ASSESSMENTS,
  payload: {
    partUuid,
    scoreType,
    gradeCollectionUuid,
    distributeWeightEqually,
    collection_assessments
  }
});

const doPutCollectionAssessmentsSuccess = result => ({
  type: PUT_COLLECTION_ASSESSMENTS_SUCCESS,
  payload: result
});

const doPutCollectionAssessmentsError = error => ({
  type: PUT_COLLECTION_ASSESSMENTS_ERROR,
  payload: error
});

export {
  doGetPartGradeSettings,
  doGetPartGradeSettingsSuccess,
  doGetPartGradeSettingsError,
  doGetGradeCollection,
  doGetGradeCollectionSuccess,
  doGetGradeCollectionError,
  doPutPartGradeDataSource,
  doPutPartGradeDataSourceSuccess,
  doPutPartGradeDataSourceError,
  doPutPartGradeCollections,
  doPutPartGradeCollectionsSuccess,
  doPutPartGradeCollectionsError,
  doPutCollectionAssessments,
  doPutCollectionAssessmentsSuccess,
  doPutCollectionAssessmentsError
};
