import fetch from '../helpers/fetch';

const getCohortOpportunityReport = ({ cohortUuid, assessmentTypes }) =>
  fetch(
    `/api/cohort/${cohortUuid}/opportunity-report?assessmentTypes=${JSON.stringify(
      assessmentTypes
    )}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getCohortOpportunityReport };
