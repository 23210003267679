import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Heading3 } from '@xcomp/xcomp-design-library';
import SimpleModal from '../Library/Modal/SimpleModal';
import Notes from './SyllabusEncounter/SyllabusNotes';
import LearningObjectivesList from './SyllabusEncounter/LearningObjectivesList';

const ModalBody = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;

  h3 {
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

const ItemDetailViewModal = ({
  isModalOpen,
  handleModalClose,
  notes,
  objectives,
  facultyAssigned
}) => {
  return (
    <SimpleModal
      open={Boolean(isModalOpen)}
      onClose={handleModalClose}
      maxWidth="md"
    >
      <ModalBody>
        {isModalOpen === 'notes' ? (
          <>
            <Heading3>Notes</Heading3>
            <Notes
              notes={notes}
              facultyAssigned={facultyAssigned}
              maxHeight="550px"
              minWidth="800px"
            />
          </>
        ) : (
          <>
            <Heading3>Learning Objectives</Heading3>
            <LearningObjectivesList
              objectives={objectives}
              maxHeight="550px"
              minWidth="800px"
            />
          </>
        )}
      </ModalBody>
    </SimpleModal>
  );
};

ItemDetailViewModal.propTypes = {
  isModalOpen: PropTypes.string,
  handleModalClose: PropTypes.func,
  notes: PropTypes.string,
  objectives: PropTypes.arrayOf(PropTypes.object),
  facultyAssigned: PropTypes.arrayOf(PropTypes.object)
};
ItemDetailViewModal.defaultProps = {
  isModalOpen: '',
  handleModalClose: undefined,
  notes: '',
  objectives: [],
  facultyAssigned: []
};

export default ItemDetailViewModal;
