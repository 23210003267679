import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Subtitle2 } from '@xcomp/xcomp-design-library';

import _ from 'lodash';
import {
  selectCoursesByCollectionComplete,
  selectCoursesByCollectionEnrolled,
  selectCoursesByCollectionRemaining
} from '../../../../redux/selectors/coursesSelectors';

import CompletionRate from '../Calculations/CompletionRate';
import CoursesTable from './CoursesTable';
import CustomDialog from '../../../Library/Modal/CustomDialog';

const Title = styled.div`
  margin-left: 132px;
  width: 100%;
`;

export const StatusLine = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }
`;

export const StatusLabel = styled(Subtitle2)`
  color: ${props => props.theme.fontColors.darker};
  position: relative;
  display: inline-block;
  z-index: 100;
  background: ${props => props.theme.colors.white};
  padding-right: 0.5rem;
  font-weight: 600;
`;

const Label = styled.span``;

export default function CoursesByCollectionTable({ collectionUuid, userUuid }) {
  const [open, setOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState();
  const [dialogTitle, setDialogTitle] = useState();

  const handleClick = useCallback((description, title) => {
    setOpen(true);
    setSelectedDescription(description);
    setDialogTitle(title);
  }, []);

  const completed = useSelector(
    state => selectCoursesByCollectionComplete(state, collectionUuid, userUuid),
    _.isEqual
  );

  const enrolled = useSelector(
    state => selectCoursesByCollectionEnrolled(state, collectionUuid, userUuid),
    _.isEqual
  );

  const remaining = useSelector(
    state =>
      selectCoursesByCollectionRemaining(state, collectionUuid, userUuid),
    _.isEqual
  );

  return (
    <>
      <Title>
        <StatusLine offset="0">
          <StatusLabel>
            <Label>Complete</Label>
            <CompletionRate
              collectionUuid={collectionUuid}
              completed={completed}
              enrolled={enrolled}
              remaining={remaining}
            />
          </StatusLabel>
        </StatusLine>
      </Title>
      {completed?.length > 0 && (
        <CoursesTable
          handleClick={handleClick}
          userUuid={userUuid}
          courses={completed}
        />
      )}
      <Title>
        <StatusLine offset="0">
          <StatusLabel>Enrolled</StatusLabel>
        </StatusLine>
      </Title>
      {enrolled?.length > 0 && (
        <CoursesTable
          handleClick={handleClick}
          userUuid={userUuid}
          courses={enrolled}
        />
      )}
      <Title>
        <StatusLine offset="0">
          <StatusLabel>Remaining</StatusLabel>
        </StatusLine>
      </Title>
      {remaining?.length > 0 && (
        <CoursesTable
          handleClick={handleClick}
          userUuid={userUuid}
          courses={remaining}
        />
      )}
      <CustomDialog
        open={open}
        handleClose={setOpen}
        title={dialogTitle}
        contentText={selectedDescription}
      />
    </>
  );
}

CoursesByCollectionTable.propTypes = {
  userUuid: PropTypes.string,
  collectionUuid: PropTypes.string
};

CoursesByCollectionTable.defaultProps = {
  userUuid: undefined,
  collectionUuid: undefined
};
