import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ButtonPrimary } from '@xcomp/xcomp-design-library';

import {
  ContainerPage,
  ContainerActions,
  ElevateLow
} from '../../../Library/Layout';
import EducationLevelShowContainer from './EducationLevelShow/EducationLevelShowContainer';

const TitleArea = styled.div`
  width: 100%;
  padding: 1rem 3.5rem 1rem 1.5rem;
  margin-bottom: 20px;
`;

const TitleBox = styled.div.attrs(({ size }) => ({
  width: size
}))`
  width: ${props => props.width};
  font-size: 0.8rem;
  color: ${props => props.theme.fontColors.dark};
`;

const EducationLevels = ({ educationLevels, handleEditMode }) => {
  return (
    <div>
      <ContainerActions paddingTop="20px">
        <Link to="/school-management/education-level/add">
          <ButtonPrimary>Add Level</ButtonPrimary>
        </Link>
      </ContainerActions>

      <ContainerPage paddingTop="60px">
        <ElevateLow>
          <TitleArea>
            <TitleBox size="100%">Education Level</TitleBox>
          </TitleArea>
        </ElevateLow>
        {educationLevels
          .sort((a, b) => a.level - b.level)
          .map(educationLevel => (
            <EducationLevelShowContainer
              key={educationLevel.uuid}
              educationLevel={educationLevel}
              handleEditMode={handleEditMode}
            />
          ))}
      </ContainerPage>
    </div>
  );
};

EducationLevels.propTypes = {
  educationLevels: PropTypes.array,
  handleEditMode: PropTypes.func
};
EducationLevels.defaultProps = {
  educationLevels: [],
  handleEditMode: undefined
};

export default EducationLevels;
