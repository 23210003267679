import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getUsersSchoolAdmin,
  getUsersSchool,
  getUsersSchools
} from '../../api/userSchoolApi';

import {
  doGetUsersSchoolAdminSuccess,
  doGetUsersSchoolAdminError,
  doGetUsersSchoolSuccess,
  doGetUsersSchoolError,
  doGetUsersSchoolsSuccess,
  doGetUsersSchoolsError
} from '../actions/userSchoolActions';

import {
  GET_USERS_SCHOOL_ADMIN,
  GET_USERS_SCHOOL,
  GET_USERS_SCHOOLS
} from '../actions-type';

function* handleGetUsersSchoolAdmins() {
  try {
    const result = yield call(getUsersSchoolAdmin);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetUsersSchoolAdminSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUsersSchoolAdminError(error));
  }
}

function* handleGetUsersSchool(action) {
  try {
    const result = yield call(getUsersSchool, action.payload.schoolUuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetUsersSchoolSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUsersSchoolError(error));
  }
}

function* handleGetUsersSchools() {
  try {
    const result = yield call(getUsersSchools);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetUsersSchoolsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUsersSchoolsError(error));
  }
}

const userSchool = [
  takeEvery(GET_USERS_SCHOOL_ADMIN, handleGetUsersSchoolAdmins),
  takeEvery(GET_USERS_SCHOOL, handleGetUsersSchool),
  takeEvery(GET_USERS_SCHOOLS, handleGetUsersSchools)
];

export { userSchool };
