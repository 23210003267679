import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { competencyScoreSelector } from '../../../../redux/selectors/competencyScoreSelectors';

export default function CompetencyPotentialByType({
  competencyUuid,
  assessmentTypeUuid
}) {
  const score = useSelector(
    state => competencyScoreSelector(state, competencyUuid),
    _.isEqual
  );

  return <>{score?.[assessmentTypeUuid]?.potTotal || '- -'}</>;
}

CompetencyPotentialByType.propTypes = {
  competencyUuid: PropTypes.string,
  assessmentTypeUuid: PropTypes.string
};

CompetencyPotentialByType.defaultProps = {
  competencyUuid: undefined,
  assessmentTypeUuid: undefined
};
