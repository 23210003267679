import {
  FILTER_COURSE_DEPARTMENT_UUID,
  FILTER_COURSE_COURSE_MASTER_UUID,
  FILTER_COURSE_ACADEMIC_YEAR_UUID,
  FILTER_COURSE_TERM_UUID,
  SET_CURRENT_ROLE,
  OPEN_COURSE_ACCORDION,
  CLOSE_COURSE_ACCORDION,
  OPEN_SECTION_ACCORDION,
  CLOSE_SECTION_ACCORDION
} from '../actions-type';

const INITIAL_STATE = {
  departmentUuid: '',
  courseMasterUuid: '',
  academicYearUuid: '',
  termUuid: '',
  openCourseAccordions: {},
  openSectionAccordions: {}
};

const filterCourseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_ROLE: {
      return {
        ...state,
        departmentUuid: '',
        courseMasterUuid: '',
        academicYearUuid: '',
        termUuid: ''
      };
    }
    case FILTER_COURSE_DEPARTMENT_UUID: {
      return {
        ...state,
        departmentUuid: action.payload.departmentUuid
      };
    }
    case FILTER_COURSE_COURSE_MASTER_UUID: {
      return {
        ...state,
        courseMasterUuid: action.payload.courseMasterUuid
      };
    }
    case FILTER_COURSE_ACADEMIC_YEAR_UUID: {
      return {
        ...state,
        academicYearUuid: action.payload.academicYearUuid
      };
    }
    case FILTER_COURSE_TERM_UUID: {
      return {
        ...state,
        termUuid: action.payload.termUuid
      };
    }
    case OPEN_COURSE_ACCORDION: {
      const { courseUuid } = action.payload;
      const newOpenCourseAccordions = { ...state.openCourseAccordions };
      newOpenCourseAccordions[courseUuid] = true;
      return {
        ...state,
        openCourseAccordions: newOpenCourseAccordions
      };
    }
    case CLOSE_COURSE_ACCORDION: {
      const { courseUuid } = action.payload;
      const newOpenCourseAccordions = { ...state.openCourseAccordions };
      if (newOpenCourseAccordions[courseUuid]) {
        delete newOpenCourseAccordions[courseUuid];
      }
      return {
        ...state,
        openCourseAccordions: newOpenCourseAccordions
      };
    }
    case OPEN_SECTION_ACCORDION: {
      const { sectionUuid } = action.payload;
      const newOpenSectionAccordions = { ...state.openSectionAccordions };
      newOpenSectionAccordions[sectionUuid] = true;
      return {
        ...state,
        openSectionAccordions: newOpenSectionAccordions
      };
    }
    case CLOSE_SECTION_ACCORDION: {
      const { sectionUuid } = action.payload;
      const newOpenSectionAccordions = { ...state.openSectionAccordions };
      if (newOpenSectionAccordions[sectionUuid]) {
        delete newOpenSectionAccordions[sectionUuid];
      }
      return {
        ...state,
        openSectionAccordions: newOpenSectionAccordions
      };
    }
    default:
      return state;
  }
};

export default filterCourseReducer;

export { INITIAL_STATE };
