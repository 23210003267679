import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body2 } from '@xcomp/xcomp-design-library';

import CoursePanel from './CoursePanel';
import { Panel, PanelHeader } from '../../../Library/DashboardSC';
import CourseFilterContainer from '../../CourseFilter/CourseFilterContainer';

const RowLabelsLayout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding-right: 88px;

  p {
    flex-grow: 0;
    min-width: 100px;
    margin-right: 0.5rem;

    &:nth-child(2) {
      flex-grow: 2;
    }
  }
`;

const PanelHeaderSC = styled(PanelHeader)`
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
`;

const StatusLabel = styled(Body2)`
  text-align: right;
`;

const CourseList = ({ filteredCourses }) => (
  <Panel maxWidth="1240px">
    <CourseFilterContainer />
    {filteredCourses.length > 0 && (
      <PanelHeaderSC paddingLeft="24px">
        <RowLabelsLayout>
          <Body2>Course #</Body2>
          <Body2>Course Title</Body2>
          <StatusLabel>Status</StatusLabel>
        </RowLabelsLayout>
      </PanelHeaderSC>
    )}

    {filteredCourses.map(course => (
      <CoursePanel
        key={course.uuid}
        title={course?.course_master?.title}
        course={course}
        courseNumber={course?.course_master?.courseNumber}
        description={course?.course_master?.description}
        sections={course?.sections}
      />
    ))}
  </Panel>
);

CourseList.propTypes = {
  filteredCourses: PropTypes.arrayOf(PropTypes.object)
};

CourseList.defaultProps = {
  filteredCourses: []
};

export default CourseList;
