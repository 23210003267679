import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectCreditsByInstitution } from '../../../../../redux/selectors/rigorIndexSelectors';

export default function InstitutionCredits({
  programUuid,
  sourceInstitutionUuid
}) {
  const credits = useSelector(state =>
    selectCreditsByInstitution(state, programUuid, sourceInstitutionUuid)
  );

  return <>{credits && credits}</>;
}

InstitutionCredits.propTypes = {
  programUuid: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string
};

InstitutionCredits.defaultProps = {
  programUuid: undefined,
  sourceInstitutionUuid: undefined
};
