import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Paper,
  Tooltip
} from '@material-ui/core';
import styled from 'styled-components';
import ChevronRight from '@material-ui/icons/ChevronRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types'; // import AccordionRowContainer from '../AccordionRowContainer'
// import AccordionRowContainer from '../AccordionRowContainer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  //border-bottom: 1px solid ${props => props.theme.colors.grey['100']};
  padding: 10px;
`;

const AccordionHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const AccordionSummaryStyled = styled(AccordionSummary)`
  align-items: center;
  flex: 1;
  display: flex;
  justify-content: center;
`;

const AccordionDetailsChild = styled.div`
  display: flex;
  //padding-left: 64px;
`;

const AccordionWithIcons = ({ children, actions, header }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Wrapper>
      <Accordion
        style={{ padding: '10px 20px' }}
        square
        component={Paper}
        elevation={3}
        onChange={() => setOpen(!open)}
      >
        <AccordionHeader>
          <AccordionSummaryStyled>
            <div style={{ paddingRight: '24px' }}>
              {open ? <KeyboardArrowDown /> : <ChevronRight />}
            </div>
            {header}
            {/* <Typography>{header}</Typography> */}
          </AccordionSummaryStyled>
          <Box>
            {actions.map(action => (
              <Tooltip title={action.tooltip} key={action.key}>
                <IconButton key={action.key}>{action.icon}</IconButton>
              </Tooltip>
            ))}
          </Box>
        </AccordionHeader>
        <AccordionDetails>
          <AccordionDetailsChild>{children}</AccordionDetailsChild>
        </AccordionDetails>
      </Accordion>
    </Wrapper>
  );
};

AccordionWithIcons.propTypes = {
  // shouldStartOpen: PropTypes.bool,
  // onRowOpen: PropTypes.func,
  // onRowClose: PropTypes.func,
  children: PropTypes.node,
  actions: PropTypes.array,
  header: PropTypes.string
};
AccordionWithIcons.defaultProps = {
  // shouldStartOpen: false,
  // onRowOpen: () => {},
  // onRowClose: () => {},
  children: null,
  actions: [],
  header: ''
};

export default AccordionWithIcons;
