import ButtonPrimary from '@xcomp/xcomp-design-library/dist/components/ButtonPrimary';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ContainerActions,
  ContainerContent,
  ContainerPage,
  ElevateLow,
  ElevateOverLay
} from '../../../Library/Layout';

import CredentialAddContainer from './CredentialForm/CredentialAddContainer';
import CredentialEditContainer from './CredentialForm/CredentialEditContainer';

const AddLayout = styled.div`
  margin-bottom: 30px;
`;

const Title = styled.div`
  padding: 2rem;
  font-size: 24px;
  color: ${props => props.theme.colors.black};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

export default function UserProfileCredentials({
  credentialEditing,
  credentials,
  userUuid,
  addCredential,
  handleOpenAdd,
  handleCloseAdd,
  handleCredentialEditing
}) {
  return (
    <>
      <ContainerActions paddingTop="20px">
        <ButtonPrimary onClick={handleOpenAdd}>Add</ButtonPrimary>
      </ContainerActions>
      <ContainerPage paddingTop="40px">
        {addCredential && (
          <AddLayout>
            <ElevateOverLay>
              <ContainerContent>
                <CredentialAddContainer
                  userUuid={userUuid}
                  addCredential={addCredential}
                  handleCloseAdd={handleCloseAdd}
                />
              </ContainerContent>
            </ElevateOverLay>
          </AddLayout>
        )}
        <ElevateLow>
          <Title>Credentials</Title>
          <ContainerContent>
            {credentials?.map(credential => (
              <CredentialEditContainer
                key={credential.uuid}
                credentialUuid={credential.uuid}
                credentialEditing={credentialEditing}
                handleCredentialEditing={handleCredentialEditing}
              />
            ))}
          </ContainerContent>
        </ElevateLow>
      </ContainerPage>
    </>
  );
}

UserProfileCredentials.propTypes = {
  credentialEditing: PropTypes.bool,
  credentials: PropTypes.array,
  userUuid: PropTypes.string,
  addCredential: PropTypes.bool,
  handleOpenAdd: PropTypes.func,
  handleCloseAdd: PropTypes.func,
  handleCredentialEditing: PropTypes.func
};

UserProfileCredentials.defaultProps = {
  credentialEditing: false,
  credentials: [],
  userUuid: undefined,
  addCredential: false,
  handleOpenAdd: undefined,
  handleCloseAdd: undefined,
  handleCredentialEditing: undefined
};
