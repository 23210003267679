import React, { useEffect, useReducer, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import _ from 'lodash';
import NewProgramAssessmentMaster from './NewProgramAssessmentMaster';
import {
  programAssessmentCollectionSelector,
  programSelector
} from '../../../../redux/selectors/programSelectors';
import {
  doGetProgramAssessments,
  doPostProgramAssessmentMaster
} from '../../../../redux/actions/programActions';
import { doClearCreated } from '../../../../redux/actions/competencyCollectionActions';
import {
  initialProgramAssessmentMasterState,
  programAssessmentMasterReducer,
  setShowValidationErrors
} from '../programAssessmentMasterState';
import {
  assessmentTypesSelector,
  assessmentAssociationsSelector
} from '../../../../redux/selectors/assessmentFieldSelectors';
import {
  allFieldsAreValid,
  validateRequired,
  validateStringLength,
  validateDecimalStringField
} from '../../../../helpers/validation/validateGeneric';
import { useUnsavedChanges } from '../../../Library/Modal/UnsavedChangesModal/useUnsavedChanges';
import {
  gradingTechniqueReducer,
  initialGradingTechniqueState
} from '../../../Library/GradingTechniqueForm/gradingTechniqueState';

export default function NewProgramAssessmentMasterContainer() {
  const dispatch = useDispatch();
  // eslint-disable-next-line prefer-const
  let history = useHistory();

  const { programAssessmentCollectionUuid } = useParams();

  const programUuid = useSelector(state => state.userState.selectedProgramUuid);
  const successfullyUpdated = useSelector(
    state => state.formSuccessState.successfullyUpdatedProgramAssessments
  );

  const [state, programAssessmentDispatch] = useReducer(
    programAssessmentMasterReducer,
    initialProgramAssessmentMasterState
  );

  const [gradingTechniqueState, gradingTechniqueDispatch] = useReducer(
    gradingTechniqueReducer,
    initialGradingTechniqueState
  );

  const clearForm = useCallback(() => {
    programAssessmentDispatch({
      type: 'clearForm'
    });
    const historyString = `/program-management/program-assessments`;
    history.push(historyString);
  }, [history]);

  const [
    isUnsavedChangesModalOpen,
    unsavedChanges,
    handleModalOpen,
    handleModalClose,
    setUnsavedChange,
    removeUnsavedChange,
    handleProceed
  ] = useUnsavedChanges(clearForm);

  const assessmentTypes = useSelector(
    state => assessmentTypesSelector(state),
    _.isEqual
  );

  const assessmentAssociations = useSelector(
    state => assessmentAssociationsSelector(state),
    _.isEqual
  );

  const program = useSelector(
    state => programSelector(state, programUuid),
    _.isEqual
  );

  const parentCollection = useSelector(
    state =>
      programAssessmentCollectionSelector(
        state,
        programAssessmentCollectionUuid
      ),
    _.isEqual
  );

  const onPostCreate = programAssessmentMaster =>
    dispatch(doPostProgramAssessmentMaster(programAssessmentMaster));

  const setGradingTechniqueValidationErrors = ({
    curvedFailingGrade,
    gradingTechnique,
    maximumRevusInput,
    // maximumMethodCalculated,
    lowScoreInput,
    lowScoreCalculated,
    scaledHighScore,
    scaledLowScore
  }) => {
    const errorObject = {};
    switch (gradingTechnique) {
      case 'points': {
        errorObject.pointOnlyError = { invalid: false };
        break;
      }
      case 'dividedCohortHigh':
      case 'dividedAssessmentPoints': {
        // errorObject.maximumRevusError = maximumMethodCalculated
        //   ? { invalid: false }
        //   : validateDecimalStringField(maximumRevusInput);
        errorObject.maximumRevusError =
          validateDecimalStringField(maximumRevusInput);
        break;
      }
      case 'curved': {
        errorObject.curvedFailingGradeError =
          validateDecimalStringField(curvedFailingGrade);
        break;
      }
      case 'scaled': {
        errorObject.scaledHighScoreError =
          validateDecimalStringField(scaledHighScore);
        errorObject.lowScoreError = lowScoreCalculated
          ? { invalid: false }
          : validateDecimalStringField(lowScoreInput);
        errorObject.scaledLowScoreError =
          validateDecimalStringField(scaledLowScore);
        break;
      }

      default:
        break;
    }
    return errorObject;
  };

  const buildGradingTechniquePayload = (
    gradingTechnique,
    maximumRevus,
    maximumMethodCalculated,
    lowScore,
    lowScoreCalculated,
    curvedFailingGrade,
    scaledHighScore,
    scaledLowScore
  ) => {
    let payload = {};
    switch (gradingTechnique) {
      case 'points': {
        payload = {
          ...initialGradingTechniqueState,
          uuid: undefined,
          gradingTechnique
        };
        break;
      }
      case 'dividedCohortHigh':
      case 'dividedAssessmentPoints': {
        payload = {
          ...initialGradingTechniqueState,
          uuid: undefined,
          gradingTechnique,
          // maximumRevus: maximumMethodCalculated ? null : maximumRevus,
          maximumRevus,
          maximumMethodCalculated
        };
        break;
      }
      case 'curved': {
        payload = {
          ...initialGradingTechniqueState,
          uuid: undefined,
          curvedHighGrade: '100',
          curvedFailingGrade,
          gradingTechnique,
          maximumMethodCalculated: true,
          lowScoreCalculated: true
        };
        break;
      }
      case 'scaled': {
        payload = {
          ...initialGradingTechniqueState,
          uuid: undefined,
          gradingTechnique,
          maximumRevus: maximumMethodCalculated ? null : maximumRevus,
          maximumMethodCalculated,
          lowScore: lowScoreCalculated ? null : lowScore,
          lowScoreCalculated,
          scaledHighScore,
          scaledLowScore
        };
        break;
      }
      default:
        break;
    }
    return payload;
  };

  const setValidationErrors = ({
    title,
    scoreType,
    assessmentTypeUuid,
    assessmentAssociationUuid
  }) => {
    const nameError = validateStringLength(title, 100, true);
    const scoreTypeError = validateRequired(scoreType);
    const assessmentTypeError = validateRequired(assessmentTypeUuid);
    const assessmentAssociationError = validateRequired(
      assessmentAssociationUuid
    );

    const newValidationErrors = {
      nameError,
      scoreTypeError,
      assessmentTypeError,
      assessmentAssociationError
    };

    return newValidationErrors;
  };

  const {
    showValidationErrors,
    optionsAssessmentTypes,
    optionsAssessmentAssociations,
    ...newProgramAssessment
  } = state;

  const onSubmit = () => {
    const { title, scoreType, assessmentTypeUuid, assessmentAssociationUuid } =
      newProgramAssessment;

    const {
      gradingTechnique,
      maximumRevusInput,
      maximumMethodCalculated,
      lowScoreInput,
      lowScoreCalculated,
      curvedFailingGrade,
      scaledHighScore,
      scaledLowScore
    } = gradingTechniqueState;

    const newValidationErrors = setValidationErrors({
      title,
      scoreType,
      assessmentTypeUuid,
      assessmentAssociationUuid
    });

    const gradingTechniqueValidationErrors =
      setGradingTechniqueValidationErrors({
        curvedFailingGrade,
        gradingTechnique,
        maximumRevusInput,
        // maximumMethodCalculated,
        lowScoreInput,
        lowScoreCalculated,
        scaledHighScore,
        scaledLowScore
      });

    if (
      allFieldsAreValid(newValidationErrors) &&
      allFieldsAreValid(gradingTechniqueValidationErrors)
    ) {
      const gradingTechniquePayload = buildGradingTechniquePayload(
        gradingTechnique,
        maximumRevusInput,
        maximumMethodCalculated,
        lowScoreInput,
        lowScoreCalculated,
        curvedFailingGrade,
        scaledHighScore,
        scaledLowScore
      );

      const payload = {
        title,
        scoreType,
        assessmentTypeUuid,
        assessmentAssociationUuid,
        programAssessmentCollectionUuid,
        programUuid,
        gradingTechnique: gradingTechniquePayload
      };

      onPostCreate(payload);
      programAssessmentDispatch(setShowValidationErrors(false));
    } else {
      programAssessmentDispatch(setShowValidationErrors(true));
    }
  };

  useEffect(() => {
    if (assessmentTypes) {
      const assessmentTypeOptions = _.map(assessmentTypes, aType => ({
        value: aType.uuid,
        label: aType.name,
        order: aType.order
      })).sort((a, b) => a.order - b.order);

      programAssessmentDispatch({
        type: 'setAssessmentTypes',
        payload: {
          optionsAssessmentTypes: assessmentTypeOptions
        }
      });
    }

    if (assessmentAssociations) {
      const assessmentAssociationOptions = assessmentAssociations
        .map(association => {
          return association.association !== 'Milestone'
            ? {
                value: association.uuid,
                label: association.association
              }
            : null;
        })
        .filter(association => association);

      programAssessmentDispatch({
        type: 'setAssessmentAssociations',
        payload: {
          optionsAssessmentAssociations: assessmentAssociationOptions
        }
      });
    }
  }, [assessmentTypes, assessmentAssociations]);

  useEffect(() => {
    dispatch(doGetProgramAssessments(programUuid));
  }, [dispatch, programUuid]);

  useEffect(() => {
    const onClearCreated = () => dispatch(doClearCreated());

    if (successfullyUpdated === true) {
      onClearCreated();
      clearForm();
    }
  }, [dispatch, successfullyUpdated, clearForm]);

  return (
    <NewProgramAssessmentMaster
      programAssessmentDispatch={programAssessmentDispatch}
      programUuid={programUuid}
      optionsAssessmentTypes={optionsAssessmentTypes}
      optionsAssessmentAssociations={optionsAssessmentAssociations}
      programAssessmentCollectionUuid={programAssessmentCollectionUuid}
      newProgramAssessment={newProgramAssessment}
      program={program}
      parentCollection={parentCollection}
      gradingTechnique={gradingTechniqueState}
      gradingTechniqueDispatch={gradingTechniqueDispatch}
      showValidationErrors={showValidationErrors}
      isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
      unsavedChanges={unsavedChanges}
      handleProceed={handleProceed}
      handleModalClose={handleModalClose}
      setUnsavedChange={setUnsavedChange}
      removeUnsavedChange={removeUnsavedChange}
      onSubmit={onSubmit}
      onCancel={handleModalOpen}
    />
  );
}
