import React, { useReducer, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { doClearCreated } from '../../../redux/actions/courseCollectionActions';
import { useUnsavedChanges } from '../../Library/Modal/UnsavedChangesModal/useUnsavedChanges';
import SectionGradeSettings from './SectionGradeSettings';
import {
  sectionGradeSettingsReducer,
  initialSectionGradeSettingsState
} from './sectionGradeSettingsState';
import { sectionSelector } from '../../../redux/selectors/sectionsSelectors';
import {
  doGetSection,
  doPutSectionGradeDataSource
} from '../../../redux/actions/sectionActions';

export default function SectionGradeSettingsContainer({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { sectionUuid } = match.params;

  const [state, sectionGradeSettingsDispatch] = useReducer(
    sectionGradeSettingsReducer,
    initialSectionGradeSettingsState
  );

  const section = useSelector(
    state => sectionSelector(state, sectionUuid),
    _.isEqual
  );

  const successfullyUpdatedDataSource = useSelector(
    state => state.formSuccessState.successfullyUpdatedSectionDataSource
  );

  const course = _.get(section, 'course', null);
  const courseNumber = _.get(course, 'course_master.courseNumber', '');
  const courseTitle = _.get(course, 'course_master.title', '');
  const sectionNumber = _.get(section, 'sectionNumber', '')
    .toString()
    .padStart(2, 0);

  const clearForm = useCallback(() => {
    sectionGradeSettingsDispatch({
      type: 'clearForm'
    });
    history.push(`/syllabus-management/all`);
  }, [history]);

  const [
    isUnsavedChangesModalOpen,
    unsavedChanges,
    handleModalOpen,
    handleModalClose,
    setUnsavedChange,
    removeUnsavedChange,
    handleProceed
  ] = useUnsavedChanges(clearForm);

  useEffect(() => {
    dispatch(doGetSection(sectionUuid));
  }, [dispatch, sectionUuid]);

  useEffect(() => {
    if (successfullyUpdatedDataSource) {
      dispatch(doClearCreated());
      removeUnsavedChange('dataSource');
    }
  }, [dispatch, removeUnsavedChange, successfullyUpdatedDataSource]);

  const setExistingState = ({ uuid, dataSource }) => {
    sectionGradeSettingsDispatch({
      type: 'setExistingSettings',
      payload: {
        uuid,
        dataSource
      }
    });
  };

  const existingDataSource = section?.dataSource;
  const existingUuid = section?.uuid || null;

  useEffect(() => {
    if (existingUuid) {
      setExistingState({
        uuid: existingUuid,
        dataSource: existingDataSource
      });
    }
  }, [existingDataSource, existingUuid]);

  const { uuid, dataSource, showValidationErrors } = state;

  const handleSubmitDataSource = () => {
    const dataSourcePayload = {
      sectionUuid,
      dataSource
    };

    dispatch(doPutSectionGradeDataSource(dataSourcePayload));
  };

  const handleDataSourceChange = event => {
    sectionGradeSettingsDispatch({
      type: 'setDataSource',
      payload: {
        dataSource: event.target.value
      }
    });
    setUnsavedChange('dataSource');
  };

  const directors = section?.section_directors_assigned;
  const sectionTitle =
    directors?.length > 0
      ? `${sectionNumber}: ${directors[0].lastName}, ${directors[0].firstName}`
      : `${sectionNumber}`;

  return (
    <SectionGradeSettings
      savedDataSource={section?.dataSource}
      dataSource={dataSource}
      sectionUuid={uuid}
      courseNumber={courseNumber}
      courseTitle={courseTitle}
      sectionNumber={sectionNumber}
      sectionTitle={sectionTitle}
      handleDataSourceChange={handleDataSourceChange}
      handleSubmitDataSource={handleSubmitDataSource}
      showValidationErrors={showValidationErrors}
      isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
      unsavedChanges={unsavedChanges}
      setUnsavedChange={setUnsavedChange}
      removeUnsavedChange={removeUnsavedChange}
      handleModalOpen={handleModalOpen}
      handleModalClose={handleModalClose}
      handleProceed={handleProceed}
    />
  );
}

SectionGradeSettingsContainer.propTypes = {
  match: PropTypes.object
};

SectionGradeSettingsContainer.defaultProps = {
  match: {}
};
