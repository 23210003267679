import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2 } from '@xcomp/xcomp-design-library';
import { InfoIcon } from '@xcomp/xcomp-design-library/dist/icons';
import {
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import { Panel, PanelHeader } from '../../../../../Library/Layout';
import { Column, InfoText } from '../../../../../Library/FormComponents';
import { ButtonOutline } from '../../../../../Library/ButtonSC';
import GradingTechniqueForm from '../../../../../Library/GradingTechniqueForm/GradingTechniqueForm';
import FormSectionShowHide from '../../../../../Library/FormSectionShowHide';
import FormSelectError from '../../../../../Library/FormSelectError';
import { validateSelectField } from '../../../../../../helpers/validation/validateGeneric';

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const SubmitButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const PanelHeaderSC = styled(PanelHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ButtonOutlineSC = styled(ButtonOutline)``;

const LowGradesDropped = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0 48px;
`;

const CheckboxContainer = styled.div`
  color: ${props => props.theme.fontColors.dark};
  margin-bottom: 12px;
`;

const LowGradesDroppedSelect = styled(FormSelectError)`
  && {
    margin-top: 0;
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    max-width: 350px;
  }
`;

const FormSectionShowHideSC = styled(FormSectionShowHide)`
  && {
    width: 100%;
    margin-bottom: 0;
  }
`;

export default function EditGradeModifiers({
  droppedOptions,
  distributeWeightEqually,
  allowDropped,
  allowDroppedLabel,
  allowCohortHigh,
  formTitle,
  handleAllowDropped,
  calculatedHighScore,
  calculatedLowScore,
  curvedFailingGrade,
  handleChangeRadio,
  handleGradingTechniqueChange,
  handleLowGradesDroppedChange,
  handleSubmitGradingTechnique,
  low_grades_dropped,
  lowScoreCalculated,
  lowScoreInput,
  maximumMethodCalculated,
  maximumRevusInput,
  scaledHighScore,
  scaledLowScore,
  selectedRadio,
  showValidationErrors,
  unsavedGradeModifierChanges,
  unsavedParentErrorMessage,
  distributeWeightErrorMessage,
  unsavedParentChanges,
  labels
}) {
  const optionsLowGradesDropped = droppedOptions
    .map((colAssessment, index) => {
      const optionNumber = index + 1;
      const ofNumber = droppedOptions.length;
      return (
        index < droppedOptions.length - 1 && {
          label: `${optionNumber} of ${ofNumber}`,
          value: String(optionNumber)
        }
      );
    })
    .filter(option => option);

  optionsLowGradesDropped.push({
    label: 'None',
    value: ''
  });

  const lowGradesDropped = low_grades_dropped ? String(low_grades_dropped) : '';

  return (
    <Panel>
      <PanelHeaderSC paddingLeft="24px" paddingRight="24px">
        <Heading2>{formTitle}</Heading2>
      </PanelHeaderSC>
      <PanelPadding>
        <LowGradesDropped>
          <Column size="100%">
            <FormControl component="fieldset">
              <FormGroup>
                <CheckboxContainer>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="collectionAllowDroppedAssessments"
                        onChange={handleAllowDropped}
                        checked={allowDropped}
                        color="primary"
                      />
                    }
                    label={allowDroppedLabel}
                  />
                </CheckboxContainer>
              </FormGroup>
            </FormControl>
            {allowDropped && unsavedParentChanges && (
              <InfoText
                alignItems="center"
                justifyContent="flex-start"
                margin="0 0 12px 0"
                error
              >
                <InfoIcon />
                {unsavedParentErrorMessage}
              </InfoText>
            )}
            {allowDropped && !distributeWeightEqually && (
              <InfoText
                alignItems="center"
                justifyContent="flex-start"
                margin="0 0 12px 0"
                error
              >
                <InfoIcon />
                {distributeWeightErrorMessage}
              </InfoText>
            )}
          </Column>
          <Column size="100%">
            <LowGradesDroppedSelect
              id="lowGradesDropped"
              value={lowGradesDropped}
              options={optionsLowGradesDropped}
              handleChange={handleLowGradesDroppedChange}
              name="low_grades_dropped"
              label="Low Grade(s) Dropped"
              displayEmpty
              handleValidation={
                !allowDropped
                  ? () => ({
                      invalid: false
                    })
                  : validateSelectField
              }
              hasErrors={showValidationErrors}
              disabled={!allowDropped}
            />
          </Column>
        </LowGradesDropped>
        <FormSectionShowHideSC
          headerText="Final Grade Calculation"
          padding="0 30px"
          startOpen={true}
        >
          <GradingTechniqueForm
            labels={labels}
            allowCohortHigh={allowCohortHigh}
            curvedFailingGrade={curvedFailingGrade}
            handleChangeRadio={handleChangeRadio}
            handleGradingTechniqueChange={handleGradingTechniqueChange}
            lowScoreInput={lowScoreInput}
            lowScoreCalculated={lowScoreCalculated}
            calculatedLowScore={calculatedLowScore}
            maximumMethodCalculated={maximumMethodCalculated}
            maximumRevusInput={maximumRevusInput}
            calculatedHighScore={calculatedHighScore}
            scaledHighScore={scaledHighScore}
            scaledLowScore={scaledLowScore}
            selectedRadio={selectedRadio}
            showValidationErrors={showValidationErrors}
          />
        </FormSectionShowHideSC>
        <SubmitButtonRow>
          <ButtonOutlineSC
            onClick={handleSubmitGradingTechnique}
            disabled={!unsavedGradeModifierChanges}
          >
            Apply
          </ButtonOutlineSC>
        </SubmitButtonRow>
      </PanelPadding>
    </Panel>
  );
}

EditGradeModifiers.propTypes = {
  droppedOptions: PropTypes.arrayOf(PropTypes.object),
  distributeWeightEqually: PropTypes.bool,
  formTitle: PropTypes.string,
  allowDroppedLabel: PropTypes.string,
  allowDropped: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  allowCohortHigh: PropTypes.bool,
  handleAllowDropped: PropTypes.func,
  calculatedHighScore: PropTypes.string,
  calculatedLowScore: PropTypes.string,
  curvedFailingGrade: PropTypes.string,
  low_grades_dropped: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChangeRadio: PropTypes.func,
  handleLowGradesDroppedChange: PropTypes.func,
  handleGradingTechniqueChange: PropTypes.func,
  handleSubmitGradingTechnique: PropTypes.func,
  lowScoreCalculated: PropTypes.bool,
  lowScoreInput: PropTypes.string,
  maximumMethodCalculated: PropTypes.bool,
  maximumRevusInput: PropTypes.string,
  scaledHighScore: PropTypes.string,
  scaledLowScore: PropTypes.string,
  selectedRadio: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  unsavedParentErrorMessage: PropTypes.string,
  distributeWeightErrorMessage: PropTypes.string,
  unsavedParentChanges: PropTypes.bool.isRequired,
  unsavedGradeModifierChanges: PropTypes.bool.isRequired,
  labels: PropTypes.object
};

EditGradeModifiers.defaultProps = {
  droppedOptions: [],
  distributeWeightEqually: false,
  formTitle: 'Collection Grade Modification',
  allowDropped: null,
  allowDroppedLabel: 'Allow Dropped Collections',
  allowCohortHigh: false,
  handleAllowDropped: undefined,
  calculatedHighScore: '',
  calculatedLowScore: '',
  curvedFailingGrade: '',
  low_grades_dropped: null,
  handleChangeRadio: undefined,
  handleLowGradesDroppedChange: undefined,
  handleGradingTechniqueChange: '',
  handleSubmitGradingTechnique: undefined,
  lowScoreCalculated: true,
  lowScoreInput: '',
  maximumMethodCalculated: true,
  maximumRevusInput: '',
  scaledHighScore: '',
  scaledLowScore: '',
  selectedRadio: '',
  unsavedParentErrorMessage:
    'Collection assessments have changed, apply changes above before setting low grade(s) dropped.',
  distributeWeightErrorMessage:
    'To drop assessments, you must select option to distributed weight equally among assessments, then apply changes above.',
  showValidationErrors: false,
  labels: {
    dividedAssessmentPoints: 'Divided by Assessment Possible Points',
    dividedCohortHigh: 'Divided by Cohort High Points',
    curved: 'Curved',
    scaled: 'Scaled'
  }
};
