import {
  GET_PART_GROUPS,
  GET_PART_GROUPS_SUCCESS,
  GET_PART_GROUPS_ERROR,
  POST_GROUP,
  POST_GROUP_SUCCESS,
  POST_GROUP_ERROR,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_ERROR,
  PUT_GROUP,
  PUT_GROUP_SUCCESS,
  PUT_GROUP_ERROR,
  PUT_GROUP_RANKINGS,
  PUT_GROUP_RANKINGS_SUCCESS,
  PUT_GROUP_RANKINGS_ERROR,
  PUT_STUDENT_GROUP_RANKINGS_SUCCESS,
  PUT_STUDENT_GROUP_RANKINGS_ERROR,
  PUT_STUDENT_GROUP_RANKINGS
} from '../actions-type';

const doGetPartGroups = partUuid => ({
  type: GET_PART_GROUPS,
  payload: {
    partUuid
  }
});

const doGetPartGroupsSuccess = groups => ({
  type: GET_PART_GROUPS_SUCCESS,
  payload: groups
});

const doGetPartGroupsError = error => ({
  type: GET_PART_GROUPS_ERROR,
  payload: error
});

const doPostGroup = ({ partUuid, groupId }) => ({
  type: POST_GROUP,
  payload: {
    partUuid,
    groupId
  }
});

const doPostGroupSuccess = group => ({
  type: POST_GROUP_SUCCESS,
  payload: group
});

const doPostGroupError = error => ({
  type: POST_GROUP_ERROR,
  payload: error
});

const doDeleteGroup = groupUuid => ({
  type: DELETE_GROUP,
  payload: {
    groupUuid
  }
});

const doDeleteGroupSuccess = ({ groupUuid }) => ({
  type: DELETE_GROUP_SUCCESS,
  payload: { groupUuid }
});

const doDeleteGroupError = error => ({
  type: DELETE_GROUP_ERROR,
  payload: error
});

const doPutGroup = (groupUuid, groupId, groupLeaderUuid) => ({
  type: PUT_GROUP,
  payload: {
    groupUuid,
    groupId,
    groupLeaderUuid
  }
});

const doPutGroupSuccess = ({ group }) => ({
  type: PUT_GROUP_SUCCESS,
  payload: { group }
});

const doPutGroupError = error => ({
  type: PUT_GROUP_ERROR,
  payload: error
});

const doPutGroupRankings = partUuid => ({
  type: PUT_GROUP_RANKINGS,
  payload: {
    partUuid
  }
});

const doPutGroupRankingsSuccess = ({ groups, userGroups }) => ({
  type: PUT_GROUP_RANKINGS_SUCCESS,
  payload: { groups, userGroups }
});

const doPutGroupRankingsError = error => ({
  type: PUT_GROUP_RANKINGS_ERROR,
  payload: error
});

const doPutStudentGroupRankingsSuccess = ({ groups, userGroups }) => ({
  type: PUT_STUDENT_GROUP_RANKINGS_SUCCESS,
  payload: { groups, userGroups }
});

const doPutStudentGroupRankingsError = error => ({
  type: PUT_STUDENT_GROUP_RANKINGS_ERROR,
  payload: error
});

const doPutStudentGroupRankings = (partUuid, studentUuid) => ({
  type: PUT_STUDENT_GROUP_RANKINGS,
  payload: {
    partUuid,
    studentUuid
  }
});

export {
  doGetPartGroups,
  doGetPartGroupsSuccess,
  doGetPartGroupsError,
  doPostGroup,
  doPostGroupSuccess,
  doPostGroupError,
  doDeleteGroup,
  doDeleteGroupSuccess,
  doDeleteGroupError,
  doPutGroup,
  doPutGroupSuccess,
  doPutGroupError,
  doPutGroupRankings,
  doPutGroupRankingsSuccess,
  doPutGroupRankingsError,
  doPutStudentGroupRankings,
  doPutStudentGroupRankingsError,
  doPutStudentGroupRankingsSuccess
};
