import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import {
  ContainerPage,
  ContainerActions,
  ElevateLow
} from '../../../Library/Layout';
import AcademicYearShowContainer from './AcademicYearShow/AcademicYearShowContainer';

const Title = styled.div`
  font-size: 0.8rem;
  color: ${props => props.theme.fontColors.dark};
`;

const TitleArea = styled.div`
  width: 100%;
  padding: 1rem 3.5rem 1rem 1.5rem;
  margin-bottom: 20px;
`;

export default function AcademicYear({ academicYears }) {
  return (
    <div>
      <ContainerActions paddingTop="20px">
        <Link to="/school-management/academic-year-term/add">
          <ButtonPrimary>Add Year</ButtonPrimary>
        </Link>
      </ContainerActions>
      <ContainerPage paddingTop="60px">
        <ElevateLow>
          <TitleArea>
            <Grid container direction="row" alignItems="center">
              <Grid item sm={5} xs={12}>
                <Title>Academic Year</Title>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Title>Start Date</Title>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Title>End Date</Title>
              </Grid>
            </Grid>
          </TitleArea>
        </ElevateLow>
        {academicYears
          .sort((a, b) => moment(b.startDate) - moment(a.startDate))
          .map(academicYear => (
            <AcademicYearShowContainer
              key={academicYear.uuid}
              academicYear={academicYear}
            />
          ))}
      </ContainerPage>
    </div>
  );
}

AcademicYear.propTypes = {
  academicYears: PropTypes.array
};
AcademicYear.defaultProps = {
  academicYears: []
};
