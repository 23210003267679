import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AccordionRow from './AccordionRow';

const AccordionRowContainer = ({
  shouldStartOpen,
  onRowOpen,
  onRowClose,
  children,
  ...rest
}) => {
  const [isRowOpen, setIsRowOpen] = useState(shouldStartOpen);

  const toggleOpenRow = useCallback(() => {
    setIsRowOpen(prevState => {
      const nextState = !prevState;
      if (nextState && onRowOpen) {
        onRowOpen();
      } else if (!nextState && onRowClose) {
        onRowClose();
      }
      return nextState;
    });
  }, [onRowClose, onRowOpen]);

  useEffect(() => {
    if (shouldStartOpen && !isRowOpen) {
      toggleOpenRow();
    }
  }, [isRowOpen, onRowClose, onRowOpen, shouldStartOpen, toggleOpenRow]);

  return (
    <AccordionRow
      // actions={actions}
      // className={className}
      // label={label}
      // column={column}
      isRowOpen={isRowOpen}
      toggleOpenRow={toggleOpenRow}
      {...rest}
    >
      {children}
    </AccordionRow>
  );
};

AccordionRowContainer.propTypes = {
  shouldStartOpen: PropTypes.bool,
  onRowOpen: PropTypes.func,
  onRowClose: PropTypes.func,
  children: PropTypes.node
};
AccordionRowContainer.defaultProps = {
  shouldStartOpen: false,
  onRowOpen: () => {},
  onRowClose: () => {},
  children: null
};

// class AccordionRowContainer extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isRowOpen: false
//     };
//     this.toggleOpenRow = this.toggleOpenRow.bind(this);
//   }

//   componentDidUpdate = prevProps => {
//     if (
//       this.props.shouldStartOpen &&
//       prevProps.shouldStartOpen !== this.props.shouldStartOpen &&
//       !this.state.isRowOpen
//     ) {
//       this.toggleOpenRow();
//     }
//   };

//   componentDidMount = () => {
//     if (this.props.shouldStartOpen && !this.state.isRowOpen) {
//       this.toggleOpenRow();
//     }
//   };

//   toggleOpenRow = () => {
//     const { onRowOpen, onRowClose } = this.props;

//     this.setState(prevState => {
//       const nextState = !prevState.isRowOpen;
//       if (nextState && onRowOpen) {
//         onRowOpen();
//       } else if (!nextState && onRowClose) {
//         onRowClose();
//       }
//       return {
//         isRowOpen: !prevState.isRowOpen
//       };
//     });
//   };

//   render() {
//     const {
//       actions,
//       children,
//       className,
//       label,
//       columnOne,
//       columnTwo,
//       columnThree,
//       columnFour,
//       columnTwoClassName,
//       columnOneClassName,
//       columnThreeClassName,
//       columnFourClassName,
//       expandWidth,
//       height,
//       rowOffset,
//       columnGutters,
//       dragHandleProps,
//       leaf,
//       disabled,
//       isSelected,
//       isPublished,
//       isTopLevel,
//       isDragEnabled,
//       isDragging,
//       isDraggingOver,
//       onSelect,
//       onShow,
//       onPublish,
//       panelRef,
//       staticBg,
//       staticActionsButton,
//       withLabelTooltip,
//       childMarginBottom,
//       withBottomBorder
//     } = this.props;
//     const { isRowOpen } = this.state;
//     return (
//       <AccordionRow
//         actions={actions}
//         className={className}
//         columnOneClassName={columnOneClassName}
//         columnTwoClassName={columnTwoClassName}
//         columnThreeClassName={columnThreeClassName}
//         columnFourClassName={columnFourClassName}
//         isRowOpen={isRowOpen}
//         isSelected={isSelected}
//         isPublished={isPublished}
//         isTopLevel={isTopLevel}
//         isDragEnabled={isDragEnabled}
//         isDragging={isDragging}
//         isDraggingOver={isDraggingOver}
//         dragHandleProps={dragHandleProps}
//         label={label}
//         columnOne={columnOne}
//         columnTwo={columnTwo}
//         columnThree={columnThree}
//         columnFour={columnFour}
//         expandWidth={expandWidth}
//         height={height}
//         rowOffset={rowOffset}
//         columnGutters={columnGutters}
//         leaf={leaf}
//         disabled={disabled}
//         onPublish={onPublish}
//         onShow={onShow}
//         onSelect={onSelect}
//         toggleOpenRow={() => this.toggleOpenRow()}
//         panelRef={panelRef}
//         staticBg={staticBg}
//         staticActionsButton={staticActionsButton}
//         childMarginBottom={childMarginBottom}
//         withBottomBorder={withBottomBorder}
//         withLabelTooltip={withLabelTooltip}
//       >
//         {children}
//       </AccordionRow>
//     );
//   }
// }

// AccordionRowContainer.propTypes = {
//   actions: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.object),
//     PropTypes.bool
//   ]),
//   children: PropTypes.node,
//   className: PropTypes.string,
//   label: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.element,
//     PropTypes.object
//   ]),
//   columnOne: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.element,
//     PropTypes.object
//   ]),
//   columnTwo: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.element,
//     PropTypes.object
//   ]),
//   columnThree: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.element,
//     PropTypes.object
//   ]),
//   columnFour: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.element,
//     PropTypes.object
//   ]),
//   columnOneClassName: PropTypes.string,
//   columnTwoClassName: PropTypes.string,
//   columnThreeClassName: PropTypes.string,
//   columnFourClassName: PropTypes.string,
//   expandWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
//   columnGutters: PropTypes.string,
//   height: PropTypes.string,
//   rowOffset: PropTypes.string,
//   dragHandleProps: PropTypes.object,
//   leaf: PropTypes.bool,
//   disabled: PropTypes.bool,
//   shouldStartOpen: PropTypes.bool,
//   isDragging: PropTypes.bool,
//   isDragEnabled: PropTypes.bool,
//   isDraggingOver: PropTypes.bool,
//   isSelected: PropTypes.bool,
//   isPublished: PropTypes.bool,
//   isTopLevel: PropTypes.bool,
//   panelRef: PropTypes.shape({ current: PropTypes.any }),
//   staticBg: PropTypes.bool,
//   staticActionsButton: PropTypes.bool,
//   withLabelTooltip: PropTypes.bool,
//   onPublish: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
//   onRowOpen: PropTypes.func,
//   onRowClose: PropTypes.func,
//   onSelect: PropTypes.func,
//   onShow: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
//   childMarginBottom: PropTypes.string,
//   withBottomBorder: PropTypes.bool
// };

// AccordionRowContainer.defaultProps = {
//   actions: false,
//   children: <div />,
//   className: '',
//   label: '',
//   columnOne: '',
//   columnTwo: '',
//   columnThree: '',
//   columnFour: '',
//   columnOneClassName: '',
//   columnTwoClassName: '',
//   columnThreeClassName: '',
//   columnFourClassName: '',
//   columnGutters: '0.5rem',
//   height: '43px',
//   rowOffset: '0',
//   expandWidth: false,
//   dragHandleProps: {},
//   leaf: false,
//   disabled: false,
//   shouldStartOpen: false,
//   isDragEnabled: false,
//   isDragging: false,
//   isDraggingOver: false,
//   isPublished: false,
//   isSelected: false,
//   isTopLevel: false,
//   panelRef: undefined,
//   staticBg: false,
//   staticActionsButton: false,
//   withLabelTooltip: false,
//   onPublish: false,
//   onRowOpen: undefined,
//   onRowClose: undefined,
//   onSelect: undefined,
//   onShow: false,
//   childMarginBottom: undefined,
//   withBottomBorder: false
// };

export default AccordionRowContainer;
