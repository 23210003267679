import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { sortByProperty } from '../../helpers/utilities';
import { orm } from '../models';

export const competencyCollectionsSelector = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const competencyCollectionSelectorORM = ormCreateSelector(
      [orm],
      session => {
        const competencyCollections = session.CompetencyCollection.all()
          .filter(collection => {
            return collection.programUuid === programUuid;
          })
          .toModelArray()
          .map(collection => {
            const competencies = session.Competency.all()
              .filter(competency => {
                return competency.competencyCollectionUuid === collection.uuid;
              })
              .toModelArray()
              .sort((a, b) => sortByProperty(a, b, 'name'))
              .map(competency => {
                return {
                  ...competency.ref
                };
              });

            return {
              ...collection.ref,
              competencies
            };
          });

        return competencyCollections.sort((a, b) =>
          sortByProperty(a, b, 'name')
        );
      }
    );

    const competencyCollections = competencyCollectionSelectorORM(redux);
    return competencyCollections;
  }
);

export const competencyCollectionSelector = createSelector(
  state => state,
  (_, uuid) => uuid,
  (redux, uuid) => {
    const competencyCollectionSelectorORM = ormCreateSelector(
      [orm],
      session => {
        const competencyCollection = session.CompetencyCollection.withId(uuid);

        if (!competencyCollection) {
          return null;
        }

        let competencies;
        if (competencyCollection.competencies) {
          competencies = competencyCollection.competencies
            .all()
            .toModelArray()
            .map(competency => {
              return {
                ...competency.ref
              };
            });
        }

        const sortedCompetencies = competencies.sort((a, b) =>
          sortByProperty(a, b, 'name')
        );

        return {
          ...competencyCollection.ref,
          competencies: sortedCompetencies
        };
      }
    );

    const competencyCollections = competencyCollectionSelectorORM(redux);
    return competencyCollections;
  }
);

export const competenciesSelector = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const competencySelectorORM = ormCreateSelector([orm], session => {
      const competencyCollectionUuids = session.CompetencyCollection.all()
        .filter(collection => {
          return collection.programUuid === programUuid;
        })
        .toModelArray()
        .map(collection => collection.uuid);

      const competencies = session.Competency.all()
        .filter(competency =>
          competencyCollectionUuids.includes(
            competency.competencyCollectionUuid
          )
        )
        .toModelArray()
        .map(competency => {
          return {
            ...competency.ref
          };
        });

      return competencies.sort((a, b) => sortByProperty(a, b, 'name'));
    });

    const competencies = competencySelectorORM(redux);
    return competencies;
  }
);

export const selectCompetencyCollections = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const competencyCollectionORM = ormCreateSelector(orm.CompetencyCollection);
    const competencyCollection = competencyCollectionORM(redux).filter(
      collection => collection.programUuid === programUuid
    );

    const sorted = _.sortBy(competencyCollection, 'name');

    return sorted;
  }
);

export const selectCompetenciesByCollection = createSelector(
  state => state,
  (_, competencyCollectionUuid) => competencyCollectionUuid,
  (redux, competencyCollectionUuid) => {
    const competencyORM = ormCreateSelector(orm.Competency);
    const competencyCollection = competencyORM(redux).filter(
      competency =>
        competency.competencyCollectionUuid === competencyCollectionUuid
    );

    const sorted = _.sortBy(competencyCollection, 'name');

    return sorted;
  }
);
