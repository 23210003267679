import _ from 'lodash';
import { isURL, isUUID } from 'validator';

const validateInputString = textField => {
  if (!textField || textField.trim() === '' || textField === 0) {
    return {
      invalid: true,
      message: 'Field is required'
    };
  } else {
    return {
      invalid: false
    };
  }
};

const validateInputNumber = numberField => {
  if (!numberField || numberField === 0) {
    return {
      invalid: true,
      message: 'Field is required'
    };
  } else {
    return {
      invalid: false
    };
  }
};

const validateInputNumberAsString = input => {
  const regex = /^[1-9]\d*(\.\d+)?$/i;
  const regexMatch = input.match(regex);
  const failsRegex = !regexMatch || regexMatch.length < 1;

  if (failsRegex) {
    return {
      invalid: true,
      message: 'Field must be decimal format'
    };
  } else {
    return {
      invalid: false
    };
  }
};

const validateInputNumberEmpty = numberField => {
  if (numberField === '') {
    return {
      invalid: true,
      message: 'Field is required'
    };
  } else {
    return {
      invalid: false
    };
  }
};

const validateSelectDate = date => {
  if (!date) {
    return {
      invalid: true,
      message: 'Date is required'
    };
  } else {
    return {
      invalid: false
    };
  }
};

const validateSelectField = inputValue => {
  if (!inputValue || inputValue.trim() === '') {
    return {
      invalid: true,
      message: 'Select is required'
    };
  }

  return {
    invalid: false
  };
};

const isNumberPositiveInteger = (inputValue) => {
  const isValid = typeof inputValue === 'number' && inputValue !== Infinity && inputValue >= 0;

  return {
    invalid: !isValid,
    message: 'Only positive integers'
  };
}

const validateDecimalStringField = (inputValue, allowNegative = false) => {
  if (!inputValue || inputValue === '') {
    return {
      invalid: true,
      message: 'Field is required'
    };
  }
  const containsNegative =
    String(inputValue).match(/^-[0-9]*\.?[0-9]*$/g) !== null;
  const regex = allowNegative ? /^-?[0-9]*\.?[0-9]*$/g : /^[0-9]*\.?[0-9]*$/g;
  const regexMatch = String(inputValue).match(regex);
  const failsRegex = !regexMatch || regexMatch.length < 1;

  if (failsRegex) {
    return {
      invalid: true,
      message:
        containsNegative && allowNegative !== true
          ? 'Negative value not permitted'
          : 'Only decimal format accepted'
    };
  }

  return {
    invalid: false
  };
};

const allFieldsAreValid = validationErrors => {
  const isValid = !Object.keys(validationErrors).some(
    key => validationErrors[key].invalid
  );

  return isValid;
};

const validateRequired = textField => {
  const trimTextField =
    textField && typeof textField === 'string' ? textField.trim() : textField;
  if (!trimTextField || trimTextField === '' || trimTextField === 0) {
    return {
      invalid: true,
      isFormatError: false,
      message: 'Required'
    };
  }
  return {
    invalid: false
  };
};

const validateUniqueEntity = (value, allSiblings, propertyName) => {
  const requiredError = validateRequired(value);
  let locationNameIsUnique = true;
  if (requiredError.invalid) {
    return requiredError;
  }

  allSiblings.forEach(sibling => {
    if (sibling[propertyName].toLowerCase() === value.toLowerCase()) {
      locationNameIsUnique = false;
    }
  });

  if (!locationNameIsUnique) {
    return {
      invalid: true,
      isFormatError: false,
      message: `${value} has already been used`
    };
  }
  return {
    invalid: false
  };
};

const validateEntityName = (entityName, allSiblings) => {
  const requiredError = validateRequired(entityName);

  let entityNameIsUnique = true;
  if (requiredError.invalid) {
    return requiredError;
  }

  if (_.size(entityName) > 100) {
    return {
      invalid: true,
      isFormatError: true,
      message: 'Must be less than 100 characters'
    };
  }

  allSiblings.forEach(sibling => {
    if (sibling.name.toLowerCase() === entityName.toLowerCase()) {
      entityNameIsUnique = false;
    }
  });

  if (!entityNameIsUnique) {
    return {
      invalid: true,
      isFormatError: false,
      message: `${entityName} has already been used`
    };
  }
  return {
    invalid: false
  };
};

const validateStringProperty = (entityName, allSiblings, propertyName) => {
  const requiredError = validateRequired(entityName);
  let entityNameIsUnique = true;
  if (requiredError.invalid) {
    return requiredError;
  }

  if (_.size(entityName) > 100) {
    return {
      invalid: true,
      isFormatError: true,
      message: 'Must be less than 100 characters'
    };
  }

  allSiblings.forEach(sibling => {
    if (sibling[propertyName].toLowerCase() === entityName.toLowerCase()) {
      entityNameIsUnique = false;
    }
  });

  if (!entityNameIsUnique) {
    return {
      invalid: true,
      isFormatError: false,
      message: `${entityName} has already been used`
    };
  }
  return {
    invalid: false
  };
};

const validateStringLength = (string, length = 100, required = true) => {
  const requiredError = required ? validateRequired(string) : false;

  if (requiredError.invalid) {
    return requiredError;
  }

  if (!required && !string) {
    return {
      invalid: false
    };
  }

  if (_.size(string) > length) {
    return {
      invalid: true,
      isFormatError: true,
      message: `Must be less than ${length} characters`
    };
  }

  return {
    invalid: false
  };
};

const validateIsUuid = (string, required = true) => {
  const requiredError = required ? validateRequired(string) : false;

  if (requiredError.invalid) {
    return requiredError;
  }

  if (!required && !string) {
    return {
      invalid: false
    };
  }

  if (!isUUID(string)) {
    return {
      invalid: true,
      isFormatError: true,
      message: `Must be a valid UUID format copied from the part you want to clone`
    };
  }

  return {
    invalid: false
  };
};

const validateUrl = (url, required = true) => {
  const requiredError = required ? validateRequired(url) : false;

  if (requiredError.invalid) {
    return requiredError;
  }

  if (!required && !url) {
    return {
      invalid: false
    };
  }

  const invalidUrlError = !isURL(url, {
    protocols: ['http', 'https'],
    require_protocol: true
  });

  if (invalidUrlError) {
    return {
      invalid: true,
      isFormatError: true,
      message: `Invalid URL, make sure to prepend http:// or https://`
    };
  }

  return {
    invalid: false
  };
};

const validateSelectUserSingle = inputValue => {
  if (!inputValue || inputValue === '') {
    return {
      invalid: true,
      message: 'Please select a user'
    };
  }

  return {
    invalid: false
  };
};

const validateSelectMultiUserField = inputValue => {
  if (!inputValue || inputValue.length < 1) {
    return {
      invalid: true,
      message: 'Select required'
    };
  }

  return {
    invalid: false
  };
};

const validateRequiredInlist = (list, fields) => {
  for (let i = 0; i < list.length; i += 1) {
    for (let j = 0; j < fields.length; j += 1) {
      const attribute = fields[j];

      const value = list[i][attribute];

      if (!value || value === '') {
        return { invalid: true, message: 'Required' };
      }
    }
  }

  return {
    invalid: false
  };
};

export {
  validateInputString,
  validateInputNumber,
  validateInputNumberAsString,
  validateInputNumberEmpty,
  validateSelectDate,
  validateSelectField,
  validateDecimalStringField,
  allFieldsAreValid,
  validateRequired,
  validateUniqueEntity,
  validateEntityName,
  validateStringProperty,
  validateStringLength,
  validateUrl,
  validateSelectMultiUserField,
  validateSelectUserSingle,
  validateRequiredInlist,
  validateIsUuid,
  isNumberPositiveInteger
};
