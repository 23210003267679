import {
  DELETE_APP_ID,
  DELETE_APP_ID_ERROR,
  DELETE_APP_ID_SUCCESS,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  GET_USER,
  GET_USERS_BY_SEARCH,
  GET_USERS_BY_SEARCH_ERROR,
  GET_USERS_BY_SEARCH_INSTITUTION_ID,
  GET_USERS_BY_SEARCH_INSTITUTION_ID_ERROR,
  GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS,
  GET_USERS_BY_SEARCH_ROLES,
  GET_USERS_BY_SEARCH_ROLES_ERROR,
  GET_USERS_BY_SEARCH_ROLES_SUCCESS,
  GET_USERS_BY_SEARCH_SUCCESS,
  GET_USER_ACTIVE,
  GET_USER_ACTIVE_ERROR,
  GET_USER_ACTIVE_REJECT,
  GET_USER_ACTIVE_SUCCESS,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  POST_CHECK_USERS,
  POST_CHECK_USERS_ERROR,
  POST_CHECK_USERS_SUCCESS,
  POST_UPLOAD_USERS,
  POST_UPLOAD_USERS_ERROR,
  POST_UPLOAD_USERS_SUCCESS,
  POST_USER,
  POST_USER_APP_ID,
  POST_USER_APP_ID_ERROR,
  POST_USER_APP_ID_SUCCESS,
  POST_USER_ERROR,
  POST_USER_ROLE,
  POST_USER_ROLE_ERROR,
  POST_USER_ROLE_SUCCESS,
  POST_USER_SUCCESS,
  PUT_USER,
  PUT_USER_ERROR,
  PUT_USER_PROFILE,
  PUT_USER_PROFILE_ERROR,
  PUT_USER_PROFILE_SUCCESS,
  PUT_USER_ROLES,
  PUT_USER_ROLES_ERROR,
  PUT_USER_ROLES_SUCCESS,
  PUT_USER_SUCCESS,
  SET_CURRENT_ROLE,
  SET_SELECTED_DEPARTMENT_ROLE_FEATURE,
  SET_SELECTED_HOME,
  SET_SELECTED_INSTITUTION_ROLE_FEATURE,
  SET_SELECTED_PROGRAM_ROLE_FEATURE,
  SET_SELECTED_SCHOOL_ROLE_FEATURE
} from '../actions-type';

const doGetUserActive = () => ({
  type: GET_USER_ACTIVE
});

const doGetUserActiveSuccess = payload => ({
  type: GET_USER_ACTIVE_SUCCESS,
  payload
});

const doGetUserActiveReject = payload => ({
  type: GET_USER_ACTIVE_REJECT,
  payload
});

const doGetUserActiveError = error => ({
  type: GET_USER_ACTIVE_ERROR,
  payload: error
});

const doGetUser = userUuid => ({
  type: GET_USER,
  payload: {
    userUuid
  }
});

const doGetUserSuccess = result => ({
  type: GET_USER_SUCCESS,
  payload: result
});

const doGetUserError = error => ({
  type: GET_USER_ERROR,
  payload: error
});

const doPostUser = user => ({
  type: POST_USER,
  payload: {
    user
  }
});

const doPostUserSuccess = payload => ({
  type: POST_USER_SUCCESS,
  payload
});

const doPostUserError = error => ({
  type: POST_USER_ERROR,
  payload: error
});

const doPutUser = (user, institutionUuid) => ({
  type: PUT_USER,
  payload: { user, institutionUuid }
});

const doPutUserSuccess = payload => ({
  type: PUT_USER_SUCCESS,
  payload
});

const doPutUserError = error => ({
  type: PUT_USER_ERROR,
  payload: error
});

const doPutUserProfile = payload => ({
  type: PUT_USER_PROFILE,
  payload
});

const doPutUserProfileSuccess = result => ({
  type: PUT_USER_PROFILE_SUCCESS,
  payload: result
});

const doPutUserProfileError = error => ({
  type: PUT_USER_PROFILE_ERROR,
  payload: error
});

const doPutRoles = (userUuid, roles) => ({
  type: PUT_USER_ROLES,
  payload: { userUuid, roles }
});

const doPutRolesSuccess = result => ({
  type: PUT_USER_ROLES_SUCCESS,
  payload: result
});

const doPutRolesError = error => ({
  type: PUT_USER_ROLES_ERROR,
  payload: error
});

const doDeleteUser = (userUuid, institutionUuid) => ({
  type: DELETE_USER,
  payload: {
    userUuid,
    institutionUuid
  }
});

const doDeleteUserSuccess = result => ({
  type: DELETE_USER_SUCCESS,
  payload: result
});

const doDeleteUserError = error => ({
  type: DELETE_USER_ERROR,
  payload: error
});

const doSetUserInstitutionRoleFeature = (
  role,
  institution,
  feature,
  featureId
) => ({
  type: SET_SELECTED_INSTITUTION_ROLE_FEATURE,
  payload: {
    role,
    institution,
    feature,
    featureId
  }
});

const doSetCurrentRole = role => ({
  type: SET_CURRENT_ROLE,
  payload: {
    role
  }
});

const doSetUserSchoolRoleFeature = (role, school, feature, featureId) => ({
  type: SET_SELECTED_SCHOOL_ROLE_FEATURE,
  payload: {
    role,
    school,
    feature,
    featureId
  }
});

const doSetUserDepartmentRoleFeature = (
  role,
  school,
  department,
  feature,
  featureId
) => ({
  type: SET_SELECTED_DEPARTMENT_ROLE_FEATURE,
  payload: {
    role,
    school,
    department,
    feature,
    featureId
  }
});

const doSetUserProgramRoleFeature = (
  role,
  school,
  program,
  feature,
  featureId
) => ({
  type: SET_SELECTED_PROGRAM_ROLE_FEATURE,
  payload: {
    role,
    school,
    program,
    feature,
    featureId
  }
});

const doSetSelectedHomeFeature = () => ({
  type: SET_SELECTED_HOME
});

const doPostUploadUsers = (uploadDate, users, institutionUuid) => ({
  type: POST_UPLOAD_USERS,
  payload: { uploadDate, users, institutionUuid }
});

const doPostUploadUsersSuccess = result => ({
  type: POST_UPLOAD_USERS_SUCCESS,
  payload: result
});

const doPostUploadUsersError = error => ({
  type: POST_UPLOAD_USERS_ERROR,
  payload: error
});

const doGetUsersBySearch = (field, input) => ({
  type: GET_USERS_BY_SEARCH,
  payload: { field, input }
});

const doGetUsersBySearchSuccess = result => ({
  type: GET_USERS_BY_SEARCH_SUCCESS,
  payload: result
});

const doGetUsersBySearchError = error => ({
  type: GET_USERS_BY_SEARCH_ERROR,
  payload: error
});

const doGetUsersBySearchInstitutionId = institutionId => ({
  type: GET_USERS_BY_SEARCH_INSTITUTION_ID,
  payload: { institutionId }
});

const doGetUsersBySearchInstitutionIdSuccess = result => ({
  type: GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS,
  payload: result
});

const doGetUsersBySearchInstitutionIdError = error => ({
  type: GET_USERS_BY_SEARCH_INSTITUTION_ID_ERROR,
  payload: error
});

const doGetUsersBySearchRoles = (field, input) => ({
  type: GET_USERS_BY_SEARCH_ROLES,
  payload: { field, input }
});

const doGetUsersBySearchRolesSuccess = result => ({
  type: GET_USERS_BY_SEARCH_ROLES_SUCCESS,
  payload: result
});

const doGetUsersBySearchRolesError = error => ({
  type: GET_USERS_BY_SEARCH_ROLES_ERROR,
  payload: error
});

const doPostCheckUsers = records => ({
  type: POST_CHECK_USERS,
  payload: records
});

const doPostCheckUsersSuccess = result => ({
  type: POST_CHECK_USERS_SUCCESS,
  payload: result
});

const doPostCheckUsersError = error => ({
  type: POST_CHECK_USERS_ERROR,
  payload: error
});

const doPostUserRole = (roleType, role) => ({
  type: POST_USER_ROLE,
  payload: { roleType, role }
});

const doPostUserRoleSuccess = result => ({
  type: POST_USER_ROLE_SUCCESS,
  payload: result
});

const doPostUserRoleError = error => ({
  type: POST_USER_ROLE_ERROR,
  payload: error
});

const doPostGenerateAppId = (roleField, userUuid, email) => ({
  type: POST_USER_APP_ID,
  payload: { roleField, userUuid, email }
});

const doPostGenerateAppIdSuccess = result => ({
  type: POST_USER_APP_ID_SUCCESS,
  payload: result
});

const doPostGenerateAppIdError = error => ({
  type: POST_USER_APP_ID_ERROR,
  payload: error
});

const doDeleteAppId = (roleField, userUuid, appId) => ({
  type: DELETE_APP_ID,
  payload: { roleField, userUuid, appId }
});

const doDeleteAppIdSuccess = result => ({
  type: DELETE_APP_ID_SUCCESS,
  payload: result
});

const doDeleteAppIdError = error => ({
  type: DELETE_APP_ID_ERROR,
  payload: error
});

export {
  doDeleteUser,
  doDeleteUserError,
  doDeleteUserSuccess,
  doGetUser,
  doGetUserActive,
  doGetUserActiveError,
  doGetUserActiveReject,
  doGetUserActiveSuccess,
  doGetUserError,
  doGetUsersBySearch,
  doGetUsersBySearchError,
  doGetUsersBySearchInstitutionId,
  doGetUsersBySearchInstitutionIdError,
  doGetUsersBySearchInstitutionIdSuccess,
  doGetUsersBySearchRoles,
  doGetUsersBySearchRolesError,
  doGetUsersBySearchRolesSuccess,
  doGetUsersBySearchSuccess,
  doGetUserSuccess,
  doPostCheckUsers,
  doPostCheckUsersError,
  doPostCheckUsersSuccess,
  doPostUploadUsers,
  doPostUploadUsersError,
  doPostUploadUsersSuccess,
  doPostUser,
  doPostUserError,
  doPostUserRole,
  doPostUserRoleError,
  doPostUserRoleSuccess,
  doPostUserSuccess,
  doPutRoles,
  doPutRolesError,
  doPutRolesSuccess,
  doPutUser,
  doPutUserError,
  doPutUserProfile,
  doPutUserProfileError,
  doPutUserProfileSuccess,
  doPutUserSuccess,
  doSetCurrentRole,
  doSetSelectedHomeFeature,
  doSetUserDepartmentRoleFeature,
  doSetUserInstitutionRoleFeature,
  doSetUserProgramRoleFeature,
  doSetUserSchoolRoleFeature,
  doPostGenerateAppId,
  doPostGenerateAppIdSuccess,
  doPostGenerateAppIdError,
  doDeleteAppId,
  doDeleteAppIdSuccess,
  doDeleteAppIdError
};
