import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getProgram,
  getPrograms,
  getProgramAssessment,
  getProgramAssessments,
  getProgramsBySchool,
  postProgram,
  putProgram,
  deleteProgram,
  postProgramAssessmentCollection,
  putProgramAssessmentCollection,
  postProgramAssessmentMaster,
  putProgramAssessmentMaster,
  deleteProgramAssessmentCollection,
  deleteProgramAssessmentMaster
} from '../../api/programApi';

import {
  doGetProgramSuccess,
  doGetProgramError,
  doGetProgramAssessmentsSuccess,
  doGetProgramAssessmentsError,
  doGetProgramsSuccess,
  doGetProgramsError,
  doGetSchoolProgramsSuccess,
  doGetSchoolProgramsError,
  doPostProgramSuccess,
  doPostProgramError,
  doPutProgramSuccess,
  doPutProgramError,
  doDeleteProgramSuccess,
  doDeleteProgramError,
  doPostProgramAssessmentCollectionSuccess,
  doPostProgramAssessmentCollectionError,
  doPutProgramAssessmentCollectionSuccess,
  doPutProgramAssessmentCollectionError,
  doPostProgramAssessmentMasterSuccess,
  doPostProgramAssessmentMasterError,
  doPutProgramAssessmentMasterSuccess,
  doPutProgramAssessmentMasterError,
  doDeleteProgramAsssessmentCollectionSuccess,
  doDeleteProgramAsssessmentCollectionError,
  doDeleteProgramAsssessmentMasterSuccess,
  doDeleteProgramAsssessmentMasterError,
  doGetProgramAssessmentSuccess,
  doGetProgramAssessmentError
} from '../actions/programActions';

import {
  GET_PROGRAM,
  GET_PROGRAMS,
  GET_PROGRAM_ASSESSMENTS,
  GET_SCHOOL_PROGRAMS,
  POST_PROGRAM,
  PUT_PROGRAM,
  DELETE_PROGRAM,
  POST_PROGRAM_ASSESSMENT_COLLECTION,
  PUT_PROGRAM_ASSESSMENT_COLLECTION,
  POST_PROGRAM_ASSESSMENT_MASTER,
  PUT_PROGRAM_ASSESSMENT_MASTER,
  DELETE_PROGRAM_ASSESSMENT_COLLECTION,
  DELETE_PROGRAM_ASSESSMENT_MASTER,
  GET_PROGRAM_ASSESSMENT
} from '../actions-type';

function* handleGetProgram(action) {
  const { programUuid } = action.payload;

  try {
    const result = yield call(getProgram, programUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetProgramSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetProgramError(error));
  }
}

function* handleGetProgramAssessments(action) {
  const { programUuid } = action.payload;

  try {
    const result = yield call(getProgramAssessments, programUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetProgramAssessmentsSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetProgramAssessmentsError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doGetProgramAssessmentsError());
  }
}

function* handleGetProgramAssessment(action) {
  const { programUuid, uuid } = action.payload;

  try {
    const result = yield call(getProgramAssessment, programUuid, uuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetProgramAssessmentSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetProgramAssessmentError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doGetProgramAssessmentError());
  }
}

function* handleGetPrograms() {
  try {
    const result = yield call(getPrograms);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetProgramsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetProgramsError(error));
  }
}

function* handleGetSchoolPrograms(action) {
  const { schoolUuid } = action.payload;

  try {
    const result = yield call(getProgramsBySchool, schoolUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetSchoolProgramsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSchoolProgramsError(error));
  }
}

function* handlePostProgram(action) {
  try {
    const result = yield call(postProgram, action.payload.program);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostProgramSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostProgramError(error));
  }
}

function* handlePostProgramAssessmentCollection(action) {
  try {
    const result = yield call(postProgramAssessmentCollection, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostProgramAssessmentCollectionSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPostProgramAssessmentCollectionError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPostProgramAssessmentCollectionError());
  }
}

function* handlePostProgramAssessmentMaster(action) {
  try {
    const result = yield call(postProgramAssessmentMaster, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostProgramAssessmentMasterSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPostProgramAssessmentMasterError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPostProgramAssessmentMasterError());
  }
}

function* handlePutProgram(action) {
  try {
    const result = yield call(putProgram, action.payload.program);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutProgramSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutProgramError(error));
  }
}

function* handlePutProgramAssessmentCollection(action) {
  try {
    const result = yield call(putProgramAssessmentCollection, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutProgramAssessmentCollectionSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPutProgramAssessmentCollectionError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPutProgramAssessmentCollectionError());
  }
}

function* handlePutProgramAssessmentMaster(action) {
  try {
    const result = yield call(putProgramAssessmentMaster, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutProgramAssessmentMasterSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPutProgramAssessmentMasterError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPutProgramAssessmentMasterError());
  }
}

function* handleDeleteProgram(action) {
  const { programUuid } = action.payload;

  try {
    const result = yield call(deleteProgram, programUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteProgramSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteProgramError(error));
  }
}

function* handleDeleteProgramAssessmentCollection(action) {
  try {
    const result = yield call(
      deleteProgramAssessmentCollection,
      action.payload
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteProgramAsssessmentCollectionSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doDeleteProgramAsssessmentCollectionError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doDeleteProgramAsssessmentCollectionError());
  }
}

function* handleDeleteProgramAssessmentMaster(action) {
  try {
    const result = yield call(deleteProgramAssessmentMaster, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteProgramAsssessmentMasterSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doDeleteProgramAsssessmentMasterError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doDeleteProgramAsssessmentMasterError());
  }
}

const program = [
  takeEvery(GET_PROGRAM, handleGetProgram),
  takeEvery(GET_PROGRAMS, handleGetPrograms),
  takeEvery(GET_PROGRAM_ASSESSMENTS, handleGetProgramAssessments),
  takeEvery(GET_PROGRAM_ASSESSMENT, handleGetProgramAssessment),
  takeEvery(GET_SCHOOL_PROGRAMS, handleGetSchoolPrograms),
  takeEvery(POST_PROGRAM, handlePostProgram),
  takeEvery(PUT_PROGRAM, handlePutProgram),
  takeEvery(
    POST_PROGRAM_ASSESSMENT_COLLECTION,
    handlePostProgramAssessmentCollection
  ),
  takeEvery(
    PUT_PROGRAM_ASSESSMENT_COLLECTION,
    handlePutProgramAssessmentCollection
  ),
  takeEvery(POST_PROGRAM_ASSESSMENT_MASTER, handlePostProgramAssessmentMaster),
  takeEvery(PUT_PROGRAM_ASSESSMENT_MASTER, handlePutProgramAssessmentMaster),
  takeEvery(DELETE_PROGRAM, handleDeleteProgram),
  takeEvery(
    DELETE_PROGRAM_ASSESSMENT_COLLECTION,
    handleDeleteProgramAssessmentCollection
  ),
  takeEvery(
    DELETE_PROGRAM_ASSESSMENT_MASTER,
    handleDeleteProgramAssessmentMaster
  )
];

export { program };
