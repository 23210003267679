import {
  AdditionalReadingIcon,
  AssessmentIcon, CategoryIcon,
  CohortIcon,
  CompetencyIcon, DocumentIcon,
  EditIcon,
  ReportIcon, SchoolIcon,
  UsersIcon,
} from '@xcomp/xcomp-design-library/dist/icons'
// import BarChart from '@material-ui/icons/BarChart'
// import CategoryOutlined from '@material-ui/icons/CategoryOutlined'
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import React from 'react'

export const roleFeatures = {
  program_admin: [
    {
      featureId: 'cohort_management',
      featureName: 'Cohort Management',
      url: '/program-management/cohort',
      icon: <CohortIcon />,
    },
    {
      featureId: 'cohort_competency',
      featureName: 'Competency Management',
      url: '/program-management/competency',
      icon: <CompetencyIcon />,
    },
    {
      featureId: 'cohort_definitions',
      featureName: 'Definitions',
      url: '/program-management/definitions/application-components',
      icon: <EditIcon />,
    },
    {
      featureId: 'quantitative_competency',
      featureName: 'Quantitative Skill Management',
      url: '/program-management/quantitative-skills',
      icon: <SettingsOutlined />,
    },
    // {
    //   featureId: 'cohort',
    //   featureName: 'Cohort',
    //   icon: <CohortIcon />,
    //   subFeatures: [
    //     {
    //       featureId: 'cohort_management',
    //       featureName: 'Cohort Management',
    //       url: '/program-management/cohort',
    //       icon: <CohortIcon />,
    //     },
    //     {
    //       featureId: 'cohort_competency',
    //       featureName: 'Competency Management',
    //       url: '/program-management/competency',
    //       icon: <CompetencyIcon />,
    //     },
    //     {
    //       featureId: 'cohort_definitions',
    //       featureName: 'Definitions',
    //       url: '/program-management/definitions/application-components',
    //       icon: <EditIcon />,
    //     },
    //   ],
    // },
    // {
    //   featureId: 'quantitative',
    //   featureName: 'Quantitative',
    //   icon: <BarChart />,
    //   subFeatures: [
    //     {
    //       featureId: 'quantitative_management',
    //       featureName: 'Quantitative Skills',
    //       url: '/program-management/quantitative-management',
    //       icon: <CategoryOutlined />,
    //     },
    //     {
    //       featureId: 'quantitative_competency',
    //       featureName: 'Quantitative Management',
    //       url: '/program-management/quantitative-competency',
    //       icon: <SettingsOutlined />,
    //     },
    //     {
    //       featureId: 'quantitative_definitions',
    //       featureName: 'Definitions',
    //       url: '/program-management/quantitative-definitions',
    //       icon: <EditIcon />,
    //     },
    //   ],
    // },
    {
      featureId: 'assessments',
      featureName: 'Assessments',
      url: '/program-management/program-assessments',
      icon: <AssessmentIcon />,
    },
    {
      featureId: 'user_management',
      featureName: 'User Management',
      url: '/user-management/dashboard/tab/view',
      icon: <UsersIcon />,
    },
    {
      featureId: 'reports',
      featureName: 'Reports',
      url: '/reports',
      icon: <ReportIcon />,
    },
  ],
  program_faculty: [
    {
      featureId: 'reports',
      featureName: 'Reports',
      url: '/reports',
      icon: <ReportIcon />,
    },
    {
      featureId: 'cohort_management',
      featureName: 'Cohort Management',
      url: '/program-management/cohort',
      icon: <CohortIcon />,
    },
  ],
  program_staff: [
    {
      featureId: 'not_available',
      featureName: 'Not Available',
      url: '/',
      icon: <SchoolIcon />,
    },
  ],
  department_faculty: [
    {
      featureId: 'syllabus_management',
      featureName: 'Syllabus Management',
      url: '/syllabus-management/all',
      icon: <AssessmentIcon />,
    },
  ],
  department_staff: [
    {
      featureId: 'syllabus_management',
      featureName: 'Syllabus Management',
      url: '/syllabus-management/all',
      icon: <AssessmentIcon />,
    },
  ],
  school_admin: [
    {
      featureId: 'course_management',
      featureName: 'Course Management',
      url: '/course-management/all',
      icon: <DocumentIcon />,
    },
    {
      featureId: 'syllabus_management',
      featureName: 'Syllabus Management',
      url: '/syllabus-management/all',
      icon: <AssessmentIcon />,
    },
    {
      featureId: 'program_management',
      featureName: 'Program Management',
      url: '/school-management/programs',
      icon: <AdditionalReadingIcon />,
    },
    {
      featureId: 'department_management',
      featureName: 'Department Management',
      url: '/school-management/departments',
      icon: <CategoryIcon />,
    },
    {
      featureId: 'definitions',
      featureName: 'Definitions',
      url: '/school-management/definitions/revus',
      icon: <EditIcon />,
    },
    {
      featureId: 'user_management',
      featureName: 'User Management',
      url: '/user-management/dashboard/tab/view',
      icon: <UsersIcon />,
    },
    {
      testId: 'institution-logs',
      featureId: 'institution_logs',
      featureName: 'Logs',
      url: '/institution-logs',
      icon: <FindInPageIcon />,
    },
  ],
  institution_admin: [
    {
      testId: 'institution-management',
      featureId: 'institution_management',
      featureName: 'Institution Management',
      url: '/institution-management/edit',
      icon: <CategoryIcon />,
    },
    {
      testId: 'school-management',
      featureId: 'school_management',
      featureName: 'School Management',
      url: '/institution-management/schools',
      icon: <SchoolIcon />,
    },
    {
      testId: 'user-management',
      featureId: 'user_management',
      featureName: 'User Management',
      url: '/user-management/dashboard/tab/check',
      icon: <UsersIcon />,
    },
  ],
}
