import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PartContainer from './PartContainer';

const Layout = styled.div`
  width: 100%;
`;

const PartList = ({
  parts,
  termDatesDisplay,
  sectionNumber,
  courseUuid,
  sectionUuid
}) => (
  <Layout>
    {parts
      .sort((a, b) => a.partNumber - b.partNumber)
      .map(part => {
        // total number of encounters
        const totalNumberofEncounters =
          part.encounters && part.encounters.length > 0
            ? part.encounters.length
            : 0;
        // total number of assessments
        const totalNumberofAssessments =
          part.assessments && part.assessments.length > 0
            ? part.assessments.length
            : 0;

        let totalNumberOfApprovedEncounters = 0;
        let totalNumberOfApprovedAssessments = 0;
        let totalNumberOfPendingEncounters = 0;
        let totalNumberOfPendingAssessments = 0;

        // if we have encounters
        if (totalNumberofEncounters > 0) {
          part.encounters.forEach(encounter => {
            if (encounter.status === 'Approved') {
              totalNumberOfApprovedEncounters += 1;
            }
            if (encounter.status === 'Complete') {
              totalNumberOfPendingEncounters += 1;
            }
          });
        }
        // if we have assessments
        if (totalNumberofAssessments > 0) {
          part.assessments.forEach(assessment => {
            if (assessment.status === 'Approved') {
              totalNumberOfApprovedAssessments += 1;
            }
            if (assessment.status === 'Complete') {
              totalNumberOfPendingAssessments += 1;
            }
          });
        }

        return (
          <PartContainer
            key={part.uuid}
            partUuid={part.uuid}
            partIndex={part.partNumber}
            termDatesDisplay={termDatesDisplay}
            sectionNumber={sectionNumber}
            sectionUuid={sectionUuid}
            courseUuid={courseUuid}
            totalNumberOfApprovedEncounters={totalNumberOfApprovedEncounters}
            totalNumberOfApprovedAssessments={totalNumberOfApprovedAssessments}
            totalNumberOfPendingEncounters={totalNumberOfPendingEncounters}
            totalNumberOfPendingAssessments={totalNumberOfPendingAssessments}
            totalNumberofEncounters={totalNumberofEncounters}
            totalNumberofAssessments={totalNumberofAssessments}
          />
        );
      })}
  </Layout>
);

PartList.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.object),
  courseUuid: PropTypes.string,
  termDatesDisplay: PropTypes.string,
  sectionUuid: PropTypes.string,
  sectionNumber: PropTypes.number
};

PartList.defaultProps = {
  parts: [],
  courseUuid: '',
  termDatesDisplay: '',
  sectionUuid: '',
  sectionNumber: 0
};

export default PartList;
