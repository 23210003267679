/* eslint no-param-reassign:off, no-bitwise:off */

import _ from 'lodash';
import Decimal from 'decimal.js-light';
import moment from 'moment';
import {
  formatFirstNameMiddleName,
  formatLastName
} from './format/user.format';
import {
  applicantDemographicReportSummaryEthnicityMapping,
  applicantDemographicReportSummaryGenderMapping,
  applicantDemographicReportSummaryMapping
} from './constants';
import fetchWithAuthorization from './fetch';

export const reorderArray = (array, deletedNumber, sortVariable) => {
  const cloneArray = _.cloneDeep(array);
  if (deletedNumber === cloneArray.length + 1) {
    return cloneArray;
  }

  return cloneArray
    .sort((a, b) => a[sortVariable] - b[sortVariable])
    .map((arrayElement, index) => {
      const newArrayElement = { ...arrayElement };
      const correctArrayNumber = index + 1;
      if (newArrayElement[sortVariable] !== correctArrayNumber) {
        newArrayElement[sortVariable] = correctArrayNumber;
      }
      return newArrayElement;
    });
};

export const convertNullToString = value => (!value ? '' : value);
export const convertNullToArray = value => (!value ? [] : value);
export const convertNullToZero = value => (!value ? 0 : value);

export const decimalSort = (a, b, reversed = false) => {
  const decimalA = typeof a === 'string' ? new Decimal(a) : a;
  const decimalB = typeof a === 'string' ? new Decimal(b) : b;
  if (decimalA.lessThan(decimalB)) return reversed ? 1 : -1;
  if (decimalA.greaterThan(decimalB)) return reversed ? -1 : 1;
  return 0;
};

export const sortByProperty = (a, b, propertyName) => {
  const propertyA =
    typeof _.get(a, propertyName) === 'string'
      ? _.get(a, propertyName).toUpperCase()
      : _.get(a, propertyName); // ignore upper and lowercase
  const propertyB =
    typeof _.get(b, propertyName) === 'string'
      ? _.get(b, propertyName).toUpperCase()
      : _.get(b, propertyName); // ignore upper and lowercase
  if (propertyA < propertyB) {
    return -1;
  }
  if (propertyA > propertyB) {
    return 1;
  }

  // properties must be equal
  return 0;
};

// const reA = /[^a-zA-Z]/g;
// const reN = /[^0-9]/g;

// export const sortByPropertyAlphaNumeric = (a, b, property) => {
//   const aA = a[property].replace(reA, '');
//   const bA = b[property].replace(reA, '');
//   if (aA === bA) {
//     const aN = parseInt(a.replace(reN, ''), 10);
//     const bN = parseInt(b.replace(reN, ''), 10);

//     if (aN === bN) return 0;

//     return aN > bN ? 1 : -1;
//   } else {
//     return aA > bA ? 1 : -1;
//   }
// };

export const sortByPropertyAlphaNumeric = (a, b, property) =>
  a[property]
    .trim()
    .toLowerCase()
    .localeCompare(b[property].trim().toLowerCase(), 'en', { numeric: true });

export const sortByNestedProperty = (a, b, path) => {
  const A = _.get(a, path);
  const B = _.get(b, path);
  if (A !== undefined && B === undefined) {
    return 1;
  }
  if (A === undefined && B !== undefined) {
    return -1;
  }
  if (A === undefined && B === undefined) {
    return 0;
  }
  const propertyA = typeof A === 'string' ? A.toUpperCase() : A;
  const propertyB = typeof B === 'string' ? B.toUpperCase() : B;
  if (propertyA < propertyB) {
    return -1;
  }
  if (propertyA > propertyB) {
    return 1;
  }
  return 0;
};

export const sortByDecimalProperty = (a, b, propertyName) => {
  if (
    (!_.get(a, propertyName) && _.get(b, propertyName)) ||
    (typeof _.get(a, propertyName) !== 'string' &&
      typeof _.get(a, propertyName) !== 'number' &&
      typeof _.get(b, propertyName) === 'string' &&
      typeof _.get(b, propertyName) === 'number')
  ) {
    // a is undefined, but b is not so rank b higher
    return -1;
  }
  if (
    (_.get(a, propertyName) && !_.get(b, propertyName)) ||
    (typeof _.get(a, propertyName) === 'string' &&
      typeof _.get(a, propertyName) === 'number' &&
      typeof _.get(b, propertyName) !== 'string' &&
      typeof _.get(b, propertyName) !== 'number')
  ) {
    // b is undefined, but a is not so rank a higher
    return 1;
  }
  if (
    (!_.get(a, propertyName) && !_.get(b, propertyName)) ||
    (typeof _.get(a, propertyName) !== 'string' &&
      typeof _.get(a, propertyName) !== 'number' &&
      typeof _.get(b, propertyName) !== 'string' &&
      typeof _.get(b, propertyName) !== 'number')
  ) {
    // a and b not defined, so rank equal
    return 0;
  }
  const propertyA = new Decimal(_.get(a, propertyName));
  const propertyB = new Decimal(_.get(b, propertyName));

  if (propertyA.lt(propertyB)) {
    return -1;
  }
  if (propertyA.gt(propertyB)) {
    return 1;
  }
  return 0;
};

export const sortByDecimalAndAlphaProperty = (
  a,
  b,
  decimalProperty,
  alphaProperty
) => {
  return sortByDecimalProperty(a, b, decimalProperty) === 0
    ? sortByProperty(b, a, alphaProperty)
    : sortByDecimalProperty(a, b, decimalProperty);
};

export const sortByDateProperty = (a, b, dateProperty, tieBreaker) => {
  if (
    !a[dateProperty] ||
    !b[dateProperty] ||
    typeof a[dateProperty] !== 'string' ||
    typeof b[dateProperty] !== 'string'
  ) {
    return 0;
  }
  const dateA = new Date(a[dateProperty]);
  const dateB = new Date(b[dateProperty]);

  if (dateA - dateB < 0) {
    return -1;
  }
  if (dateA - dateB > 0) {
    return 1;
  }

  return sortByProperty(a, b, tieBreaker);
};

export const sortScores = (a, b) => {
  if (a.sequenceOrder < b.sequenceOrder) return -1;
  if (a.sequenceOrder > b.sequenceOrder) return 1;

  if (a.procedureCodeOrder < b.procedureCodeOrder) return -1;
  if (a.procedureCodeOrder > b.procedureCodeOrder) return 1;

  if (a.rubricOrder < b.rubricOrder) return -1;
  if (a.rubricOrder > b.rubricOrder) return 1;

  if (a.rubricItem < b.rubricItem) return -1;
  if (a.rubricItem > b.rubricItem) return 1;

  if (a.stepSubItem.length < b.stepSubItem.length) return -1;
  if (a.stepSubItem.length > b.stepSubItem.length) return 1;

  if (a.stepSubItem < b.stepSubItem) return -1;
  if (a.stepSubItem > b.stepSubItem) return 1;

  if (a.stepSubItemDescription < b.stepSubItemDescription) return -1;
  if (a.stepSubItemDescription > b.stepSubItemDescription) return 1;

  return 0;
};

export const sortByFormattedFullName = (a, b) => {
  const firstNameA = _.get(a, 'firstName', '');
  const middleNameA = _.get(a, 'middleName', '');
  const lastNameA = _.get(a, 'lastName', '');
  const marriedLastNameA = _.get(a, 'marriedLastName', null);
  const firstNameB = _.get(b, 'firstName', '');
  const middleNameB = _.get(b, 'middleName', '');
  const lastNameB = _.get(b, 'lastName', '');
  const marriedLastNameB = _.get(b, 'marriedLastName', null);

  if (marriedLastNameA && marriedLastNameB) {
    let res = marriedLastNameA.localeCompare(marriedLastNameB);
    if (res === 0) {
      res = lastNameA.localeCompare(lastNameB);
      if (res === 0) {
        res = firstNameA.localeCompare(firstNameB);
        if (res === 0) {
          res = middleNameA.localeCompare(middleNameB);
        }
      }
    }
    return res;
  }
  if (marriedLastNameA && !marriedLastNameB) {
    let res = marriedLastNameA.localeCompare(lastNameB);
    if (res === 0) {
      res = firstNameA.localeCompare(firstNameB);
      if (res === 0) {
        res = middleNameA.localeCompare(middleNameB);
      }
    }
    return res;
  }
  if (!marriedLastNameA && marriedLastNameB) {
    let res = lastNameA.localeCompare(marriedLastNameB);
    if (res === 0) {
      res = firstNameA.localeCompare(firstNameB);
    }
    return res;
  }
  let res = lastNameA.localeCompare(lastNameB);
  if (res === 0) {
    res = firstNameA.localeCompare(firstNameB);
    if (res === 0) {
      res = middleNameA.localeCompare(middleNameB);
    }
  }
  return res;
};

export const sortByDecimalPropertyAndFullName = (
  a,
  b,
  decimalPropertyName,
  firstNameProperty,
  lastNameProperty,
  reversed = false
) => {
  const sanitizeA = _.get(a, decimalPropertyName) || '0.00';
  const sanitizeB = _.get(b, decimalPropertyName) || '0.00';

  const propertyA = new Decimal(sanitizeA);
  const propertyB = new Decimal(sanitizeB);

  if (propertyA.lt(propertyB)) {
    return -1;
  }
  if (propertyA.gt(propertyB)) {
    return 1;
  }
  const fullNameA = `${_.get(a, lastNameProperty)}, ${_.get(
    a,
    firstNameProperty
  )}`;
  const fullNameB = `${_.get(b, lastNameProperty)}, ${_.get(
    b,
    firstNameProperty
  )}`;
  return reversed
    ? fullNameB.localeCompare(fullNameA)
    : fullNameA.localeCompare(fullNameB);
};

export const sortByDecimalPropertiesAndFullName = (
  a,
  b,
  decimalPropertyOne,
  decimalPropertyTwo,
  firstNameProperty,
  lastNameProperty,
  reversed = false
) => {
  const propertyOneA = new Decimal(_.get(a, decimalPropertyOne, 0));
  const propertyOneB = new Decimal(_.get(b, decimalPropertyOne, 0));

  if (propertyOneA.lt(propertyOneB)) {
    return -1;
  }
  if (propertyOneA.gt(propertyOneB)) {
    return 1;
  }
  const propertyTwoA = new Decimal(_.get(a, decimalPropertyTwo, 0));
  const propertyTwoB = new Decimal(_.get(b, decimalPropertyTwo, 0));

  if (propertyTwoA.lt(propertyTwoB)) {
    return -1;
  }
  if (propertyTwoA.gt(propertyTwoB)) {
    return 1;
  }
  const fullNameA = `${_.get(a, lastNameProperty)}, ${_.get(
    a,
    firstNameProperty
  )}`;
  const fullNameB = `${_.get(b, lastNameProperty)}, ${_.get(
    b,
    firstNameProperty
  )}`;
  return reversed
    ? fullNameB.localeCompare(fullNameA)
    : fullNameA.localeCompare(fullNameB);
};

export const sortUsersByFullName = (userOne, userTwo) => {
  if (!userOne) {
    return -1;
  } else if (!userTwo) {
    return 1;
  }

  const userOneLastName = formatLastName(
    userOne.lastName,
    userOne.marriedLastName,
    userOne.suffix
  );
  const userOneFirstMiddleName = formatFirstNameMiddleName(
    userOne.firstName,
    userOne.middleName
  );
  const userTwoLastName = formatLastName(
    userTwo.lastName,
    userTwo.marriedLastName,
    userTwo.suffix
  );

  const userTwoFirstMiddleName = formatFirstNameMiddleName(
    userTwo.firstName,
    userTwo.middleName
  );
  const userOneFullName =
    userOne.fullName ||
    `${userOneLastName}, ${userOneFirstMiddleName}`.replace(/\(|\)/g, '');
  const userTwoFullName =
    userTwo.fullName ||
    `${userTwoLastName}, ${userTwoFirstMiddleName}`.replace(/\(|\)/g, '');

  return userOneFullName
    .replace('(', '')
    .localeCompare(userTwoFullName.replace('(', ''));
};

export const sortByMicroLevels = (a, b) => {
  const codeA = `${a.level_one_code}${a.level_two_code}`;
  const codeB = `${b.level_one_code}${b.level_two_code}`;

  if (codeA < codeB) {
    return -1;
  } else {
    return 1;
  }
};

export const formatOptions = (options, access) => {
  const collection = options.map(option => ({
    value: option.uuid,
    label: option[access]
  }));

  return collection;
};

export const filterUniqueOptionsByValue = options => {
  const seen = {};
  return options.filter(option => {
    if (seen[option.value]) {
      return false;
    }
    seen[option.value] = true;

    return true;
  });
};

export const filterUniqueOptionsByLabel = options => {
  const seen = {};
  return options.filter(option => {
    const thisLabel = option.label.toLowerCase();
    if (seen[thisLabel]) {
      return false;
    }
    seen[thisLabel] = true;

    return true;
  });
};

export const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

export const cutStringToLength = (title, numChars) =>
  title.length > numChars ? `${title.substring(0, numChars)}...` : title;

export const alphaNumSorted = (array, propertyA, propertyB) => {
  return [...array].sort((objA, objB) => {
    const result = objA[propertyA].localeCompare(objB[propertyA], undefined, {
      numeric: true,
      sensitivity: 'base'
    });
    return result === 0
      ? objA[propertyB].localeCompare(objB[propertyB], undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      : result;
  });
};

export const totalByScoreType = (
  scoreType,
  scoreValueType,
  scoresByAssessmentBlock
) => {
  if (scoreType === 'Attempt') {
    const assessmentBlockUuids = Object.keys(scoresByAssessmentBlock);
    let totalValue = new Decimal(0);
    for (let i = 0; i < assessmentBlockUuids.length; i += 1) {
      const currentBlockUuid = assessmentBlockUuids[i];
      let latestAttempt = 0;
      let cumulativeValue = new Decimal(0);
      for (
        let j = 0;
        j < scoresByAssessmentBlock[currentBlockUuid].length;
        j += 1
      ) {
        const currentScore = scoresByAssessmentBlock[currentBlockUuid][j];
        if (currentScore.attempt === latestAttempt) {
          cumulativeValue = cumulativeValue.plus(currentScore[scoreValueType]);
        }
        if (currentScore.attempt > latestAttempt) {
          cumulativeValue = new Decimal(currentScore[scoreValueType]);
          latestAttempt = currentScore.attempt;
        }
      }
      totalValue = totalValue.plus(cumulativeValue);
    }
    return totalValue.toDecimalPlaces(1).toString();
  }
  if (scoreType === 'Opportunity') {
    const allScores = _.flatten(Object.values(scoresByAssessmentBlock));
    const decimalTotal = allScores.reduce((total, score) => {
      return total.plus(score[scoreValueType]);
    }, new Decimal(0));
    return decimalTotal.toDecimalPlaces(1).toString();
  }
};

export const buildCriticalFailureMap = (scoreType, scoresByAttempt) => {
  const criticalFailureMap = {};
  if (scoreType === 'Attempt') {
    Object.keys(scoresByAttempt).forEach(attemptNumber => {
      scoresByAttempt[attemptNumber].forEach(score => {
        if (
          new Decimal(score.relValue).equals(0) &&
          typeof score.rubricItem === 'string' &&
          !score.rubricItem.toLowerCase().includes('self-assessment')
        ) {
          criticalFailureMap[score.uuid] = Number(attemptNumber);
        }
      });
    });
  }
  return criticalFailureMap;
};

export const determineStudentCriticalFailure = studentBlockResultsByBlock => {
  const blockUuids = Object.keys(studentBlockResultsByBlock);
  for (let i = 0; i < blockUuids.length; i += 1) {
    const currentBlockUuid = blockUuids[i];
    let latestBlockAttempt = 0;
    let latestBlockStatus;
    for (
      let j = 0;
      j < studentBlockResultsByBlock[currentBlockUuid].length;
      j += 1
    ) {
      const currentBlockResult =
        studentBlockResultsByBlock[currentBlockUuid][j];
      if (currentBlockResult.blockAttempt > latestBlockAttempt) {
        latestBlockAttempt = currentBlockResult.blockAttempt;
        latestBlockStatus = currentBlockResult.status;
      }
    }
    if (latestBlockStatus !== 'pass') return true;
  }
  return false;
};

export const decimalTenth = string => {
  if (string) {
    const decimal = new Decimal(string);

    const tenth = decimal.toFixed(2);

    return tenth.toString();
  } else {
    return '';
  }
};

export const filterResultCardsByGroup = (
  resultCardsByAssessment,
  groupMembers
) => {
  const groupMemberUuids = new Set();
  groupMembers.forEach(groupMember =>
    groupMemberUuids.add(groupMember.user.uuid)
  );
  const filteredResultCards = {};
  Object.keys(resultCardsByAssessment).forEach(assessmentUuid => {
    const currentResultCards = resultCardsByAssessment[assessmentUuid];
    filteredResultCards[assessmentUuid] = currentResultCards
      .filter(resultCard => groupMemberUuids.has(resultCard.studentUuid))
      .sort((a, b) => sortByNestedProperty(a, b, 'user.lastName'));
  });
  return filteredResultCards;
};

export const groupResultCardsByStudent = resultCardsByAssessment => {
  const groupedResultCards = {};
  Object.keys(resultCardsByAssessment).forEach(assessmentUuid => {
    groupedResultCards[assessmentUuid] = {};
    resultCardsByAssessment[assessmentUuid].forEach(resultCard => {
      groupedResultCards[assessmentUuid][resultCard.studentUuid] = resultCard;
    });
  });
  return groupedResultCards;
};

export const groupPointsPerStudentByAssessment = resultCardsByAssessment => {
  const pointsPerStudentByAssessment = {};
  const totalPointsByStudent = {};
  let groupAssessmentsTotal = new Decimal(0);
  Object.keys(resultCardsByAssessment).forEach(assessmentUuid => {
    let assessmentTotalPoints = new Decimal(0);
    const numStudents = new Decimal(
      resultCardsByAssessment[assessmentUuid].length
    );
    resultCardsByAssessment[assessmentUuid].forEach(resultCard => {
      const currentResultCardScore = new Decimal(
        resultCard?.assessmentRelativeScore || 0
      );
      groupAssessmentsTotal = groupAssessmentsTotal.plus(
        currentResultCardScore
      );
      assessmentTotalPoints = assessmentTotalPoints.plus(
        currentResultCardScore
      );

      totalPointsByStudent[resultCard.studentUuid] = totalPointsByStudent[
        resultCard.studentUuid
      ]
        ? new Decimal(totalPointsByStudent[resultCard.studentUuid])
            .plus(currentResultCardScore)
            .toDecimalPlaces(2)
            .toString()
        : currentResultCardScore.toDecimalPlaces(2).toString();
    });
    pointsPerStudentByAssessment[assessmentUuid] = assessmentTotalPoints
      .dividedBy(numStudents)
      .toDecimalPlaces(2)
      .toString();
  });
  groupAssessmentsTotal = groupAssessmentsTotal.toDecimalPlaces(2).toString();
  return {
    groupAssessmentsTotal,
    pointsPerStudentByAssessment,
    totalPointsByStudent
  };
};

export const getDroppedAssessmentUuids = (
  assessmentCollection,
  associatedResultCards,
  isPointsOnly
) => {
  if (
    isPointsOnly ||
    assessmentCollection.low_grades_dropped === undefined ||
    assessmentCollection.low_grades_dropped < 1
  )
    return new Set();
  const sortedResultCards = [...associatedResultCards].sort((a, b) =>
    sortByDecimalProperty(a, b, 'calculatedGrade')
  );
  return new Set(
    sortedResultCards
      .slice(0, assessmentCollection.low_grades_dropped)
      .map(resultCard => resultCard.assessmentUuid)
  );
};

export const weightPercentage = objectList => {
  return _.reduce(
    objectList,
    (result, item) => {
      if (item.weight) {
        const conversion = new Decimal(item.weight);
        return result.add(conversion);
      } else {
        const conversion = new Decimal(0);
        return result.add(conversion);
      }
    },
    new Decimal(0)
  )
    .toFixed(2)
    .valueOf();
};

export const getApplicantDemographicReportMapping = reportType => {
  switch (reportType) {
    case 'Age at Application':
      return applicantDemographicReportSummaryMapping;
    case 'Gender':
      return applicantDemographicReportSummaryGenderMapping;
    case 'Ethnicity':
      return applicantDemographicReportSummaryEthnicityMapping;
    default:
      break;
  }
};

export const getApplicantCognitiveReportMapping = reportType => {
  switch (reportType) {
    case 'Age at Application':
      return applicantDemographicReportSummaryMapping;
    case 'Gender':
      return applicantDemographicReportSummaryGenderMapping;
    case 'Ethnicity':
      return applicantDemographicReportSummaryEthnicityMapping;
    default:
      break;
  }
};

export const getApplicantDemographicReportFilters = reportType => {
  switch (reportType) {
    case 'Age at Application':
      return [
        {
          label: 'Default',
          property: 'user.lastName',
          decimalProperty: false,
          reversed: false
        },
        {
          label: 'Youngest to Oldest',
          property: 'ageAtApplication',
          decimalProperty: true,
          reversed: false
        },
        {
          label: 'Oldest to Youngest',
          property: 'ageAtApplication',
          decimalProperty: true,
          reversed: true
        }
      ];
    default:
      return [];
  }
};

export const getApplicantCognitiveReportFilters = () => {
  return [
    {
      label: 'Default',
      property: 'user.lastName',
      decimalProperty: false,
      reversed: false
    },
    {
      label: 'Highest to Lowest (Rank)',
      property: 'cohortRank',
      decimalProperty: true,
      reversed: false
    },
    {
      label: 'Lowest to Highest (Rank)',
      property: 'cohortRank',
      decimalProperty: true,
      reversed: true
    }
  ];
};

export const termFinder = date => {
  const year = moment(date).year();

  const strFall = `${year}-08-16`;
  const endFall = `${year}-12-31`;

  const strSpring = `${year}-01-01`;
  const endSpring = `${year}-05-15`;

  const strSummer1 = `${year}-05-16`;
  const endSummer1 = `${year}-06-30`;

  const strSummer2 = `${year}-07-01`;
  const endSummer2 = `${year}-08-15`;

  const fall = moment(date, 'YYYY-MM-DD').isBetween(
    strFall,
    endFall,
    'days',
    '[]'
  );
  const spring = moment(date, 'YYYY-MM-DD').isBetween(
    strSpring,
    endSpring,
    'days',
    '[]'
  );
  const summer1 = moment(date, 'YYYY-MM-DD').isBetween(
    strSummer1,
    endSummer1,
    'days',
    '[]'
  );
  const summer2 = moment(date, 'YYYY-MM-DD').isBetween(
    strSummer2,
    endSummer2,
    'days',
    '[]'
  );

  if (fall) {
    return 'Fall';
  }

  if (spring) {
    return 'Spring';
  }

  if (summer1) {
    return 'Summer1';
  }

  if (summer2) {
    return 'Summer2';
  }
};

class CustomError extends Error {
  constructor(status = 500, ...params) {
    super(...params);

    /* stack trace */
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }

    this.name = 'CustomError';
    this.status = status;
    this.date = new Date();
  }
}

export const extractJSON = async response => {
  const contentType = response.headers.get('content-type');

  if (_.includes(contentType, 'json')) {
    return response.json();
  }
  if (_.includes(contentType, 'text')) {
    const text = await response.text();

    throw new CustomError(
      500,
      `The response is text/html, unable to json parse. Content type ${contentType}. URL: ${response.url}. Response: ${text}`
    );
  } else {
    throw new CustomError(
      500,
      `Unable to process response. Content type ${contentType}. URL: ${response.url} `
    );
  }
};

export async function fetchWithState(url, method = 'GET', body = null) {
  const state = {
    loading: true
  };

  try {
    const response = await fetchWithAuthorization(url, {
      method,
      body,
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });

    const data = await extractJSON(response);

    if (!response.ok) {
      state.error = data.error;
    }

    state.value = data;
    state.loading = false;
  } catch (error) {
    state.error = error;
    state.loading = false;
  }

  return state;
}

export const fetcher = async (url, options) => {
  const res = await fetchWithAuthorization(`${url}${options?.arg?.queryParameters || ''}`, options?.arg?.options);

  const contentType = res?.headers?.get('content-type');

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    error.info = contentType?.includes('json')
      ? await res.json()
      : await res.text();
    error.status = res.status;
    throw error;
  }

  return contentType?.includes('json') ? res.json() : res.text();
};
