import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import FacultyAlternativeScoreViewAttemptPanelContainer from './FacultyAlternativeScoreViewAttemptPanelContainer';

const StudentPanelAccordion = styled(
  ({ withBottomBorder, withTopBorder, criticalFailure, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label,
  .column_two_label {
    flex-grow: 0;
    width: 100px;
    text-align: right;
  }

  .accordion_row_label div {
    font-size: 16px;
    letter-spacing: 0.15;
    color: ${props => props.theme.fontColors.darker};
  }

  .column_one_label {
    margin-right: 16px;
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props =>
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.theme.fontColors.darker};
    }
  }

  .column_two_label {
    span {
      letter-spacing: 0.15;
      color: ${props => props.theme.fontColors.darker};
    }
  }
  .hover-effect {
    padding-right: 44px;
  }
`;

const AttemptPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 80px;
  width: calc(100% - 80px);
`;

const AttemptPanelHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  font-weight: 450;
`;

const AttemptPanelHeaderSub = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  padding-right: ${props => props.paddingRight};
  color: ${props => props.theme.fontColors.dark};
  text-align: right;
  }
`;

const FacultyAlternaticeScoreViewFacultyPanel = ({
  facultyFullName,
  facultyTotalPotentialScore,
  facultyTotalRelativeScore,
  scoreType,
  studentScoresByAttempt
}) => {
  return (
    <StudentPanelAccordion
      expandWidth="66px"
      height="50px"
      label={`${facultyFullName}`}
      columnOne={facultyTotalRelativeScore}
      columnTwo={facultyTotalPotentialScore}
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
      rowOffset="28px"
    >
      <AttemptPanelHeader>
        <AttemptPanelHeaderInfo>Attempt / Opportunity</AttemptPanelHeaderInfo>
        <AttemptPanelHeaderInfo>
          <AttemptPanelHeaderSub paddingRight="24px">
            Total Relative
          </AttemptPanelHeaderSub>
          <AttemptPanelHeaderSub paddingRight="44px">
            Total Potential
          </AttemptPanelHeaderSub>
        </AttemptPanelHeaderInfo>
      </AttemptPanelHeader>
      {Object.keys(studentScoresByAttempt).map(attemptNumber => (
        <FacultyAlternativeScoreViewAttemptPanelContainer
          key={attemptNumber}
          attemptNumber={attemptNumber}
          scoreType={scoreType}
          studentAttemptScores={studentScoresByAttempt[attemptNumber]}
        />
      ))}
    </StudentPanelAccordion>
  );
};

FacultyAlternaticeScoreViewFacultyPanel.propTypes = {
  facultyFullName: PropTypes.string,
  facultyTotalPotentialScore: PropTypes.string,
  facultyTotalRelativeScore: PropTypes.string,
  scoreType: PropTypes.string,
  studentScoresByAttempt: PropTypes.object
};
FacultyAlternaticeScoreViewFacultyPanel.defaultProps = {
  facultyFullName: '',
  facultyTotalPotentialScore: '',
  facultyTotalRelativeScore: '',
  scoreType: '',
  studentScoresByAttempt: {}
};

export default FacultyAlternaticeScoreViewFacultyPanel;
