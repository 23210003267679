import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { useSelector } from 'react-redux';
import ButtonPrimary from '@xcomp/xcomp-design-library/dist/components/ButtonPrimary';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '../../../Library/Modal/Modal';
import { userSelector } from '../../../../redux/selectors/usersSelectors';
import { cohortSelector } from '../../../../redux/selectors/cohortsSelectors';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-top: 10px;
  width: 600px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
`;

const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Selection = styled.div`
  width: 100%;
  &:hover {
    background: ${props => props.theme.colors.grey[300]};
  }
  padding: 0.5rem;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const Group = styled.div`
  display: flex;
  font-size: 14px;
`;

const Label = styled.div``;

const Input = styled.div`
  padding-left: 10px;
`;

const InvisibleLineBreak = styled.div`
  height: 4px;
`;

export default function TranscriptClone({
  loading,
  applicantRoles,
  openModal,
  handleCloneClose,
  handleCloneTranscript
}) {
  return (
    <Modal open={openModal} onClose={handleCloneClose} maxWidth="xl">
      <ModalBody>
        <ModalHeader>
          <Title>Select Application to Clone</Title>
          <CloseModal>
            <IconButton onClick={handleCloneClose}>
              <CloseIcon />
            </IconButton>
          </CloseModal>
        </ModalHeader>
        <Grid container>
          <Grid item sm={12} xs={12}>
            {loading ? <LinearProgress /> : <InvisibleLineBreak />}
          </Grid>
        </Grid>
        {applicantRoles?.map(role => (
          <Selection key={role.uuid}>
            <Grid container>
              <Grid item sm={4} xs={12}>
                <User userUuid={role.userUuid} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Cohort cohortUuid={role.cohortUuid} />
              </Grid>
              <Grid item sm={2} xs={12}>
                <ButtonPrimary
                  disabled={loading}
                  onClick={() =>
                    handleCloneTranscript(role.cohortUuid, role.userUuid)
                  }
                >
                  Clone
                </ButtonPrimary>
              </Grid>
            </Grid>
          </Selection>
        ))}
        {applicantRoles?.length === 0 && (
          <div>The user has no existing applications to clone.</div>
        )}
      </ModalBody>
    </Modal>
  );
}

TranscriptClone.propTypes = {
  loading: PropTypes.bool,
  applicantRoles: PropTypes.array,
  openModal: PropTypes.bool,
  handleCloneClose: PropTypes.func,
  handleCloneTranscript: PropTypes.func
};

TranscriptClone.defaultProps = {
  loading: false,
  applicantRoles: [],
  openModal: false,
  handleCloneClose: undefined,
  handleCloneTranscript: undefined
};

function User({ userUuid }) {
  const user = useSelector(state => userSelector(state, userUuid));

  return (
    <Group>
      <Label>Name:</Label>
      <Input>
        {user?.firstName} {user?.lastName}
      </Input>
    </Group>
  );
}

User.propTypes = {
  userUuid: PropTypes.string
};

User.defaultProps = {
  userUuid: undefined
};

function Cohort({ cohortUuid }) {
  const cohort = useSelector(state => cohortSelector(state, cohortUuid));

  return (
    <>
      <Group>
        <Label>Cohort:</Label>
        <Input>{cohort?.name}</Input>
      </Group>
      <Group>
        <Label>Date:</Label>
        <Input>
          {moment(cohort?.startDate).format('ll')}-
          {moment(cohort?.endDate).format('ll')}
        </Input>
      </Group>
    </>
  );
}

Cohort.propTypes = {
  cohortUuid: PropTypes.string
};

Cohort.defaultProps = {
  cohortUuid: undefined
};
