import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import {
  ContainerResize,
  ContainerPage,
  ContainerActions,
  ElevateLow
} from '../Library/Layout';
import Search from '../Library/Search';
import Header from '../Library/Header';

import DepartmentActions from './DepartmentActions';

const SearchRow = styled.div`    
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid
    rgba(224, 224, 224, 1);
`;

const SearchBox = styled.div`
  width: 400px;
`;

const DepartmentManagement = ({ departments, searchUpdated }) => {
  return (
    <ContainerResize>
      <Header
        title="Department Management"
        subtitle="Manage departments, add, edit, and remove"
        backOn={false}
      />
      <ContainerActions>
        <Link to="/department-management/add">
          <ButtonPrimary>Add Department</ButtonPrimary>
        </Link>
      </ContainerActions>
      <ContainerPage paddingTop="40px">
        <ElevateLow>
          <SearchRow>
            <SearchBox>
              <Search onChange={searchUpdated} />
            </SearchBox>
          </SearchRow>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Abbr</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>UUID</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {departments.map(department => (
                <TableRow key={department.uuid}>
                  <TableCell>{department.code}</TableCell>
                  <TableCell>{department.name}</TableCell>
                  <TableCell>{department.uuid}</TableCell>
                  <TableCell>
                    <DepartmentActions departmentUuid={department.uuid} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
};

DepartmentManagement.propTypes = {
  departments: PropTypes.array,
  searchUpdated: PropTypes.func
};

DepartmentManagement.defaultProps = {
  departments: [],
  searchUpdated: undefined
};

export default DepartmentManagement;
