import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const selectLetterGrade = ormCreateSelector(orm.LetterGrade);

export const letterGradesBySchoolSelector = createSelector(
  state => state,
  state => state.userState.selectedSchoolUuid,
  (redux, selectedSchoolUuid) => {
    const letterGradesSelectorORM = ormCreateSelector(orm.LetterGrade);
    const letterGrades = letterGradesSelectorORM(redux).filter(
      letterGrade => letterGrade.schoolUuid === selectedSchoolUuid
    );

    return letterGrades;
  }
);

export const selectLetterGradesBySchool = createSelector(
  state => state,
  (_, schoolUuid) => schoolUuid,
  (redux, schoolUuid) => {
    const letterGradesSelectorORM = ormCreateSelector(orm.LetterGrade);
    const letterGrades = letterGradesSelectorORM(redux).filter(
      letterGrade => letterGrade.schoolUuid === schoolUuid
    );

    return letterGrades;
  }
);
