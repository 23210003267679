import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CoursesByCollection from './CoursesByCollection';
import { courseCollectionsSelector } from '../../../../redux/selectors/courseCollectionsSelectors';

export default function CoursesByCollectionContainer({ cohortUuid, userUuid }) {
  const collections = useSelector(
    state => courseCollectionsSelector(state, cohortUuid),
    _.isEqual
  );

  return <CoursesByCollection collections={collections} userUuid={userUuid} />;
}

CoursesByCollectionContainer.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string
};

CoursesByCollectionContainer.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined
};
