export const GET_COURSE_MASTER_BY_DEPARTMENT =
  'GET_COURSE_MASTER_BY_DEPARTMENT';
export const GET_COURSE_MASTER_BY_DEPARTMENT_SUCCESS =
  'GET_COURSE_MASTER_BY_DEPARTMENT_SUCCESS';
export const GET_COURSE_MASTER_BY_DEPARTMENT_ERROR =
  'GET_COURSE_MASTER_BY_DEPARTMENT_ERROR';
export const GET_COURSE_MASTER = 'GET_COURSE_MASTER';
export const GET_COURSE_MASTER_SUCCESS = 'GET_COURSE_MASTER_SUCCESS';
export const GET_COURSE_MASTER_ERROR = 'GET_COURSE_MASTER_ERROR';

export const POST_COURSE_MASTER = 'POST_COURSE_MASTER';
export const POST_COURSE_MASTER_SUCCESS = 'POST_COURSE_MASTER_SUCCESS';
export const POST_COURSE_MASTER_ERROR = 'POST_COURSE_MASTER_ERROR';

export const PUT_COURSE_MASTER = 'PUT_COURSE_MASTER';
export const PUT_COURSE_MASTER_SUCCESS = 'PUT_COURSE_MASTER_SUCCESS';
export const PUT_COURSE_MASTER_ERROR = 'PUT_COURSE_MASTER_ERROR';

export const DELETE_COURSE_MASTER = 'DELETE_COURSE_MASTER';
export const DELETE_COURSE_MASTER_SUCCESS = 'DELETE_COURSE_MASTER_SUCCESS';
export const DELETE_COURSE_MASTER_ERROR = 'DELETE_COURSE_MASTER_ERROR';
