import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Column, TextFieldWithError } from '../../../Library/FormComponents';
import { validateStringLength } from '../../../../helpers/validation/validateGeneric';

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

const CohortAssessmentCollectionFormDetails = ({
  title,
  showValidationErrors,
  handleTitleChange
}) => {
  const validateTitleField = value => validateStringLength(value, 100, true);

  return (
    <Layout>
      <Column size="100%">
        <TextFieldWithError
          autoFocus
          id="collection-title"
          title="title"
          value={title}
          onChange={handleTitleChange}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Collection Title"
          placeholder=""
          variant="outlined"
          paddingRight="1rem"
          handleValidate={validateTitleField}
          hasErrors={showValidationErrors}
        />
      </Column>
    </Layout>
  );
};

CohortAssessmentCollectionFormDetails.propTypes = {
  title: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleTitleChange: PropTypes.func
};
CohortAssessmentCollectionFormDetails.defaultProps = {
  title: '',
  showValidationErrors: false,
  handleTitleChange: undefined
};

export default CohortAssessmentCollectionFormDetails;
