import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import { roles } from './constants';

/* router wrappers */
const institutionAdminAuthentication = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => {
    const role = state.userState.selectedRole;
    return role === roles.institutionAdmin;
  },
  wrapperDisplayName: 'UserIsAuthenticated'
});

const schoolAdminAuthentication = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => {
    const role = state.userState.selectedRole;
    return role === roles.schoolAdmin;
  },
  wrapperDisplayName: 'UserIsAuthenticated'
});

const institutionAdminAndSchoolAdminAuthentication = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => {
    const role = state.userState.selectedRole;
    return role === roles.institutionAdmin || role === roles.schoolAdmin;
  },
  wrapperDisplayName: 'UserIsAuthenticated'
});

const adminsAuthentication = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => {
    const role = state.userState.selectedRole;
    return (
      role === roles.institutionAdmin ||
      role === roles.schoolAdmin ||
      role === roles.programAdmin
    );
  },
  wrapperDisplayName: 'UserIsAuthenticated'
});

const facultyAuthentication = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => {
    const role = state.userState.selectedRole;
    return role === roles.departmentFaculty;
  },
  wrapperDisplayName: 'UserIsAuthenticated'
});

const schoolAdminAndDeptFacultyAndDeptStaffAuthentication = connectedRouterRedirect(
  {
    redirectPath: '/',
    authenticatedSelector: state => {
      const role = state.userState.selectedRole;
      return (
        role === roles.departmentFaculty ||
        role === roles.departmentStaff ||
        role === roles.schoolAdmin
      );
    },
    wrapperDisplayName: 'UserIsAuthenticated'
  }
);

const programAdminAuthentication = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => {
    const role = state.userState.currentRole;
    return role.role === roles.programAdmin;
  },
  wrapperDisplayName: 'UserIsAuthenticated'
});

const programAuthentication = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => {
    const role = state.userState.currentRole;
    return role.role === roles.programAdmin || role.role === roles.programFaculty;
  },
  wrapperDisplayName: 'UserIsAuthenticated'
});

const programAdminFacultyStaffAuthentication = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => {
    const role = state.userState.currentRole;
    const acceptedRoles = [
      roles.programAdmin,
      roles.programFaculty,
      roles.programStaff
    ];
    return acceptedRoles.includes(role.role);
  },
  wrapperDisplayName: 'UserIsAuthenticated'
});

/* component wrappers */
const visibleToInstitutionAdmin = connectedAuthWrapper({
  authenticatedSelector: state => {
    const role = state.userState && state.userState.selectedRole;
    return state.userState !== null && role === roles.institutionAdmin;
  },
  wrapperDisplayName: 'VisibleToInstitutionAdmin'
});

const visibleToInstitutionAdminSchoolAdmin = connectedAuthWrapper({
  authenticatedSelector: state => {
    const role = state.userState && state.userState.selectedRole;
    return (
      (state.userState !== null && role === roles.institutionAdmin) ||
      (state.userState !== null && role === roles.schoolAdmin)
    );
  },
  wrapperDisplayName: 'VisibleToInstitutionAdmin'
});

const visibleToSchoolRoles = connectedAuthWrapper({
  authenticatedSelector: state => {
    const role = state.userState && state.userState.selectedRole;
    return (
      (role && role === roles.schoolAdmin) ||
      (role && role === roles.departmentFaculty) ||
      (role && role === roles.departmentStaff)
    );
  },
  wrapperDisplayName: 'VisibleToSchoolRoles'
});

const visibleToSchoolAdmin = connectedAuthWrapper({
  authenticatedSelector: state => {
    const role = state.userState && state.userState.selectedRole;
    return role && role === roles.schoolAdmin;
  },
  wrapperDisplayName: 'VisibleToSchoolAdmin'
});

const visibleToSchoolAdminProgramAdmin = connectedAuthWrapper({
  authenticatedSelector: state => {
    const role = state.userState && state.userState.selectedRole;
    return (
      (role && role === roles.schoolAdmin) ||
      (role && role === roles.programAdmin)
    );
  },
  wrapperDisplayName: 'VisibleToSchoolAdminProgramAdmin'
});

const visibleToProgramAdmin = connectedAuthWrapper({
  authenticatedSelector: state => {
    const role = state.userState && state.userState.selectedRole;
    return role && role === roles.programAdmin;
  },
  wrapperDisplayName: 'VisibleToProgramAdmin'
});

const visibleToProgramFaculty = connectedAuthWrapper({
  authenticatedSelector: state => {
    const role = state.userState && state.userState.selectedRole;
    return role && role === roles.programFaculty;
  },
  wrapperDisplayName: 'VisibleToProgramFaculty'
});

const visibleToAllProgramRoles = connectedAuthWrapper({
  authenticatedSelector: state => {
    const legalRoles = [
      roles.programAdmin,
      roles.programFaculty,
      roles.programStaff
    ];
    const role = state.userState && state.userState.selectedRole;
    return role && legalRoles.includes(role);
  },
  wrapperDisplayName: 'VisibleToAllProgramRoles'
});

const visibleToProgramStaff = connectedAuthWrapper({
  authenticatedSelector: state => {
    const role = state.userState && state.userState.selectedRole;
    return role && role === roles.programStaff;
  },
  wrapperDisplayName: 'VisibleToProgramStaff'
});

const visibleToDeptFaculty = connectedAuthWrapper({
  authenticatedSelector: state => {
    const role = state.userState && state.userState.selectedRole;
    return role && role === roles.departmentFaculty;
  },
  wrapperDisplayName: 'VisibleToDeptFaculty'
});

const visibleToDeptStaff = connectedAuthWrapper({
  authenticatedSelector: state => {
    const role = state.userState && state.userState.selectedRole;
    return role && role === roles.departmentStaff;
  },
  wrapperDisplayName: 'VisibleToDeptStaff'
});

const isSchoolAdmin = state => {
  const role = state.userState && state.userState.selectedRole;
  return role && role === roles.schoolAdmin;
};

const isProgramAdmin = state => {
  const role = state.userState && state.userState.selectedRole;
  return role && role === roles.programAdmin;
};

export {
  facultyAuthentication,
  adminsAuthentication,
  institutionAdminAndSchoolAdminAuthentication,
  institutionAdminAuthentication,
  programAdminAuthentication,
  programAdminFacultyStaffAuthentication,
  programAuthentication,
  schoolAdminAndDeptFacultyAndDeptStaffAuthentication,
  schoolAdminAuthentication,
  visibleToInstitutionAdmin,
  visibleToInstitutionAdminSchoolAdmin,
  visibleToSchoolRoles,
  visibleToSchoolAdmin,
  visibleToSchoolAdminProgramAdmin,
  visibleToProgramAdmin,
  visibleToProgramFaculty,
  visibleToAllProgramRoles,
  visibleToProgramStaff,
  visibleToDeptFaculty,
  visibleToDeptStaff,
  isSchoolAdmin,
  isProgramAdmin
};
