import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import { ButtonInline } from '@xcomp/xcomp-design-library';

const Label = styled.div``;

const FilterSelect = styled.div`
  display: flex;
  align-items: center;
`;

const MenuItemSC = styled(MenuItem)`
  && {
    display: flex;
    justify-content: space-between;
  }
`;

const FormControlLabelSC = styled(FormControlLabel)`
  && {
    margin: 0;
  }
`;

const ButtonInlineSC = styled(ButtonInline)`
  width: 100%;
`;

class DepartmentRoleStatusFilter extends Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { className, filterStatus, handleFilterSelection } = this.props;

    return (
      <div className={className}>
        <FilterSelect
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          noHoverBg
        >
          <Label>Status</Label>
          <ArrowDropDown />
        </FilterSelect>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItemSC>
            <Label>Active</Label>
            <FormControlLabelSC
              control={
                <Checkbox
                  checked={filterStatus.active}
                  onChange={() => handleFilterSelection('active')}
                  color="primary"
                />
              }
            />
          </MenuItemSC>
          <MenuItemSC>
            <Label>Complete</Label>
            <FormControlLabelSC
              control={
                <Checkbox
                  checked={filterStatus.complete}
                  onChange={() => handleFilterSelection('complete')}
                  color="primary"
                />
              }
            />
          </MenuItemSC>
          <MenuItemSC>
            <ButtonInlineSC onClick={this.handleClose}>Close</ButtonInlineSC>
          </MenuItemSC>
        </Menu>
      </div>
    );
  }
}

DepartmentRoleStatusFilter.propTypes = {
  className: PropTypes.string,
  filterStatus: PropTypes.object,
  handleFilterSelection: PropTypes.func
};

DepartmentRoleStatusFilter.defaultProps = {
  className: '',
  filterStatus: {},
  handleFilterSelection: undefined
};

export default DepartmentRoleStatusFilter;
