import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from '@material-ui/core';
import { tintColor } from '@xcomp/xcomp-design-library/dist/helpers';
import {
  StickyTitleTd,
  StickyInfoTd,
  StudentInfoText,
  TableRow
} from '../CollectionReportSC';

const TableHead = styled.thead`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;

  ${props => props.isCompact && props.selectedColumns.reduce((acc, curr) =>
  `${acc} & td:nth-child(${parseInt(curr, 10) + 1}){
        background: lavender !important;
      }`
  , "")}
`;

const TitleTd = styled(
  ({
    borderLeft,
    borderRight,
    displayNone,
    left,
    greyOne,
    redOne,
    redTwo,
    secondaryFontColor,
    blueOne,
    isExpanded,
    isFirstCompetency,
    showScores,
    ...rest
  }) => <td {...rest} />
)`
  display: ${props => (props.displayNone ? 'none' : 'flex')};
  align-items: flex-end;
  justify-content: center;
  min-width: ${props => props.isExpanded ? (!props.showScores && "25px") || "50px" : "75px"};
  max-width: ${props => props.isExpanded ? (!props.showScores && "25px") || "50px" : "75px"};
  z-index: 5;
  background-color: ${props =>
    (props.redOne && tintColor(props.theme.colors.red[100], 0.5)) ||
    (props.redTwo && tintColor(props.theme.colors.red[100], 0.4)) ||
    (props.blueOne && tintColor(props.theme.colors.blue[500], 0.8)) ||
    'white'};
  ${props =>
    props.borderLeft && `border-left: solid 1px ${props.theme.colors.grey[300]}`};
  ${props =>
    props.borderRight &&
    `border-right: solid 1px ${props.theme.colors.grey[300]}`};
  border-top: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
  color: ${props =>
    props.secondaryFontColor
      ? props.theme.fontColors.dark
      : props.theme.fontColors.darker};
  ${props =>
    props.isFirstCompetency && `background: linear-gradient(90deg, ${tintColor(props.theme.colors.blue[500], 0.8)} 0%, rgba(255,255,255,1) 75%)`};
`;

const RotatedInfoTitle = styled.div`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 16px 0px 32px 0px;
  white-space: nowrap;
`;

const ReportTableHead = ({
  competencyCollections,
  isExpanded,
  isDelayed,
  selectedColumns,
  showScores,
  showTotals,
}) => {
  return (
    <TableHead selectedColumns={selectedColumns} isCompact={!showScores}>
      <TableRow>
        <StickyTitleTd redOne isRank left="0px" borderRight key="cohort-rank">
          <RotatedInfoTitle>
            {isDelayed ? '' : 'Comptency Rank'}
          </RotatedInfoTitle>
        </StickyTitleTd>
        <StickyInfoTd left="25px" borderRightStrong key="cohort-info-text">
          <StudentInfoText />
        </StickyInfoTd>
        {showTotals &&
          <>
            <StickyTitleTd
              redOne
              left="275px"
              borderRight
              key="student-cumulative-participation"
            >
              <RotatedInfoTitle style={{ fontWeight: 500 }}>Cumulative Participation (For all)</RotatedInfoTitle>
            </StickyTitleTd>
            {isExpanded &&
              <>
                <StickyTitleTd left="340px" isRank orangeOne borderRight isRelativeRank key="relative-cohort-rank">
                  <RotatedInfoTitle>
                    Relative Comptency Rank
                  </RotatedInfoTitle>
                </StickyTitleTd>
                <StickyTitleTd
                  orangeOne
                  left="365px"
                  borderRight
                  key="student-cumulative-participation-relative"
                >
                  <RotatedInfoTitle>Relative Cumulative Participation</RotatedInfoTitle>
                </StickyTitleTd>
              </>
            }
          </>
        }
        {competencyCollections?.length > 0 &&
          competencyCollections.map(collection => {
            const someUuid = uuidv4();
            return (
              <React.Fragment key={someUuid}>
                {!isExpanded ?
                  <TitleTd blueOne borderRight isExpanded={isExpanded}>
                    <Tooltip arrow title={collection.name} placement='bottom-start' PopperProps={{ disablePortal: true }}>
                      <RotatedInfoTitle>{collection.name}</RotatedInfoTitle>
                    </Tooltip>
                  </TitleTd>
                  :
                  collection.competencies.length > 0 &&
                  collection.competencies.map((competency, index) => {
                    const someUuid = uuidv4();
                    const titles = competency?.name.split(/\s-\s|\s–\s/);

                    return (
                      <TitleTd
                        key={someUuid}
                        secondaryFontColor
                        isFirstCompetency={index === 0}
                        isExpanded={isExpanded}
                        borderRight
                        showScores={showScores}
                      >
                        <Tooltip arrow title={competency?.name} placement='bottom-start' PopperProps={{ disablePortal: true }}>
                          <RotatedInfoTitle>
                            {showScores ?
                              <>
                                <div style={{ fontWeight: '500' }}>{titles[0]}</div>
                                <div>{titles[1]}</div>
                              </>
                              :
                              competency.name
                            }
                          </RotatedInfoTitle>
                        </Tooltip>
                      </TitleTd>
                    );
                  })
                }
              </React.Fragment>
            );
          })}
      </TableRow>
    </TableHead>
  );
};

ReportTableHead.propTypes = {
  competencyCollections: PropTypes.array,
  isExpanded: PropTypes.bool,
  isDelayed: PropTypes.bool,
  selectedColumns: PropTypes.array,
  showScores: PropTypes.bool.isRequired,
  showTotals: PropTypes.bool.isRequired,
};

ReportTableHead.defaultProps = {
  competencyCollections: [],
  isExpanded: false,
  isDelayed: false,
  selectedColumns: [],
};

export default ReportTableHead;
