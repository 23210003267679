import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { KeyboardArrowUp } from '@material-ui/icons';
import { Badge, Checkbox } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    zIndex: 120
  },
  paper: {
    marginRight: theme.spacing(2),
    zIndex: 120
  }
}));

export default function FilterMenuButton({
  options,
  onClick,
  buttonLabel,
  startIcon,
  selectedOptions,
  displaySearch,
  displayCheckbox,
  maxWidth,
  isAnonymized,
  variant,
  containerStyle,
  disableMinWidth,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
    setSearchTerm('');
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleSearch = useCallback((text, searchTerm) => {
    if (!text) return;
    return text.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
  }, []);

  const filteredOptions = options.filter(option => {
    if (typeof option === 'string') return handleSearch(option, searchTerm);

    if (isAnonymized) return handleSearch(option?.institutionId, searchTerm);

    return handleSearch(option?.fullName, searchTerm);
  });

  return (
    <div className={classes.root} style={containerStyle}>
      <Button
        fullWidth
        ref={anchorRef}
        variant={variant}
        color='primary'
        size="large"
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        startIcon={
          startIcon && (
            <Badge
              style={{ marginRight: '8px' }}
              badgeContent={options.length}
              color="primary"
            />
          )
        }
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        style={{
          minWidth: !disableMinWidth && anchorRef.current?.getBoundingClientRect().width,
        }}
      >
        {buttonLabel}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        className={classes.paper}
        style={{
          marginLeft: '18px',
          marginTop: '8px',
          minWidth: anchorRef.current?.getBoundingClientRect().width,
          ...(maxWidth && { maxWidth })
        }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
              maxHeight: '400px',
            }}
          >
            <Paper
              style={{
                padding: '10px',

                ...(maxWidth && { height: maxWidth }),
                overflowY: 'auto'
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  {displaySearch && (
                    <TextField
                      size="medium"
                      autoFocus
                      variant="outlined"
                      placeholder="Type to search..."
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                      onChange={e => setSearchTerm(e.target.value)}
                      onKeyDown={e => {
                        if (e.key !== 'Escape') {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation();
                        }
                      }}
                    />
                  )}
                  <MenuList id="menu-list-grow" color="primary">
                    {filteredOptions.map(option => {
                      let value = '';

                      if (isAnonymized) value = option?.institutionId;
                      else if (!isAnonymized) value = option?.fullName;

                      return (
                        <MenuItem
                          key={option.uuid ? option.uuid : option}
                          style={{
                            textTransform: 'capitalize',
                            textWrap: 'wrap'
                          }}
                          selected={selectedOptions.includes(
                            option.uuid ? option.uuid : option
                          )}
                          onClick={event => {
                            onClick(option.uuid ? option.uuid : option);
                            // displayCheckbox is present when the menu has multiple selections
                            if (displayCheckbox) return;
                            // close the menu if it's a single selection
                            handleClose(event);
                          }}
                        >
                          {displayCheckbox && (
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selectedOptions.includes(
                                option.uuid ? option.uuid : option
                              )}
                              color="primary"
                            />
                          )}
                          {option.uuid ? value : option}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

FilterMenuButton.propTypes = {
  options: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  startIcon: PropTypes.bool,
  selectedOptions: PropTypes.array,
  displaySearch: PropTypes.bool,
  displayCheckbox: PropTypes.bool,
  isAnonymized: PropTypes.bool,
  maxWidth: PropTypes.string,
  variant: PropTypes.string,
  containerStyle: PropTypes.object,
  disableMinWidth: PropTypes.bool,
};

FilterMenuButton.defaultProps = {
  startIcon: false,
  selectedOptions: [],
  displaySearch: false,
  displayCheckbox: false,
  isAnonymized: false,
  maxWidth: null,
  variant: "outlined",
  containerStyle: {},
  disableMinWidth: false,
};
