import { Model, attr, fk } from 'redux-orm';

import { GET_SYLLABUS_PART_SUCCESS } from '../actions-type';

class EncounterFaculty extends Model {
  static reducer(action, EncounterFaculty, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_SYLLABUS_PART_SUCCESS: {
        const encounters = [...payload.encounters];

        if (encounters.length > 0) {
          encounters.forEach(encounter => {
            const { encounter_faculty_assigned } = encounter;

            if (encounter_faculty_assigned?.length > 0) {
              encounter_faculty_assigned.forEach(faculty => {
                EncounterFaculty.upsert(faculty);
              });
            }
          });
        }

        break;
      }
      default:
        return session;
    }
  }
}

EncounterFaculty.fields = {
  uuid: attr(),
  userUuid: fk({
    to: 'User',
    as: 'user',
    relatedName: 'encounter_faculty_assigned'
  }),
  EncounterUuid: fk({
    to: 'Encounter',
    as: 'encounter',
    relatedName: 'encounter_faculty_assigned'
  })
};

EncounterFaculty.options = {
  idAttribute: 'uuid'
};

EncounterFaculty.modelName = 'EncounterFaculty';

export default EncounterFaculty;
