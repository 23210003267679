import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js-light';
import { doGetPartOpportunityReportStatisticBlockDetail } from '../../../redux/actions/partReportsActions';
import OpportunityReportStatisticBlockDetail from './OpportunityReportStatisticBlockDetail';

const OpportunityReportStatisticBlockDetailContainer = ({
  assessment,
  assessmentBlock,
  studentUser
}) => {
  const dispatch = useDispatch();
  const {
    partOpportunityReportStatisticBlockDetailLoading,
    partOpportunityReportStatisticBlockDetailData
  } = useSelector(state => state.partOpportunityReportState);

  useEffect(() => {
    if (assessment.uuid && studentUser.uuid) {
      dispatch(
        doGetPartOpportunityReportStatisticBlockDetail(
          assessment.uuid,
          studentUser.uuid
        )
      );
    }
  }, [dispatch, assessment.uuid, studentUser.uuid]);

  const assessementBlockLabel = `Block ${assessmentBlock.blockNumber} — ${assessmentBlock.title}`;

  return new Decimal(1).greaterThan(0) ? (
    <OpportunityReportStatisticBlockDetail
      assessmentBlock={assessmentBlock}
      isLoading={partOpportunityReportStatisticBlockDetailLoading.includes(
        `${studentUser.uuid}:${assessment.uuid}`
      )}
      panelLabel={assessementBlockLabel}
      studentAssessmentBlockData={
        partOpportunityReportStatisticBlockDetailData[studentUser.uuid]?.[
          assessmentBlock.uuid
        ]
      }
      studentAssessmentBlockRelativeScore={
        partOpportunityReportStatisticBlockDetailData[studentUser.uuid]?.[
          assessmentBlock.uuid
        ]?.totalRelativeScore
      }
      studentAssessmentBlockOpportunityCount={
        partOpportunityReportStatisticBlockDetailData[studentUser.uuid]?.[
          assessmentBlock.uuid
        ]?.totalOpportunityCount
      }
    />
  ) : null;
};

OpportunityReportStatisticBlockDetailContainer.propTypes = {
  assessment: PropTypes.object,
  assessmentBlock: PropTypes.object,
  studentUser: PropTypes.object
};
OpportunityReportStatisticBlockDetailContainer.defaultProps = {
  assessment: {},
  assessmentBlock: PropTypes.object,
  studentUser: {}
};

export default OpportunityReportStatisticBlockDetailContainer;
