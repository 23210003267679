import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import SectionLegacyGradeUpload from './SectionLegacyGradeUpload';
import { doUploadSectionLegacyGrades } from '../../../../redux/actions/legacyGradingActions';
import { useWsSubscriber } from '../../../../helpers/hooks/subscription.hooks';
import { doGetSectionPerformanceReport } from '../../../../redux/actions/sectionPerformanceReportActions';

export default function SectionLegacyGradeUploadContainer({
  sectionUuid,
  openUploadScores,
  handleUploadClose
}) {
  const dispatch = useDispatch();
  const [grades, setGrades] = useState(undefined);
  const [openMapping, setOpenMapping] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);

  const query = `sectionUuid=${sectionUuid}`;
  const pathSubscribe = sectionUuid
    ? `section/legacy-grades/upload/subscribe-status?${query}`
    : undefined;

  const redisKey = `${sectionUuid}-legacy-grades-upload`;
  const ws = useWsSubscriber(pathSubscribe, redisKey);
  const subscription = ws.result;
  const connected = ws.readyState;
  const emitter = ws.send;
  const status = subscription?.status;

  useEffect(() => {
    if (connected) {
      emitter(redisKey);
    }
  }, [redisKey, emitter, connected]);

  useEffect(() => {
    if (status === 'success' && sectionUuid) {
      dispatch(doGetSectionPerformanceReport(sectionUuid));
    }
  }, [dispatch, sectionUuid, status]);

  const handleGrades = grades => {
    setGrades(grades);
  };

  const handleUpload = () => {
    const uploadDate = moment().format('YYYY-MM-DD');
    dispatch(doUploadSectionLegacyGrades(sectionUuid, grades, uploadDate));
  };

  const handleDisplayMapping = () => {
    setOpenMapping(openMapping => !openMapping);
  };

  const handleDisplayErrors = () => {
    setOpenError(openError => !openError);
  };

  const handleDisplaySummary = () => {
    setOpenSummary(openSummary => !openSummary);
  };

  return (
    <SectionLegacyGradeUpload
      status={subscription?.status}
      error={subscription?.error}
      summary={subscription?.summary}
      uploadDate={subscription?.uploadDate}
      openModal={openUploadScores}
      openMapping={openMapping}
      openError={openError}
      openSummary={openSummary}
      grades={grades}
      handleDisplayMapping={handleDisplayMapping}
      handleDisplayErrors={handleDisplayErrors}
      handleDisplaySummary={handleDisplaySummary}
      handleUploadClose={handleUploadClose}
      handleGrades={handleGrades}
      handleUpload={handleUpload}
    />
  );
}

SectionLegacyGradeUploadContainer.propTypes = {
  sectionUuid: PropTypes.string,
  openUploadScores: PropTypes.bool,
  handleUploadClose: PropTypes.func
};
SectionLegacyGradeUploadContainer.defaultProps = {
  sectionUuid: '',
  openUploadScores: false,
  handleUploadClose: undefined
};
