import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { sortUsersByFullName } from '../../../helpers/utilities';
import Scores from './Scores';
import ScoresUploadContainer from './ScoresUpload/ScoresUploadContainer';
import DeleteModal from '../../Library/Modal/DeleteModal/DeleteModal';
import {
  doGetScores,
  doDeleteScores
} from '../../../redux/actions/scoreActions';
import {
  doGetAssessmentBlocks,
  doGetAssessmentBlockResults
} from '../../../redux/actions/assessmentBlockActions';
import { doGetStudentRoster } from '../../../redux/actions/userSectionActions';
import { assessmentDetailsSelector } from '../../../redux/selectors/assessmentSelectors';
import { assessmentBlocksSelector } from '../../../redux/selectors/assessmentBlockSelectors';
import { partFacultySelector } from '../../../redux/selectors/partsSelectors';
import {
  scoresStepSelector,
  scoresByAssessment
} from '../../../redux/selectors/scoreSelectors';
import {
  sectionSelector,
  studentRosterSelectorBySectionUuid
} from '../../../redux/selectors/sectionsSelectors';
import DelayedScoresUploadContainer from './DelayedScoresUpload/DelayedScoresUploadContainer';
import DownloadModal from '../../Library/Modal/DownloadModal';

export default function ScoresContainer({ match, history }) {
  const dispatch = useDispatch();
  const [openUploadScores, setOpenUploadScores] = useState(false);
  const [openDownloadScores, setOpenDownloadScores] = useState(false);
  const [openUploadDelayedScores, setOpenUploadDelayedScores] = useState(false);
  const [modalDelete, setDeleteModal] = useState(false);

  const { sectionUuid, partUuid, assessmentUuid, assessmentTypeUuid } =
    match.params;

  const { path } = match;
  const { pathname } = history.location;

  const assessment = useSelector(
    state =>
      assessmentDetailsSelector(state, assessmentUuid, sectionUuid, partUuid),
    shallowEqual
  );

  const section = useSelector(
    state => sectionSelector(state, sectionUuid),
    _.isEqual
  );

  const stepScoreSummary = useSelector(
    state =>
      scoresStepSelector(
        state,
        assessmentUuid,
        sectionUuid,
        assessment.scoreType
      ),
    _.isEqual
  );

  const assessmentScores = useSelector(
    state => scoresByAssessment(state, assessmentUuid),
    _.isEqual
  );

  const assessmentBlocks = useSelector(
    state => assessmentBlocksSelector(state, assessmentUuid),
    _.isEqual
  );

  const partFacultyUsers = useSelector(
    state => partFacultySelector(state, partUuid),
    _.isEqual
  );

  const students = useSelector(state =>
    studentRosterSelectorBySectionUuid(state, sectionUuid)
  );

  const sortedBlocks = useMemo(
    () => [...assessmentBlocks].sort((a, b) => a.blockNumber - b.blockNumber),
    [assessmentBlocks]
  );

  const sortedStudents = useMemo(
    () => [...students].sort((a, b) => sortUsersByFullName(a.user, b.user)),
    [students]
  );

  const sortedFaculty = useMemo(
    () =>
      [...partFacultyUsers].sort((a, b) => sortUsersByFullName(a.user, b.user)),
    [partFacultyUsers]
  );

  useEffect(() => {
    dispatch(doGetScores(assessmentUuid, sectionUuid, partUuid));
    dispatch(doGetAssessmentBlockResults(assessmentUuid));
    dispatch(doGetAssessmentBlocks(assessmentUuid));
    dispatch(doGetStudentRoster(sectionUuid));
  }, [dispatch, assessmentUuid, sectionUuid, partUuid]);

  const handleUploadScoresOpen = () => {
    setOpenUploadScores(true);
  };

  const handleUploadClose = () => {
    setOpenUploadScores(false);
  };

  const handleUploadDelayedScoresOpen = () => {
    setOpenUploadDelayedScores(true);
  };

  const handleUploadDelayedClose = () => {
    setOpenUploadDelayedScores(false);
  };

  const handleDeleteScoresOpen = () => {
    setDeleteModal(true);
  };

  const handleDownloadScoresOpen = () => {
    setOpenDownloadScores(true);
  };

  const handleDownloadScoresClose = () => {
    setOpenDownloadScores(false);
  };

  const handleDeleteScoresClose = () => {
    setDeleteModal(false);
  };

  const handleDeleteScores = () => {
    setDeleteModal(false);
    dispatch(doDeleteScores(assessmentUuid));
  };

  return (
    <div>
      <Scores
        assessmentBlocks={sortedBlocks}
        assessmentScores={assessmentScores}
        assessmentType={assessment.assessmentType}
        assessmentTypeUuid={assessmentTypeUuid}
        assessmentUuid={assessmentUuid}
        courseUuid={section?.courseUuid}
        handleDeleteScoresOpen={handleDeleteScoresOpen}
        handleUploadScoresOpen={handleUploadScoresOpen}
        handleUploadDelayedScoresOpen={handleUploadDelayedScoresOpen}
        handleDownloadScoresOpen={handleDownloadScoresOpen}
        partFacultyUsers={sortedFaculty}
        partNumber={assessment.partNumber}
        partUuid={partUuid}
        path={path}
        pathname={pathname}
        stepScoreSummary={stepScoreSummary}
        scoreType={assessment.scoreType}
        sectionNumber={assessment.sectionNumber}
        sectionUuid={sectionUuid}
        students={sortedStudents}
        title={assessment.title}
      />
      <ScoresUploadContainer
        openUploadScores={openUploadScores}
        assessmentUuid={assessmentUuid}
        handleUploadClose={handleUploadClose}
        sectionUuid={sectionUuid}
        partUuid={partUuid}
      />
      <DelayedScoresUploadContainer
        openUploadScores={openUploadDelayedScores}
        assessmentUuid={assessmentUuid}
        handleUploadClose={handleUploadDelayedClose}
        sectionUuid={sectionUuid}
        partUuid={partUuid}
      />
      <DeleteModal
        modalOpen={modalDelete}
        type="scores"
        handleModalClose={handleDeleteScoresClose}
        handleDelete={handleDeleteScores}
      />

      {openDownloadScores &&
        <DownloadModal
          modalOpen={openDownloadScores}
          title="Download scores"
          description='Are you sure you want to download the scores under an Excel file format?'
          handleModalClose={handleDownloadScoresClose}
          downloadURL={`/api/scores/download-excel-scores?assessmentUuid=${assessmentUuid}&sectionUuid=${sectionUuid}&partUuid=${partUuid}`}
        />
      }
    </div>
  );
}

ScoresContainer.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
};

ScoresContainer.defaultProps = {
  match: {},
  history: {}
};
