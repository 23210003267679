import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CSVReader from 'react-csv-reader';
import Decimal from 'decimal.js-light';
import Chip from '@material-ui/core/Chip';
import { CircularProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonPrimary, ButtonInline } from '@xcomp/xcomp-design-library';
import Modal from '../../../Library/Modal/Modal';
import UploadMapping from '../../../Library/UploadMapping';
import UploadErrorsNew from '../../../Library/UploadErrorsNew';
import UploadSuccess from '../../../Library/UploadSuccess';
import {
  headersCognitiveProgram,
  headersCognitiveMilestone,
  headersAptitudes
} from '../../../../helpers/headers_upload/headers_transcripts';

import { GENERAL_NOTIFICATION_ERROR } from '../../../../redux/actions-type';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-top: 10px;
  width: 900px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
`;

const FileUpload = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Import = styled(ButtonPrimary)`
  && {
    margin-left: 5px;
  }
`;

const FileSelector = styled.div`
  width: 100%;
  margin-right: 16px;
`;

const ChipSC = styled(Chip)`
  && {
    background: ${props => {
      switch (props.status) {
        case 'ready': {
          return props.theme.colors.grey[500];
        }
        case 'loading': {
          return props.theme.colors.status.warning;
        }
        case 'success': {
          return props.theme.colors.status.success;
        }
        case 'failed': {
          return props.theme.colors.status.error;
        }
        default:
          return props.theme.colors.grey[500];
      }
    }};
    color: ${props => props.theme.colors.white};
  }

  margin-left: 20px;
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
`;

const LoaderMessage = styled.div`
  padding-left: 10px;
`;

const ButtonDanger = styled(ButtonPrimary)`
  && {
    margin-left: 20px;
    background: ${props => props.theme.colors.status.error};
    &:hover {
      filter: brightness(140%);
      background-color: ${props => props.theme.colors.status.error};
    }
  }
`;

const ButtonSummary = styled(ButtonPrimary)`
  && {
    margin-left: 20px;
    background: ${props => props.theme.colors.status.success};
    &:hover {
      filter: brightness(140%);
      background-color: ${props => props.theme.colors.status.success};
    }
  }
`;

const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ClearResults = styled(ButtonInline)`
  && {
    margin-left: 20px;
  }
`;

export default function TranscriptUpload({
  status,
  records,
  uploadDate,
  classifications,
  degreeLevels,
  category,
  source,
  openUpload,
  openMapping,
  summary,
  openError,
  openSummary,
  error,
  handleUploadClose,
  handleRecords,
  handleUpload,
  handleDisplayMapping,
  handleDisplaySummary,
  handleDisplayErrors,
  handleClearResults
}) {
  const dispatch = useDispatch();

  function statusMap() {
    switch (status) {
      case 'ready': {
        return 'Ready for upload';
      }
      case 'loading': {
        return (
          <Loader>
            <CircularProgress color="primary" size={20} />
            <LoaderMessage>Upload in progress</LoaderMessage>
          </Loader>
        );
      }
      case 'success': {
        return 'Recent upload complete';
      }
      case 'failed': {
        return 'Recent upload failed';
      }
      default:
        return 'Ready for upload';
    }
  }

  function ruleMapping() {
    const set = `${category}-${source}`;

    switch (set) {
      case 'Cognitive-Program':
        return [
          {
            field: 'subject',
            accepted: classifications,
            type: 'STRING',
            allowEmpty: false
          },
          {
            field: 'level',
            accepted: degreeLevels,
            type: 'STRING',
            allowEmpty: false
          },
          ...headersCognitiveProgram
        ];
      case 'Cognitive-Milestone':
        return [
          {
            field: 'subject',
            accepted: classifications,
            type: 'STRING',
            allowEmpty: false
          },
          {
            field: 'level',
            accepted: degreeLevels,
            type: 'STRING',
            allowEmpty: false
          },
          ...headersCognitiveMilestone
        ];
      case 'Non-Cognitive-Program':
        return [
          {
            field: 'skill',
            accepted: classifications,
            type: 'STRING',
            allowEmpty: false
          },
          {
            field: 'level',
            accepted: degreeLevels,
            type: 'STRING',
            allowEmpty: false
          },
          ...headersAptitudes
        ];
      case 'Non-Cognitive-Milestone':
        return [
          {
            field: 'skill',
            accepted: classifications,
            type: 'STRING',
            allowEmpty: false
          },
          {
            field: 'level',
            accepted: degreeLevels,
            type: 'STRING',
            allowEmpty: false
          },
          ...headersAptitudes
        ];
      case 'Physical-Skills-Program':
        return [
          {
            field: 'skill',
            accepted: classifications,
            type: 'STRING',
            allowEmpty: false
          },
          {
            field: 'level',
            accepted: degreeLevels,
            type: 'STRING',
            allowEmpty: false
          },
          ...headersAptitudes
        ];
      case 'Physical-Skills-Milestone':
        return [
          {
            field: 'skill',
            accepted: classifications,
            type: 'STRING',
            allowEmpty: false
          },
          {
            field: 'level',
            accepted: degreeLevels,
            type: 'STRING',
            allowEmpty: false
          },
          ...headersAptitudes
        ];
      case 'Communication-Program':
        return [
          {
            field: 'skill',
            accepted: classifications,
            type: 'STRING',
            allowEmpty: false
          },
          {
            field: 'level',
            accepted: degreeLevels,
            type: 'STRING',
            allowEmpty: false
          },
          ...headersAptitudes
        ];
      case 'Communication-Milestone':
        return [
          {
            field: 'skill',
            accepted: classifications,
            type: 'STRING',
            allowEmpty: false
          },
          {
            field: 'level',
            accepted: degreeLevels,
            type: 'STRING',
            allowEmpty: false
          },
          ...headersAptitudes
        ];
      default:
        break;
    }
  }

  function csvManipulate(value, column) {
    if (column === 'grade' && `${category}-${source}` !== 'Cognitive-Program') {
      try {
        const formatGrade = new Decimal(value).toFixed(1).toString();
        return formatGrade;
      } catch (error) {
        dispatch({
          type: GENERAL_NOTIFICATION_ERROR,
          payload: {
            error: 'Field grade for exams can only contains decimal values.'
          }
        });
      }
    }

    if (column === 'subject') {
      return value.trim();
    }

    return value;
  }

  return (
    <Modal open={openUpload} onClose={handleUploadClose} maxWidth="xl">
      <ModalBody>
        <ModalHeader>
          <Title>
            Upload Transcript for {category} {source} (CSV)
            <ChipSC status={status} label={statusMap()} />
          </Title>
          <CloseModal>
            <IconButton onClick={handleUploadClose}>
              <CloseIcon />
            </IconButton>
          </CloseModal>
        </ModalHeader>
        <FileUpload>
          <FileSelector>
            <CSVReader
              onFileLoaded={handleRecords}
              parserOptions={{
                header: true,
                transform: csvManipulate,
                skipEmptyLines: 'greedy'
              }}
              inputStyle={{
                width: '100%',
                color: 'black',
                background: 'lightgrey',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                borderRadius: '5px'
              }}
            />
          </FileSelector>
          <Import
            disabled={status === 'loading' || !records || records.length === 0}
            onClick={handleUpload}
          >
            Import
          </Import>
        </FileUpload>
        <Actions>
          <div>
            <ButtonPrimary onClick={handleDisplayMapping}>
              {openMapping ? `Hide Rules` : `Rules`}
            </ButtonPrimary>
            {status === 'success' && (
              <ButtonSummary onClick={handleDisplaySummary}>
                {openSummary ? `Hide Results` : `Results`}
              </ButtonSummary>
            )}
            {status === 'failed' && (
              <ButtonDanger onClick={handleDisplayErrors}>Errors</ButtonDanger>
            )}
            {(status === 'failed' || status === 'success') && (
              <ClearResults onClick={handleClearResults}>
                Clear Results
              </ClearResults>
            )}
          </div>
        </Actions>
        <UploadMapping display={openMapping} fields={ruleMapping()} />
        <UploadErrorsNew
          show={status === 'failed' && openError}
          error={error}
          uploadDate={uploadDate}
        />
        <UploadSuccess
          show={status === 'success' && openSummary}
          summary={summary}
          uploadDate={uploadDate}
        />
      </ModalBody>
    </Modal>
  );
}

TranscriptUpload.propTypes = {
  records: PropTypes.array,
  summary: PropTypes.object,
  status: PropTypes.string,
  error: PropTypes.object,
  classifications: PropTypes.string,
  degreeLevels: PropTypes.string,
  category: PropTypes.string,
  source: PropTypes.string,
  openUpload: PropTypes.bool,
  openMapping: PropTypes.bool,
  openError: PropTypes.bool,
  openSummary: PropTypes.bool,
  uploadDate: PropTypes.string,
  handleUploadClose: PropTypes.func,
  handleRecords: PropTypes.func,
  handleUpload: PropTypes.func,
  handleDisplayMapping: PropTypes.func,
  handleDisplayErrors: PropTypes.func,
  handleDisplaySummary: PropTypes.func,
  handleClearResults: PropTypes.func
};

TranscriptUpload.defaultProps = {
  records: undefined,
  summary: undefined,
  status: 'Ready for upload',
  error: undefined,
  classifications: undefined,
  degreeLevels: undefined,
  category: undefined,
  source: undefined,
  openUpload: false,
  openMapping: false,
  openError: false,
  openSummary: false,
  uploadDate: '',
  handleUploadClose: undefined,
  handleRecords: undefined,
  handleUpload: undefined,
  handleDisplayMapping: undefined,
  handleDisplayErrors: undefined,
  handleDisplaySummary: undefined,
  handleClearResults: undefined
};
