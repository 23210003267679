import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import {
  EditIcon,
  DocumentIcon,
  InstituteIdIcon
} from '@xcomp/xcomp-design-library/dist/icons';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import LazyLoad from 'react-lazyload';
import Grid from '@material-ui/core/Grid';
import { ContainerPage, ElevateLow } from '../../Library/Layout';
import CohortStudentStatusFilter from './CohortStudentStatusFilter';
import { rolesMap, cohortRoleStatusMap } from '../../../helpers/constants';
import {
  formatLastName,
  formatFirstNameMiddleName
} from '../../../helpers/format/user.format';
import ThirdPartyId from '../CohortApplicant/ThirdParty/ThirdPartyId';
import { useQuery } from '../../../helpers/hooks/router.hooks';

const StatusCount = styled.div`
  margin-top: 20px;
  background: ${props => props.theme.colors.white};
  padding: 20px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Edit = styled.div`
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
`;

const RoleBox = styled.div``;

const SelectAllBox = styled.div`
  display: flex;
  align-items: center;
`;

const SelectLabel = styled.div`
  padding-right: 10px;
`;

const ChipSC = styled(Chip)`
  && {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
  }
`;

const TranscriptBox = styled.div`
  display: flex;
  align-items: center;
`;

const InstituteIdIconSC = styled(InstituteIdIcon)`
  && {
    padding-right: 5px;
  }
`;

const Row = styled.div`
  font-size: 14px;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
`;

export default function CohortStudent({
  cohortUsers,
  cohortUsersStatusCount,
  selectAll,
  filterStatus,
  handleSelectAll,
  handleDeselectAll,
  handleSingleSelect,
  handleFilterStatus,
  isAdmin
}) {
  const query = useQuery();
  const programUuid = query.get('programUuid');

  return (
    <ContainerPage paddingTop="40px">
      <StatusCount>
        <ChipSC label={`${cohortUsersStatusCount.activeCount} Active`} />
        <ChipSC label={`${cohortUsersStatusCount.completeCount} Complete`} />
        <ChipSC label={`${cohortUsersStatusCount.inactiveCount} Inactive`} />
        <ChipSC label={`${cohortUsersStatusCount.delayedCount}  Delayed`} />
        <ChipSC label={`${cohortUsersStatusCount.competentCount} Competent`} />
        <ChipSC label={`${cohortUsersStatusCount.withdrawnCount} Withdrawn`} />
        <ChipSC
          label={`${cohortUsersStatusCount.transferredCount} Transferred`}
        />
      </StatusCount>
      <ElevateLow>
        <Row>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item sm={2} xs={2}>
              Institution ID
            </Grid>
            <Grid item sm={isAdmin ? 3 : 4} xs={isAdmin ? 3 : 4}>
              Name
            </Grid>
            {isAdmin &&
              <Grid item sm={1} xs={1}>
                Edit
              </Grid>
            }
            <Grid item sm={1} xs={1}>
              <CohortStudentStatusFilter
                filterStatus={filterStatus}
                handleFilterStatus={handleFilterStatus}
              />
            </Grid>
            <Grid item sm={1} xs={1}>
              Role
            </Grid>
            <Grid item sm={isAdmin ? 1 : 2} xs={isAdmin ? 1 : 2}>
              <TranscriptBox>3RD ID</TranscriptBox>
            </Grid>
            <Grid item sm={1} xs={1}>
              <TranscriptBox>Transcript</TranscriptBox>
            </Grid>
            {isAdmin &&         
              <Grid item sm={1} xs={1}>
                <SelectAllBox>
                  {selectAll ? (
                    <>
                      <SelectLabel>Deselect</SelectLabel>
                      <Checkbox
                        checked={selectAll}
                        onChange={() => handleDeselectAll()}
                        color="primary"
                      />
                    </>
                  ) : (
                    <>
                      <SelectLabel>Select</SelectLabel>
                      <Checkbox
                        checked={selectAll}
                        onChange={() => handleSelectAll()}
                        color="primary"
                      />
                    </>
                  )}
                </SelectAllBox>
              </Grid>
            }
          </Grid>
        </Row>

        {cohortUsers?.map(cohortUser => (
          <LazyLoad key={cohortUser.uuid}>
            <Row>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item sm={2} xs={2}>
                  {cohortUser.institutionId}
                </Grid>
                <Grid item sm={isAdmin ? 3 : 4} xs={isAdmin ? 3 : 4}>
                  {`${formatLastName(
                    cohortUser?.lastName,
                    cohortUser?.marriedLastName,
                    cohortUser?.suffix
                  )},
                  ${formatFirstNameMiddleName(
                    cohortUser?.firstName,
                    cohortUser?.middleName
                  )}`}
                </Grid>
                {isAdmin &&
                  <Grid item sm={1} xs={1}>
                    <Edit>
                      <Link
                        to={`/program-management/cohort/${cohortUser.cohortUuid}/user/${cohortUser.userUuid}/edit`}
                      >
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Edit>
                  </Grid>
                }
                <Grid item sm={1} xs={1}>
                  {cohortRoleStatusMap[cohortUser.status]}
                </Grid>
                <Grid item sm={1} xs={1}>
                  <RoleBox>{rolesMap[cohortUser.role]}</RoleBox>
                </Grid>
                <Grid item sm={isAdmin ? 1 : 2} xs={isAdmin ? 1 : 2}>
                  <TranscriptBox>
                    <InstituteIdIconSC />
                    <ThirdPartyId userUuid={cohortUser?.userUuid} />
                  </TranscriptBox>
                </Grid>
                <Grid item sm={1} xs={1}>
                  <TranscriptBox>
                    <Link
                      to={`/program-management/cohort/student-transcript?programUuid=${programUuid}&cohortUuid=${cohortUser.cohortUuid}&userUuid=${cohortUser.userUuid}`}
                    >
                      <IconButton>
                        <DocumentIcon />
                      </IconButton>
                    </Link>
                  </TranscriptBox>
                </Grid>
                {isAdmin &&
                  <Grid item sm={1} xs={1}>
                    <SelectAllBox>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cohortUser.selected}
                            onChange={() => handleSingleSelect(cohortUser.uuid)}
                            color="primary"
                          />
                        }
                      />
                    </SelectAllBox>
                  </Grid>
                }
              </Grid>
            </Row>
          </LazyLoad>
        ))}
      </ElevateLow>
    </ContainerPage>
  );
}

CohortStudent.propTypes = {
  cohortUsers: PropTypes.array,
  cohortUsersStatusCount: PropTypes.object,
  selectAll: PropTypes.bool,
  filterStatus: PropTypes.object,
  handleSelectAll: PropTypes.func,
  handleDeselectAll: PropTypes.func,
  handleFilterStatus: PropTypes.func,
  handleSingleSelect: PropTypes.func,
  isAdmin: PropTypes.bool,
};
CohortStudent.defaultProps = {
  cohortUsers: [],
  cohortUsersStatusCount: {},
  selectAll: false,
  filterStatus: undefined,
  handleSelectAll: undefined,
  handleDeselectAll: undefined,
  handleFilterStatus: undefined,
  handleSingleSelect: undefined,
  isAdmin: false,
};
