import { Model, attr, fk } from 'redux-orm';

import {
  GET_CLASSIFICATIONS_SUCCESS,
  GET_SUBJECTS_SUCCESS,
  POST_MILESTONES_SUCCESS,
  GET_TRANSCRIPT_SUCCESS
} from '../actions-type';

class ConversionMilestone extends Model {
  static reducer(action, ConversionMilestone, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_CLASSIFICATIONS_SUCCESS: {
        const classifications = [...payload.classifications];
        classifications.forEach(classification => {
          if (classification.category === 'Cognitive') {
            if (classification.exam_milestone) {
              if (
                classification.exam_milestone.conversion_milestones &&
                classification.exam_milestone.conversion_milestones.length > 0
              ) {
                classification.exam_milestone.conversion_milestones.forEach(
                  conversion => {
                    ConversionMilestone.upsert(conversion);
                  }
                );
              }
            }
          }
        });
        break;
      }

      case GET_TRANSCRIPT_SUCCESS: {
        const classifications = [...payload.classifications];
        classifications.forEach(classification => {
          if (classification.category === 'Cognitive') {
            if (classification?.exam_milestone) {
              if (
                classification?.exam_milestone?.conversion_milestones?.length >
                0
              ) {
                classification.exam_milestone.conversion_milestones.forEach(
                  conversion => {
                    ConversionMilestone.upsert(conversion);
                  }
                );
              }
            }
          }
        });
        break;
      }

      case GET_SUBJECTS_SUCCESS: {
        const subjects = [...payload.subjects];
        subjects.forEach(classification => {
          if (classification.exam_milestone) {
            if (
              classification.exam_milestone.conversion_milestones &&
              classification.exam_milestone.conversion_milestones.length > 0
            ) {
              const copy = [
                ...classification.exam_milestone.conversion_milestones
              ];

              copy.forEach(conversion =>
                ConversionMilestone.upsert(conversion)
              );
            }
          }
        });
        break;
      }

      case POST_MILESTONES_SUCCESS: {
        const milestones = [...payload.milestones];
        milestones.forEach(milestone => {
          milestone.conversion_milestones.forEach(conversion =>
            ConversionMilestone.upsert(conversion)
          );
        });

        const conversionsRemove = [...payload.conversionsRemove];

        if (conversionsRemove?.length > 0) {
          conversionsRemove.forEach(conversion =>
            ConversionMilestone(conversion.uuid).delete()
          );
        }
        break;
      }

      default:
        return session;
    }
  }
}

ConversionMilestone.fields = {
  uuid: attr(),
  scaledScore: attr(),
  percentage: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  examMilestoneUuid: fk({
    to: 'ExamMilestone',
    as: 'examMilestone',
    relatedName: 'conversion_milestones'
  })
};

ConversionMilestone.modelName = 'ConversionMilestone';

ConversionMilestone.options = {
  idAttribute: 'uuid'
};

export default ConversionMilestone;
