import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { selectUser } from '../../../../redux/selectors/usersSelectors';
import {
  validateInputString,
  validateSelectDate
} from '../../../../helpers/validation/validateGeneric';
import DeleteModal from '../../../Library/Modal/DeleteModal/DeleteModal';

import { selectInstitutionIdType } from '../../../../redux/selectors/sourceSelectors';
import { selectInstitution } from '../../../../redux/selectors/institutionSelectors';

import {
  doGetUser,
  doPutUser,
  doDeleteUser
} from '../../../../redux/actions/userActions';

import UserEdit from './UserEdit';

export default function UserEditContainer({ userUuid }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(undefined);
  const [middleName, setMiddleName] = useState(undefined);
  const [lastName, setLastName] = useState(undefined);
  const [marriedLastName, setMarriedLastName] = useState(undefined);
  const [suffix, setSuffix] = useState(undefined);
  const [institutionIdTypeUuid, setInstitutionIdTypeUuid] = useState(undefined);
  const [institutionId, setInstitutionId] = useState(undefined);
  const [textMessage, setTextMessage] = useState(false);
  const [birthDate, setBirthDate] = useState(null);
  const [email, setEmail] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [ethnicity, setEthnicity] = useState(undefined);
  const [gender, setGender] = useState(undefined);
  const [notes, setNotes] = useState(undefined);
  const [hasErrors, setHasErrors] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const institution = useSelector(state => selectInstitution(state), _.isEqual);
  const institutionUuid = _.get(institution, ['0', 'uuid']);

  const user = useSelector(state => selectUser(state, userUuid), _.isEqual);

  const institutionIdTypes = useSelector(state =>
    selectInstitutionIdType(state)
  );

  const optionsInstitutionIdTypes = _.map(
    institutionIdTypes,
    institutionIdType => ({
      label: institutionIdType.name,
      value: institutionIdType.uuid
    })
  );

  useEffect(() => {
    if (userUuid) {
      dispatch(doGetUser(userUuid));
    }
  }, [dispatch, userUuid]);

  useEffect(() => {
    if (user) {
      setFirstName(user?.firstName);
      setMiddleName(user?.middleName);
      setLastName(user?.lastName);
      setMarriedLastName(user?.marriedLastName);
      setSuffix(user?.suffix);
      setInstitutionIdTypeUuid(user?.institutionIdTypeUuid);
      setInstitutionId(user?.institutionId);
      setTextMessage(user?.textMessage);
      setBirthDate(user?.birthDate);
      setPhone(user?.phone);
      setEmail(user?.email);
      setEthnicity(user?.ethnicity);
      setGender(user?.gender);
      setNotes(user?.notes);
    }
  }, [user]);

  const handleChange = event => {
    const { name, value } = event.target;

    switch (name) {
      case 'firstName': {
        setFirstName(value);
        break;
      }
      case 'middleName': {
        setMiddleName(value);
        break;
      }
      case 'lastName': {
        setLastName(value);
        break;
      }
      case 'marriedLastName': {
        setMarriedLastName(value);
        break;
      }
      case 'suffix': {
        setSuffix(value);
        break;
      }
      case 'institutionIdTypeUuid': {
        setInstitutionIdTypeUuid(value);
        break;
      }
      case 'institutionId': {
        setInstitutionId(value);
        break;
      }
      case 'email': {
        setEmail(value);
        break;
      }
      case 'phone': {
        setPhone(value);
        break;
      }
      case 'ethnicity': {
        setEthnicity(value);
        break;
      }
      case 'gender': {
        setGender(value);
        break;
      }
      case 'textMessage': {
        setTextMessage(value);
        break;
      }
      case 'notes': {
        setNotes(value);
        break;
      }
      default:
        break;
    }
  };

  const handleBirthDate = (indentifier, field, date) => {
    setBirthDate(date);
  };

  const handleToggleText = () => {
    setTextMessage(!textMessage);
  };

  const validation = () => {
    let containErrors = false;

    const institutionIdTypeUuidError = validateInputString(
      institutionIdTypeUuid
    );
    const institutionIdError = validateInputString(institutionId);
    const firstNameError = validateInputString(firstName);
    const lastNameError = validateInputString(lastName);
    const birthDateError = validateSelectDate(birthDate);
    const ethnicityError = validateInputString(ethnicity);
    const genderError = validateInputString(gender);

    if (
      institutionIdTypeUuidError.invalid ||
      institutionIdError.invalid ||
      firstNameError.invalid ||
      lastNameError.invalid ||
      birthDateError.invalid ||
      ethnicityError.invalid ||
      genderError.invalid
    ) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleUpdateUser = () => {
    const reject = validation();
    if (reject) {
      setHasErrors(true);
    } else {
      const payload = {
        uuid: user.uuid,
        institutionIdTypeUuid,
        institutionId,
        firstName,
        middleName,
        lastName,
        marriedLastName,
        suffix,
        email,
        birthDate: moment(birthDate).format('YYYY-MM-DD'),
        phone,
        ethnicity,
        gender,
        notes,
        textMessage
      };

      dispatch(doPutUser(payload, institutionUuid));
      setHasErrors(false);
    }
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };

  const handleDelete = () => {
    dispatch(doDeleteUser(user.uuid, institutionUuid));
    setDeleteModal(false);
    history.push('/user-management/dashboard/tab/view');
  };

  return (
    <>
      <UserEdit
        firstName={firstName}
        middleName={middleName}
        lastName={lastName}
        marriedLastName={marriedLastName}
        suffix={suffix}
        institutionIdTypeUuid={institutionIdTypeUuid}
        institutionId={institutionId}
        textMessage={textMessage}
        birthDate={birthDate}
        email={email}
        phone={phone}
        ethnicity={ethnicity}
        gender={gender}
        notes={notes}
        optionsInstitutionIdTypes={optionsInstitutionIdTypes}
        hasErrors={hasErrors}
        handleChange={handleChange}
        handleToggleText={handleToggleText}
        handleUpdateUser={handleUpdateUser}
        handleBirthDate={handleBirthDate}
        handleDeleteModalOpen={handleDeleteModalOpen}
      />
      <DeleteModal
        type="user"
        modalOpen={deleteModal}
        handleModalClose={handleDeleteModalClose}
        handleDelete={handleDelete}
      />
    </>
  );
}

UserEditContainer.propTypes = {
  userUuid: PropTypes.string
};

UserEditContainer.defaultProps = {
  userUuid: undefined
};
