import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DocumentIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { Link } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withStyles
} from '@material-ui/core';

import AcademicYear from '../Data/AcademicYear';
import CourseMaster from '../Data/CourseMaster';
import GradeResultScore from '../Calculations/GradeResultScore';
import GradeResultPartScore from '../Calculations/GradeResultPartScore';
import GradeResultGrade from '../Calculations/GradeResultGrade';
import GradeResultPartGrade from '../Calculations/GradeResultPartGrade';
import Hours from '../Data/Hours';
import StudentStatus from '../Calculations/StudentStatus';

const Data = styled.div`
  padding-bottom: 20px;
  margin-right: 10px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PartRow = styled.div``;
const PartName = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    background: ${props => props.theme.colors.grey[100]};
  }
`;

const StyledTableCell = withStyles(() => ({
  body: {
    fontSize: 12,
    width: '10px',
    alignItems: 'flex-start'
  }
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    verticalAlign: 'top'
  }
}))(TableRow);

const CoursesTable = ({ courses, userUuid, handleClick }) => {
  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Syllabus</StyledTableCell>
              <StyledTableCell>Year</StyledTableCell>
              <StyledTableCell>Course</StyledTableCell>
              <StyledTableCell>Section/Part</StyledTableCell>
              <StyledTableCell>Score</StyledTableCell>
              <StyledTableCell>Grade</StyledTableCell>
              <StyledTableCell>Credits</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map(course => (
              <StyledTableRow key={course.name} style={{ maxWidth: '20px' }}>
                <StyledTableCell component="th" scope="row">
                  <Box
                    onClick={() =>
                      handleClick(
                        course?.courseMaster?.description,
                        `${course?.courseMaster?.courseNumber} - ${course?.courseMaster?.title}`
                      )
                    }
                  >
                    <DocumentIcon />
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <AcademicYear academicYearUuid={course.academicYearUuid} />
                </StyledTableCell>
                <StyledTableCell style={{ maxWidth: '200px' }}>
                  <CourseMaster courseMasterUuid={course.courseMasterUuid} />
                </StyledTableCell>
                <StyledTableCell style={{ maxWidth: '350px' }}>
                  <Data key={course?.section?.uuid}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px'
                      }}
                    >
                      {course?.section?.description && (
                        <Box
                          onClick={() =>
                            handleClick(
                              course?.section?.description,
                              `Section ${course?.section?.sectionNumber}`
                            )
                          }
                        >
                          <DocumentIcon />
                        </Box>
                      )}
                      <Data>Section {course?.section?.sectionNumber}</Data>
                    </Box>
                    {course?.section?.parts
                      ?.sort((a, b) => a?.partNumber - b?.partNumber)
                      ?.map(part => (
                        <PartRow key={part?.uuid}>
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '16px'
                            }}
                          >
                            {part?.description && (
                              <Box
                                onClick={() =>
                                  handleClick(
                                    part?.description,
                                    `Part ${part?.partNumber} - ${part?.title}`
                                  )
                                }
                              >
                                <DocumentIcon />
                              </Box>
                            )}
                            <Data>
                              <Tooltip
                                title={`Part ${part?.partNumber} - ${part?.title} (${part?.weight}%)`}
                                placement="top"
                              >
                                <PartName>
                                  <Link
                                    to={`/program-management/cohort/syllabus-part?userUuid=${userUuid}&courseUuid=${course.uuid}&partUuid=${part.uuid}`}
                                  >
                                    <span
                                      style={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap'
                                      }}
                                    >
                                      Part {part?.partNumber} - {part?.title}
                                    </span>
                                    <span>({part?.weight} %)</span>
                                  </Link>
                                </PartName>
                              </Tooltip>
                            </Data>
                          </Box>
                        </PartRow>
                      ))}
                  </Data>
                </StyledTableCell>
                <StyledTableCell>
                  <Data key={course?.section?.uuid}>
                    <Data>
                      <GradeResultScore
                        userUuid={userUuid}
                        sectionUuid={course?.section?.uuid}
                      />
                    </Data>

                    {course?.section?.parts
                      ?.sort((a, b) => a.partNumber - b.partNumber)
                      .map(part => (
                        <PartRow key={part?.uuid}>
                          <Data>
                            <GradeResultPartScore
                              userUuid={userUuid}
                              partUuid={part?.uuid}
                            />
                          </Data>
                        </PartRow>
                      ))}
                  </Data>
                </StyledTableCell>
                <StyledTableCell>
                  <Data>
                    <GradeResultGrade
                      userUuid={userUuid}
                      sectionUuid={course?.section?.uuid}
                    />
                  </Data>

                  {course?.section?.parts?.map(part => (
                    <PartRow key={part?.uuid}>
                      <Data>
                        <GradeResultPartGrade
                          userUuid={userUuid}
                          partUuid={part?.uuid}
                        />
                      </Data>
                    </PartRow>
                  ))}
                </StyledTableCell>
                <StyledTableCell>
                  <Hours courseMasterUuid={course?.courseMasterUuid} />
                </StyledTableCell>
                <StyledTableCell>
                  <Data key={course?.section?.uuid}>
                    <Data>- -</Data>

                    {course?.section?.parts?.map(part => (
                      <PartRow key={part?.uuid}>
                        <Data>
                          <StudentStatus
                            userUuid={userUuid}
                            sectionUuid={course?.section?.uuid}
                          />
                        </Data>
                      </PartRow>
                    ))}
                  </Data>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

CoursesTable.propTypes = {
  userUuid: PropTypes.string,
  courses: PropTypes.array,
  handleClick: PropTypes.func
};

CoursesTable.defaultProps = {
  userUuid: undefined,
  courses: [],
  handleClick: () => {}
};

export default CoursesTable;
