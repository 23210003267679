import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Decimal from 'decimal.js-light';

import {
  cognitiveMilestoneReducer,
  cognitiveMilestoneInitial
} from './cognitiveMilestoneState';

import CognitiveMilestone from './CognitiveMilestone';
import { userSelector } from '../../../../../redux/selectors/usersSelectors';
import {
  classificationsSelector,
  selectMilestone
} from '../../../../../redux/selectors/classificationSelectors';
import { degreeLevelsSelector } from '../../../../../redux/selectors/degreeLevelsSelectors';
import { selectLetterGradesBySchool } from '../../../../../redux/selectors/letterGradeSelectors';
import { programSelector } from '../../../../../redux/selectors/programSelectors';
import {
  validateSelectDate,
  validateSelectField,
  validateInputString,
  validateInputNumber
} from '../../../../../helpers/validation/validateGeneric';
import { doPostTranscript } from '../../../../../redux/actions/applicantTranscriptActions';
import { selectMilestoneConversions } from '../../../../../redux/selectors/examMilestoneSelectors';
import { selectConversionMilestone } from '../../../../../redux/selectors/conversionMilestoneSelectors';

import { alphaNumSorted } from '../../../../../helpers/utilities';

export default function CognitiveMilestoneAddContainer({
  formType,
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  modalOpen,
  handleModalClose
}) {
  const dispatch = useDispatch();
  const [itemAge, setItemAge] = useState('0.0');

  const [hasErrors, setHasErrors] = useState(false);
  const [transcriptState, transcriptDispatch] = useReducer(
    cognitiveMilestoneReducer,
    cognitiveMilestoneInitial
  );

  const {
    attempt,
    date,
    applicationDate,
    grade,
    termType,
    term,
    year,
    institutionCode,
    sourceDegreeLevelUuid,
    classificationUuid,
    sourceInstitutionUuid,
    conversionMilestoneUuid
  } = transcriptState;

  const user = useSelector(state => userSelector(state, userUuid));
  const program = useSelector(state => programSelector(state, programUuid));

  const letterGrades = useSelector(state =>
    selectLetterGradesBySchool(state, program.schoolUuid)
  );

  const letterGradeOptions = letterGrades.map(level => ({
    label: level.grade,
    value: level.grade
  }));

  const mapGrades = _(letterGrades)
    .map(record => ({
      ...record,
      rangeLow: Number(record.rangeLow),
      rangeHigh: Number(record.rangeHigh)
    }))
    .value();

  const degreeLevelOptions = useSelector(state =>
    degreeLevelsSelector(state)
  ).map(level => ({ label: level.degreeLevel, value: level.uuid }));

  const classificationOptions = useSelector(state =>
    classificationsSelector(state, programUuid, category, 'Program')
  ).map(classification => ({
    label: classification.scope,
    value: classification.uuid
  }));

  const { createTranscript, createTranscriptSuccess } = useSelector(
    state => state.crudState
  );

  const examMilestone = useSelector(
    state => selectMilestone(state, classificationUuid),
    _.isEqual
  );

  const conversions = useSelector(state => {
    if (examMilestone.uuid) {
      return selectMilestoneConversions(state, examMilestone.uuid);
    }
  }, _.isEqual);

  const conversionOptions =
    conversions?.length > 0
      ? alphaNumSorted(conversions, 'scaledScore', 'percentage').map(exam => ({
          label: exam.scaledScore,
          value: exam.uuid
        }))
      : [];

  const conversion = useSelector(
    state => selectConversionMilestone(state, conversionMilestoneUuid),
    _.isEqual
  );

  const findGrade = _.find(mapGrades, schoolGrade => {
    if (conversion?.percentage) {
      const low = new Decimal(schoolGrade.rangeLow);
      const high = new Decimal(schoolGrade.rangeHigh);

      const conversionPercentage = new Decimal(conversion.percentage);

      const conditionOne = conversionPercentage.greaterThanOrEqualTo(low);
      const conditionTwo = conversionPercentage.lessThanOrEqualTo(high);

      return conditionOne && conditionTwo;
    } else {
      return false;
    }
  });

  useEffect(() => {
    if (!modalOpen) {
      setHasErrors(false);
      transcriptDispatch({
        type: 'reset'
      });
    }
  }, [dispatch, modalOpen]);

  useEffect(() => {
    if (applicationDate && date) {
      const app = moment.utc(applicationDate);
      const historical = moment.utc(date);
      const days = moment.duration(app.diff(historical)).asDays();
      const itemage = days / 365;
      const format = new Decimal(itemage).toFixed(2).toString();
      setItemAge(format);
    }
  }, [date, applicationDate]);

  useEffect(() => {
    if (createTranscriptSuccess) {
      handleModalClose();
    }
  }, [dispatch, createTranscriptSuccess, handleModalClose]);

  const handleChangeDate = (_, field, date) => {
    transcriptDispatch({ type: field, date });
  };

  const handleChange = event => {
    const { name, value } = event.target;

    transcriptDispatch({ type: name, value });
  };

  const handleSourceInstitutionUuid = institution => {
    const sourceInstitutionUuid = institution?.uuid;
    const institutionCode = institution?.institutionCode;

    transcriptDispatch({
      type: 'sourceInstitutionUuid',
      sourceInstitutionUuid,
      institutionCode
    });
  };

  const _validate = () => {
    let containErrors = false;

    const attemptError = validateInputNumber(attempt);
    const applicationDateError = validateSelectDate(applicationDate);
    const dateError = validateSelectDate(date);

    const sourceDegreeLevelUuidError = validateSelectField(
      sourceDegreeLevelUuid
    );
    const classificationUuidError = validateSelectField(classificationUuid);
    const sourceInstitutionUuidError = validateInputString(
      sourceInstitutionUuid
    );
    const conversionMilestoneUuidError = validateSelectField(
      conversionMilestoneUuid
    );

    if (
      attemptError.invalid ||
      applicationDateError.invalid ||
      dateError.invalid ||
      sourceDegreeLevelUuidError.invalid ||
      classificationUuidError.invalid ||
      sourceInstitutionUuidError.invalid ||
      conversionMilestoneUuidError.invalid
    ) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleRequest = () => {
    const settings = {
      userUuid,
      cohortUuid,
      programUuid,
      category,
      source
    };

    const record = {
      attempt,
      applicationDate: moment(applicationDate).format('YYYY-MM-DD'),
      classificationUuid,
      credits: examMilestone.credits,
      date: moment(date).format('YYYY-MM-DD'),
      year: moment(year).format('YYYY'),
      term,
      termType: 'Semester',
      itemCode: examMilestone.examId,
      itemName: examMilestone.examName,
      institutionCode,
      sourceDegreeLevelUuid,
      sourceInstitutionUuid,
      examMilestoneUuid: examMilestone.uuid,
      conversionMilestoneUuid,
      grade: findGrade.grade,
      score: conversion.percentage
    };

    const check = _validate();

    if (check) {
      setHasErrors(true);
    } else {
      dispatch(doPostTranscript({ ...settings, record }));
    }
  };

  return (
    <CognitiveMilestone
      attempt={Number(attempt)}
      formType={formType}
      loading={createTranscript}
      user={user}
      category={category}
      source={source}
      applicationDate={applicationDate}
      termType={termType}
      term={term}
      date={date}
      year={year}
      itemAge={itemAge}
      itemCode={examMilestone.examId}
      itemName={examMilestone.examName}
      sourceDegreeLevelUuid={sourceDegreeLevelUuid}
      classificationUuid={classificationUuid}
      sourceInstitutionUuid={sourceInstitutionUuid}
      conversionMilestoneUuid={conversionMilestoneUuid}
      credits={examMilestone.credits}
      grade={grade}
      score={conversion.percentage}
      modalOpen={modalOpen}
      degreeLevelOptions={degreeLevelOptions}
      classificationOptions={classificationOptions}
      letterGradeOptions={letterGradeOptions}
      conversionOptions={conversionOptions}
      hasErrors={hasErrors}
      handleModalClose={handleModalClose}
      handleChangeDate={handleChangeDate}
      handleChange={handleChange}
      handleRequest={handleRequest}
      handleSourceInstitutionUuid={handleSourceInstitutionUuid}
    />
  );
}

CognitiveMilestoneAddContainer.propTypes = {
  formType: PropTypes.string,
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  modalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func
};

CognitiveMilestoneAddContainer.defaultProps = {
  formType: 'Add',
  category: 'Cognitive',
  source: 'Milestone',
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  modalOpen: false,
  handleModalClose: undefined
};
