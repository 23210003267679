import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { selectCourseMaster } from '../../../../redux/selectors/coursesMasterSelectors';

const Input = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default function CourseMaster({ courseMasterUuid }) {
  const courseMaster = useSelector(state =>
    selectCourseMaster(state, courseMasterUuid)
  );

  return (
    <Tooltip
      title={`${courseMaster.courseNumber} - ${courseMaster.title}`}
      placement="top"
    >
      <Input>{`${courseMaster.courseNumber} - ${courseMaster.title}`}</Input>
    </Tooltip>
  );
}

CourseMaster.propTypes = {
  courseMasterUuid: PropTypes.string
};

CourseMaster.defaultProps = {
  courseMasterUuid: undefined
};
