const initialCohortAssessmentCollectionState = {
  uuid: '',
  title: '',
  haveFetchedCohorts: false,
  haveFetchedCohortAssessmentCollections: false,
  showValidationErrors: false
};

const cohortAssessmentCollectionReducer = (state, action) => {
  switch (action.type) {
    case 'setExistingCohortAssessmentCollection': {
      const { uuid, title } = action.payload;

      return {
        ...state,
        uuid,
        title
      };
    }
    case 'setTitle':
      return { ...state, title: action.payload.title };
    case 'setShowValidationErrors':
      return {
        ...state,
        showValidationErrors: action.payload.showValidationErrors
      };
    case 'setHaveFetchedCohorts':
      return {
        ...state,
        haveFetchedCohorts: true
      };
    case 'setHaveFetchedCohortAssessmentCollections':
      return {
        ...state,
        haveFetchedCohortAssessmentCollections: true
      };
    case 'clearForm': {
      const newState = {
        ...initialCohortAssessmentCollectionState,
        courses: {}
      };

      return newState;
    }
    default:
      throw new Error();
  }
};

const setTitle = title => ({ type: 'setTitle', payload: { title } });

const setShowValidationErrors = showValidationErrors => ({
  type: 'setShowValidationErrors',
  payload: { showValidationErrors }
});

const setHaveFetchedCohorts = () => ({
  type: 'setHaveFetchedCohorts'
});

const setHaveFetchedCohortAssessmentCollections = () => ({
  type: 'setHaveFetchedCohortAssessmentCollections'
});

export {
  initialCohortAssessmentCollectionState,
  cohortAssessmentCollectionReducer,
  setTitle,
  setShowValidationErrors,
  setHaveFetchedCohorts,
  setHaveFetchedCohortAssessmentCollections
};
