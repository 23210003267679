import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import TranscriptUploadContainer from '../../TranscriptUpload/TranscriptUploadContainer';
import ByDefaultCognitiveContainer from './ByDefaultCognitiveContainer';
import ByDefaultAptitudeContainer from './ByDefaultAptitudeContainer';
import DeleteModal from '../../../../Library/Modal/DeleteModal/DeleteModal';
import CognitiveProgramAddContainer from '../../TranscriptForms/CognitiveProgram/CognitiveProgramAddContainer';
import CognitiveMilestoneAddContainer from '../../TranscriptForms/CognitiveMilestone/CognitiveMilestoneAddContainer';
import AptitudeAddContainer from '../../TranscriptForms/Aptitude/AptitudeAddContainer';
import CognitiveTotalCreditsBySource from '../../TranscriptCalculations/CognitiveTotalCreditsBySource';
import CognitiveAverageScoreBySource from '../../TranscriptCalculations/CognitiveAverageScoreBySource';
import CognitiveGradeBySource from '../../TranscriptCalculations/CognitiveGradeBySource';
import RankSource from '../../TranscriptCalculations/RankSource';
import AptitudeWeightBySource from '../../TranscriptCalculations/AptitudeWeightBySource';
import AptitudeGradeBySource from '../../TranscriptCalculations/AptitudeGradeBySource';
import AptitudeScoreBySource from '../../TranscriptCalculations/AptitudeScoreBySource';

const Accordion = styled(({ ...rest }) => (
  <AccordionRowContainer {...rest} />
))``;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.grey[500]};
  font-size: 14px;
`;

const AccordionArea = styled.div`
  width: 100%;
  scroll: auto;
`;

const Calculations = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const Spacing = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
`;

export default function ByDefaultSource({
  programUuid,
  userUuid,
  cohortUuid,
  category,
  source,
  openUpload,
  openAdd,
  openDelete,
  handleUploadOpen,
  handleAddOpen,
  handleAddClose,
  handleUploadClose,
  handleDeleteOpen,
  handleDeleteClose,
  handleDelete,
  isAdmin,
}) {
  const sourceActions = [
    {
      label: 'Upload Transcript',
      onClick: handleUploadOpen,
      shouldOpenOnClick: false,
      icon: 'publish'
    },
    {
      label: 'Add Transcript Item',
      onClick: handleAddOpen,
      shouldOpenOnClick: false,
      icon: 'add'
    },
    {
      label: 'Delete All',
      onClick: handleDeleteOpen,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  function listMapping() {
    if (category === 'Cognitive') {
      return (
        <ByDefaultCognitiveContainer
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
          isAdmin={isAdmin}
        />
      );
    }

    if (category !== 'Cognitive') {
      return (
        <ByDefaultAptitudeContainer
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
          isAdmin={isAdmin}
        />
      );
    }
  }

  function listAddForms() {
    if (category === 'Cognitive' && source === 'Program') {
      return (
        <CognitiveProgramAddContainer
          formType="Add"
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
          modalOpen={openAdd}
          handleModalClose={handleAddClose}
        />
      );
    } else if (category === 'Cognitive' && source === 'Milestone') {
      return (
        <CognitiveMilestoneAddContainer
          formType="Add"
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
          modalOpen={openAdd}
          handleModalClose={handleAddClose}
        />
      );
    } else {
      return (
        <AptitudeAddContainer
          formType="Add"
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
          modalOpen={openAdd}
          handleModalClose={handleAddClose}
        />
      );
    }
  }

  return (
    <>
      <Accordion
        expandWidth="66px"
        height="70px"
        label={
          <Group>
            <Label>{`${source}`}</Label>
          </Group>
        }
        columnOneClassName="column_one"
        columnOne={
          <>
            {category === 'Cognitive' && (
              <Calculations>
                <Spacing>
                  <RankSource
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    category={category}
                    source={source}
                  />
                </Spacing>
                <Spacing>
                  <CognitiveTotalCreditsBySource
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    category={category}
                    source={source}
                  />
                </Spacing>
                <Spacing>
                  <CognitiveGradeBySource
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    category={category}
                    source={source}
                  />
                </Spacing>
                <Spacing>
                  <CognitiveAverageScoreBySource
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    category={category}
                    source={source}
                  />
                </Spacing>
              </Calculations>
            )}

            {category !== 'Cognitive' && (
              <Calculations>
                <Spacing>
                  <RankSource
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    category={category}
                    source={source}
                  />
                </Spacing>
                <Spacing>
                  <AptitudeWeightBySource
                    programUuid={programUuid}
                    category={category}
                    source={source}
                  />
                </Spacing>
                <Spacing>
                  <AptitudeGradeBySource
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    category={category}
                    source={source}
                  />
                </Spacing>
                <Spacing>
                  <AptitudeScoreBySource
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    category={category}
                    source={source}
                  />
                </Spacing>
              </Calculations>
            )}
          </>
        }
        actions={isAdmin ? sourceActions : 'placeholder'}
      >
        <AccordionArea>{listMapping()}</AccordionArea>
      </Accordion>
      {isAdmin &&
        <TranscriptUploadContainer
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
          openUpload={openUpload}
          handleUploadClose={handleUploadClose}
        />
      }
      {isAdmin &&
        <DeleteModal
          modalOpen={openDelete}
          handleModalClose={handleDeleteClose}
          handleDelete={handleDelete}
          type="Transcript Records"
        />
      }
      {isAdmin && listAddForms()}
    </>
  );
}

ByDefaultSource.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  openUpload: PropTypes.bool,
  openAdd: PropTypes.bool,
  openDelete: PropTypes.bool,
  handleUploadOpen: PropTypes.func,
  handleUploadClose: PropTypes.func,
  handleAddOpen: PropTypes.func,
  handleAddClose: PropTypes.func,
  handleDeleteOpen: PropTypes.func,
  handleDeleteClose: PropTypes.func,
  handleDelete: PropTypes.func,
  isAdmin: PropTypes.bool,
};

ByDefaultSource.defaultProps = {
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  openUpload: false,
  openAdd: false,
  openDelete: false,
  handleUploadOpen: undefined,
  handleUploadClose: undefined,
  handleAddOpen: undefined,
  handleAddClose: undefined,
  handleDeleteOpen: undefined,
  handleDeleteClose: undefined,
  handleDelete: undefined,
  isAdmin: false,
};
