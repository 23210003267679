import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonIcon } from '@xcomp/xcomp-design-library';

import { Column, TextFieldSC } from '../../../Library/FormComponents';
import { removeCollectionCourse } from '../courseCollectionState';

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const CourseCollectionItem = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

const ButtonColumn = styled(Column)`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const ButtonIconSC = styled(ButtonIcon)`
  && {
    svg {
      font-size: 1.75rem;
    }
  }
`;

const CourseCollectionCoursesForm = ({ selectedCourses, formDispatch }) => {
  const removeCourse = uuid => formDispatch(removeCollectionCourse(uuid));
  return (
    <Layout>
      {Object.entries(selectedCourses).map(([uuid, courseObject], index) => {
        return (
          <CourseCollectionItem key={uuid}>
            <Column size="75%">
              <TextFieldSC
                id="collectionCourseTitle"
                name="title"
                value={courseObject.title}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                label={`Course ${index + 1}`}
                variant="outlined"
                paddingRight="1rem"
              />
            </Column>
            <Column size="20%">
              <TextFieldSC
                id="collectionCourseCreditsField"
                name="credits"
                value={courseObject.hours.toString()}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                label="Credits"
                placeholder=""
              />
            </Column>
            <ButtonColumn size="5%">
              <ButtonIconSC onClick={() => removeCourse(uuid)} noHoverBg>
                <DeleteIcon />
              </ButtonIconSC>
            </ButtonColumn>
          </CourseCollectionItem>
        );
      })}
    </Layout>
  );
};

CourseCollectionCoursesForm.propTypes = {
  selectedCourses: PropTypes.object.isRequired,
  formDispatch: PropTypes.func
};

CourseCollectionCoursesForm.defaultProps = {
  formDispatch: undefined
};

export default CourseCollectionCoursesForm;
