import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Heading2 } from '@xcomp/xcomp-design-library';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
// import Grid from '@material-ui/core/Grid';
// import IconButton from '@material-ui/core/IconButton';
// import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
// import { useSelector } from 'react-redux';
// import { optionUserStatus, optionsRolesAll } from '../../../../helpers/options';
// import FormField from '../../../Library/FormField';
// import FormSelectError from '../../../Library/FormSelectError';
// import Date from '../../../Library/Date';

// import {
//   validateInputString,
//   validateSelectDate,
//   validateSelectField
// } from '../../../../helpers/validation/validateGeneric';

// import { selectDepartmentsBySchool } from '../../../../redux/selectors/departmentsSelectors';
import { cohortSelector } from '../../../../redux/selectors/cohortsSelectors';

const Group = styled.div`
  display: flex;
  font-size: 12px;
  width: 120px;
`;

const Label = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Value = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 5px;
`;

export default function UserCohortStudent({ student }) {
  return (
    <>
      <Heading2>Student Roles</Heading2>
      {student?.map(user => (
        <div key={user?.uuid}>
          <Group>
            <Label>Cohort: </Label>
            <Value>
              <GetCohort cohortUuid={user?.cohortUuid} />
            </Value>
          </Group>
          <Group>
            <Label>Email: </Label>
            <Value>{user?.email}</Value>
          </Group>
          <Group>
            <Label>Status: </Label>
            <Value>{user?.status}</Value>
          </Group>
        </div>
      ))}
    </>
  );
}

UserCohortStudent.propTypes = {
  student: PropTypes.array
};

UserCohortStudent.defaultProps = { student: [] };

function GetCohort({ cohortUuid }) {
  const cohort = useSelector(
    state => cohortSelector(state, cohortUuid),
    _.isEqual
  );

  return <>{cohort?.name}</>;
}

GetCohort.propTypes = {
  cohortUuid: PropTypes.string
};

GetCohort.defaultProps = { cohortUuid: undefined };
