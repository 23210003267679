import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

const Layout = styled.div`
  font-size: 12px;
  width: 100%;
  background: lightgrey;
  border-radius: 5px;
  padding: 10px;
  overflow: auto;
  margin-top: 20px;
`;

const RecordRow = styled.div`
  display: flex;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Label = styled.div`
  font-size: 12px;
  color: ${props => props.theme.fontColors.dark};
`;

const Value = styled.div`
  font-size: 12px;
  padding-left: 10px;
`;

export default function UploadErrorsNew({ show, error, uploadDate }) {
  function errorSegment() {
    if (error?.type === 'headers') {
      return <HeaderError error={error} uploadDate={uploadDate} />;
    } else if (error?.type === 'bulk_upload') {
      return <BulkError error={error} uploadDate={uploadDate} />;
    } else if (error?.type === 'unique_constraint') {
      return <UniqueError error={error} uploadDate={uploadDate} />;
    } else if (error?.type === 'source_api') {
      return <SourceError error={error} uploadDate={uploadDate} />;
    } else if (error?.type === 'restricted_list') {
      return <ListError error={error} uploadDate={uploadDate} />;
    } else if (error?.type === 'duplicates') {
      return <DuplicateError error={error} uploadDate={uploadDate} />;
    } else if (error?.type === 'format') {
      return <FormatError error={error} uploadDate={uploadDate} />;
    } else if (error?.type === 'block_titles') {
      return <MismatchBlockTitles error={error} uploadDate={uploadDate} />;
    } else if (error?.type === 'general') {
      return <GeneralError error={error} uploadDate={uploadDate} />;
    } else {
      return <StackError error={error} />;
    }
  }

  return show && error && <Layout>{errorSegment()}</Layout>;
}

UploadErrorsNew.propTypes = {
  show: PropTypes.bool,
  error: PropTypes.object,
  uploadDate: PropTypes.string
};

UploadErrorsNew.defaultProps = {
  show: false,
  error: {},
  uploadDate: undefined
};

const HeaderError = ({ error, uploadDate }) => {
  return (
    <Grid container spacing={2}>
      {uploadDate && (
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid container justifyContent="flex-start" item sm={3}>
              Date:
            </Grid>
            <Grid item sm={9}>
              {uploadDate}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={3}>
            Message:
          </Grid>
          <Grid item sm={9}>
            {error?.message}
          </Grid>
        </Grid>
      </Grid>
      {error?.incorrect_header && (
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid container justifyContent="flex-start" item sm={3}>
              Incorrect header:
            </Grid>
            <Grid item sm={9}>
              {error?.incorrect_header}
            </Grid>
          </Grid>
        </Grid>
      )}
      {error?.missing_required_header && (
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid container justifyContent="flex-start" item sm={3}>
              Missing required header(s):
            </Grid>
            <Grid item sm={9}>
              {error?.missing_required_header?.join(', ')}
            </Grid>
          </Grid>
        </Grid>
      )}
      {error?.missing_required_value && (
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid container justifyContent="flex-start" item sm={3}>
              Missing required data value(s):
            </Grid>
            <Grid item sm={9}>
              {error?.missing_required_value?.join(', ')}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={3}>
            Accepted:
          </Grid>
          <Grid item sm={9}>
            {error?.accepted_headers?.join(', ')}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

HeaderError.propTypes = {
  error: PropTypes.object,
  uploadDate: PropTypes.string
};

HeaderError.defaultProps = {
  error: {},
  uploadDate: ''
};

const BulkError = ({ error, uploadDate }) => {
  return (
    <Grid container spacing={2}>
      {uploadDate && (
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid container justifyContent="flex-start" item sm={3}>
              Date:
            </Grid>
            <Grid item sm={9}>
              {uploadDate}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={3}>
            Message:
          </Grid>
          <Grid item sm={9}>
            {error?.message}
          </Grid>
        </Grid>
      </Grid>

      {error?.failed_records?.map(record => (
        <>
          <Grid item sm={12}>
            <Grid container spacing={2}>
              <Grid container justifyContent="flex-start" item sm={3}>
                Record:
              </Grid>
              <Grid item sm={9}>
                {record?.record &&
                  _.map(record?.record, (value, key) => (
                    <RecordRow>
                      <Group>
                        <Label>{key}</Label>
                        <Value>{value}</Value>
                      </Group>
                    </RecordRow>
                  ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid container spacing={2}>
              <Grid container justifyContent="flex-start" item sm={3}>
                Message:
              </Grid>
              <Grid item sm={9}>
                {record?.message}
              </Grid>
            </Grid>
          </Grid>
        </>
      ))}
    </Grid>
  );
};

BulkError.propTypes = {
  error: PropTypes.object,
  uploadDate: PropTypes.string
};

BulkError.defaultProps = {
  error: {},
  uploadDate: ''
};

const UniqueError = ({ error, uploadDate }) => {
  return (
    <Grid container spacing={2}>
      {uploadDate && (
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid container justifyContent="flex-start" item sm={3}>
              Date:
            </Grid>
            <Grid item sm={9}>
              {uploadDate}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={3}>
            Message:
          </Grid>
          <Grid item sm={9}>
            {error?.message}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={3}>
            Fields:
          </Grid>
          <Grid item sm={9}>
            <pre>{JSON.stringify(error?.fields, null, 2)}</pre>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

UniqueError.propTypes = {
  error: PropTypes.object,
  uploadDate: PropTypes.string
};

UniqueError.defaultProps = {
  error: {},
  uploadDate: ''
};

const DuplicateError = ({ error, uploadDate }) => {
  return (
    <Grid container spacing={2}>
      {uploadDate && (
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid container justifyContent="flex-start" item sm={3}>
              Date:
            </Grid>
            <Grid item sm={9}>
              {uploadDate}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={3}>
            Message:
          </Grid>
          <Grid item sm={9}>
            {error?.message}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={3}>
            Duplicates:
          </Grid>
          <Grid item sm={9}>
            <pre>{JSON.stringify(error?.duplicates, null, 2)}</pre>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

DuplicateError.propTypes = {
  error: PropTypes.object,
  uploadDate: PropTypes.string
};

DuplicateError.defaultProps = {
  error: {},
  uploadDate: ''
};

const FormatError = ({ error, uploadDate }) => {
  return (
    <Grid container spacing={2}>
      {uploadDate && (
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid container justifyContent="flex-start" item sm={3}>
              Date:
            </Grid>
            <Grid item sm={9}>
              {uploadDate}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={3}>
            Message:
          </Grid>
          <Grid item sm={9}>
            {error?.message}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

FormatError.propTypes = {
  error: PropTypes.object,
  uploadDate: PropTypes.string
};

FormatError.defaultProps = {
  error: {},
  uploadDate: ''
};

const GeneralError = ({ error, uploadDate }) => {
  return (
    <Grid container spacing={2}>
      {uploadDate && (
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid container justifyContent="flex-start" item sm={3}>
              Date:
            </Grid>
            <Grid item sm={9}>
              {uploadDate}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={3}>
            Message:
          </Grid>
          <Grid item sm={9}>
            {error?.message}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

GeneralError.propTypes = {
  error: PropTypes.object,
  uploadDate: PropTypes.string
};

GeneralError.defaultProps = {
  error: {},
  uploadDate: ''
};

const SourceError = ({ error, uploadDate }) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={2}>
            Date:
          </Grid>
          <Grid item sm={10}>
            {uploadDate}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={2}>
            Message:
          </Grid>
          <Grid item sm={10}>
            {error?.message}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={2}>
            Status:
          </Grid>
          <Grid item sm={10}>
            {error?.status}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={2}>
            Status Text:
          </Grid>
          <Grid item sm={10}>
            {error?.statusText}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

SourceError.propTypes = {
  error: PropTypes.object,
  uploadDate: PropTypes.string
};

SourceError.defaultProps = {
  error: {},
  uploadDate: ''
};

const ListError = ({ error, uploadDate }) => {
  return (
    <Grid container spacing={2}>
      {uploadDate && (
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid container justifyContent="flex-start" item sm={3}>
              Date:
            </Grid>
            <Grid item sm={9}>
              {uploadDate}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={3}>
            Message:
          </Grid>
          <Grid item sm={9}>
            {error?.message}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={3}>
            List:
          </Grid>
          <Grid item sm={9}>
            {error?.list?.join(', ')}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={3}>
            Provided:
          </Grid>
          <Grid item sm={9}>
            {error?.provided?.join(', ')}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ListError.propTypes = {
  error: PropTypes.object,
  uploadDate: PropTypes.string
};

ListError.defaultProps = {
  error: {},
  uploadDate: ''
};

const StackError = ({ error }) => {
  return <div>{JSON.stringify(error)}</div>;
};

StackError.propTypes = {
  error: PropTypes.object
};

StackError.defaultProps = {
  error: {}
};

const WrongRecords = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const MismatchBlockTitles = ({ error, uploadDate }) => {
  return (
    <Grid container spacing={2}>
      {uploadDate && (
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid container justifyContent="flex-start" item sm={3}>
              Date:
            </Grid>
            <Grid item sm={9}>
              {uploadDate}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid container justifyContent="flex-start" item sm={3}>
            Message:
          </Grid>
          <Grid item sm={9}>
            This assessment is configured to validate block titles against
            RBAAS. {error?.message}
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12}>
        {error?.mismatch.map(record => (
          <WrongRecords key={record.line_item}>
            <div>Block Number: {record.block_number}</div>
            <div>Incorrect: {record.block_title_input}</div>
            <div>Correct: {record.block_title_rbaas}</div>
            <div>Line Item: {record.line_item}</div>
          </WrongRecords>
        ))}
      </Grid>
    </Grid>
  );
};

MismatchBlockTitles.propTypes = {
  error: PropTypes.object,
  uploadDate: PropTypes.string
};

MismatchBlockTitles.defaultProps = {
  error: {},
  uploadDate: ''
};
