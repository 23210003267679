import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 900px;
`;

export const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  margin-bottom: 20px;
`;

export const FileUpload = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Note = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 12px;
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Import = styled(ButtonPrimary)`
  && {
    margin-left: 5px;
  }
`;

export const FileSelectorSC = styled.div`
  width: 100%;
  margin-right: 16px;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
`;

export const LoaderMessage = styled.div`
  padding-left: 10px;
`;

export const ChipSC = styled(Chip)`
  && {
    background: ${props => {
      switch (props.status) {
        case 'ready': {
          return props.theme.colors.grey[500];
        }
        case 'loading': {
          return props.theme.colors.status.warning;
        }
        case 'success': {
          return props.theme.colors.status.success;
        }
        case 'failed': {
          return props.theme.colors.status.error;
        }
        default:
          return props.theme.colors.grey[500];
      }
    }};
    color: ${props => props.theme.colors.white};
  }

  margin-left: 20px;
`;

export const ButtonDanger = styled(ButtonPrimary)`
  && {
    margin-left: 20px;
    background: ${props => props.theme.colors.status.error};
    &:hover {
      filter: brightness(140%);
      background-color: ${props => props.theme.colors.status.error};
    }
  }
`;

export const ButtonSummary = styled(ButtonPrimary)`
  && {
    margin-left: 20px;
    background: ${props => props.theme.colors.status.success};
    &:hover {
      filter: brightness(140%);
      background-color: ${props => props.theme.colors.status.success};
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
`;
