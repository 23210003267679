import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { Body1 } from '@xcomp/xcomp-design-library';
import { useSelector } from 'react-redux';
import { assessmentSelector } from '../../../../redux/selectors/assessmentSelectors';
import { Column } from '../../../Library/FormComponents';

const CompetencyDetails = styled.div`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const AssessmentField = styled(Body1)`
  flex-grow: 2;
  color: ${props => props.theme.fontColors.darker};
`;

const WeightField = styled(Body1)`
  flex-grow: 1;
  max-width: 200px;
  min-width: 200px;
  color: ${props => props.theme.fontColors.darker};
`;

const CollectionAssessmentRow = ({ collectionAssessment }) => {
  const assessment = useSelector(
    state => assessmentSelector(state, collectionAssessment.assessmentUuid),
    _.isEqual
  );

  return (
    <Column size="100%">
      <CompetencyDetails>
        <AssessmentField>{assessment.title}</AssessmentField>
        <WeightField>{collectionAssessment.weight}</WeightField>
      </CompetencyDetails>
    </Column>
  );
};

CollectionAssessmentRow.propTypes = {
  collectionAssessment: PropTypes.object
};

CollectionAssessmentRow.defaultProps = {
  collectionAssessment: {}
};

export default CollectionAssessmentRow;
