import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { ButtonOutline, ButtonIcon } from '@xcomp/xcomp-design-library';
import { NavigationDivider } from './NavigationSC';
import NavigationProfile from './NavigationProfile';

const PaperSC = styled(Paper)`
  && {
    width: 300px;
    min-width: 300px;
  }
`;

const MenuSC = styled(Menu)`
  && {
    margin-top: 56px;

    div ul {
      padding: 0px;
    }
  }
`;

const ButtonOutlineSC = styled(ButtonOutline)`
  && {
    margin: 1rem 0rem 1rem 2rem;
  }
`;

const ButtonSC = styled(ButtonIcon)`
  && {
    color: ${props => props.theme.colors.white};
    svg {
      fill: ${props => props.theme.colors.white};
    }
    &:hover,
    &:active,
    &:focus {
      svg {
        fill: ${props => props.theme.colors.white};
      }
    }
  }
`;

const NavigationMenuUser = ({
  user,
  handleUserLogout,
  currentRole,
  changeCurrentRole
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ButtonSC
        onClick={handleClick}
        aria-haspopup="true"
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        noHoverBg
      >
        <AccountCircle />
      </ButtonSC>
      <MenuSC
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        <PaperSC>
          <NavigationProfile
            handleClose={handleClose}
            user={user}
            currentRole={currentRole}
            changeCurrentRole={changeCurrentRole}
          />
          <NavigationDivider />
          <ButtonOutlineSC onClick={() => handleUserLogout()}>
            LOGOUT
          </ButtonOutlineSC>
        </PaperSC>
      </MenuSC>
    </div>
  );
};

NavigationMenuUser.propTypes = {
  user: PropTypes.object,
  currentRole: PropTypes.object,
  handleUserLogout: PropTypes.func,
  changeCurrentRole: PropTypes.func
};

NavigationMenuUser.defaultProps = {
  user: {},
  currentRole: {},
  handleUserLogout: undefined,
  changeCurrentRole: undefined
};

export default NavigationMenuUser;
