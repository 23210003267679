import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import _ from 'lodash';
import { doGetSection } from '../../../redux/actions/sectionActions';
import { doGetCalculatedPartGradeResultsBySection } from '../../../redux/actions/calculatedGradeResultActions';
import { sectionSelector } from '../../../redux/selectors/sectionsSelectors';
import SectionPerformanceSummary from './SectionPerformanceSummary';

const SectionPerformanceSummaryContainer = () => {
  const { sectionUuid } = useParams();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState(0);
  const [basePath, setBasePath] = useState(
    _(path).replace(':sectionUuid', sectionUuid)
  );

  const section =
    useSelector(state => sectionSelector(state, sectionUuid)) || {};
  const course = _.get(section, 'course', null);
  const termPeriod = _.get(course, 'term.period', '');
  const courseNumber = _.get(course, 'course_master.courseNumber', '');
  const courseTitle = _.get(course, 'course_master.title', '');
  const courseCredits = _.get(course, 'course_master.hours', '');
  const paddedSectionNumber = _.get(section, 'sectionNumber', '')
    .toString()
    .padStart(2, 0);
  const parts = _.get(section, 'parts', []);

  useEffect(() => {
    switch (pathname) {
      case `/syllabus-management/section/${sectionUuid}/section-grade-summary/default`: {
        setCurrentTab(0);
        break;
      }
      case `/syllabus-management/section/${sectionUuid}/section-grade-summary/transcript`: {
        setCurrentTab(1);
        break;
      }
      case `/syllabus-management/section/${sectionUuid}/section-grade-summary/attempt`: {
        setCurrentTab(2);
        break;
      }
      case `/syllabus-management/section/${sectionUuid}/section-grade-summary/didactic`: {
        setCurrentTab(3);
        break;
      }
      case `/syllabus-management/section/${sectionUuid}/section-grade-summary/opportunity`: {
        setCurrentTab(4);
        break;
      }
      case `/syllabus-management/section/${sectionUuid}/section-grade-summary/self-assessment`: {
        setCurrentTab(5);
        break;
      }
      default:
        break;
    }
  }, [pathname, sectionUuid]);

  useEffect(() => {
    setBasePath(_(basePath).replace(':sectionUuid', sectionUuid));
  }, [basePath, sectionUuid]);

  useEffect(() => {
    dispatch(doGetSection(sectionUuid));
  }, [dispatch, sectionUuid]);

  useEffect(() => {
    if (sectionUuid) {
      dispatch(doGetCalculatedPartGradeResultsBySection(sectionUuid));
    }
  }, [dispatch, sectionUuid]);

  const handleBackAction = useCallback(
    () => history.push('/syllabus-management/all'),
    [history]
  );

  return (
    <SectionPerformanceSummary
      basePath={basePath}
      courseCredits={courseCredits}
      courseNumber={courseNumber}
      courseTitle={courseTitle}
      currentTab={currentTab}
      onBackAction={handleBackAction}
      parts={parts}
      path={path}
      sectionNumber={paddedSectionNumber}
      sectionUuid={sectionUuid}
      section={section}
      termPeriod={termPeriod}
    />
  );
};

export default SectionPerformanceSummaryContainer;
