import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isUUID } from 'validator';
import Decimal from 'decimal.js-light';
import _ from 'lodash';
import { assessmentSelector } from '../../../../redux/selectors/assessmentSelectors';
import { doGetAssessment } from '../../../../redux/actions/assessmentActions';
import { doGetCohortGradeSummary } from '../../../../redux/actions/gradeSummaryActions';
import GradeSummary from './GradeSummary';
import { sortByProperty } from '../../../../helpers/utilities';
import { gradingTechniques } from '../../../../helpers/constants';
import { resultCardsByAssessmentUuidSelector } from '../../../../redux/selectors/resultCardSelectors';
import { doGetCohort } from '../../../../redux/actions/cohortActions';
import { cohortSelectorByMatchParams } from '../../../../redux/selectors/cohortsSelectors';

export const customSortByDecimalPropertyAndFullName = (
  a,
  b,
  decimalPropertyName,
  firstNameProperty,
  lastNameProperty,
  reversed = false
) => {
  if (a[decimalPropertyName] === '--' && b[decimalPropertyName] === '--') {
    const fullNameA = `${a[lastNameProperty]}, ${a[firstNameProperty]}`;
    const fullNameB = `${b[lastNameProperty]}, ${b[firstNameProperty]}`;
    return reversed
      ? fullNameB.localeCompare(fullNameA)
      : fullNameA.localeCompare(fullNameB);
  } else if (a[decimalPropertyName] === '--') {
    return -1;
  } else if (b[decimalPropertyName] === '--') {
    return 1;
  }

  const propertyA = new Decimal(a[decimalPropertyName]);

  const propertyB = new Decimal(b[decimalPropertyName]);

  if (propertyA.lt(propertyB)) {
    return -1;
  }
  if (propertyA.gt(propertyB)) {
    return 1;
  }

  const fullNameA = `${a[lastNameProperty]}, ${a[firstNameProperty]}`;
  const fullNameB = `${b[lastNameProperty]}, ${b[firstNameProperty]}`;
  return reversed
    ? fullNameB.localeCompare(fullNameA)
    : fullNameA.localeCompare(fullNameB);
};

export default function CohortAssessmentGradeSummaryContainer({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { assessmentUuid, cohortUuid, cohortAssessmentCollectionUuid } =
    match.params;

  const [hideStudentNames, setHideStudentNames] = useState(false);
  const [sortBy, setSortBy] = useState('highLow');

  const assessment = useSelector(
    state => assessmentSelector(state, assessmentUuid),
    _.isEqual
  );

  const gradeSummaryState = useSelector(
    state => state.gradeSummaryState,
    _.isEqual
  );

  const cohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    shallowEqual
  );

  const assessmentType = _.get(assessment, 'assessment_type.name');

  const handleBackAction = useCallback(() => {
    history.push(
      `/program-management/cohort/${cohortUuid}/assessment-collection/${cohortAssessmentCollectionUuid}/syllabus-management`
    );
  }, [cohortUuid, cohortAssessmentCollectionUuid, history]);

  useEffect(() => {
    dispatch(doGetAssessment(assessmentUuid));
  }, [dispatch, assessmentUuid]);

  useEffect(() => {
    if (assessmentUuid && isUUID(assessmentUuid))
      dispatch(doGetCohortGradeSummary(assessmentUuid, true));
  }, [dispatch, assessmentUuid]);

  useEffect(() => {
    dispatch(doGetCohort(cohortUuid));
  }, [dispatch, cohortUuid]);

  const toggleHideStudentNames = () => setHideStudentNames(!hideStudentNames);
  const { ...gradeSummary } = gradeSummaryState;

  const resultCards = useSelector(
    state => resultCardsByAssessmentUuidSelector(state, assessmentUuid),
    _.isEqual
  );

  const isPointsOnly =
    gradeSummary.gradingTechnique === gradingTechniques.points;

  const sortFunction = (a, b) => {
    if (sortBy === 'lastName') return sortByProperty(a, b, 'lastName');

    if (isPointsOnly) {
      return sortBy === 'highLow'
        ? customSortByDecimalPropertyAndFullName(
            b,
            a,
            'assessmentRelativeScore',
            'firstName',
            'lastName',
            true
          )
        : customSortByDecimalPropertyAndFullName(
            a,
            b,
            'assessmentRelativeScore',
            'firstName',
            'lastName'
          );
    }
    return sortBy === 'highLow'
      ? customSortByDecimalPropertyAndFullName(
          b,
          a,
          'calculatedGrade',
          'firstName',
          'lastName',
          true
        )
      : customSortByDecimalPropertyAndFullName(
          a,
          b,
          'calculatedGrade',
          'firstName',
          'lastName'
        );
  };

  return (
    <GradeSummary
      assessmentTitle={assessment.title}
      assessmentUuid={assessmentUuid}
      cohort={cohort}
      cohortUuid={cohortUuid}
      gradeSummary={gradeSummary}
      hideStudentNames={hideStudentNames}
      isPointsOnly={isPointsOnly}
      sortFunction={sortFunction}
      toggleHideStudentNames={toggleHideStudentNames}
      setSortBy={setSortBy}
      resultCards={resultCards}
      assessmentType={assessmentType}
      handleBackAction={handleBackAction}
    />
  );
}

CohortAssessmentGradeSummaryContainer.propTypes = {
  match: PropTypes.object
};

CohortAssessmentGradeSummaryContainer.defaultProps = {
  match: {}
};
