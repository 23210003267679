import React from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js-light';
import styled from 'styled-components';
import { Chip, Tooltip } from '@material-ui/core';
import { CohortIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { tintColor } from '@xcomp/xcomp-design-library/dist/helpers';
import {
  formatLastName,
  formatFirstNameMiddleName
} from '../../../../../helpers/format/user.format';

const Table = styled.table`
  background: ${props => props.theme.colors.white};
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
`;

const TableHead = styled.thead`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: ${props => props.theme.colors.grey[600]};
  &:first-child td {
    // border-top: none;
  }
  // border-top: 1px solid ${props => props.theme.colors.grey[300]};
`;

const TableRow = styled.tr`
  display: flex;
  justify-content: flex-start;
  inline-size: min-content;
  td:last-child {
    border-right: none;
  }
`;

const TableBody = styled.tbody`
  color: ${props => props.theme.colors.grey[900]};
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 24px;
  tr:first-child td {
    border-top: solid 1px ${props => props.theme.colors.grey[300]};
  }

  tr:last-child td {
    border-bottom: none;
  }
`;

const ScrollableDiv = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: auto;
`;

const CohortIconSC = styled(CohortIcon)`
  margin-right: 8px;
`;

const RotatedTd = styled.td`
  min-width: 75px;
  max-width: 75px;
  z-index: 5;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-top: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
`;

const StickyTitleTd = styled('td')`
  min-width: 75px;
  max-width: 75px;
  z-index: 10;
  position: sticky;
  left: ${props => props.left};
  display: flex;
  background-color: ${props =>
    (props.shadeThree && tintColor(props.theme.colors.red[100], 0.85)) ||
    (props.shadeFour && tintColor(props.theme.colors.blue[100], 0.85)) ||
    'white'};
  border-right: ${props =>
    (props.borderRight && `solid 1px ${props.theme.colors.grey[300]}`) || 'none'};
  align-items: flex-end;
  border-top: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
  justify-content: center;
`;

const SummaryTitle = styled.div`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  color: ${props => props.theme.colors.grey[600]};
  padding: 16px 0px;
  white-space: nowrap;
`;

const AssessmentTitle = styled.div`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${props => props.theme.colors.grey[600]};
  padding: 16px 0px;
  white-space: nowrap;
`;

const InfoTd = styled('td')`
  min-width: 250px;
  max-width: 250px;
  z-index: 10;
  position: sticky;
  left: 150px;
  background-color: ${props =>
    (props.greyTwo && props.theme.colors.grey[200]) ||
    (props.greyOne && props.theme.colors.grey[100]) ||
    'white'};
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
`;

const RowInfoTitle = styled(Tooltip)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 53px;
  padding: 16px;
  color: ${props => props.theme.fontColors.dark};
  font-size: '14px';
  font-weight: '400';
  line-height: 20px;
  color: ${props => props.theme.colors.grey[600]};
  text-align: center;
  .MuiSvgIcon-root {
    margin-right: 16px;
  }
  .MuiChip-root {
    margin-right: 16px;
    background-color: ${props => props.theme.colors.blue[100]};
  }
`;

const PointsTd = styled('td')`
  min-width: 75px;
  max-width: 75px;
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  background-color: ${props =>
    (props.greyOne && props.theme.colors.grey[100]) ||
    (props.greyTwo && props.theme.colors.grey[200]) ||
    'white'};
`;

const FillerTd = styled('td')`
  min-width: ${props => `${props.width}px`};
  max-width: ${props => `${props.width}px`};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-top: ${props =>
    props.borderTop ? `1px solid ${props.theme.colors.grey[300]}` : 'none'};
  border-bottom: ${props =>
    props.noBottomBorder ? 'none' : `1px solid ${props.theme.colors.grey[300]}`};
  border-right: ${props => `1px solid ${props.theme.colors.grey[300]}`};
  background-color: ${props =>
    (props.greyOne && props.theme.colors.grey[100]) ||
    (props.greyTwo && props.theme.colors.grey[200]) ||
    'white'};
`;

const StickyDataTd = styled('td')`
  min-width: 75px;
  max-width: 75px;
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  background-color: ${props =>
    (props.greyOne && props.theme.colors.grey[100]) ||
    (props.greyTwo && props.theme.colors.grey[200]) ||
    (props.redOne && tintColor(props.theme.colors.red[100], 0.5)) ||
    (props.redTwo && tintColor(props.theme.colors.red[100], 0.4)) ||
    (props.redThree && tintColor(props.theme.colors.red[100], 0.7)) ||
    (props.blueOne && tintColor(props.theme.colors.blue[500], 0.8)) ||
    (props.blueTwo && tintColor(props.theme.colors.blue[100], 0.5)) ||
    (props.blueThree && tintColor(props.theme.colors.blue[100], 0.7)) ||
    'white'};
  position: sticky;
  left: ${props => props.left};
`;

const TableDataText = styled('div')`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${props => props.theme.colors.grey[900]};
  .MuiChip-root {
    background-color: ${props =>
      props.redChip ? `${props.theme.colors.red[200]} !important` : props.theme.colors.blue[100]};
  }
`;

const PartGroupReportTable = ({
  assessments,
  groupMembers,
  resultCardsByAssessment,
  studentPartRanks,
  pointsPerStudentByAssessment,
  groupAssessmentsTotal,
  totalPointsByStudent,
  tableRef,
  useFillerColumn,
  fillerColumnWidth
}) => {
  return (
    <ScrollableDiv>
      <Table ref={tableRef}>
        <TableHead>
          <TableRow>
            <StickyTitleTd left="0px" shadeThree>
              <SummaryTitle>Class Participation (%)</SummaryTitle>
            </StickyTitleTd>
            <StickyTitleTd left="75px" shadeThree borderRight>
              <SummaryTitle>Class Rank</SummaryTitle>
            </StickyTitleTd>
            <InfoTd />
            <StickyTitleTd left="400px" shadeFour>
              <SummaryTitle>Group Rank</SummaryTitle>
            </StickyTitleTd>
            <StickyTitleTd left="475px" shadeFour>
              <SummaryTitle>Group Participation (%)</SummaryTitle>
            </StickyTitleTd>
            <StickyTitleTd left="550px" shadeFour borderRight>
              <SummaryTitle>Assessment Totals</SummaryTitle>
            </StickyTitleTd>
            {assessments &&
              assessments.map(assessment => (
                <RotatedTd key={assessment.uuid}>
                  <AssessmentTitle>{assessment.title}</AssessmentTitle>
                </RotatedTd>
              ))}
            {useFillerColumn && (
              <FillerTd borderTop noBottomBorder width={fillerColumnWidth} />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StickyDataTd redThree left="0px">
              <TableDataText>---</TableDataText>
            </StickyDataTd>
            <StickyDataTd redThree left="75px">
              <TableDataText>---</TableDataText>
            </StickyDataTd>
            <InfoTd greyTwo>
              <RowInfoTitle title="Group Information" placement="top">
                <div>
                  <CohortIconSC />
                  Group Information
                </div>
              </RowInfoTitle>
            </InfoTd>
            <StickyDataTd blueThree left="400px">
              <TableDataText>---</TableDataText>
            </StickyDataTd>
            <StickyDataTd blueThree left="475px">
              <TableDataText>---</TableDataText>
            </StickyDataTd>
            <StickyDataTd blueThree left="550px">
              <TableDataText>{groupAssessmentsTotal}</TableDataText>
            </StickyDataTd>
            {assessments &&
              assessments.map(assessment => (
                <PointsTd key={assessment.uuid} greyTwo>
                  <TableDataText>
                    {pointsPerStudentByAssessment[assessment.uuid] || 'N/A'}
                  </TableDataText>
                </PointsTd>
              ))}
            {useFillerColumn && <FillerTd greyTwo width={fillerColumnWidth} />}
          </TableRow>
          {groupMembers.map((groupMember, idx) => {
            const groupMemberFormattedLastName = formatLastName(
              groupMember.user?.lastName,
              groupMember.user?.marriedLastName,
              groupMember.user?.suffix
            );
            const groupMemberFormattedFirstMiddleName =
              formatFirstNameMiddleName(
                groupMember.user?.firstName,
                groupMember.user?.middleName
              );
            const groupMemberFullName =
              groupMember.user?.lastName && groupMember.user?.firstName
                ? `${groupMemberFormattedLastName}, ${groupMemberFormattedFirstMiddleName}`
                : 'N/A';

            const finalGroupMemberName = groupMember?.user?.firstName.includes(
              'Student'
            )
              ? 'Student'
              : groupMemberFullName;
            return (
              <TableRow key={groupMember.uuid}>
                <StickyDataTd
                  redOne={idx % 2 === 0}
                  redTwo={idx % 2 !== 0}
                  left="0px"
                >
                  <TableDataText>
                    {studentPartRanks[groupMember.user.uuid]?.classParticipation
                      ? new Decimal(
                          studentPartRanks[
                            groupMember.user.uuid
                          ].classParticipation
                        )
                          .toDecimalPlaces(2)
                          .toString()
                      : '---'}
                  </TableDataText>
                </StickyDataTd>
                <StickyDataTd
                  redOne={idx % 2 === 0}
                  redTwo={idx % 2 !== 0}
                  left="75px"
                  shadeThree
                >
                  <TableDataText redChip>
                    <Chip
                      label={
                        studentPartRanks[groupMember.user.uuid]?.classRank ||
                        '---'
                      }
                    />
                  </TableDataText>
                </StickyDataTd>
                <InfoTd greyOne={idx % 2 !== 0}>
                  <RowInfoTitle title={finalGroupMemberName} placement="top">
                    <div>{finalGroupMemberName}</div>
                  </RowInfoTitle>
                </InfoTd>
                <StickyDataTd
                  left="400px"
                  blueOne={idx % 2 === 0}
                  blueTwo={idx % 2 !== 0}
                >
                  <TableDataText>
                    <Chip label={groupMember.studentRank || '--'} />
                  </TableDataText>
                </StickyDataTd>
                <StickyDataTd
                  left="475px"
                  blueOne={idx % 2 === 0}
                  blueTwo={idx % 2 !== 0}
                >
                  <TableDataText>
                    {groupMember.groupParticipation
                      ? new Decimal(groupMember.groupParticipation)
                          .toDecimalPlaces(2)
                          .toString()
                      : '---'}
                  </TableDataText>
                </StickyDataTd>
                <StickyDataTd
                  left="550px"
                  blueOne={idx % 2 === 0}
                  blueTwo={idx % 2 !== 0}
                >
                  <TableDataText>
                    {totalPointsByStudent[groupMember.user.uuid] || '---'}
                  </TableDataText>
                </StickyDataTd>
                {assessments.map(assessment => (
                  <PointsTd greyOne={idx % 2 !== 0} key={assessment.uuid}>
                    <TableDataText>
                      {resultCardsByAssessment[assessment.uuid]?.[
                        groupMember.user.uuid
                      ]?.assessmentRelativeScore
                        ? new Decimal(
                            resultCardsByAssessment[assessment.uuid][
                              groupMember.user.uuid
                            ].assessmentRelativeScore
                          )
                            .toDecimalPlaces(2)
                            .toString()
                        : 'N/A'}
                    </TableDataText>
                  </PointsTd>
                ))}
                {useFillerColumn && (
                  <FillerTd greyOne={idx % 2 !== 0} width={fillerColumnWidth} />
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </ScrollableDiv>
  );
};

PartGroupReportTable.propTypes = {
  assessments: PropTypes.array,
  groupAssessmentsTotal: PropTypes.string,
  groupMembers: PropTypes.array,
  pointsPerStudentByAssessment: PropTypes.object,
  resultCardsByAssessment: PropTypes.object,
  studentPartRanks: PropTypes.object,
  totalPointsByStudent: PropTypes.object,
  tableRef: PropTypes.object,
  useFillerColumn: PropTypes.bool,
  fillerColumnWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

PartGroupReportTable.defaultProps = {
  assessments: [],
  groupAssessmentsTotal: '',
  groupMembers: [],
  pointsPerStudentByAssessment: {},
  resultCardsByAssessment: {},
  studentPartRanks: {},
  totalPointsByStudent: {},
  tableRef: {},
  useFillerColumn: false,
  fillerColumnWidth: 0
};

export default PartGroupReportTable;
