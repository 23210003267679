import React from 'react';

import PropTypes from 'prop-types';
import EducationLevelShow from './EducationLevelShow';

const EducationLevelShowContainer = ({ educationLevel }) => {
  return <EducationLevelShow educationLevel={educationLevel} />;
};

EducationLevelShowContainer.propTypes = {
  educationLevel: PropTypes.object
};
EducationLevelShowContainer.defaultProps = {
  educationLevel: {}
};

export default EducationLevelShowContainer;
