import fetch from '../helpers/fetch';

const getDegreeLevelIndex = programUuid =>
  fetch(`/api/degree-level-indices/${programUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postDegreeLevelIndex = (programUuid, sourceDegreeLevelUuid, percentage) =>
  fetch('/api/degree-level-index/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ programUuid, sourceDegreeLevelUuid, percentage })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putDegreeLevelIndex = (uuid, sourceDegreeLevelUuid, percentage) =>
  fetch(`/api/degree-level-index/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      sourceDegreeLevelUuid,
      percentage
    })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteDegreeLevelIndex = uuid =>
  fetch(`/api/degree-level-index/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  getDegreeLevelIndex,
  postDegreeLevelIndex,
  putDegreeLevelIndex,
  deleteDegreeLevelIndex
};
