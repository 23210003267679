import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { RemoveIcon, CheckIcon } from '@xcomp/xcomp-design-library/dist/icons';
import AccordionRowContainer from '../../AccordionRow/AccordionRowContainer';
import StepBlockResultPanel from './StepBlockResultPanel';
import { sortByProperty } from '../../../../helpers/utilities';

const StepPanelAccordion = styled(
  ({
    withBottomBorder,
    withTopBorder,
    criticalFailure,
    blockStatus,
    ...rest
  }) => <AccordionRowContainer {...rest} />
)`
  margin-left: 1rem;
  width: calc(100% - 1rem);

  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    white-space: nowrap;
  }

  .accordion_row_label div {
    font-size: 16px;
    letter-spacing: 0.15;
    color: ${props => props.theme.fontColors.darker};
  }

  .line {
    left: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }

  .block_status_label {
    .MuiChip-root {
      background-color: ${props =>
        (props.blockStatus === 'pass' && props.theme.colors.status.success) ||
        props.theme.colors.status.error};
      color: ${props => props.theme.colors.white};
      min-width: 10rem;
      font-size: 1rem;
      margin-right: 1rem;
    }
    .MuiSvgIcon-root {
      color: ${props =>
        (props.blockStatus === 'pass' && props.theme.colors.status.success) ||
        (props.blockStatus === 'pending' && props.theme.colors.status.success) ||
        props.theme.colors.status.error};
      margin-right: 1rem;
      font-size: 2rem;
    }
  }
`;

export default function StepBlockPanel({
  assessmentBlock,
  assessmentType,
  assessmentUuid,
  blockScoresByAttempt,
  handleScoreAddModalOpen,
  handleScoreEditModalOpen,
  handleDeleteStudentScoresModalOpen,
  isSingleBlock,
  scoreType,
  studentBlockResultsByBlock,
  studentUuid,
  isAdmin,
  isMutating
}) {
  const latestStatus = studentBlockResultsByBlock.sort((a, b) =>
    sortByProperty(a, b, 'blockAttempt')
  )[studentBlockResultsByBlock.length - 1]?.status;

  const labelText = isSingleBlock
    ? assessmentBlock.title.toUpperCase()
    : `BLOCK ${assessmentBlock.blockNumber} - ${assessmentBlock.title}`;
  const statusIcon = latestStatus === 'pass' ? <CheckIcon /> : <RemoveIcon />;

  return (
    <StepPanelAccordion
      height="50px"
      label={labelText}
      loading={isMutating}
      actions={isAdmin 
        ?
          [
            {
              label: 'Delete Block Scores',
              onClick: () => handleDeleteStudentScoresModalOpen(`&assessmentBlockUuid=${assessmentBlock.uuid}`),
              shouldOpenOnClick: false,
              icon: 'delete'
            }
          ]
        : 
          false
      }
      columnOne={scoreType === 'Opportunity' ? null : <div />}
      columnOneClassName="line"
      columnTwo={scoreType === 'Opportunity' ? null : statusIcon}
      columnTwoClassName="block_status_label"
      blockStatus={latestStatus}
    >
      {studentBlockResultsByBlock.map(assessmentBlockResult => (
        <StepBlockResultPanel
          assessmentBlockResult={assessmentBlockResult}
          assessmentBlockUuid={assessmentBlock.uuid}
          assessmentType={assessmentType}
          assessmentUuid={assessmentUuid}
          handleScoreAddModalOpen={handleScoreAddModalOpen}
          handleScoreEditModalOpen={handleScoreEditModalOpen}
          handleDeleteStudentScoresModalOpen={handleDeleteStudentScoresModalOpen}
          key={assessmentBlockResult.uuid}
          scores={blockScoresByAttempt[assessmentBlockResult.blockAttempt]}
          scoreType={scoreType}
          studentUuid={studentUuid}
          isAdmin={isAdmin}
          isMutating={isMutating}
        />
      ))}
    </StepPanelAccordion>
  );
}

StepBlockPanel.propTypes = {
  assessmentBlock: PropTypes.object,
  assessmentType: PropTypes.string,
  assessmentUuid: PropTypes.string,
  blockScoresByAttempt: PropTypes.object,
  handleScoreAddModalOpen: PropTypes.func,
  handleScoreEditModalOpen: PropTypes.func,
  handleDeleteStudentScoresModalOpen: PropTypes.func,
  isSingleBlock: PropTypes.bool,
  scoreType: PropTypes.string,
  studentBlockResultsByBlock: PropTypes.array,
  studentUuid: PropTypes.string,
  isAdmin: PropTypes.bool,
  isMutating: PropTypes.bool,
};

StepBlockPanel.defaultProps = {
  assessmentBlock: {},
  assessmentType: '',
  assessmentUuid: '',
  blockScoresByAttempt: {},
  handleScoreAddModalOpen: undefined,
  handleScoreEditModalOpen: undefined,
  handleDeleteStudentScoresModalOpen: undefined,
  isSingleBlock: false,
  scoreType: '',
  studentBlockResultsByBlock: [],
  studentUuid: '',
  isAdmin: false,
  isMutating: false,
};
