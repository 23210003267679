import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import DeleteModal from '../../Library/Modal/DeleteModal/DeleteModal';
import AccordionRowContainer from '../../Library/AccordionRow/AccordionRowContainer';

const ProgramAssessmentMasterAccordion = styled(
  ({ withBottomBorder, withTopBorder, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  width: 100%;

  .align_right {
    margin-left: auto;

    span {
      font-size: 12px;
      letter-spacing: 0.4;
      color: ${props => props.theme.fontColors.dark};
    }
  }

  .column_one {
    width: 150px;
  }

  .column_one span,
  .column_two span {
    font-size: 15px;
  }

  .accordion_row_label.label {
    width: 150px;

    div {
      font-size: 15px;
    }
  }
`;

const Layout = styled.div`
  width: 100%;
`;

const ProgramAssessmentMaster = ({
  programAssessmentMaster,
  toggleEditView,
  isDeleteModalOpen,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete
}) => {
  const programAssessmentMasterActions = [
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  return (
    <Layout>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={handleDeleteModalClose}
        handleDelete={handleDelete}
        type="Program Assessment Master"
      />

      <ProgramAssessmentMasterAccordion
        label={programAssessmentMaster.assessment_type?.name}
        columnOne={programAssessmentMaster.assessment_association?.association}
        columnTwo={programAssessmentMaster.title}
        actions={programAssessmentMasterActions}
        columnGutters="1.5rem"
        rowOffset="66px"
        expandWidth="42px"
        height="38px"
        columnOneClassName="column_one"
        columnTwoClassName="column_two"
        leaf
      />
    </Layout>
  );
};

ProgramAssessmentMaster.propTypes = {
  programAssessmentMaster: PropTypes.object.isRequired,
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  toggleEditView: PropTypes.func,
  handleDelete: PropTypes.func
};

ProgramAssessmentMaster.defaultProps = {
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  toggleEditView: undefined,
  handleDelete: undefined
};

export default ProgramAssessmentMaster;
