import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getCourseCollections,
  postCourseCollection,
  getCollectionCourseSearch,
  putCourseCollection,
  deleteCourseCollection
} from '../../api/courseCollectionApi';
import {
  doGetCourseCollectionsSuccess,
  doGetCourseCollectionsError,
  doSearchCollectionCoursesSuccess,
  doSearchCollectionCoursesError,
  doPostCourseCollectionSuccess,
  doPostCourseCollectionError,
  doPutCourseCollectionSuccess,
  doPutCourseCollectionError,
  doDeleteCourseCollectionSuccess,
  doDeleteCourseCollectionError
} from '../actions/courseCollectionActions';

import {
  GET_COURSE_COLLECTIONS,
  POST_COURSE_COLLECTION,
  SEARCH_COLLECTION_COURSES,
  PUT_COURSE_COLLECTION,
  DELETE_COURSE_COLLECTION
} from '../actions-type';

function* handleGetCourseCollections(action) {
  const { cohortUuid } = action.payload;
  try {
    const result = yield call(getCourseCollections, cohortUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCourseCollectionsSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetCourseCollectionsError(error));
  }
}

function* handleSearchCollectionCourses(action) {
  const { searchTerm } = action.payload;
  try {
    const result = yield call(getCollectionCourseSearch, searchTerm);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doSearchCollectionCoursesSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doSearchCollectionCoursesError(error));
  }
}

function* handlePostCreateCourseCollection(action) {
  const { newCourseCollection } = action.payload;

  try {
    const result = yield call(postCourseCollection, newCourseCollection);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostCourseCollectionSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPostCourseCollectionError({
          text: errorText
        })
      );
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPostCourseCollectionError());
  }
}

function* handlePutUpdateCourseCollection(action) {
  const { courseCollectionUpdates } = action.payload;

  try {
    const result = yield call(putCourseCollection, courseCollectionUpdates);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutCourseCollectionSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(doPutCourseCollectionError(errorText));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPutCourseCollectionError());
  }
}

function* handleDeleteCourseCollection(action) {
  const { uuid } = action.payload;

  try {
    const result = yield call(deleteCourseCollection, uuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doDeleteCourseCollectionSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.text();
      yield put(
        doDeleteCourseCollectionError({
          text: errorText
        })
      );
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doDeleteCourseCollectionError());
  }
}

const courseCollection = [
  takeEvery(GET_COURSE_COLLECTIONS, handleGetCourseCollections),
  takeEvery(POST_COURSE_COLLECTION, handlePostCreateCourseCollection),
  takeEvery(SEARCH_COLLECTION_COURSES, handleSearchCollectionCourses),
  takeEvery(PUT_COURSE_COLLECTION, handlePutUpdateCourseCollection),
  takeEvery(DELETE_COURSE_COLLECTION, handleDeleteCourseCollection)
];

export { courseCollection };
