import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';
import {useCookies} from "react-cookie";
import {
  doSetUserInstitutionRoleFeature,
  doSetUserSchoolRoleFeature,
  doSetUserDepartmentRoleFeature,
  doSetUserProgramRoleFeature
} from '../../redux/actions/userActions';

import { userActiveSelector } from '../../redux/selectors/usersSelectors';
import { userRolesInstitution } from '../../redux/selectors/userActiveSelectors';
import { doToggleDrawer } from '../../redux/actions/navigationActions';
import { persistor } from '../../redux/store';
import { logoutUser } from '../../api/logoutApi';

import Navigation from './Navigation';

function NavigationContainer() {
  const dispatch = useDispatch();
  const drawerOpen = useSelector(state => state.navigationState.drawerOpen);
  const selectedRole = useSelector(state => state.userState.selectedRole);
  const [,, removeToken] = useCookies(['token']);

  const selectedSchoolUuid = useSelector(
    state => state.userState.selectedSchoolUuid
  );
  const user = useSelector(state => userActiveSelector(state), _.isEqual);

  const institutionRoles = useSelector(
    state => userRolesInstitution(state),
    _.isEqual
  );

  const handleToggleDrawer = () => {
    dispatch(doToggleDrawer());
  };

  const handleUserLogout = async () => {
    await logoutUser();
    await persistor.purge();

    if (process.env.REACT_APP_USE_IBM_CLOUD === "true") {
      window.location.assign(
        `${window.location.protocol}//${window.location.hostname}/appid_logout`
      );
    } else {
      removeToken('token');
      window.location.href = (process.env.REACT_APP_LOGIN_URL || 'login').replace('/oauth2/authorize?','/logout?')
    }
  };

  const handleFeatureSet = ({
    role,
    school,
    department,
    program,
    institution,
    feature,
    featureId
  }) => {
    if (role === 'department_faculty' || role === 'department_staff') {
      dispatch(
        doSetUserDepartmentRoleFeature(
          role,
          school,
          department,
          feature,
          featureId
        )
      );
      return;
    }

    if (role === 'school_admin') {
      dispatch(doSetUserSchoolRoleFeature(role, school, feature, featureId));
      return;
    }

    if (role === 'institution_admin') {
      dispatch(
        doSetUserInstitutionRoleFeature(role, institution, feature, featureId)
      );
      return;
    }

    dispatch(
      doSetUserProgramRoleFeature(role, school, program, feature, featureId)
    );
  };

  return (
    <Navigation
      user={user}
      selectedRole={selectedRole}
      selectedSchoolUuid={selectedSchoolUuid}
      drawerOpen={drawerOpen}
      toggleDrawer={handleToggleDrawer}
      institutionRoles={institutionRoles}
      handleFeatureSet={handleFeatureSet}
      handleUserLogout={handleUserLogout}
    />
  );
}

export default NavigationContainer;
