import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonPrimary, ButtonInline } from '@xcomp/xcomp-design-library';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '@xcomp/xcomp-design-library/dist/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import ButtonOutline from '@xcomp/xcomp-design-library/dist/components/ButtonOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormHelperText } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '../../../Library/Modal/Modal';
import FormField from '../../../Library/FormField';
import FormSelectError from '../../../Library/FormSelectError';
import { convertNullToString } from '../../../../helpers/utilities';
import {
  validateInputString,
  validateSelectDate,
  validateSelectField,
  validateDecimalStringField
} from '../../../../helpers/validation/validateGeneric';
import {
  FormSectionHeader,
  FormSectionHeaderSubtitle
} from '../../../Library/FormComponents';
import SearchMulti from '../../../Library/SearchMulti';
import Date from '../../../Library/Date';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 900px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const SubTitle = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TitleArea = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
`;

const FormArea = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
`;

const ActionsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px;
`;

const CloseButton = styled(ButtonOutline)``;

const SaveButton = styled(ButtonPrimary)`
  && {
    margin-left: 20px;
  }
`;

const LineBreak = styled.div`
  padding-left: 20px;
`;

const TextFieldSC = styled(TextField)`
  && {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;

const MicroSelected = styled.div`
  display: flex;
  align-items: center;
`;

const MicroSelectedRemove = styled.div`
  margin-left: 10px;
  margin-top: 12px;
`;

const MicroList = styled.div`
  border: 1px solid ${props => props.theme.colors.grey[300]};
  padding: 30px;
  border-radius: 4px;
  margin-top: 20px;
`;

const MicroListTitle = styled.div`
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-bottom: 15px;
`;

const MicroCode = styled.div`
  margin-top: 2px;
  margin-bottom: 2px;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 10px;
  border-radius: 4px;
  background: ${props =>
    props.highlight ? props.theme.colors.primary : 'none'};
  color: ${props => (props.highlight ? 'white' : 'none')};
  &:hover {
    border-radius: 4px;
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primary};
  }
`;

const MicroSelection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const FormHelperTextSC = styled(FormHelperText)`
  && {
    color: ${props => props.theme.colors.status.error};
  }
`;

const InvisibleLineBreak = styled.div`
  height: 4px;
`;

const ScoreAdd = ({
  student,
  attempt,
  date,
  tieBackID,
  rubricItem,
  stepSubItem,
  stepSubItemDescription,
  microcompetencyCode,
  microcompetencies,
  relValue,
  potValue,
  grader1Uuid,
  grader2Uuid,
  grader3Uuid,
  resultCardUuid,
  microSelected,
  microcompetencyTitle,
  displayMicros,
  facultyOptions,
  fetching,
  openModal,
  hasErrors,
  createScore,
  handleScoreAddModalClose,
  handleTieBack,
  handleDate,
  handleStepSubItem,
  handleStepSubItemDescription,
  handleRubricItem,
  handleRelValue,
  handlePotValue,
  handleGrader1Change,
  handleGrader2Change,
  handleGrader3Change,
  handleRemoveMicroCode,
  handleSearch,
  handleMicroSelected,
  handleClearSearch,
  handleEstablishedMicro,
  handleCreateScore,
  isSelfAssessment
}) => {
  return (
    <Modal open={openModal} onClose={handleScoreAddModalClose}>
      <ModalBody>
        <Grid container>
          <Grid item sm={12} xs={12}>
            <TitleArea>
              <Grid container direction="row" alignItems="center">
                <Grid item sm={11} xs={11}>
                  <Title>Add Score</Title>
                  <SubTitle>
                    {student.firstName} {student.lastName}
                  </SubTitle>
                  <SubTitle>
                    ATTEMPT {attempt} - {resultCardUuid}
                  </SubTitle>
                </Grid>
                <Grid item sm={1} xs={1}>
                  <CloseModal>
                    <IconButton onClick={handleScoreAddModalClose}>
                      <CloseIcon />
                    </IconButton>
                  </CloseModal>
                </Grid>
              </Grid>
            </TitleArea>
          </Grid>
          <Grid item sm={12} xs={12}>
            {createScore ? <LinearProgress /> : <InvisibleLineBreak />}
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormArea>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormField
                    name="rubricItem"
                    label="Rubric*"
                    value={rubricItem}
                    onBlur={handleRubricItem}
                    hasErrors={hasErrors}
                    handleValidate={validateInputString}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormField
                    name="stepSubItem"
                    label="Step Identifier"
                    value={stepSubItem}
                    onBlur={handleStepSubItem}
                  />
                </Grid>

                <Grid item sm={12} xs={12}>
                  <FormField
                    name="stepSubItemDescription"
                    label="Step Description"
                    value={stepSubItemDescription}
                    onBlur={handleStepSubItemDescription}
                  />
                </Grid>

                <Grid item sm={8} xs={12}>
                  <FormField
                    name="tieBackID"
                    label="Tieback ID*"
                    value={tieBackID}
                    onBlur={handleTieBack}
                    hasErrors={hasErrors}
                    handleValidate={validateInputString}
                  />
                </Grid>

                <Grid item sm={4} xs={12}>
                  <Date
                    name="date"
                    label="Date*"
                    value={date}
                    indentifier={null}
                    hasErrors={hasErrors}
                    handleChange={handleDate}
                    handleValidate={validateSelectDate}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <FormField
                    name="relValue"
                    label="Relative Value*"
                    value={relValue}
                    onBlur={handleRelValue}
                    hasErrors={hasErrors}
                    handleValidate={curRelVal =>
                      validateDecimalStringField(curRelVal, isSelfAssessment)
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormField
                    name="potValue"
                    label="Pot Value*"
                    value={potValue}
                    onBlur={handlePotValue}
                    hasErrors={hasErrors}
                    handleValidate={validateDecimalStringField}
                  />
                </Grid>
                {/* <Grid item sm={4} xs={12}>
                  <FormField
                    name="attempt"
                    label="Attempt*"
                    type="number"
                    value={attempt}
                    onBlur={handleAttempt}
                    hasErrors={hasErrors}
                    handleValidate={validateInputNumber}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid> */}
              </Grid>
            </FormArea>
          </Grid>

          <Grid item sm={12} xs={12}>
            <LineBreak>
              <FormSectionHeader offset="0">
                <FormSectionHeaderSubtitle>
                  Microcompetency
                </FormSectionHeaderSubtitle>
              </FormSectionHeader>
            </LineBreak>
          </Grid>

          <Grid item sm={12} xs={12}>
            <FormArea>
              <Grid item sm={12} xs={12}>
                {microcompetencyCode && microcompetencyCode !== '' ? (
                  <MicroSelected>
                    <TextFieldSC
                      name="microcompetency"
                      label="Selected Microcompetency*"
                      value={`${microcompetencyCode} - ${microcompetencyTitle}`}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      disabled={true}
                    />
                    <MicroSelectedRemove>
                      <IconButton onClick={handleRemoveMicroCode}>
                        <CancelIcon />
                      </IconButton>
                    </MicroSelectedRemove>
                  </MicroSelected>
                ) : (
                  <>
                    <SearchMulti
                      placeholder="Search By"
                      options={[
                        { label: 'Code', field: 'microcompetencyCode' }
                      ]}
                      handleSearch={handleSearch}
                    />
                    {hasErrors &&
                      validateInputString(microcompetencyCode).invalid && (
                        <FormHelperTextSC>
                          Microcompetency Required
                        </FormHelperTextSC>
                      )}
                  </>
                )}
              </Grid>
              <Grid item sm={12} xs={12}>
                {displayMicros &&
                  microcompetencyCode === '' &&
                  microcompetencies.length === 0 && (
                    <MicroList>
                      <MicroListTitle>Select a microcompetency</MicroListTitle>
                      {fetching ? (
                        <CircularProgress />
                      ) : (
                        <div>No results...</div>
                      )}

                      <MicroSelection>
                        <ButtonInline onClick={handleClearSearch}>
                          Cancel
                        </ButtonInline>
                      </MicroSelection>
                    </MicroList>
                  )}
                {displayMicros &&
                  microcompetencyCode === '' &&
                  microcompetencies.length > 0 && (
                    <MicroList>
                      <MicroListTitle>Select a microcompetency</MicroListTitle>
                      {microcompetencies.map(micro => (
                        <MicroCode
                          onClick={() => handleMicroSelected(micro)}
                          key={micro.uuid}
                          highlight={micro.uuid === microSelected.uuid}
                        >
                          {micro.code} - {micro.title}
                        </MicroCode>
                      ))}
                      <MicroSelection>
                        <ButtonInline onClick={handleClearSearch}>
                          Cancel
                        </ButtonInline>
                        <ButtonInline onClick={handleEstablishedMicro}>
                          Confirm
                        </ButtonInline>
                      </MicroSelection>
                    </MicroList>
                  )}
              </Grid>
            </FormArea>
          </Grid>

          <Grid item sm={12} xs={12}>
            <LineBreak>
              <FormSectionHeader offset="0">
                <FormSectionHeaderSubtitle>Graders</FormSectionHeaderSubtitle>
              </FormSectionHeader>
            </LineBreak>
          </Grid>

          <Grid item sm={12} xs={12}>
            <FormArea>
              <Grid container spacing={2}>
                <Grid item sm={4} xs={12}>
                  <FormSelectError
                    name="grader1Uuid"
                    value={convertNullToString(grader1Uuid)}
                    label="Grader 1*"
                    handleChange={handleGrader1Change}
                    options={facultyOptions}
                    hasErrors={hasErrors}
                    handleValidation={validateSelectField}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormSelectError
                    name="grader2Uuid"
                    value={convertNullToString(grader2Uuid)}
                    handleChange={handleGrader2Change}
                    label="Grader 2"
                    options={facultyOptions}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormSelectError
                    name="grader3Uuid"
                    value={convertNullToString(grader3Uuid)}
                    handleChange={handleGrader3Change}
                    label="Grader 3"
                    options={facultyOptions}
                  />
                </Grid>
              </Grid>
            </FormArea>
          </Grid>

          <Grid item sm={12} xs={12}>
            <ActionsArea>
              <CloseButton
                onClick={() => {
                  handleScoreAddModalClose();
                }}
              >
                Close
              </CloseButton>
              <SaveButton onClick={handleCreateScore}>
                {createScore ? 'Saving...' : 'Save'}
              </SaveButton>
            </ActionsArea>
          </Grid>
        </Grid>
      </ModalBody>
    </Modal>
  );
};

ScoreAdd.propTypes = {
  attempt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  createScore: PropTypes.bool,
  date: PropTypes.string,
  displayMicros: PropTypes.bool,
  facultyOptions: PropTypes.array,
  fetching: PropTypes.bool,
  grader1Uuid: PropTypes.string,
  grader2Uuid: PropTypes.string,
  grader3Uuid: PropTypes.string,
  handleClearSearch: PropTypes.func,
  handleCreateScore: PropTypes.func,
  handleDate: PropTypes.func,
  handleEstablishedMicro: PropTypes.func,
  handleGrader1Change: PropTypes.func,
  handleGrader2Change: PropTypes.func,
  handleGrader3Change: PropTypes.func,
  handleMicroSelected: PropTypes.func,
  handlePotValue: PropTypes.func,
  handleRelValue: PropTypes.func,
  handleRemoveMicroCode: PropTypes.func,
  handleRubricItem: PropTypes.func,
  handleScoreAddModalClose: PropTypes.func,
  handleSearch: PropTypes.func,
  handleStepSubItem: PropTypes.func,
  handleStepSubItemDescription: PropTypes.func,
  handleTieBack: PropTypes.func,
  hasErrors: PropTypes.bool,
  isSelfAssessment: PropTypes.bool,
  microcompetencies: PropTypes.array,
  microcompetencyCode: PropTypes.string,
  microcompetencyTitle: PropTypes.string,
  microSelected: PropTypes.object,
  openModal: PropTypes.bool,
  potValue: PropTypes.string,
  relValue: PropTypes.string,
  resultCardUuid: PropTypes.string,
  rubricItem: PropTypes.string,
  stepSubItem: PropTypes.string,
  stepSubItemDescription: PropTypes.string,
  student: PropTypes.object,
  tieBackID: PropTypes.string
};

ScoreAdd.defaultProps = {
  attempt: 0,
  createScore: false,
  date: '',
  displayMicros: false,
  facultyOptions: [],
  fetching: false,
  grader1Uuid: '',
  grader2Uuid: '',
  grader3Uuid: '',
  handleClearSearch: undefined,
  handleCreateScore: undefined,
  handleDate: undefined,
  handleEstablishedMicro: undefined,
  handleGrader1Change: undefined,
  handleGrader2Change: undefined,
  handleGrader3Change: undefined,
  handleMicroSelected: undefined,
  handlePotValue: undefined,
  handleRelValue: undefined,
  handleRemoveMicroCode: undefined,
  handleRubricItem: undefined,
  handleScoreAddModalClose: undefined,
  handleSearch: undefined,
  handleStepSubItem: undefined,
  handleStepSubItemDescription: undefined,
  handleTieBack: undefined,
  hasErrors: false,
  isSelfAssessment: false,
  microcompetencies: [],
  microcompetencyCode: '',
  microcompetencyTitle: '',
  microSelected: {},
  openModal: false,
  potValue: '',
  relValue: '',
  resultCardUuid: '',
  rubricItem: '',
  stepSubItem: '',
  stepSubItemDescription: '',
  student: {},
  tieBackID: ''
};

export default ScoreAdd;
