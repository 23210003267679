import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Chip from '@material-ui/core/Chip';

import { selectSubjectWeight } from '../../../../../../redux/selectors/classificationSelectors';

export default function ProgramSubjectWeight({ programUuid }) {
  const weight = useSelector(
    state => selectSubjectWeight(state, programUuid),
    _.isEqual
  );

  return <Chip size="small" label={`${weight}%`} />;
}

ProgramSubjectWeight.propTypes = {
  programUuid: PropTypes.string
};

ProgramSubjectWeight.defaultProps = {
  programUuid: undefined
};
