import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../../../helpers/format/user.format';
import TopicAlternativeScoreViewFacultyPanel from './TopicAlternativeScoreViewFacultyPanel';

const TopicAlternativeScoreViewFacultyPanelContainer = ({
  facultyUser,
  scoreType,
  students,
  topicScores
}) => {
  const facultyTopicScores = useMemo(
    () =>
      topicScores.filter(
        score =>
          score.grader1Uuid === facultyUser.uuid ||
          score.grader2Uuid === facultyUser.uuid ||
          score.grader3Uuid === facultyUser.uuid
      ),
    [facultyUser.uuid, topicScores]
  );
  const { facultyTotalPotential, facultyTotalRelative } = useMemo(
    () =>
      _.reduce(
        facultyTopicScores,
        (acc, score) => {
          const currentPotential = new Decimal(score.potValue);
          const currentRelative = new Decimal(score.relValue);
          acc.facultyTotalPotential = currentPotential
            .plus(acc.facultyTotalPotential)
            .toFixed(2);
          acc.facultyTotalRelative = currentRelative
            .plus(acc.facultyTotalRelative)
            .toFixed(2);
          return acc;
        },
        {
          facultyTotalRelative: '0.00',
          facultyTotalPotential: '0.00'
        }
      ),
    [facultyTopicScores]
  );
  const facultyFirstName = formatFirstNameMiddleName(
    facultyUser.firstName,
    facultyUser.middleName
  );
  const facultyLastName = formatLastName(
    facultyUser.lastName,
    facultyUser.marriedLastName,
    facultyUser.suffix
  );
  return new Decimal(facultyTotalPotential).greaterThan(0) ? (
    <TopicAlternativeScoreViewFacultyPanel
      facultyFullName={`${facultyLastName}, ${facultyFirstName}`}
      scoreType={scoreType}
      students={students}
      facultyTopicScores={facultyTopicScores}
      facultyTotalPotential={facultyTotalPotential}
      facultyTotalRelative={facultyTotalRelative}
    />
  ) : null;
};

TopicAlternativeScoreViewFacultyPanelContainer.propTypes = {
  facultyUser: PropTypes.object,
  scoreType: PropTypes.string,
  students: PropTypes.array,
  topicScores: PropTypes.array
};
TopicAlternativeScoreViewFacultyPanelContainer.defaultProps = {
  facultyUser: {},
  scoreType: '',
  students: [],
  topicScores: []
};

export default TopicAlternativeScoreViewFacultyPanelContainer;
