import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getCohortGradeSummary,
  getGradeSummary
} from '../../api/gradeSummaryApi';

import {
  doGetGradeSummarySuccess,
  doGetGradeSummaryError
} from '../actions/gradeSummaryActions';

import { GET_GRADE_SUMMARY, GET_COHORT_GRADE_SUMMARY } from '../actions-type';

function* handleGetGradeSummary(action) {
  const { assessmentUuid } = action.payload;
  try {
    const result = yield call(getGradeSummary, assessmentUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetGradeSummarySuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetGradeSummaryError(error));
  }
}

function* handleGetCohortGradeSummary(action) {
  const { assessmentUuid } = action.payload;
  try {
    const result = yield call(getCohortGradeSummary, assessmentUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetGradeSummarySuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetGradeSummaryError(error));
  }
}

const gradeSummary = [
  takeEvery(GET_GRADE_SUMMARY, handleGetGradeSummary),
  takeEvery(GET_COHORT_GRADE_SUMMARY, handleGetCohortGradeSummary)
];

export { gradeSummary };
