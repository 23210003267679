const initialStatusGradingModalState = {
  statusMessages: []
};

const statusGradingModalReducer = (state, action) => {
  switch (action.type) {
    case 'addStatusMessage': {
      const { status, message } = action.payload;
      const newStatusMessages = [...state.statusMessages];

      newStatusMessages.push({ status, message });

      return {
        ...state,
        statusMessages: newStatusMessages
      };
    }

    case 'clearModal':
      return initialStatusGradingModalState;
    default: {
      throw new Error();
    }
  }
};

export { initialStatusGradingModalState, statusGradingModalReducer };
