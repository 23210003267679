import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonOutline, ButtonPrimary } from '@xcomp/xcomp-design-library';
import Grid from '@material-ui/core/Grid';
import Modal from '../../../Library/Modal/Modal';
import FormField from '../../../Library/FormField';
import { validateInputString } from '../../../../helpers/validation/validateGeneric';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 400px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  margin-bottom: 20px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Close = styled(ButtonOutline)`
  && {
    margin-right: 20px;
  }
`;

const Save = styled(ButtonPrimary)``;

const Program = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
`;
const User = styled.div`
  padding-top: 5px;
  padding-bottom: 20px;
`;

export default function ThirdParty({
  program,
  user,
  thirdPartyId,
  openModal,
  hasErrors,
  handleThirdPartyChange,
  handleThirdPartyClose,
  handleSaveThirdParty
}) {
  return (
    <Modal open={openModal} onClose={handleThirdPartyClose}>
      <ModalBody>
        <Title>Third Party ID</Title>
        <Program>Program - {program?.programName}</Program>
        <User>
          {user?.firstName} {user?.lastName}
        </User>
        <Grid container>
          <Grid item sm={12} xs={12}>
            <FormField
              name="thirdPartyId"
              label="Third Party Id"
              value={thirdPartyId}
              onChange={handleThirdPartyChange}
              hasErrors={hasErrors}
              handleValidate={validateInputString}
            />
          </Grid>
        </Grid>
        <Actions>
          <div>
            <Close onClick={handleThirdPartyClose}>Close</Close>
          </div>
          <div>
            <Save onClick={handleSaveThirdParty}>Save</Save>
          </div>
        </Actions>
      </ModalBody>
    </Modal>
  );
}

ThirdParty.propTypes = {
  program: PropTypes.object,
  user: PropTypes.object,
  thirdPartyId: PropTypes.string,
  hasErrors: PropTypes.bool,
  openModal: PropTypes.bool,
  handleThirdPartyChange: PropTypes.func,
  handleThirdPartyClose: PropTypes.func,
  handleSaveThirdParty: PropTypes.func
};

ThirdParty.defaultProps = {
  program: undefined,
  user: undefined,
  thirdPartyId: undefined,
  hasErrors: false,
  openModal: false,
  handleThirdPartyChange: undefined,
  handleThirdPartyClose: undefined,
  handleSaveThirdParty: undefined
};
