import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const selectRigorHistoricalReport = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const getRigorHistoricalReport = ormCreateSelector(
      orm.RigorHistoricalReport
    );

    const settings = getRigorHistoricalReport(redux).filter(
      rigor => rigor.programUuid === programUuid
    )[0];

    return settings;
  }
);
