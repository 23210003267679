import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { selectTranscriptCognitiveTerms } from '../../../../../redux/selectors/transcriptCognitiveSelectors';
import ByTermCognitive from './ByTermCognitiveTerm';

export default function ByTermCognitiveContainer({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source
}) {
  const termYears = useSelector(state =>
    selectTranscriptCognitiveTerms(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source
    )
  );

  return (
    <>
      {termYears.map(termYear => (
        <ByTermCognitive
          key={termYear}
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
          termYear={termYear}
        />
      ))}
    </>
  );
}

ByTermCognitiveContainer.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string
};
ByTermCognitiveContainer.defaultProps = {
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined
};
