const initialCourseCollectionState = {
  uuid: '',
  name: '',
  credits_required: '',
  all_credits_required: false,
  special_collection: false,
  courses: {},
  haveFetchedCohorts: false,
  haveFetchedCourseCollections: false,
  showValidationErrors: false
};

const courseCollectionReducer = (state, action) => {
  switch (action.type) {
    case 'setExistingCourseCollection': {
      const {
        uuid,
        name,
        credits_required,
        all_credits_required,
        special_collection,
        courses
      } = action.payload;

      const newCoursesObject = {};

      courses.forEach(course => {
        newCoursesObject[course.uuid] = course;
      });
      return {
        ...state,
        uuid,
        name,
        credits_required,
        all_credits_required,
        special_collection,
        courses: newCoursesObject
      };
    }
    case 'setName':
      return { ...state, name: action.payload.name };
    case 'setCreditsRequired':
      return { ...state, credits_required: action.payload.credits_required };
    case 'setAllCreditsRequired':
      return {
        ...state,
        all_credits_required: action.payload.all_credits_required
      };
    case 'setSpecialCollection':
      return {
        ...state,
        special_collection: action.payload.special_collection
      };
    case 'setShowValidationErrors':
      return {
        ...state,
        showValidationErrors: action.payload.showValidationErrors
      };
    case 'setHaveFetchedCohorts':
      return {
        ...state,
        haveFetchedCohorts: true
      };
    case 'setHaveFetchedCourseCollections':
      return {
        ...state,
        haveFetchedCourseCollections: true
      };
    case 'addCollectionToCourse': {
      const { course } = action.payload;
      const { courses } = state;
      courses[course.uuid] = course;
      return {
        ...state,
        courses
      };
    }
    case 'removeCollectionFromCourse': {
      const { courseUuid } = action.payload;
      const { courses } = state;
      delete courses[courseUuid];
      return {
        ...state,
        courses
      };
    }
    case 'clearForm': {
      const newState = { ...initialCourseCollectionState, courses: {} };

      return newState;
    }
    default:
      throw new Error();
  }
};

const setName = name => ({ type: 'setName', payload: { name } });

const setCreditsRequired = credits_required => ({
  type: 'setCreditsRequired',
  payload: { credits_required }
});

const setAllCreditsRequired = all_credits_required => ({
  type: 'setAllCreditsRequired',
  payload: { all_credits_required }
});

const setSpecialCollection = special_collection => ({
  type: 'setSpecialCollection',
  payload: { special_collection }
});

const setShowValidationErrors = showValidationErrors => ({
  type: 'setShowValidationErrors',
  payload: { showValidationErrors }
});

const setHaveFetchedCohorts = () => ({
  type: 'setHaveFetchedCohorts'
});

const setHaveFetchedCourseCollections = () => ({
  type: 'setHaveFetchedCourseCollections'
});

const setCollectionCourses = course => ({
  type: 'addCollectionToCourse',
  payload: { course }
});

const removeCollectionCourse = courseUuid => ({
  type: 'removeCollectionFromCourse',
  payload: { courseUuid }
});

export {
  initialCourseCollectionState,
  courseCollectionReducer,
  setName,
  setCreditsRequired,
  setAllCreditsRequired,
  setSpecialCollection,
  setShowValidationErrors,
  setHaveFetchedCohorts,
  setHaveFetchedCourseCollections,
  setCollectionCourses,
  removeCollectionCourse
};
