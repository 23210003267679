import fetch from '../helpers/fetch';

const getCohort = cohortUuid =>
  fetch(`/api/cohort/${cohortUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCohorts = () =>
  fetch('/api/cohorts', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCohortsByProgram = programUuid =>
  fetch(`/api/cohorts/find-by-program?programUuid=${programUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postCohort = cohort =>
  fetch('/api/cohort/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(cohort)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putCohort = cohort =>
  fetch(`/api/cohort/${cohort.uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(cohort)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteCohort = cohortUuid =>
  fetch(`/api/cohort/${cohortUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  getCohort,
  getCohorts,
  getCohortsByProgram,
  postCohort,
  putCohort,
  deleteCohort
};
