import React, { useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import Dashboard from './Dashboard';
import { userActiveSelector } from '../../redux/selectors/usersSelectors';
import { doSetSelectedHomeFeature } from '../../redux/actions/userActions';

export default function DashboardContainer() {
  // eslint-disable-next-line prefer-const
  let dispatch = useDispatch();

  const user = useSelector(state => userActiveSelector(state), _.isEqual);

  useEffect(() => {
    dispatch(doSetSelectedHomeFeature());
  }, [dispatch]);

  return <Dashboard firstName={user.firstName} />;
}
