import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_PART_GRADE_DELAYED_STUDENT_SUCCESS,
  DELETE_PART_LEGACY_GRADES_SUCCESS,
  DELETE_SECTION_GRADE_DELAYED_STUDENT_SUCCESS,
  DELETE_SECTION_LEGACY_GRADES_SUCCESS,
  GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION_SUCCESS,
  GET_COURSE_BY_STUDENT_SUCCESS,
  GET_CURRENT_RESULT_CARDS_SUCCESS,
  GET_SECTION_PERFORMANCE_REPORT_SUCCESS,
  PATCH_PART_UNDO_CALCULATED_GRADE_SUCCESS,
  PATCH_SECTION_GRADE_DELAYED_STUDENT_SUCCESS,
  PUT_PART_DELAYED_STATUS_COMMIT_SUCCESS,
  PUT_PART_GRADE_DELAYED_STUDENT_SUCCESS,
  PUT_SECTION_DELAYED_STATUS_COMMIT_SUCCESS
} from '../actions-type';

class CalculatedGradeResult extends Model {
  static reducer(action, CalculatedGradeResult, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_CURRENT_RESULT_CARDS_SUCCESS: {
        CalculatedGradeResult.all().delete();

        const { partResults, collectionResults } = payload.gradeSummary;

        if (partResults && partResults.length > 0) {
          partResults.map(calculatedGradeResult => {
            return CalculatedGradeResult.upsert(calculatedGradeResult);
          });
        }

        if (collectionResults && collectionResults.length > 0) {
          collectionResults.map(calculatedGradeResult => {
            return CalculatedGradeResult.upsert(calculatedGradeResult);
          });
        }
        break;
      }
      case PATCH_PART_UNDO_CALCULATED_GRADE_SUCCESS: {
        CalculatedGradeResult.all().delete();

        break;
      }
      case DELETE_PART_LEGACY_GRADES_SUCCESS: {
        CalculatedGradeResult.all().delete();

        break;
      }
      case DELETE_SECTION_LEGACY_GRADES_SUCCESS: {
        CalculatedGradeResult.all().delete();

        break;
      }
      case PUT_PART_GRADE_DELAYED_STUDENT_SUCCESS: {
        const { partResults, collectionResults } = payload.gradeSummary;

        if (partResults && partResults.length > 0) {
          partResults.map(calculatedGradeResult => {
            return CalculatedGradeResult.upsert(calculatedGradeResult);
          });
        }

        if (collectionResults && collectionResults.length > 0) {
          collectionResults.map(calculatedGradeResult => {
            return CalculatedGradeResult.upsert(calculatedGradeResult);
          });
        }
        break;
      }
      case PUT_PART_DELAYED_STATUS_COMMIT_SUCCESS: {
        const { calculated_grade_result } = action.payload;

        if (calculated_grade_result)
          return CalculatedGradeResult.upsert(calculated_grade_result);
        break;
      }
      case DELETE_PART_GRADE_DELAYED_STUDENT_SUCCESS: {
        const { partResults, collectionResults } = payload.gradeSummary;

        if (partResults && partResults.length > 0) {
          partResults.map(calculatedGradeResult => {
            return CalculatedGradeResult.upsert(calculatedGradeResult);
          });
        }

        if (collectionResults && collectionResults.length > 0) {
          collectionResults.map(calculatedGradeResult => {
            return CalculatedGradeResult.upsert(calculatedGradeResult);
          });
        }
        break;
      }
      case GET_SECTION_PERFORMANCE_REPORT_SUCCESS: {
        const { sectionPerformanceReport } = payload;

        if (sectionPerformanceReport && sectionPerformanceReport.length > 0) {
          sectionPerformanceReport.map(calculatedGradeResult => {
            return CalculatedGradeResult.upsert(calculatedGradeResult);
          });
        }

        break;
      }
      case PATCH_SECTION_GRADE_DELAYED_STUDENT_SUCCESS: {
        const { sectionPerformanceReport } = payload;

        if (sectionPerformanceReport && sectionPerformanceReport.length > 0) {
          sectionPerformanceReport.map(calculatedGradeResult => {
            return CalculatedGradeResult.upsert(calculatedGradeResult);
          });
        }

        break;
      }
      case PUT_SECTION_DELAYED_STATUS_COMMIT_SUCCESS: {
        const { calculated_grade_result } = action.payload;

        if (calculated_grade_result)
          return CalculatedGradeResult.upsert(calculated_grade_result);
        break;
      }
      case DELETE_SECTION_GRADE_DELAYED_STUDENT_SUCCESS: {
        const { sectionPerformanceReport } = payload;

        if (sectionPerformanceReport && sectionPerformanceReport.length > 0) {
          sectionPerformanceReport.map(calculatedGradeResult => {
            return CalculatedGradeResult.upsert(calculatedGradeResult);
          });
        }

        break;
      }
      case GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION_SUCCESS: {
        const { calculatedPartGradeResultsBySection } = payload;
        calculatedPartGradeResultsBySection.forEach(partGradeResult =>
          CalculatedGradeResult.upsert(partGradeResult)
        );
        break;
      }
      case GET_COURSE_BY_STUDENT_SUCCESS: {
        const calculated_grade_result = [...payload.calculated_grade_result];

        if (calculated_grade_result?.length > 0) {
          calculated_grade_result.forEach(result => {
            const {
              uuid,
              requiredFail,
              classRank,
              calculatedGrade,
              gradeModification,
              relativeScore,
              potentialScore,
              studentUuid,
              partUuid,
              gradeCollectionUuid,
              sectionUuid
            } = result;

            CalculatedGradeResult.upsert({
              uuid,
              requiredFail,
              classRank,
              calculatedGrade,
              gradeModification,
              relativeScore,
              potentialScore,
              studentUuid,
              partUuid,
              gradeCollectionUuid,
              sectionUuid
            });
          });
        }

        break;
      }
      default:
        return session;
    }
  }
}

CalculatedGradeResult.fields = {
  uuid: attr(),
  calculatedGrade: attr(),
  collection_assessments: attr(),
  createdAt: attr(),
  gradeCollectionUuid: fk('GradeCollection', 'calculated_grade_results'),
  partUuid: fk('Part', 'calculated_grade_results'),
  potentialScore: attr(),
  relativeScore: attr(),
  sectionUuid: fk('Section', 'calculated_grade_results'),
  studentUuid: fk('User', 'calculated_grade_results'),
  updatedAt: attr()
};

CalculatedGradeResult.modelName = 'CalculatedGradeResult';

CalculatedGradeResult.options = {
  idAttribute: 'uuid'
};

export default CalculatedGradeResult;
