import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Panel } from '../../../Library/DashboardSC';
import PartTopicReportPanelContainer from './PartTopicReportPanel/PartTopicReportPanelContainer';
import FilterDropDown from '../FilterDropDown';

const PanelSC = styled(Panel)`
  margin-top: 24px;
`;

const ProgressBar = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.fontColors.dark};
`;

const NoDataContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.fontColors.dark};
`;

const AssessmentHeaderContainer = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  height: 48px;
  width: 100%;
  display: flex;
  padding-right: ${props => props.paddingRight};
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: flex-start;
  border-bottom: ${props => `solid 1px  ${props.theme.colors.grey[300]}`};
  font-size: 15px;
  font-weight: 450;
`;

const AssessmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 66px;
  width: calc(100% - 66px);
`;

const AssessmentHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  overflow: hidden;
`;

const AssessmentHeaderData = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  min-width: 456px;
`;

const AssessmentHeaderSub = styled('div')`
  min-width: 100px;
  margin-right: ${props => props.marginRight};
  color: ${props => props.theme.fontColors.darker};
  text-align: right;
`;

const PartTopicReport = ({
  assessments,
  cumulativePotentialScore,
  cumulativeRelativeScore,
  hasAppliedFilters,
  isLoading,
  onApplyPartTopicReportFilters,
  partTopicReportAssessmentData,
  partTopicReportTopicData,
  partTopics,
  partUuid,
  reportIsEmpty,
  sortedPartTopics
}) => {
  return (
    <>
      <FilterDropDown
        options={partTopics}
        callback={onApplyPartTopicReportFilters}
        isTopicFilter
        title='Topic Report Filters'
      />
      {isLoading ? (
        <PanelSC>
          <ProgressBar>Loading</ProgressBar>
          <LinearProgress />
        </PanelSC>
      ) : (
        <PanelSC>
          {!reportIsEmpty && (
            <AssessmentHeaderContainer paddingRight="44px">
              <AssessmentHeader>
                <AssessmentHeaderInfo>Topic</AssessmentHeaderInfo>
                <AssessmentHeaderData>
                  <AssessmentHeaderSub marginRight="16px">
                    Participation (%)
                  </AssessmentHeaderSub>
                  <AssessmentHeaderSub marginRight="16px">
                    Relative (%)
                  </AssessmentHeaderSub>
                  <AssessmentHeaderSub marginRight="16px">
                    Total Relative
                  </AssessmentHeaderSub>
                  <AssessmentHeaderSub marginRight="8px">
                    Total Potential
                  </AssessmentHeaderSub>
                </AssessmentHeaderData>
              </AssessmentHeader>
            </AssessmentHeaderContainer>
          )}
          <PartTopicReportPanelContainer
            assessments={assessments}
            cumulativePotentialScore={cumulativePotentialScore}
            isSummaryPanel={true}
            partTopicReportAssessmentData={partTopicReportAssessmentData}
            partTopicTotalPotentialScore={cumulativePotentialScore}
            partTopicTotalRelativeScore={cumulativeRelativeScore}
            partUuid={partUuid}
          />
          {hasAppliedFilters && reportIsEmpty && (
            <NoDataContainer>
              No data for the selected filters. Change filter selection and
              re-apply.
            </NoDataContainer>
          )}
          {!hasAppliedFilters && reportIsEmpty && (
            <NoDataContainer>
              Apply the filter selection to view the report.
            </NoDataContainer>
          )}
          {!reportIsEmpty &&
            sortedPartTopics.map(topic => (
              <PartTopicReportPanelContainer
                key={topic.uuid}
                assessments={assessments}
                cumulativePotentialScore={cumulativePotentialScore}
                partTopicReportAssessmentData={partTopicReportAssessmentData}
                partTopicTotalPotentialScore={
                  partTopicReportTopicData[topic.uuid]?.totalPotentialScore
                }
                partTopicTotalRelativeScore={
                  partTopicReportTopicData[topic.uuid]?.totalRelativeScore
                }
                partUuid={partUuid}
                topic={topic}
              />
            ))}
        </PanelSC>
      )}
    </>
  );
};

PartTopicReport.propTypes = {
  assessments: PropTypes.array,
  cumulativePotentialScore: PropTypes.string,
  cumulativeRelativeScore: PropTypes.string,
  hasAppliedFilters: PropTypes.bool,
  isLoading: PropTypes.bool,
  onApplyPartTopicReportFilters: PropTypes.func,
  partTopicReportAssessmentData: PropTypes.object,
  partTopicReportTopicData: PropTypes.object,
  partTopics: PropTypes.array,
  partUuid: PropTypes.string,
  reportIsEmpty: PropTypes.bool,
  sortedPartTopics: PropTypes.array
};

PartTopicReport.defaultProps = {
  assessments: [],
  cumulativePotentialScore: '0.00',
  cumulativeRelativeScore: '0.00',
  hasAppliedFilters: false,
  isLoading: false,
  onApplyPartTopicReportFilters: undefined,
  partTopicReportAssessmentData: {},
  partTopicReportTopicData: {},
  partTopics: [],
  partUuid: '',
  reportIsEmpty: true,
  sortedPartTopics: []
};

export default PartTopicReport;
