import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { selectTermGPA } from '../../../../redux/selectors/gpaSelectors';

const Accumulative = styled.div`
  display: flex;
  flex-direction: column;
`;
const Numerator = styled.div`
  border-bottom: 2px solid ${props => props.theme.colors.grey[300]};
`;
const Denominator = styled.div`
  font-weight: bold;
`;

export default function TermGPA({ userUuid, termUuid, terms, termIndex }) {
  const list = _.slice(terms, 0, termIndex + 1);

  const listIds = list.map(term => term.uuid);

  const credits = useSelector(
    state => selectTermGPA(state, userUuid, termUuid, listIds),
    _.isEqual
  );

  const { gpa_at_term, gpa_to_term } = credits;

  return (
    <Accumulative>
      <Numerator>{gpa_at_term}</Numerator>
      <Denominator>{gpa_to_term}</Denominator>
    </Accumulative>
  );
}

TermGPA.propTypes = {
  userUuid: PropTypes.string,
  termUuid: PropTypes.string,
  terms: PropTypes.array,
  termIndex: PropTypes.number
};

TermGPA.defaultProps = {
  userUuid: undefined,
  termUuid: undefined,
  terms: undefined,
  termIndex: undefined
};
