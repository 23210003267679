import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import ScoresUpload from './ScoresUpload';

import {
  doGetScores,
  doUploadScores
} from '../../../../redux/actions/scoreActions';
import {
  doGetAssessmentBlocks,
  doGetAssessmentBlockResults
} from '../../../../redux/actions/assessmentBlockActions';

import { useWsSubscriber } from '../../../../helpers/hooks/subscription.hooks';

export default function ScoresUploadContainer({
  assessmentUuid,
  sectionUuid,
  partUuid,
  openUploadScores,
  handleUploadClose
}) {
  const dispatch = useDispatch();
  const [scores, setScores] = useState(undefined);
  const [openMapping, setOpenMapping] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);

  const query = `assessmentUuid=${assessmentUuid}`;
  const pathSubscribe = assessmentUuid
    ? `scores/upload/subscribe-status?${query}`
    : undefined;

  const redisKey = `${assessmentUuid}-scores-upload`;
  const ws = useWsSubscriber(pathSubscribe, redisKey);
  const subscription = ws.result;
  const connected = ws.readyState;
  const emitter = ws.send;
  const status = subscription?.status;

  useEffect(() => {
    if (connected) {
      emitter(redisKey);
    }
  }, [redisKey, emitter, connected]);

  useEffect(() => {
    if (status === 'success') {
      dispatch(doGetScores(assessmentUuid, sectionUuid, partUuid));
      dispatch(doGetAssessmentBlocks(assessmentUuid));
      dispatch(doGetAssessmentBlockResults(assessmentUuid));
    }
  }, [dispatch, status, assessmentUuid, sectionUuid, partUuid]);

  const handleScores = scores => {
    setScores(scores);
  };

  const handleUpload = () => {
    const uploadDate = moment().format('YYYY-MM-DD');
    dispatch(doUploadScores(assessmentUuid, scores, uploadDate));
  };

  const handleDisplayMapping = () => {
    setOpenMapping(openMapping => !openMapping);
  };

  const handleDisplayErrors = () => {
    setOpenError(openError => !openError);
  };

  const handleDisplaySummary = () => {
    setOpenSummary(openSummary => !openSummary);
  };

  const handleClearResults = () => {
    emitter(`${redisKey}-clear`);
  };

  return (
    <ScoresUpload
      status={subscription?.status}
      error={subscription?.error}
      summary={subscription?.summary}
      uploadDate={subscription?.uploadDate}
      openModal={openUploadScores}
      openMapping={openMapping}
      openError={openError}
      openSummary={openSummary}
      scores={scores}
      handleDisplayMapping={handleDisplayMapping}
      handleDisplayErrors={handleDisplayErrors}
      handleDisplaySummary={handleDisplaySummary}
      handleUploadClose={handleUploadClose}
      handleScores={handleScores}
      handleUpload={handleUpload}
      handleClearResults={handleClearResults}
    />
  );
}

ScoresUploadContainer.propTypes = {
  assessmentUuid: PropTypes.string,
  sectionUuid: PropTypes.string,
  partUuid: PropTypes.string,
  openUploadScores: PropTypes.bool,
  handleUploadClose: PropTypes.func
};
ScoresUploadContainer.defaultProps = {
  assessmentUuid: '',
  sectionUuid: '',
  partUuid: '',
  openUploadScores: false,
  handleUploadClose: undefined
};
