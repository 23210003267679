import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { doGetSectionCommitmentLog } from '../../../redux/actions/sectionActions';
import { sectionSelector } from '../../../redux/selectors/sectionsSelectors';

import SectionCommitmentLog from './SectionCommitmentLog';
import { commitmentLogsSelectorBySectionUuid } from '../../../redux/selectors/commitmentLogSelectors';
import { sortByDateProperty } from '../../../helpers/utilities';
import { courseMasterSelector } from '../../../redux/selectors/coursesMasterSelectors';

export default function SectionCommitmentLogContainer() {
  const dispatch = useDispatch();
  const { sectionUuid } = useParams();

  const [haveFetchedLogsFor, setHaveFetchedLogsFor] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortReverseChron, setSortReverseChron] = useState(false);

  const section = useSelector(
    state => sectionSelector(state, sectionUuid),
    _.isEqual
  );

  const courseMaster = useSelector(
    state => courseMasterSelector(state, section?.course?.courseMasterUuid),
    _.isEqual
  );

  const commitmentLogs = useSelector(
    state => commitmentLogsSelectorBySectionUuid(state, sectionUuid),
    _.isEqual
  );

  const handleQueryChange = event => {
    setSearchQuery(event.target.value);
  };

  const toggleSortReverse = () => {
    setSortReverseChron(!sortReverseChron);
  };

  useEffect(() => {
    if (commitmentLogs.length === 0 && haveFetchedLogsFor !== sectionUuid) {
      dispatch(doGetSectionCommitmentLog(sectionUuid));
      setHaveFetchedLogsFor(sectionUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionUuid, commitmentLogs, haveFetchedLogsFor]);

  const filteredCommitmentLogs = searchQuery
    ? commitmentLogs.filter(log => {
        if (!searchQuery || searchQuery.trim() === '') {
          return true;
        }

        const doesSummaryMatchQuery = log.summary
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());

        const doesJustificationyMatchQuery = log.justificationMessage
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());

        if (doesSummaryMatchQuery || doesJustificationyMatchQuery) {
          return true;
        }
        return false;
      })
    : commitmentLogs;

  const sortedCommitmentLogs = filteredCommitmentLogs.sort((a, b) =>
    !sortReverseChron
      ? sortByDateProperty(a, b, 'createdAt')
      : sortByDateProperty(b, a, 'createdAt')
  );

  return (
    <SectionCommitmentLog
      section={section}
      courseMaster={courseMaster}
      handleQueryChange={handleQueryChange}
      toggleSortReverse={toggleSortReverse}
      commitmentLogs={sortedCommitmentLogs || []}
    />
  );
}
