import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const partSelector = createSelector(
  state => state,
  state => state.syllabusState.selectedPartUuid,
  (redux, selectedPartUuid) => {
    const partSelectorORM = ormCreateSelector(orm.Part);
    const part = partSelectorORM(redux, selectedPartUuid);

    return part;
  }
);

export const partsSelector = createSelector(
  state => state,
  redux => {
    const partSelectorORM = ormCreateSelector(orm.Part);
    const parts = partSelectorORM(redux);

    return parts;
  }
);

export const partsSelectorBySection = createSelector(
  state => state,
  state => state.syllabusState.selectedSectionUuid,
  (redux, selectedSectionUuid) => {
    const partSelectorORM = ormCreateSelector(orm.Part);
    const parts = partSelectorORM(redux).filter(
      part => part.sectionUuid === selectedSectionUuid
    );

    return parts;
  }
);

export const partsSelectorBySectionUuid = createSelector(
  state => state,
  (_, sectionUuid) => sectionUuid,
  (redux, sectionUuid) => {
    const partSelectorORM = ormCreateSelector(orm.Part);
    const parts = partSelectorORM(redux).filter(
      part => part.sectionUuid === sectionUuid
    );

    return parts;
  }
);

export const partSelectorByUuid = createSelector(
  state => state,
  (_, partUuid) => partUuid,
  (redux, partUuid) => {
    const partSelectorORM = ormCreateSelector(orm.Part);
    const part = partSelectorORM(redux, partUuid);

    return part;
  }
);

export const partFacultySelector = createSelector(
  state => state,
  (_, partUuid) => partUuid,
  (redux, partUuid) => {
    const partFaculty = ormCreateSelector(orm.PartFaculty);

    const faculty =
      partFaculty(redux).filter(
        partFaculty => partFaculty.partUuid === partUuid
      ) || [];

    return faculty;
  }
);

export const selectPart = ormCreateSelector(orm.Part);
