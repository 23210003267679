import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2, Subtitle1, Caption } from '@xcomp/xcomp-design-library';
import { CheckIcon } from '@xcomp/xcomp-design-library/dist/icons';

import { Panel, PanelHeader } from '../../../Library/Layout';
import { gradingTechniquesMap } from '../../../../helpers/constants';

const formatSummaryScores = (value, isPointsOnly) => {
  if (Number.isNaN(Number(value)) || !value) return '--';
  return isPointsOnly ? value : `${value}%`;
};

const PanelHeaderSC = styled(PanelHeader)`
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 24px;
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const GradeSummaryPanelSC = styled(Panel)`
  margin-bottom: 40px;
`;

const SummaryPanelHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CheckIconSC = styled(CheckIcon)`
  color: ${props => props.theme.fontColors.dark};
  margin-right: 8px;
`;

const SummaryScores = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ScoreRightContain = styled.div`
  width: 40%;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const SummaryScoreColumn = styled.div`
  margin-right: 64px;

  &:last-child {
    margin-right: 0;
  }
`;

const ScoreLabel = styled(Caption)`
  margin-bottom: 0;
  margin: 0 0 4px;
`;

const SummaryScore = styled(Heading2)`
  margin: 0;
`;

const GradeSummaryHighLowAvg = ({ gradeSummary, isPointsOnly }) => {
  const highScore = Number(gradeSummary.highScore).toFixed(2);
  const avgScore = Number(gradeSummary.averageScore).toFixed(2);
  const lowScore = Number(gradeSummary.lowScore).toFixed(2);

  return (
    <GradeSummaryPanelSC>
      <PanelHeaderSC paddingLeft="24px">
        <SummaryPanelHeader>
          <Heading2>Grade Summary</Heading2>
          <ScoreRightContain>
            <CheckIconSC />
            <Subtitle1>
              {gradingTechniquesMap[gradeSummary.gradingTechnique]}
            </Subtitle1>
          </ScoreRightContain>
        </SummaryPanelHeader>
      </PanelHeaderSC>
      <PanelPadding>
        <SummaryScores>
          <ScoreRightContain>
            <SummaryScoreColumn>
              <ScoreLabel>High</ScoreLabel>
              <SummaryScore>
                {formatSummaryScores(highScore, isPointsOnly)}
              </SummaryScore>
            </SummaryScoreColumn>
            <SummaryScoreColumn>
              <ScoreLabel>Average</ScoreLabel>
              <SummaryScore>
                {formatSummaryScores(avgScore, isPointsOnly)}
              </SummaryScore>
            </SummaryScoreColumn>
            <SummaryScoreColumn>
              <ScoreLabel>Low</ScoreLabel>
              <SummaryScore>
                {formatSummaryScores(lowScore, isPointsOnly)}
              </SummaryScore>
            </SummaryScoreColumn>
          </ScoreRightContain>
        </SummaryScores>
      </PanelPadding>
    </GradeSummaryPanelSC>
  );
};

GradeSummaryHighLowAvg.propTypes = {
  gradeSummary: PropTypes.object,
  isPointsOnly: PropTypes.bool
};

GradeSummaryHighLowAvg.defaultProps = {
  gradeSummary: { highScore: '--', lowScore: '--', averageScore: '--' },
  isPointsOnly: false
};

export default GradeSummaryHighLowAvg;
