import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { topicSelector } from '../../../../redux/selectors/topicSelectors';
import { decimalTenth } from '../../../../helpers/utilities';
import { ScoreRow, ScoreDetail } from '../ScoresSC';

const fillEmptyRow = string => {
  return !string || string === '' ? '--' : string;
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  &:hover {
    background: ${props => props.theme.colors.grey[300]};
    svg {
      display: block;
    }
  }
`;

export default function FacultyStudentScore({
  uuid,
  date,
  rubricItem,
  stepSubItem,
  sequenceOrder,
  rubricOrder,
  procedureCodeOrder,
  procedureCodeDescriptor,
  stepSubItemDescription,
  topicUuid,
  relValue,
  potValue,
  isOpportunity,
}) {
  const topic = useSelector(
    state => topicSelector(state, topicUuid),
    shallowEqual
  );
  return (
    <Layout key={uuid}>
      <ScoreRow marginLeft="100px">
        <ScoreDetail width="10%">
          {fillEmptyRow(moment(date).format('M/D/YYYY'))}
        </ScoreDetail>
        <ScoreDetail width="10%">{fillEmptyRow(rubricItem)}</ScoreDetail>
        {!isOpportunity && 
          <>
            <ScoreDetail width="5%">{fillEmptyRow(sequenceOrder)}</ScoreDetail>
            <ScoreDetail width="5%">{fillEmptyRow(rubricOrder)}</ScoreDetail>
          </>
        }
        {isOpportunity && 
          <>
            <ScoreDetail width="5%">{fillEmptyRow(procedureCodeOrder)}</ScoreDetail>
            <ScoreDetail width="5%">{fillEmptyRow(procedureCodeDescriptor)}</ScoreDetail>
          </>
        }
        <ScoreDetail width="10%">{fillEmptyRow(stepSubItem)}</ScoreDetail>
        <ScoreDetail width="15%">
          {stepSubItemDescription && stepSubItemDescription !== ''
            ? stepSubItemDescription
            : 'no description'}
        </ScoreDetail>
        <ScoreDetail width={isOpportunity ? "36%" :"26%"}>
          {topic.title && topic.title !== '' ? topic.title : 'no topic'}
        </ScoreDetail>
        <ScoreDetail width="7%">
          {fillEmptyRow(decimalTenth(relValue))}
        </ScoreDetail>
        <ScoreDetail width="7%">
          {fillEmptyRow(decimalTenth(potValue))}
        </ScoreDetail>
      </ScoreRow>
    </Layout>
  );
}

FacultyStudentScore.propTypes = {
  uuid: PropTypes.string,
  date: PropTypes.string,
  rubricItem: PropTypes.string,
  stepSubItem: PropTypes.string,
  stepSubItemDescription: PropTypes.string,
  topicUuid: PropTypes.string,
  relValue: PropTypes.string,
  potValue: PropTypes.string,
  sequenceOrder: PropTypes.number,
  rubricOrder: PropTypes.number,
  procedureCodeOrder: PropTypes.number,
  procedureCodeDescriptor: PropTypes.string,
  isOpportunity: PropTypes.bool,
};

FacultyStudentScore.defaultProps = {
  isOpportunity: false,
  uuid: '',
  date: '',
  rubricItem: '',
  stepSubItem: '',
  stepSubItemDescription: '',
  topicUuid: '',
  relValue: '',
  potValue: '',
  procedureCodeOrder: '',
  procedureCodeDescriptor: '',
  sequenceOrder: undefined,
  rubricOrder: undefined,
};
