import { atom } from 'jotai';
import { fetchWithState } from '../../../../../../helpers/utilities';

const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index + 1)
];

const newProcedureCodeInitialState = {
  code: '',
  procedureChecked: false,
  procedureThreshold: '',
  procedureRank: false,
  procedureGrade: false,
  scoreChecked: false,
  scoreThreshold: '',
  scoreRank: false,
  scoreGrade: false,
  unitsChecked: false,
  unitsThreshold: '',
  unitsRank: false,
  unitsGrade: false,
  epasChecked: false,
  epasThreshold: '',
  epasRank: false,
  epasGrade: false
};

// Skill Collection Start
// the following atoms are used to fetch the collection details
export const collectionAtom = atom({
  name: '',
  is_default_collection: false,
  procedure_threshold: '0.00',
  score_threshold: '0.00',
  units_threshold: '0.00',
  epas_threshold: '0.00',
  rvus_threshold: '0.00'
});
export const letterGradesAtom = atom([]);
export const fetchingCollectionAtom = atom(false);
export const updatingAtom = atom(false);
export const getCollectionDetails = atom(
  get => get(collectionAtom),
  async (get, set, { quantitativeSkillUuid, programUuid }) => {
    set(fetchingCollectionAtom, true);

    const { value } = await fetchWithState(
      `/api/program/${programUuid}/quantitative-skills-collections/${quantitativeSkillUuid}`
    );

    set(collectionAtom, value.quantitativeSkillsCollection);
    set(letterGradesAtom, value.letterGrades);
    set(fetchingCollectionAtom, false);
  }
);
// Skill Collection End

// Edit Collection Name Start
// the following atoms are used to edit(UI) and update(BE) the collection name
export const editCollectionNameAtom = atom(null, (get, set, value) => {
  set(collectionAtom, {
    ...get(collectionAtom),
    ...value
  });
});
export const updateCollection = atom(
  get => get(collectionAtom),
  async (get, set, collectionUuid) => {
    set(updatingAtom, true);
    const state = get(collectionAtom);
    await fetchWithState(
      `/api/quantitative-skills/${collectionUuid}/update`,
      'PUT',
      JSON.stringify({
        name: state.name,
        is_default_collection: state.is_default_collection,
        rvus_threshold: state.rvus_threshold,
        score_threshold: state.score_threshold,
        units_threshold: state.units_threshold,
        epas_threshold: state.epas_threshold,
        procedure_threshold: state.procedure_threshold
      })
    );

    set(updatingAtom, false);
  }
);
// Edit Collection Name End

// Procedure Code Group Start
// the following atoms are used to fetch, create, edit/update and delete the procedure code groups
export const creatingGroupAtom = atom(false);
export const newGroupModalAtom = atom(false);
export const newGroupAtom = atom({
  name: '',
  description: '',
  procedure_threshold: '',
  rvus_threshold: '',
  score_threshold: '',
  units_threshold: '',
  epas_threshold: ''
});
export const createGroupAtom = atom(
  get => get(newGroupAtom),
  async (get, set, collectionUuid) => {
    set(creatingGroupAtom, true);

    await fetchWithState(
      `/api/quantitative-skills/${collectionUuid}/groups/create`,
      'POST',
      JSON.stringify(get(newGroupAtom))
    );

    set(newGroupAtom, {
      name: '',
      description: '',
      procedure_threshold: '',
      rvus_threshold: '',
      score_threshold: '',
      units_threshold: '',
      epas_threshold: ''
    });

    set(creatingGroupAtom, false);
  }
);
export const updatingGroupAtom = atom({});
export const updateGroupAtom = atom(
  null,
  async (
    get,
    set,
    { collectionUuid, uuid, quantitative_skills_procedure_codes, ...rest }
  ) => {
    set(updatingGroupAtom, { [uuid]: true });

    await fetchWithState(
      `/api/quantitative-skills/${collectionUuid}/groups/update`,
      'POST',
      JSON.stringify({ uuid, ...rest })
    );

    set(updatingGroupAtom, { [uuid]: false });
  }
);
export const editGroupAtom = atom(
  get => get(collectionAtom),
  (get, set, value) => {
    const state = get(collectionAtom);
    const group = state.procedure_code_groups.find(
      group => group.uuid === value.uuid
    );
    const index = state.procedure_code_groups.indexOf(group);

    set(collectionAtom, {
      ...state,
      procedure_code_groups: insert(state.procedure_code_groups, index, value)
    });
  }
);
export const deletingGroupAtom = atom({});
export const deleteGroupModalAtom = atom({});

export const selectedGroupAtom = atom(null);
export const deleteGroupAtom = atom(null, async (get, set, { uuid }) => {
  set(deletingGroupAtom, { [uuid]: true });

  await fetchWithState(
    `/api/quantitative-skills/group/delete/${uuid}`,
    'DELETE'
  );

  set(deletingGroupAtom, { [uuid]: false });
  set(deleteGroupModalAtom, { [get(selectedGroupAtom).uuid]: false });
  set(selectedGroupAtom, null);
});
// Procedure Code Group End

// Procedure Code Start
// the following atoms are used to fetch, create, edit/update and delete the procedure codes
export const newProcedureCodeModalAtom = atom(false);
export const creatingProcedureCodeAtom = atom(false);
export const updatingProcedureCodeAtom = atom({});
export const newProcedureCodeAtom = atom(newProcedureCodeInitialState);
export const newProcedureRuleAtom = atom(
  get => get(newProcedureCodeAtom),
  (get, set, value) => {
    set(newProcedureCodeAtom, {
      ...get(newProcedureCodeAtom),
      ...value
    });
  }
);
export const createProcedureCodeAtom = atom(null, async (get, set) => {
  set(creatingProcedureCodeAtom, true);
  const groupUuid = get(selectedGroupAtom);

  await fetchWithState(
    `/api/quantitative-skills/${groupUuid}/procedure-codes/create`,
    'POST',
    JSON.stringify(get(newProcedureCodeAtom))
  );

  set(creatingProcedureCodeAtom, false);
  set(newProcedureCodeModalAtom, false);
  set(newProcedureCodeAtom, newProcedureCodeInitialState);
});

export const editProcedureCodeAtom = atom(
  get => get(collectionAtom),
  (get, set, { groupUuid, procedureCode }) => {
    const state = get(collectionAtom);

    const group = state.procedure_code_groups.find(
      group => group.uuid === groupUuid
    );

    const code = group.quantitative_skills_procedure_codes.find(
      code => code.uuid === procedureCode.uuid
    );

    const groupIndex = state.procedure_code_groups.indexOf(group);
    const codeIndex =
      state.procedure_code_groups[
        groupIndex
      ].quantitative_skills_procedure_codes.indexOf(code);

    set(collectionAtom, {
      ...state,
      procedure_code_groups: insert(state.procedure_code_groups, groupIndex, {
        ...group,
        quantitative_skills_procedure_codes: insert(
          group.quantitative_skills_procedure_codes,
          codeIndex,
          procedureCode
        )
      })
    });
  }
);

export const updateProcedureCodeAtom = atom(
  null,
  async (get, set, procedureCode) => {
    set(updatingProcedureCodeAtom, { [procedureCode.uuid]: true });

    await fetchWithState(
      `/api/quantitative-skills/groups/code/update`,
      'PUT',
      JSON.stringify(procedureCode)
    );

    set(updatingProcedureCodeAtom, { [procedureCode.uuid]: false });
  }
);

export const selectedProcedureCodeAtom = atom(null);

export const deleteProcedureCodeModalAtom = atom(false);
export const deletingProcedureCodeAtom = atom({});
export const deleteProcedureCode = atom(null);
export const deleteProcedureCodeAtom = atom(
  null,
  async (get, set, { uuid }) => {
    set(deletingProcedureCodeAtom, { [uuid]: true });

    await fetchWithState(
      `/api/quantitative-skills/group/code/delete/${uuid}`,
      'DELETE'
    );

    set(deletingProcedureCodeAtom, { [uuid]: false });
    set(deleteProcedureCodeModalAtom, false);
  }
);
// Procedure Code End

// Procedure Code Search Start
// the following atoms are used to search(code or description) for the procedure codes
export const searchProcedureCodeAtom = atom('');
export const searchProcedureCodeResultsAtom = atom([]);
export const searchProcedureCodeFetchingAtom = atom(false);
export const searchProcedureCode = atom(null, async (get, set) => {
  set(searchProcedureCodeFetchingAtom, true);
  const searchTerm = get(searchProcedureCodeAtom);

  const { value: results } = await fetchWithState(
    `/api/quantitative-skills/search?searchTerm=${searchTerm}`
  );

  set(
    searchProcedureCodeResultsAtom,
    results.map(procedureCode => ({
      label: `${procedureCode.code} - ${procedureCode.description}`,
      value: procedureCode.uuid
    }))
  );

  set(searchProcedureCodeFetchingAtom, false);
});
// Procedure Code Search End
