export const GET_STUDENT_ROSTER = 'GET_STUDENT_ROSTER';
export const GET_STUDENT_ROSTER_SUCCESS = 'GET_STUDENT_ROSTER_SUCCESS';
export const GET_STUDENT_ROSTER_ERROR = 'GET_STUDENT_ROSTER_ERROR';
export const POST_USER_SECTION = 'POST_USER_SECTION';
export const POST_USER_SECTION_SUCCESS = 'POST_USER_SECTION_SUCCESS';
export const POST_USER_SECTION_ERROR = 'POST_USER_SECTION_ERROR';
export const PATCH_STUDENT_SECTION_STATUS = 'PATCH_STUDENT_SECTION_STATUS';
export const PATCH_STUDENT_SECTION_STATUS_SUCCESS =
  'PATCH_STUDENT_SECTION_STATUS_SUCCESS';
export const PATCH_STUDENT_SECTION_STATUS_ERROR =
  'PATCH_STUDENT_SECTION_STATUS_ERROR';
export const POST_UPLOAD_STUDENTS_SECTION = 'POST_UPLOAD_STUDENTS_SECTION';
export const POST_UPLOAD_STUDENTS_SECTION_SUCCESS =
  'POST_UPLOAD_STUDENTS_SECTION_SUCCESS';
export const POST_UPLOAD_STUDENTS_SECTION_WARNING =
  'POST_UPLOAD_STUDENTS_SECTION_WARNING';
export const POST_UPLOAD_STUDENTS_SECTION_ERROR =
  'POST_UPLOAD_STUDENTS_SECTION_ERROR';
export const RESET_UPLOAD_STUDENTS_SECTION = 'RESET_UPLOAD_STUDENTS_SECTION';

export const DELETE_USERS_SECTION = 'DELETE_USERS_SECTION';
export const DELETE_USERS_SECTION_SUCCESS = 'DELETE_USERS_SECTION_SUCCESS';
export const DELETE_USERS_SECTION_ERROR = 'DELETE_USERS_SECTION_ERROR';
