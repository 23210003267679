import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Body1,
  Body2,
  Heading3,
  ButtonOutline
} from '@xcomp/xcomp-design-library';
import { CompetencyIcon } from '@xcomp/xcomp-design-library/dist/icons';
import Tooltip from '@material-ui/core/Tooltip';
import LazyLoad from 'react-lazyload';
import StepPanel from './StepPanel';
// import FormSelect from '../../../Library/FormSelect';
// import { optionsSortStep } from '../../../../helpers/options';
import { Panel, PanelHeader } from '../../../Library/DashboardSC';

const HeaderSpacer = styled.div`
  width: 100%;
  height: 36px;
`;

const ListSortContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 21px;
`;

// const FormSelectSC = styled(FormSelect)`
//   && {
//     background-color: ${props => props.theme.colors.white};
//     width: 500px;
//   }
// `;

const RowLabelsLayout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding-right: 12px;

  p {
    flex-grow: 0;
    min-width: 100px;
    margin-right: 0.5rem;

    &:first-child {
      flex-grow: 2;
    }
  }
`;

const NoStudentsMessage = styled(Body1)`
  width: 100%;
  margin: 8px 8px 24px;

  span {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const Actions = styled.div``;

// const Actions = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   border-top: 1px solid ${props => props.theme.colors.grey[300]};
//   border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
//   padding: 0.875rem 0;
// `;

const IconSpacing = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;

const getMissingStudentsText = (
  scoresFilterSelection,
  isDelayedPanel,
  isWithdrewPanel
) => {
  if (scoresFilterSelection === 'unreported') {
    return 'No students were found for the current sort parameters';
  }

  if (isDelayedPanel) {
    return 'There are no delayed students in this section';
  }
  if (isWithdrewPanel) {
    return 'There are no students that have withdrawn from this section';
  }

  return 'There are no students in assigned to this section. If this is an error try refreshing your page.';
};

export default function StepList({
  assessmentBlocks,
  assessmentType,
  assessmentUuid,
  handleScoreAddAttemptModalOpen,
  handleScoreAddModalOpen,
  handleScoreEditModalOpen,
  // handleSortSelection,
  partUuid,
  scoresFilterSelection,
  scoreType,
  userScores,
  isDelayedPanel,
  isWithdrewPanel,
  handleUploadDelayedScoresOpen
}) {
  // TODO: find out if it is ok to remove sorting features for delayed & withdrew panels
  const missingStudentsText = getMissingStudentsText(
    scoresFilterSelection,
    isDelayedPanel,
    isWithdrewPanel
  );

  let totalPot = 0;
  if (scoreType !== 'Opportunity') {
    // eslint-disable-next-line array-callback-return
    assessmentBlocks.map(ab => {totalPot += ab.totalPotential});

    if (!totalPot) {
      const computedTotalPot = _.maxBy(userScores, 'totalPot')?.totalPot;
      totalPot = computedTotalPot || 0;
    }
  }

  return (
    <>
      <HeaderSpacer />
      <Panel maxWidth={1600}>
        <ListSortContainer>
          {isDelayedPanel && <Heading3>Delayed Status</Heading3>}
          {isWithdrewPanel && <Heading3>Withdraw Status</Heading3>}
          {isDelayedPanel && handleUploadDelayedScoresOpen && (
            <Actions>
              <IconSpacing>
                <Tooltip placement="top-start" title="Upload Delayed Scores">
                  <ButtonOutline onClick={handleUploadDelayedScoresOpen}>
                    Upload Scores &nbsp;
                    <CompetencyIcon />
                  </ButtonOutline>
                </Tooltip>
              </IconSpacing>
            </Actions>
          )}
          {/* <FormSelectSC
            handleChange={handleSortSelection}
            label="Sort By"
            name="sort"
            options={optionsSortStep}
            value={scoresFilterSelection}
          /> */}
        </ListSortContainer>

        <PanelHeader paddingLeft="12px">
          <RowLabelsLayout>
            <Body2>Student (Last Name, First Name)</Body2>
            <Body2>Total Relative</Body2>
            <Body2>Total Potential</Body2>
            <Body2>Graded Blocks</Body2>
          </RowLabelsLayout>
        </PanelHeader>
        {userScores?.length > 0 ? (
          userScores.map(userScore => (
            <LazyLoad key={userScore.studentUuid}>
              <StepPanel
                assessmentBlocks={assessmentBlocks}
                assessmentType={assessmentType}
                assessmentUuid={assessmentUuid}
                fullName={userScore.fullName}
                handleScoreAddAttemptModalOpen={handleScoreAddAttemptModalOpen}
                handleScoreAddModalOpen={handleScoreAddModalOpen}
                handleScoreEditModalOpen={handleScoreEditModalOpen}
                partUuid={partUuid}
                scoreType={scoreType}
                studentUuid={userScore.studentUuid}
                completedBlocks={userScore.completedBlocks}
                isWithdrewPanel={isWithdrewPanel}
                totalPot={totalPot}
              />
            </LazyLoad>
          ))
        ) : (
          <PanelPadding>
            <NoStudentsMessage>{missingStudentsText}</NoStudentsMessage>
          </PanelPadding>
        )}
      </Panel>
    </>
  );
}

StepList.propTypes = {
  assessmentBlocks: PropTypes.array,
  assessmentType: PropTypes.string,
  assessmentUuid: PropTypes.string,
  isDelayedPanel: PropTypes.bool,
  isWithdrewPanel: PropTypes.bool,
  handleScoreAddAttemptModalOpen: PropTypes.func,
  handleScoreAddModalOpen: PropTypes.func,
  handleScoreEditModalOpen: PropTypes.func,
  // handleSortSelection: PropTypes.func,
  handleUploadDelayedScoresOpen: PropTypes.func,
  partUuid: PropTypes.string,
  scoresFilterSelection: PropTypes.string,
  scoreType: PropTypes.string,
  userScores: PropTypes.array,
};

StepList.defaultProps = {
  assessmentBlocks: [],
  assessmentType: '',
  assessmentUuid: '',
  isDelayedPanel: false,
  isWithdrewPanel: false,
  handleScoreAddAttemptModalOpen: undefined,
  handleScoreAddModalOpen: undefined,
  handleScoreEditModalOpen: undefined,
  // handleSortSelection: undefined,
  handleUploadDelayedScoresOpen: undefined,
  partUuid: '',
  scoresFilterSelection: '',
  scoreType: '',
  userScores: [],
};
