import Decimal from 'decimal.js-light';
import { values } from 'lodash';

import {
  GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT_SUCCESS,
  GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT_ERROR,
  GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT,
  CLEAR_PART_OPPORTUNITY_REPORT
} from '../actions-type';

export const INITIAL_STATE = {
  sectionPerformanceReport: {},
  isLoading: false
};

const doMath = (data, key) => {
  return data.reduce(
    (acc, val) => new Decimal(acc).plus(val[key]),
    new Decimal(0)
  );
};

const sectionPerformanceOpportunityReportReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT_SUCCESS: {
      const { sectionPerformanceReport } = action.payload;

      sectionPerformanceReport.partOpportunityReportStudentSummaryData = {
        ...sectionPerformanceReport.partOpportunityReportStudentSummaryData,
        student_average: {
          totalOpportunityCount: doMath(
            values(
              sectionPerformanceReport?.partOpportunityReportAssessmentStatisticData
            ),
            'averageOpportunityCount'
          ).toString(),
          totalRelativeScore: doMath(
            values(
              sectionPerformanceReport?.partOpportunityReportAssessmentStatisticData
            ),
            'averageRelativeScore'
          )
            .toFixed(2)
            .toString()
        },
        student_high: {
          totalOpportunityCount: doMath(
            values(
              sectionPerformanceReport?.partOpportunityReportAssessmentStatisticData
            ),
            'highOpportunityCount'
          ).toString(),
          totalRelativeScore: doMath(
            values(
              sectionPerformanceReport?.partOpportunityReportAssessmentStatisticData
            ),
            'highRelativeScore'
          )
            .toFixed(2)
            .toString()
        }
      };

      return {
        ...state,
        sectionPerformanceReport,
        isLoading: false
      };
    }

    case GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT_ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }

    case GET_SECTION_PERFORMANCE_OPPORTUNITY_REPORT: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CLEAR_PART_OPPORTUNITY_REPORT: {
      return {
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
};

export default sectionPerformanceOpportunityReportReducer;
