import {
  POST_COHORT_COMPETENCY_GRID,
  POST_COHORT_COMPETENCY_GRID_SUCCESS,
  POST_COHORT_COMPETENCY_GRID_ERROR,
  SET_IS_COMPETENCY_REPORT_LOADING,
  CLEAR_COMPETENCY_GRID_REPORT,
  SET_TABLE_ZOOM,
  SET_SHOW_TABLE_MODAL,
  SET_IS_RESULT_LOADED
} from '../actions-type';

const doPostCohortCompetencyGrid = ({
  cohortUuid,
  competencyCollectionUuid,
  assessmentAssociationUuids,
  assessmentTechniqueUuids,
  assessmentTypeUuids,
  startDate,
  endDate
}) => ({
  type: POST_COHORT_COMPETENCY_GRID,
  payload: {
    cohortUuid,
    competencyCollectionUuid,
    assessmentAssociationUuids,
    assessmentTechniqueUuids,
    assessmentTypeUuids,
    startDate,
    endDate
  }
});

const doPostCohortCompetencyGridSuccess = result => ({
  type: POST_COHORT_COMPETENCY_GRID_SUCCESS,
  payload: result
});

const doPostCohortCompetencyGridError = error => ({
  type: POST_COHORT_COMPETENCY_GRID_ERROR,
  payload: error
});

const doSetIsCompetencyReportLoading = isLoading => ({
  type: SET_IS_COMPETENCY_REPORT_LOADING,
  payload: { isLoading }
});

const doClearCompetencyGridReport = () => ({
  type: CLEAR_COMPETENCY_GRID_REPORT
});

const doSetTableZoom = zoom => ({
  type: SET_TABLE_ZOOM,
  payload: { zoom }
});

const doSetShowTableModal = showTableModal => ({
  type: SET_SHOW_TABLE_MODAL,
  payload: { showTableModal }
});

const doSetIsResultLoaded = isResultLoaded => ({
  type: SET_IS_RESULT_LOADED,
  payload: { isResultLoaded }
});

export {
  doPostCohortCompetencyGrid,
  doPostCohortCompetencyGridSuccess,
  doPostCohortCompetencyGridError,
  doSetIsCompetencyReportLoading,
  doClearCompetencyGridReport,
  doSetTableZoom,
  doSetShowTableModal,
  doSetIsResultLoaded
};
