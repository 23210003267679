import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { studentCalculatedPartGradeResultSelector } from '../../../../../redux/selectors/calculatedGradeResultSelectors';
import { letterGradesBySchoolSelector } from '../../../../../redux/selectors/letterGradeSelectors';
import SectionPerformanceTranscriptStudentPartData from './SectionPerformanceTranscriptStudentPartData';

const SectionPerformanceTranscriptStudentPartDataContainer = ({
  part,
  studentUuid
}) => {
  const calculatedGradeResult = useSelector(state =>
    studentCalculatedPartGradeResultSelector(state, studentUuid, part.uuid)
  );
  const letterGrades = useSelector(letterGradesBySchoolSelector);

  const calculatedGrade = _.get(calculatedGradeResult, 'calculatedGrade');
  const paddedPartNumber = _.get(part, 'partNumber').toString().padStart(2, 0);
  const partTitle = _.get(part, 'title');
  const partWeight = _.get(part, 'weight');
  const letterGrade = letterGrades.find(
    letterGrade =>
      Number(calculatedGrade) >= letterGrade.rangeLow &&
      Number(calculatedGrade) <= letterGrade.rangeHigh
  );

  return (
    <SectionPerformanceTranscriptStudentPartData
      partNumber={paddedPartNumber}
      partTitle={partTitle}
      partWeight={partWeight}
      calculatedGrade={calculatedGrade}
      letterGrade={letterGrade?.grade}
    />
  );
};

SectionPerformanceTranscriptStudentPartDataContainer.propTypes = {
  part: PropTypes.object,
  studentUuid: PropTypes.string
};

SectionPerformanceTranscriptStudentPartDataContainer.defaultProps = {
  part: {},
  studentUuid: ''
};

export default SectionPerformanceTranscriptStudentPartDataContainer;
