import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import TopicDefaultScoreViewList from '../TopicDefaultScoreView/TopicDefaultScoreViewList';

const TopicAlternativeScoreViewFacultyPanelAccordion = styled(
  ({ withBottomBorder, withTopBorder, criticalFailure, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label,
  .column_two_label {
    flex-grow: 0;
    width: 100px;
    text-align: right;
  }

  .accordion_row_label div {
    font-size: 16px;
    letter-spacing: 0.15;
    color: ${props => props.theme.fontColors.darker};
  }

  .column_one_label {
    margin-right: 16px;
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props =>
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.theme.fontColors.darker};
    }
  }

  .column_two_label {
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props => props.theme.fontColors.darker};
    }
  }

  .hover-effect {
    padding-right: 44px;
  }
  width: 100%;
`;

const TopicAlternativeScoreViewFacultyPanel = ({
  facultyFullName,
  scoreType,
  students,
  facultyTopicScores,
  facultyTotalPotential,
  facultyTotalRelative
}) => {
  return (
    <TopicAlternativeScoreViewFacultyPanelAccordion
      expandWidth="66px"
      height="50px"
      label={facultyFullName}
      columnOne={facultyTotalRelative}
      columnTwo={facultyTotalPotential}
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
      rowOffset="28px"
    >
      <TopicDefaultScoreViewList
        currentOffset={28}
        scoreType={scoreType}
        students={students}
        topicScores={facultyTopicScores}
      />
    </TopicAlternativeScoreViewFacultyPanelAccordion>
  );
};

TopicAlternativeScoreViewFacultyPanel.propTypes = {
  facultyFullName: PropTypes.string,
  scoreType: PropTypes.string,
  students: PropTypes.array,
  facultyTopicScores: PropTypes.array,
  facultyTotalPotential: PropTypes.string,
  facultyTotalRelative: PropTypes.string
};

TopicAlternativeScoreViewFacultyPanel.defaultProps = {
  facultyFullName: '',
  scoreType: '',
  students: [],
  facultyTopicScores: [],
  facultyTotalPotential: '',
  facultyTotalRelative: ''
};

export default TopicAlternativeScoreViewFacultyPanel;
