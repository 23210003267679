import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { sectionsByCourseSelector } from '../../../redux/selectors/sectionsSelectors';

import Sections from './Sections';

export default function SectionsContainer({ courseUuid }) {
  const sections = useSelector(state =>
    sectionsByCourseSelector(state, courseUuid)
  );

  return <Sections sections={sections} />;
}

SectionsContainer.propTypes = {
  courseUuid: PropTypes.string
};

SectionsContainer.defaultProps = {
  courseUuid: undefined
};
