import React, { useReducer, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { doGetProgramCohorts } from '../../../../redux/actions/cohortActions';
import {
  doPutCourseCollection,
  doGetCourseCollections
} from '../../../../redux/actions/courseCollectionActions';
import { cohortSelectorByMatchParams } from '../../../../redux/selectors/cohortsSelectors';
import {
  allFieldsAreValid,
  validateStringLength
} from '../../../../helpers/validation/validateGeneric';
import { validateCreditsRequired } from '../../../../helpers/validation/validateCourseCollection';
import EditCourseCollectionDashboard from './EditCourseCollectionDashboard';
import {
  initialCourseCollectionState,
  courseCollectionReducer,
  setHaveFetchedCohorts,
  setHaveFetchedCourseCollections,
  setShowValidationErrors,
  setName,
  setCreditsRequired,
  setAllCreditsRequired,
  setSpecialCollection
} from '../courseCollectionState';
import { courseCollectionSelector } from '../../../../redux/selectors/courseCollectionsSelectors';

const EditCourseCollectionDashboardContainer = () => {
  // eslint-disable-next-line prefer-const
  let history = useHistory();
  const dispatch = useDispatch();
  const { cohortUuid, courseCollectionUuid } = useParams();

  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  const parentCohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    shallowEqual
  );

  const existingCourseCollection = useSelector(
    state => courseCollectionSelector(state, courseCollectionUuid),
    shallowEqual
  );

  const [state, editDispatch] = useReducer(
    courseCollectionReducer,
    initialCourseCollectionState
  );

  const setExistingState = existingCollection => {
    editDispatch({
      type: 'setExistingCourseCollection',
      payload: { ...existingCollection }
    });
  };

  const {
    uuid,
    name,
    credits_required,
    all_credits_required,
    special_collection,
    haveFetchedCohorts,
    haveFetchedCourseCollections,
    showValidationErrors,
    courses
  } = state;

  const clearForm = () => {
    editDispatch({
      type: 'clearForm'
    });
    history.push(`/program-management/cohort/${cohortUuid}/requirements`);
  };

  const onPutUpdate = courseCollection =>
    dispatch(doPutCourseCollection(courseCollection));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!haveFetchedCohorts && !parentCohort) {
      dispatch(doGetProgramCohorts(selectedProgramUuid));
      editDispatch(setHaveFetchedCohorts());
    }
  }, [dispatch, selectedProgramUuid, haveFetchedCohorts, parentCohort]);

  useEffect(() => {
    if (!haveFetchedCourseCollections && !existingCourseCollection) {
      dispatch(doGetCourseCollections(cohortUuid));
      editDispatch(setHaveFetchedCourseCollections());
    }
  }, [
    dispatch,
    existingCourseCollection,
    haveFetchedCourseCollections,
    cohortUuid
  ]);

  useEffect(() => {
    if (
      courseCollectionUuid &&
      existingCourseCollection &&
      uuid !== existingCourseCollection.uuid
    ) {
      setExistingState(existingCourseCollection);
    }
  }, [courseCollectionUuid, existingCourseCollection, uuid]);

  const setValidationErrors = courseCollection => {
    const nameError = validateStringLength(courseCollection.name, 100, true);
    const creditsRequiredError = validateCreditsRequired(credits_required);
    let coursesError = { invalid: false };
    if (Object.entries(courseCollection.courses).length < 1) {
      coursesError = {
        invalid: true,
        isFormatError: true,
        message: 'A collection must have at least one class'
      };
    }
    const newValidationErrors = {
      nameError,
      creditsRequiredError: courseCollection.all_credits_required
        ? { invalid: false }
        : creditsRequiredError,
      coursesError
    };

    return newValidationErrors;
  };

  const buildProcedureCodePayload = (
    uuid,
    name,
    credits_required,
    all_credits_required,
    special_collection,
    cohortUuid,
    courses
  ) => {
    const coursesArray = Object.entries(courses).map(([uuid]) => uuid);

    return {
      uuid,
      name,
      credits_required: all_credits_required ? null : credits_required,
      all_credits_required,
      special_collection,
      cohortUuid,
      courses: coursesArray
    };
  };

  const handleUpdateSubmission = () => {
    const newValidationErrors = setValidationErrors({
      name,
      credits_required,
      all_credits_required,
      special_collection,
      courses
    });

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = buildProcedureCodePayload(
        uuid,
        name,
        credits_required,
        all_credits_required,
        special_collection,
        cohortUuid,
        courses
      );

      onPutUpdate(payload);
      editDispatch(setShowValidationErrors(false));
    } else {
      editDispatch(setShowValidationErrors(true));
    }
  };

  const courseCollection = {
    name,
    credits_required,
    all_credits_required,
    special_collection,
    cohortUuid,
    courses
  };

  const handleNameChange = e => editDispatch(setName(e.target.value));
  const handleCreditsRequiredChange = e =>
    editDispatch(setCreditsRequired(e.target.value));

  const handleAllCreditsRequiredChange = value =>
    editDispatch(setAllCreditsRequired(value));
  const handleSpecialCollectionChange = value =>
    editDispatch(setSpecialCollection(value));

  return (
    <EditCourseCollectionDashboard
      courseCollection={courseCollection}
      formDispatch={editDispatch}
      parentCohort={parentCohort}
      handleNameChange={handleNameChange}
      handleCreditsRequiredChange={handleCreditsRequiredChange}
      handleAllCreditsRequiredChange={handleAllCreditsRequiredChange}
      handleSpecialCollectionChange={handleSpecialCollectionChange}
      onSubmit={handleUpdateSubmission}
      clearForm={clearForm}
      showValidationErrors={showValidationErrors}
    />
  );
};

export default EditCourseCollectionDashboardContainer;
