export const GET_COURSE = 'GET_COURSE';
export const GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS';
export const GET_COURSE_ERROR = 'GET_COURSE_ERROR';
export const GET_COURSES = 'GET_COURSES';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_ERROR = 'GET_COURSES_ERROR';
export const POST_COURSE = 'POST_COURSE';
export const POST_COURSE_SUCCESS = 'POST_COURSE_SUCCESS';
export const POST_COURSE_ERROR = 'POST_COURSE_ERROR';
export const PUT_COURSE = 'PUT_COURSE';
export const PUT_COURSE_SUCCESS = 'PUT_COURSE_SUCCESS';
export const PUT_COURSE_ERROR = 'PUT_COURSE_ERROR';
export const DELETE_COURSE = 'DELETE_COURSE';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const DELETE_COURSE_ERROR = 'DELETE_COURSE__ERROR';
export const PUT_COURSE_LOCK = 'PUT_COURSE_LOCK';
export const PUT_COURSE_LOCK_SUCCESS = 'PUT_COURSE_LOCK_SUCCESS';
export const PUT_COURSE_LOCK_ERROR = 'PUT_COURSE_LOCK_ERROR';
export const GET_COURSES_BY_DEPARTMENT_AY = 'GET_COURSES_BY_DEPARTMENT_AY';
export const GET_COURSES_BY_DEPARTMENT_AY_SUCCESS =
  'GET_COURSES_BY_DEPARTMENT_AY_SUCCESS';
export const GET_COURSES_BY_DEPARTMENT_AY_ERROR =
  'GET_COURSES_BY_DEPARTMENT_AY_ERROR';

export const GET_COURSE_BY_STUDENT = 'GET_COURSE_BY_STUDENT';
export const GET_COURSE_BY_STUDENT_SUCCESS = '  GET_COURSE_BY_STUDENT_SUCCESS';
export const GET_COURSE_BY_STUDENT_ERROR = '  GET_COURSE_BY_STUDENT_ERROR';
