import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Tooltip } from '@material-ui/core';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import LockOutlined from '@material-ui/icons/LockOutlined';

import StudentCollectionGradeData from '../GradeData/StudentCollectionGradeData';
import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../../../helpers/format/user.format';

import { TableRow, FillerTd, StudentInfoText } from './SharedSC';
import DelayedStudentPartGradeData from '../GradeData/DelayedStudentPartGradeData';
import {
  doDeletePartDelayedStudentGrade,
  doPutPartDelayedStudentGrade,
  doPutPartDelayedStudentStatusCommit
} from '../../../../../redux/actions/partActions';
import { doClearUpdatedAssessment } from '../../../../../redux/actions/assessmentActions';
import StatusCommitModalContainer from '../../../../Library/Modal/StatusCommitModal/StatusCommitModalContainer';

export const StickyInfoTdWithLockPadding = styled(
  ({ left, greyOne, borderRightStrong, ...rest }) => <td {...rest} />
)`
  min-width: 250px;
  max-width: 250px;
  z-index: 10;
  position: sticky;
  padding-left: 8px;
  padding-right: 32px;
  left: ${props => props.left};
  background-color: ${props => (props.greyOne && props.theme.colors.grey[100]) || 'white'};
  border-top: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
  border-right: ${props =>
    props.borderRightStrong
      ? `solid 2px ${props.theme.fontColors.darker}`
      : `solid 1px ${props.theme.colors.grey[300]}`};
`;

const StyledPublishedIcon = styled(LockOutlined)`
  && {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    height: 100%;
    odisplay: flex;
    align-item: center;
    font-size: 1.25rem;
    color: ${props => props.theme.fontColors.dark};
  }
`;

const getDelayedStudentActions = (
  dispatch,
  partUuid,
  studentUuid,
  calculatedGrade,
  delayedStatusCommit,
  justificationMessage,
  handleCommitModalOpen
) => {
  const isGraded = Boolean(calculatedGrade);
  const isCommitted = delayedStatusCommit === 'COMMITTED';

  const gradeDelayedStudent = (partUuid, studentUuid) =>
    dispatch(
      doPutPartDelayedStudentGrade({
        partUuid,
        studentUuid
      })
    );

  const ungradeDelayedStudent = (partUuid, studentUuid) =>
    dispatch(
      doDeletePartDelayedStudentGrade({
        partUuid,
        studentUuid
      })
    );

  const commitDelayedStudentGrade = (partUuid, studentUuid) =>
    dispatch(
      doPutPartDelayedStudentStatusCommit({
        partUuid,
        studentUuid,
        delayedStatusCommit: 'COMMITTED',
        justificationMessage: null
      })
    );

  const uncommitDelayedStudentGrade = (
    partUuid,
    studentUuid,
    justificationMessage
  ) =>
    dispatch(
      doPutPartDelayedStudentStatusCommit({
        partUuid,
        studentUuid,
        delayedStatusCommit: 'UNCOMMITTED',
        justificationMessage
      })
    );

  let delayedStudentActions = [];

  if (!isGraded) {
    delayedStudentActions = [
      {
        label: 'Grade This Student',
        onClick: () => gradeDelayedStudent(partUuid, studentUuid),
        shouldOpenOnClick: false,
        icon: 'assessment'
      }
    ];
  } else if (isGraded && !isCommitted) {
    delayedStudentActions = [
      {
        label: 'Ungrade This Student',
        onClick: () => ungradeDelayedStudent(partUuid, studentUuid),
        shouldOpenOnClick: false,
        icon: 'delete'
      },
      {
        label: 'Commit This Student',
        onClick: () => handleCommitModalOpen(),
        shouldOpenOnClick: false,
        icon: 'locked'
      }
    ];
  } else {
    delayedStudentActions = [
      {
        label: 'Uncommit This Student',
        onClick: () => handleCommitModalOpen(),
        shouldOpenOnClick: false,
        icon: 'unlock'
      }
    ];
  }

  return {
    delayedStudentActions,
    gradeDelayedStudent,
    ungradeDelayedStudent,
    commitDelayedStudentGrade,
    uncommitDelayedStudentGrade
  };
};

const DelayedPartGradeResult = ({
  gradeResult,
  students,
  idx,
  partUuid,
  assessmentCollections,
  failThreshold,
  isExpanded,
  useFillerColumn,
  fillerColumnWidth
}) => {
  const dispatch = useDispatch();

  const [justificationMessage, setJustificationMessage] = useState('');
  const [isCommitModalOpen, setCommitModalOpen] = useState(false);

  const studentUuid = gradeResult?.studentUuid;
  const student = students[gradeResult?.studentUuid] || {};
  const evenStudent = idx % 2 === 0;
  const studentFormattedLastName = formatLastName(
    student.user?.lastName,
    student.user?.marriedLastName,
    student.user?.suffix
  );
  const studentFormattedFirstMiddleName = formatFirstNameMiddleName(
    student.user?.firstName,
    student.user?.middleName
  );
  const studentFullName =
    student.user?.firstName && student.user?.lastName
      ? `${studentFormattedLastName}, ${studentFormattedFirstMiddleName}`
      : 'N/A';

  const studentRank = gradeResult.classRank;

  const { calculatedGrade, delayedStatusCommit } = gradeResult;

  const { successfullyCommitted, successfullyUncommitted } = useSelector(
    state => state.assessmentStatusState,
    shallowEqual
  );

  const handleCommitModalOpen = () => {
    setCommitModalOpen(true);
  };

  const handleCommitModalClose = () => {
    setCommitModalOpen(false);
  };

  const handleJustificationChange = event => {
    setJustificationMessage(event.target.value);
  };

  useEffect(() => {
    if (successfullyCommitted || successfullyUncommitted) {
      dispatch(doClearUpdatedAssessment());
      setJustificationMessage('');
    }
  }, [dispatch, successfullyCommitted, successfullyUncommitted]);

  const {
    delayedStudentActions,
    commitDelayedStudentGrade,
    uncommitDelayedStudentGrade
  } = getDelayedStudentActions(
    dispatch,
    partUuid,
    studentUuid,
    calculatedGrade,
    delayedStatusCommit,
    justificationMessage,
    handleCommitModalOpen
  );

  const handleStatusCommitSubmit = () => {
    handleCommitModalClose();
    if (delayedStatusCommit === 'UNCOMMITTED') {
      commitDelayedStudentGrade(partUuid, studentUuid);
    } else {
      uncommitDelayedStudentGrade(partUuid, studentUuid, justificationMessage);
    }
  };

  const currentStatusCommitType =
    delayedStatusCommit === 'COMMITTED' ? 'Uncommit' : 'Commit';

  const modalTitle = `${currentStatusCommitType} Delayed Student Grade`;

  const messageOverrides = {
    title: modalTitle,
    successCommitted: 'Successfully committed delayed student grade',
    successUncommitted: 'Successfully uncommitted delayed student grade',
    confirmCommitment:
      'Committing this delayed student grade is permanent and cannot be undone without school admin access priveleges and a justification message. Are you sure you want to proceed?',
    confirmUncommitment:
      'Uncommitting this delayed student grade will remove any previously calculated grade. This must be justified by a school admin. Are you sure you want to proceed?'
  };

  const isCommitted = delayedStatusCommit === 'COMMITTED';

  return (
    <TableRow key={student.uuid}>
      <StatusCommitModalContainer
        statusCommit={delayedStatusCommit}
        messages={messageOverrides}
        isModalOpen={isCommitModalOpen}
        handleModalClose={handleCommitModalClose}
        handleModalSuccess={undefined}
        handleSubmit={handleStatusCommitSubmit}
        justificationMessage={justificationMessage}
        handleJustificationChange={handleJustificationChange}
      />
      <DelayedStudentPartGradeData
        evenStudent={evenStudent}
        partUuid={partUuid}
        studentUuid={student.userUuid}
        studentRank={studentRank}
        actions={delayedStudentActions}
      />
      <StickyInfoTdWithLockPadding
        greyOne={!evenStudent}
        left="225px"
        borderRightStrong
      >
        <Tooltip title={studentFullName}>
          <StudentInfoText>{studentFullName}</StudentInfoText>
        </Tooltip>
        {isCommitted && <StyledPublishedIcon />}
      </StickyInfoTdWithLockPadding>
      {assessmentCollections.map(assessmentCollection => {
        return (
          <StudentCollectionGradeData
            assessmentCollection={assessmentCollection}
            evenStudent={evenStudent}
            failThreshold={failThreshold}
            isExpanded={isExpanded}
            studentUuid={student.userUuid}
          />
        );
      })}
      {useFillerColumn && (
        <FillerTd width={fillerColumnWidth} borderTop greyOne={!evenStudent} />
      )}
    </TableRow>
  );
};

DelayedPartGradeResult.propTypes = {
  assessmentCollections: PropTypes.array,
  failThreshold: PropTypes.string,
  partUuid: PropTypes.string,
  students: PropTypes.object,
  gradeResult: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool,
  useFillerColumn: PropTypes.bool,
  fillerColumnWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

DelayedPartGradeResult.defaultProps = {
  assessmentCollections: [],
  failThreshold: '',
  isExpanded: false,
  partUuid: '',
  students: {},
  useFillerColumn: false
};

export default DelayedPartGradeResult;
