import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Subtitle2 } from '@xcomp/xcomp-design-library';

const OverlineSC = styled(Subtitle2)`
  color: ${props => props.theme.fontColors.dark};
`;

const Overline = ({ children, className }) => (
  <OverlineSC className={className}>{children}</OverlineSC>
);

Overline.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

Overline.defaultProps = {
  className: '',
  children: <div />
};

export default Overline;
