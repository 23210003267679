import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import {
  doOpenNotification,
  doCloseNotification
} from '../../redux/actions/notificationActions';
import { types } from '../../redux/reducers/notificationReducer';
import Notification from './Notification';

function NotificationContainer({ ...props }) {
  const dispatch = useDispatch();
  const notificationState = useSelector(
    state => state.notificationState,
    _.isEqual
  );

  const notificationColor = type => {
    let color;

    switch (type) {
      case types.success:
        color = props.theme.colors.status.success;
        break;
      case types.error:
        color = props.theme.colors.status.error;
        break;
      case types.warning:
        color = props.theme.colors.status.warning;
        break;
      default:
        color = props.theme.colors.primary;
        break;
    }

    return color;
  };

  const handleClick = () => {
    dispatch(doOpenNotification());
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(doCloseNotification());
  };

  return (
    <Notification
      notifications={notificationState}
      open={notificationState.open}
      handleClick={handleClick}
      handleClose={handleClose}
      notificationBg={notificationColor(notificationState.type)}
    />
  );
}

NotificationContainer.propTypes = {
  theme: PropTypes.object.isRequired
};

export default withTheme(NotificationContainer);
