export const GET_SCORES = 'GET_SCORES';
export const GET_SCORES_SUCCESS = 'GET_SCORES_SUCCESS';
export const GET_SCORES_ERROR = 'GET_SCORES_ERROR';

export const GET_COHORT_ASSESSMENT_SCORES = 'GET_COHORT_ASSESSMENT_SCORES';
export const GET_COHORT_ASSESSMENT_SCORES_SUCCESS =
  'GET_COHORT_ASSESSMENT_SCORES_SUCCESS';
export const GET_COHORT_ASSESSMENT_SCORES_ERROR =
  'GET_COHORT_ASSESSMENT_SCORES_ERROR';

export const PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT =
  'PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT';
export const PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_SUCCESS =
  'PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_SUCCESS';
export const PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_ERROR =
  'PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_ERROR';

export const GET_COHORT_ASSESSMENT_COMMITMENT_LOG =
  'GET_COHORT_ASSESSMENT_COMMITMENT_LOG';
export const GET_COHORT_ASSESSMENT_COMMITMENT_LOG_SUCCESS =
  'GET_COHORT_ASSESSMENT_COMMITMENT_LOG_SUCCESS';
export const GET_COHORT_ASSESSMENT_COMMITMENT_LOG_ERROR =
  'GET_COHORT_ASSESSMENT_COMMITMENT_LOG_ERROR';

export const PUT_ASSESSMENT_BLOCK_RESULT_STATUS_SUCCESS =
  'PUT_ASSESSMENT_BLOCK_RESULT_STATUS_SUCCESS';
export const PUT_ASSESSMENT_BLOCK_RESULT_STATUS_ERROR =
  'PUT_ASSESSMENT_BLOCK_RESULT_STATUS_ERROR';

export const POST_SCORE = 'POST_SCORE';
export const POST_SCORE_SUCCESS = 'POST_SCORE_SUCCESS';
export const POST_SCORE_ERROR = 'POST_SCORE_ERROR';
export const POST_SCORE_RESET = 'POST_SCORE_RESET';
export const PUT_SCORE = 'PUT_SCORE';
export const PUT_SCORE_SUCCESS = 'PUT_SCORE_SUCCESS';
export const PUT_SCORE_ERROR = 'PUT_SCORE_ERROR';
export const DELETE_SCORE = 'DELETE_SCORE';
export const DELETE_SCORE_SUCCESS = 'DELETE_SCORE_SUCCESS';
export const DELETE_SCORE_ERROR = 'DELETE_SCORE_ERROR';
export const DELETE_STUDENT_SCORES_SUCCESS = 'DELETE_STUDENT_SCORES_SUCCESS';
export const DELETE_STUDENT_SCORES_ERROR = 'DELETE_STUDENT_SCORES_ERROR';
export const DELETE_SCORES = 'DELETE_SCORES';
export const DELETE_SCORES_SUCCESS = 'DELETE_SCORES_SUCCESS';
export const DELETE_SCORES_ERROR = 'DELETE_SCORES_ERROR';
export const GET_UPLOAD_SCORES_STATUS = 'GET_UPLOAD_SCORES_STATUS';
export const GET_UPLOAD_SCORES_STATUS_SUCCESS =
  'GET_UPLOAD_SCORES_STATUS_SUCCESS';
export const GET_UPLOAD_SCORES_STATUS_ERROR = 'GET_UPLOAD_SCORES_STATUS_ERROR';
