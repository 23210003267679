import { call, put, takeEvery } from 'redux-saga/effects';
import { getCourseAgeMod, postCourseAgeMod } from '../../api/courseAgeModApi';

import {
  doPostCourseAgeModSuccess,
  doPostCourseAgeModError,
  doGetCourseAgeModSuccess,
  doGetCourseAgeModError
} from '../actions/courseAgeModActions';

import { GET_COURSE_AGE_MOD, POST_COURSE_AGE_MOD } from '../actions-type';

function* handleGetCourseAgeMod(action) {
  try {
    const { programUuid } = action.payload;

    const result = yield call(getCourseAgeMod, programUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCourseAgeModSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCourseAgeModError(error));
  }
}

function* handlePostCourseAgeMod(action) {
  try {
    const { courseAgeMods } = action.payload;

    const result = yield call(postCourseAgeMod, courseAgeMods);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostCourseAgeModSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostCourseAgeModError(error));
  }
}

const courseAgeMod = [
  takeEvery(GET_COURSE_AGE_MOD, handleGetCourseAgeMod),
  takeEvery(POST_COURSE_AGE_MOD, handlePostCourseAgeMod)
];

export { courseAgeMod };
