import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { EditIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ContainerPage, ElevateLow } from '../../../../Library/Layout';

import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import Source from './Source';
import AptitudeWeight from './Aptitude/AptitudeWeight';

import { doGetClassifications } from '../../../../../redux/actions/classificationActions';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .accordion_row_label {
    flex-grow: 2;
  }
  .accordion_row_label div {
    letter-spacing: 0.15;
  }

  margin-bottom: 30px;
  width: 100%;
`;

const categories = [
  'Cognitive',
  'Non-Cognitive',
  'Physical-Skills',
  'Communication'
];

const Application = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ApplicationType = styled.div`
  color: ${props => props.theme.colors.grey[500]};
`;

const ApplicationTitle = styled.div`
  margin-left: 60px;
  color: ${props => props.theme.fontColors.darker};
`;

export default function Category() {
  const dispatch = useDispatch();
  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  useEffect(() => {
    dispatch(doGetClassifications(selectedProgramUuid));
  }, [dispatch, selectedProgramUuid]);

  return (
    <ContainerPage paddingTop="50px">
      {categories.map(category => (
        <ElevateLow key={category}>
          <Accordion
            withBottomBorder
            expandWidth="66px"
            height="50px"
            label={
              <Application>
                <ApplicationType>Transcript Category</ApplicationType>
                <ApplicationTitle>{category}</ApplicationTitle>
              </Application>
            }
            columnOne={
              category !== 'Cognitive' ? (
                <AptitudeWeight
                  category={category}
                  programUuid={selectedProgramUuid}
                />
              ) : (
                ''
              )
            }
            columnTwo={
              category !== 'Cognitive' ? (
                <Link
                  to={`/program-management/editor-aptitude-exam/${selectedProgramUuid}/${category}`}
                >
                  <EditIcon />
                </Link>
              ) : (
                ''
              )
            }
          >
            <Source programUuid={selectedProgramUuid} category={category} />
          </Accordion>
        </ElevateLow>
      ))}
    </ContainerPage>
  );
}
