import React from 'react';
import PropTypes from 'prop-types';

import PartAssessmentCollectionReportTable from './PartAssessmentCollectionReportTable';

const PartAssessmentCollectionReport = ({
  assessmentCollections,
  failThreshold,
  numCollectionAssessments,
  numPointsOnlyCollections,
  students,
  partGradeResults,
  partUuid
}) => {
  return (
    <PartAssessmentCollectionReportTable
      assessmentCollections={assessmentCollections}
      failThreshold={failThreshold}
      numCollectionAssessments={numCollectionAssessments}
      numPointsOnlyCollections={numPointsOnlyCollections}
      partGradeResults={partGradeResults}
      partUuid={partUuid}
      students={students}
    />
  );
};

PartAssessmentCollectionReport.propTypes = {
  assessmentCollections: PropTypes.array,
  failThreshold: PropTypes.string,
  numCollectionAssessments: PropTypes.number,
  numPointsOnlyCollections: PropTypes.number,
  partGradeResults: PropTypes.array,
  partUuid: PropTypes.string,
  students: PropTypes.object
};

PartAssessmentCollectionReport.defaultProps = {
  assessmentCollections: [],
  failThreshold: '',
  numCollectionAssessments: 0,
  numPointsOnlyCollections: 0,
  partGradeResults: [],
  partUuid: '',
  students: {}
};

export default PartAssessmentCollectionReport;
