import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../../../helpers/format/user.format';
import SectionPerformanceTranscriptStudentPartDataContainer from './SectionPerformanceTranscriptStudentPartDataContainer';
import { doGetLetterGradesBySchool } from '../../../../../redux/actions/letterGradeActions';

const SummaryHeadingContainer = styled(({ greyBg, ...rest }) => (
  <div {...rest} />
))`
  display: flex;
  width: 100%;
  padding: 0px 16px;
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  background: ${props =>
    (props.greyBg && props.theme.colors.grey[300]) || 'white'};
`;

const SummaryHeading = styled.div`
  display: flex;
  width: calc(100% - 40px);
`;

const StudentSummary = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  color: ${props => props.theme.fontColors.darker};
  font-size: 14px;
`;

const SummaryData = styled(({ center, width, ...rest }) => <div {...rest} />)`
  display: flex;
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  width: ${props => props.width};
  padding: 20px 0px;
  padding-right: 16px;
`;

const AccordionDetailsSC = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  .MuiAccordionDetails-root {
    padding: 0px;
  }
`;

const SummaryDataTitle = styled(({ ...rest }) => <Tooltip {...rest} />)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const tempStatusMap = {
  active: 'P',
  delayed: 'D',
  withdrew: 'W',
  credit: 'C',
  pass: 'P',
  pass_with_remediation: 'PWR',
  fail: 'F',
  fail_with_remediation: 'FWR'
};

const SectionPerformanceReport = ({
  courseCredits,
  isLoadingReport,
  parts,
  termPeriod,
  courseNumber,
  courseTitle,
  sectionNumber,
  sectionPerformanceReport
}) => {
  const dispatch = useDispatch();

  const schoolUuid = useSelector(state => state.userState.selectedSchoolUuid);

  const [haveFetchedLetterGrades, setHaveFetchedLetterGrades] = useState(false);

  useEffect(() => {
    if (schoolUuid && !haveFetchedLetterGrades) {
      dispatch(doGetLetterGradesBySchool(schoolUuid));
      setHaveFetchedLetterGrades(true);
    }
  }, [dispatch, schoolUuid, haveFetchedLetterGrades]);

  return isLoadingReport ? (
    <LoadingContainer>
      <CircularProgress size={100} />
    </LoadingContainer>
  ) : (
    <div>
      <SummaryHeadingContainer>
        <SummaryHeading>
          <SummaryData width="5%">Rank</SummaryData>
          <SummaryData width="15%">Student</SummaryData>
          <SummaryData width="15%">Term</SummaryData>
          <SummaryData width="10%">Course Number</SummaryData>
          <SummaryData width="10%">Section / Part</SummaryData>
          <SummaryData width="20%">Title</SummaryData>
          <SummaryData width="5%">Weight</SummaryData>
          <SummaryData width="5%">Score</SummaryData>
          <SummaryData width="5%">Grade</SummaryData>
          <SummaryData width="5%">Status</SummaryData>
          <SummaryData width="5%">Credits</SummaryData>
        </SummaryHeading>
      </SummaryHeadingContainer>
      {sectionPerformanceReport.length === 0 && (
        <SummaryHeadingContainer greyBg>
          <SummaryData center width="100%">
            No Section Performance Data
          </SummaryData>
        </SummaryHeadingContainer>
      )}
      {sectionPerformanceReport.map(studentReportInfo => {
        const applicantFirstName = formatFirstNameMiddleName(
          studentReportInfo?.user?.firstName,
          studentReportInfo?.user?.middleName
        );
        const applicantLastName = formatLastName(
          studentReportInfo?.user?.lastName,
          studentReportInfo?.user?.marriedLastName
        );
        const applicantFullName = `${applicantLastName}, ${applicantFirstName}`;
        const rank =
          studentReportInfo.calculatedGrade === null
            ? 'N/A'
            : studentReportInfo.classRank;

        const letterGrade = studentReportInfo.letterGrade ?? 'N/A';
        const calculatedGrade = studentReportInfo.calculatedGrade ?? 'N/A';
        return (
          <Accordion key={studentReportInfo?.user?.uuid}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <StudentSummary>
                <SummaryData width="5%">{rank}</SummaryData>
                <SummaryData width="15%">
                  <SummaryDataTitle title={applicantFullName} placement="top">
                    <div>{applicantFullName}</div>
                  </SummaryDataTitle>
                </SummaryData>
                <SummaryData width="15%">
                  <SummaryDataTitle title={termPeriod} placement="top">
                    <div>{termPeriod}</div>
                  </SummaryDataTitle>
                </SummaryData>
                <SummaryData width="10%">
                  <SummaryDataTitle title={courseNumber} placement="top">
                    <div>{courseNumber}</div>
                  </SummaryDataTitle>
                </SummaryData>
                <SummaryData width="10%">
                  <SummaryDataTitle
                    title={`Section ${sectionNumber}`}
                    placement="top"
                  >
                    <div>{`Section ${sectionNumber}`}</div>
                  </SummaryDataTitle>
                </SummaryData>
                <SummaryData width="20%">
                  <SummaryDataTitle title={courseTitle} placement="top">
                    <div>{courseTitle}</div>
                  </SummaryDataTitle>
                </SummaryData>
                <SummaryData width="5%" />
                <SummaryData width="5%">
                  <SummaryDataTitle title={calculatedGrade} placement="top">
                    <div>{calculatedGrade}</div>
                  </SummaryDataTitle>
                </SummaryData>
                <SummaryData width="5%">
                  <SummaryDataTitle title={letterGrade} placement="top">
                    <div>{letterGrade}</div>
                  </SummaryDataTitle>
                </SummaryData>
                <SummaryData width="5%">
                  <SummaryDataTitle
                    title={tempStatusMap[studentReportInfo.status] ?? 'N/A'}
                    placement="top"
                  >
                    <div>
                      {tempStatusMap[studentReportInfo.status] ?? 'N/A'}
                    </div>
                  </SummaryDataTitle>
                </SummaryData>
                <SummaryData width="5%">
                  <SummaryDataTitle title={courseCredits} placement="top">
                    <div>{courseCredits}</div>
                  </SummaryDataTitle>
                </SummaryData>
              </StudentSummary>
            </AccordionSummary>
            <AccordionDetailsSC>
              {parts.map(part => {
                return (
                  <SectionPerformanceTranscriptStudentPartDataContainer
                    key={part.uuid}
                    part={part}
                    studentUuid={studentReportInfo.user?.uuid}
                  />
                );
              })}
            </AccordionDetailsSC>
          </Accordion>
        );
      })}
    </div>
  );
};

SectionPerformanceReport.propTypes = {
  courseCredits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoadingReport: PropTypes.bool,
  parts: PropTypes.array,
  termPeriod: PropTypes.string,
  courseNumber: PropTypes.string,
  courseTitle: PropTypes.string,
  sectionNumber: PropTypes.string,
  sectionPerformanceReport: PropTypes.array
};

SectionPerformanceReport.defaultProps = {
  courseCredits: '',
  isLoadingReport: false,
  parts: [],
  termPeriod: '',
  courseNumber: '',
  courseTitle: '',
  sectionNumber: '',
  sectionPerformanceReport: []
};

export default SectionPerformanceReport;
