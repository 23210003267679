import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ApplicationLevel from './ApplicationLevel';
import { selectRigorApplicants } from '../../../../../redux/selectors/rigorIndexSelectors';

export default function ApplicationLevelContainer({
  displayName,
  programUuid,
  sourceInstitutionUuid,
  classificationUuid
}) {
  const applicants = useSelector(state =>
    selectRigorApplicants(
      state,
      programUuid,
      sourceInstitutionUuid,
      classificationUuid
    )
  );

  return <ApplicationLevel displayName={displayName} applicants={applicants} />;
}

ApplicationLevelContainer.propTypes = {
  displayName: PropTypes.bool,
  sourceInstitutionUuid: PropTypes.string,
  classificationUuid: PropTypes.string,
  programUuid: PropTypes.string
};

ApplicationLevelContainer.defaultProps = {
  displayName: false,
  sourceInstitutionUuid: undefined,
  classificationUuid: undefined,
  programUuid: undefined
};
