import React from 'react';
import {Heading2, ButtonPrimary} from '@xcomp/xcomp-design-library';
import {useAtom} from 'jotai';

import ContainerResize, {
  ContainerPage,
  Panel,
  PanelHeader,
  ContainerActions,
} from '../../Library/Layout';
import Header from '../../Library/Header';
import QuantitativeCollectionsList
  from './components/QuantitativeCollectionsList/QuantitativeCollectionsList';
import NewCollectionModal from './components/NewCollectionModal/NewCollectionModal';
import {newCollectionModalAtom} from './state/collectionState';

const QuantitativeSkillsRequirements = () => {
  const [open, setIsOpen]=useAtom(newCollectionModalAtom)

  return (
    <ContainerResize>
      <Header
        title="Quantitative Skill Requirements"
        subtitle="Manage Quantitative Skill competencies"
        backButtonLocation="/"
      />
      {open && <NewCollectionModal />}
      <ContainerActions paddingTop="100px">
        <ButtonPrimary onClick={() => setIsOpen(!open)}>
          Add Quantitative Skill Collection
        </ButtonPrimary>
      </ContainerActions>
      <ContainerPage paddingTop="48px">
        <Panel>
          <PanelHeader paddingLeft="24px">
            <Heading2>Quantitative Skill Requirements Collections</Heading2>
          </PanelHeader>
          <QuantitativeCollectionsList />
        </Panel>
      </ContainerPage>
    </ContainerResize>
  );
};

export default QuantitativeSkillsRequirements;
