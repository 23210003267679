import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { userActiveSelector } from '../../../../redux/selectors/usersSelectors';
import {
  validateInputString,
  validateSelectDate
} from '../../../../helpers/validation/validateGeneric';

import { doPutUserProfile } from '../../../../redux/actions/userActions';

import { selectInstitutionIdType } from '../../../../redux/selectors/sourceSelectors';

import UserProfileEdit from './UserProfileEdit';

export default function UserProfileEditContainer() {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(undefined);
  const [middleName, setMiddleName] = useState(undefined);
  const [lastName, setLastName] = useState(undefined);
  const [marriedLastName, setMarriedLastName] = useState(undefined);
  const [suffix, setSuffix] = useState(undefined);
  const [institutionIdTypeUuid, setInstitutionIdTypeUuid] = useState(undefined);
  const [institutionId, setInstitutionId] = useState(undefined);
  const [textMessage, setTextMessage] = useState(false);
  const [birthDate, setBirthDate] = useState(null);
  const [email, setEmail] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [ethnicity, setEthnicity] = useState(undefined);
  const [gender, setGender] = useState(undefined);
  const [hasErrors, setHasErrors] = useState(false);

  const user = useSelector(state => userActiveSelector(state), _.isEqual);

  const institutionIdTypes = useSelector(state =>
    selectInstitutionIdType(state)
  );

  const optionsInstitutionIdTypes = _.map(
    institutionIdTypes,
    institutionIdType => ({
      label: institutionIdType.name,
      value: institutionIdType.uuid
    })
  );

  useEffect(() => {
    if (user) {
      setFirstName(user?.firstName);
      setMiddleName(user?.middleName);
      setLastName(user?.lastName);
      setMarriedLastName(user?.marriedLastName);
      setSuffix(user?.suffix);
      setInstitutionIdTypeUuid(user?.institutionIdTypeUuid);
      setInstitutionId(user?.institutionId);
      setTextMessage(user?.textMessage);
      setBirthDate(user?.birthDate);
      setPhone(user?.phone);
      setEmail(user?.email);
      setEthnicity(user?.ethnicity);
      setGender(user?.gender);
    }
  }, [user]);

  const handleChange = event => {
    const { name, value } = event.target;

    switch (name) {
      case 'firstName': {
        setFirstName(value);
        break;
      }
      case 'middleName': {
        setMiddleName(value);
        break;
      }
      case 'lastName': {
        setLastName(value);
        break;
      }
      case 'marriedLastName': {
        setMarriedLastName(value);
        break;
      }
      case 'suffix': {
        setSuffix(value);
        break;
      }
      case 'email': {
        setEmail(value);
        break;
      }
      case 'phone': {
        setPhone(value);
        break;
      }
      case 'ethncity': {
        setEthnicity(value);
        break;
      }
      case 'gender': {
        setGender(value);
        break;
      }
      case 'textMessage': {
        setTextMessage(value);
        break;
      }
      default:
        break;
    }
  };

  const handleBirthDate = (indentifier, field, date) => {
    setBirthDate(date);
  };

  const handleToggleText = () => {
    setTextMessage(!textMessage);
  };

  const validation = () => {
    let containErrors = false;

    const firstNameError = validateInputString(firstName);
    const lastNameError = validateInputString(lastName);
    const birthDateError = validateSelectDate(birthDate);
    const ethnicityError = validateInputString(ethnicity);
    const genderError = validateInputString(gender);

    if (
      firstNameError.invalid ||
      lastNameError.invalid ||
      birthDateError.invalid ||
      ethnicityError.invalid ||
      genderError.invalid
    ) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleUpdateUser = () => {
    const reject = validation();
    if (reject) {
      setHasErrors(true);
    } else {
      const payload = {
        uuid: user.uuid,
        firstName,
        middleName,
        lastName,
        marriedLastName,
        suffix,
        email,
        birthDate: moment(birthDate).format('YYYY-MM-DD'),
        phone,
        ethnicity,
        gender,
        textMessage
      };

      dispatch(doPutUserProfile(payload));
      setHasErrors(false);
    }
  };

  return (
    <UserProfileEdit
      firstName={firstName}
      middleName={middleName}
      lastName={lastName}
      marriedLastName={marriedLastName}
      suffix={suffix}
      institutionIdTypeUuid={institutionIdTypeUuid}
      optionsInstitutionIdTypes={optionsInstitutionIdTypes}
      institutionId={institutionId}
      textMessage={textMessage}
      birthDate={birthDate}
      email={email}
      phone={phone}
      ethnicity={ethnicity}
      gender={gender}
      hasErrors={hasErrors}
      handleChange={handleChange}
      handleToggleText={handleToggleText}
      handleUpdateUser={handleUpdateUser}
      handleBirthDate={handleBirthDate}
    />
  );
}

UserProfileEditContainer.propTypes = {};

UserProfileEditContainer.defaultProps = {};
