const initialPartWeightsFormState = {
  parts: {},
  hasSetParts: false,
  showValidationErrors: false,
  distributeWeightEqually: false
};

const partWeightsFormReducer = (state, action) => {
  switch (action.type) {
    case 'setExisting': {
      const { parts, distributeWeightEqually } = action.payload;
      const newPartsObj = {};
      parts.forEach(part => {
        newPartsObj[part.uuid] = { ...part };
      });
      return {
        ...state,
        hasSetParts: true,
        distributeWeightEqually,
        parts: newPartsObj
      };
    }

    case 'setDistributeWeightEqually': {
      const { distributeWeightEqually } = action.payload;

      return {
        ...state,
        distributeWeightEqually
      };
    }
    case 'setPartWeight': {
      const { uuid, weight } = action.payload;
      const { parts } = state;

      const newParts = { ...parts };

      newParts[uuid].weight = weight;
      return {
        ...state,
        parts: newParts
      };
    }

    case 'setValidationErrors': {
      const { showValidationErrors } = action.payload;
      return {
        ...state,
        showValidationErrors
      };
    }

    case 'clearForm':
      return initialPartWeightsFormState;
    default: {
      throw new Error();
    }
  }
};

export { partWeightsFormReducer, initialPartWeightsFormState };
