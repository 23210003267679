import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import {
  FormGroup,
  FormControlLabel,
  Switch,
  FormLabel
} from '@material-ui/core/';
import { RemoveIcon, RubricIcon } from '@xcomp/xcomp-design-library/dist/icons';

import DeleteModal from '../../../../../Library/Modal/DeleteModal/DeleteModal';
import StatusCommitModalContainer from './StatusCommitModal/StatusCommitModalContainer';
import StatusGradeModalContainer from './StatusGradingModal/StatusGradingModalContainer';
import { ButtonOutline } from '../../../../../Library/ButtonSC';
import { doClearCreated } from '../../../../../../redux/actions/generalActions';

const AttemptHeader = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }
`;

const Label = styled.div`
  color: ${props => props.theme.fontColors.darker};
  position: relative;
  display: inline-block;
  z-index: 100;
  background: ${props => props.theme.colors.white};
  padding-right: 0.5rem;
  font-size: 14px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 19px;
`;

const SwitchSC = styled(FormGroup)`
  display: flex;
  align-items: center;
`;

const SwitchLabelLeft = styled(FormLabel)`
  && {
    margin-right: 1.325rem;
    color: ${props => props.theme.fontColors.darker};
  }
`;

const SwitchLabelRight = styled(FormLabel)`
  && {
    margin-left: 0;
    color: ${props => props.theme.fontColors.darker};
  }
`;

const PartGradingSection = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 32px;
`;

const ButtonRow = styled(({ margin, ...rest }) => <div {...rest} />)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: ${props => props.margin || 'initial'};
  position: relative;
  z-index: 300;
`;

const LockIconSC = styled(RubricIcon)`
  font-size: 16px;
  margin-right: 8px;
`;
const RemoveIconSC = styled(RemoveIcon)`
  font-size: 16px;
  margin-right: 8px;
`;

const ButtonOutlineSC = styled(ButtonOutline)`
  && {
    margin-right: 1rem;
  }
`;

export default function SectionGrading({
  sectionUuid,
  statusCommit,
  justificationMessage,
  statusGrade,
  isDeleteModalOpen,
  handleDeleteModalClose,
  handleDelete,
  onDeleteClick,
  handleJustificationChange,
  handleStatusCommitSubmit,
  handleStatusGradeSubmit,
  handleResetCommitment,
  handleCommit,
  handleUncommit
}) {
  const dispatch = useDispatch();
  const [isCommitModalOpen, setCommitModalOpen] = useState(false);
  const [isGradingModalOpen, setGradingModalOpen] = useState(false);

  const handleCommitModalOpen = event => {
    setCommitModalOpen(true);
    if (event.target.checked) {
      handleCommit();
    } else {
      handleUncommit();
    }
  };

  const handleCommitModalClose = () => {
    handleResetCommitment();
    setCommitModalOpen(false);
  };

  const handleCommitModalSuccess = () => {
    setCommitModalOpen(false);
  };

  const handleGradingModalOpen = () => {
    setGradingModalOpen(true);
  };

  const handleGradingModalClose = () => {
    setGradingModalOpen(false);
  };

  const handleGradingModalSuccess = () => {
    setGradingModalOpen(false);
    dispatch(doClearCreated());
  };

  const handleCommitStatusChange = handleCommitModalOpen;

  return (
    <>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={handleDeleteModalClose}
        handleDelete={handleDelete}
        type="Section Grades"
      />
      <StatusCommitModalContainer
        isModalOpen={isCommitModalOpen}
        handleModalClose={handleCommitModalClose}
        handleModalSuccess={handleCommitModalSuccess}
        handleSubmit={handleStatusCommitSubmit}
        statusCommit={statusCommit}
        justificationMessage={justificationMessage}
        handleJustificationChange={handleJustificationChange}
      />
      <StatusGradeModalContainer
        sectionUuid={sectionUuid}
        statusGrade={statusGrade}
        isModalOpen={isGradingModalOpen}
        handleModalClose={handleGradingModalClose}
        handleModalSuccess={handleGradingModalSuccess}
        handleSubmit={handleStatusGradeSubmit}
        onDeleteClick={onDeleteClick}
      />
      <AttemptHeader offset="0">
        <Label>section grading & commitment status</Label>
      </AttemptHeader>
      <PartGradingSection>
        <Column>
          <ButtonRow margin="4px 0px 24px 0px">
            {statusGrade === 'GRADED' ? (
              <ButtonOutlineSC onClick={onDeleteClick}>
                <RemoveIconSC />
                Ungrade This Section
              </ButtonOutlineSC>
            ) : (
              <ButtonOutlineSC onClick={handleGradingModalOpen}>
                <LockIconSC />
                Grade This Section
              </ButtonOutlineSC>
            )}
          </ButtonRow>

          <SwitchSC row>
            <SwitchLabelLeft>Uncommitted</SwitchLabelLeft>
            <FormControlLabel
              control={
                <Switch
                  checked={statusCommit === 'COMMITTED'}
                  onChange={handleCommitStatusChange}
                  name="section_status_commit"
                />
              }
            />
            <SwitchLabelRight>Committed</SwitchLabelRight>
          </SwitchSC>
        </Column>
        <Column />
      </PartGradingSection>
    </>
  );
}

SectionGrading.propTypes = {
  sectionUuid: PropTypes.string,
  justificationMessage: PropTypes.string,
  statusCommit: PropTypes.string.isRequired,
  statusGrade: PropTypes.string.isRequired,
  isDeleteModalOpen: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handleJustificationChange: PropTypes.func,
  handleStatusCommitSubmit: PropTypes.func,
  handleStatusGradeSubmit: PropTypes.func,
  handleResetCommitment: PropTypes.func,
  handleCommit: PropTypes.func,
  handleUncommit: PropTypes.func
};

SectionGrading.defaultProps = {
  sectionUuid: undefined,
  justificationMessage: '',
  isDeleteModalOpen: false,
  onDeleteClick: undefined,
  handleJustificationChange: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined,
  handleStatusCommitSubmit: undefined,
  handleStatusGradeSubmit: undefined,
  handleResetCommitment: undefined,
  handleCommit: undefined,
  handleUncommit: undefined
};
