import { Model, attr } from 'redux-orm';
import {
  GET_APPLICANT_COMMUNICATION_REPORT_TYPES_SUCCESS,
  GET_TRANSCRIPT_SUCCESS
} from '../actions-type';

class ApplicantCommunicationReportType extends Model {
  static reducer(action, ApplicantCommunicationReportType, session) {
    const { type, payload } = action;
    switch (type) {
      case GET_APPLICANT_COMMUNICATION_REPORT_TYPES_SUCCESS: {
        const applicantCommunicationReportTypes = [
          ...payload.applicantCommunicationReportTypes
        ];
        applicantCommunicationReportTypes.forEach(type =>
          ApplicantCommunicationReportType.upsert(type)
        );
        break;
      }

      case GET_TRANSCRIPT_SUCCESS: {
        const applicantCommunicationReportType = [
          ...payload.applicantCommunicationReportType
        ];
        applicantCommunicationReportType.forEach(type =>
          ApplicantCommunicationReportType.upsert(type)
        );
        break;
      }

      default:
        return session;
    }
  }
}

ApplicantCommunicationReportType.fields = {
  uuid: attr(),
  name: attr(),
  subType: attr(),
  order: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

ApplicantCommunicationReportType.modelName = 'ApplicantCommunicationReportType';

ApplicantCommunicationReportType.options = {
  idAttribute: 'uuid'
};

export default ApplicantCommunicationReportType;
