import React, { useEffect, useState } from 'react';
import Decimal from 'decimal.js-light';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AccordionRowContainer from '../../Library/AccordionRow/AccordionRowContainer';

const OpportunityReportAssessmentPanelAccordion = styled(AccordionRowContainer)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  && .accordion_row_label {
    flex-grow: 2;
    max-width: ${props => props.labelMaxWidth};
    white-space: nowrap;
    div {
      color: ${props => props.theme.fontColors.darker};
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .column_one_label {
    min-width: 140px;
  }
  .column_two_label {
    min-width: 100px;
  }
  .column_three_label {
    min-width: 120px;
  }
  .column_one_label,
  .column_two_label,
  .column_three_label {
    flex-grow: 0;
    text-align: right;
  }

  && .column_one_label,
  && .column_two_label {
    margin-right: 16px;
    span {
      color: ${props => props.theme.fontColors.darker};
      font-size: 14px;
      font-weight: 500;
    }
  }

  && .column_three_label {
    span {
      color: ${props => props.theme.fontColors.darker};
      font-size: 14px;
      font-weight: 500;
    }
  }

  .hover-effect {
    padding-right: 44px;
  }
  padding-left: 32px;
  width: 100%;
`;

const CourseAccordionRow = ({ student, course, performanceData }) => {
  const [perfData, setPerfData] = useState(performanceData[course.uuid]);

  useEffect(() => {
    if (student.uuid.includes('high'))
      setPerfData({
        opportunityCount: performanceData[course.uuid]?.highOpportunityCount,
        relativeScore: performanceData[course.uuid]?.highRelativeScore,
        pointsPerOpportunity: new Decimal(
          performanceData[course.uuid]?.highRelativeScore
        )
          .dividedBy(
            new Decimal(performanceData[course.uuid]?.highOpportunityCount)
          )
          .toFixed(2)
      });
    if (student.uuid.includes('average'))
      setPerfData({
        opportunityCount: performanceData[course.uuid]?.averageOpportunityCount,
        relativeScore: performanceData[course.uuid]?.averageRelativeScore,
        pointsPerOpportunity: new Decimal(
          performanceData[course.uuid]?.averageRelativeScore
        )
          .dividedBy(
            new Decimal(performanceData[course.uuid]?.averageOpportunityCount)
          )
          .toFixed(2)
      });
  }, [course.uuid, performanceData, student.uuid]);

  return (
    <OpportunityReportAssessmentPanelAccordion
      columnOne={`${perfData?.opportunityCount}  ${
        perfData?.place ? `(${perfData?.place})` : ''
      }`}
      columnTwo={perfData?.relativeScore}
      columnThree={perfData?.pointsPerOpportunity}
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
      columnThreeClassName="column_three_label"
      expandWidth="66px"
      height="50px"
      label={`${course.course_master.courseNumber} - ${course.course_master.title}`}
      withBottomBorder
      withLabelTooltip
      leaf
    />
  );
};

CourseAccordionRow.defaultProps = {
  student: {},
  course: {},
  performanceData: {}
};
CourseAccordionRow.propTypes = {
  student: PropTypes.object,
  course: PropTypes.object,
  performanceData: PropTypes.object
};

export default CourseAccordionRow;
