import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  formatLastName,
  formatFirstNameMiddleName
} from '../../../../../helpers/format/user.format';

import ApplicationCohort from './ApplicationCohort';

const Layout = styled.div`
  width: 100%;
`;

const Calculations = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: ${props => props.theme.fontColors.dark};
`;

const Input = styled.div`
  width: 130px;
  font-size: 14px;
  color: ${props => props.theme.fontColors.dark};
`;

const Report = styled.div`
  overflow-y: hidden;
`;

const User = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const UserName = styled.div`
  font-size: 14px;
  color: ${props => props.theme.fontColors.dark};
`;

const ApplicantRigor = styled.div`
  display: flex;
  padding-left: 198px;
  height: 40px;
  align-items: center;
`;

const LabelName = styled.div`
  font-size: 14px;
  margin-right: 10px;
  color: ${props => props.theme.fontColors.dark};
`;

export default function ApplicationLevel({ displayName, applicants }) {
  function displayNA(input) {
    if (!input) {
      return 'N/A';
    } else if (Number(input) === 0) {
      return 'N/A';
    } else {
      return input;
    }
  }

  return (
    <Layout>
      <Report>
        {applicants?.map(report => (
          <ApplicantRigor key={report?.uuid}>
            <User>
              <LabelName>{report?.user?.institutionId}</LabelName>
              <UserName>
                {displayName &&
                  `${formatLastName(
                    report?.user?.lastName,
                    report?.user?.marriedLastName,
                    report?.user?.suffix
                  )},
                  ${formatFirstNameMiddleName(
                    report?.user?.firstName,
                    report?.user?.middleName
                  )}`}
              </UserName>
              <ApplicationCohort cohortUuid={report?.cohortUuid} />
            </User>

            <Calculations>
              <Input>{report?.program_credits}</Input>
              <Input>{report?.program_score_average}</Input>
              <Input>{displayNA(report?.milestone_score_average)}</Input>
              <Input>{displayNA(report?.rigor_index)}</Input>
            </Calculations>
          </ApplicantRigor>
        ))}
      </Report>
    </Layout>
  );
}

ApplicationLevel.propTypes = {
  displayName: PropTypes.bool,
  applicants: PropTypes.array
};

ApplicationLevel.defaultProps = {
  displayName: false,
  applicants: []
};
