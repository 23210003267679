import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import FormSelectError from '../../Library/FormSelectError';
import { convertNullToString } from '../../../helpers/utilities';
import { programCodesSelector } from '../../../redux/selectors/degreeCodeSelector';
import { validateSelectField } from '../../../helpers/validation/validateGeneric';

export default function ProgramCode({
  programDiscipline,
  programCode,
  handleChange,
  hasErrors
}) {
  const programCodes = useSelector(
    state => programCodesSelector(state, programDiscipline),
    _.isEqual
  );

  const programCodesOptions = _.map(programCodes, programCode => ({
    value: programCode.uuid,
    label: `${programCode.code} - ${programCode.title}`
  }));

  return (
    <div>
      <FormSelectError
        name="programCode"
        label="Code*"
        handleValidation={validateSelectField}
        hasErrors={hasErrors}
        value={convertNullToString(programCode)}
        handleChange={handleChange}
        options={programCodesOptions}
      />
    </div>
  );
}

ProgramCode.propTypes = {
  programDiscipline: PropTypes.string,
  programCode: PropTypes.string,
  handleChange: PropTypes.func,
  hasErrors: PropTypes.bool
};
ProgramCode.defaultProps = {
  programDiscipline: '',
  programCode: '',
  handleChange: undefined,
  hasErrors: false
};
