import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LinkOutlineButton = styled(Link)`
  && {
    font-weight: 400;
    font-size: 14px;
    font-family: 'IBM Plex Sans';
    letter-spacing: 1.25px;
    color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    text-transform: uppercase;
    padding: 5px 15px;
    border: 1px solid ${props => props.theme.colors.primary};
    transition: background 0.4s ease-in, color 0.4s ease-in;
    line-height: 1.75;
    border-radius: 4px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};
    }
  }
`;

const LinkOutline = ({ className, children, ...rest }) => {
  return (
    <LinkOutlineButton className={className} {...rest}>
      {children}
    </LinkOutlineButton>
  );
};

LinkOutline.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.element
  ])
};
LinkOutline.defaultProps = {
  className: '',
  children: 'Button Text'
};

export default LinkOutline;
