import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import FormField from '../../../../../Library/FormField';

import { validateInputString } from '../../../../../../helpers/validation/validateGeneric';

const Title = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
`;

const IconArea = styled.div``;

export default function EditorAptitudeConversion({
  classificationUuid,
  examAptitudeUuid,
  conversions,
  hasErrors,
  handleUpdateConversion,
  handleRemoveConversion
}) {
  return (
    <Grid container spacing={4}>
      {conversions?.length > 0 && (
        <Grid item xs={12} sm={12}>
          <Grid container spacing={4} justifyContent="flex-end">
            <Grid item xs={12} sm={7}>
              <Title>Conversions</Title>
            </Grid>
          </Grid>
        </Grid>
      )}
      {conversions?.map(conversion => (
        <Grid item xs={12} sm={12} key={conversion.uuid}>
          <Grid container spacing={4} justifyContent="flex-end">
            <Grid item xs={12} sm={3}>
              <FormField
                name="scaledScore"
                label="Scaled Score*"
                type="number"
                onBlur={event =>
                  handleUpdateConversion(
                    event,
                    classificationUuid,
                    examAptitudeUuid,
                    conversion.uuid
                  )
                }
                hasErrors={hasErrors}
                value={conversion.scaledScore}
                handleValidate={validateInputString}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                name="percentage"
                label="Percentage*"
                type="number"
                onBlur={event =>
                  handleUpdateConversion(
                    event,
                    classificationUuid,
                    examAptitudeUuid,
                    conversion.uuid
                  )
                }
                hasErrors={hasErrors}
                value={conversion.percentage}
                handleValidate={validateInputString}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={1}
              direction="column"
              justifyContent="center"
            >
              <IconArea>
                <IconButton
                  onClick={() =>
                    handleRemoveConversion(
                      classificationUuid,
                      examAptitudeUuid,
                      conversion?.uuid
                    )
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </IconArea>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

EditorAptitudeConversion.propTypes = {
  classificationUuid: PropTypes.string,
  examAptitudeUuid: PropTypes.string,
  conversions: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleUpdateConversion: PropTypes.func,
  handleRemoveConversion: PropTypes.func
};

EditorAptitudeConversion.defaultProps = {
  classificationUuid: undefined,
  examAptitudeUuid: undefined,
  conversions: [],
  hasErrors: false,
  handleUpdateConversion: undefined,
  handleRemoveConversion: undefined
};
