import { ButtonOutline as ButtonOutlineLayout } from '@xcomp/xcomp-design-library';
import styled from 'styled-components';

export const ButtonOutline = styled(ButtonOutlineLayout)`
  && {
    border: 1px solid ${props => props.theme.colors.primary};
    transition: background 0.4s ease-in, color 0.4s ease-in, border 0.4s ease-in;

    svg {
      .cls-1,
      .cls-2 {
        transition: stroke 0.4s ease-in;
      }
    }

    &:hover {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};

      svg {
        .cls-1,
        .cls-2 {
          stroke: ${props => props.theme.colors.white};
        }
      }
    }
  }
`;
