import {
  DELETE_USER_COHORT,
  DELETE_USER_COHORT_ERROR,
  DELETE_USER_COHORT_SUCCESS,
  DELETE_USER_COHORTS,
  DELETE_USER_COHORTS_ERROR,
  DELETE_USER_COHORTS_SUCCESS,
  GET_APPLICANTS_BY_PROGRAM,
  GET_APPLICANTS_BY_PROGRAM_ERROR,
  GET_APPLICANTS_BY_PROGRAM_SUCCESS,
  GET_COHORT_APPLICANTS_BY_STATUS,
  GET_COHORT_APPLICANTS_BY_STATUS_ERROR,
  GET_COHORT_APPLICANTS_BY_STATUS_SUCCESS,
  GET_SCHOOL_STUDENTS_BY_STATUS,
  GET_SCHOOL_STUDENTS_BY_STATUS_ERROR,
  GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS,
  GET_USER_COHORT_BY_USER_AND_COHORT,
  GET_USER_COHORT_BY_USER_AND_COHORT_ERROR,
  GET_USER_COHORT_BY_USER_AND_COHORT_SUCCESS,
  GET_USERS_COHORT,
  GET_USERS_COHORT_ERROR,
  GET_USERS_COHORT_SUCCESS,
  PATCH_STUDENT_STATUS,
  PATCH_STUDENT_STATUS_ERROR,
  PATCH_STUDENT_STATUS_SUCCESS,
  PATCH_USER_COHORT_STATUS,
  PATCH_USER_COHORT_STATUS_ERROR,
  PATCH_USER_COHORT_STATUS_SUCCESS,
  POST_STUDENT_CONVERSION,
  POST_STUDENT_CONVERSION_ERROR,
  POST_STUDENT_CONVERSION_SUCCESS,
  POST_UPLOAD_APPLICANTS,
  POST_UPLOAD_APPLICANTS_ERROR,
  POST_UPLOAD_APPLICANTS_SUCCESS,
  POST_UPLOAD_APPLICANTS_WARNING,
  POST_USER_COHORT,
  POST_USER_COHORT_ERROR,
  POST_USER_COHORT_RESET,
  POST_USER_COHORT_SUCCESS,
  POST_USER_COHORTS,
  POST_USER_COHORTS_ERROR,
  POST_USER_COHORTS_SUCCESS,
  RESET_UPLOAD_APPLICANTS
} from '../actions-type';

const doGetUserCohortbyUserAndCohort = (userUuid, cohortUuid) => ({
  type: GET_USER_COHORT_BY_USER_AND_COHORT,
  payload: {
    userUuid,
    cohortUuid
  }
});

const doGetUserCohortbyUserAndCohortSuccess = result => ({
  type: GET_USER_COHORT_BY_USER_AND_COHORT_SUCCESS,
  payload: result
});

const doGetUserCohortbyUserAndCohortError = error => ({
  type: GET_USER_COHORT_BY_USER_AND_COHORT_ERROR,
  payload: error
});

const doPostUserCohort = userRole => ({
  type: POST_USER_COHORT,
  payload: userRole
});

const doPostUserCohortSuccess = result => ({
  type: POST_USER_COHORT_SUCCESS,
  payload: result
});

const doPostUserCohortError = error => ({
  type: POST_USER_COHORT_ERROR,
  payload: error
});

const doGetUserCohorts = (cohortUuid, status, role) => ({
  type: GET_USERS_COHORT,
  payload: {
    cohortUuid,
    status,
    role
  }
});

const doGetUserCohortsSuccess = result => ({
  type: GET_USERS_COHORT_SUCCESS,
  payload: result
});

const doGetUserCohortsError = error => ({
  type: GET_USERS_COHORT_ERROR,
  payload: error
});

const doPostUserCohorts = roles => ({
  type: POST_USER_COHORTS,
  payload: roles
});

const doPostUserCohortsSuccess = result => ({
  type: POST_USER_COHORTS_SUCCESS,
  payload: result
});

const doPostUserCohortsError = error => ({
  type: POST_USER_COHORTS_ERROR,
  payload: error
});

const doPostUserCohortsReset = () => ({
  type: POST_USER_COHORT_RESET
});

const doPatchUserCohortStatus = roles => ({
  type: PATCH_USER_COHORT_STATUS,
  payload: roles
});

const doPatchUserCohortStatusSuccess = result => ({
  type: PATCH_USER_COHORT_STATUS_SUCCESS,
  payload: result
});

const doPatchUserCohortStatusError = error => ({
  type: PATCH_USER_COHORT_STATUS_ERROR,
  payload: error
});

const doUploadApplicants = (cohortUuid, applicants) => ({
  type: POST_UPLOAD_APPLICANTS,
  payload: {
    cohortUuid,
    applicants
  }
});

const doUploadApplicantsSuccess = result => ({
  type: POST_UPLOAD_APPLICANTS_SUCCESS,
  payload: result
});

const doUploadApplicantsWarning = warning => ({
  type: POST_UPLOAD_APPLICANTS_WARNING,
  payload: { warning }
});

const doUploadApplicantsError = error => ({
  type: POST_UPLOAD_APPLICANTS_ERROR,
  payload: error
});

const doUploadApplicantsReset = () => ({
  type: RESET_UPLOAD_APPLICANTS
});

const doDeleteUserCohorts = roles => ({
  type: DELETE_USER_COHORTS,
  payload: roles
});

const doDeleteUserCohortsSuccess = result => ({
  type: DELETE_USER_COHORTS_SUCCESS,
  payload: result
});

const doDeleteUserCohortsError = error => ({
  type: DELETE_USER_COHORTS_ERROR,
  payload: error
});

const doPostStudentConversion = roles => ({
  type: POST_STUDENT_CONVERSION,
  payload: roles
});

const doPostStudentConversionSuccess = result => ({
  type: POST_STUDENT_CONVERSION_SUCCESS,
  payload: result
});

const doPostStudentConversionError = error => ({
  type: POST_STUDENT_CONVERSION_ERROR,
  payload: error
});

const doPatchStudentStatus = roles => ({
  type: PATCH_STUDENT_STATUS,
  payload: roles
});

const doPatchStudentStatusSuccess = result => ({
  type: PATCH_STUDENT_STATUS_SUCCESS,
  payload: result
});

const doPatchStudentStatusError = error => ({
  type: PATCH_STUDENT_STATUS_ERROR,
  payload: error
});

const doGetCohortApplicantsByStatus = (cohortUuid, status) => ({
  type: GET_COHORT_APPLICANTS_BY_STATUS,
  payload: { cohortUuid, status }
});

const doGetCohortApplicantsByStatusSuccess = result => ({
  type: GET_COHORT_APPLICANTS_BY_STATUS_SUCCESS,
  payload: result
});

const doGetCohortApplicantsByStatusError = error => ({
  type: GET_COHORT_APPLICANTS_BY_STATUS_ERROR,
  payload: error
});

const doGetSchoolStudentsByStatus = (schoolUuid, status, institutionId) => ({
  type: GET_SCHOOL_STUDENTS_BY_STATUS,
  payload: { schoolUuid, status, institutionId }
});

const doGetSchoolStudentsByStatusSuccess = result => ({
  type: GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS,
  payload: result
});

const doGetSchoolStudentsByStatusError = error => ({
  type: GET_SCHOOL_STUDENTS_BY_STATUS_ERROR,
  payload: error
});

const doDeleteUserCohort = (uuid, userUuid) => ({
  type: DELETE_USER_COHORT,
  payload: { uuid, userUuid }
});

const doDeleteUserCohortSuccess = result => ({
  type: DELETE_USER_COHORT_SUCCESS,
  payload: result
});

const doDeleteUserCohortError = error => ({
  type: DELETE_USER_COHORT_ERROR,
  payload: error
});

const doGetApplicantsByProgram = (userUuid, programUuid) => ({
  type: GET_APPLICANTS_BY_PROGRAM,
  payload: {
    userUuid,
    programUuid
  }
});

const doGetApplicantsByProgramSuccess = result => ({
  type: GET_APPLICANTS_BY_PROGRAM_SUCCESS,
  payload: result
});

const doGetApplicantsByProgramError = error => ({
  type: GET_APPLICANTS_BY_PROGRAM_ERROR,
  payload: error
});

export {
  doGetUserCohorts,
  doGetUserCohortsSuccess,
  doGetUserCohortsError,
  doPostUserCohort,
  doPostUserCohortSuccess,
  doPostUserCohortError,
  doGetUserCohortbyUserAndCohort,
  doGetUserCohortbyUserAndCohortSuccess,
  doGetUserCohortbyUserAndCohortError,
  doPostUserCohorts,
  doPostUserCohortsSuccess,
  doPostUserCohortsError,
  doPostUserCohortsReset,
  doPatchUserCohortStatus,
  doPatchUserCohortStatusSuccess,
  doPatchUserCohortStatusError,
  doUploadApplicants,
  doUploadApplicantsSuccess,
  doUploadApplicantsWarning,
  doUploadApplicantsError,
  doUploadApplicantsReset,
  doDeleteUserCohorts,
  doDeleteUserCohortsSuccess,
  doDeleteUserCohortsError,
  doPostStudentConversion,
  doPostStudentConversionSuccess,
  doPostStudentConversionError,
  doPatchStudentStatus,
  doPatchStudentStatusSuccess,
  doPatchStudentStatusError,
  doGetCohortApplicantsByStatus,
  doGetCohortApplicantsByStatusSuccess,
  doGetCohortApplicantsByStatusError,
  doGetSchoolStudentsByStatus,
  doGetSchoolStudentsByStatusSuccess,
  doGetSchoolStudentsByStatusError,
  doDeleteUserCohort,
  doDeleteUserCohortSuccess,
  doDeleteUserCohortError,
  doGetApplicantsByProgram,
  doGetApplicantsByProgramSuccess,
  doGetApplicantsByProgramError
};
