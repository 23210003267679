export const POST_UPLOAD_PART_LEGACY_GRADES = 'POST_UPLOAD_PART_LEGACY_GRADES';
export const POST_UPLOAD_PART_LEGACY_GRADES_SUCCESS =
  'POST_UPLOAD_PART_LEGACY_GRADES_SUCCESS';
export const POST_UPLOAD_PART_LEGACY_GRADES_ERROR =
  'POST_UPLOAD_PART_LEGACY_GRADES_ERROR';

export const DELETE_PART_LEGACY_GRADES = 'DELETE_PART_LEGACY_GRADES';
export const DELETE_PART_LEGACY_GRADES_SUCCESS =
  'DELETE_PART_LEGACY_GRADES_SUCCESS';
export const DELETE_PART_LEGACY_GRADES_ERROR =
  'DELETE_PART_LEGACY_GRADES_ERROR';

export const POST_UPLOAD_SECTION_LEGACY_GRADES =
  'POST_UPLOAD_SECTION_LEGACY_GRADES';
export const POST_UPLOAD_SECTION_LEGACY_GRADES_SUCCESS =
  'POST_UPLOAD_SECTION_LEGACY_GRADES_SUCCESS';
export const POST_UPLOAD_SECTION_LEGACY_GRADES_ERROR =
  'POST_UPLOAD_SECTION_LEGACY_GRADES_ERROR';

export const DELETE_SECTION_LEGACY_GRADES = 'DELETE_SECTION_LEGACY_GRADES';
export const DELETE_SECTION_LEGACY_GRADES_SUCCESS =
  'DELETE_SECTION_LEGACY_GRADES_SUCCESS';
export const DELETE_SECTION_LEGACY_GRADES_ERROR =
  'DELETE_SECTION_LEGACY_GRADES_ERROR';

export const RESET_UPLOAD_PART_LEGACY_GRADES =
  'RESET_UPLOAD_PART_LEGACY_GRADES';

export const RESET_UPLOAD_SECTION_LEGACY_GRADES =
  'RESET_UPLOAD_SECTION_LEGACY_GRADES';
