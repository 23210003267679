import {
  GET_COURSES_ERROR,
  GET_ACADEMIC_YEARS_BY_SCHOOL_ERROR,
  GET_DEPARTMENTS_ERROR,
  GET_TERMS_ERROR,
  GET_DEPARTMENTS_SUCCESS,
  GET_ACADEMIC_YEARS_BY_SCHOOL_SUCCESS,
  GET_TERMS_SUCCESS,
  GET_COURSES_SUCCESS,
  GET_SYLLABUS_ERROR,
  GET_SYLLABUS_SUCCESS,
  POST_CALCULATE_ENCOUNTER_OBJECTIVES_INVALID_SCORES
} from '../actions-type';

const INITIAL_STATE = {
  courseFetchError: false,
  departmentFetchError: false,
  academicYearFetchError: false,
  termFetchError: false,
  syllabusFetchError: false,
  userFetchError: false,
  objectiveInvalidRows: null,
};

const fetchErrorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COURSES_ERROR: {
      return {
        ...state,
        courseFetchError: true
      };
    }
    case GET_ACADEMIC_YEARS_BY_SCHOOL_ERROR: {
      return {
        ...state,
        academicYearFetchError: true
      };
    }
    case GET_DEPARTMENTS_ERROR: {
      return {
        ...state,
        departmentFetchError: true
      };
    }
    case GET_TERMS_ERROR: {
      return {
        ...state,
        termFetchError: true
      };
    }
    case GET_COURSES_SUCCESS: {
      return {
        ...state,
        courseFetchError: false
      };
    }
    case GET_ACADEMIC_YEARS_BY_SCHOOL_SUCCESS: {
      return {
        ...state,
        academicYearFetchError: false
      };
    }
    case GET_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        departmentFetchError: false
      };
    }
    case GET_TERMS_SUCCESS: {
      return {
        ...state,
        termFetchError: false
      };
    }
    case GET_SYLLABUS_ERROR: {
      return {
        ...state,
        syllabusFetchError: true
      };
    }
    case GET_SYLLABUS_SUCCESS: {
      return {
        ...state,
        syllabusFetchError: false
      };
    }
    case POST_CALCULATE_ENCOUNTER_OBJECTIVES_INVALID_SCORES: {
      return {
        ...state,
        objectiveInvalidRows: action.payload,
      };
    }
    default:
      return state;
  }
};

export default fetchErrorReducer;

export { INITIAL_STATE };
