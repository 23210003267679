import Decimal from 'decimal.js-light';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doGetCohortAssessmentsStudent } from '../../redux/actions/cohortAssessmentActions';
import {
  selectAssessmentByCollectionComplete,
  selectAssessmentByCollectionPending,
  selectAssessmentByCollectionRemaining
} from '../../redux/selectors/assessmentSelectors';
import { cohortAssessmentCollectionsSelector } from '../../redux/selectors/cohortAssessmentSelectors';
import { useQuery } from './router.hooks';

export const useGetCohortStudentAssessments = () => {
  const query = useQuery();
  const cohortUuid = query.get('cohortUuid');
  const userUuid = query.get('userUuid');
  const dispatch = useDispatch();

  const collections = useSelector(
    state => cohortAssessmentCollectionsSelector(state, cohortUuid),
    _.isEqual
  );

  const completed = useSelector(
    state =>
      collections.map(collection =>
        selectAssessmentByCollectionComplete(state, collection.uuid, userUuid)
      ),

    _.isEqual
  );

  const pending = useSelector(
    state =>
      collections.map(collection =>
        selectAssessmentByCollectionPending(state, collection.uuid, userUuid)
      ),
    _.isEqual
  );

  const remaining = useSelector(
    state =>
      collections.map(collection =>
        selectAssessmentByCollectionRemaining(
          state,
          collection.uuid,
          userUuid,
          completed
            ?.flat()
            ?.map(c => c?.assessmentBlocks?.map(b => b?.block?.uuid))
            .flat(),
          pending
            ?.flat()
            ?.map(c => c?.assessmentBlocks?.map(b => b?.block?.uuid))
            .flat()
        )
      ),
    _.isEqual
  );

  const { fetchingCohortAssessments } = useSelector(
    state => state.crudState,
    _.isEqual
  );

  const fetchCohortAssessments = useCallback(() => {
    if (cohortUuid && userUuid) {
      dispatch(doGetCohortAssessmentsStudent(cohortUuid, userUuid));
    }
  }, [cohortUuid, userUuid, dispatch]);

  return {
    fetchCohortAssessments,
    collections,
    loading: fetchingCohortAssessments,
    completionStatus: {
      completed: completed.flat(),
      pending: pending.flat(),
      remaining: remaining.flat()
    }
  };
};

export const useAssessmentCompletion = ({
  completed,
  pending,
  remaining,
  cohortAssessmentCollectionUuid
}) => {
  const completedAssessments = useMemo(
    () =>
      cohortAssessmentCollectionUuid
        ? completed?.filter(
            ca =>
              ca.assessment.cohortAssessmentCollectionUuid ===
              cohortAssessmentCollectionUuid
          )
        : completed,
    [cohortAssessmentCollectionUuid, completed]
  );

  const pendingAssessments = useMemo(
    () =>
      cohortAssessmentCollectionUuid
        ? pending?.filter(
            ca =>
              ca.assessment.cohortAssessmentCollectionUuid ===
              cohortAssessmentCollectionUuid
          )
        : pending,
    [cohortAssessmentCollectionUuid, pending]
  );

  const remainingAssessments = useMemo(
    () =>
      cohortAssessmentCollectionUuid
        ? remaining?.filter(
            ca =>
              ca.assessment.cohortAssessmentCollectionUuid ===
                cohortAssessmentCollectionUuid && ca.blocks.length > 0
          )
        : remaining,
    [remaining, cohortAssessmentCollectionUuid]
  );

  const isCompleted = useMemo(
    () =>
      // completedAssessments?.some(c => c?.assessment?.assessment_blocks?.length === c?.assessmentBlocks?.length &&
      remainingAssessments?.length === 0,
    [remainingAssessments]
  );

  const completedAmount = useMemo(
    () =>
      completedAssessments?.reduce(
        (a, c) => a + c?.assessmentBlocks?.length,
        0
      ),
    [completedAssessments]
  );

  const remainingAmount = useMemo(() => {
    let count = 0;

    remainingAssessments
      ?.filter(ra => ra.blocks.length > 0)
      .map(ra => {
        count += ra.blocks.length;
        return ra;
      });

    return count;
  }, [remainingAssessments]);

  const pendingAmount = useMemo(
    () =>
      pendingAssessments?.reduce(
        (a, c) =>
          c?.assessmentBlocks?.length > 0
            ? a + c?.assessmentBlocks?.length
            : a + 1,
        0
      ),
    [pendingAssessments]
  );

  const percentage = useMemo(() => {
    const completionRate = new Decimal(completedAmount);
    const remainingRate = new Decimal(remainingAmount);

    return completionRate
      .dividedBy(completionRate.add(remainingRate))
      .times(100)
      .toFixed(2);
  }, [completedAmount, remainingAmount]);

  return {
    completedAmount,
    remainingAmount,
    pendingAmount,
    completedAssessments,
    pendingAssessments,
    remainingAssessments,
    percentage,
    isCompleted
  };
};
