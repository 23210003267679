import React from 'react';
import PropTypes from 'prop-types';
import AcademicYearShow from './AcademicYearShow';

export default function AcademicYearShowContainer({ academicYear }) {
  return <AcademicYearShow academicYear={academicYear} />;
}

AcademicYearShowContainer.propTypes = {
  academicYear: PropTypes.object
};
AcademicYearShowContainer.defaultProps = {
  academicYear: {}
};
