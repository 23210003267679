/* eslint-disable jsx-a11y/aria-role */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import {
  ButtonMenu,
  ButtonPrimary,
  Heading2
} from '@xcomp/xcomp-design-library';
import FormErrors from '../../../Library/FormErrors';

import {
  ContainerPage,
  ContainerActions,
  ContainerContent,
  ElevateLow
} from '../../../Library/Layout';

import UserInstitutionAdmin from './UserInstitutionAdmin';
import UserSchoolAdmin from './UserSchoolAdmin';
import UserDepartmentFaculty from './UserDepartmentFaculty';
import UserDepartmentStaff from './UserDepartmentStaff';
import UserProgramAdmin from './UserProgramAdmin';
import UserProgramFaculty from './UserProgramFaculty';
import UserProgramStaff from './UserProgramStaff';
import UserCohortStudent from './UserCohortStudent';
import RoleInstitutionContainer from '../UserAddRole/RoleInstitution/RoleInstitutionContainer';
import RoleSchoolContainer from '../UserAddRole/RoleSchool/RoleSchoolContainer';
import RoleDepartmentContainer from '../UserAddRole/RoleDepartment/RoleDepartmentContainer';
import RoleProgramContainer from '../UserAddRole/RoleProgram/RoleProgramContainer';

import {
  visibleToInstitutionAdmin,
  visibleToInstitutionAdminSchoolAdmin,
  visibleToSchoolAdmin,
  visibleToSchoolAdminProgramAdmin
} from '../../../../helpers/authorization';

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding: 2rem;
`;

const RoleTitle = styled.div`
  margin-top: 40px;
  font-size: 16px;
  color: ${props => props.theme.colors.black};
  text-transform: uppercase;
`;

const Spacing = styled.div`
  height: 20px;
`;

const InstitutionRolesAuth = visibleToInstitutionAdmin(props => (
  <>
    <RoleTitle>INSTITUTION ADMIN ROLES</RoleTitle>
    {props?.institutionAdmin?.map(record => (
      <UserInstitutionAdmin
        key={record?.uuid}
        uuid={record?.uuid}
        status={record?.status}
        startDate={record?.startDate}
        endDate={record?.endDate}
        role={record?.role}
        email={record?.email}
        phone={record?.phone}
        hasErrors={props?.hasErrors}
        handleRole={props.handleRole}
        handleRoleDate={props.handleRoleDate}
        handleRoleRemove={props.handleRoleRemove}
      />
    ))}
  </>
));

const SchoolRolesAuth = visibleToInstitutionAdminSchoolAdmin(props => (
  <>
    <RoleTitle>SCHOOL ADMIN ROLES</RoleTitle>
    {props?.schoolAdmin?.map(record => (
      <UserSchoolAdmin
        key={record?.uuid}
        uuid={record?.uuid}
        status={record?.status}
        startDate={record?.startDate}
        endDate={record?.endDate}
        role={record?.role}
        email={record?.email}
        phone={record?.phone}
        schoolUuid={record?.schoolUuid}
        optionsSchool={props?.optionsSchool}
        hasErrors={props?.hasErrors}
        handleRole={props.handleRole}
        handleRoleDate={props.handleRoleDate}
        handleRoleRemove={props.handleRoleRemove}
      />
    ))}
  </>
));

const DepartmentFacultyAuth = visibleToSchoolAdmin(props => (
  <>
    <RoleTitle>DEPARTMENT FACULTY ROLES</RoleTitle>
    {props?.departmentFaculty?.map(record => (
      <UserDepartmentFaculty
        key={record?.uuid}
        uuid={record?.uuid}
        status={record?.status}
        startDate={record?.startDate}
        endDate={record?.endDate}
        role={record?.role}
        email={record?.email}
        phone={record?.phone}
        departmentUuid={record?.departmentUuid}
        schoolUuid={record?.schoolUuid}
        optionsSchool={props?.optionsSchool}
        hasErrors={props?.hasErrors}
        handleRole={props.handleRole}
        handleRoleDate={props.handleRoleDate}
        handleRoleRemove={props.handleRoleRemove}
      />
    ))}
  </>
));

const DepartmentStaffAuth = visibleToSchoolAdmin(props => (
  <>
    <RoleTitle>DEPARTMENT STAFF ROLES</RoleTitle>
    {props?.departmentStaff?.map(record => (
      <UserDepartmentStaff
        key={record?.uuid}
        uuid={record?.uuid}
        status={record?.status}
        startDate={record?.startDate}
        endDate={record?.endDate}
        role={record?.role}
        email={record?.email}
        phone={record?.phone}
        departmentUuid={record?.departmentUuid}
        schoolUuid={record?.schoolUuid}
        optionsSchool={props?.optionsSchool}
        hasErrors={props?.hasErrors}
        handleRole={props.handleRole}
        handleRoleDate={props.handleRoleDate}
        handleRoleRemove={props.handleRoleRemove}
      />
    ))}
  </>
));

const ProgramAdminAuth = visibleToSchoolAdminProgramAdmin(props => (
  <>
    <RoleTitle>PROGRAM ADMIN ROLES</RoleTitle>
    {props?.programAdmin?.map(record => (
      <UserProgramAdmin
        key={record?.uuid}
        uuid={record?.uuid}
        status={record?.status}
        startDate={record?.startDate}
        endDate={record?.endDate}
        role={record?.role}
        email={record?.email}
        phone={record?.phone}
        programUuid={record?.programUuid}
        schoolUuid={record?.schoolUuid}
        optionsSchool={props?.optionsSchool}
        hasErrors={props?.hasErrors}
        handleRole={props.handleRole}
        handleRoleDate={props.handleRoleDate}
        handleRoleRemove={props.handleRoleRemove}
      />
    ))}
  </>
));

const ProgramFacultyAuth = visibleToSchoolAdminProgramAdmin(props => (
  <>
    <RoleTitle>PROGRAM FACULTY ROLES</RoleTitle>
    {props?.programFaculty?.map(record => (
      <UserProgramFaculty
        key={record?.uuid}
        uuid={record?.uuid}
        status={record?.status}
        startDate={record?.startDate}
        endDate={record?.endDate}
        role={record?.role}
        email={record?.email}
        phone={record?.phone}
        programUuid={record?.programUuid}
        schoolUuid={record?.schoolUuid}
        optionsSchool={props?.optionsSchool}
        hasErrors={props?.hasErrors}
        handleRole={props.handleRole}
        handleRoleDate={props.handleRoleDate}
        handleRoleRemove={props.handleRoleRemove}
      />
    ))}
  </>
));

const ProgramStaffAuth = visibleToSchoolAdminProgramAdmin(props => (
  <>
    <RoleTitle>PROGRAM STAFF ROLES</RoleTitle>
    {props?.programStaff?.map(record => (
      <UserProgramStaff
        key={record?.uuid}
        uuid={record?.uuid}
        status={record?.status}
        startDate={record?.startDate}
        endDate={record?.endDate}
        role={record?.role}
        email={record?.email}
        phone={record?.phone}
        programUuid={record?.programUuid}
        schoolUuid={record?.schoolUuid}
        optionsSchool={props?.optionsSchool}
        hasErrors={props?.hasErrors}
        handleRole={props.handleRole}
        handleRoleDate={props.handleRoleDate}
        handleRoleRemove={props.handleRoleRemove}
      />
    ))}
  </>
));

export default function UserRoles({
  departmentFaculty,
  departmentStaff,
  hasErrors,
  institutionAdmin,
  openRoleDepartment,
  openRoleInstitution,
  openRoleProgram,
  openRoleSchool,
  optionsSchool,
  programAdmin,
  programFaculty,
  programStaff,
  schoolAdmin,
  selectedRole,
  student,
  userUuid,
  handleRole,
  handleRoleDate,
  handleRoleRemove,
  handleSaveRoles,
  handleSchoolRoleOpen,
  handleSchoolRoleClose,
  handleDepartmentRoleOpen,
  handleDepartmentRoleClose,
  handleProgramRoleOpen,
  handleProgramRoleClose,
  handleRoleInstitutionOpen,
  handleRoleInstitutionClose
}) {
  function authActions() {
    switch (selectedRole) {
      case 'institution_admin': {
        return [
          {
            label: 'Institution Roles',
            onClick: () => handleRoleInstitutionOpen()
          },
          {
            label: 'School Roles',
            onClick: () => handleSchoolRoleOpen()
          }
        ];
      }
      case 'school_admin': {
        return [
          {
            label: 'School Roles',
            onClick: () => handleSchoolRoleOpen()
          },
          {
            label: 'Department Roles',
            onClick: () => handleDepartmentRoleOpen()
          },
          {
            label: 'Program Roles',
            onClick: () => handleProgramRoleOpen()
          }
        ];
      }
      case 'program_admin': {
        return [
          {
            label: 'Program Roles',
            onClick: () => handleProgramRoleOpen()
          }
        ];
      }
      default:
        return [];
    }
  }

  return (
    <>
      <ContainerActions paddingTop="20px">
        <ButtonPrimary onClick={handleSaveRoles}>Save</ButtonPrimary>
      </ContainerActions>
      <ContainerPage paddingTop="50px">
        <ElevateLow>
          <TitleArea>
            <Heading2>Admin Roles</Heading2>
            <div>
              <ButtonMenu
                buttonText="Add Role"
                type="primary"
                options={authActions()}
              />
            </div>
          </TitleArea>

          <ContainerContent>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormErrors hasErrors={false} />
              </Grid>

              {institutionAdmin?.length > 0 && (
                <InstitutionRolesAuth
                  institutionAdmin={institutionAdmin}
                  hasErrors={hasErrors}
                  handleRole={handleRole}
                  handleRoleDate={handleRoleDate}
                  handleRoleRemove={handleRoleRemove}
                />
              )}

              {schoolAdmin?.length > 0 && (
                <SchoolRolesAuth
                  schoolAdmin={schoolAdmin}
                  optionsSchool={optionsSchool}
                  hasErrors={hasErrors}
                  handleRole={handleRole}
                  handleRoleDate={handleRoleDate}
                  handleRoleRemove={handleRoleRemove}
                />
              )}

              {departmentFaculty?.length > 0 && (
                <DepartmentFacultyAuth
                  departmentFaculty={departmentFaculty}
                  optionsSchool={optionsSchool}
                  hasErrors={hasErrors}
                  handleRole={handleRole}
                  handleRoleDate={handleRoleDate}
                  handleRoleRemove={handleRoleRemove}
                />
              )}

              {departmentStaff?.length > 0 && (
                <DepartmentStaffAuth
                  departmentStaff={departmentStaff}
                  optionsSchool={optionsSchool}
                  hasErrors={hasErrors}
                  handleRole={handleRole}
                  handleRoleDate={handleRoleDate}
                  handleRoleRemove={handleRoleRemove}
                />
              )}

              {programAdmin?.length > 0 && (
                <ProgramAdminAuth
                  programAdmin={programAdmin}
                  optionsSchool={optionsSchool}
                  hasErrors={hasErrors}
                  handleRole={handleRole}
                  handleRoleDate={handleRoleDate}
                  handleRoleRemove={handleRoleRemove}
                />
              )}

              {programFaculty?.length > 0 && (
                <ProgramFacultyAuth
                  programFaculty={programFaculty}
                  optionsSchool={optionsSchool}
                  hasErrors={hasErrors}
                  handleRole={handleRole}
                  handleRoleDate={handleRoleDate}
                  handleRoleRemove={handleRoleRemove}
                />
              )}

              {programStaff?.length > 0 && (
                <ProgramStaffAuth
                  programStaff={programStaff}
                  optionsSchool={optionsSchool}
                  hasErrors={hasErrors}
                  handleRole={handleRole}
                  handleRoleDate={handleRoleDate}
                  handleRoleRemove={handleRoleRemove}
                />
              )}
            </Grid>
          </ContainerContent>
        </ElevateLow>
        <Spacing>&nbsp;</Spacing>
        <ElevateLow>
          <ContainerContent>
            {student?.length > 0 && <UserCohortStudent student={student} />}
          </ContainerContent>
        </ElevateLow>
        <RoleInstitutionContainer
          userUuid={userUuid}
          openRoleInstitution={openRoleInstitution}
          handleRoleInstitutionClose={handleRoleInstitutionClose}
        />
        <RoleSchoolContainer
          userUuid={userUuid}
          openRoleSchool={openRoleSchool}
          optionsSchool={optionsSchool}
          handleSchoolRoleClose={handleSchoolRoleClose}
        />
        <RoleDepartmentContainer
          userUuid={userUuid}
          openRoleDepartment={openRoleDepartment}
          optionsSchool={optionsSchool}
          handleDepartmentRoleClose={handleDepartmentRoleClose}
        />
        <RoleProgramContainer
          userUuid={userUuid}
          openRoleProgram={openRoleProgram}
          optionsSchool={optionsSchool}
          handleProgramRoleClose={handleProgramRoleClose}
        />
      </ContainerPage>
    </>
  );
}

UserRoles.propTypes = {
  departmentFaculty: PropTypes.array,
  departmentStaff: PropTypes.array,
  hasErrors: PropTypes.bool,
  institutionAdmin: PropTypes.array,
  openRoleDepartment: PropTypes.bool,
  openRoleInstitution: PropTypes.bool,
  openRoleProgram: PropTypes.bool,
  openRoleSchool: PropTypes.bool,
  optionsSchool: PropTypes.array,
  programAdmin: PropTypes.array,
  programFaculty: PropTypes.array,
  programStaff: PropTypes.array,
  schoolAdmin: PropTypes.array,
  selectedRole: PropTypes.string,
  student: PropTypes.array,
  userUuid: PropTypes.string,
  handleRole: PropTypes.func,
  handleRoleDate: PropTypes.func,
  handleRoleRemove: PropTypes.func,
  handleSaveRoles: PropTypes.func,
  handleSchoolRoleOpen: PropTypes.func,
  handleDepartmentRoleOpen: PropTypes.func,
  handleProgramRoleOpen: PropTypes.func,
  handleSchoolRoleClose: PropTypes.func,
  handleDepartmentRoleClose: PropTypes.func,
  handleProgramRoleClose: PropTypes.func,
  handleRoleInstitutionOpen: PropTypes.func,
  handleRoleInstitutionClose: PropTypes.func
};

UserRoles.defaultProps = {
  departmentFaculty: [],
  departmentStaff: [],
  hasErrors: false,
  institutionAdmin: [],
  openRoleDepartment: false,
  openRoleInstitution: false,
  openRoleProgram: false,
  openRoleSchool: false,
  optionsSchool: [],
  programAdmin: [],
  programFaculty: [],
  programStaff: [],
  schoolAdmin: [],
  selectedRole: undefined,
  student: [],
  userUuid: undefined,
  handleRole: undefined,
  handleRoleDate: undefined,
  handleRoleRemove: undefined,
  handleSaveRoles: undefined,
  handleSchoolRoleOpen: undefined,
  handleDepartmentRoleOpen: undefined,
  handleProgramRoleOpen: undefined,
  handleSchoolRoleClose: undefined,
  handleDepartmentRoleClose: undefined,
  handleProgramRoleClose: undefined,
  handleRoleInstitutionOpen: undefined,
  handleRoleInstitutionClose: undefined
};
