import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import FormSelectError from '../../../../Library/FormSelectError';
import { convertNullToString } from '../../../../../helpers/utilities';
import { validateSelectField } from '../../../../../helpers/validation/validateGeneric';
import SelectUserFieldContainer from '../../../../Library/SelectUserField/SelectUserFieldContainer';
import withValidation from '../../../../Library/FieldWithError/FieldWithError';
import { validateSyllabusSelectProgramAssessmentMaster } from '../../../../../helpers/validation/validateSyllabus';

import {
  programAssessmentCollectionsSelector,
  programAssessmentMastersSelector
} from '../../../../../redux/selectors/programSelectors';

const Layout = styled.div`
  width: 100%;
`;

const FormSelectErrorSC = styled(FormSelectError)`
  && {
    margin-bottom: 48px;
  }
`;

const ProgramAssessmentMastersSelectField = ({
  programAssessmentMaster,
  selectedAssessmentMasterUuid,
  assessmentDispatch,
  hasErrors,
  isNew
}) => {
  const [selectedProgramAssessmentMasterUuid, setSelectedProgramAssessment] =
    useState('');

  const [
    selectedProgramAssessmentCollectionUuid,
    setSelectedProgramAssessmentCollection
  ] = useState('');

  const [optionsAssessmentCollections, setAssessmentCollections] = useState([]);
  const [optionsAssessmentMasters, setAssessmentMasters] = useState([]);

  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  const programAssessmentCollections = useSelector(
    state => programAssessmentCollectionsSelector(state, selectedProgramUuid),
    _.isEqual
  );

  const programAssessmentMasters = useSelector(
    state =>
      programAssessmentMastersSelector(
        state,
        selectedProgramAssessmentCollectionUuid
      ),
    _.isEqual
  );

  const setExistingState = useCallback(() => {
    if (programAssessmentMaster)
      setSelectedProgramAssessmentCollection(
        programAssessmentMaster.programAssessmentCollectionUuid
      );
    setSelectedProgramAssessment(selectedAssessmentMasterUuid);
  }, [selectedAssessmentMasterUuid, programAssessmentMaster]);

  useEffect(() => {
    if (selectedAssessmentMasterUuid) {
      setExistingState({
        selectedAssessmentMasterUuid
      });
    }
  }, [selectedAssessmentMasterUuid, setExistingState]);

  useEffect(() => {
    if (programAssessmentCollections) {
      const assessmentCollectionOptions = _.map(
        programAssessmentCollections,
        aType => ({
          value: aType.uuid,
          label: aType.title
        })
      ).sort((a, b) => a.title - b.title);

      setAssessmentCollections(assessmentCollectionOptions);
    }
  }, [programAssessmentCollections]);

  useEffect(() => {
    const programAssessments = programAssessmentMasters
      ? programAssessmentMasters.map(assessment => ({
          value: assessment.uuid,
          label: assessment.title
        }))
      : [];

    setAssessmentMasters(programAssessments);
  }, [programAssessmentMasters]);

  useEffect(() => {
    if (isNew) {
      setSelectedProgramAssessment('');
      setSelectedProgramAssessmentCollection('');
    }
  }, [isNew]);

  const handleCollectionChange = event => {
    setSelectedProgramAssessmentCollection(event.target.value);
  };

  const handleAssessmentChange = values => {
    setSelectedProgramAssessment(values);
    assessmentDispatch({
      type: 'setSelectedAssessmentMasterUuid',
      payload: {
        programAssessmentMasterUuid: values
      }
    });
  };

  const SelectUserFieldWithError = withValidation(SelectUserFieldContainer);

  return (
    <Layout>
      <Grid item xs={12}>
        <FormSelectErrorSC
          name="assessmentCollectionUuid"
          label="Assessment Collection"
          value={convertNullToString(selectedProgramAssessmentCollectionUuid)}
          handleValidation={validateSelectField}
          hasErrors={hasErrors}
          handleChange={handleCollectionChange}
          options={optionsAssessmentCollections}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectUserFieldWithError
          name="assessmentMasterUuid"
          handleValidate={validateSyllabusSelectProgramAssessmentMaster}
          hasErrors={hasErrors}
          userSelect
          fieldName="assessmentMasterUuid"
          label="Program Assessment Master*"
          handleChange={handleAssessmentChange}
          usersArray={optionsAssessmentMasters}
          placeholder="Select"
          values={selectedProgramAssessmentMasterUuid}
          isDisabled={!selectedProgramAssessmentCollectionUuid}
          singleValue
        />
      </Grid>
    </Layout>
  );
};

ProgramAssessmentMastersSelectField.propTypes = {
  programAssessmentMaster: PropTypes.object,
  selectedAssessmentMasterUuid: PropTypes.string,
  isNew: PropTypes.bool,
  hasErrors: PropTypes.bool,
  assessmentDispatch: PropTypes.func
};
ProgramAssessmentMastersSelectField.defaultProps = {
  programAssessmentMaster: null,
  selectedAssessmentMasterUuid: '',
  isNew: false,
  hasErrors: false,
  assessmentDispatch: undefined
};

export default ProgramAssessmentMastersSelectField;
