import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body2 } from '@xcomp/xcomp-design-library';
import LazyLoad from 'react-lazyload';
import StepsPanel from './StepPanel';
import FormSelect from '../../../Library/FormSelect';
import { optionsSortStep } from '../../../../helpers/options';
import { Panel, PanelHeader } from '../../../Library/DashboardSC';

const HeaderSpacer = styled.div`
  width: 100%;
  height: 36px;
`;

const ListSortContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 8px 21px;
`;

const FormSelectSC = styled(FormSelect)`
  && {
    background-color: ${props => props.theme.colors.white};
    width: 500px;
  }
`;

const RowLabelsLayout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding-right: 88px;

  p {
    flex-grow: 0;
    min-width: 100px;
    margin-right: 0.5rem;

    &:first-child {
      flex-grow: 2;
    }
  }
`;

export default function StepList({
  assessmentBlocks,
  assessmentType,
  assessmentUuid,
  handleScoreAddAttemptModalOpen,
  handleScoreAddModalOpen,
  handleScoreEditModalOpen,
  handleSortSelection,
  scoresFilterSelection,
  scoreType,
  userScores
}) {
  return (
    <>
      <HeaderSpacer />
      <Panel maxWidth={1600}>
        <ListSortContainer>
          <FormSelectSC
            handleChange={handleSortSelection}
            label="Sort By"
            name="sort"
            options={optionsSortStep}
            value={scoresFilterSelection}
          />
        </ListSortContainer>
        <PanelHeader paddingLeft="24px">
          <RowLabelsLayout>
            <Body2>Student (Last Name, First Name)</Body2>
            <Body2>Total Relative</Body2>
            <Body2>Total Potential</Body2>
          </RowLabelsLayout>
        </PanelHeader>
        {userScores.map(userScore => (
          <LazyLoad key={userScore.studentUuid}>
            <StepsPanel
              assessmentBlocks={assessmentBlocks}
              assessmentType={assessmentType}
              assessmentUuid={assessmentUuid}
              fullName={userScore.fullName}
              handleScoreAddAttemptModalOpen={handleScoreAddAttemptModalOpen}
              handleScoreAddModalOpen={handleScoreAddModalOpen}
              handleScoreEditModalOpen={handleScoreEditModalOpen}
              scoreType={scoreType}
              studentUuid={userScore.studentUuid}
            />
          </LazyLoad>
        ))}
      </Panel>
    </>
  );
}

StepList.propTypes = {
  assessmentBlocks: PropTypes.array,
  assessmentType: PropTypes.string,
  assessmentUuid: PropTypes.string,
  handleScoreAddAttemptModalOpen: PropTypes.func,
  handleScoreAddModalOpen: PropTypes.func,
  handleScoreEditModalOpen: PropTypes.func,
  handleSortSelection: PropTypes.func,
  scoresFilterSelection: PropTypes.string,
  scoreType: PropTypes.string,
  userScores: PropTypes.array
};

StepList.defaultProps = {
  assessmentBlocks: [],
  assessmentType: '',
  assessmentUuid: '',
  handleScoreAddAttemptModalOpen: undefined,
  handleScoreAddModalOpen: undefined,
  handleScoreEditModalOpen: undefined,
  handleSortSelection: undefined,
  scoresFilterSelection: '',
  scoreType: '',
  userScores: []
};
