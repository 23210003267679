import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Body2 } from '@xcomp/xcomp-design-library';

const ResultCardRow = styled.div`
  padding: 12px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  position: relative;
`;

const ColumnLabel = styled(Body2)`
  margin: 0;
`;

const BannerIdColumn = styled.div`
  width: 20%;
`;

const NameColumn = styled(({ isPointsOnly, ...rest }) => <div {...rest} />)`
  width: ${props => (props.isPointsOnly ? '70%' : '60%')};
`;

const LetterGradeColumn = styled.div`
  width: 10%;
`;

const CalculatedGradeColumn = styled.div`
  width: 10%;
`;

const GradeSummaryRowHeader = ({ isPointsOnly }) => (
  <ResultCardRow>
    <BannerIdColumn>
      <ColumnLabel>Banner ID</ColumnLabel>
    </BannerIdColumn>
    <NameColumn isPointsOnly={isPointsOnly}>
      <ColumnLabel>Student Name</ColumnLabel>
    </NameColumn>
    {!isPointsOnly ? (
      <>
        <LetterGradeColumn>
          <ColumnLabel>Grade</ColumnLabel>
        </LetterGradeColumn>
        <CalculatedGradeColumn>
          <ColumnLabel>Percentage</ColumnLabel>
        </CalculatedGradeColumn>
      </>
    ) : (
      <CalculatedGradeColumn>
        <ColumnLabel>Total Score</ColumnLabel>
      </CalculatedGradeColumn>
    )}
  </ResultCardRow>
);

GradeSummaryRowHeader.propTypes = {
  isPointsOnly: PropTypes.bool
};

GradeSummaryRowHeader.defaultProps = {
  isPointsOnly: false
};

export default GradeSummaryRowHeader;
