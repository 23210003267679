import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js-light';
import {
  BannerIdColumn,
  NameColumn,
  LetterGradeColumn,
  CalculatedGradeColumn,
  Subtitle1SC,
  getNameColumnWidth
} from './SharedSC';

const ResultCardRow = styled.div`
  padding: 12px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

export const getGradeOutput = (resultCard, isPointsOnly) => {
  const assessmentRelScore = resultCard.assessmentRelativeScore || '0.00';
  const thisCalculatedGrade = resultCard.calculatedGrade || '0.00';

  if (isPointsOnly) {
    return new Decimal(assessmentRelScore).toFixed(2);
  }

  if (resultCard.gradeModification && !resultCard.calculatedGrade) {
    return '--';
  }

  return new Decimal(thisCalculatedGrade).toFixed(2);
};

const GradeSummaryRow = ({ resultCard, hideStudentNames, isPointsOnly }) => {
  const thisGrade = getGradeOutput(resultCard, isPointsOnly);
  const criticalFailure = thisGrade === '0.00';
  const nameColumnWidth = getNameColumnWidth(isPointsOnly, false);
  return (
    <ResultCardRow
      key={isPointsOnly ? resultCard.studentUuid : resultCard.uuid}
    >
      <BannerIdColumn>
        <Subtitle1SC criticalFailure={criticalFailure}>
          {resultCard.institutionId}
        </Subtitle1SC>
      </BannerIdColumn>
      <NameColumn columnWidth={nameColumnWidth}>
        <Subtitle1SC criticalFailure={criticalFailure}>
          {!hideStudentNames
            ? `${resultCard.lastName}, ${resultCard.firstName}`
            : `---`}
        </Subtitle1SC>
      </NameColumn>
      {isPointsOnly ? (
        <CalculatedGradeColumn>
          <Subtitle1SC criticalFailure={criticalFailure}>
            {thisGrade}
          </Subtitle1SC>
        </CalculatedGradeColumn>
      ) : (
        <>
          <LetterGradeColumn>
            <Subtitle1SC criticalFailure={criticalFailure}>
              {resultCard.letterGrade}
            </Subtitle1SC>
          </LetterGradeColumn>
          <CalculatedGradeColumn>
            <Subtitle1SC criticalFailure={criticalFailure}>
              {thisGrade === '--' ? thisGrade : `${thisGrade}%`}
            </Subtitle1SC>
          </CalculatedGradeColumn>
        </>
      )}
    </ResultCardRow>
  );
};

GradeSummaryRow.propTypes = {
  hideStudentNames: PropTypes.bool,
  isPointsOnly: PropTypes.bool,
  resultCard: PropTypes.object
};

GradeSummaryRow.defaultProps = {
  hideStudentNames: false,
  isPointsOnly: false,
  resultCard: {}
};

export default GradeSummaryRow;
