import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ButtonIcon } from '@xcomp/xcomp-design-library';
import Chip from '@material-ui/core/Chip';
import ArtTrack from '@material-ui/icons/ArtTrack';
import { doGetCoursesByStudent } from '../../../../redux/actions/courseActions';
import { doGetCourseCollections } from '../../../../redux/actions/courseCollectionActions';
import { doGetLetterGradesBySchool } from '../../../../redux/actions/letterGradeActions';

const ChipSC = styled(Chip)`
  margin-left: 10px;
`;
const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;

  & * {
    cursor: pointer;
  }
`;

export default function CoursesView({
  userUuid,
  cohortUuid,
  view,
  handleSetView
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const schoolUuid = useSelector(state => state.userState.selectedSchoolUuid);

  useEffect(() => {
    if (userUuid) {
      dispatch(doGetCoursesByStudent(userUuid));
    }
    if (cohortUuid) {
      dispatch(doGetCourseCollections(cohortUuid));
    }
  }, [dispatch, userUuid, cohortUuid]);

  useEffect(() => {
    if (schoolUuid) {
      dispatch(doGetLetterGradesBySchool(schoolUuid));
    }
  }, [dispatch, schoolUuid]);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Layout>
      <ButtonIcon
        aria-controls="menu"
        aria-owns={anchorEl ? 'menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
        noHoverBg
      >
        <ChipSC icon={<ArtTrack />} size="small" label={`By ${view}`} />
      </ButtonIcon>

      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={event => {
            handleClose(event);
            handleSetView('Academic Year & Term');
          }}
        >
          View by Academic Year & Term
        </MenuItem>
        <MenuItem
          onClick={event => {
            handleClose(event);
            handleSetView('Cohort Collection');
          }}
        >
          View by Cohort Collection
        </MenuItem>
      </Menu>
    </Layout>
  );
}

CoursesView.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  view: PropTypes.string,
  handleSetView: PropTypes.func
};

CoursesView.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  view: undefined,
  handleSetView: undefined
};
