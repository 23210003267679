import { atom } from 'jotai';
import { fetchWithState } from '../../../../helpers/utilities';

export const collectionsAtom = atom([]);
export const fetchingAtom = atom(false);
export const errorAtom = atom(null);

export const fetchCollectionsAtom = atom(
  get => get(collectionsAtom),
  async (get, set, programUuid) => {
    set(fetchingAtom, true);

    const { value } = await fetchWithState(
      `/api/program/${programUuid}/quantitative-skills-collections`
    );

    set(collectionsAtom, value);
    set(fetchingAtom, false);
  }
);

export const newCollectionModalAtom = atom(false);
export const newCollectionCreatingAtom = atom(false);
export const newCollectionNameAtom = atom('');
export const newCollectionIsDefaultAtom = atom(false);
// export const newCollectionInitialStateAtom = atom({
//   name: 'kj',
//   is_default_collection: false,
//   procedure_threshold: '0.00',
//   score_threshold: '0.00',
//   units_threshold: '0.00',
//   epas_threshold: '0.00',
//   rvus_threshold: '0.00'
// });
export const newCollectionRawAtom = atom({
  name: '',
  is_default_collection: false,
  procedure_threshold: '0.00',
  score_threshold: '0.00',
  units_threshold: '0.00',
  epas_threshold: '0.00',
  rvus_threshold: '0.00'
});

export const newCollectionDataAtom = atom(
  null,
  (get, set, value) => {
    set(newCollectionRawAtom, {
      ...get(newCollectionRawAtom),
      ...value
    });
  }
  // (get, set, value) => {
  //   // console.log(get(newCollectionDataAtom));
  //   console.log(get(newCollectionInitialStateAtom));
  //   set(newCollectionInitialStateAtom, {
  //     ...get(newCollectionInitialStateAtom),
  //     ...value
  //   });
  // }
);

// newCollectionDataAtom.onMount = setAtom => {
//   setAtom({
//     name: '',
//     is_default_collection: false,
//     procedure_threshold: '0.00',
//     score_threshold: '0.00',
//     units_threshold: '0.00',
//     epas_threshold: '0.00',
//     rvus_threshold: '0.00'
//   });
// };

export const deleteCollectionModalAtom = atom({});
export const deletingCollectionAtom = atom(false);
export const deleteCollectionAtom = atom(
  null,
  async (get, set, collectionUuid) => {
    set(deletingCollectionAtom, true);

    await fetchWithState(
      `/api/quantitative-skills/${collectionUuid}/delete`,
      'DELETE'
    );

    set(deletingCollectionAtom, false);
    set(deleteCollectionModalAtom, {
      ...get(deleteCollectionModalAtom),
      [collectionUuid]: false
    });
  }
);

export const newCollectionAtom = atom(null, async (get, set, programUuid) => {
  set(newCollectionCreatingAtom, true);
  // const name = get(newCollectionNameAtom);
  // const is_default_collection = get(newCollectionIsDefaultAtom);
  const newCollection = get(newCollectionRawAtom);

  const { error } = await fetchWithState(
    '/api/program/quantitative-skills-collections/create',
    'POST',
    JSON.stringify({
      ...newCollection,
      programUuid
    })
  );

  if (error) {
    set(errorAtom, error);
    set(newCollectionCreatingAtom, false);
    return;
  }

  set(newCollectionModalAtom, false);
  set(newCollectionRawAtom, {
    name: '',
    is_default_collection: false,
    procedure_threshold: '0.00',
    score_threshold: '0.00',
    units_threshold: '0.00',
    epas_threshold: '0.00',
    rvus_threshold: '0.00'
  });
  // set(newCollectionNameAtom, '');
  // set(newCollectionIsDefaultAtom, false);
  set(newCollectionCreatingAtom, false);
});
