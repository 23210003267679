import { Model, attr } from 'redux-orm';
import { GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES_SUCCESS } from '../actions-type';

class ApplicantDemographicReportType extends Model {
  static reducer(action, ApplicantDemographicReportType, session) {
    const { type, payload } = action;
    switch (type) {
      case GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES_SUCCESS: {
        const applicantDemographicReportTypes = [
          ...payload.applicantDemographicReportTypes
        ];
        applicantDemographicReportTypes.forEach(type =>
          ApplicantDemographicReportType.upsert(type)
        );
        break;
      }
      default:
        return session;
    }
  }
}

ApplicantDemographicReportType.fields = {
  uuid: attr(),
  name: attr(),
  order: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

ApplicantDemographicReportType.modelName = 'ApplicantDemographicReportType';

ApplicantDemographicReportType.options = {
  idAttribute: 'uuid'
};

export default ApplicantDemographicReportType;
