import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '@xcomp/xcomp-design-library/dist/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '../../../Library/Modal/Modal';
import FormSelectError from '../../../Library/FormSelectError';
import { convertNullToString } from '../../../../helpers/utilities';
import { validateSelectField } from '../../../../helpers/validation/validateGeneric';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 10px;
  padding-bottom: 30px;
  width: 500px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
`;

const InvisibleLineBreak = styled.div`
  height: 4px;
`;

const Send = styled(ButtonPrimary)`
  && {
    margin-top: 6px;
    margin-left: 20px;
  }
`;

const AdminAppId = ({
  hasErrors,
  createAppId,
  email,
  optionsEmails,
  openAdminAppId,
  handleAdminAppIdClose,
  handleGenerateAppId,
  handleEmailSelection
}) => {
  return (
    <Modal open={openAdminAppId} onClose={handleAdminAppIdClose}>
      <ModalBody>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={11} sm={11}>
            <Title>Generate AppID</Title>
          </Grid>
          <Grid container justifyContent="flex-end" item xs={1} sm={1}>
            <IconButton onClick={handleAdminAppIdClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item sm={12} xs={12}>
            {createAppId ? <LinearProgress /> : <InvisibleLineBreak />}
          </Grid>

          <Grid item xs={12} sm={12}>
            <Grid container alignItems="center">
              <Grid item sm={9} xs={9}>
                <FormSelectError
                  name="email"
                  value={convertNullToString(email)}
                  label="Email*"
                  handleChange={handleEmailSelection}
                  options={optionsEmails}
                  hasErrors={hasErrors}
                  handleValidation={validateSelectField}
                />
              </Grid>
              <Grid container justifyContent="flex-start" item xs={3} sm={3}>
                <Send onClick={handleGenerateAppId}>Send</Send>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalBody>
    </Modal>
  );
};

AdminAppId.propTypes = {
  hasErrors: PropTypes.bool,
  createAppId: PropTypes.bool,
  email: PropTypes.string,
  optionsEmails: PropTypes.array,
  openAdminAppId: PropTypes.bool,
  handleAdminAppIdClose: PropTypes.func,
  handleGenerateAppId: PropTypes.func,
  handleEmailSelection: PropTypes.func
};

AdminAppId.defaultProps = {
  hasErrors: false,
  createAppId: false,
  email: undefined,
  optionsEmails: [],
  openAdminAppId: false,
  handleAdminAppIdClose: undefined,
  handleGenerateAppId: undefined,
  handleEmailSelection: undefined
};

export default AdminAppId;
