import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MoreIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonIcon } from '@xcomp/xcomp-design-library';

const MenuSC = styled(Menu)`
  && {
    margin-top: 3rem;
  }
`;

const MenuItemSC = styled(MenuItem)`
  && {
    font-size: 0.875rem;
    padding: 0.5rem 0.75rem;
  }
`;

class LetterGradeShowMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const {
      className,
      letterGradeUuid,
      handleEditMode,
      handleDeleteModalOpen
    } = this.props;

    return (
      <div className={className}>
        <ButtonIcon
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          noHoverBg
        >
          <MoreIcon />
        </ButtonIcon>
        <MenuSC
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItemSC
            onClick={event => {
              this.handleClose(event);
              handleEditMode(letterGradeUuid);
            }}
          >
            Edit
          </MenuItemSC>
          <MenuItemSC
            onClick={event => {
              this.handleClose(event);
              handleDeleteModalOpen(letterGradeUuid);
            }}
          >
            Delete
          </MenuItemSC>
        </MenuSC>
      </div>
    );
  }
}

LetterGradeShowMenu.propTypes = {
  className: PropTypes.string,
  letterGradeUuid: PropTypes.string,
  handleEditMode: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func
};

LetterGradeShowMenu.defaultProps = {
  className: '',
  letterGradeUuid: '',
  handleEditMode: undefined,
  handleDeleteModalOpen: undefined
};

export default LetterGradeShowMenu;
export { MenuSC, MenuItemSC };
