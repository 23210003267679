import { tintColor } from '@xcomp/xcomp-design-library/dist/helpers';
import styled, { css } from 'styled-components';

export const StickyTitleTd = styled.td`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-width: 75px;
  max-width: 75px;
  z-index: 10;
  position: sticky;
  left: ${props => props.left};
  background-color: ${props =>
    (props.redOne && tintColor(props.theme.colors.red[100], 0.5)) ||
    (props.redTwo && tintColor(props.theme.colors.red[100], 0.4)) ||
    (props.blueOne && tintColor(props.theme.colors.blue[500], 0.8)) ||
    'white'};
  border-top: ${props => `solid 2px ${props.theme.colors.grey[300]}`};
  border-right: ${props =>
    props.borderRight ? `solid 1px ${props.theme.colors.grey[300]}` : 'none'};
`;

export const TableDataText = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${props =>
    (props.warningFontColor && props.theme.colors.status.error) ||
    (props.secondaryFontColor && props.theme.fontColors.dark) ||
    props.theme.fontColors.darker};
  ${props =>
    props.strikeThrough &&
    css`
      text-decoration: line-through;
    `};
  .MuiChip-root {
    background-color: ${props =>
      props.redChip ? `${props.theme.colors.red[200]} !important` : props.theme.colors.blue[100]};
  }
`;

export const PointsTd = styled.td`
  ${props => props.displayNone && `display: none`};
  min-width: 75px;
  max-width: 75px;
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  background-color: ${props =>
    (props.greyOne && props.theme.colors.grey[100]) ||
    (props.blueOne && tintColor(props.theme.colors.blue[500], 0.8)) ||
    (props.blueTwo && tintColor(props.theme.colors.blue[100], 0.5)) ||
    'white'};
`;
