import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tab, Tabs } from '@material-ui/core';
import { formatYYYY } from '../../../helpers/date-fns.utils';
import { ContainerPage, ContainerResize } from '../../Library/Layout';
import Header from '../../Library/Header';
import CohortApplicantDemographicReportContainer from './CohortApplicantDemographicReport/CohortApplicantDemographicReportContainer';
import CohortApplicantCognitiveReportContainer from './CohortApplicantCognitiveReport/CohortApplicantCognitiveReportContainer';
import CohortApplicantCommunicationReportContainer from './CohortApplicantCommunicationReport/CohortApplicantCommunicationReportContainer';
import CohortApplicantPhysicalSkillsReportContainer from './CohortApplicantPhysicalSkillsReport/CohortApplicantPhysicalSkillsReportContainer';
import CohortApplicantNoncognitiveReportContainer from './CohortApplicantNoncognitiveReport/CohortApplicantNoncognitiveReportContainer';

const TabsSC = styled(Tabs)`
  background-color: ${props => props.theme.colors.white};
  padding-top: 77px;
`;

const ApplicantReports = ({
  basePath,
  cohort,
  currentTab,
  onBackAction,
  path
}) => {
  const headerSubtitle = `cohort ${formatYYYY(cohort.startDate)} - ${formatYYYY(
    cohort.endDate
  )}`;
  return (
    <ContainerResize>
      <Header
        title="Applicant Reports"
        subtitle={headerSubtitle}
        backButtonOverride={onBackAction}
      />
      <TabsSC
        value={currentTab}
        textColor="primary"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab
          component={Link}
          to={`${basePath}/demographics`}
          label="demographics"
        />
        <Tab component={Link} to={`${basePath}/cognitive`} label="cognitive" />
        <Tab
          component={Link}
          to={`${basePath}/non-cognitive`}
          label="non-cognitive"
        />
        <Tab
          component={Link}
          to={`${basePath}/physical-skills`}
          label="physical skills"
        />
        <Tab
          component={Link}
          to={`${basePath}/communication`}
          label="communication"
        />
      </TabsSC>
      <ContainerPage paddingTop="0px">
        <Switch>
          <Route path={`${path}/demographics`}>
            <CohortApplicantDemographicReportContainer
              cohortUuid={cohort?.uuid}
            />
          </Route>
          <Route path={`${path}/cognitive`}>
            <CohortApplicantCognitiveReportContainer
              cohortUuid={cohort?.uuid}
              programUuid={cohort?.programUuid}
            />
          </Route>
          <Route path={`${path}/non-cognitive`}>
            <CohortApplicantNoncognitiveReportContainer
              cohortUuid={cohort?.uuid}
              programUuid={cohort?.programUuid}
            />
          </Route>
          <Route path={`${path}/physical-skills`}>
            <CohortApplicantPhysicalSkillsReportContainer
              cohortUuid={cohort?.uuid}
              programUuid={cohort?.programUuid}
            />
          </Route>
          <Route path={`${path}/communication`}>
            <CohortApplicantCommunicationReportContainer
              cohortUuid={cohort?.uuid}
              programUuid={cohort?.programUuid}
            />
          </Route>
        </Switch>
      </ContainerPage>
    </ContainerResize>
  );
};

ApplicantReports.propTypes = {
  basePath: PropTypes.string,
  cohort: PropTypes.object,
  currentTab: PropTypes.number,
  onBackAction: PropTypes.func,
  path: PropTypes.string
};
ApplicantReports.defaultProps = {
  basePath: '',
  cohort: {},
  currentTab: 0,
  onBackAction: undefined,
  path: ''
};

export default ApplicantReports;
