import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, FormHelperText } from '@material-ui/core';

import { DocumentIcon } from '@xcomp/xcomp-design-library/dist/icons';

import { validateSelectField } from '../../../../../helpers/validation/validateGeneric';
import { validateEncounterTitle } from '../../../../../helpers/validation/validateEncounter';

import { convertNullToString } from '../../../../../helpers/utilities';
import SyllabusSubHeader from '../../SyllabusSubHeader';

import FormSelectError from '../../../../Library/FormSelectError';
import FormField from '../../../../Library/FormField';

const FormHelperTextSC = styled(FormHelperText)`
  && {
    margin-top: 0.5rem;
    margin-bottom: 1.25rem;
  }
`;
const EncounterTitleField = ({
  hasErrors,
  encounterTypeUuid,
  optionsEncounterTypes,
  handleEncounterChange,
  restrictEdit,
  title,
  fieldInFocus,
  handleFieldInFocusValidation
}) => (
  <>
    <Grid item xs={12}>
      <SyllabusSubHeader first>
        <DocumentIcon />
        Encounter Information
      </SyllabusSubHeader>
    </Grid>
    <Grid item xs={12}>
      <FormSelectError
        name="encounterTypeUuid"
        label="Encounter Type*"
        hasErrors={hasErrors}
        value={convertNullToString(encounterTypeUuid)}
        options={optionsEncounterTypes}
        handleChange={handleEncounterChange}
        handleValidation={validateSelectField}
        disabled={restrictEdit}
      />
    </Grid>
    <Grid item xs={12}>
      <FormField
        name="title"
        label="Encounter Title*"
        value={title}
        inputProps={{ maxLength: 100 }}
        onBlur={handleEncounterChange}
        handleValidate={validateEncounterTitle}
        hasErrors={hasErrors || fieldInFocus === 'title'}
        onFocus={handleFieldInFocusValidation}
        disabled={restrictEdit}
      />
      <FormHelperTextSC>Character Count: {title.length}/100</FormHelperTextSC>
    </Grid>
  </>
);

EncounterTitleField.propTypes = {
  encounterTypeUuid: PropTypes.string,
  title: PropTypes.string,
  restrictEdit: PropTypes.bool,
  optionsEncounterTypes: PropTypes.arrayOf(PropTypes.object),
  fieldInFocus: PropTypes.string,
  hasErrors: PropTypes.bool,
  handleEncounterChange: PropTypes.func,
  handleFieldInFocusValidation: PropTypes.func
};

EncounterTitleField.defaultProps = {
  encounterTypeUuid: null,
  title: '',
  restrictEdit: true,
  optionsEncounterTypes: [],
  fieldInFocus: '',
  hasErrors: false,
  handleEncounterChange: undefined,
  handleFieldInFocusValidation: undefined
};

export default EncounterTitleField;
