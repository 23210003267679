import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getLetterGradesBySchool,
  postLetterGrade,
  putLetterGrade,
  deleteLetterGrade
} from '../../api/letterGradeApi';
import {
  doGetLetterGradesBySchoolSuccess,
  doGetLetterGradesBySchoolError,
  doPostLetterGradeSuccess,
  doPostLetterGradeError,
  doPutLetterGradeSuccess,
  doPutLetterGradeError,
  doDeleteLetterGradeSuccess,
  doDeleteLetterGradeError
} from '../actions/letterGradeActions';

import {
  GET_LETTER_GRADES_BY_SCHOOL,
  PUT_LETTER_GRADE,
  POST_LETTER_GRADE,
  DELETE_LETTER_GRADE
} from '../actions-type';

function* handleGetLetterGradesBySchool(action) {
  const { schoolUuid } = action.payload;
  try {
    const result = yield call(getLetterGradesBySchool, schoolUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetLetterGradesBySchoolSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetLetterGradesBySchoolError(error));
  }
}

function* handlePostLetterGrade(action) {
  const { letterGrade } = action.payload;
  try {
    const result = yield call(postLetterGrade, letterGrade);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostLetterGradeSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostLetterGradeError(error));
  }
}

function* handlePutLetterGrade(action) {
  const { letterGrade } = action.payload;
  try {
    const result = yield call(putLetterGrade, letterGrade);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutLetterGradeSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutLetterGradeError(error));
  }
}

function* handleDeleteLetterGrade(action) {
  const { letterGradeUuid } = action.payload;
  try {
    const result = yield call(deleteLetterGrade, letterGradeUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteLetterGradeSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteLetterGradeError(error));
  }
}

const letterGrade = [
  takeEvery(GET_LETTER_GRADES_BY_SCHOOL, handleGetLetterGradesBySchool),
  takeEvery(PUT_LETTER_GRADE, handlePutLetterGrade),
  takeEvery(POST_LETTER_GRADE, handlePostLetterGrade),
  takeEvery(DELETE_LETTER_GRADE, handleDeleteLetterGrade)
];

export { letterGrade };
