import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import Person from '@material-ui/icons/Person';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TooltipTitle = styled.span`
  font-size: 13px;
`;

const IconButton = styled(Button)`
  min-width: 40px !important;
  width: 44px;
  height: 44px;
  padding: 1px !important;
  //margin-right: 10px !important;
`;

export const AnonymousIcon = () => {
  return (
    <span className="MuiButton-label">
      <svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path d="M 12 1 z m 1 17 h -2 v -2 h 2 v 2 z m -1 -3.5 c -2.67 0 -8 1.34 -8 4 v 2 h 16 v -2 c 0 -2.66 -5.33 -4 -8 -4 z m 3.07 -5.25 l -0.9 0.92 c -0.72 0.73 -1.17 1.33 -1.17 2.83 h -2 v -0.5 c 0 -1.1 0.45 -2.1 1.17 -2.83 l 1.24 -1.26 c 0.37 -0.36 0.59 -0.86 0.59 -1.41 c 0 -1.1 -0.9 -2 -2 -2 s -2 0.9 -2 2 H 8 c 0 -2.21 2 -4 4 -4 s 4 1.79 4 4 c 0 0.88 -0.36 1.68 -0.93 2.25 z" />
      </svg>
    </span>
  );
};

const AnonymizeStudents = ({ isAnonymized, setIsAnonymized }) => {
  return (
    <Tooltip
      title={
        <TooltipTitle>
          {isAnonymized ? 'Identify Students' : 'De-Identify Students'}
        </TooltipTitle>
      }
      placement="bottom"
    >
      <IconButton
        variant={isAnonymized ? 'contained' : 'outlined'}
        color="primary"
        onClick={() => setIsAnonymized(!isAnonymized)}
      >
        {isAnonymized ? <AnonymousIcon /> : <Person fontSize="large" />}
      </IconButton>
    </Tooltip>
  );
};

AnonymizeStudents.propTypes = {
  isAnonymized: PropTypes.bool.isRequired,
  setIsAnonymized: PropTypes.func.isRequired
};

export default AnonymizeStudents;
