import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import { partSelectorByUuid } from '../../../../redux/selectors/partsSelectors';
import { doPatchPartLock } from '../../../../redux/actions/partActions';
import { doClearCreated } from '../../../../redux/actions/generalActions';

import Part from './Part';
import {
  getTotalNumberOfInProgressEncounters,
  getTotalNumberOfCompleteEncounters,
  getTotalNumberOfApprovedEncounters,
  getTotalNumberOfInProgressAssessments,
  getTotalNumberOfCompleteAssessments,
  getTotalNumberOfApprovedAssessments,
  getTotalNumberOfPendingEncounters,
  getTotalNumberOfPendingAssessments,
  getTotalNumberofEncounters,
  getTotalNumberofAssessments
} from '../../../../helpers/parts';

export default function PartContainer({
  courseUuid,
  sectionUuid,
  partUuid,
  termDatesDisplay,
  partIndex,
  sectionNumber,
  onFetchSection
}) {
  const dispatch = useDispatch();

  const part = useSelector(
    state => partSelectorByUuid(state, partUuid),
    shallowEqual
  );

  const successfullyClonedPartUuid = useSelector(
    state => state.formSuccessState.successfullyClonedPartUuid
  );

  const totalNumberOfInProgressEncounters =
    getTotalNumberOfInProgressEncounters(part);
  const totalNumberOfCompleteEncounters =
    getTotalNumberOfCompleteEncounters(part);
  const totalNumberOfApprovedEncounters =
    getTotalNumberOfApprovedEncounters(part);
  const totalNumberOfInProgressAssessments =
    getTotalNumberOfInProgressAssessments(part);
  const totalNumberOfCompleteAssessments =
    getTotalNumberOfCompleteAssessments(part);
  const totalNumberOfApprovedAssessments =
    getTotalNumberOfApprovedAssessments(part);
  const totalNumberOfPendingEncounters =
    getTotalNumberOfPendingEncounters(part);
  const totalNumberOfPendingAssessments =
    getTotalNumberOfPendingAssessments(part);
  const totalNumberofEncounters = getTotalNumberofEncounters(part);
  const totalNumberofAssessments = getTotalNumberofAssessments(part);

  const handlePartLock = () => {
    const payload = {
      partUuid: part.uuid,
      locked: !part.locked
    };

    dispatch(doPatchPartLock(payload));
  };

  useEffect(() => {
    if (successfullyClonedPartUuid && successfullyClonedPartUuid === partUuid) {
      dispatch(onFetchSection());
      dispatch(doClearCreated());
    }
  }, [dispatch, onFetchSection, partUuid, successfullyClonedPartUuid]);

  return (
    <Part
      part={part}
      partIndex={partIndex}
      termDatesDisplay={termDatesDisplay}
      courseUuid={courseUuid}
      sectionUuid={sectionUuid}
      sectionNumber={sectionNumber}
      totalNumberOfInProgressEncounters={totalNumberOfInProgressEncounters}
      totalNumberOfCompleteEncounters={totalNumberOfCompleteEncounters}
      totalNumberOfApprovedEncounters={totalNumberOfApprovedEncounters}
      totalNumberOfInProgressAssessments={totalNumberOfInProgressAssessments}
      totalNumberOfCompleteAssessments={totalNumberOfCompleteAssessments}
      totalNumberOfApprovedAssessments={totalNumberOfApprovedAssessments}
      totalNumberofEncounters={totalNumberofEncounters}
      totalNumberofAssessments={totalNumberofAssessments}
      totalNumberOfPendingEncounters={totalNumberOfPendingEncounters}
      totalNumberOfPendingAssessments={totalNumberOfPendingAssessments}
      handlePartLock={handlePartLock}
    />
  );
}

PartContainer.propTypes = {
  sectionUuid: PropTypes.string,
  partUuid: PropTypes.string,
  courseUuid: PropTypes.string,
  termDatesDisplay: PropTypes.string,
  onFetchSection: PropTypes.func,
  partIndex: PropTypes.number,
  sectionNumber: PropTypes.number
};

PartContainer.defaultProps = {
  sectionUuid: '',
  courseUuid: '',
  partUuid: '',
  termDatesDisplay: '',
  partIndex: 0,
  onFetchSection: undefined,
  sectionNumber: 0
};
