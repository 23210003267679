import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import _, { sortBy } from 'lodash';
import Decimal from 'decimal.js-light';
// import { ButtonInline, Caption } from '@xcomp/xcomp-design-library';
import { Caption } from '@xcomp/xcomp-design-library';
import StepScoreContainer from './StepScoreContainer';
import { ScoreRow, TotalColumn } from '../ScoresSC';
import { decimalTenth, sortScores } from '../../../../helpers/utilities';
// import { visibleToSchoolAdmin } from '../../../../helpers/authorization';

// TODO: uncomment all the code in this file to re-allow adding steps manually. This was
//   commented to remove this feature as the step form does not currently support
//   required block fields - JV 9/10/21

const ScoreColumnHeader = styled(({ width, ...rest }) => <Caption {...rest} />)`
  width: ${props => props.width || 'initial'};
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
`;

// const AddStep = styled(ButtonInline)`
//   && {
//     margin: 0;
//   }
// `;

const Layout = styled.div`
  width: 100%;
  margin-left: 35px;
`;

// const SchoolAdminOnlyAddStep = visibleToSchoolAdmin(props => (
//   <AddStep
//     noHoverBg
//     onClick={() =>
//       props.handleScoreAddModalOpen(
//         props.studentUuid,
//         props.attempt,
//         props.resultCardUuid,
//         props.assessmentBlockUuid
//       )
//     }
//   >
//     Add Step
//   </AddStep>
// ));

export default function StepGroupAttempt({
  assessmentBlockResultStatus,
  assessmentUuid,
  // assessmentBlockUuid,
  assessmentType,
  // studentUuid,
  // resultCardUuid,
  // attempt,
  attemptScores,
  // handleScoreAddModalOpen,
  handleScoreEditModalOpen,
  scoreType
}) {
  const totalRel = _.reduce(
    attemptScores,
    (total, score) => {
      const decimalTotalRel = new Decimal(score.relValue);

      return total.add(decimalTotalRel);
    },
    new Decimal(0)
  ).val();

  const totalPot = _.reduce(
    attemptScores,
    (total, score) => {
      const decimalTotalPot = new Decimal(score.potValue);
      return total.add(decimalTotalPot);
    },

    new Decimal(0)
  ).val();

  const isOpportunity = scoreType === 'Opportunity';

  const sortedScoresByStep = isOpportunity
    ? sortBy(attemptScores, ['procedureCodeOrder', 'stepSubItem'])
    : attemptScores.sort(sortScores);

  const isDidactic =
    assessmentType === 'Applied Didactic' ||
    assessmentType === 'Basic Didactic';

  const hasCriticalFailure =
    scoreType === 'Attempt' &&
    !isDidactic &&
    (assessmentBlockResultStatus === 'fail' ||
      assessmentBlockResultStatus === 'critical');

  // const oppCriticalFailure =
  //   isOpportunity &&
  //   attemptScores.some(score => new Decimal(score.relValue).equals(0));

  return (
    <Layout>
      <ScoreRow>
        <ScoreColumnHeader width="10%">Date</ScoreColumnHeader>
        {isOpportunity && (
          <ScoreColumnHeader width={isOpportunity ? '15%' : '5%'}>
            Procedure Order
          </ScoreColumnHeader>
        )}
        <ScoreColumnHeader width="10%">Rubric Item</ScoreColumnHeader>

        <ScoreColumnHeader width={isOpportunity ? '15%' : '5%'}>
          {isOpportunity ? 'Procedure Descriptor' : 'Sequence'}
        </ScoreColumnHeader>
        {!isOpportunity && (
          <ScoreColumnHeader width={isOpportunity ? '15%' : '5%'}>
            Rubric
          </ScoreColumnHeader>
        )}
        <ScoreColumnHeader width="10%">Step/Item</ScoreColumnHeader>
        <ScoreColumnHeader width="15%">Description</ScoreColumnHeader>
        <ScoreColumnHeader width={isOpportunity ? '36%' : '26%'}>
          Topic
        </ScoreColumnHeader>
        <ScoreColumnHeader width="7%">Rel.</ScoreColumnHeader>
        <ScoreColumnHeader width="12%">Pot.</ScoreColumnHeader>
      </ScoreRow>

      {sortedScoresByStep.map(score => (
        <LazyLoad key={score.uuid}>
          <StepScoreContainer
            assessmentUuid={assessmentUuid}
            isOpportunity={isOpportunity}
            scoreType={scoreType}
            scoreUuid={score.uuid}
            criticalFailure={new Decimal(score.relValue).equals(0)}
            handleScoreEditModalOpen={handleScoreEditModalOpen}
          />
        </LazyLoad>
      ))}
      <ScoreRow>
        <TotalColumn width="85.5%" />
        <TotalColumn criticalFailure={hasCriticalFailure} width="5%" withBorder>
          {hasCriticalFailure ? '0' : decimalTenth(totalRel)}
        </TotalColumn>
        <TotalColumn width="5%" withBorder>
          {decimalTenth(totalPot)}
        </TotalColumn>
        {/* <SpacingBox width="5%" /> */}
      </ScoreRow>
    </Layout>
  );
}

StepGroupAttempt.propTypes = {
  assessmentBlockResultStatus: PropTypes.string,
  // assessmentBlockUuid: PropTypes.string,
  assessmentUuid: PropTypes.string,
  assessmentType: PropTypes.string,
  // studentUuid: PropTypes.string,
  // resultCardUuid: PropTypes.string,
  attemptScores: PropTypes.array,
  // attempt: PropTypes.number,
  // handleScoreAddModalOpen: PropTypes.func,
  handleScoreEditModalOpen: PropTypes.func,
  scoreType: PropTypes.string
};

StepGroupAttempt.defaultProps = {
  assessmentBlockResultStatus: '',
  // assessmentBlockUuid: '',
  assessmentUuid: '',
  assessmentType: '',
  // studentUuid: '',
  // resultCardUuid: '',
  attemptScores: [],
  // attempt: 0,
  // handleScoreAddModalOpen: undefined,
  handleScoreEditModalOpen: undefined,
  scoreType: ''
};
