import {
  DELETE_PART_LEGACY_GRADES,
  DELETE_PART_LEGACY_GRADES_SUCCESS,
  DELETE_PART_LEGACY_GRADES_ERROR,
  POST_UPLOAD_PART_LEGACY_GRADES_SUCCESS,
  POST_UPLOAD_PART_LEGACY_GRADES_ERROR,
  POST_UPLOAD_PART_LEGACY_GRADES,
  RESET_UPLOAD_PART_LEGACY_GRADES,
  POST_UPLOAD_SECTION_LEGACY_GRADES,
  POST_UPLOAD_SECTION_LEGACY_GRADES_SUCCESS,
  POST_UPLOAD_SECTION_LEGACY_GRADES_ERROR,
  RESET_UPLOAD_SECTION_LEGACY_GRADES,
  DELETE_SECTION_LEGACY_GRADES,
  DELETE_SECTION_LEGACY_GRADES_SUCCESS,
  DELETE_SECTION_LEGACY_GRADES_ERROR
} from '../actions-type';

const doUploadPartLegacyGrades = (partUuid, records, uploadDate) => ({
  type: POST_UPLOAD_PART_LEGACY_GRADES,
  payload: {
    partUuid,
    records,
    uploadDate
  }
});

const doUploadPartLegacyGradesSuccess = result => ({
  type: POST_UPLOAD_PART_LEGACY_GRADES_SUCCESS,
  payload: result
});

const doUploadPartLegacyGradesError = error => ({
  type: POST_UPLOAD_PART_LEGACY_GRADES_ERROR,
  payload: error
});

const doUploadPartLegacyGradesReset = () => ({
  type: RESET_UPLOAD_PART_LEGACY_GRADES
});

const doDeletePartLegacyGrades = partUuid => ({
  type: DELETE_PART_LEGACY_GRADES,
  payload: {
    partUuid
  }
});

const doDeletePartLegacyGradesSuccess = result => ({
  type: DELETE_PART_LEGACY_GRADES_SUCCESS,
  payload: result
});

const doDeletePartLegacyGradesError = error => ({
  type: DELETE_PART_LEGACY_GRADES_ERROR,
  payload: error
});

const doUploadSectionLegacyGrades = (sectionUuid, records, uploadDate) => ({
  type: POST_UPLOAD_SECTION_LEGACY_GRADES,
  payload: {
    sectionUuid,
    records,
    uploadDate
  }
});

const doUploadSectionLegacyGradesSuccess = result => ({
  type: POST_UPLOAD_SECTION_LEGACY_GRADES_SUCCESS,
  payload: result
});

const doUploadSectionLegacyGradesError = error => ({
  type: POST_UPLOAD_SECTION_LEGACY_GRADES_ERROR,
  payload: error
});

const doUploadSectionLegacyGradesReset = () => ({
  type: RESET_UPLOAD_SECTION_LEGACY_GRADES
});

const doDeleteSectionLegacyGrades = sectionUuid => ({
  type: DELETE_SECTION_LEGACY_GRADES,
  payload: {
    sectionUuid
  }
});

const doDeleteSectionLegacyGradesSuccess = result => ({
  type: DELETE_SECTION_LEGACY_GRADES_SUCCESS,
  payload: result
});

const doDeleteSectionLegacyGradesError = error => ({
  type: DELETE_SECTION_LEGACY_GRADES_ERROR,
  payload: error
});

export {
  doUploadPartLegacyGrades,
  doUploadPartLegacyGradesSuccess,
  doUploadPartLegacyGradesError,
  doUploadPartLegacyGradesReset,
  doDeletePartLegacyGrades,
  doDeletePartLegacyGradesSuccess,
  doDeletePartLegacyGradesError,
  doUploadSectionLegacyGrades,
  doUploadSectionLegacyGradesSuccess,
  doUploadSectionLegacyGradesError,
  doUploadSectionLegacyGradesReset,
  doDeleteSectionLegacyGrades,
  doDeleteSectionLegacyGradesSuccess,
  doDeleteSectionLegacyGradesError
};
