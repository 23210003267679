import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Cancel from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid';

const Warning = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.status.error};
  border-radius: 5px;
  color: ${props => props.theme.colors.white};
  display: flex;
  margin-top: 15px;
  padding: 15px;
`;

const CancelSC = styled(Cancel)`
  color: ${props => props.theme.colors.status.error};
`;

const Message = styled.div`
  color: ${props => props.theme.colors.white};
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  padding-left: 10px;
`;

const Existing = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
`;

const Name = styled.div`
  font-size: 14px;
`;

const Id = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
`;

const ExistingMessage = styled.div`
  color: ${props => props.theme.colors.status.error};
  font-size: 14px;
  letter-spacing: 0.21px;
  line-height: 16px;
  text-align: right;
`;

const List = styled.div`
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
`;

const Duplicate = styled.div`
  padding-left: 10px;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
`;

const InstitutionId = styled.div`
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
`;

const MicroCode = styled.div`
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
`;

const MessageSystemError = styled.div`
  margin-top: 10px;
  padding-left: 10px;
  font-size: 14px;
`;

export default function UploadErrors({ uploadState }) {
  if (uploadState.invalid) {
    if (uploadState.type === 'headers') {
      return (
        <Warning>
          <CancelSC />
          <Message>{uploadState.message}</Message>
        </Warning>
      );
    } else if (uploadState.type === 'departmentCheck') {
      return (
        <div>
          <Warning>
            <CancelSC />
            <Message>{uploadState.message}</Message>
          </Warning>
        </div>
      );
    } else if (uploadState.type === 'duplicates') {
      return (
        <div>
          <Warning>
            <CancelSC />
            <Message>{uploadState.message}</Message>
          </Warning>
          <List>
            {uploadState.duplicates.map(value => (
              <Duplicate key={uuidv4()}>{value}</Duplicate>
            ))}
          </List>
        </div>
      );
    } else if (uploadState.type === 'restriction') {
      return (
        <div>
          <Warning>
            <CancelSC />
            <Message>{uploadState.message}</Message>
          </Warning>
        </div>
      );
    } else if (uploadState.type === 'uniqueness') {
      return (
        <div>
          <Warning>
            <CancelSC />
            <Message>{uploadState.message}</Message>
          </Warning>
          <List>
            {uploadState.duplicates.map(value => (
              <Existing key={value.institutionId}>
                <Name>
                  {value.institutionId} - {value.firstName} {value.lastName}
                </Name>
                <ExistingMessage>Duplicate</ExistingMessage>
              </Existing>
            ))}
          </List>
        </div>
      );
    } else if (uploadState.type === 'uniqueApplicants') {
      return (
        <div>
          <Warning>
            <CancelSC />
            <Message>{uploadState.message}</Message>
          </Warning>

          <List>
            <Grid spacing={2} container>
              {uploadState.duplicates.map(value => (
                <Fragment key={value.user.institutionId}>
                  <Grid item sm={3} xs={12}>
                    {value.user.firstName} {value.user.lastName}
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    {value.user.institutionId}
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <Id>{value.role}</Id>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <ExistingMessage>Duplicate</ExistingMessage>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </List>
        </div>
      );
    } else if (uploadState.type === 'activeStudentRoleCheck') {
      return (
        <div>
          <Warning>
            <CancelSC />
            <Message>{uploadState.message}</Message>
          </Warning>
          <List>
            {uploadState.failedRecords.map(value => (
              <Duplicate key={value.institutionId}>
                {value.institutionId}
              </Duplicate>
            ))}
          </List>
        </div>
      );
    } else if (uploadState.type === 'insitutionIdCheck') {
      return (
        <div>
          <Warning>
            <CancelSC />
            <Message>{uploadState.message}</Message>
          </Warning>
          <List>
            {uploadState.failedRecords.map((value, index) => (
              <Grid key={value} container spacing={4}>
                <Grid item xs={1}>
                  {index + 1}
                </Grid>
                <Grid item xs={3}>
                  <InstitutionId key={value}>{value}</InstitutionId>
                </Grid>
              </Grid>
            ))}
          </List>
        </div>
      );
    } else if (uploadState.type === 'studentSectionCheck') {
      return (
        <div>
          <Warning>
            <CancelSC />
            <Message>{uploadState.message}</Message>
          </Warning>
          <List>
            {uploadState.failedRecords.map((value, index) => (
              <Grid key={value} container spacing={4}>
                <Grid item xs={1}>
                  {index + 1}
                </Grid>
                <Grid item xs={3}>
                  <InstitutionId key={value}>{value}</InstitutionId>
                </Grid>
              </Grid>
            ))}
          </List>
        </div>
      );
    } else if (uploadState.type === 'graderPartCheck') {
      return (
        <div>
          <Warning>
            <CancelSC />
            <Message>{uploadState.message}</Message>
          </Warning>
          <List>
            {uploadState.failedRecords.map((value, index) => (
              <Grid key={value} container spacing={4}>
                <Grid item xs={1}>
                  {index + 1}
                </Grid>
                <Grid item xs={3}>
                  <InstitutionId key={value}>{value}</InstitutionId>
                </Grid>
              </Grid>
            ))}
          </List>
        </div>
      );
    } else if (uploadState.type === 'microCodeCheck') {
      return (
        <div>
          <Warning>
            <CancelSC />
            <Message>{uploadState.message}</Message>
          </Warning>
          <List>
            {uploadState.failedRecords.map((value, index) => (
              <Grid key={value} container spacing={4}>
                <Grid item xs={1}>
                  {index + 1}
                </Grid>
                <Grid item xs={6}>
                  <MicroCode key={value}>{value}</MicroCode>
                </Grid>
              </Grid>
            ))}
          </List>
        </div>
      );
    } else if (uploadState.type === 'valueCheck') {
      return (
        <div>
          <Warning>
            <CancelSC />
            <Message>{uploadState.message}</Message>
          </Warning>
          <List>
            {uploadState.failedRecords.map((value, index) => (
              <Grid key={value} container spacing={4}>
                <Grid item xs={1}>
                  {index + 1}
                </Grid>
                <Grid item xs={6}>
                  <MicroCode key={value}>{value}</MicroCode>
                </Grid>
              </Grid>
            ))}
          </List>
        </div>
      );
    } else if (uploadState.type === 'general') {
      return (
        <div>
          <Warning>
            <CancelSC />
            <Message>{uploadState.uploadError}</Message>
          </Warning>
        </div>
      );
    } else {
      const error_server = _.get(uploadState, 'error_server');

      return (
        <div>
          <Warning>
            <div>
              <Message>Mass upload failed</Message>
              {error_server && (
                <MessageSystemError>{error_server}</MessageSystemError>
              )}
            </div>
          </Warning>
        </div>
      );
    }
  } else {
    return true;
  }
}

UploadErrors.propTypes = {
  uploadState: PropTypes.object
};

UploadErrors.defaultProps = {
  uploadState: {}
};
