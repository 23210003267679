import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Chip from '@material-ui/core/Chip';

import { selectAptitudeWeight } from '../../../../../../redux/selectors/classificationSelectors';

export default function AptitudeWeight({ programUuid, category }) {
  const weight = useSelector(
    state => selectAptitudeWeight(state, programUuid, category),
    _.isEqual
  );

  return <Chip size="small" label={`${weight}%`} />;
}

AptitudeWeight.propTypes = {
  category: PropTypes.string,
  programUuid: PropTypes.string
};

AptitudeWeight.defaultProps = {
  category: undefined,
  programUuid: undefined
};
