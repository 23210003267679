import React from 'react';
import PropTypes from 'prop-types';
import { ContainerResize, ContainerPage } from '../../Library/Layout';
import Header from '../../Library/Header';
import CompetencyGridFilterContainer from './CompetencyGridFilter/CompetencyGridFilterContainer';
import CompetencyGridSummaryDataContainer from './CompetencyGridSummaryDataContainer';

const CohortCompetencyGrid = ({
  cohortStartDate,
  cohortEndDate,
  onBackAction,
  onGenerateCompetencyReport,
  isLoading,
  setIsLoading,
}) => (
  <ContainerResize>
    <Header
      title="Competency Report"
      subtitle={`Cohort ${cohortStartDate} - ${cohortEndDate}`}
      backButtonOverride={onBackAction}
    />
    <ContainerPage>
      <CompetencyGridFilterContainer
        onGenerateCompetencyReport={onGenerateCompetencyReport}
        isLoading={isLoading}
      />
    </ContainerPage>
    <ContainerPage paddingTop="40px">
      <CompetencyGridSummaryDataContainer
        setIsLoading={setIsLoading}
      />
    </ContainerPage>
  </ContainerResize>
);

CohortCompetencyGrid.propTypes = {
  cohortStartDate: PropTypes.string,
  cohortEndDate: PropTypes.string,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  onBackAction: PropTypes.func,
  onGenerateCompetencyReport: PropTypes.func,
};
CohortCompetencyGrid.defaultProps = {
  cohortStartDate: '',
  cohortEndDate: '',
  isLoading: false,
  setIsLoading: undefined,
  onBackAction: undefined,
  onGenerateCompetencyReport: undefined
};

export default CohortCompetencyGrid;
