import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import EncounterCard from './EncounterCard';
import { selectEncounter } from '../../../../redux/selectors/encountersSelectors';

export default function EncounterCardContainer({
  encounterUuid,
  itemNumber,
  selectSyllabusItem
}) {
  const encounter = useSelector(state => selectEncounter(state, encounterUuid));

  const selectedItem = useSelector(
    state => state.syllabusState.selectedItemUuid
  );

  return (
    <EncounterCard
      encounter={encounter}
      itemNumber={itemNumber}
      selectedItem={selectedItem}
      selectSyllabusItem={selectSyllabusItem}
    />
  );
}

EncounterCardContainer.propTypes = {
  encounterUuid: PropTypes.string,
  itemNumber: PropTypes.number,
  selectSyllabusItem: PropTypes.func
};

EncounterCardContainer.defaultProps = {
  encounterUuid: undefined,
  itemNumber: 0,
  selectSyllabusItem: undefined
};
