import {
  CLEAR_APPLICANT_PHYSICAL_SKILLS_REPORT,
  PUT_APPLICANT_PHYSICAL_SKILLS_REPORT,
  PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_ERROR,
  PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_SUCCESS
} from '../actions-type';

export const INITIAL_STATE = {
  physicalSkillsReport: [],
  loadingPhysicalSkillsReport: false,
  reportHeaders: [],
  reportSummary: {},
  reportSummaryHeaders: [],
  reportType: ''
};

const applicantPhysicalSkillsReportReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_SUCCESS: {
      const {
        physicalSkillsReport,
        reportHeaders,
        reportSummary,
        reportSummaryHeaders,
        reportType
      } = action.payload;
      return {
        ...state,
        physicalSkillsReport,
        loadingPhysicalSkillsReport: false,
        reportHeaders,
        reportSummary,
        reportSummaryHeaders,
        reportType
      };
    }
    case PUT_APPLICANT_PHYSICAL_SKILLS_REPORT:
      return { ...state, loadingPhysicalSkillsReport: true };
    case PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_ERROR:
      return { ...state, loadingPhysicalSkillsReport: false };
    case CLEAR_APPLICANT_PHYSICAL_SKILLS_REPORT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default applicantPhysicalSkillsReportReducer;
