import React, {useCallback, useMemo} from 'react';
import {Box, makeStyles, Paper} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import CellExpand from '../../../CohortManagement/CohortStudentTranscripts/QuantitativeSkills/components/CellExpand';
import {useScoresForItemAnalysis} from '../../../../helpers/hooks/useScoresForItemAnalysis';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 60vh;
  align-items: center;
  font-size: 80px;
`;

const useRowStyles = makeStyles(theme => ({
  tableCell: {
    fontWeight: 'normal',
    fontSize: '0.85rem',
  },
  font: {
    fontWeight: 'bolder',
  },
  canSort: {
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  name: {
    width: '70%',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey['100'],
    },
  },
  selfAssessmentSignificantOvergrade: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
  },
  selfAssessmentEquivocal: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
  selfAssessmentPerfectAgreement: {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.error.contrastText,
  },
  selfAssessmentAgreement: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  tableRowColorSuccess: {
    color: theme.palette.success.dark,
  },
  tableRowColorError: {
    color: theme.palette.error.dark,
  },
  actionBorder: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
}));

const ItemAnalysis = ({
    assessmentUuid,
  }) => {
    const classes = useRowStyles();
    const [expanded, setExpanded] = React.useState({});
    const loading = useSelector(state => state.crudState.readScores);
    const scoresData = useScoresForItemAnalysis({assessmentUuid});
    const columnHelper = createColumnHelper();

    const getClassName = useCallback((color) => {
      let className = {};
      let selfAssessmentClassName = '';

      switch (color) {
        case 'green':
          className = classes.tableRowColorSuccess;
          break;
        case 'red':
          className = classes.tableRowColorError;
          break;
        case 'dark-red':
          selfAssessmentClassName = classes.selfAssessmentSignificantOvergrade;
          break;
        case 'light-red':
          selfAssessmentClassName = classes.selfAssessmentEquivocal;
          break;
        case 'dark-green':
          selfAssessmentClassName = classes.selfAssessmentPerfectAgreement;
          break;
        case 'light-green':
          selfAssessmentClassName = classes.selfAssessmentAgreement;
          break;
        default:
          break;
      }

      return {
        className,
        selfAssessmentClassName,
      };
    }, [
      classes.selfAssessmentAgreement,
      classes.selfAssessmentEquivocal,
      classes.selfAssessmentPerfectAgreement,
      classes.selfAssessmentSignificantOvergrade,
      classes.tableRowColorError,
      classes.tableRowColorSuccess,
    ]);

    const columns = useMemo(
      () => [
        columnHelper.display({
          id: 'action',
          cell: ({row}) => (
            <CellExpand row={row} />
          ),
        }),
        columnHelper.accessor(
          (row) => row?.name, {
            id: 'name',
            header: 'Rubric/Step',
          }),
        columnHelper.accessor(
          (row) => row?.attempt, {
            id: 'attempt',
            header: 'Attempt',
          }),
        columnHelper.accessor(
          (row) => row?.relValue, {
            id: 'total-rel',
            header: 'Total Relative',
          }),
        columnHelper.accessor(
          (row) => row?.potValue, {
            id: 'total-pot',
            header: 'Total Potential',
          }),
        columnHelper.accessor(
          (row) => row?.score, {
            id: 'score',
            header: 'Score(%)',
          }),
      ],
      [columnHelper],
    );

    const table = useReactTable({
      data: scoresData,
      columns,
      state: {
        expanded,
      },
      onExpandedChange: setExpanded,
      getSubRows: row => row.subRows,
      getCoreRowModel: getCoreRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
    });

    if (loading) {
      return (
        <LoadingContainer>
          <CircularProgress size={100} />
        </LoadingContainer>
      );
    }

    return (
      <Paper>
        <Table size="small">
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableCell
                      align="left"
                      className={`${classes.tableCell} ${header.id === 'name' ? classes.name : ''}`}
                      key={header.id}
                    >
                      {header.isPlaceholder ? null : (
                        <Box>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </Box>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map(row => {
              const {className, selfAssessmentClassName} = getClassName(row?.original?.color);

              return (
                <TableRow
                  className={`${classes.tableRow} ${selfAssessmentClassName}`}
                  key={row.id}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <TableCell
                        style={{
                          paddingLeft: cell.column.id === 'name' ? `${row.depth * 2 + 1}rem` : '',
                        }}
                        className={`${classes.tableCell} ${className} ${classes.actionBorder} ${selfAssessmentClassName}`}
                        align="left"
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
;

ItemAnalysis.propTypes = {
  assessmentUuid: PropTypes.string,
};

ItemAnalysis.defaultProps = {
  assessmentUuid: '',
};
export default ItemAnalysis;