import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Chip } from '@material-ui/core';
import useSWRMutation from 'swr/mutation';
import { useDispatch } from 'react-redux';

import AccordionRowContainer from '../../AccordionRow/AccordionRowContainer';
import StepGroupAttempt from './StepGroupAttempt';
import { fetcher } from '../../../../helpers/utilities';
import ChangeAttemptStatusModal, { assessmentBlockResultStatusMap } from '../../Modal/ChangeAttemptStatusModal/ChangeAttemptStatusModal';
import { doPutAssessmentBlockResultStatusError, doPutAssessmentBlockResultStatusSuccess } from '../../../../redux/actions/scoreActions';

const StepPanelAccordion = styled(
  ({
    withBottomBorder,
    withTopBorder,
    criticalFailure,
    blockResultStatus,
    ...rest
  }) => <AccordionRowContainer {...rest} />
)`
  margin-left: 1rem;
  width: calc(100% - 1rem);

  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    white-space: nowrap;
  }

  .accordion_row_label div {
    font-size: 14px !important;
    font-weight: 500;
    color: ${props => props.theme.fontColors.darker};
  }

  .line {
    left: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }

  .block_result_status {
    .MuiChip-root {
      background-color: ${props =>
        (props.blockResultStatus === 'pass' && props.theme.colors.status.success) ||
        (props.blockResultStatus === 'disqualified' && props.theme.colors.status.warning) ||
        (props.blockResultStatus === 'pending' && props.theme.colors.status.warning) ||
        props.theme.colors.status.error};
      color: ${props => props.theme.colors.white};
      min-width: 10rem;
      font-size: 1rem;
      margin-right: 1rem;
    }
  }
`;

export default function StepBlockResultPanel({
  assessmentBlockResult,
  assessmentBlockUuid,
  assessmentType,
  assessmentUuid,
  handleScoreAddModalOpen,
  handleScoreEditModalOpen,
  handleDeleteStudentScoresModalOpen,
  scores,
  scoreType,
  studentUuid,
  isAdmin,
  isMutating
}) {
  const [openStatusUpdateModal, setOpenStatusUpdateModal] = useState(false);
  const dispatch = useDispatch();

  const { trigger, data, error, isMutating: isUpdatingStatus } = useSWRMutation(
    `/api/scores/update-attempt-status?`,
    fetcher,
    {
      throwOnError: false,
      revalidateOnFocus: false,
    }
  );

  const handleUpdateStatus = (newStatus) => {
    trigger({
      queryParameters: `assessmentBlockResultUuid=${assessmentBlockResult.uuid}`, 
      options: {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({newStatus}),
        credentials: 'include'
      }
    })
    setOpenStatusUpdateModal(false);
  };

  useEffect(() =>
    {
      if (data) {
        dispatch(doPutAssessmentBlockResultStatusSuccess(data));
      }
    },
    // eslint-disable-next-line
    [data]
  )

  useEffect(() => 
    {
      if (error) {
        dispatch(doPutAssessmentBlockResultStatusError({...error.info}));
      }
    },
    // eslint-disable-next-line
    [error]
  )


  const labelText =
    scores.length > 0
      ? `${scoreType.toUpperCase()} ${assessmentBlockResult.blockAttempt} - ${
          scores[0].resultCardUuid
        }`
      : `${scoreType.toUpperCase()} ${assessmentBlockResult.blockAttempt}`;

  return (
    <>
      <StepPanelAccordion
        height="50px"
        label={labelText}
        loading={isMutating || isUpdatingStatus}
        actions={isAdmin 
          ?
            [
              {
                label: `Delete ${scoreType === 'Opportunity' ? 'Opportunity' : 'Attempt'} scores`,
                onClick: () => handleDeleteStudentScoresModalOpen(`&assessmentBlockUuid=${assessmentBlockUuid}&${scoreType === 'Opportunity' ? 'opportunityNumber' : 'attemptNumber'}=${assessmentBlockResult.blockAttempt}`),
                shouldOpenOnClick: false,
                icon: 'delete'
              },
              {
                label: `Update ${scoreType === 'Opportunity' ? 'Opportunity' : 'Attempt'} status`,
                onClick: () => setOpenStatusUpdateModal(true),
                shouldOpenOnClick: false,
                icon: 'edit'
              },
            ]
          : 
            false
        }
        columnOne={scoreType === 'Opportunity' ? null : <div />}
        columnOneClassName="line"
        columnTwo={
          scoreType === 'Opportunity' ? null : (
            <Chip
              label={assessmentBlockResultStatusMap[assessmentBlockResult.status]}
            />
          )
        }
        columnTwoClassName="block_result_status"
        blockResultStatus={assessmentBlockResult.status}
      >
        <StepGroupAttempt
          assessmentBlockUuid={assessmentBlockUuid}
          assessmentUuid={assessmentUuid}
          assessmentType={assessmentType}
          resultCardUuid={scores[0]?.resultCardUuid}
          studentUuid={studentUuid}
          attempt={assessmentBlockResult.blockAttempt}
          attemptScores={scores}
          handleScoreAddModalOpen={handleScoreAddModalOpen}
          handleScoreEditModalOpen={handleScoreEditModalOpen}
          scoreType={scoreType}
          assessmentBlockResultStatus={assessmentBlockResult.status}
        />
      </StepPanelAccordion>
      {openStatusUpdateModal &&
        <ChangeAttemptStatusModal
          handleUpdate={handleUpdateStatus}
          handleModalClose={() => setOpenStatusUpdateModal(null)}
          modalOpen={openStatusUpdateModal}
          currentStatus={assessmentBlockResult.status}
          isLoading={isUpdatingStatus}
        />
      }
    </>
  );
}

StepBlockResultPanel.propTypes = {
  assessmentBlockResult: PropTypes.object,
  assessmentBlockUuid: PropTypes.string,
  assessmentType: PropTypes.string,
  assessmentUuid: PropTypes.string,
  handleScoreAddModalOpen: PropTypes.func,
  handleScoreEditModalOpen: PropTypes.func,
  handleDeleteStudentScoresModalOpen: PropTypes.func,
  scores: PropTypes.array,
  scoreType: PropTypes.string,
  studentUuid: PropTypes.string,
  isAdmin: PropTypes.bool,
  isMutating: PropTypes.bool,
};

StepBlockResultPanel.defaultProps = {
  assessmentBlockResult: {},
  assessmentBlockUuid: '',
  assessmentType: '',
  assessmentUuid: '',
  handleScoreAddModalOpen: undefined,
  handleScoreEditModalOpen: undefined,
  handleDeleteStudentScoresModalOpen: undefined,
  scores: [],
  scoreType: '',
  studentUuid: '',
  isAdmin: false,
  isMutating: false,
};
