import { Model, attr } from 'redux-orm';
import {
  POST_DEGREE_LEVEL_INDEX_SUCCESS,
  PUT_DEGREE_LEVEL_INDEX_SUCCESS,
  DELETE_DEGREE_LEVEL_INDEX_SUCCESS,
  GET_DEGREE_LEVEL_INDICES_SUCCESS
} from '../actions-type';

class DegreeLevelIndex extends Model {
  static reducer(action, DegreeLevelIndex, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_DEGREE_LEVEL_INDICES_SUCCESS: {
        const degreeLevelIndices = [...payload.degreeLevelIndices];
        degreeLevelIndices.forEach(index => DegreeLevelIndex.upsert(index));
        break;
      }

      case POST_DEGREE_LEVEL_INDEX_SUCCESS: {
        const degreeLevelIndex = { ...payload.degreeLevelIndex };
        DegreeLevelIndex.upsert(degreeLevelIndex);
        break;
      }
      case PUT_DEGREE_LEVEL_INDEX_SUCCESS: {
        const degreeLevelIndex = { ...payload.degreeLevelIndex };
        DegreeLevelIndex.upsert(degreeLevelIndex);
        break;
      }
      case DELETE_DEGREE_LEVEL_INDEX_SUCCESS: {
        const { degreeLevelIndexUuid } = payload;

        DegreeLevelIndex.withId(degreeLevelIndexUuid).delete();
        break;
      }
      default:
        return session;
    }
  }
}

DegreeLevelIndex.fields = {
  uuid: attr(),
  programUuid: attr(),
  sourceDegreeLevelUuid: attr(),
  percentage: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

DegreeLevelIndex.modelName = 'DegreeLevelIndex';
DegreeLevelIndex.options = {
  idAttribute: 'uuid'
};

export default DegreeLevelIndex;
