import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import Assessment from './Assessment';
import { selectAssessment } from '../../../redux/selectors/assessmentSelectors';
import { doGetAssessmentGrades } from '../../../redux/actions/assessmentGradeActions';
// import { userSelector } from '../../../redux/selectors/usersSelectors';
import { doGetLetterGradesBySchool } from '../../../redux/actions/letterGradeActions';
import { programSelector } from '../../../redux/selectors/programSelectors';
import { useQuery } from '../../../helpers/hooks/router.hooks';

export default function AssessmentContainer() {
  const query = useQuery();
  const userUuid = query.get('userUuid');
  const dispatch = useDispatch();
  const programUuid = useSelector(state => state.userState.selectedProgramUuid);

  const program = useSelector(state => programSelector(state, programUuid));
  const selectedItem = useSelector(
    state => state.syllabusState.selectedItemUuid,
    _.isEqual
  );

  const assessment = useSelector(
    state => selectAssessment(state, selectedItem),
    _.isEqual
  );

  const selectedOrder = useSelector(state => state.syllabusState.selectedOrder);

  useEffect(() => {
    if (program.schoolUuid) {
      dispatch(doGetLetterGradesBySchool(program.schoolUuid));
    }
  }, [dispatch, program.schoolUuid]);

  useEffect(() => {
    if (assessment?.uuid && userUuid) {
      dispatch(doGetAssessmentGrades(assessment?.uuid, userUuid));
    }
  }, [assessment?.uuid, dispatch, userUuid]);

  return (
    <Assessment
      userUuid={userUuid}
      assessment={assessment}
      selectedOrder={selectedOrder}
    />
  );
}

AssessmentContainer.propTypes = {};

AssessmentContainer.defaultProps = {};
