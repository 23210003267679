import {
  GET_EDUCATION_LEVEL,
  GET_EDUCATION_LEVEL_SUCCESS,
  GET_EDUCATION_LEVEL_ERROR,
  GET_EDUCATION_LEVELS_BY_SCHOOL,
  GET_EDUCATION_LEVELS_BY_SCHOOL_SUCCESS,
  GET_EDUCATION_LEVELS_BY_SCHOOL_ERROR,
  POST_EDUCATION_LEVEL,
  POST_EDUCATION_LEVEL_SUCCESS,
  POST_EDUCATION_LEVEL_ERROR,
  PUT_EDUCATION_LEVEL,
  PUT_EDUCATION_LEVEL_SUCCESS,
  PUT_EDUCATION_LEVEL_ERROR,
  DELETE_EDUCATION_LEVEL,
  DELETE_EDUCATION_LEVEL_SUCCESS,
  DELETE_EDUCATION_LEVEL_ERROR
} from '../actions-type';

const doGetEducationLevel = educationLevelUuid => ({
  type: GET_EDUCATION_LEVEL,
  payload: {
    educationLevelUuid
  }
});

const doGetEducationLevelSuccess = result => ({
  type: GET_EDUCATION_LEVEL_SUCCESS,
  payload: result
});

const doGetEducationLevelError = error => ({
  type: GET_EDUCATION_LEVEL_ERROR,
  payload: error
});

const doGetEducationLevelsBySchool = schoolUuid => ({
  type: GET_EDUCATION_LEVELS_BY_SCHOOL,
  payload: {
    schoolUuid
  }
});

const doGetEducationLevelsBySchoolSuccess = result => ({
  type: GET_EDUCATION_LEVELS_BY_SCHOOL_SUCCESS,
  payload: result
});

const doGetEducationLevelsBySchoolError = error => ({
  type: GET_EDUCATION_LEVELS_BY_SCHOOL_ERROR,
  payload: error
});

const doPostEducationLevel = educationLevel => ({
  type: POST_EDUCATION_LEVEL,
  payload: {
    educationLevel
  }
});

const doPostEducationLevelSuccess = result => ({
  type: POST_EDUCATION_LEVEL_SUCCESS,
  payload: result
});

const doPostEducationLevelError = error => ({
  type: POST_EDUCATION_LEVEL_ERROR,
  payload: error
});

const doPutEducationLevel = educationLevel => ({
  type: PUT_EDUCATION_LEVEL,
  payload: {
    educationLevel
  }
});

const doPutEducationLevelSuccess = result => ({
  type: PUT_EDUCATION_LEVEL_SUCCESS,
  payload: result
});

const doPutEducationLevelError = error => ({
  type: PUT_EDUCATION_LEVEL_ERROR,
  payload: error
});

const doDeleteEducationLevel = educationLevelUuid => ({
  type: DELETE_EDUCATION_LEVEL,
  payload: {
    educationLevelUuid
  }
});

const doDeleteEducationLevelSuccess = result => ({
  type: DELETE_EDUCATION_LEVEL_SUCCESS,
  payload: result
});

const doDeleteEducationLevelError = error => ({
  type: DELETE_EDUCATION_LEVEL_ERROR,
  payload: error
});

export {
  doGetEducationLevel,
  doGetEducationLevelSuccess,
  doGetEducationLevelError,
  doGetEducationLevelsBySchool,
  doGetEducationLevelsBySchoolSuccess,
  doGetEducationLevelsBySchoolError,
  doPostEducationLevel,
  doPostEducationLevelSuccess,
  doPostEducationLevelError,
  doPutEducationLevel,
  doPutEducationLevelSuccess,
  doPutEducationLevelError,
  doDeleteEducationLevel,
  doDeleteEducationLevelSuccess,
  doDeleteEducationLevelError
};
