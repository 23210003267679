import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  Body1,
  Caption,
  Heading3,
  ButtonOutline
} from '@xcomp/xcomp-design-library';
import { GearIcon, UsersIcon } from '@xcomp/xcomp-design-library/dist/icons';

import Tooltip from '@material-ui/core/Tooltip';

import Overline from '../../../Library/Overline';
import LinkOutline from '../../../Library/LinkOutline';

import { visibleToSchoolAdmin } from '../../../../helpers/authorization';
import {
  gradeCalculationDataSourceMap,
  partStatusCommitMap,
  partStatusGradeMap
} from '../../../../helpers/constants';
import PartFaculty from './PartFaculty';
import { StatusChip, CompleteStatusChip } from '../../../Library/StatusChipsSC';
import ClonePartModal from './ClonePartModal';
import { doGeneralSuccessNotification } from '../../../../redux/actions/notificationActions';

const Layout = styled(({ isPublished, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  border-left: 6px solid
    ${props =>
      props.isPublished
        ? props.theme.colors.status.success
        : props.theme.colors.grey[500]};
  background-color: ${props => props.theme.colors.grey[100]};
  margin-bottom: 72px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const PartLeft = styled.div`
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  padding: 24px;
`;

const PartRight = styled.div`
  display: flex;
  position: relative;
  width: 50%;
  flex-wrap: wrap;
  padding: 24px;
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
`;

const DetailsSection = styled.div`
  width: 100%;
`;

const SyllabusStatusSection = styled.div`
  width: 100%;
  margin: 32px 0 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
`;

const TermDates = styled(Caption)`
  margin: 0;
`;

const StatusColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const PartLabel = styled(Caption)`
  margin-top: 24px;
  margin-bottom: 0;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }
`;

const PartValue = styled(Body1)`
  width: 100%;
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
`;

const StaffDisplay = styled(Body1)`
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
`;

const Status = styled(Overline)`
  margin: 0;
  line-height: 1.6;
  font-size: 0.75rem;
  font-weight: 400;
`;

const StatusTypeHeader = styled(Overline)`
  margin: 0 0 12px;
  line-height: 1.6;
  font-size: 0.75rem;
  font-weight: 400;
`;

const PartFieldDisplay = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
`;

const PartActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: auto;

  button {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ActionButton = styled(ButtonOutline)`
  && {
    border: 1px solid ${props => props.theme.colors.primary};
    transition: background 0.4s ease-in, color 0.4s ease-in;
    width: 100%;
    &:hover {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};
    }
  }
`;

const Title = styled(Heading3)`
  margin-top: 24px;
  margin-bottom: 12px;
`;

// const StatusChip = styled(({ isComplete, ...rest }) => <Overline {...rest} />)`
//   display: flex;
//   width: 175px;
//   flex-wrap: nowrap;
//   background: ${props =>
//     props.isComplete ? props.theme.colors.status.warning : props.theme.colors.grey[400]};
//   color: ${props => props.theme.fontColors.darker};
//   padding: 3px 0;
//   margin-bottom: 12px;
//   font-size: 12px;
//   font-weight: 500;
//   border-radius: 16px;
//   aling-items: center;
//   justify-content: center;

//   &:last-child {
//     margin-bottom: 0;
//   }
// `;

const StatusButton = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;

  a {
    margin-left: 16px;
    svg {
      color: ${props => props.theme.colors.grey[600]};

      &:hover {
        color: ${props => props.theme.colors.grey[900]};
      }
    }
  }
`;

const PartUuidWithCopy = styled.div`
  position: relative;
`;

const PartUuidDisplay = styled(Body1)`
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
  display: inline;
`;

const SchoolAdminOnlyPartStatus = visibleToSchoolAdmin(
  ({ part, sectionUuid, sectionNumber }) => (
    <StatusButton>
      <Tooltip title="View Part Groups" placement="top">
        <Link
          to={`/syllabus-management/section/${sectionUuid}/${sectionNumber}/part/${part.uuid}/${part.partNumber}/student-roster`}
        >
          <UsersIcon />
        </Link>
      </Tooltip>
      <Tooltip title="Edit Part Status" placement="top">
        <Link to={`/syllabus-management/part/${part.uuid}/status-settings`}>
          <GearIcon />
        </Link>
      </Tooltip>
    </StatusButton>
  )
);

const getPartStatusString = status =>
  status === 'Committed' ? 'Approved' : status;

export default function Part({
  part,
  partIndex,
  termDatesDisplay,
  courseUuid,
  sectionNumber,
  sectionUuid,
  totalNumberOfInProgressEncounters,
  totalNumberOfCompleteEncounters,
  totalNumberOfApprovedEncounters,
  totalNumberOfInProgressAssessments,
  totalNumberOfCompleteAssessments,
  totalNumberOfApprovedAssessments,
  totalNumberofEncounters,
  totalNumberofAssessments
}) {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const {
    part_directors_assigned,
    part_faculty_assigned,
    part_grade_settings,
    status,
    title,
    uuid,
    completionPercentage,
    weight,
    allDepartmentFaculty
  } = part;

  const selectText = event => {
    const thisNode = event.target;

    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(thisNode);
      range.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(thisNode);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      console.warn('Could not select text in node: Unsupported browser.');
    }

    if (navigator?.clipboard) {
      navigator.clipboard.writeText(uuid);

      dispatch(doGeneralSuccessNotification('copied partUuid to clipboard'));
    }
  };

  const listOfDirectors = part_directors_assigned
    ? part_directors_assigned.map(
        director => `${director.firstName} ${director.lastName}`
      )
    : [];

  const pathSyllabusEditor = `/syllabus-management/editor/part/${uuid}&courseUuid=${courseUuid}&sectionUuid=${sectionUuid}&partNumber=${partIndex}&sectionNumber=${sectionNumber}`;
  const pathSyllabus = `/syllabus-management/view/part/${uuid}&courseUuid=${courseUuid}&sectionUuid=${sectionUuid}&partNumber=${partIndex}&sectionNumber=${sectionNumber}`;
  const pathGradeCalculation = `/syllabus-management/part/${uuid}/part-grade-settings&courseUuid=${courseUuid}&sectionUuid=${sectionUuid}&partNumber=${partIndex}&sectionNumber=${sectionNumber}`;
  const partNumber = `Part ${partIndex.toString().padStart(2, '0')}`;
  const pathPartGradeSummary = `/syllabus-management/part/${uuid}/section/${sectionUuid}/grade-summary/assessment-collection`;

  const partGradeDataSource = part_grade_settings?.dataSource
    ? gradeCalculationDataSourceMap[part_grade_settings.dataSource]
    : 'Not Set';

  const partCompletionPercentage =
    completionPercentage && part_grade_settings?.dataSource !== 'LEGACY'
      ? `${completionPercentage}%`
      : 'N/A';

  const partTitle = weight ? `${title} - ${weight}%` : title;

  const isPublished = status === 'Published';

  return (
    <Layout isPublished={isPublished}>
      <PartLeft>
        <DetailsSection>
          <Status>
            {partNumber} - {getPartStatusString(status)}
          </Status>
          <TermDates>{termDatesDisplay}</TermDates>
          <Title>{partTitle}</Title>

          {uuid && (
            <PartUuidWithCopy>
              <PartLabel>Part ID</PartLabel>
              <PartUuidDisplay onClick={selectText} id="target">
                {uuid}
              </PartUuidDisplay>
            </PartUuidWithCopy>
          )}

          <PartLabel>Directors</PartLabel>
          <StaffDisplay>
            {listOfDirectors.length > 0
              ? listOfDirectors
              : 'No assigned Director(s)'}
          </StaffDisplay>
          <PartLabel>Faculty</PartLabel>
          <PartFaculty partFaculty={part_faculty_assigned} allDepartmentFaculty={allDepartmentFaculty} />
        </DetailsSection>

        <SyllabusStatusSection>
          <StatusColumn>
            <StatusTypeHeader>Encounters</StatusTypeHeader>
            <StatusChip
              isComplete={
                totalNumberOfInProgressEncounters !== 0 &&
                totalNumberOfInProgressEncounters === totalNumberofEncounters
              }
            >
              {`${totalNumberOfInProgressEncounters} / ${totalNumberofEncounters} In Progress`}
            </StatusChip>
            <StatusChip
              isComplete={
                totalNumberOfCompleteEncounters !== 0 &&
                totalNumberOfCompleteEncounters === totalNumberofEncounters
              }
            >
              {`${totalNumberOfCompleteEncounters} / ${totalNumberofEncounters} Complete`}
            </StatusChip>
            <CompleteStatusChip
              isComplete={
                totalNumberOfApprovedEncounters !== 0 &&
                totalNumberOfApprovedEncounters === totalNumberofEncounters
              }
            >
              {`${totalNumberOfApprovedEncounters} / ${totalNumberofEncounters} Approved`}
            </CompleteStatusChip>
          </StatusColumn>

          <StatusColumn>
            <StatusTypeHeader>Assessments</StatusTypeHeader>
            <StatusChip
              isComplete={
                totalNumberOfInProgressAssessments !== 0 &&
                totalNumberOfInProgressAssessments === totalNumberofAssessments
              }
            >
              {`${totalNumberOfInProgressAssessments} / ${totalNumberofAssessments} In Progress`}
            </StatusChip>
            <StatusChip
              isComplete={
                totalNumberOfCompleteAssessments !== 0 &&
                totalNumberOfCompleteAssessments === totalNumberofAssessments
              }
            >
              {`${totalNumberOfCompleteAssessments} / ${totalNumberofAssessments} Complete`}
            </StatusChip>
            <CompleteStatusChip
              isComplete={
                totalNumberOfApprovedAssessments !== 0 &&
                totalNumberOfApprovedAssessments === totalNumberofAssessments
              }
            >
              {`${totalNumberOfApprovedAssessments} / ${totalNumberofAssessments} Approved`}
            </CompleteStatusChip>
          </StatusColumn>
        </SyllabusStatusSection>
      </PartLeft>

      <PartRight>
        <PartFieldDisplay>
          <PartLabel>Status</PartLabel>
          <PartValue>
            {partStatusGradeMap[part.statusGrade]} /{' '}
            {partStatusCommitMap[part.statusCommit]}
          </PartValue>
          <PartLabel>Grade Calculation</PartLabel>
          <PartValue>{partGradeDataSource}</PartValue>
          <PartLabel>Completion %</PartLabel>
          <PartValue>{partCompletionPercentage}</PartValue>
        </PartFieldDisplay>
        <PartActions>
          {totalNumberofAssessments === 0 && totalNumberofEncounters === 0 && (
            <>
              <ActionButton onClick={handleModalOpen}>
                Clone from Existing Part
              </ActionButton>
              <ClonePartModal
                partUuid={uuid}
                isModalOpen={isModalOpen}
                handleModalClose={handleModalClose}
              />
            </>
          )}
          {part.status === 'In Progress' ? (
            <LinkOutline to={pathSyllabusEditor}>Edit Syllabus</LinkOutline>
          ) : (
            <LinkOutline to={pathSyllabus}>View Syllabus</LinkOutline>
          )}
          <LinkOutline to={pathGradeCalculation}>Grade Calculation</LinkOutline>
          <ActionButton>Curriculum Summary</ActionButton>
          {part.status === 'Committed' || part.status === 'Published' ? (
            <LinkOutline to={pathPartGradeSummary}>
              Performance Summary
            </LinkOutline>
          ) : (
            <Tooltip title="Part must be Locked to access Performance Summary">
              <div>
                <ActionButton disabled>Performance Summary</ActionButton>
              </div>
            </Tooltip>
          )}
        </PartActions>
        <SchoolAdminOnlyPartStatus
          part={part}
          sectionUuid={sectionUuid}
          sectionNumber={sectionNumber}
        />
      </PartRight>
    </Layout>
  );
}

Part.propTypes = {
  part: PropTypes.object,
  partIndex: PropTypes.number,
  sectionNumber: PropTypes.number,
  sectionUuid: PropTypes.string,
  courseUuid: PropTypes.string,
  termDatesDisplay: PropTypes.string,
  totalNumberOfCompleteAssessments: PropTypes.number,
  totalNumberOfApprovedAssessments: PropTypes.number,
  totalNumberOfInProgressAssessments: PropTypes.number,
  totalNumberOfInProgressEncounters: PropTypes.number,
  totalNumberOfCompleteEncounters: PropTypes.number,
  totalNumberOfApprovedEncounters: PropTypes.number,
  totalNumberofEncounters: PropTypes.number,
  totalNumberofAssessments: PropTypes.number
};

Part.defaultProps = {
  part: { part_directors_assigned: [], part_faculty_assigned: [] },
  partIndex: 1,
  sectionNumber: 1,
  sectionUuid: '',
  courseUuid: '',
  termDatesDisplay: '',
  totalNumberOfCompleteAssessments: 0,
  totalNumberOfApprovedAssessments: 0,
  totalNumberOfInProgressAssessments: 0,
  totalNumberOfInProgressEncounters: 0,
  totalNumberOfCompleteEncounters: 0,
  totalNumberOfApprovedEncounters: 0,
  totalNumberofEncounters: 0,
  totalNumberofAssessments: 0
};
