import { Model, attr, fk } from 'redux-orm';
import {
  GET_RIGOR_HISTORICAL_REPORT_SUCCESS,
  POST_INSTITUTION_RIGOR_SUCCESS
} from '../actions-type';

class RigorHistoricalReport extends Model {
  static reducer(action, RigorHistoricalReport, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_RIGOR_HISTORICAL_REPORT_SUCCESS: {
        const rigorHistoricalReport = [...payload.rigorHistoricalReport];
        rigorHistoricalReport.forEach(report =>
          RigorHistoricalReport.upsert(report)
        );
        break;
      }

      case POST_INSTITUTION_RIGOR_SUCCESS: {
        const { rigorHistoricalReport } = payload;
        RigorHistoricalReport.upsert(rigorHistoricalReport);
        break;
      }

      default:
        return session;
    }
  }
}

RigorHistoricalReport.fields = {
  report_type: attr(),
  fields: attr(),
  programUuid: fk('Program', 'historical_report_fields'),
  createdAt: attr(),
  updatedAt: attr()
};

RigorHistoricalReport.modelName = 'RigorHistoricalReport';

RigorHistoricalReport.options = {
  idAttribute: 'uuid'
};

export default RigorHistoricalReport;
