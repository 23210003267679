import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const loverbsSelectorBySchool = createSelector(
  state => state,
  state => state.userState.selectedSchoolUuid,
  (redux, selectedSchoolUuid) => {
    const educationLevelSelectorORM = ormCreateSelector([orm], session => {
      const result = session.EducationLevel.all()
        .toModelArray()
        .filter(
          educationLevel => educationLevel.schoolUuid === selectedSchoolUuid
        )
        .map(educationLevel => {
          return educationLevel.loverbs.toRefArray().map(loverb => loverb);
        });

      const loverbs = _.orderBy(_.flatten(result), ['objectiveVerb']);

      return loverbs;
    });

    const loverbs = educationLevelSelectorORM(redux);

    return loverbs;
  }
);

export const loverbsByEducationLevelSelector = createSelector(
  state => state,
  (_, educationLevelUuid) => educationLevelUuid,
  (redux, educationLevelUuid) => {
    const loverbsSelectorORM = ormCreateSelector(orm.Loverb);
    const loverbs = loverbsSelectorORM(redux).filter(
      loverb => loverb.educationLevelUuid === educationLevelUuid
    );

    const order = _.orderBy(loverbs, ['objectiveVerb'], ['asc']);

    return order;
  }
);

export const loverbSelector = createSelector(
  state => state,
  (_, loverbUuid) => loverbUuid,
  (redux, loverbUuid) => {
    const loverbSelectorORM = ormCreateSelector(orm.Loverb);
    const result = loverbSelectorORM(redux, loverbUuid) || {};

    return result;
  }
);
