import {
  PUT_THIRD_PARTY_ID,
  PUT_THIRD_PARTY_ID_SUCCESS,
  PUT_THIRD_PARTY_ID_ERROR
} from '../actions-type';

const doPutThirdPartyId = (userUuid, programUuid, thirdPartyId) => ({
  type: PUT_THIRD_PARTY_ID,
  payload: {
    userUuid,
    programUuid,
    thirdPartyId
  }
});

const doPutThirdPartyIdSuccess = result => ({
  type: PUT_THIRD_PARTY_ID_SUCCESS,
  payload: result
});

const doPutThirdPartyIdError = error => ({
  type: PUT_THIRD_PARTY_ID_ERROR,
  payload: error
});

export { doPutThirdPartyId, doPutThirdPartyIdSuccess, doPutThirdPartyIdError };
