import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  Button,
  ClickAwayListener,
  Slider,
  Tooltip,
  useMediaQuery
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import Close from '@material-ui/icons/Close';
import ZoomIn from '@material-ui/icons/ZoomIn';
import AllOut from '@material-ui/icons/AllOut';
import Group from '@material-ui/icons/Group';
import Person from '@material-ui/icons/Person';
import Assignment from '@material-ui/icons/Assignment';
import FilterList from '@material-ui/icons/FilterList';
import { SettingsBackupRestore } from '@material-ui/icons';
import { isEqual } from 'lodash';

import styled, { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import FilterDropdown from '../CohortCompetencyGrid/FilterAutocomplete';
import {
  doSetAssessmentGridZoom,
  doSetShowAssessmentGridModal
} from '../../../redux/actions/assessmentGridActions';
import { ASSESSMENT_GRID_MODAL_ID, clickOnFilter } from '../reportUtils';
import DateComponent from '../../Library/Date';
import { validateSelectDate } from '../../../helpers/validation/validateGeneric';
import { usePrevious } from '../../../helpers/hooks/state.hooks';
import DownloadModal from '../../Library/Modal/DownloadModal';

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid lightgray;
  position: sticky;
  top: 0;
  z-index: 9999999999;
  box-shadow: 0 0 8px -2px rgb(128, 128, 128);
  background: ${props => props.theme.colors.white};
  border-radius: 5px 5px 0 0;

  ${props =>
    props.isMobile &&
    `
    & span, div {
      font-size: 13px !important;
    }
  `}
`;

const TableSwitch = styled.div`
  display: flex;
  border: 1px solid rgb(128, 128, 128);
  border-radius: 5px;
  padding: 2px;
  height: 44px;

  ${props => props.marginRight && `margin-right: 10px;`}
`;

const Divider = styled.div`
  border-left: 2px solid rgb(128, 128, 128);
  height: 40px;
  margin: 0 15px;
`;

const Switches = styled.div`
  display: flex;
  gap: 10px;

  & .Mui-focused + .MuiAutocomplete-popper {
    transform: scale(1) !important;
  }
`;

const TableToggle = styled.div`
  padding: 5px;
  cursor: pointer;
  background: ${props =>
    props.selected
      ? props.theme.colors[props.$background || 'primaryShades'][300]
      : props.theme.colors.white};
  ${props => props.selected && `color: ${props.theme.colors.white}`};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.$background && `width: 70px`};

  ${props =>
    !props.selected &&
    `&:hover {
      background: ${props.theme.colors[props.$background || 'primaryShades'][100]};
    }`};

  ${props => props.capitalize && `text-transform: capitalize;`}
`;

const CloseIcon = styled(Close)`
  margin-left: auto;
  margin-right: 5px;
  height: 30px !important;
  width: 30px !important;
  cursor: pointer;
`;

const ZoomSlider = styled(Slider)`
  width: 200px !important;
  margin: 0 15px 15px;

  & .MuiSlider-valueLabel {
    top: 33px;

    & > span {
      border-radius: 50% 0 50% 50%;
    }
  }

  & .MuiSlider-markLabel {
    font-size: 10px;
  }
`;

const TooltipTitle = styled.span`
  font-size: 13px;
`;

const ZoomTooltip = styled(Tooltip)`
  & .MuiTooltip-popper {
    pointer-events: all;
  }
`;

const IconButton = styled(Button)`
  min-width: 40px !important;
  width: 44px;
  height: 44px;
  padding: 1px !important;
  //margin-right: 10px !important;
`;

const DownloadIconButton = styled(Button)`
  min-width: 40px !important;
  width: 44px;
  height: 44px;
  padding: 1px !important;
  background: ${props => props.theme.colors.status.info} !important;
  color: ${props => props.theme.colors.white} !important;
  &:hover {
    filter: brightness(140%);
    background-color: ${props => props.theme.colors.status.info};
  }
`

const ZoomIconButton = styled(IconButton)`
  margin-right: 0 !important;

  & + .MuiTooltip-popper > .MuiTooltip-tooltip {
    background: ${props => props.theme.colors.grey[200]} !important;
    box-shadow: 0 4px 5px -1px rgb(128, 128, 128);
  }
`;

const IconFilterButton = styled(Button)`
  min-width: 0 !important;
  padding: 1px !important;
  transition:
    transform 0.5s ease-in-out,
    width 0.5s ease-in-out,
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transform-origin: top right;
  justify-content: start !important;
`;

const DateTimeSC = styled(DateComponent)`
  margin-top: -16px;
  margin-left: 10px;
  width: 175px;

  & input {
    height: 44px;
  }
`;

const zoomSliderMarks = [
  { value: 80, label: '80%' },
  { value: 85 },
  { value: 90 },
  { value: 95 },
  { value: 100, label: '100%' },
  { value: 105 },
  { value: 110 },
  { value: 115 },
  { value: 120, label: '120%' }
];

const AllOption = {
  fullName: 'All',
  uuid: null
};

export const AnonymousIcon = () => {
  return (
    <span className="MuiButton-label">
      <svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path d="M 12 1 z m 1 17 h -2 v -2 h 2 v 2 z m -1 -3.5 c -2.67 0 -8 1.34 -8 4 v 2 h 16 v -2 c 0 -2.66 -5.33 -4 -8 -4 z m 3.07 -5.25 l -0.9 0.92 c -0.72 0.73 -1.17 1.33 -1.17 2.83 h -2 v -0.5 c 0 -1.1 0.45 -2.1 1.17 -2.83 l 1.24 -1.26 c 0.37 -0.36 0.59 -0.86 0.59 -1.41 c 0 -1.1 -0.9 -2 -2 -2 s -2 0.9 -2 2 H 8 c 0 -2.21 2 -4 4 -4 s 4 1.79 4 4 c 0 0.88 -0.36 1.68 -0.93 2.25 z" />
      </svg>
    </span>
  );
};

const CohortAssessmentGridModalHeader = ({
  isExpanded,
  toggleExpanded,
  students,
  filteredStudents,
  setFilteredStudents,
  isAnonymized,
  assessments,
  assessmentsLabel,
  filteredAssessments,
  setFilteredAssessments,
  setIsAnonymized,
  dataCellValueType,
  setDataCellValueType,
  reportType,
  setReportType,
  startDate,
  endDate,
  selectStartDate,
  selectEndDate,
  cohortStartDate,
  cohortEndDate,
  toggleValues,
  handleClose,
  defaultCollections,
  setDefaultCollectionsChecked,
  defaultCollectionsChecked,
  assessmentPartReport,
  downloadURL,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const zoom = useSelector(state => state.cohortAssessmentReportState.zoom);
  const [openZoom, setOpenZoom] = useState(false);
  const [openStudentFilter, setOpenStudentFilter] = useState(false);
  const [openAssessmentFilter, setOpenAssessmentFilter] = useState(false);
  const [openDownloadScores, setOpenDownloadScores] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const themeContext = useContext(ThemeContext);

  const studentOptions = useMemo(() => {
    const studentsForFilter = students.map(student => {
      const studentInstitutionId = student?.user?.institutionId;

      return {
        fullName: isAnonymized ? studentInstitutionId : student.studentFullName,
        uuid: student.userUuid
      };
    });

    return [AllOption, ...studentsForFilter];
  }, [students, isAnonymized]);

  const assessmentOptions = useMemo(() => {
    return [
      AllOption,
      ...assessments.map(a => ({
        uuid: a.uuid,
        fullName: a.title,
        type: a?.type
      }))
    ];
  }, [assessments]);

  const handleDownloadScoresOpen = () => {
    setOpenDownloadScores(true);
  };

  const handleDownloadScoresClose = () => {
    setOpenDownloadScores(false);
  };
  
  const handleClickClose = useCallback(() => {
    if (handleClose) {
      handleClose();
      return;
    }

    dispatch(doSetShowAssessmentGridModal(false));
    history.push('/reports');
  }, [dispatch, handleClose, history]);

  const prevs = usePrevious(studentOptions);
  const preva = usePrevious(assessmentOptions);

  useEffect(() => {
    const equal = isEqual(prevs, studentOptions);

    if (!equal) {
      setFilteredStudents(studentOptions);
    }
  }, [studentOptions, setFilteredStudents, prevs]);

  useEffect(() => {
    const equal = isEqual(preva, assessmentOptions);

    if (!equal) {
      setFilteredAssessments(assessmentOptions);
    }
  }, [assessmentOptions, preva, setFilteredAssessments, studentOptions]);

  return (
    <ModalHeader isMobile={isMobile}>
      <TableSwitch marginRight>
        {toggleValues.map(type => (
          <TableToggle
            key={type}
            onClick={() => setDataCellValueType(type)}
            selected={type === dataCellValueType}
            capitalize={true}
          >
            {type}
          </TableToggle>
        ))}
      </TableSwitch>
      {setReportType &&      
        <TableSwitch>
          <TableToggle
            key='pass'
            onClick={() => setReportType('pass')}
            selected={reportType === 'pass'}
            capitalize={true}
            $background='green'
          >
            Pass
          </TableToggle>
          <TableToggle
            key='fail'
            onClick={() => setReportType('fail')}
            selected={reportType === 'fail'}
            capitalize={true}
            $background='red'
          >
            Fail
          </TableToggle>
        </TableSwitch>
      }

      <DateTimeSC
        dataTestId="assessment-start-date"
        name="startDate"
        label="Start Date*"
        value={startDate}
        indentifier="startDate"
        handleChange={(indentifier, fieldName, date) =>
          selectStartDate(format(date, 'yyyy-MM-dd'))
        }
        hasErrors={false}
        handleValidate={validateSelectDate}
        minDate={new Date(cohortStartDate)}
        maxDate={new Date(cohortEndDate)}
      />
      <DateTimeSC
        dataTestId="assessment-end-date"
        name="endDate"
        label="End Date*"
        value={endDate}
        indentifier="endDate"
        handleChange={(indentifier, fieldName, date) =>
          selectEndDate(format(date, 'yyyy-MM-dd'))
        }
        hasErrors={false}
        handleValidate={validateSelectDate}
        minDate={new Date(cohortStartDate)}
        maxDate={new Date(cohortEndDate)}
      />

      <Divider />

      <Switches id="modal-header-actions">
        <Tooltip
          title={<TooltipTitle>Filter Students</TooltipTitle>}
          placement="bottom"
        >
          <IconFilterButton
            variant={
              filteredStudents.find(s => s.fullName === 'All')
                ? 'outlined'
                : 'contained'
            }
            color="primary"
            onClick={() => {
              setOpenStudentFilter(true);
              // There are some issues with the MUI portal and transitions.
              // This click after the transition is done will help autofocusing on the portal
              setTimeout(() => clickOnFilter(0), 500);
            }}
            style={{
              // marginRight: openStudentFilter ? 0 : 10,
              width: openStudentFilter ? 0 : 50,
              transform: openStudentFilter ? 'scale(0, 1)' : 'scale(1)'
            }}
          >
            <Group
              style={{
                fontSize: 22,
                position: 'absolute',
                top: 14,
                left: 24,
                color: themeContext.colors.secondary
              }}
              fontSize="large"
            />
            <FilterList fontSize="large" />
          </IconFilterButton>
        </Tooltip>

        <FilterDropdown
          setFilteredValues={setFilteredStudents}
          filteredValues={filteredStudents}
          options={studentOptions}
          open={openStudentFilter}
          onClose={() => setTimeout(() => setOpenStudentFilter(false), 200)}
          label="Students"
        />

        <Tooltip
          title={
            <TooltipTitle>
              {isAnonymized ? 'Identify Students' : 'De-Identify Students'}
            </TooltipTitle>
          }
          placement="bottom"
        >
          <IconButton
            variant={isAnonymized ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setIsAnonymized(!isAnonymized)}
          >
            {isAnonymized ? <AnonymousIcon /> : <Person fontSize="large" />}
          </IconButton>
        </Tooltip>

        <Divider />

        {defaultCollections && (
          <>
            <Tooltip
              title={
                <TooltipTitle>
                  {defaultCollectionsChecked
                    ? 'Show All Collections'
                    : 'Show Default collections'}
                </TooltipTitle>
              }
              placement="bottom"
            >
              <IconButton
                variant={defaultCollectionsChecked ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => {
                  setDefaultCollectionsChecked(!defaultCollectionsChecked);
                  // setFilteredAssessments([]);
                }}
              >
                <SettingsBackupRestore fontSize="large" />
              </IconButton>
            </Tooltip>
            <Divider />
          </>
        )}

        <Tooltip
          title={<TooltipTitle>Filter Assessments</TooltipTitle>}
          placement="bottom"
        >
          <IconFilterButton
            variant={
              filteredAssessments.find(s => s.fullName === 'All')
                ? 'outlined'
                : 'contained'
            }
            color="primary"
            onClick={() => {
              setOpenAssessmentFilter(true);
              // There are some issues with the MUI portal and transitions.
              // This click after the transition is done will help autofocusing on the portal
              setTimeout(() => clickOnFilter(1), 500);
            }}
            style={{
              // marginRight: openAssessmentFilter ? 0 : 10,
              width: openAssessmentFilter ? 0 : 50,
              transform: openAssessmentFilter ? 'scale(0, 1)' : 'scale(1)'
            }}
          >
            <Assignment
              style={{
                fontSize: 20,
                position: 'absolute',
                top: 16,
                left: 26,
                color: themeContext.colors.secondary
              }}
              fontSize="large"
            />
            <FilterList fontSize="large" />
          </IconFilterButton>
        </Tooltip>

        <FilterDropdown
          setFilteredValues={setFilteredAssessments}
          filteredValues={filteredAssessments}
          options={assessmentOptions}
          open={openAssessmentFilter}
          onClose={() => setTimeout(() => setOpenAssessmentFilter(false), 200)}
          label={assessmentsLabel}
          offsetPopper={62}
        />

        {!assessmentPartReport && (
          <Tooltip
            title={
              <TooltipTitle>
                {isExpanded ? 'Collapse Collections' : 'Expand Collections'}
              </TooltipTitle>
            }
            placement="bottom"
          >
            <IconButton
              variant={isExpanded ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => {
                toggleExpanded(!isExpanded);
                // setFilteredAssessments([]);
              }}
            >
              <AllOut fontSize="large" />
            </IconButton>
          </Tooltip>
        )}

        <Divider />

        <ClickAwayListener onClickAway={() => setOpenZoom(false)}>
          <div>
            <ZoomTooltip
              PopperProps={{
                disablePortal: true
              }}
              open={openZoom}
              onClose={() => setOpenZoom(false)}
              interactive
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <ZoomSlider
                  id="zoom-slider"
                  defaultValue={100}
                  value={parseInt(zoom * 100, 10)}
                  step={5}
                  marks={zoomSliderMarks}
                  min={80}
                  max={120}
                  valueLabelDisplay="auto"
                  // onBlur={() => setOpenZoom(false)}
                  onChange={(e, value) => {
                    const modalEl = document.getElementById(
                      ASSESSMENT_GRID_MODAL_ID
                    );
                    if (modalEl) {
                      modalEl.style.zoom = value / 100;
                      dispatch(doSetAssessmentGridZoom(value / 100));
                    }
                  }}
                />
              }
            >
              <ZoomIconButton
                variant={openZoom ? 'contained' : 'outlined'}
                color="primary"
                onClick={() =>
                  setTimeout(
                    () => setOpenZoom(true),
                    openStudentFilter || openAssessmentFilter ? 700 : 0
                  )
                }
              >
                <ZoomIn fontSize="large" />
              </ZoomIconButton>
            </ZoomTooltip>
          </div>
        </ClickAwayListener>

        {downloadURL &&    
          <>
            <Divider />
            <DownloadIconButton
              variant='outlined'
              onClick={handleDownloadScoresOpen}
            >
              <GetAppIcon fontSize="large" />
            </DownloadIconButton>
          </>    
        }
      </Switches>

      {openDownloadScores &&
        <DownloadModal
          modalOpen={openDownloadScores}
          title="Download report"
          description='Are you sure you want to download the report under an Excel file format?'
          handleModalClose={handleDownloadScoresClose}
          downloadURL={downloadURL}
        />
      }

      {!assessmentPartReport && <CloseIcon onClick={handleClickClose} />}
    </ModalHeader>
  );
};

CohortAssessmentGridModalHeader.propTypes = {
  isExpanded: PropTypes.bool,
  toggleExpanded: PropTypes.func.isRequired,
  students: PropTypes.array,
  filteredStudents: PropTypes.array,
  setFilteredStudents: PropTypes.func.isRequired,
  isAnonymized: PropTypes.bool,
  assessments: PropTypes.array,
  filteredAssessments: PropTypes.array,
  setFilteredAssessments: PropTypes.func.isRequired,
  setIsAnonymized: PropTypes.func.isRequired,
  dataCellValueType: PropTypes.string.isRequired,
  setDataCellValueType: PropTypes.func.isRequired,
  reportType: PropTypes.string,
  setReportType: PropTypes.func,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  selectStartDate: PropTypes.func.isRequired,
  selectEndDate: PropTypes.func.isRequired,
  cohortStartDate: PropTypes.string.isRequired,
  cohortEndDate: PropTypes.string.isRequired,
  toggleValues: PropTypes.array.isRequired,
  handleClose: PropTypes.func,
  assessmentsLabel: PropTypes.string,
  defaultCollections: PropTypes.bool,
  setDefaultCollectionsChecked: PropTypes.func,
  defaultCollectionsChecked: PropTypes.bool,
  assessmentPartReport: PropTypes.bool,
  downloadURL: PropTypes.string,
};

CohortAssessmentGridModalHeader.defaultProps = {
  isExpanded: false,
  filteredStudents: [],
  students: [],
  assessments: [],
  filteredAssessments: [],
  isAnonymized: false,
  handleClose: null,
  reportType: undefined,
  setReportType: undefined,
  assessmentsLabel: 'Assessments',
  defaultCollections: false,
  setDefaultCollectionsChecked: () => {},
  defaultCollectionsChecked: false,
  assessmentPartReport: false,
  downloadURL: undefined,
};

export default CohortAssessmentGridModalHeader;
