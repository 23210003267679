import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CohortStudentTranscript from './CohortStudentTranscript';
import { doGetCoursesByStudent } from '../../../redux/actions/courseActions';
import { doGetCourseCollections } from '../../../redux/actions/courseCollectionActions';
import { doGetLetterGradesBySchool } from '../../../redux/actions/letterGradeActions';
import { useQuery } from '../../../helpers/hooks/router.hooks';

export default function CohortStudentTranscriptContainer() {
  const query = useQuery();
  const cohortUuid = query.get('cohortUuid');
  const userUuid = query.get('userUuid');
  const programUuid = query.get('programUuid');
  const dispatch = useDispatch();

  const schoolUuid = useSelector(state => state.userState.selectedSchoolUuid);

  useEffect(() => {
    if (userUuid) {
      dispatch(doGetCoursesByStudent(userUuid));
    }
  }, [dispatch, userUuid]);

  useEffect(() => {
    if (cohortUuid) {
      dispatch(doGetCourseCollections(cohortUuid));
    }
  }, [dispatch, cohortUuid]);

  useEffect(() => {
    if (schoolUuid) {
      dispatch(doGetLetterGradesBySchool(schoolUuid));
    }
  }, [dispatch, schoolUuid]);

  return (
    <CohortStudentTranscript
      programUuid={programUuid}
      cohortUuid={cohortUuid}
      userUuid={userUuid}
    />
  );
}

CohortStudentTranscriptContainer.propTypes = {};

CohortStudentTranscriptContainer.defaultProps = {};
