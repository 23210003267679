import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '@material-ui/core';
import styled from 'styled-components';

import { ASSESSMENT_GRID_MODAL_ID, headerValueTypes } from '../reportUtils';
import NoStudentsFound from './components/NoStudentsFound';
import CohortAssessmentGridModalHeader from '../CohortAssessmentGrid/CohortAssessmentGridModalHeader';
import { doSetShowQuantitativeGridModal } from '../../../redux/actions/quantitativeSkillsGridActions';
import NewTable from './NewTable';

const ScrollableContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
  background: ${props => props.theme.colors.grey[200]};
`;

const CohortQuantitativeSkillsGridModal = ({
  cohortUuid,
  startDate,
  endDate,
  selectStartDate,
  selectEndDate,
  cohortStartDate,
  cohortEndDate,
  students,
  isLoading,
  quantitativeSkillsCollections,
  defaultCollectionsChecked,
  setDefaultCollectionsChecked,
  fetchDefaultCollections
}) => {
  const dispatch = useDispatch();
  const [showTotals, setShowTotals] = useState(true);

  const [filteredStudents, setFilteredStudents] = useState([]);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [collections, setCollections] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isAnonymized, setIsAnonymized] = useState(true);

  const [dataCellValueType, setDataCellValueType] = useState(
    headerValueTypes.procedures
  );
  const showQuantitativeSkillsTableModal = useSelector(
    state => state.cohortQuantitativeSkillsReportState.showTableModal
  );

  // const selectedAssessments = useRef([]);
  //
  // useEffect(() => {
  //   if (defaultCollectionsChecked) {
  //     selectedAssessments.current = filteredAssessments;
  //   }
  //   console.log(selectedAssessments.current);
  // }, [defaultCollectionsChecked, filteredAssessments]);

  const filteredAssessmentsUuids = filteredAssessments.map(
    assessment => assessment.uuid
  );
  useEffect(() => {
    if (quantitativeSkillsCollections.length > 0) {
      const collections = [
        ...quantitativeSkillsCollections.map(a => ({
          ...a,
          type: 'collection'
        })),
        ...quantitativeSkillsCollections.map(a =>
          a.procedure_code_groups.map(b => ({ ...b, type: 'group' }))
        )
      ]
        .flat()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(q => ({
          uuid: q.uuid,
          title: q.name,
          name: q.name,
          fullName: q.name,
          type: q.type
        }));
      setCollections(collections);
    }
  }, [quantitativeSkillsCollections]);

  const displayStudents = useMemo(() => {
    const filteredStudentsUuids = filteredStudents.map(student => student.uuid);
    return students
      .filter(student => filteredStudentsUuids.includes(student.userUuid))
      .sort(
        (a, b) =>
          a.rank[dataCellValueType.toLowerCase()] -
          b.rank[dataCellValueType.toLowerCase()]
      );
  }, [dataCellValueType, filteredStudents, students]);

  return (
    <Dialog
      tabIndex="0"
      open={showQuantitativeSkillsTableModal}
      fullScreen
      onClose={() => {}}
    >
      <CohortAssessmentGridModalHeader
        isExpanded={isExpanded}
        toggleExpanded={setIsExpanded}
        filteredStudents={filteredStudents}
        setFilteredStudents={setFilteredStudents}
        students={students}
        isAnonymized={isAnonymized}
        assessments={collections}
        assessmentsLabel="Collections"
        filteredAssessments={filteredAssessments}
        setFilteredAssessments={setFilteredAssessments}
        setIsAnonymized={setIsAnonymized}
        showTotals={showTotals}
        setShowTotals={setShowTotals}
        dataCellValueType={dataCellValueType}
        setDataCellValueType={setDataCellValueType}
        startDate={startDate}
        endDate={endDate}
        selectStartDate={selectStartDate}
        selectEndDate={selectEndDate}
        cohortStartDate={cohortStartDate}
        cohortEndDate={cohortEndDate}
        toggleValues={Object.values(headerValueTypes)}
        handleClose={() => dispatch(doSetShowQuantitativeGridModal(false))}
        skipAssigningAssessments
        defaultCollections
        defaultCollectionsChecked={defaultCollectionsChecked}
        setDefaultCollectionsChecked={setDefaultCollectionsChecked}
        downloadURL={`/api/cohort/${cohortUuid}/quantitative-skills-grid-download?fetchDefaultCollections=${fetchDefaultCollections}&isAnonymized=${isAnonymized}&isExpanded=${isExpanded}&startDate=${startDate}&endDate=${endDate}&filteredStudents=${filteredStudents.filter(s => s.uuid).map(s => s.uuid)}&filteredAssessments=${filteredAssessments.filter(a => a.uuid).map(a => a.uuid)}`}
      />

      {students.length === 0 ? (
        <NoStudentsFound isLoading={isLoading} />
      ) : (
        <div style={{ height: 'calc(100% - 65px)' }}>
          <div
            id={ASSESSMENT_GRID_MODAL_ID}
            style={{ paddingLeft: 15, paddingTop: 15, zoom: 1, height: '100%' }}
          >
            <ScrollableContainer>
              <NewTable
                isExpanded={isExpanded}
                students={displayStudents}
                isAnonymized={isAnonymized}
                dataCellValueType={dataCellValueType}
                quantitativeSkillsCollections={collections.filter(c => {
                  if (isExpanded) {
                    return filteredAssessmentsUuids.includes(c.uuid);
                  }

                  return (
                    c.type === 'collection' &&
                    filteredAssessmentsUuids.includes(c.uuid)
                  );
                })}
              />
            </ScrollableContainer>
          </div>
        </div>
      )}
    </Dialog>
  );
};

CohortQuantitativeSkillsGridModal.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  selectStartDate: PropTypes.func.isRequired,
  selectEndDate: PropTypes.func.isRequired,
  cohortStartDate: PropTypes.string,
  cohortEndDate: PropTypes.string,
  students: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  quantitativeSkillsCollections: PropTypes.array.isRequired,
  defaultCollectionsChecked: PropTypes.bool,
  setDefaultCollectionsChecked: PropTypes.func,
  cohortUuid: PropTypes.string,
  fetchDefaultCollections: PropTypes.bool,
};

CohortQuantitativeSkillsGridModal.defaultProps = {
  cohortUuid: undefined,
  cohortStartDate: '',
  cohortEndDate: '',
  students: [],
  defaultCollectionsChecked: true,
  setDefaultCollectionsChecked: () => {},
  fetchDefaultCollections: false,
};

export default CohortQuantitativeSkillsGridModal;
