import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSWRMutation from 'swr/mutation';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { ThemeContext } from 'styled-components';
import { LinearProgress } from '@material-ui/core';
import { shadeColor } from '@xcomp/xcomp-design-library/dist/helpers';
import { selfAssessmentAssessmentTypesSelector } from '../../../../redux/selectors/assessmentSelectors';
import { fetcher } from '../../../../helpers/utilities';
import { formatFullName } from '../../../../helpers/format/user.format';
import PageIllustration from '../../../Library/PageIllustration';
import { doGetAssessmentType } from '../../../../redux/actions/assessmentActions';
import FilterDropDown from '../../PartGradeSummary/FilterDropDown';
import { getCharacterizationColor } from '../../../Reports/reportUtils';
import { ErrorWrapper, InvalidScores, NoDataContainer, PanelSC, ProgressBar, ReportLeaf, SelfAssessmentAccordion, SelfAssessmentHeader } from '../../../Reports/SelfAssessmentReportComponents';

const SectionSelfAssessmentReportContainer = ({ sectionUuid }) => {
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);
  const [isAnonymized, setIsAnonymized] = useState(false);
  const themeContext = useContext(ThemeContext);
  const [partColors, setPartColors] = useState([]);

  const dispatch = useDispatch();

  const { trigger, error, data, isMutating } = useSWRMutation(
    `/api/section/${sectionUuid}/self-assessment-report?`,
    fetcher,
    {
      throwOnError: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (data && data.numberOfParts) {
      const amount = 50 / (data.numberOfParts + 1) / 100;
      setPartColors(
        Array(data.numberOfParts + 1).fill(0).map((e, index) => shadeColor(themeContext.colors.white, index * amount)).slice(1)
      )
    }
  }, [data, themeContext.colors.white]);

  const assessmentTypes = useSelector(
    state => selfAssessmentAssessmentTypesSelector(state),
    isEqual
  );

  useEffect(() => {
    if (sectionUuid) {
      dispatch(doGetAssessmentType());
    }
  }, [dispatch, sectionUuid]);

  const handleApplyPartSelfAssessmentReportFilters = assessmentTypes => {
    setHasAppliedFilters(true);
    trigger({queryParameters: `&assessmentTypes=${JSON.stringify(assessmentTypes)}`});
  };

  return (
    <>
      <FilterDropDown
        options={assessmentTypes}
        callback={handleApplyPartSelfAssessmentReportFilters}
        title='Self-Assessment Report Filters'
      />
      {data && Object.keys(data.invalidCharacterizations).length > 0 &&
        <InvalidScores invalidCharacterizations={data.invalidCharacterizations} />
      }
      {// eslint-disable-next-line
        isMutating ?
          <PanelSC>
            <ProgressBar>Loading</ProgressBar>
            <LinearProgress />
          </PanelSC>
        : error
          ?
            <ErrorWrapper>
              <PageIllustration
                illustrationType="notFound"
                infoText="An error occured when trying to retrieve data."
              />
            </ErrorWrapper>
          :
            <PanelSC>
              {hasAppliedFilters && data?.students.length === 0 && (
                <NoDataContainer>
                  No data for the selected filters. Change filter selection and
                  re-apply.
                </NoDataContainer>
              )}
              {!hasAppliedFilters && !data && (
                <NoDataContainer>
                  Apply the filter selection to view the report.
                </NoDataContainer>
              )}
              {data && data.students.length > 0 && (
                <SelfAssessmentHeader isAnonymized={isAnonymized} setIsAnonymized={setIsAnonymized} />
              )}
              {data && data.students.length > 0 &&
                data.students.map((s, index) => (
                  <SelfAssessmentAccordion
                    key={s.uuid}
                    columnOne={<span style={{fontWeight: 600}}>{s.totalCharacterizations}</span>}
                    columnTwo={`${(s.relValue / s.potValue * 100).toFixed(2)} %`}
                    columnThree={s.relValue.toFixed(1)}
                    columnFour={s.potValue.toFixed(1)}
                    columnOneClassName="column_one_label"
                    columnTwoClassName="column_two_label"
                    columnThreeClassName="column_three_label"
                    columnFourClassName="column_four_label"
                    expandWidth="66px"
                    height="50px"
                    isStatisticStudent={false}
                    label={isAnonymized ? s.institutionId : formatFullName(s)}
                    withBottomBorder
                    $level='student'
                    $even={index % 2 === 0}
                  >
                    {s.characterizations.map((c) =>
                      <SelfAssessmentAccordion
                        key={c.characterization}
                        columnOne={<span style={{fontWeight: c.total === 0 ? 300 : 600}}>{c.total.toString()}</span>}
                        columnTwo=' '
                        columnThree=' '
                        columnFour=' '
                        columnOneClassName="column_one_label"
                        columnTwoClassName="column_two_label"
                        columnThreeClassName="column_three_label"
                        columnFourClassName="column_four_label"
                        expandWidth="66px"
                        height="40px"
                        isStatisticStudent={false}
                        label={c.characterization}
                        $level='characterization'
                        $background={getCharacterizationColor(c)}
                      >
                        {c.parts.length === 0
                          ?
                            <div style={{ width: '100%', textAlign: 'center', fontSize: 16, fontStyle: 'italic' }}>There are no scores for this characterization</div>
                          :
                            c.parts.map((part, index) =>
                              part && 
                              <SelfAssessmentAccordion
                                key={part.title}
                                expandWidth="66px"
                                height="40px"
                                isStatisticStudent={false}
                                label={<div><span style={{fontWeight: 600, marginRight: 10}}>Part {part.partNumber}</span> {part.title}</div>}
                                $level='part'
                                $even={index % 2 === 0}
                                $background={partColors[index]}
                                backgroundSelected={partColors[index]}
                              >
                                {part.scores.map((score, index) =>
                                  <ReportLeaf
                                    key={score.key}
                                    even={index % 2 === 0}
                                    assessmentTitle={score.assessmentTitle}
                                    blockTitle={score.blockTitle}
                                    blockNumber={score.blockNumber}
                                    relValue={score.relValue}
                                    potValue={score.potValue}
                                  />
                                )}
                              </SelfAssessmentAccordion>
                            )
                        }
                      </SelfAssessmentAccordion>
                    )}
                  </SelfAssessmentAccordion>
                ))}
            </PanelSC>
    }
    </>
  );
};

SectionSelfAssessmentReportContainer.propTypes = {
  sectionUuid: PropTypes.string
};

SectionSelfAssessmentReportContainer.defaultProps = {
  sectionUuid: ''
};

export default SectionSelfAssessmentReportContainer;
