import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, makeStyles, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import useSWR from 'swr';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { atom, useAtom } from 'jotai';

import { PanelHeader } from '../../../Library/DashboardSC';
import FilterMenuButton from '../PartAttemptReport/MenuButton';
import { fetcher } from '../../../../helpers/utilities';

const ButtonSC = styled(Button)`
  margin-bottom: 16px;
  width: 200px;
`;

const Tab = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 400;
  width: 49.5%;
  height: 40px;
  border-radius: 3px;
  color: ${props => props.$selected ? props.theme.fontColors.darker : props.theme.fontColors.medium} !important;
  background: ${props => props.$selected ? props.theme.colors.secondaryShades[500] : props.theme.colors.grey[200]} !important;
`

const useStyles = makeStyles(() => ({
  paper: {
    padding: '20px',
    display: 'flex',
    gap: '15px',
    flexDirection: 'column'
  },
  panelHeader: {
    justifyContent: 'space-between',
    paddingBottom: '16px',
    paddingLeft: '0'
  },
  headerButtons: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  loadingBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  filtersBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  applyBox: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const filtersDefaultValues = {
  assessmentTechniques: [],
  assessmentTypes: [],
  encounterTypes: [],
  educationLevels: [],
  faculties: [],
  filteredFaculties: [],
  filteredAssessmentTechniques: [],
  filteredAssessmentTypes: [],
  filteredEncounterTypes: [],
  filteredEducationLevels: [],
};

const filtersAtom = atom(filtersDefaultValues);

const Filters = ({
  onApplyPartFacultyReportFilters,
  reportType,
  setReportType,
  getInstructionReport,
}) => {
  const { currentRole } = useSelector(state => state.userState);
  const classes = useStyles();
  const { partUuid } = useParams();
  const [filters, setFilters] = useAtom(filtersAtom);

  const { data, isLoading } = useSWR(
    `/api/faculty-report-filters?partUuid=${partUuid}`,
    fetcher
  );

  const resetFilters = () => {
    setFilters(filtersDefaultValues)
  }

  const setFilteredKeys = useCallback(
    (a, b) => uuid => {
      if (uuid.toLowerCase() === 'all') {
        setFilters({
          ...filters,
          [a]:
            filters[b].length === filters[a].length
              ? []
              : filters[b].map(a => a.uuid)
        });
        return;
      }

      const index = filters[a].indexOf(uuid);

      const newArray =
        index > -1 ? filters[a].filter(a => a !== uuid) : [...filters[a], uuid];

      setFilters({
        ...filters,
        [a]: newArray
      });
    },
    [filters, setFilters]
  );

  useEffect(() => {
    if (data) {
      const {
        assessmentTechniques,
        assessmentTypes,
        encounterTypes,
        educationLevels,
        faculties
      } = data || {};

      setFilters({
        ...filters,
        assessmentTechniques,
        assessmentTypes,
        encounterTypes,
        educationLevels,
        faculties,
        filteredFaculties: faculties?.map(s => s.uuid) || [],
        filteredAssessmentTechniques: assessmentTechniques?.map(a => a.uuid) || [],
        filteredAssessmentTypes: assessmentTypes?.map(a => a.uuid) || [],
        filteredEncounterTypes: encounterTypes?.map(a => a.uuid) || [],
        filteredEducationLevels: educationLevels?.map(a => a.uuid) || [],
        fetched: true
      });
    }
  // eslint-disable-next-line
  }, [data]);

  return (
    <Paper className={classes.paper}>
      <PanelHeader className={classes.panelHeader} paddingLeft="16px">
        <Tab onClick={() => setReportType('instruction')} $selected={reportType === 'instruction'}>Instruction Report Filters</Tab>
        <Tab onClick={() => setReportType('assessment')} $selected={reportType === 'assessment'}>Assessment Report Filters</Tab>
      </PanelHeader>
      {isLoading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress />
        </Box>
      ) : (
        <div style={{margin: '20px 0'}}>
          <Box className={classes.filtersBox} marginBottom={2}>
            <FilterMenuButton
              onClick={setFilteredKeys('filteredFaculties', 'faculties')}
              options={filters.faculties}
              buttonLabel={`Faculties (${
                filters.filteredFaculties.includes('all')
                  ? filters.filteredFaculties.length - 1
                  : filters.filteredFaculties.length
              })`}
              selectedOptions={filters.filteredFaculties}
              displaySearch
              displayCheckbox
              disableMinWidth
              containerStyle={{width: '100%'}}
            />
          </Box>
          <Box className={classes.filtersBox}>
            {reportType === 'instruction' &&
              <>
                <FilterMenuButton
                  onClick={setFilteredKeys('filteredEncounterTypes', 'encounterTypes')}
                  options={filters.encounterTypes}
                  buttonLabel={`Encounter Types (${filters.filteredEncounterTypes.length})`}
                  selectedOptions={filters.filteredEncounterTypes}
                  displayCheckbox
                  variant='contained'
                  disableMinWidth
                  containerStyle={{width: '49.5%'}}
                />
                <FilterMenuButton
                  onClick={setFilteredKeys('filteredEducationLevels', 'educationLevels')}
                  options={filters.educationLevels}
                  buttonLabel={`Education Levels (${filters.filteredEducationLevels.length})`}
                  selectedOptions={filters.filteredEducationLevels}
                  displayCheckbox
                  variant='contained'
                  disableMinWidth
                  containerStyle={{width: '49.5%'}}
                />
              </>
            }
            {reportType === 'assessment' &&
              <>
                <FilterMenuButton
                  onClick={setFilteredKeys('filteredAssessmentTechniques', 'assessmentTechniques')}
                  options={filters.assessmentTechniques}
                  buttonLabel={`Assessment Techniques (${filters.filteredAssessmentTechniques.length})`}
                  selectedOptions={filters.filteredAssessmentTechniques}
                  displayCheckbox
                  variant='contained'
                  disableMinWidth
                  containerStyle={{width: '49.5%'}}
                />
                <FilterMenuButton
                  onClick={setFilteredKeys('filteredAssessmentTypes', 'assessmentTypes')}
                  options={filters.assessmentTypes}
                  buttonLabel={`Assessment Types (${filters.filteredAssessmentTypes.length})`}
                  selectedOptions={filters.filteredAssessmentTypes}
                  displayCheckbox
                  variant='contained'
                  disableMinWidth
                  containerStyle={{width: '49.5%'}}
                />
              </>
            }
          </Box>
        </div>
      )}
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <ButtonSC onClick={resetFilters} color="primary">
          RESET FILTERS
        </ButtonSC>
        <ButtonSC
          color="primary"
          disabled={reportType === 'instruction'
            ? (filters.filteredEncounterTypes.length === 0 || filters.filteredEducationLevels.length === 0 || filters.filteredFaculties.length === 0)
            : (filters.filteredAssessmentTechniques.length === 0 || filters.filteredAssessmentTypes.length === 0 || filters.filteredFaculties.length === 0)
          }
          variant="contained"
          onClick={() => {
            if (reportType === 'instruction') {
              getInstructionReport({
                options: {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    reportType,
                    faculties: filters.filteredFaculties.filter(f => f !== 'all'),
                    currentRoleUuid: currentRole.uuid,
                    currentRoleName: currentRole.role,
                    encounterTypes: filters.filteredEncounterTypes.filter(f => f !== 'all'),
                    educationLevels: filters.filteredEducationLevels
                  }),
                  credentials: 'include'
                }
              });

            } else {
              onApplyPartFacultyReportFilters(reportType, filters);
            }
          }}
        >
          APPLY
        </ButtonSC>
      </div>
    </Paper>
  );
};

Filters.propTypes = {
  onApplyPartFacultyReportFilters: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
  setReportType: PropTypes.func.isRequired,
  getInstructionReport: PropTypes.func.isRequired,
};

Filters.defaultProps = {
};

export default Filters;
