import {
  GET_COURSE_AGE_MOD,
  GET_COURSE_AGE_MOD_SUCCESS,
  GET_COURSE_AGE_MOD_ERROR,
  POST_COURSE_AGE_MOD,
  POST_COURSE_AGE_MOD_SUCCESS,
  POST_COURSE_AGE_MOD_ERROR
} from '../actions-type';

const doGetCourseAgeMod = programUuid => ({
  type: GET_COURSE_AGE_MOD,
  payload: { programUuid }
});

const doGetCourseAgeModSuccess = result => ({
  type: GET_COURSE_AGE_MOD_SUCCESS,
  payload: result
});

const doGetCourseAgeModError = error => ({
  type: GET_COURSE_AGE_MOD_ERROR,
  payload: error
});

const doPostCourseAgeMod = courseAgeMods => ({
  type: POST_COURSE_AGE_MOD,
  payload: { courseAgeMods }
});

const doPostCourseAgeModSuccess = result => ({
  type: POST_COURSE_AGE_MOD_SUCCESS,
  payload: result
});

const doPostCourseAgeModError = error => ({
  type: POST_COURSE_AGE_MOD_ERROR,
  payload: error
});

export {
  doGetCourseAgeMod,
  doGetCourseAgeModSuccess,
  doGetCourseAgeModError,
  doPostCourseAgeMod,
  doPostCourseAgeModSuccess,
  doPostCourseAgeModError
};
