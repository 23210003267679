import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Body1,
  Heading2,
  ButtonPrimary,
  ButtonInline
} from '@xcomp/xcomp-design-library';
import { InfoIcon } from '@xcomp/xcomp-design-library/dist/icons';

import {
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import { Panel, PanelHeader } from '../../../Library/Layout';
import GradeCollectionDetailsForm from './GradeCollectionDetailsForm';
import { ButtonOutline } from '../../../Library/ButtonSC';
import { InfoText } from '../../../Library/FormComponents';

const CollectionPanel = styled(Panel)`
  margin-top: 32px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-top: 20px;
  button {
    margin-left: 16px;
  }
`;

const InfoTextSC = styled(InfoText)`
  position: absolute;
  top: -10px;
  right: 0;
  display: flex;
  align-items: center;

  svg {
    font-size: 16px;
    margin-right: 4px;
  }
`;

const AddCollectionButton = styled(ButtonInline)`
  && {
    margin-left: 16px;
    margin-top: 16px;
  }
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const CheckboxContainer = styled.div`
  color: ${props => props.theme.fontColors.dark};
  margin-bottom: 0;
`;

const FormControlLabelSC = styled(FormControlLabel)`
  && {
    margin-right: 0;
  }
`;

const DistributeEvenlyRow = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
`;

const GradeCollectionsForm = ({
  distributeWeightEqually,
  partGradeSettingsUuid,
  gradeCollections,
  unsavedChanges,
  showValidationErrors,
  onCollectionTitleChange,
  onCollectionWeightChange,
  onAddCollection,
  onRemoveCollection,
  handleDiscardChanges,
  handleCollectionsSubmit,
  handleDistributeWeightEquallyChange
}) => {
  const gradeCollectionKeys = Object.keys(gradeCollections);
  return (
    <>
      <CollectionPanel>
        <PanelHeader paddingLeft="24px">
          <Heading2>Grade Collections</Heading2>
        </PanelHeader>
        <PanelPadding>
          {gradeCollectionKeys?.length > 0 ? (
            gradeCollectionKeys.map((collectionUuid, index) => (
              <GradeCollectionDetailsForm
                key={collectionUuid}
                distributeWeightEqually={distributeWeightEqually}
                collection={gradeCollections[collectionUuid]}
                collectionNumber={index + 1}
                showValidationErrors={showValidationErrors}
                onCollectionTitleChange={onCollectionTitleChange}
                onCollectionWeightChange={onCollectionWeightChange}
                onRemoveCollection={onRemoveCollection}
              />
            ))
          ) : (
            <Body1>There are no collections created</Body1>
          )}
          <AddCollectionButton onClick={onAddCollection} noHoverBg>
            Add Grade Collection
          </AddCollectionButton>
          <DistributeEvenlyRow>
            <FormControl component="fieldset">
              <FormGroup>
                <CheckboxContainer>
                  <FormControlLabelSC
                    control={
                      <Checkbox
                        id={`distributeWeightEqually-${partGradeSettingsUuid}`}
                        onChange={handleDistributeWeightEquallyChange}
                        checked={distributeWeightEqually}
                        color="primary"
                      />
                    }
                    label="Distribute weight equally among collections"
                  />
                </CheckboxContainer>
              </FormGroup>
            </FormControl>
          </DistributeEvenlyRow>
          <ButtonRow>
            <ButtonOutline
              onClick={handleDiscardChanges}
              disabled={!unsavedChanges.gradeCollections}
            >
              Discard Changes
            </ButtonOutline>
            {unsavedChanges.gradeCollections && (
              <InfoTextSC
                alignItems="flex-end"
                justifyContent="flex-end"
                padding="0 8px 0 0"
                error
              >
                <InfoIcon />
                Unsaved Changes
              </InfoTextSC>
            )}
            <ButtonPrimary
              onClick={handleCollectionsSubmit}
              disabled={!unsavedChanges.gradeCollections}
            >
              Save Collections
            </ButtonPrimary>
          </ButtonRow>
        </PanelPadding>
      </CollectionPanel>
    </>
  );
};

GradeCollectionsForm.propTypes = {
  distributeWeightEqually: PropTypes.bool,
  partGradeSettingsUuid: PropTypes.string,
  gradeCollections: PropTypes.object,
  unsavedChanges: PropTypes.object,
  showValidationErrors: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onCollectionTitleChange: PropTypes.func,
  onCollectionWeightChange: PropTypes.func,
  onAddCollection: PropTypes.func,
  onRemoveCollection: PropTypes.func,
  handleDiscardChanges: PropTypes.func,
  handleCollectionsSubmit: PropTypes.func,
  handleDistributeWeightEquallyChange: PropTypes.func
};
GradeCollectionsForm.defaultProps = {
  distributeWeightEqually: false,
  partGradeSettingsUuid: '',
  gradeCollections: {},
  unsavedChanges: {},
  showValidationErrors: false,
  onCollectionTitleChange: undefined,
  onCollectionWeightChange: undefined,
  onAddCollection: undefined,
  onRemoveCollection: undefined,
  handleDiscardChanges: undefined,
  handleCollectionsSubmit: undefined,
  handleDistributeWeightEquallyChange: undefined
};

export default GradeCollectionsForm;
