export const POST_COHORT_COMPETENCY_GRID = 'POST_COHORT_COMPETENCY_GRID';
export const POST_COHORT_COMPETENCY_GRID_SUCCESS =
  'POST_COHORT_COMPETENCY_GRID_SUCCESS';
export const POST_COHORT_COMPETENCY_GRID_ERROR =
  'POST_COHORT_COMPETENCY_GRID_ERROR';

export const SET_IS_COMPETENCY_REPORT_LOADING =
  'SET_IS_COMPETENCY_REPORT_LOADING';
export const CLEAR_COMPETENCY_GRID_REPORT = 'CLEAR_COMPETENCY_GRID_REPORT';
export const SET_TABLE_ZOOM = `ZOOM_TABLE_ZOOM`;
export const SET_SHOW_TABLE_MODAL = `SET_SHOW_TABLE_MODAL`;
export const SET_IS_RESULT_LOADED = `SET_IS_RESULT_LOADED`;