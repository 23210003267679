import {
  GET_HIERARCHY,
  GET_HIERARCHY_SUCCESS,
  GET_HIERARCHY_ERROR
} from '../actions-type';

const doGetHierarchy = () => ({
  type: GET_HIERARCHY
});

const doGetHierarchySuccess = result => ({
  type: GET_HIERARCHY_SUCCESS,
  payload: result
});

const doGetHierarchyError = error => ({
  type: GET_HIERARCHY_ERROR,
  payload: error
});

export { doGetHierarchy, doGetHierarchySuccess, doGetHierarchyError };
