import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import PartTopicReportAssessmentListContainer from './PartTopicReportAssessmentDetail/PartTopicReportAssessmentListContainer';

const PartTopicReportPanelAccordion = styled(
  ({ withBottomBorder, withTopBorder, criticalFailure, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label,
  .column_two_label,
  .column_three_label,
  .column_four_label {
    flex-grow: 0;
    width: 100px;
    text-align: right;
  }

  .accordion_row_label div {
    font-size: 16px;
    letter-spacing: 0.15;
    color: ${props => props.theme.fontColors.darker};
  }

  .column_one_label,
  .column_two_label,
  .column_three_label {
    margin-right: 16px;
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props =>
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.theme.fontColors.darker};
    }
  }

  .column_four_label {
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props => props.theme.fontColors.darker};
    }
  }

  .hover-effect {
    padding-right: 44px;
  }
  width: 100%;
`;

const PartTopicReportPanel = ({
  assessments,
  assessmentTechniques,
  assessmentTypes,
  topicUuid,
  isSummaryPanel,
  topicLabel,
  partTopicReportAssessmentData,
  partTopicParticipationPercentage,
  partTopicRelativePercentage,
  partTopicTotalPotentialScore,
  partTopicTotalRelativeScore,
  partUuid
}) => {
  return (
    <PartTopicReportPanelAccordion
      withBottomBorder
      expandWidth="66px"
      height="50px"
      label={topicLabel}
      columnOne={`${partTopicParticipationPercentage}%`}
      columnTwo={`${partTopicRelativePercentage}%`}
      columnThree={partTopicTotalRelativeScore}
      columnFour={partTopicTotalPotentialScore}
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
      columnThreeClassName="column_three_label"
      columnFourClassName="column_four_label"
    >
      <PartTopicReportAssessmentListContainer
        assessments={assessments}
        assessmentTechniques={assessmentTechniques}
        assessmentTypes={assessmentTypes}
        topicUuid={topicUuid}
        isSummaryPanel={isSummaryPanel}
        partTopicReportAssessmentData={partTopicReportAssessmentData}
        partUuid={partUuid}
      />
    </PartTopicReportPanelAccordion>
  );
};

PartTopicReportPanel.propTypes = {
  assessments: PropTypes.array,
  assessmentTechniques: PropTypes.array,
  assessmentTypes: PropTypes.array,
  topicUuid: PropTypes.string,
  isSummaryPanel: PropTypes.bool,
  topicLabel: PropTypes.string,
  partTopicReportAssessmentData: PropTypes.object,
  partTopicParticipationPercentage: PropTypes.string,
  partTopicRelativePercentage: PropTypes.string,
  partTopicTotalPotentialScore: PropTypes.string,
  partTopicTotalRelativeScore: PropTypes.string,
  partUuid: PropTypes.string
};

PartTopicReportPanel.defaultProps = {
  assessments: [],
  assessmentTechniques: [],
  assessmentTypes: [],
  topicUuid: '',
  isSummaryPanel: false,
  topicLabel: '',
  partTopicReportAssessmentData: {},
  partTopicParticipationPercentage: '',
  partTopicRelativePercentage: '',
  partTopicTotalPotentialScore: '',
  partTopicTotalRelativeScore: '',
  partUuid: ''
};

export default PartTopicReportPanel;
