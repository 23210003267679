import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { Grid } from '@material-ui/core';

import FormField from '../../../../../Library/FormField';

import {
  FormSectionHeader,
  FormSectionHeaderSubtitle
} from '../../../../../Library/FormComponents';

import { validateInputString } from '../../../../../../helpers/validation/validateGeneric';

import EditorMilestoneConversion from './EditorMilestoneConversion';
import EditorSearchInstitution from './EditorSearchInstitution';

const FormSectionHeaderSC = styled(FormSectionHeader)`
  && {
    margin-bottom: 0;
  }
`;

export default function EditorMilestone({
  classificationUuid,
  milestone,
  hasErrors,
  handleUpdate,
  handleAddExam,
  handleAddConversion,
  handleUpdateConversion,
  handleUpdateSourceInstitionUuid,
  handleRemoveConversion
}) {
  useEffect(() => {
    if (_.isNil(milestone)) handleAddExam(classificationUuid);
  }, [classificationUuid, handleAddExam, milestone]);

  return (
    <Grid container spacing={4} direction="row">
      {milestone && (
        <>
          <Grid item sm={12} xs={12}>
            <FormSectionHeaderSC offset="0">
              <FormSectionHeaderSubtitle>
                Aptitude Test Data
              </FormSectionHeaderSubtitle>
            </FormSectionHeaderSC>
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormField
              name="examId"
              label="ID*"
              onChange={event =>
                handleUpdate(event, classificationUuid, milestone.uuid)
              }
              value={milestone.examId}
              hasErrors={hasErrors}
              handleValidate={validateInputString}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              name="examName"
              label="Name*"
              onChange={event =>
                handleUpdate(event, classificationUuid, milestone.uuid)
              }
              value={milestone.examName}
              hasErrors={hasErrors}
              handleValidate={validateInputString}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField
              name="credits"
              label="Credits*"
              onChange={event =>
                handleUpdate(event, classificationUuid, milestone.uuid)
              }
              value={milestone.credits}
              hasErrors={hasErrors}
              handleValidate={validateInputString}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              name="examDescription"
              label="Description"
              onChange={event =>
                handleUpdate(event, classificationUuid, milestone.uuid)
              }
              value={milestone.examDescription}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <EditorSearchInstitution
              sourceInstitutionUuid={milestone.sourceInstitutionUuid}
              classificationUuid={classificationUuid}
              examMilestoneUuid={milestone.uuid}
              hasErrors={hasErrors}
              handleUpdateSourceInstitionUuid={handleUpdateSourceInstitionUuid}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <EditorMilestoneConversion
              classificationUuid={classificationUuid}
              examMilestoneUuid={milestone.uuid}
              conversions={milestone.conversion_milestones}
              hasErrors={hasErrors}
              handleAddConversion={handleAddConversion}
              handleUpdateConversion={handleUpdateConversion}
              handleRemoveConversion={handleRemoveConversion}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

EditorMilestone.propTypes = {
  milestone: PropTypes.object,
  classificationUuid: PropTypes.string,
  hasErrors: PropTypes.bool,
  handleUpdate: PropTypes.func,
  handleAddExam: PropTypes.func,
  handleAddConversion: PropTypes.func,
  handleUpdateConversion: PropTypes.func,
  handleUpdateSourceInstitionUuid: PropTypes.func,
  handleRemoveConversion: PropTypes.func
};

EditorMilestone.defaultProps = {
  milestone: undefined,
  classificationUuid: undefined,
  hasErrors: false,
  handleUpdate: undefined,
  handleAddExam: undefined,
  handleAddConversion: undefined,
  handleUpdateConversion: undefined,
  handleUpdateSourceInstitionUuid: undefined,
  handleRemoveConversion: undefined
};
