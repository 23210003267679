import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import AdminAppId from './AdminAppId';
import { selectAdminEmails } from '../../../../redux/selectors/usersSelectors';
import { validateSelectField } from '../../../../helpers/validation/validateGeneric';
import { doPostGenerateAppId } from '../../../../redux/actions/userActions';

export default function AdminAppIdContainer({
  adminAppIdUser,
  openAdminAppId,
  handleAdminAppIdClose
}) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(undefined);
  const [hasErrors, setHasErrors] = useState(false);

  const createAppId = useSelector(state => state.crudState.createAppId);

  const createAppIdResult = useSelector(
    state => state.crudState.createAppIdResult
  );

  const userEmails = useSelector(state => {
    if (adminAppIdUser) {
      const roles = selectAdminEmails(state, adminAppIdUser);

      return [...roles];
    } else {
      return [];
    }
  });

  const optionsEmails =
    userEmails?.map(email => ({
      value: email,
      label: `${email}`
    })) || [];

  useEffect(() => {
    if (createAppIdResult === 'success') {
      handleAdminAppIdClose();
      dispatch({ type: 'POST_USER_APP_ID_RESET' });
    }
  }, [dispatch, createAppIdResult, handleAdminAppIdClose]);

  const handleEmailSelection = event => {
    const { value } = event.target;
    setEmail(value);
  };

  const validation = () => {
    let containErrors = false;

    const emailError = validateSelectField(email);

    if (emailError.invalid) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleGenerateAppId = () => {
    const reject = validation();
    if (reject) {
      setHasErrors(true);
    } else {
      dispatch(doPostGenerateAppId('appIdAdmin', adminAppIdUser, email));
      setHasErrors(false);
    }
  };

  return (
    <AdminAppId
      hasErrors={hasErrors}
      createAppId={createAppId}
      email={email}
      optionsEmails={optionsEmails}
      openAdminAppId={openAdminAppId}
      handleAdminAppIdClose={handleAdminAppIdClose}
      handleEmailSelection={handleEmailSelection}
      handleGenerateAppId={handleGenerateAppId}
    />
  );
}

AdminAppIdContainer.propTypes = {
  adminAppIdUser: PropTypes.string,
  openAdminAppId: PropTypes.bool,
  handleAdminAppIdClose: PropTypes.func
};

AdminAppIdContainer.defaultProps = {
  adminAppIdUser: undefined,
  openAdminAppId: false,
  handleAdminAppIdClose: undefined
};
