export const GET_USER_PROGRAMS_BY_PROGRAM = 'GET_USER_PROGRAMS_BY_PROGRAM';
export const GET_USER_PROGRAMS_BY_PROGRAM_SUCCESS =
  'GET_USER_PROGRAMS_BY_PROGRAM_SUCCESS';
export const GET_USER_PROGRAMS_BY_PROGRAM_ERROR =
  'GET_USER_PROGRAMS_BY_PROGRAM_ERROR';
export const GET_USER_PROGRAMS_BY_PROGRAMS = 'GET_USER_PROGRAMS_BY_PROGRAMS';
export const GET_USER_PROGRAMS_BY_PROGRAMS_SUCCESS =
  'GET_USER_PROGRAMS_BY_PROGRAMS_SUCCESS';
export const GET_USER_PROGRAMS_BY_PROGRAMS_ERROR =
  'GET_USER_PROGRAMS_BY_PROGRAMS_ERROR';
