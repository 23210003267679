import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_TRANSCRIPT,
  POST_TRANSCRIPT_UPLOAD,
  POST_TRANSCRIPT,
  PUT_TRANSCRIPT,
  DELETE_TRANSCRIPT,
  DELETE_TRANSCRIPTS,
  POST_CLONE_TRANSCRIPT
} from '../actions-type';
import {
  getTranscript,
  postTranscriptUpload,
  postTranscript,
  putTranscript,
  deleteTranscript,
  deleteTranscripts,
  postCloneTranscript
} from '../../api/applicantTranscriptApi';

import {
  doGetTranscriptError,
  doGetTranscriptSuccess,
  doPostTranscriptUploadSuccess,
  doPostTranscriptUploadError,
  doPostTranscriptSuccess,
  doPostTranscriptError,
  doDeleteTranscriptSuccess,
  doDeleteTranscriptError,
  doDeleteTranscriptsSuccess,
  doDeleteTranscriptsError,
  doPutTranscriptSuccess,
  doPutTranscriptError,
  doPostCloneTranscriptSuccess,
  doPostCloneTranscriptError
} from '../actions/applicantTranscriptActions';

function* handleGetTranscript(action) {
  try {
    const { programUuid, cohortUuid, userUuid } = action.payload;

    const result = yield call(getTranscript, programUuid, cohortUuid, userUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetTranscriptSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetTranscriptError(error));
  }
}

function* handlePostUploadTranscripts(action) {
  try {
    const result = yield call(postTranscriptUpload, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostTranscriptUploadSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostTranscriptUploadError(error));
  }
}

function* handlePostTranscript(action) {
  try {
    const result = yield call(postTranscript, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostTranscriptSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostTranscriptError(error));
  }
}

function* handlePutTranscript(action) {
  try {
    const result = yield call(putTranscript, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutTranscriptSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutTranscriptError(error));
  }
}

function* handleDeleteTranscript(action) {
  const { category, uuid } = action.payload;

  try {
    const result = yield call(deleteTranscript, category, uuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteTranscriptSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteTranscriptError(error));
  }
}

function* handleDeleteTranscripts(action) {
  const {
    category,
    source,
    userUuid,
    cohortUuid,
    programUuid
  } = action.payload;

  try {
    const result = yield call(
      deleteTranscripts,
      category,
      source,
      userUuid,
      cohortUuid,
      programUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteTranscriptsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteTranscriptsError(error));
  }
}

function* handlePostCloneTranscript(action) {
  try {
    const {
      fromCohortUuid,
      fromUserUuid,
      toCohortUuid,
      toUserUuid
    } = action.payload;

    const result = yield call(
      postCloneTranscript,
      fromCohortUuid,
      fromUserUuid,
      toCohortUuid,
      toUserUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostCloneTranscriptSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostCloneTranscriptError(error));
  }
}

const applicantTranscript = [
  takeEvery(GET_TRANSCRIPT, handleGetTranscript),
  takeEvery(POST_TRANSCRIPT_UPLOAD, handlePostUploadTranscripts),
  takeEvery(POST_TRANSCRIPT, handlePostTranscript),
  takeEvery(POST_CLONE_TRANSCRIPT, handlePostCloneTranscript),
  takeEvery(PUT_TRANSCRIPT, handlePutTranscript),
  takeEvery(DELETE_TRANSCRIPT, handleDeleteTranscript),
  takeEvery(DELETE_TRANSCRIPTS, handleDeleteTranscripts)
];

export { applicantTranscript };
