import { Model, attr } from 'redux-orm';

import { GET_INSTITUTION_ID_TYPES_SUCCESS } from '../actions-type';

class InstitutionIdType extends Model {
  static reducer(action, InstitutionIdType, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_INSTITUTION_ID_TYPES_SUCCESS: {
        const institutionIdTypes = [...payload.institutionIdTypes];
        institutionIdTypes.forEach(type => InstitutionIdType.upsert(type));
        break;
      }

      default:
        return session;
    }
  }
}

InstitutionIdType.fields = {
  uuid: attr(),
  name: attr(),
  published: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

InstitutionIdType.modelName = 'InstitutionIdType';
InstitutionIdType.options = {
  idAttribute: 'uuid'
};

export default InstitutionIdType;
