import React, { useReducer, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import UserRoles from './UserRoles';
import { GENERAL_NOTIFICATION_ERROR } from '../../../../redux/actions-type';
import { doGetUser, doPutRoles } from '../../../../redux/actions/userActions';
import { selectPrograms } from '../../../../redux/selectors/programSelectors';
import { selectSchools } from '../../../../redux/selectors/schoolSelectors';
import { selectUserRoles } from '../../../../redux/selectors/usersSelectors';
import { usePrevious } from '../../../../helpers/hooks/state.hooks';
import { userRolesReducer, userRolesInitial } from './userRolesState';
import { validateRequiredInlist } from '../../../../helpers/validation/validateGeneric';

export default function UserRolesContainer({ userUuid }) {
  const dispatch = useDispatch();
  const [hasErrors, setHasErrors] = useState(false);
  const [openRoleInstitution, setOpenRoleInstitution] = useState(false);
  const [openRoleSchool, setOpenRoleSchool] = useState(false);
  const [openRoleDepartment, setOpenRoleDepartment] = useState(false);
  const [openRoleProgram, setOpenRoleProgram] = useState(false);

  const selectedRole = useSelector(state => state.userState.selectedRole);

  const schools = useSelector(state => selectSchools(state));

  const optionsSchool = _.map(schools, school => ({
    label: school.schoolName,
    value: school.uuid
  }));

  const programs = useSelector(state => selectPrograms(state));

  const optionsProgram = _.map(programs, program => ({
    label: program.programName,
    value: program.uuid
  }));

  const currentRoles = useSelector(
    state => selectUserRoles(state, userUuid),
    _.isEqual
  );

  const currentRolesPrevious = usePrevious(currentRoles);

  const [rolesState, rolesDispatch] = useReducer(
    userRolesReducer,
    userRolesInitial
  );

  const {
    departmentFaculty,
    departmentFacultyRemove,
    departmentStaff,
    departmentStaffRemove,
    institutionAdmin,
    institutionAdminRemove,
    programAdmin,
    programAdminRemove,
    programFaculty,
    programFacultyRemove,
    programStaff,
    programStaffRemove,
    schoolAdmin,
    schoolAdminRemove,
    student
  } = rolesState;

  useEffect(() => {
    if (userUuid) {
      dispatch(doGetUser(userUuid));
    }
  }, [dispatch, userUuid]);

  useEffect(() => {
    function loadInitialState() {
      rolesDispatch({
        type: 'exist',
        currentRoles
      });
    }

    const equalityCheck = !_.isEqual(currentRolesPrevious, currentRoles);

    if (userUuid && equalityCheck && !_.isNil(currentRoles)) {
      loadInitialState();
    }
  }, [userUuid, currentRolesPrevious, currentRoles]);

  const handleRole = (event, uuid, role) => {
    const { name, value } = event.target;

    rolesDispatch({
      type: role,
      payload: {
        uuid,
        modify: { [name]: value }
      }
    });
  };

  const handleRoleDate = (indentifier, field, date, role) => {
    rolesDispatch({
      type: role,
      payload: {
        uuid: indentifier,
        modify: { [field]: date }
      }
    });
  };

  const handleRoleRemove = (uuid, role) => {
    rolesDispatch({
      type: 'remove',
      payload: {
        role,
        uuid
      }
    });
  };

  const _validate = () => {
    let containErrors = false;

    const institutionAdminErrors = validateRequiredInlist(institutionAdmin, [
      'startDate',
      'email',
      'phone',
      'status'
    ]);

    const schoolAdminErrors = validateRequiredInlist(schoolAdmin, [
      'startDate',
      'email',
      'phone',
      'status',
      'schoolUuid'
    ]);

    const departmentFacultyErrors = validateRequiredInlist(departmentFaculty, [
      'startDate',
      'email',
      'phone',
      'status',
      'departmentUuid'
    ]);

    const departmentStaffErrors = validateRequiredInlist(departmentStaff, [
      'startDate',
      'email',
      'phone',
      'status',
      'departmentUuid'
    ]);

    const programAdminErrors = validateRequiredInlist(programAdmin, [
      'startDate',
      'email',
      'phone',
      'status',
      'programUuid'
    ]);

    const programFacultyErrors = validateRequiredInlist(programFaculty, [
      'startDate',
      'email',
      'phone',
      'status',
      'programUuid'
    ]);

    const programStaffErrors = validateRequiredInlist(programStaff, [
      'startDate',
      'email',
      'phone',
      'status',
      'programUuid'
    ]);

    if (
      institutionAdminErrors.invalid ||
      schoolAdminErrors.invalid ||
      departmentFacultyErrors.invalid ||
      departmentStaffErrors.invalid ||
      programAdminErrors.invalid ||
      programStaffErrors.invalid ||
      programFacultyErrors.invalid
    ) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleSaveRoles = () => {
    const reject = _validate();

    if (reject) {
      setHasErrors(true);
    } else {
      switch (selectedRole) {
        case 'institution_admin': {
          dispatch(
            doPutRoles(userUuid, {
              institutionAdmin,
              schoolAdmin,
              institutionAdminRemove,
              schoolAdminRemove
            })
          );
          break;
        }
        case 'school_admin': {
          dispatch(
            doPutRoles(userUuid, {
              schoolAdmin,
              departmentFaculty,
              departmentStaff,
              programAdmin,
              programStaff,
              programFaculty,
              schoolAdminRemove,
              departmentFacultyRemove,
              departmentStaffRemove,
              programAdminRemove,
              programStaffRemove,
              programFacultyRemove
            })
          );
          break;
        }
        case 'program_admin': {
          dispatch(
            doPutRoles(userUuid, {
              programAdmin,
              programStaff,
              programFaculty,
              programAdminRemove,
              programStaffRemove,
              programFacultyRemove
            })
          );
          break;
        }
        default:
          dispatch({
            type: GENERAL_NOTIFICATION_ERROR,
            payload: {
              error:
                'Unable to indentify your role, saving user roles will not be permitted.'
            }
          });
          break;
      }
    }
  };

  const handleRoleInstitutionOpen = () => {
    setOpenRoleInstitution(true);
  };

  const handleRoleInstitutionClose = () => {
    setOpenRoleInstitution(false);
  };

  const handleSchoolRoleOpen = () => {
    setOpenRoleSchool(true);
  };

  const handleSchoolRoleClose = () => {
    setOpenRoleSchool(false);
  };

  const handleDepartmentRoleOpen = () => {
    setOpenRoleDepartment(true);
  };

  const handleDepartmentRoleClose = () => {
    setOpenRoleDepartment(false);
  };

  const handleProgramRoleOpen = () => {
    setOpenRoleProgram(true);
  };

  const handleProgramRoleClose = () => {
    setOpenRoleProgram(false);
  };

  return (
    <UserRoles
      selectedRole={selectedRole}
      userUuid={userUuid}
      hasErrors={hasErrors}
      institutionAdmin={institutionAdmin}
      schoolAdmin={schoolAdmin}
      departmentFaculty={departmentFaculty}
      departmentStaff={departmentStaff}
      programAdmin={programAdmin}
      programStaff={programStaff}
      programFaculty={programFaculty}
      student={student}
      optionsSchool={optionsSchool}
      optionsProgram={optionsProgram}
      openRoleInstitution={openRoleInstitution}
      openRoleSchool={openRoleSchool}
      openRoleDepartment={openRoleDepartment}
      openRoleProgram={openRoleProgram}
      handleRole={handleRole}
      handleRoleDate={handleRoleDate}
      handleRoleRemove={handleRoleRemove}
      handleSaveRoles={handleSaveRoles}
      handleRoleInstitutionOpen={handleRoleInstitutionOpen}
      handleRoleInstitutionClose={handleRoleInstitutionClose}
      handleSchoolRoleOpen={handleSchoolRoleOpen}
      handleSchoolRoleClose={handleSchoolRoleClose}
      handleDepartmentRoleOpen={handleDepartmentRoleOpen}
      handleDepartmentRoleClose={handleDepartmentRoleClose}
      handleProgramRoleOpen={handleProgramRoleOpen}
      handleProgramRoleClose={handleProgramRoleClose}
    />
  );
}

UserRolesContainer.propTypes = {
  userUuid: PropTypes.string
};

UserRolesContainer.defaultProps = {
  userUuid: undefined
};
