import React from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 132px;
  align-items: center;
`;

const Record = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => (props.width ? props.width : '120px')};
`;

export default function AptitudeRowHeader() {
  return (
    <Layout>
      <Record width="80px">Attempt</Record>
      <Record>Date</Record>
      <Record width="300px">Exam Name</Record>
      <Record width="300px">Institution</Record>
      <Record>Exam Weight</Record>
      <Record>Exam Score</Record>
      <Record>Letter Grade</Record>
      <Record>Grade</Record>
      <Record>Score</Record>
      <Record width="66px">&nbsp;</Record>
    </Layout>
  );
}

AptitudeRowHeader.propTypes = {};
AptitudeRowHeader.defaultProps = {};
