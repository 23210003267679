import _ from 'lodash';
import {
  POST_USER_DEPARTMENTS_ASSIGN,
  POST_ASSIGN_FACULTY_STAFF_USERS_SUCCESS,
  POST_ASSIGN_FACULTY_STAFF_USERS_ERROR,
  POST_ASSIGN_FACULTY_STAFF_USERS_WARNING,
  RESET_ASSIGN_USERS
} from '../actions-type';

export const INITIAL_STATE = {
  uploadingDepartmentRoles: false,
  invalid: false,
  departmentRolesCreated: 0
};

const uploadDepartmentRolesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_USER_DEPARTMENTS_ASSIGN: {
      return {
        ...state,
        uploadingDepartmentRoles: true,
        invalid: false
      };
    }
    case POST_ASSIGN_FACULTY_STAFF_USERS_SUCCESS: {
      return {
        ...state,
        uploadingDepartmentRoles: false,
        invalid: false,
        departmentRolesCreated: action.payload.department_roles_created
      };
    }
    case POST_ASSIGN_FACULTY_STAFF_USERS_ERROR: {
      return {
        ...state,
        uploadingDepartmentRoles: false,
        invalid: true,
        error_server: _.get(action, 'payload.error', '')
      };
    }
    case POST_ASSIGN_FACULTY_STAFF_USERS_WARNING: {
      return {
        ...state,
        uploadingDepartmentRoles: false,
        invalid: true,
        ...action.payload.warning
      };
    }
    case RESET_ASSIGN_USERS: {
      return {
        uploadingDepartmentRoles: false,
        invalid: false,
        departmentRolesCreated: 0
      };
    }
    default:
      return state;
  }
};

export default uploadDepartmentRolesReducer;
