import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { classificationScope } from '../../../../../redux/selectors/classificationSelectors';

export default function Scope({ classificationUuid }) {
  const { scope } = useSelector(
    state => classificationScope(state, classificationUuid),
    shallowEqual
  );

  return scope;
}

Scope.propTypes = { classificationUuid: PropTypes.string };
Scope.defaultProps = { classificationUuid: undefined };
