import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

const Layout = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export default function SwitchLabels({ displayName, handleDisplayName }) {
  const [checked, setChecked] = React.useState(true);

  useEffect(() => {
    setChecked(displayName);
  }, [displayName]);

  const handleChange = event => {
    handleDisplayName();
    setChecked(event.target.checked);
  };

  return (
    <Layout>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <div>Display Name</div>
    </Layout>
  );
}

SwitchLabels.propTypes = {
  displayName: PropTypes.bool,
  handleDisplayName: PropTypes.func
};

SwitchLabels.defaultProps = {
  displayName: false,
  handleDisplayName: undefined
};
