import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { doGetCompetencyCollectionsAndScores } from '../../../../redux/actions/competencyCollectionActions';
import CompetencyCollection from './CompetencyCollection';

import { selectCompetencyCollections } from '../../../../redux/selectors/competencyCollectionsSelectors';

export default function CompetencyCollectionContainer({
  userUuid,
  programUuid
}) {
  const dispatch = useDispatch();

  const collections = useSelector(
    state => selectCompetencyCollections(state, programUuid),
    _.isEqual
  );

  const { fetchingCompetencyCollectionsAndScores } = useSelector(
    state => state.crudState,
    _.isEqual
  );

  useEffect(() => {
    if (programUuid && userUuid) {
      dispatch(doGetCompetencyCollectionsAndScores(programUuid, userUuid));
    }
  }, [dispatch, programUuid, userUuid]);

  return (
    <CompetencyCollection
      fetchingCompetencyCollectionsAndScores={
        fetchingCompetencyCollectionsAndScores
      }
      collections={collections}
    />
  );
}

CompetencyCollectionContainer.propTypes = {
  userUuid: PropTypes.string,
  programUuid: PropTypes.string
};

CompetencyCollectionContainer.defaultProps = {
  userUuid: undefined,
  programUuid: undefined
};
