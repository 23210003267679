import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonPin from '@material-ui/icons/PersonPin';
import Cancel from '@material-ui/icons/Cancel';
import {
  ButtonPrimary,
  ButtonOutline,
  Body1,
  Heading3
} from '@xcomp/xcomp-design-library';
import { RemoveIcon } from '@xcomp/xcomp-design-library/dist/icons';

import Modal from '../../../../../../Library/Modal/Modal';
import { StatusPill } from '../../../../../../Library/StatusPill';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 900px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
  margin-bottom: 20px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
`;

const FileUpload = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Note = styled(Body1)`
  margin-top: 10px;
`;

const StatusHeading = styled(Heading3)`
  width: 100%;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const CloseButton = styled(ButtonOutline)`
  && {
    margin-right: 5px;
  }
`;

const ProceedButton = styled(ButtonPrimary)`
  && {
    margin-right: 5px;
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Created = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.white};
  border-radius: 5px;
  padding: 15px;
  background: ${props => props.theme.colors.status.success};
`;

const Warning = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.status.error};
  border-radius: 5px;
  color: ${props => props.theme.colors.white};
  display: flex;
  margin-top: 15px;
  padding: 15px;
`;

const CancelSC = styled(Cancel)`
  color: ${props => props.theme.colors.status.error};
`;

const Message = styled.div`
  color: ${props => props.theme.colors.white};
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  padding-left: 10px;
`;

const RemoveIconSC = styled(RemoveIcon)`
  font-size: 16px;
  margin-right: 8px;
`;

const getModalView = (
  subscription,
  statusGrade,
  statusMessages,
  handleModalClose,
  handleModalSuccess,
  handleForceSubmit,
  handleSubmit,
  onDeleteClick
) => {
  const successfullyGraded =
    statusGrade !== 'NOT_GRADED' && subscription?.status === 'complete';
  const isLoading = subscription && subscription.status === 'grading';
  const isError = subscription && subscription.status === 'errors';

  if (successfullyGraded) {
    return (
      <>
        <Created>
          <PersonPin />
          Successfully graded this Part and its collections.
        </Created>

        <Actions>
          <div>
            <CloseButton onClick={handleModalSuccess}>Close</CloseButton>
          </div>
        </Actions>
      </>
    );
  }

  if (isError) {
    return (
      <>
        <Warning>
          <CancelSC />
          {statusMessages?.length > 0 &&
            statusMessages.map(statusMessage => {
              const someUuid = uuidv4();
              return (
                <Message key={someUuid}>
                  Error: {statusMessage?.message}
                </Message>
              );
            })}
        </Warning>

        <Actions>
          <div>
            <CloseButton onClick={handleModalClose}>Close</CloseButton>
          </div>
          <div>
            <CloseButton onClick={onDeleteClick}>Clear Errors</CloseButton>
          </div>
        </Actions>
      </>
    );
  }

  if (isLoading)
    return (
      <>
        <FileUpload>
          <StatusHeading>Status:</StatusHeading>
          {statusMessages?.length > 0 &&
            statusMessages.map(statusMessage => {
              const someUuid = uuidv4();
              return (
                <Note key={someUuid}>
                  {statusMessage?.status}: {statusMessage?.message}
                </Note>
              );
            })}
        </FileUpload>
        <Loading>
          <CircularProgress size="100px" />
        </Loading>
        <div>
          <Body1>
            Grading taking too long? Click <i>Reset Grading</i> below to reset
            the grading status and try again.
          </Body1>
          <ButtonOutline onClick={onDeleteClick}>
            <RemoveIconSC />
            Reset Grading
          </ButtonOutline>
        </div>
      </>
    );

  return (
    <>
      <FileUpload>
        <Note>
          Clicking <strong>Proceed</strong> below will run grade calculations on
          for this part and all of its grade collections based on their
          respective grading techniques. Are you sure you want to proceed?
        </Note>
      </FileUpload>
      <Actions>
        <div>
          <CloseButton onClick={handleModalClose}>Cancel</CloseButton>
          <ProceedButton
            onClick={() => {
              handleSubmit(false);
            }}
          >
            Proceed
          </ProceedButton>
        </div>
      </Actions>
    </>
  );
};

export default function StatusGradingModal({
  subscription,
  statusGrade,
  statusMessages,
  isModalOpen,
  handleModalClose,
  handleModalSuccess,
  handleSubmit,
  onDeleteClick
}) {
  const handleForceSubmit = () => {
    handleSubmit(true);
  };

  const modalView = getModalView(
    subscription,
    statusGrade,
    statusMessages,
    handleModalClose,
    handleModalSuccess,
    handleForceSubmit,
    handleSubmit,
    onDeleteClick
  );

  const onClickAway =
    subscription?.status === 'complete' ? handleModalSuccess : handleModalClose;

  return (
    <Modal open={isModalOpen} onClose={onClickAway}>
      <ModalBody>
        <ModalHeader>
          <Title>Grade Part</Title>
          <StatusPill status={subscription?.status} />
        </ModalHeader>

        {modalView}
      </ModalBody>
    </Modal>
  );
}

StatusGradingModal.propTypes = {
  isModalOpen: PropTypes.bool,
  statusGrade: PropTypes.string,
  statusMessages: PropTypes.arrayOf(PropTypes.object),
  subscription: PropTypes.object,
  handleModalClose: PropTypes.func,
  handleModalSuccess: PropTypes.func,
  handleSubmit: PropTypes.func,
  onDeleteClick: PropTypes.func
};

StatusGradingModal.defaultProps = {
  isModalOpen: false,
  statusGrade: 'NOT_GRADED',
  statusMessages: [],
  subscription: { status: 'grading' },
  handleModalClose: undefined,
  handleModalSuccess: undefined,
  handleSubmit: undefined,
  onDeleteClick: undefined
};
