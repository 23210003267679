import { Model, attr } from 'redux-orm';
import {
  GET_PROGRAM_DISCIPLINES_SUCCESS,
  GET_SOURCE_PROGRAM_CODES_BY_TITLE_SUCCESS,
  GET_CREDENTIALS_SUCCESS
} from '../actions-type';

class ProgramDiscipline extends Model {
  static reducer(action, ProgramDiscipline, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_PROGRAM_DISCIPLINES_SUCCESS: {
        const programDisciplines = [...payload.programDisciplines];
        programDisciplines.forEach(programDiscipline =>
          ProgramDiscipline.upsert(programDiscipline)
        );
        break;
      }
      case GET_SOURCE_PROGRAM_CODES_BY_TITLE_SUCCESS: {
        const programCodes = [...payload.programCodes];
        programCodes.forEach(programCode => {
          const { program_discipline } = programCode;
          const { uuid, title, code, cipcodeUuid } = program_discipline;
          ProgramDiscipline.upsert({ uuid, title, code, cipcodeUuid });
        });
        break;
      }

      case GET_CREDENTIALS_SUCCESS: {
        const programDisciplines = [...payload.programDisciplines];
        programDisciplines.forEach(programDiscipline =>
          ProgramDiscipline.upsert(programDiscipline)
        );
        break;
      }
      default:
        return session;
    }
  }
}

ProgramDiscipline.fields = {
  uuid: attr(),
  title: attr(),
  code: attr(),
  cipcodeUuid: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

ProgramDiscipline.modelName = 'ProgramDiscipline';
ProgramDiscipline.options = {
  idAttribute: 'uuid'
};

export default ProgramDiscipline;
