import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isEqual, parseISO } from 'date-fns';
import SyllabusEditor from './SyllabusEditor';

import {
  doPatchPartStatus,
  doCompleteStatusCheck,
  doApproveStatusCheck
} from '../../redux/actions/partActions';

import {
  partSelector,
  partsSelectorBySection
} from '../../redux/selectors/partsSelectors';
import { encountersSelector } from '../../redux/selectors/encountersSelectors';
import { assessmentsSelector } from '../../redux/selectors/assessmentSelectors';
import { syllabusCourseSelector } from '../../redux/selectors/syllabusSelectors';
import { syllabusSectionSelector } from '../../redux/selectors/sectionsSelectors';
import { encounterTypesSelector } from '../../redux/selectors/encounterTypesSelectors';
import {
  doSetSyllabus,
  doGetSyllabus,
  doClearCreatedEncounter,
  doClearCreatedAssessment
} from '../../redux/actions/syllabusActions';

const itemTypes = {
  encounter: 'encounter',
  assessment: 'assessment'
};

export default function SyllabusEditorContainer({ match }) {
  const dispatch = useDispatch();
  const part = useSelector(state => partSelector(state), shallowEqual);
  const parts = useSelector(state => partsSelectorBySection(state), _.isEqual);
  const course = useSelector(
    state => syllabusCourseSelector(state),
    shallowEqual
  );
  const section = useSelector(
    state => syllabusSectionSelector(state),
    shallowEqual
  );

  const encounters = useSelector(
    state => encountersSelector(state, match.params.partUuid),
    _.isEqual
  );
  const assessments = useSelector(
    state => assessmentsSelector(state, match.params.partUuid),
    _.isEqual
  );

  const encounterTypes = useSelector(
    state => encounterTypesSelector(state),
    _.isEqual
  );

  const { successfullyCreatedEncounter, successfullyCreatedAssessment } =
    useSelector(state => state.syllabusState, shallowEqual);

  const { newEncounterUuid, newAssessmentUuid } = useSelector(
    state => state.syllabusState,
    shallowEqual
  );

  const activeUser = useSelector(state => state.userState, shallowEqual);

  const department = _.get(course, 'course_master.department', '');
  const academicYear = _.get(course, 'academic_year', '');
  const term = _.get(course, 'term', '');

  const dat = {
    department,
    academicYear,
    term
  };

  const [restrictEdit, setRestrictEdit] = useState(true);
  const [itemId, setItemId] = useState('');
  const [syllabusItemNew, setSyllabusItemNew] = useState(false);
  const [syllabusItemNewType, setSyllabusItemNewType] = useState('');
  const [modalApproveOpen, setModalApproveOpen] = useState(false);
  const [modalCommitOpen, setModalCommitOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterType, setFilterType] = useState('all');

  useEffect(() => {
    const { courseUuid, partUuid, sectionUuid } = match.params;

    dispatch(doGetSyllabus(courseUuid, sectionUuid, partUuid));
    dispatch(doSetSyllabus(courseUuid, sectionUuid, partUuid));
  }, [dispatch, match]);

  useEffect(() => {
    if (successfullyCreatedEncounter) {
      setSyllabusItemNew(false);
      setItemId(newEncounterUuid);
      setSyllabusItemNewType(itemTypes.encounter);
      dispatch(doClearCreatedEncounter());
    }
  }, [dispatch, newEncounterUuid, successfullyCreatedEncounter]);

  useEffect(() => {
    if (successfullyCreatedAssessment) {
      setSyllabusItemNew(false);
      setItemId(newAssessmentUuid);
      setSyllabusItemNewType(itemTypes.assessment);
      dispatch(doClearCreatedAssessment());
    }
  }, [dispatch, newAssessmentUuid, successfullyCreatedAssessment]);

  useEffect(() => {
    if (section && part) {
      if (activeUser.selectedRole === 'school_admin') {
        if (part.status !== 'Committed') {
          setRestrictEdit(false);
        }
      }
    }

    if (section && part) {
      if (
        activeUser.selectedRole === 'department_faculty' ||
        activeUser.selectedRole === 'department_staff'
      ) {
        const sectionDirectors = section.section_directors_assigned.map(
          director => director.uuid
        );

        const partDirectors = part.part_directors_assigned.map(
          director => director.uuid
        );

        const partFaculty = part.part_faculty_assigned.map(
          director => director.uuid
        );

        const users = [...sectionDirectors, ...partDirectors, ...partFaculty];

        const exist = users.includes(activeUser.activeUserUuid);

        if (exist && part.status === 'In Progress') {
          setRestrictEdit(false);
        }
      }
    }
  }, [activeUser, section, part]);

  const getSyllabusItems = () => {
    const allItems = [...encounters, ...assessments];
    const sorted = allItems.sort((a, b) => {
      const aStartDate = a.linkedAssessmentUuid
        ? a.linked_assessment.startDate
        : a.startDate;
      const bStartDate = b.linkedAssessmentUuid
        ? b.linked_assessment.startDate
        : b.startDate;

      const isItemAEncounter = a.type === 'encounter';
      const isItemBEncounter = b.type === 'encounter';

      if (isEqual(aStartDate && parseISO(aStartDate), bStartDate && parseISO(bStartDate))) {
        if (isItemAEncounter && !isItemBEncounter) {
          return -1;
        } else if (!isItemAEncounter && isItemBEncounter) {
          return 1;
        }
      }

      const aSD = aStartDate ? new Date(aStartDate) : 0;
      const bSD = bStartDate ? new Date(bStartDate) : 0;
      if (!aStartDate) {
        return bSD - aSD;
      }
      if (aStartDate === bStartDate) {
        const aTitle = a.title.toLowerCase();
        const bTitle = a.title.toLowerCase();
        return aTitle < bTitle ? 1 : -1;
      }
      return bStartDate ? aSD - bSD : bSD - aSD;
    });

    const filters = `${filterType}-${filterStatus}`;

    switch (filters) {
      case 'all-all': {
        return sorted;
      }
      case 'all-in_progress': {
        return sorted.filter(item => item.status === 'In Progress');
      }
      case 'all-complete': {
        return sorted.filter(item => item.status === 'Complete');
      }
      case 'all-approved': {
        return sorted.filter(item => item.status === 'Approved');
      }
      case 'encounter-all': {
        return sorted.filter(item => item.type === 'encounter');
      }
      case 'assessment-all': {
        return sorted.filter(item => item.type === 'assessment');
      }
      case 'encounter-in_progress': {
        return sorted.filter(
          item => item.type === 'encounter' && item.status === 'In Progress'
        );
      }
      case 'encounter-complete': {
        return sorted.filter(
          item => item.type === 'encounter' && item.status === 'Complete'
        );
      }
      case 'encounter-approved': {
        return sorted.filter(
          item => item.type === 'encounter' && item.status === 'Approved'
        );
      }
      case 'assessment-in_progress': {
        return sorted.filter(
          item => item.type === 'assessment' && item.status === 'In Progress'
        );
      }
      case 'assessment-complete': {
        return sorted.filter(
          item => item.type === 'assessment' && item.status === 'Complete'
        );
      }
      case 'assessment-approved': {
        return sorted.filter(
          item => item.type === 'assessment' && item.status === 'Approved'
        );
      }
      default:
        return sorted;
    }
  };

  const handleModalSubmitSyllabusOpen = () => {
    setModalApproveOpen(true);
  };

  const handleModalSubmitSyllabusClose = () => {
    setModalApproveOpen(false);
  };

  const handleModalApproveSyllabusOpen = () => {
    setModalCommitOpen(true);
  };

  const handleModalApproveSyllabusClose = () => {
    setModalCommitOpen(false);
  };

  const addSyllabusItem = type => {
    setSyllabusItemNew(true);
    setItemId('');
    setSyllabusItemNewType(type);
  };

  const changeItemId = newId => {
    setItemId(newId);
  };

  const handleSubmitForApproval = () => {
    const items = [...encounters, ...assessments];
    const completionCheck = _.every(items, ['status', 'Complete']);

    if (completionCheck) {
      const payload = {
        partUuid: part.uuid,
        changeStatus: 'Ready for Review'
      };

      dispatch(doPatchPartStatus(payload));
    } else {
      dispatch(doCompleteStatusCheck);
    }
  };

  const handleSubmitForCommit = () => {
    const items = [...encounters, ...assessments];
    const completionCheck = _.every(items, ['status', 'Approved']);

    if (completionCheck) {
      const payload = {
        partUuid: part.uuid,
        changeStatus: 'Committed'
      };

      dispatch(doPatchPartStatus(payload));
    } else {
      dispatch(doApproveStatusCheck());
    }
  };

  const handleFilterType = event => {
    const { value } = event.target;
    setFilterType(value);
  };

  const handleFilterStatus = event => {
    const { value } = event.target;
    setFilterStatus(value);
  };

  return (
    <SyllabusEditor
      addSyllabusItem={type => addSyllabusItem(type)}
      changeItemId={newId => changeItemId(newId)}
      course={course}
      syllabusItemNew={syllabusItemNew}
      syllabusItemNewType={syllabusItemNewType}
      dat={dat}
      restrictEdit={restrictEdit}
      encounterTypes={encounterTypes}
      itemId={itemId}
      modalApproveOpen={modalApproveOpen}
      modalCommitOpen={modalCommitOpen}
      part={part}
      partNumber={match.params.partNumber}
      parts={parts}
      section={section}
      sectionNumber={match.params.sectionNumber}
      syllabusItems={getSyllabusItems()}
      filterStatus={filterStatus}
      filterType={filterType}
      handleModalSubmitSyllabusOpen={handleModalSubmitSyllabusOpen}
      handleModalSubmitSyllabusClose={handleModalSubmitSyllabusClose}
      handleModalApproveSyllabusOpen={handleModalApproveSyllabusOpen}
      handleModalApproveSyllabusClose={handleModalApproveSyllabusClose}
      handleSubmitForApproval={handleSubmitForApproval}
      handleSubmitForCommit={handleSubmitForCommit}
      handleFilterType={handleFilterType}
      handleFilterStatus={handleFilterStatus}
    />
  );
}

SyllabusEditorContainer.propTypes = {
  match: PropTypes.object
};

SyllabusEditorContainer.defaultProps = {
  match: {}
};
