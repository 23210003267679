export const GET_SECTION_NESTED_BY_COURSE = 'GET_SECTION_NESTED_BY_COURSE';
export const GET_SECTION_NESTED_BY_COURSE_SUCCESS =
  'GET_SECTION_NESTED_BY_COURSE_SUCCESS';
export const GET_SECTION_NESTED_BY_COURSE_ERROR =
  'GET_SECTION_NESTED_BY_COURSE_ERROR';

export const GET_SECTION = 'GET_SECTION';
export const GET_SECTION_SUCCESS = 'GET_SECTION_SUCCESS';
export const GET_SECTION_ERROR = 'GET_SECTION_ERROR';

export const GET_SECTION_COMMITMENT_LOG = 'GET_SECTION_COMMITMENT_LOG';
export const GET_SECTION_COMMITMENT_LOG_SUCCESS =
  'GET_SECTION_COMMITMENT_LOG_SUCCESS';
export const GET_SECTION_COMMITMENT_LOG_ERROR =
  'GET_SECTION_COMMITMENT_LOG_ERROR';

export const PUT_SECTION_DATASOURCE = 'PUT_SECTION_DATASOURCE';
export const PUT_SECTION_DATASOURCE_SUCCESS = 'PUT_SECTION_DATASOURCE_SUCCESS';
export const PUT_SECTION_DATASOURCE_ERROR = 'PUT_SECTION_DATASOURCE_ERROR';

export const PUT_SECTION_PART_WEIGHTS = 'PUT_SECTION_PART_WEIGHTS';
export const PUT_SECTION_PART_WEIGHTS_SUCCESS =
  'PUT_SECTION_PART_WEIGHTS_SUCCESS';
export const PUT_SECTION_PART_WEIGHTS_ERROR = 'PUT_SECTION_PART_WEIGHTS_ERROR';

export const PATCH_SECTION_CALCULATE_GRADE = 'PATCH_SECTION_CALCULATE_GRADE';
export const PATCH_SECTION_CALCULATE_GRADE_SUCCESS =
  'PATCH_SECTION_CALCULATE_GRADE_SUCCESS';
export const PATCH_SECTION_CALCULATE_GRADE_ERROR =
  'PATCH_SECTION_CALCULATE_GRADE_ERROR';

export const DELETE_SECTION_UNDO_CALCULATED_GRADE =
  'DELETE_SECTION_UNDO_CALCULATED_GRADE';
export const DELETE_SECTION_UNDO_CALCULATED_GRADE_SUCCESS =
  'DELETE_SECTION_UNDO_CALCULATED_GRADE_SUCCESS';
export const DELETE_SECTION_UNDO_CALCULATED_GRADE_ERROR =
  'DELETE_SECTION_UNDO_CALCULATED_GRADE_ERROR';

export const PUT_SECTION_STATUS_COMMIT = 'PUT_SECTION_STATUS_COMMIT';
export const PUT_SECTION_STATUS_COMMIT_SUCCESS =
  'PUT_SECTION_STATUS_COMMIT_SUCCESS';
export const PUT_SECTION_STATUS_COMMIT_ERROR =
  'PUT_SECTION_STATUS_COMMIT_ERROR';

export const PATCH_SECTION_GRADE_DELAYED_STUDENT =
  'PATCH_SECTION_GRADE_DELAYED_STUDENT';
export const PATCH_SECTION_GRADE_DELAYED_STUDENT_SUCCESS =
  'PATCH_SECTION_GRADE_DELAYED_STUDENT_SUCCESS';
export const PATCH_SECTION_GRADE_DELAYED_STUDENT_ERROR =
  'PATCH_SECTION_GRADE_DELAYED_STUDENT_ERROR';

export const DELETE_SECTION_GRADE_DELAYED_STUDENT =
  'DELETE_SECTION_GRADE_DELAYED_STUDENT';
export const DELETE_SECTION_GRADE_DELAYED_STUDENT_SUCCESS =
  'DELETE_SECTION_GRADE_DELAYED_STUDENT_SUCCESS';
export const DELETE_SECTION_GRADE_DELAYED_STUDENT_ERROR =
  'DELETE_SECTION_GRADE_DELAYED_STUDENT_ERROR';

export const PUT_SECTION_DELAYED_STATUS_COMMIT =
  'PUT_SECTION_DELAYED_STATUS_COMMIT';
export const PUT_SECTION_DELAYED_STATUS_COMMIT_SUCCESS =
  'PUT_SECTION_DELAYED_STATUS_COMMIT_SUCCESS';
export const PUT_SECTION_DELAYED_STATUS_COMMIT_ERROR =
  'PUT_SECTION_DELAYED_STATUS_COMMIT_ERROR';
