import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2, ButtonPrimary } from '@xcomp/xcomp-design-library';
import UnsavedChangesModal from '../../../Library/Modal/UnsavedChangesModal/UnsavedChangesModal';
import {
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader,
  ContainerActions
} from '../../../Library/Layout';
import Header from '../../../Library/Header';
import GradingTechniqueForm from '../../../Library/GradingTechniqueForm/GradingTechniqueForm';

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

export default function GradeCalculationSettings({
  assessmentTitle,
  assessmentType,
  courseNumber,
  courseTitle,
  curvedFailingGrade,
  handleChangeRadio,
  handleGradingTechniqueChange,
  handleModalClose,
  handleModalOpen,
  handleProceed,
  isUnsavedChangesModalOpen,
  handleSubmitGradingTechnique,
  lowScoreInput,
  lowScoreCalculated,
  calculatedLowScore,
  maximumMethodCalculated,
  maximumRevusInput,
  calculatedHighScore,
  partNumber,
  scaledHighScore,
  scaledLowScore,
  sectionNumber,
  selectedRadio,
  showValidationErrors,
  unsavedChanges
}) {
  const headerSubtitle = `${courseNumber} ● ${courseTitle} ● Section ${sectionNumber} ● Part ${partNumber} ● ${assessmentType}`;

  return (
    <ContainerResize>
      <UnsavedChangesModal
        modalOpen={isUnsavedChangesModalOpen}
        message="The form has unsaved changes. Click cancel to go back and update the form or click Discard Changes to return to the syllabus management page."
        proceedButtonText="Discard Changes"
        handleProceed={handleProceed}
        handleModalClose={handleModalClose}
      />
      <Header
        title={assessmentTitle}
        subtitle={headerSubtitle}
        backButtonOverride={handleModalOpen}
      />
      <ContainerActions>
        <ButtonPrimary
          onClick={handleSubmitGradingTechnique}
          disabled={Object.keys(unsavedChanges).length < 1}
        >
          Apply
        </ButtonPrimary>
      </ContainerActions>
      <ContainerPage>
        <Panel>
          <PanelHeader paddingLeft="24px">
            <Heading2>Grade Calculation</Heading2>
          </PanelHeader>
          <PanelPadding>
            <GradingTechniqueForm
              allowPointsOnly
              allowCohortHigh
              curvedFailingGrade={curvedFailingGrade}
              handleChangeRadio={handleChangeRadio}
              handleGradingTechniqueChange={handleGradingTechniqueChange}
              lowScoreInput={lowScoreInput}
              lowScoreCalculated={lowScoreCalculated}
              calculatedLowScore={calculatedLowScore}
              maximumMethodCalculated={maximumMethodCalculated}
              maximumRevusInput={maximumRevusInput}
              calculatedHighScore={calculatedHighScore}
              scaledHighScore={scaledHighScore}
              scaledLowScore={scaledLowScore}
              selectedRadio={selectedRadio}
              showValidationErrors={showValidationErrors}
            />
          </PanelPadding>
        </Panel>
      </ContainerPage>
    </ContainerResize>
  );
}

GradeCalculationSettings.propTypes = {
  assessmentTitle: PropTypes.string,
  assessmentType: PropTypes.string,
  calculatedHighScore: PropTypes.string,
  calculatedLowScore: PropTypes.string,
  courseNumber: PropTypes.string,
  courseTitle: PropTypes.string,
  curvedFailingGrade: PropTypes.string,
  handleChangeRadio: PropTypes.func,
  handleGradingTechniqueChange: PropTypes.func,
  handleModalClose: PropTypes.func,
  handleModalOpen: PropTypes.func,
  handleProceed: PropTypes.func,
  handleSubmitGradingTechnique: PropTypes.func,
  isUnsavedChangesModalOpen: PropTypes.bool,
  lowScoreCalculated: PropTypes.bool,
  lowScoreInput: PropTypes.string,
  maximumMethodCalculated: PropTypes.bool,
  maximumRevusInput: PropTypes.string,
  partNumber: PropTypes.string,
  scaledHighScore: PropTypes.string,
  scaledLowScore: PropTypes.string,
  sectionNumber: PropTypes.string,
  selectedRadio: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  unsavedChanges: PropTypes.object
};

GradeCalculationSettings.defaultProps = {
  assessmentTitle: '',
  assessmentType: '',
  calculatedHighScore: '',
  calculatedLowScore: '',
  courseNumber: '',
  courseTitle: '',
  curvedFailingGrade: '',
  handleChangeRadio: undefined,
  handleGradingTechniqueChange: '',
  handleModalClose: undefined,
  handleModalOpen: undefined,
  handleProceed: undefined,
  handleSubmitGradingTechnique: undefined,
  isUnsavedChangesModalOpen: false,
  lowScoreCalculated: true,
  lowScoreInput: '',
  maximumMethodCalculated: true,
  maximumRevusInput: '',
  partNumber: '',
  scaledHighScore: '',
  scaledLowScore: '',
  sectionNumber: '',
  selectedRadio: '',
  showValidationErrors: false,
  unsavedChanges: {}
};
