import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import LetterGradeAdd from './LetterGradeAdd';
import { doPostLetterGrade } from '../../../../../redux/actions/letterGradeActions';

export default function LetterGradeAddContainer({
  selectedSchoolUuid,
  handleAddClose
}) {
  const dispatch = useDispatch();

  const [grade, setGrade] = useState('');
  const [rangeLow, setRangeLow] = useState('0.0');
  const [rangeHigh, setRangeHigh] = useState('0.0');
  const [conversion, setConversion] = useState('0.0');
  const [creditMultiplier, setCreditMultiplier] = useState('0.0');

  const handleChange = event => {
    const { name, value } = event.target;

    switch (name) {
      case 'grade': {
        setGrade(value);
        break;
      }
      case 'rangeLow': {
        setRangeLow(value);
        break;
      }
      case 'rangeHigh': {
        setRangeHigh(value);
        break;
      }
      case 'conversion': {
        setConversion(value);
        break;
      }
      case 'creditMultiplier': {
        setCreditMultiplier(value);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSave = () => {
    dispatch(
      doPostLetterGrade({
        grade,
        rangeLow,
        rangeHigh,
        conversion,
        creditMultiplier,
        schoolUuid: selectedSchoolUuid
      })
    );
  };

  return (
    <LetterGradeAdd
      grade={grade}
      rangeLow={rangeLow}
      rangeHigh={rangeHigh}
      conversion={conversion}
      creditMultiplier={creditMultiplier}
      handleChange={handleChange}
      handleSave={handleSave}
      handleAddClose={handleAddClose}
    />
  );
}

LetterGradeAddContainer.propTypes = {
  selectedSchoolUuid: PropTypes.string,
  handleAddClose: PropTypes.func
};
LetterGradeAddContainer.defaultProps = {
  selectedSchoolUuid: '',
  handleAddClose: undefined
};
