import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/icons/Menu';
import { Caption } from '@xcomp/xcomp-design-library';

import { doSetCurrentRole } from '../../redux/actions/userActions';
import NavigationMenuUser from './NavigationMenuUser';
import logo from '../../assets/logo/logo.png';

import { rolesMap } from '../../helpers/constants';

const AppBarSC = styled(AppBar)`
  && {
    background-color: ${props => props.theme.colors.primary};
    box-shadow: none;
    height: 64px;
    z-index: 1201;
  }
`;

const ToolbarSC = styled(Toolbar)`
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: space-between;
  padding-left: 18px !important;
`;

const Brand = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-left: 19px;
`;

const Image = styled.img`
  height: 50px;
  margin-top: 5px;
  background: ${props => props.isInstitution ? props.theme.colors.primary : props.theme.colors.white};
`;

const UserDetails = styled.div`
  width: 150px;
  padding: 5px;
  margin-left: 5px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: ${props => props.theme.colors.white};
  border-radius: 4px;
`;

const ToolbarSection = styled.div`
  display: flex;
  align-items: center;
`;

const MenuSC = styled(Menu)`
  color: ${props => props.theme.colors.white};
`;

const FullName = styled.div`
  font-size: 14px;
`;

const Role = styled(Caption)`
  margin: 0;
  color: ${props => props.theme.colors.white};
`;

const NavTitle = styled.div`
  font-size: 20px;
  letter-spacing: 0.25px;
  line-height: 24px;
  padding-left: 1rem;
  padding-top: 3px;
`;

export default function NavigationBar({
  user,
  handleUserLogout,
  toggleDrawer
}) {
  const dispatch = useDispatch();

  const { institutionLogo, institutionName, currentRole } = useSelector(
    state => state.userState
  );

  const userRoles = useSelector(state => state.userState.userRoles);

  const changeCurrentRole = role => {
    dispatch(doSetCurrentRole(role));
  };

  useEffect(() => {
    if (!currentRole || !currentRole.role) {
      if (userRoles.institution && userRoles.institution.length > 0) {
        const { institution } = userRoles;
        dispatch(doSetCurrentRole({ ...institution[0] }));
      } else if (userRoles.school && userRoles.school.length > 0) {
        const { school } = userRoles;
        dispatch(doSetCurrentRole({ ...school[0] }));
      } else if (userRoles.department && userRoles.department.length > 0) {
        const { department } = userRoles;
        dispatch(doSetCurrentRole({ ...department[0] }));
      } else if (userRoles.program && userRoles.program.length > 0) {
        const { program } = userRoles;
        dispatch(doSetCurrentRole({ ...program[0] }));
      }
    }
  }, [userRoles, currentRole, dispatch]);

  let institutionLogoUrl = logo;

  if (institutionLogo) {
    institutionLogoUrl = process.env.REACT_APP_USE_IBM_CLOUD === 'true' ? institutionLogo : `data:image/jpeg;base64,${institutionLogo}`;
  }

  return (
    <AppBarSC position="fixed">
      <ToolbarSC>
        <ToolbarSection>
          <MenuSC
            data-testid="navigation-toggle"
            onClick={() => {
              toggleDrawer();
            }}
          />
          <Brand>
            <Link to="/">
              <Image
                isInstitution={!!institutionLogo}
                src={institutionLogoUrl}
                alt="Logo"
              />
            </Link>
            <NavTitle>{institutionName || 'XCOMP IPM'}</NavTitle>
          </Brand>
        </ToolbarSection>
        <ToolbarSection>
          <UserDetails>
            <FullName>
              {user.firstName} {user.lastName}
            </FullName>
            {currentRole && <Role>{rolesMap[currentRole.role]}</Role>}
          </UserDetails>
          <NavigationMenuUser
            user={user}
            handleUserLogout={handleUserLogout}
            currentRole={currentRole}
            changeCurrentRole={changeCurrentRole}
          />
        </ToolbarSection>
      </ToolbarSC>
    </AppBarSC>
  );
}

NavigationBar.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/no-unused-prop-types
  currentRole: PropTypes.object,
  handleUserLogout: PropTypes.func,
  toggleDrawer: PropTypes.func
};

NavigationBar.defaultProps = {
  user: { firstName: '', lastName: '' },
  currentRole: {},
  handleUserLogout: undefined,
  toggleDrawer: undefined
};
