import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { sectionSelector } from '../../../../redux/selectors/sectionsSelectors';
import Section from './Section';
import {
  doSetOpenSectionAccordion,
  doSetClosedSectionAccordion
} from '../../../../redux/actions/filterActions';

export default function SectionContainer({
  courseUuid,
  sectionUuid,
  onFetchSection
}) {
  const dispatch = useDispatch();

  const section = useSelector(
    state => sectionSelector(state, sectionUuid),
    _.isEqual
  );

  const openSectionAccordions = useSelector(
    state => state.filterCourseState.openSectionAccordions
  );

  const onRowOpen = () => {
    dispatch(doSetOpenSectionAccordion(sectionUuid));
  };

  const onRowClose = () => {
    dispatch(doSetClosedSectionAccordion(sectionUuid));
  };

  return (
    <Section
      sectionUuid={section.uuid}
      openSectionAccordions={openSectionAccordions}
      onRowOpen={onRowOpen}
      onRowClose={onRowClose}
      parts={section.parts}
      section={section}
      directors={section.section_directors_assigned}
      sectionNumber={section.sectionNumber}
      courseUuid={courseUuid}
      onFetchSection={onFetchSection}
    />
  );
}

SectionContainer.propTypes = {
  courseUuid: PropTypes.string,
  onFetchSection: PropTypes.func,
  sectionUuid: PropTypes.string
};

SectionContainer.defaultProps = {
  courseUuid: '',
  onFetchSection: undefined,
  sectionUuid: ''
};
