import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import moment from 'moment';
import { ButtonOutline, Body1 } from '@xcomp/xcomp-design-library';
import Tooltip from '@material-ui/core/Tooltip';

import { Link } from 'react-router-dom';
// import SettingsIcon from '@material-ui/icons/Settings';
// import { gradingTechniquesMap } from '../../../helpers/constants';

import {
  ItemLeft,
  ItemType,
  ItemDetails,
  ItemDetail,
  DetailsListItem,
  Title,
  Label,
  Faculty
} from '../../Library/SyllabusComponents';
import ItemDatesBlock from '../ItemDatesBlock';

// const ButtonIconSC = styled(ButtonIcon)`
//   svg {
//     font-size: 20px;
//   }
// `;

export const DetailsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ItemLayoutCustom = styled.div`
  min-height: 250px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 2px solid ${props => props.theme.colors.grey[300]};
`;

export const ItemRightCustom = styled(({ borderColor, ...rest }) => (
  <div {...rest} />
))`
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const GradeRow = styled(({ settings, ...rest }) => <div {...rest} />)`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: ${props => (props.settings ? 'flex-end' : 'initial')};
  margin-top: 1.25rem;
`;

const ItemAction = styled.div`
  width: 56%;
`;

const Field = styled(({ half, ...rest }) => <div {...rest} />)`
  width: ${props => (props.half ? '22%' : '44%')};
  display: flex;
  flex-direction: column;
  padding-right: 8px;
`;

const Output = styled(Body1)`
  color: ${props => props.theme.fontColors.darker};
  margin: 0;
`;

const AssessmentActionButton = styled(ButtonOutline)`
  && {
    width: 100%;
    justify-content: center;
  }
`;

export default function SyllabusAssessment({
  itemNumber,
  cohortUuid,
  // programUuid,
  cohortAssessmentCollectionUuid,
  assessment,
  // assessmentManager,
  assessmentType,
  // gradingTechnique,
  responsibleFaculty,
  assessmentManager
}) {
  const themeContext = useContext(ThemeContext);

  const startDate = assessment.startDate ? moment(assessment.startDate) : null;
  const endDate = assessment.endDate ? moment(assessment.endDate) : null;

  const scoresPath = `/cohort-syllabus-management/view/scores/cohort/${cohortUuid}/cohort-assessment-collection/${cohortAssessmentCollectionUuid}/cohort-assessment/${assessment.uuid}/type/${assessment.assessmentTypeUuid}/steps`;

  const isGradeSummaryAvailable = true;

  const completionPercentage =
    assessment.scoreType === 'Opportunity'
      ? 'N/A'
      : `${assessment.completionPercentage}%`;

  return (
    <ItemLayoutCustom>
      <ItemLeft borderColor={themeContext.colors.primary}>
        <ItemType>
          Assessment&nbsp;
          {itemNumber.toString().padStart(2, 0)}&nbsp;● {assessmentType}
        </ItemType>
        <Title>{assessment.title}</Title>
        <ItemDatesBlock startDate={startDate} endDate={endDate} />
        <ItemDetails>
          <DetailsList>
            <DetailsListItem>
              <Faculty />
              <ItemDetail>{responsibleFaculty}</ItemDetail>
            </DetailsListItem>
            <DetailsListItem>
              <Faculty />
              <ItemDetail>{assessmentManager}</ItemDetail>
            </DetailsListItem>
          </DetailsList>
        </ItemDetails>
      </ItemLeft>

      <ItemRightCustom>
        {/* <GradeRow settings>
          <Link
            to={`/cohort-syllabus-management/assessment/status-settings/${assessment.uuid}`}
          >
            <ButtonIconSC noHoverBg>
              <SettingsIcon />
            </ButtonIconSC>
          </Link>
        </GradeRow> */}
        <GradeRow>
          <Field half>
            <Label>Status</Label>
            <Output>
              {assessment.statusScore === 'Close' ? 'Closed' : 'Open'}
            </Output>
          </Field>

          <Field half>
            <Label>Completion&nbsp;%</Label>
            <Output>{completionPercentage}</Output>
          </Field>

          <ItemAction>
            <Link to={scoresPath}>
              <AssessmentActionButton>Scores</AssessmentActionButton>
            </Link>
          </ItemAction>
        </GradeRow>

        {/* <GradeRow>
          <Field>
            <Label>Calculation Type</Label>
            <Output>
              {gradingTechniquesMap[gradingTechnique?.gradingTechnique]}
            </Output>
          </Field>

          <ItemAction>
            <Link
              to={`/syllabus-management/assessment/grade-calculations/${assessment.uuid}`}
            >
              <AssessmentActionButton>Grade Calculation</AssessmentActionButton>
            </Link>
          </ItemAction>
        </GradeRow> */}

        <GradeRow>
          <Field>
            <Label>Commitment Status</Label>
            <Output>{assessment.statusCommit}</Output>
          </Field>
          <ItemAction>
            {isGradeSummaryAvailable ? (
              <Link
                to={`/program-management/cohort/${cohortUuid}/cohort-assessment-collection/${cohortAssessmentCollectionUuid}/assessment/${assessment.uuid}/grade-summary`}
              >
                <AssessmentActionButton>Grade Summary</AssessmentActionButton>
              </Link>
            ) : (
              <Tooltip
                title="Close and commit this assessment to access the grade summary."
                placement="top-start"
              >
                <div>
                  <AssessmentActionButton disabled>
                    Grade Summary
                  </AssessmentActionButton>
                </div>
              </Tooltip>
            )}
          </ItemAction>
        </GradeRow>
      </ItemRightCustom>
    </ItemLayoutCustom>
  );
}

SyllabusAssessment.propTypes = {
  assessment: PropTypes.object,
  // gradingTechnique: PropTypes.object,
  assessmentManager: PropTypes.string,
  responsibleFaculty: PropTypes.string,
  assessmentType: PropTypes.string,
  cohortUuid: PropTypes.string,
  // programUuid: PropTypes.string,
  cohortAssessmentCollectionUuid: PropTypes.string,
  itemNumber: PropTypes.number.isRequired
};

SyllabusAssessment.defaultProps = {
  assessment: {},
  // gradingTechnique: {},
  assessmentManager: '',
  responsibleFaculty: '',
  assessmentType: '',
  cohortUuid: '',
  // programUuid: '',
  cohortAssessmentCollectionUuid: ''
};
