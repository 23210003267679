import fetch from '../helpers/fetch';

const postEncounter = encounter =>
  fetch('/api/encounter/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(encounter)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postCalculateLinkedEncounterObjectives = encounterUuid =>
  fetch(`/api/encounter/${encounterUuid}/objectives`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putEncounterDraft = encounter =>
  fetch(`/api/encounter/${encounter.uuid}/draft`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(encounter)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putEncounterFinal = encounter =>
  fetch(`/api/encounter/${encounter.uuid}/final`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(encounter)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchEncounterApprove = encounterUuid =>
  fetch(`/api/encounter/${encounterUuid}/approval`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteEncounter = encounterUuid =>
  fetch(`/api/encounter/${encounterUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  postEncounter,
  postCalculateLinkedEncounterObjectives,
  putEncounterDraft,
  putEncounterFinal,
  deleteEncounter,
  patchEncounterApprove
};
