import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectInstitutionAverageScore } from '../../../../redux/selectors/transcriptCognitiveSelectors';

export default function InstitutionAvgScore({
  programUuid,
  userUuid,
  cohortUuid,
  source,
  sourceInstitutionUuid
}) {
  const cognitiveScore = useSelector(state =>
    selectInstitutionAverageScore(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      source,
      sourceInstitutionUuid
    )
  );

  const score = cognitiveScore || 'N/A';

  return <>{score}</>;
}

InstitutionAvgScore.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  source: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string
};

InstitutionAvgScore.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  source: undefined,
  sourceInstitutionUuid: undefined
};
