import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { StickyTitleTd, TableDataText } from '../CollectionReportSC';
import { partGradeResultSelector } from '../../../../../redux/selectors/gradeResultSelectors';
import ActionButton from '../../../../Library/AccordionRow/ActionButton';

const DelayedStudentPartGradeData = ({
  evenStudent,
  partUuid,
  studentUuid,
  actions
}) => {
  const partGradeResult = useSelector(state =>
    partGradeResultSelector(state, partUuid, studentUuid)
  );

  return (
    <>
      <StickyTitleTd
        redOne={evenStudent}
        redTwo={!evenStudent}
        left="0px"
        borderRight
      >
        <TableDataText>{partGradeResult?.calculatedGrade}</TableDataText>
      </StickyTitleTd>
      <StickyTitleTd
        redOne={evenStudent}
        redTwo={!evenStudent}
        left="75px"
        borderRight
      >
        <TableDataText>{partGradeResult?.letterGrade}</TableDataText>
      </StickyTitleTd>
      <StickyTitleTd
        redOne={evenStudent}
        redTwo={!evenStudent}
        left="150px"
        borderRight
      >
        <TableDataText>
          <ActionButton actions={actions} isRowOpen={false} />
        </TableDataText>
      </StickyTitleTd>
    </>
  );
};

DelayedStudentPartGradeData.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool
  ]),
  evenStudent: PropTypes.bool.isRequired,
  partUuid: PropTypes.string.isRequired,
  studentUuid: PropTypes.string.isRequired
};

DelayedStudentPartGradeData.defaultProps = { actions: false };

export default DelayedStudentPartGradeData;
