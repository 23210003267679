import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { colors } from '@xcomp/xcomp-design-library';
import Tooltip from '@material-ui/core/Tooltip';

const BlockTitles = styled.div`
  padding-left: 20px;
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
  margin-top: 2rem;
`;

const BlockTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width};
  ${props => props.autoWidth && `width: calc((100% - 20% - 230px)/2);`}
  font-weight: 600;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-bottom: 10px;
`;

const AssessmentRow = styled.div`
  padding-left: 20px;
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
`;

const Input = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${props => props.width};
  ${props => props.autoWidth && `width: calc((100% - 20% - 230px)/2);`}
  padding-right: 10px;
`;

const ChipSC = styled(Chip)`
  && {
    background: ${props => props.theme.colors.status.error};
    color: ${props => props.theme.colors.white};
    text-transform: capitalize;
  }
`;

const ContainerLabel = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  padding-left: 20px;
`;

const Remaining = ({ assessments, completionPercentage }) => {
  return (
    <div style={{ marginBottom: '50px' }}>
      <ContainerLabel>
        <Typography variant="body">Remaining</Typography>
        <Chip
          label={`${completionPercentage}%`}
          variant="default"
          style={{ color: colors.white, backgroundColor: colors.red[500] }}
        />
        <hr style={{ width: '100%' }} color={colors.grey[300]} />
      </ContainerLabel>
      <BlockTitles>
        <BlockTitle autoWidth>Assessment</BlockTitle>
        <BlockTitle width="50px">Block</BlockTitle>
        <BlockTitle autoWidth>Block Title</BlockTitle>
        <BlockTitle width="10%">Status</BlockTitle>
        <BlockTitle width="80px">Date</BlockTitle>
        <BlockTitle width="80px">Attempt</BlockTitle>
        <BlockTitle width="80px">Score</BlockTitle>
        <BlockTitle width="80px">Grade</BlockTitle>
      </BlockTitles>

      {assessments.map(assessment => {
        if (assessment.assessment_blocks.length > 0) {
          return assessment.assessment_blocks.map(block => (
            <AssessmentRow key={block?.uuid}>
              <Input autoWidth>
                <Tooltip arrow title={assessment.name} placement="top">
                  <span>{assessment.name}</span>
                </Tooltip>
              </Input>

              <Input width="50px">{block?.blockNumber}</Input>
              <Input autoWidth>
                <Tooltip arrow title={block.name} placement="top">
                  <span>{block?.name}</span>
                </Tooltip>
              </Input>
              <Input width="10%">
                <ChipSC size="small" label="Remaining" />
              </Input>
              <Input width="80px">--</Input>
              <Input width="80px">--</Input>
              <Input width="80px">--</Input>
              <Input width="80px">--</Input>
            </AssessmentRow>
          ));
        }

        return (
          <AssessmentRow key={assessment?.uuid}>
            <Input autoWidth>
              <Tooltip arrow title={assessment.name} placement="top">
                <span>{assessment.name}</span>
              </Tooltip>
            </Input>

            <Input width="50px">--</Input>
            <Input autoWidth>--</Input>
            <Input width="10%">
              <ChipSC size="small" label="Remaining" />
            </Input>
            <Input width="80px">--</Input>
            <Input width="80px">--</Input>
            <Input width="80px">--</Input>
            <Input width="80px">--</Input>
          </AssessmentRow>
        );
      })}
    </div>
  );
};

Remaining.propTypes = {
  assessments: PropTypes.array,
  completionPercentage: PropTypes.number
};

Remaining.defaultProps = {
  assessments: [],
  completionPercentage: 0
};

export default Remaining;
