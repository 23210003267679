import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_PROGRAM_SUCCESS,
  GET_TRANSCRIPT_SUCCESS,
  GET_COHORT_SUCCESS,
  GET_PROGRAM_SUCCESS,
  GET_PROGRAM_ASSESSMENTS_SUCCESS,
  GET_PROGRAM_ASSESSMENT_SUCCESS,
  GET_PROGRAMS_SUCCESS,
  GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS,
  GET_USER_ACTIVE_SUCCESS,
  GET_USER_SUCCESS,
  POST_PROGRAM_SUCCESS,
  PUT_PROGRAM_SUCCESS,
  GET_HIERARCHY_SUCCESS,
  GET_USERS_BY_SEARCH_ROLES_SUCCESS,
  GET_APPLICANTS_BY_PROGRAM_SUCCESS
} from '../actions-type';

class Program extends Model {
  static reducer(action, Program, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_USER_ACTIVE_SUCCESS: {
        if (payload?.roles?.program_admin?.length > 0) {
          const userPrograms = [...payload.roles.program_admin];
          userPrograms.forEach(userProgram => {
            const { program } = userProgram;
            Program.upsert(program);
          });
        }

        if (payload?.roles?.program_staff?.length > 0) {
          const userPrograms = [...payload.roles.program_staff];
          userPrograms.forEach(userProgram => {
            const { program } = userProgram;
            Program.upsert(program);
          });
        }

        if (payload?.roles?.program_faculty?.length > 0) {
          const userPrograms = [...payload.roles.program_faculty];
          userPrograms.forEach(userProgram => {
            const { program } = userProgram;
            Program.upsert(program);
          });
        }

        break;
      }
      case GET_PROGRAM_SUCCESS: {
        const program = { ...payload.program };
        Program.upsert(program);
        break;
      }
      case GET_PROGRAM_ASSESSMENT_SUCCESS:
      case GET_PROGRAM_ASSESSMENTS_SUCCESS: {
        const program = { ...payload.program };
        Program.upsert(program);
        break;
      }
      case GET_PROGRAMS_SUCCESS: {
        const programs = [...payload.programs];
        programs.forEach(program => Program.upsert(program));
        break;
      }
      case GET_USER_SUCCESS: {
        const programs = [...payload.programs];
        programs.forEach(program => Program.upsert(program));
        break;
      }
      case POST_PROGRAM_SUCCESS: {
        const program = { ...payload.program };
        Program.upsert(program);
        break;
      }
      case PUT_PROGRAM_SUCCESS: {
        const program = { ...payload.program };
        Program.upsert(program);
        break;
      }
      case DELETE_PROGRAM_SUCCESS: {
        const program = { ...payload };
        Program.withId(program.programUuid).delete();
        break;
      }
      case GET_COHORT_SUCCESS: {
        const program = { ...payload.cohort.program };
        Program.upsert(program);
        break;
      }
      case GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS: {
        const programs = [...payload.school.programs];
        programs.forEach(program => {
          const programCopy = { ...program };
          delete programCopy.cohorts;
          Program.upsert(program);
        });
        break;
      }
      case GET_TRANSCRIPT_SUCCESS: {
        const program = { ...payload.program };
        Program.upsert(program);
        break;
      }
      case GET_HIERARCHY_SUCCESS: {
        const programs = [...payload.programs];
        programs.forEach(program => Program.upsert(program));
        break;
      }
      case GET_USERS_BY_SEARCH_ROLES_SUCCESS: {
        if (payload?.programs?.length > 0) {
          const programs = [...payload.programs];
          programs.forEach(program => Program.upsert(program));
        }
        break;
      }

      case GET_APPLICANTS_BY_PROGRAM_SUCCESS: {
        const userCohorts = [...payload.userCohorts];

        if (userCohorts?.length > 0) {
          userCohorts.forEach(userCohort => {
            const { cohort } = userCohort;

            if (cohort) {
              const { program } = cohort;

              const {
                uuid,
                programName,
                programAbbreviation,
                degreeAbbreviation,
                degreeName,
                programCip,
                programDiscipline,
                programCode,
                degreeDesignation,
                degreeDescription,
                institutionAA,
                programAA,
                createdAt,
                updatedAt,
                schoolUuid
              } = program;

              if (program) {
                Program.upsert({
                  uuid,
                  programName,
                  programAbbreviation,
                  degreeAbbreviation,
                  degreeName,
                  programCip,
                  programDiscipline,
                  programCode,
                  degreeDesignation,
                  degreeDescription,
                  institutionAA,
                  programAA,
                  createdAt,
                  updatedAt,
                  schoolUuid
                });
              }
            }
          });
        }

        break;
      }
      default:
        return session;
    }
  }
}

Program.fields = {
  uuid: attr(),
  programName: attr(),
  programAbbreviation: attr(),
  degreeAbbreviation: attr(),
  degreeName: attr(),
  degreeCode: attr(),
  degreeDesignation: attr(),
  degreeDescription: attr(),
  institutionAA: attr(),
  programAA: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  schoolUuid: fk({
    to: 'School',
    as: 'school',
    relatedName: 'programs'
  })
};

Program.modelName = 'Program';
Program.options = {
  idAttribute: 'uuid'
};

export default Program;
