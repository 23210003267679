import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTz from 'moment-timezone';
import _ from 'lodash';

import { validateSelectField } from '../../../../helpers/validation/validateGeneric';
import { filterUniqueOptionsByLabel } from '../../../../helpers/utilities';
import {
  validateAssessmentStartDate,
  validateAssessmentEndDate,
  validateAssessmentTitle
} from '../../../../helpers/validation/validateAssessment';

import { assessmentsSelector } from '../../../../redux/selectors/assessmentSelectors';
import {
  assessmentTypesSelector,
  assessmentTechniquesSelector,
  assessmentAssociationsSelector
} from '../../../../redux/selectors/assessmentFieldSelectors';

import {
  doPostAssessment,
  doPutAssessmentDraft,
  doPutAssessmentFinal,
  doDeleteAssessment,
  doPatchAssessmentApprove
} from '../../../../redux/actions/assessmentActions';
import { doClearUpdateAssessment } from '../../../../redux/actions/syllabusActions';

import {
  assessmentTypesMap,
  scoreType as scoreTypeList
} from '../../../../helpers/constants';

import AssessmentForm from './AssessmentForm';

export default function AssessmentFormContainer({
  restrictEdit,
  dat,
  existingItem,
  facultyOptions
}) {
  const dispatch = useDispatch();
  const guessTimeZone = momentTz.tz.guess();
  const [assessmentUuid, setAssessmentUuid] = useState(null);
  const [status, setStatus] = useState('In Progress');
  const [assessmentTypeUuid, setAssessmentTypeUuid] = useState(null);
  const [assessmentAssociationUuid, setAssessmentAssociationUuid] =
    useState(null);
  const [assessmentTechniqueUuid, setAssessmentTechniqueUuid] = useState(null);
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [timespan, setTimespan] = useState(null);
  const [responsibleFaculty, setResponsibleFaculty] = useState('');
  const [assessmentManager, setAssessmentManager] = useState('');
  const [location, setLocation] = useState('');
  const [scoreType, setScoreType] = useState('');
  const [existing, setExisting] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fieldInFocus, setFieldInFocus] = useState('');
  const [assessmentIndex, setAssessmentIndex] = useState(0);
  const [optionsFaculty, setOptionsFaculty] = useState([]);
  const [optionsAssessmentTypes, setAssessmentTypes] = useState([]);
  const [optionsAssessmentTechniques, setAssessmentTechniques] = useState([]);
  const [optionsAssessmentAssociations, setAssessmentAssociations] = useState(
    []
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [legacyScores, setLegacyScores] = useState(false);

  const assessments = useSelector(
    state => assessmentsSelector(state, state.syllabusState.selectedPartUuid),
    _.isEqual
  );
  const assessmentTypes = useSelector(
    state => assessmentTypesSelector(state),
    _.isEqual
  );
  const assessmentTechniques = useSelector(
    state => assessmentTechniquesSelector(state),
    _.isEqual
  );
  const assessmentAssociations = useSelector(
    state => assessmentAssociationsSelector(state),
    _.isEqual
  );

  const { successfullyUpdatedAssessment } = useSelector(
    state => state.syllabusState,
    shallowEqual
  );
  const courseUuid = useSelector(
    state => state.syllabusState.selectedCourseUuid
  );
  const sectionUuid = useSelector(
    state => state.syllabusState.selectedSectionUuid
  );
  const partUuid = useSelector(state => state.syllabusState.selectedPartUuid);

  const setExistingAssessment = existingItem => {
    setExisting(true);
    setAssessmentUuid(existingItem.uuid);
    setStatus(existingItem.status);
    setAssessmentTypeUuid(existingItem.assessmentTypeUuid);
    setAssessmentAssociationUuid(existingItem.assessmentAssociationUuid);
    setAssessmentTechniqueUuid(existingItem.assessmentTechniqueUuid);
    setScoreType(existingItem.scoreType);
    setTitle(existingItem.title);
    setStartDate(existingItem.startDate);
    setEndDate(existingItem.endDate);
    setTimespan(existingItem.timespan);
    setResponsibleFaculty(existingItem.responsibleFaculty);
    setAssessmentManager(existingItem.assessmentManager);
    setLocation(existingItem.location);
    setLegacyScores(existingItem.legacyScores);
    setFieldInFocus('');
    setHasUnsavedChanges(false);
  };

  useEffect(() => {
    if (assessmentTypes) {
      const assessmentTypeOptions = _.map(assessmentTypes, aType => ({
        value: aType.uuid,
        label: aType.name,
        order: aType.order
      })).sort((a, b) => a.order - b.order);

      const filteredAssessmentTypeOptions = filterUniqueOptionsByLabel(
        assessmentTypeOptions
      );

      setAssessmentTypes(filteredAssessmentTypeOptions);
    }
  }, [assessmentTypes]);

  useEffect(() => {
    if (assessmentTechniques) {
      const assessmentTechniqueOptions = _.map(assessmentTechniques, aTech => ({
        value: aTech.uuid,
        label: aTech.technique
      }));

      const filteredAssessmentTechniqueOptions = filterUniqueOptionsByLabel(
        assessmentTechniqueOptions
      );

      setAssessmentTechniques(filteredAssessmentTechniqueOptions);
    }
  }, [assessmentTechniques]);

  useEffect(() => {
    if (assessmentAssociations) {
      const assessmentAssociationOptions = _.map(
        assessmentAssociations,
        association => ({
          value: association.uuid,
          label: association.association
        })
      );

      const filteredAssessmentAssociationOptions = filterUniqueOptionsByLabel(
        assessmentAssociationOptions
      );

      setAssessmentAssociations(filteredAssessmentAssociationOptions);
    }
  }, [assessmentAssociations]);

  useEffect(() => {
    const faculty = facultyOptions
      ? facultyOptions.map(user => ({
          value: user.uuid,
          label: `${user.firstName} ${user.lastName}`
        }))
      : [];

    setOptionsFaculty(faculty);
  }, [facultyOptions]);

  useEffect(() => {
    if (existingItem) {
      setHasUnsavedChanges(false);
      setExistingAssessment(existingItem);
    } else {
      setHasUnsavedChanges(false);
      setExisting(false);
      setAssessmentUuid(null);
      setStatus('In Progress');
      setTitle('Untitled Assessment');
      setScoreType(null);
      setStartDate(null);
      setEndDate(null);
      setTimespan(null);
      setAssessmentTypeUuid(null);
      setAssessmentAssociationUuid(null);
      setAssessmentTechniqueUuid(null);
      setResponsibleFaculty(null);
      setAssessmentManager(null);
      setLocation('');
      setLegacyScores(false);
      setFieldInFocus('');
    }
  }, [existingItem]);

  useEffect(() => {
    if (successfullyUpdatedAssessment) {
      dispatch(doClearUpdateAssessment());
    }
  }, [dispatch, successfullyUpdatedAssessment]);

  useEffect(() => {
    if (existingItem && assessments) {
      const sort = assessments.sort(
        (a, b) => moment(a.startDate) - moment(b.startDate)
      );

      const location =
        _.findIndex(sort, item => item.uuid === existingItem.uuid) + 1;
      setAssessmentIndex(location);
    }
  }, [existingItem, assessments]);

  function buildRequest() {
    const convertStartDate = startDate
      ? momentTz(startDate).tz(guessTimeZone)
      : null;
    const convertEndDate = endDate ? momentTz(endDate).tz(guessTimeZone) : null;

    const payload = {
      partUuid,
      uuid: assessmentUuid,
      assessmentAssociationUuid,
      assessmentManager,
      assessmentTechniqueUuid,
      assessmentTypeUuid,
      endDate: convertEndDate,
      location,
      responsibleFaculty,
      scoreType,
      legacyScores,
      startDate: convertStartDate,
      status,
      timespan,
      title
    };

    return payload;
  }

  function handleAssessmentSaveDraft() {
    const payload = buildRequest();
    setHasErrors(false);

    function createOrUpdate() {
      if (existing) {
        dispatch(
          doPutAssessmentDraft(payload, courseUuid, sectionUuid, partUuid)
        );
      } else {
        dispatch(doPostAssessment(payload, courseUuid, sectionUuid, partUuid));
      }
    }

    createOrUpdate();
  }

  function validate() {
    const term = _.get(dat, 'term');

    const assessmentTypeUuidError = validateSelectField(assessmentTypeUuid);
    const assessmentAssociationUuidError = validateSelectField(
      assessmentAssociationUuid
    );
    const assessmentTechniqueUuidError = validateSelectField(
      assessmentTechniqueUuid
    );
    const titleError = validateAssessmentTitle(title);
    const startDateError = validateAssessmentStartDate(startDate, term);
    const endDateError = validateAssessmentEndDate(endDate, term);
    const responsibleFacultyError = validateSelectField(responsibleFaculty);
    const assessmentManagerError = validateSelectField(assessmentManager);

    const scoreTypeError = validateSelectField(scoreType);

    return {
      assessmentTypeUuid: assessmentTypeUuidError,
      assessmentAssociationUuid: assessmentAssociationUuidError,
      assessmentTechniqueUuid: assessmentTechniqueUuidError,
      title: titleError,
      startDate: startDateError,
      endDate: endDateError,
      responsibleFaculty: responsibleFacultyError,
      assessmentManager: assessmentManagerError,
      scoreType: scoreTypeError
    };
  }

  function passValidation(validationErrors) {
    const errorList = _.mapValues(validationErrors, value => value.invalid);
    const invalids = _.map(errorList, value => value);
    const check = _.filter(invalids, value => value === true);

    if (check.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  function handleAssessmentFinish() {
    const errors = validate();
    const payload = buildRequest();

    if (passValidation(errors)) {
      if (status === 'In Progress' || status === 'Approved') {
        dispatch(
          doPutAssessmentFinal(payload, courseUuid, sectionUuid, partUuid)
        );

        setHasErrors(false);
        setFieldInFocus('');
      } else {
        setHasErrors(false);
        setFieldInFocus('');
      }
    } else {
      setHasErrors(true);
    }
  }

  function handleAssessmentChange(event) {
    const { name, value } = event.target;

    setHasUnsavedChanges(true);
    setStatus('In Progress');

    switch (name) {
      case 'status':
        setStatus(value);
        break;
      case 'assessmentTypeUuid': {
        setAssessmentTypeUuid(value);

        const type = _.find(assessmentTypes, type => type.uuid === value);

        if (type.name === assessmentTypesMap.quantitativeSkills) {
          setScoreType(scoreTypeList.opportunity);
        } else {
          setScoreType(scoreTypeList.attempt);
        }

        break;
      }
      case 'assessmentAssociationUuid':
        setAssessmentAssociationUuid(value);

        break;
      case 'assessmentTechniqueUuid':
        setAssessmentTechniqueUuid(value);
        break;
      case 'title':
        setTitle(value);
        break;
      case 'scoreType':
        setScoreType(value);
        break;
      case 'timespan':
        setTimespan(value);
        break;
      case 'responsibleFaculty':
        setResponsibleFaculty(value);
        break;
      case 'location':
        setLocation(value);
        break;
      default:
        break;
    }
  }

  function handleDateChange(fieldName, date) {
    switch (fieldName) {
      case 'startDate':
        setStartDate(date);
        break;
      case 'endDate':
        setEndDate(date);
        break;
      default:
        break;
    }
  }

  function handleUserFieldChange(values, fieldName) {
    switch (fieldName) {
      case 'responsibleFaculty':
        setResponsibleFaculty(values);
        break;
      case 'assessmentManager':
        setAssessmentManager(values);
        break;
      default:
        break;
    }
  }

  const handleLegacyScores = () => {
    setLegacyScores(!legacyScores);
  };

  function handleDeleteOpen() {
    setOpenModal(true);
  }

  function handleDeleteClose(shouldDelete) {
    if (shouldDelete) {
      dispatch(doDeleteAssessment(assessmentUuid));
    }

    setOpenModal(false);
  }

  function handleApproveAssessment() {
    if (status === 'Complete') {
      dispatch(doPatchAssessmentApprove(assessmentUuid));
      setHasErrors(false);
      setFieldInFocus('');
    }
  }

  function handleFieldInFocusValidation(event) {
    setFieldInFocus(event.target.name);
  }

  const onDiscardChanges = () => {
    setExistingAssessment(existingItem);
  };

  return (
    <div>
      <AssessmentForm
        assessmentAssociationUuid={assessmentAssociationUuid}
        assessmentManager={assessmentManager}
        assessmentTechniqueUuid={assessmentTechniqueUuid}
        assessmentTypeUuid={assessmentTypeUuid}
        assessmentUuid={assessmentUuid}
        endDate={endDate}
        location={location}
        responsibleFaculty={responsibleFaculty}
        scoreType={scoreType}
        startDate={startDate}
        status={status}
        timespan={timespan}
        title={title}
        existing={existing}
        restrictEdit={restrictEdit}
        term={dat.term}
        legacyScores={legacyScores}
        hasUnsavedChanges={hasUnsavedChanges}
        onDiscardChanges={onDiscardChanges}
        optionsAssessmentTypes={optionsAssessmentTypes}
        optionsAssessmentTechniques={optionsAssessmentTechniques}
        optionsAssessmentAssociations={optionsAssessmentAssociations}
        optionsFaculty={optionsFaculty}
        openModal={openModal}
        hasErrors={hasErrors}
        fieldInFocus={fieldInFocus}
        handleLegacyScores={handleLegacyScores}
        handleAssessmentChange={event => handleAssessmentChange(event)}
        handleDateChange={(fieldName, newDate) =>
          handleDateChange(fieldName, newDate)
        }
        handleUserFieldChange={(value, name) =>
          handleUserFieldChange(value, name)
        }
        handleAssessmentSaveDraft={() => handleAssessmentSaveDraft()}
        handleAssessmentFinish={() => handleAssessmentFinish()}
        handleDeleteOpen={() => handleDeleteOpen()}
        handleDeleteClose={choice => handleDeleteClose(choice)}
        handleApproveAssessment={() => handleApproveAssessment()}
        assessmentIndex={assessmentIndex}
        handleFieldInFocusValidation={event =>
          handleFieldInFocusValidation(event)
        }
      />
    </div>
  );
}

AssessmentFormContainer.propTypes = {
  dat: PropTypes.object,
  existingItem: PropTypes.object,
  facultyOptions: PropTypes.array,
  restrictEdit: PropTypes.bool
};

AssessmentFormContainer.defaultProps = {
  dat: {},
  existingItem: undefined,
  facultyOptions: [],
  restrictEdit: true
};
