import fetch from '../helpers/fetch';

const getSchool = schoolUuid =>
  fetch(`/api/school/${schoolUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postSchool = school =>
  fetch('/api/school/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(school)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putSchool = school =>
  fetch(`/api/school/${school.uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(school)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteSchool = schoolUuid =>
  fetch(`/api/school/${schoolUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

const getSchools = () =>
  fetch(`/api/schools`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchSchoolRevus = revus =>
  fetch(`/api/school/revus`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(revus)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchSchoolDenominators = denominators =>
  fetch(`/api/school/denominators`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(denominators)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getSchool,
  postSchool,
  putSchool,
  deleteSchool,
  getSchools,
  patchSchoolRevus,
  patchSchoolDenominators
};
