import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {
  Heading2,
  ButtonPrimary,
  Subtitle2
} from '@xcomp/xcomp-design-library';

import {
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader
} from '../../../Library/Layout';
import Header from '../../../Library/Header';
import { doClearUpdatedAssessment } from '../../../../redux/actions/assessmentActions';
import { validateSelectDate } from '../../../../helpers/validation/validateGeneric';
import DateTime from '../../../Library/DateTime';
import StatusCommitModalContainer from './StatusCommitModal/StatusCommitModalContainer';

const AttemptHeader = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }
`;

const Label = styled.div`
  color: ${props => props.theme.fontColors.darker};
  position: relative;
  display: inline-block;
  z-index: 100;
  background: ${props => props.theme.colors.white};
  padding-right: 0.5rem;
  font-size: 14px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 19px;
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const StatusSelectionFormSection = styled.div`
  display: flex;
  position: relative;
  z-index: 400;
  flex-direction: column;
  margin: 16px 0;
`;

const RadioGroupSC = styled(RadioGroup)`
  margin: 12px 0 32px;
`;

const SwitchSC = styled(FormGroup)`
  display: flex;
  align-items: center;
`;

const SwitchLabelLeft = styled(FormLabel)`
  && {
    margin-right: 1.325rem;
    color: ${props => props.theme.fontColors.darker};
  }
`;

const SwitchLabelRight = styled(FormLabel)`
  && {
    margin-left: 0;
    color: ${props => props.theme.fontColors.darker};
  }
`;

const AssessmentSettingRules = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 1200px) {
    ${'' /* flex-wrap: wrap; */}
    display: block;
  }
`;

const RulesColumn = styled(({ isSelected, ...rest }) => <div {...rest} />)`
  position: relative;
  z-index: ${props => (props.isSelected ? '500' : '400')};
  width: 50%;
  display: block;
  label {
    position: relative;
    z-index: 100;
  }

  &:focus,
  &:active {
    z-index: 500;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

const Subtitle2SC = styled(Subtitle2)`
  display: block;
`;

const ButtonRow = styled(({ margin, ...rest }) => <div {...rest} />)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: ${props => props.margin || 'initial'};
  position: relative;
  z-index: 300;
`;

const DateTimeSC = styled(DateTime)`
  && {
    min-width: 350px;
    position: relative;
    z-index: 500;
    height: 100%;
    margin-left: 32px;
  }
`;

export default function StatusSettings({
  selectedRadioOpen,
  selectedRadioClose,
  assessmentTitle,
  courseNumber,
  courseTitle,
  sectionNumber,
  partNumber,
  assessmentType,
  assessment,
  statusScore,
  statusCommit,
  justificationMessage,
  openSpecificDate,
  closeSpecificDate,
  datePickerOpen,
  datePickerClose,
  handleChangeOpen,
  handleChangeClose,
  handleCommit,
  handleUncommit,
  handleJustificationChange,
  handleResetCommitment,
  handleResetJustification,
  handleDatePickerChange,
  handleStatusScoreSubmit,
  handleStatusCommitSubmit
}) {
  const dispatch = useDispatch();

  const [isCommitModalOpen, setCommitModalOpen] = useState(false);
  const [selectedFormSection, setSelectedFormSection] = useState('');
  const headerSubtitle = `${courseNumber} ● ${courseTitle} ● Section ${sectionNumber} ● Part ${partNumber} ● ${assessmentType}`;

  const handleCommitModalOpen = event => {
    setCommitModalOpen(true);
    if (event.target.checked) {
      handleCommit();
    } else {
      handleUncommit();
    }
  };

  const handleCommitModalClose = () => {
    handleResetCommitment();
    dispatch(doClearUpdatedAssessment());
    setCommitModalOpen(false);
  };

  const handleCommitModalSuccess = () => {
    handleResetJustification();
    setCommitModalOpen(false);
    dispatch(doClearUpdatedAssessment());
  };

  return (
    <ContainerResize>
      <StatusCommitModalContainer
        statusCommit={statusCommit}
        isModalOpen={isCommitModalOpen}
        handleModalClose={handleCommitModalClose}
        handleModalSuccess={handleCommitModalSuccess}
        handleSubmit={handleStatusCommitSubmit}
        justificationMessage={justificationMessage}
        handleJustificationChange={handleJustificationChange}
      />
      <Header title={assessmentTitle} subtitle={headerSubtitle} />
      <ContainerPage>
        <Panel>
          <PanelHeader paddingLeft="24px">
            <Heading2>Assessment Settings</Heading2>
          </PanelHeader>
          <PanelPadding>
            <AttemptHeader offset="0">
              <Label>assessment status</Label>
            </AttemptHeader>
            <SwitchSC row>
              <SwitchLabelLeft>Closed</SwitchLabelLeft>
              <FormControlLabel
                control={
                  <Switch
                    checked={statusScore === 'Open'}
                    onChange={undefined}
                    name="assessment_status"
                  />
                }
              />
              <SwitchLabelRight>Open</SwitchLabelRight>
            </SwitchSC>
            <StatusSelectionFormSection>
              <AssessmentSettingRules>
                <RulesColumn
                  isSelected={selectedFormSection === 'column1'}
                  onClick={() => setSelectedFormSection('column1')}
                >
                  <Subtitle2SC>Open Rules:</Subtitle2SC>
                  <FormControl component="fieldset" onChange={handleChangeOpen}>
                    <RadioGroupSC
                      aria-label="position"
                      name="position"
                      value={selectedRadioOpen}
                    >
                      <FormControlLabel
                        value="openNow"
                        control={<Radio color="primary" />}
                        label="Open now"
                      />
                      <FormControlLabel
                        value="openAssessment"
                        control={<Radio color="primary" />}
                        label="Open first day & time of assessment"
                      />
                      <FormControlLabel
                        value="openSetDate"
                        control={<Radio color="primary" />}
                        label="Open at specific date and time"
                      />
                      <DateTimeSC
                        dataTestId="assessment-start-date"
                        name="startDate"
                        label="Start Date*"
                        value={datePickerOpen}
                        indentifier="open_date"
                        handleChange={handleDatePickerChange}
                        hasErrors={false}
                        handleValidate={validateSelectDate}
                        disabled={!openSpecificDate}
                      />
                      <FormControlLabel
                        value="closeIndefinite"
                        control={<Radio color="primary" />}
                        label="Close assessment indefinitely"
                      />
                    </RadioGroupSC>
                  </FormControl>
                </RulesColumn>
                <RulesColumn
                  isSelected={selectedFormSection === 'column2'}
                  onClick={() => setSelectedFormSection('column2')}
                >
                  <Subtitle2SC>Close Rules:</Subtitle2SC>
                  <FormControl
                    component="fieldset"
                    onChange={handleChangeClose}
                    disabled={selectedRadioOpen === 'closeIndefinite'}
                  >
                    <RadioGroupSC
                      aria-label="position"
                      name="position"
                      value={selectedRadioClose}
                    >
                      {/* TODO: validte options */}
                      <FormControlLabel
                        value="openIndefinite"
                        control={<Radio color="primary" />}
                        label="Keep assessment open indefinitely"
                      />
                      <FormControlLabel
                        value="closeAssessment"
                        control={<Radio color="primary" />}
                        label="Close on last day of assesment at 11:59 PM"
                      />
                      <FormControlLabel
                        value="closeSetDate"
                        control={<Radio color="primary" />}
                        label="Close at specific date and time"
                      />
                      <DateTimeSC
                        dataTestId="assessment-end-date"
                        name="endDate"
                        label="End Date*"
                        value={datePickerClose}
                        indentifier="close_date"
                        handleChange={handleDatePickerChange}
                        hasErrors={false}
                        handleValidate={validateSelectDate}
                        disabled={
                          !closeSpecificDate ||
                          selectedRadioOpen === 'closeIndefinite'
                        }
                      />
                      <FormControlLabel
                        value="closeSubmission"
                        control={<Radio color="primary" />}
                        label="Close at 100% submission"
                      />
                    </RadioGroupSC>
                  </FormControl>
                </RulesColumn>
              </AssessmentSettingRules>
              <ButtonRow margin="0 0 24px">
                <ButtonPrimary onClick={handleStatusScoreSubmit}>
                  Apply
                </ButtonPrimary>
              </ButtonRow>
            </StatusSelectionFormSection>
            <AttemptHeader offset="0">
              <Label>commitment status</Label>
            </AttemptHeader>
            <SwitchSC row>
              <SwitchLabelLeft>Uncommitted</SwitchLabelLeft>
              <FormControlLabel
                control={
                  <Switch
                    checked={statusCommit === 'Commit'}
                    onChange={handleCommitModalOpen}
                    name="assessment_commit"
                  />
                }
              />
              <SwitchLabelRight>Committed</SwitchLabelRight>
            </SwitchSC>
            <ButtonRow margin="16px 0 24px">
              <ButtonPrimary
                onClick={() => handleStatusCommitSubmit(false)}
                disabled={assessment.statusCommit === statusCommit}
              >
                Apply
              </ButtonPrimary>
            </ButtonRow>
          </PanelPadding>
        </Panel>
      </ContainerPage>
    </ContainerResize>
  );
}

StatusSettings.propTypes = {
  assessmentTitle: PropTypes.string,
  courseNumber: PropTypes.string,
  courseTitle: PropTypes.string,
  sectionNumber: PropTypes.string,
  partNumber: PropTypes.string,
  assessmentType: PropTypes.string,
  assessment: PropTypes.object,
  statusScore: PropTypes.string,
  statusCommit: PropTypes.string,
  selectedRadioOpen: PropTypes.string,
  selectedRadioClose: PropTypes.string,
  openSpecificDate: PropTypes.bool,
  closeSpecificDate: PropTypes.bool,
  datePickerOpen: PropTypes.string,
  datePickerClose: PropTypes.string,
  justificationMessage: PropTypes.string,
  handleStatusScoreSubmit: PropTypes.func,
  handleJustificationChange: PropTypes.func,
  handleStatusCommitSubmit: PropTypes.func,
  handleResetCommitment: PropTypes.func,
  handleResetJustification: PropTypes.func,
  handleCommit: PropTypes.func,
  handleUncommit: PropTypes.func,
  handleChangeOpen: PropTypes.func,
  handleChangeClose: PropTypes.func,
  handleDatePickerChange: PropTypes.func
};

StatusSettings.defaultProps = {
  assessmentTitle: '',
  courseNumber: '',
  courseTitle: '',
  sectionNumber: '',
  partNumber: '',
  assessmentType: '',
  assessment: {},
  statusScore: 'Close',
  statusCommit: 'Uncommitted',
  selectedRadioOpen: '',
  selectedRadioClose: '',
  openSpecificDate: false,
  closeSpecificDate: false,
  datePickerOpen: '',
  datePickerClose: '',
  justificationMessage: '',
  handleStatusScoreSubmit: undefined,
  handleJustificationChange: undefined,
  handleStatusCommitSubmit: undefined,
  handleResetCommitment: undefined,
  handleResetJustification: undefined,
  handleCommit: undefined,
  handleUncommit: undefined,
  handleChangeOpen: undefined,
  handleChangeClose: undefined,
  handleDatePickerChange: undefined
};
