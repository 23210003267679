import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { SearchIllustration } from '@xcomp/xcomp-design-library';
import { sortByNestedProperty } from '../../../../helpers/utilities';
import UserInfo from './UserInfo';

import {
  userActiveSelector,
  usersSelectorBySearch
} from '../../../../redux/selectors/usersSelectors';

const Layout = styled.div`
  margin-bottom: 20px;
  overflow: auto;
  width: 100%;
`;

const TableSC = styled(Table)`
  && {
    table-layout: fixed;
  }
`;

const TitleArea = styled.div`
  padding-left: 10px;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const TabelCellUserInfo = styled(TableCell)`
  && {
    overflow: hidden;
    vertical-align: top;
    border-right: 1px solid rgba(224, 224, 224, 1);
    padding: 10px 20px 10px 20px;
  }
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40vh;
  align-items: center;
  font-size: 16px;
`;

export default function UserList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const activeUser = useSelector(state => userActiveSelector(state));
  const users = useSelector(state => usersSelectorBySearch(state));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const themeContext = useContext(ThemeContext);

  return (
    <>
      {users?.length > 0 ? (
        <Layout>
          <TitleArea>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              spacing={4}
            >
              <Grid item sm={2} xs={2}>
                Institution ID
              </Grid>
              <Grid item sm={8} xs={8}>
                Last Name, First Name
              </Grid>
            </Grid>
          </TitleArea>
          <TableSC>
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .sort((a, b) => sortByNestedProperty(a, b, 'item.lastName'))
                .map(user => (
                  <TableRow key={user.item.uuid}>
                    <TabelCellUserInfo>
                      <UserInfo activeUser={activeUser} user={user.item} />
                    </TabelCellUserInfo>
                  </TableRow>
                ))}
            </TableBody>
          </TableSC>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Layout>
      ) : (
        <Center>
          <div>
            <SearchIllustration
              dotsColor={themeContext.colors.primaryShades[450]}
              bgColor={themeContext.colors.primaryShades[200]}
              outlineColor={themeContext.colors.primary}
            />
          </div>
          <div>Search for users and maintain user roles.</div>
        </Center>
      )}
    </>
  );
}

UserList.propTypes = {};

UserList.defaultProps = {};
