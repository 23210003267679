import { Model, attr, fk } from 'redux-orm';
import {
  GET_COURSE_COLLECTIONS_SUCCESS,
  PUT_COURSE_COLLECTION_SUCCESS,
  DELETE_COURSE_COLLECTION_SUCCESS
} from '../actions-type';

class CourseCollection extends Model {
  static reducer(action, CourseCollection, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_COURSE_COLLECTIONS_SUCCESS: {
        const courseCollections = [...payload.courseCollections];
        return courseCollections.map(courseCollection => {
          return CourseCollection.upsert(courseCollection);
        });
      }
      case PUT_COURSE_COLLECTION_SUCCESS: {
        const { updatedCourseCollection } = payload;
        return CourseCollection.upsert(updatedCourseCollection);
      }
      case DELETE_COURSE_COLLECTION_SUCCESS: {
        const { deletedCourseCollection } = payload;
        return CourseCollection.withId(deletedCourseCollection.uuid).delete();
      }

      default:
        return session;
    }
  }
}

CourseCollection.fields = {
  uuid: attr(),
  name: attr(),
  credits_required: attr(),
  all_credits_required: attr(),
  special_collection: attr(),
  cohortUuid: fk('Cohort', 'courseCollections'),
  createdAt: attr(),
  updatedAt: attr()
};

CourseCollection.modelName = 'CourseCollection';
CourseCollection.options = {
  idAttribute: 'uuid'
};

export default CourseCollection;
