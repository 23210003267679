import { Model, attr, fk } from 'redux-orm';
import {
  GET_PART_GRADE_SETTINGS_SUCCESS,
  PUT_PART_GRADE_DATA_SOURCE_SUCCESS,
  PUT_PART_GRADE_COLLECTIONS_SUCCESS,
  GET_GRADE_COLLECTION_SUCCESS,
  PUT_COLLECTION_ASSESSMENTS_SUCCESS,
  PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_SUCCESS
} from '../actions-type';

class GradeCollection extends Model {
  static reducer(action, GradeCollection, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_PART_GRADE_SETTINGS_SUCCESS: {
        const { grade_collections } = payload.part_grade_settings;
        if (grade_collections && grade_collections.length > 0) {
          grade_collections.map(collection => {
            return GradeCollection.upsert(collection);
          });
        }
        break;
      }
      case GET_GRADE_COLLECTION_SUCCESS: {
        const { grade_collection } = payload;
        return GradeCollection.upsert(grade_collection);
      }
      case PUT_PART_GRADE_DATA_SOURCE_SUCCESS: {
        const { grade_collections, uuid } = payload.part.part_grade_settings;

        GradeCollection.all()
          .filter(collection => collection.partGradeSettingsUuid === uuid)
          .delete();

        if (grade_collections && grade_collections.length > 0) {
          grade_collections.map(collection => {
            return GradeCollection.upsert(collection);
          });
        }
        break;
      }
      case PUT_PART_GRADE_COLLECTIONS_SUCCESS: {
        const { grade_collections } = payload.part.part_grade_settings;
        if (grade_collections && grade_collections.length > 0) {
          grade_collections.map(collection => {
            return GradeCollection.upsert(collection);
          });
        }
        break;
      }
      case PUT_COLLECTION_ASSESSMENTS_SUCCESS: {
        const { grade_collection } = payload;

        return GradeCollection.upsert(grade_collection);
      }
      case PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_SUCCESS: {
        const { gradingTechnique, part_grade_settings } = payload;

        if (part_grade_settings?.grade_collections?.length > 0) {
          part_grade_settings.grade_collections.map(collection => {
            return collection.gradingTechniqueUuid === gradingTechnique.uuid
              ? GradeCollection.upsert(collection).set(
                  'grading_technique',
                  gradingTechnique
                )
              : GradeCollection.upsert(collection);
          });
        }

        break;
      }

      default:
        return session;
    }
  }
}

GradeCollection.fields = {
  uuid: attr(),
  title: attr(),
  weight: attr(),
  allow_dropped_assessments: attr(),
  low_grades_dropped: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  collection_assessments: attr(),
  partGradeSettingsUuid: fk('PartGradeSettings', 'grade_collections')
};

GradeCollection.modelName = 'GradeCollection';

GradeCollection.options = {
  idAttribute: 'uuid'
};

export default GradeCollection;
