import fetch from '../helpers/fetch';

const putThirdPartyId = (userUuid, programUuid, thirdPartyId) =>
  fetch(`/api/third-party-id`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ userUuid, programUuid, thirdPartyId })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { putThirdPartyId };
