import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import { useCallback, useMemo, useState, useEffect } from 'react';
import draftToHtml from 'draftjs-to-html';
import { markdownToDraft, draftToMarkdown } from 'markdown-draft-js';
import { isEmpty } from 'lodash';

export const extendedStyleItems = {
  UNDERLINE: {
    open: function open() {
      return '++';
    },

    close: function close() {
      return '++';
    }
  }
};

export const extendedBlockStyleItems = {
  blockStyles: {
    ins_open: 'UNDERLINE'
  },
  remarkableOptions: {
    enable: {
      inline: 'ins'
    }
  }
};

export const useDescriptionEditorState = description => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (description?.length) {
      const content = convertFromRaw(
        markdownToDraft(description, extendedBlockStyleItems)
      );

      setEditorState(EditorState.createWithContent(content));
    }
  }, [description]);

  const onEditorStateChange = useCallback(state => {
    setEditorState(state);
  }, []);

  const wordCount = useMemo(() => {
    if (isEmpty(editorState)) return 0;

    const content = convertToRaw(editorState.getCurrentContent());

    return (
      content?.blocks
        ?.map(b => b.text)
        ?.join('')
        ?.match(/\b\w+\b/gm)?.length || 0
    );
  }, [editorState]);

  const draftToText = useMemo(() => {
    if (isEmpty(editorState)) return '';

    const content = convertToRaw(editorState.getCurrentContent());

    return draftToMarkdown(content, { styleItems: extendedStyleItems });
  }, [editorState]);

  const convertToHtml = useCallback(description => {
    if (description) {
      const mdtodraft = markdownToDraft(description, extendedBlockStyleItems);
      const content = draftToHtml(mdtodraft, {
        styleItems: extendedStyleItems
      });

      return content;
    }
  }, []);

  return {
    editorState,
    onEditorStateChange,
    wordCount,
    descriptionText: draftToText,
    convertToHtml
  };
};
