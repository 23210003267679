import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonIcon, ButtonOutline } from '@xcomp/xcomp-design-library';
// import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import SearchIcon from '@material-ui/icons/Search';
import {
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader
} from '../../Library/Layout';
import Header from '../../Library/Header';

// import SearchMulti from '../../Library/SearchMultiHook';
import { TextFieldSC } from '../../Library/FormComponents';
// import { PanelLabel } from '../../Library/DashboardSC';
import NoItemsMessage from '../../Library/NoItemsMessage';
import CommitmentLog from './CommitmentLog';

const HeaderSpacer = styled.div`
  width: 100%;
  height: 36px;
`;

// const PanelPadding = styled.div`
//   padding: 16px 24px;
// `;

const SearchBarWrapper = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  max-width: 500px;
`;

const PanelHeaderSC = styled(PanelHeader)`
  && {
    height: 100px;
    width: 100%;
    justify-content: space-between;
  }
`;

const SearchButton = styled(({ error, ...rest }) => <ButtonIcon {...rest} />)`
  && {
    position: absolute;
    right: 24px;
    top: ${props => (props.error ? '-20px' : '0')};
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 300;

    &:hover,
    &:focus,
    &:active {
      background: 0;
    }
  }
`;

const SectionCommitmentLog = ({
  section,
  courseMaster,
  commitmentLogs,
  handleQueryChange,
  toggleSortReverse
}) => {
  const noCollectionsMessage =
    'There are currently no commitment logs in this section.';

  const courseNumber = courseMaster?.courseNumber;

  const sectionNumber = section?.sectionNumber
    ? section?.sectionNumber.toString().padStart(2, '0')
    : '';

  return (
    <ContainerResize>
      <Header
        title="Commitment Log"
        subtitle={`${courseNumber} ● Section ${sectionNumber}`}
        backButtonLocation="/syllabus-management/all"
      />
      <HeaderSpacer />;
      <ContainerPage paddingTop="48px">
        <Panel>
          <PanelHeaderSC paddingLeft="24px" paddingRight="24px">
            <SearchBarWrapper>
              <SearchButton noHoverBg onClick={undefined}>
                <SearchIcon />
              </SearchButton>
              <TextFieldSC
                autoFocus
                id="program-assessment-search"
                name="program-assessment-search"
                label="Search"
                placeholder="Assessment, Part or Section Title"
                value={undefined}
                onChange={handleQueryChange}
                margin="normal"
                variant="outlined"
                paddingRight="1rem"
              />
            </SearchBarWrapper>
            <ButtonOutline onClick={toggleSortReverse}>
              Reverse Sort
            </ButtonOutline>
          </PanelHeaderSC>
          {commitmentLogs?.length > 0 ? (
            <>
              {commitmentLogs?.length > 0 &&
                commitmentLogs.map(log => (
                  <CommitmentLog key={log.uuid} logUuid={log.uuid} />
                ))}
            </>
          ) : (
            <Panel>
              <NoItemsMessage message={noCollectionsMessage} />
            </Panel>
          )}{' '}
        </Panel>
      </ContainerPage>
    </ContainerResize>
  );
};

SectionCommitmentLog.propTypes = {
  section: PropTypes.object,
  courseMaster: PropTypes.object,
  handleQueryChange: PropTypes.func,
  toggleSortReverse: PropTypes.func,
  commitmentLogs: PropTypes.arrayOf(PropTypes.object)
};

SectionCommitmentLog.defaultProps = {
  section: {},
  courseMaster: {},
  handleQueryChange: undefined,
  toggleSortReverse: undefined,
  commitmentLogs: []
};

export default SectionCommitmentLog;
