const optionAssessmentType = [
  {
    value: 'Test',
    label: 'Test'
  },
  {
    value: 'Quiz',
    label: 'Quiz'
  }
];

const optionScheduleType = [
  {
    value: 'Single',
    label: 'Single Day'
  },
  {
    value: 'Multi',
    label: 'Multi-Day'
  }
];

const optionSuffix = [
  { label: 'None', value: '' },
  { label: 'Jr.', value: 'JR.' },
  { label: 'Sr.', value: 'SR.' },
  { label: 'II', value: 'II' },
  { label: 'III', value: 'III' },
  { label: 'IV', value: 'IV' },
  { label: 'V', value: 'V' },
  { label: 'VI', value: 'VI' },
  { label: 'VII', value: 'VII' },
  { label: 'VIII', value: 'VIII' },
  { label: 'IX', value: 'IX' },
  { label: 'X', value: 'X' }
];

const optionGender = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' },
  { label: 'Unreported', value: 'Unreported' }
];

const optionEthnicity = [
  {
    label: 'American Indian or Alaska Native',
    value: 'American Indian or Alaska Native'
  },
  { label: 'Asian', value: 'Asian' },
  { label: 'Black or African American', value: 'Black or African American' },
  { label: 'Hispanic or Latino', value: 'Hispanic or Latino' },
  {
    label: 'Native American or Pacific Islander',
    value: 'Native American or Pacific Islander'
  },
  { label: 'White', value: 'White' },
  { label: 'Other', value: 'Other' },
  { label: 'Unreported', value: 'Unreported' }
];

const optionUserStatus = [
  { label: 'Complete', value: 'complete' },
  { label: 'Active', value: 'active' }
];

const optionApplicantStatus = [
  { label: 'Active', value: 'active' },
  { label: 'Complete', value: 'complete' }
];

const optionStudentStatus = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Delayed', value: 'delayed' },
  { label: 'Competent', value: 'competent' },
  { label: 'Withdrawn', value: 'withdrawn' },
  { label: 'Transferred', value: 'transferred' }
];

const optionRolesForInstitutionAdmin = [
  { label: 'Institution Admin', value: 'institution_admin' },
  { label: 'School Admin', value: 'school_admin' }
];

const optionRolesForSchoolAdmin = [
  { label: 'School Admin', value: 'school_admin' },
  { label: 'Program Admin', value: 'program_admin' },
  { label: 'Department Faculty', value: 'department_faculty' },
  { label: 'Department Staff', value: 'department_staff' }
];

const optionRolesForProgramAdmin = [
  { label: 'Program Admin', value: 'program_admin' },
  { label: 'Program Faculty', value: 'program_faculty' },
  { label: 'Program Staff', value: 'program_staff' }
];

const optionRolesForCohortManagement = [
  { label: 'Applicant', value: 'applicant' },
  { label: 'Student', value: 'student' }
];

const optionsRolesAll = [
  { label: 'Institution Admin', value: 'institution_admin' },
  { label: 'School Admin', value: 'school_admin' },
  { label: 'Program Admin', value: 'program_admin' },
  { label: 'Program Faculty', value: 'program_faculty' },
  { label: 'Program Staff', value: 'program_staff' },
  { label: 'Department Faculty', value: 'department_faculty' },
  { label: 'Department Staff', value: 'department_staff' }
];

const optionsSyllabus = [
  { label: 'All', value: 'all' },
  { label: 'Encounter', value: 'encounter' },
  { label: 'Assessment', value: 'assessment' }
];

const optionsSyllabusStatus = [
  { label: 'All', value: 'all' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Complete', value: 'complete' },
  { label: 'Approved', value: 'approved' }
];

const optionsDateRange = [
  { label: 'Old to New', value: 'ascending' },
  { label: 'New to Old', value: 'descending' }
];

const optionsSortStep = [
  { label: 'Last Name', value: 'alphabetic' },
  { label: 'Relative Total', value: 'relative' },
  { label: 'Unreported', value: 'unreported' }
];

const optionsFacultyScoreView = [
  { label: 'Student by Faculty', value: 'default' },
  { label: 'Faculty by Student', value: 'alternative' }
];

const optionsTopicScoreView = [
  { label: 'Student by Topic', value: 'default' },
  { label: 'Faculty by Topic', value: 'alternative' }
];

const optionsPartGradeDataSource = [
  { label: 'Assessments', value: 'ASSESSMENTS' },
  { label: 'Legacy', value: 'LEGACY' }
];

const optionsSectionGradeDataSource = [
  { label: 'Legacy', value: 'LEGACY' },
  { label: 'Parts', value: 'PARTS' }
];

const optionsScoreType = [
  {
    label: 'Opportunity',
    value: 'Opportunity'
  },
  {
    label: 'Attempt',
    value: 'Attempt'
  }
];

const optionsTranscriptSource = [
  {
    label: 'Program',
    value: 'Program'
  },
  {
    label: 'Milestone',
    value: 'Milestone'
  }
];

const optionsTermTypes = [
  { label: 'Semester', value: 'Semester' },
  { label: 'Quarter', value: 'Quarter' },
  { label: 'Trimester', value: 'Trimester' }
];

const optionsTerms = {
  Semester: [
    { label: 'Fall', value: 'Fall' },
    { label: 'Spring', value: 'Spring' },
    { label: 'Summer', value: 'Summer' },
    { label: 'Summer1', value: 'Summer1' },
    { label: 'Summer2', value: 'Summer2' }
  ],
  Quarter: [
    { label: 'Fall', value: 'Fall' },
    { label: 'Winter', value: 'Winter' },
    { label: 'Spring', value: 'Spring' },
    { label: 'Summer', value: 'Summer' }
  ],
  Trimester: [
    { label: 'Fall', value: 'Fall' },
    { label: 'Spring', value: 'Spring' },
    { label: 'Summer', value: 'Summer' }
  ]
};

module.exports = {
  optionApplicantStatus,
  optionAssessmentType,
  optionEthnicity,
  optionGender,
  optionRolesForCohortManagement,
  optionRolesForInstitutionAdmin,
  optionRolesForProgramAdmin,
  optionRolesForSchoolAdmin,
  optionScheduleType,
  optionsDateRange,
  optionsFacultyScoreView,
  optionsPartGradeDataSource,
  optionsRolesAll,
  optionsScoreType,
  optionsSectionGradeDataSource,
  optionsSortStep,
  optionsSyllabus,
  optionsSyllabusStatus,
  optionsTerms,
  optionsTermTypes,
  optionsTopicScoreView,
  optionsTranscriptSource,
  optionStudentStatus,
  optionSuffix,
  optionUserStatus
};
