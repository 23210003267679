import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSWRMutation from 'swr/mutation';
import _, { isEqual } from 'lodash';
import { ThemeContext } from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import { shadeColor } from '@xcomp/xcomp-design-library/dist/helpers';
import { fetcher } from '../../../helpers/utilities';
import { selfAssessmentAssessmentTypesSelector } from '../../../redux/selectors/assessmentSelectors';
import { doGetAssessmentType } from '../../../redux/actions/assessmentActions';
import FilterDropDown from '../../SyllabusManagement/PartGradeSummary/FilterDropDown';
import PageIllustration from '../../Library/PageIllustration';
import { formatFullName } from '../../../helpers/format/user.format';
import ContainerResize, { ContainerPage } from '../../Library/Layout';
import Header from '../../Library/Header';
import { formatYYYY } from '../../../helpers/date-fns.utils';
import { cohortSelectorByMatchParams } from '../../../redux/selectors/cohortsSelectors';
import { doGetCohort } from '../../../redux/actions/cohortActions';
import { getCharacterizationColor } from '../reportUtils';
import { ErrorWrapper, InvalidScores, NoDataContainer, PanelSC, ProgressBar, ReportLeaf, SelfAssessmentAccordion, SelfAssessmentHeader } from '../SelfAssessmentReportComponents';

const CohortSelfAssessmentReportContainer = () => {
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);
  const [isAnonymized, setIsAnonymized] = useState(false);
  const [partColors, setPartColors] = useState([]);
  const themeContext = useContext(ThemeContext);
  const { cohortUuid } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const cohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    _.isEqual
  );

  const { trigger, error, data, isMutating } = useSWRMutation(
    `/api/cohort/${cohortUuid}/self-assessment-report?`,
    fetcher,
    {
      throwOnError: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (data && data.numberOfParts) {
      const amount = 0.5 / (data.numberOfParts + 1);
      setPartColors(
        Array(data.numberOfParts + 2).fill(0).map((e, index) => shadeColor(themeContext.colors.white, index * amount)).slice(1)
      );
    }
  }, [data, themeContext.colors.white, themeContext.colors.black]);

  const assessmentTypes = useSelector(
    state => selfAssessmentAssessmentTypesSelector(state),
    isEqual
  );

  useEffect(() => {
    if (cohortUuid) {
      dispatch(doGetAssessmentType());
      dispatch(doGetCohort(cohortUuid));
    }
  }, [dispatch, cohortUuid]);

  const handleBackAction = useCallback(
    () => history.push(`/reports`),
    [history]
  );

  const handleApplyPartSelfAssessmentReportFilters = assessmentTypes => {
    setHasAppliedFilters(true);
    trigger({queryParameters: `&assessmentTypes=${JSON.stringify(assessmentTypes)}`});
  };

  return (
    <ContainerResize>
      <Header
        title="Cohort Reports - Self Assessment"
        subtitle={cohort && `cohort ${formatYYYY(cohort.startDate)} - ${formatYYYY(cohort.endDate)}`}
        backButtonOverride={handleBackAction}
      />
      <ContainerPage paddingTop="100px">
        <FilterDropDown
          options={assessmentTypes}
          callback={handleApplyPartSelfAssessmentReportFilters}
          title='Self-Assessment Report Filters'
        />
        {data && !isMutating && Object.keys(data.invalidCharacterizations).length > 0 &&
          <InvalidScores invalidCharacterizations={data.invalidCharacterizations} />
        }
        {// eslint-disable-next-line
          isMutating ?
            <PanelSC>
              <ProgressBar>Loading</ProgressBar>
              <LinearProgress />
            </PanelSC>
            : error
              ?
                <ErrorWrapper>
                  <PageIllustration
                    illustrationType="notFound"
                    infoText="An error occured when trying to retrieve data."
                  />
                </ErrorWrapper>
              :
                <PanelSC>
                  {hasAppliedFilters && data?.students.length === 0 && (
                    <NoDataContainer>
                      No data for the selected filters. Change filter selection and
                      re-apply.
                    </NoDataContainer>
                  )}
                  {!hasAppliedFilters && !data && (
                    <NoDataContainer>
                      Apply the filter selection to view the report.
                    </NoDataContainer>
                  )}
                  {data && data.students.length > 0 && (
                    <SelfAssessmentHeader isAnonymized={isAnonymized} setIsAnonymized={setIsAnonymized} />
                  )}
                  {data && data.students.length > 0 &&
                    data.students.map((s, index) => (
                      <SelfAssessmentAccordion
                        key={s.uuid}
                        columnOne={<span style={{ fontWeight: 600 }}>{s.totalCharacterizations}</span>}
                        columnTwo={`${(s.relValue / s.potValue * 100).toFixed(2)} %`}
                        columnThree={s.relValue.toFixed(1)}
                        columnFour={s.potValue.toFixed(1)}
                        columnOneClassName="column_one_label"
                        columnTwoClassName="column_two_label"
                        columnThreeClassName="column_three_label"
                        columnFourClassName="column_four_label"
                        expandWidth="66px"
                        height="50px"
                        isStatisticStudent={false}
                        label={isAnonymized ? s.institutionId : formatFullName(s)}
                        withBottomBorder
                        $level='student'
                        $even={index % 2 === 0}
                      >
                        {s.characterizations.map((c) =>
                          <SelfAssessmentAccordion
                            key={c.characterization}
                            columnOne={<span style={{ fontWeight: c.total === 0 ? 300 : 600 }}>{c.total.toString()}</span>}
                            columnTwo=' '
                            columnThree=' '
                            columnFour=' '
                            columnOneClassName="column_one_label"
                            columnTwoClassName="column_two_label"
                            columnThreeClassName="column_three_label"
                            columnFourClassName="column_four_label"
                            expandWidth="66px"
                            height="40px"
                            isStatisticStudent={false}
                            label={c.characterization}
                            $level='characterization'
                            $background={getCharacterizationColor(c)}
                          >
                            {c.courses.length === 0
                              ?
                                <div style={{ width: '100%', textAlign: 'center', fontSize: 16, fontStyle: 'italic' }}>There are no scores for this characterization</div>
                              :
                              c.courses.map((course, index) =>
                                <SelfAssessmentAccordion
                                  key={course.courseTitle}
                                  expandWidth="66px"
                                  height="40px"
                                  isStatisticStudent={false}
                                  label={<div><span style={{ fontWeight: 600, marginRight: 10 }}>{course.courseTitle}</span></div>}
                                  $level='course'
                                  $even={index % 2 === 0}
                                  backgroundSelected={themeContext.colors.grey[500]}
                                >
                                  {course.parts.map((part, index) =>
                                    <SelfAssessmentAccordion
                                      key={part.title}
                                      expandWidth="66px"
                                      height="40px"
                                      isStatisticStudent={false}
                                      label={<div><span style={{ fontWeight: 600, marginRight: 10 }}>Part {part.partNumber}</span> {part.title}</div>}
                                      $level='part'
                                      $even={index % 2 === 0}
                                      $background={partColors[index]}
                                      backgroundSelected={partColors[index]}
                                    >
                                      {part.scores.map((score, index) =>
                                        <ReportLeaf 
                                          key={score.key}
                                          even={index % 2 === 0}
                                          assessmentTitle={score.assessmentTitle}
                                          blockTitle={score.blockTitle}
                                          blockNumber={score.blockNumber}
                                          relValue={score.relValue}
                                          potValue={score.potValue}
                                        />
                                      )}
                                    </SelfAssessmentAccordion>
                                    )
                                  }
                                </SelfAssessmentAccordion>
                              )
                            }
                          </SelfAssessmentAccordion>
                        )}
                      </SelfAssessmentAccordion>
                    ))}
                </PanelSC>
        }
      </ContainerPage>
    </ContainerResize>
  );
};

export default CohortSelfAssessmentReportContainer;
