export const getCalculatedGradeDisplay = (
  calculatedGrade,
  isDelayed = false,
  isWithdrew = false,
  isCredit = false
) => {
  if (!calculatedGrade && (isDelayed || isWithdrew)) {
    return '--';
  } else if (!calculatedGrade && isCredit) {
    return '--';
  } else if (!calculatedGrade) {
    return 'N/A';
  }
  return calculatedGrade;
};

export const getLetterGradeDisplay = (
  letterGrade,
  unranked,
  isInactiveStudent,
  isCredit
) => {
  if (unranked && isCredit) {
    return 'CR';
  }
  if (unranked && !isInactiveStudent) {
    return 'N/A';
  }

  if (!letterGrade && isInactiveStudent) {
    return '--';
  }

  return letterGrade;
};

export const getClassRankDisplay = (classRank, unranked) => {
  if (unranked) {
    return 'N/A';
  }

  return classRank;
};
