import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  doGetDepartment,
  doPutDepartment,
  doDeleteDepartment
} from '../../../redux/actions/departmentActions';

import { validateDepartmentCode } from '../../../helpers/validation/validateDepartment';
import { validateInputString } from '../../../helpers/validation/validateGeneric';

import DepartmentEdit from './DepartmentEdit';
import DeleteModalRedirect from '../../Library/Modal/DeleteModal/DeleteModalRedirect';

import { departmentSelector } from '../../../redux/selectors/departmentsSelectors';

class DepartmentEditContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      name: '',
      code: '',
      redirect: false,
      hasErrors: false,
      modalOpen: false
    };

    this._validate = this._validate.bind(this);
    this._setFormState = this._setFormState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateDepartment = this.handleUpdateDepartment.bind(this);
    this.handleDeleteModalOpen = this.handleDeleteModalOpen.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const { departmentUuid } = match.params;

    this.props.onGetDepartment(departmentUuid);

    if (this.props.department) {
      this._setFormState();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.department !== prevProps.department) {
      this._setFormState();
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleUpdateDepartment() {
    const containsErrors = this._validate();

    if (containsErrors) {
      this.setState({
        hasErrors: true
      });
    } else {
      const { uuid, name, code } = this.state;
      const { selectedSchoolUuid } = this.props;

      const payload = {
        uuid,
        name,
        code,
        schoolUuid: selectedSchoolUuid
      };

      this.props.onPutDepartment(payload);

      this.setState({
        redirect: true
      });
    }
  }

  handleDeleteModalOpen() {
    this.setState({
      modalOpen: true
    });
  }

  handleDeleteModalClose() {
    this.setState({
      modalOpen: false
    });
  }

  handleDelete() {
    this.props.onDeleteDepartment(this.state.uuid);

    this.setState({
      modalOpen: false
    });
  }

  _validate() {
    let validationErrors = false;

    const { name, code } = this.state;

    const departmentNameError = validateInputString(name);
    const departmentCodeError = validateDepartmentCode(code);

    if (departmentNameError.invalid) {
      validationErrors = true;
    }

    if (departmentCodeError.invalid) {
      validationErrors = true;
    }

    return validationErrors;
  }

  _setFormState() {
    this.setState((state, props) => {
      const { department } = props;

      return {
        ...state,
        ...department
      };
    });
  }

  render() {
    const { name, code, hasErrors, redirect, modalOpen } = this.state;

    return redirect ? (
      <Redirect to="/school-management/departments" />
    ) : (
      <div>
        <DepartmentEdit
          name={name}
          code={code}
          hasErrors={hasErrors}
          handleChange={this.handleChange}
          handleUpdateDepartment={this.handleUpdateDepartment}
          handleDeleteModalOpen={this.handleDeleteModalOpen}
        />
        <DeleteModalRedirect
          modalOpen={modalOpen}
          type="department"
          link="/school-management/departments"
          handleModalClose={() => this.handleDeleteModalClose()}
          handleDelete={() => this.handleDelete()}
        />
      </div>
    );
  }
}

DepartmentEditContainer.propTypes = {
  match: PropTypes.object,
  department: PropTypes.object,
  onPutDepartment: PropTypes.func,
  onGetDepartment: PropTypes.func,
  selectedSchoolUuid: PropTypes.string,
  onDeleteDepartment: PropTypes.func
};

DepartmentEditContainer.defaultProps = {
  match: {},
  department: {},
  onPutDepartment: undefined,
  onGetDepartment: undefined,
  selectedSchoolUuid: undefined,
  onDeleteDepartment: undefined
};

const mapStateToProps = (state, props) => {
  const { userState } = state;
  return {
    selectedSchoolUuid: userState.selectedSchoolUuid,
    department: departmentSelector(state, props.match.params.departmentUuid)
  };
};

const mapDispatchToProps = dispatch => ({
  onPutDepartment: payload => dispatch(doPutDepartment(payload)),
  onGetDepartment: departmentUuid => dispatch(doGetDepartment(departmentUuid)),
  onDeleteDepartment: departmentUuid =>
    dispatch(doDeleteDepartment(departmentUuid))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentEditContainer);
