import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const getTotalRevusColor = (current, max) => {
  if (Number(current) === Number(max)) {
    return 'success';
  } else if (current > max) {
    return 'error';
  } else {
    return 'warning';
  }
};

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0;
  div {
    &.warning {
      color: ${props => props.theme.colors.status.warning};
    }
    &.error {
      color: ${props => props.theme.colors.status.error};
    }
    &.success {
      color: ${props => props.theme.colors.status.success};
    }
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
`;

const Result = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.grey[300]};
`;

const TotalRevus = ({ calculatedMaxRevus, isSingleType, objectives }) => {
  let sumOfRevus = 0;

  if (objectives && objectives.length > 0) {
    objectives.forEach(obj => {
      if (obj.revus) {
        sumOfRevus += Number(obj.revus);
      }
    });
  }

  sumOfRevus = Number.isNaN(sumOfRevus) ? '-' : sumOfRevus;

  const signal = isSingleType
    ? getTotalRevusColor(sumOfRevus, calculatedMaxRevus)
    : '';

  return (
    <Row>
      <Box>
        <Label>TOTAL REVUS</Label>
        <Result className={signal}>
          {sumOfRevus}
          {isSingleType && ` / ${calculatedMaxRevus}`}
        </Result>
      </Box>
    </Row>
  );
};

TotalRevus.propTypes = {
  calculatedMaxRevus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isSingleType: PropTypes.bool,
  objectives: PropTypes.arrayOf(PropTypes.object)
};

TotalRevus.defaultProps = {
  calculatedMaxRevus: 0,
  isSingleType: false,
  objectives: []
};

export default TotalRevus;
