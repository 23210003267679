import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import { competencyScoreSelector } from '../../../../redux/selectors/competencyScoreSelectors';

export default function CompetencyScore({ competencyUuid }) {
  const [percentageScore, setPercentageScore] = useState('- - ');

  const score = useSelector(
    state => competencyScoreSelector(state, competencyUuid),
    _.isEqual
  );

  useEffect(() => {
    if (score?.relTotal && score?.potTotal) {
      const rel = new Decimal(score?.relTotal);
      const pot = new Decimal(score?.potTotal);

      const percentage = rel.div(pot).times(100).toFixed(2);
      setPercentageScore(percentage);
    }
  }, [score]);

  return <>{percentageScore}</>;
}

CompetencyScore.propTypes = {
  competencyUuid: PropTypes.string
};

CompetencyScore.defaultProps = {
  competencyUuid: undefined
};
