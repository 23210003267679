import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useQuery } from '../../../../helpers/hooks/router.hooks';

import { selectThirdPartyId } from '../../../../redux/selectors/thirdPartySelectors';

export default function ThirdPartyId({ userUuid }) {
  const query = useQuery();
  const programUuid = query.get('programUuid');

  const thirdPartyIdSelected = useSelector(state =>
    selectThirdPartyId(state, userUuid, programUuid)
  );

  return <div>{thirdPartyIdSelected?.thirdPartyId}</div>;
}

ThirdPartyId.propTypes = {
  userUuid: PropTypes.string
};
ThirdPartyId.defaultProps = {
  userUuid: undefined
};
