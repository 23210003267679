import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { ButtonOutline, Subtitle1 } from '@xcomp/xcomp-design-library';
import AddIllustration from '@xcomp/xcomp-design-library/dist/illustrations/custom/AddIllustration';
import { Panel } from '../../../Library/Layout';
import SearchMulti from '../../../Library/SearchMultiHook';
import MicrosToAddDisplay from './MicrosToAddDisplay';
import MicroSearchResults from './MicroSearchResults';

export const SearchPanelHeader = styled(
  ({ paddingLeft, paddingRight, ...rest }) => <div {...rest} />
)`
  height: 100px;
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: space-between;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '66px')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '66px')};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const Subtitle1SC = styled(Subtitle1)`
  margin-top: 2.5rem;
`;

const SearchBarWrapper = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  max-width: 800px;
`;

const EmptyResultsWrapper = styled.div`
  width: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const AddIllustrationSC = styled(AddIllustration)``;

const MicrocompetencySearch = ({
  searchType,
  topics,
  subtopics,
  micros,
  showResults,
  onSearchClick,
  addSelectedDispatch,
  searchDispatch,
  searchResultTopics
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Panel>
      <SearchPanelHeader paddingLeft="24px" paddingRight="24px">
        <SearchBarWrapper>
          <SearchMulti
            placeholder="Search By"
            options={[
              { label: 'Microcompetency', field: 'microcompetency' },
              { label: 'Subtopic', field: 'subtopic' },
              { label: 'Topic', field: 'topic' }
            ]}
            onSearch={onSearchClick}
          />
        </SearchBarWrapper>
        <ButtonOutline onClick={addSelectedDispatch}>Add</ButtonOutline>
      </SearchPanelHeader>
      <PanelPadding>
        {showResults ? (
          <>
            <MicrosToAddDisplay
              topics={topics}
              subtopics={subtopics}
              micros={micros}
              searchType={searchType}
            />
            <MicroSearchResults
              selectedTopics={topics}
              selectedSubtopics={subtopics}
              searchType={searchType}
              searchDispatch={searchDispatch}
              searchResultTopics={searchResultTopics}
            />
          </>
        ) : (
          <EmptyResultsWrapper>
            <AddIllustrationSC
              dotsColor={themeContext.colors.primaryShades[450]}
              bgColor={themeContext.colors.primaryShades[200]}
              outlineColor={themeContext.colors.primary}
            />
            <Subtitle1SC>
              Search for terms to view relevant Topics, Subtopics and
              Microcompetencies.
            </Subtitle1SC>
          </EmptyResultsWrapper>
        )}
      </PanelPadding>
    </Panel>
  );
};

MicrocompetencySearch.propTypes = {
  searchType: PropTypes.oneOf(['microcompetency', 'subtopic', 'topic', '']),
  searchResultTopics: PropTypes.arrayOf(PropTypes.object),
  micros: PropTypes.object,
  subtopics: PropTypes.object,
  topics: PropTypes.object,
  showResults: PropTypes.bool,
  onSearchClick: PropTypes.func,
  searchDispatch: PropTypes.func,
  addSelectedDispatch: PropTypes.func
};
MicrocompetencySearch.defaultProps = {
  searchType: 'microcompetency',
  searchResultTopics: [],
  micros: {},
  subtopics: {},
  topics: {},
  showResults: false,
  onSearchClick: undefined,
  searchDispatch: undefined,
  addSelectedDispatch: undefined
};

export default MicrocompetencySearch;
