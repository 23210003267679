const initialCompetencyCollectionState = {
  uuid: '',
  name: '',
  is_default_collection: false,
  isMicroSearchOpen: false,
  microSearchForCompetencyUuid: '',
  haveFetchedCompetencyCollection: false,
  showValidationErrors: false
};

const competencyCollectionReducer = (state, action) => {
  switch (action.type) {
    case 'setExistingCompetencyCollection': {
      const { uuid, name, is_default_collection } = action.payload;

      return {
        ...state,
        uuid,
        name,
        is_default_collection
      };
    }
    case 'setName':
      return { ...state, name: action.payload.name };
    case 'setDefaultCollection':
      return {
        ...state,
        is_default_collection: action.payload.is_default_collection
      };
    case 'openMicroSearch':
      return {
        ...state,
        isMicroSearchOpen: true,
        microSearchForCompetencyUuid: action.payload.competencyUuid
      };
    case 'closeMicroSearch':
      return {
        ...state,
        isMicroSearchOpen: false,
        microSearchForCompetencyUuid: ''
      };
    case 'setShowValidationErrors':
      return {
        ...state,
        showValidationErrors: action.payload.showValidationErrors
      };
    case 'setHaveFetchedCompetencyCollections':
      return {
        ...state,
        haveFetchedCompetencyCollection: true
      };
    case 'clearForm':
      return initialCompetencyCollectionState;
    default:
      throw new Error();
  }
};

const setName = name => ({ type: 'setName', payload: { name } });

const setDefaultCollection = is_default_collection => ({
  type: 'setDefaultCollection',
  payload: { is_default_collection }
});

const setShowValidationErrors = showValidationErrors => ({
  type: 'setShowValidationErrors',
  payload: { showValidationErrors }
});

const setHaveFetchedCompetencyCollections = () => ({
  type: 'setHaveFetchedCompetencyCollections'
});

export {
  initialCompetencyCollectionState,
  competencyCollectionReducer,
  setName,
  setDefaultCollection,
  setShowValidationErrors,
  setHaveFetchedCompetencyCollections
};
