import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';

import { ContainerResize, ContainerPage } from '../../../Library/Layout';
import Header from '../../../Library/Header';

import { Column } from '../../../Library/FormComponents';
import SelectedMicrosPill from './SelectedMicrosPill';
import UnsavedChangesModal from '../../../Library/Modal/UnsavedChangesModal/UnsavedChangesModal';

import MicrocompetencySearch from './MicrocompetencySearch';
import EditTopicMicros from './EditTopicMicros';

const FormHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  min-height: 80px;
`;

const AddedMicrosDisplay = styled.div`
  margin-left: 0;
  margin-right: auto;
`;

const ButtonColumn = styled(Column)`
  && {
    width: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

const ContainerPageSC = styled(ContainerPage)`
  && {
    margin-top: 30px;
  }
`;

const ManageCompetencyMicros = ({
  view,
  editTopicUuid,
  searchDispatch,
  competency,
  searchResultTopics,
  showResults,
  searchType,
  addedMicros,
  micros,
  subtopics,
  topics,
  isUnsavedChangesModalOpen,
  unsavedChanges,
  handleModalClose,
  setUnsavedChange,
  removeUnsavedChange,
  handleProceed,
  editTopicMicros,
  onSearchClose,
  onSearchClick,
  onSubmit
}) => {
  const addSelectedDispatch = () => {
    setUnsavedChange(competency.uuid);
    switch (searchType) {
      case 'subtopic': {
        return searchDispatch({
          type: 'addSubtopicMicrosToSelected'
        });
      }
      case 'topic': {
        return searchDispatch({
          type: 'addTopicMicrosToSelected'
        });
      }
      default: {
        return searchDispatch({
          type: 'addMicrosToSelected'
        });
      }
    }
  };

  const removeTopicAndMicros = topicUuid => {
    setUnsavedChange(competency.uuid);
    searchDispatch({
      type: 'removeTopicAndMicros',
      payload: {
        topicUuid
      }
    });
  };

  const manageTopicMicros = (topicUuid, unsavedChanges) => {
    if (Object.keys(unsavedChanges).length > 0) {
      Object.keys(unsavedChanges).forEach(uuid => {
        if (uuid !== topicUuid || uuid !== competency.uuid) {
          removeUnsavedChange(uuid);
        }
      });
    }
    searchDispatch({
      type: 'setEditTopic',
      payload: {
        topicUuid
      }
    });
  };
  const formIsChanged =
    competency && competency.uuid
      ? Boolean(unsavedChanges[competency.uuid])
      : false;

  return (
    <ContainerResize>
      <UnsavedChangesModal
        modalOpen={isUnsavedChangesModalOpen}
        message="The form has unsaved changes. Click cancel to go back and update the form or click Discard Changes to return to the competency collection."
        proceedButtonText="Discard Changes"
        handleProceed={handleProceed}
        handleModalClose={handleModalClose}
      />
      <Header
        title="Add Microcompetencies - NEW PAGE"
        backButtonOverride={onSearchClose}
        subtitle={`${competency ? competency.name : ''}`}
      />
      <ContainerPageSC>
        <FormHeader>
          <AddedMicrosDisplay>
            {Object.entries(addedMicros).map(
              ([topicUuid, { topic, micros }]) => (
                <SelectedMicrosPill
                  key={topicUuid}
                  topic={topic}
                  microsMap={micros}
                  onDelete={() => removeTopicAndMicros(topicUuid)}
                  onClick={() => manageTopicMicros(topicUuid, unsavedChanges)}
                />
              )
            )}
          </AddedMicrosDisplay>
          <ButtonColumn>
            <ButtonPrimary onClick={onSubmit} disabled={!formIsChanged}>
              Save
            </ButtonPrimary>
          </ButtonColumn>
        </FormHeader>
        {view === 'search' ? (
          <MicrocompetencySearch
            searchType={searchType}
            topics={topics}
            subtopics={subtopics}
            micros={micros}
            showResults={showResults}
            onSearchClick={onSearchClick}
            addSelectedDispatch={addSelectedDispatch}
            searchDispatch={searchDispatch}
            searchResultTopics={searchResultTopics}
          />
        ) : (
          <EditTopicMicros
            competencyUuid={competency.uuid}
            setUnsavedChange={setUnsavedChange}
            removeUnsavedChange={removeUnsavedChange}
            addedMicros={addedMicros}
            unsavedChanges={unsavedChanges}
            editTopicMicros={editTopicMicros}
            topicUuid={editTopicUuid}
            dispatch={searchDispatch}
          />
        )}
      </ContainerPageSC>
    </ContainerResize>
  );
};

ManageCompetencyMicros.propTypes = {
  view: PropTypes.oneOf(['search', 'editTopic']),
  editTopicUuid: PropTypes.string,
  competency: PropTypes.object,
  showResults: PropTypes.bool,
  searchType: PropTypes.oneOf(['microcompetency', 'subtopic', 'topic', '']),
  searchResultTopics: PropTypes.arrayOf(PropTypes.object),
  addedMicros: PropTypes.object,
  micros: PropTypes.object,
  subtopics: PropTypes.object,
  topics: PropTypes.object,
  editTopicMicros: PropTypes.object,
  isUnsavedChangesModalOpen: PropTypes.bool,
  unsavedChanges: PropTypes.object,
  handleModalClose: PropTypes.func,
  setUnsavedChange: PropTypes.func,
  removeUnsavedChange: PropTypes.func,
  handleProceed: PropTypes.func,
  onSubmit: PropTypes.func,
  onSearchClose: PropTypes.func,
  onSearchClick: PropTypes.func,
  searchDispatch: PropTypes.func
};
ManageCompetencyMicros.defaultProps = {
  view: 'search',
  editTopicUuid: '',
  competency: { uuid: '', name: '' },
  showResults: false,
  searchType: 'microcompetency',
  searchResultTopics: [],
  addedMicros: {},
  micros: {},
  subtopics: {},
  topics: {},
  editTopicMicros: {},
  isUnsavedChangesModalOpen: false,
  unsavedChanges: {},
  handleModalClose: undefined,
  setUnsavedChange: undefined,
  removeUnsavedChange: undefined,
  handleProceed: undefined,
  onSubmit: undefined,
  onSearchClose: undefined,
  onSearchClick: undefined,
  searchDispatch: undefined
};

export default ManageCompetencyMicros;
