import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body2, Caption } from '@xcomp/xcomp-design-library';

import { Column } from '../../../../../../../Library/FormComponents';

const CompetencyDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CollectionRow = ({ group, index }) => {
  return (
    <Column size="100%">
      <CompetencyDetails>
        <Caption>Procedure Code Group {index + 1}</Caption>
        <Body2>{group.name}</Body2>
      </CompetencyDetails>
    </Column>
  );
};

CollectionRow.propTypes = {
  group: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default CollectionRow;
