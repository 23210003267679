import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';
import { sortByDecimalPropertyAndFullName } from '../../helpers/utilities';

export const partGradeResultSelector = createSelector(
  state => state,
  (_, partUuid, studentUuid) => ({
    partUuid,
    studentUuid
  }),
  (redux, { partUuid, studentUuid }) => {
    const resultsSelectorByPartAndStudentUuidORM = ormCreateSelector(
      orm.CalculatedGradeResult
    );
    const gradeResults = resultsSelectorByPartAndStudentUuidORM(redux).find(
      result =>
        result.partUuid === partUuid && result.studentUuid === studentUuid
    );
    return gradeResults;
  }
);

export const partGradeSortedResultsSelector = createSelector(
  state => state,
  (_, partUuid) => ({
    partUuid
  }),
  (redux, { partUuid }) => {
    const resultsSelectorByPartAndStudentUuidORM = ormCreateSelector(
      orm.CalculatedGradeResult
    );
    const gradeResults = resultsSelectorByPartAndStudentUuidORM(redux)
      .filter(result => result.partUuid === partUuid)
      .sort((a, b) =>
        sortByDecimalPropertyAndFullName(
          b,
          a,
          'calculatedGrade',
          'firstName',
          'lastName',
          true
        )
      );
    return gradeResults;
  }
);

export const collectionGradeResultSelector = createSelector(
  state => state,
  (_, gradeCollectionUuid, studentUuid) => ({
    gradeCollectionUuid,
    studentUuid
  }),
  (redux, { gradeCollectionUuid, studentUuid }) => {
    const resultsSelectorByPartAndStudentUuidORM = ormCreateSelector(
      orm.CalculatedGradeResult
    );
    const gradeResults = resultsSelectorByPartAndStudentUuidORM(redux).find(
      result =>
        result.gradeCollectionUuid === gradeCollectionUuid &&
        result.studentUuid === studentUuid
    );
    return gradeResults;
  }
);

export const assessmentGradeResultSelector = createSelector(
  state => state,
  (_, assessmentUuid, studentUuid) => ({
    assessmentUuid,
    studentUuid
  }),
  (redux, { assessmentUuid, studentUuid }) => {
    const resultsSelectorByPartAndStudentUuidORM = ormCreateSelector(
      orm.ResultCard
    );
    const gradeResults = resultsSelectorByPartAndStudentUuidORM(redux).find(
      result =>
        result.assessmentUuid === assessmentUuid &&
        result.studentUuid === studentUuid
    );
    return gradeResults;
  }
);

export const resultCardsByCollectionSelector = createSelector(
  state => state,
  (_, gradeCollectionUuid, studentUuid) => ({
    gradeCollectionUuid,
    studentUuid
  }),
  (redux, { gradeCollectionUuid, studentUuid }) => {
    const resultCardsByCollectionSelectorORM = ormCreateSelector(
      [orm],
      session => {
        const gradeCollection = session.GradeCollection.withId(
          gradeCollectionUuid
        );
        const collectionAssessmentUuids = gradeCollection.collection_assessments.map(
          collectionAssessment => collectionAssessment.assessmentUuid
        );
        const studentCollectionResultCards = session.ResultCard.all()
          .toRefArray()
          .filter(
            resultCard =>
              resultCard.studentUuid === studentUuid &&
              collectionAssessmentUuids.includes(resultCard.assessmentUuid)
          );

        return studentCollectionResultCards;
      }
    );
    const result = resultCardsByCollectionSelectorORM(redux);
    return result;
  }
);
