import fetch from '../helpers/fetch';

const getPart = partUuid =>
  fetch(`/api/part/${partUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getPartFaculty = partUuid =>
  fetch(`/api/part-faculty/${partUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchPartStatus = partStatus =>
  fetch('/api/part/status', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(partStatus)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchPartLocked = part =>
  fetch('/api/part/lock', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(part)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchPartCalculateGrade = partUuid =>
  fetch(`/api/part/${partUuid}/calculate-grade`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchPartUndoGradeCalculation = partUuid =>
  fetch(`/api/part/${partUuid}/delete-calculated-grades`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putPartStatusCommit = ({ uuid, ...rest }) =>
  fetch(`/api/part/${uuid}/commitment-status`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(rest)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putDelayedStudentPartGrade = ({ partUuid, studentUuid }) =>
  fetch(`/api/part/${partUuid}/student/${studentUuid}/grade-delayed-student`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putDelayedStudentPartCommitStatus = ({
  partUuid,
  studentUuid,
  delayedStatusCommit,
  justificationMessage
}) =>
  fetch(`/api/part/${partUuid}/student/${studentUuid}/commitment-status`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      delayedStatusCommit,
      justificationMessage
    })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteDelayedStudentPartGrade = ({ partUuid, studentUuid }) =>
  fetch(`/api/part/${partUuid}/student/${studentUuid}/delayed-student-grade`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postCloneExistingPart = ({ partUuid, partUuidExist }) =>
  fetch(`/api/part/${partUuid}/clone-existing-part/${partUuidExist}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getPart,
  getPartFaculty,
  patchPartCalculateGrade,
  patchPartStatus,
  patchPartLocked,
  patchPartUndoGradeCalculation,
  putPartStatusCommit,
  putDelayedStudentPartCommitStatus,
  deleteDelayedStudentPartGrade,
  putDelayedStudentPartGrade,
  postCloneExistingPart
};
