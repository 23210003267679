import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { competencyScoreSelector } from '../../../../redux/selectors/competencyScoreSelectors';

export default function CompetencyRelative({ competencyUuid }) {
  const score = useSelector(
    state => competencyScoreSelector(state, competencyUuid),
    _.isEqual
  );

  return <>{score?.relTotal || '- -'}</>;
}

CompetencyRelative.propTypes = {
  competencyUuid: PropTypes.string
};

CompetencyRelative.defaultProps = {
  competencyUuid: undefined
};
