import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonIcon } from '@xcomp/xcomp-design-library';
import ArtTrack from '@material-ui/icons/ArtTrack';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const Label = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  font-size: 20px;
  color: ${props => props.theme.fontColors.darker};
`;

const FilterButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 20px;
  gap: 10px;
`;

const ChipSC = styled(Chip)`
  //margin-left: 10px;
`;
const Layout = styled.div`
  width: 100%;
  display: flex;
`;

const FilterButton = ({ filters, selectedFilter, selectFilter, labelAll }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Layout>
      <ButtonIcon
        aria-controls="menu"
        aria-owns={anchorEl ? 'menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
        noHoverBg
      >
        <ChipSC
          icon={<ArtTrack />}
          size="small"
          label={filters.find(f => f.uuid === selectedFilter)?.name || labelAll}
        />
      </ButtonIcon>

      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {filters.map(at => (
          <MenuItem
            onClick={event => {
              handleClose(event);
              selectFilter(at.uuid);
            }}
          >
            {at.name}
          </MenuItem>
        ))}
      </Menu>
    </Layout>
  );
};

FilterButton.propTypes = {
  filters: PropTypes.array,
  selectedFilter: PropTypes.array,
  selectFilter: PropTypes.func,
  labelAll: PropTypes.string
};

FilterButton.defaultProps = {
  filters: [],
  selectedFilter: [],
  selectFilter: () => {},
  labelAll: 'All'
};

const AttemptsAccordionLabel = ({
  assessmentTypes,
  assessmentTechniques,
  selectedAssessmentType,
  selectedAssessmentTechnique,
  setSelectedAssessmentType,
  setSelectedAssessmentTechnique,
  isDidactic
}) => (
  <Label>
    <Typography style={{ fontSize: '20px', textWrap: 'nowrap' }}>
      {isDidactic ? "Basic and Applied Didactic" : "Qualitative Skills and Documents"}
    </Typography>
    <FilterButtonsContainer>
      {assessmentTypes.length > 0 && (
        <FilterButton
          filters={assessmentTypes}
          selectedFilter={selectedAssessmentType}
          selectFilter={setSelectedAssessmentType}
          labelAll="All assessment types"
        />
      )}
      {assessmentTechniques.length > 0 && (
        <FilterButton
          filters={assessmentTechniques}
          selectedFilter={selectedAssessmentTechnique}
          selectFilter={setSelectedAssessmentTechnique}
          labelAll="All assessment techniques"
        />
      )}
    </FilterButtonsContainer>
  </Label>
);

AttemptsAccordionLabel.propTypes = {
  assessmentTypes: PropTypes.array,
  assessmentTechniques: PropTypes.array,
  selectedAssessmentType: PropTypes.string,
  selectedAssessmentTechnique: PropTypes.string,
  setSelectedAssessmentType: PropTypes.func,
  setSelectedAssessmentTechnique: PropTypes.func,
  isDidactic: PropTypes.bool,
};

AttemptsAccordionLabel.defaultProps = {
  isDidactic: false,
  assessmentTypes: [],
  assessmentTechniques: [],
  selectedAssessmentType: null,
  selectedAssessmentTechnique: null,
  setSelectedAssessmentType: () => {},
  setSelectedAssessmentTechnique: () => {}
};

export default AttemptsAccordionLabel;
