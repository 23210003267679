import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import FormField from '../../../../Library/FormField';
import { validateLoverbTextFieldEdit } from '../../../../../helpers/validation/validateEducationLevelLoverb';

const Center = styled.div`
  margin-top: 30px;
  margin-left: 10px;
`;

export default function LoverbEdit({
  indentifier,
  objectiveVerb,
  loverbUuid,
  loverbs,
  loverbsRedux,
  hasErrors,
  handleLoverbChange,
  handleRemoveLoverb
}) {
  return (
    <>
      <Grid item xs={11}>
        <FormField
          name="objectiveVerb"
          label="Objective Verb*"
          value={objectiveVerb}
          onBlur={event => handleLoverbChange(indentifier, event)}
          handleValidate={() =>
            validateLoverbTextFieldEdit(
              objectiveVerb,
              loverbUuid,
              loverbs,
              loverbsRedux
            )
          }
          hasErrors={hasErrors}
        />
      </Grid>
      <Grid item xs={1}>
        <Center>
          <DeleteIcon onClick={() => handleRemoveLoverb(indentifier)} />
        </Center>
      </Grid>
    </>
  );
}

LoverbEdit.propTypes = {
  indentifier: PropTypes.string,
  objectiveVerb: PropTypes.string,
  loverbUuid: PropTypes.string,
  loverbs: PropTypes.array,
  loverbsRedux: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleLoverbChange: PropTypes.func,
  handleRemoveLoverb: PropTypes.func
};

LoverbEdit.defaultProps = {
  indentifier: '',
  objectiveVerb: '',
  loverbUuid: undefined,
  loverbs: [],
  loverbsRedux: [],
  hasErrors: false,
  handleLoverbChange: undefined,
  handleRemoveLoverb: undefined
};
