import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CSVReader from 'react-csv-reader';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonPin from '@material-ui/icons/PersonPin';
import { ButtonPrimary, ButtonOutline } from '@xcomp/xcomp-design-library';
import Modal from '../../../Library/Modal/Modal';
import UploadMapping from '../../../Library/UploadMapping';
import UploadErrors from '../../../Library/UploadErrors';
import { headersApplicantRoles } from '../../../../helpers/headers_upload/headers_roles';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 900px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  margin-bottom: 20px;
`;

const FileUpload = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Note = styled.div`
  margin-top: 10px;
  font-size: 12px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Close = styled(ButtonOutline)`
  && {
    margin-right: 5px;
  }
`;

const Import = styled(ButtonPrimary)`
  && {
    margin-left: 5px;
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Created = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.status.success};
  border-radius: 5px;
  color: ${props => props.theme.colors.white};
  display: flex;
  margin-top: 15px;
  padding: 15px;
`;

export default function CohortUploadApplicants({
  uploadState,
  applicants,
  openModal,
  openMapping,
  handleCloseApplicantUpload,
  handleApplicants,
  handleUpload,
  handleDisplayMapping
}) {
  const { uploadingApplicants, applicantsCreated } = uploadState;

  return (
    <Modal open={openModal} onClose={handleCloseApplicantUpload}>
      <ModalBody>
        <Title>Mass Upload Applicants (CSV)</Title>
        <FileUpload>
          <CSVReader
            onFileLoaded={handleApplicants}
            parserOptions={{ header: true, skipEmptyLines: 'greedy' }}
            inputId="userload"
            inputStyle={{
              width: '100%',
              color: 'black',
              background: 'lightgrey',
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '10px',
              borderRadius: '5px'
            }}
          />
          <Note>
            If changes are made to an existing selected file, close this modal
            and re-open then re-select the file.
          </Note>
        </FileUpload>
        <Actions>
          <ButtonPrimary onClick={handleDisplayMapping}>
            {openMapping ? `Hide Rules` : `Show Rules`}
          </ButtonPrimary>
          <div>
            <Close onClick={handleCloseApplicantUpload}>Close</Close>
            <Import disabled={!applicants} onClick={handleUpload}>
              Import
            </Import>
          </div>
        </Actions>

        {uploadingApplicants && (
          <Loading>
            <CircularProgress size="100px" />
          </Loading>
        )}
        {applicantsCreated > 0 && (
          <Created>
            <PersonPin />
            {applicantsCreated} applicants created
          </Created>
        )}
        <UploadMapping display={openMapping} fields={headersApplicantRoles} />
        <UploadErrors uploadState={uploadState} />
      </ModalBody>
    </Modal>
  );
}

CohortUploadApplicants.propTypes = {
  uploadState: PropTypes.object,
  applicants: PropTypes.array,
  applicantsCreated: PropTypes.number,
  openModal: PropTypes.bool,
  openMapping: PropTypes.bool,
  handleCloseApplicantUpload: PropTypes.func,
  handleApplicants: PropTypes.func,
  handleUpload: PropTypes.func,
  handleDisplayMapping: PropTypes.func
};

CohortUploadApplicants.defaultProps = {
  uploadState: {},
  applicants: undefined,
  applicantsCreated: 0,
  openModal: false,
  openMapping: false,
  handleCloseApplicantUpload: undefined,
  handleApplicants: undefined,
  handleUpload: undefined,
  handleDisplayMapping: undefined
};
