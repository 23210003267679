import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  getTotalNumberOfCommittedAssessments,
  getTotalNumberOfUncommittedAssessments,
  getTotalNumberofAssessments
} from '../../../../../helpers/parts';
import Overline from '../../../../Library/Overline';
import { StatusChip } from '../../../../Library/StatusChipsSC';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StatusTypeHeader = styled(Overline)`
  margin: 0 0 12px;
  line-height: 1.6;
  font-size: 0.75rem;
  font-weight: 400;
`;

const CommitStatusSummary = ({ part, className }) => {
  const totalNumberOfCommittedAssessments = getTotalNumberOfCommittedAssessments(
    part
  );
  const totalNumberOfUncommittedAssessments = getTotalNumberOfUncommittedAssessments(
    part
  );
  const totalNumberofAssessments = getTotalNumberofAssessments(part);

  return (
    <Layout className={className}>
      <StatusTypeHeader>Assessments</StatusTypeHeader>
      <StatusChip
        isComplete={
          totalNumberOfUncommittedAssessments !== 0 &&
          totalNumberOfUncommittedAssessments === totalNumberofAssessments
        }
      >
        {`${totalNumberOfUncommittedAssessments} / ${totalNumberofAssessments} Uncommitted`}
      </StatusChip>
      <StatusChip
        isComplete={
          totalNumberOfCommittedAssessments !== 0 &&
          totalNumberOfCommittedAssessments === totalNumberofAssessments
        }
      >
        {`${totalNumberOfCommittedAssessments} / ${totalNumberofAssessments} Committed`}
      </StatusChip>
    </Layout>
  );
};

CommitStatusSummary.propTypes = {
  part: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string
};

CommitStatusSummary.defaultProps = {
  part: {},
  className: ''
};

export default CommitStatusSummary;
