import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { doPostCheckUsers } from '../../../../redux/actions/userActions';

import UserTabCheckUpload from './UserTabCheckUpload';

export default function UserTabCheckUploadContainer({
  openCheckUpload,
  handleCheckClose
}) {
  const dispatch = useDispatch();
  const [records, setRecords] = useState(undefined);
  const [openMapping, setOpenMapping] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleRecords = records => {
    setRecords(records);
  };

  const handleCheck = () => {
    dispatch(doPostCheckUsers(records));
    handleCheckClose();
  };

  const handleDisplayMapping = () => {
    setOpenMapping(openMapping => !openMapping);
  };

  const handleDisplayErrors = () => {
    setOpenError(openError => !openError);
  };

  return (
    <UserTabCheckUpload
      records={records}
      openCheckUpload={openCheckUpload}
      openMapping={openMapping}
      openError={openError}
      handleDisplayMapping={handleDisplayMapping}
      handleCheck={handleCheck}
      handleRecords={handleRecords}
      handleCheckClose={handleCheckClose}
      handleDisplayErrors={handleDisplayErrors}
    />
  );
}

UserTabCheckUploadContainer.propTypes = {
  openCheckUpload: PropTypes.bool,
  handleCheckClose: PropTypes.func
};

UserTabCheckUploadContainer.defaultProps = {
  openCheckUpload: false,
  handleCheckClose: undefined
};
