import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Body1,
  Heading2,
  lightenDarkenColor
} from '@xcomp/xcomp-design-library';
import { useSelector } from 'react-redux';
import SimpleModal from '../../Library/Modal/SimpleModal';
import { selectPartFaculty } from '../../../redux/selectors/partFacultySelectors';

const StaffDisplay = styled(Body1)`
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
`;

const ModalBody = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;

  h3 {
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

const InlineOpenModalButton = styled.button`
  display: inline;
  font-size: inherit;
  background: 0;
  border: none;
  color: ${props => props.theme.colors.primary};
  overflow: visible;
  text-transform: none;

  &[type='button'],
  &[type='reset'],
  &[type='submit'] {
    -webkit-appearance: button;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    color: ${props =>
      lightenDarkenColor(props.theme.colors.primary, -60)};
    cursor: pointer;

    transform: scale(1.04);
  }
`;

export default function PartFaculty({ partUuid }) {
  const partFaculty = useSelector(state => selectPartFaculty(state, partUuid));

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const shortListOfFaculty = partFaculty.slice(0, 3).map((faculty, index) => {
    const isLastFaculty = index === 2;
    return `${faculty.firstName} ${faculty.lastName}${
      isLastFaculty ? '...' : ', '
    }`;
  });

  const allFaculty = partFaculty.map((faculty, index) => {
    const isLastFaculty = index === partFaculty.length - 1;
    return `${faculty.firstName} ${faculty.lastName}${
      isLastFaculty ? '' : ', '
    }`;
  });

  const numUnshownFaculty = partFaculty.length - 3;
  return (
    <>
      <StaffDisplay>
        <>
          {shortListOfFaculty}
          <InlineOpenModalButton onClick={handleModalOpen} noHoverBg>
            and {numUnshownFaculty} more
          </InlineOpenModalButton>
        </>
      </StaffDisplay>
      <SimpleModal
        open={Boolean(isModalOpen)}
        onClose={handleModalClose}
        maxWidth="md"
      >
        <ModalBody>
          <Heading2>Part Faculty</Heading2>
          <StaffDisplay>{allFaculty}</StaffDisplay>
        </ModalBody>
      </SimpleModal>
    </>
  );
}

PartFaculty.propTypes = {
  partUuid: PropTypes.string
};

PartFaculty.defaultProps = {
  partUuid: undefined
};
