import { call, put, takeEvery } from 'redux-saga/effects';

import { GET_COHORT_ASSESSMENT_GRID } from '../actions-type/assessmentGridActionTypes';
import { doGetCohortAssessmentGridError, doGetCohortAssessmentGridSuccess } from '../actions/assessmentGridActions';
import { getCohortAssessmentGridReport } from '../../api/assessmentGridApi';

function* handleGetCohortAssessmentGrid(action) {
  const { cohortUuid } = action.payload;

  try {
    const result = yield call(
      getCohortAssessmentGridReport,
      cohortUuid,
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCohortAssessmentGridSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetCohortAssessmentGridError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doGetCohortAssessmentGridError());
  }
}

const cohortAssessmentGrid = [
  takeEvery(GET_COHORT_ASSESSMENT_GRID, handleGetCohortAssessmentGrid)
];

export { cohortAssessmentGrid };
