import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { ButtonPrimary, ButtonDelete } from '@xcomp/xcomp-design-library';
import Header from '../../../Library/Header';
import CourseForm from '../CourseForm';
import CourseSection from '../CourseSection/CourseSection';
import FormErrors from '../../../Library/FormErrors';
import {
  ContainerResize,
  ContainerPage,
  ContainerActions
} from '../../../Library/Layout';
import { validateCoursePartsExist } from '../../../../helpers/validation/validateCourse';
import LoadingDimmer from '../../../Library/LoadingDimmer';

const ErrorMessageSC = styled.div`
  font-size: 0.875rem;
  color: ${props => props.theme.colors.status.error};
  border: 1px solid ${props => props.theme.colors.status.error};
  background-color: ${props => props.theme.colors.status.error};
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 0.75rem 1.125rem;
  display: inline-block;

  span {
    display: block;
  }
`;

export const LinkSC = styled(Link)`
  display: flex;
  justify-content: center;
`;

const DeleteRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8rem;
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
`;

const LockMessage = styled.div`
  width: 350px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  padding-left: 10px;
`;

const CourseEdit = ({
  hasErrors,
  courseLock,
  handleDeleteModalOpen,
  handleUnlockCourse,
  handleLockCourse,
  ...props
}) => {
  const partErrors = validateCoursePartsExist(props.sections);
  const sectionErrors = !props.sections || props.sections.length < 1;
  const sectionPartErrors = Boolean(partErrors || sectionErrors);
  const isLoading = useSelector(
    state => state?.courseEditState?.isLoading || false,
    isEqual
  );

  return (
    <ContainerResize>
      <Header title="Course Sections" subtitle="Edit" />
      <LoadingDimmer isLoading={isLoading} />
      <ContainerActions>
        <ButtonPrimary variant="outlined" onClick={props.onPutCourse}>
          SAVE
        </ButtonPrimary>
      </ContainerActions>
      <ContainerPage paddingTop="40px">
        <FormErrors hasErrors={hasErrors} />
        {hasErrors && sectionPartErrors && (
          <ErrorMessageSC>
            {sectionErrors && <span>You must create at least one section</span>}
            {partErrors && <span>{partErrors.message}</span>}
          </ErrorMessageSC>
        )}
        <CourseForm hasErrors={hasErrors} {...props} />
        <CourseSection hasErrors={hasErrors} {...props} />

        {courseLock ? (
          <DeleteRow>
            <ButtonDelete>delete this course</ButtonDelete>
            <Item onClick={() => handleUnlockCourse()}>
              <Lock />
              <LockMessage>
                This course section is locked from deletion. Click the lock to
                enable deletion of this course
              </LockMessage>
            </Item>
          </DeleteRow>
        ) : (
          <DeleteRow>
            <ButtonDelete onClick={() => handleDeleteModalOpen()}>
              delete this course
            </ButtonDelete>
            <Item onClick={() => handleLockCourse()}>
              <LockOpen />
              <LockMessage>
                This course section is unlocked and can be deleted. Click the
                lock to prevent deletion of this course
              </LockMessage>
            </Item>
          </DeleteRow>
        )}
      </ContainerPage>
    </ContainerResize>
  );
};

CourseEdit.propTypes = {
  departments: PropTypes.arrayOf(PropTypes.object),
  terms: PropTypes.arrayOf(PropTypes.object),
  academicYears: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  onPutCourse: PropTypes.func,
  hasErrors: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.object),
  facultyUsers: PropTypes.arrayOf(PropTypes.object),
  courseLock: PropTypes.bool,
  onPutCourseLock: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func,
  handleUnlockCourse: PropTypes.func,
  handleLockCourse: PropTypes.func
};

CourseEdit.defaultProps = {
  departments: [],
  academicYears: [],
  terms: [],
  users: [],
  onPutCourse: undefined,
  hasErrors: false,
  sections: [],
  facultyUsers: [],
  courseLock: false,
  onPutCourseLock: undefined,
  handleDeleteModalOpen: undefined,
  handleUnlockCourse: undefined,
  handleLockCourse: undefined
};

export default CourseEdit;
export { ErrorMessageSC };
