import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { courseMasterSelector } from '../../../redux/selectors/coursesMasterSelectors';
import { departmentsBySchoolStateSelector } from '../../../redux/selectors/departmentsSelectors';
import { doGetSchoolDepartments } from '../../../redux/actions/departmentActions';

import {
  doDeleteCourseMaster,
  doGetCourseMaster,
  doPutCourseMaster
} from '../../../redux/actions/courseMasterActions';

import DeleteModalRedirect from '../../Library/Modal/DeleteModal/DeleteModalRedirect';
import CourseMasterEdit from './CourseMasterEdit';

import {
  validateCourseTitle,
  validateCourseHours,
  validateCourseDescription,
  validateCourseAliases,
  validateCourseAliasesCourseMaster
} from '../../../helpers/validation/validateCourseMaster';

import { validateSelectField } from '../../../helpers/validation/validateGeneric';
import { doGeneralErrorNotification } from '../../../redux/actions/notificationActions';

export default function CourseMasterEditContainer({ ...props }) {
  const [uuid, setUuid] = useState('');
  const [departmentUuid, setDepartmentUuid] = useState('');
  const [courseNumber, setCourseNumber] = useState('');
  const [title, setTitle] = useState('');
  const [hours, setHours] = useState(0);
  const [hasErrors, setHasFormErrors] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [alias, setAlias] = useState([]);
  const [aliasRemoval, setAliasRemoval] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const { courseMasterUuid } = props.match.params;

  const dispatch = useDispatch();
  const courseMaster = useSelector(
    state => courseMasterSelector(state, courseMasterUuid),
    shallowEqual
  );

  const { course: courseDescription } = useSelector(
    state => state.descriptionState
  );

  const schoolDepartments = useSelector(
    state => departmentsBySchoolStateSelector(state),
    _.isEqual
  );

  const selectedSchoolUuid = useSelector(
    state => state.userState.selectedSchoolUuid
  );

  useEffect(() => {
    dispatch(doGetSchoolDepartments(selectedSchoolUuid));
    dispatch(doGetCourseMaster(courseMasterUuid));
  }, [dispatch, selectedSchoolUuid, courseMasterUuid]);

  useEffect(() => {
    if (courseMaster) {
      setUuid(courseMaster.uuid);
      setDepartmentUuid(courseMaster.departmentUuid);
      setCourseNumber(courseMaster.courseNumber);
      setTitle(courseMaster.title);
      setHours(courseMaster.hours);

      const aliasWithIdentifier = courseMaster.alias.map(record => ({
        ...record,
        indentifier: uuidv4()
      }));

      setAlias(aliasWithIdentifier);
    }
  }, [courseMaster]);

  const handleChange = useCallback(event => {
    const { name, value } = event.target;

    switch (name) {
      case 'departmentUuid':
        setDepartmentUuid(value);
        break;
      case 'courseNumber':
        setCourseNumber(value);
        break;
      case 'title':
        setTitle(value);
        break;
      case 'hours':
        setHours(value);
        break;
      default:
        break;
    }
  }, []);

  const handleAddCourseAlias = useCallback(() => {
    const addAlias = {
      indentifier: uuidv4().toString(),
      courseMasterAliasUuid: '',
      departmentUuid: '',
      courseMasterUuid
    };

    setAlias([...alias, addAlias]);
  }, [alias, courseMasterUuid]);

  const handleCourseAliasUuidChange = useCallback(
    (indentifier, event) => {
      const { value } = event.target;

      const newState = alias.map(a => {
        if (a.indentifier === indentifier) {
          return { ...a, courseMasterAliasUuid: value };
        } else {
          return a;
        }
      });

      setAlias(newState);
    },
    [alias]
  );

  const handleCourseAliasChange = useCallback(
    (indentifier, event) => {
      const { value } = event.target;

      const newState = alias.map(department => {
        if (department.indentifier === indentifier) {
          return { ...department, departmentUuid: value };
        } else {
          return department;
        }
      });

      setAlias(newState);
    },
    [alias]
  );

  const handleRemoveCourseAlias = useCallback(
    indentifier => {
      const aliasToDelete = _.find(
        alias,
        department => department.indentifier === indentifier && department.uuid
      );

      if (aliasToDelete) {
        setAliasRemoval([...aliasRemoval, aliasToDelete]);
      }

      const newState = _.filter(
        alias,
        record => record.indentifier !== indentifier
      );

      setAlias(newState);
    },
    [alias, aliasRemoval]
  );

  const validateForm = useCallback(() => {
    const departmentError = validateSelectField(departmentUuid);
    const titleError = validateCourseTitle(title);
    const hoursError = validateCourseHours(hours);
    const descriptionError = validateCourseDescription(courseDescription);
    const aliasError = validateCourseAliases(alias);
    const courseMasterAliasUuidError = validateCourseAliasesCourseMaster(alias);

    const errors = {
      hasError:
        departmentError.invalid ||
        titleError.invalid ||
        hoursError.invalid ||
        descriptionError.invalid ||
        aliasError.invalid ||
        courseMasterAliasUuidError.invalid,
      message:
        departmentError.message ||
        titleError.message ||
        hoursError.message ||
        descriptionError.message ||
        aliasError.message ||
        courseMasterAliasUuidError.message
    };

    if (errors.hasError) {
      dispatch(doGeneralErrorNotification(errors.message));
      return true;
    }

    return false;
  }, [
    alias,
    courseDescription,
    departmentUuid,
    dispatch,
    hours,
    title
  ]);

  const handleUpdateCourse = () => {
    const hasValidationErrors = validateForm();

    if (hasValidationErrors) {
      setHasFormErrors(true);
    } else {
      const payload = {
        uuid,
        departmentUuid,
        courseNumber,
        title,
        hours,
        description: courseDescription,
        alias,
        aliasRemoval
      };

      setRedirect(true);
      dispatch(doPutCourseMaster(payload));
    }
  };
  const handleDeleteModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleDeleteModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);
  const handleDelete = useCallback(() => {
    dispatch(doDeleteCourseMaster(uuid));
    setModalOpen(false);
  }, [dispatch, uuid]);

  return (
    <div>
      <CourseMasterEdit
        selectedDepartment={departmentUuid}
        courseNumber={courseNumber}
        title={title}
        hours={hours}
        alias={alias}
        redirect={redirect}
        schoolDepartments={schoolDepartments}
        hasErrors={hasErrors}
        handleChange={handleChange}
        handleUpdateCourse={handleUpdateCourse}
        handleAddCourseAlias={handleAddCourseAlias}
        handleCourseAliasUuidChange={(indentifier, event) =>
          handleCourseAliasUuidChange(indentifier, event)
        }
        handleCourseAliasChange={(indentifier, event) =>
          handleCourseAliasChange(indentifier, event)
        }
        handleRemoveCourseAlias={indentifier =>
          handleRemoveCourseAlias(indentifier)
        }
        handleDeleteModalOpen={handleDeleteModalOpen}
        course={courseMaster}
      />
      <DeleteModalRedirect
        modalOpen={modalOpen}
        type="course parent"
        link="/course-management/all"
        handleModalClose={handleDeleteModalClose}
        handleDelete={handleDelete}
      />
    </div>
  );
}

CourseMasterEditContainer.propTypes = {
  match: PropTypes.object
};

CourseMasterEditContainer.defaultProps = {
  match: {}
};
