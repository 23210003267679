import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import ArtTrack from '@material-ui/icons/ArtTrack';
import { ContainerPage, ElevateLow } from '../../../Library/Layout';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import ByDefaultSourceContainer from '../TranscriptTable/ByDefault/ByDefaultSourceContainer';
import ByScopeCognitiveSubjectContainer from '../TranscriptTable/ByScopeCognitive/ByScopeCognitiveSubjectContainer';
import ByInstitutionSourceContainer from '../TranscriptTable/ByInstitutionCognitive/ByInstitutionSourceContainer';
import ByTermCognitiveSourceContainer from '../TranscriptTable/ByTermCognitive/ByTermCognitiveSourceContainer';
import CognitiveAverageScore from '../TranscriptCalculations/CognitiveAverageScore';
import CognitiveGrade from '../TranscriptCalculations/CognitiveGrade';
import CognitiveTotalCredits from '../TranscriptCalculations/CognitiveTotalCredits';
import AptitudeAverageScore from '../TranscriptCalculations/AptitudeAverageScore';
import AptitudeGrade from '../TranscriptCalculations/AptitudeGrade';
import AptitudeWeight from '../TranscriptCalculations/AptitudeWeight';
import AptitudeCompletion from '../TranscriptCalculations/AptitudeCompletion';
import RankCategory from '../TranscriptCalculations/RankCategory';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .accordion_row_label {
    width: 100%;
  }

  .column_one {
    width: 100%;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }
`;

const Calculations = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const Spacing = styled.div`
  display: flex;
  width: 120px;
`;

const ChipSC = styled(Chip)``;

const Stub = styled.div`
  width: 66px;
`;

const Label = styled.div`
  font-size: 20px;
  color: ${props => props.theme.fontColors.darker};
  display: flex;
`;

const LabelTitle = styled.div`
  margin-right: 20px;
`;

const LabelChip = styled.div``;

const LayoutHeaders = styled.div`
  position: sticky;
  top: 141px;
  z-index: 1300;
  background: ${props => props.theme.colors.white};
  margin-top: 20px;
  width: 100%;
`;

const CalculationsTitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  color: ${props => props.theme.fontColors.darker};
`;

export default function TranscriptCategory({
  userUuid,
  cohortUuid,
  programUuid,
  isAdmin,
}) {
  const [view, setView] = useState('default');

  const category_cognitive = 'Cognitive';
  const categories = ['Non-Cognitive', 'Physical-Skills', 'Communication'];

  const sources = ['Program', 'Milestone'];

  function handleSetView(choice) {
    setView(choice);
  }

  function categoryActions() {
    return [
      {
        label: 'Default',
        onClick: () => handleSetView('default'),
        shouldOpenOnClick: false
      },
      {
        label: 'By Term',
        onClick: () => handleSetView('term'),
        shouldOpenOnClick: false
      },
      {
        label: `By Subject`,
        onClick: () => handleSetView('subject'),
        shouldOpenOnClick: false
      },
      {
        label: `By Institution`,
        onClick: () => handleSetView('institution'),
        shouldOpenOnClick: false
      }
    ];
  }

  function viewDisplays() {
    switch (view) {
      case 'default': {
        return sources?.map(source => (
          <ByDefaultSourceContainer
            key={source}
            view={view}
            source={source}
            userUuid={userUuid}
            cohortUuid={cohortUuid}
            programUuid={programUuid}
            category={category_cognitive}
            isAdmin={isAdmin}
          />
        ));
      }
      case 'term': {
        return (
          <ByTermCognitiveSourceContainer
            userUuid={userUuid}
            cohortUuid={cohortUuid}
            programUuid={programUuid}
            category={category_cognitive}
            isAdmin={isAdmin}
          />
        );
      }
      case 'subject': {
        return (
          <ByScopeCognitiveSubjectContainer
            userUuid={userUuid}
            cohortUuid={cohortUuid}
            programUuid={programUuid}
            category={category_cognitive}
            isAdmin={isAdmin}
          />
        );
      }
      case 'institution': {
        return (
          <ByInstitutionSourceContainer
            userUuid={userUuid}
            cohortUuid={cohortUuid}
            programUuid={programUuid}
            category={category_cognitive}
            isAdmin={isAdmin}
          />
        );
      }
      default:
        break;
    }
  }

  return (
    <ContainerPage paddingTop="35px">
      <LayoutHeaders>
        <CalculationsTitles>
          <Spacing>Rank</Spacing>
          <Spacing>Credits</Spacing>
          <Spacing>Grade</Spacing>
          <Spacing>Score</Spacing>
          <Stub>&nbsp;</Stub>
        </CalculationsTitles>
      </LayoutHeaders>

      <ElevateLow key={category_cognitive}>
        <Accordion
          withBottomBorder={true}
          expandWidth="66px"
          height="90px"
          label={
            <Label>
              <LabelTitle>{category_cognitive}</LabelTitle>
              <LabelChip>
                <ChipSC
                  icon={<ArtTrack />}
                  size="small"
                  label={`VIEW BY ${view.toUpperCase()}`}
                />
              </LabelChip>
            </Label>
          }
          columnOneClassName="column_one"
          columnOne={
            <>
              <Calculations>
                <Spacing>
                  <RankCategory
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    category="Cognitive"
                  />
                </Spacing>
                <Spacing>
                  <CognitiveTotalCredits
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    category="Cognitive"
                  />
                </Spacing>
                <Spacing>
                  <CognitiveGrade
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    category="Cognitive"
                  />
                </Spacing>
                <Spacing>
                  <CognitiveAverageScore
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    category="Cognitive"
                  />
                </Spacing>
              </Calculations>
            </>
          }
          actions={categoryActions()}
        >
          {viewDisplays()}
        </Accordion>
      </ElevateLow>
      <LayoutHeaders>
        <CalculationsTitles>
          <Spacing>Rank</Spacing>
          <Spacing>Weight</Spacing>
          <Spacing>Grade</Spacing>
          <Spacing>Score</Spacing>
          <Stub>&nbsp;</Stub>
        </CalculationsTitles>
      </LayoutHeaders>
      {categories?.map(category => (
        <ElevateLow key={category}>
          <Accordion
            withBottomBorder={true}
            expandWidth="66px"
            height="90px"
            label={
              <Label>
                <LabelTitle>{category}</LabelTitle>
                <LabelChip>
                  <AptitudeCompletion
                    programUuid={programUuid}
                    userUuid={userUuid}
                    cohortUuid={cohortUuid}
                    category={category}
                  />
                </LabelChip>
              </Label>
            }
            columnOneClassName="column_one"
            columnOne={
              <>
                <Calculations>
                  <Spacing>
                    <RankCategory
                      programUuid={programUuid}
                      userUuid={userUuid}
                      cohortUuid={cohortUuid}
                      category={category}
                    />
                  </Spacing>
                  <Spacing>
                    <AptitudeWeight
                      programUuid={programUuid}
                      category={category}
                    />
                  </Spacing>
                  <Spacing>
                    <AptitudeGrade
                      programUuid={programUuid}
                      userUuid={userUuid}
                      cohortUuid={cohortUuid}
                      category={category}
                    />
                  </Spacing>
                  <Spacing>
                    <AptitudeAverageScore
                      programUuid={programUuid}
                      userUuid={userUuid}
                      cohortUuid={cohortUuid}
                      category={category}
                    />
                  </Spacing>
                  <Stub>&nbsp;</Stub>
                </Calculations>
              </>
            }
          >
            {sources?.map(source => (
              <ByDefaultSourceContainer
                key={source}
                view={view}
                source={source}
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                programUuid={programUuid}
                category={category}
                isAdmin={isAdmin}
              />
            ))}
          </Accordion>
        </ElevateLow>
      ))}
    </ContainerPage>
  );
}

TranscriptCategory.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  isAdmin: PropTypes.bool,
};

TranscriptCategory.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  isAdmin: false,
};
