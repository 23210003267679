import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import SubjectLevelContainer from '../SubjectLevel/SubjectLevelContainer';
import InstitutionCredits from './InstitutionCredits';
import InstitutionRigor from './InstitutionRigor';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .accordion_row_label {
    width: 100%;
  }

  .column_one {
    width: 100%;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }
`;

const Layout = styled.div``;

const LayoutHeaders = styled.div`
  position: sticky;
  top: 141px;
  z-index: 1300;
  background: ${props => props.theme.colors.white};
`;

const CalculationsTitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  color: ${props => props.theme.fontColors.darker};
`;

const Calculations = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: ${props => props.theme.fontColors.darker};
`;

const Input = styled.div`
  width: 130px;
  font-size: 14px;
`;

const Report = styled.div`
  overflow-y: hidden;
`;

const Stub = styled.div`
  width: 130px;
`;

const LabelBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelName = styled.div`
  font-size: 12px;
  margin-right: 10px;
`;

const LabelInput = styled.div`
  font-size: 14px;
  color: ${props => props.theme.fontColors.darker};
`;

export default function InstitutionLevel({
  displayName,
  programUuid,
  rigorInstitutions
}) {
  return (
    <Layout>
      {rigorInstitutions?.length > 0 && (
        <LayoutHeaders>
          <CalculationsTitles>
            <Input>Credits</Input>
            <Input>Program Score</Input>
            <Input>Milestone Score</Input>
            <Input>Rigor Index</Input>
          </CalculationsTitles>
        </LayoutHeaders>
      )}
      <Report>
        {rigorInstitutions?.map(institutionLevel => (
          <Accordion
            key={institutionLevel?.uuid}
            withBottomBorder={true}
            expandWidth="66px"
            height="50px"
            label={
              <LabelBox>
                <LabelName>Institution</LabelName>
                <LabelInput>{institutionLevel?.name}</LabelInput>
              </LabelBox>
            }
            columnOne={
              <Calculations>
                <Input>
                  <InstitutionCredits
                    programUuid={programUuid}
                    sourceInstitutionUuid={institutionLevel?.uuid}
                  />
                </Input>
                <Stub>&nbsp;</Stub>
                <Stub>&nbsp;</Stub>
                <Input>
                  <InstitutionRigor
                    programUuid={programUuid}
                    sourceInstitutionUuid={institutionLevel?.uuid}
                  />
                </Input>
              </Calculations>
            }
            columnOneClassName="column_one"
          >
            <SubjectLevelContainer
              displayName={displayName}
              programUuid={programUuid}
              sourceInstitutionUuid={institutionLevel?.uuid}
            />
          </Accordion>
        ))}
      </Report>
    </Layout>
  );
}

InstitutionLevel.propTypes = {
  displayName: PropTypes.bool,
  programUuid: PropTypes.string,
  rigorInstitutions: PropTypes.array
};

InstitutionLevel.defaultProps = {
  displayName: false,
  programUuid: undefined,
  rigorInstitutions: []
};
