import Decimal from 'decimal.js-light';
import _ from 'lodash';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { createSelector } from 'reselect';
import { orm } from '../models';
import { assessmentTypesMap } from '../../helpers/constants';

export const assessmentSelector = createSelector(
  state => state,
  (_, assessmentUuid) => assessmentUuid,
  (redux, assessmentUuid) => {
    const assessmentSelectorORM = ormCreateSelector(orm.Assessment);
    const assessment = assessmentSelectorORM(redux, assessmentUuid) || {};
    return assessment;
  }
);

export const assessmentsSelector = createSelector(
  state => state,
  (_, partUuid) => partUuid,
  (redux, partUuid) => {
    const assessmentSelectorORM = ormCreateSelector(orm.Assessment);
    const assessments = assessmentSelectorORM(redux).filter(
      assessment => assessment.partUuid === partUuid
    );
    return assessments;
  }
);

export const assessmentsByTypesSelector = createSelector(
  state => state,
  (_, partUuid, assessmentTypes) => ({
    partUuid,
    assessmentTypes
  }),
  (redux, data) => {
    const { partUuid, assessmentTypes } = data;
    const assessmentSelectorORM = ormCreateSelector(orm.Assessment);
    const assessments = assessmentSelectorORM(redux).filter(
      assessment =>
        assessment.partUuid === partUuid &&
        assessmentTypes.includes(assessment.assessmentTypeUuid)
    );
    return assessments;
  }
);

export const assessmentsByTypesAndTechniquesSelector = createSelector(
  state => state,
  (_, partUuid, assessmentTechniques, assessmentTypes) => ({
    partUuid,
    assessmentTechniques,
    assessmentTypes
  }),
  (redux, data) => {
    const { partUuid, assessmentTypes, assessmentTechniques } = data;
    const assessmentSelectorORM = ormCreateSelector(orm.Assessment);
    const assessments = assessmentSelectorORM(redux).filter(
      assessment =>
        assessment.partUuid === partUuid &&
        assessmentTechniques.includes(assessment.assessmentTechniqueUuid) &&
        assessmentTypes.includes(assessment.assessmentTypeUuid)
    );
    return assessments;
  }
);

export const assessmentTypeSelector = createSelector(
  state => state,
  (_, assessmentTypeUuid) => assessmentTypeUuid,
  (redux, assessmentTypeUuid) => {
    const assessmentTypesSelectorORM = ormCreateSelector(orm.AssessmentType);
    const assessmentType = assessmentTypesSelectorORM(
      redux,
      assessmentTypeUuid
    );

    const name = _.get(assessmentType, 'name', '');

    return name;
  }
);

export const selfAssessmentAssessmentTypesSelector = ormCreateSelector(
  orm,
  session => {
    const opportunityAssessmentTypeNames = new Set([
      assessmentTypesMap.documentProject,
      assessmentTypesMap.qualitativeSkills,
      assessmentTypesMap.quantitativeSkills
    ]);
    const allAssessmentTypes = session.AssessmentType.all().toRefArray();
    const opportunityAssessmentTypes = _.filter(
      allAssessmentTypes,
      assessmentType => opportunityAssessmentTypeNames.has(assessmentType.name)
    );
    return _.sortBy(opportunityAssessmentTypes, 'order');
  }
);

export const opportunityAssessmentTypesSelector = ormCreateSelector(
  orm,
  session => {
    const opportunityAssessmentTypeNames = new Set([
      'Discussion',
      'Document/Project',
      'Qualitative Skills',
      'Quantitative Skills'
    ]);
    const allAssessmentTypes = session.AssessmentType.all().toRefArray();
    const opportunityAssessmentTypes = _.filter(
      allAssessmentTypes,
      assessmentType => opportunityAssessmentTypeNames.has(assessmentType.name)
    );
    return _.sortBy(opportunityAssessmentTypes, 'order');
  }
);

export const assessmentAssociationsSelector = ormCreateSelector(
  orm,
  session => {
    return session.AssessmentAssociation.all().toRefArray();
  }
);

export const assessmentTechniquesSelector = ormCreateSelector(orm, session => {
  return session.AssessmentTechnique.all().toRefArray();
});

export const assessmentTypesSelector = ormCreateSelector(orm, session => {
  return _.sortBy(session.AssessmentType.all().toRefArray(), 'order');
});

export const linkedAssessmentsFormSelector = createSelector(
  state => state,
  (_, assessmentType, partUuid) => ({
    assessmentType,
    partUuid
  }),
  (redux, data) => {
    const { assessmentType, partUuid } = data;

    const assessmentTypesSelectorORM = ormCreateSelector(orm.AssessmentType);
    const thisAssessmentType = assessmentTypesSelectorORM(redux).find(
      type => type.name === assessmentType
    );

    const assessmentsSelectorORM = ormCreateSelector(orm.Assessment);

    const assessments = assessmentsSelectorORM(redux).filter(
      assessment =>
        assessment.partUuid === partUuid &&
        assessment.assessmentTypeUuid === thisAssessmentType.uuid
    );

    return assessments;
  }
);

export const assessmentsByPartSelector = createSelector(
  state => state,
  (_, partUuid) => partUuid,
  (redux, partUuid) => {
    const assessmentsSelectorORM = ormCreateSelector(orm.Assessment);
    const assessments = assessmentsSelectorORM(redux).filter(
      assessment => assessment.partUuid === partUuid
    );
    return assessments;
  }
);

export const assessmentDetailsSelector = createSelector(
  state => state,
  (_, assessmentUuid, sectionUuid, partUuid) => ({
    assessmentUuid,
    sectionUuid,
    partUuid
  }),
  (redux, data) => {
    const { assessmentUuid, sectionUuid, partUuid } = data;

    const assessmentsSelectorORM = ormCreateSelector(orm.Assessment);
    const assessment = assessmentsSelectorORM(redux, assessmentUuid) || {};

    const assessmentTypesSelectorORM = ormCreateSelector(orm.AssessmentType);
    const assessmentType = assessmentTypesSelectorORM(
      redux,
      assessment.assessmentTypeUuid
    );

    const sectionsSelectorORM = ormCreateSelector(orm.Section);
    const section = sectionsSelectorORM(redux, sectionUuid);

    const partsSelectorORM = ormCreateSelector(orm.Part);
    const part = partsSelectorORM(redux, partUuid);

    const payload = {
      title: _.get(assessment, 'title', ''),
      sectionNumber: _.get(section, 'sectionNumber', 0),
      partNumber: _.get(part, 'partNumber', 0),
      scoreType: _.get(assessment, 'scoreType', ''),
      assessmentType: _.get(assessmentType, 'name', ''),
      legacyScores: _.get(assessment, 'legacyScores', '')
    };

    return payload;
  }
);

export const selectAssessment = ormCreateSelector(orm.Assessment);

export const selectAssessmentTechnique = ormCreateSelector(
  orm.AssessmentTechnique
);

export const selectAssessmentAssociation = ormCreateSelector(
  orm.AssessmentAssociation
);

export const selectAssessmentByCollectionComplete = createSelector(
  state => state,
  (_, cohortAssessmentCollectionUuid, userUuid) => ({
    cohortAssessmentCollectionUuid,
    userUuid
  }),
  (redux, parameters) => {
    const { cohortAssessmentCollectionUuid, userUuid } = parameters;

    // const getScores = ormCreateSelector(orm.ResultCard.scores);
    const getScoresByBlock = ormCreateSelector(orm.AssessmentBlock.scores);
    const getAssessments = ormCreateSelector(
      orm.CohortAssessmentCollection.cohort_assessments
    );
    const getResultCard = ormCreateSelector(orm.ResultCard);
    const getAssessmentBlock = ormCreateSelector(orm.AssessmentBlock);
    const getAssesmentBlockResult = ormCreateSelector(
      orm.AssessmentBlockResult
    );
    const getLetterGrades = ormCreateSelector(orm.LetterGrade);

    const letterGrades = getLetterGrades(redux).filter(
      letterGrade =>
        letterGrade.schoolUuid === redux.userState.selectedSchoolUuid
    );

    if (cohortAssessmentCollectionUuid && userUuid) {
      const assessments = getAssessments(redux, cohortAssessmentCollectionUuid);
      const resultCards = getResultCard(redux).filter(
        result => result.studentUuid === userUuid
      );

      const result = _(assessments)
        .filter(assessment => {
          /* needs to filter latest attempt */

          const blocks = getAssessmentBlock(redux).filter(
            block => block.assessmentUuid === assessment?.uuid
          );

          /* needs to include fail */

          const blockResults = blocks.filter(block => {
            const assessmentBlockResult = getAssesmentBlockResult(redux)
              .filter(
                br =>
                  br?.studentUuid === userUuid &&
                  br?.assessmentBlockUuid === block?.uuid &&
                  (br.status === 'pass' || br.status === 'critical')
              )
              .filter(br => br.status !== 'fail');

            return assessmentBlockResult?.length > 0;
          });

          return blockResults?.length > 0;
        })
        .map(assessment => {
          const assessmentResultCard = _.find(resultCards, {
            assessmentUuid: assessment?.uuid
          });

          const blocks = getAssessmentBlock(redux).filter(
            block => block.assessmentUuid === assessment?.uuid
          );

          // const scores = getScores(redux, assessmentResultCard?.uuid);

          const assessmentBlocks = _(blocks)
            .filter(block => {
              const assessmentBlockResult = getAssesmentBlockResult(
                redux
              ).filter(
                br =>
                  br?.studentUuid === userUuid &&
                  br?.assessmentBlockUuid === block?.uuid
              );

              const assessmentPassed = assessmentBlockResult
                .filter(br => br.status !== 'fail')
                .sort((a, b) => b.blockAttempt - a.blockAttempt)
                .at(0);

              return ['pass', 'critical', 'disqualified'].includes(
                assessmentPassed?.status
              );
            })
            .map(block => {
              const assessmentBlockResults = getAssesmentBlockResult(redux);

              const getBlockResult = _.filter(assessmentBlockResults, {
                assessmentBlockUuid: block?.uuid,
                studentUuid: userUuid
              });

              const blockResult = _.maxBy(getBlockResult, 'blockAttempt');

              const blockScores = getScoresByBlock(redux, block?.uuid).filter(
                score => score.studentUuid === userUuid
              );

              const attemptScores = _.groupBy(blockScores, 'attempt');

              const blockScoreRel = _.reduce(
                attemptScores[blockResult.blockAttempt],
                (total, score) => {
                  const { relValue } = score;
                  const relValueDecimal = new Decimal(relValue);

                  return total.add(relValueDecimal);
                },

                new Decimal(0)
              );

              const blockScorePot = _.reduce(
                attemptScores[blockResult.blockAttempt],
                (total, score) => {
                  const { potValue } = score;
                  const potValueDecimal = new Decimal(potValue);

                  return total.add(potValueDecimal);
                },

                new Decimal(0)
              );

              const blockScoreDecimal = blockScoreRel
                .div(blockScorePot)
                .times(100);

              const blockScore = blockScoreDecimal.toFixed(2).toString();

              const findGrade = _.find(letterGrades, schoolGrade => {
                const low = new Decimal(schoolGrade.rangeLow);
                const high = new Decimal(schoolGrade.rangeHigh);

                const conditionOne =
                  blockScoreDecimal.greaterThanOrEqualTo(low);
                const conditionTwo = blockScoreDecimal.lessThanOrEqualTo(high);

                return conditionOne && conditionTwo;
              });

              const blockGrade = findGrade?.grade;

              return {
                block,
                blockResult,
                blockScore,
                blockScores,
                blockGrade
              };
            })
            .orderBy(['block.blockNumber'])
            .value();

          return {
            assessment,
            assessmentBlocks,
            assessmentResultCard
          };
        })
        .value();

      return result;
    } else {
      return undefined;
    }
  }
);

export const selectAssessmentByCollectionPending = createSelector(
  state => state,
  (_, cohortAssessmentCollectionUuid, userUuid) => ({
    cohortAssessmentCollectionUuid,
    userUuid
  }),
  (redux, parameters) => {
    const { cohortAssessmentCollectionUuid, userUuid } = parameters;

    const getAssessments = ormCreateSelector(
      orm.CohortAssessmentCollection.cohort_assessments
    );
    const getResultCard = ormCreateSelector(orm.ResultCard);
    const getAssessmentBlock = ormCreateSelector(orm.AssessmentBlock);
    const getAssesmentBlockResult = ormCreateSelector(
      orm.AssessmentBlockResult
    );
    const getAssessmentRubrics = ormCreateSelector(orm.AssessmentRubric);

    if (cohortAssessmentCollectionUuid && userUuid) {
      const assessments = getAssessments(redux, cohortAssessmentCollectionUuid);
      const resultCards = getResultCard(redux).filter(
        result => result.studentUuid === userUuid
      );

      const result = _(assessments)
        .filter(assessment => {
          const blocks = getAssessmentBlock(redux).filter(
            block => block.assessmentUuid === assessment?.uuid
          );

          const blockResults = blocks.filter(block => {
            const assessmentBlockResult = getAssesmentBlockResult(redux).filter(
              br =>
                br?.studentUuid === userUuid &&
                br?.assessmentBlockUuid === block?.uuid
            );

            const assessmentPassed = assessmentBlockResult
              .sort((a, b) => b.blockAttempt - a.blockAttempt)
              .at(0);

            return ['pending', 'fail'].includes(assessmentPassed?.status);
          });

          return blockResults?.length > 0;
        })
        .map(assessment => {
          const assessmentResultCard = _.find(resultCards, {
            assessmentUuid: assessment?.uuid
          });

          const blocks = getAssessmentBlock(redux).filter(
            block => block.assessmentUuid === assessment?.uuid
          );

          const assessmentBlocks = _(blocks)
            .filter(block => {
              const assessmentBlockResult = getAssesmentBlockResult(
                redux
              ).filter(
                br =>
                  br?.studentUuid === userUuid &&
                  br?.assessmentBlockUuid === block?.uuid
              );

              const assessmentPassed = assessmentBlockResult
                .sort((a, b) => b.blockAttempt - a.blockAttempt)
                .at(0);

              return ['pending', 'fail'].includes(assessmentPassed?.status);
            })
            .map(block => {
              const assessmentBlockResults = getAssesmentBlockResult(
                redux
              ).filter(
                br =>
                  br?.studentUuid === userUuid &&
                  br?.assessmentBlockUuid === block?.uuid
              );
              const blockResult = _.find(
                assessmentBlockResults,
                blockResult =>
                  blockResult?.assessmentBlockUuid === block?.uuid &&
                  ['pending', 'fail'].includes(blockResult?.status)
              );

              const assessmentRubric = getAssessmentRubrics(redux);

              const { max_attempts_per_block, max_remediations_per_block } =
                assessmentRubric.find(
                  as =>
                    as.syllabus_assessment_uuid ===
                    assessment.programAssessmentMasterUuid
                ) ?? {};

              const blockResultAttempts =
                max_attempts_per_block && max_remediations_per_block
                  ? +max_attempts_per_block + +max_remediations_per_block
                  : undefined;

              return {
                block,
                blockResult,
                blockResultAttempts
              };
            })
            .value();

          return {
            assessment,
            assessmentBlocks,
            assessmentResultCard
          };
        })
        .value();

      return result;
    } else {
      return undefined;
    }
  }
);

export const selectAssessmentByCollectionRemaining = createSelector(
  state => state,
  (_, cohortAssessmentCollectionUuid, userUuid, completed, pending) => ({
    cohortAssessmentCollectionUuid,
    userUuid,
    completed,
    pending
  }),
  (redux, parameters) => {
    const { cohortAssessmentCollectionUuid, userUuid, completed, pending } =
      parameters;

    const getAssessments = ormCreateSelector(
      orm.CohortAssessmentCollection.cohort_assessments
    );

    const getAssessmentBlock = ormCreateSelector(orm.AssessmentBlock);

    if (userUuid && cohortAssessmentCollectionUuid) {
      const assessments = getAssessments(redux, cohortAssessmentCollectionUuid);

      const result = _(assessments)
        .map(assessment => {
          const blocks = getAssessmentBlock(redux).filter(
            block =>
              (block.assessmentUuid === assessment?.uuid ||
                block.assessmentUuid ===
                  assessment?.programAssessmentMasterUuid) &&
              !_.includes(completed, block?.uuid) &&
              !_.includes(pending, block?.uuid)
          );

          return { assessment, blocks };
        })
        .value();

      return result;
    } else {
      return undefined;
    }
  }
);

export const selectAssesmentAssociation = ormCreateSelector(
  orm.AssessmentAssociation
);

export const selectAssesmentTechnique = ormCreateSelector(
  orm.AssessmentTechnique
);

export const selectAssesmentType = ormCreateSelector(orm.AssessmentType);

export const selectAssesmentBlocksCount = createSelector(
  state => state,
  (_, assessmentUuid) => ({
    assessmentUuid
  }),
  (redux, parameters) => {
    const { assessmentUuid } = parameters;

    const getAssessmentsBlocks = ormCreateSelector(orm.AssessmentBlock);
    if (assessmentUuid) {
      const blocks = getAssessmentsBlocks(redux).filter(
        block => block.assessmentUuid === assessmentUuid
      );

      return blocks?.length;
    } else {
      return undefined;
    }
  }
);

export const selectGradeSummary = createSelector(
  state => state,
  (_, assessmentUuid) => assessmentUuid,
  (redux, assessmentUuid) => {
    const gradeSummarySelectorORM = ormCreateSelector(orm.GradeSummary);
    const gradeSummary = gradeSummarySelectorORM(redux, assessmentUuid);
    return gradeSummary;
  }
);
