import fetch from '../helpers/fetch';

const getAssessmentGrades = (assessmentUuid, studentUuid) =>
  fetch(`/api/assessment-grades/${assessmentUuid}/student/${studentUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getAssessmentGrades };
