import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import CoursesByTermTable from './CoursesByTermTable';
import TermCredits from '../Calculations/TermCredits';
import TermGPA from '../Calculations/TermGPA';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .accordion_row_label {
    width: 64.5%;
  }

  .column_one {
    width: 35.5%;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }

  .column_one > span {
    width: 100%;
  }
`;

const Label = styled.div`
  font-size: 16px;
  color: ${props => props.theme.fontColors.darker};
`;

const Calculations = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupLabel = styled.div`
  padding: 10px;
`;

const GroupInput = styled.div`
  padding: 10px;
`;

const Record = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width};
`;

// const Temp = styled.div`
//   border: 1px dotted red;
// `;

export default function CoursesByTerm({ userUuid, terms }) {
  return (
    <>
      {terms?.map((term, index) => (
        <Accordion
          key={term.uuid}
          expandWidth="66px"
          expandcontent="66px"
          height="80px"
          label={<Label>{term?.period}</Label>}
          columnOne={
            <Calculations>
              <Record width="50%">
                <Group>
                  <GroupLabel>Credits</GroupLabel>
                  <GroupInput>
                    <TermCredits
                      userUuid={userUuid}
                      termUuid={term?.uuid}
                      terms={terms}
                      termIndex={index}
                    />
                  </GroupInput>
                </Group>
              </Record>
              <Record width="50%">
                <Group>
                  <GroupLabel>GPA</GroupLabel>
                  <GroupInput>
                    <TermGPA
                      userUuid={userUuid}
                      termUuid={term?.uuid}
                      terms={terms}
                      termIndex={index}
                    />
                  </GroupInput>
                </Group>
              </Record>
            </Calculations>
          }
          columnOneClassName="column_one"
        >
          <CoursesByTermTable termUuid={term.uuid} userUuid={userUuid} />
        </Accordion>
      ))}
    </>
  );
}

CoursesByTerm.propTypes = {
  userUuid: PropTypes.string,
  terms: PropTypes.array
};

CoursesByTerm.defaultProps = {
  userUuid: undefined,
  terms: undefined
};
