import {
  CLEAR_PART_FACULTY_REPORT,
  CLEAR_PART_TOPIC_REPORT,
  CLEAR_PART_OPPORTUNITY_REPORT,
  GET_PART_FACULTY_ASSESSMENT_SUMMARY_ERROR,
  GET_PART_FACULTY_ASSESSMENT_SUMMARY_SUCCESS,
  GET_PART_FACULTY_ASSESSMENT_SUMMARY,
  GET_PART_FACULTY_REPORT_ERROR,
  GET_PART_FACULTY_REPORT_SUCCESS,
  GET_PART_FACULTY_REPORT,
  GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL_ERROR,
  GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL_SUCCESS,
  GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL,
  GET_PART_OPPORTUNITY_REPORT_ERROR,
  GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL_ERROR,
  GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL_SUCCESS,
  GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL,
  GET_PART_OPPORTUNITY_REPORT_SUCCESS,
  GET_PART_OPPORTUNITY_REPORT,
  GET_PART_TOPIC_ASSESSMENT_SUMMARY_ERROR,
  GET_PART_TOPIC_ASSESSMENT_SUMMARY_SUCCESS,
  GET_PART_TOPIC_ASSESSMENT_SUMMARY,
  GET_PART_TOPIC_REPORT_ERROR,
  GET_PART_TOPIC_REPORT_SUCCESS,
  GET_PART_TOPIC_REPORT
} from '../actions-type';

const doClearPartFacultyAssessmentSummary = partUuid => ({
  payload: {
    partUuid
  },
  type: CLEAR_PART_FACULTY_REPORT
});

const doClearPartFacultyReport = () => ({
  type: CLEAR_PART_FACULTY_REPORT
});

const doClearPartOpportunityReport = () => ({
  type: CLEAR_PART_OPPORTUNITY_REPORT
});

const doGetPartFacultyAssessmentSummary = (
  partUuid,
  facultyUuid,
  assessmentTechniques,
  assessmentTypes
) => ({
  type: GET_PART_FACULTY_ASSESSMENT_SUMMARY,
  payload: {
    partUuid,
    facultyUuid,
    assessmentTechniques,
    assessmentTypes
  }
});

const doGetPartFacultyAssessmentSummarySuccess = result => ({
  type: GET_PART_FACULTY_ASSESSMENT_SUMMARY_SUCCESS,
  payload: result
});

const doGetPartFacultyAssessmentSummaryError = error => ({
  type: GET_PART_FACULTY_ASSESSMENT_SUMMARY_ERROR,
  payload: error
});

const doGetPartFacultyReport = (
  partUuid,
  reportType,
  filters,
) => ({
  type: GET_PART_FACULTY_REPORT,
  payload: {
    partUuid,
    reportType,
    filters,
  }
});

const doGetPartFacultyReportSuccess = result => ({
  type: GET_PART_FACULTY_REPORT_SUCCESS,
  payload: result
});

const doGetPartFacultyReportError = error => ({
  type: GET_PART_FACULTY_REPORT_ERROR,
  payload: error
});

const doClearPartTopicAssessmentSummary = partUuid => ({
  payload: {
    partUuid
  },
  type: CLEAR_PART_TOPIC_REPORT
});

const doClearPartTopicReport = () => ({
  type: CLEAR_PART_TOPIC_REPORT
});

const doGetPartTopicAssessmentSummary = (partUuid, topicUuid) => ({
  type: GET_PART_TOPIC_ASSESSMENT_SUMMARY,
  payload: {
    partUuid,
    topicUuid
  }
});

const doGetPartTopicAssessmentSummarySuccess = result => ({
  type: GET_PART_TOPIC_ASSESSMENT_SUMMARY_SUCCESS,
  payload: result
});

const doGetPartTopicAssessmentSummaryError = error => ({
  type: GET_PART_TOPIC_ASSESSMENT_SUMMARY_ERROR,
  payload: error
});

const doGetPartTopicReport = (partUuid, topics) => ({
  type: GET_PART_TOPIC_REPORT,
  payload: {
    partUuid,
    topics
  }
});

const doGetPartTopicReportSuccess = result => ({
  type: GET_PART_TOPIC_REPORT_SUCCESS,
  payload: result
});

const doGetPartTopicReportError = error => ({
  type: GET_PART_TOPIC_REPORT_ERROR,
  payload: error
});

const doGetPartOpportunityReport = (partUuid, assessmentTypes) => ({
  type: GET_PART_OPPORTUNITY_REPORT,
  payload: {
    partUuid,
    assessmentTypes
  }
});

const doGetPartOpportunityReportSuccess = result => ({
  type: GET_PART_OPPORTUNITY_REPORT_SUCCESS,
  payload: result
});

const doGetPartOpportunityReportError = error => ({
  type: GET_PART_OPPORTUNITY_REPORT_ERROR,
  payload: error
});

const doGetPartOpportunityReportBlockDetail = (
  assessmentUuid,
  studentUuid
) => ({
  type: GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL,
  payload: {
    assessmentUuid,
    studentUuid
  }
});

const doGetPartOpportunityReportBlockDetailSuccess = result => ({
  type: GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL_SUCCESS,
  payload: result
});

const doGetPartOpportunityReportBlockDetailError = error => ({
  type: GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL_ERROR,
  payload: error
});

const doGetPartOpportunityReportStatisticBlockDetail = (
  assessmentUuid,
  statisticType
) => ({
  type: GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL,
  payload: {
    assessmentUuid,
    statisticType
  }
});

const doGetPartOpportunityReportStatisticBlockDetailSuccess = result => ({
  type: GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL_SUCCESS,
  payload: result
});

const doGetPartOpportunityReportStatisticBlockDetailError = error => ({
  type: GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL_ERROR,
  payload: error
});

export {
  doClearPartFacultyAssessmentSummary,
  doClearPartFacultyReport,
  doClearPartOpportunityReport,
  doClearPartTopicAssessmentSummary,
  doClearPartTopicReport,
  doGetPartFacultyAssessmentSummary,
  doGetPartFacultyAssessmentSummaryError,
  doGetPartFacultyAssessmentSummarySuccess,
  doGetPartFacultyReport,
  doGetPartFacultyReportError,
  doGetPartFacultyReportSuccess,
  doGetPartOpportunityReport,
  doGetPartOpportunityReportBlockDetail,
  doGetPartOpportunityReportBlockDetailError,
  doGetPartOpportunityReportBlockDetailSuccess,
  doGetPartOpportunityReportError,
  doGetPartOpportunityReportStatisticBlockDetail,
  doGetPartOpportunityReportStatisticBlockDetailError,
  doGetPartOpportunityReportStatisticBlockDetailSuccess,
  doGetPartOpportunityReportSuccess,
  doGetPartTopicAssessmentSummary,
  doGetPartTopicAssessmentSummaryError,
  doGetPartTopicAssessmentSummarySuccess,
  doGetPartTopicReport,
  doGetPartTopicReportError,
  doGetPartTopicReportSuccess
};
