import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import { sortByDecimalPropertyAndFullName } from '../../../../../helpers/utilities';
import FacultyDefaultScoreViewList from './FacultyDefaultScoreViewList';

const FacultyDefaultScoreViewListContainer = ({
  assessmentScores,
  faculty,
  scoreType,
  students
}) => {
  const facultyUuids = useMemo(
    () => faculty.map(facultyUser => facultyUser.userUuid),
    [faculty]
  );

  const defaultFacultyScoreDataMap = useMemo(
    () =>
      _.reduce(
        facultyUuids,
        (acc, facultyUuid) => {
          return {
            ...acc,
            [facultyUuid]: {
              facultyTotalPotentialScore: '0.00',
              facultyTotalRelativeScore: '0.00',
              facultyScores: []
            }
          };
        },
        {}
      ),
    [facultyUuids]
  );

  const { facultyScoreDataMap, cumulativeTotalPotential } = useMemo(
    () =>
      _.reduce(
        assessmentScores,
        (acc, score) => {
          const currentPotentialValue = new Decimal(score.potValue);
          const currentRelativeValue = new Decimal(score.relValue);
          acc.cumulativeTotalPotential = currentPotentialValue
            .plus(acc.cumulativeTotalPotential)
            .toFixed(2);
          if (acc.facultyScoreDataMap[score.grader1Uuid] !== undefined) {
            acc.facultyScoreDataMap[score.grader1Uuid] = {
              facultyTotalPotentialScore: currentPotentialValue
                .plus(
                  acc.facultyScoreDataMap[score.grader1Uuid]
                    .facultyTotalPotentialScore
                )
                .toFixed(2),
              facultyTotalRelativeScore: currentRelativeValue
                .plus(
                  acc.facultyScoreDataMap[score.grader1Uuid]
                    .facultyTotalRelativeScore
                )
                .toFixed(2),
              facultyScores: [
                ...acc.facultyScoreDataMap[score.grader1Uuid].facultyScores,
                score
              ]
            };
          }
          if (acc.facultyScoreDataMap[score.grader2Uuid] !== undefined) {
            acc.facultyScoreDataMap[score.grader2Uuid] = {
              facultyTotalPotentialScore: currentPotentialValue
                .plus(
                  acc.facultyScoreDataMap[score.grader2Uuid]
                    .facultyTotalPotentialScore
                )
                .toFixed(2),
              facultyTotalRelativeScore: currentRelativeValue
                .plus(
                  acc.facultyScoreDataMap[score.grader2Uuid]
                    .facultyTotalRelativeScore
                )
                .toFixed(2),
              facultyScores: [
                ...acc.facultyScoreDataMap[score.grader2Uuid].facultyScores,
                score
              ]
            };
          }
          if (acc.facultyScoreDataMap[score.grader3Uuid] !== undefined) {
            acc.facultyScoreDataMap[score.grader3Uuid] = {
              facultyTotalPotentialScore: currentPotentialValue
                .plus(
                  acc.facultyScoreDataMap[score.grader3Uuid]
                    .facultyTotalPotentialScore
                )
                .toFixed(2),
              facultyTotalRelativeScore: currentRelativeValue
                .plus(
                  acc.facultyScoreDataMap[score.grader3Uuid]
                    .facultyTotalRelativeScore
                )
                .toFixed(2),
              facultyScores: [
                ...acc.facultyScoreDataMap[score.grader3Uuid].facultyScores,
                score
              ]
            };
          }
          return acc;
        },
        {
          facultyScoreDataMap: defaultFacultyScoreDataMap,
          cumulativeTotalPotential: '0.0'
        }
      ),
    [assessmentScores, defaultFacultyScoreDataMap]
  );

  const sortedPartFacultyUsers = useMemo(
    () =>
      [...faculty].sort((a, b) =>
        sortByDecimalPropertyAndFullName(
          {
            firstName: b.firstName,
            lastName: b.lastName,
            totalPotential:
              facultyScoreDataMap[b.userUuid]?.facultyTotalPotentialScore
          },
          {
            firstName: a.firstName,
            lastName: a.lastName,
            totalPotential:
              facultyScoreDataMap[a.userUuid]?.facultyTotalPotentialScore
          },
          'totalPotential',
          'firstName',
          'lastName',
          1
        )
      ),
    [faculty, facultyScoreDataMap]
  );

  return (
    <FacultyDefaultScoreViewList
      cumulativeTotalPotential={cumulativeTotalPotential}
      facultyScoreDataMap={facultyScoreDataMap}
      faculty={sortedPartFacultyUsers}
      scoreType={scoreType}
      students={students}
    />
  );
};

FacultyDefaultScoreViewListContainer.propTypes = {
  assessmentScores: PropTypes.array,
  faculty: PropTypes.array,
  scoreType: PropTypes.string,
  students: PropTypes.array
};

FacultyDefaultScoreViewListContainer.defaultProps = {
  assessmentScores: [],
  faculty: [],
  scoreType: '',
  students: []
};

export default FacultyDefaultScoreViewListContainer;
