import {useMemo} from 'react';
import {groupBy, isEqual, keyBy} from 'lodash';
import Decimal from 'decimal.js-light';
import {useSelector} from 'react-redux';

import {formatFullName} from '../format/user.format';
import {usersSelector} from '../../redux/selectors/usersSelectors';
import {scoresByAssessment} from '../../redux/selectors/scoreSelectors';
import {assessmentSelector} from '../../redux/selectors/assessmentSelectors';

const calculateStudentScore = ({scores, rubricItem, stepSubItem, stepSubItemDescription}) => {
  const potValue = Object.keys(scores[rubricItem][stepSubItem][stepSubItemDescription].students).
    reduce(
      (result, score) => result.plus(
        scores[rubricItem][stepSubItem][stepSubItemDescription].students[score].potValue,
      ),
      new Decimal(0),
    ).toFixed(2);
  const relValue = Object.keys(scores[rubricItem][stepSubItem][stepSubItemDescription].students).
    reduce(
      (result, score) => result.plus(
        scores[rubricItem][stepSubItem][stepSubItemDescription].students[score].relValue,
      ),
      new Decimal(0),
    ).toFixed(2);
  const score = new Decimal(relValue).dividedBy(potValue).times(100).toFixed(2);

  return {
    potValue,
    relValue,
    score,
  };

};

const mapScoreValues = (scores) => {
  const potValue = scores.reduce(
    (result, score) => result.plus(score.potValue),
    new Decimal(0),
  ).toFixed(2);
  const relValue = scores.reduce(
    (result, score) => result.plus(score.relValue),
    new Decimal(0),
  ).toFixed(2);
  const score = new Decimal(relValue).dividedBy(potValue).times(100).toFixed(2);

  return {
    potValue,
    relValue,
    score,
  };
};

const getColor = (name) => {
  const itemName = name?.split(' - ')[1];
  switch (itemName) {
    case 'Significant Overgrade':
    case 'Significant Undergrade':
      return 'dark-red';
    case 'Equivocal':
      return 'light-red';
    case 'Perfect Agreement':
      return 'dark-green';
    case 'Agreement':
      return 'light-green';
    default:
      return '';
  }

};

const getOrder = (name) => {
  const itemName = name?.split(' - ')[1];
  switch (itemName) {
    case 'Significant Overgrade':
      return 1;
    case 'Overgrade':
      return 2;
    case 'Slight Overgrade':
      return 3;
    case 'Equivocal':
      return 4;
    case 'Perfect Agreement':
      return 5;
    case 'Agreement':
      return 6;
    case 'Slight Undergrade':
      return 7;
    case 'Undergrade':
      return 8;
    case 'Significant Undergrade':
      return 9;
    default:
      return 0;
  }

};

export const useScoresForItemAnalysis = ({assessmentUuid}) => {
  const users = useSelector(usersSelector);

  const assessmentScores = useSelector(
    state => scoresByAssessment(state, assessmentUuid),
    isEqual,
  );

  const usersByUuid = useMemo(
    () => keyBy(users, 'uuid'),
    [users],
  );

  const assessment = useSelector(
    state => assessmentSelector(state, assessmentUuid),
    isEqual,
  );

  // if there is at least one score that do not have sequenceOrder or rubricOrder, then we cannot group them using these values
  // and we fall back to the old implementation, where we group them by rubricItem
  let incompleteScoreData = false;

  const scores = assessmentScores.map(
    score => {
      if (!incompleteScoreData && (score.sequenceOrder == null || score.rubricOrder == null )) {
        incompleteScoreData = true;
      }
      if (score.stepSubItem.includes('Step')) {
        return score;
      }
      return {
        ...score,
        stepSubItem: `Step ${score.stepSubItem}`,
      };
    },
  );

  const scoresByRubricItem = incompleteScoreData ? groupBy(scores, 'rubricItem') : groupBy(scores, (s) => `${s.rubricItem}/${s.sequenceOrder}/${s.rubricOrder}`);
  const scoresByStepSubItem = groupBy(scores, s => `${s.rubricItem}${incompleteScoreData ? '' : `/${s.sequenceOrder}/${s.rubricOrder}`}/${s.stepSubItem}${s.stepSubItemDescription ? `/${s.stepSubItemDescription}` : ''}`);

  // (1) Rubric,
  // (2) Step Number WITH Step Description,
  // (3) Passed (count of students who passed) / Failed (count of students who failed)
  // (4) Student Passed under Passed / Student Failed under Failed
  return useMemo(
    () => {
      const groupedScores = scores?.
        reduce(
          (result, score) => {
            const {rubricItem, stepSubItem, stepSubItemDescription, sequenceOrder, rubricOrder} = score;
            const rubricKey = `${rubricItem}${incompleteScoreData ? '' : `/${sequenceOrder}/${rubricOrder}`}`;
            const hasPassed = new Decimal(score.relValue).gt(0);
            const stepKey = `${rubricKey}/${stepSubItem}${stepSubItemDescription ? `/${stepSubItemDescription}` : ''}`

            // eslint-disable-next-line
            const rubricName = incompleteScoreData ? rubricItem : `Sequence ${sequenceOrder || 'N/A'} > Rubric ${rubricOrder || 'N/A'} > ${rubricItem}`;
            // eslint-disable-next-line
            const stepName = stepSubItemDescription
              ? `${stepSubItem} - ${stepSubItemDescription}`
              : `${stepSubItem}`;

            return {
              ...result,
              [rubricKey]: {
                rubricName,
                ...result[rubricKey],
                [stepKey]: {
                  stepName,
                  'Passed': {
                    total: result[rubricKey]?.[stepKey]?.Passed?.total +
                      (hasPassed ? 1 : 0) || (hasPassed ? 1 : 0),
                    students: {
                      ...result[rubricKey]?.[stepKey]?.Passed?.students,
                      ...(hasPassed ? {[score.studentUuid]: score} : {}),
                    },
                  },
                  'Failed': {
                    total: result[rubricKey]?.[stepKey]?.Failed?.total +
                      (!hasPassed ? 1 : 0) || (!hasPassed ? 1 : 0),
                    students: {
                      ...result[rubricKey]?.[stepKey]?.Failed?.students,
                      ...(!hasPassed ? {[score.studentUuid]: score} : {}),
                    },
                  },
                },
              },
            };
          },
          {},
        );

      const tableData = Object.keys(groupedScores).map(
        (rubricItem) => ({
          name: groupedScores[rubricItem].rubricName,
          ...mapScoreValues(scoresByRubricItem[rubricItem]),
          subRows: Object.keys(groupedScores[rubricItem]).filter(k => k !== 'rubricName').map(
            (stepSubItem) => {
              return {
                name: groupedScores[rubricItem][stepSubItem].stepName,
                ...mapScoreValues(scoresByStepSubItem[stepSubItem]),
                subRows: Object.keys(groupedScores[rubricItem][stepSubItem]).filter(k => k !== 'stepName').map(
                  (stepSubItemDescription) => {
                    return {
                      name: `${stepSubItemDescription} (${groupedScores[rubricItem][stepSubItem][stepSubItemDescription].total})`,
                      color: stepSubItemDescription.includes('Passed') ? 'green' : 'red',
                      ...calculateStudentScore(
                        {
                          scores: groupedScores,
                          rubricItem,
                          stepSubItem,
                          stepSubItemDescription,
                        },
                      ),
                      subRows: Object.keys(groupedScores[rubricItem][stepSubItem][stepSubItemDescription].students).
                        map(studentUuid => ({
                          name: formatFullName(usersByUuid[studentUuid]),
                          color: stepSubItemDescription.includes('Passed') ? 'green' : 'red',
                          ...groupedScores[rubricItem][stepSubItem][stepSubItemDescription].students[studentUuid],
                          score: new Decimal(
                            groupedScores[rubricItem][stepSubItem][stepSubItemDescription].students[studentUuid].relValue,
                          ).
                            dividedBy(
                              groupedScores[rubricItem][stepSubItem][stepSubItemDescription].students[studentUuid].potValue,
                            ).
                            times(100).
                            toFixed(2),
                        })).sort((a, b) => {
                          if (assessment.scoreType === 'Opportunity')
                            return a.name.localeCompare(b.name, undefined, {numeric: true});

                          const nameA = `${a.attempt} ${a.name}`;
                          const nameB = `${b.attempt} ${b.name}`;

                          return nameA.localeCompare(nameB, undefined, {numeric: true});
                        }),
                    };
                  },
                ),
              };
            },
          ).sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true})),
        }),
      ).sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {numeric: true});
      });

      const index = tableData.findIndex(a => a.name === 'Self-Assessment');
      if (index > 0) {
        const selfAssessmentRow = tableData.splice(index, 1)?.map(row => ({
          ...row,
          subRows: row.subRows.map(subRow => ({
            ...subRow,
            color: getColor(subRow.name),
            order: getOrder(subRow.name),
            name: `${subRow.name.split(' - ')[1]} (${subRow.subRows.map(s => s.subRows).flat().length})`,
            subRows: subRow.subRows.map(s => s.subRows).flat().map(stud => ({
              ...stud,
              color: getColor(subRow.name),
            })),
          })).sort((a, b) => a.order - b.order),
        }));

        if (selfAssessmentRow.length > 0) {
          return [...tableData, ...selfAssessmentRow];
        }
      }

      return tableData;
    },
    [scores, usersByUuid, scoresByRubricItem, scoresByStepSubItem, assessment.scoreType, incompleteScoreData],
  );

};