import {
  GET_LOVERBS,
  GET_LOVERBS_SUCCESS,
  GET_LOVERBS_ERROR,
  GET_LOVERBS_BY_EDUCATION_LEVELS,
  GET_LOVERBS_BY_EDUCATION_LEVELS_SUCCESS,
  GET_LOVERBS_BY_EDUCATION_LEVELS_ERROR
} from '../actions-type';

const doGetLoverbs = schoolUuid => ({
  type: GET_LOVERBS,
  payload: {
    schoolUuid
  }
});

const doGetLoverbsSuccess = result => ({
  type: GET_LOVERBS_SUCCESS,
  payload: result
});

const doGetLoverbsError = error => ({
  type: GET_LOVERBS_ERROR,
  payload: error
});

const doGetLoverbsByEducationLevels = educationLevels => ({
  type: GET_LOVERBS_BY_EDUCATION_LEVELS,
  payload: {
    educationLevels
  }
});

const doGetLoverbsByEducationLevelsSuccess = result => ({
  type: GET_LOVERBS_BY_EDUCATION_LEVELS_SUCCESS,
  payload: result
});

const doGetLoverbsByEducationLevelsError = error => ({
  type: GET_LOVERBS_BY_EDUCATION_LEVELS_ERROR,
  payload: error
});

export {
  doGetLoverbs,
  doGetLoverbsSuccess,
  doGetLoverbsError,
  doGetLoverbsByEducationLevels,
  doGetLoverbsByEducationLevelsSuccess,
  doGetLoverbsByEducationLevelsError
};
