import React from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js-light';
import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../../../helpers/format/user.format';
import FacultyAlternativeScoreViewStudentPanel from './FacultyDefaultScoreViewFacultyPanel';

const FacultyAlternativeScoreViewStudentPanelContainer = ({
  cumulativeTotalPotential,
  facultyScores,
  facultyTotalPotentialScore,
  facultyTotalRelativeScore,
  facultyUser,
  scoreType,
  students
}) => {
  const facultyParticipationPercentage = new Decimal(
    cumulativeTotalPotential
  ).isZero()
    ? new Decimal(cumulativeTotalPotential)
    : new Decimal(facultyTotalPotentialScore).dividedBy(
        cumulativeTotalPotential
      );
  const facultyRelativePercentage = new Decimal(
    facultyTotalPotentialScore
  ).isZero()
    ? new Decimal(facultyTotalPotentialScore)
    : new Decimal(facultyTotalRelativeScore).dividedBy(
        facultyTotalPotentialScore
      );
  const facultyFirstName = formatFirstNameMiddleName(
    facultyUser.firstName,
    facultyUser.middleName
  );
  const facultyLastName = formatLastName(
    facultyUser.lastName,
    facultyUser.marriedLastName,
    facultyUser.suffix
  );
  return new Decimal(facultyTotalPotentialScore).greaterThan(0) ? (
    <FacultyAlternativeScoreViewStudentPanel
      facultyFullName={`${facultyLastName}, ${facultyFirstName}`}
      facultyScores={facultyScores}
      facultyTotalPotential={facultyTotalPotentialScore}
      facultyTotalRelative={facultyTotalRelativeScore}
      facultyParticipationPercentage={facultyParticipationPercentage
        .times(100)
        .toFixed(2)}
      facultyRelativePercentage={facultyRelativePercentage
        .times(100)
        .toFixed(2)}
      scoreType={scoreType}
      students={students}
    />
  ) : null;
};

FacultyAlternativeScoreViewStudentPanelContainer.propTypes = {
  cumulativeTotalPotential: PropTypes.string,
  facultyScores: PropTypes.array,
  facultyTotalPotentialScore: PropTypes.string,
  facultyTotalRelativeScore: PropTypes.string,
  facultyUser: PropTypes.object,
  scoreType: PropTypes.string,
  students: PropTypes.array
};
FacultyAlternativeScoreViewStudentPanelContainer.defaultProps = {
  cumulativeTotalPotential: '0.0',
  facultyScores: [],
  facultyTotalPotentialScore: '0.0',
  facultyTotalRelativeScore: '0.0',
  facultyUser: {},
  scoreType: '',
  students: []
};

export default FacultyAlternativeScoreViewStudentPanelContainer;
