import {
  GET_PART_TOPICS,
  GET_PART_TOPICS_ERROR,
  GET_PART_TOPICS_SUCCESS
} from '../actions-type';

const doGetPartTopics = partUuid => ({
  type: GET_PART_TOPICS,
  payload: {
    partUuid
  }
});

const doGetPartTopicsSuccess = result => ({
  type: GET_PART_TOPICS_SUCCESS,
  payload: result
});

const doGetPartTopicsError = error => ({
  type: GET_PART_TOPICS_ERROR,
  payload: error
});

export { doGetPartTopics, doGetPartTopicsError, doGetPartTopicsSuccess };
