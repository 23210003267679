import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ProgramAssessmentCollectionForm from '../ProgramAssessmentCollectionForm';
import { RowSummary } from '../../../Library/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewProgramAssessmentCollection = ({
  title,
  showValidationErrors,
  handleTitleChange,
  onSubmit,
  clearNewForm
}) => (
  <Layout>
    <RowSummary>
      <ProgramAssessmentCollectionForm
        title={title}
        formSubtitle="New Collection"
        label="Collection Title"
        buttonText="Create"
        showValidationErrors={showValidationErrors}
        handleChange={handleTitleChange}
        onSubmit={onSubmit}
        closeForm={clearNewForm}
      />
    </RowSummary>
  </Layout>
);

NewProgramAssessmentCollection.propTypes = {
  title: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleTitleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  clearNewForm: PropTypes.func
};
NewProgramAssessmentCollection.defaultProps = {
  title: '',
  showValidationErrors: false,
  handleTitleChange: undefined,
  onSubmit: undefined,
  clearNewForm: undefined
};

export default NewProgramAssessmentCollection;
