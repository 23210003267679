import React from 'react';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  switchButton: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    width: '230px',
  }
}));

const SwitchButton = ({ label, handleClick, isChecked }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.switchButton}
      size="small"
      variant="outlined"
      onClick={handleClick}
    >
      <Switch color="primary" checked={isChecked} />
      {label}
    </Button>
  );
};

SwitchButton.defaultProps = {
  label: '',
  handleClick: () => {},
  isChecked: false
};

SwitchButton.propTypes = {
  label: PropTypes.string,
  handleClick: PropTypes.func,
  isChecked: PropTypes.bool
};

export default SwitchButton;
