import { takeEvery, call, put } from 'redux-saga/effects';
import {
  getPart,
  getPartFaculty,
  patchPartStatus,
  patchPartLocked,
  patchPartCalculateGrade,
  patchPartUndoGradeCalculation,
  putPartStatusCommit,
  putDelayedStudentPartGrade,
  putDelayedStudentPartCommitStatus,
  deleteDelayedStudentPartGrade,
  postCloneExistingPart
} from '../../api/partApi';

import {
  doGetPartError,
  doGetPartSuccess,
  doGetPartFacultyError,
  doGetPartFacultySuccess,
  doPatchPartLockSuccess,
  doPatchPartStatusError,
  doPatchPartStatusSuccess,
  doPatchPartLockError,
  doPatchPartCalculateGradeSuccess,
  doPatchPartCalculateGradeError,
  doPatchPartUndoCalculatedGradeSuccess,
  doPatchPartUndoCalculatedGradeError,
  doPutPartStatusCommitSuccess,
  doPutPartStatusCommitError,
  doPutPartDelayedStudentGradeSuccess,
  doPutPartDelayedStudentGradeError,
  doPutPartDelayedStudentStatusCommitSuccess,
  doPutPartDelayedStudentStatusCommitError,
  doDeletePartDelayedStudentGradeSuccess,
  doDeletePartDelayedStudentGradeError,
  doPostCloneExistingPartSuccess,
  doPostCloneExistingPartError
} from '../actions/partActions';

import {
  GET_PART,
  PATCH_PART_STATUS,
  PATCH_PART_LOCKED,
  GET_PART_FACULTY,
  PATCH_PART_CALCULATE_GRADE,
  PATCH_PART_UNDO_CALCULATED_GRADE,
  PUT_PART_STATUS_COMMIT,
  PUT_PART_GRADE_DELAYED_STUDENT,
  DELETE_PART_GRADE_DELAYED_STUDENT,
  PUT_PART_DELAYED_STATUS_COMMIT,
  POST_CLONE_EXISTING_PART
} from '../actions-type';

function* handleGetPart(action) {
  try {
    const result = yield call(getPart, action.payload.partUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetPartError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doGetPartError());
  }
}

function* handleGetPartFaculty(action) {
  try {
    const result = yield call(getPartFaculty, action.payload.partUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartFacultySuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetPartFacultyError(error));
  }
}

function* handlePatchPartStatus(action) {
  try {
    const result = yield call(patchPartStatus, action.payload.part);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchPartStatusSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPatchPartStatusError(error));
  }
}

function* handlePatchLocked(action) {
  try {
    const result = yield call(patchPartLocked, action.payload.part);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchPartLockSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPatchPartLockError(error));
  }
}

function* handlePatchPartCalculateGrade(action) {
  try {
    const result = yield call(patchPartCalculateGrade, action.payload.partUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchPartCalculateGradeSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPatchPartCalculateGradeError(errorText.error, action.payload.partUuid)
      );
    }
  } catch (error) {
    yield put(doPatchPartCalculateGradeError(error, action.payload.partUuid));
  }
}

function* handlePatchPartUndoGradeCalculation(action) {
  try {
    const result = yield call(
      patchPartUndoGradeCalculation,
      action.payload.partUuid
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchPartUndoCalculatedGradeSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(doPatchPartUndoCalculatedGradeError(errorText.error));
    }
  } catch (error) {
    yield put(doPatchPartUndoCalculatedGradeError(error));
  }
}

function* handlePutPartStatusCommit(action) {
  try {
    const result = yield call(putPartStatusCommit, action.payload.part);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutPartStatusCommitSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPutPartStatusCommitError(errorText.error, action.payload.part.uuid)
      );
    }
  } catch (error) {
    yield put(doPutPartStatusCommitError(error, action.payload.part.uuid));
  }
}

function* handlePutPartDelayedStudentGrade(action) {
  const { partUuid, studentUuid } = action.payload;

  try {
    const result = yield call(putDelayedStudentPartGrade, {
      partUuid,
      studentUuid
    });
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPutPartDelayedStudentGradeSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutPartDelayedStudentGradeError(error));
  }
}

function* handlePutPartDelayedStudentGradeStatusCommit(action) {
  const { partUuid, studentUuid, delayedStatusCommit, justificationMessage } =
    action.payload;

  try {
    const result = yield call(putDelayedStudentPartCommitStatus, {
      partUuid,
      studentUuid,
      delayedStatusCommit,
      justificationMessage
    });
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutPartDelayedStudentStatusCommitSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutPartDelayedStudentStatusCommitError(error));
  }
}

function* handlePostCloneExistingPart(action) {
  const { partUuid, partUuidExist } = action.payload;

  try {
    const result = yield call(postCloneExistingPart, {
      partUuid,
      partUuidExist
    });
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostCloneExistingPartSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostCloneExistingPartError(error));
  }
}

function* handleDeletePartDelayedStudentGrade(action) {
  const { partUuid, studentUuid } = action.payload;
  try {
    const result = yield call(deleteDelayedStudentPartGrade, {
      partUuid,
      studentUuid
    });
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeletePartDelayedStudentGradeSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeletePartDelayedStudentGradeError(error));
  }
}

const partSaga = [
  takeEvery(GET_PART, handleGetPart),
  takeEvery(GET_PART_FACULTY, handleGetPartFaculty),
  takeEvery(PATCH_PART_STATUS, handlePatchPartStatus),
  takeEvery(PATCH_PART_LOCKED, handlePatchLocked),
  takeEvery(PUT_PART_GRADE_DELAYED_STUDENT, handlePutPartDelayedStudentGrade),
  takeEvery(
    DELETE_PART_GRADE_DELAYED_STUDENT,
    handleDeletePartDelayedStudentGrade
  ),
  takeEvery(
    PUT_PART_DELAYED_STATUS_COMMIT,
    handlePutPartDelayedStudentGradeStatusCommit
  ),
  takeEvery(PATCH_PART_CALCULATE_GRADE, handlePatchPartCalculateGrade),
  takeEvery(
    PATCH_PART_UNDO_CALCULATED_GRADE,
    handlePatchPartUndoGradeCalculation
  ),
  takeEvery(PUT_PART_STATUS_COMMIT, handlePutPartStatusCommit),
  takeEvery(POST_CLONE_EXISTING_PART, handlePostCloneExistingPart)
];

export {
  partSaga,
  handlePatchLocked,
  handlePatchPartStatus,
  handleGetPartFaculty
};
