import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const applicantDemographicReportTypesSelector = ormCreateSelector(
  orm,
  session => {
    return session.ApplicantDemographicReportType.all().toRefArray();
  }
);

export const applicantCognitiveReportTypesSelector = ormCreateSelector(
  orm,
  session => {
    return session.ApplicantCognitiveReportType.all().toRefArray();
  }
);

export const applicantCommunicationReportTypesSelector = ormCreateSelector(
  orm,
  session => {
    return session.ApplicantCommunicationReportType.all().toRefArray();
  }
);

export const applicantNoncognitiveReportTypesSelector = ormCreateSelector(
  orm,
  session => {
    return session.ApplicantNoncognitiveReportType.all().toRefArray();
  }
);

export const applicantPhysicalSkillsReportTypesSelector = ormCreateSelector(
  orm,
  session => {
    return session.ApplicantPhysicalSkillsReportType.all().toRefArray();
  }
);
