import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { formatYYYY } from '../../../../helpers/date-fns.utils';
import { selectAcademicYear } from '../../../../redux/selectors/academicYearsSelectors';

const Input = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default function AcademicYear({ academicYearUuid }) {
  const academicYear = useSelector(state =>
    selectAcademicYear(state, academicYearUuid)
  );

  const startYear = formatYYYY(academicYear?.startDate);
  const endYear = formatYYYY(academicYear?.endDate);

  return (
    <Tooltip title={`${startYear}-${endYear}`} placement="top">
      <Input>{`${startYear}-${endYear}`}</Input>
    </Tooltip>
  );
}

AcademicYear.propTypes = {
  academicYearUuid: PropTypes.string
};

AcademicYear.defaultProps = {
  academicYearUuid: undefined
};
