import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2, ButtonInline, Body1 } from '@xcomp/xcomp-design-library';
import {
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import { Panel, PanelHeader } from '../../../../Library/Layout';
import { ButtonOutline } from '../../../../Library/ButtonSC';
import { InfoText } from '../../../../Library/FormComponents';
import CollectionAssessmentForm from './CollectionAssessmentForm';
import ScoreTypeForm from './ScoreTypeForm';

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const NoAssessmentsMessage = styled(Body1)`
  width: 100%;
  margin: 8px 8px 24px;

  span {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const SubmitButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const CollectionAssessments = styled.div`
  margin: 24px 0;
`;

const CheckboxContainer = styled.div`
  color: ${props => props.theme.fontColors.dark};
  margin-bottom: 0;
`;

const PanelHeaderSC = styled(PanelHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FormControlLabelSC = styled(FormControlLabel)`
  && {
    margin-right: 0;
  }
`;

const DistributeEvenlyRow = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
`;

const ButtonOutlineSC = styled(ButtonOutline)``;

const EditCollectionAssessmentsForm = ({
  title,
  scoreType,
  distributeWeightEqually,
  collection_assessments,
  gradeCollectionUuid,
  partUuid,
  partOptions,
  assessmentOptions,
  usedAssessmentUuids,
  formDispatch,
  setUnsavedChange,
  showValidationErrors,
  handleAddCollectionAssessment,
  handleDistributeWeightEquallyChange,
  handleSubmitAssessments,
  unsavedChanges
}) => {
  const handleScoreTypeChange = event => {
    setUnsavedChange('collectionAssessments');
    formDispatch({
      type: 'setScoreType',
      payload: {
        scoreType: event.target.value
      }
    });
  };
  return (
    <Panel>
      <PanelHeaderSC paddingLeft="24px" paddingRight="24px">
        <Heading2>{title}</Heading2>
      </PanelHeaderSC>
      <PanelPadding>
        <ScoreTypeForm
          handleChange={handleScoreTypeChange}
          scoreType={scoreType}
          showValidationErrors={showValidationErrors}
        />
      </PanelPadding>
      <PanelPadding>
        {Object.keys(collection_assessments).length > 0 ? (
          <CollectionAssessments>
            {Object.keys(collection_assessments).map((uuid, index) => {
              const {
                assessmentUuid,
                weight,
                required
              } = collection_assessments[uuid];
              return (
                <CollectionAssessmentForm
                  key={uuid}
                  index={index}
                  uuid={uuid}
                  partUuid={partUuid}
                  assessmentUuid={assessmentUuid}
                  weight={weight}
                  required={required}
                  distributeWeightEqually={distributeWeightEqually}
                  partOptions={partOptions}
                  assessmentOptions={assessmentOptions}
                  usedAssessmentUuids={usedAssessmentUuids}
                  formDispatch={formDispatch}
                  setUnsavedChange={setUnsavedChange}
                  handlePartChange={undefined}
                  showValidationErrors={showValidationErrors}
                  isPartFixed
                />
              );
            })}
          </CollectionAssessments>
        ) : (
          <NoAssessmentsMessage>
            No assessments have been added to this collection. Click{' '}
            <span>Add Assessment</span> below to add one.
          </NoAssessmentsMessage>
        )}
        <ButtonInline onClick={handleAddCollectionAssessment} noHoverBg>
          Add Assessment
        </ButtonInline>
        <DistributeEvenlyRow>
          <FormControl component="fieldset">
            <FormGroup>
              <CheckboxContainer>
                <FormControlLabelSC
                  control={
                    <Checkbox
                      id={`distributeWeightEqually-${gradeCollectionUuid}`}
                      onChange={handleDistributeWeightEquallyChange}
                      checked={distributeWeightEqually}
                      color="primary"
                    />
                  }
                  label="Distribute weight equally among assessments"
                />
              </CheckboxContainer>
            </FormGroup>
          </FormControl>
        </DistributeEvenlyRow>
        <SubmitButtonRow>
          {unsavedChanges.collectionAssessments && (
            <InfoText
              alignItems="flex-end"
              justifyContent="flex-end"
              padding="0 8px 0 0"
              error
            >
              {/* <InfoIcon /> */}
              Unsaved Changes
            </InfoText>
          )}
          <ButtonOutlineSC
            onClick={handleSubmitAssessments}
            disabled={!unsavedChanges.collectionAssessments}
          >
            Apply
          </ButtonOutlineSC>
        </SubmitButtonRow>
      </PanelPadding>
    </Panel>
  );
};

EditCollectionAssessmentsForm.propTypes = {
  partUuid: PropTypes.string,
  title: PropTypes.string,
  scoreType: PropTypes.string,
  distributeWeightEqually: PropTypes.bool,
  collection_assessments: PropTypes.object,
  gradeCollectionUuid: PropTypes.string,
  partOptions: PropTypes.arrayOf(PropTypes.object),
  assessmentOptions: PropTypes.arrayOf(PropTypes.object),
  handleAddCollectionAssessment: PropTypes.func,
  handleDistributeWeightEquallyChange: PropTypes.func,
  showValidationErrors: PropTypes.bool,
  formDispatch: PropTypes.func,
  handleSubmitAssessments: PropTypes.func,
  unsavedChanges: PropTypes.object,
  usedAssessmentUuids: PropTypes.object,
  setUnsavedChange: PropTypes.func
};

EditCollectionAssessmentsForm.defaultProps = {
  title: '',
  scoreType: 'SCORE',
  distributeWeightEqually: false,
  collection_assessments: {},
  gradeCollectionUuid: '',
  partUuid: '',
  partOptions: [],
  assessmentOptions: [],
  handleAddCollectionAssessment: undefined,
  handleDistributeWeightEquallyChange: undefined,
  showValidationErrors: false,
  formDispatch: undefined,
  handleSubmitAssessments: undefined,
  unsavedChanges: {},
  usedAssessmentUuids: {},
  setUnsavedChange: undefined
};

export default EditCollectionAssessmentsForm;
