import fetch from '../helpers/fetch';

const getAssessmentBlocks = assessmentUuid =>
  fetch(`/api/assessment/${assessmentUuid}/blocks`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getAssessmentBlockResults = assessmentUuid =>
  fetch(`/api/assessment/${assessmentUuid}/block-results`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getAssessmentBlocks, getAssessmentBlockResults };
