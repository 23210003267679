import { createSelector as ormCreateSelector } from 'redux-orm';
import { createSelector } from 'reselect';
import { orm } from '../models';

export const selectSourceInstitution = ormCreateSelector(orm.SourceInstitution);

export const selectDegreeLevel = ormCreateSelector(orm.DegreeLevel);

export const selectInstitutionIdType = ormCreateSelector(orm.InstitutionIdType);

export const selectProgramCode = ormCreateSelector(orm.ProgramCode);

export const selectProgramDiscipline = ormCreateSelector(orm.ProgramDiscipline);

export const selectProgramCip = ormCreateSelector(orm.ProgramCip);

export const selectProgramCodeSet = createSelector(
  state => state,
  (_, programCodeUuid) => programCodeUuid,
  (redux, programCodeUuid) => {
    const programCode = selectProgramCode(redux, programCodeUuid);

    const programDiscipline = selectProgramDiscipline(
      redux,
      programCode.disciplineUuid
    );

    const programCip = selectProgramCip(redux, programDiscipline.cipcodeUuid);

    return { programCode, programDiscipline, programCip };
  }
);
