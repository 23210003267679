import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Heading3, Body2, ButtonInline } from '@xcomp/xcomp-design-library';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { v4 as uuidv4 } from 'uuid';
import { validateInputString } from '../../../../helpers/validation/validateGeneric';
import { TextFieldWithError } from '../../../Library/FormComponents';
import SimpleModal from '../../../Library/Modal/SimpleModal';

const ModalContent = styled.div`
  width: 100%;
  padding: 1rem 2rem 1rem 1.5rem;
  min-width: 528px;
`;

const ModalHeader = styled.div`
  width: 100%;
  h3 {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;

const ModalBody = styled.div`
  width: 100%;
`;

const ModalCheckbox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const ModalActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.5rem;
`;

const CreateOrEditGroupModal = ({
  groupIdInput,
  isEditMode,
  isModalOpen,
  shouldUseGroupNumber,
  showValidationErrors,
  onGroupIdChange,
  onModalClose,
  onToggleShouldUseGroupNumber,
  onCreateNewGroup,
  onEditGroup
}) => {
  const heading = isEditMode ? 'Edit Group ID' : 'Create Group';
  const verb = isEditMode ? 'SAVE' : 'CREATE';
  return (
    <SimpleModal
      open={Boolean(isModalOpen)}
      onClose={onModalClose}
      maxWidth="md"
    >
      <ModalContent>
        <ModalHeader>
          <Heading3>{heading}</Heading3>
        </ModalHeader>
        <ModalBody>
          <TextFieldWithError
            handleValidate={validateInputString}
            hasErrors={showValidationErrors}
            id={uuidv4()}
            InputLabelProps={{ shrink: true }}
            label="Group ID"
            margin="normal"
            name="groupId"
            onChange={onGroupIdChange}
            placeholder=""
            value={groupIdInput}
            variant="outlined"
            disabled={shouldUseGroupNumber}
          />
          <ModalCheckbox>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shouldUseGroupNumber}
                  onChange={onToggleShouldUseGroupNumber}
                  color="primary"
                />
              }
            />
            <Body2>Use group number as ID</Body2>
          </ModalCheckbox>
        </ModalBody>
        <ModalActions>
          <ButtonInline onClick={onModalClose}>CANCEL</ButtonInline>
          <ButtonInline
            color="secondary"
            onClick={isEditMode ? onEditGroup : onCreateNewGroup}
          >
            {verb}
          </ButtonInline>
        </ModalActions>
      </ModalContent>
    </SimpleModal>
  );
};

CreateOrEditGroupModal.propTypes = {
  groupIdInput: PropTypes.string,
  isEditMode: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  shouldUseGroupNumber: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  onCreateNewGroup: PropTypes.func,
  onEditGroup: PropTypes.func,
  onGroupIdChange: PropTypes.func,
  onModalClose: PropTypes.func,
  onToggleShouldUseGroupNumber: PropTypes.func
};
CreateOrEditGroupModal.defaultProps = {
  groupIdInput: '',
  isEditMode: false,
  isModalOpen: false,
  shouldUseGroupNumber: false,
  showValidationErrors: false,
  onCreateNewGroup: undefined,
  onEditGroup: undefined,
  onGroupIdChange: undefined,
  onModalClose: undefined,
  onToggleShouldUseGroupNumber: undefined
};

export default CreateOrEditGroupModal;
