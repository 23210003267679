import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import {
  DeleteIcon,
  AddUserIcon,
  MassAssignIcon,
  ConversionIcon
} from '@xcomp/xcomp-design-library/dist/icons';

import CohortApplicantStatusMenu from './CohortApplicantStatusMenu';

const Actions = styled.div`
  background: ${props => props.theme.colors.white};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-top: 10px;
  padding-bottom: 10px;
`;

const IconSpacing = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;

export default function CohortApplicantActions({
  handleAssignApplicantOpen,
  handleOpenApplicanUpload,
  handleSelectedStatus,
  handleInitiateDelete,
  handleStudentConversion
}) {
  return (
    <div>
      <Actions>
        <IconSpacing>
          <Tooltip title="Change Status" placement="top-start">
            <IconButton>
              <CohortApplicantStatusMenu
                handleSelectedStatus={handleSelectedStatus}
              />
            </IconButton>
          </Tooltip>
        </IconSpacing>
        <IconSpacing>
          <Tooltip title="Convert Students" placement="top-start">
            <IconButton onClick={handleStudentConversion}>
              <ConversionIcon />
            </IconButton>
          </Tooltip>
        </IconSpacing>
        <IconSpacing>
          <Tooltip title="Assign Individual Applicant" placement="top-start">
            <IconButton onClick={handleAssignApplicantOpen}>
              <AddUserIcon />
            </IconButton>
          </Tooltip>
        </IconSpacing>
        <IconSpacing>
          <Tooltip title="Mass Assign Applicants (CSV)" placement="top-start">
            <IconButton onClick={handleOpenApplicanUpload}>
              <MassAssignIcon />
            </IconButton>
          </Tooltip>
        </IconSpacing>
        <IconSpacing>
          <Tooltip title="Delete" placement="top-start">
            <IconButton onClick={handleInitiateDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </IconSpacing>
      </Actions>
    </div>
  );
}

CohortApplicantActions.propTypes = {
  handleAssignApplicantOpen: PropTypes.func,
  handleSelectedStatus: PropTypes.func,
  handleOpenApplicanUpload: PropTypes.func,
  handleInitiateDelete: PropTypes.func,
  handleStudentConversion: PropTypes.func
};
CohortApplicantActions.defaultProps = {
  handleAssignApplicantOpen: undefined,
  handleSelectedStatus: undefined,
  handleOpenApplicanUpload: undefined,
  handleInitiateDelete: undefined,
  handleStudentConversion: undefined
};
