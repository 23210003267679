export const INITIAL_STATE = {
  course: {},
  sections: {},
  parts: {}
};

export const descriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'COURSE_DESCRIPTION': {
      const { payload } = action;

      return {
        ...state,
        course: payload.description
      };
    }
    case 'SECTION_DESCRIPTION': {
      const { payload } = action;

      return {
        ...state,
        sections: {
          ...state.sections,
          [payload?.section?.uuid]: payload.description
        }
      };
    }
    case 'PART_DESCRIPTION': {
      const { payload } = action;

      return {
        ...state,
        parts: {
          ...state.parts,
          [payload?.part?.uuid]: payload.description
        }
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};
