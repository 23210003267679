import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { ExpandIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { Subtitle1, Caption } from '@xcomp/xcomp-design-library';
import Checkbox from '@material-ui/core/Checkbox';

import ActionButton from '../ActionButton';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
  position: relative;
`;

const RowSummary = styled(
  ({
    height,
    rowOffset,
    published,
    disabled,
    isChecked,
    isTopLevel,
    ...rest
  }) => <div {...rest} />
)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: ${props => props.height};
  font-size: 0.9375rem;
  font-weight: 300;
  color: ${props => props.theme.fontColors.dark};
  margin-left: ${props => !props.isTopLevel && props.rowOffset};
  .show_on_hover {
    display: none;
  }

  .hover-effect {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    background: ${props =>
      props.published ? props.theme.colors.grey[100] : 'transparent'};
  }

  &:hover {
    .hover-effect {
      background: ${props =>
        props.disabled ? 'none' : props.theme.colors.grey[100]};
    }

    .show_on_hover {
      display: flex;
    }
  }

  h2 {
    font-size: inherit;
    font-weight: 400;
    color: ${props => props.theme.fontColors.darker};
    margin: 0;
    padding: 0;
  }

  svg {
    vertical-align: middle;
  }
`;

const RowContent = styled(({ leaf, gutter, ...rest }) => <div {...rest} />)`
  flex-grow: 2;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9375rem;
  font-weight: 300;
  color: ${props => props.theme.fontColors.dark};
  padding-left: ${props => (props.leaf ? props.gutter : 'initial')};

  h2 {
    font-size: inherit;
    font-weight: 400;
    color: ${props => props.theme.fontColors.darker};
    margin: 0;
    padding: 0;
  }
`;

const ActionsColumn = styled(({ isPublished, ...rest }) => <div {...rest} />)`
  && {
    margin-left: auto;
    width: ${props => (props.isPublished ? '88px' : '66px')};
    height: 100%;
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }
`;

const StyledPublishedIcon = styled(LockOutlined)`
  && {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    align-item: center;
    font-size: 1.25rem;
  }
`;

const RowChild = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

const ContentColumn = styled(({ gutter, disabled, ...rest }) => (
  <div {...rest} />
))`
  font-size: inherit;
  display: block;
  margin-right: ${props => props.gutter || '1rem'};

  ${props =>
    props.disabled &&
    css`
      font-style: italic;
    `}
`;

const CaptionSC = styled(Caption)`
  color: ${props => props.theme.colors.grey[600]};
`;

const SubtitleSC = styled(({ light, ...rest }) => <Subtitle1 {...rest} />)`
  && {
    color: ${props =>
      props.light
        ? props.theme.colors.grey[600]
        : props.theme.colors.grey[900]};
  }
`;

const ExpandColumn = styled(({ width, ...rest }) => <div {...rest} />)`
  && {
    width: ${props => props.width};
    height: 100%;
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

const RowButtons = styled.div`
  display: flex;
  wrap: nowrap;
  justify-content: flex-end;
  padding-right: 0.5rem;
`;

const ClosedIcon = styled(ExpandIcon)`
  transform: rotate(180deg);
`;

const OpenedIcon = styled(ExpandIcon)`
  transform: rotate(270deg);
`;

const getIcon = isRowOpen => {
  if (!isRowOpen) {
    return <ClosedIcon />;
  }
  return <OpenedIcon />;
};

const CheckboxAccordionRow = ({
  actions,
  children,
  label,
  columnOne,
  columnTwo,
  expandWidth,
  rowOffset,
  className,
  columnOneClassName,
  columnTwoClassName,
  leaf,
  disabled,
  columnGutters,
  height,
  isRowOpen,
  isChecked,
  isPublished,
  isTopLevel,
  hideCheckBox,
  toggleOpenRow,
  handleCheckboxChange
}) => {
  const getOnClick = (disabled, toggleOpenRow) => {
    if (disabled) {
      return undefined;
    }
    return toggleOpenRow;
  };

  const stopPropagation = e => e.stopPropagation();

  return (
    <Layout className={className}>
      <RowSummary
        onClick={getOnClick(disabled, toggleOpenRow)}
        published={isPublished}
        height={height}
        rowOffset={rowOffset}
        disabled={disabled}
        isChecked={isChecked}
        isTopLevel={isTopLevel}
      >
        <div className="hover-effect">
          {!hideCheckBox && (
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              onClick={stopPropagation}
              inputProps={{ 'aria-label': `check this item` }}
              color="primary"
            />
          )}
          {!leaf && (
            <ExpandColumn width={expandWidth || '66px'}>
              {getIcon(isRowOpen)}
            </ExpandColumn>
          )}
          <RowContent leaf={leaf} gutter={columnGutters}>
            {label && (
              <ContentColumn gutter={columnGutters}>
                <CaptionSC>{label}</CaptionSC>
              </ContentColumn>
            )}
            {columnOne && (
              <ContentColumn
                disabled={disabled}
                gutter={columnGutters}
                className={columnOneClassName}
              >
                <SubtitleSC light={!isTopLevel || disabled}>
                  {columnOne}
                </SubtitleSC>
              </ContentColumn>
            )}
            {columnTwo && (
              <ContentColumn
                disabled={disabled}
                gutter={columnGutters}
                className={columnTwoClassName}
              >
                <SubtitleSC light={disabled}>{columnTwo}</SubtitleSC>
              </ContentColumn>
            )}
          </RowContent>
          {(actions || isPublished) && (
            <ActionsColumn isPublished>
              <RowButtons>
                {actions && (
                  <ActionButton
                    className="show_on_hover"
                    actions={actions}
                    isRowOpen={isRowOpen}
                    toggleOpenRow={toggleOpenRow}
                  />
                )}
                {isPublished && <StyledPublishedIcon />}
              </RowButtons>
            </ActionsColumn>
          )}
        </div>
      </RowSummary>
      {isRowOpen && !leaf && <RowChild>{children}</RowChild>}
    </Layout>
  );
};

CheckboxAccordionRow.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool
  ]),
  children: PropTypes.node,
  label: PropTypes.string,
  columnOne: PropTypes.string,
  columnTwo: PropTypes.string,
  columnOneClassName: PropTypes.string,
  columnTwoClassName: PropTypes.string,
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  handleCheckboxChange: PropTypes.func,
  className: PropTypes.string,
  expandWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  columnGutters: PropTypes.string,
  height: PropTypes.string,
  rowOffset: PropTypes.string,
  leaf: PropTypes.bool,
  isRowOpen: PropTypes.bool,
  isPublished: PropTypes.bool,
  isTopLevel: PropTypes.bool,
  hideCheckBox: PropTypes.bool,
  toggleOpenRow: PropTypes.func
};

CheckboxAccordionRow.defaultProps = {
  actions: false,
  children: <div />,
  label: '',
  columnOne: '',
  columnTwo: '',
  columnOneClassName: '',
  columnTwoClassName: '',
  disabled: false,
  isChecked: false,
  handleCheckboxChange: undefined,
  className: '',
  expandWidth: false,
  columnGutters: '0.5rem',
  height: '43px',
  rowOffset: '0',
  leaf: false,
  isRowOpen: false,
  isPublished: false,
  isTopLevel: false,
  hideCheckBox: false,
  toggleOpenRow: undefined
};

export default CheckboxAccordionRow;
export { getIcon, RowChild, RowSummary, StyledPublishedIcon };
