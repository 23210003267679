export const GET_ASSESSMENT_ASSOCIATIONS = 'GET_ASSESSMENT_ASSOCIATIONS';
export const GET_ASSESSMENT_ASSOCIATIONS_SUCCESS =
  'GET_ASSESSMENT_ASSOCIATIONS_SUCCESS';
export const GET_ASSESSMENT_ASSOCIATIONS_ERROR =
  'GET_ASSESSMENT_ASSOCIATIONS_ERROR';

export const GET_ASSESSMENT_TECHNIQUES = 'GET_ASSESSMENT_TECHNIQUES';
export const GET_ASSESSMENT_TECHNIQUES_SUCCESS =
  'GET_ASSESSMENT_TECHNIQUES_SUCCESS';
export const GET_ASSESSMENT_TECHNIQUES_ERROR =
  'GET_ASSESSMENT_TECHNIQUES_ERROR';

export const GET_ASSESSMENT_TYPE = 'GET_ASSESSMENT_TYPE';
export const GET_ASSESSMENT_TYPE_SUCCESS = 'GET_ASSESSMENT_TYPE_SUCCESS';
export const GET_ASSESSMENT_TYPE_ERROR = 'GET_ASSESSMENT_TYPE_ERROR';

export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const GET_ASSESSMENT_SUCCESS = 'GET_ASSESSMENT_SUCCESS';
export const GET_ASSESSMENT_ERROR = 'GET_ASSESSMENT_ERROR';

export const GET_ASSESSMENTS_BY_PART = 'GET_ASSESSMENTS_BY_PART';
export const GET_ASSESSMENTS_BY_PART_SUCCESS =
  'GET_ASSESSMENTS_BY_PART_SUCCESS';
export const GET_ASSESSMENTS_BY_PART_ERROR = 'GET_ASSESSMENTS_BY_PART_ERROR';

export const POST_ASSESSMENT = 'POST_ASSESSMENT';
export const POST_ASSESSMENT_SUCCESS = 'POST_ASSESSMENT_SUCCESS';
export const POST_ASSESSMENT_ERROR = 'POST_ASSESSMENT_ERROR';

export const PUT_ASSESSMENT_DRAFT = 'PUT_ASSESSMENT_DRAFT';
export const PUT_ASSESSMENT_DRAFT_SUCCESS = 'PUT_ASSESSMENT_DRAFT_SUCCESS';
export const PUT_ASSESSMENT_DRAFT_ERROR = 'PUT_ASSESSMENT_DRAFT_ERROR';

export const PUT_ASSESSMENT_STATUS_SCORE = 'PUT_ASSESSMENT_STATUS_SCORE';
export const PUT_ASSESSMENT_STATUS_SCORE_SUCCESS =
  'PUT_ASSESSMENT_STATUS_SCORE_SUCCESS';
export const PUT_ASSESSMENT_STATUS_SCORE_ERROR =
  'PUT_ASSESSMENT_STATUS_SCORE_ERROR';

export const PUT_ASSESSMENT_STATUS_COMMIT = 'PUT_ASSESSMENT_STATUS_COMMIT';
export const PUT_ASSESSMENT_STATUS_COMMIT_SUCCESS =
  'PUT_ASSESSMENT_STATUS_COMMIT_SUCCESS';
export const PUT_ASSESSMENT_STATUS_COMMIT_ERROR =
  'PUT_ASSESSMENT_STATUS_COMMIT_ERROR';

export const PUT_ASSESSMENT_GRADE_DELAYED_STUDENT =
  'PUT_ASSESSMENT_GRADE_DELAYED_STUDENT';
export const PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS =
  'PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS';
export const PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_ERROR =
  'PUT_ASSESSMENT_GRADE_DELAYED_STUDENT_ERROR';

export const DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT =
  'DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT';
export const DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS =
  'DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_SUCCESS';
export const DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_ERROR =
  'DELETE_ASSESSMENT_GRADE_DELAYED_STUDENT_ERROR';

export const PUT_ASSESSMENT_DELAYED_STATUS_COMMIT =
  'PUT_ASSESSMENT_DELAYED_STATUS_COMMIT';
export const PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_SUCCESS =
  'PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_SUCCESS';
export const PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_ERROR =
  'PUT_ASSESSMENT_DELAYED_STATUS_COMMIT_ERROR';

export const RESET_STATUS_COMMIT_MODAL = 'RESET_STATUS_COMMIT_MODAL';

export const PUT_ASSESSMENT_STATUS = 'PUT_ASSESSMENT_STATUS';
export const PUT_ASSESSMENT_STATUS_SUCCESS = 'PUT_ASSESSMENT_STATUS_SUCCESS';
export const PUT_ASSESSMENT_STATUS_ERROR = 'PUT_ASSESSMENT_STATUS_ERROR';

export const PUT_ASSESSMENT_FINAL = 'PUT_ASSESSMENT_FINAL';
export const PUT_ASSESSMENT_FINAL_SUCCESS = 'PUT_ASSESSMENT_FINAL_SUCCESS';
export const PUT_ASSESSMENT_FINAL_ERROR = 'PUT_ASSESSMENT_FINAL_ERROR';

export const PATCH_ASSESSMENT_APPROVE = 'PATCH_ASSESSMENT_APPROVE';
export const PATCH_ASSESSMENT_APPROVE_SUCCESS =
  'PATCH_ASSESSMENT_APPROVE_SUCCESS';
export const PATCH_ASSESSMENT_APPROVE_ERROR = 'PATCH_ASSESSMENT_APPROVE_ERROR';

export const DELETE_ASSESSMENT = 'DELETE_ASSESSMENT';
export const DELETE_ASSESSMENT_SUCCESS = 'DELETE_ASSESSMENT_SUCCESS';
export const DELETE_ASSESSMENT_ERROR = 'DELETE_ASSESSMENT_ERROR';

export const CLEAR_CREATED_ASSESSMENT = 'CLEAR_CREATED_ASSESSMENT';
export const CLEAR_UPDATED_ASSESSMENT = 'CLEAR_UPDATED_ASSESSMENT';
