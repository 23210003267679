import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CSVReader from 'react-csv-reader';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonPin from '@material-ui/icons/PersonPin';
import { ButtonPrimary, ButtonOutline } from '@xcomp/xcomp-design-library';
import Modal from '../../../Library/Modal/Modal';
import UploadMapping from '../../../Library/UploadMapping';
import UploadErrors from '../../../Library/UploadErrors';
import { headersStudentAssign } from '../../../../helpers/headers_upload/headers_roles';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 900px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  margin-bottom: 20px;
`;

const FileUpload = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Note = styled.div`
  margin-top: 10px;
  font-size: 12px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
const Close = styled(ButtonOutline)`
  && {
    margin-right: 5px;
  }
`;

const Import = styled(ButtonPrimary)`
  && {
    margin-left: 5px;
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Created = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.white};
  border-radius: 5px;
  padding: 15px;
  background: ${props => props.theme.colors.status.success};
`;

export default function StudentMassAssign({
  uploadState,
  students,
  openModal,
  openMapping,
  handleMassAssignStudentClose,
  handleStudents,
  handleUpload,
  handleDisplayMapping
}) {
  const { uploadingStudentsSection, studentsAssigned } = uploadState;

  return (
    <Modal open={openModal} onClose={handleMassAssignStudentClose}>
      <ModalBody>
        <Title>Mass Assign Students (CSV)</Title>
        <FileUpload>
          <CSVReader
            onFileLoaded={handleStudents}
            parserOptions={{ header: true }}
            inputId="userload"
            inputStyle={{
              width: '100%',
              color: 'black',
              background: 'lightgrey',
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '10px',
              borderRadius: '5px'
            }}
          />
          <Note>
            If changes are made to an existing selected file, close this modal
            and re-open then re-select the file.
          </Note>
        </FileUpload>
        <Actions>
          <ButtonPrimary onClick={handleDisplayMapping}>
            {openMapping ? `Hide Rules` : `Show Rules`}
          </ButtonPrimary>
          <div>
            <Close onClick={handleMassAssignStudentClose}>Close</Close>
            <Import disabled={!students} onClick={handleUpload}>
              Import
            </Import>
          </div>
        </Actions>

        {uploadingStudentsSection && (
          <Loading>
            <CircularProgress size="100px" />
          </Loading>
        )}

        {studentsAssigned > 0 && (
          <Created>
            <PersonPin />
            {studentsAssigned} students assigned to section.
          </Created>
        )}
        <UploadMapping display={openMapping} fields={headersStudentAssign} />
        <UploadErrors uploadState={uploadState} />
      </ModalBody>
    </Modal>
  );
}

StudentMassAssign.propTypes = {
  uploadState: PropTypes.object,
  students: PropTypes.array,
  studentsAssigned: PropTypes.number,
  openModal: PropTypes.bool,
  openMapping: PropTypes.bool,
  handleMassAssignStudentClose: PropTypes.func,
  handleStudents: PropTypes.func,
  handleUpload: PropTypes.func,
  handleDisplayMapping: PropTypes.func
};

StudentMassAssign.defaultProps = {
  uploadState: {},
  students: undefined,
  studentsAssigned: 0,
  openModal: false,
  openMapping: false,
  handleMassAssignStudentClose: undefined,
  handleStudents: undefined,
  handleUpload: undefined,
  handleDisplayMapping: undefined
};
