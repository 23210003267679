import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ButtonOutline, Body1 } from '@xcomp/xcomp-design-library';
import { PanelLabel } from '../../../Library/DashboardSC';

import CourseCollectionRowContainer from './CourseCollectionRowContainer';

const Layout = styled.div`
  && {
    width: 100%;
    padding-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const ButtonRow = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

const CohortCourseCollectionsList = ({
  parentCohortUuid,
  courseCollections
}) => (
  <Layout>
    <ButtonRow>
      <Link
        to={`/program-management/cohort/${parentCohortUuid}/course-collection/add`}
      >
        <ButtonOutline>Add Collection</ButtonOutline>
      </Link>
    </ButtonRow>
    {courseCollections && courseCollections.length > 0 ? (
      <>
        <PanelLabel marginLeft="66px">Course Collections</PanelLabel>
        {courseCollections.map((collection, index) => (
          <CourseCollectionRowContainer
            key={collection.uuid}
            courseCollection={collection}
            withTopBorder={index === 0}
          />
        ))}
      </>
    ) : (
      <Body1>There are currently no course collections for this cohort.</Body1>
    )}
  </Layout>
);

CohortCourseCollectionsList.propTypes = {
  parentCohortUuid: PropTypes.string.isRequired,
  courseCollections: PropTypes.array.isRequired
};

export default CohortCourseCollectionsList;
