import { call, put, takeEvery } from 'redux-saga/effects';
import { getCalculatedPartGradeResultsBySection } from '../../api/calculatedGradeResultApi';

import {
  doGetCalculatedPartGradeResultsBySectionSuccess,
  doGetCalculatedPartGradeResultsBySectionError
} from '../actions/calculatedGradeResultActions';

import { GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION } from '../actions-type';

function* handleGetCalculatedPartGradeResultsBySection(action) {
  const { sectionUuid } = action.payload;
  try {
    const result = yield call(
      getCalculatedPartGradeResultsBySection,
      sectionUuid
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCalculatedPartGradeResultsBySectionSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCalculatedPartGradeResultsBySectionError(error));
  }
}

const calculatedGradeResult = [
  takeEvery(
    GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION,
    handleGetCalculatedPartGradeResultsBySection
  )
];

export { calculatedGradeResult };
