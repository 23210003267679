import {
  GET_ASSESSMENT_GRADES,
  GET_ASSESSMENT_GRADES_SUCCESS,
  GET_ASSESSMENT_GRADES_ERROR
} from '../actions-type';

const doGetAssessmentGrades = (assessmentUuid, studentUuid) => ({
  type: GET_ASSESSMENT_GRADES,
  payload: {
    assessmentUuid,
    studentUuid
  }
});

const doGetAssessmentGradesSuccess = result => ({
  type: GET_ASSESSMENT_GRADES_SUCCESS,
  payload: result
});

const doGetAssessmentGradesError = error => ({
  type: GET_ASSESSMENT_GRADES_ERROR,
  payload: error
});

export {
  doGetAssessmentGrades,
  doGetAssessmentGradesError,
  doGetAssessmentGradesSuccess
};
