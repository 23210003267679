import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import _ from 'lodash';
import { scoresStepSelector } from '../../../../redux/selectors/scoreSelectors';

import ScoreAddContainer from '../ScoreAdd/ScoreAddContainer';
import ScoreAddAttemptContainer from '../ScoreAddAttempt/ScoreAddAttemptContainer';
import ScoreEditContainer from '../ScoreEdit/ScoreEditContainer';
import StepsList from './StepList';
import { studentRosterSelectorBySectionUuid } from '../../../../redux/selectors/sectionsSelectors';
import {
  mapStudentScoresByStudentSectionStatus,
  createStudentMap
} from '../../../../helpers/sections';
import { assessmentBlockResultsPerStudent } from '../../../../redux/selectors/assessmentBlockSelectors';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 60vh;
  align-items: center;
  font-size: 80px;
`;

export default function StepListContainer({
  assessmentBlocks,
  assessmentType,
  assessmentUuid,
  partUuid,
  scoreType,
  sectionUuid,
  handleUploadDelayedScoresOpen
}) {
  const [openScoreAdd, setOpenScoreAdd] = useState(false);
  const [addStudent, setAddStudent] = useState('');

  const [addAttempt, setAddAttempt] = useState(0);
  const [addResultCard, setAddResultCard] = useState('');
  const [addBlockUuid, setAddBlockUuid] = useState('');

  const [openScoreEdit, setOpenScoreEdit] = useState(false);
  const [editScoreUuid, setEditScoreUuid] = useState('');

  const [openScoreAddAttempt, setOpenScoreAddAttempt] = useState(false);
  const [addAttemptStudent, setAddAttemptStudent] = useState('');
  const [latestAttempt, setAddLatestAttempt] = useState(null);
  const [activeScores, setActiveScores] = useState([]);
  const [delayedScores, setDelayedScores] = useState([]);
  const [withdrewScores, setWithdrewScores] = useState([]);

  const [activeScoresFilterSelection, setActiveScoresFilterSelection] =
    useState('alphabetic');
  const [activeScoresFilter, setActiveScoresFilter] = useState([]);

  const [delayedScoresFilterSelection, setDelayedScoresFilterSelection] =
    useState('alphabetic');
  const [delayedScoresFilter, setDelayedScoresFilter] = useState([]);

  const [withdrewScoresFilterSelection, setWithdrewScoresFilterSelection] =
    useState('alphabetic');
  const [withdrewScoresFilter, setWithdrewScoresFilter] = useState([]);

  const scores = useSelector(
    state => scoresStepSelector(state, assessmentUuid, sectionUuid, scoreType),
    _.isEqual
  );

  const loading = useSelector(state => state.crudState.readScores);

  const students = useSelector(state =>
    studentRosterSelectorBySectionUuid(state, sectionUuid)
  );

  const resultsPerStudent = useSelector(
    state => assessmentBlockResultsPerStudent(state, assessmentBlocks.map(ab => ab.uuid)),
    _.isEqual
  );

  useEffect(() => {
    const studentsMap = students?.length > 0 ? createStudentMap(students) : {};

    if (scores) {
      const {
        delayedStudentScores,
        activeStudentScores,
        withdrewStudentScores
      } = mapStudentScoresByStudentSectionStatus(scores, studentsMap, resultsPerStudent);

      if (activeStudentScores) {
        setActiveScores(activeStudentScores);
        setActiveScoresFilter(activeStudentScores);
      }
      if (delayedStudentScores) {
        setDelayedScoresFilter(delayedStudentScores);
        setDelayedScores(delayedStudentScores);
      }
      if (withdrewStudentScores) {
        setWithdrewScoresFilter(withdrewStudentScores);
        setWithdrewScores(withdrewStudentScores);
      }
    }
  }, [scores, students, resultsPerStudent]);

  const handleScoreAddAttemptModalOpen = (studentUuid, latestAttempt) => {
    setAddAttemptStudent(studentUuid);
    setAddLatestAttempt(latestAttempt);
    setOpenScoreAddAttempt(true);
  };

  const handleScoreAddAttemptModalClose = () => {
    setOpenScoreAddAttempt(false);
  };

  const handleScoreAddModalOpen = (
    studentUuid,
    attempt,
    gradeCardUuid,
    assessmentBlockUuid
  ) => {
    setAddStudent(studentUuid);
    setAddAttempt(attempt);
    setAddResultCard(gradeCardUuid);
    setAddBlockUuid(assessmentBlockUuid);
    setOpenScoreAdd(true);
  };

  const handleScoreAddModalClose = () => {
    setOpenScoreAdd(false);
  };

  const handleScoreEditModalOpen = scoreUuid => {
    setEditScoreUuid(scoreUuid);
    setOpenScoreEdit(true);
  };

  const handleScoreEditModalClose = () => {
    setOpenScoreEdit(false);
  };

  const handleSortActiveScores = event => {
    const { value } = event.target;

    if (value === 'alphabetic') {
      setActiveScoresFilterSelection('alphabetic');
      setActiveScoresFilter(activeScores);
    } else if (value === 'relative') {
      const sortByTotalRel = _.orderBy(
        activeScores,
        ['totalRel', 'fullName'],
        ['desc', 'asc']
      );

      setActiveScoresFilterSelection(value);
      setActiveScoresFilter(sortByTotalRel);
    } else if (value === 'unreported') {
      const unreported = _.filter(
        activeScores,
        score => score.unreported === true
      );
      setActiveScoresFilterSelection(value);
      setActiveScoresFilter(unreported);
    } else {
      setActiveScoresFilterSelection('');
      setActiveScoresFilter(activeScores);
    }
  };

  const handleSortDelayedScores = event => {
    const { value } = event.target;

    if (value === 'alphabetic') {
      setDelayedScoresFilterSelection('alphabetic');
      setDelayedScoresFilter(delayedScores);
    } else if (value === 'relative') {
      const sortByTotalRel = _.orderBy(
        delayedScores,
        ['totalRel', 'fullName'],
        ['desc', 'asc']
      );

      setDelayedScoresFilterSelection(value);
      setDelayedScoresFilter(sortByTotalRel);
    } else if (value === 'unreported') {
      const unreported = _.filter(
        delayedScores,
        score => score.unreported === true
      );
      setDelayedScoresFilterSelection(value);
      setDelayedScoresFilter(unreported);
    } else {
      setDelayedScoresFilterSelection('');
      setDelayedScoresFilter(delayedScores);
    }
  };

  const handleSortWithdrawnScores = event => {
    const { value } = event.target;

    if (value === 'alphabetic') {
      setWithdrewScoresFilterSelection('alphabetic');
      setWithdrewScoresFilter(withdrewScores);
    } else if (value === 'relative') {
      const sortByTotalRel = _.orderBy(
        withdrewScores,
        ['totalRel', 'fullName'],
        ['desc', 'asc']
      );

      setWithdrewScoresFilterSelection(value);
      setWithdrewScoresFilter(sortByTotalRel);
    } else if (value === 'unreported') {
      const unreported = _.filter(
        withdrewScores,
        score => score.unreported === true
      );
      setWithdrewScoresFilterSelection(value);
      setWithdrewScoresFilter(unreported);
    } else {
      setWithdrewScoresFilterSelection('');
      setWithdrewScoresFilter(withdrewScores);
    }
  };

  return loading ? (
    <LoadingContainer>
      <CircularProgress size={100} />
    </LoadingContainer>
  ) : (
    <div>
      <StepsList
        assessmentBlocks={assessmentBlocks}
        assessmentType={assessmentType}
        assessmentUuid={assessmentUuid}
        handleScoreAddAttemptModalOpen={handleScoreAddAttemptModalOpen}
        handleScoreAddModalOpen={handleScoreAddModalOpen}
        handleScoreEditModalOpen={handleScoreEditModalOpen}
        handleSortSelection={handleSortActiveScores}
        partUuid={partUuid}
        scoresFilterSelection={activeScoresFilterSelection}
        scoreType={scoreType}
        userScores={activeScoresFilter}
      />

      <StepsList
        assessmentBlocks={assessmentBlocks}
        assessmentType={assessmentType}
        assessmentUuid={assessmentUuid}
        handleScoreAddAttemptModalOpen={handleScoreAddAttemptModalOpen}
        handleScoreAddModalOpen={handleScoreAddModalOpen}
        handleScoreEditModalOpen={handleScoreEditModalOpen}
        handleSortSelection={handleSortDelayedScores}
        partUuid={partUuid}
        scoresFilterSelection={delayedScoresFilterSelection}
        scoreType={scoreType}
        userScores={delayedScoresFilter}
        handleUploadDelayedScoresOpen={handleUploadDelayedScoresOpen}
        isDelayedPanel
      />

      <StepsList
        assessmentBlocks={assessmentBlocks}
        assessmentType={assessmentType}
        assessmentUuid={assessmentUuid}
        partUuid={partUuid}
        handleSortSelection={handleSortWithdrawnScores}
        handleScoreEditModalOpen={handleScoreEditModalOpen}
        scoresFilterSelection={withdrewScoresFilterSelection}
        scoreType={scoreType}
        userScores={withdrewScoresFilter}
        isWithdrewPanel
      />

      <ScoreAddContainer
        assessmentUuid={assessmentUuid}
        assessmentBlockUuid={addBlockUuid}
        attempt={addAttempt}
        handleScoreAddModalClose={handleScoreAddModalClose}
        openModal={openScoreAdd}
        partUuid={partUuid}
        resultCardUuid={addResultCard}
        scoreUuid={editScoreUuid}
        studentUuid={addStudent}
      />
      <ScoreAddAttemptContainer
        assessmentUuid={assessmentUuid}
        handleScoreAddAttemptModalClose={handleScoreAddAttemptModalClose}
        latestAttempt={latestAttempt}
        openModal={openScoreAddAttempt}
        partUuid={partUuid}
        studentUuid={addAttemptStudent}
      />
      <ScoreEditContainer
        assessmentUuid={assessmentUuid}
        handleScoreEditModalClose={handleScoreEditModalClose}
        openModal={openScoreEdit}
        partUuid={partUuid}
        scoreUuid={editScoreUuid}
        isOpportunity={scoreType === 'Opportunity'}
      />
    </div>
  );
}

StepListContainer.propTypes = {
  assessmentBlocks: PropTypes.array,
  assessmentType: PropTypes.string,
  assessmentUuid: PropTypes.string,
  partUuid: PropTypes.string,
  scoreType: PropTypes.string,
  sectionUuid: PropTypes.string,
  handleUploadDelayedScoresOpen: PropTypes.func
};

StepListContainer.defaultProps = {
  assessmentBlocks: [],
  assessmentType: '',
  assessmentUuid: '',
  partUuid: '',
  scoreType: '',
  sectionUuid: '',
  handleUploadDelayedScoresOpen: undefined
};
