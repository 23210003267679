import React from 'react';
import PropTypes from 'prop-types';
import { ContainerPage } from '../../../Library/Layout';
import CohortApplicantDemographicFilters from './CohortApplicantDemographicFilters';
import CohortApplicantSummaryData from '../CohortApplicantSummaryData';
import CohortApplicantReportList from '../CohortApplicantReportList';

const CohortApplicantDemographicReport = ({
  cohortApplicants,
  isLoadingDemographicReport,
  onApplyFilterSelection,
  onApplyReportType,
  onSelectReportType,
  onToggleShouldAnonymizeReport,
  reportData,
  reportHeaders,
  reportSummary,
  reportSummaryFilters,
  reportSummaryMapping,
  reportTypeOptions,
  reportTypeSelectError,
  selectedReportType,
  shouldAnonymizeReport
}) => {
  return (
    <ContainerPage paddingTop="48px">
      <CohortApplicantDemographicFilters
        reportTypeOptions={reportTypeOptions}
        onApplyReportType={onApplyReportType}
        onSelectReportType={onSelectReportType}
        reportTypeSelectError={reportTypeSelectError}
        selectedReportType={selectedReportType}
      />
      <CohortApplicantSummaryData
        reportSummary={reportSummary}
        reportSummaryMapping={reportSummaryMapping}
      />
      <CohortApplicantReportList
        cohortApplicants={cohortApplicants}
        isLoading={isLoadingDemographicReport}
        onApplyFilterSelection={onApplyFilterSelection}
        onToggleShouldAnonymizeReport={onToggleShouldAnonymizeReport}
        reportData={reportData}
        reportHeaders={reportHeaders}
        reportSummaryFilters={reportSummaryFilters}
        shouldAnonymizeReport={shouldAnonymizeReport}
      />
    </ContainerPage>
  );
};

CohortApplicantDemographicReport.propTypes = {
  cohortApplicants: PropTypes.array,
  isLoadingDemographicReport: PropTypes.bool,
  onApplyFilterSelection: PropTypes.func,
  onApplyReportType: PropTypes.func,
  onSelectReportType: PropTypes.func,
  onToggleShouldAnonymizeReport: PropTypes.func,
  reportData: PropTypes.array,
  reportHeaders: PropTypes.array,
  reportSummary: PropTypes.object,
  reportSummaryFilters: PropTypes.array,
  reportSummaryMapping: PropTypes.array,
  reportTypeOptions: PropTypes.array,
  reportTypeSelectError: PropTypes.bool,
  selectedReportType: PropTypes.string,
  shouldAnonymizeReport: PropTypes.bool
};

CohortApplicantDemographicReport.defaultProps = {
  cohortApplicants: [],
  isLoadingDemographicReport: false,
  onApplyFilterSelection: undefined,
  onApplyReportType: undefined,
  onSelectReportType: undefined,
  onToggleShouldAnonymizeReport: undefined,
  reportData: [],
  reportHeaders: [],
  reportSummary: {},
  reportSummaryFilters: [],
  reportSummaryMapping: [],
  reportTypeOptions: [],
  reportTypeSelectError: false,
  selectedReportType: '',
  shouldAnonymizeReport: false
};

export default CohortApplicantDemographicReport;
