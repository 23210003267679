import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { ButtonInline, CaptionSmall } from '@xcomp/xcomp-design-library';

import {
  validateCourseTitle,
  validateCourseHours
} from '../../../helpers/validation/validateCourseMaster';

import { validateSelectField } from '../../../helpers/validation/validateGeneric';

import {
  ContainerResize,
  ContainerPage,
  ContainerContent,
  ElevateLow
} from '../../Library/Layout';

import FormSelectError from '../../Library/FormSelectError';
import FormField from '../../Library/FormField';

import Header from '../../Library/Header';
import CourseMasterAlias from '../CourseMasterAlias/CourseMasterAlias';
import { formatOptions } from '../../../helpers/utilities';
import DescriptionEditor from '../../Library/DescriptionEditor/DescriptionEditor';

const Cancel = styled(ButtonInline)`
  && {
    margin-right: 10px;
    color: ${props => props.theme.fontColors.dark};
  }
`;

export default function CourseMasterAdd({
  schoolDepartments,
  handleChange,
  selectedDepartment,
  hours,
  courseNumber,
  title,
  alias,
  redirect,
  hasErrors,
  handleAddCourseAlias,
  handleCourseAliasChange,
  handleCourseAliasUuidChange,
  handleCreateCourse,
  handleRemoveCourseAlias
}) {
  const departmentOptions = formatOptions(schoolDepartments, 'name');

  return redirect ? (
    <Redirect to="/course-management/all" />
  ) : (
    <ContainerResize>
      <Header title="Course Management" subtitle="Create course" />
      <ContainerPage>
        <ElevateLow>
          <ContainerContent>
            <Grid container spacing={4}>
              <Grid xs={9} item>
                <FormSelectError
                  name="departmentUuid"
                  value={selectedDepartment}
                  hasErrors={hasErrors}
                  label="Department*"
                  handleChange={handleChange}
                  handleValidation={validateSelectField}
                  options={departmentOptions}
                />
              </Grid>
              <Grid item xs={3}>
                <FormField
                  id="hours"
                  name="hours"
                  label="Hours*"
                  value={hours}
                  onBlur={handleChange}
                  handleValidate={validateCourseHours}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={3}>
                <FormField
                  id="courseNumber"
                  name="courseNumber"
                  label="Course Number*"
                  value={courseNumber}
                  onBlur={handleChange}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={9}>
                <FormField
                  id="courseTitle"
                  name="title"
                  label="Course Title*"
                  value={title}
                  onBlur={handleChange}
                  margin="normal"
                  handleValidate={validateCourseTitle}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={12}>
                <DescriptionEditor courseAdd descriptionFor="Course" />
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between">
                  <CaptionSmall>COURSE ALIAS</CaptionSmall>
                </Grid>
              </Grid>
              {alias.length > 0 &&
                alias.map(courseAlias => {
                  return (
                    <CourseMasterAlias
                      key={courseAlias.indentifier}
                      alias={alias}
                      courseAlias={courseAlias}
                      hasErrors={hasErrors}
                      departmentOptions={departmentOptions}
                      handleCourseAliasChange={handleCourseAliasChange}
                      handleCourseAliasUuidChange={handleCourseAliasUuidChange}
                      handleRemoveCourseAlias={handleRemoveCourseAlias}
                    />
                  );
                })}
              <Grid item xs={12}>
                <ButtonInline onClick={handleAddCourseAlias}>
                  Add Course Alias
                </ButtonInline>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                item
                xs={12}
              >
                <Link to="/course-management/all">
                  <Cancel>CANCEL</Cancel>
                </Link>
                <ButtonInline onClick={() => handleCreateCourse()}>
                  SAVE
                </ButtonInline>
              </Grid>
            </Grid>
          </ContainerContent>
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
}

CourseMasterAdd.propTypes = {
  redirect: PropTypes.bool,
  selectedDepartment: PropTypes.string,
  courseNumber: PropTypes.string,
  title: PropTypes.string,
  hours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alias: PropTypes.array,
  handleChange: PropTypes.func,
  hasErrors: PropTypes.bool,
  schoolDepartments: PropTypes.arrayOf(PropTypes.object),
  handleCreateCourse: PropTypes.func,
  handleAddCourseAlias: PropTypes.func,
  handleCourseAliasChange: PropTypes.func,
  handleCourseAliasUuidChange: PropTypes.func,
  handleRemoveCourseAlias: PropTypes.func
};

CourseMasterAdd.defaultProps = {
  redirect: false,
  selectedDepartment: '',
  courseNumber: '',
  title: '',
  hours: 0.0,
  alias: [],
  handleChange: undefined,
  hasErrors: false,
  schoolDepartments: [],
  handleCreateCourse: undefined,
  handleAddCourseAlias: undefined,
  handleCourseAliasUuidChange: undefined,
  handleCourseAliasChange: undefined,
  handleRemoveCourseAlias: undefined
};
