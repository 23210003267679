import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectAptitudeGradeBySourceSkill } from '../../../../redux/selectors/transcriptAptitudeSelectors';

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export default function AptitudeGradeBySkill({
  programUuid,
  userUuid,
  cohortUuid,
  category,
  source,
  classificationUuid
}) {
  const sourceSkillGrade = useSelector(state =>
    selectAptitudeGradeBySourceSkill(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      classificationUuid
    )
  );

  const grade = sourceSkillGrade || 'N/A';

  return <Layout>{grade}</Layout>;
}

AptitudeGradeBySkill.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  category: PropTypes.string,
  source: PropTypes.string,
  classificationUuid: PropTypes.string
};

AptitudeGradeBySkill.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  category: undefined,
  source: undefined,
  classificationUuid: undefined
};
