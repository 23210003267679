import {
  GET_USER_ACTIVE,
  GET_USER_ACTIVE_ERROR,
  GET_USER_ACTIVE_REJECT,
  GET_USER_ACTIVE_SUCCESS
} from '../actions-type';

export const INITIAL_STATE = {
  authorized: undefined,
  loading: undefined
};

const authorizeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_ACTIVE: {
      return {
        ...state,
        loading: 'yes'
      };
    }
    case GET_USER_ACTIVE_ERROR: {
      return {
        ...state,
        loading: 'no'
      };
    }
    case GET_USER_ACTIVE_REJECT: {
      return {
        ...state,
        loading: 'no',
        authorized: 'no'
      };
    }
    case GET_USER_ACTIVE_SUCCESS: {
      return {
        ...state,
        loading: 'no',
        authorized: 'yes'
      };
    }
    default:
      return state;
  }
};

export default authorizeReducer;
