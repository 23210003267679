import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import StudentCollectionGradeData from '../GradeData/StudentCollectionGradeData';
import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../../helpers/format/user.format';

import {
  StickyInfoTd,
  StickyTitleTd,
  StudentInfoText,
  TableDataText,
  TableRow
} from '../CollectionReportSC';

const CompetencyGridRow = ({
  student,
  idx,
  shouldAnonymizeReport,
  competencyCollections,
  competencyGridMap,
  collectionStudentMap,
  studentRankMap,
  studentTotalParticipationMap,
  isExpanded,
  isDelayed,
  showScores,
  showTotals
}) => {
  const studentUuid = student.userUuid;
  const evenStudent = idx % 2 === 0;
  const studentFormattedLastName = formatLastName(
    student.user?.lastName,
    student.user?.marriedLastName,
    student.user?.suffix
  );
  const studentFormattedFirstMiddleName = formatFirstNameMiddleName(
    student.user?.firstName,
    student.user?.middleName
  );
  const studentFullName =
    student.user?.firstName && student.user?.lastName
      ? `${studentFormattedLastName}, ${studentFormattedFirstMiddleName}`
      : 'N/A';

  const studentRank =
    studentRankMap && !isDelayed
      ? studentRankMap[studentUuid]?.cohortRank
      : null;

  const studentInstitutionId = student?.user?.institutionId;

  const studentTotalParticipation =
    studentTotalParticipationMap[studentUuid]?.studentCumulativeTotal;

  const nameColumnDisplay = shouldAnonymizeReport
    ? studentInstitutionId
    : studentFullName;

  return (
    <TableRow key={student.uuid} isStudentRow>
      <StickyTitleTd
        redOne={evenStudent}
        redTwo={!evenStudent}
        left="0px"
        borderRight
        isRank
      >
        <TableDataText>{studentRank || '-'}</TableDataText>
      </StickyTitleTd>
      <StickyInfoTd greyOne={!evenStudent} left="25px" borderRightStrong>
        <Tooltip
          arrow
          placement="top"
          title={nameColumnDisplay}
          PopperProps={{ disablePortal: true }}
        >
          <StudentInfoText>{nameColumnDisplay}</StudentInfoText>
        </Tooltip>
      </StickyInfoTd>
      {showTotals && (
        <>
          <StickyTitleTd
            redOne={evenStudent}
            redTwo={!evenStudent}
            left="275px"
            borderRight
          >
            <TableDataText bold>
              {studentTotalParticipation?.slice(0, -1) || '--'}
            </TableDataText>
          </StickyTitleTd>
          {isExpanded && (
            <>
              <StickyTitleTd
                orangeOne={evenStudent}
                orangeTwo={!evenStudent}
                borderRight
                isRank
                isRelativeRank
                left="340px"
              >
                <TableDataText>{idx + 1}</TableDataText>
              </StickyTitleTd>
              <StickyTitleTd
                orangeOne={evenStudent}
                orangeTwo={!evenStudent}
                left="365px"
                borderRight
              >
                <TableDataText>
                  {student.relativeCumulative.toFixed(1)}
                </TableDataText>
              </StickyTitleTd>
            </>
          )}
        </>
      )}
      {competencyCollections.map(collection => {
        return (
          <StudentCollectionGradeData
            key={collection.uuid}
            collection={collection}
            evenStudent={evenStudent}
            competencyGridMap={competencyGridMap}
            collectionStudentMap={collectionStudentMap}
            isExpanded={isExpanded}
            studentUuid={studentUuid}
            showScores={showScores}
          />
        );
      })}
    </TableRow>
  );
};

CompetencyGridRow.propTypes = {
  competencyCollections: PropTypes.array,
  student: PropTypes.object,
  competencyGridMap: PropTypes.object,
  collectionStudentMap: PropTypes.object,
  studentRankMap: PropTypes.object,
  studentTotalParticipationMap: PropTypes.object,
  idx: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool,
  shouldAnonymizeReport: PropTypes.bool,
  isDelayed: PropTypes.bool,
  showScores: PropTypes.bool.isRequired,
  showTotals: PropTypes.bool.isRequired
};

CompetencyGridRow.defaultProps = {
  competencyCollections: [],
  isExpanded: false,
  shouldAnonymizeReport: false,
  isDelayed: false,
  competencyGridMap: {},
  collectionStudentMap: {},
  studentRankMap: {},
  studentTotalParticipationMap: {},
  student: {}
};

export default CompetencyGridRow;
