import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DeleteSectionButton from './DeleteSectionButton';

class DeleteSectionButtonContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalOpen = event => {
    if (event) event.stopPropagation();
    this.setState({
      modalOpen: true
    });
  };

  handleModalClose = event => {
    if (event) event.stopPropagation();
    this.setState({
      modalOpen: false
    });
  };

  render() {
    const { modalOpen } = this.state;
    const { sectionUuid, handleSectionDelete, className } = this.props;
    return (
      <DeleteSectionButton
        className={className}
        modalOpen={modalOpen}
        handleModalClose={event => this.handleModalClose(event)}
        handleModalOpen={event => this.handleModalOpen(event)}
        handleSectionDelete={id => handleSectionDelete(id)}
        sectionUuid={sectionUuid}
      />
    );
  }
}

DeleteSectionButtonContainer.propTypes = {
  sectionUuid: PropTypes.string,
  className: PropTypes.string,
  handleSectionDelete: PropTypes.func
};

DeleteSectionButtonContainer.defaultProps = {
  sectionUuid: '',
  className: '',
  handleSectionDelete: undefined
};

export default DeleteSectionButtonContainer;
