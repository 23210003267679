import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectSubjectAverageScore } from '../../../../redux/selectors/transcriptCognitiveSelectors';

export default function SubjectAvgScore({
  programUuid,
  userUuid,
  cohortUuid,
  classificationUuid
}) {
  const cognitiveScore = useSelector(state =>
    selectSubjectAverageScore(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      classificationUuid
    )
  );

  const score = cognitiveScore || 'N/A';

  return <>{score}</>;
}

SubjectAvgScore.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  classificationUuid: PropTypes.string
};

SubjectAvgScore.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  classificationUuid: undefined
};
