import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LockOutlined from '@material-ui/icons/LockOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { ExpandIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { Subtitle1 } from '@xcomp/xcomp-design-library';
import { CircularProgress } from '@material-ui/core';
import DragIcon from '@xcomp/xcomp-design-library/dist/icons/actions/DragIcon';
import ActionButton from './ActionButton';

const DragIconSC = styled(DragIcon)`
  && {
    transform: rotate(90deg);
  }
`;

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
  position: relative;
`;

const RowSummary = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: ${props =>
    !props.isTopLevel && props.rowOffset !== '0'
      ? `calc(100% - ${props.rowOffset})`
      : '100%'};
  height: ${props => props.height};
  font-size: 0.9375rem;
  font-weight: 300;
  color: ${props => props.theme.fontColors.dark};
  margin-left: ${props => !props.isTopLevel && props.rowOffset};
  .show_on_hover {
    display: ${props => (props.staticActionsButton ? 'flex' : 'none')};
  }

  .hover-effect {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    background: ${props =>
      props.published ? props.theme.colors.grey[100] : 'transparent'};
  }

  ${props =>
    props.isSelectable &&
    css`
      .hover-effect:hover {
        cursor: ${props => (props.disabled ? 'initial;' : 'pointer;')};
      }
    `}

  ${props =>
    props.isSelected &&
    css`
      .hover-effect {
        background: ${props => props.theme.colors.grey[100]};
      }
      border-left: 3px solid ${props => props.theme.colors.primary};
    `}

  ${props => props.isRowOpen && props.backgroundSelected && `background: ${props.backgroundSelected};`}

  &:hover {
    .hover-effect {
      background: ${props =>
        props.disabled || props.staticBg
          ? 'none'
          : props.theme.colors.grey[100]};
    }

    .show_on_hover {
      display: flex;
    }
  }

  h2 {
    font-size: inherit;
    font-weight: 400;
    color: ${props => props.theme.fontColors.darker};
    margin: 0;
    padding: 0;
  }

  svg {
    vertical-align: middle;
  }

  border-bottom: 1px solid
    ${props => (props.withBottomBorder ? props.theme.colors.grey[300] : 'none')};
`;

const RowContent = styled.div`
  flex-grow: 2;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9375rem;
  font-weight: 300;
  color: ${props => props.theme.fontColors.dark};
  padding-left: ${props => (props.leaf ? props.gutter : 'initial')};

  h2 {
    font-size: inherit;
    font-weight: 400;
    color: ${props => props.theme.fontColors.darker};
    margin: 0;
    padding: 0;
  }
`;

const ActionsColumn = styled.div`
  && {
    margin-left: auto;
    width: ${props => (props.isPublished ? '88px' : '66px')};
    height: 100%;
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }
`;

const StyledPublishedIcon = styled(LockOutlined)`
  && {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    height: 100%;
    odisplay: flex;
    align-item: center;
    font-size: 1.25rem;
  }
`;

const RowChild = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${props => props.childMarginBottom || '0.5rem'};
  margin-top: 0.5rem;
`;

const ContentColumn = styled.div`
  font-size: inherit;
  display: block;
  margin-right: ${props => props.gutter || '1rem'};

  ${props =>
    props.disabled &&
    css`
      font-style: italic;
    `}
`;

const Label = styled.div`
  color: ${props => props.theme.fontColors.dark};
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.4px;
  text-align: left;
`;

const SubtitleSC = styled(Subtitle1)`
  && {
    color: ${props =>
      props.light
        ? props.theme.colors.grey[600]
        : props.theme.colors.grey[900]};
  }
`;

const ExpandColumn = styled.div`
  && {
    min-width: ${props => props.width};
    height: 100%;
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

const DragHandleColumn = styled.div`
  && {
    width: ${props => props.width};
    height: 100%;
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

const RowButtons = styled.div`
  display: flex;
  wrap: nowrap;
  justify-content: flex-end;
  padding-right: 0.5rem;
`;

const ClosedIcon = styled(ExpandIcon)`
  transform: rotate(180deg);
`;

const OpenedIcon = styled(ExpandIcon)`
  transform: rotate(270deg);
`;

const DragHandleLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 1.25rem;
    transform: rotate(90deg);
  }
  small {
    width: 100%;
    font-size: 0.625rem;
    margin-top: 2px;
    color: ${props => props.theme.fontColors.dark};
    text-align: center;
  }
`;

const DragHandle = props => (
  <DragHandleLayout {...props}>
    <DragIconSC />
  </DragHandleLayout>
);

const getIcon = isRowOpen => {
  if (!isRowOpen) {
    return <ClosedIcon />;
  }
  return <OpenedIcon />;
};

const AccordionRow = ({
  actions,
  children,
  label,
  columnOne,
  columnTwo,
  columnThree,
  columnFour,
  expandWidth,
  rowOffset,
  className,
  columnOneClassName,
  columnTwoClassName,
  columnThreeClassName,
  columnFourClassName,
  leaf,
  disabled,
  columnGutters,
  height,
  withBottomBorder,
  isRowOpen,
  isSelected,
  isPublished,
  isTopLevel,
  isDragEnabled,
  isDragging,
  isDraggingOver,
  dragHandleProps,
  toggleOpenRow,
  onSelect,
  panelRef,
  staticBg,
  staticActionsButton,
  withLabelTooltip,
  childMarginBottom,
  backgroundSelected,
  loading,
}) => {
  const getOnClick = (disabled, onSelect, toggleOpenRow) => {
    if (disabled) {
      return undefined;
    } else if (onSelect) {
      return onSelect;
    }
    return toggleOpenRow;
  };
  return (
    <Layout className={className}>
      <RowSummary
        onClick={getOnClick(disabled, onSelect, toggleOpenRow)}
        published={isPublished}
        height={height}
        rowOffset={rowOffset}
        disabled={disabled}
        withBottomBorder={withBottomBorder}
        isSelected={isSelected}
        isRowOpen={isRowOpen}
        isSelectable={Boolean(onSelect)}
        isTopLevel={isTopLevel}
        isDragging={isDragging}
        isDraggingOver={isDraggingOver}
        staticBg={staticBg}
        staticActionsButton={staticActionsButton}
        backgroundSelected={backgroundSelected}
      >
        <div className="hover-effect" ref={panelRef}>
          {isDragEnabled && (
            <DragHandleColumn width="30px">
              <DragHandle {...dragHandleProps} />
            </DragHandleColumn>
          )}
          {!leaf && (
            <ExpandColumn width={expandWidth || '66px'}>
              {getIcon(isRowOpen)}
            </ExpandColumn>
          )}
          <RowContent leaf={leaf} gutter={columnGutters}>
            {label && (
              <ContentColumn
                gutter={columnGutters}
                className="accordion_row_label label"
              >
                {withLabelTooltip ? (
                  <Tooltip title={label} placement="top-start">
                    <Label>{label}</Label>
                  </Tooltip>
                ) : (
                  <Label>{label}</Label>
                )}
              </ContentColumn>
            )}
            {columnOne && (
              <ContentColumn
                disabled={disabled}
                gutter={columnGutters}
                className={columnOneClassName}
              >
                <SubtitleSC light={!isTopLevel || disabled}>
                  {columnOne}
                </SubtitleSC>
              </ContentColumn>
            )}
            {columnTwo && (
              <ContentColumn
                disabled={disabled}
                gutter={columnGutters}
                className={columnTwoClassName}
              >
                <SubtitleSC light={disabled}>{columnTwo}</SubtitleSC>
              </ContentColumn>
            )}
            {columnThree && (
              <ContentColumn
                disabled={disabled}
                gutter={columnGutters}
                className={columnThreeClassName}
              >
                <SubtitleSC light={disabled}>{columnThree}</SubtitleSC>
              </ContentColumn>
            )}
            {columnFour && (
              <ContentColumn
                disabled={disabled}
                gutter={columnGutters}
                className={columnFourClassName}
              >
                <SubtitleSC light={disabled}>{columnFour}</SubtitleSC>
              </ContentColumn>
            )}
          </RowContent>
          {actions === 'placeholder' 
            ?
              <div style={{width: 66}} />
            : (actions || isPublished) && (
              <ActionsColumn isPublished={isPublished}>
                {loading
                  ?
                    <CircularProgress color="primary" size={20} />
                  :
                    <RowButtons>
                      {actions && (
                        <ActionButton
                          className="show_on_hover"
                          actions={actions}
                          isRowOpen={isRowOpen}
                          toggleOpenRow={toggleOpenRow}
                        />
                      )}
                      {isPublished && <StyledPublishedIcon />}
                    </RowButtons>
                }
              </ActionsColumn>
            )
          }
        </div>
      </RowSummary>
      {isRowOpen && !leaf && (
        <RowChild childMarginBottom={childMarginBottom}>{children}</RowChild>
      )}
    </Layout>
  );
};

AccordionRow.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool
  ]),
  children: PropTypes.node,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object
  ]),
  columnOne: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object
  ]),
  columnTwo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object
  ]),
  columnThree: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object
  ]),
  columnFour: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object
  ]),
  labelClassName: PropTypes.string,
  columnOneClassName: PropTypes.string,
  columnTwoClassName: PropTypes.string,
  columnThreeClassName: PropTypes.string,
  columnFourClassName: PropTypes.string,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  expandWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  columnGutters: PropTypes.string,
  height: PropTypes.string,
  rowOffset: PropTypes.string,
  leaf: PropTypes.bool,
  isRowOpen: PropTypes.bool,
  isPublished: PropTypes.bool,
  isTopLevel: PropTypes.bool,
  isDragEnabled: PropTypes.bool,
  isDragging: PropTypes.bool,
  isDraggingOver: PropTypes.bool,
  panelRef: PropTypes.shape({ current: PropTypes.any }),
  staticBg: PropTypes.bool,
  staticActionsButton: PropTypes.bool,
  withLabelTooltip: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  toggleOpenRow: PropTypes.func,
  childMarginBottom: PropTypes.string,
  withBottomBorder: PropTypes.bool,
  backgroundSelected: PropTypes.string,
  loading: PropTypes.bool,
};

AccordionRow.defaultProps = {
  actions: false,
  children: <div />,
  label: '',
  columnOne: '',
  columnTwo: '',
  columnThree: '',
  columnFour: '',
  labelClassName: '',
  columnOneClassName: '',
  columnTwoClassName: '',
  columnThreeClassName: '',
  columnFourClassName: '',
  disabled: false,
  isSelected: false,
  onSelect: undefined,
  className: '',
  backgroundSelected: '',
  expandWidth: false,
  columnGutters: '0.5rem',
  height: '43px',
  rowOffset: '0',
  leaf: false,
  isRowOpen: false,
  isPublished: false,
  isTopLevel: false,
  isDragEnabled: false,
  isDragging: false,
  isDraggingOver: false,
  panelRef: undefined,
  staticBg: false,
  staticActionsButton: false,
  withLabelTooltip: false,
  dragHandleProps: {},
  toggleOpenRow: undefined,
  childMarginBottom: undefined,
  withBottomBorder: false,
  loading: false,
};

export default AccordionRow;
export { getIcon, RowChild, RowSummary, StyledPublishedIcon };
