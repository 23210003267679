import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import {
  doGetProgram,
  doPutProgram,
  doDeleteProgram
} from '../../../redux/actions/programActions';

import {
  doGetDegreeLevels,
  doGetProgramCips,
  doGetProgramDisciplines,
  doGetProgramCodes,
  doGetAccreditationAgencies
} from '../../../redux/actions/sourceActions';

import { degreeLevelsSelector } from '../../../redux/selectors/degreeLevelsSelectors';
import {
  institutionAAsSelector,
  programAAsSelector
} from '../../../redux/selectors/accreditationAgenciesSelectors';
import { programSelector } from '../../../redux/selectors/programSelectors';

import {
  validateInputString,
  validateSelectField
} from '../../../helpers/validation/validateGeneric';

import ProgramEdit from './ProgramEdit';
import DeleteModalRedirect from '../../Library/Modal/DeleteModal/DeleteModalRedirect';

class ProgramEditContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      programName: '',
      programAbbreviation: '',
      degreeAbbreviation: '',
      degreeName: '',
      programCip: '',
      programDiscipline: '',
      programCode: '',
      degreeDesignation: '',
      degreeDescription: '',
      institutionAA: '',
      programAA: '',
      redirect: false,
      hasErrors: false,
      modalOpen: false
    };

    this._clearProgramDisciplineAndCode = this._clearProgramDisciplineAndCode.bind(
      this
    );
    this._clearProgramCode = this._clearProgramCode.bind(this);
    this._validate = this._validate.bind(this);
    this._setFormState = this._setFormState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateProgram = this.handleUpdateProgram.bind(this);
    this.handleDeleteModalOpen = this.handleDeleteModalOpen.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    const {
      match,
      program,
      onGetProgram,
      onGetDegreeLevels,
      onGetAccreditationAgencies,
      onGetProgramCips,
      onGetProgramDisciplines,
      onGetProgramCodes
    } = this.props;
    const { programUuid } = match.params;

    onGetDegreeLevels();
    onGetAccreditationAgencies();
    onGetProgram(programUuid);
    onGetProgramCips();

    if (program && !_.isEmpty(program)) {
      if (program.programCip !== '') {
        onGetProgramDisciplines(program.programCip);
      }

      if (program.programDiscipline !== '') {
        onGetProgramCodes(program.programDiscipline);
      }

      this._setFormState();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { onGetProgramDisciplines, onGetProgramCodes, program } = this.props;
    const { programCip, programDiscipline, programCode } = this.state;

    if (prevProps.program !== program) {
      this._setFormState();
    }

    if (prevState.programCip !== programCip && programCip !== '') {
      onGetProgramDisciplines(programCip);
    }

    if (
      prevState.programCip !== programCip &&
      prevState.programDiscipline === programDiscipline &&
      prevState.programCode === programCode
    ) {
      this._clearProgramDisciplineAndCode();
    }

    if (
      prevState.programDiscipline !== programDiscipline &&
      prevState.programCip === programCip &&
      prevState.programCode === programCode
    ) {
      this._clearProgramCode();
    }

    if (
      prevState.programDiscipline !== programDiscipline &&
      programDiscipline !== ''
    ) {
      onGetProgramCodes(programDiscipline);
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleUpdateProgram() {
    const containsErrors = this._validate();

    if (containsErrors) {
      this.setState({
        hasErrors: true
      });
    } else {
      const { schoolUuid } = this.props;
      const {
        uuid,
        programName,
        programAbbreviation,
        degreeAbbreviation,
        programCip,
        programDiscipline,
        programCode,
        degreeName,
        degreeDesignation,
        institutionAA,
        programAA
      } = this.state;

      const payload = {
        uuid,
        programName,
        programAbbreviation,
        degreeAbbreviation,
        programCip,
        programDiscipline,
        programCode,
        degreeName,
        degreeDesignation,
        institutionAA,
        programAA,
        schoolUuid
      };

      this.props.onPutProgram(payload);

      this.setState({
        redirect: true
      });
    }
  }

  handleDeleteModalOpen() {
    this.setState({
      modalOpen: true
    });
  }

  handleDeleteModalClose() {
    this.setState({
      modalOpen: false
    });
  }

  handleDelete() {
    this.props.onDeleteProgram(this.state.uuid);

    this.setState({
      modalOpen: false
    });
  }

  _clearProgramDisciplineAndCode() {
    this.setState(state => {
      return {
        ...state,
        programDiscipline: '',
        programCode: ''
      };
    });
  }

  _clearProgramCode() {
    this.setState(state => {
      return {
        ...state,
        programCode: ''
      };
    });
  }

  _setFormState() {
    this.setState((state, props) => {
      const { program } = props;

      return {
        ...state,
        ...program
      };
    });
  }

  _validate() {
    let validationErrors = false;

    const {
      programName,
      programAbbreviation,
      degreeAbbreviation,
      degreeName,
      programCip,
      programDiscipline,
      programCode,
      degreeDesignation,
      institutionAA,
      programAA
    } = this.state;

    const programNameError = validateInputString(programName);

    const programCipError = validateSelectField(programCip);
    const programDisciplineError = validateSelectField(programDiscipline);
    const programCodeError = validateSelectField(programCode);

    const programAbbreviationError = validateInputString(programAbbreviation);
    const degreeAbbreviationError = validateInputString(degreeAbbreviation);
    const degreeNameError = validateInputString(degreeName);

    const degreeDesignationError = validateSelectField(degreeDesignation);

    const institutionAAError = validateSelectField(institutionAA);
    const programAAError = validateSelectField(programAA);

    if (programNameError.invalid) {
      validationErrors = true;
    }

    if (programCipError.invalid) {
      validationErrors = true;
    }
    if (programDisciplineError.invalid) {
      validationErrors = true;
    }
    if (programCodeError.invalid) {
      validationErrors = true;
    }

    if (programAbbreviationError.invalid) {
      validationErrors = true;
    }
    if (degreeAbbreviationError.invalid) {
      validationErrors = true;
    }
    if (degreeNameError.invalid) {
      validationErrors = true;
    }

    if (degreeDesignationError.invalid) {
      validationErrors = true;
    }

    if (institutionAAError.invalid) {
      validationErrors = true;
    }
    if (programAAError.invalid) {
      validationErrors = true;
    }

    return validationErrors;
  }

  render() {
    const { degreeLevels, institutionAAs, programAAs } = this.props;

    const {
      programName,
      programAbbreviation,
      degreeAbbreviation,
      degreeName,
      programCip,
      programDiscipline,
      programCode,
      degreeDesignation,
      degreeDescription,
      institutionAA,
      programAA,
      hasErrors,
      redirect,
      modalOpen
    } = this.state;

    return redirect ? (
      <Redirect to="/school-management/programs" />
    ) : (
      <div>
        <ProgramEdit
          programName={programName}
          programAbbreviation={programAbbreviation}
          degreeAbbreviation={degreeAbbreviation}
          degreeName={degreeName}
          programCip={programCip}
          programDiscipline={programDiscipline}
          programCode={programCode}
          degreeDesignation={degreeDesignation}
          degreeDescription={degreeDescription}
          institutionAA={institutionAA}
          programAA={programAA}
          degreeDesignations={degreeLevels}
          institutionAAs={institutionAAs}
          programAAs={programAAs}
          hasErrors={hasErrors}
          handleChange={this.handleChange}
          handleUpdateProgram={this.handleUpdateProgram}
          handleDeleteModalOpen={this.handleDeleteModalOpen}
        />
        <DeleteModalRedirect
          modalOpen={modalOpen}
          type="program"
          link="/school-management/programs"
          handleModalClose={() => this.handleDeleteModalClose()}
          handleDelete={() => this.handleDelete()}
        />
      </div>
    );
  }
}

ProgramEditContainer.propTypes = {
  match: PropTypes.object,
  program: PropTypes.object,
  onPutProgram: PropTypes.func,
  onGetProgram: PropTypes.func,
  schoolUuid: PropTypes.string,
  degreeLevels: PropTypes.array,
  institutionAAs: PropTypes.array,
  programAAs: PropTypes.array,
  onDeleteProgram: PropTypes.func,
  onGetDegreeLevels: PropTypes.func,
  onGetAccreditationAgencies: PropTypes.func,
  onGetProgramCips: PropTypes.func,
  onGetProgramDisciplines: PropTypes.func,
  onGetProgramCodes: PropTypes.func
};

ProgramEditContainer.defaultProps = {
  match: {},
  program: {},
  schoolUuid: undefined,
  degreeLevels: [],
  institutionAAs: [],
  programAAs: [],
  onPutProgram: undefined,
  onGetProgram: undefined,
  onGetDegreeLevels: undefined,
  onGetAccreditationAgencies: undefined,
  onDeleteProgram: undefined,
  onGetProgramCips: undefined,
  onGetProgramDisciplines: undefined,
  onGetProgramCodes: undefined
};

const mapStateToProps = (state, props) => {
  const { userState } = state;
  return {
    schoolUuid: userState.selectedSchoolUuid,
    program: programSelector(state, props.match.params.programUuid),
    degreeLevels: degreeLevelsSelector(state),
    institutionAAs: institutionAAsSelector(state),
    programAAs: programAAsSelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onPutProgram: payload => dispatch(doPutProgram(payload)),
  onGetProgram: programUuid => dispatch(doGetProgram(programUuid)),
  onDeleteProgram: programUuid => dispatch(doDeleteProgram(programUuid)),
  onGetDegreeLevels: () => dispatch(doGetDegreeLevels()),
  onGetProgramCips: () => dispatch(doGetProgramCips()),
  onGetProgramDisciplines: cipUuid =>
    dispatch(doGetProgramDisciplines(cipUuid)),
  onGetProgramCodes: disciplineUuid =>
    dispatch(doGetProgramCodes(disciplineUuid)),
  onGetAccreditationAgencies: () => dispatch(doGetAccreditationAgencies())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramEditContainer);
