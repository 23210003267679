import { Model, attr, fk } from 'redux-orm';

import {
  GET_TRANSCRIPT_SUCCESS,
  POST_TRANSCRIPT_SUCCESS,
  DELETE_TRANSCRIPT_SUCCESS,
  DELETE_TRANSCRIPTS_SUCCESS,
  PUT_TRANSCRIPT_SUCCESS,
  POST_CLONE_TRANSCRIPT_SUCCESS
} from '../actions-type';

class TranscriptPhysicalSkills extends Model {
  static reducer(action, TranscriptPhysicalSkills, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_TRANSCRIPT_SUCCESS: {
        const transcriptPhysicalSkills = [...payload.transcriptPhysicalSkills];
        transcriptPhysicalSkills.forEach(transcript =>
          TranscriptPhysicalSkills.upsert(transcript)
        );
        break;
      }

      case POST_CLONE_TRANSCRIPT_SUCCESS: {
        const transcriptPhysicalSkills = [...payload.transcriptPhysicalSkills];
        transcriptPhysicalSkills.forEach(transcript =>
          TranscriptPhysicalSkills.upsert(transcript)
        );
        break;
      }

      case POST_TRANSCRIPT_SUCCESS: {
        const { category, transcript } = { ...payload };

        if (category === 'Physical-Skills') {
          TranscriptPhysicalSkills.upsert(transcript);
        }
        break;
      }

      case PUT_TRANSCRIPT_SUCCESS: {
        const { category, transcript } = { ...payload };

        if (category === 'Physical-Skills') {
          TranscriptPhysicalSkills.upsert(transcript);
        }
        break;
      }

      case DELETE_TRANSCRIPT_SUCCESS: {
        const { category, uuid } = payload;

        if (category === 'Physical-Skills') {
          TranscriptPhysicalSkills.withId(uuid).delete();
        }

        break;
      }

      case DELETE_TRANSCRIPTS_SUCCESS: {
        const { category, transcripts } = payload;

        if (category === 'Physical-Skills') {
          transcripts.forEach(transcript =>
            TranscriptPhysicalSkills.withId(transcript).delete()
          );
        }

        break;
      }

      default:
        return session;
    }
  }
}

TranscriptPhysicalSkills.fields = {
  date: attr(),
  itemAge: attr(),
  uuid: attr(),
  category: attr(),
  source: attr(),
  applicationDate: attr(),
  termType: attr(),
  term: attr(),
  year: attr(),
  attempt: attr(),
  level: attr(),
  institutionCode: attr(),
  sourceInstitutionUuid: attr(),
  sourceDegreeLevelUuid: attr(),
  grade: attr(),
  score: attr(),
  classificationUuid: fk('Classification', 'transcript_physical_skills'),
  programUuid: fk('Program', 'transcript_physical_skills'),
  cohortUuid: fk('Cohort', 'transcript_physical_skills'),
  userUuid: fk('User', 'transcript_physical_skills'),
  examAptitudeUuid: fk('ExamAptitude', 'transcript_physical_skills'),
  conversionAptitudeUuid: fk(
    'ConversionAptitude',
    'transcript_physical_skills'
  ),
  letterGradeUuid: fk('LetterGrade', 'transcript_physical_skills'),
  createdAt: attr(),
  updatedAt: attr()
};

TranscriptPhysicalSkills.modelName = 'TranscriptPhysicalSkills';

TranscriptPhysicalSkills.options = {
  idAttribute: 'uuid'
};

export default TranscriptPhysicalSkills;
