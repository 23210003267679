import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { ButtonInline, ButtonDelete } from '@xcomp/xcomp-design-library';
import { ContainerResize, ContainerPage } from '../../../../Library/Layout';
import FormField from '../../../../Library/FormField';
import Header from '../../../../Library/Header';
import { validateEducationLevelTextField } from '../../../../../helpers/validation/validateEducationLevelLoverb';

import LoverbEdit from './LoverbEdit';

const Container = styled.div`
  padding: 2rem;
  border-radius: 2px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.25px;
`;

const Divider = styled.div`
  font-size: 0.7rem;
`;

const Line = styled.hr`
  width: 98%;
  margin-left: 2%;
  margin-right: -32px;
`;

const Break = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LinkSC = styled(Link)`
  padding-right: 10px;
`;

const DeleteRow = styled.div`
  margin-top: 2rem;
  margin-bottom: 6rem;
`;

const Cancel = styled(ButtonInline)`
  && {
    margin-right: 10px;
    color: ${props => props.theme.fontColors.dark};
  }
`;

export default function EducationLevelEdit({
  level,
  education,
  loverbs,
  loverbsRedux,
  hasErrors,
  submit,
  handleAddLoverb,
  handleFormInputChange,
  handleLoverbChange,
  handleRemoveLoverb,
  handleSubmit,
  handleDeleteModalOpen
}) {
  const noErrors = !hasErrors && submit;

  return (
    <ContainerResize>
      {noErrors && (
        <Redirect to="/school-management/definitions/educationlevels" />
      )}
      <Header title="Education Level" subtitle="Edit" />
      <ContainerPage>
        <Container>
          <Title>Details</Title>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormField
                name="level"
                label="Level Number*"
                value={level}
                onBlur={handleFormInputChange}
                type="number"
                handleValidate={validateEducationLevelTextField}
                hasErrors={hasErrors}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                name="education"
                label="Education Level*"
                value={education}
                onBlur={handleFormInputChange}
                handleValidate={validateEducationLevelTextField}
                hasErrors={hasErrors}
              />
            </Grid>

            <Grid item xs={12}>
              <Break>
                <Divider>LOVERBS</Divider>
                <Line />
              </Break>
            </Grid>
            {loverbs
              .sort((a, b) => a.level - b.level)
              .map(loverb => {
                return (
                  <LoverbEdit
                    key={loverb.indentifier}
                    indentifier={loverb.indentifier}
                    loverbUuid={loverb.uuid}
                    objectiveVerb={loverb.objectiveVerb}
                    loverbs={loverbs}
                    loverbsRedux={loverbsRedux}
                    hasErrors={hasErrors}
                    handleLoverbChange={handleLoverbChange}
                    handleRemoveLoverb={handleRemoveLoverb}
                  />
                );
              })}
            <Grid item xs={12}>
              <ButtonInline onClick={handleAddLoverb}>Add Loverb</ButtonInline>
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={3}>
                  <ButtonArea>
                    <LinkSC to="/school-management/definitions/educationlevels">
                      <Cancel>CANCEL</Cancel>
                    </LinkSC>
                    <ButtonInline
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      SUBMIT
                    </ButtonInline>
                  </ButtonArea>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <DeleteRow>
          <ButtonDelete onClick={() => handleDeleteModalOpen()}>
            Delete Education Level
          </ButtonDelete>
        </DeleteRow>
      </ContainerPage>
    </ContainerResize>
  );
}

EducationLevelEdit.propTypes = {
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  education: PropTypes.string,
  loverbs: PropTypes.array,
  loverbsRedux: PropTypes.array,
  hasErrors: PropTypes.bool,
  submit: PropTypes.bool,
  handleAddLoverb: PropTypes.func,
  handleLoverbChange: PropTypes.func,
  handleRemoveLoverb: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func,
  handleFormInputChange: PropTypes.func
};
EducationLevelEdit.defaultProps = {
  level: 0,
  education: '',
  loverbs: [],
  loverbsRedux: [],
  submit: false,
  hasErrors: false,
  handleAddLoverb: undefined,
  handleLoverbChange: undefined,
  handleRemoveLoverb: undefined,
  handleSubmit: undefined,
  handleDeleteModalOpen: undefined,
  handleFormInputChange: undefined
};
