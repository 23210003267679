import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CourseAgeMod from './CourseAgeMod';
import { courseAgeModReducer, courseAgeModInitial } from './courseAgeModState';
import {
  doPostCourseAgeMod,
  doGetCourseAgeMod
} from '../../../../redux/actions/courseAgeModActions';

import { selectCourseMods } from '../../../../redux/selectors/courseAgeModSelectors';

export default function CourseAgeModContainer() {
  const dispatch = useDispatch();
  const [courseAgeModState, courseAgeModDispatch] = useReducer(
    courseAgeModReducer,
    courseAgeModInitial
  );

  const programUuid = useSelector(state => state.userState.selectedProgramUuid);

  const courseAgeMods = useSelector(state =>
    selectCourseMods(state, programUuid)
  );

  useEffect(() => {
    if (courseAgeMods) {
      courseAgeModDispatch({ type: 'load', payload: { courseAgeMods } });
    }
  }, [courseAgeModDispatch, courseAgeMods]);

  useEffect(() => {
    if (programUuid) {
      dispatch(doGetCourseAgeMod(programUuid));
    }
  }, [dispatch, programUuid]);

  const handleChange = event => {
    const { name, value } = event.target;

    courseAgeModDispatch({ type: 'modify', payload: { name, value } });
  };

  const handleSave = () => {
    const addProgram = courseAgeModState.map(courseAge => ({
      ...courseAge,
      programUuid
    }));

    dispatch(doPostCourseAgeMod(addProgram));
  };

  return (
    <CourseAgeMod
      courseAgeModState={courseAgeModState}
      handleChange={handleChange}
      handleSave={handleSave}
    />
  );
}

CourseAgeModContainer.propTypes = {};

CourseAgeModContainer.defaultProps = {};
