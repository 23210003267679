import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';
import Overline from '../../Library/Overline';
import { MonthHeading } from '../../Library/SyllabusComponents';
import SyllabusItem from './SyllabusItem';

const Layout = styled.div`
  height: 100%;
`;

const SyllabusItemList = ({ syllabusItems, itemId, changeItemId }) => {
  let encounterIndex = 0;
  let assessmentIndex = 0;
  let currentMonth = null;

  return (
    <Layout>
      {syllabusItems.map(item => {
        let itemNumber;
        let itemTypeName;
        let showNewMonth = false;

        const itemStartDate = item.linkedAssessmentUuid
          ? item.linked_assessment.startDate
          : item.startDate;

        const startDateMoment = moment(itemStartDate);

        if (currentMonth !== startDateMoment.format('MMMM')) {
          showNewMonth = true;
          currentMonth = startDateMoment.format('MMMM');
        }

        if (item.type === 'encounter') {
          itemNumber = encounterIndex;
          encounterIndex += 1;
          itemTypeName = _.get(item, 'encounter_type.name', '');
        } else if (item.type === 'assessment') {
          itemNumber = assessmentIndex;
          assessmentIndex += 1;
          itemTypeName = _.get(item, 'assessment_type.name', '');
        }

        return (
          <div key={item.uuid}>
            {showNewMonth && (
              <MonthHeading>
                {currentMonth !== 'Invalid date' ? (
                  <Overline>
                    {currentMonth} {startDateMoment.format('YYYY')}
                  </Overline>
                ) : (
                  <Overline>Missing Dates</Overline>
                )}
              </MonthHeading>
            )}
            <SyllabusItem
              item={item}
              itemId={itemId}
              changeItemId={changeItemId}
              itemNumber={itemNumber}
              itemTypeName={itemTypeName}
            />
          </div>
        );
      })}
    </Layout>
  );
};

SyllabusItemList.propTypes = {
  syllabusItems: PropTypes.arrayOf(PropTypes.object),
  itemId: PropTypes.string,
  changeItemId: PropTypes.func
};

SyllabusItemList.defaultProps = {
  syllabusItems: [],
  itemId: '',
  changeItemId: undefined
};

export default SyllabusItemList;
