import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2 } from '@xcomp/xcomp-design-library';

import {
  ContainerPage,
  Panel,
  PanelHeader,
  ContainerResize
} from '../../../Library/Layout';
import Header from '../../../Library/Header';
import CourseCollectionForm from '../CourseCollectionForm';

const HeaderSpacer = styled.div`
  width: 100%;
  height: 36px;
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const NewCourseCollectionDashboard = ({
  parentCohort,
  newCourseCollection,
  handleNameChange,
  handleCreditsRequiredChange,
  handleAllCreditsRequiredChange,
  handleSpecialCollectionChange,
  onSubmit,
  clearForm,
  formDispatch,
  showValidationErrors
}) => (
  <ContainerResize>
    <Header
      title="Add Collection"
      subtitle={`${parentCohort ? parentCohort.name : ''}`}
    />
    <ContainerPage>
      <HeaderSpacer />
      <Panel>
        <PanelHeader paddingLeft="24px">
          <Heading2>Course Collection</Heading2>
        </PanelHeader>
        <PanelPadding>
          <CourseCollectionForm
            courseCollection={newCourseCollection}
            buttonText="Create"
            handleNameChange={handleNameChange}
            handleCreditsRequiredChange={handleCreditsRequiredChange}
            handleAllCreditsRequiredChange={handleAllCreditsRequiredChange}
            handleSpecialCollectionChange={handleSpecialCollectionChange}
            formDispatch={formDispatch}
            onSubmit={onSubmit}
            onFormClose={clearForm}
            showValidationErrors={showValidationErrors}
          />
        </PanelPadding>
      </Panel>
    </ContainerPage>
  </ContainerResize>
);

NewCourseCollectionDashboard.propTypes = {
  parentCohort: PropTypes.object,
  newCourseCollection: PropTypes.object.isRequired,
  handleNameChange: PropTypes.func,
  handleCreditsRequiredChange: PropTypes.func,
  handleAllCreditsRequiredChange: PropTypes.func,
  handleSpecialCollectionChange: PropTypes.func,
  onSubmit: PropTypes.func,
  formDispatch: PropTypes.func,
  clearForm: PropTypes.func,
  showValidationErrors: PropTypes.bool
};

NewCourseCollectionDashboard.defaultProps = {
  parentCohort: { name: '' },
  handleNameChange: undefined,
  handleCreditsRequiredChange: undefined,
  handleAllCreditsRequiredChange: undefined,
  handleSpecialCollectionChange: undefined,
  onSubmit: undefined,
  formDispatch: undefined,
  clearForm: undefined,
  showValidationErrors: false
};

export default NewCourseCollectionDashboard;
