import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DeletePartButton from './DeletePartButton';

class DeletePartButtonContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalOpen = () => {
    this.setState({
      modalOpen: true
    });
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    });
  };

  render() {
    const { modalOpen } = this.state;
    const { handlePartDelete, className } = this.props;
    return (
      <DeletePartButton
        className={className}
        modalOpen={modalOpen}
        handleModalClose={() => this.handleModalClose()}
        handleModalOpen={() => this.handleModalOpen()}
        handlePartDelete={() => handlePartDelete()}
      />
    );
  }
}

DeletePartButtonContainer.propTypes = {
  className: PropTypes.string,
  handlePartDelete: PropTypes.func
};

DeletePartButtonContainer.defaultProps = {
  className: '',
  handlePartDelete: undefined
};

export default DeletePartButtonContainer;
