import { Model, attr } from 'redux-orm';
import {
  DELETE_SCHOOL_SUCCESS,
  GET_COHORT_SUCCESS,
  GET_COURSE_BY_STUDENT_SUCCESS,
  GET_HIERARCHY_SUCCESS,
  GET_SCHOOLS_SUCCESS,
  GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS,
  GET_SCHOOL_SUCCESS,
  GET_USERS_BY_SEARCH_ROLES_SUCCESS,
  GET_USER_ACTIVE_SUCCESS,
  GET_USER_SUCCESS,
  PATCH_SCHOOL_DENOMINATORS_SUCCESS,
  PATCH_SCHOOL_REVUS_SUCCESS,
  POST_SCHOOL_SUCCESS,
  PUT_SCHOOL_SUCCESS
} from '../actions-type';

class School extends Model {
  static reducer(action, School, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_USER_ACTIVE_SUCCESS: {
        if (payload?.roles?.school_admin?.length > 0) {
          const userSchools = [...payload.roles.school_admin];
          userSchools.forEach(userSchool => {
            const { school } = userSchool;
            School.upsert(school);
          });
        }

        if (payload?.roles?.department_staff?.length > 0) {
          const userDepartments = [...payload.roles.department_staff];
          userDepartments.forEach(userDepartment => {
            const { school } = userDepartment.department;
            School.upsert(school);
          });
        }

        if (payload?.roles?.department_faculty?.length > 0) {
          const userDepartments = [...payload.roles.department_faculty];
          userDepartments.forEach(userDepartment => {
            const { school } = userDepartment.department;
            School.upsert(school);
          });
        }

        if (payload?.roles?.program_admin?.length > 0) {
          const userPrograms = [...payload.roles.program_admin];
          userPrograms.forEach(userProgram => {
            const { school } = userProgram.program;
            School.upsert(school);
          });
        }

        if (payload?.roles?.program_staff?.length > 0) {
          const userPrograms = [...payload.roles.program_staff];
          userPrograms.forEach(userProgram => {
            const { school } = userProgram.program;
            School.upsert(school);
          });
        }

        if (payload?.roles?.program_faculty?.length > 0) {
          const userPrograms = [...payload.roles.program_faculty];
          userPrograms.forEach(userProgram => {
            const { school } = userProgram.program;
            School.upsert(school);
          });
        }

        break;
      }
      case GET_USER_SUCCESS: {
        const schools = [...payload.schools];
        schools.forEach(school => School.upsert(school));
        break;
      }
      case GET_SCHOOLS_SUCCESS: {
        const schools = [...payload.schools];
        schools.forEach(school => School.upsert(school));
        break;
      }
      case GET_SCHOOL_SUCCESS: {
        const school = { ...payload.school };
        School.upsert(school);
        break;
      }
      case POST_SCHOOL_SUCCESS: {
        const { school } = payload;
        School.upsert(school);
        break;
      }
      case PUT_SCHOOL_SUCCESS: {
        const { school } = payload;
        School.upsert(school);
        break;
      }
      case DELETE_SCHOOL_SUCCESS: {
        const school = { ...payload };
        School.withId(school.schoolUuid).delete();
        break;
      }
      case PATCH_SCHOOL_REVUS_SUCCESS: {
        const school = { ...payload };
        School.upsert(school);
        break;
      }
      case PATCH_SCHOOL_DENOMINATORS_SUCCESS: {
        const { school } = payload;
        School.upsert(school);
        break;
      }
      case GET_COHORT_SUCCESS: {
        const school = { ...payload.cohort.program.school };
        School.upsert(school);
        break;
      }
      case GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS: {
        const school = { ...payload.school };
        delete school.programs;
        School.upsert(school);
        break;
      }
      case GET_HIERARCHY_SUCCESS: {
        const schools = [...payload.schools];
        schools.forEach(school => School.upsert(school));
        break;
      }
      case GET_USERS_BY_SEARCH_ROLES_SUCCESS: {
        if (payload?.schools?.length > 0) {
          const schools = [...payload.schools];
          schools.forEach(school => School.upsert(school));
        }
        break;
      }
      case GET_COURSE_BY_STUDENT_SUCCESS: {
        const courses = [...payload.courses];
        courses.forEach(course => {
          const { course_master } = course;
          const { department } = course_master;
          const { school } = department;

          School.upsert(school);
        });
        break;
      }
      default:
        return session;
    }
  }
}

School.fields = {
  uuid: attr(),
  schoolName: attr(),
  abbreviation: attr(),
  primaryColor: attr(),
  secondaryColor: attr(),
  linkedPracExpDenominator: attr(),
  linkedSeminarDenominator: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

School.modelName = 'School';

School.options = {
  idAttribute: 'uuid'
};

export default School;
