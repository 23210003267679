import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import {
  DeleteIcon,
  AddUserIcon,
  MassAssignIcon
} from '@xcomp/xcomp-design-library/dist/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Header from '../../Library/Header';
import StatusFilter from './StatusFilter';
import { ContainerResize, ContainerPage } from '../../Library/Layout';
import { userSectionStatusMap } from '../../../helpers/constants';
import StudentRosterStatusMenu from './StudentRosterStatusMenu';
import { formatLastName } from '../../../helpers/format/user.format';

const Actions = styled.div`
  background: ${props => props.theme.colors.white};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 87px;
`;

const Section = styled.div`
  margin-top: 20px;
  background: ${props => props.theme.colors.white};
  padding: 20px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
`;

const SectionBox = styled.div`
  display: flex;
  align-items: center;
`;

const SectionTitle = styled.div`
  margin-right: 20px;
`;

const Table = styled.table`
  background: ${props => props.theme.colors.white};
  border-collapse: collapse;
  width: 100%;
`;

const TableHead = styled.thead`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: ${props => props.theme.colors.grey[600]};
`;

const TableRow = styled.tr``;

const TableBody = styled.tbody`
  color: ${props => props.theme.colors.grey[900]};
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

const InstitutionId = styled.td`
  width: 20%;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;
const Name = styled.td`
  width: 20%;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Status = styled.td`
  width: 40%;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const StatusBox = styled.div`
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const SelectAll = styled.td`
  width: 20%;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const SelectAllBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SelectLabel = styled.div`
  padding-right: 10px;
`;

const IconSpacing = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;

const StudentRoster = ({
  students,
  selectAll,
  filterStatus,
  sectionNumber,
  handleSelectAll,
  handleDeselectAll,
  handleSelectStudent,
  handleFilterSelection,
  handleAssignStudentOpen,
  handleMassAssignStudentOpen,
  handleSelectedStatus,
  handleInitiateDelete
}) => {
  return (
    <ContainerResize>
      <Header title="Student Roster" subtitle="Students within Section" />
      <Actions>
        <IconSpacing>
          <Tooltip placement="top-start" title="Change Status">
            <IconButton>
              <StudentRosterStatusMenu
                handleSelectedStatus={handleSelectedStatus}
              />
            </IconButton>
          </Tooltip>
        </IconSpacing>
        <IconSpacing>
          <Tooltip placement="top-start" title="Assign Individual Student">
            <IconButton onClick={() => handleAssignStudentOpen()}>
              <AddUserIcon />
            </IconButton>
          </Tooltip>
        </IconSpacing>
        <IconSpacing>
          <Tooltip
            placement="top-start"
            title="Mass Assign Students to Roster (CSV)"
          >
            <IconButton onClick={() => handleMassAssignStudentOpen()}>
              <MassAssignIcon />
            </IconButton>
          </Tooltip>
        </IconSpacing>
        <IconSpacing>
          <Tooltip placement="top-start" title="Remove Students">
            <IconButton onClick={() => handleInitiateDelete()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </IconSpacing>
      </Actions>
      <ContainerPage paddingTop="20px">
        <Section>
          <SectionBox>
            <SectionTitle>Section {sectionNumber}</SectionTitle>
            <Chip label={`${students.length} STUDENTS`} />
          </SectionBox>
        </Section>
        <Table>
          <TableHead>
            <TableRow>
              <InstitutionId>Institution ID</InstitutionId>
              <Name>Last Name, First Name</Name>
              <Status>
                <StatusFilter
                  filterStatus={filterStatus}
                  handleFilterSelection={handleFilterSelection}
                />
              </Status>
              <SelectAll>
                <SelectAllBox>
                  {selectAll ? (
                    <>
                      <SelectLabel>Deselect all</SelectLabel>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectAll}
                            onChange={() => handleDeselectAll()}
                            color="primary"
                          />
                        }
                      />
                    </>
                  ) : (
                    <>
                      <SelectLabel>Select all</SelectLabel>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectAll}
                            onChange={() => handleSelectAll()}
                            color="primary"
                          />
                        }
                      />
                    </>
                  )}
                </SelectAllBox>
              </SelectAll>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.sortBy(students, ['user.lastName', 'user.firstName']).map(
              student => (
                <TableRow key={student.uuid}>
                  <InstitutionId>{student.user.institutionId}</InstitutionId>
                  <Name>
                    {formatLastName(
                      student?.user?.lastName,
                      student?.user?.marriedLastName,
                      student?.user?.firstName
                    )}
                  </Name>
                  <Status>
                    <StatusBox>
                      {userSectionStatusMap[student.status]}
                    </StatusBox>
                  </Status>
                  <SelectAll>
                    <SelectAllBox>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={student.selected}
                            onChange={() => handleSelectStudent(student.uuid)}
                            color="primary"
                          />
                        }
                      />
                    </SelectAllBox>
                  </SelectAll>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </ContainerPage>
    </ContainerResize>
  );
};

StudentRoster.propTypes = {
  students: PropTypes.array,
  selectAll: PropTypes.bool,
  filterStatus: PropTypes.object,
  sectionNumber: PropTypes.string,
  handleSelectAll: PropTypes.func,
  handleDeselectAll: PropTypes.func,
  handleSelectStudent: PropTypes.func,
  handleFilterSelection: PropTypes.func,
  handleAssignStudentOpen: PropTypes.func,
  handleMassAssignStudentOpen: PropTypes.func,
  handleSelectedStatus: PropTypes.func,
  handleInitiateDelete: PropTypes.func
};
StudentRoster.defaultProps = {
  students: [],
  selectAll: false,
  filterStatus: {},
  sectionNumber: '',
  handleSelectAll: undefined,
  handleDeselectAll: undefined,
  handleSelectStudent: undefined,
  handleFilterSelection: undefined,
  handleAssignStudentOpen: undefined,
  handleMassAssignStudentOpen: undefined,
  handleSelectedStatus: undefined,
  handleInitiateDelete: undefined
};

export default StudentRoster;
