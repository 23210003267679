import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import { tintColor } from '@xcomp/xcomp-design-library/dist/helpers';
import AssessmentSummaryRow from './AssessmentSummaryRow';
import { formatMMMddyyyy } from '../../../../helpers/date-fns.utils';

const HeaderCell = styled.th`
  vertical-align: bottom;

  background-color: ${props =>
    (props.redOne && tintColor(props.theme.colors.red[100], 0.5)) ||
    (props.redTwo && tintColor(props.theme.colors.red[100], 0.4)) ||
    (props.blueOne && tintColor(props.theme.colors.blue[500], 0.8)) ||
    'white'};

  width: ${props => (props.columnWidth ? props.columnWidth : '100px')};
  max-width: ${props => (props.columnWidth ? props.columnWidth : '100px')};

  ${props =>
    props.isFirst &&
    `background: linear-gradient(90deg, ${tintColor(
      props.theme.colors.blue[500],
      0.8
    )} 0%, rgba(255,255,255,1) 75%)`};
`;

const TitleCell = styled.th`
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding-left: 5px;
  background: ${props => props.theme.colors.grey[300]};
  ${props =>
    props.isEmpty &&
    `
  border-right: 1px solid ${props.theme.colors.grey[300]} !important;
  position: unset !important;
  `};
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const ChildContainer = styled.div`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  text-align: start;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AssessmentHeaderRow = ({
  assessments,
  isExpanded,
  isCompact,
  isDidactic,
  cohortStartDate,
  summaryRows,
  totalFirstAttemptPassRate,
  noOfBlocks,
  showDaysOfCompletion
}) => (
  <thead>
    <tr>
      <HeaderCell columnWidth="25px" redOne>
        <ChildContainer>Rank</ChildContainer>
      </HeaderCell>

      <HeaderCell columnWidth="250px" />

      {showDaysOfCompletion && !isDidactic && (
        <HeaderCell columnWidth="40px" redOne>
          <ChildContainer>
            <span>
              Days of &quot;Competence&quot; since{' '}
              <b>{formatMMMddyyyy(cohortStartDate)}</b>
            </span>
          </ChildContainer>
        </HeaderCell>
      )}
      <HeaderCell columnWidth="30px" redOne>
        <ChildContainer>
          <span>
            Completion Total <b>out of {noOfBlocks || '--'} assessments</b>
          </span>
        </ChildContainer>
      </HeaderCell>
      <HeaderCell columnWidth="70px" redOne>
        <ChildContainer>Completion Percentage</ChildContainer>
      </HeaderCell>
      {isDidactic
        ?
          <HeaderCell columnWidth="70px" redOne>
            <ChildContainer>Cumulative Scores</ChildContainer>
          </HeaderCell>
        :
          <HeaderCell columnWidth="70px" redOne>
            <ChildContainer>
              <span>
                First Attempt Pass Rate{' '}
                <b>
                  Average Rate:{' '}
                  {noOfBlocks ? `${totalFirstAttemptPassRate.toFixed(2)}%` : '--'}
                </b>
              </span>
            </ChildContainer>
          </HeaderCell>
      }

      {assessments.map(a => (
        <Tooltip
          key={`header + ${a.uuid}`}
          arrow
          title={
            <>
              {a?.partTitle && <BoldSpan>{a?.partTitle}</BoldSpan>}
              {isExpanded && <BoldSpan>{a.assessmentTitle}</BoldSpan>}
              <span>{a.title}</span>
            </>
          }
          placement="bottom-start"
        >
          <HeaderCell
            columnWidth={isCompact ? '60px' : '100px'}
            isFirst={a.isFirst}
          >
            {isExpanded ? (
              <ChildContainer>
                <BoldSpan>{a.assessmentTitle}</BoldSpan>
                <span>{a.title}</span>
              </ChildContainer>
            ) : (
              <ChildContainer>{a.title}</ChildContainer>
            )}
          </HeaderCell>
        </Tooltip>
      ))}
    </tr>

    {isExpanded && (
      <>
        <tr>
          <TitleCell colSpan={6}>Summary Statistics</TitleCell>
          <TitleCell isEmpty colSpan={assessments.length} />
        </tr>

        <AssessmentSummaryRow
          summaryLabel="Earliest Completion"
          summaryRows={summaryRows}
          propName="earliest"
          assessmentBlocks={assessments}
          isExpanded={isExpanded}
          showDaysOfCompletion={showDaysOfCompletion}
        />
        <AssessmentSummaryRow
          summaryLabel="Latest Completion"
          summaryRows={summaryRows}
          propName="latest"
          assessmentBlocks={assessments}
          isExpanded={isExpanded}
          showDaysOfCompletion={showDaysOfCompletion}
        />
        <AssessmentSummaryRow
          summaryLabel="Completion Span"
          summaryRows={summaryRows}
          propName="span"
          assessmentBlocks={assessments}
          isExpanded={isExpanded}
          showDaysOfCompletion={showDaysOfCompletion}
        />
      </>
    )}

    <tr>
      <TitleCell colSpan={6}>Active or Competent Students</TitleCell>
      <TitleCell isEmpty colSpan={assessments.length} />
    </tr>
  </thead>
);

AssessmentHeaderRow.propTypes = {
  assessments: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isCompact: PropTypes.bool.isRequired,
  isDidactic: PropTypes.bool,
  cohortStartDate: PropTypes.string,
  summaryRows: PropTypes.object.isRequired,
  totalFirstAttemptPassRate: PropTypes.number.isRequired,
  noOfBlocks: PropTypes.number.isRequired,
  showDaysOfCompletion: PropTypes.bool
};
AssessmentHeaderRow.defaultProps = {
  cohortStartDate: '',
  showDaysOfCompletion: true,
  isDidactic: false,
};

export default AssessmentHeaderRow;
