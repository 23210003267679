import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const assessmentTypeORM = ormCreateSelector(orm.AssessmentType);

export const assessmentTypeSelectors = createSelector(
  state => state,
  redux => {
    const assessmentTypes = assessmentTypeORM(redux);

    const sorted = _.sortBy(assessmentTypes, 'name');

    return sorted;
  }
);
