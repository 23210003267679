import React from 'react';
import PropTypes from 'prop-types';
import ByDefaultAptitude from './ByDefaultAptitude';

export default function ByDefaultAptitudeContainer({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  isAdmin,
}) {
  return (
    <ByDefaultAptitude
      userUuid={userUuid}
      cohortUuid={cohortUuid}
      programUuid={programUuid}
      category={category}
      source={source}
      isAdmin={isAdmin}
    />
  );
}

ByDefaultAptitudeContainer.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  isAdmin: PropTypes.bool,
};
ByDefaultAptitudeContainer.defaultProps = {
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  isAdmin: false,
};
