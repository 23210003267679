import styled from 'styled-components';
import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import { Panel } from '../Library/DashboardSC';
import AccordionRowContainer from '../Library/AccordionRow/AccordionRowContainer';
import AnonymizeStudents from './CohortAssessmentGrid/components/AnonymizeStudents';
// import { AnonymousIcon } from "./CohortAssessmentGrid/CohortAssessmentGridModalHeader";

export const PanelSC = styled(Panel)`
  margin-top: 24px;
  min-width: 900px;
  border: none !important;
`;

export const ProgressBar = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.fontColors.dark};
`;

export const NoDataContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.fontColors.dark};
`;

export const ErrorWrapper = styled.div`
  margin-top: 50px;
`;

export const SelfAssessmentAccordion = styled(AccordionRowContainer)`
  display: flex;
  flex-direction: column;
  & > div:first-child > div:first-child {
    padding-right: 10px;
  }

  ${props =>
    props.$level === 'student' &&
    `
    & > div:first-child > div:first-child {
      padding-right: 0;
    }
    
    & > div:first-child {
      background: ${props.theme.colors.primaryShades[props.$even ? 100 : 50]};

      & > div:hover {
        background: ${props.theme.colors.primaryShades[250]}
    }
  `};

  ${props =>
    props.$level === 'characterization' &&
    `
    padding-left: 20px;
    & > div:first-child {
      background: ${props.$background};
      box-shadow: -10px 0px 0px 0px ${props.$background};

      & > div:hover {
        background: ${props.theme.colors.grey[700]} !important;
        box-shadow: -10px 0px 0px 0px ${props.theme.colors.grey[700]};

        & div > *, * > span {
          color: ${props.theme.colors.white} !important;
        }
      }
    }

    ${
      props.$noPadding &&
      `
      padding-left: 0;
      
      & > div:nth-child(2) {
        padding-left: 10px;
      }
    `
    }
  `};

  ${props =>
    props.$level === 'academicYear' &&
    `
    padding-left: 20px;
    & > div:first-child > div:first-child {
      padding-right: 0;
    }
    
    & > div:first-child {
      background: ${props.$background};
      box-shadow: -10px 0px 0px 0px ${props.$background};
     

      & > div:hover {
        background: ${props.theme.colors.grey[500]} !important;
        box-shadow: -10px 0px 0px 0px ${props.theme.colors.grey[500]};
      }
    }
    
    & > div > .hover-effect {
      padding-right: 0;
    
    }
`};

  ${props =>
    props.$level === 'course' &&
    `
    margin-left: 10px;
    box-shadow: -10px 0px 0px 0px ${props.theme.colors.grey[500]};
    margin-bottom: 5px;

    & > div:first-child > div:hover {
      background: ${props.theme.colors.grey[500]} !important;
      box-shadow: -10px 0px 0px 0px ${props.theme.colors.grey[500]};
    }
  `};

  ${props =>
    props.$level === 'part' &&
    `
    margin-left: 20px;
    box-shadow: -10px 0px 0px 0px ${props.$background};
    margin-bottom: 5px;

    & > div:first-child > div:hover {
      background: ${props.$background} !important;
      box-shadow: -10px 0px 0px 0px ${props.$background};
    }
    
    & > div:first-child > div:first-child {
      padding-right: 0;
    }
    
  `};

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label {
    width: 160px;
  }
  .column_two_label {
    width: 100px;
  }
  .column_three_label {
    width: 100px;
  }
  .column_four_label {
    width: 100px;
  }

  .column_one_label,
  .column_two_label,
  .column_three_label,
  .column_four_label {
    flex-grow: 0;
    text-align: right;
  }

  .accordion_row_label div {
    color: ${props => props.theme.fontColors.darker};
  }

  .column_one_label,
  .column_two_label,
  .column_three_label,
  .column_four_label {
    margin-right: 16px;
    span {
      color: ${props => props.theme.fontColors.darker};
    }
  }

  .column_three_label {
    span {
      color: ${props => props.theme.fontColors.darker};
    }
  }

  .hover-effect {
    padding-right: 44px;
  }
  font-size: 20px;
  width: 100%;
`;

const AssessmentHeaderContainer = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  padding-right: 10px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  border-bottom: ${props => `solid 1px  ${props.theme.colors.grey[300]}`};
  font-size: 15px;
  font-weight: 450;
  background: ${props => props.theme.colors.primaryShades[500]};
  color: ${props => props.theme.colors.white};
`;

const AssessmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  width: calc(100% - 10px);
`;

const AssessmentHeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0px 16px 20px;
  overflow: hidden;
  flex-grow: 1;
`;

const AssessmentHeaderData = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  min-width: 360px;
`;

const AssessmentHeaderSub = styled.div`
  width: ${props => props.width};
  margin-right: 16px;
  text-align: right;
`;

const InvalidCharacterizationsBanner = styled.div`
  width: 100%;
  margin: 16px auto 0 auto;
  background: ${props => props.theme.colors.red[100]};
  border-radius: 3px;
  max-width: ${props => (props.$fullWidth ? '100%' : '1200px')};
  min-width: ${props => (props.$fullWidth ? '100%' : '900px')};

  & > div:first-child {
    border-radius: 3px 3px 0 0;
    font-size: 18px;
    font-weight: 500;
    padding: 5px 16px 5px 5px;
    color: white;
    background: ${props => props.theme.colors.red[500]};
    display: flex;
    align-items: center;
  }

  & > div:nth-child(2) {
    padding: 10px 16px;

    & > div:first-child {
      margin-bottom: 10px;
    }

    & > div {
      & > div {
        display: flex;
        margin-bottom: 5px;

        & > div:first-child {
          width: 250px;
        }

        & > div:nth-child(2) {
          font-weight: 500;
        }
      }
    }
  }
`;

export const Leaf = styled.div`
  width: 100%;
  padding: 5px 0px 5px 16px;
  margin-left: 20px;
  display: flex;
  font-size: 16px;
  align-items: center;
  background: ${props =>
    props.theme.colors.secondaryShades[props.$even ? 150 : 50]};
  margin-right: 16px;

  &:hover {
    background: ${props => props.theme.colors.secondaryShades[400]} !important;
  }

  & > div:first-child {
    width: calc(100% - 226px);

    & > div {
      display: flex;

      & > div:first-child {
        font-weight: 600;
        width: 100px;
        margin-right: 10px;
      }

      & > div:nth-child(2) {
        width: calc(100% - 150px);
      }

      &:first-child {
        margin-bottom: 5px;
      }
    }
  }

  & > div:nth-child(2) {
    width: 100px;
    text-align: right;
  }

  & > div:nth-child(3) {
    width: 126px;
    padding-right: 10px;
    text-align: right;
  }
`;

export const InvalidScores = ({ invalidCharacterizations, style }) => {
  return (
    <InvalidCharacterizationsBanner style={style}>
      <div>
        <WarningIcon style={{ marginRight: 5 }} />
        <div>Invalid scores</div>
      </div>
      <div>
        <div>
          Some of the found scores have invalid labels used for
          characterization. Below you can find the list of invalid labels and
          how many scores have it.
        </div>
        <div>
          {Object.keys(invalidCharacterizations).map(charac => (
            <div key={charac}>
              <div>
                <i>{charac}</i>
              </div>
              <div>
                {invalidCharacterizations[charac]}{' '}
                {invalidCharacterizations[charac] === 1 ? 'score' : 'scores'}
              </div>
            </div>
          ))}
        </div>
      </div>
    </InvalidCharacterizationsBanner>
  );
};

InvalidScores.propTypes = {
  invalidCharacterizations: PropTypes.object.isRequired,
  style: PropTypes.object
};

InvalidScores.defaultProps = {
  style: undefined
};

export const SelfAssessmentHeader = ({ isAnonymized, setIsAnonymized }) => {
  return (
    <AssessmentHeaderContainer>
      <AssessmentHeader>
        <AnonymizeStudents
          isAnonymized={isAnonymized}
          setIsAnonymized={setIsAnonymized}
        />
        <AssessmentHeaderInfo>
          Student{' '}
          {isAnonymized ? '(Institution ID)' : '(Last name, First name)'}
        </AssessmentHeaderInfo>
        <AssessmentHeaderData>
          <AssessmentHeaderSub width="160px">
            Total Characterizations
          </AssessmentHeaderSub>
          <AssessmentHeaderSub width="100px">Percentage</AssessmentHeaderSub>
          <AssessmentHeaderSub width="100px">
            Total Relative
          </AssessmentHeaderSub>
          <AssessmentHeaderSub width="100px">
            Total Potential
          </AssessmentHeaderSub>
        </AssessmentHeaderData>
      </AssessmentHeader>
    </AssessmentHeaderContainer>
  );
};

SelfAssessmentHeader.propTypes = {
  isAnonymized: PropTypes.bool.isRequired,
  setIsAnonymized: PropTypes.func.isRequired
};

SelfAssessmentHeader.defaultProps = {};

export const ReportLeaf = ({
  even,
  assessmentTitle,
  blockTitle,
  blockNumber,
  relValue,
  potValue
}) => {
  return (
    <Leaf $even={even}>
      <div>
        <div>
          <div>Assessment</div>
          <div>{assessmentTitle}</div>
        </div>
        <div>
          <div>Block {blockNumber}</div>
          <div>{blockTitle}</div>
        </div>
      </div>
      <div>{relValue ? relValue.toFixed(1) : '0.0'}</div>
      <div>{potValue ? potValue.toFixed(1) : '0.0'}</div>
    </Leaf>
  );
};

ReportLeaf.propTypes = {
  even: PropTypes.bool.isRequired,
  assessmentTitle: PropTypes.string.isRequired,
  blockTitle: PropTypes.string.isRequired,
  blockNumber: PropTypes.number.isRequired,
  relValue: PropTypes.number,
  potValue: PropTypes.number
};

ReportLeaf.defaultProps = {
  relValue: undefined,
  potValue: undefined
};
