import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body1 } from '@xcomp/xcomp-design-library';
import {
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import { Panel } from '../../../Library/Layout';
import { ButtonOutline } from '../../../Library/ButtonSC';
import { InfoText } from '../../../Library/FormComponents';
import PartWeightRow from './PartWeightRow';

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const NoPartsMessage = styled(Body1)`
  width: 100%;
  margin: 8px 8px 24px;

  span {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const SubmitButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const SectionParts = styled.div`
  margin: 24px 0;
`;

const CheckboxContainer = styled.div`
  color: ${props => props.theme.fontColors.dark};
  margin-bottom: 0;
`;

const FormControlLabelSC = styled(FormControlLabel)`
  && {
    margin-right: 0;
  }
`;

const DistributeEvenlyRow = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
`;

const ButtonOutlineSC = styled(ButtonOutline)``;

const PartWeightsForm = ({
  sectionUuid,
  distributeWeightEqually,
  parts,
  onPartWeightChange,
  showValidationErrors,
  setUnsavedChange,
  handleDistributeWeightEquallyChange,
  handleSubmitPartWeights,
  unsavedChanges
}) => {
  return (
    <Panel>
      <PanelPadding>
        {Object.keys(parts).length > 0 ? (
          <SectionParts>
            {Object.keys(parts).map((uuid, index) => {
              const { weight } = parts[uuid];
              return (
                <PartWeightRow
                  key={uuid}
                  index={index}
                  uuid={uuid}
                  weight={weight}
                  distributeWeightEqually={distributeWeightEqually}
                  onPartWeightChange={onPartWeightChange}
                  setUnsavedChange={setUnsavedChange}
                  showValidationErrors={showValidationErrors}
                />
              );
            })}
          </SectionParts>
        ) : (
          <NoPartsMessage>
            No parts have been created for this section. You must parts before
            setting section grade settings.
          </NoPartsMessage>
        )}

        <DistributeEvenlyRow>
          <FormControl component="fieldset">
            <FormGroup>
              <CheckboxContainer>
                <FormControlLabelSC
                  control={
                    <Checkbox
                      id={`distributeWeightEqually-${sectionUuid}`}
                      onChange={handleDistributeWeightEquallyChange}
                      checked={distributeWeightEqually}
                      color="primary"
                    />
                  }
                  label="Distribute weight equally among parts"
                />
              </CheckboxContainer>
            </FormGroup>
          </FormControl>
        </DistributeEvenlyRow>
        <SubmitButtonRow>
          {unsavedChanges.parts && (
            <InfoText
              alignItems="flex-end"
              justifyContent="flex-end"
              padding="0 8px 0 0"
              error
            >
              {/* <InfoIcon /> */}
              Unsaved Changes
            </InfoText>
          )}
          <ButtonOutlineSC
            onClick={handleSubmitPartWeights}
            disabled={!unsavedChanges.parts}
          >
            Apply
          </ButtonOutlineSC>
        </SubmitButtonRow>
      </PanelPadding>
    </Panel>
  );
};

PartWeightsForm.propTypes = {
  sectionUuid: PropTypes.string,
  distributeWeightEqually: PropTypes.bool,
  parts: PropTypes.object,
  handleDistributeWeightEquallyChange: PropTypes.func,
  showValidationErrors: PropTypes.bool,
  onPartWeightChange: PropTypes.func,
  handleSubmitPartWeights: PropTypes.func,
  unsavedChanges: PropTypes.object,
  setUnsavedChange: PropTypes.func
};

PartWeightsForm.defaultProps = {
  distributeWeightEqually: false,
  parts: {},
  sectionUuid: '',
  handleDistributeWeightEquallyChange: undefined,
  showValidationErrors: false,
  onPartWeightChange: undefined,
  handleSubmitPartWeights: undefined,
  unsavedChanges: {},
  setUnsavedChange: undefined
};

export default PartWeightsForm;
