import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import StudentAssign from './StudentAssign';
import { doPostUserSection } from '../../../../redux/actions/userSectionActions';
import { doGetSchoolStudentsByStatus } from '../../../../redux/actions/userCohortActions';
import { userByStudentSchoolSelector } from '../../../../redux/selectors/userCohortsSelectors';

export default function StudentAssignContainer({
  sectionUuid,
  schoolUuid,
  openAssignStudent,
  handleAssignStudentClose
}) {
  const [searchInput, setSearchInput] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedToggle, setSelectedToggle] = useState(false);
  const dispatch = useDispatch();

  const searchUser = useSelector(
    state => userByStudentSchoolSelector(state, searchInput),
    _.isEqual
  );

  useEffect(() => {
    if (!openAssignStudent) {
      setSearchInput('');
      setSelectedUser(undefined);
      setSelectedToggle(false);
    }
  }, [openAssignStudent]);

  useEffect(() => {
    if (!selectedToggle) {
      setSelectedUser(undefined);
    }
  }, [selectedToggle]);

  const handleSearchChange = event => {
    const { value } = event.target;
    setSearchInput(value);
  };

  const handleSearch = () => {
    dispatch(doGetSchoolStudentsByStatus(schoolUuid, 'active', searchInput));
  };

  const handleUserSelection = uuid => {
    setSelectedToggle(!selectedToggle);
    setSelectedUser(uuid);
  };

  const handleAssignStudent = () => {
    const userUuid = selectedUser;

    const payload = {
      sectionUuid,
      userUuid,
      status: 'active'
    };

    dispatch(doPostUserSection(payload));
    handleAssignStudentClose();
  };

  return (
    <StudentAssign
      openAssignStudent={openAssignStudent}
      searchInput={searchInput}
      searchUser={searchUser}
      selectedToggle={selectedToggle}
      selectedUser={selectedUser}
      handleAssignStudentClose={handleAssignStudentClose}
      handleSearchChange={handleSearchChange}
      handleSearch={handleSearch}
      handleUserSelection={handleUserSelection}
      handleAssignStudent={handleAssignStudent}
    />
  );
}

StudentAssignContainer.propTypes = {
  schoolUuid: PropTypes.string,
  sectionUuid: PropTypes.string,
  openAssignStudent: PropTypes.bool,
  handleAssignStudentClose: PropTypes.func
};
StudentAssignContainer.defaultProps = {
  schoolUuid: '',
  sectionUuid: '',
  openAssignStudent: false,
  handleAssignStudentClose: undefined
};
