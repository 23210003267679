import fetch from '../helpers/fetch';

const getRigorHistoricalReport = programUuid => {
  return fetch(`/api/rigor-historical-report?programUuid=${programUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export { getRigorHistoricalReport };
