import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectInstitutionGradeBySubject } from '../../../../redux/selectors/transcriptCognitiveSelectors';

export default function InstitutionGrade({
  programUuid,
  userUuid,
  cohortUuid,
  source,
  sourceInstitutionUuid,
  classificationUuid
}) {
  const cognitiveGrade = useSelector(state =>
    selectInstitutionGradeBySubject(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      source,
      sourceInstitutionUuid,
      classificationUuid
    )
  );

  const grade = cognitiveGrade || 'N/A';

  return <>{grade}</>;
}

InstitutionGrade.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  source: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string,
  classificationUuid: PropTypes.string
};

InstitutionGrade.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  source: undefined,
  sourceInstitutionUuid: undefined,
  classificationUuid: undefined
};
