import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { assessmentSelector } from '../../../redux/selectors/assessmentSelectors';

import SyllabusAssessment from './SyllabusAssessment';

export default function SyllabusAssessmentContainer({
  cohortUuid,
  programUuid,
  cohortAssessmentCollectionUuid,
  cohortAssessmentUuid,
  itemNumber
}) {
  const assessment = useSelector(
    state => assessmentSelector(state, cohortAssessmentUuid),
    _.isEqual
  );

  const {
    grading_technique,
    assessment_manager,
    responsible_faculty,
    assessment_type
  } = assessment;

  return (
    <SyllabusAssessment
      itemNumber={itemNumber}
      cohortUuid={cohortUuid}
      programUuid={programUuid}
      cohortAssessmentCollectionUuid={cohortAssessmentCollectionUuid}
      assessment={assessment}
      gradingTechnique={grading_technique}
      assessmentManager={`${assessment_manager?.firstName} ${assessment_manager?.lastName}`}
      responsibleFaculty={`${responsible_faculty?.firstName} ${responsible_faculty?.lastName}`}
      assessmentType={assessment_type?.name}
    />
  );
}

SyllabusAssessmentContainer.propTypes = {
  cohortAssessmentUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  cohortAssessmentCollectionUuid: PropTypes.string,
  itemNumber: PropTypes.number.isRequired
};

SyllabusAssessmentContainer.defaultProps = {
  cohortAssessmentUuid: '',
  cohortUuid: '',
  programUuid: '',
  cohortAssessmentCollectionUuid: ''
};
