import {
  SET_SELECTED_INITIAL_EDITOR_STATE,
  SET_SELECTED_DEPARTMENT_UUID_COURSE_EDIT,
  SET_SELECTED_COURSE_MASTER_UUID_COURSE_EDIT,
  SET_SELECTED_ACADEMIC_YEAR_UUID_COURSE_EDIT,
  SET_SELECTED_TERM_UUID_COURSE_EDIT,
  SET_IS_LOADING_COURSE_EDIT
} from '../actions-type';

export const INITIAL_STATE = {
  selectedDepartmentUuid: '',
  selectedCourseMasterUuid: '',
  selectedAcademicYearUuid: '',
  selectedTermUuid: '',
  isLoading: false
};

const courseEditReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_INITIAL_EDITOR_STATE: {
      return {
        ...state,
        selectedDepartmentUuid:
          action.payload.selections.course_master.departmentUuid,
        selectedCourseMasterUuid: action.payload.selections.course_master.uuid,
        selectedAcademicYearUuid: action.payload.selections.academicYearUuid,
        selectedTermUuid: action.payload.selections.termUuid
      };
    }
    case SET_SELECTED_DEPARTMENT_UUID_COURSE_EDIT: {
      return {
        ...state,
        selectedDepartmentUuid: action.payload.departmentUuid,
        selectedCourseMasterUuid: ''
      };
    }
    case SET_SELECTED_COURSE_MASTER_UUID_COURSE_EDIT: {
      return {
        ...state,
        selectedCourseMasterUuid: action.payload.courseMasterUuid
      };
    }
    case SET_SELECTED_ACADEMIC_YEAR_UUID_COURSE_EDIT: {
      return {
        ...state,
        selectedAcademicYearUuid: action.payload.academicYearUuid,
        selectedTermUuid: ''
      };
    }
    case SET_SELECTED_TERM_UUID_COURSE_EDIT: {
      return {
        ...state,
        selectedTermUuid: action.payload.termUuid
      };
    }
    case SET_IS_LOADING_COURSE_EDIT: {
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    }
    default:
      return state;
  }
};

export default courseEditReducer;
