export const PUT_APPLICANT_COGNITIVE_REPORT = 'PUT_APPLICANT_COGNITIVE_REPORT';
export const PUT_APPLICANT_COGNITIVE_REPORT_ERROR =
  'PUT_APPLICANT_COGNITIVE_REPORT_ERROR';
export const PUT_APPLICANT_COGNITIVE_REPORT_SUCCESS =
  'PUT_APPLICANT_COGNITIVE_REPORT_SUCCESS';

export const GET_APPLICANT_COGNITIVE_REPORT_TYPES =
  'GET_APPLICANT_COGNITIVE_REPORT_TYPES';
export const GET_APPLICANT_COGNITIVE_REPORT_TYPES_ERROR =
  'GET_APPLICANT_COGNITIVE_REPORT_TYPES_ERROR';
export const GET_APPLICANT_COGNITIVE_REPORT_TYPES_SUCCESS =
  'GET_APPLICANT_COGNITIVE_REPORT_TYPES_SUCCESS';

export const CLEAR_APPLICANT_COGNITIVE_REPORT =
  'CLEAR_APPLICANT_COGNITIVE_REPORT';

export const PUT_APPLICANT_COMMUNICATION_REPORT =
  'PUT_APPLICANT_COMMUNICATION_REPORT';
export const PUT_APPLICANT_COMMUNICATION_REPORT_ERROR =
  'PUT_APPLICANT_COMMUNICATION_REPORT_ERROR';
export const PUT_APPLICANT_COMMUNICATION_REPORT_SUCCESS =
  'PUT_APPLICANT_COMMUNICATION_REPORT_SUCCESS';

export const GET_APPLICANT_COMMUNICATION_REPORT_TYPES =
  'GET_APPLICANT_COMMUNICATION_REPORT_TYPES';
export const GET_APPLICANT_COMMUNICATION_REPORT_TYPES_ERROR =
  'GET_APPLICANT_COMMUNICATION_REPORT_TYPES_ERROR';
export const GET_APPLICANT_COMMUNICATION_REPORT_TYPES_SUCCESS =
  'GET_APPLICANT_COMMUNICATION_REPORT_TYPES_SUCCESS';

export const CLEAR_APPLICANT_COMMUNICATION_REPORT =
  'CLEAR_APPLICANT_COMMUNICATION_REPORT';

export const PUT_APPLICANT_DEMOGRAPHIC_REPORT =
  'PUT_APPLICANT_DEMOGRAPHIC_REPORT';
export const PUT_APPLICANT_DEMOGRAPHIC_REPORT_ERROR =
  'PUT_APPLICANT_DEMOGRAPHIC_REPORT_ERROR';
export const PUT_APPLICANT_DEMOGRAPHIC_REPORT_SUCCESS =
  'PUT_APPLICANT_DEMOGRAPHIC_REPORT_SUCCESS';

export const GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES =
  'GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES';
export const GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES_ERROR =
  'GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES_ERROR';
export const GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES_SUCCESS =
  'GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES_SUCCESS';

export const CLEAR_APPLICANT_DEMOGRAPHIC_REPORT =
  'CLEAR_APPLICANT_DEMOGRAPHIC_REPORT';

export const PUT_APPLICANT_NONCOGNITIVE_REPORT =
  'PUT_APPLICANT_NONCOGNITIVE_REPORT';
export const PUT_APPLICANT_NONCOGNITIVE_REPORT_ERROR =
  'PUT_APPLICANT_NONCOGNITIVE_REPORT_ERROR';
export const PUT_APPLICANT_NONCOGNITIVE_REPORT_SUCCESS =
  'PUT_APPLICANT_NONCOGNITIVE_REPORT_SUCCESS';

export const GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES =
  'GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES';
export const GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES_ERROR =
  'GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES_ERROR';
export const GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES_SUCCESS =
  'GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES_SUCCESS';

export const CLEAR_APPLICANT_NONCOGNITIVE_REPORT =
  'CLEAR_APPLICANT_NONCOGNITIVE_REPORT';

export const PUT_APPLICANT_PHYSICAL_SKILLS_REPORT =
  'PUT_APPLICANT_PHYSICAL_SKILLS_REPORT';
export const PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_ERROR =
  'PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_ERROR';
export const PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_SUCCESS =
  'PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_SUCCESS';

export const GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES =
  'GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES';
export const GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES_ERROR =
  'GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES_ERROR';
export const GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES_SUCCESS =
  'GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES_SUCCESS';

export const CLEAR_APPLICANT_PHYSICAL_SKILLS_REPORT =
  'CLEAR_APPLICANT_PHYSICAL_SKILLS_REPORT';
