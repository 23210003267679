import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2, Caption, ButtonOutline } from '@xcomp/xcomp-design-library';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import StatusBar from './StatusBar';
import {
  visibleToSchoolRoles,
  visibleToSchoolAdmin
} from '../../../helpers/authorization';

import { roles } from '../../../helpers/constants';

const Layout = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  height: 70px;
`;

const Title = styled(Heading2)`
  margin: 0;
`;

const TitleAndStatus = styled.div`
  display: flex;
`;

const UnsavedChanges = styled(Caption)`
  margin: 0;
  margin-right: 1rem;
  display: flex;
  align-items: center;
`;

const RevertChangesButton = styled(ButtonOutline)`
  && {
    margin-right: 1rem;
  }
`;

const MenuItemSC = styled(MenuItem)`
  && {
    font-weight: 500;
    font-size: 0.875rem;
    opacity: 0.8;
    width: 150px;
    &:hover {
      background: ${props => props.theme.colors.grey[300]};
    }
  }
`;

const ButtonAction = styled.button`
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  list-style: none outside none;
  margin: 0;
  text-indent: 0;

  border: 1px solid ${props => props.theme.colors.grey[300]};
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 5px 8px 5px 15px;
  font-weight: 400;
  border-radius: 5px;
  font-size: 14px;
  font-family: IBM Plex Sans;
  letter-spacing: 1.25px;
  color: ${props => props.theme.colors.primary};

  &:hover,
  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

// TODO: replace with respective xcomp library component
// const ButtonActionText = styled.div`
//   padding-left: 8px;
//   padding-right: 8px;
//   font-weight: 500;
//   font-size: 0.875rem;
//   letter-spacing: 2pt;
//   opacity: 0.8;
//   color: ${props => props.theme.colors.primary};
// `;
const ButtonActionText = styled.div`
  ${
    '' /* padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 2pt;
  opacity: 0.8;
  color: ${props => props.theme.colors.primary}; */
  }
`;

const ArrowDropDownSC = styled(ArrowDropDown)`
  && {
    ${'' /* font-size: 40px; */}
    ${
      '' /* border-left: 1px solid ${props => props.theme.fontColors.dark}; */
    }
    color: ${props => props.theme.fontColors.dark};
  }
`;

const ViewOnlyMode = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
`;

const SaveActions = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
`;

const ViewOnlyLabel = styled.div`
  padding-left: 5px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
`;

const InProgress = visibleToSchoolRoles(props => (
  <MenuItemSC
    onClick={() => {
      props.handleSaveDraft();
      props.handleClose();
    }}
  >
    In Progress
  </MenuItemSC>
));

const Completed = visibleToSchoolRoles(props => (
  <MenuItemSC
    onClick={() => {
      props.handleFinish();
      props.handleClose();
    }}
  >
    Complete
  </MenuItemSC>
));

const Approved = visibleToSchoolAdmin(props => (
  <MenuItemSC
    onClick={() => {
      props.handleApprove();
      props.handleClose();
    }}
  >
    Approved
  </MenuItemSC>
));

const Delete = visibleToSchoolRoles(props => (
  <>
    <hr />
    <MenuItemSC
      onClick={() => {
        props.handleDeleteOpen();
        props.handleClose();
      }}
    >
      Delete
    </MenuItemSC>
  </>
));

export default function ItemFormHeader({
  isExistingItem,
  itemNumber,
  item,
  handleSaveDraft,
  handleFinish,
  handleApprove,
  handleDeleteOpen,
  itemType,
  restrictEdit,
  hasUnsavedChanges,
  onDiscardChanges
}) {
  const buttonRef = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const activeUser = useSelector(state => state.userState, shallowEqual);
  const title = isExistingItem ? (
    <Title>
      {itemType} {itemNumber.toString().padStart(3, 0)}
    </Title>
  ) : (
    <Title>New {itemType}</Title>
  );

  const showDelete = !(
    item.status === 'Approved' &&
    activeUser.selectedRole === roles.departmentFaculty
  );

  const handleClick = () => {
    setAnchorEl(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Layout>
      <TitleAndStatus>
        {title}
        {item.uuid && <StatusBar status={item.status} />}
      </TitleAndStatus>
      {restrictEdit ? (
        <ViewOnlyMode>
          <RemoveRedEye />
          <ViewOnlyLabel>view only mode</ViewOnlyLabel>
        </ViewOnlyMode>
      ) : (
        <SaveActions>
          {hasUnsavedChanges && (
            <>
              <UnsavedChanges>Unsaved Changes</UnsavedChanges>
              {onDiscardChanges && (
                <RevertChangesButton onClick={onDiscardChanges}>
                  Discard
                </RevertChangesButton>
              )}
            </>
          )}
          <ButtonAction
            aria-controls="simple-menu"
            aria-haspopup="true"
            ref={buttonRef}
            onClick={handleClick}
          >
            <ButtonActionText>SAVE AS</ButtonActionText>
            <ArrowDropDownSC />
          </ButtonAction>
          <Menu
            id="action-menu"
            anchorEl={() => buttonRef.current}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <div />
            <InProgress
              handleSaveDraft={handleSaveDraft}
              handleClose={handleClose}
            />
            {item.status !== 'Complete' && (
              <Completed
                handleFinish={handleFinish}
                handleClose={handleClose}
              />
            )}
            {item.uuid && (
              <div>
                {item.status === 'Complete' && (
                  <Approved
                    handleApprove={handleApprove}
                    handleClose={handleClose}
                  />
                )}
                {showDelete && (
                  <Delete
                    handleDeleteOpen={handleDeleteOpen}
                    handleClose={handleClose}
                  />
                )}
              </div>
            )}
          </Menu>
        </SaveActions>
      )}
    </Layout>
  );
}

ItemFormHeader.propTypes = {
  restrictEdit: PropTypes.bool,
  isExistingItem: PropTypes.bool,
  hasUnsavedChanges: PropTypes.bool,
  itemNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  onDiscardChanges: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  itemType: PropTypes.string,
  item: PropTypes.object,
  handleSaveDraft: PropTypes.func,
  handleFinish: PropTypes.func,
  handleApprove: PropTypes.func,
  handleDeleteOpen: PropTypes.func
};

ItemFormHeader.defaultProps = {
  restrictEdit: true,
  isExistingItem: false,
  hasUnsavedChanges: false,
  itemNumber: 1,
  itemType: '',
  item: {},
  onDiscardChanges: false,
  handleSaveDraft: undefined,
  handleFinish: undefined,
  handleApprove: undefined,
  handleDeleteOpen: undefined
};
