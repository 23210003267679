import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import AccordionRowContainer from '../../../../../Library/AccordionRow/AccordionRowContainer';
import AptitudeConversion from './AptitudeConversion';

import { selectAptitudes } from '../../../../../../redux/selectors/classificationSelectors';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .accordion_row_label {
    flex-grow: 2;
  }

  && {
    .accordion_row_label div {
      letter-spacing: 0.15;
    }
  }

  width: 100%;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div``;

const Input = styled.div`
  margin-left: 60px;
  color: ${props => props.theme.fontColors.darker};
`;

const Layout = styled.div`
  margin-left: 264px;
  margin-bottom: 30px;
  width: 100%;
`;

export default function AptitudeTest({ classificationUuid }) {
  const aptitudes = useSelector(
    state => selectAptitudes(state, classificationUuid),
    _.isEqual
  );

  return (
    <>
      {!_.isNil(aptitudes) &&
        aptitudes.map(aptitude => (
          <Accordion
            key={aptitude.uuid}
            rowOffset="198px"
            label={
              <Group>
                <Label>{aptitude.examId}</Label>
                <Input>{aptitude.examName}</Input>
              </Group>
            }
          >
            <Layout>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  {aptitude.examDescription}
                </Grid>
              </Grid>
            </Layout>
            <AptitudeConversion examAptitudeUuid={aptitude.uuid} />
          </Accordion>
        ))}
    </>
  );
}

AptitudeTest.propTypes = {
  classificationUuid: PropTypes.string
};

AptitudeTest.defaultProps = {
  classificationUuid: undefined
};
