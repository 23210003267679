import fetch from '../helpers/fetch';

const getPartGradeSettings = partUuid =>
  fetch(`/api/part/${partUuid}/part-grade-settings`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getGradeCollection = ({ partUuid, gradeCollectionUuid }) =>
  fetch(`/api/part/${partUuid}/grade-collection/${gradeCollectionUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putPartDataSource = ({ partUuid, dataSource }) =>
  fetch(`/api/part/${partUuid}/set-datasource`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ dataSource })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putPartGradeCollections = ({
  partGradeSettingsUuid,
  grade_collections,
  collectionsToDelete,
  distributeWeightEqually
}) =>
  fetch(`/api/part-grade-settings/${partGradeSettingsUuid}/grade-collections`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      grade_collections,
      collectionsToDelete,
      distributeWeightEqually
    })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putCollectionAssessments = ({
  partUuid,
  scoreType,
  gradeCollectionUuid,
  distributeWeightEqually,
  collection_assessments
}) =>
  fetch(
    `/api/part/${partUuid}/grade-collection/${gradeCollectionUuid}/assessments`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        scoreType,
        distributeWeightEqually,
        collection_assessments
      })
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getPartGradeSettings,
  getGradeCollection,
  putPartDataSource,
  putPartGradeCollections,
  putCollectionAssessments
};
