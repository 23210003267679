import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import FormSelect from '../../Library/FormSelect';

const FilterButton = styled(ButtonInline)`
  && {
    padding-left: 3px;
  }
`;

const Layout = styled.div`
  margin-bottom: 0;
  padding: 16px 24px;
`;

const ButtonRow = styled.div`
  width: 100%;
  margin: 0;
`;

const valueIfOptionsLoaded = (value, options) =>
  options?.length > 0 ? value : '';

export default function CourseFilter({
  departmentUuid,
  courseMasterUuid,
  academicYearUuid,
  termUuid,
  filterDepartmentOptions,
  filterCourseMasterOptions,
  filterAcademicYearOptions,
  filterTermOptions,
  handleFilterSelection,
  handleResetFilters
}) {
  const listOfDepartments = filterDepartmentOptions.map(department => ({
    value: department.uuid,
    label: department.name
  }));

  const listOfCourseMasters = filterCourseMasterOptions
    .filter(cm => cm.termUuid === termUuid)
    .map(courseMaster => ({
      value: courseMaster.uuid,
      label: `${courseMaster.courseNumber}-${courseMaster.title}`
    }));

  const listOfAcademicYears = filterAcademicYearOptions.map(academicYear => {
    const startYear = moment(academicYear.startDate).format('YYYY');
    const endYear = moment(academicYear.endDate).format('YYYY');

    return {
      value: academicYear.uuid,
      label: `${startYear}-${endYear}`
    };
  });

  const listOfTerms = filterTermOptions.map(term => ({
    value: term.uuid,
    label: term.period
  }));

  return (
    <Layout>
      <ButtonRow>
        <FilterButton onClick={handleResetFilters} noHoverBg>
          Clear Filters
        </FilterButton>
      </ButtonRow>

      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={3}>
          <FormSelect
            value={valueIfOptionsLoaded(departmentUuid, listOfDepartments)}
            options={listOfDepartments}
            handleChange={handleFilterSelection}
            name="department"
            label="Department"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormSelect
            value={valueIfOptionsLoaded(academicYearUuid, listOfAcademicYears)}
            options={listOfAcademicYears}
            handleChange={handleFilterSelection}
            name="academicYear"
            label="Academic Year"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormSelect
            value={valueIfOptionsLoaded(termUuid, listOfTerms)}
            options={listOfTerms}
            handleChange={handleFilterSelection}
            name="term"
            label="Term"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormSelect
            value={valueIfOptionsLoaded(courseMasterUuid, listOfCourseMasters)}
            options={listOfCourseMasters}
            handleChange={handleFilterSelection}
            name="course"
            label="Course"
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

CourseFilter.propTypes = {
  departmentUuid: PropTypes.string,
  courseMasterUuid: PropTypes.string,
  academicYearUuid: PropTypes.string,
  termUuid: PropTypes.string,
  filterDepartmentOptions: PropTypes.array,
  filterCourseMasterOptions: PropTypes.array,
  filterAcademicYearOptions: PropTypes.array,
  filterTermOptions: PropTypes.array,
  handleFilterSelection: PropTypes.func,
  handleResetFilters: PropTypes.func
};

CourseFilter.defaultProps = {
  departmentUuid: '',
  courseMasterUuid: '',
  academicYearUuid: '',
  termUuid: '',
  filterDepartmentOptions: [],
  filterCourseMasterOptions: [],
  filterAcademicYearOptions: [],
  filterTermOptions: [],
  handleFilterSelection: undefined,
  handleResetFilters: undefined
};
