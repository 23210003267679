import fetch from '../helpers/fetch';

const getPartTopics = partUuid =>
  fetch(`/api/part/${partUuid}/topics`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getPartTopics };
