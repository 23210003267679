import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useDelete } from '../../Library/Modal/DeleteModal/useDelete';
import ProgramAssessmentCollection from './ProgramAssessmentCollection';
import { programAssessmentCollectionSelector } from '../../../redux/selectors/programSelectors';
import { doDeleteProgramAsssessmentCollection } from '../../../redux/actions/programActions';

const ProgramAssessmentCollectionContainer = ({
  programAssessmentCollectionUuid,
  programUuid
}) => {
  // eslint-disable-next-line prefer-const
  let history = useHistory();
  const dispatch = useDispatch();
  const [isEditView, setEditView] = useState(false);

  const onDelete = () =>
    dispatch(
      doDeleteProgramAsssessmentCollection({
        uuid: programAssessmentCollectionUuid,
        programUuid
      })
    );

  const linkAddProgramAssessment = () => {
    const historyString = `/program-management/program-assessment-collection/${programAssessmentCollectionUuid}/program-assessment/add`;
    history.push(historyString);
  };

  const programAssessmentCollection = useSelector(
    state =>
      programAssessmentCollectionSelector(
        state,
        programAssessmentCollectionUuid
      ),
    _.isEqual
  );

  const [
    isDeleteModalOpen,
    handleDeleteClick,
    handleDeleteModalClose,
    handleDelete
  ] = useDelete(onDelete);

  const onDeleteClick = () => {
    handleDeleteClick();
  };

  const toggleEditView = value => {
    setEditView(value);
  };

  return (
    <ProgramAssessmentCollection
      isEditView={isEditView}
      programAssessmentCollection={programAssessmentCollection}
      toggleEditView={toggleEditView}
      linkAddProgramAssessment={linkAddProgramAssessment}
      isDeleteModalOpen={isDeleteModalOpen}
      handleDeleteClick={onDeleteClick}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDelete={handleDelete}
    />
  );
};

ProgramAssessmentCollectionContainer.propTypes = {
  programAssessmentCollectionUuid: PropTypes.string.isRequired,
  programUuid: PropTypes.string.isRequired
};

export default ProgramAssessmentCollectionContainer;
