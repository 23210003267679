import React from 'react';
import PropTypes from 'prop-types';
import useSWRMutation from 'swr/mutation';
import { Button, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import SimpleModal from './SimpleModal';
import fetchWithAuthorization from '../../../helpers/fetch';

const ModalHeader = styled.div`
  background: ${props => props.theme.colors.primaryShades[500]};
  padding: 10px 20px;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.veryLarge};;
`

const ModalBody = styled.div`
  width: 100%;
  padding: 15px 20px 10px 20px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ConfirmButton = styled(Button)`
  background: ${props => props.$isDisabled ? props.theme.colors.grey[300] : props.theme.colors.status.success} !important;
  color: ${props => props.theme.colors.white} !important;
  
  &:hover{
    background: ${props => props.$isDisabled ? props.theme.colors.grey[300] : props.theme.colors.green[800]} !important;
  }
  width: 150px;
`

const CancelButton = styled(Button)`
  color: ${props => props.theme.colors.status.error} !important;
  border-color: ${props => props.theme.colors.status.error} !important;
  width: 150px;
  
  &:hover{
    color: ${props => props.theme.colors.red[800]} !important;
    border-color: ${props => props.theme.colors.red[800]} !important;
    background: ${props => props.theme.colors.red[800]}20 !important;
  }
`

const ErrorBanner = styled.div`
  background: ${props => props.theme.colors.red[100]};
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
  color: ${props => props.theme.colors.red[900]};
`

const DownloadModal = ({
  modalOpen,
  style,
  handleModalClose,
  warningMessage,
  description,
  title,
  confirmationMessage,
  downloadURL
}) => {

  const { trigger, error, isMutating } = useSWRMutation(
    downloadURL,
    async (url) => {
      const res = await fetchWithAuthorization(url);

      if (!res.ok) {
        const contentType = res?.headers?.get('content-type');
        const message = contentType?.includes('json')
          ? await res.json()
          : await res.text();
        const error = new Error(message?.error || message || 'File could not be downloaded. Please try again.');

        error.status = res.status;
        throw error;
      }

      const fileName = decodeURI(res?.headers?.get('content-disposition')?.split('filename=')?.[1] || 'Assessment scores');

      const blobRes = await res.blob();
      const urlBlob = URL.createObjectURL(blobRes);

      const downloadLink = document.createElement('a');
      downloadLink.style.display = 'none';
      downloadLink.href = urlBlob;
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();

      URL.revokeObjectURL(urlBlob);
      downloadLink.remove();
      handleModalClose();
    },
    {
      throwOnError: false,
      revalidateOnFocus: false,
    }
  );

  return (
    <div style={style}>
      <SimpleModal open={modalOpen} onClose={handleModalClose}>
        <>
          <ModalHeader>
            {title}
          </ModalHeader>
          <ModalBody>
            <div>
              {description &&
                <div>
                  {isMutating ? 'Please wait while we generate your file. This may take a moment.' : description}
                </div>
              }
              {warningMessage && <div>{warningMessage}</div>}

              {error && <ErrorBanner>{error?.message}</ErrorBanner>}
            </div>
            <Actions>
              <CancelButton variant='outlined' onClick={handleModalClose}>
                Close
              </CancelButton>
              <ConfirmButton $isDisabled={isMutating} onClick={() => trigger()} style={{marginLeft: 10}}>
                {isMutating 
                  ? 
                    <>
                      <CircularProgress color='grey' size={18} />
                      <div style={{paddingLeft: 5}}>Downloading</div>
                    </>
                  :
                    <div>{confirmationMessage ?? 'Download'}</div>
                }
              </ConfirmButton>
            </Actions>
          </ModalBody>
        </>
      </SimpleModal>
    </div>
  );
}

DownloadModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  style: PropTypes.object,
  title: PropTypes.string || PropTypes.element,
  description: PropTypes.string || PropTypes.element,
  warningMessage: PropTypes.string,
  confirmationMessage: PropTypes.string,
  downloadURL: PropTypes.string.isRequired,
};

DownloadModal.defaultProps = {
  style: undefined,
  title: null,
  warningMessage: '',
  description: null,
  confirmationMessage: null,
};

export default DownloadModal;
