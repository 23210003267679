import { all } from 'redux-saga/effects';
import { academicYear } from './academicYearSaga';
import { applicantTranscript } from './applicantTranscriptSaga';
import { applicantReports } from './applicantReportSaga';
import { assessment } from './assessmentSaga';
import { assessmentBlock } from './assessmentBlockSaga';
import { assessmentGrade } from './assessmentGradeSaga';
import { calculatedGradeResult } from './calculatedGradeResultSaga';
import { classification } from './classificationSaga';
import { cohort } from './cohortSaga';
import { cohortCompetencyGrid } from './competencyGridSaga';
import { cohortOpportunityReport } from './cohortOpportunityReportSaga';
import { competency } from './competencySaga';
import { competencyCollection } from './competencyCollectionSaga';
import { course } from './courseSaga';
import { courseCollection } from './courseCollectionSaga';
import { courseMaster } from './courseMasterSaga';
import { courseAgeMod } from './courseAgeModSaga';
import { credential } from './credentialSaga';
import { department } from './departmentSaga';
import { degreeLevelIndex } from './degreeLevelIndexSaga';
import { educationLevel } from './educationLevelSaga';
import { encounter } from './encounterSaga';
import { gradeSummary } from './gradeSummarySaga';
import { gradingTechnique } from './gradingTechniqueSaga';
import { group } from './groupSaga';
import { hierarchy } from './hierachySaga';
import { rigorHistoricalReport } from './rigorHistoricalReportSaga';
import { institution } from './institutionSaga';
import { institutionRigor } from './institutionRigorSaga';
import { legacyGrading } from './legacyGradingSaga';
import { letterGrade } from './letterGradeSaga';
import { loverb } from './loverbSaga';
import { logout } from './logoutUserSaga';
import { partSaga } from './partSaga';
import { partReports } from './partReportsSaga';
import { partGradeSettingsSaga } from './partGradeSettingsSaga';
import { partGroupSummary } from './partGroupSummarySaga';
import { program } from './programSaga';
import { cohortAssessmentCollection } from './cohortAssessmentSaga';
import { resultCards } from './resultCardsSaga';
import { school } from './schoolSaga';
import { score } from './scoreSaga';
import { section } from './sectionSaga';
import { sectionPerformanceReport } from './sectionPerformanceReportSaga';
import { source } from './sourceSaga';
import { syllabus } from './syllabusSaga';
import { term } from './termSaga';
import { thirdPartyId } from './thirdPartySaga';
import { topic } from './topicSaga';
import { user } from './userSaga';
import { userCohort } from './userCohortSaga';
import { userDepartment } from './userDepartmentSaga';
import { userGroup } from './userGroupSaga';
import { userProgram } from './userProgramSaga';
import { userSchool } from './userSchoolSaga';
import { userSection } from './userSectionSaga';
import { cohortAssessmentGrid } from './assessmentGridSaga';

function* watchAll() {
  yield all([
    ...academicYear,
    ...applicantTranscript,
    ...applicantReports,
    ...assessment,
    ...assessmentBlock,
    ...assessmentGrade,
    ...calculatedGradeResult,
    ...classification,
    ...cohort,
    ...cohortCompetencyGrid,
    ...cohortAssessmentGrid,
    ...cohortOpportunityReport,
    ...cohortAssessmentCollection,
    ...competency,
    ...competencyCollection,
    ...course,
    ...courseCollection,
    ...courseMaster,
    ...courseAgeMod,
    ...credential,
    ...department,
    ...degreeLevelIndex,
    ...educationLevel,
    ...encounter,
    ...gradeSummary,
    ...gradingTechnique,
    ...group,
    ...hierarchy,
    ...rigorHistoricalReport,
    ...institution,
    ...institutionRigor,
    ...legacyGrading,
    ...letterGrade,
    ...loverb,
    ...logout,
    ...partSaga,
    ...partReports,
    ...partGradeSettingsSaga,
    ...partGroupSummary,
    ...program,
    ...resultCards,
    ...school,
    ...score,
    ...section,
    ...sectionPerformanceReport,
    ...source,
    ...syllabus,
    ...term,
    ...thirdPartyId,
    ...topic,
    ...user,
    ...userCohort,
    ...userDepartment,
    ...userGroup,
    ...userProgram,
    ...userSchool,
    ...userSection
  ]);
}

export default watchAll;
