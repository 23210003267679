import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_SCORES_SUCCESS,
  DELETE_SCORE_SUCCESS,
  DELETE_STUDENT_SCORES_SUCCESS,
  GET_ASSESSMENT_GRADES_SUCCESS,
  GET_COHORT_ASSESSMENT_SCORES_SUCCESS,
  GET_SCORES_SUCCESS,
  POST_SCORE_SUCCESS,
  PUT_SCORE_SUCCESS,
  GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS
} from '../actions-type';

class Score extends Model {
  static reducer(action, Score, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_COHORT_ASSESSMENT_SCORES_SUCCESS:
      case GET_SCORES_SUCCESS: {
        const scores = [...payload.scores];
        scores.forEach(score => Score.upsert(score));
        break;
      }
      case POST_SCORE_SUCCESS: {
        const { score } = payload;
        Score.upsert(score);
        break;
      }
      case PUT_SCORE_SUCCESS: {
        const { score } = payload;
        Score.upsert(score);
        break;
      }
      case DELETE_SCORE_SUCCESS: {
        const { scoreUuid } = payload;
        Score.withId(scoreUuid).delete();
        break;
      }
      case DELETE_STUDENT_SCORES_SUCCESS: {
        const { assessmentUuid, studentUuid, assessmentBlockUuid, attemptNumber, opportunityNumber } = payload;
        const attempt = attemptNumber || opportunityNumber;
        Score.all()
          .filter(
            score =>
              score.assessmentUuid === assessmentUuid &&
              score.studentUuid === studentUuid &&
              (!assessmentBlockUuid || score.assessmentBlockUuid === assessmentBlockUuid) &&
              (!attempt || score.attempt === attempt)
          )
          .delete();
        break;
      }
      case DELETE_SCORES_SUCCESS: {
        const { assessmentUuid } = payload;
        Score.all()
          .filter(score => score.assessmentUuid === assessmentUuid)
          .delete();
        break;
      }
      case GET_ASSESSMENT_GRADES_SUCCESS: {
        const { scores } = payload;

        if (scores?.length > 0) {
          scores.forEach(score => Score.upsert(score));
        }
        break;
      }
      case GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS: {
        const { resultCards } = payload;
        resultCards.forEach(resultCard => {
          const { scores } = resultCard;
          scores.forEach(score => Score.upsert(score));
        });
        break;
      }
      default:
        return session;
    }
  }
}

Score.fields = {
  uuid: attr(),
  attempt: attr(),
  date: attr(),
  tieBackID: attr(),
  rubricItem: attr(),
  stepSubItem: attr(),
  stepSubItemDescription: attr(),
  microcompetencyCode: attr(),
  relValue: attr(),
  potValue: attr(),
  studentID: attr(),
  grader1ID: attr(),
  grader2ID: attr(),
  grader3ID: attr(),
  assessmentUuid: fk('Assessment', 'scores'),
  studentUuid: fk('User', 'studentUuid'),
  grader1Uuid: fk('User', 'grader1Uuid'),
  grader2Uuid: fk('User', 'grader2Uuid'),
  grader3Uuid: fk('User', 'grader3Uuid'),
  resultCardUuid: fk('ResultCard', 'scores'),
  assessmentBlockUuid: fk('AssessmentBlock', 'scores')
};

Score.modelName = 'Score';

Score.options = {
  idAttribute: 'uuid'
};

export default Score;
