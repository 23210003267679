import { call, put, takeEvery } from 'redux-saga/effects';
import { getPartTopics } from '../../api/topicApi';

import {
  doGetPartTopicsError,
  doGetPartTopicsSuccess
} from '../actions/topicActions';

import { GET_PART_TOPICS } from '../actions-type';

function* handleGetPartTopics(action) {
  const { partUuid } = action.payload;
  try {
    const result = yield call(getPartTopics, partUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartTopicsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetPartTopicsError(error));
  }
}

const topic = [takeEvery(GET_PART_TOPICS, handleGetPartTopics)];

export { topic };
