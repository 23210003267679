export const PUT_ASSESSMENT_GRADING_TECHNIQUE =
  'PUT_ASSESSMENT_GRADING_TECHNIQUE';
export const PUT_ASSESSMENT_GRADING_TECHNIQUE_SUCCESS =
  'PUT_ASSESSMENT_GRADING_TECHNIQUE_SUCCESS';
export const PUT_ASSESSMENT_GRADING_TECHNIQUE_ERROR =
  'PUT_ASSESSMENT_GRADING_TECHNIQUE_ERROR';

export const PUT_GRADE_COLLECTION_GRADING_TECHNIQUE =
  'PUT_GRADE_COLLECTION_GRADING_TECHNIQUE';
export const PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_SUCCESS =
  'PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_SUCCESS';
export const PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_ERROR =
  'PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_ERROR';

export const PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE =
  'PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE';
export const PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_SUCCESS =
  'PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_SUCCESS';
export const PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_ERROR =
  'PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_ERROR';
