import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { Body1, Caption } from '@xcomp/xcomp-design-library';
import DeleteModal from '../../../Library/Modal/DeleteModal/DeleteModal';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import { Column } from '../../../Library/FormComponents';

const ProcedureCodeCategoryAccordion = styled(
  ({ withBottomBorder, withTopBorder, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  width: 100%;

  .align_right {
    margin-left: auto;

    span {
      font-size: 12px;
      letter-spacing: 0.4;
      color: ${props => props.theme.fontColors.dark};
    }
  }
`;

const CollectionCoursesLayout = styled.div`
  padding-left: 132px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  p {
    margin: 0;
  }
`;

const HoursColumn = styled(Column)`
  display: flex;
  justify-content: flex-end;
  padding-right: 97px;
`;

const CourseCollectionRow = ({
  courseCollection,
  isDeleteModalOpen,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete,
  withTopBorder
}) => {
  // eslint-disable-next-line prefer-const
  let history = useHistory();
  let rowPrefix;
  let totalCourseHoursPossible = 0;
  const { cohortUuid } = useParams();

  const navigateEditOnClick = () => {
    const historyString = `/program-management/cohort/${cohortUuid}/course-collection/${courseCollection.uuid}/edit`;
    history.push(historyString);
  };

  const courseCollectionActions = [
    {
      label: 'Edit',
      onClick: navigateEditOnClick,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  courseCollection.courses.forEach(course => {
    totalCourseHoursPossible += course.hours;
  });

  const requiredCreditsValue = courseCollection.all_credits_required
    ? totalCourseHoursPossible
    : courseCollection.credits_required;

  if (courseCollection.special_collection) {
    rowPrefix = 'Special';
  } else {
    rowPrefix = courseCollection.all_credits_required ? 'Required' : 'Elective';
  }

  return (
    <>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={handleDeleteModalClose}
        handleDelete={handleDelete}
        type="Course Collection"
      />
      <ProcedureCodeCategoryAccordion
        withTopBorder={withTopBorder}
        withBottomBorder
        expandWidth="66px"
        height="38px"
        columnOne={`${rowPrefix}: ${courseCollection.name}`}
        columnTwo={`Req Courses - ${
          courseCollection.courses.length
        }, Req Credits - ${
          courseCollection.all_credits_required
            ? totalCourseHoursPossible
            : `${requiredCreditsValue}/${totalCourseHoursPossible}`
        }`}
        columnTwoClassName="align_right"
        actions={courseCollectionActions}
      >
        <CollectionCoursesLayout>
          <Column size="70%">
            <Caption>Course</Caption>
          </Column>
          <HoursColumn size="30%">
            <Caption>Credits</Caption>
          </HoursColumn>
          {courseCollection &&
            courseCollection.courses &&
            courseCollection.courses.length > 0 &&
            courseCollection.courses.map(course => (
              <React.Fragment key={course.uuid}>
                <Column size="70%">
                  <Body1>
                    {course.courseNumber} - {course.title}
                  </Body1>
                </Column>
                <HoursColumn size="30%">
                  <Body1>{course.hours}</Body1>
                </HoursColumn>
              </React.Fragment>
            ))}
        </CollectionCoursesLayout>
      </ProcedureCodeCategoryAccordion>
    </>
  );
};

CourseCollectionRow.propTypes = {
  courseCollection: PropTypes.object.isRequired,
  withTopBorder: PropTypes.bool,
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func
};

CourseCollectionRow.defaultProps = {
  withTopBorder: false,
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined
};

export default CourseCollectionRow;
