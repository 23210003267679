import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MoreIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonIcon } from '@xcomp/xcomp-design-library';
import { roles } from '../../helpers/constants';

export default function CohortActions({ className, cohortUuid }) {
  const history = useHistory();
  const [anchorEl, SetAnchorEl] = useState(null);

  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  const selectedRole = useSelector(state => state.userState.selectedRole);
  const isAdmin = selectedRole === roles.programAdmin;

  const handleClick = event => {
    event.stopPropagation();
    SetAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    SetAnchorEl(null);
  };

  const linkToRequirements = () => {
    const historyString = `/program-management/cohort/${cohortUuid}/requirements`;
    history.push(historyString);
  };

  const linkToEdit = () => {
    const historyString = `/program-management/cohort/edit/${cohortUuid}`;
    history.push(historyString);
  };

  const linkToRoles = () => {
    const historyString = `/program-management/cohort/roles/applicants?programUuid=${selectedProgramUuid}&cohortUuid=${cohortUuid}`;
    history.push(historyString);
  };

  return (
    <div className={className}>
      <ButtonIcon
        aria-owns={anchorEl ? 'simple-menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
        noHoverBg
      >
        <MoreIcon />
      </ButtonIcon>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isAdmin && <MenuItem onClick={linkToEdit}>Edit</MenuItem> }
        <MenuItem onClick={linkToRoles}>Applicants & Students</MenuItem>
        {isAdmin && <MenuItem onClick={linkToRequirements}>Requirements</MenuItem>}
      </Menu>
    </div>
  );
}

CohortActions.propTypes = {
  className: PropTypes.string,
  cohortUuid: PropTypes.string
};

CohortActions.defaultProps = {
  className: '',
  cohortUuid: ''
};
