import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LockOutlined from '@material-ui/icons/LockOutlined';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../../../../helpers/format/user.format';
import {
  TableDataText
} from '../../../../PartGradeSummary/PartAssessmentCollectionReport/CollectionReportSC';
import SectionPerformanceStudentPartDataContainer from '../SectionPerformanceStudentPartDataContainer';
import { TableRow, FillerTd, RowInfoTitle, StickyDataTd } from './SharedSC';

import { studentCalculatedSectionGradeResultSelector } from '../../../../../../redux/selectors/calculatedGradeResultSelectors';
import {
  getCalculatedGradeDisplay,
  getLetterGradeDisplay
} from '../../../../../../helpers/format/performance_report.format';
import { doClearUpdatedAssessment } from '../../../../../../redux/actions/assessmentActions';
import {
  doDeleteSectionDelayedStudentGrade,
  doPatchSectionDelayedStudentGrade,
  doPutSectionDelayedStudentStatusCommit
} from '../../../../../../redux/actions/sectionActions';
import StatusCommitModalContainer from '../../../../../Library/Modal/StatusCommitModal/StatusCommitModalContainer';
import ActionButton from '../../../../../Library/AccordionRow/ActionButton';

export const InfoTdWithLockPadding = styled(({ greyOne, greyTwo, ...rest }) => (
  <td {...rest} />
))`
  min-width: 250px;
  max-width: 250px;
  min-height: 53px;
  padding-left: 8px;
  padding-right: 32px;
  z-index: 10;
  position: sticky;
  left: 225px;
  background-color: ${props =>
    (props.greyTwo && props.theme.colors.grey[200]) ||
    (props.greyOne && props.theme.colors.grey[100]) ||
    'white'};
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
`;

const StyledPublishedIcon = styled(LockOutlined)`
  && {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    height: 100%;
    odisplay: flex;
    align-item: center;
    font-size: 1.25rem;
    color: ${props => props.theme.fontColors.dark};
  }
`;

const getDelayedStudentActions = (
  dispatch,
  sectionUuid,
  studentUuid,
  calculatedGrade,
  delayedStatusCommit,
  justificationMessage,
  handleCommitModalOpen
) => {
  const isGraded = Boolean(calculatedGrade);
  const isCommitted = delayedStatusCommit === 'COMMITTED';

  const gradeDelayedStudent = (sectionUuid, studentUuid) =>
    dispatch(
      doPatchSectionDelayedStudentGrade({
        sectionUuid,
        studentUuid
      })
    );

  const ungradeDelayedStudent = (sectionUuid, studentUuid) =>
    dispatch(
      doDeleteSectionDelayedStudentGrade({
        sectionUuid,
        studentUuid
      })
    );

  const commitDelayedStudentGrade = (sectionUuid, studentUuid) =>
    dispatch(
      doPutSectionDelayedStudentStatusCommit({
        sectionUuid,
        studentUuid,
        delayedStatusCommit: 'COMMITTED',
        justificationMessage: null
      })
    );

  const uncommitDelayedStudentGrade = (
    sectionUuid,
    studentUuid,
    justificationMessage
  ) =>
    dispatch(
      doPutSectionDelayedStudentStatusCommit({
        sectionUuid,
        studentUuid,
        delayedStatusCommit: 'UNCOMMITTED',
        justificationMessage
      })
    );

  let delayedStudentActions = [];

  if (!isGraded) {
    delayedStudentActions = [
      {
        label: 'Grade This Student',
        onClick: () => gradeDelayedStudent(sectionUuid, studentUuid),
        shouldOpenOnClick: false,
        icon: 'assessment'
      }
    ];
  } else if (isGraded && !isCommitted) {
    delayedStudentActions = [
      {
        label: 'Ungrade This Student',
        onClick: () => ungradeDelayedStudent(sectionUuid, studentUuid),
        shouldOpenOnClick: false,
        icon: 'delete'
      },
      {
        label: 'Commit This Student',
        onClick: () => handleCommitModalOpen(),
        shouldOpenOnClick: false,
        icon: 'locked'
      }
    ];
  } else {
    delayedStudentActions = [
      {
        label: 'Uncommit This Student',
        onClick: () => handleCommitModalOpen(),
        shouldOpenOnClick: false,
        icon: 'unlock'
      }
    ];
  }

  return {
    delayedStudentActions,
    gradeDelayedStudent,
    ungradeDelayedStudent,
    commitDelayedStudentGrade,
    uncommitDelayedStudentGrade
  };
};

const DelayedStudentPerformanceTableRow = ({
  studentReportInfo,
  parts,
  useFillerColumn,
  fillerColumnWidth,
  idx
}) => {
  const dispatch = useDispatch();

  const [justificationMessage, setJustificationMessage] = useState('');
  const [isCommitModalOpen, setCommitModalOpen] = useState(false);

  const { sectionUuid } = useParams();
  const { studentUuid, user } = studentReportInfo;

  const calculatedGradeResult = useSelector(state =>
    studentCalculatedSectionGradeResultSelector(
      state,
      user?.studentUuid,
      sectionUuid
    )
  );

  const {
    calculatedGrade,
    gradeModification,
    letterGrade,
    delayedStatusCommit
  } = calculatedGradeResult;

  const { successfullyCommitted, successfullyUncommitted } = useSelector(
    state => state.assessmentStatusState,
    shallowEqual
  );

  const handleCommitModalOpen = () => {
    setCommitModalOpen(true);
  };

  const handleCommitModalClose = () => {
    setCommitModalOpen(false);
  };

  const handleJustificationChange = event => {
    setJustificationMessage(event.target.value);
  };

  useEffect(() => {
    if (successfullyCommitted || successfullyUncommitted) {
      dispatch(doClearUpdatedAssessment());
      setJustificationMessage('');
    }
  }, [dispatch, successfullyCommitted, successfullyUncommitted]);

  const {
    delayedStudentActions,
    commitDelayedStudentGrade,
    uncommitDelayedStudentGrade
  } = getDelayedStudentActions(
    dispatch,
    sectionUuid,
    studentUuid,
    calculatedGrade,
    delayedStatusCommit,
    justificationMessage,
    handleCommitModalOpen
  );

  const handleStatusCommitSubmit = () => {
    handleCommitModalClose();
    if (delayedStatusCommit === 'UNCOMMITTED') {
      commitDelayedStudentGrade(sectionUuid, studentUuid);
    } else {
      uncommitDelayedStudentGrade(
        sectionUuid,
        studentUuid,
        justificationMessage
      );
    }
  };

  const applicantFirstName = formatFirstNameMiddleName(
    user?.firstName,
    user?.middleName
  );
  const applicantLastName = formatLastName(
    user?.lastName,
    user?.marriedLastName
  );
  const applicantFullName = `${applicantLastName}, ${applicantFirstName}`;
  const unranked = calculatedGrade === null;
  const isDelayed = gradeModification === 'IP';
  const isWithdrew = gradeModification === 'W';
  const isInactiveStudent = isDelayed || isWithdrew;
  const isCredit = gradeModification === 'CR';

  const calculatedGradeDisplay = getCalculatedGradeDisplay(
    calculatedGrade,
    isDelayed,
    isWithdrew,
    isCredit
  );

  const letterGradeDisplay = getLetterGradeDisplay(
    letterGrade,
    unranked,
    isInactiveStudent,
    isCredit
  );

  const currentStatusCommitType =
    delayedStatusCommit === 'COMMITTED' ? 'Uncommit' : 'Commit';

  const modalTitle = `${currentStatusCommitType} Delayed Student Grade`;

  const messageOverrides = {
    title: modalTitle,
    successCommitted: 'Successfully committed delayed student grade',
    successUncommitted: 'Successfully uncommitted delayed student grade',
    confirmCommitment:
      'Committing this delayed student grade is permanent and cannot be undone without school admin access priveleges and a justification message. Are you sure you want to proceed?',
    confirmUncommitment:
      'Uncommitting this delayed student grade will remove any previously calculated grade. This must be justified by a school admin. Are you sure you want to proceed?'
  };

  const isCommitted = delayedStatusCommit === 'COMMITTED';

  return (
    <TableRow key={user?.uuid}>
      <StatusCommitModalContainer
        statusCommit={delayedStatusCommit}
        messages={messageOverrides}
        isModalOpen={isCommitModalOpen}
        handleModalClose={handleCommitModalClose}
        handleModalSuccess={undefined}
        handleSubmit={handleStatusCommitSubmit}
        justificationMessage={justificationMessage}
        handleJustificationChange={handleJustificationChange}
      />
      <StickyDataTd redOne={idx % 2 === 0} redTwo={idx % 2 !== 0} left="0px">
        <TableDataText>{calculatedGradeDisplay}</TableDataText>
      </StickyDataTd>
      <StickyDataTd redOne={idx % 2 === 0} redTwo={idx % 2 !== 0} left="75px">
        <TableDataText>{letterGradeDisplay}</TableDataText>
      </StickyDataTd>
      <StickyDataTd redOne={idx % 2 === 0} redTwo={idx % 2 !== 0} left="150px">
        <TableDataText>
          <ActionButton actions={delayedStudentActions} isRowOpen={false} />
        </TableDataText>
      </StickyDataTd>
      <InfoTdWithLockPadding greyOne={idx % 2 !== 0}>
        <RowInfoTitle title={`${applicantFullName}`} placement="top">
          <div>{`${applicantFullName}`}</div>
        </RowInfoTitle>
        {isCommitted && <StyledPublishedIcon />}
      </InfoTdWithLockPadding>
      {parts.map(part => (
        <SectionPerformanceStudentPartDataContainer
          partUuid={part.uuid}
          rowIndex={idx}
          key={part.uuid}
          studentUuid={user?.uuid}
        />
      ))}
      {useFillerColumn && (
        <FillerTd greyOne={idx % 2 !== 0} borderTop width={fillerColumnWidth} />
      )}
    </TableRow>
  );
};

DelayedStudentPerformanceTableRow.propTypes = {
  studentReportInfo: PropTypes.object.isRequired,
  parts: PropTypes.array,
  useFillerColumn: PropTypes.bool,
  fillerColumnWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  idx: PropTypes.number.isRequired
};

DelayedStudentPerformanceTableRow.defaultProps = {
  parts: [],
  useFillerColumn: false,
  fillerColumnWidth: 0
};

export default DelayedStudentPerformanceTableRow;
