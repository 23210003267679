import React from 'react';
import styled from 'styled-components';
import {
  Subtitle2,
  Body1,
  Caption,
  Heading3
} from '@xcomp/xcomp-design-library';
import {
  ProfileIcon,
  LocationIcon
} from '@xcomp/xcomp-design-library/dist/icons';
import Overline from './Overline';

export const MonthHeading = styled.div`
  padding: 0.5rem 1rem;
  border-bottom: 2px solid ${props => props.theme.colors.grey[300]};

  h5 {
    margin: 0;
  }
`;

export const ItemLayout = styled.div`
  min-height: 325px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 2px solid ${props => props.theme.colors.grey[300]};
`;

export const ItemLeft = styled(({ borderColor, ...rest }) => <div {...rest} />)`
  width: 50%;
  display: flex;
  flex-direction: column;
  border-left: 5px solid
    ${props => props.borderColor || props.theme.colors.secondary};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  padding: 20px;
`;

export const ItemRight = styled(({ borderColor, ...rest }) => (
  <div {...rest} />
))`
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ItemType = styled(Subtitle2)`
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 12px;
`;

export const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

export const ItemDetail = styled(Body1)`
  margin-left: 10px;
  color: ${props => props.theme.fontColors.darker};
  margin: 0;
`;

export const DetailsListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;

  svg {
    margin-right: 8px;
  }
`;

export const Title = styled(Heading3)`
  color: ${props => props.theme.fontColors.darker};
  margin: 0;
`;

export const Faculty = styled(ProfileIcon)`
  && {
    font-size: 16px;
  }
`;

export const Location = styled(LocationIcon)`
  && {
    font-size: 16px;
  }
`;

export const Manager = styled(ProfileIcon)`
  && {
    font-size: 16px;
  }
`;

export const ScrollPanelLabel = styled(Caption)`
  position: absolute;
  top: -8px;
  left: 5px;
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
  background: ${props => props.theme.colors.white};
`;

export const ViewMore = styled.div`
  position: absolute;
  bottom: 4px;
  right: 10px;
  background: ${props => props.theme.colors.white};

  svg {
    font-size: 18px;
  }
`;

export const Body1Dark = styled(Body1)`
  color: ${props => props.theme.fontColors.darker};
  margin: 0;
`;

export const OverlineSC = styled(Overline)`
  margin: 0;
  line-height: 1.6;
  font-size: 0.75rem;
  font-weight: 400;
`;

export const LinkedItem = styled.div`
  width: 100%;
  align-self: flex-end;
`;

export const Label = styled(Caption)`
  color: ${props => props.theme.fontColors.dark};
  margin: 0;
`;
