import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
import { doGetSchool } from '../../../redux/actions/schoolActions';
import { doGetUsersSchool } from '../../../redux/actions/userSchoolActions';
import { userRoleSelectorBySchool } from '../../../redux/selectors/userRolesSelectors';
import { schoolSelector } from '../../../redux/selectors/schoolSelectors';

import SchoolRole from './SchoolRole';

export default function SchoolRoleContainer({ match }) {
  const dispatch = useDispatch();
  const { schoolUuid } = match.params;
  const [filterActive, setFilterActive] = useState(false);
  const [filterComplete, setFilterComplete] = useState(false);
  const [userRoles, setUserRoles] = useState([]);

  const getUserRoles = useSelector(
    state => userRoleSelectorBySchool(state, schoolUuid),
    _.isEqual
  );

  const school = useSelector(
    state => schoolSelector(state, schoolUuid),
    shallowEqual
  );

  useEffect(() => {
    dispatch(doGetSchool(schoolUuid));
    dispatch(doGetUsersSchool(schoolUuid));
  }, [dispatch, schoolUuid, getUserRoles]);

  useEffect(() => {
    if (getUserRoles.length > 0) {
      setUserRoles(getUserRoles);
    }
  }, [getUserRoles]);

  const filterUserByStatus = () => {
    const selectedUser = [];
    if (filterActive) {
      const activeSelected = _.filter(
        userRoles,
        user => user.status === 'active'
      );

      selectedUser.push(activeSelected);
    }

    if (filterComplete) {
      const completeSelected = _.filter(
        userRoles,
        user => user.status === 'complete'
      );

      selectedUser.push(completeSelected);
    }

    if (!filterActive && !filterComplete) {
      selectedUser.push(userRoles);
    }

    return _.flatten(selectedUser);
  };

  const handleFilterSelection = selection => {
    if (selection === 'active') {
      setFilterActive(!filterActive);
    }

    if (selection === 'complete') {
      setFilterComplete(!filterComplete);
    }
  };

  return (
    <SchoolRole
      school={school}
      userRoles={filterUserByStatus()}
      filterActive={filterActive}
      filterComplete={filterComplete}
      handleFilterSelection={handleFilterSelection}
    />
  );
}

SchoolRoleContainer.propTypes = {
  match: PropTypes.object
};

SchoolRoleContainer.defaultProps = {
  match: {}
};
