import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_LETTER_GRADE_SUCCESS,
  GET_COURSE_BY_STUDENT_SUCCESS,
  GET_LETTER_GRADES_BY_SCHOOL_SUCCESS,
  POST_LETTER_GRADE_SUCCESS,
  PUT_LETTER_GRADE_SUCCESS
} from '../actions-type';

class LetterGrade extends Model {
  static reducer(action, LetterGrade, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_LETTER_GRADES_BY_SCHOOL_SUCCESS: {
        const letterGrades = [...payload.letterGrades];
        letterGrades.forEach(letterGrade => LetterGrade.upsert(letterGrade));
        break;
      }
      case POST_LETTER_GRADE_SUCCESS: {
        const letterGrade = { ...payload.letterGrade };
        LetterGrade.upsert(letterGrade);
        break;
      }
      case PUT_LETTER_GRADE_SUCCESS: {
        const letterGrade = { ...payload.letterGrade };
        LetterGrade.upsert(letterGrade);
        break;
      }
      case DELETE_LETTER_GRADE_SUCCESS: {
        const { letterGradeUuid } = payload;
        LetterGrade.withId(letterGradeUuid).delete();
        break;
      }
      case GET_COURSE_BY_STUDENT_SUCCESS: {
        const courses = [...payload.courses];
        courses.forEach(course => {
          const { course_master } = course;
          const { department } = course_master;
          const { school } = department;
          const { letter_grades } = school;
          letter_grades.forEach(letterGrade => {
            LetterGrade.upsert(letterGrade);
          });
        });
        break;
      }
      default:
        return session;
    }
  }
}

LetterGrade.fields = {
  uuid: attr(),
  grade: attr(),
  rangeLow: attr(),
  rangeHigh: attr(),
  conversion: attr(),
  creditMultiplier: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  schoolUuid: fk('School', 'letter_grades')
};

LetterGrade.modelName = 'LetterGrade';
LetterGrade.options = {
  idAttribute: 'uuid'
};

export default LetterGrade;
