import { Model, attr, fk } from 'redux-orm';
import {
  GET_COMPETENCY_COLLECTION_SUCCESS,
  GET_COMPETENCY_COLLECTIONS_SUCCESS,
  POST_COMPETENCY_COLLECTION_SUCCESS,
  PUT_COMPETENCY_COLLECTION_SUCCESS,
  DELETE_COMPETENCY_COLLECTION_SUCCESS,
  GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS
} from '../actions-type';

class CompetencyCollection extends Model {
  static reducer(action, CompetencyCollection, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_COMPETENCY_COLLECTION_SUCCESS: {
        const { competencyCollection } = payload;
        return CompetencyCollection.upsert(competencyCollection);
      }
      case GET_COMPETENCY_COLLECTIONS_SUCCESS: {
        const competencyCollections = [...payload.competencyCollections];
        return competencyCollections.map(competencyCollection => {
          return CompetencyCollection.upsert(competencyCollection);
        });
      }
      case POST_COMPETENCY_COLLECTION_SUCCESS: {
        const { createdCompetencyCollection } = payload;
        return CompetencyCollection.upsert(createdCompetencyCollection);
      }
      case PUT_COMPETENCY_COLLECTION_SUCCESS: {
        const { updatedCompetencyCollection } = payload;
        return CompetencyCollection.upsert(updatedCompetencyCollection);
      }
      case DELETE_COMPETENCY_COLLECTION_SUCCESS: {
        const { deletedCompetencyCollection } = payload;
        return CompetencyCollection.withId(
          deletedCompetencyCollection.uuid
        ).delete();
      }
      case GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS: {
        const { competencyCollection } = payload;

        if (competencyCollection?.length > 0) {
          competencyCollection.forEach(collection => {
            const { name, programUuid, uuid } = collection;
            CompetencyCollection.upsert({ uuid, programUuid, name });
          });
        }
        break;
      }
      default:
        return session;
    }
  }
}

CompetencyCollection.fields = {
  uuid: attr(),
  name: attr(),
  is_default_collection: attr(),
  cohortUuid: fk('Cohort', 'competencyCollections'),
  createdAt: attr(),
  updatedAt: attr()
};

CompetencyCollection.modelName = 'CompetencyCollection';
CompetencyCollection.options = {
  idAttribute: 'uuid'
};

export default CompetencyCollection;
