import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton
} from '@material-ui/core';
import moment from 'moment';
import { EditIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { Link } from 'react-router-dom';
import TermShowContainer from './TermShow/TermShowContainer';

const AccordionDetailsSC = styled(AccordionDetails)`
  flex-wrap: wrap;
`;

const PositionRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function AcademicYearShow({ academicYear }) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid container direction="row" alignItems="center">
          <Grid item sm={5} xs={12}>
            {`${moment(academicYear.startDate).format('YYYY')}-${moment(
              academicYear.endDate
            ).format('YYYY')}`}
          </Grid>
          <Grid item sm={3} xs={12}>
            {moment(academicYear.startDate).format('LL')}
          </Grid>
          <Grid item sm={3} xs={12}>
            {moment(academicYear.endDate).format('LL')}
          </Grid>
          <Grid item sm={1} xs={12}>
            <PositionRight>
              <Link
                to={`/school-management/academic-year-term/edit/${academicYear.uuid}`}
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Link>
            </PositionRight>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetailsSC>
        <TermShowContainer academicYearUuid={academicYear.uuid} />
      </AccordionDetailsSC>
    </Accordion>
  );
}

AcademicYearShow.propTypes = {
  academicYear: PropTypes.object
};

AcademicYearShow.defaultProps = {
  academicYear: {}
};
