import {
  POST_INSTITUTION_RIGOR,
  POST_INSTITUTION_RIGOR_SUCCESS,
  POST_INSTITUTION_RIGOR_ERROR
} from '../actions-type';

const doPostInstitutionRigor = (
  cohorts,
  programUuid,
  cohortStartUuid,
  cohortEndUuid
) => ({
  type: POST_INSTITUTION_RIGOR,
  payload: {
    cohorts,
    programUuid,
    cohortStartUuid,
    cohortEndUuid
  }
});

const doPostInstitutionRigorSuccess = result => ({
  type: POST_INSTITUTION_RIGOR_SUCCESS,
  payload: result
});

const doPostInstitutionRigorError = error => ({
  type: POST_INSTITUTION_RIGOR_ERROR,
  payload: error
});

export {
  doPostInstitutionRigor,
  doPostInstitutionRigorSuccess,
  doPostInstitutionRigorError
};
