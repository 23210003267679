import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { selectOverallAptitudeAvgScore } from '../../../../redux/selectors/transcriptAptitudeSelectors';

export default function AptitudeAverageScore({
  programUuid,
  userUuid,
  cohortUuid,
  category
}) {
  const overallAptitudeScoreAverage = useSelector(state =>
    selectOverallAptitudeAvgScore(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      category
    )
  );

  const score = overallAptitudeScoreAverage || 'N/A';

  return <>{score}</>;
}

AptitudeAverageScore.propTypes = {
  category: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string
};

AptitudeAverageScore.defaultProps = {
  category: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined
};
