import {
  GET_COHORT_ASSESSMENT_GRID,
  GET_COHORT_ASSESSMENT_GRID_ERROR,
  GET_COHORT_ASSESSMENT_GRID_SUCCESS,
  SET_ASSESSMENT_GRID_ZOOM,
  SET_SHOW_ASSESSMENT_GRID_MODAL
} from "../actions-type/assessmentGridActionTypes";

const doGetCohortAssessmentGrid = (cohortUuid) => ({
  type: GET_COHORT_ASSESSMENT_GRID,
  payload: {
    cohortUuid
  }
});

const doGetCohortAssessmentGridError = error => ({
  type: GET_COHORT_ASSESSMENT_GRID_ERROR,
  payload: error
});

const doGetCohortAssessmentGridSuccess = result => ({
  type: GET_COHORT_ASSESSMENT_GRID_SUCCESS,
  payload: result
});

const doSetAssessmentGridZoom = zoom => ({
  type: SET_ASSESSMENT_GRID_ZOOM,
  payload: { zoom }
});

const doSetShowAssessmentGridModal = (showTableModal) => ({
  type: SET_SHOW_ASSESSMENT_GRID_MODAL,
  payload: { showTableModal }
});

export {
  doGetCohortAssessmentGrid,
  doGetCohortAssessmentGridError,
  doGetCohortAssessmentGridSuccess,
  doSetAssessmentGridZoom,
  doSetShowAssessmentGridModal
}