import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { StickyTitleTd, TableDataText, PointsTd } from '../CollectionReportSC';
import { TableRow, StickyInfoTd, FillerTd, StudentInfoText } from './SharedSC';

const CollectionDetailsRow = ({
  assessmentCollections,
  isExpanded,
  useFillerColumn,
  fillerColumnWidth
}) => {
  return (
    <TableRow>
      <StickyTitleTd redTwo left="0px" borderRight>
        <TableDataText />
      </StickyTitleTd>
      <StickyTitleTd redTwo left="75px" borderRight>
        <TableDataText />
      </StickyTitleTd>
      <StickyTitleTd redTwo left="150px" borderRight>
        <TableDataText />
      </StickyTitleTd>
      <StickyInfoTd greyOne left="225px" borderRightStrong>
        <StudentInfoText />
      </StickyInfoTd>
      {assessmentCollections.map(assessmentCollection => {
        const weightText =
          assessmentCollection.low_grades_dropped === null
            ? `${assessmentCollection.weight}%`
            : `*${assessmentCollection.weight}%`;
        return (
          <Fragment key={assessmentCollection.uuid}>
            <PointsTd blueTwo>
              <TableDataText>
                {assessmentCollection.weight
                  ? `${assessmentCollection.weight}%`
                  : '--'}
              </TableDataText>
            </PointsTd>
            <PointsTd
              displayNone={
                !isExpanded || assessmentCollection.scoreType === 'SCORE'
              }
              blueTwo
            >
              <TableDataText>--</TableDataText>
            </PointsTd>
            {assessmentCollection.collection_assessments.length > 0 &&
              assessmentCollection.collection_assessments.map(
                collectionAssessment => (
                  <PointsTd
                    key={collectionAssessment.uuid}
                    displayNone={!isExpanded}
                    greyOne
                  >
                    <TableDataText secondaryFontColor>
                      {weightText}
                    </TableDataText>
                  </PointsTd>
                )
              )}
          </Fragment>
        );
      })}
      {useFillerColumn && (
        <FillerTd width={fillerColumnWidth} borderTop greyOne />
      )}
    </TableRow>
  );
};

CollectionDetailsRow.propTypes = {
  assessmentCollections: PropTypes.array,
  isExpanded: PropTypes.bool.isRequired,
  useFillerColumn: PropTypes.bool.isRequired,
  fillerColumnWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

CollectionDetailsRow.defaultProps = {
  assessmentCollections: []
};

export default CollectionDetailsRow;
