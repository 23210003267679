import { Model, attr, fk } from 'redux-orm';
import {
  GET_ASSESSMENT_BLOCKS_SUCCESS,
  GET_ASSESSMENT_GRADES_SUCCESS,
  GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS,
  DELETE_SCORES_SUCCESS
} from '../actions-type';

class AssessmentBlock extends Model {
  static reducer(action, AssessmentBlock, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_ASSESSMENT_BLOCKS_SUCCESS: {
        const { assessmentBlocks } = payload;
        assessmentBlocks.forEach(assessmentBlock =>
          AssessmentBlock.upsert(assessmentBlock)
        );
        break;
      }
      case DELETE_SCORES_SUCCESS: {
        const { assessmentUuid } = payload;
        AssessmentBlock.all()
          .filter(
            assessmentBlock => assessmentBlock.assessmentUuid === assessmentUuid
          )
          .delete();
        break;
      }
      case GET_ASSESSMENT_GRADES_SUCCESS: {
        const { assessmentBlocks } = payload;

        if (assessmentBlocks?.length > 0) {
          assessmentBlocks.forEach(assessmentBlock => {
            const copy = { ...assessmentBlock };

            delete copy.assessment_block_results;
            delete copy.scores;

            AssessmentBlock.upsert(copy);
          });
        }
        break;
      }
      case GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS: {
        const { resultCards, cohortAssessmentCollections } = payload;

        cohortAssessmentCollections.forEach(collection =>
          collection.cohort_assessments.forEach(assessment => {
            const { assessment_blocks } = assessment;

            assessment_blocks.forEach(assessmentBlock =>
              AssessmentBlock.upsert(assessmentBlock)
            );
          })
        );

        resultCards.forEach(resultCard => {
          const { assessment } = resultCard;

          const { assessment_blocks } = assessment;

          assessment_blocks.forEach(block => {
            const {
              uuid,
              title,
              blockNumber,
              createdAt,
              updatedAt,
              assessmentUuid
            } = block;

            AssessmentBlock.upsert({
              uuid,
              title,
              blockNumber,
              createdAt,
              updatedAt,
              assessmentUuid
            });
          });
        });

        break;
      }
      default:
        return session;
    }
  }
}

AssessmentBlock.fields = {
  uuid: attr(),
  blockNumber: attr(),
  title: attr(),
  assessmentUuid: fk('Assessment', 'assessmentUuid'),
  createdAt: attr(),
  updatedAt: attr()
};

AssessmentBlock.modelName = 'AssessmentBlock';

AssessmentBlock.options = {
  idAttribute: 'uuid'
};

export default AssessmentBlock;
