import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import CognitiveMilestoneRowHeader from '../DataRows/CognitiveMilestoneRowHeader';
import CognitiveMilestoneRowEdit from '../DataRows/CognitiveMilestoneRowEdit';
import CognitiveMilestoneAttemptScore from '../../TranscriptCalculations/CognitiveMilestoneAttemptScore';
import CognitiveMilestoneAttemptGrade from '../../TranscriptCalculations/CognitiveMilestoneAttemptGrade';
import CognitiveMilestoneAttemptCredits from '../../TranscriptCalculations/CognitiveMilestoneAttemptCredits';

const Accordion = styled(({ ...rest }) => (
  <AccordionRowContainer {...rest} />
))``;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.grey[500]};
  font-size: 14px;
`;

const NoRecords = styled.div`
  padding-left: 200px;
`;

const Calculations = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const Spacing = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
`;

const Stub = styled.div`
  width: 66px;
`;

const Layout = styled.div`
  width: 100%;
  font-size: 12px;
`;

export default function ByDefaultAttempt({
  userUuid,
  cohortUuid,
  programUuid,
  attempt,
  transcripts,
  category,
  source,
  isAdmin
}) {
  
  return (
    <>
      <Accordion
        rowOffset="66px"
        expandWidth="66px"
        height="70px"
        label={
          <Group>
            <Label>Attempt {attempt}</Label>
          </Group>
        }
        columnOneClassName="column_one"
        columnOne={
          <Calculations>
            <Spacing>&nbsp;</Spacing>
            <Spacing>
              <CognitiveMilestoneAttemptCredits
                programUuid={programUuid}
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                category={category}
                source={source}
                attempt={attempt}
              />
            </Spacing>
            <Spacing>
              <CognitiveMilestoneAttemptGrade
                programUuid={programUuid}
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                category={category}
                source={source}
                attempt={attempt}
              />
            </Spacing>
            <Spacing>
              <CognitiveMilestoneAttemptScore
                programUuid={programUuid}
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                category={category}
                source={source}
                attempt={attempt}
              />
            </Spacing>
            <Stub>&nbsp;</Stub>
          </Calculations>
        }
      >
        <Layout>
          {transcripts?.length > 0 && source === 'Milestone' && (
            <CognitiveMilestoneRowHeader />
          )}

          {transcripts?.length > 0 && source === 'Milestone' ? (
            _.sortBy(transcripts, [
              'date',
              'itemCode'
            ]).map((transcript, index) => (
              <CognitiveMilestoneRowEdit
                key={transcript.uuid}
                index={index}
                transcript={transcript}
                userUuid={userUuid}
                cohortUuid={cohortUuid}
                programUuid={programUuid}
                category={category}
                source={source}
                isAdmin={isAdmin}
              />
            ))
          ) : (
            <NoRecords>N/A</NoRecords>
          )}
        </Layout>
      </Accordion>
    </>
  );
}

ByDefaultAttempt.propTypes = {
  attempt: PropTypes.number,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  transcripts: PropTypes.array,
  category: PropTypes.string,
  source: PropTypes.string,
  isAdmin: PropTypes.bool,
};

ByDefaultAttempt.defaultProps = {
  attempt: 0,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  transcripts: undefined,
  category: undefined,
  source: undefined,
  isAdmin: false,
};
