import React, { useMemo, forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Switch, Route, Link } from 'react-router-dom';
import Header from '../../Library/Header';
import RevusContainer from './Revus/RevusContainer';
import AcademicYearContainer from './AcademicYear/AcademicYearContainer';
import EducationLevelsContainer from './EducationLevels/EducationLevelsContainer';
import LetterGradesContainer from './LetterGrades/LetterGradesContainer';
import { ContainerResize } from '../../Library/Layout';
import LinkedDenominators from './LinkedDenominators/LinkedDenominators';

const TabsSC = styled(Tabs)`
  && {
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
    background: ${props => props.theme.colors.white};
    padding-top: 75px;
  }
`;

const TabSC = styled(Tab)`
  && {
    max-width: 100%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    line-height: 16px;
    width: 100%;
  }
`;

const LinkSC = styled(Link)`
  width: 100%;
`;

export default function SchoolDefinitions({ path, pathname }) {
  let location = 0;

  if (pathname === '/school-management/definitions') {
    location = 0;
  } else if (pathname === '/school-management/definitions/linked-denominators') {
    location = 1;
  } else if (pathname === '/school-management/definitions/academicyearterm') {
    location = 2;
  } else if (pathname === '/school-management/definitions/educationlevels') {
    location = 3;
  } else if (pathname === '/school-management/definitions/lettergrade') {
    location = 4;
  }

  const renderLink = useMemo(
    () =>
      forwardRef((props, ref) => (
        // eslint-disable-next-line react/prop-types
        <LinkSC to={props.topath} {...props} ref={ref} />
      )),
    []
  );

  return (
    <ContainerResize>
      <Header
        title="Definitions"
        subtitle="Manage definitions, add and delete"
        backOn={false}
      />
      <TabsSC
        value={location}
        textColor="primary"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <TabSC component={renderLink} topath={`${path}/revus`} label="revus" />
        <TabSC component={renderLink} topath={`${path}/linked-denominators`} label="linked denominators" />
        <TabSC
          component={renderLink}
          topath={`${path}/academicyearterm`}
          label="academic year/term"
        />

        <TabSC
          component={renderLink}
          topath={`${path}/educationlevels`}
          label="education levels"
        />

        <TabSC
          component={renderLink}
          topath={`${path}/lettergrade`}
          label="letter grades"
        />
      </TabsSC>

      <Switch>
        <Route
          exact
          path={`${path}/revus`}
          component={props => {
            return <RevusContainer {...props} />;
          }}
        />
        <Route
          exact
          path={`${path}/linked-denominators`}
          component={props => {
            return <LinkedDenominators {...props} />;
          }}
        />
        <Route
          path={`${path}/academicyearterm`}
          component={props => {
            return <AcademicYearContainer {...props} />;
          }}
        />
        <Route
          path={`${path}/educationlevels`}
          component={props => {
            return <EducationLevelsContainer {...props} />;
          }}
        />
        <Route
          path={`${path}/lettergrade`}
          component={props => {
            return <LetterGradesContainer {...props} />;
          }}
        />
      </Switch>
    </ContainerResize>
  );
}

SchoolDefinitions.propTypes = {
  path: PropTypes.string,
  pathname: PropTypes.string
};

SchoolDefinitions.defaultProps = {
  path: '',
  pathname: ''
};
