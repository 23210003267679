import {
  GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION,
  GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION_ERROR,
  GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION_SUCCESS
} from '../actions-type';

const doGetCalculatedPartGradeResultsBySection = sectionUuid => ({
  payload: { sectionUuid },
  type: GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION
});

const doGetCalculatedPartGradeResultsBySectionError = error => ({
  payload: { error: { message: error } },
  type: GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION_ERROR
});

const doGetCalculatedPartGradeResultsBySectionSuccess = result => ({
  payload: result,
  type: GET_CALCULATED_PART_GRADE_RESULTS_BY_SECTION_SUCCESS
});

export {
  doGetCalculatedPartGradeResultsBySection,
  doGetCalculatedPartGradeResultsBySectionError,
  doGetCalculatedPartGradeResultsBySectionSuccess
};
