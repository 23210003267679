import { Model, attr, fk } from 'redux-orm';

import {
  GET_COURSE_MASTER_SUCCESS,
  POST_COURSE_MASTER_SUCCESS,
  PUT_COURSE_MASTER_SUCCESS
} from '../actions-type';

class CourseMasterDepartment extends Model {
  static reducer(action, CourseMasterDepartment, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_COURSE_MASTER_SUCCESS: {
        const courseMasterDepartments = [...payload.courseMasterDepartment];
        courseMasterDepartments.forEach(courseMasterDepartment =>
          CourseMasterDepartment.upsert(courseMasterDepartment)
        );
        break;
      }
      case POST_COURSE_MASTER_SUCCESS: {
        const courseMasterDepartments = [...payload.courseMasterDepartment];
        courseMasterDepartments.forEach(courseMasterDepartment =>
          CourseMasterDepartment.upsert(courseMasterDepartment)
        );
        break;
      }
      case PUT_COURSE_MASTER_SUCCESS: {
        const aliasRemoval = [...payload.aliasRemoval];

        if (aliasRemoval.length > 0) {
          aliasRemoval.forEach(alias =>
            CourseMasterDepartment.withId(alias.uuid).delete()
          );
        }

        const courseMasterDepartment = { ...payload.alias };
        CourseMasterDepartment.upsert(courseMasterDepartment);
        break;
      }
      default:
        return session;
    }
  }
}

CourseMasterDepartment.fields = {
  uuid: attr(),
  aliasUuid: attr(),
  departmentUuid: fk('Department', 'alias'),
  courseMasterUuid: fk('CourseMaster', 'alias'),
  createdAt: attr(),
  updatedAt: attr()
};

CourseMasterDepartment.modelName = 'CourseMasterDepartment';

CourseMasterDepartment.options = {
  idAttribute: 'uuid'
};

export default CourseMasterDepartment;
