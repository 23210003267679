import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ClickAwayListener,
  Slider,
  Tooltip,
  useMediaQuery
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import ZoomIn from '@material-ui/icons/ZoomIn';
import AllOut from '@material-ui/icons/AllOut';
import Score from '@material-ui/icons/Score';
import Group from '@material-ui/icons/Group';
import Person from '@material-ui/icons/Person';
import Functions from '@material-ui/icons/Functions';
import Assignment from '@material-ui/icons/Assignment';
import FilterList from '@material-ui/icons/FilterList';
import styled, { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import StdDevLegend from './CollectionReportTable/StdDevLegend';
import {
  doSetShowTableModal,
  doSetTableZoom
} from '../../../redux/actions/competencyGridActions';
import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../helpers/format/user.format';
import FilterDropdown from './FilterAutocomplete';
import { clickOnFilter } from '../reportUtils';

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid lightgray;
  position: sticky;
  top: 0;
  z-index: 9999999999;
  box-shadow: 0 0px 8px -2px gray;
  background: ${props => props.theme.colors.white};
  border-radius: 5px 5px 0 0;

  ${props =>
    props.isMobile &&
    `
    & span, div {
      font-size: 13px !important;
    }
  `}
`;

const TableSwitch = styled.div`
  display: flex;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 2px;
  height: 44px;
`;

const Divider = styled.div`
  border-left: 2px solid gray;
  height: 40px;
  margin: 0 15px;
`;

const Switches = styled.div`
  display: flex;

  & .Mui-focused + .MuiAutocomplete-popper {
    transform: scale(1) !important;
  }
`;

const TableToggle = styled.div`
  padding: 5px;
  cursor: pointer;
  background: ${props =>
    props.selected
      ? props.theme.colors.primaryShades[250]
      : props.theme.colors.white};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    !props.selected &&
    `&:hover {
      background: ${props.theme.colors.primaryShades[100]};
    }`};
`;

const CloseIcon = styled(Close)`
  margin-left: auto;
  margin-right: 5px;
  height: 30px !important;
  width: 30px !important;
  cursor: pointer;
`;

const ZoomSlider = styled(Slider)`
  width: 200px !important;
  margin: 0 15px 15px;

  & .MuiSlider-valueLabel {
    top: 33px;

    & > span {
      border-radius: 50% 0 50% 50%;
    }
  }

  & .MuiSlider-markLabel {
    font-size: 10px;
  }
`;

const TooltipTitle = styled.span`
  font-size: 13px;
`;

const ZoomTooltip = styled(Tooltip)`
  & .MuiTooltip-popper {
    pointer-events: all;
  }
`;

const IconButton = styled(Button)`
  min-width: 40px !important;
  width: 44px;
  height: 44px;
  padding: 1px !important;
  margin-right: 10px !important;
`;

const ZoomIconButton = styled(IconButton)`
  margin-right: 0 !important;

  & + .MuiTooltip-popper > .MuiTooltip-tooltip {
    background: ${props => props.theme.colors.grey[200]} !important;
    box-shadow: 0px 4px 5px -1px gray;
  }
}
`;

const IconFilterButton = styled(Button)`
  min-width: 0px !important;
  padding: 1px !important;
  transition:
    transform 0.5s ease-in-out,
    width 0.5s ease-in-out,
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transform-origin: top right;
  justify-content: start !important;
`;

const zoomSliderMarks = [
  { value: 80, label: '80%' },
  { value: 85 },
  { value: 90 },
  { value: 95 },
  { value: 100, label: '100%' },
  { value: 105 },
  { value: 110 },
  { value: 115 },
  { value: 120, label: '120%' }
];

const AllOption = {
  fullName: 'All',
  uuid: null
};

const AnonymousIcon = () => {
  return (
    <span className="MuiButton-label">
      <svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path d="M 12 1 z m 1 17 h -2 v -2 h 2 v 2 z m -1 -3.5 c -2.67 0 -8 1.34 -8 4 v 2 h 16 v -2 c 0 -2.66 -5.33 -4 -8 -4 z m 3.07 -5.25 l -0.9 0.92 c -0.72 0.73 -1.17 1.33 -1.17 2.83 h -2 v -0.5 c 0 -1.1 0.45 -2.1 1.17 -2.83 l 1.24 -1.26 c 0.37 -0.36 0.59 -0.86 0.59 -1.41 c 0 -1.1 -0.9 -2 -2 -2 s -2 0.9 -2 2 H 8 c 0 -2.21 2 -4 4 -4 s 4 1.79 4 4 c 0 0.88 -0.36 1.68 -0.93 2.25 z" />
      </svg>
    </span>
  );
};

const GridModalHeader = ({
  isExpanded,
  showCohort,
  toggleExpanded,
  showScores,
  setShowCohort,
  setShowScores,
  students,
  filteredStudents,
  setFilteredStudents,
  shouldAnonymizeReport,
  competencyCollections,
  filteredCompetencies,
  setFilteredCompetencies,
  setShouldAnonymizeReport,
  showTotals,
  setShowTotals,
  isCompetencyFilterActive,
  isStudentFilterActive
}) => {
  const dispatch = useDispatch();
  const zoom = useSelector(state => state.cohortCompetencyReportState.zoom);
  const [openZoom, setOpenZoom] = useState(false);
  const [openStudentFilter, setOpenStudentFilter] = useState(false);
  const [openCompetencyFilter, setOpenCompetencyFilter] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const themeContext = useContext(ThemeContext);

  const studentOptions = useMemo(() => {
    const studentsForFilter = students.map(student => {
      const studentFormattedLastName = formatLastName(
        student.user?.lastName,
        student.user?.marriedLastName,
        student.user?.suffix
      );
      const studentFormattedFirstMiddleName = formatFirstNameMiddleName(
        student.user?.firstName,
        student.user?.middleName
      );
      const studentFullName =
        student.user?.firstName && student.user?.lastName
          ? `${studentFormattedLastName}, ${studentFormattedFirstMiddleName}`
          : 'N/A';
      const sudentInstitutionId = student?.user?.institutionId;

      return {
        fullName: shouldAnonymizeReport ? sudentInstitutionId : studentFullName,
        uuid: student.userUuid
      };
    });

    return [AllOption, ...studentsForFilter];
  }, [students, shouldAnonymizeReport]);

  const competencyOptions = useMemo(() => {
    const competencies = isExpanded
      ? competencyCollections.reduce(
          (acc, curr) => [...acc, ...curr.competencies],
          []
        )
      : competencyCollections;

    return [
      AllOption,
      ...competencies.map(c => ({ uuid: c.uuid, fullName: c.name }))
    ];
  }, [competencyCollections, isExpanded]);

  useEffect(() => {
    setFilteredStudents(studentOptions);
  }, [studentOptions, setFilteredStudents]);

  useEffect(() => {
    setFilteredCompetencies(competencyOptions);
  }, [competencyOptions, setFilteredCompetencies]);

  return (
    <ModalHeader isMobile={isMobile}>
      <TableSwitch>
        <TableToggle onClick={() => setShowCohort(true)} selected={showCohort}>
          Cohort Data
        </TableToggle>
        <TableToggle
          onClick={() => setShowCohort(false)}
          selected={!showCohort}
        >
          Non Active Student Data
        </TableToggle>
      </TableSwitch>

      <Divider />

      <Switches id="modal-header-actions">
        <Tooltip
          title={<TooltipTitle>Filter Students</TooltipTitle>}
          placement="bottom"
        >
          <IconFilterButton
            variant={isStudentFilterActive ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => {
              setOpenStudentFilter(true);
              // There are some issues with the MUI portal and transitions.
              // This click after the transition is done will help autofocusing on the portal
              setTimeout(() => clickOnFilter(0), 500);
            }}
            style={{
              marginRight: openStudentFilter ? 0 : 10,
              width: openStudentFilter ? 0 : 50,
              transform: openStudentFilter ? 'scale(0, 1)' : 'scale(1)'
            }}
          >
            <Group
              style={{
                fontSize: 22,
                position: 'absolute',
                top: 14,
                left: 24,
                color: themeContext.colors.secondary
              }}
              fontSize="large"
            />
            <FilterList fontSize="large" />
          </IconFilterButton>
        </Tooltip>

        <FilterDropdown
          setFilteredValues={setFilteredStudents}
          filteredValues={filteredStudents}
          options={studentOptions}
          open={openStudentFilter}
          onClose={() => setTimeout(() => setOpenStudentFilter(false), 200)}
          label="Students"
        />

        <Tooltip
          title={<TooltipTitle>Filter Competencies</TooltipTitle>}
          placement="bottom"
        >
          <IconFilterButton
            variant={isCompetencyFilterActive ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => {
              setOpenCompetencyFilter(true);
              // There are some issues with the MUI portal and transitions.
              // This click after the transition is done will help autofocusing on the portal
              setTimeout(() => clickOnFilter(1), 500);
            }}
            style={{
              marginRight: openCompetencyFilter ? 0 : 10,
              width: openCompetencyFilter ? 0 : 50,
              transform: openCompetencyFilter ? 'scale(0, 1)' : 'scale(1)'
            }}
          >
            <Assignment
              style={{
                fontSize: 20,
                position: 'absolute',
                top: 16,
                left: 26,
                color: themeContext.colors.secondary
              }}
              fontSize="large"
            />
            <FilterList fontSize="large" />
          </IconFilterButton>
        </Tooltip>

        <FilterDropdown
          setFilteredValues={setFilteredCompetencies}
          filteredValues={filteredCompetencies}
          options={competencyOptions}
          open={openCompetencyFilter}
          onClose={() => setTimeout(() => setOpenCompetencyFilter(false), 200)}
          label="Competencies"
          offsetPopper={62}
        />

        <Tooltip
          title={
            <TooltipTitle>
              {shouldAnonymizeReport ? 'Identify Report' : 'De-Identify Report'}
            </TooltipTitle>
          }
          placement="bottom"
        >
          <IconButton
            variant={shouldAnonymizeReport ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setShouldAnonymizeReport(!shouldAnonymizeReport)}
          >
            {shouldAnonymizeReport ? (
              <AnonymousIcon />
            ) : (
              <Person fontSize="large" />
            )}
          </IconButton>
        </Tooltip>

        <Tooltip
          title={
            <TooltipTitle>
              {isExpanded ? 'Collapse Collections' : 'Expand Collections'}
            </TooltipTitle>
          }
          placement="bottom"
        >
          <IconButton
            variant={isExpanded ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => {
              toggleExpanded(!isExpanded);
              setFilteredCompetencies([]);
            }}
          >
            <AllOut fontSize="large" />
          </IconButton>
        </Tooltip>

        {isExpanded && (
          <Tooltip
            title={
              <TooltipTitle>
                {showScores ? 'Hide Scores' : 'Show Scores'}
              </TooltipTitle>
            }
            placement="bottom"
          >
            <IconButton
              variant={showScores ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setShowScores(!showScores)}
            >
              <Score fontSize="large" />
            </IconButton>
          </Tooltip>
        )}

        {isExpanded && (
          <Tooltip
            title={
              <TooltipTitle>
                {showTotals ? 'Hide Totals' : 'Show Totals'}
              </TooltipTitle>
            }
            placement="bottom"
          >
            <IconButton
              variant={showTotals ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setShowTotals(!showTotals)}
            >
              <Functions fontSize="large" />
            </IconButton>
          </Tooltip>
        )}

        <ClickAwayListener onClickAway={() => setOpenZoom(false)}>
          <div>
            <ZoomTooltip
              PopperProps={{
                disablePortal: true
              }}
              open={openZoom}
              onClose={() => setOpenZoom(false)}
              interactive
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <ZoomSlider
                  id="zoom-slider"
                  defaultValue={100}
                  value={parseInt(zoom * 100, 10)}
                  step={5}
                  marks={zoomSliderMarks}
                  min={80}
                  max={120}
                  valueLabelDisplay="auto"
                  // onBlur={() => setOpenZoom(false)}
                  onChange={(e, value) => {
                    const modalEl = document.getElementById(
                      'competency-modal-content'
                    );
                    if (modalEl) {
                      modalEl.style.zoom = value / 100;
                      dispatch(doSetTableZoom(value / 100));
                    }
                  }}
                />
              }
            >
              <ZoomIconButton
                variant={openZoom ? 'contained' : 'outlined'}
                color="primary"
                onClick={() =>
                  setTimeout(
                    () => setOpenZoom(true),
                    openStudentFilter || openCompetencyFilter ? 700 : 0
                  )
                }
              >
                <ZoomIn fontSize="large" />
              </ZoomIconButton>
            </ZoomTooltip>
          </div>
        </ClickAwayListener>
      </Switches>

      {isExpanded && !isMobile && (
        <>
          <Divider />
          <StdDevLegend />
        </>
      )}

      <CloseIcon onClick={() => dispatch(doSetShowTableModal(false))} />
    </ModalHeader>
  );
};

GridModalHeader.propTypes = {
  isExpanded: PropTypes.bool,
  showCohort: PropTypes.bool,
  showScores: PropTypes.bool,
  toggleExpanded: PropTypes.func.isRequired,
  setShowCohort: PropTypes.func.isRequired,
  setShowScores: PropTypes.func.isRequired,
  students: PropTypes.array,
  filteredStudents: PropTypes.array,
  setFilteredStudents: PropTypes.func.isRequired,
  shouldAnonymizeReport: PropTypes.bool,
  competencyCollections: PropTypes.array,
  filteredCompetencies: PropTypes.array,
  setFilteredCompetencies: PropTypes.func.isRequired,
  setShouldAnonymizeReport: PropTypes.func.isRequired,
  showTotals: PropTypes.bool.isRequired,
  setShowTotals: PropTypes.func.isRequired,
  isCompetencyFilterActive: PropTypes.bool.isRequired,
  isStudentFilterActive: PropTypes.bool.isRequired
};

GridModalHeader.defaultProps = {
  isExpanded: false,
  showCohort: true,
  showScores: true,
  filteredStudents: [],
  students: [],
  competencyCollections: [],
  filteredCompetencies: [],
  shouldAnonymizeReport: false
};

export default GridModalHeader;
