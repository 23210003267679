import { call, put, takeEvery } from 'redux-saga/effects';
import {
  deleteCohortAssessmentCollection,
  getCohortAssessments,
  getCohortAssessmentsStudent,
  postCohortAssessmentCollection,
  putCohortAssessmentCollection,
  postCohortAssessment,
  putCohortAssessment,
  deleteCohortAssessment,
  patchCohortAssessmentStatus,
  getCohortAssessmentScores,
  putCohortAssessmentStudentCommitStatus,
  getCohortAssessmentCommitmentLog
} from '../../api/cohortAssessmentApi';

import {
  doGetCohortAssessmentsSuccess,
  doGetCohortAssessmentsError,
  doPostCohortAssessmentCollectionSuccess,
  doPostCohortAssessmentCollectionError,
  doPutCohortAssessmentCollectionSuccess,
  doPutCohortAssessmentCollectionError,
  doDeleteCohortAsssessmentCollectionSuccess,
  doDeleteCohortAsssessmentCollectionError,
  doPostCohortAssessmentSuccess,
  doPostCohortAssessmentError,
  doPutCohortAssessmentSuccess,
  doPutCohortAssessmentError,
  doDeleteCohortAsssessmentSuccess,
  doDeleteCohortAsssessmentError,
  doPatchCohortAssessmentStatusSuccess,
  doPatchCohortAssessmentStatusError,
  doGetCohortAssessmentScoresSuccess,
  doGetCohortAssessmentScoresError,
  doPutCohortAssessmentStudentStatusCommitSuccess,
  doPutCohortAssessmentStudentStatusCommitError,
  doGetCohortAssessmentCommitmentLogSuccess,
  doGetCohortAssessmentCommitmentLogError,
  doGetCohortAssessmentsStudentSuccess,
  doGetCohortAssessmentsStudentError
} from '../actions/cohortAssessmentActions';

import {
  DELETE_COHORT_ASSESSMENT,
  DELETE_COHORT_ASSESSMENT_COLLECTION,
  GET_COHORT_ASSESSMENTS,
  GET_COHORT_ASSESSMENTS_STUDENT,
  GET_COHORT_ASSESSMENT_COMMITMENT_LOG,
  GET_COHORT_ASSESSMENT_SCORES,
  PATCH_COHORT_ASSESSMENT_STATUS,
  POST_COHORT_ASSESSMENT,
  POST_COHORT_ASSESSMENT_COLLECTION,
  PUT_COHORT_ASSESSMENT,
  PUT_COHORT_ASSESSMENT_COLLECTION,
  PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT
} from '../actions-type';

function* handleGetCohortAssessments(action) {
  const { cohortUuid } = action.payload;

  try {
    const result = yield call(getCohortAssessments, cohortUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCohortAssessmentsSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetCohortAssessmentsError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doGetCohortAssessmentsError());
  }
}

function* handleGetCohortAssessmentsStudent(action) {
  const { cohortUuid, userUuid } = action.payload;

  try {
    const result = yield call(
      getCohortAssessmentsStudent,
      cohortUuid,
      userUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCohortAssessmentsStudentSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetCohortAssessmentsStudentError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doGetCohortAssessmentsError());
  }
}

function* handleGetCohortAssessmentScores(action) {
  const { cohortUuid, cohortAssessmentUuid } = action.payload;

  try {
    const result = yield call(getCohortAssessmentScores, {
      cohortUuid,
      cohortAssessmentUuid
    });

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCohortAssessmentScoresSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetCohortAssessmentScoresError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doGetCohortAssessmentScoresError());
  }
}

function* handlePostCohortAssessmentCollection(action) {
  try {
    const result = yield call(postCohortAssessmentCollection, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostCohortAssessmentCollectionSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPostCohortAssessmentCollectionError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPostCohortAssessmentCollectionError());
  }
}

function* handlePutCohortAssessmentCollection(action) {
  try {
    const result = yield call(putCohortAssessmentCollection, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutCohortAssessmentCollectionSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPutCohortAssessmentCollectionError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPutCohortAssessmentCollectionError());
  }
}

function* handlePutCohortAssessmentStudentGradeStatusCommit(action) {
  const {
    cohortUuid,
    assessmentUuid,
    studentUuid,
    cohortAssessmentStatusCommit,
    justificationMessage
  } = action.payload;

  try {
    const result = yield call(putCohortAssessmentStudentCommitStatus, {
      cohortUuid,
      assessmentUuid,
      studentUuid,
      cohortAssessmentStatusCommit,
      justificationMessage
    });
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutCohortAssessmentStudentStatusCommitSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutCohortAssessmentStudentStatusCommitError(error));
  }
}

function* handleGetCohortAssessmentCommitmentLog(action) {
  try {
    const result = yield call(getCohortAssessmentCommitmentLog, action.payload);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetCohortAssessmentCommitmentLogSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCohortAssessmentCommitmentLogError(error));
  }
}

function* handleDeleteCohortAssessmentCollection(action) {
  try {
    const result = yield call(deleteCohortAssessmentCollection, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteCohortAsssessmentCollectionSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doDeleteCohortAsssessmentCollectionError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doDeleteCohortAsssessmentCollectionError());
  }
}

function* handlePostCohortAssessment(action) {
  try {
    const result = yield call(postCohortAssessment, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostCohortAssessmentSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPostCohortAssessmentError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPostCohortAssessmentError());
  }
}

function* handlePutCohortAssessment(action) {
  try {
    const result = yield call(putCohortAssessment, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutCohortAssessmentSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPutCohortAssessmentError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPutCohortAssessmentError());
  }
}

function* handlePatchCohortAssessmentStatus(action) {
  try {
    const result = yield call(patchCohortAssessmentStatus, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchCohortAssessmentStatusSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPatchCohortAssessmentStatusError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPatchCohortAssessmentStatusError());
  }
}

function* handleDeleteCohortAssessment(action) {
  try {
    const result = yield call(deleteCohortAssessment, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteCohortAsssessmentSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doDeleteCohortAsssessmentError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doDeleteCohortAsssessmentError());
  }
}

const cohortAssessmentCollection = [
  takeEvery(GET_COHORT_ASSESSMENTS, handleGetCohortAssessments),
  takeEvery(GET_COHORT_ASSESSMENT_SCORES, handleGetCohortAssessmentScores),
  takeEvery(
    GET_COHORT_ASSESSMENT_COMMITMENT_LOG,
    handleGetCohortAssessmentCommitmentLog
  ),
  takeEvery(
    POST_COHORT_ASSESSMENT_COLLECTION,
    handlePostCohortAssessmentCollection
  ),
  takeEvery(
    PUT_COHORT_ASSESSMENT_COLLECTION,
    handlePutCohortAssessmentCollection
  ),
  takeEvery(
    PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT,
    handlePutCohortAssessmentStudentGradeStatusCommit
  ),
  takeEvery(
    DELETE_COHORT_ASSESSMENT_COLLECTION,
    handleDeleteCohortAssessmentCollection
  ),
  takeEvery(POST_COHORT_ASSESSMENT, handlePostCohortAssessment),
  takeEvery(PUT_COHORT_ASSESSMENT, handlePutCohortAssessment),
  takeEvery(PATCH_COHORT_ASSESSMENT_STATUS, handlePatchCohortAssessmentStatus),
  takeEvery(DELETE_COHORT_ASSESSMENT, handleDeleteCohortAssessment),
  takeEvery(GET_COHORT_ASSESSMENTS_STUDENT, handleGetCohortAssessmentsStudent)
];

export { cohortAssessmentCollection, handlePatchCohortAssessmentStatus };
