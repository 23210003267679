import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { FormControlLabel, Checkbox, Tooltip, Chip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Heading2 } from '@xcomp/xcomp-design-library';
import { Panel, PanelHeader } from '../../Library/Layout';
import PageIllustration from '../../Library/PageIllustration';
import CohortApplicantReportActionButton from './CohortApplicantReportActionButton';
import {
  formatLastName,
  formatFirstNameMiddleName
} from '../../../helpers/format/user.format';

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const PanelSC = styled(Panel)`
  margin-top: 48px;
`;

const PanelHeaderSC = styled(PanelHeader)`
  padding: 0px 32px;
  flex-direction: row-reverse;
  .MuiTypography-body1 {
    white-space: nowrap;
  }
`;

const Heading2SC = styled(Heading2)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const CohortApplicantReportActionButtonSC = styled(
  CohortApplicantReportActionButton
)`
  padding-left: 32px;
`;

const RowContainer = styled(({ borderTop, ...rest }) => <div {...rest} />)`
  display: flex;
  width: 100%;
  flex-wrap: none;
  padding: 12px 0px;
  justify-content: flex-start;
  border-top: ${props =>
    props.borderTop && `solid 1px ${props.theme.colors.grey[300]}`};
`;

const DataColumn = styled(({ center, dataValue, padding, width, ...rest }) => (
  <div {...rest} />
))`
  display: flex;
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  color: ${props =>
    props.dataValue
      ? props.theme.fontColors.darker
      : props.theme.fontColors.dark};
  white-space: nowrap;
  font-size: ${props => (props.dataValue ? '16px' : '15px')};
  padding: ${props => props.padding};
  min-width: ${props => props.width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RankColumn = styled(({ dataValue, width, ...rest }) => <div {...rest} />)`
  display: flex;
  justify-content: center;
  color: ${props =>
    props.dataValue
      ? props.theme.fontColors.darker
      : props.theme.fontColors.dark};
  font-size: ${props => (props.dataValue ? '16px' : '15px')};
  min-width: ${props => props.width};
  .MuiChip-root {
    background-color: ${props => props.theme.colors.blue[100]};
  }
`;

const DataC = styled(({ dataValue, padding, width, ...rest }) => (
  <Tooltip {...rest} />
))`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props =>
    props.dataValue
      ? props.theme.fontColors.darker
      : props.theme.fontColors.dark};
  font-size: ${props => (props.dataValue ? '16px' : '15px')};
  padding: ${props => props.padding};
  min-width: ${props => props.width};
`;

const Left = styled(({ padding, ...rest }) => <div {...rest} />)`
  && {
    display: flex;
    flex-grow: 1;
    width: 100%;
    justify-content: flex-end;
    padding: ${props => props.padding};
  }
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding: 80px 0px;
`;

const CohortApplicantReportList = ({
  isLoading,
  onApplyFilterSelection,
  onToggleShouldAnonymizeReport,
  reportData,
  reportHeaders,
  reportSummaryFilters,
  shouldAnonymizeReport,
  showRank
}) => {
  return (
    <PanelSC>
      <PanelHeaderSC>
        {reportSummaryFilters.length > 0 && (
          <CohortApplicantReportActionButtonSC
            actions={reportSummaryFilters}
            onApplyFilterSelection={onApplyFilterSelection}
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={shouldAnonymizeReport}
              onChange={onToggleShouldAnonymizeReport}
              name="shouldAnonymizeReport"
              color="primary"
            />
          }
          label="De-Identify Report"
        />
        <Heading2SC>Cohort Data</Heading2SC>
      </PanelHeaderSC>
      {reportData.length === 0 && !isLoading && (
        <NoData>
          <PageIllustration
            infoText="Apply Filters above to view Cohort Data"
            illustrationType="welcomeBack"
          />
        </NoData>
      )}
      {isLoading && (
        <NoData>
          <CircularProgress size={100} />
        </NoData>
      )}
      {reportData.length > 0 && !isLoading && (
        <SummaryContainer>
          <RowContainer>
            {showRank && <RankColumn width="65px">Rank</RankColumn>}
            <DataColumn width="175px" padding="0px 0px 0px 32px">
              BannerID
            </DataColumn>
            <DataColumn width="200px">Student Name</DataColumn>
            <Left padding="0px 32px 0px 0px">
              {reportHeaders.map(header => (
                <DataColumn center width="125px" key={header.value}>
                  {header.label}
                </DataColumn>
              ))}
            </Left>
          </RowContainer>

          {reportData.map(applicant => {
            const applicantFirstName = formatFirstNameMiddleName(
              applicant?.user?.firstName,
              applicant?.user?.middleName
            );
            const applicantLastName = formatLastName(
              applicant?.user?.lastName,
              applicant?.user?.marriedLastName
            );
            const applicantFullName = shouldAnonymizeReport
              ? '---'
              : `${applicantFirstName} ${applicantLastName}`;
            const applicantId = shouldAnonymizeReport
              ? '---'
              : `${applicant?.user?.institutionId}`;

            return (
              <RowContainer borderTop key={applicant.institutionId}>
                {showRank && (
                  <RankColumn dataValue width="65px">
                    <Chip label={applicant.cohortRank ?? 'N/A'} />
                  </RankColumn>
                )}
                <DataColumn width="175px" padding="0px 0px 0px 32px" dataValue>
                  {applicantId}
                </DataColumn>

                <DataC
                  title={applicantFullName}
                  placement="top"
                  width="200px"
                  dataValue
                >
                  <div>{applicantFullName}</div>
                </DataC>
                <Left padding="0px 32px 0px 0px">
                  {reportHeaders.map(header => (
                    <DataColumn
                      center
                      width="125px"
                      dataValue
                      key={header.value}
                    >
                      {_.get(applicant, header.value, null) ?? 'N/A'}
                    </DataColumn>
                  ))}
                </Left>
              </RowContainer>
            );
          })}
        </SummaryContainer>
      )}
    </PanelSC>
  );
};

CohortApplicantReportList.propTypes = {
  isLoading: PropTypes.bool,
  onApplyFilterSelection: PropTypes.func,
  onToggleShouldAnonymizeReport: PropTypes.func,
  reportData: PropTypes.array,
  reportSummaryFilters: PropTypes.array,
  reportHeaders: PropTypes.array,
  shouldAnonymizeReport: PropTypes.bool,
  showRank: PropTypes.bool
};

CohortApplicantReportList.defaultProps = {
  isLoading: false,
  onApplyFilterSelection: undefined,
  onToggleShouldAnonymizeReport: undefined,
  reportData: [],
  reportSummaryFilters: [],
  reportHeaders: [],
  shouldAnonymizeReport: false,
  showRank: false
};

export default CohortApplicantReportList;
