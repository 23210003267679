import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_COHORT_ASSESSMENT_COLLECTION_SUCCESS,
  GET_COHORT_ASSESSMENTS_SUCCESS,
  GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS,
  GET_COHORT_ASSESSMENT_COMMITMENT_LOG_SUCCESS,
  POST_COHORT_ASSESSMENT_COLLECTION_SUCCESS,
  PUT_COHORT_ASSESSMENT_COLLECTION_SUCCESS
} from '../actions-type';

class CohortAssessmentCollection extends Model {
  static reducer(action, CohortAssessmentCollection, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_COHORT_ASSESSMENTS_SUCCESS: {
        const { cohort_assessment_collections } = payload;

        cohort_assessment_collections.forEach(collection =>
          CohortAssessmentCollection.upsert(collection)
        );

        break;
      }
      case GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS: {
        const { cohortAssessmentCollections } = payload;

        cohortAssessmentCollections.forEach(collection =>
          CohortAssessmentCollection.upsert(collection)
        );

        break;
      }

      case POST_COHORT_ASSESSMENT_COLLECTION_SUCCESS:
      case PUT_COHORT_ASSESSMENT_COLLECTION_SUCCESS: {
        const { cohort_assessment_collection } = payload;

        CohortAssessmentCollection.upsert(cohort_assessment_collection);
        break;
      }
      case GET_COHORT_ASSESSMENT_COMMITMENT_LOG_SUCCESS: {
        const { cohort_assessment_collection } = payload.assessment;

        CohortAssessmentCollection.upsert(cohort_assessment_collection);
        break;
      }
      case DELETE_COHORT_ASSESSMENT_COLLECTION_SUCCESS: {
        const { deletedCohortAssessmentCollection } = payload;
        CohortAssessmentCollection.withId(
          deletedCohortAssessmentCollection.uuid
        ).delete();
        break;
      }

      default:
        return session;
    }
  }
}

CohortAssessmentCollection.fields = {
  uuid: attr(),
  title: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  cohortUuid: fk({
    to: 'Cohort',
    as: 'cohort',
    relatedName: 'cohortAssessmentCollections'
  })
};

CohortAssessmentCollection.modelName = 'CohortAssessmentCollection';
CohortAssessmentCollection.options = {
  idAttribute: 'uuid'
};

export default CohortAssessmentCollection;
