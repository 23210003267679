import React, { useEffect, useState } from 'react';
import Decimal from 'decimal.js-light';
import { Body1 } from '@xcomp/xcomp-design-library';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import TopicData from './TopicData';
import { scoresByAssessmentBlockAttemptStudent } from '../../../../redux/selectors/scoreSelectors';
import { decimalTenth, sortScores } from '../../../../helpers/utilities';

export const ScoreDetail = styled(({ criticalFailure, width, ...rest }) => (
  <Body1 {...rest} />
))`
  width: ${props => props.width || 'initial'};
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  color: ${props =>
    props.criticalFailure
      ? props.theme.colors.status.error
      : props.theme.fontColors.darker};
`;

const TotalDisplay = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 2px solid ${props => props.theme.colors.black};
  font-size: 0.8rem;
`;

export default function ScoresData({
  assessmentBlockUuid,
  attempt,
  studentUuid,
  isOpportunity,
}) {
  const [totalRel, setTotalRel] = useState('- -');
  const [totalPol, setTotalPol] = useState('- -');

  const scores = useSelector(
    state =>
      scoresByAssessmentBlockAttemptStudent(
        state,
        assessmentBlockUuid,
        attempt,
        studentUuid
      ),
    _.isEqual
  );

  useEffect(() => {
    const totalRel = _.reduce(
      scores,
      (total, score) => {
        const decimalTotalRel = new Decimal(score.relValue);
        return total.add(decimalTotalRel);
      },
      new Decimal(0)
    ).val();

    setTotalRel(decimalTenth(totalRel));

    const totalPot = _.reduce(
      scores,
      (total, score) => {
        const decimalTotalPot = new Decimal(score.potValue);
        return total.add(decimalTotalPot);
      },

      new Decimal(0)
    ).val();

    setTotalPol(decimalTenth(totalPot));
  }, [scores]);

  return (
    <Grid container>
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        justifyContent="center"
        alignItems="center"
        item
      >
        <Grid item xs={2} md={2} lg={2}>
          <ScoreDetail> Date</ScoreDetail>
        </Grid>
        <Grid item xs={2} md={2} lg={2}>
          <ScoreDetail> Rubric Item</ScoreDetail>
        </Grid>
        {!isOpportunity &&
          <>
            <Grid item xs={1} md={1} lg={1}>
              <ScoreDetail> Sequence</ScoreDetail>
            </Grid>
            <Grid item xs={1} md={1} lg={1}>
              <ScoreDetail> Rubric</ScoreDetail>
            </Grid>
          </>
        }
        <Grid item xs={1} md={1} lg={1}>
          <ScoreDetail> Step/Item</ScoreDetail>
        </Grid>
        <Grid item xs={isOpportunity ? 3 : 2} md={isOpportunity ? 3 : 2} lg={isOpportunity ? 3 : 2}>
          <ScoreDetail> Description</ScoreDetail>
        </Grid>
        <Grid item xs={isOpportunity ? 2 : 1} md={isOpportunity ? 2 : 1} lg={isOpportunity ? 2 : 1}>
          <ScoreDetail> Topic</ScoreDetail>
        </Grid>
        <Grid item xs={1} md={1} lg={1}>
          <ScoreDetail> Rel.</ScoreDetail>
        </Grid>
        <Grid item xs={1} md={1} lg={1}>
          <ScoreDetail> Pot.</ScoreDetail>
        </Grid>
      </Grid>

      {scores
        ?.sort(sortScores)
        .map(score => (
          <Grid key={score?.uuid} container xs={12} md={12} lg={12} item>
            <Grid item xs={2} md={2} lg={2}>
              <ScoreDetail
                criticalFailure={new Decimal(score.relValue).equals(0)}
              >
                {score?.date}
              </ScoreDetail>
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <ScoreDetail
                criticalFailure={new Decimal(score.relValue).equals(0)}
              >
                <Tooltip arrow title={`${score?.rubricItem}`} placement="top">
                  <span>{score?.rubricItem}</span>
                </Tooltip>
              </ScoreDetail>
            </Grid>
            {!isOpportunity &&
              <>
                <Grid item xs={1} md={1} lg={1}>
                  <ScoreDetail
                    criticalFailure={new Decimal(score.relValue).equals(0)}
                  >
                    <Tooltip arrow title={`${score?.sequenceOrder || 'N/A'}`} placement="top">
                      <span>{score?.sequenceOrder || 'N/A'}</span>
                    </Tooltip>
                  </ScoreDetail>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                  <ScoreDetail
                    criticalFailure={new Decimal(score.relValue).equals(0)}
                  >
                    <Tooltip arrow title={`${score?.rubricOrder || 'N/A'}`} placement="top">
                      <span>{score?.rubricOrder || 'N/A'}</span>
                    </Tooltip>
                  </ScoreDetail>
                </Grid>
              </>
            }
            <Grid item xs={1} md={1} lg={1}>
              <ScoreDetail
                criticalFailure={new Decimal(score.relValue).equals(0)}
              >
                {_.isEmpty(score?.stepSubItem) ? '- -' : score?.stepSubItem}
              </ScoreDetail>
            </Grid>
            <Grid item xs={isOpportunity ? 3 : 2} md={isOpportunity ? 3 : 2} lg={isOpportunity ? 3 : 2}>
              <ScoreDetail
                criticalFailure={new Decimal(score.relValue).equals(0)}
              >
                {_.isEmpty(score?.stepSubItemDescription) ? (
                  '- -'
                ) : (
                  <Tooltip
                    arrow
                    title={`${score?.stepSubItemDescription}`}
                    placement="top"
                  >
                    <span>{score?.stepSubItemDescription}</span>
                  </Tooltip>
                )}
              </ScoreDetail>
            </Grid>
            <Grid item xs={isOpportunity ? 2 : 1} md={isOpportunity ? 2 : 1} lg={isOpportunity ? 2 : 1}>
              <ScoreDetail
                criticalFailure={new Decimal(score.relValue).equals(0)}
              >
                <TopicData topicUuid={score?.topicUuid} />
              </ScoreDetail>
            </Grid>
            <Grid item xs={1} md={1} lg={1}>
              <ScoreDetail
                criticalFailure={new Decimal(score.relValue).equals(0)}
              >
                {score?.relValue}
              </ScoreDetail>
            </Grid>
            <Grid item xs={1} md={1} lg={1}>
              <ScoreDetail
                criticalFailure={new Decimal(score.relValue).equals(0)}
              >
                {score?.potValue}
              </ScoreDetail>
            </Grid>
          </Grid>
        ))}

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        justifyContent="flex-end"
        alignItems="center"
        item
      >
        <Grid item xs={1} md={1} lg={1}>
          <TotalDisplay>{totalRel}</TotalDisplay>
        </Grid>
        <Grid item xs={1} md={1} lg={1}>
          <TotalDisplay>{totalPol}</TotalDisplay>
        </Grid>
      </Grid>
    </Grid>
  );
}

ScoresData.propTypes = {
  assessmentBlockUuid: PropTypes.string,
  attempt: PropTypes.number,
  studentUuid: PropTypes.string,
  isOpportunity: PropTypes.bool,
};

ScoresData.defaultProps = {
  isOpportunity: false,
  assessmentBlockUuid: undefined,
  attempt: undefined,
  studentUuid: undefined
};
