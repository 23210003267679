import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import SelectedMicrosPill from './SelectedMicrosPill';

const AddedMicrosDisplay = styled.div`
  margin-left: 0;
  margin-right: auto;
`;

// eslint-disable-next-line no-unused-vars
const MicrosToAddDisplay = ({ topics, subtopics, micros, searchType }) => {
  switch (searchType) {
    case 'subtopic':
      return (
        <AddedMicrosDisplay>
          {Object.entries(subtopics).map(([topicUuid, topicObj]) => {
            let microsMap = {};

            Object.entries(topicObj.subtopics).forEach(
              // eslint-disable-next-line no-unused-vars
              ([topicUuid, { subtopic, micros }]) => {
                microsMap = _.merge({}, microsMap, micros);
              }
            );

            return (
              <SelectedMicrosPill
                key={topicUuid}
                topic={topicObj.topic}
                microsMap={microsMap}
              />
            );
          })}
        </AddedMicrosDisplay>
      );
    case 'topic':
      return (
        <AddedMicrosDisplay>
          {Object.entries(topics).map(([topicUuid, { topic, micros }]) => (
            <SelectedMicrosPill
              key={topicUuid}
              topic={topic}
              microsMap={micros}
            />
          ))}
        </AddedMicrosDisplay>
      );
    default:
      return (
        <AddedMicrosDisplay>
          {Object.entries(micros).map(([topicUuid, { topic, micros }]) => (
            <SelectedMicrosPill
              key={topicUuid}
              topic={topic}
              microsMap={micros}
            />
          ))}
        </AddedMicrosDisplay>
      );
  }
};

MicrosToAddDisplay.propTypes = {
  topics: PropTypes.object,
  subtopics: PropTypes.object,
  micros: PropTypes.object,
  searchType: PropTypes.oneOf(['microcompetency', 'subtopic', 'topic'])
};
MicrosToAddDisplay.defaultProps = {
  topics: {},
  subtopics: {},
  micros: {},
  searchType: 'microcompetency'
};

export default MicrosToAddDisplay;
