import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ContainerResize, ContainerPage, ElevateLow } from '../Library/Layout';
import SyllabusFilter from './SyllabusFilter';
import SyllabusEncounterContainer from './SyllabusEncounter/SyllabusEncounterContainer';
import SyllabusAssessmentContainer from './SyllabusAssessment/SyllabusAssessmentContainer';
import Header from '../Library/Header';
import Overline from '../Library/Overline';
import { MonthHeading } from '../Library/SyllabusComponents';

export default function Syllabus({
  sectionUuid,
  partUuid,
  courseNumber,
  sectionNumber,
  partNumber,
  partTitle,
  syllabusItems,
  filterSelection,
  sortSelection,
  handleFilterSelection,
  handleSortSelection
}) {
  let encounterIndex = 0;
  let assessmentIndex = 0;
  let currentMonth = null;

  return (
    <ContainerResize>
      <Header
        title="Syllabus"
        subtitle={`${courseNumber} ● Section ${sectionNumber} ● Part ${partNumber} - ${partTitle}`}
        backButtonLocation="/syllabus-management/all"
      />

      <ContainerPage>
        <ElevateLow>
          <SyllabusFilter
            filterSelection={filterSelection}
            sortSelection={sortSelection}
            handleFilterSelection={handleFilterSelection}
            handleSortSelection={handleSortSelection}
          />
          {syllabusItems.map(item => {
            let itemNumber;
            let showNewMonth = false;

            const itemStartDate = item.linkedAssessmentUuid
              ? item.linked_assessment?.startDate
              : item.startDate;

            const startDateMoment = moment(itemStartDate);

            if (currentMonth !== startDateMoment.format('MMMM')) {
              showNewMonth = true;
              currentMonth = startDateMoment.format('MMMM');
            }

            if (item.type === 'encounter') {
              itemNumber = encounterIndex + 1;
              encounterIndex += 1;
            } else if (item.type === 'assessment') {
              itemNumber = assessmentIndex + 1;
              assessmentIndex += 1;
            }

            return (
              <React.Fragment key={item.uuid}>
                {showNewMonth && (
                  <MonthHeading>
                    {currentMonth !== 'Invalid date' ? (
                      <Overline>
                        {currentMonth} {startDateMoment.format('YYYY')}
                      </Overline>
                    ) : (
                      <Overline>Missing Dates</Overline>
                    )}
                  </MonthHeading>
                )}
                {item.type === 'encounter' ? (
                  <SyllabusEncounterContainer
                    key={item.uuid}
                    itemNumber={itemNumber}
                    encounterUuid={item.uuid}
                  />
                ) : (
                  <SyllabusAssessmentContainer
                    key={item.uuid}
                    itemNumber={itemNumber}
                    sectionUuid={sectionUuid}
                    partUuid={partUuid}
                    assessmentUuid={item.uuid}
                  />
                )}
              </React.Fragment>
            );
          })}
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
}

Syllabus.propTypes = {
  sectionUuid: PropTypes.string,
  partUuid: PropTypes.string,
  courseNumber: PropTypes.string,
  sectionNumber: PropTypes.string,
  partNumber: PropTypes.string,
  partTitle: PropTypes.string,
  syllabusItems: PropTypes.array,
  filterSelection: PropTypes.string,
  sortSelection: PropTypes.string,
  handleFilterSelection: PropTypes.func,
  handleSortSelection: PropTypes.func
};

Syllabus.defaultProps = {
  sectionUuid: '',
  partUuid: '',
  courseNumber: '',
  sectionNumber: '',
  partNumber: '',
  partTitle: '',
  syllabusItems: [],
  filterSelection: '',
  sortSelection: '',
  handleFilterSelection: undefined,
  handleSortSelection: undefined
};
