import { call, put, takeEvery } from 'redux-saga/effects';

import {
  getCourseMaster,
  getCourseMasterByDepartment,
  postCourseMaster,
  putCourseMaster,
  deleteCourseMaster
} from '../../api/courseMasterApi';

import {
  doGetCourseMasterSuccess,
  doGetCourseMasterError,
  doGetCoursesMasterByDepartmentSuccess,
  doGetCoursesMasterByDepartmentError,
  doPostCourseMasterSuccess,
  doPostCourseMasterError,
  doPutCourseMasterSuccess,
  doPutCourseMasterError,
  doDeleteCourseMasterSuccess,
  doDeleteCourseMasterError
} from '../actions/courseMasterActions';

import {
  GET_COURSE_MASTER,
  GET_COURSE_MASTER_BY_DEPARTMENT,
  POST_COURSE_MASTER,
  PUT_COURSE_MASTER,
  DELETE_COURSE_MASTER
} from '../actions-type';
import { doSetCourseDescription } from '../actions/descriptionActions';

function* handleGetCourseMaster(action) {
  const { courseMasterUuid } = action.payload;

  try {
    const result = yield call(getCourseMaster, courseMasterUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doSetCourseDescription(payload.courseMaster.description, undefined));
      yield put(doGetCourseMasterSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCourseMasterError(error));
  }
}

function* handleGetCourseMasterByDepartment(action) {
  const { departmentUuid } = action.payload;

  try {
    const result = yield call(getCourseMasterByDepartment, departmentUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCoursesMasterByDepartmentSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCoursesMasterByDepartmentError(error));
  }
}

function* handlePostCourseMaster(action) {
  try {
    const { courseMaster } = action.payload;

    const result = yield call(postCourseMaster, courseMaster);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostCourseMasterSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostCourseMasterError(error));
  }
}

function* handlePutCourseMaster(action) {
  const { courseMaster } = action.payload;

  try {
    const result = yield call(putCourseMaster, courseMaster);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutCourseMasterSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutCourseMasterError(error));
  }
}

function* handleDeleteCourseMaster(action) {
  const { courseMasterUuid } = action.payload;

  try {
    const result = yield call(deleteCourseMaster, courseMasterUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteCourseMasterSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteCourseMasterError(error));
  }
}

const courseMaster = [
  takeEvery(GET_COURSE_MASTER, handleGetCourseMaster),
  takeEvery(GET_COURSE_MASTER_BY_DEPARTMENT, handleGetCourseMasterByDepartment),
  takeEvery(POST_COURSE_MASTER, handlePostCourseMaster),
  takeEvery(PUT_COURSE_MASTER, handlePutCourseMaster),
  takeEvery(DELETE_COURSE_MASTER, handleDeleteCourseMaster)
];

export { courseMaster };
