import { v4 as uuidv4 } from 'uuid';

const initialGradeCollectionState = {
  uuid: null,
  title: null,
  scoreType: 'SCORE',
  distributeWeightEqually: false,
  allow_dropped_assessments: null,
  collection_assessments: {},
  usedAssessmentUuids: {},
  showValidationErrors: false
};

const editGradeCollectionReducer = (state, action) => {
  switch (action.type) {
    case 'setExisting': {
      const {
        uuid,
        title,
        scoreType,
        distributeWeightEqually,
        allow_dropped_assessments,
        collection_assessments
      } = action.payload;

      const newCollectionAssessments = {};

      if (collection_assessments?.length > 0) {
        collection_assessments.forEach(collectionAssessment => {
          newCollectionAssessments[
            collectionAssessment.uuid
          ] = collectionAssessment;
        });
      }

      return {
        ...state,
        uuid,
        title,
        scoreType,
        distributeWeightEqually,
        allow_dropped_assessments,
        collection_assessments: newCollectionAssessments
      };
    }
    case 'setPartAssessments': {
      const { partAssessments } = action.payload;

      const newUsedAssessmentUuids = {};

      if (partAssessments?.length > 0) {
        partAssessments.forEach(assessment => {
          if (assessment.collection_assessment?.assessmentUuid) {
            newUsedAssessmentUuids[
              assessment.collection_assessment.assessmentUuid
            ] = true;
          }
        });
      }

      return {
        ...state,
        usedAssessmentUuids: newUsedAssessmentUuids
      };
    }
    case 'setScoreType':
      return {
        ...state,
        scoreType: action.payload.scoreType
      };

    case 'addCollectionAssessment': {
      const someUuid = uuidv4();
      const newCollectionAssessments = { ...state.collection_assessments };

      newCollectionAssessments[someUuid] = {
        isNew: true,
        uuid: someUuid,
        assessmentUuid: null,
        weight: 100
      };

      return {
        ...state,
        collection_assessments: newCollectionAssessments
      };
    }

    case 'setAssessmentUuid': {
      const { assessmentUuid, collectionAssessmentUuid } = action.payload;
      const { usedAssessmentUuids, collection_assessments } = state;
      const newCollectionAssessments = { ...collection_assessments };
      const newUsedAssessmentUuids = { ...usedAssessmentUuids };
      const currentAssessmentUuid =
        newCollectionAssessments[collectionAssessmentUuid].assessmentUuid;

      if (currentAssessmentUuid) {
        newUsedAssessmentUuids[currentAssessmentUuid] = undefined;
      }

      newCollectionAssessments[
        collectionAssessmentUuid
      ].assessmentUuid = assessmentUuid;

      newUsedAssessmentUuids[assessmentUuid] = true;
      return {
        ...state,
        collection_assessments: newCollectionAssessments,
        usedAssessmentUuids: newUsedAssessmentUuids
      };
    }

    case 'setAssessmentWeight': {
      const { weight, collectionAssessmentUuid } = action.payload;
      const { collection_assessments } = state;
      const newCollectionAssessments = { ...collection_assessments };

      newCollectionAssessments[collectionAssessmentUuid].weight = weight;

      return {
        ...state,
        collection_assessments: newCollectionAssessments
      };
    }

    case 'setAssessmentRequired': {
      const { required, collectionAssessmentUuid } = action.payload;
      const { collection_assessments } = state;
      const newCollectionAssessments = { ...collection_assessments };

      newCollectionAssessments[collectionAssessmentUuid].required = required;

      return {
        ...state,
        collection_assessments: newCollectionAssessments
      };
    }

    case 'setDistributeWeightEqually': {
      const { distributeWeightEqually } = action.payload;

      return {
        ...state,
        distributeWeightEqually
      };
    }

    case 'setShowValidationErrors':
      return {
        ...state,
        showValidationErrors: action.payload.showValidationErrors
      };

    case 'deleteCollectionAssessment': {
      const { collectionAssessmentUuid } = action.payload;
      const { collection_assessments } = state;
      const newCollectionAssessments = { ...collection_assessments };

      delete newCollectionAssessments[collectionAssessmentUuid];

      return {
        ...state,
        collection_assessments: newCollectionAssessments
      };
    }

    default: {
      throw new Error();
    }
  }
};

export { editGradeCollectionReducer, initialGradeCollectionState };
