import { ORM } from 'redux-orm';

import AcademicYear from './academicYear';
import AccreditationAgency from './accreditationAgency';
import ApplicantCognitiveRank from './applicantCognitiveRank';
import ApplicantNoncognitiveRank from './applicantNoncognitiveRank';
import ApplicantPhysicalSkillsRank from './applicantPhysicalSkillsRank';
import ApplicantCommunicationRank from './applicantCommunicationRank';
import ApplicantCognitiveReportType from './applicantCognitiveReportType';
import ApplicantCommunicationReportType from './applicantCommunicationReportType';
import ApplicantDemographicReportType from './applicantDemographicReportType';
import ApplicantNoncognitiveReportType from './applicantNoncognitiveReportType';
import ApplicantPhysicalSkillsReportType from './applicantPhysicalSkillsReportType';
import Assessment from './assessment';
import AssessmentBlock from './assessmentBlock';
import AssessmentBlockResult from './assessmentBlockResult';
import AssessmentAssociation from './assessmentAssociation';
import AssessmentTechnique from './assessmentTechnique';
import AssessmentType from './assessmentType';
import AssessmentRank from './assessmentRank';
import AssessmentRubric from './assessmentRubric';
import CalculatedGradeResult from './calculatedGradeResult';
import Classification from './classification';
import Cohort from './cohort';
import CohortAssessmentCollection from './cohortAssessmentCollection';
import CourseCollectionCourse from './courseCollectionCourse';
import CommitmentLog from './commitmentLog';
import Competency from './competency';
import CompetencyCollection from './competencyCollection';
import CompetencyScore from './competencyScore';
import CompetencyMicro from './competencyMicro';
import ConversionAptitude from './conversionAptitude';
import ConversionMilestone from './conversionMilestone';
import Course from './course';
import CourseCollection from './courseCollection';
import CourseMaster from './courseMaster';
import CourseMasterDepartment from './courseMasterDepartment';
import CourseAgeMod from './courseAgeMod';
import Credential from './credential';
import DegreeLevel from './degreeLevel';
import DegreeLevelIndex from './degreeLevelIndex';
import Department from './department';
import EducationLevel from './educationLevel';
import Encounter from './encounter';
import EncounterFaculty from './encounterFaculty';
import EncounterType from './encounterType';
import ExamAptitude from './examAptitude';
import ExamMilestone from './examMilestone';
import GradeCollection from './gradeCollection';
import Group from './group';
import GradeSummary from './gradeSummary';
import Institution from './institution';
import InstitutionIdType from './institutionIdType';
import LetterGrade from './letterGrade';
import Loverb from './loverb';
import Microcompetency from './microcompetency';
import Part from './part';
import PartFaculty from './partFaculty';
import PartDirector from './partDirector';
import PartGradeSettings from './partGradeSettings';
import Program from './program';
import ProgramAssessmentCollection from './programAssessmentCollection';
import ProgramAssessmentMaster from './programAssessmentMaster';
import ProgramCip from './programCip';
import ProgramCode from './programCode';
import ProgramDiscipline from './programDiscipline';
import ResultCard from './resultCard';
import RigorIndexReport from './rigorIndexReport';
import RigorHistoricalReport from './rigorHistoricalReport';
import School from './school';
import Score from './score';
import Section from './section';
import SectionDirector from './sectionDirector';
import Subtopic from './subtopic';
import Term from './term';
import Topic from './topic';
import ThirdPartyId from './thirdPartyId';
import TranscriptCognitive from './transcriptCognitive';
import TranscriptNonCognitive from './transcriptNonCognitive';
import TranscriptPhysicalSkills from './transcriptPhysicalSkills';
import TranscriptCommunication from './transcriptCommunication';
import User from './user';
import UserCohort from './userCohort';
import UserDepartment from './userDepartment';
import UserGroup from './userGroup';
import UserInstitution from './userInstitution';
import UserProgram from './userProgram';
import UserSchool from './userSchool';
import UserSection from './userSection';
import SourceInstitution from './sourceInstitution';

export const orm = new ORM({ stateSelector: state => state.orm });

orm.register(
  AcademicYear,
  AccreditationAgency,
  ApplicantCognitiveRank,
  ApplicantNoncognitiveRank,
  ApplicantPhysicalSkillsRank,
  ApplicantCommunicationRank,
  ApplicantCognitiveReportType,
  ApplicantCommunicationReportType,
  ApplicantDemographicReportType,
  ApplicantNoncognitiveReportType,
  ApplicantPhysicalSkillsReportType,
  Assessment,
  AssessmentBlock,
  AssessmentBlockResult,
  AssessmentAssociation,
  AssessmentTechnique,
  AssessmentType,
  AssessmentRank,
  AssessmentRubric,
  CalculatedGradeResult,
  Classification,
  Cohort,
  CourseCollectionCourse,
  CohortAssessmentCollection,
  CommitmentLog,
  Competency,
  CompetencyCollection,
  CompetencyScore,
  CompetencyMicro,
  ConversionAptitude,
  ConversionMilestone,
  Course,
  CourseCollection,
  CourseMaster,
  CourseMasterDepartment,
  CourseAgeMod,
  Credential,
  DegreeLevel,
  DegreeLevelIndex,
  Department,
  EducationLevel,
  Encounter,
  EncounterFaculty,
  EncounterType,
  ExamAptitude,
  ExamMilestone,
  GradeCollection,
  Group,
  GradeSummary,
  Institution,
  InstitutionIdType,
  LetterGrade,
  Loverb,
  Microcompetency,
  Part,
  PartFaculty,
  PartDirector,
  PartGradeSettings,
  Program,
  ProgramAssessmentCollection,
  ProgramAssessmentMaster,
  ProgramCip,
  ProgramCode,
  ProgramDiscipline,
  ResultCard,
  RigorIndexReport,
  RigorHistoricalReport,
  School,
  Score,
  Section,
  SectionDirector,
  Subtopic,
  Term,
  Topic,
  ThirdPartyId,
  TranscriptCognitive,
  TranscriptNonCognitive,
  TranscriptPhysicalSkills,
  TranscriptCommunication,
  User,
  UserCohort,
  UserDepartment,
  UserGroup,
  UserInstitution,
  UserProgram,
  UserSchool,
  UserSection,
  SourceInstitution
);

export default orm;
