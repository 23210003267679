import React from 'react';
import PropTypes from 'prop-types';
import {
  TableDataText,
  PointsTd
} from '../../../PartGradeSummary/PartAssessmentCollectionReport/CollectionReportSC';

const SectionPerformanceStudentPartData = ({ calculatedGrade, rowIndex }) => {
  return (
    <PointsTd blueOne={rowIndex % 2 === 0} blueTwo={rowIndex % 2 !== 0}>
      <TableDataText>{calculatedGrade ?? 'N/A'}</TableDataText>
    </PointsTd>
  );
};

SectionPerformanceStudentPartData.propTypes = {
  rowIndex: PropTypes.number,
  calculatedGrade: PropTypes.string
};

SectionPerformanceStudentPartData.defaultProps = {
  rowIndex: undefined,
  calculatedGrade: undefined
};

export default SectionPerformanceStudentPartData;
