import fetch from '../helpers/fetch';

const getPartFacultyAssessmentSummary = (
  partUuid,
  facultyUuid,
  assessmentTechniques,
  assessmentTypes
) =>
  fetch(
    `/api/part/${partUuid}/faculty/${facultyUuid}/part-faculty-assessment-summary?assessmentTechniques=${JSON.stringify(
      assessmentTechniques
    )}&assessmentTypes=${JSON.stringify(assessmentTypes)}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getPartFacultyReport = (
  partUuid,
  reportType,
  filters,
) =>
  fetch(
    `/api/part/${partUuid}/part-faculty-report`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        reportType,
        faculties: filters.filteredFaculties.filter(f => f !== 'all'),
        assessmentTechniques: filters.filteredAssessmentTechniques,
        assessmentTypes: filters.filteredAssessmentTypes
      }),
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getPartOpportunityReport = (partUuid, assessmentTypes) =>
  fetch(
    `/api/part/${partUuid}/part-opportunity-report?assessmentTypes=${JSON.stringify(
      assessmentTypes
    )}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getPartOpportunityReportBlockDetail = (assessmentUuid, studentUuid) =>
  fetch(
    `/api/part-opportunity-report/assessment-block-detail?assessmentUuid=${assessmentUuid}&studentUuid=${studentUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getPartOpportunityReportStatisticBlockDetail = (
  assessmentUuid,
  statisticType
) =>
  fetch(
    `/api/part-opportunity-report/assessment-block-statistic-detail?assessmentUuid=${assessmentUuid}&statisticType=${statisticType}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getPartTopicAssessmentSummary = (partUuid, topicUuid) =>
  fetch(
    `/api/part/${partUuid}/topic/${topicUuid}/part-topic-assessment-summary`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getPartTopicReport = (partUuid, topics) =>
  fetch(
    `/api/part/${partUuid}/part-topic-report?topics=${JSON.stringify(topics)}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getPartFacultyAssessmentSummary,
  getPartFacultyReport,
  getPartOpportunityReport,
  getPartOpportunityReportBlockDetail,
  getPartOpportunityReportStatisticBlockDetail,
  getPartTopicAssessmentSummary,
  getPartTopicReport
};
