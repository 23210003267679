import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { selectTranscriptCognitiveByInstitutions } from '../../../../../redux/selectors/transcriptCognitiveSelectors';
import ByInstitutionTableHeader from './ByInstitutionTableHeader';
import ByInstitutionTableRecords from './ByInstitutionTableRecords';

const Layout = styled.div`
  font-size: 12px;
  width: 100%;
  padding-left: 66px;
`;

const NoRecords = styled.div`
  padding-left: 120px;
`;

export default function ByInstitutionTable({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  classificationUuid,
  sourceInstitutionUuid
}) {
  const transcripts = useSelector(
    state =>
      selectTranscriptCognitiveByInstitutions(
        state,
        programUuid,
        userUuid,
        cohortUuid,
        category,
        source,
        classificationUuid,
        sourceInstitutionUuid
      ),
    _.isEqual
  );

  return (
    <Layout>
      {transcripts?.length > 0 && <ByInstitutionTableHeader />}
      {transcripts?.length > 0 ? (
        _.sortBy(transcripts, ['date', 'itemCode']).map((transcript, index) => (
          <ByInstitutionTableRecords
            key={transcript?.uuid}
            index={index}
            transcript={transcript}
          />
        ))
      ) : (
        <NoRecords>N/A</NoRecords>
      )}
    </Layout>
  );
}

ByInstitutionTable.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  classificationUuid: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string
};
ByInstitutionTable.defaultProps = {
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  classificationUuid: undefined,
  sourceInstitutionUuid: undefined
};
