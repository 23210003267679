import fetch from '../helpers/fetch';

const getCourseCollections = cohortUuid =>
  fetch(`/api/cohort/${cohortUuid}/course-collections`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getCollectionCourseSearch = searchTerm =>
  fetch(`/api/course-master/search/${searchTerm}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postCourseCollection = newCourseCollection =>
  fetch(`/api/course-collection/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(newCourseCollection)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putCourseCollection = ({ uuid, ...updates }) =>
  fetch(`/api/course-collection/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(updates)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteCourseCollection = uuid =>
  fetch(`/api/course-collection/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getCourseCollections,
  getCollectionCourseSearch,
  postCourseCollection,
  putCourseCollection,
  deleteCourseCollection
};
