import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';
import CohortActions from './CohortActions';

import { formatMMMMyyyy } from '../../helpers/date-fns.utils';

import {
  cohortApplicantCountSelector,
  cohortStudentCountSelector
} from '../../redux/selectors/userCohortsSelectors';

const Text = styled.div`
  && {
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.fontColors.darker};
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.25px;
  }
`;

export default function CohortRow({ cohort }) {
  const applicantCount = useSelector(state =>
    cohortApplicantCountSelector(state, cohort.uuid)
  );

  const studentCount = useSelector(state =>
    cohortStudentCountSelector(state, cohort.uuid)
  );

  const dateRange = `${formatMMMMyyyy(cohort?.startDate)} - ${formatMMMMyyyy(
    cohort?.endDate
  )}`;

  return (
    <TableRow key={cohort?.uuid}>
      <TableCell>
        <Text>{cohort?.name}</Text>
      </TableCell>
      <TableCell>
        <Text>{dateRange}</Text>
      </TableCell>
      <TableCell>
        <Text>{applicantCount}</Text>
      </TableCell>
      <TableCell>
        <Text>{studentCount}</Text>
      </TableCell>
      <TableCell>
        <CohortActions cohortUuid={cohort.uuid} />
      </TableCell>
    </TableRow>
  );
}

CohortRow.propTypes = {
  cohort: PropTypes.object
};

CohortRow.defaultProps = {
  cohort: {}
};
