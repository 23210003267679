import React from 'react';
import PropTypes from 'prop-types';

import {
  StickyTitleTd,
  TableDataText,
  PointsTd,
  TableRow,
  StickyInfoTd,
  StudentInfoText
} from '../CollectionReportSC';

const StudentAvgRow = ({
  competencyCollections,
  collectionStudentAvgMap,
  competencyStudentAvgMap,
  isExpanded,
  showScores,
  relativeCompScores,
}) => {
  return (
    <>
      <TableRow>
        <StickyTitleTd redTwo isRank left="0px" borderRight>
          <TableDataText />
        </StickyTitleTd>
        <StickyInfoTd greyOne left="25px" borderRightStrong>
          <StudentInfoText style={{ fontWeight: 500 }}>Student Average (For all)</StudentInfoText>
        </StickyInfoTd>
        <StickyTitleTd redTwo left="275px" borderRight>
          <TableDataText />
        </StickyTitleTd>
        {isExpanded &&
          <>
            <StickyTitleTd left="340px" isRank orangeTwo borderRight isRelativeRank>
              <TableDataText />
            </StickyTitleTd>
            <StickyTitleTd orangeTwo left="365px" borderRight>
              <TableDataText />
            </StickyTitleTd>
          </>
        }
        {competencyCollections.map(collection => {
          const collectionAverage =
            collectionStudentAvgMap[collection.uuid]?.average;

          const collectionAverageDisplay =
            collectionAverage && collectionAverage !== '0.00'
              ? collectionAverage
              : '--';
          return (
            <React.Fragment key={collection.uuid}>
              {!isExpanded ?
                <PointsTd blueOne isExpanded={isExpanded}>
                  <TableDataText>{collectionAverageDisplay}</TableDataText>
                </PointsTd>
                :
                collection.competencies.length > 0 &&
                collection.competencies.map((competency, index) => {
                  const competencyAverage =
                    competencyStudentAvgMap[competency.uuid]?.average || '--';

                  const competencyAverageDisplay =
                    competencyAverage && competencyAverage !== '0.00'
                      ? competencyAverage.slice(0, -1)
                      : '--';
                  return (
                    <PointsTd
                      key={competency.uuid}
                      isFirstCompetency={index === 0}
                      isExpanded={isExpanded}
                      isCompact={!showScores}
                      greyOne
                    >
                      <TableDataText bold secondaryFontColor isCompact={!showScores}>
                        {competencyAverageDisplay}
                      </TableDataText>
                    </PointsTd>
                  );
                })
              }
            </React.Fragment>
          );
        })}
      </TableRow>
      {isExpanded &&
        <TableRow borderBottomLight={true}>
          <StickyTitleTd redOne isRank left="0px" borderRight>
            <TableDataText />
          </StickyTitleTd>
          <StickyInfoTd left="25px" borderRightStrong>
            <StudentInfoText>Relative Student Average</StudentInfoText>
          </StickyInfoTd>
          <StickyTitleTd redOne left="275px" borderRight>
            <TableDataText />
          </StickyTitleTd>
          <StickyTitleTd left="340px" isRank orangeOne borderRight isRelativeRank>
            <TableDataText />
          </StickyTitleTd>
          <StickyTitleTd orangeOne left="365px" borderRight>
            <TableDataText />
          </StickyTitleTd>
          {competencyCollections.map(collection => {
            return collection.competencies.length > 0 &&
              collection.competencies.map((competency, index) => {
                const competencyAverage =
                  relativeCompScores[competency.uuid]?.average?.toFixed(1);

                return (
                  <PointsTd
                    key={competency.uuid}
                    isFirstCompetency={index === 0}
                    isExpanded={isExpanded}
                    isCompact={!showScores}
                  >
                    <TableDataText secondaryFontColor isCompact={!showScores}>
                      {competencyAverage && competencyAverage > 0 ? competencyAverage : '--'}
                    </TableDataText>
                  </PointsTd>
                );
              })
          }
          )}
        </TableRow>
      }
    </>
  );
};

StudentAvgRow.propTypes = {
  competencyCollections: PropTypes.array,
  collectionStudentAvgMap: PropTypes.object,
  competencyStudentAvgMap: PropTypes.object,
  isExpanded: PropTypes.bool.isRequired,
  showScores: PropTypes.bool,
  relativeCompScores: PropTypes.object,
};

StudentAvgRow.defaultProps = {
  showScores: true,
  competencyCollections: [],
  collectionStudentAvgMap: {},
  competencyStudentAvgMap: {},
  relativeCompScores: {}
};

export default StudentAvgRow;
