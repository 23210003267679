import { useSelector } from 'react-redux';
import { selectInstitution } from '../../redux/selectors/institutionSelectors';
import { selectSchools } from '../../redux/selectors/schoolSelectors';
import { selectDepartments } from '../../redux/selectors/departmentsSelectors';
import { selectPrograms } from '../../redux/selectors/programSelectors';

export const useRoleHierarchy = role => {
  const institution = useSelector(state =>
    selectInstitution(state, role.institutionUuid)
  );
  const school = useSelector(state => selectSchools(state, role.schoolUuid));
  const department = useSelector(state =>
    selectDepartments(state, role.departmentUuid)
  );
  const program = useSelector(state => selectPrograms(state, role.programUuid));

  switch (role.role) {
    case 'institution_admin':
      return institution.institutionName;
    case 'school_admin':
      return school.schoolName;
    case 'department_faculty':
      return department.name;
    case 'program_faculty':
    case 'program_admin':
      return program.programName;
    default:
      return '';
  }
};
