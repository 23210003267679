import {
  PUT_ASSESSMENT_GRADING_TECHNIQUE,
  PUT_ASSESSMENT_GRADING_TECHNIQUE_SUCCESS,
  PUT_ASSESSMENT_GRADING_TECHNIQUE_ERROR,
  PUT_GRADE_COLLECTION_GRADING_TECHNIQUE,
  PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_SUCCESS,
  PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_ERROR,
  PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE,
  PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_SUCCESS,
  PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_ERROR
} from '../actions-type';

const doPutAssessmentGradingTechnique = (gradingTechnique, assessmentUuid) => ({
  type: PUT_ASSESSMENT_GRADING_TECHNIQUE,
  payload: {
    gradingTechnique,
    assessmentUuid
  }
});

const doPutAssessmentGradingTechniqueSuccess = result => ({
  type: PUT_ASSESSMENT_GRADING_TECHNIQUE_SUCCESS,
  payload: result
});

const doPutAssessmentGradingTechniqueError = error => ({
  type: PUT_ASSESSMENT_GRADING_TECHNIQUE_ERROR,
  payload: error
});

const doPutGradeCollectionGradingTechnique = (
  gradingTechnique,
  gradeCollectionUuid
) => ({
  type: PUT_GRADE_COLLECTION_GRADING_TECHNIQUE,
  payload: {
    gradingTechnique,
    gradeCollectionUuid
  }
});

const doPutGradeCollectionGradingTechniqueSuccess = result => ({
  type: PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_SUCCESS,
  payload: result
});

const doPutGradeCollectionGradingTechniqueError = error => ({
  type: PUT_GRADE_COLLECTION_GRADING_TECHNIQUE_ERROR,
  payload: error
});

const doPutPartGradeSettingsGradingTechnique = (
  gradingTechnique,
  partGradeSettingsUuid
) => ({
  type: PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE,
  payload: {
    gradingTechnique,
    partGradeSettingsUuid
  }
});

const doPutPartGradeSettingsGradingTechniqueSuccess = result => ({
  type: PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_SUCCESS,
  payload: result
});

const doPutPartGradeSettingsGradingTechniqueError = error => ({
  type: PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_ERROR,
  payload: error
});

export {
  doPutAssessmentGradingTechnique,
  doPutAssessmentGradingTechniqueSuccess,
  doPutAssessmentGradingTechniqueError,
  doPutGradeCollectionGradingTechnique,
  doPutGradeCollectionGradingTechniqueSuccess,
  doPutGradeCollectionGradingTechniqueError,
  doPutPartGradeSettingsGradingTechnique,
  doPutPartGradeSettingsGradingTechniqueSuccess,
  doPutPartGradeSettingsGradingTechniqueError
};
