import { Model, attr, fk } from 'redux-orm';
import _ from 'lodash';
import {
  DELETE_USER_SUCCESS,
  GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS,
  GET_USERS_BY_SEARCH_ROLES_SUCCESS,
  GET_USER_ACTIVE_SUCCESS,
  GET_USER_DEPARTMENTS_FACULTY_SUCCESS,
  GET_USER_DEPARTMENTS_SUCCESS,
  GET_USER_DEPARTMENT_SUCCESS,
  GET_USER_SUCCESS,
  POST_USER_ROLE_SUCCESS,
  PUT_USER_ROLES_SUCCESS
} from '../actions-type';

class UserDepartment extends Model {
  static reducer(action, UserDepartment, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_USER_ACTIVE_SUCCESS: {
        if (payload?.roles?.department_faculty?.length > 0) {
          const userDepartments = [...payload.roles.department_faculty];
          userDepartments.forEach(userDepartment => {
            const add = _.omit(userDepartment, ['department']);
            UserDepartment.upsert(add);
          });
        }

        if (payload?.roles?.department_staff?.length > 0) {
          const userDepartments = [...payload.roles.department_staff];
          userDepartments.forEach(userDepartment => {
            const add = _.omit(userDepartment, ['department']);
            UserDepartment.upsert(add);
          });
        }
        break;
      }
      case GET_USER_SUCCESS: {
        const userDepartments = [...payload.userDepartments];
        userDepartments.forEach(userDepartment =>
          UserDepartment.upsert(userDepartment)
        );
        break;
      }
      case GET_USER_DEPARTMENTS_FACULTY_SUCCESS: {
        const facultyUsersDepartments = [...payload.usersDepartments];
        facultyUsersDepartments.forEach(userDepartment =>
          UserDepartment.upsert(userDepartment)
        );
        break;
      }
      case GET_USER_DEPARTMENT_SUCCESS: {
        const userDepartments = [...payload.userDepartments];
        userDepartments.forEach(userDepartment =>
          UserDepartment.upsert(userDepartment)
        );
        break;
      }
      case GET_USER_DEPARTMENTS_SUCCESS: {
        const userDepartments = [...payload.userDepartments];
        userDepartments.forEach(userDepartment =>
          UserDepartment.upsert(userDepartment)
        );
        break;
      }
      case GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS: {
        const usersDepartments = [...payload.usersDepartments];
        usersDepartments.forEach(userDepartment =>
          UserDepartment.upsert(userDepartment)
        );
        break;
      }
      case PUT_USER_ROLES_SUCCESS: {
        if (payload?.departmentFacultyRemove?.length > 0) {
          const departmentFacultyRemove = [...payload.departmentFacultyRemove];
          departmentFacultyRemove.forEach(uuid =>
            UserDepartment.withId(uuid).delete()
          );
        }

        if (payload?.departmentStaffRemove?.length > 0) {
          const departmentStaffRemove = [...payload.departmentStaffRemove];
          departmentStaffRemove.forEach(uuid =>
            UserDepartment.withId(uuid).delete()
          );
        }

        const userDepartments = [...payload.userDepartments];

        if (userDepartments?.length > 0) {
          userDepartments.forEach(userDepartment =>
            UserDepartment.upsert(userDepartment)
          );
        }

        break;
      }
      case DELETE_USER_SUCCESS: {
        UserDepartment.all()
          .filter(
            userDepartment => userDepartment.userUuid === payload.userUuid
          )
          .delete();
        break;
      }
      case POST_USER_ROLE_SUCCESS: {
        if (payload.roleType === 'department_role') {
          UserDepartment.create(payload.role);
        }

        break;
      }

      case GET_USERS_BY_SEARCH_ROLES_SUCCESS: {
        if (payload?.userDepartments?.length > 0) {
          const userDepartments = [...payload.userDepartments];
          userDepartments.forEach(userDepartment =>
            UserDepartment.upsert(userDepartment)
          );
        }
        break;
      }
      default:
        return session;
    }
  }
}

UserDepartment.fields = {
  uuid: attr(),
  role: attr(),
  email: attr(),
  phone: attr(),
  status: attr(),
  startDate: attr(),
  endDate: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  userUuid: fk({
    to: 'User',
    as: 'user',
    relatedName: 'user_departments'
  }),
  departmentUuid: fk({
    to: 'Department',
    as: 'department',
    relatedName: 'user_departments'
  })
};

UserDepartment.modelName = 'UserDepartment';

UserDepartment.options = {
  idAttribute: 'uuid'
};

export default UserDepartment;
