import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import EditorSubjectExam from './EditorSubjectExam';
import {
  doGetSubjects,
  doPostMilestoneExams
} from '../../../../../../redux/actions/classificationActions';
import { selectSubjectMilestones } from '../../../../../../redux/selectors/classificationSelectors';
import { usePrevious } from '../../../../../../helpers/hooks/state.hooks';
import { validateInputString } from '../../../../../../helpers/validation/validateGeneric';

import {
  subjectExamReducer,
  subjectExamInitialState
} from './editorSubjectExamState';

export default function EditorSubjectExamContainer({ match }) {
  const { programUuid } = match.params;
  const dispatch = useDispatch();
  const [hasErrors, setHasErrors] = useState(false);

  const subjectsRedux = useSelector(
    state => selectSubjectMilestones(state, programUuid),
    _.isEqual
  );

  const subjectsPrevious = usePrevious(subjectsRedux);

  const [subjectMilestoneState, subjectMilestoneDispatch] = useReducer(
    subjectExamReducer,
    subjectExamInitialState
  );

  useEffect(() => {
    dispatch(doGetSubjects(programUuid));
  }, [dispatch, programUuid]);

  useEffect(() => {
    const equalityCheck = !_.isEqual(subjectsPrevious, subjectsRedux);

    if (equalityCheck && !_.isNil(subjectsRedux)) {
      subjectMilestoneDispatch({ type: 'populate', payload: subjectsRedux });
    }
  }, [subjectMilestoneDispatch, subjectsPrevious, subjectsRedux]);

  const handleAddExam = classificationUuid => {
    subjectMilestoneDispatch({
      type: 'add_exam',
      payload: {
        classificationUuid
      }
    });
  };

  const handleUpdate = (event, classificationUuid, examMilestoneUuid) => {
    const { name, value } = event.target;

    subjectMilestoneDispatch({
      type: 'update',
      payload: {
        classificationUuid,
        examMilestoneUuid,
        modify: { [name]: value }
      }
    });
  };

  const handleUpdateSourceInstitionUuid = (
    classificationUuid,
    examMilestoneUuid,
    sourceInstitutionUuid
  ) => {
    subjectMilestoneDispatch({
      type: 'update_source_institutionUuid',
      payload: {
        classificationUuid,
        examMilestoneUuid,
        sourceInstitutionUuid
      }
    });
  };

  const handleAddConversion = (classificationUuid, examMilestoneUuid) => {
    subjectMilestoneDispatch({
      type: 'add_conversion',
      payload: {
        classificationUuid,
        examMilestoneUuid
      }
    });
  };

  const handleUpdateConversion = (
    event,
    classificationUuid,
    examMilestoneUuid,
    conversionMilestoneUuid
  ) => {
    const { name, value } = event.target;
    subjectMilestoneDispatch({
      type: 'update_conversion',
      payload: {
        classificationUuid,
        examMilestoneUuid,
        conversionMilestoneUuid,
        modify: { [name]: value }
      }
    });
  };

  const handleRemoveConversion = (
    classificationUuid,
    examMilestoneUuid,
    conversionMilestoneUuid
  ) => {
    subjectMilestoneDispatch({
      type: 'remove_conversion',
      payload: {
        classificationUuid,
        examMilestoneUuid,
        conversionMilestoneUuid
      }
    });
  };

  const _validate = () => {
    let containErrors = false;

    const milestoneExamId = subjectMilestoneState.milestones.map(
      milestone => validateInputString(milestone.exam_milestone.examId).invalid
    );

    const milestoneExamName = subjectMilestoneState.milestones.map(
      milestone =>
        validateInputString(milestone.exam_milestone.examName).invalid
    );

    const milestoneCredits = subjectMilestoneState.milestones.map(
      milestone => validateInputString(milestone.exam_milestone.credits).invalid
    );

    const milestoneInstitutions = subjectMilestoneState.milestones.map(
      milestone =>
        validateInputString(milestone.exam_milestone.sourceInstitutionUuid)
          .invalid
    );

    const milestoneScore = _(subjectMilestoneState.milestones)
      .map(milestone => milestone.exam_milestone.conversion_milestones)
      .flatten()
      .remove(undefined)
      .map(conversion => validateInputString(conversion.scaledScore).invalid)
      .value();

    const milestonePercentage = _(subjectMilestoneState.milestones)
      .map(milestone => milestone.exam_milestone.conversion_milestones)
      .flatten()
      .remove(undefined)
      .map(conversion => validateInputString(conversion.percentage).invalid)
      .value();

    const milestoneExamIdErrors = _.includes(milestoneExamId, true);
    const milestoneExamNameErrors = _.includes(milestoneExamName, true);
    const milestoneCreditsErrors = _.includes(milestoneCredits, true);
    const milestoneScoreErrors = _.includes(milestoneScore, true);
    const milestonePercentageErrors = _.includes(milestonePercentage, true);
    const milestoneInstitutionsErrors = _.includes(milestoneInstitutions, true);

    if (
      milestoneExamIdErrors ||
      milestoneExamNameErrors ||
      milestoneCreditsErrors ||
      milestoneScoreErrors ||
      milestonePercentageErrors ||
      milestoneInstitutionsErrors
    ) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleSave = () => {
    const check = _validate();

    if (check) {
      setHasErrors(true);
    } else {
      const payload = subjectMilestoneState.milestones.map(milestone => {
        if (milestone.exam_milestone.type === 'new') {
          // tmp

          const {
            examId,
            examName,
            credits,
            examDescription,
            sourceInstitutionUuid,
            conversion_milestones
          } = milestone.exam_milestone;

          const conversion_milestones_clean = conversion_milestones
            ? conversion_milestones.map(conversion => {
                const { scaledScore, percentage } = conversion;

                return {
                  scaledScore,
                  percentage
                };
              })
            : [];

          return {
            examId,
            examName,
            credits,
            examDescription,
            conversion_milestones: conversion_milestones_clean,
            classificationUuid: milestone.uuid,
            sourceInstitutionUuid
          };
        } else {
          const {
            uuid,
            examId,
            examName,
            credits,
            examDescription,
            conversion_milestones,
            sourceInstitutionUuid
          } = milestone.exam_milestone;

          const conversion_milestones_clean = conversion_milestones
            ? conversion_milestones.map(conversion => {
                if (conversion.type === 'new') {
                  const { scaledScore, percentage } = conversion;

                  return {
                    scaledScore,
                    percentage
                  };
                } else {
                  return conversion;
                }
              })
            : [];

          return {
            uuid,
            examId,
            examName,
            credits,
            examDescription,
            conversion_milestones: conversion_milestones_clean,
            classificationUuid: milestone.uuid,
            sourceInstitutionUuid
          };
        }
      });

      dispatch(
        doPostMilestoneExams(payload, subjectMilestoneState.conversionsRemove)
      );
    }
  };

  return (
    <EditorSubjectExam
      exams={subjectMilestoneState.milestones}
      hasErrors={hasErrors}
      handleUpdate={handleUpdate}
      handleAddExam={handleAddExam}
      handleAddConversion={handleAddConversion}
      handleUpdateConversion={handleUpdateConversion}
      handleUpdateSourceInstitionUuid={handleUpdateSourceInstitionUuid}
      handleRemoveConversion={handleRemoveConversion}
      handleSave={handleSave}
    />
  );
}

EditorSubjectExamContainer.propTypes = {
  match: PropTypes.object
};

EditorSubjectExamContainer.defaultProps = {
  match: {}
};
