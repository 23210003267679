import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';

import ByInstitutionCognitiveContainer from './ByInstitutionCognitiveContainer';

const Accordion = styled(({ ...rest }) => (
  <AccordionRowContainer {...rest} />
))``;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.grey[500]};
  font-size: 14px;
`;

const AccordionArea = styled.div`
  width: 100%;
  scroll: auto;
  font-size: 12px;
`;

export default function ByInstitutionSource({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source
}) {
  return (
    <>
      <Accordion
        expandWidth="66px"
        height="70px"
        label={
          <Group>
            <Label>{`${source}`}</Label>
          </Group>
        }
      >
        <AccordionArea>
          <ByInstitutionCognitiveContainer
            userUuid={userUuid}
            cohortUuid={cohortUuid}
            programUuid={programUuid}
            category={category}
            source={source}
          />
        </AccordionArea>
      </Accordion>
    </>
  );
}

ByInstitutionSource.propTypes = {
  category: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  source: PropTypes.string
};

ByInstitutionSource.defaultProps = {
  category: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  source: undefined
};
