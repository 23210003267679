import _ from 'lodash';
import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const selectCategoryRank = createSelector(
  state => state,
  (_, userUuid, cohortUuid, category) => ({
    userUuid,
    cohortUuid,
    category
  }),
  (redux, parameters) => {
    const { userUuid, cohortUuid, category } = parameters;

    const type = `${category} Cumulative`;

    let getReportType;
    let getRank;
    let reportKey;

    switch (category) {
      case 'Cognitive': {
        getReportType = ormCreateSelector(orm.ApplicantCognitiveReportType);
        getRank = ormCreateSelector(orm.ApplicantCognitiveRank);
        reportKey = 'applicantCognitiveReportTypeUuid';
        break;
      }
      case 'Non-Cognitive': {
        getReportType = ormCreateSelector(orm.ApplicantNoncognitiveReportType);
        getRank = ormCreateSelector(orm.ApplicantNoncognitiveRank);
        reportKey = 'applicantNoncognitiveReportTypeUuid';
        break;
      }
      case 'Physical-Skills': {
        getReportType = ormCreateSelector(
          orm.ApplicantPhysicalSkillsReportType
        );
        getRank = ormCreateSelector(orm.ApplicantPhysicalSkillsRank);
        reportKey = 'applicantPhysicalSkillsReportTypeUuid';
        break;
      }
      case 'Communication': {
        getReportType = ormCreateSelector(orm.ApplicantCommunicationReportType);
        getRank = ormCreateSelector(orm.ApplicantCommunicationRank);
        reportKey = 'applicantCommunicationReportTypeUuid';
        break;
      }
      default:
        return undefined;
    }

    if (userUuid && cohortUuid && category) {
      const reports = getReportType(redux);
      const ranks = getRank(redux);

      const cognitiveReportType = _.find(reports, { name: type });

      const cognitiveReportTypeUuid = _.get(cognitiveReportType, 'uuid');

      const cognitiveApplicantRank = _.find(ranks, {
        [reportKey]: cognitiveReportTypeUuid,
        applicantUuid: userUuid,
        cohortUuid
      });

      return cognitiveApplicantRank;
    } else {
      return undefined;
    }
  }
);

export const selectSourceRank = createSelector(
  state => state,
  (_, userUuid, cohortUuid, category, source) => ({
    userUuid,
    cohortUuid,
    category,
    source
  }),
  (redux, parameters) => {
    const { userUuid, cohortUuid, category, source } = parameters;

    const set = `${category}-${source}`;

    let getReportType;
    let getRank;
    let reportKey;
    let reportType;

    switch (set) {
      case 'Cognitive-Program': {
        getReportType = ormCreateSelector(orm.ApplicantCognitiveReportType);
        getRank = ormCreateSelector(orm.ApplicantCognitiveRank);
        reportKey = 'applicantCognitiveReportTypeUuid';
        reportType = 'Program Cumulative Score';
        break;
      }
      case 'Cognitive-Milestone': {
        getReportType = ormCreateSelector(orm.ApplicantCognitiveReportType);
        getRank = ormCreateSelector(orm.ApplicantCognitiveRank);
        reportKey = 'applicantCognitiveReportTypeUuid';
        reportType = 'Milestone Cumulative';
        break;
      }
      case 'Non-Cognitive-Program': {
        getReportType = ormCreateSelector(orm.ApplicantNoncognitiveReportType);
        getRank = ormCreateSelector(orm.ApplicantNoncognitiveRank);
        reportKey = 'applicantNoncognitiveReportTypeUuid';
        reportType = 'Program Cumulative';
        break;
      }
      case 'Non-Cognitive-Milestone': {
        getReportType = ormCreateSelector(orm.ApplicantNoncognitiveReportType);
        getRank = ormCreateSelector(orm.ApplicantNoncognitiveRank);
        reportKey = 'applicantNoncognitiveReportTypeUuid';
        reportType = 'Milestone Cumulative';
        break;
      }
      case 'Physical-Skills-Program': {
        getReportType = ormCreateSelector(
          orm.ApplicantPhysicalSkillsReportType
        );
        getRank = ormCreateSelector(orm.ApplicantPhysicalSkillsRank);
        reportKey = 'applicantPhysicalSkillsReportTypeUuid';
        reportType = 'Program Cumulative';
        break;
      }
      case 'Physical-Skills-Milestone': {
        getReportType = ormCreateSelector(
          orm.ApplicantPhysicalSkillsReportType
        );
        getRank = ormCreateSelector(orm.ApplicantPhysicalSkillsRank);
        reportKey = 'applicantPhysicalSkillsReportTypeUuid';
        reportType = 'Milestone Cumulative';
        break;
      }
      case 'Communication-Program': {
        getReportType = ormCreateSelector(orm.ApplicantCommunicationReportType);
        getRank = ormCreateSelector(orm.ApplicantCommunicationRank);
        reportKey = 'applicantCommunicationReportTypeUuid';
        reportType = 'Program Cumulative';
        break;
      }
      case 'Communication-Milestone': {
        getReportType = ormCreateSelector(orm.ApplicantCommunicationReportType);
        getRank = ormCreateSelector(orm.ApplicantCommunicationRank);
        reportKey = 'applicantCommunicationReportTypeUuid';
        reportType = 'Milestone Cumulative';
        break;
      }
      default:
        return undefined;
    }

    if (userUuid && cohortUuid && category && source) {
      const reports = getReportType(redux);
      const ranks = getRank(redux);

      const cognitiveReportType = _.find(reports, { name: reportType });

      const cognitiveReportTypeUuid = _.get(cognitiveReportType, 'uuid');

      const cognitiveApplicantRank = _.find(ranks, {
        [reportKey]: cognitiveReportTypeUuid,
        applicantUuid: userUuid,
        cohortUuid
      });

      return cognitiveApplicantRank;
    } else {
      return undefined;
    }
  }
);

export const selectAptitudeExamRank = createSelector(
  state => state,
  (_, userUuid, cohortUuid, category, source, classificationUuid) => ({
    userUuid,
    cohortUuid,
    category,
    source,
    classificationUuid
  }),
  (redux, parameters) => {
    const {
      userUuid,
      cohortUuid,
      category,
      source,
      classificationUuid
    } = parameters;

    const set = `${category}-${source}`;

    let getReportType;
    let getRank;
    let reportKey;
    let reportType;

    switch (set) {
      case 'Non-Cognitive-Program': {
        getReportType = ormCreateSelector(orm.ApplicantNoncognitiveReportType);
        getRank = ormCreateSelector(orm.ApplicantNoncognitiveRank);
        reportKey = 'applicantNoncognitiveReportTypeUuid';
        reportType = 'Program Skill';
        break;
      }
      case 'Non-Cognitive-Milestone': {
        getReportType = ormCreateSelector(orm.ApplicantNoncognitiveReportType);
        getRank = ormCreateSelector(orm.ApplicantNoncognitiveRank);
        reportKey = 'applicantNoncognitiveReportTypeUuid';
        reportType = 'Milestone Skill';
        break;
      }
      case 'Physical-Skills-Program': {
        getReportType = ormCreateSelector(
          orm.ApplicantPhysicalSkillsReportType
        );
        getRank = ormCreateSelector(orm.ApplicantPhysicalSkillsRank);
        reportKey = 'applicantPhysicalSkillsReportTypeUuid';
        reportType = 'Program Skill';
        break;
      }
      case 'Physical-Skills-Milestone': {
        getReportType = ormCreateSelector(
          orm.ApplicantPhysicalSkillsReportType
        );
        getRank = ormCreateSelector(orm.ApplicantPhysicalSkillsRank);
        reportKey = 'applicantPhysicalSkillsReportTypeUuid';
        reportType = 'Milestone Skill';
        break;
      }
      case 'Communication-Program': {
        getReportType = ormCreateSelector(orm.ApplicantCommunicationReportType);
        getRank = ormCreateSelector(orm.ApplicantCommunicationRank);
        reportKey = 'applicantCommunicationReportTypeUuid';
        reportType = 'Program Skill';
        break;
      }
      case 'Communication-Milestone': {
        getReportType = ormCreateSelector(orm.ApplicantCommunicationReportType);
        getRank = ormCreateSelector(orm.ApplicantCommunicationRank);
        reportKey = 'applicantCommunicationReportTypeUuid';
        reportType = 'Milestone Skill';
        break;
      }
      default:
        return undefined;
    }

    if (userUuid && cohortUuid && category && source) {
      const reports = getReportType(redux);
      const ranks = getRank(redux);

      const cognitiveReportType = _.find(reports, { name: reportType });

      const cognitiveReportTypeUuid = _.get(cognitiveReportType, 'uuid');

      const cognitiveApplicantRank = _.find(ranks, {
        [reportKey]: cognitiveReportTypeUuid,
        applicantUuid: userUuid,
        cohortUuid,
        classificationUuid
      });

      return cognitiveApplicantRank;
    } else {
      return undefined;
    }
  }
);

export const selectSubjectRank = createSelector(
  state => state,
  (_, userUuid, cohortUuid, classificationUuid) => ({
    userUuid,
    cohortUuid,
    classificationUuid
  }),
  (redux, parameters) => {
    const { userUuid, cohortUuid, classificationUuid } = parameters;

    if (userUuid && cohortUuid && classificationUuid) {
      const getRank = ormCreateSelector(orm.ApplicantCognitiveRank);
      const getReportType = ormCreateSelector(orm.ApplicantCognitiveReportType);
      const ranks = getRank(redux);

      const reports = getReportType(redux);
      const cognitiveReportType = _.find(reports, { name: 'Program Subject' });
      const cognitiveReportTypeUuid = _.get(cognitiveReportType, 'uuid');
      const cognitiveApplicantRank = _.find(ranks, {
        applicantCognitiveReportTypeUuid: cognitiveReportTypeUuid,
        applicantUuid: userUuid,
        cohortUuid,
        classificationUuid
      });

      return cognitiveApplicantRank;
    } else {
      return undefined;
    }
  }
);
