import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core';
import {colors} from '@xcomp/xcomp-design-library';
import Decimal from 'decimal.js-light';

const useRowStyles = makeStyles({
  font: {
    fontWeight: 'normal',
  },
  thresholdMet: {
    color: colors.status.success,
  },
  thresholdNotMet: {
    color: colors.status.error,
  },
});

function CellDetails({
  row,
  getValue,
  threshold,
  value,
  displayRank,
  rank,
  isCollectionTotals,
}) {
  const classes = useRowStyles();

  const thresholdMet = useMemo(() => {
    const thresholdDecimal = new Decimal(threshold || 0);
    const valueDecimal = new Decimal(value || 0);

    if (!thresholdDecimal.gt(0) || !valueDecimal.gt(0)) return;

    return valueDecimal.gt(thresholdDecimal);
  }, [threshold, value]);

  if (isCollectionTotals) {
    return (
      <Typography
        className={`
          ${classes.font} 
          ${thresholdMet === true && classes.thresholdMet} 
          ${thresholdMet === false && classes.thresholdNotMet}
        `}
        variant="body2"
      >
        {getValue()} ({rank})
      </Typography>
    );
  }

  if (row.depth > 0 || displayRank) {
    return (
      <Typography
        className={`
          ${classes.font} 
          ${thresholdMet === true && classes.thresholdMet} 
          ${thresholdMet === false && classes.thresholdNotMet}
        `}
        variant="body2"
      >
        {getValue()} {(getValue() > 0 || getValue() !== '-') && displayRank &&
        +rank > 0 && `(${rank})`}
      </Typography>
    );
  }

  return (
    <Typography
      className={classes.font}
      variant="body2"
    >
      {getValue()}
    </Typography>
  );
};

CellDetails.propTypes = {
  row: PropTypes.object.isRequired,
  getValue: PropTypes.func.isRequired,
  threshold: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  displayRank: PropTypes.bool.isRequired,
  rank: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  isCollectionTotals: PropTypes.bool,
};

CellDetails.defaultProps = {
  isCollectionTotals: false,
};

export default CellDetails;