import { tintColor } from '@xcomp/xcomp-design-library/dist/helpers';
import React from 'react';
import styled, { css } from 'styled-components';

export const StickyTitleTd = styled(
  ({
    left,
    greyOne,
    redOne,
    redTwo,
    blueOne,
    borderRight,
    isRelativeRank,
    isRank,
    ...rest
  }) => <td {...rest} />
)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-width: ${props => (props.isRank ? '25px' : '65px')};
  max-width: ${props => (props.isRank ? '25px' : '65px')};
  z-index: 99;
  position: sticky;
  left: ${props => props.left};
  background-color: ${props =>
    (props.redOne && tintColor(props.theme.colors.red[100], 0.5)) ||
    (props.redTwo && tintColor(props.theme.colors.red[100], 0.4)) ||
    (props.blueOne && tintColor(props.theme.colors.blue[500], 0.8)) ||
    (props.orangeOne && tintColor(props.theme.colors.orange[100], 0.7)) ||
    (props.orangeTwo && tintColor(props.theme.colors.orange[100], 0.5)) ||
    'white'};
  border-top: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
  border-right: ${props =>
    props.borderRight ? `solid 1px ${props.theme.colors.grey[300]}` : 'none'};
  ${props =>
    props.isRelativeRank &&
    `
    border-left: solid 2px ${props.theme.colors.grey[500]};
    border-right: solid 1px ${props => props.theme.colors.black};
  `}
`;

export const TableDataText = styled(
  ({
    redChip,
    secondaryFontColor,
    warningFontColor,
    strikeThrough,
    disabledFontColor,
    fontColor,
    isCompact,
    bold,
    ...rest
  }) => <div {...rest} />
)`
  display: ${props => (props.isCompact === true ? 'none' : 'flex')};
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: ${props => (props.bold ? '500' : '400')};
  line-height: 24px;
  color: ${props =>
    (props.warningFontColor && props.theme.colors.status.error) ||
    (props.secondaryFontColor && props.theme.fontColors.dark) ||
    props.theme.fontColors.darker};

  ${props =>
    props.strikeThrough &&
    css`
      text-decoration: line-through;
    `};

  ${props =>
    props.disabledFontColor &&
    css`
      color: ${props => props.theme.fontColors.light};
    `};

  ${props =>
    props.fontColor &&
    props.fontColor === 'a' &&
    css`
      color: ${props => props.theme.colors.white};
    `};

  ${props =>
    props.fontColor &&
    props.fontColor === 'b' &&
    css`
      color: ${props => props.theme.colors.white};
    `};

  ${props =>
    props.fontColor &&
    props.fontColor === 'c' &&
    css`
      ${'' /* color: ${props => props.theme.colors.white}; */}
    `};

  ${props =>
    props.fontColor &&
    props.fontColor === 'd' &&
    css`
      ${'' /* color: ${props => props.theme.colors.white}; */}
    `};

  ${props =>
    props.fontColor &&
    props.fontColor === 'e' &&
    css`
      ${'' /* color: ${props => props.theme.colors.white}; */}
    `};

  .MuiChip-root {
    background-color: ${props =>
      props.redChip
        ? `${props.theme.colors.red[200]} !important`
        : props.theme.colors.blue[100]};
  }
`;

export const PointsTd = styled(
  ({
    displayNone,
    isFirstCompetency,
    isExpanded,
    greyOne,
    blueOne,
    blueTwo,
    bgColor,
    isCompact,
    ...rest
  }) => <td {...rest} />
)`
  ${props => props.displayNone && `display: none`};
  min-width: ${props =>
    props.isExpanded ? (props.isCompact && '25px') || '50px' : '75px'};
  max-width: ${props =>
    props.isExpanded ? (props.isCompact && '25px') || '50px' : '75px'};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  background-color: ${props =>
    (props.greyOne && props.theme.colors.grey[100]) ||
    (props.blueOne && tintColor(props.theme.colors.blue[500], 0.8)) ||
    (props.blueTwo && tintColor(props.theme.colors.blue[100], 0.5)) ||
    'white'};

  ${props =>
    props.bgColor &&
    props.bgColor === 'a' &&
    css`
      background-color: ${props => props.theme.colors.primaryShades[450]};
    `};

  ${props =>
    props.bgColor &&
    props.bgColor === 'b' &&
    css`
      background-color: ${props => props.theme.colors.status.success};
    `};

  ${props =>
    props.bgColor &&
    props.bgColor === 'c' &&
    css`
      background-color: ${props => props.theme.colors.green[400]};
    `};

  ${props =>
    props.bgColor &&
    props.bgColor === 'd' &&
    css`
      background-color: ${props => props.theme.colors.green[200]};
    `};

  ${props =>
    props.bgColor &&
    props.bgColor === 'e' &&
    css`
      background-color: ${props => props.theme.colors.yellow[600]};
    `};
  ${props =>
    props.isFirstCompetency &&
    `background: linear-gradient(90deg, ${tintColor(
      props.theme.colors.blue[500],
      0.8
    )} 0%, rgba(255,255,255,1) 75%)`};

  ${props =>
    props.isCompact &&
    `&:hover {
      transform: scale(1.9);
      z-index: 30 !important;
      border: 1px solid dimgray !important;
      width: 50px;

      &>div {
        display: flex;
        font-size: 8px;
        cursor: crosshair;
        font-weight: bold;
      }
    }`}
`;

export const TableRow = styled.tr`
  display: flex;
  justify-content: flex-start;
  position: relative;
  inline-size: min-content;
  ${props => props.isStudentRow && `height: 25px`};
  td:last-child {
    border-right: none;
  }
  ${props =>
    props.borderBottom &&
    `border-bottom: 2px solid ${props.theme.colors.grey[500]}`}
  ${props =>
    props.borderBottomLight &&
    `border-bottom: 1px solid ${props.theme.colors.grey[600]}`}
`;

export const StickyInfoTd = styled(
  ({ left, greyOne, borderRightStrong, ...rest }) => <td {...rest} />
)`
  min-width: 250px;
  max-width: 250px;
  z-index: 99;
  position: sticky;
  padding: 0;
  left: ${props => props.left};
  background-color: ${props =>
    (props.greyOne && props.theme.colors.grey[100]) || 'white'};
  border-top: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
  border-right: ${props =>
    props.borderRightStrong
      ? `solid 2px ${props.theme.colors.grey[500]}`
      : `solid 1px ${props.theme.colors.grey[300]}`};
`;

export const StudentInfoText = styled.div`
  height: 100%;
  text-align: center;
  color: ${props => props.theme.fontColors.darker};
  font-size: 14px;
  font-weight: 400;
`;
