import { Model, attr } from 'redux-orm';
import {
  GET_CREDENTIALS_SUCCESS,
  POST_CREDENTIAL_SUCCESS,
  PUT_CREDENTIAL_SUCCESS,
  DELETE_CREDENTIAL_SUCCESS
} from '../actions-type';

class Credential extends Model {
  static reducer(action, Credential, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_CREDENTIALS_SUCCESS: {
        const globalUserCredentials = [...payload.globalUserCredentials];
        globalUserCredentials.forEach(globalCredential =>
          Credential.upsert(globalCredential)
        );
        break;
      }

      case POST_CREDENTIAL_SUCCESS: {
        const globalUserCredential = { ...payload.globalUserCredential };
        Credential.upsert(globalUserCredential);
        break;
      }

      case PUT_CREDENTIAL_SUCCESS: {
        const globalUserCredential = { ...payload.globalUserCredential };
        Credential.upsert(globalUserCredential);
        break;
      }

      case DELETE_CREDENTIAL_SUCCESS: {
        Credential.withId(payload.globalUserCredentialUuid).delete();
        break;
      }

      default:
        return session;
    }
  }
}

Credential.fields = {
  globalUserUuid: attr(),
  institutionUuid: attr(),
  degreeLevelUuid: attr(),
  programCodeUuid: attr(),
  year: attr(),
  credential: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

Credential.modelName = 'Credential';
Credential.options = {
  idAttribute: 'uuid'
};

export default Credential;
