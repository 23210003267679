import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { loverbsByEducationLevelSelector } from '../../../../../../redux/selectors/loverbSelectors';
import LoverbShow from './LoverbShow';

export default function LoverbShowContainer({ educationLevelUuid }) {
  const loverbs = useSelector(
    state => loverbsByEducationLevelSelector(state, educationLevelUuid),
    _.isEqual
  );

  return <LoverbShow loverbs={loverbs} />;
}

LoverbShowContainer.propTypes = {
  educationLevelUuid: PropTypes.string
};
LoverbShowContainer.defaultProps = {
  educationLevelUuid: []
};
