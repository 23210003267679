import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_APPLICANT_COGNITIVE_REPORT_TYPES,
  GET_APPLICANT_COMMUNICATION_REPORT_TYPES,
  GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES,
  GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES,
  GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES,
  PUT_APPLICANT_COGNITIVE_REPORT,
  PUT_APPLICANT_COMMUNICATION_REPORT,
  PUT_APPLICANT_DEMOGRAPHIC_REPORT,
  PUT_APPLICANT_NONCOGNITIVE_REPORT,
  PUT_APPLICANT_PHYSICAL_SKILLS_REPORT
} from '../actions-type';
import {
  doGetApplicantCognitiveReportTypesError,
  doGetApplicantCognitiveReportTypesSuccess,
  doGetApplicantCommunicationReportTypesError,
  doGetApplicantCommunicationReportTypesSuccess,
  doGetApplicantDemographicReportTypesError,
  doGetApplicantDemographicReportTypesSuccess,
  doGetApplicantNoncognitiveReportTypesError,
  doGetApplicantNoncognitiveReportTypesSuccess,
  doGetApplicantPhysicalSkillsReportTypesError,
  doGetApplicantPhysicalSkillsReportTypesSuccess,
  doPutApplicantCognitiveReportError,
  doPutApplicantCognitiveReportSuccess,
  doPutApplicantCommunicationReportError,
  doPutApplicantCommunicationReportSuccess,
  doPutApplicantDemographicReportError,
  doPutApplicantDemographicReportSuccess,
  doPutApplicantNoncognitiveReportError,
  doPutApplicantNoncognitiveReportSuccess,
  doPutApplicantPhysicalSkillsReportError,
  doPutApplicantPhysicalSkillsReportSuccess
} from '../actions/applicantReportActions';
import {
  getApplicantCognitiveReportTypes,
  getApplicantCommunicationReportTypes,
  getApplicantDemographicReportTypes,
  getApplicantNoncognitiveReportTypes,
  getApplicantPhysicalSkillsReportTypes,
  putApplicantCognitiveReport,
  putApplicantCommunicationReport,
  putApplicantDemographicReport,
  putApplicantNoncognitiveReport,
  putApplicantPhysicalSkillsReport
} from '../../api/applicantReportApi';

function* handlePutApplicantDemographicReportSaga(action) {
  try {
    const result = yield call(
      putApplicantDemographicReport,
      action.payload.cohortUuid,
      action.payload.reportTypeUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutApplicantDemographicReportSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutApplicantDemographicReportError(error));
  }
}

function* handlePutApplicantCognitiveReportSaga(action) {
  try {
    const result = yield call(
      putApplicantCognitiveReport,
      action.payload.cohortUuid,
      action.payload.applicantCognitiveReportTypeUuid,
      action.payload.classificationUuid,
      action.payload.rankBy
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutApplicantCognitiveReportSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutApplicantCognitiveReportError(error));
  }
}

function* handlePutApplicantNoncognitiveReportSaga(action) {
  try {
    const result = yield call(
      putApplicantNoncognitiveReport,
      action.payload.cohortUuid,
      action.payload.applicantNoncognitiveReportTypeUuid,
      action.payload.classificationUuid,
      action.payload.examMilestoneUuid,
      action.payload.examAptitudeUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutApplicantNoncognitiveReportSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutApplicantNoncognitiveReportError(error));
  }
}

function* handlePutApplicantPhysicalSkillsReportSaga(action) {
  try {
    const result = yield call(
      putApplicantPhysicalSkillsReport,
      action.payload.cohortUuid,
      action.payload.applicantPhysicalSkillsReportTypeUuid,
      action.payload.classificationUuid,
      action.payload.examMilestoneUuid,
      action.payload.examAptitudeUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutApplicantPhysicalSkillsReportSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutApplicantPhysicalSkillsReportError(error));
  }
}

function* handlePutApplicantCommunicationReportSaga(action) {
  try {
    const result = yield call(
      putApplicantCommunicationReport,
      action.payload.cohortUuid,
      action.payload.applicantCommunicationReportTypeUuid,
      action.payload.classificationUuid,
      action.payload.examMilestoneUuid,
      action.payload.examAptitudeUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutApplicantCommunicationReportSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutApplicantCommunicationReportError(error));
  }
}

function* handleGetApplicantDemographicReportTypesSaga() {
  try {
    const result = yield call(getApplicantDemographicReportTypes);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetApplicantDemographicReportTypesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetApplicantDemographicReportTypesError(error));
  }
}

function* handleGetApplicantCognitiveReportTypesSaga() {
  try {
    const result = yield call(getApplicantCognitiveReportTypes);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetApplicantCognitiveReportTypesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetApplicantCognitiveReportTypesError(error));
  }
}

function* handleGetApplicantNoncognitiveReportTypesSaga() {
  try {
    const result = yield call(getApplicantNoncognitiveReportTypes);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetApplicantNoncognitiveReportTypesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetApplicantNoncognitiveReportTypesError(error));
  }
}

function* handleGetApplicantPhysicalSkillsReportTypesSaga() {
  try {
    const result = yield call(getApplicantPhysicalSkillsReportTypes);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetApplicantPhysicalSkillsReportTypesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetApplicantPhysicalSkillsReportTypesError(error));
  }
}

function* handleGetApplicantCommunicationReportTypesSaga() {
  try {
    const result = yield call(getApplicantCommunicationReportTypes);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetApplicantCommunicationReportTypesSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetApplicantCommunicationReportTypesError(error));
  }
}

const applicantReports = [
  takeEvery(
    PUT_APPLICANT_DEMOGRAPHIC_REPORT,
    handlePutApplicantDemographicReportSaga
  ),
  takeEvery(
    PUT_APPLICANT_COGNITIVE_REPORT,
    handlePutApplicantCognitiveReportSaga
  ),
  takeEvery(
    GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES,
    handleGetApplicantDemographicReportTypesSaga
  ),
  takeEvery(
    GET_APPLICANT_COGNITIVE_REPORT_TYPES,
    handleGetApplicantCognitiveReportTypesSaga
  ),
  takeEvery(
    GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES,
    handleGetApplicantNoncognitiveReportTypesSaga
  ),
  takeEvery(
    PUT_APPLICANT_NONCOGNITIVE_REPORT,
    handlePutApplicantNoncognitiveReportSaga
  ),
  takeEvery(
    PUT_APPLICANT_PHYSICAL_SKILLS_REPORT,
    handlePutApplicantPhysicalSkillsReportSaga
  ),
  takeEvery(
    GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES,
    handleGetApplicantPhysicalSkillsReportTypesSaga
  ),
  takeEvery(
    PUT_APPLICANT_COMMUNICATION_REPORT,
    handlePutApplicantCommunicationReportSaga
  ),
  takeEvery(
    GET_APPLICANT_COMMUNICATION_REPORT_TYPES,
    handleGetApplicantCommunicationReportTypesSaga
  )
];

export { applicantReports };
