import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ThirdParty from './ThirdParty';
import { useQuery } from '../../../../helpers/hooks/router.hooks';
import { userSelector } from '../../../../redux/selectors/usersSelectors';
import { selectPrograms } from '../../../../redux/selectors/programSelectors';
import { validateInputString } from '../../../../helpers/validation/validateGeneric';
import { doPutThirdPartyId } from '../../../../redux/actions/thirdPartyActions';
import { selectThirdPartyId } from '../../../../redux/selectors/thirdPartySelectors';

export default function ThirdPartyContainer({
  userUuid,
  openModal,
  handleThirdPartyClose
}) {
  const dispatch = useDispatch();
  const [hasErrors, setHasErrors] = useState(false);
  const [thirdPartyId, setThirdPartyId] = useState(undefined);
  const query = useQuery();
  const programUuid = query.get('programUuid');

  const user = useSelector(state => {
    if (userUuid) {
      return userSelector(state, userUuid);
    } else {
      return undefined;
    }
  });

  const program = useSelector(state => selectPrograms(state, programUuid));

  const thirdPartyIdSelected = useSelector(state =>
    selectThirdPartyId(state, userUuid, programUuid)
  );

  const _validate = () => {
    let containErrors = false;

    const thirdPartyIdError = validateInputString(thirdPartyId);

    if (thirdPartyIdError.invalid) {
      containErrors = true;
    }

    return containErrors;
  };

  useEffect(() => {
    if (thirdPartyIdSelected) {
      setThirdPartyId(thirdPartyIdSelected.thirdPartyId);
    }
  }, [thirdPartyIdSelected]);

  useEffect(() => {
    if (!openModal) {
      setThirdPartyId(undefined);
      setHasErrors(false);
    }
  }, [openModal]);

  const handleThirdPartyChange = event => {
    const { value } = event.target;

    setThirdPartyId(value);
  };

  const handleSaveThirdParty = () => {
    const check = _validate();

    if (check) {
      setHasErrors(true);
    } else {
      dispatch(doPutThirdPartyId(userUuid, programUuid, thirdPartyId));
    }
  };

  return (
    <ThirdParty
      thirdPartyId={thirdPartyId}
      hasErrors={hasErrors}
      user={user}
      program={program}
      openModal={openModal}
      handleThirdPartyClose={handleThirdPartyClose}
      handleThirdPartyChange={handleThirdPartyChange}
      handleSaveThirdParty={handleSaveThirdParty}
    />
  );
}

ThirdPartyContainer.propTypes = {
  userUuid: PropTypes.string,
  openModal: PropTypes.bool,
  handleThirdPartyClose: PropTypes.func
};
ThirdPartyContainer.defaultProps = {
  userUuid: undefined,
  openModal: false,
  handleThirdPartyClose: undefined
};
