import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import _ from 'lodash';
import CohortEditRole from './CohortEditRole';
import { doGetCohort } from '../../../redux/actions/cohortActions';
import {
  doGetUserCohortbyUserAndCohort,
  doPostUserCohorts,
  doDeleteUserCohort
} from '../../../redux/actions/userCohortActions';
import { cohortSelectorByMatchParams } from '../../../redux/selectors/cohortsSelectors';
import { userCohortSelector } from '../../../redux/selectors/userCohortsSelectors';
import DeleteModal from '../../Library/Modal/DeleteModal/DeleteModal';
import {
  validateUserRolesCohort,
  validateUserRolesEmailPhone
} from '../../../helpers/validation/validateUser';

import RoleCohortContainer from '../RoleCohort/RoleCohortContainer';

export default function CohortEditRoleContainer({ match }) {
  const dispatch = useDispatch();
  const { cohortUuid, userUuid } = match.params;
  const lastLocation = useLastLocation();

  const path =
    lastLocation === null
      ? '/program-management/cohort'
      : lastLocation.pathname;

  const [cohortRoles, setCohortRole] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [openRoleCohort, setOpenRoleCohort] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectDelete, setSelectDelete] = useState(undefined);

  const cohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    shallowEqual
  );

  const userCohortRoles = useSelector(
    state => userCohortSelector(state, userUuid, cohortUuid),
    _.isEqual
  );

  useEffect(() => {
    setCohortRole(userCohortRoles);
  }, [userCohortRoles]);

  useEffect(() => {
    dispatch(doGetCohort(cohortUuid));
    dispatch(doGetUserCohortbyUserAndCohort(userUuid, cohortUuid));
  }, [dispatch, userUuid, cohortUuid]);

  const handleFormRoleChange = (uuid, event) => {
    const { name, value } = event.target;

    let update;

    if (name === 'status') {
      update = {
        status: value
      };
    }

    if (name === 'role') {
      update = {
        role: value
      };
    }

    if (name === 'email') {
      update = {
        email: value
      };
    }

    if (name === 'phone') {
      update = {
        phone: value
      };
    }

    setCohortRole(cohortRoles => {
      return cohortRoles.map(cohortRole => {
        if (cohortRole.uuid === uuid) {
          return { ...cohortRole, ...update };
        } else {
          return { ...cohortRole };
        }
      });
    });
  };

  const handleFormRoleDateChange = (indentifier, field, date) => {
    let update;

    if (field === 'startDate') {
      update = {
        startDate: date
      };
    }

    if (field === 'endDate') {
      update = {
        endDate: date
      };
    }

    setCohortRole(cohortRoles => {
      return cohortRoles.map(cohortRole => {
        if (cohortRole.uuid === indentifier) {
          return { ...cohortRole, ...update };
        } else {
          return { ...cohortRole };
        }
      });
    });
  };

  const handleCohortRoleOpen = () => {
    setOpenRoleCohort(true);
  };

  const handleCohortRoleClose = () => {
    setOpenRoleCohort(false);
  };

  const handleDeleteModalOpen = uuid => {
    setSelectDelete(uuid);
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setSelectDelete(undefined);
  };

  const handleDeletion = () => {
    dispatch(doDeleteUserCohort(selectDelete, userUuid));
    setDeleteModal(false);
    setSelectDelete(undefined);
  };

  const validate = () => {
    const rolesSelectionError = validateUserRolesCohort(cohortRoles);
    const rolesTextError = validateUserRolesEmailPhone(cohortRoles);

    const validations = {
      selectionsInvalid: rolesSelectionError,
      textFieldsInvalid: rolesTextError
    };

    const isValid = !_.some(validations, { invalid: true });

    return isValid;
  };

  const handleUpdateUserCohortRoles = () => {
    const isValid = validate();

    if (isValid) {
      const payload = {
        cohortRoles
      };
      dispatch(doPostUserCohorts(payload));
    } else {
      setHasErrors(true);
    }
  };

  return (
    <>
      <CohortEditRole
        deleteModal={deleteModal}
        path={path}
        cohort={cohort}
        cohortUuid={cohortUuid}
        userUuid={userUuid}
        cohortRoles={cohortRoles}
        hasErrors={hasErrors}
        handleFormRoleChange={handleFormRoleChange}
        handleFormRoleDateChange={handleFormRoleDateChange}
        handleCohortRoleOpen={handleCohortRoleOpen}
        handleDeleteModalOpen={handleDeleteModalOpen}
        handleUpdateUserCohortRoles={handleUpdateUserCohortRoles}
      />
      <RoleCohortContainer
        userUuid={userUuid}
        cohortUuid={cohortUuid}
        openRoleCohort={openRoleCohort}
        handleCohortRoleClose={handleCohortRoleClose}
      />
      <DeleteModal
        modalOpen={deleteModal}
        handleModalClose={handleDeleteModalClose}
        handleDelete={handleDeletion}
        type="Cohort Role"
      />
    </>
  );
}

CohortEditRoleContainer.propTypes = {
  match: PropTypes.object
};

CohortEditRoleContainer.defaultProps = {
  match: {}
};
