import _ from 'lodash';
import moment from 'moment';

export const generateSectionNumber = sectionsArr => {
  if (!sectionsArr || sectionsArr.length < 1) {
    return 1;
  }

  const sortedArr = sectionsArr.sort(
    (a, b) => a.sectionNumber - b.sectionNumber
  );

  const highestSectionNumber = _.get(
    sortedArr[sortedArr.length - 1],
    'sectionNumber'
  );

  if (!highestSectionNumber) {
    return 1;
  }

  return Number(highestSectionNumber) + 1;
};

export const generatePartNumber = partsArr =>
  !partsArr ? 1 : partsArr.length + 1;

export const generateObjectiveNumber = objectivesArr =>
  !objectivesArr ? 1 : objectivesArr.length + 1;

export const generateMaxRevus = (startDateTimezone, endDateTimezone) => {
  if (
    startDateTimezone &&
    endDateTimezone &&
    startDateTimezone !== endDateTimezone
  ) {
    const startDateTimezoneMoment = moment(startDateTimezone);
    const endDateTimeoneMoment = moment(endDateTimezone);

    const maxRevus =
      moment(endDateTimeoneMoment).diff(
        moment(startDateTimezoneMoment),
        'minutes'
      ) / 15;

    const formatMaxRevus = (Math.round(maxRevus * 10) / 10).toFixed(1);

    return formatMaxRevus;
  } else {
    return undefined;
  }
};
