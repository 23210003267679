import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2 } from '@xcomp/xcomp-design-library';
import ItemStatusSummary from '../ItemStatusSummary/ItemStatusSummary';
import {
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader
} from '../../../../Library/Layout';
import Header from '../../../../Library/Header';
import StatusActionButtons from './StatusActionButtons';
import PartGradingContainer from './PartGrading/PartGradingContainer';
import {
  StatusCommitPill,
  StatusGradePill,
  PartStatusPill
} from '../../../../Library/StatusPill';

const AttemptHeader = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }
`;

const Label = styled.div`
  color: ${props => props.theme.fontColors.darker};
  position: relative;
  display: inline-block;
  z-index: 100;
  background: ${props => props.theme.colors.white};
  padding-right: 0.5rem;
  font-size: 14px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 19px;
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const StatusSelectionFormSection = styled.div`
  display: flex;
  position: relative;
  z-index: 400;
  flex-direction: column;
  margin: 32px 0 16px;
`;

const ItemStatusSummarySC = styled(ItemStatusSummary)`
  margin-top: 0;
  margin-bottom: 48px;
`;

const StatusPills = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
`;

export default function PartStatusSettings({
  part,
  partTitle,
  courseNumber,
  courseTitle,
  sectionNumber,
  partNumber,
  status,
  handleStatusPublish,
  handleStatusUnpublish,
  handleStatusApprove,
  handleStatusUnapprove
}) {
  const headerSubtitle = `${courseNumber} ● ${courseTitle} ● Section ${sectionNumber} ● Part ${partNumber}`;

  const isApproved = status === 'Committed';
  const isPublished = status === 'Published';

  return (
    <ContainerResize>
      <Header title={partTitle} subtitle={headerSubtitle} />
      <ContainerPage>
        <Panel>
          <PanelHeader paddingLeft="24px" paddingRight="24px">
            <Heading2>Part Settings</Heading2>
            <StatusPills>
              <PartStatusPill status={status} />
              <StatusGradePill statusGrade={part?.statusGrade} />
              <StatusCommitPill statusCommit={part?.statusCommit} />
            </StatusPills>
          </PanelHeader>
          <PanelPadding>
            <StatusSelectionFormSection>
              <StatusActionButtons
                part={part}
                handleStatusApprove={handleStatusApprove}
                handleStatusUnapprove={handleStatusUnapprove}
                handleStatusPublish={handleStatusPublish}
                handleStatusUnpublish={handleStatusUnpublish}
                isApproved={isApproved}
                isPublished={isPublished}
              />
            </StatusSelectionFormSection>

            <AttemptHeader offset="0">
              <Label>syllabus status</Label>
            </AttemptHeader>
            <ItemStatusSummarySC part={part} />
            <PartGradingContainer partUuid={part?.uuid} />
          </PanelPadding>
        </Panel>
      </ContainerPage>
    </ContainerResize>
  );
}

PartStatusSettings.propTypes = {
  partTitle: PropTypes.string,
  courseNumber: PropTypes.string,
  courseTitle: PropTypes.string,
  sectionNumber: PropTypes.string,
  partNumber: PropTypes.string,
  part: PropTypes.object,
  status: PropTypes.oneOf(['In Progress', 'Committed', 'Published']),
  handleStatusApprove: PropTypes.func,
  handleStatusUnapprove: PropTypes.func,
  handleStatusPublish: PropTypes.func,
  handleStatusUnpublish: PropTypes.func
};

PartStatusSettings.defaultProps = {
  partTitle: '',
  courseNumber: '',
  courseTitle: '',
  sectionNumber: '',
  partNumber: '',
  part: {},
  status: 'In Progress',
  handleStatusApprove: undefined,
  handleStatusUnapprove: undefined,
  handleStatusPublish: undefined,
  handleStatusUnpublish: undefined
};
