import React from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Record = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => (props.width ? props.width : '120px')};
`;

export default function CognitiveProgramRowHeader() {
  return (
    <Layout>
      <Record>&nbsp;</Record>
      <Record>Term</Record>
      <Record>Year</Record>
      <Record>Item Code</Record>
      <Record width="300px">Item Name</Record>
      <Record>Subject</Record>
      <Record width="300px" item xs={2} sm={2}>
        Institution
      </Record>
      <Record>Credits</Record>
      <Record>Grade</Record>
      <Record>Score</Record>
      <Record width="66px">&nbsp;</Record>
    </Layout>
  );
}

CognitiveProgramRowHeader.propTypes = {};
CognitiveProgramRowHeader.defaultProps = {};
