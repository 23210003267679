import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import InstitutionManagement from './InstitutionManagement';
import { institutionActiveUserSelector } from '../../redux/selectors/institutionSelectors';
import { validateInputString } from '../../helpers/validation/validateGeneric';
import { doPutInstitution } from '../../redux/actions/institutionActions';

export default function InstitutionManagementContainer() {
  const dispatch = useDispatch();
  const [institutionName, setInstitutionName] = useState('');
  const [logoUuid, setLogoUuid] = useState('');
  const [logo, setLogo] = useState('');
  const [picture, setPicture] = useState({});
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [displayPrimaryPicker, setDisplayPrimaryPicker] = useState(false);
  const [displaySecondaryPicker, setDisplaySecondaryPicker] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const institution = useSelector(
    state => institutionActiveUserSelector(state),
    shallowEqual
  );

  useEffect(() => {
    if (institution) {
      setInstitutionName(institution.institutionName);
      setPrimaryColor(institution.primaryColor);
      setSecondaryColor(institution.secondaryColor);

      if (institution.logoUuid === null) {
        setLogoUuid(undefined);
      } else {
        setLogoUuid(institution.logoUuid);
      }

      if (institution.logoUuid === null || !institution.logo) {
        setLogo(undefined);
      } else {
        setLogo(institution.logo);
      }
    }
  }, [institution]);

  const handleChangeName = event => {
    setInstitutionName(event.target.value);
  };

  const handlePrimaryColor = color => {
    setPrimaryColor(color.hex);
  };

  const handlePrimaryOpen = () => {
    setDisplayPrimaryPicker(true);
  };

  const handlePrimaryClose = () => {
    setDisplayPrimaryPicker(false);
  };

  const handleSecondaryColor = color => {
    setSecondaryColor(color.hex);
  };

  const handleSecondaryOpen = () => {
    setDisplaySecondaryPicker(true);
  };

  const handleSecondaryClose = () => {
    setDisplaySecondaryPicker(false);
  };

  const handleImageUpload = event => {
    const files = Array.from(event.target.files);
    setPicture(files[0]);
  };

  const validate = () => {
    let validationErrors = false;

    const institutionNameError = validateInputString(institutionName);

    if (institutionNameError.invalid) {
      validationErrors = true;
    }

    return validationErrors;
  };

  const handleUpdateInstitution = () => {
    const errors = validate();

    if (errors) {
      setHasErrors(true);
    } else {
      const payload = {
        uuid: institution.uuid,
        institutionName,
        logoUuid,
        logo,
        primaryColor,
        secondaryColor
      };

      dispatch(doPutInstitution(payload, picture));
    }
  };

  return (
    <InstitutionManagement
      hasErrors={hasErrors}
      institutionName={institutionName}
      logo={logo}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      displayPrimaryPicker={displayPrimaryPicker}
      displaySecondaryPicker={displaySecondaryPicker}
      handleChangeName={handleChangeName}
      handlePrimaryColor={handlePrimaryColor}
      handlePrimaryOpen={handlePrimaryOpen}
      handlePrimaryClose={handlePrimaryClose}
      handleSecondaryColor={handleSecondaryColor}
      handleSecondaryOpen={handleSecondaryOpen}
      handleSecondaryClose={handleSecondaryClose}
      handleImageUpload={handleImageUpload}
      handleUpdateInstitution={handleUpdateInstitution}
    />
  );
}
