import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Grid, IconButton } from '@material-ui/core';
import { ButtonOutline } from '@xcomp/xcomp-design-library';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';

import FormField from '../../../../../Library/FormField';
import FormSelectError from '../../../../../Library/FormSelectError';

import {
  validateInputString,
  validateSelectField
} from '../../../../../../helpers/validation/validateGeneric';
import { optionsTranscriptSource } from '../../../../../../helpers/options';

import {
  FormSectionHeader,
  FormSectionHeaderSubtitle
} from '../../../../../Library/FormComponents';

import EditorExam from './EditorExam';

const FormSectionHeaderSC = styled(FormSectionHeader)`
  && {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

const DeleteIconSC = styled(DeleteIcon)`
  display: flex;
  align-self: center;
`;

const IconArea = styled.div`
  position: absolute;
  top: -3px;
  right: 0;
  width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.white};
  z-index: 25;
`;

export default function EditorScope({
  index,
  classification,
  hasErrors,
  handleUpdateSource,
  handleUpdateScope,
  handleRemoveClassification,
  handleAddExam,
  handleUpdateExam,
  handleRemoveExam,
  handleAddConversion,
  handleUpdateConversion,
  handleRemoveConversion
}) {
  return (
    <Grid container spacing={4} direction="row">
      <Grid item sm={12} xs={12}>
        <FormSectionHeaderSC offset="0">
          <FormSectionHeaderSubtitle>
            Scope {index + 1}
          </FormSectionHeaderSubtitle>
          <IconArea>
            <IconButton
              onClick={() => handleRemoveClassification(classification?.uuid)}
            >
              <DeleteIconSC />
            </IconButton>
          </IconArea>
        </FormSectionHeaderSC>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormSelectError
          name="source"
          value={classification?.source}
          label="Transcript Source*"
          handleChange={event =>
            handleUpdateSource(event, classification?.uuid)
          }
          options={optionsTranscriptSource}
          hasErrors={hasErrors}
          handleValidation={validateSelectField}
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <FormField
          name="scope"
          label="Scope *"
          value={classification?.scope}
          onBlur={event => handleUpdateScope(event, classification?.uuid)}
          handleValidate={validateInputString}
          hasErrors={hasErrors}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        {classification.exam_aptitudes?.map((exam, index) => (
          <EditorExam
            key={exam?.uuid}
            index={index}
            hasErrors={hasErrors}
            classificationUuid={classification?.uuid}
            exam={exam}
            handleUpdateExam={handleUpdateExam}
            handleRemoveExam={handleRemoveExam}
            handleAddConversion={handleAddConversion}
            handleUpdateConversion={handleUpdateConversion}
            handleRemoveConversion={handleRemoveConversion}
          />
        ))}
      </Grid>
      <Grid item xs={12} sm={12}>
        <ButtonOutline onClick={() => handleAddExam(classification?.uuid)}>
          Add Exam
        </ButtonOutline>
      </Grid>
    </Grid>
  );
}

EditorScope.propTypes = {
  index: PropTypes.number,
  classification: PropTypes.object,
  hasErrors: PropTypes.bool,
  handleUpdateSource: PropTypes.func,
  handleUpdateScope: PropTypes.func,
  handleRemoveClassification: PropTypes.func,
  handleAddExam: PropTypes.func,
  handleUpdateExam: PropTypes.func,
  handleRemoveExam: PropTypes.func,
  handleAddConversion: PropTypes.func,
  handleUpdateConversion: PropTypes.func,
  handleRemoveConversion: PropTypes.func
};

EditorScope.defaultProps = {
  index: 0,
  classification: undefined,
  hasErrors: false,
  handleUpdateSource: undefined,
  handleUpdateScope: undefined,
  handleRemoveClassification: undefined,
  handleAddExam: undefined,
  handleUpdateExam: undefined,
  handleRemoveExam: undefined,
  handleAddConversion: undefined,
  handleUpdateConversion: undefined,
  handleRemoveConversion: undefined
};
