import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import InputBase from '@material-ui/core/InputBase';
import { ButtonInline, ButtonPrimary } from '@xcomp/xcomp-design-library';
import Search from '@material-ui/icons/Search';

const Layout = styled.div`
  position: absolute;
  background: ${props => props.theme.colors.white};
  width: 1000px;
  height: 670px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RowHeader = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 24px;
`;

const RowSearch = styled.div`
  height: 500px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid ${props => props.theme.colors.grey[300]};
  border-radius: 4px;
`;

const SearchUser = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const SearchGroup = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
  border-radius: 4px;
  height: 50px;
`;

const SearchLabel = styled.div`
  padding-right: 10px;
  color: ${props => props.theme.fontColors.darker};
`;

const SearchInput = styled(({ ...rest }) => <InputBase {...rest} />)`
  && {
    padding-left: 10px;
    border-left: 1px solid ${props => props.theme.colors.grey[300]};
    height: 50px;
  }
`;

const SearchIcon = styled(({ ...rest }) => <Search {...rest} />)`
  && {
    height: 50px;
    width: 50px;
    color: ${props => props.theme.colors.grey[300]};
    padding: 10px;
    border-radius: 0px 4px 4px 0px;
  }
`;

const RowAdd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100px;
  margin-left: 20px;
  margin-right: 20px;
`;

const SearchList = styled.div``;

const SearchListTitles = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
`;

const IntitutionId = styled.div`
  width: 28%;
  font-size: 12px;
  padding-left: 20px;
  padding-top: 15px;
  height: 50px;
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Name = styled.div`
  font-size: 12px;
  width: 12%;
  padding-top: 15px;
  padding-left: 20px;
  height: 50px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Role = styled.div`
  font-size: 12px;
  width: 12%;
  padding-top: 15px;
  padding-left: 20px;
  height: 50px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Status = styled.div`
  font-size: 12px;
  width: 12%;
  padding-top: 15px;
  padding-left: 20px;
  height: 50px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Cohort = styled.div`
  font-size: 12px;
  width: 12%;
  padding-top: 15px;
  padding-left: 20px;
  height: 50px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const CohortStartDate = styled.div`
  font-size: 12px;
  width: 12%;
  padding-top: 15px;
  padding-left: 20px;
  height: 50px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const CohortEndDate = styled.div`
  font-size: 12px;
  width: 12%;
  padding-top: 15px;
  padding-left: 20px;
  height: 50px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const SearchListUser = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  background ${props =>
    props.highlight ? props.theme.colors.grey[500] : 'none'};
`;

const Cancel = styled(ButtonInline)`
  && {
    margin-right: 30px;
  }
`;

export default function StudentAssign({
  openAssignStudent,
  searchInput,
  searchUser,
  selectedToggle,
  handleSearchChange,
  handleSearch,
  handleUserSelection,
  handleAssignStudentClose,
  handleAssignStudent
}) {
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="assign-modal-title"
        aria-describedby="assign-modal-description"
        open={openAssignStudent}
        onClose={handleAssignStudentClose}
      >
        <Layout>
          <RowHeader>Assign Student to Section</RowHeader>
          <RowSearch>
            <SearchUser>
              <SearchGroup>
                <SearchLabel>Institution ID</SearchLabel>
                <SearchInput
                  value={searchInput}
                  onChange={event => handleSearchChange(event)}
                  onKeyDown={handleKeyDown}
                />
                <SearchIcon onClick={handleSearch} />
              </SearchGroup>
            </SearchUser>
            <SearchList>
              <SearchListTitles>
                <IntitutionId>Institution Id</IntitutionId>
                <Name>Name</Name>
                <Role>Role</Role>
                <Status>Status</Status>
                <Cohort>Cohort</Cohort>
                <CohortStartDate>Cohort Start</CohortStartDate>
                <CohortEndDate>Cohort End</CohortEndDate>
              </SearchListTitles>

              {searchUser && (
                <SearchListUser
                  key={`${searchUser?.userCohortUuid}`}
                  highlight={selectedToggle}
                  onClick={() => handleUserSelection(searchUser?.user?.uuid)}
                >
                  <IntitutionId>{searchUser?.user?.institutionId}</IntitutionId>
                  <Name>{`${searchUser?.user?.firstName} ${searchUser?.user?.lastName}`}</Name>
                  <Role>{searchUser?.role}</Role>
                  <Status>{searchUser?.status}</Status>
                  <Cohort>{searchUser?.cohort}</Cohort>
                  <CohortStartDate>
                    {searchUser?.cohortStartDate}
                  </CohortStartDate>
                  <CohortEndDate>{searchUser?.cohortEndDate}</CohortEndDate>
                </SearchListUser>
              )}
            </SearchList>
          </RowSearch>
          <RowAdd>
            <Cancel onClick={handleAssignStudentClose}>Cancel</Cancel>
            <ButtonPrimary
              disabled={!selectedToggle}
              onClick={handleAssignStudent}
            >
              Add to Section
            </ButtonPrimary>
          </RowAdd>
        </Layout>
      </Modal>
    </div>
  );
}

StudentAssign.propTypes = {
  selectedToggle: PropTypes.bool,
  openAssignStudent: PropTypes.bool,
  searchInput: PropTypes.string,
  searchUser: PropTypes.object,
  handleAssignStudentClose: PropTypes.func,
  handleSearchChange: PropTypes.func,
  handleSearch: PropTypes.func,
  handleUserSelection: PropTypes.func,
  handleAssignStudent: PropTypes.func
};

StudentAssign.defaultProps = {
  selectedToggle: false,
  openAssignStudent: false,
  searchInput: '',
  searchUser: undefined,
  handleAssignStudentClose: undefined,
  handleSearchChange: undefined,
  handleSearch: undefined,
  handleUserSelection: undefined,
  handleAssignStudent: undefined
};
