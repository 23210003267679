import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { Subtitle1 } from '@xcomp/xcomp-design-library';
import { useSelector, shallowEqual } from 'react-redux';

import { partSelectorByUuid } from '../../../../redux/selectors/partsSelectors';

import {
  Column,
  TextFieldWithError,
  FormSectionHeaderSubtitle,
  FormSectionHeader
} from '../../../Library/FormComponents';
import { validateDecimalStringField } from '../../../../helpers/validation/validateGeneric';

const FormSectionHeaderSC = styled(FormSectionHeader)`
  position: relative;
`;

// const PartTitle = styled(Subtitle1)`
//   margin-left: 32px;
// `;

const PartRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 48px;
`;

const ColumnPaddingRight = styled(Column)`
  padding-right: 24px;
`;

const PartWeightRow = ({
  index,
  uuid,
  weight,
  distributeWeightEqually,
  onPartWeightChange,
  showValidationErrors
}) => {
  const part = useSelector(
    state => partSelectorByUuid(state, uuid),
    shallowEqual
  );
  const handleWeightChange = event => onPartWeightChange(event, uuid);
  const validateWeightField = weight => validateDecimalStringField(weight);

  const partNumber = index + 1;

  return (
    <>
      <Column size="100%">
        <FormSectionHeaderSC offset="0">
          <FormSectionHeaderSubtitle>
            Part {partNumber} - {part?.title}
          </FormSectionHeaderSubtitle>
        </FormSectionHeaderSC>
      </Column>
      <PartRow key={uuid}>
        <ColumnPaddingRight size="65%">
          {/* <PartTitle>{part?.title}</PartTitle> */}
        </ColumnPaddingRight>
        <Column size="35%">
          <TextFieldWithError
            id={`collectionWeight-${partNumber}`}
            name="weight"
            value={weight}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            label="Weight*"
            variant="outlined"
            onChange={handleWeightChange}
            handleValidate={
              distributeWeightEqually
                ? () => ({
                    invalid: false
                  })
                : validateWeightField
            }
            hasErrors={showValidationErrors}
            disabled={Boolean(distributeWeightEqually)}
          />
        </Column>
      </PartRow>
    </>
  );
};

PartWeightRow.propTypes = {
  index: PropTypes.number,
  uuid: PropTypes.string.isRequired,
  weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  distributeWeightEqually: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  onPartWeightChange: PropTypes.func
};

PartWeightRow.defaultProps = {
  index: 0,
  weight: 100,
  distributeWeightEqually: false,
  showValidationErrors: false,
  onPartWeightChange: undefined
};

export default PartWeightRow;
