import { call, put, takeEvery } from 'redux-saga/effects';
import { postCohortCompetencyGridReport } from '../../api/competencyGridApi';

import {
  doPostCohortCompetencyGridSuccess,
  doPostCohortCompetencyGridError
} from '../actions/competencyGridActions';

import { POST_COHORT_COMPETENCY_GRID } from '../actions-type';

function* handlePostCohortCompetencyGrid(action) {
  const {
    cohortUuid,
    competencyCollectionUuid,
    assessmentAssociationUuids,
    assessmentTechniqueUuids,
    assessmentTypeUuids,
    startDate,
    endDate
  } = action.payload;

  try {
    const result = yield call(postCohortCompetencyGridReport, {
      cohortUuid,
      competencyCollectionUuid,
      assessmentAssociationUuids,
      assessmentTechniqueUuids,
      assessmentTypeUuids,
      startDate,
      endDate
    });

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPostCohortCompetencyGridSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostCohortCompetencyGridError(error));
  }
}

const cohortCompetencyGrid = [
  takeEvery(POST_COHORT_COMPETENCY_GRID, handlePostCohortCompetencyGrid)
];

export { cohortCompetencyGrid };
