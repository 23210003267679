import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '@xcomp/xcomp-design-library/dist/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '../../../../Library/Modal/Modal';
import { optionUserStatus } from '../../../../../helpers/options';
import FormField from '../../../../Library/FormField';
import FormSelectError from '../../../../Library/FormSelectError';
import Date from '../../../../Library/Date';

import {
  validateInputString,
  validateSelectDate,
  validateSelectField
} from '../../../../../helpers/validation/validateGeneric';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 900px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  padding: 30px;
`;

const Submit = styled(ButtonPrimary)`
  && {
    margin-top: 15px;
    margin-bottom: 10px;
  }
`;
const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;

const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InvisibleLineBreak = styled.div`
  height: 4px;
`;

const RoleDepartment = ({
  submit,
  status,
  startDate,
  endDate,
  role,
  email,
  phone,
  schoolUuid,
  departmentUuid,
  optionsSchool,
  optionsDepartment,
  hasErrors,
  openRoleDepartment,
  handleRole,
  handleRoleDate,
  handleDepartmentRoleClose,
  handleRoleSubmit
}) => {
  return (
    <Modal open={openRoleDepartment} onClose={handleDepartmentRoleClose}>
      <div>
        <ModalHeader>
          <Title>Add Department Role</Title>
          <CloseModal>
            <IconButton onClick={handleDepartmentRoleClose}>
              <CloseIcon />
            </IconButton>
          </CloseModal>
        </ModalHeader>
        <Grid item sm={12} xs={12}>
          {submit ? <LinearProgress /> : <InvisibleLineBreak />}
        </Grid>

        <ModalBody>
          <Grid container spacing={4}>
            <Grid item sm={3} xs={12}>
              <FormSelectError
                name="role"
                label="Role"
                value={role}
                handleChange={handleRole}
                handleValidation={validateSelectField}
                options={[
                  { label: 'Department Faculty', value: 'department_faculty' },
                  { label: 'Department Staff', value: 'department_staff' }
                ]}
                hasErrors={hasErrors}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormSelectError
                name="status"
                label="Status*"
                value={status}
                handleChange={handleRole}
                handleValidation={validateSelectField}
                options={optionUserStatus}
                hasErrors={hasErrors}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Date
                name="startDate"
                label="Start Date*"
                value={startDate}
                handleChange={handleRoleDate}
                hasErrors={hasErrors}
                handleValidate={validateSelectDate}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Date
                name="endDate"
                label="End Date"
                value={endDate}
                handleChange={handleRoleDate}
                placeholderText={status === 'active' ? 'Present' : null}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormSelectError
                name="schoolUuid"
                label="School/College*"
                value={schoolUuid}
                handleChange={handleRole}
                handleValidation={validateInputString}
                options={optionsSchool}
                hasErrors={hasErrors}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <FormSelectError
                name="departmentUuid"
                label="Department*"
                value={departmentUuid}
                handleChange={handleRole}
                handleValidation={validateInputString}
                options={optionsDepartment}
                hasErrors={hasErrors}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormField
                name="email"
                label="Email*"
                value={email}
                onBlur={handleRole}
                handleValidate={validateInputString}
                hasErrors={hasErrors}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormField
                name="phone"
                label="Phone*"
                value={phone}
                onBlur={handleRole}
                margin="normal"
                handleValidate={validateInputString}
                hasErrors={hasErrors}
              />
            </Grid>
            <Grid container item justifyContent="flex-end" sm={12} xs={12}>
              <Submit onClick={handleRoleSubmit}>Submit</Submit>
            </Grid>
          </Grid>
        </ModalBody>
      </div>
    </Modal>
  );
};

RoleDepartment.propTypes = {
  submit: PropTypes.bool,
  openRoleDepartment: PropTypes.bool,
  status: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.object,
    PropTypes.string
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.object,
    PropTypes.string
  ]),
  role: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  schoolUuid: PropTypes.string,
  departmentUuid: PropTypes.string,
  optionsSchool: PropTypes.array,
  optionsDepartment: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleRole: PropTypes.func,
  handleRoleDate: PropTypes.func,
  handleDepartmentRoleClose: PropTypes.func,
  handleRoleSubmit: PropTypes.func
};

RoleDepartment.defaultProps = {
  submit: false,
  openRoleDepartment: false,
  status: undefined,
  startDate: null,
  endDate: null,
  role: undefined,
  email: undefined,
  phone: undefined,
  schoolUuid: undefined,
  departmentUuid: undefined,
  optionsSchool: [],
  optionsDepartment: [],
  hasErrors: undefined,
  handleRole: undefined,
  handleRoleDate: undefined,
  handleDepartmentRoleClose: undefined,
  handleRoleSubmit: undefined
};

export default RoleDepartment;
