import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import {
  ItemLayout,
  ItemLeft,
  ItemRight,
  ItemType,
  ItemDetails,
  ItemDetail,
  DetailsList,
  DetailsListItem,
  Title,
  Faculty,
  Location
} from '../../Library/SyllabusComponents';
import LearningObjectivesList from './LearningObjectivesList';
import ItemDatesBlock from '../ItemDatesBlock';
import Notes from './SyllabusNotes';
import ItemDetailViewModal from '../ItemDetailViewModal';

export default function SyllabusEncounter({ encounter, itemNumber }) {
  const themeContext = useContext(ThemeContext);

  const [isModalOpen, setIsModalOpen] = useState('');

  const handleLearningObjectivesModalOpen = () =>
    setIsModalOpen('learningObjectives');
  const handleNotesModalOpen = () => setIsModalOpen('notes');
  const handleModalClose = () => setIsModalOpen('');

  const startDate = encounter.startDate ? moment(encounter.startDate) : null;
  const endDate = encounter.endDate ? moment(encounter.endDate) : null;

  const faculty = `${_.get(encounter, 'faculty_lead.firstName', '')} ${_.get(
    encounter,
    'faculty_lead.lastName',
    ''
  )}`;

  const { encounter_faculty_assigned, objectives, notes } = encounter;

  return (
    <ItemLayout>
      <ItemLeft borderColor={themeContext.colors.secondary}>
        <ItemType>
          Encounter {itemNumber.toString().padStart(2, 0)} ●{' '}
          {_.get(encounter, 'encounter_type.name', '')}
        </ItemType>

        <Title>{encounter.title}</Title>

        <ItemDatesBlock startDate={startDate} endDate={endDate} />

        <ItemDetails>
          <DetailsList>
            <DetailsListItem>
              <Location />
              <ItemDetail>{encounter.location}</ItemDetail>
            </DetailsListItem>
            <DetailsListItem>
              <Faculty />
              <ItemDetail>{faculty}</ItemDetail>
            </DetailsListItem>
          </DetailsList>
        </ItemDetails>
      </ItemLeft>

      <ItemRight>
        <LearningObjectivesList
          objectives={objectives}
          noObjectivesMessage="There are currently no learning objectives for this Encounter"
          onViewMore={handleLearningObjectivesModalOpen}
          maxHeight="170px"
          hasLabel
        />
        <Notes
          notes={notes}
          facultyAssigned={encounter_faculty_assigned}
          onViewMore={handleNotesModalOpen}
          maxHeight="90px"
          hasLabel
        />
        <ItemDetailViewModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          notes={notes}
          objectives={objectives}
          facultyAssigned={encounter_faculty_assigned}
        />
      </ItemRight>
    </ItemLayout>
  );
}

SyllabusEncounter.propTypes = {
  encounter: PropTypes.object,
  itemNumber: PropTypes.number.isRequired
};

SyllabusEncounter.defaultProps = {
  encounter: {}
};
