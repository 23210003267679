import React, {useMemo} from 'react';
import Table from '@material-ui/core/Table';
import PropTypes from 'prop-types';

import TableData from './TableData';

const QuantitativeSkillsTable = ({data, totals}) => {

  const formattedData = useMemo(() => data.map(d => ({
      ...d,
      subRows: d.procedure_code_groups.map(c => ({
        ...c,
        subRows: c.quantitative_skills_procedure_codes.map(f => ({
          ...f,
          name: `${f.procedureCode.code} - ${f.procedureCode.description}`,
        })),
      })),
    })),
    [data],
  );

  return (
    <Table size="small">
      <TableData
        data={[
          {
            totals,
            name: 'All Collections',
            uuid: 'all-collections',
          },
        ]}
        isCollectionTotals
      />
      <TableData data={formattedData} />

    </Table>
  );
};

QuantitativeSkillsTable.propTypes = {
  data: PropTypes.array,
  totals: PropTypes.object,
};

QuantitativeSkillsTable.defaultProps = {
  data: [],
  totals: {},
};

export default QuantitativeSkillsTable;