import {
  GET_COHORT_OPPORTUNITY_REPORT_SUCCESS,
  GET_COHORT_OPPORTUNITY_REPORT_ERROR,
  GET_COHORT_OPPORTUNITY_REPORT,
  CLEAR_COHORT_OPPORTUNITY_REPORT
} from '../actions-type';

export const INITIAL_STATE = {
  isLoading: false
};

const cohortOpportunityReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_COHORT_OPPORTUNITY_REPORT: {
      return {
        ...INITIAL_STATE
      };
    }

    case GET_COHORT_OPPORTUNITY_REPORT_SUCCESS: {
      const { coursePerformanceReport, courses } = action.payload;

      return {
        ...state,
        coursePerformanceReport,
        courses,
        isLoading: false
      };
    }

    case GET_COHORT_OPPORTUNITY_REPORT_ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }

    case GET_COHORT_OPPORTUNITY_REPORT: {
      return {
        ...state,
        isLoading: true
      };
    }

    default:
      return state;
  }
};

export default cohortOpportunityReportReducer;
