import React from 'react';
import styled from 'styled-components';
import Overline from './Overline';

export const StatusChip = styled(({ isComplete, ...rest }) => (
  <Overline {...rest} />
))`
  display: flex;
  width: 175px;
  flex-wrap: nowrap;
  background: ${props =>
    props.isComplete ? props.theme.colors.status.warning : props.theme.colors.grey[400]};
  color: ${props => props.theme.fontColors.darker};
  padding: 3px 0;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 16px;
  aling-items: center;
  justify-content: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CompleteStatusChip = styled(({ isComplete, ...rest }) => (
  <Overline {...rest} />
))`
  display: flex;
  width: 175px;
  flex-wrap: nowrap;
  background: ${props =>
    props.isComplete
      ? props.theme.colors.status.success
      : props.theme.colors.grey[400]};
  color: ${props =>
    props.isComplete ? props.theme.colors.white : props.theme.fontColors.darker};
  padding: 3px 0;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 16px;
  aling-items: center;
  justify-content: center;

  &:last-child {
    margin-bottom: 0;
  }
`;
