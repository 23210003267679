import {
  CLEAR_APPLICANT_NONCOGNITIVE_REPORT,
  PUT_APPLICANT_NONCOGNITIVE_REPORT,
  PUT_APPLICANT_NONCOGNITIVE_REPORT_ERROR,
  PUT_APPLICANT_NONCOGNITIVE_REPORT_SUCCESS
} from '../actions-type';

export const INITIAL_STATE = {
  noncognitiveReport: [],
  loadingNoncognitiveReport: false,
  reportHeaders: [],
  reportSummary: {},
  reportSummaryHeaders: [],
  reportType: ''
};

const applicantNoncognitiveReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PUT_APPLICANT_NONCOGNITIVE_REPORT_SUCCESS: {
      const {
        noncognitiveReport,
        reportHeaders,
        reportSummary,
        reportSummaryHeaders,
        reportType
      } = action.payload;
      return {
        ...state,
        noncognitiveReport,
        loadingNoncognitiveReport: false,
        reportHeaders,
        reportSummary,
        reportSummaryHeaders,
        reportType
      };
    }
    case PUT_APPLICANT_NONCOGNITIVE_REPORT:
      return { ...state, loadingNoncognitiveReport: true };
    case PUT_APPLICANT_NONCOGNITIVE_REPORT_ERROR:
      return { ...state, loadingNoncognitiveReport: false };
    case CLEAR_APPLICANT_NONCOGNITIVE_REPORT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default applicantNoncognitiveReportReducer;
