import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CSVReader from 'react-csv-reader';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '@xcomp/xcomp-design-library/dist/icons';
import Modal from '../../../Library/Modal/Modal';
import UploadMapping from '../../../Library/UploadMapping';
// import UploadErrorsNew from '../../../Library/UploadErrorsNew';
import { headersUserCheck } from '../../../../helpers/headers_upload/headers_users';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 900px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  margin-bottom: 20px;
`;

// const ButtonDanger = styled(ButtonPrimary)`
//   && {
//     margin-left: 20px;
//     background: ${props => props.theme.colors.status.error};
//     &:hover {
//       filter: brightness(140%);
//       background-color: ${props => props.theme.colors.status.error};
//     }
//   }
// `;

const FileSelector = styled.div`
  width: 100%;
  margin-right: 16px;
`;

const TitleSub = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 12px;
  font-weight: 600;
`;

const FileUpload = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Import = styled(ButtonPrimary)`
  && {
    margin-left: 5px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const UserUpload = ({
  records,
  status,
  openCheckUpload,
  openMapping,
  handleCheckClose,
  handleRecords,
  handleCheck,
  handleDisplayMapping
}) => {
  return (
    <Modal open={openCheckUpload} onClose={handleCheckClose}>
      <ModalBody>
        <ModalHeader>
          <Title>Check Users (CSV)</Title>
          <CloseModal>
            <IconButton onClick={handleCheckClose}>
              <CloseIcon />
            </IconButton>
          </CloseModal>
        </ModalHeader>

        <TitleSub>
          Upload users to check if they exist in other Institutions across the
          XCOMP Ecosystem.
        </TitleSub>
        <FileUpload>
          <FileSelector>
            <CSVReader
              onFileLoaded={handleRecords}
              parserOptions={{
                header: true,
                skipEmptyLines: 'greedy'
              }}
              inputStyle={{
                width: '100%',
                color: 'black',
                background: 'lightgrey',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                borderRadius: '5px'
              }}
            />
          </FileSelector>
          <Import
            disabled={status.usersCheck || !records || records?.length === 0}
            onClick={handleCheck}
          >
            Import
          </Import>
        </FileUpload>
        <Actions>
          <div>
            <ButtonPrimary onClick={handleDisplayMapping}>
              {openMapping ? `Hide Rules` : `Rules`}
            </ButtonPrimary>
          </div>
        </Actions>

        <UploadMapping display={openMapping} fields={headersUserCheck} />
      </ModalBody>
    </Modal>
  );
};

UserUpload.propTypes = {
  records: PropTypes.array,
  status: PropTypes.string,
  openCheckUpload: PropTypes.bool,
  openMapping: PropTypes.bool,
  handleCheckClose: PropTypes.func,
  handleRecords: PropTypes.func,
  handleCheck: PropTypes.func,
  handleDisplayMapping: PropTypes.func
};

UserUpload.defaultProps = {
  records: undefined,
  status: 'Ready for upload',
  openCheckUpload: false,
  openMapping: false,
  handleCheckClose: undefined,
  handleRecords: undefined,
  handleCheck: undefined,
  handleDisplayMapping: undefined
};

export default UserUpload;
