import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  doPutApplicantDemographicReport,
  doGetApplicantDemographicReportTypes,
  doClearApplicantDemographicReport
} from '../../../../redux/actions/applicantReportActions';
import { applicantDemographicReportTypesSelector } from '../../../../redux/selectors/applicantReportTypesSelectors';
import {
  getApplicantDemographicReportMapping,
  getApplicantDemographicReportFilters,
  sortByNestedProperty,
  sortByDecimalProperty
} from '../../../../helpers/utilities';
import CohortApplicantDemographicReport from './CohortApplicantDemographicReport';

const CohortApplicantDemographicReportContainer = ({ cohortUuid }) => {
  const dispatch = useDispatch();
  const [reportSummaryMapping, setReportSummaryMapping] = useState([]);
  const [reportTypeSelectError, setReportTypeSelectError] = useState(false);
  const [shouldAnonymizeReport, setShouldAnonymizeReport] = useState(false);
  const [reportSummaryFilters, setReportSummaryFilters] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState('');
  const [filterSelection, setFilterSelection] = useState({
    property: 'user.lastName',
    decimalProperty: false,
    reversed: false
  });

  const demographicReportState = useSelector(
    state => state.applicantDemographicReportState
  );
  const applicantDemographicReportTypes = useSelector(
    applicantDemographicReportTypesSelector,
    _.isEqual
  );

  const isLoadingDemographicReport =
    demographicReportState?.loadingDemographicReport;

  const handleSelectReportType = event => {
    const { value } = event.target;
    dispatch(doClearApplicantDemographicReport());
    setSelectedReportType(value);
  };

  const handleToggleShouldAnonymizeReport = event => {
    const { checked } = event.target;
    setShouldAnonymizeReport(checked);
  };

  const handleApplyReportType = () => {
    if (selectedReportType === '') {
      setReportTypeSelectError(true);
    } else {
      dispatch(doClearApplicantDemographicReport());
      dispatch(doPutApplicantDemographicReport(cohortUuid, selectedReportType));
    }
  };

  const handleApplyFilterSelection = (
    selected,
    isDecimalProperty,
    isReversed
  ) => {
    setFilterSelection({
      property: selected,
      decimalProperty: isDecimalProperty,
      reversed: isReversed
    });
  };

  useEffect(() => {
    dispatch(doGetApplicantDemographicReportTypes());
  }, [dispatch]);

  useEffect(() => {
    if (
      applicantDemographicReportTypes.length > 0 &&
      selectedReportType !== ''
    ) {
      const relatedReportType = applicantDemographicReportTypes.find(
        applicantDemographicReportType =>
          applicantDemographicReportType.uuid === selectedReportType
      );
      const mapping = getApplicantDemographicReportMapping(
        relatedReportType.name
      );
      const filterOptions = getApplicantDemographicReportFilters(
        relatedReportType.name
      );
      setReportSummaryFilters(filterOptions);
      setReportSummaryMapping(mapping);
    }
  }, [dispatch, applicantDemographicReportTypes, selectedReportType]);

  const demographicReportTypeOptions = applicantDemographicReportTypes.map(
    applicantDemographicReportTypes => ({
      value: applicantDemographicReportTypes.uuid,
      label: applicantDemographicReportTypes.name
    })
  );

  const filteredReportData = filterSelection.decimalProperty
    ? demographicReportState?.demographicReport.sort((a, b) =>
        filterSelection.reversed
          ? sortByDecimalProperty(b, a, filterSelection.property)
          : sortByDecimalProperty(a, b, filterSelection.property)
      )
    : demographicReportState?.demographicReport.sort((a, b) =>
        filterSelection.reversed
          ? sortByNestedProperty(b, a, filterSelection.property)
          : sortByNestedProperty(a, b, filterSelection.property)
      );
  return (
    <CohortApplicantDemographicReport
      isLoadingDemographicReport={isLoadingDemographicReport}
      onApplyFilterSelection={handleApplyFilterSelection}
      onApplyReportType={handleApplyReportType}
      onSelectReportType={handleSelectReportType}
      onToggleShouldAnonymizeReport={handleToggleShouldAnonymizeReport}
      reportData={filteredReportData}
      reportHeaders={demographicReportState?.reportHeaders}
      reportSummary={demographicReportState?.reportSummary}
      reportSummaryFilters={reportSummaryFilters}
      reportSummaryMapping={reportSummaryMapping}
      reportTypeOptions={demographicReportTypeOptions}
      reportTypeSelectError={reportTypeSelectError}
      selectedReportType={selectedReportType}
      shouldAnonymizeReport={shouldAnonymizeReport}
    />
  );
};

CohortApplicantDemographicReportContainer.propTypes = {
  cohortUuid: PropTypes.string.isRequired
};

CohortApplicantDemographicReportContainer.defaultProps = {};

export default CohortApplicantDemographicReportContainer;
