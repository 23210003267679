import {
  GET_COHORT_ASSESSMENTS,
  GET_COHORT_ASSESSMENTS_SUCCESS,
  GET_COHORT_ASSESSMENTS_ERROR,
  GET_COHORT_ASSESSMENTS_STUDENT,
  GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS,
  GET_COHORT_ASSESSMENTS_STUDENT_ERROR,
  POST_COHORT_ASSESSMENT_COLLECTION,
  POST_COHORT_ASSESSMENT_COLLECTION_SUCCESS,
  POST_COHORT_ASSESSMENT_COLLECTION_ERROR,
  PUT_COHORT_ASSESSMENT_COLLECTION,
  PUT_COHORT_ASSESSMENT_COLLECTION_SUCCESS,
  PUT_COHORT_ASSESSMENT_COLLECTION_ERROR,
  DELETE_COHORT_ASSESSMENT_COLLECTION,
  DELETE_COHORT_ASSESSMENT_COLLECTION_SUCCESS,
  DELETE_COHORT_ASSESSMENT_COLLECTION_ERROR,
  POST_COHORT_ASSESSMENT,
  POST_COHORT_ASSESSMENT_SUCCESS,
  POST_COHORT_ASSESSMENT_ERROR,
  PUT_COHORT_ASSESSMENT,
  PUT_COHORT_ASSESSMENT_SUCCESS,
  PUT_COHORT_ASSESSMENT_ERROR,
  PATCH_COHORT_ASSESSMENT_STATUS,
  PATCH_COHORT_ASSESSMENT_STATUS_SUCCESS,
  PATCH_COHORT_ASSESSMENT_STATUS_ERROR,
  DELETE_COHORT_ASSESSMENT,
  DELETE_COHORT_ASSESSMENT_SUCCESS,
  DELETE_COHORT_ASSESSMENT_ERROR,
  GET_COHORT_ASSESSMENT_SCORES,
  GET_COHORT_ASSESSMENT_SCORES_SUCCESS,
  GET_COHORT_ASSESSMENT_SCORES_ERROR,
  PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT,
  PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_SUCCESS,
  PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_ERROR,
  GET_COHORT_ASSESSMENT_COMMITMENT_LOG,
  GET_COHORT_ASSESSMENT_COMMITMENT_LOG_SUCCESS,
  GET_COHORT_ASSESSMENT_COMMITMENT_LOG_ERROR
} from '../actions-type';

const doGetCohortAssessments = cohortUuid => ({
  type: GET_COHORT_ASSESSMENTS,
  payload: {
    cohortUuid
  }
});

const doGetCohortAssessmentsSuccess = result => ({
  type: GET_COHORT_ASSESSMENTS_SUCCESS,
  payload: result
});

const doGetCohortAssessmentsError = error => ({
  type: GET_COHORT_ASSESSMENTS_ERROR,
  payload: error
});

const doGetCohortAssessmentScores = (cohortUuid, cohortAssessmentUuid) => ({
  type: GET_COHORT_ASSESSMENT_SCORES,
  payload: {
    cohortUuid,
    cohortAssessmentUuid
  }
});

const doGetCohortAssessmentScoresSuccess = result => ({
  type: GET_COHORT_ASSESSMENT_SCORES_SUCCESS,
  payload: result
});

const doGetCohortAssessmentScoresError = error => ({
  type: GET_COHORT_ASSESSMENT_SCORES_ERROR,
  payload: error
});

const doPostCohortAssessmentCollection = ({ title, cohortUuid }) => ({
  type: POST_COHORT_ASSESSMENT_COLLECTION,
  payload: {
    title,
    cohortUuid
  }
});

const doPostCohortAssessmentCollectionSuccess = result => ({
  type: POST_COHORT_ASSESSMENT_COLLECTION_SUCCESS,
  payload: result
});

const doPostCohortAssessmentCollectionError = error => ({
  type: POST_COHORT_ASSESSMENT_COLLECTION_ERROR,
  payload: error
});

const doPutCohortAssessmentCollection = cohortAssessmentCollection => ({
  type: PUT_COHORT_ASSESSMENT_COLLECTION,
  payload: {
    ...cohortAssessmentCollection
  }
});

const doPutCohortAssessmentCollectionSuccess = result => ({
  type: PUT_COHORT_ASSESSMENT_COLLECTION_SUCCESS,
  payload: result
});

const doPutCohortAssessmentCollectionError = error => ({
  type: PUT_COHORT_ASSESSMENT_COLLECTION_ERROR,
  payload: error
});

const doDeleteCohortAsssessmentCollection = ({ cohortUuid, uuid }) => ({
  type: DELETE_COHORT_ASSESSMENT_COLLECTION,
  payload: {
    cohortUuid,
    uuid
  }
});

const doDeleteCohortAsssessmentCollectionSuccess = result => ({
  type: DELETE_COHORT_ASSESSMENT_COLLECTION_SUCCESS,
  payload: result
});

const doDeleteCohortAsssessmentCollectionError = error => ({
  type: DELETE_COHORT_ASSESSMENT_COLLECTION_ERROR,
  payload: error
});

const doPostCohortAssessment = cohortAssessment => ({
  type: POST_COHORT_ASSESSMENT,
  payload: {
    ...cohortAssessment
  }
});

const doPostCohortAssessmentSuccess = result => ({
  type: POST_COHORT_ASSESSMENT_SUCCESS,
  payload: result
});

const doPostCohortAssessmentError = error => ({
  type: POST_COHORT_ASSESSMENT_ERROR,
  payload: error
});

const doPutCohortAssessment = cohortAssessment => ({
  type: PUT_COHORT_ASSESSMENT,
  payload: {
    ...cohortAssessment
  }
});

const doPutCohortAssessmentSuccess = result => ({
  type: PUT_COHORT_ASSESSMENT_SUCCESS,
  payload: result
});

const doPutCohortAssessmentError = error => ({
  type: PUT_COHORT_ASSESSMENT_ERROR,
  payload: error
});

const doPutCohortAssessmentStudentStatusCommit = ({
  assessmentUuid,
  studentUuid,
  cohortAssessmentStatusCommit,
  justificationMessage,
  cohortUuid
}) => ({
  type: PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT,
  payload: {
    assessmentUuid,
    studentUuid,
    cohortAssessmentStatusCommit,
    justificationMessage,
    cohortUuid
  }
});

const doPutCohortAssessmentStudentStatusCommitSuccess = result => ({
  type: PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_SUCCESS,
  payload: result
});

const doPutCohortAssessmentStudentStatusCommitError = error => ({
  type: PUT_COHORT_ASSESSMENT_STUDENT_STATUS_COMMIT_ERROR,
  payload: error
});

const doPatchCohortAssessmentStatus = (uuid, cohortUuid, status) => ({
  type: PATCH_COHORT_ASSESSMENT_STATUS,
  payload: {
    cohortUuid,
    uuid,
    status
  }
});

const doPatchCohortAssessmentStatusSuccess = result => ({
  type: PATCH_COHORT_ASSESSMENT_STATUS_SUCCESS,
  payload: result
});

const doPatchCohortAssessmentStatusError = error => ({
  type: PATCH_COHORT_ASSESSMENT_STATUS_ERROR,
  payload: error
});

const doDeleteCohortAsssessment = (uuid, cohortUuid) => ({
  type: DELETE_COHORT_ASSESSMENT,
  payload: {
    cohortUuid,
    uuid
  }
});

const doDeleteCohortAsssessmentSuccess = result => ({
  type: DELETE_COHORT_ASSESSMENT_SUCCESS,
  payload: result
});

const doDeleteCohortAsssessmentError = error => ({
  type: DELETE_COHORT_ASSESSMENT_ERROR,
  payload: error
});

const doGetCohortAssessmentCommitmentLog = (assessmentUuid, cohortUuid) => ({
  type: GET_COHORT_ASSESSMENT_COMMITMENT_LOG,
  payload: { assessmentUuid, cohortUuid }
});

const doGetCohortAssessmentCommitmentLogSuccess = result => ({
  type: GET_COHORT_ASSESSMENT_COMMITMENT_LOG_SUCCESS,
  payload: result
});

const doGetCohortAssessmentCommitmentLogError = error => ({
  type: GET_COHORT_ASSESSMENT_COMMITMENT_LOG_ERROR,
  payload: error
});

const doGetCohortAssessmentsStudent = (cohortUuid, userUuid) => ({
  type: GET_COHORT_ASSESSMENTS_STUDENT,
  payload: {
    cohortUuid,
    userUuid
  }
});

const doGetCohortAssessmentsStudentSuccess = result => ({
  type: GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS,
  payload: result
});

const doGetCohortAssessmentsStudentError = error => ({
  type: GET_COHORT_ASSESSMENTS_STUDENT_ERROR,
  payload: error
});

export {
  doDeleteCohortAsssessment,
  doDeleteCohortAsssessmentCollection,
  doDeleteCohortAsssessmentCollectionError,
  doDeleteCohortAsssessmentCollectionSuccess,
  doDeleteCohortAsssessmentError,
  doDeleteCohortAsssessmentSuccess,
  doGetCohortAssessmentCommitmentLog,
  doGetCohortAssessmentCommitmentLogError,
  doGetCohortAssessmentCommitmentLogSuccess,
  doGetCohortAssessmentScores,
  doGetCohortAssessmentScoresError,
  doGetCohortAssessmentScoresSuccess,
  doGetCohortAssessments,
  doGetCohortAssessmentsError,
  doGetCohortAssessmentsSuccess,
  doPatchCohortAssessmentStatus,
  doPatchCohortAssessmentStatusError,
  doPatchCohortAssessmentStatusSuccess,
  doPostCohortAssessment,
  doPostCohortAssessmentCollection,
  doPostCohortAssessmentCollectionError,
  doPostCohortAssessmentCollectionSuccess,
  doPostCohortAssessmentError,
  doPostCohortAssessmentSuccess,
  doPutCohortAssessment,
  doPutCohortAssessmentCollection,
  doPutCohortAssessmentCollectionError,
  doPutCohortAssessmentCollectionSuccess,
  doPutCohortAssessmentError,
  doPutCohortAssessmentStudentStatusCommit,
  doPutCohortAssessmentStudentStatusCommitError,
  doPutCohortAssessmentStudentStatusCommitSuccess,
  doPutCohortAssessmentSuccess,
  doGetCohortAssessmentsStudent,
  doGetCohortAssessmentsStudentError,
  doGetCohortAssessmentsStudentSuccess
};
