import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { selectMilestoneConversions } from '../../../../../../redux/selectors/classificationSelectors';

const TableLayout = styled.div`
  margin-left: 264px;
  margin-bottom: 30px;
  width: 100%;
`;

const TableHeader = styled.div`
  color: ${props => props.theme.colors.grey[500]};
  font-size: 1rem;
  width: 100%;
`;

const TableTitle = styled.div``;

const TableRow = styled.div`
  width: 100%;
`;

const TableInput = styled.div`
  font-size: 1rem;
`;

export default function MilestoneConversion({ examMilestoneUuid }) {
  const conversions = useSelector(
    state => selectMilestoneConversions(state, examMilestoneUuid),
    _.isEqual
  );

  return (
    <TableLayout>
      <TableHeader>
        <Grid container spacing={4}>
          <Grid item xs={2}>
            <TableTitle>Scaled Score</TableTitle>
          </Grid>
          <Grid item xs={2}>
            <TableTitle>Percentage</TableTitle>
          </Grid>
        </Grid>
      </TableHeader>
      {!_.isNil(conversions) &&
        conversions.map(conversion => (
          <TableRow key={conversion.uuid}>
            <Grid container spacing={4}>
              <Grid item xs={2}>
                <TableInput>{conversion.scaledScore}</TableInput>
              </Grid>
              <Grid item xs={2}>
                <TableInput>{conversion.percentage}</TableInput>
              </Grid>
            </Grid>
          </TableRow>
        ))}
    </TableLayout>
  );
}

MilestoneConversion.propTypes = {
  examMilestoneUuid: PropTypes.string
};

MilestoneConversion.defaultProps = {
  examMilestoneUuid: undefined
};
