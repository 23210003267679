import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useSWRMutation from 'swr/mutation';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Panel } from '../../../Library/DashboardSC';
import PartFacultyReportPanelContainer from './PartFacultyReportPanel/PartFacultyReportPanelContainer';
import Filters from './Filters';
import { fetcher } from '../../../../helpers/utilities';
import InstructionReport from './InstructionReport/InstructionReport';

const PanelSC = styled(Panel)`
  margin-top: 24px;
  width: 100% !important;
  max-width: unset !important;
`;

const ProgressBar = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.fontColors.dark};
`;

const NoDataContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.fontColors.dark};
`;

const AssessmentHeaderContainer = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  height: 48px;
  width: 100%;
  display: flex;
  padding-right: ${props => props.paddingRight};
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: flex-start;
  border-bottom: ${props => `solid 1px  ${props.theme.colors.grey[300]}`};
  font-size: 15px;
  font-weight: 450;
`;

const AssessmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 66px;
  width: calc(100% - 66px);
`;

const AssessmentHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  overflow: hidden;
`;

const AssessmentHeaderData = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.darker};
  min-width: 456px;
`;

const AssessmentHeaderSub = styled(({ marginRight, ...rest }) => (
  <div {...rest} />
))`
  min-width: 100px;
  margin-right: ${props => props.marginRight};
  color: ${props => props.theme.fontColors.darker};
  text-align: right;
  }
`;

const PartFacultyReport = ({
  assessments,
  assessmentTechniques,
  assessmentTypes,
  cumulativePotentialScore,
  cumulativeRelativeScore,
  cumulativeScoreByAssessment,
  hasAppliedFilters,
  isLoading,
  onApplyPartFacultyReportFilters,
  partFacultyReportAssessmentData,
  partFacultyReportFacultyData,
  partFacultyUsers,
  partUuid,
  reportIsEmpty,
  reportType,
  setReportType,
}) => {
  const { trigger, error, data, isMutating } = useSWRMutation(
    `/api/part/${partUuid}/part-faculty-report`,
    fetcher,
    {
      throwOnError: false,
      revalidateOnFocus: false,
    }
  );

  return (
    <>
      <Filters 
        onApplyPartFacultyReportFilters={onApplyPartFacultyReportFilters}
        reportType={reportType}
        setReportType={setReportType}
        getInstructionReport={trigger}
      />
      {reportType === 'instruction' && <InstructionReport error={error} data={data} isMutating={isMutating} /> }
      {reportType === 'assessment' &&
        <>
          {isLoading ? (
            <PanelSC>
              <ProgressBar>Loading</ProgressBar>
              <LinearProgress />
            </PanelSC>
          ) : (
            <PanelSC>
              {!reportIsEmpty && (
                <AssessmentHeaderContainer paddingRight="44px">
                  <AssessmentHeader>
                    <AssessmentHeaderInfo>
                      Faculty (Last Name, First Name)
                    </AssessmentHeaderInfo>
                    <AssessmentHeaderData>
                      <AssessmentHeaderSub marginRight="16px">
                        Participation (%)
                      </AssessmentHeaderSub>
                      <AssessmentHeaderSub marginRight="16px">
                        Relative (%)
                      </AssessmentHeaderSub>
                      <AssessmentHeaderSub marginRight="16px">
                        Total Relative
                      </AssessmentHeaderSub>
                      <AssessmentHeaderSub marginRight="8px">
                        Total Potential
                      </AssessmentHeaderSub>
                    </AssessmentHeaderData>
                  </AssessmentHeader>
                </AssessmentHeaderContainer>
              )}
              <PartFacultyReportPanelContainer
                assessments={assessments}
                cumulativePotentialScore={cumulativePotentialScore}
                cumulativeScoreByAssessment={cumulativeScoreByAssessment}
                isSummaryPanel={true}
                partFacultyReportAssessmentData={partFacultyReportAssessmentData}
                partFacultyTotalPotentialScore={cumulativePotentialScore}
                partFacultyTotalRelativeScore={cumulativeRelativeScore}
                partUuid={partUuid}
              />
              {hasAppliedFilters && reportIsEmpty && (
                <NoDataContainer>
                  No data for the selected filters. Change filter selection and
                  re-apply.
                </NoDataContainer>
              )}
              {!hasAppliedFilters && reportIsEmpty && (
                <NoDataContainer>
                  Apply the filter selection to view the report.
                </NoDataContainer>
              )}
              {!reportIsEmpty &&
                partFacultyUsers.map(partFacultyUser => (
                  <PartFacultyReportPanelContainer
                    key={partFacultyUser.user?.uuid}
                    assessments={assessments}
                    assessmentTechniques={assessmentTechniques}
                    assessmentTypes={assessmentTypes}
                    cumulativePotentialScore={cumulativePotentialScore}
                    cumulativeScoreByAssessment={cumulativeScoreByAssessment}
                    partFacultyReportAssessmentData={
                      partFacultyReportAssessmentData
                    }
                    partFacultyTotalPotentialScore={
                      partFacultyReportFacultyData[partFacultyUser.user?.uuid]
                        ?.totalPotentialScore
                    }
                    partFacultyTotalRelativeScore={
                      partFacultyReportFacultyData[partFacultyUser.user?.uuid]
                        ?.totalRelativeScore
                    }
                    partFacultyUser={partFacultyUser.user}
                    partUuid={partUuid}
                  />
                ))}
            </PanelSC>
          )}
        </>
      }
    </>
  );
};

PartFacultyReport.propTypes = {
  assessmentTechniques: PropTypes.array,
  assessmentTypes: PropTypes.array,
  partUuid: PropTypes.string,
  assessments: PropTypes.array,
  cumulativePotentialScore: PropTypes.string,
  cumulativeRelativeScore: PropTypes.string,
  cumulativeScoreByAssessment: PropTypes.object,
  hasAppliedFilters: PropTypes.bool,
  isLoading: PropTypes.bool,
  onApplyPartFacultyReportFilters: PropTypes.func,
  partFacultyReportAssessmentData: PropTypes.object,
  partFacultyReportFacultyData: PropTypes.object,
  partFacultyUsers: PropTypes.array,
  reportIsEmpty: PropTypes.bool,
  reportType: PropTypes.string.isRequired,
  setReportType: PropTypes.func.isRequired
};

PartFacultyReport.defaultProps = {
  assessmentTechniques: [],
  assessmentTypes: [],
  partUuid: '',
  assessments: [],
  cumulativePotentialScore: '',
  cumulativeRelativeScore: '',
  cumulativeScoreByAssessment: {},
  hasAppliedFilters: false,
  isLoading: false,
  partFacultyUsers: [],
  partFacultyReportAssessmentData: {},
  partFacultyReportFacultyData: {},
  onApplyPartFacultyReportFilters: undefined,
  reportIsEmpty: true
};

export default PartFacultyReport;
