import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { doPostUploadUsers } from '../../../../redux/actions/userActions';
import { useWsSubscriber } from '../../../../helpers/hooks/subscription.hooks';
import { selectInstitution } from '../../../../redux/selectors/institutionSelectors';
import { selectInstitutionIdType } from '../../../../redux/selectors/sourceSelectors';
import UserUpload from './UserUpload';

export default function UserUploadContainer({
  openUpload,
  handleUserUploadClose,
  handleUserFormAddOpen
}) {
  const dispatch = useDispatch();
  const [users, setUsers] = useState(undefined);
  const [openMapping, setOpenMapping] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);

  const activeUserUuid = useSelector(state => state.userState.activeUserUuid);

  const pathSubscribe = `users/upload/subscribe-status`;
  const redisKey = `user-upload-by-${activeUserUuid}`;
  const ws = useWsSubscriber(pathSubscribe, redisKey);
  const subscription = ws.result;
  const connected = ws.readyState;
  const emitter = ws.send;
  const institution = useSelector(state => selectInstitution(state), _.isEqual);
  const institutionUuid = _.get(institution, ['0', 'uuid']);

  const institutionIdTypes = useSelector(state =>
    selectInstitutionIdType(state)
  );

  const institutionIdTypesNames = institutionIdTypes
    .map(idt => idt.name)
    .join(', ');

  useEffect(() => {
    if (connected && openUpload) {
      emitter(redisKey);
    }
  }, [openUpload, redisKey, connected, emitter]);

  const handleUsers = users => {
    setUsers(users);
  };

  const handleUpload = () => {
    const uploadDate = moment().format('YYYY-MM-DD');
    dispatch(doPostUploadUsers(uploadDate, users, institutionUuid));
  };

  const handleDisplayMapping = () => {
    setOpenMapping(openMapping => !openMapping);
  };

  const handleDisplayErrors = () => {
    setOpenError(openError => !openError);
  };

  const handleDisplaySummary = () => {
    setOpenSummary(openSummary => !openSummary);
  };

  const handleClearResults = () => {
    emitter(`${redisKey}-clear`);
  };

  return (
    <UserUpload
      users={users}
      status={subscription?.status}
      error={subscription?.error}
      summary={subscription?.summary}
      uploadDate={subscription?.uploadDate}
      openUpload={openUpload}
      openMapping={openMapping}
      openError={openError}
      openSummary={openSummary}
      institutionIdTypesNames={institutionIdTypesNames}
      handleDisplayMapping={handleDisplayMapping}
      handleUpload={handleUpload}
      handleUsers={handleUsers}
      handleUserFormAddOpen={handleUserFormAddOpen}
      handleUserUploadClose={handleUserUploadClose}
      handleDisplayErrors={handleDisplayErrors}
      handleDisplaySummary={handleDisplaySummary}
      handleClearResults={handleClearResults}
    />
  );
}

UserUploadContainer.propTypes = {
  openUpload: PropTypes.bool,
  handleUserUploadClose: PropTypes.func,
  handleUserFormAddOpen: PropTypes.func
};

UserUploadContainer.defaultProps = {
  openUpload: false,
  handleUserUploadClose: undefined,
  handleUserFormAddOpen: undefined
};
