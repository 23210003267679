import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectRigorBySubject } from '../../../../../redux/selectors/rigorIndexSelectors';

export default function SubjectRigor({
  programUuid,
  sourceInstitutionUuid,
  classificationUuid
}) {
  const rigorIndex = useSelector(state =>
    selectRigorBySubject(
      state,
      programUuid,
      sourceInstitutionUuid,
      classificationUuid
    )
  );

  return <>{rigorIndex && rigorIndex}</>;
}

SubjectRigor.propTypes = {
  programUuid: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string,
  classificationUuid: PropTypes.string
};

SubjectRigor.defaultProps = {
  programUuid: undefined,
  sourceInstitutionUuid: undefined,
  classificationUuid: undefined
};
