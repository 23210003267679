import React from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-left: 66px;
`;

const Record = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => (props.width ? props.width : '120px')};
`;

export default function CognitiveMilestoneRowHeader() {
  return (
    <Layout>
      <Record>&nbsp;</Record>
      <Record>Attempt</Record>
      <Record>Date</Record>
      <Record>Item Code</Record>
      <Record width="350px">Item Name</Record>
      <Record>Subject</Record>
      <Record width="350px">Institution</Record>
      <Record>Credits</Record>
      <Record>Grade</Record>
      <Record>Score</Record>
      <Record width="66px">&nbsp;</Record>
    </Layout>
  );
}

CognitiveMilestoneRowHeader.propTypes = {};
CognitiveMilestoneRowHeader.defaultProps = {};
