import { Model, attr } from 'redux-orm';
import {
  GET_SOURCE_INSTITUTIONS_BY_CODE_SUCCESS,
  GET_SUBJECTS_SUCCESS,
  GET_TRANSCRIPT_SUCCESS,
  GET_CREDENTIALS_SUCCESS,
  POST_INSTITUTION_RIGOR_SUCCESS,
  GET_RIGOR_HISTORICAL_REPORT_SUCCESS
} from '../actions-type';

class SourceInstitution extends Model {
  static reducer(action, SourceInstitution, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_SOURCE_INSTITUTIONS_BY_CODE_SUCCESS: {
        const results = [...payload];
        results.forEach(institution => {
          SourceInstitution.upsert(institution);
        });
        break;
      }
      case GET_SUBJECTS_SUCCESS: {
        const sourceInstitutions = [...payload.sourceInstitutions];
        sourceInstitutions.forEach(institution => {
          SourceInstitution.upsert(institution);
        });
        break;
      }
      case GET_TRANSCRIPT_SUCCESS: {
        const sourceInstitutions = [...payload.sourceInstitutions];
        sourceInstitutions.forEach(institution => {
          SourceInstitution.upsert(institution);
        });
        break;
      }

      case GET_CREDENTIALS_SUCCESS: {
        const sourceInstitutions = [...payload.sourceInstitutions];
        sourceInstitutions.forEach(institution => {
          SourceInstitution.upsert(institution);
        });
        break;
      }

      case POST_INSTITUTION_RIGOR_SUCCESS: {
        const sourceInstitutions = [...payload.sourceInstitutions];
        sourceInstitutions.forEach(institution => {
          SourceInstitution.upsert(institution);
        });
        break;
      }

      case GET_RIGOR_HISTORICAL_REPORT_SUCCESS: {
        const sourceInstitutions = [...payload.sourceInstitutions];
        sourceInstitutions.forEach(institution => {
          SourceInstitution.upsert(institution);
        });
        break;
      }

      default:
        return session;
    }
  }
}

SourceInstitution.fields = {
  uuid: attr(),
  name: attr(),
  city: attr(),
  institutionCode: attr(),
  institutionSubtypeUuid: attr(),
  stateProvinceUuid: attr(),
  institutionalSystemUuid: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

SourceInstitution.modelName = 'SourceInstitution';
SourceInstitution.options = {
  idAttribute: 'uuid'
};

export default SourceInstitution;
