import React from 'react';
import PropTypes from 'prop-types';
import { RemoveIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { Checkbox, Chip, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { getColor } from './Logs';

const FilterAutocomplete = styled(({ isHidden, offsetPopper, ...rest }) => (
  <Autocomplete {...rest} />
))`
  transform-origin: top left;
  transition:
    transform 0.5s ease-in-out,
    width 0.5s ease-in-out;
  margin: 20px 0 0 0;

  & + .MuiAutocomplete-popper {
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 0.2s ease-in-out;
    top: 50px;
    margin-left: 0px;
  }
`;

const CustomOption = styled(Typography)`
  ${({$methodLabel, theme}) => $methodLabel && $methodLabel !== 'All'  && `
    border-radius: 3px;
    padding: 2px 10px;
    color: ${theme.colors.white};
    background: ${getColor($methodLabel, theme)};
  `}
`

const AutocompleteDropdownFilter = ({
  setFilteredValues,
  filteredValues,
  options,
  label,
  style
}) => {
  const handleChange = (e, v, r, d) => {
    if (r === 'clear') {
      // if the clear icon was clicked, remove everything
      setFilteredValues([]);
    } else if (d.option.label === 'All') {
      // if the All options was clicked
      if (r === 'select-option') {
        // if checked select everything
        setFilteredValues(options);
      } else if (r === 'remove-option') {
        // if unchecked remove everything
        setFilteredValues([]);
      }
    } else if (r === 'select-option') {
      // if a normal option was checked
      if (v.length === options.length - 1) {
        // if all the normal options are checked now, check All aswell
        setFilteredValues(options);
      } else {
        // if not just do the normal behaviour
        setFilteredValues(v);
      }
    } else if (r === 'remove-option') {
      // if a normal option was unchecked
      if (v.length === options.length - 1) {
        // if All was also checked, uncheck it aswell
        setFilteredValues(v.filter(option => option.label !== 'All'));
      } else {
        // if not do the normal behaviour
        setFilteredValues(v);
      }
    }
  };

  return (
    <FilterAutocomplete
      size="small"
      style={style}
      multiple
      limitTags={1}
      disableCloseOnSelect
      options={options}
      value={filteredValues}
      getOptionLabel={option => option.label}
      getOptionSelected={(o, v) => o.label === v.label}
      groupBy={option => (option.label === 'All' ? 'All' : label)}
      onChange={handleChange}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
            color="primary"
          />
          <CustomOption variant="caption" $methodLabel={(label === 'Methods' || label === 'Actions') && option.label}>
            {option.label}
          </CustomOption>
        </>
      )}
      renderInput={params => (
        <TextField
          {...params}
          size='large'
          variant="outlined"
          label={label}
          placeholder="Name..."
        />
      )}
      renderTags={values => (
        <Chip
          id={`filter-autocomplete-${label}`}
          color="primary"
          label={`${
            values.length === options.length ? values.length - 1 : values.length
          } ${label}`}
          onDelete={() => setFilteredValues([])}
          deleteIcon={<RemoveIcon />}
        />
      )}
    />
  );
};

AutocompleteDropdownFilter.propTypes = {
  options: PropTypes.array,
  filteredValues: PropTypes.array,
  setFilteredValues: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  style: PropTypes.object
};

AutocompleteDropdownFilter.defaultProps = {
  filteredValues: [],
  options: [],
  style: {},
};

export default AutocompleteDropdownFilter;
