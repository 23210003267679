import React, { useCallback } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Body1, ButtonInline } from '@xcomp/xcomp-design-library';
import { useSelector } from 'react-redux';

import {
  Column,
  SubmitButton,
  TextFieldWithError
} from '../../../../../Library/FormComponents';
import FormSectionShowHide from '../../../../../Library/FormSectionShowHide';
import ProcedureCodeList from './ProcedureCodeList';
import {
  deleteGroupAtom,
  deleteGroupModalAtom,
  deletingGroupAtom,
  editGroupAtom,
  getCollectionDetails,
  newProcedureCodeModalAtom,
  selectedGroupAtom,
  updateGroupAtom,
  updatingGroupAtom
} from '../state/editState';
import { validateStringLength } from '../../../../../../helpers/validation/validateGeneric';
import { validateCompetencyDescription } from '../../../../../../helpers/validation/validateCompetencyCollection';
import DeleteModal from '../../../../../Library/Modal/DeleteModal/DeleteModal';
import { CustomHeading } from '../../QuantitativeCollectionsList/components/QuantitativeCollectionRow';
import Thresholds from '../../Thresholds';

const FormSectionShowHideSC = styled(FormSectionShowHide)`
  && {
    position: relative;
  }
`;

const CourseCollectionItem = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  gap: 36px;
`;

const DescriptionTextFieldWithError = styled(TextFieldWithError)``;

const ButtonsColumn = styled(Column)`
  && {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
`;

const ButtonInlineSC = styled(ButtonInline)`
  && {
    margin-left: 1rem;
  }
`;

const GroupList = () => {
  const { quantitativeSkillUuid } = useParams();
  const programUuid = useSelector(state => state.userState.selectedProgramUuid);

  const [collection, editGroup] = useAtom(editGroupAtom);
  const [isDeleteModalOpen, setDeleteModalOpen] = useAtom(deleteGroupModalAtom);
  const [selectedGroup, setSelectedGroup] = useAtom(selectedGroupAtom);

  const updating = useAtomValue(updatingGroupAtom);
  const deleting = useAtomValue(deletingGroupAtom);

  const updateGroup = useSetAtom(updateGroupAtom);
  const deleteGroup = useSetAtom(deleteGroupAtom);
  const getCollection = useSetAtom(getCollectionDetails);
  const setIsCodeModalOpen = useSetAtom(newProcedureCodeModalAtom);

  const handleChange = useCallback(
    (event, group) => {
      editGroup({
        ...group,
        [event.name]: event.value
      });
    },
    [editGroup]
  );

  const handleProcedureGroupUpdate = useCallback(
    async procedureGroup => {
      await updateGroup({
        ...procedureGroup,
        collectionUuid: quantitativeSkillUuid
      });
    },
    [updateGroup, quantitativeSkillUuid]
  );

  const handleDelete = useCallback(
    async procedureGroupUuid => {
      if (!procedureGroupUuid) return;

      await deleteGroup({
        collectionUuid: quantitativeSkillUuid,
        uuid: procedureGroupUuid
      });
      await getCollection({ quantitativeSkillUuid, programUuid });
    },
    [deleteGroup, quantitativeSkillUuid, getCollection, programUuid]
  );

  const validateNameField = useCallback(
    name => validateStringLength(name, 100, true),
    []
  );
  const validateDescriptionField = useCallback(
    name => validateCompetencyDescription(name, 100, 10000, true),
    []
  );

  if (!collection?.procedure_code_groups?.length) {
    return (
      <Body1>
        There are no Procedure Codes Groups assigned to this collection
      </Body1>
    );
  }
  return (
    <>
      {isDeleteModalOpen[selectedGroup?.uuid] && (
        <DeleteModal
          customHeading={<CustomHeading name={selectedGroup?.name} />}
          warningMessage="This action cannot be undone."
          modalOpen={isDeleteModalOpen[selectedGroup?.uuid]}
          handleModalClose={() => {
            setDeleteModalOpen({ [selectedGroup?.uuid]: false });
          }}
          handleDelete={() => handleDelete(selectedGroup?.uuid)}
        />
      )}
      {collection?.procedure_code_groups?.map((qs, index) => (
        <FormSectionShowHideSC
          key={qs.uuid}
          headerText={`Group ${index + 1} - ${qs.name}`}
          padding="0 30px"
          accordionKey={qs.uuid}
          startOpen={true}
          className="hello"
          onDelete={() => {
            setSelectedGroup(qs);
            setDeleteModalOpen({ [qs.uuid]: true });
          }}
          loading={deleting[qs.uuid]}
        >
          <CourseCollectionItem key={qs.uuid}>
            <Column size="100%">
              <TextFieldWithError
                name="name"
                value={qs.name}
                label="Name"
                variant="outlined"
                onChange={e => handleChange(e.target, qs)}
                handleValidate={() => validateNameField(qs.name)}
                hasErrors={validateNameField(qs.name).invalid}
              />
            </Column>
            <Column size="100%">
              <DescriptionTextFieldWithError
                name="description"
                value={qs.description}
                label="Description"
                variant="outlined"
                multiline
                minRows="4"
                maxRows="16"
                onChange={e => handleChange(e.target, qs)}
                handleValidate={() => validateDescriptionField(qs.description)}
                hasErrors={validateDescriptionField(qs.description).invalid}
              />
            </Column>
            <Column size="100%">
              <Thresholds
                handleChange={e => handleChange(e.target, qs)}
                thresholds={qs}
              />
            </Column>

            <ButtonsColumn size="100%">
              <SubmitButton
                onClick={() => handleProcedureGroupUpdate(qs)}
                disabled={updating[qs.uuid]}
              >
                {updating[qs.uuid] ? 'Updating...' : 'Update'}
              </SubmitButton>
            </ButtonsColumn>

            <Column size="100%">
              <ProcedureCodeList
                procedureGroupUuid={qs.uuid}
                procedureCodes={qs.quantitative_skills_procedure_codes}
              />
              <ButtonInlineSC
                onClick={() => {
                  setIsCodeModalOpen(true);
                  setSelectedGroup(qs.uuid);
                }}
                noHoverBg
              >
                Add Procedure Code
              </ButtonInlineSC>
            </Column>
          </CourseCollectionItem>
        </FormSectionShowHideSC>
      ))}
    </>
  );
};

export default GroupList;
