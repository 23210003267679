import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';

import { orm } from '../models';
import { userSectionStatus } from '../../helpers/constants';

export const selectUserSection = createSelector(
  state => state,
  (_, userUuid, sectionUuid) => ({ userUuid, sectionUuid }),
  (redux, paramaters) => {
    const { userUuid, sectionUuid } = paramaters;
    const getUserSection = ormCreateSelector(orm.UserSection);

    const userSection = getUserSection(redux).filter(
      us => us.userUuid === userUuid && us.sectionUuid === sectionUuid
    );

    const status = _.get(userSection, ['0', 'status']);

    let statusFormat;

    switch (status) {
      case userSectionStatus.pass: {
        statusFormat = 'Pass';
        break;
      }
      case userSectionStatus.pass_with_remediation: {
        statusFormat = 'Pass';
        break;
      }
      case userSectionStatus.fail: {
        statusFormat = 'Fail';
        break;
      }
      case userSectionStatus.fail_with_remediation: {
        statusFormat = 'Fail';
        break;
      }
      case userSectionStatus.credit: {
        statusFormat = 'Credit';
        break;
      }
      case userSectionStatus.withdrew: {
        statusFormat = 'Withdrew';
        break;
      }
      case userSectionStatus.delayed: {
        statusFormat = 'Delayed';
        break;
      }
      case userSectionStatus.active: {
        statusFormat = 'Active';
        break;
      }
      default: {
        statusFormat = 'N/A';
        break;
      }
    }

    return statusFormat;
  }
);
