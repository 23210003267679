import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NavigationDrawer from '@material-ui/core/Drawer';
import NavigationRolesPrograms from './NavigationRolesPrograms';
import NavigationBar from './NavigationBar';

const DrawerBody = styled.div`
  margin-top: 64px;
  padding-bottom: 40px;
  width: ${props => (props.open ? '300px' : '60px')};
  transition-duration: 0.2s;
  &:focus {
    outline: none;
  }

  & .MuiListItem-gutters {
    padding-left: 16px;
  }
`;

export default function Navigation({
  selectedRole,
  user,
  toggleDrawer,
  drawerOpen,
  institutionRoles,
  handleUserLogout,
  handleFeatureSet,
}) {
  return (
    <div>
      <NavigationBar
        user={user}
        toggleDrawer={toggleDrawer}
        handleUserLogout={handleUserLogout}
      />
      <NavigationDrawer variant="permanent" open={drawerOpen}>
        <DrawerBody tabIndex={0} role='button' open={drawerOpen}>
          <NavigationRolesPrograms
            drawerOpen={drawerOpen}
            role={selectedRole}
            handleFeatureSet={handleFeatureSet}
            userInstitution={institutionRoles.userInstitutions[0]}
          />
        </DrawerBody>
      </NavigationDrawer>
    </div>
  );
}

Navigation.propTypes = {
  selectedRole: PropTypes.string,
  drawerOpen: PropTypes.bool,
  user: PropTypes.object,
  institutionRoles: PropTypes.object,
  handleUserLogout: PropTypes.func,
  handleFeatureSet: PropTypes.func,
  toggleDrawer: PropTypes.func,
};

Navigation.defaultProps = {
  selectedRole: undefined,
  drawerOpen: false,
  user: {},
  institutionRoles: {},
  handleUserLogout: undefined,
  handleFeatureSet: undefined,
  toggleDrawer: undefined,
};
