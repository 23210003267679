import React, { useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tab, Tabs } from '@material-ui/core';
import {
  CompetencyIcon,
  DeleteIcon
} from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonDelete, ButtonPrimary } from '@xcomp/xcomp-design-library';
import { useDispatch, useSelector } from 'react-redux';

import { ContainerPage, ContainerResize } from '../../Library/Layout';
import PageIllustration from '../../Library/PageIllustration';
import Header from '../../Library/Header';
import PartAssessmentCollectionReportContainer from './PartAssessmentCollectionReport/PartAssessmentCollectionReportContainer';
import PartFacultyReportContainer from './PartFacultyReport/PartFacultyReportContainer';
import PartGroupReportContainer from './PartGroupReport/PartGroupReportContainer';
import PartOpportunityReportContainer from './PartOpportunityReport/PartOpportunityReportContainer';
import PartTopicReportContainer from './PartTopicReport/PartTopicReportContainer';
import PartLegacyGradeUploadContainer from './PartLegacyGradeUpload/PartLegacyGradeUploadContainer';
import { partGradeSortedResultsSelector } from '../../../redux/selectors/gradeResultSelectors';
import { doDeletePartLegacyGrades } from '../../../redux/actions/legacyGradingActions';
import { useDelete } from '../../Library/Modal/DeleteModal/useDelete';
import DeleteModal from '../../Library/Modal/DeleteModal/DeleteModal';
import PartSelfAssessmentReportContainer from './PartSelfAssessmentReport/PartSelfAssessmentReportContainer';
import PartAttemptReport from './PartAttemptReport/PartAttemptReport';
import PartDidacticReport from './PartDidacticReport/PartDidacticReport';

const ButtonDeleteSC = styled(ButtonDelete)`
  && {
    margin-right: 1rem;
  }
`;

const TabsSC = styled(Tabs)`
  background-color: ${props => props.theme.colors.white};
  padding-top: 77px;
`;

const ButtonRow = styled.div`
  margin: 0;
  display: flex;
  justify-content: flex-end;
`;

const PartGradeSummary = ({
  basePath,
  currentTab,
  onBackAction,
  path,
  part,
  partUuid,
  sectionUuid,
  students,
  courseNumber,
  courseTitle,
  partTitle,
  partNumber,
  sectionNumber
}) => {
  const dispatch = useDispatch();

  const [openUploadScores, setOpenUploadScores] = useState(false);

  const onDeleteLegacyGrades = () =>
    dispatch(doDeletePartLegacyGrades(partUuid));

  const [
    isDeleteModalOpen,
    handleDeleteClick,
    handleDeleteModalClose,
    handleDelete
  ] = useDelete(onDeleteLegacyGrades);

  const handleUploadScoresOpen = () => {
    setOpenUploadScores(true);
  };

  const handleUploadClose = () => {
    setOpenUploadScores(false);
  };

  const headerSubtitle = `${courseNumber} ● ${courseTitle}`;

  const partGradeResults = useSelector(state =>
    partGradeSortedResultsSelector(state, partUuid)
  );

  const showDeleteButton =
    part?.part_grade_settings?.dataSource === 'LEGACY' &&
    partGradeResults?.length > 0;

  return (
    <ContainerResize>
      <Header
        title={`Section ${sectionNumber} - Part ${partNumber}   ${partTitle}`}
        subtitle={headerSubtitle}
        backButtonOverride={onBackAction}
      />
      <TabsSC
        value={currentTab}
        textColor="primary"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab
          component={Link}
          to={`${basePath}/assessment-collection`}
          label="assessment collection"
        />
        <Tab
          component={Link}
          to={`${basePath}/assessment-type`}
          label="assessment type"
        />
        <Tab component={Link} to={`${basePath}/faculty`} label="faculty" />
        <Tab component={Link} to={`${basePath}/topic`} label="topic" />
        <Tab component={Link} to={`${basePath}/group`} label="group" />
        <Tab component={Link} to={`${basePath}/attempt`} label="attempt" />
        <Tab component={Link} to={`${basePath}/didactic`} label="didactic" />
        <Tab
          component={Link}
          to={`${basePath}/opportunity`}
          label="opportunity"
        />
        <Tab
          component={Link}
          to={`${basePath}/self-assessment`}
          label="self assessment"
        />
      </TabsSC>
      <PartLegacyGradeUploadContainer
        openUploadScores={openUploadScores}
        handleUploadClose={handleUploadClose}
        sectionUuid={sectionUuid}
        partUuid={partUuid}
      />

      {part?.part_grade_settings?.dataSource === 'LEGACY' && (
        <ContainerPage paddingTop="32px">
          <ButtonRow>
            {showDeleteButton && (
              <>
                <ButtonDeleteSC onClick={handleDeleteClick}>
                  Delete Grades&nbsp;
                  <DeleteIcon />
                </ButtonDeleteSC>
                <DeleteModal
                  modalOpen={isDeleteModalOpen}
                  handleModalClose={handleDeleteModalClose}
                  handleDelete={handleDelete}
                  type="part's legacy grades"
                />
              </>
            )}
            <ButtonPrimary onClick={handleUploadScoresOpen}>
              Upload Legacy Grades&nbsp;
              <CompetencyIcon />
            </ButtonPrimary>
          </ButtonRow>
        </ContainerPage>
      )}

      <ContainerPage paddingTop="32px">
        <Switch>
          <Route path={`${path}/assessment-collection`}>
            <PartAssessmentCollectionReportContainer
              partUuid={partUuid}
              students={students}
            />
          </Route>
          <Route path={`${path}/assessment-type`}>
            <PageIllustration
              illustrationType="notFound"
              infoText="No Assessment Type Data"
            />
          </Route>
          <Route path={`${path}/faculty`}>
            <PartFacultyReportContainer partUuid={partUuid} />
          </Route>
          <Route path={`${path}/topic`}>
            <PartTopicReportContainer partUuid={partUuid} />
          </Route>
          <Route path={`${path}/group`}>
            <PartGroupReportContainer />
          </Route>
          <Route path={`${path}/attempt`}>
            <PartAttemptReport />
          </Route>
          <Route path={`${path}/didactic`}>
            <PartDidacticReport />
          </Route>
          <Route path={`${path}/opportunity`}>
            <PartOpportunityReportContainer partUuid={partUuid} />
          </Route>
          <Route path={`${path}/self-assessment`}>
            <PartSelfAssessmentReportContainer partUuid={partUuid} />
          </Route>{' '}
        </Switch>
      </ContainerPage>
    </ContainerResize>
  );
};

PartGradeSummary.propTypes = {
  basePath: PropTypes.string,
  courseNumber: PropTypes.string,
  courseTitle: PropTypes.string,
  currentTab: PropTypes.number,
  onBackAction: PropTypes.func,
  part: PropTypes.object,
  partNumber: PropTypes.string,
  partTitle: PropTypes.string,
  partUuid: PropTypes.string,
  sectionUuid: PropTypes.string,
  path: PropTypes.string,
  sectionNumber: PropTypes.string,
  students: PropTypes.array
};
PartGradeSummary.defaultProps = {
  basePath: '',
  courseNumber: '',
  courseTitle: '',
  currentTab: 0,
  onBackAction: undefined,
  part: {},
  partNumber: '',
  partTitle: '',
  partUuid: '',
  sectionUuid: '',
  path: '',
  sectionNumber: '',
  students: []
};

export default PartGradeSummary;
