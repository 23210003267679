import React, { useCallback, useMemo } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from '@tanstack/react-table';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core';
import Decimal from 'decimal.js-light';
import PropTypes from 'prop-types';
import CellDetails from './CellDetails';
import CellExpand from './CellExpand';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  tableCell: {
    fontWeight: 'normal',
    fontSize: '0.85rem'
  },
  font: {
    fontWeight: 'normal'
  }
});

const TableData = ({ data, isCollectionTotals }) => {
  const classes = useRowStyles();
  const [expanded, setExpanded] = React.useState({});

  const columnHelper = createColumnHelper();

  const checkForZero = useCallback(
    value => (new Decimal(value || 0).gt(0) ? value : '-'),
    []
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => <CellExpand row={row} />
      }),
      columnHelper.accessor(row => row?.name, {
        id: 'name',
        header: isCollectionTotals ? 'Name' : ''
      }),
      columnHelper.display({
        id: 'totalProcedures',
        header: isCollectionTotals ? 'Proc (R)' : '',
        accessorFn: row => checkForZero(row?.totals?.totalProcedures),
        cell: ({ row, getValue }) => (
          <CellDetails
            row={row}
            getValue={getValue}
            threshold={
              row.original?.level === 'collection'
                ? row.original?.procedure_threshold
                : row.original?.procedureThreshold
            }
            value={row.original?.totals?.totalProcedures}
            displayRank={
              row.original?.procedureRank ||
              row.original?.level === 'collection'
            }
            rank={row.original?.totals?.calculatedProcedureRank}
            isCollectionTotals={isCollectionTotals}
          />
        )
      }),
      columnHelper.accessor(
        row =>
          `${checkForZero(row?.totals?.relSum)} ${
            isCollectionTotals || row.level === 'collection'
              ? `(${row?.totals?.calculatedRelRank})`
              : ''
          }`,
        {
          id: 'relativeValue',
          header: isCollectionTotals ? 'Rel' : ''
        }
      ),
      columnHelper.accessor(row => checkForZero(row?.totals?.potSum), {
        id: 'potentialValue',
        header: isCollectionTotals ? 'Pot' : ''
      }),
      columnHelper.display({
        id: 'score',
        header: isCollectionTotals ? 'Score (R)' : '',
        accessorKey: 'score',
        accessorFn: row => checkForZero(row?.totals?.scorePercentage),
        cell: ({ row, getValue }) => (
          <CellDetails
            row={row}
            getValue={getValue}
            threshold={
              row.original?.level === 'collection'
                ? row.original?.score_threshold
                : row.original?.scoreThreshold
            }
            value={row.original?.totals?.scorePercentage}
            displayRank={
              row.original?.scoreRank || row.original?.level === 'collection'
            }
            rank={row.original?.totals?.calculatedScoreRank}
            isCollectionTotals={isCollectionTotals}
          />
        )
      }),
      columnHelper.accessor(
        row => (row?.totals?.letterGrade ? row?.totals?.letterGrade : '-'),
        {
          id: 'letterGrade',
          header: isCollectionTotals ? 'Grade' : ''
        }
      ),
      ...(isCollectionTotals
        ? [
            columnHelper.accessor(
              row => `
              ${row?.totals?.totalProcedures} (${row.totals.calculatedProcedureRank}) 
              / 
              ${row?.totals?.scorePerOpportunity} (${row.totals.calculatedScorePerOpportunityRank})
            `,
              {
                id: 'score/opps',
                header: 'Opp (R)/Rel per Opp (R)'
              }
            )
          ]
        : [
            columnHelper.display({
              id: 'units',
              header: 'Units',
              accessorFn: row => checkForZero(row?.totals?.totalUnits),
              cell: ({ row, getValue }) => (
                <CellDetails
                  row={row}
                  getValue={getValue}
                  threshold={
                    row.original?.level === 'collection'
                      ? row.original?.units_threshold
                      : row.original?.unitsThreshold
                  }
                  value={row.original?.totals?.totalUnits}
                  displayRank={
                    row.original?.unitsRank ||
                    row.original?.level === 'collection'
                  }
                  rank={row.original?.totals?.calculatedUnitsRank}
                  isCollectionTotals={isCollectionTotals}
                />
              )
            }),
            columnHelper.display({
              id: 'epas',
              header: 'EPAs',
              accessorFn: row => checkForZero(row?.totals?.totalEpas),
              cell: ({ row, getValue }) => (
                <CellDetails
                  row={row}
                  getValue={getValue}
                  threshold={
                    row.original?.level === 'collection'
                      ? row.original?.epas_threshold
                      : row.original?.epasThreshold
                  }
                  value={row.original?.totals?.totalEpas}
                  displayRank={
                    row.original?.epasRank ||
                    row.original?.level === 'collection'
                  }
                  rank={row.original?.totals?.calculatedEpasRank}
                  isCollectionTotals={isCollectionTotals}
                />
              )
            }),
            columnHelper.accessor(row => checkForZero(row?.totals?.totalCfs), {
              id: 'cfs',
              header: 'CFs'
            })
          ])
    ],
    [checkForZero, columnHelper, isCollectionTotals]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded
    },
    onExpandedChange: setExpanded,
    getSubRows: row => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  });

  return (
    <>
      <TableHead>
        {table.getHeaderGroups().map(headerGroup => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              return (
                <TableCell
                  align="left"
                  className={classes.tableCell}
                  key={header.id}
                  colSpan={
                    header.column.id === 'score/opps' ? 3 : header.colSpan
                  }
                >
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map(row => {
          return (
            <TableRow key={row.id}>
              {row.getVisibleCells().map(cell => {
                return (
                  <TableCell
                    className={classes.tableCell}
                    align="left"
                    key={cell.id}
                    colSpan={cell.column.id === 'score/opps' ? 3 : 1}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
};

TableData.propTypes = {
  data: PropTypes.array,
  isCollectionTotals: PropTypes.bool
};

TableData.defaultProps = {
  data: [],
  isCollectionTotals: false
};

export default TableData;
