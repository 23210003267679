import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Lens from '@material-ui/icons/Lens';
import CheckCircle from '@material-ui/icons/CheckCircle';
import PanoramaFishEye from '@material-ui/icons/PanoramaFishEye';
import Error from '@material-ui/icons/Error';
import { colors } from '@xcomp/xcomp-design-library';

const LayoutSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
`;
const StatusTextSC = styled.div`
  height: 24px;
  width: 82.79px;
  color: ${props => props.color};
  font-size: 12px;
  letter-spacing: 0.21px;
  line-height: 24px;
`;
const StatusIconSC = styled.div`
  height: 17px;
  width: 15px;
  margin-left: 1rem;
  margin-right: 0.375rem;
  color: ${props => props.color};

  svg {
    font-size: 15px;
  }
`;

const StatusBar = ({ status }) => {
  let statusIcon;
  let statusColor;

  switch (status) {
    case 'In Progress':
      // eslint-disable-next-line prefer-destructuring
      statusColor = colors.grey[600];
      statusIcon = <PanoramaFishEye />;
      break;
    case 'Complete':
      statusColor = colors.status.warning;
      statusIcon = <Lens />;
      break;
    case 'Approved':
      statusColor = colors.status.success;
      statusIcon = <CheckCircle />;
      break;
    default:
      statusColor = 'red';
      statusIcon = <Error />;
      break;
  }

  return (
    <LayoutSC>
      <StatusIconSC color={statusColor}>{statusIcon}</StatusIconSC>
      <StatusTextSC color={statusColor}>{status}</StatusTextSC>
    </LayoutSC>
  );
};

StatusBar.propTypes = {
  status: PropTypes.string
};

StatusBar.defaultProps = {
  status: ''
};

export default StatusBar;
export { LayoutSC, StatusTextSC, StatusIconSC };
