import fetch from '../helpers/fetch';

const getLetterGradesBySchool = schoolUuid =>
  fetch(`/api/letter-grades/find-by-school?schoolUuid=${schoolUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postLetterGrade = letterGrade =>
  fetch(`/api/letter-grade/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(letterGrade)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putLetterGrade = letterGrade =>
  fetch(`/api/letter-grade/${letterGrade.uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(letterGrade)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteLetterGrade = letterGradeUuid =>
  fetch(`/api/letter-grade/${letterGradeUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getLetterGradesBySchool,
  postLetterGrade,
  putLetterGrade,
  deleteLetterGrade
};
