const genders = ['Male', 'Female', 'Other', 'Unreported'];

const ethnicities = [
  'American Indian or Alaska Native',
  'Asian',
  'Black or African American',
  'Hispanic or Latino',
  'Native American or Pacific Islander',
  'White',
  'Other',
  'Unreported'
];

const headersUserCheck = [
  {
    field: 'institutionId',
    type: 'STRING',
    allowEmpty: false,
    note: 'Optional for check required for upload'
  },
  {
    field: 'firstName',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'middleName',
    type: 'STRING',
    allowEmpty: true
  },
  {
    field: 'lastName',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'marriedLastName',
    type: 'STRING',
    allowEmpty: true
  },
  {
    field: 'suffix',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'birthDate',
    type: 'STRING',
    allowEmpty: false,
    format: 'YYYY-MM-DD'
  },
  {
    field: 'gender',
    type: 'STRING',
    allowEmpty: false,
    accepted: genders.join(', ')
  },
  {
    field: 'ethnicity',
    type: 'STRING',
    allowEmpty: false,
    accepted: ethnicities.join(', ')
  },
  {
    field: 'email',
    type: 'STRING',
    allowEmpty: true,
    format: 'name@example.com'
  },
  {
    field: 'phone',
    type: 'STRING',
    allowEmpty: true
  }
];

module.exports = {
  headersUserCheck
};
