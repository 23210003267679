import React, { useReducer, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import EditorSubject from './EditorSubject';
import {
  doGetSubjects,
  doPostSubjects
} from '../../../../../../redux/actions/classificationActions';
import { selectSubjects } from '../../../../../../redux/selectors/classificationSelectors';
import { validateInputString } from '../../../../../../helpers/validation/validateGeneric';
import { subjectReducer, subjectInitialState } from './editorSubjectState';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default function EditorSubjectsContainer({ match }) {
  const { programUuid } = match.params;
  const dispatch = useDispatch();
  const subjectsRedux = useSelector(
    state => selectSubjects(state, programUuid),
    _.isEqual
  );
  const subjectsPrevious = usePrevious(subjectsRedux);

  const [subjectState, subjectDispatch] = useReducer(
    subjectReducer,
    subjectInitialState
  );

  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    dispatch(doGetSubjects(programUuid));
  }, [dispatch, programUuid]);

  useEffect(() => {
    const equalityCheck = !_.isEqual(subjectsPrevious, subjectsRedux);

    if (equalityCheck) {
      subjectDispatch({ type: 'populate', subjects: subjectsRedux });
    }
  }, [dispatch, subjectsRedux, subjectsPrevious]);

  useEffect(() => {});

  const handleAddSubject = () => {
    subjectDispatch({ type: 'add_subject' });
  };

  const handleUpdateScope = (event, uuid) => {
    const { value } = event.target;
    subjectDispatch({
      type: 'update_scope',
      subject: { uuid, scope: value }
    });
  };

  const handleUpdateWeight = (event, uuid) => {
    const { value } = event.target;
    subjectDispatch({
      type: 'update_weight',
      subject: { uuid, weight: value }
    });
  };

  const handleDestroySubject = subject => {
    subjectDispatch({
      type: 'destroy_subject',
      subject
    });
  };

  const _validate = () => {
    let containErrors = false;

    const scopes = subjectState.subjects.map(
      subject => validateInputString(subject.scope).invalid
    );

    const weights = subjectState.subjects.map(
      subject => validateInputString(subject.weight).invalid
    );

    const scopesErrors = _.includes(scopes, true);
    const weightsErrors = _.includes(weights, true);

    if (scopesErrors || weightsErrors) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleSave = () => {
    const subjects = subjectState.subjects.map(subject => {
      if (subject.add) {
        return {
          scope: subject.scope,
          weight: subject.weight,
          programUuid,
          category: 'Cognitive',
          source: 'Program'
        };
      } else {
        return {
          ...subject,
          programUuid,
          category: 'Cognitive',
          source: 'Program'
        };
      }
    });

    const check = _validate();

    if (check) {
      setHasErrors(true);
    } else {
      dispatch(
        doPostSubjects(programUuid, subjects, subjectState.subjectsRemove)
      );
    }
  };

  return (
    <EditorSubject
      subjects={subjectState.subjects}
      hasErrors={hasErrors}
      handleAddSubject={handleAddSubject}
      handleUpdateScope={handleUpdateScope}
      handleUpdateWeight={handleUpdateWeight}
      handleDestroySubject={handleDestroySubject}
      handleSave={handleSave}
    />
  );
}

EditorSubjectsContainer.propTypes = {
  match: PropTypes.object
};

EditorSubjectsContainer.defaultProps = {
  match: {}
};
