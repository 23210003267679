import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import CognitiveProgramRowHeader from '../DataRows/CognitiveProgramRowHeader';
import CognitiveProgramRowEdit from '../DataRows/CognitiveProgramRowEdit';
import ByDefaultAttempt from './ByDefaultAttempt';

const Layout = styled.div`
  width: 100%;
  font-size: 12px;
`;

export default function ByDefaultCognitive({
  userUuid,
  cohortUuid,
  programUuid,
  transcripts,
  category,
  source,
  isAdmin,
}) {
  return (
    <Layout>
      {transcripts?.length > 0 && source === 'Program' && (
        <CognitiveProgramRowHeader />
      )}

      {transcripts?.length > 0 &&
        source === 'Program' &&
        _.sortBy(transcripts, ['date', 'itemCode']).map((transcript, index) => (
          <CognitiveProgramRowEdit
            key={transcript.uuid}
            index={index}
            transcript={transcript}
            userUuid={userUuid}
            cohortUuid={cohortUuid}
            programUuid={programUuid}
            category={category}
            source={source}
            isAdmin={isAdmin}
          />
        ))}

      {transcripts?.length > 0 &&
        source === 'Milestone' &&
        _(transcripts)
          .groupBy('attempt')
          .map((value, key) => {
            return {
              attempt: key,
              transcripts: value
            };
          })
          .value()
          .map(value => (
            <ByDefaultAttempt
              key={value.attempt}
              userUuid={userUuid}
              cohortUuid={cohortUuid}
              programUuid={programUuid}
              attempt={Number(value.attempt)}
              transcripts={value.transcripts}
              category={category}
              source={source}
              isAdmin={isAdmin}
            />
          ))}
    </Layout>
  );
}

ByDefaultCognitive.propTypes = {
  transcripts: PropTypes.array,
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  isAdmin: PropTypes.bool,
};
ByDefaultCognitive.defaultProps = {
  transcripts: undefined,
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  isAdmin: false,
};
