import { Model, attr, fk } from 'redux-orm';

import {
  GET_TRANSCRIPT_SUCCESS,
  POST_TRANSCRIPT_SUCCESS,
  DELETE_TRANSCRIPT_SUCCESS,
  DELETE_TRANSCRIPTS_SUCCESS,
  PUT_TRANSCRIPT_SUCCESS,
  POST_CLONE_TRANSCRIPT_SUCCESS
} from '../actions-type';

class TranscriptCognitive extends Model {
  static reducer(action, TranscriptCognitive, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_TRANSCRIPT_SUCCESS: {
        const transcriptCognitive = [...payload.transcriptCognitive];
        transcriptCognitive.forEach(transcript =>
          TranscriptCognitive.upsert(transcript)
        );
        break;
      }

      case POST_CLONE_TRANSCRIPT_SUCCESS: {
        const transcriptCognitive = [...payload.transcriptCognitive];
        transcriptCognitive.forEach(transcript =>
          TranscriptCognitive.upsert(transcript)
        );
        break;
      }

      case POST_TRANSCRIPT_SUCCESS: {
        const { category, transcript } = { ...payload };

        if (category === 'Cognitive') {
          TranscriptCognitive.upsert(transcript);
        }
        break;
      }

      case PUT_TRANSCRIPT_SUCCESS: {
        const { category, transcript } = { ...payload };

        if (category === 'Cognitive') {
          TranscriptCognitive.upsert(transcript);
        }
        break;
      }

      case DELETE_TRANSCRIPT_SUCCESS: {
        const { category, uuid } = payload;

        if (category === 'Cognitive') {
          TranscriptCognitive.withId(uuid).delete();
        }

        break;
      }

      case DELETE_TRANSCRIPTS_SUCCESS: {
        const { category, transcripts } = payload;

        if (category === 'Cognitive') {
          transcripts.forEach(transcript =>
            TranscriptCognitive.withId(transcript).delete()
          );
        }

        break;
      }

      default:
        return session;
    }
  }
}

TranscriptCognitive.fields = {
  uuid: attr(),
  category: attr(),
  source: attr(),
  applicationDate: attr(),
  attempt: attr(),
  termType: attr(),
  term: attr(),
  year: attr(),
  level: attr(),
  date: attr(),
  itemAge: attr(),
  itemCode: attr(),
  itemName: attr(),
  institutionCode: attr(),
  sourceInstitutionUuid: attr(),
  sourceDegreeLevelUuid: attr(),
  credits: attr(),
  grade: attr(),
  score: attr(),
  classificationUuid: fk('Classification', 'transcript_cognitives'),
  programUuid: fk('Program', 'transcript_cognitives'),
  cohortUuid: fk('Cohort', 'transcript_cognitives'),
  userUuid: fk('User', 'transcript_cognitives'),
  examMilestoneUuid: fk('ExamMilestone', 'transcript_cognitives'),
  conversionMilestoneUuid: fk('ConversionMilestone', 'transcript_cognitives'),
  createdAt: attr(),
  updatedAt: attr()
};

TranscriptCognitive.modelName = 'TranscriptCognitive';

TranscriptCognitive.options = {
  idAttribute: 'uuid'
};

export default TranscriptCognitive;
