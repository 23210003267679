import fetch from '../helpers/fetch';

const getEducationLevel = educationLevelUuid =>
  fetch(`/api/education-level/${educationLevelUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getEducationLevelsBySchool = schoolUuid =>
  fetch(`/api/education-levels/find-by-school?schoolUuid=${schoolUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postEducationLevel = educationLevel =>
  fetch(`/api/education-level/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(educationLevel)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putEducationLevel = educationLevel =>
  fetch(`/api/education-level/${educationLevel.uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(educationLevel)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteEducationLevel = educationLevelUuid =>
  fetch(`/api/education-level/${educationLevelUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  getEducationLevel,
  getEducationLevelsBySchool,
  postEducationLevel,
  putEducationLevel,
  deleteEducationLevel
};
