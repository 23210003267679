import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { ContainerPage, ContainerResize } from '../Library/Layout';
import { Panel, PanelHeader } from '../Library/DashboardSC';
import Header from '../Library/Header';
import AccordionRowContainer from '../Library/AccordionRow/AccordionRowContainer';
import CohortPanel from './Cohort/CohortPanel';
import CohortAssessmentGridContainer from './CohortAssessmentGrid/CohortAssessmentGridContainer';
import CohortQuantitativeSkillsGridContainer from './CohortQuantitativeSkillsGrid/CohortQuantitativeSkillsGridContainer';

const ProgramAccordion = styled(AccordionRowContainer)`
  width: 100%;

  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label div {
    font-family: IBM Plex Sans;
    font-size: 14px;
    color: ${props => props.theme.fontColors.dark};
  }

  .accordion_row_label.label {
    width: 30%;
  }

  .program_name span,
  .accordion_row_label p {
    font-family: IBM Plex Sans;
    font-size: 16px;
    color: ${props => props.theme.fontColors.darker};
  }

  .program_name {
    flex-grow: 2;
  }
  .accordion_row_label {
    min-width: 100px;
  }

  .section_completion span {
    font-size: 12px;
    color: ${props => props.theme.fontColors.dark};
  }
`;

const Reports = ({
  program,
  cohorts,
  onFetchProgramCohorts,
  onNavigateApplicantReports,
  onNavigateCompetencyGrid,
  onNavigateAssessmentGrid,
  onNavigateOpportunityReport,
  onNavigateSelfAssessmentReport,
  onNavigateQualitativeAndDocumentsReport,
  onNavigateDidacticReport
}) => {
  const [selectedCohort, setSelectedCohort] = useState(null);
  const showTableModal = useSelector(
    state => state.cohortAssessmentReportState.showTableModal
  );

  const showQuantitativeSkillsTableModal = useSelector(
    state => state.cohortQuantitativeSkillsReportState.showTableModal
  );

  useEffect(() => {
    if (!cohorts.length) {
      onFetchProgramCohorts();
    }
  }, [cohorts.length, onFetchProgramCohorts]);

  return (
    <ContainerResize>
      <Header
        title="Reports"
        subtitle="View Reports by Cohort"
        hideBackButton
      />
      <ContainerPage>
        <Panel>
          <PanelHeader>Academic Level</PanelHeader>
          <ProgramAccordion
            isTopLevel
            expandWidth="66px"
            height="48px"
            label="Program"
            columnOne={program?.programName}
            columnOneClassName="program_name"
          >
            {cohorts.map(cohort => (
              <CohortPanel
                key={cohort.uuid}
                cohort={cohort}
                onNavigateCompetencyGrid={onNavigateCompetencyGrid}
                onNavigateApplicantReports={onNavigateApplicantReports}
                onNavigateAssessmentGrid={onNavigateAssessmentGrid}
                onNavigateOpportunityReport={onNavigateOpportunityReport}
                onNavigateQuantitativeSkillsGrid={setSelectedCohort}
                onNavigateSelfAssessmentReport={onNavigateSelfAssessmentReport}
                onNavigateDidacticReport={onNavigateDidacticReport}
                onNavigateQualitativeAndDocumentsReport={
                  onNavigateQualitativeAndDocumentsReport
                }
              />
            ))}
          </ProgramAccordion>
        </Panel>
      </ContainerPage>
      {showTableModal && <CohortAssessmentGridContainer />}
      {showQuantitativeSkillsTableModal && (
        <CohortQuantitativeSkillsGridContainer cohortUuid={selectedCohort} />
      )}
    </ContainerResize>
  );
};

Reports.propTypes = {
  cohorts: PropTypes.array,
  onFetchProgramCohorts: PropTypes.func,
  onNavigateApplicantReports: PropTypes.func,
  onNavigateCompetencyGrid: PropTypes.func,
  onNavigateAssessmentGrid: PropTypes.func.isRequired,
  program: PropTypes.object,
  onNavigateOpportunityReport: PropTypes.func,
  onNavigateSelfAssessmentReport: PropTypes.func.isRequired,
  onNavigateQualitativeAndDocumentsReport: PropTypes.func.isRequired,
  onNavigateDidacticReport: PropTypes.func.isRequired
};
Reports.defaultProps = {
  cohorts: [],
  onFetchProgramCohorts: undefined,
  onNavigateApplicantReports: undefined,
  onNavigateCompetencyGrid: undefined,
  program: {},
  onNavigateOpportunityReport: undefined
};

export default Reports;
