import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Layout = styled.h3`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 19px;
  text-transform: uppercase;
  svg {
    vertical-align: top;
    margin-right: 0.5rem;
  }
`;

const SyllabusSubHeader = ({ children, className, first }) => (
  <Layout className={`${first ? 'first ' : ''} ${className}`}>
    {children}
  </Layout>
);

SyllabusSubHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  first: PropTypes.bool
};

SyllabusSubHeader.defaultProps = {
  children: null,
  className: '',
  first: false
};

export default SyllabusSubHeader;
