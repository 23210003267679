const headersDepartmentRole = [
  {
    field: 'institutionId',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'departmentUuid',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'status',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'role',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'startDate',
    type: 'DATEONLY',
    allowEmpty: false
  },
  {
    field: 'endDate',
    type: 'DATEONLY',
    allowEmpty: true
  },
  {
    field: 'email',
    type: 'STRING',
    allowEmpty: true
  },
  {
    field: 'phone',
    type: 'STRING',
    allowEmpty: true
  }
];

const headersApplicantRoles = [
  {
    field: 'institutionId',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'status',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'startDate',
    type: 'DATEONLY',
    allowEmpty: false
  },
  {
    field: 'endDate',
    type: 'DATEONLY',
    allowEmpty: true
  },
  {
    field: 'email',
    type: 'STRING',
    allowEmpty: true
  },
  {
    field: 'phone',
    type: 'STRING',
    allowEmpty: true
  }
];

const headersStudentAssign = [
  {
    field: 'institutionId',
    type: 'STRING',
    allowEmpty: false
  }
];

module.exports = {
  headersStudentAssign,
  headersDepartmentRole,
  headersApplicantRoles
};
