import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import UserList from './UserList/UserList';
import {
  ContainerPage,
  ElevateLow,
  ContainerContent
} from '../../Library/Layout';
import SearchMulti from '../../Library/SearchMulti';

const InvisibleLineBreak = styled.div`
  height: 4px;
`;

const LoadingBar = styled.div`
  padding-top: 5px;
`;

const UserTabView = ({
  searchUserBy,
  searchUserInput,
  searching,
  handleSearch
}) => {
  return (
    <>
      <ContainerPage paddingTop="40px">
        <ElevateLow>
          <ContainerContent>
            <Grid item xs={12} sm={12}>
              <SearchMulti
                placeholder="Search By"
                searchBy={searchUserBy}
                searchInput={searchUserInput}
                options={[
                  { label: 'Institution ID', field: 'institutionId' },
                  { label: 'First Name', field: 'firstName' },
                  { label: 'Last Name', field: 'lastName' }
                ]}
                handleSearch={handleSearch}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <LoadingBar>
                {searching ? <LinearProgress /> : <InvisibleLineBreak />}
              </LoadingBar>
            </Grid>
          </ContainerContent>
        </ElevateLow>
      </ContainerPage>
      <ContainerPage paddingTop="30px">
        <ElevateLow>
          <UserList />
        </ElevateLow>
      </ContainerPage>
    </>
  );
};

UserTabView.propTypes = {
  searchUserBy: PropTypes.string,
  searchUserInput: PropTypes.string,
  searching: PropTypes.bool,
  handleSearch: PropTypes.func
};

UserTabView.defaultProps = {
  searchUserBy: undefined,
  searchUserInput: undefined,
  searching: false,
  handleSearch: undefined
};

export default UserTabView;
