import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { tintColor } from '@xcomp/xcomp-design-library/dist/helpers';

import {
  StickyTitleTd,
  TableDataText,
  PointsTd
} from '../../../../PartGradeSummary/PartAssessmentCollectionReport/CollectionReportSC';
import { TableRow, InfoTd, FillerTd } from './SharedSC';

const SummaryTitle = styled.div`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 14px;
  font-weight: 450;
  color: ${props => props.theme.fontColors.darker};
  padding: 16px 0px;
  white-space: nowrap;
`;

const TitleTd = styled(
  ({
    borderLeft,
    borderRight,
    displayNone,
    left,
    greyOne,
    redOne,
    redTwo,
    secondaryFontColor,
    blueOne,
    ...rest
  }) => <td {...rest} />
)`
  display: ${props => (props.displayNone ? 'none' : 'flex')};
  align-items: flex-end;
  justify-content: center;
  min-width: 75px;
  max-width: 75px;
  z-index: 5;
  background-color: ${props =>
    (props.redOne && tintColor(props.theme.colors.red[100], 0.5)) ||
    (props.redTwo && tintColor(props.theme.colors.red[100], 0.4)) ||
    (props.blueOne && tintColor(props.theme.colors.blue[500], 0.8)) ||
    'white'};
  ${props =>
    props.borderLeft && `border-left: solid 1px ${props.theme.colors.grey[300]}`};
  ${props =>
    props.borderRight &&
    `border-right: solid 1px ${props.theme.colors.grey[300]}`};
  border-top: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
  color: ${props =>
    props.secondaryFontColor
      ? props.theme.fontColors.dark
      : props.theme.fontColors.darker};
`;

const RotatedInfoTitle = styled.div`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 16px 0px 32px 0px;
  white-space: nowrap;
`;

const SectionPerformanceTableHeader = ({
  parts,
  useFillerColumn,
  fillerColumnWidth,
  isDelayedPanel
}) => {
  return (
    <>
      <TableRow>
        <StickyTitleTd left="0px" redOne>
          <SummaryTitle>Section Score</SummaryTitle>
        </StickyTitleTd>
        <StickyTitleTd left="75px" redOne>
          <SummaryTitle>Section Grade</SummaryTitle>
        </StickyTitleTd>
        <StickyTitleTd left="150px" redOne>
          <SummaryTitle>
            {isDelayedPanel ? 'Actions' : 'Section Rank'}
          </SummaryTitle>
        </StickyTitleTd>
        <InfoTd />
        {parts.map(part => (
          <TitleTd key={part.uuid} blueOne>
            <RotatedInfoTitle>
              {`Part ${_.padStart(part.partNumber, 2, 0)} - ${part.title}`}
            </RotatedInfoTitle>
          </TitleTd>
        ))}
        {useFillerColumn && <FillerTd width={fillerColumnWidth} />}
      </TableRow>
      <TableRow>
        <StickyTitleTd left="0px" redTwo />
        <StickyTitleTd left="75px" redTwo />
        <StickyTitleTd left="150px" redTwo />
        <InfoTd greyOne />
        {parts.map(part => (
          <PointsTd key={part.uuid} blueOne>
            <TableDataText>{`${part.weight}%`}</TableDataText>
          </PointsTd>
        ))}
        {useFillerColumn && (
          <FillerTd greyOne borderTop width={fillerColumnWidth} />
        )}
      </TableRow>
    </>
  );
};

SectionPerformanceTableHeader.propTypes = {
  parts: PropTypes.array,
  useFillerColumn: PropTypes.bool,
  isDelayedPanel: PropTypes.bool,
  fillerColumnWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

SectionPerformanceTableHeader.defaultProps = {
  parts: [],
  useFillerColumn: false,
  isDelayedPanel: false,
  fillerColumnWidth: 0
};

export default SectionPerformanceTableHeader;
