import fetch from '../helpers/fetch';

const getCourseAgeMod = programUuid =>
  fetch(`/api/course-age-mods/${programUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postCourseAgeMod = courseAgeMods =>
  fetch('/api/course-age-mods/upsert', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ courseAgeMods })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getCourseAgeMod, postCourseAgeMod };
