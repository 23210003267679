import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { doGetEducationLevelsBySchool } from '../../../../redux/actions/educationLevelActions';

import { doGetLoverbsByEducationLevels } from '../../../../redux/actions/loverbActions';

import { educationLevelsSelectorBySchool } from '../../../../redux/selectors/educationLevelsSelectors';
import { schoolSelectorBySelectedSchoolUuid } from '../../../../redux/selectors/schoolSelectors';
import EducationLevels from './EducationLevels';

const EducationLevelsContainer = () => {
  const dispatch = useDispatch();
  const [fetchLoverbs, setFetchLoverbs] = useState(false);

  const selectedSchoolUuid = useSelector(
    state => state.userState.selectedSchoolUuid,
    isEqual
  );

  const school = useSelector(
    state => schoolSelectorBySelectedSchoolUuid(state),
    isEqual
  );

  const educationLevels = useSelector(
    state => educationLevelsSelectorBySchool(state),
    isEqual
  );

  useEffect(() => {
    if (!educationLevels.length && selectedSchoolUuid) {
      dispatch(doGetEducationLevelsBySchool(selectedSchoolUuid));
      setFetchLoverbs(true);
    }
    if (educationLevels.length && fetchLoverbs) {
      dispatch(doGetLoverbsByEducationLevels(educationLevels));
      setFetchLoverbs(false);
    }
  }, [dispatch, educationLevels, fetchLoverbs, selectedSchoolUuid]);

  return <EducationLevels school={school} educationLevels={educationLevels} />;
};

export default EducationLevelsContainer;
