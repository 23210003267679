import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import TopicAlternativeScoreViewFacultyPanelContainer from './TopicAlternativeScoreViewFacultyPanelContainer';

const TopicPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 48px;
  width: calc(100% - 48px);
`;

const TopicPanelHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  font-weight: 450;
`;

const TopicPanelHeaderSub = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  padding-right: ${props => props.paddingRight};
  color: ${props => props.theme.fontColors.dark};
  text-align: right;
  }
`;

const TopicAlternativeScoreViewList = ({
  faculty,
  scoreType,
  students,
  topicScores
}) => {
  return (
    <>
      <TopicPanelHeader>
        <TopicPanelHeaderInfo>
          Faculty (Last Name, First Name)
        </TopicPanelHeaderInfo>
        <TopicPanelHeaderInfo>
          <TopicPanelHeaderSub paddingRight="24px">
            Total Relative
          </TopicPanelHeaderSub>
          <TopicPanelHeaderSub paddingRight="44px">
            Total Potential
          </TopicPanelHeaderSub>
        </TopicPanelHeaderInfo>
      </TopicPanelHeader>
      {faculty.map(facultyUser => {
        const someUuid = uuidv4();
        return (
          <TopicAlternativeScoreViewFacultyPanelContainer
            key={someUuid}
            facultyUser={facultyUser.user}
            scoreType={scoreType}
            students={students}
            topicScores={topicScores}
          />
        );
      })}
    </>
  );
};

TopicAlternativeScoreViewList.propTypes = {
  faculty: PropTypes.array,
  scoreType: PropTypes.string,
  students: PropTypes.array,
  topicScores: PropTypes.array
};

TopicAlternativeScoreViewList.defaultProps = {
  faculty: [],
  scoreType: '',
  students: '',
  topicScores: []
};

export default TopicAlternativeScoreViewList;
