import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { format } from 'date-fns';
import useSWR from 'swr';
import Moment from 'moment';

import { cohortSelectorByMatchParams } from '../../../redux/selectors/cohortsSelectors';
import CohortQuantitativeSkillsGridModal from './CohortQuantitativeSkillsGridModal';
import { fetcher } from '../../../helpers/utilities';
import { formatFullName } from '../../../helpers/format/user.format';

const CohortQuantitativeSkillsGridContainer = ({ cohortUuid }) => {
  const [startDate, selectStartDate] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );
  const [endDate, selectEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [defaultCollectionsChecked, setDefaultCollectionsChecked] =
    useState(true);

  const cohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    isEqual
  );

  useEffect(() => {
    if (cohort) {
      selectStartDate(cohort.startDate);
      selectEndDate(cohort.endDate);
    }
  }, [cohort]);

  const shouldFetch = useMemo(() => {
    const isBefore = Moment(startDate).isBefore(endDate);
    return cohortUuid && isBefore;
  }, [cohortUuid, endDate, startDate]);

  const { data, isLoading } = useSWR(
    shouldFetch
      ? `/api/cohort/${cohortUuid}/quantitative-skills-grid?startDate=${startDate}&endDate=${endDate}&fetchDefaultCollections=${defaultCollectionsChecked}`
      : null,
    fetcher
  );

  const students = useMemo(() => {
    if (data) {
      return data.students.map(student => ({
        ...student,
        studentFullName: formatFullName(student.user),
        userUuid: student.user.uuid
      }));
    }
    return [];
  }, [data]);

  return (
    <CohortQuantitativeSkillsGridModal
      startDate={startDate}
      endDate={endDate}
      cohortStartDate={cohort?.startDate}
      cohortEndDate={cohort?.endDate}
      cohortUuid={cohort?.uuid}
      selectStartDate={selectStartDate}
      selectEndDate={selectEndDate}
      students={students}
      isLoading={isLoading}
      quantitativeSkillsCollections={data?.quantitativeSkillsCollections || []}
      defaultCollectionsChecked={defaultCollectionsChecked}
      setDefaultCollectionsChecked={setDefaultCollectionsChecked}
      fetchDefaultCollections={defaultCollectionsChecked}
    />
  );
};

CohortQuantitativeSkillsGridContainer.propTypes = {
  cohortUuid: PropTypes.string.isRequired
};

export default CohortQuantitativeSkillsGridContainer;
