import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { optionsFacultyScoreView } from '../../../../helpers/options';
import FormSelect from '../../../Library/FormSelect';
import { Panel } from '../../../Library/DashboardSC';
import { HeaderSpacer } from '../ScoresSC';
import FacultyAlternativeScoreViewList from './FacultyAlternativeScoreView/FacultyAlternativeScoreViewList';
import FacultyDefaultScoreViewListContainer from './FacultyDefaultScoreView/FacultyDefaultScoreViewListContainer';

const ListSortContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 8px 21px;
`;

const FormSelectSC = styled(FormSelect)`
  && {
    background-color: ${props => props.theme.colors.white};
    width: 500px;
  }
`;

export default function FacultyList({
  assessmentScores,
  facultyScoreViewSelection,
  onFacultyScoreViewSelection,
  partFacultyUsers,
  scoreType,
  stepScoreSummary,
  students
}) {
  return (
    <>
      <HeaderSpacer />
      <Panel maxWidth={1600}>
        <ListSortContainer>
          <FormSelectSC
            handleChange={onFacultyScoreViewSelection}
            label="View"
            name="sort"
            options={optionsFacultyScoreView}
            value={facultyScoreViewSelection}
          />
        </ListSortContainer>
        {facultyScoreViewSelection === 'default' && (
          <FacultyDefaultScoreViewListContainer
            assessmentScores={assessmentScores}
            partFacultyUsers={partFacultyUsers}
            scoreType={scoreType}
            students={students}
          />
        )}
        {facultyScoreViewSelection === 'alternative' && (
          <FacultyAlternativeScoreViewList
            assessmentScores={assessmentScores}
            partFacultyUsers={partFacultyUsers}
            scoreType={scoreType}
            stepScoreSummary={stepScoreSummary}
          />
        )}
      </Panel>
    </>
  );
}

FacultyList.propTypes = {
  assessmentScores: PropTypes.array,
  facultyScoreViewSelection: PropTypes.string,
  onFacultyScoreViewSelection: PropTypes.func,
  partFacultyUsers: PropTypes.array,
  scoreType: PropTypes.string,
  stepScoreSummary: PropTypes.array,
  students: PropTypes.array
};

FacultyList.defaultProps = {
  assessmentScores: [],
  facultyScoreViewSelection: 'default',
  onFacultyScoreViewSelection: undefined,
  partFacultyUsers: [],
  scoreType: '',
  stepScoreSummary: [],
  students: []
};
