import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { doGetPart } from '../../../../redux/actions/partActions';
import {
  doGetPartGroupSummary,
  doResetPartGroupSummary
} from '../../../../redux/actions/partGroupSummaryActions';
import { doGetPartUserGroups } from '../../../../redux/actions/userGroupActions';
import {
  doGetPartGroups,
  doPutGroupRankings
} from '../../../../redux/actions/groupActions';
import { assessmentsByPartSelector } from '../../../../redux/selectors/assessmentSelectors';
import { userGroupsByPartUuid } from '../../../../redux/selectors/userGroupSelector';
import {
  groupsSelectorByPartUuid,
  groupedStudentsSelectorByPartUuid
} from '../../../../redux/selectors/groupSelectors';
import { sortByDateProperty } from '../../../../helpers/utilities';
import PageIllustration from '../../../Library/PageIllustration';
import PartGroupReport from './PartGroupReport';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const PartGroupReportContainer = () => {
  const dispatch = useDispatch();
  const { partUuid } = useParams();
  const userGroups =
    useSelector(state => userGroupsByPartUuid(state, partUuid)) || [];

  const groups = useSelector(state =>
    groupsSelectorByPartUuid(state, partUuid)
  );

  const studentsWithGroup = useSelector(state =>
    groupedStudentsSelectorByPartUuid(state, partUuid)
  );

  const partGroupSummaryState = useSelector(
    state => state.partGroupSummaryState
  );

  const reportLoadingState = useSelector(state => state.reportLoadingState);

  const assessments = useSelector(state =>
    assessmentsByPartSelector(state, partUuid)
  );

  const loading =
    reportLoadingState.loadingPart ||
    reportLoadingState.loadingPartGroupRankings ||
    reportLoadingState.loadingPartGroups ||
    reportLoadingState.loadingPartGroupSummary ||
    reportLoadingState.loadingPartUserGroups;

  const noData =
    userGroups.length === 0 ||
    _.isEmpty(partGroupSummaryState.assessmentSummaryData);

  useEffect(() => {
    if (partUuid) {
      dispatch(doGetPart(partUuid));
      dispatch(doPutGroupRankings(partUuid));
      dispatch(doGetPartGroupSummary(partUuid));
      dispatch(doGetPartGroups(partUuid));
      dispatch(doGetPartUserGroups(partUuid));
    }
  }, [partUuid, dispatch]);

  useEffect(() => {
    return () => dispatch(doResetPartGroupSummary());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress size={100} />
      </LoadingContainer>
    );
  } else if (noData) {
    return (
      <PageIllustration
        illustrationType="notFound"
        infoText="No Group Performance Data"
      />
    );
  } else {
    return (
      <PartGroupReport
        assessments={assessments.sort((a, b) =>
          sortByDateProperty(a, b, 'startDate', 'title')
        )}
        assessmentSummaryData={partGroupSummaryState.assessmentSummaryData}
        groupAvgPerStudent={partGroupSummaryState.groupAvgPerStudent}
        groupHighPerStudent={partGroupSummaryState.groupHighPerStudent}
        groupLowPerStudent={partGroupSummaryState.groupLowPerStudent}
        groups={groups.sort(
          (groupA, groupB) => groupA.groupRank - groupB.groupRank
        )}
        partGroupSummaryState={partGroupSummaryState}
        partUuid={partUuid}
        studentsWithGroup={studentsWithGroup}
        userGroups={userGroups}
      />
    );
  }
};

export default PartGroupReportContainer;
