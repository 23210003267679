import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectInstitutionCredits } from '../../../../redux/selectors/transcriptCognitiveSelectors';

export default function InstitutionCredits({
  programUuid,
  userUuid,
  cohortUuid,
  source,
  sourceInstitutionUuid
}) {
  const cognitiveCredits = useSelector(state =>
    selectInstitutionCredits(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      source,
      sourceInstitutionUuid
    )
  );

  const credits = cognitiveCredits || 'N/A';

  return <>{credits}</>;
}

InstitutionCredits.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  source: PropTypes.string,
  sourceInstitutionUuid: PropTypes.string
};

InstitutionCredits.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  source: undefined,
  sourceInstitutionUuid: undefined
};
