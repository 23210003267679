import React, { Suspense, useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import useSWRMutation from 'swr/mutation';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import Loading from '@material-ui/core/CircularProgress';
import { Paper, TableContainer } from '@material-ui/core';
import { shadeColor } from '@xcomp/xcomp-design-library/dist/helpers';

import { fetcher } from '../../../../helpers/utilities';
import { selfAssessmentAssessmentTypesSelector } from '../../../../redux/selectors/assessmentSelectors';
import { doGetAssessmentType } from '../../../../redux/actions/assessmentActions';
import { InvalidScores, ReportLeaf, SelfAssessmentAccordion } from '../../../Reports/SelfAssessmentReportComponents';
import { getCharacterizationColor } from '../../../Reports/reportUtils';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import AssessmentTypeFilter from './AssessmentTypeFilter';

const Accordion = styled(AccordionRowContainer)`
  .accordion_row_label {
    display: flex;
    flex: ${({ isTablet }) => (!isTablet ? 1 : 'unset')};
  }

  & > div:hover > .hover-effect {
    background: ${props => props.theme.colors.grey[400]};
  }

  .hover-effect > div:nth-child(2) {
    flex-direction: ${({ isTablet }) => (isTablet ? 'column' : 'row')};
    justify-content: ${({ isTablet }) =>
      isTablet ? 'space-around' : 'flex-start'};
    align-items: ${({ isTablet }) => (isTablet ? 'flex-start' : 'center')};
  }

  .column_one {
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
  }

  .column_one > span {
    width: 100%;
  }
`;

const Label = styled.div`
  display: flex;
  font-size: 20px;
  color: ${props => props.theme.fontColors.darker};
`;

const Layout = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
`;

const Calculations = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
`;
const GroupLabel = styled.div`
  padding: 10px;
`;
const GroupInput = styled.div`
  padding: 10px;
  text-align: center;
`;

const Record = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width};
`;

const ColumnOne = ({isMutating, student}) => {
  return (
    <Calculations>
      {isMutating 
        ? <Loading style={{marginRight: 20}} />
        : student &&
        <>
          <Record width="34%">
            <Group>
              <GroupLabel>Percentage</GroupLabel>
              <GroupInput>
                {!student.potValue 
                  ? '0.00'
                  : (student?.relValue / student?.potValue * 100).toFixed(2)
                }
                %
              </GroupInput>
            </Group>
          </Record>
          <Record width="33%">
            <Group>
              <GroupLabel>Rel. Value</GroupLabel>
              <GroupInput>
                {student?.relValue.toFixed(2)}
              </GroupInput>
            </Group>
          </Record>
          <Record width="33%">
            <Group>
              <GroupLabel>Pot. Value</GroupLabel>
              <GroupInput>
                {student?.potValue.toFixed(2)}
              </GroupInput>
            </Group>
          </Record>
        </>
      }
    </Calculations>
  )
}

ColumnOne.propTypes = {
  isMutating: PropTypes.bool.isRequired,
  student: PropTypes.object,
};

ColumnOne.defaultProps = {
  student: undefined,
};

const SelfAssessmentReport = ({
  userUuid,
  cohortUuid,
}) => {
  const dispatch = useDispatch();
  const [partColors, setPartColors] = useState([]);
  const [selectedAssessmentTypes, selectAssessmentType] = useState([]);
  const themeContext = useContext(ThemeContext);

  const assessmentTypes = useSelector(
    state => selfAssessmentAssessmentTypesSelector(state),
    isEqual
  );

  useEffect(() => {
    if (cohortUuid) {
      dispatch(doGetAssessmentType());
    }
  }, [dispatch, cohortUuid]);

  useEffect(() => {
    if (assessmentTypes.length > 0) {
      selectAssessmentType(assessmentTypes)
    }
  }, [assessmentTypes]);

  const { trigger, error, data, isMutating } = useSWRMutation(
    `/api/cohort/self-assessment-transcript/student-transcript?`,
    fetcher,
    {
      throwOnError: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (selectedAssessmentTypes.length > 0) {
      trigger({queryParameters: `userUuid=${userUuid}&cohortUuid=${cohortUuid}&assessmentTypes=${JSON.stringify(selectedAssessmentTypes.map(sat => sat.uuid))}`});
    }
  }, [selectedAssessmentTypes, trigger, cohortUuid, userUuid]);

  useEffect(() => {
    if (data && data.numberOfParts) {
      const amount = 0.5 / (data.numberOfParts + 1);
      setPartColors(
        Array(data.numberOfParts + 2).fill(0).map((e, index) => shadeColor(themeContext.colors.white, index * amount)).slice(1)
      );
    }
  }, [data, themeContext.colors.white, themeContext.colors.black]);

  return (
    <Accordion
      withBottomBorder={true}
      expandWidth="66px"
      height="90px"
      label={
        <Label style={{width: 400}}>
          <div style={{width: 270}}>Self Assessment</div>
          {selectedAssessmentTypes.length > 0 &&
            <AssessmentTypeFilter
              assessmentTypes={assessmentTypes}
              selectedAssessmentTypes={selectedAssessmentTypes}
              selectAssessmentType={selectAssessmentType}
            />
          }
        </Label>
      }
      columnOne={<ColumnOne isMutating={isMutating} student={data && data.student} />}
      columnOneClassName="column_one"
    >
      <Suspense fallback={<LinearProgress />}>
        {// eslint-disable-next-line
        isMutating
          ?
            <Layout>
              <LoadingContainer>
                <LinearProgress />
              </LoadingContainer>
            </Layout>
          : error
            ?
              <Layout style={{ flexDirection: 'column' }}>
                <LoadingContainer>
                  <p>There was an error loading the data.</p>
                </LoadingContainer>
              </Layout>
            :
              <>
                {data && !isMutating && Object.keys(data.invalidCharacterizations).length > 0 &&
                  <div style={{padding: '10px 0 16px 0', width: '100%'}}>
                    <InvalidScores invalidCharacterizations={data.invalidCharacterizations} style={{minWidth: '100%', maxWidth: '100%', margin: 0}} />
                  </div>
                }
                <TableContainer component={Paper}>
                  {data?.student?.characterizations.map((c) =>
                    <SelfAssessmentAccordion
                      key={c.characterization}
                      columnOne={<span style={{ fontWeight: c.total === 0 ? 300 : 600 }}>{c.total.toString()}</span>}
                      columnTwo=' '
                      columnThree=' '
                      columnFour=' '
                      columnOneClassName="column_one_label"
                      columnTwoClassName="column_two_label"
                      columnThreeClassName="column_three_label"
                      columnFourClassName="column_four_label"
                      expandWidth="66px"
                      height="40px"
                      isStatisticStudent={false}
                      label={c.characterization}
                      $level='characterization'
                      $noPadding={true}
                      $background={getCharacterizationColor(c)}
                    >
                      {c.courses.length === 0
                        ?
                          <div style={{ width: '100%', textAlign: 'center', fontSize: 16, fontStyle: 'italic' }}>There are no scores for this characterization</div>
                        :
                        c.courses.map((course, index) =>
                          <SelfAssessmentAccordion
                            key={course.courseTitle}
                            expandWidth="66px"
                            height="40px"
                            isStatisticStudent={false}
                            label={<div><span style={{ fontWeight: 600, marginRight: 10 }}>{course.courseTitle}</span></div>}
                            $level='course'
                            $even={index % 2 === 0}
                            backgroundSelected={themeContext.colors.grey[500]}
                          >
                            {course.parts.map((part, index) =>
                              <SelfAssessmentAccordion
                                key={part.title}
                                expandWidth="66px"
                                height="40px"
                                isStatisticStudent={false}
                                label={<div><span style={{ fontWeight: 600, marginRight: 10 }}>Part {part.partNumber}</span> {part.title}</div>}
                                $level='part'
                                $even={index % 2 === 0}
                                $background={partColors[index]}
                                backgroundSelected={partColors[index]}
                              >
                                {part.scores.map((score, index) =>
                                  <ReportLeaf
                                    key={score.key}
                                    even={index % 2 === 0}
                                    assessmentTitle={score.assessmentTitle}
                                    blockTitle={score.blockTitle}
                                    blockNumber={score.blockNumber}
                                    relValue={score.relValue}
                                    potValue={score.potValue}
                                  />
                                )}
                              </SelfAssessmentAccordion>
                            )
                            }
                          </SelfAssessmentAccordion>
                        )
                      }
                    </SelfAssessmentAccordion>
                  )}
                </TableContainer>
              </>
        }
      </Suspense>
    </Accordion>

  );
};

SelfAssessmentReport.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
};

SelfAssessmentReport.defaultProps = {
  userUuid: '',
  cohortUuid: '',
};

export default SelfAssessmentReport;