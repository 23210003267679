import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CohortApplicantActions from './CohortApplicantActions';
import CohortApplicant from './CohortApplicant';
import CohortDeleteApplicantConfirmation from './CohortDeleteApplicantConfirmation';
import CohortApplicantAssignContainer from './CohortApplicantAssign/CohortApplicantAssignContainer';
import CohortUploadApplicantsContainer from './CohortUploadApplicants/CohortUploadApplicantsContainer';

import {
  doGetUserCohorts,
  doPatchUserCohortStatus,
  doDeleteUserCohorts,
  doPostStudentConversion
} from '../../../redux/actions/userCohortActions';
import { userApplicantSelectorByCohort } from '../../../redux/selectors/userRolesSelectors';
import ThirdPartyContainer from './ThirdParty/ThirdPartyContainer';
import { usePrevious } from '../../../helpers/hooks/state.hooks';

import {
  useCohortApplicantsDispatch,
  useCohortApplicantsState
} from './cohortApplicants.context';
import { roles } from '../../../helpers/constants';

export default function CohortApplicantContainer({ cohortUuid }) {
  const dispatch = useDispatch();
  const { cohortUsers } = useCohortApplicantsState();
  const cohortApplicantsDispatch = useCohortApplicantsDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [thirdPartyModal, setThirdPartyModal] = useState(false);
  const [thirdPartySelected, setThirdPartySelected] = useState(undefined);
  const [filterStatus, setFilterStatus] = useState({
    active: false,
    complete: false,
    inactive: false,
    delayed: false,
    competent: false,
    withdrawn: false,
    transferred: false
  });
  const [openAssignApplicant, setOpenAssignApplicant] = useState(false);

  const cohortUsersORM = useSelector(
    state => userApplicantSelectorByCohort(state, cohortUuid),
    _.isEqual
  );

  const selectedRole = useSelector(state => state.userState.selectedRole);
  const isAdmin = selectedRole === roles.programAdmin;

  const cohortUsersPrevious = usePrevious(cohortUsersORM);

  useEffect(() => {
    const equalityCheck = !_.isEqual(cohortUsersPrevious, cohortUsersORM);

    if (equalityCheck) {
      cohortApplicantsDispatch({
        type: 'exist',
        cohortUsersORM
      });
    }
  }, [cohortUsersPrevious, cohortUsersORM, cohortApplicantsDispatch]);

  const handleFilterStatus = selection => {
    setFilterStatus(filterStatus => ({
      ...filterStatus,
      [selection]: !filterStatus[selection]
    }));
  };

  const handleSelect = event => {
    if (event.target.checked) {
      cohortApplicantsDispatch({
        type: 'select-all'
      });
    } else {
      cohortApplicantsDispatch({
        type: 'deselect-all'
      });
    }
  };

  const filterCohortUsers = () => {
    const { active, complete } = filterStatus;

    const noFilters = !active && !complete;

    if (noFilters) {
      return cohortUsers;
    } else {
      const filterStatus = cohortUsers.filter(cohortUser => {
        const { status } = cohortUser;

        return (
          (active && status === 'active') || (complete && status === 'complete')
        );
      });

      return filterStatus;
    }
  };

  const handleAssignApplicantOpen = () => {
    setOpenAssignApplicant(true);
  };

  const handleAssignApplicantClose = () => {
    setOpenAssignApplicant(false);
  };

  const handleSelectedStatus = status => {
    const selectedUsers = cohortUsers.filter(
      cohortUser => cohortUser.selected === true
    );

    const changeStatus = selectedUsers.map(user => ({
      uuid: user.uuid,
      status
    }));

    dispatch(doPatchUserCohortStatus(changeStatus));
  };

  const handleOpenApplicanUpload = () => {
    setOpenModal(true);
  };

  const handleCloseApplicantUpload = () => {
    setOpenModal(false);
    dispatch(doGetUserCohorts(cohortUuid));
  };

  const handleInitiateDelete = () => {
    setDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setDeleteModal(false);
  };

  const handleDeleteApplicants = () => {
    const selectedUsers = cohortUsers.filter(
      cohortUser => cohortUser.selected === true
    );

    if (selectedUsers.length > 0) {
      dispatch(doDeleteUserCohorts(selectedUsers));
    }

    setDeleteModal(false);
  };

  const handleStudentConversion = () => {
    const selectedUsers = cohortUsers.filter(
      cohortUser => cohortUser.selected === true
    );

    if (selectedUsers.length > 0) {
      dispatch(doPostStudentConversion(selectedUsers));
    }
  };

  const handleThirdPartyOpen = uuid => {
    setThirdPartySelected(uuid);
    setThirdPartyModal(true);
  };

  const handleThirdPartyClose = () => {
    setThirdPartySelected(undefined);
    setThirdPartyModal(false);
  };

  return (
    <>
      {isAdmin &&
        <CohortApplicantActions
          handleSelectedStatus={handleSelectedStatus}
          handleAssignApplicantOpen={handleAssignApplicantOpen}
          handleOpenApplicanUpload={handleOpenApplicanUpload}
          handleInitiateDelete={handleInitiateDelete}
          handleStudentConversion={handleStudentConversion}
        />
      }
      <CohortApplicant
        cohortUsers={filterCohortUsers()}
        activeCount={
          cohortUsers.filter(cohortUser => cohortUser.status === 'active')
            .length
        }
        completeCount={
          cohortUsers.filter(cohortUser => cohortUser.status === 'complete')
            .length
        }
        filterStatus={filterStatus}
        handleFilterStatus={handleFilterStatus}
        handleSelect={handleSelect}
        handleThirdPartyOpen={handleThirdPartyOpen}
        isAdmin={isAdmin}
      />
      {isAdmin &&
        <>
          <CohortApplicantAssignContainer
            cohortUuid={cohortUuid}
            openAssignApplicant={openAssignApplicant}
            handleAssignApplicantClose={handleAssignApplicantClose}
          />
          <CohortUploadApplicantsContainer
            cohortUuid={cohortUuid}
            openModal={openModal}
            handleCloseApplicantUpload={handleCloseApplicantUpload}
          />
          <CohortDeleteApplicantConfirmation
            openModal={deleteModal}
            handleCancelDelete={handleCancelDelete}
            handleDeleteApplicants={handleDeleteApplicants}
          />
          <ThirdPartyContainer
            userUuid={thirdPartySelected}
            openModal={thirdPartyModal}
            handleThirdPartyClose={handleThirdPartyClose}
          />
        </>
      }
    </>
  );
}

CohortApplicantContainer.propTypes = {
  cohortUuid: PropTypes.string
};
CohortApplicantContainer.defaultProps = {
  cohortUuid: undefined
};
