import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Layout = styled.div`
  width: 100%;
  margin-right: 30px;
`;

const Label = styled.div`
  font-size: 0.8rem;
  margin-bottom: 10px;
  color: ${props => props.theme.fontColors.dark};
`;

const LoverbRow = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 0.8rem;
`;

const ObjectiveVerb = styled.div`
  width: 100%;
`;

const LoverbShow = ({ loverbs }) => {
  return (
    <Layout>
      <Label>Loverbs</Label>
      {loverbs.map(loverb => (
        <LoverbRow key={loverb.uuid}>
          <ObjectiveVerb>{loverb.objectiveVerb}</ObjectiveVerb>
        </LoverbRow>
      ))}
    </Layout>
  );
};

LoverbShow.propTypes = {
  loverbs: PropTypes.array
};

LoverbShow.defaultProps = {
  loverbs: []
};

export default LoverbShow;
