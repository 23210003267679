const status = {
  created: 'CREATED',
  inProgress: 'IN PROGRESS',
  committed: 'COMMITTED',
  archived: 'ARCHIVED'
};

const timespan = {
  oneTime: 'ONE TIME',
  weekly: 'WEEKLY',
  openEnded: 'OPEN ENDED'
};

const roles = {
  institutionAdmin: 'institution_admin',
  schoolAdmin: 'school_admin',
  programAdmin: 'program_admin',
  programFaculty: 'program_faculty',
  programStaff: 'program_staff',
  departmentFaculty: 'department_faculty',
  departmentStaff: 'department_staff',
  applicant: 'applicant',
  student: 'student'
};

const rolesMap = {
  institution_admin: 'Institution Admin',
  school_admin: 'School Admin',
  program_admin: 'Program Admin',
  program_faculty: 'Program Faculty',
  program_staff: 'Program Staff',
  department_faculty: 'Department Faculty',
  department_staff: 'Department Staff',
  applicant: 'Applicant',
  student: 'Student'
};

const userStatus = {
  active: 'active',
  complete: 'complete'
};

const userStatusMap = {
  active: 'Active',
  complete: 'Complete'
};

const applicantStatus = {
  active: 'active',
  complete: 'complete'
};

const applicantStatusMap = {
  active: 'Active',
  complete: 'Complete'
};

const studentStatus = {
  active: 'active',
  complete: 'complete',
  inactive: 'inactive',
  delayed: 'delayed',
  competent: 'competent',
  withdrawn: 'withdrawn',
  transferred: 'transferred'
};

const studentStatusMap = {
  active: 'Active',
  complete: 'Complete',
  inactive: 'Inactive',
  delayed: 'Delayed',
  competent: 'Competent',
  withdrawn: 'Withdrawn',
  transferred: 'Transferred'
};

const cohortRoleStatusMap = {
  active: 'Active',
  complete: 'Complete',
  inactive: 'Inactive',
  delayed: 'Delayed',
  competent: 'Competent',
  withdrawn: 'Withdrawn',
  transferred: 'Transferred'
};

const cohortRolesStatus = {
  active: 'active',
  complete: 'complete',
  inactive: 'inactive',
  delayed: 'delayed',
  competent: 'competent',
  withdrawn: 'withdrawn',
  transferred: 'transferred'
};

const userSectionStatus = {
  active: 'active',
  delayed: 'delayed',
  withdrew: 'withdrew',
  credit: 'credit',
  pass: 'pass',
  pass_with_remediation: 'pass_with_remediation',
  fail: 'fail',
  fail_with_remediation: 'fail_with_remediation'
};

const userSectionStatusMap = {
  active: 'Active',
  delayed: 'Delayed',
  withdrew: 'Withdrew',
  credit: 'Credit',
  pass: 'Pass',
  pass_with_remediation: 'Pass with Remediation',
  fail: 'Fail',
  fail_with_remediation: 'Fail with Remediation'
};

const syllabusTypes = {
  encounter: 'encounter',
  assessment: 'assessment'
};

const scoreType = {
  attempt: 'Attempt',
  opportunity: 'Opportunity'
};

const gradingTechniques = {
  points: 'points',
  dividedAssessmentPoints: 'dividedAssessmentPoints',
  dividedCohortHigh: 'dividedCohortHigh',
  curved: 'curved',
  scaled: 'scaled'
};

const gradingTechniquesMap = {
  points: 'Points',
  dividedAssessmentPoints: 'Divided by Assessment Possible Points',
  dividedCohortHigh: 'Divided by Cohort High',
  curved: 'Curved',
  scaled: 'Scaled'
};

const encounterTypesMap = {
  didactic: 'Didactic',
  online: 'Online',
  seminar: 'Seminar',
  seminarLinked: 'Seminar (Linked)',
  laboratory: 'Laboratory',
  simulation: 'Simulation',
  practicalExperience: 'Practical Experience',
  practicalExperienceLinked: 'Practical Experience (Linked)'
};

const assessmentTypesMap = {
  basicDidactic: 'Basic Didactic',
  appliedDidactic: 'Applied Didactic',
  quantitativeSkills: 'Quantitative Skills',
  qualitativeSkills: 'Qualitative Skills',
  documentProject: 'Document/Project',
  discussion: 'Discussion'
};

const gradeCalculationDataSourceMap = {
  ASSESSMENTS: 'By Assessments',
  LEGACY: 'Legacy',
  PARTS: 'By Parts'
};

const getRelatedAssessmentType = linkedAssessmentType =>
  linkedAssessmentType === encounterTypesMap.practicalExperienceLinked
    ? assessmentTypesMap.quantitativeSkills
    : assessmentTypesMap.discussion;

const termHash = {
  Semester: {
    Fall: '12-15',
    Spring: '05-15',
    Summer: '08-15',
    Summer1: '06-30',
    Summer2: '08-15'
  },
  Quarter: {
    Fall: '12-15',
    Winter: '03-15',
    Spring: '06-15',
    Summer: '08-30'
  },
  Trimester: {
    Fall: '12-15',
    Spring: '04-30',
    Summer: '08-15'
  }
};

const partStatusCommitMap = {
  COMMITTED: 'Committed',
  UNCOMMITTED: 'Not Committed'
};

const partStatusGradeMap = {
  GRADED: 'Graded',
  NOT_GRADED: 'Not Graded',
  GRADING_IN_PROGRESS: 'Grading in Progress'
};

const applicantDemographicReportSummaryMapping = [
  { value: 'ageAtApplicationHigh', label: 'Oldest' },
  { value: 'ageAtApplicationAverage', label: 'Average' },
  { value: 'ageAtApplicationLow', label: 'Youngest' }
];

const applicantDemographicReportSummaryGenderMapping = [
  { value: 'numberMaleApplicants', label: 'Male' },
  { value: 'numberFemaleApplicants', label: 'Female' },
  { value: 'numberUnreportedApplicants', label: 'Unreported/Other' }
];

const applicantDemographicReportSummaryEthnicityMapping = [
  { value: 'numberWhiteApplicants', label: 'White' },
  { value: 'numberAsianApplicants', label: 'Asian' },
  {
    value: 'numberNativeOrPacificIslanderApplicants',
    label: 'Native American or Pacific Islander'
  },
  { value: 'numberAfricanAmericanApplicants', label: 'Black African American' },
  {
    value: 'numberHispanicOrLatinoApplicants',
    label: 'Hispanic or Latino'
  },
  { value: 'numberOtherEthnicityApplicants', label: 'Other' },
  { value: 'numberUnreportedEthnicityApplicants', label: 'Unreported' }
];

const applicantReportTranscriptSourceOptions = [
  { label: 'Cumulative', value: 'Cumulative' },
  { label: 'Program', value: 'Program' },
  { label: 'Milestone', value: 'Milestone' }
];

const applicantCognitiveReportTranscriptSourceOptions = [
  { label: 'Cognitive Cumulative', value: 'Cumulative' },
  { label: 'Program', value: 'Program' },
  { label: 'Milestone', value: 'Milestone' }
];

const applicantReportTranscriptScopeOptions = [
  { label: 'Cumulative', value: 'Cumulative' }
];

const applicantReportRankByOptions = [
  { label: 'Score', value: 'score' },
  { label: 'Credits', value: 'credit' }
];

const descriptionMaxLength = 10000;

module.exports = {
  applicantDemographicReportSummaryMapping,
  applicantDemographicReportSummaryGenderMapping,
  applicantDemographicReportSummaryEthnicityMapping,
  applicantReportTranscriptScopeOptions,
  applicantReportRankByOptions,
  applicantReportTranscriptSourceOptions,
  applicantCognitiveReportTranscriptSourceOptions,
  applicantStatus,
  applicantStatusMap,
  assessmentTypesMap,
  cohortRolesStatus,
  cohortRoleStatusMap,
  encounterTypesMap,
  getRelatedAssessmentType,
  gradeCalculationDataSourceMap,
  gradingTechniques,
  gradingTechniquesMap,
  partStatusCommitMap,
  partStatusGradeMap,
  roles,
  rolesMap,
  scoreType,
  status,
  studentStatus,
  studentStatusMap,
  syllabusTypes,
  timespan,
  termHash,
  userSectionStatus,
  userSectionStatusMap,
  userStatus,
  userStatusMap,
  descriptionMaxLength
};
