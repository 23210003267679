import { Model, attr, fk } from 'redux-orm';

import {
  DELETE_USER_COHORTS_SUCCESS,
  DELETE_USER_COHORT_SUCCESS,
  GET_APPLICANTS_BY_PROGRAM_SUCCESS,
  GET_COHORT_APPLICANTS_BY_STATUS_SUCCESS,
  GET_COHORT_ASSESSMENT_SCORES_SUCCESS,
  GET_PROGRAM_COHORTS_SUCCESS,
  GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS,
  GET_USERS_BY_SEARCH_ROLES_SUCCESS,
  GET_USERS_COHORT_SUCCESS,
  GET_USER_COHORT_BY_USER_AND_COHORT_SUCCESS,
  GET_USER_SUCCESS,
  PATCH_USER_COHORT_STATUS_SUCCESS,
  POST_COHORT_COMPETENCY_GRID_SUCCESS,
  POST_STUDENT_CONVERSION_SUCCESS,
  POST_USER_COHORTS_SUCCESS,
  POST_USER_COHORT_SUCCESS,
  POST_USER_ROLE_SUCCESS
} from '../actions-type';

class UserCohort extends Model {
  static reducer(action, UserCohort, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_PROGRAM_COHORTS_SUCCESS:
      case GET_USERS_COHORT_SUCCESS:
      case GET_USER_COHORT_BY_USER_AND_COHORT_SUCCESS:
      case POST_USER_COHORTS_SUCCESS:
      case GET_COHORT_APPLICANTS_BY_STATUS_SUCCESS:
      case GET_USER_SUCCESS:
      case PATCH_USER_COHORT_STATUS_SUCCESS:
      case POST_STUDENT_CONVERSION_SUCCESS:
      case GET_COHORT_ASSESSMENT_SCORES_SUCCESS: {
        const userCohorts = [...payload.userCohorts];
        userCohorts.forEach(userCohort => UserCohort.upsert(userCohort));
        break;
      }
      case POST_COHORT_COMPETENCY_GRID_SUCCESS: {
        const userCohortsActive = [...payload.studentsActiveComplete];
        const userCohortsDelayed = [...payload.studentsDelayed];

        userCohortsActive.forEach(userCohort => UserCohort.upsert(userCohort));
        userCohortsDelayed.forEach(userCohort => UserCohort.upsert(userCohort));
        break;
      }
      case POST_USER_COHORT_SUCCESS: {
        const { userCohort } = payload;
        UserCohort.upsert(userCohort);
        break;
      }
      case DELETE_USER_COHORTS_SUCCESS: {
        const applicants = [...payload.applicants];
        applicants.forEach(applicant =>
          UserCohort.withId(applicant.uuid).delete()
        );
        break;
      }
      case GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS: {
        const programs = [...payload.school.programs];
        programs.forEach(program => {
          const cohorts = [...program.cohorts];
          cohorts.forEach(cohort => {
            const userCohorts = [...cohort.user_cohorts];
            userCohorts.forEach(userCohort => {
              const userCohortCopy = { ...userCohort };
              delete userCohortCopy.user;
              UserCohort.upsert(userCohortCopy);
            });
          });
        });
        break;
      }
      case GET_USERS_BY_SEARCH_ROLES_SUCCESS: {
        if (payload?.userCohorts?.length > 0) {
          const userCohorts = [...payload.userCohorts];
          userCohorts.forEach(userCohort => UserCohort.upsert(userCohort));
        }
        break;
      }
      case POST_USER_ROLE_SUCCESS: {
        if (payload.roleType === 'cohort_role') {
          UserCohort.create(payload.role);
        }
        break;
      }
      case DELETE_USER_COHORT_SUCCESS: {
        UserCohort.withId(action.payload.uuid).delete();
        break;
      }
      case GET_APPLICANTS_BY_PROGRAM_SUCCESS: {
        const userCohorts = [...payload.userCohorts];

        if (userCohorts?.length > 0) {
          userCohorts.forEach(userCohort => {
            const {
              uuid,
              role,
              email,
              phone,
              status,
              startDate,
              endDate,
              userUuid,
              cohortUuid,
              createdAt,
              updatedAt
            } = userCohort;

            UserCohort.upsert({
              uuid,
              role,
              email,
              phone,
              status,
              startDate,
              endDate,
              userUuid,
              cohortUuid,
              createdAt,
              updatedAt
            });
          });
        }

        break;
      }
      default:
        return session;
    }
  }
}

UserCohort.fields = {
  uuid: attr(),
  role: attr(),
  email: attr(),
  phone: attr(),
  status: attr(),
  startDate: attr(),
  endDate: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  userUuid: fk({
    to: 'User',
    as: 'user',
    relatedName: 'user_cohorts'
  }),
  cohortUuid: fk({
    to: 'Cohort',
    as: 'cohort',
    relatedName: 'user_cohorts'
  })
};

UserCohort.modelName = 'UserCohort';

UserCohort.options = {
  idAttribute: 'uuid'
};

export default UserCohort;
