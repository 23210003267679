import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import PartFacultyReportAssessmentListContainer from './PartFacultyReportAssessmentDetail/PartFacultyReportAssessmentListContainer';

const PartFacultyReportPanelAccordion = styled(
  ({ withBottomBorder, withTopBorder, criticalFailure, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label,
  .column_two_label,
  .column_three_label,
  .column_four_label {
    flex-grow: 0;
    width: 100px;
    text-align: right;
  }

  .accordion_row_label div {
    font-size: 16px;
    letter-spacing: 0.15;
    color: ${props => props.theme.fontColors.darker};
  }

  .column_one_label,
  .column_two_label,
  .column_three_label {
    margin-right: 16px;
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props =>
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.theme.fontColors.darker};
    }
  }

  .column_four_label {
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props => props.theme.fontColors.darker};
    }
  }

  .hover-effect {
    padding-right: 44px;
  }
  width: 100%;
`;

const PartFacultyReportPanel = ({
  assessments,
  assessmentTechniques,
  assessmentTypes,
  facultyUuid,
  isSummaryPanel,
  partFacultyLabel,
  partFacultyReportAssessmentData,
  partFacultyParticipationPercentage,
  partFacultyRelativePercentage,
  partFacultyTotalPotentialScore,
  partFacultyTotalRelativeScore,
  partUuid
}) => {
  return (
    <PartFacultyReportPanelAccordion
      withBottomBorder
      expandWidth="66px"
      height="50px"
      label={partFacultyLabel}
      columnOne={`${partFacultyParticipationPercentage}%`}
      columnTwo={`${partFacultyRelativePercentage}%`}
      columnThree={partFacultyTotalRelativeScore}
      columnFour={partFacultyTotalPotentialScore}
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
      columnThreeClassName="column_three_label"
      columnFourClassName="column_four_label"
    >
      <PartFacultyReportAssessmentListContainer
        assessments={assessments}
        assessmentTechniques={assessmentTechniques}
        assessmentTypes={assessmentTypes}
        facultyUuid={facultyUuid}
        isSummaryPanel={isSummaryPanel}
        partFacultyReportAssessmentData={partFacultyReportAssessmentData}
        partUuid={partUuid}
      />
    </PartFacultyReportPanelAccordion>
  );
};

PartFacultyReportPanel.propTypes = {
  assessments: PropTypes.array,
  assessmentTechniques: PropTypes.array,
  assessmentTypes: PropTypes.array,
  facultyUuid: PropTypes.string,
  isSummaryPanel: PropTypes.bool,
  partFacultyLabel: PropTypes.string,
  partFacultyReportAssessmentData: PropTypes.object,
  partFacultyParticipationPercentage: PropTypes.string,
  partFacultyRelativePercentage: PropTypes.string,
  partFacultyTotalPotentialScore: PropTypes.string,
  partFacultyTotalRelativeScore: PropTypes.string,
  partUuid: PropTypes.string
};

PartFacultyReportPanel.defaultProps = {
  assessments: [],
  assessmentTechniques: [],
  assessmentTypes: [],
  facultyUuid: '',
  isSummaryPanel: false,
  partFacultyLabel: '',
  partFacultyReportAssessmentData: {},
  partFacultyParticipationPercentage: '',
  partFacultyRelativePercentage: '',
  partFacultyTotalPotentialScore: '',
  partFacultyTotalRelativeScore: '',
  partUuid: ''
};

export default PartFacultyReportPanel;
