import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Heading3,
  ButtonPrimary,
  ButtonOutline
} from '@xcomp/xcomp-design-library';

import SimpleModal from '../../../../Library/Modal/SimpleModal';
import { Body1Dark } from '../../../../Library/SyllabusComponents';

const ModalBody = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;

  h3 {
    margin-top: 0;
    margin-bottom: 8px;
  }

  button {
    margin-top: 24px;
    margin-right: 16px;
  }
`;

const PartStatusModal = ({
  isModalOpen,
  handleModalClose,
  handleSubmit,
  message,
  heading,
  buttonText
}) => {
  const handleModalSubmit = () => {
    handleSubmit();
    handleModalClose();
  };
  return (
    <SimpleModal
      open={Boolean(isModalOpen)}
      onClose={handleModalClose}
      maxWidth="sm"
    >
      <ModalBody>
        <Heading3>{heading}</Heading3>
        <Body1Dark>{message}</Body1Dark>
        <ButtonOutline onClick={handleModalClose}>Cancel</ButtonOutline>
        <ButtonPrimary onClick={handleModalSubmit}>{buttonText}</ButtonPrimary>
      </ModalBody>
    </SimpleModal>
  );
};

PartStatusModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  message: PropTypes.string,
  heading: PropTypes.string,
  buttonText: PropTypes.string
};
PartStatusModal.defaultProps = {
  isModalOpen: false,
  handleModalClose: undefined,
  handleSubmit: undefined,
  message: '',
  heading: '',
  buttonText: ''
};

export default PartStatusModal;
