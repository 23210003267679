import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import FormSelectError from '../../Library/FormSelectError';
import { convertNullToString } from '../../../helpers/utilities';
import { programCipsSelector } from '../../../redux/selectors/degreeCodeSelector';
import { validateSelectField } from '../../../helpers/validation/validateGeneric';

export default function ProgramCip({ programCip, handleChange, hasErrors }) {
  const programCips = useSelector(
    state => programCipsSelector(state),
    _.isEqual
  );

  const programCipsOptions = _.map(programCips, programCip => ({
    value: programCip.uuid,
    label: `${programCip.code} - ${programCip.title}`
  }));

  return (
    <div>
      <FormSelectError
        name="programCip"
        label="Cip*"
        handleValidation={validateSelectField}
        hasErrors={hasErrors}
        value={convertNullToString(programCip)}
        handleChange={handleChange}
        options={programCipsOptions}
      />
    </div>
  );
}

ProgramCip.propTypes = {
  programCip: PropTypes.string,
  handleChange: PropTypes.func,
  hasErrors: PropTypes.bool
};
ProgramCip.defaultProps = {
  programCip: '',
  handleChange: undefined,
  hasErrors: false
};
