import React, { useCallback, useMemo } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import PropTypes from 'prop-types';
import { convertToRaw } from 'draft-js';
import { debounce, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { FormHelperText } from '@material-ui/core';
import { draftToMarkdown } from 'markdown-draft-js';
import { colors } from '@xcomp/xcomp-design-library';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
  extendedStyleItems,
  useDescriptionEditorState
} from '../../../helpers/hooks/useDescriptionEditorState';
import {
  doSetCourseDescription,
  doSetPartDescription,
  doSetSectionDescription
} from '../../../redux/actions/descriptionActions';
import { descriptionMaxLength } from '../../../helpers/constants';

const DescriptionEditor = ({
  courseAdd,
  descriptionFor,
  section,
  part,
  course
}) => {
  const description = useMemo(() => {
    switch (descriptionFor) {
      case 'Section':
        return section.description;
      case 'Part':
        return part.description;
      case 'Course':
        return course.description;
      default:
        return '';
    }
  }, [
    course.description,
    descriptionFor,
    part.description,
    section.description
  ]);

  const { onEditorStateChange, editorState, editorstyle, wordCount } =
    useDescriptionEditorState(description);

  const dispatch = useDispatch();

  const setReduxData = debounce(
    useCallback(
      state => {
        const content = draftToMarkdown(
          convertToRaw(state.getCurrentContent()),
          {
            styleItems: extendedStyleItems
          }
        );
        if (!isEmpty(section)) {
          dispatch(doSetSectionDescription(content, section));
          return;
        }
        if (!isEmpty(part)) {
          dispatch(doSetPartDescription(content, part));
        }
        if (!isEmpty(course) || courseAdd) {
          dispatch(doSetCourseDescription(content, course));
        }
      },
      [section, part, course, courseAdd, dispatch]
    ),
    500
  );

  const onStateChange = useCallback(
    state => {
      onEditorStateChange(state);
      setReduxData(state);
    },
    [setReduxData, onEditorStateChange]
  );

  return (
    <div>
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper"
        editorClassName="editor"
        onEditorStateChange={onStateChange}
        toolbar={{
          options: ['inline'],
          inline: {
            options: ['bold', 'italic', 'underline']
          }
        }}
        editorStyle={{
          border: `1px solid ${colors.grey[400]}`,
          padding: '16px',
          overflowY: 'scroll',
          height: '380px',
          ...editorstyle
        }}
        wrapperStyle={{ overflowY: 'hidden' }}
      />
      <FormHelperText
        style={{
          color: wordCount <= 0 || wordCount > descriptionMaxLength ? 'red' : ''
        }}
      >
        Word count: {wordCount} / 10.000
      </FormHelperText>
      {(wordCount < 10 || wordCount > descriptionMaxLength) && (
        <FormHelperText
          style={{
            color:
              wordCount < 10 || wordCount > descriptionMaxLength ? 'red' : ''
          }}
        >
          {descriptionFor} description must be between 10 and{' '}
          {descriptionMaxLength} words
        </FormHelperText>
      )}
    </div>
  );
};

DescriptionEditor.propTypes = {
  descriptionFor: PropTypes.string,
  section: PropTypes.object,
  part: PropTypes.object,
  course: PropTypes.object,
  courseAdd: PropTypes.bool
};

DescriptionEditor.defaultProps = {
  descriptionFor: 'Course',
  section: {},
  part: {},
  course: {},
  courseAdd: false
};

export default DescriptionEditor;
