import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectAptitudeCompletion } from '../../../../redux/selectors/transcriptAptitudeSelectors';

const ChipSC = styled(Chip)`
  && {
    background: ${props => {
      switch (props.status) {
        case 'Empty': {
          return props.theme.colors.grey[500];
        }
        case 'Incomplete': {
          return props.theme.colors.status.warning;
        }
        case 'Complete': {
          return props.theme.colors.status.success;
        }
        default:
          return props.theme.colors.grey[500];
      }
    }};
    color: ${props => props.theme.colors.white};
  }
`;

export default function AptitudeCompletion({
  programUuid,
  userUuid,
  cohortUuid,
  category
}) {
  const status = useSelector(state =>
    selectAptitudeCompletion(state, programUuid, userUuid, cohortUuid, category)
  );

  return (
    <>
      {status && (
        <ChipSC
          status={status}
          variant="outlined"
          size="small"
          label={`${status}`}
        />
      )}
    </>
  );
}

AptitudeCompletion.propTypes = {
  category: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string
};

AptitudeCompletion.defaultProps = {
  category: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined
};
