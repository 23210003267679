import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import _ from 'lodash';
import EducationLevelAdd from './EducationLevelAdd';

import {
  validateEducationLevelTextField,
  validateLoverbsAdd
} from '../../../../../helpers/validation/validateEducationLevelLoverb';

import { doPostEducationLevel } from '../../../../../redux/actions/educationLevelActions';
import { doGetLoverbs } from '../../../../../redux/actions/loverbActions';
import { loverbsSelectorBySchool } from '../../../../../redux/selectors/loverbSelectors';

class EducationLevelAddContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formInput: {
        level: '',
        education: '',
        loverbs: []
      },
      hasErrors: false,
      submit: false
    };

    this.handleFormInputChange = this.handleFormInputChange.bind(this);
    this.handleAddLoverb = this.handleAddLoverb.bind(this);
    this.handleLoverbChange = this.handleLoverbChange.bind(this);
    this.handleRemoveLoverb = this.handleRemoveLoverb.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this._validationCheck = this._validationCheck.bind(this);
  }

  componentDidMount() {
    const { schoolUuid, onGetLoverbs } = this.props;

    if (schoolUuid) {
      onGetLoverbs(schoolUuid);
    }
  }

  handleDateChange(indentifier, field, value) {
    this.setState(prevState => ({
      formInput: {
        ...prevState.formInput,
        [field]: value
      }
    }));
  }

  handleFormInputChange(event) {
    const { name, value } = event.target;

    this.setState(prevState => ({
      formInput: {
        ...prevState.formInput,
        [name]: value
      }
    }));
  }

  handleAddLoverb() {
    this.setState(prevState => {
      const addLoverb = {
        indentifier: uuidv4().toString(),
        period: '',
        level: '',
        education: '',
        objectiveVerb: ''
      };

      const mergeState = {
        formInput: {
          ...prevState.formInput,
          loverbs: [...prevState.formInput.loverbs, addLoverb]
        }
      };

      return mergeState;
    });
  }

  handleLoverbChange(indentifier, event) {
    const { name, value } = event.target;
    let update;

    if (name === 'objectiveVerb') {
      update = {
        objectiveVerb: value
      };
    }

    this.setState(prevState => {
      const currentState = prevState.formInput.loverbs;

      const newState = currentState.map(loverb => {
        if (loverb.indentifier === indentifier) {
          const clone = _.cloneDeep(loverb);
          const merge = _.merge(clone, update);

          return merge;
        } else {
          return loverb;
        }
      });

      const mergeState = {
        formInput: {
          ...prevState.formInput,
          loverbs: newState
        }
      };

      return mergeState;
    });
  }

  handleRemoveLoverb(indentifier) {
    this.setState(prevState => {
      const currentState = prevState.formInput.loverbs;

      const newState = _.filter(
        currentState,
        record => record.indentifier !== indentifier
      );

      const mergeState = {
        formInput: {
          ...prevState.formInput,
          loverbs: newState
        }
      };

      return mergeState;
    });
  }

  handleSubmit() {
    const { schoolUuid } = this.props;
    const formIsValid = this._validationCheck();

    if (formIsValid) {
      this.setState({
        submit: true,
        hasErrors: false
      });

      const payload = { ...this.state.formInput, schoolUuid };

      this.props.onPostEducationLevel(payload);
    } else {
      this.setState({
        submit: false,
        hasErrors: true
      });
    }
  }

  _validationCheck() {
    const { level, education, loverbs } = this.state.formInput;
    const { loverbsRedux } = this.props;

    const levelError = validateEducationLevelTextField(level);
    const educationError = validateEducationLevelTextField(education);
    const loverbsObjectiveVerbsError = validateLoverbsAdd(
      loverbs,
      loverbsRedux
    );

    const validations = {
      levelInvalid: levelError,
      educationInvalid: educationError,
      loverbsInvalid: loverbsObjectiveVerbsError
    };

    const isValid = !_.some(validations, { invalid: true });

    return isValid;
  }

  render() {
    const { educationLevel, loverbsRedux } = this.props;
    const { level, education, loverbs } = this.state.formInput;
    const { hasErrors, submit } = this.state;

    return (
      <EducationLevelAdd
        level={level}
        education={education}
        loverbs={loverbs}
        loverbsRedux={loverbsRedux}
        educationLevel={educationLevel}
        hasErrors={hasErrors}
        submit={submit}
        handleFormInputChange={this.handleFormInputChange}
        handleAddLoverb={this.handleAddLoverb}
        handleLoverbChange={(indentifier, event) =>
          this.handleLoverbChange(indentifier, event)
        }
        handleRemoveLoverb={this.handleRemoveLoverb}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

EducationLevelAddContainer.propTypes = {
  educationLevel: PropTypes.object,
  schoolUuid: PropTypes.string,
  onPostEducationLevel: PropTypes.func,
  loverbsRedux: PropTypes.array,
  onGetLoverbs: PropTypes.func
};
EducationLevelAddContainer.defaultProps = {
  educationLevel: {},
  schoolUuid: '',
  onPostEducationLevel: undefined,
  onGetLoverbs: undefined,
  loverbsRedux: []
};

const mapStateToProps = state => {
  return {
    schoolUuid: state.userState.selectedSchoolUuid,
    loverbsRedux: loverbsSelectorBySchool(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onPostEducationLevel: payload => dispatch(doPostEducationLevel(payload)),
  onGetLoverbs: schoolUuid => dispatch(doGetLoverbs(schoolUuid))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EducationLevelAddContainer);
