import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: grid;
  grid-template-columns: 120px 120px;
  gap: 20px;
  font-size: 12px;
  color: ${props => (props.whiteText ? 'white' : 'black')};
  font-weight: bold;
  align-items: center;
  justify-items: center;
`;

const ColumnOneContainer = styled.div`
  display: grid;
  grid-template-rows: ${props =>
    props.displayHeader ? 'repeat(2, 40px)' : 'repeat(1, 1fr)'};
  align-items: center;
  justify-items: center;
  color: ${props => (props.whiteText ? 'white' : 'black')};
`;

export const DidacticColumnOneHeader = ({ whiteText }) => {
  return (
    <Container whiteText={whiteText}>
      <div>Score</div>
      <div>Grade</div>
    </Container>
  );
};

const DidacticColumnOne = ({ data, displayHeader, whiteText }) => {
  if (!data) {
    return null;
  }

  return (
    <ColumnOneContainer displayHeader={displayHeader}>
      {displayHeader && <DidacticColumnOneHeader whiteText={whiteText} />}
      <Container whiteText={whiteText}>
        <div>{data.score}</div>
        <div>{data.grade}%</div>
      </Container>
    </ColumnOneContainer>
  );
};

DidacticColumnOne.propTypes = {
  data: PropTypes.object.isRequired,
  displayHeader: PropTypes.bool,
  whiteText: PropTypes.bool
};

DidacticColumnOne.defaultProps = {
  displayHeader: false,
  whiteText: false
};

DidacticColumnOneHeader.propTypes = {
  whiteText: PropTypes.bool
};
DidacticColumnOneHeader.defaultProps = {
  whiteText: false
};

export default DidacticColumnOne;
