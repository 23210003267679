import {
  GET_PART_USER_GROUPS,
  GET_PART_USER_GROUPS_SUCCESS,
  GET_PART_USER_GROUPS_ERROR,
  GET_STUDENT_PART_USER_GROUPS_SUCCESS,
  GET_STUDENT_PART_USER_GROUPS_ERROR,
  GET_STUDENT_PART_USER_GROUPS,
  POST_USER_GROUPS,
  POST_USER_GROUPS_SUCCESS,
  POST_USER_GROUPS_ERROR,
  DELETE_USER_GROUP,
  DELETE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_ERROR
} from '../actions-type';

const doGetPartUserGroups = partUuid => ({
  type: GET_PART_USER_GROUPS,
  payload: {
    partUuid
  }
});

const doGetPartUserGroupsSuccess = groups => ({
  type: GET_PART_USER_GROUPS_SUCCESS,
  payload: groups
});

const doGetPartUserGroupsError = error => ({
  type: GET_PART_USER_GROUPS_ERROR,
  payload: error
});

const doGetStudentPartUserGroups = (partUuid, studentUuid) => ({
  type: GET_STUDENT_PART_USER_GROUPS,
  payload: {
    partUuid,
    studentUuid
  }
});

const doGetStudentPartUserGroupsSuccess = groups => ({
  type: GET_STUDENT_PART_USER_GROUPS_SUCCESS,
  payload: groups
});

const doGetStudentPartUserGroupsError = error => ({
  type: GET_STUDENT_PART_USER_GROUPS_ERROR,
  payload: error
});

const doPostUserGroups = userGroups => ({
  type: POST_USER_GROUPS,
  payload: {
    userGroups
  }
});

const doPostUserGroupsSuccess = userGroups => ({
  type: POST_USER_GROUPS_SUCCESS,
  payload: userGroups
});

const doPostUserGroupsError = error => ({
  type: POST_USER_GROUPS_ERROR,
  payload: error
});

const doDeleteUserGroup = (userUuid, groupUuid) => ({
  type: DELETE_USER_GROUP,
  payload: {
    userUuid,
    groupUuid
  }
});

const doDeleteUserGroupSuccess = ({ userUuid, groupUuid }) => ({
  type: DELETE_USER_GROUP_SUCCESS,
  payload: { userUuid, groupUuid }
});

const doDeleteUserGroupError = error => ({
  type: DELETE_USER_GROUP_ERROR,
  payload: error
});

export {
  doGetPartUserGroups,
  doGetPartUserGroupsSuccess,
  doGetPartUserGroupsError,
  doGetStudentPartUserGroups,
  doGetStudentPartUserGroupsError,
  doGetStudentPartUserGroupsSuccess,
  doPostUserGroups,
  doPostUserGroupsSuccess,
  doPostUserGroupsError,
  doDeleteUserGroup,
  doDeleteUserGroupSuccess,
  doDeleteUserGroupError
};
