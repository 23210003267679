import fetch from '../helpers/fetch';

const getUserCohortByUserAndCohort = user =>
  fetch(`/api/user-cohort/user/${user.userUuid}/cohort/${user.cohortUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getUserCohorts = (cohortUuid, status, role) => {
  let urlParams;
  if (status && role) {
    urlParams = new URLSearchParams({
      status,
      role
    });
  }

  return fetch(
    `/api/user-cohorts/cohort/${cohortUuid}${
      urlParams ? `?${urlParams.toString()}` : ''
    }`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

const getCohortApplicantsByStatus = data =>
  fetch(
    `/api/user-cohorts/cohort/${data.cohortUuid}/applicants?status=${data.status}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getSchoolStudentByStatus = data =>
  fetch(
    `/api/user-cohorts/school/${data.schoolUuid}/user/${data.institutionId}?status=${data.status}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postUserCohort = userRole =>
  fetch(`/api/user-cohort/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(userRole)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postUserCohorts = roles =>
  fetch(`/api/user-cohorts/roles`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(roles)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchUserCohortsByStatus = users =>
  fetch(`/api/user-cohorts/status`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(users)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteUserCohorts = roles =>
  fetch(`/api/user-cohorts/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(roles)
  })
    .then(res => res)
    .catch(error => error);

const postUploadApplicants = applicants =>
  fetch(`/api/user-cohorts/applicants`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(applicants)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postStudentConversion = applicants =>
  fetch(`/api/user-cohorts/student-conversion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(applicants)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteUserCohort = (uuid, userUuid) =>
  fetch(`/api/user-cohort/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ userUuid })
  })
    .then(res => res)
    .catch(error => error);

const getApplicantsByProgram = (useruuid, programUuid) =>
  fetch(
    `/api/user-cohorts/applicants?userUuid=${useruuid}&programUuid=${programUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  deleteUserCohort,
  deleteUserCohorts,
  getApplicantsByProgram,
  getCohortApplicantsByStatus,
  getSchoolStudentByStatus,
  getUserCohortByUserAndCohort,
  getUserCohorts,
  patchUserCohortsByStatus,
  postStudentConversion,
  postUploadApplicants,
  postUserCohort,
  postUserCohorts
};
