import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DescriptionDisplay from '../DescriptionEditor/DescriptionDisplay';

const ScrollDialog = ({ open, handleClose, title, contentText }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose(!open)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            <DescriptionDisplay description={contentText} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(!open)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ScrollDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  contentText: PropTypes.string
};

ScrollDialog.defaultProps = {
  open: false,
  handleClose: () => {},
  title: '',
  contentText: ''
};

export default ScrollDialog;
