import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const selectCredentials = createSelector(
  state => state,
  (_, userUuid) => userUuid,
  (redux, userUuid) => {
    const getCredentials = ormCreateSelector(orm.Credential);

    const credentials = getCredentials(redux).filter(
      c => c.globalUserUuid === userUuid
    );

    return credentials;
  }
);

export const selectCredential = ormCreateSelector(orm.Credential);
