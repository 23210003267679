import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import {
  ContainerResize,
  ContainerPage,
  ContainerActions,
  ElevateLow
} from '../Library/Layout';
import Search from '../Library/Search';
import Header from '../Library/Header';
import CohortRow from './CohortRow';
import { roles } from '../../helpers/constants';

const SearchRow = styled.div`    
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid
    rgba(224, 224, 224, 1);
`;

const SearchBox = styled.div`
  width: 400px;
`;

export default function CohortManagement({ cohorts, handleSearchTerm }) {
  const selectedRole = useSelector(state => state.userState.selectedRole);
  const isAdmin = selectedRole === roles.programAdmin;

  return (
    <ContainerResize>
      <Header
        title="Cohort Management"
        subtitle="Manage cohorts, add, edit, and remove"
        backOn={false}
      />
      {isAdmin 
        ?
          <ContainerActions paddingTop="100px">
            <Link to="/program-management/cohort/add">
              <ButtonPrimary>Add Cohort</ButtonPrimary>
            </Link>
          </ContainerActions>
        :
          <div style={{paddingTop: 50}} />
      }
      <ContainerPage paddingTop="60px">
        <ElevateLow>
          <SearchRow>
            <SearchBox>
              <Search onChange={handleSearchTerm} />
            </SearchBox>
          </SearchRow>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cohort Name</TableCell>
                <TableCell>Cohort</TableCell>
                <TableCell>Applicants</TableCell>
                <TableCell>Students</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {cohorts.map(cohort => (
                <CohortRow key={cohort.uuid} cohort={cohort} />
              ))}
            </TableBody>
          </Table>
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
}

CohortManagement.propTypes = {
  cohorts: PropTypes.array,
  handleSearchTerm: PropTypes.func
};

CohortManagement.defaultProps = {
  cohorts: [],
  handleSearchTerm: undefined
};
