export const doSetSectionDescription = (description, section) => ({
  type: 'SECTION_DESCRIPTION',
  payload: {
    description,
    section
  }
});

export const doSetPartDescription = (description, part) => ({
  type: 'PART_DESCRIPTION',
  payload: {
    description,
    part
  }
});

export const doSetCourseDescription = (description, course) => ({
  type: 'COURSE_DESCRIPTION',
  payload: {
    description,
    course
  }
});
