const headersScores = [
  {
    field: 'studentID',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'date',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'blockNumber',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'blockDescription',
    type: 'STRING',
    allowEmpty: false,
    note: 'For single block Assessments, set blockDescription to: Single Block'
  },
  {
    field: 'blockStatus',
    type: 'STRING',
    allowEmpty: false,
    note:
      'blockStatus must be one of the following: pass, fail, critical, disqualified, or pending. For Opportunity-based Assessments, set blockStatus to pass. For Didactic Assessments, set blockStatus to pending.'
  },
  {
    field: 'attempt',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'tieBackID',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'grader1ID',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'grader2ID',
    type: 'STRING',
    allowEmpty: true
  },
  {
    field: 'grader3ID',
    type: 'STRING',
    allowEmpty: true
  },
  {
    field: 'rubricItem',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'sequenceOrder',
    type: 'NUMBER INTEGER',
    allowEmpty: true,
    note: 'For attempt based assessments, you must insert the number of the sequence',
  },
  {
    field: 'rubricOrder',
    type: 'NUMBER INTEGER',
    allowEmpty: true,
    note: 'For attempt based assessments, you must insert the number of the rubric in a SEQUENCE',
  },
  {
    field: 'procedureCodeOrder',
    type: 'NUMBER INTEGER',
    allowEmpty: true,
    note: 'For opportunity based assessments, you must insert the number of the procedure.',
  },
  {
    field: 'procedureCodeDescriptor',
    type: 'STRING',
    allowEmpty: true,
    note: 'For opportunity based assessments, you must insert the descriptor for the procedure.',
  },
  {
    field: 'stepSubItem',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'stepSubItemDescription',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'microcompetencyCode',
    type: 'STRING',
    allowEmpty: false,
    note:
      'Serial can only contain numbers with specific interval ranges ie. 00.0000.0000.0000.0000'
  },
  {
    field: 'relValue',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'potValue',
    type: 'STRING',
    allowEmpty: false
  }
];

module.exports = {
  headersScores
};
