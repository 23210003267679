import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AssessmentHeaderRow from './AssessmentHeaderRow';
import AssessmentStudentRow from './AssessmentStudentRow';
import { DataCellValueTypes } from '../../reportUtils';

const Table = styled.table`
  width: fit-content;
  table-layout: fixed;
  border-spacing: 0;

  & tr:nth-child(even) > td,
  tr:nth-child(4) > th {
    box-shadow: inset 0 0 100px 200px rgba(0, 0, 0, 0.05);
  }

  & th,
  td {
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
    border-right: 1px solid ${props => props.theme.colors.grey[300]};
  }

  & > thead > tr:first-child > th {
    border-top: 1px solid ${props => props.theme.colors.grey[300]};
    border-left: 1px solid ${props => props.theme.colors.grey[300]};
    border-right: none;

    &:nth-child(2) {
      border-right: 2px solid ${props => props.theme.colors.grey[500]};
    }

    &:nth-child(3) {
      border-left: 0;
    }

    &:nth-child(5) {
      border-right: ${props =>
        !props.showDaysOfCompletion &&
        `2px solid ${props.theme.colors.grey[500]}`};
    }

    &:nth-child(6) {
      border-right: ${props =>
        props.showDaysOfCompletion &&
        `2px solid ${props.theme.colors.grey[500]}`};
    }

    &:nth-child(7) {
      border-left: 0;
    }
  }

  & th:first-child,
  td:first-child {
    border-left: 1px solid ${props => props.theme.colors.grey[300]};
  }

  & th,
  td {
    &:nth-child(1) {
      position: sticky;
      left: 0;
      z-index: 99;
    }

    &:nth-child(2) {
      position: sticky;
      left: 25px;
      z-index: 99;
      border-right: 2px solid ${props => props.theme.colors.grey[500]};
    }

    &:nth-child(3) {
      position: sticky;
      left: 275px;
      z-index: 99;
    }

    &:nth-child(4) {
      position: sticky;
      left: ${props => (!props.showDaysOfCompletion ? 305 : 315)}px;
      z-index: 99;
    }

    &:nth-child(5) {
      position: sticky;
      left: ${props => (!props.showDaysOfCompletion ? 375 : 345)}px;
      z-index: 99;
      border-right: ${props =>
        !props.showDaysOfCompletion &&
        `2px solid ${props.theme.colors.grey[500]}`};
    }

    &:nth-child(6) {
      ${props => props.showDaysOfCompletion && `position: sticky`};
      ${props => props.showDaysOfCompletion && `left: 415px`};
      ${props => props.showDaysOfCompletion && `z-index: 99`};
      border-right: ${props =>
        props.showDaysOfCompletion &&
        `2px solid ${props.theme.colors.grey[500]}`};
    }
  }
`;

const TitleCell = styled.th`
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding-left: 5px;
  background: ${props => props.theme.colors.grey[300]};
  ${props =>
    props.isEmpty &&
    `
  border-right: 1px solid ${props.theme.colors.grey[300]} !important;
  position: unset !important;
  `};
`;

const AssessmentTable = ({
  noOfBlocks,
  assessments,
  students,
  isComputedDataStatic,
  isAnonymized,
  dataCellValueType,
  reportType,
  cohortStartDate,
  isExpanded,
  isDidactic,
  summaryRows,
  filteredStudentUuids,
  filteredAssessmentUuids,
  totalFirstAttemptPassRate,
  showDaysOfCompletion
}) => {
  const filteredStudentsForTable = useMemo(() => {
    return students.filter(s => filteredStudentUuids.includes(s.userUuid));
  }, [filteredStudentUuids, students]);

  const filteredAssessmentsForTable = useMemo(() => {
    return assessments.filter(a => filteredAssessmentUuids.includes(a.uuid));
  }, [filteredAssessmentUuids, assessments]);

  return (
    <Table showDaysOfCompletion={showDaysOfCompletion}>
      <AssessmentHeaderRow
        assessments={filteredAssessmentsForTable}
        isExpanded={isExpanded}
        isDidactic={isDidactic}
        isCompact={
          isExpanded &&
          (dataCellValueType === DataCellValueTypes.attempt ||
            dataCellValueType === DataCellValueTypes.score)
        }
        cohortStartDate={cohortStartDate}
        summaryRows={summaryRows}
        totalFirstAttemptPassRate={totalFirstAttemptPassRate}
        noOfBlocks={noOfBlocks}
        showDaysOfCompletion={showDaysOfCompletion}
      />
      <tbody>
        {filteredStudentsForTable
          .filter(s => !s.inactive)
          .map((student, index) => {
            return (
              <Fragment key={student.userUuid}>
                <AssessmentStudentRow
                  student={student}
                  index={index}
                  isAnonymized={isAnonymized}
                  isDidactic={isDidactic}
                  assessments={filteredAssessmentsForTable}
                  noOfBlocks={noOfBlocks}
                  isExpanded={isExpanded}
                  isComputedDataStatic={isComputedDataStatic}
                  dataCellValueType={dataCellValueType}
                  reportType={reportType}
                  cohortStartDate={cohortStartDate}
                  showDaysOfCompletion={showDaysOfCompletion}
                />
              </Fragment>
            );
          })}
        {filteredStudentsForTable.some(s => s.inactive) && (
          <tr>
            <TitleCell colSpan={6}>Inactive Students</TitleCell>
            <TitleCell isEmpty colSpan={filteredAssessmentsForTable.length} />
          </tr>
        )}
        {filteredStudentsForTable
          .filter(s => s.inactive)
          .map((student, index) => {
            return (
              <Fragment key={student.userUuid}>
                <AssessmentStudentRow
                  student={student}
                  index={index}
                  isAnonymized={isAnonymized}
                  assessments={filteredAssessmentsForTable}
                  noOfBlocks={noOfBlocks}
                  isExpanded={isExpanded}
                  isComputedDataStatic={isComputedDataStatic}
                  dataCellValueType={dataCellValueType}
                  reportType={reportType}
                  cohortStartDate={cohortStartDate}
                  showDaysOfCompletion={showDaysOfCompletion}
                />
              </Fragment>
            );
          })}
      </tbody>
    </Table>
  );
};

AssessmentTable.propTypes = {
  noOfBlocks: PropTypes.number.isRequired,
  assessments: PropTypes.array,
  students: PropTypes.array,
  isAnonymized: PropTypes.bool.isRequired,
  dataCellValueType: PropTypes.string.isRequired,
  reportType: PropTypes.string,
  isComputedDataStatic: PropTypes.bool,
  cohortStartDate: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  summaryRows: PropTypes.object.isRequired,
  filteredStudentUuids: PropTypes.array.isRequired,
  filteredAssessmentUuids: PropTypes.array.isRequired,
  totalFirstAttemptPassRate: PropTypes.number.isRequired,
  showDaysOfCompletion: PropTypes.bool,
  isDidactic: PropTypes.bool,
};
AssessmentTable.defaultProps = {
  assessments: [],
  students: [],
  cohortStartDate: '',
  reportType: 'pass',
  isComputedDataStatic: false,
  isDidactic: false,
  showDaysOfCompletion: true
};

export default AssessmentTable;
