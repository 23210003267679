import React, { useReducer, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { doGetProgramCohorts } from '../../../../redux/actions/cohortActions';
import { doPostCohortAssessmentCollection } from '../../../../redux/actions/cohortAssessmentActions';
import { doClearCreated } from '../../../../redux/actions/courseCollectionActions';
import { cohortSelectorByMatchParams } from '../../../../redux/selectors/cohortsSelectors';
import {
  allFieldsAreValid,
  validateStringLength
} from '../../../../helpers/validation/validateGeneric';
import NewCohortAssessmentCollectionDashboard from './NewCohortAssessmentCollectionDashboard';
import {
  initialCohortAssessmentCollectionState,
  cohortAssessmentCollectionReducer,
  setHaveFetchedCohorts,
  setShowValidationErrors,
  setTitle
} from '../cohortAssessmentCollectionState';

const NewCohortAssessmentCollectionDashboardContainer = () => {
  // eslint-disable-next-line prefer-const
  let history = useHistory();
  const dispatch = useDispatch();
  const { cohortUuid } = useParams();

  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  const successfullyCreated = useSelector(
    state => state.formSuccessState.successfullyUpdatedProgramAssessments
  );

  const parentCohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    shallowEqual
  );

  const [state, newDispatch] = useReducer(
    cohortAssessmentCollectionReducer,
    initialCohortAssessmentCollectionState
  );

  const { title, haveFetchedCohorts, showValidationErrors } = state;

  const clearForm = useCallback(() => {
    history.push(`/program-management/cohort/${cohortUuid}/requirements`);
  }, [cohortUuid, history]);

  const onPostCreate = newCollection =>
    dispatch(doPostCohortAssessmentCollection(newCollection));

  useEffect(() => {
    window.scrollTo(0, 0);
    newDispatch({
      type: 'clearForm'
    });
  }, []);

  useEffect(() => {
    const onClearCreated = () => dispatch(doClearCreated());

    if (successfullyCreated === true) {
      onClearCreated();
      clearForm();
    }
  }, [successfullyCreated, dispatch, clearForm]);

  useEffect(() => {
    if (!haveFetchedCohorts && !parentCohort) {
      dispatch(doGetProgramCohorts(selectedProgramUuid));
      newDispatch(setHaveFetchedCohorts());
    }
  }, [dispatch, selectedProgramUuid, haveFetchedCohorts, parentCohort]);

  const setValidationErrors = newCollection => {
    const titleError = validateStringLength(newCollection.title, 100, true);

    const newValidationErrors = {
      titleError
    };

    return newValidationErrors;
  };

  const buildProcedureCodePayload = (title, cohortUuid) => ({
    title,
    cohortUuid
  });

  const handleCreateSubmission = () => {
    const newValidationErrors = setValidationErrors({
      title
    });

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = buildProcedureCodePayload(title, cohortUuid);

      onPostCreate(payload);
      newDispatch(setShowValidationErrors(false));
    } else {
      newDispatch(setShowValidationErrors(true));
    }
  };

  const newCollection = {
    title,
    cohortUuid
  };

  const handleTitleChange = e => newDispatch(setTitle(e.target.value));

  return (
    <NewCohortAssessmentCollectionDashboard
      newCollection={newCollection}
      formDispatch={newDispatch}
      parentCohort={parentCohort}
      handleTitleChange={handleTitleChange}
      onSubmit={handleCreateSubmission}
      clearForm={clearForm}
      showValidationErrors={showValidationErrors}
    />
  );
};

export default NewCohortAssessmentCollectionDashboardContainer;
