import React from 'react';
import PropTypes from 'prop-types';
import ProgramDefinitions from './ProgramDefinitions';

export default function ProgramDefinitionsContainer({ match, history }) {
  const { path } = match;
  const { pathname } = history.location;

  return <ProgramDefinitions path={path} pathname={pathname} />;
}

ProgramDefinitionsContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

ProgramDefinitionsContainer.defaultProps = {
  history: {},
  match: {}
};
