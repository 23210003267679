import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Heading3, ButtonInline } from '@xcomp/xcomp-design-library';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Radio, RadioGroup, FormControl } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import SimpleModal from '../../../Library/Modal/SimpleModal';

const FormControlSC = styled(FormControl)`
  width: 100%;
`;

const RadioGroupSC = styled(RadioGroup)`
  margin: 12px 0 32px;
`;

const ModalContent = styled.div`
  width: 100%;
  padding: 1rem 2rem 1rem 1.5rem;
  min-width: 528px;
`;

const ModalHeader = styled.div`
  width: 100%;
  h3 {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;

const ModalBody = styled.div`
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
`;

const RowSC = styled.div`
  && {
    display: flex;
    justify-content: flex-start;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 12px 0px 12px 24px;
`;

const FormControlLabelSC = styled(FormControlLabel)`
  && {
    margin: 0;
  }
`;

const ModalActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.5rem;
`;

const AssignGroupLeaderModal = ({
  isModalOpen,
  groupLeaderSelection,
  students,
  onAssignGroupLeader,
  onSelectGroupLeader,
  onModalClose
}) => {
  return (
    <SimpleModal
      open={Boolean(isModalOpen)}
      onClose={onModalClose}
      maxWidth="md"
    >
      <ModalContent>
        <ModalHeader>
          <Heading3>Assign Group Leader</Heading3>
        </ModalHeader>
        <ModalBody>
          <FormControlSC component="fieldset" onChange={onSelectGroupLeader}>
            <RadioGroupSC
              aria-label="position"
              name="position"
              value={groupLeaderSelection}
            >
              {students.map(student => (
                <RowSC key={uuidv4()}>
                  <FormControlLabelSC
                    value={student.user.uuid}
                    control={<Radio color="primary" />}
                  />
                  <Label>{student.user.institutionId}</Label>
                  <Label>{`${student.user.lastName}, ${student.user.firstName}`}</Label>
                </RowSC>
              ))}
            </RadioGroupSC>
          </FormControlSC>
        </ModalBody>
        <ModalActions>
          <ButtonInline onClick={onModalClose}>CANCEL</ButtonInline>
          <ButtonInline color="secondary" onClick={onAssignGroupLeader}>
            SAVE
          </ButtonInline>
        </ModalActions>
      </ModalContent>
    </SimpleModal>
  );
};

AssignGroupLeaderModal.propTypes = {
  isModalOpen: PropTypes.bool,
  students: PropTypes.array,
  groupLeaderSelection: PropTypes.string,
  onAssignGroupLeader: PropTypes.func,
  onSelectGroupLeader: PropTypes.func,
  onModalClose: PropTypes.func
};
AssignGroupLeaderModal.defaultProps = {
  isModalOpen: false,
  students: [],
  groupLeaderSelection: '',
  onAssignGroupLeader: undefined,
  onSelectGroupLeader: undefined,
  onModalClose: undefined
};

export default AssignGroupLeaderModal;
