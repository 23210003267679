import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import StudentAppId from './StudentAppId';
import { selectStudentEmails } from '../../../../redux/selectors/usersSelectors';
import { doPostGenerateAppId } from '../../../../redux/actions/userActions';
import { validateSelectField } from '../../../../helpers/validation/validateGeneric';

export default function StudentAppIdContainer({
  studentAppIdUser,
  openStudentAppId,
  handleStudentAppIdClose
}) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(undefined);
  const [hasErrors, setHasErrors] = useState(false);

  const createAppId = useSelector(state => state.crudState.createAppId);

  const createAppIdResult = useSelector(
    state => state.crudState.createAppIdResult
  );

  const userEmails = useSelector(state => {
    if (studentAppIdUser) {
      const roles = selectStudentEmails(state, studentAppIdUser);

      return [...roles];
    } else {
      return [];
    }
  });

  const optionsEmails =
    userEmails?.map(email => ({
      value: email,
      label: `${email}`
    })) || [];

  useEffect(() => {
    if (createAppIdResult === 'success') {
      handleStudentAppIdClose();
      dispatch({ type: 'POST_USER_APP_ID_RESET' });
    }
  }, [dispatch, createAppIdResult, handleStudentAppIdClose]);

  const handleEmailSelection = event => {
    const { value } = event.target;
    setEmail(value);
  };

  const validation = () => {
    let containErrors = false;

    const emailError = validateSelectField(email);

    if (emailError.invalid) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleGenerateAppId = () => {
    const reject = validation();
    if (reject) {
      setHasErrors(true);
    } else {
      dispatch(doPostGenerateAppId('appIdStudent', studentAppIdUser, email));
      setHasErrors(false);
    }
  };

  return (
    <StudentAppId
      hasErrors={hasErrors}
      createAppId={createAppId}
      email={email}
      optionsEmails={optionsEmails}
      openStudentAppId={openStudentAppId}
      handleStudentAppIdClose={handleStudentAppIdClose}
      handleEmailSelection={handleEmailSelection}
      handleGenerateAppId={handleGenerateAppId}
    />
  );
}

StudentAppIdContainer.propTypes = {
  studentAppIdUser: PropTypes.string,
  openStudentAppId: PropTypes.bool,
  handleStudentAppIdClose: PropTypes.func
};

StudentAppIdContainer.defaultProps = {
  studentAppIdUser: undefined,
  openStudentAppId: false,
  handleStudentAppIdClose: undefined
};
