import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import { InfoIcon } from '@xcomp/xcomp-design-library/dist/icons';

import { fetcher } from '../../helpers/utilities';
import AutocompleteDropdownFilter from './AutocompleteDropdownFilter';
import { validateSelectDate } from '../../helpers/validation/validateGeneric';
import DateTime from '../Library/DateTime';

const Header = styled.div`
  font-size: 26px;
  display: flex;
  align-items: center;
`

const FiltersContainer = styled.div`
  padding: 5px 20px;
`

const DateTimeSC = styled(DateTime)`
  width: 49%;

  & input {
    height: 56px;
  }
`;

const Filters = ({ onSubmit, onOpenInfo }) => {
  const [filteredAuthors, setFilteredAuthors] = useState([]);
  const [filteredActions, setFilteredActions] = useState([]);
  const [filteredMethods, setFilteredMethods] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { data, error, isLoading } = useSWR(
    `/api/logs-filters`,
    fetcher,
    {
      revalidateOnFocus: false
    }
  );

  useEffect(() => {
    if (data) {
      setFilteredAuthors(data?.authors || []);
      setFilteredActions(data?.actions || []);
      setFilteredMethods(data?.methods || []);
    }
  }, [data])

  return (
    <FiltersContainer>
      <Header>
        <span style={{marginRight: 10}}>Filters</span>
        <InfoIcon size={18} onClick={() => onOpenInfo(true)} />
        {isLoading && <CircularProgress size={18} />}
        {error && <span style={{fontSize: 14, color: 'red'}}>Loading the filter values failed. Please refresh the page and try again.</span>}
      </Header>
      <AutocompleteDropdownFilter
        setFilteredValues={setFilteredAuthors}
        filteredValues={filteredAuthors}
        options={data?.authors || []}
        label="Authors"
      />
      <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
        <AutocompleteDropdownFilter
          setFilteredValues={setFilteredActions}
          filteredValues={filteredActions}
          options={data?.actions || []}
          label="Actions"
          style={{width: '49%'}}
        />
        <AutocompleteDropdownFilter
          setFilteredValues={setFilteredMethods}
          filteredValues={filteredMethods}
          options={data?.methods || []}
          label="Methods"
          style={{width: '49%'}}
        />
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
        <DateTimeSC
          dataTestId="assessment-start-date"
          name="startDate"
          label="Start Date"
          placeholderText="Choose a start date"
          value={startDate}
          indentifier="startDate"
          handleChange={(name, date) => setStartDate(date)}
          hasErrors={false}
          handleValidate={validateSelectDate}
          isClearable
        />
        <DateTimeSC
          dataTestId="assessment-end-date"
          name="endDate"
          label="End Date"
          placeholderText="Choose an end date"
          value={endDate}
          indentifier="endDate"
          handleChange={(name, date) => setEndDate(date)}
          hasErrors={false}
          handleValidate={validateSelectDate}
          isClearable
        />
      </div>
      <ButtonPrimary
        disabled={filteredActions.length === 0 || filteredAuthors.length === 0 || filteredMethods.length === 0}
        onClick={() => 
          onSubmit({
            options: {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                authors: filteredAuthors.map(a => a.label),
                actions: filteredActions.map(a => a.label),
                methods: filteredMethods.map(m => m.label),
                startDate,
                endDate
              }),
              credentials: 'include'
            }
          },
          )
        } 
        style={{width: '100%', marginTop: 20}}
      >
        Apply
      </ButtonPrimary>
    </FiltersContainer>
  );
}

Filters.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onOpenInfo: PropTypes.func.isRequired
};

export default Filters;