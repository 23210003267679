import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Body2 } from '@xcomp/xcomp-design-library';
import DeleteModal from '../../Library/Modal/DeleteModal/DeleteModal';
import AccordionRowContainer from '../../Library/AccordionRow/AccordionRowContainer';
import ProgramAssessmentMasterContainer from '../ProgramAssessmentMaster/ProgramAssessmentMasterContainer';
import { PanelLabel } from '../../Library/DashboardSC';
import EditProgramAssessmentCollectionContainer from './EditProgramAssessmentCollection/EditProgramAssessmentCollectionContainer';
import { sortByProperty } from '../../../helpers/utilities';

const ProgramAssessmentCollectionAccordion = styled(
  ({ withBottomBorder, withTopBorder, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  width: 100%;

  .align_right {
    margin-left: auto;

    span {
      font-size: 12px;
      letter-spacing: 0.4;
      color: ${props => props.theme.fontColors.dark};
    }
  }
`;

const Layout = styled.div`
  width: 100%;
`;

const PanelLabelSC = styled(PanelLabel)`
  margin-bottom: 8px;
`;

const LabelRow = styled.div`
  width: 100%;
  display: flex;
`;

const NoProgramAssessmentsMessasge = styled(({ rowOffset, ...rest }) => (
  <div {...rest} />
))`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const ProgramAssessmentCollection = ({
  programAssessmentCollection,
  toggleEditView,
  linkAddProgramAssessment,
  isEditView,
  isDeleteModalOpen,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete
}) => {
  const programAssessmentCollectionActions = [
    {
      label: 'Edit',
      onClick: () => toggleEditView(true),
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Add Program Assessment',
      onClick: linkAddProgramAssessment,
      shouldOpenOnClick: false,
      icon: 'add'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  const programAssessmentMasters =
    programAssessmentCollection.programAssessmentMasters.sort((a, b) =>
      sortByProperty(a, b, 'title')
    );
  return (
    <Layout>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={handleDeleteModalClose}
        handleDelete={handleDelete}
        type="Program Assessment Collection"
      />
      {isEditView ? (
        <EditProgramAssessmentCollectionContainer
          key={programAssessmentCollection.uuid}
          programAssessmentCollectionUuid={programAssessmentCollection.uuid}
          toggleEditView={toggleEditView}
        />
      ) : (
        <ProgramAssessmentCollectionAccordion
          columnOne={programAssessmentCollection.title}
          actions={programAssessmentCollectionActions}
          columnGutters="1.5rem"
          isPublished={programAssessmentCollection.published}
          isTopLevel
          withBottomBorder
          expandWidth="66px"
        >
          {programAssessmentCollection.programAssessmentMasters?.length > 0 ? (
            <>
              <LabelRow>
                <PanelLabelSC marginLeft="88px">Type</PanelLabelSC>
                <PanelLabelSC marginLeft="140px">Technique</PanelLabelSC>
                <PanelLabelSC marginLeft="108px">Assessment Title</PanelLabelSC>
              </LabelRow>
              {programAssessmentMasters
                .sort((a, b) => sortByProperty(a, b, 'title'))
                .map(assessmentMaster => (
                  <ProgramAssessmentMasterContainer
                    key={assessmentMaster.uuid}
                    programAssessmentMasterUuid={assessmentMaster.uuid}
                    programAssessmentCollectionUuid={
                      programAssessmentCollection.uuid
                    }
                  />
                ))}
            </>
          ) : (
            <NoProgramAssessmentsMessasge rowOffset="72px">
              <Body2>No program assessments added</Body2>
            </NoProgramAssessmentsMessasge>
          )}
        </ProgramAssessmentCollectionAccordion>
      )}
    </Layout>
  );
};

ProgramAssessmentCollection.propTypes = {
  isEditView: PropTypes.bool,
  programAssessmentCollection: PropTypes.object.isRequired,
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  toggleEditView: PropTypes.func,
  linkAddProgramAssessment: PropTypes.func,
  handleDelete: PropTypes.func
};

ProgramAssessmentCollection.defaultProps = {
  isEditView: false,
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  toggleEditView: undefined,
  linkAddProgramAssessment: undefined,
  handleDelete: undefined
};

export default ProgramAssessmentCollection;
