import React from 'react';
import { useSelector } from 'react-redux';
import Encounter from './Encounter';
import { selectEncounter } from '../../../redux/selectors/encountersSelectors';

export default function EncounterContainer() {
  const selectedItem = useSelector(
    state => state.syllabusState.selectedItemUuid
  );

  const selectedOrder = useSelector(state => state.syllabusState.selectedOrder);

  const encounter = useSelector(state => selectEncounter(state, selectedItem));

  return <Encounter encounter={encounter} selectedOrder={selectedOrder} />;
}

EncounterContainer.propTypes = {};

EncounterContainer.defaultProps = {};
