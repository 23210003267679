import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getPartGroupSummary,
  getStudentPartGroupSummary
} from '../../api/partGroupSummaryApi';

import {
  doGetPartGroupSummarySuccess,
  doGetPartGroupSummaryError,
  doGetStudentPartGroupSummarySuccess,
  doGetStudentPartGroupSummaryError
} from '../actions/partGroupSummaryActions';

import {
  GET_PART_GROUP_SUMMARY,
  GET_STUDENT_PART_GROUP_SUMMARY
} from '../actions-type';

function* handleGetPartGroupSummary(action) {
  const { partUuid } = action.payload;
  try {
    const result = yield call(getPartGroupSummary, partUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartGroupSummarySuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetPartGroupSummaryError(error));
  }
}

function* handleGetStudentPartGroupSummary(action) {
  const { partUuid, studentUuid } = action.payload;
  try {
    const result = yield call(
      getStudentPartGroupSummary,
      partUuid,
      studentUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetStudentPartGroupSummarySuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetStudentPartGroupSummaryError(error));
  }
}

const partGroupSummary = [
  takeEvery(GET_PART_GROUP_SUMMARY, handleGetPartGroupSummary),
  takeEvery(GET_STUDENT_PART_GROUP_SUMMARY, handleGetStudentPartGroupSummary)
];

export { partGroupSummary };
