import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Caption, Body1 } from '@xcomp/xcomp-design-library';
import { useParams, useHistory } from 'react-router-dom';
import DebouncedTextField from '../../../Library/DebouncedTextField';
import {
  validateGradeCollectionTitle,
  validateGradeCollectionWeight
} from '../../../../helpers/validation/validatePartGradeSettings';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import withValidation from '../../../Library/FieldWithError/FieldWithError';
import CollectionAssessmentRow from './CollectionAssessmentRow';

const GradeCollectionFormAccordion = styled(
  ({ withBottomBorder, withTopBorder, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  width: 100%;

  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label div {
    font-size: 16px;
  }

  .collection_title,
  .collection_weight {
    position: relative;

    .MuiFormHelperText-root.Mui-error {
      position: absolute;
      bottom: -20px;
      left: -8px;
      min-width: 220px;
    }
  }

  .collection_title {
    padding-right: 16px;
    flex-grow: 2;
  }

  .collection_weight {
    svg {
      color: ${props => props.theme.colors.grey[600]};
      &:hover {
        color: ${props => props.theme.colors.grey[900]};
      }
    }
  }

  .accordion_row_label {
    min-width: 160px;
  }
`;

const GradeCollectionLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FormRow = styled(({ marginBottom, marginTop, ...rest }) => (
  <div {...rest} />
))`
  margin: 1.25rem 0 1rem 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  &:first-child {
    margin-top: 0;
  }

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}

  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}
`;

const AccordionBody = styled.div`
  padding: 16px 66px 16px 220px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  p {
    margin: 0;
  }
`;

const FieldLabelRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const DebouncedTextFieldSC = styled(DebouncedTextField)`
  && {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    textarea {
      box-sizing: border-box;
      margin-bottom: 0;
    }
  }
`;

const AssessmentFieldLabel = styled(Caption)`
  flex-grow: 2;
`;

const WeightFieldLabel = styled(Caption)`
  flex-grow: 1;
  max-width: 200px;
  min-width: 200px;
`;

const DebouncedTextFieldWithError = withValidation(DebouncedTextFieldSC);

const GradeCollectionDetailsForm = ({
  distributeWeightEqually,
  collection,
  collectionNumber,
  showValidationErrors,
  onCollectionTitleChange,
  onCollectionWeightChange,
  onRemoveCollection
}) => {
  const { partUuid } = useParams();
  const history = useHistory();

  const handleTitleChange = e => onCollectionTitleChange(e, collection.uuid);
  const handleWeightChange = e => onCollectionWeightChange(e, collection.uuid);

  const accordionColumnOne = (
    <DebouncedTextFieldWithError
      handleValidate={validateGradeCollectionTitle}
      hasErrors={Boolean(showValidationErrors)}
      InputLabelProps={{ shrink: true }}
      label="Title"
      margin="normal"
      name="title"
      onClick={e => e.stopPropagation()}
      onChange={handleTitleChange}
      placeholder=""
      value={collection.title}
      variant="outlined"
    />
  );

  const accordionColumnTwo = (
    <DebouncedTextFieldWithError
      handleValidate={
        distributeWeightEqually
          ? () => ({
              invalid: false
            })
          : validateGradeCollectionWeight
      }
      disabled={Boolean(distributeWeightEqually)}
      hasErrors={Boolean(showValidationErrors)}
      id="weight"
      InputLabelProps={{ shrink: true }}
      label="Weight"
      margin="normal"
      name="weight"
      onClick={e => e.stopPropagation()}
      onChange={handleWeightChange}
      type="number"
      placeholder=""
      value={collection.weight}
      variant="outlined"
    />
  );

  const onDeleteCollection = () => onRemoveCollection(collection.uuid);

  const navigateEditOnClick = () => {
    const historyString = `/syllabus-management/part/${partUuid}/grade-collection/${collection.uuid}/edit`;
    history.push(historyString);
  };

  const editAction = {
    label: 'Edit',
    onClick: navigateEditOnClick,
    shouldOpenOnClick: false,
    icon: 'edit'
  };

  const deleteAction = {
    label: 'Delete',
    onClick: onDeleteCollection,
    shouldOpenOnClick: false,
    icon: 'delete'
  };

  const gradeCollectionActions = collection?.isNew
    ? [deleteAction]
    : [editAction, deleteAction];

  return (
    <GradeCollectionLayout>
      <FormRow marginBottom="0">
        <GradeCollectionFormAccordion
          withTopBorder={false}
          withBottomBorder={false}
          expandWidth="48px"
          height="100px"
          label={`Grade Collection ${collectionNumber}`}
          columnOne={accordionColumnOne}
          columnOneClassName="collection_title"
          columnTwo={accordionColumnTwo}
          columnTwoClassName="collection_weight"
          actions={gradeCollectionActions}
        >
          <AccordionBody>
            {collection.collection_assessments?.length > 0 ? (
              <>
                <FieldLabelRow>
                  <AssessmentFieldLabel>Assessment</AssessmentFieldLabel>
                  <WeightFieldLabel>Weight</WeightFieldLabel>
                </FieldLabelRow>
                {collection.collection_assessments.map(assessment => (
                  <CollectionAssessmentRow
                    key={assessment.uuid}
                    collectionAssessment={assessment}
                  />
                ))}
              </>
            ) : (
              <Body1>There are no assessments in this collection</Body1>
            )}
          </AccordionBody>
        </GradeCollectionFormAccordion>
      </FormRow>
    </GradeCollectionLayout>
  );
};

GradeCollectionDetailsForm.propTypes = {
  collection: PropTypes.object,
  distributeWeightEqually: PropTypes.bool,
  collectionNumber: PropTypes.number,
  showValidationErrors: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onCollectionTitleChange: PropTypes.func,
  onCollectionWeightChange: PropTypes.func,
  onRemoveCollection: PropTypes.func
};

GradeCollectionDetailsForm.defaultProps = {
  collection: {},
  distributeWeightEqually: false,
  collectionNumber: 1,
  showValidationErrors: false,
  onCollectionTitleChange: undefined,
  onCollectionWeightChange: undefined,
  onRemoveCollection: undefined
};

export default GradeCollectionDetailsForm;
