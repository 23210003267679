import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import SimpleModal from '../SimpleModal';

const ModalBody = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;

  h3 {
    margin-bottom: 0.5rem;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const DeleteModalRedirect = ({
  modalOpen,
  type,
  className,
  link,
  handleModalClose,
  handleDelete
}) => (
  <div className={className}>
    <SimpleModal open={modalOpen} onClose={handleModalClose}>
      <ModalBody>
        <div>
          <h3>Are you sure you want to delete this {type.toLowerCase()}?</h3>
          <em>This cannot be reversed.</em>
        </div>
        <Actions>
          <ButtonInline onClick={() => handleModalClose()}>Cancel</ButtonInline>
          <ButtonInline onClick={() => handleDelete()}>
            <Link to={link}>Delete {type}</Link>
          </ButtonInline>
        </Actions>
      </ModalBody>
    </SimpleModal>
  </div>
);

DeleteModalRedirect.propTypes = {
  modalOpen: PropTypes.bool,
  link: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  handleModalClose: PropTypes.func,
  handleDelete: PropTypes.func
};

DeleteModalRedirect.defaultProps = {
  modalOpen: false,
  link: '/',
  type: '',
  className: '',
  handleModalClose: undefined,
  handleDelete: undefined
};

export default DeleteModalRedirect;
