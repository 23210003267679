import _ from 'lodash';

const courseAgeModInitial = [
  {
    year: '1',
    low: '0',
    high: '0.99',
    mod: '0'
  },
  {
    year: '2',
    low: '1',
    high: '1.99',
    mod: '0'
  },
  {
    year: '3',
    low: '2',
    high: '2.99',
    mod: '0'
  },
  {
    year: '4',
    low: '3',
    high: '3.99',
    mod: '0'
  },
  {
    year: '5',
    low: '4',
    high: '4.99',
    mod: '0'
  },
  {
    year: '6',
    low: '5',
    high: '5.99',
    mod: '0'
  },
  {
    year: '7',
    low: '6',
    high: '6.99',
    mod: '0'
  },
  {
    year: '8',
    low: '7',
    high: '7.99',
    mod: '0'
  },
  {
    year: '9',
    low: '8',
    high: '8.99',
    mod: '0'
  },
  {
    year: '10',
    low: '9',
    high: '9.99',
    mod: '0'
  },
  {
    year: '11',
    low: '10',
    high: '10.99',
    mod: '0'
  },
  {
    year: '12',
    low: '11',
    high: '11.99',
    mod: '0'
  },
  {
    year: '13',
    low: '12',
    high: '12.99',
    mod: '0'
  },
  {
    year: '14',
    low: '13',
    high: '13.99',
    mod: '0'
  },
  {
    year: '15',
    low: '14',
    high: '14.99',
    mod: '0'
  },
  {
    year: '16',
    low: '15',
    high: '16.99',
    mod: '0'
  },
  {
    year: '17',
    low: '16',
    high: '16.99',
    mod: '0'
  },
  {
    year: '18',
    low: '17',
    high: '17.99',
    mod: '0'
  },
  {
    year: '19',
    low: '18',
    high: '18.99',
    mod: '0'
  },
  {
    year: '20',
    low: '19',
    high: '19.99',
    mod: '0'
  }
];

const courseAgeModReducer = (state, action) => {
  switch (action.type) {
    case 'load': {
      const { courseAgeMods } = action.payload;

      if (courseAgeMods?.length > 0) {
        return [...courseAgeMods];
      } else {
        return [...state];
      }
    }
    case 'modify': {
      const { name, value } = action.payload;

      const location = _.findIndex(state, { year: name });

      const copy = [...state];

      const yearMod = copy[location];

      copy[location] = { ...yearMod, mod: value };

      return [...copy];
    }
    default:
      return [...state];
  }
};

export { courseAgeModReducer, courseAgeModInitial };
