import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Header from '../../Library/Header';
import { ContainerResize, ContainerTabs } from '../../Library/Layout';
import UserProfileEditContainer from './UserProfileEdit/UserProfileEditContainer';
import UserProfileCredentialsContainer from './UserProfileCredentials/UserProfileCredentialsContainer';

const TabsSC = styled(Tabs)`
  && {
    border-top: 1px solid ${props => props.theme.colors.grey[300]};
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
    background: ${props => props.theme.colors.white};
  }
`;

const TabSC = styled(Tab)`
  && {
    max-width: 100%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    line-height: 16px;
    width: 100%;
  }
`;

const LinkSC = styled(Link)`
  width: 100%;
`;

export default function UserProfile({ path, pathname }) {
  let location = 0;

  if (pathname === `/user-management/user-profile/edit/details`) {
    location = 0;
  } else if (pathname === `/user-management/user-profile/edit/credentials`) {
    location = 1;
  }

  const renderLink = useMemo(
    () =>
      forwardRef((props, ref) => (
        // eslint-disable-next-line react/prop-types
        <LinkSC to={props.topath} {...props} ref={ref} />
      )),
    []
  );

  return (
    <ContainerResize>
      <Header title="User Profile" subtitle="Manage profile and credentials" />
      <ContainerTabs>
        <TabsSC
          value={location}
          textColor="primary"
          variant="fullWidth"
          indicatorColor="primary"
        >
          <TabSC
            component={renderLink}
            topath={`${path}/edit/details`}
            label="Details"
          />
          <TabSC
            component={renderLink}
            topath={`${path}/edit/credentials`}
            label="Credentials"
          />
        </TabsSC>
        <Switch>
          <Route
            path={`${path}/edit/details`}
            // eslint-disable-next-line no-unused-vars
            component={props => {
              return <UserProfileEditContainer {...props} />;
            }}
          />
          <Route
            path={`${path}/edit/credentials`}
            // eslint-disable-next-line no-unused-vars
            component={props => {
              return <UserProfileCredentialsContainer {...props} />;
            }}
          />
        </Switch>
      </ContainerTabs>
    </ContainerResize>
  );
}

UserProfile.propTypes = {
  path: PropTypes.string,
  pathname: PropTypes.string
};

UserProfile.defaultProps = {
  path: '',
  pathname: ''
};
