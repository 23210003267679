import fetch from '../helpers/fetch';

const postInstitutionRigor = (
  cohorts,
  programUuid,
  cohortStartUuid,
  cohortEndUuid
) =>
  fetch('/api/rigor-index', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      cohorts,
      programUuid,
      cohortStartUuid,
      cohortEndUuid
    })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { postInstitutionRigor };
