import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Header from '../../Library/Header';
import SchoolRoleStatusFilter from './SchoolRoleStatusFilter';
import { ContainerPage, ContainerResize } from '../../Library/Layout';
import { userStatusMap } from '../../../helpers/constants';

const Table = styled.table`
  background: ${props => props.theme.colors.white};
  border-collapse: collapse;
  width: 100%;
`;

const TableHead = styled.thead`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: ${props => props.theme.colors.grey[600]};
`;

const TableRow = styled.tr``;

const TableBody = styled.tbody`
  color: ${props => props.theme.colors.grey[900]};
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

const InstitutionId = styled.td`
  width: 20%;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;
const Name = styled.td`
  width: 20%;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Status = styled.td`
  width: 20%;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const StartDate = styled.td`
  width: 20%;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const EndDate = styled.td`
  width: 20%;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
`;

const StatusBox = styled.div`
  color: ${props => props.theme.colors.grey[600]};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const StartDateBox = styled.div`
  color: ${props => props.theme.colors.grey[600]};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const EndDateBox = styled.div`
  color: ${props => props.theme.colors.grey[600]};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

export default function SchoolRole({
  school,
  userRoles,
  filterActive,
  filterComplete,
  handleFilterSelection
}) {
  return (
    <ContainerResize>
      <Header title="School" subtitle={`${school.schoolName} Admins`} />

      <ContainerPage>
        <Table>
          <TableHead>
            <TableRow>
              <InstitutionId>Institution ID</InstitutionId>
              <Name>Name</Name>
              <Status>
                <SchoolRoleStatusFilter
                  filterActive={filterActive}
                  filterComplete={filterComplete}
                  handleFilterSelection={handleFilterSelection}
                />
              </Status>
              <StartDate>StartDate</StartDate>
              <EndDate>EndDate</EndDate>
            </TableRow>
          </TableHead>
          <TableBody>
            {userRoles.map(userRole => (
              <TableRow key={userRole.uuid}>
                <InstitutionId>{userRole.user.institutionId}</InstitutionId>
                <Name>{userRole.user.fullName}</Name>
                <Status>
                  <StatusBox>{userStatusMap[userRole.status]}</StatusBox>
                </Status>
                <StartDate>
                  <StartDateBox>{userRole.startDate}</StartDateBox>
                </StartDate>
                <EndDate>
                  <EndDateBox>{userRole.endDate}</EndDateBox>
                </EndDate>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ContainerPage>
    </ContainerResize>
  );
}

SchoolRole.propTypes = {
  userRoles: PropTypes.array,
  school: PropTypes.object,
  filterActive: PropTypes.bool,
  filterComplete: PropTypes.bool,
  handleFilterSelection: PropTypes.func
};

SchoolRole.defaultProps = {
  userRoles: [],
  school: {},
  filterActive: false,
  filterComplete: false,
  handleFilterSelection: undefined
};
