export const GET_SCHOOL_DEPARTMENTS = 'GET_SCHOOL_DEPARTMENTS';
export const GET_SCHOOL_DEPARTMENTS_SUCCESS = 'GET_SCHOOL_DEPARTMENTS_SUCCESS';
export const GET_SCHOOL_DEPARTMENTS_ERROR = 'GET_SCHOOL_DEPARTMENTS_ERROR';
export const GET_DEPARTMENT = 'GET_DEPARTMENT';
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
export const GET_DEPARTMENT_ERROR = 'GET_DEPARTMENT_ERROR';
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_ERROR = 'GET_DEPARTMENTS_ERROR';
export const POST_DEPARTMENT = 'POST_DEPARTMENT';
export const POST_DEPARTMENT_SUCCESS = 'POST_DEPARTMENT_SUCCESS';
export const POST_DEPARTMENT_ERROR = 'POST_DEPARTMENT_ERROR';
export const PUT_DEPARTMENT = 'PUT_DEPARTMENT';
export const PUT_DEPARTMENT_SUCCESS = 'PUT_DEPARTMENT_SUCCESS';
export const PUT_DEPARTMENT_ERROR = 'PUT_DEPARTMENT_ERROR';
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_ERROR = 'DELETE_DEPARTMENT_ERROR';
