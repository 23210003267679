import Loading from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';

const ChipSC = styled(Chip)`
  && {
    margin-right: 5px;
    background: ${props => {
      switch (props.status) {
        case 'remaining': {
          return props.theme.colors.status.error;
        }
        case 'pending': {
          return props.theme.colors.status.warning;
        }
        case 'complete': {
          return props.theme.colors.status.success;
        }
        default:
          return props.theme.colors.primary;
      }
    }};
    color: ${props => props.theme.colors.white};
  }

  .MuiChip-avatar {
    background: ${props => props.theme.colors.white};
    font-weight: 800;
    color: ${props => props.theme.colors.black} !important;
  }
`;

const CompletionRate = ({
  loading,
  completionRate: { complete, remaining, pending, percentage }
}) =>
  loading ? (
    <Loading />
  ) : (
    <>
      <ChipSC
        variant="outlined"
        size="small"
        label={`${percentage}%`}
        color="primary"
      />
      <ChipSC
        status="complete"
        variant="outlined"
        size="small"
        avatar={<Avatar>{complete}</Avatar>}
        label="Complete"
      />

      <ChipSC
        status="pending"
        variant="outlined"
        size="small"
        avatar={<Avatar>{pending}</Avatar>}
        label="Pending"
      />

      <ChipSC
        status="remaining"
        variant="outlined"
        avatar={<Avatar>{remaining}</Avatar>}
        size="small"
        label="Remaining"
      />
    </>
  );

CompletionRate.propTypes = {
  completionRate: PropTypes.shape({
    complete: PropTypes.number.isRequired,
    remaining: PropTypes.number.isRequired,
    pending: PropTypes.number.isRequired,
    percentage: PropTypes.string.isRequired
  }),
  loading: PropTypes.bool
};

CompletionRate.defaultProps = {
  completionRate: {
    complete: '0',
    remaining: '0',
    pending: '0',
    percentage: '0'
  },
  loading: false
};

export default CompletionRate;
