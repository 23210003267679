import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const coursesMasterSelectorByDepartmentInCourseMasterFilter =
  createSelector(
    state => state,
    state => state.filterCourseMasterState.departmentUuid,
    (redux, departmentUuid) => {
      const courseMastersSelectorORM = ormCreateSelector(orm.CourseMaster);
      const courseMasters = courseMastersSelectorORM(redux).filter(
        courseMaster => courseMaster.departmentUuid === departmentUuid
      );

      const coursesMastersSorted = _.sortBy(courseMasters, ['courseNumber']);

      return coursesMastersSorted;
    }
  );

export const coursesMasterSelectorByDepartmentInCourseFilter = createSelector(
  state => state,
  state => state.filterCourseState.departmentUuid,
  (redux, departmentUuid) => {
    const courseMastersSelectorORM = ormCreateSelector(orm.CourseMaster);
    const courseMasters = courseMastersSelectorORM(redux).filter(
      courseMaster => courseMaster.departmentUuid === departmentUuid
    );

    const coursesMastersSorted = _.sortBy(courseMasters, ['courseNumber']);

    return coursesMastersSorted;
  }
);

export const coursesMasterSelectorByDepartmentCourseEdit = createSelector(
  state => state,
  state => state.courseEditState.selectedDepartmentUuid,
  (redux, selectedDepartmentUuid) => {
    const courseMastersSelectorORM = ormCreateSelector(orm.CourseMaster);
    const courseMasters = courseMastersSelectorORM(redux).filter(
      courseMaster => courseMaster.departmentUuid === selectedDepartmentUuid
    );

    const coursesMastersSorted = _.sortBy(courseMasters, ['courseNumber']);

    return coursesMastersSorted;
  }
);

export const coursesMasterSelectorByDepartmentCourseAdd = createSelector(
  state => state,
  state => state.courseAddState.selectedDepartmentUuid,
  (redux, selectedDepartmentUuid) => {
    const courseMastersSelectorORM = ormCreateSelector(orm.CourseMaster);
    const courseMasters = courseMastersSelectorORM(redux).filter(
      courseMaster => courseMaster.departmentUuid === selectedDepartmentUuid
    );

    const coursesMastersSorted = _.sortBy(courseMasters, ['courseNumber']);

    return coursesMastersSorted;
  }
);

export const courseMasterSelector = createSelector(
  state => state,
  (_, courseMasterUuid) => courseMasterUuid,
  (redux, courseMasterUuid) => {
    const courseMastersSelectorORM = ormCreateSelector(
      orm.CourseMaster,
      orm.CourseMaster.alias,
      (courseMasters, courseMasterDepartment) => {
        const alias =
          courseMasterDepartment !== null ? courseMasterDepartment : [];

        return { ...courseMasters, alias };
      }
    );
    const courseMaster = courseMastersSelectorORM(redux, courseMasterUuid);

    return courseMaster;
  }
);

export const courseMasterSelectorPropsUuid = createSelector(
  state => state,
  (_, props) => props.courseMasterUuid,
  (redux, courseMasterUuid) => {
    const courseMastersSelectorORM = ormCreateSelector(orm.CourseMaster);
    const courseMaster = courseMastersSelectorORM(redux, courseMasterUuid);

    return courseMaster;
  }
);

export const courseMastersByDepartmentSelector = createSelector(
  state => state,
  (_, departmentUuid) => departmentUuid,
  (redux, departmentUuid) => {
    const courseMastersSelectorORM = ormCreateSelector(orm.CourseMaster);
    const courseMasters = courseMastersSelectorORM(redux).filter(
      courseMaster => courseMaster.departmentUuid === departmentUuid
    );

    return courseMasters;
  }
);

export const selectCourseMaster = ormCreateSelector(orm.CourseMaster);
