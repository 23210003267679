import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getStudentRoster,
  postUserSection,
  patchUsersSectionStatus,
  postUploadStudentsSection,
  deleteUsersSection
} from '../../api/userSectionApi';
import {
  doGetStudentRosterSuccess,
  doGetStudentRosterError,
  doPostUserSectionSuccess,
  doPostUserSectionError,
  doPatchUserSectionStatusSuccess,
  doPatchUserSectionStatusError,
  doUploadStudentsSectionSuccess,
  doUploadStudentsSectionWarning,
  doUploadStudentsSectionError,
  doDeleteUsersSectionSuccess,
  doDeleteUsersSectionError
} from '../actions/userSectionActions';

import {
  GET_STUDENT_ROSTER,
  POST_USER_SECTION,
  PATCH_STUDENT_SECTION_STATUS,
  POST_UPLOAD_STUDENTS_SECTION,
  DELETE_USERS_SECTION
} from '../actions-type';

function* handleGetStudentRoster(action) {
  const { sectionUuid } = action.payload;

  try {
    const result = yield call(getStudentRoster, sectionUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetStudentRosterSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetStudentRosterError(error));
  }
}

function* handlePostUserSection(action) {
  try {
    const result = yield call(postUserSection, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostUserSectionSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostUserSectionError(error));
  }
}

function* handlePatchStudentSectionStatus(action) {
  try {
    const result = yield call(patchUsersSectionStatus, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchUserSectionStatusSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPatchUserSectionStatusError(error));
  }
}

function* handleDeleteUsersSection(action) {
  try {
    const result = yield call(deleteUsersSection, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteUsersSectionSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteUsersSectionError(error));
  }
}

function* handleUploadStudentsSection(action) {
  try {
    const result = yield call(postUploadStudentsSection, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doUploadStudentsSectionSuccess(payload));
    } else if (result.status === 422) {
      const payload = yield result.json();
      yield put(doUploadStudentsSectionWarning(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doUploadStudentsSectionError(error));
  }
}

const userSection = [
  takeEvery(GET_STUDENT_ROSTER, handleGetStudentRoster),
  takeEvery(POST_USER_SECTION, handlePostUserSection),
  takeEvery(PATCH_STUDENT_SECTION_STATUS, handlePatchStudentSectionStatus),
  takeEvery(POST_UPLOAD_STUDENTS_SECTION, handleUploadStudentsSection),
  takeEvery(DELETE_USERS_SECTION, handleDeleteUsersSection)
];

export { userSection };
