const headersCognitiveProgram = [
  {
    field: 'applicationDate',
    type: 'STRING',
    allowEmpty: false,
    format: 'YYYY-MM-DD'
  },
  {
    field: 'termType',
    type: 'STRING',
    allowEmpty: false,
    accepted: 'Semester, Quarter, Trimester'
  },
  {
    field: 'term',
    type: 'STRING',
    allowEmpty: false,
    acceptedMap: {
      Semester: {
        Fall: '12-15',
        Spring: '05-15',
        Summer: '08-15',
        Summer1: '06-30',
        Summer2: '08-15'
      },
      Quarter: {
        Fall: '12-15',
        Winter: '03-15',
        Spring: '06-15',
        Summer: '08-30'
      },
      Trimester: {
        Fall: '12-15',
        Spring: '04-30',
        Summer: '08-15'
      }
    }
  },
  {
    field: 'year',
    type: 'STRING',
    allowEmpty: false,
    maxSize: 4
  },
  {
    field: 'itemCode',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'itemName',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'institutionCode',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'credits',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'grade',
    type: 'STRING',
    allowEmpty: false
  }
];

const headersCognitiveMilestone = [
  {
    field: 'applicationDate',
    type: 'STRING',
    allowEmpty: false,
    format: 'YYYY-MM-DD'
  },
  {
    field: 'attempt',
    type: 'INTEGER',
    allowEmpty: false
  },
  {
    field: 'date',
    type: 'STRING',
    allowEmpty: false,
    format: 'YYYY-MM-DD'
  },
  {
    field: 'itemCode',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'itemName',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'institutionCode',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'grade',
    type: 'STRING',
    allowEmpty: false,
    format: 'Tengths decimal must be provided with whole numbers. i.e 2.0'
  }
];

const headersAptitudes = [
  {
    field: 'applicationDate',
    type: 'STRING',
    allowEmpty: false,
    format: 'YYYY-MM-DD'
  },
  {
    field: 'attempt',
    type: 'INTEGER',
    allowEmpty: false
  },
  {
    field: 'date',
    type: 'STRING',
    allowEmpty: false,
    format: 'YYYY-MM-DD'
  },
  {
    field: 'level',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'examId',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'institutionCode',
    type: 'STRING',
    allowEmpty: false
  },
  {
    field: 'grade',
    type: 'STRING',
    allowEmpty: false,
    format: 'Tengths decimal must be provided with whole numbers. i.e 2.0'
  }
];

module.exports = {
  headersCognitiveProgram,
  headersCognitiveMilestone,
  headersAptitudes
};
