export const GET_PART_USER_GROUPS = 'GET_PART_USER_GROUPS';
export const GET_PART_USER_GROUPS_SUCCESS = 'GET_PART_USER_GROUPS_SUCCESS';
export const GET_PART_USER_GROUPS_ERROR = 'GET_PART_USER_GROUPS_ERROR';

export const GET_STUDENT_PART_USER_GROUPS = 'GET_STUDENT_PART_USER_GROUPS';
export const GET_STUDENT_PART_USER_GROUPS_SUCCESS =
  'GET_STUDENT_PART_USER_GROUPS_SUCCESS';
export const GET_STUDENT_PART_USER_GROUPS_ERROR =
  'GET_STUDENT_PART_USER_GROUPS_ERROR';

export const POST_USER_GROUPS = 'POST_USER_GROUPS';
export const POST_USER_GROUPS_SUCCESS = 'POST_USER_GROUPS_SUCCESS';
export const POST_USER_GROUPS_ERROR = 'POST_USER_GROUPS_ERROR';

export const DELETE_USER_GROUP = 'DELETE_USER_GROUP';
export const DELETE_USER_GROUP_SUCCESS = 'DELETE_USER_GROUP_SUCCESS';
export const DELETE_USER_GROUP_ERROR = 'DELETE_USER_GROUP_ERROR';
