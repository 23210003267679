import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Redirect, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import CourseAdd from './CourseAdd';
import { doPostCourse } from '../../../../redux/actions/courseActions';
import { doGetDepartments } from '../../../../redux/actions/departmentActions';
import { doGetCoursesMasterByDepartment } from '../../../../redux/actions/courseMasterActions';
import { doGetAcademicYearsBySchool } from '../../../../redux/actions/academicYearActions';
import { doGetTerms } from '../../../../redux/actions/termActions';
import { doGetUsersFaculty } from '../../../../redux/actions/userDepartmentActions';
import { departmentsSelector } from '../../../../redux/selectors/departmentsSelectors';
import { academicYearsSelectorBySchool } from '../../../../redux/selectors/academicYearsSelectors';
import { termsAcademicYearSelectorCourseAdd } from '../../../../redux/selectors/termsSelectors';
import { coursesMasterSelectorByDepartmentCourseAdd } from '../../../../redux/selectors/coursesMasterSelectors';
import { usersSelectorByDepartmentFaculty } from '../../../../redux/selectors/usersSelectors';
import { doSetDepartmentFilterForCourse } from '../../../../redux/actions/filterActions';

import {
  doSetSelectedDepartmentCourseAdd,
  doSetSelectedCourseMasterCourseAdd,
  doSetSelectedAcademicYearCourseAdd,
  doSetSelectedTermCourseAdd
} from '../../../../redux/actions/courseAddActions';

import {
  validateCourseSections,
  validateCourseParts
} from '../../../../helpers/validation/validateCourse';

import { validateSelectField } from '../../../../helpers/validation/validateGeneric';

import {
  generateSectionNumber,
  generatePartNumber
} from '../../../../helpers/generators';
import { AllDepartmentFacultyOption } from '../PartForm/PartForm';

class CourseAddContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departmentUuid: '',
      courseMasterUuid: '',
      academicYearUuid: '',
      termUuid: '',
      sections: [
        {
          uuid: uuidv4().toString(),
          courseId: '',
          sectionNumber: 1,
          section_directors_assigned: [],
          description: '',
          parts: [
            {
              uuid: uuidv4().toString(),
              title: '',
              partNumber: 1,
              part_directors_assigned: [],
              part_faculty_assigned: [],
              description: ''
            }
          ]
        }
      ],
      redirect: false,
      hasErrors: false,
      blocking: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSectionDirectorChange =
      this.handleSectionDirectorChange.bind(this);
    this.handleSectionInputChange = this.handleSectionInputChange.bind(this);
    this.handleAddSection = this.handleAddSection.bind(this);
    this.handleSectionDelete = this.handleSectionDelete.bind(this);
    this.handlePartInputChange = this.handlePartInputChange.bind(this);
    this.handlePartNumberChange = this.handlePartNumberChange.bind(this);
    this.handlePartDirectorChange = this.handlePartDirectorChange.bind(this);
    this.handleCourseSubmission = this.handleCourseSubmission.bind(this);
    this.hasValidationErrors = this.hasValidationErrors.bind(this);
  }

  componentDidMount() {
    this.props.onGetDepartments();
    this.props.onGetAcademicYearsBySchool(this.props.selectedSchoolUuid);

    if (
      this.props.filterAcademicYearUuid &&
      this.props.filterAcademicYearUuid !== ''
    ) {
      this.props.onGetTerms(this.props.filterAcademicYearUuid);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.departmentUuid !== this.state.departmentUuid &&
      this.state.departmentUuid !== ''
    ) {
      this.props.onGetCourseMasterByDepartment(this.state.departmentUuid);
      this.props.onSetDepartmentFilterForCourse(this.state.departmentUuid);
      this.props.onGetUsersFaculty(this.state.departmentUuid);
    }

    if (
      prevState.academicYearUuid !== this.state.academicYearUuid &&
      this.state.academicYearUuid !== ''
    ) {
      this.props.onGetTerms(this.state.academicYearUuid);
    }
  }

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      blocking: true
    });

    if (name === 'departmentUuid') {
      this.props.onSetSelectedDepartmentCourseAdd(value);
      this.setState({
        courseMasterUuid: ''
      });
    }

    if (name === 'courseMasterUuid') {
      this.props.onSetSelectedCourseMasterCourseAdd(value);
    }

    if (name === 'academicYearUuid') {
      this.props.onSetSelectedAcademicYearCourseAdd(value);
      this.setState({
        termUuid: ''
      });
    }

    if (name === 'termUuid') {
      this.props.onSetSelectedTermCourseAdd(value);
    }
  };

  handleSectionDirectorChange = (directors, sectionUuid) => {
    const newDirectors = directors.map(director => {
      const thisDirector =
        typeof director === 'string' ? director : director.value;
      return { uuid: thisDirector };
    });

    this.setState(prevState => {
      const newState = _.cloneDeep(prevState.sections);
      const location = _.findIndex(newState, obj => obj.uuid === sectionUuid);

      if (location !== -1) {
        newState[location].section_directors_assigned = newDirectors;
      }

      return { sections: newState, blocking: true };
    });
  };

  handleSectionInputChange = (event, id) => {
    const newValue = event.target.value;
    const fieldToChange = event.target.name;

    this.setState(prevState => {
      const newState = _.cloneDeep(prevState.sections);
      const location = _.findIndex(newState, obj => obj.uuid === id);

      if (location !== -1) {
        newState[location][fieldToChange] =
          fieldToChange === 'courseId' ? newValue.toUpperCase() : newValue;
      }

      return { sections: newState, blocking: true };
    });
  };

  handleAddSection = () => {
    this.setState(prevState => {
      const newSections = prevState.sections.concat({
        uuid: uuidv4().toString(),
        courseId: '',
        sectionNumber: generateSectionNumber(prevState.sections),
        section_directors_assigned: [],
        description: '',
        parts: [
          {
            uuid: uuidv4().toString(),
            title: '',
            partNumber: generatePartNumber(),
            part_directors_assigned: [],
            part_faculty_assigned: [],
            description: ''
          }
        ]
      });
      return {
        sections: newSections,
        blocking: true
      };
    });
  };

  handleSectionDelete = id => {
    this.setState(prevState => {
      const newState = _.cloneDeep(prevState.sections);
      const location = _.findIndex(newState, obj => obj.uuid === id);
      if (location !== -1) {
        newState.splice(location, 1);
      }
      return { sections: newState, blocking: true };
    });
  };

  handlePartInputChange = (event, sectionId, partId) => {
    const newValue = event.target.value;
    const fieldToChange = event.target.name;

    this.setState(prevState => {
      const newSectionState = _.cloneDeep(prevState.sections);
      const sectionLocation = _.findIndex(
        newSectionState,
        obj => obj.uuid === sectionId
      );

      const partLocation = _.findIndex(
        newSectionState[sectionLocation].parts,
        obj => obj.uuid === partId
      );

      if (partLocation !== -1) {
        newSectionState[sectionLocation].parts[partLocation][fieldToChange] =
          newValue;
      }

      return { sections: newSectionState, blocking: true };
    });
  };

  handlePartNumberChange = (partId, sectionId, moveUp) => {
    if (!partId || !sectionId) {
      return null;
    }

    this.setState(prevState => {
      const newSectionState = _.cloneDeep(prevState.sections);
      const sectionLocation = _.findIndex(
        newSectionState,
        obj => obj.uuid === sectionId
      );
      const partLocation = _.findIndex(
        newSectionState[sectionLocation].parts,
        obj => obj.uuid === partId
      );
      const thisSection = newSectionState[sectionLocation];
      const thisPart = thisSection.parts[partLocation];

      if (moveUp && thisPart.partNumber === 1) {
        return null;
      } else if (!moveUp && thisPart.partNumber === thisSection.parts.length) {
        return null;
      }

      if (moveUp) {
        thisSection.parts[partLocation - 1].partNumber = thisPart.partNumber;
        thisPart.partNumber -= 1;
      } else {
        thisSection.parts[partLocation + 1].partNumber = thisPart.partNumber;
        thisPart.partNumber += 1;
      }

      thisSection.parts = thisSection.parts.sort(
        (a, b) => a.partNumber - b.partNumber
      );

      return { sections: newSectionState };
    });
  };

  handlePartDirectorChange = (
    selectedDirectors,
    sectionUuid,
    partUuid,
    fieldName
  ) => {
    const filteredDirectors = selectedDirectors.includes(AllDepartmentFacultyOption.value) ? selectedDirectors.filter(sd => sd === AllDepartmentFacultyOption.value) : selectedDirectors;
    const newDirectors = filteredDirectors.map(director => {
      const addDirector =
        typeof director === 'string' ? director : director.value;
      return { uuid: addDirector };
    });

    this.setState(prevState => {
      const newSectionState = _.cloneDeep(prevState.sections);

      if (newSectionState) {
        const sectionLocation = _.findIndex(
          newSectionState,
          obj => obj.uuid === sectionUuid
        );
        const partLocation = _.findIndex(
          newSectionState[sectionLocation].parts,
          obj => obj.uuid === partUuid
        );
        newSectionState[sectionLocation].parts[partLocation][fieldName] =
          newDirectors;

        return { sections: newSectionState, blocking: true };
      }
    });
  };

  handleAddPart = sectionId => {
    this.setState(prevState => {
      const newSections = _.cloneDeep(prevState.sections);
      const location = _.findIndex(newSections, obj => obj.uuid === sectionId);
      newSections[location].parts = newSections[location].parts.concat({
        uuid: uuidv4().toString(),
        title: '',
        partNumber: generatePartNumber(newSections[location].parts),
        part_directors_assigned: [],
        part_faculty_assigned: [],
        description: ''
      });
      return {
        sections: newSections,
        blocking: true
      };
    });
  };

  handlePartDelete = (sectionId, partId) => {
    this.setState(prevState => {
      const newSections = _.cloneDeep(prevState.sections);
      const sectionLocation = _.findIndex(
        newSections,
        obj => obj.uuid === sectionId
      );
      const partLocation = _.findIndex(
        newSections[sectionLocation].parts,
        obj => obj.uuid === partId
      );

      if (partLocation !== -1) {
        newSections[sectionLocation].parts.splice(partLocation, 1);
      }
      return { sections: newSections, blocking: true };
    });
  };

  hasValidationErrors = () => {
    let validationErrors = false;

    const { courseMasterUuid, academicYearUuid, termUuid, sections } =
      this.state;

    const academicYearError = validateSelectField(academicYearUuid);
    const termError = validateSelectField(termUuid);
    const courseMasterError = validateSelectField(courseMasterUuid);
    const sectionsError = validateCourseSections(sections);
    const partError = validateCourseParts(sections);

    if (academicYearError.invalid) {
      validationErrors = true;
    }
    if (termError.invalid) {
      validationErrors = true;
    }

    if (courseMasterError.invalid) {
      validationErrors = true;
    }

    if (sectionsError.invalid) {
      validationErrors = true;
    }
    if (partError.invalid) {
      validationErrors = true;
    }

    return validationErrors;
  };

  handleCourseSubmission = async () => {
    this.setState(
      oldstate => ({
        ...oldstate,
        sections: oldstate.sections.map(s => ({
          ...s,
          description: this.props.sectionsDescription[s.uuid],
          parts: s.parts.map(p => ({
            ...p,
            description: this.props.partsDescription[p.uuid]
          }))
        }))
      }),
      () => {
        const hasValidationErrors = this.hasValidationErrors();

        if (hasValidationErrors) {
          this.setState({
            hasErrors: true
          });
        } else {
          const { courseMasterUuid, academicYearUuid, termUuid, sections } =
            this.state;

          const payload = {
            courseMasterUuid,
            academicYearUuid,
            termUuid,
            sections
          };

          this.props.onPostCourse(payload);

          this.setState({
            hasErrors: false,
            blocking: false,
            redirect: true
          });
        }
      }
    );
  };

  render() {
    const {
      departments,
      academicYears,
      terms,
      facultyUsers,
      coursesMaster,
      selectedSchool
    } = this.props;

    const { blocking, redirect, courseMasterUuid, departmentUuid } = this.state;

    const courseMaster = _.find(
      coursesMaster,
      courseMaster => courseMaster.uuid === courseMasterUuid
    );

    const department = _.find(
      departments,
      department => department.uuid === departmentUuid
    );

    return redirect ? (
      <Redirect to="/syllabus-management/all" />
    ) : (
      <div>
        <CourseAdd
          {...this.state}
          departments={departments}
          academicYears={academicYears}
          terms={terms}
          facultyUsers={facultyUsers}
          coursesMaster={coursesMaster}
          selectedCourseMaster={courseMaster}
          selectedDepartment={department}
          selectedSchool={selectedSchool}
          handleChange={event => this.handleChange(event)}
          handleSectionDirectorChange={(event, sectionUuid) =>
            this.handleSectionDirectorChange(event, sectionUuid)
          }
          handleSectionInputChange={(event, id) =>
            this.handleSectionInputChange(event, id)
          }
          handleAddSection={() => this.handleAddSection()}
          handleCourseSubmission={() => this.handleCourseSubmission()}
          handleSectionDelete={id => this.handleSectionDelete(id)}
          handlePartInputChange={(event, sectionId, partId) =>
            this.handlePartInputChange(event, sectionId, partId)
          }
          handlePartNumberChange={(partId, sectionId, moveUp) =>
            this.handlePartNumberChange(partId, sectionId, moveUp)
          }
          handlePartDirectorChange={(event, sectionId, partId, fieldName) =>
            this.handlePartDirectorChange(event, sectionId, partId, fieldName)
          }
          handleAddPart={sectionId => this.handleAddPart(sectionId)}
          handlePartDelete={(sectionId, partId) =>
            this.handlePartDelete(sectionId, partId)
          }
        />

        <Prompt
          when={blocking}
          message={() =>
            `Are you sure you want to exit this form without submitting?`
          }
        />
      </div>
    );
  }
}

CourseAddContainer.propTypes = {
  onGetDepartments: PropTypes.func,
  onGetAcademicYearsBySchool: PropTypes.func,
  onGetTerms: PropTypes.func,
  onGetUsersFaculty: PropTypes.func,
  onPostCourse: PropTypes.func,
  filterDepartmentUuid: PropTypes.string,
  filterCourseMasterUuid: PropTypes.string,
  filterAcademicYearUuid: PropTypes.string,
  filterTermUuid: PropTypes.string,
  departments: PropTypes.arrayOf(PropTypes.object),
  coursesMaster: PropTypes.arrayOf(PropTypes.object),
  terms: PropTypes.arrayOf(PropTypes.object),
  academicYears: PropTypes.arrayOf(PropTypes.object),
  facultyUsers: PropTypes.arrayOf(PropTypes.object),
  selectedSchoolUuid: PropTypes.string,
  selectedSchool: PropTypes.string,
  onGetCourseMasterByDepartment: PropTypes.func,
  onSetDepartmentFilterForCourse: PropTypes.func,
  onSetSelectedDepartmentCourseAdd: PropTypes.func,
  onSetSelectedCourseMasterCourseAdd: PropTypes.func,
  onSetSelectedAcademicYearCourseAdd: PropTypes.func,
  onSetSelectedTermCourseAdd: PropTypes.func,
  sectionsDescription: PropTypes.object,
  partsDescription: PropTypes.object
};

CourseAddContainer.defaultProps = {
  onGetDepartments: undefined,
  onGetAcademicYearsBySchool: undefined,
  onGetTerms: undefined,
  onGetUsersFaculty: undefined,
  onPostCourse: undefined,
  filterDepartmentUuid: '',
  filterCourseMasterUuid: '',
  filterAcademicYearUuid: '',
  filterTermUuid: '',
  departments: [],
  coursesMaster: [],
  academicYears: [],
  terms: [],
  facultyUsers: [],
  selectedSchoolUuid: '',
  selectedSchool: '',
  onGetCourseMasterByDepartment: undefined,
  onSetDepartmentFilterForCourse: undefined,
  onSetSelectedDepartmentCourseAdd: undefined,
  onSetSelectedCourseMasterCourseAdd: undefined,
  onSetSelectedAcademicYearCourseAdd: undefined,
  onSetSelectedTermCourseAdd: undefined,
  sectionsDescription: {},
  partsDescription: {}
};

const mapStateToProps = state => {
  const { notificationState, filterCourseState, userState, descriptionState } =
    state;

  return {
    notifications: notificationState,
    departments: departmentsSelector(state),
    coursesMaster: coursesMasterSelectorByDepartmentCourseAdd(state),
    academicYears: academicYearsSelectorBySchool(state),
    terms: termsAcademicYearSelectorCourseAdd(state),
    facultyUsers: usersSelectorByDepartmentFaculty(state),
    selectedSchoolUuid: userState.selectedSchoolUuid,
    selectedSchool: userState.selectedSchool,
    selectedDeparmentUuid: userState.selectedDepartmentUuid,
    filterDepartmentUuid: filterCourseState.departmentUuid,
    filterCourseMasterUuid: filterCourseState.courseMasterUuid,
    filterAcademicYearUuid: filterCourseState.academicYearUuid,
    filterTermUuid: filterCourseState.termUuid,
    sectionsDescription: descriptionState.sections,
    partsDescription: descriptionState.parts
  };
};

const mapDispatchToProps = dispatch => ({
  onPostCourse: course => dispatch(doPostCourse(course)),
  onGetDepartments: () => dispatch(doGetDepartments()),
  onGetAcademicYearsBySchool: schoolUuid =>
    dispatch(doGetAcademicYearsBySchool(schoolUuid)),
  onGetTerms: academicYearUuid => dispatch(doGetTerms(academicYearUuid)),
  onGetCourseMasterByDepartment: departmentUuid =>
    dispatch(doGetCoursesMasterByDepartment(departmentUuid)),
  onGetUsersFaculty: departmentUuid =>
    dispatch(doGetUsersFaculty(departmentUuid)),
  onSetDepartmentFilterForCourse: departmentUuid =>
    dispatch(doSetDepartmentFilterForCourse(departmentUuid)),
  onSetSelectedDepartmentCourseAdd: departmentUuid =>
    dispatch(doSetSelectedDepartmentCourseAdd(departmentUuid)),
  onSetSelectedCourseMasterCourseAdd: courseMasterUuid =>
    dispatch(doSetSelectedCourseMasterCourseAdd(courseMasterUuid)),
  onSetSelectedAcademicYearCourseAdd: academicYearUuid =>
    dispatch(doSetSelectedAcademicYearCourseAdd(academicYearUuid)),
  onSetSelectedTermCourseAdd: termUuid =>
    dispatch(doSetSelectedTermCourseAdd(termUuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseAddContainer);
