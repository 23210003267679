import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getCompetencyCollection,
  getCompetencyCollections,
  postCompetencyCollection,
  putCompetencyCollection,
  deleteCompetencyCollection,
  getCompetencyCollectionSubtopicSearch,
  getCompetencyCollectionTopicSearch,
  getCompetencyCollectionMicroSearch,
  getCompetencyCollectionAndScores
} from '../../api/competencyCollectionApi';
import {
  doGetCompetencyCollectionsSuccess,
  doGetCompetencyCollectionsError,
  doPostCompetencyCollectionSuccess,
  doPostCompetencyCollectionError,
  doPutCompetencyCollectionSuccess,
  doPutCompetencyCollectionError,
  doDeleteCompetencyCollectionSuccess,
  doDeleteCompetencyCollectionError,
  doSearchMicrocompetencyTopicsSuccess,
  doSearchMicrocompetencyTopicsError,
  doGetCompetencyCollectionSuccess,
  doGetCompetencyCollectionError,
  doGetCompetencyCollectionsAndScoresSuccess,
  doGetCompetencyCollectionsAndScoresError
} from '../actions/competencyCollectionActions';

import {
  GET_COMPETENCY_COLLECTION,
  GET_COMPETENCY_COLLECTIONS,
  POST_COMPETENCY_COLLECTION,
  SEARCH_MICROCOMPETENCY_TOPICS,
  PUT_COMPETENCY_COLLECTION,
  DELETE_COMPETENCY_COLLECTION,
  GET_COMPETENCY_COLLECTION_AND_SCORES
} from '../actions-type';

function* handleGetCompetencyCollection(action) {
  const { competencyCollectionUuid } = action.payload;
  try {
    const result = yield call(
      getCompetencyCollection,
      competencyCollectionUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCompetencyCollectionSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetCompetencyCollectionError(error));
  }
}

function* handleGetCompetencyCollections(action) {
  const { programUuid } = action.payload;
  try {
    const result = yield call(getCompetencyCollections, programUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCompetencyCollectionsSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetCompetencyCollectionsError(error));
  }
}

function* handleSearchMicroTopics(action) {
  const { searchTerm, searchType } = action.payload;
  try {
    let result;
    if (searchType === 'topic') {
      result = yield call(getCompetencyCollectionTopicSearch, searchTerm);
    } else if (searchType === 'subtopic') {
      result = yield call(getCompetencyCollectionSubtopicSearch, searchTerm);
    } else {
      result = yield call(getCompetencyCollectionMicroSearch, searchTerm);
    }

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doSearchMicrocompetencyTopicsSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doSearchMicrocompetencyTopicsError(error));
  }
}

function* handlePostCreateCompetencyCollection(action) {
  const { newCompetencyCollection } = action.payload;

  try {
    const result = yield call(
      postCompetencyCollection,
      newCompetencyCollection
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostCompetencyCollectionSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPostCompetencyCollectionError({
          error: errorText.error
        })
      );
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPostCompetencyCollectionError());
  }
}

function* handlePutUpdateCompetencyCollection(action) {
  const { competencyCollectionUpdates } = action.payload;

  try {
    const result = yield call(
      putCompetencyCollection,
      competencyCollectionUpdates
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutCompetencyCollectionSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(doPutCompetencyCollectionError(errorText));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPutCompetencyCollectionError());
  }
}

function* handleDeleteCompetencyCollection(action) {
  const { uuid } = action.payload;

  try {
    const result = yield call(deleteCompetencyCollection, uuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doDeleteCompetencyCollectionSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doDeleteCompetencyCollectionError({
          text: errorText
        })
      );
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doDeleteCompetencyCollectionError());
  }
}

function* handleGetCompetencyCollectionAndScores(action) {
  const { programUuid, userUuid } = action.payload;
  try {
    const result = yield call(
      getCompetencyCollectionAndScores,
      programUuid,
      userUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetCompetencyCollectionsAndScoresSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetCompetencyCollectionsAndScoresError(error));
  }
}

const competencyCollection = [
  takeEvery(GET_COMPETENCY_COLLECTION, handleGetCompetencyCollection),
  takeEvery(GET_COMPETENCY_COLLECTIONS, handleGetCompetencyCollections),
  takeEvery(POST_COMPETENCY_COLLECTION, handlePostCreateCompetencyCollection),
  takeEvery(SEARCH_MICROCOMPETENCY_TOPICS, handleSearchMicroTopics),
  takeEvery(PUT_COMPETENCY_COLLECTION, handlePutUpdateCompetencyCollection),
  takeEvery(DELETE_COMPETENCY_COLLECTION, handleDeleteCompetencyCollection),
  takeEvery(
    GET_COMPETENCY_COLLECTION_AND_SCORES,
    handleGetCompetencyCollectionAndScores
  )
];

export { competencyCollection };
