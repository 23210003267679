import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useDelete } from '../../Library/Modal/DeleteModal/useDelete';
import ProgramAssessmentMaster from './ProgramAssessmentMaster';
import { programAssessmentMasterSelector } from '../../../redux/selectors/programSelectors';
import { doDeleteProgramAsssessmentMaster } from '../../../redux/actions/programActions';

const ProgramAssessmentMasterContainer = ({
  programAssessmentMasterUuid,
  programAssessmentCollectionUuid
}) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line prefer-const
  let history = useHistory();

  const programUuid = useSelector(state => state.userState.selectedProgramUuid);

  const onDelete = () =>
    dispatch(
      doDeleteProgramAsssessmentMaster({
        programUuid,
        uuid: programAssessmentMasterUuid
      })
    );

  const programAssessmentMaster = useSelector(
    state =>
      programAssessmentMasterSelector(state, programAssessmentMasterUuid),
    _.isEqual
  );

  const [
    isDeleteModalOpen,
    handleDeleteClick,
    handleDeleteModalClose,
    handleDelete
  ] = useDelete(onDelete);

  const onDeleteClick = () => {
    handleDeleteClick();
  };

  const toggleEditView = () => {
    const historyString = `/program-management/program-assessment-collection/${programAssessmentCollectionUuid}/program-assessment/${programAssessmentMasterUuid}`;
    history.push(historyString);
  };

  return (
    <ProgramAssessmentMaster
      programAssessmentMaster={programAssessmentMaster}
      toggleEditView={toggleEditView}
      isDeleteModalOpen={isDeleteModalOpen}
      handleDeleteClick={onDeleteClick}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDelete={handleDelete}
    />
  );
};

ProgramAssessmentMasterContainer.propTypes = {
  programAssessmentMasterUuid: PropTypes.string.isRequired,
  programAssessmentCollectionUuid: PropTypes.string.isRequired
};

export default ProgramAssessmentMasterContainer;
