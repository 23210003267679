import {
  GET_USER_PROGRAMS_BY_PROGRAM,
  GET_USER_PROGRAMS_BY_PROGRAM_SUCCESS,
  GET_USER_PROGRAMS_BY_PROGRAM_ERROR,
  GET_USER_PROGRAMS_BY_PROGRAMS,
  GET_USER_PROGRAMS_BY_PROGRAMS_SUCCESS,
  GET_USER_PROGRAMS_BY_PROGRAMS_ERROR
} from '../actions-type';

const doGetUserProgramsByProgram = programUuid => ({
  type: GET_USER_PROGRAMS_BY_PROGRAM,
  payload: {
    programUuid
  }
});

const doGetUserProgramsByProgramSuccess = result => ({
  type: GET_USER_PROGRAMS_BY_PROGRAM_SUCCESS,
  payload: result
});

const doGetUserProgramsByProgramError = error => ({
  type: GET_USER_PROGRAMS_BY_PROGRAM_ERROR,
  payload: error
});

const doGetUserProgramsByProgramsByPrograms = programs => ({
  type: GET_USER_PROGRAMS_BY_PROGRAMS,
  payload: programs
});

const doGetUserProgramsByProgramsByProgramsSuccess = result => ({
  type: GET_USER_PROGRAMS_BY_PROGRAMS_SUCCESS,
  payload: result
});

const doGetUserProgramsByProgramsByProgramsError = error => ({
  type: GET_USER_PROGRAMS_BY_PROGRAMS_ERROR,
  payload: error
});

export {
  doGetUserProgramsByProgram,
  doGetUserProgramsByProgramSuccess,
  doGetUserProgramsByProgramError,
  doGetUserProgramsByProgramsByPrograms,
  doGetUserProgramsByProgramsByProgramsSuccess,
  doGetUserProgramsByProgramsByProgramsError
};
