import Decimal from 'decimal.js-light';
import { decimalSort } from './utilities';

const sortStudentsByRank = (students, studentRankMap) => {
  return studentRankMap
    ? students.sort((a, b) => {
        const propertyA = studentRankMap[a.userUuid]?.cohortRank;
        const propertyB = studentRankMap[b.userUuid]?.cohortRank;

        if (propertyA < propertyB) {
          return -1;
        }
        if (propertyA > propertyB) {
          return 1;
        }

        // properties must be equal
        return 0;
      })
    : students;
};

const generateParticipationCohortRanking = studentTotalParticipationMap => {
  const rankedParticipationMap = { ...studentTotalParticipationMap };

  const sorted = Object.entries(studentTotalParticipationMap)
    .map(([studentUuid, { studentCumulativeTotal }]) => ({
      studentUuid,
      studentCumulativeTotal
    }))
    .sort((a, b) =>
      decimalSort(a.studentCumulativeTotal, b.studentCumulativeTotal, true)
    );

  let currentRank = 1;
  let lastCalculatedGrade;

  sorted.forEach(({ studentUuid, studentCumulativeTotal }, index) => {
    let thisRank;
    if (Number(index) === 0) {
      thisRank = currentRank;
      lastCalculatedGrade = studentCumulativeTotal;
    } else if (studentCumulativeTotal === lastCalculatedGrade) {
      thisRank = currentRank;
    } else {
      currentRank = Number(index) + 1;
      thisRank = Number(index) + 1;
      lastCalculatedGrade = studentCumulativeTotal;
    }
    rankedParticipationMap[studentUuid].cohortRank = thisRank;
  });

  return rankedParticipationMap;
};

const generateScoreTotalMap = (
  competencyGridMap,
  students,
  competencyCollections
) => {
  if (students?.length < 1 || competencyCollections?.length < 1) {
    return {};
  }

  if (!competencyGridMap || Object.keys(competencyGridMap).length < 1) {
    return {};
  }

  const studentTotalParticipationMap = {};
  const studentCollectionMap = {};
  const collectionStudentMap = {};
  const collectionStudentHighMap = {};
  const competencyStudentHighMap = {};
  const collectionStudentAvgMap = {};
  const competencyStudentAvgMap = {};

  const zero = new Decimal(0);

  students.forEach(student => {
    const studentUuid = student.userUuid;
    let studentCumulativeTotal = new Decimal(0);

    if (!studentCollectionMap[studentUuid]) {
      studentCollectionMap[studentUuid] = {};
    }
    if (!studentTotalParticipationMap[studentUuid]) {
      studentTotalParticipationMap[studentUuid] = {};
      studentTotalParticipationMap[studentUuid].studentCumulativeTotal = '0.00';
    }

    competencyCollections.forEach(collection => {
      // ***** create cells if they do not exist
      if (!collectionStudentMap[collection.uuid]) {
        collectionStudentMap[collection.uuid] = {};
      }

      if (!studentCollectionMap[studentUuid][collection.uuid]) {
        studentCollectionMap[studentUuid][collection.uuid] = {
          totalCollectionScore: 0
        };
      }

      if (!collectionStudentMap[collection.uuid][studentUuid]) {
        collectionStudentMap[collection.uuid][studentUuid] = {
          totalCollectionScore: 0
        };
      }

      if (!collectionStudentAvgMap[collection.uuid]) {
        collectionStudentAvgMap[collection.uuid] = {
          total: zero,
          studentCount: 0
        };
      }

      // ***** calculate student total for collection
      const collectionScoreSum = collection.competencies.reduce(
        (sum, competency) => {
          const key = `${studentUuid}:${competency.uuid}`;

          const nextValueToAdd = competencyGridMap[key]?.totalScore
            ? new Decimal(competencyGridMap[key].totalScore)
            : zero;

          const sumDecimal = new Decimal(sum);

          // ***** replace current competency high if nextValueToAdd is higher than current
          if (!competencyStudentHighMap[competency.uuid]) {
            competencyStudentHighMap[competency.uuid] = nextValueToAdd
              .toFixed(2)
              .toString();
          } else {
            const currentHigh = new Decimal(
              competencyStudentHighMap[competency.uuid]
            );

            if (nextValueToAdd.gt(currentHigh)) {
              competencyStudentHighMap[competency.uuid] = nextValueToAdd
                .toFixed(2)
                .toString();
            }
          }

          // ***** add to accumulator and count for competencyStudentAvgMap
          if (!competencyStudentAvgMap[competency.uuid]) {
            competencyStudentAvgMap[competency.uuid] = {
              total: zero,
              studentCount: 0
            };
          }

          if (nextValueToAdd && nextValueToAdd.gt(zero)) {
            const currentTotal = competencyStudentAvgMap[competency.uuid].total;

            competencyStudentAvgMap[competency.uuid].total =
              currentTotal.plus(nextValueToAdd);
            competencyStudentAvgMap[competency.uuid].studentCount += 1;
          }

          return sumDecimal.plus(nextValueToAdd).toFixed(4).toString();
        },
        0
      );

      const collectionScoreSumDecimal = new Decimal(collectionScoreSum);

      // ***** replace current collection high if nextValueToAdd is higher than current
      if (!collectionStudentHighMap[collection.uuid]) {
        collectionStudentHighMap[collection.uuid] = collectionScoreSumDecimal
          .toFixed(2)
          .toString();
      } else {
        const currentHigh = new Decimal(
          collectionStudentHighMap[collection.uuid]
        );

        if (collectionScoreSumDecimal.gt(currentHigh)) {
          collectionStudentHighMap[collection.uuid] = collectionScoreSumDecimal
            .toFixed(2)
            .toString();
        }
      }

      // ***** add to accumulator and count for collectionStudentAvgMap

      if (collectionScoreSumDecimal && collectionScoreSumDecimal.gt(zero)) {
        const currentTotal = collectionStudentAvgMap[collection.uuid].total;

        collectionStudentAvgMap[collection.uuid].total = currentTotal.plus(
          collectionScoreSumDecimal
        );
        collectionStudentAvgMap[collection.uuid].studentCount += 1;
      }

      // ***** add collection total to student cumulative total
      studentCumulativeTotal = studentCumulativeTotal.plus(
        collectionScoreSumDecimal
      );

      const collectionScoreDisplay = collectionScoreSum
        ? collectionScoreSumDecimal.toFixed(2).toString()
        : zero.toFixed(2).toString();

      // ***** fill cells with competency totals
      studentCollectionMap[studentUuid][collection.uuid].totalCollectionScore =
        collectionScoreDisplay;
      collectionStudentMap[collection.uuid][studentUuid].totalCollectionScore =
        collectionScoreDisplay;
    });

    studentTotalParticipationMap[studentUuid].studentCumulativeTotal =
      studentCumulativeTotal.toFixed(2).toString();
  });

  // ***** fill cells with collection averages
  Object.keys(collectionStudentAvgMap).forEach(collectionUuid => {
    const { total, studentCount } = collectionStudentAvgMap[collectionUuid];
    const hasScores = total.gt(zero) && studentCount > 0;
    const studentCountDecimal = new Decimal(studentCount);
    const average = hasScores ? total.dividedBy(studentCountDecimal) : zero;

    collectionStudentAvgMap[collectionUuid].average = average
      .toFixed(2)
      .toString();
  });

  // ***** fill cells with competency averages
  Object.keys(competencyStudentAvgMap).forEach(competencyUuid => {
    const { total, studentCount } = competencyStudentAvgMap[competencyUuid];
    const hasScores = total.gt(zero) && studentCount > 0;
    const studentCountDecimal = new Decimal(studentCount);
    const average = hasScores ? total.dividedBy(studentCountDecimal) : zero;

    competencyStudentAvgMap[competencyUuid].average = average
      .toFixed(2)
      .toString();
  });

  const studentRankMap = generateParticipationCohortRanking(
    studentTotalParticipationMap
  );

  return {
    studentCollectionMap,
    collectionStudentMap,
    studentTotalParticipationMap,
    collectionStudentHighMap,
    competencyStudentHighMap,
    collectionStudentAvgMap,
    competencyStudentAvgMap,
    studentRankMap
  };
};

const standardDeviation = (relativeCompScores) => {   
  // Assigning (value - mean) ^ 2 to every array item
  const distance = relativeCompScores.values.map((k)=>{
    return (k - relativeCompScores.average) ** 2
  });
   
  // Calculating the sum of updated array
 const sum = distance.reduce((acc, curr)=> acc + curr, 0);
  
 // Calculating the variance
 const variance = sum / (relativeCompScores.count - 1);
  
 // Returning the standard deviation
 return Math.sqrt(variance);
}

export { generateScoreTotalMap, sortStudentsByRank, standardDeviation };
