import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonPrimary, ButtonOutline } from '@xcomp/xcomp-design-library';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import IconButton from '@material-ui/core/IconButton';
import DateYear from '../../../../Library/DateYear';
import FormSelectError from '../../../../Library/FormSelectError';
import FormField from '../../../../Library/FormField';
import InstitutionCodeSearch from '../../../../LibraryExternal/InstitutionCodeSearch';
import ProgramCodeSearch from '../../../../LibraryExternal/ProgramCodeSearch';
import {
  validateInputString,
  validateSelectDate,
  validateSelectField
} from '../../../../../helpers/validation/validateGeneric';

const Cancel = styled(ButtonOutline)`
  && {
    margin-right: 20px;
  }
`;

const InvisibleLineBreak = styled.div`
  height: 4px;
`;

const Layout = styled.div``;

export default function Credential({
  editMode,
  submit,
  programCodeUuid,
  degreeLevelUuid,
  institutionUuid,
  year,
  credential,
  degreeLevelOptions,
  hasErrors,
  handleChange,
  handleChangeDate,
  handleSourceInstitutionUuid,
  handleProgramCodeUuid,
  handleClose,
  handleSave,
  handleDeleteModalOpen
}) {
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          {submit && editMode ? <LinearProgress /> : <InvisibleLineBreak />}
        </Grid>
        <Grid container justifyContent="flex-end" item sm={12} xs={12}>
          <IconButton onClick={handleDeleteModalOpen}>
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Grid item sm={12} xs={12}>
          <FormSelectError
            name="degreeLevelUuid"
            value={degreeLevelUuid}
            label="Education Level*"
            handleChange={handleChange}
            options={degreeLevelOptions}
            hasErrors={hasErrors}
            handleValidation={validateSelectField}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={3} xs={3}>
          <DateYear
            name="year"
            label="Year*"
            value={year}
            indentifier={null}
            hasErrors={hasErrors}
            handleChange={handleChangeDate}
            handleValidate={validateSelectDate}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={9} xs={9}>
          <FormField
            name="credential"
            label="Credential*"
            value={credential}
            onBlur={handleChange}
            hasErrors={hasErrors}
            handleValidate={validateInputString}
            disabled={!editMode}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <InstitutionCodeSearch
            hasErrors={hasErrors}
            sourceInstitutionUuid={institutionUuid}
            handleSourceInstitutionUuid={handleSourceInstitutionUuid}
            lock={!editMode}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <ProgramCodeSearch
            hasErrors={hasErrors}
            programCodeUuid={programCodeUuid}
            handleProgramCodeUuid={handleProgramCodeUuid}
            lock={!editMode}
          />
        </Grid>
        {editMode && (
          <Grid container justifyContent="flex-end" item sm={12} xs={12}>
            <Cancel onClick={handleClose}>Cancel</Cancel>
            <ButtonPrimary onClick={handleSave}>Save</ButtonPrimary>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
}

Credential.propTypes = {
  editMode: PropTypes.bool,
  submit: PropTypes.bool,
  programCodeUuid: PropTypes.string,
  degreeLevelUuid: PropTypes.string,
  institutionUuid: PropTypes.string,
  year: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  credential: PropTypes.string,
  degreeLevelOptions: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  handleChangeDate: PropTypes.func,
  handleSourceInstitutionUuid: PropTypes.func,
  handleProgramCodeUuid: PropTypes.func,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func
};

Credential.defaultProps = {
  editMode: false,
  submit: false,
  programCodeUuid: undefined,
  degreeLevelUuid: undefined,
  institutionUuid: undefined,
  year: undefined,
  credential: undefined,
  degreeLevelOptions: undefined,
  hasErrors: false,
  handleChange: undefined,
  handleChangeDate: undefined,
  handleSourceInstitutionUuid: undefined,
  handleProgramCodeUuid: undefined,
  handleClose: undefined,
  handleSave: undefined,
  handleDeleteModalOpen: undefined
};
