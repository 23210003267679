import fetch from '../helpers/fetch';

const getDepartment = departmentUuid =>
  fetch(`/api/department/${departmentUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getDepartments = () =>
  fetch('/api/departments', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getDepartmentsBySchool = schoolUuid =>
  fetch(`/api/departments/find-by-school?schoolUuid=${schoolUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postDepartment = department =>
  fetch('/api/department/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(department)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putDepartment = department =>
  fetch(`/api/department/${department.uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(department)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteDepartment = departmentUuid =>
  fetch(`/api/department/${departmentUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  getDepartment,
  getDepartments,
  getDepartmentsBySchool,
  postDepartment,
  putDepartment,
  deleteDepartment
};
