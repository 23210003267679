import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { ButtonInline, ButtonDelete } from '@xcomp/xcomp-design-library';
import Date from '../../../../Library/Date';
import Header from '../../../../Library/Header';
import { ContainerResize, ContainerPage } from '../../../../Library/Layout';
import { validateSelectDate } from '../../../../../helpers/validation/validateGeneric';

import TermEdit from './TermEdit';

const Container = styled.div`
  padding: 2rem;
  border-radius: 2px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.25px;
`;

const Divider = styled.div`
  font-size: 0.7rem;
`;

const Line = styled.hr`
  width: 98%;
  margin-left: 2%;
  margin-right: -32px;
  border-color: ${props => props.theme.colors.grey[300]};
`;

const Break = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DeleteRow = styled.div`
  margin-top: 2rem;
  margin-bottom: 6rem;
`;

const Cancel = styled(ButtonInline)`
  && {
    margin-right: 10px;
    color: ${props => props.theme.fontColors.dark};
  }
`;

export default function AcademicYearEdit({
  startDate,
  endDate,
  terms,
  hasErrors,
  submit,
  handleAddTerm,
  handleDateChange,
  handleTermChange,
  handleTermDateChange,
  handleRemoveTerm,
  handleSubmit,
  handleDeleteModalOpen
}) {
  const noErrors = !hasErrors && submit;

  return (
    <ContainerResize>
      {noErrors && (
        <Redirect to="/school-management/definitions/academicyearterm" />
      )}
      <Header title="Academic Year" subtitle="Edit" />
      <ContainerPage>
        <Container>
          <Title>Year Details</Title>
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <Date
                dataTestId="ay-edit-end-date"
                name="startDate"
                label="Start Date*"
                value={startDate}
                indentifier={null}
                hasErrors={hasErrors}
                handleChange={handleDateChange}
                handleValidate={validateSelectDate}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Date
                dataTestId="ay-edit-end-date"
                name="endDate"
                label="End Date"
                value={endDate}
                indentifier={null}
                hasErrors={hasErrors}
                handleChange={handleDateChange}
                handleValidate={validateSelectDate}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Break>
                <Divider>TERMS</Divider>
                <Line />
              </Break>
            </Grid>
            {terms.map(term => {
              return (
                <TermEdit
                  key={term.indentifier}
                  indentifier={term.indentifier}
                  period={term.period}
                  startDate={term.startDate}
                  endDate={term.endDate}
                  hasErrors={hasErrors}
                  handleTermChange={handleTermChange}
                  handleTermDateChange={handleTermDateChange}
                  handleRemoveTerm={handleRemoveTerm}
                />
              );
            })}
            <Grid item sm={12} xs={12}>
              <ButtonInline onClick={handleAddTerm}>Add Term</ButtonInline>
            </Grid>

            <Grid item sm={12} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={3}>
                  <ButtonArea>
                    <Link to="/school-management/definitions/academicyearterm">
                      <Cancel>CANCEL</Cancel>
                    </Link>
                    <ButtonInline onClick={handleSubmit}>SUBMIT</ButtonInline>
                  </ButtonArea>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <DeleteRow>
          <ButtonDelete onClick={handleDeleteModalOpen}>
            Delete Year
          </ButtonDelete>
        </DeleteRow>
      </ContainerPage>
    </ContainerResize>
  );
}

AcademicYearEdit.propTypes = {
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  terms: PropTypes.array,
  hasErrors: PropTypes.bool,
  submit: PropTypes.bool,
  handleAddTerm: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleTermChange: PropTypes.func,
  handleTermDateChange: PropTypes.func,
  handleRemoveTerm: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func
};
AcademicYearEdit.defaultProps = {
  startDate: '',
  endDate: '',
  terms: [],
  submit: false,
  hasErrors: false,
  handleAddTerm: undefined,
  handleDateChange: undefined,
  handleTermChange: undefined,
  handleTermDateChange: undefined,
  handleRemoveTerm: undefined,
  handleSubmit: undefined,
  handleDeleteModalOpen: undefined
};
