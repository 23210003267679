import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { doDeleteCompetencyCollection } from '../../../../redux/actions/competencyCollectionActions';
import { useDelete } from '../../../Library/Modal/DeleteModal/useDelete';
import CompetencyCollectionRow from './CompetencyCollectionRow';

const CompetencyCollectionRowContainer = ({ competencyCollection }) => {
  const dispatch = useDispatch();

  const onDelete = () =>
    dispatch(doDeleteCompetencyCollection(competencyCollection.uuid));

  const [
    isDeleteModalOpen,
    handleDeleteClick,
    handleDeleteModalClose,
    handleDelete
  ] = useDelete(onDelete);

  return (
    <CompetencyCollectionRow
      competencyCollection={competencyCollection}
      isDeleteModalOpen={isDeleteModalOpen}
      handleDeleteClick={handleDeleteClick}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDelete={handleDelete}
    />
  );
};

CompetencyCollectionRowContainer.propTypes = {
  competencyCollection: PropTypes.object.isRequired
};

export default CompetencyCollectionRowContainer;
