import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import Decimal from 'decimal.js-light';
import { assessmentBlocksSelector } from '../../../redux/selectors/assessmentBlockSelectors';
import { doGetAssessmentBlocks } from '../../../redux/actions/assessmentBlockActions';
import OpportunityReportAssessmentPanel from './OpportunityReportAssessmentPanel';

const OpportunityReportAssessmentPanelContainer = ({
  assessment,
  partOpportunityReportAssessmentStatisticData,
  studentAssessmentData,
  studentUser
}) => {
  const dispatch = useDispatch();
  const assessmentBlocks = useSelector(
    state => assessmentBlocksSelector(state, assessment.uuid),
    isEqual
  );

  useEffect(() => {
    if (assessment.uuid) {
      dispatch(doGetAssessmentBlocks(assessment.uuid));
    }
  }, [dispatch, assessment.uuid]);

  let studentAssessmentRelativeScore =
    studentAssessmentData?.relativeScore || 0;
  let studentAssessmentOpportunityCount =
    studentAssessmentData?.opportunityCount || 0;

  if (studentUser.uuid === 'student_high') {
    studentAssessmentRelativeScore =
      partOpportunityReportAssessmentStatisticData[assessment.uuid]
        ?.highRelativeScore;
    studentAssessmentOpportunityCount =
      partOpportunityReportAssessmentStatisticData[assessment.uuid]
        ?.highOpportunityCount;
  } else if (studentUser.uuid === 'student_average') {
    studentAssessmentRelativeScore =
      partOpportunityReportAssessmentStatisticData[assessment.uuid]
        ?.averageRelativeScore;
    studentAssessmentOpportunityCount =
      partOpportunityReportAssessmentStatisticData[assessment.uuid]
        ?.averageOpportunityCount;
  }

  const studentAssessmentPointsPerOpportunity = new Decimal(
    studentAssessmentRelativeScore || 0
  ).dividedBy(studentAssessmentOpportunityCount || 0);

  const assessementLabel = `${assessment.title} ● ${assessment.assessment_type?.name} ● ${assessment.scoreType}`;
  return studentAssessmentPointsPerOpportunity.greaterThan(0) ? (
    <OpportunityReportAssessmentPanel
      assessment={assessment}
      assessmentBlocks={assessmentBlocks}
      panelLabel={assessementLabel}
      studentAssessmentRelativeScore={studentAssessmentRelativeScore}
      studentAssessmentOpportunityCount={studentAssessmentOpportunityCount}
      studentAssessmentPointsPerOpportunity={studentAssessmentPointsPerOpportunity.toFixed(
        2
      )}
      studentUser={studentUser}
    />
  ) : null;
};

OpportunityReportAssessmentPanelContainer.propTypes = {
  assessment: PropTypes.object,
  partOpportunityReportAssessmentStatisticData: PropTypes.object,
  studentAssessmentData: PropTypes.object,
  studentUser: PropTypes.object
};
OpportunityReportAssessmentPanelContainer.defaultProps = {
  assessment: {},
  partOpportunityReportAssessmentStatisticData: {},
  studentAssessmentData: {},
  studentUser: {}
};

export default OpportunityReportAssessmentPanelContainer;
