import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2 } from '@xcomp/xcomp-design-library';

import {
  ContainerPage,
  Panel,
  PanelHeader,
  ContainerResize
} from '../../../Library/Layout';
import Header from '../../../Library/Header';
import CohortAssessmentCollectionForm from '../CohortAssessmentCollectionForm';

const HeaderSpacer = styled.div`
  width: 100%;
  height: 36px;
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const NewCohortAssessmentCollectionDashboard = ({
  parentCohort,
  newCollection,
  handleTitleChange,
  onSubmit,
  clearForm,
  showValidationErrors
}) => (
  <ContainerResize>
    <Header
      title="Add Collection"
      subtitle={`${parentCohort ? parentCohort.name : ''}`}
    />
    <ContainerPage>
      <HeaderSpacer />
      <Panel>
        <PanelHeader paddingLeft="24px">
          <Heading2>Assessment Collection</Heading2>
        </PanelHeader>
        <PanelPadding>
          <CohortAssessmentCollectionForm
            assessmentCollection={newCollection}
            buttonText="Create"
            handleTitleChange={handleTitleChange}
            onSubmit={onSubmit}
            onFormClose={clearForm}
            showValidationErrors={showValidationErrors}
          />
        </PanelPadding>
      </Panel>
    </ContainerPage>
  </ContainerResize>
);

NewCohortAssessmentCollectionDashboard.propTypes = {
  parentCohort: PropTypes.object,
  newCollection: PropTypes.object.isRequired,
  handleTitleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  clearForm: PropTypes.func,
  showValidationErrors: PropTypes.bool
};

NewCohortAssessmentCollectionDashboard.defaultProps = {
  parentCohort: { name: '' },
  handleTitleChange: undefined,
  onSubmit: undefined,
  clearForm: undefined,
  showValidationErrors: false
};

export default NewCohortAssessmentCollectionDashboard;
