import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DegreeLevelIndexEdit from './DegreeLevelIndexEdit';
import { degreeLevelsSelector } from '../../../../../redux/selectors/degreeLevelsSelectors';
import {
  validateSelectField,
  validateInputString
} from '../../../../../helpers/validation/validateGeneric';
import { doGetDegreeLevels } from '../../../../../redux/actions/sourceActions';
import { doPutDegreeLevelIndex } from '../../../../../redux/actions/degreeLevelIndexActions';
import { selectDegreeLevelIndex } from '../../../../../redux/selectors/degreeLevelIndexSelectors';

export default function DegreeLevelIndexEditContainer({
  selectEdit,
  openDegreeEdit,
  handleDegreeEditClose
}) {
  const dispatch = useDispatch();
  const [hasErrors, setHasErrors] = useState(false);
  const [uuid, setUuid] = useState(undefined);
  const [sourceDegreeLevelUuid, setSourceDegreeLevelUuid] = useState(undefined);
  const [percentage, setPercentage] = useState(undefined);

  const degreeLevelIndex = useSelector(
    state => selectDegreeLevelIndex(state, selectEdit),
    shallowEqual
  );

  const degreeLevelOptions = useSelector(state =>
    degreeLevelsSelector(state)
  ).map(level => ({ label: level.degreeLevel, value: level.uuid }));

  useEffect(() => {
    dispatch(doGetDegreeLevels());
  }, [dispatch]);

  useEffect(() => {
    if (openDegreeEdit) {
      setUuid(degreeLevelIndex.uuid);
      setSourceDegreeLevelUuid(degreeLevelIndex.sourceDegreeLevelUuid);
      setPercentage(degreeLevelIndex.percentage);
    }
  }, [
    openDegreeEdit,
    degreeLevelIndex.uuid,
    degreeLevelIndex.sourceDegreeLevelUuid,
    degreeLevelIndex.percentage
  ]);

  const _validate = () => {
    let containErrors = false;

    const sourceDegreeLevelUuidError = validateSelectField(
      sourceDegreeLevelUuid
    );

    const percentageError = validateInputString(percentage);

    if (sourceDegreeLevelUuidError.invalid || percentageError.invalid) {
      containErrors = true;
    }

    return containErrors;
  };

  const handleDegreeLevel = event => {
    const { value } = event.target;
    setSourceDegreeLevelUuid(value);
  };

  const handlePercentage = event => {
    const { value } = event.target;
    setPercentage(value);
  };

  const handleEditDegreeLevelIndex = () => {
    const check = _validate();

    if (check) {
      setHasErrors(true);
    } else {
      dispatch(doPutDegreeLevelIndex(uuid, sourceDegreeLevelUuid, percentage));
      handleDegreeEditClose();
    }
  };

  return (
    <DegreeLevelIndexEdit
      sourceDegreeLevelUuid={sourceDegreeLevelUuid}
      percentage={percentage}
      hasErrors={hasErrors}
      openDegreeEdit={openDegreeEdit}
      degreeLevelOptions={degreeLevelOptions}
      handleDegreeEditClose={handleDegreeEditClose}
      handleEditDegreeLevelIndex={handleEditDegreeLevelIndex}
      handleDegreeLevel={handleDegreeLevel}
      handlePercentage={handlePercentage}
    />
  );
}

DegreeLevelIndexEditContainer.propTypes = {
  selectEdit: PropTypes.string,
  openDegreeEdit: PropTypes.bool,
  handleDegreeEditClose: PropTypes.func
};

DegreeLevelIndexEditContainer.defaultProps = {
  selectEdit: undefined,
  openDegreeEdit: false,
  handleDegreeEditClose: undefined
};
