const initialSectionGradeSettingsState = {
  uuid: null,
  dataSource: null,
  showValidationErrors: false
};

const sectionGradeSettingsReducer = (state, action) => {
  switch (action.type) {
    case 'setExistingSettings': {
      const { uuid, dataSource } = action.payload;

      return {
        ...state,
        uuid,
        dataSource
      };
    }
    case 'setDataSource': {
      const { dataSource } = action.payload;

      return {
        ...state,
        dataSource
      };
    }

    case 'clearForm':
      return initialSectionGradeSettingsState;
    default: {
      throw new Error();
    }
  }
};

export { sectionGradeSettingsReducer, initialSectionGradeSettingsState };
