import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { Subtitle1 } from '@xcomp/xcomp-design-library';
import NotFoundIllustration from '@xcomp/xcomp-design-library/dist/illustrations/custom/NotFoundIllustration';
import WelcomeBackIllustration from '@xcomp/xcomp-design-library/dist/illustrations/custom/WelcomeBackIllustration';

const NoGroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const Subtitle1SC = styled(Subtitle1)`
  padding-top: 32px;
  padding-right: 32px;
`;

const getIllustration = type => {
  let Illustration;
  switch (type) {
    case 'notFound': {
      Illustration = NotFoundIllustration;
      break;
    }
    case 'welcomeBack': {
      Illustration = WelcomeBackIllustration;
      break;
    }
    default: {
      Illustration = NotFoundIllustration;
      break;
    }
  }
  return Illustration;
};
const PageIllustration = ({ infoText, illustrationType }) => {
  const themeContext = useContext(ThemeContext);
  const Illustration = getIllustration(illustrationType);
  return (
    <NoGroupsWrapper>
      <Illustration
        dotsColor={themeContext.colors.primaryShades[450]}
        bgColor={themeContext.colors.primaryShades[200]}
        outlineColor={themeContext.colors.primary}
      />
      <Subtitle1SC>{infoText}</Subtitle1SC>
    </NoGroupsWrapper>
  );
};

PageIllustration.propTypes = {
  infoText: PropTypes.string,
  illustrationType: PropTypes.oneOf(['notFound', 'welcomeBack', ''])
};
PageIllustration.defaultProps = {
  infoText: '',
  illustrationType: ''
};
export default PageIllustration;
