import {
  GET_STUDENT_ROSTER,
  GET_STUDENT_ROSTER_SUCCESS,
  GET_STUDENT_ROSTER_ERROR,
  POST_USER_SECTION,
  POST_USER_SECTION_SUCCESS,
  POST_USER_SECTION_ERROR,
  PATCH_STUDENT_SECTION_STATUS,
  PATCH_STUDENT_SECTION_STATUS_SUCCESS,
  PATCH_STUDENT_SECTION_STATUS_ERROR,
  POST_UPLOAD_STUDENTS_SECTION,
  POST_UPLOAD_STUDENTS_SECTION_SUCCESS,
  POST_UPLOAD_STUDENTS_SECTION_WARNING,
  POST_UPLOAD_STUDENTS_SECTION_ERROR,
  RESET_UPLOAD_STUDENTS_SECTION,
  DELETE_USERS_SECTION,
  DELETE_USERS_SECTION_SUCCESS,
  DELETE_USERS_SECTION_ERROR
} from '../actions-type';

const doGetStudentRoster = sectionUuid => ({
  type: GET_STUDENT_ROSTER,
  payload: {
    sectionUuid
  }
});

const doGetStudentRosterSuccess = result => ({
  type: GET_STUDENT_ROSTER_SUCCESS,
  payload: result
});

const doGetStudentRosterError = error => ({
  type: GET_STUDENT_ROSTER_ERROR,
  payload: error
});

const doPostUserSection = userSection => ({
  type: POST_USER_SECTION,
  payload: userSection
});

const doPostUserSectionSuccess = result => ({
  type: POST_USER_SECTION_SUCCESS,
  payload: result
});

const doPostUserSectionError = error => ({
  type: POST_USER_SECTION_ERROR,
  payload: error
});

const doPatchUserSectionStatus = students => ({
  type: PATCH_STUDENT_SECTION_STATUS,
  payload: students
});

const doPatchUserSectionStatusSuccess = result => ({
  type: PATCH_STUDENT_SECTION_STATUS_SUCCESS,
  payload: result
});

const doPatchUserSectionStatusError = error => ({
  type: PATCH_STUDENT_SECTION_STATUS_ERROR,
  payload: error
});

const doDeleteUsersSection = students => ({
  type: DELETE_USERS_SECTION,
  payload: students
});

const doDeleteUsersSectionSuccess = result => ({
  type: DELETE_USERS_SECTION_SUCCESS,
  payload: result
});

const doDeleteUsersSectionError = error => ({
  type: DELETE_USERS_SECTION_ERROR,
  payload: error
});

const doUploadStudentsSection = (schoolUuid, sectionUuid, students) => ({
  type: POST_UPLOAD_STUDENTS_SECTION,
  payload: {
    schoolUuid,
    sectionUuid,
    students
  }
});

const doUploadStudentsSectionSuccess = result => ({
  type: POST_UPLOAD_STUDENTS_SECTION_SUCCESS,
  payload: result
});

const doUploadStudentsSectionWarning = warning => ({
  type: POST_UPLOAD_STUDENTS_SECTION_WARNING,
  payload: { warning }
});

const doUploadStudentsSectionError = error => ({
  type: POST_UPLOAD_STUDENTS_SECTION_ERROR,
  payload: error
});

const doUploadStudentsSectionReset = () => ({
  type: RESET_UPLOAD_STUDENTS_SECTION
});

export {
  doGetStudentRoster,
  doGetStudentRosterSuccess,
  doGetStudentRosterError,
  doPostUserSection,
  doPostUserSectionSuccess,
  doPostUserSectionError,
  doPatchUserSectionStatus,
  doPatchUserSectionStatusSuccess,
  doPatchUserSectionStatusError,
  doDeleteUsersSection,
  doDeleteUsersSectionSuccess,
  doDeleteUsersSectionError,
  doUploadStudentsSection,
  doUploadStudentsSectionSuccess,
  doUploadStudentsSectionWarning,
  doUploadStudentsSectionError,
  doUploadStudentsSectionReset
};
