import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CheckboxAccordionRow from './CheckboxAccordionRow';

const CheckboxAccordionRowContainer = ({
  actions,
  children,
  className,
  label,
  columnOne,
  columnTwo,
  columnTwoClassName,
  columnOneClassName,
  expandWidth,
  height,
  rowOffset,
  columnGutters,
  leaf,
  disabled,
  shouldStartOpen,
  setCheckedFromProps,
  isPublished,
  isTopLevel,
  hideCheckBox,
  onRowOpen,
  onCheck,
  onUncheck,
  onShow,
  onPublish
}) => {
  const [isRowOpen, setIsRowOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (shouldStartOpen) setIsRowOpen(true);
  }, [shouldStartOpen]);

  useEffect(() => {
    setIsChecked(setCheckedFromProps);
  }, [setCheckedFromProps]);

  const toggleOpenRow = () => {
    if (onRowOpen) {
      onRowOpen();
    }
    setIsRowOpen(!isRowOpen);
  };

  const handleCheckboxChange = useCallback(
    e => {
      e.stopPropagation();
      if (!isChecked) {
        onCheck();
      } else {
        onUncheck();
      }
      setIsChecked(!isChecked);
    },
    [isChecked, onCheck, onUncheck]
  );

  return (
    <CheckboxAccordionRow
      actions={actions}
      className={className}
      columnOneClassName={columnOneClassName}
      columnTwoClassName={columnTwoClassName}
      isRowOpen={isRowOpen}
      isChecked={isChecked}
      isPublished={isPublished}
      isTopLevel={isTopLevel}
      label={label}
      columnOne={columnOne}
      columnTwo={columnTwo}
      expandWidth={expandWidth}
      height={height}
      rowOffset={rowOffset}
      columnGutters={columnGutters}
      leaf={leaf}
      disabled={disabled}
      onPublish={onPublish}
      onShow={onShow}
      handleCheckboxChange={handleCheckboxChange}
      toggleOpenRow={toggleOpenRow}
      hideCheckBox={hideCheckBox}
    >
      {children}
    </CheckboxAccordionRow>
  );
};

CheckboxAccordionRowContainer.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  columnOne: PropTypes.string,
  columnTwo: PropTypes.string,
  columnOneClassName: PropTypes.string,
  columnTwoClassName: PropTypes.string,
  expandWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  columnGutters: PropTypes.string,
  height: PropTypes.string,
  rowOffset: PropTypes.string,
  leaf: PropTypes.bool,
  disabled: PropTypes.bool,
  shouldStartOpen: PropTypes.bool,
  setCheckedFromProps: PropTypes.bool,
  isPublished: PropTypes.bool,
  isTopLevel: PropTypes.bool,
  hideCheckBox: PropTypes.bool,
  onPublish: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onRowOpen: PropTypes.func,
  onCheck: PropTypes.func,
  onUncheck: PropTypes.func,
  onShow: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

CheckboxAccordionRowContainer.defaultProps = {
  actions: false,
  children: <div />,
  className: '',
  label: '',
  columnOne: '',
  columnTwo: '',
  columnOneClassName: '',
  columnTwoClassName: '',
  columnGutters: '0.5rem',
  height: '43px',
  rowOffset: '0',
  expandWidth: false,
  leaf: false,
  disabled: false,
  shouldStartOpen: false,
  isPublished: false,
  setCheckedFromProps: false,
  isTopLevel: false,
  hideCheckBox: false,
  onPublish: false,
  onRowOpen: undefined,
  onCheck: undefined,
  onUncheck: undefined,
  onShow: false
};

export default CheckboxAccordionRowContainer;
