import React, { useReducer, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { doGetProgramCohorts } from '../../../../redux/actions/cohortActions';
import {
  doPostCourseCollection,
  doClearCreated
} from '../../../../redux/actions/courseCollectionActions';
import { cohortSelectorByMatchParams } from '../../../../redux/selectors/cohortsSelectors';
import {
  allFieldsAreValid,
  validateStringLength
} from '../../../../helpers/validation/validateGeneric';
import { validateCreditsRequired } from '../../../../helpers/validation/validateCourseCollection';
import NewCourseCollectionDashboard from './NewCourseCollectionDashboard';
import {
  initialCourseCollectionState,
  courseCollectionReducer,
  setHaveFetchedCohorts,
  setShowValidationErrors,
  setName,
  setCreditsRequired,
  setAllCreditsRequired,
  setSpecialCollection
} from '../courseCollectionState';

const NewCourseCollectionDashboardContainer = () => {
  // eslint-disable-next-line prefer-const
  let history = useHistory();
  const dispatch = useDispatch();
  const { cohortUuid } = useParams();

  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  const successfullyCreatedCourseCollection = useSelector(
    state => state.formSuccessState.successfullyCreatedCourseCollection
  );

  const parentCohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    shallowEqual
  );

  const [state, newDispatch] = useReducer(
    courseCollectionReducer,
    initialCourseCollectionState
  );

  const {
    name,
    credits_required,
    all_credits_required,
    special_collection,
    haveFetchedCohorts,
    showValidationErrors,
    courses
  } = state;

  const clearForm = useCallback(() => {
    history.push(`/program-management/cohort/${cohortUuid}/requirements`);
  }, [cohortUuid, history]);

  const onPostCreate = newCourseCollection =>
    dispatch(doPostCourseCollection(newCourseCollection));

  useEffect(() => {
    window.scrollTo(0, 0);
    newDispatch({
      type: 'clearForm'
    });
  }, []);

  useEffect(() => {
    const onClearCreated = () => dispatch(doClearCreated());

    if (successfullyCreatedCourseCollection === true) {
      onClearCreated();
      clearForm();
    }
  }, [successfullyCreatedCourseCollection, dispatch, clearForm]);

  useEffect(() => {
    if (!haveFetchedCohorts && !parentCohort) {
      dispatch(doGetProgramCohorts(selectedProgramUuid));
      newDispatch(setHaveFetchedCohorts());
    }
  }, [dispatch, selectedProgramUuid, haveFetchedCohorts, parentCohort]);

  const setValidationErrors = newCourseCollection => {
    const nameError = validateStringLength(newCourseCollection.name, 100, true);
    const creditsRequiredError = validateCreditsRequired(credits_required);
    let coursesError = { invalid: false };
    if (Object.entries(newCourseCollection.courses).length < 1) {
      coursesError = {
        invalid: true,
        isFormatError: true,
        message: 'A collection must have at least one class'
      };
    }
    const newValidationErrors = {
      nameError,
      creditsRequiredError: newCourseCollection.all_credits_required
        ? { invalid: false }
        : creditsRequiredError,
      coursesError
    };

    return newValidationErrors;
  };

  const buildProcedureCodePayload = (
    name,
    credits_required,
    all_credits_required,
    special_collection,
    cohortUuid,
    courses
  ) => {
    const coursesArray = Object.entries(courses).map(([uuid]) => uuid);

    return {
      name,
      credits_required: all_credits_required ? null : credits_required,
      all_credits_required,
      special_collection,
      cohortUuid,
      courses: coursesArray
    };
  };

  const handleCreateSubmission = () => {
    const newValidationErrors = setValidationErrors({
      name,
      credits_required,
      all_credits_required,
      special_collection,
      courses
    });

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = buildProcedureCodePayload(
        name,
        credits_required,
        all_credits_required,
        special_collection,
        cohortUuid,
        courses
      );

      onPostCreate(payload);
      newDispatch(setShowValidationErrors(false));
    } else {
      newDispatch(setShowValidationErrors(true));
    }
  };

  const newCourseCollection = {
    name,
    credits_required,
    all_credits_required,
    special_collection,
    cohortUuid,
    courses
  };

  const handleNameChange = e => newDispatch(setName(e.target.value));
  const handleCreditsRequiredChange = e =>
    newDispatch(setCreditsRequired(e.target.value));

  const handleAllCreditsRequiredChange = value =>
    newDispatch(setAllCreditsRequired(value));
  const handleSpecialCollectionChange = value =>
    newDispatch(setSpecialCollection(value));

  return (
    <NewCourseCollectionDashboard
      newCourseCollection={newCourseCollection}
      formDispatch={newDispatch}
      parentCohort={parentCohort}
      handleNameChange={handleNameChange}
      handleCreditsRequiredChange={handleCreditsRequiredChange}
      handleAllCreditsRequiredChange={handleAllCreditsRequiredChange}
      handleSpecialCollectionChange={handleSpecialCollectionChange}
      onSubmit={handleCreateSubmission}
      clearForm={clearForm}
      showValidationErrors={showValidationErrors}
    />
  );
};

export default NewCourseCollectionDashboardContainer;
