import {
  CLEAR_APPLICANT_COGNITIVE_REPORT,
  CLEAR_APPLICANT_COMMUNICATION_REPORT,
  CLEAR_APPLICANT_DEMOGRAPHIC_REPORT,
  CLEAR_APPLICANT_NONCOGNITIVE_REPORT,
  CLEAR_APPLICANT_PHYSICAL_SKILLS_REPORT,
  GET_APPLICANT_COGNITIVE_REPORT_TYPES_ERROR,
  GET_APPLICANT_COGNITIVE_REPORT_TYPES_SUCCESS,
  GET_APPLICANT_COGNITIVE_REPORT_TYPES,
  GET_APPLICANT_COMMUNICATION_REPORT_TYPES_ERROR,
  GET_APPLICANT_COMMUNICATION_REPORT_TYPES_SUCCESS,
  GET_APPLICANT_COMMUNICATION_REPORT_TYPES,
  GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES_ERROR,
  GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES_SUCCESS,
  GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES,
  GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES_ERROR,
  GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES_SUCCESS,
  GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES,
  GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES_ERROR,
  GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES_SUCCESS,
  GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES,
  PUT_APPLICANT_COGNITIVE_REPORT_ERROR,
  PUT_APPLICANT_COGNITIVE_REPORT_SUCCESS,
  PUT_APPLICANT_COGNITIVE_REPORT,
  PUT_APPLICANT_COMMUNICATION_REPORT_ERROR,
  PUT_APPLICANT_COMMUNICATION_REPORT_SUCCESS,
  PUT_APPLICANT_COMMUNICATION_REPORT,
  PUT_APPLICANT_DEMOGRAPHIC_REPORT_ERROR,
  PUT_APPLICANT_DEMOGRAPHIC_REPORT_SUCCESS,
  PUT_APPLICANT_DEMOGRAPHIC_REPORT,
  PUT_APPLICANT_NONCOGNITIVE_REPORT_ERROR,
  PUT_APPLICANT_NONCOGNITIVE_REPORT_SUCCESS,
  PUT_APPLICANT_NONCOGNITIVE_REPORT,
  PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_ERROR,
  PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_SUCCESS,
  PUT_APPLICANT_PHYSICAL_SKILLS_REPORT
} from '../actions-type';

const doPutApplicantCognitiveReport = (
  cohortUuid,
  applicantCognitiveReportTypeUuid,
  classificationUuid,
  rankBy
) => ({
  type: PUT_APPLICANT_COGNITIVE_REPORT,
  payload: {
    cohortUuid,
    applicantCognitiveReportTypeUuid,
    classificationUuid,
    rankBy
  }
});

const doPutApplicantCognitiveReportError = error => ({
  type: PUT_APPLICANT_COGNITIVE_REPORT_ERROR,
  payload: error
});

const doPutApplicantCognitiveReportSuccess = result => ({
  type: PUT_APPLICANT_COGNITIVE_REPORT_SUCCESS,
  payload: result
});

const doGetApplicantCognitiveReportTypes = () => ({
  type: GET_APPLICANT_COGNITIVE_REPORT_TYPES
});

const doGetApplicantCognitiveReportTypesError = error => ({
  type: GET_APPLICANT_COGNITIVE_REPORT_TYPES_ERROR,
  payload: error
});

const doGetApplicantCognitiveReportTypesSuccess = result => ({
  type: GET_APPLICANT_COGNITIVE_REPORT_TYPES_SUCCESS,
  payload: result
});

const doClearApplicantCognitiveReport = () => ({
  type: CLEAR_APPLICANT_COGNITIVE_REPORT
});

const doPutApplicantCommunicationReport = (
  cohortUuid,
  applicantCommunicationReportTypeUuid,
  classificationUuid,
  examMilestoneUuid,
  examAptitudeUuid
) => ({
  type: PUT_APPLICANT_COMMUNICATION_REPORT,
  payload: {
    cohortUuid,
    applicantCommunicationReportTypeUuid,
    classificationUuid,
    examMilestoneUuid,
    examAptitudeUuid
  }
});

const doPutApplicantCommunicationReportError = error => ({
  type: PUT_APPLICANT_COMMUNICATION_REPORT_ERROR,
  payload: error
});

const doPutApplicantCommunicationReportSuccess = result => ({
  type: PUT_APPLICANT_COMMUNICATION_REPORT_SUCCESS,
  payload: result
});

const doGetApplicantCommunicationReportTypes = () => ({
  type: GET_APPLICANT_COMMUNICATION_REPORT_TYPES
});

const doGetApplicantCommunicationReportTypesError = error => ({
  type: GET_APPLICANT_COMMUNICATION_REPORT_TYPES_ERROR,
  payload: error
});

const doGetApplicantCommunicationReportTypesSuccess = result => ({
  type: GET_APPLICANT_COMMUNICATION_REPORT_TYPES_SUCCESS,
  payload: result
});

const doClearApplicantCommunicationReport = () => ({
  type: CLEAR_APPLICANT_COMMUNICATION_REPORT
});

const doPutApplicantDemographicReport = (cohortUuid, reportTypeUuid) => ({
  type: PUT_APPLICANT_DEMOGRAPHIC_REPORT,
  payload: { cohortUuid, reportTypeUuid }
});

const doPutApplicantDemographicReportError = error => ({
  type: PUT_APPLICANT_DEMOGRAPHIC_REPORT_ERROR,
  payload: error
});

const doPutApplicantDemographicReportSuccess = result => ({
  type: PUT_APPLICANT_DEMOGRAPHIC_REPORT_SUCCESS,
  payload: result
});

const doGetApplicantDemographicReportTypes = () => ({
  type: GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES
});

const doGetApplicantDemographicReportTypesError = error => ({
  type: GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES_ERROR,
  payload: error
});

const doGetApplicantDemographicReportTypesSuccess = result => ({
  type: GET_APPLICANT_DEMOGRAPHIC_REPORT_TYPES_SUCCESS,
  payload: result
});

const doClearApplicantDemographicReport = () => ({
  type: CLEAR_APPLICANT_DEMOGRAPHIC_REPORT
});

const doPutApplicantNoncognitiveReport = (
  cohortUuid,
  applicantNoncognitiveReportTypeUuid,
  classificationUuid,
  examMilestoneUuid,
  examAptitudeUuid
) => ({
  type: PUT_APPLICANT_NONCOGNITIVE_REPORT,
  payload: {
    cohortUuid,
    applicantNoncognitiveReportTypeUuid,
    classificationUuid,
    examMilestoneUuid,
    examAptitudeUuid
  }
});

const doPutApplicantNoncognitiveReportError = error => ({
  type: PUT_APPLICANT_NONCOGNITIVE_REPORT_ERROR,
  payload: error
});

const doPutApplicantNoncognitiveReportSuccess = result => ({
  type: PUT_APPLICANT_NONCOGNITIVE_REPORT_SUCCESS,
  payload: result
});

const doGetApplicantNoncognitiveReportTypes = () => ({
  type: GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES
});

const doGetApplicantNoncognitiveReportTypesError = error => ({
  type: GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES_ERROR,
  payload: error
});

const doGetApplicantNoncognitiveReportTypesSuccess = result => ({
  type: GET_APPLICANT_NONCOGNITIVE_REPORT_TYPES_SUCCESS,
  payload: result
});

const doClearApplicantNoncognitiveReport = () => ({
  type: CLEAR_APPLICANT_NONCOGNITIVE_REPORT
});

const doPutApplicantPhysicalSkillsReport = (
  cohortUuid,
  applicantPhysicalSkillsReportTypeUuid,
  classificationUuid,
  examMilestoneUuid,
  examAptitudeUuid
) => ({
  type: PUT_APPLICANT_PHYSICAL_SKILLS_REPORT,
  payload: {
    cohortUuid,
    applicantPhysicalSkillsReportTypeUuid,
    classificationUuid,
    examMilestoneUuid,
    examAptitudeUuid
  }
});

const doPutApplicantPhysicalSkillsReportError = error => ({
  type: PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_ERROR,
  payload: error
});

const doPutApplicantPhysicalSkillsReportSuccess = result => ({
  type: PUT_APPLICANT_PHYSICAL_SKILLS_REPORT_SUCCESS,
  payload: result
});

const doGetApplicantPhysicalSkillsReportTypes = () => ({
  type: GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES
});

const doGetApplicantPhysicalSkillsReportTypesError = error => ({
  type: GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES_ERROR,
  payload: error
});

const doGetApplicantPhysicalSkillsReportTypesSuccess = result => ({
  type: GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES_SUCCESS,
  payload: result
});

const doClearApplicantPhysicalSkillsReport = () => ({
  type: CLEAR_APPLICANT_PHYSICAL_SKILLS_REPORT
});

export {
  doClearApplicantCognitiveReport,
  doClearApplicantCommunicationReport,
  doClearApplicantDemographicReport,
  doClearApplicantNoncognitiveReport,
  doClearApplicantPhysicalSkillsReport,
  doGetApplicantCognitiveReportTypes,
  doGetApplicantCognitiveReportTypesError,
  doGetApplicantCognitiveReportTypesSuccess,
  doGetApplicantCommunicationReportTypes,
  doGetApplicantCommunicationReportTypesError,
  doGetApplicantCommunicationReportTypesSuccess,
  doGetApplicantDemographicReportTypes,
  doGetApplicantDemographicReportTypesError,
  doGetApplicantDemographicReportTypesSuccess,
  doGetApplicantNoncognitiveReportTypes,
  doGetApplicantNoncognitiveReportTypesError,
  doGetApplicantNoncognitiveReportTypesSuccess,
  doGetApplicantPhysicalSkillsReportTypes,
  doGetApplicantPhysicalSkillsReportTypesError,
  doGetApplicantPhysicalSkillsReportTypesSuccess,
  doPutApplicantCognitiveReport,
  doPutApplicantCognitiveReportError,
  doPutApplicantCognitiveReportSuccess,
  doPutApplicantCommunicationReport,
  doPutApplicantCommunicationReportError,
  doPutApplicantCommunicationReportSuccess,
  doPutApplicantDemographicReport,
  doPutApplicantDemographicReportError,
  doPutApplicantDemographicReportSuccess,
  doPutApplicantNoncognitiveReport,
  doPutApplicantNoncognitiveReportError,
  doPutApplicantNoncognitiveReportSuccess,
  doPutApplicantPhysicalSkillsReport,
  doPutApplicantPhysicalSkillsReportError,
  doPutApplicantPhysicalSkillsReportSuccess
};
