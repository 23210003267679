import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, shallowEqual } from 'react-redux';
import { microcompetencySelector } from '../../../../redux/selectors/microSelectors';
import { decimalTenth } from '../../../../helpers/utilities';
import { ScoreRow, ScoreDetail } from '../ScoresSC';

const fillEmptyRow = string => {
  return !string || string === '' ? '--' : string;
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  padding-right: 8px;
`;

export default function TopicScore({
  uuid,
  date,
  tieBackID,
  stepSubItem,
  marginLeft,
  microcompetencyUuid,
  sequenceOrder,
  rubricOrder,
  procedureCodeOrder,
  procedureCodeDescriptor,
  relValue,
  potValue,
  criticalFailure,
  isOpportunity,
}) {
  const microcompetency = useSelector(
    state => microcompetencySelector(state, microcompetencyUuid),
    shallowEqual
  );

  return (
    <Layout key={uuid}>
      <ScoreRow marginLeft={marginLeft}>
        <ScoreDetail criticalFailure={criticalFailure} width="10%">
          {fillEmptyRow(moment(date).format('M/D/YYYY'))}
        </ScoreDetail>
        {!isOpportunity && 
          <>
            <ScoreDetail criticalFailure={criticalFailure} width="5%">
              {sequenceOrder || 'N/A'}
            </ScoreDetail>
            <ScoreDetail criticalFailure={criticalFailure} width="5%">
              {rubricOrder || 'N/A'}
            </ScoreDetail>
          </>
        }

        {isOpportunity && 
          <>
            <ScoreDetail criticalFailure={criticalFailure} width="5%">
              {procedureCodeOrder || 'N/A'}
            </ScoreDetail>
            <ScoreDetail criticalFailure={criticalFailure} width="5%">
              {procedureCodeDescriptor || 'N/A'}
            </ScoreDetail>
          </>
        }

        <ScoreDetail criticalFailure={criticalFailure} width="10%">
          {stepSubItem}
        </ScoreDetail>
        <ScoreDetail criticalFailure={criticalFailure} width="20%">
          {tieBackID}
        </ScoreDetail>
        <ScoreDetail criticalFailure={criticalFailure} width={isOpportunity ? "38%" : "28%"}>
          {microcompetency.title}
        </ScoreDetail>
        <ScoreDetail criticalFailure={criticalFailure} width="10%">
          {decimalTenth(relValue)}
        </ScoreDetail>
        <ScoreDetail criticalFailure={criticalFailure} width="12%">
          {decimalTenth(potValue)}
        </ScoreDetail>
      </ScoreRow>
    </Layout>
  );
}

TopicScore.propTypes = {
  uuid: PropTypes.string,
  date: PropTypes.string,
  tieBackID: PropTypes.string,
  stepSubItem: PropTypes.string,
  marginLeft: PropTypes.string,
  microcompetencyUuid: PropTypes.string,
  relValue: PropTypes.string,
  potValue: PropTypes.string,
  criticalFailure: PropTypes.bool,
  sequenceOrder: PropTypes.number,
  rubricOrder: PropTypes.number,
  procedureCodeOrder: PropTypes.number,
  procedureCodeDescriptor: PropTypes.string,
  isOpportunity: PropTypes.bool,
};

TopicScore.defaultProps = {
  uuid: '',
  date: '',
  isOpportunity: false,
  tieBackID: '',
  stepSubItem: '',
  marginLeft: '0px',
  sequenceOrder: undefined,
  rubricOrder: undefined,
  microcompetencyUuid: '',
  procedureCodeOrder: '',
  procedureCodeDescriptor: '',
  relValue: '',
  potValue: '',
  criticalFailure: false
};
