import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { classificationsSelectorByCategory } from '../../../../../redux/selectors/classificationSelectors';
import ByScopeCognitiveSubject from './ByScopeCognitiveSubject';

export default function ByScopeClassifications({
  userUuid,
  cohortUuid,
  programUuid,
  category
}) {
  const subjects = useSelector(state =>
    classificationsSelectorByCategory(state, programUuid, category)
  )?.map(classification => classification);

  return (
    <>
      {subjects.map(subject => (
        <ByScopeCognitiveSubject
          key={subject.uuid}
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          subject={subject.scope}
          classificationUuid={subject.uuid}
        />
      ))}
    </>
  );
}

ByScopeClassifications.propTypes = {
  category: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string
};
ByScopeClassifications.defaultProps = {
  category: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined
};
