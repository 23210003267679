import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ButtonIcon } from '@xcomp/xcomp-design-library';
import Chip from '@material-ui/core/Chip';
import ArtTrack from '@material-ui/icons/ArtTrack';

const ChipSC = styled(Chip)`
  margin-left: 10px;
`;
const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  
  & * {
    cursor: pointer;
  }
`;

export default function AssessmentTypeFilter({
  assessmentTypes,
  selectedAssessmentTypes,
  selectAssessmentType,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Layout>
      <ButtonIcon
        aria-controls="menu"
        aria-owns={anchorEl ? 'menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
        noHoverBg
      >
        <ChipSC icon={<ArtTrack />} size="small" label={selectedAssessmentTypes.length > 1 ? 'All' : selectedAssessmentTypes[0].name} />
      </ButtonIcon>

      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={event => {
            handleClose(event);
            selectAssessmentType(assessmentTypes);
          }}
        >
          All assessment types
        </MenuItem>

        {assessmentTypes.map(at => 
          <MenuItem
            onClick={event => {
              handleClose(event);
              selectAssessmentType([at]);
            }}
          >
            {at.name}
          </MenuItem>
        )}
      </Menu>
    </Layout>
  );
}

AssessmentTypeFilter.propTypes = {
  assessmentTypes: PropTypes.array,
  selectedAssessmentTypes: PropTypes.array.isRequired,
  selectAssessmentType: PropTypes.func.isRequired,
};

AssessmentTypeFilter.defaultProps = {
  assessmentTypes: [],
};
