import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AccordionRowContainer from '../../AccordionRow/AccordionRowContainer';
import TopicDefaultScoreViewList from './TopicDefaultScoreView/TopicDefaultScoreViewList';
import TopicAlternativeScoreViewList from './TopicAlternativeScoreView/TopicAlternativeScoreViewList';

const TopicDefaultScoreViewTopicPanelAccordion = styled(
  ({ withBottomBorder, withTopBorder, criticalFailure, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label,
  .column_two_label,
  .column_three_label,
  .column_four_label {
    flex-grow: 0;
    width: 100px;
    text-align: right;
  }

  .accordion_row_label div {
    font-size: 16px;
    letter-spacing: 0.15;
    color: ${props => props.theme.fontColors.darker};
  }

  .column_one_label,
  .column_two_label,
  .column_three_label {
    margin-right: 16px;
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props =>
        props.criticalFailure
          ? props.theme.colors.status.error
          : props.theme.fontColors.darker};
    }
  }

  .column_four_label {
    span {
      font-size: 16px;
      letter-spacing: 0.15;
      color: ${props => props.theme.fontColors.darker};
    }
  }

  .hover-effect {
    padding-right: 44px;
  }
  width: 100%;
`;

const TopicDefaultScoreViewTopicPanel = ({
  faculty,
  scoreType,
  students,
  topicParticipationPercentage,
  topicRelativePercentage,
  topicScores,
  topicScoreViewSelection,
  topicTitle,
  topicTotalPotential,
  topicTotalRelative
}) => {
  return (
    <TopicDefaultScoreViewTopicPanelAccordion
      withBottomBorder
      expandWidth="66px"
      height="50px"
      label={topicTitle}
      columnOne={`${topicParticipationPercentage}%`}
      columnTwo={`${topicRelativePercentage}%`}
      columnThree={topicTotalRelative}
      columnFour={topicTotalPotential}
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
      columnThreeClassName="column_three_label"
      columnFourClassName="column_four_label"
    >
      {topicScoreViewSelection === 'default' && (
        <TopicDefaultScoreViewList
          currentOffset={0}
          scoreType={scoreType}
          students={students}
          topicScores={topicScores}
        />
      )}
      {topicScoreViewSelection === 'alternative' && (
        <TopicAlternativeScoreViewList
          faculty={faculty}
          scoreType={scoreType}
          students={students}
          topicScores={topicScores}
        />
      )}
    </TopicDefaultScoreViewTopicPanelAccordion>
  );
};

TopicDefaultScoreViewTopicPanel.propTypes = {
  faculty: PropTypes.array,
  scoreType: PropTypes.string,
  students: PropTypes.array,
  topicParticipationPercentage: PropTypes.string,
  topicRelativePercentage: PropTypes.string,
  topicScores: PropTypes.array,
  topicScoreViewSelection: PropTypes.string,
  topicTitle: PropTypes.string,
  topicTotalPotential: PropTypes.string,
  topicTotalRelative: PropTypes.string
};

TopicDefaultScoreViewTopicPanel.defaultProps = {
  faculty: [],
  scoreType: '',
  students: '',
  topicParticipationPercentage: '0.00',
  topicRelativePercentage: '0.00',
  topicScores: [],
  topicScoreViewSelection: 'default',
  topicTitle: '',
  topicTotalPotential: '',
  topicTotalRelative: ''
};

export default TopicDefaultScoreViewTopicPanel;
