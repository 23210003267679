import { Model, attr } from 'redux-orm';
import {
  GET_APPLICANT_COGNITIVE_REPORT_TYPES_SUCCESS,
  GET_TRANSCRIPT_SUCCESS
} from '../actions-type';

class ApplicantCognitiveReportType extends Model {
  static reducer(action, ApplicantCognitiveReportType, session) {
    const { type, payload } = action;
    switch (type) {
      case GET_APPLICANT_COGNITIVE_REPORT_TYPES_SUCCESS: {
        const applicantCognitiveReportTypes = [
          ...payload.applicantCognitiveReportTypes
        ];
        applicantCognitiveReportTypes.forEach(type =>
          ApplicantCognitiveReportType.upsert(type)
        );
        break;
      }

      case GET_TRANSCRIPT_SUCCESS: {
        const applicantCognitiveReportType = [
          ...payload.applicantCognitiveReportType
        ];
        applicantCognitiveReportType.forEach(type =>
          ApplicantCognitiveReportType.upsert(type)
        );
        break;
      }

      default:
        return session;
    }
  }
}

ApplicantCognitiveReportType.fields = {
  uuid: attr(),
  name: attr(),
  subType: attr(),
  order: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

ApplicantCognitiveReportType.modelName = 'ApplicantCognitiveReportType';

ApplicantCognitiveReportType.options = {
  idAttribute: 'uuid'
};

export default ApplicantCognitiveReportType;
