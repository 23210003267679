/* eslint react/prop-types: "off" */

import React from 'react';

import { FormHelperText } from '@material-ui/core';

const withValidation = FieldComponent => ({
  value,
  values,
  name,
  select,
  userSelect,
  handleValidate, // function to handleValidate field with
  hasErrors, // bool for wether or not to show validation errors
  ...rest
}) => {
  if (!hasErrors && !userSelect) {
    return <FieldComponent value={value} name={name} {...rest} />;
  } else if (!hasErrors && userSelect) {
    return <FieldComponent values={values} name={name} {...rest} />;
  }

  const fieldValue = userSelect ? values : value;
  const fieldIsInvalid = handleValidate(fieldValue);

  if (fieldIsInvalid && !select && !userSelect) {
    return (
      <FieldComponent
        error={fieldIsInvalid.invalid}
        value={value}
        name={name}
        helperText={fieldIsInvalid.message}
        {...rest}
      />
    );
  } else if (fieldIsInvalid && select) {
    return (
      <FieldComponent
        error={fieldIsInvalid.invalid}
        errorMessage={fieldIsInvalid.message}
        value={value}
        name={name}
        helperText={fieldIsInvalid.message}
        {...rest}
      />
    );
  } else if (userSelect && fieldIsInvalid) {
    return (
      <FieldComponent
        error={fieldIsInvalid.invalid}
        values={values}
        name={name}
        {...rest}
      >
        <FormHelperText error={fieldIsInvalid.invalid}>
          {fieldIsInvalid.message}
        </FormHelperText>
      </FieldComponent>
    );
  } else if (userSelect && !fieldIsInvalid) {
    return <FieldComponent values={values} name={name} {...rest} />;
  }
  return <FieldComponent value={value} name={name} {...rest} />;
};

export default withValidation;
