import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import Date from '../../../../Library/Date';
import Header from '../../../../Library/Header';
import { validateSelectDate } from '../../../../../helpers/validation/validateGeneric';

import {
  ContainerResize,
  ContainerPage,
  ContainerContent,
  ElevateLow
} from '../../../../Library/Layout';

import TermAdd from './TermAdd';

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.25px;
`;

const Divider = styled.div`
  font-size: 0.7rem;
`;

const Line = styled.hr`
  width: 98%;
  margin-left: 2%;
  margin-right: -32px;
`;

const Break = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Cancel = styled(ButtonInline)`
  && {
    margin-right: 10px;
    color: ${props => props.theme.fontColors.dark};
  }
`;

export default function AcademicYearAdd({
  startDate,
  endDate,
  terms,
  hasErrors,
  submit,
  handleAddTerm,
  handleDateChange,
  handleTermChange,
  handleTermDateChange,
  handleRemoveTerm,
  handleSubmit
}) {
  const noErrors = !hasErrors && submit;

  return (
    <ContainerResize>
      {noErrors && (
        <Redirect to="/school-management/definitions/academicyearterm" />
      )}
      <Header title="Academic Year" subtitle="Add academic year and terms" />

      <ContainerPage>
        <ElevateLow>
          <ContainerContent>
            <Title>Year Details</Title>
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12}>
                <Date
                  dataTestId="ay-add-end-date"
                  name="startDate"
                  label="Start Date*"
                  value={startDate}
                  indentifier={null}
                  hasErrors={hasErrors}
                  handleChange={handleDateChange}
                  handleValidate={validateSelectDate}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Date
                  dataTestId="ay-add-end-date"
                  name="endDate"
                  label="End Date"
                  value={endDate}
                  indentifier={null}
                  hasErrors={hasErrors}
                  handleChange={handleDateChange}
                  handleValidate={validateSelectDate}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <Break>
                  <Divider>TERMS</Divider>
                  <Line />
                </Break>
              </Grid>
              {terms.map(term => {
                return (
                  <TermAdd
                    key={term.indentifier}
                    indentifier={term.indentifier}
                    period={term.period}
                    startDate={term.startDate}
                    endDate={term.endDate}
                    hasErrors={hasErrors}
                    handleTermChange={handleTermChange}
                    handleTermDateChange={handleTermDateChange}
                    handleRemoveTerm={handleRemoveTerm}
                  />
                );
              })}
              <Grid item sm={12} xs={12}>
                <ButtonInline onClick={handleAddTerm}>Add Term</ButtonInline>
              </Grid>

              <Grid item sm={12} xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item sm={2} xs={12}>
                    <ButtonArea>
                      <Link to="/school-management/definitions/academicyearterm">
                        <Cancel>CANCEL</Cancel>
                      </Link>
                      <ButtonInline
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        SAVE
                      </ButtonInline>
                    </ButtonArea>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ContainerContent>
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
}

AcademicYearAdd.propTypes = {
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  terms: PropTypes.array,
  hasErrors: PropTypes.bool,
  submit: PropTypes.bool,
  handleAddTerm: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleTermChange: PropTypes.func,
  handleTermDateChange: PropTypes.func,
  handleRemoveTerm: PropTypes.func,
  handleSubmit: PropTypes.func
};
AcademicYearAdd.defaultProps = {
  startDate: '',
  endDate: '',
  terms: [],
  submit: false,
  hasErrors: false,
  handleAddTerm: undefined,
  handleDateChange: undefined,
  handleTermChange: undefined,
  handleTermDateChange: undefined,
  handleRemoveTerm: undefined,
  handleSubmit: undefined
};
