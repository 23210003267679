import fetch from '../helpers/fetch';

const putApplicantDemographicReport = (cohortUuid, reportTypeUuid) =>
  fetch(
    `/api/cohort-applicants/${cohortUuid}/report/demographic?applicantDemographicReportTypeUuid=${reportTypeUuid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putApplicantCognitiveReport = (
  cohortUuid,
  applicantCognitiveReportTypeUuid,
  classificationUuid,
  rankBy
) =>
  fetch(
    `/api/cohort-applicants/${cohortUuid}/report/cognitive?applicantCognitiveReportTypeUuid=${applicantCognitiveReportTypeUuid}&classificationUuid=${classificationUuid}&rankBy=${rankBy}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putApplicantCognitiveSubjectReport = (
  cohortUuid,
  applicantCognitiveReportTypeUuid,
  classificationUuid
) =>
  fetch(
    `/api/cohort-applicants/${cohortUuid}/report/cognitive?applicantCognitiveReportTypeUuid=${applicantCognitiveReportTypeUuid}&classificationUuid=${classificationUuid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putApplicantNoncognitiveReport = (
  cohortUuid,
  applicantNoncognitiveReportTypeUuid,
  classificationUuid,
  examMilestoneUuid,
  examAptitudeUuid
) =>
  fetch(
    `/api/cohort-applicants/${cohortUuid}/report/non-cognitive?applicantNoncognitiveReportTypeUuid=${applicantNoncognitiveReportTypeUuid}&classificationUuid=${classificationUuid}&examMilestoneUuid=${examMilestoneUuid}&examAptitudeUuid=${examAptitudeUuid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putApplicantPhysicalSkillsReport = (
  cohortUuid,
  applicantPhysicalSkillsReportTypeUuid,
  classificationUuid,
  examMilestoneUuid,
  examAptitudeUuid
) =>
  fetch(
    `/api/cohort-applicants/${cohortUuid}/report/physical-skills?applicantPhysicalSkillsReportTypeUuid=${applicantPhysicalSkillsReportTypeUuid}&classificationUuid=${classificationUuid}&examMilestoneUuid=${examMilestoneUuid}&examAptitudeUuid=${examAptitudeUuid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putApplicantCommunicationReport = (
  cohortUuid,
  applicantCommunicationReportTypeUuid,
  classificationUuid,
  examMilestoneUuid,
  examAptitudeUuid
) =>
  fetch(
    `/api/cohort-applicants/${cohortUuid}/report/communication?applicantCommunicationReportTypeUuid=${applicantCommunicationReportTypeUuid}&classificationUuid=${classificationUuid}&examMilestoneUuid=${examMilestoneUuid}&examAptitudeUuid=${examAptitudeUuid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getApplicantDemographicReportTypes = () =>
  fetch(`/api/applicant-demographic-report-types`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getApplicantCognitiveReportTypes = () =>
  fetch(`/api/applicant-cognitive-report-types`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getApplicantNoncognitiveReportTypes = () =>
  fetch(`/api/applicant-noncognitive-report-types`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getApplicantPhysicalSkillsReportTypes = () =>
  fetch(`/api/applicant-physical-skills-report-types`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getApplicantCommunicationReportTypes = () =>
  fetch(`/api/applicant-communication-report-types`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getApplicantCognitiveReportTypes,
  getApplicantCommunicationReportTypes,
  getApplicantDemographicReportTypes,
  getApplicantNoncognitiveReportTypes,
  getApplicantPhysicalSkillsReportTypes,
  putApplicantCognitiveReport,
  putApplicantCognitiveSubjectReport,
  putApplicantCommunicationReport,
  putApplicantDemographicReport,
  putApplicantNoncognitiveReport,
  putApplicantPhysicalSkillsReport
};
