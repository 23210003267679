import fetch from '../helpers/fetch';

const getTranscript = (programUuid, cohortUuid, userUuid) =>
  fetch(
    `/api/applicant-transcript?programUuid=${programUuid}&cohortUuid=${cohortUuid}&userUuid=${userUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postTranscriptUpload = payload =>
  fetch(`/api/applicant-transcript/upload`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(payload)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postTranscript = payload =>
  fetch(`/api/applicant-transcript/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(payload)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putTranscript = payload =>
  fetch(`/api/applicant-transcript/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(payload)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteTranscript = (category, uuid) =>
  fetch(`/api/applicant-transcript/category/${category}/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteTranscripts = (
  category,
  source,
  userUuid,
  cohortUuid,
  programUuid
) =>
  fetch(
    `/api/applicant-transcripts/delete-all?category=${category}&source=${source}&userUuid=${userUuid}&cohortUuid=${cohortUuid}&programUuid=${programUuid}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postCloneTranscript = (
  fromCohortUuid,
  fromUserUuid,
  toCohortUuid,
  toUserUuid
) =>
  fetch(`/api/applicant-transcript/clone`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      fromCohortUuid,
      fromUserUuid,
      toCohortUuid,
      toUserUuid
    })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getTranscript,
  postTranscriptUpload,
  postTranscript,
  postCloneTranscript,
  putTranscript,
  deleteTranscript,
  deleteTranscripts
};
