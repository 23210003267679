import React from 'react';

import PropTypes from 'prop-types';
import ProgramSubject from './Cognitive/ProgramSubject';
import MilestoneSubject from './Cognitive/MilestoneSubject';
import AptitudeSkill from './Aptitude/AptitudeSkill';

export default function Classification({ category, source, programUuid }) {
  function classificationCategory() {
    if (category === 'Cognitive' && source === 'Program') {
      return (
        <ProgramSubject
          programUuid={programUuid}
          category={category}
          source={source}
        />
      );
    } else if (category === 'Cognitive' && source === 'Milestone') {
      return (
        <MilestoneSubject
          programUuid={programUuid}
          category={category}
          source={source}
        />
      );
    } else {
      return (
        <AptitudeSkill
          programUuid={programUuid}
          category={category}
          source={source}
        />
      );
    }
  }

  return <>{classificationCategory()}</>;
}
Classification.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  programUuid: PropTypes.string
};

Classification.defaultProps = {
  category: '',
  source: '',
  programUuid: undefined
};
