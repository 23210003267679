import React, { useCallback } from 'react';
import { Body1 } from '@xcomp/xcomp-design-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Rule from './Rule';
import FormSectionShowHide from '../../../../../Library/FormSectionShowHide';
import {
  deleteProcedureCode,
  deleteProcedureCodeAtom,
  deleteProcedureCodeModalAtom,
  deletingProcedureCodeAtom,
  editProcedureCodeAtom,
  getCollectionDetails,
  updateProcedureCodeAtom,
  updatingProcedureCodeAtom
} from '../state/editState';
import DeleteModal from '../../../../../Library/Modal/DeleteModal/DeleteModal';
import { CustomHeading } from '../../QuantitativeCollectionsList/components/QuantitativeCollectionRow';
import { SubmitButton } from '../../../../../Library/FormComponents';

const FormSectionShowHideSC = styled(FormSectionShowHide)`
  && {
    position: relative;
  }
`;

const ProcedureCodeList = ({ procedureCodes, procedureGroupUuid }) => {
  const { quantitativeSkillUuid } = useParams();
  const programUuid = useSelector(state => state.userState.selectedProgramUuid);

  const [selectedCode, setSelectedCode] = useAtom(deleteProcedureCode);
  const [isDeleteModalOpen, setDeleteModalOpen] = useAtom(
    deleteProcedureCodeModalAtom
  );
  const deleting = useAtomValue(deletingProcedureCodeAtom);
  const updating = useAtomValue(updatingProcedureCodeAtom);
  const getCollection = useSetAtom(getCollectionDetails);
  const deleteCode = useSetAtom(deleteProcedureCodeAtom);
  const setRule = useSetAtom(editProcedureCodeAtom);
  const updateCode = useSetAtom(updateProcedureCodeAtom);

  const handleProcedureCodeUpdate = useCallback(
    async procedureCode => {
      await updateCode(procedureCode);
      await getCollection({ quantitativeSkillUuid, programUuid });
    },
    [updateCode, getCollection, quantitativeSkillUuid, programUuid]
  );

  const handleChange = useCallback(
    (e, groupUuid, procedureCode) => {
      if (
        e.target.name.toLowerCase().includes('checked') &&
        !e.target.checked
      ) {
        const rule = e.target.name.toLowerCase().replace('checked', '');

        setRule({
          groupUuid,
          procedureCode: {
            ...procedureCode,
            [`${rule}Checked`]: false,
            [`${rule}Grade`]: false,
            [`${rule}Rank`]: false,
            [`${rule}Threshold`]: '0'
          }
        });
        return;
      }
      setRule({
        groupUuid,
        procedureCode: {
          ...procedureCode,
          [e.target.name]: e.target.value || e.target.checked
        }
      });
    },
    [setRule]
  );

  const handleDelete = useCallback(async () => {
    if (!selectedCode?.uuid) return;

    await deleteCode({ uuid: selectedCode?.uuid });
    await getCollection(quantitativeSkillUuid);
  }, [selectedCode?.uuid, deleteCode, getCollection, quantitativeSkillUuid]);

  if (!procedureCodes?.length)
    return (
      <Body1>There are no Procedure Codes assigned to this collection</Body1>
    );

  return (
    <>
      {isDeleteModalOpen[selectedCode?.uuid] && (
        <DeleteModal
          customHeading={<CustomHeading name={selectedCode?.name} />}
          warningMessage="This action cannot be undone."
          modalOpen={isDeleteModalOpen[selectedCode?.uuid]}
          handleModalClose={() => {
            setDeleteModalOpen({ [selectedCode?.uuid]: false });
          }}
          handleDelete={handleDelete}
        />
      )}
      {procedureCodes?.map(pc => (
        <FormSectionShowHideSC
          key={pc.uuid}
          headerText={`${pc.procedureCode.code} - ${pc.procedureCode.description}`}
          padding="0 30px"
          startOpen={false}
          className="hello"
          onDelete={() => {
            setSelectedCode({ name: pc.procedureCode.code, uuid: pc.uuid });
            setDeleteModalOpen({ [pc.uuid]: true });
          }}
          loading={deleting[pc.uuid]}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <div key={pc.uuid}>
              <Rule
                data={{
                  checked: pc.procedureChecked,
                  threshold: pc.procedureThreshold,
                  grade: pc.procedureGrade,
                  rank: pc.procedureRank
                }}
                label="Procedure"
                name="procedure"
                handleChange={e => handleChange(e, procedureGroupUuid, pc)}
              />
              <Rule
                data={{
                  checked: pc.rvusChecked,
                  threshold: pc.rvusThreshold,
                  grade: pc.rvusGrade,
                  rank: pc.rvusRank
                }}
                label="RVUs"
                name="rvus"
                handleChange={e => handleChange(e, procedureGroupUuid, pc)}
              />
              <Rule
                data={{
                  checked: pc.scoreChecked,
                  threshold: pc.scoreThreshold,
                  grade: pc.scoreGrade,
                  rank: pc.scoreRank
                }}
                label="Score"
                name="score"
                handleChange={e => handleChange(e, procedureGroupUuid, pc)}
              />
              <Rule
                data={{
                  checked: pc.unitsChecked,
                  threshold: pc.unitsThreshold,
                  grade: pc.unitsGrade,
                  rank: pc.unitsRank
                }}
                label="Units"
                name="units"
                handleChange={e => handleChange(e, procedureGroupUuid, pc)}
              />
              <Rule
                data={{
                  checked: pc.epasChecked,
                  threshold: pc.epasThreshold,
                  grade: pc.epasGrade,
                  rank: pc.epasRank
                }}
                label="EPAs"
                name="epas"
                handleChange={e => handleChange(e, procedureGroupUuid, pc)}
              />
            </div>
            <div style={{ alignSelf: 'self-end' }}>
              <SubmitButton
                onClick={() => handleProcedureCodeUpdate(pc)}
                disabled={updating[pc.uuid]}
              >
                {updating[pc.uuid] ? 'Updating...' : 'Update'}
              </SubmitButton>
            </div>
          </div>
        </FormSectionShowHideSC>
      ))}
    </>
  );
};

ProcedureCodeList.propTypes = {
  procedureCodes: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      procedureCode: PropTypes.shape({
        code: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
      }).isRequired,
      procedureChecked: PropTypes.bool.isRequired,
      procedureThreshold: PropTypes.string.isRequired,
      procedureGrade: PropTypes.bool.isRequired,
      procedureRank: PropTypes.bool.isRequired,
      scoreChecked: PropTypes.bool.isRequired,
      scoreThreshold: PropTypes.string.isRequired,
      scoreGrade: PropTypes.bool.isRequired,
      scoreRank: PropTypes.bool.isRequired,
      unitsChecked: PropTypes.bool.isRequired,
      unitsThreshold: PropTypes.string.isRequired,
      unitsGrade: PropTypes.bool.isRequired,
      unitsRank: PropTypes.bool.isRequired,
      epasChecked: PropTypes.bool.isRequired,
      epasThreshold: PropTypes.string.isRequired,
      epasGrade: PropTypes.bool.isRequired,
      epasRank: PropTypes.bool.isRequired,
      rvusChecked: PropTypes.bool.isRequired,
      rvusThreshold: PropTypes.string.isRequired,
      rvusGrade: PropTypes.bool.isRequired,
      rvusRank: PropTypes.bool.isRequired
    })
  ).isRequired,
  procedureGroupUuid: PropTypes.string.isRequired
};

export default ProcedureCodeList;
