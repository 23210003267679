import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const topicSelector = createSelector(
  state => state,
  (_, topicUuid) => topicUuid,
  (redux, topicUuid) => {
    const topicsSelectorORM = ormCreateSelector(orm.Topic);

    const topic = topicsSelectorORM(redux, topicUuid) || {};

    return topic;
  }
);
