import { Model, attr } from 'redux-orm';
import {
  GET_PROGRAM_CIPS_SUCCESS,
  GET_SOURCE_PROGRAM_CODES_BY_TITLE_SUCCESS,
  GET_CREDENTIALS_SUCCESS
} from '../actions-type';

class ProgramCip extends Model {
  static reducer(action, ProgramCip, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_PROGRAM_CIPS_SUCCESS: {
        const programCips = [...payload.programCips];
        programCips.forEach(programCip => ProgramCip.upsert(programCip));
        break;
      }
      case GET_SOURCE_PROGRAM_CODES_BY_TITLE_SUCCESS: {
        const programCodes = [...payload.programCodes];
        programCodes.forEach(programCode => {
          const { program_discipline } = programCode;

          const { program_cipcode } = program_discipline;

          ProgramCip.upsert(program_cipcode);
        });
        break;
      }
      case GET_CREDENTIALS_SUCCESS: {
        const programCips = [...payload.programCips];
        programCips.forEach(programCip => ProgramCip.upsert(programCip));
        break;
      }
      default:
        return session;
    }
  }
}

ProgramCip.fields = {
  uuid: attr(),
  title: attr(),
  code: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

ProgramCip.modelName = 'ProgramCip';
ProgramCip.options = {
  idAttribute: 'uuid'
};

export default ProgramCip;
