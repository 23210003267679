import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid } from '@material-ui/core';

import { ContainerContent, ElevateLow } from '../../Library/Layout';
import { convertNullToString } from '../../../helpers/utilities';
import { validateSelectField } from '../../../helpers/validation/validateGeneric';
import FormSelectError from '../../Library/FormSelectError';
import DescriptionDisplay from '../../Library/DescriptionEditor/DescriptionDisplay';

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
`;

const Group = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Label = styled.div`
  width: 30%;
`;

const Value = styled.div`
  width: 70%;
`;

const Layout = styled.div`
  margin-top: 50px;
`;

const Description = styled.div`
  word-break: break-word;
`;

const CourseForm = ({ ...props }) => {
  const {
    departmentUuid,
    courseMasterUuid,
    academicYearUuid,
    termUuid,
    departments,
    academicYears,
    terms,
    hasErrors,
    handleChange,
    coursesMaster,
    selectedSchool,
    selectedCourseMaster,
    selectedDepartment
  } = props;

  const departmentOptions = _.map(departments, d => ({
    value: d.uuid,
    label: d.name
  }));

  const academicYearOptions = _.map(academicYears, ay => {
    const startYear = moment(ay.startDate).format('YYYY');
    const endYear = moment(ay.endDate).format('YYYY');
    return {
      value: ay.uuid,
      label: `${startYear}-${endYear}`
    };
  });

  const termOptions = _.map(terms, t => ({
    value: t.uuid,
    label: t.period
  }));

  const courseOptions = _.map(coursesMaster, course => ({
    value: course.uuid,
    label: `${course.courseNumber}-${course.title}`
  }));

  return (
    <div>
      <ElevateLow>
        <ContainerContent>
          <form noValidate autoComplete="off">
            <Grid container spacing={4}>
              <Grid item sm={3} xs={12}>
                <FormSelectError
                  name="departmentUuid"
                  label="Department*"
                  value={convertNullToString(departmentUuid)}
                  options={departmentOptions}
                  hasErrors={hasErrors}
                  handleChange={handleChange}
                  handleValidation={validateSelectField}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormSelectError
                  name="academicYearUuid"
                  label="Academic Year*"
                  value={convertNullToString(academicYearUuid)}
                  hasErrors={hasErrors}
                  options={academicYearOptions}
                  handleChange={handleChange}
                  handleValidation={validateSelectField}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormSelectError
                  name="termUuid"
                  label="Term*"
                  value={convertNullToString(termUuid)}
                  options={termOptions}
                  hasErrors={hasErrors}
                  handleChange={handleChange}
                  handleValidation={validateSelectField}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormSelectError
                  name="courseMasterUuid"
                  label="Courses*"
                  value={convertNullToString(courseMasterUuid)}
                  options={courseOptions}
                  hasErrors={hasErrors}
                  handleChange={handleChange}
                  handleValidation={validateSelectField}
                />
              </Grid>
            </Grid>
          </form>
        </ContainerContent>
      </ElevateLow>
      {selectedCourseMaster && (
        <Layout>
          <ElevateLow>
            <ContainerContent>
              <Info>
                <Grid container>
                  <Grid item sm={6} xs={12}>
                    <Group>
                      <Label>School</Label>
                      <Value>{selectedSchool}</Value>
                    </Group>
                    <Group>
                      <Label>Department</Label>
                      <Value>
                        {selectedDepartment && selectedDepartment.name}
                      </Value>
                    </Group>
                    <Group>
                      <Label>Hours</Label>
                      <Value>
                        {selectedCourseMaster && selectedCourseMaster.hours}
                      </Value>
                    </Group>
                    <Group>
                      <Label>Course #</Label>
                      <Value>
                        {selectedCourseMaster &&
                          selectedCourseMaster.courseNumber}
                      </Value>
                    </Group>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    {selectedCourseMaster?.description && (
                      <Description>
                        <DescriptionDisplay
                          description={selectedCourseMaster?.description}
                        />
                      </Description>
                    )}
                  </Grid>
                </Grid>
              </Info>
            </ContainerContent>
          </ElevateLow>
        </Layout>
      )}
    </div>
  );
};

CourseForm.propTypes = {
  courseMasterUuid: PropTypes.string,
  departmentUuid: PropTypes.string,
  academicYearUuid: PropTypes.string,
  termUuid: PropTypes.string,
  description: PropTypes.string,
  courseId: PropTypes.string,
  courseNumber: PropTypes.string,
  title: PropTypes.string,
  hours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleChange: PropTypes.func,
  hasErrors: PropTypes.bool,
  departments: PropTypes.arrayOf(PropTypes.object),
  terms: PropTypes.arrayOf(PropTypes.object),
  academicYears: PropTypes.arrayOf(PropTypes.object),
  coursesMaster: PropTypes.arrayOf(PropTypes.object),
  selectedSchool: PropTypes.string,
  selectedCourseMaster: PropTypes.object,
  selectedDepartment: PropTypes.object
};

CourseForm.defaultProps = {
  courseMasterUuid: '',
  departmentUuid: '',
  academicYearUuid: '',
  termUuid: '',
  description: '',
  courseId: '',
  courseNumber: '',
  title: '',
  hours: 0.0,
  handleChange: undefined,
  hasErrors: false,
  departments: [],
  academicYears: [],
  terms: [],
  coursesMaster: [],
  selectedSchool: '',
  selectedCourseMaster: undefined,
  selectedDepartment: {}
};

export default CourseForm;
