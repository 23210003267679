import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';
import { roles } from '../../helpers/constants';

export const userRolesInstitution = createSelector(
  state => state,
  state => state.userState.activeUserUuid,
  (redux, activeUserUuid) => {
    const userInstitutionsSelectorORM = ormCreateSelector(orm.UserInstitution);
    const userInstitutionsStateORM = userInstitutionsSelectorORM(redux);

    const userInstitutions = userInstitutionsStateORM.filter(
      ui =>
        ui.userUuid === activeUserUuid &&
        ui.role === roles.institutionAdmin &&
        ui.status === 'active'
    );

    return {
      role: roles.institutionAdmin,
      userInstitutions
    };
  }
);

export const userRolesSchoolAdmin = createSelector(
  state => state,
  state => state.userState.activeUserUuid,
  (redux, activeUserUuid) => {
    const userSchoolsSelectorORM = ormCreateSelector(orm.UserSchool);
    const userSchoolsStateORM = userSchoolsSelectorORM(redux);

    const userSchools = userSchoolsStateORM.filter(
      userSchool =>
        userSchool.userUuid === activeUserUuid &&
        userSchool.role === roles.schoolAdmin &&
        userSchool.status === 'active'
    );

    return {
      role: roles.schoolAdmin,
      userSchools
    };
  }
);

export const userRolesDepartmentFaculty = createSelector(
  state => state,
  state => state.userState.activeUserUuid,
  (redux, activeUserUuid) => {
    const userDepartmentsSelectorORM = ormCreateSelector(orm.UserDepartment);
    const userDepartmentsStateORM = userDepartmentsSelectorORM(redux);

    const userDepartments = userDepartmentsStateORM.filter(
      userDepartment =>
        userDepartment.userUuid === activeUserUuid &&
        userDepartment.role === roles.departmentFaculty &&
        userDepartment.status === 'active'
    );

    return {
      role: roles.departmentFaculty,
      userDepartments
    };
  }
);

export const userRolesDepartmentStaff = createSelector(
  state => state,
  state => state.userState.activeUserUuid,
  (redux, activeUserUuid) => {
    const userDepartmentsSelectorORM = ormCreateSelector(orm.UserDepartment);
    const userDepartmentsStateORM = userDepartmentsSelectorORM(redux);

    const userDepartments = userDepartmentsStateORM.filter(
      userDepartment =>
        userDepartment.userUuid === activeUserUuid &&
        userDepartment.role === roles.departmentStaff &&
        userDepartment.status === 'active'
    );

    return {
      role: roles.departmentStaff,
      userDepartments
    };
  }
);

export const userRolesProgramAdmin = createSelector(
  state => state,
  state => state.userState.activeUserUuid,
  (redux, activeUserUuid) => {
    const userProgramsSelectorORM = ormCreateSelector(orm.UserProgram);
    const userProgramsStateORM = userProgramsSelectorORM(redux);

    const userPrograms = userProgramsStateORM.filter(
      userProgram =>
        userProgram.userUuid === activeUserUuid &&
        userProgram.role === roles.programAdmin &&
        userProgram.status === 'active'
    );

    return {
      role: roles.programAdmin,
      userPrograms
    };
  }
);

export const userRolesProgramFaculty = createSelector(
  state => state,
  state => state.userState.activeUserUuid,
  (redux, activeUserUuid) => {
    const userProgramsSelectorORM = ormCreateSelector(orm.UserProgram);
    const userProgramsStateORM = userProgramsSelectorORM(redux);

    const userPrograms = userProgramsStateORM.filter(
      userProgram =>
        userProgram.userUuid === activeUserUuid &&
        userProgram.role === roles.programFaculty &&
        userProgram.status === 'active'
    );

    return {
      role: roles.programFaculty,
      userPrograms
    };
  }
);

export const userRolesProgramStaff = createSelector(
  state => state,
  state => state.userState.activeUserUuid,
  (redux, activeUserUuid) => {
    const userProgramsSelectorORM = ormCreateSelector(orm.UserProgram);
    const userProgramsStateORM = userProgramsSelectorORM(redux);

    const userPrograms = userProgramsStateORM.filter(
      userProgram =>
        userProgram.userUuid === activeUserUuid &&
        userProgram.role === roles.programStaff &&
        userProgram.status === 'active'
    );

    return {
      role: roles.programStaff,
      userPrograms
    };
  }
);
