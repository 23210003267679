import { Model, attr, fk } from 'redux-orm';
import _ from 'lodash';
import {
  GET_COHORT_ASSESSMENT_SCORES_SUCCESS,
  GET_SCORES_SUCCESS,
  POST_SCORE_SUCCESS,
  PUT_SCORE_SUCCESS,
  GET_ASSESSMENT_GRADES_SUCCESS
} from '../actions-type';

class Microcompetency extends Model {
  static reducer(action, Microcompetency, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_COHORT_ASSESSMENT_SCORES_SUCCESS:
      case GET_SCORES_SUCCESS: {
        const microcompetencies = [...payload.microcompetenciesORM];
        microcompetencies.forEach(micro => Microcompetency.upsert(micro));
        break;
      }
      case POST_SCORE_SUCCESS: {
        const { microcompetency } = payload;
        const add = _.omit(microcompetency, ['subtopic']);
        Microcompetency.upsert(add);
        break;
      }
      case PUT_SCORE_SUCCESS: {
        const { microcompetency } = payload;
        const add = _.omit(microcompetency, ['subtopic']);
        Microcompetency.upsert(add);
        break;
      }
      case GET_ASSESSMENT_GRADES_SUCCESS: {
        const microcompetencies = [...payload.microcompetenciesORM];
        microcompetencies.forEach(micro => Microcompetency.upsert(micro));
        break;
      }
      default:
        return session;
    }
  }
}

Microcompetency.fields = {
  uuid: attr(),
  title: attr(),
  code: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  subtopicUuid: fk('Subtopic', 'microcompetencies')
};

Microcompetency.modelName = 'Microcompetency';

Microcompetency.options = {
  idAttribute: 'uuid'
};

export default Microcompetency;
