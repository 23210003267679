import React from 'react';
import PropTypes from 'prop-types';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import {
  ContainerActions,
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader
} from '../Library/Layout';
import Header from '../Library/Header';
import { PanelLabel } from '../Library/DashboardSC';
import NoItemsMessage from '../Library/NoItemsMessage';
import ProgramAssessmentCollectionContainer from './ProgramAssessmentCollection/ProgramAssessmentCollectionContainer';
import NewProgramAssessmentCollectionContainer from './ProgramAssessmentCollection/NewProgramAssessmentCollection/NewProgramAssessmentCollectionContainer';
import { sortByProperty } from '../../helpers/utilities';

const ProgramAssessmentsManagement = ({
  programUuid,
  program,
  programAssessmentCollections,
  isNewFormOpen,
  setNewFormOpen
}) => {
  const openNewForm = () => setNewFormOpen(true);
  const clearNewForm = () => setNewFormOpen(false);

  const noCollectionsMessage =
    'There are currently no program assessment collections in this program.';

  return (
    <ContainerResize>
      <Header
        title={program?.programName}
        subtitle="Manage, add and remove program assessments"
        backButtonLocation="/"
      />
      <ContainerActions paddingTop="100px">
        <ButtonPrimary onClick={openNewForm}>
          Create Assessment Collection
        </ButtonPrimary>
      </ContainerActions>
      <ContainerPage paddingTop="48px">
        {programAssessmentCollections?.length > 0 || isNewFormOpen ? (
          <>
            <Panel>
              <PanelHeader paddingLeft="24px">
                <PanelLabel marginLeft="42px">Collection Title</PanelLabel>
              </PanelHeader>
              {isNewFormOpen && (
                <NewProgramAssessmentCollectionContainer
                  clearNewForm={clearNewForm}
                  programUuid={programUuid}
                />
              )}
              {programAssessmentCollections?.length > 0 &&
                programAssessmentCollections
                  .sort((a, b) => sortByProperty(a, b, 'title'))
                  .map(collection => (
                    <ProgramAssessmentCollectionContainer
                      key={collection.uuid}
                      programAssessmentCollectionUuid={collection.uuid}
                      programUuid={programUuid}
                    />
                  ))}
            </Panel>
          </>
        ) : (
          <Panel>
            <NoItemsMessage message={noCollectionsMessage} />
          </Panel>
        )}
      </ContainerPage>
    </ContainerResize>
  );
};

ProgramAssessmentsManagement.propTypes = {
  programUuid: PropTypes.string,
  program: PropTypes.object,
  programAssessmentCollections: PropTypes.arrayOf(PropTypes.object),
  isNewFormOpen: PropTypes.bool,
  setNewFormOpen: PropTypes.func
};

ProgramAssessmentsManagement.defaultProps = {
  programUuid: '',
  program: {},
  programAssessmentCollections: [],
  isNewFormOpen: false,
  setNewFormOpen: undefined
};

export default ProgramAssessmentsManagement;
