import {
  FILTER_COURSE_MASTER_DEPARTMENT_UUID,
  FILTER_COURSE_DEPARTMENT_UUID,
  FILTER_COURSE_COURSE_MASTER_UUID,
  FILTER_COURSE_ACADEMIC_YEAR_UUID,
  FILTER_COURSE_TERM_UUID,
  OPEN_COURSE_ACCORDION,
  CLOSE_COURSE_ACCORDION,
  OPEN_SECTION_ACCORDION,
  CLOSE_SECTION_ACCORDION
  //   FILTER_COMPETENCY_GRID_COLLECTIONS,
  //   FILTER_COMPETENCY_GRID_ASSESSMENT_ASSOCIATION,
  //   FILTER_COMPETENCY_GRID_ASSESSMENT_TECHNIQUE,
  //   FILTER_COMPETENCY_GRID_ASSESSMENT_TYPE
} from '../actions-type';

const doSetDepartmentFilterForCourseMaster = departmentUuid => ({
  type: FILTER_COURSE_MASTER_DEPARTMENT_UUID,
  payload: {
    departmentUuid
  }
});

const doSetDepartmentFilterForCourse = departmentUuid => ({
  type: FILTER_COURSE_DEPARTMENT_UUID,
  payload: {
    departmentUuid
  }
});

const doSetCourseMasterFilterForCourse = courseMasterUuid => ({
  type: FILTER_COURSE_COURSE_MASTER_UUID,
  payload: {
    courseMasterUuid
  }
});

const doSetAcademicYearFilterForCourse = academicYearUuid => ({
  type: FILTER_COURSE_ACADEMIC_YEAR_UUID,
  payload: {
    academicYearUuid
  }
});

const doSetTermFilterForCourse = termUuid => ({
  type: FILTER_COURSE_TERM_UUID,
  payload: {
    termUuid
  }
});

const doSetOpenCourseAccordion = courseUuid => ({
  type: OPEN_COURSE_ACCORDION,
  payload: {
    courseUuid
  }
});

const doSetClosedCourseAccordion = courseUuid => ({
  type: CLOSE_COURSE_ACCORDION,
  payload: {
    courseUuid
  }
});

const doSetOpenSectionAccordion = sectionUuid => ({
  type: OPEN_SECTION_ACCORDION,
  payload: {
    sectionUuid
  }
});

const doSetClosedSectionAccordion = sectionUuid => ({
  type: CLOSE_SECTION_ACCORDION,
  payload: {
    sectionUuid
  }
});

// const doSetCompetencyGridFilterForAssessmentAssociation = assessmentAssociationUuid => ({
//   type: FILTER_COMPETENCY_GRID_ASSESSMENT_ASSOCIATION,
//   payload: {
//     assessmentAssociationUuid
//   }
// });

// const doSetCompetencyGridFilterForAssessmentTechnique = assessmentTechniqueUuid => ({
//   type: FILTER_COMPETENCY_GRID_ASSESSMENT_TECHNIQUE,
//   payload: {
//     assessmentTechniqueUuid
//   }
// });

// const doSetCompetencyGridFilterForAssessmentType = assessmentTypeUuid => ({
//   type: FILTER_COMPETENCY_GRID_ASSESSMENT_TYPE,
//   payload: {
//     assessmentTypeUuid
//   }
// });

// const doSetCompetencyGridFilterForCollection = competencyCollectionUuid => ({
//   type: FILTER_COMPETENCY_GRID_COLLECTIONS,
//   payload: {
//     competencyCollectionUuid
//   }
// });

export {
  doSetDepartmentFilterForCourseMaster,
  doSetDepartmentFilterForCourse,
  doSetCourseMasterFilterForCourse,
  doSetAcademicYearFilterForCourse,
  doSetTermFilterForCourse,
  doSetOpenCourseAccordion,
  doSetClosedCourseAccordion,
  doSetOpenSectionAccordion,
  doSetClosedSectionAccordion
  //   doSetCompetencyGridFilterForAssessmentAssociation,
  //   doSetCompetencyGridFilterForAssessmentTechnique,
  //   doSetCompetencyGridFilterForAssessmentType,
  //   doSetCompetencyGridFilterForCollection
};
