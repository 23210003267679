import { call, put, takeEvery } from 'redux-saga/effects';

import {
  getLoverbsBySchool,
  getLoverbsByEducationLevels
} from '../../api/loverbApi';
import {
  doGetLoverbsSuccess,
  doGetLoverbsError,
  doGetLoverbsByEducationLevelsSuccess,
  doGetLoverbsByEducationLevelsError
} from '../actions/loverbActions';

import { GET_LOVERBS, GET_LOVERBS_BY_EDUCATION_LEVELS } from '../actions-type';

function* handleFetchLoverbs(action) {
  const { schoolUuid } = action.payload;
  try {
    const result = yield call(getLoverbsBySchool, schoolUuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetLoverbsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetLoverbsError(error));
  }
}

function* handleGetLoverbsByEducationLevels(action) {
  try {
    const result = yield call(getLoverbsByEducationLevels, action.payload);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetLoverbsByEducationLevelsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetLoverbsByEducationLevelsError(error));
  }
}

const loverb = [
  takeEvery(GET_LOVERBS, handleFetchLoverbs),
  takeEvery(GET_LOVERBS_BY_EDUCATION_LEVELS, handleGetLoverbsByEducationLevels)
];

export { loverb };
