import {
  PUT_INSTITUTION,
  PUT_INSTITUTION_SUCCESS,
  PUT_INSTITUTION_ERROR
} from '../actions-type';

const doPutInstitution = (institution, picture) => ({
  type: PUT_INSTITUTION,
  payload: {
    institution,
    picture
  }
});

const doPutInstitutionSuccess = result => ({
  type: PUT_INSTITUTION_SUCCESS,
  payload: result
});

const doPutInstitutionError = error => ({
  type: PUT_INSTITUTION_ERROR,
  payload: error
});

export { doPutInstitution, doPutInstitutionSuccess, doPutInstitutionError };
