import React from 'react';
import PropTypes from 'prop-types';
import { RemoveIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { Checkbox, Chip, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const FilterAutocomplete = styled(({ isHidden, offsetPopper, ...rest }) => (
  <Autocomplete {...rest} />
))`
  transform: ${props => (props.isHidden ? 'scale(0, 1)' : 'scale(1)')};
  width: ${props => (props.isHidden ? '0px' : '300px')};
  transform-origin: top left;
  transition:
    transform 0.5s ease-in-out,
    width 0.5s ease-in-out;
  margin-right: ${props => (props.isHidden ? '0px' : '10px')} !important;

  & + .MuiAutocomplete-popper {
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 0.2s ease-in-out;
    top: 50px;
    margin-left: ${props => (props.offsetPopper ? props.offsetPopper : 0)}px;
  }
`;

const FilterDropdown = ({
  setFilteredValues,
  filteredValues,
  open,
  onClose,
  options,
  label,
  offsetPopper
}) => {
  const handleChange = (e, v, r, d) => {
    if (r === 'clear') {
      // if the clear icon was clicked, remove everything
      setFilteredValues([]);
    } else if (d.option.fullName === 'All') {
      // if the All options was clicked
      if (r === 'select-option') {
        // if checked select everything
        setFilteredValues(options);
      } else if (r === 'remove-option') {
        // if unchecked remove everything
        setFilteredValues([]);
      }
    } else if (r === 'select-option') {
      // if a normal option was checked
      if (v.length === options.length - 1) {
        // if all the normal options are checked now, check All aswell
        setFilteredValues(options);
      } else {
        // if not just do the normal behaviour
        setFilteredValues(v);
      }
    } else if (r === 'remove-option') {
      // if a normal option was unchecked
      if (v.length === options.length - 1) {
        // if All was also checked, uncheck it aswell
        setFilteredValues(v.filter(option => option.fullName !== 'All'));
      } else {
        // if not do the normal behaviour
        setFilteredValues(v);
      }
    }
  };

  return (
    <FilterAutocomplete
      size="small"
      isHidden={!open}
      offsetPopper={offsetPopper}
      disablePortal={true}
      open={open}
      onOpen={() => {}}
      onClose={onClose}
      multiple
      limitTags={1}
      disableCloseOnSelect
      options={options}
      value={filteredValues}
      getOptionLabel={option => option.fullName}
      getOptionSelected={(o, v) => o.uuid === v.uuid}
      groupBy={option => (option.fullName === 'All' ? 'All' : label)}
      onChange={handleChange}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
            color="primary"
          />
          <Typography
            style={option?.type === 'collection' ? { fontWeight: 'bold' } : {}}
            variant="caption"
          >
            {option.fullName}
          </Typography>
        </>
      )}
      renderInput={params => (
        <TextField
          {...params}
          style={{ width: 300 }}
          variant="outlined"
          label={label}
          placeholder="Name..."
        />
      )}
      renderTags={values => (
        <Chip
          id={`filter-autocomplete-${label}`}
          color="primary"
          label={`${
            values.length === options.length ? values.length - 1 : values.length
          } ${label}`}
          onDelete={() => setFilteredValues([])}
          deleteIcon={<RemoveIcon />}
        />
      )}
    />
  );
};

FilterDropdown.propTypes = {
  options: PropTypes.array,
  filteredValues: PropTypes.array,
  setFilteredValues: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  offsetPopper: PropTypes.number
};

FilterDropdown.defaultProps = {
  filteredValues: [],
  options: [],
  offsetPopper: 0
};

export default FilterDropdown;
