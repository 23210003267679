import fetch from '../helpers/fetch';

const getUserActive = () =>
  fetch(`/api/load-admin`, {
    method: 'GET',
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getUserActive };
