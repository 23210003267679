import {
  CLEAR_CREATED_ASSESSMENT,
  CLEAR_CREATED_ENCOUNTER,
  CLEAR_UPDATED_ASSESSMENT,
  CLEAR_UPDATED_ENCOUNTER,
  GET_SYLLABUS,
  GET_SYLLABUS_COURSE,
  GET_SYLLABUS_COURSE_ERROR,
  GET_SYLLABUS_COURSE_SUCCESS,
  GET_SYLLABUS_ERROR,
  GET_SYLLABUS_PART,
  GET_SYLLABUS_PART_ERROR,
  GET_SYLLABUS_PART_SUCCESS,
  GET_SYLLABUS_SUCCESS,
  SET_SELECTED_SYLLABUS
} from '../actions-type';

const doSetSyllabus = (courseUuid, sectionUuid, partUuid) => ({
  type: SET_SELECTED_SYLLABUS,
  payload: {
    partUuid,
    sectionUuid,
    courseUuid
  }
});

const doGetSyllabus = (courseUuid, sectionUuid, partUuid) => ({
  type: GET_SYLLABUS,
  payload: {
    courseUuid,
    sectionUuid,
    partUuid
  }
});

const doGetSyllabusSuccess = result => ({
  type: GET_SYLLABUS_SUCCESS,
  payload: result
});

const doGetSyllabusError = error => ({
  type: GET_SYLLABUS_ERROR,
  payload: error
});

const doClearCreatedEncounter = () => ({
  type: CLEAR_CREATED_ENCOUNTER
});

const doClearUpdateEncounter = () => ({
  type: CLEAR_UPDATED_ENCOUNTER
});

const doClearCreatedAssessment = () => ({
  type: CLEAR_CREATED_ASSESSMENT
});

const doClearUpdateAssessment = () => ({
  type: CLEAR_UPDATED_ASSESSMENT
});

const doGetSyllabusCourse = courseUuid => ({
  type: GET_SYLLABUS_COURSE,
  payload: {
    courseUuid
  }
});

const doGetSyllabusCourseSuccess = result => ({
  type: GET_SYLLABUS_COURSE_SUCCESS,
  payload: result
});

const doGetSyllabusCourseError = error => ({
  type: GET_SYLLABUS_COURSE_ERROR,
  payload: error
});

const doGetSyllabusPart = partUuid => ({
  type: GET_SYLLABUS_PART,
  payload: {
    partUuid
  }
});

const doGetSyllabusPartSuccess = result => ({
  type: GET_SYLLABUS_PART_SUCCESS,
  payload: result
});

const doGetSyllabusPartError = error => ({
  type: GET_SYLLABUS_PART_ERROR,
  payload: error
});

export {
  doClearCreatedAssessment,
  doClearCreatedEncounter,
  doClearUpdateAssessment,
  doClearUpdateEncounter,
  doGetSyllabus,
  doGetSyllabusCourse,
  doGetSyllabusCourseError,
  doGetSyllabusCourseSuccess,
  doGetSyllabusError,
  doGetSyllabusPart,
  doGetSyllabusPartError,
  doGetSyllabusPartSuccess,
  doGetSyllabusSuccess,
  doSetSyllabus
};
