import { Model, attr, oneToOne } from 'redux-orm';
import { GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS } from '../actions-type';

class AssessmentRubric extends Model {
  static reducer(action, AssessmentRubric, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS: {
        const { assessmentRubrics } = payload;
        assessmentRubrics.forEach(assessmentRubric => {
          AssessmentRubric.upsert(assessmentRubric);
        });
        break;
      }
      default:
        return session;
    }
  }
}

AssessmentRubric.fields = {
  uuid: attr(),
  syllabus_assessment_uuid: oneToOne({
    to: 'Assessment',
    as: 'assessment',
    relatedName: 'assessmentRubric'
  }),
  check_step_type: attr(),
  createdAt: attr(),
  dynamic_rubric_uuid: attr(),
  email_on_completion_faculty: attr(),
  email_on_completion_graders: attr(),
  email_on_completion_students: attr(),
  email_on_fail_faculty: attr(),
  email_on_fail_graders: attr(),
  email_on_fail_students: attr(),
  faculty_notification_list: attr(),
  max_attempts_per_block: attr(),
  max_remediations_per_block: attr(),
  multiblock_option: attr(),
  notes: attr(),
  number_of_blocks: attr(),
  participation_options: attr(),
  participation_type: attr(),
  points_agreement: attr(),
  points_equivocal: attr(),
  points_overgrade: attr(),
  points_perfect_agreement: attr(),
  points_significant_overgrade: attr(),
  points_significant_undergrade: attr(),
  points_slight_overgrade: attr(),
  points_slight_undergrade: attr(),
  points_undergrade: attr(),
  rubric_type: attr(),
  school_uuid: attr(),
  self_assessment: attr(),
  self_assessment_microcompetency_code: attr(),
  self_assessment_microcompetency_title: attr(),
  self_assessment_microcompetency_uuid: attr(),
  status: attr(),
  text_on_completion_faculty: attr(),
  text_on_completion_graders: attr(),
  text_on_completion_students: attr(),
  text_on_fail_faculty: attr(),
  text_on_fail_graders: attr(),
  text_on_fail_students: attr(),
  timed_check_step: attr(),
  updatedAt: attr()
};

AssessmentRubric.modelName = 'AssessmentRubric';
AssessmentRubric.options = {
  idAttribute: 'syllabus_assessment_uuid'
};

export default AssessmentRubric;
