import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormControlLabel, Switch } from '@material-ui/core';

import { PanelHeader } from '../../../../Library/Layout';

const TableHeaderTitle = styled.div`
  font-size: 18px;
`;

const PanelHeaderSC = styled(PanelHeader)`
  && {
    display: flex;
    height: auto;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
    .MuiFormControlLabel-root span {
      font-size: 18px;
    }
  }
`;

const ReportTableHeaderBar = ({ headerText, isExpanded, setIsExpanded }) => (
  <PanelHeaderSC>
    <TableHeaderTitle>{headerText}</TableHeaderTitle>
    <FormControlLabel
      control={
        <Switch
          checked={isExpanded}
          onChange={() => setIsExpanded(!isExpanded)}
          color="primary"
        />
      }
      label="Expand Collections"
      labelPlacement="start"
    />
  </PanelHeaderSC>
);

ReportTableHeaderBar.propTypes = {
  headerText: PropTypes.string,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func
};

ReportTableHeaderBar.defaultProps = {
  isExpanded: false,
  headerText: 'Part Performance Report by Assessment Collection',
  setIsExpanded: undefined
};

export default ReportTableHeaderBar;
