import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Body1 } from '@xcomp/xcomp-design-library';
import { DeleteIcon, EditIcon } from '@xcomp/xcomp-design-library/dist/icons';

import AccordionWithIcons from '../../../../../../Library/AccordionRow/AccordionWithIcons/AccordionWithIcons';
import { deleteCollectionModalAtom } from '../../../../state/collectionState';
import CollectionRow from './components/CollectionRow';
import ProcedureCodeRow from './components/ProcedureCodeRow';

const CollectionLayout = styled.div`
  padding-left: 82px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  p {
    margin: 0;
  }
`;
const GroupLayout = styled.div`
  padding: 20px 50px;
  display: grid;
  //flex-wrap: wrap;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  p {
    margin: 0;
  }
`;

const ProcedureCodeGroupAccordion = ({ quantitativeSkillCollection }) => {
  const history = useHistory();
  const [, setIsDeleteModalOpen] = useAtom(deleteCollectionModalAtom);

  const navigateEditOnClick = useCallback(async () => {
    history.push(
      `/program-management/quantitative-skill/${quantitativeSkillCollection.uuid}/edit`
    );
  }, [history, quantitativeSkillCollection.uuid]);

  const competencyCollectionActions = useMemo(
    () => [
      {
        key: 'edit',
        icon: <EditIcon onClick={navigateEditOnClick} />,
        tooltip: 'Edit'
      },
      {
        key: 'delete',
        icon: (
          <DeleteIcon
            onClick={() => {
              setIsDeleteModalOpen({
                [quantitativeSkillCollection.uuid]: true
              });
            }}
          />
        ),
        tooltip: 'Delete'
      }
    ],
    [
      navigateEditOnClick,
      setIsDeleteModalOpen,
      quantitativeSkillCollection.uuid
    ]
  );

  const competencyCollectionNameString = useMemo(() => {
    if (!quantitativeSkillCollection) return '';

    return quantitativeSkillCollection.is_default_collection
      ? `Default: ${quantitativeSkillCollection.name}`
      : quantitativeSkillCollection.name;
  }, [quantitativeSkillCollection]);

  if (!quantitativeSkillCollection?.procedure_code_groups?.length) {
    return (
      <AccordionWithIcons
        header={competencyCollectionNameString}
        actions={competencyCollectionActions}
      >
        <Body1>There are no Procedure Codes assigned to this collection</Body1>
      </AccordionWithIcons>
    );
  }

  return (
    <AccordionWithIcons
      header={competencyCollectionNameString}
      actions={competencyCollectionActions}
    >
      <CollectionLayout>
        {quantitativeSkillCollection?.procedure_code_groups?.map(
          (group, index) => (
            <AccordionWithIcons
              key={group.uuid}
              header={<CollectionRow index={index} group={group} />}
              actions={[]}
            >
              <GroupLayout>
                {group.quantitative_skills_procedure_codes.map(
                  (procedureCode, idx) => (
                    <ProcedureCodeRow
                      key={procedureCode.uuid}
                      index={idx}
                      procedureCode={procedureCode}
                    />
                  )
                )}
              </GroupLayout>
            </AccordionWithIcons>
          )
        )}
      </CollectionLayout>
    </AccordionWithIcons>
  );
};

ProcedureCodeGroupAccordion.propTypes = {
  quantitativeSkillCollection: PropTypes.object.isRequired
};

export default ProcedureCodeGroupAccordion;
