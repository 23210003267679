const initialGradingTechniqueState = {
  uuid: null,
  gradingTechnique: null,
  maximumRevusInput: null,
  maximumMethodCalculated: true,
  calculatedHighScore: null,
  calculatedLowScore: null,
  lowScoreInput: null,
  lowScoreCalculated: true,
  curvedHighGrade: null,
  curvedFailingGrade: null,
  scaledHighScore: null,
  scaledLowScore: null,
  selectedRadio: null,
  showValidationErrors: false
};

const gradingTechniqueReducer = (state, action) => {
  switch (action.type) {
    case 'setExistingGradingTechnique': {
      const {
        uuid,
        gradingTechnique,
        maximumRevusInput,
        maximumMethodCalculated,
        calculatedHighScore,
        lowScoreInput,
        lowScoreCalculated,
        calculatedLowScore,
        curvedHighGrade,
        curvedFailingGrade,
        scaledHighScore,
        scaledLowScore
      } = action.payload;

      return {
        ...state,
        uuid,
        curvedHighGrade,
        curvedFailingGrade,
        gradingTechnique,
        lowScoreInput,
        lowScoreCalculated,
        calculatedLowScore,
        maximumRevusInput,
        maximumMethodCalculated,
        calculatedHighScore,
        scaledHighScore,
        scaledLowScore,
        selectedRadio: gradingTechnique
      };
    }
    case 'setPointsOnly':
      return {
        ...state,
        selectedRadio: action.payload.selectedRadio,
        gradingTechnique: action.payload.selectedRadio
      };
    case 'setDividedAssessmentPoints':
      return {
        ...state,
        selectedRadio: action.payload.selectedRadio,
        gradingTechnique: action.payload.selectedRadio
      };
    case 'setMaximumRevusInput':
      return {
        ...state,
        maximumRevusInput: action.payload.maximumRevusInput
      };
    case 'setMaximumMethodCalculated':
      return {
        ...state,
        maximumMethodCalculated: action.payload.maximumMethodCalculated
      };
    case 'setDividedCohortHigh':
      return {
        ...state,
        selectedRadio: action.payload.selectedRadio,
        gradingTechnique: action.payload.selectedRadio
      };
    case 'setCurved':
      return {
        ...state,
        selectedRadio: action.payload.selectedRadio,
        gradingTechnique: action.payload.selectedRadio
      };
    case 'setLowScoreInput':
      return {
        ...state,
        lowScoreInput: action.payload.lowScoreInput
      };
    case 'setLowScoreCalculated':
      return {
        ...state,
        lowScoreCalculated: action.payload.lowScoreCalculated
      };
    case 'setCurvedHighGrade':
      return {
        ...state,
        curvedHighGrade: action.payload.curvedHighGrade
      };
    case 'setCurvedFailingGrade':
      return {
        ...state,
        curvedFailingGrade: action.payload.curvedFailingGrade
      };
    case 'setScaled':
      return {
        ...state,
        selectedRadio: action.payload.selectedRadio,
        gradingTechnique: action.payload.selectedRadio
      };
    case 'setScaledHighScore':
      return {
        ...state,
        scaledHighScore: action.payload.scaledHighScore
      };
    case 'setScaledLowScore':
      return {
        ...state,
        scaledLowScore: action.payload.scaledLowScore
      };
    case 'setShowValidationErrors':
      return {
        ...state,
        showValidationErrors: action.payload.showValidationErrors
      };
    case 'clearForm':
      return initialGradingTechniqueState;
    default: {
      throw new Error();
    }
  }
};

const handleChangeRadio = (event, dispatch, callbackFn) => {
  const selectedOption = event.target.value;
  if (callbackFn && typeof callbackFn === 'function') callbackFn();

  switch (selectedOption) {
    case 'points': {
      dispatch({
        type: 'setPointsOnly',
        payload: {
          selectedRadio: selectedOption
        }
      });
      break;
    }
    case 'dividedAssessmentPoints': {
      dispatch({
        type: 'setDividedAssessmentPoints',
        payload: {
          selectedRadio: selectedOption
        }
      });
      break;
    }
    case 'dividedCohortHigh': {
      dispatch({
        type: 'setDividedCohortHigh',
        payload: {
          selectedRadio: selectedOption
        }
      });
      break;
    }
    case 'curved': {
      dispatch({
        type: 'setCurved',
        payload: {
          selectedRadio: selectedOption
        }
      });
      break;
    }
    case 'scaled': {
      dispatch({
        type: 'setScaled',
        payload: {
          selectedRadio: selectedOption
        }
      });
      break;
    }
    default:
      console.log('error: ', selectedOption);
  }
};

const handleGradingTechniqueChange = (event, dispatch, callbackFn) => {
  event.stopPropagation();
  const { name, value, checked } = event.target;
  if (callbackFn && typeof callbackFn === 'function') callbackFn();

  switch (name) {
    case 'maximumRevusInput':
      dispatch({
        type: 'setMaximumRevusInput',
        payload: {
          maximumRevusInput: value
        }
      });
      break;
    case 'maximumMethodCalculated':
      dispatch({
        type: 'setMaximumMethodCalculated',
        payload: {
          maximumMethodCalculated: checked
        }
      });
      break;
    case 'lowScoreInput':
      dispatch({
        type: 'setLowScoreInput',
        payload: {
          lowScoreInput: value
        }
      });
      break;
    case 'lowScoreCalculated':
      dispatch({
        type: 'setLowScoreCalculated',
        payload: {
          lowScoreCalculated: checked
        }
      });
      break;
    case 'curvedHighGrade':
      dispatch({
        type: 'setCurvedHighGrade',
        payload: {
          curvedHighGrade: value
        }
      });
      break;
    case 'curvedFailingGrade':
      dispatch({
        type: 'setCurvedFailingGrade',
        payload: {
          curvedFailingGrade: value
        }
      });
      break;
    case 'scaledHighScore':
      dispatch({
        type: 'setScaledHighScore',
        payload: {
          scaledHighScore: value
        }
      });
      break;
    case 'scaledLowScore':
      dispatch({
        type: 'setScaledLowScore',
        payload: {
          scaledLowScore: value
        }
      });
      break;
    default:
      break;
  }
};

export {
  gradingTechniqueReducer,
  initialGradingTechniqueState,
  handleChangeRadio,
  handleGradingTechniqueChange
};
