import {
  GET_COHORT_OPPORTUNITY_REPORT,
  GET_COHORT_OPPORTUNITY_REPORT_SUCCESS,
  GET_COHORT_OPPORTUNITY_REPORT_ERROR
} from '../actions-type';

const doGetCohortOpportunityReport = payload => ({
  type: GET_COHORT_OPPORTUNITY_REPORT,
  payload: { ...payload }
});

const doGetCohortOpportunityReportSuccess = result => ({
  type: GET_COHORT_OPPORTUNITY_REPORT_SUCCESS,
  payload: result
});

const doGetCohortOpportunityReportError = error => ({
  type: GET_COHORT_OPPORTUNITY_REPORT_ERROR,
  payload: error
});

export {
  doGetCohortOpportunityReport,
  doGetCohortOpportunityReportSuccess,
  doGetCohortOpportunityReportError
};
