import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import { orm } from '../models';

export const cohortSelector = ormCreateSelector(orm.Cohort);

export const cohortsSelector = createSelector(
  state => state,
  redux => {
    const cohortsWithProgram = ormCreateSelector(
      orm.Cohort,
      orm.Cohort.program,
      (cohorts, programs) => {
        const addProgram = cohorts.map((cohort, index) => {
          return { ...cohort, programInfo: programs[index] };
        });

        return addProgram;
      }
    );

    const cohorts = cohortsWithProgram(redux);

    const cohortsSorted = _.sortBy(cohorts, ['name']);

    return cohortsSorted;
  }
);

export const cohortsByProgramSelector = createSelector(
  state => state,
  state => state.userState.selectedProgramUuid,
  (redux, programUuid) => {
    const cohortsWithRolesSelectorORM = ormCreateSelector(orm.Cohort);

    const cohorts = cohortsWithRolesSelectorORM(redux).filter(
      cohort => cohort.programUuid === programUuid
    );

    const cohortsSorted = _.sortBy(cohorts, ['name']);

    return cohortsSorted;
  }
);

export const cohortsByProgramPropSelector = createSelector(
  state => state,
  (_, props) => props.programUuid,
  (redux, programUuid) => {
    const cohortsWithRolesSelectorORM = ormCreateSelector(orm.Cohort);

    const cohorts = cohortsWithRolesSelectorORM(redux).filter(
      cohort => cohort.programUuid === programUuid
    );
    return cohorts;
  }
);

export const cohortSelectorByMatchParams = createSelector(
  state => state,
  (_, cohortUuid) => cohortUuid,
  (redux, cohortUuid) => {
    const cohortsWithRolesSelectorORM = ormCreateSelector(orm.Cohort);

    const cohort = cohortsWithRolesSelectorORM(redux, cohortUuid) || undefined;
    return cohort;
  }
);

export const cohortUuidsByProgramSelector = ormCreateSelector(
  orm,
  state => state,
  (_, programUuid) => programUuid,
  (ormSession, reduxState, programUuid) =>
    ormSession.Cohort.all()
      .toRefArray()
      .filter(cohort => cohort.programUuid === programUuid)
);

export const selectCohortsByProgramDesc = createSelector(
  state => state,
  (_, programUuid) => programUuid,
  (redux, programUuid) => {
    const getCohorts = ormCreateSelector(orm.Cohort);

    const allCohorts = getCohorts(redux);

    const cohorts = _(allCohorts)
      .filter(cohort => cohort.programUuid === programUuid)
      .orderBy(['startDate', 'endDate'], ['asc', 'asc'])
      .value();

    return cohorts;
  }
);
