import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SyllabusEncounter from './SyllabusEncounter';

import { encounterSelector } from '../../../redux/selectors/encountersSelectors';

import SyllabusLinkedEncounter from './SyllabusLinkedEncounter';

export default function SyllabusEncounterContainer({
  encounterUuid,
  itemNumber
}) {
  const encounter = useSelector(
    state => encounterSelector(state, encounterUuid),
    _.isEqual
  );

  if (
    encounter &&
    encounter.linkedAssessmentUuid &&
    encounter.linked_assessment
  ) {
    return (
      <SyllabusLinkedEncounter encounter={encounter} itemNumber={itemNumber} />
    );
  }

  return <SyllabusEncounter encounter={encounter} itemNumber={itemNumber} />;
}

SyllabusEncounterContainer.propTypes = {
  encounterUuid: PropTypes.string,
  itemNumber: PropTypes.number.isRequired
};

SyllabusEncounterContainer.defaultProps = {
  encounterUuid: ''
};
