import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import PartLegacyGradeUpload from './PartLegacyGradeUpload';
import { doUploadPartLegacyGrades } from '../../../../redux/actions/legacyGradingActions';
import { useWsSubscriber } from '../../../../helpers/hooks/subscription.hooks';
import { doGetPartGradeSettings } from '../../../../redux/actions/partGradeSettingsActions';
import { doGetCurrentResultCards } from '../../../../redux/actions/resultCardActions';

export default function PartLegacyGradeUploadContainer({
  partUuid,
  openUploadScores,
  handleUploadClose
}) {
  const dispatch = useDispatch();
  const [grades, setGrades] = useState(undefined);
  const [openMapping, setOpenMapping] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);

  const query = `partUuid=${partUuid}`;
  const pathSubscribe = partUuid
    ? `part/legacy-grades/upload/subscribe-status?${query}`
    : undefined;

  const redisKey = `${partUuid}-legacy-grades-upload`;
  const ws = useWsSubscriber(pathSubscribe, redisKey);
  const subscription = ws.result;
  const connected = ws.readyState;
  const emitter = ws.send;
  const status = subscription?.status;

  useEffect(() => {
    if (connected) {
      emitter(redisKey);
    }
  }, [redisKey, emitter, connected]);

  useEffect(() => {
    if (status === 'success' && partUuid) {
      dispatch(doGetPartGradeSettings(partUuid));
      dispatch(doGetCurrentResultCards(partUuid));
    }
  }, [dispatch, partUuid, status]);

  const handleGrades = grades => {
    setGrades(grades);
  };

  const handleUpload = () => {
    const uploadDate = moment().format('YYYY-MM-DD');
    dispatch(doUploadPartLegacyGrades(partUuid, grades, uploadDate));
  };

  const handleDisplayMapping = () => {
    setOpenMapping(openMapping => !openMapping);
  };

  const handleDisplayErrors = () => {
    setOpenError(openError => !openError);
  };

  const handleDisplaySummary = () => {
    setOpenSummary(openSummary => !openSummary);
  };

  return (
    <PartLegacyGradeUpload
      status={subscription?.status}
      error={subscription?.error}
      summary={subscription?.summary}
      uploadDate={subscription?.uploadDate}
      openModal={openUploadScores}
      openMapping={openMapping}
      openError={openError}
      openSummary={openSummary}
      grades={grades}
      handleDisplayMapping={handleDisplayMapping}
      handleDisplayErrors={handleDisplayErrors}
      handleDisplaySummary={handleDisplaySummary}
      handleUploadClose={handleUploadClose}
      handleGrades={handleGrades}
      handleUpload={handleUpload}
    />
  );
}

PartLegacyGradeUploadContainer.propTypes = {
  partUuid: PropTypes.string,
  openUploadScores: PropTypes.bool,
  handleUploadClose: PropTypes.func
};
PartLegacyGradeUploadContainer.defaultProps = {
  partUuid: '',
  openUploadScores: false,
  handleUploadClose: undefined
};
