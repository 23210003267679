import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';

import {
  ContainerResize,
  ContainerPage,
  ContainerActions,
  ElevateLow
} from '../../../../../Library/Layout';

import Header from '../../../../../Library/Header';
import FormField from '../../../../../Library/FormField';

import {
  FormSectionHeader,
  FormSectionHeaderSubtitle
} from '../../../../../Library/FormComponents';
import EditorMilestone from './EditorMilestone';

const TitleArea = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding: 20px;
  margin-bottom: 30px;
`;

const Title = styled.div`
  font-size: 18px;
`;

const PageArea = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;

const FormSectionHeaderSC = styled(FormSectionHeader)`
  && {
    margin-bottom: 0;
  }
`;

const AlertSC = styled(Alert)`
  margin-bottom: 30px;
`;

export default function EditorSubjectExam({
  exams,
  hasErrors,
  handleUpdate,
  handleAddExam,
  handleAddConversion,
  handleUpdateConversion,
  handleUpdateSourceInstitionUuid,
  handleRemoveConversion,
  handleSave
}) {
  return (
    <ContainerResize>
      <Header title="Cognitive Milestone" subtitle="Edit Milestone Exams" />
      <ContainerActions>
        <ButtonPrimary onClick={handleSave}>Save</ButtonPrimary>
      </ContainerActions>

      <ContainerPage paddingTop="60px">
        {hasErrors && (
          <AlertSC variant="filled" severity="error">
            In order to save, all required fields must be filled. Please scroll
            through the entire form.
          </AlertSC>
        )}
        <ElevateLow>
          <TitleArea>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={9}>
                <Title>Skills</Title>
              </Grid>
            </Grid>
          </TitleArea>

          <PageArea>
            {exams.map(exam => (
              <Grid container spacing={4} direction="row" key={exam.uuid}>
                <Grid item sm={12} xs={12}>
                  <FormSectionHeaderSC offset="0">
                    <FormSectionHeaderSubtitle>Skill</FormSectionHeaderSubtitle>
                  </FormSectionHeaderSC>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    name="scope"
                    label="Subject*"
                    value={exam.scope}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <EditorMilestone
                    classificationUuid={exam.uuid}
                    milestone={exam.exam_milestone}
                    hasErrors={hasErrors}
                    handleAddExam={handleAddExam}
                    handleUpdate={handleUpdate}
                    handleAddConversion={handleAddConversion}
                    handleUpdateConversion={handleUpdateConversion}
                    handleRemoveConversion={handleRemoveConversion}
                    handleUpdateSourceInstitionUuid={
                      handleUpdateSourceInstitionUuid
                    }
                  />
                </Grid>
              </Grid>
            ))}
          </PageArea>
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
}

EditorSubjectExam.propTypes = {
  exams: PropTypes.array,
  hasErrors: PropTypes.bool,
  handleSave: PropTypes.func,
  handleAddExam: PropTypes.func,
  handleUpdate: PropTypes.func,
  handleAddConversion: PropTypes.func,
  handleUpdateConversion: PropTypes.func,
  handleUpdateSourceInstitionUuid: PropTypes.func,
  handleRemoveConversion: PropTypes.func
};

EditorSubjectExam.defaultProps = {
  exams: [],
  hasErrors: false,
  handleSave: undefined,
  handleAddExam: undefined,
  handleUpdate: undefined,
  handleAddConversion: undefined,
  handleUpdateConversion: undefined,
  handleUpdateSourceInstitionUuid: undefined,
  handleRemoveConversion: undefined
};
