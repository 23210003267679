import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useParams,
  useLocation,
  useRouteMatch,
  useHistory
} from 'react-router-dom';
import _ from 'lodash';
import { doGetCohort } from '../../../redux/actions/cohortActions';
import { cohortSelectorByMatchParams } from '../../../redux/selectors/cohortsSelectors';
import OpportunityReport from './OpportunityReports';

const OpportunityReportsContainer = () => {
  const { cohortUuid } = useParams();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentTab, setCurrentTab] = useState(0);
  const [basePath, setBasePath] = useState(
    _(path).replace(':cohortUuid', cohortUuid)
  );

  const cohort = useSelector(
    state => cohortSelectorByMatchParams(state, cohortUuid),
    _.isEqual
  );

  useEffect(() => {
    switch (pathname) {
      case `/cohort/${cohortUuid}/cohort-reports/opportunity`: {
        setCurrentTab(0);
        break;
      }
      default:
        break;
    }
  }, [dispatch, pathname, cohortUuid]);

  useEffect(() => {
    setBasePath(_(basePath).replace(':cohortUuid', cohortUuid));
  }, [basePath, cohortUuid]);

  useEffect(() => {
    dispatch(doGetCohort(cohortUuid));
  }, [dispatch, cohortUuid]);

  const handleBackAction = useCallback(
    () => history.push(`/reports`),
    [history]
  );

  return (
    <OpportunityReport
      basePath={basePath}
      currentTab={currentTab}
      onBackAction={handleBackAction}
      cohort={cohort}
      path={path}
    />
  );
};

export default OpportunityReportsContainer;
