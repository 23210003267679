import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const sectionSelector = createSelector(
  state => state,
  (_, sectionUuid) => sectionUuid,
  (redux, sectionUuid) => {
    const sectionSelectorORM = ormCreateSelector(orm.Section);
    const section = sectionSelectorORM(redux, sectionUuid);

    return section;
  }
);

export const sectionsSelector = createSelector(
  state => state,
  redux => {
    const sectionSelectorORM = ormCreateSelector(orm.Section);
    const sections = sectionSelectorORM(redux);

    return sections;
  }
);

export const syllabusSectionSelector = createSelector(
  state => state,
  state => state.syllabusState.selectedSectionUuid,
  (redux, selectedSectionUuid) => {
    const sectionSelectorORM = ormCreateSelector(orm.Section);
    const section = sectionSelectorORM(redux, selectedSectionUuid);

    return section;
  }
);

export const studentRosterSelector = createSelector(
  state => state,
  (_, props) => props.match.params.sectionUuid,
  (redux, sectionUuid) => {
    const userSectionsSelectorORM = ormCreateSelector(
      orm.UserSection,
      orm.UserSection.user,
      (userSections, user) => {
        return userSections.map((userSection, index) => {
          return {
            ...userSection,
            user: user[index]
          };
        });
      }
    );

    const students = userSectionsSelectorORM(redux).filter(
      userSection => userSection.sectionUuid === sectionUuid
    );

    return students;
  }
);

export const studentRosterSelectorBySectionUuid = createSelector(
  state => state,
  (_, sectionUuid) => sectionUuid,
  (redux, sectionUuid) => {
    const userSectionsSelectorORM = ormCreateSelector(
      orm.UserSection,
      orm.UserSection.user,
      (userSections, user) => {
        return userSections.map((userSection, index) => {
          return {
            ...userSection,
            user: user[index]
          };
        });
      }
    );

    const students = userSectionsSelectorORM(redux).filter(
      userSection => userSection.sectionUuid === sectionUuid
    );

    return students;
  }
);

export const sectionsByCourseSelector = createSelector(
  state => state,
  (_, courseUuid) => courseUuid,
  (redux, courseUuid) => {
    const sectionSelectorORM = ormCreateSelector(orm.Section);
    const sections = sectionSelectorORM(redux)
      .filter(section => section.courseUuid === courseUuid)
      .sort((a, b) => a.sectionNumber - b.sectionNumber);

    return sections;
  }
);

export const selectSectionAndParts = createSelector(
  state => state,
  (_, sectionUuid) => sectionUuid,
  (redux, sectionUuid) => {
    const getSection = ormCreateSelector(orm.Section);
    const getParts = ormCreateSelector(orm.Section.parts);

    const section = getSection(redux, sectionUuid);
    const parts = getParts(redux, sectionUuid);

    return {
      ...section,
      parts
    };
  }
);
