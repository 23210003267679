import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { visibleToSchoolAdmin } from '../../../../helpers/authorization';
import DeleteSectionButtonContainer from './DeleteSectionButton/DeleteSectionButtonContainer';
import CourseSectionForm from './CourseSectionForm';

const Layout = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 2rem;
`;

const AccordionSummarySC = styled(AccordionSummary)`
  && {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  padding: 10px 20px !important;
  background: ${props => props.theme.colors.primary} !important;

  & * {
    color: white !important;
  }
`;

const AccordionDetailsSC = styled(AccordionDetails)`
  && {
    position: relative;
    padding-top: 2rem;
  }
  padding: 20px !important;
`;

const DeleteButtonSC = styled(DeleteSectionButtonContainer)`
  && {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    align-items: center;
    right: 4rem;

  }

  & svg {
    fill: white !important;
    font-size: 26px !important;
  }
`;

const SchoolAdminOnlyButton = visibleToSchoolAdmin(props => (
  <DeleteButtonSC
    sectionUuid={props.sectionUuid}
    handleSectionDelete={props.handleSectionDelete}
  />
));

const CourseSectionPanels = ({
  handleSectionDirectorChange,
  handleSectionInputChange,
  ...props
}) => (
  <Layout>
    {props.sections.map((section, index) => (
      <Accordion key={section.uuid} defaultExpanded={index === 0}>
        <AccordionSummarySC expandIcon={<ExpandMoreIcon />}>
          <Typography>Section {section.sectionNumber}</Typography>
          <SchoolAdminOnlyButton
            handleSectionDelete={props.handleSectionDelete}
            sectionUuid={section.uuid}
          />
        </AccordionSummarySC>
        <AccordionDetailsSC>
          <CourseSectionForm
            handleSectionDirectorChange={handleSectionDirectorChange}
            handleSectionInputChange={handleSectionInputChange}
            section={section}
            {...props}
            {...section}
          />
        </AccordionDetailsSC>
      </Accordion>
    ))}
  </Layout>
);

CourseSectionPanels.propTypes = {
  sections: PropTypes.array,
  handleSectionDirectorChange: PropTypes.func,
  handleSectionInputChange: PropTypes.func,
  handleSectionDelete: PropTypes.func
};

CourseSectionPanels.defaultProps = {
  sections: [],
  handleSectionDirectorChange: undefined,
  handleSectionInputChange: undefined,
  handleSectionDelete: undefined
};

export default CourseSectionPanels;
