import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Radio, RadioGroup, Checkbox } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import FormControl from '@material-ui/core/FormControl';
import { Body1 } from '@xcomp/xcomp-design-library';
import { InfoIcon, CheckIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { TextFieldWithError, TextFieldSC } from '../FormComponents';
import { validateDecimalStringField } from '../../../helpers/validation/validateGeneric';

const FormControlSC = styled(FormControl)`
  width: 100%;
`;

const RadioGroupSC = styled(RadioGroup)`
  margin: 12px 0 32px;
`;

const ScoresInfo = styled(({ noDataWarning, paddingLeft, ...rest }) => (
  <Body1 {...rest} />
))`
  width: 100%;
  margin: 0;
  color: ${props =>
    props.noDataWarning
      ? props.theme.colors.status.error
      : props.theme.fontColors.darker};
  font-weight: 500;
  display: flex;
  aling-items: center;
  justify-content: flex-start;
  padding-bottom: 1rem;
  padding-left: ${props => props.paddingLeft};
  white-space: nowrap;
  svg {
    color: ${props =>
      props.noDataWarning
        ? props.theme.colors.status.error
        : props.theme.colors.grey[600]};
    font-size: 22px;
    margin-right: 8px;
  }
`;

const AssessmentSettingRules = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  @media screen and (max-width: 1200px) {
    display: block;
  }
`;

const FormRow = styled(({ marginBottom, marginTop, ...rest }) => (
  <div {...rest} />
))`
  padding-left: 2rem;
  margin: 1.25rem 0 1rem 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}

  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}
`;

const FormItem = styled.div`
  width: 33%;
  padding-left: 1rem;

  &:first-child {
    padding-left: 0;
  }

  .MuiFormControlLabel-root {
    white-space: nowrap;
  }
`;

const getHighScoreInputValue = (
  maximumMethodCalculated,
  calculatedHighScore,
  maximumRevusInput,
  isProgramAssessment
) => {
  if (isProgramAssessment) {
    return maximumRevusInput;
  }
  return maximumMethodCalculated ? calculatedHighScore : maximumRevusInput;
};

const CalculatedScoreInput = ({
  validateDecimalStringField,
  showValidationErrors,
  handleGradingTechniqueChange,
  scoreValue,
  isCalculated,
  label,
  name,
  checkboxName,
  isProgramAssessment,
  isReadOnly
}) => {
  const someUuid = uuidv4();
  return (
    <FormItem>
      <TextFieldWithError
        handleValidate={validateDecimalStringField}
        hasErrors={showValidationErrors && !isCalculated}
        id={someUuid}
        InputLabelProps={{ shrink: true }}
        label={label}
        margin="normal"
        name={name}
        onChange={handleGradingTechniqueChange}
        placeholder=""
        value={scoreValue || ''}
        variant="outlined"
        disabled={isCalculated || isReadOnly}
      />
      {!isProgramAssessment && (
        <FormControlLabel
          label="Use Data Calculated by Scores"
          control={
            <Checkbox
              name={checkboxName}
              checked={isCalculated}
              onChange={handleGradingTechniqueChange}
              color="primary"
            />
          }
        />
      )}
    </FormItem>
  );
};

export default function GradingTechniqueForm({
  curvedFailingGrade,
  handleChangeRadio,
  handleGradingTechniqueChange,
  lowScoreInput,
  lowScoreCalculated,
  calculatedLowScore,
  maximumMethodCalculated,
  maximumRevusInput,
  calculatedHighScore,
  scaledHighScore,
  scaledLowScore,
  selectedRadio,
  showValidationErrors,
  allowPointsOnly,
  allowCohortHigh,
  labels,
  isProgramAssessment,
  isReadOnly
}) {
  const scoreValue = getHighScoreInputValue(
    maximumMethodCalculated,
    calculatedHighScore,
    maximumRevusInput,
    isProgramAssessment
  );
  const maxRevusInputField = (
    <>
      <FormRow marginTop="0" marginBottom="0">
        {maximumMethodCalculated && !isProgramAssessment && (
          <ScoresInfo paddingLeft="0" noDataWarning={!calculatedHighScore}>
            {!calculatedHighScore ? <InfoIcon /> : <CheckIcon />}
            {!calculatedHighScore
              ? 'Data has not been committed.'
              : 'Data calculated from Scores'}
          </ScoresInfo>
        )}
      </FormRow>
      <FormRow marginTop="0" marginBottom="0">
        <CalculatedScoreInput
          validateDecimalStringField={validateDecimalStringField}
          showValidationErrors={showValidationErrors}
          handleGradingTechniqueChange={handleGradingTechniqueChange}
          scoreValue={scoreValue}
          isCalculated={maximumMethodCalculated}
          label="High Score ReVUs"
          name="maximumRevusInput"
          checkboxName="maximumMethodCalculated"
          isProgramAssessment={isProgramAssessment}
          isReadOnly={isReadOnly}
        />
      </FormRow>
    </>
  );

  return (
    <AssessmentSettingRules>
      <FormControlSC component="fieldset" onChange={handleChangeRadio}>
        <RadioGroupSC
          aria-label="position"
          name="position"
          value={selectedRadio}
        >
          {allowPointsOnly && !isProgramAssessment && (
            <FormControlLabel
              value="points"
              control={<Radio color="primary" />}
              label="No Calculation - Points Only"
            />
          )}
          <FormControlLabel
            value="dividedAssessmentPoints"
            control={<Radio color="primary" />}
            label={labels.dividedAssessmentPoints}
            disabled={isReadOnly}
          />
          {selectedRadio === 'dividedAssessmentPoints' && maxRevusInputField}
          {allowCohortHigh && !isProgramAssessment && (
            <>
              <FormControlLabel
                value="dividedCohortHigh"
                control={<Radio color="primary" />}
                label={labels.dividedCohortHigh}
              />
              {selectedRadio === 'dividedCohortHigh' && maxRevusInputField}
            </>
          )}
          {!isProgramAssessment && (
            <FormControlLabel
              value="curved"
              control={<Radio color="primary" />}
              label={labels.curved}
            />
          )}
          {selectedRadio === 'curved' && (
            <>
              <FormRow marginBottom="0" marginTop="0">
                <FormItem>
                  <ScoresInfo
                    noDataWarning={!calculatedHighScore}
                    paddingLeft="0rem"
                  >
                    {!calculatedHighScore ? <InfoIcon /> : <CheckIcon />}
                    {!calculatedHighScore
                      ? 'Data has not been committed.'
                      : 'Data calculated from Scores'}
                  </ScoresInfo>
                </FormItem>
              </FormRow>
              <FormRow marginTop="0" marginBottom="0">
                <FormItem>
                  <TextFieldSC
                    id="highScoreReVUsCurvedDisplay"
                    InputLabelProps={{ shrink: true }}
                    label="High Score ReVUs"
                    margin="normal"
                    name="highScoreReVUsCurved"
                    placeholder=""
                    value={calculatedHighScore || ''}
                    variant="outlined"
                    disabled={true}
                  />
                </FormItem>
                <FormItem>
                  <TextFieldWithError
                    id="curvedHighGradeDisplay"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 10 }}
                    label="Curved High Percentage"
                    margin="normal"
                    name="curvedHighGrade"
                    placeholder=""
                    value="100"
                    variant="outlined"
                    disabled={true}
                  />
                </FormItem>
              </FormRow>
              <FormRow marginTop="1rem" marginBottom="0">
                <FormItem>
                  <ScoresInfo
                    noDataWarning={!calculatedLowScore}
                    paddingLeft="0rem"
                  >
                    {!calculatedLowScore ? <InfoIcon /> : <CheckIcon />}
                    {!calculatedLowScore
                      ? 'Data has not been committed.'
                      : 'Data calculated from Scores'}
                  </ScoresInfo>
                </FormItem>
              </FormRow>
              <FormRow marginTop="0">
                <FormItem>
                  <TextFieldWithError
                    id="calculatedLowScoreDisplay"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 10 }}
                    label="Low Score ReVUs"
                    margin="normal"
                    name="calculatedLowScore"
                    placeholder=""
                    value={calculatedLowScore || ''}
                    variant="outlined"
                    disabled={true}
                  />
                </FormItem>
                <FormItem>
                  <TextFieldWithError
                    id="curvedFailingGradeInput"
                    handleValidate={validateDecimalStringField}
                    hasErrors={showValidationErrors}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 10 }}
                    label="Curved Low Percentage"
                    margin="normal"
                    name="curvedFailingGrade"
                    onChange={handleGradingTechniqueChange}
                    placeholder=""
                    value={curvedFailingGrade || ''}
                    variant="outlined"
                  />
                </FormItem>
              </FormRow>
            </>
          )}
          {!isProgramAssessment && (
            <FormControlLabel
              value="scaled"
              control={<Radio color="primary" />}
              label={labels.scaled}
            />
          )}
          {selectedRadio === 'scaled' && (
            <>
              <FormRow marginBottom="0" marginTop="0">
                <FormItem>
                  {maximumMethodCalculated && (
                    <ScoresInfo
                      noDataWarning={!calculatedHighScore}
                      paddingLeft="0rem"
                    >
                      {!calculatedHighScore ? <InfoIcon /> : <CheckIcon />}
                      {!calculatedHighScore
                        ? 'Data has not been committed.'
                        : 'Data calculated from Scores'}
                    </ScoresInfo>
                  )}
                </FormItem>
              </FormRow>
              <FormRow marginTop="0" marginBottom="0">
                <CalculatedScoreInput
                  validateDecimalStringField={validateDecimalStringField}
                  showValidationErrors={showValidationErrors}
                  handleGradingTechniqueChange={handleGradingTechniqueChange}
                  scoreValue={
                    maximumMethodCalculated
                      ? calculatedHighScore
                      : maximumRevusInput
                  }
                  isCalculated={maximumMethodCalculated}
                  label="High Score ReVUs"
                  name="maximumRevusInput"
                  checkboxName="maximumMethodCalculated"
                />
                <FormItem>
                  <TextFieldWithError
                    id="scaledHighScoreInput"
                    handleValidate={validateDecimalStringField}
                    hasErrors={showValidationErrors}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 10 }}
                    label="Scaled High Percentage"
                    margin="normal"
                    name="scaledHighScore"
                    onChange={handleGradingTechniqueChange}
                    placeholder=""
                    value={scaledHighScore || ''}
                    variant="outlined"
                  />
                </FormItem>
              </FormRow>
              <FormRow marginBottom="0" marginTop="1rem">
                <FormItem>
                  {lowScoreCalculated && (
                    <ScoresInfo
                      noDataWarning={!calculatedLowScore}
                      paddingLeft="0rem"
                    >
                      {!calculatedLowScore ? <InfoIcon /> : <CheckIcon />}
                      {!calculatedLowScore
                        ? 'Data has not been committed.'
                        : 'Data calculated from Scores'}
                    </ScoresInfo>
                  )}
                </FormItem>
              </FormRow>
              <FormRow marginTop="0">
                <CalculatedScoreInput
                  validateDecimalStringField={validateDecimalStringField}
                  showValidationErrors={showValidationErrors}
                  handleGradingTechniqueChange={handleGradingTechniqueChange}
                  scoreValue={
                    lowScoreCalculated ? calculatedLowScore : lowScoreInput
                  }
                  isCalculated={lowScoreCalculated}
                  label="Low Score ReVUs"
                  name="lowScoreInput"
                  checkboxName="lowScoreCalculated"
                />
                <FormItem>
                  <TextFieldWithError
                    id="scaledLowScoreInput"
                    handleValidate={validateDecimalStringField}
                    hasErrors={showValidationErrors}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 10 }}
                    label="Scaled Low Percentage"
                    margin="normal"
                    name="scaledLowScore"
                    onChange={handleGradingTechniqueChange}
                    placeholder=""
                    value={scaledLowScore || ''}
                    variant="outlined"
                  />
                </FormItem>
              </FormRow>
            </>
          )}
        </RadioGroupSC>
      </FormControlSC>
    </AssessmentSettingRules>
  );
}

CalculatedScoreInput.propTypes = {
  validateDecimalStringField: PropTypes.func,
  showValidationErrors: PropTypes.bool,
  handleGradingTechniqueChange: PropTypes.func,
  scoreValue: PropTypes.string,
  isCalculated: PropTypes.bool,
  isProgramAssessment: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  checkboxName: PropTypes.string
};

CalculatedScoreInput.defaultProps = {
  validateDecimalStringField: undefined,
  showValidationErrors: false,
  handleGradingTechniqueChange: undefined,
  scoreValue: '',
  isCalculated: true,
  isProgramAssessment: false,
  isReadOnly: false,
  label: '',
  name: '',
  checkboxName: ''
};

GradingTechniqueForm.propTypes = {
  allowPointsOnly: PropTypes.bool,
  allowCohortHigh: PropTypes.bool,
  calculatedHighScore: PropTypes.string,
  calculatedLowScore: PropTypes.string,
  curvedFailingGrade: PropTypes.string,
  handleChangeRadio: PropTypes.func,
  handleGradingTechniqueChange: PropTypes.func,
  lowScoreCalculated: PropTypes.bool,
  lowScoreInput: PropTypes.string,
  maximumMethodCalculated: PropTypes.bool,
  maximumRevusInput: PropTypes.string,
  scaledHighScore: PropTypes.string,
  scaledLowScore: PropTypes.string,
  selectedRadio: PropTypes.string,
  isProgramAssessment: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  labels: PropTypes.object
};

GradingTechniqueForm.defaultProps = {
  allowPointsOnly: false,
  allowCohortHigh: false,
  calculatedHighScore: '',
  calculatedLowScore: '',
  curvedFailingGrade: '',
  handleChangeRadio: undefined,
  handleGradingTechniqueChange: undefined,
  lowScoreCalculated: true,
  lowScoreInput: '',
  maximumMethodCalculated: true,
  maximumRevusInput: '',
  scaledHighScore: '',
  scaledLowScore: '',
  selectedRadio: '',
  isProgramAssessment: false,
  isReadOnly: false,
  showValidationErrors: false,
  labels: {
    dividedAssessmentPoints: 'Divided by Assessment Possible Points',
    dividedCohortHigh: 'Divided by Cohort High Points',
    curved: 'Curved',
    scaled: 'Scaled'
  }
};
