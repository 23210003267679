import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';
import { sortByProperty } from '../../helpers/utilities';

export const partGradeSettingsSelector = createSelector(
  state => state,
  (_, partUuid) => partUuid,
  (redux, partUuid) => {
    const partGradeSettingsSelectorORM = ormCreateSelector(
      orm.PartGradeSettings
    );
    const partGradeSettings = partGradeSettingsSelectorORM(redux).find(
      partGradeSettings => partGradeSettings.partUuid === partUuid
    );

    return partGradeSettings;
  }
);

export const partGradeCollectionsSelector = createSelector(
  state => state,
  (_, partGradeSettingsUuid) => partGradeSettingsUuid,
  (redux, partGradeSettingsUuid) => {
    const gradeCollectionSelectorORM = ormCreateSelector(orm.GradeCollection);
    const gradeCollections = gradeCollectionSelectorORM(redux).filter(
      gradeCollection =>
        gradeCollection.partGradeSettingsUuid === partGradeSettingsUuid
    );

    return gradeCollections.sort((a, b) => sortByProperty(a, b, 'createdAt'));
  }
);

export const partGradeCollectionSelector = createSelector(
  state => state,
  (_, gradeCollectionUuid) => gradeCollectionUuid,
  (redux, gradeCollectionUuid) => {
    const selectorORM = ormCreateSelector(orm.GradeCollection);
    return selectorORM(redux).find(
      gradeCollection => gradeCollection.uuid === gradeCollectionUuid
    );
  }
);

export const partGradeCollectionGradingTechniqueSelector = createSelector(
  state => state,
  (_, gradeCollectionUuid) => gradeCollectionUuid,
  (redux, gradeCollectionUuid) => {
    const selectorORM = ormCreateSelector(orm.GradeCollection);
    const thisCollection = selectorORM(redux).find(
      gradeCollection => gradeCollection.uuid === gradeCollectionUuid
    );
    return thisCollection.grading_technique;
  }
);
