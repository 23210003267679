import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { classificationsSelector } from '../../../../../../redux/selectors/classificationSelectors';

const Row = styled.div`
  color: ${props => props.theme.fontColors.darker};
  display: flex;
  letter-spacing: 0.15;
  margin-bottom: 10px;
  margin-left: 196px;
  margin-top: 10px;
  width: 100%;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div``;

const Input = styled.div`
  margin-left: 50px;

  color: ${props => props.theme.fontColors.darker};
`;

export default function ProgramSubject({ category, source, programUuid }) {
  const classifications = useSelector(
    state => classificationsSelector(state, programUuid, category, source),
    _.isEqual
  );

  return (
    <>
      {classifications.map(classification => (
        <Row key={classification.uuid}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <Group>
                <Label>Subject</Label>
                <Input>{classification.scope}</Input>
              </Group>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Group>
                <Label>Weight</Label>
                <Input>{classification.weight}</Input>
              </Group>
            </Grid>
          </Grid>
        </Row>
      ))}
    </>
  );
}

ProgramSubject.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  programUuid: PropTypes.string
};

ProgramSubject.defaultProps = {
  category: '',
  source: '',
  programUuid: undefined
};
