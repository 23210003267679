import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import { ButtonIcon } from '@xcomp/xcomp-design-library';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { sortByProperty } from '../../../../helpers/utilities';
import {
  Column,
  TextFieldWithError,
  TextFieldSC
} from '../../../Library/FormComponents';

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const SearchBarWrapper = styled.div`
  position: relative;
  z-index: 100;
`;

const SearchButton = styled(({ error, ...rest }) => <ButtonIcon {...rest} />)`
  && {
    position: absolute;
    right: 24px;
    top: ${props => (props.error ? '-20px' : '0')};
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 300;

    &:hover,
    &:focus,
    &:active {
      background: 0;
    }
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 58px;
  left: 0;
  right: 0;
  width: 100%;
  padding-right: 1rem;
`;

const SearchResults = styled.div`
  && {
    width: 100%;
    max-height: 200px;
    overflow: scroll;
    background: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.grey[300]};
  }
`;

const SearchResultsItem = styled.div`
  && {
    width: 100%;
    padding: 0.5rem 1.25rem;

    &:hover {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};
    }
  }
`;

const NoResultsMessage = styled.div`
  && {
    width: 100%;
    padding: 0.5rem 1.25rem;
  }
`;

const ButtonColumn = styled(Column)`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const ButtonIconSC = styled(ButtonIcon)`
  && {
    svg {
      font-size: 1.75rem;
    }
  }
`;

const CollectionCourseSearch = ({
  selectedCourses,
  onSearchClick,
  searchResults,
  isLoading,
  searchTerm,
  showResults,
  handleChange,
  onResultSelect,
  handleKeyDown,
  showValidationErrors,
  fieldName,
  label,
  placeholder,
  onClose,
  children
}) => {
  const filteredSearchResults =
    searchResults && searchResults.length > 0
      ? searchResults.filter(course => {
          if (selectedCourses[course.object.uuid]) {
            return false;
          }
          return true;
        })
      : [];

  const options =
    filteredSearchResults && filteredSearchResults.length > 0
      ? filteredSearchResults
          .map(course => {
            return {
              value: course.uuid,
              label: course.label,
              object: course.object
            };
          })
          .sort((a, b) => sortByProperty(a, b, 'label'))
      : [];

  return (
    <Layout>
      <Column size="75%">
        <SearchBarWrapper>
          <SearchButton
            noHoverBg
            onClick={onSearchClick}
            error={showValidationErrors}
          >
            <SearchIcon />
          </SearchButton>
          <TextFieldWithError
            autoFocus
            id={fieldName}
            name={fieldName}
            label={label}
            placeholder={placeholder}
            value={searchTerm}
            onChange={event => handleChange(event)}
            onKeyDown={event => handleKeyDown(event)}
            margin="normal"
            variant="outlined"
            handleValidate={() => ({
              invalid: true,
              formatError: false,
              message: 'Must add at least one class to the collection'
            })}
            hasErrors={showValidationErrors}
            paddingRight="1rem"
          />
          {showResults && (
            <Wrapper>
              {isLoading ? (
                <SearchResults>
                  <NoResultsMessage>Loading...</NoResultsMessage>
                </SearchResults>
              ) : (
                <SearchResults>
                  {options && options.length > 0 ? (
                    options.map(option => {
                      return (
                        <SearchResultsItem
                          key={option.value}
                          onClick={() => onResultSelect(option.object)}
                        >
                          {option.label}
                        </SearchResultsItem>
                      );
                    })
                  ) : (
                    <NoResultsMessage>No results</NoResultsMessage>
                  )}
                </SearchResults>
              )}
            </Wrapper>
          )}
          {children}
        </SearchBarWrapper>
      </Column>
      <Column size="20%">
        <TextFieldSC
          id="collectionCourseCredits"
          name="credits"
          value="0"
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Credits"
          placeholder=""
          disabled
        />
      </Column>
      {selectedCourses && Object.entries(selectedCourses).length > 0 && (
        <ButtonColumn size="5%">
          <ButtonIconSC onClick={onClose} noHoverBg>
            <DeleteIcon />
          </ButtonIconSC>
        </ButtonColumn>
      )}
    </Layout>
  );
};

CollectionCourseSearch.propTypes = {
  selectedCourses: PropTypes.object.isRequired,
  searchResults: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  showResults: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  searchTerm: PropTypes.string,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  handleChange: PropTypes.func,
  handleKeyDown: PropTypes.func,
  onResultSelect: PropTypes.func,
  onSearchClick: PropTypes.func
};

CollectionCourseSearch.defaultProps = {
  searchResults: null,
  isLoading: false,
  showResults: false,
  showValidationErrors: false,
  searchTerm: '',
  fieldName: 'searchTerm',
  label: 'Search Related Programs',
  placeholder: 'Search',
  children: null,
  onClose: undefined,
  handleChange: undefined,
  handleKeyDown: undefined,
  onResultSelect: undefined,
  onSearchClick: undefined
};

export default CollectionCourseSearch;
