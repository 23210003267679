import { call, put, takeEvery } from 'redux-saga/effects';
import {
  deleteScore,
  deleteScores,
  getScores,
  getUploadScoresStatus,
  postScore,
  postUploadScores,
  putScore
} from '../../api/scoreApi';
import {
  doDeleteScoreError,
  doDeleteScoresError,
  doDeleteScoresSuccess,
  doDeleteScoreSuccess,
  doGetScoresError,
  doGetScoresSuccess,
  doGetUploadScoresStatusError,
  doGetUploadScoresStatusSuccess,
  doPostScoreError,
  doPostScoreSuccess,
  doPutScoreError,
  doPutScoreSuccess,
  doUploadScoresError,
  doUploadScoresSuccess
} from '../actions/scoreActions';

import {
  DELETE_SCORE,
  DELETE_SCORES,
  GET_SCORES,
  GET_UPLOAD_SCORES_STATUS,
  POST_SCORE,
  POST_UPLOAD_SCORES,
  PUT_SCORE
} from '../actions-type';

function* handleGetScores(action) {
  const { assessmentUuid, sectionUuid, partUuid } = action.payload;

  try {
    const result = yield call(getScores, assessmentUuid, sectionUuid, partUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetScoresSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetScoresError(error));
  }
}

function* handleGetUploadScoresStatus(action) {
  const { assessmentUuid } = action.payload;

  try {
    const result = yield call(getUploadScoresStatus, assessmentUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetUploadScoresStatusSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUploadScoresStatusError(error));
  }
}

function* handleUploadScores(action) {
  try {
    const {
      assessmentUuid,
      records,
      uploadDate,
      isCohortAssessment,
      isDelayedStudent
    } = action.payload;

    const result = yield call(
      postUploadScores,
      assessmentUuid,
      records,
      uploadDate,
      isCohortAssessment,
      isDelayedStudent
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doUploadScoresSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doUploadScoresError(error));
  }
}

function* handlePostScore(action) {
  const { score } = action.payload;

  try {
    const result = yield call(postScore, score);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostScoreSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostScoreError(error));
  }
}

function* handlePutScore(action) {
  const { score } = action.payload;

  try {
    const result = yield call(putScore, score);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutScoreSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutScoreError(error));
  }
}

function* handleDeleteScore(action) {
  const { scoreUuid, resultCardUuid, assessmentUuid } = action.payload;

  try {
    const result = yield call(
      deleteScore,
      scoreUuid,
      resultCardUuid,
      assessmentUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteScoreSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteScoreError(error));
  }
}

function* handleDeleteScores(action) {
  const { assessmentUuid } = action.payload;

  try {
    const result = yield call(deleteScores, assessmentUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteScoresSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteScoresError(error));
  }
}

const score = [
  takeEvery(DELETE_SCORE, handleDeleteScore),
  takeEvery(DELETE_SCORES, handleDeleteScores),
  takeEvery(GET_SCORES, handleGetScores),
  takeEvery(GET_UPLOAD_SCORES_STATUS, handleGetUploadScoresStatus),
  takeEvery(POST_SCORE, handlePostScore),
  takeEvery(POST_UPLOAD_SCORES, handleUploadScores),
  takeEvery(PUT_SCORE, handlePutScore)
];

export { score };
