import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Heading2 } from '@xcomp/xcomp-design-library';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useSelector } from 'react-redux';

import Header from '../../../../Library/Header';
import ContainerResize, {
  ContainerPage,
  Panel,
  PanelHeader
} from '../../../../Library/Layout';
import { CancelButton, SubmitButton } from '../../../../Library/FormComponents';
import { validateStringLength } from '../../../../../helpers/validation/validateGeneric';
import {
  collectionAtom,
  editCollectionNameAtom,
  fetchingCollectionAtom,
  getCollectionDetails,
  letterGradesAtom,
  newGroupModalAtom,
  newProcedureCodeModalAtom,
  updateCollection,
  updatingAtom
} from './state/editState';
import QuantitativeSkillsCollectionForm from '../CollectionForm/QuantitativeSkillsCollectionForm';
import GroupList from './components/GroupList';
import NewGroupModal from './components/NewGroupModal';
import NewProcedureCode from './components/NewProcedureCode';

const HeaderSpacer = styled.div`
  width: 100%;
  height: 36px;
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const SkillContainer = styled.div``;

const InputContainer = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
//
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function EditQuantitativeSkillsCollection() {
  const { quantitativeSkillUuid } = useParams();
  const programUuid = useSelector(state => state.userState.selectedProgramUuid);

  const history = useHistory();

  const [isGroupModalOpen, setIsGroupModalOpen] = useAtom(newGroupModalAtom);
  const doUpdateCollection = useSetAtom(updateCollection);
  const [isCodeModalOpen, setIsCodeModalOpen] = useAtom(
    newProcedureCodeModalAtom
  );
  const letterGrades = useAtomValue(letterGradesAtom);
  const getCollection = useSetAtom(getCollectionDetails);
  const setCollection = useSetAtom(editCollectionNameAtom);
  const loading = useAtomValue(fetchingCollectionAtom);
  const updating = useAtomValue(updatingAtom);
  const collection = useAtomValue(collectionAtom);
  console.log(collection);
  const handleUpdate = useCallback(
    async e => {
      e.preventDefault();
      await doUpdateCollection(quantitativeSkillUuid);
    },
    [doUpdateCollection, quantitativeSkillUuid]
  );

  const validateField = useCallback(
    name => validateStringLength(name, 100, true),
    []
  );

  const handleGroupModalOpen = useCallback(() => {
    setIsGroupModalOpen(!isGroupModalOpen);
  }, [setIsGroupModalOpen, isGroupModalOpen]);

  const handleCodeModalOpen = useCallback(() => {
    setIsCodeModalOpen(false);
  }, [setIsCodeModalOpen]);

  const handleChange = useCallback(
    e => {
      setCollection({
        [e.target.name]:
          e.target.name === 'is_default_collection'
            ? e.target.checked
            : e.target.value
      });
    },
    [setCollection]
  );
  //
  // const handleIsDefaultChange = useCallback(
  //   e => {
  //     setCollection({
  //       is_default_collection: e.target.checked
  //     });
  //   },
  //   [setCollection]
  // );

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (!quantitativeSkillUuid) return;

    getCollection({ quantitativeSkillUuid, programUuid }).then();
  }, [quantitativeSkillUuid, getCollection, programUuid]);

  return (
    <ContainerResize>
      <Header
        title="Edit Skill Collection"
        subtitle="Quantitative Skill Managment"
        backButtonLocation="/program-management/quantitative-skills"
        backButtonOverride={() => history.goBack()}
      />
      {isGroupModalOpen && (
        <NewGroupModal open={isGroupModalOpen} onClose={handleGroupModalOpen} />
      )}
      {isCodeModalOpen && (
        <NewProcedureCode
          open={isCodeModalOpen}
          onClose={handleCodeModalOpen}
        />
      )}

      <ContainerPage>
        <HeaderSpacer />
        <Panel>
          <PanelHeader paddingLeft="24px">
            <Heading2>Quantitative Skills Collection</Heading2>
          </PanelHeader>
          <PanelPadding>
            {loading ? (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            ) : (
              <>
                <SkillContainer>
                  <InputContainer>
                    <QuantitativeSkillsCollectionForm
                      collection={collection}
                      letterGrades={letterGrades}
                      handleChange={handleChange}
                    />
                  </InputContainer>
                  <ButtonContainer>
                    <CancelButton onClick={handleGoBack} noHoverBg>
                      Cancel
                    </CancelButton>
                    <SubmitButton
                      type="submit"
                      onClick={handleUpdate}
                      disabled={
                        validateField(collection?.name).invalid || updating
                      }
                    >
                      {updating ? 'Updating...' : 'Update'}
                    </SubmitButton>
                  </ButtonContainer>
                </SkillContainer>
                <GroupList />

                <SubmitButton onClick={handleGroupModalOpen}>
                  add new procedure code group
                </SubmitButton>
              </>
            )}
          </PanelPadding>
        </Panel>
      </ContainerPage>
    </ContainerResize>
  );
}
