export const POST_COMPETENCY = 'POST_COMPETENCY';
export const POST_COMPETENCY_SUCCESS = 'POST_COMPETENCY_SUCCESS';
export const POST_COMPETENCY_ERROR = 'POST_COMPETENCY_ERROR';

export const PUT_COMPETENCY = 'PUT_COMPETENCY';
export const PUT_COMPETENCY_SUCCESS = 'PUT_COMPETENCY_SUCCESS';
export const PUT_COMPETENCY_ERROR = 'PUT_COMPETENCY_ERROR';

export const PUT_COMPETENCY_MICROS = 'PUT_COMPETENCY_MICROS';
export const PUT_COMPETENCY_MICROS_SUCCESS = 'PUT_COMPETENCY_MICROS_SUCCESS';
export const PUT_COMPETENCY_MICROS_ERROR = 'PUT_COMPETENCY_MICROS_ERROR';

export const DELETE_COMPETENCY = 'DELETE_COMPETENCY';
export const DELETE_COMPETENCY_SUCCESS = 'DELETE_COMPETENCY_SUCCESS';
export const DELETE_COMPETENCY_ERROR = 'DELETE_COMPETENCY_ERROR';
