import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormHelperText, Grid } from '@material-ui/core';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import CancelIcon from '@material-ui/icons/Cancel';
import LinearProgress from '@material-ui/core/LinearProgress';

import { validateSelectField } from '../../../../../helpers/validation/validateGeneric';

import {
  validateEncounterLearningObjective,
  validateSingleLearningObjectiveRevus
} from '../../../../../helpers/validation/validateEncounter';

import withValidation from '../../../../Library/FieldWithError/FieldWithError';
import { convertNullToString } from '../../../../../helpers/utilities';
import { educationLevelByVerbSelector } from '../../../../../redux/selectors/educationLevelsSelectors';
import DebouncedTextField from '../../../../Library/DebouncedTextField';
import FormSelectError from '../../../../Library/FormSelectError';
import SearchMulti from '../../../../Library/SearchMulti';
import LearningObjectiveDelete from './LearningObjectiveDelete';
import LearningObjectiveActions from './LearningObjectiveActions';
import { loverbsSelectorBySchool } from '../../../../../redux/selectors/loverbSelectors';
import FormField from '../../../../Library/FormField';
import fetchWithAuthorization from '../../../../../helpers/fetch';

const Layout = styled.div`
  margin-top: 2rem;
  &:first-child {
    margin-top: 0;
  }
`;

const LearningObjectiveForm = styled.div`
  padding-left: 2rem;
`;

const DebouncedTextFieldSC = styled(DebouncedTextField)`
  && {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    textarea {
      box-sizing: border-box;
      margin-bottom: 0;
    }
  }
`;

const ObjectiveContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.grey[300]};
  padding: 20px;
  border-radius: 4px;
`;

const ObjectiveLabel = styled.div`
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
`;

const ObjectiveBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const ObjectiveDescription = styled.div`
  width: 100%;
`;

const InjectedLoverb = styled.div`
  font-size: 1.0675rem;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  padding-top: 3.3px;
  padding-bottom: 6px;
`;

const DebouncedTextFieldWithError = withValidation(DebouncedTextFieldSC);

const MicroList = styled.div`
  border: 1px solid ${props => props.theme.colors.grey[300]};
  padding: 20px;
  border-radius: 4px;
`;

const MicroListTitle = styled.div`
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-bottom: 15px;
`;

const MicroCode = styled.div`
  margin-top: 2px;
  margin-bottom: 2px;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 10px;
  border-radius: 4px;
  background: ${props =>
    props.highlight ? props.theme.colors.primary : 'none'};
  color: ${props => (props.highlight ? 'white' : 'none')};
  &:hover {
    border-radius: 4px;
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primary};
  }
`;

const MicroSelection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const MicroSelected = styled.div`
  display: flex;
  align-items: center;
`;

const MicroSelectedRemove = styled.div`
  margin-left: 10px;
  margin-top: 10px;
  color: ${props => props.theme.colors.grey[500]};
  &:hover {
    color: red;
  }
`;

const Required = styled.div`
  position: absolute;
  font-size: 12px;
  padding-left: 16px;
  color: ${props => props.theme.colors.status.error};
  padding-top: 8px;
`;

export default function LearningObjective({
  restrictEdit,
  objective,
  fieldInFocus,
  hasErrors,
  handleFieldInFocusValidation,
  handleObjectiveChange,
  handleObjectiveDelete,
  handleObjectiveMicro,
  handleObjectiveMicroRemove,
  handleObjectiveMove
}) {
  const [openModal, setOpenModal] = useState(false);
  const [microcompetencies, setMicrocompetencies] = useState([]);
  const [displayMicros, setDisplayMicros] = useState(false);
  const [microSelected, setMicroSelected] = useState({});
  const [microEstablished, setMicroEstablished] = useState(undefined);
  const [fetching, setFetching] = useState(false);
  const educationLevel = useSelector(
    state => educationLevelByVerbSelector(state, objective.loverbUuid),
    _.isEqual
  );

  const loverbs = useSelector(
    state => loverbsSelectorBySchool(state),
    _.isEqual
  );

  const optionsLoverb = loverbs.map(loverb => ({
    value: loverb.uuid,
    label: loverb.objectiveVerb
  }));

  const selectedLoverb = objective.loverbUuid
    ? loverbs.find(loverb => loverb.uuid === objective.loverbUuid)
    : '';
  const thisObjectiveVerb = selectedLoverb ? selectedLoverb.objectiveVerb : '';
  const educationLevelName = _.get(educationLevel, 'education', 'Select Verb');

  useEffect(() => {
    setMicroEstablished(objective.microcompetency);
  }, [objective]);

  const handleSearch = async (field, input) => {
    setDisplayMicros(true);
    if (field === 'microcompetencyCode') {
      setFetching(true);
      const results = await fetchWithAuthorization(
        `/api/microcompetency-code/${input}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        }
      )
        .then(res => res)
        .catch(error => {
          throw error;
        });

      if (results.status === 200) {
        const converted = await results.json();

        if (converted.microcompetency !== null) {
          const results = [
            {
              uuid: converted.microcompetency.uuid,
              code: converted.code,
              title: converted.microcompetency.title
            }
          ];

          setMicrocompetencies(results);
          setFetching(false);
        } else {
          setMicrocompetencies([]);
          setFetching(false);
        }
      } else {
        setMicrocompetencies([]);
        setFetching(false);
      }
    }
  };

  const handleDeleteOpen = () => {
    setOpenModal(true);
  };

  const handleDeleteClose = choice => {
    if (choice === 'yes') {
      handleObjectiveDelete(objective.uuid);
    }

    setOpenModal(false);
  };

  const handleClearSearch = () => {
    setDisplayMicros(false);
    setMicrocompetencies([]);
  };

  const handleEstablishedMicro = () => {
    handleObjectiveMicro(objective.uuid, microSelected);
    setDisplayMicros(false);
    setMicrocompetencies([]);
  };

  return (
    <Layout>
      {!restrictEdit && (
        <LearningObjectiveActions
          objectiveUuid={objective.uuid}
          objectiveNumber={objective.objectiveNumber}
          handleObjectiveMove={handleObjectiveMove}
          handleDeleteOpen={handleDeleteOpen}
        />
      )}
      <LearningObjectiveForm>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormSelectError
              name="loverb"
              label="LO Verb*"
              hasErrors={hasErrors}
              value={objective.loverbUuid}
              handleChange={event =>
                handleObjectiveChange(event, objective.uuid)
              }
              handleValidation={validateSelectField}
              options={optionsLoverb}
              disabled={restrictEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              name="educationLevel"
              label="Education Level"
              value={educationLevelName}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <ObjectiveContainer>
              <ObjectiveLabel>Learning Objective*</ObjectiveLabel>
              <ObjectiveBox>
                {thisObjectiveVerb && (
                  <InjectedLoverb>{thisObjectiveVerb}</InjectedLoverb>
                )}

                <ObjectiveDescription>
                  <DebouncedTextFieldWithError
                    name="learningObjective"
                    value={objective.learningObjective}
                    onChange={event =>
                      handleObjectiveChange(event, objective.uuid)
                    }
                    margin="normal"
                    multiline
                    maxRows="10"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 500 }}
                    handleValidate={validateEncounterLearningObjective}
                    hasErrors={
                      hasErrors || fieldInFocus === 'learningObjective'
                    }
                    onFocus={handleFieldInFocusValidation}
                    disabled={restrictEdit}
                  />
                  <FormHelperText>
                    Character Count: {objective.learningObjective.length}/500
                  </FormHelperText>
                </ObjectiveDescription>
              </ObjectiveBox>
            </ObjectiveContainer>
          </Grid>
          <Grid item xs={2}>
            <FormField
              name="revus"
              label="ReVUs"
              value={convertNullToString(objective.revus)}
              onBlur={event => handleObjectiveChange(event, objective.uuid)}
              handleValidate={validateSingleLearningObjectiveRevus}
              hasErrors={hasErrors}
              disabled={restrictEdit}
            />
          </Grid>
          <Grid item xs={10}>
            {microEstablished && (
              <MicroSelected>
                <FormField
                  name="microcompetency"
                  label="Selected Microcompetency"
                  value={`${objective.microcompetency} - ${objective.microcompetencyTitle}`}
                  disabled={true}
                />

                {!restrictEdit && (
                  <MicroSelectedRemove
                    onClick={() => handleObjectiveMicroRemove(objective.uuid)}
                  >
                    <CancelIcon />
                  </MicroSelectedRemove>
                )}
              </MicroSelected>
            )}
          </Grid>
          <Grid item xs={12}>
            {!microEstablished && (
              <SearchMulti
                placeholder="Search By"
                options={[{ label: 'Code', field: 'microcompetencyCode' }]}
                handleSearch={handleSearch}
              />
            )}
            {!microEstablished && hasErrors && <Required>Required</Required>}
          </Grid>
          <Grid item xs={12}>
            {displayMicros &&
              microcompetencies.length === 0 &&
              !microEstablished && (
                <MicroList>
                  <MicroListTitle>Select a microcompetency</MicroListTitle>
                  {fetching && <LinearProgress />}
                  {!fetching && <div>No results...</div>}
                  <MicroSelection>
                    <ButtonInline onClick={handleClearSearch}>
                      Cancel
                    </ButtonInline>
                  </MicroSelection>
                </MicroList>
              )}
            {displayMicros &&
              microcompetencies.length > 0 &&
              !microEstablished && (
                <MicroList>
                  <MicroListTitle>Select a microcompetency</MicroListTitle>
                  {fetching && <LinearProgress />}
                  {microcompetencies.map(micro => (
                    <MicroCode
                      onClick={() => setMicroSelected(micro)}
                      key={micro.uuid}
                      highlight={micro.uuid === microSelected.uuid}
                    >
                      {micro.code} - {micro.title}
                    </MicroCode>
                  ))}
                  <MicroSelection>
                    <ButtonInline onClick={handleClearSearch}>
                      Cancel
                    </ButtonInline>
                    <ButtonInline onClick={handleEstablishedMicro}>
                      Confirm
                    </ButtonInline>
                  </MicroSelection>
                </MicroList>
              )}
          </Grid>
        </Grid>
      </LearningObjectiveForm>
      <LearningObjectiveDelete
        openModal={openModal}
        handleDeleteClose={handleDeleteClose}
      />
    </Layout>
  );
}

LearningObjective.propTypes = {
  restrictEdit: PropTypes.bool,
  objective: PropTypes.object,
  objectiveNumber: PropTypes.number,
  fieldInFocus: PropTypes.string,
  hasErrors: PropTypes.bool,
  handleObjectiveChange: PropTypes.func,
  handleObjectiveDelete: PropTypes.func,
  handleObjectiveMove: PropTypes.func,
  handleFieldInFocusValidation: PropTypes.func,
  handleObjectiveMicroRemove: PropTypes.func,
  handleObjectiveMicro: PropTypes.func
};

LearningObjective.defaultProps = {
  restrictEdit: true,
  objective: {},
  objectiveNumber: 1,
  fieldInFocus: '',
  hasErrors: false,
  handleObjectiveChange: undefined,
  handleObjectiveDelete: undefined,
  handleObjectiveMove: undefined,
  handleFieldInFocusValidation: undefined,
  handleObjectiveMicroRemove: undefined,
  handleObjectiveMicro: undefined
};
