import { Model, attr } from 'redux-orm';
import {
  GET_EDUCATION_LEVEL_SUCCESS,
  GET_EDUCATION_LEVELS_BY_SCHOOL_SUCCESS,
  POST_EDUCATION_LEVEL_SUCCESS,
  PUT_EDUCATION_LEVEL_SUCCESS,
  DELETE_EDUCATION_LEVEL_SUCCESS,
  GET_LOVERBS_SUCCESS
} from '../actions-type';

class EducationLevel extends Model {
  static reducer(action, EducationLevel, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_EDUCATION_LEVEL_SUCCESS: {
        const { uuid, level, education } = payload.educationLevel;
        const ay = { uuid, level, education };
        EducationLevel.upsert(ay);
        break;
      }
      case GET_EDUCATION_LEVELS_BY_SCHOOL_SUCCESS: {
        const years = [...payload.educationLevels];
        years.forEach(year => EducationLevel.upsert(year));
        break;
      }
      case POST_EDUCATION_LEVEL_SUCCESS: {
        const { uuid, level, education, schoolUuid } = payload;
        EducationLevel.upsert({
          uuid,
          level,
          education,
          schoolUuid
        });
        break;
      }
      case PUT_EDUCATION_LEVEL_SUCCESS: {
        const { educationLevel } = payload;
        EducationLevel.upsert(educationLevel);
        break;
      }
      case DELETE_EDUCATION_LEVEL_SUCCESS: {
        const { educationLevelUuid } = payload;
        EducationLevel.withId(educationLevelUuid).delete();
        break;
      }
      case GET_LOVERBS_SUCCESS: {
        const { educationLevels } = payload;
        educationLevels.forEach(educationLevel =>
          EducationLevel.upsert({
            uuid: educationLevel.uuid,
            level: educationLevel.level,
            education: educationLevel.education,
            schoolUuid: educationLevel.schoolUuid,
            createdAt: educationLevel.createdAt,
            updatedAt: educationLevel.updatedAt
          })
        );
        break;
      }
      default:
        return session;
    }
  }
}

EducationLevel.fields = {
  uuid: attr(),
  level: attr(),
  education: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

EducationLevel.modelName = 'EducationLevel';
EducationLevel.options = {
  idAttribute: 'uuid'
};

export default EducationLevel;
