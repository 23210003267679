import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { selectCourseMaster } from '../../../../redux/selectors/coursesMasterSelectors';

export default function Hours({ courseMasterUuid }) {
  const courseMaster = useSelector(
    state => selectCourseMaster(state, courseMasterUuid),
    _.isEqual
  );

  return <>{`${courseMaster.hours}`}</>;
}

Hours.propTypes = {
  courseMasterUuid: PropTypes.string
};

Hours.defaultProps = {
  courseMasterUuid: undefined
};
