import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2 } from '@xcomp/xcomp-design-library';
import {
  ContainerPage,
  Panel,
  PanelHeader,
  ContainerResize
} from '../../../Library/Layout';
import Header from '../../../Library/Header';
import CompetencyCollectionForm from '../Form/CompetencyCollectionForm';

const HeaderSpacer = styled.div`
  width: 100%;
  height: 36px;
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const NewCompetencyCollectionDashboard = ({
  newCompetencyCollection,
  handleNameChange,
  handleDefaultCollectionChange,
  onSubmit,
  clearForm,
  formDispatch,
  showValidationErrors
}) => {
  const openMicroSearch = competencyUuid =>
    formDispatch({
      type: 'openMicroSearch',
      payload: { competencyUuid }
    });

  return (
    <ContainerResize>
      <Header title="Add Collection" subtitle="Competency Managment" />
      <ContainerPage>
        <HeaderSpacer />
        <Panel>
          <PanelHeader paddingLeft="24px">
            <Heading2>Competency Collection</Heading2>
          </PanelHeader>
          <PanelPadding>
            <CompetencyCollectionForm
              competencyCollection={newCompetencyCollection}
              buttonText="Create"
              handleNameChange={handleNameChange}
              handleDefaultCollectionChange={handleDefaultCollectionChange}
              formDispatch={formDispatch}
              onSubmit={onSubmit}
              onFormClose={clearForm}
              showValidationErrors={showValidationErrors}
              openMicroSearch={openMicroSearch}
              isNewForm
            />
          </PanelPadding>
        </Panel>
      </ContainerPage>
    </ContainerResize>
  );
};

NewCompetencyCollectionDashboard.propTypes = {
  newCompetencyCollection: PropTypes.object.isRequired,
  handleNameChange: PropTypes.func,
  handleDefaultCollectionChange: PropTypes.func,
  onSubmit: PropTypes.func,
  formDispatch: PropTypes.func,
  clearForm: PropTypes.func,
  showValidationErrors: PropTypes.bool
};

NewCompetencyCollectionDashboard.defaultProps = {
  handleNameChange: undefined,
  handleDefaultCollectionChange: undefined,
  onSubmit: undefined,
  formDispatch: undefined,
  clearForm: undefined,
  showValidationErrors: false
};

export default NewCompetencyCollectionDashboard;
