import {
  GET_CREDENTIALS,
  GET_CREDENTIALS_SUCCESS,
  GET_CREDENTIALS_ERROR,
  POST_CREDENTIAL,
  POST_CREDENTIAL_SUCCESS,
  POST_CREDENTIAL_ERROR,
  PUT_CREDENTIAL,
  PUT_CREDENTIAL_SUCCESS,
  PUT_CREDENTIAL_ERROR,
  DELETE_CREDENTIAL,
  DELETE_CREDENTIAL_SUCCESS,
  DELETE_CREDENTIAL_ERROR
} from '../actions-type';

const doGetCredentials = uuid => ({
  type: GET_CREDENTIALS,
  payload: { uuid }
});

const doGetCredentialsSuccess = result => ({
  type: GET_CREDENTIALS_SUCCESS,
  payload: result
});

const doGetCredentialsError = error => ({
  type: GET_CREDENTIALS_ERROR,
  payload: error
});

const doPostCredential = payload => ({
  type: POST_CREDENTIAL,
  payload
});

const doPostCredentialSuccess = result => ({
  type: POST_CREDENTIAL_SUCCESS,
  payload: result
});

const doPostCredentialError = error => ({
  type: POST_CREDENTIAL_ERROR,
  payload: error
});

const doPutCredential = payload => ({
  type: PUT_CREDENTIAL,
  payload
});

const doPutCredentialSuccess = result => ({
  type: PUT_CREDENTIAL_SUCCESS,
  payload: result
});

const doPutCredentialError = error => ({
  type: PUT_CREDENTIAL_ERROR,
  payload: error
});

const doDeleteCredential = uuid => ({
  type: DELETE_CREDENTIAL,
  payload: { uuid }
});

const doDeleteCredentialSuccess = result => ({
  type: DELETE_CREDENTIAL_SUCCESS,
  payload: result
});

const doDeleteCredentialError = error => ({
  type: DELETE_CREDENTIAL_ERROR,
  payload: error
});

export {
  doGetCredentials,
  doGetCredentialsSuccess,
  doGetCredentialsError,
  doPostCredential,
  doPostCredentialSuccess,
  doPostCredentialError,
  doPutCredential,
  doPutCredentialSuccess,
  doPutCredentialError,
  doDeleteCredential,
  doDeleteCredentialSuccess,
  doDeleteCredentialError
};
