import {useEffect} from 'react';
import {useLocation, useHistory} from "react-router-dom";
import {useCookies} from "react-cookie";
import jwtDecode from "jwt-decode";

const Callback = () => {
  const {hash} = useLocation();
  const [, setToken] = useCookies(['token']);
  const history = useHistory();

  useEffect(() => {
    if (hash.startsWith('#') && hash.includes('id_token=')) {
      const hashObject = {};
      hash.substring(1).split('&').forEach((keyValuePair) => {
        const [key, value] = keyValuePair.split('=');
        hashObject[key] = value;
      })

      const token = hashObject.id_token;
      if (token) {
        try {
          const {exp} = jwtDecode(token);

          const expiresIn = exp - Math.floor(Date.now() / 1000);
          const expirationDate = new Date(Date.now() + expiresIn * 1000);

          setToken('token', token, {expires: expirationDate, sameSite: true});
          history.push('/');
        } catch (e) {
          window.location.href = process.env.REACT_APP_LOGIN_URL || 'login'
        }
      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL || 'login'
      }
    }
  }, [hash, history, setToken]);


  return null;
};
export default Callback;
