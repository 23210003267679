import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const selectTranscriptCommunicationUuid = ormCreateSelector(
  orm.TranscriptCommunication
);

export const selectCommunicationTranscriptsBySkill = createSelector(
  state => state,
  (
    _,
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    classificationUuid
  ) => ({
    programUuid,
    userUuid,
    cohortUuid,
    category,
    source,
    classificationUuid
  }),
  (redux, paramaters) => {
    const {
      programUuid,
      userUuid,
      cohortUuid,
      category,
      source,
      classificationUuid
    } = paramaters;

    const getTranscript = ormCreateSelector(orm.TranscriptCommunication);

    const transcriptRecords = getTranscript(redux).filter(
      tc =>
        tc.programUuid === programUuid &&
        tc.userUuid === userUuid &&
        tc.cohortUuid === cohortUuid &&
        tc.category === category &&
        tc.source === source &&
        tc.classificationUuid === classificationUuid
    );

    return transcriptRecords;
  }
);
