import _ from 'lodash';
import {
  POST_UPLOAD_APPLICANTS,
  POST_UPLOAD_APPLICANTS_SUCCESS,
  POST_UPLOAD_APPLICANTS_ERROR,
  POST_UPLOAD_APPLICANTS_WARNING,
  RESET_UPLOAD_APPLICANTS
} from '../actions-type';

export const INITIAL_STATE = {
  uploadingApplicants: false,
  invalid: false,
  applicantsCreated: 0
};

const uploadApplicantsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_UPLOAD_APPLICANTS: {
      return {
        ...state,
        uploadingApplicants: true,
        invalid: false
      };
    }
    case POST_UPLOAD_APPLICANTS_SUCCESS: {
      return {
        ...state,
        uploadingApplicants: false,
        invalid: false,
        applicantsCreated: action.payload.applicants_created
      };
    }
    case POST_UPLOAD_APPLICANTS_ERROR: {
      return {
        ...state,
        uploadingApplicants: false,
        invalid: true,
        error_server: _.get(action, 'payload.error', '')
      };
    }
    case POST_UPLOAD_APPLICANTS_WARNING: {
      return {
        ...state,
        uploadingApplicants: false,
        invalid: true,
        ...action.payload.warning
      };
    }
    case RESET_UPLOAD_APPLICANTS: {
      return {
        uploadingApplicants: false,
        invalid: false,
        applicantsCreated: 0
      };
    }
    default:
      return state;
  }
};

export default uploadApplicantsReducer;
