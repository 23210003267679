import fetch from '../helpers/fetch';

const getPartUserGroups = partUuid =>
  fetch(`/api/part/${partUuid}/user-groups`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getPartStudentUserGroups = (partUuid, studentUuid) =>
  fetch(`/api/part/${partUuid}/user-groups/${studentUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postUserGroups = userGroups =>
  fetch(`/api/user-groups/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ userGroups })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteUserGroup = (userUuid, groupUuid) =>
  fetch(`/api/user/${userUuid}/group/${groupUuid}/user-group/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getPartUserGroups,
  postUserGroups,
  deleteUserGroup,
  getPartStudentUserGroups
};
