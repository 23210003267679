import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import UserTabAppIdStudentRoles from './UserTabAppIdStudentRoles';
import UserTabAppIdAdminRoles from './UserTabAppIdAdminRoles';

import {
  formatLastName,
  formatFirstNameMiddleName
} from '../../../helpers/format/user.format';

const Attributes = styled.div`
  display: flex;
  align-items: center;
  height: 52px;
  color: ${props => props.theme.colors.grey[600]};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  overflow: hidden;
  border-left: 1px solid
    ${props => (props.left ? props.theme.colors.grey[300] : 'none')};
  border-right: 1px solid
    ${props => (props.right ? props.theme.colors.grey[300] : 'none')};
  border-top: 1px solid
    ${props => (props.top ? props.theme.colors.grey[300] : 'none')};
  border-bottom: 1px solid
    ${props => (props.bottom ? props.theme.colors.grey[300] : 'none')};
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
`;

const UserRow = styled.div`
  border-bottom: 1px solid
    ${props => (props.bottom ? props.theme.colors.grey[300] : 'none')};
`;

const AttributeName = styled.div`
  display: flex;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.grey[600]};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  border-left: 1px solid
    ${props => (props.left ? props.theme.colors.grey[300] : 'none')};
  border-right: 1px solid
    ${props => (props.right ? props.theme.colors.grey[300] : 'none')};
  border-top: 1px solid
    ${props => (props.top ? props.theme.colors.grey[300] : 'none')};
  border-bottom: 1px solid
    ${props => (props.bottom ? props.theme.colors.grey[300] : 'none')};
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  height: 100%;
`;

export default function UserTabAppIdSearch({
  usersSearched,
  handleAdminAppIdOpen,
  handleStudentAppIdOpen
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <>
      {usersSearched?.length > 0 && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <td>
                  <Grid container>
                    <Grid item xs={1} sm={1}>
                      <Attributes left bottom>
                        InstitutionId
                      </Attributes>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <Attributes bottom>Last Name, First Name</Attributes>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <Attributes left bottom>
                        Status
                      </Attributes>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <Attributes bottom>Role</Attributes>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <Attributes bottom>Email</Attributes>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <Attributes bottom>AppID Status</Attributes>
                    </Grid>
                  </Grid>
                </td>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersSearched
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map(user => (
                  <tr key={user?.item?.uuid}>
                    <td>
                      <UserRow bottom>
                        <Grid container>
                          <Grid item xs={1} sm={1}>
                            <Attributes>{user?.item?.institutionId}</Attributes>
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <AttributeName right>
                              {`${formatLastName(
                                user?.item?.lastName,
                                user?.item?.marriedLastName,
                                user?.item?.suffix
                              )},
                              ${formatFirstNameMiddleName(
                                user?.item?.firstName,
                                user?.item?.middleName
                              )}`}
                            </AttributeName>
                          </Grid>
                          <Grid item xs={9} sm={9}>
                            <UserTabAppIdAdminRoles
                              userUuid={user?.item?.uuid}
                              appIdAdmin={user?.item?.appIdAdmin}
                              handleAdminAppIdOpen={handleAdminAppIdOpen}
                            />
                            <UserTabAppIdStudentRoles
                              userUuid={user?.item?.uuid}
                              appIdStudent={user?.item?.appIdStudent}
                              handleStudentAppIdOpen={handleStudentAppIdOpen}
                            />
                          </Grid>
                        </Grid>
                      </UserRow>
                    </td>
                  </tr>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={usersSearched?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
}

UserTabAppIdSearch.propTypes = {
  usersSearched: PropTypes.array,
  handleAdminAppIdOpen: PropTypes.func,
  handleStudentAppIdOpen: PropTypes.func
};
UserTabAppIdSearch.defaultProps = {
  usersSearched: [],
  handleAdminAppIdOpen: undefined,
  handleStudentAppIdOpen: undefined
};
