import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getPartGroups,
  postGroup,
  deleteGroup,
  putGroup,
  putGroupRankings,
  putGroupStudentRankings
} from '../../api/groupApi';

import {
  doGetPartGroupsSuccess,
  doGetPartGroupsError,
  doPostGroupSuccess,
  doPostGroupError,
  doDeleteGroupSuccess,
  doDeleteGroupError,
  doPutGroupSuccess,
  doPutGroupError,
  doPutGroupRankingsSuccess,
  doPutGroupRankingsError,
  doPutStudentGroupRankingsSuccess,
  doPutStudentGroupRankingsError
} from '../actions/groupActions';

import {
  GET_PART_GROUPS,
  POST_GROUP,
  DELETE_GROUP,
  PUT_GROUP,
  PUT_GROUP_RANKINGS,
  PUT_STUDENT_GROUP_RANKINGS
} from '../actions-type';

function* handleGetPartGroups(action) {
  const { partUuid } = action.payload;
  try {
    const result = yield call(getPartGroups, partUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetPartGroupsSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doGetPartGroupsError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doGetPartGroupsError());
  }
}

function* handlePostGroup(action) {
  const { partUuid, groupId } = action.payload;
  try {
    const result = yield call(postGroup, partUuid, groupId);
    if (result.status === 201) {
      const payload = yield result.json();
      yield put(doPostGroupSuccess(payload));
    } else if (result.status < 501) {
      const errorText = yield result.json();
      yield put(
        doPostGroupError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPostGroupError());
  }
}

function* handleDeleteGroup(action) {
  const { groupUuid } = action.payload;
  try {
    const result = yield call(deleteGroup, groupUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteGroupSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doDeleteGroupError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doDeleteGroupError());
  }
}

function* handlePutGroup(action) {
  const { groupUuid, groupId, groupLeaderUuid } = action.payload;
  try {
    const result = yield call(putGroup, groupUuid, groupId, groupLeaderUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutGroupSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPutGroupError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPutGroupError());
  }
}

function* handlePutGroupRankings(action) {
  const { partUuid } = action.payload;
  try {
    const result = yield call(putGroupRankings, partUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutGroupRankingsSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();

      yield put(
        doPutGroupRankingsError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPutGroupRankingsError(error));
  }
}

function* handlePutStudentGroupRankings(action) {
  const { partUuid, studentUuid } = action.payload;
  try {
    const result = yield call(putGroupStudentRankings, partUuid, studentUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutStudentGroupRankingsSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPutStudentGroupRankingsError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPutStudentGroupRankingsError({ error }));
  }
}

const group = [
  takeEvery(GET_PART_GROUPS, handleGetPartGroups),
  takeEvery(POST_GROUP, handlePostGroup),
  takeEvery(DELETE_GROUP, handleDeleteGroup),
  takeEvery(PUT_GROUP, handlePutGroup),
  takeEvery(PUT_GROUP_RANKINGS, handlePutGroupRankings),
  takeEvery(PUT_STUDENT_GROUP_RANKINGS, handlePutStudentGroupRankings)
];

export { group };
