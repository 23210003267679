import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Heading2, Body1, Caption } from '@xcomp/xcomp-design-library';

import {
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader
} from '../Library/Layout';
import FormSectionShowHide from '../Library/FormSectionShowHide';
import Header from '../Library/Header';
import CohortCourseCollectionsListContainer from './CourseCollections/CohortCourseCollectionsList/CohortCourseCollectionsListContainer';
import CohortAssessmentCollectionsListContainer from './AssessmentCollections/CohortAssessmentCollectionsList/CohortAssessmentCollectionsListContainer';

const HeaderSpacer = styled.div`
  width: 100%;
  height: 36px;
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const DateRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: no-wrap;
  margin-bottom: 1rem;
`;

const DateColumn = styled.div`
  width: 50%;
`;

const DateCaption = styled(Caption)`
  margin-bottom: 0;
`;

const DateDisplay = styled(Body1)`
  && {
    margin-top: 0;
  }
`;

const CohortRequirements = ({ cohort }) => {
  const openAccordions = useSelector(
    state => state.openAccordionsState.openAccordions
  );

  const courseAccordionKey = `${cohort.uuid}-courses`;
  const assessmentAccordionKey = `${cohort.uuid}-assessments`;

  const isCourseAccordionOpen = openAccordions[courseAccordionKey] || false;
  const isAssessmentAccordionKey =
    openAccordions[assessmentAccordionKey] || false;
  return (
    <ContainerResize>
      <Header
        title="Cohort Requirements"
        subtitle="Manage cohort requirements"
        backButtonLocation="/program-management/cohort"
      />
      <ContainerPage>
        {/* <ContainerActions>
          <ButtonPrimary>Add Cohort</ButtonPrimary>
        </ContainerActions> */}
        <HeaderSpacer />
        <Panel>
          <PanelHeader paddingLeft="24px">
            <Heading2>{cohort ? cohort.name : ''}</Heading2>
          </PanelHeader>
          <PanelPadding>
            <DateRow>
              <DateColumn>
                <DateCaption>Start Date</DateCaption>
                <DateDisplay>
                  {moment(cohort.startDate).format('MMMM YYYY')}
                </DateDisplay>
              </DateColumn>
              <DateColumn>
                <DateCaption>End Date</DateCaption>
                <DateDisplay>
                  {moment(cohort.endDate).format('MMMM YYYY')}
                </DateDisplay>
              </DateColumn>
            </DateRow>
            <FormSectionShowHide
              headerText="Courses"
              padding="0 30px"
              startOpen={isCourseAccordionOpen}
              accordionKey={courseAccordionKey}
            >
              <CohortCourseCollectionsListContainer />
            </FormSectionShowHide>
            <FormSectionShowHide
              headerText="Assessments"
              padding="16px 30px"
              startOpen={isAssessmentAccordionKey}
              accordionKey={assessmentAccordionKey}
            >
              <CohortAssessmentCollectionsListContainer />
            </FormSectionShowHide>
          </PanelPadding>
        </Panel>
      </ContainerPage>
    </ContainerResize>
  );
};

CohortRequirements.propTypes = {
  cohort: PropTypes.object
};

CohortRequirements.defaultProps = {
  cohort: {
    name: ''
  }
};

export default CohortRequirements;
