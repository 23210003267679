import fetch from '../helpers/fetch';

const getCredentials = uuid =>
  fetch(`/api/credentials/${uuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postCredential = payload =>
  fetch('/api/credential/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(payload)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putCredential = credential =>
  fetch(`/api/credential/${credential.uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(credential)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteCredential = uuid =>
  fetch(`/api/credential/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export { getCredentials, postCredential, putCredential, deleteCredential };
