import React, { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import StatusGradingModal from './StatusGradingModal';
import {
  statusGradingModalReducer,
  initialStatusGradingModalState
} from './statusGradingModalState';

import { useWsSubscriber } from '../../../../../../../helpers/hooks/subscription.hooks';

import { doGetPart } from '../../../../../../../redux/actions/partActions';
import { doStatusCommitModalReset } from '../../../../../../../redux/actions/assessmentActions';

const StatusGradingModalContainer = ({
  partUuid,
  statusGrade,
  isModalOpen,
  handleModalClose,
  handleModalSuccess,
  handleSubmit,
  onDeleteClick
}) => {
  const dispatch = useDispatch();

  const [state, modalDispatch] = useReducer(
    statusGradingModalReducer,
    initialStatusGradingModalState
  );

  const query = `partUuid=${partUuid}`;
  const pathSubscribe = partUuid
    ? `part/grading/subscribe-status?${query}`
    : undefined;
  const redisKey = `calculate-grade-for-part-${partUuid}`;
  const ws = useWsSubscriber(pathSubscribe, redisKey);
  const subscription = ws.result;
  const connected = ws.readyState;
  const emitter = ws.send;

  useEffect(() => {
    if (connected) {
      emitter(redisKey);
    }
  }, [isModalOpen, redisKey, connected, emitter]);

  useEffect(() => {
    if (
      subscription?.status === 'complete' ||
      subscription?.status === 'errors' ||
      subscription?.status === 'not_graded'
    ) {
      dispatch(doGetPart(partUuid));
      modalDispatch({
        type: 'clearModal'
      });
    }
  }, [dispatch, partUuid, subscription]);

  const { statusMessages } = state;

  useEffect(() => {
    if (subscription) {
      const { status, message } = subscription;

      const lastIndex = statusMessages.length - 1;

      if (
        statusMessages.length === 0 ||
        status !== statusMessages[lastIndex].status ||
        message !== statusMessages[lastIndex].message
      ) {
        modalDispatch({
          type: 'addStatusMessage',
          payload: {
            status,
            message
          }
        });
      }
    }
  }, [modalDispatch, statusMessages, subscription]);

  useEffect(() => {
    if (!isModalOpen) {
      dispatch(doStatusCommitModalReset());
    }
  }, [dispatch, isModalOpen]);

  return (
    <StatusGradingModal
      subscription={subscription}
      statusGrade={statusGrade}
      statusMessages={state.statusMessages}
      isModalOpen={isModalOpen}
      handleModalClose={handleModalClose}
      handleModalSuccess={handleModalSuccess}
      handleSubmit={handleSubmit}
      onDeleteClick={onDeleteClick}
    />
  );
};

StatusGradingModalContainer.propTypes = {
  partUuid: PropTypes.string,
  statusGrade: PropTypes.string,
  isModalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleModalSuccess: PropTypes.func,
  handleSubmit: PropTypes.func,
  onDeleteClick: PropTypes.func
};
StatusGradingModalContainer.defaultProps = {
  partUuid: undefined,
  statusGrade: 'NOT_GRADED',
  isModalOpen: false,
  handleModalClose: undefined,
  handleModalSuccess: undefined,
  handleSubmit: undefined,
  onDeleteClick: undefined
};

export default StatusGradingModalContainer;
