import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';
import ApplicationLevelContainer from '../ApplicationLevel/ApplicationLevelContainer';
import SubjectCredits from './SubjectCredits';
import SubjectRigor from './SubjectRigor';

const Accordion = styled(({ ...rest }) => (
  <AccordionRowContainer {...rest} />
))``;

const Layout = styled.div`
  width: 100%;
`;

const Calculations = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
`;

const Input = styled.div`
  width: 130px;
  color: ${props => props.theme.fontColors.darker};
`;

const Report = styled.div`
  overflow-y: hidden;
`;

const Stub = styled.div`
  width: 130px;
`;

const LabelBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelName = styled.div`
  font-size: 12px;
  margin-right: 10px;
`;

const LabelInput = styled.div`
  font-size: 14px;
  color: ${props => props.theme.fontColors.darker};
`;

export default function SubjectLevel({
  displayName,
  programUuid,
  subjects,
  sourceInstitutionUuid
}) {
  return (
    <Layout>
      <Report>
        {subjects?.map(subject => (
          <Accordion
            key={subject?.uuid}
            expandWidth="132px"
            height="50px"
            label={
              <LabelBox>
                <LabelName>Subject</LabelName>
                <LabelInput>{subject?.scope}</LabelInput>
              </LabelBox>
            }
            columnOne={
              <Calculations>
                <Input>
                  <SubjectCredits
                    programUuid={programUuid}
                    classificationUuid={subject?.uuid}
                    sourceInstitutionUuid={sourceInstitutionUuid}
                  />
                </Input>
                <Stub>&nbsp;</Stub>
                <Stub>&nbsp;</Stub>
                <Input>
                  <SubjectRigor
                    programUuid={programUuid}
                    classificationUuid={subject?.uuid}
                    sourceInstitutionUuid={sourceInstitutionUuid}
                  />
                </Input>
              </Calculations>
            }
            columnOneClassName="column_one"
          >
            <ApplicationLevelContainer
              displayName={displayName}
              programUuid={programUuid}
              sourceInstitutionUuid={sourceInstitutionUuid}
              classificationUuid={subject?.uuid}
            />
          </Accordion>
        ))}
      </Report>
    </Layout>
  );
}

SubjectLevel.propTypes = {
  displayName: PropTypes.bool,
  sourceInstitutionUuid: PropTypes.string,
  programUuid: PropTypes.string,
  subjects: PropTypes.array
};

SubjectLevel.defaultProps = {
  displayName: false,
  sourceInstitutionUuid: undefined,
  programUuid: undefined,
  subjects: []
};
