import { Model, attr } from 'redux-orm';
import {
  GET_SYLLABUS_SUCCESS,
  GET_SYLLABUS_PART_SUCCESS
} from '../actions-type';

class EncounterType extends Model {
  static reducer(action, EncounterType, session) {
    const { type, payload } = action;
    switch (type) {
      case GET_SYLLABUS_SUCCESS: {
        const encounterTypes = [...payload.encounterTypes];
        encounterTypes.map(eType => EncounterType.upsert(eType));
        break;
      }
      case GET_SYLLABUS_PART_SUCCESS: {
        const encounterTypes = [...payload.encounterTypes];
        encounterTypes.map(eType => EncounterType.upsert(eType));
        break;
      }
      default:
        return session;
    }
  }
}

EncounterType.fields = {
  uuid: attr(),
  name: attr(),
  order: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

EncounterType.modelName = 'EncounterType';

EncounterType.options = {
  idAttribute: 'uuid'
};

export default EncounterType;
