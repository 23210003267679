import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import _ from 'lodash';
import { doGetSchoolDepartments } from '../../../redux/actions/departmentActions';
import { doGetCoursesByDeptAy } from '../../../redux/actions/courseActions';
import { doGetAcademicYearsBySchool } from '../../../redux/actions/academicYearActions';
import { departmentsBySchoolStateSelector } from '../../../redux/selectors/departmentsSelectors';
import { coursesMasterSelectorByDepartmentInCourseFilter } from '../../../redux/selectors/coursesMasterSelectors';
import { academicYearsSelectorBySchool } from '../../../redux/selectors/academicYearsSelectors';
import { termsAcademicYearSelector } from '../../../redux/selectors/termsSelectors';
import {
  doSetDepartmentFilterForCourse,
  doSetCourseMasterFilterForCourse,
  doSetAcademicYearFilterForCourse,
  doSetTermFilterForCourse
} from '../../../redux/actions/filterActions';

import CourseFilter from './CourseFilter';

export default function CourseFilterContainer() {
  const dispatch = useDispatch();
  const { departmentUuid, courseMasterUuid, academicYearUuid, termUuid } =
    useSelector(state => state.filterCourseState, shallowEqual);

  const filterDepartmentOptions = useSelector(
    state => departmentsBySchoolStateSelector(state),
    _.isEqual
  );

  const filterCourseMasterOptions = useSelector(
    state => coursesMasterSelectorByDepartmentInCourseFilter(state),
    _.isEqual
  );

  const filterAcademicYearOptions = useSelector(
    state => academicYearsSelectorBySchool(state),
    _.isEqual
  );

  const filterTermOptions = useSelector(
    state => termsAcademicYearSelector(state),
    _.isEqual
  );

  const { selectedSchoolUuid, selectedDepartmentUuid } = useSelector(
    state => state.userState,
    shallowEqual
  );

  useEffect(() => {
    if (departmentUuid !== '' && academicYearUuid !== '') {
      dispatch(doGetCoursesByDeptAy(departmentUuid, academicYearUuid));
    }
  }, [dispatch, departmentUuid, academicYearUuid]);

  useEffect(() => {
    dispatch(doGetSchoolDepartments(selectedSchoolUuid));
    dispatch(doGetAcademicYearsBySchool(selectedSchoolUuid));
  }, [dispatch, selectedSchoolUuid]);

  useEffect(() => {
    if (selectedDepartmentUuid && selectedDepartmentUuid !== '') {
      dispatch(doSetDepartmentFilterForCourse(selectedDepartmentUuid));
    }
  }, [dispatch, selectedDepartmentUuid]);

  const filterDepartmentOptionsByActiveRole = () => {
    if (selectedDepartmentUuid && selectedDepartmentUuid !== '') {
      return filterDepartmentOptions.filter(
        department => department.uuid === selectedDepartmentUuid
      );
    } else {
      return filterDepartmentOptions;
    }
  };

  const handleFilterSelection = event => {
    const { name, value } = event.target;

    if (name === 'department') {
      dispatch(doSetDepartmentFilterForCourse(value));
      dispatch(doSetAcademicYearFilterForCourse(''));
      dispatch(doSetTermFilterForCourse(''));
      dispatch(doSetCourseMasterFilterForCourse(''));
    }

    if (name === 'academicYear') {
      dispatch(doSetAcademicYearFilterForCourse(value));
      dispatch(doSetTermFilterForCourse(''));
      dispatch(doSetCourseMasterFilterForCourse(''));

      dispatch(doGetCoursesByDeptAy(departmentUuid, value));
    }

    if (name === 'term') {
      dispatch(doSetTermFilterForCourse(value));
      dispatch(doSetCourseMasterFilterForCourse(''));
    }

    if (name === 'course') {
      dispatch(doSetCourseMasterFilterForCourse(value));
    }
  };

  const handleResetFilters = () => {
    dispatch(doSetDepartmentFilterForCourse(''));
    dispatch(doSetCourseMasterFilterForCourse(''));
    dispatch(doSetAcademicYearFilterForCourse(''));
    dispatch(doSetTermFilterForCourse(''));
  };

  return (
    <CourseFilter
      departmentUuid={departmentUuid}
      courseMasterUuid={courseMasterUuid}
      academicYearUuid={academicYearUuid}
      termUuid={termUuid}
      filterDepartmentOptions={filterDepartmentOptionsByActiveRole()}
      filterCourseMasterOptions={filterCourseMasterOptions}
      filterAcademicYearOptions={filterAcademicYearOptions}
      filterTermOptions={filterTermOptions}
      handleFilterSelection={handleFilterSelection}
      handleResetFilters={handleResetFilters}
    />
  );
}
