import { Model, attr } from 'redux-orm';
import { GET_TRANSCRIPT_SUCCESS } from '../actions-type';

class ApplicantNoncognitiveRank extends Model {
  static reducer(action, ApplicantNoncognitiveRank, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_TRANSCRIPT_SUCCESS: {
        const applicantNoncognitiveRank = [
          ...payload.applicantNoncognitiveRank
        ];
        applicantNoncognitiveRank.forEach(rank =>
          ApplicantNoncognitiveRank.upsert(rank)
        );
        break;
      }

      default:
        return session;
    }
  }
}

ApplicantNoncognitiveRank.fields = {
  uuid: attr(),
  cohortRankRelative: attr(),
  cohortRankPotential: attr(),
  cumulativeCredits: attr(),
  averageCreditScore: attr(),
  letterGrade: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  applicantUuid: attr(),
  cohortUuid: attr(),
  applicantNoncognitiveReportTypeUuid: attr(),
  classificationUuid: attr(),
  examAptitudeUuid: attr(),
  examMilestoneUuid: attr()
};

ApplicantNoncognitiveRank.modelName = 'ApplicantNoncognitiveRank';
ApplicantNoncognitiveRank.options = {
  idAttribute: 'uuid'
};

export default ApplicantNoncognitiveRank;
