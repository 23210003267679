import { Model, attr } from 'redux-orm';

import {
  DELETE_APP_ID_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_COHORT_APPLICANTS_BY_STATUS_SUCCESS,
  GET_COHORT_ASSESSMENT_SCORES_SUCCESS,
  GET_PART_FACULTY_SUCCESS,
  GET_RIGOR_HISTORICAL_REPORT_SUCCESS,
  GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS,
  GET_SCORES_SUCCESS,
  GET_STUDENT_ROSTER_SUCCESS,
  GET_SYLLABUS_COURSE_SUCCESS,
  GET_SYLLABUS_PART_SUCCESS,
  GET_SYLLABUS_SUCCESS,
  GET_TRANSCRIPT_SUCCESS,
  GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS,
  GET_USERS_BY_SEARCH_ROLES_SUCCESS,
  GET_USERS_BY_SEARCH_SUCCESS,
  GET_USERS_COHORT_SUCCESS,
  GET_USERS_SCHOOL_ADMIN_SUCCESS,
  GET_USERS_SCHOOL_SUCCESS,
  GET_USER_ACTIVE_SUCCESS,
  GET_USER_COHORT_BY_USER_AND_COHORT_SUCCESS,
  GET_USER_DEPARTMENTS_FACULTY_SUCCESS,
  GET_USER_DEPARTMENTS_SUCCESS,
  GET_USER_DEPARTMENT_SUCCESS,
  GET_USER_PROGRAMS_BY_PROGRAM_SUCCESS,
  GET_USER_SUCCESS,
  POST_INSTITUTION_RIGOR_SUCCESS,
  POST_UPLOAD_STUDENTS_SECTION_SUCCESS,
  POST_USER_APP_ID_SUCCESS,
  POST_USER_SUCCESS,
  PUT_USER_PROFILE_SUCCESS,
  PUT_USER_SUCCESS
} from '../actions-type';

class User extends Model {
  static reducer(action, User, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_USER_ACTIVE_SUCCESS: {
        const { profile } = payload;
        User.upsert(profile);
        break;
      }
      case GET_USER_SUCCESS: {
        const user = { ...payload.user };
        User.upsert(user);
        break;
      }
      case GET_USER_DEPARTMENTS_FACULTY_SUCCESS: {
        const faculty = [...payload.users];
        faculty.forEach(user => User.upsert(user));
        break;
      }
      case GET_USERS_SCHOOL_ADMIN_SUCCESS: {
        const { users } = payload;
        users.forEach(schoolAdmin => User.upsert(schoolAdmin));
        break;
      }
      case GET_USERS_BY_SEARCH_SUCCESS: {
        const searchUsers = [...payload.users];
        searchUsers.forEach(user => User.upsert(user));
        break;
      }
      case GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS: {
        const searchUsers = [...payload.users];
        searchUsers.forEach(user => User.upsert(user));
        break;
      }
      case POST_USER_SUCCESS: {
        User.create(payload.user);
        break;
      }
      case PUT_USER_SUCCESS: {
        const user = { ...payload.user };
        User.upsert(user);
        break;
      }
      case DELETE_USER_SUCCESS: {
        const { userUuid } = payload;
        User.withId(userUuid).delete();
        break;
      }
      case PUT_USER_PROFILE_SUCCESS: {
        User.upsert(payload.user);
        break;
      }
      case GET_USERS_SCHOOL_SUCCESS: {
        const users = [...payload.users];
        users.forEach(user => User.upsert(user));
        break;
      }
      case GET_USER_DEPARTMENT_SUCCESS: {
        const users = [...payload.users];
        users.forEach(user => User.upsert(user));
        break;
      }
      case GET_USERS_COHORT_SUCCESS: {
        const users = [...payload.users];
        users.forEach(user => User.upsert(user));
        break;
      }
      case GET_USER_PROGRAMS_BY_PROGRAM_SUCCESS: {
        const users = [...payload.users];
        users.forEach(user => User.upsert(user));
        break;
      }
      case GET_USER_DEPARTMENTS_SUCCESS: {
        const users = [...payload.users];
        users.forEach(user => User.upsert(user));
        break;
      }
      case GET_STUDENT_ROSTER_SUCCESS: {
        const users = [...payload.users];
        users.forEach(user => User.upsert(user));
        break;
      }
      case GET_USER_COHORT_BY_USER_AND_COHORT_SUCCESS: {
        const user = { ...payload.user };
        User.upsert(user);
        break;
      }
      case GET_COHORT_APPLICANTS_BY_STATUS_SUCCESS: {
        const userCohorts = [...payload.userCohorts];
        userCohorts.forEach(userCohort => User.upsert(userCohort.user));
        break;
      }
      case GET_SCHOOL_STUDENTS_BY_STATUS_SUCCESS: {
        const programs = [...payload.school.programs];
        programs.forEach(program => {
          const cohorts = [...program.cohorts];
          cohorts.forEach(cohort => {
            const userCohorts = [...cohort.user_cohorts];
            userCohorts.forEach(userCohort => {
              const user = { ...userCohort.user };
              User.upsert(user);
            });
          });
        });
        break;
      }
      case POST_UPLOAD_STUDENTS_SECTION_SUCCESS: {
        const users = [...payload.users];
        users.forEach(user => User.upsert(user));
        break;
      }
      case GET_COHORT_ASSESSMENT_SCORES_SUCCESS: {
        const users = [...payload.users];
        const userCohorts = [...payload.userCohorts];
        const programFaculty = [...payload.programFaculty];

        users.forEach(user => User.upsert(user));
        userCohorts.forEach(userSection => User.upsert(userSection.user));
        programFaculty.forEach(faculty => User.upsert(faculty.user));

        break;
      }
      case GET_SCORES_SUCCESS: {
        const users = [...payload.users];
        const userSections = [...payload.userSections];
        const partFaculty = [...payload.partFaculty];

        users.forEach(user => User.upsert(user));
        userSections.forEach(userSection => User.upsert(userSection.user));
        partFaculty.forEach(faculty => User.upsert(faculty.user));
        break;
      }
      case GET_PART_FACULTY_SUCCESS: {
        const partFaculty = [...payload.partFaculty];
        partFaculty.forEach(faculty => User.upsert(faculty.user));
        break;
      }
      case GET_SYLLABUS_SUCCESS: {
        const userSections = [...payload.userSections];
        userSections.forEach(userSection => {
          User.upsert(userSection.user);
        });
        break;
      }
      case GET_TRANSCRIPT_SUCCESS: {
        const user = { ...payload.user };
        User.upsert(user);
        break;
      }

      case GET_USERS_BY_SEARCH_ROLES_SUCCESS: {
        if (payload?.users?.length > 0) {
          const users = [...payload.users];
          users.forEach(user => User.upsert(user));
        }
        break;
      }

      case POST_USER_APP_ID_SUCCESS: {
        const user = { ...payload.user };
        User.upsert(user);

        break;
      }
      case DELETE_APP_ID_SUCCESS: {
        const user = { ...payload.user };
        User.upsert(user);
        break;
      }
      case POST_INSTITUTION_RIGOR_SUCCESS: {
        if (payload?.users?.length > 0) {
          const users = [...payload.users];
          users.forEach(user => User.upsert(user));
        }
        break;
      }

      case GET_RIGOR_HISTORICAL_REPORT_SUCCESS: {
        if (payload?.users?.length > 0) {
          const users = [...payload.users];
          users.forEach(user => User.upsert(user));
        }
        break;
      }
      case GET_SYLLABUS_COURSE_SUCCESS: {
        const course = { ...payload.course };
        const { sections } = course;

        if (sections?.length > 0) {
          sections.forEach(section => {
            const { parts } = section;

            if (parts?.length > 0) {
              parts.forEach(part => {
                const { part_faculty_assigned, part_directors_assigned } = part;

                if (part_faculty_assigned?.length > 0) {
                  part_faculty_assigned.forEach(faculty => {
                    const {
                      uuid,
                      institutionId,
                      institutionIdTypeUuid,
                      firstName,
                      middleName,
                      lastName,
                      marriedLastName,
                      suffix
                    } = faculty;

                    User.upsert({
                      uuid,
                      institutionId,
                      institutionIdTypeUuid,
                      firstName,
                      middleName,
                      lastName,
                      marriedLastName,
                      suffix
                    });
                  });
                }

                if (part_directors_assigned?.length > 0) {
                  part_directors_assigned.forEach(director => {
                    const {
                      uuid,
                      institutionId,
                      institutionIdTypeUuid,
                      firstName,
                      middleName,
                      lastName,
                      marriedLastName,
                      suffix
                    } = director;

                    User.upsert({
                      uuid,
                      institutionId,
                      institutionIdTypeUuid,
                      firstName,
                      middleName,
                      lastName,
                      marriedLastName,
                      suffix
                    });
                  });
                }
              });
            }
          });
        }

        break;
      }
      case GET_SYLLABUS_PART_SUCCESS: {
        const part = { ...payload.part };
        const assessments = [...payload.assessments];
        const encounters = [...payload.encounters];

        const { part_directors_assigned, part_faculty_assigned } = part;

        if (part_directors_assigned?.length > 0) {
          part_directors_assigned.forEach(director => {
            const {
              uuid,
              institutionId,
              institutionIdTypeUuid,
              firstName,
              middleName,
              lastName,
              marriedLastName,
              suffix
            } = director;

            User.upsert({
              uuid,
              institutionId,
              institutionIdTypeUuid,
              firstName,
              middleName,
              lastName,
              marriedLastName,
              suffix
            });
          });
        }

        if (part_faculty_assigned?.length > 0) {
          part_faculty_assigned.forEach(faculty => {
            const {
              uuid,
              institutionId,
              institutionIdTypeUuid,
              firstName,
              middleName,
              lastName,
              marriedLastName,
              suffix
            } = faculty;

            User.upsert({
              uuid,
              institutionId,
              institutionIdTypeUuid,
              firstName,
              middleName,
              lastName,
              marriedLastName,
              suffix
            });
          });
        }

        if (assessments.length > 0) {
          assessments.forEach(assessment => {
            const { responsible_faculty } = assessment;

            const {
              uuid,
              institutionId,
              institutionIdTypeUuid,
              firstName,
              middleName,
              lastName,
              marriedLastName,
              suffix
            } = responsible_faculty;

            User.upsert({
              uuid,
              institutionId,
              institutionIdTypeUuid,
              firstName,
              middleName,
              lastName,
              marriedLastName,
              suffix
            });
          });
        }

        if (assessments.length > 0) {
          assessments.forEach(assessment => {
            const { assessment_manager } = assessment;

            const {
              uuid,
              institutionId,
              institutionIdTypeUuid,
              firstName,
              middleName,
              lastName,
              marriedLastName,
              suffix
            } = assessment_manager;

            User.upsert({
              uuid,
              institutionId,
              institutionIdTypeUuid,
              firstName,
              middleName,
              lastName,
              marriedLastName,
              suffix
            });
          });
        }

        if (encounters.length > 0) {
          encounters.forEach(encounter => {
            const { encounter_faculty_assigned } = encounter;

            if (encounter_faculty_assigned?.length > 0) {
              encounter_faculty_assigned.forEach(faculty => {
                const {
                  uuid,
                  institutionId,
                  institutionIdTypeUuid,
                  firstName,
                  middleName,
                  lastName,
                  marriedLastName,
                  suffix
                } = faculty;

                User.upsert({
                  uuid,
                  institutionId,
                  institutionIdTypeUuid,
                  firstName,
                  middleName,
                  lastName,
                  marriedLastName,
                  suffix
                });
              });
            }
          });
        }

        if (encounters.length > 0) {
          encounters.forEach(encounter => {
            const { faculty_lead } = encounter;

            if (faculty_lead) {
              const {
                uuid,
                institutionId,
                institutionIdTypeUuid,
                firstName,
                middleName,
                lastName,
                marriedLastName,
                suffix
              } = faculty_lead;

              User.upsert({
                uuid,
                institutionId,
                institutionIdTypeUuid,
                firstName,
                middleName,
                lastName,
                marriedLastName,
                suffix
              });
            }
          });
        }

        break;
      }
      default:
        return session;
    }
  }
}

User.fields = {
  uuid: attr(),
  institutionId: attr(),
  institutionIdTypeUuid: attr(),
  firstName: attr(),
  middleName: attr(),
  lastName: attr(),
  marriedLastName: attr(),
  birthDate: attr(),
  appIdAdmin: attr(),
  appIdStudent: attr(),
  email: attr(),
  phone: attr(),
  gender: attr(),
  ethnicity: attr(),
  suffix: attr(),
  textMessage: attr(),
  notes: attr()
};

User.modelName = 'User';
User.options = {
  idAttribute: 'uuid'
};

export default User;
