import fetch from '../helpers/fetch';

const postCompetency = newCompetency =>
  fetch(`/api/competency/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(newCompetency)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putCompetency = ({ uuid, ...updates }) =>
  fetch(`/api/competency/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(updates)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putCompetencyAddMicros = ({ uuid, micros }) =>
  fetch(`/api/competency/${uuid}/add-microcompetencies`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ micros })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteCompetency = uuid =>
  fetch(`/api/competency/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  postCompetency,
  putCompetency,
  putCompetencyAddMicros,
  deleteCompetency
};
