/* eslint no-unused-vars: off */
import React, { useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import EditProgramAssessmentCollection from './EditProgramAssessmentCollection';

import {
  allFieldsAreValid,
  validateStringLength
} from '../../../../helpers/validation/validateGeneric';
import {
  programAssessmentCollectionReducer,
  initialProgramAssessmentCollectionState,
  setShowValidationErrors
} from './programAssessmentCollectionState';
import { programAssessmentCollectionSelector } from '../../../../redux/selectors/programSelectors';
import { doPutProgramAssessmentCollection } from '../../../../redux/actions/programActions';

const EditProgramAssessmentCollectionContainer = ({
  programAssessmentCollectionUuid,
  toggleEditView
}) => {
  const dispatch = useDispatch();

  const [state, editDispatch] = useReducer(
    programAssessmentCollectionReducer,
    initialProgramAssessmentCollectionState
  );

  const existingCollection = useSelector(state =>
    programAssessmentCollectionSelector(state, programAssessmentCollectionUuid)
  );

  const setExistingState = existingCollection => {
    editDispatch({
      type: 'setExisting',
      payload: { ...existingCollection }
    });
  };

  const { uuid, title, showValidationErrors } = state;

  const onPutUpdate = payload =>
    dispatch(doPutProgramAssessmentCollection(payload));

  useEffect(() => {
    if (programAssessmentCollectionUuid && existingCollection.uuid !== uuid) {
      setExistingState(existingCollection);
    }
  }, [programAssessmentCollectionUuid, existingCollection, uuid]);

  const handleTitleChange = event => {
    editDispatch({
      type: 'setTitle',
      payload: { title: event.target.value }
    });
  };

  const setValidationErrors = ({ title }) => {
    const nameError = validateStringLength(title, 100, true);

    const newValidationErrors = {
      nameError
    };

    return newValidationErrors;
  };

  const onSubmit = () => {
    const newValidationErrors = setValidationErrors({
      title
    });

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid,
        title,
        programUuid: existingCollection.programUuid
      };

      onPutUpdate(payload);
      editDispatch(setShowValidationErrors(false));
    } else {
      editDispatch(setShowValidationErrors(true));
    }
  };

  return (
    <EditProgramAssessmentCollection
      title={title}
      handleTitleChange={handleTitleChange}
      onSubmit={onSubmit}
      showValidationErrors={showValidationErrors}
      toggleEditView={toggleEditView}
    />
  );
};

EditProgramAssessmentCollectionContainer.propTypes = {
  programAssessmentCollectionUuid: PropTypes.string.isRequired,
  toggleEditView: PropTypes.func
};

EditProgramAssessmentCollectionContainer.defaultProps = {
  toggleEditView: undefined
};

export default EditProgramAssessmentCollectionContainer;
