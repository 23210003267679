import React from 'react';
import { useSelector } from 'react-redux';
import SyllabusDetail from './SyllabusDetail';

export default function SyllabusDetailContainer() {
  const selectedType = useSelector(state => state.syllabusState.selectedType);

  return <SyllabusDetail selectedType={selectedType} />;
}

SyllabusDetailContainer.propTypes = {};

SyllabusDetailContainer.defaultProps = {};
