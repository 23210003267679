import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { sortByProperty } from '../../helpers/utilities';
import { orm } from '../models';

export const competenciesSelector = createSelector(
  state => state,
  (_, competencyCollectionUuid) => competencyCollectionUuid,
  (redux, competencyCollectionUuid) => {
    const competenciesSelectorORM = ormCreateSelector([orm], session => {
      const competencies = session.Competency.all()
        .filter(competency => {
          return (
            competency.competencyCollectionUuid === competencyCollectionUuid
          );
        })
        .toModelArray()
        .map(competency => ({
          ...competency.ref
        }));
      return competencies.sort((a, b) => sortByProperty(a, b, 'order'));
    });

    const competencies = competenciesSelectorORM(redux);
    return competencies;
  }
);

export const competencySelector = createSelector(
  state => state,
  (_, competencyUuid) => competencyUuid,
  (redux, competencyUuid) => {
    const competencySelectorORM = ormCreateSelector([orm], session => {
      return session.Competency.withId(competencyUuid);
    });

    const competency = competencySelectorORM(redux);
    const returnCompetency = competency ? { ...competency.ref } : null;
    return returnCompetency;
  }
);
