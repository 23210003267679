import { v4 as uuidv4 } from 'uuid';

const subjectInitialState = {
  subjects: [],
  subjectsRemove: []
};

const subjectReducer = (state, action) => {
  switch (action.type) {
    case 'populate': {
      const subjects = [...action.subjects];
      return {
        ...state,
        subjects
      };
    }
    case 'add_subject': {
      return {
        ...state,
        subjects: [
          ...state.subjects,
          { uuid: uuidv4(), scope: '', weight: '0.00', add: true }
        ]
      };
    }
    case 'update_scope': {
      const { uuid, scope } = { ...action.subject };

      const subjects = state.subjects.map(subject => {
        if (subject.uuid === uuid) {
          const replace = { ...subject, scope };
          return replace;
        } else {
          return subject;
        }
      });

      return {
        ...state,
        subjects
      };
    }
    case 'update_weight': {
      const { uuid, weight } = { ...action.subject };

      const subjects = state.subjects.map(subject => {
        if (subject.uuid === uuid) {
          const replace = { ...subject, weight };
          return replace;
        } else {
          return subject;
        }
      });

      return {
        ...state,
        subjects
      };
    }
    case 'destroy_subject': {
      const item = { ...action.subject };

      const subjects = state.subjects.filter(
        subject => subject.uuid !== item.uuid
      );

      const subjectsRemove = item.new
        ? [...state.subjectsRemove]
        : [...state.subjectsRemove, item];

      return {
        ...state,
        subjects,
        subjectsRemove
      };
    }
    default:
      throw new Error();
  }
};

export { subjectInitialState, subjectReducer };
