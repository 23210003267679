/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import {
  DocumentIcon,
  DateIcon,
  UserIcon,
  UsersIcon,
  ObjectivesIcon
} from '@xcomp/xcomp-design-library/dist/icons';

// import ButtonPrimary from '@xcomp/xcomp-design-library/dist/components/ButtonPrimary';
import FormRead from '../../Library/FormRead';
import { ContainerContent, ElevateLow } from '../../Library/Layout';

const Layout = styled.div`
  padding: 3rem;
`;

const FormLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 19px;
  text-transform: uppercase;
  svg {
    vertical-align: top;
    margin-right: 0.5rem;
  }
`;

const FormInput = styled.div`
  padding-left: 30px;
`;

const Loverb = styled.span`
  padding-left: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 12px;
`;

const Index = styled.span``;

const Objectives = styled.div``;

const Objective = styled.span`
  padding-left: 20px;
  font-size: 12px;
`;

const FormHeader = styled.div`
  padding: 30px;
  font-size: 24px;
  border-bottom: 2px solid ${props => props.theme.colors.grey[300]};
`;

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
`;

const SubTitle = styled.div`
  font-size: 18px;
`;

export default function Encounter({ encounter, selectedOrder }) {
  const itemStartDate = encounter.linkedAssessmentUuid
    ? encounter.linked_assessment.startDate
    : encounter.startDate;
  const itemEndDate = encounter.linkedAssessmentUuid
    ? encounter.linked_assessment.endDate
    : encounter.endDate;

  return (
    <Layout>
      <ElevateLow>
        <FormHeader>
          <Title>
            Encounter {(selectedOrder + 1).toString().padStart(2, '0')}
          </Title>
          <SubTitle>{encounter?.title}</SubTitle>
        </FormHeader>
        <ContainerContent paddingTop="40px">
          <Grid container spacing={4}>
            <Grid item sm={12} xs={12}>
              <FormLabel>
                <DocumentIcon />
                Encounter Type
              </FormLabel>
            </Grid>

            <Grid item sm={12} xs={12}>
              <FormInput>{encounter?.title}</FormInput>
            </Grid>

            <Grid item sm={12} xs={12}>
              <FormLabel>
                <DateIcon />
                Time and Location
              </FormLabel>
            </Grid>

            <Grid item sm={12} xs={12}>
              <FormInput>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={12}>
                    <FormRead
                      label="Start Date and Time"
                      value={moment(itemStartDate).format('LL, h:mm a')}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormRead
                      label="End Date and Time"
                      value={moment(itemEndDate).format('LL, h:mm a')}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormRead label="Location" value={encounter?.location} />
                  </Grid>
                </Grid>
              </FormInput>
            </Grid>

            <Grid item sm={12} xs={12}>
              <FormLabel>
                <UsersIcon />
                Encounter Faculty
              </FormLabel>
            </Grid>

            <Grid item sm={12} xs={12}>
              <FormInput>
                <FormRead
                  label="Lead Faculty"
                  value={`${encounter?.faculty_lead?.firstName} ${encounter?.faculty_lead?.lastName}`}
                />
              </FormInput>
            </Grid>

            <Grid item sm={12} xs={12}>
              <FormInput>
                <FormRead label="Partcipating Faculty" value="" />
              </FormInput>
              <FormInput>
                {encounter?.encounter_faculty_assigned.length > 0
                  ? encounter?.encounter_faculty_assigned?.map(faculty => (
                      <Chip
                        key={faculty.uuid}
                        avatar={
                          <Avatar>
                            <UserIcon />
                          </Avatar>
                        }
                        variant="outlined"
                        size="small"
                        label={`${faculty?.firstName} ${faculty?.lastName}`}
                      />
                    ))
                  : '- -'}
              </FormInput>
            </Grid>

            <Grid item sm={12} xs={12}>
              <FormLabel>
                <ObjectivesIcon />
                Learning Objectives
              </FormLabel>
            </Grid>
            <Grid item sm={12} xs={12}>
              {encounter?.objectives?.map((objective, index) => (
                <FormInput key={objective?.uuid}>
                  <Objectives>
                    <Index>{index + 1}.</Index>
                    <Loverb>{objective?.loverb?.objectiveVerb}</Loverb>
                    <Objective>{objective?.learningObjective}</Objective>
                  </Objectives>
                </FormInput>
              ))}
            </Grid>

            <Grid item sm={12} xs={12}>
              <FormLabel>
                <DocumentIcon />
                Notes and Additional Reading
              </FormLabel>
            </Grid>

            <Grid item sm={12} xs={12}>
              <FormInput>{encounter?.notes}</FormInput>
            </Grid>

            <Grid item sm={12} xs={12}>
              <FormLabel>
                <DocumentIcon />
                Linked Assessments
              </FormLabel>
            </Grid>

            <Grid item sm={12} xs={12}>
              <FormInput>{encounter?.linked_assessment?.title}</FormInput>
            </Grid>
          </Grid>
        </ContainerContent>
      </ElevateLow>
    </Layout>
  );
}

Encounter.propTypes = {
  encounter: PropTypes.object,
  selectedOrder: PropTypes.number
};

Encounter.defaultProps = {
  encounter: undefined,
  selectedOrder: 0
};
