import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import { sortByProperty } from '../../helpers/utilities';
import { orm } from '../models';
import { userSectionStatus } from '../../helpers/constants';

export const selectCourseCollection = ormCreateSelector(orm.CourseCollection);

export const courseCollectionsSelector = createSelector(
  state => state,
  (_, cohortUuid) => cohortUuid,
  (redux, cohortUuid) => {
    const courseCollectionSelectorORM = ormCreateSelector([orm], session => {
      const courseCollections = session.CourseCollection.all()
        .filter(collection => {
          return collection.cohortUuid === cohortUuid;
        })
        .toRefArray();
      return courseCollections.sort((a, b) => {
        if (a.special_collection && !b.special_collection) {
          return 1;
        } else if (a.special_collection && b.special_collection) {
          return sortByProperty(a, b, 'name');
        } else if (!a.special_collection && b.special_collection) {
          return -1;
        } else if (a.all_credits_required && !b.all_credits_required) {
          return -1;
        } else if (!a.all_credits_required && b.all_credits_required) {
          return 1;
        } else {
          return sortByProperty(a, b, 'name');
        }
      });
    });

    const courseCollections = courseCollectionSelectorORM(redux);
    return courseCollections;
  }
);

export const courseCollectionSelector = createSelector(
  state => state,
  (_, uuid) => uuid,
  (redux, uuid) => {
    const courseCollectionSelectorORM = ormCreateSelector([orm], session => {
      const courseCollection = session.CourseCollection.withId(uuid);

      if (!courseCollection) {
        return null;
      }

      return {
        ...courseCollection.ref
      };
    });

    const courseCollections = courseCollectionSelectorORM(redux);
    return courseCollections;
  }
);

export const parentCohortSelector = createSelector(
  state => state,
  (_, cohortUuid) => cohortUuid,
  (redux, cohortUuid) => {
    const cohortsSelectorORM = ormCreateSelector(orm.Cohort);
    const cohort = cohortsSelectorORM(redux, cohortUuid);
    return cohort;
  }
);

export const selectCollectionCredits = createSelector(
  state => state,
  (_, userUuid, courseCollectionUuid) => ({ userUuid, courseCollectionUuid }),
  (redux, paramaters) => {
    const { courseCollectionUuid, userUuid } = paramaters;

    const getCourseCollectionCourse = ormCreateSelector(
      orm.CourseCollection.course_collection
    );
    const getSection = ormCreateSelector(orm.Section);
    const getCourse = ormCreateSelector(orm.Course);
    const getCourseMaster = ormCreateSelector(orm.CourseMaster);
    const getUserSection = ormCreateSelector(orm.UserSection);
    const results = [];

    const courseCollectionCourses = getCourseCollectionCourse(
      redux,
      courseCollectionUuid
    );

    const courseCollectionCoursesMasters = _.map(
      courseCollectionCourses,
      course => course.courseMasterUuid
    );

    const userSections = getUserSection(redux).filter(
      us =>
        us.userUuid === userUuid &&
        (us.status === userSectionStatus.credit ||
          us.status === userSectionStatus.pass ||
          us.status === userSectionStatus.pass_with_remediation ||
          us.status === userSectionStatus.fail ||
          us.status === userSectionStatus.fail_with_remediation)
    );

    userSections.forEach(userSection => {
      const section = getSection(redux, userSection.sectionUuid);
      const course = getCourse(redux, section.courseUuid);
      const courseMaster = getCourseMaster(redux, course.courseMasterUuid);

      if (_.includes(courseCollectionCoursesMasters, courseMaster.uuid)) {
        results.push({ courseMaster, userSection });
      }
    });

    const gradePointResults = _(results)
      .map(record => ({ credit: record?.courseMaster?.hours }))
      .value();

    const creditsTotal = _.reduce(
      gradePointResults,
      (total, record) => {
        const creditDecimal = new Decimal(record?.credit);
        return total.add(creditDecimal);
      },
      new Decimal(0)
    );

    const credits_total = creditsTotal.toFixed(2).toString();

    return credits_total;
  }
);
