import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { EditIcon } from '@xcomp/xcomp-design-library/dist/icons';

import {
  formatLastName,
  formatFirstNameMiddleName
} from '../../../../helpers/format/user.format';

const UserInfo = ({ user }) => {
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={4}>
      <Grid item sm={2} xs={2}>
        {user.institutionId}
      </Grid>
      <Grid item sm={8} xs={8}>
        {`${formatLastName(
          user?.lastName,
          user?.marriedLastName,
          user?.suffix
        )},
        ${formatFirstNameMiddleName(user?.firstName, user?.middleName)}`}
      </Grid>
      <Grid item sm={2} xs={2}>
        <Grid container justifyContent="center">
          <Link to={`/user-management/user-edit/${user.uuid}/info`}>
            <EditIcon />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

UserInfo.propTypes = {
  user: PropTypes.object
};

UserInfo.defaultProps = {
  user: {}
};

export default UserInfo;
