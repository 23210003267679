import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const selectInstitution = ormCreateSelector(orm.Institution);

export const institutionActiveUserSelector = createSelector(
  state => state,
  state => state.userState.institutionUuid,
  (redux, institutionUuid) => {
    const institutionActiveUserSelectorORM = ormCreateSelector(orm.Institution);
    const result =
      institutionActiveUserSelectorORM(redux, institutionUuid) || undefined;

    return result;
  }
);

export const institutionSelector = createSelector(
  state => state,
  (_, institutionUuid) => institutionUuid,
  (redux, institutionUuid) => {
    const institutionActiveUserSelectorORM = ormCreateSelector(orm.Institution);
    const result =
      institutionActiveUserSelectorORM(redux, institutionUuid) || {};

    return result;
  }
);
