import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from 'styled-components';
import { format } from 'date-fns';
import PageIllustration from '../Library/PageIllustration';
import Pagination from '../Library/Pagination/Pagination';

const LogsContainer = styled.div`
  padding: 5px 20px;
  margin-top: 40px;
`

export const getColor = (method, theme, shade) => {
  return (
    method === 'GET' && theme.colors.blue[shade || 500] ||
    method === 'POST' && theme.colors.green[shade || 500] ||
    method === 'PUT' && theme.colors.orange[shade || 500] ||
    method === 'PATCH' && theme.colors.orange[shade || 500] ||
    method === 'DELETE' && theme.colors.red[shade || 500] ||
    theme.colors.grey[shade || 500]
  )
} 

const LogCard = styled.div`
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background: ${props => props.theme.colors.secondaryShades[100]};
  margin-bottom: 10px;
  background: ${({$methodLabel, theme}) => getColor($methodLabel, theme, 100)};
  border: 2px solid ${({$methodLabel, theme}) => getColor($methodLabel, theme)};

  &:hover {
    cursor: pointer;
    background: ${({$methodLabel, theme}) => getColor($methodLabel, theme, 200)};
  }
`

const Chip = styled.div`
  display: flex;
  justify-content: center;
  width: ${props => props.$fitContent ? 'fit-content' : '80px'};
  align-items: center;
  margin-right: 10px;
  height: 40px;
  border-radius: 3px;
  padding: 2px 10px;
  color: ${props => props.theme.colors.white};
  background: ${({$methodLabel, theme}) => getColor($methodLabel, theme)};
`


const Logs = ({error, data, isMutating, setOpenModal}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  return (
    <LogsContainer>
      {isMutating && <LinearProgress style={{margin: '10px 0'}} />}
      {error &&           
        <PageIllustration
          infoText="An error occured while trying to fetch the data. Please try again."
        />
      }
      {data && !error && !isMutating && 
        <div>
          <Pagination 
            currentPage={currentPage} 
            onChangePage={setCurrentPage} 
            numberOfPages={Math.ceil(data.length / itemsPerPage)} 
            numberOfItems={data.length}
            itemsPerPage={itemsPerPage} 
            onChangeNumberOfItems={setItemsPerPage}
            style={{marginBottom: 30, marginTop: 10}}
          />
          {data.map(log => 
            <LogCard key={log.uuid} $methodLabel={log.method} onClick={() => setOpenModal(log.uuid)}>
              <Chip $methodLabel={log.method}>{log.method}</Chip>
              <Chip $methodLabel={log.action} $fitContent={true}>{log.action}</Chip>
              <div>{log.authorFullName}</div>
              <div style={{marginLeft: 'auto'}}>{format(log.createdAt, 'MMMM d, yyyy h:mm aa')}</div>
            </LogCard>
          )}
          <Pagination 
            currentPage={currentPage} 
            onChangePage={setCurrentPage} 
            numberOfPages={Math.ceil(data.length / itemsPerPage)} 
            numberOfItems={data.length}
            itemsPerPage={itemsPerPage} 
            onChangeNumberOfItems={setItemsPerPage}
            style={{marginBottom: 10, marginTop: 30}}
          />
        </div>
      }
    </LogsContainer>
  );
}

Logs.propTypes = {
  error: PropTypes.object,
  data: PropTypes.array,
  isMutating: PropTypes.bool,
  setOpenModal: PropTypes.func.isRequired
};

Logs.defaultProps = {
  error: null,
  data: null,
  isMutating: false,
};


export default Logs;
