import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import { PanelHeader } from '../../../DashboardSC';
import FacultyAlternativeScoreViewStudentPanelContainer from './FacultyAlternativeScoreViewStudentPanelContainer';

const StudentPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 24px;
  width: calc(100% - 24px);
`;

const StudentPanelHeaderInfo = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  font-weight: 450;
`;

const StudentPanelHeaderSub = styled(({ paddingRight, ...rest }) => (
  <div {...rest} />
))`
  padding-right: ${props => props.paddingRight};
  color: ${props => props.theme.fontColors.dark};
  text-align: right;
  }
`;

const FacultyAlternativeScoreViewList = ({
  assessmentScores,
  faculty,
  scoreType,
  stepScoreSummary
}) => {
  return (
    <>
      <PanelHeader paddingLeft="0px">
        <StudentPanelHeader>
          <StudentPanelHeaderInfo>
            Student (Last name, First name)
          </StudentPanelHeaderInfo>
          <StudentPanelHeaderInfo>
            <StudentPanelHeaderSub paddingRight="24px">
              Total Relative
            </StudentPanelHeaderSub>
            <StudentPanelHeaderSub paddingRight="44px">
              Total Potential
            </StudentPanelHeaderSub>
          </StudentPanelHeaderInfo>
        </StudentPanelHeader>
      </PanelHeader>
      {stepScoreSummary.map(studentScoreSummary => {
        return (
          <LazyLoad key={studentScoreSummary.studentUuid}>
            <FacultyAlternativeScoreViewStudentPanelContainer
              assessmentScores={assessmentScores}
              faculty={faculty}
              scoreType={scoreType}
              studentFullName={studentScoreSummary.fullName}
              studentTotalPotentialScore={studentScoreSummary.totalPot}
              studentTotalRelativeScore={studentScoreSummary.totalRel}
              studentUuid={studentScoreSummary.studentUuid}
            />
          </LazyLoad>
        );
      })}
    </>
  );
};

FacultyAlternativeScoreViewList.propTypes = {
  assessmentScores: PropTypes.array,
  faculty: PropTypes.array,
  scoreType: PropTypes.string,
  stepScoreSummary: PropTypes.array
};

FacultyAlternativeScoreViewList.defaultProps = {
  assessmentScores: [],
  faculty: [],
  scoreType: '',
  stepScoreSummary: []
};

export default FacultyAlternativeScoreViewList;
