import { v4 as uuidv4 } from 'uuid';

const moment = require('moment');

const mockNewCourse = {
  department: 'Biology',
  academic_year: '2018-2019',
  term: 'fall',
  courseId: 'DENT1201',
  courseNumber: '123456789',
  title: 'course title',
  hours: '30.5',
  description: 'some description'
};

const fakeDepartments = [
  {
    uuid: '10a53390-013f-47cc-8bd9-90959af60cca',
    name: 'DENT-Dentistry',
    code: 'DENT'
  },
  {
    uuid: '060b209a-fef3-4a05-b605-1f77799d4b4d',
    name: 'PERI-Periodontology',
    code: 'PERI'
  },
  {
    uuid: '509ae4f7-1a17-41e8-8f5d-305ba90ef2fa',
    name: 'ENDO-Endodontology',
    code: 'ENDO'
  },
  {
    uuid: 'b02a92b1-61b5-4d8c-a473-4f9fb01ee2e0',
    name: 'GEND-General Dentistry',
    code: 'GEND'
  }
];

const generateNewId = () => uuidv4().toString();

const createMockNewCourse = () => ({
  uuid: 'b02a92b1-61b5-4d8c-a473-4f9fb01ee2e0',
  courseMasterUuid: 'b12a92b1-61b5-4d8c-a473-4f9fb01ee2e0',
  status: 'COMMITTED',
  createdAt: '2018-09-13T22:58:06.642Z',
  updatedAt: '2018-09-13T22:58:06.642Z',
  academicYearUuid: '060b209a-fef3-4a05-b605-1f77799d4b4d',
  termUuid: '509ae4f7-1a17-41e8-8f5d-305ba90ef2fa',
  academic_year: {
    uuid: '060b209a-fef3-4a05-b605-1f77799d4b4d',
    startDate: moment('2017-01-01'),
    endDate: moment('2017-12-31'),
    createdAt: '2018-09-13T22:58:06.627Z',
    updatedAt: '2018-09-13T22:58:06.627Z'
  },
  term: {
    uuid: '509ae4f7-1a17-41e8-8f5d-305ba90ef2fa',
    period: 'Term 1',
    createdAt: '2018-09-13T22:58:06.632Z',
    updatedAt: '2018-09-13T22:58:06.632Z'
  }
});

const mockCourseWithSections = () => ({
  uuid: 'b02a92b1-61b5-4d8c-a473-4f9fb01ee2e0',
  courseMasterUuid: 'b12a92b1-61b5-4d8c-a473-4f9fb01ee2e0',
  status: 'COMMITTED',
  createdAt: '2018-09-13T22:58:06.642Z',
  updatedAt: '2018-09-13T22:58:06.642Z',
  academicYearUuid: '060b209a-fef3-4a05-b605-1f77799d4b4d',
  termUuid: '509ae4f7-1a17-41e8-8f5d-305ba90ef2fa',
  academic_year: {
    uuid: '060b209a-fef3-4a05-b605-1f77799d4b4d',
    startDate: moment('2017-01-01'),
    endDate: moment('2017-12-31'),
    createdAt: '2018-09-13T22:58:06.627Z',
    updatedAt: '2018-09-13T22:58:06.627Z'
  },
  term: {
    uuid: '509ae4f7-1a17-41e8-8f5d-305ba90ef2fa',
    period: 'Term 1',
    createdAt: '2018-09-13T22:58:06.632Z',
    updatedAt: '2018-09-13T22:58:06.632Z'
  },
  sections: [
    {
      uuid: '9418489e-c205-4f9a-8f44-ff8a6cbd06d2',
      courseId: '3392883732',
      createdAt: '2018-09-13T22:58:06.663Z',
      updatedAt: '2018-09-13T22:58:06.663Z',
      courseUuid: 'b02a92b1-61b5-4d8c-a473-4f9fb01ee2e0',
      section_directors_assigned: [{ userUuid: 'Dewayne Johnston' }]
    }
  ]
});

const mockNewSection = () => ({
  uuid: '9418489e-c205-4f9a-8f44-ff8a6cbd06d2',
  courseId: '3392883732',
  sectionNumber: 1,
  createdAt: '2018-09-13T22:58:06.663Z',
  updatedAt: '2018-09-13T22:58:06.663Z',
  courseUuid: 'b02a92b1-61b5-4d8c-a473-4f9fb01ee2e0',
  section_directors_assigned: [{ userUuid: 'Dewayne Johnston' }]
});

const mockNewEncounter = () => ({
  uuid: 'some-uuid',
  title: 'fake class',
  encounterTypeUuid: 'fake type uuid',
  startDate: '2018-08-22 13:59:24.941-06',
  endDate: '2018-08-22 13:59:24.941-06',
  location: 'Trent Hall 312',
  facultyLead: 'fake person',
  encounter_faculty_assigned: [{ userUuid: 'foobar' }],
  leadPercent: 3.2,
  maxRevus: null,
  partUuid: '904bb7e8-2aea-4661-9cee-f9cfbd57208d',
  scheduleType: 'Single',
  notes: '',
  objectives: [
    {
      createdAt: '2018-10-12T18:28:29.945Z',
      encounterUuid: 'd61ecf1b-a29d-427a-aa18-a131845ed846',
      learningObjective: 'enim totam aut',
      loverbUuid: '984961d0-c969-4678-b01c-51746a131b5d',
      microcompetency: '0000.0000.0000..0000 - veritatis',
      objectiveNumber: 1,
      revus: '1.00',
      updatedAt: '2018-10-12T18:28:29.945Z',
      isNew: true,
      uuid: '462c0336-1ac7-4b30-af0b-981af778502e'
    }
  ]
});

const mockNewAssessment = () => ({
  uuid: 'some-uuid',
  status: 'In Progress',
  assessmentTypeUuid: 'fake type',
  assessmentAssociationUuid: 'fakeAssociation',
  assessmentTechniqueUuid: 'fakeTechnique',
  title: 'fake assessment',
  startDate: '2018-08-22 13:59:24.941-06',
  endDate: '2018-08-22 13:59:24.941-06',
  timespan: 'WEEKLY',
  responsibleFaculty: 'foobar',
  assessmentManager: 'foobar',
  partUuid: '904bb7e8-2aea-4661-9cee-f9cfbd57208d'
});

const mockNewAssessmentAssociation = () => ({
  uuid: '6eeaedfb-a1d0-4090-8572-a191dffa422f',
  association: 'Milestone',
  order: 2,
  createdAt: '2018-09-13T22:58:06.706Z',
  updatedAt: '2018-09-13T22:58:06.706Z'
});

const mockNewAssessmentTechnique = () => ({
  uuid: '4a9edc28-f1e8-415b-a45c-d74a44f70f78',
  technique: 'Formative',
  order: 1,
  createdAt: '2018-09-13T22:58:06.711Z',
  updatedAt: '2018-09-13T22:58:06.711Z'
});

const mockNewAssessmentType = () => ({
  uuid: 'a1d48ed5-f315-458c-aad2-4f1c97176348',
  name: 'Basic Didactic',
  order: 2,
  createdAt: '2018-09-13T22:58:06.696Z',
  updatedAt: '2018-09-13T22:58:06.696Z'
});

const mockNewLoverb = () => ({
  uuid: '984961d0-c969-4678-b01c-51746a131b5d',
  objectiveVerb: 'Define',
  createdAt: '2018-09-13T22:58:06.419Z',
  updatedAt: '2018-09-13T22:58:06.419Z'
});

const mockNewSchool = () => ({
  schoolName: 'ECU'
});

const mockNewPart = () => ({
  uuid: '0df52947-9dd0-4f54-94c9-011ef4ebb6a7',
  partNumber: 1,
  title: 'exercitationem est sint',
  status: 'In Progress',
  part_directors_assigned: [{ userUuid: 'foobar' }],
  part_faculty_assigned: [{ userUuid: 'Winifred Winifred' }],
  createdAt: '2018-09-13T22:58:06.679Z',
  updatedAt: '2018-09-13T22:58:06.679Z',
  sectionUuid: '2ffd8c2f-fb8a-46d3-854d-c7bdc7560525'
});

const mockCourseWithSectionsAndParts = () => ({
  uuid: 'b02a92b1-61b5-4d8c-a473-4f9fb01ee2e0',
  courseNumber: 'DENT1001',
  title: 'Basic Dentistry',
  hours: 6,
  description:
    'Commodi natus et cum. Blanditiis quis nemo ut iusto iure fugiat exercitationem. Natus et nobis et excepturi perspiciatis fugit eius totam. Ut consequatur a et beatae veritatis excepturi voluptatum est est.',
  status: 'COMMITTED',
  createdAt: '2018-09-13T22:58:06.642Z',
  updatedAt: '2018-09-13T22:58:06.642Z',
  departmentUuid: '10a53390-013f-47cc-8bd9-90959af60cca',
  academicYearUuid: '060b209a-fef3-4a05-b605-1f77799d4b4d',
  termUuid: '509ae4f7-1a17-41e8-8f5d-305ba90ef2fa',
  department: {
    uuid: '10a53390-013f-47cc-8bd9-90959af60cca',
    name: 'DENT-Dentistry',
    code: 'DENT',
    createdAt: '2018-09-13T22:58:06.624Z',
    updatedAt: '2018-09-13T22:58:06.624Z'
  },
  academic_year: {
    uuid: '060b209a-fef3-4a05-b605-1f77799d4b4d',
    startDate: moment('2017-01-01'),
    endDate: moment('2017-12-31'),
    createdAt: '2018-09-13T22:58:06.627Z',
    updatedAt: '2018-09-13T22:58:06.627Z'
  },
  term: {
    uuid: '509ae4f7-1a17-41e8-8f5d-305ba90ef2fa',
    period: 'Term 1',
    createdAt: '2018-09-13T22:58:06.632Z',
    updatedAt: '2018-09-13T22:58:06.632Z'
  },
  sections: [
    {
      uuid: '9418489e-c205-4f9a-8f44-ff8a6cbd06d2',
      courseId: '3392883732',
      sectionNumber: 1,
      createdAt: '2018-09-13T22:58:06.663Z',
      updatedAt: '2018-09-13T22:58:06.663Z',
      courseUuid: 'b02a92b1-61b5-4d8c-a473-4f9fb01ee2e0',
      section_directors_assigned: [{ userUuid: 'Dewayne Johnston' }],
      parts: [mockNewPart()]
    }
  ]
});

const fakeAcademicYears = [
  {
    uuid: '509ae4f7-1a17-41e8-8f5d-305ba90ef2fa',
    startDate: moment('2017-01-01'),
    endDate: moment('2017-12-31')
  },
  {
    uuid: '9418489e-c205-4f9a-8f44-ff8a6cbd06d2',
    startDate: moment('2018-01-01'),
    endDate: moment('2018-12-31')
  }
];

const fakeTerms = [
  {
    uuid: '9418489e-c205-4f9a-8f44-ff8a6cbd06d2',
    period: 'Term 1 - Fall 2017'
  },
  {
    uuid: '19581489e-c205-4f9a-8f44-ff8a6cbd06d2',
    period: 'Term 2 - Spring 2018'
  },
  {
    uuid: '9407439e-c205-4f9a-8f44-ff8a6cbd06d2',
    period: 'Term 3 - Summer 2018'
  },
  {
    uuid: '904bb7e8-2aea-4661-9cee-f9cfbd57208d',
    period: 'Term 4 - Fall 2017'
  },
  {
    uuid: '90kd8s90-2aea-4661-9cee-f9cfbd57208d',
    period: 'Term 5 - Spring 2018'
  },
  {
    uuid: '905aw9se-2aea-4661-9cee-f9cfbd57208d',
    period: 'Term 6 - Summer 2018'
  }
];

export {
  fakeDepartments,
  fakeAcademicYears,
  fakeTerms,
  generateNewId,
  createMockNewCourse,
  mockNewCourse,
  mockCourseWithSections,
  mockCourseWithSectionsAndParts,
  mockNewSection,
  mockNewEncounter,
  mockNewAssessment,
  mockNewAssessmentAssociation,
  mockNewAssessmentTechnique,
  mockNewAssessmentType,
  mockNewLoverb,
  mockNewSchool,
  mockNewPart
};
