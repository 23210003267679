import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { colors } from '@xcomp/xcomp-design-library';

const BlockTitles = styled.div`
  padding-left: 20px;
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
  margin-top: 2rem;
`;

const BlockTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width};
  ${props => props.autoWidth && `width: calc((100% - 20% - 230px)/2);`}
  font-weight: 600;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-bottom: 10px;
`;

const AssessmentRow = styled.div`
  width: 100%;
`;

const Input = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width};
  ${props => props.autoWidth && `width: calc((100% - 20% - 230px)/2);`}
  padding-right: 15px;
  white-space: nowrap;
`;

const BlockRow = styled.div`
  padding-left: 20px;
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
  align-items: center;
`;

const Pass = styled(Chip)`
  && {
    background: ${props => props.theme.colors.status.success};
    color: ${props => props.theme.colors.white};
    text-transform: capitalize;
  }
`;

const NotAvailable = styled(Chip)`
  && {
    background: ${props => props.theme.colors.status.warning};
    color: ${props => props.theme.colors.white};
    text-transform: capitalize;
  }
`;

const CriticalFail = styled(Chip)`
  && {
    background: ${props => props.theme.colors.status.error};
    color: ${props => props.theme.colors.white};
    text-transform: capitalize;
  }
`;

const ContainerLabel = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  padding-left: 20px;
`;

const Completed = ({ assessments, completionPercentage }) => {
  return (
    <>
      <ContainerLabel>
        <Typography variant="body">Complete</Typography>
        <Chip
          label={`${completionPercentage}%`}
          variant="default"
          color="primary"
        />
        <hr style={{ width: '100%' }} color={colors.grey[300]} />
      </ContainerLabel>
      <BlockTitles>
        <BlockTitle autoWidth>Assessment</BlockTitle>
        <BlockTitle width="50px">Block</BlockTitle>
        <BlockTitle autoWidth>Block Title</BlockTitle>
        <BlockTitle width="10%">Status</BlockTitle>
        <BlockTitle width="80px">Date</BlockTitle>
        <BlockTitle width="80px">Attempt</BlockTitle>
        <BlockTitle width="80px">Score</BlockTitle>
        <BlockTitle width="80px">Grade</BlockTitle>
      </BlockTitles>
      {assessments.map(assessment => (
        <AssessmentRow key={assessment?.uuid}>
          {assessment.assessment_blocks.map(assessmentBlock => (
            <BlockRow key={assessmentBlock?.uuid}>
              <Input autoWidth>
                <Tooltip arrow title={`${assessment?.name}`} placement="top">
                  <span>{assessment?.name}</span>
                </Tooltip>
              </Input>
              <Input width="50px">{assessmentBlock?.blockNumber}</Input>
              <Input autoWidth>
                <Tooltip arrow title={assessmentBlock.name} placement="top">
                  <span>{assessmentBlock.name}</span>
                </Tooltip>
              </Input>
              <Input width="10%">
                {assessmentBlock?.status === 'pass' && (
                  <Pass size="small" label={assessmentBlock?.status} />
                )}
                {assessmentBlock?.status === 'critical' && (
                  <CriticalFail size="small" label="Critical Failure" />
                )}

                {assessmentBlock?.status === 'N/A' && (
                  <NotAvailable size="small" label={assessmentBlock?.status} />
                )}
              </Input>
              <Input width="80px">{assessmentBlock?.passDate}</Input>
              <Input width="80px">{assessmentBlock?.blockAttempt}</Input>
              <Input width="80px">{assessmentBlock?.blockScore}</Input>
              <Input width="80px">{assessmentBlock?.grade}</Input>
            </BlockRow>
          ))}
        </AssessmentRow>
      ))}
    </>
  );
};

Completed.propTypes = {
  assessments: PropTypes.array,
  completionPercentage: PropTypes.number
};

Completed.defaultProps = {
  assessments: [],
  completionPercentage: 0
};
export default Completed;
