import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { doGetPartGradeSettings } from '../../../../redux/actions/partGradeSettingsActions';
import { doGetLetterGradesBySchool } from '../../../../redux/actions/letterGradeActions';
import { doGetCurrentResultCards } from '../../../../redux/actions/resultCardActions';
import { partGradeSettingsSelector } from '../../../../redux/selectors/partGradeSettingsSelectors';
import { letterGradesBySchoolSelector } from '../../../../redux/selectors/letterGradeSelectors';
import { partGradeSortedResultsSelector } from '../../../../redux/selectors/gradeResultSelectors';
import {
  createStudentMap,
  mapPartGradeResultsByStudentSectionStatus
} from '../../../../helpers/sections';
import PartAssessmentCollectionReport from './PartAssessmentCollectionReport';
import DelayedStudentsCollectionReportTable from './DelayedStudentsCollectionReportTable';

const PartAssessmentCollectionReportContainer = ({ partUuid, students }) => {
  const dispatch = useDispatch();
  const [haveFetchedLetterGrades, setHaveFetchedLetterGrades] = useState(false);
  const schoolUuid = useSelector(state => state.userState.selectedSchoolUuid);
  const letterGrades = useSelector(letterGradesBySchoolSelector);
  const partGradeSettings = useSelector(state =>
    partGradeSettingsSelector(state, partUuid)
  );
  const partGradeResults = useSelector(state =>
    partGradeSortedResultsSelector(state, partUuid)
  );
  const gradeCollections = partGradeSettings?.grade_collections || [];

  useEffect(() => {
    if (partUuid) {
      dispatch(doGetPartGradeSettings(partUuid));
      dispatch(doGetCurrentResultCards(partUuid));
    }
  }, [dispatch, partUuid]);

  useEffect(() => {
    if (schoolUuid && !haveFetchedLetterGrades) {
      dispatch(doGetLetterGradesBySchool(schoolUuid));
      setHaveFetchedLetterGrades(true);
    }
  }, [dispatch, haveFetchedLetterGrades, schoolUuid]);

  const numCollectionAssessments = gradeCollections.reduce((sum, current) => {
    const currentValue = current.collection_assessments?.length || 0;
    return sum + currentValue;
  }, 0);

  const numPointsOnlyCollections = _.reduce(
    gradeCollections,
    (total, gradeCollection) => {
      if (gradeCollection.scoreType !== 'SCORE') return total + 1;
      return total;
    },
    0
  );

  const failingGrade = letterGrades.find(
    letterGrade => letterGrade.grade === 'F'
  );

  const studentsMap = students?.length > 0 ? createStudentMap(students) : {};

  const { delayed, active, delayedStudentsMap, activeStudentsMap } =
    mapPartGradeResultsByStudentSectionStatus(partGradeResults, studentsMap);

  return (
    <>
      <PartAssessmentCollectionReport
        assessmentCollections={gradeCollections}
        failThreshold={failingGrade?.rangeHigh}
        numCollectionAssessments={numCollectionAssessments}
        numPointsOnlyCollections={numPointsOnlyCollections}
        students={activeStudentsMap}
        partGradeResults={active}
        partUuid={partUuid}
      />
      <DelayedStudentsCollectionReportTable
        assessmentCollections={gradeCollections}
        failThreshold={failingGrade?.rangeHigh}
        numCollectionAssessments={numCollectionAssessments}
        numPointsOnlyCollections={numPointsOnlyCollections}
        students={delayedStudentsMap}
        partGradeResults={delayed}
        partUuid={partUuid}
      />
    </>
  );
};

PartAssessmentCollectionReportContainer.propTypes = {
  partUuid: PropTypes.string,
  students: PropTypes.array
};

PartAssessmentCollectionReportContainer.defaultProps = {
  partUuid: '',
  students: []
};

export default PartAssessmentCollectionReportContainer;
