import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectOverallCognitiveAvgScore } from '../../../../redux/selectors/transcriptCognitiveSelectors';

export default function CognitiveAverageScore({
  programUuid,
  userUuid,
  cohortUuid,
  category
}) {
  const overallCognitiveScoreAverage = useSelector(state =>
    selectOverallCognitiveAvgScore(
      state,
      programUuid,
      userUuid,
      cohortUuid,
      category
    )
  );

  const score = overallCognitiveScoreAverage || 'N/A';
  return <>{score}</>;
}

CognitiveAverageScore.propTypes = {
  category: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string
};

CognitiveAverageScore.defaultProps = {
  category: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined
};
