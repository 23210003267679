import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Dialog, DialogTitle } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { debounce } from 'lodash';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useSelector } from 'react-redux';

import {
  CancelButton,
  SubmitButton
} from '../../../../../Library/FormComponents';
import AutocompleteInput from './AutocompleteInput';
import {
  createProcedureCodeAtom,
  creatingProcedureCodeAtom,
  getCollectionDetails,
  newProcedureRuleAtom,
  searchProcedureCode,
  searchProcedureCodeAtom,
  searchProcedureCodeResultsAtom,
  selectedProcedureCodeAtom
} from '../state/editState';
import Rule from './Rule';

const debouncedFetchData = debounce((query, cb) => {
  cb(query);
}, 500);

const NewProcedureCode = ({ open, onClose }) => {
  const { quantitativeSkillUuid } = useParams();
  const programUuid = useSelector(state => state.userState.selectedProgramUuid);

  const [rules, setRule] = useAtom(newProcedureRuleAtom);

  const searchTerm = useAtomValue(searchProcedureCodeAtom);
  const codes = useAtomValue(searchProcedureCodeResultsAtom);
  const creating = useAtomValue(creatingProcedureCodeAtom);

  const searchCodes = useSetAtom(searchProcedureCode);
  const setSelectedCode = useSetAtom(selectedProcedureCodeAtom);
  const createProcedureCode = useSetAtom(createProcedureCodeAtom);
  const getCollection = useSetAtom(getCollectionDetails);

  useEffect(() => {
    if (!searchTerm) return;
    debouncedFetchData(searchTerm, async () => {
      await searchCodes();
    });
  }, [searchTerm, searchCodes]);

  const handleSelect = useCallback(
    (_, value) => {
      setSelectedCode(value);
      setRule({
        code: value?.value
      });
    },
    [setSelectedCode, setRule]
  );

  const handleChange = useCallback(
    e => {
      setRule({ [e.target.name]: e.target.value || e.target.checked });
    },
    [setRule]
  );

  const handleSubmit = useCallback(async () => {
    await createProcedureCode();
    await getCollection({ quantitativeSkillUuid, programUuid });
  }, [createProcedureCode, getCollection, quantitativeSkillUuid, programUuid]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Procedure Code</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
          <Autocomplete
            fullWidth
            freeSolo
            disableClearable
            options={codes}
            name="searchTerm"
            getOptionLabel={option =>
              option.label || 'No Procedure Codes Found'
            }
            onChange={handleSelect}
            renderInput={params => <AutocompleteInput params={params} />}
          />
          <Rule
            data={{
              checked: rules.procedureChecked,
              threshold: rules.procedureThreshold,
              grade: rules.procedureGrade,
              rank: rules.procedureRank
            }}
            label="Procedure"
            name="procedure"
            handleChange={handleChange}
          />
          <Rule
            data={{
              checked: rules.rvusChecked,
              threshold: rules.rvusThreshold,
              grade: rules.rvusGrade,
              rank: rules.rvusRank
            }}
            label="RVUs"
            name="rvus"
            handleChange={handleChange}
          />
          <Rule
            data={{
              checked: rules.scoreChecked,
              threshold: rules.scoreThreshold,
              grade: rules.scoreGrade,
              rank: rules.scoreRank
            }}
            label="Score"
            name="score"
            handleChange={handleChange}
          />
          <Rule
            data={{
              checked: rules.unitsChecked,
              threshold: rules.unitsThreshold,
              grade: rules.unitsGrade,
              rank: rules.unitsRank
            }}
            label="Units"
            name="units"
            handleChange={handleChange}
          />
          <Rule
            data={{
              checked: rules.epasChecked,
              threshold: rules.epasThreshold,
              grade: rules.epasGrade,
              rank: rules.epasRank
            }}
            label="EPAs"
            name="epas"
            handleChange={handleChange}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} noHoverBg>
          Cancel
        </CancelButton>
        <SubmitButton onClick={handleSubmit} disabled={creating || !rules.code}>
          {creating ? 'Creating...' : 'Create'}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};

NewProcedureCode.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default NewProcedureCode;
