import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import Modal from '../../Library/Modal/Modal';

const Box = styled.div`
  height: 182px;
  width: 528px;
  border-radius: 4px;
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);}
  padding: 1rem 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
`;

const Question = styled.div`
  height: 27px;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'IBM Plex Sans';
  font-size: 20px;
  letter-spacing: 0.25px;
  line-height: 25px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Warning = styled.div`
  color: ${props => props.theme.colors.grey[600]};
  font-family: 'IBM Plex Sans';
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

const ButtonInlineSC = styled(ButtonInline)`
  && {
    margin-left: 10px;
  }
`;

const CohortDeleteStudentConfirmation = ({
  openModal,
  handleCancelDelete,
  handleDeleteStudents
}) => (
  <div>
    <Modal
      aria-labelledby="student-modal-title"
      aria-describedby="student-modal-description"
      open={openModal}
      onClose={() => handleCancelDelete()}
    >
      <Box>
        <Message>
          <Question>Delete Student?</Question>
          <Warning>
            This will delete these students from this cohort. This cannot be
            reversed.
          </Warning>
        </Message>
        <Buttons>
          <ButtonInlineSC onClick={() => handleCancelDelete()}>
            Cancel
          </ButtonInlineSC>
          <ButtonInlineSC onClick={() => handleDeleteStudents()}>
            Delete
          </ButtonInlineSC>
        </Buttons>
      </Box>
    </Modal>
  </div>
);

CohortDeleteStudentConfirmation.propTypes = {
  openModal: PropTypes.bool,
  handleCancelDelete: PropTypes.func,
  handleDeleteStudents: PropTypes.func
};

CohortDeleteStudentConfirmation.defaultProps = {
  openModal: false,
  handleCancelDelete: undefined,
  handleDeleteStudents: undefined
};

export default CohortDeleteStudentConfirmation;
