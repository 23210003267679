import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getSection,
  getSectionsNestedByCourse,
  putSectionDataSource,
  putSectionPartWeigths,
  patchSectionCalculateGrade,
  patchSectionUndoGradeCalculation,
  putSectionStatusCommit,
  getSectionCommitmentLog,
  patchDelayedStudentSectionGrade,
  putDelayedStudentSectionCommitStatus,
  deleteDelayedStudentSectionGrade
} from '../../api/sectionApi';
import {
  doGetSectionNestedByCourseSuccess,
  doGetSectionNestedByCourseError,
  doGetSectionSuccess,
  doGetSectionError,
  doPutSectionGradeDataSourceSuccess,
  doPutSectionGradeDataSourceError,
  doPutSectionPartWeightsSuccess,
  doPutSectionPartWeightsError,
  doPatchSectionCalculateGradeSuccess,
  doPatchSectionCalculateGradeError,
  doPatchSectionUndoCalculatedGradeSuccess,
  doPatchSectionUndoCalculatedGradeError,
  doPutSectionStatusCommitSuccess,
  doPutSectionStatusCommitError,
  doGetSectionCommitmentLogSuccess,
  doGetSectionCommitmentLogError,
  doPatchSectionDelayedStudentGradeSuccess,
  doPatchSectionDelayedStudentGradeError,
  doPutSectionDelayedStudentStatusCommitSuccess,
  doPutSectionDelayedStudentStatusCommitError,
  doDeleteSectionDelayedStudentGradeSuccess,
  doDeleteSectionDelayedStudentGradeError
} from '../actions/sectionActions';
import {
  GET_SECTION_NESTED_BY_COURSE,
  GET_SECTION,
  PUT_SECTION_DATASOURCE,
  PUT_SECTION_PART_WEIGHTS,
  PATCH_SECTION_CALCULATE_GRADE,
  DELETE_SECTION_UNDO_CALCULATED_GRADE,
  PUT_SECTION_STATUS_COMMIT,
  GET_SECTION_COMMITMENT_LOG,
  PATCH_SECTION_GRADE_DELAYED_STUDENT,
  DELETE_SECTION_GRADE_DELAYED_STUDENT,
  PUT_SECTION_DELAYED_STATUS_COMMIT
} from '../actions-type';

function* handleGetSectionNestedByCourse(action) {
  try {
    const result = yield call(getSectionsNestedByCourse, action.payload);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetSectionNestedByCourseSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSectionNestedByCourseError(error));
  }
}

function* handleGetSection(action) {
  try {
    const result = yield call(getSection, action.payload.sectionUuid);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetSectionSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSectionError(error));
  }
}

function* handleGetSectionCommitmentLog(action) {
  try {
    const result = yield call(
      getSectionCommitmentLog,
      action.payload.sectionUuid
    );
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetSectionCommitmentLogSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetSectionCommitmentLogError(error));
  }
}

function* handlePutSectionDataSource(action) {
  try {
    const result = yield call(putSectionDataSource, action.payload);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutSectionGradeDataSourceSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPutSectionGradeDataSourceError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPutSectionGradeDataSourceError());
  }
}

function* handlePutSectionPartWeights(action) {
  try {
    const result = yield call(putSectionPartWeigths, action.payload);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutSectionPartWeightsSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPutSectionPartWeightsError({
          error: errorText.error
        })
      );
    }
  } catch (error) {
    yield put(doPutSectionPartWeightsError());
  }
}

function* handlePatchSectionCalculateGrade(action) {
  try {
    const result = yield call(
      patchSectionCalculateGrade,
      action.payload.sectionUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchSectionCalculateGradeSuccess(payload));
    } else {
      const errorText = yield result.json();

      yield put(
        doPatchSectionCalculateGradeError(
          errorText.error,
          action.payload.sectionUuid
        )
      );
    }
  } catch (error) {
    yield put(
      doPatchSectionCalculateGradeError(error, action.payload.sectionUuid)
    );
  }
}

function* handlePatchSectionUndoGradeCalculation(action) {
  try {
    const result = yield call(
      patchSectionUndoGradeCalculation,
      action.payload.sectionUuid
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchSectionUndoCalculatedGradeSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(doPatchSectionUndoCalculatedGradeError(errorText.error));
    }
  } catch (error) {
    yield put(doPatchSectionUndoCalculatedGradeError(error));
  }
}

function* handlePutSectionStatusCommit(action) {
  try {
    const result = yield call(putSectionStatusCommit, action.payload.section);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutSectionStatusCommitSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPutSectionStatusCommitError(
          errorText.error,
          action.payload.section.uuid
        )
      );
    }
  } catch (error) {
    yield put(
      doPutSectionStatusCommitError(error, action.payload.section.uuid)
    );
  }
}

function* handlePutSectionDelayedStudentGrade(action) {
  const { sectionUuid, studentUuid } = action.payload;

  try {
    const result = yield call(patchDelayedStudentSectionGrade, {
      sectionUuid,
      studentUuid
    });
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPatchSectionDelayedStudentGradeSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPatchSectionDelayedStudentGradeError(error));
  }
}

function* handlePutSectionDelayedStudentGradeStatusCommit(action) {
  const {
    sectionUuid,
    studentUuid,
    delayedStatusCommit,
    justificationMessage
  } = action.payload;

  try {
    const result = yield call(putDelayedStudentSectionCommitStatus, {
      sectionUuid,
      studentUuid,
      delayedStatusCommit,
      justificationMessage
    });
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutSectionDelayedStudentStatusCommitSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutSectionDelayedStudentStatusCommitError(error));
  }
}

function* handleDeleteSectionDelayedStudentGrade(action) {
  const { sectionUuid, studentUuid } = action.payload;
  try {
    const result = yield call(deleteDelayedStudentSectionGrade, {
      sectionUuid,
      studentUuid
    });
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteSectionDelayedStudentGradeSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteSectionDelayedStudentGradeError(error));
  }
}

const section = [
  takeEvery(GET_SECTION_NESTED_BY_COURSE, handleGetSectionNestedByCourse),
  takeEvery(GET_SECTION, handleGetSection),
  takeEvery(GET_SECTION_COMMITMENT_LOG, handleGetSectionCommitmentLog),
  takeEvery(PUT_SECTION_DATASOURCE, handlePutSectionDataSource),
  takeEvery(PUT_SECTION_PART_WEIGHTS, handlePutSectionPartWeights),
  takeEvery(PATCH_SECTION_CALCULATE_GRADE, handlePatchSectionCalculateGrade),
  takeEvery(
    PATCH_SECTION_GRADE_DELAYED_STUDENT,
    handlePutSectionDelayedStudentGrade
  ),
  takeEvery(
    DELETE_SECTION_GRADE_DELAYED_STUDENT,
    handleDeleteSectionDelayedStudentGrade
  ),
  takeEvery(
    PUT_SECTION_DELAYED_STATUS_COMMIT,
    handlePutSectionDelayedStudentGradeStatusCommit
  ),
  takeEvery(
    DELETE_SECTION_UNDO_CALCULATED_GRADE,
    handlePatchSectionUndoGradeCalculation
  ),
  takeEvery(PUT_SECTION_STATUS_COMMIT, handlePutSectionStatusCommit)
];

export { section };
