import { Model, attr, fk } from 'redux-orm';
import {
  GET_COHORT_ASSESSMENT_COMMITMENT_LOG_SUCCESS,
  GET_SECTION_COMMITMENT_LOG_SUCCESS
} from '../actions-type';

class CommitmentLog extends Model {
  static reducer(action, CommitmentLog, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_SECTION_COMMITMENT_LOG_SUCCESS: {
        const { commitment_logs } = payload;

        if (commitment_logs?.length > 0) {
          commitment_logs.forEach(log => {
            CommitmentLog.upsert(log);
          });
        }

        break;
      }
      case GET_COHORT_ASSESSMENT_COMMITMENT_LOG_SUCCESS: {
        const { commitment_logs } = payload;

        if (commitment_logs?.length > 0) {
          commitment_logs.forEach(log => {
            CommitmentLog.upsert(log);
          });
        }

        break;
      }
      default:
        return session;
    }
  }
}

CommitmentLog.fields = {
  uuid: attr(),
  statusCommit: attr(),
  justificationMessage: attr(),
  userUuid: fk('User', 'commitmentLogs'),
  sectionUuid: fk('Section', 'commitmentLogs'),
  partUuid: fk('Part', 'commitmentLogs'),
  assessmentUuid: fk('Assessment', 'commitmentLogs')
};

CommitmentLog.modelName = 'CommitmentLog';

CommitmentLog.options = {
  idAttribute: 'uuid'
};

export default CommitmentLog;
