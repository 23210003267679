import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import ButtonPrimary from '@xcomp/xcomp-design-library/dist/components/ButtonPrimary';
import {
  ContainerPage,
  ContainerActions,
  ElevateLow
} from '../../../Library/Layout';
import DegreeLevelIndexAddContainer from './DegreeLevelIndexAdd/DegreeLevelIndexAddContainer';
import DegreeLevelIndexEditContainer from './DegreeLevelIndexEdit/DegreeLevelIndexEditContainer';
import DegreeLevelActions from './DegreeLevelActions';
import DeleteModal from '../../../Library/Modal/DeleteModal/DeleteModal';

const DegreeLevelName = styled.div``;

const Titles = styled.div`
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  padding: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const DegreeLevelRow = styled.div`
  padding: 1rem;
`;

export default function DegreeLevelIndex({
  degreeLevelIndices,
  programUuid,
  openDegree,
  openDegreeEdit,
  openDegreeDelete,
  selectEdit,
  handleDegreeOpen,
  handleDegreeClose,
  handleDegreeEditOpen,
  handleDegreeEditClose,
  handleDegreeDeleteOpen,
  handleDegreeDeleteClose,
  handleDeleteDegreeLevelIndex
}) {
  return (
    <div>
      <ContainerActions paddingTop="20px">
        <ButtonPrimary onClick={handleDegreeOpen}>Add</ButtonPrimary>
      </ContainerActions>
      <DegreeLevelIndexAddContainer
        programUuid={programUuid}
        openDegree={openDegree}
        handleDegreeClose={handleDegreeClose}
      />
      <ContainerPage paddingTop="40px">
        <ElevateLow>
          <Titles>
            <Grid container>
              <Grid item sm={8} xs={12}>
                Degree Level
              </Grid>
              <Grid item sm={4} xs={12}>
                Percentage
              </Grid>
              <Grid item sm={1} xs={12} />
            </Grid>
          </Titles>
          <DegreeLevelRow>
            <Grid container spacing={2}>
              {degreeLevelIndices?.map(dli => (
                <Grid key={dli?.uuid} item sm={12} xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item sm={8} xs={12}>
                      <DegreeLevelName>
                        {dli?.degreeLevel?.degreeLevel}
                      </DegreeLevelName>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <DegreeLevelName>{dli?.percentage}</DegreeLevelName>
                    </Grid>
                    <Grid
                      container
                      item
                      sm={1}
                      xs={12}
                      justifyContent="flex-end"
                    >
                      <DegreeLevelActions
                        degreeLevelIndexUuid={dli?.uuid}
                        handleDegreeEditOpen={handleDegreeEditOpen}
                        handleDegreeDeleteOpen={handleDegreeDeleteOpen}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </DegreeLevelRow>
        </ElevateLow>
      </ContainerPage>
      <DegreeLevelIndexEditContainer
        selectEdit={selectEdit}
        openDegreeEdit={openDegreeEdit}
        handleDegreeEditClose={handleDegreeEditClose}
      />
      <DeleteModal
        modalOpen={openDegreeDelete}
        handleModalClose={handleDegreeDeleteClose}
        handleDelete={handleDeleteDegreeLevelIndex}
        type="Degree Level Index"
      />
    </div>
  );
}

DegreeLevelIndex.propTypes = {
  selectEdit: PropTypes.string,
  openDegreeDelete: PropTypes.bool,
  openDegreeEdit: PropTypes.bool,
  degreeLevelIndices: PropTypes.array,
  programUuid: PropTypes.string,
  openDegree: PropTypes.bool,
  handleDegreeOpen: PropTypes.func,
  handleDegreeClose: PropTypes.func,
  handleDegreeEditOpen: PropTypes.func,
  handleDegreeEditClose: PropTypes.func,
  handleDegreeDeleteOpen: PropTypes.func,
  handleDegreeDeleteClose: PropTypes.func,
  handleDeleteDegreeLevelIndex: PropTypes.func
};

DegreeLevelIndex.defaultProps = {
  selectEdit: undefined,
  openDegreeEdit: false,
  openDegreeDelete: false,
  degreeLevelIndices: [],
  programUuid: undefined,
  openDegree: false,
  handleDegreeOpen: undefined,
  handleDegreeClose: undefined,
  handleDegreeEditOpen: undefined,
  handleDegreeEditClose: undefined,
  handleDegreeDeleteOpen: undefined,
  handleDegreeDeleteClose: undefined,
  handleDeleteDegreeLevelIndex: undefined
};
