import { Model, attr } from 'redux-orm';
import {
  GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES_SUCCESS,
  GET_TRANSCRIPT_SUCCESS
} from '../actions-type';

class ApplicantPhysicalSkillsReportType extends Model {
  static reducer(action, ApplicantPhysicalSkillsReportType, session) {
    const { type, payload } = action;
    switch (type) {
      case GET_APPLICANT_PHYSICAL_SKILLS_REPORT_TYPES_SUCCESS: {
        const applicantPhysicalSkillsReportTypes = [
          ...payload.applicantPhysicalSkillsReportTypes
        ];
        applicantPhysicalSkillsReportTypes.forEach(type =>
          ApplicantPhysicalSkillsReportType.upsert(type)
        );
        break;
      }

      case GET_TRANSCRIPT_SUCCESS: {
        const applicantPhysicalSkillsReportType = [
          ...payload.applicantPhysicalSkillsReportType
        ];
        applicantPhysicalSkillsReportType.forEach(type =>
          ApplicantPhysicalSkillsReportType.upsert(type)
        );
        break;
      }
      default:
        return session;
    }
  }
}

ApplicantPhysicalSkillsReportType.fields = {
  uuid: attr(),
  name: attr(),
  subType: attr(),
  order: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

ApplicantPhysicalSkillsReportType.modelName =
  'ApplicantPhysicalSkillsReportType';

ApplicantPhysicalSkillsReportType.options = {
  idAttribute: 'uuid'
};

export default ApplicantPhysicalSkillsReportType;
