import { call, put, takeEvery } from 'redux-saga/effects';

import {
  doGetCohortOpportunityReportError,
  doGetCohortOpportunityReportSuccess
} from '../actions/cohortOpportunitiesReportActions';
import { GET_COHORT_OPPORTUNITY_REPORT } from '../actions-type';
import { getCohortOpportunityReport } from '../../api/cohortOpportunityReportApi';

function* handleGetCohorOpportunityReport(action) {
  try {
    const result = yield call(getCohortOpportunityReport, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetCohortOpportunityReportSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetCohortOpportunityReportError(error));
  }
}

const cohortOpportunityReport = [
  takeEvery(GET_COHORT_OPPORTUNITY_REPORT, handleGetCohorOpportunityReport)
];

export { cohortOpportunityReport };
