import { Model, attr, fk } from 'redux-orm';
import { GET_COURSE_COLLECTIONS_SUCCESS } from '../actions-type';

class CourseCollectionCourse extends Model {
  static reducer(action, CourseCollectionCourse, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_COURSE_COLLECTIONS_SUCCESS: {
        const courseCollections = [...payload.courseCollections];

        courseCollections.forEach(courseCollection => {
          const { courses } = courseCollection;

          if (courses?.length > 0) {
            courses.forEach(cm => {
              const { course_collection_course } = cm;
              CourseCollectionCourse.upsert(course_collection_course);
            });
          }
        });

        break;
      }

      default:
        return session;
    }
  }
}

CourseCollectionCourse.fields = {
  uuid: attr(),
  courseMasterUuid: fk('CourseMaster', 'courses'),
  courseCollectionUuid: fk('CourseCollection', 'course_collection'),
  createdAt: attr(),
  updatedAt: attr()
};

CourseCollectionCourse.modelName = 'CourseCollectionCourse';
CourseCollectionCourse.options = {
  idAttribute: 'uuid'
};

export default CourseCollectionCourse;
