import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { programDescriptionSelector } from '../../../redux/selectors/degreeCodeSelector';

const TextFieldSC = styled(TextField)`
  && {
    width: 100%;
  }
`;

export default function ProgramDescription({ programCode }) {
  const programDescription = useSelector(state =>
    programDescriptionSelector(state, programCode)
  );

  return (
    <TextFieldSC
      id="degreeDescription"
      name="degreeDescription"
      label="Degree Description"
      variant="outlined"
      multiline
      minRows="6"
      maxRows="10"
      value={programDescription}
      margin="normal"
      InputLabelProps={{ shrink: true }}
      readOnly={true}
    />
  );
}

ProgramDescription.propTypes = {
  programCode: PropTypes.string
};
ProgramDescription.defaultProps = {
  programCode: ''
};
