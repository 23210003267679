export const POST_ENCOUNTER = 'POST_ENCOUNTER';
export const POST_ENCOUNTER_SUCCESS = 'POST_ENCOUNTER_SUCCESS';
export const POST_ENCOUNTER_ERROR = 'POST_ENCOUNTER_ERROR';

export const CLEAR_CREATED_ENCOUNTER = 'CLEAR_CREATED_ENCOUNTER';

export const POST_CALCULATE_ENCOUNTER_OBJECTIVES =
  'POST_CALCULATE_ENCOUNTER_OBJECTIVES';
export const POST_CALCULATE_ENCOUNTER_OBJECTIVES_SUCCESS =
  'POST_CALCULATE_ENCOUNTER_OBJECTIVES_SUCCESS';
export const POST_CALCULATE_ENCOUNTER_OBJECTIVES_ERROR =
  'POST_CALCULATE_ENCOUNTER_OBJECTIVES_ERROR';

export const POST_CALCULATE_ENCOUNTER_OBJECTIVES_INVALID_SCORES =
  'POST_CALCULATE_ENCOUNTER_OBJECTIVES_INVALID_SCORES';

export const PUT_ENCOUNTER_DRAFT = 'PUT_ENCOUNTER_DRAFT';
export const PUT_ENCOUNTER_DRAFT_SUCCESS = 'PUT_ENCOUNTER_DRAFT_SUCCESS';
export const PUT_ENCOUNTER_DRAFT_ERROR = 'PUT_ENCOUNTER_DRAFT_ERROR';

export const CLEAR_UPDATED_ENCOUNTER = 'CLEAR_UPDATED_ENCOUNTER';

export const PUT_ENCOUNTER_FINAL = 'PUT_ENCOUNTER_FINAL';
export const PUT_ENCOUNTER_FINAL_SUCCESS = 'PUT_ENCOUNTER_FINAL_SUCCESS';
export const PUT_ENCOUNTER_FINAL_ERROR = 'PUT_ENCOUNTER_FINAL_ERROR';

export const PATCH_ENCOUNTER_APPROVE = 'PATCH_ENCOUNTER_APPROVE';
export const PATCH_ENCOUNTER_APPROVE_SUCCESS =
  'PATCH_ENCOUNTER_APPROVE_SUCCESS';
export const PATCH_ENCOUNTER_APPROVE_ERROR = 'PATCH_ENCOUNTER_APPROVE_ERROR';

export const DELETE_ENCOUNTER = 'DELETE_ENCOUNTER';
export const DELETE_ENCOUNTER_SUCCESS = 'DELETE_ENCOUNTER_SUCCESS';
export const DELETE_ENCOUNTER_ERROR = 'DELETE_ENCOUNTER_ERROR';
