import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

const TextFieldSC = styled(TextField)`
  && {
    width: 100%;
  }
`;

class DebouncedTextField extends Component {
  debouncedHandleChange = _.debounce(
    event => this.props.onChange(event),
    1000,
    {
      maxWait: 5000
    }
  );

  constructor(props) {
    super(props);
    this.state = {
      internalValue: ''
    };

    this.setInitialState = this.setInitialState.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { value } = this.props;
    this.setInitialState(value);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setInitialState(value);
    }
  }

  setInitialState = value => {
    this.setState({
      internalValue: value
    });
  };

  handleChange = (value, name) => {
    const event = {
      target: {
        value,
        name
      }
    };

    this.setState({
      internalValue: value
    });

    this.debouncedHandleChange(event);
  };

  render() {
    const { internalValue } = this.state;
    const { value, onChange, helperText, ...props } = this.props;

    return (
      <TextFieldSC
        value={internalValue}
        onChange={event =>
          this.handleChange(event.target.value, event.target.name)}
        helperText={helperText}
        {...props}
      />
    );
  }
}

DebouncedTextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  helperText: PropTypes.string
};

DebouncedTextField.defaultProps = {
  value: '',
  onChange: undefined,
  helperText: ''
};

export default DebouncedTextField;
