import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const commitmentLogsSelectorBySectionUuid = createSelector(
  state => state,
  (_, sectionUuid) => sectionUuid,
  (redux, sectionUuid) => {
    const sectionSelectorORM = ormCreateSelector(orm.CommitmentLog);
    const commitmentLogs = sectionSelectorORM(redux).filter(
      log => log.sectionUuid === sectionUuid
    );

    return commitmentLogs;
  }
);

export const commitmentLogsSelectorByCohortAssessmentUuid = createSelector(
  state => state,
  (_, assessmentUuid) => assessmentUuid,
  (redux, assessmentUuid) => {
    const cohrotAssessmentLogsSelectorORM = ormCreateSelector(
      orm.CommitmentLog
    );
    const commitmentLogs = cohrotAssessmentLogsSelectorORM(redux).filter(
      log => log.assessmentUuid === assessmentUuid
    );

    return commitmentLogs;
  }
);

export const commitmentLogSelector = createSelector(
  state => state,
  (_, commitmentLogUuid) => commitmentLogUuid,
  (redux, commitmentLogUuid) => {
    const commitmentLogSelectorORM = ormCreateSelector(orm.CommitmentLog);
    const commitmentLog = commitmentLogSelectorORM(redux, commitmentLogUuid);

    return commitmentLog;
  }
);
