const initialCompetencyState = {
  uuid: '',
  name: '',
  description: '',
  microcompetencies: {},
  isMicroSearchOpen: false,
  showValidationErrors: false
};

const competencyReducer = (state, action) => {
  switch (action.type) {
    case 'setExistingCompetency': {
      const { uuid, name, description } = action.payload;

      return {
        ...state,
        uuid,
        name,
        description
      };
    }
    case 'setName':
      return { ...state, name: action.payload.name };
    case 'setDescription':
      return { ...state, description: action.payload.description };
    case 'openMicroSearch':
      return {
        ...state,
        isMicroSearchOpen: true,
        microSearchForCompetencyUuid: action.payload.competencyUuid
      };
    case 'closeMicroSearch':
      return {
        ...state,
        isMicroSearchOpen: false,
        microSearchForCompetencyUuid: ''
      };
    case 'setShowValidationErrors':
      return {
        ...state,
        showValidationErrors: action.payload.showValidationErrors
      };
    case 'clearForm':
      return initialCompetencyState;
    default: {
      throw new Error();
    }
  }
};

const setShowValidationErrors = showValidationErrors => ({
  type: 'setShowValidationErrors',
  payload: { showValidationErrors }
});

export { initialCompetencyState, competencyReducer, setShowValidationErrors };
