import { call, put, takeEvery } from 'redux-saga/effects';

import {
  getUserProgramsByProgram,
  getUserProgramsByPrograms
} from '../../api/userProgramApi';

import {
  doGetUserProgramsByProgramSuccess,
  doGetUserProgramsByProgramError,
  doGetUserProgramsByProgramsByProgramsSuccess,
  doGetUserProgramsByProgramsByProgramsError
} from '../actions/userProgramActions';

import {
  GET_USER_PROGRAMS_BY_PROGRAM,
  GET_USER_PROGRAMS_BY_PROGRAMS
} from '../actions-type';

function* handleGetUsersProgram(action) {
  try {
    const result = yield call(
      getUserProgramsByProgram,
      action.payload.programUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetUserProgramsByProgramSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUserProgramsByProgramError(error));
  }
}

function* handleGetUsersPrograms(action) {
  try {
    const result = yield call(getUserProgramsByPrograms, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetUserProgramsByProgramsByProgramsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUserProgramsByProgramsByProgramsError(error));
  }
}

const userProgram = [
  takeEvery(GET_USER_PROGRAMS_BY_PROGRAM, handleGetUsersProgram),
  takeEvery(GET_USER_PROGRAMS_BY_PROGRAMS, handleGetUsersPrograms)
];

export { userProgram };
