import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { optionUserStatus, optionsRolesAll } from '../../../../helpers/options';
import FormField from '../../../Library/FormField';
import FormSelectError from '../../../Library/FormSelectError';
import Date from '../../../Library/Date';

import {
  validateInputString,
  validateSelectDate,
  validateSelectField
} from '../../../../helpers/validation/validateGeneric';

const UserInstitutionAdmin = ({
  uuid,
  status,
  startDate,
  endDate,
  role,
  email,
  phone,
  hasErrors,
  handleRole,
  handleRoleDate,
  handleRoleRemove
}) => (
  <>
    <Grid item sm={12} xs={12}>
      <Grid container direction="row" justifyContent="flex-end">
        <IconButton onClick={() => handleRoleRemove(uuid, 'institution_admin')}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
    <Grid item sm={3} xs={12}>
      <FormSelectError
        name="role"
        label="Role"
        value={role}
        options={optionsRolesAll}
        hasErrors={hasErrors}
        disabled={true}
      />
    </Grid>
    <Grid item sm={3} xs={12}>
      <FormSelectError
        name="status"
        label="Status*"
        value={status}
        handleChange={event => handleRole(event, uuid, 'institution_admin')}
        handleValidation={validateSelectField}
        options={optionUserStatus}
        hasErrors={hasErrors}
      />
    </Grid>

    <Grid item sm={3} xs={12}>
      <Date
        name="startDate"
        label="Start Date*"
        value={startDate}
        indentifier={uuid}
        handleChange={(indentifier, field, date) =>
          handleRoleDate(indentifier, field, date, 'institution_admin')
        }
        hasErrors={hasErrors}
        handleValidate={validateSelectDate}
      />
    </Grid>
    <Grid item sm={3} xs={12}>
      <Date
        name="endDate"
        label="End Date"
        value={endDate}
        indentifier={uuid}
        handleChange={(indentifier, field, date) =>
          handleRoleDate(indentifier, field, date, 'institution_admin')
        }
        placeholderText={status === 'active' ? 'Present' : null}
      />
    </Grid>
    <Grid item sm={6} xs={12}>
      <FormField
        name="email"
        label="Email*"
        value={email}
        onBlur={event => handleRole(event, uuid, 'institution_admin')}
        handleValidate={validateInputString}
        hasErrors={hasErrors}
      />
    </Grid>
    <Grid item sm={6} xs={12}>
      <FormField
        name="phone"
        label="Phone*"
        value={phone}
        onBlur={event => handleRole(event, uuid, 'institution_admin')}
        margin="normal"
        handleValidate={validateInputString}
        hasErrors={hasErrors}
      />
    </Grid>
  </>
);

UserInstitutionAdmin.propTypes = {
  uuid: PropTypes.string,
  status: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.object,
    PropTypes.string
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.object,
    PropTypes.string
  ]),
  role: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  hasErrors: PropTypes.bool,
  handleRole: PropTypes.func,
  handleRoleDate: PropTypes.func,
  handleRoleRemove: PropTypes.func
};

UserInstitutionAdmin.defaultProps = {
  uuid: undefined,
  status: undefined,
  startDate: null,
  endDate: null,
  role: undefined,
  email: undefined,
  phone: undefined,
  hasErrors: undefined,
  handleRole: undefined,
  handleRoleDate: undefined,
  handleRoleRemove: undefined
};

export default UserInstitutionAdmin;
