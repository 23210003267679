import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';
import { doGetProgramCohorts } from '../../redux/actions/cohortActions';
import { selectedProgramSelector } from '../../redux/selectors/programSelectors';
import { cohortUuidsByProgramSelector } from '../../redux/selectors/cohortsSelectors';
import Reports from './Reports';
import { sortByDateProperty } from '../../helpers/utilities';

const ReportsContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const program = useSelector(selectedProgramSelector, isEqual);
  const programCohorts = useSelector(
    state => cohortUuidsByProgramSelector(state, program?.uuid),
    isEqual
  );

  const handleFetchProgramCohorts = () => {
    dispatch(doGetProgramCohorts(program.uuid));
  };

  const handleNavigateCompetencyGrid = useCallback(
    cohortUuid => {
      history.push(`/reports/cohort/${cohortUuid}/competency-grid`);
    },
    [history]
  );

  const handleNavigateAssessmentGrid = useCallback(
    cohortUuid => {
      history.push(`/reports/cohort/${cohortUuid}/assessment-grid`);
    },
    [history]
  );

  const handleNavigateApplicantReports = useCallback(
    cohortUuid => {
      history.push(
        `/cohort/${cohortUuid}/reports/applicant-reports/demographics`
      );
    },
    [history]
  );

  const handleNavigateOpportunityReport = useCallback(
    cohortUuid => {
      history.push(`/cohort/${cohortUuid}/reports/cohort-reports`);
    },
    [history]
  );

  const sortedCohorts = programCohorts.sort((a, b) =>
    sortByDateProperty(a, b, 'startDate')
  );

  const handleNavigateSelfAssessmentReport = useCallback(
    cohortUuid => {
      history.push(`/cohort/${cohortUuid}/self-assessment`);
    },
    [history]
  );
  const handleNavigateQualitativeAndDocumentsReport = useCallback(
    cohortUuid => {
      history.push(`/cohort/${cohortUuid}/qualitative-skills-and-documents`);
    },
    [history]
  );

  const handleNavigateDidacticReport = useCallback(
    cohortUuid => {
      history.push(`/cohort/${cohortUuid}/basic-and-aplied-didactic`);
    },
    [history]
  );

  return (
    <div>
      <Reports
        cohorts={sortedCohorts}
        onFetchProgramCohorts={handleFetchProgramCohorts}
        onNavigateApplicantReports={handleNavigateApplicantReports}
        onNavigateCompetencyGrid={handleNavigateCompetencyGrid}
        onNavigateAssessmentGrid={handleNavigateAssessmentGrid}
        onNavigateOpportunityReport={handleNavigateOpportunityReport}
        onNavigateSelfAssessmentReport={handleNavigateSelfAssessmentReport}
        onNavigateQualitativeAndDocumentsReport={
          handleNavigateQualitativeAndDocumentsReport
        }
        onNavigateDidacticReport={handleNavigateDidacticReport}
        program={program}
      />
    </div>
  );
};

export default ReportsContainer;
