import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { selectSectionAndParts } from '../../../redux/selectors/sectionsSelectors';

import Section from './Section';

export default function SectionContainer({ sectionUuid }) {
  const section = useSelector(
    state => selectSectionAndParts(state, sectionUuid),
    _.isEqual
  );

  return (
    <Section
      sectionUuid={section.uuid}
      courseUuid={section.courseUuid}
      parts={section.parts}
      section={section}
      directors={section.section_directors_assigned}
      sectionNumber={section.sectionNumber}
    />
  );
}

SectionContainer.propTypes = {
  sectionUuid: PropTypes.string
};

SectionContainer.defaultProps = {
  sectionUuid: undefined
};
