import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import LetterGradeEdit from './LetterGradeEdit';

import { doPutLetterGrade } from '../../../../../redux/actions/letterGradeActions';

export default function LetterGradeEditContainer({
  letterGrade,
  handleShowMode
}) {
  const dispatch = useDispatch();
  const [grade, setGrade] = useState('');
  const [rangeLow, setRangeLow] = useState('0.0');
  const [rangeHigh, setRangeHigh] = useState('0.0');
  const [conversion, setConversion] = useState('0.0');
  const [creditMultiplier, setCreditMultiplier] = useState('0.0');

  useEffect(() => {
    if (letterGrade) {
      setGrade(letterGrade.grade);
      setRangeLow(letterGrade.rangeLow);
      setRangeHigh(letterGrade.rangeHigh);
      setConversion(letterGrade.conversion);
      setCreditMultiplier(letterGrade.creditMultiplier);
    }
  }, [letterGrade]);

  const handleSave = () => {
    dispatch(
      doPutLetterGrade({
        uuid: letterGrade.uuid,
        grade,
        rangeLow,
        rangeHigh,
        conversion,
        creditMultiplier
      })
    );
  };

  const handleChange = event => {
    const { name, value } = event.target;

    switch (name) {
      case 'grade': {
        setGrade(value);
        break;
      }
      case 'rangeLow': {
        setRangeLow(value);
        break;
      }
      case 'rangeHigh': {
        setRangeHigh(value);
        break;
      }
      case 'conversion': {
        setConversion(value);
        break;
      }
      case 'creditMultiplier': {
        setCreditMultiplier(value);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <LetterGradeEdit
      uuid={letterGrade.uuid}
      grade={grade}
      rangeLow={rangeLow}
      rangeHigh={rangeHigh}
      conversion={conversion}
      creditMultiplier={creditMultiplier}
      handleShowMode={handleShowMode}
      handleChange={handleChange}
      handleSave={handleSave}
    />
  );
}

LetterGradeEditContainer.propTypes = {
  letterGrade: PropTypes.object,
  handleShowMode: PropTypes.func
};
LetterGradeEditContainer.defaultProps = {
  letterGrade: {},
  handleShowMode: undefined
};
