const validateDepartmentCode = textField => {
  if (!textField || textField === '' || textField === 0) {
    return {
      invalid: true,
      message: 'Field required'
    };
  } else if (textField !== textField.toUpperCase()) {
    return {
      invalid: true,
      message: 'Code must be uppercase'
    };
  } else {
    return {
      invalid: false
    };
  }
};

export { validateDepartmentCode };
