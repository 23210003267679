import { roles } from '../constants';

const validateUserRoles = selections => {
  const noBlankFields = selections.filter(
    selection =>
      selection.role === '' ||
      selection.status === '' ||
      selection.startDate === null ||
      (selection.role === roles.schoolAdmin && selection.schoolUuid === '') ||
      (selection.role === roles.departmentFaculty &&
        selection.departmentUuid === '') ||
      (selection.role === roles.departmentStaff &&
        selection.departmentUuid === '') ||
      (selection.role === roles.programAdmin && selection.programUuid === '') ||
      (selection.role === roles.programFaculty &&
        selection.programUuid === '') ||
      (selection.role === roles.programStaff && selection.programUuid === '')
  );

  if (noBlankFields.length > 0) {
    return {
      invalid: true
    };
  }

  return {
    invalid: false
  };
};

const validateUserRolesEmailPhone = textFields => {
  const noBlankFields = textFields.filter(
    textField =>
      textField.email === '' ||
      textField.phone === '' ||
      textField.email === null ||
      textField.phone === null
  );

  if (noBlankFields.length > 0) {
    return {
      invalid: true
    };
  }

  return {
    invalid: false
  };
};

const validateUserRolesCohort = selections => {
  const noBlankFields = selections.filter(
    selection =>
      selection.role === '' ||
      selection.status === '' ||
      selection.startDate === null
  );

  if (noBlankFields.length > 0) {
    return {
      invalid: true
    };
  }

  return {
    invalid: false
  };
};

const emailValidation = email => {
  const regexEmail = /\S+@\S+\.\S+/;
  const result = regexEmail.test(String(email).toLowerCase());
  return result;
};

const validateUserEmail = emailField => {
  if (!emailField || emailField.trim() === '' || emailField === 0) {
    return {
      invalid: true,
      message: 'Field is required'
    };
  }

  if (!emailValidation(emailField)) {
    return {
      invalid: true,
      message: 'Incorrect email format'
    };
  }

  return {
    invalid: false
  };
};

export {
  validateUserEmail,
  validateUserRoles,
  validateUserRolesEmailPhone,
  validateUserRolesCohort
};
