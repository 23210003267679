import moment from 'moment';
import _ from 'lodash';

const validateEncounterStartDateField = (
  startDate,
  endDate,
  dayType,
  termRange
) => {
  if (!startDate) {
    return {
      invalid: true,
      message: 'Please select a valid start date'
    };
  }

  if (dayType === 'Multi') {
    const startDateNoTime = moment(startDate, 'YYYY-MM-DD');
    const endDateNoTime = moment(endDate, 'YYYY-MM-DD');
    const checkRange = startDateNoTime.isSame(endDateNoTime, 'day');
    if (checkRange) {
      return {
        invalid: true,
        message: 'Muti-day cannot have the same start date and end date'
      };
    }
  }

  if (dayType === 'Single') {
    const startDateNoTime = moment(startDate, 'YYYY-MM-DD');
    const endDateNoTime = moment(endDate, 'YYYY-MM-DD');
    const isSame = startDateNoTime.isSame(endDateNoTime);

    if (!isSame) {
      return {
        invalid: true,
        message: 'Single-day start date must be the same as end date'
      };
    }
  }

  if (startDate) {
    const startDateNoTime = moment(startDate, 'YYYY-MM-DD');

    const termStartDate = moment(termRange.startDate, 'YYYY-MM-DD');
    const termEndDate = moment(termRange.endDate, 'YYYY-MM-DD');
    const startDateBeforeTerm = startDateNoTime.isBefore(termStartDate, 'day');
    const startAfterBeforeTerm = startDateNoTime.isAfter(termEndDate, 'day');

    if (startDateBeforeTerm || startAfterBeforeTerm) {
      return {
        invalid: true,
        message: `Start date is out of term range  ${termStartDate.format(
          'MM-DD-YYYY'
        )} to ${termEndDate.format('MM-DD-YYYY')}`
      };
    }
  }

  return { invalid: false };
};

const validateEncounterEndDateField = (
  endDate,
  startDate,
  dayType,
  termRange
) => {
  if (!endDate) {
    return {
      invalid: true,
      message: 'Please select a valid end date'
    };
  }

  if (dayType === 'Multi') {
    const startDateNoTime = moment(startDate, 'YYYY-MM-DD');
    const endDateNoTime = moment(endDate, 'YYYY-MM-DD');
    const checkRange = endDateNoTime.isSame(startDateNoTime, 'day');
    if (checkRange) {
      return {
        invalid: true,
        message: 'Muti-day cannot have the same start date and end date'
      };
    }
  }

  if (dayType === 'Single') {
    const startDateNoTime = moment(startDate, 'YYYY-MM-DD');
    const endDateNoTime = moment(endDate, 'YYYY-MM-DD');

    const isSame = endDateNoTime.isSame(startDateNoTime, 'day');

    if (!isSame) {
      return {
        invalid: true,
        message: 'Single-day end date must be the same as start date'
      };
    }
  }

  if (endDate) {
    const endDateNoTime = moment(endDate, 'YYYY-MM-DD');

    const termStartDate = moment(termRange.startDate, 'YYYY-MM-DD');
    const termEndDate = moment(termRange.endDate, 'YYYY-MM-DD');

    const endDateBeforeTerm = endDateNoTime.isBefore(termStartDate, 'day');
    const endAfterBeforeTerm = endDateNoTime.isAfter(termEndDate, 'day');

    if (endDateBeforeTerm || endAfterBeforeTerm) {
      return {
        invalid: true,
        message: `End date is out of term range ${termStartDate.format(
          'MM-DD-YYYY'
        )} to ${termEndDate.format('MM-DD-YYYY')}`
      };
    }
  }

  return { invalid: false };
};

const validateEncounterLeadPercent = inputValue => {
  if (!inputValue || inputValue === '' || Number(inputValue) === 0) {
    return {
      invalid: true,
      message: 'Required'
    };
  }

  const splitArrays = inputValue.toString().split('.');
  const beforeDecimal = splitArrays[0];
  const afterDecimal = splitArrays[1] ? splitArrays[1] : 0;
  if (_.size(afterDecimal) > 1) {
    return {
      invalid: true,
      message: 'Precision cannot exceed 1 decimal place'
    };
  }
  if (_.size(beforeDecimal) > 2 && Number(beforeDecimal) !== 100) {
    return {
      invalid: true,
      message: 'Must be 100 or less'
    };
  }
  if (Number(beforeDecimal) === 100 && Number(afterDecimal) !== 0) {
    return {
      invalid: true,
      message: 'Must be 100 or less'
    };
  }
  return { invalid: false };
};

const validateEncounterLearningObjective = input => {
  if (!input || input.trim() === '') {
    return {
      invalid: true,
      message: 'Field is required'
    };
  }

  if (_.size(input) > 500) {
    return {
      invalid: true,
      message: 'Learning objective must be less than 500 characters'
    };
  }

  return { invalid: false };
};

const validateSingleLearningObjectiveRevus = inputValue => {
  if (!inputValue || inputValue === '' || Number(inputValue) === 0) {
    return {
      invalid: true,
      message: 'Required'
    };
  }

  const regex = /^[0-9]*\.?[0-9]*$/g;
  const regexMatch = inputValue.match(regex);
  const failsRegex = !regexMatch || regexMatch.length < 1;

  if (failsRegex) {
    return {
      invalid: true,
      message: 'Must be digits'
    };
  }

  const splitArrays = inputValue.toString().split('.');
  const beforeDecimal = splitArrays[0];
  const afterDecimal = splitArrays[1] ? splitArrays[1] : 0;
  if (_.size(afterDecimal) > 1) {
    return {
      invalid: true,
      message: 'Cannot exceed 1 decimal place'
    };
  }
  if (_.size(beforeDecimal) > 2 && Number(beforeDecimal) !== 100) {
    return {
      invalid: true,
      message: 'Must be 100 or less'
    };
  }
  if (Number(beforeDecimal) === 100 && Number(afterDecimal) !== 0) {
    return {
      invalid: true,
      message: 'Must be 100 or less'
    };
  }
  return { invalid: false };
};

const validateTotalLearningObjectiveRevus = (objectives, maxRevus) => {
  if (!objectives || objectives.length < 1 || !maxRevus) {
    return {
      invalid: true,
      message: 'Required'
    };
  }

  let totalRevus = 0;
  objectives.forEach(obj => {
    if (obj.revus) {
      totalRevus += Number(obj.revus);
    }
  });

  if (Number(totalRevus) !== Number(maxRevus)) {
    return {
      invalid: true,
      message: `The sum of all objective ReVUs must equal ${maxRevus} but instead equals ${totalRevus}`
    };
  }

  return { invalid: false };
};

const validateEncounterTitle = textField => {
  if (
    !textField ||
    textField.trim() === '' ||
    textField.trim() === 'Untitled Encounter'
  ) {
    return {
      invalid: true,
      message: 'Encounter must have a title'
    };
  }

  if (textField.length > 100) {
    return {
      invalid: true,
      message: 'Encounter title cannot exceed 100 characters'
    };
  }

  return { invalid: false };
};

const validateEncounterFacultyAssigned = (inputValue, leadPercent) => {
  const inputFilled = inputValue && inputValue.length > 0;

  if (leadPercent < 100 && !inputFilled) {
    return {
      invalid: true,
      message: 'Select required'
    };
  }

  if (leadPercent === 100 && inputFilled) {
    return {
      invalid: true,
      message: 'Cannot have participating faculty if Lead Percent is 100%'
    };
  }

  return { invalid: false };
};

export {
  validateEncounterStartDateField,
  validateEncounterEndDateField,
  validateEncounterLeadPercent,
  validateEncounterFacultyAssigned,
  validateEncounterLearningObjective,
  validateSingleLearningObjectiveRevus,
  validateTotalLearningObjectiveRevus,
  validateEncounterTitle
};
