import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton
} from '@material-ui/core';
import { EditIcon } from '@xcomp/xcomp-design-library/dist/icons';
import LoverbShowContainer from './LoverbShow/LoverbShowContainer';

const AccordionDetailsSC = styled(AccordionDetails)`
  flex-wrap: wrap;
`;

const TitleAreaSC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 1rem;
`;

const TitleBoxSC = styled.div.attrs(({ size }) => ({
  width: size
}))`
  font-weight: 400;
  width: ${props => props.width};
`;

const IconButtonSC = styled(IconButton)`
  && {
    font-size: 0;
  }
`;

const EducationLevelShow = ({ educationLevel }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <TitleAreaSC>
          <TitleBoxSC size="100%">
            {`Level ${educationLevel.level} - ${educationLevel.education}`}
          </TitleBoxSC>
          <IconButtonSC>
            <Link
              to={`/school-management/education-level/edit/${educationLevel.uuid}`}
            >
              <EditIcon />
            </Link>
          </IconButtonSC>
        </TitleAreaSC>
      </AccordionSummary>
      <AccordionDetailsSC>
        <LoverbShowContainer educationLevelUuid={educationLevel.uuid} />
      </AccordionDetailsSC>
    </Accordion>
  );
};

EducationLevelShow.propTypes = {
  educationLevel: PropTypes.object
};

EducationLevelShow.defaultProps = {
  educationLevel: {}
};

export default EducationLevelShow;
