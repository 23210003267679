import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { doPostSchool } from '../../../redux/actions/schoolActions';

import { validateInputString } from '../../../helpers/validation/validateGeneric';

import SchoolAdd from './SchoolAdd';

export default function SchoolAddContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const institutionUuid = useSelector(state => state.userState.institutionUuid);

  const [abbreviation, setAbbreviation] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [hasErrors, setHasErrors] = useState(false);

  const handleChange = event => {
    const { name, value } = event.target;
    switch (name) {
      case 'abbreviation': {
        setAbbreviation(value);
        break;
      }
      case 'schoolName': {
        setSchoolName(value);
        break;
      }
      default:
        break;
    }
  };

  const validate = () => {
    let validationErrors = false;

    const schoolNameError = validateInputString(schoolName);
    const abbreviationError = validateInputString(abbreviation);

    if (schoolNameError.invalid) {
      validationErrors = true;
    }

    if (abbreviationError.invalid) {
      validationErrors = true;
    }

    return validationErrors;
  };

  const handleCreateSchool = () => {
    const hasError = validate();

    if (hasError) {
      setHasErrors(true);
    } else {
      const payload = {
        institutionUuid,
        abbreviation,
        schoolName
      };

      dispatch(doPostSchool(payload));
      history.push('/institution-management/schools');
    }
  };

  return (
    <SchoolAdd
      handleChange={handleChange}
      abbreviation={abbreviation}
      schoolName={schoolName}
      hasErrors={hasErrors}
      handleCreateSchool={handleCreateSchool}
    />
  );
}
