import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonInline, ButtonIcon } from '@xcomp/xcomp-design-library';
import Modal from '../../../../Library/Modal/Modal';

export const DeleteButtonSC = styled(ButtonIcon)`
  && {
    svg {
      font-size: 20px;
    }
  }
`;

export const ModalBodySC = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;
`;

export const ModalButtonsSC = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DeleteSectionButton = ({
  modalOpen,
  className,
  handleModalClose,
  handleModalOpen,
  handleSectionDelete,
  sectionUuid
}) => (
  <div className={className}>
    <DeleteButtonSC
      key="close"
      aria-label="Close"
      color="inherit"
      size="small"
      onClick={handleModalOpen}
      noHoverBg
    >
      <DeleteIcon />
    </DeleteButtonSC>
    <Modal open={modalOpen} onClose={handleModalClose}>
      <ModalBodySC>
        <div>
          <h3>Are you sure you want to delete this section?</h3>
          <em>
            Any deletions won&rsquo;t be saved unless you click Update above.
            Refresh the page to discard your changes.
          </em>
        </div>
        <ModalButtonsSC>
          <ButtonInline onClick={event => handleModalClose(event)}>
            Cancel
          </ButtonInline>
          <ButtonInline onClick={() => handleSectionDelete(sectionUuid)}>
            Delete Section
          </ButtonInline>
        </ModalButtonsSC>
      </ModalBodySC>
    </Modal>
  </div>
);

DeleteSectionButton.propTypes = {
  sectionUuid: PropTypes.string,
  modalOpen: PropTypes.bool,
  className: PropTypes.string,
  handleModalClose: PropTypes.func,
  handleModalOpen: PropTypes.func,
  handleSectionDelete: PropTypes.func
};

DeleteSectionButton.defaultProps = {
  sectionUuid: '',
  modalOpen: false,
  className: '',
  handleModalClose: undefined,
  handleModalOpen: undefined,
  handleSectionDelete: undefined
};

export default DeleteSectionButton;
