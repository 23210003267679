import {
  GET_SCHOOL,
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_ERROR,
  GET_SCHOOLS,
  GET_SCHOOLS_SUCCESS,
  GET_SCHOOLS_ERROR,
  POST_SCHOOL,
  POST_SCHOOL_SUCCESS,
  POST_SCHOOL_ERROR,
  PUT_SCHOOL,
  PUT_SCHOOL_SUCCESS,
  PUT_SCHOOL_ERROR,
  DELETE_SCHOOL,
  DELETE_SCHOOL_SUCCESS,
  DELETE_SCHOOL_ERROR,
  PATCH_SCHOOL_REVUS,
  PATCH_SCHOOL_REVUS_SUCCESS,
  PATCH_SCHOOL_REVUS_ERROR,
  PATCH_SCHOOL_DENOMINATORS_SUCCESS,
  PATCH_SCHOOL_DENOMINATORS_ERROR,
  PATCH_SCHOOL_DENOMINATORS
} from '../actions-type';

const doGetSchool = schoolUuid => ({
  type: GET_SCHOOL,
  payload: {
    schoolUuid
  }
});

const doGetSchoolSuccess = result => ({
  type: GET_SCHOOL_SUCCESS,
  payload: result
});

const doGetSchoolError = error => ({
  type: GET_SCHOOL_ERROR,
  payload: error
});

const doGetSchools = () => ({
  type: GET_SCHOOLS
});

const doGetSchoolsSuccess = result => ({
  type: GET_SCHOOLS_SUCCESS,
  payload: result
});

const doGetSchoolsError = error => ({
  type: GET_SCHOOLS_ERROR,
  payload: error
});

const doPostSchool = school => ({
  type: POST_SCHOOL,
  payload: {
    school
  }
});

const doPostSchoolSuccess = result => ({
  type: POST_SCHOOL_SUCCESS,
  payload: result
});

const doPostSchoolError = error => ({
  type: POST_SCHOOL_ERROR,
  payload: error
});

const doPutSchool = school => ({
  type: PUT_SCHOOL,
  payload: {
    school
  }
});

const doPutSchoolSuccess = result => ({
  type: PUT_SCHOOL_SUCCESS,
  payload: result
});

const doPutSchoolError = error => ({
  type: PUT_SCHOOL_ERROR,
  payload: error
});

const doDeleteSchool = schoolUuid => ({
  type: DELETE_SCHOOL,
  payload: {
    schoolUuid
  }
});

const doDeleteSchoolSuccess = schoolUuid => ({
  type: DELETE_SCHOOL_SUCCESS,
  payload: schoolUuid
});

const doDeleteSchoolError = error => ({
  type: DELETE_SCHOOL_ERROR,
  payload: error
});

const doPatchSchoolRevus = revus => ({
  type: PATCH_SCHOOL_REVUS,
  payload: {
    revus
  }
});

const doPatchSchoolRevusSuccess = school => ({
  type: PATCH_SCHOOL_REVUS_SUCCESS,
  payload: { school }
});

const doPatchSchoolRevusError = error => ({
  type: PATCH_SCHOOL_REVUS_ERROR,
  payload: error
});

const doPatchSchoolDenominators = denominators => ({
  type: PATCH_SCHOOL_DENOMINATORS,
  payload: {
    denominators
  }
});

const doPatchSchoolDenominatorsSuccess = school => ({
  type: PATCH_SCHOOL_DENOMINATORS_SUCCESS,
  payload: { school }
});

const doPatchSchoolDenominatorsError = error => ({
  type: PATCH_SCHOOL_DENOMINATORS_ERROR,
  payload: error
});

export {
  doGetSchool,
  doGetSchoolSuccess,
  doGetSchoolError,
  doGetSchools,
  doGetSchoolsSuccess,
  doGetSchoolsError,
  doPostSchool,
  doPostSchoolSuccess,
  doPostSchoolError,
  doPutSchool,
  doPutSchoolSuccess,
  doPutSchoolError,
  doDeleteSchool,
  doDeleteSchoolSuccess,
  doDeleteSchoolError,
  doPatchSchoolRevus,
  doPatchSchoolRevusSuccess,
  doPatchSchoolRevusError,
  doPatchSchoolDenominators,
  doPatchSchoolDenominatorsSuccess,
  doPatchSchoolDenominatorsError
};
