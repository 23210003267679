import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { cloneDeep, values } from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useContainerDimensions } from '../../../helpers/hooks/layout.hooks';
import PartOpportunityReportOpportunityDetail from './OpportunityReportOpportunityDetail';
import AccordionRowContainer from '../../Library/AccordionRow/AccordionRowContainer';

const OpportunityReportAssessmentBlockPanelAccordion = styled(
  AccordionRowContainer
)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
    max-width: ${props => props.maxLabelWidth};
    white-space: nowrap;
    div {
      color: ${props => props.theme.fontColors.darker};
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .column_one_label {
    min-width: 140px;
  }
  .column_two_label {
    min-width: 100px;
  }
  .column_three_label {
    min-width: 120px;
  }
  .column_one_label,
  .column_two_label,
  .column_three_label {
    flex-grow: 0;
    text-align: right;
  }

  .column_one_label,
  .column_two_label {
    margin-right: 16px;
    span {
      color: ${props => props.theme.fontColors.darker};
      font-size: 14px;
      font-weight: 500;
    }
  }

  .column_three_label {
    span {
      color: ${props => props.theme.fontColors.darker};
      font-size: 14px;
      font-weight: 500;
    }
  }

  .hover-effect {
    padding-right: 44px;
  }
  padding-left: 32px;
  width: 100%;
`;

const ProgressBar = styled.div`
  width: calc(100% - 65px);
  margin-left: 65px;
`;

const PartOpportunityReportAssessmentBlockPanel = ({
  isLoading,
  panelLabel,
  studentAssessmentBlockData,
  studentAssessmentBlockRelativeScore,
  studentAssessmentBlockOpportunityCount
}) => {
  const panelRef = useRef(null);
  const { width } = useContainerDimensions(panelRef);

  if (isLoading) {
    <ProgressBar>
      <LinearProgress />
    </ProgressBar>;
  }

  const studentAssessmentBlockDataCopy = cloneDeep(studentAssessmentBlockData);

  delete studentAssessmentBlockDataCopy.totalOpportunityCount;
  delete studentAssessmentBlockDataCopy.totalRelativeScore;

  return (
    <OpportunityReportAssessmentBlockPanelAccordion
      columnOne={`${studentAssessmentBlockOpportunityCount}`}
      columnTwo={`${studentAssessmentBlockRelativeScore}`}
      columnThree=" "
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
      columnThreeClassName="column_three_label"
      expandWidth="66px"
      height="50px"
      label={panelLabel}
      panelRef={panelRef}
      maxLabelWidth={`${width - 520}px`}
      withLabelTooltip
    >
      {values(studentAssessmentBlockDataCopy).map((opportunity, index) => {
        return (
          <PartOpportunityReportOpportunityDetail
            key={opportunity.resultCardUuid}
            isLoading={isLoading}
            opportunityNumber={index + 1}
            opportunityRelativeScore={opportunity.totalRelativeScore}
            resultCardUuid={opportunity.resultCardUuid}
          />
        );
      })}
    </OpportunityReportAssessmentBlockPanelAccordion>
  );
};

PartOpportunityReportAssessmentBlockPanel.propTypes = {
  isLoading: PropTypes.bool,
  studentAssessmentBlockData: PropTypes.object,
  panelLabel: PropTypes.string,
  studentAssessmentBlockRelativeScore: PropTypes.string,
  studentAssessmentBlockOpportunityCount: PropTypes.number
};

PartOpportunityReportAssessmentBlockPanel.defaultProps = {
  isLoading: false,
  studentAssessmentBlockData: {},
  panelLabel: '',
  studentAssessmentBlockRelativeScore: '',
  studentAssessmentBlockOpportunityCount: 0
};

export default PartOpportunityReportAssessmentBlockPanel;
