import React, { useReducer, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  allFieldsAreValid,
  validateDecimalStringField,
  validateSelectField
} from '../../../../helpers/validation/validateGeneric';
import {
  doGetGradeCollection,
  doPutCollectionAssessments
} from '../../../../redux/actions/partGradeSettingsActions';
// import { doClearCreated } from '../../../../redux/actions/courseCollectionActions';
import { useUnsavedChanges } from '../../../Library/Modal/UnsavedChangesModal/useUnsavedChanges';
import EditGradeCollection from './EditGradeCollection';
import {
  editGradeCollectionReducer,
  initialGradeCollectionState
} from './editGradeCollectionState';
import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';
import { partSelectorByUuid } from '../../../../redux/selectors/partsSelectors';
import { partGradeCollectionSelector } from '../../../../redux/selectors/partGradeSettingsSelectors';
import { assessmentsSelector } from '../../../../redux/selectors/assessmentSelectors';

export default function EditGradeCollectionContainer({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { partUuid, gradeCollectionUuid } = match.params;

  const [state, editGradeCollectionDispatch] = useReducer(
    editGradeCollectionReducer,
    initialGradeCollectionState
  );

  const part = useSelector(
    state => partSelectorByUuid(state, partUuid),
    _.isEqual
  );

  const existingGradeCollection = useSelector(
    state => partGradeCollectionSelector(state, gradeCollectionUuid),
    _.isEqual
  );

  const partAssessments = useSelector(
    state => assessmentsSelector(state, partUuid),
    shallowEqual
  );

  // const successfullyUpdatedPartDataSource = useSelector(
  //   state => state.formSuccessState.successfullyUpdatedPartDataSource
  // );

  const section = _.get(part, 'section', null);
  const course = _.get(section, 'course', null);
  const courseNumber = _.get(course, 'course_master.courseNumber', '');
  const courseTitle = _.get(course, 'course_master.title', '');
  const sectionNumber = _.get(section, 'sectionNumber', '')
    .toString()
    .padStart(2, 0);
  const partNumber = _.get(part, 'partNumber', '').toString().padStart(2, 0);

  const clearForm = useCallback(() => {
    editGradeCollectionDispatch({
      type: 'clearForm'
    });
    history.push(
      `/syllabus-management/part/${partUuid}/part-grade-settings&courseUuid=${course.uuid}&sectionUuid=${section.uuid}&partNumber=${partNumber}&sectionNumber=${sectionNumber}`
    );
  }, [history, partUuid, course, section, partNumber, sectionNumber]);

  const [
    isUnsavedChangesModalOpen,
    unsavedChanges,
    handleModalOpen,
    handleModalClose,
    setUnsavedChange,
    removeUnsavedChange,
    handleProceed
  ] = useUnsavedChanges(clearForm);

  useEffect(() => {
    if (partUuid && gradeCollectionUuid)
      dispatch(doGetGradeCollection({ partUuid, gradeCollectionUuid }));
  }, [dispatch, partUuid, gradeCollectionUuid]);

  // useEffect(() => {
  //   if (successfullyUpdatedPartDataSource) {
  //     dispatch(doClearCreated());
  //     removeUnsavedChange('dataSource');
  //   }
  // }, [dispatch, removeUnsavedChange, successfullyUpdatedPartDataSource]);

  const onGeneralErrorNotification = errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage));

  const setExistingState = ({
    uuid,
    title,
    scoreType,
    distributeWeightEqually,
    collection_assessments
  }) => {
    editGradeCollectionDispatch({
      type: 'setExisting',
      payload: {
        uuid,
        title,
        scoreType,
        distributeWeightEqually,
        collection_assessments
      }
    });
  };

  const {
    showValidationErrors,
    usedAssessmentUuids,
    ...gradeCollection
  } = state;

  useEffect(() => {
    if (existingGradeCollection?.partGradeSettingsUuid) {
      setExistingState({
        ...existingGradeCollection
      });
    }
  }, [existingGradeCollection]);

  useEffect(() => {
    if (partAssessments && partAssessments.length > 0) {
      editGradeCollectionDispatch({
        type: 'setPartAssessments',
        payload: {
          partAssessments
        }
      });
    }
  }, [partAssessments]);

  const setValidationErrors = ({
    collection_assessments,
    distributeWeightEqually
  }) => {
    // validate assessments length > 0
    const minAssessmentsError =
      Object.keys(collection_assessments).length < 1
        ? {
            invalid: true,
            message:
              'Error: There must be at least one assessment in a collection.'
          }
        : {
            invalid: false
          };

    let collectionAssessmentError = { invalid: false };

    // validate each weight and assessmentUuid
    Object.keys(collection_assessments).forEach(uuid => {
      const thisWeightError = !distributeWeightEqually
        ? validateDecimalStringField(collection_assessments[uuid].weight)
        : { invalid: false };

      const thisAssessmentError = validateSelectField(
        collection_assessments[uuid].assessmentUuid
      );

      if (thisWeightError.invalid || thisAssessmentError.invalid) {
        collectionAssessmentError = { invalid: true };
      }
    });

    const newValidationErrors = {
      minAssessmentsError,
      collectionAssessmentError
    };

    return newValidationErrors;
  };

  const buildCollectionAssessmentsPayload = (
    { uuid, scoreType, collection_assessments, distributeWeightEqually },
    partUuid
  ) => {
    const collectionAssessmentsArray = Object.keys(collection_assessments).map(
      uuid => {
        const { assessmentUuid, weight, required } = collection_assessments[
          uuid
        ];
        return {
          assessmentUuid,
          weight: distributeWeightEqually ? null : Number(weight),
          required
        };
      }
    );
    const payload = {
      partUuid,
      scoreType,
      gradeCollectionUuid: uuid,
      distributeWeightEqually,
      collection_assessments: collectionAssessmentsArray
    };
    return payload;
  };

  const handleSubmitCollectionAssessments = () => {
    const newValidationErrors = setValidationErrors(gradeCollection);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = buildCollectionAssessmentsPayload(
        gradeCollection,
        partUuid
      );

      dispatch(doPutCollectionAssessments(payload));
      removeUnsavedChange('collectionAssessments');

      editGradeCollectionDispatch({
        type: 'setShowValidationErrors',
        payload: { showValidationErrors: false }
      });
    } else {
      if (newValidationErrors.minAssessmentsError.invalid) {
        onGeneralErrorNotification(
          newValidationErrors.minAssessmentsError.message
        );
      } else {
        onGeneralErrorNotification(
          'There are errors in the form. Please address these errors and resubmit.'
        );
      }

      editGradeCollectionDispatch({
        type: 'setShowValidationErrors',
        payload: { showValidationErrors: true }
      });
    }
  };

  return (
    <EditGradeCollection
      gradeCollection={gradeCollection}
      usedAssessmentUuids={usedAssessmentUuids}
      partUuid={partUuid}
      courseNumber={courseNumber}
      courseTitle={courseTitle}
      sectionNumber={sectionNumber}
      partNumber={partNumber}
      partTitle={part?.title}
      partAssessments={partAssessments}
      formDispatch={editGradeCollectionDispatch}
      showValidationErrors={showValidationErrors}
      isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
      unsavedChanges={unsavedChanges}
      setUnsavedChange={setUnsavedChange}
      removeUnsavedChange={removeUnsavedChange}
      handleModalOpen={handleModalOpen}
      handleModalClose={handleModalClose}
      handleProceed={handleProceed}
      handleSubmitAssessments={handleSubmitCollectionAssessments}
    />
  );
}

EditGradeCollectionContainer.propTypes = {
  match: PropTypes.object
};

EditGradeCollectionContainer.defaultProps = {
  match: {}
};
