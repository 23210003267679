import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AssessmentCard from './AssessmentCard';
import { selectAssessment } from '../../../../redux/selectors/assessmentSelectors';

export default function AssessmentCardContainer({
  assessmentUuid,
  itemNumber,
  selectSyllabusItem
}) {
  const assessment = useSelector(state =>
    selectAssessment(state, assessmentUuid)
  );

  const selectedItem = useSelector(
    state => state.syllabusState.selectedItemUuid
  );

  return (
    <AssessmentCard
      assessment={assessment}
      itemNumber={itemNumber}
      selectedItem={selectedItem}
      selectSyllabusItem={selectSyllabusItem}
    />
  );
}

AssessmentCardContainer.propTypes = {
  itemNumber: PropTypes.number,
  assessmentUuid: PropTypes.string,
  selectSyllabusItem: PropTypes.func
};

AssessmentCardContainer.defaultProps = {
  itemNumber: 0,
  assessmentUuid: undefined,
  selectSyllabusItem: undefined
};
