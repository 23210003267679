import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { ButtonInline, ButtonPrimary } from '@xcomp/xcomp-design-library';

const Layout = styled.div`
  position: absolute;
  background: ${props => props.theme.colors.white};
  width: 900px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RowHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 24px;
`;

const RowSubHeader = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 14px;
`;

const RowSearch = styled.div`
  height: 500px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid ${props => props.theme.colors.grey[300]};
  border-radius: 4px;
  overflow-y: scroll;
`;

const RowAdd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100px;
  margin-left: 20px;
  margin-right: 20px;
`;

const SearchList = styled.div``;

const SearchListTitles = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  margin-left: 20px;
  margin-right: 35px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-left: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  background: ${props => props.theme.colors.grey[600]};
  color: ${props => props.theme.colors.white};
`;

const IntitutionId = styled.div`
  width: 50%;
  padding-left: 20px;
  padding-top: 15px;
  height: 50px;
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Name = styled.div`
  width: 50%;
  padding-top: 15px;
  padding-left: 20px;
  height: 50px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const ListApplicants = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  background ${props =>
    props.highlight ? props.theme.colors.primary : 'none'};
  color: ${props => (props.highlight ? 'white' : 'black')};
`;

const Cancel = styled(ButtonInline)`
  && {
    margin-right: 30px;
  }
`;

export default function CohortStudentAssign({
  openAssignStudent,
  applicants,
  handleUserSelection,
  handleAssignStudentClose,
  handleConvertStudent,
  handleClearSelection
}) {
  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openAssignStudent}
        onClose={handleAssignStudentClose}
      >
        <Layout>
          <RowHeader>Assign Students</RowHeader>
          <RowSubHeader>Select from a list of active applicants</RowSubHeader>
          <SearchListTitles>
            <IntitutionId>Institution Id</IntitutionId>
            <Name>Name</Name>
          </SearchListTitles>
          <RowSearch>
            <SearchList>
              {applicants.map(applicant => (
                <ListApplicants
                  key={applicant.uuid}
                  highlight={applicant.selected}
                  onClick={() => handleUserSelection(applicant.userUuid)}
                >
                  <IntitutionId>{applicant?.user?.institutionId}</IntitutionId>
                  <Name>{`${applicant.user.firstName} ${applicant.user.lastName}`}</Name>
                </ListApplicants>
              ))}
            </SearchList>
          </RowSearch>
          <RowAdd>
            <Cancel
              onClick={() => {
                handleClearSelection();
                handleAssignStudentClose();
              }}
            >
              Cancel
            </Cancel>
            <ButtonPrimary onClick={() => handleConvertStudent()}>
              Convert to Student
            </ButtonPrimary>
          </RowAdd>
        </Layout>
      </Modal>
    </div>
  );
}

CohortStudentAssign.propTypes = {
  openAssignStudent: PropTypes.bool,
  applicants: PropTypes.array,
  handleAssignStudentClose: PropTypes.func,
  handleUserSelection: PropTypes.func,
  handleConvertStudent: PropTypes.func,
  handleClearSelection: PropTypes.func
};
CohortStudentAssign.defaultProps = {
  openAssignStudent: false,
  applicants: [],
  handleAssignStudentClose: undefined,
  handleUserSelection: undefined,
  handleConvertStudent: undefined,
  handleClearSelection: undefined
};
