import {
  SET_SELECTED_DEPARTMENT_UUID_COURSE_ADD,
  SET_SELECTED_COURSE_MASTER_UUID_COURSE_ADD,
  SET_SELECTED_ACADEMIC_YEAR_UUID_COURSE_ADD,
  SET_SELECTED_TERM_UUID_COURSE_ADD
} from '../actions-type';

export const INITIAL_STATE = {
  selectedDepartmentUuid: '',
  selectedCourseMasterUuid: '',
  selectedAcademicYearUuid: '',
  selectedTermUuid: ''
};

const courseAddReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_DEPARTMENT_UUID_COURSE_ADD: {
      return {
        ...state,
        selectedDepartmentUuid: action.payload.departmentUuid,
        selectedCourseMasterUuid: ''
      };
    }
    case SET_SELECTED_COURSE_MASTER_UUID_COURSE_ADD: {
      return {
        ...state,
        selectedCourseMasterUuid: action.payload.courseMasterUuid
      };
    }
    case SET_SELECTED_ACADEMIC_YEAR_UUID_COURSE_ADD: {
      return {
        ...state,
        selectedAcademicYearUuid: action.payload.academicYearUuid,
        selectedTermUuid: ''
      };
    }
    case SET_SELECTED_TERM_UUID_COURSE_ADD: {
      return {
        ...state,
        selectedTermUuid: action.payload.termUuid
      };
    }
    default:
      return state;
  }
};

export default courseAddReducer;
