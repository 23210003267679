import { Model, attr, fk } from 'redux-orm';
import {
  POST_INSTITUTION_RIGOR_SUCCESS,
  GET_RIGOR_HISTORICAL_REPORT_SUCCESS,
  CLEAR_RIGOR_REPORT
} from '../actions-type';

class RigorIndexReport extends Model {
  static reducer(action, RigorIndexReport, session) {
    const { payload, type } = action;
    switch (type) {
      case POST_INSTITUTION_RIGOR_SUCCESS: {
        const rigors = [...payload.rigor_index_report];
        rigors.forEach(record => RigorIndexReport.upsert(record));
        break;
      }

      case GET_RIGOR_HISTORICAL_REPORT_SUCCESS: {
        const rigors = [...payload.rigor_index_report];
        rigors.forEach(record => RigorIndexReport.upsert(record));
        break;
      }

      case CLEAR_RIGOR_REPORT: {
        const { programUuid } = action;

        RigorIndexReport.all()
          .filter(record => record.programUuid === programUuid)
          .delete();

        break;
      }
      default:
        return session;
    }
  }
}

RigorIndexReport.fields = {
  program_credits: attr(),
  program_score_average: attr(),
  milestone_credits: attr(),
  milestone_score_average: attr(),
  rigor_index: attr(),
  weighted_credits: attr(),
  classificationUuid: fk('Classification', 'rigor_index_report'),
  programUuid: fk('Program', 'rigor_index_report'),
  cohortUuid: fk('Cohort', 'rigor_index_report'),
  userUuid: fk('User', 'rigor_index_report'),
  sourceInstitutionUuid: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

RigorIndexReport.modelName = 'RigorIndexReport';

RigorIndexReport.options = {
  idAttribute: 'uuid'
};

export default RigorIndexReport;
