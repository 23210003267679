import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { ButtonOutline, ButtonPrimary } from '@xcomp/xcomp-design-library';
import FormErrors from '../../../Library/FormErrors';

import { convertNullToString } from '../../../../helpers/utilities';

import {
  optionSuffix,
  optionEthnicity,
  optionGender
} from '../../../../helpers/options';
import FormField from '../../../Library/FormField';
import FormSelectError from '../../../Library/FormSelectError';
import Date from '../../../Library/Date';

import {
  validateInputString,
  validateSelectDate,
  validateSelectField
} from '../../../../helpers/validation/validateGeneric';

import {
  ContainerPage,
  ContainerActions,
  ContainerContent,
  ElevateLow
} from '../../../Library/Layout';

const Title = styled.div`
  padding: 2rem;
  font-size: 24px;
  color: ${props => props.theme.colors.black};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const ButtonLabel = styled.div`
  padding-bottom: 10px;
`;

const ImageArea = styled.div`
  margin-top: 20px;
  margin-bottom: 100px;
`;

const UserProfileEdit = ({
  firstName,
  middleName,
  lastName,
  marriedLastName,
  suffix,
  institutionIdTypeUuid,
  institutionId,
  // textMessage,
  birthDate,
  email,
  phone,
  ethnicity,
  gender,
  optionsInstitutionIdTypes,
  hasErrors,
  handleChange,
  // handleToggleText,
  handleUpdateUser,
  handleBirthDate
}) => (
  <>
    <ContainerActions paddingTop="20px">
      <ButtonPrimary onClick={handleUpdateUser}>Save</ButtonPrimary>
    </ContainerActions>
    <ContainerPage paddingTop="50px">
      <ElevateLow>
        <Title>User Details</Title>
        <ContainerContent>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <FormErrors hasErrors={false} />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormSelectError
                name="institutionIdTypeUuid"
                value={institutionIdTypeUuid}
                label="Institution Type"
                handleChange={handleChange}
                options={optionsInstitutionIdTypes}
                disabled={true}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormField
                name="institutionId"
                label="Institution Id*"
                value={institutionId}
                disabled={true}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormField
                name="firstName"
                label="First Name*"
                value={firstName}
                onBlur={handleChange}
                hasErrors={hasErrors}
                handleValidate={validateInputString}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormField
                name="lastName"
                label="Last Name*"
                value={lastName}
                onBlur={handleChange}
                hasErrors={hasErrors}
                handleValidate={validateInputString}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormField
                name="middleName"
                label="Middle Name (Initials)"
                value={middleName}
                onBlur={handleChange}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormSelectError
                name="suffix"
                value={suffix}
                label="Suffix"
                handleChange={handleChange}
                options={optionSuffix}
                hasErrors={hasErrors}
                handleValidation={validateSelectField}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormField
                name="marriedLastName"
                label="Married Last Name"
                value={convertNullToString(marriedLastName)}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Date
                name="birthDate"
                label="Birth Date*"
                value={birthDate}
                indentifier={null}
                hasErrors={hasErrors}
                handleChange={handleBirthDate}
                handleValidate={validateSelectDate}
              />
            </Grid>
            <Grid item sm={9} xs={12}>
              <FormField
                name="email"
                label="Email"
                value={convertNullToString(email)}
                onBlur={handleChange}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormField
                name="phone"
                label="Phone"
                value={convertNullToString(phone)}
                onBlur={handleChange}
              />
            </Grid>
            {/* <Grid container justifyContent="flex-end" item sm={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={textMessage}
                    onChange={() => {
                      handleToggleText();
                    }}
                    value="checkedB"
                    color="primary"
                  />
                }
                label="Check to receive text messages"
              />
            </Grid> */}
            <Grid item sm={9} xs={12}>
              <FormSelectError
                name="ethnicity"
                value={ethnicity}
                label="Ethnicity"
                handleChange={handleChange}
                options={optionEthnicity}
                hasErrors={hasErrors}
                handleValidation={validateSelectField}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormSelectError
                name="gender"
                value={gender}
                label="Gender"
                handleChange={handleChange}
                options={optionGender}
                hasErrors={hasErrors}
                handleValidation={validateSelectField}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ImageArea>
                <ButtonLabel>Profle Picture</ButtonLabel>
                <ButtonOutline>Upload Image</ButtonOutline>
              </ImageArea>
            </Grid>
          </Grid>
        </ContainerContent>
      </ElevateLow>
    </ContainerPage>
  </>
);

UserProfileEdit.propTypes = {
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  marriedLastName: PropTypes.string,
  suffix: PropTypes.string,
  institutionIdTypeUuid: PropTypes.string,
  institutionId: PropTypes.string,
  birthDate: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.object,
    PropTypes.string
  ]),
  email: PropTypes.string,
  phone: PropTypes.string,
  ethnicity: PropTypes.string,
  gender: PropTypes.string,
  // textMessage: PropTypes.bool,
  optionsInstitutionIdTypes: PropTypes.array,
  hasErrors: PropTypes.bool,
  // handleToggleText: PropTypes.func,
  handleChange: PropTypes.func,
  handleBirthDate: PropTypes.func,
  handleUpdateUser: PropTypes.func
};

UserProfileEdit.defaultProps = {
  firstName: '',
  middleName: '',
  lastName: '',
  marriedLastName: '',
  suffix: '',
  institutionIdTypeUuid: '',
  institutionId: '',
  birthDate: null,
  email: '',
  phone: '',
  ethnicity: '',
  gender: '',
  // textMessage: false,
  hasErrors: false,
  optionsInstitutionIdTypes: [],
  // handleToggleText: undefined,
  handleChange: undefined,
  handleBirthDate: undefined,
  handleUpdateUser: undefined
};

export default UserProfileEdit;
