import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PersonPin from '@material-ui/icons/PersonPin';
import {
  ButtonPrimary,
  ButtonOutline,
  Body1
} from '@xcomp/xcomp-design-library';
import Modal from '../../../../../../Library/Modal/Modal';
import { TextFieldWithError } from '../../../../../../Library/FormComponents';
import { validateCompetencyDescription } from '../../../../../../../helpers/validation/validateCompetencyCollection';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 900px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  margin-bottom: 20px;
`;

const JustificationTextFieldWithError = styled(TextFieldWithError)`
  && {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`;

const FileUpload = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Note = styled(Body1)`
  margin-top: 10px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const CloseButton = styled(ButtonOutline)`
  && {
    margin-right: 5px;
  }
`;

const ProceedButton = styled(ButtonPrimary)`
  && {
    margin-right: 5px;
  }
`;

const Created = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.white};
  border-radius: 5px;
  padding: 15px;
  background: ${props => props.theme.colors.status.success};
`;

const DangerText = styled.span`
  color: ${props => props.theme.colors.status.error};
  font-weight: 500;
`;

const getModalView = (
  statusCommit,
  justificationMessage,
  successfullyCommitted,
  handleModalClose,
  handleModalSuccess,
  handleSubmit,
  handleJustificationChange
) => {
  if (successfullyCommitted) {
    return (
      <>
        <Created>
          <PersonPin />
          Successfully committed and graded assessment scores.
        </Created>

        <Actions>
          <div>
            <CloseButton onClick={handleModalSuccess}>Close</CloseButton>
          </div>
        </Actions>
      </>
    );
  }

  const validateJustificationField = message =>
    validateCompetencyDescription(message, 10, 250, true);

  const justificationFieldInvalid = Boolean(
    validateJustificationField(justificationMessage).invalid
  );

  return statusCommit === 'COMMITTED' ? (
    <>
      <FileUpload>
        <Note>
          Committing this part will submit all student collection & part grades
          to student transcripts. This should only be done once and will need
          justification to undo. Are you sure you want to proceed?
        </Note>
      </FileUpload>
      <Actions>
        <div>
          <CloseButton onClick={handleModalClose}>Cancel</CloseButton>
          <ProceedButton
            onClick={() => {
              handleSubmit();
            }}
          >
            Proceed
          </ProceedButton>
        </div>
      </Actions>
    </>
  ) : (
    <>
      <FileUpload>
        <Note>
          <DangerText>DANGER:</DangerText> Uncommitting this part will revoke
          all student collection & part grades that have already been sent to
          student transcripts. This should rarely be done and must be justified
          by a school admin. Are you sure you want to proceed?
        </Note>
        <JustificationTextFieldWithError
          id="justification-message"
          name="justification-message"
          value={justificationMessage || ''}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Justification"
          variant="outlined"
          multiline
          minRows="6"
          maxRows="6"
          placeholder=""
          onChange={handleJustificationChange}
          handleValidate={validateJustificationField}
          hasErrors={true}
        />
      </FileUpload>
      <Actions>
        <div>
          <CloseButton onClick={handleModalClose}>Cancel</CloseButton>
          <ProceedButton
            disabled={justificationFieldInvalid}
            onClick={() => {
              handleSubmit();
            }}
          >
            Proceed
          </ProceedButton>
        </div>
      </Actions>
    </>
  );
};

export default function StatusCommitModal({
  statusCommit,
  justificationMessage,
  isModalOpen,
  successfullyCommitted,
  handleModalClose,
  handleModalSuccess,
  handleSubmit,
  handleJustificationChange
}) {
  const modalView = getModalView(
    statusCommit,
    justificationMessage,
    successfullyCommitted,
    handleModalClose,
    handleModalSuccess,
    handleSubmit,
    handleJustificationChange
  );

  const onClickAway = successfullyCommitted
    ? handleModalSuccess
    : handleModalClose;

  return (
    <Modal open={isModalOpen} onClose={onClickAway}>
      <ModalBody>
        <Title>
          {statusCommit === 'UNCOMMITTED' ? 'Uncommit ' : 'Commit '}
          Collection & Part Grades
        </Title>
        {modalView}
      </ModalBody>
    </Modal>
  );
}

StatusCommitModal.propTypes = {
  isModalOpen: PropTypes.bool,
  justificationMessage: PropTypes.string,
  statusCommit: PropTypes.oneOf(['COMMITTED', 'UNCOMMITTED']),
  successfullyCommitted: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleJustificationChange: PropTypes.func,
  handleModalSuccess: PropTypes.func,
  handleSubmit: PropTypes.func
};

StatusCommitModal.defaultProps = {
  isModalOpen: false,
  justificationMessage: '',
  statusCommit: false,
  successfullyCommitted: false,
  handleJustificationChange: undefined,
  handleModalClose: undefined,
  handleModalSuccess: undefined,
  handleSubmit: undefined
};
