import { v4 as uuidv4 } from 'uuid';

const aptitudeInitialState = {
  classifications: [],
  classificationsRemove: [],
  examAptitudesRemove: [],
  conversionAptitudesRemove: []
};

const aptitudeReducer = (state, action) => {
  switch (action.type) {
    case 'populate': {
      const classifications = [...action.payload];
      return {
        ...state,
        classifications
      };
    }
    case 'classification_add': {
      const { category } = { ...action.payload };
      const add = {
        uuid: uuidv4(),
        type: 'new',
        category,
        source: ''
      };

      return {
        ...state,
        classifications: [...state.classifications, add]
      };
    }

    case 'classfication_update': {
      const { classificationUuid, modify } = { ...action.payload };

      const classifications = state.classifications.map(classification => {
        if (classification.uuid === classificationUuid) {
          const merge = { ...classification, ...modify };
          return merge;
        } else {
          return classification;
        }
      });

      return {
        ...state,
        classifications
      };
    }

    case 'classfication_remove': {
      const { classificationUuid } = { ...action.payload };

      const classifications = state.classifications.filter(
        classification => classification.uuid !== classificationUuid
      );

      const remove = state.classifications.filter(
        classification =>
          classification.uuid === classificationUuid &&
          classification.type !== 'new'
      );

      return {
        ...state,
        classifications,
        classificationsRemove: [...state.classificationsRemove, ...remove]
      };
    }

    case 'exam_add': {
      const { classificationUuid } = { ...action.payload };

      const classifications = state.classifications.map(classification => {
        if (
          classification.uuid === classificationUuid &&
          classification.exam_aptitudes?.length > 0
        ) {
          const add = [
            ...classification.exam_aptitudes,
            {
              uuid: uuidv4(),
              type: 'new',
              examId: '',
              examName: '',
              weight: '',
              classificationUuid
            }
          ];
          const replace = { ...classification, exam_aptitudes: add };

          return replace;
        } else if (
          classification.uuid === classificationUuid &&
          (classification.exam_aptitudes?.length === 0 ||
            !classification.exam_aptitudes)
        ) {
          const replace = {
            ...classification,
            exam_aptitudes: [
              {
                uuid: uuidv4(),
                type: 'new',
                examId: '',
                examName: '',
                weight: '',
                classificationUuid
              }
            ]
          };

          return replace;
        } else {
          return classification;
        }
      });

      return {
        ...state,
        classifications
      };
    }

    case 'exam_update': {
      const { classificationUuid, examAptitudeUuid, modify } = {
        ...action.payload
      };

      const classifications = state.classifications.map(classification => {
        if (classificationUuid === classification?.uuid) {
          if (classification.exam_aptitudes?.length > 0) {
            const exams = classification.exam_aptitudes.map(exam => {
              if (exam?.uuid === examAptitudeUuid) {
                const merge = { ...exam, ...modify };

                return merge;
              } else {
                return exam;
              }
            });

            const update = {
              ...classification,
              exam_aptitudes: exams
            };

            return update;
          } else {
            return classification;
          }
        } else {
          return classification;
        }
      });

      return {
        ...state,
        classifications
      };
    }

    case 'exam_remove': {
      const { classificationUuid, examAptitudeUuid } = {
        ...action.payload
      };

      const toRemove = [];

      const classifications = state.classifications.map(classification => {
        if (classification?.uuid === classificationUuid) {
          if (classification.exam_aptitudes?.length > 0) {
            const keep = [];

            classification.exam_aptitudes.forEach(exam => {
              if (exam.uuid !== examAptitudeUuid) {
                keep.push(exam);
              } else if (
                exam.uuid === examAptitudeUuid &&
                exam.type !== 'new'
              ) {
                toRemove.push(exam);
              }
            });

            return {
              ...classification,
              exam_aptitudes: keep
            };
          } else {
            return classification;
          }
        } else {
          return classification;
        }
      });

      return {
        ...state,
        classifications,
        examAptitudesRemove: [...state.examAptitudesRemove, ...toRemove]
      };
    }

    case 'conversion_add': {
      const { classificationUuid, examAptitudeUuid } = { ...action.payload };

      const classifications = state.classifications.map(classification => {
        if (
          classification?.uuid === classificationUuid &&
          classification.exam_aptitudes?.length > 0
        ) {
          const exams = classification.exam_aptitudes.map(exam => {
            if (
              exam?.uuid === examAptitudeUuid &&
              exam.conversion_aptitudes?.length > 0
            ) {
              const previous = exam.conversion_aptitudes;
              const add = {
                uuid: uuidv4(),
                type: 'new',
                scaledScore: '',
                percentage: ''
              };

              const merge = [...previous, add];

              return {
                ...exam,
                conversion_aptitudes: merge
              };
            } else if (
              (exam?.uuid === examAptitudeUuid && !exam.conversion_aptitudes) ||
              exam.conversion_aptitudes?.length === 0
            ) {
              const add = {
                uuid: uuidv4(),
                type: 'new',
                scaledScore: '',
                percentage: ''
              };

              return { ...exam, conversion_aptitudes: [add] };
            } else {
              return exam;
            }
          });

          return {
            ...classification,
            exam_aptitudes: exams
          };
        } else {
          return classification;
        }
      });

      return {
        ...state,
        classifications
      };
    }

    case 'conversion_update': {
      const {
        classificationUuid,
        examAptitudeUuid,
        conversionAptitudeUuid,
        modify
      } = {
        ...action.payload
      };

      const classifications = state.classifications.map(classification => {
        if (
          classification?.uuid === classificationUuid &&
          classification.exam_aptitudes?.length > 0
        ) {
          const exams = classification.exam_aptitudes.map(exam => {
            if (
              exam?.uuid === examAptitudeUuid &&
              exam.conversion_aptitudes?.length > 0
            ) {
              const conversionsUpdate = exam.conversion_aptitudes.map(
                conversion => {
                  if (conversion?.uuid === conversionAptitudeUuid) {
                    const previous = { ...conversion };
                    const merge = { ...previous, ...modify };
                    return merge;
                  } else {
                    return conversion;
                  }
                }
              );

              return {
                ...exam,
                conversion_aptitudes: conversionsUpdate
              };
            }
            return exam;
          });

          return {
            ...classification,
            exam_aptitudes: exams
          };
        } else {
          return classification;
        }
      });

      return {
        ...state,
        classifications
      };
    }

    case 'conversion_remove': {
      const { classificationUuid, examAptitudeUuid, conversionAptitudeUuid } = {
        ...action.payload
      };

      const toRemove = [];

      const classifications = state.classifications.map(classification => {
        if (classification?.uuid === classificationUuid) {
          if (classification.exam_aptitudes?.length > 0) {
            const exams = classification.exam_aptitudes.map(exam => {
              if (
                exam?.uuid === examAptitudeUuid &&
                exam.conversion_aptitudes?.length > 0
              ) {
                const keep = [];

                exam.conversion_aptitudes.forEach(conversion => {
                  if (
                    conversion?.uuid === conversionAptitudeUuid &&
                    conversion?.type !== 'new'
                  ) {
                    toRemove.push(conversion);
                  } else if (conversion?.uuid !== conversionAptitudeUuid) {
                    keep.push(conversion);
                  }
                });

                return {
                  ...exam,
                  conversion_aptitudes: keep
                };
              } else {
                return exam;
              }
            });

            return {
              ...classification,
              exam_aptitudes: exams
            };
          } else {
            return classification;
          }
        } else {
          return classification;
        }
      });

      return {
        ...state,
        classifications,
        conversionAptitudesRemove: [
          ...state.conversionAptitudesRemove,
          ...toRemove
        ]
      };
    }

    default:
      throw new Error();
  }
};

export { aptitudeReducer, aptitudeInitialState };
