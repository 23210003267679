import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonInline, Heading3, Body2 } from '@xcomp/xcomp-design-library';
import SimpleModal from '../SimpleModal';

const ModalBody = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;
  h3 {
    margin-bottom: 0.5rem;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const UnsavedChangesModal = ({
  className,
  modalOpen,
  message,
  proceedButtonText,
  handleModalClose,
  handleProceed
}) => (
  <div className={className}>
    <SimpleModal open={modalOpen} onClose={handleModalClose}>
      <ModalBody>
        <Heading3>Unsaved Changes</Heading3>
        <Body2>{message}</Body2>
        <Actions>
          <ButtonInline onClick={() => handleModalClose()}>Cancel</ButtonInline>
          <ButtonInline onClick={() => handleProceed()}>
            {proceedButtonText}
          </ButtonInline>
        </Actions>
      </ModalBody>
    </SimpleModal>
  </div>
);

UnsavedChangesModal.propTypes = {
  modalOpen: PropTypes.bool,
  message: PropTypes.string,
  proceedButtonText: PropTypes.string,
  handleModalClose: PropTypes.func,
  handleProceed: PropTypes.func,
  className: PropTypes.string
};

UnsavedChangesModal.defaultProps = {
  modalOpen: false,
  message:
    'The form you are editing has unsaved changes that will be wiped out by navigating back. You can click cancel and save your changes or click proceed to abort your changes.',
  proceedButtonText: 'Proceed',
  handleModalClose: undefined,
  handleProceed: undefined,
  className: ''
};

export default UnsavedChangesModal;
