import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InstitutionName from '../DataCells/InstitutionName';
import RecordActions from '../DataCells/RecordActions';
import ExamName from '../DataCells/ExamName';
import ExamWeight from '../DataCells/ExamWeight';
import ExamScore from '../DataCells/ExamScore';
import LetterGrade from '../DataCells/LetterGrade';
import AptitudeEditContainer from '../../TranscriptForms/Aptitude/AptitudeEditContainer';
import DeleteModal from '../../../../Library/Modal/DeleteModal/DeleteModal';
import { doDeleteTranscript } from '../../../../../redux/actions/applicantTranscriptActions';

const Hover = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.white};
  &:hover {
    cursor: pointer;
    background: ${props => props.theme.colors.grey[100]};
  }
`;

const Spacing = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Layout = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 132px;
  align-items: center;
`;

const Record = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => (props.width ? props.width : '120px')};
`;

export default function AptitudeRow({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  transcript,
  isAdmin,
}) {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    dispatch({ type: 'PUT_TRANSCRIPT_RESET' });
  };

  const handleDeletion = () => {
    dispatch(doDeleteTranscript(category, transcript?.uuid));
    setDeleteModal(false);
  };

  return (
    <>
      <Hover>
        <Layout>
          <Record width="80px">
            <Spacing>{transcript?.attempt}</Spacing>
          </Record>
          <Record>
            <Spacing>{transcript?.date}</Spacing>
          </Record>
          <Record width="300px">
            <Spacing>
              <ExamName examAptitudeUuid={transcript?.examAptitudeUuid} />
            </Spacing>
          </Record>
          <Record width="300px">
            <Spacing>
              <InstitutionName
                sourceInstitutionUuid={transcript?.sourceInstitutionUuid}
              />
            </Spacing>
          </Record>
          <Record>
            <Spacing>
              <ExamWeight examAptitudeUuid={transcript?.examAptitudeUuid} />
            </Spacing>
          </Record>
          <Record>
            <Spacing>
              <ExamScore
                conversionAptitudeUuid={transcript?.conversionAptitudeUuid}
              />
            </Spacing>
          </Record>
          <Record>
            <Spacing>
              <LetterGrade letterGradeUuid={transcript?.letterGradeUuid} />
            </Spacing>
          </Record>
          <Record>
            <Spacing>{transcript?.grade}</Spacing>
          </Record>
          <Record>
            <Spacing>{transcript?.score}</Spacing>
          </Record>
          <Record width="66px">
            <Spacing>
              {isAdmin &&
                <RecordActions
                  uuid={transcript?.uuid}
                  handleDeleteModalOpen={handleDeleteModalOpen}
                  handleEditModalOpen={handleEditModalOpen}
                />
              }
            </Spacing>
          </Record>
        </Layout>
      </Hover>
      {isAdmin &&
        <>
          <DeleteModal
            modalOpen={deleteModal}
            handleModalClose={handleDeleteModalClose}
            handleDelete={handleDeletion}
            type="Transcript Record"
          />
          <AptitudeEditContainer
            uuid={transcript?.uuid}
            formType="Edit"
            userUuid={userUuid}
            cohortUuid={cohortUuid}
            programUuid={programUuid}
            category={category}
            source={source}
            modalOpen={editModal}
            handleModalClose={handleEditModalClose}
          />
        </>
      }
    </>
  );
}

AptitudeRow.propTypes = {
  transcript: PropTypes.object,
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  isAdmin: PropTypes.bool,
};
AptitudeRow.defaultProps = {
  transcript: {},
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  isAdmin: false,
};
