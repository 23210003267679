import _ from 'lodash';

export const getTotalNumberOfUngradedParts = section => {
  const parts = _.get(section, 'parts', []);

  const notGraded = parts.filter(part => part.statusGrade === 'NOT_GRADED');

  return notGraded.length;
};

export const getTotalNumberOfGradedParts = section => {
  const parts = _.get(section, 'parts', []);

  const graded = parts.filter(part => part.statusGrade === 'GRADED');

  return graded.length;
};

export const getTotalNumberOfUncommittedParts = section => {
  const parts = _.get(section, 'parts', []);

  const uncommitted = parts.filter(part => part.statusCommit === 'UNCOMMITTED');

  return uncommitted.length;
};

export const getTotalNumberOfCommittedParts = section => {
  const parts = _.get(section, 'parts', []);

  const committed = parts.filter(part => part.statusCommit === 'COMMITTED');

  return committed.length;
};

export const getTotalNumberOfParts = section => {
  const parts = _.get(section, 'parts', []);

  return parts.length;
};

export const mapStudentScoresByStudentSectionStatus = (scores, studentsMap, resultsPerStudent) => {
  const delayedStudentScores = [];
  const activeStudentScores = [];
  const withdrewStudentScores = [];

  if (scores && scores.length > 0) {
    scores.forEach(score => {
      if (studentsMap[score.studentUuid]?.status === 'delayed') {
        delayedStudentScores.push({
          ...score,
          delayed: true,
          withdrew: false,
          student: studentsMap[score.studentUuid]
        });
      } else if (studentsMap[score.studentUuid]?.status === 'withdrew') {
        withdrewStudentScores.push({
          ...score,
          delayed: false,
          withdrew: true,
          student: studentsMap[score.studentUuid]
        });
      } else {
        activeStudentScores.push({
          ...score,
          delayed: false,
          withdrew: false,
          student: studentsMap[score.studentUuid],
          completedBlocks: resultsPerStudent?.[score.studentUuid] || 0,
        });
      }
    });
  }

  return { delayedStudentScores, activeStudentScores: _.sortBy(activeStudentScores, 'completedBlocks') , withdrewStudentScores };
};

export const mapPartGradeResultsByStudentSectionStatus = (
  partGradeResults,
  studentsMap
) => {
  const delayed = [];
  const active = [];
  const delayedStudentsMap = {};
  const activeStudentsMap = {};

  if (partGradeResults && partGradeResults.length > 0) {
    partGradeResults.forEach(result => {
      if (studentsMap[result.studentUuid]?.status === 'delayed') {
        delayed.push({
          ...result,
          delayed: true,
          withdrew: false,
          student: studentsMap[result.studentUuid]
        });
        delayedStudentsMap[result.studentUuid] =
          studentsMap[result.studentUuid];
      } else {
        active.push({
          ...result,
          delayed: false,
          withdrew: false,
          student: studentsMap[result.studentUuid]
        });
        activeStudentsMap[result.studentUuid] = studentsMap[result.studentUuid];
      }
    });
  }

  return { delayed, active, delayedStudentsMap, activeStudentsMap };
};

export const mapSectionGradeResultsByStudentSectionStatus =
  sectionPerformanceReport => {
    const delayed = [];
    const active = [];

    if (sectionPerformanceReport && sectionPerformanceReport.length > 0) {
      sectionPerformanceReport.forEach(result => {
        if (result.status === 'delayed') {
          delayed.push(result);
        } else {
          active.push(result);
        }
      });
    }

    return { delayed, active };
  };

export const createStudentMap = students => {
  const studentsMap = {};

  students.forEach(student => {
    studentsMap[student.userUuid] = student;
  });

  return studentsMap;
};
