import React from 'react';
import styled from 'styled-components';

const Message = styled.div`
  color: ${props => props.theme.fontColors.dark};
`;

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemWelcome = () => (
  <Layout>
    <Message>Select or Create</Message>
  </Layout>
);

export default ItemWelcome;
