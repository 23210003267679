import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { ButtonInline, Heading2 } from '@xcomp/xcomp-design-library';

import {
  ContainerResize,
  ContainerPage,
  ContainerContent,
  ElevateLow
} from '../../Library/Layout';
import { validateDepartmentCode } from '../../../helpers/validation/validateDepartment';
import { validateInputString } from '../../../helpers/validation/validateGeneric';

import Header from '../../Library/Header';
import FormField from '../../Library/FormField';

const ButtonArea = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

const LinkSC = styled(Link)`
  padding-right: 10px;
`;

const Cancel = styled(ButtonInline)`
  && {
    margin-right: 10px;
    color: ${props => props.theme.fontColors.dark};
  }
`;

const DepartmentAdd = ({
  name,
  code,
  hasErrors,
  handleChange,
  handleCreateDepartment
}) => {
  return (
    <ContainerResize>
      <Header title="Department Management" subtitle="Add department" />

      <ContainerPage>
        <ElevateLow>
          <ContainerContent>
            <Heading2>Department Details</Heading2>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormField
                  name="name"
                  label="Department Name*"
                  value={name}
                  onBlur={handleChange}
                  handleValidate={validateInputString}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={6}>
                <FormField
                  name="code"
                  label="Department Code*"
                  value={code}
                  onBlur={handleChange}
                  handleValidate={validateDepartmentCode}
                  hasErrors={hasErrors}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonArea>
                <LinkSC to="/school-management/departments">
                  <Cancel>CANCEL</Cancel>
                </LinkSC>
                <ButtonInline
                  onClick={() => {
                    handleCreateDepartment();
                  }}
                >
                  SAVE
                </ButtonInline>
              </ButtonArea>
            </Grid>
          </ContainerContent>
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
};

DepartmentAdd.propTypes = {
  name: PropTypes.string,
  code: PropTypes.string,
  hasErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  handleCreateDepartment: PropTypes.func
};
DepartmentAdd.defaultProps = {
  name: '',
  code: '',
  hasErrors: false,
  handleChange: undefined,
  handleCreateDepartment: undefined
};

export default DepartmentAdd;
