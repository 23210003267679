import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_STUDENT_SCORES_SUCCESS,
  GET_ASSESSMENT_BLOCK_RESULTS_SUCCESS,
  GET_ASSESSMENT_GRADES_SUCCESS,
  GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS,
  PUT_ASSESSMENT_BLOCK_RESULT_STATUS_SUCCESS
} from '../actions-type';

class AssessmentBlockResult extends Model {
  static reducer(action, AssessmentBlockResult, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_ASSESSMENT_BLOCK_RESULTS_SUCCESS: {
        const { assessmentBlockResults } = payload;
        assessmentBlockResults.forEach(assessmentBlockResult =>
          AssessmentBlockResult.upsert(assessmentBlockResult)
        );
        break;
      }
      case GET_ASSESSMENT_GRADES_SUCCESS: {
        const { assessmentBlocks } = payload;

        if (assessmentBlocks?.length > 0) {
          assessmentBlocks.forEach(assessmentBlock => {
            if (assessmentBlock?.assessment_block_results?.length > 0) {
              assessmentBlock?.assessment_block_results.forEach(blockResult =>
                AssessmentBlockResult.upsert(blockResult)
              );
            }
          });
        }
        break;
      }
      case DELETE_STUDENT_SCORES_SUCCESS: {
        const { studentUuid, assessmentBlockUuid, attemptNumber, opportunityNumber } = payload;
        const attempt = attemptNumber || opportunityNumber;

        AssessmentBlockResult.all()
          .filter(
            abr =>
              abr.studentUuid === studentUuid && 
              (!assessmentBlockUuid || abr.assessmentBlockUuid === assessmentBlockUuid) &&
              (!attempt || abr.blockAttempt === attempt)
          )
          .delete();
        break;
      }
      case PUT_ASSESSMENT_BLOCK_RESULT_STATUS_SUCCESS: {
        const { assessmentBlockResultUuid, status } = payload;

        AssessmentBlockResult.withId(assessmentBlockResultUuid).set('status', status);

        break;
      }
      case GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS: {
        const { resultCards } = payload;
        resultCards?.forEach(resultCard => {
          const { assessment } = resultCard;

          const { assessment_blocks } = assessment;

          assessment_blocks?.forEach(block => {
            const { assessment_block_results } = block;

            assessment_block_results?.forEach(blockResult => {
              AssessmentBlockResult.upsert(blockResult);
            });
          });
        });

        break;
      }
      default:
        return session;
    }
  }
}

AssessmentBlockResult.fields = {
  uuid: attr(),
  blockNumber: attr(),
  title: attr(),
  assessmentBlockUuid: fk('AssessmentBlock', 'assessmentBlockUuid'),
  studentUuid: fk({
    to: 'User',
    as: 'student',
    relatedName: 'assessmentBlockResults'
  }),
  blockAttempt: attr(),
  status: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

AssessmentBlockResult.modelName = 'AssessmentBlockResult';

AssessmentBlockResult.options = {
  idAttribute: 'uuid'
};

export default AssessmentBlockResult;
