import React, { useMemo, forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Switch, Route, Link } from 'react-router-dom';
import Header from '../../Library/Header';
import Category from './ProgramTranscript/List/Category';
import InstitutionRigorContainer from './InstitutionRigor/InstitutionRigorContainer';
import DegreeLevelIndexContainer from './DegreeLevelIndex/DegreeLevelIndexContainer';
import CourseAgeModContainer from './CourseAgeMod/CourseAgeModContainer';
import { ContainerResize } from '../../Library/Layout';

const TabsSC = styled(Tabs)`
  && {
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
    background: ${props => props.theme.colors.white};
  }
`;

const TabSC = styled(Tab)`
  && {
    padding-top: 110px;
    max-width: 100%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    line-height: 16px;
    width: 100%;
  }
`;

const LinkSC = styled(Link)`
  width: 100%;
`;

export default function ProgramDefinitions({ path, pathname }) {
  let location = 0;

  if (pathname === '/program-management/definitions/application-components') {
    location = 0;
  } else if (
    pathname === '/program-management/definitions/institution-rigor-index'
  ) {
    location = 1;
  } else if (
    pathname === '/program-management/definitions/degree-level-index'
  ) {
    location = 2;
  } else if (
    pathname === '/program-management/definitions/course-age-modification'
  ) {
    location = 3;
  }

  const renderLink = useMemo(
    () =>
      forwardRef((props, ref) => (
        // eslint-disable-next-line react/prop-types
        <LinkSC to={props.topath} {...props} ref={ref} />
      )),
    []
  );

  return (
    <ContainerResize>
      <Header
        title="Definitions"
        subtitle="Manage definitions, add and delete"
        backOn={false}
      />
      <TabsSC
        value={location}
        textColor="primary"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <TabSC
          component={renderLink}
          topath={`${path}/application-components`}
          label="Application Components"
        />
        <TabSC
          component={renderLink}
          topath={`${path}/institution-rigor-index`}
          label="Institution Rigor Index"
        />

        <TabSC
          component={renderLink}
          topath={`${path}/degree-level-index`}
          label="Degree Level Index"
        />

        <TabSC
          component={renderLink}
          topath={`${path}/course-age-modification`}
          label="Course Age Modification"
        />
      </TabsSC>

      <Switch>
        <Route
          exact
          path={`${path}/application-components`}
          component={props => {
            return <Category {...props} />;
          }}
        />
        <Route
          path={`${path}/institution-rigor-index`}
          component={props => {
            return <InstitutionRigorContainer {...props} />;
          }}
        />
        <Route
          path={`${path}/degree-level-index`}
          component={props => {
            return <DegreeLevelIndexContainer {...props} />;
          }}
        />
        <Route
          path={`${path}/course-age-modification`}
          component={props => {
            return <CourseAgeModContainer {...props} />;
          }}
        />
      </Switch>
    </ContainerResize>
  );
}

ProgramDefinitions.propTypes = {
  path: PropTypes.string,
  pathname: PropTypes.string
};

ProgramDefinitions.defaultProps = {
  path: '',
  pathname: ''
};
