import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Caption, Body1 } from '@xcomp/xcomp-design-library';
import { DateIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { Grid } from '@material-ui/core';
import SyllabusSubHeader from '../../SyllabusSubHeader';
import FormSelectError from '../../../../Library/FormSelectError';
import { convertNullToString } from '../../../../../helpers/utilities';
import { getRelatedAssessmentType } from '../../../../../helpers/constants';
import {
  linkedAssessmentsFormSelector,
  assessmentSelector
} from '../../../../../redux/selectors/assessmentSelectors';
import DateTime from '../../../../Library/DateTime';
import { validateSelectField } from '../../../../../helpers/validation/validateGeneric';

const DatetimePickerSC = styled(DateTime)`
  height: 100%;
`;

const CaptionSC = styled(Caption)`
  margin-bottom: 0;
`;

const BodyText = styled(Body1)`
  margin-top: 0;
  color: ${props => props.theme.fontColors.darker};
`;

const LinkedEncounterFields = ({
  linkedEncounterType,
  linkedAssessmentUuid,
  handleEncounterChange,
  partUuid,
  hasErrors,
  restrictEdit
}) => {
  const linkedAssessmentType = getRelatedAssessmentType(linkedEncounterType);
  const eligibleAssessments = useSelector(
    state =>
      linkedAssessmentsFormSelector(state, linkedAssessmentType, partUuid),
    _.isEqual
  );

  const linkedAssessmentOptions = eligibleAssessments.map(assessment => ({
    value: assessment.uuid,
    label: assessment.title
  }));

  const linkedAssessment = useSelector(state =>
    assessmentSelector(state, linkedAssessmentUuid)
  );

  const { startDate, endDate } = linkedAssessment;

  return (
    <>
      <Grid item xs={12}>
        <SyllabusSubHeader>
          <DateIcon />
          Related Assessment
        </SyllabusSubHeader>
        <CaptionSC>Related Assessment Type</CaptionSC>
        <BodyText>{linkedAssessmentType}</BodyText>
      </Grid>
      <Grid item xs={12}>
        <FormSelectError
          name="linkedAssessment"
          label="Related Assessment*"
          value={convertNullToString(linkedAssessmentUuid)}
          handleValidation={validateSelectField}
          hasErrors={hasErrors}
          handleChange={handleEncounterChange}
          options={linkedAssessmentOptions}
          disabled={restrictEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <SyllabusSubHeader>
          <DateIcon />
          Location and Time
        </SyllabusSubHeader>
        <CaptionSC>Location</CaptionSC>
        <BodyText>{linkedAssessment.location || '--'}</BodyText>
      </Grid>
      <Grid item xs={6}>
        <DatetimePickerSC
          value={startDate}
          name="startDate"
          handleChange={() =>
            console.log('error: this datepicker should be disabled')
          }
          label="Start Time*"
          disabled={restrictEdit}
          locked
        />
      </Grid>
      <Grid item xs={6}>
        <DatetimePickerSC
          value={endDate}
          name="endDate"
          handleChange={() =>
            console.log('error: this datepicker should be disabled')
          }
          label="End Time*"
          disabled={restrictEdit}
          locked
        />
      </Grid>
    </>
  );
};

LinkedEncounterFields.propTypes = {
  linkedEncounterType: PropTypes.oneOf([
    'Practical Experience (Linked)',
    'Seminar (Linked)'
  ]).isRequired,
  partUuid: PropTypes.string,
  linkedAssessmentUuid: PropTypes.string,
  handleEncounterChange: PropTypes.func,
  hasErrors: PropTypes.bool,
  restrictEdit: PropTypes.bool
};

LinkedEncounterFields.defaultProps = {
  partUuid: '',
  linkedAssessmentUuid: '',
  handleEncounterChange: undefined,
  hasErrors: false,
  restrictEdit: false
};

export default LinkedEncounterFields;
