import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';

import TranscriptUpload from './TranscriptUpload';

import { useWsSubscriber } from '../../../../helpers/hooks/subscription.hooks';
import { classificationsSelector } from '../../../../redux/selectors/classificationSelectors';
import { selectDegreeLevel } from '../../../../redux/selectors/sourceSelectors';

import {
  doPostTranscriptUpload,
  doGetTranscript
} from '../../../../redux/actions/applicantTranscriptActions';

export default function TranscriptUploadContainer({
  programUuid,
  cohortUuid,
  userUuid,
  category,
  source,
  openUpload,
  handleUploadClose
}) {
  const dispatch = useDispatch();
  const [records, setRecords] = useState(undefined);
  const [openMapping, setOpenMapping] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const query = `userUuid=${userUuid}&cohortUuid=${cohortUuid}&programUuid=${programUuid}&category=${category}&source=${source}`;
  const pathSubscribe = `applicant-transcript/upload/subscribe-status?${query}`;
  const redisKey = `${userUuid}-${cohortUuid}-${programUuid}-${category}-${source}`;
  const ws = useWsSubscriber(pathSubscribe, redisKey);
  const subscription = ws.result;
  const connected = ws.readyState;
  const emitter = ws.send;

  const classifications = useSelector(state => {
    const sourceChange =
      category === 'Cognitive' && source === 'Milestone' ? 'Program' : source;

    const result = classificationsSelector(
      state,
      programUuid,
      category,
      sourceChange
    );

    const names = _(result)
      .map(classification => classification.scope)
      .join(', ');
    return names;
  }, _.isEqual);

  const degreeLevels = useSelector(state => {
    const result = selectDegreeLevel(state);
    const names = _(result)
      .map(classification => classification.degreeLevel)
      .join(', ');

    return names;
  });

  useEffect(() => {
    if (subscription?.status === 'success') {
      dispatch(doGetTranscript(programUuid, cohortUuid, userUuid));
    }
  }, [dispatch, programUuid, cohortUuid, userUuid, subscription]);

  useEffect(() => {
    if (openUpload && connected) {
      emitter(redisKey);
    }
  }, [openUpload, redisKey, connected, emitter]);

  const handleRecords = records => {
    setRecords(records);
  };

  const handleUpload = () => {
    const uploadDate = moment().format('YYYY-MM-DD');

    dispatch(
      doPostTranscriptUpload(
        category,
        source,
        programUuid,
        cohortUuid,
        userUuid,
        records,
        uploadDate
      )
    );
  };

  const handleDisplayMapping = () => {
    setOpenMapping(openMapping => !openMapping);
  };

  const handleDisplayErrors = () => {
    setOpenError(openError => !openError);
  };

  const handleDisplaySummary = () => {
    setOpenSummary(openSummary => !openSummary);
  };

  const handleClearResults = () => {
    emitter(`${redisKey}-clear`);
  };

  return (
    <TranscriptUpload
      status={subscription?.status}
      error={subscription?.error}
      summary={subscription?.summary}
      uploadDate={subscription?.uploadDate}
      classifications={classifications}
      degreeLevels={degreeLevels}
      category={category}
      source={source}
      openUpload={openUpload}
      openMapping={openMapping}
      openError={openError}
      openSummary={openSummary}
      records={records}
      handleDisplayMapping={handleDisplayMapping}
      handleDisplayErrors={handleDisplayErrors}
      handleDisplaySummary={handleDisplaySummary}
      handleUploadClose={handleUploadClose}
      handleRecords={handleRecords}
      handleUpload={handleUpload}
      handleClearResults={handleClearResults}
    />
  );
}

TranscriptUploadContainer.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  programUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  userUuid: PropTypes.string,
  openUpload: PropTypes.bool,
  handleUploadClose: PropTypes.func
};
TranscriptUploadContainer.defaultProps = {
  category: undefined,
  source: undefined,
  programUuid: undefined,
  cohortUuid: undefined,
  userUuid: undefined,
  openUpload: false,
  handleUploadClose: undefined
};
