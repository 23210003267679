import { Subtitle2 } from '@xcomp/xcomp-design-library';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {
  useAssessmentCompletion,
  useGetCohortStudentAssessments
} from '../../../../helpers/hooks/useGetCohortStudentAssessments';
import CompletedPercentage from './Assessments/CompetedPercentage';
import Completed from './Assessments/Completed';
import Pending from './Assessments/Pending';
import Remaining from './Assessments/Remaining';

const Title = styled.div`
  margin-left: 132px;
  width: 100%;
`;

export const StatusLine = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }
`;

export const StatusLabel = styled(Subtitle2)`
  color: ${props => props.theme.fontColors.darker};
  position: relative;
  display: inline-block;
  z-index: 100;
  background: ${props => props.theme.colors.white};
  padding-right: 0.5rem;
  font-weight: 600;
`;

const Label = styled.span``;

const Spacing = styled.div`
  margin-bottom: 6rem;
`;

export default function CohortAssessmentContainer({
  cohortAssessmentCollectionUuid
}) {
  const { completionStatus } = useGetCohortStudentAssessments();
  const {
    completedAssessments,
    pendingAssessments,
    remainingAssessments,
    isCompleted
  } = useAssessmentCompletion({
    ...completionStatus,
    cohortAssessmentCollectionUuid
  });

  return (
    <>
      {completedAssessments?.length > 0 && (
        <>
          <Title>
            <StatusLine offset="0">
              <StatusLabel>
                <Label>Complete</Label>
                <CompletedPercentage
                  completed={completedAssessments}
                  remaining={remainingAssessments}
                />
              </StatusLabel>
            </StatusLine>
          </Title>
          <Completed completed={completedAssessments} />
        </>
      )}
      {pendingAssessments?.length > 0 && (
        <>
          <Title>
            <StatusLine offset="0">
              <StatusLabel>
                <Label>Pending</Label>
              </StatusLabel>
            </StatusLine>
          </Title>
          <Pending pending={pendingAssessments} />
        </>
      )}
      {remainingAssessments?.length > 0 && !isCompleted && (
        <>
          <Title>
            <StatusLine offset="0">
              <StatusLabel>
                <Label>Remaining</Label>
                <CompletedPercentage
                  completed={completedAssessments}
                  remaining={remainingAssessments}
                  remainingPercentage
                />
              </StatusLabel>
            </StatusLine>
          </Title>
          <Remaining remaining={remainingAssessments} />
        </>
      )}
      <Spacing />
    </>
  );
}

CohortAssessmentContainer.propTypes = {
  cohortAssessmentCollectionUuid: PropTypes.string
};

CohortAssessmentContainer.defaultProps = {
  cohortAssessmentCollectionUuid: undefined
};
