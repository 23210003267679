import { Model, attr } from 'redux-orm';
import _ from 'lodash';
import {
  GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS,
  RESET_COMPETENCY_SCORES
} from '../actions-type';

class CompetencyScore extends Model {
  static reducer(action, CompetencyScore, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS: {
        const { competencyScores } = payload;

        if (Object.keys(competencyScores)?.length > 0) {
          CompetencyScore.all().delete();

          _.forEach(competencyScores, value => {
            CompetencyScore.upsert(value);
          });
        }
        break;
      }
      case RESET_COMPETENCY_SCORES: {
        CompetencyScore.all().delete();
        break;
      }
      default:
        return session;
    }
  }
}

CompetencyScore.fields = {
  assessmentAssociationUuid: attr(),
  assessmentTechniqueUuid: attr(),
  assessmentTypeUuid: attr(),
  cohortUuid: attr(),
  competencyCollectionUuid: attr(),
  competencyUuid: attr(),
  studentUuid: attr(),
  relTotal: attr(),
  potTotal: attr()
};

CompetencyScore.modelName = 'CompetencyScore';
CompetencyScore.options = {
  idAttribute: 'competencyUuid'
};

export default CompetencyScore;
