export const CLEAR_PART_FACULTY_ASSESSMENT_SUMMARY =
  'CLEAR_PART_FACULTY_ASSESSMENT_SUMMARY';
export const GET_PART_FACULTY_ASSESSMENT_SUMMARY =
  'GET_PART_FACULTY_ASSESSMENT_SUMMARY';
export const GET_PART_FACULTY_ASSESSMENT_SUMMARY_SUCCESS =
  'GET_PART_FACULTY_ASSESSMENT_SUMMARY_SUCCESS';
export const GET_PART_FACULTY_ASSESSMENT_SUMMARY_ERROR =
  'GET_PART_FACULTY_ASSESSMENT_SUMMARY_ERROR';

export const CLEAR_PART_FACULTY_REPORT = 'CLEAR_PART_FACULTY_REPORT';
export const GET_PART_FACULTY_REPORT = 'GET_PART_FACULTY_REPORT';
export const GET_PART_FACULTY_REPORT_SUCCESS =
  'GET_PART_FACULTY_REPORT_SUCCESS';
export const GET_PART_FACULTY_REPORT_ERROR = 'GET_PART_FACULTY_REPORT_ERROR';

export const CLEAR_PART_TOPIC_ASSESSMENT_SUMMARY =
  'CLEAR_PART_TOPIC_ASSESSMENT_SUMMARY';
export const GET_PART_TOPIC_ASSESSMENT_SUMMARY =
  'GET_PART_TOPIC_ASSESSMENT_SUMMARY';
export const GET_PART_TOPIC_ASSESSMENT_SUMMARY_SUCCESS =
  'GET_PART_TOPIC_ASSESSMENT_SUMMARY_SUCCESS';
export const GET_PART_TOPIC_ASSESSMENT_SUMMARY_ERROR =
  'GET_PART_TOPIC_ASSESSMENT_SUMMARY_ERROR';

export const CLEAR_PART_TOPIC_REPORT = 'CLEAR_PART_TOPIC_REPORT';
export const GET_PART_TOPIC_REPORT = 'GET_PART_TOPIC_REPORT';
export const GET_PART_TOPIC_REPORT_SUCCESS = 'GET_PART_TOPIC_REPORT_SUCCESS';
export const GET_PART_TOPIC_REPORT_ERROR = 'GET_PART_TOPIC_REPORT_ERROR';

export const CLEAR_PART_OPPORTUNITY_REPORT = 'CLEAR_PART_OPPORTUNITY_REPORT';
export const GET_PART_OPPORTUNITY_REPORT = 'GET_PART_OPPORTUNITY_REPORT';
export const GET_PART_OPPORTUNITY_REPORT_SUCCESS =
  'GET_PART_OPPORTUNITY_REPORT_SUCCESS';
export const GET_PART_OPPORTUNITY_REPORT_ERROR =
  'GET_PART_OPPORTUNITY_REPORT_ERROR';

export const GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL =
  'GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL';
export const GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL_SUCCESS =
  'GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL_SUCCESS';
export const GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL_ERROR =
  'GET_PART_OPPORTUNITY_REPORT_BLOCK_DETAIL_ERROR';

export const GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL =
  'GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL';
export const GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL_SUCCESS =
  'GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL_SUCCESS';
export const GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL_ERROR =
  'GET_PART_OPPORTUNITY_REPORT_STATISTIC_BLOCK_DETAIL_ERROR';
