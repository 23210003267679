import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContainerResize from '../Library/Layout';
import Header from '../Library/Header';
import SyllabusItemsContainer from './SyllabusItems/SyllabusItemsContainer';
import SyllabusDetailContainer from './SyllabusDetail/SyllabusDetailContainer';

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  padding-top: 78px;
`;

const List = styled.div`
  height: 100%;
  width: 40%;
  overflow-y: scroll;
  padding-bottom: 100px;
`;

const Form = styled.div`
  height: 100%;
  width: 60%;
  overflow-y: scroll;
  padding-bottom: 100px;
`;

export default function SyllabusPart({ partUuid, partTitle, partNumber }) {
  return (
    <ContainerResize>
      <Header
        title={`Part ${partNumber} - ${partTitle}`}
        subtitle="Syllabus Part"
      />
      <Layout>
        <List>
          <SyllabusItemsContainer partUuid={partUuid} />
        </List>
        <Form>
          <SyllabusDetailContainer />
        </Form>
      </Layout>
    </ContainerResize>
  );
}

SyllabusPart.propTypes = {
  partUuid: PropTypes.string,
  partTitle: PropTypes.string,
  partNumber: PropTypes.number
};

SyllabusPart.defaultProps = {
  partUuid: undefined,
  partTitle: '',
  partNumber: 0
};
