import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import _ from 'lodash';
import { cohortAssessmentScoresStepSelector } from '../../../../redux/selectors/scoreSelectors';
import { sortUsersByFullName } from '../../../../helpers/utilities';

import ScoreAddContainer from '../ScoreAdd/ScoreAddContainer';
import ScoreAddAttemptContainer from '../ScoreAddAttempt/ScoreAddAttemptContainer';
import ScoreEditContainer from '../ScoreEdit/ScoreEditContainer';
import StepsList from './StepList';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 60vh;
  align-items: center;
  font-size: 80px;
`;

export default function StepListContainer({
  assessmentBlocks,
  assessmentType,
  assessmentUuid,
  programUuid,
  scoreType,
  cohortUuid
}) {
  const [openScoreAdd, setOpenScoreAdd] = useState(false);
  const [addStudent, setAddStudent] = useState('');

  const [addAttempt, setAddAttempt] = useState(0);
  const [addResultCard, setAddResultCard] = useState('');
  const [addBlockUuid, setAddBlockUuid] = useState('');

  const [openScoreEdit, setOpenScoreEdit] = useState(false);
  const [editScoreUuid, setEditScoreUuid] = useState('');

  const [openScoreAddAttempt, setOpenScoreAddAttempt] = useState(false);
  const [addAttemptStudent, setAddAttemptStudent] = useState('');
  const [latestAttempt, setAddLatestAttempt] = useState(null);

  const scores = useSelector(
    state =>
      cohortAssessmentScoresStepSelector(state, assessmentUuid, cohortUuid),
    _.isEqual
  );

  const loading = useSelector(state => state.crudState.readScores);
  const [scoresFilterSelection, setScoresFilterSelection] =
    useState('alphabetic');

  const [scoresFilter, setScoresFilter] = useState([]);

  useEffect(() => {
    if (scores) {
      const sortedAlpha = scores.sort((a, b) => sortUsersByFullName(a, b));
      setScoresFilter(sortedAlpha);
    }
  }, [scores]);

  const handleScoreAddAttemptModalOpen = (studentUuid, latestAttempt) => {
    setAddAttemptStudent(studentUuid);
    setAddLatestAttempt(latestAttempt);
    setOpenScoreAddAttempt(true);
  };

  const handleScoreAddAttemptModalClose = () => {
    setOpenScoreAddAttempt(false);
  };

  const handleScoreAddModalOpen = (
    studentUuid,
    attempt,
    gradeCardUuid,
    assessmentBlockUuid
  ) => {
    setAddStudent(studentUuid);
    setAddAttempt(attempt);
    setAddResultCard(gradeCardUuid);
    setAddBlockUuid(assessmentBlockUuid);
    setOpenScoreAdd(true);
  };

  const handleScoreAddModalClose = () => {
    setOpenScoreAdd(false);
  };

  const handleScoreEditModalOpen = scoreUuid => {
    setEditScoreUuid(scoreUuid);
    setOpenScoreEdit(true);
  };

  const handleScoreEditModalClose = () => {
    setOpenScoreEdit(false);
  };

  const handleSortSelection = event => {
    const { value } = event.target;

    if (value === 'alphabetic') {
      const sortedAlpha = scores.sort((a, b) => sortUsersByFullName(a, b));

      setScoresFilterSelection('alphabetic');
      setScoresFilter(sortedAlpha);
    } else if (value === 'relative') {
      const sortByTotalRel = _.orderBy(
        scores,
        ['totalRel', 'fullName'],
        ['desc', 'asc']
      );

      setScoresFilterSelection(value);
      setScoresFilter(sortByTotalRel);
    } else if (value === 'unreported') {
      const unreported = _.filter(scores, score => score.unreported === true);
      setScoresFilterSelection(value);
      setScoresFilter(unreported);
    } else {
      const sortedAlpha = scores.sort((a, b) => sortUsersByFullName(a, b));

      setScoresFilterSelection('');
      setScoresFilter(sortedAlpha);
    }
  };

  return loading ? (
    <LoadingContainer>
      <CircularProgress size={100} />
    </LoadingContainer>
  ) : (
    <div>
      <StepsList
        assessmentBlocks={assessmentBlocks}
        assessmentType={assessmentType}
        assessmentUuid={assessmentUuid}
        handleScoreAddAttemptModalOpen={handleScoreAddAttemptModalOpen}
        handleScoreAddModalOpen={handleScoreAddModalOpen}
        handleScoreEditModalOpen={handleScoreEditModalOpen}
        handleSortSelection={handleSortSelection}
        scoresFilterSelection={scoresFilterSelection}
        scoreType={scoreType}
        userScores={scoresFilter}
      />
      <ScoreAddContainer
        assessmentUuid={assessmentUuid}
        assessmentBlockUuid={addBlockUuid}
        attempt={addAttempt}
        handleScoreAddModalClose={handleScoreAddModalClose}
        openModal={openScoreAdd}
        programUuid={programUuid}
        resultCardUuid={addResultCard}
        scoreUuid={editScoreUuid}
        studentUuid={addStudent}
      />
      <ScoreAddAttemptContainer
        assessmentUuid={assessmentUuid}
        handleScoreAddAttemptModalClose={handleScoreAddAttemptModalClose}
        latestAttempt={latestAttempt}
        openModal={openScoreAddAttempt}
        programUuid={programUuid}
        studentUuid={addAttemptStudent}
      />
      <ScoreEditContainer
        assessmentUuid={assessmentUuid}
        handleScoreEditModalClose={handleScoreEditModalClose}
        openModal={openScoreEdit}
        programUuid={programUuid}
        scoreUuid={editScoreUuid}
        isOpportunity={scoreType === 'Opportunity'}
      />
    </div>
  );
}

StepListContainer.propTypes = {
  assessmentBlocks: PropTypes.array,
  assessmentType: PropTypes.string,
  assessmentUuid: PropTypes.string,
  programUuid: PropTypes.string,
  scoreType: PropTypes.string,
  cohortUuid: PropTypes.string
};

StepListContainer.defaultProps = {
  assessmentBlocks: [],
  assessmentType: '',
  assessmentUuid: '',
  programUuid: '',
  scoreType: '',
  cohortUuid: ''
};
