import React, { Fragment, useCallback, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import { isArray, isEqual } from 'lodash'
import {
  Collapse,
  List,
  ListSubheader,
} from '@material-ui/core'

import { rolesMap } from '../../helpers/constants'

import { programSelector } from '../../redux/selectors/programSelectors'
import { schoolSelector } from '../../redux/selectors/schoolSelectors'
import { institutionSelector } from '../../redux/selectors/institutionSelectors'
import { departmentSelector } from '../../redux/selectors/departmentsSelectors'
import { roleFeatures } from './roleFeatures'
import FeatureItem from './FeatureItem'

const roleAbbr = role => {
  let abbr
  if (role === 'program_faculty') {
    abbr = 'PFAC'
  } else if (role === 'program_staff') {
    abbr = 'PSTA'
  } else if (role === 'program_admin') {
    abbr = 'PA'
  }

  return abbr;
};


export default function NavigationRolesPrograms({
  drawerOpen,
  role,
  handleFeatureSet,
  userInstitution,
}) {
  const { selectedDepartmentUuid, selectedProgramUuid } = useSelector(
    state => state.userState,
    shallowEqual,
  )

  const program = useSelector(
    state => programSelector(state, selectedProgramUuid),
    isEqual,
  )

  const department = useSelector(
    state => departmentSelector(state, selectedDepartmentUuid),
    isEqual,
  )

  const institution = useSelector(
    state => institutionSelector(state, userInstitution.institutionUuid),
    isEqual,
  )

  const [open, setOpen] = useState({})
  const [selectedFeature, setSelectedFeature] = useState(null)
  const handleClick = useCallback(
    featureId => {
      setOpen({ ...open, [featureId]: !open[featureId] })
    },
    [open],
  )

  const handleSelectFeature = featureId => {
    setSelectedFeature(featureId)
  }

  const school = useSelector(
    state =>
      schoolSelector(
        state,
        role.role === 'department_faculty'
          ? department.schoolUuid
          : program.schoolUuid,
      ),
    isEqual,
  )

  const handleFeatureClick = useCallback(
    feature => {
      if (feature.subFeatures) {
        handleClick(feature.featureId)
        return
      }
      handleSelectFeature(feature.featureId)

      handleFeatureSet({
        role,
        department,
        school: isArray(school) ? school[0] : school,
        program,
        institution,
        feature: feature.featureName,
        featureId: `${program.uuid}-${feature.featureId}`,
      })
    },
    [
      department,
      handleClick,
      handleFeatureSet,
      institution,
      program,
      role,
      school,
    ],
  )

  return (
    <List
      component='nav'
      subheader={
        <ListSubheader component='div'>
          {drawerOpen && (
            <h3 style={{ marginBottom: 0 }}>{program.programName}</h3>
          )}
          <p>{drawerOpen ? rolesMap[role] : roleAbbr(role)}</p>
        </ListSubheader>
      }
    >
      {(roleFeatures[role] || []).map(feature => (
        <Fragment key={feature.featureId}>
          <FeatureItem
            feature={feature}
            drawerOpen={drawerOpen}
            open={open}
            handleFeatureClick={handleFeatureClick}
            selectedFeature={selectedFeature}
          />
          {feature.subFeatures && (
            <Collapse in={open[feature.featureId]} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {feature.subFeatures.map(subFeature => (
                  <FeatureItem
                    key={subFeature.featureId}
                    feature={subFeature}
                    drawerOpen={drawerOpen}
                    open={open}
                    handleFeatureClick={handleFeatureClick}
                    selectedFeature={selectedFeature}
                  />
                ))}
              </List>
            </Collapse>
          )}
        </Fragment>
      ))}
    </List>
  )
}

NavigationRolesPrograms.propTypes = {
  role: PropTypes.string,
  handleFeatureSet: PropTypes.func,
  drawerOpen: PropTypes.bool,
  userInstitution: PropTypes.object,
};

NavigationRolesPrograms.defaultProps = {
  role: '',
  handleFeatureSet: undefined,
  drawerOpen: false,
  userInstitution: {},
};
