import { Model, attr, fk } from 'redux-orm';

import {
  GET_CLASSIFICATIONS_SUCCESS,
  GET_APTITUDES_BY_CATEGORY_SUCCESS,
  POST_APTITUDES_SUCCESS,
  GET_TRANSCRIPT_SUCCESS
} from '../actions-type';

class ExamAptitude extends Model {
  static reducer(action, ExamAptitude, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_CLASSIFICATIONS_SUCCESS: {
        const classifications = [...payload.classifications];
        classifications.forEach(classification => {
          if (classification.category !== 'Cognitive') {
            if (
              classification.exam_aptitudes &&
              classification.exam_aptitudes.length > 0
            ) {
              classification.exam_aptitudes.forEach(exam => {
                const copy = { ...exam };
                delete copy.conversion_aptitudes;
                ExamAptitude.upsert(copy);
              });
            }
          }
        });
        break;
      }

      case GET_APTITUDES_BY_CATEGORY_SUCCESS: {
        const classifications = [...payload.classifications];

        classifications.forEach(classification => {
          if (classification.exam_aptitudes?.length > 0) {
            classification.exam_aptitudes.forEach(exam => {
              const copy = { ...exam };
              delete copy.conversion_aptitudes;
              ExamAptitude.upsert(copy);
            });
          }
        });

        break;
      }

      case POST_APTITUDES_SUCCESS: {
        const examAptitudesRemove = [...payload.examAptitudesRemove];
        const classifications = [...payload.classifications];

        classifications.forEach(classification => {
          if (classification.exam_aptitudes?.length > 0) {
            classification.exam_aptitudes.forEach(exam => {
              const copy = { ...exam };
              delete copy.conversion_aptitudes;
              ExamAptitude.upsert(copy);
            });
          }
        });

        if (examAptitudesRemove?.length > 0) {
          examAptitudesRemove.forEach(exam => {
            ExamAptitude.withId(exam.uuid).delete();
          });
        }

        break;
      }

      case GET_TRANSCRIPT_SUCCESS: {
        const classifications = [...payload.classifications];

        classifications.forEach(classification => {
          if (classification.exam_aptitudes?.length > 0) {
            classification.exam_aptitudes.forEach(exam => {
              const copy = { ...exam };
              delete copy.conversion_aptitudes;
              ExamAptitude.upsert(copy);
            });
          }
        });

        break;
      }

      default:
        return session;
    }
  }
}

ExamAptitude.fields = {
  uuid: attr(),
  examId: attr(),
  examName: attr(),
  weight: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  classificationUuid: fk('Classification', 'exam_aptitudes')
};

ExamAptitude.modelName = 'ExamAptitude';

ExamAptitude.options = {
  idAttribute: 'uuid'
};

export default ExamAptitude;
