import React from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js-light';

import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../helpers/format/user.format';
import OpportunityReportPanel from './OpportunityReportPanel';

const OpportunityReportPanelContainer = ({
  assessments,
  partOpportunityReportAssessmentStatisticData,
  partOpportunityReportStudentSummaryData,
  partUuid,
  studentUser
}) => {
  const studentTotalOpportunityCount =
    partOpportunityReportStudentSummaryData.totalOpportunityCount;
  const studentTotalRelativeScore =
    partOpportunityReportStudentSummaryData.totalRelativeScore;

  const studentPointsPerOpportunity = new Decimal(
    partOpportunityReportStudentSummaryData.totalRelativeScore || 0
  ).dividedBy(
    partOpportunityReportStudentSummaryData.totalOpportunityCount || 0
  );
  let panelLabel;
  let isStatisticStudent = false;

  if (studentUser.uuid === 'student_high') {
    panelLabel = 'Student High (Capacity)';
    isStatisticStudent = true;
  } else if (studentUser.uuid === 'student_average') {
    panelLabel = 'Student Average (Capacity)';
    isStatisticStudent = true;
  } else {
    const formattedFirstName = formatFirstNameMiddleName(
      studentUser.firstName,
      studentUser.middleName
    );
    const formattedLastName = formatLastName(
      studentUser.lastName,
      studentUser.marriedLastName,
      studentUser.suffix
    );
    panelLabel = `${formattedLastName}, ${formattedFirstName}`;
  }

  return (
    <OpportunityReportPanel
      assessments={assessments}
      isStatisticStudent={isStatisticStudent}
      panelLabel={panelLabel}
      partOpportunityReportAssessmentStatisticData={
        partOpportunityReportAssessmentStatisticData
      }
      partUuid={partUuid}
      studentAssessmentSummaryData={partOpportunityReportStudentSummaryData}
      studentPointsPerOpportunity={studentPointsPerOpportunity?.toFixed(2)}
      studentTotalOpportunityCount={studentTotalOpportunityCount?.toString()}
      studentTotalRelativeScore={studentTotalRelativeScore?.toString()}
      studentUser={studentUser}
    />
  );
};

OpportunityReportPanelContainer.propTypes = {
  assessments: PropTypes.array,
  partOpportunityReportAssessmentStatisticData: PropTypes.object,
  partOpportunityReportStudentSummaryData: PropTypes.object,
  partUuid: PropTypes.string,
  studentUser: PropTypes.object
};
OpportunityReportPanelContainer.defaultProps = {
  assessments: [],
  partOpportunityReportAssessmentStatisticData: {},
  partOpportunityReportStudentSummaryData: {},
  partUuid: '',
  studentUser: {}
};

export default OpportunityReportPanelContainer;
