import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const microcompetencySelector = createSelector(
  state => state,
  (_, microcompetencyUuid) => microcompetencyUuid,
  (redux, microcompetencyUuid) => {
    const microcompetencysSelectorORM = ormCreateSelector(orm.Microcompetency);

    const microcompetency =
      microcompetencysSelectorORM(redux, microcompetencyUuid) || {};

    return microcompetency;
  }
);
