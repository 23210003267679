import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { selectTranscriptCognitiveInstitutions } from '../../../../../redux/selectors/transcriptCognitiveSelectors';

import ByInstitutionCognitive from './ByInstitutionCognitive';

export default function ByInstitutionCognitiveContainer({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source
}) {
  const sourceInstitutions = useSelector(
    state =>
      selectTranscriptCognitiveInstitutions(
        state,
        programUuid,
        userUuid,
        cohortUuid,
        category,
        source
      ),
    _.isEqual
  );

  return (
    <>
      {sourceInstitutions.map(sourceInstitutionUuid => (
        <ByInstitutionCognitive
          key={sourceInstitutionUuid}
          userUuid={userUuid}
          cohortUuid={cohortUuid}
          programUuid={programUuid}
          category={category}
          source={source}
          sourceInstitutionUuid={sourceInstitutionUuid}
        />
      ))}
    </>
  );
}

ByInstitutionCognitiveContainer.propTypes = {
  category: PropTypes.string,
  source: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string
};
ByInstitutionCognitiveContainer.defaultProps = {
  category: undefined,
  source: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined
};
