import { combineReducers } from 'redux';
import { createReducer } from 'redux-orm';
import { orm } from '../models';

import assessmentStatusReducer from './assessmentStatusReducer';
import gradingStatusReducer from './gradingStatusReducer';
import cohortCompetencyReportReducer from './cohortCompetencyReportReducer';
import courseAddReducer from './courseAddReducer';
import courseEditReducer from './courseEditReducer';
import crudReducer from './crudReducer';
import fetchErrorReducer from './fetchErrorReducer';
import fetchSectionsReducer from './fetchSectionsReducer';
import filterCourseMasterReducer from './filterCourseMasterReducer';
import filterCourseReducer from './filterCourseReducer';
import formSuccessReducer from './formSuccessReducer';
import gradeSummaryReducer from './gradeSummaryReducer';
import navigationReducer from './navigationReducer';
import notificationReducer from './notificationReducer';
import partFacultyReportReducer from './partFacultyReportReducer';
import partOpportunityReportReducer from './partOpportunityReportReducer';
import partTopicReportReducer from './partTopicReportReducer';
import partGroupSummaryReducer from './partGroupSummaryReducer';
import reportLoadingReducer from './reportLoadingReducer';
import sectionPerformanceReportReducer from './sectionPerformanceReportReducer';
import searchReducer from './searchReducer';
import syllabusReducer from './syllabusReducer';
import uploadApplicantsReducer from './uploadApplicantsReducer';
import uploadDepartmentRolesReducer from './uploadDepartmentRolesReducer';
import uploadLegacyGradesReducer from './uploadLegacyGradesReducer';
import uploadScoresReducer from './uploadScoresReducer';
import uploadStudentsSectionReducer from './uploadStudentsSectionReducer';
import checkUsersReducer from './checkUsersReducer';
import userReducer from './userReducer';
import authorizationReducer from './authorizationReducer';
import applicantDemographicReportReducer from './applicantDemographicReportReducer';
import applicantCommunicationReportReducer from './applicantCommunicationReportReducer';
import applicantPhysicalSkillsReportReducer from './applicantPhysicalSkillsReportReducer';
import applicantCognitiveReportReducer from './applicantCognitiveReportReducer';
import applicantNoncognitiveReportReducer from './applicantNoncognitiveReportReducer';
import openAccordionsReducer from './openAccordionsReducer';
import { descriptionReducer } from './descriptionReducer';
import sectionPerformanceOpportunityReportReducer from './sectionPerformanceOpportunityReportReducer';
import cohortAssessmentReportReducer from './cohortAssessmentReportReducer';
import cohortOpportunityReportReducer from './cohortOpportunityReportReducer';
import cohortQuantitativeSkillsReportReducer from './cohortQuantitativeReportReducer';

const rootReducer = combineReducers({
  orm: createReducer(orm),
  assessmentStatusState: assessmentStatusReducer,
  applicantCognitiveReportState: applicantCognitiveReportReducer,
  applicantCommunicationReportState: applicantCommunicationReportReducer,
  applicantDemographicReportState: applicantDemographicReportReducer,
  applicantNoncognitiveReportState: applicantNoncognitiveReportReducer,
  applicantPhysicalSkillsReportState: applicantPhysicalSkillsReportReducer,
  gradingStatusState: gradingStatusReducer,
  cohortCompetencyReportState: cohortCompetencyReportReducer,
  cohortAssessmentReportState: cohortAssessmentReportReducer,
  cohortOpportunityReportState: cohortOpportunityReportReducer,
  courseAddState: courseAddReducer,
  courseEditState: courseEditReducer,
  crudState: crudReducer,
  fetchErrorState: fetchErrorReducer,
  fetchSectionsState: fetchSectionsReducer,
  filterCourseMasterState: filterCourseMasterReducer,
  filterCourseState: filterCourseReducer,
  formSuccessState: formSuccessReducer,
  gradeSummaryState: gradeSummaryReducer,
  navigationState: navigationReducer,
  notificationState: notificationReducer,
  openAccordionsState: openAccordionsReducer,
  partFacultyReportState: partFacultyReportReducer,
  partOpportunityReportState: partOpportunityReportReducer,
  partTopicReportState: partTopicReportReducer,
  partGroupSummaryState: partGroupSummaryReducer,
  reportLoadingState: reportLoadingReducer,
  searchState: searchReducer,
  sectionPerformanceReportState: sectionPerformanceReportReducer,
  sectionPerformanceOpportunityReport:
    sectionPerformanceOpportunityReportReducer,
  syllabusState: syllabusReducer,
  uploadApplicantsState: uploadApplicantsReducer,
  uploadDepartmentRolesState: uploadDepartmentRolesReducer,
  uploadLegacyGradesState: uploadLegacyGradesReducer,
  uploadScoresState: uploadScoresReducer,
  uploadStudentsSectionState: uploadStudentsSectionReducer,
  checkUsersState: checkUsersReducer,
  userState: userReducer,
  authorizeState: authorizationReducer,
  descriptionState: descriptionReducer,
  cohortQuantitativeSkillsReportState: cohortQuantitativeSkillsReportReducer
});

export default rootReducer;
