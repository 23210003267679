import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const studentCalculatedPartGradeResultSelector = createSelector(
  state => state,
  (_, studentUuid, partUuid) => ({ studentUuid, partUuid }),
  (redux, data) => {
    const studentCalculatedPartGradeResultSelectorORM = ormCreateSelector(
      orm.CalculatedGradeResult
    );
    const calculatedGradeResult = studentCalculatedPartGradeResultSelectorORM(
      redux
    ).find(
      gradeResult =>
        gradeResult.studentUuid === data.studentUuid &&
        gradeResult.partUuid === data.partUuid
    );
    return calculatedGradeResult;
  }
);

export const studentCalculatedSectionGradeResultSelector = createSelector(
  state => state,
  (_, studentUuid, sectionUuid) => ({ studentUuid, sectionUuid }),
  (redux, data) => {
    const studentCalculatedSectionGradeResultSelectorORM = ormCreateSelector(
      orm.CalculatedGradeResult
    );
    const calculatedGradeResult =
      studentCalculatedSectionGradeResultSelectorORM(redux).find(
        gradeResult =>
          gradeResult.studentUuid === data.studentUuid &&
          gradeResult.sectionUuid === data.sectionUuid
      );
    return calculatedGradeResult || {};
  }
);

export const selectGradeResult = createSelector(
  state => state,
  (_, userUuid, sectionUuid) => ({ userUuid, sectionUuid }),
  (redux, data) => {
    const getCalculatedGradeResult = ormCreateSelector(
      orm.CalculatedGradeResult
    );

    const calculatedGradeResult = getCalculatedGradeResult(redux).find(
      gradeResult =>
        gradeResult.studentUuid === data.userUuid &&
        gradeResult.sectionUuid === data.sectionUuid &&
        gradeResult.partUuid === null
    );

    return calculatedGradeResult;
  }
);

export const selectGradeResultPart = createSelector(
  state => state,
  (_, userUuid, partUuid) => ({ userUuid, partUuid }),
  (redux, data) => {
    const getCalculatedGradeResult = ormCreateSelector(
      orm.CalculatedGradeResult
    );

    const calculatedGradeResult = getCalculatedGradeResult(redux).find(
      gradeResult =>
        gradeResult.studentUuid === data.userUuid &&
        gradeResult.sectionUuid === null &&
        gradeResult.partUuid === data.partUuid
    );

    return calculatedGradeResult;
  }
);
