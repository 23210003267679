import moment from 'moment';
import { termFinder } from '../../../../../helpers/utilities';

const aptitudeInitial = {
  attempt: 0,
  programUuid: undefined,
  cohortUuid: undefined,
  userUuid: undefined,
  classificationUuid: undefined,
  applicationDate: undefined,
  termType: undefined,
  term: undefined,
  year: undefined,
  date: undefined,
  itemCode: undefined,
  itemName: undefined,
  credits: undefined,
  grade: undefined,
  score: undefined,
  sourceDegreeLevelUuid: undefined,
  sourceInstitutionUuid: undefined,
  examAptitudeUuid: undefined,
  conversionAptitudeUuid: undefined,
  letterGradeUuid: undefined
};

const aptitudeReducer = (state, action) => {
  switch (action.type) {
    case 'exist': {
      const {
        attempt,
        applicationDate,
        date,
        classificationUuid,
        examAptitudeUuid,
        conversionAptitudeUuid,
        sourceInstitutionUuid,
        sourceDegreeLevelUuid
      } = { ...action.transcript };

      const appDate = moment(applicationDate, 'YYYY-MM-DD');
      const dateConvert = moment(date, 'YYYY-MM-DD');
      const dateYear = new Date(date);
      const term = termFinder(date);

      return {
        ...state,
        attempt,
        applicationDate: appDate,
        date: dateConvert,
        term,
        year: dateYear,
        classificationUuid,
        sourceInstitutionUuid,
        sourceDegreeLevelUuid,
        examAptitudeUuid,
        conversionAptitudeUuid
      };
    }
    case 'reset': {
      return {
        ...aptitudeInitial
      };
    }
    case 'applicationDate': {
      const { date } = { ...action };

      return {
        ...state,
        applicationDate: date
      };
    }
    case 'date': {
      const { date } = { ...action };

      const term = termFinder(date);

      return {
        ...state,
        date,
        term,
        year: date
      };
    }
    case 'attempt': {
      const { value } = { ...action };

      return {
        ...state,
        attempt: value
      };
    }
    case 'classificationUuid': {
      const { value } = { ...action };

      return {
        ...state,
        classificationUuid: value,
        examAptitudeUuid: undefined,
        conversionAptitudeUuid: undefined
      };
    }
    case 'sourceInstitutionUuid': {
      const { institutionCode, sourceInstitutionUuid } = { ...action };

      return {
        ...state,
        sourceInstitutionUuid,
        institutionCode
      };
    }
    case 'sourceDegreeLevelUuid': {
      const { value } = { ...action };

      return {
        ...state,
        sourceDegreeLevelUuid: value
      };
    }
    case 'credits': {
      const { value } = { ...action };

      return {
        ...state,
        credits: value
      };
    }
    // case 'grade': {
    //   const { score, grade } = { ...action };

    //   return {
    //     ...state,
    //     grade,
    //     score
    //   };
    // }
    case 'examAptitudeUuid': {
      const { value } = { ...action };

      return {
        ...state,
        examAptitudeUuid: value,
        conversionAptitudeUuid: undefined
      };
    }
    case 'conversionAptitudeUuid': {
      const { value } = { ...action };

      return {
        ...state,
        conversionAptitudeUuid: value
      };
    }
    default:
      return {
        ...state
      };
  }
};

export { aptitudeReducer, aptitudeInitial };
