import { Model, attr, fk } from 'redux-orm';

import {
  GET_TRANSCRIPT_SUCCESS,
  POST_TRANSCRIPT_SUCCESS,
  DELETE_TRANSCRIPT_SUCCESS,
  DELETE_TRANSCRIPTS_SUCCESS,
  PUT_TRANSCRIPT_SUCCESS,
  POST_CLONE_TRANSCRIPT_SUCCESS
} from '../actions-type';

class TranscriptCommunication extends Model {
  static reducer(action, TranscriptCommunication, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_TRANSCRIPT_SUCCESS: {
        const transcriptCommunication = [...payload.transcriptCommunication];
        transcriptCommunication.forEach(transcript =>
          TranscriptCommunication.upsert(transcript)
        );
        break;
      }

      case POST_CLONE_TRANSCRIPT_SUCCESS: {
        const transcriptCommunication = [...payload.transcriptCommunication];
        transcriptCommunication.forEach(transcript =>
          TranscriptCommunication.upsert(transcript)
        );
        break;
      }

      case POST_TRANSCRIPT_SUCCESS: {
        const { category, transcript } = { ...payload };

        if (category === 'Communication') {
          TranscriptCommunication.upsert(transcript);
        }
        break;
      }

      case PUT_TRANSCRIPT_SUCCESS: {
        const { category, transcript } = { ...payload };

        if (category === 'Communication') {
          TranscriptCommunication.upsert(transcript);
        }
        break;
      }

      case DELETE_TRANSCRIPT_SUCCESS: {
        const { category, uuid } = payload;

        if (category === 'Communication') {
          TranscriptCommunication.withId(uuid).delete();
        }

        break;
      }

      case DELETE_TRANSCRIPTS_SUCCESS: {
        const { category, transcripts } = payload;

        if (category === 'Communication') {
          transcripts.forEach(transcript =>
            TranscriptCommunication.withId(transcript).delete()
          );
        }

        break;
      }

      default:
        return session;
    }
  }
}

TranscriptCommunication.fields = {
  date: attr(),
  itemAge: attr(),
  uuid: attr(),
  category: attr(),
  source: attr(),
  applicationDate: attr(),
  termType: attr(),
  term: attr(),
  year: attr(),
  level: attr(),
  institutionCode: attr(),
  sourceInstitutionUuid: attr(),
  sourceDegreeLevelUuid: attr(),
  grade: attr(),
  score: attr(),
  classificationUuid: fk('Classification', 'transcript_communications'),
  programUuid: fk('Program', 'transcript_communications'),
  cohortUuid: fk('Cohort', 'transcript_communications'),
  userUuid: fk('User', 'transcript_communications'),
  examAptitudeUuid: fk('ExamAptitude', 'transcript_communications'),
  conversionAptitudeUuid: fk('ConversionAptitude', 'transcript_communications'),
  letterGradeUuid: fk('LetterGrade', 'transcript_communications'),
  createdAt: attr(),
  updatedAt: attr()
};

TranscriptCommunication.modelName = 'TranscriptCommunication';

TranscriptCommunication.options = {
  idAttribute: 'uuid'
};

export default TranscriptCommunication;
