export const SET_SELECTED_INITIAL_EDITOR_STATE =
  'SET_SELECTED_INITIAL_EDITOR_STATE';
export const SET_SELECTED_DEPARTMENT_UUID_COURSE_EDIT =
  'SET_SELECTED_DEPARTMENT_UUID_COURSE_EDIT';
export const SET_SELECTED_COURSE_MASTER_UUID_COURSE_EDIT =
  'SET_SELECTED_COURSE_MASTER_UUID_COURSE_EDIT';
export const SET_SELECTED_ACADEMIC_YEAR_UUID_COURSE_EDIT =
  '  SET_SELECTED_ACADEMIC_YEAR_UUID_COURSE_EDIT';
export const SET_SELECTED_TERM_UUID_COURSE_EDIT =
  'SET_SELECTED_TERM_UUID_COURSE_EDIT';
export const SET_IS_LOADING_COURSE_EDIT = 'SET_IS_LOADING_COURSE_EDIT';
