import {
  GET_PART_ERROR,
  GET_PART_GROUP_SUMMARY_ERROR,
  GET_PART_GROUP_SUMMARY_SUCCESS,
  GET_PART_GROUP_SUMMARY,
  GET_PART_GROUPS_ERROR,
  GET_PART_GROUPS_SUCCESS,
  GET_PART_GROUPS,
  GET_PART_SUCCESS,
  GET_PART_USER_GROUPS_ERROR,
  GET_PART_USER_GROUPS_SUCCESS,
  GET_PART_USER_GROUPS,
  GET_PART,
  PUT_GROUP_RANKINGS_ERROR,
  PUT_GROUP_RANKINGS_SUCCESS,
  PUT_GROUP_RANKINGS,
  PUT_STUDENT_GROUP_RANKINGS_SUCCESS,
  PUT_STUDENT_GROUP_RANKINGS_ERROR,
  PUT_STUDENT_GROUP_RANKINGS,
  GET_STUDENT_PART_USER_GROUPS,
  GET_STUDENT_PART_USER_GROUPS_SUCCESS,
  GET_STUDENT_PART_USER_GROUPS_ERROR,
  GET_STUDENT_PART_GROUP_SUMMARY,
  GET_STUDENT_PART_GROUP_SUMMARY_SUCCESS,
  GET_STUDENT_PART_GROUP_SUMMARY_ERROR
} from '../actions-type';

export const INITIAL_STATE = {
  loadingPart: false,
  loadingPartGroupRankings: false,
  loadingPartGroups: false,
  loadingPartGroupSummary: false,
  loadingPartUserGroups: false
};

const reportLoadingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PART: {
      return {
        ...state,
        loadingPart: true
      };
    }
    case GET_PART_SUCCESS: {
      return {
        ...state,
        loadingPart: false
      };
    }
    case GET_PART_ERROR: {
      return {
        ...state,
        loadingPart: false
      };
    }
    case GET_PART_GROUPS: {
      return {
        ...state,
        loadingPartGroups: true
      };
    }
    case GET_PART_GROUPS_SUCCESS: {
      return {
        ...state,
        loadingPartGroups: false
      };
    }
    case GET_PART_GROUPS_ERROR: {
      return {
        ...state,
        loadingPartGroups: false
      };
    }
    case GET_STUDENT_PART_USER_GROUPS:
    case GET_PART_USER_GROUPS: {
      return {
        ...state,
        loadingPartUserGroups: true
      };
    }
    case GET_STUDENT_PART_USER_GROUPS_SUCCESS:
    case GET_PART_USER_GROUPS_SUCCESS: {
      return {
        ...state,
        loadingPartUserGroups: false
      };
    }
    case GET_STUDENT_PART_USER_GROUPS_ERROR:
    case GET_PART_USER_GROUPS_ERROR: {
      return {
        ...state,
        loadingPartUserGroups: false
      };
    }
    case GET_STUDENT_PART_GROUP_SUMMARY:
    case GET_PART_GROUP_SUMMARY: {
      return {
        ...state,
        loadingPartGroupSummary: true
      };
    }
    case GET_STUDENT_PART_GROUP_SUMMARY_SUCCESS:
    case GET_PART_GROUP_SUMMARY_SUCCESS: {
      return {
        ...state,
        loadingPartGroupSummary: false
      };
    }
    case GET_STUDENT_PART_GROUP_SUMMARY_ERROR:
    case GET_PART_GROUP_SUMMARY_ERROR: {
      return {
        ...state,
        loadingPartGroupSummary: false
      };
    }
    case PUT_STUDENT_GROUP_RANKINGS:
    case PUT_GROUP_RANKINGS: {
      return {
        ...state,
        loadingPartGroupRankings: true
      };
    }
    case PUT_STUDENT_GROUP_RANKINGS_SUCCESS:
    case PUT_GROUP_RANKINGS_SUCCESS: {
      return {
        ...state,
        loadingPartGroupRankings: false
      };
    }
    case PUT_STUDENT_GROUP_RANKINGS_ERROR:
    case PUT_GROUP_RANKINGS_ERROR: {
      return {
        ...state,
        loadingPartGroupRankings: false
      };
    }
    default:
      return state;
  }
};

export default reportLoadingReducer;
