import fetch from '../helpers/fetch';

const getCalculatedPartGradeResultsBySection = sectionUuid =>
  fetch(
    `/api/calculated-grade-result/part-grades/by-section?sectionUuid=${sectionUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(err => {
      throw err;
    });

export { getCalculatedPartGradeResultsBySection };
