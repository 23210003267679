import {
  GET_PART_GROUP_SUMMARY,
  GET_PART_GROUP_SUMMARY_SUCCESS,
  GET_PART_GROUP_SUMMARY_ERROR,
  RESET_PART_GROUP_SUMMARY,
  GET_STUDENT_PART_GROUP_SUMMARY,
  GET_STUDENT_PART_GROUP_SUMMARY_SUCCESS,
  GET_STUDENT_PART_GROUP_SUMMARY_ERROR
} from '../actions-type';

const doGetPartGroupSummary = partUuid => ({
  type: GET_PART_GROUP_SUMMARY,
  payload: {
    partUuid
  }
});

const doGetPartGroupSummarySuccess = result => ({
  type: GET_PART_GROUP_SUMMARY_SUCCESS,
  payload: result
});

const doGetPartGroupSummaryError = error => ({
  type: GET_PART_GROUP_SUMMARY_ERROR,
  payload: error
});

const doGetStudentPartGroupSummary = (partUuid, studentUuid) => ({
  type: GET_STUDENT_PART_GROUP_SUMMARY,
  payload: {
    partUuid,
    studentUuid
  }
});

const doGetStudentPartGroupSummarySuccess = result => ({
  type: GET_STUDENT_PART_GROUP_SUMMARY_SUCCESS,
  payload: result
});

const doGetStudentPartGroupSummaryError = error => ({
  type: GET_STUDENT_PART_GROUP_SUMMARY_ERROR,
  payload: error
});

const doResetPartGroupSummary = () => ({
  type: RESET_PART_GROUP_SUMMARY
});

export {
  doGetPartGroupSummary,
  doGetPartGroupSummarySuccess,
  doGetPartGroupSummaryError,
  doGetStudentPartGroupSummary,
  doGetStudentPartGroupSummarySuccess,
  doGetStudentPartGroupSummaryError,
  doResetPartGroupSummary
};
