import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccordionRowContainer from '../../../../Library/AccordionRow/AccordionRowContainer';

import ByTermCognitiveTermContainer from './ByTermCognitiveTermContainer';

const Accordion = styled(({ ...rest }) => <AccordionRowContainer {...rest} />)`
  .accordion_row_label {
    flex-grow: 2;
  }

  && {
    .accordion_row_label div {
      font-size: 14px;
      letter-spacing: 0.15;
      color: ${props => props.theme.fontColors.darker};
    }
  }

  width: 100%;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.grey[500]};
  font-size: 14px;
`;

const AccordionArea = styled.div`
  width: 100%;
  scroll: auto;
  font-size: 12px;
`;

export default function ByTermCognitiveSource({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source
}) {
  return (
    <>
      <Accordion
        expandWidth="66px"
        height="70px"
        label={
          <Group>
            <Label>{`${source}`}</Label>
          </Group>
        }
      >
        <AccordionArea>
          <ByTermCognitiveTermContainer
            userUuid={userUuid}
            cohortUuid={cohortUuid}
            programUuid={programUuid}
            category={category}
            source={source}
          />
        </AccordionArea>
      </Accordion>
    </>
  );
}

ByTermCognitiveSource.propTypes = {
  category: PropTypes.string,
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  source: PropTypes.string
};

ByTermCognitiveSource.defaultProps = {
  category: undefined,
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  source: undefined
};
