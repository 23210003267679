import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import _ from 'lodash';
import EducationLevelEdit from './EducationLevelEdit';
import EducationLevelDeleteModal from './EducationLevelDeleteModal';

import {
  validateEducationLevelTextField,
  validateLoverbsEdit
} from '../../../../../helpers/validation/validateEducationLevelLoverb';

import { educationLevelSelectorByMatchParams } from '../../../../../redux/selectors/educationLevelsSelectors';
import { loverbsSelectorBySchool } from '../../../../../redux/selectors/loverbSelectors';

import {
  doGetEducationLevel,
  doPutEducationLevel,
  doDeleteEducationLevel
} from '../../../../../redux/actions/educationLevelActions';

import { doGetLoverbs } from '../../../../../redux/actions/loverbActions';

class EducationLevelEditContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formInput: {
        level: 0,
        education: '',
        loverbs: [],
        loverbsRemoval: []
      },
      hasErrors: false,
      submit: false,
      modalOpen: false
    };

    this.handleFormInputChange = this.handleFormInputChange.bind(this);
    this.handleAddLoverb = this.handleAddLoverb.bind(this);
    this.handleLoverbChange = this.handleLoverbChange.bind(this);
    this.handleRemoveLoverb = this.handleRemoveLoverb.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteModalOpen = this.handleDeleteModalOpen.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this._validationCheck = this._validationCheck.bind(this);
    this._setFormState = this._setFormState.bind(this);
  }

  componentDidMount() {
    const { match, schoolUuid, onGetLoverbs, onGetEducationLevel } = this.props;
    const { educationLevelUuid } = match.params;

    onGetEducationLevel(educationLevelUuid);

    if (schoolUuid) {
      onGetLoverbs(schoolUuid);
    }

    if (this.props.educationLevel) {
      this._setFormState();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.educationLevel !== prevProps.educationLevel) {
      this._setFormState();
    }
  }

  handleFormInputChange(event) {
    const { name, value } = event.target;

    this.setState(prevState => ({
      formInput: {
        ...prevState.formInput,
        [name]: value
      }
    }));
  }

  handleAddLoverb() {
    this.setState(prevState => {
      const addLoverb = {
        indentifier: uuidv4().toString(),
        period: '',
        level: 0,
        education: '',
        objectiveVerb: '',
        educationLevelUuid: this.props.educationLevel.uuid
      };

      const mergeState = {
        formInput: {
          ...prevState.formInput,
          loverbs: [...prevState.formInput.loverbs, addLoverb]
        }
      };

      return mergeState;
    });
  }

  handleLoverbChange(indentifier, event) {
    const { name, value } = event.target;
    let update;

    if (name === 'objectiveVerb') {
      update = {
        objectiveVerb: value
      };
    }

    this.setState(prevState => {
      const currentState = prevState.formInput.loverbs;

      const newState = currentState.map(loverb => {
        if (loverb.indentifier === indentifier) {
          const clone = _.cloneDeep(loverb);
          const merge = _.merge(clone, update);

          return merge;
        } else {
          return loverb;
        }
      });

      const mergeState = {
        formInput: {
          ...prevState.formInput,
          loverbs: newState
        }
      };

      return mergeState;
    });
  }

  handleRemoveLoverb(indentifier) {
    this.setState(prevState => {
      const currentState = prevState.formInput.loverbs;

      const newState = _.filter(
        currentState,
        record => record.indentifier !== indentifier
      );

      const removal = _.filter(
        currentState,
        record => record.indentifier === indentifier
      )[0];

      let removeLoverb = [];

      if (removal.uuid) {
        removeLoverb = removal;
      }

      const mergeState = {
        formInput: {
          ...prevState.formInput,
          loverbs: newState,
          loverbsRemoval: [...prevState.formInput.loverbsRemoval, removeLoverb]
        }
      };

      return mergeState;
    });
  }

  handleSubmit() {
    const { schoolUuid } = this.props;
    const formIsValid = this._validationCheck();

    if (formIsValid) {
      this.setState({
        submit: true,
        hasErrors: false
      });

      const payload = { ...this.state.formInput, schoolUuid };

      this.props.onPutEducationLevel(payload);
    } else {
      this.setState({
        submit: false,
        hasErrors: true
      });
    }
  }

  handleDeleteModalOpen() {
    this.setState({
      modalOpen: true
    });
  }

  handleDeleteModalClose() {
    this.setState({
      modalOpen: false
    });
  }

  handleDelete() {
    this.props.onDeleteEducationLevel(this.state.formInput.uuid);

    this.setState({
      modalOpen: false
    });
  }

  _setFormState() {
    this.setState(prevState => {
      const { educationLevel } = this.props;

      let newLoverbs = [];
      if (educationLevel.loverbs) {
        newLoverbs = educationLevel.loverbs.map(loverb => {
          return { ...loverb, indentifier: uuidv4().toString() };
        });
      }

      const merge = {
        ...educationLevel,
        loverbs: newLoverbs,
        loverbsRemoval: []
      };

      return {
        ...prevState,
        formInput: merge
      };
    });
  }

  _validationCheck() {
    const { level, education, loverbs } = this.state.formInput;
    const { loverbsRedux } = this.props;

    const levelError = validateEducationLevelTextField(level);
    const educationError = validateEducationLevelTextField(education);
    const loverbsObjectiveVerbsError = validateLoverbsEdit(
      loverbs,
      loverbsRedux
    );

    const validations = {
      levelInvalid: levelError,
      educationInvalid: educationError,
      loverbsInvalid: loverbsObjectiveVerbsError
    };

    const isValid = !_.some(validations, { invalid: true });

    return isValid;
  }

  render() {
    const { educationLevel, loverbsRedux } = this.props;
    const { level, education, loverbs } = this.state.formInput;
    const { hasErrors, submit, modalOpen } = this.state;

    return (
      <div>
        <EducationLevelEdit
          level={level}
          education={education}
          loverbs={loverbs}
          loverbsRedux={loverbsRedux}
          educationLevel={educationLevel}
          hasErrors={hasErrors}
          submit={submit}
          handleFormInputChange={this.handleFormInputChange}
          handleAddLoverb={this.handleAddLoverb}
          handleLoverbChange={(indentifier, event) =>
            this.handleLoverbChange(indentifier, event)
          }
          handleRemoveLoverb={this.handleRemoveLoverb}
          handleSubmit={this.handleSubmit}
          handleDeleteModalOpen={this.handleDeleteModalOpen}
        />
        <EducationLevelDeleteModal
          modalOpen={modalOpen}
          handleDeleteModalClose={() => this.handleDeleteModalClose()}
          handleDelete={() => this.handleDelete()}
        />
      </div>
    );
  }
}

EducationLevelEditContainer.propTypes = {
  match: PropTypes.object,
  educationLevel: PropTypes.object,
  schoolUuid: PropTypes.string,
  loverbsRedux: PropTypes.array,
  onPutEducationLevel: PropTypes.func,
  onGetEducationLevel: PropTypes.func,
  onDeleteEducationLevel: PropTypes.func,
  onGetLoverbs: PropTypes.func
};
EducationLevelEditContainer.defaultProps = {
  match: {},
  educationLevel: {},
  schoolUuid: '',
  loverbsRedux: [],
  onPutEducationLevel: undefined,
  onGetEducationLevel: undefined,
  onDeleteEducationLevel: undefined,
  onGetLoverbs: undefined
};

const mapStateToProps = (state, props) => {
  return {
    schoolUuid: state.userState.selectedSchoolUuid,
    educationLevel: educationLevelSelectorByMatchParams(state, props),
    loverbsRedux: loverbsSelectorBySchool(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onPutEducationLevel: payload => dispatch(doPutEducationLevel(payload)),
  onGetEducationLevel: educationLevelUuid =>
    dispatch(doGetEducationLevel(educationLevelUuid)),
  onDeleteEducationLevel: educationLevelUuid =>
    dispatch(doDeleteEducationLevel(educationLevelUuid)),
  onGetLoverbs: schoolUuid => dispatch(doGetLoverbs(schoolUuid))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EducationLevelEditContainer);
