import { Model, attr, fk } from 'redux-orm';

import { GET_SYLLABUS_COURSE_SUCCESS } from '../actions-type';

class SectionDirector extends Model {
  static reducer(action, SectionDirector, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_SYLLABUS_COURSE_SUCCESS: {
        const course = { ...payload.course };
        const { sections } = course;

        if (sections?.length > 0) {
          sections.forEach(section => {
            const { section_directors_assigned } = section;

            if (section_directors_assigned?.length > 0) {
              section_directors_assigned.forEach(director => {
                const { section_director } = director;
                SectionDirector.upsert(section_director);
              });
            }
          });
        }

        break;
      }
      default:
        return session;
    }
  }
}

SectionDirector.fields = {
  uuid: attr(),
  userUuid: fk({
    to: 'User',
    as: 'user',
    relatedName: 'section_director'
  }),
  sectionUuid: fk({
    to: 'Section',
    as: 'section',
    relatedName: 'section_director'
  })
};

SectionDirector.options = {
  idAttribute: 'uuid'
};

SectionDirector.modelName = 'SectionDirector';

export default SectionDirector;
