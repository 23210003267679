import { useReducer } from 'react';
import _ from 'lodash';

const initialSavedChangesState = {
  unsavedChanges: {},
  isUnsavedChangesModalOpen: false
};

const unsavedChangesReducer = (state, action) => {
  switch (action.type) {
    case 'setUnsavedChange': {
      const { uuid } = action.payload;
      const newUnsavedChangesObj = _.cloneDeep(state.unsavedChanges);

      if (uuid) newUnsavedChangesObj[uuid] = true;

      return {
        ...state,
        unsavedChanges: newUnsavedChangesObj
      };
    }
    case 'removeUnsavedChange': {
      const { uuid } = action.payload;
      const newUnsavedChangesObj = _.cloneDeep(state.unsavedChanges);

      if (uuid && newUnsavedChangesObj[uuid]) delete newUnsavedChangesObj[uuid];

      return {
        ...state,
        unsavedChanges: newUnsavedChangesObj
      };
    }
    case 'handleModalOpen':
      return {
        ...state,
        isUnsavedChangesModalOpen: true
      };
    case 'handleModalClose':
      return {
        ...state,
        isUnsavedChangesModalOpen: false
      };
    case 'clearUnsavedChanges':
      return initialSavedChangesState;
    default:
      throw new Error();
  }
};

export function useUnsavedChanges(onProceed = undefined) {
  const [state, dispatch] = useReducer(
    unsavedChangesReducer,
    initialSavedChangesState
  );

  const { isUnsavedChangesModalOpen, unsavedChanges } = state;

  const handleModalOpen = () => {
    if (Object.keys(unsavedChanges).length < 1) {
      onProceed();
    } else {
      dispatch({
        type: 'handleModalOpen'
      });
    }
  };

  const handleModalClose = () => {
    dispatch({
      type: 'handleModalClose'
    });
  };

  const setUnsavedChange = uuid => {
    dispatch({
      type: 'setUnsavedChange',
      payload: {
        uuid
      }
    });
  };

  const removeUnsavedChange = uuid => {
    dispatch({
      type: 'removeUnsavedChange',
      payload: {
        uuid
      }
    });
  };

  const handleProceed = () => {
    if (onProceed) onProceed();
    handleModalClose();
  };

  return [
    isUnsavedChangesModalOpen,
    unsavedChanges,
    handleModalOpen,
    handleModalClose,
    setUnsavedChange,
    removeUnsavedChange,
    handleProceed
  ];
}
