import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { assessmentSelector } from '../../../redux/selectors/assessmentSelectors';

import SyllabusAssessment from './SyllabusAssessment';

export default function SyllabusAssessmentContainer({
  sectionUuid,
  partUuid,
  assessmentUuid,
  itemNumber
}) {
  const assessment = useSelector(
    state => assessmentSelector(state, assessmentUuid),
    _.isEqual
  );

  const {
    grading_technique,
    assessment_manager,
    responsible_faculty,
    assessment_type
  } = assessment;

  return (
    <SyllabusAssessment
      itemNumber={itemNumber}
      sectionUuid={sectionUuid}
      partUuid={partUuid}
      assessment={assessment}
      gradingTechnique={grading_technique}
      assessmentManager={`${assessment_manager?.firstName} ${assessment_manager?.lastName}`}
      responsibleFaculty={`${responsible_faculty?.firstName} ${responsible_faculty?.lastName}`}
      assessmentType={assessment_type?.name}
    />
  );
}

SyllabusAssessmentContainer.propTypes = {
  assessmentUuid: PropTypes.string,
  sectionUuid: PropTypes.string,
  partUuid: PropTypes.string,
  itemNumber: PropTypes.number.isRequired
};

SyllabusAssessmentContainer.defaultProps = {
  assessmentUuid: '',
  sectionUuid: '',
  partUuid: ''
};
