import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ByDefaultSource from './ByDefaultSource';
import { doDeleteTranscripts } from '../../../../../redux/actions/applicantTranscriptActions';

export default function ByDefaultSourceContainer({
  userUuid,
  cohortUuid,
  programUuid,
  category,
  source,
  isAdmin
}) {
  const dispatch = useDispatch();
  const [openUpload, setOpenUpload] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const handleUploadOpen = () => {
    setOpenUpload(true);
  };

  const handleUploadClose = () => {
    setOpenUpload(false);
  };

  const handleAddOpen = () => {
    setOpenAdd(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
    dispatch({ type: 'POST_TRANSCRIPT_RESET' });
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleDelete = () => {
    dispatch(
      doDeleteTranscripts(category, source, userUuid, cohortUuid, programUuid)
    );
    setOpenDelete(false);
  };

  return (
    <ByDefaultSource
      programUuid={programUuid}
      userUuid={userUuid}
      cohortUuid={cohortUuid}
      category={category}
      source={source}
      openUpload={openUpload}
      openAdd={openAdd}
      openDelete={openDelete}
      handleUploadOpen={handleUploadOpen}
      handleUploadClose={handleUploadClose}
      handleAddOpen={handleAddOpen}
      handleAddClose={handleAddClose}
      handleDeleteOpen={handleDeleteOpen}
      handleDeleteClose={handleDeleteClose}
      handleDelete={handleDelete}
      isAdmin={isAdmin}
    />
  );
}

ByDefaultSourceContainer.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  programUuid: PropTypes.string,
  category: PropTypes.string,
  source: PropTypes.string,
  isAdmin: PropTypes.bool,
};

ByDefaultSourceContainer.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  programUuid: undefined,
  category: undefined,
  source: undefined,
  isAdmin: false,
};
