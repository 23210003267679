import { Model, attr } from 'redux-orm';
import {
  GET_USER_ACTIVE_SUCCESS,
  PUT_INSTITUTION_SUCCESS,
  GET_HIERARCHY_SUCCESS,
  GET_USER_SUCCESS,
  GET_USERS_BY_SEARCH_ROLES_SUCCESS
} from '../actions-type';

class Institution extends Model {
  static reducer(action, Institution, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_USER_ACTIVE_SUCCESS: {
        if (payload?.institution) {
          Institution.upsert(payload.institution);
        }
        break;
      }
      case PUT_INSTITUTION_SUCCESS: {
        const institution = { ...payload.institution };
        Institution.upsert(institution);
        break;
      }
      case GET_HIERARCHY_SUCCESS: {
        const institutions = [...payload.institutions];
        institutions.forEach(institution => Institution.upsert(institution));
        break;
      }
      case GET_USER_SUCCESS: {
        const institutions = [...payload.institutions];
        institutions.forEach(institution => Institution.upsert(institution));
        break;
      }
      case GET_USERS_BY_SEARCH_ROLES_SUCCESS: {
        if (payload?.institutions?.length > 0) {
          const institutions = [...payload.institutions];
          institutions.forEach(institution => Institution.upsert(institution));
        }
        break;
      }
      default:
        return session;
    }
  }
}

Institution.fields = {
  uuid: attr(),
  institutionName: attr(),
  primaryColor: attr(),
  secondaryColor: attr(),
  logoUuid: attr(),
  logo: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

Institution.modelName = 'Institution';

Institution.options = {
  idAttribute: 'uuid'
};

export default Institution;
