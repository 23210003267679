import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Caption } from '@xcomp/xcomp-design-library';
import Overline from '../../../Library/Overline';

export const CourseIndex = styled.div`
  display: flex;
  padding: 8px;
`;

export const CourseIndexValue = styled(Caption)`
  margin-top: 0.25rem;
  margin-bottom: 0;
  color: ${props => props.theme.fontColors.darker};
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 2.5rem;
  font-size: 0.9875rem;
  color: ${props => props.theme.fontColors.dark};

  svg {
    margin-right: 0.25rem;
    font-size: 1.25rem;
    vertical-align: top;
    color: ${props => props.theme.fontColors.dark};
  }
`;

export const OverlineSC = styled(Overline)`
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
`;

const CohortSyllabusInfo = ({ parentCohort }) => (
  <CourseIndex>
    <InfoBlock>
      <div>
        <OverlineSC>Cohort Start Date</OverlineSC>
        <CourseIndexValue>{parentCohort?.startDate}</CourseIndexValue>
      </div>
    </InfoBlock>
    <InfoBlock>
      <div>
        <OverlineSC>Cohort End Date</OverlineSC>
        <CourseIndexValue>{parentCohort?.endDate}</CourseIndexValue>
      </div>
    </InfoBlock>
  </CourseIndex>
);

CohortSyllabusInfo.propTypes = {
  parentCohort: PropTypes.object
};

CohortSyllabusInfo.defaultProps = {
  parentCohort: {}
};

export default CohortSyllabusInfo;
