import React, { useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PartStatusSettings from './PartStatusSettings';
import { partSelectorByUuid } from '../../../../../redux/selectors/partsSelectors';
import { doGeneralErrorNotification } from '../../../../../redux/actions/notificationActions';
import {
  doGetPart,
  doPatchPartStatus
} from '../../../../../redux/actions/partActions';
import { partStatusReducer, initialPartStatusState } from './partStatusState';

export default function PartStatusSettingsContainer({ match }) {
  const dispatch = useDispatch();
  const { partUuid } = match.params;

  const [state, partDispatch] = useReducer(
    partStatusReducer,
    initialPartStatusState
  );

  const part = useSelector(
    state => partSelectorByUuid(state, partUuid),
    _.isEqual
  );

  const onGeneralErrorNotification = errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage));

  const handleStatusApprove = () => {
    const items = [...part.encounters, ...part.assessments];
    const completionCheck = _.every(items, ['status', 'Approved']);

    if (completionCheck) {
      const payload = {
        partUuid: part.uuid,
        changeStatus: 'Committed'
      };

      dispatch(doPatchPartStatus(payload));
    } else {
      onGeneralErrorNotification(
        'This part cannot be approved until all the encounter and assessments have been approved'
      );
    }
  };

  const handleStatusUnapprove = () => {
    const payload = {
      partUuid: part.uuid,
      changeStatus: 'In Progress'
    };

    dispatch(doPatchPartStatus(payload));
  };

  const handleStatusPublish = () => {
    const items = [...part.encounters, ...part.assessments];
    const completionCheck = _.every(items, ['status', 'Approved']);

    if (completionCheck && part.status === 'Committed') {
      const payload = {
        partUuid: part.uuid,
        changeStatus: 'Published'
      };

      dispatch(doPatchPartStatus(payload));
    } else {
      onGeneralErrorNotification(
        'This part cannot be published until the entire part has been approved'
      );
    }
  };

  const handleStatusUnpublish = () => {
    const payload = {
      partUuid: part.uuid,
      changeStatus: 'In Progress'
    };

    dispatch(doPatchPartStatus(payload));
  };

  const setExistingState = ({ uuid, statusCommit, status }) => {
    partDispatch({
      type: 'setExistingPart',
      payload: {
        uuid,
        statusCommit,
        status
      }
    });
  };

  const section = _.get(part, 'section', null);
  const course = _.get(section, 'course', null);

  useEffect(() => {
    dispatch(doGetPart(partUuid));
  }, [dispatch, partUuid]);

  useEffect(() => {
    if (part && part.uuid) {
      setExistingState({
        uuid: part.uuid,
        statusCommit: 'Uncommitted',
        status: part.status
      });
    }
  }, [part]);

  const handleCommit = () => {
    partDispatch({
      type: 'setStatusCommitted'
    });
  };

  const handleUncommit = () => {
    partDispatch({ type: 'setStatusUncommitted' });
  };

  const { status, statusCommit } = state;

  const courseNumber = _.get(course, 'course_master.courseNumber', '');

  const courseTitle = _.get(course, 'course_master.title', '');

  const sectionNumber = _.get(section, 'sectionNumber', '')
    .toString()
    .padStart(2, 0);

  const partNumber = _.get(part, 'partNumber', '').toString().padStart(2, 0);

  return (
    <PartStatusSettings
      part={part}
      partTitle={part?.title}
      courseNumber={courseNumber}
      courseTitle={courseTitle}
      sectionNumber={sectionNumber}
      partNumber={partNumber}
      status={status}
      statusCommit={statusCommit}
      handleCommit={handleCommit}
      handleUncommit={handleUncommit}
      handleStatusApprove={handleStatusApprove}
      handleStatusUnapprove={handleStatusUnapprove}
      handleStatusPublish={handleStatusPublish}
      handleStatusUnpublish={handleStatusUnpublish}
    />
  );
}

PartStatusSettingsContainer.propTypes = {
  match: PropTypes.object
};

PartStatusSettingsContainer.defaultProps = {
  match: {}
};
