import React from 'react';
import PropTypes from 'prop-types';
import CompetencyRequirements from './CompetencyRequirements'
import QuantitativeRequirements from './QuantitativeManagement/QuantitativeCollections';

export default function CompetencyRequirementsContainer({isQuantitative}) {

  if (isQuantitative) return <QuantitativeRequirements />;

  return <CompetencyRequirements />;
}

CompetencyRequirementsContainer.propTypes = {
  isQuantitative: PropTypes.bool,
};

CompetencyRequirementsContainer.defaultProps = {
  isQuantitative: false,
};