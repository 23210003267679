import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useCompletionPercentage } from "../../../../../helpers/hooks/useCompletionPercentage";

const ChipSC = styled(Chip)`
  && {
    margin-left: 10px;
    margin-right: 5px;
  }
`;

export default function CompletedPercentage({ completed, remaining,remainingPercentage  }) {
	const percentage = useCompletionPercentage(completed, remaining, remainingPercentage)

	return (<ChipSC color="primary" size="small" label={`${percentage}%`} />);
}

CompletedPercentage.propTypes = {
	completed: PropTypes.array,
	remaining: PropTypes.array,
	remainingPercentage: PropTypes.bool
};

CompletedPercentage.defaultProps = {
	completed: undefined,
	remaining: undefined,
	remainingPercentage: false,
};
