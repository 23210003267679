import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { tintColor } from '@xcomp/xcomp-design-library/dist/helpers';

import { StickyTitleTd } from '../CollectionReportSC';

const TableHead = styled.thead`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
`;

const TableRow = styled.tr`
  display: flex;
  justify-content: flex-start;
  inline-size: min-content;
  td:last-child {
    border-right: none;
  }
`;

const StickyInfoTd = styled.td`
  min-width: 250px;
  max-width: 250px;
  z-index: 10;
  position: sticky;
  left: ${props => props.left};
  background-color: ${props => (props.greyOne && props.theme.colors.grey[100]) || 'white'};
  border-top: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
  border-right: ${props =>
    props.borderRightStrong
      ? `solid 2px ${props.theme.fontColors.darker}`
      : `solid 1px ${props.theme.colors.grey[300]}`};
`;

const TitleTd = styled.td`
  display: ${props => (props.displayNone ? 'none' : 'flex')};
  align-items: flex-end;
  justify-content: center;
  min-width: 75px;
  max-width: 75px;
  z-index: 5;
  background-color: ${props =>
    (props.redOne && tintColor(props.theme.colors.red[100], 0.5)) ||
    (props.redTwo && tintColor(props.theme.colors.red[100], 0.4)) ||
    (props.blueOne && tintColor(props.theme.colors.blue[500], 0.8)) ||
    'white'};
  ${props =>
    props.borderLeft && `border-left: solid 1px ${props.theme.colors.grey[300]}`};
  ${props =>
    props.borderRight &&
    `border-right: solid 1px ${props.theme.colors.grey[300]}`};
  border-top: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
  color: ${props =>
    props.secondaryFontColor
      ? props.theme.fontColors.dark
      : props.theme.fontColors.darker};
`;

const RotatedInfoTitle = styled.div`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 16px 0px 32px 0px;
  white-space: nowrap;
`;

const StudentInfoText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 53px;
  color: ${props => props.theme.fontColors.darker};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const FillerTd = styled.td`
  min-width: ${props => `${props.width}px`};
  max-width: ${props => `${props.width}px`};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-top: ${props =>
    props.borderTop ? `1px solid ${props.theme.colors.grey[300]}` : 'none'};
  border-bottom: ${props =>
    props.borderBottom ? `1px solid ${props.theme.colors.grey[300]}` : 'none'};
  background-color: ${props =>
    (props.greyOne && props.theme.colors.grey[100]) ||
    (props.greyTwo && props.theme.colors.grey[200]) ||
    'white'};
`;

const ReportTableHead = ({
  assessmentCollections,
  isExpanded,
  useFillerColumn,
  fillerColumnWidth,
  isDelayed
}) => {
  return (
    <TableHead>
      <TableRow>
        <StickyTitleTd redOne left="0px" key="part-score">
          <RotatedInfoTitle>Part Score</RotatedInfoTitle>
        </StickyTitleTd>
        <StickyTitleTd redOne left="75px" key="part-grade">
          <RotatedInfoTitle>Part Grade</RotatedInfoTitle>
        </StickyTitleTd>
        <StickyTitleTd redOne left="150px" borderRight key="part-rank">
          <RotatedInfoTitle>
            {isDelayed ? 'Actions' : 'ClassRank'}
          </RotatedInfoTitle>
        </StickyTitleTd>
        <StickyInfoTd left="225px" borderRightStrong key="part-info-text">
          <StudentInfoText />
        </StickyInfoTd>
        {assessmentCollections.length > 0 &&
          assessmentCollections.map(assessmentCollection => (
            <Fragment key={assessmentCollection.uuid}>
              <TitleTd blueOne>
                <RotatedInfoTitle>
                  {assessmentCollection.title}
                </RotatedInfoTitle>
              </TitleTd>
              <TitleTd
                blueOne
                displayNone={
                  assessmentCollection.scoreType === 'SCORE' || !isExpanded
                }
              >
                <RotatedInfoTitle>Cumulative Points</RotatedInfoTitle>
              </TitleTd>
              {assessmentCollection.collection_assessments.length > 0 &&
                assessmentCollection.collection_assessments.map(
                  collectionAssessment => {
                    const someUuid = uuidv4();
                    return (
                      <TitleTd
                        key={someUuid}
                        displayNone={!isExpanded}
                        secondaryFontColor
                      >
                        <RotatedInfoTitle>
                          {collectionAssessment?.assessment?.title || '--'}
                        </RotatedInfoTitle>
                      </TitleTd>
                    );
                  }
                )}
            </Fragment>
          ))}

        {useFillerColumn && (
          <FillerTd width={fillerColumnWidth} borderTop kye="filler" />
        )}
      </TableRow>
    </TableHead>
  );
};

ReportTableHead.propTypes = {
  assessmentCollections: PropTypes.array,
  isExpanded: PropTypes.bool,
  isDelayed: PropTypes.bool,
  useFillerColumn: PropTypes.bool,
  fillerColumnWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

ReportTableHead.defaultProps = {
  assessmentCollections: [],
  isExpanded: false,
  isDelayed: false,
  useFillerColumn: false
};

export default ReportTableHead;
