import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { CloseIcon } from '@xcomp/xcomp-design-library/dist/icons';

import SimpleModal from '../Library/Modal/SimpleModal';
import { getColor } from './Logs';

const Header = styled.div`
  background: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 10px 15px;
  font-size: 22px;
  font-weight: 500;
  color: ${props => props.theme.colors.white};
`

const ModalBody = styled.div`
  width: 100%;
  padding: 0 1.25rem 1rem 1.25rem;
`;

const SectionTitle = styled.div`
  font-size: 18px;
  border-bottom: 1px solid;
  margin-bottom: 5px;
  margin-top: 15px;
  font-weight: 500;
`

const TwoColumns = styled.div`
  display: flex;
  margin-bottom: 5px;
`

const Chip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 3px;
  font-size: 14px;
  padding: 0px 10px;
  height: 30px;
  width: ${props => props.$width}px;
  min-width: ${props => props.$width}px;
  color: ${props => props.theme.colors.white};
  background: ${({$methodLabel, theme}) => getColor($methodLabel, theme)};
`

const InfoModal = ({handleModalClose, openModal}) => {

  return (
    <SimpleModal open={openModal} onClose={handleModalClose} fullWidth={true} maxWidth='xl'>
      <div>
        <Header>
          <div>Definitions</div>
          <IconButton onClick={handleModalClose} style={{color: 'white'}}>
            <CloseIcon />
          </IconButton>
        </Header>
        <ModalBody>
          <div>
            <div>
              <SectionTitle>Methods Details</SectionTitle>
              <div>These are the HTTP request methods used when an action is triggered in the client. Below you can find an explation to what each method does:</div>
              <TwoColumns>
                <Chip $methodLabel='GET' $width={100}>GET</Chip>
                <div>- is used to retrieve data from the server</div>
              </TwoColumns>
              <TwoColumns>
                <Chip $methodLabel='POST' $width={100}>POST</Chip>
                <div>- is normally used to send data to the server for creation </div>
              </TwoColumns>
              <TwoColumns>
                <Chip $methodLabel='PUT' $width={100}>PUT</Chip>
                <div>- is used to send data for to the server for update, or, in case the entity does not exist, for creation</div>
              </TwoColumns>
              <TwoColumns>
                <Chip $methodLabel='PATCH' $width={100}>PATCH</Chip>
                <div>- is used to send data for to the server for update</div>
              </TwoColumns>
              <TwoColumns>
                <Chip $methodLabel='DELETE' $width={100}>DELETE</Chip>
                <div>- is used to delete a set of entities</div>
              </TwoColumns>

              <SectionTitle>Actions</SectionTitle>
              <div>Below you can find the list of actions that are currently logged:</div>
              <TwoColumns>
                <Chip $methodLabel='deleteAllStudentScores' $width={200}>deleteAllscores</Chip>
                <div>- triggered when a user deletes all the scores for an assessment from the IPM scores page </div>
              </TwoColumns>
              <TwoColumns>
                <Chip $methodLabel='deleteStudentScores' $width={200}>deleteStudentScores</Chip>
                <div>- triggered when a user deletes the scores for a student (all their score or all the block scores or the attempt scores) from the IPM scores page </div>
              </TwoColumns>
              <TwoColumns>
                <Chip $methodLabel='updateAttemptStatus' $width={200}>updateAttemptStatus</Chip>
                <div>- triggered when a user updates the status of an attempt from the IPM scores page </div>
              </TwoColumns>
              <TwoColumns>
                <Chip $methodLabel='updateIndividualScore' $width={200}>updateIndividualScore</Chip>
                <div>- triggered when a user updates the an individual score entity from the IPM scores page </div>
              </TwoColumns>
              <TwoColumns>
                <Chip $methodLabel='deleteAttemptGrader' $width={200}>deleteAttemptGrader</Chip>
                <div>- triggered when a user deletes a grader from the grading process from Grader</div>
              </TwoColumns>
            </div>
          </div>
        </ModalBody>
      </div>
    </SimpleModal>
  );
}

InfoModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired
};

export default InfoModal;
