import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectOverallAptitudeWeight } from '../../../../redux/selectors/transcriptAptitudeSelectors';

export default function AptitudeWeight({ programUuid, category }) {
  const overallAptitudeWeight = useSelector(state =>
    selectOverallAptitudeWeight(state, programUuid, category)
  );

  const weight = overallAptitudeWeight ? `${overallAptitudeWeight}%` : 'N/A';

  return <>{weight}</>;
}

AptitudeWeight.propTypes = {
  category: PropTypes.string,
  programUuid: PropTypes.string
};

AptitudeWeight.defaultProps = {
  category: undefined,
  programUuid: undefined
};
