import React, { useState, useEffect, useReducer, useMemo } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useParams } from 'react-router-dom'

import { sortByPropertyAlphaNumeric } from '../../../../../helpers/utilities'

import { validateSelectField } from '../../../../../helpers/validation/validateGeneric'
import { validateAssessmentTitle } from '../../../../../helpers/validation/validateAssessment'

import { assessmentSelector } from '../../../../../redux/selectors/assessmentSelectors'
import {
  assessmentTypesSelector,
  assessmentTechniquesSelector,
  assessmentAssociationsSelector,
} from '../../../../../redux/selectors/assessmentFieldSelectors'
import { programFacultyUsersSelector } from '../../../../../redux/selectors/userProgramsSelectors'

import {
  doPostCohortAssessment,
  doPutCohortAssessment,
  doDeleteCohortAsssessment,
} from '../../../../../redux/actions/cohortAssessmentActions'
import { doClearCreated } from '../../../../../redux/actions/courseCollectionActions'
import AssessmentForm from './AssessmentForm'
import {
  cohortAssessmentReducer,
  initialCohortAssessmentState,
  setShowValidationErrors,
} from './assessmentFormState'
import { useDelete } from '../../../../Library/Modal/DeleteModal/useDelete'
import {
  gradingTechniqueReducer,
  initialGradingTechniqueState,
} from '../../../../Library/GradingTechniqueForm/gradingTechniqueState'
import { programAssessmentMasterSelector } from '../../../../../redux/selectors/programSelectors'
import { cohortAssessmentCollectionSelector } from '../../../../../redux/selectors/cohortAssessmentSelectors'
import { assessmentTypesMap } from '../../../../../helpers/constants'

export default function AssessmentFormContainer ({
  restrictEdit,
  existingAssessmentUuid,
  isNew,
  parentCohort,
  parentCollection,
  setAssessmentFormUuid,
  setClearForm,
}) {
  const dispatch = useDispatch()
  const { cohortUuid } = useParams()

  const [existing, setExisting] = useState(false)

  const [fieldInFocus, setFieldInFocus] = useState('')
  const [assessmentIndex, setAssessmentIndex] = useState(0)
  const [optionsFaculty, setOptionsFaculty] = useState([])

  const [optionsAssessmentTypes, setAssessmentTypes] = useState([])
  const [optionsAssessmentTechniques, setAssessmentTechniques] = useState([])
  const [optionsAssessmentAssociations, setAssessmentAssociations] = useState(
    [],
  )

  const [assessmentState, assessmentDispatch] = useReducer(
    cohortAssessmentReducer,
    initialCohortAssessmentState,
  )

  const [gradingTechniqueState, gradingTechniqueDispatch] = useReducer(
    gradingTechniqueReducer,
    initialGradingTechniqueState
  );

  const { selectedAssessmentMasterUuid, showValidationErrors } =
    assessmentState;

  const selectedProgramUuid = useSelector(
    state => state.userState.selectedProgramUuid
  );

  const existingCohortAssessment = useSelector(
    state => assessmentSelector(state, existingAssessmentUuid),
    _.isEqual
  );

  const assessmentCollection = useSelector(
    state => cohortAssessmentCollectionSelector(state, parentCollection.uuid),
    _.isEqual
  );
  const { cohortAssessments } = assessmentCollection;

  const assessmentTypes = useSelector(
    state => assessmentTypesSelector(state),
    _.isEqual
  );
  const assessmentTechniques = useSelector(
    state => assessmentTechniquesSelector(state),
    _.isEqual
  );
  const assessmentAssociations = useSelector(
    state => assessmentAssociationsSelector(state),
    _.isEqual
  );

  const programAssessmentMaster = useSelector(
    state =>
      programAssessmentMasterSelector(state, selectedAssessmentMasterUuid),
    _.isEqual
  );

  const programFaculty = useSelector(
    state => programFacultyUsersSelector(state, selectedProgramUuid),
    _.isEqual
  );

  const {
    successfullyUpdatedProgramAssessments,
    successfullyDeletedCohortAssessment
  } = useSelector(state => state.formSuccessState, shallowEqual);

  useEffect(() => {
    if (assessmentTypes) {
      const assessmentTypeOptions = _.map(assessmentTypes, aType => ({
        value: aType.uuid,
        label: aType.name,
        order: aType.order
      })).sort((a, b) => a.order - b.order);

      setAssessmentTypes(assessmentTypeOptions);
    }

    if (assessmentTechniques) {
      const assessmentTechniqueOptions = _.map(assessmentTechniques, aTech => ({
        value: aTech.uuid,
        label: aTech.technique
      }));

      setAssessmentTechniques(assessmentTechniqueOptions);
    }

    if (assessmentAssociations) {
      const assessmentAssociationOptions = _.map(
        assessmentAssociations,
        association => ({
          value: association.uuid,
          label: association.association
        })
      );

      setAssessmentAssociations(assessmentAssociationOptions);
    }
  }, [assessmentTypes, assessmentTechniques, assessmentAssociations]);

  useEffect(() => {
    const faculty = programFaculty
      ? programFaculty.map(user => ({
        value: user.userUuid,
        label: `${user.firstName} ${user.lastName}`,
      }))
      : []

    setOptionsFaculty(faculty)
  }, [programFaculty])

  const isAppliedDidactic = useMemo(() => optionsAssessmentTypes
      .find(type => type.value === programAssessmentMaster.assessmentTypeUuid)
      ?.label
      ?.includes(assessmentTypesMap.appliedDidactic),
    [optionsAssessmentTypes, programAssessmentMaster.assessmentTypeUuid])

  useEffect(() => {
    // TODO: remove setExistingGradingTechnique and only show gradingTechnique that comes from parent assessment
    const setExistingGradingTechniqueState = grading_technique => {
      const {
        uuid,
        gradingTechnique,
        maximumRevus,
        maximumMethodCalculated,
        lowScore,
        lowScoreCalculated,
        calculatedLowScore,
        calculatedHighScore,
        curvedHighGrade,
        curvedFailingGrade,
        scaledHighScore,
        scaledLowScore
      } = grading_technique;

      gradingTechniqueDispatch({
        type: 'setExistingGradingTechnique',
        payload: {
          uuid,
          gradingTechnique,
          maximumRevusInput: maximumRevus,
          maximumMethodCalculated,
          lowScoreInput: lowScore,
          lowScoreCalculated,
          calculatedLowScore,
          calculatedHighScore,
          curvedHighGrade,
          curvedFailingGrade,
          scaledHighScore,
          scaledLowScore
        }
      });
    };

    if (existingCohortAssessment && existingCohortAssessment?.uuid) {
      const { grading_technique, rubricCheck, ...rest } = existingCohortAssessment
      if (grading_technique?.uuid) {
        setExistingGradingTechniqueState(grading_technique)
      }

      setExisting(true)
      assessmentDispatch({
        type: 'setExisting',
        payload: {
          ...rest,
          rubricCheck: isAppliedDidactic ? false : rubricCheck,
        },
      })

      setFieldInFocus('')
    } else {
      setExisting(false)
      setFieldInFocus('')
    }
  }, [isAppliedDidactic, existingCohortAssessment]);

  useEffect(() => {
    if (successfullyUpdatedProgramAssessments) {
      dispatch(doClearCreated());

      if (isNew) {
        setAssessmentFormUuid(successfullyUpdatedProgramAssessments);
      }
    }
  }, [
    dispatch,
    successfullyUpdatedProgramAssessments,
    isNew,
    setAssessmentFormUuid
  ]);

  useEffect(() => {
    if (successfullyDeletedCohortAssessment) {
      dispatch(doClearCreated());

      setClearForm();
    }
  }, [dispatch, successfullyDeletedCohortAssessment, setClearForm]);

  useEffect(() => {
    if (existingAssessmentUuid && cohortAssessments) {
      const sort = cohortAssessments.sort((a, b) =>
        sortByPropertyAlphaNumeric(a, b, 'title')
      );

      const location =
        _.findIndex(sort, item => item.uuid === existingAssessmentUuid) + 1;

      setAssessmentIndex(location)
    }
  }, [existingAssessmentUuid, cohortAssessments]);

  useEffect(() => {
    if (isNew) {
      assessmentDispatch({
        type: 'clearForm',
      })
    }
  }, [isNew])

  useEffect(() => {
    assessmentDispatch({
      type: 'setRubricCheck',
      payload: !isAppliedDidactic,
    })
  }, [isAppliedDidactic])

  const buildRequest = () => {
    const {
      uuid,
      assessmentManager,
      responsibleFaculty,
      status,
      title,
      rubricCheck,
    } = assessmentState

    const payload = {
      uuid: isNew ? undefined : uuid,
      assessmentManager,
      responsibleFaculty,
      status,
      title,
      rubricCheck,
      cohortUuid: parentCohort.uuid,
      programAssessmentMasterUuid: selectedAssessmentMasterUuid,
      cohortAssessmentCollectionUuid: parentCollection.uuid
    };

    return payload;
  };

  const validate = () => {
    const { assessmentManager, responsibleFaculty, title } = assessmentState;

    const titleError = validateAssessmentTitle(title);
    const responsibleFacultyError = validateSelectField(responsibleFaculty);
    const assessmentManagerError = validateSelectField(assessmentManager);

    return {
      title: titleError,
      responsibleFaculty: responsibleFacultyError,
      assessmentManager: assessmentManagerError
    };
  };

  const passValidation = validationErrors => {
    const errorList = _.mapValues(validationErrors, value => value.invalid);
    const invalids = _.map(errorList, value => value);
    const check = _.filter(invalids, value => value === true);

    if (check.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleAssessmentSubmit = () => {
    const errors = validate();
    const payload = buildRequest();

    function createOrUpdate() {
      if (existing) {
        dispatch(doPutCohortAssessment(payload));
      } else {
        dispatch(doPostCohortAssessment(payload));
      }
    }

    if (passValidation(errors)) {
      createOrUpdate();

      assessmentDispatch(setShowValidationErrors(false));
      setFieldInFocus('');
    } else {
      assessmentDispatch(setShowValidationErrors(true));
    }
  };

  const handleAssessmentChange = event => {
    const { name, value } = event.target;
    switch (name) {
      case 'title':
        assessmentDispatch({
          type: 'setTitle',
          payload: { title: value }
        });
        break;
      case 'responsibleFaculty':
        assessmentDispatch({
          type: 'setResponsibleFaculty',
          payload: { responsibleFaculty: value }
        });
        break;
      case 'assessmentManager':
        assessmentDispatch({
          type: 'setAssessmentManager',
          payload: { assessmentManager: value }
        });
        break;
      default:
        break;
    }
  };

  const handleRubricCheck = (checked) => {
    assessmentDispatch({
      type: 'setRubricCheck',
      payload: checked,
    })
  }

  const handleUserFieldChange = (values, fieldName) => {
    switch (fieldName) {
      case 'responsibleFaculty':
        assessmentDispatch({
          type: 'setResponsibleFaculty',
          payload: { responsibleFaculty: values }
        });
        break;
      case 'assessmentManager':
        assessmentDispatch({
          type: 'setAssessmentManager',
          payload: { assessmentManager: values }
        });
        break;
      default:
        break;
    }
  };

  const onDelete = () =>
    dispatch(
      doDeleteCohortAsssessment(existingCohortAssessment.uuid, cohortUuid)
    );

  const [
    isDeleteModalOpen,
    handleDeleteClick,
    handleDeleteModalClose,
    handleDelete
  ] = useDelete(onDelete);

  // function handleApproveAssessment() {
  //   if (status === 'Complete') {
  //     dispatch(doPatchAssessmentApprove(assessmentUuid));
  //     assessmentDispatch(setShowValidationErrors(false));
  //     setFieldInFocus('');
  //   }
  // }

  function handleFieldInFocusValidation(event) {
    setFieldInFocus(event.target.name);
  }

  return (
    <AssessmentForm
      assessmentDispatch={assessmentDispatch}
      assessmentIndex={assessmentIndex}
      assessmentState={assessmentState}
      existing={existing}
      fieldInFocus={fieldInFocus}
      hasErrors={showValidationErrors}
      isNew={isNew}
      optionsAssessmentAssociations={optionsAssessmentAssociations}
      optionsAssessmentTechniques={optionsAssessmentTechniques}
      optionsAssessmentTypes={optionsAssessmentTypes}
      optionsFaculty={optionsFaculty}
      parentCohort={parentCohort}
      programAssessmentMaster={programAssessmentMaster}
      restrictEdit={restrictEdit}
      selectedAssessmentMasterUuid={selectedAssessmentMasterUuid}
      handleAssessmentChange={event => handleAssessmentChange(event)}
      handleUserFieldChange={(value, name) =>
        handleUserFieldChange(value, name)
      }
      handleAssessmentSubmit={handleAssessmentSubmit}
      handleFieldInFocusValidation={event =>
        handleFieldInFocusValidation(event)
      }
      handleDelete={handleDelete}
      handleDeleteClick={handleDeleteClick}
      handleDeleteModalClose={handleDeleteModalClose}
      handleRubricCheck={handleRubricCheck}
      isDeleteModalOpen={isDeleteModalOpen}
      gradingTechnique={gradingTechniqueState}
    />
  );
}

AssessmentFormContainer.propTypes = {
  existingAssessmentUuid: PropTypes.string,
  parentCohort: PropTypes.object,
  parentCollection: PropTypes.object,
  isNew: PropTypes.bool,
  restrictEdit: PropTypes.bool,
  setAssessmentFormUuid: PropTypes.func,
  setClearForm: PropTypes.func
};

AssessmentFormContainer.defaultProps = {
  existingAssessmentUuid: '',
  parentCohort: undefined,
  parentCollection: undefined,
  isNew: false,
  restrictEdit: true,
  setAssessmentFormUuid: undefined,
  setClearForm: undefined
};
