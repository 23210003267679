import { call, put, takeEvery } from 'redux-saga/effects';
import {
  postEncounter,
  postCalculateLinkedEncounterObjectives,
  putEncounterDraft,
  putEncounterFinal,
  deleteEncounter,
  patchEncounterApprove
} from '../../api/encounterApi';
import {
  doPostEncounterSuccess,
  doPostEncounterError,
  doPostCalculateEncounterObjectivesSuccess,
  doPostCalculateEncounterObjectivesError,
  doPutEncounterDraftSuccess,
  doPutEncounterDraftError,
  doPutEncounterFinalSuccess,
  doPutEncounterFinalError,
  doDeleteEncounterSuccess,
  doDeleteEncounterError,
  doPatchEncounterApproveSuccess,
  doPatchEncounterApproveError,
  doPostCalculateEncounterObjectivesInvalidScores
} from '../actions/encounterActions';

import {
  POST_ENCOUNTER,
  POST_CALCULATE_ENCOUNTER_OBJECTIVES,
  PUT_ENCOUNTER_DRAFT,
  PUT_ENCOUNTER_FINAL,
  PATCH_ENCOUNTER_APPROVE,
  DELETE_ENCOUNTER
} from '../actions-type';

function* handlePostCreateEncounter(action) {
  const { encounter } = action.payload;

  try {
    const result = yield call(postEncounter, encounter);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPostEncounterSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostEncounterError(error));
  }
}

function* handlePostCalculateEncounterObjectives(action) {
  const { encounterUuid } = action.payload;

  try {
    const result = yield call(
      postCalculateLinkedEncounterObjectives,
      encounterUuid
    );
    if (result.status < 300) {
      const payload = yield result.json();

      yield put(doPostCalculateEncounterObjectivesSuccess(payload));
      yield put(doPostCalculateEncounterObjectivesInvalidScores(null));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    if (error.errors) {
      yield put(doPostCalculateEncounterObjectivesInvalidScores(error.errors));
    } else {
      yield put(doPostCalculateEncounterObjectivesInvalidScores(null));
    }

    yield put(doPostCalculateEncounterObjectivesError(error));
  }
}

function* handlePutDraftEncounter(action) {
  const { encounter } = action.payload;

  try {
    const result = yield call(putEncounterDraft, encounter);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutEncounterDraftSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutEncounterDraftError(error));
  }
}

function* handlePutFinalEncounter(action) {
  const { encounter } = action.payload;

  try {
    const result = yield call(putEncounterFinal, encounter);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutEncounterFinalSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutEncounterFinalError(error));
  }
}

function* handlePatchApproveEncounter(action) {
  try {
    const result = yield call(patchEncounterApprove, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchEncounterApproveSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPatchEncounterApproveError(error));
  }
}

function* handlePostDeleteEncounter(action) {
  try {
    const result = yield call(deleteEncounter, action.payload.encounterUuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteEncounterSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteEncounterError(error));
  }
}

const encounter = [
  takeEvery(POST_ENCOUNTER, handlePostCreateEncounter),
  takeEvery(
    POST_CALCULATE_ENCOUNTER_OBJECTIVES,
    handlePostCalculateEncounterObjectives
  ),
  takeEvery(PUT_ENCOUNTER_DRAFT, handlePutDraftEncounter),
  takeEvery(PUT_ENCOUNTER_FINAL, handlePutFinalEncounter),
  takeEvery(PATCH_ENCOUNTER_APPROVE, handlePatchApproveEncounter),
  takeEvery(DELETE_ENCOUNTER, handlePostDeleteEncounter)
];

export { encounter };
