import { call, put, takeEvery } from 'redux-saga/effects';
import {
  postCompetency,
  putCompetency,
  putCompetencyAddMicros,
  deleteCompetency
} from '../../api/competencyApi';
import {
  doPostCompetencySuccess,
  doPostCompetencyError,
  doPutCompetencySuccess,
  doPutCompetencyError,
  doPutCompetencyAddMicrosSuccess,
  doPutCompetencyAddMicrosError,
  doDeleteCompetencySuccess,
  doDeleteCompetencyError
} from '../actions/competencyActions';

import {
  POST_COMPETENCY,
  PUT_COMPETENCY,
  PUT_COMPETENCY_MICROS,
  DELETE_COMPETENCY
} from '../actions-type';

function* handlePostCreateCompetency(action) {
  const { newCompetency } = action.payload;

  try {
    const result = yield call(postCompetency, newCompetency);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostCompetencySuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doPostCompetencyError({
          error: errorText.error
        })
      );
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPostCompetencyError());
  }
}

function* handlePutUpdateCompetency(action) {
  const { competencyUpdates } = action.payload;

  try {
    const result = yield call(putCompetency, competencyUpdates);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutCompetencySuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(doPutCompetencyError(errorText));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPutCompetencyError());
  }
}

function* handlePutCompetencyAddMicros(action) {
  const { uuid, micros } = action.payload;

  try {
    const result = yield call(putCompetencyAddMicros, { uuid, micros });
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutCompetencyAddMicrosSuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(doPutCompetencyAddMicrosError(errorText));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPutCompetencyAddMicrosError());
  }
}

function* handleDeleteCompetency(action) {
  const { uuid } = action.payload;

  try {
    const result = yield call(deleteCompetency, uuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doDeleteCompetencySuccess(payload));
    } else if (result.status < 500) {
      const errorText = yield result.json();
      yield put(
        doDeleteCompetencyError({
          text: errorText
        })
      );
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doDeleteCompetencyError());
  }
}

const competency = [
  takeEvery(POST_COMPETENCY, handlePostCreateCompetency),
  takeEvery(PUT_COMPETENCY, handlePutUpdateCompetency),
  takeEvery(PUT_COMPETENCY_MICROS, handlePutCompetencyAddMicros),
  takeEvery(DELETE_COMPETENCY, handleDeleteCompetency)
];

export { competency };
