import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import { ButtonInline, ButtonDelete } from '@xcomp/xcomp-design-library';
import {
  ContainerResize,
  ContainerPage,
  ContainerContent,
  ElevateLow
} from '../../Library/Layout';

import { validateInputString } from '../../../helpers/validation/validateGeneric';

import Header from '../../Library/Header';
import FormField from '../../Library/FormField';

const DeleteRow = styled.div`
  margin-top: 2rem;
  margin-bottom: 6rem;
`;

const SchoolHeader = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
`;

const SchoolEdit = ({
  abbreviation,
  schoolName,
  hasErrors,
  handleChange,
  handleUpdateSchool,
  handleDeleteModalOpen
}) => {
  return (
    <ContainerResize>
      <Header title="School Management" subtitle="Edit school" />
      <ContainerPage>
        <ElevateLow>
          <ContainerContent>
            <SchoolHeader>School Details</SchoolHeader>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <FormField
                  id="abbreviation"
                  name="abbreviation"
                  label="Abbreviation*"
                  value={abbreviation}
                  onBlur={handleChange}
                  handleValidate={validateInputString}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={9}>
                <FormField
                  id="schoolName"
                  name="schoolName"
                  label="School Name*"
                  value={schoolName}
                  onBlur={handleChange}
                  handleValidate={validateInputString}
                  hasErrors={hasErrors}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <ButtonInline onClick={handleUpdateSchool}>Save</ButtonInline>
                </Grid>
              </Grid>
            </Grid>
          </ContainerContent>
        </ElevateLow>
        <DeleteRow>
          <ButtonDelete onClick={handleDeleteModalOpen}>
            Delete School
          </ButtonDelete>
        </DeleteRow>
      </ContainerPage>
    </ContainerResize>
  );
};

SchoolEdit.propTypes = {
  hasErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  handleUpdateSchool: PropTypes.func,
  abbreviation: PropTypes.string,
  schoolName: PropTypes.string,
  handleDeleteModalOpen: PropTypes.func
};
SchoolEdit.defaultProps = {
  hasErrors: false,
  handleChange: undefined,
  handleUpdateSchool: undefined,
  abbreviation: '',
  schoolName: '',
  handleDeleteModalOpen: undefined
};

export default SchoolEdit;
