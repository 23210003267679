import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import IconButton from '@material-ui/core/IconButton';

const Layout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Order = styled.div`
  width: 20px;
`;

const Middle = styled.div`
  width: 100%;
  margin-left: 15px;
  margin-right: 30px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  width: 100px;
`;

const DeletIconSC = styled(DeleteIcon)`
  width: 100%;
  color: ${props => props.theme.fontColors.dark};
  &:hover {
    color: red;
  }
`;

const LearningObjectiveActions = ({
  objectiveUuid,
  objectiveNumber,
  handleDeleteOpen,
  handleObjectiveMove
}) => (
  <Layout>
    <Order>{objectiveNumber}</Order>
    <Middle />
    <Actions>
      <IconButton onClick={() => handleObjectiveMove(objectiveUuid, 'up')}>
        <ExpandLessIcon />
      </IconButton>
      <IconButton onClick={() => handleObjectiveMove(objectiveUuid, 'down')}>
        <ExpandMoreIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          handleDeleteOpen();
        }}
      >
        <DeletIconSC />
      </IconButton>
    </Actions>
  </Layout>
);

LearningObjectiveActions.propTypes = {
  objectiveUuid: PropTypes.string,
  objectiveNumber: PropTypes.number,
  handleDeleteOpen: PropTypes.func,
  handleObjectiveMove: PropTypes.func
};

LearningObjectiveActions.defaultProps = {
  objectiveUuid: '',
  objectiveNumber: 0,
  handleDeleteOpen: undefined,
  handleObjectiveMove: undefined
};

export default LearningObjectiveActions;
