import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { coursesByDeptAy } from '../../redux/selectors/coursesSelectors';
import SyllabusManagement from './SyllabusManagement';

export default function SyllabusManagmentContainer() {
  const filteredCourses = useSelector(
    state => coursesByDeptAy(state),
    _.isEqual
  );

  return (
    <div>
      <SyllabusManagement filteredCourses={filteredCourses} />
    </div>
  );
}
