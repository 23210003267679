import { Model, attr, fk } from 'redux-orm';
import {
  GET_PART_GRADE_SETTINGS_SUCCESS,
  PUT_PART_GRADE_DATA_SOURCE_SUCCESS,
  PUT_PART_GRADE_COLLECTIONS_SUCCESS,
  PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_SUCCESS
} from '../actions-type';

class PartGradeSettings extends Model {
  static reducer(action, PartGradeSettings, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_PART_GRADE_SETTINGS_SUCCESS: {
        const { part_grade_settings } = payload;
        PartGradeSettings.upsert(part_grade_settings);
        break;
      }
      case PUT_PART_GRADE_DATA_SOURCE_SUCCESS: {
        const { part_grade_settings } = payload.part;
        PartGradeSettings.upsert(part_grade_settings);
        break;
      }
      case PUT_PART_GRADE_COLLECTIONS_SUCCESS: {
        const { part_grade_settings } = payload.part;
        PartGradeSettings.upsert(part_grade_settings);
        break;
      }
      case PUT_PART_GRADE_SETTINGS_GRADING_TECHNIQUE_SUCCESS: {
        const { gradingTechnique, part_grade_settings } = payload;

        PartGradeSettings.upsert(part_grade_settings).set(
          'grading_technique',
          gradingTechnique
        );

        break;
      }

      default:
        return session;
    }
  }
}

PartGradeSettings.fields = {
  uuid: attr(),
  dataSource: attr(),
  allow_dropped_assessments: attr(),
  low_grades_dropped: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  partUuid: fk('Part', 'part_grade_settings')
};

PartGradeSettings.modelName = 'PartGradeSettings';

PartGradeSettings.options = {
  idAttribute: 'partUuid'
};

export default PartGradeSettings;
