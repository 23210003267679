import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CSVReader from 'react-csv-reader';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonPin from '@material-ui/icons/PersonPin';
import { ButtonInline, ButtonPrimary } from '@xcomp/xcomp-design-library';
import Modal from '../../../Library/Modal/Modal';
import UploadMapping from '../../../Library/UploadMapping';
import UploadErrors from '../../../Library/UploadErrors';
import { headersDepartmentRole } from '../../../../helpers/headers_upload/headers_roles';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 900px;
`;

const Title = styled.div`
  color: ${props => props.theme.fontColors.darker};
  font-size: 20px;
  margin-bottom: 20px;
`;

const ManualSC = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
const Close = styled(ButtonInline)`
  && {
    margin-right: 5px;
  }
`;

const ImportButton = styled(ButtonPrimary)`
  && {
    margin-left: 5px;
  }
`;

const Created = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.white};
  border-radius: 5px;
  padding: 15px;
  background: ${props => props.theme.colors.status.success};
`;

const FileUpload = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Note = styled.div`
  margin-top: 10px;
  font-size: 12px;
`;

const UserAssign = ({
  openMapping,
  userRoles,
  uploadState,
  uploadingDepartmentRoles,
  departmentRolesCreated,
  openUserAssignModal,
  handleUserAssignClose,
  handleDisplayMapping,
  handleAssign,
  handleImport
}) => (
  <Modal open={openUserAssignModal} onClose={handleUserAssignClose}>
    <ModalBody>
      <Title>Mass Assign Department Roles (CSV)</Title>
      <ManualSC>Select a file to import</ManualSC>
      <FileUpload>
        <CSVReader
          onFileLoaded={handleAssign}
          parserOptions={{ header: true }}
          inputId="userload"
          inputStyle={{
            width: '100%',
            color: 'black',
            background: 'lightgrey',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            borderRadius: '5px'
          }}
        />
        <Note>
          If changes are made to an existing selected file, close this modal and
          re-open then re-select the file.
        </Note>
      </FileUpload>
      <Actions>
        <ButtonPrimary onClick={handleDisplayMapping}>
          {openMapping ? `Hide Rules` : `Show Rules`}
        </ButtonPrimary>
        <div>
          <Close onClick={handleUserAssignClose}>Close</Close>
          <ImportButton disabled={!userRoles} onClick={handleImport}>
            Import
          </ImportButton>
        </div>
      </Actions>

      {uploadingDepartmentRoles && (
        <Loading>
          <CircularProgress size="100px" />
        </Loading>
      )}

      {departmentRolesCreated > 0 && (
        <Created>
          <PersonPin />
          {departmentRolesCreated} roles assigned.
        </Created>
      )}

      <UploadMapping display={openMapping} fields={headersDepartmentRole} />
      <UploadErrors uploadState={uploadState} />
    </ModalBody>
  </Modal>
);

UserAssign.propTypes = {
  openMapping: PropTypes.bool,
  uploadState: PropTypes.object,
  uploadingDepartmentRoles: PropTypes.bool,
  departmentRolesCreated: PropTypes.number,
  openUserAssignModal: PropTypes.bool,
  handleUserAssignClose: PropTypes.func,
  handleAssign: PropTypes.func,
  handleImport: PropTypes.func,
  handleDisplayMapping: PropTypes.func,
  userRoles: PropTypes.array
};

UserAssign.defaultProps = {
  openMapping: false,
  uploadState: {},
  uploadingDepartmentRoles: false,
  departmentRolesCreated: 0,
  openUserAssignModal: false,
  handleUserAssignClose: undefined,
  handleDisplayMapping: undefined,
  handleAssign: undefined,
  handleImport: undefined,
  userRoles: undefined
};

export default UserAssign;
