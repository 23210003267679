import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { selectCoursesByTerm } from '../../../../redux/selectors/coursesSelectors';
import CustomDialog from '../../../Library/Modal/CustomDialog';
import CoursesTable from './CoursesTable';

export default function CoursesByTermTable({ userUuid, termUuid }) {
  const [open, setOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState();
  const [dialogTitle, setDialogTitle] = useState();

  const courses = useSelector(
    state => selectCoursesByTerm(state, termUuid, userUuid),
    _.isEqual
  );

  const handleClick = useCallback((description, title) => {
    setOpen(true);
    setSelectedDescription(description);
    setDialogTitle(title);
  }, []);

  return (
    <>
      <CoursesTable
        courses={courses}
        handleClick={handleClick}
        userUuid={userUuid}
      />
      <CustomDialog
        open={open}
        handleClose={setOpen}
        title={dialogTitle}
        contentText={selectedDescription}
      />
    </>
  );
}

CoursesByTermTable.propTypes = {
  userUuid: PropTypes.string,
  termUuid: PropTypes.string
};

CoursesByTermTable.defaultProps = {
  userUuid: undefined,
  termUuid: undefined
};
