import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import { visibleToSchoolAdmin } from '../../helpers/authorization';
import CourseList from './CourseList/Course/CourseList';
import Header from '../Library/Header';
import {
  ContainerResize,
  ContainerPage,
  ContainerActions
} from '../Library/Layout';

const CreateCourseSection = visibleToSchoolAdmin(() => (
  <Link to="/syllabus-management/course/add">
    <ButtonPrimary>CREATE COURSE SECTION</ButtonPrimary>
  </Link>
));

const SyllabusManagement = ({ filteredCourses }) => (
  <ContainerResize>
    <Header
      title="Course Sections"
      subtitle="Manage, add and remove course sections"
      backButtonLocation="/"
      backOn={false}
    />
    <ContainerActions>
      <CreateCourseSection />
    </ContainerActions>
    <ContainerPage paddingTop="40px">
      <CourseList filteredCourses={filteredCourses} />
    </ContainerPage>
  </ContainerResize>
);

SyllabusManagement.propTypes = {
  filteredCourses: PropTypes.arrayOf(PropTypes.object)
};

SyllabusManagement.defaultProps = {
  filteredCourses: []
};

export default SyllabusManagement;
