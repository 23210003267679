import _ from 'lodash';
import {
  POST_UPLOAD_STUDENTS_SECTION,
  POST_UPLOAD_STUDENTS_SECTION_SUCCESS,
  POST_UPLOAD_STUDENTS_SECTION_ERROR,
  POST_UPLOAD_STUDENTS_SECTION_WARNING,
  RESET_UPLOAD_STUDENTS_SECTION
} from '../actions-type';

export const INITIAL_STATE = {
  uploadingStudentsSection: false,
  invalid: false,
  studentsAssigned: 0
};

const uploadStudentsSectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_UPLOAD_STUDENTS_SECTION: {
      return {
        ...state,
        uploadingStudentsSection: true,
        invalid: false
      };
    }
    case POST_UPLOAD_STUDENTS_SECTION_SUCCESS: {
      return {
        ...state,
        uploadingStudentsSection: false,
        invalid: false,
        studentsAssigned: action.payload.students_assigned
      };
    }
    case POST_UPLOAD_STUDENTS_SECTION_ERROR: {
      return {
        ...state,
        uploadingStudentsSection: false,
        invalid: true,
        error_server: _.get(action, 'payload.error', '')
      };
    }
    case POST_UPLOAD_STUDENTS_SECTION_WARNING: {
      return {
        ...state,
        uploadingStudentsSection: false,
        invalid: true,
        ...action.payload.warning
      };
    }
    case RESET_UPLOAD_STUDENTS_SECTION: {
      return {
        uploadingStudentsSection: false,
        invalid: false,
        studentsAssigned: 0
      };
    }
    default:
      return state;
  }
};

export default uploadStudentsSectionReducer;
