import React from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js-light';
import TopicDefaultScoreViewTopicPanel from './TopicDefaultScoreViewTopicPanel';

const TopicDefaultScoreViewTopicPanelContainer = ({
  assessmentUuid,
  cumulativePotentialScore,
  partFacultyUsers,
  scoreType,
  students,
  topic,
  topicScores,
  topicScoreViewSelection,
  topicTotalPotential,
  topicTotalRelative
}) => {
  const decimalTotalPotential = new Decimal(topicTotalPotential);
  const decimalTotalRelative = new Decimal(topicTotalRelative);
  const decimalCumulativePotential = new Decimal(cumulativePotentialScore);
  const topicParticipationPercentage = decimalCumulativePotential.isZero()
    ? cumulativePotentialScore
    : decimalTotalPotential
        .dividedBy(decimalCumulativePotential)
        .times(100)
        .toFixed(2);
  const topicRelativePercentage = decimalTotalPotential.isZero()
    ? topicTotalPotential
    : decimalTotalRelative
        .dividedBy(decimalTotalPotential)
        .times(100)
        .toFixed(2);
  return decimalTotalPotential.greaterThan(0) ? (
    <TopicDefaultScoreViewTopicPanel
      assessmentUuid={assessmentUuid}
      partFacultyUsers={partFacultyUsers}
      scoreType={scoreType}
      students={students}
      topicScores={topicScores}
      topicScoreViewSelection={topicScoreViewSelection}
      topicParticipationPercentage={topicParticipationPercentage}
      topicRelativePercentage={topicRelativePercentage}
      topicTitle={topic.title}
      topicTotalPotential={topicTotalPotential}
      topicTotalRelative={topicTotalRelative}
    />
  ) : null;
};

TopicDefaultScoreViewTopicPanelContainer.propTypes = {
  assessmentUuid: PropTypes.string,
  cumulativePotentialScore: PropTypes.string,
  partFacultyUsers: PropTypes.array,
  scoreType: PropTypes.string,
  students: PropTypes.array,
  topic: PropTypes.object,
  topicScores: PropTypes.array,
  topicScoreViewSelection: PropTypes.string,
  topicTotalPotential: PropTypes.string,
  topicTotalRelative: PropTypes.string
};
TopicDefaultScoreViewTopicPanelContainer.defaultProps = {
  assessmentUuid: '',
  cumulativePotentialScore: '0.00',
  partFacultyUsers: [],
  scoreType: '',
  students: [],
  topic: {},
  topicScores: [],
  topicScoreViewSelection: '',
  topicTotalPotential: '0.00',
  topicTotalRelative: '0.00'
};

export default TopicDefaultScoreViewTopicPanelContainer;
