import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Grid } from '@material-ui/core';
import { Heading2 } from '@xcomp/xcomp-design-library';
import { Panel, PanelHeader } from '../../Library/DashboardSC';
import MultiSelectOutline from '../../Library/MultiSelectOutline';

const PanelSC = styled(Panel)`
  min-width: 900px;
`;
const FilterContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
`;
const FilterBody = styled(Grid)``;

const ButtonSC = styled(Button)`
  && {
    margin-bottom: 16px;
  }
`;

const FilterDropDown = ({ options, callback, isTopicFilter, title }) => {
  const [hasSetDefaultValues, setHasSetDefaultValues] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const dropdownOptions = useMemo(
    () =>
      options.map(opt => {
        return {
          value: opt.uuid,
          label: isTopicFilter ? opt.title : opt.name
        };
      }),
    [isTopicFilter, options]
  );
  useEffect(() => {
    if (!hasSetDefaultValues) {
      setSelectedOptions(options.map(assessmentType => assessmentType.uuid));
      setHasSetDefaultValues(true);
    }
  }, [hasSetDefaultValues, options]);

  const handleSelectOptions = event => {
    const { value } = event.target;
    if (value.includes('all')) {
      if (options.every(at => value.includes(at.uuid))) {
        setSelectedOptions([]);
        return;
      }
      setSelectedOptions(options.map(at => at.uuid));
      return;
    }

    setSelectedOptions(value);
  };

  const handleSelectAllOptions = event => {
    const { checked } = event.target;
    const { value } = event.target.dataset;

    if (checked || value === 'all') {
      setSelectedOptions(options.map(topic => topic.uuid));
    } else {
      setSelectedOptions([]);
    }
  };

  const handleDeselectOptions = value => {
    setSelectedOptions(prevTopics =>
      prevTopics.filter(topicUuid => topicUuid !== value)
    );
  };

  const handleResetAllFilters = () => {
    setSelectedOptions(options.map(topic => topic.uuid));
  };

  const handleApplyTopicReportFilters = () => {
    if (callback) callback(selectedOptions);
  };

  return (
    <PanelSC>
      <PanelHeader paddingLeft="16px">
        <Heading2>
          {title}
        </Heading2>
      </PanelHeader>
      <FilterContainer>
        <FilterBody container spacing={2}>
          <Grid item sm={12}>
            <MultiSelectOutline
              label="Topic"
              name="topicSelect"
              options={dropdownOptions}
              value={selectedOptions}
              handleChange={handleSelectOptions}
              onSelectAll={handleSelectAllOptions}
              onDeselect={handleDeselectOptions}
              multiple
            />
          </Grid>
          <Grid container item justifyContent="space-between" sm={12}>
            <ButtonSC onClick={handleResetAllFilters} color="primary">
              RESET FILTERS
            </ButtonSC>
            <ButtonSC
              color="primary"
              variant="contained"
              onClick={handleApplyTopicReportFilters}
              disabled={selectedOptions.length === 0}
            >
              APPLY
            </ButtonSC>
          </Grid>
        </FilterBody>
      </FilterContainer>
    </PanelSC>
  );
};

FilterDropDown.propTypes = {
  callback: PropTypes.func,
  options: PropTypes.array,
  title: PropTypes.string.isRequired,
  isTopicFilter: PropTypes.bool,
};

FilterDropDown.defaultProps = {
  callback: undefined,
  options: [],
  isTopicFilter: false
};

export default FilterDropDown;
