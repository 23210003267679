import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CohortStudentAssign from './CohortStudentAssign';
import { doPostStudentConversion } from '../../../../redux/actions/userCohortActions';
import { applicantsByStatusSelector } from '../../../../redux/selectors/userCohortsSelectors';

export default function CohortStudentAssignContainer({
  cohortUuid,
  openAssignStudent,
  handleAssignStudentClose
}) {
  const dispatch = useDispatch();
  const [applicants, setApplicants] = useState([]);
  const applicantsORM = useSelector(
    state => applicantsByStatusSelector(state, cohortUuid, 'active'),
    _.isEqual
  );

  useEffect(() => {
    const addSelector = applicantsORM.map(applicants => ({
      ...applicants,
      selected: false
    }));

    setApplicants(addSelector);
  }, [applicantsORM]);

  const handleUserSelection = uuid => {
    setApplicants(applicants => {
      const selectedApplicants = applicants.map(applicant => {
        if (applicant.userUuid === uuid) {
          return { ...applicant, selected: !applicant.selected };
        } else {
          return { ...applicant };
        }
      });

      return selectedApplicants;
    });
  };

  const handleClearSelection = () => {
    setApplicants(applicants => {
      const selectedApplicants = applicants.map(applicant => {
        return { ...applicant, selected: false };
      });

      return selectedApplicants;
    });
  };

  const handleConvertStudent = () => {
    const selected = applicants.filter(
      applicant => applicant.selected === true
    );

    if (selected.length > 0) {
      dispatch(doPostStudentConversion(selected));
    }

    handleAssignStudentClose();
  };

  return (
    <CohortStudentAssign
      applicants={applicants}
      openAssignStudent={openAssignStudent}
      handleAssignStudentClose={handleAssignStudentClose}
      handleUserSelection={handleUserSelection}
      handleConvertStudent={handleConvertStudent}
      handleClearSelection={handleClearSelection}
    />
  );
}

CohortStudentAssignContainer.propTypes = {
  cohortUuid: PropTypes.string,
  openAssignStudent: PropTypes.bool,
  handleAssignStudentClose: PropTypes.func
};
CohortStudentAssignContainer.defaultProps = {
  cohortUuid: '',
  openAssignStudent: false,
  handleAssignStudentClose: undefined
};
