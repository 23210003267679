import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import TermShow from './TermShow';
import { termsByAcademicYearSelector } from '../../../../../../redux/selectors/termsSelectors';

export default function TermShowContainer({ academicYearUuid }) {
  const terms = useSelector(
    state => termsByAcademicYearSelector(state, academicYearUuid),
    _.isEqual
  );

  return <TermShow terms={terms} />;
}

TermShowContainer.propTypes = {
  academicYearUuid: PropTypes.string
};
TermShowContainer.defaultProps = {
  academicYearUuid: ''
};
