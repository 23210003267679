import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import StudentMassAssign from './StudentMassAssign';
import {
  doUploadStudentsSection,
  doUploadStudentsSectionReset
} from '../../../../redux/actions/userSectionActions';

export default function StudentMassAssignContainer({
  schoolUuid,
  sectionUuid,
  openMassAssignStudent,
  handleMassAssignStudentClose
}) {
  const dispatch = useDispatch();
  const [students, setStudents] = useState(undefined);
  const [openMapping, setOpenMapping] = useState(false);
  const uploadState = useSelector(
    state => state.uploadStudentsSectionState,
    shallowEqual
  );

  const { studentsAssigned } = useSelector(
    state => state.uploadStudentsSectionState
  );

  useEffect(() => {
    if (!openMassAssignStudent) {
      setStudents(undefined);
      setOpenMapping(false);
      dispatch(doUploadStudentsSectionReset());
    }
  }, [dispatch, openMassAssignStudent]);

  const handleStudents = students => {
    setStudents(students);
  };

  const handleUpload = () => {
    dispatch(doUploadStudentsSection(schoolUuid, sectionUuid, students));
  };

  const handleDisplayMapping = () => {
    setOpenMapping(openMapping => !openMapping);
  };

  return (
    <StudentMassAssign
      uploadState={uploadState}
      openModal={openMassAssignStudent}
      openMapping={openMapping}
      students={students}
      studentsAssigned={studentsAssigned}
      handleDisplayMapping={handleDisplayMapping}
      handleMassAssignStudentClose={handleMassAssignStudentClose}
      handleStudents={handleStudents}
      handleUpload={handleUpload}
    />
  );
}

StudentMassAssignContainer.propTypes = {
  schoolUuid: PropTypes.string,
  sectionUuid: PropTypes.string,
  openMassAssignStudent: PropTypes.bool,
  handleMassAssignStudentClose: PropTypes.func
};
StudentMassAssignContainer.defaultProps = {
  schoolUuid: '',
  sectionUuid: '',
  openMassAssignStudent: false,
  handleMassAssignStudentClose: undefined
};
