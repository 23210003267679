import { Model, attr, fk } from 'redux-orm';
import {
  DELETE_ASSESSMENT_SUCCESS,
  DELETE_COHORT_ASSESSMENT_SUCCESS,
  GET_ASSESSMENTS_BY_PART_SUCCESS,
  GET_ASSESSMENT_SUCCESS,
  GET_COHORT_ASSESSMENTS_SUCCESS,
  GET_COHORT_ASSESSMENT_COMMITMENT_LOG_SUCCESS,
  GET_COHORT_ASSESSMENT_SCORES_SUCCESS,
  GET_GRADE_COLLECTION_SUCCESS,
  GET_PART_GRADE_SETTINGS_SUCCESS,
  GET_PART_GROUP_SUMMARY_SUCCESS,
  GET_SCORES_SUCCESS,
  GET_SYLLABUS_PART_SUCCESS,
  GET_SYLLABUS_SUCCESS,
  PATCH_ASSESSMENT_APPROVE_SUCCESS,
  PATCH_COHORT_ASSESSMENT_STATUS_SUCCESS,
  POST_ASSESSMENT_SUCCESS,
  POST_COHORT_ASSESSMENT_SUCCESS,
  PUT_ASSESSMENT_DRAFT_SUCCESS,
  PUT_ASSESSMENT_FINAL_SUCCESS,
  PUT_ASSESSMENT_GRADING_TECHNIQUE_SUCCESS,
  PUT_ASSESSMENT_STATUS_COMMIT_SUCCESS,
  PUT_ASSESSMENT_STATUS_SCORE_SUCCESS,
  PUT_COHORT_ASSESSMENT_SUCCESS,
  GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS,
  GET_STUDENT_PART_GROUP_SUMMARY_SUCCESS,
  GET_COHORT_OPPORTUNITY_REPORT_SUCCESS
} from '../actions-type';

class Assessment extends Model {
  static reducer(action, Assessment, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_SYLLABUS_SUCCESS: {
        const assessments = [...payload.assessments];
        assessments.forEach(assessment => Assessment.upsert(assessment));
        break;
      }
      case GET_SYLLABUS_PART_SUCCESS: {
        const assessments = [...payload.assessments];
        assessments.forEach(assessment => Assessment.upsert(assessment));
        break;
      }
      case GET_ASSESSMENT_SUCCESS: {
        const assessment = { ...payload.assessment };
        Assessment.upsert(assessment);
        break;
      }
      case GET_COHORT_ASSESSMENT_COMMITMENT_LOG_SUCCESS: {
        const assessment = { ...payload.assessment };
        Assessment.upsert(assessment);
        break;
      }
      case GET_ASSESSMENTS_BY_PART_SUCCESS: {
        const assessments = payload;
        assessments.forEach(assessment => Assessment.upsert(assessment));
        break;
      }
      case GET_PART_GRADE_SETTINGS_SUCCESS: {
        const assessments = [...payload.part.assessments];
        assessments.forEach(assessment => Assessment.upsert(assessment));
        break;
      }
      case GET_GRADE_COLLECTION_SUCCESS: {
        const assessments = [...payload.part.assessments];
        assessments.forEach(assessment => Assessment.upsert(assessment));
        break;
      }
      case GET_STUDENT_PART_GROUP_SUMMARY_SUCCESS:
      case GET_PART_GROUP_SUMMARY_SUCCESS: {
        const assessments = [...payload.assessments];
        assessments.forEach(assessment => Assessment.upsert(assessment));
        break;
      }
      case POST_ASSESSMENT_SUCCESS: {
        const assessment = { ...payload.assessment };
        Assessment.upsert(assessment);
        break;
      }
      case PUT_ASSESSMENT_DRAFT_SUCCESS: {
        const assessment = { ...payload.assessment };
        Assessment.upsert(assessment);
        break;
      }
      case PUT_ASSESSMENT_FINAL_SUCCESS: {
        const assessment = { ...payload.assessment };
        Assessment.upsert(assessment);
        break;
      }
      case PUT_ASSESSMENT_STATUS_SCORE_SUCCESS: {
        const assessment = { ...payload.assessment };

        Assessment.upsert(assessment);
        break;
      }
      case PUT_ASSESSMENT_STATUS_COMMIT_SUCCESS: {
        const assessment = { ...payload.assessment };

        Assessment.upsert(assessment);
        break;
      }
      case DELETE_ASSESSMENT_SUCCESS: {
        const { assessmentUuid } = action.payload;
        Assessment.withId(assessmentUuid).delete();
        break;
      }
      case PATCH_ASSESSMENT_APPROVE_SUCCESS: {
        const { assessment } = payload;
        Assessment.upsert(assessment);
        break;
      }
      case GET_SCORES_SUCCESS: {
        const assessment = { ...payload.assessment };
        Assessment.upsert(assessment);
        break;
      }
      case GET_COHORT_ASSESSMENT_SCORES_SUCCESS: {
        const assessment = { ...payload.assessment };
        Assessment.upsert(assessment);
        break;
      }
      case PUT_ASSESSMENT_GRADING_TECHNIQUE_SUCCESS: {
        const { gradingTechnique, assessment } = payload;
        Assessment.withId(assessment.uuid).set(
          'gradingTechnique',
          gradingTechnique
        );
        break;
      }
      case GET_COHORT_ASSESSMENTS_SUCCESS: {
        const { cohort_assessment_collections } = payload;

        cohort_assessment_collections.forEach(collection =>
          collection.cohort_assessments.forEach(assessment =>
            Assessment.upsert(assessment)
          )
        );

        break;
      }
      case GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS: {
        const { cohortAssessmentCollections } = payload;

        cohortAssessmentCollections.forEach(collection =>
          collection.cohort_assessments.forEach(assessment =>
            Assessment.upsert(assessment)
          )
        );

        const { resultCards } = payload;
        resultCards.forEach(result => {
          const { assessment } = result;

          Assessment.upsert(assessment);
        });

        break;
      }
      case POST_COHORT_ASSESSMENT_SUCCESS: {
        const { cohort_assessment } = payload;

        Assessment.upsert(cohort_assessment);

        break;
      }
      case PUT_COHORT_ASSESSMENT_SUCCESS: {
        const { cohort_assessment } = payload;

        Assessment.upsert(cohort_assessment);

        break;
      }
      case PATCH_COHORT_ASSESSMENT_STATUS_SUCCESS: {
        const { cohort_assessment } = payload;

        Assessment.upsert(cohort_assessment);

        break;
      }
      case DELETE_COHORT_ASSESSMENT_SUCCESS: {
        const { deletedCohortAssessment } = action.payload;
        Assessment.withId(deletedCohortAssessment.uuid).delete();
        break;
      }
      case GET_COHORT_OPPORTUNITY_REPORT_SUCCESS: {
        const assessments = [...payload.assessments];
        assessments.map(a => Assessment.upsert(a));

        break;
      }
      default:
        return session;
    }
  }
}

Assessment.fields = {
  assessmentCloseDate: attr(),
  assessmentManager: attr(),
  assessmentOpenDate: attr(),
  assessment_type: attr(),
  completionPercentage: attr(),
  createdAt: attr(),
  endDate: attr(),
  legacyScores: attr(),
  location: attr(),
  partUuid: attr(),
  responsibleFaculty: attr(),
  rubricCheck: attr(),
  scoreType: attr(),
  shouldCloseAssessmentEnd: attr(),
  shouldCloseAtCompletion: attr(),
  shouldOpenAssessmentStart: attr(),
  startDate: attr(),
  status: attr(),
  statusCommit: attr(),
  statusScore: attr(),
  timespan: attr(),
  title: attr(),
  type: attr(),
  updatedAt: attr(),
  uuid: attr(),
  assessmentTypeUuid: fk('AssessmentType', 'assessment_type'),
  assessmentAssociationUuid: fk(
    'AssessmentAssociation',
    'assessment_association'
  ),
  assessment_technique: attr(),
  assessmentTechniqueUuid: fk('AssessmentTechnique', 'assessment_technique'),
  cohortAssessmentCollectionUuid: fk(
    'CohortAssessmentCollection',
    'cohort_assessments'
  ),
  gradingTechniqueUuid: attr()
};

Assessment.modelName = 'Assessment';

Assessment.options = {
  idAttribute: 'uuid'
};

export default Assessment;
