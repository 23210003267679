import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, Dialog } from '@material-ui/core';

export default function Modal({
  className,
  children,
  onClose,
  headerText,
  ...rest
}) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="button-modal"
      className={className}
      maxWidth="lg"
      {...rest}
    >
      {headerText && <DialogTitle id="button-modal">{headerText}</DialogTitle>}
      <div>{children}</div>
    </Dialog>
  );
}

Modal.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  headerText: PropTypes.string,
  onClose: PropTypes.func
};

Modal.defaultProps = {
  children: <div />,
  className: '',
  headerText: '',
  onClose: undefined
};
