import { call, put, takeEvery } from 'redux-saga/effects';

import {
  getUserDepartment,
  getUserDepartments,
  getUserDepartmentsFaculty,
  postUserDepartmentsAssign
} from '../../api/userDepartmentApi';

import {
  doGetUsersFacultySuccess,
  doGetUsersFacultyError,
  doGetUserDepartmentSuccess,
  doGetUserDepartmentError,
  doGetUserDepartmentsSuccess,
  doGetUserDepartmentsError,
  doPostUserDepartmentsSuccess,
  doPostUserDepartmentsError,
  doPostUserDepartmentsWarning
} from '../actions/userDepartmentActions';

import {
  GET_USER_DEPARTMENTS_FACULTY,
  GET_USER_DEPARTMENT,
  GET_USER_DEPARTMENTS,
  POST_USER_DEPARTMENTS_ASSIGN
} from '../actions-type';

function* handleGetUsersFaculty(action) {
  try {
    const result = yield call(
      getUserDepartmentsFaculty,
      action.payload.departmentUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetUsersFacultySuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUsersFacultyError(error));
  }
}

function* handleGetUserDepartment(action) {
  try {
    const result = yield call(getUserDepartment, action.payload.departmentUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetUserDepartmentSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUserDepartmentError(error));
  }
}

function* handleGetUserDepartments(action) {
  try {
    const result = yield call(getUserDepartments, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetUserDepartmentsSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetUserDepartmentsError(error));
  }
}

function* handlePostUserDepartments(action) {
  try {
    const result = yield call(postUserDepartmentsAssign, action.payload);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostUserDepartmentsSuccess(payload));
    } else if (result.status === 422) {
      const payload = yield result.json();
      yield put(doPostUserDepartmentsWarning(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostUserDepartmentsError(error));
  }
}

const userDepartment = [
  takeEvery(GET_USER_DEPARTMENTS_FACULTY, handleGetUsersFaculty),
  takeEvery(GET_USER_DEPARTMENT, handleGetUserDepartment),
  takeEvery(GET_USER_DEPARTMENTS, handleGetUserDepartments),
  takeEvery(POST_USER_DEPARTMENTS_ASSIGN, handlePostUserDepartments)
];

export { userDepartment };
