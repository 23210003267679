import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const DimmerContainer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1202;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingDimmer = ({ isLoading }) => {
  useEffect(() => {
    document.body.style.overflow = isLoading ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isLoading]);
  return (
    isLoading && (
      <DimmerContainer>
        <CircularProgress size={100} />
      </DimmerContainer>
    )
  );
};

LoadingDimmer.propTypes = {
  isLoading: PropTypes.bool
};

LoadingDimmer.defaultProps = {
  isLoading: false
};

export default LoadingDimmer;
