import fetch from '../helpers/fetch';

const getCohortAssessmentGridReport = (cohortUuid) =>
  fetch(
    `/api/cohort/${cohortUuid}/assessment-grid`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  )
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getCohortAssessmentGridReport };
