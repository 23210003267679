import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { doDeleteCourseCollection } from '../../../../redux/actions/courseCollectionActions';
import { useDelete } from '../../../Library/Modal/DeleteModal/useDelete';
import CourseCollectionRow from './CourseCollectionRow';

const CourseCollectionRowContainer = ({ courseCollection, withTopBorder }) => {
  const dispatch = useDispatch();

  const onDelete = () =>
    dispatch(doDeleteCourseCollection(courseCollection.uuid));

  const [
    isDeleteModalOpen,
    handleDeleteClick,
    handleDeleteModalClose,
    handleDelete
  ] = useDelete(onDelete);

  return (
    <CourseCollectionRow
      courseCollection={courseCollection}
      isDeleteModalOpen={isDeleteModalOpen}
      handleDeleteClick={handleDeleteClick}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDelete={handleDelete}
      withTopBorder={withTopBorder}
    />
  );
};

CourseCollectionRowContainer.propTypes = {
  courseCollection: PropTypes.object.isRequired,
  withTopBorder: PropTypes.bool
};

CourseCollectionRowContainer.defaultProps = {
  withTopBorder: false
};

export default CourseCollectionRowContainer;
