import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2 } from '@xcomp/xcomp-design-library';
import ItemStatusSummary from '../ItemStatusSummary/ItemStatusSummary';
import {
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader
} from '../../../../Library/Layout';
import Header from '../../../../Library/Header';
import {
  StatusCommitPill,
  StatusGradePill
} from '../../../../Library/StatusPill';
import SectionGradingContainer from './SectionGrading/SectionGradingContainer';

const AttemptHeader = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }
`;

const Label = styled.div`
  color: ${props => props.theme.fontColors.darker};
  position: relative;
  display: inline-block;
  z-index: 100;
  background: ${props => props.theme.colors.white};
  padding-right: 0.5rem;
  font-size: 14px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 19px;
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const ItemStatusSummarySC = styled(ItemStatusSummary)`
  margin-top: 0;
  margin-bottom: 48px;
`;

const StatusPills = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
`;

export default function SectionStatusSettings({
  section,
  statusCommit,
  statusGrade,
  courseNumber,
  courseTitle,
  sectionNumber
}) {
  const headerSubtitle = `${courseNumber} ● ${courseTitle}`;

  const directors = section?.section_directors_assigned;

  const sectionName =
    directors?.length > 0
      ? `Section ${sectionNumber} - ${directors[0].lastName}, ${directors[0].firstName}`
      : `Section ${sectionNumber}`;

  return (
    <ContainerResize>
      <Header title={sectionName} subtitle={headerSubtitle} />
      <ContainerPage>
        <Panel>
          <PanelHeader paddingLeft="24px" paddingRight="24px">
            <Heading2>Section Settings</Heading2>
            <StatusPills>
              <StatusGradePill statusGrade={statusGrade} />
              <StatusCommitPill statusCommit={statusCommit} />
            </StatusPills>
          </PanelHeader>
          <PanelPadding>
            <AttemptHeader offset="0">
              <Label>syllabus status</Label>
            </AttemptHeader>
            <ItemStatusSummarySC section={section} />
            <SectionGradingContainer sectionUuid={section?.uuid} />
          </PanelPadding>
        </Panel>
      </ContainerPage>
    </ContainerResize>
  );
}

SectionStatusSettings.propTypes = {
  courseNumber: PropTypes.string,
  courseTitle: PropTypes.string,
  sectionNumber: PropTypes.string,
  section: PropTypes.object,
  statusCommit: PropTypes.oneOf(['COMMITTED', 'UNCOMMITTED']),
  statusGrade: PropTypes.oneOf(['GRADED', 'NOT_GRADED', 'GRADING_IN_PROGRESS'])
};

SectionStatusSettings.defaultProps = {
  courseNumber: '',
  courseTitle: '',
  sectionNumber: '',
  section: {},
  statusCommit: 'UNCOMMITTED',
  statusGrade: 'NOT_GRADED'
};
