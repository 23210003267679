import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import {
  formatFirstNameMiddleName,
  formatLastName
} from '../../../../../helpers/format/user.format';
import TopicDefaultScoreViewStudentPanel from './TopicDefaultScoreViewStudentPanel';

const TopicDefaultScoreViewStudentPanelContainer = ({
  currentOffset,
  scoreType,
  studentUser,
  topicScores
}) => {
  const studentTopicScores = useMemo(
    () => topicScores.filter(score => score.studentUuid === studentUser.uuid),
    [studentUser.uuid, topicScores]
  );
  const {
    attemptOrOpportunityCount,
    studentTotalPotential,
    studentTotalRelative
  } = useMemo(
    () =>
      _.reduce(
        studentTopicScores,
        (acc, score) => {
          const currentPotential = new Decimal(score.potValue);
          const currentRelative = new Decimal(score.relValue);
          acc.studentTotalPotential = currentPotential
            .plus(acc.studentTotalPotential)
            .toFixed(2);
          acc.studentTotalRelative = currentRelative
            .plus(acc.studentTotalRelative)
            .toFixed(2);
          acc.attemptOrOpportunityCount = Math.max(
            acc.attemptOrOpportunityCount,
            score.attempt
          );
          return acc;
        },
        {
          studentTotalRelative: '0.00',
          studentTotalPotential: '0.00',
          attemptOrOpportunityCount: 0
        }
      ),
    [studentTopicScores]
  );
  const studentFirstName = formatFirstNameMiddleName(
    studentUser.firstName,
    studentUser.middleName
  );
  const studentLastName = formatLastName(
    studentUser.lastName,
    studentUser.marriedLastName,
    studentUser.suffix
  );
  return new Decimal(studentTotalPotential).greaterThan(0) ? (
    <TopicDefaultScoreViewStudentPanel
      attemptOrOpportunityCount={attemptOrOpportunityCount}
      currentOffset={currentOffset}
      scoreType={scoreType}
      studentFullName={`${studentLastName}, ${studentFirstName}`}
      studentTopicScores={studentTopicScores}
      studentTotalPotential={studentTotalPotential}
      studentTotalRelative={studentTotalRelative}
    />
  ) : null;
};

TopicDefaultScoreViewStudentPanelContainer.propTypes = {
  currentOffset: PropTypes.number,
  scoreType: PropTypes.string,
  studentUser: PropTypes.object,
  topicScores: PropTypes.array
};
TopicDefaultScoreViewStudentPanelContainer.defaultProps = {
  currentOffset: 0,
  scoreType: '',
  studentUser: [],
  topicScores: []
};

export default TopicDefaultScoreViewStudentPanelContainer;
