import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { AddIcon } from '@xcomp/xcomp-design-library/dist/icons';
import {
  Checkbox,
  Chip,
  FormControlLabel,
  Menu,
  Button,
  MenuItem
} from '@material-ui/core';

import { userSectionStatusMap } from '../../../helpers/constants';
import Header from '../../Library/Header';
import { ContainerResize, ContainerPage } from '../../Library/Layout';
import AccordionRowContainer from '../../Library/AccordionRow/AccordionRowContainer';
import StatusFilter from '../StudentRoster/StatusFilter';
import GroupPanel from './StudentPartGroups/GroupPanel';

const Actions = styled(({ padBottom, position, ...rest }) => <div {...rest} />)`
  display: flex;
  justify-content: ${props => (props.position ? props.position : 'flex-end;')}
  align-items: center;
  padding-top: 10px;
  padding-bottom: ${props => (props.padBottom ? '10px' : '0px')};
`;

const SectionAccordion = styled(
  ({ withBottomBorder, withTopBorder, ...rest }) => (
    <AccordionRowContainer {...rest} />
  )
)`
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}
  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}
      .accordion_row_label,
    p {
    font-size: 16px;
    padding-right: 1rem;
    color: ${props => props.theme.fontColors.darker};
  }
  .section_title {
    font-size: 12px;
    color: ${props => props.theme.fontColors.darker};
    padding-right: 1rem;
    flex-grow: 2;
  }
  .action_button {
    padding-right: 24px;
    font-size: 12px;
    color: ${props => props.theme.colors.primary};
  }
  .MuiChip-root {
    font-size: 12px;
    height: 1.5rem;
    background-color: ${props => props.theme.colors.blueGrey[100]};
  }
  .MuiButton-root.Mui-disabled {
    color: ${props => props.theme.colors.grey[300]};
    border: ${props => `solid 1px ${props.theme.colors.grey[300]}`};
  }
`;

const ButtonSC = styled(Button)`
  && {
    margin-left: 12px;
  }
`;

const Table = styled.table`
  background: ${props => props.theme.colors.white};
  border-collapse: collapse;
  width: 100%;
`;

const TableHead = styled.thead`
  font-size: 12px;
  color: ${props => props.theme.colors.grey[600]};
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: ${props => props.theme.colors.grey[600]};
`;

const TableRow = styled.tr``;

const TableBody = styled.tbody`
  color: ${props => props.theme.colors.grey[900]};
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 24px;
  &:last-child td {
    border-bottom: none;
  }
`;

const InstitutionId = styled.td`
  width: 15%;
  padding: 5px 10px 5px 20px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;
const Name = styled.td`
  width: 25%;
  padding: 5px 10px 5px 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-right: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Status = styled.td`
  width: 25%;
  padding: 5px 10px 5px 20px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Group = styled.td`
  font-size: 12px;
  color: ${props => props.theme.colors.grey[600]};
  width: 15%;
  padding: 5px 10px 5px 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const StatusBox = styled.div`
  font-size: 12px;
  color: ${props => props.theme.colors.grey[600]};
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const SelectAll = styled.td`
  width: 20%;
  padding: 5px 10px 5px 10px;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const SelectAllBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SelectLabel = styled.div`
  padding-right: 10px;
`;

const AddIconSC = styled(AddIcon)`
  margin-right: 8px;
`;

const StudentPartRoster = ({
  filterStatus,
  groups,
  partNumber,
  sectionNumber,
  selectAll,
  selectedStudents,
  students,
  studentStatusMap,
  studentsWithGroup,
  userGroups,
  onSelectAll,
  onDeselectAll,
  onSelectStudent,
  onFilterSelection,
  onOpenCreateGroupModal,
  onOpenDeleteGroupModal,
  onOpenEditGroupModal,
  onAddUsersToGroup,
  onOpenAssignGroupLeaderModal,
  onOpenRemoveStudentModal,
  onBackAction,
  partUuid
}) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const handleClick = event => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setMenuAnchor(null);
  };

  const handleAddUsersToGroupAndClose = (event, groupUuid) => {
    event.stopPropagation();
    onAddUsersToGroup(event, groupUuid);
    handleClose(event);
  };

  return (
    <ContainerResize>
      <Header
        title={`Student Roster - Section ${sectionNumber}, Part ${partNumber}`}
        backButtonOverride={onBackAction}
      />
      <ContainerPage paddingTop="80px">
        <SectionAccordion
          isTopLevel
          staticBg
          withBottomBorder
          expandWidth="66px"
          height="62px"
          label={`Section ${sectionNumber}`}
          columnOne={<Chip label={`${students.length} STUDENTS`} />}
          columnOneClassName="section_title"
          columnTwo={
            <>
              <ButtonSC
                variant="outlined"
                color="primary"
                aria-owns={menuAnchor ? 'simple-menu' : null}
                aria-haspopup="true"
                onClick={handleClick}
                disabled={selectedStudents.length === 0}
              >
                <AddIconSC />
                ADD TO GROUP
              </ButtonSC>
              <Menu
                id="simple-menu"
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                {groups.map(group => (
                  <MenuItem
                    key={group.uuid}
                    onClick={event =>
                      handleAddUsersToGroupAndClose(event, group.uuid)
                    }
                  >
                    Group {group.groupId ?? group.groupNumber}
                  </MenuItem>
                ))}
              </Menu>
            </>
          }
          columnTwoClassName="action_button"
        >
          <Table>
            <TableHead>
              <TableRow>
                <InstitutionId>Institution ID</InstitutionId>
                <Name>Last Name, First Name</Name>
                <Status>
                  <StatusFilter
                    filterStatus={filterStatus}
                    handleFilterSelection={onFilterSelection}
                  />
                </Status>
                <Group>Group</Group>
                <SelectAll>
                  <SelectAllBox>
                    {selectAll ? (
                      <>
                        <SelectLabel>Deselect all</SelectLabel>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectAll}
                              onChange={() => onDeselectAll()}
                              color="primary"
                            />
                          }
                        />
                      </>
                    ) : (
                      <>
                        <SelectLabel>Select all</SelectLabel>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectAll}
                              onChange={() => onSelectAll()}
                              color="primary"
                            />
                          }
                        />
                      </>
                    )}
                  </SelectAllBox>
                </SelectAll>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map(student => (
                <TableRow key={student.uuid}>
                  <InstitutionId>{student.user.institutionId}</InstitutionId>
                  <Name>{`${student.user.lastName}, ${student.user.firstName}`}</Name>
                  <Status>
                    <StatusBox>
                      {userSectionStatusMap[student.status]}
                    </StatusBox>
                  </Status>
                  <Group>
                    {(studentsWithGroup[student.user.uuid]?.groupId ||
                      studentsWithGroup[student.user.uuid]?.groupNumber) ??
                      '---'}
                  </Group>
                  <SelectAll>
                    <SelectAllBox>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedStudents.includes(
                              student.user.uuid
                            )}
                            onChange={event =>
                              onSelectStudent(event, student.user.uuid)
                            }
                            color="primary"
                          />
                        }
                      />
                    </SelectAllBox>
                  </SelectAll>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </SectionAccordion>
        {groups.map(group => (
          <GroupPanel
            key={group.uuid}
            group={group}
            groupMembers={userGroups.filter(
              userGroup => userGroup.groupUuid === group.uuid
            )}
            onOpenDeleteGroupModal={onOpenDeleteGroupModal}
            onOpenEditGroupModal={onOpenEditGroupModal}
            onOpenAssignGroupLeaderModal={onOpenAssignGroupLeaderModal}
            onOpenRemoveStudentModal={onOpenRemoveStudentModal}
            partUuid={partUuid}
            showGroupRankings={false}
            studentStatusMap={studentStatusMap}
            filterStatus={filterStatus}
          />
        ))}
        <Actions padBottom position="flex-start">
          <Button
            variant="outlined"
            color="primary"
            onClick={onOpenCreateGroupModal}
          >
            CREATE GROUP
          </Button>
        </Actions>
      </ContainerPage>
    </ContainerResize>
  );
};
StudentPartRoster.propTypes = {
  partNumber: PropTypes.string,
  sectionNumber: PropTypes.string,
  selectedStudents: PropTypes.array,
  students: PropTypes.array,
  studentStatusMap: PropTypes.object,
  studentsWithGroup: PropTypes.object,
  groups: PropTypes.array,
  selectAll: PropTypes.bool,
  userGroups: PropTypes.array,
  filterStatus: PropTypes.object,
  onSelectAll: PropTypes.func,
  onDeselectAll: PropTypes.func,
  onSelectStudent: PropTypes.func,
  onFilterSelection: PropTypes.func,
  onOpenCreateGroupModal: PropTypes.func,
  onOpenDeleteGroupModal: PropTypes.func,
  onOpenEditGroupModal: PropTypes.func,
  onOpenAssignGroupLeaderModal: PropTypes.func,
  onBackAction: PropTypes.func,
  onAddUsersToGroup: PropTypes.func,
  onOpenRemoveStudentModal: PropTypes.func,
  partUuid: PropTypes.string
};

StudentPartRoster.defaultProps = {
  partNumber: '',
  sectionNumber: '',
  selectedStudents: [],
  students: [],
  studentStatusMap: {},
  studentsWithGroup: {},
  groups: [],
  selectAll: false,
  userGroups: [],
  filterStatus: {},
  onSelectAll: undefined,
  onDeselectAll: undefined,
  onSelectStudent: undefined,
  onFilterSelection: undefined,
  onOpenCreateGroupModal: undefined,
  onOpenDeleteGroupModal: undefined,
  onOpenAssignGroupLeaderModal: undefined,
  onOpenEditGroupModal: undefined,
  onBackAction: undefined,
  onAddUsersToGroup: undefined,
  onOpenRemoveStudentModal: undefined,
  partUuid: ''
};

export default StudentPartRoster;
