import { Model, attr, fk } from 'redux-orm';
import { POST_COHORT_COMPETENCY_GRID_SUCCESS } from '../actions-type';

class CompetencyMicro extends Model {
  static reducer(action, CompetencyMicro, session) {
    const { payload, type } = action;

    switch (type) {
      case POST_COHORT_COMPETENCY_GRID_SUCCESS: {
        const { competencyMicros } = payload;

        return competencyMicros.forEach(competencyMicro =>
          CompetencyMicro.upsert(competencyMicro)
        );
      }

      default:
        return session;
    }
  }
}

CompetencyMicro.fields = {
  uuid: attr(),
  microcompetencyUuid: attr(),
  competencyUuid: fk('Competency', 'competency_micros'),
  createdAt: attr(),
  updatedAt: attr()
};

CompetencyMicro.modelName = 'CompetencyMicro';
CompetencyMicro.options = {
  idAttribute: 'uuid'
};

export default CompetencyMicro;
