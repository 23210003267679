import {
  GET_GRADE_SUMMARY_SUCCESS,
  DELETE_SCORES_SUCCESS,
  GET_GRADE_SUMMARY_ERROR
} from '../actions-type';

export const INITIAL_STATE = {
  resultCards: []
};

const gradeSummaryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_GRADE_SUMMARY_SUCCESS: {
      const { resultCards, ...gradeSummary } = action.payload.gradeSummary;
      return {
        resultCards,
        ...gradeSummary
      };
    }
    case GET_GRADE_SUMMARY_ERROR: {
      return INITIAL_STATE;
    }

    case DELETE_SCORES_SUCCESS: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

export default gradeSummaryReducer;
