import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSWRMutation from 'swr/mutation';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { LinearProgress } from '@material-ui/core';
import { selfAssessmentAssessmentTypesSelector } from '../../../../redux/selectors/assessmentSelectors';
import FilterDropDown from '../FilterDropDown';
import { fetcher } from '../../../../helpers/utilities';
import { formatFullName } from '../../../../helpers/format/user.format';
import PageIllustration from '../../../Library/PageIllustration';
import { doGetAssessmentType } from '../../../../redux/actions/assessmentActions';
import { getCharacterizationColor } from '../../../Reports/reportUtils';
import { ErrorWrapper, InvalidScores, NoDataContainer, PanelSC, ProgressBar, ReportLeaf, SelfAssessmentAccordion, SelfAssessmentHeader } from '../../../Reports/SelfAssessmentReportComponents';

const PartSelfAssessmentReportContainer = ({ partUuid }) => {
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);
  const [isAnonymized, setIsAnonymized] = useState(false);
  const dispatch = useDispatch();

  const { trigger, error, data, isMutating } = useSWRMutation(
    `/api/part/${partUuid}/self-assessment-report?`,
    fetcher,
    {
      throwOnError: false,
      revalidateOnFocus: false,
    }
  );

  const assessmentTypes = useSelector(
    state => selfAssessmentAssessmentTypesSelector(state),
    isEqual
  );

  useEffect(() => {
    if (partUuid) {
      dispatch(doGetAssessmentType());
    }
  }, [dispatch, partUuid]);

  const handleApplyPartSelfAssessmentReportFilters = assessmentTypes => {
    setHasAppliedFilters(true);
    trigger({queryParameters: `&assessmentTypes=${JSON.stringify(assessmentTypes)}`});
  };

  return (
    <>
      <FilterDropDown
        options={assessmentTypes}
        callback={handleApplyPartSelfAssessmentReportFilters}
        title='Self-Assessment Report Filters'
      />
      {data && !isMutating && Object.keys(data.invalidCharacterizations).length > 0 &&
        <InvalidScores invalidCharacterizations={data.invalidCharacterizations} />
      }
      {// eslint-disable-next-line
        isMutating ?
          <PanelSC>
            <ProgressBar>Loading</ProgressBar>
            <LinearProgress />
          </PanelSC>
          : error
            ?
              <ErrorWrapper>
                <PageIllustration
                  illustrationType="notFound"
                  infoText="An error occured when trying to retrieve data."
                />
              </ErrorWrapper>
            :
              <PanelSC>
                {hasAppliedFilters && data?.students.length === 0 && (
                  <NoDataContainer>
                    No data for the selected filters. Change filter selection and
                    re-apply.
                  </NoDataContainer>
                )}
                {!hasAppliedFilters && !data && (
                  <NoDataContainer>
                    Apply the filter selection to view the report.
                  </NoDataContainer>
                )}
                {data && data.students.length > 0 && (
                  <SelfAssessmentHeader isAnonymized={isAnonymized} setIsAnonymized={setIsAnonymized} />
                )}
                {data && data.students.length > 0 &&
                  data.students.map((s, index) => (
                    <SelfAssessmentAccordion
                      key={s.uuid}
                      columnOne={<span style={{fontWeight: 600}}>{s.totalCharacterizations}</span>}
                      columnTwo={`${(s.relValue / s.potValue * 100).toFixed(2)} %`}
                      columnThree={s.relValue.toFixed(1)}
                      columnFour={s.potValue.toFixed(1)}
                      columnOneClassName="column_one_label"
                      columnTwoClassName="column_two_label"
                      columnThreeClassName="column_three_label"
                      columnFourClassName="column_four_label"
                      expandWidth="66px"
                      height="50px"
                      isStatisticStudent={false}
                      label={isAnonymized ? s.institutionId : formatFullName(s)}
                      withBottomBorder
                      $level='student'
                      $even={index % 2 === 0}
                    >
                      {s.characterizations.map((c) =>
                        <SelfAssessmentAccordion
                          key={c.characterization}
                          columnOne={<span style={{fontWeight: c.total === 0 ? 300 : 600}}>{c.total.toString()}</span>}
                          columnTwo=' '
                          columnThree=' '
                          columnFour=' '
                          columnOneClassName="column_one_label"
                          columnTwoClassName="column_two_label"
                          columnThreeClassName="column_three_label"
                          columnFourClassName="column_four_label"
                          expandWidth="66px"
                          height="40px"
                          isStatisticStudent={false}
                          label={c.characterization}
                          $level='characterization'
                          $background={getCharacterizationColor(c)}
                        >
                          {c.scores.length === 0
                            ?
                              <div style={{ width: '100%', textAlign: 'center', fontSize: 16, fontStyle: 'italic' }}>There are no scores for this characterization</div>
                            :
                            c.scores.map((score, index) =>
                              <ReportLeaf
                                key={score.key}
                                even={index % 2 === 0}
                                assessmentTitle={score.assessmentTitle}
                                blockTitle={score.blockTitle}
                                blockNumber={score.blockNumber}
                                relValue={score.relValue}
                                potValue={score.potValue}
                              />
                            )}
                        </SelfAssessmentAccordion>
                      )}
                    </SelfAssessmentAccordion>
                  ))}
              </PanelSC>
      }
    </>
  );
};

PartSelfAssessmentReportContainer.propTypes = {
  partUuid: PropTypes.string
};

PartSelfAssessmentReportContainer.defaultProps = {
  partUuid: ''
};

export default PartSelfAssessmentReportContainer;
