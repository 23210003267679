import React from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js-light';
import styled, { css } from 'styled-components';
import { Chip } from '@material-ui/core';
import AccordionRowContainer from '../../../Library/AccordionRow/AccordionRowContainer';
import GroupInfoTable from './GroupInfoTable';
import PartGroupReportTableContainer from '../../PartGradeSummary/PartGroupReport/PartGroupReportTable/PartGroupReportTableContainer';

const GroupAccordion = styled(
  ({ withBottomBorder, withTopBorder, ...rest }) => (
    <AccordionRowContainer childMarginBottom="0" {...rest} />
  )
)`
  width: 100%;
  margin-bottom: 1.5rem;
  radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label, p {
    font-size: 16px;
    padding-right: 1rem;
    color: ${props => props.theme.fontColors.darker};
  }
  .section_title {
    font-size: 12px;
    color: ${props => props.theme.fontColors.darker};
    padding-right: 1rem;
    flex-grow: 2;
    .MuiChip-root {
      font-size: 12px;
      height: 1.5rem;
      background-color: ${props => props.theme.colors.blueGrey[100]};
    }
  }
  .section_data {
    .MuiChip-root {
      font-size: 12px;
      height: 1.5rem;
      background-color: ${props => props.theme.colors.primaryShades[100]};
    }
  }
  .action_button {
    padding-right: 1rem;
    font-size: 12px;
    color: ${props => props.theme.colors.primary};
  }
`;

const GroupStatistics = styled.div`
  display: flex;
  /* align-items: flex-end; */
  justify-content: center;
  margin-bottom: 8px;
`;

const GroupData = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
  justify-content: space-between;
`;

const GroupDataTitle = styled(({ paddingBottom, ...rest }) => (
  <div {...rest} />
))`
  font-size: 12px;
  padding-bottom: ${props => props.paddingBottom || '4px'};
  color: ${props => props.theme.fontColors.dark};
`;

const GroupDataValue = styled.div`
  font-size: 14px;
  color: ${props => props.theme.fontColors.darker};
`;

const GroupPanel = ({
  assessments,
  group,
  groupMembers,
  onOpenAssignGroupLeaderModal,
  onOpenDeleteGroupModal,
  onOpenEditGroupModal,
  onOpenRemoveStudentModal,
  partGroupSummaryState,
  partUuid,
  showGroupRankings,
  studentStatusMap
}) => {
  const handleDeleteGroupSelection = () => {
    onOpenDeleteGroupModal(group.uuid);
  };

  const handleGroupLeaderSelection = () => {
    onOpenAssignGroupLeaderModal(group.uuid);
  };

  const handleEditGroupSelection = () => {
    onOpenEditGroupModal(group.uuid);
  };

  const groupActions = [
    {
      label: 'Edit Group Name',
      onClick: handleEditGroupSelection,
      shouldOpenOnClick: false
    },
    {
      label: 'Assign Group Leader',
      onClick: handleGroupLeaderSelection,
      shouldOpenOnClick: false
    },
    {
      label: 'Delete Group',
      onClick: handleDeleteGroupSelection,
      shouldOpenOnClick: false
    }
  ];

  return (
    <GroupAccordion
      isTopLevel
      withBottomBorder
      expandWidth="66px"
      height="62px"
      label={`Group ${group.groupId ?? group.groupNumber}`}
      columnOne={<Chip label={`${groupMembers.length} STUDENTS`} />}
      columnOneClassName="section_title"
      columnTwo={
        showGroupRankings ? (
          <GroupStatistics>
            <GroupData>
              <GroupDataTitle>Rank</GroupDataTitle>
              <GroupDataValue>
                <Chip label={group.groupRank || '--'} />
              </GroupDataValue>
            </GroupData>
            <GroupData>
              <GroupDataTitle paddingBottom="10px">
                Participation
              </GroupDataTitle>
              <Chip
                label={
                  group.groupParticipation
                    ? `${new Decimal(group.groupParticipation)
                        .toDecimalPlaces(2)
                        .toString()} %`
                    : '--' || '--'
                }
              />
            </GroupData>
            <GroupData>
              <GroupDataTitle paddingBottom="10px">Average</GroupDataTitle>
              <Chip
                label={
                  group.pointsPerStudent
                    ? new Decimal(group.pointsPerStudent)
                        .toDecimalPlaces(2)
                        .toString()
                    : '--' || '--'
                }
              />
            </GroupData>
          </GroupStatistics>
        ) : null
      }
      columnTwoClassName="section_data"
      actions={showGroupRankings ? undefined : groupActions}
      staticBg
      staticActionsButton
    >
      {groupMembers.length > 0 &&
        (showGroupRankings ? (
          <PartGroupReportTableContainer
            assessments={assessments}
            groupMembers={groupMembers}
            partGroupSummaryState={partGroupSummaryState}
            partUuid={partUuid}
            studentStatusMap={studentStatusMap}
          />
        ) : (
          <GroupInfoTable
            group={group}
            groupMembers={groupMembers}
            onOpenRemoveStudentModal={onOpenRemoveStudentModal}
            studentStatusMap={studentStatusMap}
          />
        ))}
    </GroupAccordion>
  );
};

GroupPanel.propTypes = {
  assessments: PropTypes.array,
  group: PropTypes.object,
  groupMembers: PropTypes.array,
  onOpenAssignGroupLeaderModal: PropTypes.func,
  onOpenDeleteGroupModal: PropTypes.func,
  onOpenEditGroupModal: PropTypes.func,
  onOpenRemoveStudentModal: PropTypes.func,
  partGroupSummaryState: PropTypes.object,
  partUuid: PropTypes.string,
  showGroupRankings: PropTypes.bool,
  studentStatusMap: PropTypes.object
};

GroupPanel.defaultProps = {
  assessments: [],
  group: {},
  groupMembers: [],
  onOpenDeleteGroupModal: undefined,
  onOpenEditGroupModal: undefined,
  onOpenAssignGroupLeaderModal: undefined,
  onOpenRemoveStudentModal: undefined,
  partGroupSummaryState: {},
  partUuid: '',
  showGroupRankings: false,
  studentStatusMap: {}
};

export default GroupPanel;
