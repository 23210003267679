import fetch from '../helpers/fetch';

const getSyllabus = (courseUuid, sectionUuid, partUuid) =>
  fetch('/api/syllabus', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      courseUuid,
      sectionUuid,
      partUuid
    })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getSyllabusCourse = courseUuid =>
  fetch(`/api/syllabus-course?courseUuid=${courseUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getSyllabusPart = partUuid =>
  fetch(`/api/syllabus-part?partUuid=${partUuid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export { getSyllabusPart, getSyllabusCourse, getSyllabus };
