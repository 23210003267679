import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';
import { Caption } from '@xcomp/xcomp-design-library';
import Overline from '../../Library/Overline';

export const CourseIndex = styled.div`
  display: flex;
  padding: 8px;
`;

export const CourseIndexValue = styled(Caption)`
  margin-top: 0.25rem;
  margin-bottom: 0;
  color: ${props => props.theme.fontColors.darker};
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 2.5rem;
  font-size: 0.9875rem;
  color: ${props => props.theme.fontColors.dark};

  svg {
    margin-right: 0.25rem;
    font-size: 1.25rem;
    vertical-align: top;
    color: ${props => props.theme.fontColors.dark};
  }
`;

export const OverlineSC = styled(Overline)`
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
`;

const SyllabusInfo = ({ course, section, partStatus }) => {
  const directors = _.join(
    section.section_directors_assigned.map(
      director => `${director.firstName} ${director.lastName}`
    ),
    ', '
  );

  return (
    <CourseIndex>
      <InfoBlock>
        <div>
          <OverlineSC>Part Status</OverlineSC>
          <CourseIndexValue>{partStatus}</CourseIndexValue>
        </div>
      </InfoBlock>

      <InfoBlock>
        <div>
          <OverlineSC>Department</OverlineSC>
          <CourseIndexValue>
            {course.course_master.department.name}
          </CourseIndexValue>
        </div>
      </InfoBlock>
      <InfoBlock>
        <div>
          <OverlineSC>Academic Year</OverlineSC>
          <CourseIndexValue>
            {moment(course.academic_year.startDate).format('YYYY')}
            &nbsp;-&nbsp;
            {moment(course.academic_year.endDate).format('YYYY')}
          </CourseIndexValue>
        </div>
      </InfoBlock>
      <InfoBlock>
        <div>
          <OverlineSC>Term</OverlineSC>
          <CourseIndexValue>{course.term.period}</CourseIndexValue>
        </div>
      </InfoBlock>
      <InfoBlock>
        <div>
          <OverlineSC>Section Directors</OverlineSC>
          <CourseIndexValue>{directors}</CourseIndexValue>
        </div>
      </InfoBlock>
    </CourseIndex>
  );
};

SyllabusInfo.propTypes = {
  course: PropTypes.object,
  section: PropTypes.object,
  partStatus: PropTypes.string
};

SyllabusInfo.defaultProps = {
  course: {},
  section: {},
  partStatus: ''
};

export default SyllabusInfo;
