import _ from 'lodash';
import { validateRequired } from './validateGeneric';

const validateCompetencyDescription = (
  description,
  minLength,
  maxLength,
  required = true
) => {
  const requiredError = required ? validateRequired(description) : false;

  if (requiredError.invalid) {
    return requiredError;
  }

  if (!required && !description) {
    return {
      invalid: false
    };
  }

  if (_.size(description) < minLength) {
    return {
      invalid: true,
      isFormatError: true,
      message: `Must be at least ${minLength} characters`
    };
  }

  if (_.size(description) > maxLength) {
    return {
      invalid: true,
      isFormatError: true,
      message: `Must be less than ${maxLength} characters`
    };
  }

  return {
    invalid: false
  };
};

export { validateCompetencyDescription };
