import { Model, attr } from 'redux-orm';
import {
  GET_ASSESSMENT_TYPE_SUCCESS,
  GET_COHORT_ASSESSMENT_SCORES_SUCCESS,
  GET_PROGRAM_ASSESSMENTS_SUCCESS,
  GET_PROGRAM_ASSESSMENT_SUCCESS,
  GET_SCORES_SUCCESS,
  GET_SYLLABUS_PART_SUCCESS,
  GET_SYLLABUS_SUCCESS,
  GET_COHORT_ASSESSMENTS_SUCCESS,
  GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS,
  GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS
} from '../actions-type';

class AssessmentType extends Model {
  static reducer(action, AssessmentType, session) {
    const { type, payload } = action;
    switch (type) {
      case GET_SYLLABUS_SUCCESS: {
        const assessmentTypes = [...payload.assessmentTypes];
        assessmentTypes.forEach(type => AssessmentType.upsert(type));
        break;
      }
      case GET_ASSESSMENT_TYPE_SUCCESS: {
        const { assessmentTypes } = payload;
        assessmentTypes.forEach(type => AssessmentType.upsert(type));
        break;
      }
      case GET_PROGRAM_ASSESSMENT_SUCCESS:
      case GET_PROGRAM_ASSESSMENTS_SUCCESS: {
        const { assessmentTypes } = payload;
        assessmentTypes.forEach(type => AssessmentType.upsert(type));
        break;
      }
      case GET_COHORT_ASSESSMENT_SCORES_SUCCESS:
      case GET_SCORES_SUCCESS: {
        const assessmentTypes = [...payload.assessmentTypes];
        assessmentTypes.forEach(type => AssessmentType.upsert(type));
        break;
      }
      case GET_SYLLABUS_PART_SUCCESS: {
        const assessmentTypes = [...payload.assessmentTypes];
        assessmentTypes.forEach(type => AssessmentType.upsert(type));
        break;
      }
      case GET_COHORT_ASSESSMENTS_SUCCESS: {
        const assessmentTypes = [...payload.assessmentTypes];
        assessmentTypes.forEach(type => AssessmentType.upsert(type));
        break;
      }
      case GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS: {
        const assessmentTypes = [...payload.assessmentTypes];
        assessmentTypes.forEach(type => AssessmentType.upsert(type));
        break;
      }
      case GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS: {
        const assessmentTypes = [...payload.assessmentTypes];
        assessmentTypes.forEach(type => AssessmentType.upsert(type));
        break;
      }
      default:
        return session;
    }
  }
}

AssessmentType.fields = {
  uuid: attr(),
  name: attr(),
  order: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

AssessmentType.modelName = 'AssessmentType';

AssessmentType.options = {
  idAttribute: 'uuid'
};

export default AssessmentType;
