import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import { ButtonInline } from '@xcomp/xcomp-design-library';

const Label = styled.div``;

const FilterSelect = styled.div`
  display: flex;

  align-items: center;
`;

const MenuItemSC = styled(MenuItem)`
  && {
    display: flex;
    justify-content: space-between;
  }
`;

const FormControlLabelSC = styled(FormControlLabel)`
  && {
    margin: 0;
  }
`;

const ButtonInlineSC = styled(ButtonInline)`
  width: 100%;
`;

export default function SchoolRoleStatusFilter({
  className,
  filterActive,
  filterComplete,
  handleFilterSelection
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <FilterSelect
        aria-owns={anchorEl ? 'simple-menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
        noHoverBg
      >
        <Label>Status</Label>
        <ArrowDropDown />
      </FilterSelect>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItemSC>
          <Label>Active</Label>
          <FormControlLabelSC
            control={
              <Checkbox
                checked={filterActive}
                onChange={() => handleFilterSelection('active')}
                color="primary"
              />
            }
          />
        </MenuItemSC>
        <MenuItemSC>
          <Label>Complete</Label>
          <FormControlLabelSC
            control={
              <Checkbox
                checked={filterComplete}
                onChange={() => handleFilterSelection('complete')}
                color="primary"
              />
            }
          />
        </MenuItemSC>
        <MenuItemSC>
          <ButtonInlineSC onClick={handleClose}>Close</ButtonInlineSC>
        </MenuItemSC>
      </Menu>
    </div>
  );
}

SchoolRoleStatusFilter.propTypes = {
  className: PropTypes.string,
  filterActive: PropTypes.bool,
  filterComplete: PropTypes.bool,
  handleFilterSelection: PropTypes.func
};

SchoolRoleStatusFilter.defaultProps = {
  className: '',
  filterActive: false,
  filterComplete: false,
  handleFilterSelection: undefined
};
