import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ViewIcon, WarningIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { Body1, ButtonIcon } from '@xcomp/xcomp-design-library';
import { Chip } from '@material-ui/core';
import { shallowEqual, useSelector } from 'react-redux';
import {
  ScrollPanelLabel,
  ViewMore,
  Body1Dark
} from '../../Library/SyllabusComponents';
import { formatFullName } from '../../../helpers/format/user.format';
import SimpleModal from '../../Library/Modal/SimpleModal';
import { partSelector } from '../../../redux/selectors/partsSelectors';

const NotesLayout = styled(({ maxHeight, minWidth, ...rest }) => (
  <div {...rest} />
))`
  position: relative;
  margin-top: 16px;
  border: 1px solid ${props => props.theme.colors.grey[300]};
  border-radius: 4px;
  height: ${props => props.maxHeight || '90px'};
  min-width: ${props => props.minWidth || '100px;'};
`;

const NotesInner = styled.div`
  padding: 16px 16px 32px 16px;
  height: 100%;
  overflow-y: scroll;
`;

const ParticipatingFaculty = styled(Body1Dark)`
  display: block;
  margin-bottom: 16px;
`;

const WarningMessage = styled.div`
  cursor: pointer;
  color: ${props => props.theme.colors.status.warning};
  display: flex;
  align-items: center;
  margin: 10px 0;
  
  & > svg {
    margin-right: 5px;
  }

  &:hover {
    color: ${props => props.theme.colors.status.error};
  }
`

const StaffDisplay = styled(Body1)`
  margin: 0;
  color: ${props => props.theme.fontColors.darker};
`;

const ModalHeader = styled.div`
  padding: 1rem 1.25rem;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  font-size: 24px;
`

const ModalBody = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;

  h3 {
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

const Description = styled.div`
  margin-bottom: 10px;
`;

const ChipSC = styled(Chip)`
  && {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
  }
`;

const SyllabusNotes = ({
  notes,
  facultyAssigned,
  onViewMore,
  hasLabel,
  maxHeight,
  minWidth
}) => {
  const part = useSelector(state => partSelector(state), shallowEqual);

  const [invalidFacultiesModal, setInvalidFacultiesModal] = useState(false);

  const invalidFaculties = [];
  facultyAssigned.forEach(faculty => {
    if (!(part.part_faculties || []).find(op => op.value === faculty.uuid)) {
      invalidFaculties.push(formatFullName(faculty));
    }
  });
  
  return (
    <>
      <NotesLayout maxHeight={maxHeight} minWidth={minWidth}>
        {hasLabel && <ScrollPanelLabel>Notes</ScrollPanelLabel>}
        <NotesInner>
          {facultyAssigned && facultyAssigned.length > 0 && (
            <ParticipatingFaculty>
              Participating Faculty:&nbsp;
              {facultyAssigned.map((user, index) => (
                <span key={user.uuid}>
                  {`${user.firstName} ${user.lastName}${
                    index < facultyAssigned.length - 1 ? ', ' : ''
                  }`}
                </span>
              ))}
            </ParticipatingFaculty>
          )}
          <Body1Dark>{notes || ''}</Body1Dark>
          {onViewMore && (
            <ViewMore>
              <ButtonIcon onClick={onViewMore} noHoverBg>
                <ViewIcon />
              </ButtonIcon>
            </ViewMore>
          )}
        </NotesInner>
      </NotesLayout>
      {invalidFaculties.length > 0 &&
        <div>
          <WarningMessage onClick={() => setInvalidFacultiesModal(true)}>
            <WarningIcon />
            <div>Some participating faculties are not assigned to the part anymore.</div>
          </WarningMessage>
          {invalidFacultiesModal &&
            <SimpleModal
              open={invalidFacultiesModal}
              onClose={() => setInvalidFacultiesModal(false)}
              maxWidth="md"
            >
              <>
                <ModalHeader>Invalid Faculties</ModalHeader>
                <ModalBody>
                  <Description>
                    Below you can find all the faculties that are assigned to this Encounter, but not assigned to the Part anymore.
                  </Description>
                  <StaffDisplay>
                    {invalidFaculties.map(f => <ChipSC key={f} label={f} />)}
                  </StaffDisplay>
                </ModalBody>
              </>
            </SimpleModal>
          }
        </div>
      }
    </>
  );
}

SyllabusNotes.propTypes = {
  notes: PropTypes.string,
  facultyAssigned: PropTypes.arrayOf(PropTypes.object),
  onViewMore: PropTypes.func,
  hasLabel: PropTypes.bool,
  maxHeight: PropTypes.string,
  minWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

SyllabusNotes.defaultProps = {
  notes: '',
  facultyAssigned: [],
  onViewMore: undefined,
  hasLabel: true,
  maxHeight: '170px',
  minWidth: false
};

export default SyllabusNotes;
