import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Group = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.position};
`;

const Label = styled.div`
  font-size: 14px;
  color: ${props => props.theme.fontColors.dark};
  padding-bottom: 10px;
  font-weight: ${props => props.labelFontWeight || '400'};
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: ${props => props.valueFontWeight || '400'};
`;

export default function FormRead({
  label,
  value,
  position,
  labelFontWeight,
  valueFontWeight
}) {
  return (
    <Group position={position}>
      <Label labelFontWeight={labelFontWeight}>{label}</Label>
      <Value valueFontWeight={valueFontWeight}>{value}</Value>
    </Group>
  );
}

FormRead.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  position: PropTypes.string,
  labelFontWeight: PropTypes.string,
  valueFontWeight: PropTypes.string
};

FormRead.defaultProps = {
  label: '',
  value: '',
  position: 'flex-start',
  labelFontWeight: '400',
  valueFontWeight: '400'
};
