import _ from 'lodash';

const validateEducationLevelTextField = textField => {
  if (typeof textField === 'number' && textField === 0) {
    return {
      invalid: true,
      message: 'Field is required'
    };
  } else if (
    (typeof textField === 'string' && textField.trim() === '') ||
    !textField
  ) {
    return {
      invalid: true,
      message: 'Field is required'
    };
  } else {
    return {
      invalid: false
    };
  }
};

const validateLoverbTextFieldAdd = (textField, loverbsReact, loverbsRedux) => {
  if (!textField || textField.trim() === '' || textField === '0') {
    return {
      invalid: true,
      message: 'Field is required'
    };
  }

  const duplicatesReact = [];

  if (loverbsReact) {
    loverbsReact.forEach(loverb => {
      if (textField.toLowerCase() === loverb.objectiveVerb.toLowerCase()) {
        duplicatesReact.push(loverb.objectiveVerb);
      }
    });
  }

  if (duplicatesReact.length > 1) {
    return {
      invalid: true,
      message: 'Verb already exist within form'
    };
  }

  const duplicatesRedux = [];

  if (loverbsRedux) {
    loverbsRedux.forEach(loverb => {
      if (textField.toLowerCase() === loverb.objectiveVerb.toLowerCase()) {
        duplicatesRedux.push(loverb.objectiveVerb);
      }
    });
  }

  if (duplicatesRedux.length > 0) {
    return {
      invalid: true,
      message: 'Verb already exist within school'
    };
  }

  return {
    invalid: false
  };
};

const validateLoverbsAdd = (loverbsReact, loverbsRedux) => {
  const noBlankFields = loverbsReact.filter(
    textField => textField.objectiveVerb === '' || !textField.objectiveVerb
  );

  if (noBlankFields.length > 0) {
    return {
      invalid: true,
      message: 'Contains blank loverb field'
    };
  }

  /* react state duplication check */
  const objectiveVerbsReact = _.map(loverbsReact, loverb =>
    loverb.objectiveVerb.toLowerCase()
  );

  const duplicatesReact = _.filter(
    objectiveVerbsReact,
    (item, index, collection) => {
      return _.includes(collection, item, index + 1);
    }
  );

  if (duplicatesReact.length > 1) {
    return {
      invalid: true,
      message: 'Contains duplicate within form'
    };
  }

  const duplicatesRedux = [];

  if (loverbsRedux) {
    loverbsReact.forEach(newLoverb => {
      loverbsRedux.forEach(loverb => {
        if (
          newLoverb.objectiveVerb.toLowerCase() ===
          loverb.objectiveVerb.toLowerCase()
        ) {
          duplicatesRedux.push(loverb.objectiveVerb);
        }
      });
    });
  }

  if (duplicatesRedux.length > 0) {
    return {
      invalid: true,
      message: 'Contains duplicates in school'
    };
  }

  return {
    invalid: false
  };
};

const validateLoverbTextFieldEdit = (
  textField,
  loverbUuid,
  loverbsReact,
  loverbsRedux
) => {
  if (!textField || textField.trim() === '' || textField === '0') {
    return {
      invalid: true,
      message: 'Field is required'
    };
  }

  const duplicatesReact = [];

  if (loverbsReact) {
    loverbsReact.forEach(loverb => {
      if (textField.toLowerCase() === loverb.objectiveVerb.toLowerCase()) {
        duplicatesReact.push(loverb.objectiveVerb);
      }
    });
  }

  if (duplicatesReact.length > 1) {
    return {
      invalid: true,
      message: 'Verb already exist within form'
    };
  }

  const duplicatesRedux = [];

  if (loverbsRedux) {
    loverbsRedux.forEach(loverb => {
      if (!loverbUuid) {
        if (textField.toLowerCase() === loverb.objectiveVerb.toLowerCase()) {
          duplicatesRedux.push(loverb.objectiveVerb);
        }
      }
    });
  }

  if (duplicatesRedux.length > 0) {
    return {
      invalid: true,
      message: 'Verb already exist within school'
    };
  }

  return {
    invalid: false
  };
};

const validateLoverbsEdit = (loverbsReact, loverbsRedux) => {
  const noBlankFields = loverbsReact.filter(
    textField => textField.objectiveVerb === '' || !textField.objectiveVerb
  );

  if (noBlankFields.length > 0) {
    return {
      invalid: true,
      message: 'Contains blank loverb field'
    };
  }

  /* react state duplication check */
  const objectiveVerbsReact = _.map(loverbsReact, loverb =>
    loverb.objectiveVerb.toLowerCase()
  );

  const duplicatesReact = _.filter(
    objectiveVerbsReact,
    (item, index, collection) => {
      return _.includes(collection, item, index + 1);
    }
  );

  if (duplicatesReact.length > 1) {
    return {
      invalid: true,
      message: 'Contains duplicate within form'
    };
  }

  const added = _.filter(loverbsReact, loverb => !loverb.uuid);

  const duplicatesRedux = [];

  if (loverbsRedux) {
    added.forEach(newLoverb => {
      loverbsRedux.forEach(loverb => {
        if (
          newLoverb.objectiveVerb.toLowerCase() ===
          loverb.objectiveVerb.toLowerCase()
        ) {
          duplicatesRedux.push(loverb.objectiveVerb);
        }
      });
    });
  }

  if (duplicatesRedux.length > 0) {
    return {
      invalid: true,
      message: 'Contains duplicates in school'
    };
  }

  return {
    invalid: false
  };
};

export {
  validateEducationLevelTextField,
  validateLoverbTextFieldAdd,
  validateLoverbsAdd,
  validateLoverbTextFieldEdit,
  validateLoverbsEdit
};
