import { createSelector } from 'reselect';
import _ from 'lodash';

const cohortApplicantsInitialState = {
  cohortUsers: []
};

const cohortApplicantsReducer = (state, action) => {
  switch (action.type) {
    case 'exist': {
      const applicants = action.cohortUsersORM;

      const modify = applicants.map(cohortUser => ({
        ...cohortUser,
        selected: false
      }));

      return {
        ...state,
        cohortUsers: modify
      };
    }
    case 'select-all': {
      const all = state.cohortUsers.map(cu => {
        return { ...cu, selected: true };
      });

      return {
        ...state,
        cohortUsers: all
      };
    }
    case 'deselect-all': {
      const all = state.cohortUsers.map(cu => {
        return { ...cu, selected: false };
      });

      return {
        ...state,
        cohortUsers: all
      };
    }
    case 'select-single': {
      const { cohortUserUuid } = action;
      const list = state.cohortUsers.map(cu => {
        if (cu.uuid === cohortUserUuid) {
          return { ...cu, selected: true };
        } else {
          return cu;
        }
      });

      return {
        ...state,
        cohortUsers: list
      };
    }
    case 'deselect-single': {
      const { cohortUserUuid } = action;
      const list = state.cohortUsers.map(cu => {
        if (cu.uuid === cohortUserUuid) {
          return { ...cu, selected: false };
        } else {
          return cu;
        }
      });

      return {
        ...state,
        cohortUsers: list
      };
    }

    default:
      return {
        ...state
      };
  }
};

const selectCohortUser = createSelector(
  cohortUsers => cohortUsers,
  (_, cohortUserUuid) => cohortUserUuid,
  (cohortUsers, cohortUserUuid) => {
    const cohortUser = _.find(cohortUsers, cu => cu.uuid === cohortUserUuid);

    return cohortUser;
  }
);

export {
  cohortApplicantsReducer,
  cohortApplicantsInitialState,
  selectCohortUser
};
