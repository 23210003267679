import { useMemo } from 'react';
import { orderBy } from 'lodash';
import { useAtomValue } from 'jotai';
import Decimal from 'decimal.js-light';

import { diffInDays } from '../date-fns.utils';
import {
  filtersAtom,
  reportDataAtom
} from '../../components/SyllabusManagement/PartGradeSummary/PartAttemptReport/Filters';

export const useComputeAttemptReportSummaryRows = (isDidactic) => {
  const reportData = useAtomValue(reportDataAtom);
  const filters = useAtomValue(filtersAtom);

  const students = useMemo(() => {
    if (!reportData)
      return {
        s: [],
        total: 0
      };

    const ordered = orderBy(
      reportData?.activeStudents,
      ['user.numberOfPassedAss'],
      ['desc']
    );

    ordered.push(...reportData?.inactiveStudents);

    const activeStudents = reportData?.activeStudents.filter(s =>
      filters.filteredStudents.includes(s.user.uuid)
    );

    return {
      total: activeStudents.length,
      s: ordered.filter(s => filters.filteredStudents.includes(s.user.uuid)),
      activeStudents
    };
  }, [filters.filteredStudents, reportData]);

  const summary = useMemo(() => {
    let latestPassDate = null;
    let passedInFirstAttempt = 0;

    const summaryRows = {
      firstTimePassRate: 0
    };

    if (!students?.activeStudents?.length) return summaryRows;

    students.activeStudents.forEach(student => {
      let cumulativeRelScore = new Decimal(0);
      let cumulativePotScore = new Decimal(0);

      const assessmentBlockResults = isDidactic
        ? Object.keys(student.user.assessment_block_results).filter(
            key => filters.isExpanded 
            ? filters.filteredAssessments.includes(student.user.assessment_block_results[key].assessmentBlockUuid)
            : filters.filteredParts.includes(student.user.assessment_block_results[key].partUuid)
          )
        : Object.keys(student.user.assessment_block_results).filter(
            key =>
              (filters.isExpanded ? filters.filteredAssessments : filters.filteredParts).includes(
                student.user.assessment_block_results[key].assessmentBlockUuid
              ) && student.user.assessment_block_results[key].status === 'pass'
          )
    
      const numberOfPassedAssessments = assessmentBlockResults.length;

      assessmentBlockResults.forEach(key => {
        if (isDidactic) {
          cumulativeRelScore = cumulativeRelScore.add(new Decimal(student.user.assessment_block_results[key]?.assScoreRel || 0));
          cumulativePotScore = cumulativePotScore.add(new Decimal(student.user.assessment_block_results[key]?.assScorePot || 0));
        }

        const { passDate, blockAttempt } =
          student.user.assessment_block_results[key];

        // we store the date for the latest completed assessment
        // we will need it if the student has completed all the assessments
        // so we can display the total days of "competence"
        if (latestPassDate === null || passDate > latestPassDate) {
          latestPassDate = passDate;
        }

        // we add all the assessments that were passed in the first attempt
        if (blockAttempt === 1) {
          passedInFirstAttempt += 1;
        }

        // we calculate the earliest and latest assessment completion between all the ACTIVE students
        // basically, which a student completed first the assessment vs which completed last
        // and the difference in days between these 2

        if (!summaryRows[key]) {
          summaryRows[key] = {
            latest: passDate,
            earliest: passDate,
            span: 0
          };
        }

        if (!summaryRows[key].latest || summaryRows[key].latest < passDate) {
          summaryRows[key].latest = passDate;
        }

        if (!summaryRows[key].earliest || summaryRows[key].earliest > passDate) {
          summaryRows[key].earliest = passDate;
        }

        if (summaryRows[key].earliest && summaryRows[key].latest) {
          summaryRows[key].span = diffInDays(
            summaryRows[key].latest,
            summaryRows[key].earliest
          );
        }
      });

      if (isDidactic) {
        // eslint-disable-next-line no-param-reassign
        student.user.cumulativeScore = `${cumulativeRelScore}/${cumulativePotScore}`;
      }
      // eslint-disable-next-line no-param-reassign
      student.user.firstTimePassRate =
        numberOfPassedAssessments === 0 || passedInFirstAttempt === 0
          ? 0.0
          : (passedInFirstAttempt / numberOfPassedAssessments) * 100;
      summaryRows.firstTimePassRate += student.user.firstTimePassRate;
      passedInFirstAttempt = 0;
    });

    summaryRows.firstTimePassRate /= students.total;

    return summaryRows;
  }, [filters.filteredAssessments, filters.filteredParts, filters.isExpanded, students.activeStudents, students.total, isDidactic]);

  return {
    summaryRows: summary,
    students
  };
};
