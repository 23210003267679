import React, { useReducer, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import StatusSettings from './StatusSettings';
import { assessmentSelector } from '../../../../redux/selectors/assessmentSelectors';
import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';
import { allFieldsAreValid } from '../../../../helpers/validation/validateGeneric';
import {
  doGetAssessment,
  doPutAssessmentStatusScore,
  doPutAssessmentStatusCommit
} from '../../../../redux/actions/assessmentActions';
import {
  assessmentStatusReducer,
  initialAssessmentStatusState
} from './assessentStatusState';

export default function StatusSettingsContainer({ match }) {
  const dispatch = useDispatch();
  const { assessmentUuid } = match.params;

  const [state, assessmentDispatch] = useReducer(
    assessmentStatusReducer,
    initialAssessmentStatusState
  );

  const assessment = useSelector(
    state => assessmentSelector(state, assessmentUuid),
    _.isEqual
  );

  const onGeneralErrorNotification = errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage));

  const setExistingState = ({
    uuid,
    statusCommit,
    statusScore,
    assessmentOpenDate,
    assessmentCloseDate,
    shouldOpenAssessmentStart,
    shouldCloseAssessmentEnd,
    shouldCloseAtCompletion
  }) => {
    assessmentDispatch({
      type: 'setExistingAssessment',
      payload: {
        uuid,
        statusCommit,
        statusScore,
        assessmentOpenDate,
        assessmentCloseDate,
        shouldOpenAssessmentStart,
        shouldCloseAssessmentEnd,
        shouldCloseAtCompletion
      }
    });
  };

  const section = _.get(assessment, 'part.section', null);

  const course = _.get(section, 'course', null);

  useEffect(() => {
    dispatch(doGetAssessment(assessmentUuid));
  }, [dispatch, assessmentUuid]);

  useEffect(() => {
    setExistingState({
      uuid: assessment.uuid,
      statusCommit: assessment.statusCommit,
      statusScore: assessment.statusScore,
      assessmentOpenDate: assessment.assessmentOpenDate,
      assessmentCloseDate: assessment.assessmentCloseDate,
      shouldOpenAssessmentStart: assessment.shouldOpenAssessmentStart,
      shouldCloseAssessmentEnd: assessment.shouldCloseAssessmentEnd,
      shouldCloseAtCompletion: assessment.shouldCloseAtCompletion
    });
  }, [
    assessment.uuid,
    assessment.statusCommit,
    assessment.statusScore,
    assessment.assessmentOpenDate,
    assessment.assessmentCloseDate,
    assessment.shouldOpenAssessmentStart,
    assessment.shouldCloseAssessmentEnd,
    assessment.shouldCloseAtCompletion
  ]);

  const handleChangeOpen = event => {
    const selectedOption = event.target.value;
    switch (selectedOption) {
      case 'openNow': {
        const currentMoment = moment().toISOString();
        assessmentDispatch({
          type: 'setOpenDate',
          payload: {
            assessmentOpenDate: currentMoment,
            selectedRadioOpen: selectedOption
          }
        });
        break;
      }
      case 'openAssessment': {
        assessmentDispatch({
          type: 'setOpenAssessmentStart',
          payload: {
            selectedRadioOpen: selectedOption
          }
        });
        break;
      }
      case 'openSetDate': {
        assessmentDispatch({
          type: 'setSpecificOpenDate',
          payload: {
            selectedRadioOpen: selectedOption
          }
        });
        break;
      }
      case 'closeIndefinite': {
        const currentMoment = moment().toISOString();
        assessmentDispatch({
          type: 'setCloseIndefinite',
          payload: {
            assessmentCloseDate: currentMoment,
            selectedRadioOpen: selectedOption
          }
        });
        break;
      }
      default:
        console.log('error');
    }
  };

  const handleChangeClose = event => {
    const selectedOption = event.target.value;
    switch (selectedOption) {
      case 'openIndefinite': {
        const currentMoment = moment().toISOString();
        assessmentDispatch({
          type: 'setOpenIndefinite',
          payload: {
            assessmentOpenDate: currentMoment,
            selectedRadioClose: selectedOption
          }
        });
        break;
      }
      case 'closeAssessment': {
        assessmentDispatch({
          type: 'setCloseAssessmentEnd',
          payload: {
            selectedRadioClose: selectedOption
          }
        });
        break;
      }
      case 'closeSetDate': {
        assessmentDispatch({
          type: 'setSpecificCloseDate',
          payload: {
            selectedRadioClose: selectedOption
          }
        });
        break;
      }
      case 'closeSubmission': {
        assessmentDispatch({
          type: 'setCloseSubmission',
          payload: {
            selectedRadioClose: selectedOption
          }
        });
        break;
      }

      default:
        console.log('error');
    }
  };

  const handleCommit = () => {
    assessmentDispatch({
      type: 'setStatusCommitted'
    });
  };

  const handleUncommit = () => {
    assessmentDispatch({ type: 'setStatusUncommitted' });
  };

  // eslint-disable-next-line no-unused-vars
  const handleDatePickerChange = (type, newDate) => {
    if (type === 'startDate') {
      assessmentDispatch({
        type: 'setDatePickerOpen',
        payload: { date: newDate }
      });
    } else {
      assessmentDispatch({
        type: 'setDatePickerClose',
        payload: { date: newDate }
      });
    }
  };

  const {
    statusScore,
    statusCommit,
    assessmentOpenDate,
    assessmentCloseDate,
    datePickerOpen,
    datePickerClose,
    openSpecificDate,
    closeSpecificDate,
    shouldOpenAssessmentStart,
    shouldCloseAssessmentEnd,
    shouldCloseAtCompletion,
    selectedRadioOpen,
    selectedRadioClose,
    justificationMessage
  } = state;

  const setValidationErrors = (
    datePickerOpen,
    datePickerClose,
    openSpecificDate,
    closeSpecificDate,
    selectedRadioOpen,
    selectedRadioClose
  ) => {
    let missingOptionError = {
      invalid: false
    };
    let openDatePickerError = {
      invalid: false
    };
    let closeDatePickerError = {
      invalid: false
    };

    if (openSpecificDate) {
      openDatePickerError =
        !datePickerOpen || datePickerOpen === ''
          ? {
              invalid: true,
              isFormatError: false,
              message: `Please select an open date and time`
            }
          : {
              invalid: false
            };
    }

    if (closeSpecificDate) {
      closeDatePickerError =
        !datePickerClose || datePickerClose === ''
          ? {
              invalid: true,
              isFormatError: false,
              message: `Please select a close date and time`
            }
          : {
              invalid: false
            };
    }

    if (openDatePickerError.invalid) {
      onGeneralErrorNotification(openDatePickerError.message);
    }

    if (closeDatePickerError.invalid) {
      onGeneralErrorNotification(closeDatePickerError.message);
    }

    if (selectedRadioOpen === 'closeIndefinite') {
      if (selectedRadioClose) {
        missingOptionError = {
          invalid: true,
          isFormatError: false,
          message: `A close date should not be provided if closing assessment indefinitely. Please refresh and try again.`
        };
      }
    } else if (!selectedRadioClose || selectedRadioClose === '') {
      missingOptionError = {
        invalid: true,
        isFormatError: false,
        message: `You must select a close option or close the assessment indefinitely`
      };
    }

    const newValidationErrors = {
      openDatePickerError,
      closeDatePickerError,
      missingOptionError
    };

    return newValidationErrors;
  };

  const handleStatusScoreSubmit = () => {
    const newValidationErrors = setValidationErrors(
      datePickerOpen,
      datePickerClose,
      openSpecificDate,
      closeSpecificDate,
      selectedRadioOpen,
      selectedRadioClose
    );

    if (allFieldsAreValid(newValidationErrors)) {
      const newAssessmentOpenDate = openSpecificDate
        ? moment(datePickerOpen).toISOString()
        : assessmentOpenDate;
      const newAssessmentCloseDate = closeSpecificDate
        ? moment(datePickerClose).toISOString()
        : assessmentCloseDate;

      const assessmentPayload = {
        uuid: assessmentUuid,
        assessmentOpenDate: newAssessmentOpenDate,
        assessmentCloseDate: newAssessmentCloseDate,
        shouldOpenAssessmentStart,
        shouldCloseAssessmentEnd,
        shouldCloseAtCompletion
      };

      dispatch(doPutAssessmentStatusScore(assessmentPayload));
    }
  };

  const handleStatusCommitSubmit = forceGrading => {
    const assessmentPayload = {
      uuid: assessmentUuid,
      statusCommit,
      forceGrading,
      justificationMessage:
        statusCommit === 'COMMITTED' ? null : justificationMessage
    };

    dispatch(doPutAssessmentStatusCommit(assessmentPayload));
  };

  const handleJustificationChange = event => {
    assessmentDispatch({
      type: 'setJustificationMessage',
      payload: {
        justificationMessage: event.target.value
      }
    });
  };

  const handleResetCommitment = () => {
    if (assessment?.uuid)
      setExistingState({
        uuid: assessment.uuid,
        statusCommit: assessment.statusCommit,
        statusScore: assessment.statusScore,
        assessmentOpenDate: assessment.assessmentOpenDate,
        assessmentCloseDate: assessment.assessmentCloseDate,
        shouldOpenAssessmentStart: assessment.shouldOpenAssessmentStart,
        shouldCloseAssessmentEnd: assessment.shouldCloseAssessmentEnd,
        shouldCloseAtCompletion: assessment.shouldCloseAtCompletion
      });
  };

  const handleResetJustification = () => {
    assessmentDispatch({
      type: 'resetJustification'
    });
  };

  const courseNumber = _.get(course, 'course_master.courseNumber', '');
  const courseTitle = _.get(course, 'course_master.title', '');
  const sectionNumber = _.get(section, 'sectionNumber', '')
    .toString()
    .padStart(2, 0);
  const partNumber = _.get(assessment, 'part.partNumber', '')
    .toString()
    .padStart(2, 0);
  const assessmentType = _.get(assessment, 'assessment_type.name');

  return (
    <StatusSettings
      selectedRadioOpen={selectedRadioOpen}
      selectedRadioClose={selectedRadioClose}
      assessment={assessment}
      justificationMessage={justificationMessage}
      handleJustificationChange={handleJustificationChange}
      handleResetCommitment={handleResetCommitment}
      handleResetJustification={handleResetJustification}
      assessmentTitle={assessment.title}
      courseNumber={courseNumber}
      courseTitle={courseTitle}
      sectionNumber={sectionNumber}
      partNumber={partNumber}
      assessmentType={assessmentType}
      statusScore={statusScore}
      openSpecificDate={openSpecificDate}
      closeSpecificDate={closeSpecificDate}
      datePickerOpen={datePickerOpen}
      datePickerClose={datePickerClose}
      statusCommit={statusCommit}
      handleChangeOpen={handleChangeOpen}
      handleChangeClose={handleChangeClose}
      handleCommit={handleCommit}
      handleUncommit={handleUncommit}
      handleDatePickerChange={handleDatePickerChange}
      handleStatusScoreSubmit={handleStatusScoreSubmit}
      handleStatusCommitSubmit={handleStatusCommitSubmit}
    />
  );
}

StatusSettingsContainer.propTypes = {
  match: PropTypes.object
};

StatusSettingsContainer.defaultProps = {
  match: {}
};
