import {
  DELETE_APP_ID,
  DELETE_APP_ID_ERROR,
  DELETE_APP_ID_RESET,
  DELETE_APP_ID_SUCCESS,
  DELETE_CREDENTIAL,
  DELETE_CREDENTIAL_ERROR,
  DELETE_CREDENTIAL_RESET,
  DELETE_CREDENTIAL_SUCCESS,
  DELETE_SCORES,
  DELETE_SCORES_ERROR,
  DELETE_SCORES_SUCCESS,
  GET_COHORT_ASSESSMENTS_STUDENT,
  GET_COHORT_ASSESSMENTS_STUDENT_ERROR,
  GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS,
  GET_COMPETENCY_COLLECTION_AND_SCORES,
  GET_COMPETENCY_COLLECTION_AND_SCORES_ERROR,
  GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS,
  GET_SCORES,
  GET_SCORES_ERROR,
  GET_SCORES_SUCCESS,
  GET_USERS_BY_SEARCH,
  GET_USERS_BY_SEARCH_ERROR,
  GET_USERS_BY_SEARCH_INSTITUTION_ID,
  GET_USERS_BY_SEARCH_INSTITUTION_ID_ERROR,
  GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS,
  GET_USERS_BY_SEARCH_ROLES,
  GET_USERS_BY_SEARCH_ROLES_ERROR,
  GET_USERS_BY_SEARCH_ROLES_SUCCESS,
  GET_USERS_BY_SEARCH_SUCCESS,
  POST_CLONE_TRANSCRIPT,
  POST_CLONE_TRANSCRIPT_ERROR,
  POST_CLONE_TRANSCRIPT_RESET,
  POST_CLONE_TRANSCRIPT_SUCCESS,
  POST_CREDENTIAL,
  POST_CREDENTIAL_ERROR,
  POST_CREDENTIAL_RESET,
  POST_CREDENTIAL_SUCCESS,
  POST_INSTITUTION_RIGOR,
  POST_INSTITUTION_RIGOR_ERROR,
  POST_INSTITUTION_RIGOR_RESET,
  POST_INSTITUTION_RIGOR_SUCCESS,
  POST_SCORE,
  POST_SCORE_ERROR,
  POST_SCORE_RESET,
  POST_SCORE_SUCCESS,
  POST_TRANSCRIPT,
  POST_TRANSCRIPT_ERROR,
  POST_TRANSCRIPT_RESET,
  POST_TRANSCRIPT_SUCCESS,
  POST_USER_APP_ID,
  POST_USER_APP_ID_ERROR,
  POST_USER_APP_ID_RESET,
  POST_USER_APP_ID_SUCCESS,
  POST_USER_COHORT,
  POST_USER_COHORT_ERROR,
  POST_USER_COHORT_RESET,
  POST_USER_COHORT_SUCCESS,
  POST_USER_ROLE,
  POST_USER_ROLE_ERROR,
  POST_USER_ROLE_RESET,
  POST_USER_ROLE_SUCCESS,
  PUT_CREDENTIAL,
  PUT_CREDENTIAL_ERROR,
  PUT_CREDENTIAL_RESET,
  PUT_CREDENTIAL_SUCCESS,
  PUT_SCORE,
  PUT_SCORE_ERROR,
  PUT_SCORE_SUCCESS,
  PUT_TRANSCRIPT,
  PUT_TRANSCRIPT_ERROR,
  PUT_TRANSCRIPT_RESET,
  PUT_TRANSCRIPT_SUCCESS
} from '../actions-type';

export const INITIAL_STATE = {
  createAppId: false,
  createCloneTranscript: false,
  createCredential: false,
  createRole: false,
  createScore: false,
  createScoreSuccess: false,
  createInstitutionRigor: false,
  deleteCredential: false,
  readScores: false,
  readUsersByInstitutionId: false,
  readUsersByInstitutionIdSuccess: false,
  updateCredential: false,
  updateScore: false,
  deleteAppId: false,
  deleteAppIdResult: undefined,
  fetchingCohortAssessments: false,
  fetchingCompetencyCollectionsAndScores: false
};

const crudReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SCORES: {
      return {
        ...state,
        readScores: true
      };
    }
    case GET_SCORES_SUCCESS: {
      return {
        ...state,
        readScores: false
      };
    }
    case GET_SCORES_ERROR: {
      return {
        ...state,
        readScores: false
      };
    }

    case GET_USERS_BY_SEARCH_INSTITUTION_ID: {
      return {
        ...state,
        readUsersByInstitutionId: true
      };
    }
    case GET_USERS_BY_SEARCH_INSTITUTION_ID_SUCCESS: {
      return {
        ...state,
        readUsersByInstitutionId: false,
        readUsersByInstitutionIdSuccess: true
      };
    }
    case GET_USERS_BY_SEARCH_INSTITUTION_ID_ERROR: {
      return {
        ...state,
        readUsersByInstitutionId: false,
        readUsersByInstitutionIdSuccess: false
      };
    }
    case GET_USERS_BY_SEARCH: {
      return {
        ...state,
        readUsersBySearch: true
      };
    }
    case GET_USERS_BY_SEARCH_SUCCESS: {
      return {
        ...state,
        readUsersBySearch: false,
        readUsersBySearchSuccess: true
      };
    }
    case GET_USERS_BY_SEARCH_ERROR: {
      return {
        ...state,
        readUsersBySearch: false,
        readUsersBySearchSuccess: false
      };
    }
    case GET_USERS_BY_SEARCH_ROLES: {
      return {
        ...state,
        readUsersBySearchRoles: true
      };
    }
    case GET_USERS_BY_SEARCH_ROLES_SUCCESS: {
      return {
        ...state,
        readUsersBySearchRoles: false,
        readUsersBySearchRolesSuccess: true
      };
    }
    case GET_USERS_BY_SEARCH_ROLES_ERROR: {
      return {
        ...state,
        readUsersBySearchRoles: false,
        readUsersBySearchRolesSuccess: false
      };
    }
    case POST_USER_COHORT: {
      return {
        ...state,
        createUserCohort: true
      };
    }
    case POST_USER_COHORT_SUCCESS: {
      return {
        ...state,
        createUserCohort: false,
        createUserCohortSuccess: true
      };
    }
    case POST_USER_COHORT_ERROR: {
      return {
        ...state,
        createUserCohort: false,
        createUserCohortSuccess: false
      };
    }
    case POST_USER_COHORT_RESET: {
      return {
        ...state,
        createUserCohort: false,
        createUserCohortSuccess: false
      };
    }
    case POST_SCORE: {
      return {
        ...state,
        createScore: true
      };
    }
    case POST_SCORE_SUCCESS: {
      return {
        ...state,
        createScore: false,
        createScoreSuccess: true
      };
    }
    case POST_SCORE_ERROR: {
      return {
        ...state,
        createScore: false,
        createScoreSuccess: false
      };
    }
    case POST_SCORE_RESET: {
      return {
        ...state,
        createScore: false,
        createScoreSuccess: false
      };
    }
    case PUT_SCORE: {
      return {
        ...state,
        updateScore: true
      };
    }
    case PUT_SCORE_SUCCESS: {
      return {
        ...state,
        updateScore: false
      };
    }
    case PUT_SCORE_ERROR: {
      return {
        ...state,
        updateScore: false
      };
    }
    case POST_TRANSCRIPT: {
      return {
        ...state,
        createTranscript: true
      };
    }
    case POST_TRANSCRIPT_SUCCESS: {
      return {
        ...state,
        createTranscript: false,
        createTranscriptSuccess: true
      };
    }
    case POST_TRANSCRIPT_ERROR: {
      return {
        ...state,
        createTranscript: false,
        createTranscriptSuccess: false
      };
    }
    case POST_TRANSCRIPT_RESET: {
      return {
        ...state,
        createTranscript: false,
        createTranscriptSuccess: false
      };
    }
    case PUT_TRANSCRIPT: {
      return {
        ...state,
        updateTranscript: true,
        updateTranscriptSuccess: false
      };
    }
    case PUT_TRANSCRIPT_SUCCESS: {
      return {
        ...state,
        updateTranscript: false,
        updateTranscriptSuccess: true
      };
    }
    case PUT_TRANSCRIPT_ERROR: {
      return {
        ...state,
        updateTranscript: false,
        updateTranscriptSuccess: false
      };
    }
    case PUT_TRANSCRIPT_RESET: {
      return {
        ...state,
        updateTranscript: false,
        updateTranscriptSuccess: false
      };
    }
    case POST_USER_ROLE: {
      return {
        ...state,
        createRole: true,
        createRoleResult: undefined
      };
    }
    case POST_USER_ROLE_SUCCESS: {
      return {
        ...state,
        createRole: false,
        createRoleResult: 'success'
      };
    }
    case POST_USER_ROLE_ERROR: {
      return {
        ...state,
        createRole: false,
        createRoleResult: 'fail'
      };
    }

    case POST_USER_ROLE_RESET: {
      return {
        ...state,
        createRole: false,
        createRoleResult: undefined
      };
    }

    case POST_USER_APP_ID: {
      return {
        ...state,
        createAppId: true,
        createAppIdResult: undefined
      };
    }
    case POST_USER_APP_ID_SUCCESS: {
      return {
        ...state,
        createAppId: false,
        createAppIdResult: 'success'
      };
    }
    case POST_USER_APP_ID_ERROR: {
      return {
        ...state,
        createAppId: false,
        createAppIdResult: 'fail'
      };
    }

    case POST_USER_APP_ID_RESET: {
      return {
        ...state,
        createAppId: false,
        createAppIdResult: undefined
      };
    }

    case DELETE_APP_ID: {
      return {
        ...state,
        deleteAppId: true,
        deleteAppIdResult: undefined,
        deletedUserUuid: action?.payload?.userUuid
      };
    }
    case DELETE_APP_ID_SUCCESS: {
      return {
        ...state,
        deleteAppId: false,
        deleteAppIdResult: 'success',
        deletedUserUuid: undefined
      };
    }
    case DELETE_APP_ID_ERROR: {
      return {
        ...state,
        deleteAppId: false,
        deleteAppIdResult: 'fail',
        deletedUserUuid: undefined
      };
    }

    case DELETE_APP_ID_RESET: {
      return {
        ...state,
        deleteAppId: false,
        deleteAppIdResult: undefined,
        deletedUserUuid: undefined
      };
    }

    case PUT_CREDENTIAL: {
      return {
        ...state,
        updateCredential: true,
        updateCredentialResult: undefined
      };
    }
    case PUT_CREDENTIAL_SUCCESS: {
      return {
        ...state,
        updateCredential: false,
        updateCredentialResult: 'success'
      };
    }
    case PUT_CREDENTIAL_ERROR: {
      return {
        ...state,
        updateCredential: false,
        updateCredentialResult: 'fail'
      };
    }
    case PUT_CREDENTIAL_RESET: {
      return {
        ...state,
        updateCredential: false,
        updateCredentialResult: undefined
      };
    }

    case POST_CREDENTIAL: {
      return {
        ...state,
        createCredential: true,
        createCredentialResult: undefined
      };
    }
    case POST_CREDENTIAL_SUCCESS: {
      return {
        ...state,
        createCredential: false,
        createCredentialResult: 'success'
      };
    }
    case POST_CREDENTIAL_ERROR: {
      return {
        ...state,
        createCredential: false,
        createCredentialResult: 'fail'
      };
    }

    case POST_CREDENTIAL_RESET: {
      return {
        ...state,
        createCredential: false,
        createCredentialResult: undefined
      };
    }

    case DELETE_CREDENTIAL: {
      return {
        ...state,
        deleteCredential: true,
        deleteCredentialResult: undefined
      };
    }
    case DELETE_CREDENTIAL_SUCCESS: {
      return {
        ...state,
        deleteCredential: false,
        deleteCredentialResult: 'success'
      };
    }
    case DELETE_CREDENTIAL_ERROR: {
      return {
        ...state,
        deleteCredential: false,
        deleteCredentialResult: 'fail'
      };
    }

    case DELETE_CREDENTIAL_RESET: {
      return {
        ...state,
        deleteCredential: false,
        deleteCredentialResult: undefined
      };
    }

    case POST_CLONE_TRANSCRIPT: {
      return {
        ...state,
        createCloneTranscript: true,
        createCloneTranscriptResult: undefined
      };
    }
    case POST_CLONE_TRANSCRIPT_SUCCESS: {
      return {
        ...state,
        createCloneTranscript: false,
        createCloneTranscriptResult: 'success'
      };
    }
    case POST_CLONE_TRANSCRIPT_ERROR: {
      return {
        ...state,
        createCloneTranscript: false,
        createCloneTranscriptResult: 'fail'
      };
    }

    case POST_CLONE_TRANSCRIPT_RESET: {
      return {
        ...state,
        createCloneTranscript: false,
        createCloneTranscriptResult: undefined
      };
    }

    case POST_INSTITUTION_RIGOR: {
      return {
        ...state,
        createInstitutionRigor: true,
        createInstitutionRigorResult: undefined
      };
    }
    case POST_INSTITUTION_RIGOR_SUCCESS: {
      return {
        ...state,
        createInstitutionRigor: false,
        createInstitutionRigorResult: 'success'
      };
    }
    case POST_INSTITUTION_RIGOR_ERROR: {
      return {
        ...state,
        createInstitutionRigor: false,
        createInstitutionRigorResult: 'fail'
      };
    }

    case POST_INSTITUTION_RIGOR_RESET: {
      return {
        ...state,
        createInstitutionRigor: false,
        createInstitutionRigorResult: undefined
      };
    }
    case GET_COHORT_ASSESSMENTS_STUDENT: {
      return {
        ...state,
        fetchingCohortAssessments: true
      };
    }
    case GET_COHORT_ASSESSMENTS_STUDENT_ERROR: {
      return {
        ...state,
        fetchingCohortAssessments: false
      };
    }
    case GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS: {
      return {
        ...state,
        fetchingCohortAssessments: false
      };
    }
    case GET_COMPETENCY_COLLECTION_AND_SCORES: {
      return {
        ...state,
        fetchingCompetencyCollectionsAndScores: true
      };
    }
    case GET_COMPETENCY_COLLECTION_AND_SCORES_ERROR: {
      return {
        ...state,
        fetchingCompetencyCollectionsAndScores: false
      };
    }
    case GET_COMPETENCY_COLLECTION_AND_SCORES_SUCCESS: {
      return {
        ...state,
        fetchingCompetencyCollectionsAndScores: false
      };
    }
    case DELETE_SCORES: {
      return {
        ...state,
        loadingDeleteScores: true
      };
    }
    case DELETE_SCORES_ERROR:
    case DELETE_SCORES_SUCCESS: {
      return {
        ...state,
        loadingDeleteScores: false
      };
    }
    default:
      return state;
  }
};

export default crudReducer;
