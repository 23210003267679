import React, {useMemo, forwardRef} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';

import Header from '../../Library/Header';
import {ContainerResize, ContainerPage} from '../../Library/Layout';
import StepListContainer from './Step/StepListContainer';
import FacultyListContainer from './Faculty/FacultyListContainer';
import TopicListContainer from './Topic/TopicListContainer';
import {ItemAnalysis} from './ItemAnalysis';
import { ButtonDelete, ButtonDownload, ButtonUpload } from './ScoresUpload/ScoresUpload';

const Actions = styled.div`
  background: ${props => props.theme.colors.white};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 77px;
`;

const TabsSC = styled(Tabs)`
  && {
    border-top: 1px solid ${props => props.theme.colors.grey[300]};
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
    background: ${props => props.theme.colors.white};
  }
`;

const TabSC = styled(Tab)`
  && {
    max-width: 100%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    line-height: 16px;
    width: 100%;
  }
`;

const LinkSC = styled(Link)`
  width: 100%;
`;

export default function Scores({
  assessmentBlocks,
  assessmentScores,
  assessmentType,
  assessmentTypeUuid,
  assessmentUuid,
  courseUuid,
  handleDeleteScoresOpen,
  handleUploadScoresOpen,
  handleUploadDelayedScoresOpen,
  handleDownloadScoresOpen,
  partFacultyUsers,
  partNumber,
  partUuid,
  path,
  pathname,
  stepScoreSummary,
  scoreType,
  sectionNumber,
  sectionUuid,
  students,
  title,
}) {
  const generatePath = useMemo(
    () => _(path).
      replace(':assessmentUuid', assessmentUuid).
      replace(':partUuid', partUuid).
      replace(':assessmentTypeUuid', assessmentTypeUuid).
      replace(':sectionUuid', sectionUuid),
    [path, assessmentUuid, partUuid, assessmentTypeUuid, sectionUuid],
  );

  const location = useMemo(() => {
    let loc = 0;

    if (pathname.endsWith('/steps')) {
      loc = 0;
    } else if (pathname.endsWith('/item-analysis')) {
      loc = 1;
    } else if (pathname.endsWith('/faculty')) {
      loc = 2;
    } else if (pathname.endsWith('/topics')) {
      loc = 3;
    }
    return loc;
  }, [pathname]);

  const renderLink = useMemo(
    () =>
      forwardRef((props, ref) => (
        // eslint-disable-next-line react/prop-types
        <LinkSC to={props.topath} {...props} ref={ref} />
      )),
    []
  );

  const loadingDeleteScores = useSelector(state => state.crudState.loadingDeleteScores);

  return (
    <ContainerResize>
      <Header
        title="Scores"
        subtitle={`${title} • Section ${sectionNumber} • Part ${partNumber} • ${assessmentType} • ${scoreType}`}
        backButtonLocation={`/syllabus-management/view/part/${partUuid}&courseUuid=${courseUuid}&sectionUuid=${sectionUuid}&partNumber=${partNumber}&sectionNumber=${sectionNumber}`}
      />
      <Actions>
        <div style={{padding: 10}}>
          <ButtonDownload onClick={handleDownloadScoresOpen}>
            <GetAppIcon />
            Download
          </ButtonDownload>
          <ButtonUpload onClick={handleUploadScoresOpen}>
            <PublishIcon />
            Upload
          </ButtonUpload>
          <ButtonDelete onClick={handleDeleteScoresOpen} disabled={loadingDeleteScores}>
            {loadingDeleteScores ? <CircularProgress style={{color: 'white', marginRight: 5}} size='20px' /> : <DeleteIcon /> }
            Delete
          </ButtonDelete>
        </div>
      </Actions>
      <TabsSC
        value={location}
        textColor="primary"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <TabSC
          component={renderLink}
          topath={`${generatePath}/steps`}
          label="step/item"
        />
        <TabSC
          component={renderLink}
          topath={`${generatePath}/item-analysis`}
          label="item analysis"
        />
        <TabSC
          component={renderLink}
          topath={`${generatePath}/faculty`}
          label="faculty"
        />
        <TabSC
          component={renderLink}
          topath={`${generatePath}/topics`}
          label="topic"
        />
      </TabsSC>
      <ContainerPage paddingTop="70px">
        <Switch>
          <Route
            path={`${generatePath}/steps`}
            component={props => {
              return (
                <StepListContainer
                  {...props}
                  partUuid={partUuid}
                  sectionUuid={sectionUuid}
                  scoreType={scoreType}
                  assessmentUuid={assessmentUuid}
                  assessmentType={assessmentType}
                  assessmentBlocks={assessmentBlocks}
                  handleUploadDelayedScoresOpen={handleUploadDelayedScoresOpen}
                />
              );
            }}
          />
          <Route
            path={`${generatePath}/faculty`}
            component={props => {
              return (
                <FacultyListContainer
                  {...props}
                  assessmentScores={assessmentScores}
                  partFacultyUsers={partFacultyUsers}
                  partUuid={partUuid}
                  scoreType={scoreType}
                  stepScoreSummary={stepScoreSummary}
                  students={students}
                />
              );
            }}
          />
          <Route
            path={`${generatePath}/topics`}
            component={props => {
              return (
                <TopicListContainer
                  {...props}
                  assessmentScores={assessmentScores}
                  assessmentType={assessmentType}
                  assessmentUuid={assessmentUuid}
                  partFacultyUsers={partFacultyUsers}
                  partUuid={partUuid}
                  scores={stepScoreSummary}
                  scoreType={scoreType}
                  sectionUuid={sectionUuid}
                  students={students}
                />
              );
            }}
          />
          <Route
            path={`${generatePath}/item-analysis`}
            component={props => {
              return (
                <ItemAnalysis
                  {...props}
                  assessmentUuid={assessmentUuid}
                />
              );
            }}
          />
        </Switch>
      </ContainerPage>
    </ContainerResize>
  );
}

Scores.propTypes = {
  assessmentBlocks: PropTypes.array,
  assessmentScores: PropTypes.array,
  assessmentType: PropTypes.string,
  assessmentTypeUuid: PropTypes.string,
  assessmentUuid: PropTypes.string,
  courseUuid: PropTypes.string,
  handleDeleteScoresOpen: PropTypes.func,
  handleUploadScoresOpen: PropTypes.func,
  handleUploadDelayedScoresOpen: PropTypes.func,
  handleDownloadScoresOpen: PropTypes.func.isRequired,
  partFacultyUsers: PropTypes.array,
  partNumber: PropTypes.number,
  partUuid: PropTypes.string,
  path: PropTypes.string,
  pathname: PropTypes.string,
  scoreType: PropTypes.string,
  sectionNumber: PropTypes.number,
  sectionUuid: PropTypes.string,
  stepScoreSummary: PropTypes.array,
  students: PropTypes.array,
  title: PropTypes.string,
};
Scores.defaultProps = {
  assessmentScores: [],
  assessmentBlocks: [],
  assessmentType: '',
  assessmentTypeUuid: '',
  assessmentUuid: '',
  courseUuid: '',
  handleDeleteScoresOpen: undefined,
  handleUploadScoresOpen: undefined,
  handleUploadDelayedScoresOpen: undefined,
  partFacultyUsers: [],
  partNumber: 0,
  partUuid: '',
  path: '',
  pathname: '',
  scoreType: '',
  sectionNumber: 0,
  sectionUuid: '',
  stepScoreSummary: [],
  students: [],
  title: '',
};
