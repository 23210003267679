import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { selectCategoryRank } from '../../../../redux/selectors/applicantRankSelectors';

export default function RankCategory({ userUuid, cohortUuid, category }) {
  const categoryRank = useSelector(state =>
    selectCategoryRank(state, userUuid, cohortUuid, category)
  );

  const rank =
    categoryRank?.cohortRankRelative && categoryRank?.cohortRankPotential
      ? `${categoryRank?.cohortRankRelative} of ${categoryRank?.cohortRankPotential}`
      : 'N/A';

  return <>{rank}</>;
}

RankCategory.propTypes = {
  userUuid: PropTypes.string,
  cohortUuid: PropTypes.string,
  category: PropTypes.string
};

RankCategory.defaultProps = {
  userUuid: undefined,
  cohortUuid: undefined,
  category: undefined
};
