export const GET_ACADEMIC_YEAR = 'GET_ACADEMIC_YEAR';
export const GET_ACADEMIC_YEAR_SUCCESS = 'GET_ACADEMIC_YEAR_SUCCESS';
export const GET_ACADEMIC_YEAR_ERROR = 'GET_ACADEMIC_YEAR_ERROR';
export const GET_ACADEMIC_YEARS_BY_SCHOOL = 'GET_ACADEMIC_YEARS_BY_SCHOOL';
export const GET_ACADEMIC_YEARS_BY_SCHOOL_SUCCESS =
  'GET_ACADEMIC_YEARS_BY_SCHOOL_SUCCESS';
export const GET_ACADEMIC_YEARS_BY_SCHOOL_ERROR =
  'GET_ACADEMIC_YEARS_BY_SCHOOL_ERROR';

export const POST_ACADEMIC_YEAR = 'POST_ACADEMIC_YEAR';
export const POST_ACADEMIC_YEAR_SUCCESS = 'POST_ACADEMIC_YEAR_SUCCESS';
export const POST_ACADEMIC_YEAR_ERROR = 'POST_ACADEMIC_YEAR_ERROR';

export const PUT_ACADEMIC_YEAR = 'PUT_ACADEMIC_YEAR';
export const PUT_ACADEMIC_YEAR_SUCCESS = 'PUT_ACADEMIC_YEAR_SUCCESS';
export const PUT_ACADEMIC_YEAR_ERROR = 'PUT_ACADEMIC_YEAR_ERROR';

export const DELETE_ACADEMIC_YEAR = 'DELETE_ACADEMIC_YEAR';
export const DELETE_ACADEMIC_YEAR_SUCCESS = 'DELETE_ACADEMIC_YEAR_SUCCESS';
export const DELETE_ACADEMIC_YEAR_ERROR = 'DELETE_ACADEMIC_YEAR_ERROR';
