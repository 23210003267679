import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Subtitle2 } from '@xcomp/xcomp-design-library';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import AssesmentBlocks from '../../Data/AssessmentBlocks';
import { formatMMddyy } from '../../../../../helpers/date-fns.utils';

const BlockTitles = styled.div`
  padding-left: 132px;
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
  margin-top: 2rem;
`;

const BlockTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width};
  ${props => props.autoWidth && `width: calc((100% - 20% - 230px)/2);`}
  font-weight: 600;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-bottom: 10px;
`;

const BlockRow = styled.div`
  padding-left: 132px;
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
  align-items: center;
`;

export const StatusLine = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.grey[300]};
  }
`;

export const StatusLabel = styled(Subtitle2)`
  color: ${props => props.theme.fontColors.darker};
  position: relative;
  display: inline-block;
  z-index: 100;
  background: ${props => props.theme.colors.white};
  padding-right: 0.5rem;
  font-weight: 600;
`;

const AssessmentRow = styled.div`
  width: 100%;
`;

const ChipSC = styled(Chip)`
  && {
    background: ${props => props.theme.colors.status.warning};
    color: ${props => props.theme.colors.white};
    text-transform: capitalize;
  }
`;

const Input = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width};
  ${props => props.autoWidth && `width: calc((100% - 20% - 230px)/2);`}
  padding-right: 10px;
  white-space: nowrap;
`;

const HighLight = styled.div`
  border: 2px solid red;
  background: yellow;
`;

export default function Pending({ pending }) {
  return (
    <>
      {pending?.length > 0 && (
        <BlockTitles>
          <BlockTitle autoWidth>Assessment</BlockTitle>
          <BlockTitle width="50px">Block</BlockTitle>
          <BlockTitle autoWidth>Block Title</BlockTitle>
          <BlockTitle width="10%">Status</BlockTitle>
          <BlockTitle width="10%">Date</BlockTitle>
          <BlockTitle width="60px">Attempt</BlockTitle>
          <BlockTitle width="70px">Score</BlockTitle>
          <BlockTitle width="50px">Grade</BlockTitle>
        </BlockTitles>
      )}
      {pending?.map(record => (
        <AssessmentRow key={record?.assessment?.uuid}>
          {record?.assessmentBlocks?.map(blockRecord => (
            <BlockRow key={blockRecord?.blockResult?.uuid}>
              <Input autoWidth>
                <Tooltip
                  arrow
                  title={`${record?.assessment?.title}`}
                  placement="top"
                >
                  <span>{record?.assessment?.title}</span>
                </Tooltip>
              </Input>
              <Input width="50px">
                <AssesmentBlocks
                  blockNumber={blockRecord?.block?.blockNumber}
                  assessmentUuid={record?.assessment?.uuid}
                />
              </Input>
              <Input autoWidth>
                <Tooltip
                  arrow
                  title={`${blockRecord?.block?.title}`}
                  placement="top"
                >
                  <span>{blockRecord?.block?.title}</span>
                </Tooltip>
              </Input>
              <Input width="10%">
                <ChipSC size="small" label={blockRecord?.blockResult?.status} />
              </Input>
              <Input width="10%">
                {formatMMddyy(_.get(record, ['scores', '0', 'date'], null))}
              </Input>
              <Input width="60px">
                <HighLight>
                  {`${_.get(
                    blockRecord,
                    ['blockResult', 'blockAttempt'],
                    'N/A'
                  )} of ${_.get(blockRecord, 'blockResultAttempts', 'N/A')}`}
                </HighLight>
              </Input>
              <Input width="70px">
                {blockRecord?.resultCard?.assessmentRelativeScore}
              </Input>
              <Input width="50px">
                {blockRecord?.resultCard?.calculatedGrade}
              </Input>
            </BlockRow>
          ))}
        </AssessmentRow>
      ))}
    </>
  );
}

Pending.propTypes = {
  pending: PropTypes.array
};

Pending.defaultProps = {
  pending: undefined
};
