import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import _ from 'lodash';
import Decimal from 'decimal.js-light';
import {
  formatLastName,
  formatFirstNameMiddleName
} from '../../helpers/format/user.format';
import { sortByFormattedFullName } from '../../helpers/utilities';
import { orm } from '../models';

export const scoresStepSelector = createSelector(
  state => state,
  (_, assessmentUuid, sectionUuid) => ({
    assessmentUuid,
    sectionUuid
  }),
  (redux, data) => {
    const scoresSelectorORM = ormCreateSelector([orm], session => {
      const scores = session.Score.all()
        .filter(score => score.assessmentUuid === data.assessmentUuid)
        .toRefArray();

      const studentScores = _.groupBy(scores, 'studentUuid');

      const scoresByBlock = _.groupBy(scores, 'assessmentBlockUuid');
      const totalPotPerBlock = {};

      // eslint-disable-next-line
      Object.keys(scoresByBlock).map(sbb => {
        totalPotPerBlock[sbb] = scoresByBlock[sbb].filter(s => s.resultCardUuid === scoresByBlock[sbb][0].resultCardUuid).reduce((acc, value) => acc.plus(new Decimal(value.potValue)), new Decimal(0));
      });

      const totalPot = Object.values(totalPotPerBlock).reduce((acc, value) => acc.plus(value), new Decimal(0));

      const userSection = session.UserSection.all()
        .filter(userSection => userSection.sectionUuid === data.sectionUuid)
        .toRefArray();

      const studentsInSection = userSection.map(student => student.userUuid);

      const studentsScoresLoaded = _.keys(studentScores);

      const studentsUnreported = _.difference(
        studentsInSection,
        studentsScoresLoaded
      );

      const studentsWithoutScores = {};

      if (studentsUnreported.length > 0) {
        studentsUnreported.forEach(studentUuid => {
          const user = session.User.withId(studentUuid);
          const userReference = user ? { ...user.ref } : undefined;

          const formattedLastName = formatLastName(
            userReference.lastName,
            userReference.marriedLastName,
            userReference.suffix
          );

          const formattedFirstMiddleName = formatFirstNameMiddleName(
            userReference.firstName,
            userReference.middleName
          );

          const result = {
            totalRel: 0,
            fullName: `${formattedLastName}, ${formattedFirstMiddleName}`,
            studentUuid,
            unreported: true
          };

          studentsWithoutScores[studentUuid] = result;
        });
      }

      const studentsWithScores =
        _.mapValues(studentScores, (scores, studentUuid) => {
          const user = session.User.withId(studentUuid);
          const userReference = user ? { ...user.ref } : undefined;

          const formattedLastName = formatLastName(
            userReference.lastName,
            userReference.marriedLastName,
            userReference.suffix
          );

          const formattedFirstMiddleName = formatFirstNameMiddleName(
            userReference.firstName,
            userReference.middleName
          );

          const totalRel = _.reduce(scores, (acc, score) => acc.plus(new Decimal(score.relValue)), new Decimal(0));

          const result = {
            totalRel: totalRel.toNumber(),
            totalPot: totalPot.toNumber(),
            fullName: `${formattedLastName}, ${formattedFirstMiddleName}`,
            firstName: userReference.firstName,
            middleName: userReference.middleName,
            lastName: userReference.lastName,
            marriedLastName: userReference.marriedLastName,
            studentUuid,
            unreported: false
          };

          return result;
        }) || {};

      const combined = { ...studentsWithScores, ...studentsWithoutScores };
      const combinedArr = _.values(combined);
      const sorted = [...combinedArr].sort((a, b) =>
        sortByFormattedFullName(a, b)
      );
      return sorted;
    });

    const scores = scoresSelectorORM(redux);

    return scores;
  }
);

export const cohortAssessmentScoresStepSelector = createSelector(
  state => state,
  (_, assessmentUuid, cohortUuid) => ({
    assessmentUuid,
    cohortUuid
  }),
  (redux, data) => {
    const scoresSelectorORM = ormCreateSelector([orm], session => {
      const scores = session.Score.all()
        .filter(score => score.assessmentUuid === data.assessmentUuid)
        .toRefArray();

      const studentScores = _.groupBy(scores, 'studentUuid');

      const userCohorts = session.UserCohort.all()
        .filter(
          userCohort =>
            userCohort.cohortUuid === data.cohortUuid &&
            userCohort.role === 'student'
        )
        .toRefArray();

      const studentsInCohort = userCohorts.map(student => student.userUuid);

      const studentsScoresLoaded = _.keys(studentScores);

      const studentsUnreported = _.difference(
        studentsInCohort,
        studentsScoresLoaded
      );

      const studentsWithoutScores = {};

      if (studentsUnreported.length > 0) {
        studentsUnreported.forEach(studentUuid => {
          const user = session.User.withId(studentUuid);
          const userReference = user ? { ...user.ref } : undefined;

          const formattedLastName = formatLastName(
            userReference?.lastName,
            userReference?.marriedLastName,
            userReference?.suffix
          );

          const formattedFirstMiddleName = formatFirstNameMiddleName(
            userReference?.firstName,
            userReference?.middleName
          );

          const result = {
            totalRel: 0,
            fullName: `${formattedLastName}, ${formattedFirstMiddleName}`,
            studentUuid,
            unreported: true
          };

          studentsWithoutScores[studentUuid] = result;
        });
      }

      const studentsWithScores =
        _.mapValues(studentScores, (scores, studentUuid) => {
          const user = session.User.withId(studentUuid);
          const userReference = user ? { ...user.ref } : undefined;

          const formattedLastName = formatLastName(
            userReference.lastName,
            userReference.marriedLastName,
            userReference.suffix
          );

          const formattedFirstMiddleName = formatFirstNameMiddleName(
            userReference.firstName,
            userReference.middleName
          );

          const { totalRel, totalPot } = _.reduce(
            scores,
            (acc, score) => {
              const decimalTotalRel = new Decimal(score.relValue);
              const decimalTotalPot = new Decimal(score.potValue);
              return {
                totalRel: acc.totalRel.plus(decimalTotalRel),
                totalPot: acc.totalPot.plus(decimalTotalPot)
              };
            },
            {
              totalRel: new Decimal(0),
              totalPot: new Decimal(0)
            }
          );

          const result = {
            totalRel: totalRel.toNumber(),
            totalPot: totalPot.toNumber(),
            fullName: `${formattedLastName}, ${formattedFirstMiddleName}`,
            firstName: userReference.firstName,
            middleName: userReference.middleName,
            lastName: userReference.lastName,
            marriedLastName: userReference.marriedLastName,
            studentUuid,
            unreported: false
          };

          return result;
        }) || {};

      const combined = { ...studentsWithScores, ...studentsWithoutScores };
      const combinedArr = _.values(combined);
      const sorted = [...combinedArr].sort((a, b) =>
        sortByFormattedFullName(a, b)
      );
      return sorted;
    });

    const scores = scoresSelectorORM(redux);

    return scores;
  }
);

export const scoresByFaculty = createSelector(
  state => state,
  (_, studentUuid, facultyUuid, assessmentUuid) => ({
    studentUuid,
    facultyUuid,
    assessmentUuid
  }),
  (redux, data) => {
    const { studentUuid, facultyUuid, assessmentUuid } = data;

    const scoresSelectorORM = ormCreateSelector(orm.Score);

    const scores = scoresSelectorORM(redux).filter(
      score =>
        (score.assessmentUuid === assessmentUuid &&
          score.studentUuid === studentUuid &&
          score.grader1Uuid === facultyUuid) ||
        score.grader2Uuid === facultyUuid ||
        score.grader3Uuid === facultyUuid
    );

    return scores;
  }
);

export const partScoresSelector = createSelector(
  state => state,
  (_, partUuid) => ({
    partUuid
  }),
  (redux, data) => {
    const { partUuid } = data;

    const scoresSelectorORM = ormCreateSelector(orm.Score);

    const scores = scoresSelectorORM(redux).filter(
      score => score.assessment?.partUuid === partUuid
    );

    return scores;
  }
);

export const partScoresByAssessmentTypesAndTechniquesSelector = createSelector(
  state => state,
  (_, partUuid, assessmentTypes, assessmentTechniques) => ({
    partUuid,
    assessmentTypes,
    assessmentTechniques
  }),
  (redux, data) => {
    const { partUuid, assessmentTypes, assessmentTechniques } = data;

    const scoresSelectorORM = ormCreateSelector(orm.Score);

    const scores = scoresSelectorORM(redux).filter(
      score =>
        score.assessment?.partUuid === partUuid &&
        assessmentTypes.includes(score.assessment?.assessmentTypeUuid) &&
        assessmentTechniques.includes(score.assessment?.assessmentTechniqueUuid)
    );

    return scores;
  }
);

export const scoresByTopic = createSelector(
  state => state,
  (_, studentUuid, topicUuid, assessmentUuid) => ({
    studentUuid,
    topicUuid,
    assessmentUuid
  }),
  (redux, data) => {
    const { studentUuid, topicUuid, assessmentUuid } = data;

    const scoresSelectorORM = ormCreateSelector(orm.Score);

    const scores = scoresSelectorORM(redux).filter(
      score =>
        score.assessmentUuid === assessmentUuid &&
        score.studentUuid === studentUuid &&
        score.topicUuid === topicUuid
    );

    return scores;
  }
);

export const scoreSelector = createSelector(
  state => state,
  (_, scoreUuid) => scoreUuid,
  (redux, scoreUuid) => {
    const scoresSelectorORM = ormCreateSelector(orm.Score);
    const score = scoresSelectorORM(redux, scoreUuid) || undefined;

    return score;
  }
);

export const scoresByStudentSelector = createSelector(
  state => state,
  (_, assessmentUuid, studentUuid) => ({
    assessmentUuid,
    studentUuid
  }),
  (redux, data) => {
    const scoresSelectorORM = ormCreateSelector(orm.Score);
    const scores = scoresSelectorORM(redux).filter(
      score =>
        score.studentUuid === data.studentUuid &&
        score.assessmentUuid === data.assessmentUuid
    );

    return scores;
  }
);

export const scoresGroupedByResultCardAttempt = createSelector(
  state => state,
  (_, assessmentUuid, studentUuid) => ({
    assessmentUuid,
    studentUuid
  }),
  (redux, data) => {
    const { assessmentUuid, studentUuid } = data;

    const scoresSelectorORM = ormCreateSelector(orm.Score);
    const scores = scoresSelectorORM(redux).filter(
      score =>
        score.studentUuid === studentUuid &&
        score.assessmentUuid === assessmentUuid
    );

    const scoresByAttempt = _.groupBy(scores, 'attempt');

    return scoresByAttempt;
  }
);

export const scoresGroupedByBlock = createSelector(
  state => state,
  (_, assessmentUuid, studentUuid) => ({
    assessmentUuid,
    studentUuid
  }),
  (redux, data) => {
    const { assessmentUuid, studentUuid } = data;

    const scoresSelectorORM = ormCreateSelector(orm.Score);
    const scores = scoresSelectorORM(redux).filter(
      score =>
        score.studentUuid === studentUuid &&
        score.assessmentUuid === assessmentUuid
    );

    const scoresByAttempt = _.groupBy(scores, 'assessmentBlockUuid');

    return scoresByAttempt;
  }
);

export const scoresGroupedByTopic = createSelector(
  state => state,
  (_, assessmentUuid, studentUuid) => ({
    assessmentUuid,
    studentUuid
  }),
  (redux, data) => {
    const { assessmentUuid, studentUuid } = data;

    const scoresSelectorORM = ormCreateSelector(orm.Score);
    const scores = scoresSelectorORM(redux).filter(
      score =>
        score.studentUuid === studentUuid &&
        score.assessmentUuid === assessmentUuid
    );

    const scoresByTopic = _.groupBy(scores, 'topicUuid');

    return scoresByTopic;
  }
);

export const scoresByFacultyRubric = createSelector(
  state => state,
  (_, assessmentUuid, facultyUuid) => ({
    assessmentUuid,
    facultyUuid
  }),
  (redux, data) => {
    const { assessmentUuid, facultyUuid } = data;

    const scoresSelectorORM = ormCreateSelector(orm.Score);
    const scores = scoresSelectorORM(redux).filter(
      score =>
        score.assessmentUuid === assessmentUuid &&
        (score.grader1Uuid === facultyUuid ||
          score.grader2Uuid === facultyUuid ||
          score.grader3Uuid === facultyUuid)
    );

    const scoresByFacultyRubricRubricItem = _.groupBy(scores, 'rubricItem');

    const rubricList = _.keys(scoresByFacultyRubricRubricItem);

    return rubricList;
  }
);

export const scoresByFacultyRubricAttempt = createSelector(
  state => state,
  (_, assessmentUuid, facultyUuid, rubricItem) => ({
    assessmentUuid,
    facultyUuid,
    rubricItem
  }),
  (redux, data) => {
    const { assessmentUuid, facultyUuid, rubricItem } = data;

    const scoresSelectorORM = ormCreateSelector(orm.Score);
    const scores = scoresSelectorORM(redux).filter(
      score =>
        score.rubricItem === rubricItem &&
        score.assessmentUuid === assessmentUuid &&
        (score.grader1Uuid === facultyUuid ||
          score.grader2Uuid === facultyUuid ||
          score.grader3Uuid === facultyUuid)
    );

    const scoresByAttempt = _.groupBy(scores, 'attempt');

    return scoresByAttempt;
  }
);

export const scoresByAssessment = createSelector(
  state => state,
  (_, assessmentUuid) => assessmentUuid,
  (redux, assessmentUuid) => {
    const scoresSelectorORM = ormCreateSelector(orm.Score);
    const scores = scoresSelectorORM(redux).filter(
      score => score.assessmentUuid === assessmentUuid
    );
    return scores;
  }
);

export const scoresByAssessmentBlockAttemptStudent = createSelector(
  state => state,
  (_, assessmentBlockUuid, attempt, studentUuid) => ({
    assessmentBlockUuid,
    attempt,
    studentUuid
  }),
  (redux, data) => {
    const { attempt, assessmentBlockUuid, studentUuid } = data;

    const scoresSelectorORM = ormCreateSelector(orm.Score);
    const scores = scoresSelectorORM(redux).filter(
      score =>
        score.studentUuid === studentUuid &&
        score.assessmentBlockUuid === assessmentBlockUuid &&
        score.attempt === attempt
    );

    return scores;
  }
);
