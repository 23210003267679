import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { cohortSelector } from '../../../../../redux/selectors/cohortsSelectors';

const CohortName = styled.div`
  font-size: 14px;
  padding-left: 10px;
  color: ${props => props.theme.fontColors.dark};
`;

export default function ApplicationCohort({ cohortUuid }) {
  const cohort = useSelector(state => cohortSelector(state, cohortUuid));

  const { name } = cohort;

  return <>{name && <CohortName>({name})</CohortName>}</>;
}

ApplicationCohort.propTypes = {
  cohortUuid: PropTypes.string
};

ApplicationCohort.defaultProps = {
  cohortUuid: undefined
};
