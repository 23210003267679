export const GET_COHORT = 'GET_COHORT';
export const GET_COHORT_SUCCESS = 'GET_COHORT_SUCCESS';
export const GET_COHORT_ERROR = 'GET_COHORT_ERROR';
export const GET_COHORTS = 'GET_COHORTS';
export const GET_COHORTS_SUCCESS = 'GET_COHORTS_SUCCESS';
export const GET_COHORTS_ERROR = 'GET_COHORTS_ERROR';
export const GET_PROGRAM_COHORTS = 'GET_PROGRAM_COHORTS';
export const GET_PROGRAM_COHORTS_SUCCESS = 'GET_PROGRAM_COHORTS_SUCCESS';
export const GET_PROGRAM_COHORTS_ERROR = 'GET_PROGRAM_COHORTS_ERROR';
export const POST_COHORT = 'POST_COHORT';
export const POST_COHORT_SUCCESS = 'POST_COHORT_SUCCESS';
export const POST_COHORT_ERROR = 'POST_COHORT_ERROR';
export const PUT_COHORT = 'PUT_COHORT';
export const PUT_COHORT_SUCCESS = 'PUT_COHORT_SUCCESS';
export const PUT_COHORT_ERROR = 'PUT_COHORT_ERROR';
export const DELETE_COHORT = 'DELETE_COHORT';
export const DELETE_COHORT_SUCCESS = 'DELETE_COHORT_SUCCESS';
export const DELETE_COHORT_ERROR = 'DELETE_COHORT_ERROR';

export const GET_COHORT_ASSESSMENTS = 'GET_COHORT_ASSESSMENTS';
export const GET_COHORT_ASSESSMENTS_SUCCESS = 'GET_COHORT_ASSESSMENTS_SUCCESS';
export const GET_COHORT_ASSESSMENTS_ERROR = 'GET_COHORT_ASSESSMENTS_ERROR';

export const POST_COHORT_ASSESSMENT_COLLECTION =
  'POST_COHORT_ASSESSMENT_COLLECTION';
export const POST_COHORT_ASSESSMENT_COLLECTION_SUCCESS =
  'POST_COHORT_ASSESSMENT_COLLECTION_SUCCESS';
export const POST_COHORT_ASSESSMENT_COLLECTION_ERROR =
  'POST_COHORT_ASSESSMENT_COLLECTION_ERROR';
export const PUT_COHORT_ASSESSMENT_COLLECTION =
  'PUT_COHORT_ASSESSMENT_COLLECTION';
export const PUT_COHORT_ASSESSMENT_COLLECTION_SUCCESS =
  'PUT_COHORT_ASSESSMENT_COLLECTION_SUCCESS';
export const PUT_COHORT_ASSESSMENT_COLLECTION_ERROR =
  'PUT_COHORT_ASSESSMENT_COLLECTION_ERROR';
export const DELETE_COHORT_ASSESSMENT_COLLECTION =
  'DELETE_COHORT_ASSESSMENT_COLLECTION';
export const DELETE_COHORT_ASSESSMENT_COLLECTION_SUCCESS =
  'DELETE_COHORT_ASSESSMENT_COLLECTION_SUCCESS';
export const DELETE_COHORT_ASSESSMENT_COLLECTION_ERROR =
  'DELETE_COHORT_ASSESSMENT_COLLECTION_ERROR';

export const POST_COHORT_ASSESSMENT = 'POST_COHORT_ASSESSMENT';
export const POST_COHORT_ASSESSMENT_SUCCESS = 'POST_COHORT_ASSESSMENT_SUCCESS';
export const POST_COHORT_ASSESSMENT_ERROR = 'POST_COHORT_ASSESSMENT_ERROR';
export const PUT_COHORT_ASSESSMENT = 'PUT_COHORT_ASSESSMENT';
export const PUT_COHORT_ASSESSMENT_SUCCESS = 'PUT_COHORT_ASSESSMENT_SUCCESS';
export const PUT_COHORT_ASSESSMENT_ERROR = 'PUT_COHORT_ASSESSMENT_ERROR';
export const PATCH_COHORT_ASSESSMENT_STATUS = 'PATCH_COHORT_ASSESSMENT_STATUS';
export const PATCH_COHORT_ASSESSMENT_STATUS_SUCCESS =
  'PATCH_COHORT_ASSESSMENT_STATUS_SUCCESS';
export const PATCH_COHORT_ASSESSMENT_STATUS_ERROR =
  'PATCH_COHORT_ASSESSMENT_STATUS_ERROR';
export const DELETE_COHORT_ASSESSMENT = 'DELETE_COHORT_ASSESSMENT';
export const DELETE_COHORT_ASSESSMENT_SUCCESS =
  'DELETE_COHORT_ASSESSMENT_SUCCESS';
export const DELETE_COHORT_ASSESSMENT_ERROR = 'DELETE_COHORT_ASSESSMENT_ERROR';

export const GET_COHORT_ASSESSMENTS_STUDENT = 'GET_COHORT_ASSESSMENTS_STUDENT';
export const GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS =
  'GET_COHORT_ASSESSMENTS_STUDENT_SUCCESS';
export const GET_COHORT_ASSESSMENTS_STUDENT_ERROR =
  'GET_COHORT_ASSESSMENTS_STUDENT_ERROR';
