import React from 'react';
import PropTypes from 'prop-types';

import CSVReader from 'react-csv-reader';

import { CircularProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonPrimary } from '@xcomp/xcomp-design-library';
import Modal from '../../../Library/Modal/Modal';
import UploadMapping from '../../../Library/UploadMapping';
import UploadErrorsNew from '../../../Library/UploadErrorsNew';
import UploadSuccess from '../../../Library/UploadSuccess';
import { headersLegacyGrades } from '../../../../helpers/headers_upload/headers_legacy_grades';
import {
  ModalBody,
  Title,
  FileUpload,
  Note,
  Actions,
  Import,
  FileSelectorSC,
  Loader,
  LoaderMessage,
  ChipSC,
  ButtonDanger,
  ButtonSummary,
  ModalHeader,
  CloseModal
} from '../../../Library/CSVUploadModalSC';

export default function SectionLegacyGradeUpload({
  grades,
  status,
  uploadDate,
  error,
  summary,
  openSummary,
  openModal,
  openError,
  openMapping,
  handleUploadClose,
  handleGrades,
  handleUpload,
  handleDisplayMapping,
  handleDisplaySummary,
  handleDisplayErrors
}) {
  function statusMap() {
    switch (status) {
      case 'ready': {
        return 'Ready for upload';
      }
      case 'loading': {
        return (
          <Loader>
            <CircularProgress color="primary" size={20} />
            <LoaderMessage>Upload in progress</LoaderMessage>
          </Loader>
        );
      }
      case 'success': {
        return 'Recent upload complete';
      }
      case 'failed': {
        return 'Recent upload failed';
      }
      default:
        return 'Ready for upload';
    }
  }

  return (
    <Modal open={openModal} onClose={handleUploadClose}>
      <ModalBody>
        <ModalHeader>
          <Title>
            Upload Legacy Grades (CSV)
            <ChipSC status={status} label={statusMap()} />
          </Title>
          <CloseModal>
            <IconButton onClick={handleUploadClose}>
              <CloseIcon />
            </IconButton>
          </CloseModal>
        </ModalHeader>
        <FileUpload>
          <FileSelectorSC>
            <CSVReader
              onFileLoaded={handleGrades}
              parserOptions={{ header: true }}
              inputId="grades-upload"
              inputStyle={{
                width: '100%',
                color: 'black',
                background: 'lightgrey',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                borderRadius: '5px'
              }}
            />
          </FileSelectorSC>
          <Import disabled={status === 'loading'} onClick={handleUpload}>
            Import
          </Import>
        </FileUpload>
        <Note>
          {grades
            ? 'If changes are made to an existing selected file, close this modal and re-open then re-select the file.'
            : 'Select a file to import Grades from.'}
        </Note>

        <Actions>
          <div>
            <ButtonPrimary onClick={handleDisplayMapping}>
              {openMapping ? `Hide Rules` : `Rules`}
            </ButtonPrimary>
            {status === 'success' && (
              <ButtonSummary onClick={handleDisplaySummary}>
                {openSummary ? `Hide Results` : `Results`}
              </ButtonSummary>
            )}
            {status === 'failed' && (
              <ButtonDanger onClick={handleDisplayErrors}>Errors</ButtonDanger>
            )}
          </div>
        </Actions>

        <UploadMapping display={openMapping} fields={headersLegacyGrades} />

        <UploadErrorsNew
          show={status === 'failed' && openError}
          error={error}
          uploadDate={uploadDate}
        />
        <UploadSuccess
          show={status === 'success' && openSummary}
          summary={summary}
          uploadDate={uploadDate}
        />
      </ModalBody>
    </Modal>
  );
}

SectionLegacyGradeUpload.propTypes = {
  summary: PropTypes.object,
  error: PropTypes.object,
  status: PropTypes.string,
  uploadDate: PropTypes.string,
  openSummary: PropTypes.bool,
  grades: PropTypes.array,
  openModal: PropTypes.bool,
  openError: PropTypes.bool,
  openMapping: PropTypes.bool,
  handleUploadClose: PropTypes.func,
  handleGrades: PropTypes.func,
  handleUpload: PropTypes.func,
  handleDisplayMapping: PropTypes.func,
  handleDisplaySummary: PropTypes.func,
  handleDisplayErrors: PropTypes.func
};

SectionLegacyGradeUpload.defaultProps = {
  summary: undefined,
  error: undefined,
  grades: undefined,
  uploadDate: '',
  status: 'Ready for upload',
  openModal: false,
  openError: false,
  openMapping: false,
  openSummary: false,
  handleUploadClose: undefined,
  handleGrades: undefined,
  handleUpload: undefined,
  handleDisplayMapping: undefined,
  handleDisplaySummary: undefined,
  handleDisplayErrors: undefined
};
