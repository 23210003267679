import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getEducationLevel,
  getEducationLevelsBySchool,
  postEducationLevel,
  putEducationLevel,
  deleteEducationLevel
} from '../../api/educationLevelApi';
import {
  doGetEducationLevelSuccess,
  doGetEducationLevelError,
  doGetEducationLevelsBySchoolSuccess,
  doGetEducationLevelsBySchoolError,
  doPostEducationLevelSuccess,
  doPostEducationLevelError,
  doPutEducationLevelSuccess,
  doPutEducationLevelError,
  doDeleteEducationLevelSuccess,
  doDeleteEducationLevelError
} from '../actions/educationLevelActions';

import {
  GET_EDUCATION_LEVEL,
  GET_EDUCATION_LEVELS_BY_SCHOOL,
  POST_EDUCATION_LEVEL,
  PUT_EDUCATION_LEVEL,
  DELETE_EDUCATION_LEVEL
} from '../actions-type';

function* handleGetEducationLevel(action) {
  try {
    const result = yield call(
      getEducationLevel,
      action.payload.educationLevelUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetEducationLevelSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetEducationLevelError(error));
  }
}

function* handleGetEducationLevelsBySchool(action) {
  try {
    const result = yield call(
      getEducationLevelsBySchool,
      action.payload.schoolUuid
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetEducationLevelsBySchoolSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doGetEducationLevelsBySchoolError(error));
  }
}

function* handlePostEducationLevel(action) {
  try {
    const result = yield call(
      postEducationLevel,
      action.payload.educationLevel
    );

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostEducationLevelSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPostEducationLevelError(error));
  }
}

function* handlePutEducationLevel(action) {
  try {
    const result = yield call(putEducationLevel, action.payload.educationLevel);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPutEducationLevelSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doPutEducationLevelError(error));
  }
}

function* handleDeleteEducationLevel(action) {
  const { educationLevelUuid } = action.payload;

  try {
    const result = yield call(deleteEducationLevel, educationLevelUuid);

    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doDeleteEducationLevelSuccess(payload));
    } else {
      const payload = yield result.json();
      throw payload;
    }
  } catch (error) {
    yield put(doDeleteEducationLevelError(error));
  }
}

const educationLevel = [
  takeEvery(GET_EDUCATION_LEVEL, handleGetEducationLevel),
  takeEvery(GET_EDUCATION_LEVELS_BY_SCHOOL, handleGetEducationLevelsBySchool),
  takeEvery(POST_EDUCATION_LEVEL, handlePostEducationLevel),
  takeEvery(PUT_EDUCATION_LEVEL, handlePutEducationLevel),
  takeEvery(DELETE_EDUCATION_LEVEL, handleDeleteEducationLevel)
];

export { educationLevel };
