import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import styled from 'styled-components';
import FormSelect from '../../Library/FormSelect';
import { optionsSyllabus } from '../../../helpers/options';

const Layout = styled.div`
  padding: 1rem;
`;

export default function SyllabusFilter({ filterType, handleFilterType }) {
  return (
    <Paper>
      <Layout>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item sm={10} xs={10}>
            <FormSelect
              value={filterType}
              options={optionsSyllabus}
              handleChange={handleFilterType}
              name="status"
              label="Filter By Type"
            />
          </Grid>
        </Grid>
      </Layout>
    </Paper>
  );
}

SyllabusFilter.propTypes = {
  filterType: PropTypes.string,
  handleFilterType: PropTypes.func
};

SyllabusFilter.defaultProps = {
  filterType: 'all',
  handleFilterType: undefined
};
