import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import RoleProgram from './RoleProgram';
import { doPostUserRole } from '../../../../../redux/actions/userActions';

import {
  validateInputString,
  validateSelectDate,
  validateSelectField
} from '../../../../../helpers/validation/validateGeneric';

import { selectProgramsBySchool } from '../../../../../redux/selectors/programSelectors';

export default function RoleProgramContainer({
  userUuid,
  openRoleProgram,
  optionsSchool,
  handleProgramRoleClose
}) {
  const dispatch = useDispatch();

  const [status, setStatus] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [role, setRole] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [schoolUuid, setSchoolUuid] = useState(undefined);
  const [programUuid, setProgramUuid] = useState(undefined);
  const [hasErrors, setHasErrors] = useState(false);

  const createRole = useSelector(state => state.crudState.createRole);

  const createRoleResult = useSelector(
    state => state.crudState.createRoleResult
  );

  const programs = useSelector(state =>
    selectProgramsBySchool(state, schoolUuid)
  );

  const optionsProgram = _.map(programs, program => ({
    label: program.programName,
    value: program.uuid
  }));

  useEffect(() => {
    if (!openRoleProgram) {
      setStatus(undefined);
      setStartDate(undefined);
      setEndDate(undefined);
      setRole(undefined);
      setEmail(undefined);
      setPhone(undefined);
      setSchoolUuid(undefined);
      setHasErrors(false);
    }
  }, [openRoleProgram]);

  useEffect(() => {
    if (createRoleResult === 'success') {
      handleProgramRoleClose();
      dispatch({ type: 'POST_USER_ROLE_RESET' });
    }
  }, [dispatch, createRoleResult, handleProgramRoleClose]);

  const handleRole = event => {
    const { name, value } = event.target;
    switch (name) {
      case 'status': {
        setStatus(value);
        break;
      }
      case 'role': {
        setRole(value);
        break;
      }
      case 'email': {
        setEmail(value);
        break;
      }
      case 'phone': {
        setPhone(value);
        break;
      }
      case 'schoolUuid': {
        setSchoolUuid(value);
        break;
      }
      case 'programUuid': {
        setProgramUuid(value);
        break;
      }
      default:
        break;
    }
  };

  const handleRoleDate = (indentifier, field, date) => {
    if (field === 'startDate') {
      setStartDate(date);
    }

    if (field === 'endDate') {
      setEndDate(date);
    }
  };

  const validation = () => {
    let containErrors = false;
    const statusError = validateInputString(status);
    const startDateError = validateSelectDate(startDate);
    const roleError = validateInputString(role);
    const emailError = validateInputString(email);
    const phoneError = validateInputString(phone);
    const schoolUuidError = validateSelectField(schoolUuid);
    const programUuidError = validateSelectField(programUuid);

    if (
      statusError.invalid ||
      startDateError.invalid ||
      roleError.invalid ||
      emailError.invalid ||
      phoneError.invalid ||
      schoolUuidError.invalid ||
      programUuidError.invalid
    ) {
      containErrors = true;
    }
    return containErrors;
  };

  const handleRoleSubmit = () => {
    const reject = validation();
    if (reject) {
      setHasErrors(true);
    } else {
      const payload = {
        userUuid,
        programUuid,
        status,
        startDate,
        endDate,
        role,
        email,
        phone
      };

      dispatch(doPostUserRole('program_role', payload));
    }
  };

  return (
    <RoleProgram
      submit={createRole}
      result={createRoleResult}
      status={status}
      startDate={startDate}
      endDate={endDate}
      role={role}
      email={email}
      phone={phone}
      schoolUuid={schoolUuid}
      programUuid={programUuid}
      hasErrors={hasErrors}
      optionsSchool={optionsSchool}
      optionsProgram={optionsProgram}
      handleRole={handleRole}
      handleRoleDate={handleRoleDate}
      openRoleProgram={openRoleProgram}
      handleProgramRoleClose={handleProgramRoleClose}
      handleRoleSubmit={handleRoleSubmit}
    />
  );
}

RoleProgramContainer.propTypes = {
  userUuid: PropTypes.string,
  openRoleProgram: PropTypes.bool,
  optionsSchool: PropTypes.array,
  handleProgramRoleClose: PropTypes.func
};

RoleProgramContainer.defaultProps = {
  userUuid: undefined,
  openRoleProgram: false,
  optionsSchool: [],
  handleProgramRoleClose: undefined
};
