import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { doGetStudentRoster } from '../../../redux/actions/userSectionActions';
import { doGetPart } from '../../../redux/actions/partActions';
import {
  doGetPartUserGroups,
  doPostUserGroups,
  doDeleteUserGroup
} from '../../../redux/actions/userGroupActions';
import {
  doGetPartGroups,
  doPostGroup,
  doDeleteGroup,
  doPutGroup
} from '../../../redux/actions/groupActions';
import { partSelectorByUuid } from '../../../redux/selectors/partsSelectors';
import { studentRosterSelectorBySectionUuid } from '../../../redux/selectors/sectionsSelectors';
import { userGroupsByPartUuid } from '../../../redux/selectors/userGroupSelector';
import {
  groupsSelectorByPartUuid,
  groupedStudentsSelectorByPartUuid
} from '../../../redux/selectors/groupSelectors';
import { sortByNestedProperty } from '../../../helpers/utilities';
import { validateInputString } from '../../../helpers/validation/validateGeneric';
import DeleteModal from '../../Library/Modal/DeleteModal/DeleteModal';
import CreateOrEditGroupModal from './StudentPartGroups/CreateOrEditGroupModal';
import AssignGroupLeaderModal from './StudentPartGroups/AssignGroupLeaderModal';
import StudentPartRoster from './StudentPartRoster';

const StudentPartRosterContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sectionUuid, sectionNumber, partUuid, partNumber } = useParams();
  const [groupIdInput, setGroupIdInput] = useState('');
  const [filterStatus, setFilterStatus] = useState({
    active: true,
    delayed: false,
    withdrew: false,
    credit: false,
    pass: false,
    pass_with_remediation: false,
    fail: false,
    fail_with_remediation: false
  });
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [groupLeaderSelection, setGroupLeaderSelection] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRemoveStudentModalOpen, setIsRemoveStudentModalOpen] = useState(
    false
  );
  const [shouldUseGroupNumber, setShouldUseGroupNumber] = useState(false);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [isCreateGroupModalOpen, setCreateGroupModalOpen] = useState(false);
  const [isGroupEditMode, setIsGroupEditMode] = useState(false);
  const [
    isAssignGroupLeaderModalOpen,
    setIsAssignGroupLeaderModalOpen
  ] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);

  const students = useSelector(state =>
    studentRosterSelectorBySectionUuid(state, sectionUuid)
  );

  const userGroups = useSelector(state =>
    userGroupsByPartUuid(state, partUuid)
  );

  const groups = useSelector(state =>
    groupsSelectorByPartUuid(state, partUuid)
  );

  const studentsWithGroup = useSelector(state =>
    groupedStudentsSelectorByPartUuid(state, partUuid)
  );

  const part = useSelector(state => partSelectorByUuid(state, partUuid));

  const numGroups = groups.length;

  const numUserGroups = Object.keys(studentsWithGroup).length;

  useEffect(() => {
    if (sectionUuid) {
      dispatch(doGetStudentRoster(sectionUuid));
    }
  }, [dispatch, sectionUuid]);

  useEffect(() => {
    if (partUuid) {
      dispatch(doGetPartGroups(partUuid));
      dispatch(doGetPartUserGroups(partUuid));
      dispatch(doGetPart(partUuid));
    }
  }, [dispatch, partUuid, numGroups, numUserGroups]);

  const filterStudentsByStatus = studentsList => {
    const selectedFilters = Object.keys(filterStatus).filter(
      status => filterStatus[status]
    );
    const filteredStudents = studentsList
      .filter(student => selectedFilters.includes(student.status))
      .sort((a, b) => sortByNestedProperty(a, b, 'user.firstName'));
    return filteredStudents;
  };

  const handleBackAction = useCallback(() => {
    history.push('/syllabus-management/all');
  }, [history]);

  const handleOpenCreateGroupModal = () => {
    setCreateGroupModalOpen(true);
  };

  const handleOpenEditGroupModal = groupUuid => {
    setSelectedGroup(groupUuid);
    setIsGroupEditMode(true);
    setCreateGroupModalOpen(true);
  };

  const handleOpenAssignGroupLeaderModal = groupUuid => {
    setSelectedGroup(groupUuid);
    setIsAssignGroupLeaderModalOpen(true);
  };

  const handleOpenDeleteGroupModal = groupUuid => {
    setSelectedGroup(groupUuid);
    setIsDeleteModalOpen(true);
  };

  const handleOpenRemoveStudentModal = (userUuid, groupUuid) => {
    setSelectedUser(userUuid);
    setSelectedGroup(groupUuid);
    setIsRemoveStudentModalOpen(true);
  };

  const handleCloseCreateGroupModal = () => {
    setCreateGroupModalOpen(false);
    setSelectedGroup(null);
  };

  const handleCloseAssignGroupLeaderModal = () => {
    setIsAssignGroupLeaderModalOpen(false);
    setGroupLeaderSelection(null);
    setSelectedGroup(null);
  };

  const handleCloseDeleteGroupModal = () => {
    setSelectedGroup(null);
    setIsDeleteModalOpen(false);
  };

  const handleCloseRemoveStudentModal = () => {
    setSelectedGroup(null);
    setIsRemoveStudentModalOpen(false);
  };
  const handleGroupIdChange = event => {
    const { value } = event.target;
    setGroupIdInput(value);
  };

  const handleToggleShouldUseGroupNumber = event => {
    const { checked } = event.target;
    if (checked) {
      setGroupIdInput('');
      setShowValidationErrors(false);
    }
    setShouldUseGroupNumber(checked);
  };

  const handleFilterSelection = selection => {
    setFilterStatus({ ...filterStatus, [selection]: !filterStatus[selection] });
  };

  const handleSelectGroupLeader = event => {
    const { value } = event.target;
    setGroupLeaderSelection(value);
  };

  const handleSelectStudent = (event, uuid) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedStudents([...selectedStudents, uuid]);
    } else {
      setSelectedStudents(
        selectedStudents.filter(studentUuid => studentUuid !== uuid)
      );
    }
  };

  const handleSelectAll = () => {
    setSelectedStudents([...students.map(student => student.user.uuid)]);
    setSelectAll(true);
  };

  const handleDeselectAll = () => {
    setSelectedStudents([]);
    setSelectAll(false);
  };

  const handleEditGroupId = () => {
    const groupIdInputErrors = shouldUseGroupNumber
      ? { invalid: false }
      : validateInputString(groupIdInput);
    if (groupIdInputErrors.invalid) {
      setShowValidationErrors(true);
    } else {
      setShowValidationErrors(false);
      const currentGroupId = shouldUseGroupNumber ? null : groupIdInput;
      dispatch(doPutGroup(selectedGroup, currentGroupId, null));
      handleCloseCreateGroupModal();
      setIsGroupEditMode(false);
    }
  };

  const handleCreateNewGroup = () => {
    const groupIdInputErrors = shouldUseGroupNumber
      ? { invalid: false }
      : validateInputString(groupIdInput);
    if (groupIdInputErrors.invalid) {
      setShowValidationErrors(true);
    } else {
      setShowValidationErrors(false);
      dispatch(
        doPostGroup({
          partUuid,
          groupId: shouldUseGroupNumber ? null : groupIdInput
        })
      );
      handleCloseCreateGroupModal();
    }
  };

  const handleDeleteGroup = () => {
    dispatch(doDeleteGroup(selectedGroup));
    handleCloseDeleteGroupModal();
  };

  const handleAddUsersToGroup = (event, groupUuid) => {
    event.stopPropagation();
    const userGroups = selectedStudents.map(userUuid => {
      return { userUuid, groupUuid };
    });
    setSelectedStudents([]);
    setSelectAll(false);
    dispatch(doPostUserGroups(userGroups));
  };

  const handleAssignGroupLeader = () => {
    dispatch(doPutGroup(selectedGroup, null, groupLeaderSelection));
    setIsAssignGroupLeaderModalOpen(false);
    setGroupLeaderSelection(null);
  };

  const handleRemoveStudentFromGroup = () => {
    dispatch(doDeleteUserGroup(selectedUser, selectedGroup));
    setSelectedUser(null);
    setIsRemoveStudentModalOpen(false);
  };

  const filteredStudentsList = filterStudentsByStatus(students);

  const studentStatusMap = {};
  students.forEach(student => {
    studentStatusMap[student.user.uuid] = student.status;
  });

  return (
    <>
      <StudentPartRoster
        filterStatus={filterStatus}
        groups={groups.sort(
          (groupA, groupB) => groupA.groupNumber - groupB.groupNumber
        )}
        groupIdInput={groupIdInput}
        isCreateGroupModalOpen={isCreateGroupModalOpen}
        partStatus={part?.status}
        partNumber={partNumber}
        partUuid={partUuid}
        sectionNumber={sectionNumber}
        sectionUuid={sectionUuid}
        selectAll={selectAll}
        selectedStudents={selectedStudents}
        shouldUseGroupNumber={shouldUseGroupNumber}
        showValidationErrors={showValidationErrors}
        students={filteredStudentsList}
        studentStatusMap={studentStatusMap}
        studentsWithGroup={studentsWithGroup}
        userGroups={userGroups}
        onAddUsersToGroup={handleAddUsersToGroup}
        onOpenAssignGroupLeaderModal={handleOpenAssignGroupLeaderModal}
        onBackAction={handleBackAction}
        onCreateNewGroup={handleCreateNewGroup}
        onDeselectAll={handleDeselectAll}
        onFilterSelection={handleFilterSelection}
        onGroupIdChange={handleGroupIdChange}
        onOpenCreateGroupModal={handleOpenCreateGroupModal}
        onOpenDeleteGroupModal={handleOpenDeleteGroupModal}
        onOpenEditGroupModal={handleOpenEditGroupModal}
        onOpenRemoveStudentModal={handleOpenRemoveStudentModal}
        onSelectAll={handleSelectAll}
        onSelectStudent={handleSelectStudent}
        onToggleShouldUseGroupNumber={handleToggleShouldUseGroupNumber}
      />
      <CreateOrEditGroupModal
        isModalOpen={isCreateGroupModalOpen}
        isEditMode={isGroupEditMode}
        groupIdInput={groupIdInput}
        shouldUseGroupNumber={shouldUseGroupNumber}
        showValidationErrors={showValidationErrors}
        onGroupIdChange={handleGroupIdChange}
        onToggleShouldUseGroupNumber={handleToggleShouldUseGroupNumber}
        onCreateNewGroup={handleCreateNewGroup}
        onEditGroup={handleEditGroupId}
        onModalClose={handleCloseCreateGroupModal}
      />
      <DeleteModal
        type="group"
        modalOpen={isDeleteModalOpen}
        handleModalClose={handleCloseDeleteGroupModal}
        handleDelete={handleDeleteGroup}
        customHeading="Delete Group?"
        warningMessage="This will delete the group. All students previously assigned to this group will now be unassigned."
      />
      <DeleteModal
        type="user"
        modalOpen={isRemoveStudentModalOpen}
        handleModalClose={handleCloseRemoveStudentModal}
        handleDelete={handleRemoveStudentFromGroup}
        customHeading="Remove student from Group?"
        verb="Remove"
        warningMessage="Student will be unassigned from Group. Associated data will be lost."
      />
      <AssignGroupLeaderModal
        isModalOpen={isAssignGroupLeaderModalOpen}
        groupLeaderSelection={groupLeaderSelection}
        students={students.filter(
          student =>
            studentsWithGroup[student.user.uuid] &&
            studentsWithGroup[student.user.uuid].uuid === selectedGroup
        )}
        onAssignGroupLeader={handleAssignGroupLeader}
        onSelectGroupLeader={handleSelectGroupLeader}
        onModalClose={handleCloseAssignGroupLeaderModal}
      />
    </>
  );
};

export default StudentPartRosterContainer;
