import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import UserProfile from './UserProfile';

import { doGetInstitutionIdTypes } from '../../../redux/actions/sourceActions';

export default function UserProfileContainer({ match, history }) {
  const dispatch = useDispatch();
  const { path } = match;
  const { pathname } = history.location;

  useEffect(() => {
    dispatch(doGetInstitutionIdTypes());
  }, [dispatch]);

  return <UserProfile path={path} pathname={pathname} />;
}

UserProfileContainer.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
};

UserProfileContainer.defaultProps = {
  match: {},
  history: {}
};
