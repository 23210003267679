import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Search from '@material-ui/icons/Search';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import { v4 as uuidv4 } from 'uuid';
import { ButtonOutline } from '@xcomp/xcomp-design-library';

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
`;

const SearchChoice = styled(ButtonOutline)`
  && {
    color: ${props => props.theme.fontColors.dark};
    font-size: 16px;
    letter-spacing: 0px;
    text-transform: none;
    border-radius: 5px 0px 0px 5px;
    height: 100%;
    width: 180px;
  }
`;

const ArrowDropDownSC = styled(ArrowDropDown)`
  padding-left: 5px;
`;

const MenuSC = styled(Menu)`
  margin-top: 50px;
`;

const MenuItemSC = styled(MenuItem)`
  && {
    font-size: 16px;
  }
`;

const SearchInput = styled(({ ...rest }) => <InputBase {...rest} />)`
  && {
    border-top: 1px solid ${props => props.theme.colors.grey[300]};
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
    height: 100%;
    padding-left: 10px;
    flex-grow: 2;
  }
`;

const SearchIcon = styled(({ ...rest }) => <Search {...rest} />)`
  && {
    height: 100%;
    width: 50px;
    border-right: solid 1px ${props => props.theme.colors.grey[300]};
    border-top: solid 1px ${props => props.theme.colors.grey[300]};
    border-bottom: solid 1px ${props => props.theme.colors.grey[300]};
    color: ${props => props.theme.colors.grey[300]};
    padding: 10px;
    border-radius: 0px 5px 5px 0px;
  }
`;

class SearchMulti extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      selectLabel: 'Select',
      selectField: '',
      searchText: ''
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleSearchText = this.handleSearchText.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.setState(prevState => {
      return {
        ...prevState,
        selectLabel: this.props.options[0].label,
        selectField: this.props.options[0].field
      };
    });
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleSelection = option => {
    this.setState(prevState => {
      return {
        ...prevState,
        selectLabel: option.label,
        selectField: option.field
      };
    });
  };

  handleSearchText = event => {
    this.setState({ searchText: event.target.value });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.props.onSearch(this.state.selectField, this.state.searchText);
    }
  };

  render() {
    const { options, className, onSearch, ...props } = this.props;

    const { anchorEl, selectLabel, selectField, searchText } = this.state;

    return (
      <Layout className={className}>
        <SearchChoice
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          {selectLabel}
          <ArrowDropDownSC />
        </SearchChoice>
        <MenuSC
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => this.handleClose()}
        >
          {options.map(option => (
            <MenuItemSC
              key={uuidv4()}
              onClick={() => {
                this.handleClose();
                this.handleSelection(option);
              }}
            >
              {option.label}
            </MenuItemSC>
          ))}
        </MenuSC>
        <SearchInput
          onChange={event => this.handleSearchText(event)}
          onKeyDown={this.handleKeyDown}
          placeholder="Search"
          {...props}
        />
        <SearchIcon
          onClick={() => onSearch(selectField, searchText)}
          {...props}
        />
      </Layout>
    );
  }
}

SearchMulti.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  onSearch: PropTypes.func
};

SearchMulti.defaultProps = {
  options: [],
  className: '',
  onSearch: undefined
};

export default SearchMulti;
