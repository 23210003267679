import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AccordionRowContainer from '../../Library/AccordionRow/AccordionRowContainer';
import OpportunityReportAssessmentPanelContainer from './OpportunityReportAssessmentPanelContainer';

const OpportunityReportPanelAccordion = styled(AccordionRowContainer)`
  display: flex;
  flex-direction: column;
  ${props =>
    props.withBottomBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.grey[300]};
    `}

  ${props =>
    props.withTopBorder &&
    css`
      border-top: 1px solid ${props.theme.colors.grey[300]};
    `}

  .accordion_row_label {
    flex-grow: 2;
  }

  .column_one_label {
    width: 140px;
  }
  .column_two_label {
    width: 100px;
  }
  .column_three_label {
    width: 120px;
  }
  .column_one_label,
  .column_two_label,
  .column_three_label {
    flex-grow: 0;
    text-align: right;
  }

  .accordion_row_label div {
    color: ${props =>
      props.isStatisticStudent
        ? props.theme.colors.status.success
        : props.theme.fontColors.darker};
  }

  .column_one_label,
  .column_two_label {
    margin-right: 16px;
    span {
      color: ${props =>
        props.isStatisticStudent
          ? props.theme.colors.status.success
          : props.theme.fontColors.darker};
    }
  }

  .column_three_label {
    span {
      color: ${props =>
        props.isStatisticStudent
          ? props.theme.colors.status.success
          : props.theme.fontColors.darker};
    }
  }

  .hover-effect {
    padding-right: 44px;
  }
  font-size: 20px;
  width: 100%;
`;

const OpportunityReportPanel = ({
  assessments,
  isStatisticStudent,
  panelLabel,
  partOpportunityReportAssessmentStatisticData,
  studentAssessmentSummaryData,
  studentPointsPerOpportunity,
  studentTotalOpportunityCount,
  studentTotalRelativeScore,
  studentUser
}) => {
  return (
    <OpportunityReportPanelAccordion
      columnOne={studentTotalOpportunityCount}
      columnTwo={studentTotalRelativeScore}
      columnThree={studentPointsPerOpportunity}
      columnOneClassName="column_one_label"
      columnTwoClassName="column_two_label"
      columnThreeClassName="column_three_label"
      expandWidth="66px"
      height="50px"
      isStatisticStudent={isStatisticStudent}
      label={panelLabel}
      withBottomBorder
    >
      {assessments.map(assessment => (
        <OpportunityReportAssessmentPanelContainer
          key={assessment.uuid}
          assessment={assessment}
          partOpportunityReportAssessmentStatisticData={
            partOpportunityReportAssessmentStatisticData
          }
          studentAssessmentData={studentAssessmentSummaryData[assessment.uuid]}
          studentUser={studentUser}
        />
      ))}
    </OpportunityReportPanelAccordion>
  );
};

OpportunityReportPanel.propTypes = {
  assessments: PropTypes.array,
  isStatisticStudent: PropTypes.bool,
  panelLabel: PropTypes.string,
  partOpportunityReportAssessmentStatisticData: PropTypes.object,
  studentAssessmentSummaryData: PropTypes.object,
  studentPointsPerOpportunity: PropTypes.string,
  studentTotalOpportunityCount: PropTypes.string,
  studentTotalRelativeScore: PropTypes.string,
  studentUser: PropTypes.object
};

OpportunityReportPanel.defaultProps = {
  assessments: [],
  isStatisticStudent: false,
  panelLabel: '',
  partOpportunityReportAssessmentStatisticData: {},
  studentAssessmentSummaryData: {},
  studentPointsPerOpportunity: '',
  studentTotalOpportunityCount: '',
  studentTotalRelativeScore: '',
  studentUser: {}
};

export default OpportunityReportPanel;
