import { Model, attr, fk } from 'redux-orm';

import {
  GET_TRANSCRIPT_SUCCESS,
  DELETE_TRANSCRIPT_SUCCESS,
  DELETE_TRANSCRIPTS_SUCCESS,
  POST_TRANSCRIPT_SUCCESS,
  PUT_TRANSCRIPT_SUCCESS,
  POST_CLONE_TRANSCRIPT_SUCCESS
} from '../actions-type';

class TranscriptNonCognitive extends Model {
  static reducer(action, TranscriptNonCognitive, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_TRANSCRIPT_SUCCESS: {
        const transcriptNonCognitive = [...payload.transcriptNonCognitive];
        transcriptNonCognitive.forEach(transcript =>
          TranscriptNonCognitive.upsert(transcript)
        );
        break;
      }

      case POST_CLONE_TRANSCRIPT_SUCCESS: {
        const transcriptNonCognitive = [...payload.transcriptNonCognitive];
        transcriptNonCognitive.forEach(transcript =>
          TranscriptNonCognitive.upsert(transcript)
        );
        break;
      }

      case POST_TRANSCRIPT_SUCCESS: {
        const { category, transcript } = { ...payload };

        if (category === 'Non-Cognitive') {
          TranscriptNonCognitive.upsert(transcript);
        }
        break;
      }

      case PUT_TRANSCRIPT_SUCCESS: {
        const { category, transcript } = { ...payload };

        if (category === 'Non-Cognitive') {
          TranscriptNonCognitive.upsert(transcript);
        }
        break;
      }

      case DELETE_TRANSCRIPT_SUCCESS: {
        const { category, uuid } = payload;

        if (category === 'Non-Cognitive') {
          TranscriptNonCognitive.withId(uuid).delete();
        }

        break;
      }

      case DELETE_TRANSCRIPTS_SUCCESS: {
        const { category, transcripts } = payload;

        if (category === 'Non-Cognitive') {
          transcripts.forEach(transcript =>
            TranscriptNonCognitive.withId(transcript).delete()
          );
        }

        break;
      }

      default:
        return session;
    }
  }
}

TranscriptNonCognitive.fields = {
  date: attr(),
  itemAge: attr(),
  uuid: attr(),
  category: attr(),
  source: attr(),
  applicationDate: attr(),
  termType: attr(),
  attempt: attr(),
  term: attr(),
  year: attr(),
  level: attr(),
  institutionCode: attr(),
  sourceInstitutionUuid: attr(),
  sourceDegreeLevelUuid: attr(),
  grade: attr(),
  score: attr(),
  classificationUuid: fk('Classification', 'transcript_noncognitives'),
  programUuid: fk('Program', 'transcript_noncognitives'),
  cohortUuid: fk('Cohort', 'transcript_noncognitives'),
  userUuid: fk('User', 'transcript_noncognitives'),
  examAptitudeUuid: fk('ExamAptitude', 'transcript_noncognitives'),
  conversionAptitudeUuid: fk('ConversionAptitude', 'transcript_noncognitives'),
  letterGradeUuid: fk('LetterGrade', 'transcript_noncognitives'),
  createdAt: attr(),
  updatedAt: attr()
};

TranscriptNonCognitive.modelName = 'TranscriptNonCognitive';

TranscriptNonCognitive.options = {
  idAttribute: 'uuid'
};

export default TranscriptNonCognitive;
