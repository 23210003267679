import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2, ButtonMenu } from '@xcomp/xcomp-design-library';
import {
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox
} from '@material-ui/core';

import {
  ContainerResize,
  ContainerPage,
  Panel,
  PanelHeader
} from '../../../Library/Layout';
import Header from '../../../Library/Header';
import GradeSummaryRow from './GradeSummaryRow';
import GradeSummaryRowHeader from './GradeSummaryRowHeader';
import HighLowAvgPanel from './HighLowAvgPanel';
import DelayedGradeSummaryRow from './DelayedGradeSummaryRow';

const PanelHeaderSC = styled(PanelHeader)`
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 24px;
`;

const DelayedPanel = styled(Panel)`
  margin-top: 32px;
`;

const PanelPadding = styled.div`
  padding: 16px 24px;
`;

const GradesHeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const CheckboxContainer = styled.div`
  color: ${props => props.theme.fontColors.dark};
  margin-left: 115px;
`;

const ButtonMenuSC = styled(ButtonMenu)`
  margin-left: auto;
  button {
    width: 200px;

    span {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const GradeSummary = ({
  assessmentTitle,
  assessmentType,
  courseNumber,
  courseTitle,
  gradeSummary,
  hideStudentNames,
  isPointsOnly,
  sortFunction,
  toggleHideStudentNames,
  setSortBy,
  handleBackAction,
  partNumber,
  resultCards,
  delayedResultCards,
  sectionNumber
}) => {
  const headerSubtitle = `${courseNumber} ● ${courseTitle} ● Section ${sectionNumber} ● Part ${partNumber} ● ${assessmentType}`;

  const sortByLastName = () => setSortBy('lastName');
  const sortHighToLow = () => setSortBy('highLow');
  const sortLowToHigh = () => setSortBy('lowHigh');

  return (
    <ContainerResize>
      <Header
        title={assessmentTitle}
        subtitle={headerSubtitle}
        backButtonOverride={handleBackAction}
      />
      <ContainerPage>
        <HighLowAvgPanel
          gradeSummary={gradeSummary}
          isPointsOnly={isPointsOnly}
        />
        <Panel>
          <PanelHeaderSC paddingLeft="24px">
            <GradesHeadingWrapper>
              <Heading2>Student Grades</Heading2>
              <FormControl component="fieldset">
                <FormGroup>
                  <CheckboxContainer>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="hideStudents"
                          onChange={toggleHideStudentNames}
                          checked={hideStudentNames}
                          color="primary"
                        />
                      }
                      label="Hide Student Names"
                    />
                  </CheckboxContainer>
                </FormGroup>
              </FormControl>
              <ButtonMenuSC
                buttonText="Sort By..."
                type="outline"
                options={[
                  {
                    label: 'Last Name',
                    onClick: sortByLastName
                  },
                  {
                    label: 'High to Low Percentage',
                    onClick: sortHighToLow
                  },
                  {
                    label: 'Low to High Percentage',
                    onClick: sortLowToHigh
                  }
                ]}
              />
            </GradesHeadingWrapper>
          </PanelHeaderSC>
          {resultCards && resultCards.length > 0 ? (
            <>
              <GradeSummaryRowHeader isPointsOnly={isPointsOnly} />
              {resultCards.sort(sortFunction).map(resultCard => (
                <GradeSummaryRow
                  key={isPointsOnly ? resultCard.studentUuid : resultCard.uuid}
                  isPointsOnly={isPointsOnly}
                  resultCard={resultCard}
                  hideStudentNames={hideStudentNames}
                />
              ))}
            </>
          ) : (
            <PanelPadding>No Scores</PanelPadding>
          )}
        </Panel>
        <DelayedPanel>
          <PanelHeaderSC paddingLeft="24px">
            <GradesHeadingWrapper>
              <Heading2>Delayed Students</Heading2>
            </GradesHeadingWrapper>
          </PanelHeaderSC>
          {delayedResultCards && delayedResultCards.length > 0 ? (
            <>
              <GradeSummaryRowHeader isPointsOnly={isPointsOnly} />
              {delayedResultCards.sort(sortFunction).map(resultCard => (
                <DelayedGradeSummaryRow
                  key={isPointsOnly ? resultCard.studentUuid : resultCard.uuid}
                  isPointsOnly={isPointsOnly}
                  resultCard={resultCard}
                  hideStudentNames={hideStudentNames}
                />
              ))}
            </>
          ) : (
            <PanelPadding>No Delayed Students</PanelPadding>
          )}
        </DelayedPanel>
      </ContainerPage>
    </ContainerResize>
  );
};

GradeSummary.propTypes = {
  assessmentTitle: PropTypes.string,
  assessmentType: PropTypes.string,
  courseNumber: PropTypes.string,
  courseTitle: PropTypes.string,
  gradeSummary: PropTypes.object,
  hideStudentNames: PropTypes.bool,
  isPointsOnly: PropTypes.bool,
  sortFunction: PropTypes.func,
  toggleHideStudentNames: PropTypes.func,
  setSortBy: PropTypes.func,
  handleBackAction: PropTypes.func,
  partNumber: PropTypes.string,
  resultCards: PropTypes.arrayOf(PropTypes.object),
  delayedResultCards: PropTypes.arrayOf(PropTypes.object),
  sectionNumber: PropTypes.string
};

GradeSummary.defaultProps = {
  assessmentTitle: '',
  assessmentType: '',
  courseNumber: '',
  courseTitle: '',
  gradeSummary: { highScore: '--', lowScore: '--', averageScore: '--' },
  hideStudentNames: false,
  isPointsOnly: false,
  sortFunction: undefined,
  toggleHideStudentNames: undefined,
  setSortBy: undefined,
  handleBackAction: undefined,
  partNumber: '',
  resultCards: [],
  delayedResultCards: [],
  sectionNumber: ''
};

export default GradeSummary;
