import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { SketchPicker } from 'react-color';
import { v4 as uuidv4 } from 'uuid';
import { ButtonInline } from '@xcomp/xcomp-design-library';

import {
  ContainerResize,
  ContainerPage,
  ContainerContent,
  ElevateLow
} from '../Library/Layout';

import { validateInputString } from '../../helpers/validation/validateGeneric';

import Header from '../Library/Header';
import FormField from '../Library/FormField';

const Label = styled.div`
  margin-top: 40px;
  margin-bottom: 10px;

  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  letter-spacing: 0.21px;
  line-height: 20px;
`;

const PopOver = styled.div`
  position: absolute;
  z-index: 2;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const ColorBox = styled.div`
  width: 60px;
  height: 30px;
  background-color: ${props => props.color};
  border-radius: 5px;
  border: 3px solid ${props => props.theme.colors.black};
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
`;

const ImageFrame = styled.div`
  width: 30%;
  height: auto;
  background: ${props => props.theme.colors.grey[300]};
  border-radius: 4px;
  padding: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

export default function InstitutionManagement({
  institutionName,
  primaryColor,
  secondaryColor,
  displayPrimaryPicker,
  displaySecondaryPicker,
  hasErrors,
  logo,
  handleImageUpload,
  handleChangeName,
  handleUpdateInstitution,
  handlePrimaryColor,
  handlePrimaryOpen,
  handlePrimaryClose,
  handleSecondaryColor,
  handleSecondaryOpen,
  handleSecondaryClose
}) {
  return (
    <ContainerResize>
      <Header
        backOn={false}
        title="Institution Management"
        subtitle="Edit institution"
      />
      <ContainerPage>
        <ElevateLow>
          <ContainerContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormField
                  id="institutionName"
                  name="Institution Name"
                  label="Institution Name*"
                  value={institutionName}
                  onBlur={handleChangeName}
                  handleValidate={validateInputString}
                  hasErrors={hasErrors}
                />
              </Grid>

              <Grid item xs={4}>
                <Label>Select Institution Primary Color</Label>
                <ColorBox
                  id="primary-color"
                  color={primaryColor}
                  onClick={() => handlePrimaryOpen()}
                />
                {displayPrimaryPicker && (
                  <PopOver>
                    <Cover onClick={() => handlePrimaryClose()} />
                    <SketchPicker
                      color={primaryColor}
                      onChangeComplete={handlePrimaryColor}
                    />
                  </PopOver>
                )}
              </Grid>
              <Grid item xs={8}>
                <Label>Select Institution Secondary Color</Label>
                <ColorBox
                  id="secondary-color"
                  color={secondaryColor}
                  onClick={() => handleSecondaryOpen()}
                />
                {displaySecondaryPicker && (
                  <PopOver>
                    <Cover onClick={() => handleSecondaryClose()} />
                    <SketchPicker
                      color={secondaryColor}
                      onChangeComplete={handleSecondaryColor}
                    />
                  </PopOver>
                )}
              </Grid>
              <Grid item xs={12}>
                <Label>Logo</Label>
                {logo && (
                  <ImageFrame>
                    <Image src={process.env.REACT_APP_USE_IBM_CLOUD === 'true' ? `${logo}?${uuidv4()}` : `data:image/jpeg;base64,${logo}`} alt="Logo" />
                  </ImageFrame>
                )}
              </Grid>
              <Grid item xs={4}>
                <Label>Upload Logo</Label>
                <form
                  method="post"
                  encType="multipart/form-data"
                  action="/handleImageUpload"
                >
                  <input
                    type="file"
                    id="single"
                    name="photo"
                    onChange={event => handleImageUpload(event)}
                  />
                </form>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <ButtonInline onClick={() => handleUpdateInstitution()}>
                    Save
                  </ButtonInline>
                </Grid>
              </Grid>
            </Grid>
          </ContainerContent>
        </ElevateLow>
      </ContainerPage>
    </ContainerResize>
  );
}

InstitutionManagement.propTypes = {
  displayPrimaryPicker: PropTypes.bool,
  displaySecondaryPicker: PropTypes.bool,
  hasErrors: PropTypes.bool,
  institutionName: PropTypes.string,
  logo: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  handleImageUpload: PropTypes.func,
  handleChangeName: PropTypes.func,
  handlePrimaryClose: PropTypes.func,
  handlePrimaryColor: PropTypes.func,
  handlePrimaryOpen: PropTypes.func,
  handleSecondaryClose: PropTypes.func,
  handleSecondaryColor: PropTypes.func,
  handleSecondaryOpen: PropTypes.func,
  handleUpdateInstitution: PropTypes.func
};

InstitutionManagement.defaultProps = {
  displayPrimaryPicker: false,
  displaySecondaryPicker: false,
  hasErrors: false,
  institutionName: '',
  logo: '',
  primaryColor: '',
  secondaryColor: '',
  handleImageUpload: undefined,
  handleChangeName: undefined,
  handlePrimaryClose: undefined,
  handlePrimaryColor: undefined,
  handlePrimaryOpen: undefined,
  handleSecondaryClose: undefined,
  handleSecondaryColor: undefined,
  handleSecondaryOpen: undefined,
  handleUpdateInstitution: undefined
};
