import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import ButtonPrimary from '@xcomp/xcomp-design-library/dist/components/ButtonPrimary';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  ContainerPage,
  ContainerActions,
  ElevateLow
} from '../../../Library/Layout';

import FormField from '../../../Library/FormField';

const Titles = styled.div`
  color: ${props => props.theme.fontColors.dark};
  font-size: 14px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Input = styled.div`
  padding-left: 1rem;
  font-size: 14px;
`;

const ListHeader = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`;

const ListRow = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const ModField = styled.div`
  margin-right: 10px;
`;

export default function CourseAgeModContainer({
  courseAgeModState,
  handleChange,
  handleSave
}) {
  return (
    <div>
      <ContainerActions paddingTop="20px">
        <ButtonPrimary onClick={handleSave}>Save</ButtonPrimary>
      </ContainerActions>

      <ContainerPage marginLeft="150px" marginRight="150px" paddingTop="0px">
        <ListHeader>
          <ElevateLow>
            <Titles>
              <Grid container>
                <Grid item sm={3} xs={12}>
                  Course Age Years
                </Grid>
                <Grid item sm={3} xs={12}>
                  Low
                </Grid>
                <Grid item sm={3} xs={12}>
                  High
                </Grid>
                <Grid item sm={3} xs={12}>
                  Course Age Modification
                </Grid>
              </Grid>
            </Titles>
          </ElevateLow>
        </ListHeader>
        <ElevateLow>
          {courseAgeModState
            ?.sort((a, b) => a.year - b.year)
            ?.map(year => (
              <ListRow key={year?.year}>
                <Grid container alignItems="center">
                  <Grid item sm={3} xs={12}>
                    <Input>{year?.year}</Input>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <Input>{year?.low}</Input>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <Input>{year?.high}</Input>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <ModField>
                      <FormField
                        name={year?.year}
                        label="Mod"
                        value={year?.mod}
                        onChange={handleChange}
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 },
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          )
                        }}
                      />
                    </ModField>
                  </Grid>
                </Grid>
              </ListRow>
            ))}
        </ElevateLow>
      </ContainerPage>
    </div>
  );
}

CourseAgeModContainer.propTypes = {
  courseAgeModState: PropTypes.array,
  handleChange: PropTypes.func,
  handleSave: PropTypes.func
};

CourseAgeModContainer.defaultProps = {
  courseAgeModState: [],
  handleChange: undefined,
  handleSave: undefined
};
