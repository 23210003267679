const userRolesInitial = {
  institutionAdmin: [],
  schoolAdmin: [],
  departmentFaculty: [],
  departmentStaff: [],
  programAdmin: [],
  programStaff: [],
  programFaculty: [],
  institutionAdminRemove: [],
  schoolAdminRemove: [],
  departmentFacultyRemove: [],
  departmentStaffRemove: [],
  programAdminRemove: [],
  programStaffRemove: [],
  programFacultyRemove: []
};

const userRolesReducer = (state, action) => {
  switch (action.type) {
    case 'exist': {
      const roles = { ...action.currentRoles };
      return {
        ...state,
        ...roles
      };
    }
    case 'institution_admin': {
      const institutionAdmin = state.institutionAdmin.map(role => {
        if (role.uuid === action.payload.uuid) {
          return { ...role, ...action.payload.modify };
        } else {
          return role;
        }
      });

      return {
        ...state,
        institutionAdmin
      };
    }
    case 'school_admin': {
      const schoolAdmin = state.schoolAdmin.map(role => {
        if (role.uuid === action.payload.uuid) {
          return { ...role, ...action.payload.modify };
        } else {
          return role;
        }
      });

      return {
        ...state,
        schoolAdmin
      };
    }
    case 'department_faculty': {
      const departmentFaculty = state.departmentFaculty.map(role => {
        if (role.uuid === action.payload.uuid) {
          return { ...role, ...action.payload.modify };
        } else {
          return role;
        }
      });

      return {
        ...state,
        departmentFaculty
      };
    }
    case 'department_staff': {
      const departmentStaff = state.departmentStaff.map(role => {
        if (role.uuid === action.payload.uuid) {
          return { ...role, ...action.payload.modify };
        } else {
          return role;
        }
      });

      return {
        ...state,
        departmentStaff
      };
    }
    case 'program_admin': {
      const programAdmin = state.programAdmin.map(role => {
        if (role.uuid === action.payload.uuid) {
          return { ...role, ...action.payload.modify };
        } else {
          return role;
        }
      });

      return {
        ...state,
        programAdmin
      };
    }
    case 'program_faculty': {
      const programFaculty = state.programFaculty.map(role => {
        if (role.uuid === action.payload.uuid) {
          return { ...role, ...action.payload.modify };
        } else {
          return role;
        }
      });

      return {
        ...state,
        programFaculty
      };
    }
    case 'program_staff': {
      const programStaff = state.programStaff.map(role => {
        if (role.uuid === action.payload.uuid) {
          return { ...role, ...action.payload.modify };
        } else {
          return role;
        }
      });

      return {
        ...state,
        programStaff
      };
    }
    case 'remove': {
      switch (action.payload.role) {
        case 'institution_admin': {
          const institutionAdmin = state.institutionAdmin.filter(
            role => role.uuid !== action.payload.uuid
          );

          return {
            ...state,
            institutionAdmin,
            institutionAdminRemove: [
              ...state.institutionAdminRemove,
              action.payload.uuid
            ]
          };
        }
        case 'school_admin': {
          const schoolAdmin = state.schoolAdmin.filter(
            role => role.uuid !== action.payload.uuid
          );

          return {
            ...state,
            schoolAdmin,
            schoolAdminRemove: [...state.schoolAdminRemove, action.payload.uuid]
          };
        }
        case 'department_faculty': {
          const departmentFaculty = state.departmentFaculty.filter(
            role => role.uuid !== action.payload.uuid
          );

          return {
            ...state,
            departmentFaculty,
            departmentFacultyRemove: [
              ...state.departmentFacultyRemove,
              action.payload.uuid
            ]
          };
        }
        case 'department_staff': {
          const departmentStaff = state.departmentStaff.filter(
            role => role.uuid !== action.payload.uuid
          );

          return {
            ...state,
            departmentStaff,
            departmentStaffRemove: [
              ...state.departmentStaffRemove,
              action.payload.uuid
            ]
          };
        }
        case 'program_admin': {
          const programAdmin = state.programAdmin.filter(
            role => role.uuid !== action.payload.uuid
          );

          return {
            ...state,
            programAdmin,
            programAdminRemove: [
              ...state.programAdminRemove,
              action.payload.uuid
            ]
          };
        }
        case 'program_faculty': {
          const programFaculty = state.programFaculty.filter(
            role => role.uuid !== action.payload.uuid
          );

          return {
            ...state,
            programFaculty,
            programFacultyRemove: [
              ...state.programFacultyRemove,
              action.payload.uuid
            ]
          };
        }
        case 'program_staff': {
          const programStaff = state.programStaff.filter(
            role => role.uuid !== action.payload.uuid
          );

          return {
            ...state,
            programStaff,
            programStaffRemove: [
              ...state.programStaffRemove,
              action.payload.uuid
            ]
          };
        }
        default:
          return {
            ...state
          };
      }
    }
    default:
      return {
        ...state
      };
  }
};

export { userRolesReducer, userRolesInitial };
