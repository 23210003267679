export const FILTER_COURSE_MASTER_DEPARTMENT_UUID =
  'FILTER_COURSE_MASTER_DEPARTMENT_UUID';
export const FILTER_COURSE_DEPARTMENT_UUID = 'FILTER_COURSE_DEPARTMENT_UUID';
export const FILTER_COURSE_COURSE_MASTER_UUID =
  'FILTER_COURSE_COURSE_MASTER_UUID';
export const FILTER_COURSE_ACADEMIC_YEAR_UUID =
  'FILTER_COURSE_ACADEMIC_YEAR_UUID';
export const FILTER_COURSE_TERM_UUID = 'FILTER_COURSE_TERM_UUID';
export const OPEN_COURSE_ACCORDION = 'OPEN_COURSE_ACCORDION';
export const CLOSE_COURSE_ACCORDION = 'CLOSE_COURSE_ACCORDION';
export const OPEN_SECTION_ACCORDION = 'OPEN_SECTION_ACCORDION';
export const CLOSE_SECTION_ACCORDION = 'CLOSE_SECTION_ACCORDION';
