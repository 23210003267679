import {
  OPEN_NOTIFICATION,
  CLOSE_NOTIFICATION,
  GENERAL_NOTIFICATION_ERROR,
  GENERAL_NOTIFICATION_SUCCESS
} from '../actions-type';

const doOpenNotification = () => ({
  type: OPEN_NOTIFICATION
});

const doCloseNotification = () => ({
  type: CLOSE_NOTIFICATION
});

const doGeneralErrorNotification = error => ({
  type: GENERAL_NOTIFICATION_ERROR,
  payload: {
    error
  }
});

const doGeneralSuccessNotification = message => ({
  type: GENERAL_NOTIFICATION_SUCCESS,
  payload: {
    message
  }
});

export {
  doOpenNotification,
  doCloseNotification,
  doGeneralErrorNotification,
  doGeneralSuccessNotification
};
