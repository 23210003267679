import { OPEN_ACCORDION, CLOSE_ACCORDION } from '../actions-type';

const doSetOpenAccordion = key => ({
  type: OPEN_ACCORDION,
  payload: {
    key
  }
});

const doSetClosedAccordion = key => ({
  type: CLOSE_ACCORDION,
  payload: {
    key
  }
});

export { doSetOpenAccordion, doSetClosedAccordion };
