import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { StickyTitleTd, TableDataText } from '../CollectionReportSC';
import { partGradeResultSelector } from '../../../../../redux/selectors/gradeResultSelectors';

const StudentPartGradeData = ({
  evenStudent,
  partUuid,
  studentUuid,
  studentRank
}) => {
  const partGradeResult = useSelector(state =>
    partGradeResultSelector(state, partUuid, studentUuid)
  );

  return (
    <>
      <StickyTitleTd
        redOne={evenStudent}
        redTwo={!evenStudent}
        left="0px"
        borderRight
      >
        <TableDataText>{partGradeResult?.calculatedGrade}</TableDataText>
      </StickyTitleTd>
      <StickyTitleTd
        redOne={evenStudent}
        redTwo={!evenStudent}
        left="75px"
        borderRight
      >
        <TableDataText>{partGradeResult?.letterGrade}</TableDataText>
      </StickyTitleTd>
      <StickyTitleTd
        redOne={evenStudent}
        redTwo={!evenStudent}
        left="150px"
        borderRight
      >
        <TableDataText>{studentRank || '-'}</TableDataText>
      </StickyTitleTd>
    </>
  );
};

StudentPartGradeData.propTypes = {
  evenStudent: PropTypes.bool.isRequired,
  partUuid: PropTypes.string.isRequired,
  studentUuid: PropTypes.string.isRequired,
  studentRank: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

StudentPartGradeData.defaultProps = { studentRank: '-' };

export default StudentPartGradeData;
