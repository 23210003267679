import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { doGetSectionPerformanceReport } from '../../../../redux/actions/sectionPerformanceReportActions';
import SectionPerformanceReport from './SectionPerformanceReport';
import {
  sortByNestedProperty,
  sortByProperty
} from '../../../../helpers/utilities';

const SectionPerformanceReportContainer = ({
  courseCredits,
  parts,
  reportView,
  sectionUuid,
  termPeriod,
  courseNumber,
  courseTitle,
  sectionNumber
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (sectionUuid) {
      dispatch(doGetSectionPerformanceReport(sectionUuid));
    }
  }, [dispatch, sectionUuid]);

  const sectionPerformanceReportState = useSelector(
    state => state.sectionPerformanceReportState
  );

  const isLoadingReport =
    sectionPerformanceReportState.loadingSectionPerformanceReport ||
    sectionPerformanceReportState.loadingPartGradeResults;

  const activeStudentsReport =
    sectionPerformanceReportState.sectionPerformanceReport
      .filter(
        reportInfo =>
          reportInfo.status !== 'delayed' &&
          reportInfo.status !== 'withdrew' &&
          reportInfo.status !== 'credit'
      )
      .sort((a, b) => sortByProperty(a, b, 'classRank'));

  const delayedOrWithdrawnStudentsReport =
    sectionPerformanceReportState.sectionPerformanceReport
      .filter(
        reportInfo =>
          reportInfo.status === 'delayed' ||
          reportInfo.status === 'withdrew' ||
          reportInfo.status === 'credit'
      )
      .sort((a, b) => sortByNestedProperty(a, b, 'user.lastName'));

  const sortedParts = parts.sort((a, b) => sortByProperty(a, b, 'partNumber'));

  return (
    <SectionPerformanceReport
      activeSectionPerformanceReport={activeStudentsReport}
      courseCredits={courseCredits}
      delayedOrWithdrawnSectionPerformanceReport={
        delayedOrWithdrawnStudentsReport
      }
      sectionPerformanceReport={activeStudentsReport.concat(
        delayedOrWithdrawnStudentsReport
      )}
      isLoadingReport={isLoadingReport}
      parts={sortedParts}
      reportView={reportView}
      termPeriod={termPeriod}
      courseNumber={courseNumber}
      courseTitle={courseTitle}
      sectionNumber={sectionNumber}
    />
  );
};

SectionPerformanceReportContainer.propTypes = {
  parts: PropTypes.array,
  reportView: PropTypes.string,
  sectionUuid: PropTypes.string,
  courseCredits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  termPeriod: PropTypes.string,
  courseNumber: PropTypes.string,
  courseTitle: PropTypes.string,
  sectionNumber: PropTypes.string
};

SectionPerformanceReportContainer.defaultProps = {
  parts: [],
  reportView: 'default',
  sectionUuid: '',
  courseCredits: 0,
  termPeriod: '',
  courseNumber: '',
  courseTitle: '',
  sectionNumber: ''
};

export default SectionPerformanceReportContainer;
