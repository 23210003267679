import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import {
  DeleteIcon,
  AddUserIcon
} from '@xcomp/xcomp-design-library/dist/icons';

import CohortStudentStatusMenu from './CohortStudentStatusMenu';

const Actions = styled.div`
  background: ${props => props.theme.colors.white};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding-top: 10px;
  padding-bottom: 10px;
`;

const IconSpacing = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;

export default function CohortStudentActions({
  handleAssignStudentOpen,
  handleSelectedStatus,
  handleInitiateDelete
}) {
  return (
    <div>
      <Actions>
        <IconSpacing>
          <Tooltip title="Change Status" placement="top-start">
            <IconButton>
              <CohortStudentStatusMenu
                handleSelectedStatus={handleSelectedStatus}
              />
            </IconButton>
          </Tooltip>
        </IconSpacing>
        <IconSpacing>
          <Tooltip title="Assign Individual Student" placement="top-start">
            <IconButton onClick={() => handleAssignStudentOpen()}>
              <AddUserIcon />
            </IconButton>
          </Tooltip>
        </IconSpacing>
        <IconSpacing>
          <Tooltip title="Delete" placement="top-start">
            <IconButton onClick={() => handleInitiateDelete()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </IconSpacing>
      </Actions>
    </div>
  );
}

CohortStudentActions.propTypes = {
  handleAssignStudentOpen: PropTypes.func,
  handleSelectedStatus: PropTypes.func,
  handleInitiateDelete: PropTypes.func
};
CohortStudentActions.defaultProps = {
  handleAssignStudentOpen: undefined,
  handleSelectedStatus: undefined,
  handleInitiateDelete: undefined
};
