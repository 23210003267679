import { Model, attr, fk } from 'redux-orm';
import { GET_ASSESSMENT_GRADES_SUCCESS } from '../actions-type';

class AssessmentRank extends Model {
  static reducer(action, AssessmentRank, session) {
    const { payload, type } = action;
    switch (type) {
      case GET_ASSESSMENT_GRADES_SUCCESS: {
        const { assessmentRank } = payload;

        if (assessmentRank) {
          AssessmentRank.upsert(assessmentRank);
        }

        break;
      }
      default:
        return session;
    }
  }
}

AssessmentRank.fields = {
  assessmentUuid: attr(),
  studentUuid: fk({
    to: 'User',
    as: 'student',
    relatedName: 'student'
  }),
  rank: attr(),
  rankCount: attr()
};

AssessmentRank.modelName = 'AssessmentRank';

AssessmentRank.options = {
  idAttribute: 'assessmentUuid'
};

export default AssessmentRank;
