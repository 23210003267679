import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import UserModify from './UserModify';

import { doGetInstitutionIdTypes } from '../../../redux/actions/sourceActions';

export default function UserModifyContainer({ match, history }) {
  const dispatch = useDispatch();
  const { path } = match;
  const { pathname } = history.location;
  const { userUuid } = match.params;

  useEffect(() => {
    dispatch(doGetInstitutionIdTypes());
  }, [dispatch]);

  return <UserModify userUuid={userUuid} path={path} pathname={pathname} />;
}

UserModifyContainer.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
};

UserModifyContainer.defaultProps = {
  match: {},
  history: {}
};
